import {
  initialDeleteEmployeCheckState,
  DELETE_EMPLOYEE_CHECK_ACTION_TYPES,
  IDeleteEmployeeCheckReducer,
} from "Redux/Actions/Employee/Delete/Check/types";

export const deleteEmployeeCheckReducer = (
  state = initialDeleteEmployeCheckState,
  action
): IDeleteEmployeeCheckReducer => {
  switch (action.type) {
    case DELETE_EMPLOYEE_CHECK_ACTION_TYPES.FETCH_DELETE_EMPLOYEE_CHECK_REQUEST:
      return {
        ...state,
        data: action.data,
        loading: true,
        error: false,
      };
    case DELETE_EMPLOYEE_CHECK_ACTION_TYPES.FETCH_DELETE_EMPLOYEE_CHECK_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
        error: false,
      };
    case DELETE_EMPLOYEE_CHECK_ACTION_TYPES.FETCH_DELETE_EMPLOYEE_CHECK_ERROR:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: true,
      };
    case DELETE_EMPLOYEE_CHECK_ACTION_TYPES.CLEAR_DELETE_EMPLOYEE_CHECK:
      return initialDeleteEmployeCheckState;
    default:
      return state;
  }
};
