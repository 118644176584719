import * as React from "react";
import { Modal, Button } from "antd";
import { useHistory } from "react-router-dom";
import { modalCloseIcon } from "assets/img";
import { orangeWalletIcon102x102 } from "assets/icons/card";

import styles from "Views/Card/CreateOrRequest/ActivateWallet/index.module.scss";

interface IActivateWalletModalProps {
  onClose: () => void;
}

const ActivateWalletModal: React.FunctionComponent<IActivateWalletModalProps> = ({ onClose }) => {
  const history = useHistory();

  const onActivateWallet = () => {
    history.push("/profileupdate");
  };

  return (
    <>
      <Modal
        visible
        onCancel={onClose}
        className={styles["verification-modal"]}
        title="Activate Cards Feature to Continue"
        footer={null}
        width={540}
        closeIcon={<img src={modalCloseIcon} alt="close icon" />}
        centered
      >
        <div className={styles["error-screen-container"]}>
          <img src={orangeWalletIcon102x102} width={102} height={102} alt="info icon" />
          <p className={styles["error-info"]}>
            You have not activated this feature. Activate Cards feature to start creating and requesting cards.
          </p>
          <Button className={styles["error-action-btn"]} onClick={onActivateWallet}>
            Activate Cards Feature
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default ActivateWalletModal;
