import React from "react";
import moment from "moment";
import { Controller } from "react-hook-form";

import { DatePicker as AntdDatePicker } from "antd";
import { Input } from "@spenmo/splice";
import { ErrorMessage } from "Views/SubscriptionManagement/Create/Components";

import { formFieldHasRequiredError } from "Views/SubscriptionManagement/Create/Helper";
import { FORM_NAMES } from "Views/SubscriptionManagement/Constants";
import { useFormContext } from "Views/SubscriptionManagement/Create/Context";

interface IDatePickerProps {
  name: FORM_NAMES.START_DATE | FORM_NAMES.END_DATE | FORM_NAMES.NEXT_BILLING_DATE;
  label: string;
  value: string;
  errorMessage: string;
  disabledDate?: string;
  disableBeforeDisabledDate?: boolean;
}

export const DatePicker = ({
  name,
  label,
  value,
  errorMessage,
  disabledDate,
  disableBeforeDisabledDate,
}: IDatePickerProps) => {
  const { control, errors } = useFormContext();
  const hasAnError = formFieldHasRequiredError(errors, name);
  const checkDisabledDate = (current: moment.Moment) => {
    if (disabledDate && current) {
      return disableBeforeDisabledDate
        ? current < moment(disabledDate).endOf("day")
        : current > moment(disabledDate).subtract(1, "day").endOf("day");
    }
    return false;
  };

  return (
    <>
      <Input.Label>{label}</Input.Label>
      <Controller
        name={name}
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <AntdDatePicker
            {...field}
            getPopupContainer={(triggerNode) => triggerNode.parentElement}
            id={name}
            picker="date"
            placeholder="Select Date"
            disabledDate={checkDisabledDate}
            onChange={(selectedDate) => {
              const formattedDate = selectedDate ? selectedDate?.format("YYYY-MM-DDTHH:mm:ss") + "Z" : null;
              field.onChange(formattedDate);
            }}
            value={value ? moment(value.split("T")[0]) : null}
          />
        )}
      />
      {hasAnError && <ErrorMessage message={errorMessage} />}
    </>
  );
};
