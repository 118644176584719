import { ISpendingLockChoiceConfig } from "Views/Card/Components/SpendingLock/types";
import { CARD_PATHS } from "Route/Card/path";
import { SPENDING_LOCK_TYPES } from "Views/Card/types";

export const getSpendinLockChoiceConfig = (isCategoryLockTreatmentOn): ISpendingLockChoiceConfig[] => {
  const config: ISpendingLockChoiceConfig[] = [
    {
      title: "Merchant Lock",
      subtitle: "Restrict card spending to specific merchant(s)",
      value: SPENDING_LOCK_TYPES.MERCHANT,
      infoIcon: null,
    },
  ];
  if (isCategoryLockTreatmentOn) {
    config.unshift({
      title: "Category Lock",
      subtitle: "Restrict card spending to a group of merchants",
      value: SPENDING_LOCK_TYPES.CATEGORY,
      infoIcon: {
        link: `${window?.location?.origin}${CARD_PATHS.CATEGORY_LOCK_KB_ARCTILE}`,
        title: "Tap to learn more about Categories",
      },
    });
  }
  return config;
};
