import React, { useState } from "react";
import PropTypes from "prop-types";
import UploadCSV from "Modules/fileUpload";
import DownloadTemplate from "Views/Employee/Components/DownloadTemplate";
import OverlayLoader from "Modules/overlayLoader";
import "./ManualBulk.scss";

const ManualBulk = (props) => {
  const [file, setFile] = useState(null);
  const { title, subtitle, uploadTitle, action, onDownload, customUploadURL, id, customUploadAction } = props;

  const handleFileUpload = (file) => {
    setFile(file);
    action(file);
  };

  return (
    <>
      <div className={"bulk-upload"} id={id ? id : "bulkUpload"}>
        <h1 className={"bulk-upload__description-title"}>{title}</h1>
        <p className={"bulk-upload__description-subtitle"}>{subtitle}</p>

        <div className={"bulk-upload__content"}>
          <DownloadTemplate onDownload={onDownload} />
          <UploadCSV
            title={
              <p>
                Step 2<span className={"upload__title-details"}>{uploadTitle}</span>
              </p>
            }
            action={handleFileUpload}
            useCustomAPI
            formatMessage={"XLSX format"}
            accept={".xlsx"}
            format={["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"]}
            customUploadURL={customUploadURL}
            customUploadAction={customUploadAction}
          />
        </div>
      </div>
      {file && <OverlayLoader delay />}
    </>
  );
};

ManualBulk.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  uploadTitle: PropTypes.string,
  action: PropTypes.func,
  onDownload: PropTypes.func,
  customUploadURL: PropTypes.string,
  id: PropTypes.string,
  customUploadAction: PropTypes.func,
};

export default ManualBulk;
