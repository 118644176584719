import React, { useState } from "react";
import PropTypes from "prop-types";
import { currencyFormatter, dateTimeFormatter, roundValue } from "utility";
import TableRowField from "Modules/tableRowField";
import "Views/TransactionsListing/TransactionDetails/TransactionDetails.scss";
import TransactionRefundEdit from "./TransactionRefundEdit";
import Button from "Modules/button";
import { getMerchantName } from "Views/TransactionsListing/TransactionsHelper";
import TransactionEditConfirmation from "Views/TransactionsListing/TransactionDetails/TransactionEditConfirmation";
import useLoading from "utility/useLoading";
import { updateTransactionMerchantName } from "Redux/DataCalls/Transactions.api";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import { TRANSACTION_TYPE } from "constants/Transaction.constant";
import { useTransactionTags } from "Modules/AccountingTags/hooks/useTransactionTags";
import SelectedTag from "Modules/AccountingTags/components/SelectedTag";

const TransactionRefundDetails = ({
  data,
  onEditTransaction,
  openParentTransaction,
  transactionType,
  readableTransactionType,
  parentTransaction,
  connectedToXero,
}) => {
  const [showEdit, setShowEdit] = useState(false);
  const [tags, updateTags] = useTransactionTags(data.id);

  const [editConfirmation, setEditConfirmation] = useState({
    show: false,
    message: "",
    data: {},
  });
  const [updateTransactionAction, loading] = useLoading(updateTransactionMerchantName);
  const dt = dateTimeFormatter(data.created_at);

  const resetEditConfirmationState = () => {
    setEditConfirmation({
      show: false,
      message: "",
      // We still need this data to update the form when user click cancel on the warning page
      data: editConfirmation.data,
    });
  };

  const onEdit = (showWarning, hasDataChange, payload = {}) => {
    if (showWarning) {
      setEditConfirmation({
        show: true,
        ...payload,
      });
      return;
    }
    setShowEdit(false);

    if (hasDataChange) {
      saveEditedData(payload.data);
    }
  };

  const saveEditedData = async ({ simplified_merchant_name, tags: chosenTags, transaction_id }) => {
    const requestBody = {
      transaction_number: data.transaction_number,
      simplified_merchant_name,
      transaction_id: transaction_id,
      tags: chosenTags,
    };

    const {
      data: { status, payload },
    } = await updateTransactionAction(requestBody);

    if (status === HTTP_STATUS_CODE.OK) {
      onEditTransaction(requestBody);
      updateTags(chosenTags);
    } else {
      throw payload?.status_message;
    }
  };

  const handleSaveTransaction = async (payload) => {
    await saveEditedData(payload);
    resetEditConfirmationState();
    setShowEdit(false);
  };

  if (editConfirmation.show) {
    return (
      <TransactionEditConfirmation
        editConfirmation={editConfirmation}
        resetEditConfirmationState={resetEditConfirmationState}
        handleSaveTransaction={handleSaveTransaction}
      />
    );
  }

  return (
    <div className={"transaction-details"}>
      <TableRowField title={getMerchantName(data)} details={`${dt.date} | ${dt.time}`} isBold isSubHeader />
      <div className={"transaction-details__details"}>
        <TableRowField title={currencyFormatter(roundValue(data.amount), data.currency_code)} isBold />
      </div>
      <TableRowField title={"Trx No."} details={data.transaction_number || "N/A"} />
      {parentTransaction && (
        <span onClick={() => openParentTransaction(parentTransaction)} className="transaction-details__redirect_link">
          View the original transaction to edit the details
        </span>
      )}
      <TableRowField title="Transaction Type" details={readableTransactionType} />
      <div className={"transaction-details__details"}>
        <TableRowField title={"Reference"} details={data.merchant} />
        <TableRowField title={"Card no."} details={data.card_last_four || "N/A"} />
      </div>
      <div className={"transaction-details__details"}>
        <TableRowField title={"Foreign Currency Code"} details={data.foreign_currency_code || "N/A"} />
        <TableRowField title={"Foreign Currency Amount"} details={data.foreign_currency_amount || "N/A"} />
      </div>
      <TableRowField title={"Description"} details={data.description} />
      {connectedToXero && (
        <div className="sp-margin-bottom--md">
          <TableRowField
            title={"Tags"}
            details={
              <div className="sp-margin-top--s sp-margin-bottom--s">
                {Array.isArray(tags) && tags.length !== 0
                  ? tags.map(({ label, value }, index) => (
                      <SelectedTag editable={false} name={`${label} - ${value}`} key={index} />
                    ))
                  : "-"}
              </div>
            }
          />
        </div>
      )}
      {showEdit ? (
        <TransactionRefundEdit
          transactionData={data}
          onEdit={onEdit}
          editedData={editConfirmation.data}
          loading={loading}
          initialTags={tags}
        />
      ) : (
        <>
          {transactionType === TRANSACTION_TYPE.REFUND && (
            <Button
              rounded
              className={"confirm-btn"}
              text="Edit Details"
              testid="editDetails"
              action={() => setShowEdit(true)}
            />
          )}
        </>
      )}
    </div>
  );
};

TransactionRefundDetails.propTypes = {
  data: PropTypes.object,
  onEditTransaction: PropTypes.func,
};

export default TransactionRefundDetails;
