export enum AddCardEventNames {
  CARD_ADD_CARD_CLICKED = "Card Add Card Clicked",
  ACTIVATE_CARD_CLICKED = "Card Physical Activate Card Clicked",
  CARD_REQUEST_CARD_CLICKED = "Card Request Card Clicked",
}

export enum CardCreationEventNames {
  CARD_CREATION_SETTINGS_SELECTED = "Card Creation Settings Selected",
  CARD_CREATION_BUTTON_CLICKED = "Card Creation Button Clicked",
  CARD_CREATION_PROCESSED = "Card Created Processed",
}

export enum ActivatePhysicalCardEventNames {
  CARD_PHYSICAL_ACTIVATED = "Card Physical Activated",
  CARD_ACTIVATION_PROCESSED = "Card Activation Processed",
}

export enum DeleteCardEventNames {
  CARD_DELETE_CARD_CLICKED = "Card Delete Card Clicked",
  CARD_DELETE_PROCESSED = "Card Delete Processed",
}

export enum EditCardEventNames {
  CARD_EDIT_SETTINGS_CLICKED = "Card Edit Settings Clicked",
  CARD_SETTINGS_EDITED = "Card Settings Edited",
  CARD_SETTINGS_SAVE_CLICKED = "Card Save Changes Clicked",
  CARD_STATUS_PROCESSED = "Card Status Processed",
  CARD_EDIT_PROCESSED = "Card Edit Processed",
}

export enum CardListingEventNames {
  CARD_PAGE_TAB_LOADED = "Card Page Tab Loaded",
}

export enum CardDetailsEventNames {
  SHOW_CARD_NUMBER_CLICKED = "Card Show Number Button Clicked",
  CARD_NUMBER_COPIED = "Card Number Copied",
}

export const CardSelectedEventName = "Card Selected";

/* old Events */

export enum filterEvents {
  CARD_HOLDER = "card listing card holder filter applied",
  CARD_TYPE = "card listing type filter applied",
  CARD_NUMBER = "card listing card number filter applied",
  CARD_STATUS = "card listing card status filter applied",
  CLEAR_ALL = "card listing clear all filter applied",

  SORT_OLD_TO_NEW = "card listing created ( oldest to newest ) sorter applied",
  SORT_NEW_TO_OLD = "card listing created ( newest to oldest ) sorter applied",
  SORT_BY_NAME_A_TO_Z = "card listing card title (A-Z) sorter applied",
  SORT_BY_NAME_Z_TO_A = "card listing card title (Z-A) sorter applied",
  SORT_BY_LAST_TRANSACTION = "card listing last used sorter applied",
  SEARCH = "card listing input entered in the search box",

  TEAM_NAME = "card listing tean name filter applied",
}

export enum cardListingTableEvent {
  SELECT_ALL_ROW = "card listing Select All check box checked",
  PAGINATION_CLICKED = "card listing pagination clicked",
  ALL_CARD_VIEWED = "card listing all card viewed",
  CARD_REQUEST_APPROVE_BTN_CLICKED = "card listing card request approve button clicked",
  CARD_REQUEST_DECLINED_BTN_CLICKED = "card listing card request decline button clicked",
  CARD_REQUEST_REVIEW_LINK_CLICKED = "card listing card request review button clicked",
  CARD_REQUEST_APPROVE_SUCCESS = "card listing card request approve sucess received",
  CARD_REQUEST_DECLINED_SUCCESS = "card listing card request decline sucess received",
  CARD_REQUEST_REVIEW_SUCCESS = "card listing card request review sucess received",
  CARD_REQUEST_APPROVE_ERROR = "card listing card request approve error received",
  CARD_REQUEST_DECLINED_ERROR = "card listing card request decline error received",
  CARD_REQUEST_REVIEW_ERROR = "card listing card request review error received",
}

/* card detail Modal is common for home page Mycards and card listing
   don't include any specific events to card listing or home page my card.
   Keep it card deatils, we can detect the flow using funnel if it comes
   from Mycards or table's row selection on card listing
*/
export enum cardDetailsEvent {
  CARD_DETAILS_CLOSE_BUTTON_CLICKED = "card details close button clicked",
}

export enum cardCreatingEvent {
  CREATE_VIRTUAL_CARD_BUTTON_CLICKED = "create virtual card button clicked",
  CARD_TITLE_ENTERED = "card title entered",
  TEAM_PICKED_FROM_TEAM_DROP_DOWN = "team picked from team drop down",
  CARDHOLDER_NAME_ENTERED_WHILE_CARD_CREATION = "cardholder name entered while card creation",
  ASSIGN_TO_MYSELF_CLICKED_WHILE_CARD_CREATION = "assign to myself clicked while card creation",
  NEXT_FOR_STEP_1_CLICKED_WHILE_CARD_CREATION = "next for step 1 clicked while card creation",
  GO_BACK_BUTTON_CLICKED_ON_CARD_CREATION = "go back button clicked on card creation step 2",
  CARD_PICKED_FROM_CARD_LISTING = "card picked from card listing",
}
