import React from "react";
import LetterAvatar from "../letterAvatar";
import PropTypes from "prop-types";
import Icon from "../icons";
import closeIcon from "../../assets/img/close-icon-white.svg";
import "./selectedAvatar.scss";

const SelectedAvatar = ({ data, isTeam, action }) => {
  return (
    <div className="selected-avatar">
      <div className={"selected-avatar--icon"}>
        <Icon src={closeIcon} action={action} className="close" />
        {isTeam ? (
          <LetterAvatar name={data.name || data.team_name} square />
        ) : (
          <LetterAvatar name={data.name || data.team_name} rounded />
        )}
      </div>
      <p className={"selected-avatar--name"}>{data.name.split(" ")[0]}</p>
    </div>
  );
};

SelectedAvatar.propTypes = {
  data: PropTypes.object,
  action: PropTypes.func,
  isTeam: PropTypes.bool,
};

export default SelectedAvatar;
