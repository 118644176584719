import React, { useMemo, useState } from "react";
import { SWRConfig } from "swr";
import { Typography } from "@spenmo/splice";
import { useHistory, useLocation } from "react-router-dom";

import useCheckFeatureStatus from "customHooks/featureCheck";
import { ACCOUNT_INTEGRATIONS_PATH } from "constants/Routes.constants";
import { SPLIT_NAMES, SPLIT_TREATMENT_TYPES } from "Redux/splitio/constants";

import Tabs from "Modules/DS/Tabs";
import Toaster, { TOASTER_SIZE_TYPE, TOASTER_STATUS_TYPE } from "Modules/DS/Toaster";

import { ITabConfig } from "./@types";
import { usePermission } from "./hooks";
import { SettingsModalContext } from "./context/Modal";
import { ToasterContext } from "./context/ToasterContext";
import Modal, { useSettingsModalState } from "./components/Modal";
import { PERSONAL, COMPANY, companyMenu, personalMenu } from "./config";
import { Personal, Company, Skeleton, RedirectionLogic } from "./components";

import styles from "./styles.module.scss";

const TabConfig = [
  {
    id: 1,
    key: "Company",
    title: "Company",
    Component: Company,
    enabled: true,
  },
  {
    id: 2,
    key: "Personal",
    title: "Personal",
    Component: Personal,
    enabled: true,
  },
];

const personalTabLink = personalMenu.map((menu) => menu.path);
const companyTabLink = companyMenu.map((menu) => menu.path);

const TabContent = (permission) => (tab: ITabConfig) => {
  return <tab.Component permissions={permission} />;
};

const Settings = () => {
  const location = useLocation();
  const history = useHistory();
  const { loading, notAbleToOpenCompanyPage, ...permission } = usePermission();

  const paymentRunFlag = useCheckFeatureStatus(SPLIT_NAMES.billPaymentRunPage, true);
  const newCRFlag = useCheckFeatureStatus(SPLIT_NAMES.billNewCR, true);

  const [toaster, setToaster] = useState({
    show: false,
    message: "",
    type: TOASTER_STATUS_TYPE.SUCCESS,
  });

  const modal = useSettingsModalState();

  const activeKey = personalTabLink.includes(location.pathname) ? PERSONAL : COMPANY;
  const contextValue = useMemo(() => ({ toaster, setToaster }), [toaster, setToaster]);
  const modalContextValue = useMemo(() => modal, [modal]);

  const redirect = (key) => {
    if (key === COMPANY) {
      history.push(ACCOUNT_INTEGRATIONS_PATH);
    } else {
      history.push("/settings/security");
    }
  };

  if (loading) {
    return (
      <section className={styles.setting} id="settings-page">
        <Typography size="m" variant="headline-content" tag="h3" className={styles.title}>
          Settings Page
        </Typography>
        <Skeleton />
      </section>
    );
  }

  return (
    <SWRConfig>
      <ToasterContext.Provider value={contextValue}>
        <SettingsModalContext.Provider value={modalContextValue}>
          <RedirectionLogic
            loading={loading}
            rules={[
              {
                redirectIf: companyTabLink.includes(location.pathname) && notAbleToOpenCompanyPage,
                path: personalMenu[0].path,
              },
              {
                redirectIf: location.pathname === personalTabLink[1] && !permission.ableToOpenPayoutPage,
                path: personalMenu[0].path,
              },
              // payment run
              {
                redirectIf:
                  location.pathname === companyMenu[3].path &&
                  (!permission.ableToOpenPaymentRunPage || paymentRunFlag === SPLIT_TREATMENT_TYPES.OFF),
                path: companyMenu[0].path,
              },
              {
                redirectIf:
                  location.pathname === companyMenu[5].path &&
                  (!permission.ableToOpenReimbursementsPage || newCRFlag === SPLIT_TREATMENT_TYPES.OFF),
                path: companyMenu[0].path,
              },
              // Billing
              {
                redirectIf: location.pathname === companyMenu[6].path && !permission.ableToOpenBillingPage,
                path: companyMenu[0].path,
              },
              {
                redirectIf: location.pathname === companyMenu[0].path && !permission.ableToOpenIntegrationPage,
                path: companyMenu[1].path,
              },
              {
                redirectIf: !personalTabLink.includes(location.pathname) && !companyTabLink.includes(location.pathname),
                path: notAbleToOpenCompanyPage ? personalMenu[0].path : companyMenu[0].path,
              },
            ]}
          >
            <section className={styles.setting} id="settings-page">
              <Typography size="m" variant="headline-content" tag="h3" className={styles.title}>
                Settings
              </Typography>
              <section>
                {notAbleToOpenCompanyPage ? (
                  <Personal permissions={permission} />
                ) : (
                  <Tabs
                    tabsList={TabConfig}
                    defaultActiveKey="Company"
                    activeKey={activeKey}
                    action={redirect}
                    content={TabContent(permission)}
                    destroyInactiveTabPane
                  />
                )}
              </section>
              <Modal />
              <Toaster
                size={TOASTER_SIZE_TYPE.M}
                visible={toaster.show}
                status={toaster.type}
                message={toaster.message}
                className={styles.fixToastr}
                onClose={() => setToaster({ ...toaster, show: false })}
              />
            </section>
          </RedirectionLogic>
        </SettingsModalContext.Provider>
      </ToasterContext.Provider>
    </SWRConfig>
  );
};

export default Settings;
