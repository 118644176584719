import { useAppSelector } from "hook";
import { Actions, TEAM_ACTION } from "Views/Team/List/Actions/constant";
import { ITeam } from "Views/Team/type";

const useActionsToShow = (team: ITeam) => {
  const userInfo = useAppSelector((state) => state.userInfoReducer);
  const teamActions = Actions.filter((action) => {
    if (action.value === TEAM_ACTION.DELETE) {
      return userInfo.isAdmin;
    }
    if (action.value === TEAM_ACTION.RENAME) {
      return userInfo.isAdmin || team?.your_role === "Manager";
    }
  });
  return teamActions;
};

export default useActionsToShow;
