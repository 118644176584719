import * as React from "react";
import { Input, Typography } from "antd";

import styles from "Views/Card/Pin/Input/index.module.scss";

const { Text } = Typography;

interface ICardPinInput {
  cardPinLength: number;
  onInputChange: (value: React.SetStateAction<string>) => void;
  hasError: boolean;
  errorMessage: string;
  inputLabel: string;
  focusTheFirstInput: boolean;
}

const CardInfo = ({
  cardPinLength,
  inputLabel,
  onInputChange,
  hasError,
  errorMessage,
  focusTheFirstInput,
}: ICardPinInput) => {
  const [pin, setPin] = React.useState(Array(cardPinLength)?.fill(""));
  const createStepArray = (size) => Array.from({ length: size }).map((_, index) => index);
  const [activeInputIndex, setActiveInputIndex] = React.useState(focusTheFirstInput ? 0 : null);
  const inputRef = React.useRef(null);

  const onPinChange = (event) => {
    const { key } = event || {};
    const { tabIndex } = event?.target || {};
    let enteredValue = key;

    if (["Delete", "Backspace"].includes(key)) {
      if (!pin[tabIndex] && tabIndex - 1 >= 0) {
        inputRef?.current?.blur();
        setActiveInputIndex(tabIndex - 1);
        return;
      } else {
        enteredValue = "";
      }
    }
    if (/\d/?.test(enteredValue) || enteredValue === "") {
      let newPin = [...pin];
      newPin[tabIndex] = enteredValue;
      setPin(newPin);
      tabIndex + 1 < cardPinLength && enteredValue && setActiveInputIndex(tabIndex + 1);
      const pinToString = newPin?.join("");
      onInputChange(pinToString);
    }
  };

  const getValue = (index: number) => {
    if (pin?.[index]) {
      return activeInputIndex === index ? pin[index] : ".";
    } else {
      return undefined;
    }
  };

  React.useEffect(() => {
    inputRef?.current?.focus();
  }, [activeInputIndex, inputRef]);

  return (
    <div className={styles["container"]}>
      <Text className={styles["input-label"]}>{inputLabel}</Text>
      <div>
        {createStepArray(cardPinLength).map((index) => (
          <div className={styles["input-container"]}>
            <Input
              ref={activeInputIndex === index ? inputRef : null}
              className={`${styles["input-box"]}  ${hasError ? styles["input-box__error"] : ""}`}
              tabIndex={index}
              value={getValue(index)}
              onFocus={() => {
                setActiveInputIndex(index);
              }}
              onBlur={() => {
                if (pin?.[activeInputIndex]) {
                  setActiveInputIndex(null);
                }
              }}
              onKeyUp={onPinChange}
              maxLength={1}
            />
            {activeInputIndex !== index && pin?.[index] && (
              <div className={styles["dot"]} onClick={() => setActiveInputIndex(index)} />
            )}
          </div>
        ))}
      </div>
      {errorMessage && <Text className={styles["error-message"]}>{errorMessage}</Text>}
    </div>
  );
};

export default CardInfo;
