import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { reSendOtp } from "Redux/Actions/OTP/fetchOtpAction";
import { verifyOtp } from "Redux/Actions/OTP/otpVerificationAction";
import OtpTimeOut from "Views/EmployeeOTP/OTPVerification/otpTimeout";
import OTPInputForm from "Modules/otpInputForm";
import Loader from "Views/State/Loading/LoaderIcon";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import { Typography } from "@spenmo/splice";

const OtpForm = ({ userMobileNumber, otpLength, onVerification }) => {
  const dispatch = useDispatch();
  const {
    data: sendOtpData,
    loading: sendOtpInProgress,
    error: sendOtpError,
  } = useSelector((state) => state.getOtpReducer);
  const {
    data: verifyOtpData,
    loading: verifyOtpDataLoading,
    error: verifyOtpError,
  } = useSelector((state) => state.verifyOtpReducer);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (verifyOtpError) {
      setErrorMessage(verifyOtpData || "Something went wrong, Please try again later.");
    } else if (
      verifyOtpData?.status === HTTP_STATUS_CODE.OK &&
      verifyOtpData?.payload?.status_message === "User verified successfully!"
    ) {
      onVerification();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verifyOtpData]);

  const submitOtp = (otp) => {
    if (otp?.length === otpLength) {
      dispatch(verifyOtp({ otp_to_test: otp }, "OtpAuthenticationModal", true));
    }
  };
  if (verifyOtpDataLoading) {
    return <Loader />;
  }
  return (
    <>
      <div className={"otpAuthenticationModal__modal--info-text"}>
        <Typography tag="p" size="m" variant="body-content">
          Enter 6 digits code we’ve sent to <br />
          {[...Array(8)].map((item, index) => (
            <div key={index} className="otpAuthenticationModal__modal--grey-dot" />
          ))}
          {userMobileNumber?.slice?.(userMobileNumber?.length - 4) || ""}.
        </Typography>
      </div>
      <OTPInputForm
        type="secondary-xl"
        onFinish={submitOtp}
        inputLength={otpLength}
        onFocus={() => setErrorMessage("")}
      />
      {errorMessage && <div className="otpAuthenticationModal__modal--otp-input-form-error">{errorMessage}</div>}
      <OtpTimeOut
        fetchOtpData={sendOtpData}
        fetchOtpError={sendOtpError}
        resendOtp={() => dispatch(reSendOtp("otpForm", true))}
        loading={sendOtpInProgress}
        align="center"
      />
    </>
  );
};

OtpForm.propTypes = {
  onVerification: PropTypes.func,
  userMobileNumber: PropTypes.string,
  otpLength: PropTypes.number,
};

export default OtpForm;
