import React, { useEffect, useMemo, useState } from "react";

import Checkbox from "Modules/DS/Atoms/Checkbox";
import Banner from "Modules/DS/Banner";
import { PreviewSection } from "Views/UploadInvoice/InvoiceForm/PreviewModal/types";
import { Steps } from "antd";

import moment from "moment";

import stylePreviewImageUploaded from "../../UploadInvoice.module.scss";
import style from "./PreviewModal.module.scss";
import { currencyFormatterV2, fallbackString } from "utility";
import { useSelector } from "react-redux";
import { RootState } from "Redux/ConfigureStore";

export interface PreviewModalItem {
  key: string;
  label: string;
  value: any;
  options?: { label: string; value: string }[];
  isHighlight?: boolean;
  prevValue?: string;
  orderNumber: number;
}

export interface StatusJourney {
  label: string;
  timestamp?: string;
  isFinished?: boolean;
  isSelfApprovalPrevention?: boolean;
}

export interface PreviewModalProps {
  data: FormData;
  sections: PreviewSection[];
  statusJourney: StatusJourney[];
  statusJourneyDesc: React.ReactNode;
  isVendorEdited?: boolean;
  isConfirmSaveVendor: boolean;
  setIsConfirmSaveVendor: (value: boolean) => void;
  fxRateBannerRef: React.Ref<HTMLDivElement>;
  showFxRateBanner: boolean;
  sla: React.ReactNode;
}

export interface PreviewResponseStaticField {
  fieldName: string;
  fieldFormName: string;
  newValue: string;
  oldValue: string;
  orderNumber: number;
  isEdited: boolean;
  newOptionalValue: string;
  oldOptionalValue: string;
}

export interface PreviewResponseDynamicField extends Omit<PreviewResponseStaticField, "fieldName"> {
  combinationID: number;
  fieldID: number;
}

export interface DynamicFormData {
  id: number;
  fieldID: number;
  label: string;
  value: string;
  type: string;
  options?: {
    label: string;
    value: string;
  }[];
  orderNumber: number;
}

const PreviewContent: React.FC<PreviewModalProps> = ({
  data,
  sections,
  statusJourney,
  statusJourneyDesc,
  isVendorEdited,
  isConfirmSaveVendor = true,
  setIsConfirmSaveVendor = () => undefined,
  fxRateBannerRef,
  showFxRateBanner = false,
  sla,
}) => {
  const reversedJourney = [...statusJourney].reverse();
  const currentStep = reversedJourney.length - reversedJourney.findIndex((item) => item.isFinished) - 1;
  const [invoices, setInvoices] = useState<string[]>([]);

  const [walletCurrency] = useSelector((state: RootState) => [state.wallet?.data?.currency_code]);

  const dataAsObject = useMemo(() => {
    const obj = {};

    for (let [key, value] of (data as any).entries()) {
      obj[key] = value;
    }

    return obj as any;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const isNewVendor = useMemo(() => !dataAsObject.vendorID, [dataAsObject]);

  const isEdited = useMemo(() => {
    for (let section of sections) {
      for (let group of section.groups) {
        for (let field of group.fields) {
          if (field.isEdited) {
            return true;
          }
        }
      }
    }

    return false;
  }, [sections]);

  useEffect(() => {
    if (data) {
      getInvoices();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const getInvoices = () => {
    const container = document.getElementsByClassName(stylePreviewImageUploaded.left)[0];
    if (container) {
      // for PDF files
      const canvas: NodeListOf<HTMLCanvasElement> = container.querySelectorAll(".react-pdf__Page__canvas");
      // for image files
      const images: NodeListOf<HTMLImageElement> = container.querySelectorAll(".ant-image-img");

      const canvasUrls = Array.from(canvas).map((item) => item.toDataURL());
      const imageUrls = Array.from(images).map((item) => item.src);
      setInvoices([...canvasUrls, ...imageUrls]);
    }
  };

  const isShowVendorCheckbox = isNewVendor || isVendorEdited;

  const saveVendorMessage = useMemo(() => {
    if (isShowVendorCheckbox) {
      return {
        title: "Update Recipient Detail?",
        desc: `Changes were made to ${dataAsObject.beneficiaryName}'s payment details. This will not affect any payment that has been previously submitted.`,
      };
    }
    return {
      title: "New Recipient?",
      desc: `This recipient's details can be retrieved by anyone in your company who has access to Bill Payment.`,
    };
  }, [dataAsObject.beneficiaryName, isShowVendorCheckbox]);

  const formatValue = (value, type) => {
    switch (type) {
      case "paymentCurrency":
        return currencyFormatterV2(value, dataAsObject.currency);
      case "walletCurrency":
        return currencyFormatterV2(value, walletCurrency);
      default:
        return value;
    }
  };

  return (
    <div className={style.content}>
      <div className={style.contentBody}>
        <h3>Preview</h3>
        {showFxRateBanner && (
          <div ref={fxRateBannerRef} className={style.fxRateBanner}>
            <Banner
              type="attention"
              message={
                <div className={style.fxRateContainer}>
                  <div>
                    The current exchange rate of <strong>{dataAsObject.fxRate}</strong> has been applied
                  </div>

                  <div className={style.preview}>
                    <div className={style.previewItem}>
                      <div className={style.itemLabel}>Recipient gets</div>
                      <div className={style.itemValue}>{dataAsObject.payment}</div>
                    </div>
                    <div className={style.previewItem}>
                      <div className={style.itemLabel}>You send</div>
                      <div className={style.itemValue}>{dataAsObject.senderPayment}</div>
                    </div>
                  </div>
                </div>
              }
            />
          </div>
        )}
        {isEdited && (
          <div className={style.previewLabel}>
            <div>
              <div className={style.previewHighlight} />
              <span>Updated</span>
            </div>
            <div>
              <div className={style.previewColor} />
              <span>Unchanged</span>
            </div>
          </div>
        )}

        {sections?.map((section) => (
          <div className={style.preview} key={section.label}>
            <h4>{section?.label}</h4>
            {section?.groups?.map((group) => {
              return (
                <div key={group.groupName}>
                  <div className={style.columnTwo}>
                    {group.fields.map((field) => {
                      return (
                        <div className={style.previewItem} key={field.label}>
                          <div className={style.itemLabel}>{field.label}</div>
                          {field.isEdited ? (
                            <div className={style.wrapText}>
                              {field.oldValue && (
                                <span className={style.itemPrevValue}>{formatValue(field.oldValue, field.type)}</span>
                              )}
                              <span className={style.itemHighlight}>{formatValue(field.newValue, field.type)}</span>
                            </div>
                          ) : (
                            <div className={style.itemValue}>
                              {fallbackString(formatValue(field.newValue, field.type), "-")}
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
            {section?.label === "Payment and Invoice Information" && Boolean(invoices.length) && (
              <div className={style.previewItem}>
                <div className={style.itemLabel}>Invoice(s)</div>
                <div className={style.invoices}>
                  {invoices.map((item, index) => (
                    <div key={index} className={style.invoicesImage} style={{ backgroundImage: `url(${item})` }} />
                  ))}
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
      <div className={style.contentSidebar}>
        {isShowVendorCheckbox && (
          <div className={style.confirm}>
            <h3>{saveVendorMessage.title}</h3>
            <div className={style.confirmContainer}>
              <Checkbox
                className={style.confirmCheckbox}
                checked={isConfirmSaveVendor}
                onClick={() => {
                  setIsConfirmSaveVendor(!isConfirmSaveVendor);
                }}
              />
              <div>
                <div className={style.confirmBold}>Save {dataAsObject.beneficiaryName}'s payment details</div>
                <div className={style.confirmDesc}>{saveVendorMessage.desc}</div>
              </div>
            </div>
          </div>
        )}
        <h3>Next Steps</h3>
        <p>{statusJourneyDesc}</p>
        {sla && <div className={style.sla}>{sla}</div>}
        <Steps progressDot current={currentStep} direction="vertical">
          {statusJourney.map((item, index) => (
            <Steps.Step
              key={index}
              title={item.label}
              description={
                item.timestamp ? (
                  <>
                    on <b>{moment(item.timestamp).format("DD MMM YYYY [at] hh:mm:ss")}</b>
                  </>
                ) : (
                  ""
                )
              }
            />
          ))}
        </Steps>
      </div>
    </div>
  );
};

export default PreviewContent;
