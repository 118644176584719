import classNames from "classnames";
import React from "react";
import { Card } from "@spenmo/splice";
import { ISectionProps } from "../type";

import styles from "./styles.module.scss";

const Section: React.FC<ISectionProps> = ({ children, additionalMarginButtom = false }) => {
  return (
    <Card
      elevation="level-2"
      radius="m"
      className={classNames(styles["transaction-detail__section"], {
        [styles["transaction-detail__additional-margin"]]: additionalMarginButtom,
      })}
    >
      {children}
    </Card>
  );
};

export default Section;
