import React from "react";

import { getCookieValue, cookieNames } from "utility/CookieHelper";

import { KYX_ORG_COUNTRY_LIST } from "Views/KYX/Constants";
import { InformationLoader as Loader } from "Views/KYX/Loader";

import { ManualMethod } from "./Manual";

export const PersonnelInformation = () => {
  let Component: JSX.Element;

  const countryCode = getCookieValue(cookieNames.ORG_COUNTRY_CODE);

  switch (countryCode) {
    case KYX_ORG_COUNTRY_LIST.IDR:
      Component = <ManualMethod />;
      break;
    case KYX_ORG_COUNTRY_LIST.SGD:
      Component = <ManualMethod />;
      break;
    default:
      Component = <Loader />;
      break;
  }

  return Component;
};
