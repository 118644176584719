import React, { useCallback, useContext, useEffect, useState } from "react";

import Menu from "Modules/DS/Menu";
import { FilterItem } from "Modules/DS/Filter";
import { POPOVER_WIDTH } from "Modules/DS/Popover";
import { DATE_RANGE, IMoreFilterProps } from "Modules/DS/Filter/@types";
import { AMOUNT_RANGE, ISelectContext, SelectContext } from "Modules/DS/Select";

import styles from "./More.module.scss";
import chipStyles from "Modules/DS/Chip/Title/Title.module.scss";

export const MoreFilter = <T extends object = any>({
  id,
  clearFilter,
  children,
  onApply,
  onClear,
  filters,
  clear = false,
  popoverWidth = POPOVER_WIDTH.XL,
  onClickOutside = () => null,
  containerClass,
  useDynamicPosition = true,
  positionAlgoType,
  ignoreClickOnElements,
  renderSpliceActionChip,
}: Omit<IMoreFilterProps<T>, "selected">) => {
  const _id = `more-filter-select__${id}`;
  const defaultValue = "More Filters";

  const { closeSelect } = useContext<ISelectContext>(SelectContext);

  const filtersLength = Object.values(filters).filter(Boolean).length;

  useEffect(() => {
    if (clear) {
      onClear();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clear]);

  const generateLabel = useCallback(() => {
    const keys = Object.values(filters);
    let count = filtersLength;

    if (keys.includes(DATE_RANGE.START) && keys.includes(DATE_RANGE.END)) {
      count -= 1;
    }

    if (keys.includes(AMOUNT_RANGE.MAX) && keys.includes(AMOUNT_RANGE.MIN)) {
      count -= 1;
    }

    let label: string;

    if (count > 0) {
      label = defaultValue + ` (${count})`;
    } else {
      label = defaultValue;
    }

    return { label, length: count };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersLength]);

  useEffect(() => {
    if (filtersLength !== 0) {
      const { label, length } = generateLabel();

      setTitle(label);
      setSelected(length !== 0);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersLength]);

  const [selected, setSelected] = useState(false);

  const [title, setTitle] = useState<string>(defaultValue);

  const onApplyHandler = () => {
    const { length, label } = generateLabel();

    setTitle(label);
    setSelected(length !== 0);
    onApply(filters);
  };

  const onClearFilter = (): void => {
    setTitle(defaultValue);
    setSelected(false);
    closeSelect();
    clearFilter();
  };

  const renderPopoverContent = () => {
    return (
      <div className={styles.container}>
        <div className={styles.content}>{children}</div>
        <Menu.Actions onClear={onClear} onApply={onApplyHandler} />
      </div>
    );
  };

  return (
    <FilterItem
      id={_id}
      title={title}
      tooltipText={title}
      selected={selected}
      clearFilter={onClearFilter}
      popoverWidth={popoverWidth}
      onClickOutside={onClickOutside}
      iconClass={chipStyles.add__icon}
      children={renderPopoverContent()}
      useDynamicPosition={useDynamicPosition}
      containerClass={containerClass}
      positionAlgoType={positionAlgoType}
      ignoreClickOnElements={ignoreClickOnElements}
      renderSpliceActionChip={renderSpliceActionChip}
    />
  );
};
