import * as React from "react";
import { useHistory } from "react-router-dom";

import Button from "Modules/button";

import "./index.scss";

const Header = () => {
  const history = useHistory();

  const viewObject = { title: "Add Employees", button: { text: "Back to Employees", action: history.goBack } };

  return (
    <div className="add-employee__header-container">
      <Button className="add-employee__header--back-button" textButton action={viewObject.button.action}>
        {viewObject.button.text}
      </Button>
      <h1>{viewObject.title}</h1>
    </div>
  );
};

export default Header;
