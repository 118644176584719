import React from "react";
import { Table } from "antd";
import s from "../Analytics.module.scss";
import "../Analytics.scss";
import { renderTeamsColumns } from "../Elements/TeamsDistribution/TeamsColumns";
import { TeamShimmer } from "./Shimmer";
import { EmptyState } from "Views/Analytics/EmptyState";
import { IAnalyticsFilter, IRootDataType, ISpendingTeamsData } from "Views/Analytics/@types";
import classNames from "classnames";
import { AnalyticsCardTitle } from "../Analytics";

export interface TotalTeamCategories {
  categoryName: string;
  amount: any;
  color: any;
}
interface ISpendingTeam {
  spendingTeamsLoading: boolean;
  spendingTeamsData: ISpendingTeamsData[];
  totalTeamCategories: TotalTeamCategories[];
  colorMap: any;
  totalTeamAmount: number;
  filter: IAnalyticsFilter;
  handleApplyDeeplink: (rootData: IRootDataType) => void;
}

export const Teams = ({
  filter,
  spendingTeamsLoading,
  spendingTeamsData,
  totalTeamCategories,
  colorMap,
  totalTeamAmount,
  handleApplyDeeplink,
}: ISpendingTeam) => {
  if (spendingTeamsLoading) {
    return <TeamShimmer loading={spendingTeamsLoading} />;
  }
  if (!spendingTeamsLoading && Array.isArray(spendingTeamsData) && !spendingTeamsData.length) {
    return <EmptyState />;
  }

  const onClickRow = (data: ISpendingTeamsData): void => {
    handleApplyDeeplink({
      from: AnalyticsCardTitle.BUDGETS,
      data: {
        selectedTeam: data?.teamId,
      },
    });
  };

  return (
    <Table
      columns={renderTeamsColumns(totalTeamCategories, colorMap, totalTeamAmount, filter)}
      dataSource={spendingTeamsData}
      pagination={false}
      scroll={{ y: "calc(100%-2rem)" }}
      className={classNames(s.tableCSS, "analytics-table")}
      onRow={(record) => ({
        onClick: () => onClickRow(record),
      })}
    />
  );
};
