import * as React from "react";
import { useContext } from "react";
import classNames from "classnames";
import { trackEvent } from "utility/analytics";

import Icon from "Modules/icons";
import { ModalContext } from "Modules/DS/Modal";

import { ICloseModalProps, IModalContext } from "Modules/DS/Modal/types";

import { darkGreyCloseIcon } from "assets/img";
import styles from "./CloseButton.module.scss";

export const CloseButton = ({ fixToTop = false, src = darkGreyCloseIcon, analytics, className }: ICloseModalProps) => {
  const { onClose } = useContext<IModalContext>(ModalContext);

  const fixToTopCn = fixToTop ? styles["fix-to-top"] : "";

  const handleAction = () => {
    if (analytics) {
      const { enabled = false, eventName } = analytics;
      enabled && trackEvent(eventName);
    }
    onClose();
  };

  return (
    <Icon
      src={src}
      alt="close"
      action={handleAction}
      data-testid="modal close"
      className={classNames(styles.close, fixToTopCn, className)}
    />
  );
};
