import React from "react";
import PropTypes from "prop-types";

import Item from "Views/TransactionsListing/Pending/Transaction/Detail/Item";
import { findDynamicFormAlias, renderDate } from "utility/Bills";
import { currencyFormatterV2 } from "utility";
import { paymentPurposes } from "constants/PaymentPurposes";

const Transaction = ({ data }) => {
  const findAlias = findDynamicFormAlias(data);

  const selectedPaymentPurpose = paymentPurposes.find((item) => item.value === data?.beneficiary?.paymentPurpose);

  return (
    <>
      <Item title="Invoice number">{data?.invoiceNumber || "-"}</Item>
      <Item title="Transaction Reference Number">{data?.transactionCode || "-"}</Item>
      <Item title="Bill due date">{data?.dueDate ? renderDate(data?.dueDate) : "-"}</Item>
      <Item title="Payment Schedule">
        {data?.paymentScheduleTime && data?.paymentScheduleTime !== "-"
          ? renderDate(data?.paymentScheduleTime)
          : "Pay Immediately"}
      </Item>
      <Item title="Bill issued date">{data?.issuedDate ? renderDate(data?.issuedDate) : "-"}</Item>
      {data?.currency !== data?.walletCurrency && <Item title="Exchange Rate">{data?.fxRate || "-"}</Item>}
      {data?.showTransferFee && (
        <Item title="Transfer Fee">
          {data?.transferFee
            ? `${currencyFormatterV2(data?.transferFee + data?.swiftPaymentChargeFee, data?.walletCurrency)}`
            : "-"}
        </Item>
      )}
      <Item title="Requestor">{data?.requestor || "-"}</Item>
      <Item title="Payment Purpose">{selectedPaymentPurpose?.label || findAlias("paymentPurpose") || "-"}</Item>
      <Item title="Budget">{data?.walletName || "-"}</Item>
    </>
  );
};

Transaction.propTypes = { data: PropTypes.object };

export default Transaction;
