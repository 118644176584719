import APIclient from "API/Client";
import { updateTeam } from "./index";
import { GetFormData } from "../../utility/APIWrapper";
import { userInitialState } from "../InitialState";
import { trackEvent } from "utility/analytics";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";

export const FETCH_SEND_MONEY_REQUEST = "FETCH_SEND_MONEY_REQUEST";
export const FETCH_SEND_MONEY_SUCCESS = "FETCH_SEND_MONEY_SUCCESS";
export const FETCH_SEND_MONEY_FAILURE = "FETCH_SEND_MONEY_FAILURE";
export const CLEAR_FETCH_SEND_MONEY = "CLEAR_FETCH_SEND_MONEY";
export const FETCH_MONEY_REQUEST = "FETCH_MONEY_REQUEST";
export const FETCH_MONEY_SUCCESS = "FETCH_MONEY_SUCCESS";
export const FETCH_MONEY_FAILURE = "FETCH_MONEY_FAILURE";

const fetchSendDataReq = (data = userInitialState) => {
  return {
    type: FETCH_SEND_MONEY_REQUEST,
    data: data,
  };
};

const fetchSendDataSuccess = (data) => {
  return {
    type: FETCH_SEND_MONEY_SUCCESS,
    data: data,
  };
};

const fetchSendDataError = (data) => {
  return {
    type: FETCH_SEND_MONEY_FAILURE,
    data: data,
  };
};

export const clearFetchSendMoney = () => {
  return {
    type: CLEAR_FETCH_SEND_MONEY,
  };
};

const fetchDataReq = (data = userInitialState) => {
  return {
    type: FETCH_MONEY_REQUEST,
    data: data,
  };
};

const fetchDataSuccess = (data) => {
  return {
    type: FETCH_MONEY_SUCCESS,
    data: data,
  };
};

const fetchDataError = (data) => {
  return {
    type: FETCH_MONEY_FAILURE,
    data: data,
  };
};

const actionTypes = {
  REQUEST: "request",
  SEND: "send",
};

const trackSendRequestMoneyActions = (trackActions, actionType, success, eventData = {}) => {
  const eventName = `${actionType} money confirm ${success ? "success" : "fail"}`;
  trackActions && trackEvent(eventName, { ...eventData });
};

export const sendFunc = (bodyData, trackActions = false, eventData = {}) => {
  const submitData = GetFormData(bodyData);
  return (dispatch) => {
    dispatch(fetchSendDataReq());
    APIclient.postData("/api/v1/fund/send", submitData)
      .then((response) => {
        const { data } = response || {};
        dispatch(fetchSendDataSuccess(data));
        if (data?.status === HTTP_STATUS_CODE.OK) {
          trackSendRequestMoneyActions(trackActions, actionTypes.SEND, true, eventData);
        } else {
          trackSendRequestMoneyActions(trackActions, actionTypes.SEND, false, {
            ...eventData,
            "fail reason": data?.payload?.status_message || "",
          });
        }
      })
      .then(() => dispatch(updateTeam()))
      .catch((err) => {
        dispatch(fetchSendDataError(err));
        trackSendRequestMoneyActions(trackActions, actionTypes.SEND, false, {
          ...eventData,
          "fail reason": err || "",
        });
      });
  };
};
export const reqFunc = (bodyData, trackActions = false, eventData = {}) => {
  const submitData = GetFormData(bodyData);

  return (dispatch) => {
    dispatch(fetchDataReq());
    APIclient.postData("/api/v1/fund/request", submitData)
      .then((response) => {
        const { data } = response || {};
        dispatch(fetchDataSuccess(data));
        if (data?.status === HTTP_STATUS_CODE.OK) {
          trackSendRequestMoneyActions(trackActions, actionTypes.REQUEST, true, eventData);
        } else {
          trackSendRequestMoneyActions(trackActions, actionTypes.REQUEST, false, {
            ...eventData,
            "fail reason": data?.payload?.status_message || "",
          });
        }
      })
      .catch((err) => {
        dispatch(fetchDataError(err));
        trackSendRequestMoneyActions(trackActions, actionTypes.REQUEST, false, {
          ...eventData,
          "fail reason": err || "",
        });
      });
  };
};
