import React, { useState } from "react";
import { Link } from "react-router-dom";
import { errorIcon } from "assets/img";
import Button from "Modules/button";
import Icon from "Modules/icons";
import ContactForm from "Views/SpenmoSupport/ContactForm";

const UpdateProfileError = () => {
  const [contactSupport, setContactSupport] = useState(false);

  return (
    <div className={"update-profile-error"}>
      <Icon className={"status-icon"} src={errorIcon} alt={"error icon"} />
      <h4 className={"title"}>Cannot activate your Cards feature.</h4>
      <p className={"description"}>
        We found several issues during the data verification process. Please contact our Customer Support to resolve
        this issue.
      </p>
      <div className={"action-btns"}>
        <Link className={"back-to-home-link"} to={"/"}>
          Back to Homepage
        </Link>
        <Button rounded text={"Contact Us"} action={() => setContactSupport(true)} />
      </div>
      {contactSupport && <ContactForm onClose={() => setContactSupport(false)} />}
    </div>
  );
};

export default UpdateProfileError;
