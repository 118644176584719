import { BANK_NAME_FIELD_ID, BIC_TYPE } from "Modules/DynamicForm/constants";
import { DynamicFormContext } from "Modules/DynamicForm/store";
import { DynamicFormFieldProps } from "Modules/DynamicForm/types";
import React, { useContext, useState, useMemo, useEffect, useCallback } from "react";
import DynamicFormTextfield from "../../Textfield";
import { debounce } from "utility";
import Loading3Quaters from "Modules/loading/loading3Quaters";
import { Tooltip, TooltipArrowAlignmentEnum, TooltipPositionEnum } from "Modules/DS/Tooltip";
import Icon from "Modules/Icon";
import useLoading from "utility/useLoading";
import { useSelector } from "react-redux";
import { RootState } from "Redux/ConfigureStore";
import { verifyBIC } from "Modules/DynamicForm/api";
import { VerifyBICPayload, VerifyBICResponse } from "./types";
import style from "./BankValidation.module.scss";
import axios, { Canceler } from "axios";

const BankValidation = (props: DynamicFormFieldProps) => {
  const { id, alias, onChangeValue, fieldProps } = props;
  const { destinationCountry, form, fields, isNewDataFields } = useContext(DynamicFormContext);
  const orgDetail = useSelector((state: RootState) => state.b2bOrgDetailReducer?.data?.payload);

  const [bic, setBic] = useState<string>(),
    [verifyResponse, setVerifyResponse] = useState<VerifyBICResponse | undefined>(undefined),
    [isAPICallFailed, setIsAPICallFailed] = useState(false),
    [isTooltipAutoShown, setIsTooltipAutoShown] = useState(false),
    [customError, setCustomError] = useState<string | undefined>(undefined);

  const [_verifyBIC, isLoading] = useLoading(verifyBIC, undefined, {}, false);
  let cancel: Canceler;

  const handleChange = debounce((id: number, value: string) => {
    setBic(undefined);
    setIsAPICallFailed(false);
    setIsTooltipAutoShown(false);
    setVerifyResponse(undefined);
    onChangeValue?.(id, value);
    setCustomError(undefined);
    if (!eligibleToVerify(value)) return;
    setBic(value);
  }, 500);

  const bankNameField = fields[BANK_NAME_FIELD_ID];
  // only for new bank (text field)
  useEffect(() => {
    if (!verifyResponse) return;

    // whenever the bank name change, we need to verify it
    const onChangeBankName = setTimeout(onVerify, 1000);

    return () => {
      clearTimeout(onChangeBankName);
      cancel && cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.getFieldValue(["dynamicFields", bankNameField?.id.toString()])]);

  const newVerifyBICPayload = (): VerifyBICPayload => {
    const bicType = BIC_TYPE[alias];
    const bankFieldPath = ["dynamicFields", fields[BANK_NAME_FIELD_ID]?.id.toString()];
    const bankValue = form.getFieldValue(bankFieldPath);
    const isNewBank = isNewDataFields[BANK_NAME_FIELD_ID];

    const payload: VerifyBICPayload = {
      bicType,
      bic,
      senderCountry: orgDetail.countryCode,
      receiverCountry: destinationCountry,
    };

    if (bankValue) {
      if (isNewBank) payload.bankName = bankValue;
      else payload.spenmoCode = bankValue;
    }

    return payload;
  };

  const onVerify = async () => {
    setIsAPICallFailed(false);

    try {
      const payload = newVerifyBICPayload();
      const { data } = await _verifyBIC(payload, {
        cancelToken: new axios.CancelToken((canceler) => {
          cancel = canceler;
        }),
      });
      setVerifyResponse(data.payload);
    } catch (e) {
      if (axios.isCancel(e)) {
        return;
      }
      setIsAPICallFailed(true);
      setVerifyResponse(undefined);
      console.error(e.message);
    }
  };

  const eligibleToVerify = useCallback(
    (code: string): boolean => {
      if (!code) return false;

      const errors = form.getFieldError(["dynamicFields", id.toString()]);
      if (errors.length > 0) return false;

      // add one more layer regex validation to validate code
      // in case the react fails to validate due to race condition
      const inputRegex = new RegExp(fieldProps.regex);
      if (!inputRegex.test(code)) return false;

      const bankFieldPath = ["dynamicFields", fields[BANK_NAME_FIELD_ID]?.id.toString()];
      const hasBankValue = Boolean(form.getFieldValue(bankFieldPath));
      if (!hasBankValue) {
        form.setFields([
          {
            name: bankFieldPath,
            errors: ["This field cannot be empty"],
          },
        ]);
      }

      return hasBankValue;
    },
    [fields, id, form, fieldProps]
  );

  useEffect(() => {
    if (!bic) return;

    onVerify();
    return () => {
      cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bic]);

  useEffect(() => {
    if (verifyResponse && !verifyResponse.isMatch) {
      setIsTooltipAutoShown(true);

      const onCloseTooltip = setTimeout(() => {
        setIsTooltipAutoShown(false);
      }, 3000);

      return () => clearTimeout(onCloseTooltip);
    }
  }, [verifyResponse]);

  const inputSuffix = useMemo(() => {
    if (isLoading) return <Loading3Quaters color="orange" />;
    if (verifyResponse) {
      if (verifyResponse.isMatch) {
        return <Icon data-testid="success-icon" icon="check" size="24" className={style.successIcon} />;
      } else {
        if (alias === "ibanCode" && verifyResponse.code === 4008) {
          setCustomError(verifyResponse.message);
        } else {
          return (
            <Tooltip
              data-testid="tooltip-icon"
              position={TooltipPositionEnum.LEFT}
              alignArrow={TooltipArrowAlignmentEnum.CENTER}
              text={verifyResponse.message}
              showWithoutHover={isTooltipAutoShown}
            >
              <Icon
                data-testid="tooltip-icon"
                icon="suggestion"
                size={24}
                className={style.suggestionIcon}
                svgAttr={{ viewBox: "0 0 24 24" }}
              />
            </Tooltip>
          );
        }
      }
    }

    return <></>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, verifyResponse, isTooltipAutoShown]);

  const validationStatus = customError ? "error" : props.validateStatus;
  return (
    <div className={style.bankValidation}>
      <DynamicFormTextfield
        {...props}
        validateStatus={validationStatus}
        help={customError}
        suffix={inputSuffix}
        onChangeValue={handleChange}
      />
      {isAPICallFailed && (
        <div className={style.validatedFailed}>
          This code cannot be validated now.
          <div className={style.tryAgain} onClick={onVerify}>
            Try again
          </div>
        </div>
      )}
    </div>
  );
};

export default BankValidation;
