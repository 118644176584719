import React from "react";
import cn from "classnames";
import { RootState } from "Redux/ConfigureStore";
import { useAppSelector } from "hook";
import { PRODUCT_NAME } from "Redux/ModularProduct";
import { CARD_VENDOR_TYPE } from "constants/QuickAccess.constant";
import { getIsCardVendorNium } from "customHooks/Card";
import { cookieNames, getCookieValue } from "utility/CookieHelper";
import useCheckOrgConfigs from "customHooks/useCheckOrgConfigs";

import Header from "Views/Home/SaasOnly/Header";
import PendingActions from "Views/Home/Components/PendingActions";
import UnlockPayments from "Views/Home/Components/UnlockPayments";
import LimitModelExpenseCarousel from "Views/Home/Components/LimitModelExpenseCarousel";
import WalletModelExpenseCarousel from "Views/Home/Components/WalletModelExpenseCarousel";
import SaasOnlyNonAdmin from "Views/Home/SaasOnly/NonAdmin";

import { KYB_STATUS } from "Views/KYX/Constants";
import { USER_ROLE } from "constants/Team.constant";

import styles from "./index.module.scss";

const SaasOnly: React.FC = () => {
  const { name: userName } = useAppSelector((state: RootState) => state?.userInfoReducer?.data?.payload?.user || "");

  const kybStatus: KYB_STATUS = getCookieValue(cookieNames.KYB_STATUS);
  const isAdmin = getCookieValue(cookieNames.USER_COMPANY_ROLE) === USER_ROLE.ADMIN;
  const isCardVendorNone = useCheckOrgConfigs(PRODUCT_NAME.CARD_VENDOR) === CARD_VENDOR_TYPE.NONE;
  const isCardVendorNium = getIsCardVendorNium();
  const isPaymentForBills = useCheckOrgConfigs("payment_for_bills");
  const isPaymentForCR = useCheckOrgConfigs("payment_for_reimbursements");

  const isLimitModel = isCardVendorNium || isCardVendorNone;

  // enabled payment user want to enabled payment for bills / reimbursement ( by org config), but haven't finished the KYB process
  const enabledPayment = isPaymentForBills || isPaymentForCR;
  const isUnlockedPaymentsVisible = isAdmin && kybStatus !== KYB_STATUS.APPROVED && enabledPayment;

  if (!isAdmin) {
    return <SaasOnlyNonAdmin isLimitModel={isLimitModel} isCardVendorNone={isCardVendorNone} userName={userName} />;
  }

  return (
    <div className={styles.home}>
      <Header userName={userName} />
      {isLimitModel ? <LimitModelExpenseCarousel /> : <WalletModelExpenseCarousel />}

      <div className={cn(styles.section, { [styles.oneSectionColumn]: !enabledPayment })}>
        <PendingActions isModular={isCardVendorNone} />
        {isUnlockedPaymentsVisible && <UnlockPayments />}
      </div>
    </div>
  );
};

export default SaasOnly;
