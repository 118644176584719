import { useEffect, useState } from "react";
import { BILL_MENU, CENTRALISED_APPROVAL_MENU, ANALYTICS_MENU } from "Permission/Actions";
import { CASBIN_PAGES } from "Permission/Pages";
import usePermissionCheck from "Permission/usePermissionCheck";

const permissions = [
  { object: CASBIN_PAGES.BILL_MENU, action: BILL_MENU.READ },
  { object: CASBIN_PAGES.CENTRALISED_APPROVAL_MENU, action: CENTRALISED_APPROVAL_MENU.READ },
  { object: CASBIN_PAGES.ANALYTICS_MENU, action: ANALYTICS_MENU.READ },
];

/**
 * Custom hook to check navigation permissions for menu items.
 *
 * @returns {{
 *   loading: boolean,
 *   isAllowedBillPage: boolean,
 *   isAllowedCentralisedApproval: boolean,
 *   isAllowedAnalytics: boolean,
 * }}
 */

// to store initial response (permissions) for use to prevent glitch due to continous Casbin API call
let persistedValue = [] as boolean[];

export const useNavigationPermission = () => {
  const [menuPermissions, loading] = usePermissionCheck(permissions, true) as [boolean[], boolean];

  // we will keep the state value as persistedValue to avoid glitch due to Casbin API call (refetch)
  const [persistedMenuPermissions, setPersistedMenuPermissions] = useState(persistedValue);

  useEffect(() => {
    // comparing the persisted value with new response from Casbin API call
    if (
      JSON.stringify(menuPermissions) !== JSON.stringify(persistedValue) &&
      menuPermissions.length === permissions.length
    ) {
      persistedValue = [...menuPermissions];
    }
  }, [menuPermissions]);

  useEffect(() => {
    // we will modify the state only when persistedValue and menuPermissions values are not same
    setPersistedMenuPermissions(persistedValue);
  }, [persistedValue]);

  return {
    loading: loading,
    isAllowedBillPage: persistedMenuPermissions[0],
    isAllowedCentralisedApproval: persistedMenuPermissions[1],
    isAllowedAnalytics: persistedMenuPermissions[2],
  };
};
