import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getCookieValue, cookieNames } from "utility/CookieHelper";
import { getTeamsExpensesList, resetTeamsExpensesListData } from "Redux/Actions/Teams/ExpensesListAction";
import { fetchTeamExpensesListData } from "Redux/Reducers/Teams/ExpensesListReducer";

export const LIMIT_TO_FETCH = 5;
const INITIAL_PAGE_COUNT = 0;

const flattenTeamListData = (teams) => {
  if (Array.isArray(teams) && teams.length) {
    const orgCurrencyCode = getCookieValue(cookieNames.CURRENCY_CODE);
    return teams.map((team) => ({
      teamName: team?.team_name || "",
      teamOrg: team?.team_type || "",
      remainingTeamLimit: team?.remaining?.remaining_monthly_limit?.toString() || "",
      totalTeamLimit: team?.limit?.monthly_limit?.toString() || "",
      currencyCode: team?.limit?.currency_code || orgCurrencyCode || "",
      totalExpense: team?.expenses_data?.expenses_this_month?.toString() || "",
      id: team?.id || "",
    }));
  }
  return [];
};

export const useFetchTeamList = (limit = LIMIT_TO_FETCH) => {
  const dispatch = useDispatch();
  const {
    data: teamListData,
    loading: teamListDataLoading,
    error: teamListDataError,
  } = useSelector(fetchTeamExpensesListData);

  const [teamList, setTeamList] = useState([]);
  const [page, setPage] = useState(INITIAL_PAGE_COUNT);
  const [totalTeams, setTotalTeams] = useState(null);

  const processTeamListData = () => {
    if (teamListDataLoading) return;
    const { teams = [], total_count } = teamListData?.payload || {};
    const flattenTeamData = flattenTeamListData(teams);
    if (flattenTeamData.length) {
      setTeamList((prevState) => [...prevState, ...flattenTeamData]);
      setPage((prevPage) => prevPage + 1);
      setTotalTeams(total_count);
      dispatch(resetTeamsExpensesListData());
    }
  };

  const fetchMoreTeams = () => {
    if (teamList.length < totalTeams && !teamListDataLoading) {
      dispatch(getTeamsExpensesList(true, limit, page));
    }
  };

  useEffect(() => {
    dispatch(getTeamsExpensesList(true, limit, page));
    return () => {
      dispatch(resetTeamsExpensesListData());
      setTeamList([]);
    };
  }, []);

  useEffect(() => {
    processTeamListData();
  }, [teamListData]);

  return [teamList, fetchMoreTeams, teamListDataLoading, teamListDataError];
};
