import useSWR from "swr";
import { getData } from "API/Client";
import { GetOrgId } from "utility";
import { getKycStatus } from "utility/Card";

export const useTeamWithMembers = () => {
  return useSWR(
    `/api/v1/org/${GetOrgId()}/teams-with-members`,
    (url) =>
      getData(url).then(({ data }) => {
        return data?.payload?.teams?.map((team) => ({
          id: team.id,
          teamName: team.name,
          members: team.members?.map((member) => ({
            id: member?.user_id,
            name: member?.name,
            kycStatus: getKycStatus(member?.kyc_status),
          })),
        }));
      }),
    {
      fallbackData: [],
    }
  );
};
