import { getCookieValue, cookieNames } from "utility/CookieHelper";

import { ITrxnTypeOption } from "Views/Transaction/@types";

export const getTrxnTypeOptions = (): ITrxnTypeOption[] => {
  const showCardExpenseFilter = () => {
    const cardVendor: string = getCookieValue(cookieNames.USER_CARD_VENDOR);

    return !(cardVendor === "" || cardVendor === null || cardVendor === undefined);
  };

  return [
    {
      id: "billpay_expense",
      title: "Bill Pay Expense",
      enabled: true,
    },
    {
      id: "card_expense",
      title: "Card Expense",
      enabled: showCardExpenseFilter(),
    },
    {
      id: "cash_reimbursement",
      title: "Reimbursement",
      enabled: true,
    },
    {
      id: "Topup",
      title: "Topup",
      enabled: true,
    },
    {
      id: "Incentive",
      title: "Incentive",
      enabled: true,
    },
    {
      id: "fees",
      title: "Fees",
      enabled: true,
    },
    {
      id: "Others",
      title: "Others",
      enabled: true,
    },
  ].filter((item) => item.enabled);
};
