import React, { forwardRef, useImperativeHandle, useRef } from "react";
import { CheckboxChangeEvent } from "antd/lib/checkbox";

import Checkbox from "../Checkbox";
import { useBillForm } from "Views/Bills/V2/context/FormContext";
import { CheckboxProps } from "../type";
import { FieldConsumerType } from "Views/Bills/V2/BillForm/type";
import { RecipientFormContext } from "Views/Bills/V2/Recipient/types";

import styles from "Views/Bills/V2/components/FormGenerator/FormGenerator.module.scss";

export interface AdditionalBankIdentifierProps extends Omit<CheckboxProps, "onChange">, FieldConsumerType {
  onChange?: (evt: CheckboxChangeEvent, isEnableRefetch: boolean) => void;
}

const AdditionalBankIdentifier = forwardRef((props: AdditionalBankIdentifierProps, ref) => {
  const checkboxRef = useRef(null);
  useImperativeHandle(ref, () => checkboxRef.current);

  const { onChange, textLabel } = props;

  const { setRefetchDynamicFields } = useBillForm<RecipientFormContext>();

  const handleChange = (value) => {
    const checked = value.target.checked;

    onChange(value, checked);

    if (!checked) {
      setRefetchDynamicFields({
        "dynamicFields.beneficiaryBankName": true,
      });
    }
  };

  return (
    <Checkbox
      {...props}
      onChange={handleChange}
      ref={checkboxRef}
      textLabel={<b className={styles.boldText}>{textLabel}</b>}
    />
  );
});

export default AdditionalBankIdentifier;
