import { FieldValues } from "react-hook-form";

import { omitKey } from "Views/Bills/V2/utilities";

import { RecipientFormInputs } from "./types";
import { BasicFieldGenerator, FieldGenerator, OCRData } from "Views/Bills/V2/BillForm/type";
import { DynamicFormIdentifier } from "Views/Bills/V2/hooks/useRecipientFormFields/types";

const parseValue = (value: any) => {
  if (typeof value === "undefined") {
    return undefined;
  }
  return String(value);
};

export const getRecipientPayload = (
  fieldValue: FieldValues,
  dynamicFieldsIdentifier: Record<string, DynamicFormIdentifier>,
  recipientSelectedID?: number
) => {
  if (!Object.keys(dynamicFieldsIdentifier).length || typeof fieldValue?.dynamicFields !== "object") {
    return;
  }

  const parsedDynamicFields = [];

  Object.entries(fieldValue?.dynamicFields).forEach(([key, value]) => {
    const identifier = dynamicFieldsIdentifier[`dynamicFields.${key}`];

    if (identifier) {
      parsedDynamicFields.push({
        id: identifier.id,
        value: parseValue(value),
      });
    }
  }, []);

  return {
    id: recipientSelectedID,
    source: "vendor_management",
    ...omitKey(fieldValue, "meta"),
    dynamicFields: parsedDynamicFields,
  };
};

const deepCheckEmails = (emailList: string[], checkEmail: string[]) => {
  if (!emailList) {
    return false;
  }

  let isSame = true;

  emailList.forEach((email) => {
    if (!checkEmail.includes(email)) {
      isSame = false;
    }
  });

  return isSame;
};

export const generateRecipientFields = (fieldList: any[] = [], ocrData: OCRData, formValues: RecipientFormInputs) => {
  // comparing value based on name
  const compareFieldWithOCR = (name: string) => {
    switch (name) {
      case "dynamicFields.beneficiaryBankName": {
        const options = fieldList.find((item) => item.name === name)?.fieldProps?.options;
        const ocrBankNameValue = ocrData?.recipientData?.[name];

        const { label, value } = options.find((item) => item.value === formValues?.[name]) || {};

        // select from options
        if (label && value) {
          return [label.toLowerCase(), value.toLowerCase()].includes(ocrBankNameValue.toLowerCase());
        } else {
          // input field (add new recipient bank)
          return formValues?.[name] === ocrData?.recipientData?.[name];
        }
      }
      case "dynamicFields.recipientEmail": {
        const emailFormValues = formValues?.[name];
        const ocrEmails = ocrData?.recipientData?.[name]?.split(",");

        return deepCheckEmails(emailFormValues, ocrEmails);
      }
      default: {
        return formValues?.[name] === ocrData?.recipientData?.[name];
      }
    }
  };

  const fields = fieldList.map((field: FieldGenerator) => {
    const data = { ...field };
    const { name } = data;

    /**
     * Generated OCR Props is used for straightforward field
     * and for special fields (Ex: field with fieldsGroup)
     * @param name Bill details field key
     */
    const generateOCRProps = (name: string, field?: BasicFieldGenerator) => {
      // reset ocr related data
      (field || data).withOCR = undefined;
      (field || data).isSameWithOCR = undefined;

      // IMPROVEMENT: OCR Data should not be mapped by FE
      // rather it should be called when calling the Dynamic API

      if (ocrData?.recipientData && name in ocrData.recipientData) {
        (field || data).withOCR = true;
        (field || data).isSameWithOCR = compareFieldWithOCR(name);
      }

      return field;
    };

    // for general data
    generateOCRProps(name);

    if ("fieldGroup" in data) {
      switch (name) {
        case "countryAndCurrency": {
          const [countryCode, currencyCode] = data.fieldGroup;

          data.withOCR = undefined;
          data.isSameWithOCR = undefined;

          if (ocrData?.recipientData && countryCode.name in ocrData.recipientData) {
            data.withOCR = true;
            data.isSameWithOCR = compareFieldWithOCR(countryCode.name) && compareFieldWithOCR(currencyCode.name);
          }
        }
      }
    }

    return data;
  });

  return fields;
};
