import { putData } from "API/Client";

import { appNotification, contactCSErrorMessage } from "Modules/appNotification/appNotification";

import { API_URL } from "Views/Reimbursement/DataCalls/Services";

const useMakePayment = (): {
  makePayment: (id: string) => Promise<void>;
} => {
  const makePayment = async (id: string) => {
    try {
      const response = await putData(API_URL.DETAILS.MAKE_PAYMENT(id));

      const { message } = response.data.payload;

      setTimeout(() => {
        appNotification.success({ message });
      }, 6000);
    } catch (err) {
      setTimeout(() => {
        appNotification.error(contactCSErrorMessage(err.response.data.error.code));
      }, 6000);
    }
  };

  return {
    makePayment,
  };
};

export default useMakePayment;
