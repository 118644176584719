import { CARDS_TRXNS_TABLE_LIMIT } from "Views/Transaction/Constants/Cards";
import {
  ICardsTrxnsSuccessData,
  IGetCardsTrxnsData,
  IGetCardsTrxnsRequest,
  CARD_TXN_STATUS,
  ICardTrxnDetailsSuccessData,
} from "Views/Transaction/@types";

export const initialCardsTrxnsData: IGetCardsTrxnsData = {
  id: "",
  transaction_number: "",
  created_at: "",
  posted_date: "",
  cardholder_name: "",
  team_name: "",
  simplified_merchant_name: "",
  merchant: "",
  settled_date: "",
  card_name: "",
  card_last_four: "",
  amount: "",
  amount_currency_code: "",
  receiving_currency_amount: "",
  receiving_currency_amount_currency_code: "",
  txn_status: CARD_TXN_STATUS.PENDING,
  fx_fee: "",
  fx_type: "",
  fx_rate: "",
};

export const dummyCardsTrxns: IGetCardsTrxnsData[] = Array.from({ length: CARDS_TRXNS_TABLE_LIMIT }).map(
  (_, index) => ({
    ...initialCardsTrxnsData,
    id: `DUMMY_TRXN_${index}`,
  })
);

export const initialCardsTrxnsSuccess: { [key: string]: ICardsTrxnsSuccessData } = {
  empty: {
    card_transactions: [],
    total_pg: 0,
    total: 0,
    current_pg: 0,
  },
  mocked: {
    card_transactions: dummyCardsTrxns,
    total_pg: 1,
    total: dummyCardsTrxns.length,
    current_pg: 1,
  },
};

export const initialGetTrxnRequest = (): IGetCardsTrxnsRequest => ({
  limit: CARDS_TRXNS_TABLE_LIMIT,
  pg: 1,
  start_date: null,
  end_date: null,
  card_last_four: [],
  cardholder_name: [],
  team_id: [],
  simplified_merchant_name: [],
});

export const initialCardTrxnDetailsData: ICardTrxnDetailsSuccessData = {
  final_state: initialCardsTrxnsData,
  life_cycle: [],
  receipts: {
    expense: {
      comment: "",
      expense_category_id: "",
      tax: { id: "", tax_name: "" },
      receipts: [],
      tags: [],
    },
    transaction: { transaction_number: "", transaction_id: "" },
  },
};
