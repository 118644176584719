import React from "react";

import { FilterItem } from "Modules/DS/Filter";
import { POPOVER_WIDTH } from "Modules/DS/Popover";
import { IFilterItem, IContainerBuilder, intitiaFilterItemProps } from "Modules/DS/Filter/@types";

// loading spinner on filter chip is hidden as per UX requirement
// import chipStyles from "Modules/DS/Chip/Title/Title.module.scss";

export const ContainerBuilder = ({
  children,
  header = null,
  footer = null,
  iconClass = "",
  filterItemProps = { ...intitiaFilterItemProps },
}: IContainerBuilder): JSX.Element => {
  const itemProps: Omit<IFilterItem, "children"> = {
    popoverWidth: POPOVER_WIDTH.S,
    useDynamicPosition: true,
    ...filterItemProps,
    ...(iconClass && { iconClass }),
    // loading spinner on filter chip is hidden as per UX requirement
    // ...(states.loading && { iconClass: chipStyles.loader_icon }),
  };

  return (
    <FilterItem {...itemProps}>
      {header}
      {children}
      {footer}
    </FilterItem>
  );
};
