import React from "react";

import classNames from "classnames";
import { useDrag } from "react-dnd";

import styles from "./index.module.scss";
import { IBillItem, IPaymentCycleData } from "Views/Bills/V2/PaymentRun/types";

interface IDraggableItem {
  children: React.ReactElement;
  bill: IBillItem;
  cycle: IPaymentCycleData;
}
const DraggableItem = ({ children, bill, cycle }: IDraggableItem) => {
  const [{ isDragging }, drag] = useDrag({
    type: "NODE",
    item: { bill, cycle },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const draggingStyle = classNames({ [styles.isDragging]: isDragging });
  return (
    <div ref={drag} className={draggingStyle}>
      {children}
    </div>
  );
};

export default DraggableItem;
