import React, { useEffect, useState } from "react";

import { IColumnData } from "Modules/DS/DataTable";

import { DropdownCell } from "Views/Transaction/List";
import { InputCell } from "Views/Transaction/Shimmer";
import { ALL_TRXNS_COLUMNS } from "Views/Transaction/Constants";
import { useCategoryUpdate } from "Views/Transaction/All/Update";
import { IGetTrxnResponse, GET_TRXN_RESPONSE_PARAMS, IDropdownCell } from "Views/Transaction/@types";
import { editNotAllowed, includesReversalTrxns, useHistoricalRefund } from "Views/Transaction/Helper";

export const CategoryCell = ({
  record,
  selectedRows,
  updatingCategories,
  isEditAllowed,
  loadingEditPermisson,
}: {
  record: IGetTrxnResponse;
  selectedRows: string[];
  updatingCategories: boolean;
  isEditAllowed: boolean;
  loadingEditPermisson: boolean;
}) => {
  const [loading, setLoading] = useState(false);

  const id: string = record.id;

  const { children, onClose, type, width } = useCategoryUpdate([id], setLoading);

  const { isRefundLoading, isHistoricalRefund } = useHistoricalRefund(
    record.id,
    record.transaction_number,
    record.transaction_type
  );
  const isFieldDisabled = !isEditAllowed || includesReversalTrxns(record.transaction_type);

  useEffect(() => {
    setLoading(selectedRows.includes(id) && updatingCategories);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatingCategories]);

  const props: IDropdownCell = {
    id,
    type,
    onClose,
    children,
    popoverWidth: width,
    value: record.category,
    title: ALL_TRXNS_COLUMNS.CATEGORY,
    loading: !isFieldDisabled && (loading || isRefundLoading || loadingEditPermisson),
    disabled: isFieldDisabled,
  };

  return editNotAllowed(record.transaction_type) || isHistoricalRefund ? (
    <InputCell>
      <p>N.A.</p>
    </InputCell>
  ) : (
    <DropdownCell {...props} />
  );
};

export const category = (
  selectedRows: string[],
  updatingCategories: boolean = false,
  isEditAllowed: boolean,
  loadingEditPermisson: boolean
): IColumnData<IGetTrxnResponse>["column"] => ({
  title: ALL_TRXNS_COLUMNS.CATEGORY,
  width: 152,
  key: GET_TRXN_RESPONSE_PARAMS.CATEGORY,
  dataIndex: GET_TRXN_RESPONSE_PARAMS.CATEGORY,
  render: (_, record: IGetTrxnResponse) => (
    <CategoryCell
      record={record}
      selectedRows={selectedRows}
      updatingCategories={updatingCategories}
      loadingEditPermisson={loadingEditPermisson}
      isEditAllowed={isEditAllowed}
    />
  ),
});
