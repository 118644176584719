import { useEffect, useState } from "react";
import { FormInstance } from "antd/lib/form";

import {
  FORM_NAME,
  KYX_FORMS_KEYS,
  IS_BUSINESS_ADDRESS_OPTIONS,
  IS_BUSINESS_ADDRESS_OPTIONS_LABEL,
  IS_BUSINESS_ADDRESS_OPTIONS_VALUE,
} from "Views/KYX/Constants/Forms";
import { FORM_FIELD_TYPES, DOCUMENT_STEP_TYPE, FORM_SECTION_COMPONENT } from "Views/KYX/Constants";
import {
  FORM_SOURCE_TYPE,
  IDocumentStep,
  IFetchFormSubmissionPayload,
  IFormElement,
  IGetFormConfigsPayload,
} from "Views/KYX/@types";
import { getValidationError, getValidationstatus } from "Views/KYX/Helper";

export const useCompanyAddressFormSGD = (
  form: FormInstance,
  formData: IFetchFormSubmissionPayload,
  remarks: IGetFormConfigsPayload,
  isFormDisabled: boolean
): IDocumentStep => {
  const isMyInfoSource: boolean = formData?.source === FORM_SOURCE_TYPE.MY_INFO;

  const REGISTERED_ADDRESS: IFormElement = {
    key: FORM_NAME.COMPANY_INFORMATION + KYX_FORMS_KEYS.COMPANY_ADDRESS + KYX_FORMS_KEYS.REGISTERED_ADDRESS,
    col: { span: 24 },
    element: {
      type: FORM_FIELD_TYPES.INPUT,
      props: {
        formProps: {
          name: [FORM_NAME.COMPANY_INFORMATION, KYX_FORMS_KEYS.COMPANY_ADDRESS, KYX_FORMS_KEYS.REGISTERED_ADDRESS],
          label: "Registered Address",
          required: true,
          rules: [
            {
              required: true,
              message: "Enter registered company address",
            },
            { whitespace: true, message: "This field cannot be empty" },
          ],
          validateStatus: getValidationstatus(remarks, [
            KYX_FORMS_KEYS.COMPANY_ADDRESS,
            KYX_FORMS_KEYS.REGISTERED_ADDRESS,
          ]),
          help: getValidationError(
            remarks,
            [KYX_FORMS_KEYS.COMPANY_ADDRESS, KYX_FORMS_KEYS.REGISTERED_ADDRESS],
            "Registered Address",
            FORM_NAME.COMPANY_INFORMATION
          ),
        },
        inputProps: {
          datatype: "string",
          placeholder: "Enter registered company address",
          disabled: isFormDisabled || isMyInfoSource,
        },
      },
    },
  };

  const POSTAL_CODE: IFormElement = {
    key: FORM_NAME.COMPANY_INFORMATION + KYX_FORMS_KEYS.COMPANY_ADDRESS + KYX_FORMS_KEYS.COMPANY_ADDRESS_POSTAL_CODE,
    col: { span: 12 },
    element: {
      type: FORM_FIELD_TYPES.INPUT,
      props: {
        formProps: {
          name: [
            FORM_NAME.COMPANY_INFORMATION,
            KYX_FORMS_KEYS.COMPANY_ADDRESS,
            KYX_FORMS_KEYS.COMPANY_ADDRESS_POSTAL_CODE,
          ],
          label: "Postal Code",
          required: true,
          rules: [
            {
              required: true,
              message: "Please enter the postal code",
            },
            { whitespace: true, message: "This field cannot be empty" },
          ],
          validateStatus: getValidationstatus(remarks, [
            KYX_FORMS_KEYS.COMPANY_ADDRESS + KYX_FORMS_KEYS.COMPANY_ADDRESS_POSTAL_CODE,
          ]),
          help: getValidationError(
            remarks,
            [KYX_FORMS_KEYS.COMPANY_ADDRESS + KYX_FORMS_KEYS.COMPANY_ADDRESS_POSTAL_CODE],
            "Postal Code",
            FORM_NAME.COMPANY_INFORMATION
          ),
        },
        inputProps: {
          datatype: "string",
          placeholder: "Enter postal code",
          disabled: isFormDisabled,
        },
      },
    },
  };

  const [showBusinessAddressInput, setShowBusinessAddressInput] = useState<boolean>(undefined);

  useEffect(() => {
    if (formData?.form?.company_information?.company_address?.business_address) {
      setShowBusinessAddressInput(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  const IS_BUSINESS_ADDRESS: IFormElement = {
    key: FORM_NAME.COMPANY_INFORMATION + KYX_FORMS_KEYS.COMPANY_ADDRESS + KYX_FORMS_KEYS.IS_BUSINESS_ADDRESS_DIFFERENT,
    col: { span: 24 },
    element: {
      type: FORM_FIELD_TYPES.CHECKLIST,
      props: {
        formProps: {
          name: [
            FORM_NAME.COMPANY_INFORMATION,
            KYX_FORMS_KEYS.COMPANY_ADDRESS,
            KYX_FORMS_KEYS.IS_BUSINESS_ADDRESS_DIFFERENT,
          ],
        },
        checkProps: {
          disabled: isFormDisabled,
          options: [
            {
              name: KYX_FORMS_KEYS.IS_BUSINESS_ADDRESS_DIFFERENT,
              label: IS_BUSINESS_ADDRESS_OPTIONS_LABEL[IS_BUSINESS_ADDRESS_OPTIONS.IS_COMPANY_ADDRESS],
              value: IS_BUSINESS_ADDRESS_OPTIONS_VALUE[IS_BUSINESS_ADDRESS_OPTIONS.IS_COMPANY_ADDRESS],
            },
          ],
          onChange: (e) => {
            setShowBusinessAddressInput(e[KYX_FORMS_KEYS.IS_BUSINESS_ADDRESS_DIFFERENT]);

            form.setFieldsValue({
              [FORM_NAME.COMPANY_INFORMATION]: {
                [KYX_FORMS_KEYS.COMPANY_ADDRESS]: {
                  [KYX_FORMS_KEYS.IS_BUSINESS_ADDRESS_DIFFERENT]: e.is_business_address,
                },
              },
            });

            if (!e.is_business_address) {
              form.setFieldsValue({
                [FORM_NAME.COMPANY_INFORMATION]: {
                  [KYX_FORMS_KEYS.COMPANY_ADDRESS]: {
                    [KYX_FORMS_KEYS.BUSINESS_ADDRESS]: undefined,
                    [KYX_FORMS_KEYS.BUSINESS_POSTAL_CODE]: undefined,
                  },
                },
              });
            }
          },
        },
      },
    },
  };

  const BUSINESS_ADDRESS: IFormElement = {
    key: FORM_NAME.COMPANY_INFORMATION + KYX_FORMS_KEYS.COMPANY_ADDRESS + KYX_FORMS_KEYS.BUSINESS_ADDRESS,
    col: { span: 24 },
    element: {
      type: FORM_FIELD_TYPES.INPUT,
      props: {
        formProps: {
          name: [FORM_NAME.COMPANY_INFORMATION, KYX_FORMS_KEYS.COMPANY_ADDRESS, KYX_FORMS_KEYS.BUSINESS_ADDRESS],
          label: "Business Address",
          required: true,
          dependencies: [KYX_FORMS_KEYS.IS_BUSINESS_ADDRESS_DIFFERENT],
          rules: [
            {
              required: showBusinessAddressInput,
              message: "Please enter your company's business address",
            },
            { whitespace: true, message: "This field cannot be empty" },
          ],
          validateStatus: getValidationstatus(remarks, [
            KYX_FORMS_KEYS.COMPANY_ADDRESS,
            KYX_FORMS_KEYS.BUSINESS_ADDRESS,
          ]),
          help: getValidationError(
            remarks,
            [KYX_FORMS_KEYS.COMPANY_ADDRESS, KYX_FORMS_KEYS.BUSINESS_ADDRESS],
            "Business Address",
            FORM_NAME.COMPANY_INFORMATION
          ),
          hidden: showBusinessAddressInput,
          style: { display: showBusinessAddressInput ? "block" : "none" },
        },
        inputProps: {
          datatype: "string",
          placeholder: "Enter your company's business address",
          disabled: isFormDisabled,
        },
      },
    },
  };

  const BUSINESS_POSTAL_CODE: IFormElement = {
    key: FORM_NAME.COMPANY_INFORMATION + KYX_FORMS_KEYS.COMPANY_ADDRESS + KYX_FORMS_KEYS.BUSINESS_POSTAL_CODE,
    col: { span: 12 },
    element: {
      type: FORM_FIELD_TYPES.INPUT,
      props: {
        formProps: {
          name: [FORM_NAME.COMPANY_INFORMATION, KYX_FORMS_KEYS.COMPANY_ADDRESS, KYX_FORMS_KEYS.BUSINESS_POSTAL_CODE],
          label: "Postal Code",
          dependencies: [KYX_FORMS_KEYS.IS_BUSINESS_ADDRESS_DIFFERENT],
          required: true,
          rules: [
            {
              required: showBusinessAddressInput,
              message: "Please enter postal code",
            },
            { whitespace: true, message: "This field cannot be empty" },
          ],
          validateStatus: getValidationstatus(remarks, [
            KYX_FORMS_KEYS.COMPANY_ADDRESS,
            KYX_FORMS_KEYS.BUSINESS_POSTAL_CODE,
          ]),
          help: getValidationError(
            remarks,
            [KYX_FORMS_KEYS.COMPANY_ADDRESS, KYX_FORMS_KEYS.BUSINESS_POSTAL_CODE],
            "Postal Code",
            FORM_NAME.COMPANY_INFORMATION
          ),
          hidden: showBusinessAddressInput,
          style: { display: showBusinessAddressInput ? "block" : "none" },
        },
        inputProps: {
          datatype: "string",
          placeholder: "Enter postal code",
          disabled: isFormDisabled,
        },
      },
    },
  };

  return {
    key: FORM_NAME.COMPANY_INFORMATION + KYX_FORMS_KEYS.COMPANY_ADDRESS,
    type: DOCUMENT_STEP_TYPE.DEFAULT,
    row: { gutter: 40 },
    container: {
      type: FORM_SECTION_COMPONENT.CARD,
      props: {
        number: 1.2,
        title: "Company Address",
      },
    },
    document: [
      REGISTERED_ADDRESS,
      !isMyInfoSource && POSTAL_CODE,
      IS_BUSINESS_ADDRESS,
      BUSINESS_ADDRESS,
      BUSINESS_POSTAL_CODE,
    ],
  };
};
