import { Calendar } from "assets/v1.1/icons/Core/Calendar";
import { Add } from "assets/v1.1/icons/Core/Add";
import { Search } from "assets/v1.1/icons/Core/Search";
import { Check } from "assets/v1.1/icons/Core/Check";
import { ChevronDown } from "./ChevronDown";
import { Trash } from "./Trash";
import { External } from "./External";
import { File } from "./File";
import { Info } from "./Info";
import { UploadSuccess } from "./UploadSuccess";
import { Accounting } from "./Accounting";

export const Core = {
  Add,
  Search,
  Calendar,
  Check,
  ChevronDown,
  Trash,
  External,
  File,
  Info,
  UploadSuccess,
  Accounting,
};
