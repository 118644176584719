import React from "react";
import { IGuidelinesData } from "Views/KYX/@types";

import { KYXBanner } from "Views/KYX/Elements/Banner";
import GuidelinesDocument from "Views/KYX/Modal/Guidelines/Document";

import styles from "./index.module.scss";

const GuidelinesContent: React.FC<IGuidelinesData> = ({ data }: IGuidelinesData): React.ReactElement => {
  const { title, subTitle, label, instructions, documents, banner } = data || {};

  return (
    <div className={styles.content}>
      <h3 className={styles.title}>{title}</h3>
      {subTitle && <h5 className={styles.text}>{subTitle}</h5>}
      {label && <label className={styles.text}>{label}</label>}

      <GuidelinesDocument instructions={instructions} documents={documents} />
      {banner && <KYXBanner {...banner} />}
    </div>
  );
};

export default GuidelinesContent;
