import React, { useEffect, useState } from "react";
import { Col, Row } from "antd";
import { useSelector, useDispatch } from "react-redux";
import classNames from "classnames";

import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import { xeroExpenseCodes } from "Redux/Actions";
import Loader from "Modules/loading";
import { appNotification } from "Modules/appNotification/appNotification";
import { XeroCategories } from "./XeroCategories";
import { importXeroCategories, getXeroCategoriesAccountNumbers } from "./helper";

import style from "./styles.module.scss";

const CategorySelection = ({ goToSettingPage }) => {
  const { loading: userInfoLoading, data: userInfoData } = useSelector((state: any) => state.userInfoReducer);
  const {
    xeroCodeLoading,
    xeroCodeData,
    xeroCategoryLoading: updateXeroCategoryLoading,
    xeroCategoryData,
    xeroCategoryError,
  } = useSelector((state: any) => state.xeroAccountReducer);
  const dispatch = useDispatch();

  const [categories, setCategories] = useState([]);
  const [importedCategories, setImportedCategories] = useState(false);
  const xeroCategories = xeroCodeData?.payload?.accounts.Expense.data || [];

  useEffect(() => {
    dispatch(xeroExpenseCodes());
  }, [dispatch]);

  useEffect(() => {
    const spenmoCategories = userInfoData?.payload?.categories;
    if (spenmoCategories && Array.isArray(spenmoCategories) && spenmoCategories.length && !categories.length) {
      setCategories(spenmoCategories);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfoData]);

  useEffect(() => {
    if (xeroCategoryError) {
      appNotification.error({
        message: xeroCategoryData?.status_message
          ? xeroCategoryData?.status_message
          : "Unable to able save. Please try again later",
      });
    } else if (xeroCategoryData?.status === HTTP_STATUS_CODE.OK) {
      openSettingPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [xeroCategoryData, xeroCategoryError]);

  const openSettingPage = () => {
    goToSettingPage({ message: "Integration complete! You can now set up your sync settings." });
  };

  return (
    <div>
      <div className={style.xeroCategory}>
        <Row justify="start">
          <Col span={24} className={classNames("text--h4", style.title)}>
            Map your categories
          </Col>
          <Col span={24} className={classNames("text--secondary", style.subtitle)}>
            We will sync transactions to Xero only when the categories are mapped to the chart of accounts.
          </Col>
        </Row>
        {xeroCodeLoading || userInfoLoading ? (
          <Loader className={style.loader} />
        ) : (
          <XeroCategories
            categories={categories}
            xeroCategoriesAccountNumbers={getXeroCategoriesAccountNumbers(xeroCategories)}
            xeroCategories={xeroCategories}
            onImport={() => {
              setCategories((prevState) => [...prevState, ...importXeroCategories(xeroCategories, categories)]);
              setImportedCategories(true);
            }}
            importedCategories={importedCategories}
            xeroCategoriesMappingInProgress={updateXeroCategoryLoading}
            showImportBtn={xeroCategories.length > 0 && !importedCategories}
            onNextStep={openSettingPage}
          />
        )}
      </div>
    </div>
  );
};

export default CategorySelection;
