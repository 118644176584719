import { IOutsideViewport } from "./index";

export const isOutOfParentViewport = (element: HTMLElement, container: string): IOutsideViewport => {
  if (!element) {
    throw new Error("Element is not defined.");
  }

  const getParentElement = (container) => {
    if (typeof container !== "string" || container.trim() === "") {
      throw new Error("Invalid container");
    }

    const elements = document.querySelectorAll(container);
    const visibleElement = Array.from(elements).find((element) => !element.closest('[style="display: none;"]'));

    return visibleElement || null;
  };

  const parentEl = getParentElement(container);

  // uncomment this when improving quality of test cases
  // if (!parentEl) {
  //   throw new Error("Parent container not found in the DOM.");
  // }

  const elementPosition = element?.getBoundingClientRect?.();
  const parentPosition = parentEl?.getBoundingClientRect() || ({ right: 0, left: 0, top: 0, bottom: 0 } as DOMRect);

  return {
    right: elementPosition.right > parentPosition.right,
    left: elementPosition.left < parentPosition.left,
    top: elementPosition.top < parentPosition.top,
    bottom: elementPosition.bottom > parentPosition.bottom,
  };
};
