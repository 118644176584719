import React from "react";
import { Modal as ModalWrapper } from "@spenmo/splice";
import { useRouteMatch } from "react-router-dom";

import { ILocationParams } from "Views/Bills/V2/@types";
import { BILLS_MODAL_OPTIONS, BILLS_PAGE_OPERATIONS } from "Views/Bills/V2/constants";

import MarkApproved from "./MarkApproved";

const DefaultModal = () => {
  return <ModalWrapper showModal={false} size="m" title="Bills" />;
};

const Modal = () => {
  const { params } = useRouteMatch<ILocationParams>();

  const { form = "", operation = "", id = "" } = params;

  let Component: React.FunctionComponent = undefined;

  switch (true) {
    case form === BILLS_PAGE_OPERATIONS.EXPORT &&
      id === BILLS_PAGE_OPERATIONS.MODAL &&
      operation === BILLS_MODAL_OPTIONS.MARK_APPROVED_BILLS:
      Component = MarkApproved;
      break;

    case operation === "" && id === "":
    default:
      Component = DefaultModal;
      break;
  }

  return <Component />;
};

export default Modal;
