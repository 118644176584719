import React from "react";
import classnames from "classnames";
import { Col, Row } from "Splice/Grid";
import s from "./index.module.scss";
import { Filter } from "Views/Analytics/Filters";

interface Props {
  title?: string;
  children: React.ReactChild[];
}

export const AnalyticsLayout = ({ title = "Analytics", children }: Props) => {
  return (
    <div className={s.container} data-testid={"analytics-page"}>
      <Row column="none" className={s.row} style={{ marginInline: 0 }}>
        <Filter title={title} />
        <div className={s.chartsContainer}>
          <Row className={classnames(s.row, s.row1)} offset={0} gutter={[12, 0]}>
            <Col span={24} className={s.spendingTrend}>
              {children[0]}
            </Col>
          </Row>
          <Row className={classnames(s.row, s.row2)} offset={0} gutter={[12, 0]}>
            <Col span={12} className={s.categories}>
              {children[1]}
            </Col>
            <Col span={12} className={s.merchants}>
              {children[2]}
            </Col>
          </Row>
          <Row className={classnames(s.row, s.row3)} offset={0} gutter={[12, 8]}>
            <Col span={24} className={s.teams}>
              {children[3]}
            </Col>
          </Row>
        </div>
      </Row>
    </div>
  );
};
