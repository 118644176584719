import React from "react";
import { IColumnData } from "Modules/DS/DataTable";
import { dateTimeFormatter, currencyFormatter } from "utility";
import ApprovalActions from "./ApprovalActions";
import Icon from "Modules/icons";
import { billInvoiceActive, billInvoiceInactive } from "assets/img";
import { BILLS_APPROVAL_EVENTS, CR_APPROVAL_EVENTS, CARD_APPROVAL_EVENTS } from "../trackEvents";
import ApprovalRequest from "Models/Approval/ApprovalCenter/approvalRequest.interface";
import BillApprovalRequest from "Models/Approval/ApprovalCenter/billApprovalRequest.interface";
import CardApprovalRequest from "Models/Approval/ApprovalCenter/cardApprovalRequest.interface";
import ReimbursementApprovalRequest from "Models/Approval/ApprovalCenter/reimbursementApprovalRequest.interface";
import FundTransferApprovalRequest from "Models/Approval/ApprovalCenter/fundTransferApprovalRequest.interface";
import { ApprovalCenterConstant, BillFilter, REIMBURSEMENT_STATUS } from "../types";
import { displayCardLimit, getReimbursementSubTab } from "../helper";
import PaymentActions from "./PaymentActions";
import { Skeleton } from "antd";

interface ApprovalRequestColumnProps {
  handleApproveDecision: (object) => Promise<object>;
  setDeclineApprovalRequestModal: (boolean) => void;
  activeTab: string;
  handlePreviewFiles?: (files: string[]) => void;
  selectedRows: ApprovalRequest[];
  showDetails: (object, boolean) => void;
  loading: boolean;
  isSaasWithPaymentsOrg?: boolean;
}

interface ApprovalRequestReimbursementColumnProps extends ApprovalRequestColumnProps {
  handleApprovePayImmediately: (object: any) => Promise<object>;
  approvalFilters: BillFilter;
}

const renderRequesterName = (_, record) => {
  if (record.requesterFirstName || record.requesterLastName) {
    return (
      <p>
        {record.requesterFirstName} <br /> {record.requesterLastName}
      </p>
    );
  }
  if (record.requesterName) {
    return <p>{record.requesterName} </p>;
  }
  return ApprovalCenterConstant.NotAvailable;
};

const renderSkeletonFunction = (loading, render) => {
  return loading ? () => <Skeleton.Input active /> : render;
};

const renderSkeletonElement = (loading, element) => {
  return loading ? <Skeleton.Input active /> : element;
};

const renderSkeletonButtons = () => {
  return (
    <div className="shimmer__container">
      <Skeleton.Button shape="round" size="large" active />
      <Skeleton.Button shape="round" size="large" active />
    </div>
  );
};

export const BillColumns = ({
  handleApproveDecision,
  setDeclineApprovalRequestModal,
  activeTab,
  handlePreviewFiles,
  selectedRows,
  showDetails,
  loading,
  isSaasWithPaymentsOrg,
}: ApprovalRequestColumnProps): IColumnData<BillApprovalRequest>[] => {
  return [
    {
      id: "APR-COL-1",
      enabled: true,
      column: {
        key: "requestDate",
        title: "Request Date",
        dataIndex: "requestDate",
        render: (timestamp) =>
          renderSkeletonElement(
            loading,
            <p>
              {dateTimeFormatter(timestamp).date} <br /> {dateTimeFormatter(timestamp).time}
            </p>
          ),
        width: 137,
      },
    },
    {
      id: "APR-COL-2",
      enabled: true,
      column: {
        key: "requesterName",
        title: "Requester",
        dataIndex: "requesterName",
        width: 120,
        render: renderSkeletonFunction(loading, renderRequesterName),
      },
    },
    {
      id: "APR-COL-3",
      enabled: true,
      column: {
        key: "recipientName",
        title: "Recipient",
        dataIndex: "recipientName",
        width: 120,
        render: renderSkeletonFunction(loading, null),
      },
    },
    {
      id: "APR-COL-4",
      enabled: true,
      column: {
        key: "invoiceNumber",
        title: "Invoice Number",
        dataIndex: "invoiceNumber",
        width: 112,
        render: renderSkeletonFunction(loading, null),
      },
    },
    {
      id: "APR-COL-5",
      enabled: true,
      column: {
        key: "invoice",
        title: "Invoice",
        dataIndex: "invoiceReceiptURLs",
        width: 96,
        render: (data, record) => {
          const files = (data || []).concat(record.attachmentURLs || []);
          return renderSkeletonElement(
            loading,
            files.length > 0 ? (
              <Icon
                src={billInvoiceActive}
                alt={"bill invoice active icon"}
                action={(e) => {
                  e.stopPropagation();
                  handlePreviewFiles(files);
                }}
                className="bill-invoice-icon bill-invoice-icon--active"
              />
            ) : (
              <Icon
                src={billInvoiceInactive}
                alt={"bill invoice inactive icon"}
                action={(e) => {
                  e.stopPropagation();
                }}
                className="bill-invoice-icon image-carousel--inactive"
              />
            )
          );
        },
      },
    },
    isSaasWithPaymentsOrg
      ? {
          id: "APR-COL-7",
          enabled: true,
          column: {
            key: "totalBillAmount",
            title: "You Send",
            dataIndex: "totalBillAmount",
            width: 150,
            render: (data, record) =>
              renderSkeletonElement(loading, <p>{currencyFormatter(data, record.sendCurrency)}</p>),
          },
        }
      : null,
    {
      id: "APR-COL-8",
      enabled: true,
      column: {
        key: `${isSaasWithPaymentsOrg ? "receiveAmount" : "invoiceAmount"}`,
        title: `${isSaasWithPaymentsOrg ? "Recipient Gets" : "Invoice Amount"}`,
        dataIndex: `${isSaasWithPaymentsOrg ? "receiveAmount" : "invoiceAmount"}`,
        width: 150,
        render: (data, record) => {
          const currency = isSaasWithPaymentsOrg ? record.receiveCurrency : record.invoiceCurrency;
          return renderSkeletonElement(loading, data ? <p>{currencyFormatter(data, currency)}</p> : <p>-</p>);
        },
      },
    },
    {
      id: "APR-COL-9",
      enabled: true,
      column: {
        key: "actions",
        title: "Actions",
        dataIndex: "actions",
        width: 246,
        fixed: "right" as IColumnData<BillApprovalRequest>["column"]["fixed"],
        render: (data, record) =>
          loading
            ? renderSkeletonButtons()
            : !selectedRows.length && (
                <ApprovalActions
                  record={record}
                  processType={activeTab}
                  onApprove={handleApproveDecision}
                  onDecline={setDeclineApprovalRequestModal}
                  showDetails={showDetails}
                  trackEventName={BILLS_APPROVAL_EVENTS.billApprovalButtonClicked}
                />
              ),
      },
    },
  ].filter((item) => item?.enabled);
};

export const CardColumns = ({
  handleApproveDecision,
  showDetails,
  setDeclineApprovalRequestModal,
  activeTab,
  selectedRows,
  loading,
}: ApprovalRequestColumnProps): IColumnData<CardApprovalRequest>[] => {
  return [
    {
      id: "CARD-COL-1",
      enabled: true,
      column: {
        key: "requestDate",
        title: "Request Date",
        dataIndex: "requestDate",
        width: 137,
        render: (timestamp) =>
          renderSkeletonElement(
            loading,
            <p>
              {dateTimeFormatter(timestamp).date} <br /> {dateTimeFormatter(timestamp).time}
            </p>
          ),
      },
    },
    {
      id: "CARD-COL-2",
      enabled: true,
      column: {
        key: "requesterName",
        title: "Requester",
        dataIndex: "requesterName",
        width: 120,
        render: renderSkeletonFunction(loading, renderRequesterName),
      },
    },
    {
      id: "CARD-COL-3",
      enabled: true,
      column: {
        key: "teamName",
        title: "Budget",
        dataIndex: "teamName",
        width: 120,
        render: renderSkeletonFunction(loading, null),
      },
    },
    {
      id: "CARD-COL-4",
      enabled: true,
      column: {
        key: "cardType",
        title: "Card Type",
        dataIndex: "isPhysicalCard",
        width: 112,
        render: (value) => renderSkeletonElement(loading, value ? "Physical" : "Virtual"),
      },
    },
    {
      id: "CARD-COL-5",
      enabled: true,
      column: {
        key: "cardLimitType",
        title: "Limit Type",
        dataIndex: "cardLimitType",
        className: "approval-center__text--capitalize",
        width: 150,
        render: (value) => renderSkeletonElement(loading, value ? value : ApprovalCenterConstant.NotAvailable),
      },
    },
    {
      id: "CARD-COL-6",
      enabled: true,
      column: {
        key: "cardLimit",
        title: "Card Limit",
        dataIndex: "cardLimit",
        width: 150,
        render: (data, record) =>
          renderSkeletonElement(
            loading,
            <p>{displayCardLimit(record.cardLimitType, data, record.cardLimitCurrency)}</p>
          ),
      },
    },
    {
      id: "CARD-COL-7",
      enabled: true,
      column: {
        key: "actions",
        title: "Actions",
        dataIndex: "actions",
        width: 246,
        fixed: "right" as IColumnData<BillApprovalRequest>["column"]["fixed"],
        render: (data, record) =>
          loading
            ? renderSkeletonButtons()
            : !selectedRows.length && (
                <ApprovalActions
                  record={record}
                  processType={activeTab}
                  onApprove={handleApproveDecision}
                  onDecline={setDeclineApprovalRequestModal}
                  showDetails={showDetails}
                  trackEventName={CARD_APPROVAL_EVENTS.cardApprovalButtonClicked}
                />
              ),
      },
    },
  ];
};

export const ReimbursementColumns = ({
  handleApproveDecision,
  handleApprovePayImmediately,
  showDetails,
  setDeclineApprovalRequestModal,
  activeTab,
  selectedRows,
  approvalFilters,
  loading,
}: ApprovalRequestReimbursementColumnProps): IColumnData<ReimbursementApprovalRequest>[] => {
  return [
    {
      id: "REIMBURSEMENT-COL-1",
      enabled: true,
      column: {
        key: "requestDate",
        title: "Request Date",
        dataIndex: "requestDate",
        width: 137,
        render: (timestamp) =>
          renderSkeletonElement(
            loading,
            <p>
              {timestamp && dateTimeFormatter(timestamp).date} <br /> {dateTimeFormatter(timestamp).time}
            </p>
          ),
      },
    },
    {
      id: "REIMBURSEMENT-COL-2",
      enabled: true,
      column: {
        key: "transactionDate",
        title: "Transaction Date",
        dataIndex: "transactionDate",
        width: 120,
        render: (timestamp) => renderSkeletonElement(loading, <p>{timestamp && dateTimeFormatter(timestamp).date}</p>),
      },
    },
    {
      id: "REIMBURSEMENT-COL-3",
      enabled: true,
      column: {
        key: "requesterName",
        title: "Requester",
        dataIndex: "requesterName",
        width: 120,
        render: renderSkeletonFunction(loading, renderRequesterName),
      },
    },
    {
      id: "REIMBURSEMENT-COL-4",
      enabled: true,
      column: {
        key: "teamName",
        title: "Budget",
        dataIndex: "teamName",
        width: 120,
        render: renderSkeletonFunction(loading, null),
      },
    },
    {
      id: "REIMBURSEMENT-COL-5",
      enabled: true,
      column: {
        key: "merchantName",
        title: "Merchant",
        dataIndex: "merchantName",
        width: 120,
        render: renderSkeletonFunction(loading, null),
      },
    },
    {
      id: "REIMBURSEMENT-COL-6",
      enabled: true,
      column: {
        key: "categoryName",
        title: "Category",
        dataIndex: "categoryName",
        width: 148,
        render: renderSkeletonFunction(loading, null),
      },
    },
    {
      id: "REIMBURSEMENT-COL-7",
      enabled: true,
      column: {
        key: "amount",
        title: "Amount",
        dataIndex: "amount",
        width: 148,
        render: (data, record) => {
          return renderSkeletonElement(loading, <p>{currencyFormatter(data, record.currency)}</p>);
        },
      },
    },
    {
      id: "REIMBURSEMENT-COL-8",
      enabled: true,
      column: {
        key: "actions",
        title: "Actions",
        dataIndex: "actions",
        width: 246,
        fixed: "right" as IColumnData<BillApprovalRequest>["column"]["fixed"],
        render: (data, record) => {
          if (loading) {
            return renderSkeletonButtons();
          }
          if (getReimbursementSubTab(approvalFilters) === REIMBURSEMENT_STATUS.PENDING && !selectedRows.length) {
            return (
              <ApprovalActions
                record={record}
                processType={activeTab}
                onApprove={handleApproveDecision}
                onDecline={setDeclineApprovalRequestModal}
                showDetails={showDetails}
                trackEventName={CR_APPROVAL_EVENTS.crApprovalButtonClicked}
              />
            );
          } else if (getReimbursementSubTab(approvalFilters) === REIMBURSEMENT_STATUS.READY) {
            return (
              <PaymentActions
                onPayApproveImmediately={handleApprovePayImmediately}
                record={record}
                processType={activeTab}
                trackEventName={CR_APPROVAL_EVENTS.crApprovalButtonClicked}
              />
            );
          }
        },
      },
    },
  ];
};

export const FundTransferColumns = ({ loading }): IColumnData<FundTransferApprovalRequest>[] => {
  return [
    {
      id: "FUND-COL-1",
      enabled: true,
      column: {
        key: "created_at",
        title: "Request Date",
        dataIndex: "created_at",
        width: 116,
        render: (timestamp) =>
          renderSkeletonElement(
            loading,
            <p>
              {dateTimeFormatter(timestamp).date} <br /> {dateTimeFormatter(timestamp).time}
            </p>
          ),
      },
    },
    {
      id: "FUND-COL-2",
      enabled: true,
      column: {
        key: "user_name",
        title: "Requester",
        dataIndex: "user_name",
        width: 110,
        render: (name) => renderSkeletonElement(loading, <p>{name}</p>),
      },
    },
    {
      id: "FUND-COL-3",
      enabled: true,
      column: {
        key: "team_name",
        title: "Budget",
        dataIndex: "team_name",
        width: 110,
        render: (team_name) => renderSkeletonElement(loading, <p>{team_name}</p>),
      },
    },
    {
      id: "FUND-COL-4",
      enabled: true,
      column: {
        key: "requested_for_wallet_type",
        title: "Recipient",
        dataIndex: "requested_for_wallet_type",
        width: 110,
        render: (walletType) => renderSkeletonElement(loading, <p>{walletType === "team" ? "Team" : "Self"}</p>),
      },
    },
    {
      id: "FUND-COL-5",
      enabled: true,
      column: {
        key: "amount",
        title: "Amount",
        dataIndex: "amount",
        width: 126,
        render: (amount, record) =>
          renderSkeletonElement(loading, <p>{currencyFormatter(amount, record.currency_code)}</p>),
      },
    },
  ];
};
