export const defineSize = (tags, completedTab = false) => {
  const tagSize = tags.length;
  let baseOption = 2; // edit_tax and complete without sync

  if (completedTab) {
    baseOption -= 1; // Remove complete without sync option on completed tab since it's not visible there
  }

  return `item__${tagSize + baseOption}`;
};
