import React, { useState, cloneElement } from "react";
import PropTypes from "prop-types";
import Button from "../button";
import { ArrowLeftOutlined } from "@ant-design/icons";

const DetailPopover = ({ title, content, backAction = () => {}, handleAction = () => {}, initialValues = {} }) => {
  const [values, setValues] = useState(initialValues);

  return (
    <div className="detail-popover">
      <div
        className="detail-popover__close-button"
        onClick={(e) => {
          e.stopPropagation();
          backAction();
        }}
      >
        <ArrowLeftOutlined />
      </div>
      <h2>{title}</h2>
      <div>{cloneElement(content, { values, setValues })}</div>
      <Button
        text={"Apply"}
        action={(e) => {
          e.stopPropagation();
          handleAction(values);
        }}
        primary
      />
    </div>
  );
};

DetailPopover.propTypes = {
  title: PropTypes.string,
  content: PropTypes.element,
  backAction: PropTypes.func,
  handleAction: PropTypes.func,
  initialValues: PropTypes.string,
};

export default DetailPopover;
