import React from "react";
import styles from "./index.module.scss";
import { successIcon } from "assets/img";
import Icon from "Modules/icons";

const JointApplicantThankyou = (): JSX.Element => {
  return (
    <div className={styles.container}>
      <Icon className={styles.icon} src={successIcon} alt={"success icon"} />
      <h1 className={styles.text}>Thank you!</h1>
      <p className={styles.description}>
        Your personnel information have been successfully verified. Spenmo will now proceed with your company
        verification process.
      </p>
    </div>
  );
};

export default JointApplicantThankyou;
