import * as React from "react";
import { useSelector } from "react-redux";
import { ICardFilterContext, initialCardFilterContext, initialFilter } from "Views/Card/List/Filters/Context";
import { useIsManagerOrAdmin } from "customHooks/Card";
import { useQuery } from "utility";
import { useHistory } from "react-router-dom";

const { createContext, useState, useEffect } = React;
export const CardFilterContext = createContext<ICardFilterContext>(initialCardFilterContext);

const CardFilterProvider = ({ children }) => {
  const userData = useSelector((state: any) => state.userInfoReducer);
  const [isAdmin, isManager] = useIsManagerOrAdmin();
  const userName: string = userData?.data?.payload?.user?.name;
  const history = useHistory();
  let query = useQuery();
  const defaultCardholderFilter = query.get("defaultCardholderFilter") === "true";

  const [filter, setFilter] = useState({
    ...initialFilter,
    search_filters: {
      ...(defaultCardholderFilter && (isAdmin || isManager)
        ? {
            cardholder_name: userName ? [userName] : [],
          }
        : {}),
    },
  });
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    history.replace("cards", null);
  }, []);

  const clearFilter = () => {
    setFilter({ ...initialFilter });
  };

  const context: ICardFilterContext = {
    filter,
    searchFilters: filter.search_filters,
    currentPage,
    setFilter,
    clearFilter,
    setCurrentPage,
  };

  return <CardFilterContext.Provider value={context}>{children}</CardFilterContext.Provider>;
};

export default CardFilterProvider;
