import React from "react";
import { Typography } from "@spenmo/splice";
import { useLocation } from "react-router-dom";

import { PAGE_TABS } from "Views/Reimbursement/Constants";
import EmptyIcon from "Views/Reimbursement/List/Empty/Icon";

import styles from "./index.module.scss";

const EmptyState = () => {
  const location = useLocation();

  const emptyState =
    PAGE_TABS.find((tab) => location.pathname.includes(tab.tabKey))?.emptyState || "No Reimbursements Found";

  return (
    <div className={styles.container}>
      <EmptyIcon />
      <Typography size="m" variant="body-content" tag="h4">
        {emptyState}
      </Typography>
    </div>
  );
};

export default EmptyState;
