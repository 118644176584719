import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { Select, Button, Input, Typography } from "antd";

import Icon from "Modules/icons";
import { search as searchIcon, circleClose } from "assets/img";

import styles from "./SpDropDownList.module.scss";

const SpDropDownList = ({ onDoSearch, onDoAddData, searchPlaceholder, isAbleToAddData, children, ...rest }) => {
  const { Text } = Typography,
    inputRef = useRef();

  const [value, setValue] = useState("");

  const onValueChange = (val) => {
    setValue(val);
    onDoSearch(val);
  };

  const onChange = (evt) => onValueChange(evt.target.value),
    onClear = () => onValueChange("");

  const renderClearButton = () => <Icon className={styles.icon} action={onClear} src={circleClose} />,
    renderSearchIcon = () => <Icon className={styles.icon} src={searchIcon} />,
    renderAddData = () => (
      <div className={styles.container}>
        <Text className={styles.text}>{value}</Text>
        <Button type="link" className={styles.icon} onClick={onDoAddData}>
          + Add
        </Button>
      </div>
    );

  const dropdownRender = (menu) => {
    return (
      <>
        <div className={styles.container}>
          <Input
            ref={inputRef}
            value={value}
            onChange={onChange}
            placeholder={searchPlaceholder}
            data-testid="spdropdownlist-search-input"
          />
          {value === "" ? renderSearchIcon() : renderClearButton()}
        </div>
        {menu.props.options.length || !isAbleToAddData || value === "" ? menu : renderAddData()}
      </>
    );
  };

  const onDropdownVisibleChange = (open) => {
    if (open) {
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }, 300);
    }
  };

  return (
    <Select {...rest} dropdownRender={dropdownRender} onDropdownVisibleChange={onDropdownVisibleChange}>
      {children}
    </Select>
  );
};

SpDropDownList.defaultProps = {
  onDoSearch: () => {},
  onDoAddData: () => {},
  isAbleToAddData: false,
  searchPlaceholder: "Search",
};

SpDropDownList.propTypes = {
  onDoSearch: PropTypes.func,
  onDoAddData: PropTypes.func,
  isAbleToAddData: PropTypes.bool,
  searchPlaceholder: PropTypes.string,
  children: PropTypes.any,
};

export default SpDropDownList;
