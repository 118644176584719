import { createContext } from "react";
import type { Context } from "react";
import { IScreenMap } from "../types";

export interface IRowContext {
  curScreens?: IScreenMap;
}

const RowContext: Context<IRowContext> = createContext({});

export default RowContext;
