import { ILineItem } from "./line";

export enum MENU_CLASS_NAME {
  LINE_CONTAINER = "spm-menu-line-item",
  LINE_TITLE = "spm-menu-line-item-title",
}

export enum MENU_HEIGHT {
  SMALL = "small--height",
  INHERIT = "inherit--height",
}

export interface IMenuRow extends ILineItem {
  children: React.ReactNode;
}
