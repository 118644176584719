import React, { Fragment, forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import cn from "classnames";
import { Input as AntdInput, Form, Select } from "antd";
import useSWR from "swr";

import Icon from "Modules/Icon";
import IconImage from "Modules/icons";
import Label from "Modules/DS/Atoms/Label";
import Input from "Modules/DS/Input";

import Loader from "Modules/DS/Loader";

import { getListCurrency } from "Redux/DataCalls/Disbursement.api";

import { LOADER_SIZES } from "Modules/DS/Loader/types";
import { Tooltip, TooltipArrowAlignmentEnum, TooltipPositionEnum } from "Modules/DS/Tooltip";

import { newInfo } from "assets/img";
import { CurrencyInputProps, ExchangeInputProps, ExchangeOperator, InputType } from "./types";
import styles from "./ExchangeInput.module.scss";

const { Option } = Select;

export const CurrencyInput = forwardRef((props: CurrencyInputProps, ref: React.RefObject<AntdInput>) => {
  const {
    label,
    defaultValue,
    amount,
    currency,
    country,
    onChangeAmount,
    onChangeCurrency,
    onFocus,
    selectDisabled = true,
    disabled,
    placeholder = "Enter an amount",
    inputGroupStyle,
    name,
    rules,
  } = props;
  const inputRef = useRef(null);
  useImperativeHandle(ref, () => inputRef.current);

  const { data, isValidating } = useSWR(
    [true, country, "/master/currency"],
    ([filtered, country]) => getListCurrency(filtered, country),
    {
      revalidateOnFocus: false,
    }
  );

  const currencyList = data?.data?.payload?.result;

  useEffect(() => {
    if (currencyList) {
      const defaultCurrency = currencyList.find((item) => item.CurrencyCode.slice(0, 2) === country);
      onChangeCurrency && onChangeCurrency((defaultCurrency || currencyList[0]).CurrencyCode);
    }
  }, [currencyList]);

  const renderCurrencyOptions = useCallback(() => {
    const source = (currencyCode: string) =>
      `https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.5.0/flags/4x3/${currencyCode
        ?.toLowerCase()
        ?.substr(0, 2)}.svg`;

    return currencyList?.map((item) => (
      <Option key={item.CurrencyCode} value={item.CurrencyCode}>
        <div className={styles.currencyOptions}>
          <IconImage alt={item.CurrencyCode} className={styles.flag} src={source(item.CurrencyCode)} />
          {item.CurrencyCode}
        </div>
      </Option>
    ));
  }, [currencyList]);

  return (
    <div className={styles.currencyInput}>
      <Label onClick={() => inputRef?.current?.focus()} className={styles.currencyLabel}>
        {label}
      </Label>
      <div
        className={cn(styles.currencyInputGroup, {
          [styles.withDropdown]: !selectDisabled,
          ...inputGroupStyle,
        })}
      >
        <Select
          className={styles.selectCurrency}
          loading={isValidating}
          showSearch
          suffixIcon={disabled || selectDisabled ? null : undefined}
          disabled={disabled || selectDisabled}
          value={currency}
          onChange={onChangeCurrency}
        >
          {renderCurrencyOptions()}
        </Select>
        <Form.Item name={name} rules={rules}>
          <Input
            data-testid="exchangeInput"
            ref={inputRef}
            placeholder={placeholder}
            onChange={onChangeAmount}
            defaultValue={defaultValue}
            value={amount?.toString()}
            currency={currency}
            onFocus={onFocus}
            disabled={disabled}
          />
        </Form.Item>
      </div>
    </div>
  );
});

const renderTooltip = (text: string) => {
  return (
    <Tooltip alignArrow={TooltipArrowAlignmentEnum.CENTER} position={TooltipPositionEnum.TOP} text={text}>
      <img src={newInfo} alt="tooltip info icon" width={16} height={16} className={styles.infoIcon} />
    </Tooltip>
  );
};

const ExchangeInput = (props: ExchangeInputProps) => {
  const {
    receiverAmount,
    receiverAmountLabel,
    senderAmount,
    receiverCurrencyCode,
    receiverCountry,
    senderCurrencyCode,
    exchangeProcess,
    onChangeReceiverAmount,
    onChangeSenderAmount,
    onChangeReceiverCurrency,
    onChangeFocus,
    disableYouPay,
    disabledReceiverCountrySelection = true,
    disabled = false,
  } = props;
  const receiverInputRef = useRef(null);
  const senderInputRef = useRef(null);
  const [receiveAmount, setReceiveAmount] = useState<string>(receiverAmount);
  const [sendAmount, setSendAmount] = useState<string>(senderAmount);

  useEffect(() => {
    setReceiveAmount(receiverAmount);
  }, [receiverAmount]);

  useEffect(() => {
    setSendAmount(senderAmount);
  }, [senderAmount]);

  const handleChangeReceiverAmount = (receiveAmount: string) => {
    setReceiveAmount(receiveAmount);
    onChangeReceiverAmount?.(receiveAmount);
  };

  const handleChangeSendAmount = (sendAmount: string) => {
    setSendAmount(sendAmount);
    onChangeSenderAmount?.(sendAmount);
  };

  return (
    <div className={styles.exchange}>
      <CurrencyInput
        ref={receiverInputRef}
        label={
          receiverAmountLabel ? (
            <div className={styles.inputLabel}>Recipient gets{receiverAmountLabel}</div>
          ) : (
            "Recipient gets"
          )
        }
        country={receiverCountry}
        currency={receiverCurrencyCode}
        amount={receiveAmount}
        onChangeAmount={handleChangeReceiverAmount}
        onChangeCurrency={onChangeReceiverCurrency}
        onFocus={() => onChangeFocus?.(InputType.receiver)}
        selectDisabled={disabledReceiverCountrySelection || disabled}
        disabled={disabled}
      />
      {Array.isArray(exchangeProcess) && exchangeProcess.length > 0 && (
        <div
          className={cn(styles.exchangeDetail, {
            [styles.withDropdown]: !disabledReceiverCountrySelection,
          })}
        >
          <div className={styles.verticalLine} />
          {exchangeProcess.map((detailItem, index) => {
            return (
              <Fragment key={index}>
                <p className={styles.detailLabel}>
                  {detailItem.label}
                  {detailItem.labelTooltip && renderTooltip(detailItem.labelTooltip)}
                </p>
                <div
                  className={cn(styles.processDetailIcon, {
                    [styles.times]: detailItem.operator === ExchangeOperator.MULTIPLY,
                  })}
                >
                  <Icon icon="add" size={12} />
                </div>
                <div className={styles.detail}>
                  <div className={styles.detailText}>{detailItem.detail}</div>
                  <div>{detailItem.isLoading && <Loader size={LOADER_SIZES.SMALL} />}</div>
                </div>
              </Fragment>
            );
          })}
        </div>
      )}

      <CurrencyInput
        ref={senderInputRef}
        label="You pay"
        currency={senderCurrencyCode}
        amount={sendAmount}
        onChangeAmount={handleChangeSendAmount}
        onFocus={() => onChangeFocus?.(InputType.sender)}
        placeholder={disableYouPay ? "0" : undefined}
        disabled={disableYouPay || disabled}
        selectDisabled
        inputGroupStyle={{
          [styles.withDropdown]: !disabledReceiverCountrySelection,
        }}
      />
    </div>
  );
};

export default ExchangeInput;
