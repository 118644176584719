import APIclient from "API/Client";
import { userInitialState } from "Redux/InitialState";

export const CREATE_SCHEDULED_TRANSACTION_REQUEST = "CREATE_SCHEDULED_TRANSACTION_REQUEST";
export const CREATE_SCHEDULED_TRANSACTION_SUCCESS = "CREATE_SCHEDULED_TRANSACTION_SUCCESS";
export const CREATE_SCHEDULED_TRANSACTION_FAILURE = "CREATE_SCHEDULED_TRANSACTION_FAILURE";
export const CLEAR_CREATE_SCHEDULED_TRANSACTION = "CLEAR_CREATE_SCHEDULED_TRANSACTION";

const createScheduledTransactionReq = (data = userInitialState) => {
  return {
    type: CREATE_SCHEDULED_TRANSACTION_REQUEST,
    data: data,
  };
};

const createScheduledTransactionSuccess = (data) => {
  return {
    type: CREATE_SCHEDULED_TRANSACTION_SUCCESS,
    data: data,
  };
};

const createScheduledTransactionError = (data) => {
  return {
    type: CREATE_SCHEDULED_TRANSACTION_FAILURE,
    data: data,
  };
};

export const clearCreateScheduledTransaction = () => {
  return {
    type: CLEAR_CREATE_SCHEDULED_TRANSACTION,
  };
};

export const createScheduledTransactionFunc = (payload) => {
  return (dispatch) => {
    dispatch(createScheduledTransactionReq());
    APIclient.postData("/ms/spm-organization/v1/allocation-job", payload)
      .then((data) => {
        const response = data.data;
        response.isRecurring = payload?.schedule?.intervalType !== "NONE";
        dispatch(createScheduledTransactionSuccess(response));
      })
      .catch((err) => {
        dispatch(createScheduledTransactionError(err));
      });
  };
};
