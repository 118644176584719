import APIclient from "API/Client";

import {
  DELETE_EMPLOYEE_CHECK_ACTION_TYPES,
  IDeleteEmployeeCheckData,
} from "Redux/Actions/Employee/Delete/Check/types";

import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";

export const fetchDeleteEmployeeCheckRequest = (): {
  type: DELETE_EMPLOYEE_CHECK_ACTION_TYPES.FETCH_DELETE_EMPLOYEE_CHECK_REQUEST;
} => {
  return {
    type: DELETE_EMPLOYEE_CHECK_ACTION_TYPES.FETCH_DELETE_EMPLOYEE_CHECK_REQUEST,
  };
};

export const fetchDeleteEmployeeCheckSuccess = (
  data: IDeleteEmployeeCheckData,
  employeeId: string,
  employeeName: string
): {
  type: DELETE_EMPLOYEE_CHECK_ACTION_TYPES.FETCH_DELETE_EMPLOYEE_CHECK_SUCCESS;
  data: IDeleteEmployeeCheckData;
} => {
  return {
    type: DELETE_EMPLOYEE_CHECK_ACTION_TYPES.FETCH_DELETE_EMPLOYEE_CHECK_SUCCESS,
    data: { ...data, id: employeeId, name: employeeName },
  };
};

export const fetchDeleteEmployeeCheckError = (
  data: IDeleteEmployeeCheckData,
  employeeId: string,
  employeeName: string
): {
  type: DELETE_EMPLOYEE_CHECK_ACTION_TYPES.FETCH_DELETE_EMPLOYEE_CHECK_ERROR;
  data: IDeleteEmployeeCheckData;
} => {
  return {
    type: DELETE_EMPLOYEE_CHECK_ACTION_TYPES.FETCH_DELETE_EMPLOYEE_CHECK_ERROR,
    data: { ...data, id: employeeId, name: employeeName },
  };
};

export const clearDeleteEmployeeCheck = () => {
  return {
    type: DELETE_EMPLOYEE_CHECK_ACTION_TYPES.CLEAR_DELETE_EMPLOYEE_CHECK,
    data: {},
  };
};

export const getDeleteEmployeeCheck = (employeeId: string, employeeName: string = "", teamId: string = "") => {
  return (dispatch) => {
    dispatch(fetchDeleteEmployeeCheckRequest());

    const team = teamId ? "team_id=" + teamId : "";

    APIclient.getData(`/api/v1/user/${employeeId}/offboarding-check-eligibility?${team}`)
      .then((res: { [propName: string]: any }) => {
        if (res.data.status > HTTP_STATUS_CODE.OK) {
          dispatch(fetchDeleteEmployeeCheckError(res.data, employeeId, employeeName));
        } else {
          dispatch(fetchDeleteEmployeeCheckSuccess(res.data.payload, employeeId, employeeName));
        }
      })
      .catch((err) => {
        dispatch(fetchDeleteEmployeeCheckError(err, employeeId, employeeName));
      });
  };
};
