import * as React from "react";
import { useState, useEffect } from "react";
import { FLOW_CONDITIONS } from "../../../constants";

import "./FlowCondition.scss";
import { Menu, Dropdown, Button } from "antd";
import { chevronDown } from "assets/img";
import SelectedCondition from "./SelectedCondition";
interface FlowConditionProps {
  conditions: object;
  setConditions: (object) => void;
  title: string;
  processType: string;
  isDefault: boolean;
  setEnablePublish: (boolean) => void;
}

const FlowCondition: React.FC<FlowConditionProps> = ({
  conditions,
  setConditions,
  title,
  processType,
  isDefault,
  setEnablePublish,
}: FlowConditionProps): JSX.Element => {
  const [selected, setSelected] = useState(Object.keys(conditions));
  const [expanded, setExpanded] = useState({});

  useEffect(() => {
    if (Object.keys(conditions).length > 0) {
      setSelected([
        ...selected,
        ...Object.keys(conditions).filter((item) => conditions[item] && !selected.includes(item)),
      ]);
      setExpanded({
        ...expanded,
        ...Object.keys(conditions)
          .filter((item) => item != "amount")
          .reduce((prev, curr) => {
            const isAnyConiditionInvalid =
              conditions[curr] && conditions[curr].some((condition) => condition.isDeleted);
            if (isAnyConiditionInvalid) {
              return { ...prev, [curr]: true };
            } else {
              return prev;
            }
          }, {}),
      });
    }
  }, [conditions]);

  const conditionList = Object.keys(FLOW_CONDITIONS).filter((item) => !selected.includes(item));

  const deleteItem = (type) => {
    const selectedTemp = [...selected];
    const deletedIdx = selectedTemp.indexOf(type);
    selectedTemp.splice(deletedIdx, 1);
    setSelected(selectedTemp);
    const conditionsTemp = { ...conditions };
    delete conditionsTemp[type];
    setConditions(conditionsTemp);
    setExpanded({ ...expanded, [type]: false });
  };

  const toggleExpand = (type) => {
    setExpanded({ ...expanded, [type]: !expanded[type] });
  };

  const onMenuClick = (type: string, val?: any) => {
    setSelected([...selected, type]);
    val && setConditions({ ...conditions, [type]: val });
    toggleExpand(type);
  };

  const conditionMenu = (
    <Menu className="flow-condition__select__menu">
      {conditionList.map((type) => {
        const condition = FLOW_CONDITIONS[type];
        const menuContent = (
          <>
            <img className="flow-condition__menu__icon" src={condition.icon} />
            <h3 className="flow-condition__menu__title">{condition.title}</h3>
            <p className="flow-condition__menu__desc">{condition.desc}</p>
            {condition.subMenu && <img className="flow-condition__menu__arrow" src={chevronDown} />}
          </>
        );
        return condition.subMenu ? (
          <Menu.SubMenu title={menuContent} popupClassName="flow-condition__select__sub-menu">
            {condition.subMenu.options.map((subType, idx) => {
              return (
                <Menu.Item key={idx} onClick={() => onMenuClick(type, condition.subMenu.mapToValues(subType.value))}>
                  {subType.label}
                </Menu.Item>
              );
            })}
          </Menu.SubMenu>
        ) : (
          <Menu.Item onClick={() => onMenuClick(type)}>{menuContent}</Menu.Item>
        );
      })}
    </Menu>
  );

  return (
    <div className="flow-condition">
      <SelectedCondition
        selected={selected}
        expanded={expanded}
        conditions={conditions}
        deleteItem={deleteItem}
        toggleExpand={toggleExpand}
        setConditions={setConditions}
        title={title}
        processType={processType}
        setEnablePublish={setEnablePublish}
      />
      {conditionList.length > 0 && (
        <div className="flow-condition__select">
          <Dropdown placement="bottomCenter" overlay={conditionMenu} trigger={["click"]}>
            <Button className="flow-condition__select__button">+ Add Condition</Button>
          </Dropdown>
        </div>
      )}
    </div>
  );
};

export default FlowCondition;
