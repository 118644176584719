export enum TooltipPositionEnum {
  TOP = "top",
  BOTTOM = "bottom",
  RIGHT = "right",
  LEFT = "left",
}

export enum TooltipArrowAlignmentEnum {
  CENTER = "center",
  RIGHT = "right",
  LEFT = "left",
}

/* INTERNAL USE ONLY */

export enum ArrowOrientation {
  HORIZONTAL = "horizontal",
  VERTICAL = "vertical",
}

export interface ITooltipProps {
  text: string;
  position: TooltipPositionEnum;
  alignArrow: TooltipArrowAlignmentEnum;
  children: string | JSX.Element;
  showWithoutHover?: boolean;
}
