import React from "react";
import { Input as AntInput } from "antd";

import { InputProps } from "./type";

const Input = (props: InputProps) => {
  const { isNoSpace, onChange, onBlur } = props;

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    if (isNoSpace) {
      e.target.value = value?.replace?.(/\s/g, "");
    }

    onChange(e);
  };

  const handleBlurInput = (e: React.FocusEvent<HTMLInputElement>) => {
    const value = e.target.value;

    e.target.value = value?.trim();
    onChange(e);

    onBlur?.(e);
  };

  return <AntInput {...props} onChange={handleChangeInput} onBlur={handleBlurInput} />;
};

export default Input;
