import React, { useEffect } from "react";
import { Cookies } from "react-cookie";
import Button from "Modules/button";
import Icon from "Modules/icons";
import { left_arrow } from "../../../assets/img";
import { Form, Input, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getCountryCode } from "Redux/Actions/countryCodeAction";
import { changePhoneNumber, resetChangePhoneNumber } from "Redux/Actions/OTP/changePhoneNumberAction";
import "./ChangePhoneNum.scss";
import { trackEvent } from "utility/analytics";

const cookies = new Cookies();

const REDIRECT_TIMEOUT = 500;
const ChangePhoneNum = ({ back }: { back?: () => void }): React.ReactElement => {
  const [form] = Form.useForm();
  const { Option } = Select;
  const dispatch = useDispatch();
  const { data = [], loading } = useSelector((state: any) => state.countryCodeReducer);
  const getChangeMobileNumberData = useSelector((state: any) => state.changePhoneNumberReducer);
  const changeMobileError = getChangeMobileNumberData?.error;
  const phoneCode = cookies.get("phone_code");

  if (getChangeMobileNumberData?.data?.is_success) {
    dispatch(resetChangePhoneNumber());
    setTimeout(() => back(), REDIRECT_TIMEOUT);
  }

  useEffect(() => {
    dispatch(getCountryCode());
  }, [dispatch]);

  const onFinish = (values) => {
    const requestBody = {
      mobile_country_code: values.phoneCode,
      mobile_number: values.phoneNumber,
    };
    dispatch(changePhoneNumber(requestBody));
    trackEvent("otp change phone number confirm clicked", {});
  };

  return (
    <div className="change-phone-number">
      <Button icon={<Icon src={left_arrow} alt="arrow" />} action={back} text="Back" textButton />
      <Form form={form} id="changePhoneNumForm" autoComplete="off" onFinish={onFinish}>
        <label>Change phone number</label>
        <div className="change-phone-number_form--container">
          <Form.Item
            initialValue={phoneCode}
            name="phoneCode"
            className="phone-code"
            rules={[{ required: true, message: "required" }]}
          >
            <Select style={{ width: 120 }} loading={loading} showSearch>
              {data?.length &&
                data?.map((code) => {
                  return (
                    <Option key={`code-${code.phoneCode}`} value={code.phoneCode}>
                      +{code.phoneCode}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>
          <Form.Item
            name="phoneNumber"
            className="phone-number"
            rules={[{ required: true, max: 15, message: "Please enter a valid phone number" }]}
          >
            <Input placeholder="Enter phone number" />
          </Form.Item>
        </div>
        {changeMobileError && <p className="otp-input-error">{getChangeMobileNumberData?.data}</p>}
        <Form.Item className="change-phone-number-btn">
          <Button text="Change Phone Number" rounded />
        </Form.Item>
      </Form>
    </div>
  );
};

export default ChangePhoneNum;
