import { FETCH_DELETE_REQUEST, FETCH_DELETE_SUCCESS, FETCH_DELETE_FAILURE } from "Redux/Actions/Category/delete";
import { userInitialState } from "../../InitialState";

export const categoryDeleteReducer = (state = userInitialState, action) => {
  switch (action.type) {
    case FETCH_DELETE_SUCCESS:
      return {
        ...state,
        deleteData: action.data,
        loading: false,
        error: false,
      };
    case FETCH_DELETE_FAILURE:
      return {
        ...state,
        loading: false,
        deleteData: action.data,
        error: true,
      };
    case FETCH_DELETE_REQUEST:
      return {
        ...state,
        loading: true,
        deleteData: action.data,
        error: false,
      };
    default:
      return state;
  }
};
