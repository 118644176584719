import React, { useState } from "react";
import cn from "classnames";
import { Input as AntdInput } from "antd";
import { Controller } from "react-hook-form";

import { Input } from "@spenmo/splice";
import { CheckboxWithTooltip, ErrorMessage, Field, Select } from "Views/SubscriptionManagement/Create/Components";
import { Vendor } from "./SubscriptionVendor";
import { Amount } from "./Amount";
import { ContractType } from "./ContractType";
import { DatePicker } from "./ContractType/DatePicker";
import { UploadContract } from "Views/SubscriptionManagement/Create/UploadContract";

import { useFormContext } from "Views/SubscriptionManagement/Create/Context";
import { option } from "Views/SubscriptionManagement/Create/options";
import { formFieldHasRequiredError } from "Views/SubscriptionManagement/Create/Helper";
import { FORM_NAMES, FORM_TYPE } from "Views/SubscriptionManagement/Constants";

import styles from "Views/SubscriptionManagement/Create/styles.module.scss";

const { TextArea } = AntdInput;

export const FirstStep = ({ formType }: { formType: FORM_TYPE }) => {
  const { control, setValue, errors, watch } = useFormContext();
  const [nextBillingDate] = watch([FORM_NAMES.NEXT_BILLING_DATE]);
  const [addNotes, setAddNotes] = useState(control?._formValues?.[FORM_NAMES.NOTES] ?? false);

  const descriptionHasAnError = formFieldHasRequiredError(errors, FORM_NAMES.DESCRIPTION);
  const notesHasAnError = formFieldHasRequiredError(errors, FORM_NAMES.NOTES);

  const toggleAddNotes = () => {
    setAddNotes((prevState) => {
      setValue(FORM_NAMES.NOTES, "");
      return !prevState;
    });
  };

  return (
    <>
      <Vendor formType={formType} />
      <Field>
        <Amount />
      </Field>

      <Field twoRow>
        <Field>
          <Input.Label>Billing Frequency</Input.Label>
          <Select
            name={FORM_NAMES.BILLING_FREQUENCY}
            control={control}
            placeholder="Select frequency"
            options={option.billingFrequency}
            requiredErrorMessage="Select a frequency"
          />
        </Field>
        <Field>
          <DatePicker
            label="Next Billing Date"
            name={FORM_NAMES.NEXT_BILLING_DATE}
            value={nextBillingDate}
            errorMessage="Select next billing date"
          />
        </Field>
      </Field>

      <Field>
        <Input.Label>Description</Input.Label>
        <Controller
          name={FORM_NAMES.DESCRIPTION}
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Input.Text
              className={cn(styles.input)}
              name={FORM_NAMES.DESCRIPTION}
              type="text"
              placeholder="What is this subscription for?"
              {...field}
            />
          )}
        />
        {descriptionHasAnError && <ErrorMessage message="Describe what this subscription is for" />}
      </Field>

      <ContractType />

      <Field>
        <Input.Label>
          Upload Contract <span className={styles.optional}>(optional)</span>
        </Input.Label>
        <Controller
          name={FORM_NAMES.DOCUMENTS}
          control={control}
          render={({ field }) => (
            <UploadContract
              defaultFiles={control?._formValues?.[FORM_NAMES.DOCUMENTS]}
              onChange={(files) => field.onChange(files)}
            />
          )}
        />
      </Field>

      <Field>
        <CheckboxWithTooltip
          checkboxChildren={<span className={styles.checkbox}>Add Notes</span>}
          tooltipText="Highlight payment terms, taxes, and penalties for this subscription"
          onChange={toggleAddNotes}
          checked={addNotes}
        />
        {addNotes && (
          <Controller
            name={FORM_NAMES.NOTES}
            control={control}
            rules={{ required: true }}
            render={({ field }) => <TextArea rows={3} {...field} />}
          />
        )}
        {addNotes && notesHasAnError && <ErrorMessage message="Add a note" />}
      </Field>
    </>
  );
};
