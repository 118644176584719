import { useEffect } from "react";
import { useAppSelector, useAppDispatch } from "hook";
import { RootState } from "Redux/ConfigureStore";
import { getTeamListByUserId } from "Redux/Actions";

export const useTeamList = () => {
  const dispatch = useAppDispatch();
  const teamListData = useAppSelector((state: RootState) => state?.teamListByUserIdReducer);
  useEffect(() => {
    if (!Array.isArray(teamListData)) {
      dispatch(getTeamListByUserId());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return {
    loading: teamListData?.loading ?? false,
    teamList: Array.isArray(teamListData?.data) ? teamListData.data : [],
  };
};
