import React, { useEffect, useState } from "react";
import { Row, Col, Form } from "antd";
import { AxiosError, AxiosResponse } from "axios";
import LoadingComponent from "Views/State/Loading/LoaderIcon";
import useLoading from "utility/useLoading";
import { GetCurrencyCode } from "utility";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import { AccountTypes, SyncAccountsConfig } from "constants/Integration.constant";
import CreateOrChooseBankAccount from "../../Common/CreateOrChooseBankAccount";
import { addBankAccountInAccountingPartner, updatePartnerConnection } from "../../Common/dataCall";
import { appNotification } from "Modules/appNotification/appNotification";

import styles from "./SelectXeroSyncAccount.module.scss";

const SelectXeroSyncAccount = ({ goToNextStep, bankAccounts = [], loading, goToSettingPage }) => {
  const [form] = Form.useForm();
  const [error, setError] = useState(false);
  const bankAccountWithSameCurrency = bankAccounts.filter((account) => account.currency === GetCurrencyCode());

  const getSpenmoBankAccount = () => {
    if (bankAccountWithSameCurrency.length) {
      const spenmoBankAccount = bankAccountWithSameCurrency.find((item) =>
        item.number?.toLowerCase()?.includes("spenmo")
      );
      return spenmoBankAccount ? spenmoBankAccount?.id : "";
    }
    return "";
  };

  const getAccountNameErrorMsg = (error: AxiosError): string => {
    return error.response?.data?.error?.message.includes("Please enter a unique Name")
      ? "Bank account name already exists in Xero. Please select a different name."
      : error.response?.data?.error?.message || "Something went wrong";
  };

  const [selectedBankAccount, setSelectedBankAccount] = useState(getSpenmoBankAccount());
  const [updateXeroAccount, updateXeroAccountLoading] = useLoading(updatePartnerConnection, (error: AxiosResponse) => {
    return error.data?.error?.message || "Something went wrong";
  });
  const [addNewBankAccount, loadingAddNewBankAccount] = useLoading(
    addBankAccountInAccountingPartner,
    null,
    null,
    false
  );

  useEffect(() => {
    if (bankAccountWithSameCurrency.length && !selectedBankAccount) {
      const spenmoBankAccountId = getSpenmoBankAccount();
      if (spenmoBankAccountId) {
        form.setFieldsValue({
          [SyncAccountsConfig[AccountTypes.spenmoAccount]?.formItemName]: spenmoBankAccountId,
        });
        setSelectedBankAccount(spenmoBankAccountId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bankAccountWithSameCurrency, setSelectedBankAccount]);

  const onFinishHandler = async (values) => {
    const xeroBankAccountId = values[SyncAccountsConfig[AccountTypes.spenmoAccount]?.formItemName];
    const xeroBankAccount = bankAccountWithSameCurrency.find((acc) => acc.id === xeroBankAccountId);

    const formData = {
      partnerBankAccountName: xeroBankAccount?.name || "",
      partnerBankAccountNumber: xeroBankAccount?.number,
      partnerBankAccountID: xeroBankAccountId,
      bankfeed: true,
      currency: GetCurrencyCode(),
    };

    if (values["bank_account_selection"] === "Add New Account") {
      try {
        const response = await addNewBankAccount({
          currencyCode: formData.currency,
          name: values.accountName,
          number: values.accountNumber,
        });

        const { data: bankAccountData } = response || {};
        formData.partnerBankAccountID = bankAccountData.payload?.id;
        formData.partnerBankAccountName = bankAccountData.payload?.name;
        formData.partnerBankAccountNumber = bankAccountData.payload?.number;
      } catch (error) {
        setError(true);

        form.validateFields(["accountName"]).then(() => {
          form.setFields([
            {
              name: "accountName",
              errors: [getAccountNameErrorMsg(error)],
            },
          ]);
        });
        return;
      }
    }

    const response = await updateXeroAccount(formData);

    if (response?.data?.status === HTTP_STATUS_CODE.OK) {
      if (goToSettingPage) {
        goToSettingPage({ message: "You have successfully connected your Xero Bank Feeds to your organization" });
        return;
      }
      goToNextStep({ metaData: formData });
    } else {
      appNotification.error({
        message: "Xero cannot be connected to Spenmo right now. Please try again.",
      });
    }
  };

  const onChangeHandler = (value, accountType) => {
    accountType === AccountTypes.spenmoAccount && setSelectedBankAccount(value);
  };

  if (loading) {
    return <LoadingComponent />;
  }
  return (
    <>
      <Row justify="start" className={styles.syncAccount}>
        <Form onFinish={onFinishHandler} form={form} className={styles.fullWidth}>
          <Col span={24}>
            <Row className={styles.container}>
              <CreateOrChooseBankAccount
                accountType={AccountTypes.spenmoAccount}
                bankAccounts={bankAccountWithSameCurrency}
                onChangeHandler={onChangeHandler}
                selectedBankAccount={selectedBankAccount}
                form={form}
                loading={updateXeroAccountLoading || loadingAddNewBankAccount}
                error={error}
                clearError={() => setError(false)}
              />
            </Row>
          </Col>
        </Form>
      </Row>
    </>
  );
};

export default SelectXeroSyncAccount;
