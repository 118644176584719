import React, { useEffect } from "react";
import { useAppSelector, useAppDispatch } from "hook";
import { getCardNumbersFunc } from "Redux/Actions/CardFilter/GetCardNumbers";

import { More } from "Views/Transaction/Filters";

import { ITitleWithID } from "Views/Transaction/@types";

const CardNumberFilter = ({ id, appliedKey = "id" }: { id: string; appliedKey?: keyof ITitleWithID }) => {
  const dispatch = useAppDispatch();

  const {
    loading = false,
    fetched = false,
    data = {},
  } = useAppSelector((state: any) => state.getCardNumbersReducer || {});

  useEffect(() => {
    if (!fetched) {
      dispatch(getCardNumbersFunc());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const cardNumbers = Array.isArray(data?.payload?.card_numbers) ? data?.payload?.card_numbers : [];

  const filterData: ITitleWithID[] = cardNumbers.map((cardNumber) => ({
    id: cardNumber,
    title: `****${cardNumber}`,
  }));

  return (
    <More.Multiple<ITitleWithID> id={id} label="Card" loading={loading} displayKey="title" appliedKey={appliedKey}>
      {filterData}
    </More.Multiple>
  );
};

export default CardNumberFilter;
