import React from "react";
import classNames from "classnames";

import Button from "Modules/button";
import { COMPANY_VERIFICATION } from "Route/KYX/paths";
import { useRedirectionHandler } from "Views/KYX/hooks/useRedirection";

import styles from "./index.module.scss";

export const UpdateData = () => {
  const redirect = useRedirectionHandler(COMPANY_VERIFICATION.COMPANY_INFORMATION);

  return (
    <Button className={classNames(styles.button)} action={redirect} rounded>
      Update Company Data
    </Button>
  );
};
