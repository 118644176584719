import React from "react";
import { capitalizeFirstLetter } from "utility";
import { TransactionHeader } from "Modules/TransactionDetail";
import { getDetailHeaderAmount } from "Views/Transaction/Helper";
import { IBillImportedFromXeroData } from "Modules/BillImportedFromXeroBanner/useFetchInvoiceLink";

export const Header = ({
  title,
  subtitle,
  label,
  currencyCode,
  amount,
  xeroBillBannerData,
}: {
  title: string;
  label?: string;
  subtitle?: string;
  amount: number;
  currencyCode: string;
  xeroBillBannerData?: IBillImportedFromXeroData;
}) => {
  return (
    <TransactionHeader
      subTitle={label}
      name={capitalizeFirstLetter(title)}
      secondaryName={capitalizeFirstLetter(subtitle)}
      amount={getDetailHeaderAmount(currencyCode, amount)}
      xeroBillBannerData={xeroBillBannerData}
    />
  );
};
