import * as React from "react";
import { GetTransactions } from "Redux/DataCalls/Transactions.api";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";

interface ICardHistory {
  date: string;
  isCredit: boolean;
  amount: number;
  currencyCode: string;
  foreignCurrencyAmount: string;
  foreignCurrencyCode: string;
  merchantName: string;
}

type useCardHistoryType = [ICardHistory[], boolean, boolean, () => void];

const TOTAL_LIMIT = 500;
const INITIAL_PAGE_NO = 0;

export const useCardHistory = (cardLastFourDigits: string, cardUserId: string): useCardHistoryType => {
  const [cardHistory, setCardHistory] = React.useState<ICardHistory[]>([]);
  const [cardHistoryLoading, setCardHistoryLoading] = React.useState(false);
  const [cardHistoryFetchError, setCardHistoryFetchError] = React.useState(false);

  const getCardHistoryData = async () => {
    setCardHistoryLoading(true);
    setCardHistoryFetchError(false);
    const response = await GetTransactions(INITIAL_PAGE_NO, TOTAL_LIMIT, {}, {}, cardLastFourDigits, cardUserId);
    const { payload = {}, status } = response || {};
    if (status === HTTP_STATUS_CODE.OK) {
      const { transactions = [] } = payload || {};
      const formattedTransactions = Array.isArray(transactions)
        ? transactions.map((transaction) => {
            const {
              isCredit,
              simplified_merchant_name,
              merchant,
              amount = "",
              currency_code = "",
              foreign_currency_amount = "",
              foreign_currency_code = "",
              created_at = "",
            } = transaction || {};
            return {
              date: created_at,
              isCredit: isCredit === "1",
              amount: amount || "",
              currencyCode: currency_code,
              foreignCurrencyAmount: foreign_currency_amount,
              foreignCurrencyCode: foreign_currency_code,
              merchantName: simplified_merchant_name || merchant || "",
            };
          })
        : [];
      setCardHistory(formattedTransactions);
    } else {
      setCardHistoryFetchError(true);
    }
    setCardHistoryLoading(false);
  };
  React.useEffect(() => {
    getCardHistoryData();
  }, []);
  return [cardHistory, cardHistoryLoading, cardHistoryFetchError, getCardHistoryData];
};
