import React from "react";
import { DateFilter as DSDate } from "Modules/DS/Filter";
import { useTeamActionContext } from "Views/Team/List/Actions/context";
import { FUND_TRANSFER_FILTERS_NAMES } from "Views/Team/List/Actions/constant";
import { IDateFormProps } from "Views/Team/List/Actions/type";

interface IDateProps {
  activeSubTab: string;
  filter: {
    start_date: string;
    end_date: string;
  };
}

const FundTransferDateFilter = ({ activeSubTab, filter = { start_date: "", end_date: "" } }: IDateProps) => {
  const { onApplyFilter, resetFilter } = useTeamActionContext();

  const onApply = (value: IDateFormProps) => {
    onApplyFilter({ name: FUND_TRANSFER_FILTERS_NAMES.CREATED_DATE, value: value });
  };

  const onClear = () => {
    resetFilter(FUND_TRANSFER_FILTERS_NAMES.CREATED_DATE);
  };

  return (
    <DSDate
      id={`${activeSubTab}_fund_transfer_created_date_filter`}
      asISO={true}
      filter={filter}
      onApply={onApply}
      onClear={onClear}
      startKey="start_date"
      endKey="end_date"
      defaultValue="Created Date"
    />
  );
};

export default FundTransferDateFilter;
