import APIclient from "API/Client";
import { GetOrgId } from "../../utility";
import { source } from "./index";
import { TEAM_TYPE } from "../../constants/Team.constant";
// import { updateOrgDataInTeams, updateOrgTeamWallet } from "./teamAction"; @krishna need to check
import { updateOrgTeamWallet } from "./teamAction";
import { HTTP_STATUS_CODE } from "../../constants/HTTPStatusCode.constant";

export const FETCH_ORG_TEAM = "FETCH_ORG_TEAM";
export const FETCH_ORG_TEAM_SUCCESS = "FETCH_ORG_TEAM_SUCCESS";
export const FETCH_ORG_TEAM_FAILURE = "FETCH_ORG_TEAM_FAILURE";
// get org level teams here, another use case - admin which are not member of any teams
const fetchOrgTeam = () => {
  return {
    type: FETCH_ORG_TEAM,
  };
};

const fetchOrgTeamSuccess = (orgTeam, data) => {
  return {
    type: FETCH_ORG_TEAM_SUCCESS,
    orgTeam,
    data,
  };
};

const fetchOrgTeamFailure = (error) => {
  return {
    type: FETCH_ORG_TEAM_FAILURE,
    error,
  };
};

export const orgTeamFunc = () => {
  return async (dispatch) => {
    dispatch(fetchOrgTeam());

    try {
      const { data } = await APIclient.getData(`/api/v1/org/${GetOrgId()}/team`, {
        params: {
          pg: 0,
          limit: 100,
          // team_type: TEAM_TYPE.ORGANISATION,
          with_members: 1,
        },
      });

      if (data.status === HTTP_STATUS_CODE.OK) {
        const orgTeam = data.payload.teams.find((team) => team.team_type === TEAM_TYPE.ORGANISATION);

        dispatch(fetchOrgTeamSuccess(orgTeam, data?.payload?.teams));
        // dispatch(updateOrgDataInTeams(orgTeam));
        dispatch(updateOrgTeamWallet(orgTeam));
      } else {
        source.cancel("Operation canceled for error");
      }
    } catch (error) {
      dispatch(fetchOrgTeamFailure(error));
    }
  };
};
