import React from "react";
import { Select } from "antd";
import PropTypes from "prop-types";

const SearchDropDown = ({ listData = [], action = () => {}, placeholder, displayKey = "name", initialValue }) => {
  const { Option } = Select;
  return (
    <div className={"drop-down"}>
      <Select
        showSearch
        size={"middle"}
        className={"section-select"}
        defaultValue={initialValue}
        onChange={(val, option) => action(option.id)}
        placeholder={placeholder}
        filterOption={true}
      >
        {listData.length > 0 &&
          listData.map((data, index) => (
            <Option key={index} className="section-options" value={data[displayKey]} id={data.id}>
              <p className="section-options__title">{data[displayKey]}</p>
            </Option>
          ))}
      </Select>
    </div>
  );
};

SearchDropDown.propTypes = {
  action: PropTypes.func,
  listData: PropTypes.array,
  placeholder: PropTypes.string,
  initialValue: PropTypes.string,
};

export default SearchDropDown;
