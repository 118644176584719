import * as React from "react";
import { useSelector } from "react-redux";
import Tag from "Modules/DS/Atoms/Tag";
import Dropdown from "Modules/DS/Dropdown";
import Input from "Modules/DS/Input";
import { currencyFormatter } from "utility";
import { trackEvent } from "utility/analytics";
import { AMOUNT_SUBMENU } from "../../../../constants";
import "./AmountConditionForm.scss";

import { Operator, AmountConditionObject, ConditionFormProps } from "../../../../types";
import { APPROVAL_FLOW_EVENTS } from "Views/ApprovalFlow/trackingEvents";
interface AmountConditionFormProps extends ConditionFormProps {
  values: AmountConditionObject;
}

const BETWEEN_VALUE_ERROR = "Highest Amount need to be higher than the Lowest Amount.";

const AmountConditionForm: React.FC<AmountConditionFormProps> = ({
  values,
  setValues,
  isExpanded,
}: AmountConditionFormProps): JSX.Element => {
  const { operator, value } = values || {};
  const amounts = value?.split(":") || [];
  const currencyCode = useSelector((state: any) => state.wallet?.data?.currency_code);

  return (
    <div className="approval-amount-condition">
      {isExpanded ? (
        <>
          <Dropdown
            className="approval-amount-condition__logic-dropdown"
            label="Logic"
            placeholder="Pick one"
            values={AMOUNT_SUBMENU.find((item) => item.value === operator)}
            onChange={(val) => {
              const op = val?.value;
              const newValue = {
                ...values,
                operator: op,
              };
              if (op !== Operator.between && amounts[0]) {
                newValue.value = amounts[0];
              }
              setValues(newValue);
            }}
            options={AMOUNT_SUBMENU}
          />
          <Input
            className="approval-amount-condition__first-amount-input"
            prefix={currencyCode}
            currency={currencyCode}
            value={amounts[0]}
            label={operator === Operator.between ? "Lowest Amount" : "Amount"}
            placeholder="Type amount"
            onChange={(val) => {
              const secondAmount = amounts[1] ? `:${amounts[1]}` : "";
              const newValue = {
                ...values,
                value: `${val}${secondAmount}`,
              };
              setValues(newValue);
            }}
          />
          {operator === "between" && (
            <Input
              className="approval-amount-condition__second-amount-input"
              prefix={currencyCode}
              currency={currencyCode}
              value={amounts[1]}
              label="Highest Amount"
              placeholder="Type amount"
              onChange={(val) => {
                const newValue = {
                  ...values,
                  value: `${amounts[0]}:${val}`,
                };
                setValues(newValue);
              }}
              error={parseFloat(amounts[1]) < parseFloat(amounts[0]) && BETWEEN_VALUE_ERROR}
            />
          )}
        </>
      ) : (
        <div className="selected__item__tags">
          <Tag>{`${AMOUNT_SUBMENU.find((op) => op.value === values?.operator)?.label} ${amounts
            .map((amt) => currencyFormatter(amt, currencyCode))
            .join(" to ")}`}</Tag>
        </div>
      )}
    </div>
  );
};

export default AmountConditionForm;
