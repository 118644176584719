import React from "react";
import PropTypes from "prop-types";

import Icon from "Modules/icons";

import "./error.scss";

const ErrorComponent = (props) => {
  const { message, alt = false } = props;

  const renderDisplayMessage = () => {
    return <p>{typeof message === "string" ? message : "Error loading data"}</p>;
  };

  return (
    <div id="error" className={`error ${props.className} ${alt ? "no-margin" : ""}`}>
      {alt ? (
        renderDisplayMessage()
      ) : (
        <>
          <Icon iconName={"error"} alt={"errormessage"} />
          {/*<img src={"/public/assets/img/error.svg"} alt={"errormessage"}/>*/}
          {renderDisplayMessage()}
        </>
      )}
    </div>
  );
};

ErrorComponent.propTypes = {
  message: PropTypes.string,
  className: PropTypes.string,
  alt: PropTypes.bool,
};

export default ErrorComponent;
