import {
  FETCH_FORGOT_PASSWORD_LIST,
  ForgotPasswordData,
  ForgotPasswordReducer,
  initialForgotPasswordState,
} from "Redux/Actions/Auth/VerifyForgotPassword/types";

export const verifyForgotPassword = (
  state: ForgotPasswordReducer = initialForgotPasswordState,
  action: { type: FETCH_FORGOT_PASSWORD_LIST; data: ForgotPasswordData }
): ForgotPasswordReducer => {
  switch (action.type) {
    case FETCH_FORGOT_PASSWORD_LIST.REQUEST:
      return {
        ...state,
        loading: true,
        isSuccess: false,
        error: false,
      };
    case FETCH_FORGOT_PASSWORD_LIST.SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: false,
        isSuccess: true,
      };
    case FETCH_FORGOT_PASSWORD_LIST.ERROR:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: true,
        isSuccess: false,
      };
    case FETCH_FORGOT_PASSWORD_LIST.RESET:
      return {
        ...state,
        data: null,
        isSuccess: false,
        error: false,
      };
    default:
      return state;
  }
};
