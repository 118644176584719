import { Button } from "antd";
import Modal from "Modules/DS/Modal";
import React from "react";
import Icon from "Modules/icons";
import style from "./index.module.scss";
import { NavIcon } from "assets/v1.1/icons";
import { useMoveTeamMemberContext } from "../MoveTeamMember/context";
import { infoOrange } from "assets/icons/role";
import { USER_ROLE } from "constants/Team.constant";

const RemoveBudgetProfileWarning = ({ teamList, cardList, userTeams, user }) => {
  const { employeeName, handleClose } = useMoveTeamMemberContext();

  const onClickCancel = () => {
    handleClose();
  };

  const singleManagerWarning = () => {
    const managerList = teamList?.filter((member) => member.role === USER_ROLE.MANAGER);
    if (managerList?.length === 1) {
      const foundMember = managerList.find((member) => member.id === user?.id);
      if (foundMember) {
        return (
          <div className={style.info}>
            <div className={style.info__heading}>
              <img src={infoOrange} />
              <h2 className={style.info__heading__text}>{employeeName} is the only owner in this budget</h2>
            </div>
            <p className={style.info__text}>
              A budget is required to have at least one owner. Please set another member as an owner for this budget on
              the Budget Profile page before removing {employeeName}.
            </p>
          </div>
        );
      }
    }
  };

  const noManagerWarning = () => {
    const managerList = teamList?.filter((member) => member.role === USER_ROLE.MANAGER);
    if (managerList?.length === 0) {
      return (
        <div className={style.info}>
          <div className={style.info__heading}>
            <img src={infoOrange} />
            <h2 className={style.info__heading__text}>All budgets need atleast one owner</h2>
          </div>
          <p className={style.info__text}>
            Please set a member as an owner for this budget on the Budget Profile page before removing {employeeName}
          </p>
        </div>
      );
    }
  };

  const oneBudgetWarning = () => {
    if (userTeams?.length <= 1) {
      return (
        <div className={style.info}>
          <div className={style.info__heading}>
            <img src={infoOrange} />
            <h2 className={style.info__heading__text}>{employeeName} is not a member of any other budget.</h2>
          </div>
          <p className={style.info__text}>
            An employee is required to be a member of at least one budget. If you are an admin, consider moving this
            employee into another budget instead.
          </p>
        </div>
      );
    }
  };

  const existingCardWarning = () => {
    const cardsListForCurrentUser = cardList?.filter((el) => el.user_id === user.id);
    if (cardsListForCurrentUser?.length > 0) {
      return (
        <div className={style.info}>
          <div className={style.info__heading}>
            <img src={infoOrange} />
            <h2 className={style.info__heading__text}>{employeeName} has card(s) attached to this budget</h2>
          </div>
          <p className={style.info__text}>
            Budget member cannot be removed if they have existing card(s) in the budget, including active and disabled
            cards
          </p>
        </div>
      );
    }
  };

  return (
    <Modal visible={true} className={style["remove-budget-profile-modal"]}>
      <div>
        <Icon
          src={NavIcon.Navigation.Cross.Line[24]}
          alt={"close decline approval request modal"}
          action={onClickCancel}
          className={style.close_icon}
        />
        <h3 className={style.heading}>You are not allowed to remove {employeeName}</h3>
      </div>
      <div className={style.content}>
        <div data-show="true">
          {singleManagerWarning()}
          {oneBudgetWarning()}
          {existingCardWarning()}
          {noManagerWarning()}
          <div className={style.action_area}>
            <div className={style.button}>
              <Button className={style.button__decline} onClick={onClickCancel}>
                Got It
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default RemoveBudgetProfileWarning;
