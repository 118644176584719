// Team paths
export const TEAMS_URL_PARAMS = {
  TEAM_ID: "id",
};

export const TEAM_PATHS = {
  TEAMS: "/teams",
  CREATE_TEAM: "/create-team",
  TEAM_PROFILE: "/teamprofile",
  TEAM_PROFILE_WITH_ID: `/teamprofile/:${TEAMS_URL_PARAMS.TEAM_ID}`,
};
