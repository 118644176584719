import React from "react";
import styles from "./index.module.scss";
import { AddOutline } from "@spenmo/splice";

const DragDropButton = ({
  description,
  onUpload,
}: {
  description: string;
  onUpload: () => void;
}) => {
  return (
    <div className={styles.info} onClick={onUpload}>
      <div className={styles.description}>{description}</div>
      <div className={styles.uploadAction}>
        <AddOutline iconColor="var(--icon-link)" size="16" />
        Browse, or drop multiple invoice files
      </div>
    </div>
  );
};

export default DragDropButton;
