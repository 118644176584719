export enum SUMMARY_TAB_VALUES {
  DETAILS = "details",
  PAYMENT = "payment",
  ACTIVITY_LOG = "activityLog",
}

export enum SUBSCRIPTION_AUDIT_TYPES {
  CREATED = "add",
  UPDATED = "update",
  RENEWED = "renew",
  REACTIVATED = "activate",
  DEACTIVATED = "deactivate",
  TRANSACTION_ADDED = "link",
  TRANSACTION_REMOVED = "unlink",
}

export enum AUDIT_LOG_COLUMNS {
  ACTION = "Action",
  USER = "User",
  DATE_AND_TIME = "Date/Time",
}

export enum SUBSCRIPTION_STATUS {
  ACTIVE = "ACTIVE",
  CANCELLED = "CANCELLED",
  EXPIRED = "EXPIRED",
}
