import * as React from "react";
import { Divider } from "antd";
import { useCardHistory } from "Views/Card/Details/Info/Tabs/History/UseCardHistory";
import CardHistoryLoading from "Views/Card/Details/Info/Tabs/History/CardHistoryLoading";
import { dateTimeFormatter, roundValue, currencyFormatter } from "utility";
import { searchIcon120x120, emptyCardIcon120x120 } from "assets/icons/card";

import styles from "Views/Card/Details/Info/Tabs/History/index.module.scss";

interface ICardHistoryProps {
  cardLastFourDigits: string;
  cardUserId: string;
}

const CardHistory = ({ cardLastFourDigits, cardUserId }: ICardHistoryProps) => {
  const [cardHistoryData, cardHistoryLoading, cardHistoryError, getCardHistory] = useCardHistory(
    cardLastFourDigits,
    cardUserId
  );

  const ErrorComponent = (
    <div className={styles["card-history-info-container"]}>
      <img src={searchIcon120x120} alt="error icon" width={120} height={120} />
      <p className={styles["card-history-error-text"]}>An error occurred while loading this section.</p>
      <button
        onClick={() => {
          getCardHistory();
        }}
        className={styles["reload-btn"]}
        data-testid="reload-button"
      >
        Reload
      </button>
    </div>
  );

  const getCardHistoryData = () => {
    if (cardHistoryData?.length) {
      return (
        <>
          {cardHistoryData?.map((historyData, index) => (
            <React.Fragment key={index}>
              <div className={styles["transaction-container"]}>
                <div className={styles["amount-details-container"]}>
                  <p className={styles["grey-text"]} data-testid="txn-date">{`${
                    dateTimeFormatter(historyData?.date)?.date || ""
                  }, ${dateTimeFormatter(historyData?.date)?.time || ""}`}</p>
                  <p className={styles["grey-text--bold"]}>{historyData?.merchantName}</p>
                </div>
                <div className={styles["amount-container"]}>
                  {historyData?.foreignCurrencyAmount && (
                    <p className={styles["grey-text"]}>{`${currencyFormatter(
                      roundValue(historyData?.foreignCurrencyAmount),
                      historyData?.foreignCurrencyCode
                    )}`}</p>
                  )}
                  <p className={styles["grey-text--bold"]}>{`${historyData?.isCredit ? "" : "-"} ${currencyFormatter(
                    roundValue(historyData?.amount),
                    historyData?.currencyCode
                  )}`}</p>
                </div>
              </div>
              {index < cardHistoryData?.length - 1 ? <Divider className={styles["divider"]} /> : null}
            </React.Fragment>
          ))}
        </>
      );
    } else {
      return (
        <div className={styles["card-history-info-container"]}>
          <img src={emptyCardIcon120x120} width={120} height={120} alt="empty card icon" />
          <p className={styles["no-card-history"]}>No Card History</p>
        </div>
      );
    }
  };

  const getContent = () => {
    if (cardHistoryLoading) {
      return <CardHistoryLoading />;
    } else if (cardHistoryError) {
      return ErrorComponent;
    }
    return getCardHistoryData();
  };

  return (
    <div className={styles["card-history-container"]} data-testid="card-history-container">
      {getContent()}
    </div>
  );
};

export default CardHistory;
