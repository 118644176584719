export enum BillFormTypes {
  New = "new",
  Edit = "edit",
  Draft = "draft",
}

export enum BillFooterTypes {
  Default = "default",
  Draft = "draft",
  DraftWithoutSubmit = "draft_no_submit",
}

export interface EditBillModalProps {
  visible: boolean;
  billID: Array<number>;
  onClose: () => void;
  refreshList?: () => void;
  title?: string;
  type?: BillFormTypes;
  footerType?: BillFooterTypes;
}
