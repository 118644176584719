import React, { useCallback, useState } from "react";

import { Quick } from "Modules/DS/Filter";
import { ILineItem } from "Modules/DS/Menu";
import { useMerchantSearch } from "Views/Transaction/All/useFetch";

import { ITransactionFilter } from "./index";

export const MerchantFilter = ({
  id,
  applyFilter,
  resetFilter,
  filter,
}: {
  id: string;
  filter: ITransactionFilter;
  applyFilter: (filter: any, callApi?: boolean) => void;
  resetFilter: (keys: string[]) => void;
}) => {
  const { fetchMerchants, data, loading, resetData } = useMerchantSearch();

  const [title, setTitle] = useState("");
  const [selectedIds, setSelectedIds] = useState([]);

  const getList = (arr: string[] = []) => {
    return arr.length > 0 ? arr.map((item: string) => ({ id: item, title: item })) : [];
  };
  const merchantList = getList(data);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const dataList = useCallback(() => getList(filter[id]), [filter, id]);

  const states = {
    selectedIds,
    setSelectedIds,
    title,
    setTitle,
    loading,
  };

  const onAddSelected = (selected: string[]) => {
    applyFilter({ recipient_names: selected });
  };

  const clearFilter = () => {
    resetFilter(["recipient_names"]);
    setSelectedIds([]);
  };

  return (
    <Quick.Elastic<ILineItem>
      displayKey="title"
      filterItemProps={{ id, containerClass: ".data-table-container" }}
      defaultValue="Recipient/Merchant"
      states={states}
      onAddSelected={onAddSelected}
      clearFilter={clearFilter}
      onSearch={fetchMerchants}
      onClearSearch={resetData}
      dataSet={dataList()}
      presetValue={filter.recipient_names as string[]}
    >
      {merchantList}
    </Quick.Elastic>
  );
};
