import { urlParamsBuilder } from "utility/APIWrapper";
import AxiosGetForApiWrapper from "utility/apiWrapperHelper.js";

export const GetAllInvoices = (page, limit, filters, status, isExportingCSV = false) => {
  const params = { page: page + 1, row: limit };

  let recipient = "";
  let statusList = "";

  if (status !== null) {
    params.status = status;
  }
  if (filters?.min_amount || filters?.max_amount) {
    params.amountRange = `${filters.min_amount || 0}:${filters.max_amount || 0}`;
  }
  if (filters?.start_date || filters?.end_date) {
    params.dateRange = `${filters.start_date ? filters.start_date : ""}:${filters.end_date ? filters.end_date : ""}`;
  }
  if (filters?.statusList) {
    statusList = "&" + filters.statusList.map((item) => `statusList=${item}`).join("&");
  }

  if (filters?.merchant) {
    recipient = "&" + filters.merchant.map((item) => `recipient=${item}`).join("&");
  }

  if (filters?.unscheduled === "true") {
    params.unscheduled = "true";
  }

  const exportCSVURLPath = isExportingCSV ? "/csv" : "";
  const url = urlParamsBuilder(`/ms/spm-disbursement/v1/bill${exportCSVURLPath}`, params);
  return AxiosGetForApiWrapper(url + statusList + recipient);
};

export const GetSingleInvoice = (invoiceId, params = {}) => {
  const url = urlParamsBuilder(`/ms/spm-disbursement/v1/bill/${invoiceId}`, params);
  return AxiosGetForApiWrapper(url);
};
