export const MEMBER_LIST_ACTION_KEY = {
  CLAW_BACK: "claw_back",
  SET_MEMBER: "set_member",
  SET_MANAGER: "set_manager",
  MOVE_TEAM: "move_to_team",
  REMOVE: "remove",
};

export const MEMBER_LIST_ACTIONS = [
  { key: MEMBER_LIST_ACTION_KEY.CLAW_BACK, label: "Clawback" },
  { key: MEMBER_LIST_ACTION_KEY.SET_MEMBER, label: "Set as Member" },
  { key: MEMBER_LIST_ACTION_KEY.SET_MANAGER, label: "Set as Owner" },
  { key: MEMBER_LIST_ACTION_KEY.MOVE_TEAM, label: "Move to Another Budget" },
  { key: MEMBER_LIST_ACTION_KEY.REMOVE, label: "Remove" },
];

export enum MOVE_TEAM_MEMBER_STEP {
  MOVE_TO_TEAM = "Move to Budget",
  CONFIRM = "Confirm",
}

export const INITIAL_STEP = 0;

export const initTeamDetail = {
  id: "",
  name: "",
  team_name: "",
};

export const VALIDATE_CONFIRM_MOVE_MEMBER_TEXT = "MOVE";
export const RENAME_TEAM_TITLE_LIMIT = 70;
export const DUPLICATE_BUDGET_ERROR = "Unable to rename the budget. Please try again.";
export const SCHEDULED_TRANSACTIONS_BULK_LIMIT = 20;
