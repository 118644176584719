import React from "react";
import PropTypes from "prop-types";

import TooltipOnIcon from "Modules/TooltipOnIcon";

import "./LabelWithTooltip.scss";

/*
Tooltip Props consist of description, color, trigger, placement, and overlayInnerStyle
By default
  color = "white",
  trigger = "hover",
  placement = "topRight",
  overlayInnerStyle = { color: "black" }

  Basic Usage:
    <LabelWithTooltip
      label="Company Balance"
      imageSrc="link/to/image"
      altText="alt text for image"
      description={"Your tooltip content goes here"}
    />

  *** Note : If you change the color property, make sure to update the background-color for
  .ant-tooltip-arrow:before, as well. This className updates the color for the arrow
  pointer in the tooltip. In antd v4.3.1, the arrow pointer color needs to be changed seperately from the tooltip component
*/

const LabelWithTooltip = ({ label, imageSrc, altText, alignToEnd, ...tooltipProps }) => {
  return (
    <div className={`tooltip-container ${alignToEnd ? "tooltip-container--align-to-end" : ""}`}>
      <span className="tooltip-container__title">{label}</span>
      <TooltipOnIcon imageSrc={imageSrc} altText={altText} {...tooltipProps} />
    </div>
  );
};

LabelWithTooltip.propTypes = {
  label: PropTypes.string,
  imageSrc: PropTypes.string,
  altText: PropTypes.string,
  alignToEnd: PropTypes.bool,
};

export default LabelWithTooltip;
