import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown } from "antd";
import { GetOrgId, currencyFormatterWithFractions } from "utility";
import LetterAvatar from "Modules/letterAvatar";
import RecursiveTable from "Modules/recursiveTable";
import { Toaster } from "Modules/toaster";
import { defaultEmpty, moreIcon } from "assets/img";
import { useParams } from "react-router-dom";

import Modal from "Modules/modal";

import LoaderIcon from "Views/State/Loading/LoaderIcon";
import AddMember from "Views/Team/Profile/AddMember";
import SearchMember, {
  MEMBER_INITIAL_PAGE_NUMBER,
} from "Views/Team/Profile/SearchMember";
import { searchTeamMember } from "Redux/Actions/Teams/teamMemberSearch";
import MoveTeamMemberModal from "Views/Team/Profile/MoveTeamMember";
import PromoteAdminConfirmation from "Views/Team/Profile/PromoteAdminConfirmation";
import { useIsSaasWithPaymentsOrg } from "customHooks/useIsSaasWithPaymentsOrg";

import { resetAddTeamData } from "Redux/Actions/Teams/addTeamMemberAction";
import {
  setMemberRoleFunc,
  getTeamProfileData,
  resetMemberRole,
  clearFetchSendMoney,
  sendFunc,
  UserInfoFunc,
} from "Redux/Actions";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import { getIsCardVendorNium } from "Views/Home/Utilites";
import {
  trackActionsOnTeamsPage,
  TeamProfileEventsMapping,
} from "Views/Team/Profile/TeamProfileEventsUtilites";
import { USER_ROLE } from "constants/Team.constant";
import {
  MEMBER_LIST_ACTION_KEY,
  MEMBER_LIST_ACTIONS,
} from "Views/Team/constant";
import { MoveTeamMemberEvent } from "Views/Team/Analytics";
import { trackEvent } from "utility/analytics";
import { displayRole, setApiDataToNewFormat } from "Views/Team/helper";

import "./MemberList.scss";

import usePermissionCheck from "Permission/usePermissionCheck";
import { CASBIN_PAGES } from "Permission/Pages";
import { TEAMS_PAGE_TEAM_PROFILE_PAGE } from "Permission/Actions";
import useCheckOrgConfigs from "../../../../customHooks/useCheckOrgConfigs";

const MemberList = (props) => {
  const { id } = useParams();
  const permissionParam = [
    {
      object: CASBIN_PAGES.TEAMS_PAGE_TEAM_PROFILE_PAGE,
      action: TEAMS_PAGE_TEAM_PROFILE_PAGE.ACTION_DEMOTE_PROMOTE_BUTTON,
      team_id: id,
    },
    {
      object: CASBIN_PAGES.TEAMS_PAGE_TEAM_PROFILE_PAGE,
      action: TEAMS_PAGE_TEAM_PROFILE_PAGE.ACTION_CLAWBACK_BUTTON,
      team_id: id,
    },
    {
      object: CASBIN_PAGES.TEAMS_PAGE_TEAM_PROFILE_PAGE,
      action: TEAMS_PAGE_TEAM_PROFILE_PAGE.ADD_MEMBERS_BUTTON,
      team_id: id,
    },
  ];
  const ONE_MEMBER_IN_BUDGET = 1;
  const [
    IS_ALLOWED_PROMOTE_TEAM_MEMBER_ACTION,
    IS_ALLOWED_CLAWBACK_ACTION,
    IS_ALLOWED_ADD_TEAM_MEMBERS_ACTION,
  ] = usePermissionCheck(permissionParam) || [];
  const dispatch = useDispatch();
  const { teamDetails, totalCount } = props;
  const { members: team_members, currencyCode: currency_code } =
    teamDetails || {};
  const [isToasterVisible, setToasterVisibility] = useState(false);
  const [promoteModal, setPromoteModal] = useState({
    visible: false,
    record: {},
  });
  const [statusMessage, setStatusMessage] = useState("");
  const [selectedMember, setSelectedTeamMember] = useState("");
  const [toasterStatus, setToasterStatus] = useState("");
  const [isMemberSearch, setIsMemberSearch] = useState("");
  const [moveTeamMemberModal, setMoveTeamMemberModal] = useState({
    visible: false,
  });
  const memberRoleData = useSelector(
    (state) => state?.memberRoleReducer?.data || [],
  );
  const { addMemberData, adderrorMessage } = useSelector(
    (state) => state.teamReducer || {},
  );
  const isCardVendorNium = getIsCardVendorNium();
  const memberSearchData_old = useSelector(
    (state) => state.teamMemberSearchReducer?.data?.payload?.team_members || [],
  );
  const memberSearchData = setApiDataToNewFormat(memberSearchData_old); // this is a temporary change until the  new searchMember API is implemented.

  const isPaymentEnabled = useIsSaasWithPaymentsOrg();
  const isAdmin = useSelector((state) => state.userInfoReducer?.isAdmin);
  const cardVendor = useCheckOrgConfigs("card_vendor");

  const handlePromoteOrDemote = (record, eventName) => {
    setSelectedTeamMember(record);
    const data = [
      {
        userID: record.userID,
        role: record.role === "Member" ? "Manager" : "Member",
      },
    ];
    setPromoteModal({ ...promoteModal, visible: false });
    dispatch(setMemberRoleFunc(data, teamDetails?.id));
    trackActionsOnTeamsPage(eventName);
  };

  useEffect(() => {
    if (addMemberData?.status === HTTP_STATUS_CODE.OK) {
      setToasterStatus("success");
      const membersLength = addMemberData?.payload?.team_member?.length;
      const statusMessage = `You have successfully added ${membersLength} members in ${teamDetails?.name}.`;
      setStatusMessage(statusMessage);
      setToasterVisibility(true);
      dispatch(resetAddTeamData());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addMemberData, adderrorMessage]);

  useEffect(() => {
    if (adderrorMessage?.status > HTTP_STATUS_CODE.OK) {
      setToasterStatus("error");
      setToasterVisibility(true);
      setStatusMessage(adderrorMessage.payload.status_message);
    }
  }, [adderrorMessage]);

  const handlClawBack = (data) => {
    dispatch(sendFunc(data));
    trackActionsOnTeamsPage(TeamProfileEventsMapping.clawbackClicked);
  };

  useEffect(() => {
    if (memberRoleData?.status === HTTP_STATUS_CODE.OK) {
      const statusMessage = `Successfully updated the ${userName(selectedMember?.user)} role!`;
      setToasterVisibility(true);
      setToasterStatus("success");
      setStatusMessage(statusMessage);
      dispatch(resetMemberRole());
      dispatch(UserInfoFunc());
      if (isMemberSearch) {
        dispatch(
          searchTeamMember(
            teamDetails?.id,
            isMemberSearch,
            MEMBER_INITIAL_PAGE_NUMBER,
          ),
        );
      }
    } else if (memberRoleData?.status > HTTP_STATUS_CODE.OK) {
      setToasterVisibility(true);
      setToasterStatus("error");
      setStatusMessage(memberRoleData?.error?.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberRoleData, selectedMember]);

  const { sendLoading, sendData } = useSelector(
    (state) => state.moneyReducer || {},
  );

  const dataSource = useMemo(() => {
    const result = isMemberSearch ? memberSearchData : team_members;
    return [...(result || [])].sort((a, b) => {
      if (a.role === USER_ROLE.MEMBER && b.role === USER_ROLE.MANAGER) return 1;
      if (a.role === USER_ROLE.MANAGER && b.role === USER_ROLE.MEMBER)
        return -1;
      return 0;
    });
  }, [isMemberSearch, memberSearchData, team_members]);

  const showActionColumn = useMemo(
    () => IS_ALLOWED_PROMOTE_TEAM_MEMBER_ACTION || IS_ALLOWED_CLAWBACK_ACTION,
    [IS_ALLOWED_PROMOTE_TEAM_MEMBER_ACTION, IS_ALLOWED_CLAWBACK_ACTION],
  );

  useEffect(() => {
    if (sendData?.status === HTTP_STATUS_CODE.OK) {
      const message = `Request succeeded. Reference number: ${sendData?.payload?.reference_number}`;
      setToasterVisibility(true);
      setToasterStatus("success");
      setStatusMessage(message);
      if (isMemberSearch) {
        dispatch(
          searchTeamMember(
            teamDetails?.id,
            isMemberSearch,
            MEMBER_INITIAL_PAGE_NUMBER,
          ),
        );
      } else {
        dispatch(getTeamProfileData(teamDetails?.id));
      }
    } else {
      setToasterVisibility(true);
      setStatusMessage(sendData?.payload?.status_message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendData]);

  useEffect(() => {
    return () => dispatch(clearFetchSendMoney());
  }, [dispatch]);

  const onClick = ({ key, record }) => {
    if (!record) return;

    switch (key) {
      case MEMBER_LIST_ACTION_KEY.CLAW_BACK:
        const {
          userID: user_id,
          availableBalance: available_balance,
          budgetID: team_id,
        } = record;
        const requestBody = {
          amount: {
            to_amount: available_balance,
            to_currency: currency_code,
            from_amount: available_balance,
            from_currency: currency_code,
            fee: 0,
          },
          sender: {
            team_id,
            user_id,
            type: "user",
          },
          receiver: { team_id, type: "team" },
          organisation_id: GetOrgId(),
        };

        handlClawBack(requestBody);
        break;
      case MEMBER_LIST_ACTION_KEY.SET_MEMBER:
        handlePromoteOrDemote(record, TeamProfileEventsMapping.userDemoted);
        break;
      case MEMBER_LIST_ACTION_KEY.SET_MANAGER:
        if (!isPaymentEnabled) {
          handlePromoteOrDemote(record, TeamProfileEventsMapping.userPromoted);
          return;
        }
        setPromoteModal({ visible: true, record });
        break;
      case MEMBER_LIST_ACTION_KEY.MOVE_TEAM:
        trackEvent(MoveTeamMemberEvent.MOVE_TO_TEAM_ACTION);
        setMoveTeamMemberModal({
          visible: true,
          ...record,
          teamDetails,
          process: "move",
        });
        break;
      case MEMBER_LIST_ACTION_KEY.REMOVE:
        setMoveTeamMemberModal({
          visible: true,
          ...record,
          teamDetails,
          process: "remove",
        });
        break;
      default:
        break;
    }
  };

  const moreActionMenu = useCallback(
    (record) => {
      let _actions = MEMBER_LIST_ACTIONS;
      if (cardVendor !== "MatchMove" || !IS_ALLOWED_CLAWBACK_ACTION)
        _actions = _actions.filter(
          ({ key }) => key !== MEMBER_LIST_ACTION_KEY.CLAW_BACK,
        );
      if (
        record.role === USER_ROLE.MEMBER ||
        !IS_ALLOWED_PROMOTE_TEAM_MEMBER_ACTION
      )
        _actions = _actions.filter(
          ({ key }) => key !== MEMBER_LIST_ACTION_KEY.SET_MEMBER,
        );
      if (
        record.role === USER_ROLE.MANAGER ||
        !IS_ALLOWED_PROMOTE_TEAM_MEMBER_ACTION
      )
        _actions = _actions.filter(
          ({ key }) => key !== MEMBER_LIST_ACTION_KEY.SET_MANAGER,
        );
      if (totalCount === ONE_MEMBER_IN_BUDGET || !isAdmin || !isPaymentEnabled)
        _actions = _actions.filter(
          ({ key }) => key !== MEMBER_LIST_ACTION_KEY.MOVE_TEAM,
        );
      return (
        <div className="member-list__action-menu">
          {_actions.map(({ key, label }) => (
            <div
              className="member-list__action-menu--item"
              onClick={() => onClick({ key, record })}
              key={key}
            >
              {label}
            </div>
          ))}
        </div>
      );
    },
    [
      onClick,
      IS_ALLOWED_CLAWBACK_ACTION,
      IS_ALLOWED_PROMOTE_TEAM_MEMBER_ACTION,
    ],
  );

  const renderAdminActions = useCallback(
    (record) => {
      return (
        <div className="member-list__more-icon">
          <Dropdown
            overlay={() => moreActionMenu(record)}
            trigger={["click"]}
            placement="bottomRight"
          >
            <img width={24} height={24} alt="More icon" src={moreIcon} />
          </Dropdown>
        </div>
      );
    },
    [moreActionMenu],
  );

  const userName = (user) =>
    user?.lastName ? user?.firstName + " " + user?.lastName : user?.firstName;

  const columnData = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 352,
      render: (name, record) => {
        return (
          <div className={"teams table-name-row"}>
            <LetterAvatar rounded name={record?.user?.firstName} />
            <p
              className={"transactions__fields__title member-list--long-title"}
            >
              {userName(record?.user)}
            </p>
          </div>
        );
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 328,
      render: (email, record) => {
        return (
          <p className={"teams table-email-row member-list--long-title"}>
            {record?.user?.email}
          </p>
        );
      },
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      width: 176,
      render: (role, record) => (
        <p className={"teams table-role-row"}>{displayRole(role)}</p>
      ),
    },
    ...(cardVendor === "MatchMove"
      ? [
          {
            title: "Balance",
            dataIndex: "availableBalance",
            key: "availableBalance",
            width: 284,
            render: (availableBalance) => {
              return (
                <>
                  {availableBalance
                    ? currencyFormatterWithFractions(
                        availableBalance,
                        currency_code,
                        2,
                      )
                    : "Hidden"}
                </>
              );
            },
          },
        ]
      : []),
    ...(showActionColumn
      ? [
          {
            title: "Action",
            key: "id",
            dataIndex: "id",
            width: !isCardVendorNium ? "" : "20%",
            render: (_, record) => {
              if (IS_ALLOWED_PROMOTE_TEAM_MEMBER_ACTION)
                return renderAdminActions(record);
            },
          },
        ]
      : []),
  ];

  const columnDataSearched = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 352,
      render: (name, record) => {
        return (
          <div className={"teams table-name-row"}>
            <LetterAvatar rounded name={record?.user?.firstName} />
            <p className={"transactions__fields__title"}>
              {userName(record?.user)}
            </p>
          </div>
        );
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 328,
      render: (email, record) => {
        return <p className={"teams table-email-row"}>{record?.user?.email}</p>;
      },
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      width: 176,
      render: (role) => (
        <p className={"teams table-role-row"}>{displayRole(role)}</p>
      ),
    },
    ...(cardVendor === "MatchMove"
      ? [
          {
            title: "Balance",
            dataIndex: "availableBalance",
            key: "availableBalance",
            width: 284,
            render: (availableBalance) => {
              return (
                <>
                  {availableBalance
                    ? currencyFormatterWithFractions(
                        availableBalance,
                        currency_code,
                        2,
                      )
                    : "Hidden"}
                </>
              );
            },
          },
        ]
      : []),
    ...(showActionColumn
      ? [
          {
            title: "Action",
            key: "id",
            dataIndex: "id",
            width: "20%",
            render: (_, record) => {
              if (IS_ALLOWED_PROMOTE_TEAM_MEMBER_ACTION)
                return renderAdminActions(record);
            },
          },
        ]
      : []),
  ];

  const closeMoveTeamMemberModal = () => {
    setMoveTeamMemberModal((prevMoveTeamMemberModal) => ({
      ...prevMoveTeamMemberModal,
      visible: false,
    }));
  };

  return (
    <>
      <div className={"team-members"}>
        <div className="team-profile__members">
          <div className="action-bar">
            <SearchMember
              teamId={teamDetails?.id}
              setIsMemberSearch={setIsMemberSearch}
            />
            {IS_ALLOWED_ADD_TEAM_MEMBERS_ACTION && (
              <AddMember teamDetails={teamDetails} />
            )}
          </div>
          <RecursiveTable
            className="team-members__table"
            id={"members-table"}
            pageSize={10}
            dataSource={dataSource}
            columnData={isMemberSearch ? columnDataSearched : columnData}
            customEmpty={
              isMemberSearch ? (
                <div>
                  <img
                    width={100}
                    height={100}
                    alt="Employee not found"
                    src={defaultEmpty}
                  />
                  <div>Employee not found</div>
                </div>
              ) : (
                "No Data"
              )
            }
          />
        </div>
        <Modal close={() => this.cbHandler()} className={""} show={sendLoading}>
          {sendLoading && (
            <>
              Requesting clawback <br /> <LoaderIcon />{" "}
            </>
          )}
        </Modal>
      </div>
      <Toaster
        visible={isToasterVisible}
        message={statusMessage}
        closeFunc={() => setToasterVisibility(false)}
        status={toasterStatus}
      />
      <MoveTeamMemberModal
        {...moveTeamMemberModal}
        close={closeMoveTeamMemberModal}
      />
      <PromoteAdminConfirmation
        visible={promoteModal.visible}
        employee={promoteModal.record}
        onClose={() => setPromoteModal({ visible: false, record: {} })}
        action={() =>
          handlePromoteOrDemote(
            promoteModal.record,
            TeamProfileEventsMapping.userPromoted,
          )
        }
      />
    </>
  );
};

MemberList.propTypes = {
  teamDetails: PropTypes.any,
};
export default MemberList;
