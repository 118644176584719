import qs from "query-string";
import { API_URL } from "Views/Bills/V2/constants";
import useSWR from "swr";
import { getData } from "API/Client";

export const useCurrency = () => {
  const apiURL = qs.stringifyUrl(
    {
      url: API_URL.currency,
      query: {
        filterBy: "org",
        recipientCountry: "",
      },
    },
    {
      skipEmptyString: true,
    }
  );

  return useSWR<{ id: string; currency: string; country: string }[]>(
    apiURL,
    (url) =>
      getData(url).then(({ data }) => {
        return data?.payload?.result?.map((currency) => ({
          id: currency.ID,
          currency: currency.CurrencyCode,
          country: `https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.5.0/flags/4x3/${currency.CurrencyCode.toLowerCase().substring(
            0,
            2
          )}.svg`,
        }));
      }),
    {
      fallbackData: [],
    }
  );
};
