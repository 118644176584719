export const COMMON_PATHS = {
  TOP_UP_PATH: "/topup",
  BANK_ACCOUNTS: "/bankaccount",
  SUCCESS_PATH: "/success",
  ERROR_PATH: "/error",
  LOW_BALANCE_REMINDER: "/lowbalancereminder",
  ANALYTICS: "/analytics",
  REPORTS: "/reports/:tab?",
  PAGE_NOT_FOUND: "/404",
  RESOURCE: "/resource",
  SETTINGS: "/settings",
  FORBIDDEN: "/403",
  UPSELLING: "/upselling",
};
