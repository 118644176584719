import * as React from "react";
import DisableCardConfirmationModal from "Views/Card/Details/SubInfo/StatusToggle/DisableModal";
import { changeCardStatus } from "Redux/DataCalls/Cards.api";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import { EditCardEventNames } from "Views/Card/Analytics";
import { trackEvent } from "utility/analytics";
import { trackCardStatusChange } from "Redux/Actions/cardActivateAction";
import { showSuccessNotification, showErrorNotification } from "Views/Card/Components/Notification";
import { CARD_STATUS, CARD_REQUEST_STATUS } from "Views/Card/types";
import { CARD_LIST_TABS } from "Views/Card/List/types";

type useEnableOrDisableModalReturnType = [
  (cardStatus: CARD_STATUS | CARD_REQUEST_STATUS, cardId: string, canUserEditCards: boolean) => void,
  string[],
  JSX.Element | null
];

type useEnableOrDisableModalType = (
  getCardListData: (page: number, view?: string | string[]) => void
) => useEnableOrDisableModalReturnType;

interface IShowDisableConfirmationInfo {
  visible: boolean;
  cardId: string;
  cardStatus: CARD_STATUS | CARD_REQUEST_STATUS;
}

const getToasterTailText = (cardStatus: CARD_STATUS | CARD_REQUEST_STATUS) => {
  return cardStatus === CARD_STATUS.ENABLED ? "disabled" : "enabled";
};

const getStatus = (cardStatus: CARD_STATUS | CARD_REQUEST_STATUS) => {
  return cardStatus === CARD_STATUS.ENABLED ? "suspended" : "unsuspended";
};

export const useEnableOrDisableCard: useEnableOrDisableModalType = (getCardListData) => {
  const [enableOrDisableInProgressCards, setEnableOrDisableInProgressCards] = React.useState<string[]>([]);
  const [showDisableConfirmationInfo, setShowDisableConfirmationInfo] =
    React.useState<IShowDisableConfirmationInfo | null>(null);

  let disableConfirmModal: JSX.Element | null = null;

  const handleError = (statusMessage) => {
    showErrorNotification(statusMessage);
    trackCardStatusChange(false);
  };

  const enableDisableCard = (cardStatus: CARD_STATUS | CARD_REQUEST_STATUS, cardId: string) => {
    if (enableOrDisableInProgressCards?.indexOf(cardId) !== -1) return;
    setEnableOrDisableInProgressCards((prevState) => [...prevState, cardId]);
    trackEvent(EditCardEventNames.CARD_SETTINGS_EDITED, {
      card_settings_event_source: "Status",
    });
    changeCardStatus(getStatus(cardStatus), cardId)
      .then(({ data }) => {
        const { status_message = "" } = data?.payload || {};
        if (data?.status === HTTP_STATUS_CODE.OK) {
          showSuccessNotification(`Card successfully ${getToasterTailText(cardStatus)}`);
          getCardListData(0, CARD_LIST_TABS.CARD_LIST);
          trackCardStatusChange(true);
        } else {
          handleError(status_message);
        }
      })
      .catch((err) => {
        const { status_message = "Something went wrong! Please try again later." } = err?.response?.data?.payload || {};
        handleError(status_message);
      })
      .finally(() => {
        setEnableOrDisableInProgressCards((prevState) => [...(prevState || [])]?.filter((id) => id !== cardId));
      });
  };

  const checkEnableOrDisableCard = (
    cardStatus: CARD_STATUS | CARD_REQUEST_STATUS,
    cardId: string,
    canUserEditCards: boolean
  ) => {
    if (!canUserEditCards) {
      setShowDisableConfirmationInfo({
        visible: true,
        cardId,
        cardStatus,
      });
    } else {
      enableDisableCard(cardStatus, cardId);
    }
  };

  const onDisableCardConfirmation = () => {
    enableDisableCard(showDisableConfirmationInfo?.cardStatus, showDisableConfirmationInfo?.cardId);
    setShowDisableConfirmationInfo(null);
  };

  if (showDisableConfirmationInfo?.visible) {
    disableConfirmModal = (
      <DisableCardConfirmationModal
        onClose={() => setShowDisableConfirmationInfo(null)}
        onDisableCard={onDisableCardConfirmation}
      />
    );
  }

  return [checkEnableOrDisableCard, enableOrDisableInProgressCards, disableConfirmModal];
};
