import useSWR from "swr";
import { getData } from "API/Client";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";

import { IVendor } from "Views/SubscriptionManagement/@types";

export const useVendors = () => {
  return useSWR<IVendor[]>(
    "ms/spm-organization/v1/subscriptions/vendors",
    (url) =>
      getData(url).then(({ data }) => {
        const { status, payload } = data || {};
        if (status === HTTP_STATUS_CODE.OK && Array.isArray(payload)) {
          return payload;
        }
        return [];
      }),
    {
      fallbackData: [],
      revalidateOnFocus: false,
    }
  );
};
