import * as React from "react";
import { Input, Typography } from "antd";

import styles from "Views/EmployeeOTP/OTPVerification/Input/index.module.scss";
import { useOTPTimeoutHandler } from "Views/EmployeeOTP/useOTPTimeoutHandler";
import { OTP_UNBLOCK_TIME } from "constants/Auth.constant";

const { Text } = Typography;

interface IOtpInput {
  inputLength: number;
  onInputChange: (value: string) => void;
  hasError: boolean;
  errorMessage: string;
}

const OtpInput = ({
  inputLength,
  onInputChange,
  hasError,
  errorMessage,
}: IOtpInput): React.ReactElement => {
  const [otp, setOtp] = React.useState(Array(inputLength)?.fill(""));
  const createStepArray = (size) =>
    Array.from({ length: size }).map((_, index) => index);
  const [activeInputIndex, setActiveInputIndex] = React.useState(null);
  const inputRef = React.useRef(null);
  const counter = useOTPTimeoutHandler();

  const onOtpChange = (event) => {
    const { key } = event || {};
    const { tabIndex, value } = event?.target || {};
    let enteredValue = key !== "Unidentified" ? key : Number(value);

    if (["Delete", "Backspace"].includes(key)) {
      if (!otp[tabIndex] && tabIndex - 1 >= 0) {
        inputRef?.current?.blur();
        setActiveInputIndex(tabIndex - 1);
        return;
      } else {
        enteredValue = "";
      }
    }
    if (/\d/?.test(enteredValue) || enteredValue === "") {
      let newOtp = [...otp];
      newOtp[tabIndex] = enteredValue;
      setOtp(newOtp);
      tabIndex + 1 < inputLength &&
        enteredValue &&
        setActiveInputIndex(tabIndex + 1);
      const otpToString = newOtp?.join("");
      onInputChange(otpToString);
    }
  };

  React.useEffect(() => {
    inputRef?.current?.focus();
  }, [activeInputIndex]);

  /*
    if the user enteres wrong OTP for 5 times, then BE blocks the user for more than 300 seconds
    so if BE returns otp_expires_at more than 5 minutes, we need to trigger reload to show FailedAttempts screen
  */
  /*
    We are Removing this logic from FE as it creating issues and hard to debug.
    Ideally this logic should be in BE. we should get
    { errorcode: "something" , message: "To many failed attempt"} from BE
      We will bring it back asap after BE support.
  */
  // if (counter >= OTP_UNBLOCK_TIME) {
  //   window.location.reload();
  //   return;
  // }

  return (
    <div
      className={`${styles["container"]} ${errorMessage ? styles["container-with-error"] : ""}`}
    >
      <div>
        {createStepArray(inputLength).map((index) => (
          <Input
            ref={activeInputIndex === index ? inputRef : null}
            className={`${styles["input-box"]}  ${hasError ? styles["input-box__error"] : ""}`}
            tabIndex={index}
            value={otp?.[index] ? otp[index] : undefined}
            onFocus={(event) => {
              if (event?.target?.id) {
                setActiveInputIndex(event?.target?.id);
              }
            }}
            onKeyUp={onOtpChange}
            maxLength={1}
            key={`otp_input_field_${index}`}
          />
        ))}
      </div>
      {errorMessage && (
        <Text className={styles["error-message"]}>{errorMessage}</Text>
      )}
    </div>
  );
};

export default OtpInput;
