import { createAction, createReducer, PayloadAction } from "@reduxjs/toolkit";
import { IDeleteEligibilityResponse, IDeleteTeamEligibilityState, IDeleteTeamState } from "Views/Team/Delete/type";

const eligibilitySuccess = createAction<object>("eligibilitySuccess");
const eligibilityError = createAction<object>("eligibilityError");
const eligibilityLoading = createAction<void>("eligibilityLoading");
const eligibilityReset = createAction<void>("eligibilityReset");

const deleteTeamEligibilityInitialState: IDeleteTeamEligibilityState = {
  loading: false,
  error: false,
  data: {
    payload: {
      validationErrors: null,
      statusMessage: "",
    },
  },
};
const DeleteTeamEligibilityReducer = createReducer(deleteTeamEligibilityInitialState, (builder) => {
  builder
    .addCase(eligibilitySuccess, (state, action: PayloadAction<IDeleteEligibilityResponse>) => {
      state.loading = false;
      state.error = false;
      state.data = action.payload;
    })
    .addCase(eligibilityLoading, (state, action: PayloadAction<void>) => {
      state.loading = true;
      state.error = false;
      state.data = deleteTeamEligibilityInitialState.data;
    })
    .addCase(eligibilityError, (state, action: PayloadAction<IDeleteEligibilityResponse>) => {
      state.loading = false;
      state.error = true;
      state.data = action.payload;
    })
    .addCase(eligibilityReset, (state, action: PayloadAction<IDeleteEligibilityResponse>) => {
      state.loading = false;
      state.error = false;
      state.data = deleteTeamEligibilityInitialState.data;
    });
});

export {
  eligibilityError,
  eligibilityLoading,
  eligibilitySuccess,
  eligibilityReset,
  deleteTeamEligibilityInitialState,
};

export default DeleteTeamEligibilityReducer;
