import {
  GET_APPROVAL_FLOW_LIST_REQUEST,
  GET_APPROVAL_FLOW_LIST_SUCCESS,
  GET_APPROVAL_FLOW_LIST_FAILURE,
  CLEAR_GET_APPROVAL_FLOW_LIST,
} from "../../Actions/ApprovalFlow/getApprovalFlowList";
import { userInitialState } from "../../InitialState";

export const getApprovalFlowListReducer = (state = userInitialState, action) => {
  switch (action.type) {
    case GET_APPROVAL_FLOW_LIST_REQUEST:
      return {
        ...state,
        data: action.data,
        loading: true,
      };
    case GET_APPROVAL_FLOW_LIST_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
      };
    case GET_APPROVAL_FLOW_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.data,
        error: true,
      };
    case CLEAR_GET_APPROVAL_FLOW_LIST:
      return userInitialState;
    default:
      return state;
  }
};
