import * as React from "react";
import { useSelector } from "react-redux";
import { Popover, Radio } from "antd";
import CardholderSelection from "./Components/CardholderSelection";
import { ISelectionData } from "Modules/multiSelection/DropdownSelection";
import { CardFilterContext } from "Views/Card/List/Filters/Components/CardholderFilter/Components/CardholderSelection/provider/index";
import { debounce } from "utility";
import "./index.scss";
import FilterContent from "Modules/SimpleFilter/FilterContent";

const { useState, useMemo, useCallback, useEffect, useRef, useContext } = React;
interface ICardholderFilter {
  text: string;
  list: Array<ISelectionData>;
  isMultiSelect: boolean;
  hasSearch: boolean;
  hasClearButton: boolean;
  hideActive?: boolean;
  searchFilters?: { [key: string]: Array<string> };
  action: (values: Array<string>) => void;
}

enum CardFilterStatus {
  USER_CARDS = "show-user-cards",
  OTHER_CARDS = "show-other-cards",
}
const WAITING_TIME = 300;

const CardholderFilter = ({ text, list, action, hideActive, searchFilters: propsSearchFilter }: ICardholderFilter) => {
  const { searchFilters: contextSearchFilters } = useContext(CardFilterContext);
  const searchFilters = propsSearchFilter || contextSearchFilters;
  const [isPopoverVisible, setIsPopoverVisible] = useState<boolean>(false);
  const [showSelectOther, setShowSelectOther] = useState<boolean>(false);
  const selectCardholders = useRef(null);
  const { data: userData = {} } = useSelector((state: any) => state.userInfoReducer || {});
  const userName = userData?.payload?.user?.name || "";
  const listData = useMemo(() => list.filter((item) => item.name !== userName), [list, userName]);

  const clearFilter = useCallback(
    (event) => {
      action([]);
      setIsPopoverVisible(false);
      event.stopPropagation();
    },
    [action]
  );

  useEffect(() => {
    if (!isPopoverVisible) setShowSelectOther(false);
  }, [isPopoverVisible]);

  const isEmptyCardholder = useMemo(() => !searchFilters.cardholder_name?.length, [searchFilters.cardholder_name]);

  const isDefaultCardholderVal = useMemo(
    () => userName && searchFilters?.cardholder_name?.length === 1 && searchFilters?.cardholder_name?.[0] === userName,
    [userName, searchFilters]
  );

  const radioVal = useMemo(() => {
    if (showSelectOther) return CardFilterStatus.OTHER_CARDS;
    if (isEmptyCardholder) return null;

    if (isDefaultCardholderVal) return CardFilterStatus.USER_CARDS;
    return CardFilterStatus.OTHER_CARDS;
  }, [isDefaultCardholderVal, isEmptyCardholder, showSelectOther]);

  const isShowUserCard = useMemo(() => radioVal === CardFilterStatus.USER_CARDS, [radioVal]);

  const content = useMemo(() => {
    return (
      <FilterContent
        text={text}
        isPopoverVisible={isPopoverVisible}
        emptyValue={isEmptyCardholder}
        clearFilter={clearFilter}
        showValue={isShowUserCard}
        showTextMultipleValues
        values={searchFilters?.cardholder_name}
      />
    );
  }, [text, searchFilters, isEmptyCardholder, clearFilter, isShowUserCard, isPopoverVisible]);

  const showMyCard = () => {
    setIsPopoverVisible(false);
    action([userName]);
  };

  const handleAction = (values: Array<string>) => {
    debounce(action(values), WAITING_TIME);
    setTimeout(() => {
      if (values && values.length === 1 && values[0] === userName)
        selectCardholders.current && selectCardholders.current.blur();
    }, 0);
  };

  const showOtherCards = () => {
    setShowSelectOther(true);
    setTimeout(() => {
      selectCardholders.current && selectCardholders.current.focus();
    }, WAITING_TIME);
  };

  const closeCardholderFilter = () => setIsPopoverVisible(false);

  const popover = (
    <div className="popout__cardholder-filter">
      <Radio.Group value={radioVal} className="sp-radio-group popout__checkbox-group">
        <Radio onClick={showMyCard} value={CardFilterStatus.USER_CARDS}>
          Show only my cards
        </Radio>
        <Radio onClick={showOtherCards} value={CardFilterStatus.OTHER_CARDS}>
          Show other member’s cards
        </Radio>
      </Radio.Group>
      {isPopoverVisible && (
        <CardholderSelection
          placeholder={"Please select members"}
          listData={listData}
          defaultValue={isDefaultCardholderVal ? [] : searchFilters.cardholder_name}
          resetToDefault={isDefaultCardholderVal}
          className={radioVal === CardFilterStatus.OTHER_CARDS ? "open" : ""}
          selectOptions={{
            dropdownClassName: "card-selection-filter__dropdown",
            showSearch: true,
            ref: selectCardholders,
            showAction: ["focus"],
            placement: "topLeft",
            dropdownMatchSelectWidth: false,
          }}
          action={handleAction}
          closeCardholderFilter={closeCardholderFilter}
        />
      )}
    </div>
  );

  const buttonActiveClass = !hideActive && !isEmptyCardholder ? "popout__content--on" : "";

  return (
    <div className={"popout"}>
      <Popover
        content={popover}
        visible={isPopoverVisible}
        trigger={"click"}
        onVisibleChange={setIsPopoverVisible}
        placement="bottomLeft"
      >
        <div className={`popout__content ${buttonActiveClass}`}>{content}</div>
      </Popover>
    </div>
  );
};

export default CardholderFilter;
