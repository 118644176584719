import { useState } from "react";

import { EmployeeModalTypes, IModalState, initalModalState } from "Views/Employee/types";

const useModal = (): [
  IModalState,
  React.Dispatch<React.SetStateAction<IModalState>>,
  () => void,
  (type: EmployeeModalTypes, metaData?: any) => void
] => {
  const [showModal, setShowModal] = useState({ ...initalModalState });

  const resetModal = () => setShowModal({ visible: false, type: EmployeeModalTypes.NONE, metaData: null });

  const toggleModal = (type: EmployeeModalTypes, metaData: any): void =>
    setShowModal({ visible: true, type: type, metaData });

  return [showModal, setShowModal, resetModal, toggleModal];
};

export default useModal;
