import APIclient from "API/Client";
import { message } from "antd";
import { downloadXLSX, GetOrgId } from "utility";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";

export const FETCH_EMPLOYEE_CSV_REQUEST = "FETCH_EMPLOYEE_CSV_REQUEST";
export const FETCH_EMPLOYEE_CSV_SUCCESS = "FETCH_EMPLOYEE_CSV_SUCCESS";
export const FETCH_EMPLOYEE_CSV_ERROR = "FETCH_EMPLOYEE_CSV_ERROR";

export const fetchDataRequest = (data) => {
  return {
    type: "FETCH_EMPLOYEE_CSV_REQUEST",
    data: data,
  };
};

export const fetchDataSuccess = (data) => {
  return {
    type: "FETCH_EMPLOYEE_CSV_SUCCESS",
    data: data.payload,
  };
};

export const fetchDataError = (data) => {
  return {
    type: "FETCH_EMPLOYEE_CSV_ERROR",
    data: data,
  };
};

export const downloadEmployeeCSV = () => {
  return (dispatch) => {
    dispatch(fetchDataRequest());

    APIclient.getData(`/ms/spm-organization/v1/onboarding/template-document/employee?orgID=${GetOrgId()}`)
      .then((data) => {
        if (data.data.status === HTTP_STATUS_CODE.OK) {
          const downloadURL = data.data.payload;
          message.success("Employee XLSX Downloaded Successfully");
          downloadXLSX(downloadURL, "Spenmo+Add+Employee+Template.xlsx");
          dispatch(fetchDataSuccess(data.data));
        } else {
          dispatch(fetchDataError(data.data));
        }
      })
      .catch((err) => {
        dispatch(fetchDataError(err));
      });
  };
};
