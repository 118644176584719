import * as React from "react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { clearDeleteEmployeeFromOrg } from "Redux/Actions/Employee/Delete";
import { IUpdateRoleReducer } from "Redux/Actions/Employee/Update/Role/types";
import { resetAddEmpData } from "Redux/Actions/OnBoarding/addEmployeesAction";
import { IDeleteEmployeeFromOrgReducer } from "Redux/Actions/Employee/Delete/types";
import { trackEvent } from "utility/analytics";
import { EmployeeToastEventName } from "Views/Employee/Analytics";

import Toaster from "Modules/DS/Toaster";
import { TOASTER_SIZE_TYPE, TOASTER_STATUS_TYPE } from "Modules/DS/Toaster/types.d";

import { initalPopupState } from "Views/Employee/types";

const Toast = () => {
  const dispatch = useDispatch();

  const [toaster, setToaster] = useState({ ...initalPopupState });

  const clearToaster = () => setToaster({ ...initalPopupState });

  const toggleToaster = (value: string): void => setToaster({ visible: true, message: value });

  const addEmpDetails: any = useSelector((state: any) => state.addEmployeeReducer);

  const updateRoleDetails: IUpdateRoleReducer = useSelector((state: any) => state.updateRoleReducer);

  const deleteFromOrg: IDeleteEmployeeFromOrgReducer = useSelector((state: any) => state.deleteEmployeeFromOrgReducer);
  const dataFromOrg = !!deleteFromOrg && deleteFromOrg.data;

  const isAddEmployeeSuccess: boolean = addEmpDetails?.data?.data?.payload?.isSuccess;
  const addEmployeeMessage: string = addEmpDetails?.data?.data?.payload?.message;

  const isUpdateSuccess: boolean = updateRoleDetails?.data?.isSuccess;
  const updateMessage: string = updateRoleDetails?.data?.message;

  useEffect(() => {
    if (isAddEmployeeSuccess) toggleToaster(addEmployeeMessage);

    return () => {
      if (isAddEmployeeSuccess) {
        clearToaster();
        dispatch(resetAddEmpData({}));
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addEmpDetails]);

  useEffect(() => {
    if (isUpdateSuccess) toggleToaster(updateMessage);

    return () => {
      if (isUpdateSuccess) clearToaster();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateRoleDetails]);

  useEffect(() => {
    if (!!dataFromOrg && dataFromOrg.success === true) {
      trackEvent(EmployeeToastEventName.DELETE_ACCOUNT_STATUS, {
        account_deletion_event_action: EmployeeToastEventName.DELETE_ACCOUNT_SUCCESS,
      });
      toggleToaster(`${dataFromOrg.name} is successfully deleted.`);
    }

    return () => {
      if (!!dataFromOrg && dataFromOrg.success === true) {
        clearToaster();
        dispatch(clearDeleteEmployeeFromOrg());
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataFromOrg]);

  return (
    <Toaster
      size={TOASTER_SIZE_TYPE.S}
      status={TOASTER_STATUS_TYPE.SUCCESS}
      visible={toaster.visible}
      message={toaster.message}
      onClose={() => clearToaster()}
    />
  );
};

export default Toast;
