import React, { useEffect, useMemo, useState } from "react";
import { ESidePanelButtonType, SidePanel } from "Modules/DS/SidePanel";
import BillsTrxnSidePanel from "Views/Transaction/Bills/BillsTrxnSidePanel";
import { useBillTrxnDetailsFetch } from "Views/Transaction/Bills/Hooks/useFetch";
import { useFetchInvoiceLink } from "Modules/BillImportedFromXeroBanner/useFetchInvoiceLink";

interface IApprovalBillSidePanelProps {
  selectedRow: any;
  showSideSection: boolean;
  setShowSideSection: (value: boolean) => void;
  handleApproveOrReject: (value: boolean, obj) => void;
}

const ApprovalBillSidePanel = ({
  selectedRow,
  showSideSection,
  setShowSideSection,
  handleApproveOrReject,
}: IApprovalBillSidePanelProps) => {
  const [confirmApprovalBtn, setConfirmApprovalBtn] = useState(false);
  const { fetchInvoiceLink, billImportedFromXeroData, setBillImportedFromXeroData, loadingXeroInvoiceLink } =
    useFetchInvoiceLink();
  const { data: invoiceData, loading: sidePanelDataLoading, fetchData } = useBillTrxnDetailsFetch();

  useEffect(() => {
    if (selectedRow.processID) {
      fetchData(selectedRow.processID);
    }
  }, [selectedRow.processID, fetchData]);

  useEffect(() => {
    if (invoiceData && invoiceData?.source === "portal_xero") {
      fetchInvoiceLink(invoiceData.id, "externalId");
    }
  }, [invoiceData]);

  const buttons = useMemo(() => {
    return [
      {
        id: "SIDE-PANEL-BUTTON-01",
        type: ESidePanelButtonType.CONSTRUCTIVE_ENABLE,
        enabled: true,
        disabled: false,
        text: "Approve",
        loading: false,
        action: async () => {
          handleApproveOrReject(true, null);
        },
        enableConfirmCallback: () => {
          setConfirmApprovalBtn(true);
        },
        disabledConfirmCallback: () => {
          setConfirmApprovalBtn(false);
        },
      },
      !confirmApprovalBtn
        ? {
            id: "SIDE-PANEL-BUTTON-2",
            type: ESidePanelButtonType.BORDERED_DESTRUCTIVE,
            enabled: !confirmApprovalBtn,
            disabled: false,
            text: "Decline",
            loading: false,
            action: async () => {
              handleApproveOrReject(false, null);
            },
          }
        : null,
    ].filter(Boolean);
  }, [confirmApprovalBtn]);

  return (
    <SidePanel
      title="Bill Payment"
      visible={showSideSection}
      loading={sidePanelDataLoading}
      buttons={buttons}
      onClose={() => {
        setShowSideSection(false);
        setBillImportedFromXeroData(null);
      }}
      sticky={true}
    >
      <BillsTrxnSidePanel id={selectedRow.id} invoiceData={invoiceData} importBillFromXero={billImportedFromXeroData} />
    </SidePanel>
  );
};

export default ApprovalBillSidePanel;
