export const INITIAL_TRXNS_PAGINATION = 0;

export const TRXNS_TABLE_LIMIT = 10;

export enum TRXN_TABLE_CLASS {
  ALL = "all_trxns_table",
  CARDS = "cards_trxns_table",
  BILL = "bill_trxns_table",
  SCHEDULED = "scheduled_trxns_table",
}
