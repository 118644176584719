import React from "react";
import cn from "classnames";
import { InfoOutline, Tooltip, Typography } from "@spenmo/splice";
import { IColumnData } from "Modules/DS/DataTable";

import { InputCell } from "Views/Transaction/Shimmer";
import { ALL_TRXNS_COLUMNS } from "Views/Transaction/Constants";
import { IGetTrxnResponse, GET_TRXN_RESPONSE_PARAMS } from "Views/Transaction/@types";
import { currencyFormatterV2 } from "utility";

import styles from "Views/Transaction/All/Columns/Columns.module.scss";

export const AmountCell = ({ record }: { record: IGetTrxnResponse }) => {
  // Adding logic to wrap negative amount in parentheses
  const formattedPaymentAmount = currencyFormatterV2(Math.abs(record.amount), record.currency, true, 2);
  const paymentAmount = record.amount < 0 ? `(${formattedPaymentAmount})` : formattedPaymentAmount;
  // recipient gets / invoice amount
  const isInvoiceAmount = Boolean(record?.foreign_currency_amount && record?.foreign_currency_code);

  const formattedInvoiceAmount = currencyFormatterV2(
    Math.abs(record?.foreign_currency_amount),
    record?.foreign_currency_code,
    true,
    2
  );
  const invoiceAmount = record?.foreign_currency_amount < 0 ? `(${formattedInvoiceAmount})` : formattedInvoiceAmount;

  if (isInvoiceAmount) {
    return (
      <div className={styles.invoiceTooltip}>
        <InputCell>
          <Tooltip placement="top" title={`Exchange Rate:\n${record?.foreign_exchange_rate}`}>
            <Typography variant="body-content" size="s" tag="p" className={styles.paymentAmount}>
              {paymentAmount}
            </Typography>
            <Typography variant="body-content" size="s" tag="p" className={styles.invoiceAmount}>
              {invoiceAmount}
            </Typography>
          </Tooltip>
        </InputCell>
      </div>
    );
  }

  return (
    <InputCell>
      <Typography variant="body-content" size="s" tag="p" className={styles.paymentAmount}>
        {paymentAmount}
      </Typography>
    </InputCell>
  );
};

export const amount = (): IColumnData<IGetTrxnResponse>["column"] => ({
  title: (
    <div className={cn(styles.withTooltip, styles.amountColumn)}>
      <Typography variant="body-content" size="s" tag="p" weight={600}>
        {ALL_TRXNS_COLUMNS.AMOUNT}
      </Typography>
      <Tooltip
        title={`Amounts in foreign\ncurrency are converted to\nyour dashboard currency\nusing Spenmo’s mid-\nmarket rates.`}
        placement="top"
      >
        <InfoOutline className={styles.icon} size="16" iconColor="var(--icon-default)" />
      </Tooltip>
    </div>
  ),
  width: 140,
  key: GET_TRXN_RESPONSE_PARAMS.AMOUNT,
  dataIndex: GET_TRXN_RESPONSE_PARAMS.AMOUNT,
  align: "right",
  fixed: "right",
  render: (_, record: IGetTrxnResponse): React.ReactNode => <AmountCell record={record} />,
});
