import React from "react";
import { blueCloseCircle } from "assets/img";
import Icon from "Modules/icons";

interface IFilterContent {
  isPopoverVisible?: boolean;
  emptyValue?: boolean;
  text: string;
  showValue?: boolean;
  showTextMultipleValues?: boolean;
  values: Array<string> | string;
  clearFilter?: (e?: Event) => void;
}

export default function FilterContent({
  isPopoverVisible,
  values = [],
  emptyValue = !values && values.length === 0,
  text,
  showValue,
  showTextMultipleValues,
  clearFilter,
}: IFilterContent) {
  if (isPopoverVisible || emptyValue) return <span>{text}</span>;
  const clearIcon = <Icon action={clearFilter} src={blueCloseCircle} alt="clear icon" className="popout__clear-icon" />;
  if (
    showValue ||
    ((Array.isArray(values) && values?.length === 1) || (typeof values === "string" && values.length)) > 0
  )
    return (
      <>
        <span className="popout__limit-text popout__capitalize">{values}</span>
        {clearIcon}
      </>
    );
  return (
    <>
      {showTextMultipleValues && `${text}:`}&nbsp;{" "}
      <span className="popout__limit-text popout__capitalize">{values?.[0] || ""}</span>
      {values?.length > 1 && <span className="popout__note-text">,&nbsp;+ {values.length - 1} other</span>}
      {clearIcon}
    </>
  );
}
