import { FETCH_CURRENCY, FETCH_CURRENCY_FAILURE, FETCH_CURRENCY_SUCCESS } from "../Actions/currencyAction";
import { userInitialState } from "../InitialState";

export const currencyReducer = (state = userInitialState, action) => {
  switch (action.type) {
    case FETCH_CURRENCY:
      return {
        ...state,
        loading: true,
      };
    case FETCH_CURRENCY_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
        error: false,
      };
    case FETCH_CURRENCY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
