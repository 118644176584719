import { horizontalPosition, siblingPosition, verticalPosition } from "./siblingPosition";
import { IOutsideViewport, POSITION_ALGO_TYPE } from "./index";

export const applyPositionAlgo = <T extends HTMLElement>(
  type: POSITION_ALGO_TYPE,
  view: IOutsideViewport,
  el: T
): [number, number] => {
  switch (type) {
    case POSITION_ALGO_TYPE.HORIZONTAL:
      return horizontalPosition<T>(view, el);
    case POSITION_ALGO_TYPE.VERTICAL:
      return verticalPosition<T>(view, el);
    case POSITION_ALGO_TYPE.SIBLING:
    default:
      return siblingPosition<T>(view, el);
  }
};
