import React from "react";
import Button from "Modules/button";
import { ISidePanelButton } from "../../type.d";
import classNames from "classnames";

import styles from "./styles.module.scss";

export const TextButton = ({ action, text, disabled, loading, shouldUseColSpan }: ISidePanelButton) => {
  return (
    <Button
      size="l"
      rounded
      textButton
      disabled={disabled || loading}
      className={classNames({ [styles["col-span-2"]]: shouldUseColSpan })}
      action={action}
    >
      {text}
    </Button>
  );
};
