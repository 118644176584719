import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useRef } from "react";
import qs from "query-string";
import { ControllerRenderProps, useFormContext } from "react-hook-form";

import Select from "../Select";
import { SelectProps } from "antd/lib/select";
import { useMutableData } from "API/useData";
import { API_URL } from "Views/Bills/V2/constants";

import { currencyFormatterV2, GetBaseAuthObject, GetCurrencyCode, isBookkeeper } from "utility";
import { getCookieValue, cookieNames } from "utility/CookieHelper";
import { mapTeamToWallet } from "utility/Bills";
import { USER_ROLE } from "constants/Team.constant";
import { SaaSConfig } from "Views/Bills/V2/BillForm/type";

/**
 * IMPROVEMENT: PayFrom component should able to use Select component
 * with a dynamic fetcher to fetch data
 */
const PayFrom = forwardRef((props: Partial<SelectProps<any> & ControllerRenderProps>, ref) => {
  const { value, onChange } = props;

  const selectRef = useRef(null);
  useImperativeHandle(ref, () => selectRef.current);

  const { setValue } = useFormContext();

  const orgId = GetBaseAuthObject().orgId;

  const userUrl = qs.stringifyUrl({
    url: `${API_URL.userInfo}/${GetBaseAuthObject().userId}`,
    query: {
      organisation_id: orgId,
    },
  });

  const walletUrl = qs.stringifyUrl({
    url: `${API_URL.org}/${orgId}/team-wallet`,
    query: {
      form_type: "bill",
    },
  });

  const { data: userData, isValidating: userLoading } = useMutableData(userUrl);
  const { data, isValidating } = useMutableData(walletUrl);

  const { data: saasConfigResponse } = useMutableData(API_URL.billpayConfig);
  const saasConfig: SaaSConfig = useMemo(() => saasConfigResponse?.data?.payload, [saasConfigResponse?.data?.payload]);

  const handleChangePayFrom = (value, option) => {
    onChange(value);
    setValue("teamID", option.teamID);
    setValue("walletID", option.walletID);
  };

  const walletLoading = userLoading || isValidating;

  const walletOptions = useMemo(() => {
    const userInfo = userData?.data?.payload?.user;
    const teamData = data?.data?.payload;

    if (teamData?.teams?.length && userInfo) {
      const currencyCode = GetCurrencyCode();
      const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: "base" });
      // should just sorted from the BE
      const sortedTeams = teamData.teams.sort((a, b) => collator.compare(a.name, b.name));

      const data = mapTeamToWallet({
        teams: sortedTeams,
        isAdmin: userInfo.is_admin,
        isAccountant: getCookieValue(cookieNames.USER_COMPANY_ROLE) === USER_ROLE.ACCOUNTANT,
        isBookkeeper: isBookkeeper(userInfo?.company_role),
        isCards2: teamData.is_balance_hidden,
        manageTeams: userInfo.manage_teams,
        saasConfig: saasConfig,
      });

      if (!value) {
        onChange(data?.[0]?.teamId);
        setValue("teamID", data?.[0]?.teamId);
        setValue("walletID", data?.[0]?.walletId);
      }

      return data.map((item) => {
        const { id, teamId, name, amount, walletId } = item;
        const currencyAmount = Number(amount) === 0 ? "" : `(${currencyFormatterV2(amount, currencyCode, true, 2)})`;

        return {
          value: id,
          teamID: teamId,
          walletID: walletId,
          label: `${name} ${currencyAmount}`,
        };
      });
    }

    return [];
  }, [data?.data?.payload, userData?.data?.payload?.user]);

  useEffect(() => {
    if (walletOptions.length) {
      const selectedWalletItem = walletOptions.find((item) => item.value === value);

      if (selectedWalletItem) {
        setValue("teamID", selectedWalletItem.teamID);
        setValue("walletID", selectedWalletItem.walletID);
      } else {
        onChange(walletOptions?.[0]?.value);
        setValue("teamID", walletOptions?.[0]?.teamID);
        setValue("walletID", walletOptions?.[0]?.walletID);
      }
    }
  }, [walletOptions]);

  return (
    <Select
      ref={selectRef}
      {...props}
      showSearch
      loading={walletLoading}
      filterOption={(input, option) => String(option?.label)?.toLowerCase().includes(input.toLowerCase())}
      options={walletOptions}
      onChange={handleChangePayFrom}
    />
  );
});

export default PayFrom;
