import React, { useRef, useState } from "react";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import { useSelector } from "react-redux";
import useLoading from "utility/useLoading";
import { sleep } from "Views/Bills/ImportBillFromXero/helper";
import { saveTransactionApi, updateTransactionMerchantName } from "Redux/DataCalls/Transactions.api";
import { TRANSACTION_TYPE } from "constants/Transaction.constant";
import { FIND_TRANSACTION_TYPE_INSIDE_BRACKET } from "../constant/constant";
import { useFormContext } from "../context/FormContext";
import { validateSyncAvailability } from "Redux/DataCalls/AccountingWorkflow.api";
import { detectChange } from "../Completed/helper";

export const useUpdateTransaction = (record, setRecord) => {
  const isReflected = useRef(true);
  const [loadingSaveRefund, setLoadingsaveRefund] = useState(false);
  const [saveTransaction, loadingSaveTransaction] = useLoading(saveTransactionApi, null, {}, false);
  const [processValidation, loadingValidateTransaction] = useLoading(validateSyncAvailability, null, {}, false);
  const [updateMerchant, loadingMerchant] = useLoading(updateTransactionMerchantName, null, {}, false);
  const categoriesData = useSelector((state: any) => state.userInfoReducer?.data?.payload?.categories);
  const { formValue, expenseNotFound, selectedExpense } = useFormContext();

  const updateRefundTransaction = async () => {
    setLoadingsaveRefund(true);
    const requestBody = {
      transaction_number: record.transactionNumber,
      simplified_merchant_name: formValue.merchant || record.merchant,
      transaction_id: record.id,
    };

    if (formValue.transactionTags) {
      requestBody["tags"] = formValue.transactionTags.map((tag) => ({ label: tag.label, value: tag.value }));
    }

    const { data: responseData } = await updateMerchant(requestBody);
    setLoadingsaveRefund(false);

    if (responseData.status === HTTP_STATUS_CODE.OK) {
      return requestBody;
    }

    return Promise.reject(responseData);
  };

  const cleanUpData = (formValue) => {
    const cleanTaxId = formValue.tax ? { tax_id: formValue.tax } : {};

    return {
      ...cleanTaxId,
      simplified_merchant_name: formValue.merchant || record.merchant,
      photo_urls: (formValue.receipt || []).slice((record.receipt || []).length),
      transaction_number: record.transactionNumber,
      notes: formValue.notes !== undefined ? formValue.notes : record.comment,
      tags: (formValue.transactionTags || []).map((tag) => ({ label: tag.label, value: tag.value })),
      expense_category_id: formValue.category !== undefined ? formValue.category : record.category,
    };
  };

  const updateTransaction = async ({ preventDoubleSync = false } = {}) => {
    isReflected.current = false;
    const findTransactionType = record.transactionType.match(FIND_TRANSACTION_TYPE_INSIDE_BRACKET) || [];
    const isRefund = findTransactionType[1] === TRANSACTION_TYPE.REFUND;
    const isReversal = findTransactionType[1] === TRANSACTION_TYPE.REVERSAL;
    const isFee = record.transactionType.includes(TRANSACTION_TYPE.FEES);
    const isIncentive = record.transactionType === TRANSACTION_TYPE.INCENTIVE;
    const notRequiredApiCall = [TRANSACTION_TYPE.TOPUP, TRANSACTION_TYPE.FEES_REFUND].includes(record.transactionType);

    if (notRequiredApiCall || isReversal) {
      isReflected.current = true;
      return Promise.resolve({});
    }

    if (expenseNotFound) {
      return updateRefundTransaction();
    }

    const data = cleanUpData(formValue);

    if (isRefund || isFee || isIncentive) {
      data["transaction_id"] = record.id;
    }

    if (preventDoubleSync) {
      data["is_force_sync"] = true; // This flag introduced to prevent intermitten double sync data to Xero
    }

    const { data: responseData } = await saveTransaction(data);

    const change = detectChange(selectedExpense, formValue);

    // Adding sleep here to make sure we are waiting for the change
    // reflected on reporting service before we close the sidepanel
    // Only when updating category / receipt which shown on the table list
    if (change.includes("category") || change.includes("receipts")) {
      await sleep(1);
    }
    isReflected.current = true;

    if (responseData.status === HTTP_STATUS_CODE.OK) {
      // Update record value for receipt after saving to make sure it won't double upload the data.
      setRecord({ ...record, receipt: formValue.receipt });
      return data;
    }
    isReflected.current = true;

    return Promise.reject(data);
  };

  const findCategoryId = (categoryName: string) => {
    return categoriesData.find((category) => category.category_name === categoryName) || {};
  };

  const validateTransaction = async () => {
    const data = {
      taxId: formValue.tax,
      simplifiedMerchantName: formValue.merchant || record.merchant,
      receipts: formValue.receipt,
      transactionNumber: record.transactionNumber,
      notes: formValue.notes !== undefined ? formValue.notes : record.comment,
      tags: (formValue.transactionTags || []).map((tag) => ({ label: tag.label, value: tag.value })),
      categoryId: formValue.category !== undefined ? formValue.category : findCategoryId(record.category).id,
      transactionId: record.id,
      accountingTransactionType: record.accountingTransactionStatus,
    };

    const { data: responseData } = await processValidation(data);

    if (responseData.status === HTTP_STATUS_CODE.OK) {
      return {
        isValid: responseData?.payload?.isValid && responseData?.payload?.missingFields.length === 0,
        missingFields: responseData?.payload?.missingFields,
      };
    }

    return {
      isValid: false,
    };
  };

  return {
    updateTransaction,
    loadingSaveTransaction: loadingSaveTransaction || loadingSaveRefund || !isReflected.current,
    validateTransaction,
    loadingValidateTransaction: loadingSaveTransaction || loadingMerchant || loadingValidateTransaction,
    updateMerchant,
  };
};
