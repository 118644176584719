import * as React from "react";
import { Link } from "react-router-dom";
import Icon from "Modules/icons";
import { rightArrowRoundedBlue } from "assets/img";
import { cardEvents } from "Views/Home/Analytics";
import { trackEvent } from "utility/analytics";

import styles from "Modules/CompactCard/CompactCard.module.scss";

const ViewAllCard = () => {
  const trackViewAllCards = () => {
    trackEvent(cardEvents.VIEW_ALL_CARDS);
  };
  return (
    <Link to="/cards" className={styles["card-view-btn"]} onClick={trackViewAllCards}>
      <Icon src={rightArrowRoundedBlue} alt="right arrow" />
      View all cards
    </Link>
  );
};

export default ViewAllCard;
