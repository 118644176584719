import { putData } from "API/Client";

import { useAppSelector } from "hook";

import { GetOrgId } from "utility";
import { parseToURLEncodedForm } from "utility/APIWrapper";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";

import {
  appNotification,
  contactCSErrorMessage,
} from "Modules/appNotification/appNotification";

import { API_URL } from "Views/Reimbursement/DataCalls/Services";
import { IReimbursementDetailData } from "Views/Reimbursement/@types";

const useSaveRequest = (): {
  saveRequest: (
    id: string,
    formValue: any,
    data: IReimbursementDetailData,
  ) => Promise<void>;
} => {
  const saveRequest = async (
    id: string,
    formValue: any,
    data: IReimbursementDetailData,
  ) => {
    try {
      const cleanTax = formValue.tax ? { tax_id: formValue.tax } : {};

      const params = {
        amount: { amount: data.amount, currency: data.currency_code },
        requestor: {
          user_id: data.user_id,
          team_id: data.team_id,
          type: "user",
        },
        request: {
          ...cleanTax,
          type: "reimbursement",
          image_urls: formValue.receipt || [],
          notes: formValue.notes !== undefined ? formValue.notes : data.notes,
          tags:
            formValue.transactionTags?.map((tag) => ({
              label: tag.label,
              value: tag.value,
            })) || [],
          expense_category_id:
            formValue.category !== undefined &&
            formValue.category !== data.expense_category_name &&
            formValue.category !== data.expense_category_id
              ? formValue.category
              : data.expense_category_id,
        },
        organisation_id: GetOrgId(),
      };

      const config = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      };

      const response = await putData(
        API_URL.DETAILS.SAVE_CHANGES(id),
        parseToURLEncodedForm(params),
        false,
        config,
      );

      if (response?.data?.status !== HTTP_STATUS_CODE.OK) {
        throw new Error(response?.data?.status);
      }

      appNotification.success({
        message: "You have successfully saved changes to your reimbursement",
      });
    } catch (err) {
      appNotification.error(contactCSErrorMessage(err.message));
    }
  };

  return {
    saveRequest,
  };
};

export default useSaveRequest;
