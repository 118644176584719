import * as React from "react";
import { Checkbox } from "antd";
import SelectItem from "../selectItem";
import Search from "../search";
import { searchText } from "utility";
import LetterAvatar from "Modules/letterAvatar";
import CircleLoader from "Modules/loading";
import "./multiSelection.scss";

const NO_SELECTED_VALUE = "none";
const { useState, useEffect } = React;

export interface ISelectionData {
  name?: string;
  label?: string;
  value?: string | boolean;
}

interface IDropdownSelection {
  listDataLoading?: boolean;
  listData: Array<ISelectionData>;
  selectedList: Array<string>;
  initialValues?: Array<string>;
  enableSearch?: boolean;
  letterAvatar?: boolean;
  prefix?: string;
  priorityCheckedList?: boolean;
  setSelectedList: (selectList: Array<string>) => void;
}

const DropdownSelection = ({
  listData,
  listDataLoading,
  selectedList,
  initialValues,
  setSelectedList,
  enableSearch = true,
  letterAvatar = false,
  prefix = "",
  priorityCheckedList = true,
}: IDropdownSelection) => {
  const [searchValue, setSearchValue] = useState("");
  const [filteredList, setFilteredList] = useState(listData);

  useEffect(() => {
    setFilteredList(listData);
  }, [listData]);

  const renderSelectItems = filteredList?.map((item) => (
    <Checkbox value={item?.name} key={item?.name}>
      {letterAvatar && <LetterAvatar name={item?.label} rounded />}
      {prefix}
      {item?.label}
    </Checkbox>
  ));

  const handleSelectOnSearch = (list) => {
    let selectedItems = Array.from(new Set(list.concat(selectedList)));
    return selectedItems?.filter?.((name) => name !== NO_SELECTED_VALUE);
  };

  const getCheckedList = (list) =>
    list?.filter?.((name) => name !== NO_SELECTED_VALUE)?.map((name) => ({ name: name, label: name?.toLowerCase() }));

  const getUnCheckedList = (selected, list) => {
    return list?.filter((item) => !selected.some((name) => item?.name === name));
  };

  const handleSortList = (selected, list) => {
    if (!priorityCheckedList) return;
    const checkedList = getCheckedList(selected);
    const unCheckedList = getUnCheckedList(selected, list);
    setFilteredList(checkedList.concat(unCheckedList));
  };

  useEffect(() => {
    if (initialValues?.length > 0) {
      const selectedValues = initialValues?.filter((item) => item !== NO_SELECTED_VALUE);
      handleSortList(selectedValues, filteredList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleSortList(selectedList, filteredList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedList]);

  const handleSelectedList = (list) => {
    let selectedItems = searchValue !== "" ? handleSelectOnSearch(list) : list;
    const unCheckedList = getUnCheckedList(list, filteredList);

    selectedItems =
      searchValue !== ""
        ? selectedItems.filter((name) => !unCheckedList.find((item) => item.name === name))
        : selectedItems;
    setSelectedList([...selectedItems]);
  };

  useEffect(() => {
    if (searchValue === "" && filteredList?.length !== listData?.length) {
      handleSortList(selectedList, listData);
    } else if (searchValue) {
      setFilteredList(searchText(searchValue, listData));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listData, searchValue]);

  return (
    <div data-testid="dropdown-selection" className={"multi-selection-option"}>
      {listDataLoading ? (
        <CircleLoader />
      ) : (
        <>
          {enableSearch && <Search getValue={setSearchValue} />}
          <SelectItem data={renderSelectItems} selectedData={selectedList} action={handleSelectedList} />
        </>
      )}
    </div>
  );
};

export default DropdownSelection;
