import React from "react";
import { DatePicker, Form } from "antd";
import { IKYXDatePickerProps } from "Views/KYX/@types";

/**
 * A component that renders a date picker.
 *
 * @param {IKYXDatePickerProps} dateProps - Any additional props to pass to the underlying Ant Design `DatePicker` component.
 * @returns {JSX.Element} - The rendered `KYXDatePicker` component.
 */

export const KYXDatePicker = ({ formProps, dateProps }: IKYXDatePickerProps): React.ReactElement => {
  return (
    <Form.Item {...formProps}>
      <DatePicker {...dateProps} />
    </Form.Item>
  );
};
