import { urlParamsBuilder } from "../../utility/APIWrapper";
import { GetOrgId } from "../../utility";
import AxiosGetForApiWrapper from "utility/apiWrapperHelper";

export const GetAllUsers = (pageNumber = 0, pageSize = 10) => {
  const orgId = GetOrgId();
  return AxiosGetForApiWrapper(`/api/v1/org/${orgId}/employees?pg=${pageNumber}&limit=${pageSize}`);
};

export const getAllEmployees = (params) => {
  const orgId = GetOrgId();

  const url = urlParamsBuilder(`/ms/spm-organisation/v1/approval/workflow/approvers`, {
    ...params,
    organisationId: orgId,
  });
  return AxiosGetForApiWrapper(url);
};

// v3/approvals/approvers
