import useSWRMutation from "swr/mutation";
import { postData } from "API/Client";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";

export const useUploadFile = () => {
  return useSWRMutation<string | null, any, string, { file: File }>(
    "ms/spm-organization/v1/subscriptions/documents",
    (url, { arg }) => {
      let formData = new FormData();
      formData.append("file", arg.file);
      return postData(url, formData).then(({ data }) => {
        const { status, payload } = data || {};
        if (status === HTTP_STATUS_CODE.OK && payload?.url) {
          return payload.url;
        }
        return null;
      });
    }
  );
};
