import React from "react";

import { AMOUNT_RANGE } from "Modules/DS/Select/@types";

import { ITitleWithID, ITrxnFilterItem } from "Views/Transaction/@types";
import { AttachmentFilter, CategoryFilter, More, TrxnTypeMoreFilter } from "Views/Transaction/Filters";

import statusJson from "./Status.json";
import { TRANSACTION_FILTER } from "./types";

export const TRXNS_MORE_FILTER_KEYS: string[] = [
  TRANSACTION_FILTER.CATEGORY_ID,
  TRANSACTION_FILTER.ATTACHMENTS,
  TRANSACTION_FILTER.TRXNS_TYPE,
  TRANSACTION_FILTER.SYNC_STATUS,
  AMOUNT_RANGE.MIN,
  AMOUNT_RANGE.MAX,
];

export const SyncFilter = ({ id }: { id: string }) => {
  return (
    <More.Multiple<ITitleWithID> id={id} label="Sync Status" displayKey="title" appliedKey="id">
      {statusJson.xero_sync_status}
    </More.Multiple>
  );
};

export const TRXNS_MORE_FILTER_LIST: ITrxnFilterItem[] = [
  { id: "ALL-TRXN-MORE-FILTER-1", enabled: true, Component: <TrxnTypeMoreFilter id={TRANSACTION_FILTER.TRXNS_TYPE} /> },
  {
    id: "ALL-TRXN-MORE-FILTER-2",
    enabled: true,
    Component: <CategoryFilter id={TRANSACTION_FILTER.CATEGORY_ID} appliedKey="id" />,
  },
  { id: "ALL-TRXN-MORE-FILTER-3", enabled: true, Component: <SyncFilter id={TRANSACTION_FILTER.SYNC_STATUS} /> },
  { id: "ALL-TRXN-MORE-FILTER-4", enabled: true, Component: <AttachmentFilter id={TRANSACTION_FILTER.ATTACHMENTS} /> },
  { id: "ALL-TRXN-MORE-FILTER-5", enabled: true, Component: <More.Amount title="Amount" /> },
].filter((item) => item.enabled);
