import APIclient from "API/Client";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import { trackEvent } from "utility/analytics";
export const SKIP_STEPS = "SKIP_STEPS";
export const SKIP_STEPS_REQUEST = `${SKIP_STEPS}_REQUEST`;
export const SKIP_STEPS_SUCCESS = `${SKIP_STEPS}_SUCCESS`;
export const SKIP_STEPS_FAILURE = `${SKIP_STEPS}_FAILURE`;
export const SKIP_STEPS_CLEAR = `${SKIP_STEPS}_CLEAR`;

export const fetchDataRequest = (data) => {
  return {
    type: "SKIP_STEPS_REQUEST",
    data: data,
  };
};

export const fetchDataSuccess = (data) => {
  return {
    type: "SKIP_STEPS_SUCCESS",
    data: data.payload,
  };
};

export const fetchDataError = (data) => {
  return {
    type: "SKIP_STEPS_FAILURE",
    data: data,
  };
};

export const fetchDataClear = (data) => {
  return {
    type: SKIP_STEPS_CLEAR,
    data: data,
  };
};

const skipDirectorId = () => {
  const params = { event: "director id skiped" };
  trackEvent("Skip Upload Director's ID", params);
};

export const skipOnboardingStep = (step) => {
  return (dispatch) => {
    dispatch(fetchDataRequest(step));

    APIclient.postData(`/ms/spm-organization/v1/onboarding/step`, {
      step,
      isSkipped: true,
    })
      .then((data) => {
        if (data.data.status === HTTP_STATUS_CODE.CREATED) {
          skipDirectorId(); //To track skip of director id for analytics
          dispatch(fetchDataSuccess(data.data));
        } else {
          dispatch(fetchDataError(data.data));
        }
      })
      .catch((err) => {
        dispatch(fetchDataError(err));
      });
  };
};

export const clearSkipOnboardingStep = () => {
  return (dispatch) => {
    dispatch(fetchDataClear(null));
  };
};
