import React, { useMemo } from "react";
import qs from "query-string";
import { SelectProps } from "antd/lib/select";

import Select from "Views/Bills/V2/components/FormGenerator/Select";
import { useMutableData } from "API/useData";

import { API_URL } from "Views/Bills/V2/constants";
import { TAX_STATUS } from "constants/Tax.constant";
import { roundValue } from "utility";
import { useFormContext } from "react-hook-form";

const Tax = (props: SelectProps<any>) => {
  const { formState, resetField } = useFormContext();
  const { defaultValues = null } = formState;
  const { billNumber = null, taxID = null } = defaultValues;
  const apiUrl = qs.stringifyUrl({
    url: API_URL.tax,
    query: {
      status: TAX_STATUS.ACTIVE,
    },
  });
  const { data, isValidating } = useMutableData(apiUrl);

  const taxList = useMemo(() => {
    if (data?.data?.payload?.data?.length) {
      return data?.data?.payload?.data.map((tax) => {
        const taxObj = {
          value: tax.id,
          label: `${tax.tax_name} ${roundValue(tax.tax_rate)}%`,
        };
        if (billNumber && tax.id === taxID) {
          resetField("taxID", {
            defaultValue: taxObj.label,
          });
        }
        return taxObj;
      });
    }

    return [];
  }, [data?.data, billNumber]);

  return (
    <Select
      {...props}
      showSearch
      loading={isValidating}
      filterOption={(input, option) => String(option?.label)?.toLowerCase().includes(input.toLowerCase())}
      options={taxList}
    />
  );
};

export default Tax;
