import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { IEmployeesReducer } from "Redux/Actions/Employee/Get/Employees/types";

const useEmployeesCount = () => {
  const [empCount, setEmpCount] = useState(0);

  const employeesData: IEmployeesReducer = useSelector((state: any) => state.allEmployeesReducer);
  const total: number = employeesData?.data?.payload?.total_count;

  useEffect(() => {
    !empCount && total && setEmpCount(total);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [total]);

  return empCount;
};

export default useEmployeesCount;
