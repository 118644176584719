import React, { useState, useMemo } from "react";
import { useLocation } from "react-router";
import qs from "query-string";

import { useSetUrlParams } from "Views/SubscriptionManagement/hooks/useSetUrlParams";

import { SingleSelect, ActionChip } from "@spenmo/splice";

import { SORT_BY_VALUES, ISubscriptionManagementFilterKeys } from "Views/SubscriptionManagement/@types/filters";

import styles from "./index.module.scss";

const SORT_BY_OPTIONS = [
  {
    label: "Default (Creation date)",
    value: SORT_BY_VALUES.CREATED_DATE,
  },
  {
    label: "Estimated cost (High to Low)",
    value: SORT_BY_VALUES.ESTIMATED_COST,
  },
  {
    label: "Renewal / Expiry date (Soonest first)",
    value: SORT_BY_VALUES.EXPIRY_DATE,
  },
];

export const SortByFilter = () => {
  const [open, setOpen] = useState(false);
  const setUrlParams = useSetUrlParams();
  const { search, pathname } = useLocation();
  const query = useMemo(() => qs.parse(search) ?? {}, [search]);

  const sortValue = query?.[ISubscriptionManagementFilterKeys.SORT_BY];

  const onSelect = (value: string) => {
    setUrlParams(pathname, {
      ...query,
      [ISubscriptionManagementFilterKeys.SORT_BY]: value,
    });
    setOpen(false);
  };

  const clearFilter = () => {
    const newFilters = { ...query };
    delete newFilters[ISubscriptionManagementFilterKeys.SORT_BY];
    setUrlParams(pathname, newFilters);
    setOpen(false);
  };

  const selectedSortLabel = SORT_BY_OPTIONS.find((item) => item.value === sortValue)?.label || "Sort By";

  return (
    <SingleSelect
      onSelect={onSelect}
      options={SORT_BY_OPTIONS}
      size="xs"
      className={styles.overflowContent}
      open={open}
      onOpenChange={(open) => setOpen(open)}
    >
      <ActionChip label={selectedSortLabel} active={!!sortValue} onDelete={clearFilter} />
    </SingleSelect>
  );
};
