import {
  FETCH_MONEY_REQUEST,
  FETCH_MONEY_SUCCESS,
  FETCH_MONEY_FAILURE,
  CLEAR_FETCH_SEND_MONEY,
  FETCH_SEND_MONEY_REQUEST,
  FETCH_SEND_MONEY_SUCCESS,
  FETCH_SEND_MONEY_FAILURE,
} from "../Actions/moneyAction";
import { userInitialState } from "../InitialState";

export const moneyReducer = (state = userInitialState, action) => {
  switch (action.type) {
    case FETCH_SEND_MONEY_REQUEST:
      return {
        ...state,
        sendData: action.data,
        sendLoading: true,
        sendError: false,
      };
    case FETCH_SEND_MONEY_SUCCESS:
      return {
        ...state,
        sendData: action.data,
        sendLoading: false,
      };
    case FETCH_SEND_MONEY_FAILURE:
      return {
        ...state,
        sendData: action.data,
        sendError: true,
        sendLoading: false,
      };
    case CLEAR_FETCH_SEND_MONEY:
      return userInitialState;
    case FETCH_MONEY_REQUEST:
      return {
        ...state,
        data: action.data,
        loading: true,
        error: false,
      };
    case FETCH_MONEY_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
      };
    case FETCH_MONEY_FAILURE:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: true,
      };
    default:
      return state;
  }
};
