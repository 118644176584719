import { useContext, useState } from "react";

import { IItemID } from "Modules/DS/Menu";
import { ITableProps } from "Modules/DS/DataTable";
import { ISelectContext, SelectContext } from "Modules/DS/Select";

import { IUseSelectedRows } from "Views/Transaction/@types";

export const useSelectedRows = <T extends IItemID>(): IUseSelectedRows<T> => {
  const { closeSelect } = useContext<ISelectContext>(SelectContext);

  const [selectedRows, setSelectedRows] = useState<T[]>([]);

  const reset = () => setSelectedRows([]);

  const onChangeHandler = (_: React.Key[], selected: T[]) => {
    setSelectedRows(selected);
    closeSelect();
  };

  const props: ITableProps<T>["rowSelection"] = {
    preserveSelectedRowKeys: true,
    selectedRowKeys: selectedRows?.map((item) => item?.id) || [],
    onChange: onChangeHandler,
  };

  const emptyProps: ITableProps<T>["rowSelection"] = {
    selectedRowKeys: [],
  };

  return { get: selectedRows, set: setSelectedRows, reset, props, emptyProps };
};
