import React from "react";

import { IUpdateIntro } from "Views/KYX/@types";
import { InformationLoader } from "Views/KYX/Loader";
import { KYX_ORG_COUNTRY_LIST } from "Views/KYX/Constants";

import { WithoutAutomatedSource } from "./Manual";

const ReviewedUser = ({ countryCode }: IUpdateIntro): JSX.Element => {
  let Component: JSX.Element;

  switch (countryCode) {
    case KYX_ORG_COUNTRY_LIST.IDR:
      Component = <WithoutAutomatedSource countryCode={countryCode} />;
      break;
    case KYX_ORG_COUNTRY_LIST.SGD:
      Component = <WithoutAutomatedSource countryCode={countryCode} />;
      break;
    default:
      Component = <InformationLoader />;
      break;
  }

  return Component;
};

export default ReviewedUser;
