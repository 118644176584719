import { Popover } from "antd";
import { AccountingTagsContext } from "Modules/AccountingTags/context/AccountingTagsContext";
import React, { useContext } from "react";
import TagGroup from "../TagGroup";
import TagOption from "../TagOption";

import "./styles.scss";

const TagOptionWrapper = () => {
  const { openSubMenu } = useContext(AccountingTagsContext);
  return (
    <Popover
      visible={openSubMenu}
      placement="leftTop"
      overlayClassName="transaction-tags"
      content={TagOption}
      getPopupContainer={() => document.querySelector(".accounting-tags")}
    >
      <TagGroup />
    </Popover>
  );
};

export default TagOptionWrapper;
