import React, { useContext } from "react";

import { ModalComponent } from "Modules/DS/Modal";
import { TITLE_TYPE } from "Modules/DS/Modal/types";

import { TrxnModalContext } from "Views/Transaction/Context";
import { ITrxnModalContext } from "Views/Transaction/@types";
import { CSV_EXPORT_WARNING } from "Views/Transaction/Constants";

import { FlatIcon } from "assets/v1.1/icons";
import styles from "./ExportWarning.module.scss";

export const ExportWarningModal = ({ limit, download }: { limit: number; download: () => void }) => {
  const { resetModal }: ITrxnModalContext = useContext(TrxnModalContext);

  return (
    <>
      <ModalComponent.Title titleType={TITLE_TYPE.largeWithBorder}>Download Data</ModalComponent.Title>
      <div className={styles.container}>
        <p className={styles.info}>
          {limit}+ transactions are selected for download. Estimated export time needed: &gt; 30 min
        </p>
        <div className={styles.buttonGroup}>
          <button className={styles.cancel} onClick={resetModal}>
            Cancel
          </button>
          <button className={styles.download} onClick={download}>
            Download
          </button>
        </div>
        <div className={styles.banner}>
          <img src={FlatIcon.grayInfo} alt="info icon" />
          <p>
            Use the transactions filter to specifically download the data you need. Filtered data is also quicker to
            download.
          </p>
        </div>
      </div>
    </>
  );
};

export const CsvExportWarningModal = ({ download }: { download: () => void }) => {
  return <ExportWarningModal download={download} limit={CSV_EXPORT_WARNING} />;
};
