import React from "react";

import {
  IDropdownBuilder,
  IDropdownContainer,
  initialDropdownProps,
  initialBasicDropdownStates,
  IDropdownContainerWithSection,
} from "Modules/DS/Select/@types";
import { IItemID } from "Modules/DS/Menu";
import { ListContainer, SectionList } from "Modules/DS/Filter/Quick/Container/List";

import { DropdownBuilder } from "./Builder";

export const Container = <T extends object = any>({
  children,
  listProps,
  header = null,
  footer = null,
  states = { ...initialBasicDropdownStates },
  dropdownProps = { ...initialDropdownProps },
}: IDropdownContainer<T>): JSX.Element => {
  const props: Omit<IDropdownBuilder, "children"> = {
    states,
    ...dropdownProps,
    ...(header && { header }),
    ...(footer && { footer }),
  };

  return (
    <DropdownBuilder {...props}>
      <ListContainer {...listProps}>{children}</ListContainer>
    </DropdownBuilder>
  );
};

export const ContainerWithSection = <T extends IItemID, K extends object = any>({
  children,
  listProps,
  header = null,
  footer = null,
  states = { ...initialBasicDropdownStates },
  dropdownProps = { ...initialDropdownProps },
}: IDropdownContainerWithSection<T, K>): JSX.Element => {
  const props: Omit<IDropdownBuilder, "children"> = {
    states,
    ...dropdownProps,
    ...(header && { header }),
    ...(footer && { footer }),
  };

  return (
    <DropdownBuilder {...props}>
      <SectionList {...listProps}>{children}</SectionList>
    </DropdownBuilder>
  );
};
