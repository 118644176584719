import { getCurrencyLocale } from "utility";

const cleanupAmount = (amount: string, currency: string) => {
  let cleanAmount = amount.replace(/,/g, "");

  if (currency === "IDR") {
    cleanAmount = cleanAmount.replace(/\./g, "");
  }

  // If there are 3 decimal value we will remove the last decimal
  // to make sure we are not rounding up the decimal value
  const [, decimalValue = ""] = cleanAmount.split(decimalIndicator(currency));

  if (decimalValue.length === 3) {
    cleanAmount = cleanAmount.slice(0, -1);
  }

  return cleanAmount;
};

const joinAmount = (regexMatch: RegExpMatchArray | null) => {
  if (!regexMatch) {
    return 0;
  }

  // If the regexMatch array only consist of one element, we will return the first element inside the array.
  if (regexMatch.length === 1) {
    return regexMatch[0];
  }

  return regexMatch.reduce((result, amount, index) => {
    result += amount;

    // Adding decimal pointer before the last element inside the array
    // i.e we have [100, 22] then this logic will add . as the decimal pointer
    // result example 100.22
    if (index === regexMatch.length - 2) {
      result += ".";
    }

    return result;
  }, "");
};

export const moneyFormat = (amount: string, currency: string) => {
  const cleanAmount = cleanupAmount(amount, currency);

  const regexMatch = cleanAmount.match(/\d+/g);

  const onlyNumber = Number(joinAmount(regexMatch));

  return new Intl.NumberFormat(getCurrencyLocale(currency), { maximumFractionDigits: 2 }).format(onlyNumber);
};

export const hasDecimalPointer = (amount: string) => {
  return amount.endsWith(".") && amount.match(/[.]/g)?.length === 1;
};

const decimalIndicator = (currency: string) => {
  if (currency === "IDR") {
    return ",";
  }
  return ".";
};

export const replaceThousandIndicator = (amount: string, currency: string) => {
  if (currency === "IDR") {
    return amount.replace(/\./g, "");
  }
  return amount.replace(/,/g, "");
};
