import { TABS } from "./TransactionsHelper";
import { CHIP_STATUS } from "Modules/DS/Chip";

export enum APPROVAL_ACTIONS {
  approve = "approve",
  decline = "decline",
  save = "save changes",
  setApprover = "set approver",
}

export const CLICK_APPROVAL_EVENT = (
  action: APPROVAL_ACTIONS,
  isBulk?: boolean,
  isConfirm?: boolean,
  isRequestApproval?: boolean
) =>
  `bill ${isBulk ? "many requests" : "single request"} ${isConfirm ? "confirm to " : ""}${action} ${
    isRequestApproval ? "request approval " : ""
  }button click`;

export const APPROVAL_STATUS_EVENT = (action: APPROVAL_ACTIONS, isSuccess) =>
  `${isSuccess ? "successfully" : "failed"} ${
    action === APPROVAL_ACTIONS.setApprover ? `${action} for` : action
  } bill requests`;

export const DETAIL_APPROVAL_EVENT = (action: APPROVAL_ACTIONS, isConfirm?: boolean, tab?: "approvals" | "all") =>
  `detail page ${isConfirm ? "confirm to " : ""}${action} button click on bills ${tab} page`;

export const EMAIL_APPROVAL_EVENT = (action: APPROVAL_ACTIONS, viewport: "desktop" | "mobile") =>
  `bill click ${action} on email on ${viewport}`;

export const getTransactionTabs = (
  isBillpayAvailable,
  isCardsTrxnsTabEnabled,
  pendingApprovalCount,
  extractingCount
) => {
  const result = [];
  result.push({ title: TABS.all });
  // only show pending tab if billpay is available
  if (isBillpayAvailable) {
    result.push({ title: TABS.pending, isIndicator: pendingApprovalCount > 0 || extractingCount > 0 });
  }
  if (isCardsTrxnsTabEnabled) {
    result.push({ title: TABS.cards });
  }
  result.push({ title: TABS.scheduled });
  return result;
};

export const STATUS_COLOR_MAP = {
  "#1E97EE": CHIP_STATUS.INFO,
  "#1FCE8F": CHIP_STATUS.SUCCESS,
  "#E5304C": CHIP_STATUS.FAILED,
  "#F65731": CHIP_STATUS.ATTENTION,
  "#FAAD14": CHIP_STATUS.NEUTRAL,
};

export const APPROVAL_ANY_ADMIN = "Any Admin";
export const APPROVAL_BUDGET_OWNER = "Budget Owner";

export const getApproverNames = (approvers = [], separator = " / ") =>
  approvers
    .sort((a, b) => {
      if (a?.name === APPROVAL_ANY_ADMIN) return -1;
      if (b?.name === APPROVAL_ANY_ADMIN) return 1;
      if (a?.name === APPROVAL_BUDGET_OWNER) return -1;
      if (b?.name === APPROVAL_BUDGET_OWNER) return 1;
      return 0;
    })
    .reduce((acc, curval) => {
      return `${acc}${acc && separator}${curval?.name}`;
    }, "");
