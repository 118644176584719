import React from "react";
import PropTypes from "prop-types";

import { uploadImageIcon, successUploadIcon } from "../../assets/img";
import Icon from "../icons";
import Button from "../button";
import { Progress } from "antd";

const UploadStatusView = (props) => {
  const { fileInfo, formatMessage, onRemove, successMsg, progress, bulkError = false } = props;
  const { status, name } = fileInfo;

  const showSuccessMsg = (message) => {
    return (
      <div className={"file-upload__success-container"}>
        <p className={"rows-msg"}>{message}</p>
      </div>
    );
  };

  switch (status) {
    case "success":
    case "done":
      return (
        <>
          <Icon className={"upload-icon"} src={successUploadIcon} />
          <h4>File uploaded</h4>
          <div className={"upload-message__container"}>
            <h5 style={{ paddingTop: "40px" }}>{name}</h5>
            <Button action={onRemove} text={"Change file"} textButton />
            {showSuccessMsg(successMsg)}
          </div>
        </>
      );
    case "uploading":
      return (
        <>
          <Icon className={"upload-icon"} src={uploadImageIcon} />
          <h4>Uploading your file</h4>
          <div className={"upload-message__container"}>
            <h5 style={{ paddingTop: "40px" }}>{name}</h5>
            <Progress
              className={"upload-message__container__progress-bar"}
              strokeWidth={8}
              strokeColor={"#1FCE8F"}
              percent={progress}
              showInfo={false}
            />
          </div>
        </>
      );
    case "error":
      return (
        <>
          <Icon className={"upload-icon"} src={uploadImageIcon} />
          <h5 style={{ marginBottom: "40px" }}>{name}</h5>
          {bulkError && <p className={"bulk-error-msg"}>Wrong Columns / data on the file</p>}
          <Button text={"Browse files"} rounded />
        </>
      );
    default:
      return (
        <>
          <Icon className={"upload-icon"} src={uploadImageIcon} />
          <h4>Drop a file here to get started</h4>
          <p className={"message"}>{formatMessage}</p>
          <Button text={"Browse files"} rounded />
        </>
      );
  }
};

UploadStatusView.propTypes = {
  fileInfo: PropTypes.any,
  formatMessage: PropTypes.string,
  errorMsg: PropTypes.string,
  onRemove: PropTypes.func,
  successMsg: PropTypes.any,
  progress: PropTypes.number,
  bulkError: PropTypes.bool,
};

export default UploadStatusView;
