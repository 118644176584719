import React, { useEffect, useRef, useState } from "react";
import { Form, Input, Select } from "antd";
import { FormInstance } from "antd/lib/form";
import { useDispatch, useSelector } from "react-redux";

import Button from "Modules/button";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import LoaderIcon from "Views/State/Loading/LoaderIcon";
import TextWithTooltip from "Views/Taxes/components/TextWithTooltip";
import { trackEvent } from "utility/analytics";
import { getAllPartnerTaxes } from "Redux/Actions/taxAction";
import { createTaxFunc, editTaxFunc } from "Redux/DataCalls/Taxes.api";
import useLoading from "utility/useLoading";

import InconsistentTaxConfirmation from "./InconsistentTaxConfirmation";
import { ITaxDetailsProps } from "../types";

import "./TaxDetails.scss";

const { Option } = Select;

const ACCOUNTING_PARTNER_TAX_TYPE = "accountingPartnerTaxType";

const TaxDetails = ({ details, isEdit, onFinish, loading, connected, partnerName }: ITaxDetailsProps) => {
  const formRef = useRef<FormInstance>(null);
  const dispatch = useDispatch();
  const { data } = useSelector((reduxState: any) => reduxState.taxReducer?.xeroTaxes || {});
  const [createTax, createTaxLoading] = useLoading(createTaxFunc, null, {
    styles: { width: "calc(100vw - 110px)" },
  });
  const [editTax, editTaxLoading] = useLoading(editTaxFunc, null, {
    styles: { width: "calc(100vw - 110px)" },
  });
  const [editConfirmation, setEditConfirmation] = useState({
    show: false,
    message: "",
    data: {} as any,
  });

  const accountingPartnerTaxes = data?.payload?.data;

  const [state, setState] = useState({
    taxName: details?.tax_name || "",
    taxRate: details?.tax_rate || "",
    status: "active",
    accountingPartnerTaxType: "",
    accountingPartnerTax: {} as any,
  });

  const taxStatus = [
    {
      id: 1,
      label: "Active",
      value: "active",
    },
    {
      id: 2,
      label: "Inactive",
      value: "inactive",
    },
  ];

  const trackTaxPageActions = (eventName) => {
    trackEvent(`${isEdit ? "edit" : "add new"} tax ${eventName}`);
  };

  const handleOnBlurOfInputs = (inputName, eventName) => {
    const currentValue = formRef?.current?.getFieldValue?.(inputName);
    currentValue && currentValue !== state?.[inputName] && trackTaxPageActions(eventName);

    if (inputName === ACCOUNTING_PARTNER_TAX_TYPE) {
      const accountingPartnerTax = accountingPartnerTaxes?.find((tax) => tax?.taxType === currentValue);
      setState({ ...state, [inputName]: currentValue, accountingPartnerTax });
    } else {
      setState({ ...state, [inputName]: currentValue });
    }
  };

  const onCancel = () => {
    setEditConfirmation({ ...editConfirmation, show: false });
  };

  const detectDifferentTaxRate = (value) => {
    const { accountingPartnerTax = {} } = state;

    if (connected && accountingPartnerTax?.taxName && Number(value.taxRate) !== Number(accountingPartnerTax?.taxRate)) {
      setEditConfirmation({ ...editConfirmation, show: true, data: { ...value, accountingPartnerTax } });
      return true;
    }

    return false;
  };

  const onFinishHandler = async (value) => {
    if (!detectDifferentTaxRate(value)) {
      submitRequest(value);
    }
  };

  const submitRequest = async (value) => {
    const { accountingPartnerTax = {}, status } = state;

    const formattedValue = {
      tax_name: value.taxName,
      tax_rate: value.taxRate,
      partner_tax_name: accountingPartnerTax?.taxName || "",
      partner_tax_rate: accountingPartnerTax?.taxRate === undefined ? "" : accountingPartnerTax?.taxRate,
      partner_tax_type: value.accountingPartnerTaxType || "",
      status: value.status || status,
    };
    const processStatus = isEdit ? "edit" : "create new";
    const processRequest = isEdit ? editTax : createTax;
    trackTaxPageActions(`${isEdit ? "save" : "confirm"} clicked`);
    processRequest(formattedValue, details.id)
      .then(({ data: responseData }) => {
        if (responseData.status === HTTP_STATUS_CODE.OK) {
          trackTaxPageActions(`${processStatus} tax success`);
          onFinish(responseData?.payload?.status_message);
        } else {
          return Promise.reject();
        }
      })
      .catch(() => {
        trackTaxPageActions(`${processStatus} tax failed`);
      });
  };

  const generateTaxName = (tax) => `${tax?.taxName} (${tax?.taxRate}%)`;
  const generateTitle = () => (isEdit ? "Edit Tax" : "Add New Tax");

  useEffect(() => {
    trackTaxPageActions("sidebar viewed");
  }, []);

  useEffect(() => {
    if (connected) {
      dispatch(getAllPartnerTaxes());
    }
  }, [dispatch, connected]);

  useEffect(() => {
    if (details && formRef.current) {
      formRef.current.resetFields();
      formRef.current.setFieldsValue({
        taxName: details.tax_name,
        taxRate: details.tax_rate,
        status: details.status,
        accountingPartnerTaxType: details.partner_tax_type || null,
      });

      setState({
        ...state,
        accountingPartnerTax: {
          taxName: details.partner_tax_name,
          taxRate: details.partner_tax_rate,
          partner_tax_type: details.partner_tax_type,
        },
      });
    }
  }, [details]);

  useEffect(() => {
    if (formRef.current) {
      formRef.current.validateFields([ACCOUNTING_PARTNER_TAX_TYPE]);
    }
  }, [accountingPartnerTaxes]);

  if (loading) {
    return <LoaderIcon />;
  }

  return (
    <div className="tax-details" data-testid="taxDetails">
      {editConfirmation.show ? (
        <InconsistentTaxConfirmation
          onConfirm={() => submitRequest(editConfirmation.data)}
          data={editConfirmation.data}
          onCancel={onCancel}
          partnerName={partnerName}
        />
      ) : (
        <>
          <h2 className="tax-details--title">{generateTitle()}</h2>
          <Form
            ref={formRef}
            className="tax-details--form"
            initialValues={{ ...details, ...editConfirmation.data }}
            onFinish={onFinishHandler}
          >
            <Form.Item
              className="tax-name"
              label="Tax name"
              name="taxName"
              rules={[{ required: true, message: "Please enter tax name." }]}
            >
              <Input
                className="tax-name--input"
                placeholder="Add tax name here"
                onBlur={() => handleOnBlurOfInputs("taxName", `name ${isEdit ? "updated" : "added"}`)}
              />
            </Form.Item>
            <Form.Item
              className="tax-rate"
              label="Tax rate"
              name="taxRate"
              rules={[{ required: true, message: "Please enter tax rate." }]}
            >
              <Input
                type="number"
                className="tax-rate--input"
                placeholder="Example: 7%"
                onBlur={() => handleOnBlurOfInputs("taxRate", `rate ${isEdit ? "updated" : "added"}`)}
              />
            </Form.Item>

            {connected && (
              <Form.Item
                label={`Tax Name on ${partnerName}`}
                name={ACCOUNTING_PARTNER_TAX_TYPE}
                rules={[
                  ({ getFieldValue }) => ({
                    message: `This Tax is archived on ${partnerName}`,
                    validator() {
                      const value = getFieldValue(ACCOUNTING_PARTNER_TAX_TYPE);
                      if (!accountingPartnerTaxes || !value) {
                        const partnerInLowerCase = partnerName?.toLowerCase();
                        return Promise.resolve(
                          `Only check when ${partnerInLowerCase} taxes or ${partnerInLowerCase} tax type data is available`
                        );
                      }
                      const defaultValueIsExist = accountingPartnerTaxes.find((tax) => tax?.taxType === value);

                      if (defaultValueIsExist) {
                        return Promise.resolve(`Tax is available on ${partnerName}`);
                      }
                      return Promise.reject(`Tax is not available on ${partnerName}`);
                    },
                  }),
                ]}
              >
                <Select
                  placeholder={`Select Tax Name on ${partnerName}`}
                  id="accountingPartnerTaxMapping"
                  getPopupContainer={(triggerNode) => triggerNode.parentElement}
                  onChange={() =>
                    handleOnBlurOfInputs(ACCOUNTING_PARTNER_TAX_TYPE, `status ${isEdit ? "updated" : "added"}`)
                  }
                  allowClear
                  className="xero-tax-mapping"
                >
                  {accountingPartnerTaxes?.map((tax) => (
                    <Option key={tax.taxType} value={tax.taxType}>
                      <div>
                        <TextWithTooltip treshold={45} text={generateTaxName(tax)} />
                      </div>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}

            <Form.Item label="Status" name="status" className="taxStatus">
              <Select
                placeholder="Select tax Status"
                id="taxStatus"
                defaultValue="active"
                getPopupContainer={(triggerNode) => triggerNode.parentElement}
                onChange={() => handleOnBlurOfInputs("status", `status ${isEdit ? "updated" : "added"}`)}
              >
                {taxStatus?.map((status) => (
                  <Option key={status.id} value={status.value}>
                    {status.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item className="action-btn-wrapper">
              <Button
                text="Save"
                className="action-btn-wrapper--button"
                loading={createTaxLoading || editTaxLoading}
                disabled={createTaxLoading || editTaxLoading}
              />
            </Form.Item>
          </Form>
        </>
      )}
    </div>
  );
};

export default TaxDetails;
