import React, { useEffect, useMemo, useState } from "react";
import { Select as AntdSelect, Button } from "antd";

import Input from "Views/Card/Components/Input";
import Select from "Views/Card/Components/Select";
import SpendingLimit from "Views/Card/Components/SpendingLimit";
import { ExpenseCategory } from "Views/Card/Components/ExpenseCategory";
import LoadingComponent from "Views/State/Loading/LoaderIcon";
import { ConstructiveEnableButton } from "Modules/DS/SidePanel/components/Buttons/ConstructiveEnableButton";

import { CardReviewRequestEditableFieldsEnum, CardUpdateRequestPayload, ICardDetail } from "Views/Card/Review/types";
import { CARD_LIMIT_TYPES, CARD_TYPE, ITag, SPENDING_LOCK_TYPES } from "Views/Card/types";
import { onlyPositiveIntegersWithFractionsRegEx } from "constants/RegularExpressions.constant";
import { CARD_TYPE_OPTIONS } from "Views/Card/Review/EditForm";

import { CARD_APPROVAL_LIMIT_TYPES, TAB_KEY } from "Views/Approval/ApprovalCenter/types";
import { GetApprovalCenterDetail } from "Redux/DataCalls/ApprovalCenter.api";
import styles from "./index.module.scss";
import { ESidePanelButtonType } from "Modules/DS/SidePanel";
import { prepareCardDetailsPayload } from "../helper";
import { useTransactionTags } from "Modules/AccountingTags/hooks/useTransactionTags";
import { DEFAULT_TRANSACTION_TAGS_KB_ARTICLE_LINK } from "constants/Card";
import { Tooltip, TooltipArrowAlignmentEnum, TooltipPositionEnum } from "Modules/DS/Tooltip";
import TagsDropdown from "Views/Card/CreateOrRequest/Steps/Policy/TagsDropdown";
import { helpIcon16x16 } from "assets/icons/card/";
import { trackEvent } from "utility/analytics";
import { CARD_APPROVAL_EVENTS } from "../trackEvents";
import ChooseSpendingLock from "Views/Card/Components/SpendingLock/Choose";
import AddSpendingLock from "./AddSpendingLock";
import ActionBtns from "./AddSpendingLock/ActionBtns";
import { ICategoryItem } from "Views/Card/Components/SpendingLock/Category";
import classNames from "classnames";

const { Option } = AntdSelect;

interface IApprovalCardDetail {
  id: string;
  approvalAction: (isApproval: boolean, singleCardInfo?: CardUpdateRequestPayload) => void;
  cardVendor: string;
  trackEventData: EventDataType;
}

interface EventDataType {
  name: string;
  data: {
    card_id: Array<string>;
    user_role: string;
  };
}

const ReadOnlyData = ({ headerText, data }: { headerText: string; data: string }) => {
  if (!data) return null;

  return (
    <div className={styles["approval-card-detail__item"]}>
      <p className={styles["approval-card-detail__item--label"]}>{headerText}</p>
      <p className={styles["approval-card-detail__item--data"]}>{data}</p>
    </div>
  );
};

function ApprovalCardDetail({ id, approvalAction, cardVendor, trackEventData }: IApprovalCardDetail) {
  const [loading, setLoading] = useState(false);
  const [cardDetails, setCardDetails] = useState<ICardDetail | null>(null);
  const [confirmBtnEnabled, setConfirmBtnEnabled] = useState(false);
  const showTransactionTags = useTransactionTags();
  const updateCardDetailsPayload = prepareCardDetailsPayload(cardDetails);

  const [selectedLockType, setSelectedLockType] = useState<SPENDING_LOCK_TYPES>(SPENDING_LOCK_TYPES.NONE);
  const [spendingLockType, setSpendingLockType] = useState<SPENDING_LOCK_TYPES>(SPENDING_LOCK_TYPES.NONE);
  const [showAddSpendingLockScreen, setShowAddSpendingLockScreen] = useState<boolean>(true);
  const [spendingLocksToUpdate, setSpendingLocksToUpdate] = useState<ICategoryItem[]>([]);

  const updateSpendingLocks = (): void => {
    if (spendingLockType !== selectedLockType) {
      setSpendingLockType(selectedLockType);
    }
    let spendingLocks;
    if (selectedLockType === SPENDING_LOCK_TYPES.CATEGORY) {
      spendingLocks = (spendingLocksToUpdate || []).map((spendingLock) => {
        return { merchantCategoryName: spendingLock.name, merchantCategoryId: spendingLock.id };
      });
      changeCardDetails(CardReviewRequestEditableFieldsEnum.MERCHANT_CATEGORY_LIMITS, spendingLocks);
      changeCardDetails(CardReviewRequestEditableFieldsEnum.MERCHANTS, null);
    } else if (selectedLockType === SPENDING_LOCK_TYPES.MERCHANT) {
      spendingLocks = (spendingLocksToUpdate || []).map((spendingLock) => {
        return { merchantName: spendingLock.name, merchantId: spendingLock.id };
      });
      changeCardDetails(CardReviewRequestEditableFieldsEnum.MERCHANTS, spendingLocks);
      changeCardDetails(CardReviewRequestEditableFieldsEnum.MERCHANT_CATEGORY_LIMITS, null);
    }
  };

  const clearSpendingLock = (): void => {
    let lockType = selectedLockType !== SPENDING_LOCK_TYPES.NONE ? selectedLockType : spendingLockType;
    let keyToUpdate =
      lockType === SPENDING_LOCK_TYPES.MERCHANT
        ? CardReviewRequestEditableFieldsEnum.MERCHANTS
        : CardReviewRequestEditableFieldsEnum.MERCHANT_CATEGORY_LIMITS;
    changeCardDetails(keyToUpdate, null);
    setSelectedLockType(SPENDING_LOCK_TYPES.NONE);
    setSpendingLockType(SPENDING_LOCK_TYPES.NONE);
  };

  const fetchCardDetails = async () => {
    setLoading(true);
    const response = await GetApprovalCenterDetail(id, TAB_KEY.CARD);
    setCardDetails({
      ...response?.payload,
      cardLimitType: response?.payload?.cardLimitType
        ? CARD_APPROVAL_LIMIT_TYPES[response?.payload?.cardLimitType]
        : CARD_LIMIT_TYPES.monthlyLimit,
      expenseCategoryId: response.payload?.categoryID,
    });
    setLoading(false);
  };

  useEffect(() => {
    fetchCardDetails();
    return () => {
      setCardDetails(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const changeCardDetails = (
    keyToBeUpdate: CardReviewRequestEditableFieldsEnum,
    value: string | number | string[] | CARD_LIMIT_TYPES | ITag[]
  ) => {
    trackEvent(CARD_APPROVAL_EVENTS.cardApprovalDetailsEdited, {
      user_role: trackEventData?.data?.user_role,
      approval_cards_details_edited: keyToBeUpdate,
    });
    setCardDetails((prevState) => ({
      ...prevState,
      [keyToBeUpdate]: value,
    }));
  };

  const { requestDate, requesterName, teamName, shippingAddress, managerSetsLimit } = cardDetails || {};

  const letManagerSetsLimit = managerSetsLimit === 1;

  const { cardTitleError, cardSpendingLimitError } = useMemo(() => {
    let cardTitleError = "";
    if (!cardDetails?.[CardReviewRequestEditableFieldsEnum.CARD_TITLE]) cardTitleError = "Please input card title";
    let cardSpendingLimitError = "";
    if (
      !cardDetails?.[CardReviewRequestEditableFieldsEnum.CARD_LIMIT] &&
      cardDetails?.cardLimitType !== CARD_LIMIT_TYPES.unlimited
    )
      cardSpendingLimitError = "Please input spending limit";
    return { cardTitleError, cardSpendingLimitError };
  }, [cardDetails]);

  const cardReviewProps = useMemo(() => {
    const {
      requesterName,
      processID,
      cardTitle,
      isPhysicalCard,
      cardLimitCurrency,
      cardLimit,
      cardLimitType,
      teamName,
      merchants = [],
      memberNote,
      tags,
      expenseCategoryId,
      merchantCategoryLimits,
    } = cardDetails || {};

    let spendingLocks;
    if (cardDetails?.merchants) {
      setSpendingLockType(SPENDING_LOCK_TYPES.MERCHANT);
      spendingLocks = (merchants || []).map((merchant) => {
        return { id: merchant.merchantId, name: merchant.merchantName };
      });
    } else if (cardDetails?.merchantCategoryLimits) {
      spendingLocks = (merchantCategoryLimits || []).map((merchantCategory) => {
        return { id: merchantCategory.merchantCategoryId, name: merchantCategory.merchantCategoryName };
      });
      setSpendingLockType(SPENDING_LOCK_TYPES.CATEGORY);
    }

    const props = {
      cardRequestId: processID,
      cardTitle: cardTitle,
      cardType: isPhysicalCard ? CARD_TYPE.PHYSICAL : CARD_TYPE.VIRTUAL,
      cardLimitCurrency,
      cardLimit,
      cardLimitType: !cardLimitType ? CARD_LIMIT_TYPES.monthlyLimit : cardLimitType,
      team: teamName,
      requestBy: requesterName,
      address: "",
      spendingLocks: spendingLocks,
      expenseCategoryId,
      memberNote,
      tags,
    };
    return props;
  }, [cardDetails]);

  if (loading) return <LoadingComponent />;

  if (!cardDetails) return null;

  return (
    <div
      className={classNames(
        styles["approval-card-detail"],
        !showAddSpendingLockScreen ? styles["approval-card-detail__overflow-hidden"] : ""
      )}
    >
      <h2 className={styles["approval-card-detail__title"]}>Card Request</h2>
      {showAddSpendingLockScreen && (
        <div>
          <div className={styles["approval-card-detail__list"]}>
            <ReadOnlyData headerText="Requester" data={requesterName} />
            <ReadOnlyData headerText="Budget" data={teamName} />
            <ReadOnlyData headerText="Request Date" data={requestDate} />

            <div className={styles["approval-card-detail__item"]}>
              <p className={styles["approval-card-detail__item--label-editable"]}>Card Title</p>
              <div className={styles["approval-card-detail__item--data"]}>
                <Input
                  value={cardDetails.cardTitle}
                  onChange={(event) =>
                    changeCardDetails(CardReviewRequestEditableFieldsEnum.CARD_TITLE, event?.target?.value || "")
                  }
                  placeholder="Enter Card Title"
                  hasError={!!cardTitleError}
                />
                {cardTitleError && (
                  <span className={styles["approval-card-detail__item--error"]}>{cardTitleError}</span>
                )}
              </div>
            </div>

            {cardReviewProps.cardType === CARD_TYPE.PHYSICAL && (
              <ReadOnlyData headerText="Shipping Address" data={shippingAddress} />
            )}

            <div className={styles["approval-card-detail__item"]}>
              <p className={styles["approval-card-detail__item--label-editable"]}>Card Type</p>
              <div className={styles["approval-card-detail__item--data"]}>
                <Select value={cardReviewProps.cardType} disabled>
                  {CARD_TYPE_OPTIONS.map((cardType) => (
                    <Option value={cardType.value} key={cardType.value}>
                      {cardType.label}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>

            <div className={styles["approval-card-detail__item"]}>
              <p className={styles["approval-card-detail__item--label-editable"]}>Spending Limit</p>
              <div className={styles["approval-card-detail__item--data"]}>
                <SpendingLimit
                  cardLimit={cardReviewProps.cardLimit}
                  cardLimitType={cardReviewProps.cardLimitType}
                  onSpendingLimitTypeChange={(cardLimitType) => {
                    changeCardDetails(CardReviewRequestEditableFieldsEnum.CARD_LIMIT_TYPE, cardLimitType);
                  }}
                  onSpendingLimitChange={(cardLimit) => {
                    if (onlyPositiveIntegersWithFractionsRegEx?.test?.(cardLimit) || cardLimit === "") {
                      changeCardDetails(CardReviewRequestEditableFieldsEnum.CARD_LIMIT, cardLimit);
                    }
                  }}
                  currencyCode={cardReviewProps.cardLimitCurrency}
                  spendingLimitHasError={!!cardSpendingLimitError}
                />
                {letManagerSetsLimit && cardSpendingLimitError && (
                  <span className={styles["approval-card-detail__item--error"]}>{cardSpendingLimitError}</span>
                )}
              </div>
            </div>
            <div className={styles["approval-card-detail__item"]}>
              <p className={styles["approval-card-detail__item--label-editable"]}>
                Default Expense Category (Optional)
              </p>
              <div className={styles["approval-card-detail__item--data"]}>
                <ExpenseCategory
                  selectExpenseCategoryID={
                    cardReviewProps.expenseCategoryId ? +cardReviewProps.expenseCategoryId : undefined
                  }
                  onSelect={(expenseCategoryId) =>
                    changeCardDetails(CardReviewRequestEditableFieldsEnum.EXPENSE_CATEGORY_ID, expenseCategoryId)
                  }
                />
              </div>
            </div>
            {showTransactionTags ? (
              <div className={styles["approval-card-detail__item"]}>
                <p className={styles["approval-card-detail__item--label-editable"]}>
                  <p>Default Transaction Tags</p>
                  <a
                    href={DEFAULT_TRANSACTION_TAGS_KB_ARTICLE_LINK}
                    className="create-or-request-card-steps__input-label__link"
                    target="_blank"
                  >
                    <Tooltip
                      text="Click to read about Transaction Tag"
                      alignArrow={TooltipArrowAlignmentEnum.CENTER}
                      position={TooltipPositionEnum.TOP}
                    >
                      <img src={helpIcon16x16} alt="Help Icon" />
                    </Tooltip>
                  </a>
                  <span className={styles["optional-field-label"]}>(optional)</span>
                </p>
                <div className={styles["approval-card-detail__item--data"]}>
                  <TagsDropdown
                    selectedTags={cardReviewProps.tags}
                    onChange={(data) => {
                      changeCardDetails(CardReviewRequestEditableFieldsEnum.TAGS, data);
                    }}
                    subMenuPosition="leftBottom"
                  />
                </div>
              </div>
            ) : null}
            {cardVendor !== "UOB" && (
              <div className={styles["approval-card-detail__item"]}>
                <p className={styles["approval-card-detail__item--label-editable"]}>Spending Lock (Optional)</p>
                <div className={styles["approval-card-detail__item--data"]}>
                  {showAddSpendingLockScreen && (
                    <ChooseSpendingLock
                      cardActionType="Create"
                      onSpendingLockTypeClick={(selectedLockType: SPENDING_LOCK_TYPES) => {
                        setSelectedLockType(selectedLockType);
                        setShowAddSpendingLockScreen(false);
                      }}
                      onRemove={clearSpendingLock}
                      spendingLockType={spendingLockType}
                      spendingLocks={(cardReviewProps.spendingLocks || []).map((spendingLock) => spendingLock.name)}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
          <div className={styles["approval-card-detail__footer"]}>
            <ConstructiveEnableButton
              id="approvalCardDetailBtn"
              action={() => {
                trackEvent(trackEventData.name, {
                  ...trackEventData.data,
                  approval_cards_event_action: "Approve",
                  approval_cards_event_source: "Details in Approvals",
                });
                approvalAction(true, updateCardDetailsPayload);
              }}
              text="Approve"
              type={ESidePanelButtonType.CONSTRUCTIVE_ENABLE}
              disabled={!!(cardTitleError || cardSpendingLimitError)}
              enableConfirmCallback={() => setConfirmBtnEnabled(true)}
              disabledConfirmCallback={() => setConfirmBtnEnabled(false)}
            />
            {!confirmBtnEnabled && (
              <Button
                className={styles["approval-card-detail__decline-btn"]}
                onClick={() => {
                  trackEvent(trackEventData.name, {
                    ...trackEventData.data,
                    approval_cards_event_action: "Decline",
                    approval_cards_event_source: "Details in Approvals",
                  });
                  approvalAction(false);
                }}
              >
                Decline
              </Button>
            )}
          </div>
        </div>
      )}
      {!showAddSpendingLockScreen && (
        <>
          <div className={styles["approval-card-detail__list"]}>
            <AddSpendingLock
              selectedSpendingLockType={selectedLockType}
              spendingLockType={spendingLockType}
              spendingLocks={cardReviewProps.spendingLocks}
              updateSpendingLocks={setSpendingLocksToUpdate}
              toggleLockType={() => {
                setSelectedLockType((prevType) =>
                  prevType === SPENDING_LOCK_TYPES.CATEGORY
                    ? SPENDING_LOCK_TYPES.MERCHANT
                    : SPENDING_LOCK_TYPES.CATEGORY
                );
              }}
            />
          </div>
          <div className={styles["approval-card-detail__footer"]}>
            <ActionBtns
              onCancel={() => {
                setShowAddSpendingLockScreen(true);
              }}
              onSave={() => {
                updateSpendingLocks();
                setShowAddSpendingLockScreen(true);
              }}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default ApprovalCardDetail;
