import { ICardEditFieldDetails } from "Views/Card/Edit/index.context";
import { CARD_LIMIT_TYPES, ICardDetails } from "Views/Card/types";
import { CARD_LIMITS_LABELS } from "Views/Card/Components/SpendingLimit/index.constants";
import { trackEvent } from "utility/analytics";
import { EditCardEventNames } from "Views/Card/Analytics";

const trackEditCardEvents = (eventSource: string, payload: { [key: string]: string } = {}) => {
  trackEvent(EditCardEventNames.CARD_SETTINGS_EDITED, {
    card_settings_event_source: eventSource,
    ...payload,
  });
};

const trackSpendingLock = (
  oldSpendingLocks: string[],
  newSpendingLocks: string[],
  spendingLockTypeChanged: boolean
) => {
  let spendingLockChanged = false;
  if (spendingLockTypeChanged || oldSpendingLocks?.length !== newSpendingLocks?.length) {
    spendingLockChanged = true;
  } else if (newSpendingLocks?.length > 0) {
    spendingLockChanged = oldSpendingLocks?.some((oldSpendingLock) => !newSpendingLocks?.includes(oldSpendingLock));
  }
  spendingLockChanged &&
    trackEditCardEvents("Spending Locks", {
      card_spending_locks: newSpendingLocks?.join(", ") || "",
    });
};

const trackSpendingLimit = (
  oldSpendingLimitType: CARD_LIMIT_TYPES,
  newSpendingLimitType: CARD_LIMIT_TYPES,
  oldSpendingLimit: string,
  newSpendingLimit: string
) => {
  const spendingLimitAmountPayload = {
    card_spend_limit_amount: newSpendingLimit,
  };
  if (oldSpendingLimitType !== newSpendingLimitType) {
    trackEditCardEvents("Spend Limit Category", {
      card_spend_limit_period: CARD_LIMITS_LABELS?.[newSpendingLimitType] || "",
    });
    if (newSpendingLimitType !== CARD_LIMIT_TYPES.unlimited && oldSpendingLimit !== newSpendingLimit) {
      trackEditCardEvents("Spend Limit Amount", spendingLimitAmountPayload);
    }
  } else if (oldSpendingLimit !== newSpendingLimit) {
    trackEditCardEvents("Spend Limit Amount", spendingLimitAmountPayload);
  }
};

export const trackEditActions = (cardDetailsEditState: ICardEditFieldDetails, beforeEditCardData: ICardDetails) => {
  const {
    spendingLimitType: beforeEditSpendingLimitType,
    spendingLimit: beforeEditSpendingLimit,
    expenseCategoryId: beforeEditExpenseCategoryId,
    cardColor: beforeEditColor,
    cardTitle: beforeEditCardTitle,
    spendingLocks: beforeEditSpendingLocks,
  } = beforeEditCardData || {};

  const { spendingLimit, spendingLimitType, expenseCategoryId, color, title, spendingLockType, spendingLocks } =
    cardDetailsEditState;

  trackEvent(EditCardEventNames.CARD_SETTINGS_SAVE_CLICKED);

  trackSpendingLimit(beforeEditSpendingLimitType, spendingLimitType, beforeEditSpendingLimit, spendingLimit);

  const spendingLockTypeChanged = beforeEditCardData?.spendingLockType !== spendingLockType;

  if (spendingLockTypeChanged) {
    trackEditCardEvents("Spending Lock Type", {
      card_spending_lock_type: spendingLockType,
    });
  }

  const beforeEditSpendingLocksNames = beforeEditSpendingLocks?.map((spendingLock) => spendingLock?.name) || [];

  trackSpendingLock(
    beforeEditSpendingLocksNames,
    spendingLocks?.map((spendingLock) => spendingLock?.name) || [],
    spendingLockTypeChanged
  );

  if (beforeEditExpenseCategoryId !== expenseCategoryId) {
    trackEditCardEvents("Expense Category");
  }

  if (beforeEditColor !== color) {
    trackEditCardEvents("Color");
  }

  if (beforeEditCardTitle !== title) {
    trackEditCardEvents("Name");
  }
};
