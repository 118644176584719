import React from "react";
import { IFieldLevelRemarks, IRemarksInfo } from "Views/KYX/@types";
import { RemarksInfo } from "Views/KYX/Constants/Remarks";

import styles from "Views/KYX/Remarks/Content/FormLevel/index.module.scss";

const FieldLevelRemarks: React.FC<IFieldLevelRemarks> = ({ details }: IFieldLevelRemarks): JSX.Element => {
  const formInfo: IRemarksInfo = RemarksInfo[details.form_name];

  const getReasonCard = (label: string, reason: string, title?: string) => {
    return (
      <div className={styles.container}>
        {title && <h2 className={styles.title}>{formInfo?.title}</h2>}

        <h4 className={styles.subTitle}>{label}</h4>
        <p className={styles.reason}>{reason}</p>
      </div>
    );
  };

  return (
    <>
      {getReasonCard(details.title, details?.reason)}
      {details.general_remark && getReasonCard("General Remarks", details?.general_remark, formInfo.title)}
    </>
  );
};

export default FieldLevelRemarks;
