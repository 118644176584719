import React, { useContext } from "react";
import { Link } from "react-router-dom";

import CardsShimmer from "Views/Home/Components/MyCards/CardsShimmer";
import UserCardsCarousel from "Views/Home/Components/MyCards/UserCards/Carousel";
import AddCard from "Views/Home/Components/MyCards/UserCards/AddCard";
import rightArrowBlue from "assets/img/arrows/rightArrowBlue.svg";
import { MyCardsContext } from "../MyCards.context";
import { ConfigCardsEmptyState, getCardEmptyState, GetRoleDetails } from "./Utilities";
import Icon from "Modules/icons";
import { COMPANY_ROLES } from "constants/QuickAccess.constant";
import { checkAccess } from "utility/permissions/permission";

import { cardEvents } from "Views/Home/Analytics";
import { trackEvent } from "utility/analytics";
import styles from "./index.module.scss";

const UserCards = ({ cardListData, userCardsListLoading, userCardsListError, refetchUserCardsList }) => {
  const userRoleDetails = useContext(MyCardsContext)?.userDetails;
  const roleDetails = GetRoleDetails(userRoleDetails?.teamData, userRoleDetails?.userInfo);
  const isCardListEmpty = cardListData?.cardList?.length <= 0;
  const isAuditor = checkAccess([COMPANY_ROLES.AUDITOR], roleDetails.companyRole);
  const getUserCards = () => {
    if (userCardsListLoading) {
      return <CardsShimmer />;
    } else if (userCardsListError) {
      const { status_message } = cardListData || {};
      return getCardEmptyState(status_message);
    } else if (isCardListEmpty) {
      return ConfigCardsEmptyState(roleDetails);
    } else {
      return <UserCardsCarousel cardsData={cardListData} />;
    }
  };

  const trackViewAllCards = () => {
    trackEvent(cardEvents.VIEW_ALL_CARDS);
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        {!userCardsListLoading && (
          <div className={styles.inline}>
            <h2 className={styles.title}>{isAuditor ? "Cards" : "My Cards"}</h2>
            <Link
              className={styles.viewall}
              to={{ pathname: "/cards", search: "?defaultCardholderFilter=true" }}
              onClick={trackViewAllCards}
            >
              View all <Icon className={styles.icon} src={rightArrowBlue} alt="right arrow blue" />
            </Link>
          </div>
        )}
        {!userCardsListLoading && <AddCard refetchUserCardsList={refetchUserCardsList} />}
      </div>
      {getUserCards()}
    </div>
  );
};

export default UserCards;
