import { createContext, useContext } from "react";
import { FieldErrors } from "react-hook-form";

import { ICreateForm, IPaymentCardData } from "Views/SubscriptionManagement/@types";
import { CARD_COLOR_AND_NAME_MAP, CARD_COLORS } from "constants/Card/CardColors.constants";
import { DEFAULT_LIMIT_VALUES } from "constants/Team.constant";

import { useSubscriptionManagementForm } from "Views/SubscriptionManagement/Create/form";

/**
 *  In react-hook-form (FormContext) we need to pass everything from useForm call which is a
 *  very large object & can cause lot of re-renders,
 *  In children we only need control, watch & setValue functions
 *  So it is unneccasaary use FormContext, instead creating a custom one
 */

interface IFormContext
  extends Pick<ReturnType<typeof useSubscriptionManagementForm>, "watch" | "control" | "setValue" | "clearErrors"> {
  errors: FieldErrors<ICreateForm>;
}

const initialContext: IFormContext = {
  control: null,
  watch: null,
  setValue: null,
  errors: null,
  clearErrors: null,
};

const FormContext = createContext<IFormContext>(initialContext);

export const useFormContext: () => IFormContext = () => useContext(FormContext);

export default FormContext;

/**
 * In payment method we need to send new card data
 * or if card is updated, then the updated card data
 * including all of this in the form doesn't make sense since
 * most of the data is not getting used in the form itself
 * So creating a new context to hold card data,
 * later on can add bills data here as well
 */

export interface IPayment {
  cardData: IPaymentCardData;
}

export interface IPaymentContext extends IPayment {
  setPaymentData: React.Dispatch<React.SetStateAction<IPayment>>;
}

export const initialPaymentContext: IPaymentContext = {
  cardData: {
    name: "",
    expense_category_id: null,
    color_name: CARD_COLOR_AND_NAME_MAP[CARD_COLORS[0]],
    color: CARD_COLORS[0],
    tags: [],
    merchant_limits: [],
    merchant_category_limits: [],
    monthly_limit: DEFAULT_LIMIT_VALUES.REVERT_THE_LIMIT,
    yearly_limit: DEFAULT_LIMIT_VALUES.REVERT_THE_LIMIT,
    total_limit: DEFAULT_LIMIT_VALUES.REVERT_THE_LIMIT,
    card_id: null,
  },
  setPaymentData: () => {},
};

export const PaymentContext = createContext<IPaymentContext>(initialPaymentContext);

export const usePaymentContext: () => IPaymentContext = () => useContext(PaymentContext);
