import { updateWallet } from "./walletAction";
export const FETCH_TEAMS_REQUEST = "FETCH_TEAMS_REQUEST";
export const FETCH_ADDTEAMS_REQUEST = "FETCH_ADDTEAMS_REQUEST";
export const FETCH_ADDTEAMS_SUCCESS = "FETCH_ADDTEAMS_SUCCESS";
export const FETCH_ADDTEAMS_FAILURE = "FETCH_ADDTEAMS_FAILURE";
export const FETCH_TEAMS_SUCCESS = "FETCH_TEAMS_SUCCESS";
export const FETCH_CREATE_TEAMS_SUCCESS = "FETCH_CREATE_TEAMS_SUCCESS";
export const FETCH_TEAMS_FAILURE = "FETCH_TEAMS_FAILURE";
export const UPDATE_TEAM = "UPDATE_TEAM";
// export const UPDATE_WALlET = "UPDATE_WALlET";
export const UPDATE_USER_ROLE_MEMBERSHIP = "UPDATE_USER_ROLE_MEMBERSHIP";

const fetchDataSuccess = (data, allTeams) => {
  return {
    type: FETCH_TEAMS_SUCCESS,
    data: data,
    allTeams: allTeams,
  };
};

const setUserWallet = (teamdata) => {
  const personalWallet = {};
  const teamWallet = {};
  if (teamdata.your_role === "Admin") {
    if (teamdata.your_membership === "Member" || teamdata.your_membership === "Non-Member") {
      Object.assign(personalWallet, {
        name: "Personal Wallet",
        type: "user",
        currency_code: teamdata?.your_membership_details?.user_wallet?.currency_code,
        amount: teamdata?.your_membership_details?.user_wallet?.available_balance,
        id: teamdata?.your_membership_details?.user_wallet?.id,
      });
    }
    Object.assign(teamWallet, {
      name: "Team Wallet",
      type: "team",
      amount: teamdata.wallet_amount,
      id: teamdata.wallet_id,
      currency_code: teamdata.currency_code,
      expenses: teamdata.expenses_this_month,
      alotted: teamdata.alotted_amount,
    });
    return (dispatch) => {
      dispatch(
        updateWallet({
          name: teamdata.name,
          currency_code: teamdata.currency_code,
          collection: [teamWallet, personalWallet],
          your_role: teamdata.your_role,
          your_membership: teamdata.your_membership,
          is_admin: teamdata.is_admin,
        })
      );
    };
  } else if (teamdata.your_membership === "Non-Member") {
    Object.assign(personalWallet, {
      name: "Personal Wallet",
      type: "user",
      currency_code: teamdata?.your_membership_details?.user_wallet?.currency_code,
      amount: teamdata?.your_membership_details?.user_wallet?.available_balance,
      id: teamdata?.your_membership_details?.user_wallet?.id,
    });
    Object.assign(teamWallet, {
      name: "Team Wallet",
      type: "team",
      currency_code: teamdata.currency_code,
      amount: teamdata.wallet_amount,
      id: teamdata.wallet_id,
      expenses: teamdata.expenses_this_month,
      alotted: teamdata.alotted_amount,
    });
    return (dispatch) => {
      dispatch(
        updateWallet({
          name: teamdata.name,
          currency_code: teamdata.currency_code,
          collection: [teamWallet, personalWallet],
          your_role: teamdata.your_role,
          your_membership: teamdata.your_membership,
          is_admin: teamdata.is_admin,
        })
      );
    };
  } else if (teamdata.your_membership === "Member" && teamdata.your_role === "Manager") {
    Object.assign(personalWallet, {
      name: "Personal Wallet",
      type: "user",
      amount: teamdata.your_membership_details.user_wallet.available_balance,
      currency_code: teamdata.your_membership_details.user_wallet.currency_code,
      id: teamdata.your_membership_details.user_wallet.id,
    });
    Object.assign(teamWallet, {
      name: "Team Wallet",
      type: "team",
      amount: teamdata.wallet_amount,
      currency_code: teamdata.currency_code,
      id: teamdata.wallet_id,
      expenses: teamdata.expenses_this_month,
      alotted: teamdata.alotted_amount,
    });
    return (dispatch) => {
      dispatch(
        updateWallet({
          name: teamdata.name,
          currency_code: teamdata.currency_code,
          collection: [teamWallet, personalWallet],
          your_role: teamdata.your_role,
          your_membership: teamdata.your_membership,
          is_admin: teamdata.is_admin,
        })
      );
    };
  } else if (teamdata.your_membership === "Member" && teamdata.your_role === "Member") {
    Object.assign(personalWallet, {
      name: "Personal Wallet",
      type: "user",
      amount: teamdata.your_membership_details.user_wallet.available_balance,
      currency_code: teamdata.your_membership_details.user_wallet.currency_code,
      id: teamdata.your_membership_details.user_wallet.id,
    });
    return (dispatch) => {
      dispatch(
        updateWallet({
          name: teamdata.name,
          currency_code: teamdata.currency_code,
          collection: [{}, personalWallet],
          your_role: teamdata.your_role,
          your_membership: teamdata.your_membership,
          is_admin: teamdata.is_admin,
        })
      );
    };
  }
};

//not used need clarification [ will remove once in monorepo]
export const updateOrgDataInTeams = (orgTeam) => {
  return (dispatch, getState) => {
    const { data } = getState().teamReducer;

    const updatedTeams = data.payload.teams.map((team) => (team.id === orgTeam ? orgTeam : team));

    if (data?.payload?.teams) {
      dispatch(
        fetchDataSuccess({
          ...data,
          payload: {
            teams: updatedTeams,
          },
        })
      );
    }
  };
};

// Getting used in orgTeamAction [ Move it inside team or wallet folder  once we are in monorepo ]
export const updateOrgTeamWallet = (orgTeam) => {
  return (dispatch, getState) => {
    const { selectedTeamId } = getState().teamReducer;

    if (selectedTeamId === orgTeam.id) {
      dispatch(setUserWallet(orgTeam));
    }
  };
};
