import React from "react";
import { useRouteMatch } from "react-router-dom";
import { Sidepanel as SPMSidepanel } from "@spenmo/splice";

import ExportSidepanel from "Views/Bills/V2/Sidepanel/Export";

import { ILocationParams } from "Views/Bills/V2/@types";
import { BILLS_PAGE_OPERATIONS } from "Views/Bills/V2/constants";

const DefaultSidepanel = () => {
  return (
    <SPMSidepanel
      showSidepanel={false}
      position="right"
      title="Bills"
      footerStacking="horizontal"
      transitionType="rightToLeft"
    />
  );
};

const Sidepanel = () => {
  const { params } = useRouteMatch<ILocationParams>();

  const { form = "" } = params;

  let Component: React.FunctionComponent = undefined;

  switch (true) {
    case form === BILLS_PAGE_OPERATIONS.EXPORT:
      Component = ExportSidepanel;
      break;
    case form === "":
    default:
      Component = DefaultSidepanel;
      break;
  }

  return <Component />;
};

export default Sidepanel;
