export enum PRODUCT_NAME {
  APPROVAL = "approval_system",
  AUTO_SEND_RECEIPT = "auto_send_receipt",
  BILL_FEE_CALCULATOR = "bill_fee_calculator",
  BILL_WITHHOLDING_TAX = "bill_withholding_tax",
  BILLPAY = "billpay_flag",
  CARD_VENDOR = "card_vendor",
  CASH_REIMBURSEMENT = "cash_reimbursement_flag",
  PAYMENT_FOR_REIMBURSEMENT = "payment_for_reimbursements",
  PAYMENT_FOR_BILLS = "payment_for_bills",
  LIMIT_MODEL = "limit_model",
  WALLET_MODEL = "wallet_model",
  XERO_AUTO_SYNC_CARD = "xero_auto_sync_card",
  XERO_AUTO_SYNC_REIMBURSEMENT = "xero_auto_sync_reimbursement",
  LOOKER_ORG_KEY = "looker_org_key",
}
