import { useLocation } from "react-router-dom";

const useUrlParams = () => {
  let { search } = useLocation();

  return (
    !!search &&
    Object.fromEntries(
      search
        .slice(1)
        .split("&")
        .map((item) => item.split("="))
    )
  );
};

export default useUrlParams;
