import React from "react";

import { StepProps } from "antd/lib/steps";
import classNames from "classnames";
import { getRecordDetailsDate } from "Views/Transaction/Helper";

import styles from "./index.module.scss";

export interface ITrxnStatusStepProps extends StepProps {
  trxnStatus: string;
}

type textColorType = "grey" | "darkGrey" | "blue";

const getStatusText = (statusText: string, textColor: textColorType, date?: string) => {
  const statusTextClassname = classNames(styles.statusText, styles[`${textColor}`]);
  return (
    <div className={styles.statusContainer}>
      <p className={statusTextClassname}>{statusText}</p>
      {date ? <p className={styles.date}>{getRecordDetailsDate(date || "")}</p> : null}
    </div>
  );
};

export const getPendingConfig = (pendingTrxnDate: string): ITrxnStatusStepProps[] => [
  {
    title: getStatusText("Settled", "grey"),
    status: "wait",
    trxnStatus: "settled",
  },
  {
    title: getStatusText("Pending", "blue", pendingTrxnDate),
    status: "process",
    description: "Funds are held until the merchant approves and settles the transaction.",
    trxnStatus: "pending",
  },
];

const pushPendingConfig = (config: ITrxnStatusStepProps[], pendingTrxnDate?: string): ITrxnStatusStepProps[] => {
  if (pendingTrxnDate) {
    config.push({
      title: getStatusText("Pending", "darkGrey", pendingTrxnDate),
      status: "finish",
      trxnStatus: "pending",
    });
  }
  return config;
};

export const getSettledConfig = (settledTrxnDate: string, pendingTrxnDate?: string): ITrxnStatusStepProps[] => {
  const setttledConfig: ITrxnStatusStepProps[] = [
    {
      title: getStatusText("Settled", "blue", settledTrxnDate),
      status: "process",
      description: "Funds are successfully received by merchant.",
      trxnStatus: "settled",
    },
  ];
  return pushPendingConfig(setttledConfig, pendingTrxnDate);
};

export const getReversedConfig = (reversedTrxnDate: string, pendingTrxnDate?: string): ITrxnStatusStepProps[] => {
  const reversedConfig: ITrxnStatusStepProps[] = [
    {
      title: getStatusText("Reversed", "blue", reversedTrxnDate),
      status: "process",
      description: "Funds are returned after the transaction is authorized.",
      trxnStatus: "reversed",
    },
  ];
  return pushPendingConfig(reversedConfig, pendingTrxnDate);
};

export const getRefundConfig = (refundedTrxnDate: string): ITrxnStatusStepProps[] => [
  {
    title: getStatusText("Refunded", "blue", refundedTrxnDate),
    status: "process",
    description: "Funds are partially or fully returned by merchant.",
    trxnStatus: "refunded",
  },
];
