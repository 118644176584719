import {
  GET_APPROVAL_FLOW_REQUEST,
  GET_APPROVAL_FLOW_SUCCESS,
  GET_APPROVAL_FLOW_FAILURE,
  CLEAR_GET_APPROVAL_FLOW,
} from "../../Actions/ApprovalFlow/getApprovalFlow";
import { userInitialState } from "../../InitialState";

export const getApprovalFlowReducer = (state = userInitialState, action) => {
  switch (action.type) {
    case GET_APPROVAL_FLOW_REQUEST:
      return {
        ...state,
        data: action.data,
        loading: true,
      };
    case GET_APPROVAL_FLOW_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
      };
    case GET_APPROVAL_FLOW_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.data,
        error: true,
      };
    case CLEAR_GET_APPROVAL_FLOW:
      return userInitialState;
    default:
      return state;
  }
};
