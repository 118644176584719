import React, { useContext, useEffect, useState } from "react";
import classNames from "classnames";
import { Form, Upload } from "antd";
import { DraggerProps } from "antd/lib/upload";
import { UploadChangeParam, UploadFile } from "antd/lib/upload/interface";

import { customFileUpload } from "Redux/DataCalls/CustomFileUpload.api";

import { validateFileType } from "utility";

import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";

import { TOASTER_SIZE_TYPE, TOASTER_STATUS_TYPE } from "Modules/DS/Toaster";

import { KYXToastContext } from "Views/KYX/Context";
import { KYX_SERVICE } from "Views/KYX/DataCalls/Services";
import { IKYXFileUploader, IKYXToastContext } from "Views/KYX/@types";

import FileUploaderContent from "./content";

import styles from "./index.module.scss";

const { Dragger } = Upload;

const INITIAL_PROGRESS = 50;

const MAX_FILE_SIZE_MB = 4;
const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024;

const FileUploader = ({ formProps, format, action, multiple = false, disabled = false }: IKYXFileUploader) => {
  const uploadFormat = ["image/jpg", "image/jpeg", "image/png", "application/pdf"] || format;

  const initialFileInfo: UploadFile<any> = {
    status: formProps.initialValue ? "done" : null,
    error: null,
    response: "",
    thumbUrl: formProps.initialValue,
    uid: "",
    size: 0,
    name: "",
    type: "",
  };

  const resetFileInfo: UploadFile<any> = {
    status: null,
    error: null,
    response: "",
    thumbUrl: "",
    uid: "",
    size: null,
    name: null,
    type: null,
  };

  const [isEdit, setIsEdit] = useState(false);
  const [fileInfo, setFileInfo] = useState<UploadFile<any>>(initialFileInfo);

  const [multipleFileUrls, setMultipleFileUrls] = useState([]);
  const [multipleFiles, setMultipleFiles] = useState([]);
  const [removedFile, setRemovedFile] = useState(null);

  const { setToaster } = useContext<IKYXToastContext>(KYXToastContext);

  const fetchThumbUrl = async (files) => {
    const filesList = Array.isArray(files) ? files : [files];

    const filePromises = filesList.map((file) =>
      customFileUpload({}, `${KYX_SERVICE().UPLOAD_DOCS_URL}/preview?url=${file}`, true, "getData")
        .then((res) => {
          if (res?.data?.status === HTTP_STATUS_CODE.OK) {
            const url = res?.data?.payload?.presigned_url;
            return {
              status: "done",
              thumbUrl: url,
              response: url,
              size: res?.data?.payload?.size,
              name: res?.data?.payload?.file_name,
              type: res?.data?.payload?.file_type,
            };
          }
        })
        .catch((err) => console.error(err))
    );

    const fileData = await Promise.all(filePromises);

    multiple ? setMultipleFiles(fileData) : setFileInfo({ ...fileInfo, ...fileData[0] });
  };

  useEffect(() => {
    if (formProps.initialValue) {
      multiple && setMultipleFileUrls(formProps.initialValue);
      fetchThumbUrl(formProps.initialValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formProps.initialValue]);

  const preventDefaultActions = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleOnRemove = (e: React.MouseEvent<HTMLButtonElement>, file: UploadFile) => {
    preventDefaultActions(e);

    const filteredList = multipleFiles?.filter((item) => {
      return item?.uid !== file?.uid || item?.thumbUrl !== file?.thumbUrl;
    });

    const filteredUrlList = multipleFileUrls?.filter((url) => {
      const fileUrl = file?.response?.split("?X-Amz-Algorithm")?.[0];
      return url !== fileUrl;
    });

    if (multiple) {
      action(filteredUrlList);
      setMultipleFiles(filteredList);
      setMultipleFileUrls(filteredUrlList);
    } else {
      setFileInfo(resetFileInfo);
      action("");
    }
    setIsEdit(false);
  };

  const onPreview = (e: React.MouseEvent<HTMLButtonElement>, file: UploadFile) => {
    preventDefaultActions(e);
    const { response } = file || {};

    if (response) {
      customFileUpload({}, `${KYX_SERVICE().UPLOAD_DOCS_URL}/preview?url=${response}`, true, "getData")
        .then((res) => {
          if (res.data.status === HTTP_STATUS_CODE.OK) {
            window.open(res?.data?.payload?.presigned_url, "_blank", "noopener,noreferrer");
          }
        })
        .catch((err) => console.error(err));
    }
  };

  const handleChangedFile = (changedFile: UploadFile): void => {
    if (multiple && removedFile) {
      let files = multipleFiles;

      const fileIndex = multipleFiles.indexOf(removedFile);
      files[fileIndex] = changedFile;

      setMultipleFiles(files);
    }
  };

  const getMultipleFileUrls = (newFileUrl: string): string[] => {
    if (multiple && removedFile) {
      const filteredUrlList = multipleFileUrls?.filter((url) => {
        const fileUrl = removedFile?.response?.split("?X-Amz-Algorithm")?.[0];
        return url !== fileUrl;
      });
      setRemovedFile(null);
      return filteredUrlList.concat([newFileUrl]);
    } else {
      return multipleFileUrls.length > 0 ? multipleFileUrls.concat([newFileUrl]) : [newFileUrl];
    }
  };

  const props: DraggerProps = {
    name: "file", // The name of uploading file
    disabled: disabled,
    multiple: multiple, // Whether to support selected multiple file.
    fileList: multiple ? multipleFiles : [fileInfo], // List of files that have been uploaded (controlled).
    listType: "picture-card", // Built-in stylesheets, support for three types: 'text' | 'picture' | 'picture-card';
    accept: ".png, .jpg, .jpeg, .pdf", // File types that can be accepted.
    customRequest: ({ onSuccess, onError, file }: any): void => {
      if (validateFileType(uploadFormat, file?.type)) {
        customFileUpload(file, KYX_SERVICE().UPLOAD_DOCS_URL)
          .then((res) => {
            const imgUrl: string = res?.data?.payload?.url;
            if (res.data.status === HTTP_STATUS_CODE.OK && imgUrl) {
              const list = getMultipleFileUrls(imgUrl);
              multiple && setMultipleFileUrls(list);
              onSuccess(imgUrl);
              multiple ? action(list) : action(imgUrl);
            } else {
              onError(res?.data?.errors?.[0]?.message);
            }
          })
          .catch((err) => console.error(err));
      }
    },
    onChange: (info: UploadChangeParam): void => {
      if (validateFileType(uploadFormat, info?.file?.type)) {
        const list = multipleFiles.length > 0 ? multipleFiles.concat([info.file]) : [info.file];
        multiple ? setMultipleFiles(list) : setFileInfo(info.file);
        handleChangedFile(info?.file);
      }
    },
    beforeUpload: (file) => {
      if (file.size > MAX_FILE_SIZE_BYTES) {
        setToaster({
          message: `File size must be smaller than ${MAX_FILE_SIZE_MB} MB.`,
          visible: true,
          status: TOASTER_STATUS_TYPE.ERROR,
          size: TOASTER_SIZE_TYPE.S,
        });
        return false;
      }
      return true;
    },
  };

  const { status } = fileInfo || {};
  const _classNames = classNames(styles.wrapper, {
    [styles.edit]: isEdit,
    [styles.error]: formProps?.validateStatus === "error" || status === "error",
    [styles.disabled]: disabled,
  });

  // We are setting the trigger as an empty string to avoid triggering onFormChange
  // Otherwise it sets the fakePath as the image url. We are manually updating the form field
  // value to the presigned url that we received upon response from customFileUpload
  return (
    <Form.Item trigger="" validateTrigger="" className={styles.fileInput} {...formProps}>
      <div className={_classNames}>
        <Dragger data-testid="fileUploader" {...props}>
          <div className={styles.container}>
            <FileUploaderContent
              disabled={disabled}
              progress={INITIAL_PROGRESS}
              fileInfo={fileInfo}
              multiple={multiple}
              onPreview={onPreview}
              onRemove={handleOnRemove}
              onChangeFile={setRemovedFile}
              multipleFiles={multipleFiles}
            />
          </div>
        </Dragger>
        {status === "error" && <p className={styles.errorMsg}>{fileInfo?.error}</p>}
      </div>
    </Form.Item>
  );
};

export default FileUploader;
