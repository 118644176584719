import { useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { DEBOUNCE_DELAY_TIME } from "Modules/DS/Search";
import { ISelectContext, SelectContext } from "Modules/DS/Select";
import { ESidePanelButtonType, ISidePanelButton, ISidePanelProps } from "Modules/DS/SidePanel";
import { useMutableData } from "API/useData";

import { getReceiptsUrls } from "Views/Transaction/Helper";
import { IGetTrxnResponse } from "Views/Transaction/@types";
import { TRANSACTION_SUB_TYPES, TRANSACTION_TYPES, EXPENSE_DETAILS_TYPES } from "Views/Transaction/Constants";

import { useDetailsFetch } from "./useFetch";
import { useFetchInvoiceLink } from "Modules/BillImportedFromXeroBanner/useFetchInvoiceLink";

export const useDetails = <T extends IGetTrxnResponse>(isSaveEditsAllowed: boolean, loadingPermission: boolean) => {
  const actionRef = useRef<() => Promise<void>>(() => null);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const initialTransactionNumber = params.get("transaction_number");

  const { fetchInvoiceLink, billImportedFromXeroData, setBillImportedFromXeroData, loadingXeroInvoiceLink } =
    useFetchInvoiceLink();

  const [visible, setVisible] = useState(Boolean(initialTransactionNumber));
  const [record, setRecord] = useState<T>({} as T);
  const [actionLoading, setActionloading] = useState(false);

  const { isLoading: loadingInitialData } = useMutableData(
    initialTransactionNumber
      ? `/ms/spm-reporting/v1/transactions?limit=1&exclude_types=Transfer&exclude_types=Fees&accounting_search=${initialTransactionNumber}`
      : null,
    {
      onSuccess(data) {
        const record = data?.data?.data?.[0];

        if (record) {
          setRecord(record);
        }
      },
    }
  );

  const { select, closeSelect }: ISelectContext = useContext<ISelectContext>(SelectContext);

  const {
    expenseData,
    loading,
    setLoading: setExpenseDataLoading,
    attachments,
    resetExpenseData,
    isHistoricalRefund,
  } = useDetailsFetch(
    record?.id,
    record?.transaction_number,
    record?.transaction_type,
    getReceiptsUrls(record?.receipts),
    visible
  );

  useEffect(() => {
    if (visible && record.id && expenseData?.source === "portal_xero") {
      fetchInvoiceLink(record.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, record, expenseData]);

  const setDetail = (record: T) => {
    if (select.show) {
      closeSelect();
      return;
    }
    setVisible(true);
    setRecord(record);
  };

  const onCloseHandler = () => {
    setVisible(false);
    setBillImportedFromXeroData(null);
    attachments.current = [];
    resetExpenseData();
  };

  const sidePanelBtns: ISidePanelButton[] = [
    {
      id: "SIDE-PANEL-BUTTON-001",
      text: "Save",
      disabled: false,
      loading: actionLoading,
      action: async () => {
        setActionloading(true);
        await actionRef.current();
        setTimeout(() => setActionloading(false), DEBOUNCE_DELAY_TIME.MAX * 2);
      },
      type: ESidePanelButtonType.SECONDARY,
    },
  ];

  const sidePanelBtnProps = {
    sticky: true,
    buttons: sidePanelBtns,
  };

  const buttonWhitelist = (trxn_type: TRANSACTION_TYPES) => {
    return (
      !loading &&
      !loadingPermission &&
      !isHistoricalRefund &&
      isSaveEditsAllowed &&
      EXPENSE_DETAILS_TYPES.has(trxn_type) &&
      !trxn_type.includes(TRANSACTION_SUB_TYPES.REVERSAL)
    );
  };

  const ifTrxnTypeHasButton = (trxn_type: TRANSACTION_TYPES) => {
    return buttonWhitelist(trxn_type) ? sidePanelBtnProps : {};
  };

  const detailsProps: Pick<ISidePanelProps, "visible" | "onClose" | "title" | "sticky" | "buttons"> = {
    visible: visible,
    onClose: onCloseHandler,
    title: record.transaction_type,

    ...ifTrxnTypeHasButton(record?.transaction_type as TRANSACTION_TYPES),
  };

  return {
    record,
    setRecord,
    setDetail,
    actionRef,
    attachments,
    expenseData,
    detailsProps,
    isHistoricalRefund,
    sidePanelLoading:
      loading || loadingPermission || loadingXeroInvoiceLink || (initialTransactionNumber && loadingInitialData),
    billImportedFromXeroData,
    setExpenseDataLoading,
  };
};
