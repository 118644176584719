import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";
import ReKYBModal from "Views/KYX/ReKYB/Modal";
import ReKYBBanner, { ReKYBBannerProps } from "Views/KYX/ReKYB/Banner";
import { KYB_STATUS, RE_KYB_LABELS, ReKYBLinks } from "Views/KYX/Constants";
import { isBefore } from "utility/DateUtilites";
import { COMPANY_VERIFICATION, ORG_VERIFICATION } from "Route/KYX/paths";
import { cookieNames, getCookieValue } from "utility/CookieHelper";
import { IPreliminaryData } from "Views/KYX/@types";
import { RootState } from "Redux/ConfigureStore";
import { useAppDispatch, useAppSelector } from "hook";
import { getPreliminaryData } from "Redux/Actions/KYX/GetPreliminaryData";

const ReKYBContainer = (): React.ReactElement => {
  const location = useLocation();
  const dispatch = useAppDispatch();

  // We should display re-KYB modal and banner, only if the logged in user role is Admin
  const { isAdmin = false } = useSelector((state: any) => state?.userInfoReducer);

  const isVerificationPage: boolean = location?.pathname.includes(ORG_VERIFICATION);

  // show_reKYB_modal is the cookie value, it will be true for initial and become false while clicking update later
  const show_reKYB_modal: boolean = getCookieValue(cookieNames.SHOW_REKYB_MODAL) === "true";

  const preliminaryData: IPreliminaryData = useAppSelector(
    (state: RootState) => state.preliminaryDataReducer?.data?.payload
  );

  useEffect(() => {
    dispatch(getPreliminaryData());
  }, [dispatch]);

  // KYB submission due date or last date
  const rekyb_due_date: string = preliminaryData?.rekyb_due_date;
  const kyb_status: KYB_STATUS = preliminaryData?.kyb_status;
  const isOrgBlocked: boolean = preliminaryData?.is_org_blocked;

  // showReKYBModal => is to control show and hide the modal (ex- when we click update later button)
  const [showReKYBModal, setShowReKYBModal] = useState(true as boolean);

  // We should show the modal if the KYB_STATUS is not started
  const showModal = show_reKYB_modal && showReKYBModal;

  const getBannerInfo = (): ReKYBBannerProps => {
    const dueDate: dayjs.Dayjs = dayjs(rekyb_due_date);
    const currentDate = dayjs();
    const reminingDays = dueDate.diff(currentDate, "days");
    const isDueDatePassed = isBefore(dueDate, currentDate, "day");

    const isUpdateStatus = [KYB_STATUS.NOT_STARTED, KYB_STATUS.STARTED].includes(kyb_status);

    switch (true) {
      case isDueDatePassed && isUpdateStatus:
        return {
          text: RE_KYB_LABELS.UPDATE_BEFORE_EXPIRY,
          link: { label: RE_KYB_LABELS.UPDATE_COMPANY_DATA, link: COMPANY_VERIFICATION.WELCOME },
          show: !show_reKYB_modal && !isVerificationPage,
        };
      case kyb_status === KYB_STATUS.NOT_STARTED && !isDueDatePassed:
        return {
          text:
            reminingDays === 0
              ? RE_KYB_LABELS.LAST_DAY_MESSAGE
              : `Update your company data in ${reminingDays} days to avoid losing access to Spenmo.`,
          link: { label: RE_KYB_LABELS.UPDATE_COMPANY_DATA, link: COMPANY_VERIFICATION.WELCOME },
          show: !show_reKYB_modal && !isVerificationPage,
        };
      case kyb_status === KYB_STATUS.STARTED && !isDueDatePassed:
        return {
          text:
            reminingDays === 0
              ? RE_KYB_LABELS.LAST_DAY_MESSAGE
              : `Finish your company data update within ${reminingDays} days to avoid losing access to Spenmo.`,
          link: { label: RE_KYB_LABELS.UPDATE_COMPANY_DATA, link: COMPANY_VERIFICATION.WELCOME_BACK },
          show: show_reKYB_modal && !isVerificationPage,
        };
      case kyb_status === KYB_STATUS.REJECTED && (isDueDatePassed || !isDueDatePassed):
        return {
          text: RE_KYB_LABELS.REJECTED_DATA,
          link: { label: "Customer Support", link: ReKYBLinks.supportLink },
          openInNewTab: true,
          show: !show_reKYB_modal && !isVerificationPage,
        };
      default:
        return { show: false };
    }
  };

  return (
    <>
      {!showModal && isAdmin && <ReKYBBanner {...getBannerInfo()} />}
      {showModal && !isVerificationPage && (
        <ReKYBModal
          setShowReKYBModal={setShowReKYBModal}
          dueDate={rekyb_due_date}
          isAdmin={isAdmin}
          kyb_status={kyb_status}
          isOrgBlocked={isOrgBlocked}
        />
      )}
    </>
  );
};

export default ReKYBContainer;
