import { ENTITY_TYPES } from "Redux/Actions/KYX/GetEntityTypes";
import { ILegalEntityType, ILegalEntityTypeReducer } from "Redux/Actions/KYX/types";
import { userInitialState } from "Redux/InitialState";

export const entityTypesReducer = (
  state: any = userInitialState,
  action: { type: ENTITY_TYPES; data: ILegalEntityType }
): ILegalEntityTypeReducer => {
  switch (action.type) {
    case ENTITY_TYPES.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ENTITY_TYPES.SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case ENTITY_TYPES.ERROR:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: true,
      };
    default:
      return state;
  }
};
