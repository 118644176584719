import React from "react";

import { IColumnData } from "Modules/DS/DataTable";

import { InputCell } from "Views/Transaction/Shimmer";
import { CellWithSubtitle } from "Views/Transaction/List";
import { ALL_TRXNS_COLUMNS } from "Views/Transaction/Constants";
import { IGetTrxnResponse, GET_TRXN_RESPONSE_PARAMS } from "Views/Transaction/@types";

export const TeamCell = ({ value }: { value: string }) => {
  return (
    <InputCell>
      <CellWithSubtitle title={value} charCount={9} />
    </InputCell>
  );
};

export const team = (): IColumnData<IGetTrxnResponse>["column"] => ({
  title: ALL_TRXNS_COLUMNS.TEAM,
  width: 128,
  dataIndex: GET_TRXN_RESPONSE_PARAMS.TEAM_NAME,
  key: GET_TRXN_RESPONSE_PARAMS.TEAM_ID,
  render: (value: string) => <TeamCell value={value} />,
});
