import React, { useContext, useEffect, useRef } from "react";

import {
  IFilterItem,
  IListContainer,
  ISingleQuickFilter,
  IQuickFilterContainer,
  initialSingleFilterStates,
} from "Modules/DS/Filter/@types";
import { ILineProps, LINE_TYPE } from "Modules/DS/Menu";
import { ISelectContext, SelectContext } from "Modules/DS/Select";
import { useSingleSelect } from "Modules/DS/Menu/List/useHooks/useSingleSelect";

import { Container } from "./Container";

export const SingleQuickFilter = <T extends object = any>({
  height,
  children,
  displayKey,
  defaultValue,
  idKey = "id" as keyof T,
  appliedKey = "id" as keyof T,
  header = null,
  footer = null,
  iconClass = "",
  filterItemProps,
  onSelect = () => null,
  clearFilter = () => null,
  onClickOutside = () => null,
  presetValue,
  clear = false,
  states = { ...initialSingleFilterStates },
}: ISingleQuickFilter<T>): JSX.Element => {
  const { select, closeSelect } = useContext<ISelectContext>(SelectContext);

  const isSelected: boolean = states.title && states.title !== defaultValue;

  const clearingFilter = useRef<boolean>(false);

  const isPopoverMounted = select.show && select.key === filterItemProps.id;

  useEffect(() => {
    if (clear) {
      handleClearFilter();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clear]);

  useEffect(() => {
    if (states.selected && !states.title && clearingFilter.current) {
      handleClearFilter();
      clearingFilter.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearingFilter]);

  useEffect(() => {
    if (presetValue) {
      const checked: T = children.find((item) => presetValue === item[appliedKey as string]);
      if (!checked) return;

      if (states.title && !states.selected) {
        states?.setSelected?.(checked[idKey as string]);
        return;
      }

      if (!states.selected && !states.title && !clearingFilter.current) {
        states?.setSelected?.(checked[idKey as string]);
        states?.setTitle?.(checked[displayKey as string]);

        return;
      } else if (!states.selected && !states.title && clearingFilter.current) {
        handleClearFilter();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPopoverMounted]);

  const onClickHandler = useSingleSelect((id: string) => {
    const selectedItem: T = children.find((item) => item[idKey as string] === id);

    if (!selectedItem[idKey as string] || typeof selectedItem[idKey as string] !== "string") return;
    if (!selectedItem[displayKey as string] || typeof selectedItem[displayKey as string] !== "string") return;
    if (!selectedItem[appliedKey as string] || typeof selectedItem[appliedKey as string] !== "string") return;

    states?.setSelected?.(selectedItem[idKey as string]);
    states?.setTitle?.(selectedItem[displayKey as string]);

    const value: string = selectedItem[appliedKey as string];

    onSelect?.(value);
    closeSelect();
  });

  const handleClearFilter = (): void => {
    clearingFilter.current = true;

    states?.setLoading?.(false);
    states?.setSelected?.("");
    states?.setTitle?.("");
    clearFilter?.();
    closeSelect();
  };

  const lineProps = (item: T): ILineProps => ({
    id: item[idKey as string],
    title: item[displayKey as string],
    selected: states.selected === item[appliedKey as string],
    type: LINE_TYPE.SINGLE_LINE,
    loading: states?.loading,
    onClick: onClickHandler,
  });

  const filterProps: Omit<IFilterItem, "children" | "iconClass"> = {
    onClickOutside,
    selected: isSelected,
    clearFilter: handleClearFilter,
    title: isSelected ? states.title : defaultValue,
    ...filterItemProps,
  };

  const listProps: Omit<IListContainer<T>, "children"> = {
    idKey,
    height,
    lineProps,
    loading: states?.loading,
  };

  const props: Omit<IQuickFilterContainer<T>, "children"> = {
    header,
    footer,
    iconClass,
    listProps,
    filterItemProps: filterProps,
  };

  return <Container<T> {...props}>{children}</Container>;
};
