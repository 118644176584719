import React from "react";
import PropTypes from "prop-types";
import LetterAvatar from "../../letterAvatar";
import { Checkbox } from "antd";
import "./selectDataItem.scss";

const SelectDataItem = ({ data, isTeam }) => {
  return (
    <div className="select-data-item">
      {isTeam ? (
        <LetterAvatar name={data.name || data.team_name} square />
      ) : (
        <LetterAvatar name={data.name || data.team_name} rounded />
      )}
      <Checkbox className={"select-data-item__checkbox"} value={data}>
        {data.name || data.team_name}
      </Checkbox>
    </div>
  );
};

SelectDataItem.propTypes = {
  data: PropTypes.object,
  isTeam: PropTypes.bool,
};

export default SelectDataItem;
