import APIclient from "API/Client";

import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";

import { DELETE_EMPLOYEE_FROM_ORG_ACTION_TYPES, IDeleteEmployeeFromOrgData } from "Redux/Actions/Employee/Delete/types";

export const fetchDeleteEmployeeFromOrgRequest = (): {
  type: DELETE_EMPLOYEE_FROM_ORG_ACTION_TYPES.FETCH_DELETE_EMPLOYEE_FROM_ORG_REQUEST;
} => {
  return {
    type: DELETE_EMPLOYEE_FROM_ORG_ACTION_TYPES.FETCH_DELETE_EMPLOYEE_FROM_ORG_REQUEST,
  };
};

export const fetchDeleteEmployeeFromOrgSuccess = (
  data: IDeleteEmployeeFromOrgData,
  employeeId: string,
  employeeName: string
): {
  type: DELETE_EMPLOYEE_FROM_ORG_ACTION_TYPES.FETCH_DELETE_EMPLOYEE_FROM_ORG_SUCCESS;
  data: IDeleteEmployeeFromOrgData;
} => {
  return {
    type: DELETE_EMPLOYEE_FROM_ORG_ACTION_TYPES.FETCH_DELETE_EMPLOYEE_FROM_ORG_SUCCESS,
    data: { ...data, id: employeeId, name: employeeName },
  };
};

export const fetchDeleteEmployeeFromOrgError = (
  data: IDeleteEmployeeFromOrgData,
  employeeId: string,
  employeeName: string
): {
  type: DELETE_EMPLOYEE_FROM_ORG_ACTION_TYPES.FETCH_DELETE_EMPLOYEE_FROM_ORG_ERROR;
  data: IDeleteEmployeeFromOrgData;
} => {
  return {
    type: DELETE_EMPLOYEE_FROM_ORG_ACTION_TYPES.FETCH_DELETE_EMPLOYEE_FROM_ORG_ERROR,
    data: { ...data, id: employeeId, name: employeeName },
  };
};

export const clearDeleteEmployeeFromOrg = () => {
  return {
    type: DELETE_EMPLOYEE_FROM_ORG_ACTION_TYPES.CLEAR_DELETE_EMPLOYEE_FROM_ORG,
    data: {},
  };
};

export const deleteEmployeeFromOrg = (employeeId: string, employeeName?: string) => {
  return (dispatch) => {
    dispatch(fetchDeleteEmployeeFromOrgRequest());

    APIclient.deleteData(`/api/v1/user/${employeeId}`)
      .then((res: { [propName: string]: any }) => {
        if (res.data.status > HTTP_STATUS_CODE.OK) {
          dispatch(fetchDeleteEmployeeFromOrgError(res.data, employeeId, employeeName));
        } else {
          dispatch(fetchDeleteEmployeeFromOrgSuccess(res.data.payload, employeeId, employeeName));
        }
      })
      .catch((err) => {
        dispatch(fetchDeleteEmployeeFromOrgError(err, employeeId, employeeName));
      });
  };
};
