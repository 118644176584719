import { ITrxnMoreFilter } from "Views/Transaction/@types";
import { useState } from "react";

export const useFilter = <T extends object = any>(
  defaultFilter: T,
  onApply: (value: T) => void,
  moreFilters: string[],
  more: ITrxnMoreFilter
) => {
  const [filter, setFilter] = useState<T>(defaultFilter);

  const onApplyFilter = (newFilter: object, dispatchAction?: Function) => {
    const combinedFilter: T = removeDanglingFilter({ ...filter, ...newFilter });

    setFilter(combinedFilter);

    onApply(combinedFilter);

    dispatchAction?.();
  };

  /**
   * There's a data mismatch between the more state and the filter state
   * When we are removing the data from the more state, we aren't updating the filter state
   * this function will sync the filter data based on the latest more state data
   */
  const removeDanglingFilter = (combinedFilter) => {
    Object.keys(combinedFilter).forEach((key) => {
      if (moreFilters.includes(key) && filter[key] && !more[key]) {
        delete combinedFilter[key];
      }
    });

    return combinedFilter;
  };

  const clearFilter = (keys: string[]) => {
    const newFilter: T = { ...filter };
    keys.every((key) => delete newFilter[key]);

    setFilter(newFilter);
  };

  const resetFilter = (keys: string[], dispatchAction?: Function) => {
    const newFilter: T = { ...filter };
    keys.every((key) => delete newFilter[key]);

    setFilter(newFilter);
    onApply(newFilter);

    dispatchAction?.();
  };

  const resetAllFilters = (dispatchAction?: Function) => {
    setFilter({} as T);
    onApply({} as T);

    dispatchAction?.();
  };

  return { onApplyFilter, resetFilter, resetAllFilters, filter, setFilter, clearFilter };
};
