import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import CustomCarousel from "Views/Home/Components/CustomCarousel";
import Card from "Views/Home/Components/MyCards/UserCards/Carousel/Card";
import "Views/Home/Components/MyCards/UserCards/Carousel/Carousel.scss";
import ViewAllCard from "../ViewAllCard";

const UserCardsCarousel = ({ cardsData }) => {
  const {
    cardList = [],
    totalCount = 0,
    status_message = "Something went wrong, Please try again later.",
  } = cardsData || {};
  const [cards, setCards] = useState([]);
  const CARD_CAROUSEL_LIMIT = 10;

  useEffect(() => {
    let mappedCards =
      Array.isArray(cardList) &&
      cardList?.map((cardDetails) => <Card cardDetails={cardDetails} key={cardDetails?.id} />);

    if (totalCount > CARD_CAROUSEL_LIMIT) mappedCards.push(<ViewAllCard />);

    setCards(mappedCards || []);
  }, [cardList, totalCount]);

  return (
    <div className="user-cards__carousel--container">
      <div className="user-cards__carousel">
        {cards?.length > 0 ? (
          <CustomCarousel data={cards} slidesToShow={Math.min(cards?.length, 3)} slidesToScroll={3} />
        ) : (
          <div className="user-cards__placeholder">{status_message}</div>
        )}
      </div>
    </div>
  );
};

UserCardsCarousel.propTypes = {
  cardsData: PropTypes.any,
};

export default UserCardsCarousel;
