import React from "react";

import { InfoOutline, Tooltip, Typography } from "@spenmo/splice";

import styles from "./index.module.scss";
import Section from "../Section";

export interface IBatchPayoutProps {
  date: string;
  amount: string;
  reference: string;
}
const BatchPayout = ({ date, amount, reference }: IBatchPayoutProps) => (
  // eslint-disable-next-line react/jsx-pascal-case
  <Section>
    <Typography size="m" variant="headline-brand" tag="h3">
      Batch Payout
    </Typography>
    <ul className={styles.batchPayoutSummary}>
      <li className={styles.batchPayoutSummaryItem}>
        <Typography size="s" variant="body-content" tag="p" className={styles.batchPayoutSummaryItemTitle}>
          Batch Payout Date
        </Typography>
        <Typography
          size="s"
          variant="body-content"
          tag="p"
          weight={600}
          className={styles.batchPayoutSummaryItemDetail}
        >
          {date}
        </Typography>
      </li>
      <li className={styles.batchPayoutSummaryItem}>
        <Typography size="s" variant="body-content" tag="p" className={styles.batchPayoutSummaryItemTitle}>
          Total Payout Amount
          <Tooltip title="This is the sum of all approved reimbursements this month to be deposited to your account as a single transaction">
            <InfoOutline size="16" iconColor="var(--icon-default)" />
          </Tooltip>
        </Typography>
        <Typography
          size="s"
          variant="body-content"
          tag="p"
          weight={600}
          className={styles.batchPayoutSummaryItemDetail}
        >
          {amount}
        </Typography>
      </li>
      <li className={styles.batchPayoutSummaryItem}>
        <Typography size="s" variant="body-content" tag="p" className={styles.batchPayoutSummaryItemTitle}>
          Transaction Reference
        </Typography>
        <Typography
          size="s"
          variant="body-content"
          tag="p"
          weight={600}
          className={styles.batchPayoutSummaryItemDetail}
        >
          {reference !== "N.A." && reference !== "-" ? (
            <a
              href={`/transactions/?tab=all&transaction_number=${reference}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {reference}
            </a>
          ) : (
            reference
          )}
        </Typography>
      </li>
    </ul>
  </Section>
);

export default BatchPayout;
