import React, { useEffect } from "react";
import { Form, Radio } from "antd";
import { IAdditionalConfigProps } from "../@types";
import { useSettingContext } from "../context/SettingContext";
import { DescriptiveText, SettingLabel } from "../components";
import { PARTNER_TYPE } from "../helper/constant";
import styles from "./styles.module.scss";

const BillAdditionalConfig: React.FC<IAdditionalConfigProps> = ({
  setting,
  settingUpdateNotAllowed,
  partnerName,
}: IAdditionalConfigProps) => {
  const { form, setSettingChangeObj } = useSettingContext();

  const findDefaultValue = () => {
    const autoSyncBill = setting.find(({ code }) => code === "xero_auto_sync_bill")?.isEnabled;
    return autoSyncBill ? "xero_auto_sync_bill" : "xero_auto_paid_bill";
  };

  const syncOnEveryStep = "Sync bill transactions when created, approved, or paid";
  const syncOnlyWhenPaid = "Sync bill transactions when paid";

  useEffect(() => {
    form.setFieldsValue({ xero_bill_sync_setting: findDefaultValue() });
  }, [setting]);

  return (
    <div className={styles.bottomSpacing}>
      <SettingLabel text="Sync Transactions" />
      <DescriptiveText description="The following conditions determine when the transaction will be synced." />
      <Form.Item name="xero_bill_sync_setting">
        <Radio.Group
          onChange={(e) =>
            setSettingChangeObj((settingChangeObj) => ({
              ...settingChangeObj,
              additionalConfig: e.target.value !== findDefaultValue(),
            }))
          }
          disabled={settingUpdateNotAllowed}
          className="sp-radio-group"
        >
          {partnerName !== PARTNER_TYPE.NETSUITE && <Radio value="xero_auto_sync_bill">{syncOnEveryStep}</Radio>}
          <Radio value="xero_auto_paid_bill">{syncOnlyWhenPaid}</Radio>
        </Radio.Group>
      </Form.Item>
    </div>
  );
};

export default BillAdditionalConfig;
