import { PreviewField } from "Views/Bills/V2/components/Preview/type";

export const transformPreviewRecipient = (recipientData, actionText, actionCallback): PreviewField[] => {
  const { countryName, currencyCode, dynamicFields } = recipientData || {};
  const fields = [
    {
      type: "default" as "default",
      key: "recipientCountry",
      labelName: "Recipient Country",
      oldValue: "",
      isEdited: false,
      newValue: countryName,
    },
    {
      type: "default" as "default",
      key: "recipientCurrency",
      labelName: "Recipient Currency",
      oldValue: "",
      isEdited: false,
      newValue: currencyCode,
    },
  ];

  dynamicFields
    ?.sort((a, b) => {
      if (a.orderNumber < b.orderNumber) {
        return -1;
      } else if (a.orderNumber > b.orderNumber) {
        return 1;
      } else {
        return 0;
      }
    })
    ?.forEach((field) => {
      const { label, value } = field;

      if (value !== "true" && value !== "false") {
        fields.push({
          type: "default" as "default",
          key: `dynamicFields.${label}`,
          labelName: label,
          oldValue: "",
          isEdited: false,
          newValue: value,
        });
      }
    });

  return [
    {
      key: "recipientDetails",
      label: "Recipient Details",
      actionText,
      onClickAction: actionCallback,
      fields,
    },
  ];
};
