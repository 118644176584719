import * as React from "react";
import { useContext } from "react";

import { debounce } from "utility";

import { DEBOUNCE_DELAY_TIME, EMPLOYEES_INITIAL_PAGE_NUMBER } from "constants/Employee.constant";

import Searchbar from "Modules/search";

import { EmployeesPageContext, IEmployeesContext } from "Views/Employee/context";

const Search = () => {
  const { setSearchVal, filters, getEmployeesData }: IEmployeesContext = useContext(EmployeesPageContext);

  const handleSearch = debounce((value: string) => {
    setSearchVal(value);
    getEmployeesData(EMPLOYEES_INITIAL_PAGE_NUMBER, value, filters.values);
  }, DEBOUNCE_DELAY_TIME);

  return <Searchbar getKeyUpValue={handleSearch} placeholder="Search employee name / email" />;
};

export default Search;
