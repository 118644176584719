import { useState, useEffect } from "react";
import { getTransactionTags } from "Redux/DataCalls/TransactionTag.api";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";

export const useTransactionTags = (transactionId, refetch = false, onSuccess = () => {}) => {
  const [tags, setTags] = useState([]);
  useEffect(() => {
    if (transactionId || refetch) {
      getTransactionTags(transactionId).then(({ data }) => {
        if (data.status === HTTP_STATUS_CODE.OK) {
          setTags(data.payload.tags);
          onSuccess(data.payload.tags);
        }
      });
    }
  }, [transactionId, refetch]);

  return [tags, setTags];
};
