import axios from "axios";
import { axiosHeader } from "./index";
import { GetOrgId } from "../../utility";

export const FETCH_CARD_ACTION_REQUEST = "FETCH_CARD_ACTION_REQUEST";
export const FETCH_CARD_ACTION_SUCCESS = "FETCH_CARD_ACTION_SUCCESS";
export const FETCH_CARD_ACTION_FAILURE = "FETCH_CARD_ACTION_FAILURE";

export const fetchDataReq = (data) => {
  return {
    type: FETCH_CARD_ACTION_REQUEST,
    data: data,
  };
};
export const fetchDataSuccess = (data) => {
  return {
    type: FETCH_CARD_ACTION_SUCCESS,
    data: {
      payload: data,
    },
  };
};
export const fetchDataError = (data) => {
  return {
    type: FETCH_CARD_ACTION_FAILURE,
    data: data,
  };
};

export const cardEditActionFunc = (cardId, data) => {
  const orgId = GetOrgId();

  return (dispatch) => {
    dispatch(fetchDataReq());
    axios
      /*eslint no-process-env: "off"*/
      .put(
        window.__ENV__.API_BASE_URL + `/api/v1/org/card/${cardId}`,
        { organisation_id: orgId, ...data },
        axiosHeader
      )
      .then((data) => {
        dispatch(fetchDataSuccess(data.data));
      })
      .catch((err) => {
        dispatch(fetchDataError(err));
      });
  };
};

export const cardBlockActionFunc = (cardId, data) => {
  const orgId = GetOrgId();

  return (dispatch) => {
    dispatch(fetchDataReq());
    axios
      /*eslint no-process-env: "off"*/
      .post(
        window.__ENV__.API_BASE_URL + `/api/v1/org/card/${cardId}/block`,
        { organisation_id: orgId, ...data },
        axiosHeader
      )
      .then((data) => {
        dispatch(fetchDataSuccess(data.data));
      })
      .catch((err) => {
        dispatch(fetchDataError(err));
      });
  };
};
