import APIclient from "API/Client";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { newApiCallState } from "Models/Network/utils";
import { RootState } from "Redux/ConfigureStore";
import {
  ApprovalRequestCenter,
  ApprovalRequestTabCount,
  newApprovalRequestFilter,
} from "Redux/Actions/Approval/ApprovalCenter/types";
import {
  updateApiCallStateAsCompleted,
  updateApiCallStateAsError,
  updateApiCallStateAsLoading,
  updateApiCallStateMessage,
} from "Redux/stateUtils";

import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import {
  ACTION_TYPE_PAY,
  BillFilter,
  CANCEL_REQUEST_MESSAGE,
  FilterKey,
  REIMBURSEMENT_STATUS,
  TAB_KEY,
} from "Views/Approval/ApprovalCenter/types";
import { getRequestIndex } from "Views/Approval/ApprovalCenter/helper";
import { urlParamsBuilder, parseToURLEncodedForm } from "utility/APIWrapper";
import ApprovalRequest from "Models/Approval/ApprovalCenter/approvalRequest.interface";
import { saveTransactionApi } from "Redux/DataCalls/Transactions.api";
import { trackEvent } from "utility/analytics";
import {
  BILLS_APPROVAL_EVENTS,
  CR_APPROVAL_EVENTS,
  CARD_APPROVAL_EVENTS,
} from "Views/Approval/ApprovalCenter/trackEvents";
import { CardUpdateRequestPayload } from "Views/Card/Review/types";
import axios, { CancelTokenSource } from "axios";
import { GetOrgId } from "utility";

let requestToken: CancelTokenSource | null = null;

export const initialState: ApprovalRequestCenter = {
  activeTab: "",
  activeSubTab: REIMBURSEMENT_STATUS.PENDING,
  allRequests: [],
  apiCallState: newApiCallState(),
  approvalRequestDetail: null,
  fetchApprovalRequestDetail: newApiCallState(),
  fetchCountApiCallState: newApiCallState(),
  approveApiCallState: newApiCallState(),
  selectedRequests: [],
  totalCountByRequestType: [
    { tabKey: TAB_KEY.INVOICE, title: "Bills", pendingRequests: 0 },
    { tabKey: TAB_KEY.CARD, title: "Cards", pendingRequests: 0 },
    {
      tabKey: TAB_KEY.REIMBURSEMENT,
      title: "Reimbursements",
      pendingRequests: 0,
    },
    { tabKey: TAB_KEY.FUND, title: "Fund Transfer", pendingRequests: 0 },
  ] as ApprovalRequestTabCount[],
  totalPendingRequest: 0,
  approvalRequestFilter: newApprovalRequestFilter(),
  previewFilesList: [],
  isWorkflowChange: false,
  editedCR: "",
  approvalConfig: {
    reimbursement: {
      payImmediatelyEnabled: false,
    },
  },
};

export const getReimbursementData = (id: string) => {
  return APIclient.getData(`/api/v1/transactions/${id}`);
};

export const fetchApprovalRequestCount = createAsyncThunk(
  "approvalRequest/count",
  async () => {
    const response = await APIclient.getData("/api/v1/user/pending-count");

    if (response?.status === HTTP_STATUS_CODE.OK) {
      return response.data.payload;
    }
  },
);

export const getApprovalList = async ({ filters = {}, limit, page }) => {
  // cancel the current request first, fetchApprovalRequestList is used to fetch data for the listing table, only the latest list should be displayed
  if (requestToken?.cancel) requestToken.cancel(CANCEL_REQUEST_MESSAGE);
  // create the request token for new listing request
  requestToken = axios.CancelToken.source();

  let cancelToken = requestToken.token;

  const response = await APIclient.getData(
    urlParamsBuilder(
      `${window.__ENV__.API_BASE_URL}/ms/spm-organization/v3/approvals/`,
      {
        ...filters,
        limit,
        page,
      },
    ),
    "",
    null,
    { cancelToken },
  );
  requestToken = null; // clear the request token since request completed

  if (response.status == HTTP_STATUS_CODE.OK) {
    const data = response.data.payload;
    return data;
  }
};

export const fetchApprovalRequestList = createAsyncThunk(
  "approvalRequest/list",
  (never, thunkAPI) => {
    let state = (thunkAPI.getState() as RootState)
      .approvalRequest as ApprovalRequestCenter;
    const filters = Object.assign({}, state.approvalRequestFilter);
    if (filters.search == null) delete filters.search;
    let pagination = state.apiCallState.paging;
    let limit = pagination.limit;
    let page = pagination.currentPage;

    return getApprovalList({
      filters,
      limit,
      page,
    });
  },
);

interface FetchApprovalRequestDetailPayload {
  process_id: string;
  process_type: string;
}

export const fetchApprovalRequest = createAsyncThunk(
  "approvalRequest/fetchByProcessId",
  async (
    fetchApprovalRequestDetailPayload: FetchApprovalRequestDetailPayload,
    thunkAPI,
  ) => {
    const url = urlParamsBuilder(
      `/ms/spm-organisation/v3/approvals/detail/`,
      fetchApprovalRequestDetailPayload,
    );

    const response = await APIclient.getData(url);
    if (response.status === HTTP_STATUS_CODE.OK) {
      const data = response.data.payload;
      return data;
    }
  },
);

export interface ApproveRequestFundTransferPayload {
  values: {
    [key: string]: string;
  };
  requestId: string;
  type: "approve" | "reject";
}

export const approveRequestFundTransfer = createAsyncThunk(
  "approvalRequest/fundTransfer",
  async (
    { values, requestId, type }: ApproveRequestFundTransferPayload,
    thunkAPI,
  ) => {
    const orgId = GetOrgId();
    let response;
    try {
      response = await APIclient.putData(
        `/api/v1/fund/request/${type}/${requestId}`,
        {
          organisation_id: orgId,
          ...values,
        },
      );
      const responseData = response.data && response.data;
      const { status_message } = responseData?.payload || {};
      if (responseData?.status >= HTTP_STATUS_CODE.BAD_REQUEST) {
        return thunkAPI.rejectWithValue({ errMessage: status_message });
      }
    } catch (err) {
      const { status_message } = err?.data?.payload || {};
      return thunkAPI.rejectWithValue({ errMessage: status_message });
    }
    if (response.status === HTTP_STATUS_CODE.OK) {
      return response.data;
    }
  },
);

export const approvalRequestDecision = createAsyncThunk(
  "approvalRequest/decision",
  async (
    {
      processIds,
      processType,
      isApproved,
      notes,
      singleCardInfo,
    }: {
      processIds: string[];
      processType: string;
      isApproved: boolean;
      notes?: string;
      singleCardInfo?: CardUpdateRequestPayload;
    },
    thunkAPI,
  ) => {
    const url = `/ms/spm-organization/v3/approvals/decision`;
    let response;
    try {
      response = await APIclient.putData(url, {
        processIds,
        processType,
        isApproved,
        notes,
        singleCardInfo,
      });
    } catch (err) {
      return thunkAPI.rejectWithValue({ isApproved: isApproved });
    }

    let user = (thunkAPI.getState() as RootState).user;
    if (response.status == HTTP_STATUS_CODE.OK) {
      const data = {
        isSuccess: true,
        isApproved: isApproved,
        isBulkApproval: processIds.length > 1 ? true : false,
        requestCount: processIds.length,
        processIds,
        role: user.role,
      };
      return data;
    }
  },
);

export const approvalApproveSettleRequest = createAsyncThunk(
  "approvalRequest/approve-settle",
  async (
    {
      processId,
      processType,
      actionType,
    }: { processId: string; processType: string; actionType: string },
    thunkAPI,
  ) => {
    const url = `/ms/spm-organization/v3/approvals/${actionType}`;
    let response;
    try {
      switch (actionType) {
        case ACTION_TYPE_PAY.APPROVE_SETTLE:
          response = await APIclient.putData(url, { processId, processType });
          break;
        case ACTION_TYPE_PAY.SETTLE:
          response = await APIclient.postData(url, { processId, processType });
          break;
        default:
          break;
      }
    } catch (err) {
      const errMessage = err.response?.data?.error?.message;
      return thunkAPI.rejectWithValue({
        errMessage: errMessage,
        isSettled: false,
      });
    }
    let user = (thunkAPI.getState() as RootState).user;
    if (response.status == HTTP_STATUS_CODE.OK) {
      return {
        isSettled: true,
        actionType: actionType,
        processId,
        role: user.role,
      };
    }
  },
);

export const getApprovalConfig = createAsyncThunk(
  "approval/config",
  async (never, thunkAPI) => {
    const url = `ms/spm-organisation/v3/approvals/config`;
    let response;
    try {
      response = await APIclient.getData(url);
    } catch (err) {
      return thunkAPI.rejectWithValue({ errMessage: err });
    }
    if (response.status === HTTP_STATUS_CODE.OK) {
      return response;
    }
  },
);

export const checkWorkflow = createAsyncThunk(
  "approval/check-workflow",
  async (
    data: { processId: string; processType: string; categoryId: string },
    thunkAPI,
  ) => {
    const url = `/ms/spm-organization/v3/approvals/workflow-check`;
    let response;
    try {
      response = await APIclient.putData(url, data);
    } catch (err) {
      const errMessage = err.response?.data?.error?.message;
      return thunkAPI.rejectWithValue({ errMessage: errMessage });
    }
    if (response.status === HTTP_STATUS_CODE.OK) {
      return response;
    }
  },
);

export const saveReimbursementDetails = createAsyncThunk(
  "reimbursementDetail/save",
  async (
    {
      payload,
      id,
      transaction_number,
    }: { payload: any; id: string; transaction_number: string },
    thunkAPI,
  ) => {
    const url = `/api/v1/fund/request/reimbursement/${id}`;
    let response;
    try {
      if (!transaction_number) {
        response = await APIclient.putData(
          url,
          parseToURLEncodedForm({ ...payload }),
          false,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          },
        );
      } else {
        const newPayload = {
          ...payload,
          photo_urls: payload.image_urls,
          transaction_number,
        };
        delete newPayload.image_urls;
        delete newPayload.type;
        response = await saveTransactionApi(newPayload);
      }
    } catch (err) {
      const errMessage = err.response?.data?.error?.message;
      return thunkAPI.rejectWithValue({ errMessage: errMessage });
    }
    if (response.status === HTTP_STATUS_CODE.OK) {
      return { ...response, processId: id };
    }
  },
);

const approvalRequestSlice = createSlice({
  name: "approvalRequest",
  initialState,
  reducers: {
    setActiveTab(state, action: PayloadAction<TAB_KEY>) {
      state.activeTab = action.payload;
      Object.assign(state.approvalRequestFilter, {
        type: action.payload,
        search: null,
      });
    },
    setActiveSubTab(state, action: PayloadAction<REIMBURSEMENT_STATUS>) {
      state.activeSubTab = action.payload;
    },
    setSelectedRequests(state, action: PayloadAction<ApprovalRequest[]>) {
      let selectedRowsOnCurrentPage = action.payload;
      selectedRowsOnCurrentPage.forEach((selectedRow) => {
        let requestAlreadySelected =
          getRequestIndex(state.selectedRequests, selectedRow) !== -1;
        if (!requestAlreadySelected) {
          state.selectedRequests.push(selectedRow);
        }
      });

      let requestToRemove = [];
      state.selectedRequests.forEach((request) => {
        let selectedOnCurrentPage =
          getRequestIndex(selectedRowsOnCurrentPage, request) !== -1;
        let presentOnCurrentPage =
          getRequestIndex(state.allRequests, request) !== -1;

        if (presentOnCurrentPage && !selectedOnCurrentPage) {
          requestToRemove.push(request.id);
        }
      });
      if (requestToRemove.length) {
        state.selectedRequests = state.selectedRequests.filter(
          (selectedRequest) =>
            requestToRemove.indexOf(selectedRequest.id) === -1,
        );
      }
    },
    applyFilter(state, action: PayloadAction<Partial<BillFilter>>) {
      let partialFilter = action.payload;
      Object.keys(partialFilter).forEach((key) => {
        if (partialFilter[key] === undefined) {
          delete partialFilter[key];
        }
      });
      Object.assign(state.approvalRequestFilter, partialFilter);
    },
    clearFilter(state, action: PayloadAction<string>) {
      let filterKey = action.payload;
      switch (filterKey) {
        case FilterKey.Amount_Send:
          delete state.approvalRequestFilter.minSendAmount;
          delete state.approvalRequestFilter.maxSendAmount;
          break;
        case FilterKey.Amount_Receive:
          delete state.approvalRequestFilter.minReceiveAmount;
          delete state.approvalRequestFilter.maxReceiveAmount;
          delete state.approvalRequestFilter.receiveCurrency;
          break;
        case FilterKey.Invoice_Amount:
          delete state.approvalRequestFilter.minInvoiceAmount;
          delete state.approvalRequestFilter.maxInvoiceAmount;
          delete state.approvalRequestFilter.invoiceCurrency;
          break;
        case FilterKey.Request_Date:
          delete state.approvalRequestFilter.startDate;
          delete state.approvalRequestFilter.endDate;
          break;
        case FilterKey.Transaction_Date:
          delete state.approvalRequestFilter.trxDateFrom;
          delete state.approvalRequestFilter.trxDateTo;
          break;
        case FilterKey.Amount:
          delete state.approvalRequestFilter.minAmount;
          delete state.approvalRequestFilter.maxAmount;
          break;
        case FilterKey.Reimbursement_Status:
          delete state.approvalRequestFilter.pendingSettlement;
          break;
        case FilterKey.Card_Type:
          delete state.approvalRequestFilter.isPhysicalCard;
          break;
        case FilterKey.Requester:
          delete state.approvalRequestFilter.requester;
          break;
      }
    },
    clearAllFilter(state, action: PayloadAction<string>) {
      state.approvalRequestFilter = { ...newApprovalRequestFilter() };
    },
    setPreviewFilesList(state, action: PayloadAction<string[]>) {
      state.previewFilesList = action.payload;
    },
    setCurrentPage(state, action: PayloadAction<number>) {
      state.apiCallState.paging.currentPage = action.payload;
    },
    clearSelectedRows(state, action: PayloadAction<void>) {
      state.selectedRequests = [];
    },
    resetApproveApiCallState(state, action: PayloadAction<void>) {
      state.approveApiCallState = newApiCallState();
    },
    clearAllRequests(state, action: PayloadAction<void>) {
      state.allRequests = [];
    },
    updateAPICallState(
      state,
      action: PayloadAction<{ error: boolean; message: string }>,
    ) {
      state.approveApiCallState.error = action.payload.error;
      state.approveApiCallState.message = action.payload.message;
    },
    resetState(state, action: PayloadAction<void>) {
      const totalPendingRequest = state.totalPendingRequest;
      Object.assign(state, { ...initialState, totalPendingRequest });
    },
    setEditedCR(state, action: PayloadAction<string>) {
      state.editedCR = action.payload;
    },
    clearEditedCR(state, action: PayloadAction<string>) {
      state.editedCR = "";
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(fetchApprovalRequestList.fulfilled, (state, action) => {
        let data = action.payload.list;
        let pagination = action.payload.pagination;
        state.allRequests = [];
        if (data) {
          data.forEach((request) => {
            state.allRequests.push(request);
          });
        }
        state.apiCallState.paging = { ...pagination };
        updateApiCallStateAsCompleted(state.apiCallState);
        state.isWorkflowChange = false;
      })
      .addCase(fetchApprovalRequestList.rejected, (state, action) => {
        if (action.error.message !== CANCEL_REQUEST_MESSAGE) {
          updateApiCallStateAsError(state.apiCallState);
        }
      })
      .addCase(fetchApprovalRequestList.pending, (state) => {
        updateApiCallStateAsLoading(state.apiCallState);
      })
      .addCase(fetchApprovalRequest.fulfilled, (state, action) => {
        updateApiCallStateAsCompleted(state.fetchApprovalRequestDetail);
        state.approvalRequestDetail = action.payload;
      })
      .addCase(fetchApprovalRequest.pending, (state, action) => {
        updateApiCallStateAsLoading(state.fetchApprovalRequestDetail);
      })
      .addCase(fetchApprovalRequest.rejected, (state, action) => {
        updateApiCallStateAsError(state.fetchApprovalRequestDetail);
      })
      .addCase(fetchApprovalRequestCount.pending, (state, action) => {
        updateApiCallStateAsLoading(state.fetchCountApiCallState);
      })
      .addCase(fetchApprovalRequestCount.fulfilled, (state, action) => {
        updateApiCallStateAsCompleted(state.fetchCountApiCallState);
        let data = action.payload;
        state.totalPendingRequest = data.pending_approval_count || 0;
        state.totalCountByRequestType[0].pendingRequests =
          data.pending_approval_invoice_count;
        state.totalCountByRequestType[1].pendingRequests =
          data.pending_approval_card_count;
        state.totalCountByRequestType[2].pendingRequests =
          data.pending_approval_reimbursement_count;
        state.totalCountByRequestType[3].pendingRequests =
          data.pending_approval_fund_count;
      })
      .addCase(approvalRequestDecision.rejected, (state, action) => {
        let response = action.payload as { isApproved: boolean };
        updateApiCallStateMessage(
          state.approveApiCallState,
          `An error occurred when ${response.isApproved ? "approving" : "declining"} the request(s). Please try again.`,
        );
        updateApiCallStateAsError(state.approveApiCallState);
      })
      .addCase(approvalRequestDecision.pending, (state, action) => {
        state.approveApiCallState = newApiCallState({ message: "" });
        updateApiCallStateAsLoading(state.approveApiCallState);
      })
      .addCase(approvalRequestDecision.fulfilled, (state, action) => {
        let data = action.payload;
        let typeRequest = "";
        let eventName = "";
        let eventData = {};
        const actionName = data.isApproved ? "Approve" : "Decline";
        switch (state.activeTab) {
          case TAB_KEY.INVOICE:
            eventData = {
              bill_id: data.processIds,
              user_role: data.role,
            };
            eventName = BILLS_APPROVAL_EVENTS.billRequestProcessed;
            eventData["approval_processed_event_action"] = actionName;
            typeRequest = "Bill Payment";
            break;
          case TAB_KEY.CARD:
            eventData = {
              card_id: data.processIds,
              user_role: data.role,
            };
            eventName = CARD_APPROVAL_EVENTS.cardRequestProcessed;
            eventData["approval_processed_event_action"] = actionName;
            typeRequest = "Card";
            break;
          case TAB_KEY.REIMBURSEMENT:
            eventData = {
              bill_id: data.processIds,
              user_role: data.role,
            };
            eventName = CR_APPROVAL_EVENTS.crRequestProcessed;
            eventData["approval_cr_processed_event_action"] = actionName;
            typeRequest = "Reimbursement";
            break;
          default:
            break;
        }

        if (data.isSuccess) {
          updateApiCallStateAsCompleted(state.approveApiCallState);
          updateApiCallStateMessage(
            state.approveApiCallState,
            `${data.requestCount}
            ${typeRequest} request${data.isBulkApproval ? "s" : ""} ${
              data.isBulkApproval ? "have" : "has"
            } been successfully ${data.isApproved ? "approved" : "declined"}.`,
          );
          if (eventName) {
            trackEvent(eventName, eventData);
          }
        }
      })
      .addCase(approveRequestFundTransfer.fulfilled, (state, action) => {
        const response = action.payload?.payload as { status_message: string };
        updateApiCallStateAsCompleted(state.approveApiCallState);
        const getSuccessMessage = (message: string): string => {
          if (message === "request approved successfully!")
            return "A Fund Transfer request has been successfully approved";
          else if (message === "request rejected successfully!")
            return "A Fund Transfer request has been declined";
          return message;
        };
        updateApiCallStateMessage(
          state.approveApiCallState,
          getSuccessMessage(response.status_message),
        );
      })
      .addCase(approveRequestFundTransfer.rejected, (state, action) => {
        const response = action.payload as { errMessage: string };
        updateApiCallStateAsError(state.approveApiCallState);
        updateApiCallStateMessage(
          state.approveApiCallState,
          response.errMessage,
        );
      })
      .addCase(approveRequestFundTransfer.pending, (state) => {
        state.approveApiCallState = newApiCallState({ message: "" });
        updateApiCallStateAsLoading(state.approveApiCallState);
      })
      .addCase(approvalApproveSettleRequest.rejected, (state, action) => {
        const response = action.payload as { errMessage: string };
        updateApiCallStateMessage(
          state.approveApiCallState,
          response.errMessage,
        );
        updateApiCallStateAsError(state.approveApiCallState);
      })
      .addCase(approvalApproveSettleRequest.pending, (state, action) => {
        state.approveApiCallState = newApiCallState({ message: "" });
        updateApiCallStateAsLoading(state.approveApiCallState);
      })
      .addCase(approvalApproveSettleRequest.fulfilled, (state, action) => {
        const data = action.payload;
        if (data.isSettled) {
          updateApiCallStateAsCompleted(state.approveApiCallState);
          updateApiCallStateMessage(
            state.approveApiCallState,
            `1 Reimbursement request has been successfully paid.`,
          );
          if (data.actionType === ACTION_TYPE_PAY.APPROVE_SETTLE) {
            const eventName = CR_APPROVAL_EVENTS.crRequestProcessed;
            const eventData = {
              bill_id: data.processId,
              user_role: data.role,
              approval_cr_processed_event_action: "Pay Immediately",
            };
            trackEvent(eventName, eventData);
          }
        }
      })
      .addCase(saveReimbursementDetails.pending, (state) => {
        state.approveApiCallState = newApiCallState({ message: "" });
        updateApiCallStateAsLoading(state.approveApiCallState);
      })
      .addCase(saveReimbursementDetails.fulfilled, (state, action) => {
        updateApiCallStateAsCompleted(state.approveApiCallState);
        updateApiCallStateMessage(
          state.approveApiCallState,
          `Reimbursement detail has been successfully updated.`,
        );
      })
      .addCase(saveReimbursementDetails.rejected, (state, action) => {
        const response = action.payload as { errMessage: string };
        updateApiCallStateAsError(state.approveApiCallState);
        updateApiCallStateMessage(
          state.approveApiCallState,
          response.errMessage,
        );
      })
      .addCase(checkWorkflow.fulfilled, (state, action) => {
        const payload = action.payload?.data?.payload;
        const activeSubTab = state.activeSubTab;
        if (activeSubTab !== REIMBURSEMENT_STATUS.READY) {
          state.isWorkflowChange = payload.isWorkflowChange;
        }
      })
      .addCase(getApprovalConfig.fulfilled, (state, action) => {
        const payload = action.payload.data.payload;
        state.approvalConfig.reimbursement.payImmediatelyEnabled =
          payload.reimbursement.payImmediatelyEnabled;
      }),
});

export const {
  setActiveTab,
  setActiveSubTab,
  setSelectedRequests,
  applyFilter,
  clearFilter,
  setPreviewFilesList,
  setCurrentPage,
  clearSelectedRows,
  clearAllFilter,
  resetApproveApiCallState,
  clearAllRequests,
  updateAPICallState,
  resetState,
  setEditedCR,
  clearEditedCR,
} = approvalRequestSlice.actions;

export const selectApprovalRequestList = (state: RootState) => {
  if (state.approvalRequest.allRequests) {
    return state.approvalRequest.allRequests;
  } else {
    return [];
  }
};

export const selectApprovalRequest = (state: RootState) => {
  if (state.approvalRequest.approvalRequestDetail) {
    return state.approvalRequest.approvalRequestDetail;
  }
};

export const selectActiveTab = (state: RootState) => {
  return state.approvalRequest.activeTab;
};

export const selectWorkflowChange = (state: RootState) => {
  return state.approvalRequest.isWorkflowChange;
};

export const selectActiveSubTab = (state: RootState) => {
  return state.approvalRequest.activeSubTab;
};

export const selectApiCallState = (state: RootState) => {
  if (state.approvalRequest.apiCallState) {
    return state.approvalRequest.apiCallState;
  }
  return newApiCallState();
};

export const selectApproveApiCallState = (state: RootState) => {
  if (state.approvalRequest.approveApiCallState) {
    return state.approvalRequest.approveApiCallState;
  }
  return newApiCallState();
};

export const selectApprovalFilters = (state: RootState) => {
  return state.approvalRequest.approvalRequestFilter;
};

export const selectApprovalRequestTabItems = (state: RootState) => {
  return state.approvalRequest.totalCountByRequestType;
};

export const selectPreviewFileList = (state: RootState) => {
  return state.approvalRequest.previewFilesList;
};

export const selectSelectedRequests = (state: RootState) => {
  if (state.approvalRequest.selectedRequests) {
    return state.approvalRequest.selectedRequests;
  }
  return [];
};

export const selectSearchValue = (state: RootState) => {
  if (
    state.approvalRequest.approvalRequestFilter &&
    state.approvalRequest.approvalRequestFilter.search != null
  ) {
    return state.approvalRequest.approvalRequestFilter.search;
  }
  return null;
};

export const selectIfEditedCRPresent = (state: RootState) => {
  return state.approvalRequest.editedCR;
};

export const selectPayImmediatelyConfig = (state: RootState) => {
  return state.approvalRequest.approvalConfig.reimbursement
    .payImmediatelyEnabled;
};

export default approvalRequestSlice.reducer;
