import APIclient from "API/Client";
import { GetOrgId } from "../../utility";
import { trackEvent } from "utility/analytics";
export const FETCH_TOPUP_REQUEST = "FETCH_TOPUP_REQUEST";
export const FETCH_TOPUP_SUCCESS = "FETCH_TOPUP_SUCCESS";
export const FETCH_TOPUP_FAILURE = "FETCH_TOPUP_FAILURE";
export const RESET_TOPUP_DATA = "RESET_TOPUP_DATA";

export const fetchDataReq = (data = { payload: null }) => {
  return {
    type: FETCH_TOPUP_REQUEST,
    data: data,
  };
};
export const fetchDataSuccess = (data, paymentType) => {
  return {
    type: FETCH_TOPUP_SUCCESS,
    data,
    paymentType,
  };
};
export const fetchDataError = (error, paymentType) => {
  return {
    type: FETCH_TOPUP_FAILURE,
    paymentType,
    error,
  };
};
export const resetTopupData = () => {
  return {
    type: RESET_TOPUP_DATA,
  };
};

const trackTopupCompletion = () => {
  const params = { event: "TopUp completed" };
  trackEvent("complete top up", params);
};

export const topupActionFunc = (values) => {
  const DEFAULT_TOPUP_ERROR_MESSAGE = "Failed to generate Paynow QR Code. Please contact our team.";

  return (dispatch, getState) => {
    dispatch(fetchDataReq());

    APIclient.postData("ms/spm-topup/v3/dashboard/accounts/paynow", {
      ...values,
    })
      .then((data) => {
        if (data.data.status > 200 || data.status > 200) {
          dispatch(fetchDataError(data?.payload?.status_message || DEFAULT_TOPUP_ERROR_MESSAGE, values.type));
        } else {
          trackTopupCompletion(); // To track TopUp completion
          dispatch(fetchDataSuccess(data.data, values.type));
        }
      })
      .catch(() => {
        dispatch(fetchDataError(DEFAULT_TOPUP_ERROR_MESSAGE, values.type));
      });
  };
};
