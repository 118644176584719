import {
  initialEmployeeState,
  IEmployeesData,
  IEmployeesReducer,
  GET_EMPLOYEES_ACTION_TYPES,
} from "Redux/Actions/Employee/Get/Employees/types";

export const allEmployeesReducer = (
  state: IEmployeesReducer = initialEmployeeState,
  action: { type: GET_EMPLOYEES_ACTION_TYPES; data: IEmployeesData }
): IEmployeesReducer => {
  switch (action.type) {
    case GET_EMPLOYEES_ACTION_TYPES.FETCH_ALL_EMPLOYEES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_EMPLOYEES_ACTION_TYPES.FETCH_ALL_EMPLOYEES_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case GET_EMPLOYEES_ACTION_TYPES.FETCH_ALL_EMPLOYEES_ERROR:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: true,
      };
    default:
      return state;
  }
};
