import React, { useContext } from "react";

import ModalWrapper from "Modules/DS/Modal";

import { ITrxnModalContext } from "Views/Transaction/@types";
import { TrxnModalContext } from "Views/Transaction/Context";

import { useModalType } from "./useModalType";

const Modal = () => {
  const { showModal }: ITrxnModalContext = useContext(TrxnModalContext);

  const { visible } = showModal;

  const modalType = useModalType()[showModal.type];

  return (
    visible && (
      <ModalWrapper className={modalType.className} visible={visible} close={modalType.close}>
        {modalType.children}
      </ModalWrapper>
    )
  );
};

export default Modal;

export * from "./useModal";
