export const handleTimer = (TIME_IN_SECONDS) => {
  switch (true) {
    case TIME_IN_SECONDS === 60:
      return Math.floor((TIME_IN_SECONDS % 3600) / 60) + " minute";
    case TIME_IN_SECONDS > 60 && TIME_IN_SECONDS < 3600:
      return Math.floor((TIME_IN_SECONDS % 3600) / 60) + " minutes";
    case TIME_IN_SECONDS >= 3600:
      return Math.floor(TIME_IN_SECONDS / 3600) + " hour";
    default:
      return TIME_IN_SECONDS + " seconds";
  }
};
