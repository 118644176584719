import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { IEmployee } from "Redux/Actions/Employee/Get/Employees/types";
import { updateRole, clearUpdateRole } from "Redux/Actions/Employee/Update/Role";

import { INITIAL_ROLE_UPDATE_CALL_COUNT } from "constants/Employee.constant";

import getRoleAnalytics from "Views/Employee/CustomerSupport/getRoleAnalytics";
import { IRole, intialPendingRequest, IPendingRequest } from "Views/Employee/types";

const useIfDispatchFails = (): [
  IPendingRequest,
  number,
  (requestCount: number, newRoleId: number, employee: IEmployee) => void
] => {
  const dispatch = useDispatch();

  const [pendingRequest, setPendingRequest] = useState({ ...intialPendingRequest });
  const [updateRequestCount, setUpdateRequestCount] = useState(INITIAL_ROLE_UPDATE_CALL_COUNT);

  const rolesList: IRole[] = useSelector((state: any) => state.employeeRoleReducer?.data?.payload);

  const dispatchRoleUpdate = (requestCount: number, roleId: number, employee: IEmployee): void => {
    dispatch(clearUpdateRole());

    const updatedRole = Array.isArray(rolesList) && rolesList?.find((role) => role?.id === roleId);

    const updatedRoleEvent = getRoleAnalytics(updatedRole, employee);

    setUpdateRequestCount(requestCount);
    dispatch(updateRole(roleId, employee.id, updatedRoleEvent, employee.name));
    requestCount === INITIAL_ROLE_UPDATE_CALL_COUNT && setPendingRequest({ newRoleId: roleId, employee: employee });
  };

  return [pendingRequest, updateRequestCount, dispatchRoleUpdate];
};

export default useIfDispatchFails;
