import { useContext, useEffect, useMemo, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllTaxesFunc } from "Redux/Actions";
import { TAX_STATUS } from "constants/Tax.constant";
import { fetchAccountingTag } from "Redux/Actions/AccountingTag/accountingTagAction";
import { TrxnToastContext } from "Views/Transaction/Context";
import { initialForm } from "Views/Accounting/Workflow/context/FormContext";
import { usePermission } from "Views/SubscriptionManagement/hooks/usePermission";

export const useBulkAction = () => {
  const { allowedToOpenPage } = usePermission();
  const [moreActionMenu, dispatchMoreActionMenu] = useReducer(
    (state: { loading: boolean; show: boolean }, action: { type: "toggle_visible" | "toggle_loading" }) => {
      switch (action.type) {
        case "toggle_visible":
          return {
            ...state,
            show: !state.show,
          };
        case "toggle_loading":
          return {
            ...state,
            loading: !state.loading,
          };
        default:
          return state;
      }
    },
    {
      show: false,
      loading: false,
    }
  );
  const { setToaster } = useContext(TrxnToastContext);

  const dispatch = useDispatch();
  const { accountingTag, taxesData } = useSelector((state: any) => ({
    accountingTag: state.accountingTagReducer,
    taxesData: state.taxReducer.data,
  }));

  const tags = useMemo(() => accountingTag?.data?.payload?.tags || [], [accountingTag]);
  const taxes = useMemo(() => taxesData?.payload?.data || [], [taxesData]);
  const bulkActionContextValue = useMemo(() => ({ ...initialForm, tags, taxes }), [tags, taxes]);

  useEffect(() => {
    dispatch(getAllTaxesFunc({ status: TAX_STATUS.ACTIVE }));
    dispatch(fetchAccountingTag([]));
  }, [dispatch]);

  const toggleVisible = () => dispatchMoreActionMenu({ type: "toggle_visible" });
  const toggleLoading = () => dispatchMoreActionMenu({ type: "toggle_loading" });

  return {
    moreActionMenu,
    dispatchMoreActionMenu,
    tags,
    taxes,
    size: `item__${tags.length + (allowedToOpenPage ? 2 : 1)}`,
    toggleLoading,
    toggleVisible,
    setToaster,
    bulkActionContextValue,
  };
};
