import React from "react";
import PropTypes from "prop-types";
import { Steps } from "antd";
import "./stepsBar.scss";

const StepsBar = (props) => {
  const { data = [], current, action = () => {}, initialStep, className = "" } = props;
  const { Step } = Steps;

  return (
    <Steps className={`steps-bar ${className}`} current={current} labelPlacement={"vertical"} onChange={action}>
      {data.map((step) => (
        <Step key={step.step} title={step.step_name} disabled={step.step > initialStep + 1} />
      ))}
    </Steps>
  );
};

StepsBar.propTypes = {
  data: PropTypes.array,
  current: PropTypes.number,
  initialStep: PropTypes.number,
  action: PropTypes.func,
  className: PropTypes.string,
};

export default StepsBar;
