const down_16 = require("assets/v1.1/icons/Navigation/Chevron/down_16.svg");
const down_24 = require("assets/v1.1/icons/Navigation/Chevron/down_24.svg");
const up_24 = require("assets/v1.1/icons/Navigation/Chevron/up_24.svg");

export const Chevron = {
  16: down_16,
  24: {
    up: up_24,
    down: down_24,
  },
};
