import * as React from "react";
import { useDispatch } from "react-redux";
import { Input } from "antd";
import { createCardFunc } from "Redux/Actions";
import { ActivatePhysicalCardEventNames } from "Views/Card/Analytics";
import { trackEvent } from "utility/analytics";

import Modal, { ModalComponent } from "Modules/DS/Modal";
import { TITLE_TYPE } from "Modules/DS/Modal/types";
import Button from "Modules/button";

import styles from "Views/Card/Add/ActivateCardModal/index.module.scss";

const MAX_CARD_NUMBER_LENGTH = 4;

interface ISyncCardModalProps {
  onClose: () => void;
  onActivateCard?: (cardLastFour: string) => void | null;
}

const SyncCardModal = ({ onClose, onActivateCard = null }: ISyncCardModalProps) => {
  const [cardLastFour, setCardLastFour] = React.useState("");

  const dispatch = useDispatch();

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event?.target || {};
    setCardLastFour(value);
    value?.length === MAX_CARD_NUMBER_LENGTH &&
      trackEvent(ActivatePhysicalCardEventNames.CARD_PHYSICAL_ACTIVATED, {
        card_activation_event_action: "Entered Number",
      });
  };

  const onConfirm = () => {
    if (onActivateCard) {
      onActivateCard(cardLastFour);
    } else {
      // to support legacy code :D should be removed in tech debt
      const formData = { card_last_four: cardLastFour };
      dispatch(createCardFunc("physicalSync", formData));
      onClose();
    }
    trackEvent(ActivatePhysicalCardEventNames.CARD_PHYSICAL_ACTIVATED, {
      card_activation_event_action: "Activate Button",
    });
    setCardLastFour("");
  };

  return (
    <Modal visible close={onClose} className={styles["activate-card-modal"]}>
      <ModalComponent.CloseButton />
      <ModalComponent.Title titleType={TITLE_TYPE.withBorder}>Activate Physical Card</ModalComponent.Title>

      <div className={styles["activate-card-modal__info"]}>
        <p className={styles["activate-card-modal__info__text"]}>
          Once you receive your Physical Card, enter your card's last 4 digits to start using it.
        </p>
        <Input
          addonBefore={"**** **** **** ****"}
          placeholder={"1234"}
          className={styles["activate-card-modal__input"]}
          maxLength={MAX_CARD_NUMBER_LENGTH}
          onChange={onInputChange}
        />
        <div className={styles["activate-card-modal__action-btn"]}>
          <Button
            text="Confirm"
            disabled={cardLastFour?.length < MAX_CARD_NUMBER_LENGTH}
            rounded
            className={cardLastFour?.length < MAX_CARD_NUMBER_LENGTH ? "" : "active"}
            action={onConfirm}
          />
        </div>
      </div>
    </Modal>
  );
};

export default SyncCardModal;
