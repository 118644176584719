import APIclient from "API/Client";
import { userInitialState } from "Redux/InitialState";
import { ALL_TRXNS_SERVICE } from "Views/Transaction/DataCalls/Services";
import { TABS } from "Views/TransactionsListing/TransactionsHelper";
import { GetBaseAuthObject, downloadCSV } from "utility";
import { decodeArrayBuffer, downloadPDF } from "Views/Transaction/Helper/downloadPDF";

import { GetFormData, GetFormDataWithArray } from "utility/APIWrapper";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";

export const FETCH_CSV_REQUEST = "FETCH_CSV_REQUEST";
export const FETCH_CSV_SUCCESS = "FETCH_CSV_SUCCESS";
export const FETCH_CSV_FAILURE = "FETCH_CSV_FAILURE";
export const CLEAR_CSV_DATA = "CLEAR_CSV_DATA";

const fetchDataReq = (data = userInitialState) => {
  return {
    type: FETCH_CSV_REQUEST,
    data: data,
  };
};

export const fetchDataSuccess = (data) => {
  return {
    type: FETCH_CSV_SUCCESS,
    data: data,
    message: data?.status_message,
  };
};

export const fetchDataError = (data) => {
  return {
    type: FETCH_CSV_FAILURE,
    data: data,
    message: data.payload?.status_message || data?.status_message,
  };
};

export const clearCsvData = (data = userInitialState) => {
  return {
    type: CLEAR_CSV_DATA,
    data: data,
  };
};

const getAccountingTrxnsExportParams = (params) => {
  delete params.limit;
  delete params.search_after;
  delete params.previous_page;
  return params;
};
const START_DATE = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
const END_DATE = new Date(Date.now());
const thisMonthDateRange = () => ({ start_date: START_DATE.toISOString(), end_date: END_DATE.toISOString() });

export const TransactionCSVFunc = (filters, view, subTab) => {
  const baseAuthObject = GetBaseAuthObject();
  const orgId = baseAuthObject.orgId;
  let data = {
    start_date: "2018-01-01 00:00:00",
    end_date: "2040-12-31 00:00:00",
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    organisation_id: orgId,
    search_filters: { is_bill_tab: view === TABS.pending, ...filters },
  };

  return (dispatch) => {
    dispatch(fetchDataReq());
    const isAccountingPage = view === "accounting";
    let uri = "/api/getTransactionsCSV";
    let filename = "all-transactions";
    if (view === TABS.pending) {
      if (subTab === "To Approve") data.to_approve = 1;
      filename = "bill-transactions";
      uri = "/ms/spm-disbursement/bill/csv";
    } else if (isAccountingPage) {
      uri = ALL_TRXNS_SERVICE.DOWNLOAD_CSV;
      data = getAccountingTrxnsExportParams({ ...thisMonthDateRange(), ...filters });
    }

    APIclient.postData(uri, isAccountingPage ? data : GetFormDataWithArray({ ...data }))
      .then((data) => {
        if (data.data.status > 200) {
          dispatch(fetchDataError(data.data));
        } else {
          downloadCSV(data.data, filename);
          dispatch(fetchDataSuccess(data.data && { status_message: "Download successful!" }));
        }
      })
      .catch((err) => {
        dispatch(fetchDataError({ status_message: err?.response?.data?.error || err.message }));
      });
  };
};

export const DownloadBankStatementFunc = (filters, email = null) => {
  const payload = getAccountingTrxnsExportParams({ email, ...thisMonthDateRange(), ...filters });
  return (dispatch) => {
    dispatch(fetchDataReq());
    APIclient.postData(ALL_TRXNS_SERVICE.DOWNLOAD_BANK_STATEMENT, payload, false, { responseType: "arraybuffer" })
      .then((res) => {
        let status_message = "Data exported and downloaded succesfully.";
        if (!email) {
          downloadPDF(res.data, "bank_statement");
        } else {
          const decoded = decodeArrayBuffer(res.data);
          status_message = decoded?.message;
        }
        dispatch(fetchDataSuccess(res.data && { status_message }));
      })
      .catch((err) => {
        const errRes = err?.response?.data && decodeArrayBuffer(err?.response?.data);
        dispatch(fetchDataError({ status_message: errRes?.error || err.message }));
      });
  };
};

export const XeroCSVFunc = () => {
  const data = {
    start_date: "2018-01-01 00:00:00",
    end_date: "2040-12-31 00:00:00",
  };

  return (dispatch) => {
    dispatch(fetchDataReq());
    APIclient.postData("/api/getXeroCSV", data)
      .then((data) => {
        if (data.data.status > 200) {
          dispatch(fetchDataError(data.data));
        } else {
          downloadCSV(data.data, "transactions");
          dispatch(fetchDataSuccess(data.data && { status_message: "Download successful!" }));
        }
      })
      .catch((err) => {
        dispatch(fetchDataError(err));
      });
  };
};

export const ExpenseCSVFunc = (filters = {}) => {
  const orgId = GetBaseAuthObject().orgId;
  const data = {
    start_date: "2018-01-01 00:00:00",
    end_date: "2040-12-31 00:00:00",
    organisation_id: orgId,
    spend_type: "all",
    status: "all",
    search_filters: { ...filters },
  };

  return (dispatch) => {
    dispatch(fetchDataReq());
    APIclient.postData("/api/getExpensesCSV", GetFormData({ ...data }))
      .then((data) => {
        if (data.data.status > 200) {
          dispatch(fetchDataError(data.data));
        } else {
          downloadCSV(data.data, "transactions");
          dispatch(fetchDataSuccess(data.data && { status_message: "Download successful!" }));
        }
      })
      .catch((err) => {
        dispatch(fetchDataError(err));
      });
  };
};

export const RequestsCSVFunc = (teamId, isSents, filters = {}, include_my_requests = false) => {
  const orgId = GetBaseAuthObject().orgId;
  const sentRequests = isSents && { is_scheduled_request: false };
  const data = {
    organisation_id: orgId,
    status: "all",
    teamId: teamId,
    filters: { ...filters },
    include_my_requests,
  };

  return (dispatch) => {
    dispatch(fetchDataReq());
    APIclient.postData("/api/v1/fund/request/list/csv", GetFormData({ ...data, ...sentRequests }))
      .then((data) => {
        if (data.data.status > 200) {
          dispatch(fetchDataError(data.data));
        } else {
          downloadCSV(data.data, "requests");
          dispatch(fetchDataSuccess(data && { status_message: "Download successful!" }));
        }
      })
      .catch((err) => {
        dispatch(fetchDataError(err));
      });
  };
};

export const SentFundsCSVFunc = (filters) => {
  return (dispatch) => {
    dispatch(fetchDataReq());
    APIclient.postData("/ms/spm-reporting/v1/transactions/downloadcsv", filters)
      .then((data) => {
        if (data.data.status > HTTP_STATUS_CODE.OK) {
          dispatch(fetchDataError(data.data));
        } else {
          downloadCSV(data.data, "all-transactions");
          dispatch(fetchDataSuccess(data && { status_message: "Download successful!" }));
        }
      })
      .catch((err) => {
        dispatch(fetchDataError(err));
      });
  };
};
