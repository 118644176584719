import React from "react";
import styles from "./index.module.scss";
import classNames from "classnames";
import { FUND_TRANSFERS_SUB_TABS } from "../Actions/constant";

interface Props {
  activeTab: FUND_TRANSFERS_SUB_TABS;
  onChangeTab: (tab: FUND_TRANSFERS_SUB_TABS) => void;
}

interface Tab {
  id: number;
  active: boolean;
  text: FUND_TRANSFERS_SUB_TABS;
}

export const FundTransferSubTabs = ({ activeTab, onChangeTab }: Props) => {
  const tabs: Tab[] = [
    {
      id: 1,
      active: activeTab === FUND_TRANSFERS_SUB_TABS.REQUESTED_FUNDS,
      text: FUND_TRANSFERS_SUB_TABS.REQUESTED_FUNDS,
    },
    {
      id: 2,
      active: activeTab === FUND_TRANSFERS_SUB_TABS.SENT_FUNDS,
      text: FUND_TRANSFERS_SUB_TABS.SENT_FUNDS,
    },
  ];

  const onClick = (tab: Tab) => {
    onChangeTab(tab.text);
  };

  return (
    <div className={styles["sub-tab"]}>
      <div className={styles["status-filter"]}>
        {tabs.map((tab) => (
          <div
            key={tab.id}
            id={tab.text}
            data-testid={tab.text}
            className={classNames({ [styles["status-filter__active"]]: tab.active })}
            onClick={() => onClick(tab)}
          >
            {tab.text}
          </div>
        ))}
      </div>
    </div>
  );
};
