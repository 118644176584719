import { IAddEmployeeTab } from "Views/Employee/types";

import Manually from "Views/Employee/Create/Page/Manually";
import BulkUpload from "Views/Employee/Create/Page/BulkUpload";

export const ADD_EMPLOYEES_TABS: IAddEmployeeTab[] = [
  {
    id: "1",
    tabTitle: "Add manually",
    component: Manually,
  },
  {
    id: "2",
    tabTitle: "Add many in a go",
    component: BulkUpload,
  },
];
