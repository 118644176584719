import React from "react";
import { FlatIcon } from "assets/v1.1/icons";
import { Row, Col } from "Splice/Grid";
import styles from "./index.module.scss";

export const EmptyState = () => {
  return (
    <Row>
      <Col span={24} className={styles.emptyContainer}>
        <div>
          <img src={FlatIcon.emptyState} alt="empty-state" className={styles.emptyIcon} />
        </div>
        <p className={styles.heading}>No Recorded Transactions</p>
        <div className={styles.subHeading}>
          <p className={styles.subHeadingText}>
            {" "}
            There are no recorded transactions under this filter. Change the <br />
            filter to get another results.
          </p>
        </div>
      </Col>
    </Row>
  );
};
