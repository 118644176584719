import dayjs from "dayjs";

import { currencyFormatterV2 } from "utility";
import { IAuditTrail } from "Modules/TransactionDetail";

export const detailDataMapping = (transaction: any) => {
  const defaultEmptyText = "N.A.";
  return {
    ...transaction,
    id: transaction.id,
    createdAt: transaction.created_at,
    transactionNumber: transaction.transaction_number,
    transactionType: "Reimbursement",
    merchant: transaction.simplified_merchant_name || transaction.merchant,
    amount: currencyFormatterV2(transaction.amount || 0, transaction.currency_code || "SGD", true),
    amountWithoutCurrency: transaction.amount,
    currency: transaction.currency_code,
    employee: {
      name: transaction.user_name,
      team: transaction.team_name || defaultEmptyText,
    },
    category: transaction?.expense_category_name || defaultEmptyText,
    receipt: transaction?.receipts?.urls,
    fxRate: transaction?.foreign_currency_amount || defaultEmptyText,
    fxCurrency: transaction?.foreign_currency_code || defaultEmptyText,
    comment: transaction.comment,
    accountingMessage: transaction?.accounting_message || "",
    accountingTransactionStatus: transaction?.accounting_transaction_status,
    baseAmount: transaction.amount,
    expenseDate: dayjs.unix(transaction.request_timestamp).format("DD MMM YYYY") || defaultEmptyText,
    payoutDetails: {
      date: transaction?.payout_detail?.date || defaultEmptyText,
      amount: transaction?.payout_detail?.currency + " " + transaction?.payout_detail?.amount || defaultEmptyText,
      reference: transaction?.payout_detail?.transaction_number || defaultEmptyText,
    },
  };
};

export const convertStatusJourneyToAuditTrail = (journey: any): IAuditTrail[] => {
  return journey?.map((val): IAuditTrail => {
    return {
      label: val.label,
      state: val.state,
      time: val.timestamp,
      executor: val.user?.name,
      description: val.description,
    };
  });
};
