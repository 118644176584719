import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Select } from "antd";
import DropdownSelection from "./DropdownSelection";
import "./multiSelection.scss";

const MultiSelection = (props) => {
  const { listData = [], listDataLoading = false, defaultValue = [], placeholder, initialValues, action } = props;
  const [selectedList, setSelectedList] = useState(initialValues || defaultValue);

  useEffect(() => {
    !selectedList.length && setSelectedList(defaultValue);
  }, [defaultValue, selectedList]);

  useEffect(() => {
    action(selectedList);
  }, [action, selectedList]);

  const handleSelectValue = (list) => {
    return list?.length ? list?.join(", ")?.toLowerCase?.() : null;
  };

  const selectProps = {
    value: handleSelectValue(selectedList),
    defaultValue: defaultValue,
    className: "multi-selection",
    placeholder: placeholder || "Please select",
    dropdownRender: () => (
      <DropdownSelection
        listDataLoading={listDataLoading}
        selectedList={selectedList}
        listData={listData}
        initialValues={initialValues}
        setSelectedList={setSelectedList}
      />
    ),
  };

  return <Select {...selectProps} />;
};

MultiSelection.defaultProps = { action: () => {} };

MultiSelection.propTypes = {
  listData: PropTypes.array,
  defaultValue: PropTypes.array,
  placeholder: PropTypes.string,
  initialValues: PropTypes.array,
  action: PropTypes.func,
  listDataLoading: PropTypes.bool,
};

export default MultiSelection;
