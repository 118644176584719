import React from "react";
import { Button } from "antd";
import { triangleErrorGrey24x24 } from "assets/img";
import "Views/Home/Components/ExpenseFetchError/index.scss";

const ExpenseFetchError = ({
  onReload = () => {
    /* do nothing */
  },
}) => {
  return (
    <div className="expense-fetch-error">
      <div className="expense-fetch-error__info">
        <img src={triangleErrorGrey24x24} alt="error icon" />
        <p className="expense-fetch-error__title">An error occurred while loading this section. </p>
      </div>

      <Button onClick={onReload} className="expense-fetch-error__reload-btn">
        Reload
      </Button>
    </div>
  );
};

export default ExpenseFetchError;
