import React from "react";
import { Button, Typography, Switch, Tooltip } from "@spenmo/splice";

import { UpsellingBanner } from "Views/Upselling";
import RecurringDateSelector from "Modules/RecurringDate";
import { useIsSaasWithPaymentsOrg } from "customHooks/useIsSaasWithPaymentsOrg";

import { trackCRAnalytics } from "./useCRState";
import { IReimbursementSettingsPageContainer } from "./types";

import styles from "./styles.module.scss";

const BodyContent = ({ enabled }: { enabled: boolean }) => {
  return (
    <>
      <Typography variant="body-content" size="m" tag="h5" className={styles.label}>
        Settle reimbursements with Spenmo
      </Typography>
      <Typography variant="body-content" size="m" tag="p" className={styles.description}>
        {enabled
          ? "When enabled, approved reimbursements are automatically paid out as a batch on the settlement date. All users are required to submit their payout details on Spenmo."
          : "When disabled, admins need to manually mark approved reimbursements as settled after it has been paid out."}
      </Typography>
    </>
  );
};

const HeaderContent = () => {
  return (
    <>
      <Typography variant="headline-content" size="m" tag="h3">
        Reimbursements
      </Typography>
      <Typography variant="body-content" size="m" tag="p" className={styles.descriptiveText}>
        Set up payout configurations for submitted expenses by users in your organization
      </Typography>
    </>
  );
};

const UpsellingTooltip = ({
  isPaymentEnabled,
  children,
}: {
  isPaymentEnabled: boolean;
  children: React.ReactElement;
}) => {
  if (isPaymentEnabled) {
    return children;
  }

  return (
    <Tooltip
      title="This feature is available once your company is verified to enable payment feature with Spenmo. Get verified now"
      placement="bottom"
    >
      {children}
    </Tooltip>
  );
};

const ReimbursementSettingsPageContainer = ({
  isClean,
  isEnabled,
  settledAt,
  onChangeDate,
  submitLoading,
  handleSwitchToggle,
  handleSaveChanges,
}: IReimbursementSettingsPageContainer) => {
  const isPaymentEnabled = useIsSaasWithPaymentsOrg();

  return (
    <div className={styles.page}>
      <HeaderContent />
      {!isPaymentEnabled && <UpsellingBanner />}
      <div className={styles.container}>
        <div className={styles.topSection}>
          <div>
            <BodyContent enabled={isEnabled} />
            {isEnabled && (
              <RecurringDateSelector
                recurringDate={settledAt}
                onChangeDate={onChangeDate}
                onClickHandler={() => {
                  trackCRAnalytics({ eventSource: "Date Picker", eventSubSource: "Clicked" });
                }}
              />
            )}
          </div>
          <UpsellingTooltip isPaymentEnabled={isPaymentEnabled}>
            <Switch
              size="m"
              isActive={isEnabled}
              disabled={submitLoading || !isPaymentEnabled}
              onClick={handleSwitchToggle}
            />
          </UpsellingTooltip>
        </div>
        {isPaymentEnabled && (
          <>
            <div className={styles.divider} />
            <div className={styles.bottomSection}>
              <Button variant="primary" loading={submitLoading} disabled={isClean} onClick={handleSaveChanges}>
                Save Changes
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ReimbursementSettingsPageContainer;
