/* eslint-disable default-case */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect, useMemo } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Typography, Row, Col, Table, Tooltip } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { useMutableData } from "API/useData";

import OverlayLoader from "Modules/overlayLoaderV2";
import Button from "Modules/button";
import Icon from "Modules/Icon";
import ImgIcon from "Modules/icons";
import DropDown from "Modules/dropDown";
import LoadingIcon from "Modules/loading";
import Toaster from "Modules/DS/Toaster";
import {
  TOASTER_SIZE_TYPE,
  TOASTER_STATUS_TYPE,
} from "Modules/DS/Toaster/types.d";

import { currencyFormatterV2, getValuesFromObject } from "utility";
import { renderDate, renderEmail } from "utility/Bills";
import { trackEvent } from "utility/analytics";

import {
  uploadCompleted,
  uploadCompletedWithError,
  showItems,
  hideItems,
  newTab,
  newInfo,
} from "assets/img";
import { getOrgDetailFunc } from "Redux/Actions";
import { submitMultipleBill } from "Redux/DataCalls/Disbursement.api";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import AryadanaFooter, {
  ARYADANA_FOOTER_TYPE,
} from "Views/Bills/AryadanaFooter";
import { SPLIT_NAMES, SPLIT_TREATMENT_TYPES } from "Redux/splitio/constants";
import useCheckFeatureStatus from "customHooks/featureCheck";

import {
  ERROR_CODES,
  LEARN_MORE_URL,
  INSIGHT_COLUMN_KEY,
} from "Views/UploadMultiple/const";
import {
  PAYMENT_RUN_PATH,
  BILLS_PATH_UPLIFTING,
} from "constants/Routes.constants";

import styles from "./UploadCompleted.module.scss";
import "./UploadCompleted.scss";
// import { dateFormat } from "../../../utility";
import { GetBaseAuthObject, dateFormat } from "utility";
import RefreshFxModal from "Views/UploadInvoice/InvoiceForm/RefreshFxModal";
import {
  Tooltip as DSTooltip,
  TooltipArrowAlignmentEnum,
  TooltipPositionEnum,
} from "Modules/DS/Tooltip";
import Checkbox from "Modules/DS/Atoms/Checkbox";

import { API_URL } from "Views/Bills/V2/constants";

const UploadCompleted = ({
  fileName,
  apiResponse,
  onClick,
  dropDownAction,
  walletData,
  selectedTeam,
  selectedWallet,
  isBalanceHidden,
  onUpdateAPIResponse,
  showAryadana,
}) => {
  const dispatch = useDispatch();
  const isBillFeeRealtimeDeductionEnabled =
    useCheckFeatureStatus(SPLIT_NAMES.billFeeRealtimeDeduction) ===
    SPLIT_TREATMENT_TYPES.ON;

  const { data: getSetting, isLoading: isPaymentRunSettingLoading } =
    useMutableData([API_URL.getPaymentRunSetting]);
  const isPaymentRunOn = useMemo(() => {
    return Boolean(getSetting?.data?.payload?.setting);
  }, [getSetting]);

  const { Title, Paragraph } = Typography,
    history = useHistory();

  const [toaster, setToaster] = useState({
    visible: false,
    message: "",
    status: undefined,
  });

  const clearToaster = () =>
    setToaster({
      visible: false,
      message: "",
      status: undefined,
    });

  let errorCount = 0;
  const errorList = getValuesFromObject(ERROR_CODES);

  const getColumnName = (columnId) =>
    apiResponse.tableHeader !== null
      ? apiResponse.tableHeader.find((item) => item.key === columnId)?.title
      : "";

  const getErrorCode = (listIncorrectColumn, columnId) =>
    listIncorrectColumn.length &&
    listIncorrectColumn.find((item) => item.column === columnId)?.errorCode;

  const getErrorMessage = (listIncorrectColumn, columnId) =>
    listIncorrectColumn.length &&
    listIncorrectColumn.find((item) => item.column === columnId)?.errorMessage;

  useEffect(() => {
    dispatch(getOrgDetailFunc(GetBaseAuthObject().orgId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // TODO: get errorMessage from API response
  const renderCellTooltip = (errorCode, columnName) => {
    switch (errorCode) {
      case ERROR_CODES.EMPTY:
        return `${columnName} cannot be empty.`;
      case ERROR_CODES.AMOUNT_ZERO:
        return "Entered amount must be greater than 0.";
      case ERROR_CODES.DUPLICATE_WITH.OTHER_ROW_IN_FILE:
        return "Invoice number must be unique for each payment item to each Recipient Name. Check for typo or use a different invoice number.";
      case ERROR_CODES.DUPLICATE_WITH.EXISTING_DATA_IN_DB:
        return "This invoice number has been recorded in Spenmo with the same Recipient Name. Check for typo or use a different invoice number.";
      case ERROR_CODES.INVALID.SWIFT_CODE:
        return "Invalid SWIFT code.";
      case ERROR_CODES.INVALID.ACH_CODE:
        return "Invalid ACH code.";
      case ERROR_CODES.INVALID.PAYMENT_PURPOSE:
        return "Payment Purpose must be one of the 20 options provided in the template. E.g. Allowance, Business Expense, Donation.";
      case ERROR_CODES.INPUT_TOO_LONG:
        return `${columnName} cannot exceed 255 characters.`;
      case ERROR_CODES.UNSUPPORTED_CURRENCY_FOR_COUNTRY:
        return "This Currency is currently not supported by Spenmo for payments to selected Country.";
      case ERROR_CODES.UNSUPPORTED_COUNTRY_FOR_CURRENCY:
        return "This Country is currently not supported by Spenmo for payments in selected Currency.";
    }
  };

  const renderIncorrectData = (data, errorCode, columnName, errorMessage) => {
    // TODO: get errorMessage from API response and remove the latter if block
    if (errorMessage) {
      return (
        <Tooltip title={errorMessage} placement="left" arrowPointAtCenter>
          <span className={styles["incorrect-row-data-with-tooltip"]}>
            {data}
          </span>
        </Tooltip>
      );
    }

    if (errorList.includes(errorCode)) {
      return (
        <Tooltip
          title={renderCellTooltip(errorCode, columnName)}
          placement="left"
          arrowPointAtCenter
        >
          <span className={styles["incorrect-row-data-with-tooltip"]}>
            {data}
          </span>
        </Tooltip>
      );
    }
    return <span className={styles["incorrect-row-data"]}>{data}</span>;
  };

  const renderNumeric = (data, currency) =>
    currencyFormatterV2(data, currency, true, 2);

  const checkError = (listIncorrectColumn, key) =>
    listIncorrectColumn.length &&
    listIncorrectColumn.find((item) => item.column === key);

  const renderInsight = (data, isHaveInsight, insight) => {
    let insightIcon = (
      <Icon
        data-testid="success-icon"
        icon="check"
        size="24"
        className={styles.successIcon}
      />
    );
    if (isHaveInsight) {
      if (!insight) {
        return <>{data}</>;
      }

      insightIcon = (
        <DSTooltip
          data-testid="tooltip-icon"
          position={TooltipPositionEnum.LEFT}
          alignArrow={TooltipArrowAlignmentEnum.CENTER}
          text={insight.message}
        >
          <Icon
            data-testid="tooltip-icon"
            icon="suggestion"
            size={24}
            className={styles.suggestionIcon}
            svgAttr={{ viewBox: "0 0 24 24" }}
          />
        </DSTooltip>
      );
    }

    return (
      <span className={styles.insight}>
        {data}
        {insightIcon}
      </span>
    );
  };

  const hasInsight = (listInsightColumn) => listInsightColumn.length > 0;

  const checkInsight = (listInsightColumn, key) =>
    listInsightColumn.find((item) => item.column === key);

  const renderColumns = (data, record, tableHeader) => {
    if (data) {
      const isNoError = record.incorrectColumn.length === 0;
      // insightColumn can be NULL if there is incorrect column returned from the API.
      const isColumnHasInsight =
        INSIGHT_COLUMN_KEY.includes(tableHeader.key) && record.insightColumn;
      // only show insight when the row is error free
      if (isNoError && isColumnHasInsight) {
        const isAnyInsight = hasInsight(record.insightColumn);
        return renderInsight(
          data,
          isAnyInsight,
          checkInsight(record.insightColumn, tableHeader.key),
        );
      }

      if (tableHeader.type === "date") {
        const renderDateResult = renderDate(data);
        if (checkError(record.incorrectColumn, tableHeader.key)) {
          return renderIncorrectData(
            renderDateResult === "Invalid date" ? data : renderDateResult,
            getErrorCode(record.incorrectColumn, tableHeader.key),
            getColumnName(tableHeader.key),
            getErrorMessage(record.incorrectColumn, tableHeader.key),
          );
        }
        return renderDateResult;
      } else {
        if (checkError(record.incorrectColumn, tableHeader.key))
          return renderIncorrectData(
            data,
            getErrorCode(record.incorrectColumn, tableHeader.key),
            getColumnName(tableHeader.key),
            getErrorMessage(record.incorrectColumn, tableHeader.key),
          );

        switch (tableHeader.type) {
          case "country_numeric":
            return renderNumeric(data, record.currency);
          case "numeric":
            return renderNumeric(data, apiResponse.targetCurrency);
          case "email":
            return renderEmail(data);
        }
        return data;
      }
    } else {
      return checkError(record.incorrectColumn, tableHeader.key)
        ? renderIncorrectData(
            "-",
            getErrorCode(record.incorrectColumn, tableHeader.key),
            getColumnName(tableHeader.key),
            getErrorMessage(record.incorrectColumn, tableHeader.key),
          )
        : "-";
    }
  };

  const HEADER_TOOLTIP = {
    fxRate: `Best guaranteed rate as of ${dateFormat(Date.now())}`,
    serviceFee:
      "Transfer fees vary by amount, currency, receiving country and exchange rates. Final transfer fees will be shown and charged on your next billing cycle.",
  };

  const renderHeader = (tableHeader) => {
    return HEADER_TOOLTIP[tableHeader.key] ? (
      <Tooltip
        className="upload-complete__header-tooltip"
        title={HEADER_TOOLTIP[tableHeader.key]}
      >
        {tableHeader.title} <img src={newInfo} />
      </Tooltip>
    ) : (
      tableHeader.title
    );
  };

  const columns =
    apiResponse.tableHeader === null
      ? []
      : apiResponse.tableHeader.reduce((result, tableHeader) => {
          return [
            ...result,
            {
              title: renderHeader(tableHeader),
              dataIndex: tableHeader.key,
              key: tableHeader.key,
              className:
                styles[
                  tableHeader.key === "row"
                    ? "table-column-row"
                    : "table-column"
                ],
              render: (data, record) =>
                renderColumns(data, record, tableHeader),
            },
          ];
        }, []);

  const data =
    apiResponse.tableData === null
      ? []
      : apiResponse.tableData.reduce((result, tableData, index) => {
          let incorrectColumn = [];
          let errorCountEachRow = 0;

          if (tableData.error.length > 0) {
            incorrectColumn = tableData.error.reduce(
              (resultErrorReducer, errorData) => {
                const tempResult = [...resultErrorReducer];
                if (
                  !resultErrorReducer.find(
                    (item) => item.column === errorData.column,
                  )
                ) {
                  tempResult.push(errorData);
                  errorCountEachRow++;
                }
                return tempResult;
              },
              [],
            );
            errorCount++;
          }

          return [
            ...result,
            {
              key: index,
              row: index,
              incorrectColumn,
              errorCountEachRow,
              insightColumn: tableData.bankValidation,
              ...tableData.invoice,
            },
          ];
        }, []);

  const [filteredData, setFilteredData] = useState(data),
    [onlyShowError, setOnlyShowError] = useState(false),
    [isSubmit, setIsSubmit] = useState(false),
    [showFxModal, setShowFxModal] = useState(false),
    [isPayFull, setIsPayFull] = useState(false);

  const toggleIsPayFull = () => {
    setIsPayFull((prev) => !prev);
  };

  const setRowClassName = (record) =>
    record.incorrectColumn.length > 0
      ? styles["table-row-invalid"]
      : styles["table-row"];

  const handleShowErrorData = () => {
    if (!onlyShowError) {
      trackEvent("bill click see error items only");
      setOnlyShowError(true);
      const errorData = data.reduce((result, item) => {
        if (item.incorrectColumn.length > 0) result.push(item);
        return result;
      }, []);
      setFilteredData(errorData);
    } else {
      trackEvent("bill click see all items");
      reset();
    }
  };

  const onSubmit = async () => {
    trackEvent("bill click submit bulk");
    setIsSubmit(true);

    const response = await submitMultipleBill(
      selectedWallet,
      selectedTeam,
      isPayFull ? "OUR" : "SHA",
    );
    if (response.status === HTTP_STATUS_CODE.OK) {
      trackEvent("bill submit bulk success");
      history.push({
        pathname: isPaymentRunOn ? PAYMENT_RUN_PATH : BILLS_PATH_UPLIFTING,
        state: {
          data: {
            ...response.payload,
            isMultipleUpload: true,
            paymentSource: walletData.find(
              (item) => item.walletId === selectedWallet,
            ).name,
            hideCTA: true,
          },
        },
      });
    } else if (response.status === 400) {
      setShowFxModal(true);
      onUpdateAPIResponse(response.payload.data);
    } else {
      trackEvent("bill submit bulk failed");
      setToaster({
        visible: true,
        message:
          response.error?.message || "An error occurred. Please try again.",
        status: TOASTER_STATUS_TYPE.FAILED,
      });
    }

    setIsSubmit(false);
  };

  const reset = () => {
    setOnlyShowError(false);
    setFilteredData(data);
  };

  const onClickWithTracking = () => {
    trackEvent("bill click change file");
    onClick();
  };

  useEffect(() => {
    reset();
  }, [apiResponse]);

  const renderRowTooltip = (listIncorrectColumn) => {
    const incorrectColumnName = listIncorrectColumn.map((item) =>
      getColumnName(item.column),
    );
    return (
      <>
        <strong>{incorrectColumnName.join(", ")}</strong>
        {` require${listIncorrectColumn.length === 1 ? "s" : ""} changes.`}
      </>
    );
  };

  const renderErrorCountEachRow = (_, record) =>
    record.errorCountEachRow > 0 && (
      <Tooltip
        title={renderRowTooltip(record.incorrectColumn)}
        placement="left"
        arrowPointAtCenter
      >
        <div className={styles["error-count"]}>{record.errorCountEachRow}</div>
      </Tooltip>
    );

  if (errorCount > 0) {
    columns.unshift({
      title: "",
      key: "error-count",
      className: styles["table-column-error-count"],
      render: renderErrorCountEachRow,
    });
  }

  const uploadCorrectedFile = (
    <Button
      rounded={true}
      className={styles["change-file"]}
      action={onClickWithTracking}
      disabled={isSubmit}
    >
      Upload Corrected File
    </Button>
  );

  const changeFile = (
    <div
      className={styles["change-file"]}
      onClick={onClickWithTracking}
      disabled={isSubmit}
    >
      Change file
    </div>
  );

  const totalErrorFromAllRows =
    errorCount > 0
      ? data.reduce((result, item) => result + item.errorCountEachRow, 0)
      : 0;

  const submitButtonText = apiResponse.hasApprovalSystem
    ? "Submit for Approval"
    : "Submit for Payment";

  const feeDescNonSGDomestic = (
    <>
      {"Transfer fee of "}
      <strong className={styles["color-black"]}>
        {currencyFormatterV2(
          apiResponse.totalTransferFee || 0,
          apiResponse.targetCurrency,
          true,
          2,
        )}
      </strong>
      {` will be charged ${apiResponse?.hasFeeDeduction ? "at time of payment" : "on your next billing cycle"}. `}
    </>
  );

  return (
    <>
      <Row className={styles.container}>
        {isSubmit && (
          <OverlayLoader
            title="Submitting Invoice..."
            subtitle="Please do not leave this page."
          />
        )}
        <Col
          span={6}
          className={classNames(styles.col, styles.left, styles.flex)}
        >
          <div>
            <Title level={3} className={styles.title}>
              {errorCount > 0
                ? "Changes required before you can continue"
                : "File successfully uploaded"}
            </Title>
            <Icon
              src={errorCount > 0 ? uploadCompletedWithError : uploadCompleted}
              className={styles.icon}
            />
            <Title level={4} className={styles.subtitle}>
              {errorCount > 0
                ? "Some items in your uploaded file need to be changed. Check, change, and upload it again."
                : `You may now submit bill for ${apiResponse.hasApprovalSystem ? "approval" : "payment"}.`}
            </Title>
            <Paragraph className={styles["file-name"]}>{fileName}</Paragraph>
            {errorCount > 0 ? uploadCorrectedFile : changeFile}
          </div>
          {showAryadana && (
            <div className={styles.aryadana}>
              <AryadanaFooter type={ARYADANA_FOOTER_TYPE.THREE_LINES} />
            </div>
          )}
        </Col>
        <Col span={18} className={classNames(styles.col, styles.right)}>
          <div className={styles.overflow}>
            <div className={styles.header}>
              <Title level={3} className={styles.title}>
                File Preview
              </Title>
              <div className={styles["pay-from"]}>
                <Paragraph>Budget</Paragraph>
                <DropDown
                  isWallet={true}
                  showAmount={!isBalanceHidden}
                  showCurrency={true}
                  listData={walletData}
                  action={dropDownAction}
                  initialValue={selectedTeam}
                  className={styles.ddl}
                />
                {isBalanceHidden && (
                  <div className={styles["wallet-disabled-alert"]}>
                    Payment will be drawn from the spending limit of this
                    budget.
                  </div>
                )}
              </div>
              <Paragraph className={styles["total-amount"]}>
                <strong className={styles["color-black"]}>
                  {apiResponse.totalInvoice}
                </strong>
                {` payment item${apiResponse.totalInvoice > 1 ? "s" : ""} amounting to `}
                <strong className={styles["color-black"]}>
                  {currencyFormatterV2(
                    apiResponse.totalAmount,
                    apiResponse.targetCurrency,
                    true,
                    2,
                  )}
                </strong>
                {` to be paid.`}
              </Paragraph>
              {isBillFeeRealtimeDeductionEnabled &&
                apiResponse.hasFeeDeduction && (
                  <>
                    <Paragraph className={styles["total-fee"]}>
                      {feeDescNonSGDomestic}
                      <a
                        href={LEARN_MORE_URL}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Learn more{" "}
                        <img
                          src={newTab}
                          alt="Learn more"
                          width={16}
                          height={16}
                        />
                      </a>
                      {isPayFull && (
                        <p data-testid="payfull-info">
                          Total amount you have to pay is{" "}
                          <b>
                            {currencyFormatterV2(
                              apiResponse.totalSwiftPaymentChargeFee +
                                apiResponse.totalAmount,
                              apiResponse.targetCurrency,
                              true,
                              2,
                            )}
                          </b>
                        </p>
                      )}
                    </Paragraph>
                    {Boolean(apiResponse.totalSwiftPaymentChargeFee) && (
                      <div className={styles.payFull}>
                        <div>
                          <Checkbox
                            checked={isPayFull}
                            onClick={toggleIsPayFull}
                            id="payfull-checkbox"
                          />
                        </div>
                        <div>
                          <p>
                            Pay Full Amount Guarantee of{" "}
                            <b>
                              {currencyFormatterV2(
                                apiResponse.totalSwiftPaymentChargeFee,
                                apiResponse.targetCurrency,
                                true,
                                2,
                              )}
                            </b>{" "}
                            to ensure recipient gets exact amount
                          </p>
                          {apiResponse.swiftPaymentBillCount > 1 ? (
                            <p>
                              You have {apiResponse.swiftPaymentBillCount}{" "}
                              global USD transactions which might be subject to
                              fees by recipient bank
                            </p>
                          ) : (
                            <p>
                              You have a global USD transactions which might be
                              subject to fees by recipient bank
                            </p>
                          )}
                        </div>
                      </div>
                    )}
                  </>
                )}
              {errorCount > 0 && (
                <Paragraph
                  className={classNames(styles.subtitle, styles["error-notif"])}
                >
                  There are
                  <span
                    className={classNames(styles["error-count"], {
                      [styles["smaller-font"]]: totalErrorFromAllRows > 99,
                    })}
                  >
                    {totalErrorFromAllRows > 99 ? "99+" : totalErrorFromAllRows}
                  </span>
                  field{apiResponse.totalInvoice > 1 ? "s" : ""} that require
                  changes.
                  <span
                    className={styles["error-warning"]}
                    onClick={handleShowErrorData}
                  >
                    {onlyShowError ? (
                      <>
                        <ImgIcon src={hideItems} className={styles.icon} />
                        Show all items
                      </>
                    ) : (
                      <>
                        <ImgIcon src={showItems} className={styles.icon} />
                        Show payments that require changes
                      </>
                    )}
                  </span>
                </Paragraph>
              )}
              {errorCount > 0 && (
                <Paragraph
                  className={classNames(styles.subtitle, styles["show-info"])}
                >
                  {`Showing `}
                  <strong className={styles["color-black"]}>
                    {onlyShowError ? errorCount : apiResponse.totalInvoice}
                  </strong>
                  {` of `}
                  <strong className={styles["color-black"]}>
                    {apiResponse.totalInvoice}
                  </strong>
                  {` payment item${apiResponse.totalInvoice > 1 ? "s" : ""}`}
                </Paragraph>
              )}
            </div>
            <div className={styles.body}>
              {filteredData !== null && (
                <Table
                  rowClassName={setRowClassName}
                  columns={columns}
                  dataSource={filteredData}
                />
              )}
            </div>
            {errorCount === 0 && !isPaymentRunSettingLoading && (
              <Button
                rounded={true}
                className={styles["approval-button"]}
                action={onSubmit}
                disabled={isSubmit}
              >
                {isSubmit ? (
                  <LoadingIcon size="s" />
                ) : (
                  <>
                    {submitButtonText} <ArrowRightOutlined />
                  </>
                )}
              </Button>
            )}
          </div>
        </Col>
      </Row>

      <RefreshFxModal visible={showFxModal} setVisible={setShowFxModal} />
      <Toaster size={TOASTER_SIZE_TYPE.M} onClose={clearToaster} {...toaster} />
    </>
  );
};

UploadCompleted.propTypes = {
  showAryadana: PropTypes.bool,
  isBalanceHidden: PropTypes.bool,
  fileName: PropTypes.string,
  selectedTeam: PropTypes.string,
  selectedWallet: PropTypes.string,
  onClick: PropTypes.func,
  apiResponse: PropTypes.object,
  dropDownAction: PropTypes.func,
  walletData: PropTypes.arrayOf(PropTypes.object),
};

UploadCompleted.defaultProps = {
  fileName: "",
  onClick: () => {},
  apiResponse: {},
  dropDownAction: () => {},
  walletData: [],
  selectedTeam: "",
  selectedWallet: "",
  isBalanceHidden: false,
  showAryadana: false,
};

export default UploadCompleted;
