import React from "react";
import {
  currencyFormatterV2,
  intlDateTimeFormat,
  intlTimeFormat,
  dateTimeWithTimezone,
  currencyFormatter,
  GetCurrencyCode,
} from "utility";
import { useQuery } from "utility/useQuery";
import { GetReceiptDataAxios } from "Redux/DataCalls/Receipts.api";
import { TRANSACTION_TYPE } from "constants/Transaction.constant";
import { IDetail, ITransactionHeaderProps, EInputType } from "Modules/TransactionDetail/";
import { defaultInput } from "Modules/TransactionDetail/TransactionDetail";
import { useTransactionTags } from "Modules/AccountingTags/hooks/useTransactionTags";
import { ITransaction } from "../type";
import { FIND_TRANSACTION_TYPE_INSIDE_BRACKET } from "../constant/constant";
import { useInitialTags } from "../context/InitialTagContext";

import styles from "../styles.module.scss";

type CleanExpense = ITransaction | any;
export interface Option {
  useLegacyRefund: boolean;
  notRequiredApiType: boolean;
  relatedTransaction: ITransaction | null;
  onClickRelatedTransaction: () => void;
  onSuccess: (data: any) => void;
  onError: (data: any) => void;
}

const cleanUpExpense: (expense: any, record: any, notRequiredApiType: boolean) => CleanExpense = (
  expense,
  record,
  notRequiredApiType
) => {
  if (!expense || notRequiredApiType) {
    return record;
  }

  return {
    ...expense,
    amount: currencyFormatterV2(expense.amount || 0, expense.currency_code || "SGD"),
  };
};

export const childrenTransactionType = (relatedTransaction) => {
  return [TRANSACTION_TYPE.REFUND, TRANSACTION_TYPE.FEES_REFUND].includes(relatedTransaction?.transactionType)
    ? TRANSACTION_TYPE.REFUND.toLowerCase()
    : TRANSACTION_TYPE.REVERSAL.toLowerCase();
};

const shouldCallTransactionTagsApi = (useLegacyRefund: boolean, value: string | boolean) =>
  useLegacyRefund ? value : null;

const findMerchantName = (cleanExpense: any) => {
  return cleanExpense?.simplified_merchant_name || cleanExpense?.merchant;
};

export const useTransactionDetail = (record: ITransaction, visible: boolean, option?: Option) => {
  // This initial tags context will only be used to edit synced old refund transaction,
  // since we have to compare between old transaction tags and updated  tags in order to show warning to user or not
  const { setInitialTags } = useInitialTags();
  const { useLegacyRefund, notRequiredApiType, onClickRelatedTransaction, relatedTransaction, onSuccess, onError } =
    option;
  const {
    data: { expense },
    setData,
    loading,
    refetch,
  } = useQuery({
    apiCall: () => GetReceiptDataAxios(record.transactionNumber, record.id),
    run: visible && !notRequiredApiType,
    onSuccess: onSuccess,
    onError: onError,
  });
  const [tags] = useTransactionTags(
    shouldCallTransactionTagsApi(useLegacyRefund, record.id),
    shouldCallTransactionTagsApi(useLegacyRefund, visible),
    setInitialTags
  );
  const isParentTransaction = [TRANSACTION_TYPE.REFUND, TRANSACTION_TYPE.REVERSAL].includes(
    (relatedTransaction?.transactionType?.match(FIND_TRANSACTION_TYPE_INSIDE_BRACKET) || [])[1]
  );
  const cleanExpense = cleanUpExpense(expense, record, notRequiredApiType);
  const merchantName = findMerchantName(cleanExpense);

  const safeCall = (callback, defaultValue) => {
    if (!notRequiredApiType && !expense) {
      return () => defaultValue;
    }
    return callback;
  };

  const header: () => ITransactionHeaderProps = safeCall(
    () => ({
      amount: cleanExpense.amount,
      name: merchantName,
    }),
    {}
  );

  const showForeignCurrencyInformation =
    record?.transactionType !== TRANSACTION_TYPE.INCENTIVE && GetCurrencyCode() !== cleanExpense?.foreign_currency_code;

  const detail: () => IDetail[] = safeCall(
    () =>
      [
        {
          id: "DETAIL_001",
          key: "Transaction Number",
          enabled: true,
          value: (
            <div>
              <p>{record.transactionNumber}</p>
              {Boolean(relatedTransaction) && (
                <p
                  className={styles["related-transaction"]}
                  onClick={() => {
                    refetch(() => GetReceiptDataAxios(relatedTransaction.transactionNumber, relatedTransaction.id));
                    onClickRelatedTransaction();
                  }}
                >
                  View {isParentTransaction ? childrenTransactionType(relatedTransaction) : "original"} transaction
                </p>
              )}
            </div>
          ),
        },
        {
          id: "DETAIL_002",
          key: "Created Date & Time",
          enabled: true,
          value: record.createdAt
            ? `${intlDateTimeFormat(new Date(dateTimeWithTimezone(record.createdAt)))}, ${intlTimeFormat(
                new Date(dateTimeWithTimezone(record.createdAt))
              )}`
            : "",
        },
        {
          id: "DETAIL_003",
          key: "Reference",
          enabled: true,
          value: cleanExpense?.merchant || "N.A.",
        },
        {
          id: "DETAIL_004",
          key: "Transaction Type",
          enabled: true,
          value: record.transactionType,
        },
        {
          id: "DETAIL_005",
          key: "Card no",
          enabled: Boolean(cleanExpense?.card_last_four),
          value: cleanExpense?.card_last_four,
        },
        {
          id: "DETAIL_006",
          key: "Foreign Currency Code",
          value: cleanExpense?.foreign_currency_code,
          enabled: showForeignCurrencyInformation,
        },
        {
          id: "DETAIL_007",
          key: "Foreign Currency Amount",
          value: currencyFormatter(cleanExpense.foreign_currency_amount, cleanExpense.foreign_currency_code, true),
          // || record.fxRate check for reversal,
          enabled: showForeignCurrencyInformation,
        },
      ].filter((detail) => detail.enabled),
    []
  );

  const defaultValue = safeCall(() => {
    return {
      [EInputType.CATEGORY]: cleanExpense.expense_category_id,
      [EInputType.TRANSACTION_TAGS]: tags.length !== 0 ? tags : cleanExpense.tags,
      [EInputType.NOTES]: cleanExpense.comment,
      [EInputType.TAX]: cleanExpense?.tax?.id,
      [EInputType.MERCHANT]: merchantName,
      [EInputType.RECEIPT]: !cleanExpense?.receipts ? [] : cleanExpense.receipts.map((item) => item?.url),
    };
  }, defaultInput);

  return { header, detail, loading, defaultValue, cleanExpense, trxnDetails: expense, setTrxnDetails: setData };
};
