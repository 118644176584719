import React, { useState } from "react";

import { ITrxnToastProps } from "Views/Transaction/@types";
import { initialTrxnToast, TrxnToastContext } from "Views/Transaction/Context";

export const TrxnToastProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const [toaster, setToaster] = useState<ITrxnToastProps>(initialTrxnToast.toaster);

  const toast = { toaster, setToaster };

  return <TrxnToastContext.Provider value={toast}>{children}</TrxnToastContext.Provider>;
};
