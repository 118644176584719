import * as React from "react";
import { IBadgeProps } from "./types";
import styles from "./index.module.scss";
import classNames from "classnames";

const Badge = ({ count, isIndicator }: IBadgeProps) => {
  const MAX_COUNT = 99;
  const _badgeClassNames = classNames({
    [styles.dot]: isIndicator,
    [styles.badge]: count,
  });

  const setCount = (count) => {
    if (parseInt(count) > MAX_COUNT) {
      return "99+";
    }
    return count;
  };

  if (isIndicator || count) {
    return (
      <div data-testid="badge" className={_badgeClassNames}>
        {!isIndicator && <p>{setCount(count)}</p>}
      </div>
    );
  }

  return null;
};

export default Badge;
