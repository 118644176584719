import React, { useEffect } from "react";

import {
  IPageWithTable,
  IGetTrxnRequest,
  ITrxnsErrorData,
  ITrxnsSuccessData,
  ITrxnTab,
  IGetTrxnResponse,
} from "Views/Transaction/@types";
import { TABS } from "Views/Transaction/Constants";
import { treatParams } from "Views/Transaction/Helper";
import { TrxnFilterProvider } from "Views/Transaction/Provider";
import { initialGetTrxnRequest, initialTrxnsError, initialTrxnsSuccess } from "Views/Transaction/DataCalls";
import { trackEvent } from "utility/analytics";
import { TRANSACTION_ANALYTICS, TRANSACTION_TABS } from "Views/Transaction/Analytics";

import { AllTrxnList } from "./List";
import { useListFetch } from "./useFetch";
import { MORE_FILTER_KEYS } from "./Actions/Filter";

const AllTrxn = (tab: Omit<ITrxnTab, "content" | "enabled">): JSX.Element => {
  const { error, loading, data, fetchData } = useListFetch();

  const errorMessage: string =
    error && (data as ITrxnsErrorData).error ? (data as ITrxnsErrorData).error : initialTrxnsError.empty.error;

  const total: number = error ? initialTrxnsSuccess.mocked.count : (data as ITrxnsSuccessData).count;

  const listData = error ? initialTrxnsSuccess.mocked.data : (data as ITrxnsSuccessData).data;

  const props: IPageWithTable<IGetTrxnResponse> = {
    total,
    error,
    loading,
    errorMessage,
    data: listData,
  };

  useEffect(() => {
    trackEvent(TRANSACTION_ANALYTICS.TAB_VIEWED, { transaction_tab_event_source: TRANSACTION_TABS.ALL });
  }, []);

  const filters = treatParams<IGetTrxnRequest>(initialGetTrxnRequest());
  if (![TABS.SETTLED, TABS.ALL].includes(tab.title as TABS)) return null;

  return (
    <TrxnFilterProvider filters={filters} onApply={fetchData} moreFilters={MORE_FILTER_KEYS}>
      <AllTrxnList {...props} />
    </TrxnFilterProvider>
  );
};

export default AllTrxn;
