import React from "react";
import { IRouterType, PAGE_GRID_COLUMNS, PAGE_GRID_CONTENT_TYPE } from "Route/@types";
import { SUBSCRIPTION_PATH } from "Route/Subscription/path";

import SubscriptionManagement from "Views/SubscriptionManagement";
import AutoReconciledTransactions from "Views/SubscriptionManagement/AutoReconciled";
import SubscriptionDetails from "Views/SubscriptionManagement/Details";

export const SubscriptionRoute: IRouterType[] = [
  {
    id: "subscription-1.1",
    path: SUBSCRIPTION_PATH.SUBSCRIPTON_DETAILS,
    component: <SubscriptionDetails />,
    title: "Subscription details page",
    authRequired: true,
    exactPath: false,
    grid: {
      columns: PAGE_GRID_COLUMNS.NONE,
      content: PAGE_GRID_CONTENT_TYPE.FLUID,
    },
  },
  {
    id: "subscription-1.2",
    path: SUBSCRIPTION_PATH.SUBSCRIPTION_AUTO_RECONCILED_TRXNS,
    component: <AutoReconciledTransactions />,
    title: "Subscription Auto Reconciled Transaction listing",
    authRequired: true,
    exactPath: false,
    grid: {
      columns: PAGE_GRID_COLUMNS.SINGLE,
      content: PAGE_GRID_CONTENT_TYPE.FLUID,
    },
  },
  {
    id: "subscription-1.3",
    path: SUBSCRIPTION_PATH.SUBSCRIPTION_MANAGEMENT,
    component: <SubscriptionManagement />,
    title: "Subscription main page",
    authRequired: true,
    exactPath: false,
    grid: {
      columns: PAGE_GRID_COLUMNS.SINGLE,
      content: PAGE_GRID_CONTENT_TYPE.FLUID,
    },
  },
];
