import React from "react";

import { useAppSelector } from "hook";

import { RootState } from "Redux/ConfigureStore";

import { Quick } from "Views/Transaction/Filters";
import { ALL_TRXNS_COLUMNS } from "Views/Transaction/Constants";
import { GET_TRXN_RESPONSE_PARAMS, ITeamData } from "Views/Transaction/@types";

export const TeamFilter = ({ id }: { id: string }) => {
  const loading: boolean = useAppSelector((state: RootState) => state.teamListByUserIdReducer?.loading);
  const teamListData: ITeamData[] = useAppSelector((state: RootState) => state.teamListByUserIdReducer?.data);

  return (
    <Quick.Multiple<ITeamData>
      id={id}
      searchable
      defaultValue={ALL_TRXNS_COLUMNS.TEAM}
      loading={loading}
      displayKey={GET_TRXN_RESPONSE_PARAMS.TEAM_NAME}
    >
      {teamListData}
    </Quick.Multiple>
  );
};
