import moment from "moment";
import { otherCustomFilterType } from "./@types";
import { currencyFormatterV2 as currencyFormatter } from "utility";
import { getCookieValue, cookieNames } from "utility/CookieHelper";

export const numberFormatter = (num) => {
  const currency = getCookieValue(cookieNames.CURRENCY_CODE);
  return currencyFormatter(num, currency, false, 0);
};

export const SGDCurrencyFormatter = (num, decimal = 2, style = "currency") => {
  const currency = getCookieValue(cookieNames.CURRENCY_CODE);
  return currencyFormatter(num, currency, true, 2);
};

export const dateFormatter1 = (
  date // 1 Sep
) =>
  new Intl.DateTimeFormat("en-US", {
    day: "numeric",
    month: "short",
    timeZone: "Asia/Singapore",
  })
    .formatToParts(date)
    .reverse()
    .reduce((acc, cv) => acc + cv.value, "");

export const dateFormatter2 = (
  date // 1 Sep 2022
) =>
  new Intl.DateTimeFormat("en-GB", {
    day: "numeric",
    month: "short",
    year: "numeric",
    timeZone: "Asia/Singapore",
  }).format(date);

export const dateFormatter3 = (date) => {
  // 01 Sep 2022
  const arr = new Intl.DateTimeFormat("en-GB", {
    day: "numeric",
    month: "short",
    year: "numeric",
    timeZone: "Asia/Singapore",
  }).formatToParts(date);

  const day = arr.shift().value.padStart(2, "0");
  return day + arr.reduce((acc, cv) => acc + cv.value, "");
};

export const convertToPercentage = (num) =>
  new Intl.NumberFormat("en-US", {
    style: "percent",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(num);

export const camelCaseToTitleCase = (text) => {
  const result = text.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
};

export function removeEmpty(obj: any) {
  const cloned = { ...obj };

  Object.keys(cloned).forEach((k) => {
    if (Array.isArray(cloned[k]) && cloned[k].length === 0) delete cloned[k];
    if (cloned[k] === "" || cloned[k] === null || cloned[k] === 0 || cloned[k] === undefined) delete cloned[k];
  });

  return cloned;
}

export function dateFormatter4(startDate: string, endDate: string) {
  if (!startDate || !endDate) return "";

  const startDateYear = moment(startDate).year();
  const endDateYear = moment(endDate).year();

  if (startDateYear === endDateYear)
    return `${moment(startDate).format("DD MMM")} - ${moment(endDate).utc().startOf("d").format("DD MMM YYYY")}`;

  return `${moment(startDate).format("DD MMM YYYY")} - ${moment(endDate).utc().startOf("d").format("DD MMM YYYY")}`;
}

export function calculateDate(
  date: string,
  differences: { days: number; months: number },
  otherCustomFilter?: otherCustomFilterType,
  isEnd?: boolean
) {
  if (!date) return "";

  if (otherCustomFilter?.isCustom === true) {
    if (isEnd === true) {
      if (otherCustomFilter.type === "quarters") {
        return moment(date).subtract(1, "quarter").endOf("quarter").utc().toISOString();
      } else if (otherCustomFilter.type === "years") {
        return moment(date).subtract(1, "year").endOf("year").utc().toISOString();
      } else if (otherCustomFilter.type === "months") {
        return moment(date).subtract(1, "month").endOf("month").utc().toISOString();
      }
      return moment(date).subtract(1, otherCustomFilter.type).utc().toISOString();
    }
    return moment(date).subtract(1, otherCustomFilter.type).utc().toISOString();
  }

  if (differences.months > 0) {
    return moment(date).subtract(differences.months, "months").utc().toISOString();
  }

  return moment(date).subtract(differences, "days").utc().toISOString();
}

export function checkChangePercentage(percentage: string) {
  if (!percentage) return "zero";
  const number = Number(percentage.replace("%", ""));
  if (number === 0) return "zero";
  if (number < 0) return "negative";
  return "positive";
}

export const getCurrentAndPastSpendingText = (otherCustomFilter: otherCustomFilterType, isPast: boolean): string => {
  if (Boolean(otherCustomFilter.isCustom) === false) return isPast === true ? "Past Spending" : "Current Spending";
  switch (otherCustomFilter.type) {
    case "quarters":
      return isPast === true ? "Past Quarter Spending" : "Quarter to Date Spending";
    case "years":
      return isPast === true ? "Past Year Spending" : "Year to Date Spending";
    case "months":
      return isPast === true ? "Past Monthly Spending" : "Current Monthly Spending";
  }
};

export const getCurrentDate = () => {
  return moment().utc().endOf("days").toISOString();
};
