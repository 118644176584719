import { GetOrgId, GetUserId } from "utility";

const SETTINGS_API_URL = {
  REIMBURSEMENT: "/ms/spm-disbursement/v1/reimbursement/settings/settlement",
  PREVIEW_REIMBURSEMENT_SETTLEMENT: "/ms/spm-disbursement/v1/reimbursement/settings/settlement/preview",
};

const DETAILS_API_URL = {
  REIMBURSEMENT: (id: string) => `/api/v1/fund/reimbursement/${id}?organisation_id=${GetOrgId()}&with_status_journey=1`,
  /**  @deprecating this api usage, need to talk with gde */
  MARK_AS_SETTLED: (id: string) => `/ms/spm-disbursement/v1/reimbursement/${id}/settlement-non-payout`,
  BULK_MARK_AS_SETTLED: `/ms/spm-disbursement/v1/reimbursement/bulk/settlement-non-payout`,
  SAVE_CHANGES: (id: string) => `/api/v1/fund/request/reimbursement/${id}`,
  DELETE_REQUEST: (id: string) => `/api/v1/fund/request/delete/${id}`,
  MAKE_PAYMENT: (id: string) => `/ms/spm-disbursement/v1/reimbursement/${id}/settlement-non-payout`, // using mark as settled api as placeholder for make payment ,will be replaced by actual make payment api in the next sprint
};

const LIST_API_URL = {
  REIMBURSEMENTS: "/api/v1/fund/request/list",
  EXPORT_FULL_CSV: "/api/v1/fund/request/list/csv",
  GENERIC_PAYMENT_CSV: "/api/v1/fund/request/reimbursement/payment-csv",
  BANK_ACCOUNT_INFO: () => `/api/v1/org/${GetOrgId()}/bank_account/?user_id=${GetUserId()}&limit=1&pg=0`,
};

const FILTER_API_URL = {
  MERCHANT: "/api/v1/fund/request/reimbursement/merchants/search",
};

export const API_URL = {
  CONFIG: "/ms/spm-disbursement/v1/config/page/reimbursement",
  LIST: LIST_API_URL,
  DETAILS: DETAILS_API_URL,
  SETTINGS: SETTINGS_API_URL,
  FILTER: FILTER_API_URL,
};
