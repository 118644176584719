import useSWR from "swr";
import { getAccountingPartnerList } from "Redux/DataCalls/AccountingSetting.api";
import { useNetsuiteIntegration } from "./useNetsuiteIntegration";

const getPartnerList = async ({ args }) => {
  const { connectedToAccountingPartner, accountingPartnerdata } = args;
  const { partnerId, validToken, completedIntegration } = accountingPartnerdata?.payload || {};
  const { data } = await getAccountingPartnerList();

  return data.payload.partners.filter((accountingPartner) => {
    // Hide Other partner list when current integration is partially finished
    if (accountingPartner.id !== partnerId && validToken && !completedIntegration) {
      return false;
    }
    // Hide Other partner list when current integration is  finished
    if (connectedToAccountingPartner && partnerId !== accountingPartner.id) {
      return false;
    }

    return true;
  });
};

export const usePartnerList = ({ connectedToAccountingPartner, accountingPartnerdata }) => {
  const { integrationEnabled, loading: netsuiteLoading } = useNetsuiteIntegration();

  const { data = [], isLoading } = useSWR(
    netsuiteLoading
      ? null
      : {
          url: "integration-partner",
          args: { connectedToAccountingPartner, accountingPartnerdata },
        },
    getPartnerList
  );

  return {
    netsuiteEnabled: integrationEnabled,
    partnerListData: data,
    partnerListLoading: isLoading || netsuiteLoading,
  };
};
