import { Button } from "antd";
import { RuleObject } from "antd/lib/form";
import { StoreValue } from "antd/lib/form/interface";
import { BANK_NAME_FIELD_ID, BIC_TYPE_KEY, ERROR_BIC_COUNTRY_MISMATCH } from "Modules/DynamicForm/constants";
import { DynamicFormContext } from "Modules/DynamicForm/store";
import { DynamicFormFieldProps } from "Modules/DynamicForm/types";
import React, { useContext } from "react";
import style from "../../Textfield/Textfield.module.scss";
import BankValidation from "../BankValidation";

const DynamicFormSwiftCode = (props: DynamicFormFieldProps) => {
  const { fields, isNewDataFields, setIsNewDataFields, destinationCountry } = useContext(DynamicFormContext);
  const bankNameField = fields[BANK_NAME_FIELD_ID];

  const handleAddNewBank = () => {
    setIsNewDataFields((prev) => ({ ...prev, [BANK_NAME_FIELD_ID]: true }));
  };

  const validator = (_: RuleObject, value: StoreValue): Promise<void> => {
    const countryCode = value.substr(4, 2);
    if (countryCode && countryCode.toLowerCase() !== destinationCountry.toLowerCase())
      return Promise.reject(new Error(ERROR_BIC_COUNTRY_MISMATCH.replace(BIC_TYPE_KEY, props.label)));

    return Promise.resolve();
  };

  return (
    <BankValidation
      {...props}
      validator={validator}
      extra={
        bankNameField?.fieldProps.isAllowToAddData && !isNewDataFields[BANK_NAME_FIELD_ID] ? (
          <div className={style.extra}>
            <span>Different bank and SWIFT code?</span>
            <Button type="link" onClick={handleAddNewBank}>
              + Add new bank
            </Button>
          </div>
        ) : null
      }
    />
  );
};

export default DynamicFormSwiftCode;
