import APIclient from "API/Client";
import { GetBaseAuthObject } from "utility";

export const GET_ALL_TEAMS_WALLET_REQUEST = "GET_ALL_TEAMS_WALLET_REQUEST";
export const GET_ALL_TEAMS_WALLET_SUCCESS = "GET_ALL_TEAMS_WALLET_SUCCESS";
export const GET_ALL_TEAMS_WALLET_FAILURE = "GET_ALL_TEAMS_WALLET_FAILURE";

export const getAllTeamsWalletRequest = (data) => {
  return { type: GET_ALL_TEAMS_WALLET_REQUEST, data: data };
};

export const getAllTeamsWalletSuccess = (data) => {
  return { type: GET_ALL_TEAMS_WALLET_SUCCESS, data: { payload: data } };
};

export const getAllTeamsWalletError = (data) => {
  return { type: GET_ALL_TEAMS_WALLET_FAILURE, data: data };
};

export const getAllTeamsWallet = (form_type = "bill") => {
  return (dispatch) => {
    dispatch(getAllTeamsWalletRequest());
    APIclient.getData(`/api/v1/org/${GetBaseAuthObject().orgId}/team-wallet?form_type=${form_type}`)
      .then((data) => {
        if (data.data.status > 200) {
          dispatch(getAllTeamsWalletError(data.data));
        } else {
          dispatch(getAllTeamsWalletSuccess(data.data.payload));
        }
      })
      .catch((err) => {
        dispatch(getAllTeamsWalletError(err));
      });
  };
};
