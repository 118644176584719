import React from "react";
import classNames from "classnames";
import { Typography } from "@spenmo/splice";
import { useLocation, useRouteMatch } from "react-router-dom";

import DataTable, {
  Table as DSTable,
  ITableProps,
  DATA_TABLE_PADDING,
  DATA_TABLE_SIZE_TYPES,
} from "Modules/DS/DataTable";

import ErrorState from "Views/Reimbursement/Error";
import ExpenseReport from "Views/Reimbursement/ExpenseReport";
import useFetchList from "Views/Reimbursement/hooks/useFetchList";
import { ILocationParams, IReimbursementRequestData } from "Views/Reimbursement/@types";
import { PAGE_TABS, REIMBURSEMENT_PAGE_OPERATIONS, REIMBURSEMENT_VIEWS } from "Views/Reimbursement/Constants";

import Actions from "Views/Reimbursement/List/Actions";
import EmptyState from "Views/Reimbursement/List/Empty";
import useColumns from "Views/Reimbursement/List/Columns";
import usePageProps from "Views/Reimbursement/List/Pagination";
import useRowSelection from "Views/Reimbursement/List/useRowSelection";
import Skeleton, { TableSkeleton } from "Views/Reimbursement/List/Skeleton";

import styles from "./index.module.scss";

export const Description = () => {
  const location = useLocation();

  const description = PAGE_TABS.find((tab) => location.pathname.includes(tab.tabKey))?.description || "";

  return (
    description && (
      <Typography size="m" variant="body-content" tag="p" className={styles.description}>
        {description}
      </Typography>
    )
  );
};

const Table = ({ loading = false }: { loading?: boolean }) => {
  const { data, totalCount, showEmptyState, showSkeleton, showErrorState } = useFetchList();

  const pageProps = usePageProps(totalCount, showSkeleton || loading);
  const selectedRows = useRowSelection();
  const columns = useColumns();

  const table: ITableProps<IReimbursementRequestData> = {
    dataSource: data,
    total: totalCount,
    className: styles.table,
    ...pageProps,
    ...selectedRows,
  };

  const renderSkeleton = () => {
    return showSkeleton ? <TableSkeleton /> : <DSTable<IReimbursementRequestData> {...table}>{columns}</DSTable>;
  };

  const renderTable = () => {
    return showEmptyState ? <EmptyState /> : renderSkeleton();
  };

  return showErrorState ? <ErrorState /> : renderTable();
};

const List = ({ loading = false }: { loading?: boolean }) => {
  const location = useLocation();

  const { params } = useRouteMatch<ILocationParams>();
  const { operation = "" } = params;

  const isPayoutsView = operation === REIMBURSEMENT_PAGE_OPERATIONS.PAYOUTS;

  const isPersonalTab = location.pathname.includes(REIMBURSEMENT_VIEWS.PERSONAL_REIMBURSEMENTS.link);

  const selectedRows = useRowSelection();

  const checkForBulkAction = Boolean(selectedRows?.rowSelection) && Boolean(selectedRows?.bulkAction);

  const containerClass = classNames(styles.container, {
    [styles.bulk]: checkForBulkAction && !isPersonalTab,
    [styles.default]: !checkForBulkAction && !isPersonalTab,
    [styles.personalTab]: !checkForBulkAction && isPersonalTab,
  });

  return loading ? (
    <Skeleton />
  ) : (
    <DataTable className={containerClass} size={DATA_TABLE_SIZE_TYPES.SMALL} padding={DATA_TABLE_PADDING.NONE}>
      <Description />
      <Actions />
      {isPayoutsView ? <ExpenseReport /> : <Table loading={loading} />}
    </DataTable>
  );
};

export default List;
