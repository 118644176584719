export enum KYX_BANNER_MESSAGE_TYPE {
  TEXT = "TEXT",
  LIST = "LIST",
  TITLE = "TITLE",
}

// ELEMENTS TYPES
export enum FORM_FIELD_TYPES {
  INPUT = "input",
  DATEPICKER = "datepicker",
  HEADER = "header",
  RADIO = "radio",
  CHECKLIST = "checklist",
  COUNTRYSELECT = "countrySelect",
  FILEUPLOAD = "fileupload",
  DUALUPLOAD = "dualupload",
  BANNER = "banner",
  SELECT = "select",
  ICONBUTTON = "iconButton",
  SINGPASS = "singpass",
  DOWNLOAD_LINK = "downloadLink",
}
