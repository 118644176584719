import * as React from "react";
import { Divider } from "antd";
import { useCardDetailsEditContext, ICardEditFieldDetails } from "Views/Card/Edit/index.context";
import { getCookieValue, cookieNames } from "utility/CookieHelper";
import { currencyFormatterWithFractions } from "utility";
import { CARD_LIMIT_TYPES } from "Views/Card/types";
import { CARD_LIMITS_LABELS } from "Views/Card/Components/SpendingLimit/index.constants";
import { infoIconGrey30x30 } from "assets/icons/card";
import styles from "Views/Card/Edit/Policy/ReadOnlyData/index.module.scss";
import { CARD_SETTING_LABEL } from "constants/Card";

export const getCardLimit = (cardLimitType: CARD_LIMIT_TYPES, cardLimit: string) => {
  const orgCurrencyCode = getCookieValue(cookieNames.CURRENCY_CODE);

  if (cardLimitType !== CARD_LIMIT_TYPES.unlimited) {
    return (
      <span>
        {`${currencyFormatterWithFractions(cardLimit, orgCurrencyCode, 2, false)} (${
          CARD_LIMITS_LABELS[cardLimitType] || ""
        })`}
      </span>
    );
  } else {
    return (cardLimitType && CARD_LIMITS_LABELS[cardLimitType]) || "-";
  }
};

const GetToRenderData = (cardDetailsEditState: ICardEditFieldDetails) => {
  const { spendingLimitType, spendingLimit, expenseCategoryName, spendingLocks } = cardDetailsEditState;
  const { SPENDING_LIMIT, DEFAULT_EXPENSE_CATEGORY } = CARD_SETTING_LABEL;

  const dataToRender = [
    {
      label: SPENDING_LIMIT,
      value: <span className={styles["value-bold"]}>{getCardLimit(spendingLimitType, spendingLimit)}</span>,
      show: true,
    },
    {
      label: DEFAULT_EXPENSE_CATEGORY,
      value: expenseCategoryName || "N/A",
      show: true,
    },
    {
      label: "SPENDING LOCK",
      value: (
        <span className={styles["text-capitalize"]}>
          {(spendingLocks?.length > 0 &&
            spendingLocks
              ?.map((lock) => lock?.name)
              ?.join(", ")
              ?.toLowerCase()) ||
            "None"}
        </span>
      ),
      show: true,
    },
  ];

  return dataToRender.filter((item) => item.show);
};

const CardPolicyReadOnlyData: React.FunctionComponent = () => {
  const { cardDetailsEditState } = useCardDetailsEditContext();
  const dataToRender = GetToRenderData(cardDetailsEditState);
  return (
    <>
      <div className={styles["read-only-data-container"]}>
        {dataToRender?.map((data, index) => (
          <React.Fragment key={index}>
            <p className={styles["label"]}>{data.label}</p>
            <p className={styles["value"]}>{data.value}</p>
            {index < dataToRender.length - 1 && <Divider className={styles["divider"]} />}
          </React.Fragment>
        ))}
      </div>
      <div className={styles["info-container"]}>
        <img src={infoIconGrey30x30} alt="info icon" width={30} height={30} className={styles["info-icon"]} />
        <p className={styles["info"]}>To change any of the Card Policy, please reach out to your Budget Owner.</p>
      </div>
    </>
  );
};

export default CardPolicyReadOnlyData;
