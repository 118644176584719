import React from "react";
import { Col, Form, Row } from "antd";
import { AxiosResponse } from "axios";

import useLoading from "utility/useLoading";
import { substract } from "utility/DateUtilites";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";

import DatePicker from "Modules/datePicker";
import { TOASTER_STATUS_TYPE } from "Modules/DS/Toaster";
import { Button, Typography } from "@spenmo/splice";
import useCheckFeatureStatus from "customHooks/featureCheck";
import { SPLIT_NAMES, SPLIT_TREATMENT_TYPES } from "Redux/splitio/constants";
import { updatePartnerConnection } from "../../Common/dataCall";

import styles from "./styles.module.scss";

const SyncPeriod = ({ goToSettingPage, metaData }) => {
  const [form] = Form.useForm();
  const migratingCustomerTreatment = useCheckFeatureStatus(SPLIT_NAMES.migratingAdvancedExpenseCustomer);

  const [updateXeroAccount, updateXeroAccountLoading] = useLoading(
    updatePartnerConnection,
    (error: AxiosResponse) => {
      return error?.data?.error?.message || "Something went wrong";
    },
    {},
    false
  );

  const onFinishHandler = async () => {
    // metadata was data send from the previos step
    const formData = {
      ...metaData,
      bankFeedStartTime: new Date(form.getFieldValue("sync-period")).toISOString(),
    };

    try {
      const response = await updateXeroAccount(formData);
      if (response?.data?.status === HTTP_STATUS_CODE.OK) {
        goToSettingPage?.({ message: "You have successfully connected your Xero Bank Feeds to your organization" });
      } else {
        throw new Error();
      }
    } catch (err) {
      goToSettingPage?.({
        message: "Xero cannot be connected to Spenmo right now. Please try again.",
        type: TOASTER_STATUS_TYPE.ERROR,
      });
    }
  };

  return (
    <Row justify="start">
      <Form onFinish={onFinishHandler} form={form} className={styles.fullWidth}>
        <Col span={14} className={styles.column}>
          <Typography
            variant="headline-brand"
            className={styles.title}
            size="s"
            tag="h3"
            children="Select a sychronization start date"
          />
          <Typography
            variant="body-content"
            className={styles.subTitle}
            size="s"
            tag="h5"
            children="All Spenmo transactions from this date onwards will be synchronised to Xero."
          />

          <div className={styles.syncPeriod}>
            <Form.Item
              name="sync-period"
              initialValue={new Date().toISOString()}
              label={<span>Synchronization start date</span>}
            >
              <>
                <DatePicker
                  allowClear={false}
                  id="sync-period"
                  picker="date"
                  showPastDates
                  initialValue={new Date().toISOString()}
                  placeholder="Select Date"
                  action={(date: string) => form.setFieldsValue({ "sync-period": date })}
                  pastDateRange={(current) => current < substract(1, "year") || current > new Date()}
                  disabled={migratingCustomerTreatment === SPLIT_TREATMENT_TYPES.ON}
                />
                <span className={styles.caption}>You can only select a date up to a year ago from today</span>
              </>
            </Form.Item>
          </div>
          <Button
            type="submit"
            variant="primary"
            size="l"
            loading={updateXeroAccountLoading}
            className={styles.connectButton}
          >
            Connect
          </Button>
        </Col>
      </Form>
    </Row>
  );
};

export default SyncPeriod;
