import React, { useRef } from "react";
import { LegendOrdinal, LegendItem, LegendLabel } from "@visx/legend";
import { useTooltip, useTooltipInPortal } from "@visx/tooltip";
import { AxisBottom } from "@visx/axis";
import { Group } from "@visx/group";
import { BarGroup } from "@visx/shape";
import { scaleBand, scaleLinear, scaleOrdinal } from "@visx/scale";
import { localPoint } from "@visx/event";

import { Typography } from "@spenmo/splice";

import { SpendAnalysisType, TooltipData } from "Views/SubscriptionManagement/@types";
import LoadingComponent from "Views/State/Loading/LoaderIcon";
import { chartStyle, getMaxValue } from "Views/SubscriptionManagement/Details/SpendAnalysis/utils";
import { formatDate } from "utility/DateUtilites";
import { currencyFormatterV2 } from "utility";
import { cleanBarGroups } from "./utils";
import { getCookieValue, cookieNames } from "utility/CookieHelper";

import styles from "./styles.module.scss";

const barTypes = ["estimated", "actual", "forecast"];
let tooltipTimeout: number;
const height = 120;

const colorScale = scaleOrdinal({
  domain: barTypes,
  range: [chartStyle.estimatedColor, chartStyle.actualColor, chartStyle.forecastColor],
});

export function SpendAnalysis({ analysisData, isLoading }: { analysisData: SpendAnalysisType[]; isLoading: boolean }) {
  const headerRef = useRef<HTMLDivElement>(null);
  const { tooltipOpen, tooltipLeft, tooltipTop, tooltipData, hideTooltip, showTooltip } = useTooltip<TooltipData>();

  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    scroll: true,
  });
  const { defaultMargin } = chartStyle;

  // To occupy the whole width even on resize
  const width = headerRef?.current?.clientWidth || 342;
  const xMax = width - defaultMargin.left - defaultMargin.right;
  const yMax = height - defaultMargin.top - defaultMargin.bottom;

  const dateScale = scaleBand<string>({
    domain: analysisData.map((d) => d.date),
    padding: 0.2,
    round: true,
    range: [0, xMax],
  });

  const spendTypeScale = scaleBand<string>({
    domain: barTypes,
    padding: 0.2,
    round: true,
    range: [0, dateScale.bandwidth()],
  });

  const spendAmountScale = scaleLinear<number>({
    domain: [0, getMaxValue(analysisData)],
    nice: true,
    range: [yMax, 0],
  });

  return (
    <>
      <div className={styles.header} ref={headerRef}>
        <Typography size="caption-m" variant="body-content" className={styles.title}>
          Spend analysis
        </Typography>
        <LegendOrdinal scale={colorScale}>
          {(labels) => (
            <div className={styles.legend}>
              {labels.map((label, i) => (
                <LegendItem key={`legend-quantile-${i}`} margin="0 8px">
                  <svg width={16} height={8}>
                    <rect fill={label.value} width={16} height={8} rx={4} />
                  </svg>
                  <LegendLabel align="left" margin="0 0 0 4px">
                    <Typography variant="body-content" size="caption-s" className={styles.legendLabel}>
                      {label.text.toLowerCase()}
                    </Typography>
                  </LegendLabel>
                </LegendItem>
              ))}
            </div>
          )}
        </LegendOrdinal>
      </div>
      {isLoading ? (
        <div className={styles.loadingContainer}>
          <LoadingComponent />
        </div>
      ) : (
        <svg ref={containerRef} width={width} height={height}>
          <rect x={0} y={0} width={width} height={height} fill="var(--white-0)" rx={14} />
          <Group top={defaultMargin.top} left={defaultMargin.left}>
            <BarGroup
              data={analysisData}
              keys={barTypes}
              height={yMax}
              x0={(d) => d.date}
              x0Scale={dateScale}
              x1Scale={spendTypeScale}
              yScale={spendAmountScale}
              color={colorScale}
            >
              {(barGroups) =>
                cleanBarGroups(barGroups, analysisData).map((barGroup) => (
                  <Group key={`bar-group-${barGroup.index}-${barGroup.x0}`} left={barGroup.x0}>
                    {barGroup.bars.map((bar) => (
                      <rect
                        key={`bar-group-bar-${barGroup.index}-${bar.index}-${bar.value}-${bar.key}`}
                        x={bar.x}
                        y={Math.min(bar.y, 50)}
                        width={bar.width}
                        height={bar.value > 0 ? Math.max(bar.height, 10) : 0}
                        fill={bar.color}
                        rx={4}
                        ry={4}
                        onMouseLeave={() => {
                          tooltipTimeout = window.setTimeout(() => {
                            hideTooltip();
                          }, 300);
                        }}
                        onMouseMove={(event) => {
                          if (tooltipTimeout) clearTimeout(tooltipTimeout);

                          const eventSvgCoords = localPoint(event);
                          showTooltip({
                            tooltipData: { bar },
                            tooltipTop: eventSvgCoords?.y,
                            tooltipLeft: eventSvgCoords.x,
                          });
                        }}
                      />
                    ))}
                  </Group>
                ))
              }
            </BarGroup>
          </Group>
          <AxisBottom
            top={yMax + defaultMargin.top}
            tickComponent={({ formattedValue, ...rest }) => (
              <text {...rest}>
                <tspan x={rest.x} dy={rest.dy}>
                  {formatDate(new Date(formattedValue), "MMM")}
                </tspan>
              </text>
            )}
            scale={dateScale}
            stroke="var(--border-neutral-subtle)"
            hideTicks
            tickLabelProps={{
              fontSize: "var(--font-size-00)",
              fill: "var(--text-body-subtle)",
              fontFamily: "var(--font-noto-sans)",
              textAnchor: "middle",
            }}
          />
        </svg>
      )}
      {tooltipOpen && tooltipData && (
        <TooltipInPortal top={tooltipTop} left={tooltipLeft} style={chartStyle.tooltipStyle}>
          <div className={styles.section}>
            <Typography tag="p" variant="body-content" size="m" weight={600} className={styles.amount}>
              {currencyFormatterV2(tooltipData.bar.value, getCookieValue(cookieNames.CURRENCY_CODE), true, 2)}
            </Typography>
            <Typography tag="p" variant="body-content" size="m" className={styles.date}>
              {formatDate(tooltipData.bar.date, "MMM YY")}
            </Typography>
          </div>
        </TooltipInPortal>
      )}
    </>
  );
}
