import React, { useEffect, useState } from "react";
import TopUpBalance from "./TopUpBalance";
import TopUpPaymentTypeOptions from "./TopUpPaymentTypeOptions";
import LoaderIcon from "../State/Loading/LoaderIcon";
import { useDispatch, useSelector } from "react-redux";
import { getAllTeamsWallet } from "Redux/Actions/";
import { TEAM_TYPE } from "constants/Team.constant";
import { Redirect, useHistory } from "react-router-dom";
import { useIsSessionExpired } from "customHooks/Auth/useIsSessionExpired";
import { useIsSaasWithPaymentsOrg } from "customHooks/useIsSaasWithPaymentsOrg";

import usePermissionCheck from "Permission/usePermissionCheck";
import { CASBIN_PAGES } from "Permission/Pages";
import { TOPUP_MENU } from "Permission/Actions";

const TopUp = () => {
  const history = useHistory();
  const permissionParam = [{ object: CASBIN_PAGES.TOPUP_MENU, action: TOPUP_MENU.READ }];
  const [IS_ALLOWED_TOPUP_MENU_READ, loading] = usePermissionCheck(permissionParam, true);
  const allTeamsWallet = useSelector((state) => state.allTeamsWalletReducer);
  const [orgWallet, setOrgWallet] = useState(null);
  const dispatch = useDispatch();
  const isSessionExpired = useIsSessionExpired();
  const isSaasWithPaymentsOrg = useIsSaasWithPaymentsOrg();

  useEffect(() => {
    dispatch(getAllTeamsWallet());
  }, []);

  useEffect(() => {
    if (!loading && !IS_ALLOWED_TOPUP_MENU_READ && !isSessionExpired) {
      history.push("/404");
    }
  }, [IS_ALLOWED_TOPUP_MENU_READ, loading]);

  const getWalletInfo = () => {
    const teams = allTeamsWallet.data.payload?.teams || [];
    setOrgWallet(teams.find((item) => item.type === TEAM_TYPE.ORGANISATION));
  };

  useEffect(() => {
    if (!allTeamsWallet.loading) {
      getWalletInfo();
    }
  }, [allTeamsWallet.loading]);

  const Loader = () => {
    if (allTeamsWallet) return null;
    return allTeamsWallet.loading ? <LoaderIcon /> : "Unable to fetch organisation data";
  };

  //  isSessionExpired already handled at the useEffect level and redirected to 404
  if (!isSaasWithPaymentsOrg) {
    return <Redirect to="/" />;
  }

  return loading ? (
    <LoaderIcon />
  ) : (
    <section className={"section section--white"}>
      <div className={"section__header"}>
        <div>Top Up Company Balance</div>
        <TopUpBalance orgWallet={orgWallet} iconAction={() => dispatch(getAllTeamsWallet())} />
      </div>
      <Loader />
      {orgWallet && <TopUpPaymentTypeOptions currencyCode={orgWallet.currency_code} />}
    </section>
  );
};

export default TopUp;
