import * as React from "react";
import { useSelector } from "react-redux";
import { useIsManagerOrAdmin } from "customHooks/Card";
import { CARD_TYPE } from "Views/Card/types";

type useCheckIfWalletUserReturnType = [boolean, (selectedCardholderId: string) => boolean];

export const useCheckIfWalletUser = (cardType: CARD_TYPE): useCheckIfWalletUserReturnType => {
  const [isAdmin, isManager] = useIsManagerOrAdmin();
  const { id: userId = "", is_wallet_user = false } = useSelector(
    (state: any) => state?.userInfoReducer?.data?.payload?.user || {}
  );

  /*  Need to ignore for manager and admins on step 1 since they can create cards for others as well,
      if they are creating cards for themselves then show the wallet activation if not wallet user on click of confirm in step 1 ( this is checked in reCheckIfWalletUser func )
  */
  const checkIfWalletUser = () => is_wallet_user || ((isManager || isAdmin) && cardType === CARD_TYPE.VIRTUAL);
  const [showWalletActivationScreen, setShowWalletActivationScreen] = React.useState(() => {
    return !checkIfWalletUser();
  });

  const reCheckIfWalletUser = (selectedCardholderId: string) => {
    if (selectedCardholderId === userId && !is_wallet_user) {
      setShowWalletActivationScreen(true);
      return false;
    }
    return true;
  };

  return [showWalletActivationScreen, reCheckIfWalletUser];
};
