import React, { useEffect } from "react";
import { Skeleton } from "antd";
import { Typography } from "@spenmo/splice";
import { ErrorBoundary } from "@sentry/react";

import { useAppDispatch } from "hook";
import { getTeamMembersByTeamId, getTeamListByUserId } from "Redux/Actions";

import Tabs from "Modules/DS/Tabs";
import { SelectProvider } from "Modules/DS/Select";

import { PAGE_TITLE } from "Views/Reimbursement/Constants";
import { IReimbursementsPage } from "Views/Reimbursement/@types";

import List from "Views/Reimbursement/List";
import Modal from "Views/Reimbursement/Modal";
import ErrorState from "Views/Reimbursement/Error";
import Sidepanel from "Views/Reimbursement/Sidepanel";
import useTabRedirection from "Views/Reimbursement/hooks/useTabRedirection";
import { REIMBURSEMENTS_PAGE_ANALYTICS, trackReimbursementAnalytics } from "Views/Reimbursement/Analytics";

import styles from "./index.module.scss";

const Reimbursements = ({ tabKey }: IReimbursementsPage) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getTeamMembersByTeamId());
    dispatch(getTeamListByUserId());
  }, [dispatch]);

  const { onChangeTabHandler, tabs, loading } = useTabRedirection(tabKey);

  const renderTab = () => {
    return tabs.length > 1 ? (
      <div className={styles.tabs}>
        <Tabs content={null} tabsList={tabs} activeKey={tabKey} action={onChangeTabHandler} destroyInactiveTabPane />
      </div>
    ) : null;
  };

  const renderTabSkeleton = () => {
    return loading ? <Skeleton.Input size="small" active={true} className={styles.tabSkeleton} /> : renderTab();
  };

  useEffect(() => {
    trackReimbursementAnalytics({
      eventName: REIMBURSEMENTS_PAGE_ANALYTICS.REIMBURSEMENT,
      eventSource: "Page",
      eventSubSource: "Loaded",
    });
  }, []);

  return (
    <div className={styles.page}>
      <Typography variant="headline-content" size="m" tag="h1">
        {PAGE_TITLE}
      </Typography>
      {renderTabSkeleton()}
      <SelectProvider>
        <ErrorBoundary fallback={<ErrorState />}>
          <List loading={loading} />
        </ErrorBoundary>
        <Sidepanel />
        <Modal />
      </SelectProvider>
    </div>
  );
};

export default Reimbursements;
