import * as React from "react";
import cn from "classnames";

import styles from "./Loader.module.scss";

interface LoaderProps {
  className?: string;
}

export const Loader = ({ className }: LoaderProps) => {
  return <div data-testid="modal-loader" className={cn(styles.container, className)} />;
};
