/*
  enums
*/
export enum GET_EMPLOYEES_ACTION_TYPES {
  FETCH_ALL_EMPLOYEES_REQUEST = "EMPLOYEE/FETCH_ALL_EMPLOYEES_REQUEST",
  FETCH_ALL_EMPLOYEES_SUCCESS = "EMPLOYEE/FETCH_ALL_EMPLOYEES_SUCCESS",
  FETCH_ALL_EMPLOYEES_ERROR = "EMPLOYEE/FETCH_ALL_EMPLOYEES_ERROR",
}

/*
  interfaces
*/
export interface IEmployee {
  id: string;
  name: string;
  mobile_country_code: string;
  mobile_number: string;
  email: string;
  organisation_id: string;
  profile_photo_url: string;
  is_otp_verified: string;
  kyc: any;
  id_type: string;
  zipcode: string;
  country: string;
  state: string;
  city: string;
  address_1: string;
  address_2: string;
  role_id: number;
  role_name: string;
  is_default_role: boolean;
  team: string;
  is_deletion_allowed: boolean;
  wallet_enabled: boolean;
}

export interface IEmployeesPayload {
  employees: IEmployee[];
  total_count: number;
  onboarding_version: number;
  status_message: string;
}

export interface IEmployeesData {
  payload: IEmployeesPayload;
  status: number;
}

export interface IEmployeesReducer {
  loading: boolean;
  data: IEmployeesData;
  error: boolean;
}

export interface IEmployeeFilters {
  team?: string;
  kyc_status?: string;
}

/*
  initialize
*/
export const initialEmployee: IEmployee = {
  id: "",
  name: "Dummy Employee",
  mobile_country_code: "",
  mobile_number: "",
  email: "",
  organisation_id: "",
  profile_photo_url: "",
  is_otp_verified: "",
  kyc: null,
  id_type: "",
  zipcode: "",
  country: "",
  state: "",
  city: "",
  address_1: "",
  address_2: "",
  role_id: null,
  role_name: "",
  is_default_role: false,
  team: "",
  is_deletion_allowed: false,
  wallet_enabled: false,
};

export const initialEmployeeState: IEmployeesReducer = {
  loading: false,
  data: {
    payload: {
      employees: [],
      total_count: 0,
      onboarding_version: null,
      status_message: null,
    },
    status: 200,
  },
  error: false,
};
