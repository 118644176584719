import React from "react";
import { sendMoney as sendMoneyImg, requestMoney as requestMoneyImg } from "assets/img/homeIcons";
import SendMoney from "Views/SendMoney";
import RequestMoney from "Views/RequestMoney";
import { QuickAccessShortCutsEventName } from "Views/Home/Analytics";
import QuickAccessTitleSplit from "./QuickAccessTitleSplit";
import { CATEGORIES_PATH } from "constants/Routes.constants";
import * as homeIcons from "assets/img/homeIcons";
import styles from "./index.module.scss";

const accessShortCutCard = (onClick, title, icon) => {
  const [firstWord, restTitle] = QuickAccessTitleSplit(title);

  return (
    <div className={styles.card} onClick={onClick}>
      <img src={icon} alt={`${title} icon`} className={styles.icon} />
      <p className={styles.text}>{firstWord}</p>
      <span className={styles.space} />
      {restTitle && <p className={styles.text}>{restTitle}</p>}
    </div>
  );
};

const setAccessShortCutDetails = ({ id, title, to, icon, eventName, state = null }) => {
  const redirectLink = state ? { pathname: to, ...state } : to;
  return {
    id: id || title, // compromise the id to take title if id is not exists
    title: title,
    to: redirectLink,
    icon: homeIcons?.[icon],
    eventName,
    iconName: icon,
    tooltipText: "Not supported on this \ndashboard",
  };
};

export const quickAccessShortCuts = [
  setAccessShortCutDetails({
    title: "Submit Bills",
    to: "/bills",
    icon: "submitBills",
    eventName: QuickAccessShortCutsEventName.SUBMIT_BILLS,
  }),
  setAccessShortCutDetails({
    id: "submitBillsUplifting",
    title: "Submit Bills",
    to: "/bills/submitted/new",
    icon: "submitBills",
    eventName: QuickAccessShortCutsEventName.SUBMIT_BILLS,
  }),
  setAccessShortCutDetails({
    title: "Setup Payment Run",
    to: "/settings/payment-run?edit=true",
    icon: "billspay",
    eventName: QuickAccessShortCutsEventName.SETUP_PAYMENT_RUN,
  }),
  setAccessShortCutDetails({
    title: "View Payment Run",
    to: "/bills/payment-run",
    icon: "billspay",
    eventName: QuickAccessShortCutsEventName.MANAGE_PAYMENT_RUN,
  }),
  setAccessShortCutDetails({
    title: "Manage Bill Payments",
    to: "/transactions?tab=pending&subtab=all",
    icon: "manageBills",
    eventName: QuickAccessShortCutsEventName.MANAGE_BILL_PAYMENTS,
  }),
  setAccessShortCutDetails({
    title: "Request Reimbursement",
    to: "/reimbursements",
    icon: "reimbursement",
    eventName: QuickAccessShortCutsEventName.REQUEST_REIMBURSEMENT,
    state: {
      state: { showAddReimburse: true },
    },
  }),
  setAccessShortCutDetails({
    title: "View Budgets",
    to: "/teams",
    icon: "team",
    eventName: QuickAccessShortCutsEventName.VIEW_TEAMS_LIST,
  }),
  setAccessShortCutDetails({
    title: "View Employees",
    to: "/employee",
    icon: "employee",
    eventName: QuickAccessShortCutsEventName.VIEW_EMPLOYEES,
  }),
  setAccessShortCutDetails({
    title: "Add New Employee",
    to: "/employee/add",
    icon: "addEmployee",
    eventName: QuickAccessShortCutsEventName.ADD_NEW_EMPLOYEE,
  }),
  setAccessShortCutDetails({
    title: "Create New Budget",
    to: "/create-team",
    icon: "team",
    eventName: QuickAccessShortCutsEventName.CREATE_NEW_TEAM,
  }),
  setAccessShortCutDetails({
    title: "Manage Categories",
    to: CATEGORIES_PATH,
    icon: "categories",
    eventName: QuickAccessShortCutsEventName.MANAGE_CATEGORIES,
  }),
  setAccessShortCutDetails({
    title: "View Categories",
    to: CATEGORIES_PATH,
    icon: "categories",
    eventName: QuickAccessShortCutsEventName.VIEW_CATEGORIES,
  }),
  setAccessShortCutDetails({
    title: "View Transactions",
    to: "/transactions",
    icon: "transaction",
    eventName: QuickAccessShortCutsEventName.VIEW_TRANSACTIONS,
  }),
  setAccessShortCutDetails({
    title: "Manage Bank Account",
    to: "/bankaccount",
    icon: "bankAccount",
    eventName: QuickAccessShortCutsEventName.MANAGE_BANK_ACCOUNT,
  }),
  {
    title: "Send Money",
    element: <SendMoney customBtnComponent={(onClick) => accessShortCutCard(onClick, "Send Money", sendMoneyImg)} />,
    eventName: QuickAccessShortCutsEventName.SEND_MONEY,
  },
  {
    title: "Request Money",
    element: (
      <RequestMoney customBtnComponent={(onClick) => accessShortCutCard(onClick, "Request Money", requestMoneyImg)} />
    ),
    eventName: QuickAccessShortCutsEventName.REQUEST_MONEY,
  },
];
