/**
 * @fileoverview Layout builder component that constructs the UI layout based on provided session conditions.
 *
 * The component allows for different layout views like a sidebar view or default view. It also takes care
 * of different session related modals like tab change in case of multi-sign-ins, session timeouts,
 * and even terms and conditions for admin users. Additionally, the layout builder has a feature to
 * show chat support and provides configurations for the content layout.
 *
 * @author Krishna, Ritik, Sushmitha, Bakshi
 * @created 1 Sept 2023
 * @lastModified 1 Sept 2023
 */
import React from "react";
import classNames from "classnames";

// Types for routing configuration
import { ISessionConditions, PAGE_BASE_COLOR_TYPE, PAGE_GRID_CONTENT_TYPE } from "Route/@types";

// utility components
import { useScrollOverflow } from "utility/useScrollOverflow";

// Modules components
import OverlayLoader from "Modules/overlayLoader";
import SessionTimeoutModal from "Modules/SessionTimeoutModal";

//Views components
import Navigation from "Views/Navigation";
import ReKYBContainer from "Views/KYX/ReKYB/Container";
import { TermsAndCondition } from "Views/TermsAndConditions";
import { TabChangeModal } from "Views/Auth/MultiSignin/TabChange";

import SpenmoSupport from "Views/SpenmoSupport";
import SpenmoSupportProvider from "Views/SpenmoSupport/SpenmoSupportProvider";

import styles from "./index.module.scss";
import layoutStyles from "./layout.module.scss";
import scrollStyles from "./scroll.module.scss";

export interface ILayoutBuilderProps extends Partial<ISessionConditions> {
  children: React.ReactNode;
  content?: PAGE_GRID_CONTENT_TYPE;
  baseColor?: PAGE_BASE_COLOR_TYPE;
}

/**
 * LayoutBuilder Component
 * Constructs the main application layout based on provided conditions.
 *
 * @param children - React nodes to be rendered inside the layout.
 * @param showRekyb - Flag to determine whether to show Rekyb Banner and Modal Popup.
 * @param showSidebarLayout - Flag to render sidebar layout.
 * @param showOverlayLoader - Flag to show overlay loader.
 * @param showTabChangeModal - Flag to show tab change modal in case of multi-sign-ins.
 * @param content - Specifies the type of content layout - fluid or fixed.
 */
const LayoutBuilder = ({
  children,
  showTnC = false,
  showRekyb = false,
  showSidebarLayout = false,
  showOverlayLoader = false,
  showTabChangeModal = false,
  content = PAGE_GRID_CONTENT_TYPE.FLUID,
  baseColor = PAGE_BASE_COLOR_TYPE.DEFAULT,
}: ILayoutBuilderProps) => {
  const [sectionRef, yAxisOverflown, xAxisOverflown] = useScrollOverflow<HTMLElement>();

  // CSS classes for layout styling based on conditions
  const layoutClass = {
    [layoutStyles.sidebar]: showSidebarLayout,
    [layoutStyles.default]: !showSidebarLayout,
  };

  const containerClass = {
    [styles.withSidebar]: showSidebarLayout,
    [styles.withoutSidebar]: !showSidebarLayout,
  };

  const contentClass = {
    [styles.fluid]: content === PAGE_GRID_CONTENT_TYPE.FLUID,
    [styles.fixed]: content === PAGE_GRID_CONTENT_TYPE.FIXED,
  };

  const scrollClass = {
    [scrollStyles.scroll]: yAxisOverflown && xAxisOverflown,
    [scrollStyles.scrollXAxis]: xAxisOverflown && !yAxisOverflown,
    [scrollStyles.scrollYAxis]: yAxisOverflown && !xAxisOverflown,
  };

  const baseColorClass = {
    [styles.neutralDefault]: baseColor === PAGE_BASE_COLOR_TYPE.DEFAULT,
    [styles.brandNavy]: baseColor === PAGE_BASE_COLOR_TYPE.BRAND_NAVY,
  };

  const adjustRows = {
    [styles.withRekyb]: showRekyb,
  };

  return (
    <main className={classNames(styles.layout, layoutClass)}>
      <SpenmoSupportProvider>
        {/* Sidebar navigation component */}
        {showSidebarLayout && <Navigation />}

        <section
          ref={sectionRef}
          className={classNames(
            styles.container,
            containerClass,
            contentClass,
            scrollClass,
            adjustRows,
            baseColorClass
          )}
        >
          {/* Re-KYB Container */}
          {showRekyb && <ReKYBContainer />}

          {children}
        </section>

        {/* When user switch account in case of multi sign*/}
        {showOverlayLoader && <OverlayLoader show />}

        {/* Terms and Condition, For detailed description check this component */}
        {showTnC && <TermsAndCondition />}

        {/*  To Detect Tab change in case of multi signIn account*/}
        {showTabChangeModal && <TabChangeModal />}

        {/* Chat Support, when user clicks on  help icon in bottom sidebar*/}
        <SpenmoSupport />

        {/*  Session timeout Modal when session get expired */}
        <SessionTimeoutModal />
      </SpenmoSupportProvider>
    </main>
  );
};

export default LayoutBuilder;
