import { EInputType } from "../type.d";

export const allOption: { [key: string]: boolean } = {
  [EInputType.TAX]: true,
  [EInputType.CATEGORY]: true,
  [EInputType.NOTES]: true,
  [EInputType.TRANSACTION_TAGS]: true,
  [EInputType.RECEIPT]: true,
  [EInputType.MERCHANT]: false,
};

export const noOption: { [key: string]: boolean } = {
  [EInputType.TAX]: false,
  [EInputType.CATEGORY]: false,
  [EInputType.NOTES]: false,
  [EInputType.TRANSACTION_TAGS]: false,
  [EInputType.RECEIPT]: false,
  [EInputType.MERCHANT]: false,
};

export const refundOption: { [key: string]: boolean } = {
  [EInputType.TAX]: false,
  [EInputType.CATEGORY]: false,
  [EInputType.NOTES]: false,
  [EInputType.TRANSACTION_TAGS]: true,
  [EInputType.RECEIPT]: false,
  [EInputType.MERCHANT]: false,
};

export const defaultInput = {
  [EInputType.TAX]: "",
  [EInputType.MERCHANT]: "",
  [EInputType.NOTES]: "",
  [EInputType.RECEIPT]: [],
  [EInputType.CATEGORY]: "",
  [EInputType.TRANSACTION_TAGS]: [],
};

export const isBillsTrxnsPage = (page: string) => page === "trxns_bills";
