import * as React from "react";
import { Skeleton } from "antd";

import styles from "./index.module.scss";

interface ICardHolderShimmer {
  renderTeamNameShimmer: boolean;
}

const CardHolderShimmer = ({ renderTeamNameShimmer }: ICardHolderShimmer) => {
  return (
    <div data-testid="shimmer-container" className={styles.container}>
      <Skeleton.Avatar className={styles.shimmer__initials} active shape="circle" />
      <div className={styles["shimmer__title--container"]}>
        <Skeleton.Input className={styles["shimmer__card-holder"]} active />
        {renderTeamNameShimmer && <Skeleton.Input className={styles["shimmer__team-name"]} active />}
      </div>
    </div>
  );
};

export default CardHolderShimmer;
