import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import {
  IGetCardsTrxnsData,
  GET_CARDS_TRXNS_DATA_PARAMS,
  ICardTrxnDetailsSuccessData,
  SidePanelDetailPropsType,
  ITrxnToastContext,
} from "Views/Transaction/@types";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import { getCardTrxnDetails } from "Views/Transaction/DataCalls/Cards.api";
import { initialCardsTrxnsData, initialCardTrxnDetailsData } from "Views/Transaction/DataCalls/Cards.mocks";
import { TrxnToastContext } from "Views/Transaction/Context";
import { CARDS_TRXN_SEARCH_PARAMS } from "Views/Transaction/Constants/Cards";

import { TOASTER_STATUS_TYPE } from "Modules/DS/Toaster";
import { DEBOUNCE_DELAY_TIME } from "Modules/DS/Search";
import { ISelectContext, SelectContext } from "Modules/DS/Select";
import { ISidePanelButton, ESidePanelButtonType } from "Modules/DS/SidePanel";

export const useDetails = () => {
  const history = useHistory();
  const { pathname, search = "" } = history?.location || {};
  const actionRef = useRef<() => Promise<void>>(() => null);
  const { select, closeSelect }: ISelectContext = useContext<ISelectContext>(SelectContext);
  const { setToaster }: ITrxnToastContext = useContext<ITrxnToastContext>(TrxnToastContext);

  const [visible, setVisible] = useState(false);
  const [actionLoading, setActionloading] = useState(false);
  const [trxnDetailsLoading, setTrxnDetailsLoading] = useState(false);
  const [trxnDetails, setTrxnDetails] = useState<ICardTrxnDetailsSuccessData>(initialCardTrxnDetailsData);
  const [record, setRecord] = useState<IGetCardsTrxnsData>(initialCardsTrxnsData);

  const handleError = () => {
    setVisible(false);
    setToaster({
      show: true,
      type: TOASTER_STATUS_TYPE.ERROR,
      message: "Failed to fetch the details, Please try again later.",
    });
  };

  const getTrxnDetails = (
    trxnNumber: string,
    isSpenmoTrxnNumber: boolean,
    selectedTrxnDetails?: IGetCardsTrxnsData
  ) => {
    getCardTrxnDetails(trxnNumber, isSpenmoTrxnNumber)
      .then((res) => {
        const { status, payload } = res?.data || {};
        if (status === HTTP_STATUS_CODE.OK && payload?.["receipts"]?.["expense"]) {
          setTrxnDetails(payload as ICardTrxnDetailsSuccessData);
          setRecord(selectedTrxnDetails || (payload as ICardTrxnDetailsSuccessData)?.final_state);
        } else {
          handleError();
        }
      })
      .catch(() => {
        handleError();
      })
      .finally(() => {
        setTrxnDetailsLoading(false);
      });
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    if (searchParams?.has(CARDS_TRXN_SEARCH_PARAMS.txnNumber)) {
      const txnNumber = searchParams.get(CARDS_TRXN_SEARCH_PARAMS.txnNumber);
      searchParams.delete(CARDS_TRXN_SEARCH_PARAMS.txnNumber);
      history.push({ pathname, search: searchParams.toString() });
      setVisible(true);
      setTrxnDetailsLoading(true);
      getTrxnDetails(txnNumber, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const fetchTrxnDetails = useCallback((record: IGetCardsTrxnsData) => {
    if (select.show) {
      closeSelect();
      return;
    }
    setVisible(true);
    setTrxnDetailsLoading(true);
    getTrxnDetails(record?.[GET_CARDS_TRXNS_DATA_PARAMS.TRANSACTION_NUMBER], false, record);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCloseHandler = () => {
    setVisible(false);
    setRecord(initialCardsTrxnsData);
    setTrxnDetails(initialCardTrxnDetailsData);
  };

  const sidePanelBtns: ISidePanelButton[] = [
    {
      id: "SIDE-PANEL-BUTTON-001",
      text: "Save",
      disabled: false,
      loading: actionLoading,
      action: async () => {
        setActionloading(true);
        await actionRef.current();
        setTimeout(() => setActionloading(false), DEBOUNCE_DELAY_TIME.MAX * 2);
      },
      type: ESidePanelButtonType.SECONDARY,
    },
  ];

  const detailsProps: SidePanelDetailPropsType = {
    visible: visible,
    onClose: onCloseHandler,
    title: "Card Transaction",
    sticky: true,
    buttons: sidePanelBtns,
    loading: trxnDetailsLoading,
  };

  return {
    record,
    trxnDetails,
    trxnDetailsLoading,
    fetchTrxnDetails,
    detailsProps,
    visible,
    actionRef,
    onCloseHandler,
  };
};
