import * as React from "react";
import { useRef } from "react";
import { chevronDown } from "assets/img";
import "./Dropdown.scss";
import ContextualMenu from "../ContextualMenu";
import { ContextualMenuItem, isContextualMenuItem, valueType } from "../ContextualMenu/types";
import Label from "../Atoms/Label";
import classNames from "classnames";

interface DropdownProps {
  className?: string;
  label?: string;
  placeholder?: string;
  values: valueType;
  multiple?: boolean;
  onChange: (string) => void;
  options: Array<ContextualMenuItem>;
  defaultOpen?: boolean;
  renderOption?: (option: ContextualMenuItem, isSelected?: boolean) => React.ReactElement | string;
  onScrollBottom?: () => void;
  isLoading?: boolean;
}

const Dropdown: React.FC<DropdownProps> = ({
  className,
  label,
  placeholder = "",
  values,
  options = [],
  onChange = () => undefined,
  defaultOpen = false,
  renderOption = (option: ContextualMenuItem) => <div>{option.label}</div>,
  onScrollBottom = () => undefined,
  isLoading = false,
}: DropdownProps) => {
  const triggerRef = useRef(null);

  const dropdownClassName = classNames("spm-dropdown", className);

  const displayValues = (
    <div className={"spm-dropdown__value"}>
      {renderOption(
        isContextualMenuItem(values) ? values : ({ label: values, value: values } as ContextualMenuItem),
        true
      )}
    </div>
  );

  return (
    <div className={dropdownClassName}>
      <ContextualMenu
        defaultOpen={defaultOpen}
        values={values}
        onChange={onChange}
        options={options}
        renderOption={renderOption}
        onScrollBottom={onScrollBottom}
        isLoading={isLoading}
      >
        <>
          <Label
            onClick={() => {
              triggerRef.current?.focus();
            }}
          >
            {label}
          </Label>
          <button ref={triggerRef} className="spm-dropdown__value-container">
            {values ? displayValues : <div className="spm-dropdown__placeholder">{placeholder}</div>}
            <div className="selected__item__arrow">
              <img src={chevronDown}  alt=""/>
            </div>
          </button>
        </>
      </ContextualMenu>
    </div>
  );
};

export default Dropdown;
