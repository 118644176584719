import {
  FETCH_UPLOAD_REQUEST,
  FETCH_UPLOAD_SUCCESS,
  FETCH_UPLOAD_FAILURE,
  FETCH_UPLOAD_CLEAR,
} from "../../Actions/OnBoarding/uploadDocument";
import { userInitialState } from "../../InitialState";

export const uploadDocumentReducer = (state = userInitialState, action) => {
  switch (action.type) {
    case FETCH_UPLOAD_REQUEST:
      return {
        ...state,
        loading: true,
        data: action.data,
        error: false,
      };
    case FETCH_UPLOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: false,
      };
    case FETCH_UPLOAD_FAILURE:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: true,
      };
    case FETCH_UPLOAD_CLEAR:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.data,
      };
    default:
      return state;
  }
};
