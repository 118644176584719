import React, { useEffect } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Typography, Image } from "antd";
import { pdfjs, Document, Page } from "react-pdf";

import styles from "./ExtractingDetail.module.scss";
import "./ExtractingDetail.scss";
import { trackEvent } from "utility/analytics";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const ExtractingDetail = ({ attachmentImages, requestor }) => {
  const { Text } = Typography;

  useEffect(() => {
    trackEvent("bill view detail tab on sidebar");
  }, []);

  return (
    <>
      <Text className={classNames(styles.text, styles.normal)}>
        Payment details will be shown here after extracted.
      </Text>
      <Text className={classNames(styles.text, styles.label)}>Requestor</Text>
      <Text className={classNames(styles.text, styles.normal)}>{requestor}</Text>
      <Text className={classNames(styles.text, styles.label)}>Invoice</Text>
      {attachmentImages?.map((item, index) => {
        switch (item.url.split(/[.]+/).pop().split("?")[0]) {
          case "pdf": {
            return (
              <div className="extracting-detail-pdf">
                <Document key={index} file={item.url}>
                  <Page pageNumber={1} />
                </Document>
              </div>
            );
          }
          default: {
            return <Image key={index} preview={false} className={styles.image} src={item.url} />;
          }
        }
      })}
      <div className={styles.spacing} />
    </>
  );
};

ExtractingDetail.propTypes = { attachmentImages: PropTypes.array, requestor: PropTypes.string };

ExtractingDetail.defaultProps = { attachmentImages: [], requestor: "" };

export default ExtractingDetail;
