import React, { useMemo, useState } from "react";
import { Button, Typography, Refresh, Radio } from "@spenmo/splice";
import cn from "classnames";
import qs from "query-string";
import { useLocation, useParams, useHistory } from "react-router-dom";

import { postData } from "API/Client";
import { useMatchMutate, useMutableData } from "API/useData";

import BankTransfer from "./BankTransfer";
import Paynow from "./Paynow";
import { PayViaSkeleton } from "Views/Bills/V2/components/Skeleton";

import { currencyFormatterV2 } from "utility";
import { API_URL, DEFAULT_ERROR_MESSAGE, PAYMENT_RUN_URL } from "Views/Bills/V2/constants";

import { paynowLogo } from "assets/img";
import { spenmoOrangeLogoLarge } from "assets/v1.1/logo/spenmo";

import { useBill } from "Views/Bills/V2/context/BillContext";
import { useToaster } from "Views/Bills/V2/hooks";

import { getBillIDs } from "Views/Bills/V2/PaymentRunPreview/helper";
import { BANK_METHOD, PaymentMethodProps, PaymentRunPreviewParams } from "Views/Bills/V2/PaymentRunPreview/type";
import parentStyles from "Views/Bills/V2/PaymentRunPreview/PaymentRunPreview.module.scss";
import styles from "./PaymentMethod.module.scss";
import { Payment_Run_API_URL } from "../../PaymentRun/constant";

const PaymentMethod = (props: PaymentMethodProps) => {
  const { invoiceAmount } = props;

  const { billIDs: base64BillIDs } = useParams<PaymentRunPreviewParams>();
  const billIDs = getBillIDs(base64BillIDs as string);
  const location = useLocation();
  const history = useHistory();
  const { cycleID } = qs.parse(location.search, { parseNumbers: true });

  const matchMutate = useMatchMutate();

  const { openBudgetOverlimitModal } = useBill();
  const { appNotification } = useToaster();

  const [paymentType, setPaymentType] = useState<string>();
  const [autoPayLoading, setAutopayLoading] = useState(false);
  const [makePaymentLoading, setMakePaymentLoading] = useState(false);

  const topupListURL = qs.stringifyUrl({
    url: API_URL.topupList,
    query: {
      invoiceAmount,
    },
  });
  const {
    data: topupData,
    isValidating,
    isLoading,
    mutate: refetchTopup,
  } = useMutableData(invoiceAmount ? topupListURL : null, {
    keepPreviousData: true,
  });

  const topupLoading = isLoading && !topupData;

  const { deficitTopupAmount, deficitTopupCurrency, options, walletAmount, walletCurrency } = useMemo(
    () => topupData?.data?.payload || {},
    [topupData]
  );

  const handleClickProcessPayment = (action: "paynow" | "waiting_funds") => {
    if (action === "paynow") {
      setMakePaymentLoading(true);
    } else {
      setAutopayLoading(true);
    }

    postData(API_URL.processPaymentBills, {
      cycleID,
      billIDs,
      action,
    })
      .then(async (res) => {
        const { isSuccess, isEligible, overlimitBillsCount, overlimitBudgetNames, message } = res.data.payload;

        if (overlimitBillsCount || overlimitBudgetNames.length > 0) {
          openBudgetOverlimitModal(overlimitBillsCount, overlimitBudgetNames);
          return;
        }

        if (isSuccess && isEligible) {
          appNotification.success({
            message,
          });

          await matchMutate(new RegExp(Payment_Run_API_URL.paymentCycles));

          history.push(PAYMENT_RUN_URL, {
            refetchBillList: true,
          });
          return;
        }

        appNotification.error({
          message: DEFAULT_ERROR_MESSAGE,
        });
      })
      .catch((err) => {
        console.error(err);

        if (err?.response?.data?.error) {
          const { code, message } = err.response.data.error;

          if (message) {
            appNotification.error({
              errorCode: code,
              message,
            });

            return;
          }
        }

        appNotification.error({
          message: DEFAULT_ERROR_MESSAGE,
        });
      })
      .finally(() => {
        if (action === "paynow") {
          setMakePaymentLoading(false);
        } else {
          setAutopayLoading(false);
        }
      });
  };

  return (
    <div className={styles.paymentMethod}>
      <PayViaSkeleton loading={topupLoading}>
        <Typography
          tag="p"
          variant="body-content"
          size="m"
          weight={800}
          className={cn(parentStyles.titleColor, parentStyles.sectionItem, parentStyles.verticalSpace)}
        >
          Pay via
        </Typography>
        <div className={cn(styles.walletInfo, parentStyles.verticalSpace)}>
          <div className={styles.flex}>
            <div>
              <img src={spenmoOrangeLogoLarge} width={24} height={24} alt="Spenmo logo" />
            </div>
            <div>
              <Typography tag="p" variant="body-content" size="s" className={styles.titleColor}>
                Spenmo wallet balance
              </Typography>
              <Typography tag="p" variant="body-content" size="s" weight={800} className={styles.walletAmount}>
                {currencyFormatterV2(walletAmount, walletCurrency, true, 2)}
                <span
                  className={cn(styles.rotate, {
                    [styles.rotating]: isValidating,
                  })}
                  onClick={() => refetchTopup()}
                >
                  <Refresh size="16" iconColor="var(--icon-default)" />
                </span>
              </Typography>
            </div>
          </div>
          <div className={styles.makePayment}>
            <Button
              variant="primary"
              size="s"
              disabled={deficitTopupAmount !== 0 || autoPayLoading}
              onClick={() => handleClickProcessPayment("paynow")}
            >
              Make Payment
            </Button>
          </div>
        </div>
        <div className={cn(parentStyles.sectionItem, parentStyles.verticalSpace)}>
          <Typography tag="p" variant="body-content" size="m" weight={800} className={parentStyles.titleColor}>
            Top up balance
          </Typography>
          {Boolean(deficitTopupAmount) && (
            <Typography tag="p" variant="body-content" size="caption-m" className={parentStyles.titleColor}>
              You need to top up your Spenmo Wallet by{" "}
              <Typography variant="body-content" size="caption-m" className={styles.linkColor}>
                {currencyFormatterV2(deficitTopupAmount, deficitTopupCurrency, true, 2)}
              </Typography>
            </Typography>
          )}
        </div>
        <div className={parentStyles.verticalSpace}>
          {options?.map((option) => {
            const { description, label, methods, type } = option;
            const isSelected = paymentType === type;

            const optionData = {
              label,
              // default as BankTransfer component
              content: (
                <BankTransfer
                  description={description}
                  methods={methods}
                  onClickAutoPay={() => handleClickProcessPayment("waiting_funds")}
                  isAutoPayLoading={autoPayLoading}
                />
              ),
            };

            switch (type) {
              case BANK_METHOD.PAY_NOW: {
                optionData.label = (
                  <img
                    data-testid="paynowOpt"
                    className={styles.paynowLogo}
                    src={paynowLogo}
                    alt="pay now"
                    height={20}
                    width={120}
                  />
                );
                optionData.content = (
                  <Paynow
                    onClickAutoPay={() => handleClickProcessPayment("waiting_funds")}
                    isAutoPayLoading={autoPayLoading}
                    isAutoPayDisabled={makePaymentLoading}
                  />
                );
                break;
              }
            }

            return (
              <div key={type} className={cn(styles.radioSpace, styles.titleColor)}>
                <Radio
                  id={type}
                  onChange={setPaymentType}
                  size="s"
                  name="paymentType"
                  value={type}
                  checked={isSelected}
                >
                  {optionData.label}
                </Radio>
                {isSelected && <div className={styles.paymentDetail}>{optionData.content}</div>}
              </div>
            );
          })}
        </div>
      </PayViaSkeleton>
    </div>
  );
};

export default PaymentMethod;
