import React from "react";
import { pdfjs, Document, Page } from "react-pdf";
import Carousel from "Modules/carousel";
import { fileIcon } from "assets/img";
import { isImage } from "utility";

import style from "./style.module.scss";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export interface IFile {
  file: string;
  type: "pdf" | "image";
}

interface SharedProps {
  noFileText?: string;
  itemsToShow?: number;
  paginated?: boolean;
  carouselClassname?: string;
}
interface IBasicCarousel extends SharedProps {
  files: Array<string>;
  customFiles?: never;
}

interface ICustomCarousel extends SharedProps {
  files?: never;
  customFiles: Array<IFile>;
  itemPosition?: "start" | "end";
}

type ICarouselPreview = IBasicCarousel | ICustomCarousel;

const CarouselPreview = ({
  files,
  noFileText = "No files!",
  itemsToShow = 1,
  paginated = true,
  customFiles,
  carouselClassname = "",
  ...props
}: ICarouselPreview) => {
  const dataSource = customFiles || files;
  if (!dataSource || dataSource.length === 0) return <span>{noFileText}</span>;

  const renderData = (receipt: string, index: number) => {
    if (receipt.indexOf(".pdf") > 0) {
      return (
        <a key={receipt + index} rel="noreferrer" href={receipt} target="_blank">
          <Document file={receipt}>
            <Page pageNumber={1} />
          </Document>
        </a>
      );
    }

    if (isImage(receipt)) {
      return <img key={receipt + index} src={receipt} alt={`receipt ${index}`} className={style.img} />;
    }

    return (
      <a key={receipt + index} rel="noreferrer" href={receipt} target="_blank">
        <img src={fileIcon} alt={`receipt ${index}`} className="pdf-image" />
      </a>
    );
  };

  const renderCustomData = (receipt: IFile, index: number) => {
    const { file, type } = receipt;

    switch (type) {
      case "pdf":
        return (
          <Document file={file}>
            <Page pageNumber={1} />
          </Document>
        );
      case "image":
        return (
          <div className={style.imgWrapper}>
            <img key={file} src={file} alt={`receipt ${index}`} className={style.customImg} />
          </div>
        );
      default:
        return (
          <a key={file} rel="noreferrer" href={file} target="_blank">
            <img src={fileIcon} alt={`receipt ${index}`} className="pdf-image" />
          </a>
        );
    }
  };

  const getData = customFiles?.map(renderCustomData) || files.map(renderData);

  return (
    <Carousel
      data={getData}
      showArrows
      itemsToShow={itemsToShow}
      paginated={paginated}
      carouselClassname={carouselClassname}
      {...props}
    />
  );
};

export default CarouselPreview;
