import React, { useState } from "react";
import cn from "classnames";
import { ChevronDown, SingleSelect, Tags, Typography } from "@spenmo/splice";
import { tagVariant } from "@spenmo/splice/lib/shared/types";
import { currencyFormatterV2 } from "utility";
import { getCookieValue, cookieNames } from "utility/CookieHelper";

import styles from "./styles.module.scss";

export const Label = ({
  children,
  text,
  className,
}: {
  children?: React.ReactNode;
  text: string;
  className?: string;
}) => {
  return (
    <div className={cn(className)}>
      <Typography variant="body-content" size="caption-m" tag="p" className={styles.totalSpend}>
        {text}
      </Typography>
      {Boolean(children) && children}
    </div>
  );
};

export const CurrencyText = ({ amount, percentage }: { amount: number; percentage: number }) => {
  const generatePercentageVariant: () => { variant: tagVariant; label: string } = () => {
    const label = `${Number.isInteger(percentage) ? percentage : percentage.toFixed(1)}%`;
    if (percentage === 0) {
      return {
        variant: "neutral",
        label: "0%",
      };
    }

    return {
      variant: percentage > 0 ? "danger" : "success",
      label,
    };
  };

  const { variant, label } = generatePercentageVariant();

  return (
    <Typography variant="body-content" size="s" weight={600} tag="p" className={styles.currency}>
      <p>
        {currencyFormatterV2(amount, getCookieValue(cookieNames.CURRENCY_CODE), true, 2)}{" "}
        <Tags.Label variant={variant} label={label} hierarchy="default" />
      </p>
    </Typography>
  );
};

export const Dropdown = ({
  options,
  defaultValue,
  onChange,
}: {
  options: { value: string; label: string }[];
  defaultValue: string;
  onChange?: (value: string | number) => void;
}) => {
  const findLabelFromValue = (value) => {
    return options.find((option) => option.value === value).label;
  };
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(findLabelFromValue(defaultValue));

  return (
    <SingleSelect
      onSelect={(value) => {
        setSelected(findLabelFromValue(value));
        setOpen(false);
        onChange?.(value);
      }}
      size="xs"
      className={styles.overflowContent}
      open={open}
      onOpenChange={setOpen}
      options={options}
    >
      <div className={styles.actionChip}>
        <Typography variant="body-content" size="caption-m">
          {selected}
        </Typography>
        <ChevronDown size="16" fill="currentColor" />
      </div>
    </SingleSelect>
  );
};
