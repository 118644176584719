import React from "react";
import SaasAndPayments from "Views/Home/SaasAndPayments";
import SaasOnly from "Views/Home/SaasOnly";
import { useIsSaasWithPaymentsOrg } from "customHooks/useIsSaasWithPaymentsOrg";

const HomePage: React.FC = () => {
  const isSaasWithPaymentsOrg = useIsSaasWithPaymentsOrg();
  const HomePageComponent = isSaasWithPaymentsOrg ? SaasAndPayments : SaasOnly;

  return <HomePageComponent />;
};

export default HomePage;
