import React, { useEffect } from "react";
import PropTypes from "prop-types";
import "./toaster.scss";
import Icon from "../icons";
import { Link } from "react-router-dom";
import circleTick from "../../assets/img/circle-tick.svg";
import circleCross from "../../assets/img/circle-cross.svg";
import closeIcon from "../../assets/img/close-icon_bkg.svg";

export const Toaster = ({ visible = false, status, message, viewLink, closeFunc, primary, className = "" }) => {
  useEffect(() => {
    let id;

    if (visible && status === "success") {
      const TOASTER_TIMEOUT = 3000;
      id = setTimeout(() => {
        closeFunc();
      }, TOASTER_TIMEOUT);
    }

    return () => {
      id && clearTimeout(id);
    };
  }, [visible, status]);

  let classModifiers = className;
  if (status === "success") {
    classModifiers += " toaster--success";
  } else {
    classModifiers += " toaster--error";
  }
  if (primary) {
    classModifiers += " toaster--primary";
  }

  return (
    <>
      {visible && visible && message && (
        <div className={`toaster ${classModifiers}`}>
          {status === "success" ? <Icon src={circleTick} /> : <Icon src={circleCross} />}
          <p className={"message"}>{message}</p>
          {viewLink && (
            <Link to={viewLink} className="toaster--view-details">
              View Details
            </Link>
          )}
          <Icon action={closeFunc} src={closeIcon} alt={"close icon"} className={"toaster--close-icon"} />
        </div>
      )}
    </>
  );
};

Toaster.propTypes = {
  status: PropTypes.string,
  message: PropTypes.string,
  viewLink: PropTypes.string,
  closeFunc: PropTypes.func,
  visible: PropTypes.bool,
  primary: PropTypes.bool,
  className: PropTypes.string,
};
