import React from "react";

import { IRouterType } from "Route/@types";
import { COMPANY_VERIFICATION } from "Route/KYX/paths";

import KYX from "Views/KYX";
import IntroPage from "Views/KYX/Intro";
import KYXStates from "Views/KYX/KYXStates";
import KYXProgress from "Views/KYX/Progress";
import { KYB_STATUS, KYX_STATES } from "Views/KYX/Constants";
import JointApplicantWelcome from "Views/KYX/Intro/Start/JointApplicant/Welcome";
import JointApplicantThankyou from "Views/KYX/Intro/Start/JointApplicant/Thankyou";
import JointApplicantSessionExpired from "Views/KYX/Intro/Start/JointApplicant/URLSessionExpired";
import { JointApplicantForm } from "Views/KYX/Progress/JointApplicant";
import JointApplicantInvalidToken from "Views/KYX/Intro/Start/JointApplicant/InvalidToken";
import MyInfoCallBack from "Views/KYX/MyInfo";
import JointApplicantCallBack from "Views/KYX/MyInfo/JointApplicant";

export const OnboardingRoute: IRouterType[] = [
  {
    id: "verification-1",
    path: COMPANY_VERIFICATION.WELCOME,
    component: (
      <KYX>
        <IntroPage />
      </KYX>
    ),
    title: "Welcome Page",
    authRequired: true,
    exactPath: false,
    isOnboardingRequired: true,
    acceptedStatuses: [KYB_STATUS.NOT_STARTED],
  },
  {
    id: "verification-2",
    path: COMPANY_VERIFICATION.WELCOME_BACK,
    component: (
      <KYX>
        <IntroPage />
      </KYX>
    ),
    title: "Welcome Back Page",
    authRequired: true,
    exactPath: false,
    isOnboardingRequired: true,
    acceptedStatuses: [KYB_STATUS.STARTED],
  },
  {
    id: "verification-3",
    path: COMPANY_VERIFICATION.ADDITIONAL_DOCUMENTS_REQUIRED,
    component: (
      <KYX>
        <IntroPage />
      </KYX>
    ),
    title: "Additional Documents Required",
    authRequired: true,
    exactPath: false,
    isOnboardingRequired: true,
    acceptedStatuses: [KYB_STATUS.ADDITIONAL_DOCUMENTS_REQUIRED],
  },
  {
    id: "verification-4",
    path: COMPANY_VERIFICATION.COMPANY_INFORMATION,
    component: (
      <KYX>
        <KYXProgress />
      </KYX>
    ),
    title: "Company information",
    authRequired: true,
    exactPath: false,
    isOnboardingRequired: true,
    acceptedStatuses: [KYB_STATUS.STARTED, KYB_STATUS.NOT_STARTED, KYB_STATUS.ADDITIONAL_DOCUMENTS_REQUIRED],
  },
  {
    id: "verification-5",
    path: COMPANY_VERIFICATION.COMPANY_DOCUMENTS,
    component: (
      <KYX>
        <KYXProgress />
      </KYX>
    ),
    title: "Company Documents",
    authRequired: true,
    exactPath: false,
    isOnboardingRequired: true,
    acceptedStatuses: [KYB_STATUS.STARTED, KYB_STATUS.ADDITIONAL_DOCUMENTS_REQUIRED],
  },
  {
    id: "verification-6",
    path: COMPANY_VERIFICATION.PERSONNEL_INFORMATION,
    component: (
      <KYX>
        <KYXProgress />
      </KYX>
    ),
    title: "Personal information",
    authRequired: true,
    exactPath: false,
    isOnboardingRequired: true,
    acceptedStatuses: [KYB_STATUS.STARTED, KYB_STATUS.ADDITIONAL_DOCUMENTS_REQUIRED],
  },
  {
    id: "verification-7",
    path: COMPANY_VERIFICATION.SUBMITTED,
    component: (
      <KYX>
        <KYXStates type={KYX_STATES.SUBMITTED} />
      </KYX>
    ),
    title: "KYB Submitted",
    authRequired: true,
    exactPath: false,
    isOnboardingRequired: true,
    acceptedStatuses: [KYB_STATUS.SUBMITTED],
  },
  {
    id: "verification-8",
    path: COMPANY_VERIFICATION.IN_REVIEW,
    component: (
      <KYX>
        <KYXStates type={KYX_STATES.IN_REVIEW} />
      </KYX>
    ),
    title: "KYB In Review",
    authRequired: true,
    exactPath: false,
    isOnboardingRequired: true,
    acceptedStatuses: [KYB_STATUS.IN_REVIEW, KYB_STATUS.COMPLIANCE_REVIEW, KYB_STATUS.ASSESSMENT_IN_PROGRESS],
  },
  {
    id: "verification-9",
    path: COMPANY_VERIFICATION.APPROVED,
    component: (
      <KYX>
        <KYXStates type={KYX_STATES.APPROVED} />
      </KYX>
    ),
    title: "KYB Approved",
    authRequired: true,
    exactPath: false,
    isOnboardingRequired: true,
    acceptedStatuses: [KYB_STATUS.APPROVED],
  },
  {
    id: "verification-10",
    path: COMPANY_VERIFICATION.REJECTED,
    component: (
      <KYX>
        <KYXStates type={KYX_STATES.REJECTED} />
      </KYX>
    ),
    title: "KYB Rejected",
    authRequired: true,
    exactPath: false,
    isOnboardingRequired: true,
    acceptedStatuses: [KYB_STATUS.REJECTED],
  },
  {
    id: "verification-11",
    path: COMPANY_VERIFICATION.MY_INFO_BIZ,
    component: (
      <KYX showHeader={false}>
        <MyInfoCallBack />
      </KYX>
    ),
    title: "My Info Callback Page",
    authRequired: true,
    exactPath: false,
    isOnboardingRequired: true,
    acceptedStatuses: [KYB_STATUS.NOT_STARTED, KYB_STATUS.STARTED],
  },
  {
    id: "verification-12",
    path: COMPANY_VERIFICATION.JOINT_APPLICANT,
    component: (
      <KYX infoTitle={"Joint Applicant Data Verification"} showLogout={false}>
        <JointApplicantWelcome />
      </KYX>
    ),
    title: "My Info Callback Page",
    authRequired: false,
    exactPath: true,
  },
  {
    id: "verification-13",
    path: COMPANY_VERIFICATION.THANKYOU_PAGE,
    component: (
      <KYX infoTitle={"Joint Applicant Data Verification"} showLogout={false}>
        <JointApplicantThankyou />
      </KYX>
    ),
    title: "My Info Callback Page",
    authRequired: false,
    exactPath: true,
  },
  {
    id: "verification-14",
    path: COMPANY_VERIFICATION.SESSION_EXPIRED,
    component: (
      <KYX infoTitle={"Joint Applicant Data Verification"} showLogout={false}>
        <JointApplicantSessionExpired />
      </KYX>
    ),
    title: "My Info Callback Page",
    authRequired: false,
    exactPath: true,
  },
  {
    id: "verification-15",
    path: COMPANY_VERIFICATION.JOINT_APPLICANT_FORM,
    component: (
      <KYX infoTitle={"Joint Applicant Data Verification"} showLogout={false}>
        <JointApplicantForm />
      </KYX>
    ),
    title: "Joint Applicant Form",
    authRequired: false,
    exactPath: true,
    isOnboardingRequired: true,
    acceptedStatuses: [],
  },
  {
    id: "verification-15",
    path: COMPANY_VERIFICATION.JOINT_APPLICANT_CALLBACK,
    component: <JointApplicantCallBack />,
    title: "My Info callback page",
    authRequired: false,
    exactPath: true,
    isOnboardingRequired: true,
    acceptedStatuses: [],
  },
  {
    id: "verification-16",
    path: COMPANY_VERIFICATION.INVALID_TOKEN,
    component: (
      <KYX infoTitle={"Joint Applicant Data Verification"} showLogout={false}>
        <JointApplicantInvalidToken />
      </KYX>
    ),
    title: "Joint Applicant Form",
    authRequired: false,
    exactPath: true,
  },
];
