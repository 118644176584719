import * as React from "react";
import { Select as AntdSelect } from "antd";
import { GetCardApprovers } from "Redux/DataCalls/Cards.api";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import Select from "Views/Card/Components/Select";
import "Views/Card/CreateOrRequest/Steps/index.scss";

const { Option } = AntdSelect;

interface ApproverSelectorProps {
  selectedApproverId: string;
  selectedTeamId: string;
  onSelect: (id: string, name: string) => void;
  title: string;
  infoText: string;
}

const ApproverSelector = ({ selectedApproverId, selectedTeamId, onSelect, title, infoText }: ApproverSelectorProps) => {
  const [approvers, setApprovers] = React.useState([]);

  const fetchAndSetApprovers = async () => {
    const response = await GetCardApprovers(selectedTeamId);
    if (response?.status === HTTP_STATUS_CODE.OK) {
      const approversFromApi = response?.data?.payload?.team?.approvers || [];
      const formattedApprovers = Array.isArray(approversFromApi)
        ? approversFromApi?.map((member) => ({
            key: member?.id,
            value: member?.id,
            id: member?.id,
            label: member?.name,
          }))
        : [];
      if (formattedApprovers.length === 1) {
        const { id, label } = formattedApprovers[0];
        onSelect(id, label);
      }
      setApprovers(formattedApprovers);
    }
  };

  React.useEffect(() => {
    selectedTeamId && fetchAndSetApprovers();
  }, [selectedTeamId]);

  return (
    <>
      {approvers?.length > 1 ? (
        <>
          <p className="create-or-request-card-steps__input-label sp-margin-top--xl">{title}</p>
          <Select
            value={(approvers.length && selectedApproverId) || undefined}
            placeholder="Select Budget Owner"
            onSelect={(id, option) => onSelect(id, option?.label?.toString() || "")}
            filterOption={(input, option) =>
              option?.label?.toString()?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
            }
            dropdownClassName="create-or-request-card-steps__dropdown"
            showSearch
            getPopupContainer={(triggerNode) => triggerNode.parentElement}
            data-testid="approver-selector"
          >
            {approvers?.map((member) => (
              <Option key={member.id} value={member.id} id={member.id} label={member.label}>
                {member.label}
              </Option>
            ))}
          </Select>
          <p className="create-or-request-card-steps__help-text">{infoText}</p>
        </>
      ) : null}
    </>
  );
};

export default ApproverSelector;
