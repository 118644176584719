import React, { useState, useEffect } from "react";
import { Input } from "antd";
import { Cookies } from "react-cookie";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { getTeamProfileData, getAllMembersFunc } from "Redux/Actions";
import { createTeam } from "Redux/Actions/Teams/createTeamAction";
import { Checkbox, Typography } from "@spenmo/splice";

import { TEAMS_PATH } from "constants/Routes.constants";
import { DEFAULT_LIMIT_VALUES } from "constants/Team.constant";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import { onlyPositiveIntegersWithFractionsRegEx } from "constants/RegularExpressions.constant";

import { useIsSessionExpired } from "customHooks/Auth/useIsSessionExpired";

import Button from "Modules/button";
import { Toaster } from "Modules/toaster";
import LetterAvatar from "Modules/letterAvatar";
import SelectMembers from "Modules/SelectMembers";
import LoaderIcon from "Views/State/Loading/LoaderIcon";

import usePermissionCheck from "Permission/usePermissionCheck";
import { useIsSaasWithPaymentsOrg } from "customHooks/useIsSaasWithPaymentsOrg";
import { CASBIN_PAGES } from "Permission/Pages";
import { TEAMS_PAGE } from "Permission/Actions";
import OverlayLoader from "Modules/overlayLoader";
import { trackActionsOnTeamsPage, TeamProfileEventsMapping } from "Views/Team/Profile/TeamProfileEventsUtilites";

import "./teamCreate.scss";
import { getIsCardVendroMatchMove } from "customHooks/Card";

const CreateTeam = () => {
  const permissionParam = [{ object: CASBIN_PAGES.TEAMS_PAGE, action: TEAMS_PAGE.CREATE_NEW_TEAM_BUTTON }];
  const [IS_ALLOWED_CREATE_NEW_TEAM, loading] = usePermissionCheck(permissionParam, true);
  const isPaymentEnabled = useIsSaasWithPaymentsOrg();

  const isSessionExpired = useIsSessionExpired();
  const cookies = new Cookies();
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [amount, setAmount] = useState("");
  const [interval, setInterval] = useState("monthly_limit");
  const [teamManager, setTeamManager] = useState(null);
  const [agreeToTnc, setAgreeToTnc] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");
  const [addTeamStatus, setAddTeamStatus] = useState("");
  const currencyCode = cookies.get("currency_code") || "";
  const { data } = useSelector((state) => state.membersReducer);

  const teamData = useSelector((state) => state?.teamReducer);
  const [showToaster, setToasterVisibility] = useState(false);
  const createdTeamData = teamData?.createData?.payload;
  const { team_name = name, id } = createdTeamData || {};
  const createTeamLoading = useSelector((state) => state.teamReducer?.loading);
  let history = useHistory();

  useEffect(() => {
    if (!loading && !IS_ALLOWED_CREATE_NEW_TEAM && !isSessionExpired) {
      history.push("/404");
    }
  }, [IS_ALLOWED_CREATE_NEW_TEAM, loading]);

  useEffect(() => {
    if (teamData?.createData?.status === HTTP_STATUS_CODE.OK) {
      setToasterVisibility(true);
      dispatch(getTeamProfileData(id));
      setAddTeamStatus("success");
      setStatusMessage(`You have successfully added ${team_name} Budget.`);
    }
    if (teamData?.errorMessage?.status > HTTP_STATUS_CODE.OK) {
      const errorMessage = teamData?.errorMessage?.payload?.status_message;
      setAddTeamStatus("error");
      setToasterVisibility(true);
      setStatusMessage(errorMessage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamData, team_name]);

  useEffect(() => {
    if (teamData?.createData?.status === HTTP_STATUS_CODE.OK && addTeamStatus === "success" && showToaster) {
      setTimeout(() => {
        history.push(`/teamprofile/${teamData?.createData?.payload?.id}`);
      }, 1400);
    }
  }, [teamData, showToaster, history, addTeamStatus]);

  useEffect(() => {
    dispatch(
      getAllMembersFunc({
        exclude_default_users: true,
        include_non_wallet: true,
      })
    );
  }, [dispatch]);
  const intervalsData = [
    { name: "Monthly", id: "monthly_limit" },
    { name: "Unlimited", id: "none" },
  ];

  const backToTeamsOnClickHandler = () => {
    history.action === "PUSH" ? history.goBack() : history.push(TEAMS_PATH);
    trackActionsOnTeamsPage(TeamProfileEventsMapping.backToTeamsClicked);
  };

  const handleCreateTeam = () => {
    const payload = {
      name: name,
      managerID: teamManager[0].id,
      monthlyLimit:
        (interval === "monthly_limit" && amount?.toString()?.replace?.(/\.$/, "")) ||
        DEFAULT_LIMIT_VALUES.REVERT_THE_LIMIT,
      yearlyLimit: DEFAULT_LIMIT_VALUES.REVERT_THE_LIMIT,
      totalLimit: DEFAULT_LIMIT_VALUES.REVERT_THE_LIMIT,
    };
    dispatch(createTeam(payload));
  };

  const checkData = () => {
    let limit = interval === "none" ? false : !amount;
    return !name || !interval || !teamManager?.length || limit || (isPaymentEnabled && !agreeToTnc);
  };

  const onTeamLimitChange = (event) => {
    const { value = "" } = event?.target || {};
    if (onlyPositiveIntegersWithFractionsRegEx?.test?.(value) || value === "") {
      setAmount(value);
    }
  };

  return loading ? (
    <LoaderIcon />
  ) : (
    <div className={"team-create-wrapper"}>
      {createTeamLoading && <OverlayLoader show />}
      <button className="team-create-wrapper--back-teams" onClick={backToTeamsOnClickHandler}>
        Back to Budgets
      </button>
      <div className="team-create-wrapper__title-area">
        <h1>Create a New Budget</h1>
      </div>
      <div className="create-form-container">
        <div className="create-form-card">
          <div className="team-create-wrapper__name">
            <div className="team-create-wrapper__name-first-letter">
              <LetterAvatar bigSquare name={name || "B"} />
            </div>
            <div className="team-create-wrapper__name-text-field">
              <h2>Budget Name</h2>
              <Input
                value={name}
                placeholder={"Enter budget name"}
                id={"team_name"}
                className={"team-name__input"}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
          </div>
          <h2>Budget Limit</h2>
          {isPaymentEnabled && (
            <div>
              <p className="info-text">
                {`Cards created under this budget will follow the budget limit. ${
                  !getIsCardVendroMatchMove()
                    ? "The limit also applies to Reimbursements and Bill Payments from the budget."
                    : ""
                }`}
              </p>
            </div>
          )}
          {intervalsData.length > 0 && (
            <div className="interval-container">
              {intervalsData.map((item, index) => (
                <div
                  className={`team-create-wrapper__interval ${interval !== "" && interval == item.id && "active"}`}
                  key={index}
                  onClick={() => {
                    setInterval(item.id);
                  }}
                >
                  {item.name}
                </div>
              ))}
            </div>
          )}

          {interval !== "none" && (
            <div className="currency-section">
              <div className="currency-label">{currencyCode}</div>
              <Input
                disabled={interval === "none"}
                value={amount}
                placeholder={"Set amount"}
                className={"team-limit__input"}
                onChange={onTeamLimitChange}
              />
            </div>
          )}
          {interval === "none" ? (
            <div className="info-text-footer">Unlimited means this budget does not have any spending limits.</div>
          ) : (
            <div className="info-text-footer">Monthly Budget Limit means the budget limit will reset every month.</div>
          )}
        </div>
        <div className={`create-form-card manager-select-card`}>
          <div className="header-text">Select Budget Owner</div>
          <p className="header-sub-text">
            Budget owners can add and remove members, promote and demote members, create cards for members and view the
            budget info.
          </p>
          <SelectMembers
            singleSelection={true}
            allEmployees={data || []}
            checkDuplicates
            subTitle={" "}
            receivedTeamMembers={[]}
            onChange={(data) => setTeamManager(data)}
            isTeam={false}
          />
        </div>
      </div>
      {isPaymentEnabled && (
        <div className="team-create-checkbox-wrapper">
          <Checkbox onChange={(e) => setAgreeToTnc(e.target.checked)}>
            <Typography variant="body-content" size="caption-m" className="team-create-checkbox-label">
              I am aware that All Budget owners can approve/issue cards under their respective Budgets. I further agree
              to the{" "}
              <a href="https://spenmo.com/legal" target="_blank" rel="noreferrer" className="team-create-link">
                terms & conditions
              </a>{" "}
              applicable to the issuance of cards to the relevant designated users.
            </Typography>
          </Checkbox>
        </div>
      )}
      <div className="create-team-btn">
        <Button rounded disabled={checkData()} action={() => handleCreateTeam()}>
          Create New Budget
        </Button>
      </div>
      <Toaster
        status={addTeamStatus}
        message={statusMessage}
        visible={showToaster}
        closeFunc={() => setToasterVisibility(false)}
      />
    </div>
  );
};

export default CreateTeam;
