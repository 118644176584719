import * as React from "react";
import { useSelector } from "react-redux";
import { AlignType } from "rc-table/lib/interface";

import { IEmployee } from "Redux/Actions/Employee/Get/Employees/types";

import { IColumnData } from "Modules/DS/DataTable";

import KycItem from "Views/Employee/Kyc/Item";
import KycTitle from "Views/Employee/Kyc/Title";
import DeleteButton from "Views/Employee/Delete/Button";
import UpdateRoleItem from "Views/Employee/Update/Role/Item";
import RoleTitle from "Views/Employee/RoleExplanationModal/title";

import usePermissionCheck from "Permission/usePermissionCheck";
import { CASBIN_PAGES } from "Permission/Pages";
import { EMPLOYEES_PAGE } from "Permission/Actions";

const Columns = (isSaasAndPaymentsOrg: boolean): IColumnData<IEmployee>[] => {
  const isAdmin: boolean = useSelector((state: any) => state.userInfoReducer?.isAdmin) || false;
  const permissionParam = [{ object: CASBIN_PAGES.EMPLOYEES_PAGE, action: EMPLOYEES_PAGE.CHANGE_ROLE_DROPDOWN }];
  const IS_ALLOWED_CHANGE_ROLE_DROPDOWN: any = usePermissionCheck(permissionParam);

  return [
    {
      id: "EMP-COL-1",
      enabled: true,
      column: {
        key: "name",
        title: "Name",
        dataIndex: "name",
        render: (name: string): React.ReactNode => <p className="employee-list__name">{name}</p>,
      },
    },
    {
      id: "EMP-COL-2",
      enabled: true,
      column: {
        key: "email",
        title: "Email",
        width: isSaasAndPaymentsOrg ? undefined : 420,
        dataIndex: "email",
      },
    },
    {
      id: "EMP-COL-3",
      enabled: isSaasAndPaymentsOrg,
      column: {
        key: "kyc",
        title: <KycTitle />,
        dataIndex: "kyc",
        render: (status: string): React.ReactNode => <KycItem status={status} />,
      },
    },
    {
      id: "EMP-COL-4",
      enabled: IS_ALLOWED_CHANGE_ROLE_DROPDOWN,
      column: {
        key: "role_id",
        title: <RoleTitle />,
        dataIndex: "role_id",
        width: 300,
        render: (roleId: number, record: IEmployee): React.ReactNode => (
          <UpdateRoleItem roleId={roleId} record={record} />
        ),
      },
    },
    {
      id: "EMP-COL-5",
      enabled: isAdmin,
      column: {
        key: "id",
        title: "Action",
        dataIndex: "id",
        align: "center" as AlignType,
        render: (id: string, record: IEmployee): React.ReactNode => <DeleteButton key={id} accountRecord={record} />,
      },
    },
  ].filter((item) => item.enabled);
};

export default Columns;
