import { Pending } from "./Pending";
import { Settlement } from "./Settlement";
import { SingleLine, MultiLine, SingleLineWithLink } from "./Pending/Type";

export const Requirement = {
  Pending,
  Settlement,
};

export const PendingType = {
  SingleLine,
  MultiLine,
  SingleLineWithLink,
};
