import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import DataTable, {
  ActionsBar,
  Table,
  ActionButtonType,
  ALIGN_ACTION_ITEM,
  BulkActionType,
  DATA_TABLE_SIZE_TYPES,
  IActionItem,
  DATA_TABLE_PADDING,
} from "Modules/DS/DataTable";
import Toaster from "Modules/DS/Toaster";
import { TOASTER_SIZE_TYPE, TOASTER_STATUS_TYPE } from "Modules/DS/Toaster/types.d";
import {
  dataMapping,
  fetchImportBillFromXero,
  filterBill,
} from "Redux/Reducers/ImportBillFormXero/importBillFromXero.slice";
import { accountingPartnerAuth } from "Redux/Actions";
import { importBillToSpenmo } from "Redux/DataCalls/ImportBillFromXero.api";
import { SPLIT_NAMES, SPLIT_TREATMENT_TYPES } from "Redux/splitio/constants";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import usePermissionCheck from "Permission/usePermissionCheck";
import LoadingComponent from "Views/State/Loading/LoaderIcon";
import useCheckFeatureStatus from "customHooks/featureCheck";
import Filter from "./Filter";
import ProgressModal from "./ProgressModal";
import Banner from "./Banner";
import { IBill, IImportBillToDrafts, IOriginalBillData } from "./type";
import { columnData } from "./ColumnData";
import { checkBillStatus, findBillInCurrentPage, generateCustomEmptyState } from "./helper";
import { BILL_PAGE_PERMISSION_PARAMS } from "../Permission";
import styles from "./styles.module.scss";
import ImportBillsToDraftsModal from "./ImportBillsToDraftsModal";
import { mapXeroBillsToSpenmo } from "./DataCalls";

const PAGE_SIZE = 10;

const ImportBillFromXero = () => {
  const history = useHistory();
  const [IS_ALLOWED_SUBMIT_BILL_PAYMENT, loadingPermission] = (usePermissionCheck(BILL_PAGE_PERMISSION_PARAMS, true) ||
    []) as [boolean, boolean];
  const importBillFromXeroTreatment = useCheckFeatureStatus(SPLIT_NAMES.importBillFromXero, true);
  const isEnabledImportBillFromXero = importBillFromXeroTreatment === SPLIT_TREATMENT_TYPES.ON;
  const loadingTreatment = importBillFromXeroTreatment === SPLIT_TREATMENT_TYPES.CONTROL;

  const [selectedIds, setSelectedIds] = useState([]);
  const [page, setPage] = useState(0);
  const [toaster, setToaster] = useState({
    show: false,
    message: "",
    type: TOASTER_STATUS_TYPE.SUCCESS,
  });
  const dispatch = useDispatch();
  const { data, loading, error, billResponse } = useSelector((state: any) => state.importBillFromXeroReducer);
  const { data: xeroData, loading: xeroLoading } = useSelector((state: any) => state.accountingPartnerAuthReducer);
  const connectedUsingBankfeed = xeroData?.payload?.bankfeed;
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [importBillsToDraftsModal, setImportBillsToDraftsModal] = useState<IImportBillToDrafts>({
    show: false,
    mappedBills: [],
  });
  const [importBillsToDraftsLoading, setImportBillsToDraftsLoading] = useState(false);

  useEffect(() => {
    if (showProgressBar || importBillsToDraftsModal.show) document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [showProgressBar, importBillsToDraftsModal]);

  const onApplyFilter = (filter) => {
    setPage(0);
    dispatch(filterBill(filter));
  };

  const action: IActionItem[] = [
    {
      id: "IMPORT_BILL_FROM_XERO_001",
      enabled: true,
      align: ALIGN_ACTION_ITEM.LEFT,
      component: <Filter onApplyFilter={onApplyFilter} />,
    },
  ];

  const checkXeroBillMappingsToSpenmo = async () => {
    try {
      setShowProgressBar(true);
      const bills = billResponse.filter((bill: IOriginalBillData) => selectedIds.includes(bill.InvoiceID));
      const { data: mappingResponseData } = await mapXeroBillsToSpenmo(bills);
      if (mappingResponseData.status === HTTP_STATUS_CODE.OK) {
        const mappedBills = mappingResponseData?.payload?.bills?.length
          ? (mappingResponseData.payload.bills.map((bill, index) => dataMapping(bill, index)) as IBill[])
          : [];
        setImportBillsToDraftsModal({ show: true, mappedBills });
        setShowProgressBar(false);
      } else {
        setShowProgressBar(true);
        setErrorMessage(mappingResponseData?.payload?.status_message);
      }
    } catch (err) {
      setShowProgressBar(true);
      setErrorMessage(err?.message || "Something went wrong");
    }
  };

  const tryAgainBtnClickHandler = () => {
    setErrorMessage("");
    checkXeroBillMappingsToSpenmo();
  };

  const importBill = async () => {
    if (importBillsToDraftsLoading) return;
    try {
      const bills = billResponse.filter((bill) => selectedIds.includes(bill.InvoiceID));
      setImportBillsToDraftsLoading(true);
      const { data } = await importBillToSpenmo(bills);
      const progressId = data?.payload?.progress_id;
      if (data.status === HTTP_STATUS_CODE.OK) {
        await checkBillStatus(progressId);
        setSelectedIds([]);
        setImportBillsToDraftsModal({ show: false, mappedBills: [] });
        history.push("/bills/drafts");
      } else {
        setToaster({
          message: data?.payload?.status_message,
          show: true,
          type: TOASTER_STATUS_TYPE.ERROR,
        });
        setImportBillsToDraftsLoading(false);
      }
    } catch (err) {
      setImportBillsToDraftsLoading(false);
      setSelectedIds([]);
      setToaster({
        message: err?.message || "Something went wrong",
        show: true,
        type: TOASTER_STATUS_TYPE.ERROR,
      });
    }
  };

  const tableProps = {
    total: !loading && data.length,
    loading: loading || loadingPermission,
    customEmptyState: generateCustomEmptyState(loading, data, error, {
      onRetry: () => dispatch(fetchImportBillFromXero()),
    }),
    pageNum: page,
    pageSize: PAGE_SIZE,
    onPageChange: (page) => {
      setPage(page - 1);
      setSelectedIds([]);
    },
    dataSource: !loading && data,
    bulkAction: !showProgressBar &&
      !importBillsToDraftsModal.show &&
      !loading && {
        alwaysShown: true,
        emptySelectionMessage: "Select 1 or more bill(s) to continue",
        data: selectedIds,
        dataName: "bill",
        clearSelection: () => setSelectedIds([]),
        firstButton: {
          type: ActionButtonType.Primary,
          label: "Import Bills as Drafts",
          handler: checkXeroBillMappingsToSpenmo,
          disabled: selectedIds.length === 0,
        },
        supportingInfo: null,
        type: BulkActionType.Default,
      },
    rowSelection: {
      preserveSelectedRowKeys: true,
      selectedRowKeys: selectedIds,
      onChange: setSelectedIds,
      onSelectAll: (selectAll) => {
        const selectedBill = findBillInCurrentPage(page, PAGE_SIZE, data);
        setSelectedIds(!selectAll ? [] : selectedBill.map((item) => item.id));
      },
    },
    paginationOnBottom: false,
    paginationOnTop: true,
    scrollHorizontal: "fit-content",
  };

  useEffect(() => {
    dispatch(fetchImportBillFromXero());
    dispatch(accountingPartnerAuth());
  }, [dispatch]);

  if (xeroLoading || loadingPermission || loadingTreatment) {
    return <LoadingComponent />;
  }

  if (!IS_ALLOWED_SUBMIT_BILL_PAYMENT || !connectedUsingBankfeed || !isEnabledImportBillFromXero) {
    return <Redirect to="/404" />;
  }

  return (
    <DataTable
      className={styles.importBillFromXero}
      size={DATA_TABLE_SIZE_TYPES.LARGE}
      padding={DATA_TABLE_PADDING.GRID}
    >
      <ProgressModal
        errorMessage={errorMessage}
        visible={showProgressBar}
        tryAgainBtnClickHandler={tryAgainBtnClickHandler}
      />
      <header className={styles.header}>
        <h2 className={styles.title}>Bill Payments</h2>
      </header>
      {!loading && <Banner count={data.length} />}
      <ActionsBar>{action}</ActionsBar>
      <Table<IBill> {...tableProps} className={styles.importBillsTable}>
        {columnData()}
      </Table>
      <ImportBillsToDraftsModal
        visible={importBillsToDraftsModal.show}
        mappedBills={importBillsToDraftsModal.mappedBills}
        setImportBillsToDraftsModal={setImportBillsToDraftsModal}
        importBillsToDraftsLoading={importBillsToDraftsLoading}
        importToDraftsClickHandler={importBill}
      />
      <Toaster
        size={TOASTER_SIZE_TYPE.M}
        visible={toaster.show}
        status={toaster.type}
        message={toaster.message}
        onClose={() => setToaster({ ...toaster, show: false })}
      />
    </DataTable>
  );
};

export * from "./type.d";

export default ImportBillFromXero;
