export const xeroLogo = require("./xeroLogo.svg");
export const zendeskLogo = require("./zendesk-logo.svg");
export const company_logo = require("./logo_name_orange.svg");
export const spenmoNameLogo = require("./spenmo-name-logo.svg");
export const company_logo_initial = require("./logo_orange.svg");
export const spenmoIcon = require("assets/icons/spenmo-icon.svg");
export const spenmoLogo_white = require("./spenmo_logo_white.svg");
export const spenmoLogoOrange = require("./spenmo-logo-orange.svg");
export const spenmoLogoOrangeCompact = require("./spenmo-logo-orange-compact.svg");
export const visaIcon = require("./icons/visa-icon.svg");

export const vendor_logo = require("./mastercardLogo.svg");

export const emailSent = require("./email-sent.svg");
export const errorIcon = require("./error-icon.svg");
export const comingSoon = require("./coming_soon.svg");
export const loginBackground = require("./login_bg.svg");
export const successIcon = require("./success-icon.svg");
export const fileNotFound = require("./file_not_found.svg");
export const sideBanner = require("./banner/sideBanner.svg");
export const teamIcon = require("./icons/team-icon.svg");
export const friendsIcon = require("./icons/people-icon.svg");
export const emptyCards = require("./empty-cards.svg");
export const uploadImageIcon = require("./icons/upload-image-icon.svg");
export const successUploadIcon = require("./icons/success-upload-icon.svg");
export const pngFileType = require("./upload/png-file-type.svg");
export const jpgFileType = require("./upload/jpg-file-type.svg");
export const heicFileType = require("./upload/heic-heif-file-type.svg");
export const pdfFileType = require("./upload/pdf-file-type.svg");

export const userIcon = require("./icons/user.svg");
export const peopleIcon = require("./icons/people.svg");
export const paylahIcon = require("./icons/paylah.svg");
export const sendMoney = require("./send-money.svg");
export const requestMoney = require("./request-money.svg");
export const paylahOrange = require("./paylah-orange.svg");
export const cameraIcon = require("assets/icons/camera.svg");
export const queuedIcon = require("./queuedIcon.svg");
export const syncedIcon = require("./syncedIcon.svg");
export const failedIcon = require("./failedIcon.svg");
export const pendingIcon = require("./pendingIcon.svg");
export const receiptsIcon = require("./icons/receipts.svg");
export const receiptsGreyIcon = require("./receipts-grey.svg");
export const chatIcon = require("./chat-icon.svg");
export const chatArticleIcon = require("./chat-article-icon.svg");
export const upload = require("./upload.svg");
export const filterIcon = require("./filter-icon.svg");
export const saasIcon = require("./icons/saasIcon.svg");
export const fundingIcon = require("./icons/fundingIcon.svg");
export const cashReimburseIcon = require("./cashReimburse.svg");
export const bankAccGrey = require("./bankAccGrey.svg");
export const bankAccActive = require("./bankAccActive.svg");
export const spendMoneyIcon = require("./icons/spendMoneyIcon.svg");
export const creditcardIcon = require("./icons/creditcardIcon.svg");
export const creditcardIconInactive = require("./icons/creditcardIcon-inactive.png");
export const paynowActive = require("./paynow-active.svg");
export const paynowInActive = require("./paynow-inactive.svg");
export const pdfView = require("./pdf.svg");
export const fileIcon = require("./file.svg");
export const CSVIcon = require("./csv-icon.svg");
export const RawIcon = require("./raw-icon.svg");
export const search = require("./search.svg");
export const searchBlack50 = require("./search-black-50.svg");
export const refresh = require("./refresh.svg");
export const copy_icon = require("./copyIcon.svg");
export const lockIcon = require("./lock_icon.svg");
export const viewAll = require("./icons/viewall.svg");
export const leftArrowOrange = require("./leftArrowOrange.svg");
export const left_arrow = require("./leftArrow.svg");
export const rightArrowDark = require("./right-arrow-dark.svg");
export const right_arrow = require("./right_arrow.svg");
export const left_arrow_grey = require("./left-arrow-grey.svg");
export const leftArrowWhite = require("assets/icons/left-arrow-white.svg");
export const rightArrowWhite = require("assets/icons/right-arrow-white.svg");
export const leftArrowGrey = require("assets/icons/left-arrow-grey.svg");
export const rightArrowGrey = require("assets/icons/right-arrow-grey.svg");
export const carouselLeftIcon = require("./carouselLeftIcon.svg");
export const carouselRightIcon = require("./carouselRightIcon.svg");
export const downArrowWhite = require("./down-arrow-white.svg");
export const downArrowIconGrey = require("./icons/downArrowIconGrey.svg");
export const circleClose = require("./circle-close.svg");
export const greyCloseIcon = require("./close-grey.svg");
export const darkGreyCloseIcon = require("./close-icon.svg");
export const lightGreyCloseIcon = require("./close-light_grey.svg");
export const closeIconWhite = require("assets/icons/close-white.svg");
export const plusIcon = require("./plus.svg");
export const plusIconOrange = require("./plusIconOrange.svg");
export const plusIconDark = require("./plus-dark.svg");
export const minusIcon = require("./minus.svg");
export const rightArrowLg = require("./right_arrow_lg.svg");
export const downArrowLg = require("./downArrowLg.svg");
export const rightArrowWhiteLg = require("./rightArrowWhiteLg.svg");
export const downloadIcon = require("assets/icons/download-icon.svg");
export const whiteDownloadIcon = require("./download.svg");
export const itemsStacked = require("./itemsStacked.svg");
export const blueInfoCircle = require("./blue-info-circle.svg");
export const infoIcon = require("./info.svg");
export const exclamationMarkWithCircle = require("./exclamationIcon.svg");
export const editIcon = require("./editIcon.svg");
export const checklistBlue = require("./checklist-blue.svg");
export const checklistWhite = require("./checklist-white.svg");
export const deleteIcon = require("./icons/deleteIcon.svg");
export const greenTickIcon = require("./icons/green-tick-icon16x16.svg");
export const greenTickBoldIcon = require("./icons/green-tick-bold-icon.svg");
export const rightArrowWhite16x16 = require("./icons/right-arrow-white-16x16.svg");
export const termsNConditions = require("./tnc-inactive.svg");
export const termsNConditionsActive = require("./tnc-active.svg");
export const enterLeft = require("./enterLeft.svg");

export const uploadInvoice = require("./uploadInvoice.svg");
export const uploadCompleted = require("./uploadCompleted.svg");
export const uploadCompletedWithError = require("./uploadCompletedWithError.svg");
export const showItems = require("./showItems.svg");
export const hideItems = require("./hideItems.svg");
export const changeFile = require("./changeFile.svg");
export const deleteFile = require("./deleteFile.svg");
export const billsEmail = require("./bills-email.svg");
export const billsMultiUpload = require("./bills-multiple-upload.svg");
export const billsUploadBulk = require("./bills-uploadBulk.svg");
export const billsUploadInvoice = require("./bills-uploadInvoice.svg");
export const billsEmailIcon = require("./bills-emailIcon.svg");
export const billsImportFromXero = require("./bill-importFromXero.svg");
export const uploadReceiptIcon = require("./uploadReceiptIcon.svg");

export const cardCircleIcon = require("../img/card-circle-icon.svg");
export const orangeYellowGradientBG = require("../img/orange-yellow-gradient-bg.svg");
export const spenmoIconBordered = require("../img/spenmo-icon-bordered.svg");
export const oneURLQRCode = require("../img/one-url-qr-code.png");
export const googlePlayBtn = require("../img/google-play-btn.svg");
export const appStoreBtn = require("../img/app-store-btn.svg");
export const tickMarkGreenIcon = require("./icons/tick-mark-green-16x12.svg");
export const lockOrangeIcon = require("./icons/lock-orange-48x48.svg");
export const noSmsOrangeIcon = require("./icons/no-sms-orange-48x48.svg");
export const noInternetOrangeIcon = require("./icons/no-internet-orange-48x48.svg");

export const infoFilled = require("./ic_info.svg");
export const infoOutline = require("./ic_info_outline.svg");
export const infoOutlineGrey = require("./ic_info_outline_grey.svg");

export const documentPending = require("./doument_pending.svg");
export const documentUploaded = require("./document_uploaded.svg");
export const documentInReview = require("./document_in_review.svg");
export const tinyError = require("./tiny_error.svg");
export const tinySuccess = require("./tiny_success.svg");

export const moreIcon = require("./ic_overflow.svg");

export const virtualCard = require("./virtual-card.svg");
export const physicalCard = require("./physical-card.svg");
export const activateCard = require("./activate-card.svg");

export const iconNotFound = require("./notfound.svg");
export const smartCSVExport = require("./smart-csv-export.svg");
export const pdfExport = require("./pdf-export.svg");
export const csvExport = require("./csv-export.svg");
export const attachmentExport = require("./attachment_export.svg");

export const csvXeroLogo = require("./smartcsv/xero.svg");
export const csvJurnalLogo = require("./smartcsv/jurnal.svg");
export const csvQuickBooksLogo = require("./smartcsv/quickbooks.svg");
export const newTab = require("./new-tab.svg");
export const whiteEyeOutlined = require("./white-eye.svg");

export const flowStart = require("./flow_start.svg");
export const flowCondition = require("./flow_condition.svg");
export const flowAdd = require("./flow_add.svg");
export const flowEnd = require("./flow_end.svg");
export const flowConditionWallet = require("./flow_condition_wallet.svg");
export const flowConditionAmount = require("./flow_condition_amount.svg");
export const flowConditionCategory = require("./flow_condition_category.svg");

export const threeDots = require("./three_dots.svg");
export const trash = require("./trash.svg");
export const chevronDown = require("./chevron_down.svg");
export const downArrowBlack = require("./down-arrow-black.svg");
export const downArrowGrey = require("./down-arrow-grey.svg");

export const modalCloseIcon = require("../icons/modal-close-icon-24x24.svg");
export const emptyDefaultIcon = require("./home/empty-default-icon.svg");
export const circleTick = require("./circle-tick.svg");
export const circleCross = require("./circle-cross.svg");
export const closeIconBkg = require("./close-icon_bkg.svg");
export const closeIcon = require("../icons/close-icon_bkg.svg");

export const miniSpotSearchIcon = require("./mini-spot-search-icon.svg");
export const miniSpotActivateIcon = require("./mini-spot-activate-icon.svg");
export const emptyCardStateIcon = require("./empty-card-state.svg");
export const plusRedIcon = require("./plus-red-icon.svg");
export const visaIconSmall = require("./visa-icon-small.svg");
export const rightArrowRoundedBlue = require("./rounded-right-arrow-blue.svg");
export const tinyEmptyComingSoon = require("../img/home/tiny-empty-coming-soon.svg");
export const tinyEmptyComingSoonWithoutBackground = require("../img/home/tiny-empty-coming-soon-without-background.svg");
export const blueCloseCircle = require("./blue-close-circle.svg");
export const triangleErrorGrey24x24 = require("./icons/triangle-error-grey-24x24.svg");
export const defaultEmpty = require("./empty_default.svg");

export const billInvoiceActive = require("./bill-invoice-active.svg");
export const billInvoiceInactive = require("./bill-invoice-inactive.svg");
export const orangeTick = require("./orange-tick.svg");
export const newInfo = require("./icons/info.svg");
export const refreshFxRate = require("./refresh-fxrate.svg");
export const rejectApprovalRequest = require("./reject-approval-request.svg");
export const brandThreeDots = require("./brand-three-dot.svg");

export const toasterCircleTick = require("./toaster-circle-tick.svg");
export const toasterError = require("./toaster-error.svg");
export const toasterSuccessClose = require("./toaster-success-close.svg");
export const toasterErrorClose = require("./toaster-error-close.svg");
export const UploadImage = require("./ic_upload-image.svg");

export const ptAryadana = require("./pt-aryadana.svg");
export const bankIndonesia = require("./bank-indonesia.svg");
export const appui = require("./appui.svg");
export const navigationDotsVertical = require("./navigation-dots-vertical.svg");

export const dataTableNoRecordFound = require("./data-table-no-record-found.svg");
export const emptyComingSoon = require("./empty_coming_soon.svg");

export const adminIcon = require("./ico_team.svg");
export const analyticsSideBanner = require("./background/analytics-side-banner.svg");
export const bulbTooltip = require("./bulb-tooltip-small.svg");
export const ocrUnion = require("./ocr-union.svg");
export const ocrUnionGreen = require("./ocr-union-green.svg");
export const chevronDownApproval = require("./chevron-down-approval.svg");
export const billApprovalFlow = require("./bill-approval-flow.svg");
export const reimbursementApprovalFlow = require("./reimbursement-approval-flow.svg");
export const landingSoon = require("./landing-soon.svg");
export const limitExceededInfo = require("./limit-exceeded-info.svg");
export const discardEdit = require("./discard-edit.svg");
export const bannerDefaultInfo = require("./banner-default-info.svg");

export const documentLoadingBlue = require("./document-loading-blue.svg");
export const documentError = require("./document-error.svg");
export const documentLoadingGreen = require("./document-loading-green.svg");
export const exclamationBlack = require("./exclamation-black.svg");
export const chevronDownOrange = require("./chevron-down-orange.svg");
export const chevronUpOrange = require("./chevron-up-orange.svg");

export const singpassIcon = require("./singpass.svg");
export const featureCheckIcon = require("./featureCheck.svg");
export const retriveSingpassIcon = require("./retriveSingpass.svg");

export const externalLinkIcon = require("./external-link.svg");

export const reKybModelIcon = require("./re-kyb-alert.svg");
export const billDiscard = require("./bill-discard.svg");
export const paymentRunBillPreview = require("./bill-preview.png");

export const paynowLogo = require("./paynow-logo.png");
export const setupPaymentRun = require("./setup-payment-run.png");

export const unlockBanner = require("./home/unlockBanner.png");
export const netsuiteUpgrade = require("./background/netsuite-upgrade.png");
export const microsoftPartner = require("./background/microsoft.svg");
export const myobPartner = require("./background/myob.svg");
export const quickbooksPartner = require("./background/quickbooks.svg");
export const sagePartner = require("./background/sage.svg");
export const upsellingBackground = require("./upselling-background.png");
