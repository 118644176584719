import React from "react";
import { Typography } from "@spenmo/splice";

import styles from "./index.module.scss";

interface ISingleItemGrid {
  label: string;
  value: string | React.ReactNode;
  tag?: React.ReactNode;
}
interface IGridProps {
  items: ISingleItemGrid[];
}

const Item = ({ label, value, tag }: ISingleItemGrid) => (
  <>
    <Typography variant="body-content" size="s">
      {label}
    </Typography>
    {typeof value === "string" ? (
      <Typography variant="body-content" size="s" className={styles.textBlack}>
        {value}
        {tag && tag}
      </Typography>
    ) : (
      value
    )}
  </>
);

export const Grid = ({ items }: IGridProps) => {
  return (
    <div className={styles.grid}>
      {items.map(({ label, value, tag }) => (
        <div className={styles.gridItem} key={label}>
          <Item label={label} value={value} tag={tag} />
        </div>
      ))}
    </div>
  );
};

export const SingleItemGrid = ({ label, value }: ISingleItemGrid) => {
  return (
    <div className={styles.gridItemWithPadding} key={label}>
      <Item label={label} value={value} />
    </div>
  );
};
