import React from "react";

import { Col, Row } from "Splice/Grid";

import Tabs, { TAB_TYPE } from "Modules/DS/Tabs";

import { IProgressTabs } from "Views/KYX/@types";
import { PROGRESS_TABS } from "Views/KYX/Constants";

import styles from "./index.module.scss";
import { useStepChangeHandler } from "Views/KYX/hooks/useStepChangeHandler";

export const ProgressTabs = ({ activeTab }: IProgressTabs): JSX.Element => {
  const { handleActiveTab } = useStepChangeHandler();

  return (
    <Row align="end" justify="space-between" className={styles.row}>
      <Col className={styles.tabs} span={24}>
        <Tabs
          action={handleActiveTab}
          type={TAB_TYPE.FIXED}
          tabsList={PROGRESS_TABS}
          content={activeTab?.content}
          activeKey={activeTab?.tabKey}
          defaultActiveKey={PROGRESS_TABS[0].tabKey}
        />
      </Col>
    </Row>
  );
};
