import React, { useState, useEffect } from "react";
import cn from "classnames";
import { useDispatch } from "react-redux";

import APIClient from "API/Client";
import { getOrgDetailFunc } from "Redux/Actions";

import { Typography, Button } from "@spenmo/splice";

import { BodySkeleton } from "Views/Settings/components";
import LoaderIcon from "Views/State/Loading/LoaderIcon";
import PayoutForm from "./PayoutForm";
import PayoutDetail from "./PayoutDetail";

import { GetBaseAuthObject } from "utility";

import { PayoutPayload } from "../types";

import styles from "./ManagePayout.module.scss";

const ManagePayout = ({ newSettingPage }: { newSettingPage: boolean }) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [payoutData, setPayoutData] = useState<PayoutPayload | undefined>();
  const [showModal, setShowModal] = useState(false);

  const hasPayoutData = Boolean(payoutData);

  const getPayout = () => {
    setLoading(true);
    APIClient.getData(
      `/api/v1/org/${GetBaseAuthObject().orgId}/bank_account/?user_id=${GetBaseAuthObject().userId}&limit=1&pg=0`
    )
      .then((res) => {
        const data = res.data.payload.bank_accounts[0];

        setPayoutData(data || undefined);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getPayout();
    // for PayoutForm and PayoutDetail
    dispatch(getOrgDetailFunc(GetBaseAuthObject().orgId));
  }, []);

  const handleShowModal = () => {
    !loading && setShowModal(true);
  };

  const handleCloseModal = () => setShowModal(false);

  if (loading) {
    return newSettingPage ? <BodySkeleton paddingLess /> : <LoaderIcon />;
  }

  return (
    <div
      className={cn({
        [styles.managePayout]: !newSettingPage,
      })}
    >
      {!newSettingPage && <h1 className={styles.title}>Settings — Reimbursements</h1>}
      <div
        className={cn({
          [styles.card]: !newSettingPage,
        })}
      >
        <div className={styles.cardHeader}>
          <Typography size="m" variant="headline-content" tag="h3" className={styles.header}>
            Payout Details
          </Typography>
          <Typography tag="p" size="m" variant="body-content" className={styles.caption}>
            Set account details for your reimbursement payout
          </Typography>
        </div>
        {hasPayoutData ? (
          <PayoutDetail payoutPayload={payoutData} onClickEdit={handleShowModal} onClickSubmit={getPayout} />
        ) : (
          <div className={styles.emptyPayout}>
            <Typography tag="p" size="m" variant="body-content" className={styles.caption}>
              All approved reimbursements to you will be settled with your entered payout details
            </Typography>

            <Button variant="primary" type="submit" onClick={handleShowModal}>
              Set a Payout Method
            </Button>
          </div>
        )}
      </div>
      <PayoutForm
        show={showModal}
        initialFormValues={payoutData}
        onCloseModal={handleCloseModal}
        onSubmit={getPayout}
      />
    </div>
  );
};

export default ManagePayout;
