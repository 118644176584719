import {
  FETCH_CATEGORY_LIST_REQUEST,
  FETCH_CATEGORY_LIST_SUCCESS,
  FETCH_CATEGORY_LIST_FAILURE,
  FETCH_CATEGORY_MISSING_LIST_SUCCESS,
} from "Redux/Actions/Category/list";
import { userInitialState } from "../../InitialState";

export const categoryListReducer = (state = userInitialState, action) => {
  switch (action.type) {
    case FETCH_CATEGORY_LIST_REQUEST:
      return {
        ...state,
        categoryList: {
          loading: true,
          data: [],
          error: false,
        },
      };
    case FETCH_CATEGORY_LIST_SUCCESS:
      return {
        ...state,
        categoryList: {
          loading: false,
          data: action.payload,
          error: false,
        },
      };
    case FETCH_CATEGORY_MISSING_LIST_SUCCESS:
      return {
        ...state,
        missingCategories: action.payload,
      };
    case FETCH_CATEGORY_LIST_FAILURE:
      return {
        ...state,
        categoryList: {
          loading: false,
          data: [],
          error: true,
        },
      };
    default:
      return state;
  }
};
