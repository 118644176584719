import APIclient from "API/Client";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import { FETCH_PENDING_ACTIONS_TYPES, PendingActionsData } from "./types";

export const fetchPendingActionsRequest = (): { type: FETCH_PENDING_ACTIONS_TYPES.REQUEST } => {
  return {
    type: FETCH_PENDING_ACTIONS_TYPES.REQUEST,
  };
};

export const fetchPendingActionsSuccess = (
  data: PendingActionsData
): {
  type: FETCH_PENDING_ACTIONS_TYPES.SUCCESS;
  data: PendingActionsData;
} => {
  return {
    type: FETCH_PENDING_ACTIONS_TYPES.SUCCESS,
    data: data,
  };
};

export const fetchPendingActionsError = (
  data: PendingActionsData
): {
  type: FETCH_PENDING_ACTIONS_TYPES.ERROR;
  data: PendingActionsData;
} => {
  return {
    type: FETCH_PENDING_ACTIONS_TYPES.ERROR,
    data: data,
  };
};

export const getPendingActions = () => {
  return (dispatch) => {
    dispatch(fetchPendingActionsRequest());

    APIclient.getData("/ms/spm-organization/v2/pending-action")
      .then((res: any) => {
        if (res?.data?.status > HTTP_STATUS_CODE.OK) {
          dispatch(fetchPendingActionsError(res.data));
        } else {
          dispatch(fetchPendingActionsSuccess(res.data?.payload));
        }
      })
      .catch((err) => {
        dispatch(fetchPendingActionsError(err));
      });
  };
};
