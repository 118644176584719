/**
 * An enum that represents the different types of KYX modals.
 * @readonly
 * @enum {string}
 */
export enum KYX_MODAL_TYPES {
  /**
   * @property {string} NONE - Indicates that no KYX modal is active.
   */
  NONE = "NONE",
  /**
   * @property {string} CLIENT_AGREEMENT - Indicates that the KYX modal is related to the client agreement to company policy.
   */
  CLIENT_AGREEMENT = "CLIENT_AGREEMENT_MODAL",
  /**
   * @property {string} DOCUMENT_GUIDELINES_MODAL - Indicates that the KYX modal is related to one of the various document guidelines.
   */
  DOCUMENT_GUIDELINES = "DOCUMENT_GUIDELINES_MODAL",
  /**
   * @property {string} SAVE_AS_DRAFT_LOGOUT_MODAL - Indicates that the KYX modal is related to the save as draft logout confirmation modal.
   */
  SAVE_AS_DRAFT_LOGOUT_MODAL = "SAVE_AS_DRAFT_LOGOUT_MODAL",
  /**
   * @property {string} CHANGE_KYB_METHOD_MODAL - Indicates that the KYX modal is related to the change KYB modal which gives ability to switch between kyx method types.
   */
  CHANGE_KYB_METHOD = "CHANGE_KYB_METHOD_MODAL",

  MY_INFO_ERROR = "MY_INFO_ERROR_MODAL",

  ACCESS_DENIED_JOINT_APPLICANT = "ACCESS_DENIED_JOINT_APPLICANT_MODAL",
}
