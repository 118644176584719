import { API } from "API/AxiosSetUp";
import { AxiosRequestConfig } from "axios";

export interface GetDynamicSLAResult {
  payload: {
    id: number;
    sla: string;
  };
}

export interface GetDynamicSLAParams {
  billID?: string;
  senderCountry?: string;
  beneficiaryBankCountry?: string;
  currency?: string;
  amount?: number;
}

export const getDynamicSLA = (params: GetDynamicSLAParams, configs?: AxiosRequestConfig) => {
  return API.get<GetDynamicSLAResult>(`${window.__ENV__.API_BASE_URL}/ms/spm-disbursement/v1/sla`, {
    ...configs,
    params,
  });
};
