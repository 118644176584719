import * as React from "react";
import { useCallback, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { trackEvent } from "utility/analytics";
import { EmployeeDeleteEventName } from "Views/Employee/Analytics";

import { IEmployee } from "Redux/Actions/Employee/Get/Employees/types";
import { getDeleteEmployeeCheck } from "Redux/Actions/Employee/Delete/Check";

import Button from "Modules/button";

import { EmployeeModalTypes } from "Views/Employee/types";
import { EmployeesModalContext, IEmployeesModalContext } from "Views/Employee/context";

const DeleteButton = ({ accountRecord }: { accountRecord: IEmployee }) => {
  const dispatch = useDispatch();
  const { id, name, is_deletion_allowed } = accountRecord;

  const { toggleModal }: IEmployeesModalContext = useContext(EmployeesModalContext);

  const userId: string = useSelector((state: any) => state.userInfoReducer.userId);

  const handleDelete = useCallback(() => {
    toggleModal(EmployeeModalTypes.DELETE);
    dispatch(getDeleteEmployeeCheck(id, name));
    trackEvent(EmployeeDeleteEventName.DELETE_ACCOUNT_EVENT, {
      account_deletion_event_source: EmployeeDeleteEventName.DELETE_ACCOUNT_CLICKED,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const viewCondition = is_deletion_allowed && userId !== id;

  return (
    viewCondition && (
      <Button textButton btnStyle="transparent-red" action={handleDelete}>
        Delete Account
      </Button>
    )
  );
};

export default DeleteButton;
