import * as React from "react";
import { useEffect, useContext } from "react";
import { useSelector } from "react-redux";

import { IEmployee, IEmployeesReducer } from "Redux/Actions/Employee/Get/Employees/types";

import { EMPLOYEES_TABLE_PAGE_SIZE } from "constants/Employee.constant";

import DataTable, { Header, ActionsBar, Table, DATA_TABLE_SIZE_TYPES, DATA_TABLE_PADDING } from "Modules/DS/DataTable";
import { useIsSaasWithPaymentsOrg } from "customHooks/useIsSaasWithPaymentsOrg";
import { plural } from "utility";

import * as E from "Views/Employee/context";
import Actions from "Views/Employee/List/Actions";
import Columns from "Views/Employee/List/Columns";
import useEmployeesCount from "Views/Employee/List/useEmployeesCount";

import "./index.scss";

const List = () => {
  const {
    /* to be enabled when pagination is fixed from BE */
    // searchVal, filters,
    getEmployeesData,
  }: E.IEmployeesContext = useContext(E.EmployeesPageContext);
  const { currentPage, setCurrentPage }: E.IEmployeesPaginationContext = useContext(E.EmployeesPaginationContext);

  const employeeCount = useEmployeesCount();
  const isSaasAndPaymentsOrg = useIsSaasWithPaymentsOrg();

  const allEmployees: IEmployeesReducer = useSelector((state: any) => state.allEmployeesReducer);

  const loading: boolean = allEmployees?.loading || false;
  const total: number = allEmployees?.data?.payload?.total_count || 0;
  const dataSource: Array<IEmployee> = allEmployees?.data?.payload?.employees || [];

  const onPageChange = (page: number): void => {
    /* to be enabled when pagination is fixed from BE */
    // getEmployeesData(page - 1, searchVal, filters.values);
    window.scrollTo(0, 0);
    setCurrentPage(page - 1);
  };

  useEffect(() => {
    getEmployeesData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const table = {
    total: total,
    loading: loading,
    dataSource: dataSource,
    pageNum: currentPage,
    pageSize: EMPLOYEES_TABLE_PAGE_SIZE,
    onPageChange: onPageChange,
    emptyState: <p>Employee not found</p>,
    scrollHorizontal: "max-content",
  };

  const pageName = isSaasAndPaymentsOrg ? "Employee" : "User";
  const subtitle = plural(employeeCount, pageName, "s", false);

  return (
    <DataTable className="employees-list" size={DATA_TABLE_SIZE_TYPES.SMALL} padding={DATA_TABLE_PADDING.GRID}>
      <Header title={`${pageName}s`} subtitle={subtitle} />
      <ActionsBar>{Actions()}</ActionsBar>
      <Table<IEmployee> {...table}>{Columns(isSaasAndPaymentsOrg)}</Table>
    </DataTable>
  );
};

export default List;
