import useSWR, { SWRConfiguration } from "swr";
import { AxiosResponse } from "axios";

import { getData } from "API/Client";

import { PRODUCT_NAME } from "Redux/ModularProduct";

import useCheckOrgConfigs from "customHooks/useCheckOrgConfigs";
import { getCookieValue, cookieNames } from "utility/CookieHelper";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";

import {
  appNotification,
  contactCSErrorMessage,
} from "Modules/appNotification/appNotification";

import {
  IReimbursementDetailData,
  IReimbursementDetailResponse,
  IUseFetchReimbursementDetails,
} from "Views/Reimbursement/@types";
import { API_URL } from "Views/Reimbursement/DataCalls/Services";

const useFetchDetails = (id: string): IUseFetchReimbursementDetails => {
  const [isCrConfigEnabled, loadingCrConfig] = useCheckOrgConfigs(
    PRODUCT_NAME.CASH_REIMBURSEMENT,
    true,
  ) as [boolean, boolean];

  const keys: [string, string] = [
    isCrConfigEnabled ? API_URL.DETAILS.REIMBURSEMENT(id) : null,
    getCookieValue(cookieNames.AUTH_TOKEN),
  ];

  const config: SWRConfiguration<AxiosResponse<IReimbursementDetailResponse>> =
    {
      revalidateOnFocus: false,
      onSuccess: (response) => {
        if (response.data.status !== HTTP_STATUS_CODE.OK) {
          throw new Error(`${response.data.status}`);
        }
      },
      onError: (err) => {
        isCrConfigEnabled &&
          appNotification.error(contactCSErrorMessage(err.message));
      },
    };

  const { data, isLoading, isValidating, error } = useSWR<
    AxiosResponse<IReimbursementDetailResponse>
  >(keys, ([url]) => getData(url), config);

  return {
    data: data?.data.payload?.request || ({} as IReimbursementDetailData),
    showSkeleton:
      isLoading || isValidating || loadingCrConfig || !isCrConfigEnabled,
    showErrorState: (error?.message as string)?.length > 0 || false,
  };
};

export default useFetchDetails;
