import { useCallback, useState } from "react";
import {
  IGetCardsTrxnsRequest,
  IGetCardsTrxnsResponsePayload,
  ICardsTrxnsSuccessData,
  ICardsTrxnsErrorData,
  GET_CARDS_TRXNS_REQUEST_PARAMS,
} from "Views/Transaction/@types";
import { initialCardsTrxnsSuccess } from "Views/Transaction/DataCalls/Cards.mocks";

import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import { getAllCardsTrxns } from "Views/Transaction/DataCalls/Cards.api";

export const useListFetch = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [data, setData] = useState<IGetCardsTrxnsResponsePayload>(initialCardsTrxnsSuccess.mocked);

  const removeExtraAddedParams = (params: IGetCardsTrxnsRequest) => {
    const paramsAllowed = Object.values(GET_CARDS_TRXNS_REQUEST_PARAMS) as string[];
    Object.keys(params).forEach((key) => {
      if (!paramsAllowed.includes(key)) {
        delete params[key];
      }
    });
    return params;
  };

  const fetchData = useCallback((params: IGetCardsTrxnsRequest) => {
    setLoading(true);
    setError(false);

    getAllCardsTrxns(removeExtraAddedParams(params))
      .then((res) => {
        const { status, payload = {} } = res?.data || {};
        if (status === HTTP_STATUS_CODE.OK) {
          setData(payload as ICardsTrxnsSuccessData);
        } else {
          setError(true);
          setData(payload as ICardsTrxnsErrorData);
        }
      })
      .catch((error) => {
        setError(true);
        setData(error as ICardsTrxnsErrorData);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return { loading, error, data, fetchData };
};
