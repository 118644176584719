import {
  GENDER_OF_INDIVIDUAL_OPTIONS,
  GENDER_OF_INDIVIDUAL_OPTIONS_LABEL,
  GENDER_OF_INDIVIDUAL_OPTIONS_VALUE,
  KYX_FORMS_KEYS,
  PERSONNEL_INFORMATION_FORM_ITEM_KEYS,
} from "Views/KYX/Constants/Forms";
import { FORM_FIELD_TYPES, DOCUMENT_STEP_TYPE, FORM_SECTION_COMPONENT } from "Views/KYX/Constants";
import { IDocumentStep, IFormElement, IPersonForm } from "Views/KYX/@types";
import { formatDate } from "Views/KYX/Helper";

export const useNRICForm = (formData: IPersonForm, isFormDisabled: boolean): IDocumentStep => {
  const NRIC_DOCUMENT_HEADER: IFormElement = {
    key: PERSONNEL_INFORMATION_FORM_ITEM_KEYS.NRIC_DOCUMENT_HEADER_AND_GUIDELINES,
    col: { span: 24 },
    element: {
      type: FORM_FIELD_TYPES.HEADER,
      props: {
        title: "NRIC Document",
      },
    },
  };

  const NRIC_NUMBER: IFormElement = {
    key: PERSONNEL_INFORMATION_FORM_ITEM_KEYS.NRIC_NUMBER,
    col: { span: 24 },
    element: {
      type: FORM_FIELD_TYPES.INPUT,
      props: {
        formProps: {
          name: [
            KYX_FORMS_KEYS.PERSON_DATA_ID_DOCUMENTS,
            KYX_FORMS_KEYS.PERSON_DATA_NRIC_DOCUMENT_TYPE,
            KYX_FORMS_KEYS.PERSON_DATA_DOCUMENT_NUMBER,
          ],
          label: "NRIC Number",
          required: false,
        },
        inputProps: {
          placeholder: "Please enter NRIC number",
          disabled: isFormDisabled,
        },
      },
    },
  };

  const DATE_OF_BIRTH: IFormElement = {
    key: KYX_FORMS_KEYS.PERSONNEL_DATA_PERSON + KYX_FORMS_KEYS.PERSON_DATA_DOB,
    col: { span: 12, md: 12, xs: 24 },
    element: {
      type: FORM_FIELD_TYPES.DATEPICKER,
      props: {
        formProps: {
          name: [KYX_FORMS_KEYS.PERSONNEL_DATA_PERSON, KYX_FORMS_KEYS.PERSON_DATA_DOB],
          label: "Date of Birth",
          required: false,
          initialValue: formData?.date_of_birth && formatDate(formData?.date_of_birth),
        },
        dateProps: {
          name: KYX_FORMS_KEYS.PERSON_DATA_FULL_NAME,
          placeholder: "Please select date of birth",
          disabled: isFormDisabled,
        },
      },
    },
  };

  const GENDER: IFormElement = {
    key: KYX_FORMS_KEYS.PERSONNEL_DATA_PERSON + KYX_FORMS_KEYS.PERSON_DATA_GENDER,
    col: { span: 12, md: 12, xs: 24 },
    element: {
      type: FORM_FIELD_TYPES.RADIO,
      props: {
        formProps: {
          name: KYX_FORMS_KEYS.PERSON_DATA_GENDER,
          label: "Gender",
          required: false,
        },
        radioProps: {
          name: KYX_FORMS_KEYS.PERSON_DATA_GENDER,
          options: [
            {
              label: GENDER_OF_INDIVIDUAL_OPTIONS_LABEL[GENDER_OF_INDIVIDUAL_OPTIONS.MALE],
              value: GENDER_OF_INDIVIDUAL_OPTIONS_VALUE[GENDER_OF_INDIVIDUAL_OPTIONS.MALE],
            },
            {
              label: GENDER_OF_INDIVIDUAL_OPTIONS_LABEL[GENDER_OF_INDIVIDUAL_OPTIONS.FEMALE],
              value: GENDER_OF_INDIVIDUAL_OPTIONS_VALUE[GENDER_OF_INDIVIDUAL_OPTIONS.FEMALE],
            },
          ],
          disabled: isFormDisabled,
        },
      },
    },
  };

  const COUNTRY_OF_RESIDENCE: IFormElement = {
    key: KYX_FORMS_KEYS.PERSONNEL_DATA_PERSON + KYX_FORMS_KEYS.PERSON_DATA_RESIDENTIAL_COUNTRY,
    col: { span: 12, md: 12, xs: 24 },
    element: {
      type: FORM_FIELD_TYPES.COUNTRYSELECT,
      props: {
        formProps: {
          name: [KYX_FORMS_KEYS.PERSONNEL_DATA_PERSON, KYX_FORMS_KEYS.PERSON_DATA_RESIDENTIAL_COUNTRY],
          label: "Country of Residence",
          required: true,
          initialValue: formData?.[KYX_FORMS_KEYS.PERSON_DATA_RESIDENTIAL_COUNTRY],
          rules: [
            {
              required: true,
              message: "This field cannot be empty",
            },
          ],
        },
        selectProps: {
          placeholder: "Select country of residence",
          isnationality: false,
        },
      },
    },
  };

  const RESIDENTIAL_ADDRESS: IFormElement = {
    key: KYX_FORMS_KEYS.PERSONNEL_DATA_PERSON + KYX_FORMS_KEYS.PERSON_DATA_RESIDENTIAL_ADDRESS,
    col: { span: 24 },
    element: {
      type: FORM_FIELD_TYPES.INPUT,
      props: {
        formProps: {
          name: KYX_FORMS_KEYS.PERSON_DATA_RESIDENTIAL_ADDRESS,
          label: "Residential Address",
          required: true,
          rules: [
            {
              required: true,
              message: "Please enter residential address",
            },
            { whitespace: true, message: "This field cannot be empty" },
          ],
        },
        inputProps: {
          name: KYX_FORMS_KEYS.PERSON_DATA_RESIDENTIAL_ADDRESS,
          placeholder: "Please enter residential address",
          disabled: isFormDisabled,
        },
      },
    },
  };

  const POSTAL_CODE: IFormElement = {
    key: KYX_FORMS_KEYS.PERSONNEL_DATA_PERSON + KYX_FORMS_KEYS.COMPANY_ADDRESS_POSTAL_CODE,
    col: { span: 12, md: 12, xs: 24 },
    element: {
      type: FORM_FIELD_TYPES.INPUT,
      props: {
        formProps: {
          name: [KYX_FORMS_KEYS.PERSONNEL_DATA_PERSON, KYX_FORMS_KEYS.COMPANY_ADDRESS_POSTAL_CODE],
          label: "Postal Code",
          required: false,
          initialValue: formData?.[KYX_FORMS_KEYS.COMPANY_ADDRESS_POSTAL_CODE],
        },
        inputProps: {
          name: KYX_FORMS_KEYS.COMPANY_ADDRESS_POSTAL_CODE,
          placeholder: "Please enter postal code",
          disabled: isFormDisabled,
        },
      },
    },
  };

  return {
    key: KYX_FORMS_KEYS.PERSONNEL_DATA_PERSON,
    type: DOCUMENT_STEP_TYPE.DEFAULT,
    row: { gutter: 40 },
    container: {
      type: FORM_SECTION_COMPONENT.CARD,
      props: {
        title: "Personnel Information",
      },
    },
    document: [
      NRIC_DOCUMENT_HEADER,
      NRIC_NUMBER,
      DATE_OF_BIRTH,
      GENDER,
      COUNTRY_OF_RESIDENCE,
      RESIDENTIAL_ADDRESS,
      POSTAL_CODE,
    ],
  };
};
