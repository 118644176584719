/**
 * @fileoverview This module provides the definition and creation of the Manage menu
 * for the application's sidebar navigation. The menu contains links to manage different
 * application features such as Budgets, Users, and Approval Policies.
 *
 * @author Sushmitha, Bakshi
 * @created 25 Aug 2023
 * @lastModified 30 Aug 2023
 */
import * as Icons from "@spenmo/splice";
import * as ROUTES from "constants/Routes.constants";

import { ISidebarMenuConfig } from "Views/Navigation/@types";
import { NAVIGATION_EVENTS } from "Views/Navigation/Constants";
import { useNavigationConfigs } from "../Hooks/useOrgConfigs";

/**
 * `useManageMenu` function generates a list of menu items for the management section of the sidebar.
 * Each menu item has an `id` for identification, a `props` object containing its display properties
 * (like label, icon, badge details) and its respective route defined in the `history` property.
 *
 * Only items with the `enabled` property set to true will be included in the final list.
 *
 * @returns {ISidebarMenuConfig[]} - An array of sidebar menu items for the management section.
 */
export const useManageMenu = (): ISidebarMenuConfig[] => {
  const { isApprovalPolicesPageAvailable } = useNavigationConfigs();

  return [
    {
      id: "MANAGE_MENU_01",
      enabled: true,
      permission: true,
      props: {
        menu: {
          label: "Budgets",
          prefixIcon: Icons.ChartPieOutline,
          prefixActiveIcon: Icons.ChartPieFilled,
        },
        history: ROUTES.TEAMS_PATH,
        analytics: {
          enabled: true,
          data: { sidebar_event_source: NAVIGATION_EVENTS.TEAMS },
        },
      },
    } as ISidebarMenuConfig,
    {
      id: "MANAGE_MENU_02",
      enabled: true,
      permission: true,
      props: {
        menu: {
          label: "Users",
          prefixIcon: Icons.PeopleOutline,
          prefixActiveIcon: Icons.PeopleFilled,
        },
        history: ROUTES.EMPLOYEES_PATH,
        analytics: {
          enabled: true,
          data: { sidebar_event_source: NAVIGATION_EVENTS.USERS },
        },
      },
    } as ISidebarMenuConfig,
    {
      id: "MANAGE_MENU_3",
      enabled: isApprovalPolicesPageAvailable,
      permission: true,
      props: {
        menu: {
          label: "Approval Policies",
          prefixIcon: Icons.ApprovalFolderOutline,
          prefixActiveIcon: Icons.ApprovalFolderFilled,
        },
        history: ROUTES.APPROVAL_FLOW_PATH,
        analytics: {
          enabled: true,
          data: {
            sidebar_event_source: NAVIGATION_EVENTS.APPROVALS,
          },
        },
      },
    } as ISidebarMenuConfig,
  ].filter((item) => item.enabled && item.permission);
};
