import { CS_spenmo_logo } from "assets/v1.1/logo/spenmo";
import { JWT_TOKEN_ENDPOINT } from "constants/SpenmoSupport.constant";

var jwt = require("jsonwebtoken");
var headers = {
  header: { alg: "HS256", cty: "JWT" },
  algorithm: "HS256",
};

export const setJWTToken = (userData) => {
  if (userData) {
    return {
      name: userData.name,
      email: userData.email,
      external_id: "zendesk-user" + userData.id + "zendesk-user-name",
    };
  }
};

export const settings = (userDetails) => {
  return {
    color: {
      theme: "#131A2E",
    },
    offset: {
      horizontal: "86px",
      vertical: "11px",
    },
    helpCenter: {
      suppress: true,
    },
    talk: {
      suppress: true,
    },
    answerBot: {
      suppress: true,
    },
    contactForm: {
      suppress: true,
    },
    navigation: {
      popoutButton: {
        enabled: false,
      },
    },
    webWidget: {
      authenticate: {
        chat: {
          jwtFn: (callback) => {
            userDetails &&
              jwt.sign(userDetails, JWT_TOKEN_ENDPOINT, headers, function (err, token) {
                token && callback(token);
              });
          },
        },
      },
    },
    chat: {
      connectOnPageLoad: true,
      hideWhenOffline: true,
      title: {
        "*": "Chat with Spenmo",
      },
      concierge: {
        avatarPath: CS_spenmo_logo,
        name: "Spenmo",
        title: { "*": "Customer Support" },
      },
      menuOptions: {
        emailTranscript: false,
      },
      profileCard: {
        avatar: true,
        rating: false,
        title: true,
      },
    },
  };
};
