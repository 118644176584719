import { useEffect, useState, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import {
  IAnalyticsFilter,
  ISpendingCategoriesData,
  ISpendingCategory,
  ISpendingMerchant,
  ISpendingMerchantsData,
  ISpendingTeam,
  ISpendingTeamsData,
} from "Views/Analytics/@types";

import {
  getSpendingCategories,
  getSpendingMerchants,
  getSpendingTeams,
  getSpendingTrend,
} from "./DataCalls/Analytics.api";
import { pieColors } from "./dummyData.js";
import { removeEmpty, calculateDate } from "./utils";
import moment from "moment";

interface Props {
  filter: IAnalyticsFilter;
  currency: string;
  checkRetrieveDataError: (value: boolean) => void;
  isCustom?: boolean;
}

interface SpendTrendDataItem {
  timestamp: string;
  amount: number;
  label?: string;
  start?: string;
  end?: string;
}

interface SpendingTrendProps extends Props {
  getTotalSpending: (value: number) => void;
}

export function useSpendingTrendList({
  filter,
  currency,
  getTotalSpending,
  checkRetrieveDataError,
}: SpendingTrendProps) {
  const [{ loading: spendingTrendLoading, data: spendingTrendData }, setSpendingTrend] = useState({
    loading: false,
    data: [] as SpendTrendDataItem[],
  });

  const fetchSpendingTrend = useCallback(() => {
    const payload = {
      start_date: filter.startDate,
      end_date: filter.endDate,
      interval: "day",
      currency,
      team_ids: filter.team_ids,
      expense_types: filter.type_ids,
    };

    getSpendingTrend(removeEmpty(payload))
      .then((response: any) => {
        let spendTrendData: SpendTrendDataItem[] = response?.data?.data || [];

        const startDate: moment.Moment = moment(filter.startDate);
        const endDate: moment.Moment = moment(filter.endDate).utc().startOf("d");

        // if 0th element is not equal to start date we need to insert new dummy data in 0th position in array for proper line chart
        if (spendTrendData.length && startDate !== moment(spendTrendData[0].timestamp)) {
          spendTrendData.unshift({
            timestamp: filter.startDate,
            amount: 0,
            label: moment(filter.startDate).format("DD MMM YYYY"),
          });
        }

        // if last element is not equal to end date we need to insert new dummy data in last position in array for proper line chart
        if (
          spendTrendData.length &&
          endDate.toISOString() !== moment(spendTrendData[spendTrendData.length - 1].timestamp).toISOString()
        ) {
          spendTrendData.push({
            timestamp: endDate.toISOString(),
            amount: 0,
            label: endDate.format("DD MMM YYYY"),
          });
        }

        // this condition is for check the selected filter range is more than month or not
        const isLessThanMonth: boolean =
          (filter.differences.months === 1 && filter.otherCustomFilter.isCustom === false) ||
          (filter.otherCustomFilter.isCustom === true && filter.otherCustomFilter.type === "months");

        // we need to create a custom/combined data for write the logic to displaying the line chart as we want (only for more than month filters)
        if (isLessThanMonth === false) {
          // finding the differance between startdate and end date
          const differance: number = Math.ceil(
            moment(endDate.endOf("month").utc().startOf("d")).diff(moment(startDate.startOf("month")), "months", true)
          );

          // monthOfStartingSpend => to store the startng month of spend (jan = 0, ..., dec = 11)
          const monthOfStartingSpend: number = startDate.get("M");

          // yearOfStartingSpend => to store the starting year of spend
          const yearOfStartingSpend: number = startDate.get("year");

          // function returns number which is equivalent to the month (jan = 0, dec = 11, .., mar = 14)
          const getMonthsEquivalentNumber = (month): number => (month >= 12 ? month - 12 : month);

          // we need to loop the array no of times based on the differance between start and end date
          const groupedMonthSpend: SpendTrendDataItem[] = [...Array(differance)].map((_, currentMonth) => {
            /**
             * @currentMonth => consecutive months of @monthOfStartingSpend
             * if the starting month is 1, then @currentMonth have next and next consecutive months => 2, 3 ...
             * @startingDateOfYear => stores jan 1 of the year which spending is started
             */

            const startingDateOfYear: moment.Moment = moment().set("year", yearOfStartingSpend).set("month", 0);

            // totalMonthSpend => storing all spend trends which happened in whole complete month;
            let totalMonthSpend: SpendTrendDataItem[] = spendTrendData.filter((spend) => {
              const currentSpend: moment.Moment = moment(spend.timestamp);
              const shouldHaveSameMonth: boolean =
                currentSpend.get("month") === getMonthsEquivalentNumber(monthOfStartingSpend + currentMonth);
              const shouldHaveSameYear: boolean =
                currentSpend.get("year") ===
                moment(startingDateOfYear)
                  .add(monthOfStartingSpend + currentMonth, "months")
                  .get("year");
              return shouldHaveSameYear && shouldHaveSameMonth;
            });

            // if there is no spend happened in the month, then we need to create dummy data for plotting in the line chart
            if (totalMonthSpend.length === 0) {
              totalMonthSpend = [
                {
                  timestamp: moment(startingDateOfYear)
                    .add(monthOfStartingSpend + currentMonth, "months")
                    .utc()
                    .startOf("month")
                    .toISOString(),
                  amount: 0,
                },
                {
                  timestamp: moment(startingDateOfYear)
                    .add(monthOfStartingSpend + currentMonth, "months")
                    .utc()
                    .endOf("month")
                    .startOf("day")
                    .toISOString(),
                  amount: 0,
                },
              ];
            }

            if (!totalMonthSpend.length) return null;
            if (totalMonthSpend.length === 1) {
              return {
                timestamp: totalMonthSpend[0].timestamp,
                amount: totalMonthSpend[0].amount,
                label:
                  moment(totalMonthSpend[0].timestamp).get("D").toString() +
                  "-" +
                  moment(totalMonthSpend[totalMonthSpend.length - 1].timestamp)
                    .format("DD MMM YYYY")
                    .toString(),
                start: moment(totalMonthSpend[0].timestamp).utc().startOf("d").toISOString(),
                end: moment(totalMonthSpend[totalMonthSpend.length - 1].timestamp)
                  .utc()
                  .startOf("d")
                  .toISOString(),
              };
            } else {
              return totalMonthSpend.reduce((a, b) => {
                return {
                  timestamp: a.timestamp,
                  amount: a.amount + b.amount,
                  label:
                    moment(totalMonthSpend[0].timestamp).get("D").toString() +
                    "-" +
                    moment(totalMonthSpend[totalMonthSpend.length - 1].timestamp)
                      .format("DD MMM YYYY")
                      .toString(),
                  start: moment(totalMonthSpend[0].timestamp).utc().startOf("d").toISOString(),
                  end: moment(totalMonthSpend[totalMonthSpend.length - 1].timestamp)
                    .utc()
                    .startOf("d")
                    .toISOString(),
                };
              });
            }
          });

          spendTrendData = [...groupedMonthSpend].filter((val) => Boolean(val));
        } else {
          spendTrendData = spendTrendData.map((data) => ({
            ...data,
            label: moment(data.timestamp).format("DD MMM YYYY"),
          }));
        }

        setSpendingTrend({
          loading: false,
          data: spendTrendData || [],
        });
        getTotalSpending(response?.data?.total_spend);
        checkRetrieveDataError(false);
      })
      .catch((error) => {
        setSpendingTrend({
          loading: false,
          data: [],
        });
        if (error.response && error.response.status !== 401) {
          checkRetrieveDataError(true);
        }
      });
  }, [filter]);

  useEffect(() => {
    setSpendingTrend({
      loading: true,
      data: [],
    });
    fetchSpendingTrend();
  }, [fetchSpendingTrend]);

  return {
    spendingTrendLoading,
    spendingTrendData,
    fetchSpendingTrend,
  };
}

export function useMerchantList({ filter, currency, checkRetrieveDataError }: Props) {
  const [{ loading: spendingMerchantsLoading, data: spendingMerchantsData }, setSpendingMerchants] = useState({
    loading: false,
    data: [] as ISpendingMerchantsData[],
  });
  const payload = {
    past_start_date: calculateDate(filter.startDate, filter.differences, filter.otherCustomFilter),
    past_end_date: calculateDate(filter.endDate, filter.differences, filter.otherCustomFilter, true),
    current_start_date: filter.startDate,
    current_end_date: filter.endDate,
    currency,
    team_ids: filter.team_ids,
    expense_types: filter.type_ids,
  };

  const fetchMerchants = useCallback(() => {
    getSpendingMerchants(removeEmpty(payload))
      .then((response) => {
        setSpendingMerchants({
          loading: false,
          data: (response?.data?.data || []).map((item: ISpendingMerchant, index: number) => ({
            change: item.change,
            merchant: item.merchant_name,
            currentSpending: item.current_spending,
            pastSpending: item.past_spending,
            currency,
          })),
        });
        checkRetrieveDataError(false);
      })
      .catch((error) => {
        setSpendingMerchants({
          loading: false,
          data: [],
        });
        if (error.response && error.response.status !== 401) {
          checkRetrieveDataError(true);
        }
      });
  }, [filter]);

  useEffect(() => {
    setSpendingMerchants({
      loading: true,
      data: [],
    });

    fetchMerchants();
  }, [fetchMerchants]);

  return {
    spendingMerchantsData,
    spendingMerchantsLoading,
    fetchMerchants,
  };
}

export function useCategoryList({ filter, currency, checkRetrieveDataError }: Props) {
  const [{ loading: spendingCategoriesLoading, data: spendingCategoriesData }, setSpendingCategories] = useState({
    loading: false,
    data: [] as ISpendingCategoriesData[],
  });
  const [selectedCategory, setSelectedCategory] = useState(null);

  const payload = {
    past_start_date: calculateDate(filter.startDate, filter.differences, filter.otherCustomFilter),
    past_end_date: calculateDate(filter.endDate, filter.differences, filter.otherCustomFilter, true),
    current_start_date: filter.startDate,
    current_end_date: filter.endDate,
    currency,
    team_ids: filter.team_ids,
    expense_types: filter.type_ids,
  };
  const categoryPieChart = useMemo(
    () =>
      spendingCategoriesData.map(({ leftColumn }) => ({
        category: leftColumn?.category,
        amount: leftColumn?.currentSpending,
        color: leftColumn?.color,
      })),
    [spendingCategoriesData]
  );
  const fetchCategories = useCallback(() => {
    getSpendingCategories(removeEmpty(payload))
      .then((response) => {
        const { data = [] } = response?.data || {};
        const totalCategories =
          Boolean(data) && Array.isArray(data) ? [...data].sort((a, b) => a.amount - b.amount) : [];
        const firstFiveCategories = totalCategories.length > 5 ? totalCategories.slice(0, 5) : totalCategories;
        const otherCategories = totalCategories.slice(5, totalCategories.length);

        let otherCategory = {
          category_name: "",
          change: "",
          currency: "",
          current_spending: "",
          past_spending: "",
        };

        if (otherCategories.length > 0) {
          otherCategory = otherCategories.reduce((acc, curVal, curInd) => {
            const change = Number(acc.change.replace("%", "")) + Number(curVal.change.replace("%", ""));

            return {
              category_name: "Others",
              change: `${Math.round((change + Number.EPSILON) * 100) / 100}%`,
              currency: currency,
              current_spending: acc.current_spending + curVal.current_spending,
              past_spending: acc.past_spending + curVal.past_spending,
            };
          });
        }

        const categories = [...firstFiveCategories, { ...otherCategory }].filter((item) => item.currency !== "");

        setSpendingCategories({
          loading: false,
          data: categories.map((item: ISpendingCategory, index: number) => {
            const leftColumn = {
              category: item.category_name,
              color: index <= 5 ? pieColors[index] : "#C5C7CD",
              currentSpending: item.current_spending,
              currency: item.currency,
            };
            const rightColumn = {
              category: item.category_name,
              pastSpending: item.past_spending,
              change: item.change,
              currency: item.currency,
            };

            return {
              key: index,
              leftColumn,
              rightColumn,
              disabled: item.category_name === "Others" ? true : false,
            };
          }),
        });
      })
      .catch((error) => {
        setSpendingCategories({
          loading: false,
          data: [],
        });
        if (error.response && error.response.status !== 401) {
          checkRetrieveDataError(true);
        }
      });
  }, [filter]);

  useEffect(() => {
    setSpendingCategories({
      loading: true,
      data: [],
    });
    fetchCategories();
  }, [fetchCategories]);

  const handleSelectChart = (data: any) => {
    setSelectedCategory(data);
  };

  return {
    selectedCategory,
    categoryPieChart,
    spendingCategoriesData,
    spendingCategoriesLoading,
    handleSelectChart,
    fetchCategories,
  };
}

export function useTeamList({ filter, currency, checkRetrieveDataError }: Props) {
  const [{ loading: spendingTeamsLoading, data: spendingTeamsData }, setSpendingTeams] = useState({
    loading: false,
    data: [] as ISpendingTeamsData[],
  });
  const teamList = useSelector((state: any) => state.teamList?.data?.payload?.teams);
  const { totalTeamCategories, colorMap, totalTeamAmount } = useMemo(() => {
    const results = {};
    let totalTeamAmount = 0;
    spendingTeamsData.forEach((item) => {
      if (!item.categorySpendDetails?.length) return;

      item.categorySpendDetails.forEach((categoryDetail) => {
        if (!results[categoryDetail.categoryName]) results[categoryDetail.categoryName] = 0;
        results[categoryDetail.categoryName] += categoryDetail.amount;
        totalTeamAmount += categoryDetail.amount;
      });
    });

    const totalTeamCategories = Object.keys(results)
      .map((key) => ({ categoryName: key, amount: results[key] }))
      .sort((a, b) => b.amount - a.amount)
      .map((item, index) => ({ ...item, color: pieColors[index] }));

    const topTenCategories = totalTeamCategories.length > 10 ? totalTeamCategories.slice(0, 10) : totalTeamCategories;
    const firstFiveCategories = topTenCategories.length > 5 ? topTenCategories.slice(0, 5) : topTenCategories;
    const otherCategories = topTenCategories
      .slice(5, topTenCategories.length)
      .map((item) => ({ ...item, color: "#C5C7CD" }));

    let otherCategory = {
      categoryName: "",
      amount: 0,
      color: "",
    };

    if (otherCategories.length > 0) {
      otherCategory = otherCategories.reduce((acc, curVal, curInd) => {
        return {
          categoryName: "Others",
          amount: acc.amount + curVal.amount,
          color: "#AEC6E4",
        };
      });
    }

    const categories = [...firstFiveCategories, { ...otherCategory }, ...otherCategories].filter(
      (item) => item.color !== ""
    );

    const colorMap = categories.reduce(
      (prev, curr) => ({
        ...prev,
        [curr.categoryName]: curr.color,
      }),
      {}
    );
    return { totalTeamCategories: categories, colorMap, totalTeamAmount };
  }, [spendingTeamsData]);

  const fetchTeams = useCallback(() => {
    const payload = {
      past_start_date: calculateDate(filter.startDate, filter.differences, filter.otherCustomFilter),
      past_end_date: calculateDate(filter.endDate, filter.differences, filter.otherCustomFilter, true),
      current_start_date: filter.startDate,
      current_end_date: filter.endDate,
      currency,
      team_ids: filter.team_ids,
      expense_types: filter.type_ids,
    };

    getSpendingTeams(removeEmpty(payload))
      .then((response) => {
        setSpendingTeams({
          loading: false,
          data: (response?.data?.data || [])
            .map((item: ISpendingTeam) => ({
              key: item.team_id,
              teamId: item.team_id,
              teamName: (Array.isArray(teamList) && teamList.find((team) => team.id === item.team_id)?.team_name) || "",
              change: item.change,
              currentSpending: item.current_spending,
              pastSpending: item.past_spending,
              currency: item.currency,
              categorySpendDetails: (item.category_spend_detail || [])
                .map(({ amount, currency, category_name }) => ({
                  categoryName: category_name,
                  amount,
                  currency,
                }))
                .sort((a, b) => b.amount - a.amount),
              totalAmount: item.category_spend_detail.reduce((acc, curr) => acc + curr.amount, 0),
            }))
            .sort((a, b) => b.totalAmount - a.totalAmount),
        });
      })
      .catch((error) => {
        setSpendingTeams({
          loading: false,
          data: [],
        });
        if (error.response && error.response.status !== 401) {
          checkRetrieveDataError(true);
        }
      });
  }, [filter, teamList]);

  useEffect(() => {
    setSpendingTeams({
      loading: true,
      data: [],
    });

    fetchTeams();
  }, [fetchTeams]);

  return {
    spendingTeamsLoading,
    spendingTeamsData,
    fetchTeams,
    totalTeamCategories,
    colorMap,
    totalTeamAmount,
  };
}
