import React from "react";

import { DropdownFilter } from "Views/SubscriptionManagement/Filters/DropdownFilter";

import { ISubscriptionManagementFilterKeys } from "Views/SubscriptionManagement/@types/filters";
import { SUBSCRIPTION_STATUS } from "Views/SubscriptionManagement/Constants";

const SUBSCRIPTION_STATUS_OPTIONS = [
  {
    title: "Active",
    id: SUBSCRIPTION_STATUS.ACTIVE,
  },
  {
    title: "Inactive",
    id: SUBSCRIPTION_STATUS.CANCELLED,
  },
  {
    title: "Expired",
    id: SUBSCRIPTION_STATUS.EXPIRED,
  },
];

export const StatusFilter = () => (
  <DropdownFilter
    id={ISubscriptionManagementFilterKeys.STATUS}
    defaultValue="Status"
    options={SUBSCRIPTION_STATUS_OPTIONS}
  />
);
