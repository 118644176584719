import {
  initialRoleState,
  IUpdateRoleError,
  IUpdateRoleReducer,
  IUpdateRoleSuccess,
  UPDATE_ROLE_ACTION_TYPES,
} from "Redux/Actions/Employee/Update/Role/types";

export const updateRoleReducer = (
  state: IUpdateRoleReducer = initialRoleState,
  action: { type: UPDATE_ROLE_ACTION_TYPES; data: IUpdateRoleSuccess | IUpdateRoleError }
): IUpdateRoleReducer => {
  switch (action.type) {
    case UPDATE_ROLE_ACTION_TYPES.FETCH_UPDATE_ROLE_REQUEST:
      return {
        ...state,
        loading: true,
        data: action.data,
      };
    case UPDATE_ROLE_ACTION_TYPES.FETCH_UPDATE_ROLE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: false,
      };
    case UPDATE_ROLE_ACTION_TYPES.FETCH_UPDATE_ROLE_ERROR:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: true,
      };
    case UPDATE_ROLE_ACTION_TYPES.CLEAR_UPDATE_ROLE:
      return initialRoleState;
    default:
      return state;
  }
};
