/**
 * @fileoverview This module assembles various sidebar menus into two main sections: TopMenuList and BottomMenuList.
 * TopMenuList encompasses the "Overview", "Expenses", and "Manage" subsections.
 * BottomMenuList focuses exclusively on utility functions.
 *
 * @author Sushmitha, Bakshi
 * @created 25 Aug 2023
 * @lastModified 30 Aug 2023
 */
import { ISidebarSectionConfig } from "Views/Navigation/@types";

import { useManageMenu } from "./Manage";
import { useUtilityMenu } from "./Utility";
import { useOverviewMenu } from "./Overview";
import { useExpensesMenu } from "./Expenses";

/**
 * `TopMenuList` function generates a list of major menu sections for the top portion
 * of the sidebar navigation. It includes subsections such as "Overview", "Expenses", and "Manage".
 *
 * Each section has an `id` for identification, a `title` for its display name, and a
 * `list` which holds the individual menu items related to that section.
 *
 * @returns {ISidebarSectionConfig[]} - An array of major sidebar sections for the top part.
 */
export const useTopMenuList = (): ISidebarSectionConfig[] => {
  return [
    { id: "TOP_MENU_SECTION_01", title: "Overview", list: useOverviewMenu() },
    { id: "TOP_MENU_SECTION_02", title: "Expenses", list: useExpensesMenu() },
    { id: "TOP_MENU_SECTION_03", title: "Manage", list: useManageMenu() },
  ];
};

/**
 * `BottomMenuList` references the utility functions' menu. This menu generally includes
 * options for settings, help, and other ancillary functionalities.
 */
export const useBottomMenuList = useUtilityMenu;
