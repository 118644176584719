import { POPOVER_WIDTH } from "Modules/DS/Popover";

import {
  IBasicDropdownsState,
  ICommonDropdownProps,
  IMultipleSelectDropdownsState,
  ISingleSelectDropdownsState,
} from "./dropdown.d";

export enum SELECT_DROPDOWN_TYPE {
  SINGLE = "single",
  MULTIPLE = "multiple",
}

export const initialDropdownProps: ICommonDropdownProps = {
  id: "",
  title: "",
  selected: false,
  searchable: false,
  searchPlaceholder: "",
  onClickOutside: () => null,
  clearDropdown: () => null,
  popoverWidth: "inherit--width" as POPOVER_WIDTH,
};

export const initialBasicDropdownStates: IBasicDropdownsState = {
  value: "",
  setValue: () => null,
  loading: false,
  setLoading: () => null,
};

export const initialSingleSelectDropdownStates: ISingleSelectDropdownsState = {
  selected: "",
  setSelected: () => null,
  title: "",
  setTitle: () => null,
  icon: "",
  setIcon: () => null,
  loading: false,
  setLoading: () => null,
};

export const initialMultipleSelectDropdownStates: IMultipleSelectDropdownsState = {
  selectedIds: [],
  setSelectedIds: () => null,
  title: "",
  setTitle: () => null,
  icon: "",
  setIcon: () => null,
  loading: false,
  setLoading: () => null,
};

export * from "./dropdown.d";
export * from "./types.d";
