import * as React from "react";
import { Select as AntdSelect, Checkbox } from "antd";
import { useSelector, useDispatch } from "react-redux";
import classNames from "classnames";
import { teamEmpFunc } from "Redux/Actions";
import { getIsLimitModelOrg } from "customHooks/Card";
import { KYC_STATUS } from "Views/Card/types";
import { getKycStatus } from "utility/Card/index";
import Select from "Views/Card/Components/Select";
import LoaderIcon from "Views/State/Loading/LoaderIcon";
import { Tooltip } from "Views/Card/Components/Tooltip";
import UnverifiedTag from "Views/Card/CreateOrRequest/Steps/TitleAndTeam/CardholderSelector/UnverifiedTag";
import "Views/Card/CreateOrRequest/Steps/index.scss";

import styles from "Views/Card/CreateOrRequest/Steps/TitleAndTeam/CardholderSelector/index.module.scss";

const { Option } = AntdSelect;

interface TeamSelectorProps {
  selectedCardholderId: string;
  selectedTeamId: string;
  onSelect: (id: string) => void;
}

const CardholderSelector = ({ selectedCardholderId, selectedTeamId, onSelect }: TeamSelectorProps) => {
  const dispatch = useDispatch();
  const isLimitModelOrg = getIsLimitModelOrg();
  const { data = {}, loading } = useSelector((state: any) => state?.empReducer || {});
  const teamMembers = data?.payload?.team_members || [];
  const {
    id: userId,
    is_wallet_user = false,
    kyc,
  } = useSelector((state: any) => state?.userInfoReducer?.data?.payload?.user || {});

  const loggedInUserKycStatus = getKycStatus(kyc);

  React.useEffect(() => {
    selectedTeamId && dispatch(teamEmpFunc(selectedTeamId, !isLimitModelOrg));
  }, [selectedTeamId, dispatch]);

  const formattedTeamMembers =
    (Array.isArray(teamMembers) &&
      teamMembers?.map((member) => ({
        key: member?.id,
        value: member?.id,
        id: member?.id,
        label: member?.name,
        kycStatus: getKycStatus(member?.kyc),
      }))) ||
    [];

  const loggedInUserPresentInSelectedTeam =
    formattedTeamMembers?.length > 0 && formattedTeamMembers.some((teamMember) => teamMember?.value === userId);

  const getOption = (member) => {
    const isDisabled = member?.kycStatus !== KYC_STATUS.APPROVED;
    const firstLetterClassname = classNames(styles.firstLetter, {
      [styles.firstLetterDisabled]: isDisabled,
    });
    const optionClassname = classNames({ [styles.optionDisabled]: isDisabled });
    return (
      <Option
        key={member.id}
        value={member.id}
        id={member.id}
        label={member.label}
        disabled={isDisabled}
        className={optionClassname}
      >
        <div className={firstLetterClassname}>{member?.label?.charAt(0)?.toUpperCase()}</div>
        {member.label}
        {isDisabled && <UnverifiedTag />}
      </Option>
    );
  };

  const userCanCreateCardForHimself =
    loggedInUserKycStatus === KYC_STATUS.APPROVED && (is_wallet_user || isLimitModelOrg);

  const assignToMyselfCheckbox = (
    <Checkbox
      className={styles.checkbox}
      checked={selectedCardholderId === userId}
      onChange={(event) => {
        onSelect(event?.target?.checked ? userId : "");
      }}
      disabled={!userCanCreateCardForHimself}
    >
      Create this card for myself
    </Checkbox>
  );

  const wrapWithUnverfiedUserTooltip = (children: JSX.Element) => (
    <Tooltip tooltipText="Verify your account on the Spenmo mobile app to start using cards" placement="topLeft">
      {children}
    </Tooltip>
  );

  return (
    <>
      <Select
        value={selectedCardholderId || undefined}
        placeholder="Search for employee"
        onSelect={(value) => onSelect(value)}
        filterOption={(input, option) => option?.label?.toString()?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0}
        dropdownClassName="create-or-request-card-steps__dropdown"
        showArrow={false}
        showSearch
        getPopupContainer={(triggerNode) => triggerNode.parentElement}
        data-testid="cardholder-selector"
        optionLabelProp="label"
        notFoundContent={loading ? <LoaderIcon /> : undefined}
      >
        {formattedTeamMembers?.map(getOption)}
      </Select>
      <p className="create-or-request-card-steps__help-text">Cardholder cannot be changed once the card is created</p>
      {loggedInUserPresentInSelectedTeam &&
        (userCanCreateCardForHimself ? assignToMyselfCheckbox : wrapWithUnverfiedUserTooltip(assignToMyselfCheckbox))}
    </>
  );
};

export default CardholderSelector;
