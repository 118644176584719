import React from "react";
import PropTypes from "prop-types";
import Button from "Modules/button";
import "./DownloadTemplate.scss";

const DownloadTemplate = ({ onDownload }) => {
  return (
    <div className={"download-template"}>
      <div className={"download-template__details"}>
        <h1 className={"download-template__details-title"}>Step 1 </h1>
        <span className={"download-template__dot"}> </span>
        <p className={"download-template__details-titleDetail"}>Download Template</p>
      </div>
      <Button className={"download-template__btn"} action={onDownload} rounded>
        <span className={"download-template__btn-text"}>Download Template</span>
      </Button>
    </div>
  );
};

DownloadTemplate.propTypes = {
  onDownload: PropTypes.func,
};

export default DownloadTemplate;
