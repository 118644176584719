import React from "react";
import PropTypes from "prop-types";
import TopUpBankTransfer from "./TopUpBankTransfer";
import TopUpCreditCard from "./TopUpCreditCard";
import TopUpPayNow from "./TopUpPayNow";
import { TOPUP_PAYMENT_TYPES } from "../../../constants/TopUp.constant";

const {
  BANK_TRANSFER,
  BANK_TRANSFER_WITH_VIRTUAL_ACCOUNT,
  PAY_NOW,
  CREDIT_CARD,
  CREDIT_CARD_WITH_FAST,
  USD_VIRTUAL_ACCOUNT_OR_CARD,
  TRANSFER_BELOW_50,
  TRANSFER_ABOVE_50,
} = TOPUP_PAYMENT_TYPES;

const TopUpPaymentType = (props) => {
  const { paymentType, currencyCode, trackTopupNow } = props;

  switch (paymentType) {
    case BANK_TRANSFER:
    case BANK_TRANSFER_WITH_VIRTUAL_ACCOUNT:
    case TRANSFER_ABOVE_50:
      return <TopUpBankTransfer currencyCode={currencyCode} />;
    case TRANSFER_BELOW_50:
      return <TopUpBankTransfer currencyCode={currencyCode} filterBy={{ isVirtualAccount: true }} />;
    case CREDIT_CARD:
    case CREDIT_CARD_WITH_FAST:
    case USD_VIRTUAL_ACCOUNT_OR_CARD:
      return <TopUpCreditCard currencyCode={currencyCode} trackTopupNow={trackTopupNow} />;
    case PAY_NOW:
      return <TopUpPayNow currencyCode={currencyCode} trackTopupNow={trackTopupNow} />;
    default:
      return <div>No selected payment type</div>;
  }
};

TopUpPaymentType.propTypes = {
  paymentType: PropTypes.string,
  currencyCode: PropTypes.string,
  trackTopupNow: PropTypes.func,
};

export default TopUpPaymentType;
