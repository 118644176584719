import React from "react";
import { Typography } from "@spenmo/splice";

import styles from "./Title.module.scss";

interface IMultiLineTitleProps {
  title: string;
  subtitle: string;
  captionSubtitle?: boolean;
}

export const MultiLineTitle = ({ title, subtitle, captionSubtitle = false }: IMultiLineTitleProps) => {
  return (
    <div className={styles.multiLineTitle}>
      <span>{title}</span>
      <br />
      <Typography
        variant="body-content"
        size={captionSubtitle ? "caption-m" : "s"}
        weight={captionSubtitle ? undefined : 600}
      >
        {subtitle}
      </Typography>
    </div>
  );
};
