import { getData } from "API/Client";

export const getBillAttachment = (invoiceId: string) => {
  return getData(`/ms/b2b-accounting/v1/xero/bills/${invoiceId}/attachments`);
};

export const getBillAttachmentContent = (url: string, mimeType: string) => {
  return getData(url, "", null, {
    responseType: "blob",
    headers: {
      "Content-Type": mimeType,
    },
  });
};
