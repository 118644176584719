import React from "react";

import { errorIcon } from "assets/img";

import styles from "./index.module.scss";

export const ErrorState = () => {
  return (
    <div className={styles.container}>
      <img src={errorIcon} alt="error icon" />
      <h3>Failed to Load Data</h3>
      <p>Click the button below to reload</p>
      <button onClick={() => window.location.reload()}>Reload</button>
    </div>
  );
};
