import { FormInstance } from "antd/lib/form";

import {
  GENDER_OF_INDIVIDUAL_OPTIONS,
  GENDER_OF_INDIVIDUAL_OPTIONS_LABEL,
  GENDER_OF_INDIVIDUAL_OPTIONS_VALUE,
  KYX_FORMS_KEYS,
} from "Views/KYX/Constants/Forms";
import { IFetchFormSubmissionPayload, IFormElement, IGetFormConfigsPayload } from "Views/KYX/@types";
import { FORM_FIELD_TYPES } from "Views/KYX/Constants";
import { disableFutureDates } from "Views/KYX/Helper";

export const usePersonnelDetails = (
  form: FormInstance,
  formData: IFetchFormSubmissionPayload,
  remarks: IGetFormConfigsPayload,
  currentKey: string,
  isFormDisabled: boolean,
  isFormVerified: boolean
): IFormElement[] => {
  const fieldError = "This field cannot be empty";

  const DATE_OF_BIRTH: IFormElement = {
    key: KYX_FORMS_KEYS.PERSONNEL_DATA_PERSON + KYX_FORMS_KEYS.PERSON_DATA_DOB,
    col: { span: 12 },
    element: {
      type: FORM_FIELD_TYPES.DATEPICKER,
      props: {
        formProps: {
          name: [KYX_FORMS_KEYS.PERSON_DATA_DOB],
          label: "Date of Birth",
          required: isFormVerified ? false : true,
          rules: [
            {
              required: isFormVerified ? false : true,
              message: fieldError,
            },
          ],
        },
        dateProps: {
          placeholder: "dd/mm/yyyy",
          disabledDate: disableFutureDates,
          format: "DD/MM/YYYY",
          disabled: isFormDisabled || isFormVerified,
        },
      },
    },
  };

  const GENDER: IFormElement = {
    key: KYX_FORMS_KEYS.PERSONNEL_DATA_PERSON + KYX_FORMS_KEYS.PERSON_DATA_GENDER,
    col: { span: 12 },
    element: {
      type: FORM_FIELD_TYPES.RADIO,
      props: {
        formProps: {
          name: KYX_FORMS_KEYS.PERSON_DATA_GENDER,
          label: "Gender",
          required: isFormVerified ? false : true,
          rules: [
            {
              required: isFormVerified ? false : true,
              message: fieldError,
            },
          ],
        },
        radioProps: {
          name: KYX_FORMS_KEYS.PERSON_DATA_GENDER,
          options: [
            {
              label: GENDER_OF_INDIVIDUAL_OPTIONS_LABEL[GENDER_OF_INDIVIDUAL_OPTIONS.MALE],
              value: GENDER_OF_INDIVIDUAL_OPTIONS_VALUE[GENDER_OF_INDIVIDUAL_OPTIONS.MALE],
            },
            {
              label: GENDER_OF_INDIVIDUAL_OPTIONS_LABEL[GENDER_OF_INDIVIDUAL_OPTIONS.FEMALE],
              value: GENDER_OF_INDIVIDUAL_OPTIONS_VALUE[GENDER_OF_INDIVIDUAL_OPTIONS.FEMALE],
            },
          ],
          disabled: isFormDisabled || isFormVerified,
        },
      },
    },
  };

  const COUNTRY_OF_RESIDENCE: IFormElement = {
    key: KYX_FORMS_KEYS.PERSONNEL_DATA_PERSON + KYX_FORMS_KEYS.PERSON_DATA_RESIDENTIAL_COUNTRY,
    col: { span: 12 },
    element: {
      type: FORM_FIELD_TYPES.COUNTRYSELECT,
      props: {
        formProps: {
          name: [KYX_FORMS_KEYS.PERSON_DATA_RESIDENTIAL_COUNTRY],
          label: "Country of Residence",
          required: true,
          rules: [
            {
              required: true,
              message: fieldError,
            },
          ],
        },
        selectProps: {
          placeholder: "Select country of residence",
          isnationality: false,
          disabled: isFormDisabled || isFormVerified,
        },
      },
    },
  };

  const RESIDENTIAL_ADDRESS: IFormElement = {
    key: KYX_FORMS_KEYS.PERSONNEL_DATA_PERSON + KYX_FORMS_KEYS.PERSON_DATA_RESIDENTIAL_ADDRESS,
    col: { span: 24 },
    element: {
      type: FORM_FIELD_TYPES.INPUT,
      props: {
        formProps: {
          name: KYX_FORMS_KEYS.PERSON_DATA_RESIDENTIAL_ADDRESS,
          label: "Residential Address",
          required: true,
          rules: [
            {
              required: true,
              message: fieldError,
            },
            { whitespace: true, message: fieldError },
          ],
        },
        inputProps: {
          name: KYX_FORMS_KEYS.PERSON_DATA_RESIDENTIAL_ADDRESS,
          placeholder: "Please enter residential address",
          disabled: isFormDisabled || isFormVerified,
        },
      },
    },
  };

  const POSTAL_CODE: IFormElement = {
    key: KYX_FORMS_KEYS.PERSONNEL_DATA_PERSON + KYX_FORMS_KEYS.COMPANY_ADDRESS_POSTAL_CODE,
    col: { span: 12 },
    element: {
      type: FORM_FIELD_TYPES.INPUT,
      props: {
        formProps: {
          name: [KYX_FORMS_KEYS.COMPANY_ADDRESS_POSTAL_CODE],
          label: "Postal Code",
          required: true,
          rules: [
            {
              required: true,
              message: fieldError,
            },
          ],
        },
        inputProps: {
          name: KYX_FORMS_KEYS.COMPANY_ADDRESS_POSTAL_CODE,
          placeholder: "Please enter postal code",
          disabled: isFormDisabled || isFormVerified,
        },
      },
    },
  };

  return [DATE_OF_BIRTH, GENDER, COUNTRY_OF_RESIDENCE, RESIDENTIAL_ADDRESS, POSTAL_CODE];
};
