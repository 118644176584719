import { AxiosResponse } from "axios";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";

import { useEffect, useState } from "react";

interface IUseQuery {
  defaultData?: any;
  apiCall: () => Promise<AxiosResponse<any>>;
  customDataMapping?: (data: any) => any;
  run?: boolean;
  onSuccess?: (data: any) => void;
  onError?: (data: any) => void;
  ignoreLoadingState?: boolean;
}

export const useQuery = ({
  defaultData = {},
  apiCall,
  customDataMapping,
  onSuccess,
  onError,
  run = true,
}: IUseQuery) => {
  const [data, setData] = useState(defaultData);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const processApiCall = (request = apiCall, ignoreLoadingState = false) => {
    !ignoreLoadingState && setLoading(true);
    request()
      .then(({ data: responseData }) => {
        if (responseData.status === HTTP_STATUS_CODE.OK) {
          const mappedData = customDataMapping ? customDataMapping(responseData) : responseData.payload;
          setData(mappedData);
          onSuccess?.(mappedData);
        } else {
          throw responseData;
        }
      })
      .catch((err) => {
        setError(err);
        onError?.(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (run) {
      processApiCall();
    }
  }, [run]);

  return { data, setData, loading, error, refetch: processApiCall };
};
