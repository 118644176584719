import React from "react";
import classNames from "classnames";

import Icon, { IconList } from "Modules/Icon";

import { IFormProgress, IProgressOverview } from "Views/KYX/@types";
import { FORMS_LIST, RESUME_INTRO_PROGRESS_OVERVIEW_LAST_STEP_PROXY_VALUE } from "Views/KYX/Constants";

import styles from "./index.module.scss";

/**
 * A React Component that is used to render the Progress Overivew of the Resume Intro Screens when the user is logging in again to their last saved session.
 *
 * @param {IFormProgress[]} list - Accepts a list of type IFormProgress.
 * @param {number} lastStep - indicates which form filling step the user had progressed to.
 * @returns {JSX.Element} A JSX Element
 */

export const Progress = ({
  list,
  lastStep = RESUME_INTRO_PROGRESS_OVERVIEW_LAST_STEP_PROXY_VALUE,
}: IProgressOverview): JSX.Element => {
  const getProgressStep = (step: IFormProgress) => {
    const stepClass = classNames(styles.documentList, { [styles.disabled]: step.step > lastStep });

    const renderIcon = () => {
      return (
        <Icon
          className={styles.icon}
          icon={IconList.uploadSuccess}
          svgAttr={{ width: 24, height: 24, viewBox: "0 0 24 24", role: "step-icon" }}
        />
      );
    };

    return (
      <div className={stepClass} key={`document-${FORMS_LIST[step.id]}`}>
        {renderIcon()}
        <p className={styles.documentName}>{FORMS_LIST[step.id]}</p>
      </div>
    );
  };
  return <>{list?.map(getProgressStep)}</>;
};
