import React from "react";
import { Typography, Popover } from "antd";
import classNames from "classnames";

import { currencyFormatterV2 as currencyFormatter } from "utility";
import s from "./MerchantDistribution.module.scss";
import analyticsStyles from "../../Analytics.module.scss";
import { percentageStyles } from "Views/Analytics";
import {
  calculateDate,
  checkChangePercentage,
  getCurrentAndPastSpendingText,
  dateFormatter4,
} from "Views/Analytics/utils";
import { IAnalyticsFilter } from "Views/Analytics/@types";

const { Text } = Typography;

export function getMerchantColumns(filter: IAnalyticsFilter) {
  const merchantColumns = [
    {
      title: "Top merchants by spending",
      dataIndex: "merchant",
      key: "merchant",
      render: (text) => (
        <>
          {Boolean(text) && <div className={s.avatar}>{text[0]?.toUpperCase() || "?"}</div>}
          <Popover arrowPointAtCenter={true} placement="bottom" overlayClassName="analytics-tooltip" content={text}>
            <Text className={s.merchantsName} style={{ maxWidth: 100 }} ellipsis>
              {text}
            </Text>
          </Popover>
        </>
      ),
      width: "26.2%",
    },
    {
      title: () => (
        <div className={classNames(analyticsStyles.tableHead, analyticsStyles.tableLastCol)}>
          {getCurrentAndPastSpendingText(filter.otherCustomFilter, false)}
          <br />
          {dateFormatter4(filter.startDate, filter.endDate)}
        </div>
      ),
      dataIndex: "currentSpending",
      key: "currentSpending",
      render: (amount, record) => (
        <div className={classNames(analyticsStyles.tableHead, analyticsStyles.tableLastCol)}>
          {currencyFormatter(amount, record.currency, true, 2)}
        </div>
      ),
      width: "30.25%",
    },
    {
      title: () => (
        <div className={classNames(analyticsStyles.tableHead, analyticsStyles.tableLastCol)}>
          {getCurrentAndPastSpendingText(filter.otherCustomFilter, true)}
          <br />
          {dateFormatter4(
            calculateDate(filter.startDate, filter.differences, filter.otherCustomFilter),
            calculateDate(filter.endDate, filter.differences, filter.otherCustomFilter, true)
          )}
        </div>
      ),
      dataIndex: "pastSpending",
      key: "pastSpending",
      render: (amount, record) => (
        <div className={classNames(analyticsStyles.tableHead, analyticsStyles.tableLastCol)}>
          {currencyFormatter(amount, record.currency, true, 2)}
        </div>
      ),
      width: "30.25%",
    },
    {
      title: () => <div className={classNames(analyticsStyles.tableHead, analyticsStyles.tableLastCol)}>% Change</div>,
      dataIndex: "change",
      key: "change",
      render: (change) => (
        <div
          className={classNames(
            analyticsStyles.tableCell,
            analyticsStyles.tableLastCol,
            percentageStyles[checkChangePercentage(change)]
          )}
        >
          {checkChangePercentage(change) === "positive" ? `+${change}` : change || "0%"}
        </div>
      ),
      width: "13.3%",
    },
  ];

  return merchantColumns;
}

/* Note: Need to move these svg to new file in public folder */
function FaceBookLogo() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ verticalAlign: "-0.4375rem" }}
    >
      <g clipPath="url(#clip0_2689_9927)">
        <rect width="24" height="24" rx="12" fill="white" />
        <path
          d="M12 22.4512C17.5228 22.4512 22 17.974 22 12.4512C22 6.92833 17.5228 2.45117 12 2.45117C6.47715 2.45117 2 6.92833 2 12.4512C2 17.974 6.47715 22.4512 12 22.4512Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 4C16.4183 4 20 7.58173 20 12C20 16.4183 16.4183 20 12 20C7.58173 20 4 16.4183 4 12C4 7.58173 7.58173 4 12 4Z"
          fill="url(#paint0_linear_2689_9927)"
        />
        <path
          d="M13.0451 20.4269V14.2141H14.7966L15.0288 12.0274H13.0451L13.0481 10.9329C13.0481 10.3626 13.1022 10.0571 13.9214 10.0571H15.0165V7.87012H13.2646C11.1602 7.87012 10.4197 8.93082 10.4197 10.7149V12.0276H9.10788V14.2144H10.4197V20.3388C10.9307 20.4407 11.4591 20.4945 12 20.4945C12.3495 20.4945 12.6986 20.4719 13.0451 20.4269Z"
          fill="white"
        />
      </g>
      <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" stroke="#F4F6F9" />
      <defs>
        <linearGradient
          id="paint0_linear_2689_9927"
          x1="12"
          y1="3.85423"
          x2="12"
          y2="20.6822"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#00B2FF" />
          <stop offset="1" stop-color="#006AFF" />
        </linearGradient>
        <clipPath id="clip0_2689_9927">
          <rect width="24" height="24" rx="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
