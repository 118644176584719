import { useSelector, useDispatch } from "react-redux";
import { CallAPIWithLoader } from "Redux/Actions/dynamicActions";
import { CARD_LIST_TABS, IFilter, CARD_LIST_TABS_DATA_KEYS } from "Views/Card/List/types";

const LIMIT_PER_PAGE = 10;

export interface IApiDetailsMapping {
  [key: string]: {
    name: string;
    apiIdentifier: string;
    type?: string;
    status?: string;
  };
}

const apiDetailsMapping: IApiDetailsMapping = {
  [CARD_LIST_TABS.CARD_LIST]: {
    name: CARD_LIST_TABS_DATA_KEYS.CARD_LIST,
    apiIdentifier: "getAllCards",
  },
  [CARD_LIST_TABS.RECEIVED_CARD_REQUEST]: {
    name: CARD_LIST_TABS_DATA_KEYS.RECEIVED_CARD_REQUEST,
    apiIdentifier: "GetAllCardsRequests",
    type: "received_request",
    status: "requested",
  },
  [CARD_LIST_TABS.SENT_CARD_REQUEST]: {
    name: CARD_LIST_TABS_DATA_KEYS.SENT_CARD_REQUEST,
    apiIdentifier: "GetAllCardsRequests",
    type: "sent_request",
    status: "requested,declined",
  },
};

export const useCardListData = () => {
  const dispatch = useDispatch();
  const { cardListData, receivedCardRequestsData, sentCardRequestsData } = useSelector((state: any) => ({
    cardListData: state?.dynamicData?.[CARD_LIST_TABS_DATA_KEYS.CARD_LIST] || {},
    receivedCardRequestsData: state?.dynamicData?.[CARD_LIST_TABS_DATA_KEYS.RECEIVED_CARD_REQUEST] || {},
    sentCardRequestsData: state?.dynamicData?.[CARD_LIST_TABS_DATA_KEYS.SENT_CARD_REQUEST] || {},
  }));

  const fetchCardListData = (page: number, filters: IFilter, view?: string | string[]) => {
    const params: (number | IFilter | string)[] = [page, LIMIT_PER_PAGE, filters];
    let requestApi = Object.values(apiDetailsMapping);
    if (view) {
      let refetchData = typeof view === "string" ? [view] : view;
      requestApi = refetchData.map((viewToFetch) => apiDetailsMapping[viewToFetch]);
    }

    requestApi.forEach(({ apiIdentifier, name, type, status }) => {
      type && params.push(type);
      status && params.push(status);
      dispatch(CallAPIWithLoader(name, apiIdentifier, params));
    });
  };

  return [cardListData, receivedCardRequestsData, sentCardRequestsData, fetchCardListData];
};
