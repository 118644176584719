import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { InputNumber, Radio } from "antd";
import "./CustomRepeatForm.scss";

const REPEAT_TYPES = [
  { value: "DAY", label: "Day" },
  { value: "WEEK", label: "Week" },
  { value: "MONTH", label: "Month" },
  { value: "YEAR", label: "Year" },
];

const CustomRepeatForm = ({
  values,
  encodeValue = (value) => value,
  parseValue = (value) => value,
  setValues = () => {},
}) => {
  const parsedValues = parseValue(values);
  const isRecurring = parsedValues?.intervalType !== "NONE";
  const defaultIntervalType = isRecurring ? parsedValues?.intervalType : REPEAT_TYPES[0].value;
  const [interval, setInterval] = useState(parsedValues?.interval || 1);
  const [intervalType, setIntervalType] = useState(defaultIntervalType);
  const [type, setType] = useState(isRecurring ? parsedValues?.type : "UNLIMITED");
  const [endsAfter, setEndsAfter] = useState(parsedValues?.endsAfter || 1);

  useEffect(() => {
    const value = encodeValue({
      interval,
      intervalType,
      type,
      endsAfter,
    });
    setValues(value);
  }, [interval, intervalType, type, endsAfter, setValues, encodeValue]);

  return (
    <div className="repeat-form">
      <h4>Repeat every</h4>
      <InputNumber
        className="repeat-interval"
        min={1}
        maxLength={2}
        value={interval}
        onChange={(value) => {
          if (value) {
            setInterval(value);
          }
        }}
      />
      <Radio.Group
        className="repeat-interval-type"
        options={REPEAT_TYPES}
        value={intervalType}
        onChange={(e) => {
          setIntervalType(e.target.value);
        }}
        optionType="button"
      />
      <h4>End</h4>
      <Radio.Group
        className="repeat-unlimited"
        value={type === "UNLIMITED"}
        onChange={(e) => {
          setType(e.target.value ? "UNLIMITED" : "LIMITED");
        }}
      >
        <Radio value={true}>Never</Radio>
        <Radio value={false}>
          Ends after
          <InputNumber
            className="repeat-ends-after"
            min={1}
            maxLength={2}
            value={endsAfter}
            onChange={(value) => {
              setEndsAfter(value);
            }}
            disabled={type === "UNLIMITED"}
          />
          occurrences
        </Radio>
      </Radio.Group>
    </div>
  );
};

CustomRepeatForm.propTypes = {
  values: PropTypes.string,
  setValues: PropTypes.func,
  encodeValue: PropTypes.func,
  parseValue: PropTypes.func,
};

export default CustomRepeatForm;
