import { IBillImportedFromXeroData } from "Modules/BillImportedFromXeroBanner/useFetchInvoiceLink";
import { ITrxnDetailsExpenseData, ITrxnDetailsSaveAction } from "./detail.d";

/* enum */

export enum ATTACHMENT_FILTER {
  YES = "yes",
  NO = "no",
  NA = "na",
}

export enum GET_TRXN_REQUEST_PARAMS {
  LIMIT = "limit",
  PREVIOUS_PAGE = "previous_page",
  SEARCH_AFTER = "search_after",
  STATUS = "status",
  TEAM_ID = "team_ids",
  TEAM_NAME = "team_names",
  REQUESTER_ID = "requester_ids",
  REQUESTER_NAME = "requester_names",
  RECIPIENT_ID = "recipient_id",
  RECIPIENT_NAME = "recipient_names",
  CATEGORY = "categories",
  SEARCH = "search",
  MIN_AMOUNT = "min_amount",
  MAX_AMOUNT = "max_amount",
  TYPES = "types",
  START_DATE = "start_date",
  END_DATE = "end_date",
  ATTACHMENT = "attachment",
  EXCLUDE_TYPES = "exclude_types",
  ACCOUNTING_SEARCH = "accounting_search",
}

export enum GET_TRXN_RESPONSE_PARAMS {
  ID = "id",
  TRXN_NUMBER = "transaction_number",
  TYPE = "type",
  TRXN_TYPE = "transaction_type",
  STATUS = "status",
  DESCRIPTION = "description",
  CURRENCY = "currency",
  AMOUNT = "amount",
  PREVIOUS_BALANCE = "previous_balance",
  BALANCE = "balance",
  ORG_ID = "organisation_id",
  ORG_NAME = "organisation_name",
  TEAM_ID = "team_id",
  TEAM_NAME = "team_name",
  PAYER_ID = "payer_id", // RequesterID,
  PAYER_NAME = "payer_name", // RequesterName
  PAYEE_ID = "payee_id", // RecipientID
  PAYEE_NAME = "payee_name", // RecipientName
  CATEGORY = "category",
  TAX_AMOUNT = "tax_amount",
  NOTES = "notes",
  RECEIPTS = "receipts",
  LINE_ITEMS = "line_items",
  TAGS = "tags",
  URL = "url",
  SORT = "sort",
  CREATED_AT = "created_at",
  UPDATED_AT = "updated_at",
  FX_RATE = "foreign_exchange_rate",
  FOREIGN_AMOUNT = "foreign_currency_amount",
  FOREIGN_CURRENCY = "foreign_currency_code",
}

export enum LIST_TRXN_RESPONSE_PARAMS {
  HAS_MORE = "has_more",
  COUNT = "count",
  DATA = "data",
  STATUS = "status",
  ERROR = "error",
}

export enum TRANSACTION_RECEIPTS_COUNT_RESPONSE_PARAMS {
  COUNT = "count",
}

/* types */

export type IAllTrxnFilter = IAllTrxnQuickFilter & IAllTrxnMoreFilter & ITrxnsPaginationFilterRequest;

export type IAllTrxnQuickFilter = ITrxnsDateFilterRequest &
  Pick<
    ICommonTrxnsFilterRequest,
    GET_TRXN_REQUEST_PARAMS.TYPES | GET_TRXN_REQUEST_PARAMS.REQUESTER_NAME | GET_TRXN_REQUEST_PARAMS.RECIPIENT_NAME
  >;

export type IAllTrxnMoreFilter = Pick<
  ICommonTrxnsFilterRequest,
  | GET_TRXN_REQUEST_PARAMS.CATEGORY
  | GET_TRXN_REQUEST_PARAMS.MAX_AMOUNT
  | GET_TRXN_REQUEST_PARAMS.MIN_AMOUNT
  | GET_TRXN_REQUEST_PARAMS.ATTACHMENT
>;

// /* interfaces */

export interface ITrxnsDateFilterRequest {
  [GET_TRXN_REQUEST_PARAMS.START_DATE]?: string;
  [GET_TRXN_REQUEST_PARAMS.END_DATE]?: string;
}

export interface ITrxnsPaginationFilterRequest {
  [GET_TRXN_REQUEST_PARAMS.SEARCH_AFTER]?: string[];
  [GET_TRXN_REQUEST_PARAMS.PREVIOUS_PAGE]?: boolean;
}

export interface ICommonTrxnsFilterRequest {
  [GET_TRXN_REQUEST_PARAMS.STATUS]?: string;
  [GET_TRXN_REQUEST_PARAMS.TEAM_ID]?: string[];
  [GET_TRXN_REQUEST_PARAMS.TEAM_NAME]?: string[];
  [GET_TRXN_REQUEST_PARAMS.REQUESTER_ID]?: string[];
  [GET_TRXN_REQUEST_PARAMS.REQUESTER_NAME]?: string[];
  [GET_TRXN_REQUEST_PARAMS.RECIPIENT_ID]?: string[];
  [GET_TRXN_REQUEST_PARAMS.RECIPIENT_NAME]?: string[];
  [GET_TRXN_REQUEST_PARAMS.CATEGORY]?: string[];
  [GET_TRXN_REQUEST_PARAMS.SEARCH]?: string;
  [GET_TRXN_REQUEST_PARAMS.MIN_AMOUNT]?: number;
  [GET_TRXN_REQUEST_PARAMS.MAX_AMOUNT]?: number;
  [GET_TRXN_REQUEST_PARAMS.TYPES]?: string[];
  [GET_TRXN_REQUEST_PARAMS.ATTACHMENT]?: ATTACHMENT_FILTER;
  [GET_TRXN_REQUEST_PARAMS.ACCOUNTING_SEARCH]?: string;
}

export interface IFilteredRequest extends ICommonTrxnsFilterRequest, ITrxnsDateFilterRequest {}

export interface IGetTrxnRequest extends IFilteredRequest, ITrxnsPaginationFilterRequest {
  [GET_TRXN_REQUEST_PARAMS.LIMIT]?: number;
  [GET_TRXN_REQUEST_PARAMS.EXCLUDE_TYPES]?: string[];
}

export interface ITrxnReceipts {
  url: string;
}

export interface ITrxnLineItem {
  [GET_TRXN_RESPONSE_PARAMS.ID]: string;
  [GET_TRXN_RESPONSE_PARAMS.CATEGORY]: string;
  [GET_TRXN_RESPONSE_PARAMS.AMOUNT]: number;
  [GET_TRXN_RESPONSE_PARAMS.TAX_AMOUNT]: number;
  [GET_TRXN_RESPONSE_PARAMS.TAGS]: string[];
  comment: string;
}

export interface IGetTrxnResponse {
  [GET_TRXN_RESPONSE_PARAMS.ID]: string;
  [GET_TRXN_RESPONSE_PARAMS.TRXN_NUMBER]: string;
  [GET_TRXN_RESPONSE_PARAMS.TYPE]: string;
  [GET_TRXN_RESPONSE_PARAMS.TRXN_TYPE]: string;
  [GET_TRXN_RESPONSE_PARAMS.STATUS]: string;
  [GET_TRXN_RESPONSE_PARAMS.DESCRIPTION]: string;
  [GET_TRXN_RESPONSE_PARAMS.CURRENCY]: string;
  [GET_TRXN_RESPONSE_PARAMS.AMOUNT]: number;
  [GET_TRXN_RESPONSE_PARAMS.PREVIOUS_BALANCE]: number;
  [GET_TRXN_RESPONSE_PARAMS.BALANCE]: number;
  [GET_TRXN_RESPONSE_PARAMS.ORG_ID]: string;
  [GET_TRXN_RESPONSE_PARAMS.ORG_NAME]: string;
  [GET_TRXN_RESPONSE_PARAMS.TEAM_ID]: string;
  [GET_TRXN_RESPONSE_PARAMS.TEAM_NAME]: string;
  [GET_TRXN_RESPONSE_PARAMS.PAYER_ID]: string;
  [GET_TRXN_RESPONSE_PARAMS.PAYER_NAME]: string;
  [GET_TRXN_RESPONSE_PARAMS.PAYEE_ID]: string;
  [GET_TRXN_RESPONSE_PARAMS.PAYEE_NAME]: string;
  [GET_TRXN_RESPONSE_PARAMS.CATEGORY]: string;
  [GET_TRXN_RESPONSE_PARAMS.TAX_AMOUNT]: number;
  [GET_TRXN_RESPONSE_PARAMS.NOTES]: string;
  [GET_TRXN_RESPONSE_PARAMS.RECEIPTS]: ITrxnReceipts[];
  [GET_TRXN_RESPONSE_PARAMS.LINE_ITEMS]: ITrxnLineItem[];
  [GET_TRXN_RESPONSE_PARAMS.TAGS]: string[];
  [GET_TRXN_RESPONSE_PARAMS.URL]: string;
  [GET_TRXN_RESPONSE_PARAMS.SORT]: [number, string];
  [GET_TRXN_RESPONSE_PARAMS.CREATED_AT]: string;
  [GET_TRXN_RESPONSE_PARAMS.UPDATED_AT]: string;
  [GET_TRXN_RESPONSE_PARAMS.FX_RATE]: number;
  [GET_TRXN_RESPONSE_PARAMS.FOREIGN_AMOUNT]: number;
  [GET_TRXN_RESPONSE_PARAMS.FOREIGN_CURRENCY]: string;
}

export interface ITrxnsSuccessData {
  [LIST_TRXN_RESPONSE_PARAMS.DATA]: IGetTrxnResponse[];
  [LIST_TRXN_RESPONSE_PARAMS.COUNT]: number;
  [LIST_TRXN_RESPONSE_PARAMS.HAS_MORE]: boolean;
}

export interface ITrxnsErrorData {
  [LIST_TRXN_RESPONSE_PARAMS.STATUS]: number;
  [LIST_TRXN_RESPONSE_PARAMS.ERROR]: string;
}

export type IGetListTrxnsResponse = ITrxnsSuccessData | ITrxnsErrorData;

export interface IGetTrxnsCountRequest extends IFilteredRequest {}

export interface IGetTrxnsCountResponse {
  [LIST_TRXN_RESPONSE_PARAMS.COUNT]: number;
}

export interface IGetTransactionReceiptsCountRequest extends IFilteredRequest {}
export interface IGetTransactionReceiptsCountResponse {
  [TRANSACTION_RECEIPTS_COUNT_RESPONSE_PARAMS.COUNT]: number;
}

export interface IDownloadCSVRequest extends IFilteredRequest {
  transaction_ids?: string[];
}

export interface IDownloadBankStatementRequest extends IFilteredRequest {
  email?: string;
}

export interface ISidePanelDetails extends ITrxnDetailsSaveAction {
  record: IGetTrxnResponse;
  visible?: boolean;
  attachments?: string[];
  disableEditing?: boolean;
  closeDetails: () => void;
  expenseData?: ITrxnDetailsExpenseData;
  isHistoricalRefund?: boolean;
  xeroBillBannerData?: IBillImportedFromXeroData;
  viewTransactionClickHandler?: () => void;
  relatedTransaction?: IGetTrxnResponse;
}
