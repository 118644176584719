import React from "react";
import AccountingSetup from "../Common/AccountingSetup";
import { findExpenseConfig } from "./config";
import { useLocation } from "react-router-dom";

const Setup = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  return (
    <AccountingSetup
      partner="xero"
      title="Xero Integration"
      config={findExpenseConfig(params.get("choose_connection") === "true")}
    />
  );
};

export default Setup;
