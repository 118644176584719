import APIclient from "API/Client";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";

export const FETCH_DELETE_REQUEST = "FETCH_DELETE_REQUEST";
export const FETCH_DELETE_SUCCESS = "FETCH_DELETE_SUCCESS";
export const FETCH_DELETE_FAILURE = "FETCH_DELETE_FAILURE";

export const fetchDeleteDataReq = () => {
  return {
    type: FETCH_DELETE_REQUEST,
    data: {},
  };
};

export const fetchDeleteDataSuccess = (data) => {
  return {
    type: FETCH_DELETE_SUCCESS,
    data: data,
  };
};

export const fetchDeleteDataError = (data) => {
  return {
    type: FETCH_DELETE_FAILURE,
    data: data,
  };
};

export const CategoryDeleteFunc = (categoryId) => {
  return (dispatch) => {
    dispatch(fetchDeleteDataReq());
    return (
      APIclient
        /*eslint no-process-env: "off"*/
        .postData("/api/categories/delete/", { category_id: categoryId })
        .then((data: { [propName: string]: any }) => {
          if (data.data.status > HTTP_STATUS_CODE.OK) {
            dispatch(fetchDeleteDataError(data.data));
            return Promise.reject();
          } else {
            dispatch(fetchDeleteDataSuccess(data.data));
          }
        })
        .catch((err) => {
          dispatch(fetchDeleteDataError(err));
          return Promise.reject();
        })
    );
  };
};
