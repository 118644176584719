import {
  CARD_LIMIT_TYPES,
  CARD_REQUEST_STATUS,
  CARD_TYPE,
  ISpendingLock,
  ISpendingLockWithNameAndId,
  SPENDING_LOCK_TYPES,
  KYC_STATUS,
} from "Views/Card/types";
import { DEFAULT_LIMIT_VALUES } from "constants/Team.constant";

export const getCardSpendingLimit = (spending_limits) => {
  const {
    daily_limit = null,
    yearly_limit = null,
    monthly_limit = null,
    total_limit = null,
    card_transaction_limit = null,
  } = spending_limits || {};
  switch (true) {
    case (daily_limit || daily_limit === 0) && daily_limit?.toString() !== DEFAULT_LIMIT_VALUES.NO_LIMIT_SET:
      return { cardLimit: daily_limit, cardLimitType: CARD_LIMIT_TYPES.dailyLimit };
    case (yearly_limit || yearly_limit === 0) && yearly_limit?.toString() !== DEFAULT_LIMIT_VALUES.NO_LIMIT_SET:
      return { cardLimit: yearly_limit, cardLimitType: CARD_LIMIT_TYPES.yearlyLimit };
    case (monthly_limit || monthly_limit === 0) && monthly_limit?.toString() !== DEFAULT_LIMIT_VALUES.NO_LIMIT_SET:
      return { cardLimit: monthly_limit, cardLimitType: CARD_LIMIT_TYPES.monthlyLimit };
    case (total_limit || total_limit === 0) && total_limit?.toString() !== DEFAULT_LIMIT_VALUES.NO_LIMIT_SET:
      return { cardLimit: total_limit, cardLimitType: CARD_LIMIT_TYPES.totalLimit };
    case (card_transaction_limit || card_transaction_limit === 0) &&
      card_transaction_limit?.toString() !== DEFAULT_LIMIT_VALUES.NO_LIMIT_SET:
      return { cardLimit: card_transaction_limit, cardLimitType: CARD_LIMIT_TYPES.cardTransactionLimit };
    default:
      return { cardLimit: null, cardLimitType: CARD_LIMIT_TYPES.unlimited };
  }
};

interface ISpendingLockData {
  spendingLockType: SPENDING_LOCK_TYPES;
  spendingLocks: ISpendingLock[];
}

const restructureMerchantLock = (merchant): ISpendingLock => ({
  name: merchant?.merchant_name || "",
  logo: merchant?.merchant_logo || "",
  altText: `${merchant?.merchant_name || "merchant"} logo`,
  id: merchant?.merchant_name || "",
});

const restructureCategoryLock = (category): ISpendingLock => ({
  name: category?.merchant_category_name || "",
  logo: category?.merchant_category_logo || "",
  altText: `${category?.merchant_category_name || "category"} logo`,
  id: category?.merchant_category_id || "",
});

export const getSpendingLocks = (spendingLockType: SPENDING_LOCK_TYPES, selectedSpendingLocks): ISpendingLock[] => {
  if (selectedSpendingLocks && selectedSpendingLocks !== "none") {
    let spendingLocks = [];
    if (typeof selectedSpendingLocks === "string") {
      spendingLocks = JSON.parse(selectedSpendingLocks);
    } else if (Array.isArray(selectedSpendingLocks)) {
      spendingLocks = selectedSpendingLocks;
    }
    if (spendingLocks && spendingLocks?.length > 0) {
      return spendingLocks.map(
        spendingLockType === SPENDING_LOCK_TYPES.MERCHANT ? restructureMerchantLock : restructureCategoryLock
      );
    }
  }
  return [];
};

export const getSpendingLockData = (spending_limits): ISpendingLockData => {
  const { merchant_limits, merchant_category_limits } = spending_limits || {};
  const merchantLocks = getSpendingLocks(SPENDING_LOCK_TYPES.MERCHANT, merchant_limits);
  if (merchantLocks?.length > 0) {
    return {
      spendingLockType: SPENDING_LOCK_TYPES.MERCHANT,
      spendingLocks: merchantLocks,
    };
  }
  const categoryLocks = getSpendingLocks(SPENDING_LOCK_TYPES.CATEGORY, merchant_category_limits);
  if (categoryLocks?.length > 0) {
    return {
      spendingLockType: SPENDING_LOCK_TYPES.CATEGORY,
      spendingLocks: categoryLocks,
    };
  }
  return {
    spendingLockType: SPENDING_LOCK_TYPES.NONE,
    spendingLocks: [],
  };
};

export const getCardType = (isPhysical: number | string) => {
  if (typeof isPhysical === "string") {
    return isPhysical === "0" ? CARD_TYPE.VIRTUAL : CARD_TYPE.PHYSICAL;
  } else {
    return isPhysical ? CARD_TYPE.PHYSICAL : CARD_TYPE.VIRTUAL;
  }
};

export const getCardRequestTagInfo = (cardStatus): { label: string; tagType: "default" | "error" } => {
  const isCardRequestPending = cardStatus === CARD_REQUEST_STATUS.REQUESTED;
  return {
    label: isCardRequestPending ? "Requested" : "Rejected",
    tagType: isCardRequestPending ? "default" : "error",
  };
};

export const formatCardNumber = (text: string = "") => {
  if (text?.length < 5) {
    return `**** **** **** ${text}`;
  } else {
    let formattedText = text?.toString()?.split(" ")?.join("");
    if (formattedText?.length <= 16) {
      formattedText = formattedText?.match(/.{1,4}/g)?.join(" ") || "";
    }
    return formattedText;
  }
};

export const getSpendingLocksPayload = (
  spendingLockType: SPENDING_LOCK_TYPES,
  spendingLocks: ISpendingLockWithNameAndId[]
) => {
  if (spendingLockType === SPENDING_LOCK_TYPES.CATEGORY) {
    return {
      merchant_limits: [],
      merchant_category_limits: JSON.stringify(
        spendingLocks?.map((lock) => ({
          merchant_category_id: lock?.id,
          merchant_category_name: lock?.name,
        }))
      ),
    };
  } else if (spendingLockType === SPENDING_LOCK_TYPES.MERCHANT) {
    return {
      merchant_limits: JSON.stringify(spendingLocks?.map((lock) => ({ merchant_name: lock?.name }))),
      merchant_category_limits: [],
    };
  } else {
    return {
      merchant_limits: [],
      merchant_category_limits: [],
    };
  }
};

const kycValuesToStatusMap = {
  [KYC_STATUS.PENDING]: KYC_STATUS.PENDING,
  [KYC_STATUS.SUBMITTED]: KYC_STATUS.SUBMITTED,
  [KYC_STATUS.APPROVED]: KYC_STATUS.APPROVED,
  [KYC_STATUS.REJECTED]: KYC_STATUS.REJECTED,
};

export const getKycStatus = (kycStatus): KYC_STATUS => {
  if (kycStatus || kycStatus === 0) {
    return kycValuesToStatusMap[kycStatus?.toString()] || KYC_STATUS.INITIAL;
  }
  return KYC_STATUS.INITIAL;
};
