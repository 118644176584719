/*
  enums
*/

export enum SEARCH_CONSTANTS {
  SEARCH_BAR = "search-bar-input",
  POPOVER_SEARCH = "search-in-popover",
}

export enum DEBOUNCE_DELAY_TIME {
  MAX = 1000,
  MID = 500,
  MIN = 300,
  ZERO = 0,
}

/*
  interfaces
*/

/// ./SearchBar

export interface ISearchBar {
  onSearch: (value: string) => void;
  placeholder: string;
  inputValue?: string;
  clearSearch: () => void;
  debounceDelay?: number;
}

/// ./PopoverSearch

export interface IPopoverSearch {
  onSearch: (val: string) => void;
  placeholder: string;
  value: string;
  loading?: boolean;
  classname?: string;
}

/// ./useSearch

interface ISearchLoader {
  stopLoader: () => void;
}

interface ISearchFunctions {
  onSearchHandler: (val: string) => void;
  clearSearchHandler: () => void;
}

interface IGetCommonSearchProps {
  value: string;
  loading: boolean;
}

interface IGetSearchProps<T> extends IGetCommonSearchProps {
  data: T[];
}

export interface IUseCommonSearchProps extends ISearchLoader {
  get: IGetCommonSearchProps;
  onInputChange: (val: string) => void;
  clearInput: () => void;
}

export interface IUseElasticSearchProps extends ISearchLoader, ISearchFunctions {
  get: IGetCommonSearchProps;
}

export interface IUseSimpleSearchProps<T> extends ISearchLoader, ISearchFunctions {
  resetFilteredData: () => void;
  get: IGetSearchProps<T>;
}
