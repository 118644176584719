import React, { useContext } from "react";

import Toaster, { TOASTER_SIZE_TYPE } from "Modules/DS/Toaster";

import { ITrxnToastContext } from "Views/Transaction/@types";
import { TrxnToastContext } from "Views/Transaction/Context";

const Toast = () => {
  const { toaster, setToaster } = useContext<ITrxnToastContext>(TrxnToastContext);

  return (
    <Toaster
      size={TOASTER_SIZE_TYPE.S}
      visible={toaster.show}
      status={toaster.type}
      message={toaster.message}
      onClose={() => setToaster({ ...toaster, show: false })}
      action={toaster.action}
      actionLabel={toaster.actionLabel}
    />
  );
};

export default Toast;
