import React from "react";
import { IColumnData } from "Modules/DS/DataTable";
import { ICategory } from "Views/Categories/types";
import { capitalizeFirstLetter, roundValue } from "utility";
import Chip, { CHIP_STATUS } from "Modules/DS/Chip";
import TextWithTooltip from "Views/Taxes/components/TextWithTooltip";

export const columnData: IColumnData<ICategory>[] = [
  {
    id: "CATEGORY-COL-1",
    enabled: true,
    column: {
      title: "Category Name",
      dataIndex: "categoryName",
      key: "categoryName",
      width: "50%",
      render: (category: string) => <p className={"categories__fields__title"}>{category}</p>,
    },
  },
  {
    id: "CATEGORY-COL-2",
    enabled: true,
    column: {
      title: "Default Tax Name",
      dataIndex: "taxName",
      width: "50%",
      key: "taxName",
    },
  },
  {
    id: "CATEGORY-COL-3",
    enabled: true,
    column: {
      title: "Default Tax Rate",
      dataIndex: "taxRate",
      width: "160px",
      key: "taxRate",
      render: (taxRate) => <p>{taxRate && `${roundValue(taxRate)}%`}</p>,
    },
  },
  {
    id: "CATEGORY-COL-4",
    enabled: true,
    column: {
      title: "Account Code",
      dataIndex: "accountingSystemCode",
      key: "accountingSystemCode",
      width: "160px",
      render(accountCode, category: ICategory) {
        if (category.isNew) {
          return (
            <div className="text-with-new-wrapper">
              <TextWithTooltip
                treshold={category.isNew ? 24 : 35}
                text={accountCode}
                element={
                  <p className="text-with-tooltip__title">
                    <span>{accountCode}</span>
                  </p>
                }
              />
              {category.isNew && <Chip.Label title="New" status={CHIP_STATUS.INFO} />}
            </div>
          );
        }

        return accountCode;
      },
    },
  },
  {
    id: "CATEGORY-COL-5",
    enabled: true,
    column: {
      title: "Status",
      dataIndex: "status",
      width: "144px",
      key: "status",
      render: (status: string) => <p>{capitalizeFirstLetter(status)}</p>,
    },
  },
];
