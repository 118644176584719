import React from "react";
import { useDispatch } from "react-redux";
import { Input } from "antd";
import Icon from "Modules/icons";
import { DownloadBankStatementFunc } from "Redux/Actions";
import Modal from "Modules/modal";
import IButton from "Modules/button";

const ModaldownloadBankStatement = (props) => {
  const { email, setEmail, filters, setShowToaster, showModal, setShowModal } = props;
  const dispatch = useDispatch();

  const doSendEmail = () => {
    dispatch(DownloadBankStatementFunc(filters, email));
    setShowToaster(true);
    setShowModal(false);
  };

  return (
    <Modal className="modal-details" show={showModal} title="Download Bank Statement" close={() => setShowModal(false)}>
      <div className="modal-download-report">
        <p>
          <strong>Download and send bank statement to email</strong>
        </p>
        <p className="text-light">Data more than 500 transactions will be sent to this email below.</p>
        <Input
          placeholder="sample@email.com"
          maxLength={100}
          defaultValue={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <div className="modal-download-report-info">
          <Icon iconName="info" />
          <p>This may take a few minutes to generate your statement.</p>
        </div>
        <div className="modal-download-report-button">
          <IButton
            rounded
            id="downloadBankStatement"
            action={() => {
              doSendEmail();
            }}
            text="Send to Email"
          />
        </div>
      </div>
    </Modal>
  );
};

export default ModaldownloadBankStatement;
