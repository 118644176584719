import APIclient from "API/Client";
import { GetBaseAuthObject } from "../../utility";

export const FETCH_CURRENCY = "FETCH_CURRENCY";
export const FETCH_CURRENCY_SUCCESS = "FETCH_CURRENCY_SUCCESS";
export const FETCH_CURRENCY_FAILURE = "FETCH_CURRENCY_FAILURE";

const fetchCurrency = () => {
  return {
    type: FETCH_CURRENCY,
  };
};

const fetchCurrencySuccess = (data) => {
  return {
    type: FETCH_CURRENCY_SUCCESS,
    data,
  };
};

const fetchCurrencyFailure = (error) => {
  return {
    type: FETCH_CURRENCY_FAILURE,
    error,
  };
};

export const getCurrencyFunc = () => {
  const orgId = GetBaseAuthObject().orgId;
  return (dispatch) => {
    dispatch(fetchCurrency());

    APIclient.getData(`/api/v1/enumeration/currencies?organisation_id=${orgId}`)
      .then((data) => {
        if (data.data.status === 200) {
          dispatch(fetchCurrencySuccess(data.data));
        } else {
          dispatch(fetchCurrencyFailure(data));
        }
      })
      .catch((err) => {
        dispatch(fetchCurrencyFailure(err));
      });
  };
};
