import React, { useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { Checkbox, FaqFilled, Tags, Typography } from "@spenmo/splice";

import { IExpenseReportHeader, ILocationParams } from "Views/Reimbursement/@types";
import { REIMBURSEMENT_PAGE_OPERATIONS, REIMBURSEMENT_SETTLEMENT_STATUSES } from "Views/Reimbursement/Constants";

import ExpenseReportHeaderSkeleton from "Views/Reimbursement/ExpenseReport/Header/Skeleton";

import styles from "./index.module.scss";

const ExpenseReportHeader = ({ employeeCount, totalAmount, loading, addOnsEnabled }: IExpenseReportHeader) => {
  const { params } = useRouteMatch<ILocationParams>();

  const { operation = "", id = "" } = params;

  const isUnpaidPayouts =
    operation === REIMBURSEMENT_PAGE_OPERATIONS.PAYOUTS &&
    id === REIMBURSEMENT_SETTLEMENT_STATUSES.UNPAID_FOR_PAYOUTS_VIEW.id;

  const [transistionLoader, setTransistionLoader] = useState(false);

  useEffect(() => {
    setTransistionLoader(true);

    setTimeout(() => {
      setTransistionLoader(false);
    }, 500);
  }, [id]);

  const unpaidExpenses = () => {
    return (
      <div className={styles.info}>
        <Typography size="m" variant="body-content">
          Total amount payable for
        </Typography>
        <Typography size="m" variant="body-content" weight={600} className={styles.title}>
          {employeeCount}
        </Typography>
        <Typography size="m" variant="body-content">
          employees =
        </Typography>
        <Typography size="m" variant="body-content" weight={600} className={styles.title}>
          {totalAmount}
        </Typography>
        <Typography size="m" variant="body-content">
          for
        </Typography>
        <Tags.Label hierarchy="default" label="Approved" variant="info" className={styles.tag} />
        {!addOnsEnabled && (
          <>
            <Typography size="m" variant="body-content">
              and
            </Typography>
            <Tags.Label hierarchy="default" label="Pending Approval" variant="warning" className={styles.tag} />
          </>
        )}
        <Typography size="m" variant="body-content">
          expenses
        </Typography>
      </div>
    );
  };

  const paidExpenses = () => {
    return (
      <div className={styles.info}>
        <Typography size="m" variant="body-content">
          Total amount paid for
        </Typography>
        <Typography size="m" variant="body-content" weight={600} className={styles.title}>
          {employeeCount}
        </Typography>
        <Typography size="m" variant="body-content">
          employees =
        </Typography>
        <Typography size="m" variant="body-content" weight={600} className={styles.title}>
          {totalAmount}
        </Typography>
        <Typography size="m" variant="body-content">
          in expenses in this period.
        </Typography>
      </div>
    );
  };

  return transistionLoader || loading ? (
    <ExpenseReportHeaderSkeleton />
  ) : (
    <div className={styles.header}>
      {isUnpaidPayouts && (
        <div className={styles.check}>
          <Checkbox size="s" />
        </div>
      )}
      <div className={styles.content}>
        <div className={styles.icon}>
          <FaqFilled size="16" iconColor="var(--icon-default)" />
        </div>
        {isUnpaidPayouts ? unpaidExpenses() : paidExpenses()}
      </div>
    </div>
  );
};

export default ExpenseReportHeader;
export * from "./Skeleton";
