import { SELECT_TYPE } from "./index";

export const useSelectTypes = (type: SELECT_TYPE) => {
  const searchable: boolean =
    type === SELECT_TYPE.SINGLE_WITH_SEARCH ||
    type === SELECT_TYPE.MULTIPLE_WITH_SEARCH ||
    type === SELECT_TYPE.ELASTIC_SEARCH;

  const multiSelect: boolean =
    type === SELECT_TYPE.MULTIPLE || type === SELECT_TYPE.MULTIPLE_WITH_SEARCH || type === SELECT_TYPE.ELASTIC_SEARCH;

  const elastic = type === SELECT_TYPE.ELASTIC_SEARCH;

  return { searchable, multiSelect, elastic };
};
