import { createContext } from "react";

export const TagsDropdownContext = createContext({
  openDropdown: null,
  setOpenDropdown: null,
  openSubMenu: null,
  setOpenSubMenu: null,
  data: null,
  selectedTag: null,
  setSelectedTag: null,
  selectChildren: null,
  selectedChildren: null,
  deleteTag: null,
  subMenuPosition: null,
});
