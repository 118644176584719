import { ArrowRightOutlined } from "@ant-design/icons";
import { Checkbox, Form, Input } from "antd";
import { ocrUnion as ocrUnionIcon, ocrUnionGreen as ocrUnionGreenIcon, newInfo } from "assets/img";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import { TAX_STATUS } from "constants/Tax.constant";
import { USER_ROLE } from "constants/Team.constant";
import { PRODUCT_NAME } from "Redux/ModularProduct";
import useCheckOrgConfigs from "customHooks/useCheckOrgConfigs";
import Button from "Modules/button";
import DropDown from "Modules/dropDown";
import Toaster from "Modules/DS/Toaster";
import OCRTooltip from "Modules/ocrTooltip";
import { TOASTER_SIZE_TYPE, TOASTER_STATUS_TYPE } from "Modules/DS/Toaster/types.d";
import { Tooltip, TooltipArrowAlignmentEnum, TooltipPositionEnum } from "Modules/DS/Tooltip";
import DynamicSLA from "Modules/DynamicSLA";
import Icon from "Modules/icons";
import LoadingIcon from "Modules/loading";
import Loading3Quaters from "Modules/loading/loading3Quaters";
import TagInput from "Modules/TagInput";
import { CurrencyInput } from "Modules/ExchangeInput";
import ExchangeCalculator from "./ExchangeCalculator";
import moment from "moment";
import PropTypes from "prop-types";
import React, { createContext, forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getAllTaxesFunc, getAllTeamsWallet, getOrgDetailFunc } from "Redux/Actions";
import { getApprovers } from "Redux/DataCalls/Approvals.api";
import {
  deleteAttachment as deleteAttachmentAPI,
  getBillFee,
  getConversionRateV2,
  getCorridorType,
  saveVendor,
  submitBill,
  submitDraftBill,
  submitSingleBill,
  saveDraftBill,
  uploadAttachment,
  checkSimilarInvoice,
  billPreviewV2,
} from "Redux/DataCalls/Disbursement.api";
import { currencyFormatterV2, GetBaseAuthObject, isBookkeeper, toNormalCase } from "utility";
import { trackEvent } from "utility/analytics";
import { BILL_EVENTS, BILL_FLOW_TYPES, BILL_SUBMISSION_TYPES } from "Views/Bills/events";
import { mapTeamToWallet } from "utility/Bills";
import {
  EMAIL_REGEX,
  DUPLICATE_INVOICE_NUMBER,
  INTERNAL_SERVER_ERROR,
  INVALID_FX_RATE,
  BILL_PAGE_URL,
  CORRIDOR_SCHEDULE_DATE_INTERVAL,
  ORG_NOT_ALLOWED,
  INVALID_PAYLOAD,
} from "Views/UploadInvoice/const";
import { debounce, isSet, quantifier } from "../../../utility";
import { APPROVAL_ANY_ADMIN, APPROVAL_BUDGET_OWNER, getApproverNames } from "../../TransactionsListing/helper";
import { STATUS } from "../../TransactionsListing/Pending/const";
import { CONVERSION_NOT_MATCHED } from "../const";
import { BillFormTypes } from "../EditBillModal/types.d";
import Categorization from "./Categorization";
import DateSection from "./DateSection";
import styles from "./InvoiceForm.module.scss";
import PreviewModal from "./PreviewModal";
import PreviewContent from "./PreviewModal/PreviewContent";
import SaveVendorModal from "./SaveVendorModal";
import VendorSection from "./VendorSection";
import InvoiceNumber from "./InvoiceNumber";
import WHTCalculator from "./WHTCalculator";
import { BENEFICIARY_WHT } from "Modules/DynamicForm/constants";
import WithholdingTaxModal from "./WithholdingTaxModal";
import axios from "axios";
import useGetBillUrl from "../../Bills/useGetBillUrl";

let timer;
const DEBOUNCE_DELAY = 300;

export const InvoiceFormContext = createContext({
  vendorList: [],
  setVendorList: () => {},
});

const InvoiceForm = forwardRef(
  (
    {
      billData,
      ocrResult,
      isLoadingOcr,
      isFromTransactionPage,
      id,
      uploadedFiles,
      shouldSendOcrId,
      deletedAttachmentId,
      billFormType,
    },
    invoiceFormRef
  ) => {
    const history = useHistory(),
      dispatch = useDispatch(),
      [form] = Form.useForm(),
      [userInfo, taxesData, allTeamsWallet, orgDetail, senderCurrency] = useSelector((state) => [
        state.userInfoReducer,
        state.taxReducer.data,
        state.allTeamsWalletReducer,
        state.b2bOrgDetailReducer,
        state.wallet?.data?.currency_code,
      ]),
      [previewContent, setPreviewContent] = useState(),
      [previewPayload, setPreviewPayload] = useState([]);

    const rejectRef = useRef(null);

    const isWithholdingTaxEnabled = useCheckOrgConfigs(PRODUCT_NAME.BILL_WITHHOLDING_TAX);
    const isEditBill = billFormType === BillFormTypes.Edit;
    const isDraftBill = billFormType === BillFormTypes.Draft;
    const isEditModal = isEditBill || isDraftBill;

    const { isShowBillFeeCalculator, isUseApprovalSystem } = orgDetail?.data?.payload || {};
    const orgCountryCode = orgDetail?.data?.payload?.countryCode;

    // this ref is used to automatically scroll to first error
    const paymentAmountRef = useRef(),
      invoiceNumberRef = useRef(),
      previewFxRateBannerRef = useRef(),
      fxRateBannerRef = useRef();

    const [paymentAmount, setPaymentAmount] = useState(null),
      [paymentCurrency, setPaymentCurrency] = useState(null),
      [isFullPayment, setIsFullPayment] = useState(false),
      [recipientBankCountry, setRecipientBankCountry] = useState(null),
      [isAddCategorization, setIsAddCategorization] = useState(false),
      [isAddNewVendor, setIsAddNewVendor] = useState(false),
      [isEditedVendor, setIsEditedVendor] = useState(false),
      [selectedVendor, setSelectedVendor] = useState(null),
      [paymentDate, setPaymentDate] = useState(null),
      [billDueDate, setBillDueDate] = useState(null),
      [billIssuedDate, setBillIssuedDate] = useState(null),
      [additionalEmails, setAdditionalEmails] = useState([]),
      [walletData, setWalletData] = useState([]),
      [selectedTeam, setSelectedTeam] = useState(null),
      [selectedWallet, setSelectedWallet] = useState(null),
      [isValidInvoiceNumber, setIsValidInvoiceNumber] = useState(true),
      [isSubmit, setIsSubmit] = useState(false),
      [isBalanceHidden, setIsBalanceHidden] = useState(undefined),
      [trackFieldValue, setTrackFieldValue] = useState(null),
      [billFee, setBillFee] = useState(null),
      [billFeeLoading, setBillFeeLoading] = useState(false),
      [fxRate, setFxRate] = useState({}),
      [fxRateLoading, setFxRateLoading] = useState(false),
      [showPreviewModal, setShowPreviewModal] = useState(false),
      [isConfirmSaveVendor, setIsConfirmSaveVendor] = useState(true),
      [showVendorModal, setShowVendorModal] = useState(false),
      [showWithholdingModal, setShowWithholdingModal] = useState(false),
      // walletAmount is only used for submitting the bill (using the new calculator) -> get from amountFx
      [walletAmount, setWalletAmount] = useState(""),
      // senderAmount is used for displaying the You send amount, and only used for submitting the bill
      // for the old calculator -> FE calc it manually
      [senderAmount, setSenderAmount] = useState(""),
      [isPaymentDateSelectedFirst, setIsPaymentDateSelectedFirst] = useState(false),
      [isBillDueDateFirstLoad, setIsBillDueDateFirstLoad] = useState(false),
      [corridorType, setCorridorType] = useState(null),
      [isFixedRate, setIsFixedRate] = useState(true),
      [toaster, setToaster] = useState({
        visible: false,
        message: "",
        status: undefined,
        action: undefined,
        actionLabel: "",
      }),
      [approvalSteps, setApprovalSteps] = useState([]),
      [vendorList, setVendorList] = useState([]),
      [isFxRateReviewed, setIsFxRateReviewed] = useState(false),
      // to get form update status
      [formOnChangeCount, setFormOnChangeCount] = useState(0),
      [isLoadingCheckInvoice, setIsLoadingCheckInvoice] = useState(false),
      [checkInvoiceFocusField, setCheckInvoiceFocusField] = useState(null),
      [transactionLink, setTransactionLink] = useState(null),
      [isDebounceCheckInvoice, setIsDebounceCheckInvoice] = useState(false),
      [isAmountMismatch, setIsAmountMismatch] = useState(false),
      [hasAccessToTransaction, setHasAccessToTransaction] = useState(false),
      [isWHT, setIsWHT] = useState(false),
      [taxData, setTaxData] = useState(undefined);

    const BILL_FLOW_TYPE = isEditBill ? BILL_FLOW_TYPES.edit : BILL_FLOW_TYPES.new;
    const { billUrl, billParamsBuilder } = useGetBillUrl();

    const clearToaster = () =>
      setToaster({
        visible: false,
        message: "",
        status: undefined,
        action: undefined,
        actionLabel: "",
      });

    useEffect(() => {
      dispatch(getAllTaxesFunc({ status: TAX_STATUS.ACTIVE }));
      dispatch(getAllTeamsWallet());
      dispatch(getOrgDetailFunc(GetBaseAuthObject().orgId));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchApprovalSteps = () => {
      getApprovers({
        processType: "invoice",
        walletId: selectedWallet,
        expenseCategoryId: form.getFieldValue("category"),
        amount: walletAmount || 0,
      }).then((res) => {
        setApprovalSteps(res?.data?.payload?.approvalSteps);
      });
    };

    // Map data with Bill data in Edit Bills
    useEffect(() => {
      if (taxData) {
        setPaymentAmount(taxData.totalAmount);
      }
    }, [taxData]);

    useEffect(() => {
      if (isEditModal && billData) {
        const {
          amount,
          walletAmount,
          currency,
          totalFeeAmount,
          beneficiary,
          dynamicFields,
          issuedDate,
          dueDate,
          paymentScheduleTime,
          invoiceNumber,
          receiptEmails,
          expenseCategoryID,
          taxID,
          tags,
          fxRate: _fxRate,
          createdAt,
          clientNotes,
          teamID,
          walletID,
          swiftPaymentChargeFee,
        } = billData;

        const { bankAccountNumber, bankCountry, type, bankCode, remarks, name, vendorID, legalName } = beneficiary;

        setIsBillDueDateFirstLoad(true);

        if (!vendorID) {
          form.setFieldsValue({
            vendorName: "",
          });
          setIsAddNewVendor(true);
        } else {
          setSelectedVendor({ id: vendorID, legalName, dynamicFields });
          setIsAddNewVendor(false);
        }

        setFxRate({ amount: _fxRate, updatedAt: moment(createdAt).format("DD MMM YYYY [at] hh:mm:ss") });
        setPaymentCurrency(currency);
        setPaymentAmount(amount);
        setSenderAmount(walletAmount);
        setWalletAmount(walletAmount);
        setIsFullPayment(Boolean(swiftPaymentChargeFee));
        setBillFee({ totalFeeAmount });
        billData?.billTax &&
          setTaxData({
            ...billData.billTax,
            amount: billData.billTax.invoiceAmount,
            taxAmount: billData.billTax.whtAmount,
            totalAmount: amount,
            vatRate: billData.billTax.vatRate || undefined,
          });

        if (walletData.length) {
          const selectedWalletData = walletData.find((item) => item.id === walletID && item.teamId === teamID);
          const selectedWalletId = selectedWalletData?.id || walletData[0].id;
          const selectedTeamId = selectedWalletData?.teamId || walletData[0].teamId;
          setSelectedWallet(selectedWalletId);
          setSelectedTeam(selectedTeamId);
        }

        setRecipientBankCountry(bankCountry);
        if (receiptEmails) setAdditionalEmails(receiptEmails?.split(","));

        const showCategorization = taxID || expenseCategoryID || clientNotes || tags;

        dueDate && setBillDueDate(moment(dueDate));
        issuedDate && setBillIssuedDate(moment(issuedDate));
        paymentScheduleTime !== "-" && setPaymentDate(moment(paymentScheduleTime));

        const editDynamicFields = {};
        dynamicFields.forEach((item) => {
          editDynamicFields[item.id] = item.value;
        });

        form.setFieldsValue({
          vendorName: legalName,
          recipientName: name,
          bankAccountNumber: bankAccountNumber,
          recipientBankName: bankCode,
          remarks: remarks,
          invoiceNumber: invoiceNumber,
          entityType: type,
          category: expenseCategoryID && parseInt(expenseCategoryID),
          tax: taxID,
          tags,
          notes: clientNotes,
          categorization: showCategorization,
        });

        setIsAddCategorization(showCategorization);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [billData, walletData]);

    useEffect(() => {
      if (isEditModal && billData && paymentAmount && paymentCurrency) {
        const { amount, currency } = billData;
        const isChangedAmtCcy =
          paymentCurrency !== senderCurrency && (paymentAmount !== amount || paymentCurrency !== currency);
        setIsFixedRate(!isChangedAmtCcy);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEditModal, billData, paymentAmount, paymentCurrency]);

    // Map form data with OCR data
    useEffect(() => {
      if (ocrResult?.ocrId) {
        const vendorExist = vendorList.find(
          (item) => item?.beneficiaryName?.toLowerCase() === ocrResult.recipientName.toLowerCase()
        );

        form.setFieldsValue({
          recipientName: ocrResult.recipientName,
          invoiceNumber: ocrResult.invoiceNumber,
        });

        const newPaymentCurrency = ocrResult.paymentCurrency || paymentCurrency;
        const newPaymentAmount = ocrResult.paymentAmount || paymentAmount;
        const newSenderAmount = newPaymentAmount * (fxRate.amount || 1);

        const newTax = taxesData?.payload?.data?.find((item) => item.tax_rate === parseFloat(ocrResult.tax));
        const newCategory = userInfo?.data?.payload?.categories?.find(
          (item) => item.category_name === ocrResult.category
        );

        const newFieldsValue = {};

        if (!vendorExist && newPaymentAmount && newPaymentCurrency)
          newFieldsValue.payment = `${newPaymentCurrency}${newPaymentAmount}`;
        if (newTax) newFieldsValue.tax = newTax.id;
        if (newCategory) newFieldsValue.category = newCategory.id;
        newFieldsValue.notes = ocrResult.notes;

        if (ocrResult.notes || newTax || newCategory) {
          setIsAddCategorization(true);
          newFieldsValue.categorization = true;
        }

        if (!vendorExist) {
          newFieldsValue.currencyCode = newPaymentCurrency;
          setPaymentCurrency(newPaymentCurrency);
        }
        setPaymentAmount(newPaymentAmount);
        setSenderAmount(newSenderAmount);
        setBillDueDate(ocrResult.billDueDate);
        setBillIssuedDate(ocrResult.billIssuedDate);
        setPaymentDate(ocrResult.billScheduledDate);

        setIsDebounceCheckInvoice(false);
        if (ocrResult.invoiceNumber) {
          setCheckInvoiceFocusField({ field: "invoiceNumber", value: ocrResult.invoiceNumber });
        } else if (ocrResult.paymentAmount) {
          if (ocrResult.billDueDate) {
            setCheckInvoiceFocusField({ field: "billDueDate", value: ocrResult.billDueDate });
          } else {
            setCheckInvoiceFocusField({ field: "paymentAmount", value: ocrResult.paymentAmount });
          }
        }

        form.setFieldsValue(newFieldsValue);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ocrResult]);

    useEffect(() => {
      form.setFieldsValue({ payment: `${paymentCurrency}${paymentAmount}` });
    }, [paymentCurrency, paymentAmount, form]);

    useEffect(() => {
      if (!allTeamsWallet.loading) {
        // is_balance_hidden true meaning company is using cards 2.0
        setIsBalanceHidden(allTeamsWallet?.data?.payload?.is_balance_hidden);
        setWalletData(
          mapTeamToWallet({
            teams: allTeamsWallet?.data?.payload?.teams,
            isAdmin: userInfo.isAdmin,
            isAccountant: userInfo?.company_role === USER_ROLE.ACCOUNTANT,
            isBookkeeper: isBookkeeper(userInfo?.company_role),
            isCards2: allTeamsWallet?.data?.payload?.is_balance_hidden,
            manageTeams: userInfo.manageTeams,
          })
        );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allTeamsWallet, userInfo.isAdmin, userInfo.manageTeams]);

    useEffect(() => {
      if (walletData?.length && !selectedWallet) {
        setSelectedWallet(walletData[0].id);
        setSelectedTeam(walletData[0].teamId);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [walletData]);

    useEffect(() => {
      billDueDate && actionBillDueDate(billDueDate);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [corridorType]);

    const getBillFeeFunc = (params) => {
      clearTimeout(timer);
      timer = setTimeout(async () => {
        setBillFeeLoading(true);
        try {
          const billFeeData = await getBillFee(params);
          setBillFee(billFeeData?.data?.payload);
          setBillFeeLoading(false);
        } catch {
          setBillFeeLoading(false);
        }
      }, DEBOUNCE_DELAY);
    };

    useEffect(() => {
      if (recipientBankCountry && paymentCurrency && orgCountryCode && senderCurrency) {
        getCorridorTypeFunc();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paymentCurrency, recipientBankCountry, orgCountryCode, senderCurrency]);

    useEffect(() => {
      if (isFxRateReviewed && showPreviewModal) {
        previewFxRateBannerRef?.current && previewFxRateBannerRef.current.scrollIntoView();
      } else if (isFxRateReviewed) {
        fxRateBannerRef?.current && fxRateBannerRef.current.scrollIntoView();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFxRateReviewed]);

    const trackView = (fieldName, value) => {
      const val = value || form.getFieldValue(fieldName);
      setTrackFieldValue(val);
      trackEvent(BILL_EVENTS.fieldsViewed, {
        bill_edit_event_source: toNormalCase(fieldName),
        bill_id: billData?.id,
        bill_flow_type: BILL_FLOW_TYPE,
      });
    };

    const trackEdit = (fieldName, value) => {
      const val = value || form.getFieldValue(fieldName);
      if (val !== trackFieldValue) {
        setTrackFieldValue(null);
        trackEvent(BILL_EVENTS.fieldsEdited, {
          bill_edit_event_source: toNormalCase(fieldName),
          bill_id: billData?.id,
          bill_flow_type: BILL_FLOW_TYPE,
        });
      }
    };

    const handleShowError = (message, errorCode) => {
      const actionProps = {
        actionLabel: "",
        action: undefined,
      };

      switch (errorCode) {
        case INTERNAL_SERVER_ERROR:
          actionProps.actionLabel = "Refresh";
          actionProps.action = () => {
            window.location.reload();
          };
          break;

        default:
          actionProps.actionLabel = "Email Support";
          actionProps.action = () => {
            window.open("mailto:support@spenmo.com");
          };
          break;
      }

      setToaster({
        visible: true,
        message,
        status: TOASTER_STATUS_TYPE.ERROR,
        ...actionProps,
      });
    };

    const onChangeCategorization = () => {
      setIsAddCategorization(!isAddCategorization);
    };

    const renderOptionalLabel = (textLabel) => (
      <>
        {textLabel} <span className={styles.optional}>(optional)</span>
      </>
    );

    const renderOCRLabel = (textLabel, isOptional = false, isOCROveridden = false) => (
      <div className={styles["form-item-ocr"]}>
        {textLabel} {isOptional && <span className={styles.optional}>(optional)</span>}
        <div className={styles.tooltip}>
          <Tooltip
            text={
              isOCROveridden ? `This field has been overidden manually from OCR` : `This field has been filled by OCR`
            }
            alignArrow={TooltipArrowAlignmentEnum.CENTER}
            position={TooltipPositionEnum.TOP}
          >
            <Icon alt="ocr-union-icon" src={isOCROveridden ? ocrUnionIcon : ocrUnionGreenIcon} />
          </Tooltip>
        </div>
      </div>
    );

    const onChangeAdditionalEmail = (val) => {
      if (EMAIL_REGEX.test(val[additionalEmails.length])) {
        setAdditionalEmails([...additionalEmails, val[additionalEmails.length]]);
        trackEvent(BILL_EVENTS.fieldsEdited, {
          bill_edit_event_source: "additional emails",
          bill_id: billData?.id,
          bill_flow_type: BILL_FLOW_TYPE,
        });
      }
    };

    const onDeselectAdditionalEmail = (val) => {
      setAdditionalEmails(additionalEmails.filter((item) => item !== val));
      trackEvent(BILL_EVENTS.fieldsEdited, {
        bill_edit_event_source: "additional emails",
        bill_id: billData?.id,
        bill_flow_type: BILL_FLOW_TYPE,
      });
    };

    const onChangePaymentAmount = (val) => {
      // IMPROVEMENT: it should only be a callback if needed.
      if (val !== paymentAmount) {
        setPaymentAmount(val);
        form.setFieldsValue({ paymentAmount: val });

        setIsDebounceCheckInvoice(true);
        setCheckInvoiceFocusField({ field: "paymentAmount", value: val });
      }
    };

    const actionPaymentDate = (val, isNow) => {
      if (isNow || (val && val.format("DD MMM YYYY") === moment().format("DD MMM YYYY"))) setPaymentDate(null);
      else setPaymentDate(val);
      if (billDueDate === null) {
        setIsPaymentDateSelectedFirst(true);
      }
      trackEvent(BILL_EVENTS.fieldsEdited, {
        bill_edit_event_source: "payment date",
        bill_id: billData?.id,
        bill_flow_type: BILL_FLOW_TYPE,
      });
    };

    const disabledDatePaymentDate = (current) => {
      return current < moment().subtract(1, "day");
    };

    const actionBillDueDate = (val) => {
      setBillDueDate(val);
      if (corridorType && val && !isPaymentDateSelectedFirst && !isBillDueDateFirstLoad) {
        const length =
          corridorType === "domestic"
            ? CORRIDOR_SCHEDULE_DATE_INTERVAL.DOMESTIC
            : CORRIDOR_SCHEDULE_DATE_INTERVAL.INTERNATIONAL;
        let predictedScheduleDate = moment(val).subtract(length, "day");
        if (moment.now() > predictedScheduleDate) {
          predictedScheduleDate = null;
        }
        setPaymentDate(predictedScheduleDate);
      }
      setIsBillDueDateFirstLoad(false);
      trackEvent(BILL_EVENTS.fieldsEdited, {
        bill_edit_event_source: "bill due date",
        bill_id: billData?.id,
        bill_flow_type: BILL_FLOW_TYPE,
      });

      setIsDebounceCheckInvoice(false);
      setCheckInvoiceFocusField({ field: "billDueDate", value: val });
    };

    const isNotSelectedVendorWhenCreateBill = !isEditModal && selectedVendor === null && !isAddNewVendor;
    const disableFieldBillDueDate =
      paymentCurrency === null || recipientBankCountry === null || isNotSelectedVendorWhenCreateBill;

    const actionBillIssuedDate = (val) => {
      setBillIssuedDate(val);
      trackEvent(BILL_EVENTS.fieldsEdited, {
        bill_edit_event_source: "bill issued date",
        bill_id: billData?.id,
        bill_flow_type: BILL_FLOW_TYPE,
      });
    };

    const disabledDateBillIssuedDate = (current) => {
      return current > moment();
    };

    const getExchangeRate = async (from, to) => {
      setFxRateLoading(true);
      const abortSignal = () => {
        if (rejectRef.current) {
          rejectRef.current.cancel("Operation canceled by the user.");
        }

        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        rejectRef.current = source;

        return source.token;
      };

      const conversionRate = await getConversionRateV2(from, to, {
        cancelToken: abortSignal(),
      });
      setFxRate({
        amount: conversionRate?.data?.payload?.buyingRate?.toFixed(8),
        updatedAt: moment(conversionRate?.data?.payload?.updated_at).format("DD MMM YYYY [at] hh:mm:ss"),
      });
      setFxRateLoading(false);
    };

    const getCorridorTypeFunc = async () => {
      const getCorridorTypeData = await getCorridorType(
        orgCountryCode,
        senderCurrency,
        paymentCurrency,
        recipientBankCountry
      );
      setCorridorType(getCorridorTypeData?.data?.payload?.corridorType);
    };

    const parsedDynamicFields = (dynamicFields) => {
      return Object.entries(dynamicFields).map(([key, value]) => ({
        id: parseInt(key, 10),
        value: isSet(value) ? String(value) : value,
      }));
    };

    const handleDynamicFormData = (values) => {
      const formData = new FormData();
      const { dynamicFields } = values;
      if (dynamicFields) {
        const dynamicFieldsPayload = parsedDynamicFields(dynamicFields);
        formData.append("dynamicFields", JSON.stringify(dynamicFieldsPayload));
      }

      return formData;
    };

    useImperativeHandle(invoiceFormRef, () => ({
      preview() {
        form
          .validateFields()
          .then((values) => {
            if (isWithholdingTaxEnabled && isWHT) setShowWithholdingModal(true);
            else handlePreview(values);
          })
          .catch((e) => {
            const { values, errorFields } = e;
            onFinishFailed({ values, errorFields });
          });
      },
      saveDraft(payloads) {
        handleSaveDraft(payloads).catch((e) => console.error(e));
      },
      async getDraftPayload() {
        return await getDraftPayload(form.getFieldsValue());
      },
      getFieldsValue() {
        return form.getFieldsValue();
      },
    }));

    const handleUploadAttachments = async (files) => {
      return Promise.all(
        files.map(({ file }) => {
          const uploadFormData = new FormData();
          uploadFormData.append("formType", "bill-attachments");
          uploadFormData.append("file", file);
          return uploadAttachment(uploadFormData);
        })
      )
        .then((data) => {
          return data.map((item) => {
            if (item.status === HTTP_STATUS_CODE.OK) {
              return { url: item?.payload?.filePath, filename: item?.filename };
            } else {
              throw item;
            }
          });
        })
        .catch(() => {
          setToaster({
            visible: true,
            message: "Unexpected error while uploading attachments. Please try again.",
            status: TOASTER_STATUS_TYPE.ERROR,
          });
          return false;
        });
    };

    const getAttachmentPayload = async () => {
      const newFiles = uploadedFiles.filter((item) => item.file !== undefined);
      const existingFiles = uploadedFiles
        .filter((item) => item.id && !deletedAttachmentId.includes(item.id))
        .map((item) => ({ id: item.id, url: item.url, type: item.type }));

      let newFilesUrls = [];
      if (newFiles.length) {
        newFilesUrls = await handleUploadAttachments(newFiles);
        if (!newFilesUrls) return false;
      }

      return [...existingFiles, ...newFilesUrls];
    };

    const getDraftPayload = async (values) => {
      const recipientFormData = handleDynamicFormData(values);
      const formData = getBillPayload(values, recipientFormData, selectedVendor?.id);

      formData.append("id", billData.id);
      formData.delete("isFixedFxRate");
      formData.delete("action");
      formData.delete("additionalAttachments");
      let formDataObject = {};
      formData.forEach((value, key) => {
        formDataObject[key] = value;
      });

      formDataObject.amount = parseFloat(formDataObject.amount);
      formDataObject.fxRate = parseFloat(formDataObject.fxRate);
      formDataObject.walletAmount = parseFloat(formDataObject.walletAmount);
      formDataObject.vendorID = parseInt(formDataObject.vendorID);
      formDataObject.slaID = parseInt(formDataObject.slaID);
      formDataObject.isVendorLinked = Boolean(formDataObject.isVendorLinked);
      formDataObject.isVendorSaved = Boolean(formDataObject.isVendorSaved);
      if (formDataObject.billTax && formDataObject.billTax !== "")
        try {
          formDataObject.billTax = JSON.parse(formDataObject.billTax);
        } catch (e) {
          console.error(e);
        }
      formDataObject = {
        ...formDataObject,
        ...(formDataObject.flatFeeFx && { flatFeeFx: parseFloat(formDataObject.flatFeeFx) }),
        ...(formDataObject.variableFee && { variableFee: parseFloat(formDataObject.variableFee) }),
        ...(formDataObject.swiftPaymentChargeFee && {
          swiftPaymentChargeFee: parseFloat(formDataObject.swiftPaymentChargeFee),
        }),
      };

      // Set to pay immediately if date is less than today
      const paymentScheduleTime = formDataObject.paymentScheduleTime && new Date(formDataObject.paymentScheduleTime);
      if (paymentScheduleTime >= new Date()) {
        formDataObject.paymentScheduleTime = paymentScheduleTime.toISOString();
      } else {
        delete formDataObject.paymentScheduleTime;
      }

      const attachments = await getAttachmentPayload();
      if (!attachments) return;
      formDataObject.attachments = attachments;

      return formDataObject;
    };

    const handleSaveDraft = async (payloads) => {
      const response = await saveDraftBill(payloads);
      if (response?.status === HTTP_STATUS_CODE.OK && response?.payload?.draftBills?.[0]?.isSuccess) {
        history.push({
          pathname: "/bills/drafts",
          state: {
            toasterData: {
              visible: true,
              message: "Bill has been successfully saved.",
              status: TOASTER_STATUS_TYPE.SUCCESS,
            },
            closeModal: true,
          },
        });
      } else if (response?.payload?.draftBills?.[0]?.errorMessage) {
        setToaster({
          visible: true,
          message: response?.payload?.draftBills?.[0]?.errorMessage,
          status: TOASTER_STATUS_TYPE.ERROR,
        });
      } else {
        setToaster({
          visible: true,
          message: "Unexpected error",
          status: TOASTER_STATUS_TYPE.ERROR,
        });
      }
    };

    const handleSubmitForm = () => {
      form.submit();
      onFinish(form.getFieldsValue(), (isEditedVendor || isAddNewVendor) && isConfirmSaveVendor);
    };

    const getSource = () => {
      switch (true) {
        case isDraftBill:
          return "portal_draft";

        case isEditBill:
          return "portal_edit_bill";

        default:
          return "portal_single";
      }
    };

    const getVendorPayload = (values) => {
      let payload = {
        legalName: values.vendorName,
        beneficiaryName: values.beneficiaryName,
        recipientEmail: values.recipientEmail?.join(),
        currencyCode: String(values.currencyCode),
        countryCode: values.countryCode,
        dynamicFields: parsedDynamicFields(values.dynamicFields),
        source: getSource(),
      };

      return payload;
    };

    const getBillPayload = (values, formData, savedVendorID) => {
      const uploadedFilesFiltered = uploadedFiles.filter((item) => item.file !== undefined);

      // mandatory fields
      values.beneficiaryName && formData.append("beneficiaryName", values.beneficiaryName);
      paymentAmount && formData.append("amount", paymentAmount);
      paymentCurrency && formData.append("currency", paymentCurrency);
      recipientBankCountry && formData.append("beneficiaryBankCountry", recipientBankCountry);
      selectedTeam && formData.append("teamID", selectedTeam);
      selectedWallet && formData.append("walletID", selectedWallet);
      fxRate.amount && formData.append("fxRate", fxRate.amount);
      if (senderAmount) {
        formData.append("walletAmount", walletAmount);
      }

      formData.append("swiftPaymentChargeType", isFullPayment ? "OUR" : "SHA");
      //additional payload to trace payment amount difference in BE
      billFee?.flatFeeFx && formData.append("flatFeeFx", billFee?.flatFeeFx);
      billFee?.variableFee && formData.append("variableFee", billFee?.variableFee);
      billFee?.swiftPaymentChargeFee && formData.append("swiftPaymentChargeFee", billFee?.swiftPaymentChargeFee);

      // optional fileds
      values.slaID && formData.append("slaID", values.slaID);
      values.invoiceNumber && formData.append("billNumber", values.invoiceNumber);
      values.remarks && formData.append("beneficiaryRemarks", values.remarks);
      values.recipientEmail?.length && formData.append("beneficiaryEmail", values.recipientEmail.join());
      additionalEmails.length && formData.append("receiptEmailRecipients", additionalEmails);
      billIssuedDate && formData.append("issuedDate", billIssuedDate.format("YYYY-MM-DD"));
      billDueDate && formData.append("dueDate", billDueDate.format("YYYY-MM-DD"));
      paymentDate && formData.append("paymentScheduleTime", paymentDate.format("YYYY-MM-DD"));
      shouldSendOcrId && formData.append("ocrAttachmentID", ocrResult.ocrId);
      values.vendorName && formData.append("legalName", values.vendorName);
      formData.append("isVendorLinked", isConfirmSaveVendor ? 1 : 0);

      let isVendorSaved = isConfirmSaveVendor ? 1 : 0;
      formData.append("isVendorSaved", isVendorSaved);

      if (isAddCategorization) {
        values.category && formData.append("expenseCategoryID", values.category);
        values.tax && formData.append("taxID", values.tax);
        values.notes && formData.append("notes", values.notes);
        const tags = values.tag?.map((tag) => ({ label: tag.label, value: tag.value }));
        tags && formData.append("tags", JSON.stringify(tags));
      }

      if (uploadedFilesFiltered?.length) {
        uploadedFilesFiltered.forEach((item) => formData.append("additionalAttachments", item.file));
      }

      if (deletedAttachmentId.length) {
        deletedAttachmentId.forEach((item) => deleteAttachmentAPI(item));
      }

      if (isEditModal) {
        // when editing bill, if vendor ID is undefined we will send to BE as 0
        // this is a limitation from BE to remove existing vendor ID in bill detail
        formData.append("vendorID", savedVendorID || 0);

        formData.append("action", "edit");
        if (isFixedRate && paymentCurrency !== senderCurrency) {
          formData.append("isFixedFxRate", true);
        }
      } else if (savedVendorID) {
        formData.append("vendorID", savedVendorID);
      }

      if (isWHT && taxData) {
        formData.append(
          "billTax",
          JSON.stringify({
            invoiceAmount: Number(taxData.amount),
            taxRate: Number(taxData.taxRate),
            vatRate: Number(taxData.vatRate),
            vatAmount: Number(taxData.vatAmount),
            whtAmount: Number(taxData.taxAmount),
            hasManualWHT: taxData.hasManualWHT,
            isInclusive: taxData.isInclusive,
          })
        );
        values.tax && formData.append("taxID", values.tax);
      }

      return formData;
    };

    const onFinish = async (values, isSaveVendor = false) => {
      setIsSubmit(true);

      trackEvent(BILL_EVENTS.submitClicked, {
        bill_id: billData?.id,
        bill_flow_type: BILL_FLOW_TYPE,
        bill_submission_type: BILL_SUBMISSION_TYPES.single,
      });

      let saveVendorMessage = null;
      let savedVendorID = selectedVendor?.id;

      const recipientFormData = handleDynamicFormData(values);

      const isSeparateVendorCall = isFromTransactionPage || isDraftBill;

      if (isSeparateVendorCall && isSaveVendor && values.vendorName) {
        try {
          const addNewVendorPayload = getVendorPayload(values);
          const addNewVendorResponse = await saveVendor(addNewVendorPayload);
          if (addNewVendorResponse.status === HTTP_STATUS_CODE.OK) {
            saveVendorMessage = `Details for ${values.vendorName} has been saved.`;
            savedVendorID = addNewVendorResponse.payload?.ID;
          } else {
            handleShowError(
              addNewVendorResponse.error?.message || "Error while saving vendor detail. Please try again.",
              addNewVendorResponse.error?.code
            );
            setIsSubmit(false);
            return;
          }
        } catch (e) {
          handleShowError("Error while saving vendor detail. Please try again.");
          setIsSubmit(false);
          return;
        }
      }

      const formData = getBillPayload(values, recipientFormData, savedVendorID);

      let response;
      if (isFromTransactionPage || isEditBill) response = await submitBill(formData, id);
      else if (isDraftBill) {
        const attachments = await getAttachmentPayload();
        if (!attachments) return;
        formData.delete("additionalAttachments");
        attachments.forEach((attachment) => {
          formData.append("attachments", JSON.stringify(attachment));
        });
        response = await submitDraftBill(formData, id);
      } else response = await submitSingleBill(formData);

      if (response?.status === HTTP_STATUS_CODE.OK) {
        trackEvent("bill submit success");

        if (isEditBill) {
          history.push("/");
          history.replace({
            pathname: billUrl,
            state: {
              editBillToaster: {
                visible: true,
                message: "Bill has been successfully edited and submitted for approval.",
                status: TOASTER_STATUS_TYPE.SUCCESS,
              },
            },
          });
        } else if (isDraftBill) {
          history.push({
            pathname: "/bills/drafts",
            state: {
              billID: billData?.id,
              toasterData: {
                visible: true,
                message: `Bill has been successfully submitted for ${isUseApprovalSystem ? "approval" : "payment"}.`,
                status: TOASTER_STATUS_TYPE.SUCCESS,
                actionLabel: "View Bill",
              },
              closeModal: true,
            },
          });
        } else {
          history.push({
            pathname: BILL_PAGE_URL,
            state: {
              data: {
                ...response.payload,
                saveVendorMessage,
                paymentSource: walletData.find((item) => item.id === selectedWallet).name,
              },
            },
          });
        }
      } else if (response?.error?.code === INVALID_FX_RATE || response?.error?.code === CONVERSION_NOT_MATCHED) {
        getExchangeRate(paymentCurrency, senderCurrency);
        setIsFxRateReviewed(true);
      } else if (response?.error?.code === DUPLICATE_INVOICE_NUMBER) {
        setShowPreviewModal(false);
        setIsValidInvoiceNumber(false);
        form.validateFields().catch((e) => console.log(e));
        invoiceNumberRef.current?.scrollIntoView();
      } else if (response?.error?.code === INTERNAL_SERVER_ERROR) {
        handleShowError("An error has occured. Please try refreshing the page.", response.error.code);
      } else if ([ORG_NOT_ALLOWED, INVALID_PAYLOAD].includes(response?.error?.code)) {
        handleShowError(
          <>
            An error has occurred. Please contact <b>support@spenmo.com</b> for further assistance.
          </>,
          response.error.code
        );
      } else {
        handleShowError(
          <>
            An error has occurred. Please contact <b>support@spenmo.com</b> for further assistance.
            <br />
            Error ID: {response?.error?.code}
          </>,
          response?.error?.code
        );
      }

      if (response.status !== HTTP_STATUS_CODE.OK) {
        trackEvent("bill submit failed");
      }

      setIsSubmit(false);
    };

    const scrollToID = (id) => {
      document.getElementById(id)?.scrollIntoView({
        block: "center",
      });
    };

    const scrollToError = (errorFieldName, errorFields) => {
      // automatically scroll to first error
      switch (errorFieldName) {
        case "payment":
          paymentAmountRef.current?.scrollIntoView();
          break;
        case "invoiceNumber":
          invoiceNumberRef.current?.scrollIntoView();
          break;
        default:
          scrollToID(errorFieldName);
          break;
      }
    };

    const trackErrorFields = (values, errorFields) => {
      let trackError = "";
      let trackEmpty = "";
      errorFields.forEach((item) => {
        if (
          !values[item.name] ||
          values[item.name] === "" ||
          (item.name[0] === "payment" && values[item.name] === "null")
        ) {
          trackEmpty += toNormalCase(item.name[0]) + ",";
        } else {
          trackError += toNormalCase(item.name[0]) + ",";
        }
      });

      trackError && trackEvent("bill wrong", { fields: trackError });
      trackEmpty && trackEvent("bill empty", { fields: trackEmpty });
      trackEvent("bill submit failed");
    };

    const onFinishFailed = ({ values, errorFields }) => {
      if (errorFields?.length) {
        trackErrorFields(values, errorFields);
        scrollToError(errorFields[0].name.join("_"), errorFields);
      }
    };

    const validatePaymentAmount = () =>
      !paymentAmount || paymentAmount > 0
        ? Promise.resolve()
        : Promise.reject(new Error("Entered amount must be greater than 0."));

    const resetValidateInvoiceNumber = (val) => {
      setIsValidInvoiceNumber(true);
      form.validateFields(["invoiceNumber"]).catch((e) => console.error(e));
      setIsDebounceCheckInvoice(true);
      setCheckInvoiceFocusField({ field: "invoiceNumber", value: val });
    };

    const statusJourney = () => {
      const waitingApprovals =
        approvalSteps
          ?.filter((item) => item.stepStatus === "Pending")
          ?.map((item) => ({
            label: `Pending ${getApproverNames(item.assignees)} for approval`,
          })) || [];

      if (isEditBill && billData?.status === STATUS.rejected) {
        const declinedNextSteps = [{ label: "Sending payment" }, { label: "Payment Received" }];
        return [...billData.statusJourney, ...waitingApprovals, ...declinedNextSteps];
      }
      if (isEditBill) {
        const steps = billData?.statusJourney?.filter((item) => !item.label.includes("Approved by")) || [];
        const replacedIndexes = steps.reduce(
          (acc, curval, idx) => (curval.label.includes("Waiting for approval") ? [...acc, idx] : acc),
          []
        );
        if (replacedIndexes[0] !== -1) {
          steps.splice(replacedIndexes[0], replacedIndexes.length, ...waitingApprovals);
        }
        return steps;
      }
      if (isDraftBill) {
        return [
          {
            label: "Draft created",
            isFinished: true,
          },
          ...waitingApprovals,
          {
            label: "Sending Payment",
          },
          {
            label: "Payment Received",
          },
        ];
      }

      return [
        {
          label: "Extracting Payment details",
          isFinished: true,
        },
        ...waitingApprovals,
        {
          label: "Sending Payment",
        },
        {
          label: "Payment Received",
        },
      ];
    };

    const getStatusJourneyDesc = () => {
      if (!isUseApprovalSystem) return "The payment will be sent for processing immediately";
      const assignees = approvalSteps?.[0]?.assignees || [];

      const allQuantifier = assignees.indexOf((item) => item?.name === APPROVAL_ANY_ADMIN || APPROVAL_BUDGET_OWNER)
        ? "All"
        : "";

      let assigneeNames = getApproverNames(assignees, ", ")?.replace(APPROVAL_ANY_ADMIN, "Administrators") || "";
      if (assignees.length > 1)
        assigneeNames = assigneeNames.replace(/,(?=[^,]+$)/, `${assignees.length > 2 ? "," : ""} and`);

      return (
        <>
          The approver{quantifier(assignees.length)} {allQuantifier} <b>{assigneeNames}</b> will be notified upon{" "}
          {isEditModal ? "any changes to this Bill" : "creating this Bill"}. Payment details cannot be{" "}
          {isEditModal ? "modified" : "amended"} once it is approved.
        </>
      );
    };

    const dropDownAction = (id) => {
      setSelectedWallet(id);
      setSelectedTeam(walletData.find((item) => item.id === id).teamId);

      trackEvent(BILL_EVENTS.fieldsEdited, {
        bill_edit_event_source: "selected wallet",
        bill_id: billData?.id,
        bill_flow_type: BILL_FLOW_TYPE,
      });
    };

    const payFromField = (
      <Form.Item label={isBalanceHidden ? "Payment source" : "Pay from"}>
        {walletData?.length > 0 ? (
          <DropDown
            currentValue={selectedWallet}
            isWallet
            showAmount={!isBalanceHidden}
            showCurrency
            listData={walletData}
            className={styles.wallet}
            action={dropDownAction}
            onFocus={() => trackView("selectedWallet", selectedWallet)}
          />
        ) : (
          <LoadingIcon />
        )}
        {isBalanceHidden && (
          <div className={styles["wallet-disabled-alert"]}>
            Payment will be deducted from the spending limit of this payment source.
          </div>
        )}
      </Form.Item>
    );

    const handlePreview = async (values) => {
      fetchApprovalSteps();

      const isSameCurrency = paymentCurrency === senderCurrency;
      if (!isFixedRate || !isEditModal || isSameCurrency) {
        const newRate = await getConversionRateV2(paymentCurrency, senderCurrency);
        const amount = newRate?.data?.payload?.buyingRate?.toFixed(8);
        const updatedAt = moment(newRate?.data?.payload?.updated_at).format("DD MMM YYYY [at] hh:mm:ss");
        if (amount !== fxRate.amount && !isSameCurrency) {
          setIsFxRateReviewed(true);
          setFxRate({ amount, updatedAt });
          return;
        } else if (isSameCurrency) {
          setFxRate({ amount, updatedAt });
        }
      }
      setIsFxRateReviewed(false);

      const recipientFormData = handleDynamicFormData(values);
      const formData = getBillPayload(values, recipientFormData, selectedVendor?.id);
      if (isEditModal) formData.append("billID", billData.id);

      formData.append("totalPaidAmount", senderAmount);

      formData.append(
        "payment",
        paymentAmount && paymentCurrency ? currencyFormatterV2(paymentAmount, paymentCurrency) : ""
      );
      formData.append(
        "senderPayment",
        senderAmount && senderCurrency ? currencyFormatterV2(senderAmount, senderCurrency) : ""
      );

      try {
        const resp = await billPreviewV2(formData);
        if (resp?.status === HTTP_STATUS_CODE.OK) {
          setPreviewPayload(resp.payload);
          setPreviewContent(formData);

          /*
            in Edit Bill, it's possible to show the preview without changing any form.
            So, need to update formOnChangeCount to trigger the actual form data.
          */
          setFormOnChangeCount((prev) => prev + 1);

          setShowPreviewModal(true);
        } else if (resp?.error?.code === CONVERSION_NOT_MATCHED) {
          setIsAmountMismatch(true);
          throw new Error(resp?.error?.message);
        } else {
          throw new Error(resp?.error?.message);
        }
      } catch (e) {
        handleShowError(e?.message);
        console.error(e);
      }
    };

    const onChangeSLA = (sla) => {
      form.setFieldsValue({
        slaID: sla?.id,
      });
    };

    const handleFormChange = debounce((_, formValues) => {
      if (isWithholdingTaxEnabled && formValues?.dynamicFields?.hasOwnProperty(BENEFICIARY_WHT)) {
        setIsWHT(formValues?.dynamicFields?.[BENEFICIARY_WHT]);
      }
      setFormOnChangeCount((prev) => prev + 1);
    }, 300);

    const onCheckSimilarInvoice = async () => {
      setIsLoadingCheckInvoice(true);

      const payload = { vendorID: parseInt(selectedVendor.id), billID: billData?.id };
      if (paymentCurrency) payload.currency = paymentCurrency;
      if (paymentAmount) payload.amount = parseFloat(paymentAmount);
      if (billDueDate) payload.dueDate = billDueDate.format("YYYY-MM-DD");
      if (form.getFieldValue("invoiceNumber")) payload.invoiceNo = form.getFieldValue("invoiceNumber");

      try {
        const response = await checkSimilarInvoice(payload);
        if (response?.data?.payload?.isAlert) {
          const { filter, isDuplicateInvoiceNo, isBillAccessible } = response.data.payload;
          if (isDuplicateInvoiceNo) {
            setIsValidInvoiceNumber(false);
            form.validateFields(["invoiceNumber"]).catch((e) => console.error(e));
          }
          if (isBillAccessible) {
            setHasAccessToTransaction(true);
          }
          if (filter) {
            const urlParams = {};
            urlParams.recipient = filter.recipientName;
            if (filter.minAmount && filter.maxAmount) {
              urlParams.minAmount = filter.minAmount;
              urlParams.maxAmount = filter.maxAmount;
            }
            if (filter.minDate && filter.maxDate) {
              urlParams.startDate = filter.minDate;
              urlParams.endDate = filter.maxDate;
            }
            setTransactionLink(billParamsBuilder(urlParams));
          }
        }
        setIsLoadingCheckInvoice(false);
      } catch {
        setIsLoadingCheckInvoice(false);
      }
    };

    const isEligibleToCheckSimilarInvoice = () => {
      if (!selectedVendor) return false;

      if (!checkInvoiceFocusField) return false;

      return paymentAmount || (paymentAmount && billData) || form.getFieldValue("invoiceNumber");
    };

    useEffect(() => {
      setTransactionLink(null);

      if (!isEligibleToCheckSimilarInvoice()) return;

      let timeout = 0;
      if (isDebounceCheckInvoice) {
        timeout = 500;
      }
      const timer = setTimeout(() => {
        onCheckSimilarInvoice();
      }, timeout);

      return () => {
        clearTimeout(timer);
      };

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkInvoiceFocusField, selectedVendor]);

    useEffect(() => {
      if (!isWithholdingTaxEnabled) {
        return;
      }

      const beneficiaryWithholdingTax = selectedVendor?.dynamicFields?.find?.(
        (item) => item.alias === "beneficiaryWithholdingTax"
      );
      if (beneficiaryWithholdingTax) {
        setIsWHT(beneficiaryWithholdingTax.value === "true");
      }
    }, [selectedVendor, isWithholdingTaxEnabled]);

    const submitButtonText = isUseApprovalSystem ? `Submit for Approval` : `Submit for Payment`;

    const vendorDefaultValue = useMemo(() => {
      if (!billData) {
        return;
      }

      const dynamicFields = billData.dynamicFields.map((item) => ({
        id: item.id,
        alias: item.fieldAlias,
        value: item.value,
      }));

      return {
        beneficiaryName: billData.beneficiary.name,
        billID: billData.id,
        countryCode: billData.beneficiary.bankCountry,
        currencyCode: billData.currency,
        dynamicFields,
        organisationID: billData.organisation.ID,
        recipientEmail: billData.beneficiary.email?.split(","),
        vendorID: billData.beneficiary.vendorID?.toString(),
        vendorName: billData.beneficiary.legalName,
        isVendorLinked: billData.beneficiary.isVendorLinked,
      };
    }, [billData]);

    const renderSLA = (
      <DynamicSLA
        amount={paymentAmount}
        beneficiaryBankCountry={recipientBankCountry}
        currency={paymentCurrency}
        senderCountry={orgDetail?.data?.payload?.countryCode}
        tooltipArrowAlignment="left"
        tooltipMessage={`Estimated Arrival is calculated from the time payment status changes to "Sending Payment". Please ensure all information has been submitted and there is sufficient balance.`}
        onChangeSLA={onChangeSLA}
        labelClassName={styles.slaLabel}
        valueClassName={styles.slaValue}
      />
    );

    const youPaySection = senderCurrency && (
      <div className={styles.youPaySection}>
        <div className={styles.youPayLabel}>
          <p>You Pay</p>
          <Tooltip
            text="This is the final amount that would be deducted immediately from your balance when payment is sent."
            alignArrow={TooltipArrowAlignmentEnum.CENTER}
            position={TooltipPositionEnum.TOP}
          >
            <img src={newInfo} alt="tooltip info icon" width={16} height={16} />
          </Tooltip>
        </div>
        <div className={styles.youPayAmount}>
          {currencyFormatterV2(
            (isShowBillFeeCalculator ? billFee?.totalAmount : senderAmount) || senderAmount,
            senderCurrency
          )}
        </div>
      </div>
    );

    const fxReviewedMessage = "Exchange rate has been updated. Please review the amounts below before proceeding.";
    const editBillNotFixedRateMessage =
      "When you edit the amount and/or currency, the previous exchange rate will be discarded and the current rates will be applied.";
    const draftBillAmountChangedMessage =
      "The amount/currency has been updated with the current exchange rate applied.";
    let fxBannerMessage;
    if (isEditBill && !isFixedRate) fxBannerMessage = editBillNotFixedRateMessage;
    else if (isDraftBill && !isFixedRate) fxBannerMessage = draftBillAmountChangedMessage;
    else if (isFxRateReviewed) fxBannerMessage = fxReviewedMessage;

    const handleOnFinish = (values) => {
      if (!showPreviewModal && isWithholdingTaxEnabled && isWHT) setShowWithholdingModal(true);
      else handlePreview(values);
    };

    return (
      <InvoiceFormContext.Provider value={{ vendorList, setVendorList }}>
        <div className={styles.container}>
          {isLoadingOcr && (
            <div className={styles.overlay}>
              <Loading3Quaters className={styles.loading} />
            </div>
          )}
          <Form
            form={form}
            initialValues={{ layout: "vertical", entityType: "company" }}
            onFinish={handleOnFinish}
            onFinishFailed={onFinishFailed}
            data-testid="invoice-form"
            onValuesChange={handleFormChange}
          >
            <div className={styles.boxed}>
              <VendorSection
                defaultValue={vendorDefaultValue}
                form={form}
                formOnChangeCount={formOnChangeCount}
                ocrData={ocrResult}
                onChangeCountry={(code) => {
                  setRecipientBankCountry(code);
                }}
                onChangeCurrency={(code) => {
                  setPaymentCurrency(code);
                }}
                onEditVendor={setIsEditedVendor}
                onNewVendor={setIsAddNewVendor}
                onSelectVendor={setSelectedVendor}
                paymentCurrency={paymentCurrency}
                recipientBankCountryCode={recipientBankCountry}
                trackView={trackView}
                trackEdit={trackEdit}
                billFormType={billFormType}
              />
            </div>
            {isWHT && (
              <div className={styles.boxed}>
                <WHTCalculator
                  form={form}
                  defaultValue={isEditModal ? taxData : undefined}
                  onTaxDataChange={setTaxData}
                />
              </div>
            )}
            <div className={styles.boxed}>
              <CurrencyInput label="Invoice Amount" selectDisabled={false} name="invoiceAmount" />
              <ExchangeCalculator
                form={form}
                corridorType={corridorType}
                fxRate={fxRate}
                isFixedRate={isEditBill && isFixedRate}
                ocrData={ocrResult}
                receiverCountry={recipientBankCountry}
                receiverAmount={String(paymentAmount || "")}
                senderAmount={String(senderAmount || "")}
                receiverCurrencyCode={paymentCurrency}
                senderCurrencyCode={senderCurrency}
                isFullPayment={isFullPayment}
                refetchFxRate={getExchangeRate}
                onChangeReceiverAmount={onChangePaymentAmount}
                onChangeSenderAmount={setSenderAmount}
                onChangeWalletAmount={setWalletAmount}
                onChangePayFullGuarantee={setIsFullPayment}
                onChangeBillFee={setBillFee}
                isAmountMismatch={isAmountMismatch}
                disabled={isWHT}
              />
              {payFromField}
              <Form.Item name="slaID">{renderSLA}</Form.Item>
            </div>
            <div className={styles.boxed}>
              <DateSection
                corridorType={corridorType}
                billDueDate={billDueDate}
                paymentDate={paymentDate}
                billIssuedDate={billIssuedDate}
                disabledBillDueDateField={disableFieldBillDueDate}
                disabledPaymentDate={disabledDatePaymentDate}
                disabledBillIssuedDate={disabledDateBillIssuedDate}
                actionBillDueDate={actionBillDueDate}
                actionPaymentDate={actionPaymentDate}
                actionBillIssuedDate={actionBillIssuedDate}
                trackView={trackView}
                renderOptionalLabel={renderOptionalLabel}
                renderOCRLabel={renderOCRLabel}
                ocrResult={ocrResult}
                isPaymentDateSelectedFirst={isPaymentDateSelectedFirst}
                billDueDateLoading={isLoadingCheckInvoice && checkInvoiceFocusField?.field === "billDueDate"}
              />
            </div>

            <div ref={invoiceNumberRef}>
              <InvoiceNumber
                label={
                  Boolean(ocrResult.invoiceNumber) ? (
                    <OCRTooltip
                      label="Invoice Number"
                      isOCROveridden={ocrResult.invoiceNumber !== form.getFieldValue("invoiceNumber")}
                    />
                  ) : (
                    "Invoice Number"
                  )
                }
                isValid={isValidInvoiceNumber}
                onTrackEdit={trackEdit}
                onTrackView={trackView}
                onChange={resetValidateInvoiceNumber}
                isLoading={isLoadingCheckInvoice && checkInvoiceFocusField?.field === "invoiceNumber"}
                bannerLink={transactionLink}
                isTransactionAccessible={hasAccessToTransaction}
              />
            </div>
            <Form.Item label={renderOptionalLabel("Remarks for Recipient")} name="remarks">
              <Input.TextArea rows={4} placeholder="Remarks will be shown on the receipt" maxLength={255} />
            </Form.Item>
            <Form.Item label={renderOptionalLabel("Send Payment Updates To")}>
              <TagInput
                data-testid="additional-emails"
                placeholder="Enter emails"
                value={additionalEmails}
                onChange={onChangeAdditionalEmail}
                onDeselect={onDeselectAdditionalEmail}
                onFocus={() => trackView("additionalEmails", additionalEmails)}
              />
            </Form.Item>
            <div className={styles.spacing}></div>
            <Form.Item name="categorization" onChange={onChangeCategorization} valuePropName="checked">
              <Checkbox>
                <span className={styles.categorization}>Add categorization and notes</span>
              </Checkbox>
            </Form.Item>
            {isAddCategorization && (
              <Categorization
                userInfoData={userInfo.data}
                taxesData={taxesData}
                renderOptionalLabel={renderOptionalLabel}
                trackView={trackView}
                trackEdit={trackEdit}
                setTags={(tags) => {
                  form.setFieldsValue({ tags });
                }}
                form={form}
                isWHT={isWHT}
              />
            )}
            <div className={styles.spacing}></div>
            {!isEditModal && (
              <Button rounded disabled={isLoadingOcr || isSubmit || walletData?.length === 0}>
                {isSubmit ? (
                  <LoadingIcon size="s" />
                ) : (
                  <>
                    Preview Bill
                    <ArrowRightOutlined />
                  </>
                )}
              </Button>
            )}
          </Form>
          <PreviewModal
            visible={showPreviewModal}
            setVisible={setShowPreviewModal}
            billID={billData?.id}
            billFormType={billFormType}
          >
            <PreviewContent
              fxRateBannerRef={previewFxRateBannerRef}
              currency={paymentCurrency}
              destinationCountry={recipientBankCountry}
              isVendorEdited={previewPayload?.isEditedVendor}
              isConfirmSaveVendor={isConfirmSaveVendor}
              setIsConfirmSaveVendor={setIsConfirmSaveVendor}
              data={previewContent}
              sections={previewPayload?.sections}
              statusJourney={statusJourney()}
              statusJourneyDesc={getStatusJourneyDesc()}
              sla={renderSLA}
              showFxRateBanner={paymentCurrency !== senderCurrency && (isFxRateReviewed || !isFixedRate || isDraftBill)}
            />
            <PreviewModal.Footer>
              <Button
                type="button"
                rounded
                disabled={isLoadingOcr || isSubmit || walletData?.length === 0}
                action={handleSubmitForm}
              >
                {isSubmit ? (
                  <LoadingIcon size="s" />
                ) : (
                  <>
                    {submitButtonText} <ArrowRightOutlined />
                  </>
                )}
              </Button>
            </PreviewModal.Footer>
          </PreviewModal>

          <WithholdingTaxModal
            data={{
              paymentAmount,
              paymentCurrency,
              taxData: form.getFieldValue("whtTaxCategory"),
              taxAmount: taxData?.taxAmount,
            }}
            visible={showWithholdingModal}
            close={() => {
              setShowWithholdingModal(false);
            }}
            action={() => {
              handlePreview(form.getFieldsValue());
            }}
          />

          <SaveVendorModal
            isEdit={isEditedVendor}
            recipientName={form.getFieldValue("vendorName")}
            visible={showVendorModal}
            setVisible={setShowVendorModal}
            action={() => {
              onFinish(form.getFieldsValue(), true);
            }}
            cancel={() => {
              onFinish(form.getFieldsValue(), false);
            }}
          />
          <Toaster {...toaster} size={TOASTER_SIZE_TYPE.M} onClose={clearToaster} />
        </div>
      </InvoiceFormContext.Provider>
    );
  }
);

InvoiceForm.defaultProps = {
  ocrResult: {},
  isLoadingOcr: false,
  isFromTransactionPage: false,
  id: null,
  uploadedFiles: [],
  deletedAttachmentId: [],
  shouldSendOcrId: false,
  billFormType: BillFormTypes.New,
};

InvoiceForm.propTypes = {
  billData: PropTypes.object,
  ocrResult: PropTypes.object,
  isLoadingOcr: PropTypes.bool,
  isFromTransactionPage: PropTypes.bool,
  id: PropTypes.string,
  uploadedFiles: PropTypes.array,
  deletedAttachmentId: PropTypes.array,
  shouldSendOcrId: PropTypes.bool,
  billFormType: PropTypes.string,
};

export default InvoiceForm;
