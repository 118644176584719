import React, { useEffect, useMemo } from "react";
import dayjs from "dayjs";
import qs from "query-string";
import { useHistory, useLocation } from "react-router-dom";

import { DateFilter, IDateFormProps } from "Modules/DS/Filter";
import { ILocationQueryParams } from "Views/Reimbursement/@types";
import { REIMBURSEMENT_QUERY_PARAMS } from "Views/Reimbursement/Constants";

const PaidDateFilter = () => {
  const history = useHistory();
  const location = useLocation();

  const query: ILocationQueryParams = qs.parse(location.search, {
    parseNumbers: true,
    parseBooleans: true,
  });

  const { startDate, endDate } = query;

  const processDate = (date: number) => {
    return dayjs.unix(date).format("YYYY-MM-DD");
  };

  const startOfMonth = dayjs().startOf("month").unix();
  const today = dayjs().unix();

  useEffect(() => {
    if (!startDate || !endDate) {
      history.push({
        pathname: location.pathname,
        search: qs.stringify({
          ...query,
          [REIMBURSEMENT_QUERY_PARAMS.START_DATE]: startOfMonth,
          [REIMBURSEMENT_QUERY_PARAMS.END_DATE]: today,
        }),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnApply = (value: IDateFormProps) => {
    history.push({
      pathname: location.pathname,
      search: qs.stringify({
        ...query,
        [REIMBURSEMENT_QUERY_PARAMS.START_DATE]: dayjs(value.startDate).unix(),
        [REIMBURSEMENT_QUERY_PARAMS.END_DATE]: dayjs(value.endDate).unix(),
      }),
    });
  };

  const handleOnClear = () => {
    history.push({
      pathname: location.pathname,
      search: qs.stringify({
        ...query,
        [REIMBURSEMENT_QUERY_PARAMS.START_DATE]: undefined,
        [REIMBURSEMENT_QUERY_PARAMS.END_DATE]: undefined,
      }),
    });
  };

  const filter = useMemo(
    () => ({
      ...(startDate && {
        [REIMBURSEMENT_QUERY_PARAMS.START_DATE]: processDate(startDate),
      }),
      ...(endDate && {
        [REIMBURSEMENT_QUERY_PARAMS.END_DATE]: processDate(endDate),
      }),
    }),
    [endDate, startDate]
  );

  return (
    <DateFilter
      filter={filter}
      endKey="endDate"
      startKey="startDate"
      onApply={handleOnApply}
      onClear={handleOnClear}
      defaultValue="Paid On"
      id="REIMBURSEMENTS-ACTION-PAID-DATE-FILTER"
    />
  );
};

export default PaidDateFilter;
