import * as React from "react";
import { Button } from "antd";
import Loader, { LoaderTypes } from "Views/Card/Components/Loader";
import styles from "Views/Card/Details/ActionBtns/index.module.scss";
interface IDeleteButtonProps {
  onCardDelete: () => void;
  deleteInProgress: boolean;
}

export const DeleteButton = ({ onCardDelete, deleteInProgress }: IDeleteButtonProps) => {
  return (
    <>
      <Button
        className={`${styles["btn"]} ${styles["pinkish-white-btn"]}`}
        disabled={deleteInProgress}
        onClick={() => onCardDelete()}
        data-testid="delete-btn"
      >
        {deleteInProgress ? <Loader type={LoaderTypes.red} /> : "Delete Card"}
      </Button>
    </>
  );
};
