import React, { useEffect } from "react";
import { InfoFilled } from "@spenmo/splice";
import useSWRMutation from "swr/mutation";
import { useFormContext } from "react-hook-form";

import { postData } from "API/Client";

import Preview from "../components/Preview";

import { useBill } from "../context/BillContext";

import { API_URL } from "../constants";
import { getRecipientPayload } from "./helper";

const RecipientPreview: React.FC = () => {
  const { getValues } = useFormContext();
  const { dynamicFieldsIdentifier, recipientSelectedID } = useBill();

  const { trigger, data } = useSWRMutation(API_URL.recipientPreview, (url, { arg }: { arg: any }) =>
    postData(url, arg)
  );
  const previewData = data?.data?.payload || {};

  useEffect(() => {
    trigger(getRecipientPayload(getValues(), dynamicFieldsIdentifier, recipientSelectedID));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!previewData) {
    return null;
  }

  return (
    <Preview
      data={previewData.sections || []}
      isEdited={previewData.isEdited}
      banner={
        previewData.isEdited
          ? {
              description: "Updates will not be reflected in bills currently in approval.",
              title: "Updated recipient details will be applied to the current bill",
              variant: "info",
              icon: InfoFilled,
            }
          : undefined
      }
    />
  );
};

export default RecipientPreview;
