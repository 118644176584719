import * as React from "react";
import classNames from "classnames";
import { chevronIcon16x16 } from "assets/icons/card";
import styles from "./index.module.scss";

interface IChooseLockOption {
  children: React.ReactNode;
  onClick: () => void;
  active: boolean;
  value?: string;
  onRemove?: () => void;
  size?: "l" | null;
}
const ChooseLockOption = ({
  children,
  onClick,
  active,
  value = "",
  onRemove = () => {
    /* do nothing */
  },
  size = null,
}: IChooseLockOption) => {
  const buttonClassName = classNames(styles["choose-lock-option"], {
    [styles[`choose-lock-option__${size}`]]: size,
  });
  const radioBtnClassName = classNames(styles["choose-lock-option__radio"], {
    [styles["choose-lock-option__radio--active"]]: active,
  });
  return (
    <div className={styles["choose-lock-option__container"]}>
      <button className={buttonClassName} onClick={onClick}>
        <div className={radioBtnClassName} />
        <div>{children}</div>
        <img src={chevronIcon16x16} alt="Chevron Icon" />
      </button>
      {value ? (
        <div className={styles["choose-lock-option__value__container"]}>
          <p className={styles["choose-lock-option__value"]}>{value}</p>
          <button className={styles["choose-lock-option__remove-btn"]} onClick={onRemove}>
            Remove
          </button>
        </div>
      ) : null}
    </div>
  );
};
export default ChooseLockOption;
