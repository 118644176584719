import APIclient from "API/Client";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";

export enum COUNTRY_LIST_ACTION_TYPES {
  REQUEST = "GET_COUNTRY_LIST_REQUEST",
  SUCCESS = "GET_COUNTRY_LIST_SUCCESS",
  ERROR = "GET_COUNTRY_LIST_ERROR",
}
export interface ICountryListPayload {
  code: string;
  id: number;
  name: string;
  nationality_name: string;
}

export interface ICountryListData {
  payload: ICountryListPayload;
  status: number;
}

export interface ICountryListReducer {
  loading: boolean;
  data: ICountryListData;
  error: boolean;
}

const fetchDataRequest = (data?: any) => {
  return {
    type: COUNTRY_LIST_ACTION_TYPES.REQUEST,
    data,
  };
};

const fetchDataSuccess = (data?: any) => {
  return {
    type: COUNTRY_LIST_ACTION_TYPES.SUCCESS,
    data,
  };
};

const fetchDataError = (data: any) => {
  return {
    type: COUNTRY_LIST_ACTION_TYPES.ERROR,
    data,
  };
};

export const getCountryList = (isPublic: boolean = false) => {
  return (dispatch) => {
    dispatch(fetchDataRequest());
    const APIURL = isPublic ? "/ms/spm-organisation/public/v1/countries" : "/ms/spm-organisation/v1/countries";

    APIclient.getData(APIURL)
      .then((response) => {
        if (response?.data?.status === HTTP_STATUS_CODE.OK) {
          dispatch(fetchDataSuccess(response.data));
        } else {
          dispatch(fetchDataError(response.data));
        }
      })
      .catch((error) => {
        dispatch(fetchDataError(error?.response?.data));
      });
  };
};
