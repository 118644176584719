import React from "react";
import { PropTypes } from "prop-types";
import { useDispatch } from "react-redux";
import { rightArrowWhiteLg } from "assets/img";

import Button from "Modules/button";
import Icon from "Modules/icons";
import { Toaster } from "Modules/toaster";

import BulkUploadErrorModal from "Views/Employee/Components/BulkUploadErrorModal";
import ManualBulk from "Views/Employee/Components/ManualBulk";

import { downloadEmployeeCSV } from "Redux/Actions";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import { ERROR_STATUS_CODE } from "constants/ErrorStatusCode.constants";

import "./EmployeeCSVUpload.scss";

const EmployeeCSVUpload = ({ next, bringEmployeesLater, toggleList, statusCheck }) => {
  const [status, setStatus] = React.useState(null);
  const [showToaster, setShowToaster] = React.useState(false);
  const [showErrorModal, setShowErrorModal] = React.useState(false);
  const [errorLink, setErrorLink] = React.useState("");
  const [statusMessage, setStatusMessage] = React.useState("");
  const genericErrorMsg = "There is an error within our system.  Please try again in a few moments";
  const dispatch = useDispatch();
  const redirectToNextStep = () => {
    if (bringEmployeesLater && status === "success") {
      toggleList();
      statusCheck && statusCheck(status);
    } else next();
  };
  const handleDownloadCSV = () => {
    dispatch(downloadEmployeeCSV());
  };
  const handleEmployeeUpload = (res) => {
    const { status, error } = res?.data || {};

    if (status !== HTTP_STATUS_CODE.CREATED && error?.code === ERROR_STATUS_CODE.ERROR_LINK) {
      setShowErrorModal(true);
      setErrorLink(error?.message);
    } else if (status !== HTTP_STATUS_CODE.CREATED) {
      setShowToaster(true);
      if (status === HTTP_STATUS_CODE.BAD_REQUEST) {
        setStatusMessage(error?.message);
      } else {
        setStatusMessage(genericErrorMsg);
      }
    }
  };
  return (
    <div className={"employee-csv-upload"}>
      <ManualBulk
        id={"employeeCSVUploadBulk"}
        title={"Add many employees in a go"}
        subtitle={"Each of the added employee(s) will be notified by email once we finish creating accounts for them."}
        uploadTitle={"Upload template with your employees data"}
        action={setStatus}
        onDownload={handleDownloadCSV}
        customUploadAction={handleEmployeeUpload}
        customUploadURL={"/ms/spm-organization/v1/onboarding/employee-file"}
      />
      {status === "success" && (
        <Button
          text={"Finish"}
          className="onboarding-arrow-btn"
          rounded
          id={"bringEmployeeBtn"}
          action={redirectToNextStep}
          icon={<Icon src={rightArrowWhiteLg} alt={"arrow"} />}
        />
      )}
      <Toaster
        className={`onboarding--toaster ${bringEmployeesLater ? "bring-emp-later" : ""}`}
        message={statusMessage}
        status={status}
        visible={showToaster}
        closeFunc={() => setShowToaster(false)}
      />
      <BulkUploadErrorModal close={() => setShowErrorModal(false)} show={showErrorModal} errorLink={errorLink} />
    </div>
  );
};
EmployeeCSVUpload.propTypes = {
  next: PropTypes.func,
  bringEmployeesLater: PropTypes.bool,
  toggleList: PropTypes.func,
  statusCheck: PropTypes.any,
};
export default EmployeeCSVUpload;
