import React, { useContext } from "react";
import { useAppSelector } from "hook";
import { USER_ROLE } from "constants/Team.constant";
import { getCookieValue, cookieNames } from "utility/CookieHelper";
import {
  ISmartCSVProps,
  ITrxnFilterContext,
  ITrxnModalContext,
  IGetTrxnRequest,
  IGetTrxnsCountRequest,
  ITrxnsSuccessData,
} from "Views/Transaction/@types";
import { SmartCSV } from "Views/Transaction/SmartCSV/v2";
import { trimPaginationAndListParams } from "Views/Transaction/DataCalls";
import { TrxnModalContext, TrxnFilterContext } from "Views/Transaction/Context";
import { useAllTrxnExportOptions } from "Views/Transaction/All/useCSV";
import { useCountFetch, useListFetch, useTransactionReceiptsCountFetch } from "Views/Transaction/All/useFetch";
import { useAccountingTransaction } from "Views/Transaction/Context/AccountingTransactions";

export const AllTrxnDownload = ({ isExportButtonEnabled }: { isExportButtonEnabled?: boolean }) => {
  const { activePageDetails } = useAccountingTransaction();

  // filters applied in the all trxns tab which are accessible on the page level - used for Export feature in page header
  const appliedFilters = activePageDetails?.allTrxnFilters || {};

  // filters applied in the all trxns tab which are accessible on the tab level - used for existing Download Data feature
  const { filter }: ITrxnFilterContext = useContext<ITrxnFilterContext>(TrxnFilterContext);

  const loggedInUserDetails = useAppSelector((state: any) => state?.userInfoReducer);
  const isEmployee = getCookieValue(cookieNames.USER_COMPANY_ROLE) === USER_ROLE.EMPLOYEE;
  const isEmployeeTeamManager = isEmployee && loggedInUserDetails?.manageTeams?.length > 0;

  const { count, fetchCount } = useCountFetch();

  const { receiptsCount, fetchReceiptsCount } = useTransactionReceiptsCountFetch();

  const { data, fetchData } = useListFetch();

  const { downloadBankStatement, downloadRawCSV, emailBankStatement, exportRawCSV, emailRawCSV, emailDownloadReceipt } =
    useAllTrxnExportOptions(count, null, isExportButtonEnabled);

  const { toggleModal }: ITrxnModalContext = useContext<ITrxnModalContext>(TrxnModalContext);

  const params: IGetTrxnRequest = isExportButtonEnabled ? { ...appliedFilters } : { ...filter };

  trimPaginationAndListParams<IGetTrxnsCountRequest>(params);

  const props: ISmartCSVProps<IGetTrxnsCountRequest> = {
    fetchCount,
    fetchReceiptsCount,
    exportRawCSV,
    emailBankStatement,
    emailDownloadReceipt,
    emailRawCSV,
    // BE doesn't support sending limit as 0, and when no limit value or 0 is passed the
    // default BE response contains a maximun of 20 records as that is their default limit
    // So we are setting the limit as one to make sure we don't receive more data than is
    // absolutely necessary. So far we only require the correct record count for CSV.
    fetchCsvCount: (params?: IGetTrxnsCountRequest) => fetchData({ ...params, limit: 1 }),
    totalCSVCount: (data as ITrxnsSuccessData).count,
    totalStatementCount: count,
    totalReceiptsCount: receiptsCount,
    showModalType: toggleModal,
    filter: params as IGetTrxnsCountRequest,
    customCsv: {
      enabled: !isEmployeeTeamManager,
    },
    rawCsv: {
      enabled: true,
      download: downloadRawCSV,
    },
    downloadReceipt: {
      enabled: true,
    },
    bankStatement: {
      enabled: !isEmployeeTeamManager,
      download: downloadBankStatement,
    },
    isExportButtonEnabled,
  };

  return <SmartCSV<IGetTrxnsCountRequest> {...props} />;
};
