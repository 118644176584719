import { LabelChip } from "./Label";
import { StatusChip } from "./Status";
import { ActionChip } from "./Action";
import { TierChip } from "./Tier";

import { TitleWithIconPrefixed } from "./Title";

const Chip = {
  Label: LabelChip,
  Status: StatusChip,
  Action: ActionChip,
  Title: {
    PrefixIcon: TitleWithIconPrefixed,
  },
  Tier: TierChip,
};

export * from "./types.d";
export default Chip;
