import APIclient from "API/Client";
import { GetBaseAuthObject } from "../../utility";

export const FETCH_DELETE_REQUEST = "FETCH_DELETE_REQUEST";
export const FETCH_DELETE_SUCCESS = "FETCH_DELETE_SUCCESS";
export const FETCH_DELETE_FAILURE = "FETCH_DELETE_FAILURE";

export const fetchDeleteDataReq = (data) => {
  return {
    type: FETCH_DELETE_REQUEST,
    data: data,
  };
};

export const fetchDeleteDataSuccess = (data) => {
  return {
    type: FETCH_DELETE_SUCCESS,
    data: data,
  };
};

export const fetchDeleteDataError = (data) => {
  return {
    type: FETCH_DELETE_FAILURE,
    data: data,
  };
};

export const scheduleDeleteFunc = (type, id) => {
  const orgId = GetBaseAuthObject().orgId;
  return (dispatch) => {
    dispatch(fetchDeleteDataReq());
    APIclient.putData(`/api/v1/fund/${type}/${id}`, { is_deleted: true, organisation_id: orgId })
      .then((data) => {
        if (data.data.status > 200) {
          dispatch(fetchDeleteDataError(data.data));
        } else {
          dispatch(fetchDeleteDataSuccess(data.data));
        }
      })
      .catch((err) => {
        dispatch(fetchDeleteDataError(err));
      });
  };
};
