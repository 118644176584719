import React from "react";
import PropTypes from "prop-types";
import "./tableRowField.scss";

const TableRowField = ({
  title,
  details,
  isBold = false,
  isSubHeader = false,
  className = "",
  id = "",
  action,
  actionName = "",
}) => {
  return (
    <div className={`table-row-field ${className && className}`} id={id}>
      <h4 className={`table-row-field__title ${isBold && "table-row-field__title--bold"}`}>{title}</h4>
      <p className={`table-row-field__details ${isSubHeader && "table-row-field__details--header"}`}>
        {details === "undefined" ? "" : details}
      </p>
      {action && (
        <div className={"table-row-field__action"} onClick={action}>
          {actionName}
        </div>
      )}
    </div>
  );
};

TableRowField.propTypes = {
  title: PropTypes.string,
  details: PropTypes.any,
  isBold: PropTypes.bool,
  isSubHeader: PropTypes.bool,
  className: PropTypes.string,
  id: PropTypes.string,
  action: PropTypes.func,
  actionName: PropTypes.string,
};

export default TableRowField;
