import React from "react";
import { Loader } from "Modules/DS/Modal/Components";
import styles from "./index.module.scss";
import Modal from "Modules/DS/Modal";
interface ILoadingWithInfo {
  title?: string;
  subTitle?: string;
}

const LoadingWithInfo = ({ title, subTitle }: ILoadingWithInfo) => {
  return (
    <Modal visible={true}>
      <div className={`${styles["loading-content"]}`}>
        <Loader />
        <h4 className={`${styles["title-loading"]}`}>{title}</h4>
        <p className={`${styles["sub-title"]}`}>{subTitle}</p>
      </div>
    </Modal>
  );
};

export default LoadingWithInfo;
