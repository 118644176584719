import { IOutsideViewport } from "./index";

export const siblingPosition = <T extends HTMLElement>(
  { right, left, top, bottom }: IOutsideViewport,
  el: T
): [number, number] => {
  const siblingEl = el?.previousElementSibling as HTMLElement;

  if (!siblingEl) return [0, 0];

  let x: number = 0;
  let y: number = 0;

  const siblingPosition = siblingEl?.getBoundingClientRect();
  const elPosition = el?.getBoundingClientRect();

  if (right && !left) {
    x = siblingPosition.right - elPosition.right;
  } else if (left && !right) {
    x = elPosition.left - siblingPosition.left;
  }
  if (top && !bottom) {
    y = elPosition.top - siblingPosition.top;
  } else if (bottom && !top) {
    y = siblingPosition.bottom - elPosition.bottom;
  }

  return [x, y];
};

/**
 * Calculates the horizontal position of an element relative to its sibling element based on the specified options.
 * @template T - HTMLElement type
 * @param {IOutsideViewport} { right, left } - Options for positioning (right or left)
 * @param {T} el - The element to position
 * @returns {number[]} - Array containing the horizontal and vertical position
 */
export const horizontalPosition = <T extends HTMLElement>(
  { right, left }: IOutsideViewport,
  el: T
): [number, number] => {
  // Get the sibling element
  const siblingEl = el?.previousElementSibling as HTMLElement;

  // If there is no sibling element, return default position [0, 0]
  if (!siblingEl) return [0, 0];

  // Initialize horizontal position
  let x = 0;

  // Get the bounding rectangles of the sibling and target elements
  const siblingPosition = siblingEl?.getBoundingClientRect();
  const elPosition = el?.getBoundingClientRect();

  // Calculate the horizontal position based on the options
  if (right && !left) {
    // Position the target element at the left of the sibling element
    x = siblingPosition.right - elPosition.right;
  } else if (left && !right) {
    // Position the target element at the right of the sibling element
    x = elPosition.left - siblingPosition.left;
  }

  // Return the vertical position (always 0) and the calculated horizontal position
  return [x, 0];
};

/**
 * Calculates the vertical position of an element relative to its sibling element based on the specified options.
 * @template T - HTMLElement type
 * @param {IOutsideViewport} { top, bottom } - Options for positioning (top or bottom)
 * @param {T} el - The element to position
 * @returns {number[]} - Array containing the horizontal and vertical position
 */
export const verticalPosition = <T extends HTMLElement>({ top, bottom }: IOutsideViewport, el: T): [number, number] => {
  // Get the sibling element
  const siblingEl = el?.previousElementSibling as HTMLElement;

  // If there is no sibling element, return default position [0, 0]
  if (!siblingEl) return [0, 0];

  // Initialize vertical position
  let y = 0;

  // Get the bounding rectangles of the sibling and target elements
  const siblingPosition = siblingEl?.getBoundingClientRect();
  const elPosition = el?.getBoundingClientRect();
  // Get the top margin of the target element
  const marginSize = parseInt(getComputedStyle(el).marginTop) ?? 0;

  // Calculate the vertical position based on the options
  if (top && !bottom) {
    // Position the target element at the bottom of the sibling element
    y = elPosition.top - siblingPosition.top;
  } else if (bottom && !top) {
    // Position the target element at the top of the sibling element
    y = siblingPosition.bottom - siblingPosition.height - elPosition.bottom - marginSize;
  }

  // Return the horizontal position (always 0) and the calculated vertical position
  return [0, y];
};
