export const userInitialState = {
  loading: false,
  data: { payload: null, linkedAccount: [] },
  error: false,
  step: "IDLE",
};

export const getPrefixedInitialState = (prefix = "") => ({
  [`${prefix}Loading`]: false,
  [`${prefix}Data`]: null,
  [`${prefix}Error`]: false,
});
