import React from "react";
import classNames from "classnames";

import {
  TSectionProps,
  ISectionCard,
  ISectionContainer,
  ISingleSectionCard,
  ISingleSectionCardHeader,
  IOptionalSectionCardHeading,
  ISectionCardWithOptionalSection,
} from "Views/KYX/@types";
import { FORM_SECTION_COMPONENT } from "Views/KYX/Constants";
import GuidelinesButton from "Views/KYX/Modal/Guidelines/Button";

import styles from "./index.module.scss";

export const OptionalSectionCardHeading = ({
  title,
  guidelines,
  optional,
}: IOptionalSectionCardHeading): JSX.Element => {
  return (
    <div className={styles.optionalHeading}>
      <div className={styles.subSection}>
        <h5 className={styles.subTitle}>{title}</h5>
        {optional && <span className={styles.optional}> (Optional)</span>}
      </div>
      {guidelines && (
        <div className={styles.guidelines}>
          <GuidelinesButton type={guidelines} />
        </div>
      )}
    </div>
  );
};

export const SingleSectionCardHeader = ({
  number,
  title,
  optional,
  guidelines = false,
}: ISingleSectionCardHeader): JSX.Element => {
  return (
    <div className={styles.header}>
      {number && <p className={styles.serialNo}>{number}</p>}
      <div className={styles.heading}>
        <p className={styles.title}>{title}</p>
        {optional && <span className={styles.optional}> (Optional)</span>}
      </div>
      {guidelines && (
        <div className={styles.guidelines}>
          <GuidelinesButton type={guidelines} />
        </div>
      )}
    </div>
  );
};

export const SingleSectionCard = ({
  children,
  number,
  title,
  optional,
  guidelines = false,
  className,
}: ISingleSectionCard): JSX.Element => {
  return (
    <SectionContainer className={className}>
      <SingleSectionCardHeader number={number} title={title} guidelines={guidelines} optional={optional} />
      {children}
    </SectionContainer>
  );
};

export const SectionCardWithOptionalSection = ({
  children,
  header,
  optional,
  className,
}: ISectionCardWithOptionalSection): JSX.Element => {
  return (
    <SectionContainer className={className}>
      <SingleSectionCardHeader {...header} />
      {children}
      {optional.length > 0 &&
        optional.map((section) => {
          return (
            <div key={section.key}>
              {section?.header && <OptionalSectionCardHeading {...section.header} />}
              {section.children}
            </div>
          );
        })}
    </SectionContainer>
  );
};

export const SectionContainer = ({ children, className }: ISectionContainer): JSX.Element => {
  return <div className={classNames(styles.container, { [styles[className]]: className })}>{children}</div>;
};

export const SectionCard = ({ children, props, type, className }: ISectionCard) => {
  let Component: (prop: TSectionProps) => JSX.Element;

  switch (type) {
    case FORM_SECTION_COMPONENT.CARD:
      Component = SingleSectionCard;
      break;
    case FORM_SECTION_COMPONENT.MULTIPLE_SECTIONS_WITH_HEADERS_IN_CARD:
      Component = SingleSectionCard;
      break;
    case FORM_SECTION_COMPONENT.CARD_WITH_OPTIONAL:
      Component = SectionCardWithOptionalSection;
      break;
    case FORM_SECTION_COMPONENT.CONTAINER:
    default:
      Component = SectionContainer;
      break;
  }

  return <Component {...{ className, ...props }}>{children}</Component>;
};
