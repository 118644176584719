import React from "react";
import { IColumnData } from "Modules/DS/DataTable";
import ShimmerEffect from "Views/Accounting/Workflow/ShimmerEffect";
import {
  AUTO_RECONCILED_TRXNS_COLUMNS,
  AUTO_RECONCILED_TRXNS_PARAMS,
} from "Views/SubscriptionManagement/Constants/AutoReconciled";
import { AmountCell } from "Views/Transaction/All/Columns/Amount";
import UpdateSubscription from "Views/SubscriptionManagement/AutoReconciled/UpdateSubscription";
import { TitleWithSubTitle } from "Views/SubscriptionManagement/List/Columns/Components";
import { IAutoReconciledTrxns, OnChange } from "Views/SubscriptionManagement/@types";
import { VendorImage } from "Views/SubscriptionManagement/Components/VendorImage";
import { dateTimeFormatter } from "utility";
import { SelectProvider } from "Modules/DS/Select";

export const Columns = (loading: boolean, onChange: OnChange): IColumnData<any>[] => {
  return [
    {
      id: "AUTO-RECONCILED-TRXN-COL-1",
      enabled: true,
      column: {
        title: AUTO_RECONCILED_TRXNS_COLUMNS.DATE_N_TIME,
        key: AUTO_RECONCILED_TRXNS_PARAMS.CREATED_AT,
        dataIndex: AUTO_RECONCILED_TRXNS_PARAMS.CREATED_AT,
        render: (created_at: string) => (
          <ShimmerEffect loading={loading}>
            <TitleWithSubTitle
              title={dateTimeFormatter(created_at).date}
              subtitle={dateTimeFormatter(created_at).time}
            />
          </ShimmerEffect>
        ),
      },
    },
    // {
    //   id: "AUTO-RECONCILED-TRXN-COL-2",
    //   enabled: true,
    //   column: {
    //     title: AUTO_RECONCILED_TRXNS_COLUMNS.TYPE,
    //     key: AUTO_RECONCILED_TRXNS_PARAMS.TYPE,
    //     dataIndex: AUTO_RECONCILED_TRXNS_PARAMS.TYPE,
    //     render: (type: string) => <ShimmerEffect loading={loading}>{type}</ShimmerEffect>,
    //   },
    // },
    {
      id: "AUTO-RECONCILED-TRXN-COL-3",
      enabled: true,
      column: {
        title: AUTO_RECONCILED_TRXNS_COLUMNS.OWNER_BUDGET,
        key: AUTO_RECONCILED_TRXNS_PARAMS.BUDGET,
        render: (record: IAutoReconciledTrxns) => (
          <ShimmerEffect loading={loading}>
            <TitleWithSubTitle title={record.owner.name} subtitle={record.budget.name} />
          </ShimmerEffect>
        ),
      },
    },
    {
      id: "AUTO-RECONCILED-TRXN-COL-4",
      enabled: true,
      column: {
        title: AUTO_RECONCILED_TRXNS_COLUMNS.VENDOR,
        key: AUTO_RECONCILED_TRXNS_PARAMS.VENDOR,
        dataIndex: AUTO_RECONCILED_TRXNS_PARAMS.VENDOR,
        render: (_, record) => <ShimmerEffect loading={loading}>{record.subscription?.vendor_name}</ShimmerEffect>,
      },
    },
    {
      id: "AUTO-RECONCILED-TRXN-COL-6",
      enabled: true,
      column: {
        title: AUTO_RECONCILED_TRXNS_COLUMNS.AMOUNT,
        key: AUTO_RECONCILED_TRXNS_PARAMS.AMOUNT,
        dataIndex: AUTO_RECONCILED_TRXNS_PARAMS.AMOUNT,
        render: (_, record) => (
          <ShimmerEffect loading={loading}>
            <AmountCell record={record} />
          </ShimmerEffect>
        ),
      },
    },
    {
      id: "AUTO-RECONCILED-TRXN-COL-7",
      enabled: true,
      column: {
        title: AUTO_RECONCILED_TRXNS_COLUMNS.SUBSCRIPTION,
        width: 250,
        render: (_, record) => (
          <ShimmerEffect loading={loading}>
            <SelectProvider>
              <UpdateSubscription
                transactionDate={record.created_at}
                transactionId={record.id}
                defaultSubscription={{
                  id: record?.subscription.id,
                  title: record?.subscription?.vendor_name,
                  IconComponent: <VendorImage vendor={record?.subscription?.vendor_name} width={18} height={20} />,
                }}
                onChange={onChange}
              />
            </SelectProvider>
          </ShimmerEffect>
        ),
      },
      width: 400,
    },
  ].filter((item) => item.enabled);
};
