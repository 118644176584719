import React, { useContext, useState } from "react";
import moment from "moment";
import classNames from "classnames";
import { Popover } from "antd";

import AnalyticsContext from "Views/Analytics/Context/context";
import s from "Views/Analytics/Layout/index.module.scss";
import { MoreFilters } from "./MoreFilters";
import { dateFormatter4, getCurrentDate } from "Views/Analytics/utils";
import CustomDateFilter, { CustomDateType } from "./Custom";

const quickFilters = [
  {
    label: "30 Days",
    key: 1,
    monthCompare: true,
  },
  {
    label: "3 Months",
    key: 3,
    monthCompare: true,
  },
  {
    label: "12 Months",
    key: 12,
    monthCompare: true,
  },
];

export const Filter = ({ title }) => {
  const { filter, setFilter, moreFilter, setMoreFilter, setClear } = useContext(AnalyticsContext);
  const [quickFilterActive, setQuickFilterActive] = useState(1);

  const handleQuickFilters = ({ key, monthCompare }) => {
    setQuickFilterActive(key);
    if (monthCompare) {
      setFilter((prevFilter) => ({
        ...prevFilter,
        startDate: moment()
          .add(key * -1, "M")
          .add(1, "d")
          .utc()
          .startOf("d")
          .toISOString(),
        endDate: getCurrentDate(),
        otherCustomFilter: {
          isCustom: false,
          type: "months",
        },
        differences: {
          months: key,
          days: 0,
        },
      }));
    }
  };

  const applyMoreFilter = (value: { [key: string]: string[] }) => {
    setMoreFilter(value);
    setFilter((prevState) => ({
      ...prevState,
      team_ids: value.team_ids || [],
      type_ids: value.type_ids || [],
    }));
  };
  const handleMoreFilter = (value: { [key: string]: string[] }) => {
    setMoreFilter(value);
  };
  const handleClearMoreFilter = () => {
    setMoreFilter({});
    setClear(true);
  };

  const handleResetMoreFilter = () => {
    setMoreFilter({});
    if (filter.team_ids.length || filter.type_ids.length) {
      setFilter((prevState) => ({
        ...prevState,
        team_ids: [],
        type_ids: [],
      }));
    }
  };

  const triggerCustomDateSelection = (date: CustomDateType) => {
    setFilter((prevState) => ({
      ...prevState,
      startDate: moment(date.start).utc().toISOString(),
      endDate: moment(date.end).utc().endOf("days").toISOString(),
      otherCustomFilter: {
        isCustom: true,
        type: date.key,
      },
    }));
    setQuickFilterActive(0);
  };

  return (
    <div className={s.header}>
      <div className={s.title}>{title}</div>
      <div className={s.filters}>
        <div className={s.quickFilters}>
          {quickFilters.map(({ key, label, monthCompare }) => {
            if (key === quickFilterActive) {
              return (
                <Popover
                  arrowPointAtCenter={true}
                  placement="bottom"
                  overlayClassName="analytics-tooltip"
                  content={dateFormatter4(filter.startDate, filter.endDate)}
                  key={key}
                >
                  <div
                    onClick={() => handleQuickFilters({ key, monthCompare })}
                    className={classNames(s.filterItem, {
                      [s.active]: quickFilterActive === key,
                    })}
                  >
                    {label}
                  </div>
                </Popover>
              );
            }
            return (
              <div
                onClick={() => handleQuickFilters({ key, monthCompare })}
                className={classNames(s.filterItem, {
                  [s.active]: quickFilterActive === key,
                })}
                key={key}
              >
                {label}
              </div>
            );
          })}

          <CustomDateFilter active={quickFilterActive === 0} triggerCustomDateSelection={triggerCustomDateSelection} />
        </div>

        <MoreFilters
          filters={moreFilter}
          handleMoreFilter={handleMoreFilter}
          applyFilter={applyMoreFilter}
          clearFilter={handleClearMoreFilter}
          resetFilter={handleResetMoreFilter}
        />
      </div>
    </div>
  );
};
