import React from "react";
import { quantifier } from "utility";
import CustomRepeatForm from "./CustomRepeatForm";

export const encodeValue = ({ interval, intervalType, type, endsAfter }) =>
  `${interval}${intervalType}${type === "LIMITED" ? `:${endsAfter}` : ""}`;

export const parseValue = (value) => {
  try {
    const extract = value?.match?.(/(\d+)([A-Z]+):?(\d+)?/);
    const payload = {
      interval: parseInt(extract[1]),
      intervalType: extract[2],
    };
    if (extract[3]) {
      payload.type = "LIMITED";
      payload.endsAfter = parseInt(extract[3]);
    } else {
      payload.type = "UNLIMITED";
      payload.endsAfter = 0;
    }
    return payload;
  } catch (e) {
    return null;
  }
};

export const RECURRING_OPTIONS = [
  { value: "0NONE:1", label: "Doesn't repeat" },
  { value: "1DAY", label: "Every day" },
  { value: "1WEEK", label: "Every week" },
  { value: "1MONTH", label: "Every month" },
  { value: "1YEAR", label: "Every year" },
  {
    value: "custom",
    label: "Custom...",
    title: "Custom Recurrence",
    detail: <CustomRepeatForm encodeValue={encodeValue} parseValue={parseValue} />,
  },
];

export const createListItem = (value) => {
  const { interval, intervalType, type, endsAfter } = parseValue(value) || {};
  let label = "Doesn't repeat";
  if (intervalType !== "NONE") {
    const endsAfterLabel = `, ${endsAfter} time${quantifier(endsAfter)}`;
    const typeLabel = type === "LIMITED" ? endsAfterLabel : "";
    label = `Every ${interval > 1 ? interval : ""} ${intervalType.toLowerCase()}${quantifier(interval)}${typeLabel}`;
  }
  const payload = {
    label,
    value,
  };
  if (!RECURRING_OPTIONS.find((item) => item.value === value)) {
    payload.detail = "custom";
  }
  return payload;
};
