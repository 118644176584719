import { useContext } from "react";
import dayjs from "dayjs";
import qs from "query-string";
import { Base64 } from "js-base64";
import { useLocation } from "react-router-dom";

import { postData } from "API/Client";

import { downloadCSV } from "utility";

import { appNotification, contactCSErrorMessage } from "Modules/appNotification/appNotification";

import { API_URL } from "Views/Bills/V2/DataCalls/Services";
import { EXPORT_DATE_RANGE_TYPES } from "Views/Bills/V2/constants";
import { PermissionAndSettingsContext } from "Views/Bills/V2/context/BillContext";
import { ILocationQueryParams, IExportPreviewPayload } from "Views/Bills/V2/@types";
import { FULL_CSV, GENERIC_PAYMENT_CSV } from "Views/Bills/V2/hooks/useExportOptionsList";

const useExportCSV = () => {
  const location = useLocation();

  const query: ILocationQueryParams = qs.parse(location.search, {
    parseNumbers: true,
    parseBooleans: true,
  });

  const {
    cycleId = "",
    endDate,
    exportType = "",
    markAsPaid = false,
    rangeType = "",
    startDate,
    approvedBills,
    pendingdBills,
  } = query;

  const { settings: paymentRunSettingEnabled } = useContext(PermissionAndSettingsContext);

  let type: string = undefined;
  let date_range: string = undefined;
  let start_date: string = undefined;
  let end_date: string = undefined;
  let cycle_id: number = undefined;
  let mark_as_paid: boolean = false;

  if (Number(startDate) && Number(endDate)) {
    start_date = dayjs.unix(startDate).format("YYYY-MM-DD");
    end_date = dayjs.unix(endDate).format("YYYY-MM-DD");
  }

  if (cycleId.length > 0) {
    cycle_id = Base64.decode(cycleId);
  }

  if (markAsPaid) {
    mark_as_paid = true;
  }

  if (rangeType.length > 0) {
    date_range = Base64.decode(rangeType);
  }

  if (exportType.length > 0) {
    type = Base64.decode(exportType);
  }

  const getType = (): "full" | "generic" => {
    let val = undefined;
    switch (type) {
      case GENERIC_PAYMENT_CSV.id:
        val = "generic";
        break;

      case FULL_CSV.id:
      default:
        val = "full";
        break;
    }
    return val;
  };

  const getFilename = (): string => {
    let val = dayjs().format("YYYYMMDD");
    switch (type) {
      case GENERIC_PAYMENT_CSV.id:
        val = `bill_generic_payment_${val}`;
        break;

      case FULL_CSV.id:
      default:
        val = `bill_full_${val}`;
        break;
    }
    return val;
  };

  const data = {
    type: getType(),
    markBillAsPaid: mark_as_paid,
    filters: {
      ...(date_range === EXPORT_DATE_RANGE_TYPES.PAYMENT_RUN && { cycleID: Number(cycle_id) }),
      ...(date_range === EXPORT_DATE_RANGE_TYPES.DUE_DATES && {
        dueDate: {
          startDate: start_date,
          endDate: end_date,
        },
      }),
      ...(date_range === EXPORT_DATE_RANGE_TYPES.CREATION_DATES && {
        createdDate: {
          startDate: start_date,
          endDate: end_date,
        },
      }),
    },
  };

  const exportCSV = async () => {
    try {
      if (date_range === EXPORT_DATE_RANGE_TYPES.PAYMENT_RUN ? isNaN(cycle_id) : isNaN(startDate) || isNaN(endDate)) {
        throw new Error("");
      }

      const response = await postData(API_URL.LIST.EXPORT_CSV, data);

      const startDownload = () => {
        appNotification.success({
          message: "Your download has successfully started",
        });
        downloadCSV(response.data, getFilename(), true);
      };

      switch (true) {
        case markAsPaid && paymentRunSettingEnabled && Boolean(approvedBills):
          startDownload();
          appNotification.success({
            message: "The current payment run period has been closed. Approved bills have been marked as paid.",
          });
          break;
        case markAsPaid && paymentRunSettingEnabled && Boolean(pendingdBills) && !approvedBills:
          appNotification.success({
            message: "The current payment run period has been closed. ",
          });
          break;
        case markAsPaid && !paymentRunSettingEnabled && Boolean(approvedBills):
          appNotification.success({
            message: "Approved bills have been marked as paid.",
          });
          startDownload();
          break;

        default:
          startDownload();
          break;
      }
    } catch (error) {
      appNotification.error(contactCSErrorMessage(error.response.data.error.code));
      switch (true) {
        case markAsPaid && paymentRunSettingEnabled && Boolean(approvedBills):
          appNotification.error({
            message: "The current payment run period has not been closed. Approved bills have not been marked as paid.",
          });
          break;
        case markAsPaid && paymentRunSettingEnabled && Boolean(pendingdBills) && !approvedBills:
          appNotification.error({
            message: "The current payment run period has not been closed. ",
          });
          break;
        case markAsPaid && !paymentRunSettingEnabled && Boolean(approvedBills):
          appNotification.error({
            message: "Approved bills have not been marked as paid.",
          });
          break;
        default:
          break;
      }
    }
  };

  const exportPreview = async (): Promise<IExportPreviewPayload> => {
    try {
      if (date_range === EXPORT_DATE_RANGE_TYPES.PAYMENT_RUN ? isNaN(cycle_id) : isNaN(startDate) || isNaN(endDate)) {
        throw new Error("");
      }

      const response = await postData(API_URL.LIST.EXPORT_CSV_PREVIEW, data);

      return {
        approvedBillsCount: response?.data?.payload?.approvedBillsCount || 0,
        pendingBillsCount: response?.data?.payload?.pendingBillsCount || 0,
        isCSVDownloadable: response?.data?.payload?.isCSVDownloadable || false,
        currentPaymentCycle: response?.data?.payload?.paymentRun?.label || "",
      };
    } catch (error) {
      appNotification.error(contactCSErrorMessage(error.response.data.error.code));
    }
  };

  return {
    exportCSV,
    exportPreview,
  };
};

export default useExportCSV;
