import Filter from "Modules/DS/Filter";
import SimpleFilter from "Modules/SimpleFilter";
import Search from "Modules/search";
import React, { useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { trackEvent } from "utility/analytics";
import { MANAGE_RECIPIENTS_SORT_BY } from "../../const";
import styles from "./RecipientsFilter.module.scss";

const RecipientsFilter = () => {
  const { pathname, search } = useLocation();
  const history = useHistory();

  // to re-mount all inputs for reset
  const [resetKey, setResetKey] = useState(0);

  const hasFilter = useMemo(() => {
    // value of search when no params is empty string or "?"
    return search.length > 1;
  }, [search]);

  const sortText = useMemo(() => {
    const urlSearchParams = new URLSearchParams(search);
    const sortBy = urlSearchParams.get("sortBy");
    const sortDir = urlSearchParams.get("sortDir");
    const prefix = "Sort By: ";
    let label = MANAGE_RECIPIENTS_SORT_BY[0].label;
    if (sortBy && sortDir) {
      label = MANAGE_RECIPIENTS_SORT_BY.find((item) => item.value === `${sortBy},${sortDir}`).label;
    }
    return `${prefix}${label}`;
  }, [search]);

  const handleResetFilter = () => {
    setResetKey((prev) => prev + 1);
    history.push(pathname);
  };

  const searchWithParams = (params: { key: string; value: string }[]) => {
    const urlSearchParams = new URLSearchParams(search);
    urlSearchParams.delete("id");
    params.forEach((param) => {
      if (param.value) {
        urlSearchParams.set(param.key, param.value);
      } else {
        urlSearchParams.delete(param.key);
      }
    });
    const searchParams = urlSearchParams.toString() ? `?${urlSearchParams.toString()}` : "";
    history.push({
      pathname,
      search: searchParams,
    });
  };

  const handleChangeSearch = (value: string) => {
    trackEvent("LP Recipient - Search", { input: value });
    searchWithParams([{ key: "keyword", value }]);
  };

  const handleChangeSort = (value: string) => {
    const [sortBy, sortDir] = value.split(",");
    searchWithParams([
      { key: "sortBy", value: sortBy },
      { key: "sortDir", value: sortDir },
    ]);
  };

  return (
    <Filter className={styles.filter}>
      <Search
        className={styles.searchFilter}
        placeholder="Search by Recipient Name"
        changeAction={handleChangeSearch}
        onChangeDelay={500}
        key={`search-${resetKey}`}
      />
      <SimpleFilter text={sortText} action={(value) => handleChangeSort(value[0])} list={MANAGE_RECIPIENTS_SORT_BY} />
      {hasFilter && (
        <div className={styles.resetFilter} onClick={handleResetFilter}>
          Clear all filters
        </div>
      )}
    </Filter>
  );
};

export default RecipientsFilter;
