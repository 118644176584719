import {
  KYX_FORMS_KEYS,
  ROLE_OF_INDIVIDUAL_OPTIONS,
  ROLE_OF_INDIVIDUAL_OPTIONS_VALUE,
} from "Views/KYX/Constants/Forms";
import { FORM_FIELD_TYPES, DOCUMENT_STEP_TYPE, FORM_SECTION_COMPONENT } from "Views/KYX/Constants";
import { IDocumentStep, IFormElement, IPersonForm } from "Views/KYX/@types";

export const usePersonnelInformation = (formData: IPersonForm, isFormDisabled: boolean): IDocumentStep => {
  const ROLE_OF_INDIVIDUAL: IFormElement = {
    key: KYX_FORMS_KEYS.PERSONNEL_DATA_PERSON + KYX_FORMS_KEYS.PERSON_DATA_ROLES,
    col: { span: 12, lg: 12, md: 16 },
    element: {
      type: FORM_FIELD_TYPES.CHECKLIST,
      props: {
        formProps: {
          name: [KYX_FORMS_KEYS.PERSONNEL_DATA_PERSON, KYX_FORMS_KEYS.PERSON_DATA_ROLES],
          label: "Role of Individual",
          required: false,
          initialValue:
            formData?.roles_of_individual &&
            Object.entries(formData?.roles_of_individual)
              ?.map(([key, value]) => {
                if (value) {
                  return key;
                } else {
                  return undefined;
                }
              })
              ?.filter((key) => key),
        },
        checkProps: {
          name: KYX_FORMS_KEYS.PERSON_DATA_ROLES,
          options: [
            {
              label: ROLE_OF_INDIVIDUAL_OPTIONS_VALUE[ROLE_OF_INDIVIDUAL_OPTIONS.AUTHORIZED_PERSON],
              value: KYX_FORMS_KEYS.PERSON_ROLES_AUTHORIZED,
            },
            {
              label: ROLE_OF_INDIVIDUAL_OPTIONS_VALUE[ROLE_OF_INDIVIDUAL_OPTIONS.DIRECTOR],
              value: KYX_FORMS_KEYS.PERSON_ROLES_DIRECTOR,
            },
            {
              label: ROLE_OF_INDIVIDUAL_OPTIONS_VALUE[ROLE_OF_INDIVIDUAL_OPTIONS.SHAREHOLDER],
              value: KYX_FORMS_KEYS.PERSON_ROLES_SHAREHOLDER,
            },
          ],
          disabled: isFormDisabled,
        },
      },
    },
  };

  const FULL_NAME: IFormElement = {
    key: KYX_FORMS_KEYS.PERSONNEL_DATA_PERSON + KYX_FORMS_KEYS.PERSON_DATA_FULL_NAME,
    col: { span: 24 },
    element: {
      type: FORM_FIELD_TYPES.INPUT,
      props: {
        formProps: {
          name: [KYX_FORMS_KEYS.PERSONNEL_DATA_PERSON, KYX_FORMS_KEYS.PERSON_DATA_FULL_NAME],
          label: "Full Name",
          required: false,
          initialValue: formData?.full_name,
        },
        inputProps: {
          name: KYX_FORMS_KEYS.PERSON_DATA_FULL_NAME,
          placeholder: "Please enter your full name",
          disabled: isFormDisabled,
        },
      },
    },
  };

  const OWNERSHIP_CATEGORY: IFormElement = {
    key: KYX_FORMS_KEYS.PERSONNEL_DATA_PERSON + KYX_FORMS_KEYS.PERSON_DATA_OWNERSHIP_CATEGORY,
    col: { span: 24 },
    element: {
      type: FORM_FIELD_TYPES.INPUT,
      props: {
        formProps: {
          name: [KYX_FORMS_KEYS.PERSONNEL_DATA_PERSON, KYX_FORMS_KEYS.PERSON_DATA_OWNERSHIP_CATEGORY],
          label: "Ownership Category",
          required: false,
          initialValue: formData?.[KYX_FORMS_KEYS.PERSON_DATA_OWNERSHIP_CATEGORY],
        },
        inputProps: {
          name: KYX_FORMS_KEYS.PERSON_DATA_OWNERSHIP_CATEGORY,
          placeholder: "Please enter ownership category",
          disabled: isFormDisabled,
        },
      },
    },
  };

  const SHARE_TYPE: IFormElement = {
    key: KYX_FORMS_KEYS.PERSONNEL_DATA_PERSON + KYX_FORMS_KEYS.PERSON_DATA_SHARE_TYPE,
    col: { span: 12, md: 12, xs: 24 },
    element: {
      type: FORM_FIELD_TYPES.INPUT,
      props: {
        formProps: {
          name: [KYX_FORMS_KEYS.PERSONNEL_DATA_PERSON, KYX_FORMS_KEYS.PERSON_DATA_SHARE_TYPE],
          label: "Share Type",
          required: false,
          initialValue: formData?.[KYX_FORMS_KEYS.PERSON_DATA_SHARE_TYPE],
        },
        inputProps: {
          name: KYX_FORMS_KEYS.PERSON_DATA_SHARE_TYPE,
          placeholder: "Please select share type",
          disabled: isFormDisabled,
        },
      },
    },
  };

  const SHARE_ALLOCATION: IFormElement = {
    key: KYX_FORMS_KEYS.PERSONNEL_DATA_PERSON + KYX_FORMS_KEYS.PERSON_DATA_SHARE_ALLOCATION,
    col: { span: 12, md: 12, xs: 24 },
    element: {
      type: FORM_FIELD_TYPES.INPUT,
      props: {
        formProps: {
          name: [KYX_FORMS_KEYS.PERSONNEL_DATA_PERSON, KYX_FORMS_KEYS.PERSON_DATA_SHARE_ALLOCATION],
          label: "Share Allocation",
          required: false,
          initialValue: formData?.[KYX_FORMS_KEYS.PERSON_DATA_SHARE_ALLOCATION],
        },
        inputProps: {
          name: KYX_FORMS_KEYS.PERSON_DATA_SHARE_ALLOCATION,
          placeholder: "Please select share allocation",
          disabled: isFormDisabled,
        },
      },
    },
  };

  const POSITION: IFormElement = {
    key: KYX_FORMS_KEYS.PERSONNEL_DATA_PERSON + KYX_FORMS_KEYS.PERSON_DATA_POSITION,
    col: { span: 24 },
    element: {
      type: FORM_FIELD_TYPES.INPUT,
      props: {
        formProps: {
          name: [KYX_FORMS_KEYS.PERSONNEL_DATA_PERSON, KYX_FORMS_KEYS.PERSON_DATA_POSITION],
          label: "Position",
          required: false,
          initialValue: formData?.[KYX_FORMS_KEYS.PERSON_DATA_POSITION],
        },
        inputProps: {
          name: KYX_FORMS_KEYS.PERSON_DATA_POSITION,
          placeholder: "Please enter position",
          disabled: isFormDisabled,
        },
      },
    },
  };

  const EMAIL_ADDRESS: IFormElement = {
    key: KYX_FORMS_KEYS.PERSONNEL_DATA_PERSON + KYX_FORMS_KEYS.PERSON_DATA_EMAIL,
    col: { span: 12, md: 12, xs: 24 },
    element: {
      type: FORM_FIELD_TYPES.INPUT,
      props: {
        formProps: {
          name: [KYX_FORMS_KEYS.PERSONNEL_DATA_PERSON, KYX_FORMS_KEYS.PERSON_DATA_EMAIL],
          label: "Email Address",
          required: false,
          initialValue: formData?.[KYX_FORMS_KEYS.PERSON_DATA_EMAIL],
        },
        inputProps: {
          name: KYX_FORMS_KEYS.PERSON_DATA_EMAIL,
          placeholder: "Please enter your email",
        },
      },
    },
  };

  const MOBILE_NUMBER: IFormElement = {
    key: KYX_FORMS_KEYS.PERSONNEL_DATA_PERSON + KYX_FORMS_KEYS.PERSON_DATA_MOBILE,
    col: { span: 12, md: 12, xs: 24 },
    element: {
      type: FORM_FIELD_TYPES.INPUT,
      props: {
        formProps: {
          name: [KYX_FORMS_KEYS.PERSONNEL_DATA_PERSON, KYX_FORMS_KEYS.PERSON_DATA_MOBILE],
          label: "Mobile Number",
          required: false,
          initialValue: formData?.[KYX_FORMS_KEYS.PERSON_DATA_MOBILE],
        },
        inputProps: {
          name: KYX_FORMS_KEYS.PERSON_DATA_MOBILE,
          placeholder: "Please enter your mobile number",
        },
      },
    },
  };

  const NATIONALITY: IFormElement = {
    key: KYX_FORMS_KEYS.PERSONNEL_DATA_PERSON + KYX_FORMS_KEYS.PERSON_DATA_NATIONALITY,
    col: { span: 24 },
    element: {
      type: FORM_FIELD_TYPES.COUNTRYSELECT,
      props: {
        formProps: {
          name: [KYX_FORMS_KEYS.PERSONNEL_DATA_PERSON, KYX_FORMS_KEYS.PERSON_DATA_NATIONALITY],
          label: "Nationality/Citizenship",
          required: false,
          initialValue: formData?.[KYX_FORMS_KEYS.PERSON_DATA_NATIONALITY],
        },
        selectProps: {
          placeholder: "Select nationality",
          isnationality: true,
          disabled: isFormDisabled,
        },
      },
    },
  };

  return {
    key: KYX_FORMS_KEYS.PERSONNEL_DATA_PERSON,
    type: DOCUMENT_STEP_TYPE.DEFAULT,
    row: { gutter: 40 },
    container: {
      type: FORM_SECTION_COMPONENT.CARD,
      props: {
        title: "Personnel Information",
      },
    },
    document: [
      ROLE_OF_INDIVIDUAL,
      FULL_NAME,
      OWNERSHIP_CATEGORY,
      SHARE_TYPE,
      SHARE_ALLOCATION,
      POSITION,
      EMAIL_ADDRESS,
      MOBILE_NUMBER,
      NATIONALITY,
    ],
  };
};
