import APIclient from "API/Client";
import { GetBaseAuthObject } from "utility";

export const GET_SCHEDULED_TRANSACTIONS_REQUEST = "GET_SCHEDULED_TRANSACTIONS_REQUEST";
export const GET_SCHEDULED_TRANSACTIONS_SUCCESS = "GET_SCHEDULED_TRANSACTIONS_SUCCESS";
export const GET_SCHEDULED_TRANSACTIONS_FAILURE = "GET_SCHEDULED_TRANSACTIONS_FAILURE";
export const CLEAR_GET_SCHEDULED_TRANSACTIONS = "CLEAR_GET_SCHEDULED_TRANSACTIONS";

const getScheduledTransactionsReq = (data) => {
  return {
    type: GET_SCHEDULED_TRANSACTIONS_REQUEST,
    data,
  };
};
const getScheduledTransactionsSuccess = (data) => {
  return {
    type: GET_SCHEDULED_TRANSACTIONS_SUCCESS,
    data,
  };
};
const getScheduledTransactionsError = (data) => {
  return {
    type: GET_SCHEDULED_TRANSACTIONS_FAILURE,
    data,
  };
};

export const clearGetScheduledTransactions = (data) => {
  return {
    type: CLEAR_GET_SCHEDULED_TRANSACTIONS,
  };
};

export const getScheduledTransactionsFunc = ({ page, limit }) => {
  const orgId = GetBaseAuthObject().orgId;
  return (dispatch) => {
    dispatch(getScheduledTransactionsReq());

    APIclient.getData(`/api/v1/fund/scheduled?pg=${page}&limit=${limit}&organisation_id=${orgId}`)
      .then((data) => {
        dispatch(getScheduledTransactionsSuccess(data.data));
      })
      .catch((err) => {
        const errorMessage = err?.response?.data?.error?.message || err;
        dispatch(getScheduledTransactionsError(errorMessage));
      });
  };
};
