import { GetCategoriesWithAxios, MissingCategory } from "Redux/DataCalls/Categories.api";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";

export const FETCH_CATEGORY_LIST_REQUEST = "FETCH_CATEGORY_LIST_REQUEST";
export const FETCH_CATEGORY_LIST_SUCCESS = "FETCH_CATEGORY_LIST_SUCCESS";
export const FETCH_CATEGORY_MISSING_LIST_SUCCESS = "FETCH_CATEGORY_MISSING_LIST_SUCCESS";
export const FETCH_CATEGORY_LIST_FAILURE = "FETCH_CATEGORY_LIST_FAILURE";

export const fetchCategoryListReq = () => {
  return {
    type: FETCH_CATEGORY_LIST_REQUEST,
  };
};

export const fetchCategoryListSuccess = (data) => {
  return {
    type: FETCH_CATEGORY_LIST_SUCCESS,
    payload: data,
  };
};

export const fetchMissingCategoryListSuccess = (data) => {
  return {
    type: FETCH_CATEGORY_MISSING_LIST_SUCCESS,
    payload: data,
  };
};

export const fetchCategoryListError = (data) => {
  return {
    type: FETCH_CATEGORY_LIST_FAILURE,
    data: data,
  };
};

const injectImportedCategory = (data, importedCategories) => {
  return data.map((category) => {
    if (importedCategories.find((importedCategory) => importedCategory.account_name === category.categoryName)) {
      return {
        ...category,
        isNew: true,
      };
    }

    return category;
  });
};

const modifyCategoryData = (data) => {
  const { accounts, total } = data?.payload || {};
  return {
    categories:
      accounts?.map((account) => ({
        account_name: account?.name,
        account_number: account?.number,
      })) ?? [],
    count: total ?? 0,
  };
};

export const fetchCategories = (connectedToAccountingPartner, importedCategories = []) => {
  return async (dispatch) => {
    dispatch(fetchCategoryListReq());
    let missingCategory = null;
    if (connectedToAccountingPartner) {
      const { data } = await MissingCategory();

      missingCategory = modifyCategoryData(data);
    }

    GetCategoriesWithAxios()
      .then(({ data }) => {
        if (data.status === HTTP_STATUS_CODE.OK) {
          dispatch(
            fetchCategoryListSuccess(injectImportedCategory(data?.payload?.categories || [], importedCategories))
          );
          dispatch(fetchMissingCategoryListSuccess(missingCategory || []));
        } else {
          return Promise.reject(data.payload);
        }
      })
      .catch((err) => {
        dispatch(fetchCategoryListError(err));
      });
  };
};
