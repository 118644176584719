import * as React from "react";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import Toaster from "Modules/DS/Toaster";
import { TOASTER_SIZE_TYPE, TOASTER_STATUS_TYPE } from "Modules/DS/Toaster/types.d";

import { initalPopupState } from "Views/Approval/ApprovalCenter/types";
import { selectApproveApiCallState } from "Redux/Reducers/Approval/ApprovalCenter/approvalCenter.slice";
import { ApiCallState } from "Models/Network/models";

const Toast = () => {
  const [toaster, setToaster] = useState({ ...initalPopupState });

  const clearToaster = () => setToaster({ ...initalPopupState });

  const toggleToaster = (error: boolean, value: string): void =>
    setToaster({ error: error, visible: true, message: value });
  const approveApiCallState: ApiCallState = useSelector(selectApproveApiCallState);

  useEffect(() => {
    if (approveApiCallState && approveApiCallState.message) {
      toggleToaster(approveApiCallState.error, approveApiCallState.message);
    }
  }, [approveApiCallState]);

  return (
    <Toaster
      size={TOASTER_SIZE_TYPE.S}
      status={toaster.error ? TOASTER_STATUS_TYPE.ERROR : TOASTER_STATUS_TYPE.SUCCESS}
      visible={toaster.visible}
      message={toaster.message}
      onClose={() => clearToaster()}
    />
  );
};

export default Toast;
