import * as React from "react";
import { emptyCardStateIcon, plusRedIcon } from "assets/img";
import SectionEmptyState from "Modules/sectionEmptyState";
import { COMPANY_ROLES, TEAM_ROLES } from "constants/QuickAccess.constant";
import { checkAccess, checkMembership } from "utility/permissions/permission";
import { getTeamRole } from "utility";
import { Roles, teamData } from "./types";
import Icon from "Modules/icons";
import styles from "./index.module.scss";
import { CARD_TYPE } from "Views/Card/types";

export const getCardEmptyState = (message: string) => {
  return (
    <SectionEmptyState
      className={styles["card-empty-state"]}
      iconSrc={emptyCardStateIcon}
      iconAlt="empty card icon"
      message={message}
    />
  );
};

export const cardCTABtn = (title) => {
  return (
    <div className={styles["add-card-btn"]}>
      <Icon className={styles.icon} src={plusRedIcon} alt="plus" />
      {title}
    </div>
  );
};

export const GetRoleDetails = (teamData: teamData, userInfo: any) => {
  const teamRole = getTeamRole(teamData?.team_members, userInfo?.id, teamData?.your_membership);

  return {
    companyRole: teamData?.company_role,
    isManager: userInfo?.manage_teams?.length > 0,
    isMember: checkMembership([TEAM_ROLES.MEMBER, TEAM_ROLES.NON_MEMBER], teamRole),
  };
};

export const ConfigCardsEmptyState = (roleDetails: Roles) => {
  const { companyRole, isManager, isMember } = roleDetails;

  switch (true) {
    case checkAccess([COMPANY_ROLES.EMPLOYEE, COMPANY_ROLES.ACCOUNTANT, COMPANY_ROLES.BOOKKEEPER], companyRole) &&
      isMember:
      return getCardEmptyState("Get virtual or physical cards by requesting them to your budget owner.");

    case checkAccess([COMPANY_ROLES.ADMIN], companyRole) ||
      (checkAccess([COMPANY_ROLES.EMPLOYEE], companyRole) && isManager):
      return getCardEmptyState(
        "Add virtual cards or request physical cards now to manage all your expenses seamlessly."
      );
    case checkAccess([COMPANY_ROLES.AUDITOR], companyRole):
      return getCardEmptyState("No available cards to view.");

    default:
      return getCardEmptyState(
        "Add your virtual cards or request physical cards to manage all your expenses in a seamless way"
      );
  }
};

export const getCardType = (cardType: string) => {
  const VIRTUAL_TYPE_CARD = "0";
  if (cardType === VIRTUAL_TYPE_CARD) return CARD_TYPE.VIRTUAL;
  return CARD_TYPE.PHYSICAL;
};
