import React, { useState } from "react";
import classNames from "classnames";
import { pdfjs, Document, Page } from "react-pdf";

import { ModalComponent } from "Modules/DS/Modal";

import { ALLOWED_FILE_EXTENSIONS } from "Views/UploadInvoice/const";

import { fileIcon } from "assets/img";
import styles from "./attachment.module.scss";
import "./preview.scss";
import { isImage } from "utility";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export const AttachmentPreview = ({ url }: { url: string }) => {
  // Hiding this part as we currently don't have the BE support to delete/change files
  //
  //
  // const onDeleteHandler = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
  //   console.log("delete attachment");
  // };
  //
  // const onChangeHandler = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
  //   console.log("change attachment");
  // };
  //
  // const renderDeleteButton = () => {
  //   return <button className={styles.deleteIcon} onClick={onDeleteHandler} />;
  // };
  //
  // const renderChangeFileButton = () => {
  //   return (
  //     <button className={styles.change} onClick={onChangeHandler}>
  //       Change File
  //     </button>
  //   );
  // };
  //
  // const renderHeaderActions = () => {
  //   return (
  //     <div className={styles.receiptHeaderSecondary}>
  //       {renderChangeFileButton()}
  //       <div className={styles.separator} />
  //       {renderDeleteButton()}
  //     </div>
  //   );
  // };

  const renderHeader = () => {
    return (
      <div className={styles.receiptHeader}>
        {/* // Hiding this part as we currently don't have the BE support to delete/change files */}
        {/* {renderHeaderActions()} */}
      </div>
    );
  };

  const [pageCount, setPageCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);

  const getWidth =
    document.querySelector(`.${styles.receiptBody}.${styles.pdfReceipt}`)?.getBoundingClientRect()?.width || 0;

  const handlePdfFileClick = (event: React.MouseEvent<Element, MouseEvent>) => {
    event.preventDefault();
    if (url) {
      window.open(`${url}#page=${currentPage}`, "_blank");
    }
  };

  const renderPdfBody = () => {
    return (
      <>
        <div className={classNames(styles.receiptBody, styles.pdfReceipt)}>
          <Document
            file={url}
            externalLinkTarget="_blank"
            externalLinkRel="noopener noreferrer"
            imageResourcesPath={fileIcon}
            loading={<ModalComponent.Loader />}
            noData={renderBrokenPreview()}
            error={renderBrokenPreview()}
            onLoadSuccess={(pdf) => setPageCount(pdf._pdfInfo.numPages)}
          >
            <div className={styles.docWrapper}>
              <Page
                width={getWidth}
                renderMode="canvas"
                pageNumber={currentPage + 1}
                onClick={handlePdfFileClick}
                loading={<ModalComponent.Loader />}
              />
            </div>
          </Document>
        </div>
        <div className={styles.receiptFooter}>
          <button
            className={classNames(styles.pdfPrev, { [styles.pdfPrevDisabled]: currentPage < 1 })}
            onClick={(e) => {
              e.stopPropagation();
              if (currentPage < 1) return;
              setCurrentPage(currentPage - 1);
            }}
          />
          <span>
            Page {currentPage + 1} of {pageCount === 0 ? pageCount + 1 : pageCount}
          </span>
          <button
            className={classNames(styles.pdfNext, {
              [styles.pdfNextDisabled]: currentPage === (pageCount === 0 ? pageCount : pageCount - 1),
            })}
            onClick={(e) => {
              e.stopPropagation();
              if (currentPage === (pageCount === 0 ? pageCount : pageCount - 1)) return;
              setCurrentPage(currentPage + 1);
            }}
          />
        </div>
      </>
    );
  };

  const handleImageClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.preventDefault();
    const url = event.currentTarget.dataset.url;
    if (url) {
      window.open(url, "_blank");
    }
  };

  const renderImageBody = () => {
    return (
      <div className={classNames(styles.receiptBody, styles.imageReceipt)} onClick={handleImageClick} data-url={url}>
        <a href={url} target="_blank" rel="noopener noreferrer">
          <img src={url} alt={`receipt ${url}`} data-url={url} />
        </a>
      </div>
    );
  };

  const renderBrokenPreview = () => {
    return (
      <div className={classNames(styles.receiptBody, styles.brokenReceipt)} onClick={handleImageClick} data-url={url}>
        <a href={url} target="_blank" rel="noopener noreferrer">
          <img src={fileIcon} data-url={url} alt={`receipt ${url}`} className={styles.pdfIcon} />
          <p>Failed to load PDF file.</p>
        </a>
      </div>
    );
  };

  let child: JSX.Element = null;

  if (url.endsWith(".pdf") || url.indexOf(".pdf") > 0) {
    child = renderPdfBody();
  } else if (ALLOWED_FILE_EXTENSIONS.some((item: string) => url.endsWith(item)) || isImage(url)) {
    child = renderImageBody();
  } else {
    child = renderBrokenPreview();
  }

  return (
    <div key={url + url.length} className={styles.receipt}>
      {renderHeader()}
      {child}
    </div>
  );
};
