import { Rule, RuleObject } from "antd/lib/form";
import { StoreValue } from "antd/lib/form/interface";
import { DynamicFormField } from "Modules/DynamicForm/types";

export const rules = (
  fieldProps: DynamicFormField["fieldProps"],
  validator?: (rule: RuleObject, value: StoreValue) => Promise<void>
): Rule[] => {
  const rules: Rule[] = [
    {
      required: !fieldProps.inBSS.optional && fieldProps.required,
      message: "This field cannot be empty",
    },
    {
      pattern: new RegExp(fieldProps.regex),
      message: fieldProps.regexErrorMessage,
    },
  ];

  if (validator) {
    rules.push({ validator });
  }

  return rules;
};
