import * as React from "react";
import { Typography } from "antd";
import { getIsCardVendorNium } from "customHooks/Card";
import Drawer from "Views/Card/Components/Drawer";
import CardInfo from "Views/Card/Pin/CardInfo";
import CardPinInput from "Views/Card/Pin/Input";
import CardPinActionBtn from "Views/Card/Pin/ActionBtn";
import { backArrowGrey40x40 } from "assets/icons/card";

import styles from "Views/Card/Pin/index.module.scss";
import { ICardDetails } from "Views/Card/types";

const { Text } = Typography;

interface ICardPinProps {
  cardDetails: ICardDetails;
  onClose: () => void;
  onUpdate: () => void;
}

const CardPin = ({ onUpdate, onClose, cardDetails }: ICardPinProps) => {
  const { cardLastFourDigits, cardTitle, id, cardColor, isCardPinSet } = cardDetails;
  const isCardVendorNium = getIsCardVendorNium();
  const SET_PIN_LENGTH = isCardVendorNium ? 6 : 4;
  const [pin, setPin] = React.useState("");
  const [confirmPin, setConfirmPin] = React.useState("");
  const checkForError = () => {
    return pin?.length === SET_PIN_LENGTH && confirmPin?.length >= 1 && pin !== confirmPin;
  };
  const disableBtn = () => {
    return pin?.length !== SET_PIN_LENGTH || confirmPin?.length !== SET_PIN_LENGTH || pin !== confirmPin;
  };
  return (
    <Drawer title={isCardPinSet ? "Change PIN" : "Set PIN"} onClose={onClose}>
      <img
        src={backArrowGrey40x40}
        className={styles["back-arrow"]}
        width={40}
        height={40}
        onClick={onClose}
        data-testid="back-arrow-icon"
        alt="Back Arrow"
      />
      <div className={styles["card-pin__container"]}>
        <CardInfo cardLastFourDigits={cardLastFourDigits} cardTitle={cardTitle} cardColor={cardColor} />
        <div className={styles["card-pin__form-container"]}>
          <Text className={styles["form-info"]}>
            {isCardPinSet
              ? "Please enter a new PIN to enable offline transactions with this card."
              : "Enable offline transactions* by setting a 4-digit PIN for this card."}
          </Text>
          <CardPinInput
            cardPinLength={SET_PIN_LENGTH}
            inputLabel="Input PIN"
            onInputChange={setPin}
            hasError={false}
            errorMessage=""
            focusTheFirstInput={true}
          />
          <CardPinInput
            cardPinLength={SET_PIN_LENGTH}
            inputLabel="Confirm PIN"
            onInputChange={setConfirmPin}
            hasError={checkForError()}
            errorMessage={checkForError() ? "PIN does not match." : ""}
            focusTheFirstInput={false}
          />
          <Text className={styles["form-sub-info"]}>
            *ATM withdrawal is only available outside Singapore with the minimum amount subject to the banks.
          </Text>
        </div>
        <CardPinActionBtn
          pin={pin}
          cardId={id}
          isUpdatePin={isCardPinSet}
          disabled={disableBtn()}
          onUpdate={onUpdate}
        />
      </div>
    </Drawer>
  );
};

export default CardPin;
