import * as React from "react";
import { useContext } from "react";

import ContactForm from "Views/SpenmoSupport/ContactForm";
import { EmployeesCSContext, IEmployeesCSContext } from "Views/Employee/context";

const CustomerSupport = () => {
  const { supportChat, toggleSupportChat }: IEmployeesCSContext = useContext(EmployeesCSContext);
  const { visible, message } = supportChat;

  return visible && <ContactForm onClose={toggleSupportChat} automatedMsg={message} />;
};

export default CustomerSupport;
