import React, { MouseEvent, ReactNode } from "react";

export enum ESidePanelButtonType {
  PRIMARY = "primary",
  PRIMARY_ENABLE = "primary_enable",
  SECONDARY = "secondary",
  DESTRUCTIVE = "destructive",
  TERTIARY = "tertiary",
  ADDITIONAL_INFORMATION = "additional_information",
  CONSTRUCTIVE = "constructive",
  CONSTRUCTIVE_ENABLE = "constructive_enable",
  BORDERED_DESTRUCTIVE = "bordered_destructive",
  TEXT = "text",
  INTERACTIVE_PRIMARY = "interactive_primary",
}

export interface ISidePanelProps {
  visible: boolean;
  withOverlay?: boolean;
  title: React.ReactNode | string;
  loading?: boolean;
  useLongHeight?: boolean;
  onClose: (e?: MouseEvent<HTMLElement>) => void;
  buttons?: ISidePanelButton[];
  sticky?: boolean;
  children: React.ReactNode;
  onBack?: () => void;
  onEdit?: () => void;
  stickyContent?: React.ReactNode;
  className?: string;
  sidePanelClassName?: string;
}

export interface IAdditionalInfo {
  icon?: React.ReactNode;
  content: string | React.ReactNode;
}

export interface ISidePanelButton {
  id: string;
  type: ESidePanelButtonType;
  text: string | ReactNode;
  warningHidden?: boolean;
  warningText?: string;
  action: () => void | Promise<void>;
  loading?: boolean;
  disabled?: boolean;
  shouldUseColSpan?: boolean;
  additionalInfo?: IAdditionalInfo;
  enableConfirmCallback?: () => void;
  disabledConfirmCallback?: () => void;
  hiddenButtonBeforeConfirm?: boolean;
  buttonClassName?: string;
}

export interface IStickyProps {
  buttons: ISidePanelButton[];
  useLongHeight?: Boolean;
  stickyContent?: React.ReactNode;
  loading?: boolean;
}

export interface IHeaderProps {
  title: string | React.ReactNode;
  onClose: (e: MouseEvent<HTMLElement>) => void;
  onBack?: () => void;
  onEdit?: () => void;
}
