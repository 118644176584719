import React, { useEffect, useState } from "react";
import { useQuery } from "utility";
import ChangePasswordForm from "./Form";
import AuthPage from "Views/Auth";
import Icon from "Modules/icons";
import { successIcon } from "assets/img";
import { useDispatch, useSelector } from "react-redux";
import { getCookieValue, cookieNames, removeCookie } from "utility/CookieHelper";
import {
  resetVerifyForgotData,
  forceChangePassword,
  VerifyForgotPassword,
} from "Redux/Actions/Auth/VerifyForgotPassword";
import forgotPasswordCookiesReset from "Views/Auth//ForgotPasswordOTP/utility";
import {
  AUTH_EXPIRED_TOKEN_CODE,
  EXPIRED_TOKEN_CODE,
  FORCE_PASSWORD_EXPIRED_TOKEN_CODE,
  INVALID_REFRESH_TOKEN,
} from "./types";
import ResetThankYou from "../Forgot/Components/ResetThankYou";
import { Link } from "react-router-dom";
import { isForceResetNeeded, forceResetToken, forceResetSuccess, forceResetError } from "utility/ForceResetHelper";
import { LOGIN_PATH } from "constants/Routes.constants";
import { ERROR_STATUS_CODE } from "constants/ErrorStatusCode.constants";
import { AuthContentHeader } from "../Components/AuthContentHeader";
import { AuthPageContextProps } from "../context";
import { AuthBoxError } from "../Components";
import CustomSessionTimeoutModal from "Modules/CustomSessionTimeoutModal";
import { Base64 } from "js-base64";

const ForgotChangePasswordSuccess = () => {
  return (
    <>
      <AuthContentHeader title="Password Successfully Reset" />
      <Icon className={"thankyou-icon"} src={successIcon} alt={"success icon"} />
      <p className="auth-title-description">You can log in to Spenmo using your new password</p>
    </>
  );
};

const ChangePassword = (): React.ReactElement => {
  let query = useQuery();
  const dispatch = useDispatch();
  const token = query.get("t");
  const isChangePasswordSuccess = getCookieValue(cookieNames.CHANGE_PASSWORD_SUCCESS);
  const { error, data, isSuccess } = useSelector((state: any) => state.verifyForgotPassword);
  const isForceReset = isForceResetNeeded();
  const isForgotPassword = getCookieValue(cookieNames.IS_FORGOT_PASSWORD);

  const [showExpiredModel, setShowExpiredModel] = useState(false);

  useEffect((): void => {
    error && dispatch(resetVerifyForgotData());
  }, [dispatch]);
  useEffect(() => {
    if (isForceReset) {
      forgotPasswordCookiesReset();
    }
  }, []);
  const isForceSessionExpiredError =
    [
      FORCE_PASSWORD_EXPIRED_TOKEN_CODE,
      ERROR_STATUS_CODE.INVALID_ACCESS_TOKEN,
      ERROR_STATUS_CODE.INVALID_REFRESH_TOKEN,
    ].includes(data?.[0]?.code) && forceResetError();
  let context: AuthPageContextProps = {
    error: error && !isSuccess && !isForceSessionExpiredError,
    loading: false,
    errorMessage: error && (data?.[0]?.message || data),
    appendClassName: "change-password-wrapper",
    showTnC: false,
  };
  const params = new URLSearchParams(window.location.search);
  let redirectTo = `${params.get("redirect")}`;
  params.delete("redirect");
  redirectTo = `${redirectTo}${params.toString() ? "?" + params.toString() : ""}`;

  const redirectComponent = (): React.ReactElement => {
    return isChangePasswordSuccess && isForgotPassword ? (
      <Link id="goLogin" to={LOGIN_PATH} replace>
        Back to Login
      </Link>
    ) : null;
  };

  const onFinish = (values): void => {
    const new_password = Base64.encode(values?.new_password);
    dispatch(resetVerifyForgotData());
    if (isForceReset && isForceReset !== "undefined") {
      dispatch(forceChangePassword({ new_password, force_reset_token: forceResetToken() || token }));
    } else {
      dispatch(VerifyForgotPassword(new_password, forceResetToken() || token));
    }
  };

  const getComponent = () => {
    if (
      error &&
      (data?.[0]?.code === EXPIRED_TOKEN_CODE ||
        data?.[0]?.code === INVALID_REFRESH_TOKEN ||
        data?.[0]?.code === AUTH_EXPIRED_TOKEN_CODE) &&
      !showExpiredModel
    ) {
      removeCookie(cookieNames.AUTH_TOKEN);
      removeCookie(cookieNames.IS_FORCE_RESET_NEEDED);
      setShowExpiredModel(true);
    }
    if (isSuccess || isChangePasswordSuccess) {
      if (forceResetSuccess() == "true") {
        return <ResetThankYou redirectionPath={redirectTo || "/"} />;
      } else {
        return <ForgotChangePasswordSuccess />;
      }
    }

    return (
      <>
        {isForceReset && isForceReset !== "undefined" ? (
          <AuthContentHeader
            title="Update Your Password"
            description={"To securely start using Spenmo, please update your password"}
          />
        ) : (
          <AuthContentHeader title="Set New Password" />
        )}
        <AuthBoxError />
        <ChangePasswordForm token={forceResetToken() || token} onFinish={onFinish} />
      </>
    );
  };

  return (
    <>
      <AuthPage value={context} redirectComponent={redirectComponent()}>
        <>
          {showExpiredModel && (
            <CustomSessionTimeoutModal
              errorMessage={"Your session is expired. Please log in to continue."}
              buttonText={"Login"}
            />
          )}
          {getComponent()}
        </>
      </AuthPage>
    </>
  );
};

export default ChangePassword;
