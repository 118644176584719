import { ALIGN_ACTION_ITEM, IActionItem } from "Modules/DS/DataTable";
import { AllTrxnFilters } from "./Filter";

const Actions = (): IActionItem[] => {
  return [
    {
      id: "ALL-TRXN-ACTION-1",
      enabled: true,
      align: ALIGN_ACTION_ITEM.LEFT,
      component: AllTrxnFilters,
    },
  ].filter((item) => item.enabled);
};

export default Actions;
