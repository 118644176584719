import React from "react";

import { KYXSelect } from "Views/KYX/Elements/Select";
import { KYXChecklist } from "Views/KYX/Elements/CheckList";
import { KYXRadioGroup } from "Views/KYX/Elements/RadioGroup";
import DualUploader from "Views/KYX/Elements/DualUpload";
import FileUploader from "Views/KYX/Elements/FileUpload";
import { KYXCountrySelect } from "Views/KYX/Elements/CountrySelect";
import { KYXDatePicker } from "Views/KYX/Elements/DatePicker/index";

import { TKYXElement } from "Views/KYX/@types";
import { FORM_FIELD_TYPES } from "Views/KYX/Constants";

import { KYXInput } from "./Input";
import { KYXBanner } from "./Banner";
import { KYXFormHeader } from "./Header";
import { KYXIconButton } from "./IconButton";
import { KYXVerifySingpass } from "./Singpass";
import { KYXDownloadLink } from "./DownloadLink";

export const KYXElements = ({ type, props }: TKYXElement): JSX.Element => {
  switch (type) {
    case FORM_FIELD_TYPES.INPUT:
      return <KYXInput {...props} />;

    case FORM_FIELD_TYPES.SELECT:
      return <KYXSelect {...props} />;

    case FORM_FIELD_TYPES.CHECKLIST:
      return <KYXChecklist {...props} />;

    case FORM_FIELD_TYPES.RADIO:
      return <KYXRadioGroup {...props} />;

    case FORM_FIELD_TYPES.BANNER:
      return <KYXBanner {...props} />;

    case FORM_FIELD_TYPES.FILEUPLOAD:
      return <FileUploader {...props} />;

    case FORM_FIELD_TYPES.DUALUPLOAD:
      return <DualUploader {...props} />;

    case FORM_FIELD_TYPES.COUNTRYSELECT:
      return <KYXCountrySelect {...props} />;

    case FORM_FIELD_TYPES.DATEPICKER:
      return <KYXDatePicker {...props} />;

    case FORM_FIELD_TYPES.HEADER:
      return <KYXFormHeader {...props} />;

    case FORM_FIELD_TYPES.ICONBUTTON:
      return <KYXIconButton {...props} />;

    case FORM_FIELD_TYPES.SINGPASS:
      return <KYXVerifySingpass {...props} />;

    case FORM_FIELD_TYPES.DOWNLOAD_LINK:
      return <KYXDownloadLink {...props} />;

    default:
      return null;
  }
};
