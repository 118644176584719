import React, { useEffect, useState } from "react";
import Icon from "Modules/icons";
import PropTypes from "prop-types";
import { carouselRightIcon, carouselLeftIcon } from "assets/img";
import "./PDFViewer.scss";

import { pdfjs, Document, Page } from "react-pdf";
import classNames from "classnames";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const PDFViewer = ({ file, className, showArrows = true }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setPageNumber(1);
  };

  useEffect(() => {
    file && onDocumentLoadSuccess(1);
  }, []);

  const changePage = (offset) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };

  const previousPage = (e) => {
    e.preventDefault();
    e.stopPropagation();

    pageNumber > 1 && changePage(-1);
  };

  const nextPage = (e) => {
    e.preventDefault();
    e.stopPropagation();
    pageNumber <= numPages && changePage(1);
  };

  const showNavIcons = numPages > 1;

  return (
    <div className={"pdf-viewer"}>
      {showArrows && showNavIcons && pageNumber !== 1 && (
        <Icon
          className={"pdf-viewer__left-arrow"}
          src={carouselLeftIcon}
          action={previousPage}
        />
      )}
      <Document
        className={classNames({ [className]: className })}
        file={file}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        <Page pageNumber={pageNumber} />
      </Document>
      {showArrows && showNavIcons && pageNumber !== numPages && (
        <Icon
          className={"pdf-viewer__right-arrow"}
          src={carouselRightIcon}
          action={nextPage}
        />
      )}
    </div>
  );
};

PDFViewer.propTypes = {
  file: PropTypes.any,
  className: PropTypes.string,
  showArrows: PropTypes.bool,
};

export default PDFViewer;
