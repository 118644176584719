import { Cookies } from "react-cookie";

export const cookies = new Cookies();

// set cookie secure only for production env
const IS_PRODUCTION_ENV = process.env.NODE_ENV === "production";

export const getCookieValue = (cookieName) =>
  (cookieName && cookies?.get(cookieName)) || "";

export const setCookie = (cookieName, cookieValue, options = { path: "/" }) => {
  const defaultOptions = {
    path: "/",
    secure: IS_PRODUCTION_ENV,
  };
  const finalOptions = { ...defaultOptions, ...options };

  if (cookieName) {
    cookies?.set(cookieName, cookieValue, finalOptions);
  }
};

export const removeCookie = (cookieName) => {
  const defaultOptions = {
    path: "/",
    secure: IS_PRODUCTION_ENV,
  };
  cookies.remove(cookieName, defaultOptions);
};

export const cookieNames = {
  CURRENCY_CODE: "currency_code",
  USER_COMPANY_ROLE: "user_company_role",
  USER_CARD_VENDOR: "user_card_vendor",
  PHONE_NUMBER: "phone_number",
  PHONE_CODE: "phone_code",
  USER_EMAIL: "user_email",
  USER_MOBILE_NUMBER: "mobile_number",
  IS_FORGOT_PASSWORD: "is_forgot_password",
  CHANGE_PASSWORD_SUCCESS: "change_password_success",
  CHANGE_PASSWORD_MESSAGE: "change_password_message",
  REFRESH_TOKEN: "refresh_token",
  AUTH_TOKEN: "auth_token",
  EXPIRED: "expired",
  IS_USE_CREDIT: "is_use_credit",
  REAUTH: "reauth",
  IS_OTP_VERIFICATION_NEEDED: "is_otp_verification_needed",
  IS_ONBOARDING_FINISHED: "is_onboarding_finished",
  KYB_STATUS: "kyb_status",
  ORG_REGISTRATION_DATE: "org_registration_date",
  ONBOARDING_VERSION: "onboarding_version",
  // This cookie is used for user when access website through mobile
  CONTINUE_MOBILE_VIEW: "continue_mobile_view",
  ORG_COUNTRY_CODE: "org_country_code",

  // This cookie is used to hide edit bill banner in trx page
  // for the session after user clicks close button
  HIDE_EDIT_BILL_BANNER: "hide_edit_bill_banner",
  IS_FORCE_RESET_NEEDED: "is_force_reset_needed",
  FORCE_RESET_TOKEN: "force_reset_token",
  FORCE_RESET_SUCCESS: "force_reset_success",
  FORCE_RESET_ERROR: "force_reset_error",
  KYB_SOURCE: "kyb_source",

  // re-kyb cookies
  IS_REKYB_REQUIRED: "is_rekyb_required",
  SHOW_REKYB_MODAL: "show_rekyb_modal",
  // KYB On Demand cookie will be removed after release
  IS_KYB_ON_DEMAND: "is_kyb_on_demand",
};

export const reactiveCookie = () => {
  const cookies = new Cookies();

  return {
    getCookie(cookieName) {
      return cookies.get(cookieName);
    },
  };
};
