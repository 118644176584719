import React, { MouseEvent, useState } from "react";
import { pdfjs } from "react-pdf";
import { AxiosResponse } from "axios";
import classNames from "classnames";

import Icon from "Modules/icons";
import Modal from "Modules/modal";
import CarouselPreview, { IFile } from "Modules/DS/CarouselFiles";
import Loader from "Modules/DS/Loader";
import { useQuery } from "utility/useQuery";
import { receiptsIcon, receiptsGreyIcon } from "assets/img";

import { getBillAttachment, getBillAttachmentContent } from "./dataCall";

import styles from "./ImageCarousel.module.scss";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

interface IAsyncImageCarouselProps {
  className?: string;
  invoiceId: string;
  hasAttachment: boolean;
}

const AsyncImageCarousel: React.FC<IAsyncImageCarouselProps> = ({ className, invoiceId, hasAttachment }) => {
  const [showModal, setShowModal] = useState(false);
  const [images, setImages] = useState<IFile[]>([]);
  const [loading, setLoading] = useState(false);
  const { loading: dataloading } = useQuery({
    apiCall: () => getBillAttachment(invoiceId),
    run: showModal,
    onSuccess: (data) => fetchAttachments(data),
  });

  const fetchAttachments = (data) => {
    setLoading(true);
    const promises = data.attachments.map(({ Url, MimeType }) => {
      return getBillAttachmentContent(Url, MimeType);
    });

    Promise.all(promises)
      .then((responses) => {
        const formatted: IFile[] = responses.map((response: AxiosResponse) => {
          const url = response.config.url;
          const attachmentType = url.includes("pdf") ? "pdf" : "image";
          const attachmentUrl = URL.createObjectURL(response.data);

          return {
            file: attachmentUrl,
            type: attachmentType,
          };
        });

        setImages(formatted);
      })
      .finally(() => setLoading(false));
  };

  const toggleModal = () => setShowModal(!showModal);

  const openModal = (e: MouseEvent) => {
    e.stopPropagation();
    toggleModal();
  };

  const InitialIcon = (
    <Icon
      src={hasAttachment ? receiptsIcon : receiptsGreyIcon}
      alt="receipt icon"
      action={openModal}
      className={classNames(styles.icon, className)}
    />
  );

  const isLoading = loading || dataloading;

  if (showModal) {
    return (
      <>
        {InitialIcon}
        <Modal show className={classNames(styles.asyncImageCarousel, styles.modalPreview)} close={toggleModal}>
          {isLoading && (
            <div className={styles.loadingWrapper} data-testid="loader">
              <Loader />
            </div>
          )}
          {!isLoading && images?.length > 0 && (
            <CarouselPreview customFiles={images} carouselClassname="async-carousel" />
          )}
        </Modal>
      </>
    );
  }

  return InitialIcon;
};

export default AsyncImageCarousel;
