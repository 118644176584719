import { COMPANY_VERIFICATION } from "Route/KYX/paths";
import { IRemarksInfo } from "Views/KYX//@types";

export enum REMARKS_TYPES {
  COMPANY_INFORMATION = "company_information",
  PERSONNEL_INFORMATION = "personnel_information",
  COMPANY_DOCUMENTS = "company_document",
  DUE_DILIGENCE_DOCUMENTS = "due_diligence_documents",
}

export enum REMARKS_SIDEBAR_TYPE {
  FORM = "form",
  FIELD = "field",
}

export enum REMARKS_VALIDATION_TYPES {
  SUCCESS = "success",
  ERROR = "error",
  WARNING = "warning",
  VALIDATING = "validating",
}

export const company_information = {
  title: "Section 1: Company Information",
  button: {
    text: "Go to  Section 1: Company Information form",
    link: COMPANY_VERIFICATION.COMPANY_INFORMATION,
  },
};

export const personnel_information = {
  title: "Section 2: Personnel Information",
  button: {
    text: "Go to  Section 2: Personnel Information form",
    link: COMPANY_VERIFICATION.PERSONNEL_INFORMATION,
  },
};

export const company_document = {
  title: "Section 3: Company Documents",
  button: {
    text: "Go to Section 3: Company Documents form",
    link: COMPANY_VERIFICATION.COMPANY_DOCUMENTS,
  },
};

export const due_diligence_documents = {
  title: "Section 3: Due DILIGENCE DOCUMENTS",
  button: {
    text: "Go to Section 3: Company Documents form",
    link: COMPANY_VERIFICATION.COMPANY_DOCUMENTS,
  },
};

export const RemarksInfo: {
  [REMARKS_TYPES.COMPANY_INFORMATION]: IRemarksInfo;
  [REMARKS_TYPES.PERSONNEL_INFORMATION]: IRemarksInfo;
  [REMARKS_TYPES.COMPANY_DOCUMENTS]: IRemarksInfo;
  [REMARKS_TYPES.DUE_DILIGENCE_DOCUMENTS]: IRemarksInfo;
} = {
  [REMARKS_TYPES.COMPANY_INFORMATION]: company_information,
  [REMARKS_TYPES.PERSONNEL_INFORMATION]: personnel_information,
  [REMARKS_TYPES.COMPANY_DOCUMENTS]: company_document,
  [REMARKS_TYPES.DUE_DILIGENCE_DOCUMENTS]: due_diligence_documents,
};
