import APIclient from "API/Client";
import { source } from "../index";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import { GetOrgId } from "utility";

export const FETCH_PAGINATED_TEAMS_LIST_REQUEST = "FETCH_PAGINATED_TEAMS_LIST_REQUEST";
export const FETCH_PAGINATED_TEAMS_LIST_SUCCESS = "FETCH_PAGINATED_TEAMS_LIST_SUCCESS";
export const FETCH_PAGINATED_TEAMS_LIST_FAILURE = "FETCH_PAGINATED_TEAMS_LIST_FAILURE";
export const UPDATE_TEAM_NAME = "UPDATE_TEAM_NAME";
export const DELETE_FROM_LIST = "DELETE_FROM_LIST";

export const updateTeamName = (data) => {
  return {
    type: "UPDATE_TEAM_NAME",
    data: data,
  };
};

export const fetchDataRequest = () => {
  return {
    type: "FETCH_PAGINATED_TEAMS_LIST_REQUEST",
    data: {},
  };
};

export const fetchDataSuccess = (data) => {
  return {
    type: "FETCH_PAGINATED_TEAMS_LIST_SUCCESS",
    data: data,
  };
};

export const fetchDataError = (data) => {
  return {
    type: "FETCH_PAGINATED_TEAMS_LIST_FAILURE",
    data: data,
  };
};

export const deleteTeamFromList = (id) => {
  return {
    type: "DELETE_FROM_LIST",
    data: id,
  };
};

export const getPaginatedTeamList = (page, limit, soleManager = false, memberId = "") => {
  const soleTeamManager = soleManager ? "sole_team_manager=1&" : "";
  const teamMember = memberId ? `user_id=${memberId}&` : "";

  return (dispatch) => {
    dispatch(fetchDataRequest());
    APIclient.getData(
      `/api/v1/org/${GetOrgId()}/team-list?pg=${page}&limit=${limit}&${soleTeamManager}${teamMember}type=all&with_members=1`
    )
      .then((data) => {
        if (data.data.status === 403) {
          source.cancel("Operation canceled for error");
        } else if (data.data.status > HTTP_STATUS_CODE.OK) {
          source.cancel("Operation canceled for error");
        } else {
          return data;
        }
      })
      .then((data) => {
        dispatch(fetchDataSuccess(data.data));
      })
      .catch((err) => {
        dispatch(fetchDataError(err));
      });
  };
};
