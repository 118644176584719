import * as React from "react";
import CheckboxWrapper from "Views/Card/Components/CheckboxWrapper";
import styles from "./index.module.scss";

interface IMerchantItem {
  name: string;
  logo: string;
  iconImageAlt: string;
  onClick: (checked: boolean) => void;
  checked?: boolean;
}

const MerchantItem = ({ name, logo, iconImageAlt, onClick, checked = false }: IMerchantItem) => (
  <CheckboxWrapper
    checked={checked}
    name={name?.replace(/ /g, "_")}
    className={styles["single-lock-item"]}
    onClick={onClick}
  >
    <img src={logo} alt={iconImageAlt} className={styles["single-lock-item__image"]} />
    <p className={styles["single-lock-item__text"]}>{name}</p>
  </CheckboxWrapper>
);

export default MerchantItem;
