import * as React from "react";
import { useCardDetailsEditContext, CARD_DETAILS_FIELDS_MAP } from "Views/Card/Edit/index.context";
import Input from "Views/Card/Components/Input";

const CardEditDetailsInput = () => {
  const { cardDetailsEditState, setCardDetailsEditState } = useCardDetailsEditContext();

  const onTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event?.persist();
    setCardDetailsEditState((prevState) => ({
      ...prevState,
      [CARD_DETAILS_FIELDS_MAP.TITLE]: event?.target?.value || "",
    }));
  };

  return (
    <div className="card-edit__fields-container">
      <p className="card-edit__field-header">Card Title</p>
      <Input placeholder="Enter Card Title" onChange={onTitleChange} value={cardDetailsEditState.title} />
    </div>
  );
};

export default CardEditDetailsInput;
