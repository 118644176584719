import React from "react";
import classNames from "classnames";
import { IWorkflowFilter } from "../type";

import styles from "./styles.module.scss";

const AccountingWorkflowFilter = ({ filter, onClick }: IWorkflowFilter) => {
  return (
    <div className={styles["sub-tab"]}>
      <div className={styles["status-filter"]}>
        {filter.map((filter) => (
          <div
            key={filter.id}
            id={filter.text}
            className={classNames({ [styles["status-filter__active"]]: filter.active })}
            onClick={() => onClick(filter)}
          >
            {filter.text}
            {filter.counter ? <span className={styles.counter}>{filter.counter}</span> : null}
          </div>
        ))}
      </div>
    </div>
  );
};

export default AccountingWorkflowFilter;
