import React, { useContext } from "react";
import classNames from "classnames";

import Popover, { IPopoverProps } from "Modules/DS/Popover";
import { ISelectContext, SelectContext } from "Modules/DS/Select";

import { IPopoverWithBanner } from "Views/Transaction/@types";

import styles from "./index.module.scss";

export const PopoverWithBanner = ({
  active,
  width,
  children,
  className,
  containerClass,
  bannerText = "",
  headerText = "",
  headerInfo = "",
  overrideBanner = null,
  onClose = () => {},
  useDynamicPosition = true,
}: IPopoverWithBanner) => {
  const { closeSelect } = useContext<ISelectContext>(SelectContext);

  const header: boolean = headerText.length > 0;
  const banner: boolean = bannerText.length > 0 || !!overrideBanner;

  const popoverProps: IPopoverProps = {
    width,
    active,
    useDynamicPosition: useDynamicPosition,
    className: classNames({ [className]: className }, { [styles.withBanner]: banner }),
    onClickOutside: () => {
      closeSelect();
      onClose?.();
    },
    ...(containerClass && { containerClass }),
  };

  const renderHeader = () => {
    return (
      header && (
        <div className={styles.header}>
          <h5>{headerText}</h5>
          {headerInfo && <p>{headerInfo}</p>}
        </div>
      )
    );
  };

  const renderBanner = () => {
    const name = classNames(styles.banner, { [styles.withHeader]: header });

    return (
      banner &&
      (overrideBanner
        ? React.createElement("div", { className: name }, overrideBanner)
        : React.createElement("p", { className: name }, bannerText))
    );
  };

  const renderPopoverContents = () => {
    return (
      <>
        {renderHeader()}
        {renderBanner()}
        {children}
      </>
    );
  };

  return <Popover {...popoverProps}>{renderPopoverContents()}</Popover>;
};
