import { CancelTokenSource } from "axios";
import { axiosHeader } from "../index";
import APIclient from "API/Client";
export const GET_APPROVAL_FLOW_LIST_REQUEST = "GET_APPROVAL_FLOW_LIST_REQUEST";
export const GET_APPROVAL_FLOW_LIST_SUCCESS = "GET_APPROVAL_FLOW_LIST_SUCCESS";
export const GET_APPROVAL_FLOW_LIST_FAILURE = "GET_APPROVAL_FLOW_LIST_FAILURE";
export const CLEAR_GET_APPROVAL_FLOW_LIST = "CLEAR_GET_APPROVAL_FLOW_LIST";

const getApprovalFlowListReq = (data?: any) => {
  return {
    type: GET_APPROVAL_FLOW_LIST_REQUEST,
    data,
  };
};
const getApprovalFlowListSuccess = (data?: any) => {
  return {
    type: GET_APPROVAL_FLOW_LIST_SUCCESS,
    data,
  };
};
const getApprovalFlowListError = (data?: any) => {
  return {
    type: GET_APPROVAL_FLOW_LIST_FAILURE,
    data,
  };
};

export const clearGetApprovalFlowList = () => {
  return {
    type: CLEAR_GET_APPROVAL_FLOW_LIST,
  };
};

export const getApprovalFlowListFunc = ({
  page,
  processType,
  requestSource,
}: {
  page: number;
  processType: string;
  requestSource?: CancelTokenSource;
}) => {
  return (dispatch) => {
    dispatch(getApprovalFlowListReq());

    APIclient.getData(
      `/ms/spm-organization/v2/approval/workflow/?page=${page}&limit=10&processType=${processType}`,
      "",
      null,
      { ...axiosHeader, cancelToken: requestSource?.token }
    )
      .then((data) => {
        dispatch(getApprovalFlowListSuccess(data.data));
      })
      .catch((err) => {
        const errorMessage = err?.response?.data?.error?.message || err;
        dispatch(getApprovalFlowListError(errorMessage));
      });
  };
};
