import React, { useEffect } from "react";
import PropTypes, { object } from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { getBankTransferListAction } from "Redux/Actions";

import TopUpBankTransferItem from "./TopUpBankTransferItem";

import Icon from "Modules/Icon";
import Icons from "Modules/icons";
import LoadingIcon from "Modules/loading";

import { groupArrayBy } from "utility/groupArrayBy";

const FlagIcon = (url) => {
  return url && <Icons className={"bank-transfer__flag"} src={url} />;
};

const TopUpBankTransfer = ({ currencyCode, filterBy }) => {
  const { bankTransferList, loading, error } = useSelector((state) => state.topupBankTransferReducer);
  const dispatch = useDispatch();
  const groupedBankByCountry = groupArrayBy(bankTransferList, "countryLabel");
  let countries = Object.keys(groupedBankByCountry);
  // if the currencyCode of the country equal to dashboard's currencyCode, put the country at first
  // ex: dashboard's currencyCode == SGD, then ["Indonesia", "Singapore"] => ["Singapore", "Indonesia"]]
  const index = countries.findIndex((b) => groupedBankByCountry[b][0].currencyCode === currencyCode);
  if (index !== -1) {
    countries.unshift(countries.splice(index, 1)[0]);
  }

  useEffect(() => {
    if (bankTransferList.length === 0) {
      dispatch(getBankTransferListAction(currencyCode));
    }
  }, []);

  if (loading) return <LoadingIcon />;

  if (error) return <div className={"text--danger"}>Failed to fetch bank transfer data</div>;

  const getBankList = (countryLabel) => {
    // get all available object keys to check with the data
    const filterKeys = Object.entries(groupedBankByCountry[countryLabel][0])
      .filter(([objectKey]) => {
        if (filterBy?.hasOwnProperty(objectKey)) {
          return objectKey;
        }
      })
      .map(([objectKey]) => objectKey);

    // filter bank items according to the filterBy value
    const bankList = filterBy
      ? groupedBankByCountry[countryLabel].filter((item) => {
          let value = true;
          for (const objectKey of filterKeys) {
            value = value && filterBy[objectKey] === item[objectKey];
          }

          return value;
        })
      : groupedBankByCountry[countryLabel];

    return bankList;
  };

  return (
    <>
      <div className={"bank-transfer__info"}>Bank/virtual account number stays the same for all your top ups</div>
      <div className="bank-transfer__note">
        <b>
          Please use a corporate bank account with the same entity name as your registered Spenmo account to top up your
          balance.
        </b>{" "}
        If you are a sole proprietor without a corporate account, you may use the business owner's bank account instead.{" "}
        <a
          className="bank-transfer__note__link"
          target="_blank"
          href="https://support.spenmo.com/hc/en-us/sections/4411139352985-Top-Up"
          rel="noopener noreferrer"
        >
          Learn more
          <Icon icon="openInNew" className="bank-transfer__note__link-icon" />
        </a>
      </div>
      {countries.map((countryLabel) => (
        <React.Fragment key={countryLabel}>
          <div className={"bank-transfer__title"}>
            {currencyCode === "USD" ? (
              <span>{"All Countries (USD)"}</span>
            ) : (
              <>
                {FlagIcon(groupedBankByCountry[countryLabel][0].countryFlagUrl)}
                <span>{`${countryLabel} (${groupedBankByCountry[countryLabel][0].currencyCode})`}</span>
              </>
            )}
          </div>
          {getBankList(countryLabel).map((item) => (
            <TopUpBankTransferItem key={item.accountNumber} item={item} filterBy={filterBy} />
          ))}
        </React.Fragment>
      ))}
    </>
  );
};

TopUpBankTransfer.propTypes = {
  currencyCode: PropTypes.string,
  filterBy: {
    isVirtualAccount: PropTypes.bool,
  },
};

export default TopUpBankTransfer;
