import * as React from "react";
import classNames from "classnames";
import { Input as AntdInput } from "antd";
import "Views/Card/Components/Input/index.scss";

interface InputProps {
  value: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  addonBefore?: React.ReactNode;
  disabled?: boolean;
  hasError?: boolean;
  onBlur?: () => void;
}

export const Input = ({
  value,
  onChange = () => {
    /* do nothing */
  },
  placeholder = "",
  addonBefore = null,
  disabled = false,
  hasError = false,
  onBlur = () => {
    /* do nothing*/
  },
}: InputProps) => {
  const _className = classNames("card-input-box", {
    [`card-input-box__disabled`]: disabled,
    [`card-input-box__error`]: hasError,
  });
  return (
    <AntdInput
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      className={_className}
      addonBefore={addonBefore}
      disabled={disabled}
      onBlur={onBlur}
    />
  );
};

export default Input;
