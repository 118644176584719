import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchUserCardsList } from "Redux/Actions/Cards/List";
import { fetctCardListData } from "Redux/Reducers/Cards/ListReducer";

const cardsOrdering = {
  order_col: "last_transaction",
  order_dir: "desc",
};

const INITAL_PAGE_NUMBER = 0;

export const useCardList = (isAuditor, limit = 10) => {
  const dispatch = useDispatch();
  const {
    data: userCardListData,
    loading: userCardsListLoading,
    error: userCardsListError,
  } = useSelector(fetctCardListData);

  const processCardListData = () => {
    const { cards = [], total_count, status_message = "" } = userCardListData?.payload || {};
    if (userCardsListLoading) {
      return null;
    } else if (userCardsListError) {
      return {
        cardList: [],
        totalCount: total_count,
        status_message,
      };
    } else if (Array.isArray(cards)) {
      return {
        cardList: [...cards],
        totalCount: total_count,
        status_message,
      };
    }
  };

  const refetchUserCardsList = () => {
    const MY_CARDS = isAuditor ? false : true;
    dispatch(fetchUserCardsList(MY_CARDS, { page: INITAL_PAGE_NUMBER, limit, ordering: cardsOrdering }));
  };

  useEffect(() => {
    refetchUserCardsList();
  }, []);

  return [processCardListData(), userCardsListLoading, userCardsListError, refetchUserCardsList];
};
