import { Button, Divider, Form, Select } from "antd";
import { DynamicFormContext } from "Modules/DynamicForm/store";
import { DynamicFormFieldOption, DynamicFormFieldProps } from "Modules/DynamicForm/types";
import React, { useContext, useEffect, useState } from "react";
import DynamicFormTextfield from "../Textfield";
import { formItemProps } from "../utils/formItemProps";

import { greyCloseIcon } from "assets/img";
import style from "./Dropdown.module.scss";
import classNames from "classnames";
import { highlighter } from "utility";

export const DynamicFormDropdownBase = (
  props: DynamicFormFieldProps & {
    onChange: (value: string) => void;
    onEnter: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    onClickNewButton: () => void;
  }
) => {
  const { alias, label, fieldProps, onChange, onClickNewButton, onEnter, options } = props;
  const { trackEdit, trackView } = useContext(DynamicFormContext);
  const [value, setValue] = useState("");
  const [search, setSearch] = useState("");

  // IMPROVEMENT: getSubLabel should be dynamic to make it more flexible
  const getSubLabel = (field: DynamicFormFieldOption) => {
    return field.additionalCode;
  };

  const dropdownRender = (menu: React.ReactElement<any, string | React.JSXElementConstructor<any>>) => {
    if (fieldProps.isAllowToAddData) {
      return (
        <>
          {menu}
          {Boolean(menu.props?.["options"]?.length) && <Divider className={style.divider} />}
          <Button className={style.dropdownAdd} onClick={(_e) => onClickNewButton()}>
            + New {label}
          </Button>
        </>
      );
    }
    return menu;
  };

  const handleOnChange = (value) => {
    setValue(value);
    onChange(value);
  };

  const handleOnFocus = () => {
    trackView(alias, value);
  };

  const handleOnBlur = () => {
    trackEdit(alias, value);
  };

  const filterOption = (input: string, option) => {
    const subLabel = option.props.subLabel;

    return (
      option.props.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
      option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
      (Boolean(subLabel) && subLabel.toLowerCase().indexOf(input.toLowerCase()) >= 0)
    );
  };

  const handleSearch = (val) => {
    setSearch(val.toLowerCase());
  };

  return (
    <Form.Item {...formItemProps(props)}>
      <Select
        showSearch
        className={style.select}
        dropdownClassName={classNames(style.dropdown, { [style.hideNotFountContent]: fieldProps.isAllowToAddData })}
        filterOption={filterOption}
        optionFilterProp="label"
        placeholder={fieldProps.placeholder}
        onChange={handleOnChange}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        onInputKeyDown={onEnter}
        disabled={!fieldProps.editable}
        dropdownRender={dropdownRender}
        onSearch={handleSearch}
      >
        {React.Children.toArray(
          options.map((item: DynamicFormFieldOption) => {
            const highlight = (text) => <span className={style.highlight}>{text}</span>;
            const highlightedLabel = highlighter(item.label, search, highlight);

            const subLabel = getSubLabel(item);
            return (
              <Select.Option value={item.value} label={item.label} subLabel={subLabel}>
                {Boolean(subLabel) ? (
                  <>
                    <div className={style.mainOption}>{highlightedLabel}</div>
                    <div className={style.break} />
                    <div className={style.subOption}>{highlighter(subLabel, search, highlight)}</div>
                  </>
                ) : (
                  highlightedLabel
                )}
              </Select.Option>
            );
          })
        )}
      </Select>
    </Form.Item>
  );
};

// To do: refactor this. Shouldn't use hard coded alias since it's not dynamic
const CHAINING_ALIAS = ["paynowIdentifierType"];

const DynamicFormDropdown = (props: DynamicFormFieldProps) => {
  const { id, fieldID, onChangeValue, options, alias, fieldProps, value } = props;
  const { doFetch } = fieldProps;
  const { form, setChainFields } = useContext(DynamicFormContext);
  const [isNew, setIsNew] = useState(false);

  const handleChange = (value: string) => {
    onChangeValue?.(fieldID, value);
  };

  // chaining on did mount
  useEffect(() => {
    // To do: refactor this. Shouldn't use hard coded alias since it's not dynamic
    // can be improved. Currently fetching the same chain (paynow) twice
    if (CHAINING_ALIAS.includes(alias) && doFetch) {
      setChainFields((prev) => ({
        ...prev,
        [fieldID]: value,
      }));
    }
  }, []);

  const handleNew = () => {
    setIsNew(true);
  };

  const handleClear = () => {
    setIsNew(false);
    form.resetFields([["dynamicFields", id]]);
  };

  const handleNewByEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    if (e.key === "Enter") {
      const isExist = Boolean(options.find((option) => option.label.toLowerCase().search(value?.toLowerCase()) !== -1));
      if (!isExist) {
        handleNew();
        form.setFieldsValue({
          dynamicFields: {
            [id]: value,
          },
        });
      }
    }
  };

  return (
    <>
      {isNew ? (
        <DynamicFormTextfield
          {...props}
          suffix={<img className={style.clearIcon} alt="Clear" src={greyCloseIcon} onClick={handleClear} />}
        />
      ) : (
        <DynamicFormDropdownBase
          {...props}
          onChange={handleChange}
          onClickNewButton={handleNew}
          onEnter={handleNewByEnter}
        />
      )}
    </>
  );
};

export default DynamicFormDropdown;
