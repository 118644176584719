import React from "react";
import { Skeleton } from "antd";

import styles from "./index.module.scss";

export const DocumentsLoader: React.FC = (): React.ReactElement => {
  const NUM_OF_SECTIONS = 3;

  const setSectionSkeleton = (count: number): React.ReactNode => {
    return React.Children.toArray(
      Array.from({ length: count }, () => (
        <div className={styles.section}>
          <Skeleton.Input className={styles.header} active size="large" />
          <Skeleton.Input className={styles.fileUpload} active size="large" />
        </div>
      ))
    );
  };

  return <>{setSectionSkeleton(NUM_OF_SECTIONS)}</>;
};
