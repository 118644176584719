import { useContext } from "react";
import { FormInstance } from "antd/lib/form";

import {
  FORM_NAME,
  KYX_FORMS_KEYS,
  PERSON_DOCUMENT_TYPE,
  PERSONNEL_INFORMATION_FORM_ITEM_KEYS,
} from "Views/KYX/Constants/Forms";
import { useFormSaveAsDraft } from "Views/KYX/hooks";
import { ManualFormContext } from "Views/KYX/Context";
import { GUIDELINES_TYPE, FORM_FIELD_TYPES } from "Views/KYX/Constants";
import { getValidationError, getValidationstatus } from "Views/KYX/Helper";
import { IFormElement, IPersonForm, IGetFormConfigsPayload } from "Views/KYX/@types";

import { useProofOfResidence } from "./Residence";

export const usePersonnelIdDocumentPassportTypeSGD = (
  form: FormInstance,
  remarks: IGetFormConfigsPayload,
  currentKey: string,
  isFormDisabled: boolean,
  formId: string
): IFormElement[] => {
  const { formData, setFormData } = useContext(ManualFormContext);
  const { saveFormAsDraft } = useFormSaveAsDraft(setFormData);

  const PASSPORT_DOCUMENT_HEADER_AND_GUIDELINES: IFormElement = {
    key: PERSONNEL_INFORMATION_FORM_ITEM_KEYS.PASSPORT_DOCUMENT_HEADER_AND_GUIDELINES,
    col: { span: 24 },
    element: {
      type: FORM_FIELD_TYPES.HEADER,
      props: {
        title: `${PERSON_DOCUMENT_TYPE.PASSPORT} Document`,
        guidelines: GUIDELINES_TYPE.PASSPORT,
      },
    },
  };

  const PASSPORT_NUMBER: IFormElement = {
    key: PERSONNEL_INFORMATION_FORM_ITEM_KEYS.PASSPORT_NUMBER,
    col: { span: 12 },
    element: {
      type: FORM_FIELD_TYPES.INPUT,
      props: {
        formProps: {
          name: [
            KYX_FORMS_KEYS.PERSON_DATA_ID_DOCUMENTS,
            KYX_FORMS_KEYS.PERSON_DATA_PASSPORT_DOCUMENT_TYPE,
            KYX_FORMS_KEYS.PERSON_DATA_DOCUMENT_NUMBER,
          ],
          label: `${PERSON_DOCUMENT_TYPE.PASSPORT} Number`,
          required: true,
          rules: [
            {
              required: true,
              message: `Please enter ${PERSON_DOCUMENT_TYPE.PASSPORT} number`,
            },
            { whitespace: true, message: "This field cannot be empty" },
          ],
          validateStatus: getValidationstatus(remarks, [
            KYX_FORMS_KEYS.PERSONNEL_DATA_PERSONS,
            currentKey,
            KYX_FORMS_KEYS.PERSON_DATA_ID_DOCUMENTS,
            KYX_FORMS_KEYS.PERSON_DATA_PASSPORT_DOCUMENT_TYPE,
            KYX_FORMS_KEYS.PERSON_DATA_DOCUMENT_NUMBER,
          ]),
          help: getValidationError(
            remarks,
            [
              KYX_FORMS_KEYS.PERSONNEL_DATA_PERSONS,
              currentKey,
              KYX_FORMS_KEYS.PERSON_DATA_ID_DOCUMENTS,
              KYX_FORMS_KEYS.PERSON_DATA_PASSPORT_DOCUMENT_TYPE,
              KYX_FORMS_KEYS.PERSON_DATA_DOCUMENT_NUMBER,
            ],
            `${PERSON_DOCUMENT_TYPE.PASSPORT} Number`,
            FORM_NAME.PERSONNEL_INFORMATION
          ),
        },
        inputProps: {
          placeholder: `Please write your 9-digits alphanumeric ${PERSON_DOCUMENT_TYPE.PASSPORT} number here`,
          disabled: isFormDisabled,
        },
      },
    },
  };

  const PASSPORT_EXPIRY_DATE: IFormElement = {
    key: PERSONNEL_INFORMATION_FORM_ITEM_KEYS.PASSPORT_EXPIRY,
    col: { span: 12 },
    element: {
      type: FORM_FIELD_TYPES.DATEPICKER,
      props: {
        formProps: {
          name: [
            KYX_FORMS_KEYS.PERSON_DATA_ID_DOCUMENTS,
            KYX_FORMS_KEYS.PERSON_DATA_PASSPORT_DOCUMENT_TYPE,
            KYX_FORMS_KEYS.PERSON_DATA_DOCUMENT_EXPIRY,
          ],
          label: "Expiry Date",
          required: true,
          rules: [
            {
              required: true,
              message: "Please input expiry date",
            },
          ],
          validateStatus: getValidationstatus(remarks, [
            KYX_FORMS_KEYS.PERSONNEL_DATA_PERSONS,
            currentKey,
            KYX_FORMS_KEYS.PERSON_DATA_ID_DOCUMENTS,
            KYX_FORMS_KEYS.PERSON_DATA_PASSPORT_DOCUMENT_TYPE,
            KYX_FORMS_KEYS.PERSON_DATA_DOCUMENT_EXPIRY,
          ]),
          help: getValidationError(
            remarks,
            [
              KYX_FORMS_KEYS.PERSONNEL_DATA_PERSONS,
              currentKey,
              KYX_FORMS_KEYS.PERSON_DATA_ID_DOCUMENTS,
              KYX_FORMS_KEYS.PERSON_DATA_PASSPORT_DOCUMENT_TYPE,
              KYX_FORMS_KEYS.PERSON_DATA_DOCUMENT_EXPIRY,
            ],
            "Expiry Date",
            FORM_NAME.PERSONNEL_INFORMATION
          ),
        },
        dateProps: {
          placeholder: "dd/mm/yyyy",
          format: "DD/MM/YYYY",
          disabled: isFormDisabled,
        },
      },
    },
  };

  const UPLOAD_PASSPORT: IFormElement = {
    key: PERSONNEL_INFORMATION_FORM_ITEM_KEYS.UPLOAD_PASSPORT,
    col: { span: 24 },
    element: {
      type: FORM_FIELD_TYPES.DUALUPLOAD,
      props: {
        titleProps: {
          name: [
            KYX_FORMS_KEYS.PERSON_DATA_ID_DOCUMENTS,
            KYX_FORMS_KEYS.PERSON_DATA_PASSPORT_DOCUMENT_TYPE,
            KYX_FORMS_KEYS.PERSON_DATA_DOCUMENT,
            "_title",
          ],
          label: `Upload Document`,
          required: true,
        },
        filePropsOne: {
          formProps: {
            name: [
              KYX_FORMS_KEYS.PERSON_DATA_ID_DOCUMENTS,
              KYX_FORMS_KEYS.PERSON_DATA_PASSPORT_DOCUMENT_TYPE,
              KYX_FORMS_KEYS.PERSON_DATA_DOCUMENT,
            ],
            label: `Front Side`,
            // required: true,
            rules: [
              {
                required: true,
                message: `Please upload a valid ${PERSON_DOCUMENT_TYPE.PASSPORT} document`,
              },
            ],
            validateStatus: getValidationstatus(remarks, [
              KYX_FORMS_KEYS.PERSONNEL_DATA_PERSONS,
              currentKey,
              KYX_FORMS_KEYS.PERSON_DATA_ID_DOCUMENTS,
              KYX_FORMS_KEYS.PERSON_DATA_PASSPORT_DOCUMENT_TYPE,
              KYX_FORMS_KEYS.PERSON_DATA_DOCUMENT,
            ]),
            help: getValidationError(
              remarks,
              [
                KYX_FORMS_KEYS.PERSONNEL_DATA_PERSONS,
                currentKey,
                KYX_FORMS_KEYS.PERSON_DATA_ID_DOCUMENTS,
                KYX_FORMS_KEYS.PERSON_DATA_PASSPORT_DOCUMENT_TYPE,
                KYX_FORMS_KEYS.PERSON_DATA_DOCUMENT,
              ],
              `${PERSON_DOCUMENT_TYPE.PASSPORT} Document`,
              FORM_NAME.PERSONNEL_INFORMATION
            ),
            initialValue: formData?.form?.personnel_information?.persons
              ? formData?.form?.personnel_information?.persons[currentKey]?.identification_document?.passport_document
                  ?.document
              : "",
          },
          disabled: isFormDisabled,
          // guidelinesType: GUIDELINES_TYPE.PASSPORT,
          action: (arg) => {
            const personsObj = form.getFieldsValue()["persons"];

            const idDoc = {
              [KYX_FORMS_KEYS.PERSON_DATA_DOCUMENT_TYPE]: PERSON_DOCUMENT_TYPE.PASSPORT,
              [KYX_FORMS_KEYS.PERSON_DATA_PASSPORT_DOCUMENT_TYPE]: {
                ...personsObj[currentKey].identification_document[KYX_FORMS_KEYS.PERSON_DATA_PASSPORT_DOCUMENT_TYPE],
                [KYX_FORMS_KEYS.PERSON_DATA_DOCUMENT]: arg,
              },
            };

            const newPersons: IPersonForm[] = Object.keys(personsObj).map((key, index) => {
              const data = {
                ...personsObj[key],
                roles_of_individual: personsObj[key]?.roles_of_individual
                  ? Object.assign(
                      {},
                      ...personsObj[key]?.roles_of_individual?.map((role) => {
                        return role ? { [role]: true } : null;
                      })
                    )
                  : {},
              };

              if (index !== parseInt(currentKey)) {
                // This isn't the person we care about - keep it as-is
                return {
                  ...data,
                  [KYX_FORMS_KEYS.PERSON_DATA_ID_DOCUMENTS]: {
                    ...data?.identification_document,
                    is_same_residential_address: personsObj[key]?.identification_document?.is_same_residential_address
                      ?.length
                      ? personsObj[key]?.identification_document?.is_same_residential_address?.[0] === "checked"
                      : false,
                  },
                };
              }

              // Otherwise, this is the one we want - return an updated value
              return {
                ...data,
                [KYX_FORMS_KEYS.PERSON_DATA_ID_DOCUMENTS]: {
                  ...idDoc,
                  is_same_residential_address: personsObj[key]?.identification_document?.is_same_residential_address
                    ?.length
                    ? personsObj[key]?.identification_document?.is_same_residential_address?.[0] === "checked"
                    : false,
                },
              } as IPersonForm;
            });

            saveFormAsDraft({
              id: formId,
              form: {
                ...formData.form,
                personnel_information: {
                  ...formData.form.personnel_information,
                  persons: newPersons,
                },
              },
            });

            return form.setFieldsValue({
              [KYX_FORMS_KEYS.PERSONNEL_DATA_PERSONS]: {
                [currentKey]: { [KYX_FORMS_KEYS.PERSON_DATA_ID_DOCUMENTS]: idDoc },
              },
            });
          },
        },
        filePropsTwo: {
          formProps: {
            name: [
              KYX_FORMS_KEYS.PERSON_DATA_ID_DOCUMENTS,
              KYX_FORMS_KEYS.PERSON_DATA_PASSPORT_DOCUMENT_TYPE,
              KYX_FORMS_KEYS.PERSON_DATA_DOCUMENT_BACK,
            ],
            label: `Back Side`,
            // required: true,
            rules: [
              {
                required: true,
                message: `Please upload a valid ${PERSON_DOCUMENT_TYPE.PASSPORT} document`,
              },
            ],
            validateStatus: getValidationstatus(remarks, [
              KYX_FORMS_KEYS.PERSONNEL_DATA_PERSONS,
              currentKey,
              KYX_FORMS_KEYS.PERSON_DATA_ID_DOCUMENTS,
              KYX_FORMS_KEYS.PERSON_DATA_PASSPORT_DOCUMENT_TYPE,
              KYX_FORMS_KEYS.PERSON_DATA_DOCUMENT_BACK,
            ]),
            help: getValidationError(
              remarks,
              [
                KYX_FORMS_KEYS.PERSONNEL_DATA_PERSONS,
                currentKey,
                KYX_FORMS_KEYS.PERSON_DATA_ID_DOCUMENTS,
                KYX_FORMS_KEYS.PERSON_DATA_PASSPORT_DOCUMENT_TYPE,
                KYX_FORMS_KEYS.PERSON_DATA_DOCUMENT_BACK,
              ],
              `${PERSON_DOCUMENT_TYPE.PASSPORT} Document`,
              FORM_NAME.PERSONNEL_INFORMATION
            ),
            initialValue: formData?.form?.personnel_information?.persons
              ? formData?.form?.personnel_information?.persons[currentKey]?.identification_document?.passport_document
                  ?.document_back
              : "",
          },
          disabled: isFormDisabled,
          // guidelinesType: GUIDELINES_TYPE.PASSPORT,
          action: (arg) => {
            const personsObj = form.getFieldsValue()["persons"];

            const idDoc = {
              [KYX_FORMS_KEYS.PERSON_DATA_DOCUMENT_TYPE]: PERSON_DOCUMENT_TYPE.PASSPORT,
              [KYX_FORMS_KEYS.PERSON_DATA_PASSPORT_DOCUMENT_TYPE]: {
                ...personsObj[currentKey].identification_document[KYX_FORMS_KEYS.PERSON_DATA_PASSPORT_DOCUMENT_TYPE],
                [KYX_FORMS_KEYS.PERSON_DATA_DOCUMENT_BACK]: arg,
              },
            };

            const newPersons: IPersonForm[] = Object.keys(personsObj).map((key, index) => {
              const data = {
                ...personsObj[key],
                roles_of_individual: personsObj[key]?.roles_of_individual
                  ? Object.assign(
                      {},
                      ...personsObj[key]?.roles_of_individual?.map((role) => {
                        return role ? { [role]: true } : null;
                      })
                    )
                  : {},
              };

              if (index !== parseInt(currentKey)) {
                // This isn't the person we care about - keep it as-is
                return {
                  ...data,
                  [KYX_FORMS_KEYS.PERSON_DATA_ID_DOCUMENTS]: {
                    ...data?.identification_document,
                    is_same_residential_address: personsObj[key]?.identification_document?.is_same_residential_address
                      ?.length
                      ? personsObj[key]?.identification_document?.is_same_residential_address?.[0] === "checked"
                      : false,
                  },
                };
              }

              // Otherwise, this is the one we want - return an updated value
              return {
                ...data,
                [KYX_FORMS_KEYS.PERSON_DATA_ID_DOCUMENTS]: {
                  ...idDoc,
                  is_same_residential_address: personsObj[key]?.identification_document?.is_same_residential_address
                    ?.length
                    ? personsObj[key]?.identification_document?.is_same_residential_address?.[0] === "checked"
                    : false,
                },
              } as IPersonForm;
            });

            saveFormAsDraft({
              id: formId,
              form: {
                ...formData.form,
                personnel_information: {
                  ...formData.form.personnel_information,
                  persons: newPersons,
                },
              },
            });

            return form.setFieldsValue({
              [KYX_FORMS_KEYS.PERSONNEL_DATA_PERSONS]: {
                [currentKey]: { [KYX_FORMS_KEYS.PERSON_DATA_ID_DOCUMENTS]: idDoc },
              },
            });
          },
        },
      },
    },
  };

  const residence = useProofOfResidence(
    form,
    remarks,
    currentKey,
    isFormDisabled,
    PERSON_DOCUMENT_TYPE.PASSPORT,
    formId
  );

  return [
    PASSPORT_DOCUMENT_HEADER_AND_GUIDELINES,
    PASSPORT_NUMBER,
    PASSPORT_EXPIRY_DATE,
    UPLOAD_PASSPORT,
    ...residence,
  ];
};
