import * as React from "react";
import { Tooltip, Switch } from "antd";
import classNames from "classnames";
import Tag from "Views/Card/Components/Tag";
import { useSelector, useDispatch } from "react-redux";
import { useCardDetailsContext } from "Views/Card/Details/index.context";
import { useCanUserEditCards } from "customHooks/Card";
import { cardActivateFunc, clearCardActivateData } from "Redux/Actions";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import { showErrorNotification } from "Views/Card/Components/Notification";
import { CARD_STATUS } from "Views/Card/types";
import DisableCardConfirmationModal from "Views/Card/Details/SubInfo/StatusToggle/DisableModal";
import styles from "Views/Card/Details/SubInfo/StatusToggle/index.module.scss";

interface ICardSatusToggleProps {
  onCardStatusToggle: (status: boolean) => void;
}

const CardSatusToggle: React.FunctionComponent<ICardSatusToggleProps> = ({ onCardStatusToggle }) => {
  const dispatch = useDispatch();
  const [showDisableModal, setShowDisableModal] = React.useState(false);
  const { data, loading, error } = useSelector((state: any) => state?.cardActivateReducer || {});
  const { cardData } = useCardDetailsContext();
  const { cardStatus, teamId, id } = cardData;
  const canUserEditCards = useCanUserEditCards(teamId);
  const isCardEnabled = cardStatus === CARD_STATUS.ENABLED;

  const updateStatus = () => {
    if (data?.status === HTTP_STATUS_CODE.OK) {
      onCardStatusToggle(!isCardEnabled);
    } else if (error) {
      const { status_message = "Something went wrong! Please try again later." } = data?.payload || {};
      showErrorNotification(status_message);
    }
  };

  React.useEffect(() => {
    updateStatus();
  }, [data]);

  React.useEffect(() => {
    return () => dispatch(clearCardActivateData());
  }, []);

  const setCardStatus = () => {
    const status = isCardEnabled ? "suspended" : "unsuspended";
    dispatch(cardActivateFunc({ id }, status));
  };

  const onDisableCard = () => {
    setShowDisableModal(false);
    setCardStatus();
  };

  const disabledStatus = (withTooltip: boolean) => {
    let statusTag: JSX.Element = null;
    if (withTooltip) {
      statusTag = (
        <Tooltip
          title="Please contact your Budget Owner to enable this card."
          trigger="hover"
          placement="top"
          arrowPointAtCenter
          getPopupContainer={(triggerNode) => triggerNode.parentElement}
          destroyTooltipOnHide
          overlayClassName={styles["tooltip"]}
        >
          <div>
            <Tag tagName="Disabled" tagType="error" />
          </div>
        </Tooltip>
      );
    } else {
      statusTag = <Tag tagName="Disabled" tagType="error" />;
    }
    return (
      <div className={`${styles["status-toogle-container"]} ${styles["status-disabled-container"]}`}>
        <div className={styles["card-status-header"]}>Card status: </div>
        {statusTag}
      </div>
    );
  };

  const onToogle = () => {
    canUserEditCards ? setCardStatus() : setShowDisableModal(true);
  };

  const enableTextClassname = classNames(styles["toogle-text"], {
    [styles["text-active"]]: isCardEnabled,
  });

  const disableTextClassname = classNames(styles["toogle-text"], {
    [styles["text-active"]]: !isCardEnabled,
  });

  const getCardStatus = () =>
    isCardEnabled || canUserEditCards ? (
      <div className={styles["status-toogle-container"]}>
        <p className={enableTextClassname}>Enable</p>
        <Switch
          data-testid="custom-switch-btn"
          checked={!isCardEnabled}
          onChange={onToogle}
          className={styles["status-toggle"]}
          loading={loading}
        />
        <p className={disableTextClassname}>Disable</p>
      </div>
    ) : (
      disabledStatus(true)
    );

  return (
    <>
      {getCardStatus()}
      {showDisableModal && (
        <DisableCardConfirmationModal onClose={() => setShowDisableModal(false)} onDisableCard={onDisableCard} />
      )}
    </>
  );
};

export default CardSatusToggle;
