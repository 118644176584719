import React, { useEffect } from "react";
import { useQuery } from "utility";
import { RootState } from "Redux/ConfigureStore";
import { Redirect } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useAppSelector } from "hook";

import { COMPANY_VERIFICATION } from "Route/KYX/paths";
import { MyInfoCallBack as MyInfoCallBackAPI } from "Redux/Actions";
import { IMyInfoCallBackReducer } from "Redux/Actions/KYX/types";
import { RedirectionLoader } from "Views/KYX/Loader/RedirectionLoader";
import JointApplicantInvalidToken from "Views/KYX/Intro/Start/JointApplicant/InvalidToken";

import styles from "./index.module.scss";

const JointApplicantCallBack: React.FC = (): JSX.Element => {
  let query = useQuery();
  const dispatch = useDispatch();

  const code: string = query.get("code");
  const state: string = query.get("state");

  const myInfoCallBackData: IMyInfoCallBackReducer = useAppSelector((state: RootState) => state.myInfoCallBackReducer);

  useEffect(() => {
    const onMyInfoSuccess = () => {
      const IS_MY_INFO = true;
      dispatch(MyInfoCallBackAPI({ code: code, state: state }, IS_MY_INFO));
    };
    code && state && onMyInfoSuccess();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code, state]);

  if (myInfoCallBackData?.isSuccess) {
    return <Redirect to={COMPANY_VERIFICATION.JOINT_APPLICANT_FORM} />;
  }

  if (myInfoCallBackData?.error) {
    return <Redirect to={COMPANY_VERIFICATION.JOINT_APPLICANT} />;
  }

  if (myInfoCallBackData?.loading) {
    return (
      <div className={styles.wrapper}>
        <RedirectionLoader />
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <JointApplicantInvalidToken />
    </div>
  );
};

export default JointApplicantCallBack;
