import * as React from "react";
import classNames from "classnames";
import "./Tag.scss";
import { greyCloseIcon } from "assets/img";

interface TagProps {
  children: string | React.ReactElement;
  onClose?: () => void;
}

const Tag: React.FC<TagProps> = ({ children, onClose }: TagProps) => {
  const tagClassNames = classNames("spm-tag", {
    "spm-tag--closeable": onClose,
  });
  return (
    <div className={tagClassNames}>
      {children}
      {onClose && <img className="spm-tag__close-btn" onClick={onClose} src={greyCloseIcon}  alt=""/>}
    </div>
  );
};

export default Tag;
