import * as React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { getEmployeeRoles } from "Redux/Actions";
import { clearUpdateRole } from "Redux/Actions/Employee/Update/Role";
import { getKYCStatuses } from "Redux/Actions/Employee/Get/KycStatusList";

import { trackEvent } from "utility/analytics";
import { USER_EVENT_NAMES } from "Views/Employee/Analytics";

import { UPDATE_ROLE_PORTAL } from "constants/Employee.constant";

import List from "Views/Employee/List";
import Modal from "Views/Employee/Modal";
import Toast from "Views/Employee/Toast";
import EmployeesProvider from "Views/Employee/provider";
import CustomerSupport from "Views/Employee/CustomerSupport";

import "./index.scss";

const Employee = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getKYCStatuses());
    dispatch(clearUpdateRole());
    dispatch(getEmployeeRoles(UPDATE_ROLE_PORTAL));
  }, [dispatch]);

  useEffect(() => {
    const onPageLoad = () => trackEvent(USER_EVENT_NAMES.USER_PAGE_LOADED);

    // Check if the page has already loaded
    if (document.readyState === "complete") {
      onPageLoad();
    } else {
      window.addEventListener("load", onPageLoad);
      // Remove the event listener when component unmounts
      return () => window.removeEventListener("load", onPageLoad);
    }
  }, []);

  return (
    <div className="employees" data-testid="employees">
      <EmployeesProvider>
        <List />
        <Modal />
        <Toast />
        <CustomerSupport />
      </EmployeesProvider>
    </div>
  );
};

export default Employee;
