import React, { useEffect } from "react";
import qs from "query-string";
import { Typography } from "@spenmo/splice";
import { useHistory, useLocation } from "react-router-dom";
import { PaginationProps } from "antd/lib/pagination";
import { TablePaginationConfig } from "antd/lib/table";

import { ILocationQueryParams, IUsePageProps } from "Views/Reimbursement/@types";
import { REIMBURSEMENT_QUERY_PARAMS, TABLE_SIZE } from "Views/Reimbursement/Constants";

import styles from "./index.module.scss";

export const itemRender: PaginationProps["itemRender"] = (_, type, originalElement) => {
  if (type === "page" || type === "jump-next" || type === "jump-prev") {
    return undefined;
  } else {
    return originalElement;
  }
};

export const PaginationDetails = ({ qty, range }: { qty: number; range: [number, number] }) => {
  const location = useLocation();

  const query: ILocationQueryParams = qs.parse(location.search, {
    parseNumbers: true,
    parseBooleans: true,
  });

  const { page = 1 } = query;

  return (
    <div className={styles.pagination}>
      <Typography size="s" variant="body-content">
        Showing {range[0]} - {range[1]} of {qty}
      </Typography>
      <Typography size="s" variant="body-content">
        Page {page} of {Math.ceil(qty / TABLE_SIZE)}
      </Typography>
    </div>
  );
};

const paginationProps: TablePaginationConfig = {
  itemRender,
  showLessItems: true,
  showQuickJumper: false,
  hideOnSinglePage: false,
  className: styles.container,
  showTotal: (qty: number, range: [number, number]) => <PaginationDetails qty={qty} range={range} />,
};

const usePageProps = (totalCount: number, loading: boolean): IUsePageProps => {
  const history = useHistory();
  const location = useLocation();

  const query: ILocationQueryParams = qs.parse(location.search, {
    parseNumbers: true,
    parseBooleans: true,
  });

  const { page = 1 } = query;

  // Shift page to first page is quey number exceeds list limit
  // Shift page to first page is quey number is less than or equal to 0
  useEffect(() => {
    if (!loading) {
      const lastPage = Math.ceil(totalCount / TABLE_SIZE);
      if (page > lastPage) {
        history.push({
          search: qs.stringify({
            ...query,
            [REIMBURSEMENT_QUERY_PARAMS.PAGE]: lastPage === 0 ? 1 : lastPage,
          }),
        });
      } else if (page <= 0) {
        history.push({
          search: qs.stringify({
            ...query,
            [REIMBURSEMENT_QUERY_PARAMS.PAGE]: 1,
          }),
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalCount, loading]);

  const handleOnPageChange = (pg: number): void => {
    window.scrollTo(0, 0);
    history.push({
      search: qs.stringify({
        ...query,
        [REIMBURSEMENT_QUERY_PARAMS.PAGE]: pg,
      }),
    });
  };

  return {
    pageSize: TABLE_SIZE,
    paginationOnTop: true,
    pageNum: page - 1,
    onPageChange: handleOnPageChange,
    paginationProps: paginationProps,
    scrollHorizontal: "max-content",
  };
};

export default usePageProps;
