import { useEffect } from "react";

/**
 * useClarity hooks is a Analytics that will record the user behavior when
 * the user is using our dashboard. It will only record the production ENV.
 * @returns void
 */
export const useClarity = () => {
  useEffect(() => {
    // Microsoft Clarity initialisation. Only for production.
    // PLEASE DON'T MODIFY THIS CODE IF YOU DON'T KNOW WHAT IS THIS ABOUT.
    /**
     * IF YOU NEED TO CHANGE/UPDATE THIS, please visit https://clarity.microsoft.com/.
     * IF YOU WANT TO TEST THIS, add CLARITY_KEY into the runtime-envs
     * so that it will work in your local. DON'T forget to change it back into "production" again.
     */
    if (window && window.__ENV__?.CLARITY_KEY) {
      const head = document.querySelector("head");
      const script: HTMLScriptElement = document.createElement("script");
      script.type = "text/javascript";
      script.text = (function (c, l, a, r, i, t, y) {
        c[a] =
          c[a] ||
          function () {
            (c[a].q = c[a].q || []).push(arguments);
          };
        t = l.createElement(r);
        t.async = 1;
        t.src = "https://www.clarity.ms/tag/" + i;
        y = l.getElementsByTagName(r)[0];
        y.parentNode.insertBefore(t, y);

        return "";
      })(window, document, "clarity", "script", window.__ENV__.CLARITY_KEY);

      head.appendChild(script);

      return () => {
        head.removeChild(script);
      };
    }
  }, []);

  return;
};
