import * as React from "react";
import { Button } from "antd";
import Loader, { LoaderTypes } from "Views/Card/Components/Loader";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import { removeCardRequest } from "Redux/DataCalls/Cards.api";
import { useCardDetailsContext } from "Views/Card/Details/index.context";
import { showSuccessNotification, showErrorNotification } from "Views/Card/Components/Notification";

import styles from "Views/Card/Details/ActionBtns/index.module.scss";

interface IRemoveButtonProps {
  onCardRequestDelete: () => void;
}

export const CardRequestRemoveButton = ({ onCardRequestDelete }: IRemoveButtonProps) => {
  const { cardData } = useCardDetailsContext();
  const { id } = cardData || {};
  const [removeCardRequestInProgress, setRemoveCardRequestInProgress] = React.useState(false);

  const onRemove = () => {
    setRemoveCardRequestInProgress(true);
    removeCardRequest(id)
      .then(({ data }) => {
        const { status_message = "" } = data?.payload || {};
        if (data?.status === HTTP_STATUS_CODE.OK) {
          showSuccessNotification(status_message);
          onCardRequestDelete();
        } else {
          showErrorNotification(status_message);
        }
      })
      .catch((err) => {
        const { status_message = "Something went wrong! Please try again later." } = err?.response?.data?.payload || {};
        showErrorNotification(status_message);
      })
      .finally(() => {
        setRemoveCardRequestInProgress(false);
      });
  };

  return (
    <>
      <Button
        className={`${styles["btn"]} ${styles["transparent-btn__red"]} ${styles["full-width-btn"]}`}
        disabled={removeCardRequestInProgress}
        onClick={onRemove}
        data-testid="remove-btn"
      >
        {removeCardRequestInProgress ? <Loader type={LoaderTypes.red} /> : "Remove"}
      </Button>
    </>
  );
};
