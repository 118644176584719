import React from "react";
import { useSelector } from "react-redux";
import WarningModal from "./index";

const CompleteWithoutSyncWarningModal = ({
  showWarningModal,
  setShowWarningModal,
  loadingUpdateStatus,
  handleProceedWithoutSyncClick,
}: {
  showWarningModal: boolean;
  setShowWarningModal: (_: boolean) => void;
  loadingUpdateStatus: boolean;
  handleProceedWithoutSyncClick: () => void | Promise<void>;
}) => {
  const { data } = useSelector((state: any) => state.accountingPartnerAuthReducer || {});
  const accountingPartnerName = data?.payload?.partnerName;
  return (
    <WarningModal
      visible={showWarningModal}
      onClose={() => setShowWarningModal(false)}
      loading={loadingUpdateStatus}
      message={
        <span>
          Selected transaction(s) will be marked as completed. You will not be able to sync them to{" "}
          {accountingPartnerName} anymore
        </span>
      }
      handleSync={handleProceedWithoutSyncClick}
      buttonText="Yes, Proceed Without Syncing"
      title={`Complete this transaction without syncing to ${accountingPartnerName}?`}
    />
  );
};

export default CompleteWithoutSyncWarningModal;
