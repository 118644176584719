import React, { useMemo } from "react";
import PropTypes from "prop-types";

import Item from "Views/TransactionsListing/Pending/Transaction/Detail/Item";
import { findDynamicFormAlias, findFieldPropsFromAlias, renderBankIdentifierLabel } from "utility/Bills";

const RecipientDynamic = ({ data }) => {
  const findAlias = findDynamicFormAlias(data);
  const findFieldProps = findFieldPropsFromAlias(data);

  const isPayNow = Boolean(findAlias("paynowIdentifierType"));

  const getPayNowField = useMemo(() => {
    let label, value;

    switch (true) {
      case Boolean(findAlias("mobileNumber")): {
        label = "Recipient Mobile Number";
        value = `${findFieldProps("mobileNumber")?.staticPrefix || ""}${findAlias("mobileNumber")}`;
        break;
      }
      case Boolean(findAlias("nric")): {
        label = "Recipient NRIC";
        value = findAlias("nric");
        break;
      }
      case Boolean(findAlias("uen")): {
        label = "Recipient UEN";
        value = findAlias("uen");
        break;
      }
      default: {
        label = "Recipient Bank Account Number";
        value = data?.beneficiary.bankAccountNumber;
      }
    }

    return {
      label,
      value,
    };
  }, [findAlias, data?.beneficiary.bankAccountNumber]);

  return (
    <>
      {data?.beneficiary.legalName && <Item title="Recipient">{data?.beneficiary.legalName}</Item>}
      {data?.beneficiary.name && <Item title="Recipient name">{data?.beneficiary.name}</Item>}
      {data?.beneficiary.bankCountryName && (
        <Item title="Recipient Bank Country">{data?.beneficiary.bankCountryName}</Item>
      )}
      {data?.beneficiary.bankName && <Item title="Recipient Bank">{data?.beneficiary.bankName}</Item>}
      {data?.beneficiary.identifierType && data?.beneficiary.identifierValue && (
        <Item title={renderBankIdentifierLabel(data.beneficiary.identifierType)}>
          {data.beneficiary.identifierValue.toUpperCase()}
        </Item>
      )}
      {data?.beneficiary.additionalIdentifierType && data?.beneficiary.additionalIdentifierValue && (
        <Item title={renderBankIdentifierLabel(data.beneficiary.additionalIdentifierType)}>
          {data.beneficiary.additionalIdentifierValue.toUpperCase()}
        </Item>
      )}
      {isPayNow && <Item title="Recipient PayNow Identifier Type">{findAlias("paynowIdentifierType")}</Item>}
      {getPayNowField.value && <Item title={getPayNowField.label}>{getPayNowField.value}</Item>}
      {data?.beneficiary.email && <Item title="Recipient Email">{data?.beneficiary.email?.replace(/,/g, ", ")}</Item>}
      {findAlias("beneficiaryAddress") && <Item title="Recipient Address">{findAlias("beneficiaryAddress")}</Item>}
      {!data?.beneficiary.additionalIdentifierType && (
        <>{findAlias("beneficiaryCity") && <Item title="Recipient City">{findAlias("beneficiaryCity")}</Item>}</>
      )}
      <Item title="Recipient Bank Account Type">
        {data?.beneficiary.bankCountryName === "Viet Nam" ? "Individual" : findAlias("beneficiaryType") || "-"}
      </Item>
      {data?.beneficiary.remarks && <Item title="Remark for Recipient">{data?.beneficiary.remarks}</Item>}
    </>
  );
};

RecipientDynamic.propTypes = { data: PropTypes.object };

export default RecipientDynamic;
