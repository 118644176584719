import { Rule } from "antd/lib/form";

export enum InputType {
  receiver = "receiver",
  sender = "sender",
}

export interface CurrencyInputProps {
  label: React.ReactElement | string;
  defaultValue?: string;
  amount?: string;
  currency: string;
  country?: string;
  onChangeAmount?(amount: string): void;
  onChangeCurrency?(value: string): void;
  onFocus?(): void;
  selectDisabled?: boolean;
  disabled?: boolean;
  placeholder?: string;
  inputGroupStyle?: Record<string, any>;
  name?: string | string[];
  rules?: Rule[];
}

export enum ExchangeOperator {
  ADDITION = "ADDITION",
  MULTIPLY = "MULTIPLY",
}

export type ExchangeProcessType = {
  label: string;
  labelTooltip?: string;
  operator: keyof typeof ExchangeOperator;
  value: number;
  detail: React.ReactNode | string;
  isLoading?: boolean;
};

export interface InputProps {
  receiverAmount?: string;
  receiverAmountLabel?: React.ReactNode;
  receiverCurrencyCode: string;
  receiverCountry?: string;
  senderAmount?: string;
  senderCurrencyCode: string;
  onChangeReceiverAmount?(amount: string): void;
  onChangeSenderAmount?(amount: string): void;
}

export interface ExchangeInputProps extends InputProps {
  exchangeProcess?: ExchangeProcessType[];
  onChangeFocus?(type: keyof typeof InputType): void;
  onChangeReceiverCurrency?(value: string): void;
  onChangeSenderCurrency?(value: string): void;
  disableYouPay?: boolean;
  disabledReceiverCountrySelection?: boolean;
  disabled?: boolean;
}
