export enum SIDEBAR_MENU_ITEM_TYPE {
  COMPONENT = "COMPONENT",
  NAV_LINK = "NAV_LINK",
  BUTTON = "BUTTON",
}

export enum SUPPORT_STATUS {
  ONLINE = "online",
  OFFLINE = "offline",
}
