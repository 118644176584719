import React, { useState } from "react";

import { Popover } from "antd";
import { OverflowVertical } from "@spenmo/splice";

import { SubscriptionAction } from "Views/SubscriptionManagement/Details/Modal/SubscriptionAction";

import styles from "./index.module.scss";

export const Action = () => {
  const [visible, setVisible] = useState(false);

  return (
    <Popover
      visible={visible}
      onVisibleChange={() => setVisible(!visible)}
      getPopupContainer={(trigger) => trigger.parentElement}
      trigger="click"
      content={<SubscriptionAction closePopover={() => setVisible(false)} />}
      placement="bottom"
      overlayClassName={styles.overlay}
    >
      <div className={styles.pointer} id="trigger">
        <OverflowVertical iconColor="var(--blue-060)" size="16" />
      </div>
    </Popover>
  );
};
