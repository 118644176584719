import { GetReceiptDataAxios } from "Redux/DataCalls/Receipts.api";
import { useQuery } from "utility/useQuery";
import {
  intlDateTimeFormat,
  dateTimeWithTimezone,
  intlTimeFormat,
} from "utility/index";
import { defaultInput } from "Modules/TransactionDetail/TransactionDetail";
import { detailDataMapping } from "./helper";
import {
  EInputType,
  IDetail,
  ITransactionHeaderProps,
} from "Modules/TransactionDetail";
import { useIsSaasWithPaymentsOrg } from "customHooks/useIsSaasWithPaymentsOrg";

export const useTransactionDetail = (
  record: any,
  visible: boolean,
  notRequiredApiType: boolean,
) => {
  const {
    data: { expense },
    loading,
  } = useQuery({
    apiCall: () => GetReceiptDataAxios(record?.transactionNumber, record.id),
    run: visible && !notRequiredApiType,
  });
  const merchantName =
    record?.simplified_merchant_name || record?.merchant || "Fund Transfer";
  const isSaasWithPaymentsOrg = useIsSaasWithPaymentsOrg();

  let cleanExpense = detailDataMapping(expense || {});

  if (notRequiredApiType || !expense?.id) {
    cleanExpense = record;
  }

  const safeCall = (callback, defaultValue) => {
    if (!notRequiredApiType && !expense && !record.id) {
      return () => defaultValue;
    }
    return callback;
  };

  const header: () => ITransactionHeaderProps = safeCall(
    () => ({
      amount: cleanExpense.amount,
      name: merchantName,
      caption: `(Paid amount ${cleanExpense.amount})`,
    }),
    {},
  );

  const detail: () => IDetail[] = safeCall(
    () =>
      [
        {
          id: "DETAIL_001",
          key: "Requester",
          value: record.employee?.name,
          enabled: true,
        },
        {
          id: "DETAIL_002",
          key: "Transaction Number",
          value: record.transactionNumber,
          enabled: true,
        },
        {
          id: "DETAIL_003",
          key: "Created Date & Time",
          value: record.createdAt
            ? `${intlDateTimeFormat(new Date(dateTimeWithTimezone(record.createdAt)))}, ${intlTimeFormat(
                new Date(dateTimeWithTimezone(record.createdAt)),
              )}`
            : "",
          enabled: true,
        },
        {
          id: "DETAIL_004",
          key: "Transaction Type",
          value: record.transactionType,
          enabled: true,
        },
        {
          id: "DETAIL_005",
          key: "Exchange Currency",
          value: record.fxCurrency,
          enabled: isSaasWithPaymentsOrg,
        },
        {
          id: "DETAIL_006",
          key: "Exchange Rate",
          value: record.fxRate,
          enabled: isSaasWithPaymentsOrg,
        },
      ].filter((item) => item.enabled),
    [],
  );

  const defaultValue = safeCall(() => {
    return {
      [EInputType.CATEGORY]: Number(cleanExpense.expense_category_id) || null,
      [EInputType.TRANSACTION_TAGS]: cleanExpense.tags,
      [EInputType.NOTES]: cleanExpense.notes,
      [EInputType.TAX]: cleanExpense?.tax?.id,
      [EInputType.MERCHANT]: merchantName,
      [EInputType.RECEIPT]: cleanExpense?.receipts?.urls || [],
    };
  }, defaultInput);

  return { header, detail, loading, expense, defaultValue, cleanExpense };
};
