import { FETCH_UPLOAD_REQUEST, FETCH_UPLOAD_SUCCESS, FETCH_UPLOAD_FAILURE } from "../Actions/imageUploadAction";
import { userInitialState } from "../InitialState";

export const imageUploadReducer = (state = userInitialState, action) => {
  switch (action.type) {
    case FETCH_UPLOAD_REQUEST:
      return {
        ...state,
        data: action.data,
        loading: true,
        image: null,
      };
    case FETCH_UPLOAD_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
        image: action.image,
      };
    case FETCH_UPLOAD_FAILURE:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: true,
        image: null,
      };
    default:
      return state;
  }
};
