import React from "react";
import styles from "./index.module.scss";
import { tinySuccess } from "assets/img";

const ResetThankYou = ({ redirectionPath }: { redirectionPath?: string }) => {
  return (
    <div className={styles.resetThankYou}>
      <img src={tinySuccess} alt="Success" />
      <p className={styles.resetThankYouMessage}>Your password has been updated</p>
      <p className={styles.resetThankYouSecondaryMessage}>Now you can continue using Spenmo</p>
      <a href={redirectionPath || "/"} className={styles.resetThankYouCta}>
        Continue to Dashboard
      </a>
    </div>
  );
};

export default ResetThankYou;
