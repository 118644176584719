import React from "react";
import { Skeleton } from "antd";

import styles from "./index.module.scss";

const ModalSkeleton = () => {
  return (
    <div className={styles.container}>
      <Skeleton.Input size="large" active />
      <Skeleton.Input size="large" active />
      <Skeleton.Input size="large" active />
      <Skeleton.Input size="large" active />
    </div>
  );
};

export default ModalSkeleton;
