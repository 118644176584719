import React, { useCallback, useEffect, useMemo, useState } from "react";
import classNames from "classnames";

import { SPM_CONTAINER_CONTENT_CLASS } from "Route/@types";

import { useClickOutside } from "utility/Helper/useClickOutside";

import { IGNORE_ELEMENTS } from "./IgnoreElements";
import { applyPositionAlgo } from "./applyPosition";
import { isOutOfParentViewport } from "./isOutOfParentViewport";
import { IPopoverProps, POPOVER_WIDTH, POSITION_ALGO_TYPE } from "./types.d";

import styles from "./Popover.module.scss";

const Popover = ({
  active,
  children,
  className,
  onClickOutside,
  ignoreClickOnElements = [],
  useDynamicPosition = false,
  width = POPOVER_WIDTH.INHERIT,
  positionAlgoType = POSITION_ALGO_TYPE.SIBLING,
  containerClass = `.${SPM_CONTAINER_CONTENT_CLASS}`,
}: IPopoverProps): JSX.Element => {
  const [translateX, setTranslateX] = useState<number>(0);
  const [translateY, setTranslateY] = useState<number>(0);

  const ignoreElements = useMemo(
    () => [`.${styles.container}.${styles[width]}.${className}`, ...IGNORE_ELEMENTS(), ...ignoreClickOnElements],
    [className, ignoreClickOnElements, width]
  );

  const itemRef: React.MutableRefObject<HTMLDivElement> = useClickOutside(
    active ? onClickOutside : null,
    ignoreElements
  );

  const allClassNames = classNames(
    className,
    styles.container,
    styles[width],
    { [styles.active]: active },
    { [styles.hidden]: !active }
  );

  const resize = useCallback(() => {
    if (!itemRef.current || !useDynamicPosition) return;

    const view = isOutOfParentViewport(itemRef.current, containerClass);

    const [x, y] = applyPositionAlgo<HTMLDivElement>(positionAlgoType, view, itemRef.current);

    setTranslateX(x);
    setTranslateY(y);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemRef.current]);

  useEffect(() => {
    if (!active) {
      setTranslateX(0);
      setTranslateY(0);
      return;
    }

    resize();
  }, [active, resize]);

  return (
    <div ref={itemRef} className={allClassNames} style={{ transform: `translate(${translateX}px, ${translateY}px)` }}>
      {active ? children : null}
    </div>
  );
};

export * from "./IgnoreElements";
export * from "./types.d";
export default Popover;
