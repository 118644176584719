import { BillFlowEnum, BillFormFields } from "./type";

export const getRequestPayload = (data) => {
  const formData = new FormData();

  Object.entries(data).forEach(([key, value]: [string, any]) => {
    if (typeof value !== "undefined" && value !== null) {
      // tags still wants to use the old ways (request from the BE)
      if (Array.isArray(value) && key !== "tags") {
        value.forEach((item) => {
          if (typeof item === "object") {
            formData.append(key, JSON.stringify(item));
          }
          formData.append(key, item);
        });
      } else if (typeof value === "object") {
        // hardcode for input type number
        if (key === "billTax" && value.invoiceAmount) {
          value.invoiceAmount = Number(value.invoiceAmount);
        }

        formData.append(key, JSON.stringify(value));
      } else if (["amount", "totalPaidAmount"].includes(key)) {
        // IMPROVEMENT: Should ask PM to implement input type number
        // as input type number and not text
        // Number input type
        value = Number(value);
        formData.append(key, value);
      } else if (key === "paymentScheduleDate") {
        formData.append("paymentScheduleTime", value);
      } else if (key === "ocrID") {
        const deletedAttachments = data?.deletedAttachmentIDs || [];
        if (!deletedAttachments.includes(value)) {
          formData.append(key, value);
        }
      } else {
        formData.append(key, value);
      }
    }
  });

  return formData;
};

export const DEFAULT_VALUES: Partial<BillFormFields> = {
  source: "portal_single",
};

export const billSource = {
  [BillFlowEnum.EDIT_BILL]: "edit_bill",
  [BillFlowEnum.EDIT_DRAFT]: "edit_draft",
};
