import React from "react";

import { putData } from "API/Client";

import { API_URL } from "Views/Reimbursement/DataCalls/Services";
import { appNotification, contactCSErrorMessage } from "Modules/appNotification/appNotification";
import {
  REIMBURSEMENT_PAGE_OPERATIONS,
  REIMBURSEMENT_SETTLEMENT_STATUSES,
  REIMBURSEMENT_VIEWS,
} from "Views/Reimbursement/Constants";
import { TEAM_PATHS } from "Route/Team/paths";

const useMarkAsSettled = (): {
  markAsSettledInBulk: (ids: string[]) => Promise<void>;
} => {
  const markAsSettledInBulk = async (ids: string[]) => {
    try {
      const response = await putData(API_URL.DETAILS.BULK_MARK_AS_SETTLED, { reimbursementIDs: [...ids] });

      const { message, overlimitBudgetNames = [] } = response.data.payload;

      setTimeout(() => {
        appNotification.success({
          message,
          cta: "View items",
          onClickCTA: () => {
            const link = document.createElement("a");
            link.target = "_blank";
            link.href = `${REIMBURSEMENT_VIEWS.COMPANY_REIMBURSEMENTS.link}/${REIMBURSEMENT_PAGE_OPERATIONS.SETTLEMENTS}/${REIMBURSEMENT_SETTLEMENT_STATUSES.SETTLED_FOR_SETTLEMENT_VIEW.id}`;
            link.click();
            link.remove();
          },
        });

        if (overlimitBudgetNames.length) {
          appNotification.success({
            message: (
              <p>Kindly review the {overlimitBudgetNames.join(" and ")} budgets as they have exceeded the limit.</p>
            ),
            cta: "View budgets",
            onClickCTA: () => {
              const link = document.createElement("a");
              link.target = "_blank";
              link.href = `${TEAM_PATHS.TEAMS}`;
              link.click();
              link.remove();
            },
          });
        }
      }, 6000);
    } catch (err) {
      setTimeout(() => {
        appNotification.error(contactCSErrorMessage(err.response.data.error.code));
      }, 6000);
    }
  };

  return {
    markAsSettledInBulk,
  };
};

export default useMarkAsSettled;
