import React from "react";

import { FlatIcon } from "assets/v1.1/icons";
import styles from "./EmptyState.module.scss";
import classNames from "classnames";

export const EmptyState = ({ message = "No Result Found" }: { message?: string }): JSX.Element => {
  return (
    <div className={classNames(styles.container, "spm-menu-empty-state")}>
      <img src={FlatIcon.emptyState} alt={message} />
      <div className={styles.text}>{message}</div>
    </div>
  );
};
