import APIclient from "API/Client";

export const EDIT_SCHEDULED_TRANSACTION_REQUEST = "EDIT_SCHEDULED_TRANSACTION_REQUEST";
export const EDIT_SCHEDULED_TRANSACTION_SUCCESS = "EDIT_SCHEDULED_TRANSACTION_SUCCESS";
export const EDIT_SCHEDULED_TRANSACTION_FAILURE = "EDIT_SCHEDULED_TRANSACTION_FAILURE";
export const CLEAR_EDIT_SCHEDULED_TRANSACTION = "CLEAR_EDIT_SCHEDULED_TRANSACTION";

const editScheduledTransactionReq = (data) => {
  return {
    type: EDIT_SCHEDULED_TRANSACTION_REQUEST,
    data,
  };
};
const editScheduledTransactionSuccess = (data) => {
  return {
    type: EDIT_SCHEDULED_TRANSACTION_SUCCESS,
    data,
  };
};
const editScheduledTransactionError = (data) => {
  return {
    type: EDIT_SCHEDULED_TRANSACTION_FAILURE,
    data,
  };
};

export const clearEditScheduledTransaction = () => {
  return {
    type: CLEAR_EDIT_SCHEDULED_TRANSACTION,
  };
};

export const editScheduledTransactionFunc = (id, payload) => {
  return (dispatch) => {
    dispatch(editScheduledTransactionReq({ trxId: id }));

    APIclient.putData(`/ms/spm-organization/v1/allocation-job/${id}`, payload)
      .then((data) => {
        dispatch(editScheduledTransactionSuccess(data.data));
      })
      .catch((err) => {
        const error = {
          message: err?.response?.data?.error?.message,
          status: err?.response?.status,
        };
        dispatch(editScheduledTransactionError(error));
      });
  };
};
