const fill_16 = require("assets/v1.1/icons/Core/Calendar/fill_16.svg");
const fill_24 = require("assets/v1.1/icons/Core/Calendar/fill_24.svg");

const line_16 = require("assets/v1.1/icons/Core/Calendar/line_16.svg");
const line_24 = require("assets/v1.1/icons/Core/Calendar/line_24.svg");

export const Calendar = {
  Line: { 16: line_16, 24: line_24 },
  Fill: { 16: fill_16, 24: fill_24 },
};
