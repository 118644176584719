import React, { useEffect, useRef, useState } from "react";

import "./index.scss";
import { AppDispatch, useAppSelector } from "../../../../hook";
import { useDispatch } from "react-redux";
import Searchbar from "Modules/search";
import { debounce } from "utility";
import {
  applyFilter,
  selectActiveTab,
  fetchApprovalRequestList,
  selectSearchValue,
  setCurrentPage,
} from "Redux/Reducers/Approval/ApprovalCenter/approvalCenter.slice";
import { DEBOUNCE_DELAY_TIME } from "constants/ApprovalRequest.constants";
import { TAB_KEY } from "Views/Approval/ApprovalCenter/types";

const Search = () => {
  const dispatch: AppDispatch = useDispatch();
  const search = useAppSelector(selectSearchValue);
  const currentSearch = useRef(search);
  const [clearSearch, setClearSearch] = useState(false);
  const activeTab: TAB_KEY = useAppSelector(selectActiveTab);

  const handleSearch = debounce((value: string) => {
    dispatch(setCurrentPage(1));
    dispatch(applyFilter({ search: value }));
    dispatch(fetchApprovalRequestList());
  }, DEBOUNCE_DELAY_TIME);

  useEffect(() => {
    // need to clear the search text box when search change from non empty to empty
    if (currentSearch.current && !search) {
      // search is the new value, currentSearch.current is the previous one
      setClearSearch(true);
    } else {
      setClearSearch(false);
    }
    currentSearch.current = search; // update the ref value
  }, [search]);

  const placeholder = React.useMemo(() => {
    switch (activeTab) {
      case TAB_KEY.INVOICE:
        return "Search for Requester/Recipient/Invoice Number";
      case TAB_KEY.CARD:
        return "Search for Requester/Budget";
      case TAB_KEY.REIMBURSEMENT:
        return "Search for Requester/Budget/Merchant/Category";
      case TAB_KEY.FUND:
        return "Search for Requester";
      default:
        return "Search";
    }
  }, [activeTab]);

  return (
    <div className="approval-action__search">
      <Searchbar getKeyUpValue={handleSearch} clearSearch={clearSearch} placeholder={placeholder} />
    </div>
  );
};

export default Search;
