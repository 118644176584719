import React, {
  ChangeEventHandler,
  FocusEventHandler,
  useEffect,
  useState,
} from "react";
import cn from "classnames";
import { useDispatch } from "react-redux";
import { Form } from "antd";
import { Redirect } from "react-router-dom";

import { Typography, Button, Input, Checkbox } from "@spenmo/splice";
import LoadingComponent from "Views/State/Loading/LoaderIcon";
import { BodySkeleton } from "Views/Settings/components";
import { useAnalytic } from "Views/Settings/hooks";
import {
  getLowBalanceActionFunc,
  updateLowBalanceActionFunc,
} from "Redux/Actions/lowBalanceReminderAction";
import { LOADING_STATE } from "constants/Loading";
import { GetCurrencyCode } from "utility";
import { useAppSelector } from "hook";
import { trackEvent } from "utility/analytics";
import usePermissionCheck from "Permission/usePermissionCheck";
import { CASBIN_PAGES } from "Permission/Pages";
import { LOW_BALANCE_NOTIFICATION_PAGE } from "Permission/Actions";
import { useIsSaasWithPaymentsOrg } from "customHooks/useIsSaasWithPaymentsOrg";
import { useIsSessionExpired } from "customHooks/Auth/useIsSessionExpired";
import {
  hasDecimalPointer,
  moneyFormat,
  replaceThousandIndicator,
} from "./helper";
import { COMMON_PATHS } from "Route/Common/paths";

import styles from "./styles.module.scss";

interface ILowBalanceReminderProps {
  newSettingPage?: boolean;
}

const LowBalanceReminder = ({
  newSettingPage = false,
}: ILowBalanceReminderProps) => {
  const permissionParam = [
    {
      object: CASBIN_PAGES.LOW_BALANCE_NOTIFICATION_PAGE,
      action: LOW_BALANCE_NOTIFICATION_PAGE.SAVE_BUTTON,
    },
  ];
  const [hasChanged, setHasChanged] = useState(false);
  const IS_ALLOWED_SAVE_BUTTON_ACTION = usePermissionCheck(permissionParam);
  const [form] = Form.useForm();
  const [enabledNotification, setEnabledNotification] = useState(false);
  const [balanceOnBlurValue, setBalanceOnBlurValue] = useState("");
  const { wallet, lowBalanceSettings, loading } = useAppSelector((state) => {
    return {
      wallet: state.wallet.data,
      lowBalanceSettings: state.lowBalanceReminderReducer.data,
      loading: state.lowBalanceReminderReducer.loading,
    };
  });
  const dispatch = useDispatch();
  const isSaasWithPaymentsOrg = useIsSaasWithPaymentsOrg();
  const isSessionExpired = useIsSessionExpired();
  const isUpdateLowBalanceLoading = loading === LOADING_STATE.UPDATE;
  useAnalytic({
    eventSource: "Company",
    eventSubSource: "Low Balance Reminder",
  });

  useEffect(() => {
    dispatch(getLowBalanceActionFunc());
  }, []);

  useEffect(() => {
    setEnabledNotification(lowBalanceSettings.enabled);
  }, [lowBalanceSettings]);

  const onFinish = (values) => {
    const formData: { enabled: boolean; amount?: string } = {
      enabled: enabledNotification,
    };

    if (values.amount) {
      formData.amount = replaceThousandIndicator(
        values.amount,
        wallet.currency_code,
      );
    }
    dispatch(updateLowBalanceActionFunc(formData));
    trackEvent("low balance reminder save clicked");
  };

  const handleAmount: ChangeEventHandler<HTMLInputElement> = (e) => {
    const amount = e.target.value;
    let value = moneyFormat(amount, wallet.currency_code);

    // Skip saving the formated amount, but use the raw amount to indicate want to use decimal value
    if (hasDecimalPointer(amount)) {
      value = amount;
    }

    form.setFieldsValue({ amount: value });
    const newValue = replaceThousandIndicator(amount, wallet.currency_code);
    setHasChanged(newValue !== lowBalanceSettings.amount);
  };

  const trackOnBlurInputAction: FocusEventHandler<HTMLInputElement> = () => {
    const currentValue = form?.getFieldValue?.("amount");
    currentValue &&
      currentValue !== balanceOnBlurValue &&
      trackEvent("low balance reminder amount added");
    setBalanceOnBlurValue(currentValue);
  };

  if (!isSaasWithPaymentsOrg && !isSessionExpired) {
    return <Redirect to={COMMON_PATHS.PAGE_NOT_FOUND} />;
  }

  if (loading === LOADING_STATE.GET)
    return newSettingPage ? <BodySkeleton paddingLess /> : <LoadingComponent />;

  return (
    <Form form={form} onFinish={onFinish}>
      <section
        className={cn({
          section: !newSettingPage,
          [styles.container]: !newSettingPage,
        })}
      >
        {!newSettingPage && <div className={"section__header"}>Settings</div>}
        <div className={cn({ section__body: !newSettingPage })}>
          <Typography
            size="m"
            variant="headline-content"
            tag="h3"
            className={styles.header}
          >
            Low Balance Reminder
          </Typography>
          <Typography
            tag="p"
            size="m"
            variant="body-content"
            className={styles.caption}
          >
            Receive an email or a notification on your Spenmo mobile app when
            your account balance changes
          </Typography>
          <div className={cn("section__body-subtitle", styles.marginBottom)}>
            <Checkbox
              checked={enabledNotification}
              onClick={() => {
                const newValue = !enabledNotification;
                setEnabledNotification(newValue);
                setHasChanged(newValue !== lowBalanceSettings.enabled);
              }}
            >
              <Typography
                tag="p"
                size="m"
                variant="body-content"
                className={styles.checkboxTitle}
              >
                Email Notification
              </Typography>
            </Checkbox>
          </div>

          {enabledNotification && (
            <div className={styles.amount}>
              <Input.Label
                variant="required"
                htmlFor="amount"
                className={styles.label}
              >
                Amount Threshold
              </Input.Label>
              <Form.Item
                name="amount"
                initialValue={moneyFormat(
                  lowBalanceSettings?.amount || "",
                  wallet.currency_code || GetCurrencyCode(),
                )}
              >
                <Input.Text
                  name="amount"
                  hint="All administrators will be notified when balance drops below this entered amount"
                  type="text"
                  className={styles.input}
                  onChange={handleAmount}
                  onBlur={trackOnBlurInputAction}
                  autoComplete="off"
                />
              </Form.Item>

              <Typography
                tag="p"
                size="m"
                variant="body-content"
                className={styles.currency}
              >
                {wallet.currency_code || GetCurrencyCode()}
              </Typography>
            </div>
          )}

          {IS_ALLOWED_SAVE_BUTTON_ACTION && (
            <Form.Item shouldUpdate>
              <Button
                loading={isUpdateLowBalanceLoading}
                variant="primary"
                type="submit"
                className={styles.submitButton}
                disabled={!hasChanged}
              >
                Save Changes
              </Button>
            </Form.Item>
          )}
        </div>
      </section>
    </Form>
  );
};

export default LowBalanceReminder;
