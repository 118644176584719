import {
  FETCH_GA_QR_CODE_REQUEST,
  FETCH_GA_QR_CODE_SUCCESS,
  FETCH_GA_QR_CODE_FAILURE,
  VERIFY_GA_AUTHENTICATION_KEY_REQUEST,
  VERIFY_GA_AUTHENTICATION_KEY_SUCCESS,
  VERIFY_GA_AUTHENTICATION_KEY_FAILURE,
  GA_RESET,
} from "Redux/Actions/SecurityHub/GoogleAuthenticator/ActivationActions";
import { userInitialState } from "Redux/InitialState";

export const gaActivationReducer = (state = userInitialState, action) => {
  switch (action.type) {
    case FETCH_GA_QR_CODE_REQUEST:
      return {
        ...state,
        fetchGaQrCodeLoading: true,
        fetchGaQrCodeData: action.data,
        fetchGaQrCodeError: false,
      };
    case FETCH_GA_QR_CODE_SUCCESS:
      return {
        ...state,
        fetchGaQrCodeLoading: false,
        fetchGaQrCodeData: action.data,
        fetchGaQrCodeError: false,
      };
    case FETCH_GA_QR_CODE_FAILURE:
      return {
        ...state,
        fetchGaQrCodeLoading: false,
        fetchGaQrCodeData: action.data,
        fetchGaQrCodeError: true,
      };
    case VERIFY_GA_AUTHENTICATION_KEY_REQUEST:
      return {
        ...state,
        verifyGaAuthenticationKeyLoading: true,
        verifyGaAuthenticationKeyData: action.data,
        verifyGaAuthenticationKeyError: false,
      };
    case VERIFY_GA_AUTHENTICATION_KEY_SUCCESS:
      return {
        ...state,
        verifyGaAuthenticationKeyLoading: false,
        verifyGaAuthenticationKeyData: action.data,
        verifyGaAuthenticationKeyError: false,
      };
    case VERIFY_GA_AUTHENTICATION_KEY_FAILURE:
      return {
        ...state,
        verifyGaAuthenticationKeyLoading: false,
        verifyGaAuthenticationKeyData: action.data,
        verifyGaAuthenticationKeyError: true,
      };
    case GA_RESET:
      return {
        fetchGaQrCodeLoading: false,
        fetchGaQrCodeData: {},
        fetchGaQrCodeError: false,
      };
    default:
      return state;
  }
};
