import React, { useMemo } from "react";
import { Tags, Typography } from "@spenmo/splice";

import { useMutableData } from "API/useData";

import { PRODUCT_NAME } from "Redux/ModularProduct";
import useCheckOrgConfigs from "customHooks/useCheckOrgConfigs";

import { CASBIN_PAGES } from "Permission/Pages";
import { BILL_EXPORT_CSV } from "Permission/Actions";
import usePermissionCheck from "Permission/usePermissionCheck";

import { API_URL } from "Views/Bills/V2/constants";
import { SaaSConfig } from "Views/Bills/V2/BillForm/type";
import { IUseBillsExportOptionList } from "Views/Bills/V2/@types";

import styles from "./index.module.scss";

export const FULL_CSV = {
  id: "BILLS_EXPORT_OPTION_FULL_CSV",
  title: "Full CSV",
  description:
    "Contains all details stored in Spenmo. Use this format for reporting or further analysis.",
};

export const GENERIC_PAYMENT_CSV = {
  id: "BILLS_EXPORT_OPTION_GENERIC_PAYMENT_CSV",
  title: "Generic Payment CSV",
  description:
    "Contains payment details for initiating payouts with your preferred banks or financial services.",
  bottom: () => {
    return (
      <div className={styles.generic}>
        <Typography size="s" variant="body-content">
          Only
        </Typography>
        <Tags.Progression hierarchy="default" label="Approved" variant="info" />
        <Typography size="s" variant="body-content">
          bills are exported
        </Typography>
      </div>
    );
  },
};

const permissionParam = [
  {
    object: CASBIN_PAGES.BILL_EXPORT_CSV,
    action: BILL_EXPORT_CSV.GENERIC_DOWNLOAD_SAAS_PAYMENTS,
  },
  {
    object: CASBIN_PAGES.BILL_EXPORT_CSV,
    action: BILL_EXPORT_CSV.GENERIC_DOWNLOAD_SAAS,
  },
  {
    object: CASBIN_PAGES.BILL_EXPORT_CSV,
    action: BILL_EXPORT_CSV.FULL_DOWNLOAD_SAAS_PAYMENTS,
  },
  {
    object: CASBIN_PAGES.BILL_EXPORT_CSV,
    action: BILL_EXPORT_CSV.FULL_DOWNLOAD_SAAS,
  },
];

const useExportOptionsList = (): IUseBillsExportOptionList => {
  const { data: saasConfigResponse } = useMutableData(API_URL.saasConfig);
  const saasConfig: SaaSConfig = useMemo(
    () => saasConfigResponse?.data?.payload,
    [saasConfigResponse?.data?.payload],
  );
  const isBillsPaymentEnabled = saasConfig?.isPaymentEnabled;

  const [isBPConfigEnabled, loadingBPConfig] = useCheckOrgConfigs(
    PRODUCT_NAME.BILLPAY,
    true,
  ) as [boolean, boolean];

  const [permissions, loadingPermissions] = usePermissionCheck(
    permissionParam,
    true,
  ) as [[boolean, boolean, boolean, boolean], boolean];

  const isGenericCsvAllowed = isBillsPaymentEnabled
    ? permissions?.[0]
    : permissions?.[1];
  const isFullCsvAllowed = isBillsPaymentEnabled
    ? permissions?.[2]
    : permissions?.[3];

  const list = [
    {
      id: GENERIC_PAYMENT_CSV.id,
      title: GENERIC_PAYMENT_CSV.title,
      description: GENERIC_PAYMENT_CSV.description,
      bottom: GENERIC_PAYMENT_CSV.bottom,
      iconColor: "#797979",
      enabled: isGenericCsvAllowed,
    },
    {
      id: FULL_CSV.id,
      title: FULL_CSV.title,
      description: FULL_CSV.description,
      enabled: isFullCsvAllowed,
    },
  ].filter((item) => item.enabled);

  return {
    list,
    loading: loadingPermissions || loadingBPConfig || !isBPConfigEnabled,
  };
};

export default useExportOptionsList;
