import React from "react";
import { Divider } from "antd";
import Button from "/Modules/button";
import { ModalComponent } from "Modules/DS/Modal";
import { greyCloseIcon, infoIcon } from "assets/img";
import { useMoveTeamMemberContext } from "../MoveTeamMember/context";
import { trackEvent } from "utility/analytics";
import { MoveTeamMemberEvent } from "Views/Team/Analytics";

const { CloseButton, Title } = ModalComponent;

const MoveTeamWarning = () => {
  const { employeeName, user, handleClose } = useMoveTeamMemberContext();
  const handleOnClick = () => {
    handleClose();
    trackEvent(MoveTeamMemberEvent.MOVE_TO_TEAM_ONLY_MANAGER, { "moved user id": user.id });
  };
  return (
    <>
      <CloseButton src={greyCloseIcon} />
      <div className="move-team-member__error">
        <Title>{`You are not allowed to move ${employeeName}`}</Title>
        <Divider />
        <div className="move-team-member__error-content">
          <div className="move-team-member__error-header">
            <img className="move-team-member__error-icon" src={infoIcon} alt="" /> {employeeName} is the only budget
            owner in the budget
          </div>
          <span className="move-team-member__error-text">
            A budget needs to have at least one owner. Please set another member as a budget owner on the Budget Profile
            page before moving {employeeName}.
          </span>
        </div>
        <Button text="Close" rounded action={handleOnClick} />
      </div>
    </>
  );
};

export default MoveTeamWarning;
