import { GetFormData, urlParamsBuilder } from "utility/APIWrapper";
import APIclient from "API/Client";

export const getPartnerTaxes = (importMissingTaxes = false) => {
  return APIclient.getData(
    urlParamsBuilder("/ms/b2b-accounting/v2/partners/taxes", {
      page: 1,
      limit: 1000,
      unImported: importMissingTaxes,
    })
  );
};

export const createTaxFunc = (values) => {
  return APIclient.postData("/api/v1/tax/", GetFormData({ ...values }));
};

export const createBulkTaxFunc = (values) => {
  return APIclient.postData("/api/v1/tax/bulk", values);
};

export const editTaxFunc = (values, taxId) => {
  return APIclient.putData(`/api/v1/tax/${taxId}`, values);
};

export const getLastAccountingEvent = (eventType = "import_tax") => {
  return APIclient.getData(`/ms/b2b-accounting/v1/accounting-event/last?eventType=${eventType}`);
};
