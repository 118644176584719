import React from "react";
import {
  RequestPhysicalCardConfig,
  RequestVirtualCardConfig,
  CreateVirtualCardConfig,
} from "Views/Card/CreateOrRequest/Utilities/Config";
import { CARD_TYPE } from "Views/Card/types";

interface ConfigType {
  Component: React.JSX.Element;
}

const addStepNumbers = (config: Array<ConfigType>) => {
  return config?.map((step, index) => ({
    ...step,
    stepNumber: index + 1,
  }));
};

const ConfigBuilder = (
  isManagerOrAdmin: boolean,
  cardType: CARD_TYPE,
  isTeamSelected: boolean,
  isManagerInSelectedTeam: boolean
) => {
  const isManager = (isTeamSelected && isManagerInSelectedTeam) || (isManagerOrAdmin && !isTeamSelected);
  if (cardType === CARD_TYPE.PHYSICAL) {
    return addStepNumbers([...CreateVirtualCardConfig(), ...RequestPhysicalCardConfig(isManager)]);
  } else if (isManager) {
    return addStepNumbers([...CreateVirtualCardConfig()]);
  } else {
    return addStepNumbers([...CreateVirtualCardConfig(), ...RequestVirtualCardConfig()]);
  }
};

export default ConfigBuilder;
