import React, { useContext } from "react";
import classNames from "classnames";
import { ArrowLeft } from "@spenmo/splice";
import { useHistory } from "react-router-dom";

import useLogout from "utility/useLogout";
import { setCookie, cookieNames, getCookieValue } from "utility/CookieHelper";

import { Col, Row } from "Splice/Grid";

import Icon from "Modules/icons";

import { KYXModalContext } from "Views/KYX/Context/Modal";
import { KYX_MODAL_TYPES } from "Views/KYX/Constants/Modal";
import { ManualFormContext } from "Views/KYX/Context/ManualForm";
import { useIsSaasWithPaymentsOrg } from "customHooks/useIsSaasWithPaymentsOrg";
import { IKYXModalContext, IManualFormContext, IPreliminaryData } from "Views/KYX/@types";
import { RootState } from "Redux/ConfigureStore";
import { useAppSelector } from "hook";
import { KYB_STATUS } from "Views/KYX/Constants";
import { SignOut } from "assets/v1.1/icons/Core/SignOut";
import { spenmoOrangeLogoLargeWithText } from "assets/v1.1/logo/spenmo";

import styles from "./index.module.scss";

interface KYXHeaderProps {
  infoTitle?: string;
  showLogout?: boolean;
}

const KYXHeader = ({ infoTitle = "", showLogout = true }: KYXHeaderProps): React.ReactElement => {
  const logout = useLogout();
  const history = useHistory();

  const { setModal }: IKYXModalContext = useContext(KYXModalContext);
  const { isFormModified }: IManualFormContext = useContext(ManualFormContext);

  const preliminaryData: IPreliminaryData = useAppSelector(
    (state: RootState) => state.preliminaryDataReducer?.data?.payload
  );
  const kyb_status: KYB_STATUS = preliminaryData?.kyb_status;
  const isOrgBlocked: boolean = preliminaryData?.is_org_blocked;

  const isRekybRequired: boolean = getCookieValue(cookieNames.IS_REKYB_REQUIRED) === "true";

  // We are checking if the KYB on-demand split flag is enabled or not and displaying the "Go to Dashboard" button.
  // After the existing orgs soft deletion, this flag is removed, and we check based on the KYB status.

  const isSaasWithPaymentsOrg = useIsSaasWithPaymentsOrg();
  const isGoToDashboardDisabled = [KYB_STATUS.REJECTED, KYB_STATUS.IN_REVIEW].includes(kyb_status);

  const showGoToDashboard =
    (isRekybRequired &&
      preliminaryData &&
      ![KYB_STATUS.STARTED, KYB_STATUS.NOT_STARTED].includes(kyb_status) &&
      !isOrgBlocked) ||
    !isSaasWithPaymentsOrg;

  const handleLogout = () => {
    const isSessionExpired = getCookieValue(cookieNames.EXPIRED) === "true";
    if (isFormModified && !isSessionExpired) {
      setModal({ visible: true, type: KYX_MODAL_TYPES.SAVE_AS_DRAFT_LOGOUT_MODAL, payload: {} });
    } else {
      return logout();
    }
  };

  const handleDashboard = () => {
    history.push("/");
    kyb_status === KYB_STATUS.REJECTED && setCookie(cookieNames.SHOW_REKYB_MODAL, true);
  };

  return (
    <Row className={styles.header} align="end" justify="space-between">
      <Col className={classNames(styles.alignInline, styles.details)} span={12}>
        <Icon src={spenmoOrangeLogoLargeWithText} alt="spenmo logo" />
        <span>|</span>
        <p className={styles.tagLine}>
          {Boolean(infoTitle) ? "Joint Applicant Data Verification" : "Company Verification Process"}
        </p>
      </Col>
      <Col className={classNames(styles.alignInline, styles.details)} span={12}>
        {showLogout && isSaasWithPaymentsOrg && (
          <button data-testid="logoutLink" className={styles.link} onClick={handleLogout}>
            <Icon src={SignOut.Line[24]} />
            <p className={styles.logOut}>Log out</p>
          </button>
        )}
        {showGoToDashboard && (
          <>
            {isSaasWithPaymentsOrg && <span>|</span>}
            {!isGoToDashboardDisabled && (
              <button data-testid="dashboardLink" className={styles.link} onClick={handleDashboard}>
                <p className={styles.dashboard}>Go to Dashboard</p>
                <ArrowLeft iconColor="var(--icon-default)" size="24" className={styles.arrowRight} />
              </button>
            )}
          </>
        )}
      </Col>
    </Row>
  );
};

export default KYXHeader;
