import * as React from "react";
import { Button } from "antd";
import { blackCrossIcon24x24 } from "assets/icons/card";
import Loader, { LoaderTypes } from "Views/Card/Components/Loader";
import { DEFAULT_LIMIT_VALUES } from "constants/Team.constant";
import { CardRequestDetailsType } from "Views/Card/Review/types";
import { CARD_LIMIT_TYPES } from "Views/Card/types";

export interface IActionBtnsProps {
  onDecline: () => void;
  onApprove: (payload) => void;
  disableApproveBtn: boolean;
  cardDetails: CardRequestDetailsType;
  approvalInProgress: boolean;
}

const ActionBtns = ({ cardDetails, onDecline, disableApproveBtn, approvalInProgress, onApprove }: IActionBtnsProps) => {
  const [showConfirmToApprove, setShowConfirmToApprove] = React.useState(false);
  const onApproveClick = () => {
    const merchantLimits = cardDetails?.merchantLock?.map?.((merchant) => ({ merchant_name: merchant }));
    const defaultLimitsToSend: { [key: string]: number | string } = {
      monthly_limit: DEFAULT_LIMIT_VALUES.REVERT_THE_LIMIT,
      yearly_limit: DEFAULT_LIMIT_VALUES.REVERT_THE_LIMIT,
      total_limit: DEFAULT_LIMIT_VALUES.REVERT_THE_LIMIT,
    };
    if (cardDetails.cardLimitType !== CARD_LIMIT_TYPES.unlimited) {
      defaultLimitsToSend[cardDetails.cardLimitType] = cardDetails.cardLimit;
    }
    onApprove({
      card_name: cardDetails.cardTitle,
      name: cardDetails.cardTitle,
      merchant_limits: JSON.stringify(merchantLimits),
      expense_category_id: cardDetails.expenseCategoryId || undefined,
      tags: cardDetails.tags,
      ...defaultLimitsToSend,
    });
  };

  const getConfirmToApprove = () => {
    if (approvalInProgress) {
      return <Loader type={LoaderTypes.white} />;
    }
    return "Confirm to Approve";
  };
  return (
    <div className="review__action-btns-container">
      {showConfirmToApprove ? (
        <>
          <Button
            onClick={onApproveClick}
            className="review__action-btn review__action-btn--confirm confirm-to-approve"
            disabled={approvalInProgress}
          >
            {getConfirmToApprove()}
          </Button>
          <img
            src={blackCrossIcon24x24}
            className="review__action-btn__cross"
            height={24}
            width={24}
            onClick={() => setShowConfirmToApprove(false)}
            alt="Cross Icon"
          />
        </>
      ) : (
        <>
          <Button className="review__action-btn review__action-btn--decline" onClick={onDecline}>
            Decline
          </Button>
          <Button
            className="review__action-btn review__action-btn--confirm"
            disabled={disableApproveBtn}
            onClick={() => setShowConfirmToApprove(true)}
          >
            Approve
          </Button>
        </>
      )}
    </div>
  );
};

export default ActionBtns;
