/*
    Unauthenticated Paths
*/
export const LOGIN_PATH = "/login";
export const LOGOUT_PATH = "/logout";
export const EMPLOYEE_OTP_PATH = "/otp";
export const FORGOT_PASSWORD_PATH = "/forgot";
export const EMPLOYEE_UPLOAD_ID_PATH = "/onboarding/verification";
export const FORGOT_PASSWORD_OTP = "/forgotpassword-otp";
export const CHANGE_PASSWORD = "/changepassword";

/*
    Empty State Paths
*/
export const FOUR_O_FOUR_ERROR_PATH = "/404";

/*
    Coming Soon Paths
*/
export const NOTIFICATION_PATH = "/notification";

/*
    Admin Only Paths
*/
export const TOP_UP_PATH = "/topup";

/*
    Authenticated Paths
*/
export const DASHBOARD_PATH = "/";
export const CARDS_PATH = "/cards";
export const TRANSACTIONS_PATH = "/transactions";
export const EMPLOYEES_PATH = "/employee";
export const ADD_EMPLOYEES_PATH = EMPLOYEES_PATH + "/add";
export const REQUESTS_PATH = "/reimbursements";
export const SUBSCRIPTIONS = "/subscriptions";

/// Teams Paths
export const TEAMS_URL_PARAMS = {
  SOLE_MANAGER: "sole_manager",
  MEMBER_ID: "user_id",
  MEMBER_NAME: "user_name",
  TEAM_ID: "id",
};

export const TEAMS_PATH = "/teams";
export const CREATE_TEAM_PATH = "/create-team";
export const PROFILE_UPDATE_PATH = "/profileupdate";
export const TEAM_PROFILE_PATH = "/teamprofile";
export const TEAM_PROFILE_PATH_WITH_ID = `/teamprofile/:${TEAMS_URL_PARAMS.TEAM_ID}`;

export const TEAMS_PATH_WITH_QUERY = {
  [TEAMS_URL_PARAMS.SOLE_MANAGER]: (id?: string, name?: string) =>
    `${TEAMS_PATH}?${TEAMS_URL_PARAMS.SOLE_MANAGER}=true&${id ? TEAMS_URL_PARAMS.MEMBER_ID + "=" + id : ""}&${
      name ? TEAMS_URL_PARAMS.MEMBER_NAME + "=" + name : ""
    }`,
};

export const CATEGORIES_PATH = "/settings/category";
export const ACCOUNT_INTEGRATIONS_PATH = "/settings/integration";
export const BANK_ACCOUNTS_PATH = "/bankaccount";
export const TAXES_PATH = "/settings/tax";
export const RESET_PASSWORD_PATH = "/resetpassword";
export const XERO_SETUP_PATH = "/xero/account";
export const LOW_BALANCE_REMINDER_PATH = "/lowbalancereminder";
export const PAY_BILLS_PATH = "/paybills";
export const SECURITY_HUB_PATH = "/security-hub";
export const ACTIVATE_GA_PATH = "/activate-ga";
export const BILLS_PATH = "/bills";
export const BILLS_PATH_UPLIFTING = "/bills/submitted";
export const PAYMENT_RUN_PATH = "/bills/payment-run";
export const UPLOAD_INVOICE_PATH = "/bills/create";
export const BULK_UPLOAD_BILLS_PATH = "/bills/bulk";
export const SUCCESS_PATH = "/success";
export const ERROR_PATH = "/error";
export const APPROVAL_FLOW_PATH = "/approvalflow";
export const APPROVAL_REQUEST_CENTER = "/approvalRequestCenter";
export const ACCOUNTING_TRANSACTIONS_WORKFLOW = "/transactions/accounting";
export const ACCOUNTING_WORKFLOW = "/accounting";
export const ANALYTICS_PATH = "/analytics";
export const REPORTS_PATH = "/reports";

/*
Onboarding Paths
*/
export const ONBOARDING_PATH = "/onboard";
export const ONBOARDING_STEP = "#step";
export const ONBOARDING_PATH_WITH_STEP = ONBOARDING_PATH + ONBOARDING_STEP;
