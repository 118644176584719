import { AxiosResponse } from "axios";
import useSWR, { SWRConfiguration } from "swr";

import { getData } from "API/Client";

import { PRODUCT_NAME } from "Redux/ModularProduct";

import useCheckOrgConfigs from "customHooks/useCheckOrgConfigs";
import { getCookieValue, cookieNames } from "utility/CookieHelper";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";

import {
  appNotification,
  contactCSErrorMessage,
} from "Modules/appNotification/appNotification";

import { API_URL } from "Views/Reimbursement/DataCalls/Services";
import usePermissionCheck from "Permission/usePermissionCheck";
import { CASBIN_PAGES } from "Permission/Pages";
import { REIMBURSEMENT_TRANSACTION_PAGE } from "Permission/Actions";

// Custom hook to fetch payout details exist for the user
const usePayoutValidation = () => {
  const isCrConfigEnabled = useCheckOrgConfigs(
    PRODUCT_NAME.CASH_REIMBURSEMENT,
    true,
  ) as boolean;
  const [isAllowedToCreateCR = false, loadingPermission] = usePermissionCheck(
    [
      {
        object: CASBIN_PAGES.REIMBURSEMENT_TRANSACTION_PAGE,
        action: REIMBURSEMENT_TRANSACTION_PAGE.CREATE,
      },
    ],
    true,
  ) as [boolean, boolean];

  // Define keys for SWR cache
  const keys: [string, string] = [
    isCrConfigEnabled ? API_URL.LIST.BANK_ACCOUNT_INFO() : null,
    getCookieValue(cookieNames.AUTH_TOKEN),
  ];

  // Configuration options for useSWR hook
  const config: SWRConfiguration<AxiosResponse<any>> = {
    revalidateOnFocus: false,
    onSuccess: (response) => {
      if (response.data.status !== HTTP_STATUS_CODE.OK) {
        throw new Error(`${response.data.status}`);
      }
    },
    onError: (err) => {
      isCrConfigEnabled &&
        appNotification.error(contactCSErrorMessage(err.message));
    },
  };

  // Fetch data using useSWR hook
  const { data, isLoading, isValidating } = useSWR<AxiosResponse<any>>(
    keys,
    ([url]) => getData(url),
    config,
  );

  // Determine if payout details are valid based on fetched data
  const setPayoutDetails =
    (isAllowedToCreateCR &&
      !data?.data?.payload?.bank_accounts?.[0]?.is_valid) ||
    false;

  // Return payout validation data and loading states
  return {
    setPayoutDetails,
    loadingPayoutDetails: isLoading || isValidating || loadingPermission,
  };
};
export default usePayoutValidation;
