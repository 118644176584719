import React, { useState } from "react";

import { Quick } from "Modules/DS/Filter";

import { ITitleWithID } from "Views/Transaction/@types";

import { transactionType } from "./data";

export const TransactionTypeFilter = ({
  id,
  applyFilter,
  resetFilter,
}: {
  id: string;
  applyFilter: (filter: any, callApi?: boolean) => void;
  resetFilter: (keys: string[]) => void;
}) => {
  const [title, setTitle] = useState<string>("");
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const states = {
    selectedIds,
    setSelectedIds,
    title,
    setTitle,
  };

  const onAddSelected = (selected: string[]) => applyFilter({ types: selected });

  const clearFilter = () => resetFilter(["types"]);

  return (
    <Quick.Multiple<ITitleWithID>
      idKey="id"
      displayKey="title"
      searchable
      states={states}
      defaultValue="Type"
      filterItemProps={{ id, containerClass: ".data-table-container" }}
      onAddSelected={onAddSelected}
      clearFilter={clearFilter}
    >
      {transactionType}
    </Quick.Multiple>
  );
};
