export const paymentPurposes = [
  {
    value: "own_account",
    label: "Transfer to own account",
  },
  {
    value: "living_expense",
    label: "Living Expense",
  },
  {
    value: "allowance",
    label: "Allowance",
  },
  {
    value: "saving",
    label: "Saving",
  },
  {
    value: "gift",
    label: "Gift",
  },
  {
    value: "medical_expense",
    label: "Medical Expense",
  },
  {
    value: "education_expense",
    label: "Education Expense",
  },
  {
    value: "donation",
    label: "Donation",
  },
  {
    value: "travel_expense",
    label: "Travel Expense",
  },
  {
    value: "salary",
    label: "Salary",
  },
  {
    value: "loan_payment",
    label: "Loan Payment",
  },
  {
    value: "investment",
    label: "Investment",
  },
  {
    value: "business_expense",
    label: "Business Expense",
  },
  {
    value: "payment_for_service",
    label: "Payment for Service",
  },
  {
    value: "payment_for_goods",
    label: "Payment for Goods",
  },
  {
    value: "service_fee",
    label: "Service Fee",
  },
  {
    value: "tax_payment",
    label: "Tax Payment",
  },
  {
    value: "rental_payment",
    label: "Rental Payment",
  },
  {
    value: "property_payment",
    label: "Property Payment",
  },
  {
    value: "insurance_payment",
    label: "Insurance Payment",
  },
  {
    value: "others",
    label: "Others",
  },
];

export const defaultPaymentPurpose = paymentPurposes[12].value; // business expense
