import React from "react";
import cn from "classnames";
import "./Label.scss";

interface LabelProps {
  className?: string;
  htmlFor?: string;
  children: React.ReactNode;
  onClick?: () => void;
}

const Label: React.FC<LabelProps> = ({ className, children, onClick = () => undefined, ...rest }: LabelProps) => {
  return children ? (
    <label onClick={onClick} className={cn("spm-label", className)} {...rest}>
      {children}
    </label>
  ) : null;
};

export default Label;
