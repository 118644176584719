import React, { useEffect, useState } from "react";
import { Popover as AntdPopover } from "antd";
import classNames from "classnames";
import Search from "./Search";
import { roundValue, capitalizeFirstLetter, plural } from "utility";
import { useStaticSubscription } from "Views/SubscriptionManagement/hooks/useSubscriptionList";
import { VendorImage } from "Views/SubscriptionManagement/Components/VendorImage";
import { usePermission } from "Views/SubscriptionManagement/hooks/usePermission";
import { linkTransactionToSubscriptions } from "Views/Transaction/DataCalls";
import { EWorkflowApiStatus, IMoreActionProps } from "../../type";
import useLoading from "utility/useLoading";
import { bulkActionTransactions } from "Redux/DataCalls/AccountingWorkflow.api";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import { useFormContext } from "../../context/FormContext";

import styles from "./styles.module.scss";

const Popover: React.FC<IMoreActionProps> = ({
  setShowEmailReminder,
  setShowMoreActionMenu,
  setShowWarningModal,
  selectedIds,
  selectedTransactionNumbers,
  onError,
  onProcess,
  onSuccess,
  onFinish,
  showMoreActionmenu,
  employeeCount,
  status,
  connected = false,
  showAddSubscriptionItem = false,
}) => {
  const [visible, setVisible] = useState(false);
  const { tags, taxes } = useFormContext();

  const { subscriptions } = useStaticSubscription();
  const { allowedToOpenPage } = usePermission();
  const [type, setType] = useState("");
  const [bulkAction] = useLoading(bulkActionTransactions, null, {}, false);

  const allowToCompleteWithoutSync = () => status !== EWorkflowApiStatus.COMPLETED && connected;

  useEffect(() => {
    if (!showMoreActionmenu) {
      setVisible(false);
      setType("");
    }
  }, [showMoreActionmenu]);

  const handleCompleteWithoutSyncClick = () => {
    setShowWarningModal(true);
    setShowMoreActionMenu(false);
  };

  const setBulkEditType = (type: string) => {
    setVisible(true);
    setType(type);
  };

  const generateData = () => {
    const dataSource = {
      tax: () => taxes.map((tax) => ({ id: tax.id, value: `${tax.tax_name} (${roundValue(tax.tax_rate)}%)` })),
      subscription: () =>
        subscriptions.map((subscription) => ({
          id: subscription.id,
          searchKey: subscription.vendor.name,
          value: (
            <div className={styles.vendor}>
              <VendorImage vendor={subscription.vendor.name} width={18} height={20} />
              {subscription.vendor.name}
            </div>
          ),
        })),
    };

    if (!type) {
      return [];
    }

    if (dataSource[type]) {
      return dataSource[type]?.() || [];
    }

    return tags.find((tag) => tag.label === type)?.values || [];
  };

  const generateWord = () => {
    const dictionary = {
      tax: {
        singular: "tax",
        plural: "taxes",
      },
      transactionTags: {
        singular: "transaction tag",
        plural: "transaction tags",
      },
    };

    return dictionary?.[type] || dictionary.transactionTags;
  };

  const findKey = () => {
    if (type === "tax") {
      return "tax_id";
    }

    return "tags";
  };

  const findValue = (row) => {
    if (type === "tax") {
      return row.id;
    }

    return [
      {
        label: type,
        value: row.value,
      },
    ];
  };

  const handleBulkUpdate = async (row) => {
    try {
      setVisible(false);
      setShowMoreActionMenu(false);
      onProcess();

      if (type === "subscription") {
        const subscriptionId = row.id;
        await linkTransactionToSubscriptions(selectedTransactionNumbers, subscriptionId);
        const message = `${plural(selectedIds.length, "transaction")} added to ${
          row.searchKey
        } subscription succesfully`;
        onSuccess(message, type, { subscriptionId });
        return;
      }

      const { data } = await bulkAction(selectedIds, findKey(), findValue(row));

      if (data.status === HTTP_STATUS_CODE.OK) {
        const { singular, plural } = generateWord();
        const message =
          selectedIds.length > 1 ? `Multiple ${plural} successfully edited` : `1 ${singular} successfully edited`;

        onSuccess(message, type);
      } else {
        onError(data);
      }
    } catch (error) {
      onError(error);
    } finally {
      onFinish();
      setType("");
      setVisible(false);
    }
  };

  return (
    <AntdPopover
      visible={visible}
      placement="leftBottom"
      overlayClassName={styles["second-level-popover"]}
      content={() => (
        <Search transactionCount={selectedIds.length} data={generateData()} onSelected={handleBulkUpdate} type={type} />
      )}
      getPopupContainer={(triggerNode) => triggerNode.parentElement}
    >
      <div className={styles.popover}>
        <ul className={classNames(styles.list)}>
          {allowedToOpenPage && showAddSubscriptionItem && (
            <li
              className={classNames({
                [styles.selected]: type === "subscription",
              })}
              onClick={() => setBulkEditType("subscription")}
            >
              Add to Subscription
            </li>
          )}
          <li
            className={classNames({
              [styles.selected]: type === "tax",
            })}
            onClick={() => setBulkEditType("tax")}
          >
            Edit Tax
          </li>
          {tags.map((tag) => (
            <li
              className={classNames({
                [styles.selected]: type === tag.label,
              })}
              onClick={() => setBulkEditType(tag.label)}
              key={tag.id}
            >
              Edit {capitalizeFirstLetter(tag.label)}
            </li>
          ))}
          {[EWorkflowApiStatus.PENDING_REVIEW, EWorkflowApiStatus.MISSING_INFO].includes(status) && (
            <li
              onClick={() => {
                setShowMoreActionMenu(false);
                setShowEmailReminder(true);
              }}
            >
              Send Reminder Email{employeeCount > 1 ? "s" : ""}
            </li>
          )}
          {allowToCompleteWithoutSync() && <li onClick={handleCompleteWithoutSyncClick}>Complete without Sync</li>}
        </ul>
      </div>
    </AntdPopover>
  );
};

export default Popover;
