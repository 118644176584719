import React, { useState } from "react";
import DataTable, {
  ActionButtonType,
  BulkActionType,
  DATA_TABLE_PADDING,
  DATA_TABLE_SIZE_TYPES,
  IBulkActionProps,
  ITableProps,
  Table,
} from "Modules/DS/DataTable";
import { useMutableData } from "API/useData";
import classNames from "classnames";
import { BulkActions } from "Modules/DS/DataTable/Components/BulkActions";
import { IGetTrxnResponse } from "Views/Transaction/@types";
import ListEmptyState from "Views/SubscriptionManagement/Components/ListEmptyState";
import Info from "Views/Transaction/All/Info";
import { useSubscriptionContext } from "Views/SubscriptionManagement/Details/SubscriptionContext";
import { dummyPayments } from "Views/SubscriptionManagement/mock/paymentList";
import { getCookieValue, cookieNames } from "utility/CookieHelper";

import RemoveSubscriptionModal from "../RemoveSubscriptionModal";
import { TransactionSidePanel } from "../SidePanel";
import Columns from "./Columns";

import styles from "./index.module.scss";

const generateParams = (filter: { limit: string; subId: string }) => {
  const params = new URLSearchParams();
  params.set("limit", filter.limit);
  params.set("subscription_ids", filter.subId);

  return params.toString();
};

const PaymentList = () => {
  const { subscription } = useSubscriptionContext();
  const [visible, setVisible] = useState(false);
  const [transaction, setTransaction] = useState({});

  /**
   * Note for limit
   * After implementing lazy loading for this list, the hardcoded limit will be removed.
   **/
  const params = generateParams({
    limit: "100",
    subId: subscription.id,
  });

  const { data, isLoading, isValidating } = useMutableData(`/ms/spm-reporting/v1/transactions?${params}`, {
    fallbackData: {},
  });

  const loading = isLoading || isValidating;
  const transactions = loading ? dummyPayments : data.data?.data || [];

  const [selectedRows, setSelectedRows] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const selectedIds = selectedRows.map((row) => (row as IGetTrxnResponse).id);

  const getTotalAmount = selectedRows?.reduce(
    (total, transaction) => {
      const amount = Math.abs(transaction.amount);
      total[transaction.amount < 0 ? "credit" : "debit"] += amount;
      return total;
    },
    { credit: 0, debit: 0 }
  );

  const openTransactionDetail = (transaction) => {
    setTransaction(transaction);
    setVisible(true);
  };

  const table: ITableProps<IGetTrxnResponse> = {
    loading: false,
    dataSource: transactions,
    rowAction: openTransactionDetail,
    rowSelection: {
      preserveSelectedRowKeys: false,
      selectedRowKeys: selectedIds,
      onChange: (_, selectedItems) => setSelectedRows(selectedItems),
    },
    paginationEnabled: false,
  };

  const bulkAction: IBulkActionProps = {
    dataName: "transaction",
    data: selectedRows,
    type: BulkActionType.Default,
    supportingInfo: <Info label="Total" amount={getTotalAmount} />,
    clearSelection: () => setSelectedRows([]),
    firstButton: {
      label: "Remove from Subscription",
      type: ActionButtonType.Secondary,
      handler: () => setShowModal(true),
    },
  };

  return (
    <div className={classNames(styles.list, { [styles.hasCredit]: getTotalAmount?.credit })}>
      {transactions.length > 0 ? (
        <>
          <DataTable
            size={DATA_TABLE_SIZE_TYPES.SMALL}
            padding={DATA_TABLE_PADDING.NONE}
            className="data-table-container"
          >
            <Table<IGetTrxnResponse> {...table}>{Columns({ loading })}</Table>
          </DataTable>
          {selectedRows?.length > 0 && <BulkActions {...bulkAction} />}
          <RemoveSubscriptionModal
            selectedRows={selectedRows.map((transaction) => transaction.transaction_number)}
            showModal={showModal}
            onFinish={() => {
              setSelectedRows([]);
              setShowModal(false);
            }}
            onClose={() => setShowModal(false)}
          />
          <TransactionSidePanel visible={visible} record={transaction} onClose={() => setVisible(false)} />
        </>
      ) : (
        <ListEmptyState />
      )}
    </div>
  );
};

export default PaymentList;
