export const allSettled = (promises: Promise<any>[]): Promise<any[]> => {
  return Promise.all(
    promises.map(async (promise) => {
      try {
        const value = await promise;
        return { status: "fulfilled", value: value };
      } catch (reason) {
        return { status: "rejected", reason: reason };
      }
    })
  );
};
