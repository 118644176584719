import Modal, { ModalComponent } from "Modules/DS/Modal";
import { TITLE_TYPE } from "Modules/DS/Modal/types";
import React, { useContext, useEffect, useMemo } from "react";
import { trackEvent } from "utility/analytics";
import { BILL_EVENTS, BILL_FLOW_TYPES, BILL_SUBMISSION_TYPES } from "Views/Bills/events";
import { BillFormTypes } from "Views/UploadInvoice/EditBillModal/types.d";
import style from "./PreviewModal.module.scss";

export interface PreviewModalProps {
  billFormType: BillFormTypes;
  visible: boolean;
  billID: string;
  setVisible: (visible: boolean) => void;
  action?: () => void;
  cancel?: () => void;
}

const PreviewModalContext = React.createContext<Pick<PreviewModalProps, "setVisible" | "billID" | "billFormType">>({
  setVisible: () => {},
  billID: null,
  billFormType: BillFormTypes.New,
});

export const Footer: React.FC = ({ children }) => {
  const { setVisible, billID, billFormType } = useContext(PreviewModalContext);

  return (
    <div className={style.footer}>
      <div
        onClick={() => {
          setVisible(false);
          trackEvent(BILL_EVENTS.previewPageClosed, {
            bill_id: billID,
            bill_flow_type: BILL_FLOW_TYPES[billFormType],
          });
        }}
        className={style.footerCloselink}
      >
        Back
      </div>
      <div>{children}</div>
    </div>
  );
};

interface SubComponents {
  Footer: typeof Footer;
}

const PreviewModal: React.FC<PreviewModalProps> & SubComponents = ({
  billFormType,
  visible,
  setVisible,
  children,
  billID,
}) => {
  const handleClose = () => {
    setVisible(false);
    trackEvent(BILL_EVENTS.previewPageClosed, {
      bill_id: billID,
      bill_flow_type: BILL_FLOW_TYPES[billFormType],
    });
  };

  useEffect(() => {
    if (visible) {
      trackEvent(BILL_EVENTS.previewPageLoaded, {
        bill_id: billID,
        bill_flow_type: BILL_FLOW_TYPES[billFormType],
        bill_submission_type: BILL_SUBMISSION_TYPES.single,
      });
    }
  }, [visible]);

  const titles = {
    [BillFormTypes.New]: "New Bill",
    [BillFormTypes.Draft]: "New Bill",
    [BillFormTypes.Edit]: "Edit Bill",
  };

  const contextValues = useMemo(() => ({ setVisible, billID, billFormType }), [setVisible, billID, billFormType]);

  return (
    <PreviewModalContext.Provider value={contextValues}>
      <Modal visible={visible} close={handleClose} className={style.previewModal}>
        <ModalComponent.CloseButton />
        <ModalComponent.Title titleType={TITLE_TYPE.withBorder}>{titles[billFormType]}</ModalComponent.Title>
        {children}
      </Modal>
    </PreviewModalContext.Provider>
  );
};

PreviewModal.Footer = Footer;
export default PreviewModal;
