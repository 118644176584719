import React, { useContext } from "react";

import Menu, { IItemTitle } from "Modules/DS/Menu";
import Select from "Modules/DS/Select";

import { TrxnMoreFilterContext } from "Views/Transaction/Context";
import { ITrxnFilter, ITrxnMoreFilterContext } from "Views/Transaction/@types";

export const AmountFilter = ({ title }: IItemTitle) => {
  const { more, applyMoreFilter, clear } = useContext<ITrxnMoreFilterContext>(TrxnMoreFilterContext);

  return (
    <Menu.Row id={title.toLowerCase()} title={title}>
      <Select.Range.Input<ITrxnFilter>
        filter={more}
        clear={clear}
        onApply={(key, value) => applyMoreFilter<number>(key, value)}
      />
    </Menu.Row>
  );
};
