import React from "react";
import { Dropdown } from "antd";
import { moreIcon } from "assets/img";
import styles from "./index.module.scss";
import TeamListAction from "Views/Team/List/Actions/Action/index";
import { ITeam } from "Views/Team/type";
import useActionsToShow from "Views/Team/hooks/UseActionsToShow";

const TeamListActions = ({ team }: { team: ITeam }) => {
  const teamActions = useActionsToShow(team);
  if (teamActions.length === 0) {
    return null;
  }
  const moreActionMenu = (
    <div className={`${styles["action-list"]}`}>
      {teamActions.map(({ value, label }) => (
        <TeamListAction team={team} value={value} key={value} label={label} />
      ))}
    </div>
  );

  return (
    <div className={`${styles.container}`} onClick={(e) => e.stopPropagation()}>
      <Dropdown overlay={moreActionMenu} trigger={["click"]} placement="bottomRight">
        <img width={24} height={24} alt="More icon" src={moreIcon} />
      </Dropdown>
    </div>
  );
};

export default TeamListActions;
