import { SET_ACTIVE_TAB } from "Redux/Actions/ApprovalFlow/handleTabApprovalFlow";

export const approvalFlowTabReducer = (state = { activeTab: null }, action) => {
  switch (action.type) {
    case SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.activeTab,
      };
    default:
      return state;
  }
};
