import React from "react";
import classNames from "classnames";

import { IDateItem } from "Modules/RecurringDate/types";

import styles from "./styles.module.scss";

const DateItem = ({ date, onClickHandler, selected }: IDateItem) => {
  return (
    <button
      className={classNames(styles.container, {
        [styles.selected]: selected,
      })}
      onClick={onClickHandler}
    >
      {date}
    </button>
  );
};

export default DateItem;
