import { getData, postData } from "API/Client";
import { IOriginalBillData } from "Views/Bills/ImportBillFromXero";

export const getBillFromXero = () => {
  return getData("/ms/b2b-accounting/v1/xero/bills");
};

export const importBillToSpenmo = (bills: IOriginalBillData[]) => {
  return postData("/ms/b2b-accounting/v1/xero/bills/import", { bills });
};

export const checkImportBillStatus = (progressId) => {
  return getData(`/ms/b2b-accounting/v1/xero/bills/import/${progressId}`);
};
