import React, {
  KeyboardEvent,
  MouseEvent,
  useContext,
  useEffect,
  useState,
} from "react";
import dayjs from "dayjs";
import { useLocation, useHistory } from "react-router-dom";
import qs from "query-string";
import {
  AttachmentCrossedOutline,
  Tags,
  Tooltip,
  Typography,
} from "@spenmo/splice";

import Amount from "Views/Bills/V2/PaymentRun/BillDetail/Amount";
import BillSidepanel from "Views/Bills/V2/components/BillSidepanel";

import { DATE_FORMAT } from "Views/Bills/V2/constants";
import { BILL_STATUS_VARIANT } from "Views/Bills/V2/PaymentRun/constant";

import { paymentRunBillPreview } from "assets/img";
import styles from "./index.module.scss";
import { TrxnModalContext } from "Views/Transaction/Context";
import { TRXN_MODAL_TYPES } from "Views/Transaction/Constants";
import { IBillItem, IPaymentCycleData } from "Views/Bills/V2/PaymentRun/types";
import DraggableItem from "Views/Bills/V2/PaymentRun/DraggableItem";
import classNames from "classnames";

export interface IBillDetailProps {
  id: string;
  companyName: string;
  billNumber: string;
  dueDate: string;
  amount: number;
  currency: string;
  fileUrls: string[];
  status: {
    key: string;
    label: string;
  };
  isOverDue: boolean;
  isDraggable?: boolean;
  bill?: IBillItem;
  currentCycle?: IPaymentCycleData;
}

const BillDetail = (props: IBillDetailProps) => {
  const {
    id,
    companyName,
    billNumber,
    dueDate,
    amount,
    currency,
    fileUrls,
    status,
    isDraggable = false,
    bill,
    currentCycle,
    isOverDue,
  } = props;

  const location = useLocation();
  const history = useHistory();
  const { invoiceid, ...rest } = qs.parse(location.search);
  const { setShowModal } = useContext(TrxnModalContext);
  const [showSidePanel, setShowSidePanel] = useState(false);

  const dueDateStyles = classNames(styles.dueDate, {
    [styles.latePayment]: isOverDue,
  });

  useEffect(() => {
    if (invoiceid === id) {
      setShowSidePanel(true);

      // IMPROVEMENT: could delete this
      // after we implement billID/ invoiceid to the URL permanently
      history.replace(
        qs.stringifyUrl({
          url: location.pathname,
          query: rest,
        }),
      );
    }
  }, [invoiceid, id]);

  const handlePreview = (
    e: MouseEvent<HTMLImageElement> | KeyboardEvent<HTMLImageElement>,
    fileUrls: string[],
  ) => {
    e.stopPropagation();
    setShowModal({
      visible: true,
      type: TRXN_MODAL_TYPES.VIEW_ATTACHMENT,
      payload: {
        isBillsTrxnsPage: true,
        receipts: fileUrls,
      },
    });
  };

  const BillWrapper = (
    <div className={styles.container} onClick={() => setShowSidePanel(true)}>
      {fileUrls.length > 0 ? (
        <img
          className={styles.attachementPresent}
          width={36}
          height={36}
          role="button"
          onKeyDown={(e) => {
            if (e.key === "Enter" || e.key === " ") {
              handlePreview(e, fileUrls);
            }
          }}
          tabIndex={0}
          onClick={(e) => handlePreview(e, fileUrls)}
          src={paymentRunBillPreview}
          alt="bill-preview"
        />
      ) : (
        <div className={styles.attachementAbsent}>
          <AttachmentCrossedOutline iconColor="var(--icon-default)" size="24" />
        </div>
      )}
      <Typography
        className={styles.companyName}
        size="s"
        variant="body-content"
        weight={600}
      >
        {companyName}
      </Typography>
      <div className={styles.billNumber}>
        <Tooltip title={billNumber} placement="top-start">
          <Typography size="s" variant="body-content">
            {billNumber}
          </Typography>
        </Tooltip>
      </div>
      {dueDate.length > 0 && (
        <Typography className={dueDateStyles} size="s" variant="body-content">
          {`Due on ${dayjs(dueDate).format(DATE_FORMAT.short)}`}
        </Typography>
      )}
      <div className={styles.amount}>
        <Amount currency={currency} value={amount} />
      </div>
      {status?.label && (
        <Tags.Progression
          className={styles.status}
          hierarchy="low"
          label={status?.label}
          variant={BILL_STATUS_VARIANT[status.key]}
        />
      )}
    </div>
  );

  return (
    <>
      {isDraggable ? (
        <DraggableItem bill={bill} cycle={currentCycle}>
          {BillWrapper}
        </DraggableItem>
      ) : (
        BillWrapper
      )}
      <BillSidepanel
        id={id}
        visible={showSidePanel}
        onClose={() => setShowSidePanel(false)}
      />
    </>
  );
};

export default BillDetail;
