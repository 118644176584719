import { ESidePanelButtonType } from "Modules/DS/SidePanel";
import { PrimaryButton } from "Modules/DS/SidePanel/components/Buttons/PrimaryButton";
import { TextButton } from "Modules/DS/SidePanel/components/Buttons/TextButton";
import React from "react";
import styles from "./index.module.scss";

interface IActionBtns {
  onCancel: () => void;
  onSave: () => void;
}

const ActionBtns = ({ onCancel, onSave }: IActionBtns) => {
  return (
    <div className={styles.container}>
      <TextButton id="approvalCardDetailBtn" action={onCancel} text="Cancel" type={ESidePanelButtonType.TEXT} />
      <PrimaryButton
        id="approvalCardDetailBtn"
        action={onSave}
        text="Save Changes"
        type={ESidePanelButtonType.PRIMARY}
      />
    </div>
  );
};

export default ActionBtns;
