import React from "react";
import LoadingComponent from "Views/State/Loading/LoaderIcon";
import { intlDateTimeFormat } from "utility";
import { clock } from "assets/v1.1/icons/Line";

import "./styles.scss";

const ImportCategoryInformation = ({ importCategoryEvent, partnerName, partnerLogoUrl }) => {
  const { data, loading } = importCategoryEvent;
  const createdAt = data?.created_at;

  const generateImportDate = () => {
    if (!createdAt) {
      return "-";
    }
    return intlDateTimeFormat(new Date(createdAt));
  };

  return (
    <div className="import-category-information">
      <section className="import-category-information__content">
        <h4 className="title">Import Category</h4>
        <p className="paragraph">
          You will import all categories from {partnerName}. If there is a category that has the same name as an
          existing category in Spenmo, the category will not be imported again.
        </p>
      </section>

      <section className="import-category-information__content">
        <h4 className="title">Import Details</h4>
        <div className="import-details">
          <div className="import-details__accounting-software">
            <img alt="xeroLogo" src={partnerLogoUrl} className="import-details__accounting_software" />
            <p className="import-details__header">Accounting Software</p>
            <p className="import-details__headline">{partnerName}</p>
          </div>
          <div className="import-details__import-information">
            <img alt="xeroLogo" src={clock} className="import-details__logo" />
            <p className="import-details__header">Last Imported & Mapped</p>
            {loading ? (
              <LoadingComponent className="import-details__loading" />
            ) : (
              <p className="import-details__headline">{generateImportDate()}</p>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default ImportCategoryInformation;
