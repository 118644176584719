import React, { useState, useEffect, useContext, useCallback } from "react";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import DataTable, {
  Table,
  Header,
  ActionsBar,
  BulkActionType,
  BulkActionPropTypes,
  DATA_TABLE_SIZE_TYPES,
} from "Modules/DS/DataTable";
import Icon from "Modules/icons";
import Modal, { ModalComponent } from "Modules/DS/Modal";
import { trackEvent } from "utility/analytics";
import CarouselPreview from "Modules/DS/CarouselFiles";
import {
  BillActions,
  CardActions,
  ReimbursementActions,
  fundTransferActions,
} from "./Actions";
import { APPROVAL_REQUEST_TABLE_PAGE_SIZE } from "constants/ApprovalRequest.constants";
import BillApprovalRequest from "Models/Approval/ApprovalCenter/billApprovalRequest.interface";
import { AppDispatch, useAppSelector } from "../../../../hook";
import {
  BillColumns,
  CardColumns,
  ReimbursementColumns,
  FundTransferColumns,
} from "./Columns";
import { ApprovalRequestTabCount } from "Redux/Actions/Approval/ApprovalCenter/types";
import { settings } from "assets/v1.1/icons/Line";
import SideSection from "Modules/sideSection";

import {
  approvalRequestDecision,
  approvalApproveSettleRequest,
  fetchApprovalRequestList,
  fetchApprovalRequestCount,
  selectActiveTab,
  selectApiCallState,
  selectApprovalFilters,
  selectApprovalRequestList,
  selectApprovalRequestTabItems,
  selectApproveApiCallState,
  selectPreviewFileList,
  setActiveTab,
  setSelectedRequests,
  setPreviewFilesList,
  setCurrentPage,
  selectSelectedRequests,
  clearSelectedRows,
  clearAllFilter,
  resetApproveApiCallState,
  fetchApprovalRequest,
  selectApprovalRequest,
  clearAllRequests,
  selectActiveSubTab,
  updateAPICallState,
  selectIfEditedCRPresent,
  selectWorkflowChange,
  getApprovalConfig,
  approveRequestFundTransfer,
  ApproveRequestFundTransferPayload,
} from "Redux/Reducers/Approval/ApprovalCenter/approvalCenter.slice";
import Tabs, { ListData } from "Modules/DS/Tabs";
import { ApiCallState, NetworkCallState } from "Models/Network/models";
import { TAB_KEY, REIMBURSEMENT_STATUS, APPROVAL_EVENT_SOURCE } from "../types";
import DeleteApprovalRequestModal from "./ApprovalActions/DeleteApprovalRequestModal";
import {
  getApprovalRequestTabs,
  getBulkRequestDeclineWarning,
  getCardLimitError,
  getLabelForTotalAmount,
  getSelectedProcessIds,
  getSelectedRowForDeclineModal,
  getSingleBillRequestDeclineWarning,
  getSingleCardRequestDeclineWarning,
  getSingleFundTransferRequestDeclineWarning,
  getSingleReimbursementRequestDeclineWarning,
  getTotalAmountForSelectedRequests,
  isCardLimitValidationFail,
  shouldDisableCurrentRowSelection,
  shouldDisableHeaderSelection,
  shouldShowCheckboxSelection,
} from "../helper";
import { SupportingInfo } from "./SupportingInfo";
import ApprovalRequest from "Models/Approval/ApprovalCenter/approvalRequest.interface";
import { ApprovalFilterContext, initalFiltersState } from "../context";
import ApprovalCardDetail from "../ApprovalCardDetail";
import ApprovalReimbursementTab from "../ApprovalReimbursementTab";
import ApprovalReimbursementSidePanel from "../ApprovalReimbursementSidePanel";
import ReimbursementApprovalRequest from "Models/Approval/ApprovalCenter/reimbursementApprovalRequest.interface";
import CardApprovalRequest from "Models/Approval/ApprovalCenter/cardApprovalRequest.interface";
import FundTransferApprovalRequest from "Models/Approval/ApprovalCenter/fundTransferApprovalRequest.interface";
import useCheckOrgConfigs from "../../../../customHooks/useCheckOrgConfigs";
import {
  BILLS_APPROVAL_EVENTS,
  CR_APPROVAL_EVENTS,
  CARD_APPROVAL_EVENTS,
  APPROVAL_EVENTS,
} from "../trackEvents";
import { CardUpdateRequestPayload } from "Views/Card/Review/types";
import { GetAllApprovals } from "Redux/DataCalls/Approvals.api";
import { GetOrgId } from "utility";

import ApprovalFundTransferSidePanel from "Views/Approval/ApprovalCenter/ApprovalFundTransferSidePanel";
import ApprovalBillSidePanel from "Views/Approval/ApprovalCenter/ApprovalBillSidePanel/index";
import useCheckFeatureStatus from "customHooks/featureCheck";
import { SPLIT_NAMES, SPLIT_TREATMENT_TYPES } from "Redux/splitio/constants";
import { useIsSaasWithPaymentsOrg } from "customHooks/useIsSaasWithPaymentsOrg";

import "./ApprovalRequestTabItem.scss";

const List: React.FC = (): JSX.Element => {
  const dispatch: AppDispatch = useDispatch();
  const cardProductConfig = useCheckOrgConfigs("card_vendor");
  const billProductConfig = useCheckOrgConfigs("billpay_flag");
  const cashReimbursementProductConfig = useCheckOrgConfigs(
    "cash_reimbursement_flag",
  );
  const isSaasWithPaymentsOrg = useIsSaasWithPaymentsOrg();
  const history = useHistory();

  const allApprovalItems = useAppSelector(selectApprovalRequestList);
  const apiCallState: ApiCallState = useAppSelector(selectApiCallState);
  const approvalRequestTabItems = useAppSelector(selectApprovalRequestTabItems);
  const activeTab: TAB_KEY = useAppSelector(selectActiveTab);
  const activeSubTab: REIMBURSEMENT_STATUS = useAppSelector(selectActiveSubTab);
  const approveApiCallState = useAppSelector(selectApproveApiCallState);
  const approvalFilters = useAppSelector(selectApprovalFilters);
  const previewImageList: string[] = useAppSelector(selectPreviewFileList);
  const [allowedApprovalRequestTabItems, setAllowedApprovalRequestTabItems] =
    useState([]);
  const selectedRows: ApprovalRequest[] = useAppSelector(
    selectSelectedRequests,
  );
  const approvalRequestDetail = useAppSelector(selectApprovalRequest);

  const isWorkflowChange = useAppSelector(selectWorkflowChange);

  const currencyCode = useAppSelector(
    (state) => state.wallet?.data?.currency_code,
  );
  const user = useAppSelector((state) => state.user);
  const editedCR = useAppSelector(selectIfEditedCRPresent);

  const isAdmin: boolean =
    useSelector((state: any) => state.userInfoReducer?.isAdmin) || false;
  const teamId: string = useSelector(
    (state: any) => state.teamReducer?.selectedTeamId,
  );

  const queryParams = new URLSearchParams(window.location.search);
  const processid = queryParams.get("processid");
  const queryActiveTab = queryParams.get("activeTab");
  const emailAction = queryParams.get("action");

  const approveDeclineReady =
    activeSubTab === REIMBURSEMENT_STATUS.PENDING ? true : false;

  useEffect(() => {
    dispatch(getApprovalConfig()).catch((error) => {
      console.error("Error fetching approval config:", error);
    });
    trackEvent(APPROVAL_EVENTS.APPROVAL_PAGE_LOADED, {
      approval_event_source: APPROVAL_EVENT_SOURCE[TAB_KEY.INVOICE],
    });
  }, []);

  useEffect(() => {
    if (approvalRequestDetail) {
      setSelectedRow(approvalRequestDetail);
      setShowSideSection(true);
    }
  }, [approvalRequestDetail]);

  useEffect(() => {
    if (editedCR && editedCR !== "" && activeTab === TAB_KEY.REIMBURSEMENT) {
      const editedCRRequest = (allApprovalItems as ApprovalRequest[]).find(
        (approvalItem) => approvalItem.processID === editedCR,
      );
      showDetails(editedCRRequest);
    }
    if (isWorkflowChange) {
      showDetails(false);
    }
  }, [editedCR]);

  useEffect(() => {
    let allowedTabs = [];
    if (billProductConfig) {
      allowedTabs.push(
        approvalRequestTabItems.find(
          (tab: ApprovalRequestTabCount) => tab.tabKey === TAB_KEY.INVOICE,
        ),
      );
    }
    if (
      cardProductConfig &&
      cardProductConfig !== "" &&
      isSaasWithPaymentsOrg
    ) {
      allowedTabs.push(
        approvalRequestTabItems.find(
          (tab: ApprovalRequestTabCount) => tab.tabKey === TAB_KEY.CARD,
        ),
      );
    }
    if (cashReimbursementProductConfig) {
      allowedTabs.push(
        approvalRequestTabItems.find(
          (tab: ApprovalRequestTabCount) =>
            tab.tabKey === TAB_KEY.REIMBURSEMENT,
        ),
      );
    }
    if (isSaasWithPaymentsOrg) {
      allowedTabs.push(
        approvalRequestTabItems.find(
          (tab: ApprovalRequestTabCount) => tab.tabKey === TAB_KEY.FUND,
        ),
      );
    }

    setAllowedApprovalRequestTabItems(allowedTabs);
    if (!Boolean(activeTab)) {
      dispatch(setActiveTab(allowedTabs[0]?.tabKey));
    }
  }, [approvalRequestTabItems]);

  const [headerSelection, setHeaderSelection] = useState<boolean>(true);
  const [selectedRow, setSelectedRow] = useState<
    | BillApprovalRequest
    | ReimbursementApprovalRequest
    | CardApprovalRequest
    | FundTransferApprovalRequest
  >(null);
  const [showSideSection, setShowSideSection] = useState<boolean>(false);
  const [declineApprovalRequestModal, setDeclineApprovalRequestModal] =
    useState<boolean>(false);
  const { filters, setFilters } = useContext(ApprovalFilterContext);
  const [fundTransferRequests, setFundTransferRequest] = useState([]);
  const [fundTransferApiLoading, setFundTransferApiLoading] = useState(false);
  const [fundTransferRequestsCount, setFundTransferRequestsCount] = useState(0);
  const isBulkApproval = selectedRows.length > 0;
  const selectedProcessIds: string[] = getSelectedProcessIds(
    isBulkApproval,
    selectedRows,
    selectedRow,
  );

  let supportingInfoLabel = "";
  let selectedProcessAmount: string = "";
  if (isBulkApproval) {
    selectedProcessAmount = getTotalAmountForSelectedRequests(
      selectedRows,
      activeTab,
    );
    supportingInfoLabel = getLabelForTotalAmount(activeTab);
  }

  useEffect(() => {
    if (activeTab !== TAB_KEY.FUND) {
      dispatch(fetchApprovalRequestCount());
      dispatch(fetchApprovalRequestList());
    }
  }, [activeTab, approvalFilters, apiCallState.paging.currentPage]);

  useEffect(() => {
    if (
      activeTab !== TAB_KEY.FUND &&
      (approveApiCallState.loading === NetworkCallState.unresolved ||
        approveApiCallState.loading === NetworkCallState.completed)
    ) {
      if (approveApiCallState.loading === NetworkCallState.completed) {
        setDeclineApprovalRequestModal(false);
        if (isBulkApproval) {
          dispatch(setCurrentPage(1));
          dispatch(clearSelectedRows());
          dispatch(clearAllFilter());
          setFilters({ ...filters, ...initalFiltersState });
        }
        dispatch(resetApproveApiCallState());
        if (!isBulkApproval) {
          dispatch(fetchApprovalRequestList());
        }
      }
    }
  }, [approveApiCallState.loading]);

  useEffect(() => {
    if (activeTab === TAB_KEY.FUND) {
      fetchFundTransferData(apiCallState.paging.currentPage - 1);
      dispatch(resetApproveApiCallState());
      setDeclineApprovalRequestModal(false);
    }
  }, [
    activeTab,
    approvalFilters,
    approveApiCallState.loading,
    apiCallState.paging.currentPage,
  ]);

  const fetchFundTransferData = (page) => {
    const { maxAmount, minAmount, requester } = approvalFilters;
    const fundTransferFilters = {
      min_amount: minAmount,
      max_amount: maxAmount,
      user_id: requester ? requester[0] : undefined,
      type: "topup",
      status: "pending",
    };
    setFundTransferApiLoading(true);
    GetAllApprovals(
      GetOrgId(),
      teamId,
      "all",
      page,
      10,
      "",
      fundTransferFilters,
    ).then((data) => {
      const requests = data?.payload?.requests || [];
      const all_count = data?.payload?.counts?.all;
      setFundTransferRequest(requests);
      setFundTransferRequestsCount(all_count);
      setFundTransferApiLoading(false);
    });
  };

  useEffect(() => {
    if (processid) {
      dispatch(
        fetchApprovalRequest({
          process_type: activeTab,
          process_id: processid,
        }),
      );
    }
  }, []);

  const tabPermissions = {
    [TAB_KEY.INVOICE]: billProductConfig,
    [TAB_KEY.REIMBURSEMENT]: cashReimbursementProductConfig,
    [TAB_KEY.CARD]: cardProductConfig && cardProductConfig !== "",
    [TAB_KEY.FUND]: isSaasWithPaymentsOrg,
  };

  useEffect(() => {
    const queryTabValue = queryActiveTab;
    if (queryTabValue !== activeTab && tabPermissions[queryTabValue]) {
      switch (queryTabValue) {
        case TAB_KEY.INVOICE:
          updateActiveTab(TAB_KEY.INVOICE);
          break;
        case TAB_KEY.REIMBURSEMENT:
          updateActiveTab(TAB_KEY.REIMBURSEMENT);
          break;
        case TAB_KEY.CARD:
          updateActiveTab(TAB_KEY.CARD);
          break;
        case TAB_KEY.FUND:
          updateActiveTab(TAB_KEY.FUND);
          break;
      }
    }
  }, [queryActiveTab]);

  useEffect(() => {
    if (!showSideSection && !declineApprovalRequestModal) {
      setSelectedRow(null);
    }
  }, [showSideSection, declineApprovalRequestModal]);

  const updateActiveTab = (activeTab: TAB_KEY) => {
    updateUrlParams(activeTab);
    dispatch(setActiveTab(activeTab));

    setFilters({ ...filters, ...initalFiltersState });
    dispatch(setCurrentPage(1));
    dispatch(clearAllFilter());
    dispatch(clearAllRequests());

    trackEvent(APPROVAL_EVENTS.APPROVAL_PAGE_LOADED, {
      approval_event_source: APPROVAL_EVENT_SOURCE[activeTab],
    });
  };

  const updateUrlParams = (activeTab: string) => {
    dispatch(clearSelectedRows());
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.set("activeTab", activeTab);
    history.push(`/approvalRequestCenter?${queryParams.toString()}`);
  };

  useEffect(() => {
    if (shouldDisableHeaderSelection(allApprovalItems, selectedRows)) {
      setHeaderSelection(false);
    } else {
      setHeaderSelection(true);
    }
  }, [selectedRows, apiCallState.paging]);

  let rowSelection = {
    onChange: (
      selectedRowKeys: React.Key[],
      selectedRowsOnCurrentPage: BillApprovalRequest[],
    ) => {
      dispatch(setSelectedRequests(selectedRowsOnCurrentPage));
    },
    selectedRowKeys: selectedRows.map((row) => row.id),
    getCheckboxProps: (record) => {
      return {
        disabled: shouldDisableCurrentRowSelection(selectedRows, record),
      };
    },
  };

  if (!headerSelection) {
    rowSelection["columnTitle"] = (
      <th className="ant-table-cell ant-table-selection-column">
        <div className="ant-table-selection">
          <label className="ant-checkbox-wrapper ant-checkbox-wrapper-disabled">
            <span className="ant-checkbox ant-checkbox-disabled">
              <input
                type="checkbox"
                className="ant-checkbox-input"
                checked={allApprovalItems.length}
                value=""
              />
              <span className="ant-checkbox-inner"></span>
            </span>
          </label>
        </div>
      </th>
    );
  }

  const validateCardLimitBeforeApprove = () => {
    const cardLimitError: string = getCardLimitError(isBulkApproval);
    dispatch(resetApproveApiCallState());
    dispatch(updateAPICallState({ error: true, message: cardLimitError }));
    return Promise.resolve({});
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleApproveDecision = ({
    ids,
    type,
    approved,
    notes = null,
    singleCardInfo = null,
  }: {
    ids: string[];
    type: string;
    approved: boolean;
    notes?: string;
    singleCardInfo?: CardUpdateRequestPayload;
  }) => {
    if (activeTab === TAB_KEY.CARD && !showSideSection && approved == true) {
      const approvalRequestsToValidate: CardApprovalRequest[] = (
        allApprovalItems as CardApprovalRequest[]
      ).filter((approvalItem) => ids.includes(approvalItem.processID));
      if (approvalRequestsToValidate.some(isCardLimitValidationFail)) {
        return validateCardLimitBeforeApprove();
      }
    }

    if (activeTab === TAB_KEY.FUND) {
      let payload: ApproveRequestFundTransferPayload = {
        values: { reason: notes },
        type: "reject",
        requestId: selectedRow.id,
      };
      setShowSideSection(false);
      return dispatch(approveRequestFundTransfer(payload));
    }

    setShowSideSection(false);
    return dispatch(
      approvalRequestDecision({
        processIds: ids,
        processType: type,
        isApproved: approved,
        notes: notes,
        singleCardInfo: singleCardInfo,
      }),
    );
  };

  const handleApprovePayImmediately = ({
    id,
    type,
    actionType,
  }: {
    id: string;
    type: string;
    actionType: string;
  }) => {
    setShowSideSection(false);
    return dispatch(
      approvalApproveSettleRequest({
        processId: id,
        processType: type,
        actionType: actionType,
      }),
    );
  };

  const handleBulkApprove = () => {
    if (activeTab === TAB_KEY.CARD) {
      if (
        (selectedRows as CardApprovalRequest[]).some(isCardLimitValidationFail)
      ) {
        return validateCardLimitBeforeApprove();
      }
    }

    return dispatch(
      approvalRequestDecision({
        processIds: selectedProcessIds,
        processType: activeTab,
        isApproved: true,
      }),
    );
  };

  const handleBulkReject = () => {
    setDeclineApprovalRequestModal(true);
    return Promise.resolve({});
  };

  const handleApproveOrReject = (isApproved, singleCardInfo) => {
    if (isApproved) {
      handleApproveDecision({
        ids: [selectedRow?.processID],
        type: activeTab,
        approved: isApproved,
        singleCardInfo: singleCardInfo,
      });
    } else {
      setDeclineApprovalRequestModal(true);
    }
  };

  const handlePreviewFiles = useCallback(
    (files: string[]) => dispatch(setPreviewFilesList(files)),
    [dispatch],
  );

  const handleClosePreviewModal = () => handlePreviewFiles([]);

  const showDetails = (record, sideSection = true) => {
    setShowSideSection(sideSection);
    setSelectedRow(record);
  };

  const onPageChange = (pagenumber: number): void => {
    window.scrollTo(0, 0);
    dispatch(setCurrentPage(pagenumber));
  };

  const highlightSelectedRow = (record: ApprovalRequest) => {
    if (selectedRow && record.id === selectedRow.id) {
      return "row-selected";
    }
  };

  const setBulkTrackEventData = (action, source) => {
    switch (activeTab) {
      case TAB_KEY.INVOICE:
        return {
          name: BILLS_APPROVAL_EVENTS.billApprovalButtonClicked,
          data: {
            bill_id: selectedProcessIds,
            user_role: user.role,
            approval_bills_event_action: action,
            approval_bills_event_source: source,
          },
        };
      case TAB_KEY.CARD:
        return {
          name: CARD_APPROVAL_EVENTS.cardApprovalButtonClicked,
          data: {
            card_id: selectedProcessIds,
            user_role: user.role,
            approval_cards_event_action: action,
            approval_cards_event_source: source,
          },
        };
      case TAB_KEY.REIMBURSEMENT:
        return {
          name: CR_APPROVAL_EVENTS.crApprovalButtonClicked,
          data: {
            card_id: selectedProcessIds,
            user_role: user.role,
            approval_cr_event_action: action,
            approval_cr_event_source: source,
          },
        };
      default:
        break;
    }
  };

  const table = {
    total:
      activeTab === TAB_KEY.FUND
        ? fundTransferRequestsCount
        : apiCallState.paging.total,
    loading: false,
    dataSource:
      activeTab === TAB_KEY.FUND ? fundTransferRequests : allApprovalItems,
    pageNum: apiCallState.paging.currentPage - 1,
    pageSize: APPROVAL_REQUEST_TABLE_PAGE_SIZE,
    onPageChange: onPageChange,
    emptyState: <p>No pending approvals found</p>,
    rowAction: (record) => showDetails(record),
    rowClassName: highlightSelectedRow,
    bulkAction: {
      type: BulkActionType.Destructive,
      dataName: "request",
      maximum: 20,
      data: selectedRows,
      supportingInfo: activeTab !== TAB_KEY.CARD && (
        <SupportingInfo
          label={supportingInfoLabel}
          total={selectedProcessAmount}
          sendCurrency={currencyCode}
        ></SupportingInfo>
      ),
      constructive: {
        label: "Approve",
        handler: handleBulkApprove,
      },
      destructive: {
        label: "Decline",
        handler: handleBulkReject,
      },
      clearSelection: () => {
        dispatch(clearSelectedRows());
      },
      trackEventData: setBulkTrackEventData,
    } as BulkActionPropTypes,
    scrollHorizontal: "fit-content",
  };

  if (shouldShowCheckboxSelection(activeTab, approvalFilters)) {
    table["rowSelection"] = { ...rowSelection };
  }

  const { titleModalDelete, warningMessageDelete, eventName, eventData } =
    React.useMemo(() => {
      let titleModalDelete = "";
      let warningMessageDelete = "";

      if (!selectedProcessIds?.length || !declineApprovalRequestModal) {
        return { titleModalDelete, warningMessageDelete };
      }

      let eventName = "";
      let eventData = {};
      const action = "Confirm Decline";
      if (isBulkApproval) {
        let warningTypeConfirm = "";
        switch (activeTab) {
          case TAB_KEY.INVOICE:
            eventName = BILLS_APPROVAL_EVENTS.billApprovalButtonClicked;
            warningTypeConfirm = "Bill Payment";
            eventData = {
              approval_bills_event_action: action,
              user_role: user.role,
              bill_id: selectedProcessIds,
              approval_bills_event_source: "Bulk",
            };
            break;
          case TAB_KEY.CARD:
            eventName = CARD_APPROVAL_EVENTS.cardApprovalButtonClicked;
            warningTypeConfirm = "Card";
            eventData = {
              approval_cards_event_action: action,
              approval_cards_event_source: "Bulk",
              card_id: selectedProcessIds,
              user_role: user?.role,
            };
            break;
          case TAB_KEY.REIMBURSEMENT:
            eventName = CR_APPROVAL_EVENTS.crApprovalButtonClicked;
            warningTypeConfirm = "Reimbursement";
            eventData = {
              approval_cr_event_action: action,
              user_role: user.role,
              bill_id: selectedProcessIds,
              approval_cr_event_source: "Bulk",
            };
            break;
          default:
            break;
        }
        titleModalDelete = "Decline Multiple Requests";
        warningMessageDelete = getBulkRequestDeclineWarning(
          selectedRows,
          warningTypeConfirm,
        );
      } else if (selectedRow) {
        const source = showSideSection ? "Details in Approvals" : "Single";
        const sub_source_details = approveDeclineReady
          ? "Details in Pending Approval"
          : "Details in Waiting Payment";
        const sub_source_single = approveDeclineReady
          ? "Single in Pending Approval"
          : "Single in Waiting Payment";
        switch (activeTab) {
          case TAB_KEY.INVOICE:
            eventName = BILLS_APPROVAL_EVENTS.billApprovalButtonClicked;
            titleModalDelete = "Decline this Bill Payment Request";
            warningMessageDelete = getSingleBillRequestDeclineWarning(
              selectedRow as BillApprovalRequest,
            );
            eventData = {
              approval_bills_event_action: action,
              user_role: user.role,
              bill_id: [selectedRow?.processID],
              approval_bills_event_source: source,
            };
            break;
          case TAB_KEY.CARD:
            eventName = CARD_APPROVAL_EVENTS.cardApprovalButtonClicked;
            titleModalDelete = "Decline this Card Request";
            warningMessageDelete =
              getSingleCardRequestDeclineWarning(selectedRow);
            eventData = {
              approval_cards_event_action: action,
              approval_cards_event_source: source,
              card_id: [selectedRow?.processID],
              user_role: user?.role,
            };
            break;
          case TAB_KEY.REIMBURSEMENT:
            eventName = CR_APPROVAL_EVENTS.crApprovalButtonClicked;
            titleModalDelete = "Decline this Reimbursement Request";
            warningMessageDelete = getSingleReimbursementRequestDeclineWarning(
              selectedRow as ReimbursementApprovalRequest,
            );
            eventData = {
              approval_cr_event_action: action,
              user_role: user.role,
              bill_id: [selectedRow?.processID],
              approval_cr_event_source: showSideSection
                ? sub_source_details
                : sub_source_single,
            };
            break;
          case TAB_KEY.FUND:
            eventName = CR_APPROVAL_EVENTS.crApprovalButtonClicked;
            titleModalDelete = "Decline this Fund Transfer Request";
            warningMessageDelete = getSingleFundTransferRequestDeclineWarning(
              selectedRow as FundTransferApprovalRequest,
            );
            break;
          default:
            break;
        }
      }
      return { warningMessageDelete, titleModalDelete, eventName, eventData };
    }, [
      activeTab,
      declineApprovalRequestModal,
      isBulkApproval,
      selectedProcessIds,
      selectedRow,
      selectedRows,
    ]);

  const isTableDataLoading = () => {
    return (
      approveApiCallState.loading === NetworkCallState.loading ||
      apiCallState.loading === NetworkCallState.loading ||
      fundTransferApiLoading
    );
  };

  const isPayCRAdhocOn =
    useCheckFeatureStatus(SPLIT_NAMES.paynowCRAdhoc) ===
    SPLIT_TREATMENT_TYPES.ON;

  const { tableContent, sideContent } = React.useMemo(() => {
    let tableContent: React.ReactNode;
    let sideContent: React.ReactNode;
    const isEmptyRecords = apiCallState?.paging?.isEmpty;
    switch (activeTab) {
      case TAB_KEY.FUND:
        tableContent = (
          <>
            <ActionsBar>{fundTransferActions()}</ActionsBar>
            <Table {...table}>
              {FundTransferColumns({
                loading: isTableDataLoading(),
              })}
            </Table>
          </>
        );
        sideContent = showSideSection && (
          <ApprovalFundTransferSidePanel
            visible={showSideSection}
            setVisible={setShowSideSection}
            record={selectedRow as FundTransferApprovalRequest}
            onDecline={() => setDeclineApprovalRequestModal(true)}
          />
        );
        break;
      case TAB_KEY.REIMBURSEMENT:
        tableContent = (
          <>
            {isPayCRAdhocOn && <ApprovalReimbursementTab />}
            {!isEmptyRecords && (
              <ActionsBar>{ReimbursementActions()}</ActionsBar>
            )}
            <Table {...table}>
              {ReimbursementColumns({
                handleApproveDecision,
                handleApprovePayImmediately,
                setDeclineApprovalRequestModal,
                showDetails,
                activeTab,
                selectedRows,
                approvalFilters,
                loading: isTableDataLoading(),
              })}
            </Table>
          </>
        );
        sideContent = showSideSection && (
          <ApprovalReimbursementSidePanel
            record={selectedRow as ReimbursementApprovalRequest}
            visible={showSideSection}
            setVisible={setShowSideSection}
            onApprove={handleApproveDecision}
            onDecline={setDeclineApprovalRequestModal}
            onPayApproveImmediately={handleApprovePayImmediately}
            trackEventData={{
              name: CR_APPROVAL_EVENTS.crApprovalButtonClicked,
              data: {
                bill_id: [selectedRow?.processID],
                user_role: user.role,
              },
            }}
          />
        );
        break;
      case TAB_KEY.CARD:
        tableContent = (
          <>
            {!isEmptyRecords && <ActionsBar>{CardActions()}</ActionsBar>}
            <Table {...table}>
              {CardColumns({
                handleApproveDecision,
                setDeclineApprovalRequestModal,
                showDetails,
                activeTab,
                selectedRows,
                loading: isTableDataLoading(),
              })}
            </Table>
          </>
        );
        sideContent = (
          <ApprovalCardDetail
            id={selectedRow?.processID}
            approvalAction={handleApproveOrReject}
            cardVendor={cardProductConfig}
            trackEventData={{
              name: CARD_APPROVAL_EVENTS.cardApprovalButtonClicked,
              data: {
                card_id: [selectedRow?.processID],
                user_role: user.role,
              },
            }}
          />
        );
        break;
      case TAB_KEY.INVOICE:
      default:
        tableContent = (
          <>
            {!isEmptyRecords && <ActionsBar>{BillActions()}</ActionsBar>}
            <Table<BillApprovalRequest> {...table}>
              {BillColumns({
                handleApproveDecision,
                setDeclineApprovalRequestModal,
                activeTab,
                handlePreviewFiles,
                selectedRows,
                showDetails,
                loading: isTableDataLoading(),
                isSaasWithPaymentsOrg,
              })}
            </Table>
          </>
        );
        sideContent = (
          <ApprovalBillSidePanel
            selectedRow={selectedRow}
            showSideSection={showSideSection}
            setShowSideSection={setShowSideSection}
            handleApproveOrReject={handleApproveOrReject}
          />
        );
        break;
    }
    return { tableContent, sideContent: selectedRow ? sideContent : null };
  }, [
    activeTab,
    emailAction,
    handleApproveDecision,
    handleApproveOrReject,
    handlePreviewFiles,
    selectedRow,
    selectedRows,
    showSideSection,
    table,
  ]);

  const showApprovalFlowButton = () => {
    return isAdmin && (billProductConfig || cashReimbursementProductConfig);
  };

  return (
    <>
      <div className="approval-center-header-container">
        <Header title="Approvals"></Header>
        {showApprovalFlowButton() && (
          <button
            className="approval-flow-button"
            onClick={() => history.push("/approvalflow")}
          >
            <Icon src={settings} alt={"approval-flow"} />
            Approval Flow
          </button>
        )}
      </div>
      <Tabs
        tabsList={getApprovalRequestTabs(allowedApprovalRequestTabItems)}
        defaultActiveKey="invoice"
        activeKey={activeTab}
        action={updateActiveTab}
        content={(tab: ListData) => {
          return null;
        }}
      />
      <DataTable
        className="approval-center-list"
        size={DATA_TABLE_SIZE_TYPES.LARGE}
      >
        {tableContent}
        {selectedRow &&
          (activeTab === TAB_KEY.CARD ? (
            <SideSection
              className={classNames("transactions--side-section", {
                card: activeTab === TAB_KEY.CARD,
              })}
              visible={showSideSection}
              onClose={() => {
                setShowSideSection(false);
              }}
            >
              {sideContent}
            </SideSection>
          ) : (
            sideContent
          ))}
      </DataTable>
      <Modal
        visible={previewImageList?.length > 0}
        close={handleClosePreviewModal}
      >
        <ModalComponent.CloseButton fixToTop />
        <div className="image-carousel image-carousel__modal-preview">
          <CarouselPreview itemPosition="start" files={previewImageList} />
        </div>
      </Modal>
      {selectedProcessIds.length > 0 && declineApprovalRequestModal && (
        <DeleteApprovalRequestModal
          warningMessage={warningMessageDelete}
          processIds={selectedProcessIds}
          processType={activeTab}
          onCancel={() => {
            setDeclineApprovalRequestModal(false);
          }}
          title={titleModalDelete}
          onDelete={handleApproveDecision}
          showDetails={() => {
            showDetails(
              getSelectedRowForDeclineModal(
                isBulkApproval,
                selectedProcessIds,
                selectedRows,
                selectedRow,
              ),
            );
            setDeclineApprovalRequestModal(false);
          }}
          eventData={{ name: eventName, data: eventData }}
        />
      )}
    </>
  );
};

export default List;
