export const ALLOWED_FILE_EXTENSIONS = ["xlsx"];
export const ERROR_CODES = {
  EMPTY: 3003,
  DUPLICATE_WITH: {
    EXISTING_DATA_IN_DB: 4024,
    OTHER_ROW_IN_FILE: 4029,
  },
  INVALID: {
    ACH_CODE: 4031,
    SWIFT_CODE: 4027,
    PAYMENT_PURPOSE: 4037,
    TEMPLATE: 4039,
  },
  INPUT_TOO_LONG: 3008,
  AMOUNT_ZERO: 3006,
  UNSUPPORTED_CURRENCY_FOR_COUNTRY: 4049,
  UNSUPPORTED_COUNTRY_FOR_CURRENCY: 4050,
};
export const BULK_TEMPLATE_URL = "https://docs.google.com/spreadsheets/d/1koWKAFROBQ3yzxgLWBwdyvtx-DSiHNJOdQ7U6e0rar8/";
export const DOWNLOAD_TEMPLATE_URL = `${BULK_TEMPLATE_URL}export?format=xlsx`;
export const COPY_TEMPLATE_URL = `${BULK_TEMPLATE_URL}copy`;
export const LEARN_MORE_URL = "https://spenmo.com/faq#billing";

export const INSIGHT_COLUMN_KEY = ["recipientBankEGStandardCharteredBank", "recipientBankCountry", "recipientBankCode"];
