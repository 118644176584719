import React from "react";

import BillsV2 from "./V2";
import { PermissionAndSettingsProvider } from "Views/Bills/V2/context/BillContext";

const BillsVersioning = ({ tabKey }: { tabKey: string }) => {
  return (
    <PermissionAndSettingsProvider>
      <BillsV2 tabKey={tabKey} />
    </PermissionAndSettingsProvider>
  );
};

export default BillsVersioning;
