import React from "react";
import { vendor_logo, visaIcon } from "assets/img";
import { getIsCardVendorNium } from "Views/Home/Utilites";
import "./index.scss";
import { useCardDetailsContext } from "Views/Card/Details/index.context";
import { CARD_COLORS } from "constants/Card/CardColors.constants";
import { spenmoOrangeLogoLarge, spenmoWhiteLogoLarge } from "assets/v1.1/logo/spenmo";

const PhysicalCard = () => {
  const { cardData } = useCardDetailsContext();
  const { cardColor, cardLastFourDigits, cardHolder } = cardData || {};
  const isCardVendorNium = getIsCardVendorNium();
  const logo = () => {
    if (cardColor !== CARD_COLORS[0]) {
      return spenmoWhiteLogoLarge;
    } else {
      return spenmoOrangeLogoLarge;
    }
  };
  return (
    <>
      <article
        className="physical"
        style={{
          background: cardColor,
        }}
        data-testid="card-physical"
      >
        <div className="physical__top">
          <div className="physical__top__info">
            <img className="physical__top__info__brand" src={logo()} width={30} height={32} alt="Spenmo logo" />
            <h4 className="physical__top__info__type">Physical Card</h4>
          </div>
          <div className="physical__top__personal-info">
            <p className="physical__top__personal-info__merchant">{cardHolder?.toUpperCase()}</p>
            <p id="cardNumber" className="physical__top__personal-info__number">
              **** **** **** {cardLastFourDigits}
            </p>
          </div>
        </div>
        <div className="physical__details">
          <img className="physical__details__logo" src={isCardVendorNium ? visaIcon : vendor_logo} alt="mastercard" />
        </div>
      </article>
      <p className="physical__card-info">Refer to the Physical Card for the complete card information.</p>
    </>
  );
};

export default PhysicalCard;
