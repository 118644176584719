import React from "react";
import { useAppSelector } from "hook";

import { More } from "Views/Transaction/Filters";

import { ALL_TRXNS_COLUMNS } from "Views/Transaction/Constants";
import { ITitleWithID } from "Views/Transaction/@types";

export const BudgetFilter = ({ id, appliedKey = "id" }: { id: string; appliedKey?: keyof ITitleWithID }) => {
  const label = ALL_TRXNS_COLUMNS.BUDGET;

  const loading: boolean = useAppSelector((state: any) => state.teamListByUserIdReducer?.loading);

  const budgetData = useAppSelector((state: any) => state.teamListByUserIdReducer?.data || []);

  const data: ITitleWithID[] = budgetData.length
    ? budgetData.map((budget) => ({ id: budget.id, title: budget.team_name }))
    : [];

  return (
    <More.Multiple<ITitleWithID>
      id={id}
      searchable
      label={label}
      loading={loading}
      displayKey="title"
      appliedKey={appliedKey}
    >
      {data}
    </More.Multiple>
  );
};
