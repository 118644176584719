import * as React from "react";
import {
  useSpendingLockEditContext,
  useCardDetailsEditContext,
  CARD_DETAILS_FIELDS_MAP,
} from "Views/Card/Edit/index.context";
import { useHistory } from "react-router-dom";
import { getParamsValue } from "utility";
import MerchantSpendingLock from "Views/Card/Components/SpendingLock/Merchant";
import CategorySpendingLock from "Views/Card/Components/SpendingLock/Category";
import ActionButtons from "Views/Card/Edit/Policy/SpendingLock/Drawer/ActionButtons";
import { SPENDING_LOCK_TYPES, ISpendingLockWithNameAndId } from "Views/Card/types";
import { URL_PARAMS_KEYS } from "Views/Card/Edit/types";

import styles from "./index.module.scss";

interface ISpendingLockDrawer {
  onClose: () => void;
  onSubscriptionPage?: boolean;
}

const SpendingLockDrawer = ({ onClose, onSubscriptionPage }: ISpendingLockDrawer) => {
  const history = useHistory();
  const { setCardDetailsEditState, cardDetailsEditState } = useCardDetailsEditContext();
  const { spendingLockEditDetails, setSpendingLockEditDetails } = useSpendingLockEditContext();
  const { spendingLocks, spendingLockType } = cardDetailsEditState;
  const { selectedSpendingLockType } = spendingLockEditDetails || {};

  const getInitialSpendingLocks = () => {
    if (spendingLockType !== selectedSpendingLockType) return [];
    let initialSpendingLocks = [...(spendingLocks || [])];
    if (spendingLockType === SPENDING_LOCK_TYPES.CATEGORY) {
      const [toBeAddedCategoryLockId, toBeAddedCategoryLockName] = getParamsValue(
        URL_PARAMS_KEYS.TO_BE_ADDED_CATEGORY_LOCK_ID,
        URL_PARAMS_KEYS.TO_BE_ADDED_CATEGORY_LOCK_NAME
      );
      if (toBeAddedCategoryLockId && toBeAddedCategoryLockName) {
        initialSpendingLocks.push({
          name: toBeAddedCategoryLockName,
          id: toBeAddedCategoryLockId,
        });
      }
    }
    return initialSpendingLocks;
  };

  const [selectedLocks, setSelectedLocks] = React.useState<ISpendingLockWithNameAndId[]>(getInitialSpendingLocks());

  const onSave = () => {
    setCardDetailsEditState((prevState) => ({
      ...prevState,
      [CARD_DETAILS_FIELDS_MAP.SPENDING_LOCK_TYPE]:
        selectedLocks?.length > 0 ? selectedSpendingLockType : SPENDING_LOCK_TYPES.NONE,
      [CARD_DETAILS_FIELDS_MAP.SPENDING_LOCKS]: selectedLocks,
    }));
    setSpendingLockEditDetails(null);
    /* remove any params present in url for adding category lock */
    !onSubscriptionPage && history?.replace("cards");
  };

  const onChange = (userSelectedLocks) => {
    let selectedLocks = [];
    if (selectedSpendingLockType === SPENDING_LOCK_TYPES.MERCHANT) {
      selectedLocks = userSelectedLocks?.map((merchant) => ({
        id: merchant,
        name: merchant,
      }));
    } else {
      selectedLocks = userSelectedLocks?.map((lock) => ({
        id: lock?.id,
        name: lock?.name,
      }));
    }
    setSelectedLocks(selectedLocks?.length > 0 ? selectedLocks : []);
  };

  return (
    <>
      <div className={styles["spending-lock-edit__container"]}>
        {selectedSpendingLockType === SPENDING_LOCK_TYPES.CATEGORY ? (
          <CategorySpendingLock
            onChange={onChange}
            initialSpendingLocks={
              (spendingLockType === SPENDING_LOCK_TYPES.CATEGORY &&
                selectedLocks?.map((lock) => ({ name: lock?.name, id: lock?.id }))) ||
              []
            }
            totalShimmerToRender={20}
          />
        ) : (
          <MerchantSpendingLock
            onChange={onChange}
            initialSpendingLocks={
              (spendingLockType === SPENDING_LOCK_TYPES.MERCHANT && selectedLocks?.map((lock) => lock?.name)) || []
            }
            totalShimmerToRender={20}
          />
        )}
      </div>
      <ActionButtons onCancel={onClose} onSave={onSave} />
    </>
  );
};

export default SpendingLockDrawer;
