import React from "react";
import { Row, Col } from "Splice/Grid";
import { Skeleton } from "antd";
import "./shimmer.scss";

export const TeamShimmer = ({ loading }) => {
  return (
    <Row offset={0} className="teamConatinerShimmer">
      {Array.from({ length: 8 })?.map(() => (
        <>
          <Col span={24} className="paragraph">
            <Skeleton.Avatar active className="avatar" shape={"circle"} />
            <Skeleton.Button active className="contentBtn" />
            <Skeleton.Button active className="contentBtn" />
            <Skeleton.Button active className="contentBtn" />
            <Skeleton.Button active className="contentBtn" />
          </Col>
          <Col span={24} className="divider"></Col>
        </>
      ))}
    </Row>
  );
};
