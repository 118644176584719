import React from "react";
import IButton from "Modules/button";
import { smartCSVExport } from "assets/img";

const DownloadCustomCSVButton = (props) => {
  const { action } = props;

  return (
    <IButton id="exportCsv" action={action} icon={<img alt="download" src={smartCSVExport} />}>
      <div className="button-children">
        <p className="button-header">Custom CSV</p>
        <p className="button-sub_header">Export data to CSV template from online accounting software.</p>
      </div>
    </IButton>
  );
};

export default DownloadCustomCSVButton;
