import React, { useState } from "react";
import PropTypes from "prop-types";
import { Form, Input, Row, Radio, Col } from "antd";
import { exclamationMarkWithCircle, rightArrowWhiteLg, deleteIcon } from "assets/img";

import Button from "Modules/button";
import Icon from "Modules/icons";
import OverlayLoader from "Modules/overlayLoader";
import { ValidateNumber } from "utility/index";
import PhoneCodesDropdown from "Modules/phoneCodesDropdown";
import ModalWrapper from "Modules/DS/Modal";

import AddEmpConfirmModal from "Views/Employee/Components/ConfirmModal";
import RoleExplanationModal from "Views/Employee/RoleExplanationModal";
import { ACCOUNTANT_ROLE_ID, ADMIN_ROLE_ID, AUDITOR_ROLE_ID } from "Views/Employee/types.ts";

import "./AddEmployeeForm.scss";

const AddEmployeeForm = (props) => {
  const { action, roles = [], phoneCodes = [], orgCurrency = "62" } = props;
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [formValues, setFormValues] = useState([]);
  const initialFormValue = { phoneCode: orgCurrency };
  const alphabetPattern = new RegExp(/^[a-zA-Z ]*$/);
  const initialValues = { employees: [initialFormValue] };

  const onFinish = (values) => {
    const isPhoneNumNull = values?.employees?.some(
      (emp) => [ACCOUNTANT_ROLE_ID, ADMIN_ROLE_ID].includes(emp.roleId) && !emp?.phoneNumber
    );

    if (isPhoneNumNull) {
      setShowConfirmModal(true);
      setFormValues(values);
    } else {
      action({ values });
    }
  };

  const onAddEmpConfirm = () => {
    action({ values: formValues });
    setShowConfirmModal(false);
  };

  const getRoles = () => {
    return roles?.map((role, index) => (
      <Radio.Button key={`role-${index}`} value={role.id}>
        {role.name}
      </Radio.Button>
    ));
  };

  const handleRoleButton = () => {
    if (Array.isArray(roles)) {
      return getRoles();
    }
    return <OverlayLoader show />;
  };

  const handlePhoneCodeChange = (code, key) => {
    let employees = form.getFieldValue("employees");
    employees[key].phoneCode = code;
    form.setFieldsValue({ employees });
  };

  const getPhoneCode = (key) => {
    return form.getFieldValue("employees")?.[key]?.phoneCode;
  };

  const handlePhoneNumRequiredCheck = (key) => {
    let roleId = form.getFieldValue("employees")?.[key]?.roleId;
    return ![ACCOUNTANT_ROLE_ID, AUDITOR_ROLE_ID, ADMIN_ROLE_ID].includes(roleId);
  };

  const onRoleUpdate = (prevValues, curValues, key) => {
    return prevValues?.employees?.[key]?.roleId !== curValues?.employees?.[key]?.roleId;
  };

  return (
    <>
      <Form
        form={form}
        colon={false}
        name="employee_form"
        className={"add-employee-form"}
        onFinish={onFinish}
        requiredMark
        initialValues={{ ...initialValues }}
        autoComplete="off"
      >
        <Form.List name="employees">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, key) => (
                <div key={key}>
                  <div className={"employee_details--header"}>
                    <h3 className={"add-employee-form__title"}>Employee {field.name + 1}</h3>
                    {field.name !== 0 && (
                      <Button
                        textButton
                        className={"add-employee-form__delete-btn"}
                        action={() => remove(field.name)}
                        icon={<Icon src={deleteIcon} />}
                        text={"Delete"}
                      />
                    )}
                  </div>
                  <Row key={`field-1-${key}`} gutter={[24, 0]}>
                    <Col span={8}>
                      <Form.Item
                        {...field}
                        className="first-name"
                        label="First name"
                        name={[field.name, "firstName"]}
                        fieldKey={[field.fieldKey, "firstName"]}
                        rules={[
                          { required: true, message: "Please enter first name" },
                          { pattern: alphabetPattern, message: "Please use alphabetical only" },
                        ]}
                      >
                        <Input placeholder={"Enter first name"} />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        {...field}
                        className="last-name"
                        label="Last name"
                        name={[field.name, "lastName"]}
                        fieldKey={[field.fieldKey, "lastName"]}
                        rules={[
                          { required: true, message: "Please enter last name" },
                          { pattern: alphabetPattern, message: "Please use alphabetical only" },
                        ]}
                      >
                        <Input placeholder={"Enter last name"} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row key={`field-2-${key}`} align={"bottom"} gutter={[24, 0]}>
                    <Col span={8}>
                      <Form.Item
                        {...field}
                        label="Email"
                        className="email"
                        name={[field.name, "email"]}
                        fieldKey={[field.fieldKey, "email"]}
                        rules={[
                          { required: true, message: "Please enter email" },
                          { type: "email", message: "Please enter correct email format" },
                        ]}
                      >
                        <Input placeholder={"Enter email"} />
                      </Form.Item>
                    </Col>
                    <Form.Item
                      noStyle
                      shouldUpdate={(prevValues, curValues) => onRoleUpdate(prevValues, curValues, key)}
                    >
                      {() => (
                        <Form.Item
                          {...field}
                          className="country-code-field"
                          label="Phone number"
                          required={handlePhoneNumRequiredCheck(key)}
                          name={[field.name, "phoneCode"]}
                          fieldKey={[field.fieldKey, "phoneCode"]}
                        >
                          <PhoneCodesDropdown
                            currentValue={getPhoneCode(field.name)}
                            phoneCodes={phoneCodes}
                            placeholder={"country code"}
                            action={(code) => handlePhoneCodeChange(code, field.name)}
                          />
                        </Form.Item>
                      )}
                    </Form.Item>
                    <Form.Item
                      noStyle
                      shouldUpdate={(prevValues, curValues) => onRoleUpdate(prevValues, curValues, key)}
                    >
                      {() => (
                        <Form.Item
                          {...field}
                          className="phone-number-field"
                          name={[field.name, "phoneNumber"]}
                          fieldKey={[field.fieldKey, "phoneNumber"]}
                          rules={[
                            {
                              pattern: ValidateNumber(),
                              message: "phone number should only contain numbers",
                            },
                            {
                              required: handlePhoneNumRequiredCheck(key),
                              message: "Please enter phone number",
                            },
                          ]}
                        >
                          <Input maxLength={15} placeholder={"Enter phone number"} />
                        </Form.Item>
                      )}
                    </Form.Item>
                  </Row>
                  <Form.Item
                    {...field}
                    label="Role"
                    className="role-label"
                    tooltip={{
                      icon: (
                        <Icon
                          src={exclamationMarkWithCircle}
                          action={() => setVisible(true)}
                          id={"roleGuidelineBtn"}
                          alt={"exclamation"}
                        />
                      ),
                    }}
                    name={[field.name, "roleId"]}
                    fieldKey={[field.fieldKey, "roleId"]}
                    rules={[{ required: true, message: "Please select a role" }]}
                  >
                    <Radio.Group size={"large"}>{handleRoleButton()}</Radio.Group>
                  </Form.Item>
                </div>
              ))}

              <Form.Item>
                <Button
                  action={(e) => {
                    e.preventDefault();
                    add(initialFormValue);
                  }}
                  className="add-employee-btn"
                  secondary
                  text={"+ Add more employee"}
                />
              </Form.Item>

              <Form.Item>
                <Button
                  text={"Add employees"}
                  className="set-employee-btn onboarding-arrow-btn"
                  rounded
                  icon={<Icon src={rightArrowWhiteLg} alt={"arrow"} />}
                />
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form>
      {visible && (
        <ModalWrapper className="add-employees-role-info-modal" visible={visible} close={() => setVisible(false)}>
          <RoleExplanationModal />
        </ModalWrapper>
      )}
      <AddEmpConfirmModal
        show={showConfirmModal}
        close={() => setShowConfirmModal(false)}
        onConfirm={onAddEmpConfirm}
      />
    </>
  );
};

AddEmployeeForm.propTypes = {
  action: PropTypes.func,
  roles: PropTypes.array,
  phoneCodes: PropTypes.array || PropTypes.object,
};

export default AddEmployeeForm;
