import {
  FETCH_PAGINATED_TEAMS_LIST_REQUEST,
  FETCH_PAGINATED_TEAMS_LIST_SUCCESS,
  FETCH_PAGINATED_TEAMS_LIST_FAILURE,
  UPDATE_TEAM_NAME,
  DELETE_FROM_LIST,
} from "Redux/Actions/Teams/paginatedTeamListAction";
import { userInitialState } from "Redux/InitialState";

export const paginatedTeamListReducer = (
  state = {
    ...userInitialState,
  },
  action
) => {
  const { data } = state;
  const { payload } = data;
  switch (action.type) {
    case FETCH_PAGINATED_TEAMS_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case FETCH_PAGINATED_TEAMS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: false,
      };
    case FETCH_PAGINATED_TEAMS_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: true,
      };
    case UPDATE_TEAM_NAME:
      const teamIndexToRename = payload.teams.findIndex((team) => team.id === action.data.id);
      return {
        ...state,
        data: {
          ...data,
          payload: {
            ...payload,
            teams: [
              ...payload.teams.slice(0, teamIndexToRename),
              { ...payload.teams[teamIndexToRename], ...{ team_name: action.data.name } },
              ...payload.teams.slice(teamIndexToRename + 1),
            ],
          },
        },
      };
    case DELETE_FROM_LIST:
      const teamIndexToDelete = payload.teams.findIndex((team) => team.id === action.data);
      return {
        ...state,
        data: {
          ...data,
          payload: {
            ...payload.payload,
            teams: [...payload.teams.slice(0, teamIndexToDelete), ...payload.teams.slice(teamIndexToDelete + 1)],
          },
        },
      };
    default:
      return state;
  }
};
