import React, { useContext } from "react";
import "./index.scss";
import { useClickOutside } from "customHooks/useClickOutside";
import SelectedTag from "./SelectedTag";
import { TagsDropdownContext } from "../context";
import { downArrowIcon24x24 } from "assets/icons/card";

const TagInput = () => {
  const { setOpenDropdown, setOpenSubMenu, openDropdown, selectedChildren, data } = useContext(TagsDropdownContext);
  const [transactionTagsRef] = useClickOutside(
    (e) => {
      if (
        e?.target?.classList[0] === "selected-tags__wrapper__button__icon" ||
        e?.target?.parentElement?.classList[0] === "selected-tags__wrapper__button__icon"
      ) {
        return;
      }

      setOpenSubMenu(false);
      setOpenDropdown(false);
    },
    [".tags-dropdown__popover", ".tag-options-wrapper", ".selected-tags"]
  );

  return (
    <div className="tag-input">
      <div ref={transactionTagsRef}>
        <button
          className="ant-input tag-input__button"
          onClick={() => {
            setOpenDropdown(!openDropdown);
            setOpenSubMenu(false);
          }}
          disabled={!data || !data?.length}
        >
          {data && data?.length ? (
            <div className="tag-input__button__wrapper">
              {selectedChildren?.length === 0 ? (
                <span>Select Tag</span>
              ) : (
                selectedChildren?.map(({ label, value, tagGroup }) => (
                  <SelectedTag key={label} name={`${label} - ${value}`} tagGroup={tagGroup} editable />
                ))
              )}
            </div>
          ) : (
            <p>Transaction tags have not been added</p>
          )}
          <img src={downArrowIcon24x24} alt="down arrow" className="tag-input__button__icon" />
        </button>
      </div>
    </div>
  );
};

export default TagInput;
