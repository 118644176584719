import React from "react";
import { Link } from "react-router-dom";
import { IRemarksBtnDetails } from "Views/KYX/@types";
import styles from "./index.module.scss";

const RedirectionButton: React.FC<IRemarksBtnDetails> = ({ details }: IRemarksBtnDetails): JSX.Element => {
  return (
    <Link className={styles.link} to={details.link}>
      {details.text}
    </Link>
  );
};

export default RedirectionButton;
