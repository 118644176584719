import React, { MouseEvent, useEffect, useMemo } from "react";
import cn from "classnames";
import { Button, Card, Typography, ExternalOutline, Tab } from "@spenmo/splice";
import useSWR from "swr";

import { postData } from "API/Client";
import { API_URL } from "Views/PayInfra/services";

import { PRODUCT_NAME } from "Redux/ModularProduct";
import useCheckOrgConfigs from "customHooks/useCheckOrgConfigs";
import { CARD_VENDOR_TYPE } from "constants/QuickAccess.constant";
import { getCookieValue, cookieNames } from "utility/CookieHelper";
import { getLookerStudioUrl, getSubscriptionContent, getTabItem } from "./helper";
import styles from "./Billing.module.scss";

const Billing = () => {
  const portalSessionPayload = {
    type: "create_portal_session",
    payload: {
      redirect_url: window?.location?.href,
    },
  };
  const { data, isValidating } = useSWR(
    [API_URL.chargebee, JSON.stringify(portalSessionPayload), getCookieValue(cookieNames.AUTH_TOKEN)],
    ([url]: string[]) => postData(url, portalSessionPayload)
  );

  const lookerOrgKey = useCheckOrgConfigs(PRODUCT_NAME.LOOKER_ORG_KEY);
  const isCardVendorNone = useCheckOrgConfigs(PRODUCT_NAME.CARD_VENDOR) === CARD_VENDOR_TYPE.NONE;

  const chargebeePayload = useMemo(() => data?.data?.payload || {}, [data]);
  const { accessUrl } = chargebeePayload;

  const subscriptionPayload = {
    type: "get_main_subscription",
  };

  const { data: changebeeSubscription, isValidating: chargebeeSubscriptionLoading } = useSWR(
    [API_URL.chargebee, JSON.stringify(subscriptionPayload), getCookieValue(cookieNames.AUTH_TOKEN)],
    ([url]: string[]) => postData(url, subscriptionPayload)
  );

  const chargebeeSubscriptionPayload = useMemo(
    () => changebeeSubscription?.data?.payload || {},
    [changebeeSubscription]
  );
  const { nextBillingAt, billingPeriodUnit } = chargebeeSubscriptionPayload;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const isLoading = isValidating || chargebeeSubscriptionLoading || !accessUrl;

  const handleClickBilling = (e: MouseEvent<HTMLAnchorElement>) => {
    if (isLoading) {
      e.preventDefault();
    }
  };

  const tabItem = useMemo(() => getTabItem(isCardVendorNone, lookerOrgKey), [isCardVendorNone, lookerOrgKey]);

  return (
    <div className={styles.billing}>
      <Typography variant="headline-brand" tag="h1" size="m">
        Billing and Usage
      </Typography>
      <Typography variant="body-content" size="m" tag="p" className={styles.vSpace4}>
        Manage your account, edit payment methods and view billing and usage history
      </Typography>
      <Card elevation="ground" radius="m" padding="large" className={cn(styles.billingBanner, styles.vSpace24)}>
        <Typography variant="body-content" size="m" tag="p" weight={600}>
          Your Subscription
        </Typography>
        {getSubscriptionContent(billingPeriodUnit, nextBillingAt)}
        <Typography className={styles.vSpace8} variant="body-content" size="m" tag="p">
          Your Spenmo invoice is currently sent to your organization's administrator via Email
        </Typography>
        <a href={accessUrl} target="_blank" rel="noopener noreferrer" onClick={handleClickBilling}>
          <Button
            className={cn(styles.vSpace16, styles.manageBillingBtn)}
            variant="primary"
            icon={ExternalOutline}
            loading={isLoading}
          >
            Manage Billing
          </Button>
        </a>
      </Card>
      <div className={styles.vSpace24}>
        <Tab id="1" orientation="horizontal" position="start" size="m" items={tabItem} />
      </div>
    </div>
  );
};

export default Billing;
