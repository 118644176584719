import {
  ACCOUNTING_SETTING_MENU,
  BANK_ACCOUNT_MENU,
  CATEGORY_MENU,
  LOW_BALANCE_NOTIFICATION_PAGE,
  REIMBURSEMENT_SETTING_MENU,
  PAYMENT_RUN_SETTING_PAGE,
  TAX_MENU,
  BILLING_PAGE,
} from "Permission/Actions";
import { CASBIN_PAGES } from "Permission/Pages";
import usePermissionCheck from "Permission/usePermissionCheck";
import useCheckFeatureStatus from "customHooks/featureCheck";
import { getIsLimitModel } from "utility";
import { SPLIT_NAMES, SPLIT_TREATMENT_TYPES } from "Redux/splitio/constants";
import { useIsSaasWithPaymentsOrg } from "customHooks/useIsSaasWithPaymentsOrg";
import useCheckOrgConfigs from "customHooks/useCheckOrgConfigs";

const permissions = [
  {
    object: CASBIN_PAGES.ACCOUNTING_SETTING_MENU,
    action: ACCOUNTING_SETTING_MENU.READ,
  },
  { object: CASBIN_PAGES.CATEGORY_MENU, action: CATEGORY_MENU.READ },
  { object: CASBIN_PAGES.TAX_MENU, action: TAX_MENU.READ },
  {
    object: CASBIN_PAGES.LOW_BALANCE_NOTIFICATION_PAGE,
    action: LOW_BALANCE_NOTIFICATION_PAGE.SAVE_BUTTON,
  },
  { object: CASBIN_PAGES.BANK_ACCOUNT_MENU, action: BANK_ACCOUNT_MENU.READ },
  {
    object: CASBIN_PAGES.REIMBURSEMENT_SETTING_MENU,
    action: REIMBURSEMENT_SETTING_MENU.READ,
  },
  { object: CASBIN_PAGES.PAYMENT_RUN, action: PAYMENT_RUN_SETTING_PAGE.READ },
  { object: CASBIN_PAGES.BILLING_PAGE, action: BILLING_PAGE.WRITE },
];

export const usePermission = () => {
  const [result, loading] = usePermissionCheck(permissions, true) as [boolean[], boolean];
  const paymentRunFlag = useCheckFeatureStatus(SPLIT_NAMES.billPaymentRunPage, true);
  const newCRFlag = useCheckFeatureStatus(SPLIT_NAMES.billNewCR);
  const isSaasWithPaymentsOrg = useIsSaasWithPaymentsOrg();
  const isCREnabled = useCheckOrgConfigs("cash_reimbursement_flag");
  const isBillEnabled = useCheckOrgConfigs("billpay_flag");
  const isLimitModelOrg = getIsLimitModel();

  const companyPagePermission = result?.slice?.(0, 3);

  return {
    loading: loading || paymentRunFlag === SPLIT_TREATMENT_TYPES.CONTROL || newCRFlag === SPLIT_TREATMENT_TYPES.CONTROL,
    ableToOpenIntegrationPage: result[0],
    ableToOpenCategoryPage: result[1],
    ableToOpenTaxPage: result[2],
    ableToOpenLowBalancePage: isSaasWithPaymentsOrg ? result[3] : false,
    notAbleToOpenCompanyPage: Array.isArray(result) && companyPagePermission.every((item) => item === false),
    ableToOpenPayoutPage: isCREnabled && result[4],
    ableToOpenReimbursementsPage: newCRFlag === SPLIT_TREATMENT_TYPES.ON ? result[5] && isCREnabled : false,
    ableToOpenPaymentRunPage:
      paymentRunFlag === SPLIT_TREATMENT_TYPES.ON && isLimitModelOrg && isBillEnabled ? result[6] : false,
    ableToOpenBillingPage: result[7],
  };
};
