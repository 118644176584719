import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Select, Form, Input } from "antd";

import SpDropDownList from "Modules/SpDropDownList";

import { roundValue } from "utility";

import ImageList from "./ImageList";

import { STATUS } from "Views/TransactionsListing/Pending/const";
import Item from "Views/TransactionsListing/Pending/Transaction/Detail/Item";

import "./Other.scss";
import { trackEvent } from "utility/analytics";
import AccountingTags from "Modules/AccountingTags";
import { getApproverNames } from "../../../../helper";

import styles from "./Other.module.scss";

const Other = ({ data, userInfoData, taxesData, formData, setFormData }) => {
  const { Option } = Select,
    { TextArea } = Input,
    [formSearch, setFormSearch] = useState({ category: "", tax: "" });

  const [invoiceImages, setInvoiceImages] = useState(data?.attachments || []),
    [receiptImages, setReceiptImages] = useState(data?.receipts || []),
    [isNotesChanged, setIsNotesChanged] = useState(false);

  const isEditable = formData && setFormData;

  useEffect(() => {
    if (data?.tags && setFormData) {
      setFormData({
        ...formData,
        tags: data?.tags || [],
      });
    }
  }, [data?.tags, setFormData]);

  const onAddImageInvoice = ({ file, preview, isPdf }) => {
    trackEvent("bill upload attachment");
    setFormData({ ...formData, attachmentFiles: [...formData.attachmentFiles, file] });
    setInvoiceImages([...invoiceImages, { url: preview, isPdf }]);
  };

  const onAddImageReceipt = ({ file, preview, isPdf }) => {
    trackEvent("bill upload receipt");
    setFormData({ ...formData, receiptFiles: [...formData.receiptFiles, file] });
    setReceiptImages([...receiptImages, { url: preview, isPdf }]);
  };

  useEffect(() => {
    if (isNotesChanged) {
      trackEvent("bill edit notes");
    }
  }, [isNotesChanged]);

  const setTags = useCallback(
    (val) => {
      const tagsPayload = val.map((item) => ({
        label: item.label,
        value: item.value,
      }));

      setFormData({ ...formData, tags: tagsPayload });
    },
    [formData, setFormData]
  );

  const isWaitingApproval = data?.status === STATUS.waitingApproval;
  const isSendingPayment = data?.status === STATUS.completed || data?.status === STATUS.payoutIssue;

  const tax = taxesData?.payload?.data?.find((item) => item?.id === data?.taxID);
  const category = userInfoData?.payload?.categories?.find((item) => item?.id?.toString() === data?.expenseCategoryID);

  const approvers = data?.approvalSteps?.reduce((acc, curval) => {
    return [...acc, ...(curval?.assignees || [])];
  }, []);

  return (
    <>
      <Item title="Email(s) for payment update">{data?.receiptEmails || "-"}</Item>
      <Item title="Category">
        {isEditable ? (
          <Form.Item>
            <SpDropDownList
              data-testid="bill-edit-category"
              placeholder={isWaitingApproval ? "Select category" : "-"}
              defaultValue={data?.expenseCategoryID}
              disabled={!isWaitingApproval}
              showArrow={isWaitingApproval}
              allowClear={isWaitingApproval}
              onDoSearch={(val) => setFormSearch({ ...formSearch, category: val })}
              onChange={(val) => {
                trackEvent("bill edit category");
                setFormData({ ...formData, expenseCategoryID: val });
              }}
            >
              {Boolean(userInfoData?.payload?.categories?.length) &&
                React.Children.toArray(
                  userInfoData?.payload?.categories
                    .filter((category) => category.category_name.toLowerCase().includes(formSearch.category))
                    .map((category) => <Option value={category.id.toString()}>{category.category_name}</Option>)
                )}
            </SpDropDownList>
          </Form.Item>
        ) : (
          <div data-testid="notes">{category?.category_name || "-"}</div>
        )}
      </Item>
      <div className="transaction-accounting-tags" data-testid="transaction-accounting-tags">
        <AccountingTags onChange={setTags} disabled={!isEditable || !isWaitingApproval} initialTags={data?.tags} />
      </div>
      <Item title="Tax">
        {isEditable ? (
          <Form.Item>
            <SpDropDownList
              data-testid="bill-edit-tax"
              placeholder={isWaitingApproval ? "Select tax" : "-"}
              defaultValue={data?.taxID || undefined}
              disabled={!isWaitingApproval}
              showArrow={isWaitingApproval}
              allowClear={isWaitingApproval}
              onDoSearch={(val) => setFormSearch({ ...formSearch, tax: val })}
              onChange={(val) => {
                trackEvent("bill edit tax");
                setFormData({ ...formData, taxID: val });
              }}
            >
              {taxesData?.payload?.data?.length &&
                React.Children.toArray(
                  taxesData?.payload?.data
                    .filter(
                      (tax) =>
                        tax.tax_name.toLowerCase().includes(formSearch.tax) || tax.tax_rate.includes(formSearch.tax)
                    )
                    .map((tax) => (
                      <Option value={tax.id}>
                        {tax.tax_name} ({roundValue(tax.tax_rate)}%)
                      </Option>
                    ))
                )}
            </SpDropDownList>
          </Form.Item>
        ) : (
          <div data-testid="notes">{tax?.tax_name ? `${tax?.tax_name} (${roundValue(tax?.tax_rate)}%)` : "-"}</div>
        )}
      </Item>
      <Item title="Approver">
        <div data-testid="approver">{getApproverNames(approvers, ", ") || "-"}</div>
      </Item>
      <Item title="Notes">
        {isEditable ? (
          <TextArea
            data-testid="bill-edit-notes"
            rows={2}
            defaultValue={data?.clientNotes || (isWaitingApproval ? "" : "-")}
            disabled={!isWaitingApproval}
            className={styles.notes}
            onChange={(event) => {
              setIsNotesChanged(true);
              setFormData({ ...formData, clientNotes: event.target.value });
            }}
          />
        ) : (
          <div data-testid="notes">{data?.clientNotes || "-"}</div>
        )}
      </Item>
      <Item title="Invoice">
        {(isWaitingApproval || Boolean(invoiceImages?.length)) && (
          <ImageList
            images={invoiceImages}
            data-testid="bill-upload-attachment"
            onAddImage={onAddImageInvoice}
            canAddImage={isEditable && isWaitingApproval}
          />
        )}
      </Item>
      {Boolean(receiptImages?.length) && (
        <Item title={isSendingPayment ? `Proof of Payment` : `Payment Receipt`}>
          <ImageList
            images={receiptImages}
            data-testid="bill-upload-receipt"
            onImageClick={() =>
              trackEvent(`${isSendingPayment ? "proof of payment receipt clicks" : "payment receipt clicks"}`)
            }
            onAddImage={onAddImageReceipt}
            canAddImage={isEditable}
          />
        </Item>
      )}
    </>
  );
};

Other.propTypes = {
  data: PropTypes.object,
  userInfoData: PropTypes.object,
  taxesData: PropTypes.object,
};

export default Other;
