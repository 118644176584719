import React from "react";
import qs from "query-string";
import { Base64 } from "js-base64";
import { currencyFormatterV2 } from "utility";
import { TableRowSelection } from "antd/lib/table/interface";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";

import { useSelectContext } from "Modules/DS/Select";
import { ActionButtonType, BulkActionType, IBulkActionProps } from "Modules/DS/DataTable";

import {
  REIMBURSEMENT_MODAL_OPTIONS,
  REIMBURSEMENT_PAGE_OPERATIONS,
  REIMBURSEMENT_SETTLEMENT_STATUSES,
} from "Views/Reimbursement/Constants";
import { REIMBURSEMENTS_PAGE_ANALYTICS, trackReimbursementAnalytics } from "Views/Reimbursement/Analytics";
import { ILocationParams, IReimbursementRequestData, IUseListRowSelection } from "Views/Reimbursement/@types";

const useRowSelection = (): IUseListRowSelection => {
  const history = useHistory();
  const location = useLocation();

  const query = qs.parse(location.search, {
    parseNumbers: true,
    parseBooleans: true,
  });

  const { bulkIds = "" } = query;

  const { select, closeSelect } = useSelectContext();

  const { params } = useRouteMatch<ILocationParams>();
  const { operation = "", id = "" } = params;

  const isApprovedSettlementsView =
    operation === REIMBURSEMENT_PAGE_OPERATIONS.SETTLEMENTS && id === REIMBURSEMENT_SETTLEMENT_STATUSES.APPROVED.id;

  const clearBulkSelection = () => {
    history.push({
      pathname: location.pathname,
      search: qs.stringify({
        ...query,
        bulkIds: undefined,
        bulkSettlement: undefined,
        payoutValue: undefined,
      }),
    });
  };

  const handleOnChange = (ids: string[], selectedRows: IReimbursementRequestData[]) => {
    closeSelect();

    if (ids.length === 0) {
      clearBulkSelection();
    } else if (ids.length === 1) {
      const payoutVal = currencyFormatterV2(
        selectedRows[0].amount,
        selectedRows[0].currency_code,
        true,
        selectedRows[0].currency_code !== "IDR" ? 2 : 0
      );

      history.push({
        pathname: location.pathname,
        search: qs.stringify({
          ...query,
          bulkIds: Base64.encodeURI(ids),
          bulkSettlement: false,
          payoutValue: Base64.encodeURI(payoutVal),
        }),
      });
    } else if (ids.length > 1) {
      history.push({
        pathname: location.pathname,
        search: qs.stringify({
          ...query,
          bulkIds: Base64.encodeURI(ids),
          bulkSettlement: true,
          payoutValue: undefined,
        }),
      });
    }
  };

  const rowSelection: TableRowSelection<IReimbursementRequestData> = {
    preserveSelectedRowKeys: true,
    selectedRowKeys: Base64.decode(bulkIds as string)
      .split(",")
      .filter((item: string) => item.trim()),
    columnWidth: "var(--size-036)",
    onChange: handleOnChange,
  };

  const bulkAction: IBulkActionProps = {
    clearSelection: clearBulkSelection,
    data: Base64.decode(bulkIds as string)
      .split(",")
      .filter((item: string) => item.trim())
      .map((item: string) => ({ id: item } as IReimbursementRequestData)),
    dataName: "reimbursement",
    supportingInfo: null,
    type: BulkActionType.Default,
  };

  const rowAction = (record: IReimbursementRequestData, e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    e.preventDefault();

    if (select.show) closeSelect();

    if (
      ["ant-table-cell ant-table-selection-column", "sp-checkbox__input"].includes((e.target as HTMLElement).className)
    )
      return;

    history.push({
      pathname: `${location.pathname}/${REIMBURSEMENT_PAGE_OPERATIONS.READ}/${record.id}`,
      search: qs.stringify({
        ...query,
        bulkIds: undefined,
        bulkSettlement: undefined,
        payoutValue: undefined,
      }),
    });
  };

  let props: IUseListRowSelection = { rowAction, rowClassName: (record) => record.id };

  const handleMarkAsSettled = async () => {
    history.push({
      pathname: `${location.pathname}/${REIMBURSEMENT_PAGE_OPERATIONS.MODAL}/${REIMBURSEMENT_MODAL_OPTIONS.MARK_AS_SETTLED}`,
      search: location.search,
    });

    trackReimbursementAnalytics({
      eventName: REIMBURSEMENTS_PAGE_ANALYTICS.SETTLED_REIMBURSEMENT_BULK_ACTION,
      eventSource: "Mark As Settled",
      eventSubSource: "Clicked",
    });

    return Promise.resolve({});
  };

  if (isApprovedSettlementsView) {
    props = {
      ...props,
      rowSelection,
      bulkAction: {
        ...bulkAction,
        firstButton: {
          type: ActionButtonType.Primary,
          label: "Mark as paid",
          handler: handleMarkAsSettled,
        },
      },
    };
  }

  return props;
};

export default useRowSelection;
