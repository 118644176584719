import { initSplitSdk, getTreatments } from "@splitsoftware/splitio-redux";
import { getCookies } from "utility";

import { SPLIT_NAMES } from "./constants";

export const init = () => async (dispatch, getState) => {
  const userId = getState().userInfoReducer.userId || Math.random();

  return dispatch(
    initSplitSdk({
      config: {
        core: {
          authorizationKey: window.__ENV__.SPLIT_API_KEY || "localhost",
          key: userId,
        },
      },
    })
  );
};

export const fetchAllTreatments = () => (dispatch, getState) => {
  const orgId = getState().userInfoReducer?.data?.payload?.user?.organisation_id;
  const currencyCode = getCookies("currency_code");

  dispatch(
    getTreatments({
      splitNames: Object.values(SPLIT_NAMES),
      key: orgId,
      attributes: {
        currency_code: currencyCode,
      },
    })
  );
};
