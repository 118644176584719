import React, { useEffect } from "react";
import { Redirect, Route, useRouteMatch, useHistory } from "react-router-dom";
import { Toast } from "@spenmo/splice";

import LoadingComponent from "Views/State/Loading/LoaderIcon";
import { SUBSCRIPTION_PATH } from "Route/Subscription/path";
import usePermissionCheck from "Permission/usePermissionCheck";
import { COMMON_PATHS } from "Route/Common/paths";
import { useIsSaasWithPaymentsOrg } from "customHooks/useIsSaasWithPaymentsOrg";
import { useIsSessionExpired } from "customHooks/Auth/useIsSessionExpired";
import { CREATE_NEW_SUBSCRIPTION_PARAMS } from "Views/SubscriptionManagement/Permission";
import { SubscriptionManagementFilters } from "Views/SubscriptionManagement/Filters";
import { trackEvent } from "utility/analytics";

import { Header } from "./Header";
import { Empty } from "./Empty";
import { Create } from "./Create";
import SubscriptionList from "./List";
import { FORM_TYPE } from "./Constants";
import { usePermission } from "./hooks/usePermission";
import { useSubscriptionAnalytics, useSubscriptionList } from "./hooks/useSubscriptionList";
import { SubscriptionAnalysis } from "./SubscriptionAnalysis";
import { SUBSCRIPTION_EVENT } from "./Analytic";

const SubscriptionManagement = () => {
  const history = useHistory();
  const isSaasWithPaymentsOrg = useIsSaasWithPaymentsOrg();

  const isSessionExpired = useIsSessionExpired();
  const { subscriptions, isLoading, isValidating, hasFilter, mutate, setSize, hasMoreData } = useSubscriptionList();

  const { path } = useRouteMatch();
  const { loading, allowedToOpenPage } = usePermission();
  const IS_ALLOWED_TO_CREATE_SUBSCRIPTION = (usePermissionCheck(CREATE_NEW_SUBSCRIPTION_PARAMS) as boolean) || false;
  const { mutateSummary, summaryData, isLoadingSummary, spendAnalysisData, spendAnalysisLoading } =
    useSubscriptionAnalytics();

  useEffect(() => {
    trackEvent(SUBSCRIPTION_EVENT.ACCESS_SUBSCRIPTION_PAGE);
  }, []);

  if (loading) {
    return <LoadingComponent />;
  }

  if ((!allowedToOpenPage || !isSaasWithPaymentsOrg) && !isSessionExpired) {
    return <Redirect to="/" />;
  }

  const onSubscriptionCreation = () => {
    mutate();
    mutateSummary();
    history.push(SUBSCRIPTION_PATH.SUBSCRIPTION_MANAGEMENT);
  };

  return (
    <section>
      <Header isAllowedToCreateSubscription={IS_ALLOWED_TO_CREATE_SUBSCRIPTION} />
      {subscriptions.length === 0 && !hasFilter && !isLoading ? (
        <Empty />
      ) : (
        <>
          <SubscriptionAnalysis
            summaryData={summaryData}
            isLoadingSummary={isLoadingSummary}
            spendAnalysisData={spendAnalysisData}
            spendAnalysisLoading={spendAnalysisLoading}
          />
          <SubscriptionManagementFilters />
          <SubscriptionList
            data={subscriptions}
            loading={isLoading}
            loadingMoreData={isValidating}
            onReachEnd={() => {
              if (hasMoreData) {
                setSize((size) => size + 1);
              }
            }}
          />
        </>
      )}
      {IS_ALLOWED_TO_CREATE_SUBSCRIPTION && (
        <Route path={`${path}/new`}>
          <Create
            formType={FORM_TYPE.CREATE}
            onCreate={onSubscriptionCreation}
            onClose={() => history.push(SUBSCRIPTION_PATH.SUBSCRIPTION_MANAGEMENT)}
          />
        </Route>
      )}
      <Toast />
    </section>
  );
};

export default SubscriptionManagement;
