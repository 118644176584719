import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getCookieValue, cookieNames } from "utility/CookieHelper";
import { USER_ROLE } from "constants/Team.constant";
import { fetchUserWalletBalances, resetUserWalletBalances } from "Redux/Actions/user/WalletBalancesList";
import { fetchUserWalletBalancesListData } from "Redux/Reducers/user/WalletBalancesListReducer";

export const TEAMS_TO_FETCH = 5;

const checkIfNumeric = (value) => {
  const valueToString = value?.toString?.()?.trim?.() || "";
  return valueToString ? !isNaN(valueToString) : false;
};

const checkIfIsManagerOrAdminOrAccountant = (role, companyRole) => {
  return (
    role === USER_ROLE.MANAGER ||
    companyRole === USER_ROLE.ADMIN ||
    companyRole === USER_ROLE.ACCOUNTANT ||
    companyRole === USER_ROLE.AUDITOR
  );
};

const flattenUserWalletBalancesData = (walletsData, isWalletUser = false) => {
  if (Array.isArray(walletsData) && walletsData.length) {
    const orgCurrencyCode = getCookieValue(cookieNames.CURRENCY_CODE);
    const companyRole = getCookieValue(cookieNames.USER_COMPANY_ROLE);
    return walletsData.map((walletData) => {
      const { team_name, personal_balance, monthwise_spending, available_balance, currency_code, role } =
        walletData || {};
      return {
        teamName: team_name || "",
        personalBalance: (checkIfNumeric(personal_balance) && personal_balance?.toString()) || "",
        totalTeamSpending: (checkIfNumeric(monthwise_spending) && monthwise_spending?.toString()) || "",
        availableTeamBalance: (checkIfNumeric(available_balance) && available_balance?.toString()) || "",
        currencyCode: currency_code || orgCurrencyCode || "",
        isManagerOrAdminOrAccountant: checkIfIsManagerOrAdminOrAccountant(role, companyRole),
        isWalletUser: isWalletUser,
      };
    });
  }
  return [];
};

const INITIAL_PAGE_COUNT = 0;

export const useFetchUserWalletBalancesList = () => {
  const dispatch = useDispatch();
  const {
    data: userWalletBalancesData,
    loading: userWalletBalancesDataLoading,
    error: userWalletBalancesDataError,
  } = useSelector(fetchUserWalletBalancesListData);
  const { data: userData } = useSelector((state) => state?.userInfoReducer);

  const [userWalletBalancesList, setUserWalletBalancesList] = useState([]);
  const [page, setPage] = useState(INITIAL_PAGE_COUNT);
  const [totalUserWallets, setTotalUserWallets] = useState(null);

  const processUserWalletBalancesData = () => {
    const { data = [], count } = userWalletBalancesData?.payload || {};
    const { is_wallet_user } = userData?.payload?.user || {};
    const flattenData = flattenUserWalletBalancesData(data, is_wallet_user);
    const widthThreshold = 1920;
    if (flattenData.length) {
      setUserWalletBalancesList((prevState) => [...prevState, ...flattenData]);
      setPage((prevPage) => prevPage + 1);
      setTotalUserWallets(count);
      dispatch(resetUserWalletBalances());
      if (count > TEAMS_TO_FETCH && page === 0 && window.innerWidth > widthThreshold) {
        dispatch(fetchUserWalletBalances(page + 1, TEAMS_TO_FETCH));
      }
    }
  };

  const fetchMoreUserWalletBalancesData = () => {
    if (userWalletBalancesList.length < totalUserWallets && !userWalletBalancesDataLoading) {
      dispatch(fetchUserWalletBalances(page, TEAMS_TO_FETCH));
    }
  };

  useEffect(() => {
    dispatch(fetchUserWalletBalances(page, TEAMS_TO_FETCH));
    return () => {
      dispatch(resetUserWalletBalances());
      setUserWalletBalancesList([]);
    };
  }, []);

  useEffect(() => {
    processUserWalletBalancesData();
  }, [userWalletBalancesData]);

  return [
    userWalletBalancesList,
    fetchMoreUserWalletBalancesData,
    userWalletBalancesDataLoading,
    userWalletBalancesDataError,
  ];
};
