import APIclient from "API/Client";
import { GetBaseAuthObject } from "utility";
import AxiosGetForApiWrapper from "utility/apiWrapperHelper";
import { urlParamsBuilder } from "utility/APIWrapper";

// When doing category page revamp, I update the code to fetch data to use axios instead of fetch,
// It turns out that this broke other test cases that already mock the fetch request.
// This is some workaround to support both fetch and axios version of the function
export const GetCategoriesWithAxios = () => {
  return APIclient.getData("ms/b2b-accounting/v1/categories");
};

export const GetActiveCategories = () => {
  return APIclient.getData("ms/b2b-accounting/v1/categories?status=active");
};

export const GetCategories = () => {
  return AxiosGetForApiWrapper(
    `/api/v1/user/info/${GetBaseAuthObject().userId}?organisation_id=${GetBaseAuthObject().orgId}`
  );
};

export const ImportCategories = (formattedCategories) => {
  return APIclient.postData("/api/v1/partner/xero/categories", {
    organisation_id: GetBaseAuthObject().orgId,
    account_code_map: JSON.stringify(formattedCategories),
    import_categories: true,
  });
};

export const CreateCategory = (payload) => {
  return APIclient.postData("/ms/b2b-accounting/v1/categories", payload);
};

export const UpdateCategory = (id, payload) => {
  return APIclient.putData(`/ms/b2b-accounting/v1/categories/${id}`, payload);
};

export const MissingCategory = () => {
  return APIclient.getData(
    urlParamsBuilder("ms/b2b-accounting/v2/partners/accounts", {
      accountType: "expense",
      page: 1,
      limit: 1000,
      unImported: true,
    })
  );
};
