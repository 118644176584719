import React from "react";

import Modal from "Modules/modal";

import LoadingComponent from "Views/State/Loading/LoaderIcon";

import "./loadingScreen.scss";

const LoadingScreen = () => {
  return (
    <div className="loading-screen">
      <Modal show>
        <LoadingComponent />
      </Modal>
    </div>
  );
};

export default LoadingScreen;
