import { useCallback, useRef, useState } from "react";
import axios, { CancelTokenSource } from "axios";

import APIclient from "API/Client";

import { GetOrgId } from "utility";
import { urlParamsBuilder } from "utility/APIWrapper";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";

import { API_URL } from "Views/Reimbursement/DataCalls/Services";

const searchMerchantList = async (value: string, source: CancelTokenSource) => {
  const payload = {
    organisation_id: GetOrgId(),
    keywordField: "merchant",
    keywordValue: value,
    orderBy: "ASC",
  };

  const config = { cancelToken: source.token };

  const url = urlParamsBuilder(API_URL.FILTER.MERCHANT, payload);

  try {
    const response = await APIclient.getData(url, null, null, config);
    return response.data;
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log("Request canceled", error.message);
    } else {
      throw error;
    }
  }
};

export const useReimbursementMerchantSearch = () => {
  const latestRequest = useRef<CancelTokenSource>(null);

  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const [data, setData] = useState<string[]>([]);

  const resetData = () => setData([]);

  const fetchMerchants = useCallback((value: string) => {
    if (latestRequest.current) {
      latestRequest.current.cancel("Invalidating previous requests");
    }

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    latestRequest.current = source;

    setLoading(true);
    setError(false);
    setErrorMessage("");

    if (!value) {
      setData([]);
      setError(false);
      setLoading(false);
      setErrorMessage("");
      return;
    }

    searchMerchantList(value, source)
      .then((resp) => {
        if (resp.status > HTTP_STATUS_CODE.OK) {
          setError(true);
          setErrorMessage(resp.data.payload.status_message);
          setData([]);
        } else {
          setError(false);
          const response =
            Array.isArray(resp.payload.merchants) && resp.payload.merchants.length > 0 ? resp.payload.merchants : [];
          setData(response.map((item: { merchant: string }) => item.merchant));
        }
      })
      .catch((error) => {
        setError(true);
        setData([]);
        setErrorMessage(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return { error, loading, data, fetchMerchants, resetData, errorMessage };
};
