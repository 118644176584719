import { TRANSACTION_TYPES } from "Views/Transaction/Constants";
import { includesReversalTrxns } from "./includesReversalTrxns";

// Adding business logic to hide edit functions
export const editNotAllowed = (type: string) => {
  const notHaveAttachment = [TRANSACTION_TYPES.TOPUP, TRANSACTION_TYPES.TRANSFER] as string[];
  return notHaveAttachment.includes(type);
};

export const isEditableTrxnType = (type: string) => !includesReversalTrxns(type) && !editNotAllowed(type);
