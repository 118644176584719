import React, { useEffect, useState } from "react";
import { getDynamicSLA, GetDynamicSLAParams } from "Redux/DataCalls/DynamicSLA.api";
import { Tooltip, TooltipArrowAlignmentEnum, TooltipPositionEnum } from "Modules/DS/Tooltip";
import { newInfo } from "assets/img";
import { Skeleton } from "antd";
import classNames from "classnames";

import styles from "./DynamicSLA.module.scss";

export interface SLA {
  id: number;
  sla: string;
}

export interface DynamicSLALabelProps {
  tooltipArrowAlignment?: TooltipArrowAlignmentEnum;
  tooltipMessage: string;
  className?: string;
  tooltipPosition?: TooltipPositionEnum;
}

export interface DynamicSLAValueProps extends GetDynamicSLAParams {
  onChangeSLA?: (sla: SLA) => void;
  message?: string | React.ReactNode;
  className?: string;
}

export enum DynamicSLAVariant {
  horizontal = "horizontal",
  vertical = "vertical",
}

export interface DynamicSLAProps extends DynamicSLAValueProps, DynamicSLALabelProps {
  variant?: DynamicSLAVariant;
  className?: string;
  labelClassName?: string;
  valueClassName?: string;
}

export const DynamicSLALabel: React.FC<DynamicSLALabelProps> = ({
  tooltipMessage,
  tooltipArrowAlignment = TooltipArrowAlignmentEnum.CENTER,
  tooltipPosition = TooltipPositionEnum.TOP,
  className,
}) => {
  return (
    <div className={classNames(styles.label, className)}>
      <div>Estimated Arrival</div>
      {Boolean(tooltipMessage) && (
        <Tooltip text={tooltipMessage} alignArrow={tooltipArrowAlignment} position={tooltipPosition}>
          <img src={newInfo} alt="SLA Information" />
        </Tooltip>
      )}
    </div>
  );
};

export const DynamicSLAValue: React.FC<DynamicSLAValueProps> = ({
  billID,
  amount,
  beneficiaryBankCountry,
  currency,
  senderCountry,
  message,
  onChangeSLA,
  className,
}) => {
  const [sla, setSla] = useState<SLA>(undefined);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    onChangeSLA?.(sla);
  }, [sla, onChangeSLA]);

  useEffect(() => {
    if (
      Boolean(billID) ||
      (Boolean(amount) && Boolean(beneficiaryBankCountry) && Boolean(currency) && Boolean(senderCountry))
    ) {
      setIsLoading(true);
      const onChangeSLATimeout = setTimeout(() => {
        getDynamicSLA({
          billID,
          amount,
          beneficiaryBankCountry,
          currency,
          senderCountry,
        })
          .then((res) => {
            res?.data?.payload && setSla(res.data.payload);
          })
          .catch(() => {
            setSla({ id: 0, sla: "" });
          })
          .finally(() => {
            setIsLoading(false);
          });
      }, 2000);
      return () => clearTimeout(onChangeSLATimeout);
    }
  }, [billID, amount, beneficiaryBankCountry, currency, senderCountry]);

  const renderSla = sla?.sla ? (
    <>
      {sla.sla}
      {Boolean(message) && <div className={styles.message}>{message}</div>}
    </>
  ) : null;

  return (
    <div className={classNames(styles.value, className)}>
      {isLoading ? <Skeleton.Input className={styles.loading} active data-testid="sla-loading" /> : renderSla}
    </div>
  );
};

const DynamicSLA: React.FC<DynamicSLAProps> = ({
  billID,
  amount,
  beneficiaryBankCountry,
  currency,
  senderCountry,
  tooltipMessage,
  message,
  onChangeSLA,
  variant = DynamicSLAVariant.horizontal,
  tooltipArrowAlignment,
  className,
  labelClassName,
  valueClassName,
}) => {
  const [sla, setSla] = useState<SLA>(undefined);
  const variantClassname = variant === DynamicSLAVariant.vertical ? styles["container-vertical"] : styles.container;

  useEffect(() => {
    onChangeSLA?.(sla);
  }, [sla, onChangeSLA]);

  if (sla === undefined || Boolean(sla?.sla)) {
    return null;
  }

  return (
    <div className={classNames(variantClassname, className)}>
      <DynamicSLALabel
        tooltipMessage={tooltipMessage}
        tooltipArrowAlignment={tooltipArrowAlignment}
        className={labelClassName}
      />
      <DynamicSLAValue
        billID={billID}
        amount={amount}
        beneficiaryBankCountry={beneficiaryBankCountry}
        currency={currency}
        senderCountry={senderCountry}
        onChangeSLA={setSla}
        message={message}
        className={valueClassName}
      />
    </div>
  );
};

export default DynamicSLA;
