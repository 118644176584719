import React from "react";
import classNames from "classnames";
import { BUTTON_SIZES, BUTTON_TYPES, TitleButtonProps } from "../types";

import Loader from "Modules/DS/Loader";
import { LOADER_SIZES, LOADER_TYPES } from "Modules/DS/Loader/types";
import styles from "../index.module.scss";

export const TitleButton = ({
  children,
  disabled,
  loading,
  type = BUTTON_TYPES.PRIMARY,
  size = BUTTON_SIZES.MEDIUM,
  onClick,
}: TitleButtonProps): JSX.Element => {
  const _classNames = classNames(styles.button, styles[type], styles[size], {
    [styles.disabled]: disabled,
    [styles.loading]: loading,
  });
  const LoaderSize = [BUTTON_SIZES.EXTRA_LARGE, BUTTON_SIZES.LARGE].includes(size)
    ? LOADER_SIZES.BIG
    : LOADER_SIZES.SMALL;
  const LoaderType = type === BUTTON_TYPES.PRIMARY ? LOADER_TYPES.WHITE : LOADER_TYPES.BRAND;

  return (
    <button disabled={disabled} onClick={onClick} className={_classNames}>
      {loading ? <Loader size={LoaderSize} type={LoaderType} /> : children}
    </button>
  );
};

export default TitleButton;
