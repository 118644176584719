import { useEffect } from "react";

export const usePreventClose = () => {
  useEffect(() => {
    const confirmExit = (e) => {
      e.preventDefault();
      e.returnValue = true;
    };
    window.addEventListener("beforeunload", confirmExit);
    return () => {
      window.removeEventListener("beforeunload", confirmExit);
    };
  }, []);
};
