import React from "react";

import { MenuItem } from "Views/Navigation/Menu/Item";
import { useBottomMenuList } from "Views/Navigation/Config";
import { ISidebarMenuConfig } from "Views/Navigation/@types";

import styles from "./index.module.scss";

const BottomMenu: React.FC = (): React.ReactElement => {
  const handleBottomMenuList = (list: ISidebarMenuConfig[]) => {
    return list.map((menu) => <MenuItem key={menu.id} {...menu.props} ref={menu.ref} />);
  };

  return <div className={styles.container}>{handleBottomMenuList(useBottomMenuList())}</div>;
};

export default BottomMenu;
