export const removeFalsyValues = (obj: any): any => {
  if (typeof obj !== "object" || obj === null) return obj;

  if (Array.isArray(obj)) {
    return obj
      .map((value: any) => removeFalsyValues(value))
      .filter((value: any) => value !== null && value !== undefined && value !== "");
  }

  const newObj: {
    [key: string]: any;
  } = {};
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = obj[key];
      if (value !== null && value !== undefined && value !== "") {
        newObj[key] = removeFalsyValues(value);
      }
    }
  }
  return newObj;
};
