import React, { useContext, useState } from "react";

import { Quick } from "Modules/DS/Filter";
import { IItemID } from "Modules/DS/Menu";
import { POSITION_ALGO_TYPE } from "Modules/DS/Popover";
import { IMultipleQuickFilterWithElaticSearch } from "Modules/DS/Filter/@types";

import { TrxnFilterContext, TrxnPaginationContext } from "Views/Transaction/Context";
import { ALL_TRXN_PAGINATION_PARAMS } from "Views/Transaction/Constants";
import { ITrxnFilterContext, ITrxnPaginationContext, ITrxnElasticQuickFilter } from "Views/Transaction/@types";

export const ElasticSelect = <T extends IItemID>({
  id,
  children,
  idKey,
  displayKey,
  appliedKey = "id",
  defaultValue,
  loading = false,
  iconClass,
  onClickOutside,
  height,
  onSearch = () => null,
  onClearSearch = () => null,
  dataSet = [],
  dispatchOnSelect = () => null,
  dispatchOnClear = () => null,
}: ITrxnElasticQuickFilter<T>) => {
  const { resetTrxnPaginationParams, onApplyFilter, resetFilter, filter, clearAll } =
    useContext<ITrxnFilterContext>(TrxnFilterContext);

  const [title, setTitle] = useState<string>("");
  const [selectedIds, setSelectedIds] = useState<string[]>(() => {
    return filter["merchant"] || [];
  });

  const { resetCurrentPage } = useContext<ITrxnPaginationContext>(TrxnPaginationContext);
  const onSelectHandler = (value: string[]) => {
    onApplyFilter({
      [id]: value,
      ...resetTrxnPaginationParams,
    });

    dispatchOnSelect?.(value);
    resetCurrentPage();
  };

  const clearFilter = () => {
    resetFilter([id, ...ALL_TRXN_PAGINATION_PARAMS]);
    dispatchOnClear?.();
    resetCurrentPage();
  };

  const props: IMultipleQuickFilterWithElaticSearch<T> = {
    idKey,
    displayKey,
    appliedKey,
    iconClass,
    onClickOutside,
    height,
    clearFilter,
    defaultValue,
    onClearSearch,
    dataSet,
    onSearch,
    clear: clearAll,
    presetValue: filter[id],
    children: children,
    filterItemProps: { id, containerClass: ".data-table-container", positionAlgoType: POSITION_ALGO_TYPE.HORIZONTAL },
    onAddSelected: onSelectHandler,
    states: { selectedIds, setSelectedIds, title, setTitle, loading },
  };

  return <Quick.Elastic<T> {...props} />;
};
