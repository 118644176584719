import React from "react";
import { Form, Radio } from "antd";
import classNames from "classnames";
import { IRadioGroupProps } from "Views/KYX/@types";

/**
 * A component that renders a group of radio buttons.
 *
 * @param {Object} restProps - Any additional props to pass to the underlying Ant Design `Radio.Group` component.
 * @returns {JSX.Element} - The rendered `KYXRadioGroup` component.
 */

export const KYXRadioGroup = ({ formProps, radioProps }: IRadioGroupProps): JSX.Element => {
  const { className } = radioProps || {};

  return (
    <Form.Item {...formProps}>
      <Radio.Group className={classNames({ [className || ""]: className })} {...radioProps} />
    </Form.Item>
  );
};
