import { useState } from "react";
import { appNotification } from "../Modules/appNotification/appNotification";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";

const successStatus = [
  HTTP_STATUS_CODE.OK,
  HTTP_STATUS_CODE.CREATED,
  HTTP_STATUS_CODE.ACCEPTED,
  HTTP_STATUS_CODE.NO_CONTENT,
];

const useLoading = (action, generateErrorMessage, config = {}, withAppNotification = true) => {
  const [loading, setLoading] = useState(false);

  const doAction = async (...args) => {
    setLoading(true);
    try {
      const response = await action(...args);

      if (!successStatus.includes(response.data.status)) {
        withAppNotification &&
          appNotification.error({
            message: response.data.payload.status_message,
            ...config,
          });
        if (!withAppNotification) {
          throw response.data;
        }
      }

      return response;
    } catch (error) {
      withAppNotification &&
        appNotification.error({
          message: generateErrorMessage ? generateErrorMessage(error.response) : JSON.stringify(error),
          ...config,
        });
      if (!withAppNotification) {
        throw error;
      }
    } finally {
      setLoading(false);
    }
  };

  return [doAction, loading];
};

export default useLoading;
