import { useEffect, useState } from "react";
import { getCookieValue, cookieNames } from "../utility/CookieHelper";
import useDetectMobile from "../utility/useDetectMobile";

export type HookState = {
  showAlert: boolean;
  setShowAlert: React.Dispatch<React.SetStateAction<boolean>>;
};

const useShowAlert = (): HookState => {
  const CONTINUE_MOBILE_VIEW: boolean =
    JSON.parse(getCookieValue(cookieNames.CONTINUE_MOBILE_VIEW) || "false") || false;
  const isMob: boolean = useDetectMobile();
  const [showAlert, setShowAlert] = useState<boolean>(false);

  useEffect(() => {
    if (document.getElementById("root") && isMob && !CONTINUE_MOBILE_VIEW) {
      document.getElementById("root").style.overflow = "hidden";
    } else {
      document.getElementById("root").style.removeProperty("overflow");
    }

    setShowAlert(isMob && !CONTINUE_MOBILE_VIEW);
    return () => {
      document.getElementById("root").style.removeProperty("overflow");
    };
  }, [showAlert]);

  return { showAlert, setShowAlert };
};

export default useShowAlert;
