import React from "react";
import { Skeleton } from "antd";
import cn from "classnames";

import { SkeletonProps } from "Views/Bills/V2/PaymentRunPreview/type";
import paymentRunItemStyles from "Views/Bills/V2/PaymentRun/BillDetail/index.module.scss";
import paymentRunPreviewStyles from "Views/Bills/V2/PaymentRunPreview/PaymentRunPreview.module.scss";
import PaymenRunPreviewMethodStyles from "Views/Bills/V2/PaymentRunPreview/PaymentMethod/PaymentMethod.module.scss";

export const BillDetailCardSkeleton: React.FC<SkeletonProps> = (props) => {
  const { loading, children } = props;
  const bills = new Array(5).fill(true);

  if (loading) {
    return (
      <>
        <Skeleton
          active
          title={false}
          paragraph={{
            rows: 1,
            width: 150,
          }}
          className={paymentRunPreviewStyles.verticalSpace}
        />
        <div className={paymentRunPreviewStyles.verticalSpace}>
          {bills?.map((_, index) => {
            return (
              <div
                key={`paymentRunSkeleton-${index}`}
                className={cn(paymentRunPreviewStyles.verticalSpace, paymentRunItemStyles.container)}
              >
                <div className={paymentRunItemStyles.attachementPresent}>
                  <Skeleton.Image />
                </div>
                {[200, 220, 200, 150, 100].map((width, innerIndex) => {
                  return (
                    <Skeleton
                      key={`paymentRunSkeletonParagraph-${index}-${innerIndex}`}
                      active
                      title={false}
                      paragraph={{
                        rows: 1,
                        width,
                      }}
                    />
                  );
                })}
              </div>
            );
          })}
        </div>
        <div
          className={cn(
            paymentRunPreviewStyles.totalPayout,
            paymentRunPreviewStyles.sectionItem,
            paymentRunPreviewStyles.verticalSpace
          )}
          style={{
            justifyContent: "space-between",
          }}
        >
          <Skeleton
            active
            title={false}
            paragraph={{
              rows: 1,
              width: 100,
            }}
          />
          <div>
            <Skeleton
              active
              title={false}
              paragraph={{
                rows: 1,
                width: 150,
              }}
            />
          </div>
        </div>
      </>
    );
  }

  return <>{children}</>;
};

export const PayViaSkeleton: React.FC<SkeletonProps> = (props) => {
  const { loading, children } = props;

  if (loading) {
    return (
      <>
        <Skeleton
          active
          title={false}
          paragraph={{
            rows: 1,
            width: 150,
          }}
          className={paymentRunPreviewStyles.verticalSpace}
        />
        <div className={cn(PaymenRunPreviewMethodStyles.walletInfo, paymentRunPreviewStyles.verticalSpace)}>
          <div className={PaymenRunPreviewMethodStyles.flex}>
            <div
              style={{
                width: 24,
                height: "auto",
              }}
            >
              <Skeleton.Image />
            </div>
            <div>
              <Skeleton
                active
                title={false}
                paragraph={{
                  rows: 2,
                  width: [200, 160],
                }}
              />
            </div>
          </div>
          <div className={PaymenRunPreviewMethodStyles.makePayment}>
            <Skeleton.Button active />
          </div>
        </div>
        <div className={cn(paymentRunPreviewStyles.sectionItem, paymentRunPreviewStyles.verticalSpace)}>
          <Skeleton
            active
            title={false}
            paragraph={{
              rows: 4,
              width: [200, 240, 160, 160],
            }}
          />
        </div>
      </>
    );
  }
  return <>{children}</>;
};
