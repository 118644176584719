import { useEffect, useState } from "react";

import { IUseHistoricalRefund } from "Views/Transaction/@types";
import { TRANSACTION_SUB_TYPES } from "Views/Transaction/Constants";
import { useExpenseDetailsFetch } from "Views/Transaction/Details/useFetch";

export const useHistoricalRefund = (
  id: string,
  transaction_number: string,
  transaction_type: string
): IUseHistoricalRefund => {
  const [isHistoricalRefund, setIsHistoricalRefund] = useState<boolean>(false);

  const { fetchExpenseDetails, loading } = useExpenseDetailsFetch(id, transaction_number, transaction_type);

  useEffect(() => {
    if (transaction_type?.includes(TRANSACTION_SUB_TYPES.REFUND)) {
      fetchExpenseDetails().catch((err) => {
        if (err.message === "expense not found!") {
          setIsHistoricalRefund(true);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return {
    isRefundLoading: loading,
    isHistoricalRefund,
  };
};
