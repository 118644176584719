import * as React from "react";

import Chip, { CHIP_STATUS } from "Modules/DS/Chip";

const KycItem = ({ status }: { status: string }) => {
  const kyc = { title: "", status: CHIP_STATUS.FAILED };

  switch (status) {
    case "2":
      kyc.title = "Approved";
      kyc.status = CHIP_STATUS.SUCCESS;
      break;
    case "1":
      kyc.title = "Submitted";
      kyc.status = CHIP_STATUS.ATTENTION;
      break;
    case "-1":
      kyc.title = "Rejected";
      break;
    case "0":
    default:
      kyc.title = "Pending";
      break;
  }

  return <Chip.Status title={kyc.title} status={kyc.status} />;
};

export default KycItem;
