import React from "react";
import { Form } from "antd";

import { EInputType, TransactionDetail } from "Modules/TransactionDetail";

import { IExpenseTrxnsDetails } from "Views/Transaction/@types";
import { includesReversalTrxns } from "Views/Transaction/Helper";
import { useExpenseForm } from "Views/Transaction/Details/useExpenseForm";
import { EXPENSE_INPUT_DETAILS_OPTIONS } from "Views/Transaction/Constants";
import { useRemoveSubscriptionModal } from "Views/Transaction/Modal/Subscriptions";

export const Details = ({
  taxId,
  notes,
  details,
  receipts,
  trxnType,
  trxnNumber,
  categoryId,
  categoryName,
  closeDetails,
  handleSaveRef,
  tags,
  disableEditing = false,
  taxName,
  subscriptionId,
  subscriptionInitialProps,
}: IExpenseTrxnsDetails) => {
  const { form, buttonAction, defaultValue, setFormValue } = useExpenseForm(
    trxnNumber,
    closeDetails,
    receipts,
    notes,
    taxId,
    categoryId,
    null,
    tags,
    null,
    subscriptionId
  );

  const { openModal } = useRemoveSubscriptionModal(subscriptionId, trxnNumber, () => {
    form.setFieldsValue({
      [EInputType.SUBSCRIPTION]: null,
    });
  });

  handleSaveRef.current = buttonAction;

  return (
    <Form form={form}>
      <TransactionDetail
        form={form}
        details={details}
        defaultValue={defaultValue}
        setFormValue={setFormValue}
        missingFields={defaultValue}
        visibleInput={EXPENSE_INPUT_DETAILS_OPTIONS}
        deletable={false}
        disabled={disableEditing || includesReversalTrxns(trxnType)}
        categoryFallback={categoryName}
        taxFallback={taxName}
        ignoreRequiredCheck
        onRemoveSubscription={openModal}
        subscriptionInitialProps={subscriptionInitialProps}
      />
    </Form>
  );
};
