import {
  FETCH_TAX_REQUEST,
  FETCH_TAX_SUCCESS,
  FETCH_TAX_ERROR,
  FETCH_XERO_TAX_ERROR,
  FETCH_XERO_TAX_REQUEST,
  FETCH_XERO_TAX_SUCCESS,
} from "../Actions/taxAction";
import { userInitialState } from "../InitialState";

const initialState = {
  ...userInitialState,
  xeroTaxes: {
    ...userInitialState,
  },
};

export const taxReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TAX_REQUEST:
      return {
        ...state,
        loading: true,
        data: action.data,
        error: false,
      };
    case FETCH_TAX_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: false,
      };
    case FETCH_TAX_ERROR:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: true,
      };
    case FETCH_XERO_TAX_REQUEST: {
      return {
        ...state,
        xeroTaxes: {
          data: action.data,
          loading: true,
          error: false,
        },
      };
    }
    case FETCH_XERO_TAX_SUCCESS: {
      return {
        ...state,
        xeroTaxes: {
          loading: false,
          data: action.data,
          error: false,
        },
      };
    }
    case FETCH_XERO_TAX_ERROR: {
      return {
        ...state,
        xeroTaxes: {
          loading: false,
          data: action.data,
          error: true,
        },
      };
    }
    default:
      return state;
  }
};
