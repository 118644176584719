import React from "react";
import { AddOutline, Button } from "@spenmo/splice";
import { useHistory, useLocation } from "react-router-dom";

import { APPROVAL_PATHS } from "Route/Approval/paths";

import { REIMBURSEMENT_PAGE_OPERATIONS } from "Views/Reimbursement/Constants";

export const Export = () => {
  const history = useHistory();
  const location = useLocation();

  const handleExport = () => {
    history.push({
      pathname: `${location.pathname}/${REIMBURSEMENT_PAGE_OPERATIONS.EXPORT}`,
      search: location.search,
    });
  };

  return (
    <Button variant="secondary" size="m" onClick={handleExport}>
      Export
    </Button>
  );
};

export const NewReimbursement = () => {
  const history = useHistory();

  const handleOnClick = () => {
    history.push(APPROVAL_PATHS.NEW_REIMBURSEMENTS);
  };

  return (
    <Button variant="secondary" size="m" icon={AddOutline} onClick={handleOnClick}>
      New Reimbursement
    </Button>
  );
};
