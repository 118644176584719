import React from "react";

import { Skeleton } from "antd";
import { Checkbox } from "@spenmo/splice";

import styles from "./index.module.scss";

const PaymentRunContentShimmer = () => {
  return (
    <>
      {Array.from(Array(4).keys()).map((innerKey) => (
        <div className={styles.billItem} key={`billItem-${innerKey}`}>
          <Checkbox disabled />
          <div className={styles.billDetail}>
            <Skeleton.Input className={styles.shimmer} active={true} />
            <Skeleton.Input className={styles.shimmer} active={true} />
          </div>
        </div>
      ))}
    </>
  );
};

export default PaymentRunContentShimmer;
