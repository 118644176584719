import React from "react";
import Icon from "Modules/icons";
import { UploadImage } from "assets/img";

import styles from "./index.module.scss";
import classNames from "classnames";

const InitialState = ({ disabled = false }: { disabled?: boolean }): JSX.Element => {
  return (
    <>
      <Icon src={UploadImage} disabled={disabled} className={classNames({ [styles.iconDisabled]: disabled })} />
      <div className={classNames(styles.content, { [styles.disabled]: disabled })}>
        <h4 className={styles.title}>
          Drop a file here or <span>choose a file</span>
        </h4>
        <p className={styles.info}>We only accept PDF, PNG, and JPEG format</p>
        <p className={styles.info}>(max 6MB)</p>
      </div>
    </>
  );
};

export default InitialState;
