import React from "react";
import { Select } from "antd";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import LetterAvatar from "../letterAvatar";
import { currencyFormatter, currencyFormatterWithFractions } from "utility";
import "./dropDown.scss";

const walletTypeToDisplayName = (wallet_type) => {
  if (wallet_type == "team") {
    return "Budget";
  } else if (wallet_type == "user") {
    return "Personal Wallet";
  }

  return "";
};

const DropDown = ({
  listData = [],
  showLabel,
  action = () => undefined,
  isWallet = false,
  disabled = false,
  team,
  isTeam,
  hasBackground,
  placeholder,
  initialValue,
  link,
  fullSize,
  showAmount,
  showCurrency,
  className = "",
  currentValue = undefined,
  onFocus = () => undefined,
  onBlur = () => undefined,
  showAmountInDecimals = false,
}) => {
  const { Option } = Select;
  const uniqueId = Math.random().toString(36);
  const valueProp = currentValue ? { value: currentValue } : {};
  const formatAmount = (amount, currencyCode) => {
    if (showAmountInDecimals) {
      return currencyFormatterWithFractions(amount, currencyCode, 2, true);
    } else {
      return currencyFormatter(amount, currencyCode, true);
    }
  };
  return (
    <div
      className={`drop-down ${isTeam && "drop-down__team"} ${isWallet && "drop-down__select-wallet"} ${
        hasBackground && "drop-down--dark"
      } ${fullSize && "drop-down--full-size"} ${className}`}
    >
      <Select
        size={"middle"}
        getPopupContainer={() => /*to avoid non clickable problem*/ document.getElementById(uniqueId)}
        notFoundContent={() => <>NA</>}
        className={"section-select"}
        defaultValue={placeholder || initialValue ? initialValue : listData[0]?.id}
        onSelect={(id) => action(id, listData)}
        placeholder={placeholder}
        disabled={disabled}
        onFocus={onFocus}
        onBlur={onBlur}
        dropdownRender={(menu) => (
          <>
            {menu}
            {link && (
              <Link to={link} className={"drop-down--link"}>
                View all
              </Link>
            )}
          </>
        )}
        {...valueProp}
      >
        {listData.length > 0 &&
          listData.map((data, index) => (
            <Option key={index} className={"section-options"} value={data?.id}>
              {isTeam && (
                <LetterAvatar className={"section-options__avatar-icon"} rounded name={data.name || data.team_name} />
              )}
              <div className="section-options__content">
                <p className="section-options__content__title">{data.team_name || data.bank_name || data.name}</p>
                <p className="section-options__content__subtitle">{walletTypeToDisplayName(data.wallet_type)}</p>
              </div>
              {isWallet && (
                <span aria-label={data.name || data.team_name} className={"section-options__team-title"}>
                  {team}
                </span>
              )}
              {showAmount && (
                <span aria-label={data.name || data.team_name} className={"section-options__amount"}>
                  {showCurrency && <>{data.currency_code} </>}
                  {formatAmount(data?.amount, data?.currency_code)}
                </span>
              )}
              {showLabel && (
                <span aria-label={data.name || data.category_name || data.team_name}>
                  {data.name || data.category_name || data.team_name}
                </span>
              )}
            </Option>
          ))}
      </Select>
      <span id={uniqueId} style={{ position: "relative" }} />
    </div>
  );
};

DropDown.propTypes = {
  action: PropTypes.func,
  listData: PropTypes.array,
  isTeam: PropTypes.bool,
  team: PropTypes.string,
  isWallet: PropTypes.bool,
  hasBackground: PropTypes.bool,
  placeholder: PropTypes.string,
  initialValue: PropTypes.string,
  link: PropTypes.string,
  fullSize: PropTypes.bool,
  showAmount: PropTypes.bool,
  showLabel: PropTypes.bool,
  showCurrency: PropTypes.bool,
  className: PropTypes.string,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  showAmountInDecimals: PropTypes.bool,
};

export default DropDown;
