import { useState, useRef, useEffect } from "react";

export const useDragScroll = (ignoreQuery: string) => {
  const scrollRef = useRef(null);

  const [isScrolling, setIsScrolling] = useState<boolean>(null);
  const [scrollLeft, setScrollLeft] = useState<number>(0);
  const [scrollTop, setScrollTop] = useState<number>(0);
  const [clientX, setClientX] = useState<number>(0);
  const [clientY, setClientY] = useState<number>(0);

  const handleMouseMove = (e) => {
    let scrollElement = scrollRef.current || { scrollLeft: 0, scrollTop: 0 };
    scrollElement.scrollLeft = scrollLeft + clientX - e.clientX;
    scrollElement.scrollTop = scrollTop + clientY - e.clientY;
  };
  const handleMouseUp = () => {
    setClientX(0);
    setScrollLeft(0);
    setClientY(0);
    setScrollTop(0);
    setIsScrolling(false);
  };

  const handleMouseDown = (e) => {
    if (e.target.matches(ignoreQuery)) return;
    setClientX(e.clientX);
    setScrollLeft(scrollRef.current.scrollLeft);
    setClientY(e.clientY);
    setScrollTop(scrollRef.current.scrollTop);
    setIsScrolling(true);
  };

  useEffect(() => {
    scrollRef.current?.addEventListener("mousedown", handleMouseDown, false);
    return () => {
      window.removeEventListener("mousedown", handleMouseDown);
    };
  }, [scrollRef.current]);

  useEffect(() => {
    if (isScrolling) {
      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleMouseUp);
    }
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, [isScrolling]);

  return [scrollRef, isScrolling];
};
