import * as React from "react";
import { useCardDoesNotBelongToUser } from "customHooks/Card";
import Tag, { TagType } from "Views/Card/Components/Tag";
import { CardTagsInfoMap } from "constants/Card";
import { useCardDetailsContext } from "Views/Card/Details/index.context";
import CardStatusToggle from "Views/Card/Details/SubInfo/StatusToggle";
import CardFuelGuage from "Views/Card/Details/SubInfo/FuelGuage";

import { CARD_REQUEST_STATUS, CARD_STATUS } from "Views/Card/types";
import "Views/Card/Details/SubInfo/index.scss";

export interface ISubInfoProps {
  onCardStatusToggle: (status: boolean) => void;
}
export interface TagInfo {
  label: string;
  tagType: TagType;
}

export default function SubInfo({ onCardStatusToggle }: ISubInfoProps) {
  const { cardData } = useCardDetailsContext();
  const {
    cardStatus = "",
    rejectionReason = "",
    isCardRequestDetails,
    isNotActivatedPhysicalCard,
    cardHolder,
    cardUserId,
  } = cardData || {};

  const cardDoesNotBelongsToUser = useCardDoesNotBelongToUser(cardUserId);

  const getHelpText = () => {
    if (isNotActivatedPhysicalCard) {
      return cardDoesNotBelongsToUser
        ? `Only ${cardHolder} can activate and use this card. This card cannot be shared or transferred.`
        : "This card is being delivered. Once received, please activate the card to start using it.";
    }
    return "Activation is in progress. You will receive a notification once your card is activated.";
  };

  if (isCardRequestDetails || isNotActivatedPhysicalCard || cardStatus === CARD_STATUS.IN_PROGRESS) {
    const tagsInfo = CardTagsInfoMap?.[cardStatus];
    return (
      <div className="card-details-subinfo">
        <Tag tagName={tagsInfo?.label} tagType={tagsInfo?.tagType} />
        {cardStatus === CARD_REQUEST_STATUS.REJECTED && rejectionReason && (
          <div className="card-details-subinfo__container">
            <p className="header">Budget Owner note:</p>
            <p className="subheader">{rejectionReason}</p>
          </div>
        )}
        {(isNotActivatedPhysicalCard || cardStatus === CARD_STATUS.IN_PROGRESS) && (
          <div className="card-details-subinfo__container not-activated-info">
            <p className="subheader">{getHelpText()}</p>
          </div>
        )}
      </div>
    );
  } else {
    return (
      <>
        <CardStatusToggle onCardStatusToggle={onCardStatusToggle} />
        <CardFuelGuage />
      </>
    );
  }
}
