import React from "react";
import PropTypes from "prop-types";
import LoaderIcon from "Views/State/Loading/LoaderIcon";
import "./loadingModal.scss";

const LoadingModal = ({ title, message }) => {
  return (
    <div className="loading-modal__wrapper">
      <div className="loading-modal__content">
        <h4 className="loading-modal__content__title">{title}</h4>
        <LoaderIcon />
        <p className="loading-modal__content__message">{message}</p>
      </div>
    </div>
  );
};

LoadingModal.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
};

export default LoadingModal;
