import React from "react";
import { Row, Col } from "Splice/Grid";
import { Skeleton } from "antd";
import "./shimmer.scss";

export const MerchantShimmer = ({ loading }) => {
  return (
    <Row offset={0} className="conatiner">
      {Array.from(Array(8).keys())?.map((key) => (
        <React.Fragment key={`merchant-chart-${key}`}>
          <Col span={24} className="paragraph">
            <Skeleton.Avatar active className="avatar" shape={"circle"} />
            <Skeleton.Button active className="contentBtnFirst" />
            <Skeleton.Button active className="contentBtn" />
            <Skeleton.Button active className="contentBtn" />
            <Skeleton.Button active className="contentBtn" />
          </Col>
          <Col span={24} className="divider"></Col>
        </React.Fragment>
      ))}
    </Row>
  );
};
