import { useEffect, useState } from "react";
import { transformHeicImage } from "../utilities";
import { TRANSFORMED_FILE_EXTENSIONS } from "../constants";

interface Options {
  onError?(): void;
}

export const useTransformHeicImage = (fileType: string, fileUrl: string, options?: Options) => {
  const [isLoading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState(fileUrl);

  const { onError } = options || {};

  useEffect(() => {
    const fileExt = fileType?.split("/").pop();

    //   special extension that needed transformation
    if (TRANSFORMED_FILE_EXTENSIONS.includes(fileExt)) {
      setLoading(true);
      transformHeicImage(fileUrl)
        .then((imageUrl) => {
          setImageUrl(imageUrl);
        })
        .catch(() => {
          onError?.();
        })
        .finally(() => setLoading(false));
    } else {
      setImageUrl(fileUrl);
    }
  }, [fileType, fileUrl]);

  return { isLoading, imageUrl };
};
