import { useCallback, useRef, useState } from "react";

import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";

import { getTrxnAttachment } from "Views/Transaction/DataCalls";

export const useVerifiedReceipts = (
  receipts: string[],
  throwError: boolean = false,
  isBillsTrxnsPage: boolean = false
) => {
  const attachments = useRef<string[]>([]);

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [isComplete, setIsComplete] = useState<boolean>(false);

  const getVerifiedUrl = useCallback(async (receipt: string) => {
    const resp = await getTrxnAttachment(receipt);
    if (resp.status > HTTP_STATUS_CODE.OK) {
      throw new Error("Failed to fetch verified URL");
    } else if (resp.data.status > HTTP_STATUS_CODE.OK) {
      throw new Error(
        resp?.data?.payload?.status_message || `An error occured with the status code ${resp.data.status}`
      );
    }

    return resp.data.payload?.url || resp.data.url;
  }, []);

  const fetchReceipts = useCallback(async () => {
    try {
      if (!receipts.length || receipts?.every((r) => !r)) return;
      setLoading(true);
      setError(false);

      attachments.current = [];
      const urls: string[] = await Promise.all(isBillsTrxnsPage ? receipts : receipts.map(getVerifiedUrl));
      attachments.current = urls.filter((item) => item.trim());
    } catch (err) {
      setError(true);
      if (throwError) {
        let message: string;
        if (err.message === "unauthorized, bad access level.") {
          message = `Some of the receipts can't be accessed. (${err.message})`;
        } else {
          message = err?.message;
        }
        throw new Error(message);
      }
    } finally {
      setLoading(false);
      setIsComplete(true);
    }
  }, [getVerifiedUrl, receipts, throwError, isBillsTrxnsPage]);

  return { fetchReceipts, attachments, loading, isComplete, error };
};
