import React, { useState } from "react";
import Menu from "Modules/DS/Menu";
import Select from "Modules/DS/Select";
import { IAccountingMoreFilterItem } from "./TransactionMoreFilter";
import { ITitleWithID } from "Views/Transaction/@types";

const MultiSelectDropdownFilter = ({
  applyFilter,
  resetFilter,
  filter,
  clear,
  dispatchOnAdd,
  dispatchOnRemove,
  onClickOutside,
  filterId,
  defaultValue,
  data = [],
  loading,
}: IAccountingMoreFilterItem) => {
  const getFormattedData: () => ITitleWithID[] = () =>
    data?.length > 0 ? data.map((team) => ({ id: team.id, title: team.name || team.team_name })) : [];

  const [title, setTitle] = useState("");
  const [icon, setIcon] = useState("");
  const [selectedIds, setSelectedIds] = useState([]);

  const onSelectHandler = (value: string[]) => {
    applyFilter(filterId, value);
    dispatchOnAdd?.(value);
  };

  const handleClearDropdown = () => {
    resetFilter(filterId);
    dispatchOnRemove?.();
  };

  const handleOnClickOutside = () => {
    onClickOutside?.();
    !filter[filterId] && setSelectedIds([]);
  };

  return (
    <Menu.Row id={filterId} title={defaultValue}>
      <Select.Dropdown.Multiple<ITitleWithID>
        dropdownProps={{ id: filterId, searchable: true, containerClass: ".data-table-container" }}
        defaultValue={defaultValue}
        idKey="id"
        displayKey="title"
        parentPopoverId="more-filter-select__6"
        clear={clear}
        presetValue={filter[filterId]}
        states={{
          selectedIds,
          setSelectedIds,
          setTitle,
          title,
          loading,
          icon,
          setIcon,
        }}
        onAddSelected={onSelectHandler}
        clearDropdown={handleClearDropdown}
        onClickOutside={handleOnClickOutside}
      >
        {getFormattedData()}
      </Select.Dropdown.Multiple>
    </Menu.Row>
  );
};

export default MultiSelectDropdownFilter;
