import React from "react";
import classNames from "classnames";
import { Skeleton, Divider } from "antd";
import { Col, Row } from "Splice/Grid";
import styles from "./index.module.scss";
import "./Shimmer.scss";

export const SpendingTrendShimmer = ({ loading }) => {
  return (
    <Row
      className={classNames(styles.TrendingSpendShimmerContainer, "spending-trend-shimmer ")}
      gutter={[0, 0]}
      offset={0}
    >
      <Col span={1} className={styles.spendvalue}>
        {Array.from(Array(7).keys())?.map((key) => (
          <div style={{ marginTop: "16px" }} key={`y-axis-${key}`}>
            <Skeleton.Button active className={styles.spendvalueBtn} />
          </div>
        ))}
      </Col>
      <Col span={23}>
        <Col span={24} className={styles.ShimmerLineGraph}>
          <Skeleton active={loading} paragraph={{ rows: 6 }} />
        </Col>
        <Col span={24} className={styles.DateShimmer}>
          {Array.from(Array(7).keys())?.map((key) => (
            <div key={`spend-chart-${key}`}>
              <Divider type="vertical" className="ValueDivider" />
              <Col span={5}>
                <Skeleton.Button active className={styles.Button} />
              </Col>
            </div>
          ))}
        </Col>
      </Col>
    </Row>
  );
};
