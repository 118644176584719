import APIclient from "API/Client";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";

export const FETCH_EMAIL_REQUEST = "FETCH_EMAIL_REQUEST";
export const FETCH_EMAIL_SUCCESS = "FETCH_EMAIL_SUCCESS";
export const FETCH_EMAIL_ERROR = "FETCH_EMAIL_ERROR";

export const fetchDataRequest = (data) => {
  return {
    type: "FETCH_EMAIL_REQUEST",
    data: data,
  };
};

export const fetchDataSuccess = (data) => {
  return {
    type: "FETCH_EMAIL_SUCCESS",
    data: data.payload,
  };
};

export const fetchDataError = (data) => {
  return {
    type: "FETCH_EMAIL_ERROR",
    data: data,
  };
};

export const CheckEmail = (email) => {
  return (dispatch) => {
    dispatch(fetchDataRequest());

    APIclient.getData(`/ms/spm-organization/v1/employee/check-email?email=${email}`)
      .then((data) => {
        if (data.data.status === HTTP_STATUS_CODE.OK) {
          dispatch(fetchDataSuccess(data.data));
        } else {
          dispatch(fetchDataError(data.data));
        }
      })
      .catch((err) => {
        dispatch(fetchDataError(err));
      });
  };
};
