import React, { useContext } from "react";
import { Col, Form } from "antd";
import PropTypes from "prop-types";

import DropDown from "Modules/dropDown";
import { FiltersAPI, FilterComponents } from "Modules/Filters";

export const DropDownFilter = ({ title, appendClassName, formItem, listData, children, eventName, placeholder }) => {
  const api = useContext(FiltersAPI);

  return (
    <FilterComponents.FilterItemWrapper appendClassName={appendClassName} title={title}>
      <Col>
        <Form.Item name={formItem}>
          <DropDown
            initialValue={children}
            placeholder={placeholder}
            listData={listData || []}
            action={(value) => api.handleDropDownActions(value, formItem, eventName)}
          />
        </Form.Item>
      </Col>
    </FilterComponents.FilterItemWrapper>
  );
};

DropDownFilter.propTypes = {
  title: PropTypes.string,
  listData: PropTypes.array,
  children: PropTypes.string,
  formItem: PropTypes.string,
  eventName: PropTypes.string,
  placeholder: PropTypes.string,
  appendClassName: PropTypes.string,
};
