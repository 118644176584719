import React from "react";
import PropTypes from "prop-types";

import { FilterById } from "utility";

import { analytics, FilterComponents } from "Modules/Filters";

export const TeamFilter = ({ listData, selectedTeam, dispatchAction, formItem }) => {
  return (
    <FilterComponents.SearchDropDownFilter
      title="Budget"
      formItem={formItem ? formItem : "team_id"}
      listData={listData || []}
      appendClassName="teams"
      eventName={analytics.teamSelected}
      dispatchAction={dispatchAction}
      placeholder="Select Budget"
      displayKey="team_name"
    >
      {selectedTeam && FilterById(selectedTeam, listData)[0]?.team_name}
    </FilterComponents.SearchDropDownFilter>
  );
};

TeamFilter.propTypes = {
  listData: PropTypes.array,
  formItem: PropTypes.string,
  dispatchAction: PropTypes.func,
  selectedTeam: PropTypes.string,
};
