import React from "react";
import classNames from "classnames";
import Icon from "Modules/icons";

import { premiumWhiteIcon } from "assets/v1.1/icons/Flat";
import { TierProps, TIER_CHIP_STATUS } from "Modules/DS/Chip";
import styles from "./index.module.scss";

export const TierChip = ({ title, status, icon = premiumWhiteIcon, secondary = false }: TierProps): JSX.Element => {
  const _classNames = classNames(styles.container, styles[status], { [styles.secondary]: secondary });
  const isPremium = status === TIER_CHIP_STATUS.ESSENTIAL || status === TIER_CHIP_STATUS.CUSTOM;
  return (
    <div data-testid="tier-chip" className={_classNames}>
      {isPremium && <Icon className={styles.icon} src={icon} alt={`${status} icon`} />}
      {title && <span className={styles.title}>{title}</span>}
    </div>
  );
};
