import APIclient from "API/Client";
import { GetOrgId } from "utility";

const FETCH_USER_WALLET_BALANCES_LIST = "FETCH_USER_WALLET_BALANCES_LIST";

export const FETCH_USER_WALLET_BALANCES_LIST_REQUEST = `${FETCH_USER_WALLET_BALANCES_LIST}_REQUEST`;
export const FETCH_USER_WALLET_BALANCES_LIST_SUCCESS = `${FETCH_USER_WALLET_BALANCES_LIST}_SUCCESS`;
export const FETCH_USER_WALLET_BALANCES_LIST_FAILURE = `${FETCH_USER_WALLET_BALANCES_LIST}_FAILURE`;
export const RESET_USER_WALLET_BALANCES_LIST = "RESET_USER_WALLET_BALANCES_LIST";

const fetchUserWalletBalancesList = () => ({
  type: FETCH_USER_WALLET_BALANCES_LIST_REQUEST,
});

const fetchUserWalletBalancesError = (data) => ({
  type: FETCH_USER_WALLET_BALANCES_LIST_FAILURE,
  data: data,
});

const fetchUserWalletBalancesSuccess = (data) => ({
  type: FETCH_USER_WALLET_BALANCES_LIST_SUCCESS,
  data: data,
});

export const resetUserWalletBalances = () => ({
  type: RESET_USER_WALLET_BALANCES_LIST,
});

const DEFAULT_PAGE = 0;
const DEFAULT_LIMIT_TO_FETCH = 10;

export const fetchUserWalletBalances = (page = DEFAULT_PAGE, limit = DEFAULT_LIMIT_TO_FETCH) => {
  return (dispatch) => {
    const orgId = GetOrgId();
    dispatch(fetchUserWalletBalancesList());

    const requestUrl = `/api/v1/org/card/wallet/wallet-list?organisation_id=${orgId}&limit=${limit}&pg=${page}`;
    APIclient.getData(requestUrl)
      .then((data) => {
        dispatch(fetchUserWalletBalancesSuccess(data?.data));
      })
      .catch((err) => {
        dispatch(fetchUserWalletBalancesError(err));
      });
  };
};
