export const CARD_LIMIT_TYPES = {
  monthlyLimit: "monthly_limit",
  yearlyLimit: "yearly_limit",
  totalLimit: "total_limit",
  unlimited: "none",
};

export const CARD_LIMITS_LABELS = {
  [CARD_LIMIT_TYPES.monthlyLimit]: "Monthly",
  [CARD_LIMIT_TYPES.yearlyLimit]: "Yearly",
  [CARD_LIMIT_TYPES.totalLimit]: "Total",
  [CARD_LIMIT_TYPES.unlimited]: "Unlimited",
};

export const CARD_LIMITS = [
  { label: CARD_LIMITS_LABELS[CARD_LIMIT_TYPES.monthlyLimit], value: CARD_LIMIT_TYPES.monthlyLimit },
  { label: CARD_LIMITS_LABELS[CARD_LIMIT_TYPES.yearlyLimit], value: CARD_LIMIT_TYPES.yearlyLimit },
  { label: CARD_LIMITS_LABELS[CARD_LIMIT_TYPES.totalLimit], value: CARD_LIMIT_TYPES.totalLimit },
  { label: CARD_LIMITS_LABELS[CARD_LIMIT_TYPES.unlimited], value: CARD_LIMIT_TYPES.unlimited },
];

export const CARD_LIMITS_INFO_TEXTS = {
  [CARD_LIMIT_TYPES.monthlyLimit]: "Monthly Limit means the Card Limit will reset every month.",
  [CARD_LIMIT_TYPES.yearlyLimit]: "Yearly Limit means the Card Limit will reset every year.",
  [CARD_LIMIT_TYPES.totalLimit]: "Total Limit means the Card Limit will apply without monthly/yearly reset.",
  [CARD_LIMIT_TYPES.unlimited]: "Unlimited means the card can be used within the remaining Team Limit.",
};
