import React from "react";
import cn from "classnames";

import { Typography } from "@spenmo/splice";

import { IKYXDownloadLink } from "Views/KYX/@types";

import styles from "./index.module.scss";

export const KYXDownloadLink = ({ title, className, ...props }: IKYXDownloadLink) => (
  <a {...props} className={cn(styles.downloadLink, className)}>
    <Typography variant="body-content" size="s" weight={600}>
      {title}
    </Typography>
  </a>
);
