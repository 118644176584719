import useSWRInfinite from "swr/infinite";

import { getData } from "API/Client";
import { IAuditLog } from "Views/SubscriptionManagement/@types";

export const useAuditLogs = (subscriptionId: string, limit: number = 10) => {
  const { data, isLoading, setSize, isValidating } = useSWRInfinite(
    (index, previousPageData) => {
      if (previousPageData && previousPageData?.data?.payload?.audit_logs?.length !== limit) {
        return null;
      }
      return `/ms/spm-organisation/v1/subscriptions/${subscriptionId}/logs?page=${index + 1}&limit=${limit}`;
    },
    getData,
    {
      revalidateFirstPage: false,
      revalidateAll: false,
      revalidateOnFocus: true,
      revalidateOnMount: true,
    }
  );

  const { pagination = {}, audit_logs: currentPageAuditLogs = [] } = data?.at(-1)?.data?.payload || {
    pagination: {},
    audit_logs: [],
  };
  const hasMoreData = pagination?.currentPage < pagination?.totalPage && currentPageAuditLogs?.length === limit;
  const auditLogs = data?.flatMap((data) => (data?.data?.payload?.audit_logs as IAuditLog[]) || []) || [];

  return {
    auditLogs,
    isLoading,
    setSize,
    isValidating,
    hasMoreData,
  };
};
