import * as React from "react";
import { useSelector } from "react-redux";
import ConfigBuilder from "Views/Card/CreateOrRequest/Utilities/ConfigBuilder";
import { CARD_TYPE } from "Views/Card/types";
import Modal from "Views/Card/CreateOrRequest/Modal";
import ActivateWalletModal from "Views/Card/CreateOrRequest/ActivateWallet";
import { useCreateOrRequestState } from "Views/Card/CreateOrRequest/Utilities/UseCreateOrRequestState";
import { useCheckIfWalletUser } from "Views/Card/CreateOrRequest/Utilities/UseVerifyUser";
import {
  generateCreateOrRequestCardPayload,
  trackCardCreation,
} from "Views/Card/CreateOrRequest/Utilities/PayloadGenerator";
import CreateOrRequestCardContext from "Views/Card/CreateOrRequest/index.context";

interface CreateOrRequestProps {
  isManagerOrAdmin: boolean;
  cardType: CARD_TYPE;
  onClose: () => void;
  onConfirm: (
    payload: { [key: string]: string | number },
    adminOrManagerInSelectedTeam: boolean,
    cardType: CARD_TYPE
  ) => void;
  cardRequestInProgress: boolean;
}

const CreateOrRequestCardModal = ({
  isManagerOrAdmin,
  cardType,
  onClose,
  onConfirm,
  cardRequestInProgress,
}: CreateOrRequestProps) => {
  const [state, dispatch] = useCreateOrRequestState();
  const [showWalletActivationScreen, reCheckIfWalletUser] = useCheckIfWalletUser(cardType);

  const userName = useSelector((reduxState: any) => reduxState?.userInfoReducer?.data?.payload?.user?.name || "");
  const [cardStepsConfig, setCardStepsConfig] = React.useState(() =>
    ConfigBuilder(isManagerOrAdmin, cardType, !!state.selectedTeamId, state.adminOrManagerInSelectedTeam)
  );
  const [currentStep, setCurrentStep] = React.useState(0);

  const resetStepsConfig = () => {
    setCardStepsConfig(
      ConfigBuilder(isManagerOrAdmin, cardType, !!state.selectedTeamId, state.adminOrManagerInSelectedTeam)
    );
  };

  React.useEffect(() => {
    state.selectedTeamId && resetStepsConfig();
  }, [state.selectedTeamId]);

  const getModalTitle = () => {
    const { selectedTeamId, adminOrManagerInSelectedTeam } = state;
    if (cardType === CARD_TYPE.PHYSICAL) return "Request New Physical Card";
    else if ((selectedTeamId && adminOrManagerInSelectedTeam) || (isManagerOrAdmin && !selectedTeamId))
      return "Add New Virtual Card";
    else return "Request New Virtual Card";
  };

  const changeCurrentStep = (goToNextStep: boolean) => {
    const { cardholderId } = state;
    if (currentStep === 0 && !reCheckIfWalletUser(cardholderId)) return;

    if (currentStep === cardStepsConfig.length - 1 && goToNextStep) {
      onConfirm(
        generateCreateOrRequestCardPayload(state, cardType, userName),
        state.adminOrManagerInSelectedTeam,
        cardType
      );
      trackCardCreation(state, cardType);
    } else {
      setCurrentStep((prevStep) => (goToNextStep ? prevStep + 1 : prevStep - 1));
    }
  };

  const ContextValue = {
    state: state,
    dispatch: dispatch,
    isManagerOrAdmin,
    cardType,
    changeStep: changeCurrentStep,
    cardRequestInProgress,
  };

  return (
    <CreateOrRequestCardContext.Provider value={ContextValue}>
      {showWalletActivationScreen ? (
        <ActivateWalletModal onClose={onClose} />
      ) : (
        <Modal stepsConfig={cardStepsConfig} cardTitle={getModalTitle()} currentStep={currentStep} onClose={onClose} />
      )}
    </CreateOrRequestCardContext.Provider>
  );
};

export default CreateOrRequestCardModal;
