import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { Typography, Card } from "antd";

import { getAllTaxesFunc, UserInfoFunc } from "Redux/Actions";
import { TAX_STATUS } from "constants/Tax.constant";

import Beneficiary from "./Beneficiary";
import RecipientDynamic from "./RecipientDynamic";
import Transaction from "./Transaction";
import Other from "./Other";

import styles from "./TransactionDetail.module.scss";
import { trackEvent } from "utility/analytics";

const TransactionDetail = ({ data, formData, setFormData }) => {
  const { Text } = Typography,
    [userInfoData, taxesData] = useSelector((state) => [state.userInfoReducer.data, state.taxReducer.data]),
    dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllTaxesFunc({ status: TAX_STATUS.ACTIVE }));
    dispatch(UserInfoFunc());
  }, [dispatch]);

  useEffect(() => {
    trackEvent("bill view detail tab on sidebar");
  }, []);

  return (
    <div className={styles.container}>
      <Text className={styles.title}>
        {!data?.dynamicFields || data?.dynamicFields?.length === 0 ? "Beneficiary details" : "Recipient"}
      </Text>
      <Card className={styles.card}>
        {!data?.dynamicFields || data?.dynamicFields?.length === 0 ? (
          <Beneficiary data={data?.beneficiary} />
        ) : (
          <RecipientDynamic data={data} />
        )}
      </Card>
      <Text className={styles.title}>Transaction</Text>
      <Card className={styles.card}>
        <Transaction data={data} />
      </Card>
      <Text className={styles.title}>Others</Text>
      <Card className={styles.card}>
        <Other
          data={data}
          userInfoData={userInfoData}
          taxesData={taxesData}
          formData={formData}
          setFormData={setFormData}
        />
      </Card>
    </div>
  );
};

TransactionDetail.propTypes = {
  data: PropTypes.object,
  formData: PropTypes.object,
  setFormData: PropTypes.func,
};

export default TransactionDetail;
