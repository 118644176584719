import { IAutoReconciledTrxns } from "Views/SubscriptionManagement/@types";

export const AutoReconciledTxnsData: IAutoReconciledTrxns[] = [
  {
    id: "mocked_auto_reconcile_01",
    type: "card",
    owner: {
      id: generateUniqueId(),
      name: "Mark Henry",
    },
    budget: {
      id: generateUniqueId(),
      name: "Marketing",
    },
    subscription: {
      id: generateUniqueId(),
      vendor_name: "Google",
    },
    vendor: "Google",
    attachments: ["https://s3.aws.com/spenmo-prod/attachment.pdf"],
    amount: 400000,
    currency: "SGD",
    created_at: "2023-07-17T12:38:39Z",
  },
  {
    id: generateUniqueId(),
    type: "bill",
    owner: {
      id: generateUniqueId(),
      name: "Mark Henry",
    },
    subscription: {
      id: generateUniqueId(),
      vendor_name: "Google",
    },
    budget: {
      id: generateUniqueId(),
      name: "Marketing",
    },
    vendor: "Facebook",
    attachments: ["https://s3.aws.com/spenmo-prod/attachment.pdf"],
    amount: 400000,
    currency: "SGD",
    created_at: "2023-06-25T12:38:39Z",
  },
];

function generateUniqueId(): string {
  return Date.now().toString(36) + Math.random().toString(36).substr(2, 9);
}

export const mockAutoReconciledTransaction = () => {
  return Array.from({ length: 5 })
    .fill({})
    .map((_, index) => {
      return {
        ...AutoReconciledTxnsData[0],
        id: `mocked_auto_reconcile_${index}`,
      };
    });
};

export default AutoReconciledTxnsData;
