import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { useQuery } from "utility/useQuery";
import { GetUserId } from "utility";
import { getPendingActionByState } from "./Utilities";
import { getPendingActions } from "Redux/Actions/PendingActions";
import usePermissionCheck from "Permission/usePermissionCheck";
import { TRANSACTION_REQUESTER_PERMISSION_PARAMS } from "Views/Transaction/Permission";
import { IPendingActionsProps } from "./types";
import { getAllTeamsWithMembers } from "Views/Team/DataCalls";
import styles from "./index.module.scss";

const PendingActions = ({ isModular = false }) => {
  const dispatch = useDispatch();
  const { data } = useQuery({ apiCall: getAllTeamsWithMembers });
  const getLoggedinUserTeams = (data?.teams || [])
    .filter((team) => (team?.members || []).find((member) => member?.user_id === GetUserId()))
    .map((team) => team.id);

  const contentRef = useRef(null);

  const IS_TRXN_REQUESTER_FILTER_ALLOWED = usePermissionCheck(TRANSACTION_REQUESTER_PERMISSION_PARAMS) as boolean;

  if (contentRef.current) {
    const activeElement = contentRef.current.querySelector("#pendingActionContent");
    activeElement && activeElement.scrollIntoView({ behaviour: "smooth", block: "nearest" });
  }

  const { pendingActionsData, userDetails }: IPendingActionsProps = useSelector((state: any) => ({
    pendingActionsData: state.pendingActionsReducer,
    userDetails: state?.userInfoReducer?.data?.payload?.user,
  }));
  const { is_admin, is_wallet_user } = userDetails || {};
  const _classNames = classNames(styles.container, { [styles.modularContainer]: isModular });

  React.useEffect(() => {
    dispatch(getPendingActions());
  }, [dispatch]);

  return (
    <div id={"pendingActions"} className={_classNames}>
      <h1 className={styles.title}>Pending Actions</h1>
      <div ref={contentRef} id="pendingActionContent" className={styles.content}>
        {getPendingActionByState(
          pendingActionsData,
          is_admin,
          is_wallet_user,
          getLoggedinUserTeams || [],
          IS_TRXN_REQUESTER_FILTER_ALLOWED
        )}
      </div>
    </div>
  );
};

export default PendingActions;
