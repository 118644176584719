import React, { useContext, useEffect, useRef } from "react";

import { capitalizeFirstLetter } from "utility";

import {
  SelectContext,
  ISelectContext,
  IDropdownContainer,
  IElasticSelectDropdown,
  initialMultipleSelectDropdownStates,
  IBasicDropdownsState,
  ICommonDropdownProps,
} from "Modules/DS/Select";
import { useElasticSearch } from "Modules/DS/Search";
import { IListContainer } from "Modules/DS/Filter/@types";
import Menu, { ILineProps, LINE_TYPE } from "Modules/DS/Menu";
import { useMultiSelect } from "Modules/DS/Menu/List/useHooks/useMultiSelect";

import { getLabel } from "./getLabel";
import { Container } from "./Container";

export const ElasticSelectDropdown = <T extends object = any>({
  height,
  children,
  displayKey,
  defaultValue,
  idKey = "id" as keyof T,
  appliedKey = "id" as keyof T,
  iconKey,
  header = null,
  onAddSelected = () => null,
  dropdownProps,
  presetValue,
  clear,
  parentPopoverId,
  clearDropdown = () => null,
  onClickOutside = () => null,
  onSearch,
  onClearSearch,
  dataSet,
  states = { ...initialMultipleSelectDropdownStates },
}: IElasticSelectDropdown<T>): JSX.Element => {
  const processedLabel = defaultValue.includes("/")
    ? defaultValue.split("/")[0].toLowerCase()
    : defaultValue.toLowerCase();

  const { get, onSearchHandler, clearSearchHandler } = useElasticSearch(onSearch, onClearSearch);

  const ifDataSetProvided: T[] = dataSet?.length && !get.value ? dataSet : children;

  const { closeNested, select, nested } = useContext<ISelectContext>(SelectContext);

  const _defaultValue = `Select ${processedLabel}`;

  const isSelected: boolean = states.title && states.title !== _defaultValue;

  const clearingDropdown = useRef<boolean>(false);

  const isParentPopoverMounted = select.show && select.key === parentPopoverId;
  const isDropdownPopoverMounted = nested.show && nested.key === dropdownProps.id;

  useEffect(() => {
    if (clear) {
      handleClearDropdown();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clear]);

  useEffect(() => {
    if (states.selectedIds.length && !states.title && clearingDropdown.current) {
      handleClearDropdown();
      clearingDropdown.current = false;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearingDropdown]);

  useEffect(() => {
    if (presetValue?.length) {
      const getSelectedIds = presetValue.map((item) => item[idKey as string]);

      if (states.title && !states.selectedIds.length) {
        states?.setSelectedIds?.(getSelectedIds);
        return;
      }

      if (!states.selectedIds.length && !states.title && !clearingDropdown.current) {
        states?.setSelectedIds?.(getSelectedIds);

        let { text, icon } = getLabel<T>(
          presetValue,
          displayKey,
          _defaultValue,
          capitalizeFirstLetter(processedLabel),
          iconKey
        );
        states?.setTitle?.(text);
        states?.setIcon?.(icon);

        return;
      } else if (!states.selectedIds.length && !states.title && clearingDropdown.current) {
        handleClearDropdown();
      }
    }

    return () => {
      clearingDropdown.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isParentPopoverMounted, isDropdownPopoverMounted]);

  const onApplyHandler = () => {
    if (!states.selectedIds.length) {
      handleClearDropdown();
      return;
    }
    const dropdownData = [...dataSet, ...children];
    const checked: T[] = states.selectedIds
      .map((selectedId) => dropdownData.find((item) => item[idKey as string] === selectedId))
      .filter(Boolean);

    if (!checked.length) return;

    const value: string[] = checked.map((val) => val[appliedKey as string]);

    let { text, icon } = getLabel<T>(
      checked,
      displayKey,
      _defaultValue,
      capitalizeFirstLetter(processedLabel),
      iconKey
    );

    states.setTitle(text);
    states?.setIcon(icon);

    onAddSelected(value);
    closeNested();

    onSearchHandler("");
    clearSearchHandler();
  };

  const onClickOutsideHandler = () => {
    if (!isSelected && states.selectedIds.length > 0) {
      states.setSelectedIds([]);
    }

    onClickOutside?.();
    clearSearchHandler();
  };

  const handleClearDropdown = () => {
    clearingDropdown.current = true;

    states?.setLoading?.(false);
    states?.setSelectedIds?.([]);
    states?.setTitle?.("");
    states?.setIcon?.("");

    if (isSelected) {
      clearDropdown?.();
      closeNested();
    }
  };

  const renderFooter = () => {
    return ifDataSetProvided?.length && <Menu.Actions onApply={onApplyHandler} onClear={handleClearDropdown} />;
  };

  const onClickHandler = useMultiSelect(states.selectedIds, states.setSelectedIds);

  const _states: IBasicDropdownsState = {
    loading: states?.loading || get.loading,
    setLoading: states?.setLoading,
    value: get.value,
    setValue: onSearchHandler,
  };

  const lineProps = (item: T): ILineProps => ({
    id: item[idKey as string],
    title: item[displayKey as string],
    src: item?.[iconKey as string],
    selected: states.selectedIds.includes(item[idKey as string]),
    type: LINE_TYPE.CHECKBOX_SINGLE_LINE,
    loading: _states.loading,
    onClick: onClickHandler,
  });

  const emptyStateProp = !ifDataSetProvided.length && !get.value && { showEmptyState: false };

  const _dropdownProps: ICommonDropdownProps = {
    selected: isSelected,
    clearDropdown: handleClearDropdown,
    onClickOutside: onClickOutsideHandler,
    title: isSelected ? states.title : _defaultValue,
    searchPlaceholder: `Search ${processedLabel}`,
    ...emptyStateProp,
    ...dropdownProps,
  };

  const listProps: Omit<IListContainer<T>, "children"> = {
    idKey,
    height,
    lineProps,
    loading: _states.loading,
    ...emptyStateProp,
  };

  const props: Omit<IDropdownContainer<T>, "children"> = {
    header,
    listProps,
    states: _states,
    footer: renderFooter(),
    dropdownProps: _dropdownProps,
  };

  return <Container<T> {...props}>{ifDataSetProvided}</Container>;
};
