import React, { useMemo, useState } from "react";

import { IKYXToastContext, IKYXToastProps } from "Views/KYX/@types";
import { initialKYXToast, KYXToastContext } from "Views/KYX/Context";

/**
 * KYXToastProvider is a React component that provides KYX toast context to its children.
 * @param {React.ReactNode} children - The child components of the KYXToastProvider.
 * @returns {JSX.Element} The KYXToastContext.Provider with the context values.
 */
export const KYXToastProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const [toaster, setToaster] = useState<IKYXToastProps>(initialKYXToast.toaster);

  const resetToaster = () => setToaster(initialKYXToast.toaster);

  const context: IKYXToastContext = useMemo(() => ({ toaster, setToaster, resetToaster }), [toaster]);

  return <KYXToastContext.Provider value={context}>{children}</KYXToastContext.Provider>;
};
