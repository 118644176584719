import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Row, Col } from "antd";
import classNames from "classnames";

import { useQuery } from "utility/useQuery";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import StatusView from "Modules/statusView";
import StepsBar from "Modules/stepsBar";
import OverlayLoader from "Modules/overlayLoader";
import { getPartnerAccount, getXeroIntegrationDetails } from "Redux/Actions";
import { companyMenu } from "Views/Settings/config";

import { getXeroOrganisation } from "./dataCall";
import { IBankAccount, IAccountingSetup } from "./types";

import styles from "./AccountingSetup.module.scss";
import { Typography } from "@spenmo/splice";

const AccountingSetup = ({ config, title, partner, description }: IAccountingSetup) => {
  const isXeroSetup = partner === "xero";
  const integrationSettingsPagePath = companyMenu[0].path;

  const [currentStep, setCurrentStep] = useState(config[0].index);
  const [metaData, setMetaData] = useState({});
  const partnerAccountsFetchedOnMount = useRef(false);
  const partnerOrganisationFetched = useRef(false);
  const { error, data, loading, integrationData, integrationDataLoading } = useSelector(
    (state: any) => state.xeroAccountReducer
  );
  const partnerIntegrationData = integrationData?.payload || {};
  const { expenseBankAccountId, partnerOrganisationName } = partnerIntegrationData;
  const isValidPartnerData = integrationData?.status === HTTP_STATUS_CODE.OK && expenseBankAccountId?.length > 0;

  const { data: xeroOrganisation } = useQuery({
    apiCall: getXeroOrganisation,
    run: !partnerOrganisationFetched.current && isXeroSetup,
    onSuccess() {
      partnerOrganisationFetched.current = true;
    },
  });
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (!isValidPartnerData) {
      dispatch(getXeroIntegrationDetails());
      dispatch(getPartnerAccount());
    } else {
      partnerAccountsFetchedOnMount.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (isValidPartnerData) {
      goToSettingsPage();
    }
    if (integrationData?.status === HTTP_STATUS_CODE.OK && !partnerAccountsFetchedOnMount.current) {
      partnerAccountsFetchedOnMount.current = true;
    }
  }, [integrationData]);

  const getBankAccounts: () => IBankAccount[] = () => {
    const bankAccounts = data?.payload?.accounts;
    if (Array.isArray(bankAccounts) && bankAccounts.length) {
      return bankAccounts.filter((item) => item?.name && item?.number);
    }
    return [];
  };

  useEffect(() => {
    const step = config[currentStep];

    if (step.needRefetch) {
      dispatch(getPartnerAccount());
    }
  }, [currentStep]);

  const goToNextStep = (payload = {} as { step: number; metaData: any }) => {
    const { metaData } = payload;
    setCurrentStep(currentStep + 1);
    if (metaData) {
      setMetaData(metaData);
    }
  };

  const goToSettingsPage = (routerState?: any) => {
    history.push(integrationSettingsPagePath, routerState);
  };

  if (error) {
    return (
      <StatusView
        primary
        header="Authentication Failed!"
        message={data?.payload?.status_message}
        ButtonLink={integrationSettingsPagePath}
        ButtonText="Back to Integration"
      />
    );
  }

  const { Component } = config[currentStep];
  const isLastStep = currentStep === config.length - 1;

  return (
    <div className={classNames("section section--full-height", styles.navy)}>
      {(loading || integrationDataLoading) && !partnerAccountsFetchedOnMount.current ? (
        <OverlayLoader show />
      ) : (
        <>
          <Row justify="space-between" align="middle" className="sp-margin-bottom--xl">
            <Col>
              <div className={classNames("section__header sp-margin-0", styles["xero-setup__header"])}>{title}</div>
              {Boolean(description) && (
                <Typography size="m" variant="body-content" className={styles.description}>
                  {description}
                </Typography>
              )}
            </Col>
          </Row>
          {/* Hide Step when only have one step  */}
          {config.length > 1 && (
            <Row justify="space-between" align="middle" className="sp-margin-bottom--xl">
              <Col span={config.length * 5}>
                <StepsBar
                  className={classNames(styles["xero-setup__step-bar"])}
                  current={currentStep}
                  initialStep={config[0].index}
                  data={config.map((step) => ({
                    step: step.index + 1,
                    step_name: step.label,
                  }))}
                />
              </Col>
            </Row>
          )}
          <Component
            goToNextStep={goToNextStep}
            goToSettingPage={isLastStep ? goToSettingsPage : undefined}
            bankAccounts={getBankAccounts()}
            connections={xeroOrganisation.connections}
            selectedOrganisation={partnerOrganisationName}
            loading={loading}
            metaData={metaData}
          />
        </>
      )}
    </div>
  );
};

export default AccountingSetup;
