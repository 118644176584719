import "./clipboard.scss";

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Icon from "../icons";
import ClipboardJS from "clipboard";

let clipboardNumber = 0;

const Clipboard = (props) => {
  const CLIPBOARD_ID = `clipboard__${clipboardNumber++}`;
  const [showCopied, setShowCopied] = useState(false);

  const clipboard = new ClipboardJS(`#${CLIPBOARD_ID}`);

  clipboard.on("success", (event) => {
    const SHOW_COPIED_DURATION_IN_MS = 3000;

    setShowCopied(true);

    setTimeout(() => {
      setShowCopied(false);
    }, SHOW_COPIED_DURATION_IN_MS);
  });

  useEffect(() => {
    return () => clipboard.destroy();
  }, []);

  return (
    <div className={"clipboard"}>
      <Icon
        id={CLIPBOARD_ID}
        className={"clipboard__btn"}
        iconName={"clipboard"}
        data-testid={"clipboard__btn"}
        data-clipboard-text={props.text}
      />
      {showCopied && <span className={"clipboard__copied"}>Copied</span>}
    </div>
  );
};

Clipboard.propTypes = {
  text: PropTypes.string,
};

export default Clipboard;
