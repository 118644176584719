import React, { useEffect, useMemo } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { Button, Card, ChevronLeft, Typography } from "@spenmo/splice";
import useSWR from "swr";
import cn from "classnames";
import qs from "query-string";

import { postData } from "API/Client";

import PaymentMethod from "./PaymentMethod";
import { BillDetailCardSkeleton } from "Views/Bills/V2/components/Skeleton";
import BillDetail from "Views/Bills/V2/PaymentRun/BillDetail";

import { BillProvider } from "Views/Bills/V2/context/BillContext";
import { getBillIDs } from "./helper";
import { API_URL, PAYMENT_RUN_URL } from "Views/Bills/V2/constants";
import { currencyFormatterV2 } from "utility";

import { PaymentRunPreviewParams } from "./type";
import styles from "./PaymentRunPreview.module.scss";

const PaymentRunPreview = () => {
  const { billIDs: base64BillIDs } = useParams<PaymentRunPreviewParams>();
  const location = useLocation();
  const { cycleID } = qs.parse(location.search, { parseNumbers: true });
  const billIDs = getBillIDs(base64BillIDs as string);
  const history = useHistory();

  const payload = {
    ...(typeof cycleID !== "number" ? {} : { cycleID }),
    billIDs,
  };

  const {
    data: billListData,
    isValidating,
    isLoading,
    error,
  } = useSWR(
    [API_URL.billList, payload],
    ([url, payload]) => postData(url, payload),
    {
      revalidateOnFocus: false,
    },
  );

  const billListLoading = isValidating || isLoading;

  const { bills, name, totalWalletAmount, totalWalletCurrency } = useMemo(
    () => billListData?.data?.payload || {},
    [billListData],
  );

  useEffect(() => {
    if (!billIDs || error) {
      history.push(PAYMENT_RUN_URL);
    }
  }, [cycleID, billIDs, error, history]);

  return (
    <BillProvider>
      <div className={styles.paymentRunPreview}>
        <Button
          type="button"
          variant="tertiary"
          icon={ChevronLeft}
          onClick={() => history.push(PAYMENT_RUN_URL)}
        >
          Back
        </Button>
        <div className={styles.grid}>
          <div>
            <Card elevation="level-2" padding="medium" radius="m">
              <BillDetailCardSkeleton loading={billListLoading}>
                <Typography
                  tag="p"
                  variant="body-content"
                  size="m"
                  weight={800}
                  className={cn(
                    styles.cycleTitle,
                    styles.sectionItem,
                    styles.verticalSpace,
                  )}
                >
                  {name}
                </Typography>
                <div className={styles.verticalSpace}>
                  {bills?.map((billItem) => {
                    const {
                      id,
                      amount,
                      attachmentURLs,
                      beneficiaryName,
                      billNumber,
                      currency,
                      dueDate,
                      status,
                      isOverDue,
                    } = billItem;

                    return (
                      <div key={id} className={styles.verticalSpace}>
                        <BillDetail
                          id={id}
                          companyName={beneficiaryName}
                          billNumber={billNumber}
                          dueDate={dueDate}
                          amount={amount}
                          currency={currency}
                          fileUrls={attachmentURLs || []}
                          status={status}
                          isOverDue={isOverDue}
                        />
                      </div>
                    );
                  })}
                </div>
                <div
                  className={cn(
                    styles.totalPayout,
                    styles.sectionItem,
                    styles.verticalSpace,
                  )}
                  data-testid="totalPayout"
                >
                  <Typography
                    tag="p"
                    variant="body-content"
                    size="s"
                    className={styles.bodyColor}
                  >
                    Total payout
                  </Typography>
                  <Typography
                    tag="p"
                    variant="body-content"
                    size="caption-m"
                    className={styles.captionColor}
                  >
                    {totalWalletCurrency}{" "}
                    <Typography
                      variant="body-content"
                      size="s"
                      weight={600}
                      className={styles.titleColor}
                    >
                      {currencyFormatterV2(
                        totalWalletAmount,
                        totalWalletCurrency,
                        false,
                        2,
                      )}
                    </Typography>
                  </Typography>
                </div>
              </BillDetailCardSkeleton>
            </Card>
          </div>
          <Card elevation="level-2" padding="medium" radius="m">
            <PaymentMethod invoiceAmount={totalWalletAmount} />
          </Card>
        </div>
      </div>
    </BillProvider>
  );
};

export default PaymentRunPreview;
