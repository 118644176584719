export const HOME_PAGE = {
  TOP_UP_ACTION: "topup-button",
  PENDING_ACTION_SECTION: "pending-action-section",
};

export const TOPUP_PAGE = {
  TOPUP_NOW_BUTTON: "topup-now-button",
};

export const TRANSACTION_PAGE = {
  // OLD PERMISSIONS
  SYNC_TO_ZERO_BUTTON: "sync-to-zero-button",
  DOWNLOAD_DATA_BUTTON: "download-data-button",
  // NEW PERMISSIONS
  REQUESTER_FILTER: "requester-filter",
  TRANSACTION_TYPE_INCENTIVE: "transaction-type-incentive",
};

export const TRANSACTION_PAGE_DETAILS_PANEL = {
  SAVE_BUTTON: "edit-button",
};

export const TRANSACTION_PAGE_ALL_TRANSACTION_TAB = {
  DOWNLOAD_DATA_BUTTON: "download-data-button",
};

export const TRANSACTION_PAGE_BILL_TRASACTION_TAB = {
  APPROVAL_REQUIRED_TAB: "approval-required-tab",
  ACTIONS_BUTTON: "actions-button",
};

export const TRANSACTION_PAGE_SCHEDULED_INTERNAL_TRANSACTIONS_TAB = {
  EDIT_BUTTON: "edit-button",
  STOP_BUTTON: "stop-button",
};

export const TOPUP_MENU = {
  READ: "read",
};

export const BILL_PAYMENTS_PAGE = {
  SUBMIT_BILL_PAYMENT: "submit-bill-payment",
  SUBMITTED_BILL_TAB: "submitted-bill-tab",
  MANAGE_DRAFTS_TAB: "manage-drafts-tab",
  MANAGE_RECEIPIENTS_TAB: "manage-receipients-tab",
  FX_RATE_CALCULATOR: "fx-rate-calculator",
  BILL_TRANSACTION_PAGE_SHORTCUT: "bill-transaction-page-shortcut",
};

export const BILL_EXPORT_CSV = {
  // SAAS
  GENERIC_DOWNLOAD_SAAS: "generic-download-saas",
  MARK_AS_PAID_SAAS: "mark-as-paid-saas",
  FULL_DOWNLOAD_SAAS: "full-download-saas",
  // SAAS + PAYMENTS
  GENERIC_DOWNLOAD_SAAS_PAYMENTS: "generic-download-saas-payments",
  MARK_AS_PAID_SAAS_PAYMENTS: "mark-as-paid-saas-payments",
  FULL_DOWNLOAD_SAAS_PAYMENTS: "full-download-saas-payments",
};

export const CARDS_PAGE = {
  ADD_CARD_BUTTON: "add-card-button",
  RECEIVED_CARD_REQUEST_TAB: "received-card-request-tab",
  SENT_CARD_REQUEST_TAB: "sent-card-request-tab",
};

export const CARDS_PAGE_CARDS_DETAILS_PAGE = {
  COMPLETE_CARD_NUMBER_CVV: "complete-card-number-cvv",
  ENABLE_DISABLE_BUTTON: "enable-disable-button",
};

export const CARDS_PAGE_CARDS_DETAILS_PAGE_HISTORY_TAB = {
  READ: "read",
};

export const CARDS_PAGE_CARDS_DETAILS_PAGE_SETTINGS_TAB = {
  READ: "read",
  EDIT_BUTTON: "edit-button",
  DELETE_BUTTON: "delete-button",
};

export const REIMBURSEMENT_REQUEST_PAGE = {
  DOWNLOAD_BUTTON: "download-button",
  ADD_REIMBURSEMENT_BUTTON: "add-reimbursement-button",
  REQUESTS_INBOX_ACTIONS: "requests-inbox-actions",
  REQUESTS_INBOX_TAB: "requests-inbox-tab",
  MY_REQUESTS_TAB: "my-requests-tab",
};

export const TEAMS_PAGE = {
  CREATE_NEW_TEAM_BUTTON: "create-new-team-button",
};

export const TEAMS_PAGE_TEAM_PROFILE_PAGE = {
  ACTION_DEMOTE_PROMOTE_BUTTON: "action-demote-promote-button",
  ACTION_CLAWBACK_BUTTON: "action-clawback-button",
  ADD_MEMBERS_BUTTON: "add-members-button",
};

export const TEAMS_PAGE_TEAM_PROFILE_PAGE_POLICIES_TAB = {
  READ: "read",
  SAVE_BUTTON: "save-button",
};

export const EMPLOYEES_PAGE = {
  ADD_EMPLOYEES_BUTTON: "add-employees-button",
  CHANGE_ROLE_DROPDOWN: "change-role-dropdown",
};

export const APPROVAL_FLOW_MENU = {
  READ: "read",
};

export const BANK_ACCOUNT_PAGE = {
  ADD_ACCOUNT_BUTTON: "add-account-button",
  DELETE_ACCOUNT_BUTTON: "delete-account-button",
};

export const CATEGORIES_PAGE = {
  ADD_CATEGORY_BUTTON: "add-category-button",
  DELETE_CATEGORY_BUTTON: "delete-category-button",
  EDIT_CATEGORY_BUTTON: "edit-category-button",
};

export const TAX_PAGE = {
  ADD_NEW_TAX_BUTTON: "add-new-tax-button",
  EDIT_TAX_BUTTON: "edit-tax-button",
};

export const INTEGRATION_MENU = {
  READ: "read",
};

export const INTEGRATION_PAGE = {
  XERO_INTEGRATION: "xero-integration",
};

export const LOW_BALANCE_NOTIFICATION_MENU = {
  READ: "read",
};

export const LOW_BALANCE_NOTIFICATION_PAGE = {
  SAVE_BUTTON: "save-button",
};

export const SECURITY_HUB_MENU = {
  READ: "read",
};

export const BANK_ACCOUNT_MENU = {
  READ: "read",
};

export const REIMBURSEMENT_SETTING_MENU = {
  READ: "read",
  CREATE: "create",
  UPDATE: "update",
  DISABLE: "disable",
};

export const REIMBURSEMENT_TRANSACTION_PAGE = {
  MARK_AS_SETTLE: "mark-as-settled",
  MAKE_PAYMENT: "make-payment",
  UPDATE: "update",
  VIEW_COMPANY_TAB: "view-company-cr-list",
  SAVE_ACCOUNTING_FIELDS: "save-accounting-fields",
  EXPORT_PAYMENT_CSV: "export-payment-csv",
  VIEW_SETTLEMENT: "view-settlement",
  CREATE: "create",
  READ: "read",
};

export const TAX_MENU = {
  READ: "read",
};

export const BILL_MENU = {
  READ: "read",
};

export const CENTRALISED_APPROVAL_MENU = {
  READ: "read",
};

export const ANALYTICS_MENU = {
  READ: "read",
};

export const COMPANY_BALANCE = {
  COMPANY_BALANCE_BUTTON: "company-balance-button",
};

export const CATEGORY_MENU = {
  READ: "read",
};

export const ACCOUNTING_SETTING_MENU = {
  READ: "read",
};

export const ACCOUNTING_SETTING_PAGE = {
  UPDATE: "update",
};

export const PAYMENT_RUN_SETTING_PAGE = {
  READ: "read",
};

export const PAYMENT_RUN_BILL_PAY_PAGE = {
  READ_SETUP: "read-setup",
  READ_CYCLE: "read-cycle",
  INITIATE_PAYMENT: "initiate-payment",
};

export const SUBSCRIPTIONS = {
  CREATE: "create",
  UPDATE: "update",
  RENEW: "renew",
  STOP: "stop",
  RESTART: "restart",
  SUMMARY: "summary",
};

export const BILLING_PAGE = {
  WRITE: "write",
};
