import React, { useContext, useState } from "react";
import cn from "classnames";

import { Checkbox, IllCards, Typography, Button } from "@spenmo/splice";
import {
  EmployeesDispatchFailsContext,
  EmployeesModalContext,
  IEmployeesDispatchFailsContext,
  IEmployeesModalContext,
} from "Views/Employee/context";
import { ModalComponent } from "Modules/DS/Modal";
import { TITLE_TYPE } from "Modules/DS/Modal/types";
import { INITIAL_ROLE_UPDATE_CALL_COUNT } from "constants/Employee.constant";
import { ADMIN_ROLE_ID, EmployeeModalTypes } from "Views/Employee/types";
import { trackEvent } from "utility/analytics";
import { EmployeeRoleUpdateEventName } from "Views/Employee/Analytics";

import styles from "./AdminConfirmation.module.scss";

export const AdminConfirmation = ({
  title,
  usage,
  employee,
  action,
}: {
  title: string;
  usage: "budget_owner" | "admin";
  employee?: any;
  action?: () => void;
}) => {
  const { resetModal, showModal, toggleModal }: IEmployeesModalContext = useContext(EmployeesModalContext);
  let { record } = showModal?.metaData || {};

  if (!record) {
    record = { name: `${employee?.user?.firstName} ${employee?.user?.lastName}` };
  }

  const { dispatchRoleUpdate }: IEmployeesDispatchFailsContext = useContext(EmployeesDispatchFailsContext);

  const [agreeToTnc, setAgreeToTnc] = useState(false);

  const promoteAsAdmin = () => {
    if (action) {
      action();
      return;
    }
    resetModal();
    toggleModal(EmployeeModalTypes.ROLE, { record });
    dispatchRoleUpdate(INITIAL_ROLE_UPDATE_CALL_COUNT, ADMIN_ROLE_ID, record);
    trackEvent(EmployeeRoleUpdateEventName.ROLE_UPDATE_STATUS, { role_update_event_action: record.role_name });
  };

  return (
    <>
      <ModalComponent.Title titleType={TITLE_TYPE.withBorder}>{title}</ModalComponent.Title>

      <div className={styles.container}>
        <IllCards size={120} />
        <Typography variant="body-content" size="s" className={styles.information}>
          {usage === "admin" ? (
            <span>
              Before you make the role switch for <span className={styles.user}>{record?.name?.trim()}</span>, please
              note that Admin users' powers include the approval/issuance of cards under the Company's account.
            </span>
          ) : (
            <span>
              Before you make the role switch for <span className={styles.user}>{record?.name?.trim()}</span>, please
              note that Budget owner's powers include the approval/issuance of cards under the Company's account.
            </span>
          )}
        </Typography>

        <div className={styles.divider}></div>

        <div className={styles.termAndCondition}>
          <Checkbox onChange={(e) => setAgreeToTnc(e.target.checked)}>
            <Typography variant="body-content" size="caption-m" className={styles.checkboxLabel} tag="p">
              {usage === "admin" ? (
                <span>
                  I acknowledge that any Admin users can approve/issue cards and exercise other Admin functions under
                  the Company's account. I further agree to the{" "}
                  <a href="https://spenmo.com/legal" target="_blank" rel="noreferrer" className={styles.link}>
                    Terms & Conditions
                  </a>{" "}
                  applicable to the assignment and delegation of Admin functions to the relevant designated users
                </span>
              ) : (
                <span>
                  I am aware that all Budget Owners can approve/issue cards under their respective Budgets. I further
                  agree to the{" "}
                  <a href="https://spenmo.com/legal" target="_blank" rel="noreferrer" className={styles.link}>
                    Terms & Conditions
                  </a>{" "}
                  applicable to the issuance of cards to the relevant designated users.
                </span>
              )}
            </Typography>
          </Checkbox>
        </div>

        <div className={cn(styles.divider, styles.fullWidth)}></div>
        <div className={styles.button}>
          <Button variant="secondary" onClick={() => resetModal()}>
            Cancel
          </Button>
          <Button variant="primary" disabled={!agreeToTnc} onClick={promoteAsAdmin}>
            Confirm Role Switch
          </Button>
        </div>
      </div>
    </>
  );
};
