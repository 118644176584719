import React from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { verify2Fa, VERIFY_2FA_FAILURE, VERIFY_2FA_REQUEST, LOGIN_FAILURE } from "Redux/Actions/loginAction";

import { LOGIN_PATH } from "constants/Routes.constants";
import { OTP_ERROR, OTP_MAX_LENGTH } from "constants/TfaOtp.constant";

import OTPInputForm from "Modules/otpInputForm";
import LoginScreenWrapper from "Modules/loginScreenWrapper";

import LoaderIcon from "Views/State/Loading/LoaderIcon";
import "Views/EmployeeOTP/OTPVerification/OTPVerification.scss";
import { FailedAttemptsG2FA } from "Views/Auth/Components/FailedAttempts";

const OTPCodeEntry = ({ hasError = false }: { hasError?: boolean }): React.ReactElement => {
  const dispatch = useDispatch();
  return (
    <>
      <p className="employee-otp-verification__desc">Enter the 6-digit code from your Google Authenticator app</p>
      <OTPInputForm
        onFinish={(otp) => {
          const isFilled = otp.length === OTP_MAX_LENGTH;
          if (isFilled) {
            dispatch(verify2Fa({ otp }));
          }
        }}
        type="secondary-xl"
      />
      {hasError && <p className="employee-otp-verification__error">The code you entered is invalid</p>}
      <p className={"contact-support"}>
        Cannot access your phone? Contact
        <a href={"mailto:support@spenmo.com"}> support@spenmo.com </a> for assistance
      </p>
    </>
  );
};

const OTPVerification = (): React.ReactElement => {
  const { step, errorCode } = useSelector(({ loginReducer }: any) => loginReducer);

  const isAccountLocked =
    (step === VERIFY_2FA_FAILURE && errorCode === OTP_ERROR.ACCOUNT_2FA_LOCKED) ||
    (step === LOGIN_FAILURE && errorCode === OTP_ERROR.ACCOUNT_LOGIN_LOCKED);

  const getOtpVerificationScreen = (): React.ReactElement => {
    switch (true) {
      case step === VERIFY_2FA_REQUEST:
        return <LoaderIcon />;
      case step === VERIFY_2FA_FAILURE && errorCode === OTP_ERROR.OTP_MISMATCH:
        return <OTPCodeEntry hasError={errorCode !== undefined} />;
      case isAccountLocked:
        return <FailedAttemptsG2FA />;
      default:
        return <OTPCodeEntry />;
    }
  };

  return (
    <LoginScreenWrapper className="employee-otp--wrapper" id="employee-otp">
      {isAccountLocked ? (
        <FailedAttemptsG2FA />
      ) : (
        <div className="employee-otp-verification">
          <h3 className="employee-otp-verification__title">Verify Your Login</h3>
          {getOtpVerificationScreen()}
          <div className="auth-box__redirect">
            <Link id="goLogin" to={LOGIN_PATH} role="button" onClick={() => window.location.reload()} replace>
              Back to Login
            </Link>
          </div>
        </div>
      )}
    </LoginScreenWrapper>
  );
};

export default OTPVerification;
