import { useSelector } from "react-redux";

// Custom hook to check organization configurations for a specific feature
const useCheckOrgConfigs = (featureName: string, loading: boolean = false) => {
  // Get organization configurations from Redux store
  const orgConfigs = useSelector((state: any) => state?.orgConfigs);

  // Extract the value of the specified feature from organization configurations
  const value = orgConfigs?.data?.payload?.configs?.[featureName]?.value;

  // Treat undefined or null value as false
  const valueTreatment = value ?? false;

  // If loading flag is true, handle loading state
  if (loading) {
    // If value is undefined or null, return treated value which is false along with loading state as false.
    // Since we have the org config dispatch on Session Container, the api gets called when the orgConfig value is undefined
    // We are hardcoding the return values for undefined state as the config based redirections
    // become unrelaiable when we receive [false, false] on inital page load due to undefined value
    if (value === undefined || value === null) {
      return [false, true];
    } else {
      // Otherwise, return treated value along with the loading state from organization configurations
      return [valueTreatment, orgConfigs.loading];
    }
  }
  // Return treated value
  return valueTreatment;
};

export default useCheckOrgConfigs;
