import { useRouteMatch } from "react-router-dom";

import { CASBIN_PAGES } from "Permission/Pages";
import usePermissionCheck from "Permission/usePermissionCheck";
import { REIMBURSEMENT_TRANSACTION_PAGE } from "Permission/Actions";

import { PRODUCT_NAME } from "Redux/ModularProduct";

import useCheckOrgConfigs from "customHooks/useCheckOrgConfigs";

import { ILocationParams, IUseExportOptionsList } from "Views/Reimbursement/@types";
import { REIMBURSEMENT_PAGE_OPERATIONS, REIMBURSEMENT_SETTLEMENT_STATUSES } from "Views/Reimbursement/Constants";

export const FULL_CSV = {
  id: "REIMBURSEMENT_EXPORT_OPTION_FULL_CSV",
  title: "Full CSV",
  description: "Contains all details stored in Spenmo. Use this format for reporting or further analysis.",
};

export const GENERIC_PAYMENT_CSV = {
  id: "REIMBURSEMENT_EXPORT_OPTION_GENERIC_PAYMENT_CSV",
  title: "Generic Payment CSV",
  description: "Contains payment details for initiating payouts with your preferred banks or financial services.",
};

export const UOB_FAST_GIRO_CSV = {
  id: "REIMBURSEMENT_EXPORT_OPTION_UOB_FAST_GIRO_CSV",
  title: "UOB FAST GIRO TXT",
  description:
    "Contains payment details formatted for initiating payouts inside UOB SME with UOB (United Overseas Bank Limited).",
};

export const DBS_INTERBANK_GIRO_CSV = {
  id: "REIMBURSEMENT_EXPORT_OPTION_DBS_INTERBANK_GIRO_TXT",
  title: "DBS Interbank GIRO TXT",
  description: "Contains payment details formatted for initiating payouts inside DBS IDEAL with DBS (DBS Bank Ltd).",
};

const useExportOptionsList = (): IUseExportOptionsList => {
  const { params } = useRouteMatch<ILocationParams>();
  const { operation = "", id = "" } = params;

  const permissionParam = [
    { object: CASBIN_PAGES.REIMBURSEMENT_TRANSACTION_PAGE, action: REIMBURSEMENT_TRANSACTION_PAGE.EXPORT_PAYMENT_CSV },
  ];

  const [isCrConfigEnabled, loadingCrConfig] = useCheckOrgConfigs(PRODUCT_NAME.CASH_REIMBURSEMENT, true) as [
    boolean,
    boolean
  ];

  const [permissions, loadingPermissions] = usePermissionCheck(permissionParam, true) as [boolean, boolean];

  const isApprovedSettlementsView =
    operation === REIMBURSEMENT_PAGE_OPERATIONS.SETTLEMENTS && id === REIMBURSEMENT_SETTLEMENT_STATUSES.APPROVED.id;

  const isUnpaidPayoutsView =
    operation === REIMBURSEMENT_PAGE_OPERATIONS.PAYOUTS &&
    id === REIMBURSEMENT_SETTLEMENT_STATUSES.UNPAID_FOR_PAYOUTS_VIEW.id;

  // Hidden till the time of generic payment csv release
  const isGenericPaymentCsvEnabled = (permissions && (isApprovedSettlementsView || isUnpaidPayoutsView)) || false;
  const isUOBFastGiroTxtEnabled = false;
  const isDbsInterbankGiroTxtEnabled = false;

  const list = [
    {
      id: FULL_CSV.id,
      title: FULL_CSV.title,
      description: FULL_CSV.description,
      enabled: true,
    },
    {
      id: GENERIC_PAYMENT_CSV.id,
      iconColor: "#797979",
      title: GENERIC_PAYMENT_CSV.title,
      description: GENERIC_PAYMENT_CSV.description,
      enabled: isGenericPaymentCsvEnabled,
    },
    {
      id: UOB_FAST_GIRO_CSV.id,
      iconColor: "#0C1976",
      title: UOB_FAST_GIRO_CSV.title,
      description: UOB_FAST_GIRO_CSV.description,
      comingSoon: true,
      enabled: isUOBFastGiroTxtEnabled,
    },
    {
      id: DBS_INTERBANK_GIRO_CSV.id,
      iconColor: "#EB483F",
      title: DBS_INTERBANK_GIRO_CSV.title,
      description: DBS_INTERBANK_GIRO_CSV.description,
      comingSoon: true,
      enabled: isDbsInterbankGiroTxtEnabled,
    },
  ].filter((item) => item.enabled);

  return {
    list,
    loading: loadingPermissions || loadingCrConfig || !isCrConfigEnabled,
  };
};

export default useExportOptionsList;
