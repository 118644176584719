import { userInitialState } from "Redux/InitialState";
import {
  FETCH_ACCOUNTING_TAG_REQUEST,
  FETCH_ACCOUNTING_TAG_SUCCESS,
  FETCH_ACCOUNTING_TAG_FAILURE,
  UPDATE_ACCOUNTING_TAGS_VALUE,
} from "Redux/Actions/AccountingTag/accountingTagAction";

const accountingTagInitialState = {
  ...userInitialState,
  loading: true,
  finish: false,
  data: {
    payload: {
      tags: [],
    },
  },
};

export const accountingTagReducer = (state = accountingTagInitialState, action) => {
  switch (action.type) {
    case FETCH_ACCOUNTING_TAG_REQUEST:
      return {
        ...state,
        loading: true,
        finish: false,
        error: false,
      };
    case FETCH_ACCOUNTING_TAG_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        finish: true,
        data: action.payload,
      };
    case FETCH_ACCOUNTING_TAG_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case UPDATE_ACCOUNTING_TAGS_VALUE:
      return {
        ...state,
        data: {
          payload: {
            tags: action.payload,
          },
        },
      };
    default:
      return state;
  }
};
