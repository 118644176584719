import { createContext } from "react";

export interface AuthPageContextProps {
  error?: boolean;
  loading?: boolean;
  appendClassName?: string;
  errorMessage?: string;
  showTnC?: boolean;
}

export const AuthPageContext: React.Context<AuthPageContextProps> = createContext({
  error: false,
  loading: false,
  appendClassName: "",
  errorMessage: "Error, Try again",
  showTnC: false,
});
