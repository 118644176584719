import React, { useContext, useEffect, useState } from "react";
import { searchBlack50 as searchIcon } from "assets/img";
import "./index.scss";
import { Input, Radio } from "antd";
import Icon from "Modules/icons";
import { TagsDropdownContext } from "Views/Card/CreateOrRequest/Steps/Policy/TagsDropdown/context";
import { ITag } from "Views/Card/types";

const TagOption = () => {
  const [value, setValue] = useState("");
  const { data, selectedTag, selectChildren } = useContext(TagsDropdownContext);
  const { values = [], selectedChildren } = data?.find((tag: ITag) => tag.id === selectedTag) || {};

  const filteredData = values.filter((tag: ITag) => tag.value.toLowerCase().includes(value.toLowerCase()));

  useEffect(() => {
    setValue("");
  }, [selectedTag]);
  const [selectedOption, setSelectedOption] = useState(selectedChildren);
  return (
    <>
      <div className="search-form_1">
        <Icon className="search-form_1__icon" src={searchIcon} />
        <Input
          placeholder="Search Tag Option"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          className="search-form_1__input"
        />
      </div>
      {filteredData.length === 0 ? (
        <span className="error">Tag Option is not available. Please add new Tag Option inside Xero.</span>
      ) : (
        <Radio.Group
          onChange={(event) => {
            setSelectedOption(event.target.value);
            selectChildren(selectedTag, event.target.value);
          }}
          value={selectedOption || selectedChildren}
          className="tag-options"
        >
          {filteredData.map((tag: ITag) => (
            <Radio value={tag.id} className="tag-options__option" key={tag.id}>
              <span>{tag.value}</span>
            </Radio>
          ))}
        </Radio.Group>
      )}
    </>
  );
};

export default TagOption;
