import "./topup.scss";

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "antd";
import { getOrgDetailFunc } from "Redux/Actions";

import TopUpPaymentType from "./TopUpPaymentType/TopUpPaymentType";

import { GetBaseAuthObject } from "utility";
import { NiumDisclaimer } from "Modules/NiumDisclaimer";
import { trackEvent } from "utility/analytics";
import { TOPUP_PAYMENT_TYPES } from "constants/TopUp.constant";
import { CURRENCY_CODE } from "constants/Currency.constant";

const { BANK_TRANSFER, PAY_NOW, TRANSFER_BELOW_50, TRANSFER_ABOVE_50 } = TOPUP_PAYMENT_TYPES;

/**
 *
 * @param {string} currencyCode to determine which tabs will be shown
 * @param {boolean} fundingAccountNumber to determine whether the PHP dashboard will show transfers above 50k
 * default is true to make it not show the Transfers above 50k at the first time
 * @returns
 */
const getPaymentTypeOptions = (currencyCode, fundingAccountNumber = true) => {
  switch (currencyCode) {
    case CURRENCY_CODE.SGD:
      return [BANK_TRANSFER, PAY_NOW];
    case CURRENCY_CODE.USD:
      return [BANK_TRANSFER];
    case CURRENCY_CODE.PHP: {
      const tabs = [TRANSFER_BELOW_50];

      if (!fundingAccountNumber) {
        tabs.push(TRANSFER_ABOVE_50);
      }

      return tabs;
    }
    default:
      return [BANK_TRANSFER];
  }
};

const TopUpPaymentTypeOptions = ({ currencyCode }) => {
  const dispatch = useDispatch();

  const [selectedPaymentType, setSelectedPaymentType] = useState(getPaymentTypeOptions(currencyCode)[0]);

  const orgDetail = useSelector((state) => state.b2bOrgDetailReducer?.data?.payload || {});

  useEffect(() => {
    dispatch(getOrgDetailFunc(GetBaseAuthObject().orgId));
  }, [dispatch]);

  // To track selected payment method to do top up
  useEffect(() => {
    const params = { selectedPaymentMethod: selectedPaymentType };
    trackEvent("top up method selected", params);
  }, [selectedPaymentType]);

  const trackTopupNow = (topUpAmount) => {
    const params = {
      selectedPaymentMethod: selectedPaymentType,
      amount: topUpAmount,
      name: "Top up now",
    };
    trackEvent("top up button clicked", params);
  };

  const PaymentTypeOption = (paymentType) => {
    const _className = classNames("topup__payment-btn", {
      "topup__payment-btn--active": paymentType === selectedPaymentType,
    });

    return (
      <div className={_className} key={paymentType} onClick={() => setSelectedPaymentType(paymentType)}>
        {paymentType}
      </div>
    );
  };

  return (
    <Row gutter={16}>
      <Col lg={8} md={24}>
        {getPaymentTypeOptions(currencyCode, orgDetail.fundingAccountNumber).map((paymentType) => {
          return PaymentTypeOption(paymentType);
        })}
      </Col>
      <Col lg={16} md={24}>
        <div className={"topup__payment-wrapper"}>
          <TopUpPaymentType
            paymentType={selectedPaymentType}
            currencyCode={currencyCode}
            trackTopupNow={trackTopupNow}
          />
        </div>
        <div className="topup__nium">
          <NiumDisclaimer type="topup" />
        </div>
      </Col>
    </Row>
  );
};

TopUpPaymentTypeOptions.propTypes = {
  currencyCode: PropTypes.string,
};

export default TopUpPaymentTypeOptions;
