import React, { forwardRef } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import "Modules/button/button.scss";
import LoadingIcon from "Modules/loading";
import Loading3Quaters from "Modules/loading/loading3Quaters";
import Icon from "Modules/icons";

const Button = forwardRef(
  (
    {
      id,
      testid = "button",
      value = "Submit",
      action,
      text,
      icon,
      iconName,
      type,
      className,
      rounded,
      textButton,
      secondary,
      btnStyle,
      outline,
      plain,
      disabled,
      loading,
      size,
      children,
      showOnlyLoaderIconWhenLoading = false,
    },
    ref
  ) => {
    // TODO: Refactor textButton, secondary, plain using btnStyle for button style variation
    const _className = classNames(
      "button",
      {
        [`button--${size}`]: size,
        [`button--${btnStyle}`]: btnStyle,
        "button--disabled": (loading && !showOnlyLoaderIconWhenLoading) || disabled,
        "button--rounded": rounded,
        "button--text-button": textButton,
        "button--secondary": secondary,
        "button--plain": plain,
        "button--outline": outline,
        "button--none-pointer-events": loading && showOnlyLoaderIconWhenLoading,
      },
      className
    );

    const buttonComponents = () => (
      <>
        {loading && <LoadingIcon inline size={"s"} />}
        {icon && <span className={"button__icon"}>{icon}</span>}
        {iconName && <Icon className="button__icon" iconName={iconName} />}
        <span>{children || text}</span>
      </>
    );

    return (
      <button ref={ref} data-testid={testid} id={id} className={_className} onClick={action} value={value} type={type}>
        {loading && showOnlyLoaderIconWhenLoading ? <Loading3Quaters color="white" /> : buttonComponents()}
      </button>
    );
  }
);

Button.propTypes = {
  id: PropTypes.string,
  action: PropTypes.func,
  text: PropTypes.string,
  className: PropTypes.string,
  testid: PropTypes.string,
  value: PropTypes.string,
  secondary: PropTypes.bool,
  plain: PropTypes.bool,
  outline: PropTypes.bool,
  btnStyle: PropTypes.string,
  rounded: PropTypes.bool,
  icon: PropTypes.element,
  iconName: PropTypes.string,
  disabled: PropTypes.bool,
  textButton: PropTypes.bool,
  children: PropTypes.any,
  type: PropTypes.string,
  loading: PropTypes.bool,
  size: PropTypes.string,
  showOnlyLoaderIconWhenLoading: PropTypes.bool,
};

export default Button;
