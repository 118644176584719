import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { logoutFunc } from "Redux/Actions";

import { AUTH_PATHS } from "Route/Auth/paths";

const useLogout = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  return (path = AUTH_PATHS.LOGIN) => {
    localStorage.clear();
    sessionStorage.clear();
    dispatch(logoutFunc());
    history.replace(path);
  };
};

export const LogoutOnLoad = () => {
  const logout = useLogout();

  useEffect(() => {
    logout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default useLogout;
