import React, { useEffect } from "react";

import Tabs from "Views/Employee/Create/Page/Tabs";
import Header from "Views/Employee/Create/Page/Header";
import { useHistory } from "react-router-dom";
import { useIsSessionExpired } from "customHooks/Auth/useIsSessionExpired";

import usePermissionCheck from "Permission/usePermissionCheck";
import { CASBIN_PAGES } from "Permission/Pages";
import { EMPLOYEES_PAGE } from "Permission/Actions";
import LoaderIcon from "Views/State/Loading/LoaderIcon";

import "./index.scss";

const AddEmployee = () => {
  const history = useHistory();
  const permissionParam = [{ object: CASBIN_PAGES.EMPLOYEES_PAGE, action: EMPLOYEES_PAGE.ADD_EMPLOYEES_BUTTON }];
  const [IS_ALLOWED_ADD_EMPLOYEES, loading]: any = usePermissionCheck(permissionParam, true);
  const isSessionExpired = useIsSessionExpired();

  useEffect(() => {
    if (!loading && !IS_ALLOWED_ADD_EMPLOYEES && !isSessionExpired) {
      history.push("/404");
    }
  }, [IS_ALLOWED_ADD_EMPLOYEES, loading]);
  return loading ? (
    <LoaderIcon />
  ) : (
    <div className="add-container">
      <Header />
      <Tabs />
    </div>
  );
};

export default AddEmployee;
