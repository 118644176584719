const getDateLabel = (start: string, end: string) => {
  const [sDay, sMonth, sDate, sYear]: string[] = new Date(start).toDateString().split(" ");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, eMonth, eDate, eYear]: string[] = new Date(end).toDateString().split(" ");

  const startDate: number = new Date(start).getDate();
  const startMonth: number = new Date(start).getMonth();
  const startYear: number = new Date(start).getFullYear();

  const endDate: number = new Date(end).getDate();
  const endMonth: number = new Date(end).getMonth();
  const endYear: number = new Date(end).getFullYear();

  let label: string = "";

  if (startYear === endYear && startMonth === endMonth && startDate === endDate) {
    label = `${sDate} ${sDay} ${sMonth} ${sYear}`;
  } else if (startYear === endYear && startMonth === endMonth && startDate < endDate) {
    label = `${sDate} - ${eDate} ${sMonth} ${sYear}`;
  } else if (startYear === endYear && startMonth < endMonth) {
    label = `${sDate} ${sMonth} - ${eDate} ${eMonth} ${sYear}`;
  } else if (startYear < endYear) {
    label = `${sDate} ${sMonth} ${sYear} - ${eDate} ${eMonth} ${eYear}`;
  }

  return label;
};

export default getDateLabel;
