/**
 * @fileoverview This module defines the main Container component of the application.
 * The Container acts as a high-order component wrapping the main content and
 * providing a structured layout with grid configurations.
 *
 * It integrates a SessionManager to manage session-related conditions
 * and uses a LayoutBuilder to create the desired layout. The actual
 * content is then rendered within a Content component based on the
 * provided grid configuration.
 *
 * @author Krishna, Ritik, Sushmitha, Bakshi
 * @created 6 Feb 2023
 * @lastModified 1 Sept 2023
 */
import React from "react";

// Types for routing configuration
import { IPageGrid, PAGE_BASE_COLOR_TYPE, PAGE_GRID_COLUMNS, PAGE_GRID_CONTENT_TYPE } from "Route/@types";

import Content from "./Content";
import LayoutBuilder from "./Layout";
import SessionManager from "./Session";

/**
 * Type definition for Container component properties.
 *
 * @property {React.ReactNode} children - Represents the main content components to be rendered inside the container.
 * @property {IPageGrid} [grid] - Optional layout grid configuration.
 */
export interface IContainerProps {
  children: React.ReactNode;
  grid?: IPageGrid;
}

/**
 * Container Component
 *
 * The `Container` component acts as a primary wrapper around the application content. It integrates
 * session management, layout building, and content rendering to ensure a cohesive structure
 * throughout the application.
 *
 * @param {IContainerProps} props - Properties provided to the Container component.
 * @returns {React.ReactElement} The rendered Container component structure.
 */
const Container: React.FC<IContainerProps> = ({ children, grid }: IContainerProps): React.ReactElement => {
  // Destructure grid object for easy access
  const {
    columns = PAGE_GRID_COLUMNS.SINGLE,
    content = PAGE_GRID_CONTENT_TYPE.FLUID,
    baseColor = PAGE_BASE_COLOR_TYPE.DEFAULT,
  } = grid;

  return (
    // SessionManager manages conditions related to the current session
    <SessionManager>
      {(conditions) => (
        // LayoutBuilder constructs the UI layout based on content type and session conditions
        <LayoutBuilder content={content} baseColor={baseColor} {...conditions}>
          {/* Rendering the main content within a Content component based on grid columns configuration */}
          <Content columns={columns}>
            {/* Main content from the router */}
            {children}
          </Content>
        </LayoutBuilder>
      )}
    </SessionManager>
  );
};

export default Container;
