import { CARD_LIMIT_TYPES } from "Views/Card/types";
import { PlacementType } from "Views/Card/Components/Tooltip/types";

export const CARD_LIMITS_LABELS = {
  [CARD_LIMIT_TYPES.dailyLimit]: "Daily",
  [CARD_LIMIT_TYPES.monthlyLimit]: "Monthly",
  [CARD_LIMIT_TYPES.yearlyLimit]: "Yearly",
  [CARD_LIMIT_TYPES.totalLimit]: "Total",
  [CARD_LIMIT_TYPES.unlimited]: "Unlimited",
};

export const CARD_LIMITS = [
  { label: CARD_LIMITS_LABELS[CARD_LIMIT_TYPES.dailyLimit], value: CARD_LIMIT_TYPES.dailyLimit },
  { label: CARD_LIMITS_LABELS[CARD_LIMIT_TYPES.monthlyLimit], value: CARD_LIMIT_TYPES.monthlyLimit },
  { label: CARD_LIMITS_LABELS[CARD_LIMIT_TYPES.yearlyLimit], value: CARD_LIMIT_TYPES.yearlyLimit },
  { label: CARD_LIMITS_LABELS[CARD_LIMIT_TYPES.totalLimit], value: CARD_LIMIT_TYPES.totalLimit },
  { label: CARD_LIMITS_LABELS[CARD_LIMIT_TYPES.unlimited], value: CARD_LIMIT_TYPES.unlimited },
];

export const CARD_LIMITS_INFO_TEXTS = {
  [CARD_LIMIT_TYPES.dailyLimit]: "Card limit will reset each day at midnight",
  [CARD_LIMIT_TYPES.monthlyLimit]: "Monthly Limit means the Card Limit will reset every month.",
  [CARD_LIMIT_TYPES.yearlyLimit]: "Yearly Limit means the Card Limit will reset every year.",
  [CARD_LIMIT_TYPES.totalLimit]: "Total Limit means the Card Limit will not reset.",
  [CARD_LIMIT_TYPES.unlimited]: "Unlimited means the card can be used within the remaining Budget Limit.",
};

interface ICardLimitWithTooltipInfo {
  label: string;
  value: CARD_LIMIT_TYPES;
  tooltipText: string;
  tooltipPosition: PlacementType;
  enabled: boolean;
}

export const getCardLimitsWithTooltipInfo: (dailyFilterEnabled: boolean) => ICardLimitWithTooltipInfo[] = (
  dailyFilterEnabled
) => {
  const limitsInfo: ICardLimitWithTooltipInfo[] = [
    {
      label: CARD_LIMITS_LABELS[CARD_LIMIT_TYPES.dailyLimit],
      value: CARD_LIMIT_TYPES.dailyLimit,
      tooltipText: CARD_LIMITS_INFO_TEXTS[CARD_LIMIT_TYPES.dailyLimit],
      tooltipPosition: "topLeft",
      enabled: dailyFilterEnabled,
    },
    {
      label: CARD_LIMITS_LABELS[CARD_LIMIT_TYPES.monthlyLimit],
      value: CARD_LIMIT_TYPES.monthlyLimit,
      tooltipText: CARD_LIMITS_INFO_TEXTS[CARD_LIMIT_TYPES.monthlyLimit],
      tooltipPosition: dailyFilterEnabled ? "top" : "topLeft",
      enabled: true,
    },
    {
      label: CARD_LIMITS_LABELS[CARD_LIMIT_TYPES.yearlyLimit],
      value: CARD_LIMIT_TYPES.yearlyLimit,
      tooltipText: CARD_LIMITS_INFO_TEXTS[CARD_LIMIT_TYPES.yearlyLimit],
      tooltipPosition: "top",
      enabled: true,
    },
    {
      label: CARD_LIMITS_LABELS[CARD_LIMIT_TYPES.totalLimit],
      value: CARD_LIMIT_TYPES.totalLimit,
      tooltipText: CARD_LIMITS_INFO_TEXTS[CARD_LIMIT_TYPES.totalLimit],
      tooltipPosition: dailyFilterEnabled ? "topRight" : "top",
      enabled: true,
    },
    {
      label: CARD_LIMITS_LABELS[CARD_LIMIT_TYPES.unlimited],
      value: CARD_LIMIT_TYPES.unlimited,
      tooltipText: CARD_LIMITS_INFO_TEXTS[CARD_LIMIT_TYPES.unlimited],
      tooltipPosition: "topRight",
      enabled: !dailyFilterEnabled,
    },
  ];
  return limitsInfo.filter((item) => item.enabled);
};
