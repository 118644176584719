import * as React from "react";
import { InitialState, ActionType, StateType } from "Views/Card/CreateOrRequest/Utilities/UseCreateOrRequestState";
import { CARD_TYPE } from "Views/Card/types";

interface CreateOrRequestCardContextType {
  state: StateType;
  isManagerOrAdmin: boolean;
  cardType: CARD_TYPE;
  dispatch: React.Dispatch<ActionType>;
  changeStep: (goToNextStep: boolean) => void;
  cardRequestInProgress: boolean;
}

const CreateOrRequestCardContext = React.createContext<CreateOrRequestCardContextType>({
  state: InitialState,
  isManagerOrAdmin: false,
  cardType: CARD_TYPE.VIRTUAL,
  dispatch: () => {
    /* do nothing */
  },
  changeStep: (_goToNextStep: boolean) => {
    /* do nothing */
  },
  cardRequestInProgress: false,
});

export const useCreateOrRequestCardContext = () => React.useContext(CreateOrRequestCardContext);

export default CreateOrRequestCardContext;
