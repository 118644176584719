import * as React from "react";
import { useRef } from "react";
import { Select, ConfigProvider, Empty } from "antd";
import Label from "Modules/DS/Atoms/Label";
import Icon from "Modules/icons";

import "./CountrySelect.scss";
import { emptyDefaultIcon } from "assets/img";
import { RefSelectProps } from "antd/lib/select";

export interface CountrySelectProps {
  disabled?: boolean;
  label?: string;
  value: string;
  loading?: boolean;
  onChange: (value: string, option?: any) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  placeholder?: string;
  countryList?: Array<{
    countryId: number;
    countryCode: string;
    countryName: string;
  }>;
  countryGroupList?: Array<{
    label: string;
    list: Array<{
      countryId: number;
      countryCode: string;
      countryName: string;
    }>;
  }>;
}

const CountrySelect = ({
  disabled,
  label,
  value,
  loading,
  onChange,
  onFocus = () => undefined,
  onBlur = () => undefined,
  countryList,
  countryGroupList,
  placeholder,
}: CountrySelectProps) => {
  const countryCodeRef = useRef<RefSelectProps>();
  return (
    <>
      {label && (
        <Label
          onClick={() => {
            countryCodeRef?.current?.focus();
          }}
        >
          {label}
        </Label>
      )}
      <ConfigProvider renderEmpty={() => <Empty description="No results found" image={emptyDefaultIcon} />}>
        <Select
          placeholder={placeholder}
          ref={countryCodeRef}
          className="country-select"
          showSearch
          optionFilterProp="label"
          value={value}
          onChange={onChange}
          dropdownClassName="country-select__dropdown"
          onFocus={onFocus}
          onBlur={onBlur}
          loading={loading}
          disabled={disabled || loading}
          getPopupContainer={(triggerNode) => triggerNode.parentElement}
        >
          {countryList?.map((item) => (
            <Select.Option key={item.countryId} value={item.countryCode} label={item.countryName}>
              <div className="country-select__option">
                <Icon
                  alt={item.countryCode}
                  className="country-select__flag"
                  src={`https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.5.0/flags/4x3/${item.countryCode.toLowerCase()}.svg`}
                />
                <span>{item.countryName}</span>
              </div>
            </Select.Option>
          ))}
          {countryGroupList?.map((group) => (
            <Select.OptGroup key={group.label} label={group.label}>
              {group.list.length > 0 &&
                group.list.map((item) => (
                  <Select.Option key={item.countryId} value={item.countryCode} label={item.countryName}>
                    <div className="country-select__option">
                      <Icon
                        alt={item.countryCode}
                        className="country-select__flag"
                        src={`https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.5.0/flags/4x3/${item.countryCode.toLowerCase()}.svg`}
                      />
                      <span>{item.countryName}</span>
                    </div>
                  </Select.Option>
                ))}
            </Select.OptGroup>
          ))}
        </Select>
      </ConfigProvider>
    </>
  );
};

export default CountrySelect;
