import React, { useContext } from "react";

import { IFilterItem } from "Modules/DS/Filter/@types";
import Chip, { IActionChipProps } from "Modules/DS/Chip";
import Popover, { IPopoverProps } from "Modules/DS/Popover";
import { ISelectContext, SelectContext } from "Modules/DS/Select";
import { Tooltip, TooltipArrowAlignmentEnum, TooltipPositionEnum } from "Modules/DS/Tooltip";

import { ActionChip } from "@spenmo/splice";

import styles from "./Item.module.scss";

const TITLE_LENGTH_LIMIT = 20;
export const FILTER_POPOVER_CLASS = "filter-item";

export const FilterItem = ({
  id,
  title,
  children,
  clearFilter,
  popoverWidth,
  iconClass = "",
  tooltipText = "",
  selected = false,
  positionAlgoType,
  useDynamicPosition = false,
  onClickOutside = () => null,
  ignoreClickOnElements = [],
  containerClass,
  renderSpliceActionChip = false,
}: IFilterItem): JSX.Element => {
  const { select, closeSelect, onSelectHandler } = useContext<ISelectContext>(SelectContext);
  const active: boolean = select.show && select.key === id;

  const chipProps: IActionChipProps = {
    title,
    active,
    selected,
    iconClass,
    onClear: clearFilter,
    onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.stopPropagation();
      onSelectHandler(id);
    },
  };

  const popoverProps: IPopoverProps = {
    active,
    containerClass,
    useDynamicPosition,
    positionAlgoType,
    ignoreClickOnElements,
    className: FILTER_POPOVER_CLASS,
    width: popoverWidth,
    onClickOutside: () => {
      closeSelect();
      onClickOutside?.();
    },
  };

  const renderChip = () =>
    renderSpliceActionChip ? (
      <ActionChip
        label={title}
        active={selected}
        onDelete={clearFilter}
        onClick={(_, event) => {
          event.stopPropagation();
          onSelectHandler(id);
        }}
      />
    ) : (
      <Chip.Action {...chipProps} />
    );

  const _tooltipText = tooltipText ? tooltipText : title;

  return (
    <div className={styles.container}>
      {_tooltipText.length > TITLE_LENGTH_LIMIT ? (
        <Tooltip position={TooltipPositionEnum.TOP} alignArrow={TooltipArrowAlignmentEnum.CENTER} text={_tooltipText}>
          {renderChip()}
        </Tooltip>
      ) : (
        renderChip()
      )}
      <Popover {...popoverProps}>{active ? children : null}</Popover>
    </div>
  );
};
