export const ORG_VERIFICATION = "/org-verification";

const INTRODUCTORY_ROUTES = {
  WELCOME: `${ORG_VERIFICATION}/welcome`,
  WELCOME_BACK: `${ORG_VERIFICATION}/welcome-back`,
  ADDITIONAL_DOCUMENTS_REQUIRED: `${ORG_VERIFICATION}/update-documents`,
} as const;

const FORM_ROUTES = {
  COMPANY_DOCUMENTS: `${ORG_VERIFICATION}/company-documents`,
  COMPANY_INFORMATION: `${ORG_VERIFICATION}/company-information`,
  PERSONNEL_INFORMATION: `${ORG_VERIFICATION}/personnel-information`,
} as const;

// will be replaced with /myinfobiz/callback instead of /callback after testing
export const MY_INFO_ROUTES = {
  MY_INFO_BIZ: "/myinfobiz/callback",
  JOINT_APPLICANT: "/joint-applicant",
  THANKYOU_PAGE: "/joint-applicant/thank-you",
  SESSION_EXPIRED: "/joint-applicant/session-expired",
  INVALID_TOKEN: "/joint-applicant/invalid-token",
  JOINT_APPLICANT_FORM: "/joint-applicant/personnel-information",
  JOINT_APPLICANT_CALLBACK: "/myinfo/callback",
} as const;

const IN_PROCESS_ROUTES = {
  SUBMITTED: `${ORG_VERIFICATION}/submitted`,
  IN_REVIEW: `${ORG_VERIFICATION}/in-review`,
} as const;

const PROCESSED_ROUTES = {
  REJECTED: `${ORG_VERIFICATION}/rejected`,
  APPROVED: `${ORG_VERIFICATION}/approved`,
} as const;

export const COMPANY_VERIFICATION = {
  ...INTRODUCTORY_ROUTES,
  ...FORM_ROUTES,
  ...IN_PROCESS_ROUTES,
  ...PROCESSED_ROUTES,
  ...MY_INFO_ROUTES,
} as const;
