import React from "react";
import { IRouterType } from "Route/@types";
import { TEAM_PATHS } from "Route/Team/paths";

// Auth Component
import TeamProfile from "Views/Team/Profile/index";
import CreateTeam from "Views/Team/Create/index";
import Team from "Views/Team";
import TeamToasterProvider from "Views/Team/Toast/provider";
import TeamActionContext from "Views/Team/List/Actions/context";
import TeamActionProvider from "Views/Team/List/Actions/provider";
import { TEAM_ACTION } from "Views/Team/List/Actions/constant";
import DeleteTeam from "Views/Team/Delete";
import Rename from "Views/Team/Rename";
import TeamToaster from "Views/Team/Toast";
import DeleteTeamProvider from "Views/Team/Delete/provider";

export const TeamRoute: IRouterType[] = [
  {
    id: "team-1",
    path: TEAM_PATHS.TEAMS,
    component: <Team />,
    title: "Team Home",
    authRequired: true,
    exactPath: true,
  },
  {
    id: "team-2",
    path: TEAM_PATHS.TEAM_PROFILE_WITH_ID,
    component: (
      <TeamToasterProvider>
        <TeamActionProvider>
          {" "}
          <DeleteTeamProvider>
            <TeamProfile />
            <TeamActionContext.Consumer>
              {({ state: { selectedAction } }) => {
                if (selectedAction === TEAM_ACTION.DELETE) {
                  return <DeleteTeam />;
                }
                if (selectedAction === TEAM_ACTION.RENAME) {
                  return <Rename />;
                }
              }}
            </TeamActionContext.Consumer>
          </DeleteTeamProvider>
          <TeamToaster />
        </TeamActionProvider>
      </TeamToasterProvider>
    ),
    title: "Team profile",
    authRequired: true,
    exactPath: true,
  },
  {
    id: "team-3",
    path: TEAM_PATHS.CREATE_TEAM,
    component: <CreateTeam />,
    title: "Create team",
    authRequired: true,
    exactPath: true,
  },
];
