import axios from "axios";
import { axiosHeader } from "./index";
import { parseToURLEncodedForm } from "../../../src/utility/APIWrapper";
import { GetOrgId } from "utility";

export const FETCH_CARD_ACTIVATE_BULK_REQUEST = "FETCH_CARD_ACTIVATE_BULK_REQUEST";
export const FETCH_CARD_ACTIVATE_BULK_SUCCESS = "FETCH_CARD_ACTIVATE_BULK_SUCCESS";
export const FETCH_CARD_ACTIVATE_BULK_FAILURE = "FETCH_CARD_ACTIVATE_BULK_FAILURE";

export const fetchDataReq = (data) => {
  return {
    type: FETCH_CARD_ACTIVATE_BULK_REQUEST,
    data: data,
  };
};
export const fetchDataSuccess = (data) => {
  return {
    type: FETCH_CARD_ACTIVATE_BULK_SUCCESS,
    data: data,
  };
};
export const fetchDataError = (data) => {
  return {
    type: FETCH_CARD_ACTIVATE_BULK_FAILURE,
    data: data,
  };
};

export const cardActivateBulkFunc = (selected, status, adminManagerOnly) => {
  const orgId = GetOrgId();
  const url = adminManagerOnly
    ? "/api/v1/org/card/status/bulk-update?adminManagerOnly=true"
    : "/api/v1/org/card/status/bulk-update";
  return (dispatch) => {
    dispatch(fetchDataReq());

    /*eslint no-process-env: "off"*/
    axios
      .put(
        window.__ENV__.API_BASE_URL + url,
        parseToURLEncodedForm({
          status,
          organisation_id: orgId,
          card_ids: JSON.stringify(selected),
        }),
        {
          headers: {
            ...axiosHeader.headers,
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((data) => {
        dispatch(fetchDataSuccess({ ...data.data, change_to: status }));
      })
      .catch((err) => {
        dispatch(fetchDataError({ ...err, change_to: status }));
      });
  };
};
