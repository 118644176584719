import { getPermissionStatus } from "Redux/DataCalls/casbinPermission.api.js";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";

import { IActionArr, IResponse } from "./types.d";

const formatter = (res: IResponse[]) => {
  if (res.length > 1) {
    return res.map((item) => item?.is_authorized || false);
  }
  return res[0]?.is_authorized || false;
};

export const fetchPermissionForClassComp = async (actionArr: IActionArr[]) => {
  try {
    const response = await getPermissionStatus(actionArr);

    if (response.status === HTTP_STATUS_CODE.OK) {
      return formatter(response?.data?.data as IResponse[]);
    } else {
      console.error(`Error ${response.status} ${response.statusText}`);
      return [];
    }
  } catch (error) {
    console.error(`Error ${error}`);
    return [];
  }
};
