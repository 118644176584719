import React from "react";
import cn from "classnames";
import { Typography, Tags, ArrowLeft } from "@spenmo/splice";
import { Button } from "antd";
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";

import { formatDate, timeFromNow } from "utility/DateUtilites";
import { ContractRenewal } from "Views/SubscriptionManagement/Details/Modal/ContractRenewal";
import { useSubscriptionContext } from "Views/SubscriptionManagement/Details/SubscriptionContext";
import { SUBSCRIPTION_STATUS, CONTRACT_TYPE } from "Views/SubscriptionManagement/Constants";
import usePermissionCheck from "Permission/usePermissionCheck";
import { RENEW_SUBSCRIPTION_PARAMS } from "Views/SubscriptionManagement/Permission";

import styles from "./index.module.scss";

dayjs.extend(isSameOrBefore);
const withinReviewExpiryDate = (expiredDate) => dayjs(expiredDate).isSameOrBefore(new Date(), "day");

export const ExpiryBanner = () => {
  const { subscription } = useSubscriptionContext();
  const IS_ALLOWED_TO_RENEW_SUBSCRIPTION = (usePermissionCheck(RENEW_SUBSCRIPTION_PARAMS) as boolean) || false;
  const nearOfExpiryDate = subscription.review_expiry_date
    ? withinReviewExpiryDate(subscription.review_expiry_date)
    : false;
  const isExpired = subscription.status === SUBSCRIPTION_STATUS.EXPIRED;

  const isFixedContract = subscription.contract_type === CONTRACT_TYPE.FIXED;

  const showRenewBtn = IS_ALLOWED_TO_RENEW_SUBSCRIPTION && isFixedContract && nearOfExpiryDate;

  const findExpiryOrRenewalDate = () => {
    let date = subscription.next_renewal_date;
    if (subscription.contract_type === CONTRACT_TYPE.FIXED) {
      date = subscription.end_date;
    }

    return formatDate(date || new Date(), "DD MMM YYYY");
  };

  return (
    <div className={styles.container}>
      <div className={styles.expiryBanner}>
        <div className={styles.detailsContainer}>
          <div className={styles.flexItemStart}>
            <Typography variant="body-content" size="caption-m" className={styles.textWhite}>
              CONTRACT {subscription.contract_type === CONTRACT_TYPE.FIXED ? "EXPIRY" : "RENEWAL"}
            </Typography>
            <Typography variant="body-content" size="s" weight={600} className={styles.textWhite}>
              {findExpiryOrRenewalDate()}
            </Typography>
          </div>
          <div className={styles.flexItemEnd}>
            <Tags.Progression
              label={isExpired ? "EXPIRED" : timeFromNow(findExpiryOrRenewalDate() || new Date()) + " left"}
              hierarchy="default"
              variant="neutral"
              className={cn({ [styles.tagRed]: nearOfExpiryDate })}
            />

            <Typography variant="body-content" size="caption-m" className={styles.textGrey}>
              {isFixedContract ? "Not on auto-renewal" : "Contract auto-renews"}
            </Typography>
          </div>
        </div>
        {showRenewBtn && (
          <ContractRenewal
            trigger={({ openModal }) => (
              <Button className={styles.renewBtn} onClick={openModal}>
                <Typography variant="body-content" size="caption-m" weight={600}>
                  Renew contract
                </Typography>
                <ArrowLeft size="16" iconColor="var(--text-body-default-inverse)" />
              </Button>
            )}
          />
        )}
      </div>
    </div>
  );
};
