import APIclient from "API/Client";
import { GetOrgId } from "../../utility";
import { GetFormData } from "../../utility/APIWrapper";

export const FETCH_SYNC_REQUEST = "FETCH_SYNC_REQUEST";
export const FETCH_SYNC_SUCCESS = "FETCH_SYNC_SUCCESS";
export const FETCH_SYNC_FAILURE = "FETCH_SYNC_FAILURE";

export const fetchDataReq = (data = []) => {
  return {
    type: FETCH_SYNC_REQUEST,
    data: data,
  };
};
export const fetchDataSuccess = (data) => {
  return {
    type: FETCH_SYNC_SUCCESS,
    data: data,
  };
};
export const fetchDataError = (data) => {
  return {
    type: FETCH_SYNC_FAILURE,
    data: data,
  };
};

export const syncXeroFunc = (values) => {
  const orgId = GetOrgId();

  return (dispatch) => {
    dispatch(fetchDataReq());
    APIclient.postData(
      "/api/v1/partner/xero/transactions",
      GetFormData({
        organisation_id: orgId,
        ...values,
      })
    )
      .then((data) => {
        if (data.status && data.data.status === 200) {
          dispatch(fetchDataSuccess(data.data));
        } else {
          dispatch(fetchDataError(data.data));
        }
      })
      .catch((err) => {
        dispatch(fetchDataError(err));
      });
  };
};
