import {
  FETCH_USER_WALLET_BALANCES_LIST_REQUEST,
  FETCH_USER_WALLET_BALANCES_LIST_SUCCESS,
  FETCH_USER_WALLET_BALANCES_LIST_FAILURE,
  RESET_USER_WALLET_BALANCES_LIST,
} from "Redux/Actions/user/WalletBalancesList";
import { userInitialState } from "Redux/InitialState";

export const userWalletBalancesListReducer = (state = userInitialState, action = {}) => {
  switch (action?.type) {
    case FETCH_USER_WALLET_BALANCES_LIST_REQUEST:
      return {
        ...state,
        data: null,
        loading: true,
      };
    case FETCH_USER_WALLET_BALANCES_LIST_SUCCESS:
      return {
        ...state,
        data: action?.data,
        loading: false,
      };
    case FETCH_USER_WALLET_BALANCES_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        data: action?.data,
        error: true,
      };
    case RESET_USER_WALLET_BALANCES_LIST:
      return {
        ...state,
        ...userInitialState,
      };
    default:
      return state;
  }
};

export const fetchUserWalletBalancesListData = (state) => state?.userWalletBalancesListReducer || {};
