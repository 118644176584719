import React, { useContext } from "react";
import LineChart from "Splice/Charts/LineChart/";
import { SpendingTrendShimmer } from "./Shimmer";
import { EmptyState } from "Views/Analytics/EmptyState";
import "./Shimmer.scss";
import { IRootDataType } from "../@types";
import AnalyticsContext from "../Context/context";

interface SpendingTrendProps {
  spendingTrendLoading: boolean;
  spendingTrendData: any[];
  handleApplyDeeplink: (rootData: IRootDataType) => void;
}

export const SpendingTrend = ({ spendingTrendLoading, spendingTrendData, handleApplyDeeplink }: SpendingTrendProps) => {
  const { filter } = useContext(AnalyticsContext);

  if (spendingTrendLoading) {
    return <SpendingTrendShimmer loading={spendingTrendLoading} />;
  }

  if (!spendingTrendLoading && Array.isArray(spendingTrendData) && !spendingTrendData.length) {
    return <EmptyState />;
  }

  const isLessThanMonth: boolean =
    (filter.differences.months === 1 && filter.otherCustomFilter.isCustom === false) ||
    (filter.otherCustomFilter.isCustom === true && filter.otherCustomFilter.type === "months");

  const getNoOfTicks = () => {
    if (isLessThanMonth) return 15;
    if (filter.differences.months === 3) return 4;
    if (filter.differences.months === 12) return 13;
    if (filter.otherCustomFilter.isCustom === true && filter.otherCustomFilter.type === "years") return 13;
    return spendingTrendData.length;
  };

  let props = {
    data: spendingTrendData,
    handleApplyDeeplink,
    hideDate: isLessThanMonth === false,
    noOfTicks: getNoOfTicks(),
  };

  return <LineChart {...props} />;
};
