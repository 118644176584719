import { SaaSConfig } from "Views/Bills/V2/BillForm/type";

export const BILL_PAY_PROCESSING_TIME_ARTICLE_URL =
  "https://support.spenmo.com/hc/en-us/articles/4412728657433-Bill-Pay-Processing-Time";
export const NO_SIMULATION_CURRENCIES = ["MYR"];
export const CENTRALISED_APPROVAL_BILL_URL = "/approvalRequestCenter?activeTab=invoice";
export const APPROVAL_BILL_URL = "/transactions?tab=pending&subtab=to_approve";
export const BILL_PENDING_TASK = "bill_pending_approval";
export const IMPORT_BILL_FROM_XERO = "/bills/xero";

export const DEFAULT_SEND_AMOUNT = {
  SGD: 100,
  USD: 100,
  MYR: 100,
  IDR: 1000000,
  PHP: 1000,
};

export const DYNAMIC_FIELD_ID_MAPPING = {
  swiftCode: 1,
  recipientAddress: 5,
  recipientCity: 6,
  routingNumber: 10,
  IFSC: 11,
  IBAN: 12,
  BSB: 16,
  sortCode: 17,
};

export const ARYADANA_COUNTRY = "ID";
export const TEAMS_PAGE_URL = "/teams";
export const TEAM_LIMIT_EXCEEDED = {
  YES: "yes",
  NO: "no",
  PARTIAL: "partial",
};

export const TABS = {
  CREATE_BILLS: {
    tabKey: "createBills",
    title: "Create Bills",
    pathname: "/bills",
    eventSource: "Create Bill",
    link: "/bills",
  },
  SUBMITTED_BILLS: {
    tabKey: "submittedBills",
    title: "Submitted Bills",
    eventSource: "Submitted Bill",
    pathname: "/bills/submitted/:form?/:id?/:operation?",
    link: "/bills/submitted",
  },
  MANAGE_DRAFT: {
    tabKey: "manageDraft",
    title: "Manage Drafts",
    eventSource: "Manage Draft",
    pathname: "/bills/drafts/:form?/:id?",
    link: "/bills/drafts",
  },
  MANAGE_DRAFT_BULK_EDIT: {
    tabKey: "manageDraft",
    title: "Manage Drafts",
    eventSource: "Manage Draft",
    pathname: "/bills/drafts/:form?/:operation/:id?",
    link: "/bills/drafts",
  },
  MANAGE_RECIPIENTS: {
    tabKey: "manageRecipient",
    title: "Manage Recipients",
    eventSource: "Manage Recipient",
    pathname: "/bills/recipients/:form?/:vendorId?",
    link: "/bills/recipients",
  },
};

export const LIMIT = 10;
export const LS_IS_UPLOAD_NEW = "x-upload-new";
export const UPLOAD_ACCEPT_TYPE = [
  "image/png",
  "image/jpg",
  "image/jpeg",
  "application/pdf",
  "image/heic",
  "image/heif",
];
export const UPLOAD_MAX_SIZE = 10 * 1000 * 1000; // X * 1MB

const SORT_BY_ENUM = {
  CREATION_DATE: "creationDate",
  DUE_DATE: "dueDate",
  SCHEDULED_DATE: "scheduledDate",
  RECIPIENT_NAME: "recipientName",
  RECIPIENT_GETS: "amount",
};

export const MANAGE_RECIPIENTS_SORT_BY = [
  {
    label: "Created Date (Newest to Oldest)",
    value: "created_at,desc",
  },
  {
    label: "Created Date (Oldest to Newest)",
    value: "created_at,asc",
  },
  {
    label: "Recipient (A-Z)",
    value: "legal_name,asc",
  },
  {
    label: "Recipient (Z-A)",
    value: "legal_name,desc",
  },
];
export interface SortFilterData {
  id: string;
  title: string;
}

export function getDraftSortBy(saasConfig: SaaSConfig) {
  const sortFilters = [];
  if (saasConfig?.filterSorts && saasConfig?.filterSorts.length > 0) {
    return saasConfig?.filterSorts?.map((sort) => {
      return { id: `${sort.columnID},${sort.orderID}`, title: `${sort.columnLabel} (${sort.orderLabel})` };
    });
  }
  return sortFilters;
}

export const chipStatus = {
  payout_issue: { variant: "info", label: "Sending Payment" },
  received: { variant: "success", label: "Paid" },
  waiting_approval: { variant: "attention", label: "Waiting for Approval" },
  approved: { variant: "info", label: "Approved" },
  draft: { variant: "neutral", label: "Extracting" },
  cancelled: { variant: "failed", label: "Cancelled" },
  completed: { variant: "info", label: "Sending Payment" },
  reversed: { variant: "failed", label: "Reversed" },
  pending: { variant: "info", label: "Bill Processed" },
};
