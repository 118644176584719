import React, { useContext, useEffect, useState } from "react";
import { FormInstance } from "antd/lib/form";

import {
  FORM_NAME,
  KYX_FORMS_KEYS,
  PERSON_DOCUMENT_TYPE,
  ROLE_OF_INDIVIDUAL_OPTIONS,
  ROLE_OF_INDIVIDUAL_OPTIONS_VALUE,
  PERSONNEL_INFORMATION_FORM_ITEM_KEYS,
  VERIFICATION_METHOD_OPTIONS_LABEL,
  VERIFICATION_METHOD_OPTIONS,
  VERIFICATION_METHOD_OPTIONS_VALUE,
} from "Views/KYX/Constants/Forms";
import {
  IPersonForm,
  IFormElement,
  IDocumentStep,
  IGetFormConfigsPayload,
  IFetchFormSubmissionPayload,
  IMultipleHeaderAndStepInformation,
  IManualFormContext,
} from "Views/KYX/@types";
import { useFormSaveAsDraft } from "Views/KYX/hooks";
import { ManualFormContext } from "Views/KYX/Context";
import { getValidationError, getValidationstatus } from "Views/KYX/Helper";
import { FORM_FIELD_TYPES, DOCUMENT_STEP_TYPE, SECTION_CONTAINER_CLASS, GUIDELINES_TYPE } from "Views/KYX/Constants";

import { useVerifyManuallyWorkpass } from "./MyInfo/Workpass";
import { useVerifyManuallyNRIC } from "./MyInfo/NRIC";

import styles from "./index.module.scss";

export const usePersonnelInfoFormPersonDataSGD = (
  activeKey: string,
  remarks: IGetFormConfigsPayload,
  isFormDisabled: boolean
): IMultipleHeaderAndStepInformation => {
  const ROLE_OF_INDIVIDUAL: IFormElement = {
    key: FORM_NAME.PERSONNEL_INFORMATION + KYX_FORMS_KEYS.PERSONNEL_DATA_PERSONS + KYX_FORMS_KEYS.PERSON_DATA_ROLES,
    col: { span: 12 },
    element: {
      type: FORM_FIELD_TYPES.CHECKLIST,
      props: {
        formProps: {
          name: KYX_FORMS_KEYS.PERSON_DATA_ROLES,
          label: "Role of Individual",
          required: true,
          rules: [
            {
              required: true,
              message: "Please choose at least one role",
            },
          ],
          validateStatus: getValidationstatus(remarks, [
            KYX_FORMS_KEYS.PERSONNEL_DATA_PERSONS,
            activeKey,
            KYX_FORMS_KEYS.PERSON_DATA_ROLES,
          ]),
          help: getValidationError(
            remarks,
            [KYX_FORMS_KEYS.PERSONNEL_DATA_PERSONS, activeKey, KYX_FORMS_KEYS.PERSON_DATA_ROLES],
            "Role of Individual",
            FORM_NAME.PERSONNEL_INFORMATION
          ),
        },
        checkProps: {
          name: KYX_FORMS_KEYS.PERSON_DATA_ROLES,
          options: [
            {
              label: ROLE_OF_INDIVIDUAL_OPTIONS_VALUE[ROLE_OF_INDIVIDUAL_OPTIONS.AUTHORIZED_PERSON],
              value: KYX_FORMS_KEYS.PERSON_ROLES_AUTHORIZED,
            },
            {
              label: ROLE_OF_INDIVIDUAL_OPTIONS_VALUE[ROLE_OF_INDIVIDUAL_OPTIONS.DIRECTOR],
              value: KYX_FORMS_KEYS.PERSON_ROLES_DIRECTOR,
              disabled: true,
            },
            {
              label: ROLE_OF_INDIVIDUAL_OPTIONS_VALUE[ROLE_OF_INDIVIDUAL_OPTIONS.SHAREHOLDER],
              value: KYX_FORMS_KEYS.PERSON_ROLES_SHAREHOLDER,
              disabled: true,
            },
          ],
          disabled: isFormDisabled,
        },
      },
    },
  };

  const FULL_NAME: IFormElement = {
    key: FORM_NAME.PERSONNEL_INFORMATION + KYX_FORMS_KEYS.PERSONNEL_DATA_PERSONS + KYX_FORMS_KEYS.PERSON_DATA_FULL_NAME,
    col: { span: 24 },
    element: {
      type: FORM_FIELD_TYPES.INPUT,
      props: {
        formProps: {
          name: KYX_FORMS_KEYS.PERSON_DATA_FULL_NAME,
          label: (
            <>
              Full Name <span className={styles.fullName}>(As shown on ID)</span>
            </>
          ),
        },
        inputProps: {
          name: KYX_FORMS_KEYS.PERSON_DATA_FULL_NAME,
          placeholder: "Please enter your full name",
          disabled: true,
        },
      },
    },
  };
  const OWNERSHIP_CATEGORY: IFormElement = {
    key:
      FORM_NAME.PERSONNEL_INFORMATION +
      KYX_FORMS_KEYS.PERSONNEL_DATA_PERSONS +
      KYX_FORMS_KEYS.PERSON_DATA_OWNERSHIP_CATEGORY,
    col: { span: 24 },
    element: {
      type: FORM_FIELD_TYPES.INPUT,
      props: {
        formProps: {
          name: KYX_FORMS_KEYS.PERSON_DATA_OWNERSHIP_CATEGORY,
          label: <>Ownership Category </>,
        },
        inputProps: {
          name: KYX_FORMS_KEYS.PERSON_DATA_OWNERSHIP_CATEGORY,
          disabled: true,
        },
      },
    },
  };
  const SHARE_ALLOCATION: IFormElement = {
    key:
      FORM_NAME.PERSONNEL_INFORMATION +
      KYX_FORMS_KEYS.PERSONNEL_DATA_PERSONS +
      KYX_FORMS_KEYS.PERSON_DATA_SHARE_ALLOCATION,
    col: { span: 12 },
    element: {
      type: FORM_FIELD_TYPES.INPUT,
      props: {
        formProps: {
          name: KYX_FORMS_KEYS.PERSON_DATA_SHARE_ALLOCATION,
          label: <>Share Allocation </>,
        },
        inputProps: {
          name: KYX_FORMS_KEYS.PERSON_DATA_SHARE_ALLOCATION,
          disabled: true,
        },
      },
    },
  };
  const SHARE_TYPE: IFormElement = {
    key:
      FORM_NAME.PERSONNEL_INFORMATION + KYX_FORMS_KEYS.PERSONNEL_DATA_PERSONS + KYX_FORMS_KEYS.PERSON_DATA_SHARE_TYPE,
    col: { span: 12 },
    element: {
      type: FORM_FIELD_TYPES.INPUT,
      props: {
        formProps: {
          name: KYX_FORMS_KEYS.PERSON_DATA_SHARE_TYPE,
          label: <>Share Type </>,
        },
        inputProps: {
          name: KYX_FORMS_KEYS.PERSON_DATA_SHARE_TYPE,
          disabled: true,
        },
      },
    },
  };
  const POSITION: IFormElement = {
    key: FORM_NAME.PERSONNEL_INFORMATION + KYX_FORMS_KEYS.PERSONNEL_DATA_PERSONS + KYX_FORMS_KEYS.PERSON_DATA_POSITION,
    col: { span: 24 },
    element: {
      type: FORM_FIELD_TYPES.INPUT,
      props: {
        formProps: {
          name: KYX_FORMS_KEYS.PERSON_DATA_POSITION,
          label: <>Position</>,
        },
        inputProps: {
          name: KYX_FORMS_KEYS.PERSON_DATA_POSITION,
          disabled: true,
        },
      },
    },
  };
  const EMAIL: IFormElement = {
    key: FORM_NAME.PERSONNEL_INFORMATION + KYX_FORMS_KEYS.PERSONNEL_DATA_PERSONS + KYX_FORMS_KEYS.PERSON_DATA_EMAIL,
    col: { span: 12 },
    element: {
      type: FORM_FIELD_TYPES.INPUT,
      props: {
        formProps: {
          name: KYX_FORMS_KEYS.PERSON_DATA_EMAIL,
          label: <>Email Address</>,
        },
        inputProps: {
          name: KYX_FORMS_KEYS.PERSON_DATA_EMAIL,
          disabled: false,
        },
      },
    },
  };
  const MOBILE: IFormElement = {
    key: FORM_NAME.PERSONNEL_INFORMATION + KYX_FORMS_KEYS.PERSONNEL_DATA_PERSONS + KYX_FORMS_KEYS.PERSON_DATA_MOBILE,
    col: { span: 12 },
    element: {
      type: FORM_FIELD_TYPES.INPUT,
      props: {
        formProps: {
          name: KYX_FORMS_KEYS.PERSON_DATA_MOBILE,
          label: <>Mobile Number</>,
        },
        inputProps: {
          name: KYX_FORMS_KEYS.PERSON_DATA_MOBILE,
          disabled: false,
        },
      },
    },
  };

  const NATIONALITY: IFormElement = {
    key:
      FORM_NAME.PERSONNEL_INFORMATION + KYX_FORMS_KEYS.PERSONNEL_DATA_PERSONS + KYX_FORMS_KEYS.PERSON_DATA_NATIONALITY,
    col: { span: 24 },
    element: {
      type: FORM_FIELD_TYPES.COUNTRYSELECT,
      props: {
        formProps: {
          name: KYX_FORMS_KEYS.PERSON_DATA_NATIONALITY,
          label: "Nationality/Citizenship",
          required: false,
        },
        selectProps: {
          placeholder: "Select nationality",
          isnationality: true,
          disabled: true,
        },
      },
    },
  };

  /* Personnel Information */
  const PERSONNEL_INFORMATION: IMultipleHeaderAndStepInformation = {
    key: FORM_NAME.PERSONNEL_INFORMATION + KYX_FORMS_KEYS.PERSONNEL_DATA_PERSONS + "1",
    row: { gutter: 40 },
    header: { number: 2.1, title: "Personnel Information" },
    document: [
      ROLE_OF_INDIVIDUAL,
      FULL_NAME,
      OWNERSHIP_CATEGORY,
      SHARE_TYPE,
      SHARE_ALLOCATION,
      POSITION,
      EMAIL,
      MOBILE,
      NATIONALITY,
    ],
  };

  return PERSONNEL_INFORMATION;
};

export const usePersonnelIdFormPersonDataSGD = (
  form: FormInstance,
  formattedFormData: IFetchFormSubmissionPayload,
  remarks: IGetFormConfigsPayload,
  currentKey: string,
  isFormDisabled: boolean
): IMultipleHeaderAndStepInformation => {
  const [onlyDocNumber, setOnlyDocNumber] = useState<boolean>(false);
  const [documentSelectionType, setDocumentSelectionType] = useState<PERSON_DOCUMENT_TYPE>();
  const [verificationMethod, setVerificationMethod] = useState<VERIFICATION_METHOD_OPTIONS>(undefined);

  const activePerson = formattedFormData?.form?.personnel_information?.persons[currentKey];
  const isVerifiedWithSingpass =
    activePerson?.verification_method === VERIFICATION_METHOD_OPTIONS.SINGPASS && activePerson?.status === "verified";

  const { isFormModified, formData, setFormData }: IManualFormContext = useContext(ManualFormContext);
  const { saveFormAsDraft } = useFormSaveAsDraft(setFormData);

  useEffect(() => {
    if (!formData?.id) {
      setFormData({ ...formattedFormData, [KYX_FORMS_KEYS.FORM_ID]: formattedFormData?.form_id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formattedFormData]);

  useEffect(() => {
    if (formData) {
      const activePersonRole = form.getFieldValue([
        KYX_FORMS_KEYS.PERSONNEL_DATA_PERSONS,
        currentKey,
        KYX_FORMS_KEYS.PERSON_DATA_ROLES,
      ]);

      const isMajorShareholder = activePerson?.roles_of_individual?.is_major_share_holder || false;
      const isOnlyDirector = activePersonRole?.includes("is_director") && activePersonRole?.length === 1;
      const minorShareholder =
        !activePersonRole?.includes("is_major_share_holder") && activePersonRole?.includes("is_share_holder");

      setOnlyDocNumber(() => {
        if (isOnlyDirector || (minorShareholder && !isMajorShareholder)) {
          return true;
        } else {
          return false;
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData, isFormModified]);

  useEffect(() => {
    const documentType = form.getFieldValue([
      KYX_FORMS_KEYS.PERSONNEL_DATA_PERSONS,
      currentKey,
      KYX_FORMS_KEYS.PERSON_DATA_ID_DOCUMENTS,
      KYX_FORMS_KEYS.PERSON_DATA_DOCUMENT_TYPE,
    ]);
    const verificationMethodFormValue = form.getFieldValue([
      KYX_FORMS_KEYS.PERSONNEL_DATA_PERSONS,
      currentKey,
      KYX_FORMS_KEYS.PERSON_DATA_VERIFICATION_METHOD,
    ]);
    setVerificationMethod(verificationMethodFormValue);

    if (formData) {
      setDocumentSelectionType(
        documentType ||
          (formData?.form?.personnel_information?.persons
            ? formData.form.personnel_information?.persons[currentKey]?.identification_document.document_type
            : "")
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentKey, formData]);
  const VERIFICATION_METHOD: IFormElement = {
    key:
      FORM_NAME.PERSONNEL_INFORMATION +
      KYX_FORMS_KEYS.PERSONNEL_DATA_PERSONS +
      KYX_FORMS_KEYS.PERSON_DATA_VERIFICATION_METHOD,
    col: { span: 12 },
    element: {
      type: FORM_FIELD_TYPES.RADIO,
      props: {
        formProps: {
          name: KYX_FORMS_KEYS.PERSON_DATA_VERIFICATION_METHOD,
          label: "Verification Method",
          required: true,
          rules: [
            {
              required: true,
              message: "Please input Verification Method",
            },
          ],
        },
        radioProps: {
          name: KYX_FORMS_KEYS.PERSON_DATA_VERIFICATION_METHOD,
          className: styles.VERIFICATION_METHOD,
          options: [
            {
              label: VERIFICATION_METHOD_OPTIONS_LABEL[VERIFICATION_METHOD_OPTIONS.MANUAL],
              value: VERIFICATION_METHOD_OPTIONS_VALUE[VERIFICATION_METHOD_OPTIONS.MANUAL],
            },
            {
              label: VERIFICATION_METHOD_OPTIONS_LABEL[VERIFICATION_METHOD_OPTIONS.SINGPASS],
              value: VERIFICATION_METHOD_OPTIONS_VALUE[VERIFICATION_METHOD_OPTIONS.SINGPASS],
            },
          ],
          disabled: isFormDisabled,
          onChange: (e) => {
            setVerificationMethod(e.target.value);
          },
        },
      },
    },
  };

  const VERIFY_SINGPASS: IFormElement = {
    key: FORM_NAME.PERSONNEL_INFORMATION,
    col: { span: 24 },
    element: {
      type: FORM_FIELD_TYPES.SINGPASS,
      props: {
        shareholderName: formData?.form?.personnel_information?.persons?.[currentKey]?.full_name,
        personnelId: formData?.form?.personnel_information?.persons?.[currentKey]?.id,
      },
    },
  };

  const nric = useVerifyManuallyNRIC(
    form,
    formattedFormData,
    remarks,
    currentKey,
    isFormDisabled,
    isVerifiedWithSingpass,
    onlyDocNumber
  );
  const workpass = useVerifyManuallyWorkpass(
    form,
    formattedFormData,
    remarks,
    currentKey,
    isFormDisabled,
    isVerifiedWithSingpass,
    onlyDocNumber
  );

  const getDocumentTypeKeyName = (): string => {
    let value: string;
    switch (documentSelectionType) {
      case PERSON_DOCUMENT_TYPE.WORKPASS:
        value = KYX_FORMS_KEYS.PERSON_DATA_WORK_PASS_DOCUMENT_TYPE;
        break;
      case PERSON_DOCUMENT_TYPE.PASSPORT:
        value = KYX_FORMS_KEYS.PERSON_DATA_PASSPORT_DOCUMENT_TYPE;
        break;
      case PERSON_DOCUMENT_TYPE.NRIC:
        value = KYX_FORMS_KEYS.PERSON_DATA_NRIC_DOCUMENT_TYPE;
        break;
      default:
        break;
    }

    return value;
  };

  const [isResidentialAddressSame, setIsResidentialAddressSame] = useState<boolean>(true);

  useEffect(() => {
    const isAddressSameCheckbox = form.getFieldValue([
      KYX_FORMS_KEYS.PERSONNEL_DATA_PERSONS,
      currentKey,
      KYX_FORMS_KEYS.PERSON_DATA_ID_DOCUMENTS,
      KYX_FORMS_KEYS.PERSON_IS_RESIDENCE_ADDRESS_SAME,
    ]);

    const personsData = formData?.form?.personnel_information?.persons?.length
      ? formData?.form?.personnel_information?.persons?.[currentKey]?.identification_document
          ?.is_same_residential_address
      : false;

    const addressData = isAddressSameCheckbox && isAddressSameCheckbox[0] === "checked" ? true : personsData;

    setIsResidentialAddressSame(addressData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formattedFormData]);

  const PROOF_OF_RESIDENCE: IFormElement = {
    key:
      FORM_NAME.PERSONNEL_INFORMATION +
      KYX_FORMS_KEYS.PERSONNEL_DATA_PERSONS +
      KYX_FORMS_KEYS.PERSON_IS_RESIDENCE_ADDRESS_SAME,
    col: { span: 24 },
    element: {
      type: FORM_FIELD_TYPES.CHECKLIST,
      props: {
        formProps: {
          name: [KYX_FORMS_KEYS.PERSON_DATA_ID_DOCUMENTS, KYX_FORMS_KEYS.PERSON_IS_RESIDENCE_ADDRESS_SAME],
          required: true,
          validateStatus: getValidationstatus(remarks, [
            KYX_FORMS_KEYS.PERSONNEL_DATA_PERSONS,
            currentKey,
            KYX_FORMS_KEYS.PERSON_IS_RESIDENCE_ADDRESS_SAME,
          ]),
          help: getValidationError(
            remarks,
            [KYX_FORMS_KEYS.PERSONNEL_DATA_PERSONS, currentKey, KYX_FORMS_KEYS.PERSON_IS_RESIDENCE_ADDRESS_SAME],
            "Identity Documents",
            FORM_NAME.PERSONNEL_INFORMATION
          ),
          className: "mb-0",
        },
        checkProps: {
          options: [
            {
              label: "Residential address is different from the address indicated on identity documents",
              value: "checked",
            },
          ],
          onChange: () => {
            setIsResidentialAddressSame((prev) => {
              if (prev) {
                form.setFieldsValue({
                  [KYX_FORMS_KEYS.PERSONNEL_DATA_PERSONS]: {
                    [currentKey]: {
                      [KYX_FORMS_KEYS.PERSON_DATA_ID_DOCUMENTS]: {
                        [KYX_FORMS_KEYS.PERSON_DATA_PROOF_OF_RESIDENCE_DOCUMENT]: "",
                      },
                    },
                  },
                });
              }
              return !prev;
            });
          },
          disabled: isFormDisabled,
        },
      },
    },
  };

  const PROOF_OF_RESIDENCE_HEADER_AND_GUIDELINES: IFormElement =
    documentSelectionType === PERSON_DOCUMENT_TYPE.NRIC && !isResidentialAddressSame
      ? null
      : {
          key: PERSONNEL_INFORMATION_FORM_ITEM_KEYS.PROOF_OF_RESIDENCE_HEADER_AND_GUIDELINES,
          col: { span: 24, style: { marginTop: "2rem" } },
          element: {
            type: FORM_FIELD_TYPES.HEADER,
            props: {
              title: "Proof of Address",
              guidelines: GUIDELINES_TYPE.PROOF_OF_ADDRESS,
            },
          },
        };

  const UPLOAD_PROOF_OF_RESIDENCE: IFormElement = {
    key:
      FORM_NAME.PERSONNEL_INFORMATION +
      KYX_FORMS_KEYS.PERSONNEL_DATA_PERSONS +
      KYX_FORMS_KEYS.PERSON_DATA_PROOF_OF_RESIDENCE_DOCUMENT,
    col: { span: 24 },
    element: {
      type: FORM_FIELD_TYPES.FILEUPLOAD,
      props: {
        formProps: {
          name: [
            KYX_FORMS_KEYS.PERSON_DATA_ID_DOCUMENTS,
            getDocumentTypeKeyName(),
            KYX_FORMS_KEYS.PERSON_DATA_PROOF_OF_RESIDENCE_DOCUMENT,
          ],
          label: "Upload Proof of Residence",
          required: documentSelectionType === PERSON_DOCUMENT_TYPE.NRIC && !isResidentialAddressSame ? false : true,
          dependencies: [KYX_FORMS_KEYS.PERSON_DATA_ID_DOCUMENTS, KYX_FORMS_KEYS.PERSON_IS_RESIDENCE_ADDRESS_SAME],
          rules: [
            {
              required: documentSelectionType === PERSON_DOCUMENT_TYPE.NRIC && !isResidentialAddressSame ? false : true,
              message: "Please upload a valid Proof of Residence",
            },
          ],
          // validateStatus: getValidationstatus(remarks, [
          //   KYX_FORMS_KEYS.PERSONNEL_DATA_PERSONS,
          //   currentKey,
          //   KYX_FORMS_KEYS.PERSON_DATA_PROOF_OF_RESIDENCE_DOCUMENT,
          // ]),
          // help: getValidationError(
          //   remarks,
          //   [KYX_FORMS_KEYS.PERSONNEL_DATA_PERSONS, currentKey, KYX_FORMS_KEYS.PERSON_DATA_PROOF_OF_RESIDENCE_DOCUMENT],
          //   "Proof of Residence",
          //   FORM_NAME.PERSONNEL_INFORMATION
          // ),
          style: {
            display:
              documentSelectionType === PERSON_DOCUMENT_TYPE.NRIC && !isResidentialAddressSame ? "none" : "block",
          },
          initialValue: formData?.form?.personnel_information?.persons
            ? formData?.form?.personnel_information?.persons[currentKey]?.identification_document[
                getDocumentTypeKeyName()
              ]?.proof_of_residence
            : "",
        },
        disabled: isFormDisabled,
        action: (arg) => {
          const personsObj = form.getFieldsValue()["persons"];
          const residenceDoc = {
            [KYX_FORMS_KEYS.PERSON_DATA_DOCUMENT_TYPE]: documentSelectionType,
            [getDocumentTypeKeyName()]: {
              ...personsObj[currentKey].identification_document[getDocumentTypeKeyName()],
              [KYX_FORMS_KEYS.PERSON_DATA_PROOF_OF_RESIDENCE_DOCUMENT]: arg,
            },
          };

          const newPersons: IPersonForm[] = Object.keys(personsObj).map((key, index) => {
            const data = {
              ...personsObj[key],
              roles_of_individual: personsObj[key]?.roles_of_individual
                ? Object.assign(
                    {},
                    ...personsObj[key].roles_of_individual.map((role) => {
                      return role ? { [role]: true } : null;
                    })
                  )
                : {},
            };

            if (index !== parseInt(currentKey)) {
              // This isn't the person we care about - keep it as-is
              return {
                ...data,
                [KYX_FORMS_KEYS.PERSON_DATA_ID_DOCUMENTS]: {
                  ...data?.identification_document,
                  is_same_residential_address: personsObj[key]?.identification_document?.is_same_residential_address
                    ?.length
                    ? personsObj[key]?.identification_document?.is_same_residential_address?.[0] === "checked"
                    : false,
                },
              };
            }

            // Otherwise, this is the one we want - return an updated value
            return {
              ...data,
              [KYX_FORMS_KEYS.PERSON_DATA_ID_DOCUMENTS]: {
                ...residenceDoc,
                is_same_residential_address: personsObj[key]?.identification_document?.is_same_residential_address
                  ?.length
                  ? personsObj[key]?.identification_document?.is_same_residential_address?.[0] === "checked"
                  : false,
                [getDocumentTypeKeyName()]: {
                  ...personsObj[key].identification_document[getDocumentTypeKeyName()],
                  proof_of_residence: arg,
                },
              },
            } as IPersonForm;
          });

          saveFormAsDraft({
            id: formattedFormData?.form_id,
            source: formattedFormData?.source,
            form: {
              ...formData.form,
              personnel_information: {
                ...formData.form.personnel_information,
                persons: newPersons,
              },
            },
          });

          return form.setFieldsValue({
            [KYX_FORMS_KEYS.PERSONNEL_DATA_PERSONS]: {
              [currentKey]: { [KYX_FORMS_KEYS.PERSON_DATA_ID_DOCUMENTS]: residenceDoc },
            },
          });
        },
      },
    },
  };

  /* Personnel Identification Document */
  const PERSONNEL_ID_DOCUMENT: IMultipleHeaderAndStepInformation = {
    key: FORM_NAME.PERSONNEL_INFORMATION + KYX_FORMS_KEYS.PERSONNEL_DATA_PERSONS + "2",
    row: { gutter: 40 },
    header: {
      number: 2.2,
      title: isVerifiedWithSingpass ? (
        <div className={styles.personnelMyInfoHeader}>
          <p>Personnel Identification Section</p>
          <p className={styles.personnelMyInfoHeaderBadge}>Verified with Singpass</p>
        </div>
      ) : (
        "Verify Personnel Information"
      ),
    },
    document: [
      !isVerifiedWithSingpass && !onlyDocNumber && VERIFICATION_METHOD,
      verificationMethod === VERIFICATION_METHOD_OPTIONS.SINGPASS &&
        !isVerifiedWithSingpass &&
        !onlyDocNumber &&
        VERIFY_SINGPASS,
      ...((verificationMethod === VERIFICATION_METHOD_OPTIONS.MANUAL || isVerifiedWithSingpass || onlyDocNumber) &&
      documentSelectionType?.toLowerCase() === PERSON_DOCUMENT_TYPE.NRIC.toLowerCase()
        ? nric
        : []),
      ...((verificationMethod === VERIFICATION_METHOD_OPTIONS.MANUAL || isVerifiedWithSingpass || onlyDocNumber) &&
      documentSelectionType?.toLowerCase() === PERSON_DOCUMENT_TYPE.WORKPASS.toLowerCase()
        ? workpass
        : []),
      verificationMethod === VERIFICATION_METHOD_OPTIONS.MANUAL &&
        !onlyDocNumber &&
        documentSelectionType?.toLowerCase() === PERSON_DOCUMENT_TYPE.NRIC.toLowerCase() &&
        PROOF_OF_RESIDENCE,
      verificationMethod === VERIFICATION_METHOD_OPTIONS.MANUAL &&
        documentSelectionType &&
        PROOF_OF_RESIDENCE_HEADER_AND_GUIDELINES,
      verificationMethod === VERIFICATION_METHOD_OPTIONS.MANUAL && documentSelectionType && UPLOAD_PROOF_OF_RESIDENCE,
    ],
  };

  return PERSONNEL_ID_DOCUMENT;
};

export const usePersonnelInfoFormDataSGDMyInfo = (
  form: FormInstance,
  activeKey: string,
  formData: IFetchFormSubmissionPayload,
  remarks: IGetFormConfigsPayload,
  currentKey: string,
  isFormDisabled: boolean
): IDocumentStep => {
  return {
    key: FORM_NAME.PERSONNEL_INFORMATION,
    className: SECTION_CONTAINER_CLASS.FLAT_TOP,
    type: DOCUMENT_STEP_TYPE.MULTIPLE_HEADERS_AND_STEPS,
    documents: [
      usePersonnelInfoFormPersonDataSGD(activeKey, remarks, isFormDisabled),
      usePersonnelIdFormPersonDataSGD(form, formData, remarks, currentKey, isFormDisabled),
    ],
  };
};
