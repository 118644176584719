import * as React from "react";
import { useCardDetailsContext } from "Views/Card/Details/index.context";
import { getCardDetailsTabDetails } from "Views/Card/Details/Info/Tabs/Config";
import { CARD_DETAILS_TABS } from "Views/Card/List/types";

import styles from "Views/Card/Details/Info/Tabs/index.module.scss";
import Tabs, { TAB_TYPE } from "Modules/DS/Tabs";

const CardDetailsInfo = () => {
  const { setCardDetailsActiveTab, cardData, showCardDetailsInModal, cardDetailsActiveTab } = useCardDetailsContext();
  const cardDetailsTabsDetails = getCardDetailsTabDetails(cardData);

  return (
    <div className={`${styles["tabs-container"]} ${showCardDetailsInModal ? styles["tabs-container-in-modal"] : ""}`}>
      <Tabs
        activeKey={cardDetailsActiveTab}
        action={(activeKey: CARD_DETAILS_TABS) => setCardDetailsActiveTab(activeKey)}
        tabsList={cardDetailsTabsDetails}
        content={() => cardDetailsTabsDetails.find((tab) => tab.title === cardDetailsActiveTab).component}
        type={TAB_TYPE.FIXED}
      />
    </div>
  );
};

export default CardDetailsInfo;
