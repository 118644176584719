import React, { useState, useEffect, useRef, useImperativeHandle } from "react";
import classNames from "classnames";
import RcInput, { InputProps as RcInputProps } from "rc-input-latest";
import { Input as AntdInput } from "antd";
import { InputProps as AntdInputProps } from "antd/lib/input";

import { currencyFormatterV2 as currencyFormatter, currencyParser, getCurrencyParts } from "utility";

import Label from "../Atoms/Label";

import "./Input.scss";

export interface IInputProps
  extends Omit<AntdInputProps, "onChange" | "value" | "label" | "className">,
    Omit<RcInputProps, "allowClear" | "onChange" | "value" | "label" | "className"> {
  label?: string;
  className?: string;
  // Set as optional, to be fixed as a tech debt ==> (context) remove the usage of [key: string]: any to allow ts complier to work properly
  value?: string;
  onChange?: (val: string) => void;
  error?: string;
  currency?: string;
  // Set as optional, to be fixed as a tech debt ==> (context) remove the usage of [key: string]: any to allow ts complier to work properly
  showCount?: boolean;
  maxLength?: number;
  restrictedCharacters?: RegExp | string;
}

export interface ICurrencyParts {
  decimalSeparator?: string;
  decimalCount?: number;
  thousandSeparator?: string;
}

const isLastChar = (val, char) =>
  val.length && val.lastIndexOf(char) === val.length - 1 && val.indexOf(char) === val.length - 1;

// IMPROVEMENT: props should just use Antd / rcInput instead of creating our own
const Input = React.forwardRef((props: IInputProps, ref: React.RefObject<IInputProps>) => {
  const {
    label,
    className,
    error,
    value,
    onChange = () => undefined,
    currency,
    maxLength,
    showCount = false,
    restrictedCharacters,
    ...rest
  }: IInputProps = props;

  const inputClassNames = classNames("spm-input__input", className, {
    err: error,
  });
  const [currencyParts, setCurrencyParts] = useState<ICurrencyParts>({ decimalSeparator: null, decimalCount: 0 });
  const innerRef = useRef(null);
  useImperativeHandle(ref, () => innerRef.current);

  useEffect(() => {
    currency && setCurrencyParts(getCurrencyParts(currency));
  }, [currency]);

  // handling of trailing decimal separator to enable
  // formatted decimal value
  const trailingDecimal = (curr: string, val = "") => {
    if (!currencyParts.decimalCount) return "";
    const decimalSeparator = curr && currencyParts.decimalSeparator;
    const currentDecimal = ".";
    const targetDecimal = decimalSeparator;
    if (isLastChar(val, currentDecimal)) return targetDecimal;
    return "";
  };

  // modify onchange handler to parse currency value
  const onChangeModifier = (e: React.ChangeEvent<HTMLInputElement>) => {
    let val = e.target.value;
    if (currency) {
      val = currencyParser(val, currency).replace(/[^0-9.]/g, "");
    }
    if (restrictedCharacters) {
      val = val.trimStart().replace(restrictedCharacters, "");
    }
    onChange(val);
  };

  const trail = trailingDecimal(currency, value);
  const formattedValue = value && currency ? currencyFormatter(value, currency, false) + trail : value;

  return (
    <div className="spm-input">
      {label && (
        <Label
          onClick={() => {
            innerRef.current?.focus();
          }}
        >
          {label}
        </Label>
      )}

      {!showCount ? (
        <AntdInput
          maxLength={maxLength}
          ref={innerRef}
          value={currency ? formattedValue : value}
          onChange={onChangeModifier}
          className={inputClassNames}
          {...rest}
        />
      ) : (
        <RcInput
          showCount
          maxLength={maxLength}
          onChange={onChangeModifier}
          ref={innerRef}
          value={value}
          className={inputClassNames}
          {...rest}
        />
      )}
      {error && <div className="spm-input__error">{error}</div>}
    </div>
  );
});

export default Input;
