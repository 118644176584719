import {
  CREATE_APPROVAL_FLOW_REQUEST,
  CREATE_APPROVAL_FLOW_SUCCESS,
  CREATE_APPROVAL_FLOW_FAILURE,
  CLEAR_CREATE_APPROVAL_FLOW,
} from "../../Actions/ApprovalFlow/createApprovalFlow";
import { userInitialState } from "../../InitialState";

export const createApprovalFlowReducer = (state = userInitialState, action) => {
  switch (action.type) {
    case CREATE_APPROVAL_FLOW_REQUEST:
      return {
        ...state,
        data: action.data,
        loading: true,
        error: false,
        errorDetail: null,
      };
    case CREATE_APPROVAL_FLOW_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
        error: false,
        errorDetail: null,
      };
    case CREATE_APPROVAL_FLOW_FAILURE:
      return {
        ...state,
        data: null,
        loading: false,
        errorDetail: action.data,
        error: true,
      };
    case CLEAR_CREATE_APPROVAL_FLOW:
      return userInitialState;
    default:
      return state;
  }
};
