import React, { MouseEventHandler, useMemo, useState } from "react";

import Modal from "Modules/DS/Modal";
import { receiptsIcon } from "assets/img";
import { ViewAttachments } from "Views/Transaction/Modal/Attachments";
import { IconCell } from "Views/Transaction/List";
import { TrxnFilterContext, TrxnToastContext } from "Views/Transaction/Context";
import { AddAttachmentCell } from "Views/Transaction/All/Columns/Attachment/Add";
import { includesReversalTrxns } from "Views/Transaction/Helper";
import ShimmerEffect from "../ShimmerEffect";

import styles from "./style.module.scss";

const ModalAttachment = ({ receipts, record, setToaster, refetch }) => {
  const [loading, setLoading] = useState(false);
  const [openAttachment, setOpenAttachment] = useState(false);

  const onClick: MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
    setOpenAttachment(true);
  };

  const filterContext = useMemo(() => ({ onApplyFilter: refetch }), [refetch]);
  const toasterContext = useMemo(() => ({ setToaster, toaster: null }), [setToaster]);

  if (loading) {
    return <ShimmerEffect loading>{null}</ShimmerEffect>;
  }

  if (receipts?.length === 0 || !receipts) {
    return (
      <div className={styles.center}>
        <TrxnFilterContext.Provider value={filterContext}>
          <TrxnToastContext.Provider value={toasterContext}>
            <AddAttachmentCell
              record={{
                ...record,
                transaction_number: record.transactionNumber, // To be able to match what key store on transaction page
              }}
              setLoading={setLoading}
              disabled={includesReversalTrxns(record.transactionType)}
            />
          </TrxnToastContext.Provider>
        </TrxnFilterContext.Provider>
      </div>
    );
  }

  return (
    <div className={styles.center} onClick={(e) => e.stopPropagation()}>
      <IconCell src={receiptsIcon} alt="view attachment" onClick={onClick} />
      <Modal visible={openAttachment} className={styles.attachment} close={() => setOpenAttachment(!openAttachment)}>
        <ViewAttachments receipts={receipts} />
      </Modal>
    </div>
  );
};

export default ModalAttachment;
