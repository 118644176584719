import React from "react";

import { capitalizeFirstLetter } from "utility";

import { GET_TRXN_REQUEST_PARAMS, ITrxnsDateFilterRequest } from "Views/Transaction/@types";

export const messageGenerator = ({ selectedTemplate, noFilters, isConnected, mustSendEmail, sentEmail }: any) => {
  const generateTextHeader = () => {
    if (selectedTemplate.name) {
      return (
        <span>
          <span className="capitalize">{selectedTemplate.accountingSoftware}</span> export guide
        </span>
      );
    }

    if (noFilters) {
      return <span>No filter applied for this dowloaded data</span>;
    }

    if (!isConnected || !selectedTemplate.name) {
      return <span>Make sure the amount of transactions to download is correct</span>;
    }
  };

  const generateSubHeader = (): string => {
    if (selectedTemplate.name) {
      return selectedTemplate.detail;
    }

    if (noFilters) {
      return "If you don’t use filter, you will automatically download all transactions from the beginning of this current month.";
    }

    if (!isConnected || !selectedTemplate.name) {
      return "If you used the filter, make sure the amount of transactions data to download correctly matches with the filter applied.";
    }
  };

  const generateButtonText = (): string => {
    if (mustSendEmail) {
      return "Send to Email";
    }
    if (sentEmail) {
      return `Download & Send to Email`;
    }
    return `Download ${capitalizeFirstLetter(selectedTemplate?.accountingSoftware) || ""} CSV`;
  };

  return {
    generateTextHeader,
    generateSubHeader,
    generateButtonText,
  };
};

export const thisYearDateRange = (): ITrxnsDateFilterRequest => ({
  [GET_TRXN_REQUEST_PARAMS.START_DATE]: new Date(new Date().getFullYear(), 1, 1).toISOString(),
  [GET_TRXN_REQUEST_PARAMS.END_DATE]: new Date(Date.now()).toISOString(),
});

export const START_DATE = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
export const END_DATE = new Date(Date.now());

export const thisMonthDateRange = (): ITrxnsDateFilterRequest => ({
  [GET_TRXN_REQUEST_PARAMS.START_DATE]: START_DATE.toISOString(),
  [GET_TRXN_REQUEST_PARAMS.END_DATE]: END_DATE.toISOString(),
});

export const customCSVThisMonthDateRange = (): ITrxnsDateFilterRequest => ({
  [GET_TRXN_REQUEST_PARAMS.START_DATE]: dateFormatter(START_DATE),
  [GET_TRXN_REQUEST_PARAMS.END_DATE]: dateFormatter(END_DATE),
});

export const useSmartCsvFilters = <T extends ITrxnsDateFilterRequest>(filter: T): T => ({
  ...thisMonthDateRange(),
  ...filter,
});

export const dateFormatter = (date) => {
  if (!date) {
    return "";
  }

  // Create a custom format to follow BE accepted date string
  const dateformat = new Intl.DateTimeFormat("en-SG", { year: "numeric", month: "numeric", day: "numeric" });
  const [day, , month, , year] = dateformat.formatToParts(new Date(date)).map((p) => p.value);
  return `${year}-${month}-${day}`;
};
