import * as React from "react";
import { Link } from "react-router-dom";
import Icon from "Modules/icons";

import { ITaskCardProps } from "./types";
import { navigationChevron } from "assets/img/homeIcons";
import styles from "./index.module.scss";

const TaskCard = ({
  iconSrc,
  iconAlt,
  title,
  description,
  btnText,
  showButton = false,
  btnRedirectLink,
  cardRedirectLink,
  action,
}: ITaskCardProps) => {
  return (
    <Link to={cardRedirectLink} onClick={action} data-testid="redirection-link" className={styles.card}>
      <Icon className={styles["card-icon"]} src={iconSrc} alt={iconAlt} />
      <div>
        <h3 className={styles.title}>{title}</h3>
        {description && <p className={styles.description} dangerouslySetInnerHTML={{ __html: description }} />}
        {showButton && (
          <Link className={styles.button} to={btnRedirectLink}>
            {btnText}
          </Link>
        )}
      </div>
      <Icon className={styles.arrow} src={navigationChevron} alt={"arrow icon"} />
    </Link>
  );
};

export default TaskCard;
