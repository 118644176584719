import APIclient from "API/Client";
import { AxiosResponse } from "axios";

import { IAllTeamsWithMembersResponseData } from "Views/Team";

import { TEAMS_SERVICE } from "./Services";

export const getAllTeamsWithMembers = async (): Promise<AxiosResponse<IAllTeamsWithMembersResponseData>> => {
  return APIclient.getData(TEAMS_SERVICE().GET_ALL_TEAMS_WITH_MEMBERS);
};
