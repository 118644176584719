import * as React from "react";

import { ModalComponent } from "Modules/DS/Modal";

import { greyCloseIcon } from "assets/img";
import {
  check,
  cross,
  singleAsterisk,
  doubleAsterisk,
  checkSingleAsterisk,
  checkDoubleAsterisk,
} from "assets/icons/role/index";
import "./RoleExplanationModal.scss";

const RoleExplanationModal = () => {
  return (
    <>
      <ModalComponent.CloseButton src={greyCloseIcon} />
      <ModalComponent.Title>Roles</ModalComponent.Title>
      <div className="role-explanation-modal">
        <table>
          <tr>
            <th>Permission</th>
            <th>Admin </th>
            <th>Employee</th>
            <th>Accountant</th>
            <th>Bookkeeper</th>
            <th>Auditor</th>
          </tr>
          <tr>
            <td>Use Cards</td>
            <td>
              <img src={check} />
            </td>
            <td>
              <img src={check} />
            </td>
            <td>
              <img src={check} />
            </td>
            <td>
              <img src={check} />
            </td>
            <td>
              <img src={cross} />
            </td>
          </tr>
          <tr>
            <td>Create Cards & Approve Cards Request</td>
            <td>
              <img src={check} />
            </td>
            <td>
              <img src={checkSingleAsterisk} />
            </td>
            <td>
              <img src={checkSingleAsterisk} />
            </td>
            <td>
              <img src={checkSingleAsterisk} />
            </td>
            <td>
              <img src={cross} />
            </td>
          </tr>
          <tr>
            <td>Request for Reimbursement</td>
            <td>
              <img src={check} />
            </td>
            <td>
              <img src={check} />
            </td>
            <td>
              <img src={check} />
            </td>
            <td>
              <img src={check} />
            </td>
            <td>
              <img src={cross} />
            </td>
          </tr>
          <tr>
            <td>Approve Reimbursement</td>
            <td>
              <img src={check} />
            </td>
            <td>
              <img src={checkSingleAsterisk} />
            </td>
            <td>
              <img src={checkSingleAsterisk} />
            </td>
            <td>
              <img src={checkSingleAsterisk} />
            </td>
            <td>
              <img src={cross} />
            </td>
          </tr>
          <tr>
            <td>Request for Bill Payment</td>
            <td>
              <img src={check} />
            </td>
            <td>
              <img src={checkSingleAsterisk} />
            </td>
            <td>
              <img src={check} />
            </td>
            <td>
              <img src={check} />
            </td>
            <td>
              <img src={cross} />
            </td>
          </tr>
          <tr>
            <td>Approve Bill Payment</td>
            <td>
              <img src={check} />
            </td>
            <td>
              <img src={checkDoubleAsterisk} />
            </td>
            <td>
              <img src={checkDoubleAsterisk} />
            </td>
            <td>
              <img src={checkDoubleAsterisk} />
            </td>
            <td>
              <img src={cross} />
            </td>
          </tr>
          <tr>
            <td>Sync with Accounting Software</td>
            <td>
              <img src={check} />
            </td>
            <td>
              <img src={cross} />
            </td>
            <td>
              <img src={check} />
            </td>
            <td>
              <img src={cross} />
            </td>
            <td>
              <img src={cross} />
            </td>
          </tr>
          <tr>
            <td>Access Financial Details</td>
            <td>
              <img src={check} />
            </td>
            <td>
              <img src={cross} />
            </td>
            <td>
              <img src={check} />
            </td>
            <td>
              <img src={cross} />
            </td>
            <td>
              <img src={check} />
            </td>
          </tr>
          <tr>
            <td>Top Up Company Balance</td>
            <td>
              <img src={check} />
            </td>
            <td>
              <img src={cross} />
            </td>
            <td>
              <img src={check} />
            </td>
            <td>
              <img src={cross} />
            </td>
            <td>
              <img src={cross} />
            </td>
          </tr>
          <tr>
            <td>Create Team & Manage Employees</td>
            <td>
              <img src={check} />
            </td>
            <td>
              <img src={cross} />
            </td>
            <td>
              <img src={cross} />
            </td>
            <td>
              <img src={cross} />
            </td>
            <td>
              <img src={cross} />
            </td>
          </tr>
        </table>
        <div className="footer">
          <div className="footer-first">
            <img src={singleAsterisk} />
            Only if they are a Budget Owner
          </div>
          <div className="footer-second">
            <img src={doubleAsterisk} />
            Only if they are assigned as an Approver
          </div>
        </div>
      </div>
    </>
  );
};

export default RoleExplanationModal;
