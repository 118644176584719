import React from "react";
import dayjs from "dayjs";
import { Form } from "antd";
import { useLocation } from "react-router-dom";

import { CASBIN_PAGES } from "Permission/Pages";
import usePermissionCheck from "Permission/usePermissionCheck";
import { REIMBURSEMENT_TRANSACTION_PAGE } from "Permission/Actions";

import {
  currencyFormatterV2,
  dateTimeWithTimezone,
  intlDateTimeFormat,
  intlTimeFormat,
  roundValue,
} from "utility";

import {
  EInputType,
  IAuditTrail,
  IAuditTrailProps,
  IDetail,
  ITransactionDetailProps,
  ITransactionHeaderProps,
} from "Modules/TransactionDetail";
import { IBatchPayoutProps } from "Modules/TransactionDetail/BatchPayout";

import { REIMBURSEMENT_VIEWS } from "Views/Reimbursement/Constants";
import { IReimbursementDetailData } from "Views/Reimbursement/@types";
import { useIsSaasWithPaymentsOrg } from "customHooks/useIsSaasWithPaymentsOrg";

const currencyWrapper = (amount: string | number, currency: string) => {
  return currencyFormatterV2(
    amount,
    currency,
    true,
    currency !== "IDR" ? 2 : 0,
  );
};

const useDetailProps = (
  data: IReimbursementDetailData,
  setFormValue: React.Dispatch<any>,
) => {
  const location = useLocation();

  const isPersonalTab = location.pathname.includes(
    REIMBURSEMENT_VIEWS.PERSONAL_REIMBURSEMENTS.link,
  );

  const [form] = Form.useForm();

  const permissionParam = [
    {
      object: CASBIN_PAGES.REIMBURSEMENT_TRANSACTION_PAGE,
      action: REIMBURSEMENT_TRANSACTION_PAGE.SAVE_ACCOUNTING_FIELDS,
    },
  ];

  const isPaymentEnabled = useIsSaasWithPaymentsOrg();
  const isAllowedSaving =
    (usePermissionCheck(permissionParam) as boolean) || false;

  const defaultEmptyText = "N.A.";

  const amount: string = currencyWrapper(data?.amount, data?.currency_code);

  const payoutAmount = currencyWrapper(
    data?.payout_detail?.amount,
    data?.payout_detail?.currency,
  );

  const receiptAmount = currencyWrapper(data?.amount, data?.currency_code);

  const details: IDetail[] = [
    {
      id: "DETAIL_001",
      key: "Requester",
      value: data?.user_name,
      visible: true,
    },
    {
      id: "DETAIL_002",
      key: "Created Date & Time",
      value: `${intlDateTimeFormat(new Date(dateTimeWithTimezone(data?.created_at)))}, ${intlTimeFormat(
        new Date(dateTimeWithTimezone(data?.created_at)),
      )}`,
      visible: true,
    },
    {
      id: "DETAIL_004",
      key: "Transaction Type",
      value: data?.type,
      visible: true,
    },
    {
      id: "DETAIL_0011",
      key: "Transaction Number",
      value: data?.transaction_number || defaultEmptyText,
      visible: true,
    },
    {
      id: "DETAIL_005",
      key: "Merchant",
      value: data?.merchant,
      visible: true,
    },
    {
      id: "DETAIL_006",
      key: "Budget",
      value: data?.team_name || defaultEmptyText,
      visible: true,
    },
    {
      id: "DETAIL_007",
      key: "Receipt Amount",
      value: receiptAmount,
      visible: true,
    },
    {
      id: "DETAIL_008",
      key: "Expense Date",
      value: dayjs.unix(data?.request_timestamp).format("DD MMM YYYY"),
      visible: true,
    },
    {
      id: "DETAIL_009",
      key: "FX Currency",
      value: data?.foreign_currency_code || defaultEmptyText,
      visible: isPaymentEnabled,
    },
    {
      id: "DETAIL_010",
      key: "FX Rate",
      value: data?.foreign_currency_amount || defaultEmptyText,
      visible: isPaymentEnabled,
    },
  ].filter((item) => item.visible);

  const visibleInput = {
    [EInputType.TAX]: true,
    [EInputType.NOTES]: true,
    [EInputType.RECEIPT]: true,
    [EInputType.CATEGORY]: true,
    [EInputType.TRANSACTION_TAGS]: true,
    [EInputType.REJECTION_REASON]: data?.status === "rejected" || false,
  };

  const defaultValue = {
    [EInputType.CATEGORY]: data?.expense_category_name,
    [EInputType.TRANSACTION_TAGS]: data?.tags,
    [EInputType.NOTES]: data?.notes,
    [EInputType.TAX]: data?.tax?.id,
    [EInputType.MERCHANT]: data?.merchant,
    [EInputType.RECEIPT]: data?.receipts?.urls,
    [EInputType.REJECTION_REASON]: data?.rejection_reason,
  };

  const headerProps: ITransactionHeaderProps = {
    subTitle: "Merchant",
    name: data?.merchant,
    amount: amount,
    caption: `Paid amount ${amount}`,
  };

  const auditTrail: IAuditTrailProps = {
    title: "Transaction Status",
    isEditable: true,
    list: data?.status_journey?.map((val) => {
      return {
        label: val?.label,
        state: val?.state,
        time: val?.timestamp,
        executor: val?.user?.name ?? "",
        description: val?.description,
      } as IAuditTrail;
    }),
  };

  const payoutProps: IBatchPayoutProps = {
    amount: payoutAmount || defaultEmptyText,
    date: data?.payout_detail?.date || defaultEmptyText,
    reference: data?.payout_detail?.transaction_number || defaultEmptyText,
  };

  const detailProps: ITransactionDetailProps = {
    form,
    setFormValue,
    visibleInput,
    defaultValue,
    details,
    taxFallback: `${data?.tax?.name} (${roundValue(data?.tax?.rate)}%)`,
    receiptTitle: "Attachments",
    disabled: !(isAllowedSaving && data?.final_status?.key === "approved" && !isPersonalTab),
  };

  return {
    detailProps,
    payoutProps,
    auditTrail,
    headerProps,
  };
};

export default useDetailProps;
