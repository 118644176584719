import React, { ChangeEvent, forwardRef, useCallback, useEffect, useMemo, useState } from "react";
import qs from "query-string";
import dayjs, { Dayjs } from "dayjs";
import { useFormContext } from "react-hook-form";
import { Input as AntdInput, Badge, Space } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";

import { useMutableData } from "API/useData";

import { FormItem } from "Views/Bills/V2/components/FormGenerator";
import Date from "Views/Bills/V2/components/FormGenerator/SpDate";
import Checkbox from "Views/Bills/V2/components/FormGenerator/Checkbox";
import FormController from "Views/Bills/V2/components/FormController";

import { useErrorHandler } from "Views/Bills/V2/context/ErrorHandlerContext";

import { GetBaseAuthObject } from "utility";
import { API_URL, DATE_FORMAT } from "Views/Bills/V2/constants";
import { PaymentScheduleProps, VendorDynamicFieldNames } from "Views/Bills/V2/components/FormGenerator/type";
import classNames from "classnames";
import styles from "./PaymentSchedule.module.scss";

/**
 * This component is using react-hook-form.
 * Please use FormProvider before using this.
 * This component should NOT be copied to other page component
 * since it's using css from BillForm
 */
const PaymentSchedule = forwardRef((props: Partial<PaymentScheduleProps>, ref: React.RefObject<AntdInput>) => {
  const { dateFieldProps, checkboxFieldProps } = props;
  const {
    label: dateInputLabel,
    name: dateInputName,
    rules: dateInputRules,
    onChange: onChangeDate,
    fieldProps: dateProps,
  } = dateFieldProps;

  const { help: dateETALabel, fieldType: deletedKey, ...restDateProps } = dateProps;

  const { controllerProps, ...checkboxProps } = checkboxFieldProps || {};
  const { name: checkBoxName } = controllerProps || {};

  const [payImmediately, setPayImmediately] = useState(false);
  const [resetAllFields, setResetAllFields] = useState(false);

  const { handleError } = useErrorHandler();

  const { control, watch, setError, clearErrors, setValue } = useFormContext();

  // Extract value for api call
  const [vendorID, amount, currency, dueDate, paymentScheduleDate, isSuggestPaymentDate, addDateByCalender] = watch([
    "vendorID",
    "amount",
    "currency",
    "dueDate",
    "paymentScheduleDate",
    "isSuggestPaymentDate",
    "addDateByCalender",
  ]);

  const {
    data: recipientDetail,
    mutate: refetchDetail,
    error: recipientDetailError,
  } = useMutableData(vendorID ? `${API_URL.recipientDetail}/${vendorID}` : null);

  const { dynamicFields } = recipientDetail?.data?.payload || {};

  // Beneficiary country code
  const vendorCountryCode = useMemo(() => {
    return dynamicFields?.find((item) => {
      return item.alias === VendorDynamicFieldNames.countryCode;
    })?.value;
  }, [dynamicFields]);

  // Extract Sender country code
  const senderCountryCode = GetBaseAuthObject()?.orgCountryCode;

  const apiURL = useMemo(() => {
    if (amount && currency) {
      return qs.stringifyUrl({
        url: API_URL.schedulePayment,
        query: {
          dueDate: dueDate,
          amount: Number(amount), // API accepts a number
          currency: currency,
          beneficiaryBankCountry: vendorCountryCode,
          senderCountry: senderCountryCode,
        },
      });
    }
  }, [amount, currency, dueDate, senderCountryCode, vendorCountryCode]);

  const { data, isValidating } = useMutableData(apiURL, {
    revalidateOnFocus: false,
  });

  // Show etimated payment days to user
  const estimatedDays: string = data?.data?.payload?.sla || "";

  // IMPROVEMENT: disabling date could be put into the props
  // with an object example: before: Date, after: Date, dates: Date[]
  const getDisabledDate = useCallback((currentDate: Dayjs) => {
    return dayjs(currentDate).isBefore(dayjs(), "day");
  }, []);

  const onSelectDate = (date: string, isPayNow?: boolean, isModifyCheckbox: boolean = true) => {
    // Note: check date value is valid
    if (date) {
      setPayImmediately(isPayNow);
      onChangeDate(dayjs(date).format(DATE_FORMAT.standard) as unknown as ChangeEvent<HTMLInputElement>);
      setValue("addDateByCalender", dayjs(date).format(DATE_FORMAT.standard));

      if (isModifyCheckbox && checkboxFieldProps) {
        // Unchecked the checkbox if the user select date manually
        setValue(checkBoxName, false);
      }

      // Clear the error message
      clearErrors(dateInputName);
    }
  };

  const handleSuggestPaymentDate = () => {
    const { paymentScheduleDate, isPayNow } = data?.data?.payload || {};

    if (!dueDate) {
      // Show error notification for select due date
      setError(dateInputName, {
        message: "Select a due date to enable payment date suggestion",
      });
      return false;
    }

    if (paymentScheduleDate) {
      setPayImmediately(false);
      onSelectDate(paymentScheduleDate, false, false);
    } else if (isPayNow) {
      onSelectDate(dayjs().format(DATE_FORMAT.standard), true, false);
    }

    return true;
  };

  const onClickSuggestPaymentDate = (e: CheckboxChangeEvent, onChangeCheckbox: (isChecked: boolean) => void) => {
    if (e.target.checked) {
      if (handleSuggestPaymentDate()) {
        onChangeCheckbox(e.target.checked);
      }
    } else {
      // Set payment schedule date to empty again
      onChangeCheckbox(e.target.checked);
      onChangeDate(addDateByCalender || "");
      if (!addDateByCalender) setResetAllFields(true);
    }
  };

  // handle recipient detail error
  useEffect(() => {
    if (recipientDetailError) {
      handleError({
        retry: {
          id: "recipientDetail",
          onClickRetry: refetchDetail,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recipientDetailError]);

  // Update payment schedule date, If user back and change the due date
  useEffect(() => {
    if (!isValidating && data) {
      // hard code for estimated arrival
      setValue("slaID", data?.data?.payload?.id);

      if (isSuggestPaymentDate) {
        if (!dueDate) {
          setValue(checkBoxName, false);
          return;
        }

        handleSuggestPaymentDate();
      }
    }
  }, [data, isValidating]);

  // create date props
  const dateComponentProps = {
    ...restDateProps,
    action: onSelectDate,
    defaultDate:
      isSuggestPaymentDate || (!payImmediately && !addDateByCalender) ? paymentScheduleDate : addDateByCalender,
    name: dateInputName,
    showPlaceholderWithOutHasClear: true, // Show placeholder
    resetAllFields,
    setResetAllFields,
    disabledDate: getDisabledDate,
  };

  const dateLabelClass = classNames(styles.formItem, {
    noSLA: !estimatedDays,
  });

  return (
    <div className={styles.fieldContainer}>
      <FormItem
        className={dateLabelClass}
        name={dateInputName}
        label={
          <Space className={styles.labelContainer}>
            <label>{dateInputLabel}</label>
            {estimatedDays && (
              <Badge status="success" text={`${dateETALabel} ${estimatedDays}`} className={styles.badgeColor} />
            )}
          </Space>
        }
        required={Boolean(dateInputRules?.required)}
        showError={false}
      >
        <Date {...dateComponentProps} onChange={() => {}} />
      </FormItem>
      {checkboxFieldProps && (
        <Space align="center" className={styles.checkboxContainer}>
          <FormController
            control={control}
            {...controllerProps}
            render={({ field }) => (
              <Checkbox
                {...field}
                {...checkboxProps}
                disabled={!data}
                onChange={(evn) => onClickSuggestPaymentDate(evn, field.onChange)}
              />
            )}
          />
        </Space>
      )}
    </div>
  );
});

export default PaymentSchedule;
