import APIclient from "API/Client";
import { getCardOwnerNamesFunc } from "./index";
import { GetFormData } from "../../utility/APIWrapper";
import { trackEvent } from "utility/analytics";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import { GetOrgId } from "utility";
import { ActivatePhysicalCardEventNames, CardCreationEventNames } from "Views/Card/Analytics";

export const FETCH_CREATE_CARD_REQUEST = "FETCH_CREATE_CARD_REQUEST";
export const FETCH_CREATE_CARD_SUCCESS = "FETCH_CREATE_CARD_SUCCESS";
export const FETCH_CREATE_CARD_FAILURE = "FETCH_CREATE_CARD_FAILURE";
export const RESET_CREATE_CARD_DATA = "RESET_CREATE_CARD_DATA";

export const fetchDataReq = (data = {}) => {
  return {
    type: FETCH_CREATE_CARD_REQUEST,
    data: data,
  };
};
export const fetchDataSuccess = (data) => {
  return {
    type: FETCH_CREATE_CARD_SUCCESS,
    data: {
      payload: data,
    },
  };
};
export const fetchDataError = (data) => {
  return {
    type: FETCH_CREATE_CARD_FAILURE,
    data: data,
  };
};

export const resetCreateCardData = () => ({
  type: RESET_CREATE_CARD_DATA,
});

const ACTIONS = {
  CREATE_VIRTUAL_CARD: "virtual",
  REQUEST_PHYSICAL_CARD: "physical",
  ACTIVATE_PHYSICAL_CARD: "physicalSync",
};

export const trackActivatePhysicalCard = (isActivated) => {
  trackEvent(ActivatePhysicalCardEventNames.CARD_ACTIVATION_PROCESSED, {
    is_virtual_card: false,
    card_process_success: isActivated,
  });
};

const trackCreateVirtualCard = (isSuccess) => {
  trackEvent(CardCreationEventNames.CARD_CREATION_PROCESSED, {
    is_virtual_card: true,
    card_process_success: isSuccess,
  });
};

const trackCardEvents = (action, isSuccess) => {
  if (action === ACTIONS.ACTIVATE_PHYSICAL_CARD) {
    trackActivatePhysicalCard(isSuccess);
  } else if (action === ACTIONS.CREATE_VIRTUAL_CARD) {
    trackCreateVirtualCard(isSuccess);
  }
};

export const createCardFunc = (action, data) => {
  const orgId = GetOrgId();
  let requestURL;

  if (action === ACTIONS.CREATE_VIRTUAL_CARD) {
    requestURL = "/api/v1/org/card/virtual";
  } else if (action === ACTIONS.REQUEST_PHYSICAL_CARD) {
    requestURL = "/api/v1/org/card/physical/request";
  } else if (action === ACTIONS.ACTIVATE_PHYSICAL_CARD) {
    requestURL = "/api/v1/org/card/physical/activate";
  }

  return (dispatch) => {
    dispatch(fetchDataReq());
    APIclient.postData(requestURL, GetFormData({ organisation_id: orgId, ...data }))
      .then((data) => {
        dispatch(fetchDataSuccess({ ...data.data, type: action }));
        dispatch(getCardOwnerNamesFunc());
        const { status = "" } = data?.data || {};
        trackCardEvents(action, status === HTTP_STATUS_CODE.OK);
      })
      .catch((err) => {
        dispatch(fetchDataError(err));
        trackCardEvents(action, false);
      });
  };
};
