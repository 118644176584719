import { useState } from "react";

import { EMPLOYEES_INITIAL_PAGE_NUMBER } from "constants/Employee.constant";

const usePagination = (): [number, React.Dispatch<React.SetStateAction<number>>, () => void] => {
  const [currentPage, setCurrentPage] = useState(EMPLOYEES_INITIAL_PAGE_NUMBER);
  const resetCurrentPage = (): void => setCurrentPage(EMPLOYEES_INITIAL_PAGE_NUMBER);

  return [currentPage, setCurrentPage, resetCurrentPage];
};

export default usePagination;
