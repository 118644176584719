import React from "react";

import { Tags } from "@spenmo/splice";

import styles from "./index.module.scss";
import { ICycleStatus, IPaymentCycleData } from "Views/Bills/V2/PaymentRun/types";
import { CYCLE_STATUS_ENUM } from "Views/Bills/V2/PaymentRun/constant";
import { plural } from "utility";

const StatusInfo = ({
  status,
  statusDetails,
}: {
  status: ICycleStatus;
  statusDetails: IPaymentCycleData["statusDetails"];
}) => {
  return (
    <div className={styles.container}>
      {[CYCLE_STATUS_ENUM.SENDING_PAYMENT, CYCLE_STATUS_ENUM.WAITING_FUNDS].includes(status.key) && (
        <Tags.Progression className={styles.status} hierarchy="low" label={status.label} variant="info" />
      )}
      {[CYCLE_STATUS_ENUM.PAID, CYCLE_STATUS_ENUM.REVERSED].includes(status.key) &&
        statusDetails?.map((statusDetail) => (
          <Tags.Progression
            key={statusDetail.status.key}
            className={styles.status}
            hierarchy="low"
            label={`${plural(statusDetail.count, "bill")} ${statusDetail.status.label.toLowerCase()}`}
            variant={statusDetail.status.key === CYCLE_STATUS_ENUM.PAID ? "success" : "danger"}
          />
        ))}
    </div>
  );
};

export default StatusInfo;
