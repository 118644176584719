import React from "react";

import { capitalizeFirstLetter } from "utility";

import { Section, TransactionStatus } from "Modules/TransactionDetail";

export const Status = ({ status, date }: { status: string; date: string }) => {
  return (
    <Section>
      <TransactionStatus
        currentStatusId={"1"}
        transactionStatus={[{ id: "1", title: capitalizeFirstLetter(status), date: date }]}
      />
    </Section>
  );
};
