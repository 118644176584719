import {
  GET_SCHEDULED_TRANSACTIONS_REQUEST,
  GET_SCHEDULED_TRANSACTIONS_SUCCESS,
  GET_SCHEDULED_TRANSACTIONS_FAILURE,
  CLEAR_GET_SCHEDULED_TRANSACTIONS,
} from "Redux/Actions/ScheduledTransactions/getScheduledTransactions";
import { userInitialState } from "Redux/InitialState";

export const getScheduledTransactionsReducer = (state = userInitialState, action) => {
  switch (action.type) {
    case GET_SCHEDULED_TRANSACTIONS_REQUEST:
      return {
        ...state,
        data: action.data,
        loading: true,
      };
    case GET_SCHEDULED_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
      };
    case GET_SCHEDULED_TRANSACTIONS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.data,
        error: true,
      };
    case CLEAR_GET_SCHEDULED_TRANSACTIONS:
      return userInitialState;
    default:
      return state;
  }
};
