import React from "react";
import {
  useFetchUserWalletBalancesList,
  TEAMS_TO_FETCH,
} from "Views/Home/Components/WalletModelExpenseCarousel/Utitlites";
import { useSelector } from "react-redux";
import ExpenseCard from "Views/Home/Components/ExpenseCard";
import ExpensesCarousel from "Views/Home/Components/ExpensesCarousel";
import ExpenseFetchError from "Views/Home/Components/ExpenseFetchError";

const WalletModelExpenseCarousel = () => {
  const [
    userWalletBalancesList,
    fetchMoreUserWalletBalancesData,
    userWalletBalancesDataLoading,
    userWalletBalancesDataError,
  ] = useFetchUserWalletBalancesList();
  const { loading: userDataLoading } = useSelector((state) => state?.userInfoReducer);

  const getUserWalletBalancesCarouselData = () => {
    if (userWalletBalancesDataError) {
      return [<ExpenseFetchError onReload={fetchMoreUserWalletBalancesData} />];
    } else if ((userWalletBalancesDataLoading && userWalletBalancesList?.length === 0) || userDataLoading) {
      return Array.from({ length: TEAMS_TO_FETCH })?.map(() => <ExpenseCard isLoading />);
    } else {
      return userWalletBalancesList?.map((expenseDetails) => <ExpenseCard expenseDetails={expenseDetails} />);
    }
  };

  return <ExpensesCarousel data={getUserWalletBalancesCarouselData()} onChange={fetchMoreUserWalletBalancesData} />;
};

export default WalletModelExpenseCarousel;
