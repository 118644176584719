import { useRef, useEffect, useCallback } from "react";

export const useClickOutside = <T extends HTMLElement>(
  callback: (e: MouseEvent) => void,
  ignoreSelectorElements: string[] = []
): React.MutableRefObject<T> => {
  const ref = useRef<T>(null);
  const memoizedIgnoreSelectorElements = useRef<string[]>(ignoreSelectorElements);

  const handleSelectorElements = useCallback((e: MouseEvent) => {
    return memoizedIgnoreSelectorElements.current.some((selector: string) => {
      return (e.target as Element)?.closest(selector);
    });
  }, []);

  const handleClickOutside = useCallback(
    (e: MouseEvent) => {
      if (!ref.current?.contains(e.target as Node) && !handleSelectorElements(e)) {
        callback?.(e);
      }
    },
    [callback, handleSelectorElements]
  );

  useEffect(() => {
    const handleClick = (e: MouseEvent) => handleClickOutside(e);
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [handleClickOutside]);

  useEffect(() => {
    memoizedIgnoreSelectorElements.current = ignoreSelectorElements;
  }, [ignoreSelectorElements]);

  return ref;
};
