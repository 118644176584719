import React from "react";
import { useHistory } from "react-router-dom";
import useSWRMutation from "swr/mutation";
import { postData } from "API/Client";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import { ICreateForm } from "Views/SubscriptionManagement/@types";
import { toast, Typography } from "@spenmo/splice";
import { capitalizeFirstLetter } from "utility";
import { SUBSCRIPTION_PATH } from "Route/Subscription/path";

export const useCreateSubscription = () => {
  const history = useHistory();

  return useSWRMutation<string | null, any, string, ICreateForm>(
    "ms/spm-organization/v1/subscriptions",
    (url, { arg }) => {
      const vendorName = arg.vendor_name;
      delete arg.vendor_name;

      return postData(url, { ...arg })
        .then(({ data }) => {
          const { status, payload } = data || {};
          if (status === HTTP_STATUS_CODE.OK && payload?.subscription_id) {
            toast.success(`${capitalizeFirstLetter(vendorName.toLowerCase())} subscription successfully created`, {
              duration: 3000,
              actionButton: (
                <Typography
                  size="m"
                  variant="body-content"
                  className="cursor-pointer"
                  weight={600}
                  onClick={() =>
                    history.push(SUBSCRIPTION_PATH.SUBSCRIPTON_DETAILS.replace(":id", payload?.subscription_id))
                  }
                >
                  View
                </Typography>
              ),
            });
            return payload.subscription_id;
          }
          throw new Error(data);
        })
        .catch((err) => {
          const serverErrorMessage = err?.response?.data?.error?.message;
          const defaultMessage =
            "This subscription could not be created due to some error. Please retry or contact us at support@spenmo.com if the issue persists.";
          toast.danger(serverErrorMessage || defaultMessage);
          throw new Error(err);
        });
    }
  );
};
