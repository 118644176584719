import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "antd";
import { Link } from "react-router-dom";
import CustomCarousel from "Views/Home/Components/CustomCarousel";
import rightArrowBlue from "assets/img/arrows/rightArrowBlue.svg";

import "Views/Home/Components/ExpensesCarousel/ExpensesCarousel.scss";

const ExpensesCarousel = ({ onChange, data = [], limitModel = false }) => (
  <>
    {data?.length > 0 && (
      <div className="expenses-carousel">
        <Row>
          <Col className="expenses-carousel__header">
            Budget Overview
            <Link to="/teams">
              View all <img src={rightArrowBlue} alt="right arrow blue" />
            </Link>
          </Col>
        </Row>
        <div
          className={`expenses-carousel__container expenses-carousel__container--${
            limitModel ? "limit" : "wallet"
          }-model`}
        >
          <CustomCarousel data={data} onChange={onChange} slidesToShow={Math.min(data.length, 2)} />
        </div>
      </div>
    )}
  </>
);

ExpensesCarousel.propTypes = {
  data: PropTypes.array,
  itemsToShow: PropTypes.number,
  onChange: PropTypes.func,
  limitModel: PropTypes.bool,
};

export default ExpensesCarousel;
