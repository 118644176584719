import React, { useContext } from "react";
import dayjs from "dayjs";
import qs from "query-string";
import { Select } from "antd";
import { Base64 } from "js-base64";

import { Radio, Typography } from "@spenmo/splice";
import { useHistory, useLocation } from "react-router-dom";

import { BILLS_QUERY_PARAMS, EXPORT_DATE_RANGE_TYPES } from "Views/Bills/V2/constants";
import { IDateTypeProps, ILocationQueryParams, IPaymenRunType, IRangeType } from "Views/Bills/V2/@types";

import DateRange from "Views/Bills/V2/Sidepanel/Export/Date/Range";
import { PermissionAndSettingsContext } from "Views/Bills/V2/context/BillContext";

import styles from "./index.module.scss";

const RangeType = ({ title, value, handleOnChange, handleOnApply, showDatesTillToday = false }: IRangeType) => {
  const location = useLocation();

  const query: ILocationQueryParams = qs.parse(location.search, {
    parseNumbers: true,
    parseBooleans: true,
  });

  const { startDate, endDate, rangeType = "" } = query;

  const active = Base64.decode(rangeType);

  const startOfMonth = dayjs().startOf("month").unix();
  const today = dayjs().unix();

  return (
    <>
      <Radio value={value} size="s" onChange={handleOnChange} checked={active === value}>
        {title}
      </Radio>
      {active === value && (
        <div className={styles.dates}>
          <DateRange
            startDate={Number(startDate) ? startDate : startOfMonth}
            endDate={Number(endDate) ? endDate : today}
            handleOnApply={handleOnApply}
            showDatesTillToday={showDatesTillToday}
          />
        </div>
      )}
    </>
  );
};

const PaymenRunType = ({ handleOnChange, paymentPeriods = [], loading }: IPaymenRunType) => {
  const history = useHistory();
  const location = useLocation();

  const { settings: paymentRunSettingEnabled } = useContext(PermissionAndSettingsContext);

  const query: ILocationQueryParams = qs.parse(location.search, {
    parseNumbers: true,
    parseBooleans: true,
  });

  const { rangeType = "", cycleId = "" } = query;

  const active = Base64.decode(rangeType);
  const activeCycle = Base64.decode(cycleId);

  const findDefaultValue = () => {
    const isQueryExist = paymentPeriods?.find((cycle) => cycle?.id === activeCycle);

    return isQueryExist?.label;
  };

  return (
    <>
      <Radio
        value={EXPORT_DATE_RANGE_TYPES.PAYMENT_RUN}
        size="s"
        onChange={handleOnChange}
        checked={active === EXPORT_DATE_RANGE_TYPES.PAYMENT_RUN}
        disabled={!paymentRunSettingEnabled}
      >
        By Payment Run period
      </Radio>
      {active === EXPORT_DATE_RANGE_TYPES.PAYMENT_RUN && paymentRunSettingEnabled && (
        <Select
          className={styles.payment}
          defaultValue={findDefaultValue()}
          placeholder="Payment Run"
          getPopupContainer={(triggerNode) => triggerNode.parentElement}
          onSelect={(id) => {
            history.push({
              pathname: location.pathname,
              search: qs.stringify({
                ...query,
                [BILLS_QUERY_PARAMS.CYCLE_ID]: Base64.encodeURI(id),
                [BILLS_QUERY_PARAMS.MARK_AS_PAID]: undefined,
              }),
            });
          }}
          loading={loading}
        >
          {paymentPeriods.map((data) => {
            return (
              <Select.Option key={data.id} value={data.id} id={data.id}>
                <Typography size="caption-m" variant="body-content" className={styles.cycleOption}>
                  {data.label}
                </Typography>
              </Select.Option>
            );
          })}
        </Select>
      )}
    </>
  );
};

const DateType = ({ paymentPeriods, loadingPeriods }: IDateTypeProps) => {
  const history = useHistory();
  const location = useLocation();

  const query: ILocationQueryParams = qs.parse(location.search, {
    parseNumbers: true,
    parseBooleans: true,
  });

  const startOfMonth = dayjs().startOf("month").unix();
  const today = dayjs().unix();

  const handleOnApply = (date: string, key: string) => {
    history.push({
      pathname: location.pathname,
      search: qs.stringify({
        ...query,
        [key]: date !== null ? dayjs(date).unix() : undefined,
      }),
    });
  };

  const handleOnChange = (value: string) => {
    history.push({
      pathname: location.pathname,
      search: qs.stringify({
        ...query,
        [BILLS_QUERY_PARAMS.RANGE_TYPE]: Base64.encodeURI(value),
        ...(value === EXPORT_DATE_RANGE_TYPES.PAYMENT_RUN
          ? {
              [BILLS_QUERY_PARAMS.CYCLE_ID]: Base64.encodeURI(paymentPeriods[1].id ?? 0),
            }
          : {
              [BILLS_QUERY_PARAMS.CYCLE_ID]: undefined,
              [BILLS_QUERY_PARAMS.MARK_AS_PAID]: undefined,
            }),
        ...(value === EXPORT_DATE_RANGE_TYPES.DUE_DATES || value === EXPORT_DATE_RANGE_TYPES.CREATION_DATES
          ? {
              [BILLS_QUERY_PARAMS.START_DATE]: startOfMonth,
              [BILLS_QUERY_PARAMS.END_DATE]: today,
            }
          : {
              [BILLS_QUERY_PARAMS.START_DATE]: undefined,
              [BILLS_QUERY_PARAMS.END_DATE]: undefined,
            }),
      }),
    });
  };

  return (
    <>
      <Typography size="m" variant="body-content" tag="h4">
        Select date range
      </Typography>
      <PaymenRunType handleOnChange={handleOnChange} loading={loadingPeriods} paymentPeriods={paymentPeriods} />
      <RangeType
        title="By bill due dates"
        value={EXPORT_DATE_RANGE_TYPES.DUE_DATES}
        handleOnChange={handleOnChange}
        handleOnApply={handleOnApply}
      />
      <RangeType
        title="By bill creation dates"
        value={EXPORT_DATE_RANGE_TYPES.CREATION_DATES}
        handleOnChange={handleOnChange}
        handleOnApply={handleOnApply}
        showDatesTillToday
      />
    </>
  );
};

export default DateType;
