import React, { useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import DataTable, { ActionsBar, Table, BulkActionType, DATA_TABLE_SIZE_TYPES, IActionItem } from "Modules/DS/DataTable";
import { ESidePanelButtonType } from "Modules/DS/SidePanel";
import { ISelectContext, SelectContext } from "Modules/DS/Select";
import { TOASTER_SIZE_TYPE, TOASTER_STATUS_TYPE } from "Modules/DS/Toaster/types.d";
import Toaster from "Modules/DS/Toaster";
import { IToasterProps } from "Views/Accounting/Settings/@types";
import { fetchAccountingWorkflow } from "Redux/Reducers/AccountingWorkflow/accountingWorkflow.slice";
import { findTransactionInXero } from "Redux/DataCalls/AccountingWorkflow.api";
import { trackEvent } from "utility/analytics";
import { AlreadyReconciledMessage } from "constants/Integration.constant";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import { useAccountingTransaction } from "Views/Transaction/Context";
import Info from "Views/Transaction/All/Info";
import { PARTNER_TYPE } from "Views/Accounting/Settings/helper/constant";

import { EWorkflowApiStatus, ITabConfigComponent, ITransaction } from "../type";
import { columnData } from "../ColumnData";
import TransactionSidePanel from "../SidePanel";
import EmptyState from "../EmptyState";
import { PAGE_SIZE, SCROLL_DELAY, TAB_NAME } from "../constant/constant";
import { useFilter } from "../hooks/useFilter";
import { useUpdateTransaction } from "../hooks/useUpdateTransaction";
import { useBulkEdit } from "../hooks/useBulkEdit";
import Popover from "../BulkAction/Popover";
import { useFormContext } from "../context/FormContext";
import { defineSize } from "../PendingSync/helper";
import {
  getRelatedTransaction,
  generateActions,
  paginationProps,
  allowToUpdateTransaction,
  getTotalAmount,
} from "../PendingReview/helper";
import { ANALYTIC_EVENT } from "../Analytic";
import { detectChange } from "./helper";
import WarningModal from "../WarningModal";
import InitialTagContext from "../context/InitialTagContext";

import styles from "./styles.module.scss";

const Completed: React.FC<ITabConfigComponent> = ({ activeKey, connectedUsingExpenseIntegration }) => {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [page, setPage] = useState(0);
  const [selectedIds, setSelectedIds] = useState([]);
  const [record, setRecord] = useState<ITransaction>({} as ITransaction);
  const [relatedTransaction, setRelatedTransaction] = useState<ITransaction | null>(null);
  const [showMoreActionMenu, setShowMoreActionMenu] = useState(false);
  const [initialTags, setInitialTags] = useState([]);
  const initialTagsMemoizedValue = useMemo(
    () => ({
      initialTags,
      setInitialTags,
    }),
    [initialTags]
  );
  const [resyncWarning, setResyncWarning] = useState({
    message: "",
    show: false,
  });
  const [loadingMoreactionMenu, setLoadingMoreactionMenu] = useState(false);
  const [toaster, setToaster] = useState<IToasterProps>({
    show: false,
    message: "",
    type: TOASTER_STATUS_TYPE.SUCCESS,
  });
  const { select, closeSelect } = useContext<ISelectContext>(SelectContext);
  const { activePageDetails, setActivePageDetails } = useAccountingTransaction();

  const { loadingSaveTransaction, updateTransaction } = useUpdateTransaction(record, setRecord);
  const status = EWorkflowApiStatus.COMPLETED;
  const { filters, onApplyFilter, resetFilter } = useFilter(status);
  const { data, loading, accountingPartner } = useSelector((state: any) => ({
    ...state.accountingWorkflowReducer,
    accountingPartner: state.accountingPartnerAuthReducer.data,
  }));
  const accountingPartnerName = accountingPartner?.payload?.partnerName;

  const shouldShowLoading = loading || data[status].transactions[0]?.id === "Mocked_transaction_0";

  const { tags, formValue, selectedExpense, expenseNotFound, setExpenseNotFound } = useFormContext();
  const fetchData = (params = {}) =>
    dispatch(fetchAccountingWorkflow({ filter: filters, status, limit: PAGE_SIZE, ...params }));

  const { editCategoryButton, showErrorToaster } = useBulkEdit(selectedIds, setToaster, setSelectedIds, {
    transactions: data[status].transactions,
    onSuccess() {
      fetchData();
      trackEvent(ANALYTIC_EVENT.BULK_EDIT_CATEGORY, { filters, section: status });
    },
  });
  const [accountingPartnerLink, setAccountingPartnerLink] = useState<string | null>(null);

  useEffect(() => {
    if (activeKey === TAB_NAME.COMPLETED) {
      setActivePageDetails({
        ...activePageDetails,
        name: activeKey,
        accSubTabStatus: status,
        accountingFilters: filters,
      });
    }
  }, [activeKey, status, filters]);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (activeKey && activeKey !== TAB_NAME.COMPLETED) {
      clearSelection();
    }
  }, [activeKey]);

  const openTransactionDetail = (record: ITransaction) => {
    if (select.show) {
      closeSelect();
      return;
    }
    if (shouldShowLoading) return;

    setRelatedTransaction(getRelatedTransaction(data[status].transactions, record));
    setVisible(true);
    setRecord(record);
    setAccountingPartnerLink(null);

    if (connectedUsingExpenseIntegration && accountingPartnerName !== PARTNER_TYPE.NETSUITE) {
      findTransactionInXero(record.id).then(({ data }) => {
        if (data.status === HTTP_STATUS_CODE.OK && data?.payload?.link) {
          setAccountingPartnerLink(data.payload.link);
        }
      });
    }
  };

  const clearSelection = () => {
    setPage(0);
    setSelectedIds([]);
  };

  const onPageChange = (newPage: number) => {
    const newPageIndex = newPage - 1;
    let sortData = data[status].transactions[PAGE_SIZE - 1];
    const moveToPreviousPage = page > newPageIndex;

    if (moveToPreviousPage) {
      sortData = data[status].transactions[0];
    }

    setPage(newPageIndex);
    setSelectedIds([]);
    fetchData({
      sortData: sortData?.sort,
      moveToPreviousPage,
      skipCount: true,
    });
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }, SCROLL_DELAY);
  };

  const tableProps = {
    total: data.transactionCount?.[status] || 0,
    pageNum: page,
    pageSize: PAGE_SIZE,
    onPageChange: onPageChange,
    dataSource: data[status].transactions,
    emptyState: <EmptyState type={status} />,
    rowAction: openTransactionDetail,
    paginationOnTop: true,
    paginationProps: paginationProps(PAGE_SIZE, page),
    scrollHorizontal: "fit-content",
    bulkAction: {
      data: selectedIds,
      dataName: "transaction",
      clearSelection: () => setSelectedIds([]),
      supportingInfo: <Info amount={getTotalAmount(data[status].transactions, selectedIds)} />,
      type: BulkActionType.Action_With_Menu,
      button: editCategoryButton,
      showMoreActionMenu,
      setShowMoreActionMenu,
      loadingMoreactionMenu,
      size: defineSize(tags, true),
      moreActionMenu: (
        <Popover
          onError={showErrorToaster}
          onProcess={() => {
            setLoadingMoreactionMenu(true);
          }}
          onFinish={() => setLoadingMoreactionMenu(false)}
          onSuccess={(message, type) => {
            trackEvent(type === "tax" ? ANALYTIC_EVENT.BULK_EDIT_TAX : ANALYTIC_EVENT.BULK_EDIT_TRANSACTION_TAGS, {
              filters,
              section: status,
            });
            setSelectedIds([]);
            fetchData();
            setToaster({
              show: true,
              message,
              type: TOASTER_STATUS_TYPE.SUCCESS,
            });
          }}
          showMoreActionmenu={showMoreActionMenu}
          setShowMoreActionMenu={setShowMoreActionMenu}
          selectedIds={selectedIds}
          status={status}
        />
      ),
    },
    rowSelection: !shouldShowLoading && {
      preserveSelectedRowKeys: true,
      selectedRowKeys: selectedIds,
      onChange: setSelectedIds,
      onSelectAll: (selectAll) => {
        setSelectedIds(!selectAll ? [] : data[status].transactions.map((item) => item.id));
      },
    },
  };

  if (tableProps.dataSource.length === 0) {
    // Prevent the scroll to be appear when there are no data.
    delete tableProps.scrollHorizontal;
  }

  const actions: IActionItem[] = generateActions({
    filter: filters,
    onApplyFilter,
    resetFilter,
    clearSelection,
    status: () => status,
  });

  const resetSyncWarning = () => {
    setResyncWarning({ message: "", show: false });
  };

  const handleUpdateTransaction = async () => {
    try {
      await updateTransaction();
      setToaster({
        show: true,
        message: "1 transaction successfully saved.",
        type: TOASTER_STATUS_TYPE.SUCCESS,
      });
      trackEvent(ANALYTIC_EVENT.REDO_SYNC_IN_COMPLETE_TAB, { filters });
      fetchData();
    } catch (error) {
      showErrorToaster(error);
    } finally {
      setVisible(false);
      resetSyncWarning();
      setSelectedIds([]);
      setExpenseNotFound(false);
    }
  };

  const buttons = [
    {
      id: "SIDE-PANEL-BUTTON-001",
      type: ESidePanelButtonType.PRIMARY,
      disabled: loadingSaveTransaction,
      enabled: allowToUpdateTransaction(record?.transactionType),
      text: connectedUsingExpenseIntegration ? "Save & Re-Sync" : "Save",
      loading: loadingSaveTransaction,
      action: async () => {
        const initialValue = expenseNotFound ? { merchant: record.merchant, tags: initialTags } : selectedExpense;
        const changesKey = detectChange(initialValue, formValue);
        const onlyUpdateAttachment = changesKey.length === 1 && changesKey[0] === "receipts";

        if (changesKey.length !== 0 && !onlyUpdateAttachment && connectedUsingExpenseIntegration) {
          const updateReceipt = changesKey.includes("receipts");

          const message = updateReceipt
            ? AlreadyReconciledMessage.withReceipt(accountingPartnerName)
            : AlreadyReconciledMessage.withoutReceipt(accountingPartnerName);
          setResyncWarning({
            message,
            show: true,
          });
          return;
        }
        await handleUpdateTransaction();
      },
    },
  ];

  const findBannerMessage = () => {
    if (!accountingPartnerLink) {
      return null;
    }
    return (
      <span>
        Transaction synced to {accountingPartnerName}.{" "}
        <a href={accountingPartnerLink} target="_blank" rel="noreferrer" className={styles.highlight}>
          See transaction on {accountingPartnerName}
        </a>
      </span>
    );
  };

  return (
    <InitialTagContext.Provider value={initialTagsMemoizedValue}>
      <DataTable className={styles["completed-workflow"]} size={DATA_TABLE_SIZE_TYPES.LARGE}>
        <ActionsBar>{actions}</ActionsBar>
        <Table<ITransaction> {...tableProps}>{columnData(status, shouldShowLoading, setToaster, fetchData)}</Table>
        <TransactionSidePanel
          type={record.transactionType}
          visible={visible}
          setVisible={setVisible}
          buttons={buttons.filter((button) => button.enabled)}
          record={record}
          currentStatusId={record.currentStatusId}
          withSyncDetails
          relatedTransaction={relatedTransaction}
          onClickRelatedTransaction={() => {
            openTransactionDetail(relatedTransaction);
          }}
          customBannerMessage={findBannerMessage()}
          ignoreRequiredCheck
          activeTab={EWorkflowApiStatus.COMPLETED}
        />

        <WarningModal
          visible={resyncWarning.show}
          onClose={resetSyncWarning}
          loading={loadingSaveTransaction}
          message={resyncWarning.message}
          handleSync={handleUpdateTransaction}
          buttonText="Yes, Save & Re-Sync"
          title="Save & re-sync this transaction anyway?"
        />

        <Toaster
          size={TOASTER_SIZE_TYPE.M}
          visible={toaster.show}
          status={toaster.type}
          message={toaster.message}
          onClose={() => setToaster({ ...toaster, show: false })}
        />
      </DataTable>
    </InitialTagContext.Provider>
  );
};

export default Completed;
