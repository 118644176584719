import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Popover } from "antd";

import {
  fetchAccountingTag,
  setNewTags,
  initialTagSetup,
  fetchAccountingTagSuccess,
} from "Redux/Actions/AccountingTag/accountingTagAction";
import { AccountingTagsContext } from "./context/AccountingTagsContext";

import TagInput from "./components/TagInput";
import TagOptionWrapper from "./components/TagOptionWrapper";

import "./styles.scss";

const AccountingTags = ({
  onChange,
  initialTags,
  label = "Tags",
  disabled = false,
  customLabel,
  customIcon,
  resetTagsOnUnmount = true,
  hasMissingField = false,
  onOpenChange = () => {},
}) => {
  const [openSubMenu, setOpenSubMenu] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [selectedTag, setSelectedTag] = useState("");

  const { data, error, loading } = useSelector((state) => state.accountingTagReducer);
  const dispatch = useDispatch();

  const { tags = [] } = data.payload || {};

  useEffect(() => {
    if (tags.length === 0) {
      dispatch(fetchAccountingTag(initialTags));
    }

    return () => {
      // This will reset to be empty the tags when the component is updated or unmount
      resetTagsOnUnmount && dispatch(setNewTags([]));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (tags.length !== 0 && initialTags) {
      const payload = {
        ...data,
        payload: {
          tags: initialTagSetup(tags, initialTags),
        },
      };
      dispatch(fetchAccountingTagSuccess(payload));
    }
  }, [initialTags]);

  const selectChildren = (selectedTagGroup, selectedTagOption) => {
    const newTags = tags.map((tagGroup) => {
      const newTagGroup = { ...tagGroup };
      if (newTagGroup.id === selectedTagGroup) {
        if (newTagGroup.selectedChildren === selectedTagOption) {
          newTagGroup.selectedChildren = false;
        } else {
          newTagGroup.selectedChildren = selectedTagOption;
        }
      }
      return newTagGroup;
    });

    onChange(findSelectedChildren(newTags));

    dispatch(setNewTags(newTags));
  };

  const findSelectedChildren = (selectedTags) => {
    return selectedTags
      .filter((tagGroup) => tagGroup.selectedChildren)
      .map((tagGroup) => {
        const { value } = tagGroup.values.find((tagOption) => tagOption.id === tagGroup.selectedChildren);

        return { label: tagGroup.label, value, tagGroup };
      });
  };

  const selectedChildren = findSelectedChildren(tags);

  const deleteTag = (deletedTagGroup) => {
    const newTags = tags.map((tagGroup) => {
      const newTagGroup = { ...tagGroup };
      if (newTagGroup.id === deletedTagGroup) {
        newTagGroup.selectedChildren = false;
      }
      return newTagGroup;
    });

    onChange(findSelectedChildren(newTags));
    dispatch(setNewTags(newTags));
  };

  const contextValue = {
    openDropdown,
    setOpenDropdown,
    openSubMenu,
    setOpenSubMenu,
    data: tags,
    selectedTag,
    setSelectedTag,
    selectChildren,
    selectedChildren,
    deleteTag,
    label,
  };

  if (error || loading || (!loading && tags.length === 0)) {
    return null;
  }
  return (
    <AccountingTagsContext.Provider value={contextValue}>
      <Popover
        visible={!disabled && openDropdown}
        placement="bottomRight"
        overlayClassName="accounting-tags"
        getPopupContainer={(triggerNode) => triggerNode.parentElement}
        content={TagOptionWrapper}
      >
        <TagInput
          editable={!disabled}
          customLabel={customLabel}
          customIcon={customIcon}
          onOpenChange={!disabled ? onOpenChange : () => {}}
          hasMissingField={hasMissingField}
        />
      </Popover>
    </AccountingTagsContext.Provider>
  );
};

export default AccountingTags;
