import React, { useContext, useMemo, useState } from "react";
import qs from "query-string";
import { Base64 } from "js-base64";
import { AxiosResponse } from "axios";
import { useHistory, useLocation } from "react-router-dom";
import { TActionButton } from "@spenmo/splice/lib/shared/types";
import { ISidepanelProps } from "@spenmo/splice/lib/components/SidePanel";
import { Tooltip, Checkbox, Sidepanel, Tags, Typography } from "@spenmo/splice";

import { useMutableData } from "API/useData";

import { CASBIN_PAGES } from "Permission/Pages";
import { BILL_EXPORT_CSV } from "Permission/Actions";
import usePermissionCheck from "Permission/usePermissionCheck";

import {
  BILLS_MODAL_OPTIONS,
  BILLS_PAGE_OPERATIONS,
  BILLS_QUERY_PARAMS,
  EXPORT_DATE_RANGE_TYPES,
} from "Views/Bills/V2/constants";
import { API_URL } from "Views/Bills/V2/constants";
import { SaaSConfig } from "Views/Bills/V2/BillForm/type";
import { ILocationQueryParams } from "Views/Bills/V2/@types";
import useExportCSV from "Views/Bills/V2/hooks/useExportCsv";
import { IPaymentPeriodResponse } from "Views/Bills/V2/PaymentRun/types";
import { GENERIC_PAYMENT_CSV } from "Views/Bills/V2/hooks/useExportOptionsList";
import { PermissionAndSettingsContext } from "Views/Bills/V2/context/BillContext";
import {
  Payment_Run_API_URL,
  initialPeriodResponse,
} from "Views/Bills/V2/PaymentRun/constant";

import ExportBills from "./Container";

import styles from "./index.module.scss";

const permissionParam = [
  {
    object: CASBIN_PAGES.BILL_EXPORT_CSV,
    action: BILL_EXPORT_CSV.MARK_AS_PAID_SAAS_PAYMENTS,
  },
  {
    object: CASBIN_PAGES.BILL_EXPORT_CSV,
    action: BILL_EXPORT_CSV.MARK_AS_PAID_SAAS,
  },
];

const ExportSidepanel = () => {
  const history = useHistory();
  const location = useLocation();

  const { data: saasConfigResponse } = useMutableData(API_URL.saasConfig);
  const saasConfig: SaaSConfig = useMemo(
    () => saasConfigResponse?.data?.payload,
    [saasConfigResponse?.data?.payload],
  );
  const isBillsPaymentEnabled = saasConfig?.isPaymentEnabled;

  const permissions = usePermissionCheck(permissionParam) as boolean[];

  const isMarkAsPaidAllowed = isBillsPaymentEnabled
    ? permissions?.[0]
    : permissions?.[1];

  const query: ILocationQueryParams = qs.parse(location.search, {
    parseNumbers: true,
    parseBooleans: true,
  });

  const {
    startDate,
    endDate,
    exportType = "",
    rangeType = "",
    cycleId = "",
    markAsPaid = false,
  } = query;

  const { exportCSV, exportPreview } = useExportCSV();

  const [exporting, setExporting] = useState<boolean>(false);

  const { settings: paymentRunSettingEnabled } = useContext(
    PermissionAndSettingsContext,
  );

  const { data, isValidating, isLoading } = useMutableData(
    paymentRunSettingEnabled ? Payment_Run_API_URL.paymentPeriods : null,
  );

  const response = data as AxiosResponse<IPaymentPeriodResponse>;
  const { payload: paymentPeriodsList } =
    response?.data ?? initialPeriodResponse.data;

  const paymentPeriods = paymentPeriodsList?.map(({ name, id }) => ({
    id: `${id}`,
    label: name,
  }));

  const handleOnClose = () => {
    history.push({
      pathname: location.pathname.replace(
        `/${BILLS_PAGE_OPERATIONS.EXPORT}`,
        "",
      ),
      search: qs.stringify({
        ...query,
        [BILLS_QUERY_PARAMS.START_DATE]: undefined,
        [BILLS_QUERY_PARAMS.END_DATE]: undefined,
        [BILLS_QUERY_PARAMS.EXPORT_TYPE]: undefined,
        [BILLS_QUERY_PARAMS.RANGE_TYPE]: undefined,
        [BILLS_QUERY_PARAMS.MARK_AS_PAID]: undefined,
        [BILLS_QUERY_PARAMS.CYCLE_ID]: undefined,
      }),
    });
  };

  const periodId = Base64.decode(cycleId);
  const disabled =
    Base64.decode(rangeType) === EXPORT_DATE_RANGE_TYPES.PAYMENT_RUN
      ? isNaN(periodId)
      : isNaN(startDate) || isNaN(endDate);

  const eligibleToPaid = Number(periodId)
    ? paymentPeriodsList.find(
        (period) => Number(period.id) === Number(periodId),
      )?.eligibleToPaid
    : false;

  const downloadData: TActionButton = {
    title: "Download",
    variant: "primary",
    disabled: disabled || !exportType,
    action: () => {
      setExporting(true);

      if (markAsPaid) {
        exportPreview()
          .then(
            ({
              approvedBillsCount,
              pendingBillsCount,
              isCSVDownloadable,
              currentPaymentCycle,
            }) => {
              history.push({
                pathname: `${location.pathname}/${BILLS_PAGE_OPERATIONS.MODAL}/${BILLS_MODAL_OPTIONS.MARK_APPROVED_BILLS}`,
                search: qs.stringify({
                  ...query,
                  [BILLS_QUERY_PARAMS.APPROVED_BILLS]: approvedBillsCount,
                  [BILLS_QUERY_PARAMS.PENDING_BILLS]: pendingBillsCount,
                  [BILLS_QUERY_PARAMS.IS_CSV_DOWNLOADABLE]: isCSVDownloadable,
                  [BILLS_QUERY_PARAMS.CURRENT_PAYMENT_CYCLE]:
                    currentPaymentCycle,
                }),
              });
            },
          )
          .finally(() => setExporting(false));
      } else {
        exportCSV()
          .then(() => handleOnClose())
          .finally(() => setExporting(false));
      }
    },
    loading: exporting,
  };

  const markAsPaidCheckbox = () => {
    return (
      <div className={styles.footer}>
        <Tooltip
          title={
            paymentRunSettingEnabled
              ? "Mark as paid can only be selected when exporting to a Payment CSV for the current Payment Run period"
              : "Mark as paid can only be selected when exporting to a Payment CSV"
          }
        >
          <Checkbox
            id={BILLS_QUERY_PARAMS.MARK_AS_PAID}
            checked={Boolean(markAsPaid)}
            disabled={
              paymentRunSettingEnabled
                ? disabled ||
                  !eligibleToPaid ||
                  Base64.decode(exportType) !== GENERIC_PAYMENT_CSV.id
                : disabled ||
                  Base64.decode(exportType) !== GENERIC_PAYMENT_CSV.id
            }
            onChange={() => {
              history.push({
                pathname: location.pathname,
                search: qs.stringify({
                  ...query,
                  [BILLS_QUERY_PARAMS.MARK_AS_PAID]: !markAsPaid,
                }),
              });
            }}
          />
        </Tooltip>
        <div className={styles.footer}>
          <Typography size="s" variant="body-content">
            Mark as paid for
          </Typography>
          <Tags.Progression
            hierarchy="default"
            label="Approved"
            variant="info"
          />
          <Typography size="s" variant="body-content">
            Bills
          </Typography>
        </div>
      </div>
    );
  };

  const props: ISidepanelProps = {
    showSidepanel: true,
    position: "right",
    transitionType: "rightToLeft",
    title: `Export`,
    footerAlignment: "end",
    footerStacking: "horizontal",
    onClose: handleOnClose,
    secondaryActionButton: downloadData,
    additionalFooterItem: isMarkAsPaidAllowed && markAsPaidCheckbox(),
    children: (
      <ExportBills
        paymentPeriods={paymentPeriods}
        loadingPeriods={isLoading || isValidating}
      />
    ),
  };

  return <Sidepanel {...props} />;
};

export default ExportSidepanel;
