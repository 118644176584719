import * as React from "react";
import { useCardDetailsContext } from "Views/Card/Details/index.context";
import CardV1 from "Views/Card/Details/Card/V1";
import CardV2 from "Views/Card/Details/Card/V2";
import PhysicalCard from "./Physical";
import { CARD_STATUS, CARD_TYPE } from "Views/Card/types";

/**
 * CardV1 -- Card request card
 * CardV2 -- Virtual card with show card number / cvv buttons
 * PhysicalCard -- Physical card with no show card number / cvv buttons
 */

const Card = () => {
  const { cardData } = useCardDetailsContext();
  const { isCardRequestDetails, isNotActivatedPhysicalCard, cardType, cardStatus } = cardData || {};
  if (isCardRequestDetails || isNotActivatedPhysicalCard || cardStatus === CARD_STATUS.IN_PROGRESS) {
    return <CardV1 />;
  } else if (cardType === CARD_TYPE.PHYSICAL) {
    return <PhysicalCard />;
  } else {
    return <CardV2 />;
  }
};

export default Card;
