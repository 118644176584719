import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import Search from "Modules/search";
import { getCardNumbersFunc, getCardOwnerNamesFunc, getCardRequestOwnerNamesFunc } from "Redux/Actions";
import { CARD_LIST_TABS } from "Views/Card/List/types";
import { initialFilter } from "../index.context";
import Filters from "./Components/Filters";
import { trackEvent } from "utility/analytics";
import { filterEvents } from "Views/Card/Analytics";

import "./index.scss";

type ICardFilterProps = {
  action: (filter: any) => void;
  showCardownerFilter: boolean;
  currentFilters: any;
  cardListActiveTab: CARD_LIST_TABS;
};

const CardFilter = (props: ICardFilterProps) => {
  const dispatch = useDispatch();
  const { action, currentFilters = {}, showCardownerFilter, cardListActiveTab } = props;

  React.useEffect(() => {
    dispatch(getCardNumbersFunc());
    dispatch(getCardOwnerNamesFunc());
    dispatch(getCardRequestOwnerNamesFunc());
  }, [dispatch]);

  const cardNumbers = useSelector((state: any) => {
    return state?.getCardNumbersReducer?.data?.payload?.card_numbers || [];
  });
  const cardOwnerNames = useSelector((state: any) => {
    return state?.getCardOwnerNamesReducer?.data?.payload?.card_owners || [];
  });

  const cardRequestOwnerNames = useSelector((state: any) => {
    return state?.getCardOwnerNamesReducer?.cardRequestOwnersData?.payload?.card_request_owners || [];
  });
  const teams = useSelector((state: any) => {
    return state?.teamList?.data?.payload?.teams || [];
  });
  const teamList = teams.map((team) => ({
    label: team?.team_name,
    value: team?.id,
  }));

  const handleAction = (key: string, value: string) => {
    switch (key) {
      case "ordering": {
        const ordering = value?.split(":");
        action({
          ...currentFilters,
          ordering: {
            order_col: ordering[0],
            order_dir: ordering[1],
          },
        });
        break;
      }
      case "team": {
        action({
          ...currentFilters,
          team_id: value,
        });
        break;
      }
      default: {
        if (value === null || (Array.isArray(value) && value?.length === 0)) {
          const newFilters = { ...currentFilters?.search_filters };
          delete newFilters[key];
          action({
            ...currentFilters,
            search_filters: newFilters,
          });
        } else {
          action({
            ...currentFilters,
            search_filters: {
              ...currentFilters?.search_filters,
              [key]: value,
            },
          });
        }
      }
    }
  };

  const clearAllFilter = () => {
    action(initialFilter);
  };

  return (
    <div className="card-filter">
      <Search
        className="card-filter__search"
        placeholder="Search Card Title/Holder/Number"
        changeAction={(value: string) => {
          trackEvent(filterEvents.SEARCH, { "input data": value });
          handleAction(cardListActiveTab === CARD_LIST_TABS.CARD_LIST ? "card_search" : "card_request_search", value);
        }}
        onChangeDelay={500}
      />
      <Filters
        action={handleAction}
        cardNumbers={cardNumbers}
        cardOwnerNames={
          cardListActiveTab === CARD_LIST_TABS.RECEIVED_CARD_REQUEST ? cardRequestOwnerNames : cardOwnerNames
        }
        teamList={teamList}
        showCardownerFilter={showCardownerFilter}
        cardListActiveTab={cardListActiveTab}
        currentFilters={currentFilters}
        clearAllFilter={clearAllFilter}
      />
    </div>
  );
};

export default CardFilter;
