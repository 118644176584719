export const TAX_STATUS = {
  ACTIVE: "active",
  INACTIVE: "inactive",
  ALL: "all",
};

export enum TAX_MAPPING_STATUS {
  OK = "ok",
  INCONSISTENT = "inconsistent",
}

export enum TAX_STATE_STATUS {
  OK = "ok",
  INCOMPLETE = "incomplete",
  NOT_CONNECTED = "not_connected",
}
