import React, { useContext } from "react";

import RichHeader from "Modules/DS/RichHeader";
import Button from "Modules/button";
import { IKYXModalContext } from "Views/KYX/@types";
import { KYXModalContext } from "Views/KYX/Context";
import { IMyInfoError, MyInfoErrorInfo } from "Views/KYX/Constants/MyInfoError";
import { KYB_STATUS, MY_INFO_ERROR_TYPE } from "Views/KYX/Constants";
import { LOADER_TYPES } from "Modules/DS/Loader/types";
import Loader from "Modules/DS/Loader";

import { RootState } from "Redux/ConfigureStore";
import { useAppSelector } from "hook";
import { useRedirectionHandler, useStartKYXManually } from "Views/KYX/hooks";
import { COMPANY_VERIFICATION } from "Route/KYX/paths";
import styles from "./index.module.scss";

interface IMyInfoErrorModal {
  fields: string;
  type: MY_INFO_ERROR_TYPE;
}

const ILoader = <Loader type={LOADER_TYPES.WHITE} />;

const MyInfoErrorModal: React.FC<IMyInfoErrorModal> = ({ fields, type }): JSX.Element => {
  const details: IMyInfoError = MyInfoErrorInfo[type];
  const redirect = useRedirectionHandler(COMPANY_VERIFICATION.COMPANY_INFORMATION);
  const kybStatus = useAppSelector((state: RootState) => state.preliminaryDataReducer?.data?.payload);
  const { resetModal }: IKYXModalContext = useContext(KYXModalContext);

  const { loading: manualLoader, startVerification } = useStartKYXManually(redirect);
  const fieldList = fields?.split(","); // This will convert the string into an array

  const getMissingFields = (list) => {
    return Array.isArray(list) && list?.map((field) => <li key={field}>{field}</li>);
  };

  const manualBtnAction = kybStatus?.kyb_status === KYB_STATUS.STARTED ? redirect : startVerification;

  return (
    <div className={styles.wrapper}>
      <RichHeader title={details.title} onClose={resetModal} />
      <div className={styles.content}>
        <p className={styles.text}>{details.info}</p>
        <ul className={styles.list}>{getMissingFields(fieldList)}</ul>
        <p className={styles.text}>{details.message}</p>
        {type === MY_INFO_ERROR_TYPE.ACRA ? (
          <div className={styles.button}>
            <a href="https://support.spenmo.com/hc/en-us/requests/new" target="_blank" rel="noreferrer noopener">
              {details.button}
            </a>
          </div>
        ) : (
          <Button className={styles.button} action={manualBtnAction} rounded>
            {manualLoader ? ILoader : details.button}
          </Button>
        )}
      </div>
    </div>
  );
};

export default MyInfoErrorModal;
