import { useCallback, useState } from "react";

const useLoadingToaster = (action) => {
  const [toasterDetails, setToasterDetails] = useState([]);
  const [loading, setLoading] = useState(false);

  const closeToaster = useCallback(
    (id) => {
      const remains = toasterDetails.filter((toaster) => toaster.id !== id);
      setToasterDetails(remains);
    },
    [toasterDetails]
  );

  const doAction = async (...args) => {
    setLoading(true);
    try {
      const response = await action(...args);

      return response;
    } catch (error) {
      const errors = error?.response?.data?.error || error?.response?.data?.payload?.errors;
      const toasterErrors = Array.isArray(errors)
        ? errors.map((error, index) => ({
            id: index,
            message: error.message,
          }))
        : [
            {
              id: errors.code,
              message: errors.message,
            },
          ];

      setToasterDetails(toasterErrors);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return [doAction, loading, toasterDetails, closeToaster];
};

export default useLoadingToaster;
