import { AxiosResponse } from "axios";
import React, { useEffect, useState } from "react";
import styles from "Views/ApprovalFlow/SelfApprovalSidePanel/ChangeHistory/index.module.scss";
import { ChangeLog, ChangeLogResponse } from "Views/ApprovalFlow/SelfApprovalSidePanel/type";
import { getChangelogHistory } from "Redux/DataCalls/ApprovalFlow.api";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import { Steps } from "antd";
import { dateTimeFormatter } from "utility";
import Icon from "Modules/icons";
import { chevronDownOrange, chevronUpOrange } from "assets/img";
import classNames from "classnames";

const ChangeLogHistory = ({ type }) => {
  const { Step } = Steps;
  const [changeLogs, setChangeLogs] = useState<ChangeLog[]>(null);
  const toggleExpand = (changeLogToExpand: ChangeLog) => {
    const updatedChangeLogs = changeLogs.map((changeLog) => {
      if (changeLog.id === changeLogToExpand.id) {
        return { ...changeLog, actionExpanded: !changeLog.actionExpanded };
      }
      return changeLog;
    });
    setChangeLogs(updatedChangeLogs);
  };

  const getChangeLogHistory = async () => {
    try {
      const {
        data: { status, payload },
      } = (await getChangelogHistory(type)) as AxiosResponse<ChangeLogResponse>;
      if (status === HTTP_STATUS_CODE.OK) {
        setChangeLogs(payload);
      }
    } catch (e) {}
  };

  useEffect(() => {
    getChangeLogHistory().catch((e) => e);
  }, []);

  return (
    <div>
      {changeLogs?.length > 0 && (
        <Steps progressDot className={styles["container"]} current={changeLogs.length - 1} direction="vertical">
          {changeLogs.map((changeLog) => (
            <Step
              className=""
              key={changeLog.id}
              description={
                <div key={`"log-${changeLog.id}`} className={styles["description"]}>
                  <div className={styles["title"]}>
                    <span className={styles["label"]}>On </span>
                    <span className={styles["value"]}>
                      {dateTimeFormatter(changeLog.createdAt).date} at {dateTimeFormatter(changeLog.createdAt).time}
                    </span>
                    <span className={styles["label"]}> by </span>
                    <span className={styles["value"]}>{changeLog.creator}</span>
                  </div>
                  {changeLog.change.length > 0 && (
                    <Icon
                      action={() => toggleExpand(changeLog)}
                      className={styles.arrow}
                      src={changeLog.actionExpanded ? chevronUpOrange : chevronDownOrange}
                      alt={"show details"}
                    />
                  )}
                  {changeLog.actionExpanded && (
                    <div className={styles["change-log-actions-container"]}>
                      {changeLog.change.map((changeItem, index) => {
                        return (
                          <div key={`change-log-id-${changeLog.id}`} className={styles["change-log-action"]}>
                            {index + 1}.
                            <span className={styles["change-log-action-owner"]}>{changeItem.displayName}</span>
                            {changeItem.employeeRole && (
                              <span className={styles["change-log-action-role"]}>{changeItem.employeeRole}</span>
                            )}
                            <span
                              className={classNames(styles["change-log-action-value"], {
                                [styles["added"]]: changeItem.action === "add",
                                [styles["removed"]]: changeItem.action === "removed",
                              })}
                            >
                              {changeItem.action === "add" ? "Added" : "Removed"}
                            </span>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              }
              title={changeLog.title}
            />
          ))}
        </Steps>
      )}
    </div>
  );
};

export default ChangeLogHistory;
