import React from "react";
import { Skeleton } from "antd";

import styles from "./index.module.scss";

export const InformationLoader: React.FC = (): React.ReactElement => {
  const NUM_OF_SECTIONS = 3;
  const NUM_OF_CONTENTS = 2;
  const NUM_OF_INPUTS = 3;

  const getInputSkeleton = (count: number): React.ReactNode => {
    return React.Children.toArray(
      Array.from({ length: count }, () => <Skeleton.Input className={styles.textbox} active size="large" />)
    );
  };

  const getContent = (count: number): React.ReactNode => {
    return React.Children.toArray(
      Array.from({ length: count }, () => <div className={styles.content}>{getInputSkeleton(NUM_OF_INPUTS)}</div>)
    );
  };

  const setSectionSkeleton = (count: number): React.ReactNode => {
    return (
      <div className={styles.container}>
        {React.Children.toArray(
          Array.from({ length: count }, () => (
            <div className={styles.section}>
              <Skeleton.Input className={styles.header} active size="large" />
              <div className={styles.alignInline}>{getContent(NUM_OF_CONTENTS)}</div>
            </div>
          ))
        )}
      </div>
    );
  };

  return <>{setSectionSkeleton(NUM_OF_SECTIONS)}</>;
};
