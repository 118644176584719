import React, { useState } from "react";
import { Skeleton } from "antd";
import cn from "classnames";
import { trackEvent } from "utility/analytics";
import { Typography } from "@spenmo/splice";
import { SpendingSummaryType } from "Views/SubscriptionManagement/@types";
import { SUBSCRIPTION_EVENT } from "Views/SubscriptionManagement/Analytic";
import { CurrencyText, Dropdown, Label } from "../components";

import styles from "./styles.module.scss";

export const UpcomingSpend = ({
  spendingSummaryData,
  isLoading,
}: {
  spendingSummaryData: SpendingSummaryType;
  isLoading: boolean;
}) => {
  const [month, setMonth] = useState<keyof SpendingSummaryType["upcomingSpend"]>("0_m");

  if (isLoading) {
    return (
      <div className={cn(styles.loadingContainer, styles.upcomingSpend)}>
        <div className={styles.upcomingInformation}>
          <Skeleton.Input size="small" active className={cn(styles.inputCell, styles.small)} />
          <Skeleton.Input size="small" active className={cn(styles.inputCell, styles.small)} />
        </div>
        <div className={cn(styles.contractInformation, styles.marginTop)}>
          <Skeleton.Input size="small" active className={cn(styles.inputCell, styles.small)} />
          <Skeleton.Input size="small" active className={cn(styles.inputCell, styles.small)} />
        </div>
      </div>
    );
  }

  return (
    <section className={styles.upcomingSpend}>
      <div className={styles.upcomingInformation}>
        <Label text="Upcoming spend">
          <CurrencyText
            amount={spendingSummaryData.upcomingSpend[month].spend}
            percentage={spendingSummaryData.upcomingSpend[month].variation}
          />
        </Label>
        <Dropdown
          options={[
            {
              label: "Next month",
              value: "0_m",
            },
            {
              label: "Next 3 months",
              value: "3_m",
            },
            {
              label: "Next 6 months",
              value: "6_m",
            },
            {
              label: "Next 12 months",
              value: "12_m",
            },
          ]}
          defaultValue={month}
          onChange={(value: keyof SpendingSummaryType["upcomingSpend"]) => {
            setMonth(value);
            trackEvent(SUBSCRIPTION_EVENT.INTERACTION_WITH_SUBSCRIPTION_ANALYTIC, {
              action_required_event_source: "Upcoming spend month dropdown",
            });
          }}
        />
      </div>
      <div className={styles.contractInformation}>
        <Label text="Active contracts" className={styles.count}>
          <Typography size="s" weight={600} variant="body-content">
            {spendingSummaryData.activeContract}
          </Typography>
        </Label>
        <Label text="Expired contracts" className={styles.count}>
          <Typography size="s" weight={600} variant="body-content">
            {spendingSummaryData.expiredContract}
          </Typography>
        </Label>
      </div>
    </section>
  );
};
