import React from "react";

import { InputCell } from "Views/Transaction/Shimmer";
import Chip from "Modules/DS/Chip";

import { StatusToChipConfig } from "Views/Transaction/Cards/Columns/Status/Config";

import { IColumnData } from "Modules/DS/DataTable";
import {
  IGetCardsTrxnsData,
  GET_CARDS_TRXNS_DATA_PARAMS,
  CARDS_TRXNS_TABLE_COLUMNS,
  CARD_TXN_STATUS,
} from "Views/Transaction/@types";

const TrxnStatus = ({ value }: { value: CARD_TXN_STATUS }) => {
  const config = StatusToChipConfig?.[value];
  return <InputCell>{config ? <Chip.Status {...config} /> : <p>N/A</p>}</InputCell>;
};

export const status = (): IColumnData<IGetCardsTrxnsData>["column"] => ({
  title: CARDS_TRXNS_TABLE_COLUMNS.STATUS,
  key: GET_CARDS_TRXNS_DATA_PARAMS.STATUS,
  dataIndex: GET_CARDS_TRXNS_DATA_PARAMS.STATUS,
  align: "left",
  width: 204,
  render: (value: CARD_TXN_STATUS): React.ReactNode => <TrxnStatus value={value} />,
});
