import * as React from "react";
import { Modal } from "antd";
import { modalCloseIcon } from "assets/img";

import styles from "Views/Card/Details/Modal/index.module.scss";

interface CardDetailsModal {
  title: string;
  children: React.ReactNode;
  onClose: () => void;
}

const CardRequest = ({ children, title, onClose }: CardDetailsModal) => {
  return (
    <Modal
      visible
      title={title}
      footer={null}
      maskClosable={false}
      className={styles["card-details-modal"]}
      width={534}
      onCancel={onClose}
      closeIcon={<img src={modalCloseIcon} alt="close icon" />}
    >
      {children}
    </Modal>
  );
};

export default CardRequest;
