import React from "react";
import { IGuidelinesDocumentProps } from "Views/KYX/@types";
import classNames from "classnames";
import Icon from "Modules/icons";

import styles from "./index.module.scss";

const GuidelinesDocument: React.FC<IGuidelinesDocumentProps> = ({
  instructions,
  documents,
}: IGuidelinesDocumentProps): React.ReactElement => {
  const getInstructions = (): any => {
    return React.Children.toArray(instructions.map((item) => <li className={styles.text}>{item}</li>));
  };

  const getDocumentsList = (): any => {
    return React.Children.toArray(
      documents?.map((doc) => (
        <div className={classNames(styles.documents, styles[doc.state])}>
          <img src={doc.image.toString()} alt="document" width="fit-content" height="fit-content" />
          <Icon className={styles.icon} src={doc.icon} />
        </div>
      ))
    );
  };

  return (
    <>
      <ul className={styles.list}>{getInstructions()}</ul>
      <div className={styles.documentsList}>{getDocumentsList()}</div>
    </>
  );
};

export default GuidelinesDocument;
