import { IDocumentsInfo } from "Views/KYX/@types";

import { KYX_ORG_COUNTRY_LIST, KYB_SOURCE } from "Views/KYX/Constants";

import { IDR_MANUAL } from "./IDR";
import { SGD_MANUAL, SGD_MY_INFO } from "./SGD";

export const DOCUMENTS_INFO = (): {
  [KYX_ORG_COUNTRY_LIST.SGD]: {
    [KYB_SOURCE.MANUAL]: IDocumentsInfo;
    [KYB_SOURCE.AUTOMATED]: IDocumentsInfo;
  };
  [KYX_ORG_COUNTRY_LIST.IDR]: {
    [KYB_SOURCE.MANUAL]: IDocumentsInfo;
  };
} => ({
  [KYX_ORG_COUNTRY_LIST.SGD]: {
    [KYB_SOURCE.MANUAL]: SGD_MANUAL,
    [KYB_SOURCE.AUTOMATED]: SGD_MY_INFO,
  },
  [KYX_ORG_COUNTRY_LIST.IDR]: {
    [KYB_SOURCE.MANUAL]: IDR_MANUAL,
  },
});
