import React from "react";
import classNames from "classnames";
import Header from "Views/Home/SaasOnly/Header";
import PendingActions from "Views/Home/Components/PendingActions";
import LimitModelExpenseCarousel from "Views/Home/Components/LimitModelExpenseCarousel";
import WalletModelExpenseCarousel from "Views/Home/Components/WalletModelExpenseCarousel";
import { Row } from "Splice/Grid";

import styles from "Views/Home/SaasOnly/index.module.scss";

interface IProps {
  userName: string;
  isCardVendorNone: boolean;
  isLimitModel: boolean;
}

const SaasOnlyNonAdmin: React.FC<IProps> = ({ userName, isCardVendorNone, isLimitModel }: IProps) => {
  return (
    <Row className={classNames(styles.home, styles.nonAdmin)} column={24} offset={120} gutter={[24, 24]}>
      <Header userName={userName} />
      {isLimitModel ? <LimitModelExpenseCarousel /> : <WalletModelExpenseCarousel />}

      <div className={styles.pendingActions}>
        <PendingActions isModular={isCardVendorNone} />
      </div>
    </Row>
  );
};

export default SaasOnlyNonAdmin;
