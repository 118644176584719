import * as React from "react";
import { Skeleton } from "antd";

import styles from "Views/Card/Details/Info/Tabs/History/index.module.scss";

export default function CardHistoryLoading() {
  return (
    <div>
      {Array.from({ length: 5 }).map((_, index) => (
        <div key={index} data-testid="shimmer">
          <Skeleton.Input className={styles["card-date-shimmer"]} active />
          <div className={styles["card-details__shimmer-container"]}>
            <Skeleton.Input className={styles["card-merchant-shimmer"]} active />
            <Skeleton.Input className={styles["card-amount-shimmer"]} active />
          </div>
        </div>
      ))}
    </div>
  );
}
