import React from "react";
import classNames from "classnames";
import styles from "./index.module.scss";
import { currencyFormatterWithFractions } from "utility";
import { getCookieValue, cookieNames } from "utility/CookieHelper";
import { CARD_LIMIT_TYPES } from "Views/Card/types";
import { CARD_LIMITS_LABELS } from "Views/Card/Components/SpendingLimit/index.constants";

interface ISpendingLimits {
  limit: string;
  type: CARD_LIMIT_TYPES;
  isLimitSet: boolean;
}

const SpendingLimits = ({ limit, type, isLimitSet }: ISpendingLimits) => {
  const currencyCode = getCookieValue(cookieNames.CURRENCY_CODE);
  const containerClassName = classNames(styles["spending-limits__amount"], {
    [styles["spending-limits__amount--medium"]]: isLimitSet && type === CARD_LIMIT_TYPES.unlimited,
    [styles["spending-limits__amount--small"]]: !isLimitSet,
  });
  const getHeaderText = () => {
    if (type === CARD_LIMIT_TYPES.unlimited && isLimitSet) {
      return CARD_LIMITS_LABELS[type];
    } else {
      return isLimitSet ? currencyFormatterWithFractions(limit, currencyCode, 2) : "Not Added";
    }
  };
  return (
    <article className={styles["spending-limits"]}>
      <p className={containerClassName}>{getHeaderText()}</p>
      {type !== CARD_LIMIT_TYPES.unlimited && (
        <p className={styles["spending-limits__duration"]}>{CARD_LIMITS_LABELS[type]}</p>
      )}
    </article>
  );
};
export default SpendingLimits;
