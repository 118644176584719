import CountrySelect from "Modules/CountrySelect";
import Banner from "Modules/DS/Banner";
import DynamicForm from "Modules/DynamicForm";
import Icon from "Modules/icons";
import OCRTooltip from "Modules/ocrTooltip";
import { RootState } from "Redux/ConfigureStore";
import {
  checkVendorNameAvailability,
  getListCountry,
  getListCurrency,
  getVendorDetail,
  getVendorList,
} from "Redux/DataCalls/Disbursement.api";
import LoaderIcon from "Views/State/Loading/LoaderIcon";
import { BillFormTypes } from "Views/UploadInvoice/EditBillModal/types.d";
import { DUPLICATE_VENDOR_NAME_ERROR_MESSAGE } from "Views/UploadInvoice/const";
import { Button, Divider, Form, Input, Select } from "antd";
import { FormInstance } from "antd/lib/form";
import { dataTableNoRecordFound, greyCloseIcon } from "assets/img";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { debounce } from "utility";
import useLoading from "utility/useLoading";
import style from "./VendorSection.module.scss";
import {
  Country,
  Currency,
  OCRData,
  SelectVendorListItem,
  VendorDetail,
  VendorDetailWithID,
  VendorListItem,
} from "./types";

export interface VendorSectionProps {
  defaultValue?: {
    beneficiaryName: string;
    billID?: string;
    countryCode: string;
    currencyCode: string;
    dynamicFields: VendorDetail["dynamicFields"];
    organisationID: string;
    recipientEmail: string[];
    vendorID?: string;
    vendorName: string;
    isVendorLinked: boolean;
  };
  form: FormInstance;
  formOnChangeCount: number;
  noSelectVendor?: boolean;
  ocrData?: OCRData;
  onChangeCountry: (code: string, name: string) => void;
  onChangeCurrency: (code: string) => void;
  onEditVendor?: (isEdit: boolean) => void;
  onNewVendor?: (isNew: boolean) => void;
  onSelectVendor?: (vendor: VendorDetailWithID) => void;
  paymentCurrency: string;
  recipientBankCountryCode: string;
  trackView: (fieldName: string, value?: any) => void;
  trackEdit: (fieldName: string, value?: any) => void;
  billFormType: BillFormTypes;
}

enum EditBillVendorState {
  newVendor = "newVendor",
  updatedVendor = "updatedVendor",
  uptodateVendor = "uptodateVendor",
}

const VendorSection = ({
  defaultValue,
  form,
  formOnChangeCount,
  noSelectVendor,
  ocrData,
  onChangeCountry,
  onChangeCurrency,
  onEditVendor,
  onNewVendor,
  onSelectVendor,
  paymentCurrency,
  recipientBankCountryCode,
  trackView,
  trackEdit,
  billFormType,
}: VendorSectionProps) => {
  const [vendorList, setVendorList] = useState<VendorListItem[]>([]);
  const [vendorId, setVendorId] = useState<string>();
  const [vendorDetail, setVendorDetail] = useState<VendorDetailWithID>(null);
  const [currencyList, setCurrencyList] = useState<Currency[]>([]);
  const [countryList, setCountryList] = useState<Country[]>([]);
  const [isNew, setIsNew] = useState(false);
  const [isCurrencyChangedManually, setIsCurrencyChangedManually] = useState(false);
  const [isCountryChangedManually, setIsCountryChangedManually] = useState(false);
  const [showVendorBanner, setShowVendorBanner] = useState(Boolean(defaultValue));
  const [isEditCountryAndCurrency, setIsEditCountryAndCurrency] = useState(false);
  const orgDetail = useSelector((state: RootState) => state.b2bOrgDetailReducer?.data?.payload);
  const [selectedCountryCode, setSelectedCountryCode] = useState(recipientBankCountryCode);
  const [search, setSearch] = useState("");

  // re-mount the dynamic form
  const [resetKey, setResetKey] = useState(0);

  // fetcher with loading
  const [_getVendorDetail, isLoadingVendorDetail] = useLoading(getVendorDetail);
  const [_getVendorList, isLoadingVendorList] = useLoading(getVendorList);
  const [_getListCountry, isLoadingCountryList] = useLoading(getListCountry);
  const [_getListCurrency, isLoadingCurrencyList] = useLoading(getListCurrency);

  const location = useLocation<{
    vendorID?: string;
    legalName?: string;
  }>();
  const isDraftBill = billFormType === BillFormTypes.Draft;

  const editBillVendorState = useMemo(() => {
    if (!defaultValue?.vendorID) {
      return EditBillVendorState.newVendor;
    }
    if (!defaultValue.isVendorLinked) {
      return EditBillVendorState.updatedVendor;
    }
    return EditBillVendorState.uptodateVendor;
  }, [defaultValue]);

  const handleDynamicFieldValue = (value: string) => {
    // if value is boolean as string, set to form as boolean
    const isBoolean = ["true", "false"].includes(value);
    const convertedValue = isBoolean ? value === "true" : value;
    return convertedValue;
  };

  const getCurrency = useCallback(
    (currencyCode?: string, currencies?: Currency[], countryCode?: string) => {
      const list = currencies || currencyList;
      const defaultCurrency = list.find((item) => item.CurrencyCode.slice(0, 2) === countryCode);
      return list.find((item) => item.CurrencyCode === currencyCode) || defaultCurrency || currencies[0];
    },
    [currencyList]
  );

  const getCountry = useCallback(
    (countryCode: string, countries?: Country[]) => {
      const selectedCountry = (countries || countryList).find((item) => item.countryCode === countryCode);
      return selectedCountry;
    },
    [countryList]
  );

  const parsedVendorDetail = useMemo(() => {
    if (!vendorDetail) {
      return null;
    }
    const parsedFields = vendorDetail.dynamicFields.reduce(
      (prev, curr) => {
        if (curr.id) {
          prev.dynamic[curr.id] = handleDynamicFieldValue(curr.value);
        } else {
          prev.static[curr.alias] = curr.value;
        }
        return prev;
      },
      { dynamic: {}, static: {} }
    );
    return {
      ...vendorDetail,
      ...parsedFields.static,
      dynamicFields: parsedFields.dynamic,
    };
  }, [vendorDetail]);

  const isEdit = useMemo(() => {
    return (
      !isLoadingVendorDetail &&
      parsedVendorDetail &&
      (JSON.stringify(parsedVendorDetail.dynamicFields) !== JSON.stringify(form.getFieldValue("dynamicFields")) ||
        (parsedVendorDetail as any).beneficiaryName !== form.getFieldValue("beneficiaryName") ||
        (parsedVendorDetail as any).currencyCode !== form.getFieldValue("currencyCode") ||
        (parsedVendorDetail as any).countryCode !== form.getFieldValue("countryCode") ||
        parsedVendorDetail.legalName !== form.getFieldValue("vendorName"))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, formOnChangeCount, parsedVendorDetail, isLoadingVendorDetail]);

  const handleChangeCountry = (countryCode: string, currencyCode?: string) => {
    if (!countryList.length) {
      return;
    }

    let country = getCountry(countryCode);
    if (!country) {
      country = getCountry(orgDetail?.countryCode);
    }
    onChangeCountry(country?.countryCode, country?.countryName);
    form.setFieldsValue({
      countryCode: country?.countryCode,
    });
    fetchCurrencyList(country?.countryCode).then((currencies: Currency[]) => {
      const currency = getCurrency(ocrData?.paymentCurrency || currencyCode, currencies, country?.countryCode);
      if (!isCurrencyChangedManually) {
        onChangeCurrency(currency?.CurrencyCode);
        form.setFieldsValue({
          currencyCode: currency?.CurrencyCode,
        });
      }
    });
    setSelectedCountryCode(country?.countryCode);
  };

  const handleSetFormVendorDetail = (detail: any) => {
    const dynamicFields: Record<string, any> = {};
    const staticFields: Record<string, any> = {};

    staticFields.recipientEmail = detail?.recipientEmail ? detail?.recipientEmail?.split(",") : [];
    detail?.dynamicFields?.forEach((item) => {
      const value = handleDynamicFieldValue(item.value);
      if (item.id) {
        if (item.alias === "recipientEmail") {
          dynamicFields[item.id] = typeof value === "string" && value !== "" ? String(value).split(",") : value || [];
        } else {
          dynamicFields[item.id] = value;
        }
      } else {
        staticFields[item.alias] = value;
      }
    });

    handleChangeCountry(staticFields.countryCode, staticFields.currencyCode);
    form.setFieldsValue({ ...staticFields, dynamicFields });
  };

  const fetchVendorList = async () => {
    const vendorListResponse = await _getVendorList();
    setVendorList(vendorListResponse?.data?.payload || []);
  };

  const getCombinationIDs = () => {
    if (isEditCountryAndCurrency) {
      return [];
    }

    return vendorDetail?.dynamicFields?.map((item) => item.id).filter(Boolean) || [];
  };

  const combinationIDs = useMemo(getCombinationIDs, [vendorDetail, isEditCountryAndCurrency]);
  // const combinationIDs = [67];

  const fetchCountryList = async () => {
    const countryListResponse: any = await _getListCountry(true);
    setCountryList(countryListResponse?.data?.payload?.countries || []);
  };

  const handleNewVendor = (value: boolean) => {
    if (value && !ocrData?.ocrId) {
      resetForm();
      // set new recipient nickname by search keyword
      form.setFieldsValue({
        vendorName: search,
      });
      setSearch("");
    }
    setIsNew(value);
    onNewVendor?.(value);
    setVendorDetail(null);
    setShowVendorBanner(false);
  };

  const fetchDetail = async (id: string) => {
    handleNewVendor(false);
    const vendorDetailResponse = await _getVendorDetail(id);
    const detail = vendorDetailResponse?.data?.payload as VendorDetail;

    detail.dynamicFields = detail.dynamicFields.map((field) => {
      // IMPROVEMENT: should not changing the value and just
      // show as it is. P.S it should be an string[]
      if (field.alias === "recipientEmail") {
        return {
          ...field,
          value: field.value ? field.value.split(",") : [],
        };
      }
      return field;
    });

    setVendorDetail({ ...detail, id });
    handleSetFormVendorDetail(detail);
    return detail;
  };

  const resetForm = () => {
    form.resetFields([
      "beneficiaryName",
      "currencyCode",
      "countryCode",
      "dynamicFields",
      "vendorName",
      "recipientEmail",
    ]);
    handleChangeCountry(orgDetail?.countryCode, orgDetail?.currency);
    setResetKey((prev) => prev + 1);
  };

  const handleClearNewVendor = () => {
    resetForm();
    handleNewVendor(false);
    setShowVendorBanner(false);
  };

  const fetchCurrencyList = async (countryCode: string) => {
    const currencyListResponse = await _getListCurrency(true, countryCode);
    const currencies = currencyListResponse?.data?.payload?.result || [];
    setCurrencyList(currencies);
    return currencies;
  };

  const checkVendorNameValidator = debounce((_: unknown, legalName: string, callback: (message?: string) => void) => {
    const isEditSelectedVendor = defaultValue && defaultValue.vendorName === legalName;
    if (!legalName || !isNew || isEditSelectedVendor) {
      callback();
      return;
    }
    checkVendorNameAvailability(legalName)
      .then(() => callback())
      .catch(() => {
        callback(DUPLICATE_VENDOR_NAME_ERROR_MESSAGE);
      });
  }, 300);

  useEffect(() => {
    if (!noSelectVendor) {
      fetchVendorList();
    } else {
      setIsNew(true);
    }
    fetchCountryList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noSelectVendor]);

  useEffect(() => {
    if (location?.state?.vendorID && vendorList.length && countryList.length) {
      fetchDetail(location?.state?.vendorID);
      form.setFieldsValue({
        vendorName: location?.state?.legalName,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.state?.vendorID, vendorList, countryList]);

  useEffect(() => {
    onEditVendor?.(isEdit);
  }, [isEdit, onEditVendor]);

  // if payment currency and bank country updated, reset the dynamic form
  useEffect(() => {
    form.resetFields(["dynamicFields"]);
  }, [form, paymentCurrency, recipientBankCountryCode]);

  // setVendorID
  useEffect(() => {
    if (location?.state?.vendorID) {
      setVendorId(location.state.vendorID);
    } else if (defaultValue?.vendorID) {
      setVendorId(defaultValue.vendorID);
    }
  }, [defaultValue?.vendorID, location?.state?.vendorID]);

  useEffect(() => {
    onSelectVendor?.(vendorDetail);
  }, [onSelectVendor, vendorDetail]);

  /*
    =========== defaultValue effects ===========

    When default value setted, change country and vendor detail to trigger dynamic fields.
    After that, set the data to form (handleSetFormVendorDetail) is needed
  */

  const parsedDefaultValue: VendorDetailWithID = useMemo(() => {
    if (!defaultValue) {
      return;
    }
    return {
      dynamicFields: defaultValue.dynamicFields.concat([
        {
          alias: "beneficiaryName",
          value: defaultValue.beneficiaryName,
        },
        {
          alias: "currencyCode",
          value: defaultValue.currencyCode,
        },
        {
          alias: "countryCode",
          value: defaultValue.countryCode,
        },
        {
          alias: "recipientEmail",
          value: defaultValue.recipientEmail,
        },
      ]),
      id: defaultValue.vendorID,
      legalName: defaultValue.vendorName,
      organisationID: defaultValue.organisationID,
      recipientEmail: defaultValue.recipientEmail?.join(),
    };
  }, [defaultValue]);

  useEffect(() => {
    // update on formOnChangeCount is 0 only, means didmount
    if (formOnChangeCount) {
      return;
    }
    if (!countryList?.length || !defaultValue) {
      return;
    }

    handleChangeCountry(defaultValue.countryCode, defaultValue.currencyCode);
    setVendorDetail(parsedDefaultValue);
    if (!defaultValue.vendorID) {
      setIsNew(true);
    } else {
      form.setFieldsValue({
        vendorName: defaultValue.vendorName,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue, vendorList, countryList, formOnChangeCount]);

  // =========== end of defaultValue effects ===========

  useEffect(() => {
    /*
      reset dynamic form component to did mount because changing payment
      currency, recipient bank country, and vendor detail means starting
      point for dynamic form.
    */
    setResetKey((prev) => prev + 1);
    if (vendorDetail) {
      const dynamicFields = vendorDetail.dynamicFields.reduce((prev, curr) => {
        return {
          ...prev,
          [curr.alias]: curr.value,
        };
      }, {} as Record<string, any>);

      if (dynamicFields?.currencyCode !== paymentCurrency || dynamicFields?.countryCode !== recipientBankCountryCode) {
        // if currency code is different with the vendor detail or default value, reset the dynamic fields
        form.resetFields(["dynamicFields"]);
        setIsEditCountryAndCurrency(true);
      } else {
        handleSetFormVendorDetail(vendorDetail); // bring back detail value to form dynamicFields
        setIsEditCountryAndCurrency(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentCurrency, recipientBankCountryCode, vendorDetail]);

  useEffect(() => {
    if (!ocrData?.ocrId || !vendorList.length) {
      return;
    }

    const selectedVendor = vendorList.find(
      (item) => item?.beneficiaryName?.toLowerCase() === ocrData.recipientName.toLowerCase()
    );
    if (selectedVendor) {
      fetchDetail(String(selectedVendor.id));
      form.setFieldsValue({
        vendorName: selectedVendor.legalName,
      });
    } else {
      handleNewVendor(true);
      const appliedBankCountryCode =
        !isCountryChangedManually && ocrData.recipientBankCountry
          ? ocrData.recipientBankCountry
          : recipientBankCountryCode;
      const appliedCurrencyCode =
        !isCurrencyChangedManually && ocrData.paymentCurrency ? ocrData.paymentCurrency : paymentCurrency;
      handleChangeCountry(appliedBankCountryCode, appliedCurrencyCode);

      if (!Boolean(form.getFieldValue("beneficiaryName"))) {
        form.setFieldsValue({
          beneficiaryName: ocrData.recipientName,
        });
      }

      if (ocrData.recipientName) {
        form.setFieldsValue({
          vendorName: ocrData.recipientName,
          beneficiaryName: ocrData.recipientName,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ocrData, vendorList]);

  const dropdownVendorRender = (menu: React.ReactElement<any, string | React.JSXElementConstructor<any>>) => {
    const isVendorExist =
      (search.length > 0 && search.length <= 3) ||
      Boolean(vendorList?.find((item) => item.legalName.toLowerCase() === search.toLowerCase()));

    return (
      <>
        {menu}
        {Boolean(menu.props?.["options"]?.length) && <Divider className={style.divider} />}
        <Button className={style.newVendorButton} onClick={() => handleNewVendor(true)} disabled={isVendorExist}>
          + New Recipient{search ? ` “${search}”` : ""}
        </Button>
      </>
    );
  };

  const renderDraftVendorBanner = !showVendorBanner && isNew && isDraftBill && (
    <Banner
      message={
        <div className={style.draftVendorBanner}>
          <p className={style.title}>New Recipient Detected</p>
          <p>
            A new recipient with these payment details will only be created after this bill is submitted for processing
          </p>
        </div>
      }
      type="info"
    />
  );

  const renderVendorBanner = () => {
    if (!showVendorBanner) {
      return null;
    }

    switch (editBillVendorState) {
      case EditBillVendorState.newVendor:
        return (
          <div className={style.addNewBanner}>
            A recipient with the payment details below has not been created.{" "}
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              role="button"
              onClick={() => {
                setShowVendorBanner(false);
                onEditVendor(false);
              }}
            >
              Add New Recipient
            </a>
          </div>
        );

      case EditBillVendorState.updatedVendor:
        return (
          <Banner
            message={
              <>
                Payment detail(s) for this recipient is unique to this bill.{" "}
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  role="button"
                  onClick={() => {
                    fetchDetail(defaultValue.vendorID);
                    setShowVendorBanner(false);
                  }}
                >
                  Reset Recipient Details
                </a>{" "}
                to use the centrally saved recipient details.
              </>
            }
            type="attention"
          />
        );

      default:
        return null;
    }
  };

  const renderLegalNameHelper = () => {
    if (noSelectVendor || isNew) {
      return (
        <>
          <p className={style.selectVendorHelp}>
            Recipient will be saved under this nickname that is only visible to your organization
          </p>
          {!isNew && <div className={style.selectVendorSeparator} />}
        </>
      );
    }

    if (vendorDetail) {
      return null;
    }

    return (
      <p className={style.selectVendorHelp}>
        Select a saved recipient, or create a new recipient to enter payment details
      </p>
    );
  };

  const renderNotFound = () => {
    return (
      <div className={style.notFound}>
        <Icon src={dataTableNoRecordFound} alt="no result found" />
        <div className={style.notFoundText}>
          {vendorList.length
            ? `No recipient matching “${search}” found.`
            : "You do not have any saved recipients. Create a new recipient to retrieve them in this list for future payments."}
        </div>
      </div>
    );
  };

  return (
    <div className={style.vendorSection}>
      <Form.Item
        label={
          ocrData?.recipientName ? (
            <OCRTooltip
              label="Recipient"
              isOptional={false}
              isOCROveridden={ocrData?.recipientName !== form.getFieldValue("vendorName")}
            />
          ) : (
            "Recipient"
          )
        }
        name="vendorName"
        className={style.selectVendor}
        rules={[
          {
            required: !(editBillVendorState === EditBillVendorState.newVendor && showVendorBanner),
            message: "This field cannot be empty",
          },
          {
            validator: checkVendorNameValidator,
          },
        ]}
      >
        {isNew ? (
          <Input
            onFocus={() => {
              trackView("vendorName");
            }}
            onBlur={() => {
              trackEdit("vendorName");
            }}
            placeholder="Type a recipient nickname"
            suffix={
              noSelectVendor ? null : (
                <img
                  className={style.clearIcon}
                  alt="Clear"
                  src={greyCloseIcon}
                  onClick={handleClearNewVendor}
                  width="12"
                  height="12"
                />
              )
            }
            disabled={editBillVendorState === EditBillVendorState.newVendor && showVendorBanner}
          />
        ) : (
          <Select
            showSearch
            onSearch={setSearch}
            dropdownClassName={style.dropdown}
            onChange={(_, option: SelectVendorListItem) => {
              setVendorId(option.vendorId);
              fetchDetail(String(option.vendorId));
            }}
            notFoundContent={renderNotFound()}
            dropdownRender={dropdownVendorRender}
            placeholder="Recipient"
            loading={isLoadingVendorList}
            disabled={isLoadingVendorList}
            onFocus={() => {
              trackView("vendorName");
            }}
            onBlur={() => {
              trackEdit("vendorName");
            }}
          >
            {vendorList.map((item) => (
              <Select.Option key={item.id} vendorId={item.id} value={item.legalName}>
                {item.legalName}
              </Select.Option>
            ))}
          </Select>
        )}
      </Form.Item>
      {renderLegalNameHelper()}

      {renderVendorBanner()}
      {renderDraftVendorBanner}

      {isLoadingVendorDetail ? (
        <LoaderIcon />
      ) : (
        (vendorDetail || isNew) && (
          <>
            <h3 className={style.sectionHeader}>Recipient Information</h3>
            <Form.Item
              label={
                ocrData?.recipientBankCountry || ocrData?.paymentCurrency ? (
                  <OCRTooltip
                    label="Recipient Country and Currency"
                    isOCROveridden={
                      ocrData?.recipientBankCountry !== recipientBankCountryCode ||
                      ocrData?.paymentCurrency !== paymentCurrency
                    }
                  />
                ) : (
                  "Recipient Country and Currency"
                )
              }
            >
              <div className={style.currencyAndCountry}>
                <Form.Item name="countryCode" rules={[{ required: true, message: "Please select bank country" }]}>
                  <CountrySelect
                    countryList={countryList}
                    value={recipientBankCountryCode}
                    onChange={(value) => {
                      handleChangeCountry(value);
                      setIsCountryChangedManually(true);
                    }}
                    onFocus={() => {
                      trackView("countryCode");
                    }}
                    onBlur={() => {
                      trackEdit("countryCode");
                    }}
                    loading={isLoadingCountryList}
                    placeholder="Select Recipient Country"
                  />
                </Form.Item>

                <Form.Item name="currencyCode" rules={[{ required: true, message: "Please select currency" }]}>
                  <Select
                    showSearch
                    optionFilterProp="label"
                    onChange={(value: string) => {
                      onChangeCurrency(value);
                      form.setFieldsValue({
                        currencyCode: value,
                      });
                      setIsCurrencyChangedManually(true);
                    }}
                    onFocus={() => {
                      trackView("currencyCode");
                    }}
                    onBlur={() => {
                      trackEdit("currencyCode");
                    }}
                    loading={isLoadingCurrencyList}
                    disabled={isLoadingCurrencyList || currencyList.length === 0}
                    placeholder="Select Recipient Currency"
                  >
                    {currencyList.map((item) => (
                      <Select.Option key={item.ID} value={item.CurrencyCode} label={item.CurrencyCode}>
                        {item.CurrencyCode}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            </Form.Item>

            <DynamicForm
              billID={defaultValue?.billID}
              vendorId={vendorId}
              combinationIDs={combinationIDs}
              currency={paymentCurrency}
              destinationCountry={selectedCountryCode}
              form={form}
              key={String(resetKey)}
              trackView={trackView}
              trackEdit={trackEdit}
              ocrData={ocrData}
              source={noSelectVendor && "vendor_management"}
            />
          </>
        )
      )}
    </div>
  );
};

export default VendorSection;
