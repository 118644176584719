import * as React from "react";
import {
  CARD_LIMIT_TYPES,
  ICardDetails,
  ITag,
  SPENDING_LOCK_TYPES,
  ISpendingLockWithNameAndId,
} from "Views/Card/types";

export enum CARD_DETAILS_FIELDS_MAP {
  TITLE = "title",
  SPENDING_LIMIT = "spendingLimit",
  SPENDING_LIMIT_TYPE = "spendingLimitType",
  SPENDING_LOCKS = "spendingLocks",
  SPENDING_LOCK_TYPE = "spendingLockType",
  EXPENSE_CATEGORY_ID = "expenseCategoryId",
  EXPENSE_CATEGORY_NAME = "expenseCategoryName",
  COLOR = "color",
  ID = "id",
  LET_MY_MANAGER_CHOOSE_LIMIT = "letMyManagerChooseLimit",
  TAGS = "tags",
  IS_CATEGORY_LOCK_ADDED_FROM_URL = "isCategoryLockAddedFromUrl",
}

export interface ICardEditFieldDetails {
  [CARD_DETAILS_FIELDS_MAP.TITLE]: string;
  [CARD_DETAILS_FIELDS_MAP.SPENDING_LIMIT]: string;
  [CARD_DETAILS_FIELDS_MAP.SPENDING_LIMIT_TYPE]: CARD_LIMIT_TYPES;
  [CARD_DETAILS_FIELDS_MAP.SPENDING_LOCKS]: ISpendingLockWithNameAndId[];
  [CARD_DETAILS_FIELDS_MAP.SPENDING_LOCK_TYPE]: SPENDING_LOCK_TYPES;
  [CARD_DETAILS_FIELDS_MAP.EXPENSE_CATEGORY_ID]: number;
  [CARD_DETAILS_FIELDS_MAP.EXPENSE_CATEGORY_NAME]: string;
  [CARD_DETAILS_FIELDS_MAP.COLOR]: string;
  [CARD_DETAILS_FIELDS_MAP.ID]: string;
  [CARD_DETAILS_FIELDS_MAP.LET_MY_MANAGER_CHOOSE_LIMIT]: boolean;
  [CARD_DETAILS_FIELDS_MAP.TAGS]: ITag[];
  [CARD_DETAILS_FIELDS_MAP.IS_CATEGORY_LOCK_ADDED_FROM_URL]?: boolean;
}

interface CardDetailsEditContextType {
  cardDetailsEditState: ICardEditFieldDetails;
  setCardDetailsEditState: React.Dispatch<React.SetStateAction<ICardEditFieldDetails>>;
  cardData: ICardDetails;
}

const CardDetailsEditContext = React.createContext<CardDetailsEditContextType>({
  cardDetailsEditState: {
    [CARD_DETAILS_FIELDS_MAP.TITLE]: "",
    [CARD_DETAILS_FIELDS_MAP.SPENDING_LIMIT]: "",
    [CARD_DETAILS_FIELDS_MAP.SPENDING_LIMIT_TYPE]: CARD_LIMIT_TYPES.unlimited,
    [CARD_DETAILS_FIELDS_MAP.SPENDING_LOCKS]: [],
    [CARD_DETAILS_FIELDS_MAP.SPENDING_LOCK_TYPE]: SPENDING_LOCK_TYPES.NONE,
    [CARD_DETAILS_FIELDS_MAP.EXPENSE_CATEGORY_ID]: null,
    [CARD_DETAILS_FIELDS_MAP.EXPENSE_CATEGORY_NAME]: null,
    [CARD_DETAILS_FIELDS_MAP.COLOR]: null,
    [CARD_DETAILS_FIELDS_MAP.ID]: null,
    [CARD_DETAILS_FIELDS_MAP.LET_MY_MANAGER_CHOOSE_LIMIT]: false,
    [CARD_DETAILS_FIELDS_MAP.TAGS]: null,
    [CARD_DETAILS_FIELDS_MAP.IS_CATEGORY_LOCK_ADDED_FROM_URL]: false,
  },
  setCardDetailsEditState: () => {
    /* do nothing */
  },
  cardData: null,
});

export const useCardDetailsEditContext = () => React.useContext(CardDetailsEditContext);

export default CardDetailsEditContext;

export interface ISpendingLockEditDetails {
  showDrawer: boolean;
  selectedSpendingLockType: SPENDING_LOCK_TYPES;
}

interface ISpendingLockEditContext {
  spendingLockEditDetails: ISpendingLockEditDetails;
  setSpendingLockEditDetails: React.Dispatch<React.SetStateAction<ISpendingLockEditDetails>>;
}

export const SpendingLockEditContext = React.createContext<ISpendingLockEditContext>({
  spendingLockEditDetails: {
    showDrawer: false,
    selectedSpendingLockType: SPENDING_LOCK_TYPES.NONE,
  },
  setSpendingLockEditDetails: () => {
    /* do nothing */
  },
});

export const useSpendingLockEditContext = () => React.useContext(SpendingLockEditContext);
