import React, { useCallback } from "react";
import classNames from "classnames";

import Chip, { IActionChipProps } from "Modules/DS/Chip";

import styles from "./Action.module.scss";

export const ActionChip = ({
  title,
  onClick,
  onClear,
  active,
  selected,
  iconClass = "",
}: IActionChipProps): JSX.Element => {
  const filterClassName: string = classNames(
    styles.container,
    { [styles.selected]: selected },
    { [styles.active]: active || selected }
  );

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.stopPropagation();
      onClick(e);
    },
    [onClick]
  );

  const handleClear = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();
      selected && onClear();
    },
    [onClear, selected]
  );

  return (
    <div className={filterClassName} onClick={handleClick}>
      {iconClass ? <Chip.Title.PrefixIcon title={title} className={iconClass} /> : <span>{title}</span>}
      <button className={styles.close} onClick={handleClear} />
    </div>
  );
};
