/**
 * This custom hook determines whether an organization is eligible for SaaS + payments based on its configuration.
 * It checks if the organization's KYB status is approved and if it has enabled payments for bills or reimbursements.
 * The behavior of this hook is determined by the 'IS_KYB_ON_DEMAND' flag, which is initially set to true for existing organizations
 * and evaluated based on SaaS and payments logic for new organizations. This flag will be removed after soft deletion of existing 3.0 orgs.
 *
 * @returns {boolean} Returns true if the organization is eligible for SaaS + payments, otherwise false.
 */
import { KYB_STATUS } from "Views/KYX/Constants";
import { getCookieValue, cookieNames } from "utility/CookieHelper";
import useCheckOrgConfigs from "customHooks/useCheckOrgConfigs";

/**
 * This hooks is to determine whether it is SaaS only or it is SaaS + Payment enabled.
 * false: SaaS only
 * true: SaaS + Payment
 *
 * IMPROVEMENT: rather using hooks which means FE will maintain the hooks for the SaaS + Payment config,
 * it is better to ask BE to put this in the configs API so only BE will be the one that manage all configurations.
 * @returns boolean
 */
export const useIsSaasWithPaymentsOrg = () => {
  // Checks if the KYB status of the organization is approved
  const isKYBApproved = getCookieValue(cookieNames.KYB_STATUS) === KYB_STATUS.APPROVED;

  // Checks if payments for bills and reimbursements are enabled for the organization
  const isPaymentForBills = useCheckOrgConfigs("payment_for_bills");
  const isPaymentForCR = useCheckOrgConfigs("payment_for_reimbursements");

  // Retrieve the value of the 'IS_KYB_ON_DEMAND' flag from the cookie
  // The use of cookie here should be removed for security reasons and redirection should be handled accordingly
  const isKYBOnDemand = getCookieValue(cookieNames.IS_KYB_ON_DEMAND) === "true";

  // Determine if the organization is eligible for SaaS + payments
  // If 'IS_KYB_ON_DEMAND' flag is true, eligibility depends on KYB approval and payments configuration
  // If 'IS_KYB_ON_DEMAND' flag is false (for existing organizations), default to true until its soft deletion
  return isKYBOnDemand ? isKYBApproved && (isPaymentForBills || isPaymentForCR) : true;
};
