export const API_URL = {
  globalConfig: "/ms/spm-disbursement/v1/payment-run/configs",
  getSetting: "/ms/spm-disbursement/v1/payment-run/settings",
  createSetting: "/ms/spm-disbursement/v1/payment-run",
  previewSetting: "/ms/spm-disbursement/v1/payment-run/settings/preview",
  disableSetting: "/ms/spm-disbursement/v1/payment-run/disable",
};

export const DEFAULT_ERROR_MESSAGE = "An error has occurred. Please try refreshing the page.";

export const PAYMENT_RUN_EVENT_NAME = {
  pageLoaded: "Payment Run Settings Page Loaded",
  saveBtnClicked: "Payment Run Settings Save Button Clicked",
  saveBtnProcessed: "Payment Run Settings Save Processed",
};
