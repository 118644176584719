import React, { useEffect, useMemo, useState } from "react";
import { Radio } from "antd";
import { RadioChangeEvent } from "antd/lib/radio";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "Modules/DS/Modal/Components";
import Search from "Modules/search";
import { getTeamList } from "Redux/Actions";
import { searchInTeamList } from "utility";
import { ISelectRadioList } from "Views/Team/Profile/types";
import "./index.scss";

const SelectRadioList = ({ title, searchPlaceholder, excludeTeams = [], value = "", onChange }: ISelectRadioList) => {
  const dispatch = useDispatch();

  const [searchValue, setSearchValue] = useState("");

  const { data: teamData, loading } = useSelector((state: any) => state.teamList || {});

  const teamDataSource = teamData?.payload?.teams || [];

  const dataSource = useMemo(() => {
    if (excludeTeams.length === 0) {
      return teamDataSource;
    }

    return teamDataSource.filter((team) => !excludeTeams.includes(team.id));
  }, [excludeTeams, teamDataSource]);

  const filteredData = useMemo(() => searchInTeamList(searchValue, dataSource), [dataSource, searchValue]);

  useEffect(() => {
    if (!dataSource) dispatch(getTeamList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnchange = (event: RadioChangeEvent) => {
    onChange(dataSource.find((data) => data.id === event.target.value));
  };

  return (
    <div className="select-radio-list">
      {title && <h3 className="select-radio-list__title">{title}</h3>}
      <Search getValue={(val) => setSearchValue(val)} placeholder={searchPlaceholder} />
      {loading ? (
        <Loader />
      ) : (
        <Radio.Group className="select-radio-list__content" value={value} onChange={handleOnchange}>
          {filteredData.map(({ id, team_name }) => (
            <Radio key={id} value={id} className="select-radio-list__item">
              {team_name}
            </Radio>
          ))}
        </Radio.Group>
      )}
    </div>
  );
};

export default SelectRadioList;
