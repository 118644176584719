import React, { useState } from "react";

import { Quick } from "Modules/DS/Filter";

import { ITitleWithID } from "Views/Transaction/@types";
import { useActiveCategory } from "utility/useActiveCategory";

export const CategoryFilter = ({
  id,
  applyFilter,
  resetFilter,
}: {
  id: string;
  applyFilter: (filter: any, callApi?: boolean) => void;
  resetFilter: (keys: string[]) => void;
}) => {
  const [title, setTitle] = useState("");
  const [selectedIds, setSelectedIds] = useState([]);
  const { categoryList, isLoading } = useActiveCategory();

  const data: ITitleWithID[] = categoryList.map((category) => ({
    id: category.id.toString(),
    title: category.categoryName,
  }));

  const states = {
    selectedIds,
    setSelectedIds,
    title,
    setTitle,
    loading: isLoading,
  };

  const onSelectHandler = (value: string[]) => applyFilter({ categories: value });

  const clearFilter = () => resetFilter(["categories"]);

  return (
    <Quick.Multiple<ITitleWithID>
      idKey="title"
      displayKey="title"
      appliedKey="title" // Check on download!
      searchable
      states={states}
      defaultValue="Category"
      filterItemProps={{ id: "title", containerClass: ".data-table-container" }}
      onAddSelected={onSelectHandler}
      clearFilter={clearFilter}
    >
      {data}
    </Quick.Multiple>
  );
};
