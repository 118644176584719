import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { comingSoon, fileNotFound } from "../../../assets/img";
import "./emptyState.scss";

const EmptyState = ({ isComingSoon = false }) => {
  return (
    <div className={`empty-state ${isComingSoon && "empty-state--comingsoon"}`}>
      <img
        className={`${isComingSoon && "img-comingsoon"}`}
        src={isComingSoon ? comingSoon : fileNotFound}
        alt="coming soon"
      />
      <h4>The page you requested could not be found</h4>
      <Link className={"empty-state__back-btn"} to={"/"}>
        Back to Home
      </Link>
    </div>
  );
};

EmptyState.propTypes = {
  isComingSoon: PropTypes.bool,
};

export default EmptyState;
