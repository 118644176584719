import React, { useMemo } from "react";
import Icon from "Modules/icons";
import Input from "Modules/DS/Input";

import { LineIcon } from "assets/v1.1/icons";
import { useMoveTeamMemberContext } from "../MoveTeamMember/context";
import { IMoveTeamConfirmStep } from "../types";
import { VALIDATE_CONFIRM_MOVE_MEMBER_TEXT } from "Views/Team/constant";

import { getIsCardVendorNium } from "Views/Home/Utilites";

import styles from "./index.module.scss";

const MoveTeamConfirmStep = (props: IMoveTeamConfirmStep) => {
  const {
    employeeName,
    teamDetails: currentTeam,
    destinationTeam,
    validateText,
    setValidateText,
  } = useMoveTeamMemberContext();
  const isCardVendorNium = getIsCardVendorNium();

  const processList = useMemo(() => {
    return [
      {
        icon: LineIcon.orangeTeam,
        contents: [
          `Remove ${employeeName} from ${currentTeam?.name} budget.`,
          `Move ${employeeName} to ${destinationTeam?.team_name} budget.`,
          `Set ${employeeName}’s role as a Member in ${destinationTeam?.team_name} budget.`,
        ],
      },
      ...(!isCardVendorNium
        ? [
            {
              icon: LineIcon.orangeCalendar,
              contents: [
                `Return ${employeeName}’s personal balance to the previous budget balance, if any.`,
                `Stop ongoing Scheduled Internal Transactions from ${employeeName}’s wallet in the previous budget.`,
              ],
            },
          ]
        : []),
      {
        icon: LineIcon.orangeCard,
        contents: [
          `Move ${employeeName}’s Cards from the previous budget to the ${destinationTeam?.team_name} budget.`,
          `Decline ongoing Card requests from ${employeeName} in the previous budget.`,
        ],
      },
      ...(!isCardVendorNium
        ? [
            {
              icon: LineIcon.orangeWallet,
              contents: [`Decline ongoing Fund Transfer requests to ${employeeName}’s wallet in the previous budget.`],
            },
          ]
        : []),
      {
        icon: LineIcon.orangeTransferMoney,
        contents: [`Have no impact on Reimbursement requests from ${employeeName}.`],
      },
    ];
  }, [employeeName, currentTeam, destinationTeam, isCardVendorNium]);

  const invalidConfirm = useMemo(
    () => validateText && validateText !== VALIDATE_CONFIRM_MOVE_MEMBER_TEXT,
    [validateText]
  );

  return (
    <div
      data-testid="move-team-member-step-two"
      className={`${styles["move-team-member__content"]} ${styles["move-team-member__step-two"]}`}
    >
      <h3 className={styles["move-team-member__confirm-title"]}>Proceeding to move will:</h3>
      <ul className={styles["move-team-member__process-list"]}>
        {processList.map((process, processIndex) => (
          <li key={processIndex} className={styles["move-team-member__process-item"]}>
            <Icon className={styles["move-team-member__process-item-icon"]} alt="" src={process.icon} />
            <ul className={styles["move-team-member__process-item-content"]}>
              {process.contents.map((content, contentIndex) => (
                <li key={processIndex + contentIndex}>{content}</li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
      <p className={styles["move-team-member__confirm-validate-text"]}>
        Type {VALIDATE_CONFIRM_MOVE_MEMBER_TEXT} to confirm:
      </p>
      <Input
        className={`${styles["move-team-member__step-two-input"]} ${
          invalidConfirm ? styles["move-team-member__step-two-input--error"] : ""
        }`}
        placeholder="Type the exact word using the same letter case"
        onChange={setValidateText}
      />
      <div className={styles["move-team-member__error-text"]}>
        {invalidConfirm &&
          `${VALIDATE_CONFIRM_MOVE_MEMBER_TEXT} is typed incorrectly, please make sure to use the correct spelling and
          capitalization.`}
      </div>
    </div>
  );
};

export default MoveTeamConfirmStep;
