import * as React from "react";

import { IActionItem } from "Modules/DS/DataTable";

import useAlignActions from "./useAlignActions";

import "./index.scss";

export const ActionsBar = ({ children = [] }: { children: IActionItem[] }) => {
  const actionButtons = useAlignActions(children.filter((item) => item.enabled));

  return (
    children.length > 0 && (
      <div className="data-table__actions-bar">
        {actionButtons.map((item, index) => (
          <div key={index}>{item}</div>
        ))}
      </div>
    )
  );
};
