export const useMultiSelect =
  (selectedIds: string[], setSelectedIds: React.Dispatch<React.SetStateAction<string[]>>) =>
  (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();

    const selectedId: string | undefined = (e.target as Element).id;
    if (!selectedId) return;

    const selectedData: string[] = selectedIds.includes(selectedId)
      ? selectedIds.filter((itemId) => itemId !== selectedId)
      : [...selectedIds, selectedId];

    setSelectedIds(selectedData);
  };
