import { getSmartCSVTemplate } from "Redux/DataCalls/SmartCSV.api";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";

export const FETCH_SMART_CSV_REQUEST = "FETCH_SMART_CSV_REQUEST";
export const FETCH_SMART_CSV_SUCCESS = "FETCH_SMART_CSV_SUCCESS";
export const FETCH_SMART_CSV_FAILURE = "FETCH_SMART_CSV_FAILURE";

const sendSmartCSVRequest = () => {
  return {
    type: FETCH_SMART_CSV_REQUEST,
  };
};

const fetchSmartCSVSuccess = (data) => {
  return {
    type: FETCH_SMART_CSV_SUCCESS,
    payload: data,
  };
};

const fetchSmartCSVError = (data) => {
  return {
    type: FETCH_SMART_CSV_FAILURE,
    payload: data,
  };
};

export const fetchSmartCSVTemplates = () => {
  return (dispatch) => {
    dispatch(sendSmartCSVRequest());

    return getSmartCSVTemplate()
      .then(({ data }) => {
        if (data.status === HTTP_STATUS_CODE.OK) {
          dispatch(fetchSmartCSVSuccess(data));
        } else {
          dispatch(fetchSmartCSVError(data));
        }
      })
      .catch((err) => {
        dispatch(fetchSmartCSVError(err));
      });
  };
};
