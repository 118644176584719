import dayjs, { ConfigType, OpUnitType } from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { plural } from "utility";

dayjs.extend(relativeTime);

export const isBefore = (date1: ConfigType, date2: ConfigType, unit: OpUnitType) =>
  dayjs(date1).isBefore(dayjs(date2), unit);

export const formatDate = (date: string | Date, format: string = "YYYY-MM-DD") => dayjs(date).format(format);

export const substract = (interval: number, unit: dayjs.ManipulateType) => dayjs().subtract(interval, unit);

export const dateDiffInMonth = (start: string | Date, end: string | Date) => {
  const startDate = dayjs(start);
  const endDate = dayjs(end);

  const diff = endDate.add(1, "M").diff(startDate.startOf("M"), "month");

  return plural(diff, "month");
};

export const timeFromNow = (endDate: string | Date) => {
  return dayjs(endDate).fromNow(true);
};
