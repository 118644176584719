export const CARD_VENDOR_TYPE = {
  MATCHMOVE: "MatchMove",
  NIUM: "NIUM",
  UOB: "UOB",
  NONE: "",
};

export const COMPANY_ROLES = {
  ACCOUNTANT: "Accountant",
  ADMIN: "Admin",
  BOOKKEEPER: "Bookkeeper",
  EMPLOYEE: "Employee",
  AUDITOR: "Auditor",
};

export const TEAM_ROLES = {
  MEMBER: "Member",
  MANAGER: "Manager",
  NON_MEMBER: "Non-Member",
};
