import APIclient from "API/Client";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import { GetOrgId, GetUserId } from "utility";
export const TERMS_CONDITIONS_EXISTING_REQUEST = "TERMS_CONDITIONS_EXISTING_REQUEST";
export const TERMS_CONDITIONS_EXISTING_SUCCESS = "TERMS_CONDITIONS_EXISTING_SUCCESS";
export const TERMS_CONDITIONS_EXISTING_ERROR = "TERMS_CONDITIONS_EXISTING_ERROR";

export const fetchTnCExistingDataReq = (data) => {
  return {
    type: TERMS_CONDITIONS_EXISTING_REQUEST,
    data: data,
  };
};

export const fetchTnCExistingDataSuccess = (data) => {
  return {
    type: TERMS_CONDITIONS_EXISTING_SUCCESS,
    data: data,
  };
};

export const fetchTnCExistingDataError = (data) => {
  return {
    type: TERMS_CONDITIONS_EXISTING_ERROR,
    data: data,
  };
};

export const checkTermsAndConditionsExisting = () => {
  return (dispatch) => {
    const headers = {
      headers: { "X-Organisation-Id": GetOrgId(), "X-User-Id": GetUserId() },
    };
    APIclient.getData(`/ms/spm-organization/v1/agreements/`, "", false, headers)
      .then((data) => {
        if (data?.data?.status === HTTP_STATUS_CODE.OK) {
          dispatch(fetchTnCExistingDataSuccess(data?.data));
        } else {
          dispatch(fetchTnCExistingDataError(data));
        }
      })
      .catch((err) => {
        dispatch(fetchTnCExistingDataError(err));
      });
  };
};
