import { RulesType } from "./type";

/**
 *
 * @param rules custom rules that extends react hook form RegisterOptions
 * @returns transformed rules according to the RegisterOptions
 */
export const transformRules = (rules: RulesType) => {
  if (typeof rules !== "object") {
    return rules;
  }

  switch (true) {
    case Boolean(rules.positive): {
      const { value, message } = rules.positive;
      rules.validate = {
        positive: (v) => Number(v) > value || message,
      };
    }
  }

  return {
    ...rules,
  };
};
