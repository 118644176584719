import { useContext } from "react";

import { DEBOUNCE_DELAY_TIME } from "Modules/DS/Search";
import { TOASTER_STATUS_TYPE } from "Modules/DS/Toaster";

import { DEFAULT_ERROR_MESSAGE } from "Views/Transaction/Constants";
import { TrxnFilterContext, TrxnToastContext } from "Views/Transaction/Context";
import { ITrxnFilterContext, ITrxnToastContext } from "Views/Transaction/@types";

import { useSaveTrxnDetails } from "./useFetch";

export const useTrxnUpdate = (trxnNumber: string, dispatchTimeoutAction?: () => void, trxnId?: string) => {
  const { fetchData, linkSubscription } = useSaveTrxnDetails();

  const { filter, onApplyFilter }: ITrxnFilterContext = useContext(TrxnFilterContext);
  const { setToaster }: ITrxnToastContext = useContext<ITrxnToastContext>(TrxnToastContext);

  const handleUpdateTransaction = async (
    urls: string[],
    notes: string,
    category: number,
    tax: string,
    tags?: { label: string; value: string }[],
    deleteAttachmentIds?: string[],
    subscriptionId?: string
  ) => {
    try {
      const payload = {
        expense_category_id: category,
        notes: notes,
        photo_urls: urls,
        tax_id: tax,
        transaction_id: trxnId,
        tags: JSON.stringify((tags || []).map((tag) => ({ label: tag.label, value: tag.value }))),
      };
      if (trxnId) {
        payload["transaction_id"] = trxnId;
      }

      if (deleteAttachmentIds) {
        payload["deleting_receipt_ids"] = deleteAttachmentIds;
      }

      if (subscriptionId) {
        await linkSubscription([trxnNumber], subscriptionId);
      }

      await fetchData(trxnNumber, payload);

      setTimeout(() => {
        setToaster({
          show: true,
          message: "1 transaction successfully updated.",
          type: TOASTER_STATUS_TYPE.SUCCESS,
        });
        dispatchTimeoutAction?.();
        onApplyFilter(filter);
      }, DEBOUNCE_DELAY_TIME.MAX * 3);
    } catch (error) {
      dispatchTimeoutAction?.();
      setToaster({
        show: true,
        type: TOASTER_STATUS_TYPE.ERROR,
        message: error?.message || DEFAULT_ERROR_MESSAGE,
      });
    }
  };

  return handleUpdateTransaction;
};
