import { useCallback, useContext, useEffect, useState } from "react";

import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";

import { TOASTER_STATUS_TYPE } from "Modules/DS/Toaster";

import {
  IGetTrxnResponse,
  ITrxnToastContext,
  ISaveTrxnDetailsRequest,
  ITrxnDetailsExpenseData,
} from "Views/Transaction/@types";
import {
  TRANSACTION_TYPES,
  DEFAULT_ERROR_MESSAGE,
  EXPENSE_DETAILS_TYPES,
  TRANSACTION_SUB_TYPES,
} from "Views/Transaction/Constants";
import { allSettled } from "Views/Transaction/Helper";
import { TrxnToastContext } from "Views/Transaction/Context";
import {
  saveTrxnDetails,
  getTrxnDetailsById,
  getReceiptDetails,
  linkTransactionToSubscriptions,
} from "Views/Transaction/DataCalls";

import { useVerifiedReceipts } from "./useVerifiedReceipts";

export const useSaveTrxnDetails = () => {
  const [data, setData] = useState<object>({});
  const [loading, setLoading] = useState<boolean>(false);

  const fetchData = async (trxnId: string, details: ISaveTrxnDetailsRequest) => {
    try {
      setLoading(true);

      const response = await saveTrxnDetails(trxnId, details);

      if (response.status > HTTP_STATUS_CODE.OK || response.data.status > HTTP_STATUS_CODE.OK) {
        throw new Error(response.data?.payload?.status_message || response.data?.payload?.errors);
      }

      setData(response?.data?.payload);
    } catch (error) {
      throw new Error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const linkSubscription = async (trxnNumbers: string[], subscriptionId: string) => {
    try {
      setLoading(true);

      const response = await linkTransactionToSubscriptions(trxnNumbers, subscriptionId);

      if (response.status > HTTP_STATUS_CODE.OK || response.data.status > HTTP_STATUS_CODE.OK) {
        throw new Error(response.data?.payload?.status_message || response.data?.payload?.errors);
      }
    } catch (error) {
      setLoading(false);
      throw new Error(error.message);
    }
  };

  return { loading, data, fetchData, linkSubscription };
};

export const useFetchFromUrl = <T extends IGetTrxnResponse>(setDetail: (record: T) => void) => {
  const { setToaster }: ITrxnToastContext = useContext<ITrxnToastContext>(TrxnToastContext);

  const fetchDetails = useCallback(async (id: string) => {
    const resp = await getTrxnDetailsById(id);
    if (resp.status > HTTP_STATUS_CODE.OK) {
      throw new Error("Failed to fetch transaction details");
    } else if (!resp.data.found) {
      throw new Error("Transaction details not found");
    }

    return resp.data.transaction;
  }, []);

  const setDetailsFromUrl = useCallback(
    async (param: string) => {
      try {
        const data = await fetchDetails(param);
        setDetail(data);
      } catch (error) {
        setToaster({
          show: true,
          type: TOASTER_STATUS_TYPE.ERROR,
          message: error?.message || error || DEFAULT_ERROR_MESSAGE,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fetchDetails]
  );

  return setDetailsFromUrl;
};

export const useExpenseDetailsFetch = (id: string, trxnNumber: string, type: string) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<ITrxnDetailsExpenseData>({} as ITrxnDetailsExpenseData);

  const resetExpenseData = () => setData({} as ITrxnDetailsExpenseData);
  const fetchExpenseDetails = useCallback(async () => {
    if (!id || !trxnNumber || !EXPENSE_DETAILS_TYPES.has(type as TRANSACTION_TYPES)) return;

    try {
      setLoading(true);
      const response = await getReceiptDetails(id, trxnNumber);

      if (response.status > HTTP_STATUS_CODE.OK || response.data.status > HTTP_STATUS_CODE.OK) {
        throw new Error(response.data.payload.status_message);
      }

      setData({
        tax: response?.data?.payload?.expense?.tax?.id,
        taxName: response?.data?.payload?.expense?.tax?.tax_name,
        category: response?.data?.payload?.expense?.expense_category_id,
        categoryName: response?.data?.payload?.expense?.expense_category_name,
        reference: response?.data?.payload?.expense?.merchant,
        tags: response?.data?.payload?.expense?.tags,
        receipts: response?.data?.payload?.expense?.receipts,
        source: response?.data?.payload?.expense?.source,
        foreignCurrencyAmount: response?.data?.payload?.expense?.foreign_currency_amount,
        foreignCurrencyCode: response?.data?.payload?.expense?.foreign_currency_code,
        subscription_id: response?.data?.payload?.expense?.subscription_id,
        budgetId: response?.data?.payload?.expense?.team_id,
        payerId: response?.data?.payload?.expense?.user_id,
      });
    } catch (error) {
      throw new Error(error.message);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return { loading, fetchExpenseDetails, resetExpenseData, expenseData: data };
};

export const useDetailsFetch = (
  id: string,
  trxnNumber: string,
  trxnType: string,
  receipts: string[],
  visible: boolean
) => {
  const [loading, setLoading] = useState(false);
  const [isHistoricalRefund, setIsHistoricalRefund] = useState<boolean>(false);

  const { setToaster }: ITrxnToastContext = useContext<ITrxnToastContext>(TrxnToastContext);

  const { fetchReceipts, attachments } = useVerifiedReceipts(receipts, true);

  const { fetchExpenseDetails, resetExpenseData, expenseData } = useExpenseDetailsFetch(id, trxnNumber, trxnType);

  useEffect(() => {
    if (!visible) return;

    setLoading(true);
    setIsHistoricalRefund(false);
    allSettled([fetchExpenseDetails(), fetchReceipts()])
      .then((results) => {
        // Handle failed promises
        const rejectedPromises = results.filter((result) => result.status === "rejected");

        // Handle errors if any of the promises were rejected
        if (rejectedPromises.length > 0) {
          const errorMessages: string[] = rejectedPromises.map((promise) => promise.reason?.message || promise.reason);
          if (errorMessages.includes("expense not found!") && trxnType.includes(TRANSACTION_SUB_TYPES.REFUND)) {
            setIsHistoricalRefund(true);
            return;
          }

          setToaster({
            show: true,
            type: TOASTER_STATUS_TYPE.ERROR,
            message: errorMessages.join("; "),
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, id]);
  // adding record id as a dependency above so as to call the APIs again when "View <trxn_type> Transaction" is clicked from side panel

  return { loading, setLoading, attachments, expenseData, resetExpenseData, isHistoricalRefund };
};
