import { IKYXRemarkContextType, IRemarkState } from "Views/KYX/@types";
import { REMARKS_SIDEBAR_TYPE } from "Views/KYX/Constants/Remarks";
import { createContext } from "react";

export const initialRemarkState: IRemarkState = {
  visible: false,
  payload: {},
  type: REMARKS_SIDEBAR_TYPE.FORM,
};

export const initialKYXRemarkContext: IKYXRemarkContextType = {
  close: () => null,
  setRemarks: () => null,
  remarks: initialRemarkState,
  formRemarks: {},
  setFormRemarks: () => null,
};

export const KYXRemarkContext: React.Context<IKYXRemarkContextType> =
  createContext<IKYXRemarkContextType>(initialKYXRemarkContext);
