import React, { useState } from "react";
import s from "./PieChart.module.scss";

import { ParentSize } from "@visx/responsive";
import { Pie } from "@visx/shape";
import { Group } from "@visx/group";
import { Text } from "@visx/text";
import { GlyphTriangle } from "@visx/glyph";

/* utils */
import { convertToPercentage, SGDCurrencyFormatter } from "Views/Analytics/utils";

/* type */
interface PieDatatype {
  category: string;
  amount: number;
  color: string;
}

interface Props {
  data: PieDatatype[];
  onHover: (category: PieDatatype | null) => void;
}

function PieChart({ data, onHover }: Props) {
  const [active, setActive] = useState(null);
  const total = data.reduce((acc, d) => acc + d.amount, 0);

  const handleMouseEnter = (data: PieDatatype) => {
    setActive(data);
    onHover(data);
  };
  const handleMouseLeave = () => {
    setActive(null);
    onHover(null);
  };

  return (
    <ParentSize className={s.container}>
      {({ width }) => (
        <svg width={Math.min(260, width)} height={width} viewBox="-15 -20 310 300">
          <filter id="shadow">
            <feDropShadow dx="0" dy="2" stdDeviation="8" floodColor="#DEDEDE" floodOpacity="0.5" />
          </filter>
          <Group top={width / 2} left={width / 2}>
            <Pie
              data={data}
              pieValue={(d) => d.amount}
              pieSortValues={(x, y) => y - x}
              outerRadius={width / 2}
              innerRadius={width / 2 - 20}
              startAngle={0}
              endAngle={2 * Math.PI}
              cornerRadius={0}
              padAngle={0}
            >
              {(pie) => {
                return pie.arcs.map((arc, index) => {
                  const { category } = arc.data;
                  const arcPath = pie.path(arc);

                  const setOpacity = (hex, alpha) =>
                    `${hex}${Math.floor(alpha * 255)
                      .toString(16)
                      .padStart(2, "0")}`;
                  const arcFill = arc.data.color;

                  const glyphRotation = ((arc.endAngle + arc.startAngle) / 2 + Math.PI) * (180 / Math.PI);

                  return (
                    <g
                      key={`arc-${category}`}
                      onMouseEnter={() => handleMouseEnter(arc.data)}
                      onMouseLeave={handleMouseLeave}
                      style={{ cursor: "pointer" }}
                      className={s.categoryPie}
                    >
                      <path d={arcPath} fill={arcFill} />

                      {active?.category === arc.data.category && (
                        <>
                          <GlyphTriangle
                            style={{
                              fill: arcFill,
                              transform: `rotate(${glyphRotation}deg) translate(0rem, ${(width / 2 - 23) / 16}rem)`,
                            }}
                          />
                          <path d={arcPath} fill={setOpacity(arcFill, 0.6)} style={{ transform: "scale(1.1)" }} />
                        </>
                      )}
                    </g>
                  );
                });
              }}
            </Pie>
            {active ? (
              <>
                <Text width={180} textAnchor="middle" fill="#333744" fontSize={14} fontWeight={700} dy={10}>
                  {SGDCurrencyFormatter(active.amount, 2, "currency")}
                </Text>
                <Text width={150} textAnchor="middle" fill="#535766" fontSize={14} dy={-10}>
                  {`${active.category} ${convertToPercentage(active.amount / total)}`}
                </Text>
              </>
            ) : (
              <>
                <Text width={180} textAnchor="middle" fill="#535766" fontSize={14} fontWeight={700} dy={10}>
                  {SGDCurrencyFormatter(total)}
                </Text>
                <Text width={150} textAnchor="middle" fill="#333744" fontSize={14} dy={-10}>
                  {`${data.length} Categories`}
                </Text>
              </>
            )}
          </Group>
        </svg>
      )}
    </ParentSize>
  );
}

export { PieChart };
