import React from "react";
import DataTable, { Table, DATA_TABLE_SIZE_TYPES, IColumnData, DATA_TABLE_PADDING } from "Modules/DS/DataTable";
import Chip, { CHIP_STATUS } from "Modules/DS/Chip";
import NoRecord from "Modules/DS/DataTable/Components/Table/NoRecord";
import TextWithTooltip from "Views/Taxes/components/TextWithTooltip";
import { INewCategory } from "Views/Categories/types";
import CategoryCountBanner from "../CategoryCountBanner";

import "./styles.scss";

const CategoryImporting = ({ missingCategories, partnerName }) => {
  const { categories = [], count = 0 } = missingCategories;

  const tableProps = {
    total: 2,
    loading: false,
    pageNum: 1,
    pageSize: categories?.length || 10,
    paginationEnabled: false,
    customEmptyState: (
      <NoRecord
        className="category-mapping__small-height"
        emptyMessage={
          <p>
            No new CoA in {partnerName}. All CoA in {partnerName} already existed in Spenmo.
          </p>
        }
      />
    ),
    dataSource: (categories || []).map((category) => ({
      categoryName: category.account_name,
      categoryAccountNumber: category.account_number,
      isNew: true,
    })),
  };

  const columnData: IColumnData<INewCategory>[] = [
    {
      id: "1",
      enabled: true,
      column: {
        key: "categoryName",
        title: "Category Name",
        dataIndex: "categoryName",
        width: 150,
        ellipsis: true,
        render(categoryName: string) {
          return (
            <>
              <TextWithTooltip
                treshold={28}
                text={categoryName}
                element={
                  <div className="text-with-tooltip__title">
                    <span>{categoryName}</span>
                  </div>
                }
              />
            </>
          );
        },
      },
    },
    {
      id: "2",
      enabled: true,
      column: {
        key: "categoryAccountNumber",
        title: "Chart of Accounts",
        dataIndex: "categoryAccountNumber",
        width: 150,
        ellipsis: true,
        render(categoryAccountNumber: string, record: INewCategory) {
          const taxNameonXero = `${categoryAccountNumber}-${record.categoryName}`;

          return (
            <div className="text-with-new-wrapper">
              <TextWithTooltip
                treshold={20}
                text={taxNameonXero}
                element={
                  <div className="text-with-tooltip__title">
                    <span>{taxNameonXero}</span>
                  </div>
                }
              />
              {record.isNew && <Chip.Label title="New" status={CHIP_STATUS.INFO} />}
            </div>
          );
        },
      },
    },
  ];

  return (
    <div className="category-mapping">
      <CategoryCountBanner count={count} partnerName={partnerName} />

      <DataTable
        size={DATA_TABLE_SIZE_TYPES.SMALL}
        className="category-mapping__data"
        padding={DATA_TABLE_PADDING.NONE}
      >
        <Table<INewCategory> {...tableProps}>{columnData}</Table>
      </DataTable>
    </div>
  );
};

export default CategoryImporting;
