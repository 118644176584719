import { currencyFormatterV2, dateTimeFormatter } from "utility/index";
import { defaultInput } from "Modules/TransactionDetail/TransactionDetail";
import { EInputType, IDetail, ISection } from "Modules/TransactionDetail";
import { useIsSaasWithPaymentsOrg } from "customHooks/useIsSaasWithPaymentsOrg";
import { SaaSConfig } from "Views/Bills/V2/BillForm/type";

export const useBillTrxnDetails = (
  record: any,
  showEditableFields: boolean,
  visible: boolean,
  saasConfig: SaaSConfig
) => {
  const walletCurrency = record?.walletCurrency;
  const { invoiceCurrency } = record?.billTax || {};
  const billInfoData = record?.cards?.[0]?.sections || [];
  const isSaasWithPaymentsOrg: boolean = useIsSaasWithPaymentsOrg();

  const safeCall = (callback, defaultValue) => {
    if (!record?.id) {
      return () => defaultValue;
    }
    return callback;
  };

  const defaultEmptyValue = "-";

  const formatDetailsData = (detailsArr: IDetail[]) =>
    detailsArr
      .map((detail: IDetail) => ({ ...detail, value: detail?.value || defaultEmptyValue }))
      .filter((field) => field.visible);

  const trxnDetails: () => IDetail[] = safeCall(
    () =>
      formatDetailsData([
        {
          id: "TRXN_DETAIL_001",
          visible: true,
          key: "Transaction Type",
          value: "Bill Payment",
        },
        {
          id: "TRXN_DETAIL_002",
          visible: true,
          key: `${saasConfig?.isPaymentEnabled ? "Transaction Number" : "Spenmo Reference"}`,
          value: record?.transactionCode,
        },
        {
          id: "TRXN_DETAIL_003",
          visible: true,
          key: "Created Date & Time",
          value: record.createdAt
            ? `${dateTimeFormatter(record.createdAt).date}, ${dateTimeFormatter(record.createdAt).time}`
            : defaultEmptyValue,
        },
        {
          id: "TRXN_DETAIL_004",
          visible: saasConfig?.isPaymentEnabled,
          key: "Exchange Rate",
          value: record?.fxRate,
        },
        {
          id: "TRXN_DETAIL_005",
          visible: !showEditableFields,
          key: "Category",
          value: record?.expenseCategoryName,
        },
        {
          id: "TRXN_DETAIL_006",
          visible: !showEditableFields,
          key: "Tax",
          value: record?.taxName,
        },
        {
          id: "TRXN_DETAIL_007",
          visible: !showEditableFields,
          key: "Tags",
          value: record?.tags?.map((tag) => tag?.value)?.join(", "),
        },
        {
          id: "TRXN_DETAIL_008",
          visible: !showEditableFields,
          key: "Notes",
          value: record?.clientNotes,
        },
      ]),
    []
  );

  // PAY USING PAYNOW (for SG corridor only)
  const isPayUsingPayNowAvailable = false;

  // values should be updated after API integration
  const billInfoSections: () => ISection[] = safeCall(
    () =>
      billInfoData.map((section, sid) => {
        return {
          id: `BILL_DETAIL_SECTION_${sid + 1}`,
          title: section.label,
          details: formatDetailsData(
            section.fields.map((field, fid) => {
              const { label, type, value, info } = field;

              let fieldValue = value;

              // hard code the currency format for any type of amount
              switch (true) {
                case Boolean(label === "Invoice amount" && invoiceCurrency): {
                  fieldValue = currencyFormatterV2(Number(field.value || 0), invoiceCurrency, true, 2);
                  break;
                }
                case type === "walletCurrency": {
                  fieldValue = currencyFormatterV2(Number(field.value || 0), walletCurrency, true, 2);
                  break;
                }
              }

              // to remove "Exchange Rate" field for saas only users
              if (label === "Exchange Rate" && !isSaasWithPaymentsOrg) {
                return null;
              }

              return {
                id: `RECIPIENT_INFO_${fid}`,
                key: label,
                value: fieldValue,
                visible: true,
                tooltipContent: info,
              };
            })
          ),
        };
      }),
    []
  );

  const defaultValue = safeCall(() => {
    const attachments = record?.attachments?.map((att) => att?.url) || [];
    const attachmentsDetailsMap = (record?.attachments || []).reduce((map, att) => {
      map[att.url] = {
        name: att.filename,
        url: att.url,
        size: att.size,
      };
      return map;
    }, {});
    const receipts = record?.receipts?.map((rec) => rec?.url) || [];
    return {
      [EInputType.CATEGORY]: Number(record?.expenseCategoryID) || null,
      [EInputType.TRANSACTION_TAGS]: record.tags,
      [EInputType.NOTES]: record?.clientNotes,
      [EInputType.TAX]: record?.taxID,
      [EInputType.MERCHANT]: record?.beneficiary?.name,
      [EInputType.RECEIPT]: attachments, // attachments.concat(receipts) ???
      [EInputType.RECEIPT_DETAIL]: attachmentsDetailsMap, // attachments.concat(receipts) ???
      [EInputType.PAYMENT_RECEIPT]: receipts[0], // assuming there is only one payment receipt/proof of payment
    };
  }, defaultInput);

  return { trxnDetails, defaultValue, billInfoSections };
};
