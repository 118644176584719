import React from "react";
import PropTypes from "prop-types";
import { Form, Input, Select } from "antd";
import UploadImage from "../../../Modules/uploadImage";
import Button from "../../../Modules/button";
import { roundValue } from "../../../utility";
import { connect } from "react-redux";
import { receiptActionFunc, getAllTaxesFunc } from "../../../Redux/Actions";
import LoaderIcon from "../../State/Loading/LoaderIcon";
import { pdfView } from "../../../assets/img";
import CategoryDropdown from "../../../Modules/categoryDropdown";
import "./TransactionDetails.scss";
import { getMerchantName } from "../TransactionsHelper";
import { AlreadyReconciledMessage } from "constants/Integration.constant";
import { TRANSACTION_TYPE } from "constants/Transaction.constant";
import AccountingTags from "Modules/AccountingTags";
import { TAX_STATUS } from "constants/Tax.constant";

class ExpenseDetailsEdit extends React.Component {
  static propTypes = {
    onSubmit: PropTypes.func,
    onEditConfirmation: PropTypes.func,
    data: PropTypes.object,
    actionData: PropTypes.any,
    receiptAction: PropTypes.func,
    transactionData: PropTypes.object,
    receiptUploadAction: PropTypes.func,
    uploadData: PropTypes.any,
    imagefilePath: PropTypes.any,
    uploadLoading: PropTypes.bool,
    getUserInfo: PropTypes.func,
    getAllTaxes: PropTypes.func,
    taxesData: PropTypes.object,
    editedData: PropTypes.object,
    loading: PropTypes.bool,
    trackTransactionDetails: PropTypes.func,
    transactionId: PropTypes.string,
    transactionType: PropTypes.string,
  };

  state = {
    receipts: [],
    accountingTags: this.props.transactionData.tags,
  };

  formRef = React.createRef();

  componentDidMount() {
    const { getAllTaxes, editedData } = this.props;

    if (Object.keys(editedData || {}).length !== 0) {
      this.formRef.current.setFieldsValue({
        ...editedData,
        tax_id: this.findTaxDefaultValue(editedData?.tax_id),
      });
    }

    if (editedData?.photo_urls) {
      this.setState({ receipts: editedData.photo_urls });
    }

    if (editedData?.tags) {
      this.setState({ accountingTags: editedData?.tags });
    }
    getAllTaxes({ status: TAX_STATUS.ACTIVE });
  }

  onFinish = (values) => {
    const {
      transactionData,
      trackTransactionDetails,
      onEditConfirmation,
      receiptAction,
      accountingPartnerAuthReducer,
      transactionId,
      transactionType,
    } = this.props;
    const { accountingTags } = this.state;
    const { found, taxId: defaultTaxId } = this.defaultTax(values.tax_id);

    trackTransactionDetails("transaction edit save clicked");
    const tax = found ? { tax_id: values?.tax_id } : { tax_id: defaultTaxId };
    const cleanTaxId = tax.tax_id ? tax : {};
    const isConnectedToXero = accountingPartnerAuthReducer?.payload?.has_valid_token;
    const data = {
      ...cleanTaxId,
      simplified_merchant_name: values.simplified_merchant_name,
      photo_urls: this.state.receipts,
      transaction_number: transactionData.transaction_id,
      notes: values.notes !== undefined ? values.notes : transactionData.comment,
      tags: accountingTags.map((tag) => ({ label: tag.label, value: tag.value })),
      expense_category_id:
        values.expense_category_id !== undefined ? values.expense_category_id : transactionData.expense_category_id,
    };

    if ([TRANSACTION_TYPE.REFUND, TRANSACTION_TYPE.FEE].includes(transactionType)) {
      data["transaction_id"] = transactionId;
    }

    const changesKey = this.detectChange(data);

    const onlyUpdateAttachment = changesKey.length === 1 && changesKey[0] === "photo_urls";

    if (transactionData.is_reconciled && changesKey.length !== 0 && !onlyUpdateAttachment && isConnectedToXero) {
      const updateReceipt = changesKey.includes("photo_urls");

      const message = updateReceipt
        ? AlreadyReconciledMessage.withReceipt()
        : AlreadyReconciledMessage.withoutReceipt();

      onEditConfirmation(message, data);
      return;
    }

    receiptAction(data);
  };

  detectChange = (changed) => {
    const { transactionData } = this.props;
    const { receipts } = this.state;

    const original = {
      tax_id: transactionData.tax?.id,
      simplified_merchant_name: getMerchantName(transactionData),
      expense_category_id: transactionData?.expense_category_id,
      notes: transactionData.comment,
      photo_urls: transactionData.receipts,
      tags: transactionData.tags,
    };

    return Object.entries(original)
      .filter(([key, value]) => {
        if (key === "photo_urls") {
          // To detect change on newly added attachment
          // we will look at receipts state and check the length
          // if greater than 0 then there is new attachment attach,
          return receipts.length !== 0;
        }

        if (key === "tags") {
          return this.checkTagsChange(value, changed[key]);
        }
        return changed[key] !== value;
      })
      .map(([key]) => key);
  };

  checkTagsChange = (initialTags, tags) => {
    if (tags.length !== initialTags.length) {
      return true;
    }

    for (let i = 0; i < tags.length; i++) {
      if (tags[i].value !== initialTags[i]?.value) {
        return true;
      }
    }

    return false;
  };

  componentDidUpdate(prevProps) {
    const { actionData } = this.props;
    if (actionData !== prevProps.actionData) {
      if (actionData && actionData.payload) {
        this.props.onSubmit(actionData);
      }
    }
  }

  handleCategoryChange = (id, option) => {
    const { trackTransactionDetails, transactionData } = this.props;
    const tax = option[0]?.tax_id && { tax_id: option[0]?.tax_id };
    this.formRef.current.setFieldsValue({
      expense_category_id: id,
      ...tax,
    });
    transactionData?.expense_category_name !== id && trackTransactionDetails("transaction edit category changed");
  };

  handleReceipts = (data = [], added = []) => {
    const receipts = data?.receipts || [];
    const combineReceipts = receipts.concat(added).map((photo) => {
      if (typeof photo === "string") {
        return { url: photo };
      }
      return photo;
    });

    return (
      combineReceipts.length > 0 && (
        <div className={"expense-details-edit__receipts"}>
          {combineReceipts.map((receipt, index) => {
            return receipt.url.trim() !== "" && receipt.url.indexOf(".pdf") > 0 ? (
              <a rel="noreferrer" href={receipt.url} target={"_blank"}>
                <img src={pdfView} key={index} className={"receipt-image"} alt="receipt" />
              </a>
            ) : (
              <img src={receipt.url} key={index} className={"receipt-image"} alt="receipt" />
            );
          })}
        </div>
      )
    );
  };

  handleOnBlurOfInput = (input) => {
    const { trackTransactionDetails, transactionData } = this.props;
    const currentValue =
      this?.formRef?.current?.getFieldValue(input === "merchantName" ? "simplified_merchant_name" : "notes") || "";
    if (input === "merchantName") {
      currentValue &&
        currentValue !== getMerchantName(transactionData) &&
        trackTransactionDetails("transaction edit merchant changed");
    } else if (input === "additionalNotes") {
      const notesInitialValue =
        (transactionData?.comment && transactionData?.comment !== "undefined" && transactionData.comment) || "";
      currentValue !== notesInitialValue && trackTransactionDetails("transaction edit notes added");
    }
  };

  handleOnReceiptsUpload = (images) => {
    const { trackTransactionDetails } = this.props;
    const { receipts } = this.state;
    images?.length && receipts?.length !== images?.length && trackTransactionDetails("transaction edit receipt added");
    this.setState({ receipts: images });
  };

  trackTaxChange = (value) => {
    const taxId = this.props?.transactionData?.tax?.id || "";
    taxId && taxId !== value && this.props.trackTransactionDetails("transaction edit tax changed");
  };

  defaultTax = (taxId) => {
    const { taxesData, editedData, transactionData } = this.props;
    const taxes = taxesData?.payload?.data || [];
    const originalTaxId = editedData?.tax_id || transactionData?.tax?.id;
    const taxName = editedData?.tax_name || transactionData?.tax?.tax_name;
    const taxRate = editedData?.tax_rate || transactionData?.tax?.tax_rate;

    return { found: taxes.find((tax) => tax.id === taxId), taxName, taxRate, taxId: originalTaxId };
  };

  findTaxDefaultValue = () => {
    const { found, taxName, taxRate, taxId } = this.defaultTax();

    if (!taxId) {
      return null;
    }

    if (found) {
      return taxId;
    }

    return `${taxName} (${roundValue(taxRate)}%)`;
  };

  render() {
    const { Option } = Select;
    const { transactionData, taxesData, loading, editedData, transactionType } = this.props;
    const taxes = taxesData?.payload?.data || [];

    if (loading) {
      return <LoaderIcon />;
    }

    const getTaxLabel = (tax) => {
      return `${tax.tax_name} (${roundValue(tax.tax_rate)}%)`;
    };

    return (
      <>
        <Form
          ref={this.formRef}
          onFinish={this.onFinish}
          hideRequiredMark
          validateTrigger={true}
          name="expense-edit"
          className="expense-details-edit"
        >
          <Form.Item
            label="Merchant Name"
            name="simplified_merchant_name"
            className="sp-form-control"
            initialValue={getMerchantName(transactionData)}
            rules={[{ required: true, message: "Merchant Name is required" }]}
          >
            <Input
              className="expense-details-edit__notes"
              placeholder="Merchant Name"
              onBlur={() => this.handleOnBlurOfInput("merchantName")}
            />
          </Form.Item>
          <Form.Item label="Category" name="expense_category_id">
            <CategoryDropdown
              showEmptyCategory={true}
              defaultValue={editedData?.expense_category_id || transactionData?.expense_category_name}
              action={this.handleCategoryChange}
            />
          </Form.Item>
          <Form.Item
            label="Tax Name"
            name="tax_id"
            initialValue={this.findTaxDefaultValue(editedData?.tax_id || transactionData?.tax?.id)}
            className="expense-details-edit__default-tax"
          >
            <Select placeholder="Select tax" onChange={this.trackTaxChange}>
              {taxes?.map((tax) => (
                <Option key={tax.id} value={tax.id}>
                  {getTaxLabel(tax)}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <AccountingTags
            onChange={(accountingTags) => this.setState({ accountingTags })}
            initialTags={editedData?.tags || transactionData.tags}
          />
          <Form.Item
            label="Notes"
            name="notes"
            initialValue={
              transactionData.comment && transactionData.comment !== "undefined" ? transactionData.comment : ""
            }
          >
            <Input
              className="expense-details-edit__notes"
              placeholder="Add a note"
              onBlur={() => this.handleOnBlurOfInput("additionalNotes")}
            />
          </Form.Item>
          {transactionType !== TRANSACTION_TYPE.FEE && (
            <Form.Item label="Receipt">
              {this.handleReceipts(transactionData, editedData?.photo_urls)}
              <UploadImage multipleUpload fileList={this.handleOnReceiptsUpload} />
            </Form.Item>
          )}
          <Form.Item shouldUpdate={true}>{() => <Button rounded className={"confirm-btn"} text={"Save"} />}</Form.Item>
        </Form>
      </>
    );
  }
}

export default connect(
  (state) => ({
    actionData: state.receiptActionReducer.data,
    uploadData: state.imageUploadReducer.data,
    taxesData: state.taxReducer.data,
    loading: state.userInfoReducer.loading,
    accountingPartnerAuthReducer: state.accountingPartnerAuthReducer.data,
  }),
  {
    receiptAction: receiptActionFunc,
    getAllTaxes: getAllTaxesFunc,
  }
)(ExpenseDetailsEdit);
