import React from "react";
import { IRouterType, PAGE_GRID_CONTENT_TYPE } from "Route/@types";
import { AUTH_PATHS } from "Route/Auth/paths";

// Auth Component
import Login from "Views/Auth/Login";
import EmployeeOTP from "Views/EmployeeOTP";
import Forgot from "Views/Auth/Forgot";
import ForgotPasswordOTP from "Views/Auth/ForgotPasswordOTP";
import ChangePassword from "Views/Auth/ChangePassword";
import ResetPassword from "Views/ResetPassword";
import SecurityHub from "Views/SecurityHub";
import ActivateGA from "Views/SecurityHub/GoogleAuthenticator/ActivateGA";
import { LogoutOnLoad } from "utility/useLogout";

export const AuthRoute: IRouterType[] = [
  {
    id: "auth-1",
    path: AUTH_PATHS.LOGIN,
    component: <Login />,
    title: "login",
    authRequired: false,
    exactPath: true,
  },
  {
    id: "auth-2",
    path: AUTH_PATHS.EMPLOYEE_OTP,
    component: <EmployeeOTP />,
    title: "OTP screen after login",
    authRequired: false,
    exactPath: true,
  },
  {
    id: "auth-3",
    path: AUTH_PATHS.FORGOT_PASSWORD,
    component: <Forgot />,
    title: "forgot password",
    authRequired: false,
    exactPath: true,
  },
  {
    id: "auth-4",
    path: AUTH_PATHS.FORGOT_PASSWORD_OTP,
    component: <ForgotPasswordOTP />,
    title: "forgot password OTP",
    authRequired: false,
    exactPath: true,
  },
  {
    id: "auth-5",
    path: AUTH_PATHS.CHANGE_PASSWORD,
    component: <ChangePassword />,
    title: "change password",
    authRequired: false,
    exactPath: true,
  },
  {
    id: "auth-6",
    path: AUTH_PATHS.SECURITY_HUB,
    component: <SecurityHub />,
    title: "security hub",
    authRequired: true,
    exactPath: true,
    grid: {
      content: PAGE_GRID_CONTENT_TYPE.FIXED,
    },
  },
  {
    id: "auth-7",
    path: AUTH_PATHS.ACTIVATE_GA,
    component: <ActivateGA />,
    title: "actoivate google auth",
    authRequired: true,
    exactPath: true,
  },
  {
    id: "auth-8",
    path: AUTH_PATHS.RESET_PASSWORD,
    component: <ResetPassword />,
    title: "Reset password",
    authRequired: true,
    exactPath: true,
    grid: {
      content: PAGE_GRID_CONTENT_TYPE.FIXED,
    },
  },
  {
    id: "auth-9",
    path: AUTH_PATHS.LOGOUT,
    component: <LogoutOnLoad />,
    title: "Logout on load",
    authRequired: true,
    exactPath: true,
  },
  {
    id: "auth-10",
    path: AUTH_PATHS.FORCE_RESET,
    component: <ChangePassword />,
    title: "force change password",
    authRequired: false,
    exactPath: true,
  },
];
