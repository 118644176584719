import { COUNTRY_CODE_REQ, FETCH_OTP_SUCCESS, FETCH_OTP_FAILURE } from "../Actions/countryCodeAction";
import { userInitialState } from "../InitialState";

export const countryCodeReducer = (state = userInitialState, action) => {
  switch (action.type) {
    case COUNTRY_CODE_REQ:
      return {
        ...state,
        data: action.data,
        loading: true,
        error: false,
        errorMessage: "",
      };
    case FETCH_OTP_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
        error: false,
        errorMessage: "",
      };
    case FETCH_OTP_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.data,
        error: true,
      };
    default:
      return state;
  }
};
