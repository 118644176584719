import React from "react";

import { FilterComponents, analytics } from "Modules/Filters";

export const AmountFilter = () => {
  return (
    <FilterComponents.InputRangeFilter
      title="Amount"
      appendClassName="amount"
      minLabel="min"
      minRangeFormItem="min_amount"
      minInputPlaceholder="min amount"
      minEventName={analytics.minAmountSelected}
      maxLabel="max"
      maxRangeFormItem="max_amount"
      maxInputPlaceholder="max amount"
      maxEventName={analytics.maxAmountSelected}
    />
  );
};
