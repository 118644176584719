import React from "react";
import ModalWrapper from "Modules/DS/Modal";
import { AdminConfirmation } from "Views/Employee/Update/Role/Modal/AdminConfirmation";
import { EmployeesModalContext } from "Views/Employee/context";

const PromoteAdminConfirmation = ({
  visible,
  onClose,
  employee,
  action,
}: {
  visible: boolean;
  onClose: () => void;
  employee: any;
  action: () => void;
}) => {
  return (
    <ModalWrapper visible={visible} close={onClose}>
      <EmployeesModalContext.Provider value={{ resetModal: onClose }}>
        <AdminConfirmation
          title="You're about to switch a user as a Budget Owner"
          usage="budget_owner"
          employee={employee}
          action={action}
        />
      </EmployeesModalContext.Provider>
    </ModalWrapper>
  );
};

export default PromoteAdminConfirmation;
