import React from "react";
import styles from "./index.module.scss";

const SettlementRequirement = ({ icon, content }: { icon: React.ReactNode; content: React.ReactNode }) => {
  return (
    <div className={`${styles.container}`}>
      <div className={`${styles.icon}`}>{icon}</div>
      <div className={`${styles.content}`}>{content}</div>
    </div>
  );
};

export default SettlementRequirement;
