import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../hook";
import List from "Views/Approval/ApprovalCenter/List";
import "./index.scss";
import Toast from "./Toast";
import ApprovalCenterProvider from "./provider";
import { resetState } from "Redux/Reducers/Approval/ApprovalCenter/approvalCenter.slice";
import usePermissionCheck from "Permission/usePermissionCheck";
import { CENTRALISED_APPROVAL_MENU } from "Permission/Actions";
import { CASBIN_PAGES } from "Permission/Pages";
import Loader from "Views/State/Loading/LoaderIcon";
import { Redirect } from "react-router-dom";
import { PermissionCheckResultWithLoading } from "Permission/types";

const ApprovalCenter = (): JSX.Element | React.ReactElement => {
  const dispatch: AppDispatch = useDispatch();
  const centralisedApprovalPermission = {
    object: CASBIN_PAGES.CENTRALISED_APPROVAL_MENU,
    action: CENTRALISED_APPROVAL_MENU.READ,
  };
  const [isAllowed, loading] = usePermissionCheck(
    [centralisedApprovalPermission],
    true
  ) as PermissionCheckResultWithLoading;
  const isNotAllowed = !isAllowed;

  useEffect(() => {
    return () => {
      dispatch(resetState());
    };
  }, []);

  if (loading) {
    return <Loader />;
  }

  if (isNotAllowed) {
    return <Redirect to={"/404"} />;
  }

  return (
    <div className="approval-center">
      <ApprovalCenterProvider>
        <List />
        <Toast />
      </ApprovalCenterProvider>
    </div>
  );
};

export default ApprovalCenter;
