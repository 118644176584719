import React from "react";
import { Typography } from "@spenmo/splice";
import { Moment } from "moment";
import dayjs, { Dayjs } from "dayjs";
import { DatePickerProps } from "antd/lib/date-picker";
import { FrequencyType, PaymentRunFields } from "Views/Settings/@types";
import { plural } from "utility";

export const customDateFormat =
  (type: FrequencyType): DatePickerProps["format"] =>
  (value?: Moment): string => {
    if (!value) {
      return "";
    }

    switch (type) {
      case "fortnightly": {
        const formmatedDate = value.format("Do MMMM YYYY");
        return `Every 2 weeks from ${formmatedDate}`;
      }
      case "monthly": {
        const formmatedDate = value.format("Do");
        return `${formmatedDate} of every month`;
      }
    }
  };

export const getDisableWording = (bill: number) => {
  if (typeof bill !== "number") {
    throw new Error("bill must be a valid number");
  }

  return (
    <Typography variant="body-content" size="s" tag="p">
      By disabling the Payment Run,{" "}
      <Typography variant="body-content" size="s" weight={600}>
        {plural(bill, "bill", "s")}
      </Typography>{" "}
      associated with it will be assigned the same payment initiation date as
      the Payment Run date and they will automatically be processed on that
      date. This action cannot be undone.
      <br />
      <br />
      Are you sure you want to proceed with disabling the Payment Run?
    </Typography>
  );
};

export const getEditWording = (bill: number) => {
  if (typeof bill !== "number") {
    throw new Error("bill must be a valid number");
  }

  return (
    <Typography variant="body-content" size="s" tag="p">
      With this change,{" "}
      <Typography variant="body-content" size="s" weight={600}>
        {plural(bill, "bill", "s", true, "scheduled")}
      </Typography>{" "}
      will become{" "}
      <Typography variant="body-content" size="s" weight={600}>
        Unscheduled.
      </Typography>{" "}
      This action cannot be undone.
      <br />
      <br />
      Are you sure you want to proceed with this Payment Run setting change?
    </Typography>
  );
};

/**
 *
 * @param data PaymentRunFields without isEOM
 * @param toDate to change runOn from YYYY-MM-DD to dayjs
 * @returns
 */
export const getRunOnValue = (
  data: Omit<PaymentRunFields, "isEOM">,
  toDate?: Boolean,
): string | Dayjs => {
  const { frequency, runOn } = data;
  switch (frequency) {
    case FrequencyType.WEEKLY: {
      return runOn as string;
    }
    default: {
      return runOn
        ? toDate
          ? dayjs(runOn as string, "YYYY-MM-DD")
          : (runOn as Moment).format("YYYY-MM-DD")
        : undefined;
    }
  }
};
