import React from "react";
import classNames from "classnames";
import { Core } from "assets/v1.1/icons/Core";
import { IAccordionProps } from "../type";

import styles from "./styles.module.scss";

const { ChevronDown } = Core;

const Accordion: React.FC<IAccordionProps> = ({ children, open, setOpen, hide = false }) => {
  return (
    <div className={styles["side-panel__section"]}>
      {!hide && (
        <div onClick={() => setOpen(!open)}>
          <img
            className={classNames(styles["accordion__arrow"], open ? styles["rotate-180"] : styles["rotate-0"])}
            src={ChevronDown.Line[24]}
            alt="Chevron Down Icon"
          />
        </div>
      )}
      {children}
    </div>
  );
};

export default Accordion;
