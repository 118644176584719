import {
  FETCH_TEAMS_LIST_REQUEST,
  FETCH_TEAMS_LIST_SUCCESS,
  FETCH_TEAMS_LIST_FAILURE,
  UPDATE_TEAM_DATA,
  RESET_TEAMS_DATA,
} from "Redux/Actions/Teams/teamListAction";
import { userInitialState } from "Redux/InitialState";

export const teamListReducer = (
  state = {
    ...userInitialState,
    selectedTeamId: null,
  },
  action
) => {
  switch (action?.type) {
    case FETCH_TEAMS_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case FETCH_TEAMS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action?.data,
        error: false,
      };
    case FETCH_TEAMS_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        data: action?.data,
        error: true,
      };
    case UPDATE_TEAM_DATA:
      return {
        ...state,
        selectedTeamId: action?.data,
        teamWallet: action?.wallet,
      };
    case RESET_TEAMS_DATA:
      return {
        ...state,
        ...userInitialState,
      };
    default:
      return state;
  }
};

export const fetchTeamListData = (state) => state?.teamList || {};
