import { axiosHeader } from "../index";
import APIclient from "API/Client";
import { Cookies } from "react-cookie";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import { GetOrgCountryCode } from "utility";
import { cookieNames, setCookie } from "utility/CookieHelper";
import {
  OTP_EXPIRES_AT_SESSION_STORAGE_NAME,
  getPreliminaryData,
} from "Redux/Actions/loginAction";

export const VERIFY_OTP = "VERIFY_OTP";
export const VERIFY_OTP_REQUEST = `${VERIFY_OTP}_REQUEST`;
export const VERIFY_OTP_SUCCESS = `${VERIFY_OTP}_SUCCESS`;
export const VERIFY_OTP_FAILURE = `${VERIFY_OTP}_FAILURE`;
export const VERIFY_OTP_RESET_DATA = `${VERIFY_OTP}_RESET_DATA`;

const cookies = new Cookies();

const fetchDataRequest = (data, comp) => {
  return {
    type: VERIFY_OTP_REQUEST,
    data: data,
  };
};

const fetchDataSuccess = (data) => {
  return {
    type: VERIFY_OTP_SUCCESS,
    data: data,
  };
};
const fetchDataError = (data) => {
  return {
    type: VERIFY_OTP_FAILURE,
    data: data,
  };
};

export const verifyOtpResetData = () => ({
  type: VERIFY_OTP_RESET_DATA,
});

export const verifyOtpV1 = (payload = {}) => {
  return (dispatch) => {
    dispatch(fetchDataRequest());
    APIclient.postData(`/api/v1/auth/otp/verify`, {
      ...payload,
      verify_user: true,
    })
      .then((response) => {
        const { data = {} } = response || {};
        if (data?.status === HTTP_STATUS_CODE.OK) {
          dispatch(fetchDataSuccess(data));
        } else {
          dispatch(fetchDataError(data));
        }
      })
      .catch((err) => {
        dispatch(fetchDataError(err?.response?.data || {}));
      });
  };
};

const storeAuthTokenAndUserId = (data) => {
  const { access_token, user_id, org_id } = data?.payload || {};

  cookies.remove("is_otp_verification_needed");
  cookies.remove("mobile_number");
  const lastSubmittedIndex = sessionStorage.getItem("lastSubmittedSavedIndex");
  sessionStorage.clear();

  // Persist the lastSubmittedSavedIndex key so we are not disabling the KYB form after re-login
  if (lastSubmittedIndex) {
    sessionStorage.setItem("lastSubmittedSavedIndex", lastSubmittedIndex);
  }

  setCookie(
    "reauth",
    org_id + "vspenmo1" + user_id + "vspenmo1" + GetOrgCountryCode(),
  );

  // TODO: This is hackish way, we should use axios interceptor to automatically append auth to
  //  request headers
  axiosHeader.headers.Authorization = `Bearer ${access_token}`;
  setCookie("auth_token", access_token);
};

export const verifyOtp = (
  dataObj = {},
  componentName = "default",
  isUserLoggedIn = false,
) => {
  return (dispatch) => {
    dispatch(fetchDataRequest());
    APIclient.postData(
      `${isUserLoggedIn ? "/api/v3/otp/verify" : "/api/v2/otp/verification"}`,
      dataObj,
    )
      .then(async ({ data = {} }) => {
        const { status } = data || {};
        if (status > HTTP_STATUS_CODE.OK) {
          const {
            status_message,
            expires_at,
            is_force_reset,
            force_reset_token,
          } = data?.payload || {};
          dispatch(fetchDataError(status_message));
          setCookie(cookieNames.IS_FORCE_RESET_NEEDED, is_force_reset);
          setCookie(cookieNames.FORCE_RESET_TOKEN, force_reset_token);
          expires_at &&
            sessionStorage.setItem(
              OTP_EXPIRES_AT_SESSION_STORAGE_NAME,
              expires_at,
            );
        } else {
          const { is_force_reset, force_reset_token } = data?.payload || {};
          setCookie(cookieNames.IS_FORCE_RESET_NEEDED, is_force_reset);
          setCookie(cookieNames.FORCE_RESET_TOKEN, force_reset_token);
          storeAuthTokenAndUserId(data);
          await getPreliminaryData(dispatch);
          sessionStorage.removeItem(OTP_EXPIRES_AT_SESSION_STORAGE_NAME);
          dispatch(fetchDataSuccess(data));
        }
      })
      .catch((err) => {
        const errorMessage =
          err?.message || "Unable to verify otp. Internal Server Error";
        dispatch(fetchDataError(errorMessage, componentName));
      });
  };
};
