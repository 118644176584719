import React, { useState } from "react";

import { Quick } from "Modules/DS/Filter";

import { ITitleWithID } from "Views/Transaction/@types";

import { missingFilter } from "./data";
import { useFormContext } from "../context/FormContext";

const defaultIncompleteFields = {
  missing_fields: [],
  attachment: "",
};

export const IncompleteFieldsFilter = ({
  id,
  applyFilter,
  resetFilter,
  connected,
  connectedUsingExpenseIntegration,
}: {
  id: string;
  applyFilter: (filter: any, callApi?: boolean) => void;
  resetFilter: (keys: string[]) => void;
  connected: boolean;
  connectedUsingExpenseIntegration: boolean;
}) => {
  const [title, setTitle] = useState("");
  const [selectedIds, setSelectedIds] = useState([]);
  const { tags } = useFormContext();

  const states = {
    selectedIds,
    setSelectedIds,
    title,
    setTitle,
  };

  const onAddSelected = (selected: string[]) => {
    const missingFilter = selected.reduce(
      (result, item) => {
        if (item === "attachment") {
          result[item] = "no";
        } else {
          result["missing_fields"].push(item);
        }
        return result;
      },
      {
        missing_fields: [],
      }
    );

    applyFilter({ ...defaultIncompleteFields, ...missingFilter });
  };

  const clearFilter = () => resetFilter(Object.keys(defaultIncompleteFields));

  return (
    <Quick.Multiple<ITitleWithID>
      idKey="id"
      displayKey="title"
      searchable
      states={states}
      defaultValue="Incomplete Fields"
      filterItemProps={{ id, containerClass: ".data-table-container" }}
      onAddSelected={onAddSelected}
      clearFilter={clearFilter}
    >
      {missingFilter(connected, connectedUsingExpenseIntegration, tags.length !== 0)}
    </Quick.Multiple>
  );
};
