import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataTable, {
  Table,
  ActionsBar,
  IActionItem,
  BulkActionType,
  ActionButtonType,
  DATA_TABLE_SIZE_TYPES,
} from "Modules/DS/DataTable";
import { ESidePanelButtonType } from "Modules/DS/SidePanel";
import { ISelectContext, SelectContext } from "Modules/DS/Select";
import useLoading from "utility/useLoading";
import { trackEvent } from "utility/analytics";
import Toaster from "Modules/DS/Toaster";
import { TOASTER_SIZE_TYPE, TOASTER_STATUS_TYPE } from "Modules/DS/Toaster/types.d";
import { IToasterProps } from "Views/Accounting/Settings/@types";
import {
  fetchAccountingWorkflow,
  workflowStatusMapping,
} from "Redux/Reducers/AccountingWorkflow/accountingWorkflow.slice";

import { updateTransactionStatus } from "Redux/DataCalls/AccountingWorkflow.api";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import { useAccountingTransaction } from "Views/Transaction/Context";
import { columnData } from "../ColumnData";
import AccountingWorkflowFilter from "../Filter";
import { EWorkflowApiStatus, IFilter, ITabConfigComponent, ITransaction } from "../type";
import TransactionSidePanel from "../SidePanel";
import EmailReminder from "../EmailReminder";
import EmptyState from "../EmptyState";
import Popover from "../BulkAction/Popover";
import ForceSync from "../ForceSync";
import { PAGE_SIZE, SCROLL_DELAY, TAB_NAME } from "../constant/constant";
import { useFilter } from "../hooks/useFilter";
import { useBulkEdit } from "../hooks/useBulkEdit";
import { useUpdateTransaction } from "../hooks/useUpdateTransaction";
import {
  allowToUpdateTransaction,
  defineSize,
  generateActions,
  generateSubTab,
  generateSuccessMessage,
  getRelatedTransaction,
  getTotalAmount,
  getUniqueEmployeeCount,
  paginationProps,
} from "./helper";
import { ANALYTIC_EVENT } from "../Analytic";
import { useFormContext } from "../context/FormContext";
import CompleteWithoutSyncWarningModal from "../WarningModal/CompleteWithoutSyncWarningModal";

import styles from "./styles.module.scss";
import Info from "Views/Transaction/All/Info";

const isValidDefaultStatus = (defaultStatus: EWorkflowApiStatus) => {
  if ([EWorkflowApiStatus.MISSING_INFO, EWorkflowApiStatus.PENDING_REVIEW].includes(defaultStatus)) {
    return defaultStatus;
  }
  return EWorkflowApiStatus.MISSING_INFO;
};

const PendingReview: React.FC<ITabConfigComponent> = ({
  activeKey,
  defaultStatus,
  connectedUsingExpenseIntegration,
}) => {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [page, setPage] = useState(0);
  const [showMoreActionMenu, setShowMoreActionMenu] = useState(false);
  const [showEmailRemainder, setShowEmailReminder] = useState(false);
  const [showForceSyncModal, setShowForceSyncModal] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [loadingMoreactionMenu, setLoadingMoreactionMenu] = useState(false);
  const [missingFields, setMissingFields] = useState([]);
  const [relatedTransaction, setRelatedTransaction] = useState<ITransaction | null>(null);
  const [status, setActiveStatus] = useState<EWorkflowApiStatus>(isValidDefaultStatus(defaultStatus));
  const [record, setRecord] = useState<ITransaction>({} as ITransaction);
  const { updateTransaction, loadingSaveTransaction, validateTransaction, loadingValidateTransaction } =
    useUpdateTransaction(record, setRecord);
  const { tags } = useFormContext();
  const [selectedIds, setSelectedIds] = useState([]);
  const { select, closeSelect } = useContext<ISelectContext>(SelectContext);

  const [toaster, setToaster] = useState<IToasterProps>({
    show: false,
    message: "",
    type: TOASTER_STATUS_TYPE.SUCCESS,
  });

  const { activePageDetails, setActivePageDetails } = useAccountingTransaction();

  const { filters, onApplyFilter, resetFilter } = useFilter(status);

  useEffect(() => {
    if (activeKey === TAB_NAME.PENDING_REVIEW) {
      setActivePageDetails({
        ...activePageDetails,
        name: activeKey,
        accSubTabStatus: status,
        accountingFilters: filters,
      });
    }
  }, [activeKey, status, filters]);

  const { data, loading } = useSelector((state: any) => state.accountingWorkflowReducer);
  const { editCategoryButton, showErrorToaster } = useBulkEdit(selectedIds, setToaster, setSelectedIds, {
    transactions: data[status].transactions,
    onSuccess() {
      refetch([EWorkflowApiStatus.MISSING_INFO, EWorkflowApiStatus.PENDING_REVIEW, EWorkflowApiStatus.QUEUED]);
      trackEvent(ANALYTIC_EVENT.BULK_EDIT_CATEGORY, { filters, section: status });
    },
  });
  const shouldShowLoading = loading || data[status].transactions[0]?.id === "Mocked_transaction_0";

  const [updateStatus, loadingUpdateStatus] = useLoading(updateTransactionStatus, null, {}, false);

  const uniqueEmployeeCount = getUniqueEmployeeCount(data[status].transactions, selectedIds);

  useEffect(() => {
    refetch([status]);
  }, []);

  useEffect(() => {
    if (activeKey && activeKey !== TAB_NAME.PENDING_REVIEW) {
      clearSelection();
    }
  }, [activeKey]);

  const clearSelection = () => {
    setSelectedIds([]);
    setPage(0);
  };

  const updateAccountingWorkflowStatus = async (transactionIds: string[], action: string, withoutSync?: boolean) => {
    const payload = withoutSync ? { without_xero_sync: true } : {};
    const { data } = await updateStatus(transactionIds, action, payload);

    if (data.status === HTTP_STATUS_CODE.OK) {
      setVisible(false);
      setRecord({
        ...record,
        receipt: data.photo_urls,
      });

      const message = generateSuccessMessage(
        connectedUsingExpenseIntegration,
        status,
        transactionIds.length,
        withoutSync
      );
      setToaster({
        show: true,
        message,
        type: TOASTER_STATUS_TYPE.SUCCESS,
      });
      refetch([EWorkflowApiStatus.MISSING_INFO, EWorkflowApiStatus.PENDING_REVIEW, EWorkflowApiStatus.QUEUED]);

      return data;
    }

    return Promise.reject(data);
  };

  const onForceSync = async () => {
    try {
      await updateAccountingWorkflowStatus([record.id], "FORCE_SYNC");
      trackEvent(ANALYTIC_EVENT.FORCE_SYNC_TRANSACTION, { filters, section: status });
    } catch (err) {
      showErrorToaster(err);
    } finally {
      setVisible(false);
      setShowForceSyncModal(false);
    }
  };

  const refetch = (statuses: EWorkflowApiStatus[]) => {
    statuses.forEach((status, index) => {
      dispatch(
        fetchAccountingWorkflow({ filter: filters, status, limit: PAGE_SIZE, pg: page, skipCount: index !== 0 })
      );
    });
  };

  const openTransactionDetail = (record: ITransaction) => {
    if (select.show) {
      closeSelect();
      return;
    }
    if (shouldShowLoading) return;
    const relatedTransaction = getRelatedTransaction(data[status].transactions, record);
    setRelatedTransaction(relatedTransaction);
    setRecord(record);
    setVisible(true);
  };

  const bulkActionButton =
    status === EWorkflowApiStatus.MISSING_INFO
      ? {
          button: editCategoryButton,
        }
      : {
          button: {
            type: ActionButtonType.Primary,
            label: connectedUsingExpenseIntegration ? "Review & Sync" : "Mark as Completed",
            handler: async () => {
              try {
                await updateStatus(selectedIds, "REVIEWED");
                const count = selectedIds.length;
                const message = generateSuccessMessage(connectedUsingExpenseIntegration, status, count);
                setToaster({
                  show: true,
                  message,
                  type: TOASTER_STATUS_TYPE.SUCCESS,
                });
                refetch([EWorkflowApiStatus.PENDING_REVIEW, EWorkflowApiStatus.QUEUED]);
                trackEvent(ANALYTIC_EVENT.BULK_SYNC, { filters, message });
              } catch (error) {
                showErrorToaster(error);
              } finally {
                setSelectedIds([]);
              }
            },
          },
          secondButton: editCategoryButton,
        };

  const onPageChange = (newPage) => {
    const newPageIndex = newPage - 1;
    let sortData = data[status].transactions[PAGE_SIZE - 1];
    const moveToPreviousPage = page > newPageIndex;

    if (moveToPreviousPage) {
      sortData = data[status].transactions[0];
    }

    setPage(newPageIndex);
    setSelectedIds([]);

    dispatch(
      fetchAccountingWorkflow({
        filter: filters,
        status,
        limit: PAGE_SIZE,
        sortData: sortData?.sort,
        moveToPreviousPage,
        skipCount: true,
      })
    );

    setTimeout(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }, SCROLL_DELAY);
  };

  const tableProps = {
    total: data.transactionCount?.[status] || 0,
    pageNum: page,
    pageSize: PAGE_SIZE,
    dataSource: data[status].transactions,
    onPageChange: onPageChange,
    emptyState: <EmptyState type={workflowStatusMapping(status)} />,
    rowAction: openTransactionDetail,
    paginationOnTop: true,
    paginationProps: paginationProps(PAGE_SIZE, page),
    scrollHorizontal: "fit-content",
    bulkAction: {
      data: selectedIds,
      dataName: "transaction",
      clearSelection: () => setSelectedIds([]),
      supportingInfo: <Info amount={getTotalAmount(data[status].transactions, selectedIds)} />,
      type: BulkActionType.Action_With_Menu,
      ...bulkActionButton,
      showMoreActionMenu,
      setShowMoreActionMenu,
      loadingMoreactionMenu,
      size: defineSize(tags, connectedUsingExpenseIntegration),
      moreActionMenu: (
        <Popover
          onError={showErrorToaster}
          onProcess={() => {
            setLoadingMoreactionMenu(true);
          }}
          onFinish={() => setLoadingMoreactionMenu(false)}
          onSuccess={(message, type) => {
            trackEvent(type === "tax" ? ANALYTIC_EVENT.BULK_EDIT_TAX : ANALYTIC_EVENT.BULK_EDIT_TRANSACTION_TAGS, {
              filters,
              section: status,
            });
            setSelectedIds([]);
            refetch([EWorkflowApiStatus.MISSING_INFO, EWorkflowApiStatus.PENDING_REVIEW, EWorkflowApiStatus.QUEUED]);
            setToaster({
              show: true,
              message,
              type: TOASTER_STATUS_TYPE.SUCCESS,
            });
          }}
          setShowEmailReminder={setShowEmailReminder}
          showMoreActionmenu={showMoreActionMenu}
          setShowMoreActionMenu={setShowMoreActionMenu}
          setShowWarningModal={setShowWarningModal}
          selectedIds={selectedIds}
          status={status}
          connected={connectedUsingExpenseIntegration}
          employeeCount={uniqueEmployeeCount}
        />
      ),
    },
    rowSelection: !shouldShowLoading && {
      preserveSelectedRowKeys: true,
      selectedRowKeys: selectedIds,
      onChange: setSelectedIds,
      onSelectAll: (selectAll) => {
        setSelectedIds(!selectAll ? [] : data[status].transactions.map((item) => item.id));
      },
    },
  };

  if (tableProps.dataSource.length === 0) {
    // Prevent the scroll to be appear when there are no data.
    delete tableProps.scrollHorizontal;
  }

  const isLoadingButton = loadingSaveTransaction || loadingUpdateStatus || loadingValidateTransaction;

  const buttons = [
    {
      id: "SIDE-PANEL-BUTTON-001",
      type: ESidePanelButtonType.PRIMARY,
      enabled: true,
      disabled: isLoadingButton,
      text: connectedUsingExpenseIntegration ? "Save & Sync" : "Save & Complete",
      loading: isLoadingButton,
      action: async () => {
        try {
          await updateTransaction({ preventDoubleSync: true });
          if (status === EWorkflowApiStatus.MISSING_INFO && allowToUpdateTransaction(record?.transactionType)) {
            const { isValid, missingFields } = await validateTransaction();
            setShowForceSyncModal(!isValid);
            setMissingFields(missingFields);
            if (!isValid) {
              return;
            }
          }
          const action = status === EWorkflowApiStatus.MISSING_INFO ? "FORCE_SYNC" : "REVIEWED";
          await updateAccountingWorkflowStatus([record.id], action);
          const message = generateSuccessMessage(connectedUsingExpenseIntegration, status, 1);
          setToaster({
            show: true,
            message,
            type: TOASTER_STATUS_TYPE.SUCCESS,
          });
        } catch (error) {
          setVisible(false);
          showErrorToaster(error);
        } finally {
          setSelectedIds([]);
        }
      },
    },
    {
      id: "SIDE-PANEL-BUTTON-002",
      type: ESidePanelButtonType.SECONDARY,
      enabled: allowToUpdateTransaction(record?.transactionType),
      disabled: isLoadingButton,
      text: "Save",
      loading: isLoadingButton,
      action: async () => {
        try {
          await updateTransaction();
          setVisible(false);
          setToaster({
            show: true,
            message: "1 transaction successfully saved.",
            type: TOASTER_STATUS_TYPE.SUCCESS,
          });
          refetch([EWorkflowApiStatus.MISSING_INFO, EWorkflowApiStatus.PENDING_REVIEW]);
        } catch (error) {
          setVisible(false);
          showErrorToaster(error);
        } finally {
          setSelectedIds([]);
        }
      },
    },
  ];

  const actions: IActionItem[] = generateActions({
    filter: filters,
    onApplyFilter,
    resetFilter,
    clearSelection,
    status: () => status,
  });
  const subTab: IFilter[] = generateSubTab(status, data);

  return (
    <DataTable className={styles["pending-review"]} size={DATA_TABLE_SIZE_TYPES.LARGE}>
      <AccountingWorkflowFilter
        filter={subTab}
        onClick={(filter) => {
          clearSelection();
          setActiveStatus(filter.status);
          dispatch(fetchAccountingWorkflow({ filter: filters, status: filter.status }));
        }}
      />
      <ActionsBar>{actions}</ActionsBar>
      <Table<ITransaction> {...tableProps}>
        {columnData(status, shouldShowLoading, setToaster, () => refetch([status]))}
      </Table>
      <TransactionSidePanel
        type={record.transactionType}
        visible={visible}
        setVisible={setVisible}
        buttons={buttons.filter((item) => item.enabled)}
        currentStatusId={record.currentStatusId}
        record={record}
        relatedTransaction={relatedTransaction}
        onClickRelatedTransaction={() => {
          openTransactionDetail(relatedTransaction);
        }}
        ignoreRequiredCheck={status === EWorkflowApiStatus.PENDING_REVIEW}
      />
      <Toaster
        size={TOASTER_SIZE_TYPE.M}
        visible={toaster.show}
        status={toaster.type}
        message={toaster.message}
        onClose={() => setToaster({ ...toaster, show: false })}
      />

      <EmailReminder
        visible={showEmailRemainder}
        loading={false}
        count={uniqueEmployeeCount}
        onClose={() => setShowEmailReminder(false)}
        transactionIds={selectedIds}
        showErrorToaster={showErrorToaster}
        onSendRemainder={(employee) => {
          setShowMoreActionMenu(false);
          setShowEmailReminder(false);
          setToaster({
            show: true,
            message: `The reminder emails have been successfully sent to ${uniqueEmployeeCount} ${employee}.`,
            type: TOASTER_STATUS_TYPE.SUCCESS,
          });
          trackEvent(ANALYTIC_EVENT.SEND_EMAIL_REMINDER, { filters, section: status });
        }}
      />

      <ForceSync
        visible={showForceSyncModal}
        loading={loadingUpdateStatus}
        onClose={() => setShowForceSyncModal(false)}
        onForceSync={onForceSync}
        missingFields={missingFields}
        connected={connectedUsingExpenseIntegration}
      />
      <CompleteWithoutSyncWarningModal
        showWarningModal={showWarningModal}
        setShowWarningModal={setShowWarningModal}
        loadingUpdateStatus={loadingUpdateStatus}
        handleProceedWithoutSyncClick={() =>
          updateAccountingWorkflowStatus(selectedIds, "REVIEWED", true).then(() => {
            clearSelection();
            setShowWarningModal(false);
          })
        }
      />
    </DataTable>
  );
};

export default PendingReview;
