import React, { useCallback, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getTeamListByUserId, getTeamMembersByTeamId } from "Redux/Actions";

import { MoreFilter, invalidAmountErrorMsg } from "Modules/DS/Filter";
import Menu, { ILineItem } from "Modules/DS/Menu";
import Select, { ISelectContext, SelectContext } from "Modules/DS/Select";
import { EWorkflowApiStatus } from "Views/Accounting/Workflow/type";
import { attachmentOptions } from "Views/Transaction/Filters/Attachment";

import { ITransactionFilter } from "./index";
import { sanitizeObject } from "Views/Transaction/Helper";
import MultiSelectDropdownFilter from "./MultiSelectDropdownFilter";

interface ITransactionMoreFilter {
  applyFilter: (filter: any, callApi?: boolean) => void;
  resetFilter: (keys: string[], callApi?: boolean) => void;
  filter: ITransactionFilter;
  accountingStatus: EWorkflowApiStatus;
}

type IAccountingMoreFilter = Pick<
  ITransactionFilter,
  "max_amount" | "min_amount" | "requester_ids" | "attachment" | "team_ids"
>;

export interface IAccountingMoreFilterItem {
  filter: IAccountingMoreFilter;
  applyFilter: <R extends unknown>(item: string, value: R) => void;
  resetFilter: (item: string) => void;
  clear: boolean;
  dispatchOnAdd?: (id: string | string[]) => void;
  dispatchOnRemove?: () => void;
  onClickOutside?: () => void;
  filterId?: string;
  defaultValue?: string;
  data?: any;
  loading?: boolean;
}

const AttachmentFilter = ({
  applyFilter,
  resetFilter,
  filter,
  clear,
  dispatchOnAdd,
  dispatchOnRemove,
  onClickOutside,
}: IAccountingMoreFilterItem) => {
  const id = "attachment";

  const [title, setTitle] = useState("");
  const [icon, setIcon] = useState("");
  const [selected, setSelected] = useState("");

  const onSelectHandler = (value: string) => {
    applyFilter(id, value);
    dispatchOnAdd?.(value);
  };

  const handleClearDropdown = () => {
    resetFilter(id);

    dispatchOnRemove?.();
  };

  const handleOnClickOutside = () => {
    onClickOutside?.();
    !filter[id] && setSelected("");
  };

  return (
    <Menu.Row id={id} title="Attachment/ Receipt">
      <Select.Dropdown.Single<ILineItem>
        dropdownProps={{ id, labelIcon: icon, containerClass: ".data-table-container" }}
        defaultValue="Attachment/ Receipt"
        idKey="id"
        displayKey="title"
        iconKey="src"
        parentPopoverId="more-filter-select__6"
        clear={clear}
        presetValue={filter.attachment}
        states={{
          selected,
          setSelected,
          setTitle,
          title,
          icon,
          setIcon,
        }}
        onSelect={onSelectHandler}
        clearDropdown={handleClearDropdown}
        onClickOutside={handleOnClickOutside}
      >
        {attachmentOptions()}
      </Select.Dropdown.Single>
    </Menu.Row>
  );
};

const TransactionMoreFilter = ({ applyFilter, resetFilter, filter, accountingStatus }: ITransactionMoreFilter) => {
  const dispatch = useDispatch();

  const { closeSelect } = useContext<ISelectContext>(SelectContext);
  const [clear, setClear] = useState(false);
  const [showInvalidAmountError, setShowInvalidAmountError] = useState(false);

  const moreFilters: string[] = ["team_ids", "requester_ids", "min_amount", "max_amount", "attachment"];

  const [more, setMore] = useState<IAccountingMoreFilter>({} as IAccountingMoreFilter);

  const budgetsLoading = useSelector((state: any) => state.teamListByUserIdReducer?.loading);
  const budgetsData = useSelector((state: any) => state.teamListByUserIdReducer?.data || []);

  const employeesLoading = useSelector((state: any) => state.teamMembersByTeamIdReducer?.loading);
  const employeesData = useSelector((state: any) => state.teamMembersByTeamIdReducer?.data || []);

  useEffect(() => {
    const treatedFilters = sanitizeObject<IAccountingMoreFilter>({ ...filter });
    moreFilters.forEach((key) => treatedFilters[key] && (more[key] = treatedFilters[key]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const applyMoreFilter = <R extends unknown>(item: string, value: R) => setMore({ ...more, [item]: value });

  const clearMoreFilter = (item: string) => {
    setMore((prev) => {
      delete prev[item];
      if (
        Object.keys(filter)
          .filter((key) => moreFilters.includes(key))
          .includes(item)
      ) {
        delete filter[item];
      }
      return { ...prev };
    });
  };

  const resetMoreFilter = () => setMore({} as IAccountingMoreFilter);

  const dispatchTeamListAction = useCallback(
    (id) => {
      dispatch(getTeamListByUserId(id));
    },
    [dispatch]
  );

  const dispatchTeamMemberAction = useCallback(
    (id) => {
      dispatch(getTeamMembersByTeamId(id));
    },
    [dispatch]
  );

  useEffect(() => {
    dispatchTeamListAction(undefined);
  }, [dispatchTeamListAction]);

  useEffect(() => {
    dispatchTeamMemberAction(undefined);
  }, [dispatchTeamMemberAction]);

  useEffect(() => {
    if (Object.keys(filter).filter((key) => more[key]).length === 0 && clear) setClear(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clear]);

  const clearFilterHandler = () => {
    resetFilter(moreFilters);
    resetMoreFilter();
    closeSelect();
    setClear(true);
    setShowInvalidAmountError(false);
  };

  const onClearHandler = () => {
    resetFilter(moreFilters, false);
    resetMoreFilter();
    setClear(true);
    setShowInvalidAmountError(false);
  };

  const onApplyHandler = () => {
    const isInvalidAmount = more.max_amount >= 0 && more.min_amount > more.max_amount;
    setShowInvalidAmountError(isInvalidAmount);
    if (!isInvalidAmount) {
      applyFilter(more);
      closeSelect();
    }
  };

  const _filters: string[] = Object.keys(filter)
    .filter((key) => filter[key])
    .filter((key) => moreFilters.includes(key) && more[key]);

  return (
    <MoreFilter
      id="6"
      useDynamicPosition
      filters={_filters}
      clearFilter={clearFilterHandler}
      onApply={onApplyHandler}
      onClear={onClearHandler}
      clear={clear}
      containerClass={".data-table-container"}
    >
      <MultiSelectDropdownFilter
        applyFilter={applyMoreFilter}
        resetFilter={clearMoreFilter}
        filter={more}
        clear={clear}
        filterId="team_ids"
        defaultValue="Budget"
        loading={budgetsLoading}
        data={budgetsData}
      />
      <MultiSelectDropdownFilter
        applyFilter={applyMoreFilter}
        resetFilter={clearMoreFilter}
        filter={more}
        clear={clear}
        filterId="requester_ids"
        defaultValue="Employee"
        loading={employeesLoading}
        data={employeesData}
      />

      {![EWorkflowApiStatus.MISSING_INFO, EWorkflowApiStatus.PENDING_REVIEW].includes(accountingStatus) && (
        <AttachmentFilter applyFilter={applyMoreFilter} resetFilter={clearMoreFilter} filter={more} clear={clear} />
      )}

      <Menu.Row id="amount" title="Amount">
        <Select.Range.Input<IAccountingMoreFilter>
          filter={more}
          clear={clear}
          onApply={(key, value) => applyMoreFilter<number>(key, value)}
        />
      </Menu.Row>
      {showInvalidAmountError && (
        <Menu.Row id="inputError" title="">
          {invalidAmountErrorMsg()}
        </Menu.Row>
      )}
    </MoreFilter>
  );
};

export default TransactionMoreFilter;
