import React, { Fragment, useState } from "react";
import classNames from "classnames";
import { Col, Radio, Form } from "antd";
import { Button, Typography, Banner, InfoFilled } from "@spenmo/splice";

import { PARTNER_TYPE } from "Views/Accounting/Settings/helper/constant";
import DSInput from "Modules/DS/Input";
import { GetCurrencyCode } from "utility";

import Select from "../Select";

import styles from "./styles.module.scss";

export const selectXeroBankAccountBodyContent =
  "Spenmo will automatically synchronize transactions to Xero to this selected bank account on an hourly basis.";

export const accountNameEmptyErrorMsg = "Bank Account Name cannot be empty";
export const accountNumberEmptyErrorMsg = "Account Number cannot be empty";

const CreateOrChooseBankAccount = ({
  accountType,
  selectedBankAccount,
  bankAccounts,
  onChangeHandler,
  form,
  loading,
  error,
  clearError,
  partnerName = "Xero",
  buttonTitle = "Next",
  finishFetchBankAccount = true,
}) => {
  const defaultAlphanumericHelpText = "Only alphanumeric characters and spaces are allowed.";
  const defaultAccountNumberHelpText = `Enter a number for easier identification in ${partnerName}. ${defaultAlphanumericHelpText.replace(
    " and spaces",
    ""
  )}`;

  const [accountNumberHelpText, setAccountNumberHelpText] = useState(defaultAccountNumberHelpText);
  const [bankAccountNameHelpText, setBankAccountNameHelpText] = useState(defaultAlphanumericHelpText);

  const accountOnChangeHandler = (event) => {
    const { value } = event?.target || {};
    value && onChangeHandler(value, accountType);
    setBankAccountNameHelpText(defaultAlphanumericHelpText);
    setAccountNumberHelpText(defaultAccountNumberHelpText);
  };

  const updateHelpTextOfInputFields = (inputFieldName: string, errorOccurred = false) => {
    if (inputFieldName === "accountName") {
      setBankAccountNameHelpText(errorOccurred ? accountNameEmptyErrorMsg : defaultAlphanumericHelpText);
    } else {
      setAccountNumberHelpText(errorOccurred ? accountNumberEmptyErrorMsg : defaultAccountNumberHelpText);
    }
  };

  const validatorFn = (fieldValue: string, inputFieldName?: string) => {
    if (!fieldValue || (!inputFieldName && fieldValue.includes("Select Existing Account"))) {
      inputFieldName && updateHelpTextOfInputFields(inputFieldName, true);
      return Promise.reject(!inputFieldName ? "Select a bank account from the list" : "");
    }
    inputFieldName && updateHelpTextOfInputFields(inputFieldName);
    return Promise.resolve();
  };

  const bankAccountOptions = [
    {
      id: "Add New Account",
      text: `Add a new bank account in ${partnerName}`,
      children: (
        <div className={styles.children}>
          <Typography
            variant="body-content"
            size="s"
            tag="h5"
            children={`An account will be created for you in ${partnerName} in your Spenmo dashboard’s currency (${GetCurrencyCode()})`}
          />
          <Form.Item
            name="accountName"
            label={<span>Bank Account Name</span>}
            initialValue="Spenmo"
            rules={[{ validator: (rule, value: string) => validatorFn(value, "accountName") }]}
            help={!error ? bankAccountNameHelpText : null}
            validateTrigger="onBlur"
          >
            <DSInput
              placeholder="Enter a Bank Account Name"
              onBlur={clearError}
              restrictedCharacters={new RegExp(/[^A-Za-z\d\s]/g)}
            />
          </Form.Item>
          <Form.Item
            name="accountNumber"
            label={<span>Account Number</span>}
            rules={[{ validator: (rule, value: string) => validatorFn(value, "accountNumber") }]}
            help={accountNumberHelpText}
            validateTrigger="onBlur"
          >
            <DSInput placeholder="Enter an Account Number" restrictedCharacters={new RegExp(/[^A-Za-z\d]/g)} />
          </Form.Item>
        </div>
      ),
    },
    {
      id: "Select Existing Account",
      text: `Select an existing bank account in ${partnerName}`,
      children: (
        <div className={styles.children}>
          <Select
            initialValue={selectedBankAccount}
            placeholder="Select bank account"
            label=""
            onChange={(value) => onChangeHandler(value, accountType)}
            rules={[{ validator: (rule, value: string) => validatorFn(value) }]}
            name="xero_bank_account_id"
            data={bankAccounts?.map((bankAccount) => ({
              key: bankAccount.id,
              value: bankAccount.id,
              text: `${bankAccount.number}-${bankAccount.name}`,
            }))}
            disabled={!finishFetchBankAccount}
          />

          {!finishFetchBankAccount && (
            <div className={styles.banner}>
              <Banner
                variant="info"
                icon={InfoFilled}
                title="Awaiting bank account details from NetSuite"
                description="Fetching details from NetSuite may take up to 5 minutes. If this is taking too long, check back again later to complete NetSuite integration setup."
              />
            </div>
          )}
        </div>
      ),
    },
  ];

  const selectedOption =
    bankAccountOptions.find((option) => option.id === form.getFieldValue("bank_account_selection")) ||
    bankAccountOptions[0];
  const emptyBankAccount = bankAccounts.length === 0;

  return (
    <Col className={styles.column} span={14}>
      <Typography
        variant="headline-brand"
        size="s"
        tag="h3"
        children={`Select a ${partnerName} Bank Account`}
        className={classNames({
          [styles.netsuite]: partnerName === PARTNER_TYPE.NETSUITE,
        })}
      />
      {partnerName === "Xero" && (
        <Typography variant="body-content" size="m" tag="h5" children={selectXeroBankAccountBodyContent} />
      )}
      <div className={styles.bankAccount}>
        <Form.Item name="bank_account_selection" initialValue={selectedOption.id}>
          <Radio.Group
            className={classNames("sp-radio-group")}
            onChange={accountOnChangeHandler}
            disabled={emptyBankAccount && finishFetchBankAccount}
          >
            {bankAccountOptions.map((bankAccount) => (
              <Fragment key={bankAccount.id}>
                <Radio value={bankAccount.id}>{bankAccount.text}</Radio>
                {selectedOption?.id === bankAccount.id && bankAccount.children}
              </Fragment>
            ))}
          </Radio.Group>
        </Form.Item>
        {emptyBankAccount && finishFetchBankAccount && (
          <Typography
            variant="body-content"
            size="s"
            tag="p"
            children="No bank account found in your selected organization"
            className={styles.emptyBankAccount}
          />
        )}
      </div>
      <Button type="submit" variant="primary" size="l" loading={loading} className={styles.connectButton}>
        {buttonTitle}
      </Button>
    </Col>
  );
};

export default CreateOrChooseBankAccount;
