import axios from "axios";
import { axiosHeader } from "Redux/Actions";
import { GetBaseAuthObject } from "utility";

export const FETCH_USER_WITH_TEAM_REQUEST = "FETCH_USER_WITH_TEAM_REQUEST";
export const FETCH_USER_WITH_TEAM_SUCCESS = "FETCH_USER_WITH_TEAM_SUCCESS";
export const FETCH_USER_WITH_TEAM_FAILURE = "FETCH_USER_WITH_TEAM_FAILURE";

export const fetchGetDataWithTeamReq = (data) => {
  return {
    type: FETCH_USER_WITH_TEAM_REQUEST,
    data: data,
  };
};

export const fetchGetDataWithTeamSuccess = (data) => {
  return {
    type: FETCH_USER_WITH_TEAM_SUCCESS,
    data: {
      payload: data,
    },
  };
};

export const fetchGetDataWithTeamError = (data) => {
  return {
    type: FETCH_USER_WITH_TEAM_FAILURE,
    data: data,
  };
};

export const UserInfoWithTeamData = () => {
  return (dispatch) => {
    dispatch(fetchGetDataWithTeamReq());
    axios
      .get(
        /*eslint no-process-env: "off"*/
        `${window.__ENV__.API_BASE_URL}/api/v1/user/${GetBaseAuthObject().userId}?organisation_id=${
          GetBaseAuthObject().orgId
        }`,
        axiosHeader
      )
      .then((data) => {
        if (data.data.status > 200) {
          dispatch(fetchGetDataWithTeamError(data.data));
        } else {
          dispatch(fetchGetDataWithTeamSuccess(data.data.payload));
        }
      })
      .catch((err) => {
        dispatch(fetchGetDataWithTeamError(err));
      });
  };
};
