import APIclient from "API/Client";
import { CreateApprovalFlowPayload } from "Views/ApprovalFlow/types";

export const CREATE_APPROVAL_FLOW_REQUEST = "CREATE_APPROVAL_FLOW_REQUEST";
export const CREATE_APPROVAL_FLOW_SUCCESS = "CREATE_APPROVAL_FLOW_SUCCESS";
export const CREATE_APPROVAL_FLOW_FAILURE = "CREATE_APPROVAL_FLOW_FAILURE";
export const CLEAR_CREATE_APPROVAL_FLOW = "CLEAR_CREATE_APPROVAL_FLOW";

const createApprovalFlowReq = (data: CreateApprovalFlowPayload) => {
  return {
    type: CREATE_APPROVAL_FLOW_REQUEST,
    data,
  };
};
const createApprovalFlowSuccess = (data?: any) => {
  return {
    type: CREATE_APPROVAL_FLOW_SUCCESS,
    data,
  };
};
const createApprovalFlowError = (data?: any) => {
  return {
    type: CREATE_APPROVAL_FLOW_FAILURE,
    data,
  };
};

export const clearCreateApprovalFlow = () => {
  return {
    type: CLEAR_CREATE_APPROVAL_FLOW,
  };
};

export const createApprovalFlowFunc = (payload: CreateApprovalFlowPayload) => {
  return (dispatch) => {
    dispatch(createApprovalFlowReq(payload));

    APIclient.postData(`/ms/spm-organization/v2/approval/workflow/`, payload)
      .then((data) => {
        dispatch(createApprovalFlowSuccess(data.data));
      })
      .catch((err) => {
        const errorDetail = err?.response?.data?.error || err;
        dispatch(
          createApprovalFlowError({
            ...errorDetail,
            data: err?.response?.data?.payload,
          })
        );
      });
  };
};

export const editApprovalFlowFunc = (id: string, payload: CreateApprovalFlowPayload) => {
  return (dispatch) => {
    dispatch(createApprovalFlowReq(payload));

    APIclient.putData(`/ms/spm-organization/v2/approval/workflow/${id}`, payload)
      .then((data: any) => {
        dispatch(createApprovalFlowSuccess(data.data));
      })
      .catch((err) => {
        const errorDetail = err?.response?.data?.error || err;
        dispatch(
          createApprovalFlowError({
            ...errorDetail,
            data: err?.response?.data?.payload,
          })
        );
      });
  };
};
