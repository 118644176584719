import React, { useContext } from "react";
import { Typography, Loader } from "@spenmo/splice";
import { EmployeesModalContext, IEmployeesModalContext } from "Views/Employee/context";

import styles from "./Processing.module.scss";

const Processing = () => {
  const { showModal }: IEmployeesModalContext = useContext(EmployeesModalContext);
  const { employeeName } = showModal.metaData || {};

  return (
    <div className={styles.container}>
      <Loader.Spinner size="l" variant="brand" />
      <Typography variant="headline-content" size="m" className={styles.title}>
        Role switching in progress..
      </Typography>
      <Typography variant="body-content" size="s" className={styles.description}>
        Please wait while we are changing {employeeName} role
      </Typography>
    </div>
  );
};
export default Processing;
