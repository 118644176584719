import { FETCH_PASSWORD_REQUEST, FETCH_PASSWORD_SUCCESS, FETCH_PASSWORD_FAILURE } from "../Actions/ResetPasswordAction";
import { userInitialState } from "../InitialState";

export const resetPasswordReducer = (state = userInitialState, action) => {
  switch (action.type) {
    case FETCH_PASSWORD_REQUEST:
      return {
        ...state,
        data: action.data,
        loading: true,
        error: false,
      };
    case FETCH_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: false,
        successMessage: action.message,
      };
    case FETCH_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: true,
        errorMessage: action.message,
      };
    default:
      return state;
  }
};
