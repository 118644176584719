import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { darkGreyCloseIcon, greyCloseIcon } from "../../assets/img";
import Icon from "../icons";
import "./modal.scss";

const Modal = ({
  close,
  show = false,
  children,
  title,
  className = "",
  subtitle,
  lightCloseIcon = false,
  breakLine = false,
}) => {
  useEffect(() => {
    const closeOnEsc = (e) => {
      if (e.keyCode === 27 || e.code === "Escape") {
        close?.();
      }
    };

    window.addEventListener("keydown", closeOnEsc);
    return () => window.removeEventListener("keydown", closeOnEsc);
  }, [show, close]);

  return (
    show && (
      <div
        className={"modal"}
        id="modal"
        onMouseDown={(e) => {
          e.stopPropagation();
          close && close();
        }}
      >
        <div
          className={`modal__content ${className}`}
          onMouseDown={(e) => {
            e.stopPropagation();
          }}
        >
          {close && (
            <Icon
              src={lightCloseIcon ? greyCloseIcon : darkGreyCloseIcon}
              alt="close"
              className={"modal__close"}
              action={close}
            />
          )}
          {title && <h4 className={"modal__title"}>{title}</h4>}
          {breakLine && <hr className={"modal__breakline"} />}
          {subtitle && <p className={"modal__subtitle"}>{subtitle}</p>}
          <div className={"modal__content--children-elements"}>{children && children}</div>
        </div>
      </div>
    )
  );
};

Modal.propTypes = {
  close: PropTypes.func,
  show: PropTypes.bool,
  children: PropTypes.any,
  title: PropTypes.string,
  className: PropTypes.string,
  subtitle: PropTypes.string,
  lightCloseIcon: PropTypes.bool,
  breakLine: PropTypes.bool,
};

export default Modal;
