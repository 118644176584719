import React, { useMemo, useState } from "react";

import { IKYXModalState } from "Views/KYX/@types";
import { KYX_MODAL_TYPES } from "Views/KYX/Constants";
import { KYXModalContext, initialKYXModalState } from "Views/KYX/Context";

/**
 * KYXModalProvider component that provides modal functionality to its children components.
 * @param {React.ReactNode} children - The children components to render within the KYXModalProvider.
 * @returns {JSX.Element} The KYXModalContext.Provider with the context values.
 */
export const KYXModalProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const [modal, setModal] = useState<IKYXModalState>(initialKYXModalState);

  const resetModal = () => setModal({ ...initialKYXModalState, visible: false });

  const toggleModal = (type: KYX_MODAL_TYPES, payload?: object): void =>
    setModal({ visible: true, type: type, payload: payload || initialKYXModalState.payload });

  const value = useMemo(
    () => ({
      modal,
      setModal,
      resetModal,
      toggleModal,
    }),
    [modal]
  );

  return <KYXModalContext.Provider value={value}>{children}</KYXModalContext.Provider>;
};
