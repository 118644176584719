import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { plural } from "utility";
import useLoading from "utility/useLoading";
import { trackEvent } from "utility/analytics";
import DataTable, {
  ActionsBar,
  Table,
  ActionButtonType,
  BulkActionType,
  DATA_TABLE_SIZE_TYPES,
  IActionItem,
} from "Modules/DS/DataTable";
import { TOASTER_SIZE_TYPE, TOASTER_STATUS_TYPE } from "Modules/DS/Toaster/types.d";
import Toaster from "Modules/DS/Toaster";
import { ESidePanelButtonType } from "Modules/DS/SidePanel";
import { ISelectContext, SelectContext } from "Modules/DS/Select";
import { IToasterProps } from "Views/Accounting/Settings/@types";
import Info from "Views/Transaction/All/Info";
import {
  fetchAccountingWorkflow,
  workflowStatusMapping,
} from "Redux/Reducers/AccountingWorkflow/accountingWorkflow.slice";
import { updateTransactionStatus } from "Redux/DataCalls/AccountingWorkflow.api";
import { useAccountingTransaction } from "Views/Transaction/Context";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";

import { EWorkflowApiStatus, EWorkflowStatus, IFilter, ITabConfigComponent, ITransaction } from "../type";
import { columnData } from "../ColumnData";
import AccountingWorkflowFilter from "../Filter";
import TransactionSidePanel from "../SidePanel";
import EmptyState from "../EmptyState";
import { PAGE_SIZE, SCROLL_DELAY, TAB_NAME } from "../constant/constant";
import { useFilter } from "../hooks/useFilter";
import { useUpdateTransaction } from "../hooks/useUpdateTransaction";
import Popover from "../BulkAction/Popover";
import { useBulkEdit } from "../hooks/useBulkEdit";
import { useFormContext } from "../context/FormContext";
import { defineSize } from "./helper";
import {
  getRelatedTransaction,
  generateActions,
  generateSuccessMessage,
  paginationProps,
  getTotalAmount,
} from "../PendingReview/helper";
import { ANALYTIC_EVENT } from "../Analytic";
import CompleteWithoutSyncWarningModal from "../WarningModal/CompleteWithoutSyncWarningModal";

import styles from "./styles.module.scss";

const isValidDefaultStatus = (defaultStatus: EWorkflowApiStatus) => {
  if ([EWorkflowApiStatus.QUEUED, EWorkflowApiStatus.FAILED_SYNC].includes(defaultStatus)) {
    return defaultStatus;
  }
  return EWorkflowApiStatus.QUEUED;
};

const PendingSync: React.FC<ITabConfigComponent> = ({ activeKey, defaultStatus, connectedUsingExpenseIntegration }) => {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [record, setRecord] = useState<ITransaction>({} as ITransaction);
  const { loadingSaveTransaction, updateTransaction } = useUpdateTransaction(record, setRecord);
  const [page, setPage] = useState(0);
  const [status, setActiveStatus] = useState<EWorkflowApiStatus>(isValidDefaultStatus(defaultStatus));
  const [showMoreActionMenu, setShowMoreActionMenu] = useState(false);
  const [loadingMoreactionMenu, setLoadingMoreactionMenu] = useState(false);
  const [relatedTransaction, setRelatedTransaction] = useState<ITransaction | null>(null);
  const [toaster, setToaster] = useState<IToasterProps>({
    show: false,
    message: "",
    type: TOASTER_STATUS_TYPE.SUCCESS,
  });
  const [showWarningModal, setShowWarningModal] = useState(false);

  const { activePageDetails, setActivePageDetails } = useAccountingTransaction();

  const { tags } = useFormContext();
  const { filters, onApplyFilter, resetFilter } = useFilter(status);
  const { select, closeSelect } = useContext<ISelectContext>(SelectContext);

  const fetchData = (params = {}) => {
    dispatch(fetchAccountingWorkflow({ filter: filters, status, limit: PAGE_SIZE, ...params }));
  };

  const { data, loading } = useSelector((state: any) => state.accountingWorkflowReducer);
  const { editCategoryButton, showErrorToaster } = useBulkEdit(selectedIds, setToaster, setSelectedIds, {
    transactions: data[status].transactions,
    onSuccess() {
      fetchData();
      trackEvent(ANALYTIC_EVENT.BULK_EDIT_CATEGORY, { filters, section: status });
    },
  });
  const shouldShowLoading = loading || data[status].transactions[0]?.id === "Mocked_transaction_0";

  useEffect(() => {
    if (activeKey === TAB_NAME.PENDING_SYNC) {
      setActivePageDetails({
        ...activePageDetails,
        name: activeKey,
        accSubTabStatus: status,
        accountingFilters: filters,
      });
    }
  }, [activeKey, status, filters]);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (activeKey && activeKey !== TAB_NAME.PENDING_SYNC) {
      clearSelection();
    }
  }, [activeKey]);

  const [updateStatus, loadingUpdateStatus] = useLoading(updateTransactionStatus, null, {}, false);
  const updateTransactionWorkflowStatus: (
    isRetrySyncAction: boolean,
    transactionIds: string[],
    action?: string
  ) => Promise<object> = async (isRetrySyncAction, transactionIds, action) => {
    const payload = !isRetrySyncAction ? { without_xero_sync: true } : {};
    const { data } = await updateStatus(transactionIds, isRetrySyncAction ? "RETRY_SYNC" : action, payload);

    if (data.status === HTTP_STATUS_CODE.OK) {
      setVisible(false);
      !isRetrySyncAction &&
        setRecord({
          ...record,
          receipt: data.photo_urls,
        });
      const message = isRetrySyncAction
        ? `${plural(
            transactionIds.length,
            "transaction"
          )} queued to be synced. You can check the transaction sync status again in a few moments.`
        : generateSuccessMessage(connectedUsingExpenseIntegration, status, transactionIds.length, true);
      setToaster({
        show: true,
        message,
        type: TOASTER_STATUS_TYPE.SUCCESS,
      });
      fetchData({
        status: EWorkflowApiStatus.FAILED_SYNC,
      });
      fetchData({
        status: EWorkflowApiStatus.QUEUED,
        skipCount: true,
      });
      return data;
    }
    return Promise.reject(data);
  };

  const clearSelection = () => {
    setSelectedIds([]);
    setPage(0);
  };

  const openTransactionDetail = (record: ITransaction) => {
    if (select.show) {
      closeSelect();
      return;
    }
    if (shouldShowLoading) return;

    setRelatedTransaction(getRelatedTransaction(data[status].transactions, record));
    setVisible(true);
    setRecord(record);
  };

  const onPageChange = (newPage) => {
    const newPageIndex = newPage - 1;
    let sortData = data[status].transactions[PAGE_SIZE - 1];
    const moveToPreviousPage = page > newPageIndex;

    if (moveToPreviousPage) {
      sortData = data[status].transactions[0];
    }

    setPage(newPageIndex);
    setSelectedIds([]);
    fetchData({
      sortData: sortData?.sort,
      moveToPreviousPage,
      skipCount: true,
    });
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }, SCROLL_DELAY);
  };

  const tableProps = {
    total: data.transactionCount?.[status] || 0,
    pageNum: page,
    onPageChange: onPageChange,
    pageSize: PAGE_SIZE,
    dataSource: data[status].transactions,
    emptyState: <EmptyState type={workflowStatusMapping(status)} />,
    rowAction: openTransactionDetail,
    paginationOnTop: true,
    paginationProps: paginationProps(PAGE_SIZE, page),
    scrollHorizontal: "fit-content",
    bulkAction: {
      data: selectedIds,
      dataName: "transaction",
      clearSelection: () => setSelectedIds([]),
      supportingInfo: <Info amount={getTotalAmount(data[status].transactions, selectedIds)} />,
      type: BulkActionType.Action_With_Menu,
      button: {
        type: ActionButtonType.Primary,
        label: "Retry Sync",
        handler: async () => {
          try {
            await updateTransactionWorkflowStatus(true, selectedIds);
            trackEvent(ANALYTIC_EVENT.BULK_RETRY_SYNC, { filters });
            return { status: "Success" };
          } catch (error) {
            setVisible(false);
            showErrorToaster(error);
          } finally {
            setSelectedIds([]);
          }
        },
      },
      secondButton: editCategoryButton,
      showMoreActionMenu,
      setShowMoreActionMenu,
      loadingMoreactionMenu,
      size: defineSize(tags),
      moreActionMenu: (
        <Popover
          onError={showErrorToaster}
          onProcess={() => {
            setLoadingMoreactionMenu(true);
          }}
          onFinish={() => setLoadingMoreactionMenu(false)}
          onSuccess={(message, type) => {
            trackEvent(type === "tax" ? ANALYTIC_EVENT.BULK_EDIT_TAX : ANALYTIC_EVENT.BULK_EDIT_TRANSACTION_TAGS, {
              filters,
              section: status,
            });
            setSelectedIds([]);
            fetchData();
            setToaster({
              show: true,
              message,
              type: TOASTER_STATUS_TYPE.SUCCESS,
            });
          }}
          showMoreActionmenu={showMoreActionMenu}
          setShowMoreActionMenu={setShowMoreActionMenu}
          selectedIds={selectedIds}
          status={status}
          connected={connectedUsingExpenseIntegration}
          setShowWarningModal={setShowWarningModal}
        />
      ),
    },
    rowSelection: !shouldShowLoading && {
      preserveSelectedRowKeys: true,
      selectedRowKeys: selectedIds,
      onChange: setSelectedIds,
      onSelectAll: (selectAll) => {
        setSelectedIds(!selectAll ? [] : data[status].transactions.map((item) => item.id));
      },
    },
  };

  if (tableProps.dataSource.length === 0) {
    // Prevent the scroll to be appear when there are no data.
    delete tableProps.scrollHorizontal;
  }

  const buttons = [
    {
      id: "SIDE-PANEL-BUTTON-001",
      type: ESidePanelButtonType.PRIMARY,
      disabled: loadingUpdateStatus || loadingSaveTransaction,
      text: "Retry Sync",
      loading: loadingUpdateStatus || loadingSaveTransaction,
      action: async () => {
        try {
          await updateTransaction({ preventDoubleSync: true });
          await updateTransactionWorkflowStatus(true, [record.id]);
        } catch (error) {
          setVisible(false);
          showErrorToaster(error);
        }
      },
    },
  ];
  const actions: IActionItem[] = generateActions({
    filter: filters,
    onApplyFilter,
    resetFilter,
    clearSelection,
    status: () => status,
  });

  const subTab: IFilter[] = [
    {
      id: 1,
      active: status === EWorkflowApiStatus.QUEUED,
      text: EWorkflowStatus.IN_QUEUE,
      counter: data.count[EWorkflowApiStatus.QUEUED],
      status: EWorkflowApiStatus.QUEUED,
    },
    {
      id: 2,
      active: status === EWorkflowApiStatus.FAILED_SYNC,
      text: EWorkflowStatus.SYNC_FAILED,
      counter: data.count[EWorkflowApiStatus.FAILED_SYNC],
      status: EWorkflowApiStatus.FAILED_SYNC,
    },
  ];

  return (
    <DataTable className={styles["pending-sync"]} size={DATA_TABLE_SIZE_TYPES.LARGE}>
      <AccountingWorkflowFilter
        filter={subTab}
        onClick={(filter) => {
          setActiveStatus(filter.status);
          clearSelection();
          setPage(0);
          fetchData({ status: filter.status });
        }}
      />
      <ActionsBar>{actions}</ActionsBar>
      <Table<ITransaction> {...tableProps}>{columnData(status, shouldShowLoading, setToaster, fetchData)}</Table>
      <TransactionSidePanel
        type={record.transactionType}
        visible={visible}
        setVisible={setVisible}
        currentStatusId={record.currentStatusId}
        buttons={buttons}
        record={record}
        relatedTransaction={relatedTransaction}
        onClickRelatedTransaction={() => {
          openTransactionDetail(relatedTransaction);
        }}
      />
      <Toaster
        size={TOASTER_SIZE_TYPE.M}
        visible={toaster.show}
        status={toaster.type}
        message={toaster.message}
        onClose={() => setToaster({ ...toaster, show: false })}
      />
      <CompleteWithoutSyncWarningModal
        showWarningModal={showWarningModal}
        setShowWarningModal={setShowWarningModal}
        loadingUpdateStatus={loadingUpdateStatus}
        handleProceedWithoutSyncClick={() =>
          updateTransactionWorkflowStatus(false, selectedIds, "REVIEWED").then(() => {
            clearSelection();
            setShowWarningModal(false);
          })
        }
      />
    </DataTable>
  );
};

export default PendingSync;
