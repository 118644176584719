import React from "react";
import { useListFetch } from "Views/Transaction/Cards/Hooks/useFetch";
import { initialCardsTrxnsSuccess, initialGetTrxnRequest } from "Views/Transaction/DataCalls/Cards.mocks";
import {
  ICardsTrxnsErrorData,
  ICardsTrxnsSuccessData,
  IGetCardsTrxnsData,
  IGetCardsTrxnsRequest,
  IPageWithTable,
} from "Views/Transaction/@types";

import { TrxnFilterProvider } from "Views/Transaction/Provider";
import { treatParams } from "Views/Transaction/Helper";

import { CardsTrxnsList } from "./List";
import { MORE_FILTER_KEYS } from "Views/Transaction/Cards/Actions/Filters";

const CardsTrxn = (): JSX.Element => {
  const { error, loading, data, fetchData } = useListFetch();

  const errorMessage: string =
    error && (data as ICardsTrxnsErrorData)?.status_message
      ? (data as ICardsTrxnsErrorData).status_message
      : "Internal Server Problem! Please try again later.";

  const total: number = error ? initialCardsTrxnsSuccess.mocked.total : (data as ICardsTrxnsSuccessData).total;

  const listData: IGetCardsTrxnsData[] = error
    ? initialCardsTrxnsSuccess.mocked.card_transactions
    : (data as ICardsTrxnsSuccessData).card_transactions;

  const props: IPageWithTable<IGetCardsTrxnsData> = {
    total,
    error,
    loading,
    errorMessage,
    data: listData,
  };

  const filters = treatParams<IGetCardsTrxnsRequest>(initialGetTrxnRequest());

  return (
    <TrxnFilterProvider filters={filters} onApply={fetchData} moreFilters={MORE_FILTER_KEYS}>
      <CardsTrxnsList {...props} />
    </TrxnFilterProvider>
  );
};

export default CardsTrxn;
