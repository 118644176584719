import { FETCH_CSV_REQUEST, FETCH_CSV_SUCCESS, FETCH_CSV_FAILURE, CLEAR_CSV_DATA } from "../Actions/csvAction";
import { userInitialState } from "../InitialState";

export const csvReducer = (state = userInitialState, action) => {
  switch (action.type) {
    case FETCH_CSV_REQUEST:
      return {
        ...state,
        data: action.data,
        loading: true,
        error: false,
      };
    case FETCH_CSV_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        message: action.message,
        error: false,
      };
    case FETCH_CSV_FAILURE:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: true,
        message: action.message,
      };
    case CLEAR_CSV_DATA:
      return {
        ...state,
        data: action.data,
        loading: false,
        error: false,
      };
    default:
      return state;
  }
};
