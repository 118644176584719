import { FormInstance } from "antd/lib/form";
import BillAdditionalConfig from "../TransactionSettings/BillAdditionalConfig";
import {
  IGlobalConfig,
  IRequiredFields,
  ISetting,
  ITabConfig,
} from "../@types";
import {
  TRANSACTION_TYPE,
  REQUIRED_FIELDS,
  NON_EDITABLE_FIELDS,
  PARTNER_TYPE,
} from "./constant";
import {
  dateTimeWithTimezone,
  intlDateTimeFormat,
  intlTimeFormat,
} from "utility";
import { getUserDetailsFromUserId } from "Redux/DataCalls/AccountingSetting.api";

const isBill = (key) => key === "bill";
const GLOBAL_SETTING = "global";

export const mapSetting: (
  config: ISetting,
  connected: boolean,
) => ITabConfig[] = (config, connected) => {
  return Object.entries(config || {})
    .filter(([key]) => key !== GLOBAL_SETTING)
    .map(([key, setting], index) => {
      let autoSync = setting.find(
        ({ code }) => code === `xero_auto_sync_${key}`,
      )?.isEnabled;
      if (isBill(key)) {
        const autoPaidBill = setting.find(
          ({ code }) => code === "xero_auto_paid_bill",
        )?.isEnabled;
        autoSync = autoPaidBill;
      }

      const result: ITabConfig = {
        id: index,
        key: TRANSACTION_TYPE[key],
        title: TRANSACTION_TYPE[key],
        apiKey: key,
        requiredFields: mapRequiredFields(setting, connected),
        review: !autoSync, // Is a reverse logic, if autosync is true then review is false & vice-versa
      };

      if (isBill(key)) {
        result.AdditionalConfig = BillAdditionalConfig;
      }

      return result;
    });
};

export const mapToApiPayload = (
  tabsConfig: ITabConfig[],
  form: FormInstance,
  globalConfig: IGlobalConfig[],
) => {
  const billSyncValue = form.getFieldValue("xero_bill_sync_setting");

  return {
    settings: {},
    settingsV2: {
      ...tabsConfig.reduce((setting, tab) => {
        if (isBill(tab.apiKey)) {
          setting[tab.apiKey] = [
            {
              code: `xero_auto_sync_${tab.apiKey}`,
              isEnabled: billSyncValue === "xero_auto_sync_bill",
              transactionType: tab.apiKey,
            },
            {
              code: `xero_auto_paid_${tab.apiKey}`,
              isEnabled: !tab.review,
              transactionType: tab.apiKey,
            },
            ...fieldCategoryMap(tab),
          ];
        } else {
          setting[tab.apiKey] = [
            {
              code: `xero_auto_sync_${tab.apiKey}`,
              isEnabled: !tab.review, // Is a reverse logic if autosync true then review is false vice versa
              transactionType: tab.apiKey,
            },

            ...fieldCategoryMap(tab),
          ];
        }
        return setting;
      }, {}),
      [GLOBAL_SETTING]: globalConfig,
    },
  };
};

export const mapRequiredFields: (
  setting: any,
  connected: boolean,
) => IRequiredFields[] = (settings, connected) => {
  return settings
    .filter(({ code }) => {
      if (code === "field_tags") return connected;
      return code.startsWith("field_") && code !== "field_tax"; // Temporary will hide tax
    })
    .map(({ code, isEnabled }, index): IRequiredFields => {
      const clearKey = REQUIRED_FIELDS[code];
      return {
        id: `${clearKey}-${index}`,
        value: clearKey,
        checked: isEnabled,
        disabled: Boolean(NON_EDITABLE_FIELDS[clearKey]),
        apiKey: code,
      };
    });
};

export const fieldCategoryMap = (tab: ITabConfig) => {
  return tab.requiredFields.map((field) => ({
    code: field.apiKey,
    isEnabled: field.checked,
    transactionType: tab.apiKey,
  }));
};

export const findGlobalConfig: (config: ISetting) => IGlobalConfig[] = (
  config,
) => {
  // index 1 is the value
  return (
    Object.entries(config || {}).find(([key]) => key === GLOBAL_SETTING)?.[1] ||
    ([{}] as IGlobalConfig[])
  );
};

export const formattedDate = (date) => {
  const dateWithTimezone = dateTimeWithTimezone(date);
  return `${intlDateTimeFormat(new Date(dateWithTimezone))} ${intlTimeFormat(new Date(dateWithTimezone))}`;
};

export const getUserNameWhoPausedSync = async (userId, loggedInUserDetails) => {
  if (userId) {
    if (userId === loggedInUserDetails?.id && loggedInUserDetails?.name) {
      return loggedInUserDetails?.name;
    } else {
      // fetching user info based on the user's ID who initiated sync pause
      const { data: userDetails } = await getUserDetailsFromUserId(userId);
      return userDetails?.payload?.user?.name || "";
    }
  }
  return "";
};

export const findSingleSettingConfig = (val, config, key = "code") =>
  config?.find((config) => config[key] === val);

export const getConfigSettingChangedFlag: (
  initialGlobalConfig: IGlobalConfig[],
  globalConfig: IGlobalConfig[],
  initialTabsConfig: ITabConfig[],
  tabsConfig: ITabConfig[],
) => { globalConfigChanged: boolean; tabsConfigChanged: boolean } = (
  initialGlobalConfig,
  globalConfig,
  initialTabsConfig,
  tabsConfig,
) => {
  const isGlobalConfigSettingChanged = globalConfig.some(
    (config: IGlobalConfig) =>
      config.isEnabled !==
      findSingleSettingConfig(config.code, initialGlobalConfig)?.isEnabled,
  );
  const isTabsConfigSettingChanged = tabsConfig.some((tab: ITabConfig) => {
    const tabInitialSetting = findSingleSettingConfig(
      tab.apiKey,
      initialTabsConfig,
      "apiKey",
    );
    const isReviewTrxnsSettingChanged =
      tab.review !== tabInitialSetting?.review;
    const isTabRequiredFieldSettingChanged = tab.requiredFields?.some(
      (field: IRequiredFields) =>
        field.checked !==
        findSingleSettingConfig(
          field.apiKey,
          tabInitialSetting.requiredFields,
          "apiKey",
        )?.checked,
    );
    return isReviewTrxnsSettingChanged || isTabRequiredFieldSettingChanged;
  });

  return {
    globalConfigChanged: isGlobalConfigSettingChanged,
    tabsConfigChanged: isTabsConfigSettingChanged,
  };
};

/**
 * IMPROVEMENT: this function is only for the sake of changing the partner name into something else.
 * It should be handled by the BE, but since we are doing logic validation with the name field in the FE,
 * it cannot be changed at the moment. It's better to change the validation to `id` if needed, or better
 * let the BE handle the logic if possible.
 * @param originalName partner original name from the BE. It should always the same as PARTNER_TYPE type
 * @returns
 */
export const getPartnerLabel = (originalName: string) => {
  switch (originalName) {
    case PARTNER_TYPE.NETSUITE: {
      return `Oracle NetSuite`;
    }
    default: {
      return originalName;
    }
  }
};
