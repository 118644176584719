import React from "react";

import { InputCell } from "Views/Transaction/Shimmer";
import { Tooltip, InfoOutline, Typography } from "@spenmo/splice";
import { IColumnData } from "Modules/DS/DataTable";
import {
  IGetCardsTrxnsData,
  CARDS_TRXNS_TABLE_COLUMNS,
  GET_CARDS_TRXNS_DATA_PARAMS,
  CARD_TXN_STATUS,
} from "Views/Transaction/@types";
import { REVERSED_TRXNS_STATUS } from "Views/Transaction/Constants/Cards";
import { isFxTransaction } from "Views/Transaction/Cards/SidePanel/TrxnAmount/Utility";
import cn from "classnames";

import styles from "./index.module.scss";

export const AmountCell = ({ record }: { record: IGetCardsTrxnsData }) => {
  const {
    fx_type,
    amount_currency_code = "",
    amount,
    receiving_currency_amount,
    receiving_currency_amount_currency_code,
    txn_status,
  } = record || {};
  const displayValue = (value: string, currencyCode: string): string =>
    (currencyCode || "") + " " + Math.abs(+(value || "")).toFixed(2);

  const isFxTrxn = isFxTransaction(fx_type || "");
  const isPendingTrxn = txn_status === CARD_TXN_STATUS.PENDING;
  const isReversedTrxn = REVERSED_TRXNS_STATUS.includes(txn_status);

  return (
    <InputCell>
      <div className={styles.amountContainer}>
        <div>
          <Typography
            variant="body-content"
            size="s"
            tag="p"
            children={displayValue(amount, amount_currency_code)}
            className={cn(styles.alignRight, { [styles.amountGreyedout]: isReversedTrxn })}
          />
          {isFxTrxn && (
            <Typography
              variant="body-content"
              size="s"
              tag="p"
              children={displayValue(receiving_currency_amount, receiving_currency_amount_currency_code)}
              className={cn(styles.alignRight, styles.fxAmount)}
            />
          )}
        </div>
        {isPendingTrxn ? (
          <Tooltip
            placement="top"
            title="Transaction amount may differ once they are settled"
            className={styles.tooltipBtn}
          >
            <InfoOutline iconColor="var(--icon-default)" size="16"></InfoOutline>
          </Tooltip>
        ) : (
          <div className={styles.paddingLeft} />
        )}
      </div>
    </InputCell>
  );
};

export const amount = (): IColumnData<IGetCardsTrxnsData>["column"] => ({
  title: <div className={styles.paddingLeft}>{CARDS_TRXNS_TABLE_COLUMNS.AMOUNT}</div>,
  key: GET_CARDS_TRXNS_DATA_PARAMS.AMOUNT,
  dataIndex: GET_CARDS_TRXNS_DATA_PARAMS.AMOUNT_CURRENCY_CODE,
  align: "right",
  className: styles.columnHeader,
  width: 160,
  render: (_, record: IGetCardsTrxnsData): React.ReactNode => <AmountCell record={record} />,
});
