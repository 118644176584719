export const useSingleSelect = (onClick: (id: string) => void) => (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
  e.stopPropagation();

  let selectedId = e.currentTarget.id;
  let target = e.target as Element;

  while (!selectedId && target) {
    selectedId = target.id;
    target = target.parentElement;
  }

  if (!selectedId) {
    console.error("Target element does not have an ID.");
    return;
  }

  onClick(selectedId);
};
