import React from "react";
import PropTypes from "prop-types";
import { Typography } from "antd";

import styles from "./Step.module.scss";

const Step = ({ number, title, children }) => {
  const { Title } = Typography;

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <Title level={3} className={styles.number}>{`Step ${number}`}</Title>
        <Title level={3}>{title}</Title>
      </div>
      {children}
    </div>
  );
};

Step.propTypes = { number: PropTypes.number, title: PropTypes.string, children: PropTypes.node };

Step.defaultProps = { number: "", title: "" };

export default Step;
