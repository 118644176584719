import React from "react";
import cn from "classnames";
import { Steps as AntdSteps } from "antd";
import { StepsProps as AntdStepsProps, StepProps } from "antd/lib/steps";

import styles from "./Steps.module.scss";

const { Step } = AntdSteps;

export interface StepsProps extends AntdStepsProps {
  data: StepProps[];
}

const Steps = ({ data, className, ...props }: StepsProps) => {
  return (
    <AntdSteps labelPlacement="vertical" className={cn(styles.Steps, className)} {...props}>
      {React.Children.toArray(
        data.map((item, index) => (
          <Step key={`step-${index + 1}`} icon={<div className={styles.Step}>{index + 1}</div>} {...item} />
        ))
      )}
    </AntdSteps>
  );
};

export default Steps;
