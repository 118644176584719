import React from "react";
import IButton from "Modules/button";
import { pdfExport } from "assets/img";

const DownloadBankStatementButton = (props) => {
  const { action } = props;

  return (
    <IButton
      id="downloadBankStatement"
      action={action}
      icon={<img alt="download" src={pdfExport} />}
      text="Bank Statement"
    />
  );
};

export default DownloadBankStatementButton;
