import React, { useState } from "react";
import { Steps } from "antd";
import classNames from "classnames";
import Accordion from "Modules/TransactionDetail/Section/Accordion";

import { CARD_TXN_STATUS, ITrxnLifeCycle } from "Views/Transaction/@types";
import { SETTLED_TRXNS_STATUS, REVERSED_TRXNS_STATUS, REFUNDED_TRXNS_STATUS } from "Views/Transaction/Constants/Cards";

import {
  getPendingConfig,
  getSettledConfig,
  getReversedConfig,
  getRefundConfig,
  ITrxnStatusStepProps,
} from "./StepsConfig";

import styles from "./index.module.scss";

const { Step } = Steps;

interface ITrxnStatusProps {
  trxnLifecyle: ITrxnLifeCycle[];
  trxnStatus: CARD_TXN_STATUS;
}

const TrxnStatus = ({ trxnLifecyle, trxnStatus }: ITrxnStatusProps) => {
  const [statusExpanded, setStatusExpanded] = useState(false);
  let stepItems: ITrxnStatusStepProps[] = [];
  let currentStep: ITrxnStatusStepProps[] = [];

  const getPendingTrxn = () => trxnLifecyle?.find((trxn) => trxn?.txn_status === CARD_TXN_STATUS.PENDING);

  const currentLifeCycleTrxn = trxnLifecyle?.find((trxn) => trxn?.txn_status === trxnStatus);
  const { created_at = "" } = currentLifeCycleTrxn || {};
  if (trxnStatus === CARD_TXN_STATUS.PENDING) {
    stepItems = getPendingConfig(created_at);
    currentStep = [stepItems[1]];
  } else if (SETTLED_TRXNS_STATUS.indexOf(trxnStatus) !== -1) {
    const pendingTrxn = getPendingTrxn();
    stepItems = getSettledConfig(created_at, pendingTrxn?.created_at || "");
    currentStep = [stepItems[0]];
  } else if (REVERSED_TRXNS_STATUS.indexOf(trxnStatus) !== -1) {
    const pendingTrxn = getPendingTrxn();
    let reversedDate = created_at;
    if (!reversedDate) {
      const refundFullData = trxnLifecyle?.find((trxn) => trxn?.txn_status === CARD_TXN_STATUS.REFUND_FULL);
      reversedDate = refundFullData?.created_at || "";
    }
    stepItems = getReversedConfig(reversedDate, pendingTrxn?.created_at || "");
    currentStep = [stepItems[0]];
  } else if (REFUNDED_TRXNS_STATUS.indexOf(trxnStatus) !== -1) {
    stepItems = getRefundConfig(created_at);
    currentStep = [stepItems[0]];
  }

  const stepsVisible = statusExpanded ? stepItems : currentStep;

  if (stepsVisible.length === 0) {
    return null;
  }
  return (
    <div className={styles.container}>
      <Accordion open={statusExpanded} setOpen={setStatusExpanded} hide={stepItems.length === 1}>
        <p className={styles.header}>Transaction Status</p>
        <Steps
          progressDot
          current={1}
          direction="vertical"
          className={classNames(styles.steps, { [styles.pendingSteps]: trxnStatus === CARD_TXN_STATUS.PENDING })}
        >
          {stepsVisible.map((step) => {
            const { trxnStatus, ...stepProps } = step;
            return <Step {...stepProps} key={step.trxnStatus} />;
          })}
        </Steps>
      </Accordion>
    </div>
  );
};

export default TrxnStatus;
