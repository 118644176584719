import * as React from "react";
import { Table, IColumnData } from "Modules/DS/DataTable";
import { ICategoryDetails } from "Views/Card/CategoryLockKbArticle/types";
import { useGetCategoryDetails } from "Views/Card/CategoryLockKbArticle/UseGetCategories";

import styles from "./index.module.scss";

const DEFAULT_PAGE_NUMBER = 0;
const DEFAULT_PAGE_SIZE = 100;

const KbArticle = () => {
  const [data, loading] = useGetCategoryDetails();
  const columnData: IColumnData<ICategoryDetails>[] = [
    {
      id: "CARD-COL-1",
      enabled: true,
      column: {
        title: "Category Lock",
        dataIndex: "name",
        key: "name",
        render: (_, record) => (
          <div className={styles.categoryLockInfo}>
            <img src={record?.merchant_category_icon} alt={`${record?.name} logo`} width={36} height={36} />
            <div>
              <p className={styles.categoryLockInfoTitle}>{record?.name}</p>
              <p className={styles.categoryLockInfoSubTitle}>{record?.description}</p>
            </div>
          </div>
        ),
      },
    },
    {
      id: "CARD-COL-2",
      enabled: true,
      column: {
        title: "Merchants",
        dataIndex: "merchants",
        key: "merchants",
        render: (_, record) => <p className={styles.merchants}>{record?.merchants?.join(", ")}</p>,
      },
    },
  ];
  return (
    <div className={styles.container}>
      <div className={styles.title}>Category Lock</div>
      <div className={styles.subTitle}>
        Category lock helps you set the lock on a group of merchants present in that particular category
      </div>
      <div className={styles.tableContainer}>
        <Table<ICategoryDetails>
          pageSize={DEFAULT_PAGE_SIZE}
          pageNum={DEFAULT_PAGE_NUMBER}
          total={data.length}
          dataSource={data}
          loading={loading}
        >
          {columnData}
        </Table>
      </div>
    </div>
  );
};

export default KbArticle;
