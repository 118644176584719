export const reimbursement = require("src/assets/img/homeIcons/reimbursement.svg");
export const team = require("src/assets/img/homeIcons/teams.svg");
export const transaction = require("src/assets/img/homeIcons/transaction.svg");
export const sendMoney = require("src/assets/img/homeIcons/sendMoney.svg");
export const requestMoney = require("src/assets/img/homeIcons/requestMoney.svg");
export const submitBills = require("src/assets/img/homeIcons/submitBills.svg");
export const manageBills = require("src/assets/img/homeIcons/manageBills.svg");
export const categories = require("src/assets/img/homeIcons/categories.svg");
export const bankAccount = require("src/assets/img/homeIcons/bankAccount.svg");
export const employee = require("src/assets/img/homeIcons/employee.svg");
export const addEmployee = require("src/assets/img/homeIcons/addEmployee.svg");
export const billsapproval = require("src/assets/img/homeIcons/ic_billsapproval.svg");
export const fundtransfer = require("src/assets/img/homeIcons/ic_fundtransfer.svg");
export const navigationChevron = require("src/assets/img/homeIcons/ic_chevron.svg");
export const billspay = require("src/assets/img/homeIcons/ic_bills.svg");
export const manageBillsGrey = require("src/assets/img/homeIcons/ic_bills_grey.svg");
export const request = require("src/assets/img/homeIcons/ic_request.svg");
export const reimbursementGrey = require("src/assets/img/homeIcons/ic_request_grey.svg");
export const submitBillsGrey = require("src/assets/img/homeIcons/ic_submit_bills_grey.svg");
export const pendingcard = require("src/assets/img/homeIcons/pendingcard.svg");
