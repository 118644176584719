import React from "react";

import { TRANSACTION_PATHS } from "Route/Transaction/paths";
import { IRouterType, PAGE_GRID_COLUMNS } from "Route/@types";

import NewTransactions from "Views/Transaction";

export const TransactionRoute: IRouterType[] = [
  {
    id: "transaction-1",
    path: TRANSACTION_PATHS.TRANSACTIONS,
    component: <NewTransactions />,
    title: "Transaction listing",
    authRequired: true,
    exactPath: true,
    grid: {
      columns: PAGE_GRID_COLUMNS.NONE,
    },
  },
];
