import * as React from "react";
import classNames from "classnames";
import { Tooltip } from "Views/Card/Components/Tooltip";
import MerchantSpendingLock from "Views/Card/Components/SpendingLock/Merchant";
import CategorySpendingLock from "Views/Card/Components/SpendingLock/Category";
import ActionBtns from "Views/Card/CreateOrRequest/ActionBtns";

import { useCreateOrRequestCardContext } from "Views/Card/CreateOrRequest/index.context";
import { Actions } from "Views/Card/CreateOrRequest/Utilities/UseCreateOrRequestState";
import { SPLIT_NAMES, SPLIT_TREATMENT_TYPES } from "Redux/splitio/constants";
import { CARD_PATHS } from "Route/Card/path";
import useCheckFeatureStatus from "customHooks/featureCheck";
import { CardCreationEventNames } from "Views/Card/Analytics";
import { trackEvent } from "utility/analytics";

import { SPENDING_LOCK_TYPES, ISpendingLockWithNameAndId } from "Views/Card/types";

import { infoIconBlue16x16 } from "assets/icons/card";
import styles from "./index.module.scss";

interface IAddSpendingLock {
  selectedSpendingLockType: SPENDING_LOCK_TYPES;
  toggleLockType: () => void;
  setShowAddSpendingLockScreen: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddSpendingLock = ({
  selectedSpendingLockType,
  toggleLockType,
  setShowAddSpendingLockScreen,
}: IAddSpendingLock) => {
  const { state, dispatch, changeStep } = useCreateOrRequestCardContext();
  const { spendingLockType, spendingLocks, adminOrManagerInSelectedTeam } = state;
  const isCategoryLockTreatmentOn = useCheckFeatureStatus(SPLIT_NAMES.categoryLock) === SPLIT_TREATMENT_TYPES.ON;
  const getInitialSpendingLocks = () => {
    if (spendingLockType !== selectedSpendingLockType) return [];
    return [...(spendingLocks || [])];
  };
  const [selectedLocks, setSelectedLocks] = React.useState<ISpendingLockWithNameAndId[]>(getInitialSpendingLocks());

  const saveSpendingLocks = () => {
    dispatch({
      type: Actions.UPDATE_SPENDING_LOCK_TYPE,
      value: selectedLocks?.length > 0 ? selectedSpendingLockType : SPENDING_LOCK_TYPES.NONE,
    });
    dispatch({ type: Actions.UPDATE_SPENDING_LOCKS, value: selectedLocks });
  };

  const onChange = (userSelectedLocks) => {
    let selectedLocks: ISpendingLockWithNameAndId[] = [];
    if (selectedSpendingLockType === SPENDING_LOCK_TYPES.MERCHANT) {
      selectedLocks = userSelectedLocks?.map((merchant) => ({
        id: merchant,
        name: merchant,
      }));
    } else {
      selectedLocks = userSelectedLocks?.map((lock) => ({
        id: lock?.id,
        name: lock?.name,
      }));
    }
    setSelectedLocks(selectedLocks?.length > 0 ? selectedLocks : []);
    trackEvent(CardCreationEventNames.CARD_CREATION_SETTINGS_SELECTED, {
      card_creation_event_source: `${selectedSpendingLockType} lock`,
      card_spending_locks: selectedLocks?.map((lock) => lock?.name)?.join(", ") || "",
    });
  };

  const onChangeStep = (goToNextStep: boolean) => {
    if (goToNextStep) {
      saveSpendingLocks();
      adminOrManagerInSelectedTeam ? setShowAddSpendingLockScreen(true) : changeStep(goToNextStep);
    } else {
      changeStep(goToNextStep);
    }
  };
  const switchLockType = selectedSpendingLockType === SPENDING_LOCK_TYPES.CATEGORY ? "Merchant" : "Category";
  const lockType = selectedSpendingLockType === SPENDING_LOCK_TYPES.CATEGORY ? "Category" : "Merchant";
  const headerStyle = classNames("create-or-request-card-steps__header", {
    [styles.headerWithIcon]: selectedSpendingLockType === SPENDING_LOCK_TYPES.CATEGORY,
    [styles.marginBottom12]: !isCategoryLockTreatmentOn,
  });
  const onSpendingLockToogle = () => {
    let initialSpendingLocks = [];
    const futureSpendingLock =
      selectedSpendingLockType === SPENDING_LOCK_TYPES.CATEGORY
        ? SPENDING_LOCK_TYPES.MERCHANT
        : SPENDING_LOCK_TYPES.CATEGORY;
    if (spendingLockType === futureSpendingLock) {
      initialSpendingLocks = [...(spendingLocks || [])];
    }
    setSelectedLocks(initialSpendingLocks);
    toggleLockType();
  };
  return (
    <div className={styles.container}>
      <div className={headerStyle}>
        Set Up {lockType} Lock
        {selectedSpendingLockType === SPENDING_LOCK_TYPES.CATEGORY && (
          <a
            href={`${window.location.origin}${CARD_PATHS.CATEGORY_LOCK_KB_ARCTILE}`}
            target="_blank"
            onClick={(event) => event.stopPropagation()}
            rel="noreferrer"
          >
            <Tooltip tooltipText="Tap to learn more about Categories" placement="top">
              <img src={infoIconBlue16x16} alt="Info Icon" />
            </Tooltip>
          </a>
        )}
      </div>
      {isCategoryLockTreatmentOn && (
        <button onClick={onSpendingLockToogle} className={styles.cancelBtn} data-testid="switch-button">
          Cancel and switch to {switchLockType} Lock
        </button>
      )}

      <div className={styles.listContainer}>
        {selectedSpendingLockType === SPENDING_LOCK_TYPES.CATEGORY ? (
          <CategorySpendingLock
            onChange={onChange}
            initialSpendingLocks={
              (spendingLockType === SPENDING_LOCK_TYPES.CATEGORY &&
                spendingLocks?.map((lock) => ({ name: lock?.name, id: lock?.id }))) ||
              []
            }
            totalShimmerToRender={5}
          />
        ) : (
          <MerchantSpendingLock
            onChange={onChange}
            initialSpendingLocks={
              (spendingLockType === SPENDING_LOCK_TYPES.MERCHANT && spendingLocks?.map((lock) => lock?.name)) || []
            }
            totalShimmerToRender={10}
          />
        )}
      </div>
      <ActionBtns actionBtnName="Confirm" changeStep={onChangeStep} renderGoBackBtn />
    </div>
  );
};

export default AddSpendingLock;
