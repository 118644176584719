export enum RICH_HEADER_TYPE {
  RICH = "rich",
  DEFAULT = "default",
}

export enum SIZE_TYPES {
  EXTRA = "extra",
  MEDIUM = "medium",
}

export interface RichHeaderProps {
  type?: string;
  onClose?: () => void;
  title: string | React.ReactElement;
  showPremiumLabel?: boolean;
  size?: SIZE_TYPES.EXTRA | SIZE_TYPES.MEDIUM;
}
