import React, { useMemo } from "react";
import classNames from "classnames";
import Banner from "Modules/DS/Banner";
import style from "./PreviewModal.module.scss";
import { PreviewSection } from "./types";
import { fallbackString } from "utility";

export interface PreviewVendorContentProps {
  sections: PreviewSection[];
}

const PreviewVendorContent = ({ sections }: PreviewVendorContentProps) => {
  const isVendorEdited = useMemo(() => {
    for (let section of sections) {
      for (let group of section.groups) {
        for (let field of group.fields) {
          if (field.isEdited) {
            return true;
          }
        }
      }
    }

    return false;
  }, [sections]);

  return (
    <div className={classNames(style.previewModal, style.previewModalSimple)}>
      <div className={style.content}>
        <div className={style.contentBody}>
          <h3>Preview Recipient</h3>
          {isVendorEdited && (
            <>
              <div className={style.banner}>
                <Banner
                  type="info"
                  message={
                    <>
                      <div className={style.bannerTitle}>
                        Changes in recipient details will apply on your next created bills
                      </div>
                      <p>
                        Changes in recipient details will not be reflected in existing bills currently in approval.
                        Recipient details in existing bills can be manually updated in Edit Bills.
                      </p>
                    </>
                  }
                />
              </div>

              <div className={style.previewLabel}>
                <div>
                  <div className={style.previewHighlight} />
                  <span>Updated</span>
                </div>
                <div>
                  <div className={style.previewColor} />
                  <span>Unchanged</span>
                </div>
              </div>
            </>
          )}
          {sections?.map((section) => {
            return (
              <div className={style.preview} key={section.label}>
                <h4>{section?.label}</h4>
                {section?.groups?.map((group) => {
                  return (
                    <div key={group.groupName}>
                      <div className={style.columnTwo}>
                        {group.fields.map((field) => {
                          return (
                            <div className={style.previewItem} key={field.label}>
                              <div className={style.itemLabel}>{field.label}</div>
                              {field.isEdited ? (
                                <div className={style.wrapText}>
                                  {field.oldValue && <span className={style.itemPrevValue}>{field.oldValue}</span>}
                                  <span className={style.itemHighlight}>{field.newValue}</span>
                                </div>
                              ) : (
                                <div className={style.itemValue}>{fallbackString(field.newValue, "-")}</div>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default PreviewVendorContent;
