import React from "react";
import { Redirect, useLocation } from "react-router-dom";

import StatusView from "Modules/statusView";
import { companyMenu } from "Views/Settings/config";
import { trackEvent } from "utility/analytics";

import XeroExpenseSetup from "./XeroExpense/Setup";
import XeroBankFeedSetup from "./XeroBankFeed/Setup";
import { SETUP_EVENT } from "./Common/Analytics";
import NetsuiteSetup from "./Netsuite/Setup";

const Setup = ({ netsuiteIntegration = false }: { netsuiteIntegration?: boolean }) => {
  const integrationSettingsPagePath = companyMenu[0].path;
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const isUsingBankFeedIntegration = urlParams.get("bankfeed") === "true";
  const isUsingExpenseIntegration = urlParams.get("expense") === "true";
  const successParam = urlParams.get("success");

  if (successParam) {
    trackEvent(successParam === "true" ? SETUP_EVENT.INTEGRATION_SUCCESS : SETUP_EVENT.INTEGRATION_FAIL);
  }

  if (isUsingBankFeedIntegration) {
    return <XeroBankFeedSetup />;
  }

  if (isUsingExpenseIntegration) {
    return <XeroExpenseSetup />;
  }

  if (netsuiteIntegration) {
    return <NetsuiteSetup />;
  }

  if (successParam === "false") {
    return (
      <StatusView
        primary
        header="Authentication Failed!"
        message="cannot get bank accounts as connection cant be found. Please try after some time or try reconnecting to xero."
        ButtonLink={integrationSettingsPagePath}
        ButtonText="Back to Integration"
      />
    );
  }

  return <Redirect to="/404" />;
};

export default Setup;
