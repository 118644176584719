import APIclient from "API/Client";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import { OTP_EXPIRES_AT_SESSION_STORAGE_NAME } from "Redux/Actions/loginAction";
import { appNotification } from "../../../Modules/appNotification/appNotification";

export const FETCH_OTP = "FETCH_OTP";
export const FETCH_OTP_REQUEST = `${FETCH_OTP}_REQUEST`;
export const FETCH_OTP_SUCCESS = `${FETCH_OTP}_SUCCESS`;
export const FETCH_OTP_FAILURE = `${FETCH_OTP}_FAILURE`;

export const RESEND_OTP_REQUEST = `${FETCH_OTP}_REQUEST`;
export const RESEND_OTP_SUCCESS = `${FETCH_OTP}_SUCCESS`;
export const RESEND_OTP_FAILURE = `${FETCH_OTP}_FAILURE`;

export const FETCH_OTP_RESET_DATA = `${FETCH_OTP}_RESET_DATA`;

const sendOtpRequest = (data) => {
  return {
    type: FETCH_OTP_REQUEST,
    data: data,
  };
};

const reSendOtpRequest = (data) => {
  return {
    type: FETCH_OTP_REQUEST,
    data: data,
  };
};

const fetchDataSuccess = (data) => {
  return {
    type: FETCH_OTP_SUCCESS,
    data: data,
  };
};

const fetchDataError = (data) => {
  return {
    type: FETCH_OTP_FAILURE,
    data: data,
  };
};

export const fetchOtpResetData = () => ({
  type: FETCH_OTP_RESET_DATA,
});

export const fetchOtpV1 = () => {
  return (dispatch) => {
    dispatch(sendOtpRequest());
    APIclient.getData(`/api/v1/auth/otp`)
      .then((response) => {
        const { data = {} } = response || {};
        if (data?.status === HTTP_STATUS_CODE.OK) {
          dispatch(fetchDataSuccess(data));
        } else {
          dispatch(fetchDataError(data));
        }
      })
      .catch((err) => {
        dispatch(fetchDataError(err?.response?.data || {}));
      });
  };
};

export const fetchOtp = (componentName = "default", isUserLoggedIn = false) => {
  return (dispatch) => {
    dispatch(sendOtpRequest());
    APIclient.postData(`/api/v2/otp/send${isUserLoggedIn ? "/auth" : ""}`, {})
      .then((data) => {
        if (data.data.status === HTTP_STATUS_CODE.OK) {
          dispatch(fetchDataSuccess(data.data));
        } else {
          dispatch(fetchDataError(data.data));
        }
      })
      .catch((err) => {
        const errorMessage = err || "Unable to sent otp. Internal Server Error";
        dispatch(fetchDataError(errorMessage, componentName));
      });
  };
};

export const reSendOtp = (componentName = "default", isUserLoggedIn = false) => {
  return (dispatch) => {
    dispatch(reSendOtpRequest());
    APIclient.postData(`/api/v2/otp/resend${isUserLoggedIn ? "/auth" : ""}`, {})
      .then(({ data = {} }) => {
        const { status = "" } = data;
        const { expires_at = "" } = data?.payload || {};
        if (status === HTTP_STATUS_CODE.OK) {
          dispatch(fetchDataSuccess(data));
          appNotification.success({
            message: "New verification code has been sent",
          });
        } else {
          dispatch(fetchDataError(data));
        }
        expires_at && sessionStorage.setItem(OTP_EXPIRES_AT_SESSION_STORAGE_NAME, expires_at);
      })
      .catch((err) => {
        const errorMessage = err || "Unable to resend otp. Internal Server Error";
        dispatch(fetchDataError(errorMessage, componentName));
      });
  };
};
