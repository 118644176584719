import React from "react";

import KYXStates from "Views/KYX/KYXStates";
import { KYX_STATES } from "Views/KYX/Constants";

import styles from "./index.module.scss";

export const UpdateIntroHeader = () => {
  return (
    <>
      <KYXStates type={KYX_STATES.ADDITIONAL_DOC_REQUIRED} />
      <p className={styles.content}>
        Please prepare the following documents to assist you in updating your company data:
      </p>
    </>
  );
};
