import React, { useContext, useEffect, useState } from "react";
import { FormInstance } from "antd/lib/form";
import classNames from "classnames";

import {
  IFormElement,
  IDocumentStep,
  IKYXRemarkContextType,
  IFetchFormSubmissionPayload,
  FORM_SOURCE_TYPE,
} from "Views/KYX/@types";
import {
  FORM_NAME,
  KYX_FORMS_KEYS,
  GUIDELINES_TYPE,
  FORM_FIELD_TYPES,
  DOCUMENT_STEP_TYPE,
  FORM_SECTION_COMPONENT,
  KYX_BANNER_MESSAGE_TYPE,
} from "Views/KYX/Constants";
import { Typography } from "@spenmo/splice";
import { useFormSaveAsDraft } from "Views/KYX/hooks";
import { ManualFormContext } from "Views/KYX/Context";
import { KYXRemarkContext } from "Views/KYX/Context/Remarks";
import { StepInformation } from "Views/KYX/Progress/Step/Document";
import { getValidationError, getValidationstatus } from "Views/KYX/Helper";

import styles from "../index.module.scss";

export const useCompanyDocumentFormSGD = (
  form: FormInstance,
  formattedFormData: IFetchFormSubmissionPayload,
  isFormDisabled: boolean
): IDocumentStep[] => {
  const { formData, setFormData } = useContext(ManualFormContext);
  const isMyInfoSource: boolean = formData?.source === FORM_SOURCE_TYPE.MY_INFO;

  const { formRemarks }: IKYXRemarkContextType = useContext(KYXRemarkContext);
  const remarks = formRemarks?.[FORM_NAME.COMPANY_DOCUMENTS];

  const [optionalChart, setOptionalChart] = useState<boolean>(false);
  const [showOwnershipInfoBanner, setShowOwnershipInfoBanner] = useState<boolean>(false);

  const { saveFormAsDraft } = useFormSaveAsDraft(setFormData);
  useEffect(() => {
    if (!formData?.id) {
      setFormData({ ...formattedFormData, [KYX_FORMS_KEYS.FORM_ID]: formattedFormData?.form_id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formattedFormData]);

  useEffect(() => {
    const ownershipLevel =
      form.getFieldValue([FORM_NAME.COMPANY_DOCUMENTS, KYX_FORMS_KEYS.OWNERSHIP_NUMBER_OF_LEVELS]) ||
      formData?.form?.company_document?.ownership_number_of_levels;

    if (typeof ownershipLevel === "string") {
      if (ownershipLevel === "1") {
        setOptionalChart(true);
        setShowOwnershipInfoBanner(false);
      } else {
        setOptionalChart(false);
        setShowOwnershipInfoBanner(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  const UNIQUE_ENTITY_NUMBER: IFormElement = {
    key: FORM_NAME.COMPANY_DOCUMENTS + KYX_FORMS_KEYS.ACRA_DOCUMENT + KYX_FORMS_KEYS.ACRA_DOCUMENT_NUMBER,
    col: { span: 12 },
    element: {
      type: FORM_FIELD_TYPES.INPUT,
      props: {
        formProps: {
          name: [FORM_NAME.COMPANY_DOCUMENTS, KYX_FORMS_KEYS.ACRA_DOCUMENT, KYX_FORMS_KEYS.ACRA_DOCUMENT_NUMBER],
          label: "Unique Entity Number (UEN)",
          required: true,
          rules: [
            {
              required: true,
              message: "Please enter Unique Entity Number",
            },
            { whitespace: true, message: "This field cannot be empty" },
          ],
          validateStatus: getValidationstatus(remarks, [
            KYX_FORMS_KEYS.ACRA_DOCUMENT,
            KYX_FORMS_KEYS.ACRA_DOCUMENT_NUMBER,
          ]),
          help: getValidationError(
            remarks,
            [KYX_FORMS_KEYS.ACRA_DOCUMENT, KYX_FORMS_KEYS.ACRA_DOCUMENT_NUMBER],
            "Unique Entity Number (UEN)",
            FORM_NAME.COMPANY_DOCUMENTS
          ),
        },
        inputProps: {
          datatype: "string",
          placeholder: "Enter UEN",
          disabled: isFormDisabled || isMyInfoSource,
        },
      },
    },
  };

  const EXPIRY_DATE: IFormElement = {
    key: FORM_NAME.COMPANY_DOCUMENTS + KYX_FORMS_KEYS.ACRA_DOCUMENT + KYX_FORMS_KEYS.ACRA_DOCUMENT_EXPIRY,
    col: { span: 12 },
    element: {
      type: FORM_FIELD_TYPES.DATEPICKER,
      props: {
        formProps: {
          name: [FORM_NAME.COMPANY_DOCUMENTS, KYX_FORMS_KEYS.ACRA_DOCUMENT, KYX_FORMS_KEYS.ACRA_DOCUMENT_EXPIRY],
          label: "Date of ACRA",
          required: true,
          rules: [
            {
              required: true,
              message: "Please enter a valid Date format",
            },
          ],
          validateStatus: getValidationstatus(remarks, [
            KYX_FORMS_KEYS.ACRA_DOCUMENT,
            KYX_FORMS_KEYS.ACRA_DOCUMENT_EXPIRY,
          ]),
          help: getValidationError(
            remarks,
            [KYX_FORMS_KEYS.ACRA_DOCUMENT, KYX_FORMS_KEYS.ACRA_DOCUMENT_EXPIRY],
            "Expiry Date",
            FORM_NAME.COMPANY_DOCUMENTS
          ),
        },
        dateProps: {
          placeholder: "dd/mm/yyyy",
          format: "DD/MM/YYYY",
          disabled: isFormDisabled,
        },
      },
    },
  };

  const UPLOAD_ACRA_DOCUMENT: IFormElement = {
    key: FORM_NAME.COMPANY_DOCUMENTS + KYX_FORMS_KEYS.ACRA_DOCUMENT + KYX_FORMS_KEYS.ACRA_DOCUMENT_FILE,
    col: { span: 24 },
    element: {
      type: FORM_FIELD_TYPES.FILEUPLOAD,
      props: {
        formProps: {
          name: [FORM_NAME.COMPANY_DOCUMENTS, KYX_FORMS_KEYS.ACRA_DOCUMENT, KYX_FORMS_KEYS.ACRA_DOCUMENT_FILE],
          label: (
            <>
              Upload ACRA BizFile Document{" "}
              <Typography className={styles.infoText} variant="body-content" size="s">
                (Dated within the last 6 months)
              </Typography>
            </>
          ),
          required: true,
          rules: [
            {
              required: true,
              message: "Please upload a valid ACRA document",
            },
          ],
          validateStatus: getValidationstatus(remarks, [
            KYX_FORMS_KEYS.ACRA_DOCUMENT,
            KYX_FORMS_KEYS.ACRA_DOCUMENT_FILE,
          ]),
          help: getValidationError(
            remarks,
            [KYX_FORMS_KEYS.ACRA_DOCUMENT, KYX_FORMS_KEYS.ACRA_DOCUMENT_FILE],
            "Upload ACRA BizFile Document",
            FORM_NAME.COMPANY_DOCUMENTS
          ),
          initialValue: formData?.form?.company_document?.arca_document?.document_file,
          className: "mb-0",
        },
        disabled: isFormDisabled,
        // guidelinesType: GUIDELINES_TYPE.ARCA,
        action: (arg) => {
          saveFormAsDraft({
            ...formData,
            id: formData.form_id,
            form: {
              ...formData.form,
              company_document: {
                ...formData.form.company_document,
                arca_document: {
                  ...formData.form.company_document.arca_document,
                  document_file: arg as string,
                },
              },
            },
          });

          return form.setFieldsValue({
            [FORM_NAME.COMPANY_DOCUMENTS]: {
              [KYX_FORMS_KEYS.ACRA_DOCUMENT]: { [KYX_FORMS_KEYS.ACRA_DOCUMENT_FILE]: arg },
            },
          });
        },
      },
    },
  };

  const companyName = formData?.form?.company_information?.general_information?.legal_entity_name;

  const OWNERSHIP_INFO: IFormElement = {
    key: FORM_NAME.COMPANY_DOCUMENTS + KYX_FORMS_KEYS.OWNERSHIP_NUMBER_OF_LEVELS,
    col: { span: 24 },
    element: {
      type: FORM_FIELD_TYPES.RADIO,
      props: {
        formProps: {
          name: [FORM_NAME.COMPANY_DOCUMENTS, KYX_FORMS_KEYS.OWNERSHIP_NUMBER_OF_LEVELS],
          label: `Please indicate the ownership between ${companyName} and the Ultimate Beneficial Owners (UBO)`,
          required: true,
          rules: [
            {
              required: true,
              message: "Please select a level",
            },
          ],
          validateStatus: getValidationstatus(remarks, KYX_FORMS_KEYS.OWNERSHIP_NUMBER_OF_LEVELS),
          help: getValidationError(
            remarks,
            KYX_FORMS_KEYS.OWNERSHIP_NUMBER_OF_LEVELS,
            "Ownership Information",
            FORM_NAME.COMPANY_DOCUMENTS
          ),
        },
        radioProps: {
          className: "ownership_number_list",
          style: { display: "flex", flexDirection: "column", gap: "1.25rem", marginTop: "1.5rem" },
          options: [
            {
              label: "1 level (Direct ownership by UBO)",
              value: "1",
            },
            {
              label: `2 levels (${companyName} is owned by another entity which is owned by UBO)`,
              value: "2",
            },
            {
              label: `3 or more levels (${companyName} is owned indirectly by UBO through several corporate layers)`,
              value: "3",
            },
          ],
          disabled: isMyInfoSource ? false : isFormDisabled,
          onChange: (e) => {
            if (e.target.value === "1") {
              setOptionalChart(true);

              form.setFields([
                {
                  name: [FORM_NAME.COMPANY_DOCUMENTS, KYX_FORMS_KEYS.OWNERSHIP_CHART_FILE],
                  errors: [],
                },
              ]);
              setShowOwnershipInfoBanner(false);
            } else {
              setOptionalChart(false);
              setShowOwnershipInfoBanner(true);
            }
          },
        },
      },
    },
  };

  const OWNERSHIP_STRUCTURE: IFormElement = {
    key: FORM_NAME.COMPANY_DOCUMENTS + KYX_FORMS_KEYS.OWNERSHIP_CHART_FILE,
    col: { span: 24 },
    element: {
      type: FORM_FIELD_TYPES.FILEUPLOAD,
      props: {
        formProps: {
          name: [FORM_NAME.COMPANY_DOCUMENTS, KYX_FORMS_KEYS.OWNERSHIP_CHART_FILE],
          label: "Upload Ownership Chart",
          required: !optionalChart,
          rules: [
            {
              required: !optionalChart,
              message: `Please upload a valid Ownership Chart document`,
            },
          ],
          validateStatus: getValidationstatus(remarks, KYX_FORMS_KEYS.OWNERSHIP_CHART_FILE),
          help: getValidationError(
            remarks,
            KYX_FORMS_KEYS.OWNERSHIP_CHART_FILE,
            "Upload Ownership Chart",
            FORM_NAME.COMPANY_DOCUMENTS
          ),
          initialValue: formData?.form?.company_document?.ownership_chart_file,
          className: "mb-0",
        },
        disabled: isMyInfoSource ? false : isFormDisabled,
        // guidelinesType: GUIDELINES_TYPE.OWNERSHIP,
        action: (arg) => {
          saveFormAsDraft({
            ...formData,
            id: formData.form_id,
            form: {
              ...formData.form,
              company_document: {
                ...formData.form.company_document,
                ownership_chart_file: arg,
              },
            },
          });

          return form.setFieldsValue({
            [FORM_NAME.COMPANY_DOCUMENTS]: { [KYX_FORMS_KEYS.OWNERSHIP_CHART_FILE]: arg },
          });
        },
      },
    },
  };

  const UPLOAD_DOCUMENT_INSTRUCTION: IFormElement = showOwnershipInfoBanner
    ? {
        key: FORM_NAME.COMPANY_DOCUMENTS + KYX_FORMS_KEYS.OWNERSHIP_CHART_FILE + "INFO_BANNER",
        col: { span: 24 },
        element: {
          type: FORM_FIELD_TYPES.BANNER,
          props: {
            bannerType: "neutral",
            message: `Document must be provided as “Certified True Copy” by a suitable person.
        A “suitable person” includes any of the following: (i) a lawyer, (ii) a notary public, (iii) a certified accountant, or (iv) a corporate secretary.`,
            messageType: KYX_BANNER_MESSAGE_TYPE.TEXT,
          },
        },
      }
    : null;

  const UPLOAD_MOA: IFormElement = {
    key: FORM_NAME.COMPANY_DOCUMENTS + KYX_FORMS_KEYS.MEMORANDUM_FILE,
    col: { span: 24 },
    element: {
      type: FORM_FIELD_TYPES.FILEUPLOAD,
      props: {
        formProps: {
          name: [FORM_NAME.COMPANY_DOCUMENTS, KYX_FORMS_KEYS.MEMORANDUM_FILE],
          label: "Upload Memorandum & Articles of Association/Constitution Document",
          required: true,
          initialValue: formData?.form?.company_document?.memorandum_file,
          rules: [
            {
              required: true,
              message: "Please upload a valid Memorandum document",
            },
          ],
          validateStatus: getValidationstatus(remarks, KYX_FORMS_KEYS.MEMORANDUM_FILE),
          help: getValidationError(
            remarks,
            KYX_FORMS_KEYS.MEMORANDUM_FILE,
            "Upload Memorandum & Articles of Association/Constitution Document",
            FORM_NAME.COMPANY_DOCUMENTS
          ),
          className: "mb-0",
        },
        disabled: isFormDisabled,
        // guidelinesType: GUIDELINES_TYPE.MEMORANDUM,
        action: (arg) => {
          saveFormAsDraft({
            ...formData,
            id: formData.form_id,
            form: {
              ...formData.form,
              company_document: {
                ...formData.form.company_document,
                memorandum_file: arg,
              },
            },
          });
          return form.setFieldsValue({
            [FORM_NAME.COMPANY_DOCUMENTS]: { [KYX_FORMS_KEYS.MEMORANDUM_FILE]: arg },
          });
        },
      },
    },
  };

  const DUE_DILIGENCE_DOCUMENTS: IFormElement = {
    key: FORM_NAME.COMPANY_DOCUMENTS + KYX_FORMS_KEYS.DUE_DILIGENCE_DOCUMENTS,
    col: { span: 24 },
    element: {
      type: FORM_FIELD_TYPES.FILEUPLOAD,
      props: {
        formProps: {
          name: [FORM_NAME.COMPANY_DOCUMENTS, KYX_FORMS_KEYS.DUE_DILIGENCE_DOCUMENTS],
          label: "Upload Required Due Diligence Documents (You can upload up to 8 files)",
          required: true,
          rules: [
            {
              required: true,
              message: "Please upload the valid Due Diligence document(s)",
            },
          ],
          initialValue: formData?.form?.company_document?.due_diligence_documents,
        },
        multiple: true,
        disabled: isFormDisabled,
        action: (arg) => {
          saveFormAsDraft({
            ...formData,
            id: formData.form_id,
            form: {
              ...formData.form,
              company_document: {
                ...formData.form.company_document,
                due_diligence_documents: arg,
              },
            },
          });

          return form.setFieldsValue({
            [FORM_NAME.COMPANY_DOCUMENTS]: { [KYX_FORMS_KEYS.DUE_DILIGENCE_DOCUMENTS]: arg },
          });
        },
      },
    },
  };

  const BOARD_RESOLUTION_FORM_TEMPLATE_LINK: IFormElement = {
    key: FORM_NAME.COMPANY_DOCUMENTS + KYX_FORMS_KEYS.SIGNED_BOARD_RESOLUTION_FORM + "DOWNLOAD_LINK",
    col: { span: 24 },
    element: {
      type: FORM_FIELD_TYPES.DOWNLOAD_LINK,
      props: {
        title: "Download Board Resolution Form template",
        href: "https://spenmo-prod.s3.ap-southeast-1.amazonaws.com/template-csv/Spenmo+Board+Resolution+Form.pdf",
        download: "signedBoardResolution.pdf",
        target: "_blank",
      },
    },
  };

  const UPLOAD_BOARD_RESOLUTION_FORM: IFormElement = {
    key: FORM_NAME.COMPANY_DOCUMENTS + KYX_FORMS_KEYS.SIGNED_BOARD_RESOLUTION_FORM,
    col: { span: 24 },
    element: {
      type: FORM_FIELD_TYPES.FILEUPLOAD,
      props: {
        formProps: {
          name: [FORM_NAME.COMPANY_DOCUMENTS, KYX_FORMS_KEYS.SIGNED_BOARD_RESOLUTION_FORM],
          label: "Upload Signed Board Resolution Form",
          required: true,
          initialValue: formData?.form?.company_document?.signed_board_resolution_form_file,
          rules: [
            {
              required: true,
              message: "Please upload a valid Signed Board Resolution document",
            },
          ],
          validateStatus: getValidationstatus(remarks, KYX_FORMS_KEYS.SIGNED_BOARD_RESOLUTION_FORM),
          help: getValidationError(
            remarks,
            KYX_FORMS_KEYS.SIGNED_BOARD_RESOLUTION_FORM,
            "Upload Signed Board Resolution Form",
            FORM_NAME.COMPANY_DOCUMENTS
          ),
          className: "mb-0",
        },
        disabled: isFormDisabled,
        action: (arg) => {
          saveFormAsDraft({
            ...formData,
            id: formData.form_id,
            form: {
              ...formData.form,
              company_document: {
                ...formData.form.company_document,
                signed_board_resolution_form_file: arg,
              },
            },
          });
          return form.setFieldsValue({
            [FORM_NAME.COMPANY_DOCUMENTS]: { [KYX_FORMS_KEYS.SIGNED_BOARD_RESOLUTION_FORM]: arg },
          });
        },
      },
    },
  };

  const getSignedBoardResolutionStepNumber = () => {
    if (isMemorandumFileDisabled && !formData?.form?.company_document?.is_diligence_document_required) {
      return 3.3;
    }
    return !isMemorandumFileDisabled && formData?.form?.company_document?.is_diligence_document_required ? 3.5 : 3.4;
  };

  const SOLE_PROPRIETOR = "Sole Proprietor";
  const legalEntityType = formData?.form?.company_information?.general_information?.legal_entity_type || "";
  const isMemorandumFileDisabled = [SOLE_PROPRIETOR]?.includes(legalEntityType);

  return [
    // ACRA BizFile Document
    {
      key: FORM_NAME.COMPANY_DOCUMENTS + KYX_FORMS_KEYS.ACRA_DOCUMENT,
      type: DOCUMENT_STEP_TYPE.DEFAULT,
      row: { gutter: 40 },
      container: {
        props: {
          number: 3.1,
          title: "ACRA BizFile Document",
          guidelines: GUIDELINES_TYPE.ARCA,
        },
        type: FORM_SECTION_COMPONENT.CARD,
      },
      document: [UNIQUE_ENTITY_NUMBER, EXPIRY_DATE, UPLOAD_ACRA_DOCUMENT],
    },
    // Ownership Information
    {
      key: FORM_NAME.COMPANY_DOCUMENTS + KYX_FORMS_KEYS.OWNERSHIP_NUMBER_OF_LEVELS,
      row: { gutter: 40 },
      type: DOCUMENT_STEP_TYPE.DEFAULT,
      container: {
        type: FORM_SECTION_COMPONENT.CARD_WITH_OPTIONAL,
        props: {
          header: {
            number: 3.2,
            title: "Ownership Information",
          },
          optional: [
            {
              key: FORM_NAME.COMPANY_DOCUMENTS + KYX_FORMS_KEYS.OWNERSHIP_CHART_FILE,
              header: {
                optional: optionalChart,
                title: (
                  <div className={classNames({ [styles.ownershipLabel]: !optionalChart })}>
                    Ownership Chart Structure
                  </div>
                ),
                guidelines: GUIDELINES_TYPE.OWNERSHIP,
              },
              children: (
                <StepInformation document={[UPLOAD_DOCUMENT_INSTRUCTION, OWNERSHIP_STRUCTURE]} row={{ gutter: 40 }} />
              ),
            },
          ],
        },
      },
      document: [OWNERSHIP_INFO],
    },
    // Memorandum & Articles of Association/Constitution
    !isMemorandumFileDisabled
      ? {
          key: FORM_NAME.COMPANY_DOCUMENTS + KYX_FORMS_KEYS.MEMORANDUM_FILE,
          row: { gutter: 40 },
          type: DOCUMENT_STEP_TYPE.DEFAULT,
          container: {
            type: FORM_SECTION_COMPONENT.CARD,
            props: {
              number: 3.3,
              title: "Memorandum & Articles of Association/Constitution",
              guidelines: GUIDELINES_TYPE.MEMORANDUM,
            },
          },
          document: [UPLOAD_MOA],
        }
      : {
          key: FORM_NAME.COMPANY_DOCUMENTS + KYX_FORMS_KEYS.DUE_DILIGENCE_DOCUMENTS + "_hidden",
          type: DOCUMENT_STEP_TYPE.NONE,
        },
    // Due Diligence Documents
    formData?.form?.company_document?.is_diligence_document_required
      ? {
          key: FORM_NAME.COMPANY_DOCUMENTS + KYX_FORMS_KEYS.DUE_DILIGENCE_DOCUMENTS,
          row: { gutter: 40 },
          type: DOCUMENT_STEP_TYPE.DEFAULT,
          container: {
            type: FORM_SECTION_COMPONENT.CARD,
            props: {
              number: isMyInfoSource ? 3.3 : 3.4,
              title: "Due Diligence Documents",
            },
          },
          document: [DUE_DILIGENCE_DOCUMENTS],
        }
      : {
          key: FORM_NAME.COMPANY_DOCUMENTS + KYX_FORMS_KEYS.DUE_DILIGENCE_DOCUMENTS + "_hidden",
          type: DOCUMENT_STEP_TYPE.NONE,
        },
    {
      key: FORM_NAME.COMPANY_DOCUMENTS + KYX_FORMS_KEYS.SIGNED_BOARD_RESOLUTION_FORM,
      row: { gutter: [40, 12] },
      type: DOCUMENT_STEP_TYPE.DEFAULT,
      container: {
        type: FORM_SECTION_COMPONENT.CARD,
        props: {
          number: getSignedBoardResolutionStepNumber(),
          title: "Signed Board Resolution Form",
          guidelines: GUIDELINES_TYPE.BOARD_RESOLUTION_FORM,
        },
      },
      document: [BOARD_RESOLUTION_FORM_TEMPLATE_LINK, UPLOAD_BOARD_RESOLUTION_FORM],
    },
  ];
};
