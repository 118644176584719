import React from "react";
import moment from "moment";
import TeamListActions from "Views/Team/List/Actions/index";
import singularOrPlural from "utility/Helper/singularOrPlural";
import LetterAvatar from "Modules/letterAvatar";
import { USER_ROLE, DEFAULT_LIMIT_VALUES } from "constants/Team.constant";
import { currencyFormatterWithFractions, currencyFormatter, dateTimeFormatter, currencyFormatterV2 } from "utility";
import { Cookies } from "react-cookie";
import ScheduledActionButtons from "./Actions/ScheduledListAction";
import { intlDateTimeFormat, dateTimeWithTimezone, intlTimeFormat } from "utility/index";
import { InfoOutline, Tags, Tooltip, WarningFilled } from "@spenmo/splice";
import { ITagsProgressionProps } from "@spenmo/splice/lib/components/Tags/Progression";
import { ISentFundsListItem } from "../type";
import { IDetail } from "Modules/TransactionDetail";

export const nameItem = (name, orgType, isPaymentEnabled) => (
  <div className="teams-tab-page__name">
    <LetterAvatar rounded name={name} />
    {/* hard coded for SaaS only */}
    {orgType === "Organisation" && !isPaymentEnabled ? "Default" : name}
  </div>
);

const cookies = new Cookies();
const currencyCode = cookies.get("currency_code") || "";

const managerOnly = () => <p className="manager-only">Owner Only</p>;

const hasPrivilegedRole = (user) =>
  user.company_role === USER_ROLE.ADMIN ||
  user.company_role === USER_ROLE.ACCOUNTANT ||
  user.company_role === USER_ROLE.AUDITOR ||
  user.your_role === USER_ROLE.MANAGER;

export const renderPrivilegedCell = (record, item) => {
  return hasPrivilegedRole(record) ? <p className="teams-tab-page__size">{item}</p> : managerOnly();
};

export const getLimitType = (record) => {
  const { monthly_limit = null } = record?.limit || {};
  if ((monthly_limit || monthly_limit === 0) && monthly_limit?.toString() !== DEFAULT_LIMIT_VALUES.NO_LIMIT_SET) {
    return "Monthly";
  }
  return "Unlimited";
};

export const getTotalLimit = (record) => {
  const { monthly_limit = null } = record?.limit || {};
  if ((monthly_limit || monthly_limit === 0) && monthly_limit?.toString() !== DEFAULT_LIMIT_VALUES.NO_LIMIT_SET) {
    return currencyFormatterWithFractions(monthly_limit, currencyCode, 2);
  }
  return "Unlimited";
};

export const getRemainingLimit = (record) => {
  const limitType = getLimitType(record);
  if (limitType === "Unlimited") return "Unlimited";
  return currencyFormatterWithFractions(record?.remaining?.remaining_monthly_limit || "", currencyCode, 2);
};

export const AllBudgetColumnsDataMember = (isPaymentEnabled: boolean) => [
  {
    id: "BUDGETS-COL-1",
    enabled: true,
    column: {
      title: "Name",
      dataIndex: "team_name",
      key: "name",
      width: 136,
      render: (name, record) => nameItem(name, record?.team_type, isPaymentEnabled),
    },
  },
  {
    id: "BUDGETS-COL-2",
    enabled: true,
    column: {
      title: "Membership",
      dataIndex: "total_members",
      key: "total_members",
      width: 110,
      render: (members) => <p className="teams__size">{`${members} ${singularOrPlural(members, "members")}`}</p>,
    },
  },
];

export const AllBudgetColumnsData = (currencyCode: string, isPaymentEnabled: boolean) => [
  ...AllBudgetColumnsDataMember(isPaymentEnabled),
  {
    id: "BUDGETS-COL-4",
    enabled: true,
    column: {
      title: "Spending Period",
      dataIndex: "spending_period",
      key: "spending_period",
      width: 110,
      render: (_, record) => renderPrivilegedCell(record, record?.spending_period || "N/A"),
    },
  },
  {
    id: "BUDGETS-COL-7",
    enabled: true,
    column: {
      title: "Recurrence",
      dataIndex: "limit",
      key: "limit_recurrence",
      width: 110,
      render: (_, record) => renderPrivilegedCell(record, getLimitType(record)),
    },
  },
  {
    id: "BUDGETS-COL-3",
    enabled: true,
    column: {
      title: isPaymentEnabled ? (
        "Current Spending"
      ) : (
        <div className="column-with-tooltip">
          Est. Spend{" "}
          <Tooltip
            placement="top"
            title="This is the total estimated spend from each budget. Note that actual payment amounts may vary due to transfer or other fees imposed by your bank or payment platform."
          >
            <InfoOutline size="16" iconColor="var(--icon-strong)" />
          </Tooltip>
        </div>
      ),
      dataIndex: "expenses_data",
      key: "expenses_this_month",
      width: 160,
      render: (_, record) => {
        const { expenses_data: expensesData, limit } = record;
        const remainingBudget = limit?.monthly_limit - expensesData?.expenses_this_month;

        return renderPrivilegedCell(
          record,
          <>
            {currencyFormatterWithFractions(record?.expenses_data?.expenses_this_month, currencyCode, 2)}
            {!isPaymentEnabled && !isNaN(remainingBudget) && remainingBudget < 0 && (
              <Tooltip
                title={`You have exceeded your budget limit by ${currencyFormatterWithFractions(
                  Math.abs(remainingBudget),
                  currencyCode,
                  2
                )}. Kindly review your spending or increase your limit as needed.`}
              >
                <WarningFilled className="overspend-icon" size="16" iconColor="var(--text-body-danger)" />
              </Tooltip>
            )}
          </>
        );
      },
    },
  },
  {
    id: "BUDGETS-COL-6",
    enabled: true,
    column: {
      title: "Limit",
      dataIndex: "limit",
      key: "monthly_limit",
      width: 110,
      render: (_, record) => renderPrivilegedCell(record, getTotalLimit(record)),
    },
  },
];

export const AllBudgetColumnActions = [
  {
    id: "BUDGETS-COL-8",
    enabled: true,
    column: {
      title: "",
      key: "id",
      dataIndex: "id",
      width: 110,
      render: (_, record) => {
        return <TeamListActions team={record}></TeamListActions>;
      },
    },
  },
];

export const scheduledColumnData = (isAllowedEditBtn, isAllowedStopBtn, setScheduledEditValue) => [
  {
    id: "SCHEDULED-COL-1",
    enabled: true,
    column: {
      title: "Select All",
      dataIndex: "senderName",
      key: "senderName",
      className: "transactions__item--big",
    },
  },
  {
    id: "SCHEDULED-COL-2",
    enabled: true,
    column: {
      render: () => <h2>→</h2>,
      key: "arrow",
    },
  },
  {
    id: "SCHEDULED-COL-3",
    enabled: true,
    column: {
      dataIndex: "receiverName",
      key: "receiverName",
      className: "transactions__item--big",
    },
  },
  {
    id: "SCHEDULED-COL-4",
    enabled: true,
    column: {
      dataIndex: "receiverTeamName",
      key: "receiverTeamName",
      className: "transactions__item--big",
    },
  },
  {
    id: "SCHEDULED-COL-5",
    enabled: true,
    column: {
      dataIndex: "amount",
      render: (amount, record) => currencyFormatter(amount, record.currency),
      key: "amount",
    },
  },
  {
    id: "SCHEDULED-COL-6",
    enabled: true,
    column: {
      dataIndex: "schedule",
      render: (data) => moment(data?.nextDate?.replace?.("Z00:00", "Z"))?.format("DD MMM YYYY - HH:mm"),
      key: "nextOccurence",
    },
  },
  {
    id: "SCHEDULED-COL-7",
    enabled: true,
    column: {
      key: "actions",
      dataIndex: "id",
      width: 300,
      render: (_, record) => (
        <ScheduledActionButtons
          record={record}
          isAllowedEditBtn={isAllowedEditBtn}
          isAllowedStopBtn={isAllowedStopBtn}
          setScheduledEditValue={setScheduledEditValue}
        />
      ),
    },
  },
];

// getVariant => to get relevent variant for the tag according to the status of funds transfer
export const getVariant = (status: string): ITagsProgressionProps["variant"] => {
  switch (status) {
    case "rejected":
      return "danger";
    case "pending":
      return "warning";
    case "approved":
      return "success";
    default:
      return "info";
  }
};

// getVariant => to get the text to display in the table according to the status
export const getLabel = (status: string): ITagsProgressionProps["label"] => {
  switch (status) {
    case "rejected":
      return "Rejected";
    case "pending":
      return "Pending";
    case "approved":
      return "Completed";
    default:
      return "Pending";
  }
};

export const getLabelVariant = (status: string) => ({
  variant: getVariant(status),
  label: getLabel(status),
});

// RequestFundsColumns => columns for requested funds in fund transfers page
export const RequestFundsColumns = [
  {
    id: "REQUESTED-COL-1",
    enabled: true,
    column: {
      title: "Created Date & Time",
      dataIndex: "created_at",
      render: (data) => (
        <>
          {dateTimeFormatter(data).date} <br />
          <span className="typhography-caption">{dateTimeFormatter(data).time}</span>
        </>
      ),
      key: "created_at",
      width: "144px",
    },
  },
  {
    id: "REQUESTED-COL-2",
    enabled: true,
    column: {
      title: "Requester",
      dataIndex: "user_name",
      key: "user_name",
    },
  },
  {
    id: "REQUESTED-COL-3",
    enabled: true,
    column: {
      title: "Destination Fund",
      dataIndex: "requested_for_wallet_type",
      key: "requested_for_wallet_type",
      render: (walletType, record) =>
        walletType === "team" ? (
          <>
            (Budget) <br />
            <span className="typhography-caption">{record?.team_name}</span>
          </>
        ) : (
          <>
            {record.user_name} <br />
            <span className="typhography-caption">{record?.team_name}</span>
          </>
        ),
    },
  },
  {
    id: "REQUESTED-COL-4",
    enabled: true,
    column: {
      title: "Reason",
      dataIndex: "title",
      key: "title",
    },
  },
  {
    id: "REQUESTED-COL-5",
    enabled: true,
    column: {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      className: "align-right",
      render: (amount, record) => <p>{currencyFormatterV2(amount, record.currency_code)}</p>,
      width: "168px",
    },
  },
  {
    id: "REQUESTED-COL-5",
    enabled: true,
    column: {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => <Tags.Progression hierarchy="default" {...getLabelVariant(status)} />,
      width: "168px",
    },
  },
];

// RequestFundsColumns => columns for requested funds in fund transfers page
export const SentFundsColumns = [
  {
    id: "SENT-COL-1",
    enabled: true,
    column: {
      title: "Created Date & Time",
      dataIndex: "created_at",
      render: (data) => (
        <>
          {dateTimeFormatter(data).date} <br />
          <span className="typhography-caption">{dateTimeFormatter(data).time}</span>
        </>
      ),
      key: "created_at",
    },
  },
  {
    id: "SENT-COL-2",
    enabled: true,
    column: {
      title: "Source of Fund",
      dataIndex: "source_team",
      key: "source_team",
      render: (sourceTeam, record) => {
        const sourceUserTeam: string = record?.source_user_name ? record?.source_user_name : "(budget)";
        return (
          <>
            {sourceTeam} <br />
            <span className="typhography-caption">{sourceUserTeam}</span>
          </>
        );
      },
    },
  },
  {
    id: "SENT-COL-3",
    enabled: true,
    column: {
      title: "Destination Fund",
      dataIndex: "destination_team",
      key: "destination_team",
      render: (destinationTeam, record) => {
        const destinationUserTeam: string = record?.destination_user_name ? record?.destination_user_name : "(budget)";
        return (
          <>
            {destinationTeam} <br />
            <span className="typhography-caption">{destinationUserTeam}</span>
          </>
        );
      },
    },
  },
  {
    id: "SENT-COL-5",
    enabled: true,
    column: {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      className: "align-right",
      render: (amount, record) => <p>{currencyFormatterV2(amount, record.currency, true, 2)}</p>,
    },
  },
];

export const setCreatedAtDate = (record) => {
  return `${intlDateTimeFormat(new Date(dateTimeWithTimezone(record?.created_at)))}, ${intlTimeFormat(
    new Date(dateTimeWithTimezone(record?.created_at))
  )}`;
};

export const requestedFundTransferDetails = (record): IDetail[] => [
  {
    id: "DETAIL_001",
    key: "Created Date & Time",
    value: record?.created_at ? setCreatedAtDate(record) : "",
  },
  {
    id: "DETAIL_002",
    key: "Transaction Type",
    value: "Transfer (Request Money)",
  },
  {
    id: "DETAIL_003",
    key: "Reason",
    value: record?.title,
  },
  {
    id: "DETAIL_004",
    key: "Requester",
    value: record?.user_name,
  },
  {
    id: "DETAIL_005",
    key: "Destination Fund",
    value:
      record?.requested_for_wallet_type === "team" ? (
        <>
          (Budget) <br />
          <span className="typhography-caption">{record?.team_name}</span>
        </>
      ) : (
        <>
          {record.user_name} <br />
          <span className="typhography-caption">{record?.team_name}</span>
        </>
      ),
  },
  {
    id: "DETAIL_006",
    key: "Approver",
    value: record?.request_assignees[0]?.name,
  },
];

export const sentFundTransferDetails = (record: ISentFundsListItem): IDetail[] => [
  {
    id: "DETAIL_001",
    key: "Created Date & Time",
    value: record?.created_at ? setCreatedAtDate(record) : "",
  },
  {
    id: "DETAIL_002",
    key: "Transaction Type",
    value: "Transfer",
  },
  {
    id: "DETAIL_004",
    key: "Source of Funds",
    value: (
      <>
        {record.source_team} <br />
        <span className="typhography-caption">
          {record?.destination_user_name ? record?.destination_user_name : "(budget)"}
        </span>
      </>
    ),
  },
  {
    id: "DETAIL_005",
    key: "Destination Fund",
    value: (
      <>
        {record.destination_team} <br />
        <span className="typhography-caption">
          {record?.destination_user_name ? record?.destination_user_name : "(budget)"}
        </span>
      </>
    ),
  },
];
