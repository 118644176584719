import React from "react";
import { Redirect } from "react-router-dom";

const RedirectionLogic = ({
  loading,
  rules,
  children,
}: {
  loading: boolean;
  rules: { redirectIf: boolean; path: string }[];
  children: React.ReactElement;
}) => {
  if (loading) {
    return children;
  }

  for (let rule of rules) {
    if (rule.redirectIf) {
      return <Redirect to={rule.path} />;
    }
  }

  return children;
};

export default RedirectionLogic;
