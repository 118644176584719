import { useState, useEffect } from "react";

import { currencyFormatterV2 as currencyFormatter, currencyParser, getCurrencyParts } from "utility";

interface ICurrencyParts {
  decimalSeparator?: string;
  decimalCount?: number;
  thousandSeparator?: string;
}

const zeroValue = /^0+\.*$/i;
const onlyDots = /^\.+$/;

const isLastChar = (val, char) => val?.length && val?.lastIndexOf(char) === val?.length - 1;

export const useFormatAmount = (currency: string) => {
  const [currencyParts, setCurrencyParts] = useState<ICurrencyParts>({
    decimalSeparator: null,
    decimalCount: 0,
    thousandSeparator: null,
  });

  useEffect(() => {
    currency && setCurrencyParts(getCurrencyParts(currency));
  }, [currency]);

  const trailingDecimal = (currencyCode: string, amount = "") => {
    if (!currencyParts.decimalCount) return "";
    const decimalSeparator = currencyCode && currencyParts.decimalSeparator;
    const currentDecimal = ".";
    if (isLastChar(amount, currentDecimal)) return decimalSeparator;
    return "";
  };

  const onChangeModifier = (e: React.ChangeEvent<HTMLInputElement>, onChange) => {
    let val = e.target.value;
    if (onlyDots.test(val) || zeroValue.test(val)) {
      e.target.value = "";
      return;
    }
    if (currency) {
      val = currencyParser(val, currency).replace(/[^0-9.]/g, "");
    }
    onChange(val);
  };
  const getFormattedAmount = (amount) => {
    const trail = trailingDecimal(currency, amount);
    return amount && currency ? currencyFormatter(amount, currency, false) + trail : amount;
  };
  return {
    getFormattedAmount,
    onChangeModifier,
  };
};
