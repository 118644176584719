import * as React from "react";
import { useCardDetailsContext } from "Views/Card/Details/index.context";
import { getIsCardVendorNium } from "customHooks/Card";
import { CARD_COLORS } from "constants/Card/CardColors.constants";
import { spenmoOrangeLogoLarge, spenmoWhiteLogoLarge } from "assets/v1.1/logo/spenmo";
import { vendor_logo, visaIcon } from "assets/img";
import { CARD_TYPE } from "Views/Card/types";

import "Views/Card/Details/Card/V1/index.scss";

const CardV1 = () => {
  const { cardData } = useCardDetailsContext();
  const { cardColor, cardHolder, cardTitle, cardType } = cardData || {};
  const isCardVendorNium = getIsCardVendorNium();

  return (
    <div
      className="request-card"
      style={{
        backgroundColor: cardColor,
      }}
      data-testid="card-v1"
    >
      <img
        className="request-card__company-logo"
        src={cardColor === CARD_COLORS[0] ? spenmoOrangeLogoLarge : spenmoWhiteLogoLarge}
        alt={"company logo"}
        width={30}
        height={32}
      />
      <p className="request-card__title">{cardType === CARD_TYPE.PHYSICAL ? cardHolder?.toUpperCase() : cardTitle}</p>
      <div className="request-card__footer">
        <p className="request-card__number" style={{ color: cardColor === CARD_COLORS[0] ? "#666b79" : "#ffffff" }}>
          **** **** **** ****
        </p>
        <img src={isCardVendorNium ? visaIcon : vendor_logo} alt="vendor icon" />
      </div>
    </div>
  );
};

export default CardV1;
