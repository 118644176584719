import React, { useContext, useState } from "react";

import { IItemID } from "Modules/DS/Menu";
import { Quick } from "Modules/DS/Filter";
import { POSITION_ALGO_TYPE } from "Modules/DS/Popover";
import { ISingleQuickFilterWithSearch } from "Modules/DS/Filter/@types";

import { TrxnFilterContext, TrxnPaginationContext } from "Views/Transaction/Context";
import { ALL_TRXN_PAGINATION_PARAMS } from "Views/Transaction/Constants";
import { ITrxnFilterContext, ITrxnPaginationContext, ITrxnSingleQuickFilter } from "Views/Transaction/@types";

export const SingleSelect = <T extends IItemID>({
  id,
  children,
  displayKey,
  appliedKey = "id",
  idKey = "id",
  defaultValue,
  loading = false,
  searchable = false,
  iconClass,
  onClickOutside,
  footer,
  height,
  dispatchOnSelect = () => null,
  dispatchOnClear = () => null,
}: ITrxnSingleQuickFilter<T>) => {
  const [title, setTitle] = useState<string>("");
  const [selected, setSelected] = useState<string>("");

  const { resetCurrentPage } = useContext<ITrxnPaginationContext>(TrxnPaginationContext);
  const { resetTrxnPaginationParams, onApplyFilter, resetFilter, filter, clearAll } =
    useContext<ITrxnFilterContext>(TrxnFilterContext);

  const onSelectHandler = (value: string) => {
    onApplyFilter({
      [id]: value,
      ...resetTrxnPaginationParams,
    });

    dispatchOnSelect?.(value);
    resetCurrentPage();
  };

  const clearFilter = () => {
    resetFilter([id, ...ALL_TRXN_PAGINATION_PARAMS]);
    dispatchOnClear?.();
    resetCurrentPage();
  };

  const props: ISingleQuickFilterWithSearch<T> = {
    idKey,
    displayKey,
    appliedKey,
    children,
    height,
    footer,
    iconClass,
    onClickOutside,
    searchable,
    clearFilter,
    defaultValue,
    clear: clearAll,
    presetValue: filter[id],
    filterItemProps: { id, containerClass: ".data-table-container", positionAlgoType: POSITION_ALGO_TYPE.HORIZONTAL },
    onSelect: onSelectHandler,
    states: {
      selected,
      setSelected,
      title,
      setTitle,
      loading,
    },
  };

  return <Quick.Single<T> {...props} />;
};
