import {
  FETCH_TEAM_MEMBER_REQUEST,
  FETCH_TEAM_MEMBER_SUCCESS,
  FETCH_TEAM_MEMBER_FAILURE,
  RESET_TEAM_MEMBER,
} from "Redux/Actions/Teams/teamMemberSearch.js";
import { userInitialState } from "Redux/InitialState";

export const teamMemberSearchReducer = (state = userInitialState, action) => {
  switch (action.type) {
    case FETCH_TEAM_MEMBER_REQUEST:
      return {
        ...state,
        loading: true,
        data: action.data,
        error: false,
      };
    case FETCH_TEAM_MEMBER_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: false,
      };
    case FETCH_TEAM_MEMBER_FAILURE:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: true,
      };
    case RESET_TEAM_MEMBER:
      return {
        ...state,
        data: {},
      };
    default:
      return state;
  }
};
