import APIclient from "API/Client";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import { GetOrgId } from "utility";

export const FETCH_TEAMS_EXPENSES_LIST = "FETCH_TEAMS_EXPENSES_LIST";
export const FETCH_TEAMS_EXPENSES_LIST_REQUEST = `${FETCH_TEAMS_EXPENSES_LIST}_REQUEST`;
export const FETCH_TEAMS_EXPENSES_LIST_SUCCESS = `${FETCH_TEAMS_EXPENSES_LIST}_SUCCESS`;
export const FETCH_TEAMS_EXPENSES_LIST_FAILURE = `${FETCH_TEAMS_EXPENSES_LIST}_FAILURE`;
export const RESET_TEAMS_EXPENSES_LIST_DATA = `RESET_TEAMS_EXPENSES_LIST_DATA`;

const DEFAULT_FETCH_LIMIT = 10;
const DEFAULT_PAGE = 0;

export const fetchDataRequest = () => ({
  type: "FETCH_TEAMS_EXPENSES_LIST_REQUEST",
});

export const fetchDataSuccess = (data) => ({
  type: "FETCH_TEAMS_EXPENSES_LIST_SUCCESS",
  data: data,
});

export const fetchDataError = (data) => ({
  type: "FETCH_TEAMS_EXPENSES_LIST_FAILURE",
  data: data,
});

export const resetTeamsExpensesListData = () => ({
  type: RESET_TEAMS_EXPENSES_LIST_DATA,
});

export const getTeamsExpensesList = (
  getOnlyUserManagedTeams = false,
  limit = DEFAULT_FETCH_LIMIT,
  page = DEFAULT_PAGE
) => {
  return (dispatch) => {
    dispatch(fetchDataRequest());
    APIclient.getData(
      `/api/v1/org/${GetOrgId()}/team-list?pg=${page}&limit=${limit}&type=all&with_members=1&managed_teams=${getOnlyUserManagedTeams}`
    )
      .then((response) => {
        const { status } = response?.data || {};
        if (status === HTTP_STATUS_CODE.OK) {
          dispatch(fetchDataSuccess(response?.data));
        } else {
          dispatch(fetchDataError(response?.data));
        }
      })
      .catch((err) => {
        dispatch(fetchDataError(err));
      });
  };
};
