import React, { useEffect, useMemo, useRef, useState } from "react";
import { FormInstance } from "antd/lib/form";

import Button from "Modules/button";

import {
  SOCIAL_MEDIA_OPTIONS,
  FORM_FIELD_TYPES,
  DOCUMENT_STEP_TYPE,
  FORM_SECTION_COMPONENT,
} from "Views/KYX/Constants";
import {
  IDocumentStep,
  IFetchFormSubmissionPayload,
  IFormElement,
  IGetFormConfigsPayload,
  IOptionalSectionCard,
} from "Views/KYX/@types";
import { FORM_NAME, KYX_FORMS_KEYS } from "Views/KYX/Constants/Forms";

import styles from "./index.module.scss";
import { getValidationError, getValidationstatus } from "Views/KYX/Helper";

export const socialMediaInfo = (
  form: FormInstance,
  id: number,
  remarks: IGetFormConfigsPayload,
  isDeletable: boolean,
  isFormDisabled: boolean,
  onDelete?: (id: number) => void
): IFormElement[] => {
  const SOCIAL_MEDIA_TYPE: IFormElement = {
    key:
      FORM_NAME.COMPANY_INFORMATION +
      KYX_FORMS_KEYS.ADDITIONAL_INFORMATION +
      KYX_FORMS_KEYS.SOCIAL_MEDIA +
      id +
      KYX_FORMS_KEYS.SOCIAL_MEDIA_TYPE,
    col: { span: 10 },
    id: id,
    element: {
      type: FORM_FIELD_TYPES.SELECT,
      props: {
        formProps: {
          name: [
            FORM_NAME.COMPANY_INFORMATION,
            KYX_FORMS_KEYS.ADDITIONAL_INFORMATION,
            KYX_FORMS_KEYS.SOCIAL_MEDIA,
            id,
            KYX_FORMS_KEYS.SOCIAL_MEDIA_TYPE,
          ],
          label: "Social Media Type",
          validateStatus: getValidationstatus(remarks, [
            KYX_FORMS_KEYS.ADDITIONAL_INFORMATION,
            KYX_FORMS_KEYS.SOCIAL_MEDIA,
            id,
            KYX_FORMS_KEYS.SOCIAL_MEDIA_TYPE,
          ]),
          help: getValidationError(
            remarks,
            [KYX_FORMS_KEYS.ADDITIONAL_INFORMATION, KYX_FORMS_KEYS.SOCIAL_MEDIA, id, KYX_FORMS_KEYS.SOCIAL_MEDIA_TYPE],
            "Social Media Type",
            FORM_NAME.COMPANY_INFORMATION
          ),
        },
        selectProps: {
          disabled: isFormDisabled,
          placeholder: "Select social media",
          options: SOCIAL_MEDIA_OPTIONS,
          onChange: (val) => {
            form.setFieldsValue({
              [KYX_FORMS_KEYS.ADDITIONAL_INFORMATION]: {
                [KYX_FORMS_KEYS.SOCIAL_MEDIA]: { [id]: { [KYX_FORMS_KEYS.SOCIAL_MEDIA_TYPE]: val } },
              },
            });
          },
        },
      },
    },
  };

  const SOCIAL_MEDIA_URL: IFormElement = {
    key:
      FORM_NAME.COMPANY_INFORMATION +
      KYX_FORMS_KEYS.ADDITIONAL_INFORMATION +
      KYX_FORMS_KEYS.SOCIAL_MEDIA +
      id +
      KYX_FORMS_KEYS.SOCIAL_MEDIA_URL,
    col: { span: isDeletable ? 13 : 14 },
    id: id,
    element: {
      type: FORM_FIELD_TYPES.INPUT,
      props: {
        formProps: {
          name: [
            FORM_NAME.COMPANY_INFORMATION,
            KYX_FORMS_KEYS.ADDITIONAL_INFORMATION,
            KYX_FORMS_KEYS.SOCIAL_MEDIA,
            id,
            KYX_FORMS_KEYS.SOCIAL_MEDIA_URL,
          ],
          rules: [{ whitespace: true, message: "This field cannot be empty" }],
          label: "Social Media URL",
          validateStatus: getValidationstatus(remarks, [
            KYX_FORMS_KEYS.ADDITIONAL_INFORMATION,
            KYX_FORMS_KEYS.SOCIAL_MEDIA,
            id,
            KYX_FORMS_KEYS.SOCIAL_MEDIA_URL,
          ]),
          help: getValidationError(
            remarks,
            [KYX_FORMS_KEYS.ADDITIONAL_INFORMATION, KYX_FORMS_KEYS.SOCIAL_MEDIA, id, KYX_FORMS_KEYS.SOCIAL_MEDIA_URL],
            "Social Media URL",
            FORM_NAME.COMPANY_INFORMATION
          ),
        },
        inputProps: {
          disabled: isFormDisabled,
          datatype: "string",
          placeholder: "Enter company's social media URL",
          onChange: (val) => {
            form.setFieldsValue({
              [FORM_NAME.COMPANY_INFORMATION]: {
                [KYX_FORMS_KEYS.ADDITIONAL_INFORMATION]: {
                  [KYX_FORMS_KEYS.SOCIAL_MEDIA]: { [id]: { [KYX_FORMS_KEYS.SOCIAL_MEDIA_URL]: val } },
                },
              },
            });
          },
        },
      },
    },
  };

  const SOCIAL_MEDIA_DELETE_BUTTON: IFormElement = {
    key:
      FORM_NAME.COMPANY_INFORMATION +
      KYX_FORMS_KEYS.ADDITIONAL_INFORMATION +
      KYX_FORMS_KEYS.SOCIAL_MEDIA +
      id +
      "delete",
    col: { span: isDeletable ? 1 : 0, style: { paddingLeft: 0, display: isDeletable ? "block" : "none" } },
    id: id,
    element: {
      type: FORM_FIELD_TYPES.ICONBUTTON,
      props: {
        id:
          FORM_NAME.COMPANY_INFORMATION +
          "_" +
          KYX_FORMS_KEYS.ADDITIONAL_INFORMATION +
          "_" +
          KYX_FORMS_KEYS.SOCIAL_MEDIA +
          "_" +
          id +
          "_",

        onClick: (e) => {
          if (!isDeletable) return;

          onDelete?.(id);
        },
      },
    },
  };

  return [SOCIAL_MEDIA_TYPE, SOCIAL_MEDIA_URL, SOCIAL_MEDIA_DELETE_BUTTON];
};

export const useAdditionalInformationFormSGD = (
  form: FormInstance,
  formData: IFetchFormSubmissionPayload,
  remarks: IGetFormConfigsPayload,
  isFormDisabled: boolean
): IDocumentStep => {
  const [socialMediaLinks, setSocialMediaLinks] = useState<IFormElement[]>([]);
  const [removeSocialMediaLinkId, setRemoveSocialMediaLinkId] = useState(null);
  const linkCount = useRef(0);

  useEffect(() => {
    if (removeSocialMediaLinkId) {
      const tempSocialMediaLinks = [...socialMediaLinks];
      const filterSocialMediaLinks = tempSocialMediaLinks.filter((ele) => ele.id !== removeSocialMediaLinkId);
      setSocialMediaLinks(filterSocialMediaLinks);
      setRemoveSocialMediaLinkId(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [removeSocialMediaLinkId]);

  const addSocialMediaLinks = (isDeletable = false, onDelete?: (id: number) => void) => {
    const linkId = linkCount.current;
    setSocialMediaLinks((prev) => {
      return [...prev, ...socialMediaInfo(form, linkId, remarks, isDeletable, isFormDisabled, onDelete)];
    });
    linkCount.current += 1;
  };

  const initialSocialMediaLinks = useMemo(() => {
    return formData?.form?.company_information?.additional_information?.social_media || [];
  }, [formData?.form?.company_information?.additional_information?.social_media]);

  useEffect(() => {
    if (initialSocialMediaLinks.length === 0 && socialMediaLinks.length === 0) {
      addSocialMediaLinks(false);
    } else if (initialSocialMediaLinks?.length > 0) {
      setSocialMediaLinks([]);
      linkCount.current = 0;
      initialSocialMediaLinks.forEach((_, index) => {
        addSocialMediaLinks(index > 0 ? true : false, setRemoveSocialMediaLinkId);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialSocialMediaLinks]);

  const ADD_SOCIAL_MEDIA: IOptionalSectionCard = {
    key: FORM_NAME.COMPANY_INFORMATION + KYX_FORMS_KEYS.ADDITIONAL_INFORMATION + KYX_FORMS_KEYS.SOCIAL_MEDIA,
    children: (
      <Button
        type="button"
        secondary
        rounded
        className={styles.lightBtn}
        action={() => addSocialMediaLinks(true, setRemoveSocialMediaLinkId)}
      >
        Add more social media
      </Button>
    ),
  };

  // Additional Information
  return {
    key: FORM_NAME.COMPANY_INFORMATION + KYX_FORMS_KEYS.ADDITIONAL_INFORMATION,
    type: DOCUMENT_STEP_TYPE.DEFAULT,
    row: { gutter: 40 },
    container: {
      type: FORM_SECTION_COMPONENT.CARD_WITH_OPTIONAL,
      props: {
        header: {
          number: 1.3,
          optional: true,
          title: "Additional Information",
        },
        optional: [ADD_SOCIAL_MEDIA],
      },
    },
    document: [...socialMediaLinks],
  };
};
