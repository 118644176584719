import React, { useEffect, useState } from "react";
import { Tabs } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";

import { getTeamProfileData } from "Redux/Actions";
import { getParamsValue } from "utility";

import { TEAMS_PATH } from "constants/Routes.constants";

import OverlayLoader from "Modules/overlayLoader";

import TeamPolicy from "Views/Team/Profile/Policy";
import TeamName from "Views/Team/Profile/TeamName";
import MemberList from "Views/Team/Profile/MemberList";
import { trackActionsOnTeamsPage, TeamProfileEventsMapping } from "Views/Team/Profile/TeamProfileEventsUtilites";

import usePermissionCheck from "Permission/usePermissionCheck";
import { CASBIN_PAGES } from "Permission/Pages";
import { TEAMS_PAGE_TEAM_PROFILE_PAGE_POLICIES_TAB } from "Permission/Actions";
import { useTeamActionContext } from "Views/Team/List/Actions/context";
import { updateTeamProfileName } from "Redux/Actions/Teams/teamProfileAction";
import { TEAM_ACTION_STATUS, TEAM_ACTION } from "Views/Team/List/Actions/constant";
import { trash, trashDisable } from "assets/v1.1/icons/Line";
import { isActionDisabled } from "Views/Team/helper";
import { useDeleteTeamContext } from "Views/Team/Delete/context";
import { TEAM_PATHS } from "Route/Team/paths";
import "./teamProfile.scss";

const TeamProfile = () => {
  const { id } = useParams();
  const permissionParam = [
    {
      object: CASBIN_PAGES.TEAMS_PAGE_TEAM_PROFILE_PAGE_POLICIES_TAB,
      action: TEAMS_PAGE_TEAM_PROFILE_PAGE_POLICIES_TAB.READ,
      team_id: id,
    },
  ];
  const IS_ALLOWED_READ_POLICY_ACTION = usePermissionCheck(permissionParam);
  const isAdmin = useSelector((state) => state.userInfoReducer?.role === "Admin");
  let history = useHistory();
  const dispatch = useDispatch();

  const { TabPane } = Tabs;
  const { loading, data } = useSelector((state) => state?.teamProfileData);
  const teamData = data?.payload?.budget;
  const { data: allTeams } = useSelector((state) => state?.teamList) || {};
  const totalCount = allTeams?.payload?.total_count || 0;
  const { selectAction, selectTeam } = useTeamActionContext();
  const [activeKey, setActiveKey] = useState("members");
  const { deleted: deleteTeamSuccess } = useDeleteTeamContext();

  useEffect(() => {
    dispatch(getTeamProfileData(id));
  }, [dispatch, id, activeKey]);

  const handleTabChange = (key) => {
    setActiveKey(key);
    trackActionsOnTeamsPage(`${key} tab viewed`);
  };

  const {
    state: { event },
    clearEvent,
  } = useTeamActionContext();
  useEffect(() => {
    if (event.status === TEAM_ACTION_STATUS.COMPLETED) {
      dispatch(updateTeamProfileName(event.data));
      clearEvent();
    }
  }, [event.status]);

  const setActiveTabFromUrl = () => {
    const [tab] = getParamsValue("tab");
    if (tab && (tab === "policies" || tab === "members")) {
      handleTabChange(tab);
    }
  };

  useEffect(() => {
    setActiveTabFromUrl();
  }, []);

  useEffect(() => {
    if (deleteTeamSuccess) {
      setTimeout(() => {
        history.push(TEAM_PATHS.TEAMS);
      }, 2000);
    }
  }, [deleteTeamSuccess, history]);

  const backToTeamsOnClickHandler = () => {
    history.action === "PUSH" ? history.goBack() : history.push(TEAMS_PATH);
    trackActionsOnTeamsPage(TeamProfileEventsMapping.backToTeamsClicked);
  };

  const handleDelete = () => {
    selectTeam({
      ...teamData,
      team_name: teamData.name,
    });
    selectAction(TEAM_ACTION.DELETE);
  };

  return (
    <div className={"team-profile-wrapper"}>
      {loading && id && <OverlayLoader show />}
      <button className="team-profile-wrapper--back-teams" onClick={backToTeamsOnClickHandler}>
        {"Back to Budgets"}
      </button>
      <div className="team-profile-wrapper__title-area">
        <div className="team-profile-wrapper__title-container">
          <h1>Budget Profile</h1>
          {isAdmin && teamData && (
            <button
              disabled={
                totalCount === 1 || isActionDisabled(TEAM_ACTION.DELETE, { ...teamData, team_type: teamData?.type }) // team_type is added only until team api migration is complete
              }
              onClick={handleDelete}
            >
              {totalCount === 1 || isActionDisabled(TEAM_ACTION.DELETE, { ...teamData, team_type: teamData?.type }) ? ( // team_type is added only until team api migration is complete
                <img src={trashDisable} alt="dis" />
              ) : (
                <img data-testid="del-bgt" src={trash} alt="delete" />
              )}
              Delete Budget
            </button>
          )}
        </div>
        {teamData?.name && <TeamName teamDetails={teamData} getTeamProfileData={getTeamProfileData} />}
        <Tabs
          className={"team-profile-menu-items"}
          activeKey={activeKey}
          onTabClick={handleTabChange}
          defaultActiveKey={"members"}
        >
          <TabPane className={"team-profile-menu-items__members"} tab="Members" key="members">
            <MemberList teamDetails={teamData} totalCount={totalCount} />
          </TabPane>
          {IS_ALLOWED_READ_POLICY_ACTION && (
            <TabPane className={"team-profile-menu-items__policies"} tab="Policies" key="policies">
              <TeamPolicy teamDetails={teamData} handleTabChange={handleTabChange} />
            </TabPane>
          )}
        </Tabs>
      </div>
    </div>
  );
};

export default TeamProfile;
