import React from "react";

import DataTable, { DATA_TABLE_SIZE_TYPES, DATA_TABLE_PADDING } from "Modules/DS/DataTable";
import Modal from "Views/Transaction/Modal";
import Toast from "Views/Transaction/Toast";
import CardsTrxn from "Views/Transaction/Cards";

import { TrxnPageProvider } from "Views/Transaction/Provider";

import trxnsPageStyles from "Views/Transaction/index.module.scss";
import styles from "./index.module.scss";

const CardTransactions = () => {
  return (
    <TrxnPageProvider>
      <div className={styles.container}>
        <DataTable
          className={trxnsPageStyles.transactions}
          size={DATA_TABLE_SIZE_TYPES.LARGE}
          padding={DATA_TABLE_PADDING.NONE}
        >
          <CardsTrxn />
        </DataTable>
        <Modal />
        <Toast />
      </div>
    </TrxnPageProvider>
  );
};

export default CardTransactions;
