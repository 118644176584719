import { Context, createContext, Dispatch, SetStateAction } from "react";
import { IAnalyticsFilter } from "Views/Analytics/@types";

interface IMoreFilter {
  [key: string]: string[];
}
interface IAnalytics {
  filter?: IAnalyticsFilter;
  setFilter?: Dispatch<SetStateAction<IAnalyticsFilter>>;
  clear?: boolean;
  setClear?: (value: boolean) => void;
  moreFilter?: IMoreFilter;
  setMoreFilter?: Dispatch<SetStateAction<IMoreFilter>>;
}

const AnalyticsContext: Context<IAnalytics> = createContext({});

export default AnalyticsContext;
