import * as React from "react";
import { getCardLimit } from "Views/Card/Details/Info/Config";
import { renderCardDetails } from "Views/Card/Details/Info/Utility";
import CardHistory from "Views/Card/Details/Info/Tabs/History";
import { ICardDetails, CARD_TYPE } from "Views/Card/types";
import { CARD_DETAILS_TABS } from "Views/Card/List/types";
import styles from "Views/Card/Details/Info/Tabs/index.module.scss";
import { CARD_SETTING_LABEL, DEFAULT_TRANSACTION_TAGS_KB_ARTICLE_LINK } from "constants/Card";
import Chip, { CHIP_STATUS } from "Modules/DS/Chip";
import { helpIcon16x16, infoIcon16x16 } from "assets/icons/card/";
import { Tooltip, TooltipArrowAlignmentEnum, TooltipPositionEnum } from "Modules/DS/Tooltip";
import { useTransactionTags } from "customHooks/Card";

const CardDetailsToRenderConfig = (cardData: ICardDetails) => {
  const { cardHolder, cardType, shippingAddress, cardHolderContact } = cardData;
  return [
    {
      label: "CARDHOLDER",
      value: cardHolder || "-",
    },
    {
      label: "CARD TYPE",
      value: <span className={styles["text-capitalize"]}>{cardType}</span>,
    },
    {
      label: "ADDRESS",
      value: shippingAddress || "-",
    },
    {
      label: "PHONE NUMBER",
      value: cardHolderContact || "-",
    },
  ];
};

export const CardSettingsToRenderConfig = (cardData: ICardDetails) => {
  const showTransactionTags = useTransactionTags();

  const {
    cardTitle,
    expenseCategoryName,
    spendingLimit,
    spendingLimitType,
    spendingLocks,
    cardExpiryYear,
    cardColorName,
    cardType,
    tags,
  } = cardData;

  const { CARD_COLOR, CARD_TITLE, EXPIRY_YEAR, SPENDING_LIMIT } = CARD_SETTING_LABEL;

  const cardSettingsRenderConfig = [
    {
      label: CARD_TITLE,
      value: cardTitle || "-",
      show: true,
    },
    {
      label: SPENDING_LIMIT,
      value: getCardLimit(spendingLimitType, spendingLimit),
      show: true,
    },
    {
      label: EXPIRY_YEAR,
      value: cardExpiryYear,
      show: cardType !== CARD_TYPE.PHYSICAL,
    },
    {
      label: "SPENDING LOCK",
      value: (
        <span className={styles["text-capitalize"]}>
          {spendingLocks
            ?.map((merchantData) => merchantData?.name)
            ?.join(", ")
            ?.toLowerCase() || "None"}
        </span>
      ),
      show: true,
    },
    {
      label: (
        <span className={styles["label"]}>
          DEFAULT EXPENSE CATEGORY
          <Tooltip
            text="Every expense in this card will be automatically categorized into the selected category."
            alignArrow={TooltipArrowAlignmentEnum.CENTER}
            position={TooltipPositionEnum.TOP}
          >
            <img src={infoIcon16x16} alt="Help Icon" />
          </Tooltip>
        </span>
      ),
      value: expenseCategoryName || "N/A",
      show: true,
    },
    {
      label: (
        <span className={styles["label"]}>
          DEFAULT TRANSACTION TAGS
          <a
            href={DEFAULT_TRANSACTION_TAGS_KB_ARTICLE_LINK}
            className="create-or-request-card-steps__input-label__link"
            target="_blank"
            rel="noreferrer"
          >
            <Tooltip
              text="Click to read about Transaction Tag"
              alignArrow={TooltipArrowAlignmentEnum.CENTER}
              position={TooltipPositionEnum.TOP}
            >
              <img src={helpIcon16x16} alt="Help Icon" />
            </Tooltip>
          </a>
        </span>
      ),
      value: tags?.length ? (
        <div className={styles["tags-wrapper"]}>
          {tags.map((tag) => (
            <Chip.Label key={tag.label} title={`${tag.label} - ${tag.value}`} status={CHIP_STATUS.INFO} />
          ))}
        </div>
      ) : (
        "N/A"
      ),
      show: showTransactionTags,
    },
    {
      label: CARD_COLOR,
      value: cardColorName || "-",
      show: true,
    },
  ];
  return cardSettingsRenderConfig.filter((item) => item.show);
};

export const getCardDetailsTabDetails = (cardData: ICardDetails) => {
  return [
    {
      title: CARD_DETAILS_TABS.DETAILS,
      component: (
        <div className={styles["details-container"]} data-testid="card-details">
          {renderCardDetails(CardDetailsToRenderConfig(cardData))}
        </div>
      ),
    },
    {
      title: CARD_DETAILS_TABS.SETTINGS,
      component: (
        <div className={styles["details-container"]} data-testid="card-settings-details">
          {renderCardDetails(CardSettingsToRenderConfig(cardData))}
        </div>
      ),
    },
    {
      title: CARD_DETAILS_TABS.HISTORY,
      component: <CardHistory cardLastFourDigits={cardData?.cardLastFourDigits} cardUserId={cardData?.cardUserId} />,
    },
  ];
};
