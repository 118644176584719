export const orangeCard = require("assets/v1.1/icons/Line/orange-card.svg");
export const orangeTeam = require("assets/v1.1/icons/Line/orange-team.svg");
export const orangeWallet = require("assets/v1.1/icons/Line/orange-wallet.svg");
export const orangeInfoCircle = require("assets/v1.1/icons/Line/orange-info-circle.svg");
export const orangeCalendar = require("assets/v1.1/icons/Line/orange-calendar.svg");
export const orangeTransferMoney = require("assets/v1.1/icons/Line/orange-transfer-money.svg");
export const clock = require("assets/v1.1/icons/Line/clock.svg");
export const clock48 = require("assets/v1.1/icons/Line/clock_48.svg");
export const addReceipt = require("assets/v1.1/icons/Line/add-receipt.svg");
export const grayInfoCircle = require("assets/v1.1/icons/Line/gray-info-circle.svg");
export const close = require("assets/v1.1/icons/Line/close.svg");
export const pencil = require("assets/v1.1/icons/Line/pencil.svg");
export const settings = require("assets/v1.1/icons/Line/settings-icon.svg");
export const trash = require("assets/v1.1/icons/Line/trash.svg");
export const trashDisable = require("assets/v1.1/icons/Line/trash-disable.svg");
export const orangeWarning = require("assets/v1.1/icons/Line/orange-warning.svg");
