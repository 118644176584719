import { ALIGN_ACTION_ITEM, IActionItem } from "Modules/DS/DataTable";

import Filter from "Views/Employee/Filter";
import Search from "Views/Employee/Search";
import AddButton from "Views/Employee/Create/Button";

const Actions = (): IActionItem[] => {
  return [
    {
      id: "EMP-ACTION-1",
      enabled: true,
      align: ALIGN_ACTION_ITEM.LEFT,
      component: Search,
    },
    {
      id: "EMP-ACTION-2",
      enabled: true,
      align: ALIGN_ACTION_ITEM.LEFT,
      component: Filter,
    },
    {
      id: "EMP-ACTION-3",
      enabled: true,
      align: ALIGN_ACTION_ITEM.RIGHT,
      component: AddButton,
    },
  ];
};

export default Actions;
