import * as React from "react";

import Icon from "Modules/icons";

import { ISettlement } from "Views/Employee/types";

import styles from "./Settlement.module.scss";

export const Item = ({ icon, altText, description }: ISettlement) => {
  return (
    <div data-testid="settlement item" className={styles.item}>
      <Icon data-testid="settlement icon" className={styles.item__icon} alt={altText} src={icon} />
      <p data-testid="settlement description">{description}</p>
    </div>
  );
};
