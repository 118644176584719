import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Typography } from "antd";

import styles from "./Item.module.scss";

const Item = ({ title, children }) => {
  const { Text } = Typography;

  return (
    <div className={styles.container}>
      <Text className={classNames(styles.text, styles.title)}>{title}</Text>
      <Text className={classNames(styles.text, styles.content)}>{children}</Text>
    </div>
  );
};

Item.propTypes = { title: PropTypes.string, children: PropTypes.any };

Item.defaultProps = { title: "" };

export default Item;
