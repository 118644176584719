import React from "react";
import { Typography } from "@spenmo/splice";
import { ColumnType } from "antd/lib/table";

import { dateTimeFormatter } from "utility";

import { IReimbursementRequestData } from "Views/Reimbursement/@types";
import { REIMBURSEMENT_PAGE_COLUMNS } from "Views/Reimbursement/Constants";

import styles from "./index.module.scss";

export const Title = () => {
  return (
    <div>
      <Typography size="s" variant="body-content" tag="h5" weight={600} className={styles.header}>
        Created
      </Typography>
      <Typography size="s" variant="body-content" tag="h5" weight={600} className={styles.header}>
        Date & Time
      </Typography>
    </div>
  );
};

export const Cell = ({ value }: { value?: string }) => {
  const title = value ? dateTimeFormatter(value).date + " ," : "Invalid Date ,";
  const subtitle = value ? dateTimeFormatter(value).time : "Invalid Date";

  return (
    <div>
      <Typography size="s" variant="body-content" tag="p" className={styles.title}>
        {title}
      </Typography>
      <Typography size="caption-m" variant="body-content" tag="p" className={styles.subtitle}>
        {subtitle}
      </Typography>
    </div>
  );
};

const createdAt: ColumnType<IReimbursementRequestData> = {
  title: <Title />,
  key: REIMBURSEMENT_PAGE_COLUMNS.CREATED_AT,
  dataIndex: REIMBURSEMENT_PAGE_COLUMNS.CREATED_AT,
  render: (value: string): React.ReactNode => <Cell value={value} />,
};

export default createdAt;
