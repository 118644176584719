import APIclient from "API/Client";
import { UPDATE_WALlET } from "Redux/Actions/Teams/teamListAction";
import { GetOrgId } from "../../utility";
import { getCookieValue, cookieNames } from "utility/CookieHelper";

export const FETCH_WALLET_REQUEST = "FETCH_WALLET_REQUEST";
export const FETCH_WALLET_SUCCESS = "FETCH_WALLET_SUCCESS";
export const FETCH_WALLET_FAILURE = "FETCH_WALLET_FAILURE";

const fetchDataReq = (data) => {
  return {
    type: FETCH_WALLET_REQUEST,
    data: data,
  };
};
const fetchDataSuccess = (data) => {
  return {
    type: FETCH_WALLET_SUCCESS,
    data: data,
  };
};
export const updateWallet = (data = {}) => {
  return {
    type: UPDATE_WALlET,
    data: data,
  };
};
const fetchDataError = (data) => {
  return {
    type: FETCH_WALLET_FAILURE,
    data: data,
  };
};
//
// export const walletFunc = () => {
//     return (dispatch, getState) => {
//         dispatch(fetchDataReq());
//
//         const sessionData = sessionStorage.getItem("reauth");
//
//         axios
//             /*eslint no-process-env: "off"*/
//             .get(window.__ENV__.API_BASE_URL + `/api/v1/org/${sessionData.split("vspenmo1")[0]}/team/${getState().teamReducer.selectedTeamId}`, axiosHeader)
//             .then(data => {
//                 dispatch(fetchDataSuccess(data.data));
//             })
//             .catch(err=>{
//                 dispatch(fetchDataError(err));
//             });
//     };
// };

export const allWalletFunc = () => {
  return (dispatch) => {
    dispatch(fetchDataReq());
    APIclient.getData(`/api/v1/mywallets?organisation_id=${GetOrgId()}`)
      .then((data) => {
        const { wallets = [] } = data?.data?.payload || {};
        const orgCurrencyCode = getCookieValue(cookieNames.CURRENCY_CODE);
        const constructedWallets = wallets?.map((wallet) => {
          const { subwallet_id, display_name, available_balance, currency_code, team_id, wallet_type, team_name } =
            wallet || {};
          return {
            id: subwallet_id,
            name: display_name,
            amount: available_balance,
            currency_code: currency_code || orgCurrencyCode || "",
            type: wallet_type,
            team_id: team_id,
            wallet_type,
            team_name,
          };
        });
        dispatch(fetchDataSuccess(constructedWallets));
      })
      .catch((err) => {
        dispatch(fetchDataError(err));
      });
  };
};
//need to remove *not used
export const employeeWallets = (user_id = 0) => {
  return (dispatch) => {
    //{{base_url}}/api/v1/user/eae6577a-6385-11ea-affa-0242ac110002?organisation_id={{org_id}}

    dispatch(fetchDataReq());
    APIclient.getData(`/api/v1/user/${user_id}?organisation_id=${GetOrgId()}`)
      .then((data) => {
        const constructedWallet = data.data.payload.teams.map((e) => {
          return {
            team_id: e.id,
            team_name: e.id,
            id: e.your_membership_details.user_wallet.id,
            wallet_type: e.your_membership_details.user_wallet.wallet_type,
            available_balance: e.your_membership_details.user_wallet.available_balance,
          };
          // return {
          //     id: e.subwallet_id,
          //     name: e.display_name,
          //     amount: e.available_balance,
          //     type: e.display_name.indexOf("team") > -1 ? "team" : "user"
          // };
        });

        dispatch(fetchDataSuccess(constructedWallet));
      })
      .catch((err) => {
        dispatch(fetchDataError(err));
      });
  };
};
