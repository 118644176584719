// Approval paths
export const APPROVAL_PATHS = {
  OLD_APPROVAL_PAGE: "/approvals",
  REIMBURSEMENTS: "/reimbursements",
  NEW_REIMBURSEMENTS: `/reimbursements/new`,
  EDIT_REIMBURSEMENTS: `/reimbursements/edit/:id`,
  APPROVAL_FLOW: "/approvalflow",
  APPROVAL_REQUEST_CENTER: "/approvalRequestCenter",
  CREATE_APPROVAL_FLOW_WITH_PROCESS: "/approvalflow/create/:processType",
  VIEW_APPROVAL_FLOW_WITH_ID: "/approvalflow/view/:id",
  EDIT_APPROVAL_FLOW_WITH_ID: "/approvalflow/edit/:id",
};
