import React, { useContext, useState } from "react";

import { IColumnData } from "Modules/DS/DataTable";

import { IconCell } from "Views/Transaction/List";
import { InputCell } from "Views/Transaction/Shimmer";
import { TrxnModalContext } from "Views/Transaction/Context";
import { ALL_TRXNS_COLUMNS, TRANSACTION_TYPES, TRXN_MODAL_TYPES } from "Views/Transaction/Constants";
import { editNotAllowed, getReceiptsUrls, includesReversalTrxns, useHistoricalRefund } from "Views/Transaction/Helper";
import { ITrxnReceipts, IGetTrxnResponse, ITrxnModalContext, GET_TRXN_RESPONSE_PARAMS } from "Views/Transaction/@types";

import { AddAttachmentCell } from "./Add";

import { receiptsIcon } from "assets/img";

export const AttachmentCell = ({
  children,
  record,
  isEditAllowed,
  loadingEditPermisson,
}: {
  children: ITrxnReceipts[];
  record: IGetTrxnResponse;
  isEditAllowed: boolean;
  loadingEditPermisson: boolean;
}) => {
  const [loading, setLoading] = useState(false);

  const { toggleModal }: ITrxnModalContext = useContext<ITrxnModalContext>(TrxnModalContext);

  const { isRefundLoading, isHistoricalRefund } = useHistoricalRefund(
    record.id,
    record.transaction_number,
    record.transaction_type
  );

  const onViewReceiptsClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
    toggleModal(TRXN_MODAL_TYPES.VIEW_ATTACHMENT, { receipts: getReceiptsUrls(record.receipts) });
  };

  const renderIcon = () => {
    if (
      editNotAllowed(record.transaction_type) ||
      isHistoricalRefund ||
      [TRANSACTION_TYPES.FEES, TRANSACTION_TYPES.INCENTIVE]?.includes(record.transaction_type as TRANSACTION_TYPES)
    ) {
      return <p>N.A.</p>;
    } else if (Array.isArray(children) && children.length) {
      return <IconCell src={receiptsIcon} alt="view attachment" onClick={onViewReceiptsClick} />;
    } else {
      return (
        <AddAttachmentCell
          record={record}
          setLoading={setLoading}
          disabled={!isEditAllowed || includesReversalTrxns(record.transaction_type)}
        />
      );
    }
  };

  return <InputCell active={loading || isRefundLoading || loadingEditPermisson}>{renderIcon()}</InputCell>;
};

export const attachment = (
  isEditAllowed: boolean,
  loadingEditPermisson: boolean
): IColumnData<IGetTrxnResponse>["column"] => ({
  title: ALL_TRXNS_COLUMNS.ATTACHMENT,
  width: 120,
  key: GET_TRXN_RESPONSE_PARAMS.RECEIPTS,
  dataIndex: GET_TRXN_RESPONSE_PARAMS.RECEIPTS,
  align: "center",
  render: (receipts: ITrxnReceipts[], record: IGetTrxnResponse): React.ReactNode => (
    <AttachmentCell record={record} loadingEditPermisson={loadingEditPermisson} isEditAllowed={isEditAllowed}>
      {receipts}
    </AttachmentCell>
  ),
});
