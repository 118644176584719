import * as React from "react";
import { Row, Col } from "antd";
import Input from "Views/Card/Components/Input";
import CountrySelector from "Views/Card/CreateOrRequest/Steps/ShippingAddress/CountrySelector";
import ActionBtns from "Views/Card/CreateOrRequest/ActionBtns";
import { useCreateOrRequestCardContext } from "Views/Card/CreateOrRequest/index.context";
import { Actions } from "Views/Card/CreateOrRequest/Utilities/UseCreateOrRequestState";

import styles from "./index.module.scss";

export const ShippingAddress = (): React.ReactElement => {
  const { state, dispatch, changeStep, cardRequestInProgress } = useCreateOrRequestCardContext();
  const { adminOrManagerInSelectedTeam } = state || {};
  const enableNextButton = () => {
    const { addressLine1, pincode, country } = state;
    return !!(addressLine1 && pincode && country);
  };

  const dispatchUpdate = (event: React.ChangeEvent<HTMLInputElement>, ActionType: string) => {
    dispatch({ type: ActionType, value: event?.target?.value || "" });
  };

  return (
    <div className={styles.shippingAddressContainer}>
      <div className="create-or-request-card-steps">
        <p className="create-or-request-card-steps__header">Enter shipping address</p>
        <section className="create-or-request-card-steps__form-container">
          <p className="create-or-request-card-steps__input-label sp-margin-top--xl">
            Address Line 1<span className="mandatory-star">*</span>
          </p>
          <Input
            value={state.addressLine1}
            onChange={(event) => dispatchUpdate(event, Actions.UPDATE_ADDRESS_LINE_1)}
            placeholder="Enter street name"
          />
          <p className="create-or-request-card-steps__input-label sp-margin-top--xl">Address Line 2</p>
          <Input
            value={state.addressLine2}
            onChange={(event) => dispatchUpdate(event, Actions.UPDATE_ADDRESS_LINE_2)}
            placeholder="Address Line 2"
          />
          <Row gutter={[16, 0]}>
            <Col span={12}>
              <p className="create-or-request-card-steps__input-label sp-margin-top--xl">
                Country<span className="mandatory-star">*</span>
              </p>
              <CountrySelector />
            </Col>
            <Col span={12}>
              <p className="create-or-request-card-steps__input-label sp-margin-top--xl">
                ZIP Code<span className="mandatory-star">*</span>
              </p>
              <Input
                value={state.pincode}
                onChange={(event) => dispatchUpdate(event, Actions.UPDATE_PINCODE)}
                placeholder="Enter ZIP Code"
              />
            </Col>
          </Row>
        </section>
      </div>
      <ActionBtns
        actionBtnName={adminOrManagerInSelectedTeam ? "Confirm" : "Next"}
        changeStep={changeStep}
        loading={cardRequestInProgress}
        renderGoBackBtn={true}
        disableNextBtn={!enableNextButton()}
      />
    </div>
  );
};
