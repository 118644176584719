import { IEmployee, IEmployeeFilters } from "Redux/Actions/Employee/Get/Employees/types";

import { IFilter } from "Modules/Filters/types";

/*
  enums
*/
export enum EmployeeModalTypes {
  NONE = "NONE",
  KYC = "KYC_MODAL",
  ROLE = "ROLE_UPDATE_MODAL",
  SWITCH_ADMIN_INFORMATION = "SWITCH_ADMIN_INFORMATION",
  DELETE = "DELETE_EMPLOYEE_MODAL",
  ROLE_EXPLANATION = "ROLE_EXPLANATION_MODAL",
}

export enum KYC_STATUS {
  APPROVED = "Approved",
  PENDING = "Pending",
  REJECTED = "Rejected",
  SUBMITTED = "Submitted",
}

export enum DELETE_EMPLOYEE_MODAL_TYPES {
  CHECK_ELIGIBILITY = EmployeeModalTypes.DELETE + "/CHECK_ELIGIBILITY",
  DELETE_EMPLOYEE_PROMPT = EmployeeModalTypes.DELETE + "/DELETE_EMPLOYEE_PROMPT",
  RESOLVE_PENDING_ITEMS = EmployeeModalTypes.DELETE + "/RESOLVE_PENDING_ITEMS",
  IN_PROGRESS = EmployeeModalTypes.DELETE + "/IN_PROGRESS",
  FAILED = EmployeeModalTypes.DELETE + "/FAILED",
}

/*
  interfaces
*/
export interface IPopupState {
  visible: boolean;
  message: string;
}

export interface IModalState {
  visible: boolean;
  type: EmployeeModalTypes;
  metaData: any;
}

export interface IPendingRequest {
  newRoleId: number;
  employee: IEmployee;
}

export interface IAddEmployeeTab {
  id: string;
  tabTitle: string;
  component: () => JSX.Element;
}

export interface IEmployeeModalType {
  close?: () => void;
  className?: string;
  children: any;
}

export interface IEmployeeModal {
  [key: string]: IEmployeeModalType;
}

export interface IUpdateRoleItemProps {
  roleId: number;
  record: IEmployee;
}

export interface IRole {
  id: number;
  name: string;
  description: string;
}

export interface IUpdateRoleModalView {
  title: string;
  children: React.ReactChild;
}

export interface IRequirement<RequirementType> {
  id: string;
  enabled: boolean;
  requirement: RequirementType;
}

export interface ISettlement {
  icon: string;
  altText: string;
  description: string;
}

export interface IPending {
  title: string;
  description: string | React.ReactNode;
  list?: {
    data: JSX.IntrinsicElements["li"][];
    collapsed?: boolean;
    unit?: string;
  };
  button?: {
    action: () => void;
    text: string;
  };
}

export interface ISingleLine {
  title: string;
  trailing: string;
}

export interface IMultiLine extends ISingleLine {
  subtitle: string;
}

export interface ISingleLineWithLink extends ISingleLine {
  link: string;
  text: string;
  analytics?: {
    enabled: boolean;
    eventName: string;
    properties?: { [key: string]: string };
  };
}

/*
  initialize
*/
export const initalPopupState: IPopupState = {
  visible: false,
  message: "",
};

export const initalModalState: IModalState = {
  visible: false,
  type: EmployeeModalTypes.NONE,
  metaData: null,
};

export const intialPendingRequest: IPendingRequest = {
  newRoleId: undefined,
  employee: undefined,
};

export const initalFiltersState: IFilter<IEmployeeFilters> = {
  values: {},
  clearFilters: null,
};

// CONSTANTS

export const ACCOUNTANT_ROLE_ID = 4;
export const AUDITOR_ROLE_ID = 6;
export const ADMIN_ROLE_ID = 1;
