import { FeeType } from "Redux/DataCalls/Disbursement.api";
import { currencyFormatter } from "utility";

export const payloadParser = (payload) => {
  const beneficiary = {
    bankCountry: payload.beneficiaryBankCountry,
    remarks: payload.beneficiaryRemarks,
    name: payload.beneficiaryName,
    vendorID: payload.vendorID || null,
    legalName: payload.legalName || "",
  };

  let dynamicFields = JSON.parse(payload.dynamicFields || "");

  // IMPROVEMENT: NOT RECOMMENDED since comma
  // can be used anywhere not only email
  // but since there is no static identifier for
  // email, it needs to be done.
  dynamicFields = dynamicFields.map((field) => {
    // condition for email
    if (field.value.indexOf(",") !== -1) {
      return {
        ...field,
        value: field.value.split(","),
      };
    }

    return field;
  });

  const billData = {
    attachments: payload.attachments,
    amount: payload.amount,
    walletAmount: payload.amount * payload.fxRate,
    currency: payload.currency,
    beneficiary,
    dynamicFields: dynamicFields,
    issuedDate: payload.issuedDate,
    dueDate: payload.dueDate,
    paymentScheduleTime: payload.paymentScheduleTime,
    invoiceNumber: payload.billNumber,
    receiptEmails: payload.receiptEmailRecipients,
    expenseCategoryID: payload.expenseCategoryID,
    taxID: payload.taxID,
    tags: payload.tags,
    fxRate: payload.fxRate,
    clientNotes: payload.notes,
    teamID: payload.teamID,
    walletID: payload.walletID,
  };

  return billData;
};

export const feeValueFormatter = (type: keyof typeof FeeType, value: number, currencyCode: string) => {
  switch (type) {
    case FeeType.CURRENCY: {
      return currencyFormatter(value, currencyCode);
    }
    default: {
      //for FeeType.UNIT
      return value;
    }
  }
};
