import React from "react";

import { CreatedCell } from "Views/Transaction/All/Columns/Created";
import { MultiLineTitle } from "Views/Transaction/List";

import { IColumnData } from "Modules/DS/DataTable";
import { IGetCardsTrxnsData, GET_CARDS_TRXNS_DATA_PARAMS, CARDS_TRXNS_TABLE_COLUMNS } from "Views/Transaction/@types";

export const created = (): IColumnData<IGetCardsTrxnsData>["column"] => ({
  title: <MultiLineTitle title={CARDS_TRXNS_TABLE_COLUMNS.CREATED} subtitle={CARDS_TRXNS_TABLE_COLUMNS.DATE_N_TIME} />,
  key: GET_CARDS_TRXNS_DATA_PARAMS.CREATED_AT,
  dataIndex: GET_CARDS_TRXNS_DATA_PARAMS.CREATED_AT,
  width: 136,
  render: (value: string): React.ReactNode => <CreatedCell>{value}</CreatedCell>,
});
