import React from "react";

import { IStartIntroHeader } from "Views/KYX/@types";

import styles from "./index.module.scss";

export const StartIntroHeader = ({ companyName, content, label }: IStartIntroHeader) => {
  return (
    <>
      <h1 className={styles.title}>Hi {companyName}</h1>
      <p className={styles.content}>{content}</p>
      <br />
      <p className={styles.content}>{label}</p>
    </>
  );
};
