import React, { useContext, useEffect } from "react";

import { useAppSelector } from "hook";

import { getCookieValue, cookieNames } from "utility/CookieHelper";

import { RootState } from "Redux/ConfigureStore";

import { IntroLoader as Loader } from "Views/KYX/Loader";
import { errorHandler } from "Views/KYX/Helper/errorHandler";
import { KYXModalContext, KYXToastContext } from "Views/KYX/Context";
import { IKYXModalContext, IKYXToastContext, IPreliminaryData } from "Views/KYX/@types";
import { KYB_STATUS, KYX_MODAL_TYPES, KYX_ORG_COUNTRY_LIST, MY_INFO_ERROR_TYPE } from "Views/KYX/Constants";

import ReviewedUser from "./Update";
import FirstTimeUser from "./Start";
import ReturningUser from "./Resume";

const IntroPage = (): JSX.Element => {
  const { setModal }: IKYXModalContext = useContext(KYXModalContext);
  const { setToaster } = useContext<IKYXToastContext>(KYXToastContext);
  const loading = useAppSelector((state: RootState) => state.preliminaryDataReducer?.loading);

  const preliminaryData: IPreliminaryData = useAppSelector(
    (state: RootState) => state.preliminaryDataReducer?.data?.payload
  );

  const myInfoCallBackData: any = useAppSelector((state: RootState) => state.myInfoCallBackReducer);

  const countryCode = getCookieValue(cookieNames.ORG_COUNTRY_CODE);

  const { org_name: companyName, kyb_status: kybStatus } = preliminaryData || {
    org_name: undefined,
    kyb_status: undefined,
  };

  useEffect(() => {
    const setMyInfoCallBackError = () => {
      const errorDetails = myInfoCallBackData?.data?.error;
      const errorFields = errorDetails?.message;
      if (errorDetails?.code === 4019) {
        setModal({
          visible: true,
          type: KYX_MODAL_TYPES.MY_INFO_ERROR,
          payload: { errorFields: errorFields, errorType: MY_INFO_ERROR_TYPE.DATA_UNAVALIABLE },
        });
      } else if (errorDetails?.code === 4020) {
        setModal({
          visible: true,
          type: KYX_MODAL_TYPES.MY_INFO_ERROR,
          payload: { errorFields: errorFields, errorType: MY_INFO_ERROR_TYPE.ACRA },
        });
      } else {
        const toast = errorHandler(errorDetails);
        setToaster(toast);
      }
    };
    myInfoCallBackData?.error && setMyInfoCallBackError();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myInfoCallBackData?.error]);

  let Component: JSX.Element;

  switch (kybStatus) {
    case KYB_STATUS.NOT_STARTED:
      Component = <FirstTimeUser companyName={companyName} countryCode={countryCode as KYX_ORG_COUNTRY_LIST} />;
      break;
    case KYB_STATUS.STARTED:
      Component = <ReturningUser companyName={companyName} countryCode={countryCode as KYX_ORG_COUNTRY_LIST} />;
      break;
    case KYB_STATUS.ADDITIONAL_DOCUMENTS_REQUIRED:
      Component = <ReviewedUser countryCode={countryCode as KYX_ORG_COUNTRY_LIST} />;
      break;
    case loading:
      Component = <Loader />;
      break;
    default:
      Component = <Loader />;
      break;
  }

  return Component;
};

export default IntroPage;
