import {
  FETCH_DATA_REQUEST,
  FETCH_DATA_SUCCESS,
  FETCH_DATA_FAILURE,
  FETCH_ADD_REQUEST,
  FETCH_ADD_SUCCESS,
  FETCH_ADD_FAILURE,
  FETCH_DELETE_REQUEST,
  FETCH_DELETE_SUCCESS,
  FETCH_DELETE_FAILURE,
} from "../Actions/bankAction";
import { userInitialState } from "../InitialState";

export const bankReducer = (state = userInitialState, action) => {
  switch (action.type) {
    case FETCH_ADD_REQUEST:
      return {
        ...state,
        data: action.data,
        loading: true,
        error: false,
      };
    case FETCH_ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: false,
      };
    case FETCH_ADD_FAILURE:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: true,
      };
    case FETCH_DELETE_REQUEST:
      return {
        ...state,
        data: action.data,
        loading: true,
        error: false,
      };
    case FETCH_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: false,
      };
    case FETCH_DELETE_FAILURE:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: true,
      };

    case FETCH_DATA_REQUEST:
      return {
        ...state,
        data: action.data,
        loading: true,
        error: true,
      };
    case FETCH_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: false,
      };
    case FETCH_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: true,
      };
    default:
      return state;
  }
};
