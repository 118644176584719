import { COMPANY_ROLES, TEAM_ROLES } from "constants/QuickAccess.constant";
import { checkAccess, checkMembership } from "utility/permissions/permission";
import { quickAccessShortCuts } from "./QuickAccessShortCuts";

const handleQuickAccessShortCuts = (list) => {
  return list.map((title) => quickAccessShortCuts.find((item) => item?.id === title || item.title === title));
};

export const configQuickAccessShortCuts = ({
  isLimitModel,
  teamRole,
  companyRole,
  userInfo,
  isPaymentRunFlagOn,
  isSetupPaymentRun,
}) => {
  const isManager = userInfo?.manage_teams?.length > 0;
  const isMember = checkMembership([TEAM_ROLES.MEMBER, TEAM_ROLES.NON_MEMBER], teamRole);

  const submitBillsAccessName = "submitBillsUplifting";
  const withPaymentRunFlag =
    isPaymentRunFlagOn && isLimitModel
      ? !isSetupPaymentRun
        ? "Setup Payment Run"
        : "View Payment Run"
      : "Manage Bill Payments";

  switch (true) {
    case checkAccess([COMPANY_ROLES.ADMIN], companyRole):
      if (isLimitModel) {
        return handleQuickAccessShortCuts([
          submitBillsAccessName,
          withPaymentRunFlag,
          "Create New Budget",
          "Add New Employee",
        ]);
      }
      return handleQuickAccessShortCuts(["Send Money", "Request Money", submitBillsAccessName, "Manage Bill Payments"]);

    case checkAccess([COMPANY_ROLES.EMPLOYEE], companyRole) && isManager:
      if (isLimitModel) {
        return handleQuickAccessShortCuts([
          submitBillsAccessName,
          "Manage Bill Payments",
          "Request Reimbursement",
          "View Employees",
        ]);
      }
      return handleQuickAccessShortCuts([
        "Send Money",
        "Request Money",
        submitBillsAccessName,
        "Request Reimbursement",
      ]);

    case checkAccess([COMPANY_ROLES.BOOKKEEPER, COMPANY_ROLES.ACCOUNTANT], companyRole):
      if (isLimitModel) {
        return handleQuickAccessShortCuts([
          submitBillsAccessName,
          "Manage Bill Payments",
          "Request Reimbursement",
          "Manage Categories",
        ]);
      }
      return handleQuickAccessShortCuts([
        "Send Money",
        "Request Money",
        submitBillsAccessName,
        "Request Reimbursement",
      ]);

    case checkAccess([COMPANY_ROLES.AUDITOR], companyRole):
      if (isLimitModel) {
        return handleQuickAccessShortCuts(["View Transactions", "View Categories", "View Budgets", "View Employees"]);
      }
      return handleQuickAccessShortCuts(["View Transactions", "View Categories", "View Budgets", "View Employees"]);

    case checkAccess([COMPANY_ROLES.EMPLOYEE], companyRole) && isMember:
      if (isLimitModel) {
        return handleQuickAccessShortCuts(["Request Reimbursement", "View Transactions", "Manage Bank Account"]);
      }
      return handleQuickAccessShortCuts(["Send Money", "Request Money", "Request Reimbursement", "View Transactions"]);

    default:
      return null;
  }
};
