import * as React from "react";
import cn from "classnames";
import { CARD_DETAILS_FIELDS_MAP, useCardDetailsEditContext } from "Views/Card/Edit/index.context";
import { CARD_COLORS } from "constants/Card/CardColors.constants";
import "Views/Card/Edit/Color/index.scss";

export default function CardEditColor() {
  const { cardDetailsEditState, setCardDetailsEditState } = useCardDetailsEditContext();
  const { color: cardColor } = cardDetailsEditState;
  return (
    <>
      <p className="card-edit__container-header">Card Color</p>
      <div className="card-edit__fields-container card-edit-color__container">
        {CARD_COLORS.map((color, index) => (
          <div
            key={`${color}_${index}`}
            onClick={() => {
              setCardDetailsEditState((prevState) => ({ ...prevState, [CARD_DETAILS_FIELDS_MAP.COLOR]: color }));
            }}
            className={cn("card-edit-color__radio", {
              active: color === cardColor,
            })}
            style={{ backgroundColor: color }}
            data-testid="card-color"
          />
        ))}
      </div>
    </>
  );
}
