import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ToggleSwitch from "Views/Transaction/ToggleSwitch";
import { TABS_URL, TRXNS_PAGE_TITLE } from "Views/Transaction/Constants";
import cn from "classnames";
import styles from "Views/Transaction/index.module.scss";
import { Tooltip, Typography } from "@spenmo/splice";
import { getAccountingWorkflowCount } from "Views/Transaction/Helper";
import { LabelChip } from "Modules/DS/Chip/Label";
import { CHIP_STATUS } from "Modules/DS/Chip";
import { greenTickBoldIcon } from "assets/img";
import { ACCOUNTING_TRANSACTIONS_WORKFLOW, TRANSACTIONS_PATH } from "constants/Routes.constants";
import ExportButton from "./ExportButton";
import { activeTabBasedOnStatus } from "Views/Accounting/Workflow";
import { useAccountingTransaction } from "Views/Transaction/Context";
import { defaultFilter } from "Views/Accounting/Workflow/TransactionFilter";
import { fetchAccountingSummary } from "Redux/Reducers/AccountingWorkflow/accountingWorkflow.slice";

const TransactionPageHeader = ({
  isAllowedToViewAccountingPage,
  isAccountingPage,
  setIsAccountingPage,
  isAllTrxnsDownloadAllowed,
  isAccTrxnsExportAllowed,
}: {
  isAllowedToViewAccountingPage: boolean;
  isAccountingPage: boolean;
  setIsAccountingPage: (_: boolean) => void;
  isAllTrxnsDownloadAllowed: boolean;
  isAccTrxnsExportAllowed: boolean;
}) => {
  const history = useHistory();
  const { pathname, search } = history.location || {};
  const isAccountingPagePath = pathname?.includes("accounting");
  const isOpeningSidePanel = Boolean(new URLSearchParams(search).get("transaction_number"));
  const { activePageDetails, setActivePageDetails } = useAccountingTransaction();
  const dispatch = useDispatch();
  const { accountingWorkflowCount, accountingPartner } = useSelector((state: any) => ({
    accountingWorkflowCount: state.accountingWorkflowReducer?.data?.count || 0,
    accountingPartner: state.accountingPartnerAuthReducer.data,
  }));
  const isAccountingPartnerConnected = accountingPartner?.payload?.has_valid_token;
  const connectedUsingExpenseIntegration = isAccountingPartnerConnected && accountingPartner?.payload?.expense;
  const accountingPartnerName = accountingPartner?.payload?.partnerName;

  const accountingTrxnsCount = getAccountingWorkflowCount(accountingWorkflowCount, connectedUsingExpenseIntegration);
  const accountingTrxnsCountToDisplay = accountingTrxnsCount > 99 ? "99+" : accountingTrxnsCount;
  const areAccountingTrxnsAvailable = accountingTrxnsCount > 0 && !isOpeningSidePanel;

  const isExportAllowedInTrxnPage = !search || search?.search("tab=all") !== -1 ? isAllTrxnsDownloadAllowed : true;
  const showExportBtnSection = isAccountingPage ? isAccTrxnsExportAllowed : isExportAllowedInTrxnPage;

  const [hideAccountingToggleTooltip, setHideAccountingToggleTooltip] = useState(isAccountingPagePath);

  useEffect(() => {
    // calling accounting-summary API only if the user has access to view Accounting page
    if (isAllowedToViewAccountingPage && !accountingTrxnsCount) {
      dispatch(fetchAccountingSummary());
    }
  }, [dispatch, accountingTrxnsCount, isAllowedToViewAccountingPage]);

  const toggleChangeHandler = () => {
    setIsAccountingPage(!isAccountingPage);
    if (!isAccountingPage) {
      setHideAccountingToggleTooltip(true);
      setActivePageDetails({
        ...activePageDetails,
        name: activeTabBasedOnStatus(""),
        accountingFilters: defaultFilter,
      });
    } else {
      setActivePageDetails({ ...activePageDetails, name: TABS_URL.SETTLED });
    }
    const urlPath = isAccountingPage ? TRANSACTIONS_PATH : ACCOUNTING_TRANSACTIONS_WORKFLOW;
    // using window.history to change the url path as it doesn't refreshes the entire page on toggle
    window.history.pushState({ state: urlPath }, "", urlPath);
  };

  return (
    <div className={cn(styles.header, styles.flexStyle)}>
      <div className={styles.flexStyle}>
        <Typography variant="headline-content" size="l" tag="h1" children={TRXNS_PAGE_TITLE} />
        {isAccountingPage && isAccountingPartnerConnected && (
          <LabelChip
            title={`Connected to ${accountingPartnerName}`}
            status={CHIP_STATUS.SUCCESS}
            icon={greenTickBoldIcon}
            className={styles.connectedLabel}
          />
        )}
      </div>
      <div className={cn(styles.accountingToggleContainer, styles.flexStyle)}>
        {isAllowedToViewAccountingPage && (
          <>
            <Typography
              variant="body-content"
              size="s"
              tag="p"
              children="Accountant view"
              className={styles.accountantViewText}
            />
            {areAccountingTrxnsAvailable && (
              <div className={cn(styles.countLabel, styles.flexStyle)}>{accountingTrxnsCountToDisplay}</div>
            )}
            <Tooltip
              title="Click here to view transactions pending review"
              placement="bottom-start"
              initialOpen={areAccountingTrxnsAvailable}
              open={areAccountingTrxnsAvailable && !hideAccountingToggleTooltip}
              onOpenChange={(open) => setHideAccountingToggleTooltip(!open)}
            >
              <ToggleSwitch
                checked={isAccountingPage}
                defaultChecked={isAccountingPagePath}
                showChildren
                onChange={toggleChangeHandler}
              />
            </Tooltip>
          </>
        )}
        {showExportBtnSection && (
          <>
            {isAllowedToViewAccountingPage && <div className={styles.verticalSeparator} />}
            <ExportButton />
          </>
        )}
      </div>
    </div>
  );
};

export default TransactionPageHeader;
