import APIclient from "API/Client";
import { HTTP_STATUS_CODE } from "../../../constants/HTTPStatusCode.constant";

export const FETCH_PHONE_CODE_REQUEST = "FETCH_PHONE_CODE_REQUEST";
export const FETCH_PHONE_CODE_SUCCESS = "FETCH_PHONE_CODE_SUCCESS";
export const FETCH_PHONE_CODE_ERROR = "FETCH_PHONE_CODE_ERROR";

export const fetchDataRequest = (data) => {
  return {
    type: "FETCH_PHONE_CODE_REQUEST",
    data: data,
  };
};

export const fetchDataSuccess = (data) => {
  return {
    type: "FETCH_PHONE_CODE_SUCCESS",
    data: data.payload,
  };
};

export const fetchDataError = (data) => {
  return {
    type: "FETCH_PHONE_CODE_ERROR",
    data: data,
  };
};

export const getPhoneCodes = () => {
  return (dispatch) => {
    dispatch(fetchDataRequest());

    APIclient.getData("/ms/spm-organization/v1/master/phone-code")
      .then((data) => {
        if (data.data.status === HTTP_STATUS_CODE.OK) {
          dispatch(fetchDataSuccess(data.data));
        } else {
          dispatch(fetchDataError(data.data));
        }
      })
      .catch((err) => {
        dispatch(fetchDataError(err));
      });
  };
};
