import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Button from "Modules/button";
import { receiptActionFunc } from "Redux/Actions";

const TransactionEditConfirmation = ({
  editConfirmation,
  resetEditConfirmationState,
  onSubmit,
  handleSaveTransaction,
}) => {
  const [clicked, setClicked] = useState(false);
  const actionData = useSelector((state) => state.receiptActionReducer?.data);
  const dispatch = useDispatch();

  useEffect(() => {
    if (actionData && actionData.payload && clicked) {
      onSubmit(actionData);
    }
  }, [actionData]);

  const updateTransaction = () => {
    setClicked(true);
    if (handleSaveTransaction) {
      // This will handle refund/reversal edit process
      handleSaveTransaction(editConfirmation.data);
    } else {
      // this will handle card/Cr/expense edit process
      dispatch(receiptActionFunc(editConfirmation.data));
    }
  };

  return (
    <div className="transaction-details">
      <p className="transaction-details__confirmation-message">{editConfirmation.message}</p>
      <div className="transaction-details__group-button">
        <div className="transaction-details__edit-btn bg-green">
          <Button text="Proceed" action={updateTransaction} disabled={clicked} />
        </div>
        <div className="transaction-details__edit-btn bg-white">
          <Button text="Cancel" action={resetEditConfirmationState} />
        </div>
      </div>
    </div>
  );
};

TransactionEditConfirmation.propTypes = {
  editConfirmation: PropTypes.object,
  resetEditConfirmationState: PropTypes.func,
  onSubmit: PropTypes.func,
  handleSaveTransaction: PropTypes.func,
};

export default TransactionEditConfirmation;
