import { useEffect } from "react";
import { fetchAllTreatments } from "Redux/splitio/controllers";
import { useSelector } from "react-redux";
import { selectTreatmentWithConfig } from "@splitsoftware/splitio-redux";
import { identifyEvent } from "utility/analytics";
import { GetOrgId } from "utility";

function useCheckFeatureStatus(featureName, loader = false, withConfig = false) {
  const splitio = useSelector((state) => state?.splitio || { treatments: {} });
  const orgId = useSelector((state) => state.userInfoReducer?.data?.payload?.user?.organisation_id) || GetOrgId();
  const treatmentResult = selectTreatmentWithConfig(splitio, featureName, orgId);
  const config = JSON.parse(treatmentResult.config);
  const treatment = treatmentResult.treatment;

  useEffect(() => {
    fetchAllTreatments();
  });

  useEffect(() => {
    const formattedString = featureName?.replaceAll?.("_", " ")?.toLowerCase?.() || "";
    formattedString && identifyEvent({ [`${formattedString} split experiment`]: treatment });
  }, [treatment]);

  if (withConfig) {
    return [treatment, config];
  }

  if (loader) {
    return treatment; // either control | on | off
  }

  return treatment === "on" ? "on" : "off";
}

export default useCheckFeatureStatus;
