export enum TRXN_MODAL_TYPES {
  NONE = "NONE",
  CUSTOM_CSV = "CUSTOM_CSV",
  BANK_STATEMENT = "BANK_STATEMENT",
  DOWNLOAD_RECEIPT = "DOWNLOAD_RECIPT",
  RAW_CSV_WARNING = "RAW_CSV_WARNING",
  RAW_CSV_LIMIT = "RAW_CSV_LIMIT",
  VIEW_ATTACHMENT = "VIEW_ATTACHMENT",
  REMOVE_SUBSCRIPTION_WARNING = "REMOVE_SUBSCRIPTION_WARNING",
}
