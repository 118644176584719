import React, { useCallback, useEffect } from "react";
import { useAppDispatch } from "hook";
import { getTeamListByUserId } from "Redux/Actions";

import TrxnFilters, { More, AttachmentFilter, BudgetFilter } from "Views/Transaction/Filters";

import { CARD_TXN_STATUS, GET_CARDS_TRXNS_REQUEST_PARAMS, ITrxnFilterItem } from "Views/Transaction/@types";
import { REFUNDED_TRXNS_STATUS, REVERSED_TRXNS_STATUS, SETTLED_TRXNS_STATUS } from "Views/Transaction/Constants/Cards";

import DateFilter from "./Date/index";
import CardNumberFilter from "./CardNumber/index";
import CardHolderFilter from "./CardHolder/index";
import MerchantFilter from "./Merchant/index";
import TrxnStatus from "./TrxnStatus";

export const QUICK_FILTER_KEYS: string[] = [GET_CARDS_TRXNS_REQUEST_PARAMS.TXN_STATUS];

export const MORE_FILTER_KEYS: string[] = [
  GET_CARDS_TRXNS_REQUEST_PARAMS.START_DATE,
  GET_CARDS_TRXNS_REQUEST_PARAMS.END_DATE,
  GET_CARDS_TRXNS_REQUEST_PARAMS.CARD_LAST_FOUR,
  GET_CARDS_TRXNS_REQUEST_PARAMS.CARDHOLDER_NAME,
  GET_CARDS_TRXNS_REQUEST_PARAMS.TEAM_ID,
  GET_CARDS_TRXNS_REQUEST_PARAMS.MERCHANT,
  GET_CARDS_TRXNS_REQUEST_PARAMS.ATTACHMENT,
  GET_CARDS_TRXNS_REQUEST_PARAMS.MIN_AMOUNT,
  GET_CARDS_TRXNS_REQUEST_PARAMS.MAX_AMOUNT,
];

const CARDS_TRXNS_QUICK_FILTER_LIST: ITrxnFilterItem[] = [
  {
    id: "CARDS-TRXNS-QUICK-FILTER-1",
    enabled: true,
    Component: <TrxnStatus label="Pending" value={[CARD_TXN_STATUS.PENDING]} />,
  },
  {
    id: "CARDS-TRXNS-QUICK-FILTER-2",
    enabled: true,
    Component: <TrxnStatus label="Settled" value={SETTLED_TRXNS_STATUS} />,
  },
  {
    id: "CARDS-TRXNS-QUICK-FILTER-3",
    enabled: true,
    Component: <TrxnStatus label="Reversed" value={REVERSED_TRXNS_STATUS} />,
  },
  {
    id: "CARDS-TRXNS-QUICK-FILTER-4",
    enabled: true,
    Component: <TrxnStatus label="Refunded" value={REFUNDED_TRXNS_STATUS} />,
  },
  {
    id: "CARDS-TRXNS-QUICK-FILTER-5",
    enabled: false,
    Component: <TrxnStatus label="Declined" value={[CARD_TXN_STATUS.DECLINED]} />,
  },
].filter((item) => item.enabled);

const ALL_TRXN_MORE_FILTER_LIST: ITrxnFilterItem[] = [
  {
    id: "CARDS-TRXNS-MORE-FILTER-1",
    enabled: true,
    Component: (
      <DateFilter
        startDateId={GET_CARDS_TRXNS_REQUEST_PARAMS.START_DATE}
        endDateId={GET_CARDS_TRXNS_REQUEST_PARAMS.END_DATE}
      />
    ),
  },
  {
    id: "CARDS-TRXNS-MORE-FILTER-2",
    enabled: true,
    Component: <CardNumberFilter id={GET_CARDS_TRXNS_REQUEST_PARAMS.CARD_LAST_FOUR} />,
  },
  {
    id: "CARDS-TRXNS-MORE-FILTER-3",
    enabled: true,
    Component: <CardHolderFilter id={GET_CARDS_TRXNS_REQUEST_PARAMS.CARDHOLDER_NAME} />,
  },
  {
    id: "CARDS-TRXNS-MORE-FILTER-4",
    enabled: true,
    Component: <BudgetFilter id={GET_CARDS_TRXNS_REQUEST_PARAMS.TEAM_ID} />,
  },
  {
    id: "CARDS-TRXNS-MORE-FILTER-5",
    enabled: true,
    Component: <MerchantFilter id={GET_CARDS_TRXNS_REQUEST_PARAMS.MERCHANT} />,
  },

  { id: "CARDS-TRXNS-MORE-FILTER-6", enabled: true, Component: <More.Amount title="Amount" /> },
  {
    id: "CARDS-TRXNS-MORE-FILTER-7",
    enabled: false,
    Component: <AttachmentFilter id={GET_CARDS_TRXNS_REQUEST_PARAMS.ATTACHMENT} />,
  },
].filter((item) => item.enabled);

export const CardsTrxnsFilters = () => {
  const dispatch = useAppDispatch();

  const dispatchTeamListAction = useCallback(() => {
    dispatch(getTeamListByUserId());
  }, [dispatch]);

  useEffect(() => {
    dispatchTeamListAction();
  }, [dispatchTeamListAction]);

  return (
    <TrxnFilters quick={CARDS_TRXNS_QUICK_FILTER_LIST} more={ALL_TRXN_MORE_FILTER_LIST} quickKeys={QUICK_FILTER_KEYS} />
  );
};
