import React from "react";
import { FormInstance } from "antd/lib/form";
import { BannerType } from "Modules/DS/Banner";
import { IBillImportedFromXeroData } from "Modules/BillImportedFromXeroBanner/useFetchInvoiceLink";
import { IBannerProps } from "@spenmo/splice/lib/components/Banner";
import { FORM_NAMES } from "Views/SubscriptionManagement/Constants";
import { SaaSConfig } from "Views/Bills/V2/BillForm/type";

export enum EBannerPosition {
  TOP = "top",
  BOTTOM = "bottom",
}

export interface IList {
  id: string;
  title: string;
  date: string;
  subtitle?: string;
  notes?: string;
  state?: any; // can be changed after API integration
}
export interface ISidePanelImplementationProps {
  visible: boolean;
  setVisible: (value: boolean) => void;
  transactionStatus?: IList[];
}

export enum EInputType {
  TAX = "tax",
  MERCHANT = "merchant",
  CATEGORY = "category",
  TRANSACTION_TAGS = "transactionTags",
  NOTES = "notes",
  RECEIPT = "receipt",
  /**
   * As of now `EInputType.RECEIPT` holds list of file urls only, with updates in
   * BillSidePanel, we are now showing name and size as well. But since, the existing
   * use of UploadAttachment in other feature than Bill might not have that information
   * so, a new key `EInputType.RECEIPT_DETAIL` is maintained to hold url, name and size
   * and the existing `EInputType.RECEIPT` can be used as fallback
   */
  RECEIPT_DETAIL = "receiptDetails",
  REJECTION_REASON = "rejectionReason",
  PAYMENT_RECEIPT = "paymentReceipt",
  SUBSCRIPTION = "subscription",
}

export interface IDetail {
  id: string;
  key: string;
  value: string | React.ReactNode;
  subtitle?: string;
  visible?: boolean;
  tooltipContent?: string;
}

export interface ISection {
  id: string;
  title: string;
  details: IDetail[];
}

export interface IBillInformationProps {
  sections: ISection[];
}

export interface ISubscriptionInitialProps {
  [FORM_NAMES.VENDOR_NAME]: string;
  [FORM_NAMES.ESTIMATED_AMOUNT]: number;
  [FORM_NAMES.CURRENCY]: string;
  [FORM_NAMES.BUDGET_ID]: string;
  [FORM_NAMES.OWNER_ID]: string;
}

interface TrackEventDataType {
  name: string;
  role: string;
}

type TransactionForm = Partial<{
  [EInputType.TAX]: string;
  [EInputType.NOTES]: string;
  [EInputType.CATEGORY]: string;
  [EInputType.RECEIPT]: string[];
  [EInputType.RECEIPT_DETAIL]: ReceiptType[];
  [EInputType.TRANSACTION_TAGS]: any[];
  [EInputType.PAYMENT_RECEIPT]?: string[];
  [EInputType.SUBSCRIPTION]?: string;
}>;

export interface ITransactionDetailProps {
  details: IDetail[];
  receiptTitle?: string;
  visibleInput?: { [key: string]: any };
  bannerPosition?: EBannerPosition;
  form: FormInstance;
  setFormValue: (value: any) => void;
  defaultValue?: TransactionForm;
  additionalMarginButtom?: boolean;
  disabled?: boolean;
  setFormChanged?: (value: boolean) => void;
  missingFields?: TransactionForm;
  trackEventData?: TrackEventDataType;
  deletable?: boolean;
  customBannerMessage?: string | React.ReactNode;
  categoryFallback?: string;
  taxFallback?: string;
  ignoreRequiredCheck?: boolean;
  additionalDetails?: any;
  resetFormData?: boolean;
  page?: string;
  paymentReceiptTitle?: string;
  onDeleteFile?: (url: string) => void;
  onRemoveSubscription?: () => void;
  showDSBanner?: boolean;
  bannerProps?: IBannerProps;
  showLineItems?: boolean;
  lineItems?: ITransactionDetailLineItems;
  subscriptionInitialProps?: ISubscriptionInitialProps;
}

export interface ITransactionDetailLineItems {
  title: string;
  items: {
    title: string;
    subtitle: string;
    linkTo: string;
  }[];
  total: {
    title: string;
    subtitle: string;
  };
}

export interface IListProps {
  details: IDetail[];
  children?: React.ReactNode;
  page?: string;
}

export interface IAccordionProps {
  children: React.ReactNode;
  hide?: boolean;
  open: boolean;
  setOpen: (value: boolean) => void;
}

export interface ITransactionHeaderProps {
  amount: string;
  name: string | React.ReactNode;
  secondaryName?: string;
  caption?: string;
  subTitle?: string;
  info?: {
    type: BannerType;
    content: string | React.ReactNode;
  };
  xeroBillBannerData?: IBillImportedFromXeroData;
}

export interface ITransactionStatusProps {
  transactionStatus: IList[];
  currentStatusId?: string;
  footer?: React.ReactNode;
  hide?: boolean;
  page?: string;
}

export interface IBillDetailProps extends ISidePanelImplementationProps {
  info: {
    type: BannerType;
    content: string | React.ReactNode;
  };
}

export interface IUploadAttachmentProps {
  transactionForm: TransactionForm;
  setTransactionForm: React.Dispatch<React.SetStateAction<TransactionForm>>;
  hasMissingField?: boolean;
  disabled?: boolean;
  deletable?: boolean;
  additionalConfig?: any;
  onDelete?: (url: string) => void;
}

export interface ISectionProps {
  children: React.ReactNode;
  additionalMarginButtom?: boolean;
}

export interface IInformationBannerProps {
  position: "top" | "bottom";
  onSeeEmptyFields?: () => void;
  customMessage?: string | React.ReactNode;
}

export interface IAuditTrail {
  label: string;
  time?: string;
  executor?: string;
  state: "pending" | "active" | "done";
  description?: string;
}
export interface IAuditTrailProps {
  list: IAuditTrail[];
  title: string;
  isEditable?: boolean;
}

export interface ReceiptType {
  name: string;
  url: string;
  size: number;
}
