import React from "react";
import { createPortal } from "react-dom";
import { useSelector } from "react-redux";

import { Button, Modal, Typography } from "@spenmo/splice";

import { RootState } from "Redux/ConfigureStore";

import styles from "./index.module.scss";

interface IUpdateWarningModalProps {
  showModal: boolean;
  onClose: () => void;
  onProceed: () => void;
}

export const UpdateWarningModal = ({ showModal, onClose, onProceed }: IUpdateWarningModalProps) => {
  const userName = useSelector((state: RootState) => state?.userInfoReducer?.data?.payload?.user?.name || "");
  return (
    <>
      {createPortal(
        <Modal showModal={showModal} size="m" title="Please note..." onClose={onClose} className={styles.modal}>
          <section className={styles.container}>
            <section className={styles.content}>
              <Typography variant="body-content" size="s">
                {`Hey ${userName}, note that updating subscriptions on Spenmo does not
                automatically update them on the vendor website. We encourage you
                to make the relevant changes on the vendor's website as well.`}
              </Typography>
            </section>
            <section className={styles.footer}>
              <Button variant="secondary" size="l" onClick={onClose}>
                Go back
              </Button>
              <Button variant="primary" size="l" onClick={onProceed}>
                Proceed
              </Button>
            </section>
          </section>
        </Modal>,
        document.getElementById("modal_container")
      )}
    </>
  );
};
