import React from "react";

import { IMenuRow } from "Modules/DS/Menu";

import styles from "./Row.module.scss";

export const MenuRow = ({ id, title, children }: IMenuRow): JSX.Element => {
  return (
    <div id={id} className={styles.container}>
      <h4>{title}</h4>
      <div className={styles.content}>{children}</div>
    </div>
  );
};
