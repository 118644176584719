import React from "react";
import { Loader } from "Modules/DS/Modal/Components";

interface IMoveTeamLoading {
  title?: string;
  subTitle?: string;
}

const MoveTeamLoading = ({ title, subTitle }: IMoveTeamLoading) => {
  return (
    <div className="move-team-member__loading-content">
      <Loader />
      <h4 className="move-team-member__title-loading">{title}</h4>
      <p className="move-team-member__sub-title">{subTitle}</p>
    </div>
  );
};

export default MoveTeamLoading;
