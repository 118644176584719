import { getAccountingTag } from "Redux/DataCalls/AccountingTag.api";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";

export const FETCH_ACCOUNTING_TAG_REQUEST = "FETCH_ACCOUNTING_TAG_REQUEST";
export const FETCH_ACCOUNTING_TAG_SUCCESS = "FETCH_ACCOUNTING_TAG_SUCCESS";
export const FETCH_ACCOUNTING_TAG_FAILURE = "FETCH_ACCOUNTING_TAG_FAILURE";
export const UPDATE_ACCOUNTING_TAGS_VALUE = "UPDATE_ACCOUNTING_TAG_VALUE";

const sendAccountingTagRequest = () => {
  return {
    type: FETCH_ACCOUNTING_TAG_REQUEST,
  };
};

export const fetchAccountingTagSuccess = (data) => {
  return {
    type: FETCH_ACCOUNTING_TAG_SUCCESS,
    payload: data,
  };
};

const fetchAccountingTagError = () => {
  return {
    type: FETCH_ACCOUNTING_TAG_FAILURE,
  };
};

export const generateDataWithId = (data) => {
  return data?.payload?.data?.map?.((tag, index) => {
    const id = index + 1;
    return {
      ...tag,
      selectedChildren: false, // This key is for UI Purpose
      id,
      values: tag.values.map((option, childrenIndex) => {
        const tagOptionId = `${id}${childrenIndex + 1}`;
        return {
          value: option,
          id: Number(tagOptionId),
        };
      }),
    };
  });
};

export const initialTagSetup = (data, initialTags) => {
  return data.map((tag) => {
    const selectedTag = initialTags?.find((initialTag) => tag.label === initialTag.label) || {};
    const selectedChildren = tag.values.find((tagOption) => tagOption.value === selectedTag.value);

    return {
      ...tag,
      selectedChildren: selectedChildren ? selectedChildren.id : false,
    };
  });
};

export const fetchAccountingTag = (initialTags = []) => {
  return (dispatch) => {
    dispatch(sendAccountingTagRequest());

    return getAccountingTag()
      .then(({ data }) => {
        if (data.status === HTTP_STATUS_CODE.OK) {
          const payload = {
            ...data,
            payload: {
              tags: initialTagSetup(generateDataWithId(data), initialTags),
            },
          };
          dispatch(fetchAccountingTagSuccess(payload));
        } else {
          dispatch(fetchAccountingTagError());
        }
      })
      .catch(() => {
        dispatch(fetchAccountingTagError());
      });
  };
};

export const setNewTags = (newTag) => {
  return {
    type: UPDATE_ACCOUNTING_TAGS_VALUE,
    payload: newTag,
  };
};
