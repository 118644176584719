import { TYPES, PAY_NOW_IDENTIFIER_TYPES } from "./types";

export const PAY_NOW_IDENTIFIER = [
  {
    value: PAY_NOW_IDENTIFIER_TYPES.MOBILE,
    label: "Mobile Number",
  },
  {
    value: PAY_NOW_IDENTIFIER_TYPES.NRIC,
    label: "National Registration Identity Card (NRIC)",
  },
];

export const PAYOUT_TYPE = [
  {
    value: TYPES.PAYNOW,
    label: "PayNow",
  },
  {
    value: TYPES.BANK_TRANSFER,
    label: "Bank Transfer",
  },
];
