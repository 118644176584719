import * as React from "react";
import { Select as AntdSelect } from "antd";
import { useSelector } from "react-redux";
import Select from "Views/Card/Components/Select";
import "Views/Card/CreateOrRequest/Steps/index.scss";

const { Option } = AntdSelect;

interface TeamSelectorProps {
  selectedTeamId: string;
  onSelect: (id: string, teamName: string) => void;
}

const TeamSelector = ({ selectedTeamId, onSelect }: TeamSelectorProps) => {
  const { teams = [] } = useSelector((state: any) => state?.teamList?.data?.payload || {});
  const formattedTeams =
    (Array.isArray(teams) &&
      teams?.map((team) => ({
        key: team?.id,
        value: team?.id,
        id: team?.id,
        label: team?.team_name,
      }))) ||
    [];

  return (
    <Select
      value={selectedTeamId || undefined}
      placeholder="Select Budget"
      onSelect={(id, option) => onSelect(id, option?.label?.toString() || "")}
      filterOption={(input, option) => option?.label?.toString()?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0}
      dropdownClassName="create-or-request-card-steps__dropdown"
      listHeight={188}
      getPopupContainer={(triggerNode) => triggerNode.parentElement}
      data-testid="team-selector"
      showSearch
    >
      {formattedTeams?.map((team) => (
        <Option key={team.key} value={team.value} id={team.id} label={team.label}>
          {team.label}
        </Option>
      ))}
    </Select>
  );
};

export default TeamSelector;
