import React from "react";
import { Button, CheckFilled, Typography, UnlockedOutline } from "@spenmo/splice";
import { COMPANY_VERIFICATION } from "Route/KYX/paths";
import { KYB_STATUS, ReKYBLinks } from "Views/KYX/Constants";
import { getCookieValue, cookieNames } from "utility/CookieHelper";

import styles from "./index.module.scss";

export const enablePaymentsList = [
  "Pay invoices and reimbursements directly with Spenmo.",
  "Issue corporate cards with spending locks for greater convenience and control",
  "Manage your recurring bills and subscriptions with dedicated virtual card",
];

const UnlockPayments: React.FC = () => {
  const handlePaymentsList = () => {
    return enablePaymentsList.map((item, index) => (
      <div className={styles.item} key={`${item}-${index}`}>
        <div className={styles.checkIcon}>
          <CheckFilled size="16" iconColor="var(--fill-system-success-strong)" />
        </div>
        <Typography size="caption-m" variant="body-content">
          {item}
        </Typography>
      </div>
    ));
  };

  const handleVerify = () => {
    const kybStatus = getCookieValue(cookieNames.KYB_STATUS);
    const cleanKybStatus = kybStatus.replace("_", "-").toLowerCase();
    if ([KYB_STATUS.NOT_STARTED, KYB_STATUS.STARTED].includes(kybStatus)) {
      return window.open(COMPANY_VERIFICATION.WELCOME);
    }
    return window.open(`org-verification/${cleanKybStatus}`);
  };

  const onContactUs = () => {
    return window.open(ReKYBLinks.supportLink);
  };

  return (
    <div className={styles.container}>
      <div className={styles.bgImage}></div>
      <div className={styles.content}>
        <UnlockedOutline className={styles.lockIcon} size="24" iconColor="var(--black-100)" />
        <Typography size="s" variant="body-content" weight={600} className={styles.title}>
          Verify to gain enhanced visibility and control over your company expenses
        </Typography>

        <div className={styles.info}>
          <Typography className={styles.label} size="caption-m" variant="body-content">
            Verify your business to enable:
          </Typography>
          {handlePaymentsList()}
        </div>

        <div className={styles.buttons}>
          <Button onClick={onContactUs} size="s" variant="tertiary">
            Contact Us
          </Button>
          <Button onClick={handleVerify} size="s" variant="secondary">
            Get Verified
          </Button>
        </div>
      </div>
    </div>
  );
};

export default UnlockPayments;
