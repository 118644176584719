import React, { useState } from "react";
import { Typography } from "@spenmo/splice";

import Banner from "Modules/DS/Banner";

import DateGrid from "./Grid";
import DateInput from "./Input";
import { IRecurringDateSelector, LAST_POSSIBLE_RECURRING_DATE } from "./types";

import styles from "./styles.module.scss";

const RecurringDateSelector = ({
  recurringDate = LAST_POSSIBLE_RECURRING_DATE.MAX,
  onChangeDate,
  onClickHandler,
}: IRecurringDateSelector) => {
  const [showPopover, setShowPopover] = useState(false);

  const lastPossibleDatesInaMonth =
    recurringDate >= LAST_POSSIBLE_RECURRING_DATE.MIN && recurringDate <= LAST_POSSIBLE_RECURRING_DATE.MAX;

  return (
    <div className={styles.container}>
      <Typography size="s" variant="body-content" className={styles.label}>
        Monthly Settlement Date
      </Typography>
      <div className={styles.picker}>
        <DateInput
          date={recurringDate}
          show={showPopover}
          onClickHandler={(e) => {
            setShowPopover((prev) => !prev);
            onClickHandler(e);
          }}
        />
        <DateGrid
          show={showPopover}
          recurringDate={recurringDate}
          onChangeShow={setShowPopover}
          onChangeDate={onChangeDate}
        />
      </div>
      {lastPossibleDatesInaMonth && (
        <Banner
          type="neutral"
          className={styles.info}
          message="This will be set to the last date of the month if this date is not present for that month"
        />
      )}
    </div>
  );
};

export default RecurringDateSelector;
