import React, { useContext } from "react";

import ModalWrapper from "Modules/DS/Modal";

import { IKYXModalContext } from "Views/KYX/@types";
import { KYXModalContext } from "Views/KYX/Context";

import { useModalType } from "./useModalType";

/**
 * Modal component that renders the appropriate modal based on the provided type and context.
 * @returns {JSX.Element} The rendered modal component.
 */
const Modal = (): JSX.Element => {
  const { modal }: IKYXModalContext = useContext(KYXModalContext);

  const { visible, type } = modal;

  const modalType = useModalType()[type];
  return visible ? (
    <ModalWrapper className={modalType.className} visible={visible} close={modalType.close}>
      {modalType.children}
    </ModalWrapper>
  ) : null;
};

export default Modal;
