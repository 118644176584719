import { FETCH_MERCHANT_REQUEST, FETCH_MERCHANT_SUCCESS, FETCH_MERCHANT_FAILURE } from "../Actions/merchantAction";
import { userInitialState } from "../InitialState";

export const merchantReducer = (state = userInitialState, action) => {
  switch (action.type) {
    case FETCH_MERCHANT_REQUEST:
      return {
        ...state,
        data: action.data,
        loading: true,
      };
    case FETCH_MERCHANT_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
      };
    case FETCH_MERCHANT_FAILURE:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: true,
      };
    default:
      return state;
  }
};
