import { ITeamMemberData, ITeamWithMembersData } from "../index";

export const initialTeamMember: ITeamMemberData = {
  member_id: "",
  user_id: "",
  role: "",
  name: "",
};

export const initialTeamWithMembers: ITeamWithMembersData = {
  id: "",
  name: "",
  members: [],
};

export const dummyTeamMember: ITeamMemberData[] = [
  { ...initialTeamMember, user_id: "1" },
  { ...initialTeamMember, user_id: "2" },
  { ...initialTeamMember, user_id: "3" },
  { ...initialTeamMember, user_id: "4" },
  { ...initialTeamMember, user_id: "5" },
  { ...initialTeamMember, user_id: "6" },
  { ...initialTeamMember, user_id: "7" },
  { ...initialTeamMember, user_id: "8" },
  { ...initialTeamMember, user_id: "9" },
  { ...initialTeamMember, user_id: "10" },
];

export const dummyTeamWithMembers: ITeamWithMembersData[] = [
  { ...initialTeamWithMembers, id: "1", members: [...dummyTeamMember] },
  { ...initialTeamWithMembers, id: "2", members: [...dummyTeamMember] },
];
