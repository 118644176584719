import APIclient from "API/Client";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import {
  getFailureDispatchObj,
  getDispatchObject,
} from "Redux/Actions/SecurityHub/GoogleAuthenticator/utilites";

export const FETCH_GA_CONNECTION_STATUS = "FETCH_GA_CONNECTION_STATUS";
export const FETCH_GA_CONNECTION_STATUS_REQUEST = `${FETCH_GA_CONNECTION_STATUS}_REQUEST`;
export const FETCH_GA_CONNECTION_STATUS_SUCCESS = `${FETCH_GA_CONNECTION_STATUS}_SUCCESS`;
export const FETCH_GA_CONNECTION_STATUS_FAILURE = `${FETCH_GA_CONNECTION_STATUS}_FAILURE`;
export const GA_STATUS_DATA_RESET = "GA_STATUS_DATA_RESET";

export const fetchGaConnectionStatus = () => {
  return (dispatch) => {
    dispatch(getDispatchObject(FETCH_GA_CONNECTION_STATUS_REQUEST));
    APIclient.getData("/ms/b2b-auth/v1/2fa/status")
      .then((response) => {
        const { status, data = {} } = response || {};
        if (status === HTTP_STATUS_CODE.OK) {
          dispatch(
            getDispatchObject(FETCH_GA_CONNECTION_STATUS_SUCCESS, data?.data),
          );
        } else {
          dispatch(
            getFailureDispatchObj(FETCH_GA_CONNECTION_STATUS_FAILURE, data),
          );
        }
      })
      .catch((err) => {
        if (err?.response?.status !== HTTP_STATUS_CODE.UNAUTHORIZED) {
          dispatch(
            getFailureDispatchObj(
              FETCH_GA_CONNECTION_STATUS_FAILURE,
              err?.response?.data,
              "Failed to fetch google authentication connection status",
            ),
          );
        }
      });
  };
};
