export const activatePhysicalCardIcon48x48 = require("./activate-physical-card-48x48.svg");
export const requestPhysicalCardIcon48x48 = require("./request-physical-card-48x48.svg");
export const createVirtualCardIcon48x48 = require("./create-virtual-card-48x48.svg");
export const requestVirtualCardIcon48x48 = require("./request-virtual-card-48x48.svg");
export const leftArrowOrangeIcon24x24 = require("./left-arrow-orange-24x24.svg");
export const successNotificationIcon24x24 = require("./success-notification-icon-24x24.svg");
export const errorNotificationIcon24x24 = require("./error-notification-icon-24x24.svg");
export const errorCrossIcon80x80 = require("./error-cross-icon-80x80.svg");
export const drawerCloseIconGrey40x40 = require("./drawer-close-icon-grey-40x40.svg");
export const backArrowGrey40x40 = require("./back-arrow-grey-40x40.svg");
export const downArrowIcon24x24 = require("./down-arrow-icon-24x24.svg");
export const leftArrowIcon24x24 = require("./left-arrow-icon-24x24.svg");
export const blackCrossIcon24x24 = require("./black-cross-icon-24x24.svg");
export const whiteLoadingIcon24x24 = require("./white-loading-icon-24x24.svg");
export const whiteLoadingIcon16x16 = require("./white-loading-icon-16x16.svg");
export const grayLoadingIcon16x16 = require("./gray-loading-icon-16x16.svg");
export const whiteRefreshIcon16x16 = require("./white-refresh-icon-16x16.svg");
export const redLoadingIcon24x24 = require("./red-loading-icon-24x24.svg");
export const orangeWalletIcon102x102 = require("./orange-wallet-icon-102x102.svg");
export const visaIconWhite61x31 = require("./visa-icon-white-61x31.svg");
export const copyIconWhite24x24 = require("./copy-icon-white-24x24.svg");
export const blackTickIcon24x24 = require("./black-tick-icon-24x24.svg");
export const searchIcon120x120 = require("./search-icon-120x120.svg");
export const emptyCardIcon120x120 = require("./empty-card-icon-120x120.svg");
export const masterCardIcon32x20 = require("./master-card-icon-32x20.svg");
export const visaIcon32x20 = require("./visa-icon-32x20.svg");
export const infoIconGrey30x30 = require("./info-icon-grey-30x30.svg");
export const redLock16x16 = require("./red-lock-16x16.svg");
export const waringIcon18x18 = require("./warning.svg");
export const helpIcon16x16 = require("./ic_help.svg");
export const infoIcon16x16 = require("./ic_info.svg");
export const infoIconBlue16x16 = require("./info-icon-blue.svg");
export const radioIcon16x16 = require("./radio.svg");
export const chevronIcon16x16 = require("./chevron.svg");
export const visaIconWhite24x12 = require("./visa-icon-white-24x12.svg");
export const uobIconWhite42x12 = require("./uob-icon-white-42x12.svg");
export const exclamationPointBlack20x20 = require("./exclamation-point-black-20x20.svg");
export const orangeTick140x150 = require("./orange-tick-140x150.svg");
export const emptyCardsOutline72x72 = require("./empty-cards-outline-72x72.svg");
