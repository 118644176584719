import React, { forwardRef, useImperativeHandle, useRef } from "react";
import { Typography } from "@spenmo/splice";
import { Checkbox as AntdCheckbox } from "antd";
import { FieldConsumerType } from "Views/Bills/V2/BillForm/type";

import { CheckboxProps } from "./type";
import styles from "./FormGenerator.module.scss";

const Checkbox = forwardRef((props: CheckboxProps & FieldConsumerType, ref) => {
  const { name, value, textLabel } = props;

  const checkboxRef = useRef(null);
  useImperativeHandle(ref, () => checkboxRef.current);

  return (
    <AntdCheckbox {...props} ref={checkboxRef} checked={value} data-testid={`checkboxField-${name}`}>
      <Typography className={styles.checkboxLabel} variant="body-content" size="s">
        {textLabel}
      </Typography>
    </AntdCheckbox>
  );
});

export default Checkbox;
