import React from "react";

export enum LINE_TYPE {
  SINGLE_LINE = "single-line",
  CHECKBOX_SINGLE_LINE = "checkbox-single-line",
}

export interface ISquareSkeletonProps {
  loading: boolean;
  children: React.ReactElement;
}

export interface ISkeletonLoader {
  loading: boolean;
}

export interface IItemID {
  id: string;
}

export interface IItemTitle {
  title: string;
}

export interface IItemClick<T> {
  onClick: React.MouseEventHandler<T>;
}

export interface ILineIconProps extends IItemID, Partial<ISkeletonLoader> {
  src: string;
  alt?: string;
}

export interface ILineCheckboxProps extends IItemID, IItemClick<HTMLImageElement>, Partial<ISkeletonLoader> {
  selected: boolean;
}

export interface ILineSpanProps extends IItemID, IItemTitle, IItemClick<HTMLSpanElement>, Partial<ISkeletonLoader> {}

export interface ILineItem extends IItemID, IItemTitle {
  src?: string;
  alt?: string;
  IconComponent?: React.ReactNode;
  disabled?: boolean;
}

export interface ILineProps extends ILineItem, Partial<IItemClick<Element>>, Partial<ISkeletonLoader> {
  selected?: boolean;
  type: LINE_TYPE;
}
