import * as React from "react";
import { Button } from "antd";
import { useDispatch } from "react-redux";
import classNames from "classnames";
import { getCardRequestOwnerNamesFunc } from "Redux/Actions";
import Icon from "Modules/icons";
import { blackCrossIcon24x24 } from "assets/icons/card";
import Loader, { LoaderTypes } from "Views/Card/Components/Loader";
import { approveCardRequest, rejectCardRequest } from "Redux/DataCalls/Cards.api";
import { useCardListContext } from "../index.context";
import { CARD_LIST_TABS, IApprovalActionProps } from "Views/Card/List/types";
import { CardNotification } from "Views/Card/Components/Notification";
import DeclineCardRequestModal from "Views/Card/Review/Decline";
import { cardListingTableEvent } from "Views/Card/Analytics";
import { trackEvent } from "utility/analytics";

import CardReviewModal from "Views/Card/Review";
import { getCardRequestReviewData } from "Views/Card/List/Utilities";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";

import "Views/Card/List/ApprovalActions/index.scss";

type apiCallInProgressType = null | { approve: boolean; reject: boolean };

const ApprovalAction = ({ record }: IApprovalActionProps) => {
  const dispatch = useDispatch();
  const [showConfirmToApproveBtn, setShowConfirmToApproveBtn] = React.useState(false);
  const [showCardDeclineModal, setShowCardDeclineModal] = React.useState(false);
  const [requestInProgress, setRequestInProgress] = React.useState<apiCallInProgressType>(null);

  const [showCardReviewModalDetails, setShowCardReviewModalDetails] = React.useState<{
    disableApprove: boolean;
    showReviewModal: boolean;
  } | null>(null);

  const { getCardListData } = useCardListContext();

  const handleError = (data, eventName: string, eventParams?: { [key: string]: string }) => {
    const { status_message = "Something went wrong! Please try again later." } = data?.payload || {};
    CardNotification.error({
      message: status_message,
      duration: 3,
    });
    trackEvent(eventName, eventParams);
  };

  const handleSuccess = (message, cardListToFetch: string | string[], eventName: string) => {
    CardNotification.success({
      message: message,
      duration: 3,
    });
    getCardListData(0, cardListToFetch);
    trackEvent(eventName);
  };
  const onApprove = (payload: { [key: string]: string }) => {
    setRequestInProgress({ approve: true, reject: false });
    const errorEventName = cardListingTableEvent.CARD_REQUEST_APPROVE_ERROR;
    let eventParams = {};
    approveCardRequest(record?.id, payload)
      .then(({ data }) => {
        if (data?.status === HTTP_STATUS_CODE.OK) {
          const { status_message = "" } = data?.payload || {};
          const eventName = cardListingTableEvent.CARD_REQUEST_APPROVE_SUCCESS;
          handleSuccess(status_message, [(CARD_LIST_TABS.CARD_LIST, CARD_LIST_TABS.RECEIVED_CARD_REQUEST)], eventName);
          setShowCardReviewModalDetails(null);
          dispatch(getCardRequestOwnerNamesFunc());
        } else {
          eventParams = {
            "error message": data?.payload?.status_message,
          };
          handleError(data, errorEventName, eventParams);
        }
      })
      .catch((err) => {
        eventParams = {
          "error message": err?.response?.data,
        };
        handleError(err?.response?.data, errorEventName, eventParams);
      })
      .finally(() => {
        setRequestInProgress(null);
        setShowConfirmToApproveBtn(false);
      });
  };

  const onReject = (reason) => {
    setRequestInProgress({ approve: false, reject: true });
    const errorEventName = cardListingTableEvent.CARD_REQUEST_DECLINED_ERROR;
    rejectCardRequest(record?.id, reason)
      .then(({ data }) => {
        if (data?.status === HTTP_STATUS_CODE.OK) {
          const { status_message = "" } = data?.payload || {};
          const eventName = cardListingTableEvent.CARD_REQUEST_DECLINED_SUCCESS;
          handleSuccess(status_message, CARD_LIST_TABS.RECEIVED_CARD_REQUEST, eventName);
          dispatch(getCardRequestOwnerNamesFunc());
        } else {
          handleError(data, errorEventName);
        }
      })
      .catch((err) => {
        handleError(err?.response?.data, errorEventName, { "error message": err?.response?.data });
      })
      .finally(() => {
        setRequestInProgress(null);
        setShowCardDeclineModal(false);
      });
  };

  const getConfirmToApproveBtnText = () =>
    requestInProgress?.approve ? <Loader type={LoaderTypes.white} /> : "Confirm to Approve";

  const onApproveClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    if (record?.letMyManagerChooseLimit) {
      setShowCardReviewModalDetails({ disableApprove: false, showReviewModal: true });
    } else {
      setShowConfirmToApproveBtn(true);
    }
    trackEvent(cardListingTableEvent.CARD_REQUEST_APPROVE_BTN_CLICKED);
  };
  const confirmToApproveClassName = classNames("approval-action__btn approve-btn", {
    "approve-btn-loading": requestInProgress?.approve,
  });

  return (
    <>
      <div className="approval-action" onClick={(e) => e.stopPropagation()}>
        {!showConfirmToApproveBtn ? (
          <>
            <Button className={`approval-action__btn approve-btn`} onClick={onApproveClick}>
              Approve
            </Button>
            <Button
              className="approval-action__btn decline-btn"
              onClick={(e) => {
                e.stopPropagation();
                setShowCardReviewModalDetails({ disableApprove: true, showReviewModal: true });
                trackEvent(cardListingTableEvent.CARD_REQUEST_DECLINED_BTN_CLICKED);
              }}
            >
              Decline
            </Button>
            <Button
              className="approval-action__btn review-btn"
              onClick={(e) => {
                e.stopPropagation();
                setShowCardReviewModalDetails({ disableApprove: false, showReviewModal: true });
                trackEvent(cardListingTableEvent.CARD_REQUEST_REVIEW_LINK_CLICKED);
              }}
            >
              Review
            </Button>
          </>
        ) : (
          <>
            <Button
              className={confirmToApproveClassName}
              onClick={() => onApprove({})}
              disabled={requestInProgress?.approve}
            >
              {getConfirmToApproveBtnText()}
            </Button>
            <Icon
              className={"close-button"}
              src={blackCrossIcon24x24}
              size="2"
              action={(e) => {
                if (!requestInProgress?.approve) {
                  e.stopPropagation();
                  setShowConfirmToApproveBtn(false);
                }
              }}
            />
          </>
        )}
      </div>
      {showCardReviewModalDetails?.showReviewModal && (
        <CardReviewModal
          disableApproveBtn={showCardReviewModalDetails?.disableApprove}
          cardRequestDetails={getCardRequestReviewData(record)}
          onClose={() => setShowCardReviewModalDetails(null)}
          onApprove={onApprove}
          approvalInProgress={requestInProgress?.approve}
          onDecline={() => {
            setShowCardDeclineModal(true);
            setShowCardReviewModalDetails(null);
          }}
        />
      )}
      {showCardDeclineModal && (
        <DeclineCardRequestModal
          onClose={() => setShowCardDeclineModal(false)}
          onConfirm={onReject}
          rejectionInProgress={requestInProgress?.reject}
          onReview={() => {
            setShowCardDeclineModal(false);
            setShowCardReviewModalDetails({ disableApprove: false, showReviewModal: true });
          }}
        />
      )}
    </>
  );
};

export default ApprovalAction;
