import React, { useMemo } from "react";
import styles from "./index.module.scss";
import { useMutableData } from "API/useData";
import { REPORTS_SERVICES } from "Views/Reports/dataCalls/service";
import { Skeleton } from "antd";
import { FrameProps } from "Views/Reports/types";

export const Frame = (props: FrameProps) => {
  const { tab } = props;
  const { data, isLoading } = useMutableData(
    tab ? REPORTS_SERVICES.getFrame(tab) : null,
  );
  const frameUrl = useMemo(() => {
    return data?.data?.url;
  }, [data?.data?.url]);

  if (isLoading) {
    return <Skeleton.Button active className={styles.frameLoader} />;
  }

  return <iframe className={styles.frame} title="user" src={frameUrl} />;
};
