import * as React from "react";
import { useEffect } from "react";

import Icon from "Modules/icons";

import { IToasterProps, TOASTER_SIZE_TYPE, TOASTER_STATUS_TYPE } from "./types.d";
import { DEFAULT_ACTION_LABEL, DEFAULT_TOASTER_TIMEOUT } from "./const";
import { toasterCircleTick, toasterError, toasterSuccessClose, toasterErrorClose } from "assets/img";
import styles from "./Toaster.module.scss";
import classNames from "classnames";

const Toaster = ({
  visible,
  message,
  className,
  size = TOASTER_SIZE_TYPE.L,
  status = TOASTER_STATUS_TYPE.ERROR,
  timeout = DEFAULT_TOASTER_TIMEOUT,
  actionLabel = DEFAULT_ACTION_LABEL,
  action,
  onClose,
}: IToasterProps) => {
  useEffect(() => {
    let id: NodeJS.Timeout;

    if (visible && status === TOASTER_STATUS_TYPE.SUCCESS && timeout > 0) {
      id = setTimeout(onClose, timeout);
    }

    return () => {
      id && clearTimeout(id);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, timeout]);

  let img: string;
  let closeImg: string;

  if (status === TOASTER_STATUS_TYPE.SUCCESS) {
    img = toasterCircleTick;
    closeImg = toasterSuccessClose;
  } else {
    img = toasterError;
    closeImg = toasterErrorClose;
  }

  const containerClassNames = classNames(className, styles.container, styles[status], styles[size]);

  return (
    visible &&
    message && (
      <div className={containerClassNames}>
        <div className={styles.wrapper}>
          <Icon className={styles.icon} src={img} />
          <div className={styles.message} data-testid="toaster-message">
            {message}
          </div>
        </div>
        {action && (
          <button onClick={action} className={styles.action} data-testid="toaster-action">
            {actionLabel}
          </button>
        )}
        <Icon action={onClose} src={closeImg} alt="close icon" className={styles.close} />
      </div>
    )
  );
};

export default Toaster;

export * from "./types.d";
