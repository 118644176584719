import APIclient from "API/Client";
import { HTTP_STATUS_CODE } from "../../../constants/HTTPStatusCode.constant";
import { trackEvent } from "utility/analytics";
export const FETCH_ADD_EMPLOYEE_REQUEST = "FETCH_ADD_EMPLOYEE_REQUEST";
export const FETCH_ADD_EMPLOYEE_SUCCESS = "FETCH_ADD_EMPLOYEE_SUCCESS";
export const FETCH_ADD_EMPLOYEE_ERROR = "FETCH_ADD_EMPLOYEE_ERROR";
export const FETCH_ADD_EMPLOYEE_RESET = "FETCH_ADD_EMPLOYEE_RESET";

export const fetchDataRequest = (data) => {
  return {
    type: "FETCH_ADD_EMPLOYEE_REQUEST",
    data: data,
  };
};

export const fetchDataSuccess = (data) => {
  return {
    type: "FETCH_ADD_EMPLOYEE_SUCCESS",
    data: data,
  };
};

export const fetchDataError = (data) => {
  return {
    type: "FETCH_ADD_EMPLOYEE_ERROR",
    data: data,
  };
};
export const resetAddEmpData = (data) => {
  return {
    type: "FETCH_ADD_EMPLOYEE_RESET",
    data: data,
  };
};

const trackAddEmployeeMethod = (url) => {
  const addEmployeeMethod = url === "/ms/spm-organization/v1/employee/bulk" ? "Bath" : "Manually";
  const params = { method: addEmployeeMethod };
  trackEvent("Add employee finish", params);
};

export const addEmployeesManually = (data, url) => {
  return (dispatch) => {
    dispatch(fetchDataRequest());
    APIclient.postData(url, data.values)
      .then((data) => {
        if (data.data.status === HTTP_STATUS_CODE.CREATED) {
          trackAddEmployeeMethod(url); // To track add employee finish method
          dispatch(fetchDataSuccess(data));
        } else {
          dispatch(fetchDataError(data));
        }
      })
      .catch((error) => {
        dispatch(fetchDataError(error));
      });
  };
};
