import React from "react";
import { ALIGN_ACTION_ITEM, IActionItem } from "Modules/DS/DataTable";
import Sort from "../Sort";
import Search from "../Search";
import Filter from "../Filter";
import ApprovalCardFilter from "../ApprovalCardFilter";
import ApprovalReimbursementFilter from "../ApprovalReimbursementFilter";
import ApprovalFundTransferFilter from "../ApprovalFundTransferFilter";
import { SelectProvider } from "Modules/DS/Select";

export const BillActions = (): IActionItem[] => {
  return [
    {
      id: "APR-BILL-ACTION-1",
      enabled: true,
      align: ALIGN_ACTION_ITEM.LEFT,
      component: Search,
    },
    {
      id: "APR-BILL-ACTION-2",
      enabled: true,
      align: ALIGN_ACTION_ITEM.LEFT,
      component: Filter,
    },
    {
      id: "APR-BILL-ACTION-3",
      enabled: true,
      align: ALIGN_ACTION_ITEM.LEFT,
      component: Sort,
    },
  ].filter((item) => item.enabled);
};

export const CardActions = (): IActionItem[] => {
  return [
    {
      id: "APR-CARD-ACTION-1",
      enabled: true,
      align: ALIGN_ACTION_ITEM.LEFT,
      component: Search,
    },
    {
      id: "APR-CARD-ACTION-2",
      enabled: true,
      align: ALIGN_ACTION_ITEM.LEFT,
      component: (
        <SelectProvider>
          <ApprovalCardFilter />
        </SelectProvider>
      ),
    },
    {
      id: "APR-CARD-ACTION-3",
      enabled: true,
      align: ALIGN_ACTION_ITEM.LEFT,
      component: Sort,
    },
  ].filter((item) => item.enabled);
};

export const ReimbursementActions = (): IActionItem[] => {
  return [
    {
      id: "APR-REIMBURSEMENT-ACTION-1",
      enabled: true,
      align: ALIGN_ACTION_ITEM.LEFT,
      component: Search,
    },
    {
      id: "APR-REIMBURSEMENT-ACTION-2",
      enabled: true,
      align: ALIGN_ACTION_ITEM.LEFT,
      component: ApprovalReimbursementFilter,
    },
    {
      id: "APR-REIMBURSEMENT-ACTION-3",
      enabled: true,
      align: ALIGN_ACTION_ITEM.LEFT,
      component: Sort,
    },
  ].filter((item) => item.enabled);
};

export const fundTransferActions = (): IActionItem[] => {
  return [
    {
      id: "APR-FUND-ACTION-2",
      enabled: true,
      align: ALIGN_ACTION_ITEM.LEFT,
      component: ApprovalFundTransferFilter,
    },
  ].filter((item) => item.enabled);
};
