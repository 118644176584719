import { useState, useEffect } from "react";

import { useAppDispatch } from "hook";

import { getCountryList } from "Redux/Actions/Common/getCountryList";

import { useGetKYXFormData } from "Views/KYX/hooks";
import { FORM_NAME, KYX_FORMS_KEYS } from "Views/KYX/Constants";
import { formatDate, removeFalsyValues } from "Views/KYX/Helper";
import { IFetchDataFormatter, IFetchFormSubmissionPayload, IKYXFetchFormData } from "Views/KYX/@types";

export const useFetchDataFormatter = (): IFetchDataFormatter => {
  const dispatch = useAppDispatch();
  const [formData, setFormData] = useState<IFetchFormSubmissionPayload>(undefined);

  const { getFormData, loading } = useGetKYXFormData(setFormData);

  useEffect(() => {
    getFormData();
    dispatch(getCountryList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [formattedInitialValues, setFormattedInitialValues] = useState<Omit<IKYXFetchFormData, "source">>(undefined);

  useEffect(() => {
    const initialValues = removeFalsyValues(formData?.form);

    let formattedValues;
    let personsObject = {};
    let personsArray = initialValues?.personnel_information?.persons;
    if (personsArray?.length) {
      personsArray.forEach((person, index) => {
        personsObject[index] = {
          ...person,
          date_of_birth: formatDate(person?.date_of_birth),
          roles_of_individual: Object.entries(person.roles_of_individual)
            .map(([key, value]) => {
              if (value) {
                return key;
              } else {
                return undefined;
              }
            })
            .filter((key) => key),
          identification_document: {
            ...person?.identification_document,
            document_type: person?.identification_document?.document_type
              ? person?.identification_document?.document_type
              : "",
            passport_document: {
              ...person?.identification_document["passport_document"],
              expiry_date: person?.identification_document["passport_document"]?.expiry_date
                ? formatDate(person?.identification_document["passport_document"]?.expiry_date)
                : undefined,
            },
            is_same_residential_address: person?.identification_document?.is_same_residential_address
              ? ["checked"]
              : [],
          },
        };
      });
    } else {
      personsObject = {
        0: {
          roles_of_individual: [KYX_FORMS_KEYS.PERSON_ROLES_AUTHORIZED],
        },
      };
    }
    const socialMediaArray = initialValues?.company_information?.additional_information?.social_media;
    const socialMediaObject = {};
    socialMediaArray?.forEach((socialMedia, index) => {
      socialMediaObject[index] = {
        ...socialMedia,
      };
    });

    formattedValues = {
      [FORM_NAME.COMPANY_INFORMATION]: {
        [KYX_FORMS_KEYS.GENERAL_INFORMATION]: {
          ...initialValues?.[FORM_NAME.COMPANY_INFORMATION]?.[KYX_FORMS_KEYS.GENERAL_INFORMATION],
          [KYX_FORMS_KEYS.DATE_OF_INCORPORATION]: formatDate(
            initialValues?.[FORM_NAME.COMPANY_INFORMATION]?.[KYX_FORMS_KEYS.GENERAL_INFORMATION]?.[
              KYX_FORMS_KEYS.DATE_OF_INCORPORATION
            ]
          ),
        },
        [KYX_FORMS_KEYS.COMPANY_ADDRESS]: {
          ...initialValues?.[FORM_NAME.COMPANY_INFORMATION]?.[KYX_FORMS_KEYS.COMPANY_ADDRESS],
          [KYX_FORMS_KEYS.IS_BUSINESS_ADDRESS_DIFFERENT]:
            initialValues?.[FORM_NAME.COMPANY_INFORMATION]?.[KYX_FORMS_KEYS.COMPANY_ADDRESS]?.[
              KYX_FORMS_KEYS.BUSINESS_ADDRESS
            ] ||
            initialValues?.[FORM_NAME.COMPANY_INFORMATION]?.[KYX_FORMS_KEYS.COMPANY_ADDRESS]?.[
              KYX_FORMS_KEYS.BUSINESS_POSTAL_CODE
            ]
              ? ["true"]
              : ["false"],
        },
        [KYX_FORMS_KEYS.ADDITIONAL_INFORMATION]: {
          [KYX_FORMS_KEYS.COMPANY_WEBSITE]:
            initialValues?.[FORM_NAME.COMPANY_INFORMATION]?.[KYX_FORMS_KEYS.ADDITIONAL_INFORMATION]?.company_website,
          social_media: socialMediaObject,
        },
      },
      [FORM_NAME.COMPANY_DOCUMENTS]: {
        ...initialValues?.[FORM_NAME.COMPANY_DOCUMENTS],
        [KYX_FORMS_KEYS.OWNERSHIP_NUMBER_OF_LEVELS]:
          initialValues?.[FORM_NAME.COMPANY_DOCUMENTS]?.[KYX_FORMS_KEYS.OWNERSHIP_NUMBER_OF_LEVELS],
        [KYX_FORMS_KEYS.ACRA_DOCUMENT]: {
          ...initialValues?.[FORM_NAME.COMPANY_DOCUMENTS]?.[KYX_FORMS_KEYS.ACRA_DOCUMENT],
          [KYX_FORMS_KEYS.ACRA_DOCUMENT_EXPIRY]: formatDate(
            initialValues?.[FORM_NAME.COMPANY_DOCUMENTS]?.[KYX_FORMS_KEYS.ACRA_DOCUMENT]?.[
              KYX_FORMS_KEYS.ACRA_DOCUMENT_EXPIRY
            ]
          ),
        },
      },

      ...initialValues?.[FORM_NAME.PERSONNEL_INFORMATION],
      [KYX_FORMS_KEYS.PERSONNEL_DATA_PERSONS]: personsObject,
    };

    setFormattedInitialValues(formattedValues);
  }, [formData?.form]);

  return { formattedInitialValues, loading, formData };
};
