import React from "react";
import classNames from "classnames";

import Button from "Modules/button";
import { IMenuActionsProps } from "Modules/DS/Menu";

import styles from "./Actions.module.scss";

export const Actions = ({
  onClear,
  onApply,
  disableApply = false,
  disableClear = false,
}: IMenuActionsProps): JSX.Element => {
  return (
    <div className={styles.container}>
      <Button
        className={classNames(styles.clear, { [styles.disableClear]: disableClear })}
        textButton
        action={onClear}
        type="reset"
      >
        Clear
      </Button>
      <Button
        className={classNames(styles.add, { [styles.disableApply]: disableApply })}
        action={onApply}
        type="submit"
      >
        Apply
      </Button>
    </div>
  );
};
