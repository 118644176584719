import React, { useContext, useEffect, useState } from "react";
import { Input, Radio } from "antd";
import { search as searchIcon } from "assets/img";
import { AccountingTagsContext } from "Modules/AccountingTags/context/AccountingTagsContext";
import Icon from "Modules/icons";
import "./styles.scss";

const TagOption = () => {
  const [value, setValue] = useState("");
  const { data, selectedTag, selectChildren } = useContext(AccountingTagsContext);
  const { values = [], selectedChildren } = data.find((tag) => tag.id === selectedTag) || {};

  const filteredData = values.filter((tag) => tag.value.toLowerCase().includes(value.toLowerCase()));

  useEffect(() => {
    setValue("");
  }, [selectedTag]);

  return (
    <>
      <div className="search-form">
        <Input placeholder="Search Tag Option" value={value} onChange={(e) => setValue(e.target.value)} />
        <Icon className="search-form__icon" src={searchIcon} />
      </div>

      <div className="accounting-tag-group__list">
        {filteredData.length === 0 ? (
          <span className="error">Tag Option is not available yet. Please add new Tag Option inside Xero.</span>
        ) : (
          <Radio.Group
            className="accounting-tag-options"
            value={selectedChildren}
            onChange={(event) => {
              selectChildren(selectedTag, event.target.value);
            }}
          >
            {filteredData.map((tag) => (
              <Radio key={tag.id} value={tag.id} className="accounting-tag-options__option">
                <span>{tag.value}</span>
              </Radio>
            ))}
          </Radio.Group>
        )}
      </div>
    </>
  );
};

export default TagOption;
