import React from "react";
import spenmoDesktopImg from "assets/img/spenmo-desktop.svg";
import { Typography } from "antd";
import Button from "Modules/button";
import styles from "./index.module.scss";
import { setCookie, cookieNames } from "../../utility/CookieHelper";
import useShowAlert from "customHooks/useShowAlert";

const { Text, Link } = Typography;

const MobileAccessAlert: React.FC = (): JSX.Element => {
  const { showAlert, setShowAlert } = useShowAlert();

  const handleContinueAnyway = (): void => {
    setCookie(cookieNames.CONTINUE_MOBILE_VIEW, true);
    setShowAlert(false);
  };

  if (!showAlert) return <></>;

  return (
    <section className={styles.container}>
      <div className={styles.content}>
        <span className={styles.indicator}></span>
        <img src={spenmoDesktopImg} alt="Spenmo Logo" id="companyLogo" className="auth-box__logo" />
        <Text className={styles.title}>A better experience on your desktop browser awaits</Text>
        <p className={styles.description}>Viewing the Spenmo dashboard on your mobile device might have issues</p>
        <Button text={"Continue anyway"} className={styles.continueAnywayButton} action={handleContinueAnyway} />
        <p className={styles.downloadAppContent}>
          Our{" "}
          <Link href="https://onelink.to/un3yd4" target="_blank">
            Spenmo Mobile App
          </Link>{" "}
          also provides a host of features for your spend management needs.
        </p>
      </div>
    </section>
  );
};

export default MobileAccessAlert;
