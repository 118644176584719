import * as React from "react";
import { useState } from "react";
import Modal from "Modules/DS/Modal";
import Button from "Modules/button";
import UploadInvoice from "Views/UploadInvoice";
import { CloseButton, Title } from "Modules/DS/Modal/Components";
import { discardEdit } from "assets/img";
import { trackEvent } from "utility/analytics";
import { BILL_EVENTS } from "Views/Bills/events";

import styles from "./EditBillModal.module.scss";
import { EditBillModalProps, BillFormTypes, BillFooterTypes } from "./types.d";
import classNames from "classnames";

const EditBillModal = ({
  refreshList,
  onClose,
  visible = false,
  billID,
  title = "Edit Bill",
  type = BillFormTypes.Edit,
  footerType = BillFooterTypes.Default,
}: EditBillModalProps) => {
  const [discardModalVisible, setDiscardModalVisible] = useState(false);

  return (
    <>
      <Modal
        className={styles.modal}
        visible={visible}
        close={() => {
          setDiscardModalVisible(true);
        }}
      >
        <Title>{title}</Title>
        <CloseButton fixToTop />
        <div className={classNames(styles.formContainer, { [styles.multiPage]: billID?.length > 1 })}>
          <UploadInvoice
            billID={billID}
            type={type}
            footerType={footerType}
            onCloseModal={onClose}
            refreshList={refreshList}
          />
        </div>
      </Modal>

      <Modal
        visible={discardModalVisible}
        close={() => {
          setDiscardModalVisible(false);
        }}
      >
        <Title>Discard Changes?</Title>
        <CloseButton fixToTop />
        <div className={styles.discardModalContainer}>
          <img className={styles.discardModalIcon} src={discardEdit} alt="refresh fx rate" />
          <p className={styles.discardModalText}>
            There are unsaved changes in this bill. Do you want to discard these changes?{" "}
          </p>
          <Button
            className={styles.discardModalButton}
            action={() => {
              setDiscardModalVisible(false);
            }}
          >
            Continue Editing
          </Button>
          <Button
            className={styles.discardModalButton}
            textButton
            action={() => {
              onClose();
              setDiscardModalVisible(false);
              trackEvent(BILL_EVENTS.editPageClosed, { bill_id: billID });
            }}
          >
            Discard Changes
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default EditBillModal;
