import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input, Checkbox, Form } from "antd";
import Modal from "Modules/modal";
import IButton from "Modules/button";
import AccountingSoftware from "../AccountingSoftware";
import LoaderIcon from "Views/State/Loading/LoaderIcon";
import { fetchSmartCSVTemplates } from "Redux/Actions/SmartCSV/templateAction";
import { GetOrgId } from "utility";
import { downloadSmartCSVTemplate } from "Redux/DataCalls/SmartCSV.api";
import useLoading from "utility/useLoading";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import { fetchDataSuccess } from "Redux/Actions/csvAction";
import { SMART_CSV_LIMIT_TRANSACTION, SMART_CSV_EXCLUDE_ACCOUNT_CODE } from "constants/Transaction.constant";
import AccountCodeInput from "../AccountCodeInput";
import { trackEvent } from "utility/analytics";
import {
  messageGenerator,
  thisMonthDateRange,
  reportingServiceMonthDateRange,
} from "Views/Transaction/SmartCSV/v1/utilities";
import useCheckFeatureStatus from "customHooks/featureCheck";
import { SPLIT_NAMES, SPLIT_TREATMENT_TYPES } from "Redux/splitio/constants";

import "./styles.scss";

const ModalSmartCSV = (props) => {
  const {
    email,
    filters,
    setShowToaster,
    showModal,
    setShowModal,
    transactionCount,
    noFilters,
    isConnected,
    count,
    accountingStatus,
    reportingFilters,
  } = props;
  const [showTemplate, setShowTemplate] = useState(0);
  const [selectedTemplate, setSelectedTemplate] = useState({});
  const useReportingServiceFilter =
    useCheckFeatureStatus(SPLIT_NAMES.customCSVWithReportingFilter) === SPLIT_TREATMENT_TYPES.ON;

  const [sentEmail, setSentEmail] = useState(false);
  const [mustSendEmail, setMustSendEmail] = useState(false);
  const isOpen = (id) => showTemplate === id;
  const dispatch = useDispatch();
  const downloadLinkRef = useRef();
  const [form] = Form.useForm();

  const { user, smartCSV } = useSelector((state) => ({
    smartCSV: state.smartCSVTemplateReducer,
    user: state.userInfoReducer?.data,
  }));
  const { loading, data } = smartCSV;
  const [downloadCSV, loadingDownloadCSV] = useLoading(downloadSmartCSVTemplate, (response) => {
    return response?.data?.error?.message || "Something Went Wrong";
  });
  const generator = messageGenerator({
    selectedTemplate,
    noFilters,
    isConnected,
    mustSendEmail,
    sentEmail,
  });

  useEffect(() => {
    dispatch(fetchSmartCSVTemplates());
  }, [dispatch]);

  useEffect(() => {
    if (count) {
      const reachTreshold = count > SMART_CSV_LIMIT_TRANSACTION;
      setMustSendEmail(reachTreshold);
      setSentEmail(reachTreshold);
    }
  }, [count]);

  const notAllowToDownload = () => !selectedTemplate.name || loadingDownloadCSV || transactionCount === 0;

  const onFinish = async (value) => {
    if (notAllowToDownload()) {
      return;
    }

    const payload = {
      search_filters: { ...thisMonthDateRange(), ...filters },
      template_id: selectedTemplate.id,
      organisation_id: GetOrgId(),
      organisation_name: user?.payload?.user?.organisation_name,
      is_send_email: sentEmail,
      account_number: form.getFieldValue("accountCode"),
      email: value.email,
    };

    if (useReportingServiceFilter) {
      delete payload.search_filters;
      payload["reporting_filters"] = { ...reportingServiceMonthDateRange(), ...reportingFilters };
    }

    if (accountingStatus) {
      payload["accounting_filters"] = {
        status: accountingStatus,
      };
    }

    const { data: downloadData } = await downloadCSV(payload);

    localStorage.setItem("accountCode", form.getFieldValue("accountCode") || "");

    if (downloadData.status === HTTP_STATUS_CODE.OK) {
      if (downloadData.payload.download_url) {
        downloadLinkRef.current.href = downloadData.payload.download_url;
        downloadLinkRef.current.click();
      }

      dispatch(
        fetchDataSuccess({
          ...downloadData.payload,
          status_message: downloadData.payload.message,
        })
      );
      setShowToaster(true);
      handleClose();
      trackEvent("Custom CSV Template Downloaded", {
        custom_csv_event_source: selectedTemplate.accountingSoftware,
        custom_csv_event_subsource: selectedTemplate.name,
      });
    }
  };

  const handleClose = () => {
    setShowModal(false);
    setShowTemplate(0);
    setSelectedTemplate({});
    if (!mustSendEmail) {
      setSentEmail(false);
      setMustSendEmail(false);
    }
  };

  const showAccountCodeInput = () => {
    return (
      selectedTemplate.name &&
      !SMART_CSV_EXCLUDE_ACCOUNT_CODE.includes(selectedTemplate.name) &&
      selectedTemplate.accountingSoftware === "Jurnal"
    );
  };

  const arrangeOrder = (list = []) => {
    if (isConnected) {
      const xero = list.find((accountingSoftware) => accountingSoftware.name === "Xero");
      const withoutXero = list.filter((accountingSoftware) => accountingSoftware.name !== "Xero");
      return [xero, ...withoutXero].filter(Boolean);
    }

    return list;
  };

  return (
    <Modal className="modal-export-csv" show={showModal} title="Custom CSV" close={handleClose}>
      <Form form={form} onFinish={onFinish}>
        <div className="modal-export-csv__content">
          <div className="accounting-software">
            <h4 className="header">Export data to CSV template from</h4>

            {loading ? (
              <LoaderIcon />
            ) : (
              <div className="accounting-software__list">
                {Array.isArray(data.payload) &&
                  arrangeOrder(data.payload).map((template) => (
                    <AccountingSoftware
                      key={template.id}
                      template={template}
                      isOpen={isOpen}
                      showTemplate={showTemplate}
                      setShowTemplate={setShowTemplate}
                      isConnected={template.name === "Xero" && isConnected}
                      onChangeTemplate={(value) => setSelectedTemplate({ ...value, accountingSoftware: template.name })}
                    />
                  ))}
              </div>
            )}
          </div>
          <div className="export-guide">
            <div className="export-guide__content">
              <h4 className="header">{generator.generateTextHeader()}</h4>
              <p className="sub_header min-height">{generator.generateSubHeader()}</p>
              {selectedTemplate.guide_url && (
                <a className="guidance" href={selectedTemplate.guide_url} target="__blank">
                  <span>
                    Learn more about importing CSV inside
                    <span className="capitalize"> {selectedTemplate.accountingSoftware}</span>
                  </span>
                </a>
              )}
              {showAccountCodeInput() && <AccountCodeInput accountingSoftware={selectedTemplate.accountingSoftware} />}
              <div className="divider"></div>

              <div className="flex">
                <Checkbox
                  className={"sp-checkbox"}
                  name="checkbox-test"
                  disabled={mustSendEmail}
                  checked={sentEmail}
                  onChange={(e) => {
                    setSentEmail(e.target.checked);
                    if (!e.target.checked) {
                      form.resetFields();
                      form.setFieldsValue({ email });
                    }
                  }}
                />
                <section>
                  <h4 className="header sp-margin-bottom--s">Send export details and download link to email</h4>

                  <p className="sub_header sp-margin-bottom--s">
                    {mustSendEmail
                      ? `The data which contains more than ${SMART_CSV_LIMIT_TRANSACTION} transactions will be sent to email.`
                      : `Download again this exported data by the link on the email.`}
                  </p>
                  <Form.Item
                    name="email"
                    initialValue={email}
                    rules={
                      sentEmail
                        ? [
                            { type: "email", message: "Please enter correct email address" },
                            { required: sentEmail, message: "Please enter an email address" },
                          ]
                        : []
                    }
                  >
                    <Input disabled={!sentEmail} maxLength={100} />
                  </Form.Item>
                </section>
              </div>
              <div className="download-button-wrapper">
                <a ref={downloadLinkRef} className="hide"></a>
                <IButton
                  rounded
                  disabled={notAllowToDownload()}
                  loading={loadingDownloadCSV}
                  className={selectedTemplate.name && !notAllowToDownload() ? "button--download" : ""}
                  id="downloadSmartCSV"
                  type="submit"
                  text={generator.generateButtonText()}
                />
              </div>
            </div>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default ModalSmartCSV;
