import {
  FETCH_PHONE_CODE_REQUEST,
  FETCH_PHONE_CODE_SUCCESS,
  FETCH_PHONE_CODE_ERROR,
} from "../../Actions/OnBoarding/phoneCodeAction";
import { userInitialState } from "../../InitialState";

export const phoneCodeReducer = (state = userInitialState, action) => {
  switch (action.type) {
    case FETCH_PHONE_CODE_REQUEST:
      return {
        ...state,
        loading: true,
        data: action.data,
        error: false,
      };
    case FETCH_PHONE_CODE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: false,
      };
    case FETCH_PHONE_CODE_ERROR:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: true,
      };
    default:
      return state;
  }
};
