import React from "react";
import { Button, Modal } from "@spenmo/splice";
import { DescriptiveText } from "./index";
import styles from "./styles.module.scss";

const DisconnectConfirmationModal = ({
  showModal,
  onClose,
  loading,
  onDisconnect,
  partnerName,
}: {
  showModal: boolean;
  onClose: () => void;
  loading: boolean;
  onDisconnect: () => void;
  partnerName: string;
}) => {
  return (
    <Modal
      showModal={showModal}
      size="m"
      title={`Disconnect ${partnerName}`}
      children={
        <>
          <DescriptiveText
            className={styles.modalBodyText}
            description={`Are you sure you want to disconnect your ${partnerName} account?`}
          />
          <DescriptiveText
            className={styles.modalBodyText}
            description={`All future transactions will stop syncing from Spenmo to ${partnerName}.`}
          />
          <div className={styles.modalFooterButtons}>
            <Button variant="primary" onClick={onClose}>
              Cancel
            </Button>
            <Button loading={loading} variant="danger" onClick={onDisconnect}>
              Disconnect
            </Button>
          </div>
        </>
      }
      onClose={onClose}
    />
  );
};

export default DisconnectConfirmationModal;
