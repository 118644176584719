import * as React from "react";
import {
  useCardDetailsEditContext,
  useSpendingLockEditContext,
  CARD_DETAILS_FIELDS_MAP,
} from "Views/Card/Edit/index.context";

import ChooseSpendingLock from "Views/Card/Components/SpendingLock/Choose";
import { SPENDING_LOCK_TYPES } from "Views/Card/types";

export interface ISpendingLockProps {}

export default function SpendingLock(props: ISpendingLockProps) {
  const { cardDetailsEditState, setCardDetailsEditState } = useCardDetailsEditContext();
  const { spendingLockType, spendingLocks } = cardDetailsEditState;
  const { setSpendingLockEditDetails } = useSpendingLockEditContext();
  const spendingLockNames = spendingLocks?.map((lock) => lock?.name) || [];

  const onRemove = () => {
    setCardDetailsEditState((prevState) => ({
      ...prevState,
      [CARD_DETAILS_FIELDS_MAP.SPENDING_LOCK_TYPE]: SPENDING_LOCK_TYPES.NONE,
      [CARD_DETAILS_FIELDS_MAP.SPENDING_LOCKS]: [],
    }));
    setSpendingLockEditDetails(null);
  };

  return (
    <ChooseSpendingLock
      cardActionType="Edit"
      onSpendingLockTypeClick={(selectedLockType: SPENDING_LOCK_TYPES) =>
        setSpendingLockEditDetails({
          showDrawer: true,
          selectedSpendingLockType: selectedLockType,
        })
      }
      onRemove={onRemove}
      spendingLockType={spendingLockType}
      spendingLocks={spendingLockNames}
    />
  );
}
