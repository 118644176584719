import React, { useState } from "react";
import classNames from "classnames";
import Button from "Modules/button";
import { ISidePanelButton } from "../../type.d";
import Icon from "Modules/icons";
import styles from "./styles.module.scss";
import { greyCloseIcon } from "assets/img/index";
import { WARNING_TEXT_PAY } from "Views/Approval/ApprovalCenter/types";
import Banner from "Modules/DS/Banner";

export const PrimaryEnableButton = ({
  action,
  text,
  disabled,
  loading,
  shouldUseColSpan,
  enableConfirmCallback,
  disabledConfirmCallback,
  hiddenButtonBeforeConfirm,
}: ISidePanelButton) => {
  const [confirmAction, setConfirmAction] = useState(false);
  const enableConfirm = () => {
    setConfirmAction(true);
    if (enableConfirmCallback) enableConfirmCallback();
  };

  const disableConfirm = () => {
    setConfirmAction(false);
    if (disabledConfirmCallback) disabledConfirmCallback();
  };

  if (confirmAction) {
    return (
      <>
        <div className={classNames([styles["col-span-2"]])}>
          <Banner key="warning-button" message={WARNING_TEXT_PAY.CONFIRM_PAY_WARNING} type="info" />
        </div>
        <div className={classNames([styles["confirm-approve"], styles["col-span-2"]])}>
          <Button
            size="l"
            rounded
            disabled={disabled || loading}
            className={classNames([styles["confirm-approve__button"]])}
            action={action}
          >
            {WARNING_TEXT_PAY.CONFIRM_PAY_BUTTON}
          </Button>
          <Icon
            alt="close-confirm-button-icon"
            src={greyCloseIcon}
            size="2"
            action={(e) => {
              disableConfirm();
            }}
          />
        </div>
      </>
    );
  }
  return (
    <Button
      size="l"
      rounded
      disabled={disabled || loading}
      className={classNames(
        { [styles["col-span-2"]]: shouldUseColSpan },
        { [styles.hidden]: hiddenButtonBeforeConfirm }
      )}
      action={enableConfirm}
    >
      {text}
    </Button>
  );
};
