import React from "react";

import { ILineIconProps } from "Modules/DS/Menu/@types";

import { SquareSkeleton } from "./Skeleton";

import styles from "./Icon.module.scss";

export const LineIcon = ({ id, src, loading, alt = "icon" }: ILineIconProps) => {
  return (
    <SquareSkeleton loading={loading}>
      <img id={id} src={src} alt={alt} className={styles.image} />
    </SquareSkeleton>
  );
};
