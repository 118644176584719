import { initialVerifyForgotOTPState } from "Redux/Actions/Auth/VerifyForgotOTP/types";
import { MY_INFO_CALLBACK_TYPES } from "Redux/Actions/KYX/MyInfoCallBack";
import { IMyInfoCallBackData, IMyInfoCallBackReducer } from "Redux/Actions/KYX/types";

export const myInfoCallBackReducer = (
  state: any = initialVerifyForgotOTPState,
  action: { type: MY_INFO_CALLBACK_TYPES; data: IMyInfoCallBackData }
): IMyInfoCallBackReducer => {
  switch (action.type) {
    case MY_INFO_CALLBACK_TYPES.REQUEST:
      return {
        ...state,
        loading: true,
        isSuccess: false,
        error: false,
      };
    case MY_INFO_CALLBACK_TYPES.SUCCESS:
      return {
        ...state,
        loading: false,
        isSuccess: true,
        data: action.data,
      };
    case MY_INFO_CALLBACK_TYPES.ERROR:
      return {
        ...state,
        loading: false,
        data: action.data,
        isSuccess: false,
        error: true,
      };
    default:
      return state;
  }
};
