//global scss & css
import "@spenmo/splice/lib/style.css";
import "./assets/scss/app.scss";
import "./assets/css/reset.css";
import "./global.scss";

import React from "react";
import { Router } from "Route";

import { FallbackScreen } from "Modules/FallbackScreen";

// Sentry
import * as Sentry from "@sentry/react";
import { initSentry } from "utility/Sentry";

// Clarity
import { useClarity } from "customHooks/useClarity";

initSentry(); //Initialisation

const App = () => {
  // initialisation for Microsoft Clarity Analytics
  useClarity();

  return (
    <Sentry.ErrorBoundary fallback={<FallbackScreen />}>
      <Router />
    </Sentry.ErrorBoundary>
  );
};
export default App;
