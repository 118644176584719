import React from "react";
import { Link } from "react-router-dom";
import { Typography, WarningOutline } from "@spenmo/splice";
import classNames from "classnames";
import styles from "./index.module.scss";

export interface ReKYBBannerProps {
  show: boolean;
  text?: string;
  openInNewTab?: boolean;
  link?: {
    label: string;
    link: string;
  };
}

const ReKYBBanner = ({ text, link, show, openInNewTab = false }: ReKYBBannerProps): React.ReactElement => {
  if (!show) return null;

  const linkLabel = (
    <Typography variant="body-content" size="m">
      <u>{link.label}</u>
    </Typography>
  );

  return (
    <section className={classNames(styles.reKYBBannerContainer, styles.gridRow)} data-testid="re-KYB-banner">
      <WarningOutline iconColor="var(--icon-default)" size="24" />
      <Typography variant="body-content" size="m">
        {text}
      </Typography>

      {openInNewTab ? (
        <a href={link.link} data-testid="link-element" target="_blank" rel="noreferrer noopener">
          {linkLabel}
        </a>
      ) : (
        <Link to={link.link} target="_blank" data-testid="link-element">
          {linkLabel}
        </Link>
      )}
    </section>
  );
};

export default ReKYBBanner;
