import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import PolicyForm from "./PolicyForm/PolicyForm";
import LoaderIcon from "Views/State/Loading/LoaderIcon";
import ErrorComponent from "Views/State//Error";
import "./policy.scss";

const TeamPolicy = (props) => {
  const { teamDetails, handleTabChange } = props;
  const { loading, addloading, error, adderror } = useSelector((state) => state?.teamPolicyReducer);

  if (loading || addloading) {
    return <LoaderIcon />;
  }

  if (error || adderror) {
    return <ErrorComponent />;
  }

  return (
    <div className="team-profile">
      <div className="team-profile__title-area">
        <PolicyForm teamDetails={teamDetails} handleTabChange={handleTabChange} />
      </div>
    </div>
  );
};

TeamPolicy.propTypes = {
  teamDetails: PropTypes.any,
  handleTabChange: PropTypes.func,
};
export default TeamPolicy;
