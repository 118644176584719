import React, { useContext } from "react";
import { TagsDropdownContext } from "Views/Card/CreateOrRequest/Steps/Policy/TagsDropdown/context";
import "./index.scss";
import { leftArrowIcon24x24 } from "assets/icons/card";
import { ITagGroup } from "Views/Card/types";
import classNames from "classnames";

const TagGroup = () => {
  const { setOpenSubMenu, data, setSelectedTag, selectedTag } = useContext(TagsDropdownContext);

  const hasSelectedChildren = (tagGroup) => {
    return !!tagGroup.selectedChildren;
  };

  return (
    <div>
      <div className="tag-group">
        <p className="tag-group__info">Select Tag Group</p>
        <ul className="tag-group__list">
          {data?.map((tagGroup: ITagGroup) => (
            <li className={`${hasSelectedChildren(tagGroup) ? "selected" : ""}`} key={tagGroup.id}>
              <button
                onClick={(event) => {
                  setOpenSubMenu(true);
                  setSelectedTag(tagGroup.id);
                  event.stopPropagation();
                  event.preventDefault();
                }}
                className={classNames([
                  "tag-group__list__button",
                  selectedTag === tagGroup.id && "tag-group__list__button--active",
                ])}
              >
                <span>{tagGroup.label}</span>
                <img src={leftArrowIcon24x24} alt="left arrow" className="tag-group__list__icon" />
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default TagGroup;
