import React from "react";
import { notification } from "antd";
import { WarningFilled } from "@ant-design/icons";

import styles from "./index.module.scss";

const closeIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" width={16} height={16}>
    <path
      fill="#DD1C0B"
      fill-rule="evenodd"
      d="M.293 1.707A1 1 0 0 1 1.707.293l6.15 6.15 6.15-6.15a1 1 0 1 1 1.414 1.414l-6.15 6.15 6.15 6.15a1 1 0 0 1-1.414 1.414l-6.15-6.15-6.15 6.15a1 1 0 0 1-1.414-1.414l6.15-6.15-6.15-6.15Z"
      clip-rule="evenodd"
    />
  </svg>
);

const SwitchAccountErrorMessage = (message: string) => {
  notification.open({
    message: <span className={styles.switchAccountErrorMessageTitle}>{message}</span>,
    icon: <WarningFilled style={{ color: "#dd1c0b" }} />,
    placement: "bottomLeft",
    className: styles.switchAccountErrorMessage,
    closeIcon,
  });
};

export default SwitchAccountErrorMessage;
