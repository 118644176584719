import "../topup.scss";

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { getCurrencyFunc, topupActionFunc } from "../../../Redux/Actions";
import { Form } from "antd";
import SelectAmount from "../../../Modules/selectAmount";
import Button from "../../../Modules/button";
import { CURRENCY_CODE } from "../../../constants/Currency.constant";
import LoaderIcon from "../../State/Loading/LoaderIcon";
import { resetTopupData } from "../../../Redux/Actions/topupAction";
import { trackEvent } from "utility/analytics";

const TopUpCreditCard = ({ currencyCode, trackTopupNow }) => {
  const PAYMENT_TYPE = "all";
  const { paymentType, data: topupData, error, loading } = useSelector((state) => state.topupReducer);
  const { data: currencyData, loading: isCurrencyLoading } = useSelector((state) => state.currencyReducer);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [currency, setCurrency] = useState(currencyCode);
  const responseData = paymentType === PAYMENT_TYPE ? topupData : null;
  const redirectionURL = responseData?.payload?.payment?.redirection_url;
  const isIDR = currency === CURRENCY_CODE.IDR;
  const allCurrencies = currencyData?.payload?.currencies || [];

  useEffect(() => {
    if (allCurrencies.length === 0) {
      dispatch(getCurrencyFunc());
    }
  }, []);

  /**
   * Get the default currency code to select on the UI based on the dashboard's default currency
   * code.
   *
   * @param {String} dbDefaultCurrency
   * @returns {String}
   */
  const getInitialCurrency = (dbDefaultCurrency) => {
    switch (dbDefaultCurrency.toUpperCase()) {
      case "IDR":
      case "SGD":
      case "USD":
        return dbDefaultCurrency;

      default:
        return "SGD";
    }
  };

  useEffect(() => {
    if (redirectionURL) {
      window.location.assign(redirectionURL);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redirectionURL]);

  useEffect(() => {
    return () => {
      dispatch(resetTopupData());
    };
  }, [dispatch]);

  const onFinish = (values) => {
    const { amount } = values;
    trackTopupNow(amount); // to track top up amount and method along with currency
    const formData = {
      currency,
      type: PAYMENT_TYPE,
      ...values,
    };

    dispatch(topupActionFunc(formData));
  };

  const handleAmount = (value) => {
    form.setFieldsValue({ amount: value });
  };

  if (isCurrencyLoading) return <LoaderIcon />;

  return (
    <>
      <div className={"sp-margin-bottom--l"}>Enter your amount</div>
      <div className={"sp-margin-bottom--xl"}>
        <Form form={form} onFinish={onFinish}>
          <SelectAmount
            alternate
            currencyAction={(value) => {
              setCurrency(value);
              const params = {
                currencyCode: value,
              };
              trackEvent("Top up currency changed", params);
            }}
            initialCurrency={getInitialCurrency(currencyCode)}
            action={handleAmount}
            className={"sp-margin-bottom--xl"}
            rules={[
              { required: true, message: "Please enter the Amount!" },
              ({ getFieldValue }) => ({
                validator() {
                  if (isIDR && getFieldValue("amount") < 10001) {
                    return Promise.reject("Minimum amount is IDR 10.001");
                  } else if (getFieldValue("amount") < 1) {
                    return Promise.reject("Minimum amount is 1");
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          />

          <Form.Item>
            <Button
              type={"submit"}
              rounded
              size={"l"}
              loading={loading}
              text={redirectionURL ? "Redirecting..." : "Top up now"}
              disabled={redirectionURL}
            />
          </Form.Item>
        </Form>
        {redirectionURL && (
          <div className={"text--secondary"}>
            If you are not redirected, click &nbsp;
            <a href={redirectionURL}>here</a>.
          </div>
        )}
        {paymentType === PAYMENT_TYPE && error && <div className={"text--danger"}>{error}</div>}
      </div>

      {currencyCode === "USD" ? (
        <>
          <div className={"text--secondary sp-margin-top--xl"}>
            Your top up will be reflected instantly after Spenmo receives the fund. It may take longer depending on how
            you make the transfer.
          </div>

          <div className={"text--secondary sp-margin-top--xl"}>
            If it&apos;s taking too long, you can contact our customer service.
          </div>
        </>
      ) : null}
    </>
  );
};

TopUpCreditCard.propTypes = {
  currencyCode: PropTypes.string,
  trackTopupNow: PropTypes.func,
};

export default TopUpCreditCard;
