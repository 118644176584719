import React from "react";
import { IFieldRemark, IGetFormConfigsPayload } from "Views/KYX/@types";
import { REMARKS_SIDEBAR_TYPE, REMARKS_VALIDATION_TYPES } from "Views/KYX/Constants/Remarks";
import RemarksButton from "Views/KYX/Remarks/Button";

type TFieldName = string | (string | number)[];

export const getFieldError = (fieldRemarks: IFieldRemark): string => {
  return fieldRemarks?.reason;
};

export const getFieldReason = (remarks: IGetFormConfigsPayload, name: TFieldName): IFieldRemark => {
  const keyArray = Array.isArray(name) ? name : [name];
  const [firstKey, ...restKeys] = keyArray;
  const currentObj = remarks?.[firstKey];

  if (!currentObj) {
    return undefined;
  }

  if (restKeys.length === 0) {
    return currentObj?.field_remark;
  }

  return getFieldReason(currentObj, restKeys);
};

export const filterUpdatedRemark = (remarks: any, name: TFieldName, index: number = 0): object => {
  try {
    if (index >= name.length) {
      return remarks;
    }
    if (index === name.length - 1 && remarks.hasOwnProperty(name[index])) {
      delete remarks[name[index]];
      return remarks;
    }
    if (remarks.hasOwnProperty(name[index])) {
      filterUpdatedRemark(remarks[name[index]], name, index + 1);
    }
    return remarks;
  } catch (error) {
    return remarks;
  }
};

export const getRemarksPersonIds = (remarks: any) => {
  return remarks?.persons?.map((person) => person?.id);
};

export const getValidationstatus = (remarks: IGetFormConfigsPayload, fieldName: TFieldName): "success" | "error" => {
  const fieldRemarks = getFieldReason(remarks, fieldName);

  if (fieldRemarks && !fieldRemarks.is_approved) {
    return REMARKS_VALIDATION_TYPES.ERROR;
  }
  return undefined;
};

export const getValidationError = (
  remarks: any,
  fieldName: TFieldName,
  label: string,
  formName: string
): JSX.Element => {
  const fieldRemarks = getFieldReason(remarks, fieldName);
  const data = {
    title: label,
    form_name: formName,
    reason: fieldRemarks?.reason,
    general_remark: remarks?.general_remark,
  };

  if (fieldRemarks && !fieldRemarks.is_approved) {
    return (
      <>
        {fieldRemarks?.reason}
        <RemarksButton data={data} type={REMARKS_SIDEBAR_TYPE.FIELD} />
      </>
    );
  }
  return null;
};
