import React, { useContext } from "react";
import { Col, Row } from "Splice/Grid";
import { IKYXModalContext, IResumeIntro } from "Views/KYX/@types";
import { ResumeIntroHeader as Header } from "Views/KYX/Intro/Resume/Header";
import { ResumeManual, ResumeVerification } from "Views/KYX/Intro/Resume/Button";

import {
  KYB_METHOD_LIST,
  KYX_MODAL_TYPES,
  RESUME_INTRO_PROGRESS_OVERVIEW_LAST_STEP_PROXY_VALUE,
} from "Views/KYX/Constants";
import { KYXModalContext } from "Views/KYX/Context";
import { Overview } from "../Manual/Overview";
import { MY_INFO_LOGO_DETAILS } from "Views/KYX/Constants/Documents/SGD";

import styles from "../Manual/index.module.scss";

export const AutomatedSource = ({ companyName }: IResumeIntro): JSX.Element => {
  const { setModal }: IKYXModalContext = useContext(KYXModalContext);

  const showResumeManualModal = (): void => {
    setModal({
      visible: true,
      type: KYX_MODAL_TYPES.CHANGE_KYB_METHOD,
      payload: { methodType: KYB_METHOD_LIST.MANUAL },
    });
  };

  return (
    <Row className={styles.welcomeBack} align="end" justify="start">
      <Col className={styles.header} span={24}>
        <Header companyName={companyName} />
        <Overview lastStep={RESUME_INTRO_PROGRESS_OVERVIEW_LAST_STEP_PROXY_VALUE} details={MY_INFO_LOGO_DETAILS} />
      </Col>
      <Col className={styles.actionBtns} span={24}>
        <ResumeVerification />
        <ResumeManual action={showResumeManualModal} />
      </Col>
    </Row>
  );
};
