import { FieldErrors } from "react-hook-form";
import { ICreateForm } from "Views/SubscriptionManagement/@types";
import { FORM_NAMES } from "Views/SubscriptionManagement/Constants";
import { IPayment } from "Views/SubscriptionManagement/Create/Context";

export const formFieldHasRequiredError = (errors: FieldErrors<ICreateForm>, fieldName: FORM_NAMES) =>
  errors?.[fieldName]?.type === "required";

export const getPaymentPayload = (paymentData: IPayment, subscriptionData: ICreateForm) => {
  const { owner_name, payment_limit } = subscriptionData;
  const { name, card_id, expense_category_id, monthly_limit, total_limit, yearly_limit } = paymentData.cardData;
  const cardData = {
    ...paymentData.cardData,
    cardholder_name: owner_name,
    manager_sets_limit: 0,
    card_name: name,
    expense_category_id: expense_category_id ? String(expense_category_id) : "",
    monthly_limit: Number(monthly_limit),
    yearly_limit: Number(yearly_limit),
    total_limit: Number(total_limit),
  };
  const payment_limit_amount = payment_limit ? Number(payment_limit) : 0;
  delete cardData.card_id;
  if (card_id) {
    return {
      card_id,
      /** Change this when updation of card is supported on api */
      // updated_card_settings: cardData,
      payment_limit_amount,
    };
  }
  return {
    card_id: "",
    new_card: cardData,
    payment_limit_amount,
  };
};
