import React from "react";
import useCheckOrgConfigs from "customHooks/useCheckOrgConfigs";

export const NetsuiteIntegration = React.createContext({
  integrationEnabled: false,
  setIntegrationEnabled: (_: boolean) => {},
});

export const useNetsuiteIntegration = () => {
  const [isNetsuiteEnabled, loading] = useCheckOrgConfigs("netsuite_integration", true);

  return {
    integrationEnabled: isNetsuiteEnabled,
    loading,
  };
};
