/**
 * @fileoverview MenuItem component is a forward-ref wrapper over the generic
 * Navigation.Menu component from the "@spenmo/splice" library. It provides enhanced
 * functionality for navigation and accepts a history prop to manage routing.
 *
 * @author Sushmitha, Bakshi
 * @created 25 Aug 2023
 * @lastModified 30 Aug 2023
 */
import React, { forwardRef } from "react";
import { Navigation } from "@spenmo/splice";
import { useHistory, useLocation } from "react-router-dom";

import { trackEvent } from "utility/analytics";

import { IMenuItem, TNavMenu } from "Views/Navigation/@types";
import { NAVIGATION_EVENTS } from "Views/Navigation/Constants";

/**
 * `MenuItem` is a functional component that wraps around the generic Navigation.Menu component
 * and provides additional functionality related to routing using react-router's useHistory hook.
 * This component uses forwardRef to pass down any refs directly to the Navigation.Menu component.
 *
 * @param {IMenuItem} props - The properties that this component accepts.
 * @param {React.Ref<HTMLButtonElement>} ref - Ref to the underlying button element of the Navigation.Menu component.
 * @returns {React.ReactElement} - A React element that represents a menu item with integrated routing capabilities.
 */
export const MenuItem: React.ForwardRefExoticComponent<IMenuItem & React.RefAttributes<HTMLButtonElement>> = forwardRef(
  ({ menu, history = "", analytics }: IMenuItem, ref: React.Ref<HTMLButtonElement>): React.ReactElement => {
    // Instantiating react-router's useHistory hook.
    const h = useHistory();
    // Get the current location using react-router's useLocation hook
    const location = useLocation();
    const { prefixActiveIcon, ...menuProps } = menu;

    /**
     * Handles navigation based on the provided history prop.
     * Navigates to the route only if a valid path is provided.
     */
    const pushToRoute = () => {
      return (typeof history === "string" ? history.length > 0 : history.pathname.length > 0) && h.push(history);
    };

    /**
     * Handles the analytics tracking if provided.
     */
    const handleAnalytics = (): void => {
      trackEvent(NAVIGATION_EVENTS.SIDEBAR_MENU_CLICKED, {
        location: "sidebar",
        type: "sidebar element",
        ...(analytics.data && { ...analytics.data }),
      });
    };

    // Get the currently selected route from the location object
    const path = typeof history === "string" ? history : history.pathname;

    // Determine whether the current menu item is selected based on the route
    const selectedRoute =
      menu.label === "Home"
        ? path === location?.pathname // For the "Home" menu item, compare the exact route.
        : location?.pathname.includes(path); // For other menu items, check if the route contains the path.

    // Determine the final selection state for the menu item.
    const isMenuSelected = history ? selectedRoute : menu.isSelected;

    // Constructing the final props for the Navigation.Menu component.
    const props: TNavMenu = {
      ...menuProps,
      onClick: (e) => {
        pushToRoute();
        menu?.onClick && menu?.onClick(e);
        analytics?.enabled && handleAnalytics();
      },
    };

    return (
      <Navigation.Menu
        {...props}
        ref={ref}
        prefixIcon={isMenuSelected ? menu.prefixActiveIcon : menu.prefixIcon}
        isSelected={isMenuSelected}
      />
    );
  }
);
