import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Slider from "react-slick";
import { rightArrowDark, left_arrow } from "assets/img";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "Views/Home/Components/CustomCarousel/CustomCarousel.scss";

const arrowTypes = {
  PREVIOUS: "previous",
  NEXT: "next",
};

const CarosuelArrows = ({ type, onClick }) => {
  const imageSrc = type === arrowTypes.PREVIOUS ? left_arrow : rightArrowDark;
  return (
    <div className={`custom-carousel__arrows custom-carousel__arrows--${type}`} onClick={onClick}>
      <img width={8} height={14} src={imageSrc} alt={`${type} arrow`} />
    </div>
  );
};

const CustomCarousel = ({
  data = [],
  onChange = () => {
    /* do nothing*/
  },
  slidesToShow = 2,
  slidesToScroll = 2,
}) => {
  const [highestPageTraversed, setHighestPageTraversed] = useState(0);
  const sliderRef = useRef(null);
  const [slidesToShowLocal, setSlidesToShow] = useState(slidesToShow);
  const [slidesToScrollLocal, setslidesToScroll] = useState(slidesToScroll);

  const OnAfterChange = (index) => {
    if (index > highestPageTraversed) {
      onChange();
      setHighestPageTraversed(index);
    }
  };

  useEffect(() => {
    const innerSlider = sliderRef.current.innerSlider;
    const slideTrack = innerSlider.track.node;
    const children = slideTrack.children;
    let childrenWidth = 0;
    const leftMarginOfSlides = slideTrack.offsetLeft;
    const frameWidth = innerSlider.list.offsetWidth - leftMarginOfSlides;
    let maxSlidePerFrame = 0;

    for (const child of children) {
      childrenWidth += child.offsetWidth;
      if (childrenWidth > frameWidth) {
        maxSlidePerFrame = 1;
        break;
      }
      maxSlidePerFrame++;
    }

    setSlidesToShow(maxSlidePerFrame);
    setslidesToScroll(maxSlidePerFrame);
  }, [slidesToShowLocal]);

  return (
    <div className="custom-carousel__container">
      {data?.length > 0 && (
        <Slider
          ref={sliderRef}
          className={"custom-carousel"}
          variableWidth
          slidesToShow={slidesToShowLocal}
          slidesToScroll={slidesToScrollLocal}
          infinite={false}
          nextArrow={<CarosuelArrows type={arrowTypes.NEXT} />}
          prevArrow={<CarosuelArrows type={arrowTypes.PREVIOUS} />}
          dots
          arrows
          // draggable
          afterChange={OnAfterChange}
          vertical={false}
        >
          {data.map((carouselItem) => carouselItem)}
        </Slider>
      )}
    </div>
  );
};

CustomCarousel.propTypes = {
  data: PropTypes.array,
  slidesToShow: PropTypes.number,
  slidesToScroll: PropTypes.number,
  onChange: PropTypes.func,
};

export default CustomCarousel;
