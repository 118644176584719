import { useEffect, useMemo, useState } from "react";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import { IActionArr, IResponse } from "./types.d";
import { useMutableData } from "API/useData";
import qs from "query-string";
import useIsMounted from "customHooks/useIsMounted";

function usePermissionCheck(actionArr: IActionArr[], loader: boolean = false) {
  const [loading, setLoading] = useState(true);
  const isMountedRef = useIsMounted();

  const formatter = (res: IResponse[]) => {
    if (res.length > 1) {
      return res.map((item) => item?.is_authorized || false);
    }
    return res[0]?.is_authorized || false;
  };

  const casbinPermissionUrl = qs.stringifyUrl({
    url: `/ms/b2b-auth/v1/casbin/validate-permissions`,
    query: {
      permissions: JSON.stringify(actionArr),
    },
  });

  const {
    data: response,
    error,
    isLoading,
  } = useMutableData(casbinPermissionUrl);

  /**
   * todo: Refactor to use isLoading directly, does not seem to work correctly as the default value required to be true
   * when getting rid of this, try to validate the Approval feature once as I encountered that issue on Approvals
   */

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  const allowed = useMemo(() => {
    if (!error && response && isMountedRef.current) {
      if (response.status === HTTP_STATUS_CODE.OK) {
        return formatter(response.data.data as IResponse[]);
      } else {
        console.error(`Error ${response.status} ${response.statusText}`);
      }
    } else if (error) {
      console.error(`Error ${error}`);
    }
    return false;
  }, [response, error, isMountedRef]);

  return loader ? [allowed, loading] : allowed;
}

export default usePermissionCheck;
