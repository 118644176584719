import * as React from "react";
import { getCookieValue, cookieNames } from "utility/CookieHelper";
import { CURRENCY_CODE } from "constants/Currency.constant";
import { MMPH_FAQS, UOB_FAQS } from "Views/Card/Faqs/Faqs";
import ServiceAdvisory from "Views/Card/Faqs/ServiceAdvisory";

import styles from "./index.module.scss";

const CardsFaqs = (): JSX.Element => {
  const orgCurrency = getCookieValue(cookieNames.CURRENCY_CODE);
  const isOrgCurrencyPHP = orgCurrency === CURRENCY_CODE.PHP;
  const faqs = isOrgCurrencyPHP ? MMPH_FAQS : UOB_FAQS;
  return (
    <div className={styles.container}>
      <ServiceAdvisory isOrgCurrencyPHP={isOrgCurrencyPHP} />
      <div className={styles.title}>Frequently Asked Questions</div>
      <div className={styles.faqsContainer}>
        {faqs.map(({ question, answer }) => (
          <div className={styles.faq}>
            <p className={styles.question}>{question}</p>
            <p className={styles.answer}>{answer}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CardsFaqs;
