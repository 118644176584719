import {
  FETCH_REIMBURSE_REQUEST,
  FETCH_REIMBURSE_SUCCESS,
  FETCH_REIMBURSE_FAILURE,
  FETCH_ADD_REQUEST,
  FETCH_ADD_SUCCESS,
  FETCH_ADD_FAILURE,
  FETCH_ADD_CLEAR,
} from "../Actions/reimburseAction";
import { userInitialState } from "../InitialState";

export const reimburseReducer = (state = userInitialState, action) => {
  switch (action.type) {
    case FETCH_REIMBURSE_REQUEST:
      return {
        ...state,
        data: action.data,
        loading: true,
      };
    case FETCH_REIMBURSE_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
      };
    case FETCH_REIMBURSE_FAILURE:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: true,
      };
    case FETCH_ADD_REQUEST:
      return {
        ...state,
        data: action.data,
        loading: true,
      };
    case FETCH_ADD_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
      };
    case FETCH_ADD_FAILURE:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: true,
      };
    case FETCH_ADD_CLEAR:
      return {
        ...state,
        loading: false,
        data: {},
        error: false,
      };
    default:
      return state;
  }
};
