import APIclient from "API/Client";
import { GetOrgId } from "../../utility";

export const customFileUpload = (file, reqURL, isPreview = false, type = "postData") => {
  const orgId = GetOrgId();
  let formData = new FormData();
  formData.append("file", file);
  formData.append("organisation_id", orgId);

  return APIclient[type](reqURL, !isPreview && formData)
    .then((resp) => {
      return resp;
    })
    .catch((err) => {
      return err?.response;
    });
};
