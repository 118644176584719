import React from "react";

import { Tooltip, TooltipArrowAlignmentEnum, TooltipPositionEnum } from "Modules/DS/Tooltip";

export const wrapTooltip = (
  text: string,
  htmlEl: keyof JSX.IntrinsicElements,
  charCount: number = 15,
  props = null
) => {
  return text.length > charCount ? (
    <Tooltip text={text} position={TooltipPositionEnum.RIGHT} alignArrow={TooltipArrowAlignmentEnum.CENTER}>
      {React.createElement(htmlEl, props, text)}
    </Tooltip>
  ) : (
    React.createElement(htmlEl, props, text)
  );
};
