import { createContext } from "react";

import { ITrxnPaginationContext } from "Views/Transaction/@types";
import { INITIAL_TRXNS_PAGINATION } from "Views/Transaction/Constants";

export const initialTrxnPagination: ITrxnPaginationContext = {
  currentPage: INITIAL_TRXNS_PAGINATION,
  setCurrentPage: () => null,
  resetCurrentPage: () => null,
};

export const TrxnPaginationContext = createContext<ITrxnPaginationContext>(initialTrxnPagination);
