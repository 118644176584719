import {
  FETCH_ORG_DETAIL_REQUEST,
  FETCH_ORG_DETAIL_SUCCESS,
  FETCH_ORG_DETAIL_ERROR,
} from "../Actions/b2bOrgDetailAction";
import { userInitialState } from "../InitialState";

export const b2bOrgDetailReducer = (state = userInitialState, action) => {
  switch (action.type) {
    case FETCH_ORG_DETAIL_REQUEST:
      return {
        ...state,
        loading: true,
        data: action.data,
        error: false,
      };
    case FETCH_ORG_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: false,
      };
    case FETCH_ORG_DETAIL_ERROR:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: true,
      };
    default:
      return state;
  }
};
