import {
  FETCH_TEAM_MEMBERS_REQUEST,
  FETCH_TEAM_MEMBERS_SUCCESS,
  FETCH_TEAM_MEMBERS_FAILURE,
  CLEAR_TEAM_MEMBERS_DATA,
} from "Redux/Actions/Teams/teamMembersByTeamIdAction";
import { userInitialState } from "Redux/InitialState";

export const teamMembersByTeamIdReducer = (
  state = {
    ...userInitialState,
    selectedTeamId: null,
  },
  action
) => {
  switch (action.type) {
    case FETCH_TEAM_MEMBERS_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case FETCH_TEAM_MEMBERS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: false,
      };
    case FETCH_TEAM_MEMBERS_FAILURE:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: true,
      };
    case CLEAR_TEAM_MEMBERS_DATA:
    default:
      return state;
  }
};
