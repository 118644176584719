import { useState } from "react";

import { ITrxnModalState } from "Views/Transaction/@types";
import { TRXN_MODAL_TYPES } from "Views/Transaction/Constants";

export const useModal = (): [
  ITrxnModalState,
  React.Dispatch<React.SetStateAction<ITrxnModalState>>,
  () => void,
  (type: TRXN_MODAL_TYPES) => void
] => {
  const initalModalState: ITrxnModalState = {
    visible: false,
    type: TRXN_MODAL_TYPES.NONE,
    payload: {},
  };

  const [showModal, setShowModal] = useState<ITrxnModalState>({ ...initalModalState });

  const resetModal = () => setShowModal(initalModalState);

  const toggleModal = (type: TRXN_MODAL_TYPES, payload?: object): void =>
    setShowModal({ visible: true, type: type, payload: payload || initalModalState.payload });

  return [showModal, setShowModal, resetModal, toggleModal];
};
