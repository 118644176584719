import React, { useEffect, useMemo, useState } from "react";
import { Button, Typography } from "@spenmo/splice";
import { useFormContext } from "react-hook-form";
import useSWRMutation from "swr/mutation";
import { Document, Page } from "react-pdf";
import { useParams } from "react-router-dom";

import { postData } from "API/Client";

import { NiumDisclaimer } from "Modules/NiumDisclaimer";

import Preview from "Views/Bills/V2/components/Preview";
import { useBillForm } from "Views/Bills/V2/context/FormContext";
import FormFooter from "Views/Bills/V2/BillForm/FormFooter";

import useIsBulkView from "Views/Bills/V2/hooks/useIsBulkView";

import { isPDF } from "Views/Bills/V2/utilities";
import { API_URL, BillParams } from "Views/Bills/V2/constants";
import { getRequestPayload } from "Views/Bills/V2/BillForm/helper";
import { generatePreviewFields } from "./helper";

import { GetOrgId, GetUserId } from "utility";
import { trackEvent } from "utility/analytics";
import { BILL_EVENTS, BILL_SUBMISSION_TYPES } from "Views/Bills/events";

import { uploadInvoice } from "assets/img";
import {
  BillFlowEnum,
  BillFormProviderProps,
  BillFormStepProps,
  BillPreviewDataResponse,
} from "Views/Bills/V2/BillForm/type";
import styles from "./BillPreview.module.scss";

interface ErrorUploadProps {
  name: string;
  width?: number;
  height?: number;
}

const FilePlaceholder = (props: ErrorUploadProps) => {
  const { name, width = 32, height = 32 } = props;

  return (
    <img
      className={styles.thumbnail}
      src={uploadInvoice}
      alt={name}
      width={width}
      height={height}
    />
  );
};

const BillPreview: React.FC<BillFormStepProps> = (props) => {
  const { onBack, onNext, billFlow } = props;
  const params = useParams<BillParams>();

  const { handleSaveDraft, isSubmitLoading } =
    useBillForm<BillFormProviderProps>();
  const [isExpand, setIsExpand] = useState(false);
  const isBulkView = useIsBulkView();

  const { trigger, data } = useSWRMutation(
    API_URL.previewBill,
    (url, { arg }) =>
      postData(url, arg, false, {
        headers: {
          "X-Organization-Id": GetOrgId(),
          "X-User-Id": GetUserId(),
          "X-Is-Opsy": false,
        },
      }),
  );

  const {
    watch,
    getValues,
    formState: { defaultValues },
  } = useFormContext();
  const { billNumber = null } = defaultValues;

  // Get attachments arrays
  const additionalAttachments = watch("additionalAttachments");
  const uploadedAttachments = watch("uploadedAttachments", []);
  const deletedAttachmentIDs = watch("deletedAttachmentIDs", []);
  const filteredUploadedAttachments = uploadedAttachments?.filter(
    (item) => !deletedAttachmentIDs?.includes(item?.id),
  );

  useEffect(() => {
    if (
      billFlow === BillFlowEnum.NEW_BILL ||
      billFlow === BillFlowEnum.EDIT_DRAFT
    ) {
      trackEvent("Bill Creation Preview Loaded", {
        bill_flow_type: billFlow,
      });
    } else {
      trackEvent(BILL_EVENTS.previewPageLoaded, {
        bill_id: params.id,
        bill_flow_type: billFlow,
        bill_submission_type: isBulkView
          ? BILL_SUBMISSION_TYPES.bulk
          : BILL_SUBMISSION_TYPES.single,
      });
    }
  }, []);

  useEffect(() => {
    const formDataPayload: any = getRequestPayload(getValues());
    if (params?.id) {
      formDataPayload.append("billID", params.id);
    }
    trigger(formDataPayload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const previewDataResponse: BillPreviewDataResponse = useMemo(
    () => data?.data.payload,
    [data],
  );

  const billPreviewData = useMemo(
    () => generatePreviewFields(previewDataResponse, isExpand, setIsExpand),
    [previewDataResponse, isExpand, setIsExpand],
  );

  const renderThumbnail = (
    file: File | Blob,
    fileUrl: string = "",
    fileName: string = "",
    width: number = 32,
    height: number = 32,
  ) => {
    let name = "";
    let url = fileUrl;
    let isPDFDocument;
    if (fileUrl) {
      name = fileName;
      isPDFDocument = isPDF(fileUrl);
    } else {
      isPDFDocument = file?.type === "application/pdf";
      name = file?.name;
      url = URL.createObjectURL(file);
    }

    if (isPDFDocument) {
      return (
        <Document
          file={url}
          error={<FilePlaceholder name={name} />}
          noData={<FilePlaceholder name={name} />}
          className={styles.pdfViewer}
        >
          <Page pageNumber={1} />
        </Document>
      );
    }

    return (
      <img
        className={styles.thumbnail}
        src={url || uploadInvoice}
        alt={name}
        width={width}
        height={height}
      />
    );
  };

  const isDisableSubmitBtn = useMemo(() => {
    if ([BillFlowEnum.NEW_BILL, BillFlowEnum.EDIT_DRAFT].includes(billFlow)) {
      return false;
    }

    return !previewDataResponse?.isEdited;
  }, [billFlow, previewDataResponse?.isEdited]);

  return (
    <>
      <Preview data={billPreviewData} isEdited={previewDataResponse?.isEdited}>
        {(Boolean(additionalAttachments?.length) ||
          Boolean(filteredUploadedAttachments?.length)) && (
          <div className={styles.previewSection} data-title="Attachment">
            <div className={styles.previewContainer}>
              {[
                ...(additionalAttachments || []),
                ...(filteredUploadedAttachments || []),
              ]?.map((attachment, index) => {
                const { url = "", filename = "" } = attachment || {};
                return (
                  <div
                    className={styles.previewAttachmentItem}
                    key={`additional-attacment-${index + 1}`}
                  >
                    <div className={styles.imgContainer}>
                      {renderThumbnail(url ? null : attachment, url, filename)}
                    </div>
                    <Typography
                      className={styles.fileName}
                      variant="body-content"
                      tag="p"
                      size="s"
                    >
                      {url ? filename : attachment?.name}
                    </Typography>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </Preview>

      <div className={styles.nium}>
        <NiumDisclaimer type="bill" />
      </div>
      <FormFooter
        disableSubmit={isDisableSubmitBtn}
        onClickBack={onBack}
        onSubmit={() => {
          if (isBulkView) {
            handleSaveDraft();
          } else {
            onNext();
          }
        }}
        submitText={isBulkView ? "Save changes" : "Submit"}
        isSubmitLoading={isSubmitLoading}
      >
        {!billNumber && !isBulkView && billFlow !== BillFlowEnum.EDIT_BILL && (
          <Button
            type="button"
            size="m"
            variant="secondary"
            onClick={handleSaveDraft}
            loading={isSubmitLoading}
          >
            Save as draft
          </Button>
        )}
      </FormFooter>
    </>
  );
};

export default BillPreview;
