import React from "react";
import "./FAQButton.scss";
import { faqIcon } from "assets/v1.1/icons/Flat/index";

const FaqButton = () => {
  const handleClick = () => {
    window.open("https://support.spenmo.com/hc/en-us/sections/12388987863577-Approvals", "_blank", "noopener");
  };

  return (
    <div onClick={handleClick} data-testid="faq-button-container" className="faq-button__container">
      <img src={faqIcon} className="icon" />
      Learn more about approval flow in FAQs
    </div>
  );
};

export default FaqButton;
