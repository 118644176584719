import { IItemID } from "Modules/DS/Menu/@types";

export const dummyData = (prefix: string): IItemID[] => [
  { id: `${prefix}--DUMMY_DATA--1` },
  { id: `${prefix}--DUMMY_DATA--2` },
  { id: `${prefix}--DUMMY_DATA--3` },
  { id: `${prefix}--DUMMY_DATA--4` },
  { id: `${prefix}--DUMMY_DATA--5` },
  { id: `${prefix}--DUMMY_DATA--6` },
  { id: `${prefix}--DUMMY_DATA--7` },
  { id: `${prefix}--DUMMY_DATA--8` },
  { id: `${prefix}--DUMMY_DATA--9` },
  { id: `${prefix}--DUMMY_DATA--10` },
];
