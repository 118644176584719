import APIclient from "API/Client";
import { GetFormData } from "../../utility/APIWrapper";
import { GetBaseAuthObject } from "../../utility";

export const XeroAPI = {
  updateXeroAccount: (data) => {
    const orgId = GetBaseAuthObject().orgId;

    return APIclient.postData("/api/v1/partner/xero/account", GetFormData({ organisation_id: orgId, ...data }));
  },

  getAutoSyncSettings: () => {
    const orgId = GetBaseAuthObject().orgId;

    return APIclient.getData(`/ms/spm-organization/v1/setting/${orgId}?code=xero`);
  },

  updateAutoSyncSettings: (data) => {
    const orgId = GetBaseAuthObject().orgId;

    return APIclient.putData(`/ms/spm-organization/v1/setting/${orgId}/bulk`, data);
  },
};
