import React from "react";

import { DateFilter as DSDate, IDateFilterProps } from "Modules/DS/Filter";

import "./index.scss";

interface IFormState {
  start_date: string;
  end_date: string;
}

const RequestDate = ({
  filter,
  id,
  onApply,
  onClear,
  defaultValue,
}: Omit<IDateFilterProps<IFormState>, "startKey" | "endKey" | "asISO">) => {
  return (
    <DSDate<IFormState>
      id={id}
      defaultValue={defaultValue}
      filter={filter}
      onApply={onApply}
      onClear={onClear}
      startKey="start_date"
      endKey="end_date"
    />
  );
};

export default RequestDate;
