import {
  FETCH_TEAM_PROFILE_DATA_REQUEST,
  FETCH_TEAM_PROFILE_DATA_SUCCESS,
  FETCH_TEAM_PROFILE_DATA_FAILURE,
  RESET_TEAM_PROFILE_DATA,
  UPDATE_TEAM_PROFILE_NAME,
} from "Redux/Actions/Teams/teamProfileAction";
import { userInitialState } from "Redux/InitialState";

export const teamProfileReducer = (
  state = {
    ...userInitialState,
    selectedTeamId: null,
  },
  action
) => {
  const { data } = state;
  switch (action.type) {
    case FETCH_TEAM_PROFILE_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        data: data,
        error: false,
      };
    case FETCH_TEAM_PROFILE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: false,
      };
    case FETCH_TEAM_PROFILE_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: true,
      };
    case RESET_TEAM_PROFILE_DATA:
      return {
        ...state,
        data: {},
      };
    case UPDATE_TEAM_PROFILE_NAME:
      return {
        ...state,
        data: {
          ...data,
          payload: {
            ...data.payload,
            budget: {
              ...data.payload.budget,
              name: action.data.name,
            },
          },
        },
      };
    default:
      return state;
  }
};
