import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchGaConnectionStatus } from "Redux/Actions/SecurityHub/GoogleAuthenticator/ConnectionStatusActions";
import { deactiveGoogleAuthenticator } from "Redux/DataCalls/GoogleAuthenticator.api";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";

import ThirdPartyIntegrationCard from "Modules/Cards/ThirdPartyIntegrationCards";
import OtpAuthenticationModal from "Views/SecurityHub/GoogleAuthenticator/OtpAuthenticationModal";
import googleAuthenticatorLogo from "assets/img/googleAuthenticatorLogo.svg";
import "Views/SecurityHub/GoogleAuthenticator/GoogleAuthenticator.scss";
import { useToasterContext, TOASTER_STATUS_TYPE } from "Views/Settings/context/ToasterContext";

const GoogleAuthenticator = () => {
  const { gaConnectionStatusLoading, gaConnectionStatusError, gaConnectionStatusData } = useSelector(
    (state: any) => state?.gaConnectionStatusReducer || {}
  );
  const [showOtpAuthenticationModal, setShowOtpAuthenticationModal] = useState(false);
  const { setToaster } = useToasterContext();

  const history = useHistory();
  const dispatch = useDispatch();
  const isGoogleAuthenticatorActivated = gaConnectionStatusData?.is_2fa_enabled || false;

  useEffect(() => {
    dispatch(fetchGaConnectionStatus());
  }, [dispatch]);

  useEffect(() => {
    if (gaConnectionStatusError) {
      setToaster({
        show: true,
        message: gaConnectionStatusData?.errorMessage || "Failed to fetch google authenticator connection status",
        type: TOASTER_STATUS_TYPE.ERROR,
      });
    }
  }, [gaConnectionStatusError]);

  const onConfirmOfOtpAuthentication = async () => {
    setShowOtpAuthenticationModal(false);
    const response = await deactiveGoogleAuthenticator();
    const deactivationSuccessful = response?.status === HTTP_STATUS_CODE.OK;
    if (deactivationSuccessful) {
      dispatch(fetchGaConnectionStatus());
    }
    setToaster({
      show: deactivationSuccessful,
      message: deactivationSuccessful
        ? "Succesfully deactivated the google authenticator"
        : response?.data?.errors?.[0]?.message || "Oops something went wrong, Please try again later.",
      type: deactivationSuccessful ? TOASTER_STATUS_TYPE.SUCCESS : TOASTER_STATUS_TYPE.ERROR,
    });
  };

  const cardActionBtnsOnClickHandler = () => {
    if (isGoogleAuthenticatorActivated) {
      setShowOtpAuthenticationModal(true);
    } else {
      gaConnectionStatusError ? dispatch(fetchGaConnectionStatus()) : history.push("/activate-ga");
    }
  };

  const getGoogleAuthenticatorActionButtons = () => {
    if (gaConnectionStatusLoading) return [];
    return [
      {
        buttonText: isGoogleAuthenticatorActivated ? "Deactivate" : "Activate",
        onClick: cardActionBtnsOnClickHandler,
        variant: isGoogleAuthenticatorActivated ? "danger" : "primary",
        className: "third-party-integration-card__button",
      },
    ];
  };

  return (
    <>
      <ThirdPartyIntegrationCard
        isConnected={isGoogleAuthenticatorActivated}
        thirdPartyName="Google Authenticator"
        thirdPartyLogoSrc={googleAuthenticatorLogo}
        connectedTagText="Active"
        notConnectedTagText="Inactive"
        actionBtnsArray={getGoogleAuthenticatorActionButtons()}
        loadingConnectionStatus={gaConnectionStatusLoading}
      />
      {showOtpAuthenticationModal && (
        <OtpAuthenticationModal
          onClose={() => setShowOtpAuthenticationModal(false)}
          onConfirm={() => onConfirmOfOtpAuthentication()}
          showDeactivateInfoFirst={true}
          otpLength={6}
        />
      )}
    </>
  );
};

export default GoogleAuthenticator;
