import * as React from "react";
import Input from "Views/Card/Components/Input";
import ActionBtns from "Views/Card/CreateOrRequest/ActionBtns";
import { showErrorNotification } from "Views/Card/Components/Notification";

import TeamSelector from "Views/Card/CreateOrRequest/Steps/TitleAndTeam/TeamSelector";
import CardholderSelector from "Views/Card/CreateOrRequest/Steps/TitleAndTeam/CardholderSelector";
import ApproverSelector from "Views/Card/CreateOrRequest/Steps/TitleAndTeam/ApproverSelector";

import { useSelector } from "react-redux";
import { Actions } from "Views/Card/CreateOrRequest/Utilities/UseCreateOrRequestState";
import { useCreateOrRequestCardContext } from "Views/Card/CreateOrRequest/index.context";
import "Views/Card/CreateOrRequest/Steps/index.scss";
import { trackEvent } from "utility/analytics";
import { useIsLoggedInUserUnverified } from "customHooks/Card";
import { cardCreatingEvent, CardCreationEventNames } from "Views/Card/Analytics";
import { CARD_TYPE } from "Views/Card/types";

export const TitleAndTeam = () => {
  const { state, dispatch, cardType, changeStep } = useCreateOrRequestCardContext();
  const {
    manage_teams = [],
    is_admin,
    name: userName,
  } = useSelector((reduxState: any) => reduxState?.userInfoReducer?.data?.payload?.user || {});

  const isLoggedInUserUnverified = useIsLoggedInUserUnverified();

  const onTeamSelect = (teamId: string, teamName: string) => {
    if (teamId !== state.selectedTeamId) {
      dispatch({
        type: Actions.UPDATE_ADMIN_OR_MANAGER_IN_SELECTED_TEAM,
        value: is_admin || manage_teams?.includes(teamId),
      });
      dispatch({ type: Actions.UPDATE_SELECTED_TEAM_ID, value: teamId });
      dispatch({ type: Actions.UPDATE_SELECTED_TEAM_NAME, value: teamName });
      dispatch({ type: Actions.UPDATE_CARD_HOLDER_ID, value: "" });
      dispatch({ type: Actions.UPDATE_SELECTED_APPROVER_ID, value: "" });
      dispatch({ type: Actions.UPDATE_SELECTED_APPROVER_NAME, value: "" });
      trackEvent(cardCreatingEvent.TEAM_PICKED_FROM_TEAM_DROP_DOWN);
    }
  };

  const onStepChange = (goToNextStep: boolean) => {
    const { adminOrManagerInSelectedTeam } = state;
    if ((!adminOrManagerInSelectedTeam || cardType === CARD_TYPE.PHYSICAL) && isLoggedInUserUnverified) {
      showErrorNotification("Verify your account on the Spenmo mobile app to start using cards");
    } else {
      adminOrManagerInSelectedTeam && trackEvent(cardCreatingEvent.NEXT_FOR_STEP_1_CLICKED_WHILE_CARD_CREATION);
      changeStep(goToNextStep);
    }
  };

  const enableNextStep = () => {
    const { cardTitle, adminOrManagerInSelectedTeam, cardholderId, selectedApproverId } = state;
    if (cardTitle) {
      if (adminOrManagerInSelectedTeam) {
        return !!(cardholderId || cardType === CARD_TYPE.PHYSICAL);
      } else {
        return !!selectedApproverId;
      }
    }
    return false;
  };

  const onCardHolderSelection = (id: string) => {
    dispatch({ type: Actions.UPDATE_CARD_HOLDER_ID, value: id });
    trackEvent(CardCreationEventNames.CARD_CREATION_SETTINGS_SELECTED, {
      is_virtual_card: cardType === CARD_TYPE.VIRTUAL,
      card_creation_event_source: "Cardholder",
    });
  };

  const disabledCardHolderInput = (
    <>
      <Input value={userName} disabled={true} />
      <p className="create-or-request-card-steps__help-text">Cardholder cannot be changed once the card is created</p>
    </>
  );

  const getCardHolderOrApproverSelector = () => {
    if (state.selectedTeamId) {
      if (state.adminOrManagerInSelectedTeam) {
        return (
          <>
            <p className="create-or-request-card-steps__input-label sp-margin-top--xl">Cardholder</p>
            {cardType === CARD_TYPE.PHYSICAL ? (
              disabledCardHolderInput
            ) : (
              <CardholderSelector
                selectedCardholderId={state.cardholderId}
                selectedTeamId={state.selectedTeamId}
                onSelect={onCardHolderSelection}
              />
            )}
          </>
        );
      } else if (!state.adminOrManagerInSelectedTeam) {
        return (
          <>
            {cardType === CARD_TYPE.PHYSICAL && (
              <>
                <p className="create-or-request-card-steps__input-label sp-margin-top--xl">Cardholder</p>
                {disabledCardHolderInput}
              </>
            )}
            <ApproverSelector
              selectedApproverId={state.selectedApproverId}
              selectedTeamId={state.selectedTeamId}
              onSelect={(id, name) => {
                dispatch({ type: Actions.UPDATE_SELECTED_APPROVER_ID, value: id });
                dispatch({ type: Actions.UPDATE_SELECTED_APPROVER_NAME, value: name });
              }}
              title="Select Approver"
              infoText="Your company's Admin(s) can view, approve, and decline your card requests"
            />
          </>
        );
      }
    }
  };

  return (
    <>
      <div className="create-or-request-card-steps">
        <p className="create-or-request-card-steps__header">Set Up Your Card</p>
        <section className="create-or-request-card-steps__form-container">
          <p className="create-or-request-card-steps__input-label sp-margin-top--xl">Card Title</p>
          <Input
            value={state.cardTitle}
            onChange={(e) => {
              dispatch({ type: Actions.UPDATE_CARD_TITLE, value: e?.target?.value || "" });
              trackEvent(cardCreatingEvent.CARD_TITLE_ENTERED);
            }}
            placeholder="Enter Card Title"
          />
          <p className="create-or-request-card-steps__help-text">Example: Subscriptions, Travel</p>
          <p className="create-or-request-card-steps__input-label sp-margin-top--xl">Select Budget</p>
          <TeamSelector selectedTeamId={state.selectedTeamId} onSelect={onTeamSelect} />
          {getCardHolderOrApproverSelector()}
        </section>
      </div>
      <ActionBtns
        actionBtnName="Next"
        changeStep={onStepChange}
        renderGoBackBtn={false}
        disableNextBtn={!enableNextStep()}
      />
    </>
  );
};
