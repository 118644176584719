import React, { useContext, useMemo, useState } from "react";

import { debounce } from "utility";

import {
  IFilterItem,
  ISectionList,
  ISectionedQuickFilterWithSearch,
  IQuickFilterContainerWithSection,
  initialSectionedFilterWithStates,
} from "Modules/DS/Filter/@types";
import { ISelectContext, SelectContext } from "Modules/DS/Select";
import Menu, { IItemID, ILineProps, LINE_TYPE } from "Modules/DS/Menu";
import { useMultiSelect } from "Modules/DS/Menu/List/useHooks/useMultiSelect";
import Search, { DEBOUNCE_DELAY_TIME, sectionedSearch } from "Modules/DS/Search";

import { getLabel } from "./getLabel";
import { ContainerWithSection } from "./Container";

export const SectionedQuickFilterWithSearch = <T extends IItemID, K extends object = any>({
  listKey,
  titleKey,
  subArryIdKey,
  subArryTitleKey,
  appliedKey,
  idSeparator,
  height,
  children,
  defaultValue,
  onAddSelected,
  iconClass = "",
  searchPlaceholder = "",
  clearFilter = () => null,
  onClickOutside = () => null,
  filterItemProps,
  states = { ...initialSectionedFilterWithStates },
  sortFilteredData,
}: ISectionedQuickFilterWithSearch<T, K>): JSX.Element => {
  const [filteredData, setFilteredData] = useState<T[]>([]);

  const { closeSelect } = useContext<ISelectContext>(SelectContext);

  const isSelected: boolean = states.title && states.title !== defaultValue;

  const disableActions = !children.length || !states.selectedIds.length;

  const debounceHandler = debounce((filtered: T[]) => {
    states.setSearching(false);
    if (typeof sortFilteredData === "function") {
      setFilteredData(sortFilteredData(filtered));
    } else {
      setFilteredData(filtered);
    }
  }, DEBOUNCE_DELAY_TIME.MID);

  const handleSearch = (val: string) =>
    debounceHandler(sectionedSearch<T, K>(val, [...children] as T[], titleKey, listKey, subArryTitleKey));

  const onSearchHandler = (val: string) => {
    states.setSearching(true);
    states.setValue(val);
    handleSearch(val);
  };

  const subArr: K[] = useMemo(
    () => [].concat(...children.map((team: T) => team[listKey as string].length > 0 && team[listKey as string])),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [children]
  );

  const onApplyHandler = () => {
    if (!states.selectedIds.length) return;

    const splitIds = states.selectedIds.map((id) => id.split(idSeparator));
    const subArrIds = splitIds.map((id) => id[0]);
    const arrIds = splitIds.map((id) => id[1]);

    const checked: K[] = subArrIds
      .map((selectedId) => subArr.find((item) => item[subArryIdKey as string] === selectedId))
      .filter(Boolean);

    if (!checked.length) return;

    let text: string = getLabel(checked, subArryTitleKey as string, defaultValue);

    if (!text) return;

    states?.setTitle?.(text);

    const subArrValue: string[] = checked.map((val) => val[(appliedKey ? appliedKey : subArryIdKey) as string]);

    onAddSelected?.(subArrValue, arrIds);
    closeSelect();

    onSearchHandler("");
    setFilteredData([]);
  };

  const onClickOutsideHandler = () => {
    if (!isSelected && states.selectedIds.length > 0) {
      states.setSelectedIds([]);
    }

    onClickOutside?.();
    onSearchHandler("");
  };

  const handleClearFilter = (): void => {
    states?.setLoading?.(false);
    states?.setSelectedIds?.([]);
    states?.setTitle?.("");
    if (isSelected) {
      clearFilter?.();
      closeSelect();
    }

    onSearchHandler("");
  };

  const renderHeader = () => {
    return (
      <Search.Popover
        value={states.value}
        onSearch={onSearchHandler}
        loading={states?.loading || states?.searching}
        placeholder={`Search ${(searchPlaceholder ? searchPlaceholder : defaultValue).toLowerCase()}`}
      />
    );
  };

  const renderFooter = () => {
    return (
      children.length && (
        <Menu.Actions
          onApply={onApplyHandler}
          onClear={handleClearFilter}
          disableApply={disableActions}
          disableClear={disableActions}
        />
      )
    );
  };

  const onClickHandler = useMultiSelect(states.selectedIds, states.setSelectedIds);

  const lineProps =
    (itemId: string) =>
    (item: K): ILineProps => ({
      id: item[subArryIdKey] + idSeparator + itemId,
      title: item[titleKey as string],
      selected: states.selectedIds.includes(item[subArryIdKey] + idSeparator + itemId),
      type: LINE_TYPE.CHECKBOX_SINGLE_LINE,
      loading: states?.loading || states?.searching,
      onClick: onClickHandler,
    });

  const filterProps: Omit<IFilterItem, "children" | "iconClass"> = {
    selected: isSelected,
    clearFilter: handleClearFilter,
    onClickOutside: onClickOutsideHandler,
    title: isSelected ? states.title : defaultValue,
    ...filterItemProps,
  };

  const listProps: Omit<ISectionList<T, K>, "children"> = {
    height,
    listKey,
    titleKey,
    lineProps,
    subArryIdKey,
    loading: states?.loading || states?.searching,
  };

  const props: Omit<IQuickFilterContainerWithSection<T, K>, "children"> = {
    iconClass,
    listProps,
    header: renderHeader(),
    footer: renderFooter(),
    filterItemProps: filterProps,
  };

  return (
    <ContainerWithSection<T> {...props}>
      {filteredData.length > 0 || states.value.length > 0 ? filteredData : children}
    </ContainerWithSection>
  );
};
