/**
 * @fileoverview This module provides the primary navigation container for the application.
 * It utilizes various components such as Sidebar, NavigationLoader, and others
 * to provide a cohesive navigation experience. If user information is being loaded,
 * a loader is shown, otherwise the Sidebar is displayed with the respective organization name.
 *
 * @author Sushmitha, Bakshi
 * @created 11 Aug 2023
 * @lastModified 30 Aug 2023
 */
import React from "react";

import { RootState } from "Redux/ConfigureStore";

import { useAppSelector } from "hook";

import Footer from "Views/Navigation/Footer";
import Sidebar from "Views/Navigation/Sidebar";
import { NavigationLoader } from "Views/Navigation/Loader";
import { NavigationProvider } from "Views/Navigation/Provider";

import styles from "./index.module.scss";

/**
 * The Navigation component serves as the primary navigation sidebar of the application.
 *
 * If the user's organization name is still loading or undefined, it will render
 * the NavigationLoader. Once the user's organization name becomes available,
 * the Sidebar is rendered within the NavigationProvider.
 *
 * @returns {React.ReactElement} The rendered navigation container.
 */
const Navigation = (): React.ReactElement => {
  // Extracting `loading` and `data` from userInfoReducer of the Redux state.
  const { loading, data } = useAppSelector((state: RootState) => state.userInfoReducer);

  // Safely extracting the organisation name from the data, defaulting to undefined if unavailable.
  const organisationName = data?.payload?.user?.organisation_name || undefined;

  return (
    <aside className={styles.container}>
      {loading || organisationName === undefined ? (
        <NavigationLoader />
      ) : (
        <NavigationProvider>
          <Sidebar organisationName={organisationName} />
          {/* A footer is added using a variant from the splice library. */}
          <Footer />
        </NavigationProvider>
      )}
    </aside>
  );
};

export default Navigation;
