import * as React from "react";
import { Skeleton } from "antd";

import styles from "./index.module.scss";

const CardTitleShimmer = () => {
  return (
    <div data-testid="shimmer-container" className={styles["shimmer__container"]}>
      <Skeleton.Input className={styles["shimmer__limit"]} active />
      <Skeleton.Input className={styles["shimmer__limit-type"]} active />
    </div>
  );
};

export default CardTitleShimmer;
