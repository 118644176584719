import React, { useEffect, useState } from "react";
import { Row, Col, Form } from "antd";
import { AxiosError, AxiosResponse } from "axios";

import useLoading from "utility/useLoading";
import { GetCurrencyCode } from "utility";
import { useQuery } from "utility/useQuery";
import { getPartnerDataStatus } from "Redux/DataCalls/AccountingSetting.api";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import { AccountTypes, SyncAccountsConfig } from "constants/Integration.constant";
import LoadingComponent from "Views/State/Loading/LoaderIcon";

import CreateOrChooseBankAccount from "../../Common/CreateOrChooseBankAccount";
import { addBankAccountInAccountingPartner, updatePartnerConnection } from "../../Common/dataCall";

import styles from "./SelectXeroSyncAccount.module.scss";

const SelectXeroSyncAccount = ({ goToSettingPage, bankAccounts = [], loading }) => {
  const [form] = Form.useForm();
  const [error, setError] = useState(false);

  const getSpenmoBankAccount = () => {
    if (bankAccounts.length) {
      const spenmoBankAccount = bankAccounts.find((item) => item.name?.toLowerCase()?.includes("spenmo"));
      return spenmoBankAccount ? spenmoBankAccount?.id : "";
    }
    return "";
  };

  const getAccountNameErrorMsg = (error: AxiosError): string => {
    return error.response?.data?.error?.message.includes("Please enter a unique Name")
      ? "Bank account name already exists in NetSuite. Please select a different name."
      : error.response?.data?.error?.message || "Something went wrong";
  };

  const [selectedBankAccount, setSelectedBankAccount] = useState(getSpenmoBankAccount());
  const [updatePartnerAccount, updatePartnerAccountLoading] = useLoading(
    updatePartnerConnection,
    (error: AxiosResponse) => {
      return error.data?.error?.message || "Something went wrong";
    }
  );
  const [addNewBankAccount, loadingAddNewBankAccount] = useLoading(
    addBankAccountInAccountingPartner,
    null,
    null,
    false
  );
  const { data: finishFetchBankAccount } = useQuery({
    apiCall: getPartnerDataStatus,
    customDataMapping(data) {
      return (data?.payload?.data || []).find((item) => item.dataType === "chartOfAccounts")?.status === "Complete";
    },
    defaultData: false,
  });

  useEffect(() => {
    if (bankAccounts.length && !selectedBankAccount) {
      const spenmoBankAccountId = getSpenmoBankAccount();
      if (spenmoBankAccountId) {
        form.setFieldsValue({
          [SyncAccountsConfig[AccountTypes.spenmoAccount]?.formItemName]: spenmoBankAccountId,
        });
        setSelectedBankAccount(spenmoBankAccountId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bankAccounts, setSelectedBankAccount]);

  const onFinishHandler = async (values) => {
    const partnerBankAccountID = values[SyncAccountsConfig[AccountTypes.spenmoAccount]?.formItemName];
    const partnerBankAccount = bankAccounts.find((acc) => acc.id === partnerBankAccountID);

    const formData = {
      partnerBankAccountName: partnerBankAccount?.name || "",
      partnerBankAccountNumber: partnerBankAccount?.number,
      partnerBankAccountID: partnerBankAccountID,
    };

    if (values["bank_account_selection"] === "Add New Account") {
      try {
        const response = await addNewBankAccount({
          currencyCode: GetCurrencyCode(),
          name: values.accountName,
          number: values.accountNumber,
        });

        const { data: bankAccountData } = response || {};
        formData.partnerBankAccountID = bankAccountData.payload?.id;
        formData.partnerBankAccountName = bankAccountData.payload?.name;
        formData.partnerBankAccountNumber = bankAccountData.payload?.number;
      } catch (error) {
        setError(true);

        form.validateFields(["accountName"]).then(() => {
          form.setFields([
            {
              name: "accountName",
              errors: [getAccountNameErrorMsg(error)],
            },
          ]);
        });
        return;
      }
    }

    const response = await updatePartnerAccount(formData);

    if (response?.data?.status === HTTP_STATUS_CODE.OK) {
      goToSettingPage({
        message:
          "You have successfully connected NetSuite to your organization. Please allow some time for synchonization to complete.",
      });
    }
  };

  const onChangeHandler = (value, accountType) => {
    accountType === AccountTypes.spenmoAccount && setSelectedBankAccount(value);
  };

  if (loading) {
    return <LoadingComponent />;
  }
  return (
    <Row justify="start" className={styles.syncAccount}>
      <Form onFinish={onFinishHandler} form={form} className={styles.fullWidth}>
        <Col span={24}>
          <Row className={styles.container}>
            <CreateOrChooseBankAccount
              accountType={AccountTypes.spenmoAccount}
              bankAccounts={bankAccounts}
              onChangeHandler={onChangeHandler}
              selectedBankAccount={selectedBankAccount}
              form={form}
              loading={updatePartnerAccountLoading || loadingAddNewBankAccount}
              error={error}
              clearError={() => setError(false)}
              partnerName="NetSuite"
              buttonTitle="Connect"
              finishFetchBankAccount={finishFetchBankAccount}
            />
          </Row>
        </Col>
      </Form>
    </Row>
  );
};

export default SelectXeroSyncAccount;
