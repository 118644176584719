import React, { useState, memo, useEffect } from "react";
import cn from "classnames";

import styles from "./styles.module.scss";

export const VendorLogo = ({
  title,
  additionalMargin,
  disabled,
}: {
  title: string;
  additionalMargin: boolean;
  disabled: boolean;
}) => {
  return (
    <div
      className={cn(styles.newVendor, {
        [styles.additionalMargin]: additionalMargin,
        [styles.disabled]: disabled,
      })}
    >
      {title.toUpperCase()}
    </div>
  );
};

export const VendorImageComponent = ({
  vendor,
  width = 24,
  height = 23,
  additionalMargin = false,
  disabled,
}: {
  vendor: string;
  width?: number;
  height?: number;
  additionalMargin?: boolean;
  disabled?: boolean;
}) => {
  const [iconNotFound, setIconNotFound] = useState(false);

  useEffect(() => {
    setIconNotFound(false);
  }, [vendor]);

  if (iconNotFound) {
    return <VendorLogo additionalMargin={additionalMargin} title={vendor.charAt(0)} disabled={disabled} />;
  }

  return (
    <img
      src={`https://spenmo-prod.s3.ap-southeast-1.amazonaws.com/merchant-logo/${vendor.toLocaleLowerCase()}.svg`}
      width={width}
      height={height}
      alt="vendor_icon"
      className={cn({
        [styles.disabled]: disabled,
      })}
      onError={() => setIconNotFound(true)}
    />
  );
};

export const VendorImage = memo(VendorImageComponent);
