import React from "react";
import PersonnnelInfoForm from "./Form";

import "../../Progress/index.scss";

export const JointApplicantForm = () => {
  return (
    <div className="kyc-comp">
      <PersonnnelInfoForm />
    </div>
  );
};
