import axios, { AxiosResponse, CancelTokenSource } from "axios";

import APIclient from "API/Client";

import { GetOrgId } from "utility";
import { GetFormDataWithArray } from "utility/APIWrapper";
import {
  IGetTrxnRequest,
  IDownloadCSVRequest,
  IGetListTrxnsResponse,
  IGetTrxnsCountRequest,
  IGetTrxnsCountResponse,
  ISaveTrxnDetailsRequest,
  IDownloadBankStatementRequest,
  GET_TRXN_RESPONSE_PARAMS,
  IGetTransactionReceiptsCountRequest,
  IGetTransactionReceiptsCountResponse,
} from "Views/Transaction/@types";
import { buildUrl, treatParams } from "Views/Transaction/Helper";

import { trimPaginationAndListParams } from "./index";
import { ALL_TRXNS_SERVICE, TRANSACTION_DETAILS } from "./Services";

export const getAllTrxns = async (payload?: IGetTrxnRequest): Promise<AxiosResponse<IGetListTrxnsResponse>> => {
  const url: string = buildUrl(ALL_TRXNS_SERVICE.GET_TRXNS_LIST, treatParams<IGetTrxnRequest>(payload));

  return APIclient.getData(url);
};

export const getTrxnsCount = async (
  payload?: IGetTrxnsCountRequest
): Promise<AxiosResponse<IGetTrxnsCountResponse>> => {
  trimPaginationAndListParams<IGetTrxnsCountRequest>(payload);

  const url: string = buildUrl(ALL_TRXNS_SERVICE.GET_TRXNS_COUNT, treatParams<IGetTrxnsCountRequest>(payload));
  return APIclient.getData(url);
};

export const getTransactionsReceiptsCount = async (
  payload?: IGetTransactionReceiptsCountRequest
): Promise<AxiosResponse<IGetTransactionReceiptsCountResponse>> => {
  trimPaginationAndListParams<IGetTransactionReceiptsCountRequest>(payload);

  const url: string = buildUrl(
    ALL_TRXNS_SERVICE.GET_RECEIPTS_COUNT,
    treatParams<IGetTransactionReceiptsCountRequest>(payload)
  );
  return APIclient.getData(url);
};

export const downloadTrxnsBankStatement = async (
  payload: IDownloadBankStatementRequest,
  email?: string
): Promise<AxiosResponse<Iterable<number>>> => {
  trimPaginationAndListParams<IDownloadBankStatementRequest>(payload);

  const params = treatParams<IDownloadBankStatementRequest>({
    ...payload,
    ...(email && { email }),
  });

  return APIclient.postData(ALL_TRXNS_SERVICE.DOWNLOAD_BANK_STATEMENT, params, false, {
    responseType: "arraybuffer",
  });
};

export const downloadTrxnsCsv = async (
  payload: IDownloadCSVRequest,
  selectedRows?: string[],
  email?: string
): Promise<AxiosResponse<string>> => {
  trimPaginationAndListParams<IDownloadCSVRequest>(payload);

  const params = treatParams<IDownloadCSVRequest>({
    ...payload,
    ...(Array.isArray(selectedRows) && selectedRows.length > 0 && { transaction_ids: selectedRows }),
    ...(email && { email }),
  });

  return APIclient.postData(ALL_TRXNS_SERVICE.DOWNLOAD_CSV, params);
};

export const downloadBillTrxnsRawCsv = async (payload, selectedRows?: string[]): Promise<AxiosResponse<string>> => {
  const params = treatParams<IDownloadCSVRequest>({
    ...payload,
    ...(Array.isArray(selectedRows) && selectedRows.length > 0 && { transaction_ids: selectedRows }),
  });

  return APIclient.postData("api/v1/invoices/csv", GetFormDataWithArray({ ...params }));
};

export const downloadRecipt = async (
  payload: IDownloadBankStatementRequest,
  email?: string
): Promise<AxiosResponse<Iterable<number>>> => {
  trimPaginationAndListParams<IDownloadBankStatementRequest>(payload);

  const params = treatParams<IDownloadBankStatementRequest>({
    ...payload,
    ...(email && { email }),
  });

  return APIclient.postData(ALL_TRXNS_SERVICE.DOWNLOAD_RECEIPT, params);
};

export const getTrxnAttachment = async (url: string) => {
  return APIclient.getData(url + `?organisation_id=${GetOrgId()}`);
};

export const getTrxnDetailsById = async (id: string) => {
  return APIclient.getData(TRANSACTION_DETAILS().GET_TRANSACTION_DETAILS(id));
};

export const getReceiptDetails = async (transactionId: string, transactionNumber: string) => {
  return APIclient.getData(TRANSACTION_DETAILS().GET_RECEIPT_DETAILS(transactionId, transactionNumber));
};

export const saveTrxnDetails = async (
  transactionNumber: string,
  payload: ISaveTrxnDetailsRequest
): Promise<AxiosResponse<any>> => {
  const params = treatParams<ISaveTrxnDetailsRequest>(payload);

  const formData = {
    [GET_TRXN_RESPONSE_PARAMS.ORG_ID]: GetOrgId(),
    [GET_TRXN_RESPONSE_PARAMS.TRXN_NUMBER]: transactionNumber,
    ...params,
  };

  return APIclient.postData(TRANSACTION_DETAILS().SAVE_TRANSACTION_DETAILS, formData);
};

export const linkTransactionToSubscriptions = async (
  trxnNumbers: string[],
  subscriptionId: string
): Promise<AxiosResponse<any>> => {
  const formData = {
    transaction_numbers: trxnNumbers,
    subscription_id: subscriptionId,
  };

  return APIclient.putData(TRANSACTION_DETAILS().LINK_TRANSACTION_TO_SUBSCRIPTION, formData);
};

export const searchMerchantList = async (value: string, source: CancelTokenSource) => {
  const payload = {
    search_term: value,
    organisation_id: GetOrgId(),
  };

  const config = { cancelToken: source.token };

  try {
    const response = await APIclient.postData(ALL_TRXNS_SERVICE.SEARCH_MERCHANTS, payload, null, config);
    return response.data;
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log("Request canceled", error.message);
    } else {
      throw error;
    }
  }
};
