import { ILegalDocuments } from "Views/KYX/@types";

export enum CLIENT_AGREEMENT_TYPE {
  SG = "SG",
  ID = "ID",
  DEFAULT = "DEFAULT",
}

const SG: ILegalDocuments = {
  title: "Spenmo Client Agreement",
  description: "Please read our terms and policies before submitting your company data for verification.",
  list: [
    "Terms of Use",
    "Privacy Policy",
    "Client Services Agreement",
    "Payment Service Partners",
    "Cash Rebate Programme",
  ].map((name) => ({
    name,
    link: "https://spenmo.com/legal",
  })),
  confirmationMessage: "I have read and agree to Spenmo’s Client Agreement",
};

const DEFAULT: ILegalDocuments = {
  title: "Spenmo Client Agreement",
  description: "Please read our terms and policies before submitting your company data for verification.",
  list: ["Client Services Agreement", "Privacy Policy", "Terms of Use"].map((name) => ({
    name,
    link: "https://spenmo.com/legal",
  })),
  confirmationMessage: "I have read and agree to Spenmo’s Client Agreement",
};

export const ClientAgreementData: {
  [CLIENT_AGREEMENT_TYPE.SG]: ILegalDocuments;
  [CLIENT_AGREEMENT_TYPE.DEFAULT]: ILegalDocuments;
} = {
  [CLIENT_AGREEMENT_TYPE.SG]: SG,
  [CLIENT_AGREEMENT_TYPE.DEFAULT]: DEFAULT,
};
