import React, { useState } from "react";
import { IToasterProps } from "Modules/DS/Toaster/types";
import TeamToasterContext from "Views/Team/Toast/context";

const TeamToasterProvider = ({ children }: { children: React.ReactNode }) => {
  const [toaster, setToaster] = useState<IToasterProps>({
    visible: false,
    message: "",
  });

  return <TeamToasterContext.Provider value={{ toaster, setToaster }}>{children}</TeamToasterContext.Provider>;
};

export default TeamToasterProvider;
