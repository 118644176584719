import classNames from "classnames";
import React from "react";
import { TRXN_ELEMENT_IDS } from "Views/Transaction/Constants";

import { wrapTooltip } from "Views/Transaction/Helper";

import styles from "./Cell.module.scss";

export const CellWithSubtitle = ({
  title,
  subtitle,
  charCount,
}: {
  title: string;
  subtitle?: string;
  charCount?: number;
}) => {
  return (
    <div className={styles.cellWithSubtitle}>
      {wrapTooltip(title, "h5", charCount)}
      {subtitle && wrapTooltip(subtitle, "p", charCount)}
    </div>
  );
};

export const IconCell = ({
  src,
  alt,
  onClick,
  disabled = false,
}: {
  src: string;
  alt: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  disabled?: boolean;
}) => {
  const onClickHandler = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();

    if (disabled) return;

    onClick?.(e);
  };

  return (
    <div
      id={TRXN_ELEMENT_IDS.ICON_CELL}
      className={classNames(styles.cellWithIcon, { [styles.hover]: onClick }, { [styles.disabled]: disabled })}
      onClick={onClickHandler}
    >
      <img alt={alt} className={styles.icon} src={src} />
    </div>
  );
};
