import * as React from "react";
import { RichHeaderProps, RICH_HEADER_TYPE, SIZE_TYPES } from "./types";
import Icon from "Modules/icons";
import classNames from "classnames";
import { Cross } from "assets/v1.1/icons/Navigation/Cross";
import Chip, { TIER_CHIP_STATUS } from "Modules/DS/Chip";
import styles from "./index.module.scss";

const RichHeader = ({
  onClose,
  title,
  type = RICH_HEADER_TYPE.DEFAULT,
  showPremiumLabel,
  size = SIZE_TYPES.EXTRA,
}: RichHeaderProps) => {
  const _classNames = classNames(styles.container, styles[type], styles[size]);

  return (
    <div data-testid="rich-header" className={_classNames}>
      {onClose && <Icon className={styles.close} action={onClose} src={Cross.Line[24]} alt="close" />}
      <div className={styles["title-wrapper"]}>
        <p className={styles.title}>{title}</p>
        {showPremiumLabel && <Chip.Tier status={TIER_CHIP_STATUS.ESSENTIAL} title="Essential" />}
      </div>
    </div>
  );
};

export default RichHeader;
