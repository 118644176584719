import React from "react";

/*
  enums
*/
export enum TITLE_TYPE {
  largeWithBorder = "with-border--large",
  withBorder = "with-border",
  loadContent = "loading-content",
  primaryColor = "primary-color",
}

/*
  interfaces
*/
export interface IModalContext {
  onClose: () => void;
}

/**
 * IModalProps interface represents the type for the data contained in props of a modal,
 * which includes an optional close function, optional className string and required children React node.
 */
export interface IModalProps {
  /**
   * @property {( () => void )} [close] - Optional close function for the modal
   */
  close?: () => void;
  /**
   * @property {React.ReactNode} children - Required React node for the modal
   */
  children: React.ReactNode;
  /**
   * @property {string} [className] - Optional class name for the modal
   */
  className?: string;
  /**
   * @property {boolean} visible - Indicates whether the modal is visible or not
   */
  visible?: boolean;
  /**
   * @property {boolean} maskClosable - Whether to close the modal dialog when the mask (area outside the modal) is clicked
   */
  maskClosable?: boolean;
}

export interface ICloseModalProps {
  fixToTop?: boolean;
  src?: string;
  className?: string;
  analytics?: {
    enabled: boolean;
    eventName: string;
    properties?: { [key: string]: string };
  };
}

export interface IModalTitleProps {
  titleType?: TITLE_TYPE;
  children: string | React.ReactNode;
}

export interface IModalSubTitleProps {
  children: string;
}

export interface IContentLoader {
  title: string;
  subtitle?: string;
}

/*
  initialize
*/
export const initialModalContext: IModalContext = { onClose: () => null };
