/* enum */

import { GET_TRXN_RESPONSE_PARAMS } from "Views/Transaction/@types";

export enum GET_BILLS_TRXN_REQUEST_PARAMS {
  ROWS = "rows",
  PAGE = "page",
  FILTERS = "filters",
  STATUS_LIST = "statusList",
  AMOUNT_RANGE = "amountRange",
  RECIPIENT = "recipient",
  DATE_RANGE = "dateRange",
  STATUS = "status",
}

export interface IGetBillsTrxnRequest {
  [GET_BILLS_TRXN_REQUEST_PARAMS.ROWS]: number;
  [GET_BILLS_TRXN_REQUEST_PARAMS.PAGE]: number;
  [GET_BILLS_TRXN_REQUEST_PARAMS.STATUS]?: any;
  [GET_BILLS_TRXN_REQUEST_PARAMS.STATUS_LIST]?: string;
  [GET_BILLS_TRXN_REQUEST_PARAMS.AMOUNT_RANGE]?: string;
  [GET_BILLS_TRXN_REQUEST_PARAMS.RECIPIENT]?: string;
  [GET_BILLS_TRXN_REQUEST_PARAMS.DATE_RANGE]?: string;
}

export enum GET_BILLS_TRXN_RESPONSE_PARAMS {
  ID = "id",
  SLA = "SLA", // ESTIMATED ARRIVAL
  AMOUNT = "amount", // RECEIVING AMOUNT
  ATTACHMENTS = "attachments",
  ATTACHMENTS_URL = "attachmentsURL",
  BENEFICIARY_NAME = "beneficiaryName", // RECEIPIENT
  BILL_AMOUNT_FEE_INCLUSIVE = "billAmountFeeInclusive", // YOU PAY
  CLIENT_NOTES = "clientNotes",
  COUNT_ATTACHMENTS = "countAttachments",
  COUNT_RECEIPTS = "countReceipts",
  CREATED_AT = "createdAt",
  CURRENCY = "currency",
  DUE_DATE = "dueDate",
  PAYMENT_SCHEDULE = "paymentSchedule",
  PAYMENT_SCHEDULE_SAAS = "paymentScheduleSaaS", // only placeholder to manipulate paymentSchedule
  INVOICE_NUMBER = "invoiceNumber",
  INVOICE_AMOUNT = "invoiceAmount",
  INVOICE_CURRENCY = "invoiceCurrency",
  IS_ENABLE_FEE_CALCULATOR = "isEnableFeeCalculator",
  RECEIPTS = "receipts",
  RECEIPTS_URL = "receiptsURL",
  STATUS = "status",
  TAGS = "tags",
  TOTAL_FEE_AMOUNT = "totalFeeAmount",
  TRANSACTION_CODE = "transactionCode",
  UPDATED_AT = "updatedAt",
  WALLET_AMOUNT = "walletAmount",
  WALLET_CURRENCY = "walletCurrency",
}

export interface IBillTrxnReceipts {
  id: string;
}

export interface IBillTrxnStatus {
  label?: string;
  key?: string;
  color?: string;
}

export interface IGetBillTrxnResponse {
  [GET_BILLS_TRXN_RESPONSE_PARAMS.ID]: string;
  [GET_BILLS_TRXN_RESPONSE_PARAMS.SLA]: string;
  [GET_BILLS_TRXN_RESPONSE_PARAMS.AMOUNT]: number;
  [GET_BILLS_TRXN_RESPONSE_PARAMS.ATTACHMENTS]: any[] | null;
  [GET_BILLS_TRXN_RESPONSE_PARAMS.ATTACHMENTS_URL]: string[] | null;
  [GET_BILLS_TRXN_RESPONSE_PARAMS.BENEFICIARY_NAME]: string;
  [GET_BILLS_TRXN_RESPONSE_PARAMS.CURRENCY]: string;
  [GET_BILLS_TRXN_RESPONSE_PARAMS.BILL_AMOUNT_FEE_INCLUSIVE]: number;
  [GET_BILLS_TRXN_RESPONSE_PARAMS.COUNT_ATTACHMENTS]: number;
  [GET_BILLS_TRXN_RESPONSE_PARAMS.COUNT_RECEIPTS]: number;
  [GET_BILLS_TRXN_RESPONSE_PARAMS.CLIENT_NOTES]: string;
  [GET_BILLS_TRXN_RESPONSE_PARAMS.INVOICE_NUMBER]: string;
  [GET_BILLS_TRXN_RESPONSE_PARAMS.STATUS]: IBillTrxnStatus;
  [GET_BILLS_TRXN_RESPONSE_PARAMS.TAGS]: any[];
  [GET_BILLS_TRXN_RESPONSE_PARAMS.TOTAL_FEE_AMOUNT]: number;
  [GET_BILLS_TRXN_RESPONSE_PARAMS.TRANSACTION_CODE]: string;
  [GET_BILLS_TRXN_RESPONSE_PARAMS.RECEIPTS]: IBillTrxnReceipts[];
  [GET_BILLS_TRXN_RESPONSE_PARAMS.RECEIPTS_URL]: string[] | null;
  [GET_BILLS_TRXN_RESPONSE_PARAMS.TAGS]: any[];
  [GET_BILLS_TRXN_RESPONSE_PARAMS.UPDATED_AT]: string;
  [GET_BILLS_TRXN_RESPONSE_PARAMS.WALLET_AMOUNT]: number;
  [GET_BILLS_TRXN_RESPONSE_PARAMS.WALLET_CURRENCY]: string;
  [GET_BILLS_TRXN_RESPONSE_PARAMS.CREATED_AT]: string;
  [GET_BILLS_TRXN_RESPONSE_PARAMS.DUE_DATE]: string;
  [GET_BILLS_TRXN_RESPONSE_PARAMS.IS_ENABLE_FEE_CALCULATOR]: boolean;
  [GET_TRXN_RESPONSE_PARAMS.SORT]?: [number, string];
}

export enum BILL_TRXN_LIST_RESPONSE_PARAMS {
  PAGINATION = "pagination",
  COUNT_ALL = "countAll",
  COUNT_APPROVAL = "countApproval",
  LIST = "list",
  STATUS = "status",
  ERROR = "error",
}

export interface IBillsTrxnSuccessData {
  [BILL_TRXN_LIST_RESPONSE_PARAMS.LIST]: IGetBillTrxnResponse[] | null;
  [BILL_TRXN_LIST_RESPONSE_PARAMS.COUNT_ALL]?: number;
  [BILL_TRXN_LIST_RESPONSE_PARAMS.COUNT_APPROVAL]?: number;
  [BILL_TRXN_LIST_RESPONSE_PARAMS.PAGINATION]: {
    CurrentPage: number;
    TotalPage: number;
    TotalRow: number;
  };
}

export interface IBillsTrxnErrorData {
  [BILL_TRXN_LIST_RESPONSE_PARAMS.STATUS]: number;
  [BILL_TRXN_LIST_RESPONSE_PARAMS.ERROR]: any;
}

export type IGetBillsTrxnsListResponse = IBillsTrxnSuccessData | IBillsTrxnErrorData;
