import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import Icon from "Modules/icons";
import Toaster from "Modules/DS/Toaster";

import { TOASTER_SIZE_TYPE } from "Modules/DS/Toaster/types.d";
import UploadFile from "Modules/UploadFile/UploadFile";

import useLoadingToaster from "utility/useLoadingToaster";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import { uploadInvoice as uploadInvoiceLogo } from "assets/img";
import { uploadBulk as uploadInvoiceAPI } from "Redux/DataCalls/Disbursement.api";

import { ALLOWED_FILE_EXTENSIONS } from "Views/UploadMultiple/const";

import styles from "./Uploader.module.scss";
import { trackEvent } from "utility/analytics";

const Uploader = ({ onValidateFile, onUploadFile, selectedTeam, selectedWallet, disabled, supportedVersion }) => {
  const generateErrorMessage = (response) => {
    return response.data.error.message;
  };

  const [uploadFileAction, isLoading, toasterDetails, closeToaster] = useLoadingToaster(
      uploadInvoiceAPI,
      generateErrorMessage,
      {}
    ),
    [hint, setHint] = useState(""),
    [error, setError] = useState();

  useEffect(() => {
    let versions = "-";
    if (supportedVersion && supportedVersion.length > 0) {
      versions = supportedVersion.join(", ") + ".";
    }

    const subtitle = `Supported template version: ${versions}`;
    setHint(subtitle);
  }, [supportedVersion]);

  const validateFile = (file) => {
    const fileExtension = file.name.split(".").pop().toLowerCase();
    const isFileValid = ALLOWED_FILE_EXTENSIONS.includes(fileExtension);

    if (!isFileValid) {
      setHint("");
      setError("We only accept XLSX format");
    }

    onValidateFile(file.name);

    return isFileValid;
  };

  const uploadFile = async ({ payload, onUploadProgress }) => {
    try {
      const { data } = await uploadFileAction(payload.file, selectedWallet, selectedTeam, onUploadProgress);
      if (data.status === HTTP_STATUS_CODE.OK) {
        trackEvent("bill upload bulk attachment success");
        onUploadFile(data.payload);
      } else {
        trackEvent("bill upload bulk attachment failed");
      }
    } catch (e) {
      trackEvent("bill upload bulk attachment failed");
    }
  };

  return (
    <div
      onDrop={(e) => {
        // This is a workaround to handle event tracking on drag and drop attachment
        // Current version of antd doesn't support onDrop for Uploader component
        // When upgrading antd version to 4.16, move this inside the Uploader component
        e.stopPropagation();
        e.preventDefault();
        trackEvent("bill drag and drop bulk attachment");
      }}
    >
      <UploadFile
        maxCount={1}
        beforeUpload={validateFile}
        customRequest={uploadFile}
        loading={isLoading}
        icon={<Icon src={uploadInvoiceLogo} />}
        text={"Drag and drop .xlsx file here"}
        hint={hint}
        error={error}
        className={styles.uploader}
        accept={ALLOWED_FILE_EXTENSIONS.map((item) => `.${item}`).join(",")}
        disabled={disabled}
        onClick={() => {
          trackEvent("bill click upload bulk attachment");
        }}
      />
      <div className={styles["toaster-container"]}>
        {toasterDetails.length > 0 &&
          toasterDetails.map((toasterDetail) => (
            <Toaster
              message={toasterDetail.message}
              visible={true}
              onClose={() => {
                closeToaster(toasterDetail.id);
              }}
              size={TOASTER_SIZE_TYPE.M}
            />
          ))}
      </div>
    </div>
  );
};

Uploader.propTypes = {
  onValidateFile: PropTypes.func,
  onUploadFile: PropTypes.func,
  selectedTeam: PropTypes.string,
  selectedWallet: PropTypes.string,
  disabled: PropTypes.bool,
};

Uploader.defaultProps = {
  onValidateFile: () => {},
  onUploadFile: () => {},
  selectedTeam: "",
  selectedWallet: "",
  disabled: true,
};

export default Uploader;
