export const sanitizeObject = <T extends object = any>(obj: T) => {
  return Object.keys(obj).filter((k) => {
    switch (true) {
      case Array.isArray(obj[k]) && obj[k].length === 0:
      case obj[k] === undefined:
      case obj[k] === null:
        // eslint-disable-next-line array-callback-return
        return;
      default:
        return obj[k];
    }
  });
};
