export enum FETCH_FORGOT_PASSWORD_LIST {
  REQUEST = "FETCH_FORGOT_PASSWORD_REQUEST",
  SUCCESS = "FETCH_FORGOT_PASSWORD_SUCCESS",
  ERROR = "FETCH_FORGOT_PASSWORD_ERROR",
  RESET = "FETCH_FORGOT_PASSWORD_RESET",
}

export interface ForgotPasswordData {
  data: Object;
  status_message: string;
}

export interface ForgotPasswordReducer {
  loading: boolean;
  data: ForgotPasswordData | any;
  error: boolean;
  isSuccess: boolean;
}

export const initialForgotPasswordState: ForgotPasswordReducer = {
  loading: false,
  data: [],
  error: false,
  isSuccess: false,
};
