import React from "react";
import classNames from "classnames";

import { MENU_HEIGHT, IListProps } from "Modules/DS/Menu/@types";

import styles from "./index.module.scss";

export const List = ({ children, height = MENU_HEIGHT.SMALL }: IListProps): JSX.Element => {
  const listClassName = classNames(styles.container, { [styles[height]]: height });

  return (
    <ul className={listClassName} onClick={(e) => e.stopPropagation()}>
      {children}
    </ul>
  );
};
