import React, { useState } from "react";
import PropTypes from "prop-types";
import Search from "../search";
import SelectItem from "../selectItem";
import SelectDataItem from "../selectItem/selectDataItem";
import { removeDataObject, searchInTeamList } from "../../utility/index";
import SelectedAvatar from "../selectedAvatar";
import "./SelectMembers.scss";
import LoadingIcon from "Modules/loading";

const SelectMembersList = ({
  list,
  isTeam,
  singleSelection,
  onChange = () => {
    /* do nothing*/
  },
  title,
  formError,
  subTitle,
  loading,
  searchPlaceholder,
  isSendMoneyForMMDashboardV2 = false,
  initialSelectedMembers = [],
}) => {
  const [searchInput, setSearchValue] = useState("");
  const [selectedMembers, setSelectedMembers] = useState(initialSelectedMembers || []);

  const filteredMembersRaw = searchInTeamList(searchInput, list || []);

  const colleaguesData = filteredMembersRaw.map((item) => <SelectDataItem data={item} key={item.id} isTeam={isTeam} />);

  const onRemoveSelectedMember = (member) => {
    const data = removeDataObject(selectedMembers, member);
    setSelectedMembers(data);
    handleChange(selectedMembers);
  };

  const handleChange = (member) => {
    if (singleSelection && selectedMembers.length > 0) {
      let data = member.filter((item) => item.id !== selectedMembers[0].id);
      setSelectedMembers(data);
      onChange(data);
    } else {
      setSelectedMembers(member);
      onChange(member);
    }
  };
  return (
    <div className={`select-members ${isSendMoneyForMMDashboardV2 ? "select-members__matchmove-dashboardv2" : ""}`}>
      <div className="select-members__search">
        {!isSendMoneyForMMDashboardV2 && <h4 className={"title"}>{title}</h4>}
        <Search getValue={(val) => setSearchValue(val)} placeholder={searchPlaceholder} />
      </div>
      {formError && <p className={"select-members--error"}>Please select the member!</p>}
      {!singleSelection && (
        <div className={"select-members__selected-members"}>
          {selectedMembers.map((member) => (
            <SelectedAvatar
              isTeam={isTeam}
              data={member}
              key={member.id}
              action={() => onRemoveSelectedMember(member)}
            />
          ))}
        </div>
      )}
      <div className={"select-members__members"}>
        {!isSendMoneyForMMDashboardV2 && <h4>{subTitle ? subTitle : "MEMBERS"}</h4>}
        {loading ? (
          <LoadingIcon />
        ) : (
          <SelectItem data={colleaguesData} selectedData={selectedMembers} action={handleChange} />
        )}
      </div>
    </div>
  );
};

SelectMembersList.propTypes = {
  list: PropTypes.array,
  title: PropTypes.string,
  isTeam: PropTypes.bool,
  onChange: PropTypes.func,
  formError: PropTypes.bool,
  singleSelection: PropTypes.bool,
  subTitle: PropTypes.any,
  loading: PropTypes.bool,
  isSendMoneyForMMDashboardV2: PropTypes.bool,
  initialSelectedMembers: PropTypes.array,
};

export { SelectMembersList };
