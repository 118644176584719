import React, { useState } from "react";
import { Button } from "@spenmo/splice";
import { Input } from "antd";
import { useSelector } from "react-redux";

import { ModalComponent } from "Modules/DS/Modal";
import { TITLE_TYPE } from "Modules/DS/Modal/types";

import { IEmailReportModal } from "Views/Transaction/@types";
import { SMART_CSV_LIMIT_TRANSACTION, CSV_EXPORT_LIMIT } from "Views/Transaction/Constants";

import styles from "./EmailReport.module.scss";

export const EmailReportModal = ({ title, limit, sendEmail }: IEmailReportModal) => {
  const [email, setEmail] = useState("");
  const userEmail: string = useSelector((state: any) => state.userInfoReducer?.data?.payload?.user?.email);

  const defaultValue: string = email || userEmail;

  const onEmailInput: React.ChangeEventHandler<HTMLInputElement> = (e) => setEmail(e.target.value);

  return (
    <>
      <ModalComponent.CloseButton />
      <ModalComponent.Title titleType={TITLE_TYPE.withBorder}>{title} Download</ModalComponent.Title>
      <div className="modal-download-report">
        <p className={styles.caption}>
          Your download will be queued for processing{Boolean(limit) && ` as it contains more than ${limit} rows`}. Upon
          completion, you will receive the file via email instead.
        </p>
        <label htmlFor="email" className={styles.label}>
          Email
        </label>
        <Input
          id="email"
          placeholder="sample@email.com"
          maxLength={100}
          defaultValue={defaultValue}
          onChange={onEmailInput}
        />

        <div className="modal-download-report-button sp-margin-top--l">
          <Button variant="primary" onClick={() => sendEmail(defaultValue)} className={styles.button}>
            Confirm
          </Button>
        </div>
      </div>
    </>
  );
};

export const BankStatementModal = ({ sendEmail }: Pick<IEmailReportModal, "sendEmail">) => {
  return <EmailReportModal limit={SMART_CSV_LIMIT_TRANSACTION} sendEmail={sendEmail} title="Bank Statement" />;
};

export const CsvExportModal = ({ sendEmail }: Pick<IEmailReportModal, "sendEmail">) => {
  return <EmailReportModal limit={CSV_EXPORT_LIMIT} sendEmail={sendEmail} title="Raw CSV" />;
};

export const DownloadReceipt = ({ sendEmail }: Pick<IEmailReportModal, "sendEmail">) => {
  return <EmailReportModal sendEmail={sendEmail} title="Receipt" />;
};
