import React from "react";
import Modal from "Modules/DS/Modal";
import styles from "./DeleteDraftModal.module.scss";
import { Title, CloseButton } from "Modules/DS/Modal/Components";
import { discardEdit } from "assets/img";
import Button from "Modules/button";

const DeleteDraftModal = ({ visible, setVisible = (state: boolean) => {}, action = () => {} }) => {
  return (
    <Modal
      className={styles.deleteModal}
      visible={visible}
      close={() => {
        setVisible(false);
      }}
    >
      <Title>Delete Draft?</Title>
      <CloseButton fixToTop />
      <div className={styles.deleteModalContainer}>
        <img className={styles.deleteModalIcon} src={discardEdit} alt="edit icon" />
        <p className={styles.deleteModalText}>Deleted drafts cannot be restored. Do you want to delete this draft?</p>
        <Button
          className={styles.deleteModalButton}
          action={() => {
            setVisible(false);
          }}
        >
          Continue Editing
        </Button>
        <Button
          className={styles.deleteModalButton}
          textButton
          action={() => {
            action();
            setVisible(false);
          }}
        >
          Delete Draft
        </Button>
      </div>
    </Modal>
  );
};

export default DeleteDraftModal;
