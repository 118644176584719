import React from "react";
import PropTypes from "prop-types";
import { Form, Input } from "antd";

import Icon from "Modules/icons";
import Button from "Modules/button";

import LoaderIcon from "Views/State/Loading/LoaderIcon";

import { zendeskLogo } from "assets/img";
import "./ContactForm.scss";

const ContactTicketForm = ({ onFinish, loading, automatedMsg = "" }) => {
  const { TextArea } = Input;

  return (
    <Form className="form-container" onFinish={onFinish} colon={false} requiredMark={false}>
      <p>
        Sorry, we aren’t online at the moment. <br />
        Leave a message and we’ll get back to you.
      </p>
      <Form.Item
        label="What do you want to talk about?"
        name="description"
        className="description-field"
        rules={[{ required: true, message: "Please enter the message!" }]}
        initialValue={automatedMsg}
      >
        <TextArea
          defaultValue={automatedMsg}
          placeholder="Hi. I have a question about pricing."
          autoSize={{ minRows: 5, maxRows: 5 }}
        />
      </Form.Item>
      <Form.Item className="form-footer">
        <div className="form-footer-container">
          <a
            href="https://www.zendesk.com/service/messaging/live-chat-widget/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <Icon src={zendeskLogo} alt="zendesk" />
          </a>
          <Button icon={loading ? <LoaderIcon /> : <></>} text={!loading && "Send message"} />
        </div>
      </Form.Item>
    </Form>
  );
};

ContactTicketForm.propTypes = {
  close: PropTypes.func,
  onFinish: PropTypes.func,
  loading: PropTypes.bool,
  automatedMsg: PropTypes.string,
};

export default ContactTicketForm;
