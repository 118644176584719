import * as React from "react";
import QuickLinks from "Views/Home/Components/QuickLinks";
import MyCards from "Views/Home/Components/MyCards";
import PendingActions from "Views/Home/Components/PendingActions";
import ActivateCardBanner from "Views/Wallet/UpdateCardActivation/ActivateCardBanner";
import useCheckOrgConfigs from "customHooks/useCheckOrgConfigs";
import { CARD_VENDOR_TYPE } from "constants/QuickAccess.constant";
import { PRODUCT_NAME } from "Redux/ModularProduct";

import { getIsCardVendorNium } from "Views/Home/Utilites";
import useCheckFeatureStatus from "customHooks/featureCheck";
import classNames from "classnames";
import { SPLIT_NAMES, SPLIT_TREATMENT_TYPES } from "Redux/splitio/constants";
import { CompactDesignProps } from "./types";
import "./index.scss";

const CompactDesign = ({ userData, teamData }: CompactDesignProps) => {
  const createWalletTreatment = useCheckFeatureStatus(SPLIT_NAMES.admin_wallet_activation);
  const userInfo = userData?.payload?.user;
  const isCardVendorNium = getIsCardVendorNium();

  const showWalletActivationBanner = userData?.payload?.user?.is_wallet_activation_shown || false;
  const showActivateWallet = () => {
    if (showWalletActivationBanner && createWalletTreatment === SPLIT_TREATMENT_TYPES.ON) {
      return <ActivateCardBanner />;
    }
    return <MyCards userInfo={userInfo} teamData={teamData} />;
  };
  const isCardVendorNone = useCheckOrgConfigs(PRODUCT_NAME.CARD_VENDOR) === CARD_VENDOR_TYPE.NONE;

  const _classNames = classNames("compact-design-container", {
    "compact-design-container--modular-model": isCardVendorNone,
  });

  return (
    <div className={_classNames}>
      <div className="compact-design-container__first-column">
        <QuickLinks
          isCardVendorNone={isCardVendorNone}
          userInfo={userInfo}
          teamData={teamData}
          isCardVendorNium={isCardVendorNium}
        />
        {!isCardVendorNone && showActivateWallet()}
      </div>
      <PendingActions isModular={isCardVendorNone} />
    </div>
  );
};

export default CompactDesign;
