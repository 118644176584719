import React from "react";
import styles from "./index.module.scss";
import { ModalComponent } from "Modules/DS/Modal";
import { greyCloseIcon } from "assets/img";
import { TITLE_TYPE } from "Modules/DS/Modal/types";
import Icon from "Modules/icons";
import { Button } from "antd";
import { EligibilityValidationErrors } from "../constant";
import TeamActionsWarning from "Views/Team/TeamActionsWarning";
import { IEligibilityValidationErrors } from "../type";
import { useDeleteTeamModalClose } from "Views/Team/hooks/UseDeleteTeamModalClose";

const EligibilityErrorModal = ({ title, errors }: { title: string; errors: Array<{ key: string }> }) => {
  const { close } = useDeleteTeamModalClose();
  const eligibilityErrors = errors?.map((error) => error.key);
  const isValidationErrorPresent = (validationError: IEligibilityValidationErrors) =>
    eligibilityErrors.includes(validationError.key);

  return (
    <>
      <ModalComponent.CloseButton fixToTop src={greyCloseIcon} />
      <ModalComponent.Title titleType={TITLE_TYPE.withBorder}>
        <p className={`${styles.title}`}>{title}</p>
      </ModalComponent.Title>

      <section className={`${styles.body}`}>
        {EligibilityValidationErrors()
          .filter(isValidationErrorPresent)
          .map((eligibilityValidationError) => {
            return (
              <TeamActionsWarning
                key={eligibilityValidationError.key}
                icon={<Icon key={eligibilityValidationError.key} src={eligibilityValidationError.icon} />}
                contentHeading={`${eligibilityValidationError.title}`}
                contentDescription={`${eligibilityValidationError.subtitle}`}
              />
            );
          })}
      </section>

      <div className={`${styles["footer"]}`}>
        <Button className={`${styles["action-button"]}`} onClick={close}>
          Got It
        </Button>
      </div>
    </>
  );
};

export default EligibilityErrorModal;
