import React, { useState, useRef } from "react";

import { trackEvent } from "utility/analytics";

import { SpenmoSupportContext, SUPPORT_STATUS, analyticsEvent } from "Views/SpenmoSupport/common";

const SpenmoSupportProvider = ({ children }) => {
  const supportChatRef = useRef(null);
  const supportPopupRef = useRef(null);

  const [shifting, setShifting] = useState(false);
  const [showSupportPopup, setShowSupportPopup] = useState(false);
  const [showSupportChat, setShowSupportChat] = useState(false);
  const [supportStatus, setSupportStatus] = useState(SUPPORT_STATUS.OFFLINE);

  const toggleChat = () => {
    setShowSupportChat(!showSupportChat);
  };

  const trackAnalytic = (event) => {
    const targetId = event && event.target.id;
    const isTargetIdValid = analyticsEvent[targetId];
    targetId && isTargetIdValid && trackEvent(isTargetIdValid);
  };

  const toggleSupport = (event) => {
    trackAnalytic(event);
    setShowSupportPopup(!showSupportPopup);
  };

  const toggleSupportAndChat = (event) => {
    toggleSupport(event);
    toggleChat();
  };

  const context = {
    showSupportPopup,
    setShowSupportPopup,
    showSupportChat,
    setShowSupportChat,
    supportStatus,
    setSupportStatus,
    toggleChat,
    toggleSupport,
    toggleSupportAndChat,
    setShifting,
    shifting,
    supportChatRef,
    supportPopupRef,
  };

  return <SpenmoSupportContext.Provider value={context}>{children}</SpenmoSupportContext.Provider>;
};

export default SpenmoSupportProvider;
