import * as React from "react";
import { Button } from "antd";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createCardFunc } from "Redux/Actions";
import { useIsManagerOrAdmin, useIsLoggedInUserUnverified } from "customHooks/Card/index";
import { sendCardRequest } from "Redux/DataCalls/Cards.api.js";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import { getAddCardTypeConfig, AddCardTypes } from "Views/Card/Add/Config";
import { CARD_TYPE } from "Views/Card/types";
import CardTypeSelectionModal from "Views/Card/Add/TypeSelectionModal";
import CreateOrRequestModal from "Views/Card/CreateOrRequest";
import ActivateCardModal from "Views/Card/Add/ActivateCardModal";
import PhysicalCardEligibilityModal from "Views/Card/Add/PhysicalCardEligibility";
import UnverifiedUserHaltModal from "Views/Card/Add//UnverifiedUserHaltModal";
import { trackEvent } from "utility/analytics";
import { AddCardEventNames, CardCreationEventNames } from "Views/Card/Analytics";
import usePermissionCheck from "Permission/usePermissionCheck";
import { ADD_CARD_PERMISSION_PARAMS } from "Views/Card/Permission";
import { getCookieValue, cookieNames } from "utility/CookieHelper";
import { showSuccessNotification, showErrorNotification } from "Views/Card/Components/Notification";

import "Views/Card/Add/index.scss";

interface IAddCardProps {
  onFinish: (isAdminOrManager: boolean) => void;
  buttonComponent?: JSX.Element | null;
}

const AddCard = ({ onFinish, buttonComponent = null }: IAddCardProps) => {
  const IS_ALLOWED_ADD_CARD: any = usePermissionCheck(ADD_CARD_PERMISSION_PARAMS);
  const isLoggedInUserUnverified = useIsLoggedInUserUnverified();
  const history = useHistory();
  const dispatch = useDispatch();
  const [isAdmin, isManager] = useIsManagerOrAdmin();

  const { data: userData, loading: userDataLoading = false } = useSelector(
    (reduxState: any) => reduxState?.userInfoReducer || {}
  );
  const cardVendor: string = getCookieValue(cookieNames.USER_CARD_VENDOR);

  const { is_wallet_user: isWalletUser = false } = userData?.payload?.user || {};

  const [showAddCardTypeSelectionModal, setShowAddCardTypeSelectionModal] = React.useState<boolean>(false);
  const [cardRequestInProgress, setCardRequestInProgress] = React.useState<boolean>(false);
  const [addCardType, setAddCardType] = React.useState<AddCardTypes | null>(null);
  const [showPhysicalCardEligibilityCheckModal, setShowPhysicalCardEligibilityCheckModal] =
    React.useState<boolean>(false);

  const isManagerOrAdmin = isAdmin || isManager;

  const onCreateOrRequestConfirm = async (
    payload: { [k: string]: string | number },
    isAdminOrManagerInSelectedTeam: boolean,
    cardType: CARD_TYPE
  ) => {
    if (isAdminOrManagerInSelectedTeam && cardType !== CARD_TYPE.PHYSICAL) {
      dispatch(createCardFunc(cardType?.toLowerCase(), payload));
      setAddCardType(null);
    } else {
      setCardRequestInProgress(true);
      try {
        const response = await sendCardRequest(payload);
        if (response?.data?.status === HTTP_STATUS_CODE.OK) {
          const { status_message } = response?.data?.payload || {};
          showSuccessNotification(status_message);
          setAddCardType(null);
          onFinish(isAdminOrManagerInSelectedTeam);
        } else {
          const { status_message = "Oops! Something went wrong, Please try again later." } =
            response?.data?.payload || {};
          showErrorNotification(status_message);
        }
      } catch (error) {
        showErrorNotification("Oops! Something went wrong, Please try again later.");
      }
      setCardRequestInProgress(false);
    }
  };

  const showCreateOrRequestModal = () =>
    addCardType === AddCardTypes.CreateVirtualCard ||
    addCardType === AddCardTypes.RequestVirtualCard ||
    addCardType === AddCardTypes.RequestPhysicalCard;

  const handleAddCardButtonClick = () => {
    setShowAddCardTypeSelectionModal(true);
    trackEvent(AddCardEventNames.CARD_ADD_CARD_CLICKED);
  };

  const getAddCardButton = () => {
    if ((IS_ALLOWED_ADD_CARD || isWalletUser) && !userDataLoading) {
      return buttonComponent ? (
        <div onClick={handleAddCardButtonClick} className="">
          {buttonComponent}
        </div>
      ) : (
        <Button className="add-card__button" onClick={handleAddCardButtonClick}>
          + Add Card
        </Button>
      );
    }
    return null;
  };

  const trackTypeSelection = (type: AddCardTypes) => {
    if (type === AddCardTypes.ActivatePhysicalCard) {
      trackEvent(AddCardEventNames.ACTIVATE_CARD_CLICKED, {
        card_activate_event_source: history?.location?.pathname === "/cards" ? "Card Page" : "Home",
      });
    } else {
      const isVirtualCard = type === AddCardTypes.CreateVirtualCard || type === AddCardTypes.RequestVirtualCard;
      trackEvent(AddCardEventNames.CARD_REQUEST_CARD_CLICKED, {
        is_virtual_card: isVirtualCard,
        is_creation_button: type === AddCardTypes.CreateVirtualCard,
      });
    }
  };

  const onAddCardTypeSelection = (type: AddCardTypes) => {
    /** If the user in not kyc approved and trying to
     *  request physical or virtual card then we need block
     *  the user and ask him to do the verfication first.
     *  We ignore the above condition, if user is not a wallet user
     *  because we show activate card feature modal if user is not
     *  a wallet user.
     */
    const blockRequestFlowForUnverifiedUser =
      type !== AddCardTypes.ActivatePhysicalCard &&
      type !== AddCardTypes.CreateVirtualCard &&
      isWalletUser &&
      isLoggedInUserUnverified;
    if (blockRequestFlowForUnverifiedUser) {
      setAddCardType(AddCardTypes.UnverifedUserCardRequest);
    } else if (type === AddCardTypes.RequestPhysicalCard) {
      setShowPhysicalCardEligibilityCheckModal(true);
    } else {
      setAddCardType(type);
    }
    trackTypeSelection(type);
    setShowAddCardTypeSelectionModal(false);
  };

  const onCreateOrRequestModalClose = () => {
    setAddCardType(null);
    trackEvent(CardCreationEventNames.CARD_CREATION_BUTTON_CLICKED, {
      card_creation_event_action: "Cancel",
    });
  };

  return (
    <>
      {getAddCardButton()}
      {showAddCardTypeSelectionModal && (
        <CardTypeSelectionModal
          cardTitle={isManagerOrAdmin ? "Create or Activate Card" : "Request or Activate Card"}
          onClose={() => setShowAddCardTypeSelectionModal(false)}
          addCardTypes={getAddCardTypeConfig(isManagerOrAdmin, cardVendor)}
          onClick={onAddCardTypeSelection}
        />
      )}
      {showCreateOrRequestModal() && (
        <CreateOrRequestModal
          onClose={onCreateOrRequestModalClose}
          isManagerOrAdmin={isManagerOrAdmin}
          cardType={
            addCardType === AddCardTypes.CreateVirtualCard || addCardType === AddCardTypes.RequestVirtualCard
              ? CARD_TYPE.VIRTUAL
              : CARD_TYPE.PHYSICAL
          }
          onConfirm={onCreateOrRequestConfirm}
          cardRequestInProgress={cardRequestInProgress}
        />
      )}
      {addCardType === AddCardTypes.ActivatePhysicalCard && <ActivateCardModal onClose={() => setAddCardType(null)} />}
      {showPhysicalCardEligibilityCheckModal && (
        <PhysicalCardEligibilityModal
          onClose={() => {
            setAddCardType(null);
            setShowPhysicalCardEligibilityCheckModal(false);
          }}
          showCardRequestFlow={() => {
            setAddCardType(AddCardTypes.RequestPhysicalCard);
          }}
        />
      )}
      {addCardType === AddCardTypes.UnverifedUserCardRequest && (
        <UnverifiedUserHaltModal onClose={() => setAddCardType(null)} />
      )}
    </>
  );
};

export default AddCard;
