import {
  FETCH_CARD_ACTION_REQUEST,
  FETCH_CARD_ACTION_SUCCESS,
  FETCH_CARD_ACTION_FAILURE,
} from "../Actions/cardEditAction";
import { userInitialState } from "../InitialState";

export const cardEditReducer = (state = userInitialState, action) => {
  switch (action.type) {
    case FETCH_CARD_ACTION_REQUEST:
      return {
        ...state,
        data: action.data,
        loading: true,
      };
    case FETCH_CARD_ACTION_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
      };
    case FETCH_CARD_ACTION_FAILURE:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: true,
      };
    default:
      return state;
  }
};
