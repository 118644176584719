import React from "react";
import Modal, { ModalComponent } from "Modules/DS/Modal";
import IButton from "Modules/button";
import { TITLE_TYPE } from "Modules/DS/Modal/types";
import { documentPending, errorIcon } from "assets/img";

import styles from "./styles.module.scss";
import { PrimaryButton } from "Modules/DS/SidePanel/components/Buttons/PrimaryButton";
import { ESidePanelButtonType } from "Modules/DS/SidePanel";

interface IProgressModal {
  visible: boolean;
  errorMessage?: string;
  tryAgainBtnClickHandler?: () => void;
}

const ProgressModal = ({ visible, errorMessage, tryAgainBtnClickHandler }: IProgressModal) => {
  return (
    <Modal visible={visible} close={() => {}} className={styles.progressImport}>
      <ModalComponent.Title titleType={TITLE_TYPE.withBorder}>Importing Bills</ModalComponent.Title>
      <section className={styles.body}>
        <img src={errorMessage ? errorIcon : documentPending} alt="document-pending" className={styles.image} />
        <p data-testid={errorMessage ? "error-msg" : "loading-msg"}>{errorMessage || "Importing bills to Spenmo..."}</p>
        <div className={styles.button}>
          {errorMessage ? (
            <PrimaryButton
              text="Try again"
              type={ESidePanelButtonType.PRIMARY}
              id="tryAgain"
              action={tryAgainBtnClickHandler}
              disabled={false}
            />
          ) : (
            <IButton text="" loading btnStyle="white-orange-with-border" className={styles.disabled} />
          )}
        </div>
      </section>
    </Modal>
  );
};

export default ProgressModal;
