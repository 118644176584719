import React from "react";
import { useAppSelector } from "hook";

import { Multiple } from "../More";

import { ITitleWithID } from "Views/Transaction/@types";
import { transactionType } from "../transactionType";

export const TransactionTypeFilter = ({
  id,
  appliedKey = "id",
  handleSelectFilter,
  clearFilter,
}: {
  id: string;
  appliedKey?: keyof ITitleWithID;
  handleSelectFilter: (values: string[], type: string) => void;
  clearFilter;
}) => {
  const label = "Transaction Type";

  const loading: boolean = useAppSelector((state: any) => state.userInfoReducer?.loading);

  const data = transactionType;

  return (
    <Multiple<ITitleWithID>
      id={id}
      searchable
      label={label}
      loading={loading}
      displayKey="title"
      appliedKey={appliedKey}
      handleSelectFilter={handleSelectFilter}
      clearMoreFilter={clearFilter}
    >
      {data}
    </Multiple>
  );
};
