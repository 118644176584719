import React, { useEffect } from "react";
import styles from "./index.module.scss";
import ModalWrapper from "Modules/DS/Modal";
import ConfirmationModal from "Views/Team/Delete/ConfirmationModal";
import LoadingWithInfo from "Views/Team/LoadingWithInfo";
import FailedModal from "Views/Team/Delete/FailedModal";
import EligibilityErrorModal from "Views/Team/Delete/EligibilityErrorModal";
import { useTeamToasterContext } from "Views/Team/Toast/context";
import { useTeamActionContext } from "Views/Team/List/Actions/context";
import { useDeleteTeamModalClose } from "Views/Team/hooks/UseDeleteTeamModalClose";
import { useDeleteTeamContext, useDeleteTeamEligibilityContext } from "Views/Team/Delete/context";
import { TOASTER_STATUS_TYPE } from "Modules/DS/Toaster/types.d";

const DeleteTeam = () => {
  const { close: closeDeleteTeamModal } = useDeleteTeamModalClose();
  const { setToaster } = useTeamToasterContext();
  const { state: teamActionContext } = useTeamActionContext();
  const { deleted: deleteTeamSuccess, loading: deleteTeamLoading, error: deleteTeamError } = useDeleteTeamContext();
  const { loading: eligibilityCheckLoading, isEligible, data, checkEligibility } = useDeleteTeamEligibilityContext();
  const { selectedTeam } = teamActionContext;

  useEffect(() => {
    if (selectedTeam?.id) {
      checkEligibility(selectedTeam.id);
    }
  }, []);

  useEffect(() => {
    if (deleteTeamSuccess) {
      setToaster({
        visible: true,
        status: TOASTER_STATUS_TYPE.SUCCESS,
        message: `${selectedTeam.team_name} Budget has been successfully deleted`,
      });
      closeDeleteTeamModal();
    }
  }, [deleteTeamSuccess]);

  if (!selectedTeam) return null;

  if (eligibilityCheckLoading) {
    return (
      <LoadingWithInfo
        title="Checking eligibility..."
        subTitle={`Please wait while we check for pending items from ${selectedTeam?.team_name} budget`}
      />
    );
  }

  if (deleteTeamLoading) {
    return (
      <LoadingWithInfo
        title="Deleting in progress..."
        subTitle={`Please wait while we are deleting ${selectedTeam.team_name} Budget`}
      />
    );
  }

  if (deleteTeamError) {
    return (
      <FailedModal teamName={selectedTeam.team_name} title={`Failed to delete ${selectedTeam.team_name} Budget`} />
    );
  }

  const modalContent = (() => {
    if (!isEligible) {
      return (
        <EligibilityErrorModal
          errors={data.payload.validationErrors}
          title={`Failed to delete ${selectedTeam.team_name} Budget`}
        />
      );
    }

    if (isEligible) {
      return (
        <ConfirmationModal
          teamId={selectedTeam.id}
          teamName={selectedTeam.team_name}
          title={`You are about to delete ${selectedTeam.team_name} Budget`}
        />
      );
    }
  })();

  return (
    <div className={`${styles.container}`}>
      <ModalWrapper className={`${styles.modal}`} visible={true} close={closeDeleteTeamModal}>
        {modalContent}
      </ModalWrapper>
    </div>
  );
};

export default DeleteTeam;
