import React from "react";
import dayjs from "dayjs";
import { Typography } from "@spenmo/splice";

import DatePicker from "Modules/datePicker";

import { IDateRange } from "Views/Bills/V2/@types";
import { BILLS_QUERY_PARAMS } from "Views/Bills/V2/constants";

const DateRange = ({ startDate, endDate, handleOnApply, showDatesTillToday = false }: IDateRange) => {
  return (
    <>
      <div>
        <label htmlFor={BILLS_QUERY_PARAMS.START_DATE}>
          <Typography size="s" variant="body-content" weight={600}>
            Start Date
          </Typography>
        </label>
        <DatePicker
          id={BILLS_QUERY_PARAMS.START_DATE}
          picker="date"
          showPastDates
          popupStyle={{ zIndex: "var(--layer-5)" }}
          initialValue={dayjs.unix(startDate).format("YYYY-MM-DD")}
          placeholder="Select Date"
          action={(date: string) => handleOnApply(date, BILLS_QUERY_PARAMS.START_DATE)}
        />
      </div>
      <div>
        <label htmlFor={BILLS_QUERY_PARAMS.END_DATE}>
          <Typography size="s" variant="body-content" weight={600}>
            End Date
          </Typography>
        </label>
        <DatePicker
          id={BILLS_QUERY_PARAMS.END_DATE}
          picker="date"
          showPastDates
          popupStyle={{ zIndex: "var(--layer-5)" }}
          initialValue={dayjs.unix(endDate).format("YYYY-MM-DD")}
          placeholder="Select Date"
          action={(date: string) => handleOnApply(date, BILLS_QUERY_PARAMS.END_DATE)}
          disabledDate={(current) => showDatesTillToday && current && current > dayjs().endOf("day")}
        />
      </div>
    </>
  );
};
export default DateRange;
