export enum TABS {
  SETTLED = "Settled",
  ALL = "All",
  BILLS = "Bills",
  CARDS = "Cards",
  SCHEDULED = "Scheduled Internal Transactions",
  TEAM = "Team",
}

export enum BILLS_SUB_TABS {
  ALL = "All",
  TO_APPROVE = "Approval Required",
  EXTRACTING = "Extracting",
}

export enum TABS_URL {
  SETTLED = "all",
  ALL = "all",
  BILLS = "pending",
  SCHEDULED = "scheduled",
  CARDS = "cards",
}
