import {
  FETCH_TEAMS_REQUEST,
  FETCH_TEAMS_SUCCESS,
  FETCH_TEAMS_FAILURE,
  UPDATE_TEAM,
  FETCH_ADDTEAMS_FAILURE,
} from "Redux/Actions/Teams/teamDetailsAction";

import {
  CREATE_TEAM_REQUEST,
  CREATE_TEAM_SUCCESS,
  CREATE_TEAM_FAILURE,
  CREATE_TEAM_RESET,
} from "Redux/Actions/Teams/createTeamAction";

import {
  ADD_TEAM_MEMBER_REQUEST,
  ADD_TEAM_MEMBER_SUCCESS,
  ADD_TEAM_MEMBER_FAILURE,
  RESET_ADD_TEAM,
} from "Redux/Actions/Teams/addTeamMemberAction";

import {
  MOVE_TEAM_MEMBER_FAILURE,
  RESET_MOVE_TEAM_MEMBER,
  MOVE_TEAM_MEMBER_SUCCESS,
  MOVE_TEAM_MEMBER_REQUEST,
} from "Redux/Actions/Teams/moveTeamMemberAction";

import {
  FETCH_TEAMS_BY_USER,
  FETCH_TEAMS_BY_USER_FAILURE,
  FETCH_TEAMS_BY_USER_SUCCESS,
} from "Redux/Actions/Teams/teamDetailsAction";

import { userInitialState } from "Redux/InitialState";

export const teamDetailsReducer = (
  state = {
    ...userInitialState,
    selectedTeamId: null,
    createData: {},
    addMemberError: false,
    addMemberLoading: false,
    addMemberData: {},
    moveTeamMemberLoading: false,
    moveTeamMemberSuccess: false,
    moveTeamMemberFailed: false,
    userTeamLoading: false,
    userTeams: [],
  },
  action
) => {
  switch (action.type) {
    case FETCH_TEAMS_REQUEST:
    case CREATE_TEAM_REQUEST:
      return {
        ...state,
        data: action.data,
        loading: true,
        errorMessage: {},
      };
    case FETCH_TEAMS_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
        allTeams: action.allTeams,
      };
    case CREATE_TEAM_SUCCESS:
      return {
        ...state,
        createData: action.createData,
        loading: false,
        errorMessage: {},
      };
    case ADD_TEAM_MEMBER_REQUEST:
      return {
        ...state,
        addMemberLoading: true,
        addMemberData: {},
        adderror: false,
      };
    case ADD_TEAM_MEMBER_SUCCESS:
      return {
        ...state,
        addMemberLoading: false,
        addMemberData: action.data,
        adderror: false,
      };
    case ADD_TEAM_MEMBER_FAILURE:
    case FETCH_ADDTEAMS_FAILURE:
      return {
        ...state,
        addMemberLoading: false,
        addMemberData: {},
        adderror: true,
        adderrorMessage: action.data,
      };
    case FETCH_TEAMS_FAILURE:
    case CREATE_TEAM_FAILURE:
      return {
        ...state,
        addMemberLoading: false,
        // data: action.data,
        error: true,
        errorMessage: action.data,
      };
    case UPDATE_TEAM:
      return {
        ...state,
        selectedTeamId: action.data,
        teamWallet: action.wallet,
      };
    case RESET_ADD_TEAM:
      return {
        ...state,
        addMemberData: {},
        adderrorMessage: "",
        adderror: false,
      };
    case CREATE_TEAM_RESET:
      return {
        ...state,
        createData: {},
        errorMessage: "",
      };
    case MOVE_TEAM_MEMBER_REQUEST:
      return {
        ...state,
        moveTeamMemberLoading: true,
        moveTeamMemberSuccess: false,
        moveTeamMemberFailed: false,
      };
    case MOVE_TEAM_MEMBER_SUCCESS:
      return {
        ...state,
        moveTeamMemberLoading: false,
        moveTeamMemberSuccess: true,
        moveTeamMemberFailed: false,
      };
    case MOVE_TEAM_MEMBER_FAILURE:
      return {
        ...state,
        moveTeamMemberLoading: false,
        moveTeamMemberSuccess: false,
        moveTeamMemberFailed: true,
      };
    case RESET_MOVE_TEAM_MEMBER:
      return {
        ...state,
        moveTeamMemberLoading: false,
        moveTeamMemberSuccess: false,
        moveTeamMemberFailed: false,
      };
    case FETCH_TEAMS_BY_USER:
      return {
        ...state,
        userTeamLoading: true,
        userTeams: [],
      };
    case FETCH_TEAMS_BY_USER_SUCCESS:
      return {
        ...state,
        userTeamLoading: false,
        userTeams: action.data,
      };
    case FETCH_TEAMS_BY_USER_FAILURE:
      return {
        ...state,
        userTeamLoading: false,
        userTeams: [],
      };

    default:
      return state;
  }
};
