import * as React from "react";
import { useHistory } from "react-router-dom";

import { EMPLOYEES_PATH } from "constants/Routes.constants";

import EmployeeCSVUpload from "Views/Employee/Create/Page/BulkUpload/EmployeeCSVUpload";

import "./index.scss";

const BulkUpload = () => {
  const history = useHistory();

  return <EmployeeCSVUpload toggleList={() => history.replace(EMPLOYEES_PATH)} bringEmployeesLater={true} />;
};

export default BulkUpload;
