import { ReactNode } from "react";
import { Moment } from "moment";

export enum FrequencyType {
  WEEKLY = "weekly",
  FORTNIGHTLY = "fortnightly",
  MONTHLY = "monthly",
}

export type PaymentRunFields =
  | {
      frequency: FrequencyType.FORTNIGHTLY | FrequencyType.MONTHLY;
      runOn: Moment;
      isEOM?: boolean;
    }
  | {
      frequency: FrequencyType.WEEKLY;
      runOn: string;
      isEOM?: boolean;
    };

export interface PaymentRunModal {
  title: string;
  content: ReactNode;
  fetcher: Function;
}
