import React from "react";
import {
  add_bank_account_illustration,
  add_it_anyways_illustration,
  bank_account_details_illustration,
} from "assets/img/integrations/xero";

export const INTEGRATION_STATUS = {
  CONNECTED: "Connected",
  DISCONNECTED: "Disconnected",
  NOT_CONNECTED: "Not Connected",
  FAILED_TO_CONNECT: "Failed to Connect",
  PENDING_SETUP: "Pending Setup",
};

export const XERO_INTEGRATION_STEP = {
  FIRST: {
    label: "Add Spenmo as a Bank Account in Xero",
    value: 0,
  },
  SECOND: {
    label: "Map your accounts",
    value: 1,
  },
  THRID: {
    label: "Map your categories",
    value: 2,
  },
};

export const XERO_BANK_FEED_INTEGRATION_STEP = {
  FIRST: {
    label: "Add Spenmo as a Bank Account in Xero",
    value: 0,
  },
  SECOND: {
    label: "Map your accounts",
    value: 1,
  },
};

export const XERO_AUTO_SYNC_OPTIONS = [
  {
    label: (
      <>
        Cards <span className={"text--secondary"}>(When paid)</span>
      </>
    ),
    value: "xero_auto_sync_card",
  },
  {
    label: (
      <>
        Reimbursements <span className={"text--secondary"}>(When paid)</span>
      </>
    ),
    value: "xero_auto_sync_reimbursement",
  },
  {
    label: (
      <>
        Top Ups <span className={"text--secondary"}>(When credited)</span>
      </>
    ),
    value: "xero_auto_sync_topup",
  },
];

export const AddAsABankAccountStepsData = (styles) => {
  const className = styles ? styles.link : "step-card__content--link"; // TEST again after deleting integration page

  return [
    {
      key: "addBankAccount",
      illustration: add_bank_account_illustration,
      contentText: (
        <>
          Log in to your&nbsp;
          <a href="https://go.xero.com/Dashboard/" className={className} target="_blank" rel="noreferrer">
            Xero dashboard
          </a>
          &nbsp; and click <b>Accounting &gt; Bank Accounts,</b> then click the <b>Add Bank Account</b> button.
        </>
      ),
    },
    {
      key: "addItAnyway",
      illustration: add_it_anyways_illustration,
      contentText: (
        <>
          Start typing Spenmo under Find your bank field, then click <b>“Add it anyway”.</b>
        </>
      ),
    },
    {
      key: "bankAccountDetails",
      illustration: bank_account_details_illustration,
      contentText: (
        <>
          Enter <b>Bank</b> and <b>Account Name</b> as <b>Spenmo,</b> then select <b>Other</b> as account type. Make
          sure you type a unique
          <b>Account Number</b> and follow your Spenmo account&apos;s <b>Currency.</b> Once you’re done, click{" "}
          <b>Save.</b>
        </>
      ),
    },
  ];
};
export const AccountTypes = {
  spenmoAccount: "spenmoAccount",
  topUpAccount: "topUpAccount",
  xeroOrganisation: "xeroOrganisation",
};

export const SyncAccountsConfig = {
  [AccountTypes.spenmoAccount]: {
    title: "Select your Spenmo Account Code",
    subTitle: "All your Spenmo transactions will be automatically synced to this account.",
    formItemName: "xero_bank_account_id",
  },
  [AccountTypes.topUpAccount]: {
    title: "Select your Funding Bank Account",
    subTitle: "The Funding Bank Account will be used as the source for all Top Up transaction.",
    formItemName: "xero_top_up_bank_account_id",
  },
};

export const SettingsPageSyncAccountsConfig = {
  [AccountTypes.spenmoAccount]: {
    title: "spenmo account",
    subTitle: "All your Spenmo transactions are synced to this Xero account.",
  },
  [AccountTypes.topUpAccount]: {
    title: "funding bank account",
    subTitle: "This account will be used as the source account for all Top Up transactions.",
  },
  [AccountTypes.xeroOrganisation]: {
    title: "",
    subTitle: "All transactions is associated with this organisation inside Xero.",
  },
};

export const AlreadyReconciledMessage = {
  withReceipt: (accountingPartnerName) =>
    `Only the receipt will be reflected in ${accountingPartnerName}. Other changes will not be reflected since the transaction has already been reconciled. You also need to make the changes inside ${accountingPartnerName}.`,
  withoutReceipt: (accountingPartnerName) =>
    `The changes will not be reflected in ${accountingPartnerName} since the transaction has already been reconciled. You also need to make the changes inside ${accountingPartnerName}.`,
};
