import { GetOrgCountryCode, GetOrgId } from "../../utility";
import APIclient from "API/Client";

export const FETCH_BANK_TRANSFER_REQUEST = "FETCH_BANK_TRANSFER_REQUEST";
export const FETCH_BANK_TRANSFER_SUCCESS = "FETCH_BANK_TRANSFER_SUCCESS";
export const FETCH_BANK_TRANSFER_FAILURE = "FETCH_BANK_TRANSFER_FAILURE";

export const fetchBankTransferRequest = () => {
  return {
    type: FETCH_BANK_TRANSFER_REQUEST,
  };
};

export const fetchBankTransferSuccess = (data) => {
  return {
    type: FETCH_BANK_TRANSFER_SUCCESS,
    data,
  };
};

export const fetchBankTransferFailure = () => {
  return {
    type: FETCH_BANK_TRANSFER_FAILURE,
  };
};

export const getBankTransferListAction = (currency) => {
  const orgId = GetOrgId();
  const country = GetOrgCountryCode();

  return async (dispatch) => {
    dispatch(fetchBankTransferRequest());

    try {
      const { data } = await APIclient.postData(`/ms/spm-topup/v1/bank-account/get-top-up-account`, {
        orgId,
        currency,
        country,
      });

      if (data.status === 200) {
        dispatch(fetchBankTransferSuccess(data.payload?.bankAccounts || []));
      } else {
        dispatch(fetchBankTransferFailure());
      }
    } catch (error) {
      dispatch(fetchBankTransferFailure());
    }
  };
};
