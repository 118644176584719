import React, { forwardRef, useState, useEffect, useCallback, useRef, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import cn from "classnames";
import { Divider, Typography } from "antd";

import { getPendingApprovalsFunc, getOrgDetailFunc, accountingPartnerAuth } from "Redux/Actions";
import { PRODUCT_NAME } from "Redux/ModularProduct";
import useCheckOrgConfigs from "customHooks/useCheckOrgConfigs";

import { appNotification } from "Modules/appNotification/appNotification";
import Tabs, { ListData } from "Modules/DS/Tabs";
import Modal, { ModalComponent } from "Modules/DS/Modal";

import EmptyState from "Views/State/emptyState";

import CreateBills from "./CreateBills";
import LandingSoon from "./LandingSoon";
import ManageDraft from "./ManageDraft";

import { BILL_PAGE_PERMISSION_PARAMS } from "./Permission";

import { postData } from "API/Client";
import { UPLOAD_ACCEPT_TYPE, UPLOAD_MAX_SIZE, TABS } from "./const";
import { BILLS_ANALYTICS } from "./events";
import { getTabList } from "./helper";
import { GetBaseAuthObject } from "utility";
import { trackEvent } from "utility/analytics";
import usePermissionCheck from "Permission/usePermissionCheck";

import { landingSoon, uploadInvoice, greyCloseIcon, errorIcon } from "assets/img";
import styles from "./Bills.module.scss";
import ManageRecipients from "./ManageRecipients";
import SubmittedBills from "./SubmittedBills";

const { CloseButton, Title: ModalTitle } = ModalComponent;

interface ModalProps {
  title: string;
  content: string;
  ctaText: string;
  onClose(): void;
}

export const DragDrop = forwardRef<HTMLInputElement, React.HTMLProps<HTMLDivElement>>((props, inputFileRef) => {
  const { children } = props;

  const history = useHistory();
  const location = useLocation();

  const [IS_ALLOWED_SUBMIT_BILL_PAYMENT] = (usePermissionCheck(BILL_PAGE_PERMISSION_PARAMS) as boolean[]) || [];

  const allowedDragDrop = IS_ALLOWED_SUBMIT_BILL_PAYMENT;

  const dragRef = useRef(null);

  const [modalProps, setModalProps] = useState<ModalProps | undefined>();
  const [isOnDrag, setIsOnDrag] = useState(false);

  // Does not know how to test drag & drop a file
  /* istanbul ignore next */
  const handleDragOver = (e) => {
    if (!allowedDragDrop) return;
    // prevent browser drop behavior
    e.preventDefault();
    const dt = e.dataTransfer;

    if (dt.types && (dt.types.indexOf ? dt.types.indexOf("Files") != -1 : dt.types.contains("Files"))) {
      if (!isOnDrag) {
        setIsOnDrag(true);
      }

      if (dragRef.current) {
        clearTimeout(dragRef.current);
      }
    }
  };

  // Does not know how to test drag & drop a file
  /* istanbul ignore next */
  const handleDragLeave = () => {
    if (!allowedDragDrop) return;

    dragRef.current = setTimeout(() => {
      setIsOnDrag(false);
    }, 25);
  };

  // Does not know how to test drag & drop a file
  /* istanbul ignore next */
  const handleFileDrop = (e) => {
    if (!allowedDragDrop) return;
    // prevent browser drop behavior
    e.preventDefault();

    setIsOnDrag(false);
    handleFileChange(e.dataTransfer.files);
  };

  const handleFileChange = (uploadFiles: HTMLInputElement["files"]) => {
    if (!allowedDragDrop) return;

    let files = Array.from(uploadFiles);
    let bigFiles = 0;
    let unknownType = 0;

    const showErrorNotification = () => {
      if (bigFiles) {
        appNotification.error({
          message: `${bigFiles} can’t be processed due to unsupported file size (max 10 MB).`,
        });
      }

      if (unknownType) {
        appNotification.error({
          message: `${unknownType} can’t be processed due to unsupported format as we only accept PDF, PNG, JPG.`,
        });
      }
    };

    // filter by size
    files = files.filter((file) => {
      const isBigFiles = file.size <= UPLOAD_MAX_SIZE;

      if (!isBigFiles) bigFiles++;

      return isBigFiles;
    });

    // filter by type
    files = files.filter((file) => {
      const isAcceptedType = UPLOAD_ACCEPT_TYPE.includes(file.type);

      if (!isAcceptedType) unknownType++;

      return isAcceptedType;
    });

    if (!files.length) {
      showErrorNotification();

      return;
    }

    const formData = new FormData();
    files.forEach((file) => {
      formData.append("files", file);
    });

    // hard copy state
    const prevState = {
      ...(location.state as object),
    };

    postData("/ms/spm-disbursement/v1/bill/draft/upload", formData)
      .then((res) => {
        const { data } = res;
        if (data.status !== 200) {
          throw new Error(String(res.status));
        }

        appNotification.success({
          message: `${files.length} of ${uploadFiles.length} are being uploaded.`,
        });

        showErrorNotification();

        history.push({
          pathname: TABS.MANAGE_DRAFT.link,
          state: {
            ...prevState,
            isUploading: true,
          },
        });
      })
      .catch((err) => {
        const message = err?.response?.data?.error?.message;
        if (message) {
          setModalProps({
            title: "Limit Exceeded",
            content: message,
            ctaText: "Got It",
            onClose: () => setModalProps(undefined),
          });
        } else {
          history.push({
            pathname: TABS.MANAGE_DRAFT.link,
            state: {
              ...prevState,
              isUploading: true,
            },
          });

          appNotification.error({
            message: "An error has occured. Please try again.",
          });
        }
      })
      .finally(() => {
        // removing isUploading state
        history.push({
          state: {
            ...prevState,
          },
        });
      });
  };

  if (!allowedDragDrop) {
    return <div className={styles.container}>{children}</div>;
  }

  return (
    <div className={styles.container} onDragOver={handleDragOver} onDragLeave={handleDragLeave} onDrop={handleFileDrop}>
      <div className={cn(styles.dropContainer, { [styles.hide]: !isOnDrag })}>
        <div className={styles.dropZone}>
          <div className={styles.dropContent}>
            <img src={uploadInvoice} alt="upload invoice" width={120} height={120} />
            <p className={styles.dropContentTitle}>Drop your files here to upload</p>
            <p className={styles.dropContentSubtitle}>Only PDF, PNG, JPG, HEIC, HEIF formats are accepted</p>
          </div>
        </div>
        <input
          type="file"
          data-testid="upload-file"
          accept={UPLOAD_ACCEPT_TYPE.join(", ")}
          ref={inputFileRef}
          hidden
          multiple
          onChange={(e) => handleFileChange(e.target.files)}
        />
      </div>
      {children}

      <Modal className={styles.modal} visible={Boolean(modalProps)} close={() => modalProps?.onClose()}>
        <CloseButton src={greyCloseIcon} />
        <ModalTitle>{modalProps?.title}</ModalTitle>
        <Divider />
        <img src={errorIcon} alt="error" width={120} height={120} />
        <p>{modalProps?.content}</p>
        <button className={styles.success} data-testid="modal-button" onClick={() => modalProps?.onClose()}>
          {modalProps?.ctaText}
        </button>
      </Modal>
    </div>
  );
});

const Bills = ({ tabKey = TABS.CREATE_BILLS.tabKey }) => {
  const dispatch = useDispatch();
  const { Title } = Typography;
  const isPageAvailable = useCheckOrgConfigs(PRODUCT_NAME.BILLPAY);

  const history = useHistory();
  const location = useLocation();

  const tabs = getTabList();

  const isTabFound = useMemo(() => tabs.find((tab) => tab.tabKey === tabKey), [tabs, tabKey]);

  const inputFileRef = useRef<HTMLInputElement>(null);

  const clearLocationState = useCallback(() => {
    history.replace({ ...location, state: undefined });
  }, [history, location]);

  const trackEventOnPageLoad = (eventSource: string) => {
    trackEvent(BILLS_ANALYTICS.PAGE_LOADED, {
      bill_page_event_source: eventSource,
    });
  };

  const handleChangeTab = (tabKey: string) => {
    let url = "/bills";
    let eventSource = TABS.CREATE_BILLS.eventSource;

    switch (tabKey) {
      case TABS.SUBMITTED_BILLS.tabKey: {
        url = TABS.SUBMITTED_BILLS.link;
        eventSource = TABS.SUBMITTED_BILLS.eventSource;
        break;
      }
      case TABS.MANAGE_RECIPIENTS.tabKey: {
        url = TABS.MANAGE_RECIPIENTS.link;
        eventSource = TABS.MANAGE_RECIPIENTS.eventSource;
        break;
      }
      case TABS.MANAGE_DRAFT.tabKey: {
        url = TABS.MANAGE_DRAFT.link;
        eventSource = TABS.MANAGE_DRAFT.eventSource;
        break;
      }
      default: {
        url = TABS.CREATE_BILLS.link;
        eventSource = TABS.CREATE_BILLS.eventSource;
      }
    }

    trackEventOnPageLoad(eventSource);

    history.push(url);
  };

  const renderTabContent = (tab: ListData) => {
    switch (tab.tabKey) {
      case TABS.SUBMITTED_BILLS.tabKey: {
        return <SubmittedBills />;
      }
      case TABS.MANAGE_RECIPIENTS.tabKey: {
        return <ManageRecipients />;
      }
      case TABS.MANAGE_DRAFT.tabKey: {
        return <ManageDraft onUpload={() => inputFileRef.current?.click()} />;
      }
      default: {
        return (
          <CreateBills
            onCreateFromAttachment={() => {
              inputFileRef.current?.click();
            }}
          />
        );
      }
    }
  };

  useEffect(() => {
    const currentTab = tabs.find((tab) => tab.tabKey === tabKey);
    currentTab && trackEventOnPageLoad(currentTab.eventSource);
    window.addEventListener("beforeunload", clearLocationState);
    return () => {
      window.removeEventListener("beforeunload", clearLocationState);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(getPendingApprovalsFunc({ page: 1, limit: 1 }));
    dispatch(getOrgDetailFunc(GetBaseAuthObject().orgId));
    dispatch(accountingPartnerAuth());
  }, [dispatch]);

  if (!isPageAvailable) {
    return (
      <LandingSoon
        iconSrc={landingSoon}
        iconAlt="Landing Soon"
        title="Landing Soon"
        body="Bill Payment will be available in your region soon"
      />
    );
  }

  if (!isTabFound) {
    return <EmptyState />;
  }

  return (
    <DragDrop ref={inputFileRef}>
      <Title className={styles.title}>Bill Payments</Title>
      <Tabs
        tabsList={tabs}
        content={renderTabContent}
        action={handleChangeTab}
        activeKey={tabKey}
        destroyInactiveTabPane
      />
    </DragDrop>
  );
};

export default Bills;
