import { SpendAnalysisType, SpendingSummaryType } from "Views/SubscriptionManagement/@types";

const checkIfForecastData = (spendData: SpendAnalysisType) => {
  const { forecast, actual, estimated } = spendData;
  return forecast && !actual && !estimated;
};

export const cleanBarGroups = (barGroups, data: SpendAnalysisType[]) => {
  return barGroups.map((barGroups, index) => {
    let x0 = Number(barGroups.x0);

    // Shift the single forecase bar to the middle of x1 axis
    if (checkIfForecastData(data[index])) {
      x0 -= 10;
    } else {
      // Shift actual & estimated bars to the middle of x1 axis
      x0 += 5;
    }

    return {
      ...barGroups,
      x0: String(x0),
      bars: barGroups.bars
        .filter((bar) => Boolean(bar.value))
        .map((bar) => ({
          ...bar,
          date: data[index].date,
        })),
    };
  });
};

export const generateAnalysisData: (data: any) => SpendAnalysisType[] = (data) => {
  const currentMonth = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();

  if (data.length === 0) {
    return [];
  }

  return data.map((analysis) => {
    const [year, month] = analysis.year_month.split("-");
    if (month > currentMonth && year >= currentYear) {
      return {
        date: `${year}-${month}-01`,
        forecast: Number(analysis.estimated).toFixed(2),
      };
    }

    return {
      date: `${year}-${month}-01`,
      estimated: Number(analysis.estimated).toFixed(2),
      actual: Number(analysis.actual).toFixed(2),
    };
  });
};

export const generateSpendingSummaryData: (data: any) => SpendingSummaryType = (data) => {
  return {
    activeContract: data.active_contracts,
    expiredContract: data.expired_contracts,
    upcomingSpend: {
      "0_m": {
        spend: data.upcoming_spend_1_month,
        variation: data.upcoming_variation_percent_1_month,
      },
      "3_m": {
        spend: data.upcoming_spend_3_months,
        variation: data.upcoming_variation_percent_3_months,
      },
      "6_m": {
        spend: data.upcoming_spend_6_months,
        variation: data.upcoming_variation_percent_6_months,
      },
      "12_m": {
        spend: data.upcoming_spend_12_months,
        variation: data.upcoming_variation_percent_12_months,
      },
    },
    totalSpend: {
      "0_m": {
        spend: data.total_spend_1_month,
        variation: data.total_variation_percent_1_month,
      },
      "3_m": {
        spend: data.total_spend_3_month,
        variation: data.total_variation_percent_3_month,
      },
      "6_m": {
        spend: data.total_spend_6_month,
        variation: data.total_variation_percent_6_month,
      },
      "12_m": {
        spend: data.total_spend_12_month,
        variation: data.total_variation_percent_12_month,
      },
    },
  };
};

export const accumulateTotalSpend: (data: SpendAnalysisType[]) => SpendAnalysisType[] = (data) => {
  let accumulatedTotalSpend = 0;
  return data.map((item) => {
    accumulatedTotalSpend += Number(item.actual);

    return {
      ...item,
      actual: accumulatedTotalSpend.toFixed(2),
    };
  });
};
