import { KYXErrorToaster } from "../Context";

export const errorHandler = (error: {
  response: { data: { error: { message: string }; errors: { message: string }[] } };
  message: string;
}) => {
  return KYXErrorToaster(
    error?.response?.data?.error?.message || error?.response?.data?.errors?.[0]?.message || error?.message
  );
};
