import * as React from "react";

import { ModalComponent } from "Modules/DS/Modal";
import { IContentLoader } from "Modules/DS/Modal/types";

import Failed from "Views/Employee/Delete/Modal/Failed";
import Confirm from "Views/Employee/Delete/Modal/Confirm";
import Resolve from "Views/Employee/Delete/Modal/Resolve";

import { DELETE_EMPLOYEE_MODAL_TYPES, IEmployeeModal } from "Views/Employee/types";

const _Eligibility: IContentLoader = {
  title: "Checking...",
  subtitle: "Please wait while we check for pending items from the employee",
};

const _Deletion: IContentLoader = {
  title: "Deletion in progress...",
  subtitle: "Please wait while we delete this employee’s account",
};

const useDeleteModalType = (onClose: () => void, name: string, confirmDelete: () => void): IEmployeeModal => {
  const loader = (content: IContentLoader) => <ModalComponent.ContentLoader {...content} />;

  return {
    [DELETE_EMPLOYEE_MODAL_TYPES.CHECK_ELIGIBILITY]: {
      close: () => null,
      className: "employees__modal--delete-employee__content-loader",
      children: loader(_Eligibility),
    },
    [DELETE_EMPLOYEE_MODAL_TYPES.DELETE_EMPLOYEE_PROMPT]: {
      close: onClose,
      className: "employees__modal--delete-employee__confirm",
      children: <Confirm name={name} confirmDelete={confirmDelete} onCancel={onClose} />,
    },
    [DELETE_EMPLOYEE_MODAL_TYPES.RESOLVE_PENDING_ITEMS]: {
      close: onClose,
      className: "employees__modal--delete-employee__pending",
      children: <Resolve name={name} />,
    },
    [DELETE_EMPLOYEE_MODAL_TYPES.IN_PROGRESS]: {
      close: () => null,
      className: "employees__modal--delete-employee__content-loader",
      children: loader(_Deletion),
    },
    [DELETE_EMPLOYEE_MODAL_TYPES.FAILED]: {
      close: onClose,
      className: "employees__modal--delete-employee__failed",
      children: <Failed name={name} />,
    },
  };
};

export default useDeleteModalType;
