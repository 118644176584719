import { Button, Spin } from "antd";
import Modal, { ModalComponent } from "Modules/DS/Modal";
import { TITLE_TYPE } from "Modules/DS/Modal/types";
import React, { useState } from "react";
import Icon from "Modules/icons";
import { rejectApprovalRequest } from "assets/img";
import "./index.scss";
import { LoadingOutlined } from "@ant-design/icons";
import { NavIcon } from "assets/v1.1/icons";
import { trackEvent } from "utility/analytics";

interface EventDataType {
  name: string;
  data: object;
}

interface deleteApprovalRequestProps {
  processIds: string[];
  processType: string;
  title: string;
  onCancel: () => void;
  onDelete: (object) => Promise<object>;
  showDetails: (object) => void;
  warningMessage: string;
  eventData: EventDataType;
}

const DeleteApprovalRequestModal = ({
  processIds,
  processType,
  onCancel,
  title,
  onDelete,
  showDetails,
  warningMessage,
  eventData,
}: deleteApprovalRequestProps) => {
  const loadingSpinner = <LoadingOutlined spin />;
  const [deleteReasonInput, setDeleteReasonInput] = useState<string>("");
  const [isSubmit, setIsSubmit] = useState(false);

  const handleDecline = async () => {
    const { name, data } = eventData;
    if (isSubmit) {
      return;
    }
    setIsSubmit(true);

    if (name && data) {
      trackEvent(name, data);
    }
    const response = await onDelete({
      ids: processIds,
      type: processType,
      approved: false,
      notes: deleteReasonInput?.trim(),
    });
    if (response) {
      setIsSubmit(false);
    }
  };

  return (
    <Modal
      visible={true}
      className={"approval-request-decline-modal no-animation"}
      close={onCancel}
    >
      <ModalComponent.CloseButton
        fixToTop
        src={NavIcon.Navigation.Cross.Line[24]}
      />
      <ModalComponent.Title titleType={TITLE_TYPE.withBorder}>
        <p className="approval-request-decline-modal__title">{title}</p>
      </ModalComponent.Title>
      <div className="approval-request-decline-modal__content">
        <div data-show="true">
          <div className="approval-request-decline-modal__content__info">
            <Icon
              src={rejectApprovalRequest}
              alt={"reject approval request icon"}
              action={(e) => {
                e.stopPropagation();
              }}
              className="reject-approval-request-icon"
            />
            <p dangerouslySetInnerHTML={{ __html: warningMessage }}></p>
          </div>
          <div className="approval-request-decline-modal__action-area">
            <p className="approval-request-decline-modal__action-area__label mt-15">
              Reason for Declining
            </p>
            <textarea
              placeholder={`Please provide a reason for declining this request.`}
              name="reason"
              className="approval-request-decline-modal__action-area__reason-input"
              value={deleteReasonInput}
              onChange={(e) => setDeleteReasonInput(e.target.value)}
            />

            <div className="approval-request-decline-modal__action-area__button">
              <Button
                className={`approval-request-decline-modal__action-area__button--details`}
                onClick={processIds.length === 1 ? showDetails : onCancel}
              >
                {`${processIds.length === 1 ? "See Details" : "Cancel"}`}
              </Button>
              <Button
                disabled={!deleteReasonInput?.trim()}
                className={`approval-request-decline-modal__action-area__button--decline`}
                onClick={(e) => {
                  e.stopPropagation();
                  handleDecline();
                }}
              >
                {isSubmit ? (
                  <Spin indicator={loadingSpinner} />
                ) : (
                  `Confirm to Decline`
                )}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteApprovalRequestModal;
