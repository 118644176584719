import { useHistory } from "react-router-dom";

/**
 * useRedirectionHandler
 *
 * This function returns a function that can be used to redirect the user to the path defined.
 * It makes use of the useHistory hook to access the history object of the current route and navigate to the specified route.
 *
 * const redirect = useRedirectionHandler(): () => void;
 */

export const useRedirectionHandler = (path: string) => {
  const history = useHistory();

  return () => {
    return history.push(path);
  };
};
