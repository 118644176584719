import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Button, Pause as PauseIcon, Play as ResumeIcon, Refresh as SyncIcon, Typography } from "@spenmo/splice";
import styles from "./styles.module.scss";
import useLoading from "utility/useLoading";
import {
  getSingleAccountingSettingFromCode,
  syncTransactionsOnDemand,
  updateSingleAccountingSetting,
} from "Redux/DataCalls/AccountingSetting.api";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import { useSettingContext } from "../context/SettingContext";
import { IGlobalConfig, ILastSyncedSettingProps } from "../@types";
import { formattedDate, getUserNameWhoPausedSync } from "../helper/dataMapping";
import { oneMinuteDelay, syncPauseFailureText, syncResumeFailureText } from "../helper/constant";
import { SettingLabel } from "../components";

const LastSyncedSetting = ({
  lastSyncTime,
  refetchLastSyncTime,
  accountNotFound,
  settingUpdateNotAllowed,
}: ILastSyncedSettingProps) => {
  const loggedInUserDetails = useSelector((state: any) => state?.userInfoReducer?.data?.payload?.user);
  const lastSyncErrorMessage = lastSyncTime?.bank_feed?.last_sync_err_msg || "";

  const { globalConfig, setGlobalConfig } = useSettingContext();
  const bankFeedSyncPausedConfigIndex = globalConfig.findIndex(
    (config: IGlobalConfig) => config.code === "bank_feed_sync_paused"
  );
  const bankFeedSyncPausedConfig = globalConfig[bankFeedSyncPausedConfigIndex];

  const [updateBankFeedSyncPauseSetting, pauseResumeActionLoading] = useLoading(
    updateSingleAccountingSetting,
    null,
    null,
    false
  );
  const [syncTransactionsToXero, syncNowActionLoading] = useLoading(syncTransactionsOnDemand, null, null, false);

  const [bankFeedSyncPausedDetails, setBankFeedSyncPausedDetails] = useState(null);
  const [allowBankFeedSyncing, setAllowBankFeedSyncing] = useState(true);
  const [isSyncFailed, setIsSyncFailed] = useState(false);
  const [bankFeedSyncRestrictError, setBankFeedSyncRestrictError] = useState("");

  const isBankFeedOnDemandSyncPaused = bankFeedSyncPausedConfigIndex >= 0 && bankFeedSyncPausedConfig.isEnabled;
  const disableBankFeedOnDemandFeatures = settingUpdateNotAllowed || accountNotFound || isSyncFailed;
  const syncNowButtonDisabled =
    disableBankFeedOnDemandFeatures || pauseResumeActionLoading || isBankFeedOnDemandSyncPaused;
  const bankFeedSyncErrorMessage = lastSyncErrorMessage || bankFeedSyncRestrictError;

  const getBankFeedSyncPauseStatus = async () => {
    // used to fetch the timestamp at which on-demand sync is paused
    const { data = {} } = await getSingleAccountingSettingFromCode(bankFeedSyncPausedConfig.code);
    if (Object.keys(data?.payload || {})?.length > 0) {
      const userWhoPausedSync = await getUserNameWhoPausedSync(data?.payload?.userId, loggedInUserDetails);
      setBankFeedSyncPausedDetails({ ...data?.payload, userName: userWhoPausedSync });
    }
  };

  useEffect(() => {
    if (accountNotFound) refetchLastSyncTime(); // to fetch the sync failure message
  }, [accountNotFound]);

  useEffect(() => {
    isBankFeedOnDemandSyncPaused && getBankFeedSyncPauseStatus();
  }, [globalConfig]);

  // allows the user to click the sync button again after 1min since the time it was last clicked
  const allowRestrictedButtonClick = () => {
    setTimeout(() => {
      setAllowBankFeedSyncing(true);
      setBankFeedSyncRestrictError("");
    }, oneMinuteDelay);
  };

  const syncNowBtnClickHandler = async () => {
    try {
      if (allowBankFeedSyncing) {
        const response = await syncTransactionsToXero();
        if (response?.data?.status === HTTP_STATUS_CODE.OK) {
          refetchLastSyncTime(); // used to fetch the updated last sync time
          setAllowBankFeedSyncing(false); // to restrict user from clicking the button again within a minute since it was last clicked
          allowRestrictedButtonClick();
        }
        setIsSyncFailed(false);
      } else {
        setBankFeedSyncRestrictError("You can try to sync again in 1 minute");
      }
    } catch (syncErr) {
      const errorMsg = syncErr.response?.data?.error?.message;
      const isBankAccountNotAvailable =
        errorMsg?.includes("archived") || errorMsg?.includes("not found") || errorMsg?.includes("connection error");
      if (isBankAccountNotAvailable) setIsSyncFailed(true);
      refetchLastSyncTime(); // used to fetch the sync failure reason
    }
  };

  const pauseResumeBtnClickHandler = async () => {
    try {
      const response = await updateBankFeedSyncPauseSetting({
        code: bankFeedSyncPausedConfig.code,
        isEnabled: !bankFeedSyncPausedConfig.isEnabled,
      });
      if (response?.data?.status === HTTP_STATUS_CODE.OK) {
        const updatedGlobalConfig = [...globalConfig];
        updatedGlobalConfig[bankFeedSyncPausedConfigIndex].isEnabled = !bankFeedSyncPausedConfig.isEnabled;
        if (!updatedGlobalConfig[bankFeedSyncPausedConfigIndex].isEnabled) {
          // resetting the paused data to null as sync was resumed
          setBankFeedSyncPausedDetails(null);
        }
        setGlobalConfig(updatedGlobalConfig);
      }
    } catch (error) {
      setBankFeedSyncPausedDetails({
        ...bankFeedSyncPausedDetails,
        error: isBankFeedOnDemandSyncPaused ? syncResumeFailureText : syncPauseFailureText,
      });
    }
  };

  const showError = (errorMsg: string) => (
    <Typography variant="body-content" size="s" tag="p" children={errorMsg} className={styles.error} />
  );

  return (
    <>
      <SettingLabel text="Last Synced" />
      <Typography
        variant="body-content"
        size="s"
        tag="p"
        className={styles.lastSyncedTime}
        children={
          lastSyncTime?.bank_feed?.last_synced_at
            ? `Last synced to Xero at ${formattedDate(lastSyncTime?.bank_feed?.last_synced_at)}`
            : "-"
        }
        weight={600}
      />
      <div className={styles.syncButtonContainer}>
        <Button
          onClick={syncNowBtnClickHandler}
          variant="secondary"
          size="s"
          disabled={syncNowButtonDisabled}
          loading={syncNowActionLoading}
          icon={SyncIcon}
        >
          Sync Now
        </Button>
        <Button
          onClick={pauseResumeBtnClickHandler}
          variant={syncNowButtonDisabled ? "secondary" : "tertiary"}
          size="s"
          disabled={disableBankFeedOnDemandFeatures}
          loading={pauseResumeActionLoading}
          icon={isBankFeedOnDemandSyncPaused ? ResumeIcon : PauseIcon}
        >
          {isBankFeedOnDemandSyncPaused ? "Resume" : "Pause"}
        </Button>
      </div>
      {bankFeedSyncErrorMessage && showError(bankFeedSyncErrorMessage)}
      {bankFeedSyncPausedDetails &&
        showError(
          bankFeedSyncPausedDetails?.error ||
            `Xero sychronization was paused at ${formattedDate(bankFeedSyncPausedDetails?.updatedAt)} by ${
              bankFeedSyncPausedDetails?.userName
            }`
        )}
    </>
  );
};

export default LastSyncedSetting;
