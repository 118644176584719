import * as React from "react";
import { v4 as uuidv4 } from "uuid";
import { useState, useEffect } from "react";
import { Button, Popover } from "antd";
import classNames from "classnames";
import "./ContextualMenu.scss";
import Checkbox from "../Atoms/Checkbox";
import LoaderIcon from "Views/State/Loading/LoaderIcon";
import { ContextualMenuItem, valueType, isContextualMenuItem } from "./types";
import { useClickOutside } from "customHooks/useClickOutside";
import { useScrollBottom } from "../../../customHooks/useScrollBottom";

interface ContextualMenuProps {
  children: React.ReactElement | string;
  onChange: (value: Array<valueType> | valueType) => void;
  options: Array<ContextualMenuItem>;
  values: Array<valueType> | valueType;
  defaultOpen?: boolean;
  multiple?: boolean;
  renderOption?: (option: ContextualMenuItem) => React.ReactElement | string;
  onScrollBottom?: () => void;
  isLoading?: boolean;
  checkBoxStyle?: string;
  setShowDropdown?: (flag: boolean) => void;
}

const ContextualMenu: React.FC<ContextualMenuProps> = ({
  children,
  options = [],
  onChange = () => undefined,
  values,
  defaultOpen = false,
  multiple,
  renderOption = (option: ContextualMenuItem) => option.label,
  onScrollBottom = () => undefined,
  isLoading = false,
  checkBoxStyle = "",
  setShowDropdown = () => undefined,
}: ContextualMenuProps) => {
  const [isEdit, setIsEdit] = useState<boolean>(!values);
  const [isVisible, setIsVisible] = useState<boolean>(defaultOpen);
  const [wrapperHeight, setWrapperHeight] = useState<number>(0);
  const [selected, setSelected] = useState<Array<string>>([]);
  const [valueLabel, setValueLabel] = useState<{ [key: string]: ContextualMenuItem }>({});
  const [isConfirming, setIsConfirming] = useState<boolean>(false);
  // generate static unique identifier to differentiate multiple mounted components
  const [ctxMenuId] = useState<string>(uuidv4());
  const scrollRef = useScrollBottom(onScrollBottom, 30);

  const handleChange = (optionParams: valueType | Array<valueType>) => {
    setSelected([]);
    setValueLabel({});
    setIsEdit(false);
    setIsVisible(false);
    onChange(optionParams);
  };

  const handleItemClick = (option: ContextualMenuItem) => {
    const val = option.value;
    if (multiple) {
      const newSelection = [...selected];
      const newvalueLabel = { ...valueLabel };
      if (newSelection.includes(val)) {
        newSelection.splice(newSelection.indexOf(val), 1);
        delete newvalueLabel[val];
      } else {
        newSelection.push(val);
        newvalueLabel[val] = option;
      }
      setSelected(newSelection);
      setValueLabel(newvalueLabel);
    } else {
      handleChange(option);
    }
  };

  useEffect(() => {
    if (Array.isArray(values)) {
      const newSelection = [...selected];
      const newvalueLabel = { ...valueLabel };
      values.forEach((item: ContextualMenuItem) => {
        const val = item.value;
        newSelection.push(val);
        newvalueLabel[val] = item;
      });
      setSelected(newSelection);
      setValueLabel(newvalueLabel);
    }
  }, []);

  useEffect(() => {
    if (isConfirming) {
      const selectedOptions = selected.map((value) => ({ ...valueLabel[value] }));
      handleChange(selectedOptions);
      setIsConfirming(false);
    }
  }, [isConfirming]);

  const [triggerRef] = useClickOutside(() => {
    if (multiple) setIsConfirming(true);
    setIsVisible(false);
    setShowDropdown && setShowDropdown(false);
  }, [`#popover-${ctxMenuId}`, `#wrapper-${ctxMenuId}`]);

  // This is to position the popout under the trigger element
  // since antd popout positioning isn't working well when the
  // popout height is limited and has scrollable content
  useEffect(() => {
    const wrapperComponent = triggerRef?.current;
    if (wrapperComponent) setWrapperHeight(wrapperComponent?.offsetHeight);
  }, [triggerRef.current, isEdit]);

  const confirmBox = (
    <div className="spm-ctx-menu__popover__footer">
      <Button
        className="spm-ctx-menu__footer__clear"
        onClick={() => {
          setSelected([]);
        }}
      >
        Clear
      </Button>
      <Button className="spm-ctx-menu__footer__confirm" onClick={() => setIsConfirming(true)}>
        Confirm ({selected.length})
      </Button>
    </div>
  );

  const popoverContent = (
    <div className="spm-ctx-menu__popover__content" style={{ top: wrapperHeight + 8 }}>
      <ul ref={scrollRef}>
        {options?.map((item, idx) => {
          const active = selected?.includes(item.value);
          const listClassNames = classNames(`spm-ctx-menu__popover__option ${checkBoxStyle}`, {
            active,
            multiple,
          });
          const option = isContextualMenuItem(item) ? item : { label: item, value: item };
          return (
            <li
              className={listClassNames}
              key={idx}
              value={item.value}
              onClick={(e) => {
                e.stopPropagation();
                handleItemClick(item);
              }}
            >
              {multiple && (
                <Checkbox
                  checked={active}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleItemClick(item);
                  }}
                />
              )}
              <div className="spm-ctx-menu__option__content">{renderOption(option)}</div>
            </li>
          );
        })}
        {isLoading && <LoaderIcon />}
      </ul>
      {multiple && selected.length ? confirmBox : null}
    </div>
  );

  return (
    <Popover
      id={`popover-${ctxMenuId}`}
      trigger="focus"
      placement="bottom"
      content={popoverContent}
      visible={isVisible}
      overlayClassName="spm-ctx-menu__popover"
      getPopupContainer={(triggerNode) => triggerNode}
    >
      <div
        id={`wrapper-${ctxMenuId}`}
        className="spm-ctx-menu__wrapper"
        onClick={() => {
          setIsVisible(true);
          triggerRef.current.focus();
        }}
        ref={triggerRef}
      >
        {children}
      </div>
    </Popover>
  );
};

export default ContextualMenu;
