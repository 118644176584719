import * as React from "react";
import { Select as AntdSelect } from "antd";
import { CardRequestDetailsType, CardReviewRequestEditableFieldsEnum } from "Views/Card/Review/types";
import { CARD_LIMIT_TYPES, CARD_TYPE, ITag } from "Views/Card/types";
import Input from "Views/Card/Components/Input";
import Select from "Views/Card/Components/Select";
import SpendingLimit from "Views/Card/Components/SpendingLimit";
import { MerchantLock } from "Views/Card/Components/MerchantLock";
import { ExpenseCategory } from "Views/Card/Components/ExpenseCategory";
import { onlyPositiveIntegersWithFractionsRegEx } from "constants/RegularExpressions.constant";
import { DEFAULT_TRANSACTION_TAGS_KB_ARTICLE_LINK } from "constants/Card";
import "Views/Card/Review/index.scss";
import TagsDropdown from "Views/Card/CreateOrRequest/Steps/Policy/TagsDropdown";
import { helpIcon16x16, infoIcon16x16 } from "assets/icons/card/";
import styles from "./index.module.scss";
import { Tooltip, TooltipArrowAlignmentEnum, TooltipPositionEnum } from "Modules/DS/Tooltip";
import { useTransactionTags } from "customHooks/Card";

const { Option } = AntdSelect;

export const CARD_TYPE_OPTIONS = [
  {
    label: "Virtual",
    value: CARD_TYPE.VIRTUAL,
  },
  {
    label: "Physical",
    value: CARD_TYPE.PHYSICAL,
  },
];

interface EditFormProps {
  changeCardDetails: (
    keyToBeUpdate: CardReviewRequestEditableFieldsEnum,
    value: string | number | string[] | CARD_LIMIT_TYPES | ITag[]
  ) => void;
  cardDetails: CardRequestDetailsType;
  currencyCode: string;
}
const EditForm = ({ cardDetails, changeCardDetails, currencyCode }: EditFormProps) => {
  const showTransactionTags = useTransactionTags();

  const getFormItemHeader = (header, withTopMargin = false, mandatory = false) => (
    <p className={`review__form-item-header ${withTopMargin ? "review__form-item-header--margin-top" : ""}`}>
      {header}
      {mandatory ? <span className="mandatory-star">*</span> : null}
    </p>
  );
  return (
    <>
      {getFormItemHeader("Card Title")}
      <Input
        value={cardDetails.cardTitle}
        onChange={(event) =>
          changeCardDetails(CardReviewRequestEditableFieldsEnum.CARD_TITLE, event?.target?.value || "")
        }
        placeholder="Enter Card Title"
      />
      {getFormItemHeader("Card Type", true)}
      <Select value={cardDetails.cardType} disabled>
        {CARD_TYPE_OPTIONS.map((cardType) => (
          <Option value={cardType.value} key={cardType.value}>
            {cardType.label}
          </Option>
        ))}
      </Select>
      {getFormItemHeader("Spending Limit", true, true)}
      <SpendingLimit
        cardLimit={cardDetails.cardLimit}
        cardLimitType={cardDetails.cardLimitType}
        onSpendingLimitTypeChange={(cardLimitType) =>
          changeCardDetails(CardReviewRequestEditableFieldsEnum.CARD_LIMIT_TYPE, cardLimitType)
        }
        onSpendingLimitChange={(cardLimit) => {
          if (onlyPositiveIntegersWithFractionsRegEx?.test?.(cardLimit) || cardLimit === "") {
            changeCardDetails(CardReviewRequestEditableFieldsEnum.CARD_LIMIT, cardLimit);
          }
        }}
        currencyCode={currencyCode}
      />
      <p className="create-or-request-card-steps__input-label review__form-item-header--margin-top">
        <span>Merchant Lock</span>
        <span className={styles["optional-field-label"]}>(optional)</span>
      </p>
      <MerchantLock
        onMerchantLockSelection={(merchantLocks) =>
          changeCardDetails(CardReviewRequestEditableFieldsEnum.MERCHANT_LOCK, merchantLocks)
        }
        initialValues={cardDetails.merchantLock}
      />
      <div className="create-or-request-card-steps__input-label review__form-item-header--margin-top">
        <p>Default Expense Category</p>
        <Tooltip
          text="Every expense in this card will be automatically categorized into the selected category."
          alignArrow={TooltipArrowAlignmentEnum.CENTER}
          position={TooltipPositionEnum.TOP}
        >
          <img src={infoIcon16x16} alt="Help Icon" />
        </Tooltip>
        <p className={styles["optional-field-label"]}>(optional)</p>
      </div>
      <ExpenseCategory
        selectExpenseCategoryID={cardDetails.expenseCategoryId ? +cardDetails.expenseCategoryId : undefined}
        onSelect={(expenseCategoryId) =>
          changeCardDetails(CardReviewRequestEditableFieldsEnum.EXPENSE_CATEGORY_ID, expenseCategoryId)
        }
      />
      {showTransactionTags ? (
        <>
          <div className="create-or-request-card-steps__input-label review__form-item-header--margin-top">
            <p>Default Transaction Tags</p>
            <a
              href={DEFAULT_TRANSACTION_TAGS_KB_ARTICLE_LINK}
              className="create-or-request-card-steps__input-label__link"
              target="_blank"
              rel="noreferrer"
            >
              <Tooltip
                text="Click to read about Transaction Tag"
                alignArrow={TooltipArrowAlignmentEnum.CENTER}
                position={TooltipPositionEnum.TOP}
              >
                <img src={helpIcon16x16} alt="Help Icon" />
              </Tooltip>
            </a>
            <p className={styles["optional-field-label"]}>(optional)</p>
          </div>
          <TagsDropdown
            selectedTags={cardDetails.tags}
            onChange={(data) => {
              changeCardDetails(CardReviewRequestEditableFieldsEnum.TAGS, data);
            }}
            subMenuPosition="leftBottom"
          />
        </>
      ) : null}
    </>
  );
};

export default EditForm;
