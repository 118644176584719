import React from "react";
import dayjs from "dayjs";
import { Button, Modal, Typography } from "@spenmo/splice";
import Icon from "Modules/icons";
import { IReKYBModalContent, REKYB_UPDATE_MODAL_TYPE, ReKYBUpdateModalDetails } from "Views/KYX/Constants";
import { reKybModelIcon } from "assets/img";
import { COMPANY_VERIFICATION } from "Route/KYX/paths";
import useLogout from "utility/useLogout";

import styles from "../index.module.scss";

interface ReKYBModalProps {
  type: REKYB_UPDATE_MODAL_TYPE;
  dueDate: string;
  isAdmin: boolean;
}

const ReKYBUpdateModal = ({ type, dueDate, isAdmin }: ReKYBModalProps): React.ReactElement => {
  const logout = useLogout();

  const handleUpdateNow = () => {
    if (isAdmin) window.open(COMPANY_VERIFICATION.WELCOME);
    else logout();
  };

  const dueDateObject = dayjs(dueDate);

  const formattedDate: string = dueDateObject.format("DD/MMM/YYYY");
  const Details: IReKYBModalContent = ReKYBUpdateModalDetails[type];

  return (
    <Modal showModal title={Details.title} size="m">
      <div className={styles.reKybContent}>
        <Icon src={reKybModelIcon} alt="wraning" />
        <Typography className={styles.modalAlertText} variant="body-content" size="s">
          {Details.description}
          <Typography variant="headline-content" size="xs">
            {` ${Details.subContent} ${type === REKYB_UPDATE_MODAL_TYPE.BEFORE_EXPIRY ? formattedDate + "." : ""}`}
          </Typography>
        </Typography>
        <Button variant="primary" onClick={handleUpdateNow} className={styles.updateBtn} size="m">
          {isAdmin ? "Update Now" : "Log out"}
        </Button>
      </div>
    </Modal>
  );
};

export default ReKYBUpdateModal;
