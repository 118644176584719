import React, { useState } from "react";

import {
  FORM_FIELD_TYPES,
  DOCUMENT_STEP_TYPE,
  FORM_SECTION_COMPONENT,
  KYX_BANNER_MESSAGE_TYPE,
  SECTION_CONTAINER_CLASS,
} from "Views/KYX/Constants";
import { IDocumentStep, IFormElement, IGetFormConfigsPayload } from "Views/KYX/@types";
import { PERSONNEL_INFORMATION_FORM_ITEM_KEYS, KYX_FORMS_KEYS, FORM_NAME } from "Views/KYX/Constants/Forms";
import { getValidationError, getValidationstatus } from "Views/KYX/Helper";

export const usePersonnelInfoFormBannerSGD = (
  remarks: IGetFormConfigsPayload,
  isFormDisabled: boolean,
  isMyInfoSource?: boolean
): IDocumentStep => {
  const [isShowMyInfoBanner, setIsShowMyInfoBanner] = useState(true);
  const ATTENTION_BANNER: IFormElement = {
    key: PERSONNEL_INFORMATION_FORM_ITEM_KEYS.INFO_DETAILS_ATTENTION_BANNER,
    col: { span: 24 },
    element: {
      type: FORM_FIELD_TYPES.BANNER,
      props: {
        bannerType: "attention",
        messageType: KYX_BANNER_MESSAGE_TYPE.TEXT,
        message:
          "You are required to add the details of at least one (1) Authorized Person and two (2) Directors. If someone is both an Authorized Person and Director of your company, you only need to enter their details once and select all applicable roles when filling in the form.",
      },
    },
  };
  const MYINFO_BANNER: IFormElement = {
    key: PERSONNEL_INFORMATION_FORM_ITEM_KEYS.INFO_DETAILS_ATTENTION_BANNER,
    col: { span: 24 },
    element: {
      type: FORM_FIELD_TYPES.BANNER,
      props: {
        bannerType: "success",
        messageType: KYX_BANNER_MESSAGE_TYPE.TEXT,
        message:
          "Personnel Information data successfully retrieved from MyInfo Business. Please review retrieved data below before submitting to prevent data discrepancy.",
        onClose: () => setIsShowMyInfoBanner(false),
      },
    },
  };

  const NEUTRAL_BANNER: IFormElement = {
    key: PERSONNEL_INFORMATION_FORM_ITEM_KEYS.ROLE_INFO_DETAILS_NEUTRAL_BANNER,
    col: { span: 24 },
    element: {
      type: FORM_FIELD_TYPES.BANNER,
      props: {
        bannerType: "neutral",
        messageType: KYX_BANNER_MESSAGE_TYPE.LIST,
        message: "Authorized Persons, Company Directors, and Shareholders",
        list: [
          {
            key: PERSONNEL_INFORMATION_FORM_ITEM_KEYS.ROLE_INFO_DETAILS_AUTHORIZED_USERS,
            children: (
              <>
                <strong>Authorized Person(s)</strong> are individuals authorized on behalf of the Company to operate the
                account in the manner indicated in the board resolution.
              </>
            ),
          },
          {
            key: PERSONNEL_INFORMATION_FORM_ITEM_KEYS.ROLE_INFO_DETAILS_COMPANY_DIRECTOR,
            children: (
              <>
                <strong>Company Director(s)</strong> is an individual who legally holds said position in the company,
                validated through legal certificates.
              </>
            ),
          },
          {
            key: PERSONNEL_INFORMATION_FORM_ITEM_KEYS.ROLE_INFO_DETAILS_BENEFICIAL_OWNER,
            children: (
              <>
                <strong>Shareholder(s)</strong> refers to the natural person(s) who owns 25% or more effective
                shareholdings (directly or indirectly) in the company, or if none, the natural person(s) who ultimately
                / effectively control(s) the Company (through significant influence) or the natural person with
                executive authority, regardless of shareholding.
              </>
            ),
          },
        ],
      },
    },
  };

  const HAS_ONLY_DIRECTOR: IFormElement = {
    key: FORM_NAME.PERSONNEL_INFORMATION + KYX_FORMS_KEYS.PERSONNEL_DATA_ONLY_DIRECTOR,
    col: { span: 12 },
    element: {
      type: FORM_FIELD_TYPES.RADIO,
      props: {
        formProps: {
          name: KYX_FORMS_KEYS.PERSONNEL_DATA_ONLY_DIRECTOR,
          label: "Company only has 1 Director",
          required: true,
          style: { marginTop: "1.5rem" },
          validateStatus: getValidationstatus(remarks, KYX_FORMS_KEYS.PERSONNEL_DATA_ONLY_DIRECTOR),
          help: getValidationError(
            remarks,
            KYX_FORMS_KEYS.PERSONNEL_DATA_ONLY_DIRECTOR,
            "Company only has 1 Director",
            FORM_NAME.PERSONNEL_INFORMATION
          ),
        },
        radioProps: {
          className: "company_director_check",
          disabled: isFormDisabled,
          options: [
            {
              label: "Yes",
              value: true,
            },
            {
              label: "No ",
              value: false,
            },
          ],
        },
      },
    },
  };

  // Banner Information
  return {
    key: PERSONNEL_INFORMATION_FORM_ITEM_KEYS.PERSONNEL_INFO_AND_BANNER_DETAILS,
    row: { gutter: 40 },
    type: DOCUMENT_STEP_TYPE.DEFAULT,
    container: {
      type: FORM_SECTION_COMPONENT.CONTAINER,
      props: isMyInfoSource && isShowMyInfoBanner ? { className: SECTION_CONTAINER_CLASS.HIDE_CONTAINER } : null,
    },
    document: [
      !isMyInfoSource && ATTENTION_BANNER,
      !isMyInfoSource && NEUTRAL_BANNER,
      !isMyInfoSource && HAS_ONLY_DIRECTOR,
      isMyInfoSource && isShowMyInfoBanner && MYINFO_BANNER,
    ],
  };
};

export const useCompanyInfoFormBannerSGD = (isMyInfoSource?: boolean): IDocumentStep => {
  const [isShowMyInfoBanner, setIsShowMyInfoBanner] = useState(true);
  const MYINFO_BANNER: IFormElement = {
    key: PERSONNEL_INFORMATION_FORM_ITEM_KEYS.INFO_DETAILS_ATTENTION_BANNER,
    col: { span: 24 },
    element: {
      type: FORM_FIELD_TYPES.BANNER,
      props: {
        bannerType: "success",
        messageType: KYX_BANNER_MESSAGE_TYPE.TEXT,
        message:
          "Company Information data successfully retrieved from MyInfo Business. Please review retrieved data below before submitting to prevent data discrepancy upon creating a new Spenmo account.",
        onClose: () => setIsShowMyInfoBanner(false),
      },
    },
  };

  // Banner Information
  return {
    key: PERSONNEL_INFORMATION_FORM_ITEM_KEYS.PERSONNEL_INFO_AND_BANNER_DETAILS,
    row: { gutter: 40 },
    type: DOCUMENT_STEP_TYPE.DEFAULT,
    container: { type: FORM_SECTION_COMPONENT.CONTAINER, props: { className: SECTION_CONTAINER_CLASS.HIDE_CONTAINER } },
    document: [isMyInfoSource && isShowMyInfoBanner && MYINFO_BANNER],
  };
};

export const useCompanyDocumentsFormBannerSGD = (isMyInfoSource?: boolean): IDocumentStep => {
  const [isShowMyInfoBanner, setIsShowMyInfoBanner] = useState(true);
  const MYINFO_BANNER: IFormElement = {
    key: PERSONNEL_INFORMATION_FORM_ITEM_KEYS.INFO_DETAILS_ATTENTION_BANNER,
    col: { span: 24 },
    element: {
      type: FORM_FIELD_TYPES.BANNER,
      props: {
        bannerType: "success",
        messageType: KYX_BANNER_MESSAGE_TYPE.TEXT,
        message:
          "Company Documents data successfully retrieved from MyInfo Business. Please review retrieved data below before submitting to prevent data discrepancy.",
        onClose: () => setIsShowMyInfoBanner(false),
      },
    },
  };

  // Banner Information
  return {
    key: PERSONNEL_INFORMATION_FORM_ITEM_KEYS.PERSONNEL_INFO_AND_BANNER_DETAILS,
    row: { gutter: 40 },
    type: DOCUMENT_STEP_TYPE.DEFAULT,
    container: { type: FORM_SECTION_COMPONENT.CONTAINER, props: { className: SECTION_CONTAINER_CLASS.HIDE_CONTAINER } },
    document: [isMyInfoSource && isShowMyInfoBanner && MYINFO_BANNER],
  };
};
