import React, { useContext } from "react";
import classNames from "classnames";
import RowContext from "../Row/context";
import { IColProps, IColSize } from "../types";
import styles from "./styles.module.scss";

const sizes = ["sm", "md", "lg", "xl"];

const Col: React.FC<IColProps> = (props) => {
  const { curScreens } = useContext(RowContext);

  const { span, start, end, order, className, children, ...others } = props;

  let sizeClassObj = {
    [styles[`col-span-${span}`]]: span !== undefined,
    [styles[`col-order-${order}`]]: order || order === 0,
  };

  for (let size of sizes) {
    if (!curScreens[size]) continue;
    let sizeProps: IColSize = {};
    const propSize = props[size];
    if (typeof propSize === "number") {
      sizeProps.span = propSize;
    } else if (typeof propSize === "object") {
      sizeProps = propSize || {};
    }
    delete others[size];

    sizeClassObj = {
      ...sizeClassObj,
      [styles[`col-${size}-span-${sizeProps.span}`]]: sizeProps.span !== undefined,
      [styles[`col-${size}-start-${sizeProps.start}`]]: sizeProps.start || sizeProps.start === 0,
      [styles[`col-${size}-end-${sizeProps.end}`]]: sizeProps.end || sizeProps.end === 0,
      [styles[`col-${size}-order-${sizeProps.order}`]]: sizeProps.order || sizeProps.order === 0,
    };
  }

  const classes = classNames(className, sizeClassObj);

  return (
    <div {...others} className={classes}>
      {children}
    </div>
  );
};

if (process.env.NODE_ENV !== "production") {
  Col.displayName = "Col";
}

export default Col;
