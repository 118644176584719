export enum HomeEventName {
  HOME_PAGE_LOADED = "Homepage Loaded",
}

export enum QuickAccessShortCutsEventName {
  SUBMIT_BILLS = "submit bills home quick access shortcut clicked",
  MANAGE_BILL_PAYMENTS = "manage bills home quick access shortcut clicked",
  SETUP_PAYMENT_RUN = "setup payment run access shortcut clicked",
  MANAGE_PAYMENT_RUN = "manage payment run access shortcut clicked",
  REQUEST_REIMBURSEMENT = "home banner requests clicked",
  VIEW_TEAMS_LIST = "view teams list home quick access shortcut clicked",
  VIEW_EMPLOYEES = "view employees home quick access shortcut clicked",
  ADD_NEW_EMPLOYEE = "add new employee home quick access shortcut clicked",
  CREATE_NEW_TEAM = "create new team home quick access shortcut clicked",
  MANAGE_CATEGORIES = "manage categories home quick access shortcut clicked",
  VIEW_CATEGORIES = "view categories home quick access shortcut clicked",
  VIEW_TRANSACTIONS = "home banner transactions clicked",
  MANAGE_BANK_ACCOUNT = "manage bank account home quick access shortcut clicked",
  SEND_MONEY = "send money home quick access shortcut clicked",
  REQUEST_MONEY = "request money home quick access shortcut clicked",
}

export const cardEvents = {
  VIEW_ALL_CARDS: "homepage view all cards link clicked",
};

export enum PendingActionEvents {
  EVENT_NAME = "Task Card Clicked",
  INSUFFICIENT_BALANCE = "Billpay insufficient balance",
  BILL_PAYMENT_UNASSIGNED = "Payment run unassigned bills",
  BILL_PAYMENT_SUMMARY_UPCOMING = "Payment run upcoming",
  BILL_PAYMENT_SUMMARY_OVERDUE = "Payment run overdue",
  BILL_PAYMENT_SUMMARY_TODAY = "Payment run today",
  BILL_PAYMENT_INSUFICIENT_BALANCE = "Payment run insufficient balance",
  BILL_PENDING_APPROVAL = "Billpay approval",
  FUND_TRANSFER_APPROVAL = "Fund approval",
  REIMBURSEMENT_PENDING_APPROVAL = "Reimbursement approval",
  ACCOUNTING_FAILED_SYNC = "Accounting failed sync",
  ACCOUNTING_PENDING_REVIEW = "Accounting pending review",
  ACCOUNTING_MISSING_RECEIPT = "Transactions missing receipt",
  CARD_PENDING_APPROVAL = "Card approval",
}
