import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { SelectMembersList } from "./SelectMembersList";
import LoadingIcon from "../loading";
import { empFunc, teamEmpFunc, getTeamList } from "../../Redux/Actions";

import "./SelectMembers.scss";
import { GetUserId } from "utility";

class SelectMembers extends React.PureComponent {
  componentDidMount() {
    if (this.props.showAccountant) {
      this.props.getTEmp(null, false, true);
    } else if (!this.props.isRequestMoney) {
      this.props.getTEmp();
    } else {
      this.props.getEmp();
    }

    if (!this.props.teamDataList.data.payload) {
      this.props.getTeam();
    }
  }

  shouldDisplaySelf = (team) => {
    return !(team?.your_role === "Manager" || team?.your_role === "Admin");
  };

  generatePayload = () => {
    const { isTeam, isRequestMoney, isRegisterCard, tdata, edata, tedata, teamDataList } = this.props;
    const shouldDisplaySelf = this.shouldDisplaySelf(tdata?.payload?.team);
    const userId = GetUserId();

    switch (true) {
      case isTeam:
        return teamDataList.data.payload.team;
      case isRequestMoney:
        return edata.payload.employees;
      case shouldDisplaySelf && isRegisterCard:
        return tedata.payload.team_members?.filter((member) => member.id === userId);
      default:
        return tedata.payload.team_members;
    }
  };

  filterDuplicate = ({ id: value1 }) => {
    return !this.props.receivedTeamMembers.some(({ id: value2 }) => value2 === value1);
  };

  render() {
    const {
      isTeam,
      edata,
      checkDuplicates,
      allEmployees,
      singleSelection,
      formError,
      title,
      subTitle,
      onChange,
      teamDataList,
    } = this.props;

    if ((!isTeam && edata.payload) || (isTeam && teamDataList.data.payload)) {
      let payload = this.generatePayload();

      let payloadFiltered;
      if (checkDuplicates && Array.isArray(allEmployees)) {
        payloadFiltered = [...allEmployees];
        payloadFiltered = payloadFiltered.filter(this.filterDuplicate);
      }
      return (
        <SelectMembersList
          {...this.props}
          singleSelection={singleSelection}
          formError={formError}
          title={title}
          subTitle={subTitle}
          list={checkDuplicates ? payloadFiltered : payload}
          onChange={onChange}
        />
      );
    }
    return <LoadingIcon style={{ margin: "50px" }} />;
  }
}

SelectMembers.defaultProps = {
  onChange: () => {},
};

SelectMembers.propTypes = {
  edata: PropTypes.object,
  tdata: PropTypes.object,
  teamDataList: PropTypes.object,
  tedata: PropTypes.object,
  selectedTeamId: PropTypes.string,
  getEmp: PropTypes.func,
  getTEmp: PropTypes.func,
  getTeam: PropTypes.func,
  loading: PropTypes.bool,
  isRequestMoney: PropTypes.bool,
  isRegisterCard: PropTypes.bool,
  formError: PropTypes.bool,
  onChange: PropTypes.func,
  title: PropTypes.string,
  isTeam: PropTypes.bool,
  singleSelection: PropTypes.bool,
  checkDuplicates: PropTypes.bool,
  receivedTeamMembers: PropTypes.array,
  allEmployees: PropTypes.any,
  subTitle: PropTypes.string,
  showAccountant: PropTypes.bool,
};

export default connect(
  (state) => {
    return {
      tedata: state.empReducer.data,
      edata: state.empReducer.data,
      tdata: state.teamReducer.data,
      teamDataList: state.teamList,
      selectedTeamId: state.teamReducer.selectedTeamId,
    };
  },
  {
    getEmp: empFunc,
    getTEmp: teamEmpFunc,
    getTeam: getTeamList,
  }
)(SelectMembers);
