import { FormInstance } from "antd/lib/form/hooks/useForm";

export enum DATE_RANGE {
  START = "start_date",
  END = "end_date",
}

export interface IDateFilterProps<T> {
  id: string;
  filter: T;
  onApply: (value: IDateFormProps) => void;
  onClear: (keys: string[]) => void;
  startKey?: string;
  endKey?: string;
  asISO?: boolean;
  defaultValue?: string;
}

export interface IDateFormProps {
  [key: string]: string;
}

export interface IDateFilterStates {
  get: {
    form: FormInstance<IDateFormProps>;
    title: string;
    selected: boolean;
    from: string;
    to: string;
    error: string;
  };
  set: {
    from: React.Dispatch<string>;
    to: React.Dispatch<string>;
  };
  defaultValue: string;
  applyFilter: (values: IDateFormProps) => void;
  clearStates: () => void;
  clearSelected: () => void;
}
