import {
  FETCH_MEMBERROLE_REQUEST,
  FETCH_MEMBERROLE_SUCCESS,
  FETCH_MEMBERROLE_FAILURE,
  MEMBER_ROLE_RESET,
} from "../Actions/memberRoleAction";
import { userInitialState } from "../InitialState";

export const memberRoleReducer = (state = userInitialState, action) => {
  switch (action.type) {
    case FETCH_MEMBERROLE_REQUEST:
      return {
        ...state,
        data: action.data,
        loading: true,
      };
    case FETCH_MEMBERROLE_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
      };
    case FETCH_MEMBERROLE_FAILURE:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: true,
      };
    case MEMBER_ROLE_RESET:
      return {
        ...state,
        data: {},
      };
    default:
      return state;
  }
};
