import * as React from "react";

import { ModalComponent } from "Modules/DS/Modal";

import { IContentLoader, TITLE_TYPE } from "Modules/DS/Modal/types";

export const ContentLoader = ({ title, subtitle }: IContentLoader) => {
  return (
    <>
      <ModalComponent.Loader />
      {title && <ModalComponent.Title titleType={TITLE_TYPE.loadContent}>{title}</ModalComponent.Title>}
      {subtitle && <ModalComponent.SubTitle>{subtitle}</ModalComponent.SubTitle>}
    </>
  );
};
