import React from "react";
import { Form, Select } from "antd";

import { Banner, ChevronDown, InfoFilled, Loader, Typography } from "@spenmo/splice";
import { Col, Row } from "Splice/Grid";
import { useQuery } from "utility/useQuery";
import { getPartnerDataStatus } from "Redux/DataCalls/AccountingSetting.api";

import { formattedDate } from "../helper/dataMapping";
import { IXeroOrgAccountInfoProps } from "../@types";
import LastSyncedSetting from "../LastSyncedSetting";
import ReconciliationSetting from "../ReconciliationSetting";
import { useSettingContext } from "../context/SettingContext";
import { SettingLabel } from "../components";
import ImportSettings from "../ImportSettings";
import { PARTNER_TYPE } from "../helper/constant";

import styles from "./styles.module.scss";
import { useIsSaasWithPaymentsOrg } from "customHooks/useIsSaasWithPaymentsOrg";

const { Option } = Select;

const PartnerOrgAccountInformation = ({
  partnerIntegrationData,
  partnerAccountData,
  partnerAccountNotFound,
  lastSyncTime,
  refetchLastSyncTime,
  settingUpdateNotAllowed,
  partnerName,
}: IXeroOrgAccountInfoProps) => {
  const isPaymentEnabled = useIsSaasWithPaymentsOrg();
  const { setSettingChangeObj, usingExpenseIntegration, usingBankFeedIntegration } = useSettingContext();
  const onlyExpenseIntegration = !usingBankFeedIntegration && usingExpenseIntegration;
  const isNetsuiteIntegration = partnerName === PARTNER_TYPE.NETSUITE;

  const { data: finishFetchAllData } = useQuery({
    apiCall: getPartnerDataStatus,
    run: isNetsuiteIntegration,
    customDataMapping(data) {
      return (data?.payload?.data || []).every((item) => item.status === "Complete");
    },
    defaultData: true,
  });

  const {
    partnerOrganisationName,
    expenseBankAccountName,
    expenseBankAccountNumber,
    expenseBankAccountId,
    partnerFundingBankAccountId,
  } = partnerIntegrationData || {};

  const { accounts, topup_bank_account_id } = partnerAccountData?.payload || {};
  const fundingBankAccounts = accounts?.filter((account) => account?.id !== expenseBankAccountId) || [];
  const showFundingBankAccount = onlyExpenseIntegration || isNetsuiteIntegration;

  return (
    <div className={styles.xeroOrgAccountInfoContainer}>
      <Row column={16} offset={0} gutter={[48, 24]}>
        {!finishFetchAllData && (
          <Col span={16}>
            <div className={styles.banner}>
              <Banner
                icon={InfoFilled}
                variant="info"
                title="Awaiting other sync details from NetSuite"
                description="Account details from NetSuite are automatically retrieved and may take awhile to complete. Some setting options may not be available right now."
              />
            </div>
          </Col>
        )}
        <Col span={8}>
          <SettingLabel text={`Connected ${partnerName} Organization`} />
          <Typography variant="body-content" size="l" tag="h4" weight={600} children={partnerOrganisationName} />
        </Col>
        <Col span={8}>
          <SettingLabel text={`Synchronized ${partnerName} Bank Account`} />
          <Typography
            variant="body-content"
            size="l"
            tag="h4"
            weight={600}
            children={`${expenseBankAccountNumber}-${expenseBankAccountName}`}
          />
          <Typography
            variant="body-content"
            size="caption-m"
            tag="p"
            children="Reconciliation of expenses from Spenmo are done in this account"
            className={styles.bodyContent}
          />
        </Col>
      </Row>
      {!onlyExpenseIntegration && isPaymentEnabled && (
        <Row column={16} offset={0} gutter={[48, 24]}>
          <Col span={8}>
            <SettingLabel text="Synchronization Start Date" />
            <Typography
              variant="body-content"
              size="m"
              className={styles.bodyContent}
              children={
                lastSyncTime?.bank_feed?.started_at ? (
                  <>
                    Bank Feed was set to sync with Spenmo from
                    <p>
                      <strong>{formattedDate(lastSyncTime?.bank_feed?.started_at)} onwards</strong>
                    </p>
                  </>
                ) : (
                  "-"
                )
              }
            />
          </Col>
          <Col span={8}>
            <LastSyncedSetting
              settingUpdateNotAllowed={settingUpdateNotAllowed}
              lastSyncTime={lastSyncTime}
              refetchLastSyncTime={refetchLastSyncTime}
              accountNotFound={partnerAccountNotFound}
            />
          </Col>
        </Row>
      )}
      {showFundingBankAccount && fundingBankAccounts?.length > 0 && (
        <Row column={16} offset={0} gutter={[0, 24]}>
          <Col span={7}>
            <SettingLabel text="Funding Bank Account" />
            <Form.Item name="xero_topup_account_id" id="XeroTopAccountCode" initialValue={topup_bank_account_id}>
              <Select
                className={styles.fundingBankAccountDropdown}
                disabled={settingUpdateNotAllowed || !finishFetchAllData}
                placeholder="Select Funding Bank Account"
                defaultValue={partnerFundingBankAccountId}
                suffixIcon={
                  !finishFetchAllData ? (
                    <span className={styles.loader}>
                      <Loader.Spinner size="l" variant="brand" />
                    </span>
                  ) : (
                    <ChevronDown
                      iconColor={settingUpdateNotAllowed ? "var(--icon-disabled)" : "var(--icon-default)"}
                      size="24"
                    />
                  )
                }
                onChange={(val) =>
                  setSettingChangeObj((settingChangeObj) => ({
                    ...settingChangeObj,
                    fundingBankAccount: val !== topup_bank_account_id,
                  }))
                }
              >
                {fundingBankAccounts?.map((item) => (
                  <Option key={item?.id} value={item?.account_id}>
                    {`${item?.number} - ${item?.name}`}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Typography
              variant="body-content"
              size="caption-m"
              tag="p"
              children="All top-ups to Spenmo will be reconciled to this account"
              className={styles.caption}
            />
          </Col>
        </Row>
      )}
      <Row column={16} offset={0} gutter={[0, 0]} className={styles.reconciliationSettingContainer}>
        <Col span={16}>
          {partnerName === PARTNER_TYPE.XERO && (
            <ReconciliationSetting settingUpdateNotAllowed={settingUpdateNotAllowed} />
          )}
        </Col>
        <Col span={16}>
          <ImportSettings finishFetchAllData={finishFetchAllData} />
        </Col>
      </Row>
    </div>
  );
};

export default PartnerOrgAccountInformation;
