import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Modal from "../modal";
import SelectMembers from "../SelectMembers";
import SelectMembersFromTeam from "../SelectMembersFromTeam";
import AmountDetailsWrapper from "../AmountDetails";
import "./modalDetails.scss";
import { GetBaseAuthObject } from "../../utility";
import { trackEvent } from "utility/analytics";
import { ActionTypes } from "Modules/AmountDetails/AmountDetailsEventMapping";

const ModalDetails = ({
  onSuccess,
  updateData,
  teamData = [],
  close,
  isTeam = false,
  show = false,
  isRequestMoney = false,
  isRegisterCard = false,
  title,
  isSchedulePayment,
  editData,
  teamid,
  view,
  merchantData,
  intervalsData,
  isVirtual = false,
  isPhysical = false,
  trackActions = false,
  disableZeroLimit = false,
}) => {
  const selectWalletRaw = [
    { name: "Personal Wallet", amount: "320", id: "1" },
    { name: "Team Wallet", amount: "430", id: "2" },
  ];
  //selected employee or team - DO NOT REMOVE COMMENT
  const [selectedMembers, setSelectedMembers] = useState({});
  const [selectedMembersData, setSelectedMembersData] = useState([]);
  const [error, setError] = useState(false);
  const onReceiverSelect = (data) => {
    if (data && data[0]) {
      let modal_user = {
        team_id: data[0].id,
      };
      let user = {
        team_id: data[0].team_id || teamid,
        type: "team",
      };

      if (!isTeam) {
        modal_user.team_id = data[0].team_id || teamid;
        modal_user.user_id = data[0].id;
        user.user_id = GetBaseAuthObject().userId;
      }
      setSelectedMembers({ modal_user, user, user_name: data[0].name });
    }
  };

  const trackCardFlowAction = (data) => {
    const cardsData = data?.[0];
    const params = {
      "cardholder name": cardsData?.name,
    };
    const eventName = isVirtual ? "create virtual cardholder selected" : "create physical cardholder selected";
    trackEvent(eventName, params);
  };

  const getSelectMembersDropDown = () => {
    const title = isVirtual || isPhysical ? "Cardholder" : isRequestMoney ? "Request money from" : "Send money to";
    const subTitle = isTeam ? "YOUR TEAMS" : "";
    if (isRequestMoney) {
      return (
        <SelectMembers
          isRequestMoney={isRequestMoney}
          isRegisterCard={isRegisterCard}
          title={title}
          subTitle={subTitle}
          editData={editData}
          singleSelection
          onChange={(data) => {
            (isVirtual || isPhysical) && trackCardFlowAction(data);
            onReceiverSelect(data);
            setError(false);
            setSelectedMembersData(data);
            isRequestMoney && trackActions && trackEvent("request money from person selected");
          }}
          isTeam={isTeam}
          formError={error}
          data={teamData}
        />
      );
    } else if (isRegisterCard) {
      return (
        <SelectMembersFromTeam
          isRequestMoney={isRequestMoney}
          isRegisterCard={isRegisterCard}
          title={title}
          subTitle={subTitle}
          editData={editData}
          singleSelection
          onChange={(data) => {
            (isVirtual || isPhysical) && trackCardFlowAction(data);
            onReceiverSelect(data);
            setError(false);
            setSelectedMembersData(data);
            isRequestMoney && trackActions && trackEvent("request money from person selected");
          }}
          isTeam={isTeam}
          formError={error}
          data={teamData}
          teamSelectTitle="Select Cardholder’s Team"
          teamSelectPlaceholder="Select Cardholder’s Team"
          memberListTitle="Select Cardholder"
          noDefaultTeam
        />
      );
    }
  };

  return (
    <Modal show={show} close={close} title={title} className={"modal-details"}>
      <div className={"modal-details__wrapper"}>
        {getSelectMembersDropDown()}
        <AmountDetailsWrapper
          updateData={updateData}
          title={isRequestMoney ? "Select Recipient Budget" : "Send from"}
          editData={editData}
          merchantData={merchantData}
          intervalsData={intervalsData}
          isPhysical={isPhysical}
          isVirtual={isVirtual}
          memberData={selectedMembers}
          selectedMembersData={selectedMembersData}
          isSchedulePayment={isSchedulePayment}
          checkError={() => setError(!Object.keys(selectedMembers).length > 0)}
          view={view}
          showUpload={isRequestMoney}
          walletData={selectWalletRaw}
          onConfirm={onSuccess}
          trackActions={trackActions}
          actionType={isRequestMoney ? ActionTypes.RequestMoney : ""}
          isRequestMoney={isRequestMoney}
          disableZeroLimit={disableZeroLimit}
        />
      </div>
    </Modal>
  );
};

ModalDetails.propTypes = {
  teamData: PropTypes.any,
  close: PropTypes.func,
  updateData: PropTypes.func,
  onSuccess: PropTypes.func,
  isTeam: PropTypes.bool,
  show: PropTypes.bool,
  title: PropTypes.string,
  teamid: PropTypes.string,
  isRequestMoney: PropTypes.bool,
  isRegisterCard: PropTypes.bool,
  editData: PropTypes.object,
  merchantData: PropTypes.array,
  isSchedulePayment: PropTypes.bool,
  view: PropTypes.string,
  intervalsData: PropTypes.array,
  isVirtual: PropTypes.bool,
  isPhysical: PropTypes.bool,
  trackActions: PropTypes.bool,
};

export default connect((state) => ({
  teamid: state.teamReducer?.selectedTeamId,
}))(ModalDetails);
