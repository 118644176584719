import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useHistory, useParams } from "react-router-dom";
import useSWRInfinite from "swr/infinite";
import "intersection-observer"; // optional polyfill
import Observer from "@researchgate/react-intersection-observer";
import qs from "query-string";

import { getData, postData } from "API/Client";

import { appNotification } from "Modules/appNotification/appNotification";
import LoaderIcon from "Views/State/Loading/LoaderIcon";
import DataTable, {
  Table,
  BulkActionType,
  DATA_TABLE_SIZE_TYPES,
  BulkActionPropTypes,
} from "Modules/DS/DataTable";
import Icon from "Modules/Icon";
import DraftFilter from "./DraftFilter";
import DraftUpload from "./DraftUpload";
import FilePreview from "./FilePreview";
import Loader from "Modules/DS/Loader";
import Modal from "Modules/DS/Modal";
import { CloseButton, Title } from "Modules/DS/Modal/Components";
import Toaster from "Modules/DS/Toaster";
import EditBillModal from "Views/UploadInvoice/EditBillModal";
import PreviewBulk from "./PreviewBulk";
import DeleteDraftModal from "./DeleteDraftModal";

import { RootState } from "Redux/ConfigureStore";
import useGetBillUrl from "../useGetBillUrl";

import { dataTableNoRecordFound, refreshFxRate } from "assets/img";
import { LIMIT } from "../const";
import { getColumns, DraftColumn } from "../helper";

import { API_URL, BillFormType, BillParams } from "Views/Bills/V2/constants";
import {
  BillFooterTypes,
  BillFormTypes,
} from "Views/UploadInvoice/EditBillModal/types.d";
import {
  TOASTER_SIZE_TYPE,
  TOASTER_STATUS_TYPE,
} from "Modules/DS/Toaster/types.d";

import styles from "../Bills.module.scss";
import { useMutableData } from "API/useData";
import { SaaSConfig } from "Views/Bills/V2/BillForm/type";
import DragDropButton from "Views/Bills/DragDropButton";

type PaginationData = {
  TotalRow?: number;
  TotalPage?: number;
};

type PreviewFile = {
  fileName: string;
  fileType: string;
  src: string;
};

export interface ToasterData {
  visible: boolean;
  message: string;
  status: TOASTER_STATUS_TYPE;
  actionLabel?: string;
  action?: () => void;
}

export interface LocationState {
  toasterData: ToasterData;
  billID: string;
  closeModal?: boolean;
}

const ManageDraft = (props) => {
  const [orgDetail] = useSelector((state: RootState) => [
    state.b2bOrgDetailReducer,
  ]);

  const { onUpload } = props;
  const location = useLocation();
  const history = useHistory();
  const locationState = location?.state as LocationState;

  const query = qs.parse(location.search);

  const [paginationData, setPaginationData] = useState<PaginationData>({});
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  // when selectAll is true, selectedIds will be unselectedIds
  const [selectedIds, setSelectedIds] = useState({});
  const [previewFile, setPreviewFile] = useState<PreviewFile | undefined>();
  const [isEditBillVisible, setIsEditBillVisible] = useState(false);
  const [editBillID, setEditBillID] = useState(null);
  const [isBulkEdit, setIsBulkEdit] = useState(false);
  const [showPreviewBulk, setShowPreviewBulk] = useState(false);
  const [showFxModal, setShowFxModal] = useState(false);
  const [deleteDraftModalVisible, setDeleteDraftModalVisible] = useState(false);
  const [toasterData, setToasterData] = useState<ToasterData>({
    visible: false,
    message: "",
    status: TOASTER_STATUS_TYPE.ERROR,
  });

  const { data: saasConfigResponse } = useMutableData(
    `${API_URL.saasConfig}?view=draft`,
  );
  const saasConfig: SaaSConfig = useMemo(
    () => saasConfigResponse?.data?.payload,
    [saasConfigResponse?.data?.payload],
  );

  const params = useParams<BillParams>();
  const urlParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search],
  );
  const encodedBillIds = urlParams.get("data");

  // Memoized calculations for form status
  const isEditForm = useMemo(
    () =>
      params?.form === BillFormType.BULK &&
      encodedBillIds &&
      encodedBillIds.length > 0,
    [params, encodedBillIds],
  );

  // Use useMemo to prevent unnecessary re-computations
  const isEditBulkDraft = useMemo(
    () => isEditForm && location.pathname.includes("/bills/drafts"),
    [isEditForm, location.pathname],
  );

  useEffect(() => {
    if (encodedBillIds === "all") {
      setSelectAll(true);
    } else {
      resetRowSelection();
    }
  }, [encodedBillIds]);

  useEffect(() => {
    setShowPreviewBulk(false);
  }, [encodedBillIds]);

  useEffect(() => {
    if (isEditBulkDraft) {
      setShowPreviewBulk(isEditBulkDraft);
    }
  }, [isEditBulkDraft]);

  const { billParamsBuilder } = useGetBillUrl();

  const clearToasterData = () => {
    setToasterData({
      visible: false,
      message: "",
      status: TOASTER_STATUS_TYPE.ERROR,
    });
    window.history.replaceState({}, document.title);
  };

  const { data, mutate, size, setSize, error, isValidating } = useSWRInfinite(
    (pageIndex) => {
      const queryStr = qs.stringify({
        ...query,
        page: pageIndex + 1,
        row: LIMIT,
      });

      return `/ms/spm-disbursement/v1/bill/draft?${queryStr}`;
    },
    (url) =>
      getData(url).then((res) => {
        setPaginationData(res.data.payload.pagination);
        return res.data.payload.list || [];
      }),
    {
      revalidateAll: true,
      revalidateOnFocus: false,
    },
  );

  useEffect(() => {
    const _toasterData = locationState?.toasterData;
    const closeModal = locationState?.closeModal;
    const billID = locationState?.billID;

    if (closeModal) {
      setIsEditBillVisible(false);
      mutate().catch((e) => console.error(e));
    }

    if (_toasterData) {
      const action =
        _toasterData.actionLabel === "View Bill"
          ? () => {
              history.push(billParamsBuilder({ invoiceid: billID }));
            }
          : undefined;
      setToasterData({ ..._toasterData, action });
    }
  }, [locationState?.toasterData]);

  useEffect(() => {
    if (selectAll && size && data) {
      const pageIndex = size - 1;

      setSelectedRowKeys((prevKeys) => {
        const newDataKey = data[pageIndex].map((item) => item.id);
        return [...prevKeys, ...newDataKey];
      });
    }
  }, [data?.length]);

  const list = data?.flat() || [];
  const isLoadingInitialData = !data && !error;
  const isLoadingMore =
    isLoadingInitialData ||
    (size > 0 && data && !error && typeof data[size - 1] === "undefined");
  const isEmpty = data?.[0]?.length === 0;
  const isReachingEnd = isEmpty || paginationData.TotalPage === size;
  const isRefreshing = isValidating && data && data.length === size;

  // This is for removing deleted item from selected rows / ids
  useEffect(() => {
    if (!selectAll && selectedRowKeys.length > 0) {
      const newSelectedRowKeys = [];
      const newSelectedIds = {};
      list.forEach((item) => {
        if (selectedRowKeys.includes(item?.id)) {
          newSelectedRowKeys.push(item?.id);
          newSelectedIds[item.id] = selectedIds[item.id];
        }
      });
      setSelectedRowKeys(newSelectedRowKeys);
      setSelectedIds(newSelectedIds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleChangeObserver = (e, unobserve) => {
    if (e.isIntersecting) {
      unobserve();

      if (!isReachingEnd) {
        setSize(size + 1);
      }
    }
  };

  const handleClickAttachment = (e, record) => {
    e.stopPropagation();
    const { attachmentURLs, attachments } = record;

    if (attachmentURLs && attachments) {
      const [attachment] = attachments;

      setPreviewFile({
        fileName: attachment.name,
        fileType: attachment.fileType,
        src: attachmentURLs[0],
      });
    }
  };

  const tableRowAction = ({ id }: DraftColumn) => {
    history.push(`${location.pathname}/${BillFormType.EDIT}/${id}`);
  };

  const onEditBillClose = () => {
    setIsEditBillVisible(false);
  };

  const resetRowSelection = () => {
    // reset row selection state variables
    setSelectAll(false);
    setSelectedRowKeys([]);
    setSelectedIds({});
  };

  const getSelectedRowLength = () => {
    if (!paginationData.TotalRow) {
      return 0;
    }
    // get (totalrow - unselectedIds) when selectAll is true | selectedIds
    return selectAll
      ? paginationData.TotalRow - Object.keys(selectedIds).length
      : Object.keys(selectedIds).length;
  };

  const deleteDraftModal = (
    <DeleteDraftModal
      visible={deleteDraftModalVisible}
      setVisible={setDeleteDraftModalVisible}
      action={() => {
        handleDeleteDrafts();
      }}
    />
  );

  const handleDeleteDrafts = () => {
    // unselected if selectAll is true
    const draftIDs = Object.keys(selectedIds);

    postData("/ms/spm-disbursement/v1/bill/draft/bulk-delete", {
      filters: query,
      selectedAll: selectAll,
      draftIDs,
    })
      .then((res) => {
        const { data } = res;
        if (data.status !== 200) {
          throw new Error(data.status);
        }

        resetRowSelection();

        const length = getSelectedRowLength();
        const countifier = length === 1 ? "Bill has" : `${length} bills have`;
        appNotification.success({
          message: `${countifier} been successfully deleted from drafts`,
        });

        mutate();
      })
      .catch(() => {
        appNotification.error({
          message:
            "An error occurred when deleting selected bill(s). Please try again.",
        });
      });
  };

  const tableRowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys) => {
      setSelectedRowKeys(selectedRowKeys);
    },
    onSelectAll: (selected) => {
      setSelectAll(selected);

      // empty up unSelectedIds
      setSelectedIds({});
    },
    onSelect: (record, selected, selectedRows, e) => {
      // e.stopPropagation();
      // when selectAll is true, selectedIds become unSelectedIds
      if ((selected && !selectAll) || (!selected && selectAll)) {
        setSelectedIds((rows) => ({ ...rows, [record.id]: true }));
        return;
      }

      setSelectedIds((rows) => {
        delete rows[record.id];
        return rows;
      });
    },
    renderCell: (checked, record, index, originNode) => {
      if ((index + 1) % LIMIT === 0) {
        return (
          <Observer onChange={handleChangeObserver}>{originNode}</Observer>
        );
      }
      return originNode;
    },
  };

  const tableBulkAction: BulkActionPropTypes = {
    type: BulkActionType.Destructive,
    constructive: {
      // based on CTA
      label: `Submit Drafts for ${orgDetail?.data?.payload?.isUseApprovalSystem ? "Approval" : "Payment"}`,
      handler: () => {
        //need to shorten url here
        // we can use lz-string for encoding if we require 3rd party tool
        const encodedBillIds = btoa(selectedRowKeys.join(","));
        history.push(
          `${location.pathname}/bulk?data=${selectAll ? "all" : encodedBillIds}`,
        );
      },
    },
    destructive: {
      label: "Delete Drafts",
      handler: () => {
        setDeleteDraftModalVisible(true);
        return Promise.resolve(true);
      },
    },
    dataName: `draft`,

    data: new Array(getSelectedRowLength()).fill(true),
    supportingInfo: null,
    clearSelection: resetRowSelection,
  };

  const columns = getColumns(
    {
      onClickAttachment: handleClickAttachment,
    },
    saasConfig,
  );

  const withFilter = Boolean(
    query.keyword ||
      query.startDueDate ||
      query.endDueDate ||
      query.startScheduledDate ||
      query.endScheduledDate ||
      query.startCreationDate ||
      query.endCreationDate ||
      query.sortBy ||
      query.sortDir,
  );

  return showPreviewBulk || encodedBillIds ? (
    <PreviewBulk
      selectedAll={selectAll}
      billIDs={selectAll ? [] : encodedBillIds}
      onClose={() => {
        history.push(`/bills/drafts`);
      }}
    />
  ) : (
    <div className={styles.draft}>
      <DraftUpload mutateDraftList={mutate} />
      {isLoadingInitialData ? (
        <LoaderIcon />
      ) : !isEmpty || withFilter ? (
        <>
          <DragDropButton
            onUpload={onUpload}
            description="Save partially created bills to your account for submission later"
          />
          <div className={styles.filterContainer} data-testid="draft-filter">
            <DraftFilter />
          </div>
          <DataTable
            className={styles.draftTable}
            size={DATA_TABLE_SIZE_TYPES.LARGE}
          >
            <Table<DraftColumn>
              loading={isLoadingMore || isRefreshing}
              customEmptyState={
                <div className={styles.emptyDraftContainer}>
                  <img
                    src={dataTableNoRecordFound}
                    alt="draft not found"
                    width={120}
                    height={120}
                  />
                  <p className={styles.emptyTitle}>No Draft Found</p>
                  <p className={styles.content}>
                    Change or remove selected filters to see drafts
                  </p>
                </div>
              }
              rowSelection={tableRowSelection}
              bulkAction={tableBulkAction}
              rowAction={tableRowAction}
              paginationEnabled={false}
              dataSource={list}
              scrollHorizontal="fit-content"
            >
              {columns}
            </Table>
          </DataTable>
        </>
      ) : (
        <div className={styles.emptyDraftContainer}>
          <img
            src={dataTableNoRecordFound}
            alt="You have yet to create any drafts"
            width={120}
            height={120}
          />
          <p className={styles.emptyTitle}>You have yet to create any drafts</p>
          <p className={styles.content}>
            Created drafts will only be visible to you
          </p>
          <div className={styles.btnContainer}>
            <button onClick={onUpload} className={styles.browseBtn}>
              <Icon icon="add" className={styles.icon} size="16" />
              <span>Browse or drop a file here to start</span>
            </button>
          </div>
        </div>
      )}
      <FilePreview
        isOpen={Boolean(previewFile)}
        onClose={() => {
          setPreviewFile(undefined);
        }}
        data={previewFile}
      />
      <EditBillModal
        title={"Edit Draft"}
        visible={isEditBillVisible}
        billID={editBillID}
        type={BillFormTypes.Draft}
        footerType={
          isBulkEdit
            ? BillFooterTypes.DraftWithoutSubmit
            : BillFooterTypes.Draft
        }
        onClose={onEditBillClose}
        refreshList={mutate}
      />
      <Modal
        visible={showFxModal}
        close={() => {
          setShowFxModal(false);
        }}
      >
        <Title>Previewing Drafts</Title>
        <CloseButton fixToTop />
        <div className={styles.refreshFxPreview}>
          <img src={refreshFxRate} alt="refresh fx icon" />
          <div>Fetching current exchange rates</div>
          <Loader />
        </div>
      </Modal>
      {deleteDraftModal}
      <Toaster
        size={TOASTER_SIZE_TYPE.M}
        onClose={clearToasterData}
        {...toasterData}
      />
    </div>
  );
};

export default ManageDraft;
