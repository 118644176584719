import React from "react";
import Completed from "../Completed";
import PendingReview from "../PendingReview";
import PendingSync from "../PendingSync";
import { AccountingCount, EWorkflowApiStatus, ITabConfig } from "../type";

export const defaultConfig: (
  countConfig: AccountingCount,
  connectedUsingExpenseIntegration: boolean,
  bankfeedWithExpenseIntegration: boolean
) => ITabConfig[] = (countConfig, connectedUsingExpenseIntegration, bankfeedWithExpenseIntegration) => {
  return [
    {
      id: 1,
      key: "Pending Review",
      title: "Pending Review",
      Component: PendingReview,
      enabled: true,
      count: countConfig[EWorkflowApiStatus.MISSING_INFO] + countConfig[EWorkflowApiStatus.PENDING_REVIEW],
    },
    {
      id: 2,
      key: "Pending Sync",
      title: "Pending Sync",
      Component: PendingSync,
      count: countConfig[EWorkflowApiStatus.QUEUED] + countConfig[EWorkflowApiStatus.FAILED_SYNC],
      enabled: bankfeedWithExpenseIntegration || connectedUsingExpenseIntegration,
    },
    {
      id: 3,
      key: "Completed",
      title: "Completed",
      Component: Completed,
      enabled: true,
    },
  ];
};

export const useTabConfig = (
  countConfig: AccountingCount,
  connectedUsingExpenseIntegration: boolean,
  bankfeedWithExpenseIntegration: boolean
) => {
  const tabsConfig = defaultConfig(
    countConfig,
    connectedUsingExpenseIntegration,
    bankfeedWithExpenseIntegration
  ).filter((config) => config.enabled);

  return [tabsConfig];
};
