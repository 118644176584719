export * from "./SmartCSV";

export enum TRANSACTION_ANALYTICS {
  TAB_VIEWED = "Transaction Tab Viewed",
}

export enum TRANSACTION_TABS {
  ALL = "All",
  BILLS = "Bills",
  SCHEDULED = "Scheduled International Transaction",
}
