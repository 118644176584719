import React from "react";
import { IRouterType, PAGE_GRID_CONTENT_TYPE } from "Route/@types";
import { EMPLOYEE_PATHS } from "Route/Employee/paths";

//Employee Components
import Employee from "Views/Employee";
import AddEmployee from "Views/Employee/Create/Page";

export const EmployeeRoute: IRouterType[] = [
  {
    id: "employee-1",
    path: EMPLOYEE_PATHS.EMPLOYEES,
    component: <Employee />,
    title: "Employee list",
    authRequired: true,
    exactPath: true,
  },
  {
    id: "employee-2",
    path: EMPLOYEE_PATHS.ADD_EMPLOYEES,
    component: <AddEmployee />,
    title: "Add Employee",
    authRequired: true,
    exactPath: true,
    grid: {
      content: PAGE_GRID_CONTENT_TYPE.FIXED,
    },
  },
];
