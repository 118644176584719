import useSWR, { SWRConfiguration, useSWRConfig } from "swr";
import { getData } from "./Client";

import { getCookieValue, cookieNames } from "utility/CookieHelper";

export function useMutableData(
  keys: string | (() => string) | any[] | null,
  options?: SWRConfiguration,
) {
  let url = keys;
  let otherKeys = [];
  if (Array.isArray(keys) && keys.length >= 1) {
    const [urlKey, ...others] = keys;
    url = urlKey;
    otherKeys = others;
  }
  const swr = useSWR(
    Boolean(url)
      ? [url, getCookieValue(cookieNames.AUTH_TOKEN), ...otherKeys]
      : null,
    ([url]: string[]) => {
      return getData(url);
    },
    {
      revalidateOnFocus: false,
      ...options,
    },
  );

  // IMPROVEMENT: Should provide error handler.

  return {
    ...swr,
    isLoading:
      keys !== null
        ? typeof swr.data === "undefined" && !swr.error
        : swr.isLoading,
  };
}

// https://swr.vercel.app/docs/advanced/cache#mutate-multiple-keys-from-regex
export function useMatchMutate() {
  const { cache, mutate } = useSWRConfig();
  return (matcher, ...args) => {
    if (!(cache instanceof Map)) {
      throw new Error(
        "matchMutate requires the cache provider to be a Map instance",
      );
    }
    const keys = [];
    for (let key of Array.from(cache.keys())) {
      if (matcher.test(key)) {
        keys.push(key);
      }
    }

    const mutations = keys.map((key) => mutate(key, ...args));
    return Promise.all(mutations);
  };
}
