import {
  FETCH_ADDPOLICY_FAILURE,
  FETCH_ADDPOLICY_REQUEST,
  FETCH_ADDPOLICY_SUCCESS,
  FETCH_POLICY_FAILURE,
  FETCH_POLICY_REQUEST,
  FETCH_POLICY_SUCCESS,
  RESET_TEAM_POLICY,
} from "../Actions/teamPolicyAction";
import { userInitialState } from "../InitialState";

export const teamPolicyReducer = (
  state = {
    ...userInitialState,
    selectedTeamId: null,
    createData: {},
    addMemberError: false,
    addMemberLoading: false,
    addPolicyData: {},
  },
  action
) => {
  switch (action.type) {
    case FETCH_POLICY_REQUEST:
      return {
        ...state,
        data: action.data,
        loading: true,

        error: false,
        errorMessage: {},
      };
    case FETCH_POLICY_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
        allTeams: action.allTeams,

        error: false,
        errorMessage: {},
      };

    case FETCH_POLICY_FAILURE:
      return {
        ...state,
        addloading: false,
        loading: false,
        data: {},
        error: true,
        errorMessage: action.data,
      };
    case FETCH_ADDPOLICY_REQUEST:
      return {
        ...state,
        loading: false,
        policyData: action.createData,
        error: false,
      };
    case FETCH_ADDPOLICY_SUCCESS:
      return {
        ...state,
        loading: false,
        policyData: action.createData,
        error: false,
      };
    case FETCH_ADDPOLICY_FAILURE:
      return {
        ...state,
        loading: false,
        policyData: action.createData,
        error: true,
      };
    case RESET_TEAM_POLICY:
      return {
        ...state,
        loading: false,
        policyData: {},
        error: false,
      };
    default:
      return state;
  }
};
