import React from "react";

import { IStartIntro } from "Views/KYX/@types";
import { IntroLoader as Loader } from "Views/KYX/Loader";
import { getCookieValue, cookieNames } from "utility/CookieHelper";
import { KYB_SOURCE_LIST, KYX_ORG_COUNTRY_LIST } from "Views/KYX/Constants";

import { WithoutAutomatedSource } from "./Manual";
import { AutomatedSource } from "./MyInfo";

const ReturningUser = ({ companyName, countryCode }: IStartIntro) => {
  let Component: JSX.Element;
  const KYBSource = getCookieValue(cookieNames.KYB_SOURCE);

  switch (true) {
    case countryCode === KYX_ORG_COUNTRY_LIST.IDR:
      Component = <WithoutAutomatedSource companyName={companyName} />;
      break;
    case countryCode === KYX_ORG_COUNTRY_LIST.SGD && KYBSource === KYB_SOURCE_LIST.MANUAL:
      Component = <WithoutAutomatedSource companyName={companyName} />;
      break;
    case countryCode === KYX_ORG_COUNTRY_LIST.SGD && KYBSource === KYB_SOURCE_LIST.MY_INFO:
      Component = <AutomatedSource companyName={companyName} />;
      break;
    default:
      Component = <Loader />;
      break;
  }

  return Component;
};

export default ReturningUser;
