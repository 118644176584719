import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Form, Input } from "antd";
import Button from "Modules/button";
import { getMerchantName } from "Views/TransactionsListing/TransactionsHelper";
import { AlreadyReconciledMessage } from "constants/Integration.constant";
import AccountingTags from "Modules/AccountingTags";

const TransactionRefundEdit = (props) => {
  const { transactionData, onEdit, editedData, loading, initialTags, showAccountingTags = true } = props;
  const [form] = Form.useForm();
  const [tags, setTags] = useState(initialTags);
  const accountingPartnerAuth = useSelector((state) => state.accountingPartnerAuthReducer.data);

  useEffect(() => {
    if (editedData) {
      form.setFieldsValue(editedData);
    }
  }, [editedData]);

  const onFinish = async (values) => {
    const { simplified_merchant_name } = values;
    const isReconciled = transactionData.xero_syncs?.[0]?.partner_transaction_id;
    const isConnectedToXero = accountingPartnerAuth?.payload?.has_valid_token;

    const hasDataChange = detectChange(simplified_merchant_name, tags);
    const needToShowWarning = isReconciled && hasDataChange && isConnectedToXero;

    onEdit(needToShowWarning, hasDataChange, {
      message: AlreadyReconciledMessage.withoutReceipt,
      data: { simplified_merchant_name, tags, transaction_id: transactionData.id },
    });
  };

  const detectChange = (simplified_merchant_name, chosenTags) => {
    if (simplified_merchant_name !== transactionData?.simplified_merchant_name) {
      return true;
    }

    if (chosenTags.length !== initialTags.length) {
      return true;
    }

    for (let i = 0; i < chosenTags.length; i++) {
      if (chosenTags[i].value !== initialTags[i]?.value) {
        return true;
      }
    }

    return false;
  };

  return (
    <Form form={form} name={"transaction-refund-edit"} onFinish={onFinish}>
      {showAccountingTags && (
        <Form.Item>
          <AccountingTags
            onChange={(chosenTags) => setTags(chosenTags)}
            initialTags={editedData?.tags || initialTags}
          />
        </Form.Item>
      )}
      <Form.Item
        label={"Merchant Name"}
        name="simplified_merchant_name"
        initialValue={getMerchantName(transactionData)}
        rules={[{ required: true, message: "Merchant Name is required" }]}
      >
        <Input className={"expense-details-edit__notes"} placeholder="Merchant Name" />
      </Form.Item>
      <Form.Item shouldUpdate>
        {() => <Button rounded loading={loading} className={"confirm-btn"} text={"Save"} />}
      </Form.Item>
    </Form>
  );
};

TransactionRefundEdit.propTypes = {
  transactionData: PropTypes.any,
  onEdit: PropTypes.func,
  onEditConfirmation: PropTypes.func,
  editedData: PropTypes.object,
  loading: PropTypes.bool,
};

export default TransactionRefundEdit;
