import React from "react";

import { IColumnData } from "Modules/DS/DataTable";

import { InputCell } from "Views/Transaction/Shimmer";
import { ALL_TRXNS_COLUMNS } from "Views/Transaction/Constants";
import { IGetTrxnResponse, GET_TRXN_RESPONSE_PARAMS } from "Views/Transaction/@types";

export const TypeCell = ({ children }: { children: string }) => {
  return (
    <InputCell>
      <p>{children}</p>
    </InputCell>
  );
};

export const type = (): IColumnData<IGetTrxnResponse>["column"] => ({
  title: ALL_TRXNS_COLUMNS.TYPE,
  key: GET_TRXN_RESPONSE_PARAMS.TRXN_TYPE,
  width: 160,
  dataIndex: GET_TRXN_RESPONSE_PARAMS.TRXN_TYPE,
  render: (value: string): React.ReactNode => <TypeCell>{value}</TypeCell>,
});
