import React from "react";

import { TrxnShimmerContext } from "Views/Transaction/Context";
import { ITrxnShimmerContext } from "Views/Transaction/@types";

export const TrxnShimmerProvider = ({
  children,
  loading,
}: {
  children: React.ReactNode;
  loading: boolean;
}): JSX.Element => {
  const shimmer: ITrxnShimmerContext = {
    loading,
  };

  return <TrxnShimmerContext.Provider value={shimmer}>{children}</TrxnShimmerContext.Provider>;
};
