import React, { useMemo, useState } from "react";

import { Input } from "@spenmo/splice";
import LoadingComponent from "Views/State/Loading/LoaderIcon";
import { LabelWithTooltip, Select } from "Views/SubscriptionManagement/Create/Components";
// import { PaymentMethod } from "./PaymentMethod";
// import { PaymentLimit } from "./PaymentLimit";

import { useTeamWithMembers } from "Views/SubscriptionManagement/hooks/useTeamWithMembers";
import { option } from "Views/SubscriptionManagement/Create/options";
import { useFormContext, usePaymentContext, initialPaymentContext } from "Views/SubscriptionManagement/Create/Context";
import { useSubscriptionContext } from "Views/SubscriptionManagement/Details/SubscriptionContext";
import { FORM_NAMES } from "Views/SubscriptionManagement/Constants";
// import { KYC_STATUS } from "Views/Card/types";

import styles from "Views/SubscriptionManagement/Create/styles.module.scss";

export const SecondStep = () => {
  const [budgetSearchKeyword, setBudgetSearchKeyword] = useState("");
  const [ownerSearchKeyword, setOwnerSearchKeyword] = useState("");

  const { watch, control, setValue, clearErrors } = useFormContext();
  const { subscription } = useSubscriptionContext();
  const { setPaymentData } = usePaymentContext();

  const [budgetId, ownerId] = watch([FORM_NAMES.BUDGET_ID, FORM_NAMES.OWNER_ID]);
  const { data: teamWithMembers, isLoading } = useTeamWithMembers();

  const teamMembers = useMemo(
    () => teamWithMembers?.find((team) => team?.id === budgetId)?.members || [],
    [teamWithMembers, budgetId]
  );

  // const selectedUserKycStatus = useMemo(() => {
  //   return (ownerId && teamMembers?.find((member) => member?.id === ownerId)?.kycStatus) || "";
  // }, [ownerId, teamMembers]);

  const resetPaymentData = () => {
    setValue(FORM_NAMES.CARD_ID, null);
    setValue(FORM_NAMES.PAYMENT_METHOD, null);
    clearErrors([FORM_NAMES.CARD_ID]);
    setPaymentData((prevState) => ({
      ...prevState,
      cardData: {
        ...initialPaymentContext.cardData,
      },
    }));
  };

  const findUserDefaultValue = () => {
    if (!ownerId) {
      return null;
    }

    if (option.owner(teamMembers).find((item) => item.id === ownerId)) {
      return ownerId;
    }

    return subscription?.owner?.name || "";
  };

  if (isLoading) {
    return <LoadingComponent />;
  }

  const onOwnerChange = (selectedId: string) => {
    const name = teamMembers?.find((member) => member.id === selectedId)?.name || "";
    setValue(FORM_NAMES.OWNER_NAME, name);
    resetPaymentData();
  };

  return (
    <>
      <div className={styles.field}>
        <Input.Label>Budget</Input.Label>
        <Select
          name={FORM_NAMES.BUDGET_ID}
          control={control}
          options={option.budget(teamWithMembers)}
          placeholder="Select a budget"
          onChange={() => {
            setValue(FORM_NAMES.OWNER_ID, null);
            resetPaymentData();
          }}
          requiredErrorMessage="Select a budget"
          searchValue={budgetSearchKeyword}
          onSearch={setBudgetSearchKeyword}
          optionFilterProp="label"
          showSearch
        />
      </div>
      {Boolean(budgetId) && (
        <div className={styles.field}>
          <LabelWithTooltip title="Owner" tooltipTitle="This is the person-in-charge for managing this subscription" />
          <Select
            name={FORM_NAMES.OWNER_ID}
            control={control}
            options={option.owner(teamMembers)}
            placeholder="Select a user"
            requiredErrorMessage="Select a user"
            value={findUserDefaultValue()}
            onChange={onOwnerChange}
            searchValue={ownerSearchKeyword}
            onSearch={setOwnerSearchKeyword}
            optionFilterProp="label"
            showSearch
          />
        </div>
      )}
      {/* {Boolean(budgetId) && Boolean(ownerId) && (
        <PaymentMethod selectedOwnerKycNotApproved={selectedUserKycStatus !== KYC_STATUS.APPROVED} />
      )}
      <PaymentLimit /> */}
    </>
  );
};
