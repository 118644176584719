import * as React from "react";
import { useEffect, useMemo } from "react";
import CardDetailsEditContext, {
  ICardEditFieldDetails,
  CARD_DETAILS_FIELDS_MAP,
  SpendingLockEditContext,
  ISpendingLockEditDetails,
} from "Views/Card/Edit/index.context";
import { CARD_STATUS, CARD_TYPE, ICardDetails, SPENDING_LOCK_TYPES } from "Views/Card/types";
import { URL_PARAMS_KEYS } from "Views/Card/Edit/types";
import { backArrowGrey40x40 } from "assets/icons/card/";
import { getParamsValue } from "utility";
import { CARD_PATHS } from "Route/Card/path";
import useCheckFeatureStatus from "customHooks/featureCheck";
import { SPLIT_NAMES, SPLIT_TREATMENT_TYPES } from "Redux/splitio/constants";

import Drawer from "Views/Card/Components/Drawer";
import CardTitle from "Views/Card/Edit/Title";
import CardPolicy from "Views/Card/Edit/Policy";
import CardPin from "Views/Card/Edit/Pin";
import ActionBtns from "Views/Card/Edit/ActionBtns";
import SpendingLock from "Views/Card/Edit/Policy/SpendingLock/Drawer";
import CardColor from "Views/Card/Edit/Color";
import { Tooltip } from "Views/Card/Components/Tooltip";
import { infoIconBlue16x16 } from "assets/icons/card";

import "Views/Card/Edit/index.scss";

interface CardDetailsProps {
  cardData: ICardDetails;
  onClose: () => void;
  onSuccessfulEdit: (updatedData) => void;
  onBackArrowClick: () => void;
  onSetOrChangePin: () => void;
  onSubscriptionPage?: boolean;
}

const CardDetails = ({
  cardData,
  onClose,
  onSuccessfulEdit,
  onBackArrowClick,
  onSetOrChangePin,
  onSubscriptionPage = false,
}: CardDetailsProps) => {
  const treatment = useCheckFeatureStatus(SPLIT_NAMES.removeCardsColorSelection, true);
  const showCardColorSelection = treatment === SPLIT_TREATMENT_TYPES.OFF;
  const {
    cardTitle,
    spendingLimit,
    spendingLimitType,
    expenseCategoryId,
    cardColor,
    id,
    cardType,
    isCardRequestDetails,
    expenseCategoryName,
    cardStatus,
    letMyManagerChooseLimit,
    tags,
    spendingLockType,
    spendingLocks,
  } = cardData || {};

  const [cardDetailsEditState, setCardDetailsEditState] = React.useState<ICardEditFieldDetails>(() => ({
    [CARD_DETAILS_FIELDS_MAP.TITLE]: cardTitle,
    [CARD_DETAILS_FIELDS_MAP.SPENDING_LIMIT]: spendingLimit,
    [CARD_DETAILS_FIELDS_MAP.SPENDING_LIMIT_TYPE]: spendingLimitType,
    [CARD_DETAILS_FIELDS_MAP.SPENDING_LOCKS]: spendingLocks,
    [CARD_DETAILS_FIELDS_MAP.SPENDING_LOCK_TYPE]: spendingLockType,
    [CARD_DETAILS_FIELDS_MAP.EXPENSE_CATEGORY_ID]: expenseCategoryId,
    [CARD_DETAILS_FIELDS_MAP.EXPENSE_CATEGORY_NAME]: expenseCategoryName,
    [CARD_DETAILS_FIELDS_MAP.COLOR]: cardColor,
    [CARD_DETAILS_FIELDS_MAP.ID]: id,
    [CARD_DETAILS_FIELDS_MAP.LET_MY_MANAGER_CHOOSE_LIMIT]: letMyManagerChooseLimit,
    [CARD_DETAILS_FIELDS_MAP.TAGS]: tags,
    [CARD_DETAILS_FIELDS_MAP.IS_CATEGORY_LOCK_ADDED_FROM_URL]: false,
  }));
  const [spendingLockEditDetails, setSpendingLockEditDetails] = React.useState<ISpendingLockEditDetails | null>({
    showDrawer: false,
    selectedSpendingLockType: SPENDING_LOCK_TYPES.NONE,
  });

  const showSpendingLockFromUrl = () => {
    const [toBeAddedCategoryLock, toBeAddedCategoryLockName] = getParamsValue(
      URL_PARAMS_KEYS.TO_BE_ADDED_CATEGORY_LOCK_ID,
      URL_PARAMS_KEYS.TO_BE_ADDED_CATEGORY_LOCK_NAME
    );
    if (toBeAddedCategoryLock && toBeAddedCategoryLockName) {
      setSpendingLockEditDetails({
        showDrawer: true,
        selectedSpendingLockType: SPENDING_LOCK_TYPES.CATEGORY,
      });
      setCardDetailsEditState((prevState) => ({
        ...prevState,
        [CARD_DETAILS_FIELDS_MAP.IS_CATEGORY_LOCK_ADDED_FROM_URL]: true,
      }));
    }
  };

  useEffect(() => {
    showSpendingLockFromUrl();
  }, []);

  const context = { cardDetailsEditState, setCardDetailsEditState, cardData };
  const spendingLockEditDetailsContext = useMemo(
    () => ({ spendingLockEditDetails, setSpendingLockEditDetails }),
    [spendingLockEditDetails]
  );

  const CategoryLockTitle = (
    <div className="categoryLockTitle">
      Set Category Lock
      <a
        href={`${window?.location?.origin}${CARD_PATHS.CATEGORY_LOCK_KB_ARCTILE}`}
        target="_blank"
        onClick={(event) => event?.stopPropagation()}
        rel="noreferrer"
      >
        <Tooltip tooltipText="Tap to learn more about Categories" placement="top">
          <img src={infoIconBlue16x16} alt="Info Icon" />
        </Tooltip>
      </a>
    </div>
  );

  const getDrawerTitle = () => {
    const { showDrawer, selectedSpendingLockType } = spendingLockEditDetails || {};
    if (showDrawer) {
      return selectedSpendingLockType === SPENDING_LOCK_TYPES.CATEGORY ? CategoryLockTitle : "Set Merchant Lock";
    }
    return isCardRequestDetails ? "Edit Card Request" : "Card Settings";
  };

  const CardDetailsEditBody = (
    <>
      <div className="card-edit">
        <div className="card-edit__container">
          <CardTitle />

          <CardPolicy />

          {cardType === CARD_TYPE.PHYSICAL && !isCardRequestDetails && (
            <CardPin onSetOrChangePin={onSetOrChangePin} disablePinChangeBtn={cardStatus !== CARD_STATUS.ENABLED} />
          )}
          {cardType === CARD_TYPE.VIRTUAL && showCardColorSelection && <CardColor />}
        </div>
        <ActionBtns onCancel={onClose} onSuccessfulEdit={onSuccessfulEdit} sendApiPayload={onSubscriptionPage} />
      </div>
    </>
  );

  const closeSpendingLockEditOrEditModal = (closeFn: () => void) => {
    const { showDrawer } = spendingLockEditDetails || {};
    return showDrawer ? setSpendingLockEditDetails(null) : closeFn();
  };

  return (
    <Drawer
      title={getDrawerTitle()}
      width={480}
      onClose={() => closeSpendingLockEditOrEditModal(onClose)}
      withOverlay={onSubscriptionPage}
    >
      <img
        src={backArrowGrey40x40}
        className="card-edit__back-arrow"
        width={40}
        height={40}
        onClick={() => closeSpendingLockEditOrEditModal(onBackArrowClick)}
        data-testid="back-arrow-icon"
        alt="Back Arrow"
      />
      <CardDetailsEditContext.Provider value={context}>
        <SpendingLockEditContext.Provider value={spendingLockEditDetailsContext}>
          {spendingLockEditDetails?.showDrawer ? (
            <SpendingLock onClose={() => setSpendingLockEditDetails(null)} onSubscriptionPage={onSubscriptionPage} />
          ) : (
            CardDetailsEditBody
          )}
        </SpendingLockEditContext.Provider>
      </CardDetailsEditContext.Provider>
    </Drawer>
  );
};

export default CardDetails;
