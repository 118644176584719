export enum NAVIGATION_EVENTS {
  HOME = "Home",
  TOP_UP = "Top up",
  CARDS = "Cards",
  TRANSACTIONS = "Transactions",
  REQUESTS = "Reimbursement & Requests",
  SUBSCRIPTIONS = "Subscriptions",
  BILLS = "Bill Payments",
  PAY_BILLS = "sidebar pay bills clicked",
  TEAMS = "Teams",
  ACCOUNTING = "Accounting",
  BANK_ACCOUNT = "settings_bank_accounts",
  CATEGORIES = "settings_categories",
  TAX = "settings_tax",
  INTEGRATIONS = "sidebar settings integrations clicked",
  USERS = "settings_employees",
  APPROVAL_FLOW = "settings_approval_flow",
  RESET_PASSWORD = "sidebar settings reset password clicked",
  LOW_BALANCE_REMINDER = "settings_low_balance_notification",
  SECURITY_HUB = "settings_security_hub",
  LOGOUT = "logout clicked",
  APPROVALS = "Approvals",
  ACCOUNTING_SETTING = "settings_accounting",
  SIDEBAR_MENU_CLICKED = "Sidebar Menu Clicked",
  SETTINGS = "Settings",
  ANALYTICS = "Analytics",
}
