import { useForm } from "react-hook-form";
import { ICreateForm } from "Views/SubscriptionManagement/@types";
import { FORM_NAMES, REVIEW_CONTRACT_DURATION } from "Views/SubscriptionManagement/Constants";
import { getCookieValue, cookieNames } from "utility/CookieHelper";

export const getDefaultValues = (): ICreateForm => ({
  [FORM_NAMES.VENDOR_ID]: null,
  [FORM_NAMES.VENDOR_NAME]: "",
  [FORM_NAMES.CURRENCY]: getCookieValue(cookieNames.CURRENCY_CODE),
  [FORM_NAMES.ESTIMATED_AMOUNT]: null,
  [FORM_NAMES.VARIATION_PERCENTAGE]: 0,
  [FORM_NAMES.BILLING_FREQUENCY]: null,
  [FORM_NAMES.DESCRIPTION]: "",
  [FORM_NAMES.CONTRACT_TYPE]: null,
  [FORM_NAMES.START_DATE]: null,
  [FORM_NAMES.END_DATE]: "",
  [FORM_NAMES.NEXT_BILLING_DATE]: "",
  [FORM_NAMES.ROLLING_FREQUENCY]: null,
  [FORM_NAMES.REVIEW_CONTRACT_DURATION]: REVIEW_CONTRACT_DURATION.IN_1_MONTH,
  [FORM_NAMES.DOCUMENTS]: [],
  [FORM_NAMES.NOTES]: "",
  [FORM_NAMES.BUDGET_ID]: null,
  [FORM_NAMES.OWNER_ID]: null,
  [FORM_NAMES.OWNER_NAME]: "",
  [FORM_NAMES.PAYMENT_METHOD]: null,
  [FORM_NAMES.CARD_ID]: null,
  [FORM_NAMES.PAYMENT_LIMIT]: null,
});

export const useSubscriptionManagementForm = (initialValues: Partial<ICreateForm>) =>
  useForm({
    defaultValues: { ...getDefaultValues(), ...(initialValues || {}) },
  });
