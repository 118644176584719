import APIclient from "API/Client";
import { GetFormData, parseToURLEncodedForm } from "../../utility/APIWrapper";
import { trackEvent } from "utility/analytics";
import { GetOrgId } from "utility";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";

export const FETCH_REIMBURSE_REQUEST = "FETCH_REIMBURSE_REQUEST";
export const FETCH_REIMBURSE_SUCCESS = "FETCH_REIMBURSE_SUCCESS";
export const FETCH_REIMBURSE_FAILURE = "FETCH_REIMBURSE_FAILURE";

export const FETCH_ADD_REQUEST = "FETCH_ADD_REQUEST";
export const FETCH_ADD_SUCCESS = "FETCH_ADD_SUCCESS";
export const FETCH_ADD_FAILURE = "FETCH_ADD_FAILURE";
export const FETCH_ADD_CLEAR = "FETCH_ADD_CLEAR";

export const fetchDataReq = (data) => {
  return {
    type: FETCH_REIMBURSE_REQUEST,
    data: data,
  };
};
export const fetchDataSuccess = (data) => {
  return {
    type: FETCH_REIMBURSE_SUCCESS,
    data: data,
  };
};
export const fetchDataError = (data) => {
  return {
    type: FETCH_REIMBURSE_FAILURE,
    data: data,
  };
};

export const fetchAddDataReq = (data) => {
  return {
    type: FETCH_ADD_REQUEST,
    data: data,
  };
};
export const fetchAddDataSuccess = (data) => {
  return {
    type: FETCH_ADD_SUCCESS,
    data: data,
  };
};
export const fetchAddDataError = (data) => {
  return {
    type: FETCH_ADD_FAILURE,
    data: data,
  };
};

export const clearDeleteFundData = () => {
  return {
    type: FETCH_ADD_CLEAR,
  };
};

const trackReimbursementEditAndAddRequest = (success, data = {}) => {
  trackEvent(`CR Submission Confirm Processed`, {
    cr_submission_success: success ? "success" : "fail",
    ...(data && { cr_submission_fail_reason: data }),
  });
};

export const reimburseActionFunc = (requestId, type, reason = "") => {
  // const orgId = GetOrgId();
  const data = {
    expense_id: requestId,
    status: type === "decline" ? "rejected" : "approved",
  };
  if (type === "decline" && reason.length > 0) {
    data.reason = reason;
  }
  // console.log(requestId, type, reason);
  console.log(data);
  return (dispatch) => {
    dispatch(fetchDataReq());
    APIclient.postData("/api/resolveExpense", data)
      .then((data) => {
        console.log(data);
        dispatch(fetchDataSuccess(data.data));
      })
      .catch((err) => {
        console.log(err);
        dispatch(fetchDataError(err));
      });
  };
};

export const AddReimburseFunc = (data, onFinish = () => null) => {
  return (dispatch) => {
    dispatch(fetchAddDataReq());
    APIclient.postData("/api/v1/fund/request", GetFormData({ ...data }))
      .then((data) => {
        if (data.data.status > 200) {
          dispatch(fetchAddDataError(data.data));
          trackReimbursementEditAndAddRequest(false, data.data);
        } else {
          data.data.payload.status_message = "Cash reimbursement request successfully sent!";
          dispatch(fetchAddDataSuccess(data.data));
          trackReimbursementEditAndAddRequest(true);
          onFinish?.();
        }
      })
      .catch((err) => {
        dispatch(fetchAddDataError(err));
        trackReimbursementEditAndAddRequest(false, data.data);
      });
  };
};

export const editReimburseFunc = (data, requestId, onFinish = () => null) => {
  return (dispatch) => {
    dispatch(fetchAddDataReq());
    APIclient.putData(`/api/v1/fund/request/reimbursement/${requestId}`, parseToURLEncodedForm({ ...data }), false, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((data) => {
        if (data.data.status > 200) {
          dispatch(fetchAddDataError(data.data));
          trackReimbursementEditAndAddRequest(false);
        } else {
          data.data.payload.status_message = "Cash reimbursement request successfully sent!";
          dispatch(fetchAddDataSuccess(data.data));
          trackReimbursementEditAndAddRequest(true);
          onFinish?.();
        }
      })
      .catch((err) => {
        dispatch(fetchAddDataError(err));
        trackReimbursementEditAndAddRequest(false);
      });
  };
};

export const deleteTeamPendingFundTransfer = (requestId) => {
  return (dispatch, getState) => {
    const url = `/api/v1/fund/request/delete/${requestId}`;
    dispatch(fetchAddDataReq());
    APIclient.putData(url, { organisation_id: GetOrgId() })
      .then((data) => {
        if (data.data.status === HTTP_STATUS_CODE.OK) {
          dispatch(fetchAddDataSuccess(data.data));
        } else {
          dispatch(fetchAddDataError(data.data));
        }
      })
      .catch((err) => {
        dispatch(fetchAddDataError(err));
      });
  };
};
