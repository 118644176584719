import { ApiCallState, NetworkCallState, Pagination } from "./models";

export const newApiCallState = (assignSource?: Partial<ApiCallState>): ApiCallState => {
  return Object.assign(
    {
      paging: newPagination(),
      loading: NetworkCallState.unresolved,
      error: false,
      message: "",
    },
    assignSource
  );
};

export const newPagination = (assignSource?: Partial<Pagination>): Pagination => {
  return Object.assign(
    {
      currentPage: 1,
      totalPage: 1,
      limit: 10,
      total: 0,
      isEmpty: true,
    },
    assignSource
  ) as Pagination;
};
