import React from "react";
import cn from "classnames";

import styles from "./ProgressBar.module.scss";

interface ProgressBarProps {
  className?: string;
  percentage: number; // 0 - 100
  isError?: boolean;
}

const ProgressBar = (props: ProgressBarProps) => {
  const { className, percentage, isError } = props;
  return (
    <div className={cn(styles.barContainer, className)}>
      <div
        className={cn(styles.progressBar, {
          [styles.failed]: isError,
        })}
        style={{
          width: `${percentage || 0}%`,
        }}
      />
    </div>
  );
};

export default ProgressBar;
