import React from "react";
import { ChangeLogHistoryTabs, SelfApprovalTabs } from "Views/ApprovalFlow/helpers";
export interface ListData {
  title: string;
  tabKey?: string;
  count?: number;
  isIndicator?: boolean;
  content?: (tab: ListData) => React.ReactNode;
}

export enum TAB_TYPE {
  FIXED = "fixed",
  FLUID = "fluid",
}
export interface ITabsProps {
  tabsList: ListData[] | ChangeLogHistoryTabs[] | SelfApprovalTabs[];
  content: (tab: ListData) => React.ReactNode;
  action: (activeTab: string) => void;
  defaultActiveKey?: string;
  activeKey?: string;
  type?: string;
  destroyInactiveTabPane?: boolean;
  className?: string;
  useContentInTabList?: boolean;
}
