import { putData } from "API/Client";

import { GetOrgId } from "utility";

import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";

import { API_URL } from "Views/Reimbursement/DataCalls/Services";
import { appNotification, contactCSErrorMessage } from "Modules/appNotification/appNotification";

const useCancelRequest = (): {
  cancelRequest: (id: string) => Promise<void>;
} => {
  const cancelRequest = async (id: string) => {
    try {
      const response = await putData(API_URL.DETAILS.DELETE_REQUEST(id), { organisation_id: GetOrgId() });

      if (response.data.status !== HTTP_STATUS_CODE.OK) {
        throw new Error(`${response.data.status}`);
      }

      appNotification.success({ message: "You have successfully cancelled your reimbursement request" });
    } catch (err) {
      appNotification.error(contactCSErrorMessage(err.message));
    }
  };

  return {
    cancelRequest,
  };
};

export default useCancelRequest;
