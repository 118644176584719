import React, { useState } from "react";
import { useSelector } from "react-redux";
import Filter, { DateFilter } from "Modules/DS/Filter";
import { ISingleLine } from "Views/Accounting/Workflow/TransactionFilter";
import Searchbar from "Modules/search";
import { debounce } from "utility";

import styles from "../styles.module.scss";
import RecipientFilter from "./RecipientFilter";
import SortBy from "./SortBy";

const sort: ISingleLine[] = [
  { id: "created_date_newest_first", title: "Created Date Newest First" },
  { id: "created_date_oldest_first", title: "Created Date Oldest First" },
  { id: "due_date_newest_first", title: "Due Date Newest First" },
  { id: "due_date_oldest_first", title: "Due Date Oldest First" },
  { id: "amount_highest_first", title: "Amount Highest First" },
  { id: "amount_lowest_first", title: "Amount Lowest First" },
];

interface IBillFilterProps {
  onApplyFilter: (filter: any) => void;
}

const onlySelected = (filter) => {
  return Object.entries(filter)
    .filter(([_, value]) => {
      if (Array.isArray(value)) {
        return value.length !== 0;
      }
      return Boolean(value);
    })
    .reduce((newFilter, [key, value]) => {
      newFilter[key] = value;
      return newFilter;
    }, {});
};

const DEBOUNCE_DELAY = 250;

const BillFilter: React.FC<IBillFilterProps> = ({ onApplyFilter }) => {
  const { recipient } = useSelector((state: any) => state.importBillFromXeroReducer);

  const [filter, setFilter] = useState({
    startDate: "",
    endDate: "",
    dueDateStartDate: "",
    dueDateEndDate: "",
    recipient: [],
    sort: "",
    reference: "",
  });

  const adjustFilter = (
    key: string | string[],
    value: ISingleLine | string[] | string | object,
    option = { appliedFilter: true }
  ) => {
    const { appliedFilter } = option;
    let newFilter = { ...filter };
    if (Array.isArray(key)) {
      key.forEach((item, index) => {
        newFilter[item] = value[index] || "";
      });
    } else {
      newFilter[key] = value;
    }

    setFilter(newFilter);
    if (appliedFilter) {
      onApplyFilter(onlySelected(newFilter));
    }
  };

  const handleSearchByReference = debounce((value: string) => {
    if (!filter.reference && !value) {
      return;
    }
    adjustFilter("reference", value);
  }, DEBOUNCE_DELAY);

  return (
    <Filter>
      <div className={styles.search}>
        <Searchbar
          placeholder="Search by reference number"
          getKeyUpValue={handleSearchByReference}
          clearSearch={filter.reference === "" || filter.reference === null}
        />
      </div>
      <DateFilter
        id="Date"
        filter={filter}
        onApply={(selected) => adjustFilter(["startDate", "endDate"], Object.values(selected))}
        onClear={() => adjustFilter(["startDate", "endDate"], {})}
      />
      <DateFilter
        id="Due Date"
        defaultValue="Due Date"
        filter={filter}
        onApply={(selected) => {
          adjustFilter(["dueDateStartDate", "dueDateEndDate"], Object.values(selected));
        }}
        onClear={() => {
          adjustFilter(["dueDateStartDate", "dueDateEndDate"], {});
        }}
      />
      <RecipientFilter recipient={recipient} selectedRecipient={filter.recipient} adjustFilter={adjustFilter} />
      <SortBy sort={sort} selectedSort={filter.sort} adjustFilter={adjustFilter} />
    </Filter>
  );
};

export default BillFilter;
