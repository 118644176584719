import React, { FC } from "react";
import { CopyOutline } from "@spenmo/splice";
import cn from "classnames";

import { CopyTextProps } from "Views/Bills/V2/PaymentRunPreview/type";
import styles from "./PaymentMethod.module.scss";

const CopyText: FC<CopyTextProps> = (props) => {
  const { children, text, className, ...rest } = props;
  const handleClickCopy = () => {
    navigator.clipboard.writeText(text);
  };

  return (
    <div className={cn(styles.copyTextItem, className)} {...rest}>
      {children}
      <CopyOutline iconColor="var(--icon-default)" size="16" onClick={handleClickCopy} />
    </div>
  );
};

export default CopyText;
