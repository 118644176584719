import "Views/SecurityHub/GoogleAuthenticator/ActivateGA/GaBenefitsAndHowItWorks/GaBenefitsAndHowItWorks.scss";
import React, { useState } from "react";
import { Row, Col } from "antd";
import PropTypes from "prop-types";
import { GOOGLE_AUTHENTICATOR_INFO } from "constants/SecurityHub.constant";
import OtpAuthenticationModal from "Views/SecurityHub/GoogleAuthenticator/OtpAuthenticationModal";
import Button from "Modules/button";

const GABenefitsAndHowItWorks = ({ onVerification }) => {
  const [showOtpAuthenticationModal, setShowOtpAuthenticationModal] = useState(false);
  return (
    <>
      <div className="section section--white section--full-height">
        <Row>
          <Col span={24} className="ga-info__title">
            Provide stronger security with Google Authenticator
          </Col>
          <Col span={24} className="ga-info__subtitle">
            Google Authenticator is a security feature that helps you protect your data and funds from the risk of
            account theft or hacking.
          </Col>
        </Row>
        {GOOGLE_AUTHENTICATOR_INFO.map((item) => (
          <div key={item?.title} className="ga-info__wrapper">
            <fieldset className="ga-info__fieldset">
              <legend className="ga-info__fieldset--legend">
                <p className="ga-info__fieldset--title">{item?.title}</p>
              </legend>
            </fieldset>
            <fieldset className="ga-info__fieldset ga-info__fieldset-info">
              <legend className="ga-info__fieldset--legend ga-info__fieldset-info--legend">
                <Row justify="space-between" align="middle">
                  {item?.info.map((infoItem, index) => (
                    <Col key={index} span={8} className="ga-info__fieldset--legend-item">
                      {infoItem?.iconSrc ? (
                        <img src={infoItem?.iconSrc} alt={infoItem?.iconAlt} />
                      ) : (
                        <div className="ga-info__fieldset--legend-number-icon">{index + 1}</div>
                      )}
                      <p>{infoItem?.text}</p>
                    </Col>
                  ))}
                </Row>
              </legend>
            </fieldset>
          </div>
        ))}
        <Row justify="center" align="middle">
          <Col className="ga-info__action-btn">
            <Button rounded text="Activate Google Authenticator" action={() => setShowOtpAuthenticationModal(true)} />
          </Col>
        </Row>
      </div>
      {showOtpAuthenticationModal && (
        <OtpAuthenticationModal
          onClose={() => setShowOtpAuthenticationModal(false)}
          onConfirm={onVerification}
          showDeactivateInfoFirst={false}
          otpLength={6}
        />
      )}
    </>
  );
};

GABenefitsAndHowItWorks.propTypes = {
  onVerification: PropTypes.func,
};

export default GABenefitsAndHowItWorks;
