import React, { useContext, useEffect, useState } from "react";
import { FormInstance } from "antd/lib/form";
import { RadioChangeEvent } from "antd/lib/radio";

import {
  FORM_NAME,
  KYX_FORMS_KEYS,
  PERSON_DOCUMENT_TYPE,
  ROLE_OF_INDIVIDUAL_OPTIONS,
  ROLE_OF_INDIVIDUAL_OPTIONS_VALUE,
  GENDER_OF_INDIVIDUAL_OPTIONS,
  GENDER_OF_INDIVIDUAL_OPTIONS_LABEL,
  GENDER_OF_INDIVIDUAL_OPTIONS_VALUE,
  PERSONNEL_INFORMATION_FORM_ITEM_KEYS,
  PERSONNNEL_IDENTIFICATION_DOCUMENT_TYPE_OPTIONS,
  PERSONNNEL_IDENTIFICATION_DOCUMENT_TYPE_OPTIONS_LABEL,
  PERSONNNEL_IDENTIFICATION_DOCUMENT_TYPE_OPTIONS_VALUE,
} from "Views/KYX/Constants/Forms";
import {
  IPersonForm,
  IFormElement,
  IDocumentStep,
  IGetFormConfigsPayload,
  IFetchFormSubmissionPayload,
  IMultipleHeaderAndStepInformation,
} from "Views/KYX/@types";
import { useFormSaveAsDraft } from "Views/KYX/hooks";
import { ManualFormContext } from "Views/KYX/Context";
import { getValidationError, getValidationstatus, disableFutureDates } from "Views/KYX/Helper";
import { FORM_FIELD_TYPES, DOCUMENT_STEP_TYPE, SECTION_CONTAINER_CLASS } from "Views/KYX/Constants";

import { usePersonnelIdDocumentNricTypeSGD } from "./Nric";
import { usePersonnelIdDocumentPassportTypeSGD } from "./Passport";
import { usePersonnelIdDocumentWorkPassTypeSGD } from "./Workpass";

import styles from "./index.module.scss";

export const usePersonnelInfoFormPersonDataSGD = (
  activeKey: string,
  remarks: IGetFormConfigsPayload,
  isFormDisabled: boolean
): IMultipleHeaderAndStepInformation => {
  const ROLE_OF_INDIVIDUAL: IFormElement = {
    key: FORM_NAME.PERSONNEL_INFORMATION + KYX_FORMS_KEYS.PERSONNEL_DATA_PERSONS + KYX_FORMS_KEYS.PERSON_DATA_ROLES,
    col: { span: 12 },
    element: {
      type: FORM_FIELD_TYPES.CHECKLIST,
      props: {
        formProps: {
          name: KYX_FORMS_KEYS.PERSON_DATA_ROLES,
          label: "Role of Individual",
          required: true,
          rules: [
            {
              required: true,
              message: "Please choose at least one role",
            },
          ],
          validateStatus: getValidationstatus(remarks, [
            KYX_FORMS_KEYS.PERSONNEL_DATA_PERSONS,
            activeKey,
            KYX_FORMS_KEYS.PERSON_DATA_ROLES,
          ]),
          help: getValidationError(
            remarks,
            [KYX_FORMS_KEYS.PERSONNEL_DATA_PERSONS, activeKey, KYX_FORMS_KEYS.PERSON_DATA_ROLES],
            "Role of Individual",
            FORM_NAME.PERSONNEL_INFORMATION
          ),
        },
        checkProps: {
          name: KYX_FORMS_KEYS.PERSON_DATA_ROLES,
          options: [
            {
              label: ROLE_OF_INDIVIDUAL_OPTIONS_VALUE[ROLE_OF_INDIVIDUAL_OPTIONS.AUTHORIZED_PERSON],
              value: KYX_FORMS_KEYS.PERSON_ROLES_AUTHORIZED,
              disabled: activeKey === "0",
            },
            {
              label: ROLE_OF_INDIVIDUAL_OPTIONS_VALUE[ROLE_OF_INDIVIDUAL_OPTIONS.DIRECTOR],
              value: KYX_FORMS_KEYS.PERSON_ROLES_DIRECTOR,
            },
            {
              label: ROLE_OF_INDIVIDUAL_OPTIONS_VALUE[ROLE_OF_INDIVIDUAL_OPTIONS.SHAREHOLDER],
              value: KYX_FORMS_KEYS.PERSON_ROLES_SHAREHOLDER,
            },
          ],
          disabled: isFormDisabled,
        },
      },
    },
  };

  const FULL_NAME: IFormElement = {
    key: FORM_NAME.PERSONNEL_INFORMATION + KYX_FORMS_KEYS.PERSONNEL_DATA_PERSONS + KYX_FORMS_KEYS.PERSON_DATA_FULL_NAME,
    col: { span: 24 },
    element: {
      type: FORM_FIELD_TYPES.INPUT,
      props: {
        formProps: {
          name: KYX_FORMS_KEYS.PERSON_DATA_FULL_NAME,
          label: (
            <>
              Full Name <span className={styles.fullName}>(As shown on ID)</span>
            </>
          ),
          required: true,
          rules: [
            {
              required: true,
              message: "Please enter full name",
            },
            { whitespace: true, message: "This field cannot be empty" },
          ],
          validateStatus: getValidationstatus(remarks, [
            KYX_FORMS_KEYS.PERSONNEL_DATA_PERSONS,
            activeKey,
            KYX_FORMS_KEYS.PERSON_DATA_FULL_NAME,
          ]),
          help: getValidationError(
            remarks,
            [KYX_FORMS_KEYS.PERSONNEL_DATA_PERSONS, activeKey, KYX_FORMS_KEYS.PERSON_DATA_FULL_NAME],
            "Email",
            FORM_NAME.PERSONNEL_INFORMATION
          ),
        },
        inputProps: {
          name: KYX_FORMS_KEYS.PERSON_DATA_FULL_NAME,
          placeholder: "Please enter your full name",
          disabled: isFormDisabled,
        },
      },
    },
  };

  const ALIASES: IFormElement = {
    key: FORM_NAME.PERSONNEL_INFORMATION + KYX_FORMS_KEYS.PERSONNEL_DATA_PERSONS + KYX_FORMS_KEYS.PERSON_DATA_ALIAS,
    col: { span: 12 },
    element: {
      type: FORM_FIELD_TYPES.INPUT,
      props: {
        formProps: {
          name: KYX_FORMS_KEYS.PERSON_DATA_ALIAS,
          label: "Aliases (If applicable)",
          required: false,
          validateStatus: getValidationstatus(remarks, [
            KYX_FORMS_KEYS.PERSONNEL_DATA_PERSONS,
            activeKey,
            KYX_FORMS_KEYS.PERSON_DATA_ALIAS,
          ]),
          rules: [{ whitespace: true, message: "This field cannot be empty" }],
          help: getValidationError(
            remarks,
            [KYX_FORMS_KEYS.PERSONNEL_DATA_PERSONS, activeKey, KYX_FORMS_KEYS.PERSON_DATA_ALIAS],
            "Aliases",
            FORM_NAME.PERSONNEL_INFORMATION
          ),
        },
        inputProps: {
          name: KYX_FORMS_KEYS.PERSON_DATA_ALIAS,
          placeholder: "Please enter alias",
          disabled: isFormDisabled,
        },
      },
    },
  };

  const EMPTY_SPAN: IFormElement = {
    key: PERSONNEL_INFORMATION_FORM_ITEM_KEYS.EMPTY,
    col: { span: 12 },
    element: {
      type: null,
    },
  };

  const DATE_OF_BIRTH: IFormElement = {
    key: FORM_NAME.PERSONNEL_INFORMATION + KYX_FORMS_KEYS.PERSONNEL_DATA_PERSONS + KYX_FORMS_KEYS.PERSON_DATA_DOB,
    col: { span: 12 },
    element: {
      type: FORM_FIELD_TYPES.DATEPICKER,
      props: {
        formProps: {
          name: KYX_FORMS_KEYS.PERSON_DATA_DOB,
          label: "Date of Birth",
          required: true,
          rules: [
            {
              required: true,
              message: "Please input date of birth",
            },
          ],
          validateStatus: getValidationstatus(remarks, [
            KYX_FORMS_KEYS.PERSONNEL_DATA_PERSONS,
            activeKey,
            KYX_FORMS_KEYS.PERSON_DATA_DOB,
          ]),
          help: getValidationError(
            remarks,
            [KYX_FORMS_KEYS.PERSONNEL_DATA_PERSONS, activeKey, KYX_FORMS_KEYS.PERSON_DATA_DOB],
            "Date of Birth",
            FORM_NAME.PERSONNEL_INFORMATION
          ),
        },
        dateProps: {
          name: KYX_FORMS_KEYS.PERSON_DATA_DOB,
          disabledDate: disableFutureDates,
          placeholder: "dd/mm/yyyy",
          format: "DD/MM/YYYY",
          disabled: isFormDisabled,
        },
      },
    },
  };

  const GENDER: IFormElement = {
    key: FORM_NAME.PERSONNEL_INFORMATION + KYX_FORMS_KEYS.PERSONNEL_DATA_PERSONS + KYX_FORMS_KEYS.PERSON_DATA_GENDER,
    col: { span: 12 },
    element: {
      type: FORM_FIELD_TYPES.RADIO,
      props: {
        formProps: {
          name: KYX_FORMS_KEYS.PERSON_DATA_GENDER,
          label: "Gender",
          required: true,
          rules: [
            {
              required: true,
              message: "Please input gender",
            },
            { whitespace: true, message: "This field cannot be empty" },
          ],
          validateStatus: getValidationstatus(remarks, [
            KYX_FORMS_KEYS.PERSONNEL_DATA_PERSONS,
            activeKey,
            KYX_FORMS_KEYS.PERSON_DATA_GENDER,
          ]),
          help: getValidationError(
            remarks,
            [KYX_FORMS_KEYS.PERSONNEL_DATA_PERSONS, activeKey, KYX_FORMS_KEYS.PERSON_DATA_GENDER],
            "Gender",
            FORM_NAME.PERSONNEL_INFORMATION
          ),
        },
        radioProps: {
          name: KYX_FORMS_KEYS.PERSON_DATA_GENDER,
          className: styles.gender,
          options: [
            {
              label: GENDER_OF_INDIVIDUAL_OPTIONS_LABEL[GENDER_OF_INDIVIDUAL_OPTIONS.MALE],
              value: GENDER_OF_INDIVIDUAL_OPTIONS_VALUE[GENDER_OF_INDIVIDUAL_OPTIONS.MALE],
            },
            {
              label: GENDER_OF_INDIVIDUAL_OPTIONS_LABEL[GENDER_OF_INDIVIDUAL_OPTIONS.FEMALE],
              value: GENDER_OF_INDIVIDUAL_OPTIONS_VALUE[GENDER_OF_INDIVIDUAL_OPTIONS.FEMALE],
            },
          ],
          disabled: isFormDisabled,
        },
      },
    },
  };

  const NATIONALITY: IFormElement = {
    key:
      FORM_NAME.PERSONNEL_INFORMATION + KYX_FORMS_KEYS.PERSONNEL_DATA_PERSONS + KYX_FORMS_KEYS.PERSON_DATA_NATIONALITY,
    col: { span: 12 },
    element: {
      type: FORM_FIELD_TYPES.COUNTRYSELECT,
      props: {
        formProps: {
          name: KYX_FORMS_KEYS.PERSON_DATA_NATIONALITY,
          label: "Nationality",
          required: true,
          rules: [
            {
              required: true,
              message: "Please select nationality",
            },
          ],
          validateStatus: getValidationstatus(remarks, [
            KYX_FORMS_KEYS.PERSONNEL_DATA_PERSONS,
            activeKey,
            KYX_FORMS_KEYS.PERSON_DATA_NATIONALITY,
          ]),
          help: getValidationError(
            remarks,
            [KYX_FORMS_KEYS.PERSONNEL_DATA_PERSONS, activeKey, KYX_FORMS_KEYS.PERSON_DATA_NATIONALITY],
            "Nationality",
            FORM_NAME.PERSONNEL_INFORMATION
          ),
        },
        selectProps: {
          placeholder: "Select nationality",
          isnationality: true,
          disabled: isFormDisabled,
        },
      },
    },
  };

  const COUNTRY_OF_RESIDENCE: IFormElement = {
    key:
      FORM_NAME.PERSONNEL_INFORMATION +
      KYX_FORMS_KEYS.PERSONNEL_DATA_PERSONS +
      KYX_FORMS_KEYS.PERSON_DATA_RESIDENTIAL_COUNTRY,
    col: { span: 12 },
    element: {
      type: FORM_FIELD_TYPES.COUNTRYSELECT,
      props: {
        formProps: {
          name: KYX_FORMS_KEYS.PERSON_DATA_RESIDENTIAL_COUNTRY,
          label: "Country of Residence",
          required: true,
          rules: [
            {
              required: true,
              message: "Please select country of residence",
            },
          ],
          validateStatus: getValidationstatus(remarks, [
            KYX_FORMS_KEYS.PERSONNEL_DATA_PERSONS,
            activeKey,
            KYX_FORMS_KEYS.PERSON_DATA_RESIDENTIAL_COUNTRY,
          ]),
          help: getValidationError(
            remarks,
            [KYX_FORMS_KEYS.PERSONNEL_DATA_PERSONS, activeKey, KYX_FORMS_KEYS.PERSON_DATA_RESIDENTIAL_COUNTRY],
            "Country of Residence",
            FORM_NAME.PERSONNEL_INFORMATION
          ),
        },
        selectProps: {
          placeholder: "Select country of residence",
          isnationality: false,
          disabled: isFormDisabled,
        },
      },
    },
  };

  const RESIDENTIAL_ADDRESS: IFormElement = {
    key:
      FORM_NAME.PERSONNEL_INFORMATION +
      KYX_FORMS_KEYS.PERSONNEL_DATA_PERSONS +
      KYX_FORMS_KEYS.PERSON_DATA_RESIDENTIAL_ADDRESS,
    col: { span: 24 },
    element: {
      type: FORM_FIELD_TYPES.INPUT,
      props: {
        formProps: {
          name: KYX_FORMS_KEYS.PERSON_DATA_RESIDENTIAL_ADDRESS,
          label: "Residential Address",
          required: true,
          rules: [
            {
              required: true,
              message: "Please enter residential address",
            },
            { whitespace: true, message: "This field cannot be empty" },
          ],
          validateStatus: getValidationstatus(remarks, [
            KYX_FORMS_KEYS.PERSONNEL_DATA_PERSONS,
            activeKey,
            KYX_FORMS_KEYS.PERSON_DATA_RESIDENTIAL_ADDRESS,
          ]),
          help: getValidationError(
            remarks,
            [KYX_FORMS_KEYS.PERSONNEL_DATA_PERSONS, activeKey, KYX_FORMS_KEYS.PERSON_DATA_RESIDENTIAL_ADDRESS],
            "Residential Address",
            FORM_NAME.PERSONNEL_INFORMATION
          ),
        },
        inputProps: {
          name: KYX_FORMS_KEYS.PERSON_DATA_RESIDENTIAL_ADDRESS,
          placeholder: "Enter residential address",
          disabled: isFormDisabled,
        },
      },
    },
  };

  const POSTAL_CODE: IFormElement = {
    key:
      FORM_NAME.PERSONNEL_INFORMATION + KYX_FORMS_KEYS.PERSONNEL_DATA_PERSONS + KYX_FORMS_KEYS.PERSON_DATA_POSTAL_CODE,
    col: { span: 12 },
    element: {
      type: FORM_FIELD_TYPES.INPUT,
      props: {
        formProps: {
          name: KYX_FORMS_KEYS.PERSON_DATA_POSTAL_CODE,
          label: "Postal Code",
          required: true,
          rules: [
            {
              required: true,
              message: "Please enter postal code",
            },
            { whitespace: true, message: "This field cannot be empty" },
          ],
          validateStatus: getValidationstatus(remarks, [
            KYX_FORMS_KEYS.PERSONNEL_DATA_PERSONS,
            activeKey,
            KYX_FORMS_KEYS.PERSON_DATA_POSTAL_CODE,
          ]),
          help: getValidationError(
            remarks,
            [KYX_FORMS_KEYS.PERSONNEL_DATA_PERSONS, activeKey, KYX_FORMS_KEYS.PERSON_DATA_POSTAL_CODE],
            "Postal Code",
            FORM_NAME.PERSONNEL_INFORMATION
          ),
        },
        inputProps: {
          name: KYX_FORMS_KEYS.PERSON_DATA_POSTAL_CODE,
          placeholder: "Enter postal code",
          disabled: isFormDisabled,
        },
      },
    },
  };

  /* Personnel Information */
  const PERSONNEL_INFORMATION: IMultipleHeaderAndStepInformation = {
    key: FORM_NAME.PERSONNEL_INFORMATION + KYX_FORMS_KEYS.PERSONNEL_DATA_PERSONS + "1",
    row: { gutter: 40 },
    header: { number: 2.1, title: "Personnel Information" },
    document: [
      ROLE_OF_INDIVIDUAL,
      FULL_NAME,
      ALIASES,
      EMPTY_SPAN,
      DATE_OF_BIRTH,
      GENDER,
      NATIONALITY,
      COUNTRY_OF_RESIDENCE,
      RESIDENTIAL_ADDRESS,
      POSTAL_CODE,
    ],
  };

  return PERSONNEL_INFORMATION;
};

export const usePersonnelIdFormPersonDataSGD = (
  form: FormInstance,
  formattedFormData: IFetchFormSubmissionPayload,
  remarks: IGetFormConfigsPayload,
  currentKey: string,
  isFormDisabled: boolean
): IMultipleHeaderAndStepInformation => {
  const [documentSelectionType, setDocumentSelectionType] = useState<PERSON_DOCUMENT_TYPE>();
  const { formData, setFormData } = useContext(ManualFormContext);
  const { saveFormAsDraft } = useFormSaveAsDraft(setFormData);

  useEffect(() => {
    if (!formData?.id) {
      setFormData({ ...formattedFormData, [KYX_FORMS_KEYS.FORM_ID]: formattedFormData?.form_id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formattedFormData]);

  useEffect(() => {
    const documentType = form.getFieldValue([
      KYX_FORMS_KEYS.PERSONNEL_DATA_PERSONS,
      currentKey,
      KYX_FORMS_KEYS.PERSON_DATA_ID_DOCUMENTS,
      KYX_FORMS_KEYS.PERSON_DATA_DOCUMENT_TYPE,
    ]);

    if (formData) {
      setDocumentSelectionType(
        documentType ||
          (formData?.form?.personnel_information?.persons
            ? formData.form.personnel_information?.persons[currentKey]?.identification_document.document_type
            : "")
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentKey, formData]);

  const nric = usePersonnelIdDocumentNricTypeSGD(form, remarks, currentKey, isFormDisabled, formattedFormData?.form_id);
  const passport = usePersonnelIdDocumentPassportTypeSGD(
    form,
    remarks,
    currentKey,
    isFormDisabled,
    formattedFormData?.form_id
  );
  const workpass = usePersonnelIdDocumentWorkPassTypeSGD(
    form,
    remarks,
    currentKey,
    isFormDisabled,
    formattedFormData?.form_id
  );

  const CHOOSE_A_DOCUMENT: IFormElement = {
    key:
      FORM_NAME.PERSONNEL_INFORMATION +
      KYX_FORMS_KEYS.PERSONNEL_DATA_PERSONS +
      KYX_FORMS_KEYS.PERSON_DATA_DOCUMENT_TYPE,
    col: { span: 12 },
    element: {
      type: FORM_FIELD_TYPES.RADIO,
      props: {
        formProps: {
          name: [KYX_FORMS_KEYS.PERSON_DATA_ID_DOCUMENTS, KYX_FORMS_KEYS.PERSON_DATA_DOCUMENT_TYPE],
          label: "Choose a Document",
          required: true,
          rules: [
            {
              required: true,
              message: "Please choose a document",
            },
          ],
          validateStatus: getValidationstatus(remarks, [
            KYX_FORMS_KEYS.PERSONNEL_DATA_PERSONS,
            currentKey,
            KYX_FORMS_KEYS.PERSON_DATA_ID_DOCUMENTS,
            KYX_FORMS_KEYS.PERSON_DATA_DOCUMENT_TYPE,
          ]),
          help: getValidationError(
            remarks,
            [
              KYX_FORMS_KEYS.PERSONNEL_DATA_PERSONS,
              currentKey,
              KYX_FORMS_KEYS.PERSON_DATA_ID_DOCUMENTS,
              KYX_FORMS_KEYS.PERSON_DATA_DOCUMENT_TYPE,
            ],
            "Document",
            FORM_NAME.PERSONNEL_INFORMATION
          ),
        },
        radioProps: {
          name: KYX_FORMS_KEYS.PERSON_DATA_DOCUMENT_TYPE,
          options: [
            {
              label:
                PERSONNNEL_IDENTIFICATION_DOCUMENT_TYPE_OPTIONS_LABEL[
                  PERSONNNEL_IDENTIFICATION_DOCUMENT_TYPE_OPTIONS.NRIC
                ],
              value:
                PERSONNNEL_IDENTIFICATION_DOCUMENT_TYPE_OPTIONS_VALUE[
                  PERSONNNEL_IDENTIFICATION_DOCUMENT_TYPE_OPTIONS.NRIC
                ],
            },
            {
              label:
                PERSONNNEL_IDENTIFICATION_DOCUMENT_TYPE_OPTIONS_LABEL[
                  PERSONNNEL_IDENTIFICATION_DOCUMENT_TYPE_OPTIONS.PASSPORT
                ],
              value:
                PERSONNNEL_IDENTIFICATION_DOCUMENT_TYPE_OPTIONS_VALUE[
                  PERSONNNEL_IDENTIFICATION_DOCUMENT_TYPE_OPTIONS.PASSPORT
                ],
            },
            {
              label:
                PERSONNNEL_IDENTIFICATION_DOCUMENT_TYPE_OPTIONS_LABEL[
                  PERSONNNEL_IDENTIFICATION_DOCUMENT_TYPE_OPTIONS.WORKPASS
                ],
              value:
                PERSONNNEL_IDENTIFICATION_DOCUMENT_TYPE_OPTIONS_VALUE[
                  PERSONNNEL_IDENTIFICATION_DOCUMENT_TYPE_OPTIONS.WORKPASS
                ],
            },
          ],
          disabled: isFormDisabled,
          onChange: (e: RadioChangeEvent) => {
            setDocumentSelectionType(e.target.value as PERSON_DOCUMENT_TYPE);

            const personsObj = form.getFieldsValue()["persons"];

            const idDoc = {
              [KYX_FORMS_KEYS.PERSON_DATA_ID_DOCUMENTS]: {
                [KYX_FORMS_KEYS.PERSON_DATA_DOCUMENT_TYPE]: e.target.value as PERSON_DOCUMENT_TYPE,
                is_same_residential_address: false,
                [KYX_FORMS_KEYS.PERSON_DATA_NRIC_DOCUMENT_TYPE]: {
                  [KYX_FORMS_KEYS.PERSON_DATA_DOCUMENT_NUMBER]: undefined,
                  [KYX_FORMS_KEYS.PERSON_DATA_DOCUMENT]: "",
                  [KYX_FORMS_KEYS.PERSON_DATA_PROOF_OF_RESIDENCE_DOCUMENT]: "",
                },
                [KYX_FORMS_KEYS.PERSON_DATA_PASSPORT_DOCUMENT_TYPE]: {
                  [KYX_FORMS_KEYS.PERSON_DATA_DOCUMENT_NUMBER]: undefined,
                  [KYX_FORMS_KEYS.PERSON_DATA_DOCUMENT_EXPIRY]: undefined,
                  [KYX_FORMS_KEYS.PERSON_DATA_DOCUMENT]: "",
                  [KYX_FORMS_KEYS.PERSON_DATA_PROOF_OF_RESIDENCE_DOCUMENT]: "",
                },
                [KYX_FORMS_KEYS.PERSON_DATA_WORK_PASS_DOCUMENT_TYPE]: {
                  [KYX_FORMS_KEYS.PERSON_DATA_DOCUMENT_NUMBER]: undefined,
                  [KYX_FORMS_KEYS.PERSON_DATA_DOCUMENT]: "",
                  [KYX_FORMS_KEYS.PERSON_DATA_PROOF_OF_RESIDENCE_DOCUMENT]: "",
                },
              },
            };

            const newPersons: IPersonForm[] = Object.keys(personsObj).map((key, index) => {
              const data = {
                ...personsObj[key],
                roles_of_individual: personsObj[key]?.roles_of_individual
                  ? Object.assign(
                      {},
                      ...personsObj[key]?.roles_of_individual?.map((role) => {
                        return role ? { [role]: true } : null;
                      })
                    )
                  : {},
              };

              if (index !== parseInt(currentKey)) {
                // This isn't the person we care about - keep it as-is
                return {
                  ...data,
                  [KYX_FORMS_KEYS.PERSON_DATA_ID_DOCUMENTS]: {
                    ...data?.identification_document,
                    is_same_residential_address: personsObj[key]?.identification_document?.is_same_residential_address
                      ?.length
                      ? personsObj[key]?.identification_document?.is_same_residential_address?.[0] === "checked"
                      : false,
                  },
                };
              }

              // Otherwise, this is the one we want - return an updated value
              return {
                ...data,
                [KYX_FORMS_KEYS.PERSON_DATA_ID_DOCUMENTS]: idDoc,
              } as IPersonForm;
            });

            saveFormAsDraft({
              id: formattedFormData?.form_id,
              form: {
                ...formData.form,
                personnel_information: {
                  ...formData.form.personnel_information,
                  persons: newPersons,
                },
              },
            });

            return form.setFieldsValue({
              [KYX_FORMS_KEYS.PERSONNEL_DATA_PERSONS]: {
                [currentKey]: idDoc,
              },
            });
          },
        },
      },
    },
  };

  /* Personnel Identification Document */
  const PERSONNEL_ID_DOCUMENT: IMultipleHeaderAndStepInformation = {
    key: FORM_NAME.PERSONNEL_INFORMATION + KYX_FORMS_KEYS.PERSONNEL_DATA_PERSONS + "2",
    row: { gutter: 40 },
    header: { number: 2.2, title: "Personnel Identification Document" },
    document: [
      CHOOSE_A_DOCUMENT,
      ...(documentSelectionType?.toLowerCase() === PERSON_DOCUMENT_TYPE.NRIC.toLowerCase() ? nric : []),
      ...(documentSelectionType?.toLowerCase() === PERSON_DOCUMENT_TYPE.PASSPORT.toLowerCase() ? passport : []),
      ...(documentSelectionType?.toLowerCase() === PERSON_DOCUMENT_TYPE.WORKPASS.toLowerCase() ? workpass : []),
    ],
  };

  return PERSONNEL_ID_DOCUMENT;
};

export const usePersonnelInfoFormDataSGD = (
  form: FormInstance,
  activeKey: string,
  formData: IFetchFormSubmissionPayload,
  remarks: IGetFormConfigsPayload,
  currentKey: string,
  isFormDisabled: boolean
): IDocumentStep => {
  return {
    key: FORM_NAME.PERSONNEL_INFORMATION,
    className: SECTION_CONTAINER_CLASS.FLAT_TOP,
    type: DOCUMENT_STEP_TYPE.MULTIPLE_HEADERS_AND_STEPS,
    documents: [
      usePersonnelInfoFormPersonDataSGD(activeKey, remarks, isFormDisabled),
      usePersonnelIdFormPersonDataSGD(form, formData, remarks, currentKey, isFormDisabled),
    ],
  };
};
