import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getAllTeamsWallet } from "Redux/Actions";

import SearchDropdown from "Modules/DS/SearchDropdown";
import { ConditionFormProps, WalletConditionObject } from "../../../../types";
import Tag from "Modules/DS/Atoms/Tag";
import { trackEvent } from "utility/analytics";
import { APPROVAL_FLOW_EVENTS } from "Views/ApprovalFlow/trackingEvents";
import { ContextualMenuItem } from "Modules/DS/ContextualMenu/types";

interface WalletConditionFormProps extends ConditionFormProps {
  values: Array<WalletConditionObject>;
}

const WalletConditionForm: React.FC<WalletConditionFormProps> = ({
  values,
  setValues,
  isExpanded,
  toggleExpand,
}: WalletConditionFormProps): JSX.Element => {
  const dispatch = useDispatch();
  const walletData = useSelector((state: any) => state.allTeamsWalletReducer?.data?.payload?.teams || []);
  const [search, setSearch] = useState<string>("");

  useEffect(() => {
    dispatch(getAllTeamsWallet());
  }, []);

  return (
    <>
      {isExpanded ? (
        <>
          <SearchDropdown
            className="wallet-condition__dropdown"
            label=""
            placeholder="Search budget"
            values={values?.map((item) => ({
              value: item.walletId,
              label: item.name || item.walletId,
              error: item.isDeleted
                ? "Selected budget no longer exists. Please delete it and select a new payment source to proceed."
                : "",
            }))}
            onChange={(val: ContextualMenuItem) => {
              if (val.length && (val.length >= values?.length || !values)) toggleExpand();
              setValues(val.map((item) => ({ walletId: item?.value, name: item?.label })));
            }}
            onSearch={setSearch}
            defaultOpen
            multiple
            renderOption={(option, isSelected) => {
              if (isSelected) {
                const selected = values?.find((val) => val.walletId === option.value) || ({} as WalletConditionObject);
                if (selected.error && option.label)
                  return (
                    <div>
                      <div>{option.label}</div>
                      <div>{selected.error}</div>
                    </div>
                  );
              }
              return option.label;
            }}
            options={walletData
              ?.filter((wallet) => wallet.name.toLowerCase().indexOf(search.toLowerCase()) > -1)
              ?.map((wallet) => ({ value: wallet.wallet_id, label: wallet.name }))}
          />
        </>
      ) : (
        <div className="selected__item__tags">
          {values?.map((item) => {
            return <Tag key={item.walletId}>{item.name || item.walletId}</Tag>;
          })}
        </div>
      )}
    </>
  );
};

export default WalletConditionForm;
