/*
  enums
*/
export enum UPDATE_ROLE_ACTION_TYPES {
  FETCH_UPDATE_ROLE_REQUEST = "EMPLOYEE/FETCH_UPDATE_ROLE_REQUEST",
  FETCH_UPDATE_ROLE_SUCCESS = "EMPLOYEE/FETCH_UPDATE_ROLE_SUCCESS",
  FETCH_UPDATE_ROLE_ERROR = "EMPLOYEE/FETCH_UPDATE_ROLE_ERROR",
  CLEAR_UPDATE_ROLE = "EMPLOYEE/CLEAR_UPDATE_ROLE",
}

/*
  interfaces
*/
export interface IUpdateRoleSuccess {
  isSuccess: boolean;
  message: string;
  createdID: string;
}

export interface IUpdateRoleError {
  code: number;
  message: string;
  isSuccess?: false;
}

export interface IUpdateRoleReducer {
  loading: boolean;
  data: IUpdateRoleSuccess | IUpdateRoleError;
  error: boolean;
}

/*
  initialize
*/
export const initialRoleState: IUpdateRoleReducer = {
  loading: false,
  data: {} as IUpdateRoleSuccess | IUpdateRoleError,
  error: false,
};
