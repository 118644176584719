import { useParams } from "react-router-dom";
import { BillFormType, BillParams } from "../constants";

const useIsBulkView = () => {
  const params = useParams<BillParams>();
  const isBulkView = params.form === BillFormType.BULK;
  return isBulkView;
};

export default useIsBulkView;
