import { useEffect, useState } from "react";

import { debounce } from "utility";

import { DEBOUNCE_DELAY_TIME, IUseSimpleSearchProps } from "Modules/DS/Search";

import { useCommonSearchProps } from "./useCommon";

export const useSimpleSearch = <T extends unknown>(
  data: T[],
  titleKey: keyof T,
  inputValue?: string
): IUseSimpleSearchProps<T> => {
  const { get, onInputChange, clearInput, stopLoader } = useCommonSearchProps(inputValue);

  const [filteredData, setFilteredData] = useState<T[]>(data);

  /** update the filteredData, if data is fetched dynamically */
  useEffect(() => {
    if (filteredData.length !== data.length) {
      setFilteredData(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.length]);

  const resetFilteredData = () => setFilteredData(data);

  const debounceHandler = debounce(setFilteredData, DEBOUNCE_DELAY_TIME.MID);
  const debounceLoader = debounce(stopLoader, DEBOUNCE_DELAY_TIME.MAX);

  const onSearchHandler = (val: string) => {
    onInputChange(val);

    let filtered: T[] = [];
    let regex = new RegExp(val.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"), "i"); // escape special characters and make case-insensitive

    if (!data.length) return;

    for (let item of data) {
      if (regex.test(item[titleKey as string])) {
        filtered.push(item);
      }
    }

    debounceHandler(filtered);
    debounceLoader();
  };

  const clearSearchHandler = () => {
    clearInput();
    resetFilteredData();
  };

  return {
    get: { ...get, data: filteredData },
    clearSearchHandler,
    resetFilteredData,
    onSearchHandler,
    stopLoader,
  };
};
