import React, { useCallback, useEffect, useMemo, useState } from "react";
import cn from "classnames";
import { Form, Input, Radio, Select } from "antd";
import { useSelector } from "react-redux";
import { Base64 } from "js-base64";

import APIClient from "API/Client";
import { RootState } from "Redux/ConfigureStore";
import { getListBank } from "Redux/DataCalls/Disbursement.api";

import Button from "Modules/button";
import Modal, { ModalComponent } from "Modules/DS/Modal";
import { TITLE_TYPE } from "Modules/DS/Modal/types";
import { appNotification } from "Modules/appNotification/appNotification";
import Icon from "Modules/Icon";
import EmptySearch from "Views/State/EmptySearch";

import { GetBaseAuthObject, ValidateNumber } from "utility";
import { GetFormData } from "utility/APIWrapper";
import { PAYOUT_TYPE, PAY_NOW_IDENTIFIER } from "../const";
import {
  PayoutFormProps,
  FormFields,
  FormValues,
  TYPES,
  PAY_NOW_IDENTIFIER_TYPES,
} from "../types";

import { greyCloseIcon } from "assets/img";
import styles from "./ManagePayout.module.scss";

const { CloseButton, Title: ModalTitle } = ModalComponent;

const PayoutForm: React.FC<PayoutFormProps> = (props) => {
  const { show, onCloseModal, onSubmit, initialFormValues } = props;
  const [orgDetail, userId] = useSelector((state: RootState) => [
    state.b2bOrgDetailReducer?.data?.payload,
    state.userInfoReducer?.userId,
  ]);

  const [form] = Form.useForm<FormValues>();

  const isEdit = Boolean(initialFormValues);

  const [bankList, setBankList] = useState([]);
  const [searchBank, setSearchBank] = useState("");
  const [openBankList, setOpenBankList] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [openBankInfo, setOpenBankInfo] = useState(false);

  // form
  // need type to do an action when the type is changed
  const [type, setType] = useState<string>(TYPES.BANK_TRANSFER);
  const [payNowIdentifierType, setPayNowIdentifierType] = useState(undefined);

  const getInitialType = useCallback(() => {
    let type = initialFormValues?.type;

    if (!type) {
      if (Boolean(orgDetail) && orgDetail.countryCode === "SG") {
        type = TYPES.PAYNOW;
      } else {
        type = TYPES.BANK_TRANSFER;
      }
    }

    return type;
  }, [orgDetail, initialFormValues?.type]);

  const initValues = useMemo(() => {
    const initIdentifier = isEdit
      ? initialFormValues?.mobile_number
        ? PAY_NOW_IDENTIFIER_TYPES.MOBILE
        : PAY_NOW_IDENTIFIER_TYPES.NRIC
      : undefined;

    const type = getInitialType();

    // need to create variables since initialFormValues is using shallow copy
    let swift_code;
    let account_number;
    let bank_name;

    // decode the inital data for bank account number
    if (initialFormValues?.account_number) {
      account_number = Base64.decode(initialFormValues.account_number);
    }

    // only set bank name and swift code if has spenmo code and platform pomt
    if (initialFormValues?.spenmo_code && initialFormValues?.platform_pomt) {
      bank_name = initialFormValues?.bank_name;

      // decode the inital data for swift code
      if (initialFormValues?.swift_code) {
        swift_code = Base64.decode(initialFormValues.swift_code);
      }
    }

    setType(type);
    setPayNowIdentifierType(initIdentifier);

    return {
      ...initialFormValues,
      bank_name,
      type,
      payNowIdentifierType: initIdentifier,
      swift_code,
      account_number,
    };
  }, [initialFormValues, isEdit, getInitialType]);

  const resetFormFields = useCallback(
    (fields?: string[]) => {
      // reset the 2nd source of truth (state) for field "type"
      if (!Array.isArray(fields) || fields.includes(FormFields.type)) {
        setType(getInitialType());
      }

      // reset the 2nd source of truth (state) for field "payNowIdentifierType"
      if (
        !Array.isArray(fields) ||
        fields.includes(FormFields.payNowIdentifierType)
      )
        setPayNowIdentifierType(undefined);

      form.resetFields(fields);
    },
    [form, getInitialType],
  );

  useEffect(() => {
    if (Boolean(orgDetail)) {
      getListBank(orgDetail.countryCode, orgDetail.currency, true).then(
        (res) => {
          setBankList(res.data.payload?.bankList);
        },
      );
    }
  }, [orgDetail]);

  useEffect(() => {
    if (show) {
      form.setFieldsValue(initValues);
    }
  }, [form, initValues, show]);

  const handleCloseModal = () => {
    resetFormFields();

    onCloseModal();
  };

  const handleClickBankInfo = () => {
    setOpenBankList(false);
    setOpenBankInfo(true);
  };

  const handleCloseBankInfo = () => setOpenBankInfo(false);

  const handleChangeValues = (val) => {
    switch (true) {
      case Boolean(val.type): {
        setType(val.type);
        // reset all fields other than field "type"
        const fields = Object.keys(FormFields).filter(
          (field) => field !== FormFields.type,
        );
        resetFormFields(fields);
        break;
      }
      case Boolean(val.bank_name): {
        const { bankName, bankSpenmoCode, platformPOMT, swiftCode } =
          JSON.parse(val.bank_name);
        form.setFieldsValue({
          bank_name: bankName,
          spenmo_code: bankSpenmoCode,
          platform_pomt: platformPOMT,
          swift_code: swiftCode,
        });
        break;
      }
      case Boolean(val.payNowIdentifierType): {
        setPayNowIdentifierType(val.payNowIdentifierType);
        break;
      }
    }
  };

  const handleDeletePayout = () => {
    setDeleteLoading(true);

    APIClient.postData(
      `/api/v1/org/${GetBaseAuthObject().orgId}/bank_account/${initialFormValues.id}/delete`,
      GetFormData({ user_id: GetBaseAuthObject().userId }),
    )
      .then((res) => {
        if (res.data.status !== 200) {
          throw res;
        }

        appNotification.success({
          message: "Your reimbursements payout details have been deleted",
        });
      })
      .catch((e) => {
        appNotification.error({
          message: `An error has occurred. Please contact support@spenmo.com for further assistance.\nError ID: ${
            e?.data?.status || 500
          }`,
          cta: "Email Support",
          onClickCTA: () => {
            const link = document.createElement("a");
            link.href = "mailto:support@spenmo.com";
            link.click();
            link.remove();
          },
        });
      })
      .finally(() => {
        setDeleteLoading(false);
        handleCloseModal();
        onSubmit();
      });
  };

  const handleSubmitForm = (value) => {
    let optData: object = {
      bank_name: value.bank_name,
      swift_code: Base64.encode(value.swift_code),
      platform_pomt: value.platform_pomt,
      spenmo_code: value.spenmo_code,
      account_name: value.account_name,
      account_number: Base64.encode(value.account_number),
      country_code: orgDetail.countryCode,
      currency_code: orgDetail.currency,
    };

    if (value.type === TYPES.PAYNOW) {
      if (Boolean(value.mobile_number)) {
        optData = {
          mobile_country_code: 65,
          mobile_number: value.mobile_number,
          account_name: value.account_name,
        };
      } else {
        optData = {
          nric: value.nric,
          account_name: value.account_name,
        };
      }
    }

    const formData = GetFormData({
      user_id: userId,
      type: value.type || TYPES.BANK_TRANSFER,
      is_confirmed: true,
      ...optData,
    });

    setSubmitLoading(true);

    let url = `/api/v1/org/${GetBaseAuthObject().orgId}/bank_account`;

    if (isEdit) {
      url = `/api/v1/org/${GetBaseAuthObject().orgId}/bank_account/${initialFormValues.id}/update`;
    }

    APIClient.postData(url, formData)
      .then((res) => {
        if (res.data.status !== 200) {
          throw res;
        }

        appNotification.success({
          message: "Your reimbursements payout details have been updated.",
        });
      })
      .catch((e) => {
        appNotification.error({
          message: `An error has occurred. Please contact support@spenmo.com for further assistance.\nError ID: ${
            e?.data?.status || 500
          }`,
          cta: "Email Support",
          onClickCTA: () => {
            const link = document.createElement("a");
            link.href = "mailto:support@spenmo.com";
            link.click();
            link.remove();
          },
        });
      })
      .finally(() => {
        setSubmitLoading(false);
        handleCloseModal();
        onSubmit();
      });
  };

  return (
    <>
      <Modal className={styles.modal} visible={show} close={handleCloseModal}>
        <CloseButton src={greyCloseIcon} className={styles.closeIcon} />
        <Form
          form={form}
          onValuesChange={handleChangeValues}
          onFinish={handleSubmitForm}
          requiredMark={false}
        >
          <ModalTitle titleType={TITLE_TYPE.withBorder}>
            {isEdit ? "Edit" : "Add"} Payout Method
          </ModalTitle>
          <p className={cn(styles.subtitle, styles.modalRow)}>
            Your payout details will be saved for all reimbursement payouts with
            Spenmo
          </p>
          <div className={cn(styles.formContainer, styles.modalRow)}>
            {orgDetail?.countryCode === "SG" && (
              <Form.Item
                className={styles.formTitle}
                label="Payment Method"
                name="type"
              >
                <Radio.Group>
                  {PAYOUT_TYPE.map((item) => (
                    <Radio key={item.value} value={item.value}>
                      {item.label}
                    </Radio>
                  ))}
                </Radio.Group>
              </Form.Item>
            )}
            {type === TYPES.BANK_TRANSFER ? (
              <>
                <Form.Item name="spenmo_code" hidden noStyle></Form.Item>
                <Form.Item name="platform_pomt" hidden noStyle></Form.Item>
                <Form.Item
                  className={styles.formTitle}
                  label="Bank"
                  name="bank_name"
                  rules={[
                    { required: true, message: "Your Bank cannot be empty" },
                  ]}
                  extra="Showing banks in your country"
                >
                  <Select
                    showSearch
                    onSearch={(value) => setSearchBank(value)}
                    placeholder="Select a Bank by name or SWIFT / BIC Code"
                    open={openBankList}
                    onDropdownVisibleChange={(visible) =>
                      setOpenBankList(visible)
                    }
                    dropdownRender={(menu) => (
                      <>
                        {menu}
                        <div className={styles.selectFooter}>
                          <Button
                            className={cn(styles.link, styles.info)}
                            textButton
                            action={handleClickBankInfo}
                            type="button"
                            value="button"
                          >
                            <Icon icon="help" className={styles.footerIcon} />
                            My bank is not listed
                          </Button>
                        </div>
                      </>
                    )}
                    notFoundContent={
                      <EmptySearch
                        description={`No bank matching “${searchBank}” found.`}
                      />
                    }
                  >
                    {bankList.map((item, index) => {
                      return (
                        <Select.Option
                          key={`${item.bankName}-${index}`}
                          label={item.bankName}
                          subLabel={item.swiftCode}
                          value={JSON.stringify(item)}
                        >
                          {item.swiftCode ? (
                            <div className={styles.bankOptions}>
                              <p
                                data-testid={item.bankName}
                                className={styles.bankName}
                              >
                                {item.bankName}
                              </p>
                              <p className={styles.swiftCode}>
                                {item.swiftCode}
                              </p>
                            </div>
                          ) : (
                            item.bankName
                          )}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Bank SWIFT/BIC Code"
                  name="swift_code"
                  className={styles.formTitle}
                  trigger="" // empty trigger to trully disabled the field
                  rules={[
                    {
                      required: true,
                      message: "Bank SWIFT/BIC Code cannot be empty",
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter SWIFT/BIC Code"
                    autoComplete="off"
                    disabled
                  />
                </Form.Item>
                <Form.Item
                  label="Bank Account Name"
                  name="account_name"
                  className={styles.formTitle}
                  rules={[
                    {
                      required: true,
                      message: "Bank Account Name cannot be empty",
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter bank account name"
                    autoComplete="off"
                  />
                </Form.Item>
                <Form.Item
                  label="Bank Account Number"
                  name="account_number"
                  className={styles.formTitle}
                  rules={[
                    {
                      required: true,
                      message: "Bank Account Number cannot be empty",
                    },
                    {
                      pattern: /^\S*$/,
                      message: "Bank Account Number cannot contain space",
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter bank account number"
                    autoComplete="off"
                  />
                </Form.Item>
              </>
            ) : (
              <>
                <Form.Item
                  className={styles.formTitle}
                  label="PayNow Identifier Type"
                  name="payNowIdentifierType"
                  rules={[
                    {
                      required: true,
                      message: "Recipient Identifier Number cannot be empty.",
                    },
                  ]}
                >
                  <Select placeholder="Select PayNow Identifier Type">
                    {PAY_NOW_IDENTIFIER.map((item) => (
                      <Select.Option
                        key={item.value}
                        value={item.value}
                        label={item.label}
                      >
                        {item.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                {payNowIdentifierType === PAY_NOW_IDENTIFIER_TYPES.MOBILE && (
                  <Form.Item className={styles.formTitle} label="Mobile Number">
                    <div className={styles.mobileNumber}>
                      <Form.Item>
                        <Input
                          className={styles.textCenter}
                          value="+65"
                          autoComplete="off"
                          disabled
                        />
                      </Form.Item>
                      <Form.Item
                        name="mobile_number"
                        rules={[
                          {
                            required: true,
                            message: "Your Mobile Number cannot be empty",
                          },
                          {
                            min: 8,
                            message: "Mobile number must contain 8 digits.",
                          },
                          {
                            pattern: ValidateNumber(),
                            message: "phone number should only contain numbers",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Enter mobile number"
                          autoComplete="off"
                        />
                      </Form.Item>
                    </div>
                  </Form.Item>
                )}
                {payNowIdentifierType === PAY_NOW_IDENTIFIER_TYPES.NRIC && (
                  <Form.Item
                    className={styles.formTitle}
                    label="NRIC"
                    name="nric"
                    rules={[
                      { required: true, message: "NRIC cannot be empty" },
                      {
                        min: 9,
                        message: "NRIC must contain 9 digits.",
                      },
                      {
                        pattern: /^\S*$/,
                        message: "NRIC cannot contain space",
                      },
                    ]}
                  >
                    <Input placeholder="Enter NRIC" />
                  </Form.Item>
                )}
                {Boolean(payNowIdentifierType) && (
                  <Form.Item
                    label="PayNow Name"
                    name="account_name"
                    className={styles.formTitle}
                    rules={[
                      {
                        required: true,
                        message: "Your PayNow name cannot be empty",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter your PayNow name"
                      autoComplete="off"
                    />
                  </Form.Item>
                )}
              </>
            )}
          </div>
          <div className={styles.modalFooter}>
            <div className={styles.deleteContainer}>
              {isEdit && (
                <Button
                  className={cn(styles.link, styles.danger)}
                  textButton
                  action={handleDeletePayout}
                  loading={deleteLoading}
                  type="button"
                  value="button"
                >
                  Delete
                </Button>
              )}
            </div>
            <Button
              className={cn(styles.primary, styles.large)}
              rounded
              type="submit"
              loading={submitLoading}
            >
              Save Details
            </Button>
          </div>
        </Form>
      </Modal>
      <Modal
        className={styles.bankInfoModal}
        visible={openBankInfo}
        close={handleCloseBankInfo}
      >
        <CloseButton src={greyCloseIcon} className={styles.closeIcon} />
        <ModalTitle titleType={TITLE_TYPE.withBorder}>
          Unsupported Banks
        </ModalTitle>
        <p>
          Your bank might not be available for your Spenmo account. If this is
          incorrect, please contact customer support at{" "}
          <a href="mailto:support@spenmo.com" rel="noopener noreferrer">
            support@spenmo.com
          </a>
        </p>
        <Button
          className={cn(styles.primary, styles.large)}
          rounded
          type="button"
          value="button"
          action={handleCloseBankInfo}
        >
          Got it
        </Button>
      </Modal>
    </>
  );
};

export default PayoutForm;
