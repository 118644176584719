import React from "react";

import { iconNotFound } from "assets/img";

import styles from "./EmptySearch.module.scss";

const EmptySearch = (props) => {
  const { description = "Not found" } = props;

  return (
    <div className={styles.notFound}>
      <img src={iconNotFound} alt="Data not found" width={120} height={120} />
      <p>{description}</p>
    </div>
  );
};

export default EmptySearch;
