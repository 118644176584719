import React, { useContext, useState } from "react";
import { DynamicFormFieldProps } from "Modules/DynamicForm/types";
import { Form, Input } from "antd";
import { formItemProps } from "../utils/formItemProps";
import style from "./Textfield.module.scss";
import classNames from "classnames";
import { DynamicFormContext } from "Modules/DynamicForm/store";

const DynamicFormTextfield = (props: DynamicFormFieldProps) => {
  const { alias, fieldID, fieldProps, onChangeValue, suffix } = props;
  const { isTrimSpace } = fieldProps;
  const [value, setValue] = useState("");
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.currentTarget?.value;

    if (isTrimSpace) {
      value = value?.trim();
    }
    onChangeValue?.(fieldID, value);
    setValue(value);
  };

  const handleFocus = () => {
    trackView(alias, value);
  };

  const handleBlur = (e) => {
    const value = e.target?.value?.trim();
    onChangeValue?.(fieldID, value);
    setValue(value);
    trackEdit(alias, value);
  };

  const { className, ...rest } = formItemProps(props);
  const { trackView, trackEdit } = useContext(DynamicFormContext);

  return (
    <Form.Item {...rest} className={classNames(style.textfield, className)}>
      <Input
        type="text"
        placeholder={fieldProps.placeholder}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        disabled={!fieldProps.editable}
        maxLength={fieldProps.maxChar}
        suffix={suffix}
      />
    </Form.Item>
  );
};

export default DynamicFormTextfield;
