import { useEffect } from "react";
import { trackEvent } from "utility/analytics";

interface Analytic {
  eventSource: "Company" | "Personal";
  eventSubSource:
    | "Integration"
    | "Category"
    | "Tax"
    | "Low Balance Reminder"
    | "Reimbursements"
    | "Security"
    | "Payout Details";
}

export const useAnalytic = ({ eventSource, eventSubSource }: Analytic) => {
  useEffect(() => {
    trackEvent("Settings Page Loaded", {
      settings_page_event_source: eventSource,
      settings_page_event_subsource: eventSubSource,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
