export const RequestTypes = {
  physical: {
    type: "physical",
    successMessage: "You have successfully requested a physical card!",
  },
  virtual: {
    type: "virtual",
    successMessage: "You have successfully created a virtual card!",
  },
  physicalSync: {
    type: "physicalSync",
    successMessage: "You have successfully synced a physical card!",
  },
};
