import React, { useState, useEffect, forwardRef } from "react";
import PropTypes from "prop-types";
import { DatePicker, Button } from "antd";
import moment from "moment";
import TimePicker from "./TimePicker";
import "./SpDatePicker.scss";

const SpDatePicker = forwardRef(
  (
    {
      defaultDate,
      disabled = false,
      action = () => undefined,
      hasTimePicker = false,
      hasClear = false,
      className = "",
      validateTime = () => false,
      onFocus = () => undefined,
      onBlur = () => undefined,
      showPlaceholderWithOutHasClear = false,
      resetAllFields = false,
      setResetAllFields = () => undefined,
      ...rest
    },
    ref
  ) => {
    const [date, setDate] = useState(moment());
    const [confirmDate, setConfirmDate] = useState(hasClear || showPlaceholderWithOutHasClear ? "" : moment());
    const [open, setOpen] = useState(false);
    const [inputFocus, setInputFocus] = useState(false);
    const [contentFocus, setContentFocus] = useState(false);
    const [isPayNow, setIsPayNow] = useState(!hasClear && !defaultDate && !showPlaceholderWithOutHasClear);
    const [timeErrorMessage, setTimeErrorMessage] = useState(false);

    const dateFormat = hasTimePicker ? "DD MMM YYYY . HH:mm" : "DD MMM YYYY";

    useEffect(() => {
      if (inputFocus || contentFocus) {
        setOpen(true);
      } else {
        setOpen(false);
      }
    }, [inputFocus, contentFocus]);

    useEffect(() => {
      if (defaultDate) {
        setIsPayNow(false);
        setDate(moment(defaultDate));
        setConfirmDate(moment(defaultDate));
      } else {
        // show placeholder on hovering the calendar
        if (!showPlaceholderWithOutHasClear) setIsPayNow(true);
      }
    }, [defaultDate, showPlaceholderWithOutHasClear]);

    useEffect(() => {
      if (validateTime && date) {
        const timeError = validateTime(date);
        if (!timeError) {
          setTimeErrorMessage(null);
        } else {
          setTimeErrorMessage(timeError);
        }
      }
    }, [date, validateTime]);

    const confirmAction = (isNow) => {
      const value = isNow ? moment() : date;
      setIsPayNow(isNow);
      setDate(value);
      setConfirmDate(value);
      setOpen(false);
      action(value, isNow);
    };

    const clearAction = () => {
      setDate("");
      setConfirmDate("");
      setOpen(false);
      action(null);
    };

    // TODO: reset date values based on props value
    useEffect(() => {
      if (resetAllFields) {
        clearAction();
        setResetAllFields(false);
      }
    }, [resetAllFields, clearAction, setResetAllFields]);

    const renderFooter = () => (
      <div
        onClick={(e) => {
          e.stopPropagation();
          e.target.focus();
        }}
        onFocus={() => {
          setContentFocus(true);
        }}
        onBlur={() => {
          setContentFocus(false);
        }}
        className="sp-datepicker__popover__footer"
      >
        {hasTimePicker && <TimePicker date={date} setDate={setDate} timeErrorMessage={timeErrorMessage} />}
        <div className="action">
          {hasClear ? (
            <Button
              className="action__button--now"
              type="link"
              onClick={(e) => {
                e.stopPropagation();
                clearAction();
              }}
            >
              Clear
            </Button>
          ) : (
            <Button
              className="action__button--now"
              type="link"
              onClick={(e) => {
                e.stopPropagation();
                confirmAction(true);
              }}
            >
              Pay Immediately
            </Button>
          )}
          <Button
            disabled={timeErrorMessage}
            className="action__button--confirm"
            type="primary"
            onClick={(e) => {
              e.stopPropagation();
              confirmAction();
            }}
          >
            Confirm
          </Button>
        </div>
      </div>
    );

    return (
      <DatePicker
        {...rest}
        ref={ref}
        getPopupContainer={(triggerNode) => triggerNode.parentElement}
        className={`sp-datepicker ${className}`}
        disabled={disabled}
        dropdownClassName="sp-datepicker__popover"
        allowClear={false}
        open={open}
        onClick={() => {
          setOpen(true);
        }}
        onFocus={() => {
          onFocus();
          setInputFocus(true);
        }}
        onBlur={() => {
          onBlur();
          setInputFocus(false);
        }}
        renderExtraFooter={renderFooter}
        onSelect={(value) => {
          const initialDate = date ? moment(date) : moment();
          const temp = initialDate.set("year", value?.year()).set("month", value?.month()).set("date", value?.date());
          setDate(temp);
        }}
        value={confirmDate}
        showToday={false}
        format={() => {
          // Todo: As per update in current flow in payment schedule it's needed, handled the scenario though props values
          // Improvement: For make it more customizable, better to make a new Date component in Bill section.
          const isDateSameAsToday =
            showPlaceholderWithOutHasClear && confirmDate?.format?.(dateFormat) === moment().format(dateFormat);
          if (isDateSameAsToday || isPayNow) {
            return "Pay Immediately";
          }
          if (confirmDate) {
            return confirmDate?.format?.(dateFormat);
          }
          return "";
        }}
      />
    );
  }
);

SpDatePicker.propTypes = {
  action: PropTypes.func,
  className: PropTypes.string,
  hasTimePicker: PropTypes.bool,
  hasClear: PropTypes.bool,
  defaultDate: PropTypes.instanceOf(Date),
  validateTime: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  showPlaceholderWithOutHasClear: PropTypes.bool,
  resetAllFields: PropTypes.bool,
  setResetAllFields: PropTypes.func,
};

export default SpDatePicker;
