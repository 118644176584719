import React, { useEffect, useMemo, useState } from "react";

import { Sidepanel } from "@spenmo/splice";
import { TActionButton } from "@spenmo/splice/lib/shared/types";

import { FirstStep } from "./FirstStep/FirstStep";
import { SecondStep } from "./SecondStep/SecondStep";
import { Stepper } from "./Stepper";
import { UpdateWarningModal } from "./UpdateWarningModal";

import { useSubscriptionManagementForm } from "./form";
// import { getPaymentPayload } from "./Helper";
import FormContext, { PaymentContext, IPayment, initialPaymentContext } from "./Context";
import { CONTRACT_TYPE, CREATE_STEPS, FORM_NAMES, FORM_TYPE } from "Views/SubscriptionManagement/Constants";
import { ICreateForm } from "Views/SubscriptionManagement/@types";
import { useCreateSubscription } from "Views/SubscriptionManagement/Create/Hooks/useCreateSubscription";
import { useUpdateSubscription } from "Views/SubscriptionManagement/Create/Hooks/useUpdateSubscription";
import { useVendors } from "Views/SubscriptionManagement/Create/Hooks/useVendors";
import { useAddNewVendor } from "Views/SubscriptionManagement/Create/Hooks/useAddNewVendor";

import styles from "./styles.module.scss";

//TODO: rename folder to CreateOrUpdate

enum BUTTON_TITLES {
  NEXT = "Next",
  CREATE = "Create",
  UPDATE = "Update",
  DISCARD = "Discard",
  BACK = "Back",
}

interface ICreateProps {
  onClose: () => void;
  showSidePanel?: boolean;
  onCreate?: (id?: string) => void;
  onUpdate?: () => void;
  formType?: FORM_TYPE;
  initialValue?: Partial<ICreateForm>;
  id?: string;
}

export const Create = ({
  showSidePanel = true,
  onClose,
  onCreate,
  onUpdate,
  formType,
  initialValue,
  id = "",
}: ICreateProps) => {
  const {
    control,
    watch,
    getValues,
    handleSubmit,
    setValue,
    formState: { errors },
    clearErrors,
  } = useSubscriptionManagementForm(initialValue);
  const [activeStep, setActiveStep] = useState<CREATE_STEPS>(CREATE_STEPS.FIRST);
  const [paymentData, setPaymentData] = useState<IPayment>(initialPaymentContext);

  const [showUpdateWarningModal, setShowUpdateWarningModal] = useState(false);
  const { trigger: createSubscriptionTrigger, isMutating: isCreateSubscriptionLoading } = useCreateSubscription();
  const { trigger: updateSubscriptionTrigger, isMutating: isUpdateSubscriptionLoading } = useUpdateSubscription(id);
  const { data: vendors } = useVendors();
  const { trigger } = useAddNewVendor();

  useEffect(() => {
    const initialVendorName = initialValue?.vendor_name;
    if (initialVendorName && showSidePanel) {
      const vendor = vendors.find((vendor) => {
        return vendor.name.toLowerCase() === initialVendorName.toLowerCase();
      });

      if (!vendor) {
        trigger(
          { name: initialVendorName },
          {
            onSuccess(vendorId) {
              setValue(FORM_NAMES.VENDOR_ID, vendorId);
            },
          }
        );
      } else {
        setValue(FORM_NAMES.VENDOR_ID, vendor.id);
      }
    }
  }, [initialValue?.vendor_name, vendors, showSidePanel]);

  const sanitizePayload = () => {
    const payload = getValues();
    // const paymentPayload = getPaymentPayload(paymentData, payload);
    if (payload.contract_type === CONTRACT_TYPE.ROLLING) {
      // Delete end_date data to prevent error when parsing time at BE
      // We don't need this value when contract type is rolling
      delete payload.end_date;
    }

    return {
      ...payload,
      // payment: paymentPayload,
    };
  };

  const getPayload = () => {
    const payload = sanitizePayload();
    // delete payload.payment_limit;
    // delete payload.payment_method;
    delete payload.owner_name;
    return {
      ...payload,
      [FORM_NAMES.ESTIMATED_AMOUNT]: Number(payload.estimated_amount),
    };
  };

  const createSubscription = () => {
    handleSubmit(() => {
      createSubscriptionTrigger(getPayload(), {
        onSuccess: (id) => {
          onCreate?.(id);
        },
        throwOnError: false,
      });
    })();
  };

  const updateSubscription = () => {
    setShowUpdateWarningModal(false);
    updateSubscriptionTrigger(getPayload(), {
      onSuccess: () => {
        onUpdate?.();
      },
      throwOnError: false,
    });
  };

  const nextAction: () => TActionButton = () => {
    if (activeStep === CREATE_STEPS.FIRST) {
      return {
        title: BUTTON_TITLES.NEXT,
        action: () => {
          handleSubmit(() => {
            setActiveStep(CREATE_STEPS.SECOND);
          })();
        },
      };
    }

    return {
      title: formType === FORM_TYPE.EDIT ? BUTTON_TITLES.UPDATE : BUTTON_TITLES.CREATE,
      action: () => {
        if (formType === FORM_TYPE.CREATE) {
          createSubscription();
        } else {
          handleSubmit(() => {
            setShowUpdateWarningModal(true);
          })();
        }
      },
      loading: isCreateSubscriptionLoading || isUpdateSubscriptionLoading,
    };
  };

  const tertiaryAction = () => {
    if (activeStep === CREATE_STEPS.FIRST) {
      return {
        title: BUTTON_TITLES.DISCARD,
        action: (event) => {
          event?.preventDefault();
          onClose();
        },
      };
    }

    return {
      title: BUTTON_TITLES.BACK,
      action: (event) => {
        event?.preventDefault();
        setActiveStep(CREATE_STEPS.FIRST);
      },
    };
  };

  const contextValue = useMemo(
    () => ({
      control,
      watch,
      setValue,
      errors,
      clearErrors,
    }),
    [control, watch, setValue, errors, clearErrors]
  );

  const paymentContextValue = useMemo(
    () => ({
      ...paymentData,
      setPaymentData,
    }),
    [paymentData, setPaymentData]
  );

  return (
    <FormContext.Provider value={contextValue}>
      <PaymentContext.Provider value={paymentContextValue}>
        <form className={styles.form}>
          <Sidepanel
            footerStacking="horizontal"
            position="right"
            showSidepanel={showSidePanel}
            title={formType === FORM_TYPE.EDIT ? "Edit Subscription" : "New Subscription"}
            transitionType="rightToLeft"
            width={650}
            onClose={onClose}
            tertiaryActionButton={tertiaryAction()}
            primaryActionButton={nextAction()}
            headerChildren={<Stepper step={activeStep} />}
          >
            <section className={styles.createSubscription}>
              {activeStep === CREATE_STEPS.FIRST ? <FirstStep formType={formType} /> : <SecondStep />}
            </section>
          </Sidepanel>
        </form>
      </PaymentContext.Provider>
      <UpdateWarningModal
        showModal={showUpdateWarningModal}
        onClose={() => setShowUpdateWarningModal(false)}
        onProceed={updateSubscription}
      />
    </FormContext.Provider>
  );
};
