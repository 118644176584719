import React from "react";
import { getSpendinLockChoiceConfig } from "Views/Card/Components/SpendingLock/Choose/Config";

import ChooseLockOption from "Views/Card/Components/SpendingLock/Choose/Option";
import { Tooltip } from "Views/Card/Components/Tooltip";
import { infoIconBlue16x16 } from "assets/icons/card";

import { SPENDING_LOCK_TYPES } from "Views/Card/types";
import { CardActionType } from "Views/Card/Components/SpendingLock/types";
import { SPLIT_NAMES, SPLIT_TREATMENT_TYPES } from "Redux/splitio/constants";
import useCheckFeatureStatus from "customHooks/featureCheck";

import styles from "./index.module.scss";

interface IChooseSpendingLock {
  spendingLockType: SPENDING_LOCK_TYPES;
  spendingLocks: string[];
  onSpendingLockTypeClick: (selectedLockType: SPENDING_LOCK_TYPES) => void;
  onRemove: () => void;
  cardActionType: CardActionType;
}

const ChooseSpendingLock = ({
  spendingLockType,
  spendingLocks,
  onSpendingLockTypeClick,
  onRemove,
  cardActionType,
}: IChooseSpendingLock) => {
  const getSpendingLockOptionValue = (spendingLockChoice: SPENDING_LOCK_TYPES) => {
    if (spendingLockChoice === spendingLockType) return spendingLocks?.join(", ")?.toLowerCase() || "";
    return "";
  };

  const isCategoryLockTreatmentOn = useCheckFeatureStatus(SPLIT_NAMES.categoryLock) === SPLIT_TREATMENT_TYPES.ON;

  const spendingLockChoiceConfig = getSpendinLockChoiceConfig(isCategoryLockTreatmentOn);

  return (
    <div className={styles["choose__options"]}>
      {spendingLockChoiceConfig.map((spendingLockChoice) => {
        return (
          <ChooseLockOption
            onClick={() => {
              onSpendingLockTypeClick(spendingLockChoice?.value);
            }}
            active={spendingLockType === spendingLockChoice?.value}
            key={spendingLockChoice?.title}
            value={getSpendingLockOptionValue(spendingLockChoice?.value)}
            onRemove={onRemove}
            size={cardActionType === "Create" ? "l" : null}
          >
            <div className={styles["choose__options__title"]}>
              <p>{spendingLockChoice?.title}</p>
              {spendingLockChoice?.infoIcon ? (
                <a
                  href={spendingLockChoice?.infoIcon?.link}
                  target="_blank"
                  onClick={(event) => event.stopPropagation()}
                  rel="noreferrer"
                >
                  <Tooltip tooltipText={spendingLockChoice?.infoIcon?.title} placement="top">
                    <img src={infoIconBlue16x16} alt="Info Icon" width={18} height={18} />
                  </Tooltip>
                </a>
              ) : null}
            </div>
            <p className={styles["choose__options__subtitle"]}>{spendingLockChoice?.subtitle}</p>
          </ChooseLockOption>
        );
      })}
    </div>
  );
};

export default ChooseSpendingLock;
