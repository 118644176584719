import * as React from "react";
import { Skeleton } from "antd";

import styles from "./index.module.scss";

interface ICardTitleShimmer {
  renderCardShimmer: boolean;
}

const CardTitleShimmer = ({ renderCardShimmer }: ICardTitleShimmer) => {
  return (
    <div data-testid="shimmer-container" className={styles.container}>
      {renderCardShimmer && <Skeleton.Avatar className={styles["shimmer__card"]} active shape="circle" />}
      <div className={styles["shimmer__title--container"]}>
        <Skeleton.Input className={styles["shimmer__title"]} active />
        <Skeleton.Input className={styles["shimmer__type"]} active />
      </div>
    </div>
  );
};

export default CardTitleShimmer;
