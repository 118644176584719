import * as React from "react";
import { useDispatch } from "react-redux";
import { useState, useCallback } from "react";

import { getAllEmployees } from "Redux/Actions/Employee/Get/Employees";
import { IEmployeeFilters } from "Redux/Actions/Employee/Get/Employees/types";

import { EMPLOYEES_INITIAL_PAGE_NUMBER, EMPLOYEES_TABLE_PAGE_SIZE } from "constants/Employee.constant";

import useModal from "Views/Employee/Modal/useModal";
import useCS from "Views/Employee/CustomerSupport/useCS";
import usePagination from "Views/Employee/List/usePagination";
import useIfDispatchFails from "Views/Employee/CustomerSupport/useIfDispatchFails";

import * as E from "Views/Employee/context";
import { initalFiltersState } from "Views/Employee/types";

const EmployeesProvider = ({ children }: { children: React.ReactNode }) => {
  const dispatch = useDispatch();

  const [searchVal, setSearchVal] = useState("");
  const [filters, setFilters] = useState({ ...initalFiltersState });

  const [supportChat, clearSupportChat, toggleSupportChat] = useCS();

  const [showModal, setShowModal, resetModal, toggleModal] = useModal();

  const [currentPage, setCurrentPage, resetCurrentPage] = usePagination();

  const [pendingRequest, updateRequestCount, dispatchRoleUpdate] = useIfDispatchFails();

  const getEmployeesData = useCallback(
    (page: number = EMPLOYEES_INITIAL_PAGE_NUMBER, search: string = "", filter: IEmployeeFilters = {}) => {
      dispatch(getAllEmployees(page, EMPLOYEES_TABLE_PAGE_SIZE, search, filter));
      setCurrentPage(page);
    },
    [dispatch, setCurrentPage]
  );

  const pageContext: E.IEmployeesContext = {
    searchVal,
    setSearchVal,
    filters,
    setFilters,
    getEmployeesData,
  };

  const csContext: E.IEmployeesCSContext = {
    supportChat,
    clearSupportChat,
    toggleSupportChat,
  };

  const paginationContext: E.IEmployeesPaginationContext = {
    currentPage,
    setCurrentPage,
    resetCurrentPage,
  };

  const dispatchFailsContext: E.IEmployeesDispatchFailsContext = {
    pendingRequest,
    updateRequestCount,
    dispatchRoleUpdate,
  };

  const modalContext: E.IEmployeesModalContext = {
    showModal,
    setShowModal,
    resetModal,
    toggleModal,
  };

  return (
    <E.EmployeesPageContext.Provider value={pageContext}>
      <E.EmployeesModalContext.Provider value={modalContext}>
        <E.EmployeesPaginationContext.Provider value={paginationContext}>
          <E.EmployeesCSContext.Provider value={csContext}>
            <E.EmployeesDispatchFailsContext.Provider value={dispatchFailsContext}>
              {children}
            </E.EmployeesDispatchFailsContext.Provider>
          </E.EmployeesCSContext.Provider>
        </E.EmployeesPaginationContext.Provider>
      </E.EmployeesModalContext.Provider>
    </E.EmployeesPageContext.Provider>
  );
};

export default EmployeesProvider;
