import {
  FETCH_LOGIN_REQUEST,
  FETCH_LOGIN_SUCCESS,
  FETCH_LOGIN_FAILURE,
  VERIFY_2FA_REQUEST,
  VERIFY_2FA_SUCCESS,
  VERIFY_2FA_FAILURE,
  VERIFY_2FA_START,
  LOGIN_START,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGIN_IDLE,
  LINKED_ACCOUNTS,
  SWITCH_ACCOUNT_STATUS,
} from "../Actions/loginAction";
import { userInitialState } from "../InitialState";

export const loginReducer = (state = userInitialState, action) => {
  switch (action.type) {
    case LOGIN_IDLE:
      return userInitialState;

    case FETCH_LOGIN_REQUEST:
      return {
        ...state,
        data: { payload: action.data },
        loading: true,
        step: LOGIN_START,
      };

    case FETCH_LOGIN_SUCCESS:
      return {
        ...state,
        data: { payload: action.data },
        loading: false,
        step: LOGIN_SUCCESS,
        processLoginForm: false,
      };

    case FETCH_LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.data,
        error: true,
        step: LOGIN_FAILURE,
      };
    case SWITCH_ACCOUNT_STATUS:
      return {
        ...state,
        loading: true,
        step: LOGIN_START,
        switchingAccount: action.data,
      };

    case LINKED_ACCOUNTS:
      return {
        ...state,
        data: { ...state.data, linkedAccount: action.data },
      };

    case VERIFY_2FA_START:
      return {
        ...state,
        data: { payload: action.data },
        loading: false,
        error: false,
        errorMessage: undefined,
        errorCode: undefined,
        step: VERIFY_2FA_START,
      };

    case VERIFY_2FA_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: undefined,
        errorCode: undefined,
        step: VERIFY_2FA_REQUEST,
      };

    case VERIFY_2FA_SUCCESS:
      return {
        ...state,
        data: { payload: action.data },
        loading: false,
        error: false,
        errorMessage: undefined,
        errorCode: undefined,
        step: VERIFY_2FA_SUCCESS,
      };

    case VERIFY_2FA_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.data.errorMessage,
        errorCode: action.data.errorCode,
        step: VERIFY_2FA_FAILURE,
      };

    default:
      return state;
  }
};
