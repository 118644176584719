import * as React from "react";
import "./index.scss";
import { currencyFormatterV2 } from "utility";

interface SupportingInfoProps {
  total: string;
  sendCurrency: string;
  label: string;
  toBeFixed?: number;
}

export const SupportingInfo = ({ total, sendCurrency, label, toBeFixed }: SupportingInfoProps) => {
  return (
    <div className="supporting-info">
      {!!total && (
        <>
          <span className="supporting-info__label">{`${label}:`}</span>
          <span className="supporting-info__amount">{currencyFormatterV2(total, sendCurrency, true, toBeFixed)}</span>
        </>
      )}
    </div>
  );
};
