import React from "react";
import { Core } from "assets/v1.1/icons/Core";

import { Typography } from "@spenmo/splice";
import { Tooltip } from "Views/Card/Components/Tooltip";

import styles from "./index.module.scss";

export const getAmountDetail = (label: string, value: string, tooltipText?: string) => {
  return (
    <div className={styles.breakupContainer}>
      <div className={styles.breakupLabelContainer}>
        <Typography size="s" variant="body-content" tag="p">
          {label}
        </Typography>
        {tooltipText && (
          <Tooltip placement="top" tooltipText={tooltipText}>
            <img src={Core.Info.Line.grey_16} alt="info-icon" width={16} height={16} />
          </Tooltip>
        )}
      </div>
      <Typography size="s" variant="body-content" tag="p" weight={600} className={styles.breakupAmount}>
        {value}
      </Typography>
    </div>
  );
};

export const isFxTransaction = (fxType: string) => fxType !== "DCC_FX_RATE" && fxType.includes("FX_RATE");
