export const DEFAULT_ERROR_MESSAGE = "Something went wrong, Please try again.";
export const KYB_ONBOARDING_VERSION = "3";

/**
 * Enum representing the possible sources for KYB (Know Your Business) data.
 * @readonly
 * @enum {string}
 */
export enum KYB_SOURCE {
  MANUAL = "MANUAL",
  AUTOMATED = "AUTOMATED",
}

/**
 * Enum representing the countries that are included in ONB 3.0
 * @readonly
 * @enum {string}
 */
export enum KYX_COUNTRY_LIST {
  SGD = "SGD",
  IDR = "IDR",
}

export enum KYX_ORG_COUNTRY_LIST {
  SGD = "SG",
  IDR = "ID",
}

export * from "./Guidelines";
export * from "./Documents";
export * from "./Progress";
export * from "./Elements";
export * from "./Status";
export * from "./Modal";
export * from "./Remarks";
export * from "./Forms";
export * from "./ReKYB";
