import useSWRMutation from "swr/mutation";
import { toast } from "@spenmo/splice";

import { putData } from "API/Client";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import { capitalizeFirstLetter } from "utility";
import { trackEvent } from "utility/analytics";
import { ICreateForm } from "Views/SubscriptionManagement/@types";
import { SUBSCRIPTION_EVENT } from "Views/SubscriptionManagement/Analytic";

export const useUpdateSubscription = (subscriptionId: string) => {
  return useSWRMutation<null, any, string, ICreateForm>(
    `ms/spm-organization/v1/subscriptions/${subscriptionId}`,
    (url, { arg }) => {
      const vendorName = arg.vendor_name;
      delete arg.vendor_name;

      return putData(url, { ...arg })
        .then(({ data }) => {
          const { status, payload } = data || {};
          if (status === HTTP_STATUS_CODE.OK && !payload) {
            trackEvent(SUBSCRIPTION_EVENT.EDIT_SUBSCRIPTION);

            toast.success(`${capitalizeFirstLetter(vendorName.toLowerCase())} subscription successfully updated`, {
              duration: 3000,
            });
            return null;
          }
          throw new Error(data);
        })
        .catch((err) => {
          const serverErrorMessage = err?.response?.data?.error?.message;
          const defaultErrorMessage =
            "This subscription could not be updated due to some error. Please retry or contact us at support@spenmo.com if the issue persists.";
          toast.danger(serverErrorMessage || defaultErrorMessage);
          throw new Error(err);
        });
    }
  );
};
