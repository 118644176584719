import {
  FETCH_UPDATE_PROFILE_REQUEST,
  FETCH_UPDATE_PROFILE_SUCCESS,
  FETCH_UPDATE_PROFILE_ERROR,
} from "Redux/Actions/Wallet/updateProfileAction";
import { userInitialState } from "Redux/InitialState";

export const updateProfileReducer = (state = userInitialState, action) => {
  switch (action.type) {
    case FETCH_UPDATE_PROFILE_REQUEST:
      return {
        ...state,
        loading: true,
        data: action.data,
        error: false,
      };
    case FETCH_UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: false,
      };
    case FETCH_UPDATE_PROFILE_ERROR:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: true,
      };
    default:
      return state;
  }
};
