import { useEffect, useState } from "react";
import { OTP_EXPIRES_AT_SESSION_STORAGE_NAME } from "Redux/Actions/loginAction";
import { getTimeDiffInSeconds } from "utility";

const DECREMENT_COUNTER_TIMEOUT = 1000;

export const useOTPTimeoutHandler = () => {
  const OTP_EXPIRE_TIME = new Date(
    sessionStorage?.getItem?.(OTP_EXPIRES_AT_SESSION_STORAGE_NAME)?.replace(/\s/, "T") || ""
  );

  const [counter, setCounter] = useState(null);

  useEffect(() => {
    OTP_EXPIRE_TIME && setCounter(getTimeDiffInSeconds(OTP_EXPIRE_TIME));
  }, [OTP_EXPIRE_TIME]);

  useEffect(() => {
    const decreementCounter = () => setCounter(counter - 1);
    if (counter) {
      const countdown = setTimeout(() => decreementCounter(), DECREMENT_COUNTER_TIMEOUT);
      return function cleanUp() {
        clearTimeout(countdown);
      };
    }
  }, [counter]);

  return counter;
};
