import APIclient from "API/Client";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";

export const FETCH_ORG_CONFIGS_REQUEST = "FETCH_ORG_CONFIGS_REQUEST";
export const FETCH_ORG_CONFIGS_SUCCESS = "FETCH_ORG_CONFIGS_SUCCESS";
export const FETCH_ORG_CONFIGS_ERROR = "FETCH_ORG_CONFIGS_ERROR";

export const fetchOrgConfigsReq = () => {
  return {
    type: FETCH_ORG_CONFIGS_REQUEST,
  };
};

export const fetchOrgConfigsSuccess = (data) => {
  return {
    type: FETCH_ORG_CONFIGS_SUCCESS,
    data: data,
  };
};

export const fetchOrgConfigsError = (data) => {
  return {
    type: FETCH_ORG_CONFIGS_ERROR,
    data: data,
  };
};

export const getOrgConfigsFunc = (orgId) => {
  return (dispatch) => {
    dispatch(fetchOrgConfigsReq());

    APIclient.getData(`/ms/spm-organization/v1/organisation/${orgId}/configs`)
      .then((res: any) => {
        if (res?.data?.status === HTTP_STATUS_CODE.OK) {
          dispatch(fetchOrgConfigsSuccess(res?.data));
        } else {
          dispatch(fetchOrgConfigsError(res?.data));
        }
      })
      .catch((err) => {
        dispatch(fetchOrgConfigsError(err));
      });
  };
};
