import React from "react";
import classNames from "classnames";
import { Col, Space, Radio, Form } from "antd";
import { SyncAccountsConfig } from "constants/Integration.constant";

import styles from "./styles.module.scss";

const XeroSyncAccounts = ({ accountType, selectedBankAccount, bankAccounts, onChangeHandler, disabled }) => {
  const accountOnChangeHandler = (event) => {
    const { value } = event?.target || {};
    value && onChangeHandler(value, accountType);
  };
  return (
    <Col className={styles.column}>
      <p className={styles.title}>{SyncAccountsConfig[accountType]?.title}</p>
      <p className={styles.subTitle}>{SyncAccountsConfig[accountType]?.subTitle}</p>
      <Form.Item name={SyncAccountsConfig[accountType]?.formItemName} initialValue={selectedBankAccount}>
        <Radio.Group
          className={classNames("sp-radio-group", styles.radioGroup)}
          onChange={accountOnChangeHandler}
          value={selectedBankAccount}
          disabled={disabled}
        >
          <Space direction="vertical">
            {bankAccounts?.map((bank) => (
              <Radio key={bank?.id} value={bank?.id}>
                {`${bank?.number}- ${bank?.name}`}
              </Radio>
            ))}
          </Space>
        </Radio.Group>
      </Form.Item>
    </Col>
  );
};

export default XeroSyncAccounts;
