import { IEmployee } from "Redux/Actions/Employee/Get/Employees/types";

import { USER_ROLE } from "constants/Team.constant";

import { IRole } from "Views/Employee/types";

const getRoleAnalytics = (updatedRole: IRole, employee: IEmployee): string => {
  let event = "";

  if (updatedRole.name === USER_ROLE.ADMIN && employee.role_name === USER_ROLE.EMPLOYEE)
    event = "employee role updated to admin";
  else if (updatedRole?.name === USER_ROLE.EMPLOYEE && employee.role_name === USER_ROLE.ADMIN)
    event = "admin role updated to employee";

  return event;
};

export default getRoleAnalytics;
