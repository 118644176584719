import {
  COUNTRY_LIST_ACTION_TYPES,
  ICountryListPayload,
  ICountryListReducer,
} from "Redux/Actions/Common/getCountryList";
import { userInitialState } from "Redux/InitialState";

export const countryListReducer = (
  state: any = userInitialState,
  action: { type: COUNTRY_LIST_ACTION_TYPES; data: ICountryListPayload }
): ICountryListReducer => {
  switch (action.type) {
    case COUNTRY_LIST_ACTION_TYPES.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case COUNTRY_LIST_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case COUNTRY_LIST_ACTION_TYPES.ERROR:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: true,
      };
    default:
      return state;
  }
};
