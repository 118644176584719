import React from "react";
import { useHistory } from "react-router-dom";
import { EmptyState } from "@spenmo/splice";

import usePermissionCheck from "Permission/usePermissionCheck";
import { CASBIN_PAGES } from "Permission/Pages";
import { ACCOUNTING_SETTING_MENU, ACCOUNTING_SETTING_PAGE } from "Permission/Actions";
import { useIsSessionExpired } from "customHooks/Auth/useIsSessionExpired";

import { BodySkeleton } from "Views/Settings/components";
import { useAnalytic } from "Views/Settings/hooks";
import IntegrationSettingContainer from "./IntegrationSettingContainer";

import styles from "./styles.module.scss";

const IntegrationSetting = () => {
  const history = useHistory();
  const permissionParam = [
    { object: CASBIN_PAGES.ACCOUNTING_SETTING_MENU, action: ACCOUNTING_SETTING_MENU.READ },
    { object: CASBIN_PAGES.ACCOUNTING_SETTING_PAGE, action: ACCOUNTING_SETTING_PAGE.UPDATE },
  ];

  const [integrationSettingsPermissions, loadingPermission] = usePermissionCheck(permissionParam, true) as [
    boolean | boolean[],
    boolean
  ];
  const IS_ALLOWED_INTEGRATION_SETTING_MENU_READ = integrationSettingsPermissions?.[0];
  const IS_ALLOWED_INTEGRATION_SETTING_UPDATE = integrationSettingsPermissions?.[1];

  const isSessionExpired = useIsSessionExpired();

  useAnalytic({ eventSource: "Company", eventSubSource: "Integration" });

  if (loadingPermission) {
    return <BodySkeleton paddingLess />;
  }

  if (!IS_ALLOWED_INTEGRATION_SETTING_MENU_READ || isSessionExpired) {
    return (
      <div className={styles.noAccessPage}>
        <EmptyState
          description="Reach out to your Spenmo administrator for access"
          headline="You do not have access to this page"
          actionButtonText="Back to Homepage"
          onClickActionButton={() => history.push("/")}
        />
      </div>
    );
  }

  return <IntegrationSettingContainer isAllowedIntegrationSettingUpdate={IS_ALLOWED_INTEGRATION_SETTING_UPDATE} />;
};

export default IntegrationSetting;
