import React, { useEffect } from "react";
import qs from "query-string";
import { useHistory, useRouteMatch } from "react-router-dom";

import { COMMON_PATHS } from "Route/Common/paths";

import { PRODUCT_NAME } from "Redux/ModularProduct";

import useCheckOrgConfigs from "customHooks/useCheckOrgConfigs";

import { appNotification } from "Modules/appNotification/appNotification";

import { ILocationParams } from "Views/Reimbursement/@types";
import AddReimbursement from "Views/Reimbursement/AddReimbursement";
import useFetchDetails from "Views/Reimbursement/hooks/useFetchDetails";
import { REIMBURSEMENT_QUERY_PARAMS, REIMBURSEMENT_VIEWS } from "Views/Reimbursement/Constants";
import { REIMBURSEMENTS_PAGE_ANALYTICS, trackReimbursementAnalytics } from "Views/Reimbursement/Analytics";

const EditReimbursement = () => {
  const history = useHistory();

  const [isCrConfigEnabled, loadingCrConfig] = useCheckOrgConfigs(PRODUCT_NAME.CASH_REIMBURSEMENT, true) as [
    boolean,
    boolean
  ];

  const { params } = useRouteMatch<ILocationParams>();

  const { id = "" } = params;

  // Redirection Logic
  useEffect(() => {
    if (!loadingCrConfig && (!isCrConfigEnabled || id.trim().length === 0)) {
      history.replace(COMMON_PATHS.PAGE_NOT_FOUND);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingCrConfig]);

  const { data } = useFetchDetails(id);

  useEffect(() => {
    trackReimbursementAnalytics({
      eventName: REIMBURSEMENTS_PAGE_ANALYTICS.EDIT_REIMBURSEMENT,
      eventSource: "Page",
      eventSubSource: "Loaded",
    });
  }, []);

  return (
    <AddReimbursement
      toggleAddReimbursement={() => history.goBack()}
      isEdit={true}
      editData={data}
      isNewCR={true}
      loading={loadingCrConfig}
      submitReimbursement={() => {
        history.replace({
          pathname: REIMBURSEMENT_VIEWS.PERSONAL_REIMBURSEMENTS.link,
          search: qs.stringify({
            [REIMBURSEMENT_QUERY_PARAMS.PAGE]: 1,
          }),
        });
        appNotification.success({ message: "Reimbursement request successfully edited!" });
      }}
    />
  );
};

export default EditReimbursement;
