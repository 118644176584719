import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { trackEvent } from "utility/analytics";

import Icon from "Modules/icons";
import { ModalComponent } from "Modules/DS/Modal";
import { TITLE_TYPE } from "Modules/DS/Modal/types";

import { EmployeeDeleteEventName } from "Views/Employee/Analytics";

import { errorIcon } from "assets/img";

const Failed = ({ name }: { name: string }) => {
  const title = `Failed to Remove ${name ? name : ""}`;

  const errorMessage: string = useSelector(
    (state: any) => state.deleteEmployeeFromOrgReducer?.data?.payload?.status_message
  );

  const subtitle =
    errorMessage ||
    `An error occurred when removing this user. Please check for any outstanding transactions that need to be settled before attempting to delete again.`;

  useEffect(() => {
    trackEvent(EmployeeDeleteEventName.EMPLOYEE_ACCOUNT_DELETION_FAILED);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ModalComponent.CloseButton
        analytics={{ enabled: true, eventName: EmployeeDeleteEventName.CLOSE_EMPLOYEE_ACCOUNT_DELETION_FAIL }}
      />
      <ModalComponent.Title titleType={TITLE_TYPE.withBorder}>{title}</ModalComponent.Title>
      <Icon className="error-icon" alt="error" src={errorIcon} />
      <ModalComponent.SubTitle>{subtitle}</ModalComponent.SubTitle>
    </>
  );
};

export default Failed;
