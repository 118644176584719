import React from "react";
// import Chip, { IActionChipProps } from "Modules/DS/Chip";
import { DATE_RANGE } from "Modules/DS/Filter/@types";
import { AMOUNT_RANGE } from "Modules/DS/Select/@types";
import TrxnFilters, { BillsRecipientFilter, Quick } from "Views/Transaction/Filters";
import { ITitleWithID, ITrxnFilterItem } from "Views/Transaction/@types";
import { TRANSACTION_FILTER } from "Views/TransactionsListing/Filters/types";
import { transactionStatuses } from "Views/Transaction/Constants";

interface BillsTrxnFiltersProps {
  statuses?: string[];
  withUnscheduledFilter?: boolean;
}

export const BILLS_QUICK_FILTER_KEYS: string[] = [
  DATE_RANGE.START,
  DATE_RANGE.END,
  TRANSACTION_FILTER.MERCHANT,
  TRANSACTION_FILTER.BILL_STATUS,
  AMOUNT_RANGE.MIN,
  AMOUNT_RANGE.MAX,
];

export const BillStatusFilter = ({ statuses }: { statuses?: string[] }) => {
  let billsStatusList = (
    statuses ? transactionStatuses.filter((status) => statuses.includes(status)) : transactionStatuses
  ).map((item: string) => ({
    id: item,
    title: item,
  }));
  const paymentRunSpecificStatuses = statuses
    ? statuses
        .filter((status) => !transactionStatuses.includes(status))
        .map((item: string) => ({
          id: item,
          title: item,
        }))
    : [];

  billsStatusList = [...billsStatusList, ...paymentRunSpecificStatuses];

  return (
    <Quick.Multiple<ITitleWithID>
      id={TRANSACTION_FILTER.BILL_STATUS}
      searchable
      defaultValue="Status"
      displayKey="title"
    >
      {billsStatusList}
    </Quick.Multiple>
  );
};

const BILLS_TRXN_QUICK_FILTER_LIST = (
  statuses?: string[],
  withUnscheduledFilter: boolean = true
): ITrxnFilterItem[] => {
  return [
    {
      id: "BILLS-TRXN-QUICK-FILTER-1",
      enabled: true,
      Component: <Quick.Date id="bills" asISO={false} defaultValue="Due Date" />,
    },
    {
      id: "BILLS-TRXN-QUICK-FILTER-2",
      enabled: true,
      Component: <BillsRecipientFilter id={TRANSACTION_FILTER.MERCHANT} />,
    },
    { id: "BILLS-TRXN-QUICK-FILTER-3", enabled: true, Component: <BillStatusFilter statuses={statuses} /> },
    { id: "BILLS-TRXN-QUICK-FILTER-4", enabled: true, Component: <Quick.Amount id="bills_amount" /> },
    {
      id: "BILLS-TRXN-QUICK-FILTER-5",
      enabled: withUnscheduledFilter,
      Component: <Quick.Chip label="Unscheduled Bills" filterKey="unscheduled" value="true" />,
    },
  ].filter((item) => item.enabled);
};

export const BillsTrxnFilters: React.FC<BillsTrxnFiltersProps> = ({ statuses, withUnscheduledFilter }) => {
  return (
    <TrxnFilters
      quick={BILLS_TRXN_QUICK_FILTER_LIST(statuses, withUnscheduledFilter)}
      more={[]}
      quickKeys={BILLS_QUICK_FILTER_KEYS}
    />
  );
};
