import * as React from "react";
import { ICardDetails } from "Views/Card/types";
import { CARD_DETAILS_TABS } from "Views/Card/List/types";

interface CardDetailsContextType {
  cardData: ICardDetails;
  cardDetailsActiveTab: CARD_DETAILS_TABS;
  setCardDetailsActiveTab: React.Dispatch<React.SetStateAction<CARD_DETAILS_TABS>>;
  showCardDetailsInModal: boolean;
}

const CardDetailsContext = React.createContext<CardDetailsContextType>({
  cardData: null,
  cardDetailsActiveTab: CARD_DETAILS_TABS.DETAILS,
  setCardDetailsActiveTab: () => {
    /* do nothing */
  },
  showCardDetailsInModal: false,
});

export const useCardDetailsContext = () => React.useContext(CardDetailsContext);

export default CardDetailsContext;
