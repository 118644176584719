/**
 * @fileoverview This module provides the definition and creation of the Expenses menu
 * for the application's sidebar navigation. The menu encompasses links to manage different
 * financial aspects such as Cards, Bills, and Reimbursements.
 *
 * @author Sushmitha, Bakshi
 * @created 25 Aug 2023
 * @lastModified 30 Aug 2023
 */
import * as Icons from "@spenmo/splice";

import * as ROUTES from "constants/Routes.constants";

import { ISidebarMenuConfig } from "Views/Navigation/@types";
import { NAVIGATION_EVENTS } from "Views/Navigation/Constants";
import { useNavigationPermission } from "Views/Navigation/Hooks/useCheckPermission";
import { usePermission } from "Views/SubscriptionManagement/hooks/usePermission";
import { useNavigationConfigs } from "../Hooks/useOrgConfigs";
import { useIsSaasWithPaymentsOrg } from "customHooks/useIsSaasWithPaymentsOrg";
import useCheckFeatureStatus from "customHooks/featureCheck";
import { SPLIT_NAMES, SPLIT_TREATMENT_TYPES } from "Redux/splitio/constants";

/**
 * `useExpensesMenu` function generates a list of menu items for the expenses section of the sidebar.
 * Each menu item has an `id` for identification, a `props` object that contains its display properties
 * (such as label, icon, badge details), and its respective route, defined in the `history` property.
 *
 * Only items with the `enabled` property set to true will be included in the final list.
 *
 * @returns {ISidebarMenuConfig[]} - An array of sidebar menu items for the expenses section.
 */
export const useExpensesMenu = (): ISidebarMenuConfig[] => {
  const { isCardsPageAvailable, isBillPayPageAvailable, isReimbursementPageAvailable } = useNavigationConfigs();
  const { isAllowedBillPage } = useNavigationPermission();
  const { allowedToOpenPage: isAllowedOpenSubscription } = usePermission();
  const isSaasWithPaymentsOrg = useIsSaasWithPaymentsOrg();
  const upsellingCardFlag = useCheckFeatureStatus(SPLIT_NAMES.upsellingCard) === SPLIT_TREATMENT_TYPES.ON;

  return [
    {
      id: "EXPENSE_MENU_01",
      enabled: isCardsPageAvailable || upsellingCardFlag,
      permission: isSaasWithPaymentsOrg || upsellingCardFlag,
      props: {
        menu: {
          label: "Cards",
          prefixIcon: Icons.CardOutline,
          prefixActiveIcon: Icons.CardFilled,
        },
        history: ROUTES.CARDS_PATH,
        analytics: {
          enabled: true,
          data: { sidebar_event_source: NAVIGATION_EVENTS.CARDS },
        },
      },
    } as ISidebarMenuConfig,
    {
      id: "EXPENSE_MENU_02",
      enabled: isBillPayPageAvailable,
      permission: isAllowedBillPage,
      props: {
        menu: {
          label: "Bills",
          prefixIcon: Icons.BillOutline,
          prefixActiveIcon: Icons.BillFilled,
        },
        history: ROUTES.BILLS_PATH,
        analytics: {
          enabled: true,
          data: { sidebar_event_source: NAVIGATION_EVENTS.BILLS },
        },
      },
    } as ISidebarMenuConfig,
    {
      id: "EXPENSE_MENU_03",
      enabled: isReimbursementPageAvailable,
      permission: true,
      props: {
        menu: {
          label: "Reimbursements",
          prefixIcon: Icons.CashOutline,
          prefixActiveIcon: Icons.CashFilled,
        },
        history: {
          pathname: ROUTES.REQUESTS_PATH,
          state: { showAddReimburse: false },
        },
        analytics: {
          enabled: true,
          data: { sidebar_event_source: NAVIGATION_EVENTS.REQUESTS },
        },
      },
    } as ISidebarMenuConfig,
    {
      id: "EXPENSE_MENU_04",
      enabled: isAllowedOpenSubscription,
      permission: isSaasWithPaymentsOrg,
      props: {
        menu: {
          label: "Subscriptions",
          prefixIcon: Icons.Refresh,
          prefixActiveIcon: Icons.Refresh,
        },
        history: ROUTES.SUBSCRIPTIONS,
        analytics: {
          enabled: true,
          data: { sidebar_event_source: NAVIGATION_EVENTS.SUBSCRIPTIONS },
        },
      },
    } as ISidebarMenuConfig,
  ].filter((item) => item.enabled && item.permission);
};
