import * as React from "react";
import { Typography } from "antd";
import { getIsCardVendorNium } from "Views/Home/Utilites";
import { masterCardIcon32x20, visaIcon32x20 } from "assets/icons/card";

import styles from "Views/Card/Pin/CardInfo/index.module.scss";

const { Text } = Typography;

interface ICardInfoProps {
  cardLastFourDigits: string;
  cardTitle: string;
  cardColor: string;
}

const CardInfo = ({ cardLastFourDigits, cardTitle, cardColor }: ICardInfoProps) => {
  const isCardVendorNium = getIsCardVendorNium();
  return (
    <div className={styles["container"]}>
      <div className={styles["card"]} style={{ background: cardColor }}>
        <img src={isCardVendorNium ? visaIcon32x20 : masterCardIcon32x20} alt="vendor icon" />
      </div>
      <div className={styles["card-details-container"]}>
        <Text className={styles["card-title"]} ellipsis>
          {cardTitle}
        </Text>
        <Text className={styles["card-number"]}>{`**** **** **** ${cardLastFourDigits}`}</Text>
      </div>
    </div>
  );
};

export default CardInfo;
