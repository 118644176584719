import * as React from "react";
import DropDown from "Modules/dropDown";
import Modal from "Modules/modal";
import Button from "Modules/button";
import { trackEvent } from "utility/analytics";

import styles from "Views/Card/Delete/index.module.scss";

interface ICardDeleteModalProps {
  cardId: string;
  onClose: () => void;
  onDelete: (reason: string) => void;
}

const DELETE_OPTIONS = [
  { name: "lost", id: "1" },
  { name: "stolen", id: "2" },
  { name: "damaged", id: "3" },
];

const CardDeleteModal: React.FunctionComponent<ICardDeleteModalProps> = ({ cardId, onClose, onDelete }) => {
  const [deleteReason, setDeleteReason] = React.useState(DELETE_OPTIONS[0].name);
  const onDeleteCard = () => {
    onDelete(deleteReason);
    trackEvent("card delete confirmed", { "card id": cardId });
  };
  const onDeleteReasonSelect = (val) => {
    const reason = DELETE_OPTIONS.filter((option) => option?.id === val)?.[0]?.name || "";
    setDeleteReason(reason);
    trackEvent("card delete reason provided", {
      "delete reason": deleteReason,
    });
  };
  return (
    <Modal show close={onClose} className={styles["delete-modal"]}>
      <h4 className={styles["title"]}>Delete Card?</h4>
      <p className={styles["description"]}>
        This card will be permanently deleted. Are you sure you want to delete this card?
      </p>
      <label className={styles["label"]}>Select Reason</label>
      <DropDown fullSize listData={DELETE_OPTIONS} action={onDeleteReasonSelect} />
      <div className={styles["action-btns"]}>
        <Button text="cancel" rounded secondary action={onClose} />
        <Button text="Delete Card" rounded action={onDeleteCard} disabled={!deleteReason} testid="delete-card" />
      </div>
    </Modal>
  );
};

export default CardDeleteModal;
