import React from "react";

import DeleteTeam from "Views/Team/Delete";
import DeleteTeamProvider from "Views/Team/Delete/provider";
import TeamsListing from "Views/Team/List";
import { TEAM_ACTION } from "Views/Team/List/Actions/constant";
import TeamActionContext from "Views/Team/List/Actions/context";
import TeamActionProvider from "Views/Team/List/Actions/provider";
import TeamToaster from "Views/Team/Toast";
import TeamToasterProvider from "Views/Team/Toast/provider";
import { SelectProvider } from "Modules/DS/Select";
import RenameTeam from "Views/Team/Rename";

const Team = () => {
  return (
    <SelectProvider>
      <TeamToasterProvider>
        <TeamActionProvider>
          <DeleteTeamProvider>
            <TeamsListing />
            <TeamActionContext.Consumer>
              {({ state: { selectedAction } }) => {
                if (selectedAction === TEAM_ACTION.DELETE) {
                  return <DeleteTeam />;
                }
                if (selectedAction === TEAM_ACTION.RENAME) {
                  return <RenameTeam />;
                }
              }}
            </TeamActionContext.Consumer>
            <TeamToaster />
          </DeleteTeamProvider>
        </TeamActionProvider>
      </TeamToasterProvider>
    </SelectProvider>
  );
};

export default Team;

export * from "./@types";
