import AxiosGetForApiWrapper from "utility/apiWrapperHelper";

export const GetAllRequests = (
  page = 0,
  limit = 10,
  orgId = "",
  teamId = "",
  scheduledRequest = false,
  status = "all"
) => {
  return AxiosGetForApiWrapper(
    `/api/v1/fund/request?pg=${page}&limit=${limit}&status=${status}&organisation_id=${orgId}&team_id=${teamId}&is_scheduled_request=${scheduledRequest}`
  );
};

export const GetAllScheduledSends = (orgId = "", pageNumber = 0, pageSize = 10) => {
  return AxiosGetForApiWrapper(`/api/v1/fund/send?pg=${pageNumber}&limit=${pageSize}&organisation_id=${orgId}`);
};
