import "Modules/Cards/ThirdPartyIntegrationCards/thirdPartyIntegrationCards.scss";
import React from "react";
import PropTypes from "prop-types";
import CircleLoader from "Modules/loading";
import { Typography, Button, Tags } from "@spenmo/splice";

const ThirdPartyIntegrationCard = (props) => {
  const { thirdPartyName, thirdPartyLogoSrc, actionBtnsArray, isConnected, loadingConnectionStatus } = props;

  const getActionBtns = () => {
    if (loadingConnectionStatus) return <CircleLoader />;
    return (
      actionBtnsArray?.length > 0 &&
      actionBtnsArray.map((btnDetails) => (
        <Button key={btnDetails?.buttonText} {...btnDetails}>
          {btnDetails?.buttonText}
        </Button>
      ))
    );
  };
  return (
    <div className="third-party-integration-card">
      <div className="third-party-integration-card__body">
        <div className="third-party-integration-card__logo">
          <img src={thirdPartyLogoSrc} alt="integration app logo" />
        </div>
        <div>
          <Typography tag="h4" size="m" variant="body-content" className="third-party-integration-card__header">
            {thirdPartyName}
          </Typography>
          <Typography tag="p" size="m" variant="body-content" className="third-party-integration-card__caption">
            Protect your data and funds from the risk of account theft or hacking with an added security with Google
            Authenticator
          </Typography>
          {isConnected && (
            <Tags.Progression
              hierarchy="default"
              label="Connected"
              variant="success"
              className="third-party-integration-card__chip"
            />
          )}
        </div>
      </div>
      <div>{getActionBtns()}</div>
    </div>
  );
};

ThirdPartyIntegrationCard.propTypes = {
  thirdPartyLogoSrc: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  thirdPartyName: PropTypes.string.isRequired,
  actionBtnsArray: PropTypes.array,
  isConnected: PropTypes.bool.isRequired,
  connectedTagText: PropTypes.string,
  notConnectedTagText: PropTypes.string,
  loadingConnectionStatus: PropTypes.bool,
};

export default ThirdPartyIntegrationCard;
