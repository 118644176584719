import React, { useState } from "react";
import { Dropdown } from "antd";
import { useCanUserEditCards, useCardDoesNotBelongToUser } from "customHooks/Card";
import { trackEvent } from "utility/analytics";
import { EditCardEventNames } from "Views/Card/Analytics";
import { navigationDotsVertical } from "assets/img/index";
import { useClickOutside } from "customHooks/useClickOutside";
import { useCardListContext } from "Views/Card/List/index.context";
import { CARD_DETAILS_TABS, CARD_DRAWER_TYPES } from "Views/Card/List/types";
import { CARD_STATUS, ICardDetails } from "Views/Card/types";
import Loader, { LoaderTypes } from "Views/Card/Components/Loader";

import styles from "./index.module.scss";
interface IQuickActionsProps {
  cardData: ICardDetails;
}

const QuickAction = ({ cardData }: IQuickActionsProps) => {
  const {
    setShowCardDeleteModal,
    deleteInProgressCards,
    setShowCardActivationModal,
    activationInProgressCards,
    enableOrDisableCard,
    enableOrDisableInProgressCards,
    setCardDetails,
  } = useCardListContext();
  const { cardStatus, id, teamId, cardUserId } = cardData || {};
  const canUserEditCards = useCanUserEditCards(teamId);

  const [triggerRef] = useClickOutside(() => {
    setPopoverVisible(false);
  }, [".quick-action", ".quick-action__popover__trigger"]);
  const [popoverVisible, setPopoverVisible] = useState(false);

  const editButton = {
    label: "Edit Card Settings",
    action: () => {
      setCardDetails(() => {
        return {
          drawerType: CARD_DRAWER_TYPES.EDIT,
          activeTab: CARD_DETAILS_TABS.SETTINGS,
          data: cardData,
        };
      });
      trackEvent(EditCardEventNames.CARD_EDIT_SETTINGS_CLICKED);
    },
    disable: false,
    showLoader: false,
  };

  const statusButton = () => {
    if (canUserEditCards || cardStatus === CARD_STATUS.ENABLED) {
      return {
        label: cardStatus === CARD_STATUS.ENABLED ? "Disable Card" : "Enable Card",
        action: () => enableOrDisableCard(cardStatus, id, canUserEditCards),
        disable: enableOrDisableInProgressCards?.length > 0,
        showLoader: enableOrDisableInProgressCards?.includes(cardData?.id),
      };
    }
    return null;
  };

  const deleteButton = {
    label: "Delete Card",
    action: () => {
      setShowCardDeleteModal(cardData?.id);
    },
    disable: deleteInProgressCards?.length > 0,
    showLoader: deleteInProgressCards?.includes(cardData?.id),
  };

  const activateButton = {
    label: "Activate",
    action: () => {
      setShowCardActivationModal(cardData?.id);
    },
    disable: useCardDoesNotBelongToUser(cardUserId) || activationInProgressCards?.length > 0,
    showLoader: activationInProgressCards?.includes(cardData?.id),
  };

  const generateOptions: () => { label: string; action: () => void; disable: boolean; showLoader: boolean }[] = () => {
    const { cardStatus } = cardData || {};
    if (cardStatus === CARD_STATUS.ENABLED || cardStatus === CARD_STATUS.DISABLED) {
      return [editButton, statusButton(), deleteButton]?.filter((btn) => !!btn);
    } else {
      return [activateButton];
    }
  };

  const DropdownPopover = () => {
    const list = generateOptions();
    return (
      <ul className={styles["dropdown-popover"]} data-testid="dropdown-popover">
        {list?.map((item, index) => {
          const key = `${index}-${item?.label}`.replace(/\s/g, "");
          return (
            <li key={key} className={styles["dropdown-popover__item"]} data-testid={key}>
              <button
                onClick={(event) => {
                  event.stopPropagation();
                  item?.action();
                  setPopoverVisible(false);
                }}
                className={styles["dropdown-popover__item__button"]}
                disabled={item?.disable}
              >
                {item?.label}
                {item?.showLoader && <Loader type={LoaderTypes.red} />}
              </button>
            </li>
          );
        })}
      </ul>
    );
  };

  return (
    <Dropdown
      overlay={DropdownPopover}
      placement="bottomRight"
      visible={popoverVisible}
      overlayClassName={styles["quick-action"]}
    >
      <button
        onClick={(event) => {
          event.stopPropagation();
          cardStatus !== CARD_STATUS.IN_PROGRESS && setPopoverVisible((prevState) => !prevState);
        }}
        className={styles["quick-action__popover__trigger"]}
        ref={triggerRef}
        data-testid="quick-action"
      >
        <img src={navigationDotsVertical} alt="Vertical Dots" />
      </button>
    </Dropdown>
  );
};

export default QuickAction;
