import { UploadFile as UploadFileAction } from "Redux/DataCalls/UploadImage.api";

import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";

import { validateFile } from "Views/Transaction/Helper";
import { ALLOWED_FILE_EXTENSIONS, MAX_FILE_SIZE_ALLOWED } from "Views/UploadInvoice/const";

export const processFilesData = (uploadedFiles: FileList) => {
  const getFilePath = async (file: File) => {
    try {
      const resp = await UploadFileAction(file);

      if (resp.status > HTTP_STATUS_CODE.OK) {
        throw new Error(resp.data?.payload?.status_message || resp.data?.payload?.errors);
      } else if (resp?.data?.status > HTTP_STATUS_CODE.OK) {
        throw new Error(resp.data?.payload?.status_message);
      }

      return resp?.data?.payload?.file_path as string;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  const processFileData = async (file: File) => {
    try {
      const { isFileValid, sizeValid, extensionValid } = validateFile(file);
      const sizeMessage = `File size must be less than ${MAX_FILE_SIZE_ALLOWED / (1024 * 1024)}MB`;
      const extMessage = `Files must belong to either of the following types: ${ALLOWED_FILE_EXTENSIONS.join(", ")}`;
      if (!sizeValid && extensionValid) {
        throw new Error(sizeMessage);
      } else if (!extensionValid && sizeValid) {
        throw new Error(extMessage);
      } else if (!isFileValid) {
        throw new Error(extMessage + " and " + sizeMessage);
      }

      const resp: string = await getFilePath(file);

      return resp;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  const processFiles = async () => {
    let paths: string[] = [];

    for (const file of Array.from(uploadedFiles)) {
      try {
        const resp = await processFileData(file);
        paths = paths.concat(resp);
      } catch (error) {
        throw new Error(error.message);
      }
    }

    return paths;
  };

  return processFiles();
};
