import React from "react";
import PropTypes from "prop-types";
import { Col, Form, Input, Row, Select } from "antd";
const { Option } = Select;

export const AddBankAccount = ({
  currencyData = [],
  teamCurrency = "",
  trackAddBankAccountEvents = () => {},
}) => {
  return (
    <div className={"add-bank-account"}>
      <h3>Expense Details</h3>
      <Row gutter={[8, 8]}>
        <Col span={6}>
          <Form.Item
            rules={[{ required: true, message: "Please enter the bank name!" }]}
            name={"bank_name"}
            label={"Bank Name"}
          >
            <Input
              placeholder="Enter bank name"
              onBlur={() =>
                trackAddBankAccountEvents("bank_name", "add bank name added")
              }
              autoComplete="off"
            />
          </Form.Item>
        </Col>
        <Col />
        <Col span={6}>
          <Form.Item name={"swift_code"} label={"Swift Code"}>
            <Input
              placeholder={"Enter swift code"}
              onBlur={() =>
                trackAddBankAccountEvents(
                  "swift_code",
                  "add bank swift code added",
                )
              }
              autoComplete="off"
            />
          </Form.Item>
          <p>For non SG bank account holders</p>
        </Col>
      </Row>
      <h3>Account Details</h3>
      <Row gutter={[8, 8]}>
        <Col span={6}>
          <Form.Item
            rules={[
              { required: true, message: "Please enter the account name!" },
            ]}
            name={"account_name"}
            label={"Account Name"}
          >
            <Input
              placeholder="Your name as per your Bank Account"
              onBlur={() =>
                trackAddBankAccountEvents(
                  "account_name",
                  "add bank account name added",
                )
              }
              autoComplete="off"
            />
          </Form.Item>
        </Col>
        <Col />
        <Col span={6}>
          <Form.Item
            rules={[{ required: true, message: "Please select the currency!" }]}
            name={"currency_code"}
            label={"Currency"}
          >
            <Select
              defaultValue={teamCurrency}
              className={"add-bank--form__select"}
              placeholder={"Select currency"}
              onChange={() =>
                trackAddBankAccountEvents(
                  "currency_code",
                  "add bank currency selected",
                )
              }
            >
              {currencyData?.map?.((currency) => (
                <Option key={currency.id} value={currency.currency_code}>
                  {`${currency.currency_code} - ${currency.currency_description}`}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[8, 8]}>
        <Col span={6}>
          <Form.Item
            rules={[
              { required: true, message: "Please enter the account number!" },
            ]}
            name={"account_number"}
            label={"Account Number"}
            onBlur={() =>
              trackAddBankAccountEvents(
                "account_number",
                "add bank account number added",
              )
            }
          >
            <Input placeholder="Enter account number" autoComplete="off" />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

AddBankAccount.propTypes = {
  currencyData: PropTypes.array,
  teamCurrency: PropTypes.string,
  trackAddBankAccountEvents: PropTypes.func,
};

export default AddBankAccount;
