import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import {
  setCurrentPage,
  setActiveSubTab,
  selectApiCallState,
  selectActiveSubTab,
  clearAllFilter,
  applyFilter,
  selectPayImmediatelyConfig,
} from "Redux/Reducers/Approval/ApprovalCenter/approvalCenter.slice";
import { REIMBURSEMENT_STATUS } from "../types";
import { useAppSelector } from "../../../../hook";
import styles from "./index.module.scss";
import { ApiCallState, NetworkCallState } from "Models/Network/models";

interface ReimbursementSubTab {
  label: string;
  status: REIMBURSEMENT_STATUS;
}

const ApprovalReimbursementTab = () => {
  const dispatch = useDispatch();
  const MAX_TAB_COUNT_DISPLAY = 99;
  const apiCallState: ApiCallState = useAppSelector(selectApiCallState);
  const activeTab: REIMBURSEMENT_STATUS = useAppSelector(selectActiveSubTab);
  const payImmediatelyConfig = useAppSelector(selectPayImmediatelyConfig);

  const [tabCount, setTabCount] = useState({});
  const tabs: ReimbursementSubTab[] = [
    { label: "Pending Approval", status: REIMBURSEMENT_STATUS.PENDING },
    { label: "Ready for Payment", status: REIMBURSEMENT_STATUS.READY },
  ];

  useEffect(() => {
    return () => {
      dispatch(clearAllFilter());
    };
  }, []);

  useEffect(() => {
    setTabCount((prevTabCount) => ({
      ...prevTabCount,
      [activeTab]: apiCallState.paging.total,
    }));
  }, [apiCallState.paging.total]);

  const handleOnClickTab = (status: REIMBURSEMENT_STATUS) => {
    dispatch(setActiveSubTab(status));
    dispatch(setCurrentPage(1));
    dispatch(clearAllFilter());
    if (status === REIMBURSEMENT_STATUS.READY) {
      dispatch(applyFilter({ pendingSettlement: true }));
    }
  };

  const showTab = (tab: ReimbursementSubTab) => {
    return (
      tab.status === REIMBURSEMENT_STATUS.PENDING || (tab.status === REIMBURSEMENT_STATUS.READY && payImmediatelyConfig)
    );
  };

  return (
    <ul className={styles["reimbursement__tab-list"]}>
      {tabs.filter(showTab).map((item) => (
        <li
          key={item.label}
          className={classNames(styles["reimbursement__tab-item"], { [styles["active"]]: activeTab === item.status })}
          onClick={() => handleOnClickTab(item.status)}
        >
          {item.label}
          {item.status !== REIMBURSEMENT_STATUS.READY && tabCount[item.status] && tabCount[item.status] !== 0
            ? apiCallState.loading === NetworkCallState.completed && (
                <span className={styles["reimbursement__tab-item--count"]}>
                  {tabCount[item.status] > MAX_TAB_COUNT_DISPLAY ? `${MAX_TAB_COUNT_DISPLAY}+` : tabCount[item.status]}
                </span>
              )
            : null}
        </li>
      ))}
    </ul>
  );
};

export default ApprovalReimbursementTab;
