import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { isBookkeeper, GetBaseAuthObject } from "utility";
import useIsAryadana from "customHooks/useIsAryadana.tsx";
import { uploadBulk as uploadInvoiceAPI } from "Redux/DataCalls/Disbursement.api";

import LoadingIcon from "Modules/loading";
import Toaster from "Modules/DS/Toaster";
import {
  TOASTER_SIZE_TYPE,
  TOASTER_STATUS_TYPE,
} from "Modules/DS/Toaster/types.d";
import { USER_ROLE } from "constants/Team.constant";

import UploadCompleted from "./UploadCompleted";
import UploadNotCompleted from "./UploadNotCompleted";
import { ALLOWED_FILE_EXTENSIONS } from "./const";

import styles from "./UploadMultiple.module.scss";
import { getAllTeamsWallet, getOrgDetailFunc } from "Redux/Actions";
import { mapTeamToWallet } from "utility/Bills";
import { trackEvent } from "utility/analytics";

import usePermissionCheck from "Permission/usePermissionCheck";
import { CASBIN_PAGES } from "Permission/Pages";
import { BILL_MENU } from "Permission/Actions";

import useCheckOrgConfigs from "customHooks/useCheckOrgConfigs.tsx";
import LandingSoon from "Views/Bills/LandingSoon";
import { PRODUCT_NAME } from "Redux/ModularProduct";
import { landingSoon } from "assets/img";
import { BILL_PAYMENTS_PAGE } from "Permission/Actions";
import { COMMON_PATHS } from "Route/Common/paths";

const UploadMultiple = () => {
  const permissionParam = [
    { object: CASBIN_PAGES.BILL_MENU, action: BILL_MENU.READ },
    {
      object: CASBIN_PAGES.BILL_PAYMENTS_PAGE,
      action: BILL_PAYMENTS_PAGE.SUBMIT_BILL_PAYMENT,
    },
  ];
  const [permissions, loading] = usePermissionCheck(permissionParam, true);
  const IS_ALLOWED_BILL_MENU_READ = permissions[0];
  const IS_ALLOWED_CREATE_BILL = permissions[1];
  const history = useHistory(),
    isPageAvailable = useCheckOrgConfigs(PRODUCT_NAME.BILLPAY),
    inputFileRef = useRef(),
    dispatch = useDispatch(),
    [userInfo, allTeamsWallet] = useSelector((state) => [
      state.userInfoReducer,
      state.allTeamsWalletReducer,
    ]);

  const [toaster, setToaster] = useState({
    visible: false,
    message: "",
    status: undefined,
  });

  const clearToaster = () =>
    setToaster({
      visible: false,
      message: "",
      status: undefined,
    });

  const [apiResponse, setApiResponse] = useState(null),
    [isLoadingFile, setIsLoadingFile] = useState(false),
    [fileName, setFileName] = useState(null),
    [walletData, setWalletData] = useState([]),
    [isBalanceHidden, setIsBalanceHidden] = useState(undefined),
    [selectedTeam, setSelectedTeam] = useState(null),
    [selectedWallet, setSelectedWallet] = useState(null);

  useEffect(() => {
    if (!loading) {
      if (!IS_ALLOWED_BILL_MENU_READ || !IS_ALLOWED_CREATE_BILL) {
        history.replace(COMMON_PATHS.PAGE_NOT_FOUND);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [IS_ALLOWED_BILL_MENU_READ, IS_ALLOWED_CREATE_BILL, loading]);

  useEffect(() => {
    dispatch(getAllTeamsWallet());
    dispatch(getOrgDetailFunc(GetBaseAuthObject().orgId));
    trackEvent("bill visit create xlsx page");
  }, [dispatch]);

  useEffect(() => {
    if (!allTeamsWallet.loading) {
      // is_balance_hidden true meaning company is using cards 2.0
      setIsBalanceHidden(allTeamsWallet?.data?.payload?.is_balance_hidden);
      setWalletData(
        mapTeamToWallet({
          teams: allTeamsWallet?.data?.payload?.teams,
          isAdmin: userInfo.isAdmin,
          isAccountant: userInfo.company_role === USER_ROLE.ACCOUNTANT,
          isBookkeeper: isBookkeeper(userInfo?.company_role),
          isCards2: allTeamsWallet?.data?.payload?.is_balance_hidden,
          manageTeams: userInfo.manageTeams,
        }),
      );
    }
  }, [allTeamsWallet, userInfo.isAdmin, userInfo.manageTeams]);

  useEffect(() => {
    if (walletData?.length) {
      setSelectedWallet(walletData[0].walletId);
      setSelectedTeam(walletData[0].teamId);
    }
  }, [walletData]);

  const onValidateFile = (data) => setFileName(data),
    onUploadFile = (data) => setApiResponse(data),
    dropDownAction = (id) => {
      setSelectedWallet(id);
      setSelectedTeam(walletData.find((item) => item.walletId === id).teamId);
    },
    onClick = () => inputFileRef.current.click();

  const onChangeFile = async (event) => {
    if (event.target.files.length) {
      setIsLoadingFile(true);

      const file = event.target.files[0];
      if (
        ALLOWED_FILE_EXTENSIONS.includes(
          file.name.split(".").pop().toLowerCase(),
        )
      ) {
        try {
          const { data } = await uploadInvoiceAPI(
            file,
            selectedWallet,
            selectedTeam,
          );
          setApiResponse(data.payload);
          setFileName(file.name);
        } catch (_) {
          setToaster({
            visible: true,
            message:
              "Payments made to the USA require Recipient Address and Recipient City to be provided. Get the latest template to fill in the mandatory new fields.",
            status: TOASTER_STATUS_TYPE.FAILED,
          });
        }
      } else {
        setToaster({
          visible: true,
          message: "We only accept XLSX format",
          status: TOASTER_STATUS_TYPE.FAILED,
        });
      }

      setIsLoadingFile(false);
      inputFileRef.current.value = "";
    }
  };

  const showAryadana = useIsAryadana();

  if (!isPageAvailable) {
    return (
      <LandingSoon
        iconSrc={landingSoon}
        iconAlt="Landing Soon"
        title="Landing Soon"
        body="Bill Payment will be available in your region soon"
      />
    );
  }

  return (
    <div className={styles.container}>
      {isLoadingFile && (
        <div className={styles.overlay}>
          <LoadingIcon className={styles.loading} />
        </div>
      )}
      {apiResponse !== null ? (
        <UploadCompleted
          onClick={onClick}
          dropDownAction={dropDownAction}
          fileName={fileName}
          apiResponse={apiResponse}
          walletData={walletData}
          selectedTeam={selectedTeam}
          selectedWallet={selectedWallet}
          isBalanceHidden={isBalanceHidden}
          onUpdateAPIResponse={setApiResponse}
          showAryadana={showAryadana}
        />
      ) : (
        <UploadNotCompleted
          onValidateFile={onValidateFile}
          onUploadFile={onUploadFile}
          dropDownAction={dropDownAction}
          walletData={walletData}
          selectedTeam={selectedTeam}
          selectedWallet={selectedWallet}
          isBalanceHidden={isBalanceHidden}
          showAryadana={showAryadana}
        />
      )}
      <input
        className={styles.hidden}
        type="file"
        ref={inputFileRef}
        accept={ALLOWED_FILE_EXTENSIONS.map((item) => `.${item}`).join(",")}
        onChange={onChangeFile}
      />
      <Toaster size={TOASTER_SIZE_TYPE.M} onClose={clearToaster} {...toaster} />
    </div>
  );
};

export default UploadMultiple;
