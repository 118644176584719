import React, { useContext, useEffect, useRef } from "react";

import {
  IFilterItem,
  IListContainer,
  IMultipleQuickFilter,
  IQuickFilterContainer,
  initialMultipleFilterStates,
} from "Modules/DS/Filter/@types";
import { ISelectContext, SelectContext } from "Modules/DS/Select";
import Menu, { IItemID, ILineProps, LINE_TYPE } from "Modules/DS/Menu";
import { useMultiSelect } from "Modules/DS/Menu/List/useHooks/useMultiSelect";

import { getLabel } from "./getLabel";
import { Container } from "./Container";

export const MultipleQuickFilter = <T extends IItemID>({
  height,
  children,
  displayKey,
  defaultValue,
  dataSet = [],
  idKey = "id" as keyof T,
  appliedKey = "id" as keyof T,
  header = null,
  iconClass = "",
  filterItemProps,
  onAddSelected = () => null,
  clearFilter = () => null,
  onClickOutside = () => null,
  presetValue,
  clear = false,
  states = { ...initialMultipleFilterStates },
}: IMultipleQuickFilter<T>): JSX.Element => {
  const { select, closeSelect } = useContext<ISelectContext>(SelectContext);

  const isSelected: boolean = states.title && states.title !== defaultValue;

  const disableActions = !children.length || !states.selectedIds.length;

  const clearingFilter = useRef<boolean>(false);

  const isPopoverMounted = select.show && select.key === filterItemProps.id;

  useEffect(() => {
    if (clear) {
      handleClearFilter();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clear]);

  useEffect(() => {
    if (states.selectedIds.length && !states.title && clearingFilter.current) {
      handleClearFilter();
      clearingFilter.current = false;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearingFilter]);

  useEffect(() => {
    if (presetValue) {
      const checked: T[] = Array.isArray(children)
        ? children.filter((item) => presetValue.includes(item[appliedKey as string]))
        : [];
      if (checked.length === 0) return;

      const getSelectedIds = checked.map((item) => item[idKey as string]);

      if (states.title && !states.selectedIds.length) {
        states?.setSelectedIds?.(getSelectedIds);
        return;
      }

      if (!states.selectedIds.length && !states.title && !clearingFilter.current) {
        states?.setSelectedIds?.(getSelectedIds);

        let text: string = getLabel(checked, displayKey as string, defaultValue);
        states?.setTitle?.(text);

        return;
      } else if (!states.selectedIds.length && !states.title && clearingFilter.current) {
        handleClearFilter();
      }
    }

    return () => {
      clearingFilter.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPopoverMounted, presetValue]);

  const onApplyHandler = () => {
    if (!states.selectedIds.length) return;

    const ifDataSetProvided: T[] = dataSet.length ? dataSet : children;

    if (!ifDataSetProvided.length) return;

    const checked: T[] = states.selectedIds
      .map((selectedId) => ifDataSetProvided.find((item) => item[idKey as string] === selectedId))
      .filter(Boolean);

    if (!checked.length) return;

    let text: string = getLabel(checked, displayKey as string, defaultValue);

    if (!text) return;

    states?.setTitle?.(text);

    const value: string[] = checked.map((val) => val[appliedKey as string]);

    onAddSelected?.(value);
    closeSelect();
  };

  const onClickOutsideHandler = () => {
    if (!isSelected && states.selectedIds.length > 0) {
      states.setSelectedIds([]);
    }

    onClickOutside?.();
  };

  const handleClearFilter = (): void => {
    clearingFilter.current = true;

    states?.setLoading?.(false);
    states?.setSelectedIds?.([]);
    states?.setTitle?.("");
    if (isSelected) {
      clearFilter?.();
      closeSelect();
    }
  };

  const renderFooter = () => {
    return (
      children.length && (
        <Menu.Actions
          onApply={onApplyHandler}
          onClear={handleClearFilter}
          disableApply={disableActions}
          disableClear={disableActions}
        />
      )
    );
  };

  const onClickHandler = useMultiSelect(states.selectedIds, states.setSelectedIds);

  const lineProps = (item: T): ILineProps => ({
    id: item[idKey as string],
    title: item[displayKey as string],
    selected: states.selectedIds.includes(item[appliedKey as string]),
    type: LINE_TYPE.CHECKBOX_SINGLE_LINE,
    loading: states?.loading,
    onClick: onClickHandler,
  });

  const filterProps: Omit<IFilterItem, "children" | "iconClass"> = {
    selected: isSelected,
    clearFilter: handleClearFilter,
    onClickOutside: onClickOutsideHandler,
    title: isSelected ? states.title : defaultValue,
    ...filterItemProps,
  };

  const listProps: Omit<IListContainer<T>, "children"> = {
    idKey,
    height,
    lineProps,
    loading: states?.loading,
  };

  const props: Omit<IQuickFilterContainer<T>, "children"> = {
    header,
    iconClass,
    listProps,
    footer: renderFooter(),
    filterItemProps: filterProps,
  };

  return <Container<T> {...props}>{children}</Container>;
};
