import APIclient from "API/Client";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import { IMyInfoCallBackPayload } from "./types";

export enum MY_INFO_CALLBACK_TYPES {
  REQUEST = "MY_INFO_CALLBACK_REQUEST",
  SUCCESS = "MY_INFO_CALLBACK_SUCCESS",
  ERROR = "MY_INFO_CALLBACK_ERROR",
}

const fetchDataRequest = (data?: any) => {
  return {
    type: MY_INFO_CALLBACK_TYPES.REQUEST,
    data,
  };
};

const fetchDataSuccess = (data: IMyInfoCallBackPayload) => {
  return {
    type: MY_INFO_CALLBACK_TYPES.SUCCESS,
    data,
  };
};

const fetchDataError = (data: IMyInfoCallBackPayload) => {
  return {
    type: MY_INFO_CALLBACK_TYPES.ERROR,
    data,
  };
};

export const MyInfoCallBack = (payload, isMyInfo: boolean = false) => {
  return (dispatch) => {
    dispatch(fetchDataRequest());
    const MyInfoURL = isMyInfo
      ? `/ms/spm-organization/public/v1/myinfo/callback?code=${payload.code}&state=${payload.state}`
      : "ms/spm-organization/v1/myinfobiz/kyb";

    APIclient.postData(MyInfoURL, payload)
      .then((response) => {
        if (response?.data?.status === HTTP_STATUS_CODE.OK) {
          dispatch(fetchDataSuccess(response.data));
        } else {
          dispatch(fetchDataError(response.data));
        }
      })
      .catch((error) => {
        dispatch(fetchDataError(error?.response?.data));
      });
  };
};
