import APIclient from "API/Client";
import { GetFormData } from "../../utility/APIWrapper";
import { GetBaseAuthObject } from "../../utility";
import { trackEvent } from "utility/analytics";

export const FETCH_DATA_REQUEST = "FETCH_DATA_REQUEST";
export const FETCH_DATA_SUCCESS = "FETCH_DATA_SUCCESS";
export const FETCH_DATA_FAILURE = "FETCH_DATA_FAILURE";

export const FETCH_DELETE_REQUEST = "FETCH_DELETE_REQUEST";
export const FETCH_DELETE_SUCCESS = "FETCH_DELETE_SUCCESS";
export const FETCH_DELETE_FAILURE = "FETCH_DELETE_FAILURE";

export const FETCH_ADD_REQUEST = "FETCH_ADD_REQUEST";
export const FETCH_ADD_SUCCESS = "FETCH_ADD_SUCCESS";
export const FETCH_ADD_FAILURE = "FETCH_ADD_FAILURE";

const fetchDataReq = (data) => {
  return {
    type: FETCH_DATA_REQUEST,
    data: data,
  };
};

const fetchDataSuccess = (data) => {
  return {
    type: FETCH_DATA_SUCCESS,
    data: data,
  };
};

const fetchDataError = (data) => {
  return {
    type: FETCH_DATA_FAILURE,
    data: data,
  };
};

const fetchDelDataReq = (data) => {
  return {
    type: FETCH_DELETE_REQUEST,
    data: data,
  };
};

const fetchDelDataSuccess = (data) => {
  return {
    type: FETCH_DELETE_SUCCESS,
    data: data,
  };
};

const fetchDelDataError = (data) => {
  return {
    type: FETCH_DELETE_FAILURE,
    data: data,
  };
};

const fetchAddDataReq = (data) => {
  return {
    type: FETCH_ADD_REQUEST,
    data: data,
  };
};

const fetchAddDataSuccess = (data) => {
  return {
    type: FETCH_ADD_SUCCESS,
    data: data,
  };
};

const fetchAddDataError = (data) => {
  return {
    type: FETCH_ADD_FAILURE,
    data: data,
  };
};

export const delBankAccFunc = (accountId) => {
  return (dispatch) => {
    dispatch(fetchDelDataReq());
    APIclient
      /*eslint no-process-env: "off"*/
      .postData(
        `/api/v1/org/${GetBaseAuthObject().orgId}/bank_account/${accountId}/delete`,
        GetFormData({ org_id: GetBaseAuthObject().orgId })
      )
      .then((data) => {
        if (data.data.status > 200) {
          dispatch(fetchDelDataError(data.data));
        } else {
          dispatch(fetchDelDataSuccess(data.data));
        }
      })
      .catch((err) => {
        dispatch(fetchDelDataError(err));
      });
  };
};

export const addBankAccFunc = (data, trackActions = false) => {
  return (dispatch) => {
    dispatch(fetchAddDataReq());
    APIclient
      /*eslint no-process-env: "off"*/
      .postData(
        `/api/v1/org/${GetBaseAuthObject().orgId}/bank_account`,
        GetFormData({ user_id: GetBaseAuthObject().userId, ...data })
      )
      .then((data) => {
        if (data.data.status > 200) {
          dispatch(fetchAddDataError(data.data));
          trackActions && trackEvent("add bank account fail");
        } else {
          dispatch(fetchAddDataSuccess(data.data));
          trackActions && trackEvent("add bank account success");
        }
      })
      .catch((err) => {
        dispatch(fetchAddDataError(err));
        trackActions && trackEvent("add bank account fail");
      });
  };
};

export const fetchBankFunc = (accountId) => {
  return (dispatch) => {
    dispatch(fetchDataReq());
    APIclient
      /*eslint no-process-env: "off"*/
      .getData(`/api/v1/org/${GetBaseAuthObject().orgId}/bank_account/?user_id=${accountId}&limit=5&pg=0`)
      .then((data) => {
        if (data.data.status > 200) {
          dispatch(fetchDataError(data.data));
        } else {
          dispatch(fetchDataSuccess(data.data));
        }
      })
      .catch((err) => {
        dispatch(fetchDataError(err));
      });
  };
};
