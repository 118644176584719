import React from "react";
import "./styles.scss";
import { Button } from "antd";

interface ButtonProps {
  loading?: boolean;
  text: string | React.ReactNode;
  handleKeyPress?: (event) => void;
  id?: string;
  testID?: string;
}

export const AuthButton = ({
  text,
  loading = false,
  handleKeyPress = () => {},
  id,
  testID = "login-button",
}: ButtonProps): React.ReactElement => {
  return (
    <Button
      htmlType="submit"
      id={id}
      data-testid={testID}
      className="auth-common-button"
      loading={loading}
      onClick={handleKeyPress}
    >
      {!loading && text}
    </Button>
  );
};
