import { FETCH_EDIT_REQUEST, FETCH_EDIT_SUCCESS, FETCH_EDIT_FAILURE } from "Redux/Actions/Category/edit";
import { userInitialState } from "../../InitialState";

export const categoryEditReducer = (state = userInitialState, action) => {
  switch (action.type) {
    case FETCH_EDIT_REQUEST:
      return {
        ...state,
        loading: true,
        editData: action.data,
        error: false,
      };
    case FETCH_EDIT_SUCCESS:
      return {
        ...state,
        editData: action.data,
        loading: false,
        error: false,
      };
    case FETCH_EDIT_FAILURE:
      return {
        ...state,
        loading: false,
        editData: action.data,
        error: true,
      };
    default:
      return state;
  }
};
