import { useSelector } from "react-redux";

import { TEAM_TYPE } from "constants/Team.constant";

const useDefaultTeam = () => {
  const teamsData = useSelector((state) => state.teamList?.data?.payload?.teams);
  return (teamsData?.filter((team) => team.team_type === TEAM_TYPE.ORGANISATION) || teamsData)?.[0];
};

export default useDefaultTeam;
