import * as React from "react";
import { Button } from "antd";
import { CardNotification } from "Views/Card/Components/Notification";
import Loader, { LoaderTypes } from "Views/Card/Components/Loader";
import { CARD_DETAILS_FIELDS_MAP, useCardDetailsEditContext } from "Views/Card/Edit/index.context";
import { generatePayload } from "Views/Card/Edit/ActionBtns/PayloadGenerator";
import { trackEditActions } from "Views/Card/Edit/ActionBtns/TrackEditActions";
import { trackEvent } from "utility/analytics";
import { EditCardEventNames } from "Views/Card/Analytics";
import { updateCardDetails } from "Redux/DataCalls/Cards.api.js";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import { useIsManagerOrAdmin } from "customHooks/Card";
import { CARD_LIMIT_TYPES } from "Views/Card/types";

import "Views/Card/Edit/ActionBtns/index.scss";
import { useSelector } from "react-redux";

export interface ICardDetailsEditActionBtnsProps {
  onCancel: () => void;
  onSuccessfulEdit: (updatedData) => void;
  sendApiPayload?: boolean;
}

export default function CardDetailsEditActionBtns({
  onCancel,
  onSuccessfulEdit,
  sendApiPayload,
}: ICardDetailsEditActionBtnsProps) {
  const { cardDetailsEditState, cardData, setCardDetailsEditState } = useCardDetailsEditContext();
  const { isCardRequestDetails, cardHolder } = cardData || {};
  const [editCardDetailsInProgress, setEditCardDetailsInProgress] = React.useState(false);
  const userName = useSelector((state: any) => state?.userInfoReducer?.data?.payload?.user?.name || "");

  const { isCategoryLockAddedFromUrl } = cardDetailsEditState;

  const trackCardEditStatus = (isSuccess) => {
    trackEvent(EditCardEventNames.CARD_EDIT_PROCESSED, {
      card_process_success: isSuccess,
      card_category_lock_edit_source: isCategoryLockAddedFromUrl ? "email" : "edit page",
    });
  };

  const handleError = (message) => {
    CardNotification.error({
      message: message || "Something went wrong! Please try again later.",
      duration: 3,
    });
    !isCardRequestDetails && trackCardEditStatus(false);
  };

  const cardDoesNotBelongsToUser = cardHolder?.toLowerCase() !== userName?.toLowerCase();

  const getToastMessage = (statusMessage = "") => {
    let toastMessage = "";
    if (isCardRequestDetails) {
      toastMessage = statusMessage || "";
    } else if (isAdminOrManager && cardDoesNotBelongsToUser) {
      toastMessage = "Card Settings saved. Team Member will be notified.";
    } else {
      toastMessage = "Successfully saved card settings.";
    }
    return toastMessage;
  };

  const [isAdmin, isManager] = useIsManagerOrAdmin();
  const isAdminOrManager = isAdmin || isManager;
  const onSave = () => {
    setEditCardDetailsInProgress(true);
    const payload = generatePayload(cardDetailsEditState, isCardRequestDetails);
    if (sendApiPayload) {
      setEditCardDetailsInProgress(false);
      onSuccessfulEdit(payload);
      return;
    }
    updateCardDetails(cardDetailsEditState.id, payload, isCardRequestDetails)
      .then(({ data }) => {
        const { status_message } = data?.payload || {};

        if (data?.status === HTTP_STATUS_CODE.OK) {
          CardNotification.success({
            message: getToastMessage(status_message),
            duration: 3,
          });
          onSuccessfulEdit(isCardRequestDetails ? data?.payload : data?.payload?.card);
          !isCardRequestDetails && trackCardEditStatus(true);
          isCategoryLockAddedFromUrl &&
            setCardDetailsEditState((prevState) => ({
              ...prevState,
              [CARD_DETAILS_FIELDS_MAP.IS_CATEGORY_LOCK_ADDED_FROM_URL]: false,
            }));
        } else {
          handleError(status_message);
        }
      })
      .catch((err) => {
        const { status_message = "" } = err?.response?.data?.payload || {};
        handleError(status_message);
      })
      .finally(() => setEditCardDetailsInProgress(false));
    !isCardRequestDetails && trackEditActions(cardDetailsEditState, cardData);
  };

  const checkIfFormIsValid = () => {
    const { spendingLimitType, spendingLimit, letMyManagerChooseLimit, title } = cardDetailsEditState;
    return (spendingLimitType === CARD_LIMIT_TYPES.unlimited || spendingLimit || letMyManagerChooseLimit) && title;
  };

  return (
    <div className="card-edit-actions__container">
      <Button className="card-edit-actions__btn card-edit-actions__btn--transparent" onClick={() => onCancel()}>
        Cancel
      </Button>
      <Button
        disabled={!checkIfFormIsValid()}
        className={`card-edit-actions__btn card-edit-actions__btn--primary ${
          editCardDetailsInProgress ? "is-loading" : ""
        }`}
        onClick={onSave}
      >
        {editCardDetailsInProgress ? <Loader type={LoaderTypes.white} /> : "Save Changes"}
      </Button>
    </div>
  );
}
