import { StateType } from "Views/Card/CreateOrRequest/Utilities/UseCreateOrRequestState";
import { CARD_COLOR_AND_NAME_MAP } from "constants/Card/CardColors.constants";
import { GetOrgId, GetUserId } from "utility";
import { getSpendingLocksPayload } from "utility/Card";
import { DEFAULT_LIMIT_VALUES } from "constants/Team.constant";
import { trackEvent } from "utility/analytics";
import { CardCreationEventNames } from "Views/Card/Analytics";
import { CARD_LIMIT_TYPES, CARD_TYPE, SPENDING_LOCK_TYPES } from "Views/Card/types";

const getLimitsData = (
  cardLimitType: CARD_LIMIT_TYPES,
  cardLimit: string,
  adminOrManagerInSelectedTeam: boolean,
  letMyManagerChooseLimit: boolean
) => {
  if (adminOrManagerInSelectedTeam || !letMyManagerChooseLimit) {
    const defaultLimitsToSend = {
      monthly_limit: DEFAULT_LIMIT_VALUES.REVERT_THE_LIMIT,
      yearly_limit: DEFAULT_LIMIT_VALUES.REVERT_THE_LIMIT,
      total_limit: DEFAULT_LIMIT_VALUES.REVERT_THE_LIMIT,
    };
    if (cardLimitType !== CARD_LIMIT_TYPES.unlimited && cardLimit) {
      defaultLimitsToSend[cardLimitType] = cardLimit;
    }
    return defaultLimitsToSend;
  }
  return {};
};

export const trackCardCreation = (state: StateType, cardType: CARD_TYPE) => {
  const { cardLimitType, expenseCategoryId, adminOrManagerInSelectedTeam, spendingLockType, spendingLocks } = state;
  trackEvent(CardCreationEventNames.CARD_CREATION_BUTTON_CLICKED, {
    is_virtual_card: cardType === CARD_TYPE.VIRTUAL,
    card_expense_category_used: !!expenseCategoryId,
    card_spending_lock_used: spendingLockType !== SPENDING_LOCK_TYPES.NONE,
    card_spending_lock_type: spendingLockType,
    card_spending_locks: spendingLocks?.map((spendingLock) => spendingLock?.name)?.join(", "),
    card_spend_limit_period: cardLimitType,
    is_card_creation: cardType === CARD_TYPE.VIRTUAL && adminOrManagerInSelectedTeam,
    card_creation_event_action: "Confirm",
  });
};

export const generateCreateOrRequestCardPayload = (state: StateType, cardType: CARD_TYPE, userName: string) => {
  const {
    adminOrManagerInSelectedTeam,
    selectedTeamId,
    cardTitle,
    cardColor,
    addressLine1,
    addressLine2,
    country,
    pincode,
    expenseCategoryId,
    cardLimitType,
    cardLimit,
    cardholderId,
    noteToManager,
    selectedApproverId,
    letMyManagerChooseLimit,
    tags,
    spendingLocks,
    spendingLockType,
  } = state;
  const commonPayload = {
    organisation_id: GetOrgId(),
    team_id: selectedTeamId,
    user_id: adminOrManagerInSelectedTeam && cardType === CARD_TYPE.VIRTUAL ? cardholderId : GetUserId(),
    card_name: cardTitle,
    name: cardTitle,
    cardholder_name: userName,
    expense_category_id: expenseCategoryId || undefined,
    ...getSpendingLocksPayload(spendingLockType, spendingLocks),
    ...getLimitsData(cardLimitType, cardLimit, adminOrManagerInSelectedTeam, letMyManagerChooseLimit),
    manager_sets_limit: letMyManagerChooseLimit ? 1 : 0,
    tags: tags,
  };
  let payloadToSend = {};
  if (cardType === CARD_TYPE.PHYSICAL) {
    payloadToSend = {
      ...commonPayload,
      deliver_to_name: userName,
      card_billing_address: [addressLine1, addressLine2 || ""]?.filter((address) => address)?.join(", ") || "",
      country,
      zip_code: pincode,
      is_physical: cardType === CARD_TYPE.PHYSICAL ? 1 : 0,
    };
  } else {
    payloadToSend = {
      ...commonPayload,
      color_name: CARD_COLOR_AND_NAME_MAP[cardColor],
      color: cardColor,
    };
  }
  if (!adminOrManagerInSelectedTeam) {
    payloadToSend = {
      ...payloadToSend,
      is_physical: cardType === CARD_TYPE.PHYSICAL ? 1 : 0,
      note_to_manager: noteToManager,
      approver_id: selectedApproverId,
    };
  }
  return payloadToSend;
};
