import * as React from "react";

import { ALIGN_ACTION_ITEM, IActionItem } from "Modules/DS/DataTable";

const useAlignActions = (listData: IActionItem[]) => {
  const alignToLeft: JSX.Element[] = [];

  const alignToRight: JSX.Element[] = [];

  listData.forEach((actionItem: IActionItem) => {
    const action =
      typeof actionItem.component !== "function" ? (
        <React.Fragment key={actionItem.id}>{actionItem.component}</React.Fragment>
      ) : (
        <actionItem.component key={actionItem.id} />
      );
    switch (actionItem.align) {
      case ALIGN_ACTION_ITEM.RIGHT:
        alignToRight.push(action);
        break;
      case ALIGN_ACTION_ITEM.LEFT:
      default:
        alignToLeft.push(action);
        break;
    }
  });

  return [alignToLeft, alignToRight];
};

export default useAlignActions;
