import * as React from "react";
import { Radio, Select as AntdSelect, Typography, Button } from "antd";
import { GetActiveCategories } from "Redux/DataCalls/Categories.api";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import Select from "Views/Card/Components/Select";
import { CATEGORY_CONSTANT } from "constants/Category.constant";

import "Views/Card/Components/ExpenseCategory/index.scss";

const { Option } = AntdSelect;
const { Text } = Typography;

interface ExpenseCategoryProps {
  selectExpenseCategoryID: number;
  onSelect: (id: number, name: string) => void;
}

export const ExpenseCategory = ({ selectExpenseCategoryID, onSelect }: ExpenseCategoryProps) => {
  const [expenseCategoryData, setExpenseCategoryData] = React.useState<{ label: string; value: number }[]>([]);
  const [expenseCategoryLoading, setExpenseCategoryLoading] = React.useState<boolean>(false);
  const [expenseCategoryErrorMessage, setExpenseCategoryErrorMessage] = React.useState<string>("");

  const fetchExpenseCategories = async () => {
    setExpenseCategoryLoading(true);
    try {
      const response = await GetActiveCategories();
      if (response?.status === HTTP_STATUS_CODE.OK) {
        const { categories = [] } = response?.data?.payload || {};

        let sortedAndFormattedCategories = categories
          ?.filter((category) => category?.categoryName && category?.id)
          ?.filter((i) => i.categoryName !== CATEGORY_CONSTANT.DEFAULT && i.categoryName !== CATEGORY_CONSTANT.REFUND)
          ?.sort((a, b) => {
            let nameA = a?.categoryName?.toLowerCase();
            let nameB = b?.categoryName?.toLowerCase();
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
          })
          ?.map((category) => ({
            label: category?.categoryName,
            value: category?.id,
          }));

        let sortedDefaultRefund = categories
          ?.filter((category) => category?.categoryName && category?.id)
          ?.filter((i) => i.categoryName === CATEGORY_CONSTANT.DEFAULT || i.categoryName === CATEGORY_CONSTANT.REFUND)
          ?.sort((a, b) => {
            let nameA = a?.categoryName?.toLowerCase();
            let nameB = b?.categoryName?.toLowerCase();
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
          })
          ?.map((category) => ({
            label: category?.categoryName,
            value: category?.id,
          }));

        sortedAndFormattedCategories = [...sortedDefaultRefund, ...sortedAndFormattedCategories];
        setExpenseCategoryData(sortedAndFormattedCategories);
      } else {
        const { status_message = "Error fetching expense categories" } = response?.data?.payload || {};
        setExpenseCategoryErrorMessage(status_message);
      }
      setExpenseCategoryLoading(false);
    } catch (error) {}
  };

  const onExpenseCategoryClear = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event?.stopPropagation();
    onSelect(undefined, "");
  };

  React.useEffect(() => {
    fetchExpenseCategories();
  }, []);

  const getDropDown = () => {
    if (expenseCategoryErrorMessage) return expenseCategoryErrorMessage;
    return expenseCategoryData?.map((expenseCategory) => (
      <Option
        key={expenseCategory.value}
        value={expenseCategory.value}
        label={expenseCategory.label}
        id={expenseCategory.label}
      >
        <Radio checked={selectExpenseCategoryID === expenseCategory.value} />
        <Text>{expenseCategory?.label}</Text>
        {selectExpenseCategoryID === expenseCategory.value && (
          <Button onClick={onExpenseCategoryClear} className="expense-category__clear-btn">
            Clear
          </Button>
        )}
      </Option>
    ));
  };

  return (
    <Select
      placeholder="Select Category"
      dropdownClassName="expense-category__dropdown"
      listHeight={166}
      showSearch
      loading={expenseCategoryLoading}
      filterOption={(input, option) => option?.label?.toString()?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0}
      value={expenseCategoryLoading ? undefined : selectExpenseCategoryID}
      onSelect={(id, option) => {
        onSelect(id, option?.label?.toString() || "");
      }}
      getPopupContainer={(triggerNode) => triggerNode.parentElement}
      optionLabelProp="label"
      data-testid="expense-category"
    >
      {getDropDown()}
    </Select>
  );
};
