import React from "react";
import { Form, Input, Popover } from "antd";
import { useSelector } from "react-redux";
import { ChangePasswordFormProps, REGREX_EXPRESSION } from "./types";
import "./styles.scss";
import { AuthButton } from "../Components/AuthButton";
import PasswordCriteria from "./PasswordCriteria";

const NewPassword = (): React.ReactElement => {
  return (
    <>
      <Popover
        trigger="click"
        content={<PasswordCriteria />}
        overlayClassName={"new-password-criteria"}
        placement="left"
      >
        <Form.Item
          name="password"
          label="New Password"
          className="login-password-field"
          rules={[
            {
              required: true,
              message: "This field cannot be empty",
            },
            {
              pattern: REGREX_EXPRESSION,
              message:
                "Password must be at least 8 letters long with at least one lowercase letter, one uppercase letter, one number and one special character.",
            },
          ]}
          validateTrigger="onChange"
        >
          <Input.Password placeholder="Enter new password" autoComplete="off" />
        </Form.Item>
      </Popover>
      <Form.Item
        name="new_password"
        label="Confirm Password"
        dependencies={["password"]}
        className="login-password-field"
        rules={[
          { required: true, message: "This field cannot be empty" },
          ({ getFieldValue }) => ({
            validator(rule, value) {
              if (!value || getFieldValue("password") === value) {
                return Promise.resolve();
              }
              return Promise.reject("Entered passwords do not match");
            },
          }),
        ]}
        validateTrigger="onChange"
      >
        <Input.Password
          placeholder={"Confirm new password"}
          autoComplete="off"
        />
      </Form.Item>
    </>
  );
};

const ChangePasswordForm = ({
  onFinish,
}: ChangePasswordFormProps): React.ReactElement => {
  const { loading } = useSelector((state: any) => state.verifyForgotPassword);

  return (
    <section className="change-password">
      <Form name="change-password" onFinish={onFinish}>
        <NewPassword />
        <AuthButton
          text="Change Password"
          testID="change-password"
          loading={loading}
        />
      </Form>
    </section>
  );
};

export default ChangePasswordForm;
