import { ICardDetails, CARD_STATUS, CARD_REQUEST_STATUS } from "Views/Card/types";

export interface ICard {
  createCardData: any;
  showCardownerFilter: boolean;
  bulkChangeStatusData: any; // Create type later
  isAdmin: boolean;
  userData: any; // Create Type later
  bulkChangeStatus: (key: string, status?: string, adminManagerOnly?: boolean) => void;
}

export interface IFilter {
  search_filters: any;
  team_id: any;
  ordering: any;
}

export interface IToaster {
  message: string;
  status: string;
  isPrimary: boolean;
  visible: boolean;
}

export interface CardTypeObject {
  key: string;
  title: any; // Need to change react.element
}

export enum CARD_LIST_TABS {
  CARD_LIST = "All Cards",
  RECEIVED_CARD_REQUEST = "Received Card Requests",
  SENT_CARD_REQUEST = "Sent Card Requests",
  CARD_TRXNS = "Card Transactions",
}

export enum CARD_LIST_TABS_DATA_KEYS {
  CARD_LIST = "CardsList",
  RECEIVED_CARD_REQUEST = "ReceivedCardRequests",
  SENT_CARD_REQUEST = "SentCardRequests",
}

export interface IApprovalActionProps {
  record: ICardDetails;
}

export interface IRejectRequestProps {
  show: boolean;
  onConfirm: (reasong: string) => void;
  onReview: () => void;
  close: () => void;
  loading: boolean;
}
export interface ICardListContext {
  cardListLoading: boolean;
  bulkChangeStatusOrCreateCardDataInProgress: boolean;
  canAddCards: boolean;
  selectedRows: any[];
  handleRowClick: (cardDetails) => void;
  setSelectedRows: React.Dispatch<React.SetStateAction<any[]>>;
  totalCount: number;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  getCardListData: (page: number, view?: string | string[]) => void;
  paginatorStartIndex: number;
  isNotFiltered: (filterData) => boolean;
  filters: IFilter;
  tabs: (CARD_LIST_TABS | CardTypeObject)[];
  cardListActiveTab: CARD_LIST_TABS;
  changeCardListActiveTab: (tab: CARD_LIST_TABS) => void;
  cardsListTabsData: {
    [CARD_LIST_TABS.CARD_LIST]: ICardDetails[];
    [CARD_LIST_TABS.RECEIVED_CARD_REQUEST]: ICardDetails[];
    [CARD_LIST_TABS.SENT_CARD_REQUEST]: ICardDetails[];
  };
  selectedRowId: string;
  cardDetails: CardDetailsType;
  setCardDetails: React.Dispatch<React.SetStateAction<CardDetailsType>>;
  setShowCardDeleteModal: (cardId: string) => void;
  deleteInProgressCards: string[];
  setShowCardActivationModal: (cardId: string) => void;
  activationInProgressCards: string[];
  enableOrDisableCard: (
    cardStatus: CARD_STATUS | CARD_REQUEST_STATUS,
    cardId: string,
    canUserEditCards: boolean
  ) => void;
  enableOrDisableInProgressCards: string[];
}

export type CardDetailsType = null | {
  drawerType: CARD_DRAWER_TYPES;
  data: ICardDetails;
  activeTab: CARD_DETAILS_TABS | null;
};

export enum CARD_DRAWER_TYPES {
  EDIT = "edit",
  DETAILS = "details",
}

export enum CARD_DETAILS_TABS {
  DETAILS = "Details",
  SETTINGS = "Settings",
  HISTORY = "History",
}

export enum URL_PARAMS_KEYS {
  CARD_LIST_ACTIVE_TAB = "cardListActiveTab",
  DEFAULT_CARD_HOLDER = "defaultCardholderFilter",
  CARD_ID = "card_id",
  CARD_DRAWER_TYPE = "cardDrawerType",
}

export enum CARD_LIST_ACTIVE_TAB_URL_PARAM_VALUES {
  SENT_CARD_REQUESTS = "sentCardRequests",
  RECEIVED_CARD_REQUESTS = "receivedCardRequests",
  CARD_TRXNS = "cardTransactions",
}
