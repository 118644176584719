import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { dateTimeFormatter } from "utility";
import { Typography, Steps } from "antd";
import ErrorBox from "Modules/DS/Molecules/ErrorBox";

import styles from "./TabStatus.module.scss";
import { STATUS } from "../const";
import "./TabStatus.scss";

const TabStatus = ({ data, paymentScheduleTime, isExtracting }) => {
  const { Text } = Typography,
    { Step } = Steps;

  const statusJourney = data?.statusJourney || [];

  const currentStep = statusJourney?.filter((item) => item.isFinished),
    formattedDate =
      paymentScheduleTime && paymentScheduleTime !== "-" ? dateTimeFormatter(paymentScheduleTime).date : "-";

  const renderSchedule = () => (
    <div className={styles.schedule}>
      <Text className={classNames(styles.text, styles.small, styles.space)}>Payment is scheduled on</Text>
      <Text className={classNames(styles.text, styles.normal)}>{formattedDate}</Text>
    </div>
  );

  return (
    <div className="tab-status-pending-detail">
      {!isExtracting && renderSchedule()}
      {statusJourney?.length && (
        <Steps progressDot current={currentStep.length - 1} direction="vertical">
          {statusJourney.map((item, index) => (
            <Step
              className={item.isSelfApprovalPrevention && "has-error"}
              key={index}
              description={
                <>
                  <p>
                    {item.timestamp &&
                      `${dateTimeFormatter(item.timestamp).date} ${dateTimeFormatter(item.timestamp).time}`}
                  </p>
                  {Boolean(item.notes) && (
                    <div className="audit-trail-notes">
                      {data.status === STATUS.rejected && <h4>Reason for Declining:</h4>}
                      <p>{item.notes}</p>
                    </div>
                  )}
                  {item.isSelfApprovalPrevention && (
                    <ErrorBox message="Approval will be redirected to Any Admin as self-approval is not allowed." />
                  )}
                </>
              }
              title={`${item.label}${item.actionUser ? ` by ${item.actionUser}` : ""}`}
            />
          ))}
        </Steps>
      )}
    </div>
  );
};

TabStatus.propTypes = { data: PropTypes.object, paymentScheduleTime: PropTypes.string, isExtracting: PropTypes.bool };

TabStatus.defaultProps = { data: {}, isExtracting: false, paymentScheduleTime: "" };

export default TabStatus;
