import React, { useState } from "react";
import { Popover } from "antd";
import Content from "./Content";
import { useClickOutside } from "customHooks/useClickOutside";
import ModalCategoryImport from "Views/Categories/Import";
import { AddOutline, Button } from "@spenmo/splice";
import { trackEvent } from "utility/analytics";
import { IAddCategoryProps } from "../types";
import { categoryEvents } from "../Analytics";

import "./styles.scss";

const AddCategory = ({
  onnAddNewCategory,
  showImportModal,
  setShowImportModal,
  hasValidToken,
  partnerName,
}: IAddCategoryProps) => {
  const [visible, setVisible] = useState(false);

  const closePopover = () => setVisible(false);
  const togglePopover = () => setVisible(!visible);
  const [triggerRef] = useClickOutside(closePopover);

  return (
    <>
      <div ref={triggerRef}>
        <Popover
          overlayClassName="add-category__popover"
          getPopupContainer={(triggerNode) => triggerNode}
          content={
            <Content
              hasValidToken={hasValidToken}
              onImportCategory={() => {
                closePopover();
                setShowImportModal(true);
                trackEvent(categoryEvents.IMPORT_FROM_XERO);
              }}
              onnAddNewCategory={() => {
                closePopover();
                onnAddNewCategory();
              }}
              partnerName={partnerName}
            />
          }
          visible={visible}
          placement="bottomRight"
        >
          <Button variant="primary" icon={AddOutline} onClick={togglePopover}>
            New
          </Button>
        </Popover>
      </div>
      <ModalCategoryImport visible={showImportModal} onClose={() => setShowImportModal(false)} />
    </>
  );
};

export default AddCategory;
