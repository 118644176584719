import { useEffect } from "react";

const useScrollBlocker = (disabled: boolean) => {
  useEffect(() => {
    const disableScroll = () => {
      // Get the current page scroll position
      let scrollTop = window.scrollY || document.documentElement.scrollTop;
      let scrollLeft = window.scrollX || document.documentElement.scrollLeft;

      // if any scroll is attempted, set this to the previous value
      window.onscroll = () => window.scrollTo(scrollLeft, scrollTop);
    };

    disabled && disableScroll();

    return () => {
      window.onscroll = () => {
        /* enables scrolling again */
      };
    };
  }, [disabled]);
};

export default useScrollBlocker;
