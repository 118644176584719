import React, { forwardRef, useState, useMemo, useEffect } from "react";
import { Upload } from "antd";
import { RcCustomRequestOptions, UploadFile } from "antd/lib/upload/interface";
import { ControllerRenderProps, useFormContext } from "react-hook-form";
import { Button, DeleteFilled, Typography } from "@spenmo/splice";
import { Document, Page } from "react-pdf";

import {
  ADDITIONAL_ATTACHMENT_EXT_FORMATTED,
  ADDITIONAL_ATTACHMENT_ALLOWED_FILE_EXT,
  MAX_FILE_SIZE_ALLOWED,
} from "Views/Bills/V2/constants";

import {
  getAcceptedFiles,
  getUploadedFiles,
  isPDF,
  transformFile,
} from "Views/Bills/V2/utilities";

import { AttachmentProps } from "Views/Bills/V2/components/FormGenerator/type";
import { uploadInvoice } from "assets/img";
import styles from "./Attachment.module.scss";

interface FileItemProps {
  file: {
    status?: string;
  } & Omit<UploadFile, "status">;
  onDelete(): void;
}

const FileItem = (props: FileItemProps) => {
  const { file, onDelete } = props;
  const { name, size, url, status, type } = file;

  const handleDeleteFile = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    onDelete();
  };

  const isDone = status === "done";
  const sizeMB = (size / 1000 / 1000).toFixed(3);
  const isPDF = type === "application/pdf";

  const handleClickPreview = (e) => {
    e.preventDefault();
    e.stopPropagation();

    window.open(url);
  };

  const renderThumbnail = () => {
    if (isPDF) {
      return (
        <Document file={url}>
          <Page pageNumber={1} />
        </Document>
      );
    }

    return <img src={url} alt={name} width={143} height={174} />;
  };

  return (
    <div className={styles.fileItem}>
      <a
        className={styles.fileImg}
        rel="noopener noreferrer"
        href={url}
        target="_blank"
        onClick={handleClickPreview}
      >
        {isDone ? (
          renderThumbnail()
        ) : (
          <img src={uploadInvoice} alt={name} width={143} height={174} />
        )}
      </a>
      <Typography
        className={styles.fileName}
        variant="body-content"
        size="caption-m"
        tag="p"
        weight={600}
      >
        {isDone ? name : "Uploading..."}
      </Typography>
      {isDone && (
        <>
          <div className={styles.fileInfo}>
            <Typography variant="body-content" size="caption-m">
              {sizeMB} MB
            </Typography>
          </div>
          <div
            className={styles.deleteIcon}
            tabIndex={0}
            aria-label="button"
            onClick={handleDeleteFile}
          >
            <DeleteFilled size="16" iconColor="#C2190A" />
          </div>
        </>
      )}
    </div>
  );
};

const Attachment = forwardRef(
  (
    props: Partial<AttachmentProps & ControllerRenderProps>,
    ref: React.RefObject<HTMLDivElement>,
  ) => {
    const { value, onChange, attachments } = props;
    const { setValue, watch } = useFormContext();
    const deletedAttachmentIDs = watch("deletedAttachmentIDs", []);

    // fileList is to hold all fileList (uploading, done files)
    // probably need to use useEffect instead
    // but it will set the fileList twice in that case
    const defaultFileList: UploadFile[] = useMemo(() => {
      return (
        value?.map((file, index) => {
          const { uid, name, type, size } = file;

          return transformFile({
            uid: uid || `default-file-${index + 1}`,
            name,
            type,
            size,
            status: "done",
            originFileObj: file,
          });
        }) || []
      );
    }, [value]);

    const [fileList, setFileList] = useState(defaultFileList);

    useEffect(() => {
      setValue("uploadedAttachments", attachments);
    }, [attachments, setValue]);

    const uploadedAttachments = useMemo(() => {
      return (
        attachments
          ?.filter((item) => !deletedAttachmentIDs.includes(item.id))
          ?.map((item) => {
            const { id, url, filename, size } = item;
            let type = isPDF(url) ? "application/pdf" : "image/png";

            return {
              uid: id,
              url,
              name: filename,
              status: "done",
              size,
              type,
            };
          }) || []
      );
    }, [attachments, deletedAttachmentIDs]);

    const validateFile = (file: any) => {
      const fileExtension = file.name.split(".").pop().toLowerCase();
      const extensionValid =
        ADDITIONAL_ATTACHMENT_ALLOWED_FILE_EXT.includes(fileExtension);
      const sizeValid = file.size <= (4 / 10) * MAX_FILE_SIZE_ALLOWED; // 4 MB
      const isFileValid = extensionValid && sizeValid;

      return isFileValid;
    };

    const handleUploadRequest = (options: RcCustomRequestOptions) => {
      const { file, onSuccess } = options;
      onSuccess(undefined, file);
    };

    const handleUpload = (value) => {
      const { fileList } = value;

      // only setFileList on uploading / done
      // error should not be included
      const acceptedFiles = getAcceptedFiles(fileList).map(transformFile);
      setFileList(acceptedFiles);

      onChange(getUploadedFiles(fileList));
    };

    const handleDeleteUploadedFile = (fileID: string) => {
      setValue("deletedAttachmentIDs", [fileID, ...deletedAttachmentIDs]);
    };

    const handleDeleteFile = (fileIndex) => {
      setFileList((fileList) => {
        fileList.splice(fileIndex, 1);

        onChange(getUploadedFiles(fileList));
        return [...fileList];
      });
    };

    return (
      <Upload.Dragger
        {...props}
        listType="picture-card"
        className={styles.attachment}
        showUploadList={false}
        beforeUpload={validateFile}
        onChange={handleUpload}
        customRequest={handleUploadRequest}
        accept={ADDITIONAL_ATTACHMENT_EXT_FORMATTED}
        multiple
        fileList={fileList}
      >
        {uploadedAttachments.length === 0 && fileList.length === 0 ? (
          <>
            <div>
              <img src={uploadInvoice} alt="upload" width={120} height={120} />
            </div>
            <Typography
              className={styles.desc}
              variant="body-content"
              size="s"
              tag="div"
              weight={600}
            >
              Drop your files here, or{" "}
              <span className={styles.highlight}>Choose Files</span>
            </Typography>
            <Typography
              className={styles.caption}
              variant="body-content"
              size="caption-m"
              tag="div"
            >
              Upload any supporting documents here. We only accept PDF and PNG
              format. (Max. 4MB)
            </Typography>
          </>
        ) : (
          <div className={styles.fileContainer}>
            <div className={styles.fileList}>
              {uploadedAttachments.map((file, fileIndex) => {
                return (
                  <FileItem
                    key={fileIndex}
                    file={file}
                    onDelete={() => handleDeleteUploadedFile(file.uid)}
                  />
                );
              })}
              {fileList.map((file, fileIndex) => {
                return (
                  <FileItem
                    key={fileIndex}
                    file={file}
                    onDelete={() => handleDeleteFile(fileIndex)}
                  />
                );
              })}
            </div>
            <Button
              className={styles.addMoreFiles}
              variant="primary"
              size="s"
              type="button"
            >
              Add More Files
            </Button>
          </div>
        )}
      </Upload.Dragger>
    );
  },
);

export default Attachment;
