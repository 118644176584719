import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { InView } from "react-intersection-observer";
import { EmptyState, Loader } from "@spenmo/splice";
import { useAppSelector } from "hook";
import DataTable, { DATA_TABLE_PADDING, DATA_TABLE_SIZE_TYPES, ITableProps, Table } from "Modules/DS/DataTable";
import { SUBSCRIPTION_PATH } from "Route/Subscription/path";
import { ISubscription } from "Views/SubscriptionManagement/@types";
import Columns from "./Columns";

import styles from "./index.module.scss";

const List = ({
  data,
  loading,
  onReachEnd,
  loadingMoreData,
}: {
  data: ISubscription[];
  loading: boolean;
  onReachEnd: () => void;
  loadingMoreData: boolean;
}) => {
  const h = useHistory();
  const location = useLocation();
  const loggedInUserDetails = useAppSelector((state: any) => state?.userInfoReducer);
  const userName = loggedInUserDetails?.data?.payload?.user?.name || "";

  const handleRowAction = (item) => {
    return h.push(SUBSCRIPTION_PATH.SUBSCRIPTON_DETAILS.replace(":id", item.id), { filters: location.search });
  };

  // Configuration for the DataTable
  const table: ITableProps<ISubscription> = {
    loading: false,
    rowAction: loading ? null : handleRowAction,
    dataSource: data,
    paginationEnabled: false,
    customEmptyState: (
      <div className={styles.emptyState}>
        <EmptyState
          headline="Uh-ohh, no subscriptions to show"
          description={`Hey ${userName}, there are no subscriptions to show for the filters you have applied. Trying clearing the filters to see some subscriptions.`}
        />
      </div>
    ),
  };

  return (
    <DataTable size={DATA_TABLE_SIZE_TYPES.LARGE} padding={DATA_TABLE_PADDING.NONE}>
      <Table<ISubscription> className={styles.list} {...table}>
        {Columns({ loading })}
      </Table>
      {loadingMoreData ? <Loader.Spinner size="l" variant="brand" className={styles.spinner} /> : null}

      <InView
        onChange={(_, e) => {
          if (e.isIntersecting) {
            onReachEnd();
          }
        }}
      />
    </DataTable>
  );
};

export default List;
