export enum TEAM_ACTION {
  DELETE = "delete",
  RENAME = "rename",
}

export enum TEAM_ACTION_STATUS {
  LOADING = "loading",
  INITIATED = "initiated",
  COMPLETED = "completed",
}

export const Actions = [
  {
    label: "Delete Budget",
    value: TEAM_ACTION.DELETE,
  },
  {
    label: "Rename Budget",
    value: TEAM_ACTION.RENAME,
  },
];

export enum TABS_KEYS {
  ALL = "all",
  FUND_TRANSFERS = "fundTransfers",
  SCHEDULED = "scheduled",
}

export enum TABS_TITLES {
  ALL_BUDGETS = "All Budgets",
  FUND_TRANSFERS = "Fund Transfers",
  SCHEDULED_TRANSFERS = "Scheduled Transfers",
}

export const TABS = [
  {
    tabKey: TABS_KEYS.ALL,
    title: TABS_TITLES.ALL_BUDGETS,
  },
  {
    tabKey: TABS_KEYS.FUND_TRANSFERS,
    title: TABS_TITLES.FUND_TRANSFERS,
  },
  {
    tabKey: TABS_KEYS.SCHEDULED,
    title: TABS_TITLES.SCHEDULED_TRANSFERS,
  },
];

export enum FUND_TRANSFERS_SUB_TABS {
  SENT_FUNDS = "Sent Funds",
  REQUESTED_FUNDS = "Requested Funds",
}

export enum FUND_TRANSFER_FILTERS_NAMES {
  CREATED_DATE = "createdDate",
  AMOUNT = "amount",
}

export enum FUND_TRANSFER_FILTERS_DATE {
  START_DATE = "start_date",
  END_DATE = "end_date",
}

export enum FUND_TRANSFER_FILTERS_AMOUNT {
  MIN_AMOUNT = "min_amount",
  MAX_AMOUNT = "max_amount",
}

export const ADDITIONAL_INFO_TEXT = {
  [TABS_KEYS.ALL]: "Create and manage your organization’s budgets to control spend",
  [TABS_KEYS.SCHEDULED]: "Track recurring fund transfers active in your company",
  [FUND_TRANSFERS_SUB_TABS.REQUESTED_FUNDS]: "Track funds that have been requested by a user across all statuses",
  [FUND_TRANSFERS_SUB_TABS.SENT_FUNDS]:
    "Track funds that have been sent that are either initiated or approved by a Budget owner",
};
