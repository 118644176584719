import APIclient from "API/Client";

export const createPartnerConnection = ({
  partnerId,
  partnerTenantId,
  partnerTenantName,
  partnerConnectionID,
}: {
  partnerId: number;
  partnerTenantId: string;
  partnerTenantName: string;
  partnerConnectionID: string;
}) => {
  return APIclient.postData("ms/b2b-accounting/v2/partners/connections", {
    partnerId,
    partnerTenantId,
    partnerTenantName,
    partnerConnectionID,
  });
};
