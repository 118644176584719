import { ESidePanelButtonType } from "Modules/DS/SidePanel";
import styles from "../index.module.scss";
import { BUTTON_ACTION, ENABLED_PAY_IMMEDIATELY_STATUS } from "Views/Transaction/Constants";

export const getBillsTrxnSidePanelButtons = (
  showEditableFields: boolean,
  sidePanelActionBtnClickHandler: (_: string) => void,
  formValueChanged: boolean,
  statusKey: string,
  isPaymentEnabled: boolean
) => {
  switch (true) {
    case showEditableFields: {
      return [
        {
          id: "SIDE-PANEL-BUTTON-001",
          type: ESidePanelButtonType.SECONDARY,
          disabled: false,
          text: BUTTON_ACTION.DISCARD,
          enabled: true,
          loading: false,
          action: () => sidePanelActionBtnClickHandler(BUTTON_ACTION.DISCARD),
          buttonClassName: styles.discardChangesBtn,
        },
        {
          id: "SIDE-PANEL-BUTTON-002",
          type: ESidePanelButtonType.SECONDARY,
          enabled: formValueChanged,
          disabled: !formValueChanged,
          text: BUTTON_ACTION.SAVE,
          loading: false,
          action: () => sidePanelActionBtnClickHandler(BUTTON_ACTION.SAVE),
          buttonClassName: styles[!formValueChanged ? "interactivePrimaryBtnDisabled" : "interactivePrimaryBtn"],
        },
      ];
    }
    case ENABLED_PAY_IMMEDIATELY_STATUS.includes(statusKey) && isPaymentEnabled: {
      return [
        {
          id: "SIDE-PANEL-BUTTON-001",
          type: ESidePanelButtonType.SECONDARY,
          text: BUTTON_ACTION.PAY_IMMEDIATELY,
          shouldUseColSpan: true,
          loading: false,
          action: () => sidePanelActionBtnClickHandler(BUTTON_ACTION.PAY_IMMEDIATELY),
          buttonClassName: styles.interactivePrimaryBtn,
        },
      ];
    }
    case ENABLED_PAY_IMMEDIATELY_STATUS.includes(statusKey) && !isPaymentEnabled: {
      return [
        {
          id: "SIDE-PANEL-BUTTON-002",
          type: ESidePanelButtonType.SECONDARY,
          text: BUTTON_ACTION.MARK_AS_PAID,
          shouldUseColSpan: true,
          loading: false,
          action: () => sidePanelActionBtnClickHandler(BUTTON_ACTION.MARK_AS_PAID),
          buttonClassName: styles.interactivePrimaryBtn,
        },
      ];
    }
    default: {
      return [
        {
          id: "SIDE-PANEL-BUTTON-001",
          type: ESidePanelButtonType.SECONDARY,
          text: BUTTON_ACTION.EDIT,
          shouldUseColSpan: true,
          loading: false,
          action: () => sidePanelActionBtnClickHandler(BUTTON_ACTION.EDIT),
          buttonClassName: styles.interactivePrimaryBtn,
        },
      ];
    }
  }
};
