import { TOASTER_STATUS_TYPE } from "Modules/DS/Toaster";
import { createContext, useContext } from "react";

interface Toaster {
  show: boolean;
  type: TOASTER_STATUS_TYPE;
  message: string;
}

interface IToasterContext {
  toaster: Toaster;
  setToaster: (toaster: Toaster) => void;
}

export const ToasterContext = createContext<IToasterContext>({
  toaster: null,
  setToaster: () => {},
});

export const useToasterContext: () => IToasterContext = () => useContext(ToasterContext);

export { TOASTER_STATUS_TYPE } from "Modules/DS/Toaster";
