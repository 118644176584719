import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Form, Input } from "antd";
import classNames from "classnames";
import "./otpInputForm.scss";

const OTPInputForm = ({
  inputLength = 6,
  onFinish,
  error,
  type,
  onFocus = () => {
    /* do nothing */
  },
}) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [form] = Form.useForm();
  const handleInputFocus = (ele) => {
    setErrorMessage("");
    onFocus();
    let next = ele.target.tabIndex;
    if (["Delete", "Backspace"].includes(ele.key)) {
      const nextSelected = next - 2;
      nextSelected > -1 && ele.target.form.elements[nextSelected].focus();
    } else if (next < inputLength && ele.target.form.elements[next - 1].value) {
      ele.target.form.elements[next].focus();
    }
    form.submit();
  };

  const handleFormInputs = (num) => {
    return [...Array(num)].map((ele, index) => {
      const currentInput = index + 1;
      return (
        <Form.Item key={`otp-input-${index}`} name={`input${currentInput}`}>
          <Input
            className={`otp-input-field ${(errorMessage && "otp-input-field--error") || ""}`}
            type="text"
            autoComplete="off"
            tabIndex={currentInput}
            maxLength="1"
            data-testid={`otp-input-${currentInput}`}
            onKeyUp={handleInputFocus}
          />
        </Form.Item>
      );
    });
  };

  const onSubmitHandler = (values) => {
    const onlyNumbersRegEx = new RegExp(/^\d+$/);
    const otpInputs = Object.values(values)
      .filter((item) => item)
      .join("");
    if (otpInputs.length === inputLength) {
      if (onlyNumbersRegEx.test(otpInputs)) {
        onFinish(otpInputs);
      } else {
        setErrorMessage("Please input a valid OTP.");
      }
    }
  };

  useEffect(() => {
    error && form.resetFields();
  }, [form, error]);

  const _className = classNames("otp-input-form", {
    [`otp-input-form--${type}`]: type,
  });

  return (
    <>
      <div className={_className}>
        <Form data-testid="otp-form" form={form} onFinish={onSubmitHandler} autoComplete="off">
          {handleFormInputs(inputLength)}
        </Form>
        {errorMessage && <div className="otp-input-form__error-message">{errorMessage}</div>}
      </div>
    </>
  );
};

OTPInputForm.propTypes = {
  inputLength: PropTypes.number,
  onFinish: PropTypes.func,
  error: PropTypes.bool,
  type: PropTypes.string,
  onFocus: PropTypes.func,
};

export default OTPInputForm;
