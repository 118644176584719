import { CARD_TXN_STATUS } from "Views/Transaction/@types";
import { CHIP_STATUS } from "Modules/DS/Chip";

const RefundConfig = {
  status: CHIP_STATUS.NEUTRAL,
  title: "Refunded",
};

const SettledConfig = {
  status: CHIP_STATUS.SUCCESS,
  title: "Settled",
};

const ReversedConfig = {
  status: CHIP_STATUS.NEUTRAL,
  title: "Reversed",
};

const DisputedConfig = {
  status: CHIP_STATUS.FAILED,
  title: "Disptued",
};

export const StatusToChipConfig = {
  [CARD_TXN_STATUS.PENDING]: {
    status: CHIP_STATUS.ATTENTION,
    title: "Pending",
  },
  [CARD_TXN_STATUS.SETTLED]: {
    ...SettledConfig,
  },
  [CARD_TXN_STATUS.DECLINED]: {
    status: CHIP_STATUS.FAILED,
    title: "Declined",
  },
  [CARD_TXN_STATUS.REVERSED]: {
    ...ReversedConfig,
  },
  [CARD_TXN_STATUS.REFUND_FULL]: {
    ...RefundConfig,
  },
  [CARD_TXN_STATUS.REFUND_PARTIAL]: {
    ...RefundConfig,
  },
  [CARD_TXN_STATUS.DISPUTED]: {
    ...DisputedConfig,
  },
  [CARD_TXN_STATUS.CLOSED]: {
    ...SettledConfig,
  },
  [CARD_TXN_STATUS.UNMATCHED_REVERSAL]: {
    ...RefundConfig,
  },
  [CARD_TXN_STATUS.DISPUTED_CHARGEBACK]: {
    ...DisputedConfig,
  },
  [CARD_TXN_STATUS.UNMATCHED_SETTLEMENT]: {
    ...SettledConfig,
  },
  [CARD_TXN_STATUS.SETTLED_UPDATED]: {
    ...SettledConfig,
  },
  [CARD_TXN_STATUS.APPROVED_DROPPED_OFF]: {
    ...ReversedConfig,
  },
  [CARD_TXN_STATUS.SETTLEMENT_CREDIT]: {
    ...RefundConfig,
  },
};
