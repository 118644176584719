import { useContext, useState } from "react";

import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";

import { getSummaryConfigs } from "Views/KYX/DataCalls";
import { KYXToastContext, KYXErrorToaster } from "Views/KYX/Context";
import { IGetSummaryConfigs, IKYXRemarkContextType, IKYXToastContext, IRemarksSummaryPayload } from "Views/KYX/@types";
import { KYXRemarkContext } from "Views/KYX/Context/Remarks";
import { REMARKS_SIDEBAR_TYPE } from "Views/KYX/Constants/Remarks";

export const useGetSummaryConfigs = (
  dispatchOnSuccess?: (payload: IRemarksSummaryPayload) => void
): IGetSummaryConfigs => {
  const [loading, setLoading] = useState(false);
  const { setToaster } = useContext<IKYXToastContext>(KYXToastContext);
  const { setRemarks }: IKYXRemarkContextType = useContext(KYXRemarkContext);

  const getRemarksSummary = async () => {
    try {
      setLoading(true);

      const response = await getSummaryConfigs();

      if (response?.status !== HTTP_STATUS_CODE.OK) {
        throw new Error(response?.statusText);
      }

      const data = response?.data?.payload;
      const errorMessage = response?.data?.error?.message || response?.data?.meta?.message;

      if (response?.data?.status === HTTP_STATUS_CODE.OK) {
        dispatchOnSuccess?.(data);
        setRemarks({ visible: true, payload: data, type: REMARKS_SIDEBAR_TYPE.FORM });
      } else {
        throw new Error(errorMessage);
      }
    } catch (error) {
      const toast = KYXErrorToaster(error?.message || error);

      setToaster(toast);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return { getRemarksSummary, loading };
};
