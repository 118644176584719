import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getCookieValue, cookieNames } from "utility/CookieHelper";
import { fetchCompanyExpenseAndBalance } from "Redux/DataCalls/Expenses.api";
import { USER_ROLE } from "constants/Team.constant";

import LimitModelExpenseCarousel from "Views/Home/Components/LimitModelExpenseCarousel";
import WalletModelExpenseCarousel from "Views/Home/Components/WalletModelExpenseCarousel";
import CompactDesign from "Views/Home/Components/CompactDesign";
import Header from "Views/Home/Components/Header";
import { trackEvent } from "utility/analytics";
import { HomeEventName } from "Views/Home/Analytics";
import { getIsCardVendorNium } from "customHooks/Card";

import { CARD_VENDOR_TYPE } from "constants/QuickAccess.constant";
import { PRODUCT_NAME } from "Redux/ModularProduct";
import { checkAccess } from "utility/permissions/permission";
import useCheckOrgConfigs from "customHooks/useCheckOrgConfigs";

import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";

import styles from "./index.module.scss";

const SaasAndPayments = () => {
  const isCardVendorNone = useCheckOrgConfigs(PRODUCT_NAME.CARD_VENDOR) === CARD_VENDOR_TYPE.NONE;
  const isCardVendorNium = getIsCardVendorNium();
  const isLimitModel = isCardVendorNium || isCardVendorNone;
  const { teamData, userData = {} } = useSelector((state: any) => ({
    teamData: state?.teamReducer?.data?.payload?.team,
    userData: state?.userInfoReducer?.data,
  }));
  const [companyExpenseAndBalance, setCompanyExpenseAndBalance] = useState(null);

  const userName = userData?.payload?.user?.name || "";
  const companyRole = getCookieValue(cookieNames.USER_COMPANY_ROLE);
  const orgCurrencyCode = getCookieValue(cookieNames.CURRENCY_CODE);

  const getCompanyExpenseAndBalance = () => {
    if (checkAccess([USER_ROLE.ADMIN, USER_ROLE.ACCOUNTANT, USER_ROLE.AUDITOR], companyRole)) {
      fetchCompanyExpenseAndBalance()
        .then((response) => {
          const { status, payload = null } = response?.data || {};
          if (status === HTTP_STATUS_CODE.OK && payload) {
            setCompanyExpenseAndBalance({
              lowBalanceThreshold: payload?.low_balance_threshold?.toString() || "",
              remainingBalance: payload?.remaining_company_balance?.toString() || "",
              totalExpense: payload?.expenses_this_month?.toString() || "",
              currencyCode: payload?.currency_code || orgCurrencyCode,
            });
          }
        })
        .catch((error) => console.log(error));
    }
  };

  useEffect(() => {
    getCompanyExpenseAndBalance();
  }, [companyRole]);

  useEffect(() => {
    const onPageLoad = () => trackEvent(HomeEventName.HOME_PAGE_LOADED);

    // Check if the page has already loaded
    if (document.readyState === "complete") {
      onPageLoad();
    } else {
      window.addEventListener("load", onPageLoad);
      // Remove the event listener when component unmounts
      return () => window.removeEventListener("load", onPageLoad);
    }
  }, []);

  return (
    <>
      <div className={styles.limitModalContainer}>
        <Header companyExpenses={companyExpenseAndBalance} userName={userName} companyRole={companyRole} />
        {isLimitModel ? <LimitModelExpenseCarousel /> : <WalletModelExpenseCarousel />}
        <CompactDesign userData={userData} teamData={teamData} />
      </div>
    </>
  );
};

export default SaasAndPayments;
