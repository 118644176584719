import React, { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { greyCloseIcon } from "assets/img";
import Modal, { ModalComponent } from "Modules/DS/Modal";
import { TITLE_TYPE } from "Modules/DS/Modal/types";
import Button from "Modules/button";
import useLoading from "utility/useLoading";
import { useQuery } from "utility/useQuery";
import { createBulkTaxFunc, getPartnerTaxes, getLastAccountingEvent } from "Redux/DataCalls/Taxes.api";
import ImportTaxInformation from "./components/ImportTaxInformation";
import TaxMapping from "./components/TaxMapping";
import TaxImporting from "./components/TaxImporting";
import { trackEvent } from "utility/analytics";
import { TaxContext } from "../context/TaxContext";
import { IImportTaxStep, IModalTaxImport } from "../types";
import { TOASTER_STATUS_TYPE } from "Modules/DS/Toaster";

import "./styles.scss";

const ModalTaxImport = ({ visible, onClose }: IModalTaxImport) => {
  const { setToaster, getData } = useContext(TaxContext);
  const [activeStep, setActiveStep] = useState(0);
  const missingTaxes = useQuery({ apiCall: () => getPartnerTaxes(true), run: activeStep === 1 });
  const importTaxEvent = useQuery({ apiCall: getLastAccountingEvent, run: activeStep === 0 && visible });
  const { payload } = useSelector((state: any) => state.accountingPartnerAuthReducer.data);
  const { partnerName, partnerLogoUrl } = payload || {};

  const [importTax, loadingImportTax] = useLoading(createBulkTaxFunc, (response) => {
    return response?.data?.error?.message || "Something Went Wrong";
  });
  const steps: IImportTaxStep[] = [
    {
      id: 1,
      Component: ImportTaxInformation,
      text: "Import Tax",
      action: () => {
        trackEvent("Click import tax from modal");
        setActiveStep(activeStep + 1);
      },
      height: "short",
    },
    {
      id: 2,
      Component: TaxMapping,
      text: "Map New Tax",
      height: "long",
      action: () => {
        trackEvent("Click Map new tax from modal");
        setActiveStep(activeStep + 1);
      },
    },
    {
      id: 3,
      Component: TaxImporting,
      text: "Finish Importing & Mapping",
      height: "long",
      action: async () => {
        const taxes = missingTaxes?.data?.data;

        if (!taxes) {
          onClose();
          return;
        }

        const importedTax = taxes?.map((tax) => {
          const { taxName, taxRate, taxType } = tax;
          return {
            tax_name: taxName,
            tax_rate: taxRate,
            status: "active",
            partner_tax_name: taxName,
            partner_tax_type: taxType,
            partner_tax_rate: taxRate,
          };
        });

        const { data } = await importTax({
          tax_data: importedTax,
        });

        onClose();
        setToaster({
          message: data?.payload?.status_message,
          show: true,
          type: TOASTER_STATUS_TYPE.SUCCESS,
        });
        getData(0, importedTax);
        trackEvent("Finish importing tax from modal");
      },
    },
  ];
  const step = steps[activeStep];

  const disabledButton = () => {
    if (activeStep == 1 && missingTaxes?.data?.count === 0) {
      return true;
    }

    return loadingImportTax;
  };

  return (
    <Modal
      visible={visible}
      className={`import-tax-modal import-tax-modal__${step.height}`}
      close={() => {
        setActiveStep(0);
        onClose();
      }}
    >
      <ModalComponent.CloseButton fixToTop src={greyCloseIcon} />
      <ModalComponent.Title titleType={TITLE_TYPE.withBorder}>Add Tax</ModalComponent.Title>
      <section className="import-tax-modal__body">
        <div className="import-tax-modal__body-option">
          <div className="item selected">
            Import From {partnerName}
            <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M1.81827 0.909179L6.90918 6.00009L1.81827 11.091"
                stroke="currentColor"
                strokeWidth="1.81818"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
        <div className="import-tax-modal__body-content">
          <step.Component
            missingTaxes={missingTaxes}
            importTaxEvent={importTaxEvent}
            partnerName={partnerName}
            partnerLogoUrl={partnerLogoUrl}
          />
          <div className="download-button-wrapper">
            <Button
              rounded
              disabled={disabledButton()}
              loading={loadingImportTax}
              className={disabledButton() ? "button--processing" : "button--download"}
              action={step.action}
              type="submit"
              text={step.text}
            />
          </div>
        </div>
      </section>
    </Modal>
  );
};

export default ModalTaxImport;
