import { FETCH_CARD_REQUEST, FETCH_CARD_SUCCESS, FETCH_CARD_FAILURE } from "../Actions/cardAction";
import { userInitialState } from "../InitialState";

export const cardReducer = (state = userInitialState, action) => {
  switch (action.type) {
    case FETCH_CARD_REQUEST:
      return {
        ...state,
        data: action.data,
        loading: true,
      };
    case FETCH_CARD_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
      };
    case FETCH_CARD_FAILURE:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: true,
      };
    default:
      return state;
  }
};
