import React, { useState, useEffect } from "react";
import Icon from "Modules/icons";
import { Typography } from "antd";

import { SingPass } from "assets/img/kyb";
import styles from "./index.module.scss";
import { IKYXSingpass } from "Views/KYX/@types";
import { useGetSingpassToken } from "Views/KYX/hooks/useGetSingpassToken";
import moment from "moment";

export const VerifySingpass: React.FC<IKYXSingpass> = ({ shareholderName, personnelId }: IKYXSingpass): JSX.Element => {
  const { Text } = Typography;
  const [tokenInfo, setTokenInfo] = useState<{ value: string; expiry_time: string }>(null);
  const { getToken } = useGetSingpassToken(setTokenInfo);

  useEffect(() => {
    getToken(personnelId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const jointApplicantUrl = `${window.location.origin}/joint-applicant?t=${tokenInfo?.value}`;

  return (
    <div className={styles.wrapper}>
      <Icon className={styles.icon} src={SingPass} />
      <p className={styles.info}>
        Share the link below with {shareholderName} to have them verify their submitted information using Singpass
      </p>

      <div className={styles.linkSection}>
        <h5 className={styles.label}>Personnel Verification URL</h5>
        <Text className={styles.link} copyable={{ icon: <p>Copy</p>, tooltips: false }}>
          {jointApplicantUrl}
        </Text>
        <p className={styles.expiryInfo}>
          The link is valid until {moment(tokenInfo?.expiry_time).format("DD/MM/YYYY hh:mm:ss")}
        </p>
      </div>
    </div>
  );
};
