/**
 * @fileoverview NavigationLoader Component
 * This component renders a skeleton UI for the Navigation. It serves as a placeholder
 * while the actual data is being fetched, giving users visual feedback that content is loading.
 *
 * @author Sushmitha, Bakshi
 * @created 24 Aug 2023
 * @lastModified 30 Aug 2023
 *
 * Dependencies:
 * - index.module.scss: Contains the necessary styling variables and classes for this component.
 * - antd: Uses Skeleton components from antd for creating the loader effect.
 */
import React from "react";
import { Skeleton } from "antd";
import spenmoLogo from "@spenmo/splice/src/assets/logo_spenmo.svg";

import styles from "./index.module.scss";

export const NavigationLoader: React.FC = (): React.ReactElement => {
  // Constants to determine the number of sections and items.
  const NUM_OF_SECTIONS = 3;
  const FIRST_SECTION_MENU_ITEMS = 4;

  /**
   * Generates an array of menu item skeleton components.
   *
   * @param {number} count - Number of menu items to generate.
   * @returns {React.ReactNode} An array of menu item skeleton components.
   */
  const getMenuItem = (count: number): React.ReactNode => {
    return React.Children.toArray(
      Array.from({ length: count }, () => (
        <div className={styles.menuItem} data-testid="menu-item">
          <Skeleton.Avatar className={styles.badge} active shape="square" size={16} />
          <Skeleton.Input className={styles.menu} active size="small" />
          <Skeleton.Avatar className={styles.badge} active shape="square" size={16} />
        </div>
      ))
    );
  };

  /**
   * Generates an array of menu list skeleton components.
   *
   * @returns {React.ReactNode} An array of menu list skeleton components.
   */
  const getMenuList = (): React.ReactNode => {
    return React.Children.toArray(
      Array.from({ length: NUM_OF_SECTIONS }, (_, index) => (
        <div className={styles.menuList} data-testid="menu-list">
          <Skeleton.Input className={styles.title} active size="small" />
          {getMenuItem(index === 0 ? FIRST_SECTION_MENU_ITEMS : NUM_OF_SECTIONS)}
        </div>
      ))
    );
  };

  return (
    <>
      <div className={styles.loader} data-testid="navigation-loader">
        {/* Header section of the loader */}
        <div className={styles.header} data-testid="navigation-header">
          <Skeleton.Avatar className={styles.avatar} active shape="square" size={32} />
          <Skeleton.Input className={styles.menu} active size="small" />
          <Skeleton.Avatar className={styles.badge} active shape="square" size={16} />
        </div>

        {/* Separator for visual spacing */}
        <div className={styles.separator} />

        {/* Menu list loader sections */}
        {getMenuList()}

        {/* Footer section of the loader */}
        <div className={styles.utility} data-testid="utility">
          {getMenuItem(NUM_OF_SECTIONS)}
        </div>
      </div>

      <div className={styles.footer}>
        <div className={styles.content}>
          <img className={styles.logo} src={spenmoLogo} alt="Spenmo Logo" />
        </div>
      </div>
    </>
  );
};
