import React from "react";
import { useHistory } from "react-router-dom";
import { FormInstance } from "antd/lib/form";
import { ESidePanelButtonType, SidePanel } from "Modules/DS/SidePanel";
import { TransactionDetail, TransactionHeader } from "Modules/TransactionDetail";
import { Option, useTransactionDetail } from "Views/Accounting/Workflow/hooks/useTransactionDetail";
import { useAppSelector } from "hook";
import { USER_ROLE } from "constants/Team.constant";

const mapRecord = (record) => ({
  transactionNumber: record.transaction_number,
  createdAt: record.created_at,
  transactionType: record.transaction_type,
  ...record,
});

export const TransactionSidePanel = ({
  visible,
  record,
  onClose,
}: {
  visible: boolean;
  record: any;
  onClose: () => void;
}) => {
  const transaction = mapRecord(record);
  const history = useHistory();
  const loggedInUserDetails = useAppSelector((state: any) => state?.userInfoReducer);
  const isAdmin = [USER_ROLE.ADMIN, USER_ROLE.ACCOUNTANT].includes(loggedInUserDetails?.company_role);
  const isPayeerUser = loggedInUserDetails.data?.payload?.user?.id === record.payer_id;
  const { header, loading, detail, defaultValue } = useTransactionDetail(transaction, visible, {} as Option);

  return (
    <SidePanel
      sticky
      buttons={[
        {
          id: "BUTTON-001",
          type: ESidePanelButtonType.SECONDARY,
          disabled: !isAdmin && !isPayeerUser,
          text: "Edit in Transaction Page",
          action: () => history.push(`/transactions?tab=all&transaction_number=${transaction.transactionNumber}`),
        },
      ]}
      onClose={onClose}
      title="Transaction Details"
      visible={visible}
      loading={loading}
    >
      <TransactionHeader {...header()} />
      <TransactionDetail
        form={{ setFieldsValue: () => {}, getFieldValue: () => {} } as unknown as FormInstance}
        defaultValue={defaultValue()}
        setFormValue={() => {}}
        receiptTitle="Receipts"
        disabled
        details={detail()}
      />
    </SidePanel>
  );
};
