import React from "react";

import { IStartIntro } from "Views/KYX/@types";
import { KYX_ORG_COUNTRY_LIST } from "Views/KYX/Constants";
import { IntroLoader as Loader } from "Views/KYX/Loader";
import { ManualMethod } from "./Manual";
import { MyInfo } from "./MyInfo";

/**
 *
 * FirstTimeUser component displays the appropriate KYB_SOURCE component based on the provided countryCode.
 *
 * This component takes two props, companyName and countryCode,
 * and renders a different ManualMethod component based on the countryCode value.
 *
 * If the countryCode matches either KYX_ORG_COUNTRY_LIST.IDR or KYX_ORG_COUNTRY_LIST.SGD,
 * it will render the ManualMethod component with the corresponding countryCode.
 *
 * If the countryCode doesn't match either of these values,
 * it will render a Loader component as a fallback.
 *
 * @param {IStartIntro} props - Props containing companyName and countryCode
 * @param {string} props.companyName - A string value that contains the name of the company.
 * @param {KYX_ORG_COUNTRY_LIST} props.countryCode - Contains a reference to the Country Codes that are supported for ONB 3.0
 * @returns {JSX.Element} A JSX Element
 */
const FirstTimeUser = ({ companyName, countryCode }: IStartIntro): JSX.Element => {
  let Component: JSX.Element;

  switch (true) {
    case countryCode === KYX_ORG_COUNTRY_LIST.IDR:
      Component = <ManualMethod companyName={companyName} countryCode={countryCode} />;
      break;
    case countryCode === KYX_ORG_COUNTRY_LIST.SGD:
      Component = <MyInfo companyName={companyName} countryCode={countryCode} />;
      break;
    default:
      Component = <Loader />;
      break;
  }

  return Component;
};

export default FirstTimeUser;
