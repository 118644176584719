import React, { useEffect, useMemo, useState } from "react";
import { Document, Page } from "react-pdf";
import { Form, Input, Select } from "antd";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import qs from "query-string";
import { roundValue } from "utility";
import { TAX_STATUS } from "constants/Tax.constant";
import { Core } from "assets/v1.1/icons/Core";
import {
  ChevronRight,
  Banner,
  ExternalOutline,
  Switch,
  Typography,
} from "@spenmo/splice";

import Section from "Modules/TransactionDetail/Section";
import { trackEvent } from "utility/analytics";
import CategoryDropdown from "Modules/categoryDropdown";
import DSInput from "Modules/DS/Input";
import AccountingTags from "Modules/AccountingTags";
import { LabelWithTooltip } from "Views/SubscriptionManagement/Create/Components";
import { subscriptionListWithAddNew } from "Views/SubscriptionManagement/Create/options";
import { useStaticSubscription } from "Views/SubscriptionManagement/hooks/useSubscriptionList";
import { Create } from "Views/SubscriptionManagement/Create";
import { usePermission } from "Views/SubscriptionManagement/hooks/usePermission";
import { FORM_TYPE } from "Views/SubscriptionManagement/Constants";
import { ErrorMessage } from "Views/SubscriptionManagement/Create/Components";
import usePermissionCheck from "Permission/usePermissionCheck";
import { CREATE_NEW_SUBSCRIPTION_PARAMS } from "Views/SubscriptionManagement/Permission";
import { SUBSCRIPTION_PATH } from "Route/Subscription/path";

import {
  EBannerPosition,
  EInputType,
  ITransactionDetailProps,
} from "../type.d";
import List from "../List";
import UploadAttachment from "./UploadAttachment";
import { getAllTaxesFunc } from "Redux/Actions";
import InformationBanner from "./InformationBanner";
import { allOption, defaultInput, isBillsTrxnsPage } from "./config";

import styles from "./styles.module.scss";

const { Option } = Select;
const { TextArea } = Input;
const { Check, ChevronDown, Search } = Core;

const greenCheckIcon = (
  <img width={16} height={16} src={Check.Line[16]} alt="Green check" />
);

const TransactionDetail: React.FC<ITransactionDetailProps> = ({
  details,
  visibleInput = allOption,
  receiptTitle = "Receipt",
  bannerPosition,
  defaultValue = defaultInput,
  form,
  setFormValue,
  additionalMarginButtom = false,
  disabled = false,
  deletable = true,
  setFormChanged = () => {},
  missingFields = {},
  trackEventData,
  customBannerMessage,
  categoryFallback = "",
  taxFallback = "",
  ignoreRequiredCheck,
  resetFormData,
  page,
  paymentReceiptTitle,
  onDeleteFile,
  bannerProps,
  lineItems,
  onRemoveSubscription = () => {},
  showDSBanner = false,
  showLineItems = false,
  subscriptionInitialProps,
}) => {
  const dispatch = useDispatch();

  const isBillsTrxnsView = isBillsTrxnsPage(page);
  const taxesData = useSelector((state: any) => state.taxReducer.data);
  const accountingTag = useSelector((state: any) => state.accountingTagReducer);
  const [hasAccountingTag, setHasAccountingTag] = useState(true); // Default to true so the child component can call the API to check the tags
  const taxes = useMemo(() => taxesData?.payload?.data || [], [taxesData]);

  const { subscriptions, mutate: mutateSubscriptions } =
    useStaticSubscription();
  const [openAccountingTags, setOpenAccountingTags] = useState(false);
  const [openCategoryDropdown, setOpenCategoryDropdown] = useState(false);
  const [showSubscription, setShowSubscription] = useState(
    Boolean(defaultValue[EInputType.SUBSCRIPTION]),
  );
  const [showCreateSubscription, setShowCreateSubscription] = useState(false);
  const [transactionForm, setTransactionForm] = useState(defaultValue);
  const { allowedToOpenPage } = usePermission();
  const IS_ALLOWED_TO_CREATE_SUBSCRIPTION =
    (usePermissionCheck(CREATE_NEW_SUBSCRIPTION_PARAMS) as boolean) || false;

  const tnxRedirectionLink = qs.stringifyUrl({
    url: "/transactions",
    query: {
      tab: "all",
      startDate: dayjs().subtract(1, "year").toISOString(),
      endDate: dayjs().toISOString(),
      recipientNames: subscriptions.find(
        (subscription) =>
          subscription.id === transactionForm[EInputType.SUBSCRIPTION],
      )?.vendor?.name,
      subscription: transactionForm[EInputType.SUBSCRIPTION],
    },
  });

  useEffect(() => {
    resetFormData && setTransactionForm(defaultValue);
  }, [resetFormData]);

  useEffect(() => {
    setFormValue(transactionForm);
    setFormChanged(
      JSON.stringify(transactionForm) !== JSON.stringify(defaultValue),
    );
  }, [transactionForm]);

  useEffect(() => {
    setShowSubscription(Boolean(form.getFieldValue(EInputType.SUBSCRIPTION)));
  }, [form.getFieldValue(EInputType.SUBSCRIPTION)]);

  useEffect(() => {
    setHasAccountingTag(
      accountingTag.loading ||
        (!accountingTag.error &&
          accountingTag?.data?.payload?.tags?.length > 0),
    );
  }, [accountingTag]);

  useEffect(() => {
    dispatch(getAllTaxesFunc({ status: TAX_STATUS.ACTIVE }));
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      ...defaultValue,
      [EInputType.TAX]: findTaxDefaultValue(),
    });
  }, [taxes]);

  useEffect(() => {
    const newValue = {
      ...transactionForm,
      [EInputType.TRANSACTION_TAGS]: defaultValue[EInputType.TRANSACTION_TAGS],
    };
    setTransactionForm(newValue);
    // There is a use case for old refund transaction, there is a delay to show the transaction tags because we have to do API call,
    // So there is a gap between data in the state with data cames after finishing the API call.
    // This useEffect will sync the state data withdata come from the API call.
  }, [defaultValue[EInputType.TRANSACTION_TAGS]]);

  const defaultTags = defaultValue[EInputType.TRANSACTION_TAGS];
  const getTaxLabel = (tax: any) => {
    return `${tax.tax_name} (${roundValue(tax.tax_rate)}%)`;
  };

  const setValue =
    (key: string) => (value: string | string[] | any, additionalData?: any) => {
      if (trackEventData) {
        const { name, role } = trackEventData;
        if (name && role) {
          trackEvent(name, {
            approval_cr_details_edited: key,
            user_role: role,
          });
        }
      }
      const updatedValue = {
        [key]: value,
      };

      if (key === EInputType.CATEGORY) {
        const [selectedCategory] = additionalData;
        const taxValue = selectedCategory?.tax_id;
        updatedValue[EInputType.TAX] = taxValue;
        form.setFieldsValue({ [EInputType.TAX]: taxValue });
      }

      setTransactionForm({
        ...transactionForm,
        ...updatedValue,
      });
    };

  const haveValue = (name: string) => {
    if (
      !transactionForm[name] ||
      transactionForm[name]?.length === 0 ||
      transactionForm[name]?.trim?.() === ""
    ) {
      return null;
    }

    return greenCheckIcon;
  };

  const requiredAndEmpty = (type: EInputType) => {
    return (
      !ignoreRequiredCheck && Boolean(missingFields?.[type]) && !haveValue(type)
    );
  };

  const onSeeEmptyFields = () => {
    const updateMissingField = { ...missingFields };
    delete updateMissingField[EInputType.MERCHANT]; // Ignore merchant since it's not shown anymores
    const firstMissingElementId = Object.keys(updateMissingField)[0];
    if (!firstMissingElementId) {
      return;
    }
    const missingElement = document.getElementById(firstMissingElementId);
    missingElement?.scrollIntoView({ behavior: "smooth" });
  };

  const findTaxDefaultValue = () => {
    const defaultTax = defaultValue[EInputType.TAX];
    if (
      (taxes || [])?.find(
        (tax) => tax.id === defaultTax || tax.tax_name === defaultTax,
      )
    ) {
      return defaultTax;
    }

    return taxFallback;
  };

  const handleSubscription: React.MouseEventHandler<HTMLButtonElement> = () => {
    const toggledShowSubscription = !showSubscription;
    // This means the user want to remove the subscription from the transaction
    if (!toggledShowSubscription && transactionForm[EInputType.SUBSCRIPTION]) {
      onRemoveSubscription();
      return;
    }

    setShowSubscription(toggledShowSubscription);
  };

  const filteredDetails = details.filter((detail) => Boolean(detail.value));
  const isSubscriptionFieldEmpty = Boolean(
    form.getFieldValue(EInputType.SUBSCRIPTION),
  );

  return (
    <Section additionalMarginButtom={additionalMarginButtom}>
      <div className={styles["transaction-detail"]}>
        {bannerPosition === EBannerPosition.TOP && (
          <InformationBanner
            position={bannerPosition}
            onSeeEmptyFields={onSeeEmptyFields}
            customMessage={customBannerMessage}
          />
        )}
        <h4
          className={classNames(styles.title, {
            [styles.titleColor]: isBillsTrxnsView,
          })}
        >
          Transaction Details
        </h4>
        <List page={page} details={filteredDetails}>
          {visibleInput?.[EInputType.MERCHANT] && (
            <li>
              <Form.Item
                name={EInputType.MERCHANT}
                label={
                  <span
                    className={classNames(styles["label-wrapper"], {
                      [styles.titleColor]: isBillsTrxnsView,
                    })}
                  >
                    Merchant Name {haveValue(EInputType.MERCHANT)}
                  </span>
                }
              >
                <DSInput
                  className={classNames({
                    [styles["error-border"]]: requiredAndEmpty(
                      EInputType.MERCHANT,
                    ),
                  })}
                  value={transactionForm[EInputType.MERCHANT]}
                  onChange={setValue(EInputType.MERCHANT)}
                  disabled={disabled}
                />
              </Form.Item>
            </li>
          )}
          {visibleInput?.[EInputType.CATEGORY] && (
            <li id={EInputType.CATEGORY}>
              <Form.Item
                name={EInputType.CATEGORY}
                label={
                  <span
                    className={classNames(styles["label-wrapper"], {
                      [styles.titleColor]: isBillsTrxnsView,
                    })}
                  >
                    Category {haveValue(EInputType.CATEGORY)}
                  </span>
                }
              >
                <CategoryDropdown
                  showEmptyCategory
                  defaultValue={defaultValue[EInputType.CATEGORY]}
                  fallbackValue={categoryFallback}
                  action={setValue(EInputType.CATEGORY)}
                  onDropdownVisibleChange={setOpenCategoryDropdown}
                  missingField={requiredAndEmpty(EInputType.CATEGORY)}
                  suffixIcon={
                    <img
                      width={openCategoryDropdown ? 16 : 24}
                      height={openCategoryDropdown ? 16 : 24}
                      className={classNames("ant-select-suffix")}
                      src={
                        openCategoryDropdown
                          ? Search.Line[16]
                          : ChevronDown.Line[24]
                      }
                      alt="Category Dropdown Icon"
                    />
                  }
                  disabled={disabled}
                />
              </Form.Item>
            </li>
          )}
          {visibleInput?.[EInputType.TAX] && (
            <li id={EInputType.TAX}>
              <Form.Item
                name={EInputType.TAX}
                label={
                  <span
                    className={classNames(styles["label-wrapper"], {
                      [styles.titleColor]: isBillsTrxnsView,
                    })}
                  >
                    Tax {haveValue(EInputType.TAX)}
                  </span>
                }
              >
                <Select
                  getPopupContainer={(triggerNode) => triggerNode.parentElement}
                  placeholder="Select tax"
                  className={classNames(styles.taxSelect, {
                    [styles["inner-error-border"]]: requiredAndEmpty(
                      EInputType.TAX,
                    ),
                  })}
                  defaultValue={findTaxDefaultValue()}
                  suffixIcon={
                    <img
                      width={24}
                      height={24}
                      className={classNames(
                        "ant-select-suffix",
                        styles["rotate-180"],
                      )}
                      src={ChevronDown.Line[24]}
                      alt="Chevron Down Icon"
                    />
                  }
                  onChange={setValue(EInputType.TAX)}
                  disabled={disabled}
                >
                  {taxes?.map((tax) => (
                    <Option key={tax.id} value={tax.id}>
                      {getTaxLabel(tax)}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </li>
          )}
          {visibleInput?.[EInputType.TRANSACTION_TAGS] && hasAccountingTag && (
            <li id={EInputType.TRANSACTION_TAGS}>
              <Form.Item name={EInputType.TRANSACTION_TAGS}>
                <AccountingTags
                  onChange={setValue(EInputType.TRANSACTION_TAGS)}
                  initialTags={defaultTags}
                  resetTagsOnUnmount={false}
                  hasMissingField={requiredAndEmpty(
                    EInputType.TRANSACTION_TAGS,
                  )}
                  onOpenChange={(open) => setOpenAccountingTags(open)}
                  customIcon={
                    <div className={classNames("ant-select-arrow")}>
                      <img
                        width={24}
                        height={24}
                        src={ChevronDown.Line[24]}
                        className={
                          openAccountingTags
                            ? styles["rotate-arrow"]
                            : styles["rotate-0"]
                        }
                        alt="Chevron Down Icon"
                      />
                    </div>
                  }
                  customLabel={
                    <label>
                      <span
                        className={classNames(styles["label-wrapper"], {
                          [styles.titleColor]: isBillsTrxnsView,
                        })}
                      >
                        Tags {haveValue(EInputType.TRANSACTION_TAGS)}
                      </span>
                    </label>
                  }
                  disabled={disabled}
                />
              </Form.Item>
            </li>
          )}
          {showDSBanner && (
            <li>
              <Banner {...bannerProps} />
            </li>
          )}

          {showLineItems && lineItems?.items?.length > 0 && (
            <li className={styles.lineItem}>
              <Typography
                size="m"
                variant="body-content"
                className={styles.lineItemTitle}
              >
                {lineItems.title}
              </Typography>
              <div className={styles.lineItemList}>
                {lineItems?.items.map((item) => {
                  return (
                    <div
                      key={item.title + item.subtitle + item.linkTo}
                      className={styles.lineItemContent}
                    >
                      <Typography
                        size="m"
                        variant="body-content"
                        className={styles.lineItemContentTitle}
                      >
                        {/* TODO Hiding link redirection for now, will resolve post new cr mvp  */}
                        {/* <a href={item.linkTo} target="_blank" rel="noopener noreferrer"> */}
                        {item.title}
                        {/* </a> */}
                      </Typography>
                      <Typography
                        size="m"
                        variant="body-content"
                        className={styles.lineItemContentSubtitle}
                      >
                        {item.subtitle}
                      </Typography>
                    </div>
                  );
                })}
                <div className={styles.lineItemDivider} />
                <div className={styles.lineItemTotal}>
                  <Typography size="s" variant="body-content" weight={800}>
                    {lineItems.total.title}
                  </Typography>
                  <Typography size="s" variant="body-content" weight={800}>
                    {lineItems.total.subtitle}
                  </Typography>
                </div>
              </div>
            </li>
          )}
          {visibleInput?.[EInputType.SUBSCRIPTION] && allowedToOpenPage && (
            <li>
              <div
                className={classNames(
                  { subscription: showCreateSubscription },
                  styles.subscription,
                )}
              >
                <div className={styles.labelWithToggle}>
                  <LabelWithTooltip
                    tooltipTitle="Add recurring transactions to an existing or new subscription to manage them better"
                    title="Subscription"
                  />
                  <Switch
                    size="s"
                    isActive={showSubscription}
                    onClick={handleSubscription}
                    disabled={disabled}
                  />
                </div>
                <Create
                  showSidePanel={showCreateSubscription}
                  onClose={() => {
                    setShowCreateSubscription(false);
                    form.setFieldsValue({
                      [EInputType.SUBSCRIPTION]:
                        transactionForm[EInputType.SUBSCRIPTION] || null,
                    });
                  }}
                  onCreate={(id) => {
                    mutateSubscriptions();
                    setValue(EInputType.SUBSCRIPTION)(id);
                    form.setFieldsValue({ [EInputType.SUBSCRIPTION]: id });
                    setShowCreateSubscription(false);
                  }}
                  formType={FORM_TYPE.CREATE}
                  initialValue={{ ...subscriptionInitialProps }}
                />
                {showSubscription && (
                  <>
                    <Form.Item name={EInputType.SUBSCRIPTION}>
                      <Select
                        getPopupContainer={(triggerNode) =>
                          triggerNode.parentElement
                        }
                        placeholder="Select a subscription"
                        onChange={(value) => {
                          if (
                            value ===
                            subscriptionListWithAddNew(
                              subscriptions,
                              IS_ALLOWED_TO_CREATE_SUBSCRIPTION,
                            )[0].value
                          ) {
                            setShowCreateSubscription(true);
                          } else {
                            setValue(EInputType.SUBSCRIPTION)(value);
                          }
                        }}
                        value={transactionForm[EInputType.SUBSCRIPTION]}
                        suffixIcon={
                          <img
                            width={24}
                            height={24}
                            className={classNames(
                              "ant-select-suffix",
                              styles["rotate-180"],
                            )}
                            src={ChevronDown.Line[24]}
                            alt="Chevron Down Icon"
                          />
                        }
                        className={styles.subscriptionSelectBox}
                      >
                        {subscriptionListWithAddNew(
                          subscriptions,
                          IS_ALLOWED_TO_CREATE_SUBSCRIPTION,
                        ).map((vendor) => (
                          <Option key={vendor.id} value={vendor.value}>
                            <div className={styles.vendor}>
                              {vendor.Icon}
                              {vendor.label}
                            </div>
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    {showSubscription && !isSubscriptionFieldEmpty && (
                      <ErrorMessage message="Select a subscription" />
                    )}
                    {Boolean(transactionForm[EInputType.SUBSCRIPTION]) && (
                      <div className={styles.subscriptionBanner}>
                        <Link
                          target="_blank"
                          to={SUBSCRIPTION_PATH.SUBSCRIPTON_DETAILS.replace(
                            ":id",
                            transactionForm[EInputType.SUBSCRIPTION],
                          )}
                        >
                          <Typography
                            variant="body-content"
                            size="caption-m"
                            weight={600}
                            className={classNames(
                              styles.subscriptionLink,
                              styles.subscriptionPaddingLarge,
                            )}
                          >
                            View this subscription
                            <ExternalOutline
                              size="16"
                              iconColor="var(--text-link-default)"
                            />
                          </Typography>
                        </Link>
                        <Banner
                          variant="info"
                          size="s"
                          description={
                            <div>
                              <p className={styles.subscriptionPaddingSmall}>
                                There are other transactions that might be
                                related to this subscription
                              </p>
                              <Link target="_blank" to={tnxRedirectionLink}>
                                <Typography
                                  variant="body-content"
                                  size="caption-m"
                                  weight={600}
                                  className={styles.subscriptionLink}
                                  tag="p"
                                >
                                  Review
                                  <ChevronRight
                                    size="16"
                                    iconColor="var(--text-link-default)"
                                  />
                                </Typography>
                              </Link>
                            </div>
                          }
                        />
                      </div>
                    )}
                  </>
                )}
              </div>
            </li>
          )}
          {visibleInput?.[EInputType.RECEIPT] && (
            <li>
              <Form.Item
                name={EInputType.RECEIPT}
                label={
                  <span
                    className={classNames(styles["label-wrapper"], {
                      [styles.titleColor]: isBillsTrxnsView,
                    })}
                  >
                    {receiptTitle} {haveValue(EInputType.RECEIPT)}
                  </span>
                }
              >
                <UploadAttachment
                  transactionForm={transactionForm}
                  setTransactionForm={setTransactionForm}
                  disabled={disabled}
                  deletable={deletable}
                  hasMissingField={requiredAndEmpty(EInputType.RECEIPT)}
                  additionalConfig={
                    visibleInput?.[EInputType.RECEIPT]?.additionalConfig
                  }
                  onDelete={onDeleteFile}
                />
              </Form.Item>
            </li>
          )}
          {visibleInput?.[EInputType.NOTES] && (
            <li>
              <Form.Item
                name={EInputType.NOTES}
                label={
                  <span
                    className={classNames(styles["label-wrapper"], {
                      [styles.titleColor]: isBillsTrxnsView,
                    })}
                  >
                    Notes {haveValue(EInputType.NOTES)}
                  </span>
                }
              >
                <TextArea
                  id={EInputType.NOTES}
                  onChange={(e) => setValue(EInputType.NOTES)(e.target.value)}
                  rows={2}
                  className={classNames(styles.note, {
                    [styles["error-border"]]: requiredAndEmpty(
                      EInputType.NOTES,
                    ),
                  })}
                  placeholder="Input your notes here"
                  disabled={disabled}
                  defaultValue={defaultValue[EInputType.NOTES]}
                />
                {isBillsTrxnsView && (
                  <p className={styles.additionalInfo}>
                    This is only visible within your organization
                  </p>
                )}
              </Form.Item>
            </li>
          )}
          {visibleInput?.[EInputType.PAYMENT_RECEIPT] &&
            transactionForm[EInputType.PAYMENT_RECEIPT] && (
              <li>
                <Form.Item
                  name={EInputType.PAYMENT_RECEIPT}
                  label={
                    <span
                      className={classNames(styles["label-wrapper"], {
                        [styles.titleColor]: isBillsTrxnsView,
                      })}
                    >
                      {paymentReceiptTitle}
                    </span>
                  }
                >
                  {transactionForm[EInputType.PAYMENT_RECEIPT].includes(
                    ".pdf",
                  ) ? (
                    <a
                      rel="noreferrer"
                      className={styles["pdf-preview"]}
                      href={transactionForm[EInputType.PAYMENT_RECEIPT]}
                      target="_blank"
                      data-testid="pdf-preview"
                    >
                      <Document
                        file={transactionForm[EInputType.PAYMENT_RECEIPT]}
                      >
                        <Page pageNumber={1} />
                      </Document>
                    </a>
                  ) : (
                    <a
                      href={transactionForm[EInputType.PAYMENT_RECEIPT]}
                      rel="noreferrer"
                      target="_blank"
                      data-testid="image-preview"
                    >
                      <img
                        width={168}
                        height={160}
                        className={styles.paymentReceiptImg}
                        src={transactionForm[EInputType.PAYMENT_RECEIPT]}
                        alt="payment-receipt"
                      />
                    </a>
                  )}
                </Form.Item>
              </li>
            )}
          {visibleInput?.[EInputType.REJECTION_REASON] && (
            <li>
              <Form.Item
                name={EInputType.REJECTION_REASON}
                label={
                  <span className={styles["label-wrapper"]}>
                    Rejection Reason {haveValue(EInputType.REJECTION_REASON)}
                  </span>
                }
              >
                <TextArea
                  onChange={(e) =>
                    setValue(EInputType.REJECTION_REASON)(e.target.value)
                  }
                  rows={2}
                  className={classNames(styles.note, {
                    [styles["error-border"]]: requiredAndEmpty(
                      EInputType.REJECTION_REASON,
                    ),
                  })}
                  disabled
                  defaultValue={defaultValue[EInputType.REJECTION_REASON]}
                />
              </Form.Item>
            </li>
          )}
        </List>
        {bannerPosition === EBannerPosition.BOTTOM && (
          <InformationBanner position={bannerPosition} />
        )}
      </div>
    </Section>
  );
};

export * from "./config";

export default TransactionDetail;
