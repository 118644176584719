import React from "react";
import { Col, Row } from "antd";
import PropTypes from "prop-types";

export const FilterItemWrapper = ({ title, appendClassName, children }) => {
  return (
    <Row gutter={16} className={`filter--${appendClassName}`}>
      <Col span={6}>
        <h3>{title.toUpperCase()}</h3>
      </Col>
      {children}
    </Row>
  );
};

FilterItemWrapper.propTypes = {
  children: PropTypes.any,
  title: PropTypes.string,
  appendClassName: PropTypes.string,
};
