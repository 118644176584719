import * as React from "react";
import { Modal, Row, Col } from "antd";
import { modalCloseIcon } from "assets/img";
import { addCardTypeConfig, AddCardTypes } from "Views/Card/Add/Config";

import "Views/Card/Add/TypeSelectionModal/index.scss";

interface TypeSelectionModalProps {
  addCardTypes: addCardTypeConfig;
  cardTitle: string;
  onClose: () => void;
  onClick: (type: AddCardTypes) => void;
}

const TypeSelectionModal = ({ addCardTypes, cardTitle, onClose, onClick }: TypeSelectionModalProps) => {
  return (
    <Modal
      visible
      title={cardTitle}
      footer={null}
      maskClosable={false}
      className="add-card-type-selection-modal"
      width={356}
      onCancel={onClose}
      closeIcon={<img src={modalCloseIcon} alt="close icon" />}
      centered
    >
      <Row gutter={[4, 0]} justify="space-around">
        {addCardTypes?.map((cardType) => (
          <Col className="add-card-type-selection-modal__type-container" key={cardType.cardType}>
            <button
              onClick={() => onClick(cardType.cardType)}
              className="add-card-type-selection-modal__type-btn"
              data-testid={cardType.cardType}
            >
              <img src={cardType.icon} alt={`${cardType.label} icon`} />
            </button>
            <div className="add-card-type-selection-modal__type-label">{cardType.label}</div>
          </Col>
        ))}
      </Row>
    </Modal>
  );
};

export default TypeSelectionModal;
