import React, { useContext } from "react";
import { Skeleton } from "antd";

import { TrxnShimmerContext } from "Views/Transaction/Context";
import { ITrxnShimmerContext } from "Views/Transaction/@types";

import styles from "./InputCell.module.scss";

export const InputCell = ({ children, active = false }: { children: JSX.Element; active?: boolean }) => {
  const { loading } = useContext<ITrxnShimmerContext>(TrxnShimmerContext);

  const loader: boolean = loading || active;

  return loader ? <Skeleton.Input size="small" active={loader} className={styles.inputCell} /> : children;
};
