import { APPROVAL_PATHS } from "Route/Approval/paths";

import { ICRPage } from "Views/Reimbursement/@types";
import { REIMBURSEMENTS_PAGE_ANALYTICS } from "Views/Reimbursement/Analytics";

/**
 * The title of the Reimbursements page.
 */
export const PAGE_TITLE = "Reimbursements";

/**
 * The default table size for pagination.
 */
export const TABLE_SIZE = 25;

/**
 * Represents the key and title for Company reimbursements.
 */
export const COMPANY_REIMBURSEMENTS = {
  KEY: "company",
  TITLE: `Company`,
};

/**
 * Represents the key and title for Personal reimbursements.
 */
export const PERSONAL_REIMBURSEMENTS = {
  KEY: "personal",
  TITLE: `Personal`,
};

/**
 * Operations that can be performed on Reimbursement pages. Used as url params to match routes.
 */
export const REIMBURSEMENT_PAGE_OPERATIONS = {
  CREATE: "new",
  UPDATE: "edit",
  DELETE: "remove",
  READ: "view",
  EXPORT: "export",
  SETTLEMENTS: "settlements",
  PAYOUTS: "payouts",
  MODAL: "modal",
};

/**
 * Options for different modals related to Reimbursements. Used as url params to match routes.
 */
export const REIMBURSEMENT_MODAL_OPTIONS = {
  METHOD_VALIDATION: "methodValidation",
  MAKE_PAYMENT: "makePayment",
  MARK_AS_SETTLED: "markAsSettled",
};

/**
 * Options for different query params related to Reimbursements Page.
 */
export enum REIMBURSEMENT_QUERY_PARAMS {
  TEAM_IDS = "teamIds",
  MERCHANT_IDS = "merchantIds",
  REQUESTER_IDS = "requesterIds",
  STATUSES = "statuses",
  PAGE = "page",
  START_DATE = "startDate",
  END_DATE = "endDate",
  EXPORT_TYPE = "exportType",
  EXPENSE_START_DATE = "expenseStartDate",
  EXPENSE_END_DATE = "expenseEndDate",
  PAYOUT_USER = "payoutUser",
  PAYOUT_VALUE = "payoutValue",
  BULK_IDS = "bulkIds",
  BULK_SETTLEMENT = "bulkSettlement",
}

/**
 * Represents various settlement statuses for Reimbursements.
 */
export const REIMBURSEMENT_SETTLEMENT_STATUSES = {
  WAITING_FOR_APPROVAL: { id: "waiting_approval", label: "Pending Approval" },
  SENDING_PAYMENT: { id: "completed", label: "Sending Payment" },
  APPROVED: { id: "approved", label: "Approved" },
  SETTLED_WITH_SPENMO: { id: "settled", label: "Paid - Spenmo" },
  SETTLED_WITH_OTHERS: { id: "settled_non_payout", label: "Paid - Others" },
  REVERSED: { id: "reversed", label: "Reversed" },
  REJECTED: { id: "rejected", label: "Rejected" },
  UNPAID_FOR_PAYOUTS_VIEW: { id: "unpaid", label: "Unpaid" }, // Only for Internal Use on Payouts/Settlement View, not an actual status to be shown to clients
  SETTLED_FOR_SETTLEMENT_VIEW: { id: "paid", label: "Paid" }, // Only for Internal Use on Payouts/Settlement View, not an actual status to be shown to clients
};

/**
 * Options for settlement statuses for Reimbursements Settlement View.
 */
export const REIMBURSEMENT_SETTLEMENT_OPTIONS = [
  REIMBURSEMENT_SETTLEMENT_STATUSES.APPROVED,
  REIMBURSEMENT_SETTLEMENT_STATUSES.SETTLED_FOR_SETTLEMENT_VIEW,
];

/**
 * Options for settlement statuses for Reimbursements Settlement View.
 */
export const REIMBURSEMENT_PAYOUTS_OPTIONS = [
  REIMBURSEMENT_SETTLEMENT_STATUSES.UNPAID_FOR_PAYOUTS_VIEW,
  REIMBURSEMENT_SETTLEMENT_STATUSES.SETTLED_FOR_SETTLEMENT_VIEW,
];

/**
 * Represents various statuses for Reimbursements. To be used in filters.
 */
export const REIMBURSEMENT_STATUSES = [
  REIMBURSEMENT_SETTLEMENT_STATUSES.WAITING_FOR_APPROVAL,
  REIMBURSEMENT_SETTLEMENT_STATUSES.APPROVED,
  REIMBURSEMENT_SETTLEMENT_STATUSES.SENDING_PAYMENT,
  REIMBURSEMENT_SETTLEMENT_STATUSES.SETTLED_WITH_SPENMO,
  REIMBURSEMENT_SETTLEMENT_STATUSES.SETTLED_WITH_OTHERS,
  REIMBURSEMENT_SETTLEMENT_STATUSES.REVERSED,
  REIMBURSEMENT_SETTLEMENT_STATUSES.REJECTED,
];

/**
 * Actions that can be included on Reimbursement Page Tabs.
 */
export const REIMBURSEMENT_PAGE_ACTIONS = {
  EXPENSE_DATE: "expense_date",
  PAID_DATE: "paid_date",
  BUDGET: "budget",
  REQUESTER: "requester",
  MERCHANT: "merchant",
  STATUS: "status",
  EXPORT: "export",
  NEW_REIMBURSEMENT: "new_reimbursement",
  SETTLEMENT_VIEW: "settlement_view",
  SETTLEMENT_ACTION_CHIPS: "settlement_action_chips",
  PAYOUTS_VIEW: "payouts_view",
  PAYOUTS_ACTION_CHIPS: "payouts_action_chips",
  RIGHT_BORDER: "right_side_border",
};

/**
 * Columns for displaying on Reimbursement Page Tabs.
 */
export const REIMBURSEMENT_PAGE_COLUMNS = {
  CREATED_AT: "created_at",
  EXPENSE_DATE: "request_time",
  BUDGET: "team_name",
  REQUESTER: "user_name",
  MERCHANT: "merchant",
  AMOUNT: "amount",
  BATCH_PAYOUT: "batch_payout_name",
  STATUS: "final_status",
};

/**
 * Views available for Reimbursements.
 */
export const REIMBURSEMENT_VIEWS: {
  REIMBURSEMENTS: ICRPage;
  COMPANY_REIMBURSEMENTS: ICRPage;
  PERSONAL_REIMBURSEMENTS: ICRPage;
} = {
  /**
   * View for rendering the page Reimbursements. Gets redirected to Company tab link upon visting.
   */
  REIMBURSEMENTS: {
    tabKey: "/",
    title: PAGE_TITLE,
    eventSource: REIMBURSEMENTS_PAGE_ANALYTICS.REIMBURSEMENT,
    pathname: APPROVAL_PATHS.REIMBURSEMENTS,
    link: APPROVAL_PATHS.REIMBURSEMENTS,
  },
  /**
   * View for Company Reimbursements.
   */
  COMPANY_REIMBURSEMENTS: {
    tabKey: COMPANY_REIMBURSEMENTS.KEY,
    title: COMPANY_REIMBURSEMENTS.TITLE,
    eventSource: REIMBURSEMENTS_PAGE_ANALYTICS.COMPANY_REIMBURSEMENT,
    pathname: `${APPROVAL_PATHS.REIMBURSEMENTS}/${COMPANY_REIMBURSEMENTS.KEY}/:operation?/:id?/:secondaryOperation?/:secondaryId?/:tertiaryOperation?/:tertiaryId?`,
    link: `${APPROVAL_PATHS.REIMBURSEMENTS}/${COMPANY_REIMBURSEMENTS.KEY}`,
    description: "Manage and track all submitted reimbursements in your organization",
    emptyState: "No reimbursements found in your company",
    columns: [
      REIMBURSEMENT_PAGE_COLUMNS.CREATED_AT,
      REIMBURSEMENT_PAGE_COLUMNS.EXPENSE_DATE,
      REIMBURSEMENT_PAGE_COLUMNS.BUDGET,
      REIMBURSEMENT_PAGE_COLUMNS.REQUESTER,
      REIMBURSEMENT_PAGE_COLUMNS.MERCHANT,
      REIMBURSEMENT_PAGE_COLUMNS.AMOUNT,
      REIMBURSEMENT_PAGE_COLUMNS.BATCH_PAYOUT,
      REIMBURSEMENT_PAGE_COLUMNS.STATUS,
    ],
    actions: [
      REIMBURSEMENT_PAGE_ACTIONS.EXPENSE_DATE,
      REIMBURSEMENT_PAGE_ACTIONS.BUDGET,
      REIMBURSEMENT_PAGE_ACTIONS.REQUESTER,
      REIMBURSEMENT_PAGE_ACTIONS.MERCHANT,
      REIMBURSEMENT_PAGE_ACTIONS.STATUS,
      REIMBURSEMENT_PAGE_ACTIONS.SETTLEMENT_ACTION_CHIPS,
      REIMBURSEMENT_PAGE_ACTIONS.SETTLEMENT_VIEW,
      REIMBURSEMENT_PAGE_ACTIONS.RIGHT_BORDER,
      REIMBURSEMENT_PAGE_ACTIONS.EXPORT,
    ],
  },
  /**
   * View for Personal Reimbursements.
   */
  PERSONAL_REIMBURSEMENTS: {
    tabKey: PERSONAL_REIMBURSEMENTS.KEY,
    title: PERSONAL_REIMBURSEMENTS.TITLE,
    eventSource: REIMBURSEMENTS_PAGE_ANALYTICS.PERSONAL_REIMBURSEMENT,
    pathname: `${APPROVAL_PATHS.REIMBURSEMENTS}/${PERSONAL_REIMBURSEMENTS.KEY}/:operation?/:id?/:secondaryOperation?/:secondaryId?`,
    link: `${APPROVAL_PATHS.REIMBURSEMENTS}/${PERSONAL_REIMBURSEMENTS.KEY}`,
    description: "Submit or edit reimbursements to make an expense claim for yourself",
    emptyState: "You do not have any submitted reimbursements",
    columns: [
      REIMBURSEMENT_PAGE_COLUMNS.CREATED_AT,
      REIMBURSEMENT_PAGE_COLUMNS.EXPENSE_DATE,
      REIMBURSEMENT_PAGE_COLUMNS.BUDGET,
      REIMBURSEMENT_PAGE_COLUMNS.MERCHANT,
      REIMBURSEMENT_PAGE_COLUMNS.AMOUNT,
      REIMBURSEMENT_PAGE_COLUMNS.BATCH_PAYOUT,
      REIMBURSEMENT_PAGE_COLUMNS.STATUS,
    ],
    actions: [
      REIMBURSEMENT_PAGE_ACTIONS.EXPENSE_DATE,
      REIMBURSEMENT_PAGE_ACTIONS.BUDGET,
      REIMBURSEMENT_PAGE_ACTIONS.MERCHANT,
      REIMBURSEMENT_PAGE_ACTIONS.STATUS,
      REIMBURSEMENT_PAGE_ACTIONS.NEW_REIMBURSEMENT,
    ],
  },
};

export const PAGE_TABS = [REIMBURSEMENT_VIEWS.COMPANY_REIMBURSEMENTS, REIMBURSEMENT_VIEWS.PERSONAL_REIMBURSEMENTS];
