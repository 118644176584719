import { IFilter, ISearchFilters } from "Views/Card/List/Filters/Types";

export interface ICardFilterContext {
  filter: IFilter;
  searchFilters: ISearchFilters;
  currentPage: number;
  setCurrentPage: (payload: number) => void;
  setFilter: (payload: IFilter) => void;
  clearFilter: () => void;
}

export const initialFilter: IFilter = {
  search_filters: {
    card_search: "",
  },
  team_id: [],
  ordering: {},
};

export const initialCardFilterContext = {
  filter: initialFilter,
  searchFilters: initialFilter.search_filters,
  currentPage: 0,
  setFilter: () => null,
  clearFilter: () => null,
  setCurrentPage: () => null,
};
