import React, { useEffect, useState } from "react";

import { Input, AddOutline, Tooltip, Typography } from "@spenmo/splice";
import { Field, Select } from "Views/SubscriptionManagement/Create/Components";

import { FORM_TYPE, FORM_NAMES } from "Views/SubscriptionManagement/Constants";
import { VendorImage } from "Views/SubscriptionManagement/Components/VendorImage";
import { useVendors } from "Views/SubscriptionManagement/Create/Hooks/useVendors";
import { useAddNewVendor } from "Views/SubscriptionManagement/Create/Hooks/useAddNewVendor";
import { useFormContext } from "Views/SubscriptionManagement/Create/Context";

import styles from "Views/SubscriptionManagement/Create/styles.module.scss";

export interface IVendorProps {
  formType: FORM_TYPE;
}

const WithTooltip = ({ children, isEdit }: { children: React.ReactElement; isEdit: boolean }) => {
  if (isEdit) {
    return (
      <div className={styles.vendorTooltip}>
        <Tooltip
          placement="bottom-start"
          title="Cannot change the vendor for this subscription. Kindly create a new subscription for new vendors."
        >
          {children}
        </Tooltip>
      </div>
    );
  }
  return children;
};

export const Vendor = ({ formType }: IVendorProps) => {
  const { setValue, control, watch } = useFormContext();
  const selectedVendor = watch(FORM_NAMES.VENDOR_ID);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [open, setOpen] = useState(false);
  const { data: vendors, isLoading, mutate } = useVendors();
  const { trigger, reset } = useAddNewVendor();

  useEffect(() => {
    if (selectedVendor && vendors?.length) {
      const vendorName = vendors.find((vendor) => vendor.id === selectedVendor)?.name || "";
      setValue(FORM_NAMES.VENDOR_NAME, vendorName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVendor, vendors]);

  const addAndSelectNewVendorToList = (vendorId: string) => {
    if (vendorId) {
      mutate(
        [
          ...vendors,
          {
            name: searchKeyword,
            id: vendorId,
          },
        ],
        { revalidate: false }
      );
    }
    setSearchKeyword("");
    setValue(FORM_NAMES.VENDOR_ID, vendorId);
    setOpen(false);
  };

  const addNewVendor = async () => {
    reset();
    await trigger(
      {
        name: searchKeyword,
      },
      {
        onSuccess: addAndSelectNewVendorToList,
      }
    );
  };

  const vendorOptions = vendors.map((vendor) => ({
    id: vendor.id,
    value: vendor.id,
    label: vendor.name,
    children: (
      <div className={styles.vendor}>
        <VendorImage vendor={vendor.name} width={18} height={20} />
        <Typography variant="body-content" size="s">
          {vendor.name}
        </Typography>
      </div>
    ),
  }));

  const NotFoundContent = () => {
    return (
      searchKeyword && (
        <div className={styles.vendorNotFound} onClick={addNewVendor}>
          <AddOutline size="16" iconColor="var(--text-link-default)" />
          Add "{searchKeyword}"
        </div>
      )
    );
  };

  const isEditForm = formType === FORM_TYPE.EDIT;

  return (
    <WithTooltip isEdit={isEditForm}>
      <Field>
        <Input.Label>Vendor</Input.Label>

        <Select
          loading={isLoading}
          disabled={isEditForm}
          name={FORM_NAMES.VENDOR_ID}
          control={control}
          open={open}
          onDropdownVisibleChange={setOpen}
          placeholder="Select vendor"
          showSearch
          value={selectedVendor}
          onSearch={setSearchKeyword}
          options={vendorOptions}
          notFoundContent={<NotFoundContent />}
          searchValue={searchKeyword}
          optionFilterProp="label"
          requiredErrorMessage="Select a vendor"
        />
      </Field>
    </WithTooltip>
  );
};
