import React, { FocusEvent, useMemo, useState } from "react";
import {
  ArrowLeft,
  Banner,
  Button,
  Card,
  InfoFilled,
  Typography,
} from "@spenmo/splice";
import CurrencyInput from "react-currency-input-field";
import cn from "classnames";

import { postData } from "API/Client";

import { appNotification } from "Modules/appNotification/appNotification";

import {
  API_URL,
  DEFAULT_ERROR_MESSAGE,
  SG_FLAG_URL,
} from "Views/Bills/V2/constants";
import { transformQRCode } from "Views/Bills/V2/PaymentRunPreview/helper";
import { currencyFormatterV2 } from "utility";

import { PaynowProps, QRData } from "Views/Bills/V2/PaymentRunPreview/type";
import styles from "./PaymentMethod.module.scss";

// hardcoded for paynow since paynow is only for SG dashboard
const paynowCurrency = "SGD";

const Paynow = (props: PaynowProps) => {
  const { onClickAutoPay, isAutoPayLoading, isAutoPayDisabled } = props;
  const [qrData, setQRData] = useState<QRData>();
  const [amount, setAmount] = useState<string>();
  const [qrLoading, setQRLoading] = useState(false);

  const isQRCodeGenerated = Boolean(qrData);

  /**
   * handle clicking for generating QR and Auto pay
   */
  const handleClickBtn = () => {
    // click auto pay
    if (isQRCodeGenerated) {
      onClickAutoPay();
      return;
    }

    setQRLoading(true);

    postData(API_URL.generateQR, {
      amount,
    })
      .then((res) => {
        const { failure_code, data } = res.data;
        if (failure_code !== 0) {
          appNotification.error({
            message: DEFAULT_ERROR_MESSAGE,
          });
          return;
        }

        setQRData(data);
      })
      .catch(() => {
        appNotification.error({
          message: DEFAULT_ERROR_MESSAGE,
        });
      })
      .finally(() => {
        setQRLoading(false);
      });
  };

  const handleOnBlurInput = (e: FocusEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value) {
      const amount = currencyFormatterV2(
        value.replace(/[,]/gi, ""),
        paynowCurrency,
        false,
        2,
      ).replace(/[,]/gi, "");

      setAmount(amount);
    }
  };

  const renderPayNowContent = () => {
    if (!isQRCodeGenerated) {
      return (
        <div>
          <label htmlFor="amount">
            <Typography
              variant="body-content"
              size="s"
              className={styles.bodyColor}
            >
              Enter your amount
            </Typography>
          </label>
          <div className={styles.paynowInput}>
            <div className={styles.flagContainer}>
              <img
                className={styles.flagImg}
                src={SG_FLAG_URL}
                alt="singapore"
                width={24}
                height={20}
              />
              <Typography tag="p" variant="body-content" size="s" weight={600}>
                {paynowCurrency}
              </Typography>
            </div>
            <CurrencyInput
              className={styles.amount}
              id="amount"
              name="amount"
              value={amount}
              allowNegativeValue={false}
              placeholder={currencyFormatterV2(1000, paynowCurrency, false, 2)}
              step={Number(amount)}
              intlConfig={{ locale: paynowCurrency }}
              decimalsLimit={2}
              onValueChange={setAmount}
              onBlur={handleOnBlurInput}
            />
          </div>
        </div>
      );
    }

    return (
      <>
        <Button
          className={styles.topUpBtn}
          variant="tertiary"
          size="s"
          icon={() => <ArrowLeft iconColor="var(--icon-default)" size="16" />}
          onClick={() => setQRData(undefined)}
        >
          <Typography
            variant="body-content"
            size="s"
            className={cn(styles.bodyColor)}
          >
            Top up {currencyFormatterV2(amount, paynowCurrency, true, 2)}
          </Typography>
        </Button>
        <Card elevation="ground" className={styles.qrCodeCard} radius="m">
          <img
            src={transformQRCode(qrData.qr_code)}
            alt="Pay Now QR Code"
            width={200}
            height={200}
          />
        </Card>
        <div className={styles.banner}>
          <Banner
            description={
              <Typography variant="body-content" size="s">
                After transferring funds, refresh your Spenmo Wallet Balance. If
                the money doesn't appear instantly, use the button below to
                process the payment automatically once it's in your account.
              </Typography>
            }
            title=""
            icon={InfoFilled}
            variant="info"
          />
        </div>
      </>
    );
  };

  return (
    <>
      {renderPayNowContent()}
      <Button
        className={styles.autoPayBtn}
        size="s"
        variant="primary"
        onClick={handleClickBtn}
        loading={qrLoading || isAutoPayLoading}
        disabled={!amount || isAutoPayDisabled}
      >
        {isQRCodeGenerated ? "Auto-Pay on Fund Arrival" : "Generate QR Code"}
      </Button>
    </>
  );
};

export default Paynow;
