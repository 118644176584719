import React, { ReactElement, useContext } from "react";
import PropTypes from "prop-types";

import { AuthPageContext } from "Views/Auth";
import "./styles.scss";

export const AuthBoxRedirect = ({ children }: { children: ReactElement }): ReactElement => {
  const { loading } = useContext(AuthPageContext);

  return !loading && <div className="auth-box__redirect">{children}</div>;
};

AuthBoxRedirect.propTypes = {
  children: PropTypes.any,
};
