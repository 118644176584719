export const check = require("./ic_check.svg");
export const cross = require("./ic_cross.svg");
export const singleAsterisk = require("./single-asterisk.svg");
export const doubleAsterisk = require("./double-asterisk.svg");
export const checkSingleAsterisk = require("./check-single-asterisk.svg");
export const checkDoubleAsterisk = require("./check-double-asterisk.svg");
export const external = require("./ic_external.svg");
export const info = require("./ic_info.svg");
export const infoBlue = require("./ic_info_blue.svg");
export const infoBrown = require("./ic_info_brown.svg");
export const infoOrange = require("./info_orange.svg");
