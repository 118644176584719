/**
 * @fileoverview This module provides a utility function that returns the configuration for
 * a utility menu in the sidebar. The menu items include settings, help and support, and log out.
 *
 * Each menu item is an object that follows the ISidebarMenuConfig shape, which
 * specifies the properties required for rendering a menu item.
 *
 * @author Sushmitha, Bakshi
 * @created 25 Aug 2023
 * @lastModified 30 Aug 2023
 */
import { useContext } from "react";
import * as Icons from "@spenmo/splice";

import { COMMON_PATHS } from "Route/Common/paths";

import useLogout from "utility/useLogout";

import * as ROUTES from "constants/Routes.constants";

import { SUPPORT_STATUS } from "Views/Navigation/Constants";
import { ISidebarMenuConfig } from "Views/Navigation/@types";
import { SpenmoSupportContext } from "Views/SpenmoSupport/common";

/**
 * `useUtilityMenu` function returns an array of sidebar menu configurations that are specific
 * to utility functions like settings, help and support, and log out. The returned items
 * can be directly used to render sidebar menu items.
 *
 * Only items with the `enabled` property set to true will be included in the final list.
 *
 * @returns {ISidebarMenuConfig[]} An array of sidebar menu configurations.
 */
export const useUtilityMenu = (): ISidebarMenuConfig[] => {
  const logout = useLogout();

  const { toggleSupport, showSupportPopup, showSupportChat, supportStatus }: any = useContext(SpenmoSupportContext);

  return [
    {
      id: "UTILITY_MENU_01",
      permission: true,
      enabled: true,
      props: {
        menu: {
          label: "Settings",
          prefixIcon: Icons.GearOutline,
          prefixActiveIcon: Icons.GearFilled,
        },
        history: COMMON_PATHS.SETTINGS,
      },
    } as ISidebarMenuConfig,
    {
      id: "UTILITY_MENU_02",
      enabled: true,
      permission: true,
      props: {
        menu: {
          label: "Help and Support",
          prefixIcon: Icons.HelpOutline,
          prefixActiveIcon: Icons.HelpFilled,
          badgeType: supportStatus === SUPPORT_STATUS.ONLINE ? "label" : undefined,
          onClick: () => toggleSupport(),
          badgeLabel: supportStatus,
          isSelected: showSupportPopup || showSupportChat,
        },
      },
    } as ISidebarMenuConfig,
    {
      id: "UTILITY_MENU_03",
      enabled: true,
      permission: true,
      props: {
        menu: {
          label: "Log Out",
          prefixIcon: Icons.LogOutOutline,
          prefixActiveIcon: Icons.LogOutFilled,
          onClick: () => logout(),
        },
        history: ROUTES.APPROVAL_REQUEST_CENTER,
      },
    } as ISidebarMenuConfig,
  ].filter((item) => item.enabled && item.permission);
};
