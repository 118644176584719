export enum BUTTON_TYPES {
  PRIMARY = "primary",
  SECONDARY = "secondary",
  TEXT = "text",
}

export enum BUTTON_SIZES {
  EXTRA_LARGE = "extraLarge",
  LARGE = "large",
  MEDIUM = "medium",
  SMALL = "small",
}
export interface TitleButtonProps {
  loading?: boolean;
  disabled?: boolean;
  children?: React.ReactNode;
  size?: BUTTON_SIZES;
  type?: BUTTON_TYPES;
  text?: string;
  onClick?: () => void;
}
export interface IconButtonProps extends TitleButtonProps {
  src: string | object;
  suffix?: boolean;
}
export interface ActionButtonProps extends TitleButtonProps {
  danger?: boolean;
}
