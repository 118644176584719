import React from "react";
import classNames from "classnames";
import { IColumnData } from "Modules/DS/DataTable";
import { intlDateTimeFormat, dateTimeWithTimezone, currencyFormatterV2 } from "utility";
import { addReceipt } from "assets/v1.1/icons/Line";
import AsyncImageCarousel from "Views/Bills/ImportBillFromXero/AsyncImageCarousel";
import { IBill } from "Views/Bills/ImportBillFromXero/index";
import styles from "Views/Bills/ImportBillFromXero/styles.module.scss";
import { isCategoryMappingIssue, isTaxMappingIssue } from "Views/Bills/ImportBillFromXero/helper";
import mapBillStyles from "Views/Bills/ImportBillFromXero/ImportBillsToDraftsModal/styles.module.scss";
import { Tooltip, TooltipArrowAlignmentEnum, TooltipPositionEnum } from "Modules/DS/Tooltip";

const charCount = 18;
const isFieldValueBig = (val: string) => val?.length > charCount;

const getTooltipText = (bill, originalValue, mappedValue, isCategory) => {
  const multipleLineItemsText =
    bill?.lineItems?.length > 1 &&
    `Set to “${mappedValue}” as multiple ${
      isCategory ? "categories" : "taxes"
    } or line items are not supported in Spenmo`;
  const matchedLabelText = `Set to “${mappedValue}” as no match for “${originalValue}” is found on Spenmo`;

  const isBillTaxExclusive = !isCategory && bill?.lineAmountTypes?.toLowerCase() === "exclusive";
  const taxExclusiveText = `Set to “${mappedValue}” as the amounts in this bill has been marked as “Tax Exclusive” in Xero`;

  return multipleLineItemsText || (isBillTaxExclusive ? taxExclusiveText : matchedLabelText);
};

const categoryAndTaxComponent = (bill: IBill, isCategory?: boolean) => {
  const originalFieldValue = isCategory
    ? bill?.originalCategory?.category_name || bill?.mappedCategory?.category_name
    : `${bill?.originalTax?.tax_name || bill?.mappedTax?.tax_name} (${
        bill?.originalTax?.tax_rate || bill?.mappedTax?.tax_rate
      }%)`;
  const mappedFieldValue = isCategory
    ? bill?.mappedCategory?.category_name
    : `${bill?.mappedTax?.tax_name} (${bill?.mappedTax?.tax_rate}%)`;
  const isMappingIssuePresent = isCategory ? isCategoryMappingIssue(bill) : isTaxMappingIssue(bill);
  const isMappedTaxNoTax = bill?.mappedTax?.tax_name?.toLowerCase()?.trim() === "no tax";
  const isDefaultCategory = bill?.mappedCategory?.category_name?.toLowerCase()?.trim() === "default";

  const mappedTooltipText = getTooltipText(bill, originalFieldValue, mappedFieldValue, isCategory);

  return (
    <>
      <div
        className={classNames({
          [mapBillStyles.tooltipPosition]: isMappingIssuePresent && isFieldValueBig(originalFieldValue),
          [mapBillStyles.hideToolTip]: !isFieldValueBig(originalFieldValue) || !isMappingIssuePresent,
        })}
      >
        <Tooltip
          text={originalFieldValue}
          position={TooltipPositionEnum.BOTTOM}
          alignArrow={TooltipArrowAlignmentEnum.CENTER}
        >
          <p className={classNames({ [mapBillStyles.orginialFieldStriked]: isMappingIssuePresent })}>
            {originalFieldValue}
          </p>
        </Tooltip>
      </div>
      {isMappingIssuePresent ? (
        <div
          className={classNames({
            [mapBillStyles.tooltipPosition]: isCategory ? isDefaultCategory : isMappedTaxNoTax,
            [mapBillStyles.hideToolTip]: !(isCategory ? isDefaultCategory : isMappedTaxNoTax) && !mappedTooltipText,
          })}
        >
          <Tooltip
            text={mappedTooltipText}
            position={TooltipPositionEnum.BOTTOM}
            alignArrow={TooltipArrowAlignmentEnum.CENTER}
          >
            <p className={mapBillStyles.mappedField}>{mappedFieldValue}</p>
          </Tooltip>
        </div>
      ) : null}
    </>
  );
};

export const columnData = (isImportBillsToDraftsModal?: boolean): IColumnData<IBill>[] => {
  return [
    {
      id: "IMPORT-BILL-FROM-XERO-COL-001",
      enabled: !isImportBillsToDraftsModal,
      column: {
        title: "Created Date",
        dataIndex: "date",
        key: "date",
        width: "130px",
        render(date) {
          const formattedDate = dateTimeWithTimezone(date);
          return (
            <div>
              <p className={styles.body}>{intlDateTimeFormat(new Date(formattedDate))}</p>
            </div>
          );
        },
      },
    },
    {
      id: "IMPORT-BILL-FROM-XERO-COL-002",
      enabled: !isImportBillsToDraftsModal,
      column: {
        title: "Due Date",
        dataIndex: "dueDate",
        key: "dueDate",
        width: "130px",
        render(dueDate) {
          const formattedDate = dateTimeWithTimezone(dueDate);
          return <p className={styles.body}>{intlDateTimeFormat(new Date(formattedDate))}</p>;
        },
      },
    },
    {
      id: "IMPORT-BILL-FROM-XERO-COL-003",
      enabled: true,
      column: {
        title: "Reference",
        dataIndex: "reference",
        key: "reference",
        width: isImportBillsToDraftsModal ? "10.25rem" : "200px",
        render(reference, bill) {
          return (
            <a
              href={bill.invoiceLink}
              target="_blank"
              rel="noreferrer"
              className={classNames(styles.body, styles.link)}
            >
              {reference}
            </a>
          );
        },
      },
    },
    {
      id: "IMPORT-BILL-FROM-XERO-COL-003",
      enabled: true,
      column: {
        title: "Recipient",
        dataIndex: "recipient",
        key: "recipient",
        width: isImportBillsToDraftsModal ? "11.25rem" : "165px",
        render(recipient) {
          return (
            <div className={styles.recipient}>
              {/* Will be used when connecting to vendor management system */}
              {/* <img src={toasterCircleTick} alt="availiable on vendor management system" /> */}
              <p className={styles.body}>{recipient}</p>
            </div>
          );
        },
      },
    },
    {
      id: "IMPORT-BILL-FROM-XERO-COL-004",
      enabled: true,
      column: {
        title: "Amount Due",
        dataIndex: "dueTotal",
        key: "dueTotal",
        width: isImportBillsToDraftsModal ? "10rem" : "150px",
        render(dueTotal, bill: IBill) {
          return (
            <p className={classNames(styles.body, styles.money)}>{currencyFormatterV2(dueTotal, bill.currencyCode)}</p>
          );
        },
      },
    },
    {
      id: "IMPORT-BILL-FROM-XERO-COL-005",
      enabled: !isImportBillsToDraftsModal,
      column: {
        title: "Total Amount",
        dataIndex: "total",
        key: "total",
        width: "150px",
        render(total, bill: IBill) {
          return (
            <p className={classNames(styles.body, styles.money)}>{currencyFormatterV2(total, bill.currencyCode)}</p>
          );
        },
      },
    },
    {
      id: "IMPORT-BILL-FROM-XERO-COL-006",
      enabled: !isImportBillsToDraftsModal,
      column: {
        title: "Attachment",
        dataIndex: "attachments",
        key: "attachments",
        width: "150px",
        render(attachments, bill: IBill, index) {
          return (
            <div className={styles.attachments}>
              {bill.hasAttachments ? (
                <AsyncImageCarousel className={styles.carousel} invoiceId={bill.invoiceId} hasAttachment />
              ) : (
                <img src={addReceipt} alt="no attachments from xero" />
              )}
            </div>
          );
        },
      },
    },
    {
      id: "IMPORT-BILL-FROM-XERO-COL-007",
      enabled: isImportBillsToDraftsModal,
      column: {
        title: "Category",
        dataIndex: "originalCategory",
        key: "originalCategory",
        width: "12.5rem",
        render(originalCategory, bill: IBill) {
          return categoryAndTaxComponent(bill, true);
        },
      },
    },
    {
      id: "IMPORT-BILL-FROM-XERO-COL-008",
      enabled: isImportBillsToDraftsModal,
      column: {
        title: "Tax",
        dataIndex: "originalTax",
        key: "originalTax",
        width: "10rem",
        render(originalTax, bill: IBill) {
          return categoryAndTaxComponent(bill);
        },
      },
    },
  ].filter((col) => col.enabled);
};
