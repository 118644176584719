import React from "react";

import { dateTimeFormatter } from "utility";

import { IColumnData } from "Modules/DS/DataTable";

import { InputCell } from "Views/Transaction/Shimmer";
import { ALL_TRXNS_COLUMNS } from "Views/Transaction/Constants";
import { CellWithSubtitle } from "Views/Transaction/List";
import { IGetTrxnResponse, GET_TRXN_RESPONSE_PARAMS } from "Views/Transaction/@types";
import { InfoOutline, Tooltip, Typography } from "@spenmo/splice";

import styles from "Views/Transaction/All/Columns/Columns.module.scss";

export const CreatedCell = ({ children }: { children: string }) => {
  const title = dateTimeFormatter(children).date + " ,";
  const subtitle = dateTimeFormatter(children).time;

  return (
    <InputCell>
      <CellWithSubtitle title={title} subtitle={subtitle} />
    </InputCell>
  );
};

export const created = (): IColumnData<IGetTrxnResponse>["column"] => ({
  title: (
    <div className={styles.withTooltip}>
      <Typography variant="body-content" size="s" tag="p" weight={600}>
        {ALL_TRXNS_COLUMNS.DATE_N_TIME}
      </Typography>
      <Tooltip title={`This is when the payment\n was processed by Spenmo\n or marked as paid.`} placement="top">
        <InfoOutline className={styles.icon} size="16" iconColor="var(--icon-default)" />
      </Tooltip>
    </div>
  ),
  width: 144,
  key: GET_TRXN_RESPONSE_PARAMS.CREATED_AT,
  dataIndex: GET_TRXN_RESPONSE_PARAMS.CREATED_AT,
  render: (value: string): React.ReactNode => <CreatedCell>{value}</CreatedCell>,
});
