import React, { useContext, useState } from "react";

import Menu from "Modules/DS/Menu";
import Select, { IMultipleSelectDropdown } from "Modules/DS/Select";

import { IMultipleMoreFilter } from "Views/Analytics/@types";

import { ANALYTICS_MORE_FILTER_ID } from "./index";
import AnalyticsContext from "Views/Analytics/Context/context";

export const MultipleSelect = <T extends object = any>({
  id,
  children,
  label,
  displayKey,
  iconKey,
  idKey = "id" as keyof T,
  appliedKey = "id" as keyof T,
  searchable = false,
  loading = false,
  onClickOutside = () => null,
  header,
  height,
  searchValue,
  handleSelectFilter,
  clearMoreFilter,
}: IMultipleMoreFilter<T>) => {
  const [title, setTitle] = useState<string>("");
  const [icon, setIcon] = useState<string>("");
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const { clear, moreFilter } = useContext(AnalyticsContext);

  const onAddSelected = (items: string[]) => {
    handleSelectFilter(items, id);
  };

  const handleClearDropdown = () => {
    handleSelectFilter([], id);
    clearMoreFilter(id);
  };

  const handleOnClickOutside = () => {
    onClickOutside?.();
  };

  const props: IMultipleSelectDropdown<T> = {
    idKey,
    displayKey,
    appliedKey,
    children,
    onAddSelected,
    header,
    height,
    iconKey,
    searchValue,
    clear,
    defaultValue: label,
    presetValue: moreFilter[id],
    parentPopoverId: ANALYTICS_MORE_FILTER_ID,
    clearDropdown: handleClearDropdown,
    onClickOutside: handleOnClickOutside,
    states: {
      selectedIds,
      setSelectedIds,
      setTitle,
      title,
      loading,
      icon,
      setIcon,
    },
    dropdownProps: {
      id,
      searchable,
      labelIcon: icon,
    },
  };

  return (
    <Menu.Row id={id} title={label}>
      <Select.Dropdown.Multiple<T> {...props} />
    </Menu.Row>
  );
};
