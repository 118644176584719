import React, { useState } from "react";
import { Modal } from "@spenmo/splice";

import ReimbursementSettingsModal from "Views/Reimbursement/Settings/Modal";

import { IModalProps, ISettingsModalState } from "Views/Settings/@types";
import { IModalPayloadTypes, ISettingsModal } from "Views/Settings/@types";
import { SETTINGS_MODAL_TYPES, useSettingsModalContext } from "Views/Settings/context/Modal";

export const useSettingsModalState = (): {
  modal: ISettingsModalState;
  setModal: React.Dispatch<React.SetStateAction<ISettingsModalState>>;
  resetModal: () => void;
  toggleModal: (type: SETTINGS_MODAL_TYPES, payload?: object) => void;
} => {
  const initialModalState: ISettingsModalState = {
    props: {
      showModal: false,
      size: "m",
      title: "",
    },
    type: SETTINGS_MODAL_TYPES.NONE,
    payload: {},
  };

  const [modal, setModal] = useState<ISettingsModalState>(initialModalState);

  const resetModal = () => setModal(initialModalState);

  const toggleModal = (type: SETTINGS_MODAL_TYPES, props: IModalProps, payload?: object): void =>
    setModal({
      props: props || initialModalState.props,
      type: type,
      payload: payload || initialModalState.payload,
    });

  return { modal, setModal, resetModal, toggleModal };
};

export const useModalType = (): ISettingsModal => {
  const { modal } = useSettingsModalContext();

  const { textContent }: IModalPayloadTypes = modal?.payload || {};

  return {
    [SETTINGS_MODAL_TYPES.NONE]: {
      children: null,
    },
    [SETTINGS_MODAL_TYPES.CONFIRM_REIMBURSEMENT_SETTINGS]: {
      children: <ReimbursementSettingsModal textContent={textContent} />,
    },
  };
};

const SettingsModal = () => {
  const { modal } = useSettingsModalContext();
  const { showModal } = modal.props;

  const modalType = useModalType()[modal.type];

  return showModal && <Modal {...modal.props}>{modalType.children}</Modal>;
};


export default SettingsModal;
