import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { threeDots } from "assets/img";
import { Popover } from "antd";
import "./ApprovalListItem.scss";
import { clearDeleteApprovalFlow, deleteApprovalFlow } from "Redux/Actions/ApprovalFlow/deleteApprovalFlow";
import { appNotification } from "Modules/appNotification/appNotification";
import { useHistory } from "react-router-dom";
import { getApprovalFlowListFunc } from "Redux/Actions/ApprovalFlow/getApprovalFlowList";
import ErrorBox from "Modules/DS/Molecules/ErrorBox";
import { trackEvent } from "utility/analytics";
import { APPROVAL_FLOW_EVENTS } from "Views/ApprovalFlow/trackingEvents";
import { getApprovalFlowTabs, getEventType } from "../../helpers";
import { MultiApproversObject } from "../../types";
import DeleteFlowModal from "./DeleteFlowModal";
import ApproverItem from "./ApproverItem";

interface ListItemProps {
  id: string;
  title: string;
  desc: string;
  publishedAt?: string;
  publishedBy?: string;
  approvers: Array<MultiApproversObject>;
  hasInvalidData: boolean;
  invalidDataError: string;
  onClick: () => void;
  isDefault?: boolean;
  currentPage: number;
  processType: string;
  stepInactive: boolean;
}

const dateOptions: Intl.DateTimeFormatOptions = { year: "numeric", month: "short", day: "numeric" };

const ApprovalFlowListItem: React.FC<ListItemProps> = ({
  id,
  title,
  desc,
  publishedAt,
  publishedBy,
  approvers,
  isDefault,
  hasInvalidData,
  invalidDataError,
  currentPage,
  processType,
  stepInactive,
}: ListItemProps): JSX.Element => {
  const history = useHistory();
  const dispatch = useDispatch();
  const deleteReducer = useSelector((state: any) => {
    return state.deleteApprovalFlowReducer;
  });

  const [actionMenuVisible, setActionMenuVisible] = useState(false);
  const [deleteFlowModalVisible, setDeleteFlowModalVisible] = useState(false);

  useEffect(() => {
    if (hasInvalidData) {
      trackEvent(APPROVAL_FLOW_EVENTS.approvalFlowInvalidWarningDisplayed, {
        approval_flow_id: id,
        approval_flow_type: getEventType(processType),
      });
    }
  }, []);

  const showApprovalActionMenu = (
    <>
      {getApprovalFlowTabs()
        .filter((item) => item.tabKey !== processType)
        .map((item) => (
          <div key={item.tabKey} className="list-item__menu__dropdown-btn">
            <span
              onClick={(e) => {
                e.stopPropagation();
                trackEvent(APPROVAL_FLOW_EVENTS.approvalFlowDuplicated, {
                  approval_flow_id: id,
                  approval_flow_type: getEventType(processType),
                });
                history.push(`/approvalflow/create/${item.tabKey}?duplicate_process=${id}`);
              }}
            >
              {`Duplicate to ${item.title}`}
            </span>
          </div>
        ))}
      <div className="list-item__menu__dropdown-btn list-item__menu__delete-btn">
        <span
          onClick={(e) => {
            e.stopPropagation();
            setDeleteFlowModalVisible(true);
            setActionMenuVisible(false);
            trackEvent(APPROVAL_FLOW_EVENTS.approvalFlowDeleted, {
              approval_flow_id: id,
              approval_flow_type: getEventType(processType),
            });
          }}
        >
          Delete
        </span>
      </div>
    </>
  );

  useEffect(() => {
    if (deleteReducer.data?.payload?.isSuccess && deleteReducer.data?.id === id) {
      dispatch(getApprovalFlowListFunc({ page: currentPage, processType }));
      dispatch(clearDeleteApprovalFlow());
      appNotification.success({
        message: `${title} successfully deleted!`,
      });
    } else if (deleteReducer.error) {
      dispatch(clearDeleteApprovalFlow());
      appNotification.error({
        message: `Failed deleting ${title}. Please try again later.`,
      });
    }
  }, [deleteReducer]);

  const handleDeleteFlow = () => {
    setDeleteFlowModalVisible(false);
    dispatch(deleteApprovalFlow(id));
  };

  return (
    <li
      className={`approval-flow__list__item__container ${
        hasInvalidData ? "approval-flow__list__item__container__inactive" : ""
      }`}
    >
      {hasInvalidData && (
        <div className={"list__item__invalid-error"}>
          <ErrorBox message={invalidDataError}></ErrorBox>
        </div>
      )}
      <div className="approval-flow__list__item" onClick={() => history.push(`/approvalflow/edit/${id}`)}>
        <h3 className="list__item__title">{title}</h3>
        <div className="list__item__detail">
          <p className="detail__desc" dangerouslySetInnerHTML={{ __html: desc }}></p>
          <p className="detail__pub">{`Published ${publishedAt} by ${publishedBy}`}</p>
        </div>

        <div className="list__item__approver">
          <ul>
            {approvers.map((item, idx) => (
              <ApproverItem key={item.step} approvalFlowId={id} assignees={item.assignees} index={idx} />
            ))}
          </ul>
        </div>

        <div className="list_item_actions">
          <div
            className="actions_edit"
            onClick={(e) => {
              e.stopPropagation();
              history.push(`/approvalflow/edit/${id}`);
            }}
          >
            Edit
          </div>
          {!isDefault && (
            <Popover
              visible={actionMenuVisible}
              onVisibleChange={() => setActionMenuVisible(!actionMenuVisible)}
              trigger="click"
              content={showApprovalActionMenu}
              overlayClassName="list-item__menu__popover"
              align={{
                points: ["tl", "tr"],
                targetOffset: [100, 0],
              }}
            >
              <div className="actions_menu" onClick={(e) => e.stopPropagation()}>
                <img src={threeDots} alt="action menu" />
              </div>
            </Popover>
          )}
        </div>
      </div>
      {
        <DeleteFlowModal
          show={deleteFlowModalVisible}
          onCancel={() => {
            setDeleteFlowModalVisible(false);
            setActionMenuVisible(undefined);
          }}
          title={title}
          onDelete={handleDeleteFlow}
        />
      }
    </li>
  );
};

export default ApprovalFlowListItem;
