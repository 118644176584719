import * as React from "react";
import cn from "classnames";
import { TooltipPositionEnum, ITooltipProps, ArrowOrientation } from "./types.d";
import styles from "Modules/DS/Tooltip/index.module.scss";

/**
 * ToolTip Component
 * @param {string} text - text of the tooltip
 * @param {string} position - position of tooltip ( top | bottom | right | left )
 * @param {string} alignArrow - aligment of tooltip arrow ( right | center | left )
 * @param {boolean} showWithoutHover - whether we show the tooltip without hovering the component
 */
export const Tooltip = ({ children, text, position, alignArrow, showWithoutHover }: ITooltipProps) => {
  const horizontalOrientationPositions = [TooltipPositionEnum.TOP, TooltipPositionEnum.BOTTOM];

  const arrowOrientation = horizontalOrientationPositions?.includes(position)
    ? ArrowOrientation.HORIZONTAL
    : ArrowOrientation.VERTICAL;

  const arrowStyle = `arrow-${arrowOrientation}-${alignArrow}`;
  const visibilityStyle = showWithoutHover ? "auto-visible" : "";

  return (
    <div
      className={cn(styles["tooltip"], styles[position], styles[arrowStyle], styles[visibilityStyle])}
      data-tooltip-text={text}
      data-testid="tooltip"
    >
      {children}
    </div>
  );
};

export * from "./types.d";
