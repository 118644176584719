const isEqual = (obj1: object | Array<any>, obj2: object | Array<any>): boolean => {
  if (!obj1 || !obj2) {
    return true;
  }
  // Get the keys of each object
  const obj1Keys = Object.keys(obj1);
  const obj2Keys = Object.keys(obj2);

  if (obj1Keys.length !== obj2Keys.length) {
    return false;
  }

  // Check each key and value in obj1 against obj2
  for (let key of obj1Keys) {
    // If obj2 doesn't have the same key, the objects are not equal
    if (!obj2.hasOwnProperty(key)) {
      return false;
    }

    // Recursively check nested objects
    if (typeof obj1[key] === "object" && typeof obj2[key] === "object") {
      if (!isEqual(obj1[key], obj2[key])) {
        return false;
      }
    } else {
      // Compare values
      if (obj1[key] !== obj2[key]) {
        return false;
      }
    }
  }

  return true;
};

export default isEqual;
