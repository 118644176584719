const facebookIcon = require("src/assets/icons/facebook.svg");
const instagramIcon = require("src/assets/icons/instagram.svg");
const linkedinIcon = require("src/assets/icons/linkedin.svg");
const tiktokIcon = require("src/assets/icons/tiktok.svg");
const twitterIcon = require("src/assets/icons/twitter.svg");
const whatsAppIcon = require("src/assets/icons/whatsapp.svg");
const othersIcon = require("src/assets/icons/others.svg");

export const SocialMediaIcons = {
  facebook: facebookIcon,
  instagram: instagramIcon,
  linkedin: linkedinIcon,
  tiktok: tiktokIcon,
  twitter: twitterIcon,
  whatsApp: whatsAppIcon,
  others: othersIcon,
};
