import React from "react";

import styles from "./style.module.scss";

const EmptyState = ({ type = "Pending Transaction" }) => {
  const capitalize = (type) => {
    return type
      .split(" ")
      .map((sentences) => sentences[0].toUpperCase() + sentences.slice(1).toLowerCase())
      .join(" ");
  };
  return (
    <div className={styles["empty-state"]}>
      <div>
        <h4>No '{capitalize(type)}' Transactions Recorded</h4>
        <p>Transaction with {type.toLowerCase()} status will appear here</p>
      </div>
    </div>
  );
};

export default EmptyState;
