import { FormInstance, FormItemProps, RuleObject } from "antd/lib/form";
import { StoreValue } from "antd/lib/form/interface";
import React, { ReactNode } from "react";
import { OCRData } from "Views/UploadInvoice/InvoiceForm/VendorSection/types";

export interface DynamicFormProps {
  billID?: string;
  vendorId?: string;
  combinationIDs?: number[];
  currency: string;
  destinationCountry: string;
  fieldID?: number;
  fieldValue?: string;
  form: FormInstance;
  isNewData?: boolean;
  key?: string;
  ocrData?: OCRData;
  trackView: (_fieldName: string, _value: any) => void;
  trackEdit: (_fieldName: string, _value: any) => void;
  source?: string;
}

export enum DynamicFormFieldType {
  checkbox = "checkbox",
  dropdown = "dropdown",
  option = "option",
  text = "text",
  textarea = "textarea",
  textfield = "textfield",
  tagInput = "tagInput",
}

export interface DynamicFormFieldOption {
  value: string;
  label: string;
  additionalCode?: string;
}

export interface DynamicFormField {
  alias: string;
  fieldID: number;
  fieldProps: {
    checklist: {
      title: string;
      subtitle: string;
      default: boolean;
      hideCombinationIDs?: number[];
    } | null;
    doFetch: boolean;
    editable: boolean;
    inBSS: {
      hide: boolean;
      optional: boolean;
    };
    indentation: number;
    information: string;
    inOpsy: {
      hide: boolean;
      optional: boolean;
    };
    isAllowToAddData: boolean;
    isHideForNewData: boolean;
    hideWhenSingleOption?: boolean;
    maxChar: number;
    minChar: number;
    noteLink: string;
    noteText: string;
    parentFieldID: number;
    parentValue: string;
    placeholder: string;
    regex: string;
    regexErrorMessage: string;
    isTrimSpace: boolean;
    renderNewBank: boolean;
    required: boolean;
    similarCombinationID: number;
    staticPrefix?: string;
  };
  id: number;
  label: string;
  options: DynamicFormFieldOption[];
  type: DynamicFormFieldType;
  orderNumber: number;
  value: string;
}

export interface DynamicFormFieldWithAncestor extends DynamicFormField {
  ancestorIDs: DynamicFormField["fieldID"][];
}

export interface DynamicFormFieldProps extends DynamicFormField, Omit<FormItemProps, "id" | "label"> {
  onChangeValue?: (id: number, value: string | boolean) => void;
  suffix?: ReactNode;
  validator?: (rule: RuleObject, value: StoreValue) => Promise<void>;
  labelField: string | React.ReactNode;
  onFocus?: (any) => void;
  onBlur?: (any) => void;
  form: any;
}
