import {
  initialDeleteEmployeFromOrgState,
  DELETE_EMPLOYEE_FROM_ORG_ACTION_TYPES,
  IDeleteEmployeeFromOrgReducer,
} from "Redux/Actions/Employee/Delete/types";

export const deleteEmployeeFromOrgReducer = (
  state = initialDeleteEmployeFromOrgState,
  action
): IDeleteEmployeeFromOrgReducer => {
  switch (action.type) {
    case DELETE_EMPLOYEE_FROM_ORG_ACTION_TYPES.FETCH_DELETE_EMPLOYEE_FROM_ORG_REQUEST:
      return {
        ...state,
        data: action.data,
        loading: true,
        error: false,
      };
    case DELETE_EMPLOYEE_FROM_ORG_ACTION_TYPES.FETCH_DELETE_EMPLOYEE_FROM_ORG_SUCCESS:
      return {
        ...state,
        data: { ...action.data, success: true },
        loading: false,
        error: false,
      };
    case DELETE_EMPLOYEE_FROM_ORG_ACTION_TYPES.FETCH_DELETE_EMPLOYEE_FROM_ORG_ERROR:
      return {
        ...state,
        loading: false,
        data: { ...action.data, success: false },
        error: true,
      };
    case DELETE_EMPLOYEE_FROM_ORG_ACTION_TYPES.CLEAR_DELETE_EMPLOYEE_FROM_ORG:
      return initialDeleteEmployeFromOrgState;
    default:
      return state;
  }
};
