import React, { useContext, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "Redux/ConfigureStore";
import {
  applyFilter,
  clearFilter,
  selectApprovalFilters,
  setCurrentPage,
  selectActiveSubTab,
} from "Redux/Reducers/Approval/ApprovalCenter/approvalCenter.slice";
import { getCurrencyFunc } from "Redux/Actions";
import { useAppSelector } from "../../../../hook";
import { BillFilter, FilterKey, REIMBURSEMENT_STATUS } from "../types";
import { ApprovalFilterContext } from "../context";
import RequestDate from "../Filter/RequestDate";
import AmountFilter from "../Filter/amount";
import { initalSelectState, ISelectContext, SelectContext, SelectProvider } from "Modules/DS/Select";
import { POPOVER_WIDTH } from "Modules/DS/Popover";
import { FilterItem } from "Modules/DS/Filter";
import dayjs from "dayjs";

const Filter = () => {
  return (
    <SelectProvider>
      <ApprovalReimbursementFilter />
    </SelectProvider>
  );
};

const ApprovalReimbursementFilter = () => {
  const { setSelect } = useContext<ISelectContext>(SelectContext);

  const dispatch = useDispatch();
  const approvalRequestFilter: BillFilter = useAppSelector(selectApprovalFilters);
  const activeSubTab: REIMBURSEMENT_STATUS = useAppSelector(selectActiveSubTab);

  const { filters, setFilters } = useContext(ApprovalFilterContext);

  useEffect(() => {
    dispatch(getCurrencyFunc());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleClear(FilterKey.Transaction_Date);
    handleClear(FilterKey.Amount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSubTab]);

  const { data: currencyData } = useSelector((state: RootState) => state.currencyReducer);
  const currencies = (currencyData.payload && currencyData.payload.currencies) || [];

  const handleSubmit = (filterKey: FilterKey, values) => {
    dispatch(setCurrentPage(1));
    switch (filterKey) {
      case FilterKey.Amount:
        dispatch(applyFilter({ minAmount: values.min_amount, maxAmount: values.max_amount }));
        break;
      case FilterKey.Transaction_Date:
        dispatch(
          applyFilter({
            trxDateFrom: dayjs(values.start_date).format("YYYY-MM-DD"),
            trxDateTo: dayjs(values.end_date).format("YYYY-MM-DD"),
          })
        );
        break;
    }
    setSelect({
      ...initalSelectState,
    });
    setFilters((filters) => {
      return {
        ...filters,
        [filterKey]: true,
      };
    });
  };

  const handleClear = (filterKey: FilterKey) => {
    dispatch(clearFilter(filterKey));
    setSelect({ ...initalSelectState });
    setFilters((filters) => {
      return {
        ...filters,
        [filterKey]: false,
      };
    });
  };

  return (
    <div className="approval-filter">
      <RequestDate
        id={FilterKey.Transaction_Date}
        defaultValue="Transaction Date"
        filter={{ start_date: approvalRequestFilter.trxDateFrom, end_date: approvalRequestFilter.trxDateTo }}
        onApply={(value) => {
          handleSubmit(FilterKey.Transaction_Date, value);
        }}
        onClear={() => {
          handleClear(FilterKey.Transaction_Date);
        }}
      />

      <FilterItem
        id={FilterKey.Amount}
        title="Amount"
        selected={filters[FilterKey.Amount]}
        popoverWidth={POPOVER_WIDTH.L}
        clearFilter={() => handleClear(FilterKey.Amount)}
      >
        <AmountFilter
          currencyData={currencies}
          initialMinAmount={approvalRequestFilter.minAmount}
          initialMaxAmount={approvalRequestFilter.maxAmount}
          handleSubmit={handleSubmit}
          filterKey={FilterKey.Amount}
        />
      </FilterItem>
    </div>
  );
};

export default Filter;
