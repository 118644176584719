import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form } from "antd";

import { GetBaseAuthObject } from "utility";
import { getOrgDetailFunc } from "Redux/Actions";
import { getListCountry, getListCurrency } from "Redux/DataCalls/Disbursement.api";
import SendMoneyCalculator from "../SendMoneyCalculator";

import { trackEvent } from "utility/analytics";
import "./Simulation.scss";

const Simulation = () => {
  const [form] = Form.useForm();
  const userData = useSelector((state) => state.wallet.data);
  const dispatch = useDispatch();

  const [currency, setCurrency] = useState({ get: undefined, sent: undefined }),
    [amount, setAmount] = useState({ get: "", sent: "" }),
    [countryList, setCountryList] = useState([]),
    [currencyList, setCurrencyList] = useState([]),
    [countryCode, setCountryCode] = useState(undefined);

  const onGetCurrencyChange = (val) => {
      trackEvent("bill edit currency for receiver amount FX calculator");
      let amountGet = amount.get;
      if (isNaN(amountGet)) {
        amountGet = parseFloat(amountGet.replace(",", "."));
      }
      setAmount({ ...amount, get: amountGet });
      setCurrency({ ...currency, get: val });
    },
    onSentCurrencyChange = (val) => setCurrency({ ...currency, sent: val }),
    onGetAmountChange = (val) => setAmount((prevAmount) => ({ ...prevAmount, get: val || 0 })),
    onSentAmountChange = (val) => setAmount((prevAmount) => ({ ...prevAmount, sent: val || 0 }));

  useEffect(() => {
    const currencyCode = userData?.currency_code;
    if (currencyCode) {
      if (!countryCode) {
        setCountryCode(currencyCode.substring(0, 2));
      }
      if (!currency.get) {
        form.setFieldsValue({ getCurrency: currencyCode });
        setCurrency({ get: currencyCode, sent: currencyCode });
      } else {
        setCurrency({ ...currency, sent: currencyCode });
      }
      form.setFieldsValue({ sentCurrency: currencyCode });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  const fetchCountryAndCurrencyList = async () => {
    const dataListCountry = await getListCountry(true);
    setCountryList(dataListCountry.data.payload.countries);

    const dataListCurrency = await getListCurrency(true);
    setCurrencyList(dataListCurrency?.data?.payload?.result?.map((item) => ({ currency_code: item.CurrencyCode })));
  };

  useEffect(() => {
    dispatch(getOrgDetailFunc(GetBaseAuthObject().orgId));
    fetchCountryAndCurrencyList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SendMoneyCalculator
      countryList={countryList}
      currencyList={currencyList}
      receiverCountryCode={countryCode}
      receiverCurrency={currency.get}
      receiverAmount={amount.get}
      senderCurrency={currency.sent}
      senderAmount={amount.sent}
      onReceiverCountryCodeChange={setCountryCode}
      onReceiverCurrencyChange={onGetCurrencyChange}
      onReceiverAmountChange={onGetAmountChange}
      onSenderCurrencyChange={onSentCurrencyChange}
      onSenderAmountChange={onSentAmountChange}
    />
  );
};

export default Simulation;
