import React from "react";

import { InputCell } from "Views/Transaction/Shimmer";

import { IColumnData } from "Modules/DS/DataTable";
import { IGetCardsTrxnsData, GET_CARDS_TRXNS_DATA_PARAMS, CARDS_TRXNS_TABLE_COLUMNS } from "Views/Transaction/@types";

export const merchant = (): IColumnData<IGetCardsTrxnsData>["column"] => ({
  title: CARDS_TRXNS_TABLE_COLUMNS.MERCHANT,
  key: GET_CARDS_TRXNS_DATA_PARAMS.SIMPLIFIED_MERCHANT,
  dataIndex: GET_CARDS_TRXNS_DATA_PARAMS.SIMPLIFIED_MERCHANT,
  width: "50%",
  render: (value: string): React.ReactNode => (
    <InputCell>
      <p>{value}</p>
    </InputCell>
  ),
});
