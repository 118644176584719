import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import Icon from "../icons";
import "./sectionEmptyState.scss";

const SectionEmptyState = ({ iconSrc, iconAlt, message, primary, className, header }) => {
  const _MessageClassNames = classNames("section-empty-state__message", {
    "section-empty-state__message--primary": primary,
    "section-empty-state__message--with-header": header,
  });

  return (
    <div className={classNames("section-empty-state", className)}>
      <Icon src={iconSrc} alt={iconAlt} />
      {header && <p className="section-empty-state__header">{header}</p>}
      <p className={_MessageClassNames}>{message}</p>
    </div>
  );
};

SectionEmptyState.propTypes = {
  iconAlt: PropTypes.string,
  iconSrc: PropTypes.any,
  message: PropTypes.string,
  primary: PropTypes.bool,
  className: PropTypes.string,
  header: PropTypes.string,
};

export default SectionEmptyState;
