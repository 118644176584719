import * as React from "react";
import { Select as AntdSelect } from "antd";
import { getPhoneCodes } from "Redux/DataCalls/Cards.api";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import { useCreateOrRequestCardContext } from "Views/Card/CreateOrRequest/index.context";
import { Actions } from "Views/Card/CreateOrRequest/Utilities/UseCreateOrRequestState";

import Select from "Views/Card/Components/Select";

import "Views/Card/CreateOrRequest/Steps/index.scss";

const { Option } = AntdSelect;

const CountrySelector = () => {
  const { dispatch, state } = useCreateOrRequestCardContext();
  const [countries, setCountries] = React.useState([]);

  const fetchAndSetCountries = async () => {
    getPhoneCodes()
      .then((response) => {
        if (response?.status === HTTP_STATUS_CODE.OK) {
          const { payload: phoneCodes = [] } = response?.data?.payload || {};
          const formattedApprovers = Array.isArray(phoneCodes)
            ? phoneCodes
                ?.filter((phoneCode) => phoneCode?.country)
                ?.map((phoneCode) => ({
                  value: phoneCode?.country,
                  label: phoneCode?.country,
                }))
            : [];
          setCountries(formattedApprovers);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  React.useEffect(() => {
    fetchAndSetCountries();
  }, []);

  const onCountrySelect = (value) => {
    dispatch({ type: Actions.UPDATE_COUNTRY, value });
  };

  return (
    <Select
      value={state?.country || undefined}
      placeholder="Select Country"
      onSelect={onCountrySelect}
      filterOption={(input, option) => option?.label?.toString()?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0}
      dropdownClassName="create-or-request-card-steps__dropdown"
      showSearch
      getPopupContainer={(triggerNode) => triggerNode.parentElement}
      data-testid="approver-selector"
      optionLabelProp="label"
    >
      {countries?.map((member) => (
        <Option key={member.value} value={member.value} label={member.label}>
          {member.label}
        </Option>
      ))}
    </Select>
  );
};

export default CountrySelector;
