import React, { useState, createContext } from "react";
import PropTypes from "prop-types";
import { Col, Form, Row } from "antd";

import { checkEmptyObject } from "utility";
import { trackEvent } from "utility/analytics";

import { useClickOutside } from "customHooks/useClickOutside";

import Button from "Modules/button";
import { analytics } from "./analytics";
import * as FilterComponents from "./Components";

import "./filters.scss";

const FiltersAPI = createContext({
  handleDateChange: () => null,
  handleDropDownActions: () => null,
  handleInputOnBlurActions: () => null,
  trackFilterActions: () => null,
});

const Filters = ({ children, onApply, onClear, filters, page = "", trackFilterEvents = false }) => {
  const [form] = Form.useForm();
  const [showFiltersModal, setShowFiltersModal] = useState(false);

  const [filterRef] = useClickOutside(
    () => setShowFiltersModal(false),
    [".ant-picker-dropdown", ".ant-select-dropdown"]
  );

  const trackFilterActions = (eventName) => {
    if (!trackFilterEvents) return;
    trackEvent(`${page} filter ${eventName}`);
  };

  const onFinish = (values) => {
    onApply(values);
    trackFilterActions(analytics.apply);
    setShowFiltersModal(false);
  };

  const handleReset = () => {
    onClear(form);
    trackFilterActions(analytics.clearAll);
  };

  const handleFilterClickAndCancelActions = (eventName) => {
    setShowFiltersModal(!showFiltersModal);
    trackFilterActions(eventName);
  };

  const handleDateChange = (date, type, eventName) => {
    form.setFieldsValue({ [type]: date !== null ? date : undefined });
    date && trackFilterActions(eventName);
  };

  const handleDropDownActions = (value, fieldName, eventName) => {
    form.setFieldsValue({ [fieldName]: value });
    trackFilterActions(eventName);
  };

  const handleInputOnBlurActions = (fieldName, eventName) => {
    const currentValue = form?.getFieldValue(fieldName);
    if (!currentValue) {
      form.setFieldsValue({ [fieldName]: undefined });
    }
    currentValue && trackFilterActions(eventName);
  };

  const api = { handleDateChange, handleDropDownActions, handleInputOnBlurActions, trackFilterActions };

  const renderFiltersIndicatorDot = () => {
    return !checkEmptyObject(filters) && <span className="dot" />;
  };

  const renderFilterActionButtons = () => {
    return (
      <Row className="filter--action">
        <Col span={6}>
          <Button textButton type="reset" className="clear-btn" action={handleReset}>
            Clear all filters
          </Button>
        </Col>
        <Col style={{ justifyContent: "flex-end" }}>
          <Button
            textButton
            type="button"
            className="cancel-btn"
            action={() => handleFilterClickAndCancelActions(analytics.cancelFilter)}
          >
            Cancel
          </Button>
          <Button className="submit-btn" textButton type="submit">
            Apply
          </Button>
        </Col>
      </Row>
    );
  };

  return (
    <div>
      <div className="filter--btn">
        {renderFiltersIndicatorDot()}
        <Button
          plain
          id="filterToggle"
          testid="filterToggle"
          iconName="filter-icon"
          className="filter--toggle-btn"
          action={() => handleFilterClickAndCancelActions(analytics.filterBtnClicked)}
        >
          Filters
        </Button>
      </div>
      {showFiltersModal && (
        <div className="filter" ref={filterRef}>
          <div className="filter--wrapper">
            <Form form={form} onFinish={onFinish} initialValues={{ ...filters }}>
              <FiltersAPI.Provider value={api}>{children}</FiltersAPI.Provider>
              {renderFilterActionButtons()}
            </Form>
          </div>
        </div>
      )}
    </div>
  );
};

Filters.propTypes = {
  page: PropTypes.string,
  onApply: PropTypes.func,
  onClear: PropTypes.func,
  filters: PropTypes.object,
  trackFilterEvents: PropTypes.bool,
};

export default Filters;

export { FiltersAPI, FilterComponents, analytics };
