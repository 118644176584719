import React, { useMemo, useRef, useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Button, Popover, Spin } from "antd";

import { ActionButtonType } from "Modules/DS/DataTable";
import bulkActionStyle from "Modules/DS/DataTable/Components/BulkActions/ActionWithMoreMenu/actionWithMoreMenu.module.scss";
import { TOASTER_STATUS_TYPE } from "Modules/DS/Toaster/types.d";
import { useClickOutside } from "utility/Helper/useClickOutside";
import useLoading from "utility/useLoading";
import { useActiveCategory } from "utility/useActiveCategory";
import { sleep } from "Views/Bills/ImportBillFromXero/helper";
import { isEditableTrxnType } from "Views/Transaction/Helper";
import { IToasterProps } from "Views/Accounting/Settings/@types";
import { bulkActionTransactions } from "Redux/DataCalls/AccountingWorkflow.api";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";

import Search from "../BulkAction/Popover/Search";
import { ITransaction } from "../type";

import styles from "../PendingReview/styles.module.scss";

type UseBulkEdit = (
  transactionIds: string[],
  setToaster: React.Dispatch<React.SetStateAction<IToasterProps>>,
  setSelectedIds: React.Dispatch<React.SetStateAction<any[]>>,
  option?: {
    onSuccess?: () => void;
    onError?: () => void;
    transactions: ITransaction[];
  }
) => { editCategoryButton: any; showErrorToaster: (error?: any) => void };

const allowToEditCategory = (selectedTransactions) =>
  selectedTransactions.some((transaction) => isEditableTrxnType(transaction?.transactionType || ""));

const editCategorySuccessMessage = (transactionIds: string[]) =>
  transactionIds?.length > 1 ? "Multiple categories successfully edited" : "1 category successfully edited";

export const useBulkEdit: UseBulkEdit = (transactionIds, setToaster, setSelectedIds, option) => {
  const isReflected = useRef(true);
  const { categoryList } = useActiveCategory();

  const [popoverVisible, setPopoverVisible] = useState(false);
  const [bulkAction, loadingBulkAction] = useLoading(bulkActionTransactions, null, {}, false);

  const ref: React.MutableRefObject<HTMLDivElement> = useClickOutside(() => {
    setPopoverVisible(false);
  }, []);

  const showErrorToaster = (error) => {
    setToaster({
      show: true,
      message: error?.payload?.status_message || "Something went wrong",
      type: TOASTER_STATUS_TYPE.ERROR,
    });
  };
  const selectedTransactions = useMemo(
    () => (option?.transactions || []).filter((transaction) => transactionIds.includes(transaction.id)),
    [option.transactions, transactionIds]
  );

  const editableTransactionIds = () => {
    return selectedTransactions
      .filter((transaction) => isEditableTrxnType(transaction.transactionType))
      .map((transaction) => transaction.id);
  };

  const editCategoryButton = {
    type: ActionButtonType.Secondary,
    label: "Edit Category",
    handler: async () => {},
    customButton: (
      <div ref={ref}>
        <Popover
          visible={popoverVisible}
          placement="top"
          overlayClassName={styles["category-popover"]}
          content={() => (
            <Search
              transactionCount={transactionIds.length}
              type="category"
              data={categoryList.map((category) => ({ id: category.id, value: category.categoryName }))}
              onSelected={async (category) => {
                try {
                  isReflected.current = false;
                  setPopoverVisible(false);
                  const { data } = await bulkAction(editableTransactionIds(), "expense_category_id", category.id);
                  const timeToSleepInSecond = 3;
                  await sleep(timeToSleepInSecond);
                  isReflected.current = true;

                  if (data.status === HTTP_STATUS_CODE.OK) {
                    const message = editCategorySuccessMessage(transactionIds);
                    setToaster({
                      show: true,
                      message,
                      type: TOASTER_STATUS_TYPE.SUCCESS,
                    });
                    setSelectedIds([]);
                    option?.onSuccess?.();
                    return data;
                  }
                  return Promise.reject(data);
                } catch (err) {
                  setSelectedIds([]);
                  showErrorToaster(err);
                  option?.onError?.();
                }
              }}
            />
          )}
          getPopupContainer={(triggerNode) => triggerNode.parentElement}
        >
          <Button
            className={bulkActionStyle[`action-with-more-menu__button--secondary`]}
            onClick={() => {
              setPopoverVisible(!popoverVisible);
            }}
          >
            {loadingBulkAction || !isReflected.current ? (
              <Spin indicator={<LoadingOutlined spin />} />
            ) : (
              "Edit Category"
            )}
          </Button>
        </Popover>
      </div>
    ),
  };

  return {
    editCategoryButton: allowToEditCategory(selectedTransactions) ? editCategoryButton : null,
    showErrorToaster,
  };
};
