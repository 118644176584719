import { useCallback, useRef, useState } from "react";
import axios, { CancelTokenSource } from "axios";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import {
  IGetTrxnRequest,
  ITrxnsErrorData,
  ITrxnsSuccessData,
  IGetListTrxnsResponse,
  IGetTrxnsCountRequest,
  IGetTransactionReceiptsCountRequest,
} from "Views/Transaction/@types";
import {
  getAllTrxns,
  getTrxnsCount,
  getTransactionsReceiptsCount,
  searchMerchantList,
  initialTrxnsSuccess,
  initialGetTrxnRequest,
  initialGetTrxnCountRequest,
  trimPaginationAndListParams,
} from "Views/Transaction/DataCalls";
import { DEFAULT_ERROR_MESSAGE, TRANSACTION_TYPES } from "Views/Transaction/Constants";
import { getAllTeamsWithMembers, dummyTeamWithMembers } from "Views/Team/DataCalls";
import { ITeamWithMembersData, IAllTeamsWithMembersErrorData, IAllTeamsWithMembersSuccessData } from "Views/Team";
import { clearURLParams } from "utility";

export const useListFetch = () => {
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<IGetListTrxnsResponse>(initialTrxnsSuccess.mocked);

  const fetchData = useCallback((params: IGetTrxnRequest = initialGetTrxnRequest()) => {
    params.exclude_types = [TRANSACTION_TYPES.TRANSFER];

    setLoading(true);
    setError(false);
    getAllTrxns(params)
      .then((resp) => {
        if (resp.status > HTTP_STATUS_CODE.OK) {
          setError(true);
          setData(resp.data as ITrxnsErrorData);
        } else {
          setError(false);
          setData(resp.data as ITrxnsSuccessData);
        }
      })
      .catch((error) => {
        setError(true);
        setData(error as ITrxnsErrorData);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return { error, loading, data, fetchData };
};

export const useCountFetch = () => {
  const [count, setCount] = useState(0);

  const fetchCount = useCallback((params: IGetTrxnsCountRequest = initialGetTrxnCountRequest()) => {
    trimPaginationAndListParams<IGetTrxnsCountRequest>(params);

    getTrxnsCount(params)
      .then((resp) => {
        if (resp.status > HTTP_STATUS_CODE.OK) {
          setCount(0);
        } else {
          setCount(resp.data.count);
        }
      })
      .catch((error) => {
        setCount(0);
      });
  }, []);

  return { count, fetchCount };
};

export const useTransactionReceiptsCountFetch = () => {
  const [receiptsCount, setReceiptsCount] = useState(0);

  const fetchReceiptsCount = useCallback(
    (params: IGetTransactionReceiptsCountRequest = initialGetTrxnCountRequest()) => {
      trimPaginationAndListParams<IGetTransactionReceiptsCountRequest>(params);
      getTransactionsReceiptsCount(params)
        .then((resp) => {
          if (resp.status > HTTP_STATUS_CODE.OK) {
            setReceiptsCount(0);
          } else {
            setReceiptsCount(resp.data.count);
          }
        })
        .catch((error) => {
          setReceiptsCount(0);
        });
    },
    []
  );

  return { receiptsCount, fetchReceiptsCount };
};

export const useMerchantSearch = () => {
  const latestRequest = useRef<CancelTokenSource>(null);

  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const [data, setData] = useState<string[]>([]);

  const resetData = () => {
    clearURLParams(["recipientNames"]);
    setData([]);
  };

  const fetchMerchants = useCallback((value: string) => {
    if (latestRequest.current) {
      latestRequest.current.cancel("Invalidating previous requests");
    }

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    latestRequest.current = source;

    setLoading(true);
    setError(false);
    setErrorMessage("");

    if (!value) {
      setData([]);
      setError(false);
      setLoading(false);
      setErrorMessage("");
      return;
    }

    searchMerchantList(value, source)
      .then((resp) => {
        if (resp.status > HTTP_STATUS_CODE.OK) {
          setError(true);
          setErrorMessage(resp.data.payload.status_message || DEFAULT_ERROR_MESSAGE);
          setData([]);
        } else {
          setError(false);
          setData(resp.payload.merchants);
        }
      })
      .catch((error) => {
        setError(true);
        setData([]);
        setErrorMessage(error.message || DEFAULT_ERROR_MESSAGE);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return { error, loading, data, fetchMerchants, resetData, errorMessage };
};

export const useRequesterFetch = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const [data, setData] = useState<ITeamWithMembersData[]>(dummyTeamWithMembers);

  const fetchData = useCallback(() => {
    setLoading(true);
    setError(false);
    setErrorMessage("");

    getAllTeamsWithMembers()
      .then((resp) => {
        if (resp.status > HTTP_STATUS_CODE.OK || resp.data.status > HTTP_STATUS_CODE.OK) {
          setError(true);
          setErrorMessage((resp.data.payload as IAllTeamsWithMembersErrorData).status_message || DEFAULT_ERROR_MESSAGE);
          setData([]);
        } else {
          setError(false);
          setErrorMessage("");
          setData((resp.data.payload as IAllTeamsWithMembersSuccessData).teams);
        }
      })
      .catch((error) => {
        setError(true);
        setErrorMessage(error.message || DEFAULT_ERROR_MESSAGE);
        setData([]);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return { data, loading, error, errorMessage, fetchData };
};
