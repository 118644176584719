import { useContext, useState } from "react";

import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";

import { getFormConfigs } from "Views/KYX/DataCalls";
import { KYXToastContext, KYXErrorToaster } from "Views/KYX/Context";
import { IGetFormConfigs, IGetFormConfigsPayload, IKYXToastContext } from "Views/KYX/@types";

export const useGetFormConfigs = (dispatchOnSuccess?: (payload: IGetFormConfigsPayload) => void): IGetFormConfigs => {
  const [loading, setLoading] = useState(false);
  const { setToaster } = useContext<IKYXToastContext>(KYXToastContext);

  const getRemarks = async () => {
    try {
      setLoading(true);

      const response = await getFormConfigs();

      if (response?.status !== HTTP_STATUS_CODE.OK) {
        throw new Error(response?.statusText);
      }

      const data = response?.data?.payload;
      const errorMessage = response.data?.error?.message || response.data?.meta?.message;

      if (response?.data?.status === HTTP_STATUS_CODE.OK) {
        dispatchOnSuccess?.(data);
      } else {
        throw new Error(errorMessage);
      }
    } catch (error) {
      const toast = KYXErrorToaster(error?.message || error);

      setToaster(toast);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return { getRemarks, loading };
};
