import React from "react";
import dayjs from "dayjs";
import moment from "moment";

import { capitalizeFirstLetter, startDateOfMonth, startDateOfTheMonth } from "utility";

export const messageGenerator = ({ selectedTemplate, noFilters, isConnected, mustSendEmail, sentEmail, filters }) => {
  const generateTextHeader = () => {
    if (selectedTemplate.name) {
      return (
        <span>
          <span className="capitalize">{selectedTemplate.accountingSoftware}</span> export guide
        </span>
      );
    }

    if (noFilters) {
      return <span>No filter applied for this dowloaded data</span>;
    }

    if (!isConnected || !selectedTemplate.name) {
      return <span>Make sure the amount of transactions to download is correct</span>;
    }
  };

  const generateSubHeader = () => {
    if (selectedTemplate.name) {
      return selectedTemplate.detail;
    }

    if (noFilters) {
      return "If you don’t use filter, you will automatically download all transactions from the beginning of this current year.";
    }

    if (!isConnected || !selectedTemplate.name) {
      return "If you used the filter, make sure the amount of transactions data to download correctly matches with the filter applied.";
    }
  };

  const generateButtonText = () => {
    if (mustSendEmail) {
      return "Send to Email";
    }
    if (sentEmail) {
      return `Download & Send to Email`;
    }
    return `Download ${capitalizeFirstLetter(selectedTemplate?.accountingSoftware) || ""} CSV`;
  };

  const generatePopupMessage = () => {
    let startDate = startDateOfTheMonth();
    let endDate = "today";

    if (filters.start_date) {
      startDate = moment(filters.start_date).format("DD/MM/YYYY");
    }

    if (filters.end_date) {
      endDate = moment(filters.end_date).format("DD/MM/YYYY");
    }

    return (
      <p className="summary__information">
        Transactions selected from {startDate} to {endDate}
      </p>
    );
  };

  return {
    generateTextHeader,
    generateSubHeader,
    generateButtonText,
    generatePopupMessage,
  };
};

export const thisYearDateRange = () => {
  return {
    start_date: `${new Date().getFullYear()}-01-01`,
    end_date: moment().format("YYYY-MM-DD"),
  };
};

export const thisMonthDateRange = () => {
  return {
    start_date: startDateOfMonth(),
    end_date: moment().format("YYYY-MM-DD"),
  };
};

export const reportingServiceMonthDateRange = () => {
  return {
    start_date: dayjs().startOf("month").toISOString(),
    end_date: dayjs().toISOString(),
  };
};
