import { Checkbox, Form } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { DynamicFormContext } from "Modules/DynamicForm/store";
import { DynamicFormFieldProps } from "Modules/DynamicForm/types";
import React, { useContext } from "react";
import { formItemProps } from "../utils/formItemProps";
import style from "./Checkbox.module.scss";

const DynamicFormCheckbox = (props: DynamicFormFieldProps) => {
  const { alias, label, fieldID, fieldProps, onChangeValue, value } = props;
  const { trackView, trackEdit } = useContext(DynamicFormContext);

  const handleChange = (e: CheckboxChangeEvent) => {
    trackEdit(alias, e.target.checked);
    trackView(alias, e.target.checked);
    onChangeValue?.(fieldID, e.target.checked);
  };

  return (
    <Form.Item {...formItemProps(props)} label="" valuePropName="checked" initialValue={Boolean(value)}>
      <Checkbox onChange={handleChange} disabled={!fieldProps.editable} className={style.checkbox}>
        <div className={style.title}>{label}</div>
        {Boolean(fieldProps.placeholder) && <div className={style.desc}>{fieldProps.placeholder}</div>}
      </Checkbox>
    </Form.Item>
  );
};

export default DynamicFormCheckbox;
