import React from "react";

import {
  IBillItem,
  IPaymentCycleData,
  PAYMENT_CYCLE_STATE,
  PaySelectedErrorType,
} from "Views/Bills/V2/PaymentRun/types";
import { BILL_STATUS_ENUM, CYCLE_STATUS_ENUM } from "Views/Bills/V2/PaymentRun/constant";
import { plural } from "utility";

export const Separator = () => <>|</>;

export const formatBudgetNames = (budgetNames: string[]) => {
  return budgetNames.length > 1
    ? `${budgetNames.slice(0, -2).join(", ")}, ${budgetNames.slice(-2).join(" and ")}`
    : budgetNames.join("");
};

export const PaySelectedError = (styles, { type, data: budgetNames }: PaySelectedErrorType) => {
  switch (type) {
    case "overlimitBillsCount":
      return (
        <div>
          Payment run cannot be processed since {budgetNames} bill’s status has changed to
          <span className={styles.highlight}> Budget overlimit.</span> Kindly refresh this page and review the payment
          run before continuing.
        </div>
      );

    case "overlimitBudgetNames": {
      const formattedBudgetNames = formatBudgetNames(budgetNames);

      return (
        <div>
          Payment run cannot be processed as you don’t have sufficient budget limit in{" "}
          <span className={styles.highlight}>{formattedBudgetNames}</span> {plural(budgetNames.length, "budget")}.
          Kindly review and adjust the {plural(budgetNames.length, "budget")} before proceeding with the payment run.
        </div>
      );
    }
    default:
      return null;
  }
};

export const billSelectionError = (paymentCycleState: PAYMENT_CYCLE_STATE, billStatus: BILL_STATUS_ENUM) => {
  if (paymentCycleState === PAYMENT_CYCLE_STATE.UNSCHEDULED) return "";
  if (paymentCycleState === PAYMENT_CYCLE_STATE.UPCOMING)
    return "Cannot select this bill since it’s not assigned to the current Payment Run";
  else if (billStatus === BILL_STATUS_ENUM.PENDING_APPROVAL)
    return "Cannot select this bill since it’s yet to be approved";
  else if (billStatus === BILL_STATUS_ENUM.BUDGET_OVERLIMIT)
    return "Cannot select this bill since its budget limit has reached. \n\nIf you’re the budget owner, kindly revise the budget limit to process this bill.";
  else return "";
};

export const selectionNotAllowed = (paymentCycleState: PAYMENT_CYCLE_STATE, billStatus: BILL_STATUS_ENUM) => {
  return billSelectionError(paymentCycleState, billStatus).length > 0;
};

export const mapToPaymentRunBillFilters = (filter) => {
  const FilterMap = {
    min_amount: "minAmount",
    max_amount: "maxAmount",
    end_date: "endDueDate",
    start_date: "startDueDate",
    merchant: "recipients",
    statusList: "statuses",
  };

  return (
    filter &&
    Object.keys(filter).reduce((mappedFilter, key) => {
      if (FilterMap[key]) {
        mappedFilter[FilterMap[key]] = filter[key];
      }

      return mappedFilter;
    }, {})
  );
};

export const updatePaymentCycleState = (passed: IPaymentCycleData[], upcoming: IPaymentCycleData[]) => {
  return [
    ...(passed || []).map((cycle: IPaymentCycleData) => ({
      ...cycle,
      state:
        cycle.id === 0
          ? PAYMENT_CYCLE_STATE.UNSCHEDULED
          : cycle.id === -1
          ? PAYMENT_CYCLE_STATE.ADHOC
          : PAYMENT_CYCLE_STATE.PAST,
    })),
    ...(upcoming || []).map((cycle: IPaymentCycleData, index) => ({
      ...cycle,
      state: cycle.isActive ? PAYMENT_CYCLE_STATE.ACTIVE : PAYMENT_CYCLE_STATE.UPCOMING,
    })),
  ];
};

export const showCheckbox = (cycle: IPaymentCycleData) => {
  return (
    [PAYMENT_CYCLE_STATE.ACTIVE, PAYMENT_CYCLE_STATE.UNSCHEDULED].includes(cycle.state) ||
    (PAYMENT_CYCLE_STATE.UPCOMING === cycle.state && Object.keys(cycle.status).length === 0)
  );
};

export const filterAssignMenu = (cycle: IPaymentCycleData) => {
  return (
    [PAYMENT_CYCLE_STATE.ACTIVE, PAYMENT_CYCLE_STATE.UPCOMING].includes(cycle.state) &&
    ![
      CYCLE_STATUS_ENUM.PAID,
      CYCLE_STATUS_ENUM.REVERSED,
      CYCLE_STATUS_ENUM.SENDING_PAYMENT,
      CYCLE_STATUS_ENUM.WAITING_FUNDS,
    ].includes(cycle?.status?.key)
  );
};

export const refreshSelectedBillIds = (selectedBillIds: string[], currentCycleBillIds: IBillItem[]) => {
  if (selectedBillIds.length) {
    return selectedBillIds.filter((selectedBillId) =>
      currentCycleBillIds.map((bill) => bill.id).includes(selectedBillId)
    );
  }
  return [];
};
