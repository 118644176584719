import APIclient from "API/Client";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";

export const COUNTRY_CODE_REQ = "COUNTRY_CODE_REQ";
export const FETCH_OTP_SUCCESS = "FETCH_OTP_SUCCESS";
export const FETCH_OTP_FAILURE = "FETCH_OTP_FAILURE";

const countryCodeReq = (data, comp) => {
  return {
    type: COUNTRY_CODE_REQ,
    data: data,
  };
};
const fetchDataSuccess = (data) => {
  return {
    type: FETCH_OTP_SUCCESS,
    data: data,
  };
};
const fetchDataError = (data) => {
  return {
    type: FETCH_OTP_FAILURE,
    data: data,
  };
};

export const getCountryCode = (componentName = "default") => {
  return (dispatch) => {
    dispatch(countryCodeReq());

    APIclient.getData("/ms/common/v1/master/phone-code")
      .then((data) => {
        const { status_message } = data.data.payload;

        if (data.data.status > HTTP_STATUS_CODE.OK) {
          dispatch(fetchDataError(status_message));
          return Promise.reject(status_message);
        } else {
          dispatch(fetchDataSuccess(data.data.payload.payload));
          return data;
        }
      })
      .catch((err) => {
        const errorMessage = err || "Unable to get country code. Internal Server Error";
        dispatch(fetchDataError(errorMessage, componentName));
      });
  };
};
