import { FETCH_ROLE_REQUEST, FETCH_ROLE_SUCCESS, FETCH_ROLE_ERROR } from "../../Actions/OnBoarding/rolesAction";
import { userInitialState } from "../../InitialState";

export const employeeRoleReducer = (state = userInitialState, action) => {
  switch (action.type) {
    case FETCH_ROLE_REQUEST:
      return {
        ...state,
        loading: true,
        data: action.data,
        error: false,
      };
    case FETCH_ROLE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: false,
      };
    case FETCH_ROLE_ERROR:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: true,
      };
    default:
      return state;
  }
};
