import React, { useMemo, useState } from "react";

import { NavigationContext } from "Views/Navigation/Context";

/**
 * NavigationProvider component that manages popover visibility using a context.
 * @param {React.ReactNode} children - The children components to render within the NavigationProvider.
 * @returns {JSX.Element} The NavigationContext.Provider with the context values.
 */
export const NavigationProvider = ({ children }: { children: React.ReactNode }): React.ReactElement => {
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);

  const value = useMemo(
    () => ({
      isPopoverOpen,
      setIsPopoverOpen,
    }),
    [isPopoverOpen]
  );

  return <NavigationContext.Provider value={value}>{children}</NavigationContext.Provider>;
};
