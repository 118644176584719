import React from "react";
import { Col, Form } from "antd";

import { Button, Typography } from "@spenmo/splice";
import useLoading from "utility/useLoading";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";

import { chooseXeroOrganisation } from "../dataCall";
import Select from "../Select";

import styles from "./styles.module.scss";

export const selectXeroOrgBodyContent =
  "If you have more than one organization in your Xero account, select the one you would like to integrate Bank Feeds with.";

const ChooseOrganisation = ({ goToNextStep, connections, selectedOrganisation }) => {
  const [form] = Form.useForm();
  const defaultConnection = connections?.find((connection) => connection.tenantName === selectedOrganisation);
  const [selectXeroOrganisation, loading] = useLoading(chooseXeroOrganisation);

  const onFinish = async () => {
    const selectedTenant = connections.find((connection) => connection.tenantId === form.getFieldValue("tenantId"));
    const response = await selectXeroOrganisation({
      partnerTenantId: selectedTenant.tenantId,
      partnerTenantName: selectedTenant.tenantName,
      partnerConnectionId: selectedTenant?.id,
    });
    if (response?.data?.status === HTTP_STATUS_CODE.OK) {
      goToNextStep();
    }
  };

  return (
    <Form form={form} onFinish={onFinish}>
      <Col className={styles.column} span={14}>
        <Typography variant="headline-brand" size="s" tag="h3" children="Select your Xero Organisation" />
        <Typography variant="body-content" size="s" tag="h5" children={selectXeroOrgBodyContent} />
        <section className={styles.xeroOrganisation}>
          <Select
            placeholder="Select Xero Organisation"
            name="tenantId"
            initialValue={defaultConnection?.tenantId}
            label="Xero Organisation"
            rules={[]}
            data={connections?.map((connection) => ({
              key: connection.id,
              value: connection.tenantId,
              text: connection.tenantName,
            }))}
          />
        </section>
        <Button type="submit" variant="primary" size="l" loading={loading} className={styles.nextButton}>
          Next
        </Button>
      </Col>
    </Form>
  );
};

export default ChooseOrganisation;
