import { GET_TRXN_REQUEST_PARAMS } from "Views/Transaction/@types";

export const ALL_TRXNS_TABLE_LIMIT = 100;

export enum ALL_TRXNS_BULK_ACTION {
  DOWNLOAD = "Download CSV",
  CATEGORY = "Edit Categories",
}

export enum ALL_TRXN_BULK_ACTION_TYPE {
  CATEGORY = "BULK_UPDATE_CATEGORY",
  DOWNLOAD = "BULK_UPDATE_DOWNLOAD",
}

export enum ALL_TRXN_BULK_ACTION_POPOVER_CLASS {
  CATEGORY = "all_trxn_download_csv_bulk_action",
  DOWNLOAD = "all_trxn_edit_categories_bulk_action",
}

export enum ALL_TRXNS_COLUMNS {
  AMOUNT = "Amount",
  ATTACHMENT = "Attachment",
  CATEGORY = "Category",
  BUDGET = "Budget",
  CREATED = "Created",
  DATE_N_TIME = "Paid\nDate & Time",
  RECIPIENT = "Recipient/ Merchant",
  REQUESTER = "Requester",
  TYPE = "Type",
  EMPLOYEE = "Employee",
  TEAM = "Budget",
}

export const ALL_TRXN_PAGINATION_PARAMS = [GET_TRXN_REQUEST_PARAMS.SEARCH_AFTER, GET_TRXN_REQUEST_PARAMS.PREVIOUS_PAGE];
export const resetPaginationParams = {
  [GET_TRXN_REQUEST_PARAMS.SEARCH_AFTER]: null,
  [GET_TRXN_REQUEST_PARAMS.PREVIOUS_PAGE]: false,
};
