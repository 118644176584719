import * as React from "react";
import { infoIcon } from "assets/img";

import "./ErrorBox.scss";

export interface ErrorBoxProps {
  message: string;
  action?: {
    label: string;
    onClick: () => void;
  };
}

const ErrorBox: React.FC<ErrorBoxProps> = ({ message, action }: ErrorBoxProps) => {
  return (
    <div className="error-box">
      <img className="error-box__icon" src={infoIcon}  alt=""/>
      <div className="error-box__message">
        {message}
        {action && (
          <>
            {" "}
            <span className="error-box__action" onClick={action.onClick}>
              {action.label}
            </span>
          </>
        )}
      </div>
    </div>
  );
};

export default ErrorBox;
