import APIclient from "API/Client";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";

const AxiosPostForApiWrapper = (url, data) =>
  APIclient.postData(url, data)
    .then((response) =>
      response?.status >= HTTP_STATUS_CODE.OK && response?.status < HTTP_STATUS_CODE.MULTIPLE_CHOICES
        ? Promise.resolve(response?.data, response)
        : Promise.reject(response?.data)
    )
    .catch((err) => Promise.reject(err));

export const GetAllReimbursements = (
  page = 0,
  limit = 7,
  orgId = "",
  teamId = "",
  status = "pending",
  type = "cash"
) => {
  const body = {
    pg: page,
    limit: limit,
    expense_type: type,
    organisation_id: orgId,
    status: status,
    app: "portal",
  };
  return AxiosPostForApiWrapper(`/api/expenseSummary`, body);
};
