import * as React from "react";
import { Button, Typography } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { fetchCardNumber, clearCardNumber } from "Redux/Actions/Card/Number";
import { formatCardNumber } from "utility/Card";
import { trackEvent } from "utility/analytics";
import { CardDetailsEventNames } from "Views/Card/Analytics";
import { REVEAL_CARD_DETAILS_BTN_COLOR_MAP } from "constants/Card/CardColors.constants";
import Loader, { LoaderTypes } from "Views/Card/Components/Loader";
import { showErrorNotification } from "Views/Card/Components/Notification";
import { copyIconWhite24x24, whiteRefreshIcon16x16 } from "assets/icons/card";

import styles from "Views/Card/Details/Card/V2/Number/index.module.scss";

const { Text } = Typography;

export interface ICardNumberProps {
  cardId: string;
  cardLastFourDigits: string;
  cardColor: string;
}

const CardNumber = ({ cardId, cardLastFourDigits, cardColor }: ICardNumberProps) => {
  const dispatch = useDispatch();
  const { data, loading, error } = useSelector((state: any) => state?.cardNumberReducer || {});

  const showNotificationIfFailedToFetch = () => {
    if (error) {
      const { statusMessage = "Unable to fetch Card Number! Please try again later." } = data || {};
      showErrorNotification(statusMessage);
    }
  };

  React.useEffect(() => {
    showNotificationIfFailedToFetch();
  }, [data]);

  React.useEffect(() => {
    return () => {
      dispatch(clearCardNumber());
    };
  }, []);

  const getButtonText = () => {
    switch (true) {
      case error:
        return (
          <div className={styles["tap-to-refresh-container"]}>
            <p>Tap to refresh</p>
            <img src={whiteRefreshIcon16x16} width={16} height={16} alt="refresh icon" />
          </div>
        );
      case loading:
        return <Loader type={LoaderTypes.white_16x16} />;
      default:
        return "Show Card Number";
    }
  };

  const onClickHandler = () => {
    if (!error || !loading) {
      trackEvent(CardDetailsEventNames.SHOW_CARD_NUMBER_CLICKED);
    }
    dispatch(fetchCardNumber(cardId));
  };

  const getCardNumberData = () => {
    const { cardNumber = "" } = data || {};
    if (!!cardNumber) {
      return <Text className={styles["card-number"]}>{formatCardNumber(cardNumber)}</Text>;
    } else {
      return (
        <>
          <Button
            style={{ backgroundColor: REVEAL_CARD_DETAILS_BTN_COLOR_MAP?.[cardColor] || "" }}
            className={styles["show-card-number-button"]}
            onClick={onClickHandler}
          >
            {getButtonText()}
          </Button>
          <Text className={styles["card-number"]}>{cardLastFourDigits}</Text>
        </>
      );
    }
  };

  return (
    <Text
      copyable={{
        text: formatCardNumber(data?.cardNumber || cardLastFourDigits),
        icon: <img src={copyIconWhite24x24} width={24} height={24} alt="copy icon" className={styles["copy-icon"]} />,
        onCopy: () => {
          trackEvent(CardDetailsEventNames.CARD_NUMBER_COPIED);
        },
      }}
      className={styles["card-number-container"]}
    >
      {getCardNumberData()}
    </Text>
  );
};

export default CardNumber;
