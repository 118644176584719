import React, { useContext } from "react";

import { COMPANY_VERIFICATION } from "Route/KYX/paths";

import { FilterById } from "utility";

import RichHeader from "Modules/DS/RichHeader";

import {
  KYB_SOURCE,
  DOCUMENTS_INFO,
  KYB_METHOD_LIST,
  KYX_ORG_COUNTRY_LIST,
  KYX_BANNER_MESSAGE_TYPE,
} from "Views/KYX/Constants";
import { KYXModalContext } from "Views/KYX/Context";
import { KYXBanner } from "Views/KYX/Elements/Banner";
import { DocumentWithButton } from "Views/KYX/Intro/Document";
import { IDocumentsInfo, IKYXModalContext } from "Views/KYX/@types";
import { useRedirectionHandler, useStartKYXManually } from "Views/KYX/hooks";
import { usePostMyInfoBusinessAuthorise } from "Views/KYX/hooks/MyInfoBusiness";

import { ResumeManualButton, ResumeMyInfoButton } from "./Button";

import styles from "./index.module.scss";

const ModalWrapper = ({
  children,
  methodType,
  type,
}: {
  children: JSX.Element;
  methodType: KYB_METHOD_LIST;
  type: KYB_METHOD_LIST;
}) => {
  const { resetModal }: IKYXModalContext = useContext(KYXModalContext);
  return (
    <div className={styles.modal}>
      <RichHeader title={`Restart Verification with ${type}`} onClose={resetModal} />

      <div className={styles.content}>
        <KYXBanner
          bannerType="attention"
          messageType={KYX_BANNER_MESSAGE_TYPE.TITLE}
          title={`Current saved draft from ${methodType} will be lost`}
          message={`Restarting the verification process with ${type} will discard any data currently saved in draft`}
        />
        {children}
      </div>
    </div>
  );
};

const ChangeKYBMethodModal = ({ type }: { type: KYB_METHOD_LIST }): JSX.Element => {
  const redirect = useRedirectionHandler(COMPANY_VERIFICATION.COMPANY_INFORMATION);

  const { loading: manualLoader, startVerification } = useStartKYXManually(redirect);
  const { loading, myInfoBusinessAuthorise } = usePostMyInfoBusinessAuthorise();
  const details: IDocumentsInfo = DOCUMENTS_INFO()[KYX_ORG_COUNTRY_LIST.SGD][KYB_SOURCE.AUTOMATED];

  const onResumeMyInfo = () => {
    myInfoBusinessAuthorise()
      .then((result) => {
        window.location.href = result?.authorise_url as string;
      })
      .catch(() => {});
  };

  switch (type) {
    case KYB_METHOD_LIST.MY_INFO:
      return (
        <ModalWrapper methodType={KYB_METHOD_LIST.MANUAL} type={type}>
          <DocumentWithButton
            className="documentDetails"
            button={<ResumeMyInfoButton loading={loading} action={onResumeMyInfo} />}
            document={FilterById("myInfo", details?.documents)[0]}
          />
        </ModalWrapper>
      );

    case KYB_METHOD_LIST.MANUAL:
    default:
      return (
        <ModalWrapper methodType={KYB_METHOD_LIST.MY_INFO} type={type}>
          <DocumentWithButton
            className="documentDetails"
            button={<ResumeManualButton loading={manualLoader} action={startVerification} />}
            document={FilterById("manual", details?.documents)[0]}
          />
        </ModalWrapper>
      );
  }
};

export default ChangeKYBMethodModal;
