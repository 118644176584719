import React from "react";
import { FormInstance } from "antd/lib/form";
import { ReactNode } from "react";
import { OCRData } from "Views/UploadInvoice/InvoiceForm/VendorSection/types";
import { BANK_NAME_ALIAS } from "Views/UploadInvoice/const";
import { DynamicFormField } from "Modules/DynamicForm/types";
import { DYNAMIC_FORM_OCR_MAPPING } from "Modules/DynamicForm/constants";
import OCRTooltip from "Modules/ocrTooltip";

export const generateOCRLabel = (
  form: FormInstance,
  field: DynamicFormField,
  ocrData?: OCRData
): ReactNode | string => {
  const dynamicFieldValues = form.getFieldValue("dynamicFields");
  let fieldValue = dynamicFieldValues?.[field.id];
  const dynamicFormOCRKey = DYNAMIC_FORM_OCR_MAPPING[field.alias];
  let ocrValue = ocrData?.[dynamicFormOCRKey];
  if (field.alias === BANK_NAME_ALIAS) {
    const bankBasedOnOCR = field.options.filter((value) => value.additionalCode === ocrValue);
    const bankCodeByOCR = Boolean(bankBasedOnOCR.length) ? bankBasedOnOCR[0].value : null;
    if (Boolean(fieldValue) && bankCodeByOCR === fieldValue) {
      const banks = field.options.filter((value) => value.additionalCode === ocrValue);
      fieldValue = Boolean(banks.length) ? banks[0].additionalCode : null;
    }
  }

  return Boolean(ocrValue) ? (
    <OCRTooltip label={field.label} isOptional={!field.fieldProps.required} isOCROveridden={ocrValue !== fieldValue} />
  ) : (
    field.label
  );
};

export const autoChainOCR = (
  form: FormInstance,
  field: DynamicFormField,
  onFetchValue: (fieldID: number, value: string, autoChain?: boolean) => void,
  ocrData?: OCRData
) => {
  const dynamicFieldValues = form.getFieldValue("dynamicFields");
  const dynamicFieldsManuallyChanged = form.getFieldValue("dynamicFieldsManuallyChanged");
  const dynamicFormOCRKey = DYNAMIC_FORM_OCR_MAPPING[field.alias];
  let ocrValue = ocrData?.[dynamicFormOCRKey];
  if (
    Boolean(dynamicFormOCRKey) &&
    Boolean(ocrValue) &&
    !Boolean(dynamicFieldValues?.[field.id]) &&
    !Boolean(dynamicFieldsManuallyChanged?.[field.fieldID])
  ) {
    if (field.alias === BANK_NAME_ALIAS) {
      const banks = field.options.filter((value) => value.additionalCode === ocrValue);
      ocrValue = Boolean(banks.length) ? banks[0].value : null;
    }
    form.setFieldsValue({
      dynamicFields: {
        ...dynamicFieldValues,
        [field.id]: ocrValue,
      },
    });
    field.fieldProps.doFetch && onFetchValue(field.fieldID, ocrValue, true);
  }
};
