export const ActionTypes = {
  RequestMoney: "Request Money",
};

export const RequestMoneyEventKeys = {
  walletSelected: "walletSelected",
  currencySelected: "currencySelected",
  amountEntered: "amountEntered",
  photoUploaded: "photoUploaded",
  messageAdded: "messageAdded",
  confirmClicked: "confirmClicked",
};

export const AmountDetailsEventMapping = {
  [ActionTypes.RequestMoney]: {
    [RequestMoneyEventKeys.walletSelected]: "wallet selected",
    [RequestMoneyEventKeys.currencySelected]: "currency selected",
    [RequestMoneyEventKeys.amountEntered]: "amount entered",
    [RequestMoneyEventKeys.photoUploaded]: "photo uploaded",
    [RequestMoneyEventKeys.messageAdded]: "message added",
    [RequestMoneyEventKeys.confirmClicked]: "confirm clicked",
  },
};
