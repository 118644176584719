import APIclient from "API/Client";

export const FETCH_MERCHANT_REQUEST = "FETCH_MERCHANT_REQUEST";
export const FETCH_MERCHANT_SUCCESS = "FETCH_MERCHANT_SUCCESS";
export const FETCH_MERCHANT_FAILURE = "FETCH_MERCHANT_FAILURE";

const fetchDataReq = () => {
  return {
    type: FETCH_MERCHANT_REQUEST,
    data: {},
  };
};
const fetchDataSuccess = (data) => {
  return {
    type: FETCH_MERCHANT_SUCCESS,
    data: data,
  };
};

const fetchDataError = (data) => {
  return {
    type: FETCH_MERCHANT_FAILURE,
    data: data,
  };
};

export const getMerchantsFunc = () => {
  return (dispatch) => {
    dispatch(fetchDataReq());
    APIclient.getData("/api/v1/enumeration/merchants")
      .then((data) => {
        dispatch(fetchDataSuccess(data.data));
      })
      .catch((err) => {
        dispatch(fetchDataError(err));
      });
  };
};
