import React from "react";
import { Form } from "antd";

import { EInputType, ITransactionDetailProps, TransactionDetail } from "Modules/TransactionDetail";

import { IExpenseTrxnsDetails } from "Views/Transaction/@types";
import { includesReversalTrxns } from "Views/Transaction/Helper";
import { useExpenseForm } from "Views/Transaction/Details/useExpenseForm";
import { EXPENSE_INPUT_DETAILS_OPTIONS } from "Views/Transaction/Constants";

export const Details = ({
  taxId,
  notes,
  details,
  receipts,
  trxnId,
  trxnType,
  trxnNumber,
  categoryId,
  categoryName,
  closeDetails,
  handleSaveRef,
  tags,
  disableEditing = false,
  taxName,
}: IExpenseTrxnsDetails) => {
  const { form, buttonAction, defaultValue, setFormValue } = useExpenseForm(
    trxnNumber,
    closeDetails,
    receipts,
    notes,
    taxId,
    categoryId,
    trxnId,
    tags
  );

  handleSaveRef.current = buttonAction;

  let props: ITransactionDetailProps = {
    form,
    details,
    defaultValue,
    setFormValue,
    missingFields: defaultValue,
    disabled: disableEditing || includesReversalTrxns(trxnType),
    visibleInput: { ...EXPENSE_INPUT_DETAILS_OPTIONS, [EInputType.RECEIPT]: false, [EInputType.SUBSCRIPTION]: false },
    categoryFallback: categoryName,
    taxFallback: taxName,
  };

  return (
    <Form form={form}>
      <TransactionDetail {...props} ignoreRequiredCheck />
    </Form>
  );
};
