import React, { useEffect, useState } from "react";
import { Form, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { debounce, ValidateNumber } from "utility/index";
import Button from "Modules/button";
import { CheckPhoneNumber, updateProfile } from "Redux/Actions";
import PhoneCodesDropdown from "Modules/phoneCodesDropdown";
import LoaderIcon from "Views/State/Loading/LoaderIcon";

const CardActivationForm = (props) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { phoneCodes = [] } = props;
  const DELAY_CHECK_DATA = 1000;

  const { checkPhoneNumDetails, userInfo } = useSelector((state) => ({
    checkPhoneNumDetails: state.checkPhoneNumReducer?.data,
    userInfo: state.userInfoReducer?.data?.payload?.user,
  }));

  const existingPhoneCode = userInfo?.mobile_country_code?.toString();
  const DEFAULT_PHONE_CODE = "65";
  const [phoneCode, setPhoneCode] = useState(existingPhoneCode || DEFAULT_PHONE_CODE);
  const [isPhoneNumExist, setIsPhoneNumExist] = useState(false);

  useEffect(() => {
    setIsPhoneNumExist(checkPhoneNumDetails?.isExisted);
  }, [checkPhoneNumDetails]);

  const handleValueChange = debounce((value, type) => {
    if (type === "phoneNum" && value !== "") {
      form.setFieldsValue({ mobile_number: value });
      dispatch(CheckPhoneNumber(phoneCode, value));
    }
  }, DELAY_CHECK_DATA);

  const onUpdateProfile = (values) => {
    const mobile_country_code = phoneCode;
    dispatch(updateProfile({ ...values, mobile_country_code }));
  };

  if (!userInfo?.name) {
    return <LoaderIcon />;
  }

  return (
    <Form
      form={form}
      colon={false}
      initialValues={{ mobile_number: userInfo?.mobile_number?.toString(), email: userInfo?.email }}
      onFinish={onUpdateProfile}
      validateTrigger={true}
    >
      <Form.Item id="firstName" label="First Name" name="first_name" rules={[{ required: true }]}>
        <Input data-testid="first-name" placeholder="Please enter your first name" />
      </Form.Item>
      <Form.Item id="lastName" label="Last Name" name="last_name" rules={[{ required: true }]}>
        <Input data-testid="last-name" placeholder="Please enter your last name" />
      </Form.Item>
      <Form.Item id="email" label="Email" name="email">
        <Input disabled defaultValue={userInfo?.email} />
      </Form.Item>
      <Form.Item id="phoneNumber" className="phone-details" label="Phone Number">
        <PhoneCodesDropdown
          disabled={userInfo?.mobile_country_code}
          currentValue={phoneCode}
          phoneCodes={phoneCodes}
          action={setPhoneCode}
        />
        <Form.Item
          id="mobileNumber"
          name="mobile_number"
          rules={[{ required: true, pattern: ValidateNumber(), message: "phone number should only contain numbers" }]}
          validateTrigger="onKeyUp"
        >
          <Input
            type="text"
            className="phone-number-field"
            disabled={userInfo?.mobile_number}
            maxlength="12"
            data-testid="phone-number-field"
            defaultValue={userInfo?.mobile_number}
            onKeyUp={(e) => handleValueChange(e.target.value, "phoneNum")}
            placeholder="Please enter your phone number"
          />
        </Form.Item>
        {isPhoneNumExist && (
          <div className="error-message">
            This phone number has been used by other user. Please check for mistake or type a different number.
          </div>
        )}
      </Form.Item>
      <Form.Item shouldUpdate>
        {() => (
          <Button
            disabled={
              !form.isFieldTouched("first_name") ||
              !form.isFieldTouched("last_name") ||
              (userInfo?.mobile_number ? false : !form.isFieldTouched("mobile_number")) ||
              !!form.getFieldsError().filter(({ errors }) => errors.length).length
            }
            className={"update-profile-btn"}
            rounded
            text={"Update Profile"}
          />
        )}
      </Form.Item>
    </Form>
  );
};

CardActivationForm.propTypes = {
  phoneCodes: PropTypes.any,
};

export default CardActivationForm;
