import React from "react";
import { Input, Form } from "antd";
import { InputProps } from "antd/lib/input";

import { capitalizeFirstLetter } from "utility";

import TooltipOnIcon from "Modules/TooltipOnIcon";

import { blueInfoCircle } from "assets/img";
import "./styles.scss";

export const InputAccountCode = ({ accountingSoftware }: { accountingSoftware: string }) => {
  const inputProps: InputProps = {
    maxLength: 100,
    placeholder: "Enter Spenmo bank account code",
    defaultValue: localStorage.getItem("accountCode") || "",
  };

  const tooltipProps = {
    placement: "bottom",
    imageSrc: blueInfoCircle,
    altText: "Spenmo account code tooltip",
    description: `The account code used for your Spenmo bank account in ${capitalizeFirstLetter(accountingSoftware)}.`,
  };

  return (
    <Form.Item name="accountCode">
      <div className="account-code-input">
        <Input {...inputProps} />
        <TooltipOnIcon {...tooltipProps} />
      </div>
    </Form.Item>
  );
};
