import * as React from "react";
import ActionBtns from "Views/Card/CreateOrRequest/ActionBtns";
import SpendingLimits from "Views/Card/Components/SpendingLimit";
import { ExpenseCategory } from "Views/Card/Components/ExpenseCategory";
import { Tooltip, TooltipArrowAlignmentEnum, TooltipPositionEnum } from "Modules/DS/Tooltip";
import { useTransactionTags } from "customHooks/Card";
import { useCreateOrRequestCardContext } from "Views/Card/CreateOrRequest/index.context";
import { getCookieValue, cookieNames } from "utility/CookieHelper";
import { Actions } from "Views/Card/CreateOrRequest/Utilities/UseCreateOrRequestState";
import { onlyPositiveIntegersWithFractionsRegEx } from "constants/RegularExpressions.constant";
import { DEFAULT_TRANSACTION_TAGS_KB_ARTICLE_LINK } from "constants/Card";
import { CARD_LIMIT_TYPES, CARD_TYPE } from "Views/Card/types";
import { CARD_LIMITS_LABELS } from "Views/Card/Components/SpendingLimit/index.constants";
import { trackEvent } from "utility/analytics";
import { CardCreationEventNames } from "Views/Card/Analytics";
import TagsDropdown from "./TagsDropdown";
import { helpIcon16x16, infoIcon16x16 } from "assets/icons/card/";

import "Views/Card/CreateOrRequest/Steps/Policy/index.scss";
import styles from "./index.module.scss";

export const Policy = () => {
  const { state, dispatch, changeStep, cardType } = useCreateOrRequestCardContext();
  const { adminOrManagerInSelectedTeam, cardLimit, cardLimitType, letMyManagerChooseLimit } = state;
  const currencyCode = getCookieValue(cookieNames.CURRENCY_CODE);

  const trackPolicyEvent = (payload: { [key: string]: string } = {}) => {
    trackEvent(CardCreationEventNames.CARD_CREATION_SETTINGS_SELECTED, {
      is_virtual_card: cardType === CARD_TYPE.VIRTUAL,
      ...payload,
    });
  };

  const onSpendingLimitChange = (limit) => {
    if (onlyPositiveIntegersWithFractionsRegEx?.test?.(limit) || limit === "") {
      dispatch({ type: Actions.UPDATE_CARD_LIMIT, value: limit });
    }
  };

  const onSpendingLimitTypeChange = (limitType: CARD_LIMIT_TYPES) => {
    dispatch({ type: Actions.UPDATE_LET_MY_MANAGER_CHOOSE_LIMIT, value: false });
    dispatch({ type: Actions.UPDATE_CARD_LIMIT_TYPE, value: limitType });
    trackPolicyEvent({
      card_creation_event_source: "Spend Limit Period",
      card_creation_spend_limit: CARD_LIMITS_LABELS[limitType],
    });
  };

  const onSpendingLimitBlur = () => {
    if (cardLimit && cardLimitType !== CARD_LIMIT_TYPES.unlimited) {
      trackPolicyEvent({
        card_creation_event_source: "Spend Limit Amount",
        card_spend_limit_amount: cardLimit,
      });
    }
  };

  const onExpenseCategorySelection = (expenseCategoryId: number, expenseCategoryName: string) => {
    dispatch({ type: Actions.UPDATE_EXPENSE_CATEGORY_ID, value: expenseCategoryId });
    dispatch({ type: Actions.UPDATE_EXPENSE_CATEGORY_NAME, value: expenseCategoryName });
    expenseCategoryId &&
      trackPolicyEvent({
        card_creation_event_source: "Expense Category",
      });
  };

  const onLetMyMangerChooseLimit = (letMyManagerChooseLimit: boolean) => {
    dispatch({
      type: Actions.UPDATE_CARD_LIMIT_TYPE,
      value: letMyManagerChooseLimit ? null : CARD_LIMIT_TYPES.monthlyLimit,
    });
    letMyManagerChooseLimit && dispatch({ type: Actions.UPDATE_CARD_LIMIT, value: "" });
    dispatch({ type: Actions.UPDATE_LET_MY_MANAGER_CHOOSE_LIMIT, value: letMyManagerChooseLimit });
  };

  const enableNextStep = () => {
    return cardLimitType === CARD_LIMIT_TYPES.unlimited || (cardLimitType && cardLimit) || letMyManagerChooseLimit;
  };

  const onTransactionTagSelection = (data) => {
    dispatch({
      type: Actions.UPDATE_TAGS,
      value: data,
    });
  };
  const showTransactionTags = useTransactionTags();

  return (
    <>
      <div className="create-or-request-card-steps">
        <p className="create-or-request-card-steps__header">Set up policy</p>
        <p className="create-or-request-card-steps__sub-header">
          Card policy will be subject to your Budget Owner's approval.
        </p>
        <section className="create-or-request-card-steps__form-container with-subheader">
          <p className="create-or-request-card-steps__input-label sp-margin-top--xl">Spending Limit</p>
          <SpendingLimits
            cardLimitType={state.cardLimitType}
            cardLimit={state.cardLimit}
            onSpendingLimitTypeChange={onSpendingLimitTypeChange}
            onSpendingLimitChange={onSpendingLimitChange}
            currencyCode={currencyCode}
            showLetMyManagerChooseLimit={!adminOrManagerInSelectedTeam}
            onLetMyMangerChooseLimit={onLetMyMangerChooseLimit}
            letMyManagerChooseLimit={letMyManagerChooseLimit}
            showBlueBackgroundForLimitInput={!adminOrManagerInSelectedTeam}
            onInputBlur={onSpendingLimitBlur}
          />
          <div className="create-or-request-card-steps__input-label sp-margin-top--xl">
            <span>Default Expense Category</span>
            <Tooltip
              text="Every expense in this card will be automatically categorized into the selected category."
              alignArrow={TooltipArrowAlignmentEnum.CENTER}
              position={TooltipPositionEnum.TOP}
            >
              <img src={infoIcon16x16} alt="Help Icon" />
            </Tooltip>
            <span className={styles["optional-field-label"]}>(optional)</span>
          </div>
          <ExpenseCategory selectExpenseCategoryID={state.expenseCategoryId} onSelect={onExpenseCategorySelection} />
          {showTransactionTags ? (
            <>
              <div className="create-or-request-card-steps__input-label sp-margin-top--xl">
                <p>Default Transaction Tags</p>
                <a
                  href={DEFAULT_TRANSACTION_TAGS_KB_ARTICLE_LINK}
                  className="create-or-request-card-steps__input-label__link"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Tooltip
                    text="Click to read about Transaction Tag"
                    alignArrow={TooltipArrowAlignmentEnum.CENTER}
                    position={TooltipPositionEnum.TOP}
                  >
                    <img src={helpIcon16x16} alt="Help Icon" />
                  </Tooltip>
                </a>
                <span className={styles["optional-field-label"]}>(optional)</span>
              </div>
              <TagsDropdown onChange={onTransactionTagSelection} />
            </>
          ) : null}
        </section>
      </div>
      <ActionBtns
        actionBtnName="Next"
        changeStep={changeStep}
        renderGoBackBtn={true}
        disableNextBtn={!enableNextStep()}
      />
    </>
  );
};
