import {
  FETCH_CARD_ACTIVATE_REQUEST,
  FETCH_CARD_ACTIVATE_SUCCESS,
  FETCH_CARD_ACTIVATE_FAILURE,
  RESET_CARD_ACTIVATE_DATA,
} from "Redux/Actions/cardActivateAction";
import { userInitialState } from "../InitialState";

export const cardActivateReducer = (state = userInitialState, action) => {
  switch (action.type) {
    case FETCH_CARD_ACTIVATE_REQUEST:
      return {
        ...state,
        data: action.data,
        loading: true,
        error: false,
      };
    case FETCH_CARD_ACTIVATE_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
        error: false,
      };
    case FETCH_CARD_ACTIVATE_FAILURE:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: true,
      };
    case RESET_CARD_ACTIVATE_DATA:
      return {
        ...userInitialState,
      };
    default:
      return state;
  }
};
