import React from "react";
import { useLocation } from "react-router-dom";
import { Typography, Tags } from "@spenmo/splice";
import { tagVariant } from "@spenmo/splice/lib/shared/types";
import { GetCurrencyCode, currencyFormatterV2 } from "utility";
import {
  BILLING_FREQUENCY,
  SUBSCRIPTION_STATUS,
  SUBSCRIPTION_STATUS_LABELS,
  SUMMARY_TAB_VALUES,
} from "Views/SubscriptionManagement/Constants";
import { useSubscriptionContext } from "Views/SubscriptionManagement/Details/SubscriptionContext";
import {
  LastPaymentAlert,
  VariationPercentage,
} from "Views/SubscriptionManagement/Components/Tooltips";

import { Tabs } from "./Tabs";
import { Grid } from "./Grid";

import styles from "./index.module.scss";

const billingCycleFormat = (cycle: string) => {
  switch (cycle) {
    case BILLING_FREQUENCY.ANNUALLY:
      return "/year";
    default:
      return "/" + cycle.slice(0, -2).toLowerCase();
  }
};

const statusFormat = (
  status: string,
): { label: string; variant: tagVariant } => {
  return {
    label: SUBSCRIPTION_STATUS_LABELS[status],
    variant: status === SUBSCRIPTION_STATUS.ACTIVE ? "success" : "danger",
  };
};

export const Summary = () => {
  const { subscription } = useSubscriptionContext();
  const location = useLocation();

  const {
    estimated_cost,
    variation_percentage,
    currency_code,
    billing_frequency,
    vendor,
    last_payment_amount,
    last_payment_Foreign_amount,
    last_payment_foreign_currency,
  } = subscription;
  const status = statusFormat(subscription.status);
  const paymentThreshold =
    estimated_cost + estimated_cost * (variation_percentage / 100);

  const findCurrency = () => {
    if (last_payment_foreign_currency && last_payment_Foreign_amount) {
      return {
        currency: last_payment_foreign_currency,
        amount: last_payment_Foreign_amount,
      };
    }

    return {
      currency: GetCurrencyCode(),
      amount: last_payment_amount,
    };
  };

  const { currency, amount } = findCurrency();

  const vendorAndCost = [
    {
      label: "Vendor",
      value: vendor?.name,
    },
    {
      label: "Estimated cost",
      value:
        currencyFormatterV2(estimated_cost, currency_code) +
        billingCycleFormat(billing_frequency),
      tag: <VariationPercentage percentage={variation_percentage} />,
    },
    {
      label: "Last payment",
      value: (
        <div>
          <div className={styles.smallFlex}>
            <Typography
              size="s"
              variant="body-content"
              tag="p"
              className={styles.originalCurrency}
            >
              {currencyFormatterV2(amount, currency, true, 2)}
            </Typography>
            {amount > paymentThreshold && (
              <LastPaymentAlert
                gap={currencyFormatterV2(
                  amount - paymentThreshold,
                  currency,
                  true,
                  2,
                )}
              />
            )}
          </div>
          {last_payment_foreign_currency &&
            last_payment_foreign_currency !== currency_code && (
              <Typography
                size="caption-m"
                variant="body-content"
                tag="p"
                className={styles.dashboardCurrency}
              >
                {currencyFormatterV2(
                  last_payment_Foreign_amount || 0,
                  last_payment_foreign_currency,
                  true,
                  2,
                )}
              </Typography>
            )}
        </div>
      ),
    },
  ];

  return (
    <div className={styles.container}>
      <div className={styles.flexbox}>
        <Typography
          variant="body-content"
          size="m"
          weight={600}
          className={styles.textBlack}
        >
          Contract summary
        </Typography>
        <Tags.Progression
          hierarchy="default"
          label={status.label}
          variant={status.variant}
        />
      </div>
      <div className={styles.vendor}>
        <Grid items={vendorAndCost} />
      </div>
      <Tabs
        defaultActiveTab={
          (new URLSearchParams(location.search).get(
            "tab",
          ) as SUMMARY_TAB_VALUES) || SUMMARY_TAB_VALUES.PAYMENT
        }
      />
    </div>
  );
};
