import React from "react";
import { Row, Col } from "antd";
import Button from "Modules/button";
import { AddAsABankAccountStepsData } from "constants/Integration.constant";
import { rightArrowWhite16x16 } from "assets/img";

import styles from "./AddSpenmoAsABankAccount.module.scss";

const AddSpenmoAsaBankAccount = ({ goToNextStep }) => {
  return (
    <>
      <Row>
        {AddAsABankAccountStepsData(styles).map((item, index) => (
          <Col className={styles.stepCard} key={item.key}>
            <Row justify="space-between" align="middle" className={styles.section}>
              <Col>
                <p className={styles.title}>Step {index + 1}</p>
                <p className={styles.content}>{item.contentText}</p>
              </Col>
              <Col>
                <img src={item.illustration} alt={item.key} />
              </Col>
            </Row>
          </Col>
        ))}
      </Row>
      <Row justify="end" align="middle" className="sp-margin-top--md">
        <Col>
          <Button rounded action={goToNextStep} size="xl" className={styles.doneCta}>
            I’m Done!
            <img src={rightArrowWhite16x16} alt="right arrow icon" />
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default AddSpenmoAsaBankAccount;
