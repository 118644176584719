import React, { useContext, useEffect } from "react";
import { POPOVER_WIDTH } from "Modules/DS/Popover";
import { ISelectContext, SelectContext } from "Modules/DS/Select";

import {
  IExportOptions,
  ISmartCSVProps,
  IPopoverWithBanner,
  GET_TRXN_REQUEST_PARAMS,
  ITrxnsDateFilterRequest,
} from "Views/Transaction/@types";
import { DownloadButton } from "Views/Transaction/Download";
import { PopoverWithBanner } from "Views/Transaction/Popover";
import { DOWNLOAD_SMART_CSV } from "Views/Transaction/Constants";

import { ExportOptions } from "./Options";
import { thisMonthDateRange, useSmartCsvFilters } from "./utilities";

import styles from "./index.module.scss";
import { Button } from "@spenmo/splice";
import cn from "classnames";

export const SmartCSV = <T extends ITrxnsDateFilterRequest>({
  fetchCount,
  fetchReceiptsCount,
  fetchCsvCount,
  totalCSVCount,
  totalStatementCount,
  totalReceiptsCount,
  filter,
  bankStatement,
  emailBankStatement,
  emailDownloadReceipt,
  emailRawCSV,
  exportRawCSV,
  customCsv,
  rawCsv,
  showModalType,
  downloadReceipt,
  isExportButtonEnabled,
}: ISmartCSVProps<T>) => {
  const { select, onSelectHandler } = useContext<ISelectContext>(SelectContext);

  const visible: boolean = select.show && select.key === DOWNLOAD_SMART_CSV;

  const onClickHandler = () => onSelectHandler(DOWNLOAD_SMART_CSV);

  const noFilters = Object.values(filter).filter(Boolean).length === 0;
  const stringifiedFilters: string = JSON.stringify(filter);

  const _filters: T = useSmartCsvFilters(filter);

  useEffect(() => {
    bankStatement.enabled && fetchCount(_filters);
    fetchCsvCount(_filters);
    downloadReceipt.enabled && fetchReceiptsCount(_filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stringifiedFilters, noFilters]);

  const options: IExportOptions<T> = {
    totalCSVCount,
    totalStatementCount,
    totalReceiptsCount,
    rawCsv,
    customCsv,
    bankStatement,
    showModalType,
    emailBankStatement,
    emailDownloadReceipt,
    emailRawCSV,
    exportRawCSV,
    downloadReceipt,
    filters: filter,
  };

  const startDate: string = filter?.[GET_TRXN_REQUEST_PARAMS.START_DATE]
    ? new Date(filter[GET_TRXN_REQUEST_PARAMS.START_DATE]).toLocaleDateString()
    : new Date(thisMonthDateRange()[GET_TRXN_REQUEST_PARAMS.START_DATE]).toLocaleDateString();

  const endDate: string = filter?.[GET_TRXN_REQUEST_PARAMS.END_DATE]
    ? new Date(filter[GET_TRXN_REQUEST_PARAMS.END_DATE]).toLocaleDateString()
    : new Date(thisMonthDateRange()[GET_TRXN_REQUEST_PARAMS.END_DATE]).toLocaleDateString();

  const banner = () => {
    return (
      <span className={styles.export}>
        Export Date : <strong>{startDate}</strong> - <strong>{endDate}</strong>
      </span>
    );
  };

  const props: IPopoverWithBanner = {
    active: visible,
    width: POPOVER_WIDTH.R,
    className: cn(styles.popover, { [styles.exportPopover]: isExportButtonEnabled }),
    overrideBanner: banner(),
    headerText: "Downloading current table view",
    headerInfo: "The current displayed rows (after applying filters) in the table will be marked for download",
    children: <ExportOptions {...options} />,
    useDynamicPosition: false,
  };

  return (
    <div className={styles.container}>
      {isExportButtonEnabled ? (
        <Button variant="primary" size="s" onClick={onClickHandler}>
          Export
        </Button>
      ) : (
        <DownloadButton onClickHandler={onClickHandler} />
      )}
      <PopoverWithBanner {...props} />
    </div>
  );
};

export * from "./AccountingSoftware";
export * from "./AccountCode";
export * from "./utilities";
export * from "./Options";
export * from "./Modal";
