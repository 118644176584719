import React from "react";

import { IGuidelinesInfo } from "Views/KYX/@types";
import { KYX_BANNER_MESSAGE_TYPE } from "Views/KYX/Constants/Elements";

import { DocumentGuidelines } from "assets/img/document-guidelines";
import { Cross } from "assets/v1.1/icons/Navigation/Cross";
import { Check } from "assets/v1.1/icons/Core/Check";

import styles from "Views/KYX/index.module.scss";

export enum GUIDELINES_TYPE {
  NRIC = "NRIC",
  WORK_PASS = "WorkPass",
  PASSPORT = "Passport",
  ARCA = "Acra",
  PROOF_OF_ADDRESS = "ProofOfAddress",
  OWNERSHIP = "Ownership",
  MEMORANDUM = "Memorandum",
  BUSINESS_ACTIVITY = "BusinessActivity",
  BOARD_RESOLUTION_FORM = "BoardResolutionForm",
}

export enum GUIDELINES_STATES {
  SUCCESS = "success",
  ERROR = "error",
}

const NRIC: IGuidelinesInfo = {
  title: "Document Guidelines",
  data: {
    title: "NRIC Document Guidelines",
    instructions: [
      "Documents must be in color, with clear and readable text. Black and white and/or low-quality images are not accepted.",
      "Documents must be uploaded in PDF, JPEG, or PNG format. Maximum file size is 4MB.",
      "Documents must not be cropped.",
    ],
    documents: [
      {
        image: DocumentGuidelines.NRIC.correct,
        state: GUIDELINES_STATES.SUCCESS,
        icon: Check.Fill.green_18,
      },
      {
        image: DocumentGuidelines.NRIC.wrong,
        state: GUIDELINES_STATES.ERROR,
        icon: Cross.Fill.red_18,
      },
    ],
  },
};

const BusinessActivity = {
  title: "Business Activity Description Guidelines",
  data: {
    title: "Your business activity description must include:",
    instructions: [
      "Product or services provided by the business",
      "Business headquarter and branch locations",
      "Breakdown of revenue generators by region, product, or user segment",
      "Business expansion plan to other regions or other product/services",
    ],
    banner: {
      title: "Business activity description example:",
      bannerType: "neutral",
      messageType: KYX_BANNER_MESSAGE_TYPE.TITLE,
      message:
        "Alta is an International Sea & Air Freight Forwarder based in Singapore. We specialize in Export & Import Customs Handling, Inland Transportation & Project Logistics provider for all businesses. We are currently present in several regions across Asia such as Indonesia, Malaysia, Thailand, Philippines and Europe such as Spain, Italy, Germany, Greece. The Asian market generates 70% of our total revenue. We are planning to expand our business to Australia and North America within the next 3 years.",
    },
  },
};

const WorkPass: IGuidelinesInfo = {
  title: "Document Guidelines",
  data: {
    title: "Work Pass Document Guidelines",
    instructions: [
      "Documents must be in a clear and readable text. Low-quality images are not accepted.",
      "Documents must be uploaded in PDF, JPEG, or PNG format. Maximum file size is 4MB.",
      "Documents must not be cropped.",
    ],
    documents: [
      {
        image: DocumentGuidelines.WorkPass.correct,
        state: GUIDELINES_STATES.SUCCESS,
        icon: Check.Fill.green_18,
      },
      {
        image: DocumentGuidelines.WorkPass.wrong,
        state: GUIDELINES_STATES.ERROR,
        icon: Cross.Fill.red_18,
      },
    ],
  },
};

const Passport: IGuidelinesInfo = {
  title: "Document Guidelines",
  data: {
    title: "Passport Document Guidelines",
    instructions: [
      "Documents must be in color, with clear and readable text. Black and white and/or low-quality images are not accepted.",
      "Documents must be uploaded in PDF, JPEG, or PNG format. Maximum file size is 4MB.",
      "Documents must not be cropped.",
    ],
    documents: [
      {
        image: DocumentGuidelines.Passport.correct,
        state: GUIDELINES_STATES.SUCCESS,
        icon: Check.Fill.green_18,
      },
      {
        image: DocumentGuidelines.Passport.wrong,
        state: GUIDELINES_STATES.ERROR,
        icon: Cross.Fill.red_18,
      },
    ],
  },
};

const Acra: IGuidelinesInfo = {
  title: "Document Guidelines",
  data: {
    title:
      "ACRA (Accounting and Corporate Regulatory Authority) Document Guidelines",
    instructions: [
      "Documents must be in color, with clear and readable text. Black and white and/or low-quality images are not accepted.",
      "Documents must be uploaded in PDF, JPEG, or PNG format. Maximum file size is 4MB.",
      "Documents must not be cropped.",
    ],
    documents: [
      {
        image: DocumentGuidelines.Acra.correct,
        state: GUIDELINES_STATES.SUCCESS,
        icon: Check.Fill.green_18,
      },
      {
        image: DocumentGuidelines.Acra.wrong,
        state: GUIDELINES_STATES.ERROR,
        icon: Cross.Fill.red_18,
      },
    ],
  },
};

const ProofOfAddress: IGuidelinesInfo = {
  title: "Document Guidelines",
  data: {
    title: "Proof of Address Document Guidelines",
    instructions: [
      "Documents must be in a clear and readable text. Low-quality images are not accepted.",
      "Documents must be uploaded in PDF, JPEG, or PNG format. Maximum file size is 4MB.",
      "Documents must not be cropped.",
    ],
    documents: [
      {
        image: DocumentGuidelines.ProofOfAddress.correct,
        state: GUIDELINES_STATES.SUCCESS,
        icon: Check.Fill.green_18,
      },
      {
        image: DocumentGuidelines.ProofOfAddress.wrong,
        state: GUIDELINES_STATES.ERROR,
        icon: Cross.Fill.red_18,
      },
    ],
  },
};

const Ownership: IGuidelinesInfo = {
  title: "Document Guidelines",
  data: {
    title: "Ownership Structure Document Guidelines",
    instructions: [
      "Documents must be in color, with clear and readable text. Black and white and/or low-quality images are not accepted.",
      "Documents must be uploaded in PDF, JPEG, or PNG format. Maximum file size is 4MB.",
      "Documents must not be cropped.",
    ],
    documents: [
      {
        image: DocumentGuidelines.Ownership.correct,
        state: GUIDELINES_STATES.SUCCESS,
        icon: Check.Fill.green_18,
      },
      {
        image: DocumentGuidelines.Ownership.wrong,
        state: GUIDELINES_STATES.ERROR,
        icon: Cross.Fill.red_18,
      },
    ],
  },
};

const MemorandumLink: JSX.Element = (
  <p>
    Go <a href="https://www.bizfile.gov.sg">https://www.bizfile.gov.sg</a> &gt;
    Enter your Unique Entity Number (UEN) in the search bar &gt; click Search
    again mid-screen.
  </p>
);

const Memorandum: IGuidelinesInfo = {
  title: "Document Guidelines",
  data: {
    title:
      "Memorandum & Articles of Association / Constitution Document Guidelines",
    subTitle:
      "The constitution must be signed by all subscribers, or be an extract from ACRA (Available to Singapore businesses only). Pictures or scans are accepted.",
    label:
      "For Singapore businesses, you can purchase this with ACRA by following these steps:",
    instructions: [
      MemorandumLink,
      "Click on Other Information in the search bar results > Click Search > Scroll all the way down to the section Extracts > Under Category, select Company Incorporation (M&AA)/Constitution. For the Transaction Date, select Last 5 Years and click Find; (if unavailable try selecting the other options eg. Last 10 years, etc.)",
      "Select the First option under Extract. Purchase it. Notes: eCertification is not needed (no need to buy the eCertificate version).",
    ],
  },
};

// Replace with actual link
const boardOfResolutionLink: JSX.Element = (
  <p>
    Download your{" "}
    <a
      href="https://spenmo-prod.s3.ap-southeast-1.amazonaws.com/template-csv/Spenmo+Board+Resolution+Form.pdf"
      download="Board resolution form template"
      rel="noopener noreferrer"
      className={styles.link}
    >
      Signed Board Resolution form template
    </a>
  </p>
);

const BoardOfResolution: IGuidelinesInfo = {
  title: "Document Guidelines",
  data: {
    title: "Signed Board Resolution Form",
    subTitle:
      "This form must be signed and filled with this following guidelines:",
    instructions: [
      boardOfResolutionLink,
      "Ensure all relevant stakeholders put their signature on the document upon uploading.",
      "Documents must be uploaded in PDF, JPEG, or PNG format. Maximum file size is 4MB.",
      "Documents must not be cropped.",
    ],
  },
};

export const GuidelinesData: {
  [GUIDELINES_TYPE.NRIC]: IGuidelinesInfo;
  [GUIDELINES_TYPE.WORK_PASS]: IGuidelinesInfo;
  [GUIDELINES_TYPE.PASSPORT]: IGuidelinesInfo;
  [GUIDELINES_TYPE.ARCA]: IGuidelinesInfo;
  [GUIDELINES_TYPE.PROOF_OF_ADDRESS]: IGuidelinesInfo;
  [GUIDELINES_TYPE.OWNERSHIP]: IGuidelinesInfo;
  [GUIDELINES_TYPE.MEMORANDUM]: IGuidelinesInfo;
  [GUIDELINES_TYPE.BUSINESS_ACTIVITY]: IGuidelinesInfo;
  [GUIDELINES_TYPE.BOARD_RESOLUTION_FORM]: IGuidelinesInfo;
} = {
  [GUIDELINES_TYPE.NRIC]: NRIC,
  [GUIDELINES_TYPE.WORK_PASS]: WorkPass,
  [GUIDELINES_TYPE.PASSPORT]: Passport,
  [GUIDELINES_TYPE.ARCA]: Acra,
  [GUIDELINES_TYPE.PROOF_OF_ADDRESS]: ProofOfAddress,
  [GUIDELINES_TYPE.OWNERSHIP]: Ownership,
  [GUIDELINES_TYPE.MEMORANDUM]: Memorandum,
  [GUIDELINES_TYPE.BUSINESS_ACTIVITY]: BusinessActivity,
  [GUIDELINES_TYPE.BOARD_RESOLUTION_FORM]: BoardOfResolution,
};
