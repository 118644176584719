import { useState } from "react";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import { findTransactionInXero } from "Redux/DataCalls/AccountingWorkflow.api";

export interface IBillImportedFromXeroData {
  link?: string;
  xeroInvoiceLinkFailure?: boolean;
  billID?: string;
  identifierType?: string;
  errorMsg?: string;
}

export const useFetchInvoiceLink = () => {
  const [loadingXeroInvoiceLink, setLoadingXeroInvoiceLink] = useState(false);
  const [billImportedFromXeroData, setBillImportedFromXeroData] = useState<IBillImportedFromXeroData>(null);

  const fetchInvoiceLink = async (billID: string, identifierType = "ledgerId") => {
    const errorResponse = { xeroInvoiceLinkFailure: true, billID, identifierType };
    try {
      setLoadingXeroInvoiceLink(true);
      const res = await findTransactionInXero(billID, identifierType);
      setBillImportedFromXeroData(
        res.status === HTTP_STATUS_CODE.OK && res?.data?.payload.link
          ? res?.data?.payload
          : { ...errorResponse, errorMsg: res?.data?.payload?.status_message }
      );
      setLoadingXeroInvoiceLink(false);
    } catch (err) {
      setBillImportedFromXeroData({ ...errorResponse, errorMsg: err?.response?.data?.error?.message });
      setLoadingXeroInvoiceLink(false);
    }
  };

  return {
    fetchInvoiceLink,
    billImportedFromXeroData,
    setBillImportedFromXeroData,
    loadingXeroInvoiceLink,
  };
};
