import React from "react";
import { Button } from "@spenmo/splice";
import { useDispatch } from "react-redux";
import { RequestsCSVFunc, SentFundsCSVFunc } from "Redux/Actions";
import FundTransferDateFilters from "./filters/date";
import FundTransferAmountFilters from "./filters/amount";
import styles from "./index.module.scss";
import { useTeamActionContext } from "Views/Team/List/Actions/context";
import { FUND_TRANSFERS_SUB_TABS, FUND_TRANSFER_FILTERS_NAMES } from "../../Actions/constant";

interface IFundTransferActions {
  teamId: string;
  activeSubTab: string;
}

const FundTransferActions = ({ teamId, activeSubTab }: IFundTransferActions) => {
  const dispatch = useDispatch();
  const {
    state: { filter },
  } = useTeamActionContext();

  const handleClickDownload = () => {
    const amountFilterValues = filter ? filter[FUND_TRANSFER_FILTERS_NAMES?.AMOUNT] : {};
    if (activeSubTab === FUND_TRANSFERS_SUB_TABS.REQUESTED_FUNDS) {
      const dateFilterValues = filter ? filter[FUND_TRANSFER_FILTERS_NAMES?.CREATED_DATE] : {};
      const isSents = false;
      const filters = { type: "topup", ...dateFilterValues, ...amountFilterValues };
      const includeMyRequests = true;
      dispatch(RequestsCSVFunc(teamId, isSents, filters, includeMyRequests));
    } else if (activeSubTab === FUND_TRANSFERS_SUB_TABS.SENT_FUNDS) {
      const startDate = new Date();
      startDate.setDate(1);
      startDate.setHours(-1);
      const isoStartDate = startDate.toISOString();
      const endDate = new Date(Date.now()).toISOString();
      const dateFilterValues = {
        start_date:
          filter && filter[FUND_TRANSFER_FILTERS_NAMES?.CREATED_DATE]
            ? new Date(filter[FUND_TRANSFER_FILTERS_NAMES?.CREATED_DATE]?.start_date)?.toISOString()
            : isoStartDate,
        end_date:
          filter && filter[FUND_TRANSFER_FILTERS_NAMES?.CREATED_DATE]
            ? new Date(filter[FUND_TRANSFER_FILTERS_NAMES?.CREATED_DATE]?.end_date)?.toISOString()
            : endDate,
      };
      const filters = {
        types: ["Transfer"],
        ...dateFilterValues,
        ...amountFilterValues,
      };
      dispatch(SentFundsCSVFunc(filters));
    }
  };

  return (
    <div className={styles["action-container"]}>
      <div className={styles["filter-container"]}>
        <FundTransferDateFilters activeSubTab={activeSubTab} filter={filter?.createdDate} />
        <FundTransferAmountFilters activeSubTab={activeSubTab} filter={filter?.amount} />
      </div>
      <Button
        className={styles["fund-trnsfr-dwnld-csv-btn"]}
        data-testid="DownloadCSV"
        id="DownloadCSV"
        onClick={handleClickDownload}
        variant="primary"
        size="s"
      >
        Download CSV
      </Button>
    </div>
  );
};

export default FundTransferActions;
