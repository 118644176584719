import { brazeIdentityEventReservedConstants } from "utility/analytics/brazeIdentityEvent.constants";

const brazeUserDetailsStorageKey = "braze-user-details";

const setAlreadySentBrazeUserDetails = (userId, payload) => {
  window.localStorage.setItem(
    `${brazeUserDetailsStorageKey}.${userId}`,
    JSON.stringify({
      userId: userId,
      userTraits: payload,
    })
  );
};

const getAlreadySentBrazeUserDetails = (userId) => {
  const rawBrazeUserDetails = window.localStorage.getItem(`${brazeUserDetailsStorageKey}.${userId}`) || null;
  if (rawBrazeUserDetails) {
    const parsedBrazeUserDetails = JSON.parse(rawBrazeUserDetails) || null;
    if (parsedBrazeUserDetails?.userId === userId) {
      return parsedBrazeUserDetails?.userTraits;
    }
  }
  return {};
};

// returns only those which are not sent to braze till now or values which have been changed
const getBrazeIdentityEventTraits = (userId, newUserTraits = {}) => {
  const alreadySentBrazeUserTraits = getAlreadySentBrazeUserDetails(userId);
  const deltaUserTraits = {}; // will contain user traits/details which are not sent & those whose values have changed
  Object.keys(newUserTraits).forEach((key) => {
    if (!alreadySentBrazeUserTraits[key] || alreadySentBrazeUserTraits[key] !== newUserTraits[key]) {
      deltaUserTraits[key] = newUserTraits[key];
    }
  });
  setAlreadySentBrazeUserDetails(userId, { ...alreadySentBrazeUserTraits, ...deltaUserTraits });
  return deltaUserTraits;
};

const getUnReservedTraitsKeys = (brazeUserTraitsKeys) => {
  const segmentToBrazeReservedKeys = brazeIdentityEventReservedConstants.map(
    (reservedConstant) => reservedConstant.segmentKeyName
  );
  const unreservedTraitsKeys = [];
  brazeUserTraitsKeys.forEach((key) => {
    if (!segmentToBrazeReservedKeys.includes(key)) {
      unreservedTraitsKeys.push(key);
    }
  });
  return unreservedTraitsKeys;
};

const trigerIdentifyEventToSegment = (userId, userTraits, payload) => {
  const { identify } = window.analytics || {};
  identify && userId && identify(userId, userTraits, payload);
};

const trigerIdentifyEventToBraze = (brazeUserTraits = {}) => {
  const { getUser } = window?.appboy || {};
  const user = getUser?.() || null;
  const brazeUserTraitsKeys = Object.keys(brazeUserTraits) || [];
  if (brazeUserTraitsKeys?.length > 0 && user) {
    brazeIdentityEventReservedConstants.map((reservedConstant) => {
      const { segmentKeyName, brazeSetterMethodName } = reservedConstant;
      brazeUserTraits?.[segmentKeyName] &&
        user?.[brazeSetterMethodName] &&
        user[brazeSetterMethodName](brazeUserTraits[segmentKeyName]);
    });
    const unreservedTraitsKeys = getUnReservedTraitsKeys(brazeUserTraitsKeys);
    if (unreservedTraitsKeys?.length > 0) {
      unreservedTraitsKeys.forEach((key) => {
        user.setCustomUserAttribute && user.setCustomUserAttribute(key, brazeUserTraits[key]);
      });
    }
  }
};

/*
    In segment it doesn't matter how many identity events we send but,
    In braze everytime when we send an identity event with user traits/details, it updates those values which consumes data points
    and braze charges us based on those data points. Even though we have sent the same data which is already present.
    So it is recommended to send user traits only once and again when the value changes.
    This funtion below helps in achieving the above objective
      * first it sends identity event to segement
      * second it calculates the user traits which needs to be sent to braze and
      * trigers another identity event to braze separately
*/

export const identifyEventWithBrazeDebounce = (userId, userTraits, payload) => {
  trigerIdentifyEventToSegment(userId, userTraits, {
    ...payload,
  });
  const { appboy } = window || null;
  if (appboy) {
    const brazeIdentityEventTraits = getBrazeIdentityEventTraits(userId, userTraits);
    /*
        Sending identify event to braze separately because when we are sending the identity event through segement,
        it is always sending all the user traits even though we are controlling through the integrations object
    */
    const { changeUser = null } = appboy;
    if (userId && changeUser) {
      changeUser(userId);
      trigerIdentifyEventToBraze(brazeIdentityEventTraits);
    }
  }
};
