import React from "react";
import { InfoOutline, Tooltip } from "@spenmo/splice";

import { IColumnData } from "Modules/DS/DataTable";
import { GET_BILLS_TRXN_RESPONSE_PARAMS } from "Views/Transaction/@types";
import { BILLS_TRXNS_COLUMNS } from "Views/Transaction/Constants/Bills";
import { tableCell } from "./TableCell";

export const getBillsTableColumns = (
  isPaymentEnabled: boolean,
  isPaymentRunOn: boolean,
  adminAccountantRoles?: boolean
): IColumnData<any>[] => {
  const billsTableColumns = [
    {
      id: "BILLS-TRXN-COL-1",
      enabled: true,
      column: tableCell({
        title: BILLS_TRXNS_COLUMNS.INVOICE_X_DATE,
        key: GET_BILLS_TRXN_RESPONSE_PARAMS.CREATED_AT,
        dataIndex: GET_BILLS_TRXN_RESPONSE_PARAMS.CREATED_AT,
      }),
    },
    {
      id: "BILLS-TRXN-COL-2",
      enabled: true,
      column: tableCell({
        title: BILLS_TRXNS_COLUMNS.RECIPIENT,
        key: GET_BILLS_TRXN_RESPONSE_PARAMS.BENEFICIARY_NAME,
        dataIndex: GET_BILLS_TRXN_RESPONSE_PARAMS.BENEFICIARY_NAME,
      }),
    },
    {
      id: "BILLS-TRXN-COL-3",
      enabled: true,
      column: tableCell({
        title: BILLS_TRXNS_COLUMNS.DUE_DATE,
        key: GET_BILLS_TRXN_RESPONSE_PARAMS.DUE_DATE,
        dataIndex: GET_BILLS_TRXN_RESPONSE_PARAMS.DUE_DATE,
      }),
    },
    {
      id: "BILLS-TRXN-COL-4",
      enabled: isPaymentEnabled || isPaymentRunOn,
      column: tableCell({
        title: (
          <div
            style={{
              display: "flex",
              alignContent: "center",
            }}
          >
            {isPaymentRunOn ? BILLS_TRXNS_COLUMNS.PAYMENT_RUN : BILLS_TRXNS_COLUMNS.PAYMENT_DATE}
            <Tooltip title={isPaymentRunOn ? "Payment run" : "Payment date"} placement="top">
              <InfoOutline size="16" iconColor="var(--icon-strong)" />
            </Tooltip>
          </div>
        ),
        key: GET_BILLS_TRXN_RESPONSE_PARAMS.PAYMENT_SCHEDULE,
        dataIndex: GET_BILLS_TRXN_RESPONSE_PARAMS.PAYMENT_SCHEDULE,
        width: "9.75rem",
        isPaymentRunOn,
        adminAccountantRoles,
      }),
    },
    {
      id: "BILLS-TRXN-COL-4-SaaS",
      enabled: !(isPaymentEnabled || isPaymentRunOn),
      column: tableCell({
        title: BILLS_TRXNS_COLUMNS.MARK_AS_PAID_DATE,
        key: GET_BILLS_TRXN_RESPONSE_PARAMS.PAYMENT_SCHEDULE_SAAS, // placeholder for PAYMENT_SCHEDULE
        dataIndex: GET_BILLS_TRXN_RESPONSE_PARAMS.PAYMENT_SCHEDULE,
        width: "9.75rem",
      }),
    },
    {
      id: "BILLS-TRXN-COL-5",
      enabled: true,
      column: tableCell({
        title: BILLS_TRXNS_COLUMNS.ATTACHMENT,
        key: GET_BILLS_TRXN_RESPONSE_PARAMS.RECEIPTS,
        dataIndex: GET_BILLS_TRXN_RESPONSE_PARAMS.RECEIPTS,
        align: "center",
      }),
    },
    {
      id: "BILLS-TRXN-COL-6",
      enabled: true,
      column: tableCell({
        title: BILLS_TRXNS_COLUMNS.STATUS,
        key: GET_BILLS_TRXN_RESPONSE_PARAMS.STATUS,
        dataIndex: GET_BILLS_TRXN_RESPONSE_PARAMS.STATUS,
        width: "13.125rem",
      }),
    },
    {
      id: "BILLS-TRXN-COL-7",
      enabled: isPaymentEnabled,
      column: tableCell({
        title: BILLS_TRXNS_COLUMNS.YOU_PAY,
        key: GET_BILLS_TRXN_RESPONSE_PARAMS.BILL_AMOUNT_FEE_INCLUSIVE,
        dataIndex: GET_BILLS_TRXN_RESPONSE_PARAMS.BILL_AMOUNT_FEE_INCLUSIVE,
      }),
    },
    {
      id: "BILLS-TRXN-COL-8",
      enabled: isPaymentEnabled,
      column: tableCell({
        title: BILLS_TRXNS_COLUMNS.RECIPIENT_GETS,
        key: GET_BILLS_TRXN_RESPONSE_PARAMS.AMOUNT,
        dataIndex: GET_BILLS_TRXN_RESPONSE_PARAMS.AMOUNT,
      }),
    },
    {
      id: "BILLS-TRXN-COL-7-SaaS",
      enabled: !isPaymentEnabled,
      column: tableCell({
        title: BILLS_TRXNS_COLUMNS.INVOICE_AMOUNT,
        key: GET_BILLS_TRXN_RESPONSE_PARAMS.INVOICE_AMOUNT,
        dataIndex: GET_BILLS_TRXN_RESPONSE_PARAMS.INVOICE_AMOUNT,
      }),
    },
  ].filter((item) => item.enabled);

  return billsTableColumns;
};
