import * as React from "react";

import { AuthPageContext, AuthPageContextProps } from "./context";

import { AuthLoader, AuthContainer, AuthBox, AuthBoxRedirect } from "./Components";

import "./auth.scss";

interface Props {
  value: AuthPageContextProps;
  redirectComponent?: React.ReactElement;
  children?: React.ReactElement;
}

const AuthPage = ({ value, children = null, redirectComponent = null }: Props): React.ReactElement => {
  return (
    <AuthPageContext.Provider value={value}>
      <AuthContainer>
        <AuthBox>
          <>
            <AuthLoader>{children}</AuthLoader>
            {redirectComponent && <AuthBoxRedirect>{redirectComponent}</AuthBoxRedirect>}
          </>
        </AuthBox>
      </AuthContainer>
    </AuthPageContext.Provider>
  );
};

export default AuthPage;

export { AuthPageContext };
