import APIclient from "API/Client";

import { source } from "Redux/Actions";

import { GetOrgId, GetUserId } from "utility";

import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";

export const FETCH_TEAMS_LIST = "FETCH_TEAMS_LIST_BY_USER_ID";
export const FETCH_TEAMS_LIST_REQUEST = `${FETCH_TEAMS_LIST}_REQUEST`;
export const FETCH_TEAMS_LIST_SUCCESS = `${FETCH_TEAMS_LIST}_SUCCESS`;
export const FETCH_TEAMS_LIST_FAILURE = `${FETCH_TEAMS_LIST}_FAILURE`;
export const CLEAR_TEAM_LIST_DATA = "CLEAR_TEAM_LIST_DATA";

export const fetchDataRequest = () => {
  return {
    type: FETCH_TEAMS_LIST_REQUEST,
  };
};

export const fetchDataSuccess = (data) => {
  return {
    type: FETCH_TEAMS_LIST_SUCCESS,
    data: data,
  };
};

export const fetchDataError = (data) => {
  return {
    type: FETCH_TEAMS_LIST_FAILURE,
    data: data,
  };
};

export const clearTeamListByUserIdData = () => {
  return {
    type: CLEAR_TEAM_LIST_DATA,
  };
};

export const getTeamListByUserId = (userId, page) => {
  const id = userId ? userId : GetUserId();
  const pg = page ? "pg=" + page + "&limit=15&" : "";

  return (dispatch) => {
    dispatch(fetchDataRequest());
    APIclient.getData(`/api/v1/org/${GetOrgId()}/team-list?${pg}user_id=${id}&is_maintain_loggedin_team=1`)
      .then((data) => {
        if (data.data.status > HTTP_STATUS_CODE.OK) {
          source.cancel("Operation canceled for error");
        } else {
          return data;
        }
      })
      .then((data) => {
        const { teams } = data.data.payload;
        dispatch(fetchDataSuccess(teams));
      })
      .catch((err) => {
        dispatch(fetchDataError(err));
      });
  };
};
