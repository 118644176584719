import React from "react";

import { getCookieValue, cookieNames } from "utility/CookieHelper";

import styles from "./index.module.scss";

const Info = ({ amount, label = "Total Spend" }: { amount: { credit: number; debit: number }; label?: string }) => {
  const orgCurrencyCode: string = getCookieValue(cookieNames.CURRENCY_CODE);

  return (
    <div className={styles.info}>
      <span>{label}: </span>
      <span>
        {orgCurrencyCode} {amount.debit.toFixed(2)}
      </span>
      {Boolean(amount.credit) && (
        <>
          <span>(Receive: </span>
          <span>
            {orgCurrencyCode} {amount.credit.toFixed(2)}
            <span>)</span>
          </span>
        </>
      )}
    </div>
  );
};

export const BasicInfo = ({ amount }: { amount: number }) => {
  const orgCurrencyCode: string = getCookieValue(cookieNames.CURRENCY_CODE);
  const totalAmount: string = orgCurrencyCode + " " + amount.toFixed(2);

  return (
    <div className={styles.info}>
      <span>Total: </span>
      <span>{totalAmount}</span>
    </div>
  );
};

export default Info;
