import React from "react";
import PropTypes from "prop-types";
import Icon from "Modules/icons";
import TextLabel from "Modules/TextLabel/TextLabel";
import Clipboard from "Modules/Clipboard/Clipboard";
import Modal from "Modules/modal";
import Button from "Modules/button";
import TableRowField from "Modules/tableRowField";

const TopUpBankTransferItem = ({ item, filterBy }) => {
  const [showUsdVAModal, setShowUsdVAModal] = React.useState(false);

  const BankLogo = (url) => {
    return url && <Icon className={"bank-transfer__bank-logo"} src={url} />;
  };

  return (
    <div className={"bank-transfer__item"}>
      {BankLogo(item.bankLogoUrl)}
      {item.currencyCode === "PHP" && item.bankCode === "Netbank" && (
        <div className={"bank-transfer__help-text bank-transfer__bank_note"}>
          Formerly known as Romblon Community Rural Bank
        </div>
      )}
      {!item.bankLogoUrl && item.bankCode && <div>{item.bankCode}</div>}
      <TableRowField
        className="bank-transfer__label-clipboard"
        details={
          <>
            {item.accountNumber}
            <Clipboard text={item.accountNumber} />
          </>
        }
      />
      <div className={"bank-transfer__label-text"}>{item.accountName}</div>
      {item.swiftCode && (
        <TableRowField
          className="bank-transfer__label-clipboard"
          title={"SWIFT Code: "}
          details={
            <>
              {item.swiftCode}
              <Clipboard text={item.swiftCode} />
            </>
          }
        />
      )}
      {item.bankAddress && (
        <TableRowField
          className="bank-transfer__label-clipboard"
          title={"Beneficiary Address: "}
          details={
            <>
              {item.bankAddress}
              <Clipboard text={item.bankAddress} />
            </>
          }
        />
      )}
      {item.currencyCode === "PHP" &&
        (item.bankCode === "Netbank" ? (
          // transfer below PHP 50k
          filterBy?.isVirtualAccount ? (
            <TextLabel className={"bank-transfer__info-text"} icon={"red-info"}>
              <p className={"bank-transfer__paragraph"}>
                Transactions of PHP 50,000 and under will be instantly processed if sent via{" "}
                <strong className="bank-transfer__bold">INSTAPAY</strong>.
              </p>
            </TextLabel>
          ) : (
            <TextLabel className={"bank-transfer__info-text"} icon={"red-info"}>
              <p className={"bank-transfer__paragraph"}>
                Transactions over PHP 50,000 will be subject to <strong className="bank-transfer__bold">PESONET</strong>{" "}
                rules:
                <ol>
                  <li>Transactions received before 3:30 PM on working days will be processed the same day</li>
                  <li>Transactions received after 3:30 PM will be processed on the next working day</li>
                  <li>Transactions made on weekends or bank holidays will be processed on the next working day</li>
                </ol>
              </p>
            </TextLabel>
          )
        ) : (
          <TextLabel className={"bank-transfer__info-text"} icon={"red-info"}>
            For quicker payment verification process, please write your company name on Transfer Description field and
            send the transfer receipt to <a href="mailto:hello.philippines@spenmo.com"> hello.philippines@spenmo.com</a>
            . <br />
            <br />
            Transactions of PHP 50,000 and under transferred via this account will automatically refunded. Please
            transfer via <strong className="bank-transfer__bold">NETBANK</strong> instead.
          </TextLabel>
        ))}
      {item.currencyCode === "MYR" && (
        <TextLabel className="bank-transfer__info-text" icon="red-info">
          To speed up the payment verification process, please put your company name on Transfer Description and send
          the transfer receipt to <a href="mailto:support@spenmo.com">support@spenmo.com</a>
        </TextLabel>
      )}
      {item.additionalInfos?.length > 0 &&
        item.additionalInfos.map((info) => (
          <TableRowField
            key={info.label}
            className="bank-transfer__label-clipboard"
            title={`${info.label}:`}
            details={
              <>
                {`${info.value}`}
                <Clipboard text={info.value} />
              </>
            }
          />
        ))}
      {item.notes && <div className={"bank-transfer__help-text"}>{item.notes}</div>}
      {item.isVirtualAccount && item.currencyCode !== "USD" && (
        <TextLabel className={"bank-transfer__help-text"} icon={"flash"}>
          Will reflect instantly.
        </TextLabel>
      )}
      {item.isVirtualAccount && item.currencyCode === "USD" && (
        <>
          <TextLabel className={"bank-transfer__help-text"} icon={"flash"}>
            Balance will be updated instantly or within 1-3 working days{" "}
            <Button textButton action={() => setShowUsdVAModal(!showUsdVAModal)}>
              depending on your bank
            </Button>
            .
          </TextLabel>
          <Modal
            className="bank-transfer__modal"
            title="Bank Transfer"
            show={showUsdVAModal}
            close={() => setShowUsdVAModal(!showUsdVAModal)}
          >
            <p>
              Your balance will be updated after the fund has been received. It may take longer depending on how you
              make the transfer:
            </p>
            <ol className="bank-transfer__modal__list">
              <li>
                Instantly if you transfer from <strong>DBS Bank Singapore</strong>.
              </li>
              <li>
                Within 1 working day if you transfer from <strong>other Singapore banks</strong>.
              </li>
              <li>
                Within 3 working days if you transfer from foreign banks <strong>(outside Singapore)</strong>.
              </li>
            </ol>
          </Modal>
        </>
      )}
      {item.isVirtualAccount && item.currencyCode === "USD" && (
        <>
          <TextLabel className={"bank-transfer__info-text"} icon={"red-info"}>
            Please make sure that all information has been entered correctly to avoid delay in your top-up process.
          </TextLabel>
          <div className={"bank-transfer__help-text"}>
            If you have any question, please contact our Customer Support.
          </div>
        </>
      )}
    </div>
  );
};

TopUpBankTransferItem.propTypes = {
  item: PropTypes.object,
};

export default TopUpBankTransferItem;
