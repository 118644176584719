import { IScreenMap, ISubscribeFunc, responsiveContainer, IBreakpoint, responsiveMap } from "./types";

const subscribers = new Map<number, ISubscribeFunc>();
let subUid = -1;
let screens = {};

const ResponsiveObserve = {
  matchHandlers: {} as {
    [prop: string]: {
      mql: MediaQueryList;
      listener: ((this: MediaQueryList, ev: MediaQueryListEvent) => any) | null;
    };
  },
  dispatch(pointMap: IScreenMap) {
    screens = pointMap;
    subscribers.forEach((func) => func(screens));
    return subscribers.size >= 1;
  },
  subscribe(func: ISubscribeFunc): number {
    if (!subscribers.size) this.register();
    subUid += 1;
    subscribers.set(subUid, func);
    func(screens);
    return subUid;
  },
  unsubscribe(token: number) {
    subscribers.delete(token);
    if (!subscribers.size) this.unregister();
  },
  unregister() {
    Object.keys(responsiveContainer).forEach((screen: IBreakpoint) => {
      const matchMediaQuery = responsiveMap[screen];
      const handler = this.matchHandlers[matchMediaQuery];
      handler?.mql.removeEventListener(handler?.listener);
    });
    subscribers.clear();
  },
  register() {
    Object.keys(responsiveMap).forEach((screen: IBreakpoint) => {
      const matchMediaQuery = responsiveMap[screen];
      const listener = ({ matches }: { matches: boolean }) => {
        this.dispatch({
          ...screens,
          [screen]: matches,
        });
      };
      const mql = window.matchMedia(matchMediaQuery);
      mql.addEventListener("change", listener);
      this.matchHandlers[matchMediaQuery] = {
        mql,
        listener,
      };

      listener(mql);
    });
  },
};

export default ResponsiveObserve;
