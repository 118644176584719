import React from "react";
import { Skeleton } from "antd";

import { IItemID, MENU_HEIGHT } from "Modules/DS/Menu";
import { ISectionContainer } from "Modules/DS/Filter/@types";

import { dummyData } from "./mock";
import { ListContainer } from "./List";

import styles from "./Section.module.scss";

export const SectionContainer = <T extends IItemID, K extends object = any>({
  children,
  listKey,
  titleKey,
  lineProps,
  subArryIdKey,
  loading = false,
}: ISectionContainer<T, K>) => {
  let data: K[] = [];

  if (loading) data = [...dummyData("SECTIONED")] as K[];
  else if (children[listKey as string].length > 0) {
    data = [...children[listKey as string]];
  }

  const renderTitle = () => {
    return loading ? (
      <Skeleton.Input active={loading} size="default" className={styles.titleShimmer} />
    ) : (
      children[titleKey as string]
    );
  };

  return (
    data.length > 0 && (
      <div>
        <div className={styles.title}>{renderTitle()}</div>
        <ListContainer
          idKey={subArryIdKey}
          loading={loading}
          height={MENU_HEIGHT.INHERIT}
          lineProps={lineProps(children.id)}
        >
          {data}
        </ListContainer>
      </div>
    )
  );
};
