import { GetBaseAuthObject } from "../../utility";
import APIclient from "API/Client";
import AxiosGetForApiWrapper from "utility/apiWrapperHelper";

export const GetReceiptData = (TxnNumber, TxnId) => {
  const orgbase = GetBaseAuthObject();
  const searchParams = new URLSearchParams();

  searchParams.set("organisation_id", orgbase.orgId);
  searchParams.set("transaction_number", TxnNumber);
  searchParams.set("transaction_id", TxnId);

  return AxiosGetForApiWrapper(`/api/v1/receipt/?${searchParams.toString()}`);
};

export const GetReceiptDataAxios = (TxnNumber, TxnId) => {
  const orgbase = GetBaseAuthObject();
  const searchParams = new URLSearchParams();

  searchParams.set("organisation_id", orgbase.orgId);
  searchParams.set("transaction_number", TxnNumber);
  searchParams.set("transaction_id", TxnId);

  return APIclient.getData(`/api/v1/receipt/?${searchParams.toString()}`);
};
