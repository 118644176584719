import * as React from "react";
import { useState } from "react";
import { Tabs as AntTabs } from "antd";

import { IAddEmployeeTab } from "Views/Employee/types";
import { ADD_EMPLOYEES_TABS as tabs } from "Views/Employee/Create/Page/Tabs/List";

import "./index.scss";

const { TabPane } = AntTabs;

const Tabs = () => {
  const [activeKey, setActiveKey] = useState(tabs[0].id);

  const renderTabs = (tab: IAddEmployeeTab) => {
    const { id, tabTitle, component: Component } = tab;

    return (
      <TabPane key={id} tab={tabTitle}>
        <Component />
      </TabPane>
    );
  };

  return (
    <AntTabs
      className="add-employee__tabs"
      type="card"
      tabPosition="left"
      onChange={setActiveKey}
      defaultActiveKey={activeKey}
    >
      {tabs.map(renderTabs)}
    </AntTabs>
  );
};

export default Tabs;
