import React, { useState } from "react";
import { Quick } from "Modules/DS/Filter";
import { ISingleLine } from "Views/Accounting/Workflow/TransactionFilter";

interface IReipientFilter {
  recipient: ISingleLine[];
  selectedRecipient: string[];
  adjustFilter: (
    key: string | string[],
    value: ISingleLine | string[] | string | object,
    option?: { appliedFilter: boolean }
  ) => void;
}

const RecipientFilter = ({ recipient, selectedRecipient, adjustFilter }: IReipientFilter) => {
  const [title, setTitle] = useState("");

  return (
    <Quick.Multiple<ISingleLine>
      idKey="id"
      displayKey="title"
      searchable
      filterItemProps={{ id: "recipient_filter", containerClass: ".data-table-container" }}
      defaultValue="Recipient/Vendor"
      states={{
        title,
        setTitle,
        selectedIds: selectedRecipient,
        setSelectedIds: (value) => {
          adjustFilter("recipient", value, { appliedFilter: false });
        },
      }}
      onAddSelected={(value) => {
        adjustFilter("recipient", value);
      }}
      clearFilter={() => adjustFilter("recipient", "")}
    >
      {recipient}
    </Quick.Multiple>
  );
};

export default RecipientFilter;
