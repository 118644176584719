import React from "react";
import { Redirect, Route, useLocation } from "react-router-dom";

import Container from "Route/Container";
import {
  IPageGrid,
  PAGE_GRID_COLUMNS,
  PAGE_GRID_CONTENT_TYPE,
} from "Route/@types";
import { AUTH_PATHS } from "Route/Auth/paths";
import { kybStatusRedirector } from "Route/KYX/map";

import { useIsSaasWithPaymentsOrg } from "customHooks/useIsSaasWithPaymentsOrg";

import { cookieNames, getCookieValue } from "utility/CookieHelper";

import { KYB_ONBOARDING_VERSION, KYB_STATUS } from "Views/KYX/Constants";
import SessionTimeoutModal from "Modules/SessionTimeoutModal";

interface IPrivateRouterProps {
  children: React.JSX.Element;
  path: string;
  exact: boolean;
  isOnboardingRequired?: boolean;
  acceptedStatuses?: string[];
  grid?: IPageGrid;
}

export const PrivateRouter = ({
  children,
  acceptedStatuses = [],
  isOnboardingRequired = false,
  grid = {
    columns: PAGE_GRID_COLUMNS.SINGLE,
    content: PAGE_GRID_CONTENT_TYPE.FLUID,
  },
  ...props
}: IPrivateRouterProps) => {
  const location = useLocation();

  const redirectTo =
    location.pathname.trim().replace("/", "?redirect=") +
    location.search.trim().replace("?", "&");

  // Extract onboarding-related values from cookies
  const kybStatus: KYB_STATUS = getCookieValue(cookieNames.KYB_STATUS);
  const onboardingVersion: string = getCookieValue(
    cookieNames.ONBOARDING_VERSION,
  );
  const isSaasWithPaymentsOrg = useIsSaasWithPaymentsOrg();

  const isOnboardingFinishedCookie: boolean =
    getCookieValue(cookieNames.IS_ONBOARDING_FINISHED) === "true";

  // Check if onboarding process is finished based on KYB status and onboarding finished cookie
  const isOnboardingFinished =
    kybStatus === KYB_STATUS.APPROVED && isOnboardingFinishedCookie;

  const isOnboardingVersionThree = onboardingVersion === KYB_ONBOARDING_VERSION;

  const isHomePageAllowed = !isSaasWithPaymentsOrg;

  const isAllowedStatus = acceptedStatuses.includes(kybStatus);

  const { validateStatus, kybRoute } = kybStatusRedirector(kybStatus);

  const sessionExpired =
    getCookieValue(cookieNames.EXPIRED) === "true" || false;

  return (
    <Route
      {...props}
      render={() => {
        switch (true) {
          case !validateStatus:
            return <Redirect to={AUTH_PATHS.LOGIN + redirectTo} />;

          case isOnboardingVersionThree &&
            !isOnboardingFinished &&
            !isOnboardingRequired &&
            !isHomePageAllowed:
          case isOnboardingRequired && !isAllowedStatus && !isHomePageAllowed:
            return <Redirect to={kybRoute} />;

          case isOnboardingRequired &&
            isOnboardingFinished &&
            isHomePageAllowed:
            return <Redirect to="/" />;

          case sessionExpired:
            return <SessionTimeoutModal />;

          default:
            return <Container grid={grid}>{children}</Container>;
        }
      }}
    />
  );
};
