import React from "react";

import { ITrxnFilterItem, ITrxnFilters } from "Views/Transaction/@types";

import { Container } from "./More";
// import { ClearAllButton } from "./Clear";

import styles from "./index.module.scss";
import classNames from "classnames";

const TrxnFilters = ({ quick, more, quickKeys, className, isAllTab }: ITrxnFilters) => {
  const renderFilterList = (data: ITrxnFilterItem[]): JSX.Element[] => {
    return data.map((item) => <div key={item.id}>{item.Component}</div>);
  };

  return (
    <div className={classNames({ [className]: className }, styles.filters)}>
      {renderFilterList(quick)}
      {more.length > 0 && <Container>{renderFilterList(more)}</Container>}
      {/* <ClearAllButton quickKeys={quickKeys} /> */}
    </div>
  );
};

export * as Quick from "./Quick";
export * as More from "./More";
export * from "./Attachment";
export * from "./Recipient";
export * from "./Category";
export * from "./Budget";
export * from "./Team";
export * from "./Type";

export default TrxnFilters;
