import * as React from "react";
import classNames from "classnames";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMerchantsFunc } from "Redux/Actions";
import Search from "Modules/DS/Search";
import MerchantSpendingLockItem from "Views/Card/Components/SpendingLock/Merchant/Item";
import SpendingLockMerchantShimmer from "Views/Card/Components/SpendingLock/Merchant/Shimmer";
import SpendingLockFooter from "Views/Card/Components/SpendingLock/Footer";

import styles from "./index.module.scss";
import commonStyles from "Views/Card/Components/SpendingLock/index.module.scss";

interface IMerchant {
  label: string;
  name: string;
  icon: string;
}

interface IMerchantSpendingLock {
  initialSpendingLocks: string[];
  onChange: (selectedMerchantLock: string[]) => void;
  totalShimmerToRender: number;
}

const MerchantSpendingLock = ({ onChange, initialSpendingLocks, totalShimmerToRender }: IMerchantSpendingLock) => {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const dispatch = useDispatch();

  const [merchantData, setMerchantData] = useState<IMerchant[]>([]);
  const [merchantInitialData, setMerchantInitialData] = useState<IMerchant[]>([]);
  const [selectedMerchantLocks, setSelectedMerchantLocks] = useState<string[]>(initialSpendingLocks);

  const { data: merchantDataFromApi, loading } = useSelector((state: any) => state?.merchantReducer || {});

  const processMerchantData = () => {
    const { merchants = [] } = merchantDataFromApi?.payload || {};
    const processedMerchants =
      merchants?.map((merchant) => ({
        name: merchant?.name,
        label: merchant?.name?.toLowerCase?.(),
        icon: merchant?.logo,
      })) || [];
    setMerchantData(processedMerchants);
    setMerchantInitialData(processedMerchants);
  };

  const onSearch = (searchString: string) => {
    let filteredMerchants: React.SetStateAction<IMerchant[]>;
    if (searchString) {
      filteredMerchants = merchantInitialData.filter((merchant) =>
        merchant?.label?.toLowerCase()?.includes(searchString?.toLowerCase())
      );
    } else {
      filteredMerchants = merchantInitialData;
    }
    setMerchantData(filteredMerchants);
  };

  React.useEffect(() => {
    if (merchantDataFromApi?.payload?.merchants?.length > 0) {
      processMerchantData();
    } else {
      dispatch(getMerchantsFunc());
    }
  }, [dispatch]);

  React.useEffect(() => {
    processMerchantData();
  }, [merchantDataFromApi]);

  const handleMerchantLockSelection = ({ checked, merchantName }: { checked: boolean; merchantName: string }) => {
    let merchants: string[] = [...(selectedMerchantLocks || [])];
    if (checked) {
      merchants = merchants?.concat(merchantName);
    } else {
      merchants = merchants?.filter((selectedMerchantLock) => selectedMerchantLock !== merchantName);
    }
    setSelectedMerchantLocks(merchants);
    onChange(merchants);
  };

  const isMerchantSelected = (merchantName) => selectedMerchantLocks?.includes(merchantName);

  const getMerchantData = () => {
    if (Array.isArray(merchantData) && merchantData?.length > 0) {
      return merchantData?.map((merchant) => {
        return (
          <li key={merchant?.label}>
            <MerchantSpendingLockItem
              checked={isMerchantSelected(merchant?.name)}
              iconImageAlt={`${merchant?.name} logo`}
              logo={merchant?.icon}
              name={merchant?.name}
              onClick={(checked) => handleMerchantLockSelection({ checked, merchantName: merchant?.name })}
            />
          </li>
        );
      });
    }
    return null;
  };

  const showSpendingLockFooter = selectedMerchantLocks?.length > 0 && merchantData?.length > 0;

  const contentClassName = classNames(styles.merchantLockContent, {
    [commonStyles.marginBottomXL]: showSpendingLockFooter,
  });

  return (
    <section className={commonStyles.section}>
      <Search.Popover
        onSearch={(searchString) => {
          setSearchQuery(searchString);
          onSearch(searchString);
        }}
        placeholder="Search for merchant"
        value={searchQuery}
        classname={commonStyles.searchBar}
      />
      {loading ? (
        <SpendingLockMerchantShimmer totalShimmerToRender={totalShimmerToRender} />
      ) : (
        <>
          <div className={contentClassName}>
            <ul>{getMerchantData()}</ul>
          </div>
          {showSpendingLockFooter && (
            <SpendingLockFooter
              message={`${selectedMerchantLocks?.length || 0} Merchants Selected`}
              onClear={() => {
                setSelectedMerchantLocks([]);
                onChange([]);
              }}
            />
          )}
        </>
      )}
    </section>
  );
};

export default MerchantSpendingLock;
