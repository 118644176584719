import * as React from "react";
import classNames from "classnames";
import { useEffect, createContext, useCallback } from "react";

import useScrollBlocker from "utility/useScrollBlocker";

import { IModalContext, IModalProps, initialModalContext } from "Modules/DS/Modal/types";

import styles from "./Modal.module.scss";

export const ModalContext = createContext<IModalContext>(initialModalContext);

const Modal = ({ close, children, className = "", visible = false, maskClosable = true }: IModalProps) => {
  useScrollBlocker(visible);

  const onClose = useCallback(() => {
    close?.();
  }, [close]);

  const closeOnEsc = useCallback(
    (e: KeyboardEvent) => {
      if (e.code === "Escape" && maskClosable) onClose();
    },
    [onClose, maskClosable]
  );

  useEffect(() => {
    visible && window.addEventListener("keydown", closeOnEsc);

    return () => {
      window.removeEventListener("keydown", closeOnEsc);
    };
  }, [visible, closeOnEsc]);

  const context: IModalContext = { onClose };

  return (
    visible && (
      <ModalContext.Provider value={context}>
        <div
          data-testid="modal-container"
          className={styles.container}
          onMouseDown={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            e.stopPropagation();
            if (maskClosable) {
              onClose();
            }
          }}
        >
          <div
            data-testid="modal-content"
            className={classNames({ [className]: className }, styles.content)}
            onMouseDown={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => e.stopPropagation()}
          >
            {children}
          </div>
        </div>
      </ModalContext.Provider>
    )
  );
};

export default Modal;
export * as ModalComponent from "./Components";
