import APIclient from "API/Client";
import { MY_INFO_SERVICE } from "Views/KYX/DataCalls/Services";
export interface IPostMyInfoAuthorisePayload {
  token: string;
}
export interface IPostMyInfoAuthoriseResponse {
  authorise_url: string;
}
export interface IPostMyInfoCallbackPayload {
  code: string;
  state: string;
}
export interface IPostMyInfoCallbackResponse {
  id: string;
  roles_of_individual: {
    is_authorized_person: boolean;
    is_director: boolean;
    is_share_holder: boolean;
    full_name: string;
    alias: string;
    date_of_birth: string;
    gender: string;
    nationality: string;
    country_of_residence: string;
    residential_address: string;
    postal_code: string;
    ownership_category: string;
    share_type: string;
    share_allocation: number;
    position: string;
    email_address: string;
    mobile_number: string;
    identification_document: {
      document_type: string;
      nric_document: object;
      passport_document: object;
      work_pass_document: object;
    };
    auth_token: string;
  };
}
export interface IPostMyInfoSubmitPayload {
  auth_token: string;
  country_of_residence: string;
  email_address?: string;
  mobile_number?: string;
}
export interface IPostMyInfoSubmitResponse {
  description: string;
}

export interface IGetMyInfoTokenResponse {
  person_name: string;
  company_name: string;
  status: string;
}

export const postMyInfoAuthorise = (payload: IPostMyInfoAuthorisePayload): Promise<IPostMyInfoAuthoriseResponse> => {
  return new Promise((resolve, reject) => {
    APIclient.postData(MY_INFO_SERVICE("authorise"), payload)
      .then((result) => {
        resolve(result.data.payload);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const postMyInfoCallback = (payload: IPostMyInfoCallbackPayload): Promise<IPostMyInfoCallbackResponse> => {
  return new Promise((resolve, reject) => {
    APIclient.postData(MY_INFO_SERVICE("callback"), payload)
      .then((result) => {
        resolve(result.data.payload);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const postMyInfoSubmit = (payload: IPostMyInfoSubmitPayload): Promise<IPostMyInfoSubmitResponse> => {
  return new Promise((resolve, reject) => {
    APIclient.postData(MY_INFO_SERVICE("submit"), payload)
      .then((result) => {
        resolve(result.data.payload);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const getMyInfoToken = (token: string): Promise<IGetMyInfoTokenResponse> => {
  return new Promise((resolve, reject) => {
    APIclient.getData(`${MY_INFO_SERVICE("token")}${token}`)
      .then((result) => {
        resolve(result.data.payload);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
