import APIclient from "API/Client";
import { GetFormData, urlParamsBuilder } from "utility/APIWrapper";
import { GetOrgId } from "utility";

export const GetAllApprovals = (
  orgId = "",
  teamId = "",
  status = "",
  page = 0,
  pageLimit = 10,
  isSents = "",
  filter = {},
  include_my_requests = false
) => {
  const sentRequests = isSents ? { is_scheduled_request: false } : "";
  return APIclient.postData(
    `/api/v1/fund/request/list`,
    GetFormData({
      organisation_id: orgId,
      team_id: teamId,
      pg: page,
      limit: pageLimit,
      status: status,
      ...sentRequests,
      filters: { ...filter },
      include_my_requests,
    })
  )
    .then((resp) => {
      return resp.data;
    })
    .catch((err) => {
      console.error(err);
      return err;
    });
};

export const getRequestDetails = (requestId) => {
  return APIclient.getData(
    `/api/v1/fund/reimbursement/${requestId}?organisation_id=${GetOrgId()}&with_status_journey=1`
  );
};

export const getApprovers = ({ processType = "invoice", walletId = "", expenseCategoryId = "", amount = 0 } = {}) => {
  return APIclient.getData(
    urlParamsBuilder(`/ms/spm-organization/v3/approvals/preview`, {
      organisation_id: GetOrgId(),
      processType,
      walletId,
      expenseCategoryId,
      amount,
    })
  );
};

export const getSentFunds = (page) => {
  return APIclient.getData(`/api/v1/org/${GetOrgId()}/team/fund/transfer/list${page}`)
    .then((resp) => {
      return resp.data;
    })
    .catch((err) => {
      return err;
    });
};
