import * as React from "react";
import { useContext, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { clearUpdateRole } from "Redux/Actions/Employee/Update/Role";
import { MULTIPLE_ERROR_COUNT_LIMIT, UPDATE_ROLE_HELP_MESSAGE } from "constants/Employee.constant";

import Icon from "Modules/icons";
import Button from "Modules/button";

import * as E from "Views/Employee/context";

import { errorIcon } from "assets/img";

const Failed = () => {
  const dispatch = useDispatch();
  const { resetModal }: E.IEmployeesModalContext = useContext(E.EmployeesModalContext);
  const { toggleSupportChat }: E.IEmployeesCSContext = useContext(E.EmployeesCSContext);
  const { dispatchRoleUpdate, updateRequestCount, pendingRequest }: E.IEmployeesDispatchFailsContext = useContext(
    E.EmployeesDispatchFailsContext
  );

  const errorMessage: string =
    useSelector((state: any) => state.updateRoleReducer?.data?.message) || "An Error Occurred";

  const triggerSupport = () => {
    toggleSupportChat(UPDATE_ROLE_HELP_MESSAGE);
    resetModal();
  };

  React.useEffect(() => {
    return () => {
      dispatch(clearUpdateRole());
    };
  }, []);

  const handlePendingRequest = useCallback((): void => {
    dispatchRoleUpdate(updateRequestCount + 1, pendingRequest.newRoleId, pendingRequest.employee);
  }, [dispatchRoleUpdate, updateRequestCount, pendingRequest]);

  const redirectToSupportChat = () => {
    return (
      <>
        <Button action={triggerSupport}>Chat CS</Button>
        <Button action={resetModal}>Later</Button>
      </>
    );
  };

  const tryUpdateAgain = () => {
    return (
      <>
        <Button action={handlePendingRequest}>Try Again</Button>
        <Button action={resetModal}>Cancel</Button>
      </>
    );
  };

  const actionButtons = () => {
    return updateRequestCount > MULTIPLE_ERROR_COUNT_LIMIT ? redirectToSupportChat() : tryUpdateAgain();
  };

  return (
    <>
      <Icon className="error-icon" src={errorIcon} alt="error" />
      <p className="message">{errorMessage}</p>
      <div className="action-btns">{actionButtons()}</div>
    </>
  );
};

export default Failed;
