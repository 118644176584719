import React, { useState, useContext } from "react";
import { Form } from "antd";
import { formItemProps } from "../utils/formItemProps";
import { DynamicFormContext } from "Modules/DynamicForm/store";
import { DynamicFormFieldProps } from "Modules/DynamicForm/types";
import TagInputSelect from "Modules/TagInput";

const EmailTagInput = (props: DynamicFormFieldProps) => {
  const { alias, fieldID, id, fieldProps, onChangeValue, form, value: dynamicValue } = props;
  const [value, setValue] = useState([]);
  const handleChange = (value) => {
    const val = value.length > 0 ? value.join() : undefined;
    onChangeValue?.(fieldID, val);
    setValue(val);
    form.setFieldsValue((values) => ({
      ...values,
      dynamicFields: {
        ...values.dynamicFields,
        [id]: val,
      },
    }));
  };

  const handleFocus = () => {
    trackView(alias, value);
  };

  const handleBlur = () => {
    trackEdit(alias, value);
  };

  const validator = (_: unknown, values: string[] | string, callback: (message?: string) => void) => {
    if (typeof values === "string") values = [values];
    const invalidInputs = values?.filter((value) => !value.match(fieldProps.regex));
    if (!invalidInputs?.length) {
      callback();
    } else if (invalidInputs.length === 1) {
      callback(invalidInputs.join("") + " is not a valid email");
    } else {
      callback(invalidInputs.slice(0, -1).join(", ") + " and " + invalidInputs.slice(-1) + " are not valid emails");
    }
  };

  const { className, ...rest } = formItemProps(props, [
    {
      validator,
    },
  ]);
  const { trackView, trackEdit } = useContext(DynamicFormContext);

  return (
    <Form.Item {...rest} className={className}>
      <TagInputSelect
        placeholder={fieldProps.placeholder}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        value={dynamicValue.split(",")}
      />
    </Form.Item>
  );
};

export default EmailTagInput;
