import APIclient from "API/Client";
import { GetBaseAuthObject } from "../../utility";
import { GetFormData } from "../../utility/APIWrapper";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";

export const FETCH_RECEIPT_REQUEST = "FETCH_RECEIPT_REQUEST";
export const FETCH_RECEIPT_SUCCESS = "FETCH_RECEIPT_SUCCESS";
export const FETCH_RECEIPT_FAILURE = "FETCH_RECEIPT_FAILURE";

export const fetchDataReq = (data) => {
  return {
    type: FETCH_RECEIPT_REQUEST,
    data: data,
  };
};
export const fetchDataSuccess = (data) => {
  return {
    type: FETCH_RECEIPT_SUCCESS,
    data: data,
  };
};
export const fetchDataError = (data) => {
  return {
    type: FETCH_RECEIPT_FAILURE,
    data: data,
    error: true,
  };
};

export const receiptActionFunc = (data) => {
  const orgId = GetBaseAuthObject().orgId;

  return (dispatch) => {
    dispatch(fetchDataReq());
    return APIclient.postData("/api/v1/receipt/", GetFormData({ organisation_id: orgId, ...data }))
      .then((data) => {
        if (data?.data?.status > HTTP_STATUS_CODE.OK) {
          dispatch(fetchDataError(data.data));
        } else {
          dispatch(fetchDataSuccess(data?.data));
        }
      })
      .catch((err) => {
        dispatch(fetchDataError(err));
      });
  };
};
