import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import qs from "query-string";
import { Base64 } from "js-base64";
import { Typography } from "@spenmo/splice";
import { useHistory, useLocation } from "react-router-dom";

import { ILocationQueryParams } from "Views/Reimbursement/@types";
import { REIMBURSEMENT_QUERY_PARAMS } from "Views/Reimbursement/Constants";

import useExportOptionsList from "Views/Reimbursement/hooks/useExportOptionsList";

import SidepanelSkeleton from "Views/Reimbursement/Sidepanel/Skeleton";
import ExportOption from "Views/Reimbursement/Sidepanel/Export/Option";
import ExpenseDate from "Views/Reimbursement/Sidepanel/Export/ExpenseDate";

import styles from "./index.module.scss";

const ExportReimbursements = () => {
  const history = useHistory();
  const location = useLocation();

  const query: ILocationQueryParams = qs.parse(location.search, {
    parseNumbers: true,
    parseBooleans: true,
  });

  const { expenseStartDate, expenseEndDate, exportType = "" } = query;

  const [active, setActive] = useState<string>(Base64.decode(exportType));

  const { list: options, loading } = useExportOptionsList();

  const handleOnClick = (id: string) => {
    setActive(id);

    history.push({
      pathname: location.pathname,
      search: qs.stringify({
        ...query,
        [REIMBURSEMENT_QUERY_PARAMS.EXPORT_TYPE]: Base64.encodeURI(id),
      }),
    });
  };

  const startOfMonth = dayjs().startOf("month").unix();
  const today = dayjs().unix();

  useEffect(() => {
    if ((!expenseStartDate || !expenseEndDate || !exportType) && !loading) {
      let search = {};

      if (!expenseStartDate && !expenseEndDate) {
        search = {
          ...search,
          [REIMBURSEMENT_QUERY_PARAMS.EXPENSE_START_DATE]: startOfMonth,
          [REIMBURSEMENT_QUERY_PARAMS.EXPENSE_END_DATE]: today,
        };
      }

      if (options.length === 1) {
        setActive(options[0].id);
        search = {
          ...search,
          [REIMBURSEMENT_QUERY_PARAMS.EXPORT_TYPE]: Base64.encodeURI(options[0].id),
        };
      }

      history.push({
        pathname: location.pathname,
        search: qs.stringify({
          ...query,
          ...search,
        }),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const handleOnApply = (date: string, key: string) => {
    history.push({
      pathname: location.pathname,
      search: qs.stringify({
        ...query,
        [key]: date !== null ? dayjs(date).unix() : undefined,
      }),
    });
  };

  return loading ? (
    <SidepanelSkeleton />
  ) : (
    <div className={styles.container}>
      <div className={styles.format}>
        <Typography size="m" variant="body-content" tag="h4">
          Select a format
        </Typography>
        {options.map((item) => (
          <ExportOption
            id={item.id}
            key={item.id}
            title={item.title}
            iconColor={item.iconColor}
            description={item.description}
            comingSoon={item.comingSoon}
            active={active === item.id}
            handleOnClick={handleOnClick}
            bottom={item.bottom}
          />
        ))}
      </div>
      <div className={styles.filter}>
        <ExpenseDate
          startDate={Number(expenseStartDate) ? expenseStartDate : startOfMonth}
          endDate={Number(expenseEndDate) ? expenseEndDate : today}
          handleOnApply={handleOnApply}
        />
      </div>
    </div>
  );
};

export default ExportReimbursements;
