import React from "react";
import Icon, { IconList } from "Modules/Icon";
import moment from "moment";
import { Tooltip } from "antd";
import { Tooltip as SpliceTooltip } from "@spenmo/splice";
import cn from "classnames";

import { IColumnData } from "Modules/DS/DataTable";

import { currencyFormatterV2 } from "utility";

import { TABS } from "./const";

import { jpgFileType, pngFileType, heicFileType, pdfFileType } from "assets/img";
import styles from "./Bills.module.scss";
import { SaaSConfig } from "Views/Bills/V2/BillForm/type";

export interface DraftColumn {
  id: string;
  createdAt: string;
  vendorName: string;
  beneficiaryName: string;
  invoiceNumber: string;
  dueDate: string;
  scheduleDate: string;
  amount: number;
  currency: string;
  invoiceCurrency: string;
  createdIn: string;
  attachments: string;
  attachmentURLs: string;
}

const render = (value) => (["", undefined, null, NaN].includes(value) ? "-" : value);

const OCRDataUnavailableTooltip = () => {
  return (
    <SpliceTooltip placement="top" title="No details were retrieved from the scanned invoice file">
      -
    </SpliceTooltip>
  );
};

export const getColumns = (props, saasConfig: SaaSConfig): IColumnData<DraftColumn>[] => {
  const { onClickAttachment } = props;

  return [
    {
      id: "createdAt",
      enabled: true,
      column: {
        title: "Created Date & Time",
        dataIndex: "createdAt",
        key: "createdAt",
        width: 130,
        render: (date, _, index) => {
          const momentDate = moment(date);

          const content = momentDate.isValid() ? (
            <div>
              <span>{momentDate.format("DD MMM YYYY,")}</span>{" "}
              <span className={styles.secondary}>{momentDate.format("HH:mm")}</span>
            </div>
          ) : (
            <div>-</div>
          );

          return content;
        },
      },
    },
    {
      id: "vendorName",
      enabled: true,
      column: {
        title: (
          <div>
            <span className={styles.columnText}>Recipient</span>
            <Tooltip
              color="#333744"
              title="Top row indicates the recipient’s name as saved in our system. Bottom row indicates the recipient’s name on their bank account."
              overlayStyle={{
                width: 320,
              }}
            >
              <Icon className={styles.columnIcon} icon="info" size={16} />
            </Tooltip>
          </div>
        ),
        dataIndex: "vendorName",
        key: "vendorName",
        width: 180,
        render: (vendorName, data) => {
          if (!vendorName && !data.beneficiaryName) {
            return (
              <>
                <p>-</p>
                <p>-</p>
              </>
            );
          }

          return (
            <Tooltip
              placement="rightTop"
              color="#333744"
              title={
                <>
                  <p>{render(vendorName)}</p>
                  <p>{render(data.beneficiaryName)}</p>
                </>
              }
            >
              <p className={styles.oneLine}>{render(vendorName)}</p>
              <p className={cn(styles.oneLine, styles.secondary)}>{render(data.beneficiaryName)}</p>
            </Tooltip>
          );
        },
      },
    },
    {
      id: "invoiceNumber",
      enabled: true,
      column: {
        title: "Invoice Number",
        dataIndex: "invoiceNumber",
        key: "invoiceNumber",
        ellipsis: true,
        width: 180,
        render: (invoiceNumber) => (
          <Tooltip placement="rightTop" color="#333744" title={invoiceNumber}>
            {render(invoiceNumber)}
          </Tooltip>
        ),
      },
    },
    {
      id: "dueDate",
      enabled: true,
      column: {
        title: "Bill Due Date",
        dataIndex: "dueDate",
        key: "dueDate",
        width: 120,
        render: (date) => {
          const momentDate = moment(date);
          return momentDate.isValid() ? momentDate.format("DD MMM YYYY") : "-";
        },
      },
    },
    {
      id: "scheduledDate",
      enabled: true,

      column: {
        title: "Payment Initiation Date",
        dataIndex: "scheduledDate",
        key: "scheduledDate",
        width: 140,
        render: (date) => {
          const momentDate = moment(date);
          const isPaymentEnabled = saasConfig?.isPaymentEnabled;
          const isValidDate = momentDate.isValid();
          return isValidDate ? momentDate.format("DD MMM YYYY") : isPaymentEnabled ? "Pay Immediately" : "Unscheduled";
        },
      },
    },
    saasConfig && !saasConfig.isPaymentEnabled
      ? {
          id: "currency",
          enabled: true,
          column: {
            title: "Invoice Currency",
            dataIndex: "invoiceCurrency",
            key: "invoiceCurrency",
            width: 170,
            render: (currency, _) => {
              return currency ? currency : OCRDataUnavailableTooltip();
            },
          },
        }
      : null,
    {
      id: "amount",
      enabled: true,
      column: {
        title: `${saasConfig?.isPaymentEnabled ? "Recipient gets" : "Invoice Amount"}`,
        dataIndex: `${saasConfig?.isPaymentEnabled ? "amount" : "invoiceAmount"}`,
        key: `${saasConfig?.isPaymentEnabled ? "amount" : "invoiceAmount"}`,
        width: 170,
        render: (amount, data) => {
          return saasConfig?.isPaymentEnabled
            ? amount
              ? `${currencyFormatterV2(amount, data.currency, saasConfig?.isPaymentEnabled)}`
              : OCRDataUnavailableTooltip()
            : amount
            ? amount
            : OCRDataUnavailableTooltip();
        },
      },
    },
    {
      id: "createdIn",
      enabled: true,
      column: {
        title: (
          <div>
            <span className={styles.columnText}>Created in</span>
            <Tooltip
              color="#333744"
              title={`${
                saasConfig?.isPaymentEnabled
                  ? "Drafts can be created manually by uploading payment information through Spenmo or synchronized automatically with a linked Xero account"
                  : "Drafts can be created manually by uploading Invoice information through Spenmo"
              }`}
              overlayStyle={{
                width: 300,
              }}
            >
              <Icon className={styles.columnIcon} icon="info" size={16} />
            </Tooltip>
          </div>
        ),
        dataIndex: "createdIn",
        key: "createdIn",
        width: 180,
        render,
      },
    },
    {
      id: "attachments",
      enabled: true,
      column: {
        title: "Attachment",
        dataIndex: "attachments",
        key: "attachments",
        width: 120,
        onCell: (record) => {
          const { attachmentURLs } = record;

          return attachmentURLs
            ? {
                onClick: (e) => {
                  e.preventDefault();

                  onClickAttachment(e, record);
                },
              }
            : {};
        },
        render: (attachments, _, index) => {
          const hasAttachment = attachments?.length > 0;
          const iconProps = {
            size: 20,
            icon: hasAttachment ? IconList.fillReceiptAdded : IconList.receiptAdded,
            className: hasAttachment ? styles.attachmentActive : styles.attachmentInfo,
          };

          return (
            <div className={styles.attachment} data-testid={`attachment-${index}`}>
              <Icon {...iconProps} />
            </div>
          );
        },
      },
    },
  ].filter((item) => item != null);
};

export const srcFileTypes = {
  "image/jpg": jpgFileType,
  "image/jpeg": jpgFileType,
  "image/png": pngFileType,
  "image/heic": heicFileType,
  "image/heif": heicFileType,
  "application/pdf": pdfFileType,
};

export const getTabList = () => {
  const tabs = [TABS.CREATE_BILLS, TABS.SUBMITTED_BILLS, TABS.MANAGE_DRAFT, TABS.MANAGE_RECIPIENTS];
  return tabs;
};

export const sortByOrderNumber = (data: any[]) => {
  if (!data.length) {
    return [];
  }
  return [...data].sort((a, b) => a.orderNumber - b.orderNumber);
};
