import { dateTimeFormatter } from "utility";
import { EWorkflowApiStatus } from "Views/Accounting/Workflow/type";
import { TABS_URL } from "../Constants";
import { activeTabBasedOnStatus } from "Views/Accounting/Workflow";

export const getDetailHeaderAmount = (currencyCode: string, amount: number) => {
  return `${currencyCode} ${Math.abs(amount).toFixed(2)}`;
};

export const getRecordDetailsDate = (date: string) =>
  dateTimeFormatter(date).date + ", " + dateTimeFormatter(date).time;

export const getAccountingWorkflowCount = (accountingWorkflowCount, connectedUsingExpenseIntegration) => {
  let total =
    accountingWorkflowCount[EWorkflowApiStatus.MISSING_INFO] +
    accountingWorkflowCount[EWorkflowApiStatus.PENDING_REVIEW];

  if (connectedUsingExpenseIntegration) {
    total +=
      accountingWorkflowCount[EWorkflowApiStatus.FAILED_SYNC] + accountingWorkflowCount[EWorkflowApiStatus.QUEUED];
  }

  return total;
};

export const findActivePageName = (pathname: string, search: string) => {
  const params = new URLSearchParams(search);
  if (pathname?.includes("accounting")) {
    const accountingTrxnStatus = params?.get("status")?.toUpperCase();
    return activeTabBasedOnStatus(accountingTrxnStatus || "");
  } else if (search?.search("tab=pending") >= 0) {
    return TABS_URL.BILLS;
  } else if (search?.search("tab=scheduled") >= 0) {
    return TABS_URL.SCHEDULED;
  } else if (search?.search("tab=cards") >= 0) {
    return TABS_URL.CARDS;
  } else {
    return TABS_URL.SETTLED;
  }
};
