import { AxiosResponse } from "axios";
import useSWR, { SWRConfiguration } from "swr";
import { useRouteMatch } from "react-router-dom";

import { postData } from "API/Client";

import { PRODUCT_NAME } from "Redux/ModularProduct";

import { GetFormData } from "utility/APIWrapper";
import useCheckOrgConfigs from "customHooks/useCheckOrgConfigs";
import { getCookieValue, cookieNames } from "utility/CookieHelper";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";

import { appNotification, contactCSErrorMessage } from "Modules/appNotification/appNotification";

import {
  ILocationParams,
  IReimbursementListResponse,
  IUseFetchReimbursementsList,
  IReimbursementListRequestPayload,
} from "Views/Reimbursement/@types";
import { API_URL } from "Views/Reimbursement/DataCalls/Services";

import useListCRPayload from "./useListCRPayload";

const useFetchList = (): IUseFetchReimbursementsList => {
  const payload: IReimbursementListRequestPayload = useListCRPayload();

  const { params } = useRouteMatch<ILocationParams>();

  const [isCrConfigEnabled, loadingCrConfig] = useCheckOrgConfigs(PRODUCT_NAME.CASH_REIMBURSEMENT, true) as [
    boolean,
    boolean
  ];

  const keys: [string, IReimbursementListRequestPayload, string, ILocationParams] = [
    isCrConfigEnabled ? API_URL.LIST.REIMBURSEMENTS : null,
    isCrConfigEnabled ? payload : null,
    getCookieValue(cookieNames.AUTH_TOKEN),
    isCrConfigEnabled ? params : null,
  ];

  const config: SWRConfiguration<AxiosResponse<IReimbursementListResponse>> = {
    revalidateOnFocus: false,
    onSuccess: (response) => {
      if (response.data.status !== HTTP_STATUS_CODE.OK) {
        throw new Error(`${response.data.status}`);
      }
    },
    onError: (err) => {
      isCrConfigEnabled && appNotification.error(contactCSErrorMessage(err.message));
      throw new Error(err.message);
    },
  };

  const { data, isLoading, isValidating, error } = useSWR<AxiosResponse<IReimbursementListResponse>>(
    keys,
    ([url, request]) => postData(url, GetFormData(request)),
    config
  );

  return {
    data: data?.data?.payload?.requests || [],
    totalCount: data?.data?.payload?.counts?.filtered || 0,
    showEmptyState: data?.data?.payload?.requests?.length === 0 || false,
    showSkeleton: isLoading || isValidating || loadingCrConfig || !isCrConfigEnabled,
    showErrorState: (error?.message as string)?.length > 0 || false,
  };
};

export default useFetchList;
