import { IColumnData } from "Modules/DS/DataTable";

import { IGetTrxnResponse } from "Views/Transaction/@types";

import { attachment } from "./Attachment";
import { recipient } from "./Recipient";
import { requester } from "./Requester";
import { category } from "./Category";
import { created } from "./Created";
import { amount } from "./Amount";
import { team } from "./Team";
import { type } from "./Type";

import "./index.scss";

const columns = (
  selectedRows: string[],
  updatingCategories: boolean = false,
  isEditAllowed: boolean = false,
  loadingEditPermisson: boolean = false
): IColumnData<IGetTrxnResponse>[] => {
  return [
    {
      id: "ALL-TRXN-COL-1",
      enabled: true,
      column: created(),
    },
    {
      id: "ALL-TRXN-COL-2",
      enabled: true,
      column: type(),
    },
    {
      id: "ALL-TRXN-COL-4",
      enabled: true,
      column: requester(),
    },
    {
      id: "ALL-TRXN-COL-5",
      enabled: true,
      column: recipient(),
    },
    {
      id: "ALL-TRXN-COL-3",
      enabled: true,
      column: team(),
    },
    {
      id: "ALL-TRXN-COL-6",
      enabled: true,
      column: category(selectedRows, updatingCategories, isEditAllowed, loadingEditPermisson),
    },
    {
      id: "ALL-TRXN-COL-7",
      enabled: true,
      column: attachment(isEditAllowed, loadingEditPermisson),
    },
    {
      id: "ALL-TRXN-COL-8",
      enabled: true,
      column: amount(),
    },
  ].filter((item) => item.enabled);
};

export default columns;
