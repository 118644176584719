import React from "react";
import AccountingSetup from "../Common/AccountingSetup";
import { findBankfeedConfig } from "./config";
import { useLocation } from "react-router-dom";
import { useIsSaasWithPaymentsOrg } from "customHooks/useIsSaasWithPaymentsOrg";

const Setup = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const isPaymentEnabled = useIsSaasWithPaymentsOrg();

  return (
    <AccountingSetup
      partner="xero"
      title="Xero Bank Feed Integration"
      config={findBankfeedConfig(params.get("choose_connection") === "true", isPaymentEnabled)}
    />
  );
};

export default Setup;
