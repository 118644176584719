import React, { useMemo, useRef, useState } from "react";
import { Popover, Select } from "antd";
import { ISelectionData } from "Modules/multiSelection/DropdownSelection";
import { plusIconDark, chevronDown } from "assets/img";
import { STATUS_OPTIONS_NEW } from "Views/Card/List/Filters/Constants";
import CardholderSelection from "Views/Card/List/Filters/Components/CardholderFilter/Components/CardholderSelection";
import { ISearchFilters } from "Views/Card/List/Filters/Types";
import { filterEvents } from "Views/Card/Analytics";
import { trackEvent } from "utility/analytics";
import "./index.scss";

interface ICardFilterMore {
  cardNumbers: Array<ISelectionData>;
  searchFilters: ISearchFilters;
  action: (type: string, payload) => void;
}
const { Option } = Select;

const CardFilterMore: React.FC<ICardFilterMore> = ({ cardNumbers, action, searchFilters = {} }) => {
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const selectCardNumbers = useRef(null);

  const countValues = useMemo(() => {
    let result = 0;
    if (searchFilters.card_last_four?.length > 0) result++;
    if (searchFilters.card_status?.length > 0) result++;
    return result;
  }, [searchFilters]);

  const handleCardNumberAction = (values: Array<string>) => {
    setIsPopoverVisible(false);
    action("card_last_four", values);
    trackEvent(filterEvents.CARD_NUMBER, { "card number": values });
  };

  const handleStatusAction = (values: string) => {
    setIsPopoverVisible(false);
    action("card_status", values);
    trackEvent(filterEvents.CARD_STATUS, { "card status": values });
  };

  const popover = (
    <div data-testid="card-popup" className="pop-over__card-filter-more">
      <div>
        <span className="pop-over__filter-more-label">Card Number</span>
        {isPopoverVisible && (
          <CardholderSelection
            placeholder={"Please select card numbers"}
            listData={cardNumbers}
            defaultValue={searchFilters.card_last_four || []}
            className="pop-over__filter-more-select"
            selectOptions={{
              dropdownClassName: "card-selection-filter__dropdown card-filter-more__dropdown",
              showSearch: true,
              ref: selectCardNumbers,
              showAction: ["focus"],
              placement: "topLeft",
              dropdownMatchSelectWidth: false,
            }}
            action={handleCardNumberAction}
            letterAvatar={false}
            prefix="***"
          />
        )}
      </div>
      <div data-testid="popover-select">
        <span className="pop-over__filter-more-label">Status</span>
        <Select
          placeholder="Please select a status"
          className="pop-over__filter-more-select"
          suffixIcon={<img src={chevronDown} alt="drop down icon" />}
          onChange={handleStatusAction}
          dropdownClassName="pop-over__filter-more-drop-down"
          value={searchFilters.card_status}
        >
          {STATUS_OPTIONS_NEW.map((item) => (
            <Option key={item.value} value={item.value}>
              {item.label}
            </Option>
          ))}
        </Select>
      </div>
    </div>
  );

  return (
    <div className={"popout"}>
      <Popover
        content={popover}
        visible={isPopoverVisible}
        trigger={"click"}
        onVisibleChange={setIsPopoverVisible}
        placement="bottomLeft"
      >
        <div className={`popout__content ${countValues > 0 ? "popout__content--on" : ""}`}>
          <img className="popout__more-icon" src={plusIconDark} alt="More filter icon" />
          More Filters {countValues ? `(${countValues})` : ""}
        </div>
      </Popover>
    </div>
  );
};

export default CardFilterMore;
