import React from "react";

import { Col, Row } from "Splice/Grid";

import Document from "Views/KYX/Intro/Document";
import { IDocumentsInfo, IUpdateIntro } from "Views/KYX/@types";
import { KYB_SOURCE, DOCUMENTS_INFO } from "Views/KYX/Constants";

import { UpdateData as Button } from "Views/KYX/Intro/Update/Button";
import { UpdateIntroHeader as Header } from "Views/KYX/Intro/Update/Header";

import styles from "./index.module.scss";

/**
 * A React Component that is used to render the Manual Method of the Update Intro Screens when a user's submission has already been review by the opsy team on retool.
 * It is maintained according to the KYX_COUNTRY_LIST
 *
 * @param {KYX_COUNTRY_LIST} countryCode - Contains a reference to the Country Codes for the Org
 * @returns {JSX.Element} A JSX Element
 */
export const WithoutAutomatedSource = ({ countryCode }: IUpdateIntro): JSX.Element => {
  const details: IDocumentsInfo = DOCUMENTS_INFO()[countryCode][KYB_SOURCE.MANUAL];

  return (
    <Row align="end" justify="start">
      <Col span={24}>
        <Header />
      </Col>
      <Col className={styles.alignInline} span={24}>
        <Document documents={details?.documents} />
      </Col>
      <Col span={24}>
        <Button />
      </Col>
    </Row>
  );
};
