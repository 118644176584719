import * as React from "react";
import { getAdvisoryTexts } from "Views/Card/Faqs/Faqs";

import styles from "./index.module.scss";

interface IServiceAdvisoryProps {
  isOrgCurrencyPHP: boolean;
}

const ServiceAdvisory = ({ isOrgCurrencyPHP }: IServiceAdvisoryProps): JSX.Element => {
  const advisoryTexts = getAdvisoryTexts(isOrgCurrencyPHP);
  return (
    <div className={styles.container}>
      <div className={styles.serviceAdvisoryTitle}>Service Advisory: Spenmo is Migrating To A New Card Provider</div>
      <div className={styles.serviceAdvisoryContainer}>
        {advisoryTexts.map((text) => (
          <p className={styles.serviceAdvisory}>{text}</p>
        ))}
      </div>
    </div>
  );
};

export default ServiceAdvisory;
