import PropTypes from "prop-types";

export const BILL_PAGE_URL = "/bills";
export const INVALID_FX_RATE = 4039;
export const CONVERSION_NOT_MATCHED = 4040;
export const DUPLICATE_INVOICE_NUMBER = 4024;
export const INTERNAL_SERVER_ERROR = 5000;
export const ORG_NOT_ALLOWED = 4065;
export const INVALID_PAYLOAD = 3002;
export const ALLOWED_FILE_EXTENSIONS = ["jpg", "jpeg", "png", "pdf"];
export const MAX_FILE_SIZE_ALLOWED = 12 * 1024 * 1024; // 12MB
export const ENTITY_TYPE = [
  { label: "Company", value: "company" },
  { label: "Individual", value: "individual" },
];
export const DYNAMIC_FORM_TYPES = {
  DROPDOWN: "dropdown",
  TEXTFIELD: "textfield",
  TEXTAREA: "textarea",
  OPTION: "option",
  TEXT: "text",
};
export const BANK_IDENTIFIER = {
  ACH: {
    priority: 1,
    key: "ach",
    value: "ACH",
    label: "ACH Routing Number",
    error: "ACH Routing Number must contain exactly 9 numeric digits",
    regex: /^\d{9}$/,
    maxLength: 9,
  },
  BSB: {
    priority: 2,
    key: "bsb",
    value: "BSB",
    label: "BSB Number",
    error: "BSB Number must contain exactly 6 numeric digits",
    regex: /^\d{6}$/,
    maxLength: 6,
  },
  IBAN: {
    priority: 3,
    key: "iban",
    value: "IBAN",
    label: "IBAN",
    error: "IBAN must contain less than 35 alphanumeric characters",
    regex: /^[a-z0-9]{1,34}$/i,
    maxLength: 34,
  },
  IFSC: {
    priority: 4,
    key: "ifsc",
    value: "IFSC",
    label: "IFSC Code",
    error: "Invalid IFSC Code. Check your bank details for the correct code.",
    regex: /^[a-z]{4}0[a-z0-9]{6}$/i,
    maxLength: 11,
  },
  SORT: {
    priority: 5,
    key: "sort",
    value: "Sort Code",
    label: "Sort Code",
    error: "Sort Code must contain exactly 6 numeric digits",
    regex: /^\d{6}$/,
    maxLength: 6,
  },
  SWIFT: {
    priority: 6,
    key: "swift",
    value: "SWIFT/BIC Code",
    label: "SWIFT/BIC Code",
    error: "Invalid SWIFT/BIC Code. Check your bank details for the correct code.",
    regex: /^[a-z]{6}[a-z0-9]{2}[a-z0-9]{0,3}$/i,
    maxLength: 11,
  },
};
export const REF_PROP_TYPES = PropTypes.oneOfType([
  PropTypes.func,
  PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
]);
export const FILE_NAME_TIME_OUT = 500;
/*
 * Email Regex based on https://www.emailregex.com/
 */
export const EMAIL_REGEX =
  /* eslint-disable-next-line */
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const CORRIDOR_SCHEDULE_DATE_INTERVAL = {
  DOMESTIC: 3,
  INTERNATIONAL: 6,
};

export const BANK_NAME_ALIAS = "beneficiaryBankName";
export const DYNAMIC_FIELDS_NAME = "dynamicFields";
export const PHP_DYNAMIC_FORM = {
  COUNTRY_CODE: "PH",
  CURRENCY: "PHP",
  AMOUNT_BAR: 500000,
};

export const DUPLICATE_VENDOR_NAME_ERROR_MESSAGE = "This recipient name has been taken. Use another name instead.";
export const DUPLICATE_INVOICE_NUMBER_ERROR_MESSAGE =
  "Invoice number already exists for this recipient. Please enter a new invoice number to proceed.";
