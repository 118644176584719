import APIclient from "API/Client";

export const STOP_SCHEDULED_TRANSACTIONS_REQUEST = "STOP_SCHEDULED_TRANSACTIONS_REQUEST";
export const STOP_SCHEDULED_TRANSACTIONS_SUCCESS = "STOP_SCHEDULED_TRANSACTIONS_SUCCESS";
export const STOP_SCHEDULED_TRANSACTIONS_FAILURE = "STOP_SCHEDULED_TRANSACTIONS_FAILURE";
export const CLEAR_STOP_SCHEDULED_TRANSACTIONS = "CLEAR_STOP_SCHEDULED_TRANSACTIONS";

const stopScheduledTransactionsReq = (data) => {
  return {
    type: STOP_SCHEDULED_TRANSACTIONS_REQUEST,
    data,
  };
};
const stopScheduledTransactionsSuccess = (data) => {
  return {
    type: STOP_SCHEDULED_TRANSACTIONS_SUCCESS,
    data,
  };
};
const stopScheduledTransactionsError = (data) => {
  return {
    type: STOP_SCHEDULED_TRANSACTIONS_FAILURE,
    data,
  };
};

export const clearStopScheduledTransactions = (data) => {
  return {
    type: CLEAR_STOP_SCHEDULED_TRANSACTIONS,
  };
};

export const stopScheduledTransactionsFunc = (payload) => {
  return (dispatch) => {
    dispatch(stopScheduledTransactionsReq(payload));

    APIclient.putData("/ms/spm-organization/v1/allocation-job", payload)
      .then((data) => {
        dispatch(stopScheduledTransactionsSuccess(data.data));
      })
      .catch((err) => {
        const errorMessage = err?.response?.data?.error?.message || err;
        dispatch(stopScheduledTransactionsError(errorMessage));
      });
  };
};
