import React from "react";
import dayjs from "dayjs";
import { Col, Row } from "antd";
import { Banner, InfoFilled } from "@spenmo/splice";

import { LAST_POSSIBLE_RECURRING_DATE } from "Modules/RecurringDate/types";

const PayoutsBanner = ({ settledAt, isEnabled }: { settledAt: number; isEnabled: boolean }) => {
  const lastPossibleDatesInaMonth =
    settledAt >= LAST_POSSIBLE_RECURRING_DATE.MIN && settledAt <= LAST_POSSIBLE_RECURRING_DATE.MAX;


    const getBannerTitle = () => {
      const date = dayjs().month(0).date(settledAt).format("Do");
      switch (true) {
        case settledAt !== undefined && isEnabled && lastPossibleDatesInaMonth:
          return `Payouts are processed on the ${date} or last day of every month`;
        case settledAt !== undefined && isEnabled && !lastPossibleDatesInaMonth:
          return `Payouts are processed on the ${date} of every month`;
        default:
          return "Payouts are processed externally outside of Spenmo";
      }
    };

    const getBannerDescription = () => {
      const date = dayjs().month(0).date(settledAt).format("Do");
      switch (true) {
        case settledAt !== undefined && isEnabled && lastPossibleDatesInaMonth:
          return `Upon approval, reimbursements are automatically processed and settled on the ${date} or last day of every month in a single transaction.`;
        case settledAt !== undefined && isEnabled && !lastPossibleDatesInaMonth:
          return `Upon approval, reimbursements are automatically processed and settled on the ${date} of every month in a single transaction.`;
        default:
          return "Your approved reimbursements are processed outside of Spenmo. Check with your administrator or Finance person for more details.";
      }
    };

  return  isEnabled ? (
    <Row gutter={[8, 8]}>
      <Col span={12} className={"add-reimbursement--payout-banner"}>
        <Banner icon={InfoFilled} variant="info" title={getBannerTitle()} description={getBannerDescription()} />
      </Col>
    </Row>
  ) : null;
};

export default PayoutsBanner;
