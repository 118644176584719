import React from "react";
import { NextButton } from "Views/KYX/Progress/Footer";

import styles from "./index.module.scss";

export const Footer = ({ loading }: { loading: boolean }): JSX.Element => {
  return (
    <div className={styles.footer}>
      <NextButton isLastStep loading={loading} />
    </div>
  );
};
