import React, { useContext } from "react";
import classNames from "classnames";

import { IItemID } from "Modules/DS/Menu";
import { ISelectContext, SelectContext } from "Modules/DS/Select";

import { isEditableTrxnType } from "Views/Transaction/Helper";
import { PopoverWithBanner } from "Views/Transaction/Popover";
import { ALL_TRXN_BULK_ACTION_TYPE, ALL_TRXN_BULK_ACTION_POPOVER_CLASS } from "Views/Transaction/Constants";
import { GET_TRXN_RESPONSE_PARAMS, IBulkUpdatePopover, IPopoverWithBanner } from "Views/Transaction/@types";

import { useCategoryUpdate } from "Views/Transaction/All/Update";

import styles from "./Bulk.module.scss";

export const BulkUpdatePopover = <T extends IItemID>({
  selectedRows,
  onSuccess,
  onError,
  data,
  bulkLoader,
}: IBulkUpdatePopover<T>) => {
  const { select } = useContext<ISelectContext>(SelectContext);

  const active: boolean = select.show && select.key === ALL_TRXN_BULK_ACTION_TYPE.CATEGORY;

  const _data: string[] =
    data
      ?.filter((item) => selectedRows.includes(item.id) && isEditableTrxnType(item[GET_TRXN_RESPONSE_PARAMS.TRXN_TYPE]))
      ?.map((item) => item.id) || [];

  const { addNew, children, onClose, width, bannerText, listLength } = useCategoryUpdate(
    _data,
    bulkLoader,
    onSuccess,
    onError
  );

  const props: IPopoverWithBanner = {
    width,
    active,
    onClose,
    children,
    bannerText,
    containerClass: ".data-table-container",
    className: ALL_TRXN_BULK_ACTION_POPOVER_CLASS.CATEGORY,
    // headerText: "Note",
    // headerInfo: `Of the ${selectedRows.length} transactions selected, You can only download ${_data.length} eligbile transactions`,
  };

  const className: string = classNames(
    styles.categories,
    { [styles.addNew]: addNew },
    { [styles[`length--${listLength}`]]: listLength > 0 && listLength < 6 }
  );

  return (
    <div className={styles.container}>
      <div className={className}>
        <PopoverWithBanner {...props} />
      </div>
    </div>
  );
};
