import { MY_INFO_ERROR_TYPE } from "./Progress";

export interface IMyInfoError {
  title: string;
  button: string;
  info: string;
  message: string;
}

export const Acra = {
  title: "ACRA Data Discrepancy",
  button: "Request For Support",
  info: "Submitted company data below does not match the ACRA document(s) retrieved from MyInfo Business:",
  message:
    "Please reach out to our team to resolve this discrepancy before proceeding with your company verification process",
};

export const UnavaliableData = {
  title: "Data Unavailable",
  button: "Upload Company Documents Manually",
  info: "We were unable to obtain your company data below from MyInfo Business:",
  message: "Please continue your company verification process by manually uploading required documents",
};

export const MyInfoErrorInfo: {
  [MY_INFO_ERROR_TYPE.ACRA]: IMyInfoError;
  [MY_INFO_ERROR_TYPE.DATA_UNAVALIABLE]: IMyInfoError;
} = {
  [MY_INFO_ERROR_TYPE.ACRA]: Acra,
  [MY_INFO_ERROR_TYPE.DATA_UNAVALIABLE]: UnavaliableData,
};
