import React from "react";
import { Skeleton } from "antd";
import { Typography } from "@spenmo/splice";
import cn from "classnames";
import dayjs from "dayjs";

import styles from "./Billing.module.scss";

export const getSubscriptionContent = (period: "month" | "year", renewDate: number) => {
  switch (period) {
    case "year": {
      return (
        <Typography className={styles.vSpace4} variant="body-content" size="m" tag="p">
          Your annual subscription renews on{" "}
          <Typography variant="body-content" size="m" weight={600}>
            {dayjs(renewDate * 1000).format("DD MMM YYYY")}
          </Typography>
        </Typography>
      );
    }
    case "month": {
      return (
        <Typography className={styles.vSpace4} variant="body-content" size="m" tag="p">
          Your monthly subscription renews on{" "}
          <Typography variant="body-content" size="m" weight={600}>
            1st of every month.
          </Typography>{" "}
          You will receive the next invoice on{" "}
          <Typography variant="body-content" size="m" weight={600}>
            5th of every month
          </Typography>
          .
        </Typography>
      );
    }
    // loading
    default: {
      return (
        <Skeleton
          title={false}
          paragraph={{
            rows: 1,
          }}
        />
      );
    }
  }
};

export const getLookerStudioUrl = (tab: "user" | "additionalCard" | "cardCashback", lookerOrgKey: string) => {
  switch (tab) {
    case "additionalCard": {
      return `https://lookerstudio.google.com/embed/reporting/27d6c12b-72fb-4773-a249-869137060cb0/page/gIUtD?params={"df2":"include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580${lookerOrgKey}"}`;
    }
    case "cardCashback": {
      // only pick the 1st day of the month from the requirement: https://spenmo.atlassian.net/browse/CD-1721
      const date = dayjs().subtract(2, "month").format("YYYYMM01");
      return `https://lookerstudio.google.com/embed/u/0/reporting/04c0ba3b-353d-4ca6-9a30-2979ae9fab05/page/wm2sD?params={"df5":"include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580${lookerOrgKey}","df6":"include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580${date}"}`;
    }
    // user tab
    default: {
      return `https://lookerstudio.google.com/embed/u/0/reporting/82580172-c9ae-47fc-92d6-ad8c8496a349/page/wm2sD?params=%7B%22df5%22:%22include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580${lookerOrgKey}%22%7D`;
    }
  }
};

export const getTabItem = (isCardVendorNone: boolean, lookerOrgKey: string) => {
  const tabWithCard = !isCardVendorNone
    ? [
        {
          content: (
            <iframe
              className={cn(styles.frameContainer, styles.vSpace24)}
              title="additionalCard"
              src={getLookerStudioUrl("additionalCard", lookerOrgKey)}
            />
          ),
          id: "2",
          label: "Additional Physical Card",
          visible: true,
        },
        {
          content: (
            <>
              <Typography className={styles.vSpace24} variant="body-content" size="m" tag="p" weight={600}>
                Card Transactions Eligible for Cashback
              </Typography>
              <Typography className={styles.vSpace8} variant="body-content" size="m" tag="p">
                Card cashback is credited directly to your Spenmo balance on the{" "}
                <Typography variant="body-content" size="m" weight={600}>
                  7th of every month
                </Typography>
                . It will take 2 months from date of card transaction for cashback to be credited to your account.
              </Typography>
              <iframe
                className={cn(styles.frameContainer, styles.vSpace24)}
                title="cardCashback"
                src={getLookerStudioUrl("cardCashback", lookerOrgKey)}
              />
            </>
          ),
          id: "3",
          label: "Cards Cashback",
          visible: true,
        },
      ]
    : [];
  return [
    {
      content: (
        <iframe
          className={cn(styles.frameContainer, styles.vSpace24)}
          title="user"
          src={getLookerStudioUrl("user", lookerOrgKey)}
        />
      ),
      id: "1",
      label: "Users",
      visible: true,
    },
    ...tabWithCard,
  ];
};
