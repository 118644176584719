import React from "react";

import { SelectProvider } from "Modules/DS/Select";

import TrxnFilters from "Views/Transaction/Filters";
import { TrxnFilterProvider } from "Views/Transaction/Provider";

import {
  TRXNS_QUICK_FILTER_LIST,
  TRXNS_QUICK_FILTER_KEYS,
  BILLS_QUICK_FILTER_KEYS,
  BILLS_TRXN_QUICK_FILTER_LIST,
} from "./Quick";
import { TABS } from "../TransactionsHelper";
import { ITransactionFilterProps } from "./types";
import { TRXNS_MORE_FILTER_KEYS, TRXNS_MORE_FILTER_LIST } from "./More";

import styles from "./index.module.scss";

const TransactionFiltersProviderWrapper = ({ filters, onApply, view }: ITransactionFilterProps) => {
  return (
    <SelectProvider>
      <TrxnFilterProvider
        onApply={onApply}
        filters={filters}
        containerClass=".container"
        moreFilters={view === TABS.pending ? [] : TRXNS_MORE_FILTER_KEYS}
      >
        <TransactionFilters view={view} />
      </TrxnFilterProvider>
    </SelectProvider>
  );
};

const TransactionFilters = ({ view }: Omit<ITransactionFilterProps, "filters" | "onApply">) => {
  return view !== TABS.scheduled ? (
    <TrxnFilters
      quick={view === TABS.pending ? BILLS_TRXN_QUICK_FILTER_LIST : TRXNS_QUICK_FILTER_LIST}
      more={view === TABS.pending ? [] : TRXNS_MORE_FILTER_LIST}
      quickKeys={view === TABS.pending ? BILLS_QUICK_FILTER_KEYS : TRXNS_QUICK_FILTER_KEYS}
      className={styles.transaction}
    />
  ) : null;
};

export default TransactionFiltersProviderWrapper;
