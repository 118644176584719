import heic2any from "heic2any";

import { flattenObj, removeEmptyProperty } from "utility";
import { OCRData, OCRExtractData } from "Views/Bills/V2/BillForm/type";

export const mapOCRData = (extractData: OCRExtractData) => {
  const { recipientDetail, ...billDetail } = extractData;
  const billData = removeEmptyProperty(flattenObj(billDetail)) as OCRExtractData;

  const { dynamicFields, ...rest } = recipientDetail;
  const recipientData = removeEmptyProperty(flattenObj(rest)) as OCRExtractData;
  dynamicFields?.forEach((item) => {
    recipientData[`dynamicFields.${item.alias}`] = item.value;
  });

  // TO DO: ask BE to change it into invoiceAmount
  if (typeof billData?.amount === "number") {
    // for invoiceAmount
    billData["billTax.invoiceAmount"] = billData.amount; // IMPROVEMENT: no need this if the BE already change to `billTax.invoiceAmount`

    delete billData.amount;
  }

  return { billData, recipientData } as OCRData;
};

/**
 * transformOCRResp is to transform edit draft bill detail data
 * into OCR Response from the Extract API
 *
 * @param billDetailData response from the bill detail - edit draft API
 * @returns Extract API response | boolean
 */
export const transformOCRResp = (billDetailData: Record<string, any> = {}) => {
  const {
    beneficiary,
    currency: currencyCode,
    billTax,
    dynamicFields,
    dueDate,
    issuedDate,
    invoiceNumber: billNumber,
    ocrAttachment,
  } = billDetailData;
  const { vendorID, legalName, name: beneficiaryName, bankCountry: countryCode } = beneficiary || {};

  if (ocrAttachment?.id) {
    const recipientDetail = {
      beneficiaryName,
      countryCode,
      currencyCode,
      dynamicFields: dynamicFields.map((item) => ({ alias: item.fieldAlias, value: item.value })),
      legalName,
    };

    const billData = {
      ocrID: ocrAttachment.id,
      vendorID,
      billNumber,
      billTax,
      dueDate,
      issuedDate,
      isSameWithOCR: Boolean(vendorID),
    };

    return {
      ...billData,
      recipientDetail,
    };
  }

  return false;
};
