import React, { MouseEvent, useContext, useRef, useState } from "react";
import dayjs from "dayjs";
import { Typography } from "@spenmo/splice";
import { Moment } from "moment";
import { useHistory, useLocation } from "react-router-dom";

import { putData } from "API/Client";

import { TrxnModalContext } from "Views/Transaction/Context";
import { CellWithSubtitle, IconCell } from "Views/Transaction/List";
import { InputCell } from "Views/Transaction/Shimmer";
import Chip from "Modules/DS/Chip";
import SpDatePicker from "Modules/SpDatePicker";
import { appNotification } from "Modules/appNotification/appNotification";

import { STATUS_COLOR_MAP } from "Views/TransactionsListing/helper";
import { API_URL, TRXN_MODAL_TYPES, TRXN_STATUS } from "Views/Transaction/Constants";
import { currencyFormatter, currencyFormatterV2, dateTimeFormatter, plural } from "utility";

import { receiptsIcon } from "assets/img";
import { addReceipt } from "assets/v1.1/icons/Line";
import {
  GET_BILLS_TRXN_RESPONSE_PARAMS,
  IGetBillTrxnResponse,
  ITableCellObj,
  ITrxnModalContext,
} from "Views/Transaction/@types";

import styles from "./TableCell.module.scss";

const DEFAULT_ERROR_MESSAGE = "An error has occured. Please contact support@spenmo.com for further assistance.";

const AttachmentCell = ({ receipts }: { receipts: string[] }) => {
  const { toggleModal }: ITrxnModalContext = useContext<ITrxnModalContext>(TrxnModalContext);

  const onViewReceiptsClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
    toggleModal(TRXN_MODAL_TYPES.VIEW_ATTACHMENT, { isBillsTrxnsPage: true, receipts });
  };
  return (
    <IconCell
      src={receipts.length ? receiptsIcon : addReceipt}
      alt={receipts.length ? "view_attachment" : "no_attachment"}
      onClick={onViewReceiptsClick}
      disabled={!receipts?.length}
    />
  );
};

const Component = (props) => {
  const { keyID, value, data, isPaymentRunOn, adminAccountantRoles } = props;

  const location = useLocation();
  const history = useHistory();

  const addDateRef = useRef(null);
  const dateRef = useRef(null);

  const [openDatePicker, setOpenDatePicker] = useState(false);

  const handleOpenDatePicker = (e: MouseEvent<HTMLParagraphElement>) => {
    e.stopPropagation();
    dateRef.current.focus();

    setOpenDatePicker(true);
  };

  const handleOnChangeDate = (date: Moment) => {
    const { id } = data;
    const formData = new FormData();
    formData.append("billID", id);
    formData.append("paymentScheduleTime", date.format("YYYY-MM-DD"));

    putData(API_URL.updatePaymentSchedule, formData)
      .then((res) => {
        const { payload, error } = res.data;
        if (payload.isSuccess) {
          appNotification.success({
            message: payload.message,
          });

          // mutate submitted bills
          history.push(location.pathname + location.search, {
            refetchBillList: true,
          });

          return;
        }

        // Note: should use something like useToaster hooks instead.
        switch (error.code) {
          case 5000: {
            appNotification.error({
              message: error.message,
              cta: "Refresh",
              onClickCTA: () => window.location.reload(),
            });
            break;
          }
          default: {
            appNotification.error({
              message: error.message || DEFAULT_ERROR_MESSAGE,
              cta: "Email Support",
              onClickCTA: () => window.open("mailto:support@spenmo.com"),
            });
          }
        }
      })
      .catch((e) => {
        console.error(e);
        appNotification.error({
          message: DEFAULT_ERROR_MESSAGE,
          cta: "Email Support",
          onClickCTA: () => window.open("mailto:support@spenmo.com"),
        });
      });
  };

  const getCustomTableCellValue = (keyID: string, value, record: IGetBillTrxnResponse) => {
    const { invoiceNumber } = record;
    switch (keyID) {
      case GET_BILLS_TRXN_RESPONSE_PARAMS.CREATED_AT: {
        return value ? <CellWithSubtitle title={`#${invoiceNumber}`} subtitle={dateTimeFormatter(value).date} /> : "-";
      }
      case GET_BILLS_TRXN_RESPONSE_PARAMS.DUE_DATE: {
        if (!value) {
          return "-";
        }

        const isBeforeToday = dayjs().isBefore(value, "day");
        const countDays = dayjs().diff(value, "day") * -1;
        const absDays = Math.abs(countDays);

        switch (true) {
          case countDays > 10 && isBeforeToday: {
            return dayjs(value).format("DD MMM YYYY");
          }
          case countDays === 0: {
            return "Due today";
          }
          case countDays <= 10 && isBeforeToday: {
            return `Due in ${plural(absDays, "day")}`;
          }
          // overdue
          default: {
            return `Overdue by ${plural(absDays, "day")}`;
          }
        }
      }
      case GET_BILLS_TRXN_RESPONSE_PARAMS.PAYMENT_SCHEDULE: {
        if (!value) {
          const status = record?.status?.key;
          // these status is already the end status which doesn't need a payment schedule
          // when it's empty
          if ([TRXN_STATUS.cancelled, TRXN_STATUS.rejected].includes(status)) {
            return "-";
          }

          return (
            <div>
              <Typography
                tag="p"
                variant="body-content"
                style={{
                  color: "var(--text-title-default)",
                }}
                size="s"
              >
                Unscheduled
              </Typography>
              {!isPaymentRunOn && adminAccountantRoles && (
                <div ref={addDateRef} className={styles.addDateWrapper} onClick={(e) => e.stopPropagation()}>
                  <Typography
                    tag="p"
                    variant="body-content"
                    style={{
                      color: "var(--text-link-default)",
                    }}
                    size="s"
                    onClick={handleOpenDatePicker}
                  >
                    + Add a date
                  </Typography>
                  {
                    <SpDatePicker
                      className={styles.datePicker}
                      ref={dateRef}
                      open={openDatePicker}
                      action={handleOnChangeDate}
                      onBlur={() => setOpenDatePicker(false)}
                      disabledDate={(currentDate) => dayjs(currentDate).isBefore(dayjs(), "day")}
                    />
                  }
                </div>
              )}
            </div>
          );
        }

        if (isPaymentRunOn) {
          return value?.label;
        }

        return dayjs(value?.date).format("DD MMM YYYY");
      }
      case GET_BILLS_TRXN_RESPONSE_PARAMS.PAYMENT_SCHEDULE_SAAS: {
        if (!value) {
          return "-";
        }
        return dayjs(value?.date).format("DD MMM YYYY");
      }
      case GET_BILLS_TRXN_RESPONSE_PARAMS.RECEIPTS: {
        const imagesList = [];
        if (record.receiptsURL?.length) imagesList.push(...record.receiptsURL);
        if (record.attachmentsURL?.length) imagesList.push(...record.attachmentsURL);

        return <AttachmentCell receipts={imagesList} />;
      }
      case GET_BILLS_TRXN_RESPONSE_PARAMS.STATUS: {
        return (
          <div className="transactions__invoices__status">
            <Chip.Status title={value?.label} status={STATUS_COLOR_MAP[value?.color]} />
          </div>
        );
      }
      case GET_BILLS_TRXN_RESPONSE_PARAMS.BILL_AMOUNT_FEE_INCLUSIVE: {
        return currencyFormatter(record.billAmountFeeInclusive || 0, record.walletCurrency);
      }
      case GET_BILLS_TRXN_RESPONSE_PARAMS.AMOUNT: {
        return currencyFormatter(value || 0, record.currency);
      }
      case GET_BILLS_TRXN_RESPONSE_PARAMS.INVOICE_AMOUNT: {
        return currencyFormatterV2(value || 0, record[GET_BILLS_TRXN_RESPONSE_PARAMS.INVOICE_CURRENCY], true, 2);
      }
      default:
        return <p>{value}</p>;
    }
  };

  return <InputCell>{getCustomTableCellValue(keyID, value, data)}</InputCell>;
};

export const tableCell = ({
  title,
  key,
  dataIndex,
  align,
  width,
  isPaymentRunOn = false,
  adminAccountantRoles,
}: ITableCellObj) => ({
  title,
  key,
  dataIndex,
  align,
  width,
  render: (value, data: IGetBillTrxnResponse): React.ReactNode => (
    <Component
      key={key}
      keyID={key}
      value={value}
      data={data}
      isPaymentRunOn={isPaymentRunOn}
      adminAccountantRoles={adminAccountantRoles}
    />
  ),
});
