export enum QueryParamsKeys {
  MONEY_ACTION_TYPE = "moneyActionType",
  INITITAL_WALLET_ID = "initialWalletId",
  SEND_MONEY_TO = "sendMoneyTo",
  INITIAL_SELECTED_TEAM_ID = "initialSelectedTeamId",
  INITIAL_SELECTED_USER_ID = "initialSelectedUserId",
}

export enum QueryParamsVaules {
  REQUEST_MONEY = "requestMoney",
  SEND_MONEY = "sendMoney",
}
