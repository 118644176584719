import React, { useState } from "react";
import { Input } from "antd";
import Icon from "Modules/icons";
import { search as searchIcon } from "assets/img";
import { IBasicSearchProps } from "../../type";

import styles from "./styles.module.scss";

const Search: React.FC<IBasicSearchProps> = ({ onSelected, transactionCount, data, type }) => {
  const [value, setValue] = useState("");
  const filteredData = data.filter((row) => {
    if (typeof row.value === "string") {
      return row.value.toLowerCase().includes(value.toLowerCase());
    }
    return row.searchKey.toLowerCase().includes(value.toLowerCase());
  });

  return (
    <>
      <div className={styles.info}>
        Please select {type.toLowerCase()} for these {transactionCount} transaction
        {transactionCount > 1 ? "s" : ""}
      </div>
      <div className={styles.search}>
        <Input placeholder={`Search ${type.toLowerCase()}`} value={value} onChange={(e) => setValue(e.target.value)} />
        <Icon className={styles["search__icon"]} src={searchIcon} />
      </div>
      <ul className={styles.list} data-testid="bulk-action-data">
        {filteredData.map((row) => (
          <li key={row.id} onClick={() => onSelected(row)}>
            {row.value}
          </li>
        ))}
      </ul>
    </>
  );
};

export default Search;
