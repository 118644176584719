import React from "react";
import cn from "classnames";
import { Skeleton as AntdSkeleton } from "antd";

import styles from "./styles.module.scss";

const Skeleton = () => {
  return (
    <>
      <div className={styles.tab} data-testid="skeleton-loading">
        <AntdSkeleton.Input size="small" active className={styles.loading} />
      </div>

      <div className={styles.text}>
        <AntdSkeleton.Input size="small" active className={styles.loading} />
      </div>
      <div className={styles.content}>
        <div className={styles.subMenu}>
          <AntdSkeleton.Input size="small" active className={styles.loading} />
          <AntdSkeleton.Input size="small" active className={styles.loading} />
          <AntdSkeleton.Input size="small" active className={styles.loading} />
        </div>
        <BodySkeleton />
      </div>
    </>
  );
};

export const BodySkeleton = ({ paddingLess = false }: { paddingLess?: boolean }) => {
  return (
    <div className={cn(styles.body, { [styles.paddingLess]: paddingLess })}>
      <AntdSkeleton.Input size="small" active className={cn(styles.loading, styles.title)} />

      <div className={styles.section}>
        <AntdSkeleton.Input size="small" active className={cn(styles.loading, styles.title)} />
        <div className={styles.subMenu}>
          <AntdSkeleton.Input size="small" active className={styles.loading} />
          <AntdSkeleton.Input size="small" active className={styles.loading} />
          <AntdSkeleton.Input size="small" active className={styles.loading} />
        </div>
      </div>
    </div>
  );
};

export default Skeleton;
