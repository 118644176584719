import React, { useState } from "react";
import classNames from "classnames";
import Accordion from "Modules/TransactionDetail/Section/Accordion";
import { ITransactionStatusProps } from "../index";

import styles from "./styles.module.scss";
import { isBillsTrxnsPage } from "../TransactionDetail";

const TransactionStatus: React.FC<ITransactionStatusProps> = ({ transactionStatus, footer, currentStatusId, page }) => {
  const [open, setOpen] = useState(true);

  const activeStepIndex = transactionStatus.findIndex((status) => status?.id === currentStatusId);
  const activeStep = transactionStatus[activeStepIndex];
  const editedTransactionStatus = open ? transactionStatus : [activeStep];

  const lastItem = (index: number) => {
    if (open) {
      return editedTransactionStatus.length - 1 === index;
    }

    return (
      (activeStepIndex === 0 && editedTransactionStatus.length === 1) ||
      transactionStatus.length - 1 === activeStepIndex
    );
  };

  const isMiddle = () => {
    if (open || activeStepIndex === 0) {
      return false;
    }

    return activeStepIndex !== 0 || transactionStatus.length - 1 !== activeStepIndex;
  };

  return (
    <Accordion open={open} setOpen={setOpen} hide={transactionStatus.length === 1}>
      <h4 className={classNames(styles.title, { [styles.titleColor]: isBillsTrxnsPage(page) })}>Transaction Status</h4>
      <div className={styles["status-list"]}>
        <ul>
          {editedTransactionStatus?.map((status, index) => {
            const isActive = status?.id === activeStep?.id;
            const isDisabled = open && index < activeStepIndex;
            return (
              <li className={styles["status-list__element"]} key={status?.id}>
                <div className={styles["element-circle__wrapper"]}>
                  {isMiddle() && (
                    <span
                      className={classNames(styles["element-circle__middle-line"], {
                        [styles["element-circle-line__active"]]: isActive,
                        [styles["element-circle-line__disabled"]]: isDisabled,
                      })}
                    />
                  )}
                  <div
                    className={classNames(styles["element-circle"], {
                      [styles["element-circle__active"]]: isActive,
                      [styles["element-circle__disabled"]]: isDisabled,
                    })}
                  ></div>
                  {!lastItem(index) && (
                    <span
                      className={classNames(styles["element-circle__line"], {
                        [styles["element-circle__line-compact"]]: !open,
                        [styles["element-circle__line-bigger"]]:
                          status?.subtitle && editedTransactionStatus[index + 1]?.subtitle,
                      })}
                    />
                  )}
                </div>
                <div className={styles["status-list__element-content"]}>
                  <div>
                    <p
                      className={classNames({
                        [styles.active]: isActive,
                        [styles.disabled]: isDisabled,
                      })}
                    >
                      {status?.title}
                    </p>
                    {Boolean(status?.subtitle) && (
                      <p
                        className={classNames(styles.subtitle, {
                          [styles.disabled]: isDisabled,
                        })}
                      >
                        {status?.subtitle}
                      </p>
                    )}
                    {Boolean(status?.notes) && (
                      <p
                        className={classNames(styles.notes, {
                          [styles.disabled]: isDisabled,
                        })}
                      >
                        {status.notes}
                      </p>
                    )}
                  </div>
                  <span className={styles.date}>{status?.date}</span>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
      {footer && <div>{footer}</div>}
    </Accordion>
  );
};

export default TransactionStatus;
