export interface ChangeLogResponse {
  status: number;
  payload: ChangeLog[];
}

export interface ChangeLog {
  id: string;
  selfApprovalPolicyID: string;
  action: string;
  title: string;
  change: Array<{
    action: string;
    displayName: string;
    identifier?: string;
    assigneeRole?: string;
    employeeRole?: string;
  }>;
  creator: string;
  createdBy: string;
  createdAt: string;
  actionExpanded?: boolean;
}

export const enum SidePanelContent {
  POLICY_CONTENT = "policyContent",
  CHANGE_LOG_HISTORY = "changelogHistory",
}
