import React from "react";
import { Table } from "antd";
import s from "../Analytics.module.scss";
import { getMerchantColumns } from "../Elements/MerchantDistribution/merchantColumns";
import { MerchantShimmer } from "./Shimmer";
import { EmptyState } from "Views/Analytics/EmptyState";
import "./shimmer.scss";
import "../Analytics.scss";
import { IAnalyticsFilter, IRootDataType, ISpendingMerchantsData } from "Views/Analytics/@types";
import classNames from "classnames";
import { AnalyticsCardTitle } from "../Analytics";

interface ISpendingMerchant {
  filter: IAnalyticsFilter;
  spendingMerchantsLoading: boolean;
  spendingMerchantsData: ISpendingMerchantsData[];
  handleApplyDeeplink: (rootData: IRootDataType) => void;
}

export const Merchants = ({
  filter,
  spendingMerchantsLoading,
  spendingMerchantsData,
  handleApplyDeeplink,
}: ISpendingMerchant) => {
  if (spendingMerchantsLoading) {
    return <MerchantShimmer loading={spendingMerchantsLoading} />;
  }
  if (!spendingMerchantsLoading && Array.isArray(spendingMerchantsData) && !spendingMerchantsData.length) {
    return <EmptyState />;
  }

  const onClickRow = (data: ISpendingMerchantsData): void => {
    handleApplyDeeplink({
      from: AnalyticsCardTitle.MERCHANTS,
      data: {
        selectedMerchants: data?.merchant,
      },
    });
  };

  return (
    <Table
      columns={getMerchantColumns(filter)}
      dataSource={spendingMerchantsData}
      pagination={false}
      scroll={{ y: "280px" }}
      className={classNames(s.tableCSS, "analytics-table")}
      onRow={(record) => ({
        onClick: () => onClickRow(record),
      })}
    />
  );
};
