export const BANK_NAME_FIELD_ID = 1;
export const BENEFICIARY_WHT = 124;
export const WHT_TAX_RATE = 130;

export const BIC_TYPE_KEY = "bic_type";
export const ERROR_BIC_COUNTRY_MISMATCH = `${BIC_TYPE_KEY} does not match the selected country above`;

export const BIC_TYPE = {
  achCode: "ach",
  bsbCode: "bsb",
  ibanCode: "iban",
  ifscCode: "ifsc",
  sortCode: "sort",
  swiftCode: "swift",
};

// KEY = DYNAMIC FORM FIELD ALIAS, VALUE = OCR field
export const DYNAMIC_FORM_OCR_MAPPING = {
  beneficiaryName: "recipientName",
  beneficiaryBankAccountNumber: "recipientBankAccount",
  mobileNumber: "recipientBankAccount",
  nric: "recipientBankAccount",
  uen: "recipientBankAccount",
  beneficiaryAddress: "recipientAddress",
  beneficiaryCity: "recipientCity",
  ibanCode: "recipientIBAN",
  beneficiaryBankName: "recipientSWIFT",
  ifscCode: "recipientIFSC",
  achCode: "recipientRoutingNo",
  bsbCode: "recipientBSB",
  sortCode: "recipientSortCode",
  recipientEmail: "recipientEmails",
};
