import React, { useContext } from "react";
import classNames from "classnames";

import Guidelines from "Views/KYX/Modal/Guidelines";
import ClientAgreementModal from "Views/KYX/Modal/ClientAgreement";
import SaveAsDraftLogOutModal from "Views/KYX/Modal/SaveAsDraft";
import ChangeKYBMethodModal from "Views/KYX/Modal/ChangeKYBMethod";
import MyInfoError from "Views/KYX/Modal/MyInfoError";

import { KYXModalContext } from "Views/KYX/Context";
import { KYX_MODAL_TYPES } from "Views/KYX/Constants";
import { IKYXModal, IKYXModalContext, IKYXModalPayload } from "Views/KYX/@types";

import styles from "./index.module.scss";
import AccessDeniedModal from "./AccessDeniedJA";

/**
 * A custom React hook to manage the modal types.
 * @returns {IKYXModal} An object containing information about the modal types.
 */
export const useModalType = (): IKYXModal => {
  const { resetModal, modal }: IKYXModalContext = useContext(KYXModalContext);

  const { guidelinesType, guidelinesAction, methodType, errorFields, errorType }: IKYXModalPayload =
    modal?.payload || {};

  return {
    [KYX_MODAL_TYPES.NONE]: {
      close: () => null,
      className: null,
      children: null,
    },
    [KYX_MODAL_TYPES.CLIENT_AGREEMENT]: {
      close: resetModal,
      className: classNames(styles.clientAgreement, "modal-client-agreement"),
      children: <ClientAgreementModal />,
    },
    [KYX_MODAL_TYPES.DOCUMENT_GUIDELINES]: {
      close: resetModal,
      className: classNames(styles.guidelines, "modal-guidelines"),
      children: <Guidelines type={guidelinesType} action={guidelinesAction} />,
    },
    [KYX_MODAL_TYPES.SAVE_AS_DRAFT_LOGOUT_MODAL]: {
      close: resetModal,
      className: classNames(styles.saveAsDraft, "modal-save-as-draft"),
      children: <SaveAsDraftLogOutModal />,
    },
    [KYX_MODAL_TYPES.CHANGE_KYB_METHOD]: {
      close: resetModal,
      className: classNames(styles.changeKYBMethod, "modal-change-kyb-method"),
      children: <ChangeKYBMethodModal type={methodType} />,
    },
    [KYX_MODAL_TYPES.MY_INFO_ERROR]: {
      close: resetModal,
      className: classNames(styles.acraDataDiscrepancy, "modal-acra-data-discrepancy"),
      children: <MyInfoError fields={errorFields} type={errorType} />,
    },
    [KYX_MODAL_TYPES.ACCESS_DENIED_JOINT_APPLICANT]: {
      close: resetModal,
      className: classNames(styles.acraDataDiscrepancy, "modal-acra-data-discrepancy"),
      children: <AccessDeniedModal />,
    },
  };
};
