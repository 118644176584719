import React, { useMemo, useState } from "react";
import { IManualFormContext, ISaveAsDraftRequest } from "Views/KYX/@types";
import { initialManualFormContext, ManualFormContext } from "Views/KYX/Context/ManualForm";

export const ManualFormProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const [isFormModified, setIsFormModified] = useState<boolean>(initialManualFormContext.isFormModified);
  const [formData, setFormData] = useState<ISaveAsDraftRequest>(initialManualFormContext.formData);

  const value = useMemo(
    (): IManualFormContext => ({
      isFormModified,
      setIsFormModified,
      formData,
      setFormData,
    }),
    [isFormModified, formData]
  );

  return <ManualFormContext.Provider value={value}>{children}</ManualFormContext.Provider>;
};
