import ApprovalRequest, {
  ApprovalRequestDetail,
  ApprovalStep,
} from "Models/Approval/ApprovalCenter/approvalRequest.interface";
import { currencyFormatter, currencyFormatterV2 } from "utility";
import { CARD_LIMIT_TYPES, CARD_TYPE } from "Views/Card/types";
import { ApprovalCenterConstant, BillFilter, REIMBURSEMENT_STATUS, TAB_KEY, URLParams } from "./types";
import BillApprovalRequest from "Models/Approval/ApprovalCenter/billApprovalRequest.interface";
import ReimbursementApprovalRequest from "Models/Approval/ApprovalCenter/reimbursementApprovalRequest.interface";
import { APPROVAL_REQUEST_BULK_LIMIT } from "constants/ApprovalRequest.constants";
import { CardTypeLabels } from "Views/Card/List/Table/ColumnsData";
import { CardUpdateRequestPayload } from "Views/Card/Review/types";
import CardApprovalRequest from "Models/Approval/ApprovalCenter/cardApprovalRequest.interface";
import { ListData } from "Modules/DS/Tabs";
import FundTransferApprovalRequest from "Models/Approval/ApprovalCenter/fundTransferApprovalRequest.interface";

const invalidCurrencyRegex = /^Only Available for (IDR|SGD|PHP)+$/;
const invalidCurrencyDetailRegex =
  /Pay immediately is only available for (IDR|SGD|PHP)\. This reimbursement needs to be settled by the payroll system\./;

export const getSingleRequestDeclineWarning = (amount, currency, requestType, requesterName) => {
  return `You are about to decline the<span class="highlighted-text"> ${currencyFormatter(
    amount,
    currency
  )} ${requestType}</span> request from<span class="highlighted-text"> ${requesterName}</span>.`;
};

export const getSingleReimbursementRequestDeclineWarning = (record: ReimbursementApprovalRequest) => {
  return getSingleRequestDeclineWarning(record.amount, record.currency, "Reimbursement", record.requesterName);
};

export const getSingleFundTransferRequestDeclineWarning = (record: FundTransferApprovalRequest) => {
  return getSingleRequestDeclineWarning(record.amount, record.currency_code, "Funds Transfer", record.user_name);
};

export const getSingleBillRequestDeclineWarning = (record: BillApprovalRequest) => {
  const requesterName = record.requesterFirstName + " " + record.requesterLastName;
  return getSingleRequestDeclineWarning(record.totalBillAmount, record.sendCurrency, "Bill Payment", requesterName);
};

export const getBulkRequestDeclineWarning = (records, type) => {
  return `You are about to decline <span class="highlighted-text">${records.length} ${type}</span> request${
    records.length > 1 ? "s" : ""
  }.`;
};

export const getSingleCardRequestDeclineWarning = (record) => {
  const cardType = record?.isPhysicalCard ? CardTypeLabels[CARD_TYPE.PHYSICAL] : CardTypeLabels[CARD_TYPE.VIRTUAL];
  return `<span>You are about to decline the <b>${cardType} Card</b> request from <b>
        ${record?.requesterName}
      </b>
    </span>`;
};

export const setInitialActiveTabFromURL = () => {
  const query = new URLSearchParams(window.location.search);
  const activeTab = query.get("activeTab");
  return activeTab === null ? "invoice" : activeTab;
};

export const getRequestIndex = (requests: ApprovalRequest[], request: ApprovalRequest) => {
  return requests.findIndex((currentRequest) => currentRequest.id === request.id);
};

export const getURLParams = (): URLParams => {
  const urlParams: URLParams = { type: "" };
  const activeTab = setInitialActiveTabFromURL();

  urlParams.type = activeTab;
  const query = new URLSearchParams(window.location.search);
  switch (activeTab) {
    case TAB_KEY.REIMBURSEMENT:
      const pendingSettlement = query.get("pendingSettlement");
      if (pendingSettlement) {
        urlParams.pendingSettlement = true;
      }
      break;
  }
  return urlParams;
};

export const shouldDisableCurrentRowSelection = (
  selectedRows: ApprovalRequest[],
  currentRow: ApprovalRequest
): boolean => {
  if (selectedRows.length === APPROVAL_REQUEST_BULK_LIMIT) {
    return selectedRows.findIndex((row) => row.processID === currentRow.processID) === -1;
  }
};

export const shouldDisableHeaderSelection = (
  approvalRequestOnCurrentPage: ApprovalRequest[],
  selectedRequestOnAllPages: ApprovalRequest[]
): boolean => {
  const getUnSelectedRowsOnCurrentPage = (): ApprovalRequest[] => {
    let unselectedRows: ApprovalRequest[] = [];
    approvalRequestOnCurrentPage.forEach((request) => {
      let unselectedOnCurrentPage = getRequestIndex(selectedRequestOnAllPages, request) === -1;
      if (unselectedOnCurrentPage) {
        unselectedRows.push(request);
      }
    });
    return unselectedRows;
  };

  let unselectedRowsOnCurrentPage = getUnSelectedRowsOnCurrentPage();
  return unselectedRowsOnCurrentPage.length + selectedRequestOnAllPages.length > APPROVAL_REQUEST_BULK_LIMIT;
};

export const getTotalAmountForSelectedRequests = (selectedRequests: ApprovalRequest[], activeTab: TAB_KEY): string => {
  return selectedRequests
    .map((row) => {
      let amount: number = 0;
      switch (activeTab) {
        case TAB_KEY.INVOICE:
          amount = (row as BillApprovalRequest).totalBillAmount;
          break;
        case TAB_KEY.REIMBURSEMENT:
          amount = (row as ReimbursementApprovalRequest).walletAmount;
          break;
      }
      return amount;
    })
    .reduce((prev, curr) => prev + curr, 0)
    .toFixed(2);
};

export const getLabelForTotalAmount = (activeTab: TAB_KEY): string => {
  let label = "";
  switch (activeTab) {
    case TAB_KEY.INVOICE:
      label = "You Send";
      break;
    case TAB_KEY.REIMBURSEMENT:
      label = "Total";
      break;
  }
  return label;
};

export const getSelectedProcessIds = (
  isBulkApproval: boolean,
  selectedRows: ApprovalRequest[],
  selectedRow: ApprovalRequest
) => {
  if (isBulkApproval) {
    return selectedRows.map((row) => row.processID);
  } else if (selectedRow) {
    return [selectedRow.processID];
  }
  return [];
};

export const getSelectedRowForDeclineModal = (
  isBulkApproval: boolean,
  selectedProcessIds: string[],
  selectedRows: ApprovalRequest[],
  selectedRow: ApprovalRequest
) => {
  if (isBulkApproval) {
    if (selectedProcessIds.length === 1) {
      return selectedRows.find((row) => row.processID === selectedProcessIds[0]);
    } else {
      return null;
    }
  } else {
    return selectedRow;
  }
};

export const detailDataMapping = (transaction: any) => {
  return {
    ...transaction,
    id: transaction.id,
    createdAt: transaction.created_at,
    transactionNumber: transaction.transaction_number,
    transactionType: "Reimbursement",
    merchant: transaction.simplified_merchant_name || transaction.merchant,
    amount: currencyFormatterV2(transaction.amount || 0, transaction.currency_code || "SGD", true),
    currency: transaction.currency_code,
    employee: {
      name: transaction.user_name,
      team: transaction.team_name || "N/A",
    },
    category: transaction?.expense_category_name || "N/A",
    receipt: transaction?.receipts?.urls,
    fxRate: transaction?.foreign_currency_amount || "N/A",
    fxCurrency: transaction?.foreign_currency_code || "N/A",
    comment: transaction.comment,
    accountingMessage: transaction?.accounting_message || "",
    accountingTransactionStatus: transaction?.accounting_transaction_status,
    baseAmount: transaction.amount,
  };
};

export const shouldShowCheckboxSelection = (activeTab: TAB_KEY, approvalFilters: BillFilter): boolean => {
  if (activeTab === TAB_KEY.FUND) {
    return false;
  } else if (activeTab === TAB_KEY.REIMBURSEMENT) {
    return getReimbursementSubTab(approvalFilters) === REIMBURSEMENT_STATUS.PENDING;
  }
  return true;
};

export const getReimbursementSubTab = (approvalRequestFilter: BillFilter): REIMBURSEMENT_STATUS => {
  return approvalRequestFilter.pendingSettlement === true ? REIMBURSEMENT_STATUS.READY : REIMBURSEMENT_STATUS.PENDING;
};

export const isPayNowSetupAsBankAccount = (record: ReimbursementApprovalRequest) => {
  return record.payImmediatelyEligible && record.payImmediatelyValidationError === "";
};

const getCardLimitType = (limitType: CARD_LIMIT_TYPES) => {
  if (limitType) {
    if (limitType === CARD_LIMIT_TYPES.unlimited) {
      return "unlimited";
    } else {
      return limitType.replace("_limit", "");
    }
  }
};

export const prepareCardDetailsPayload = (cardDetails: any): CardUpdateRequestPayload => {
  let cardUpdatePayload = {} as any;
  if (cardDetails) {
    cardUpdatePayload.cardTitle = cardDetails.cardTitle;
    cardUpdatePayload.cardLimitType = getCardLimitType(cardDetails.cardLimitType);
    cardUpdatePayload.cardLimit = +cardDetails.cardLimit;
    if (cardDetails.expenseCategoryId) {
      cardUpdatePayload.categoryID = "" + cardDetails.expenseCategoryId;
    }
    if (cardDetails.tags) {
      cardUpdatePayload.tags = cardDetails.tags?.map((tag) => {
        return { label: tag.label, value: tag.value };
      });
    }
    if (cardDetails.merchants) {
      cardUpdatePayload.merchants = cardDetails.merchants.map((merchant) => {
        return { merchantName: merchant.merchantName };
      });
    }
    if (cardDetails.merchants) {
      cardUpdatePayload.merchants = cardDetails.merchants.map((merchant) => {
        return { merchantName: merchant.merchantName };
      });
    }
    if (cardDetails.merchantCategoryLimits) {
      cardUpdatePayload.merchantCategoryLimits = cardDetails.merchantCategoryLimits;
    }
  }
  return cardUpdatePayload;
};

export const displayCardLimit = (cardLimitType, cardLimit, cardLimitCurrency) => {
  let cardLimitDisplayValue = "";
  if (cardLimitType?.toString() === "unlimited") {
    cardLimitDisplayValue = "Unlimited";
  } else {
    if (cardLimit) {
      cardLimitDisplayValue = Number.isNaN(Number(cardLimit))
        ? cardLimit
        : currencyFormatter(cardLimit, cardLimitCurrency);
    } else {
      cardLimitDisplayValue = ApprovalCenterConstant.NotAvailable;
    }
  }
  return cardLimitDisplayValue;
};

export const isCardLimitValidationFail = (approvalRequest: CardApprovalRequest): boolean => {
  if (approvalRequest.cardLimitType?.toString() === "unlimited") {
    return false;
  } else if (!approvalRequest.cardLimit) {
    return true;
  }
};

export const getCardLimitError = (isBulkApproval: boolean): string => {
  return `Please input the spending limit before approving the request${isBulkApproval ? "(s)" : ""}.`;
};

export const checkIfLastApprover = (approverSteps: ApprovalStep[]): boolean => {
  let isLastApprover = false;
  if (approverSteps) {
    const pendingApprovals = approverSteps.filter((approverStep) => approverStep.stepStatus === "Pending").length;
    if (pendingApprovals === 1) {
      isLastApprover = true;
    }
  }
  return isLastApprover;
};

export const showPayImmediately = (
  reimbursementApprovalRequestDetail: ApprovalRequestDetail,
  activeSubTab: REIMBURSEMENT_STATUS
) => {
  return (
    activeSubTab === REIMBURSEMENT_STATUS.READY ||
    checkIfLastApprover(reimbursementApprovalRequestDetail?.approvalSteps)
  );
};

export interface ApprovalRequestTabs {
  title: string;
  tabKey: string;
  content: (tab: ListData) => React.ReactNode;
}

export const getApprovalRequestTabs = (allowedApprovalRequestTabItems): ApprovalRequestTabs[] => {
  return allowedApprovalRequestTabItems.map((approvalRequestTabItem) => {
    const { tabKey, title, pendingRequests } = approvalRequestTabItem;
    return { tabKey: tabKey, title: title, count: pendingRequests, content: (tab: ListData) => "" };
  });
};

export const isCRCurrencyRuleInvalid = (record: ReimbursementApprovalRequest): boolean => {
  let currencyRuleInvalid = false;
  if (
    invalidCurrencyRegex.test(record.payImmediatelyValidationError) ||
    invalidCurrencyDetailRegex.test(record.payImmediatelyValidationError)
  ) {
    currencyRuleInvalid = true;
  }
  return currencyRuleInvalid;
};

export const isKYBCountryAndWalletCurrenyMatch = (dashboardCurrency: string, KYBCountry: string): boolean => {
  const KYBCountryAndWalletCurrency = {
    SG: "SGD",
    ID: "IDR",
    PH: "PHP",
  };
  return KYBCountryAndWalletCurrency[KYBCountry] && KYBCountryAndWalletCurrency[KYBCountry] === dashboardCurrency;
};
