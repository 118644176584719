import APIclient from "API/Client";
import { GetOrgId } from "utility";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";

export enum FETCH_CARD_NUMBER_ACTIONS {
  FETCH_CARD_NUMBER_REQUEST = "FETCH_CARD_NUMBER_REQUEST",
  FETCH_CARD_NUMBER_SUCCESS = "FETCH_CARD_NUMBER_SUCCESS",
  FETCH_CARD_NUMBER_FAILURE = "FETCH_CARD_NUMBER_FAILURE",
}

export interface ICardNumberData {
  cardNumber: string;
  statusMessage: string;
}

export const getDispatchObject = (action: FETCH_CARD_NUMBER_ACTIONS, data = {}) => ({
  type: action,
  data: { ...data },
});

export const clearCardNumber = () => {
  return (dispatch) => {
    dispatch(getDispatchObject(FETCH_CARD_NUMBER_ACTIONS.FETCH_CARD_NUMBER_SUCCESS));
  };
};

const processCardNumberData = (data) => {
  const { card_number = "", status_message = "Please try again!" } = data?.payload || {};
  return {
    cardNumber: card_number,
    statusMessage: status_message,
  };
};

export const fetchCardNumber = (card_id: string) => {
  return (dispatch) => {
    dispatch(getDispatchObject(FETCH_CARD_NUMBER_ACTIONS.FETCH_CARD_NUMBER_REQUEST));
    APIclient.getData(`/api/v1/org/card/${card_id}/details?organisation_id=${GetOrgId()}`)
      .then((response) => {
        const { data = {} }: any = response || {};
        if (data?.status === HTTP_STATUS_CODE.OK) {
          dispatch(getDispatchObject(FETCH_CARD_NUMBER_ACTIONS.FETCH_CARD_NUMBER_SUCCESS, processCardNumberData(data)));
        } else {
          dispatch(getDispatchObject(FETCH_CARD_NUMBER_ACTIONS.FETCH_CARD_NUMBER_FAILURE, processCardNumberData(data)));
        }
      })
      .catch((error) => {
        dispatch(
          getDispatchObject(
            FETCH_CARD_NUMBER_ACTIONS.FETCH_CARD_NUMBER_FAILURE,
            processCardNumberData(error?.response?.data)
          )
        );
      });
  };
};
