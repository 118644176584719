import React from "react";
import { Skeleton } from "antd";
import styles from "./index.module.scss";

const BillPreviewShimmer = () => {
  return (
    <>
      <Skeleton.Input className={styles.shimmer} active={true} />
      <Skeleton.Input className={styles.shimmer} active={true} />
    </>
  );
};

export default BillPreviewShimmer;
