import * as React from "react";
import classNames from "classnames";
import { ISpendingLock, SPENDING_LOCK_TYPES } from "Views/Card/types";
import StackedImages from "./StackedImages";

import styles from "./index.module.scss";

const MAX_MERCHANT_IMAGES = 3;

interface IMerchantLocksProps {
  merchantLocks: ISpendingLock[];
  disabledCard: boolean;
  spendingLockType: string;
}

const MerchantLogo = ({ merchantLocks, disabledCard, spendingLockType }: IMerchantLocksProps) => {
  const containerClassName = classNames([
    styles.logo,
    {
      [styles.logoDisabled]: disabledCard && merchantLocks?.length > 0,
    },
  ]);
  const imageClassName =
    spendingLockType === SPENDING_LOCK_TYPES.MERCHANT ? styles.logoMerchants : styles.logoCategories;
  return (
    <div className={containerClassName}>
      {merchantLocks?.length > 0 ? (
        <StackedImages merchantLocks={merchantLocks} maxImages={MAX_MERCHANT_IMAGES} imageClassName={imageClassName} />
      ) : (
        <p className={styles.logoText}>None</p>
      )}
    </div>
  );
};

export default MerchantLogo;
