import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Typography, Button } from "antd";

import { currencyFormatter } from "utility";
import LoadingIcon from "Modules/loading";

import styles from "./StopPaymentConfirmation.module.scss";

const StopPaymentConfirmation = ({ onConfirm, onCancel, currency, amount, merchant, isSubmit }) => {
  const { Text } = Typography;

  return (
    <>
      <Text className={classNames(styles.text, styles.small)}>Confirm stop payment for</Text>
      <Text className={classNames(styles.text, styles.big)}>{merchant}</Text>
      <Text className={classNames(styles.text, styles.big, styles.space)}>{currencyFormatter(amount, currency)}</Text>
      <Text className={classNames(styles.text, styles.normal, styles.space)}>
        If you confirm, the transaction will be stopped.
      </Text>
      <Button
        className={classNames(styles.button, styles.confirm)}
        type="primary"
        shape="round"
        size="large"
        danger={true}
        onClick={onConfirm}
        disabled={isSubmit}
      >
        {isSubmit ? <LoadingIcon size="s" /> : "Confirm Stop Payment"}
      </Button>
      <Text className={classNames(styles.button, styles.text, styles.normal, styles.cancel)} onClick={onCancel}>
        Cancel
      </Text>
    </>
  );
};

StopPaymentConfirmation.propTypes = {
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  merchant: PropTypes.string,
  currency: PropTypes.string,
  amount: PropTypes.string,
  isSubmit: PropTypes.bool,
};

StopPaymentConfirmation.defaultProps = {
  onConfirm: () => {},
  onCancel: () => {},
  currency: "",
  amount: "",
  merchant: "",
  isSubmit: false,
};

export default StopPaymentConfirmation;
