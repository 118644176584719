import React, { useMemo } from "react";
import { useLocation } from "react-router";
import qs from "query-string";

import { useSetUrlParams } from "Views/SubscriptionManagement/hooks/useSetUrlParams";

import { ActionChip } from "@spenmo/splice";

interface ISingleFilterProps {
  label: string;
  id: string;
}

export const SingleFilter = ({ label, id }: ISingleFilterProps) => {
  const setUrlParams = useSetUrlParams();
  const { search, pathname } = useLocation();
  const query = useMemo(() => qs.parse(search) ?? {}, [search]);

  const onSelect = (_, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setUrlParams(pathname, {
      ...query,
      [id]: true,
    });
    event.stopPropagation();
  };

  const onDelete = () => {
    const newFilters = { ...query };
    delete newFilters[id];
    setUrlParams(pathname, newFilters);
  };

  return <ActionChip label={label} active={query?.[id] === "true"} onClick={onSelect} onDelete={onDelete} />;
};
