import React, { useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { IBreakpoint, IBreakPointItem, IRowProps, IScreenMap, responsiveContainer } from "../types";
import useMergePropByScreen from "../hook/useMergePropByScreen";
import RowContext from "./context";
import ResponsiveObserve from "../responsiveObserve";
import styles from "./styles.module.scss";

const Row: React.FC<IRowProps> = ({
  justify,
  align,
  style,
  children,
  gutter: gutterProps = 0,
  offset: offsetProps,
  column = 24,
  className = "",
  responsiveArray = responsiveContainer,
  ...others
}) => {
  const [curScreens, setCurScreens] = useState<IScreenMap>({
    sm: false,
    md: false,
    lg: false,
    xl: false,
  });

  const mergeAlign = useMergePropByScreen(align, curScreens);
  const mergeJustify = useMergePropByScreen(justify, curScreens);
  const mergeColumn = useMergePropByScreen(column, curScreens);

  useEffect(() => {
    const token = ResponsiveObserve.subscribe((screen) => {
      setCurScreens(screen);
    });
    return () => ResponsiveObserve.unsubscribe(token);
  }, []);

  const { gutters, offset }: IBreakPointItem = useMemo(() => {
    let result: IBreakPointItem = responsiveArray[responsiveArray.length - 1];
    for (let responsiveItem of responsiveArray) {
      const breakpoint: IBreakpoint = responsiveItem.breakPoint;
      if (curScreens[breakpoint]) {
        result = responsiveItem;
        break;
      }
    }
    return result;
  }, [curScreens, responsiveArray]);

  const classes = classNames(
    {
      [styles[`row-justify-${mergeJustify}`]]: justify !== undefined,
      [styles[`row-align-${mergeAlign}`]]: align !== undefined,
      [styles[`row-column-${mergeColumn}`]]: column,
    },
    styles.container,
    className
  );

  const mergedGutter = useMemo(() => {
    if (Array.isArray(gutterProps)) return gutterProps;
    if (gutterProps > 0) return [gutterProps, undefined];
    return gutters;
  }, [gutterProps, gutters]);

  const rowStyle: React.CSSProperties = useMemo(() => {
    let mergedStyle: React.CSSProperties = {};
    const mergedOffset = offsetProps != null ? offsetProps : offset;
    if (mergedOffset) {
      mergedStyle.marginInline = mergedOffset;
    }

    if (mergedGutter) {
      if (mergedGutter[0] > 0) {
        mergedStyle.gap = mergedGutter[0];
      }
      if (mergedGutter[1] != null) {
        mergedStyle.paddingBlock = mergedGutter[1];
      }
    }

    return mergedStyle;
  }, [offsetProps, offset, mergedGutter]);

  const rowContext = React.useMemo(() => ({ curScreens }), [curScreens]);

  return (
    <RowContext.Provider value={rowContext}>
      <div {...others} className={classes} style={{ ...rowStyle, ...style }}>
        {children}
      </div>
    </RowContext.Provider>
  );
};

if (process.env.NODE_ENV !== "production") {
  Row.displayName = "Row";
}

export default Row;
