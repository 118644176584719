import React, { useState } from "react";
import cn from "classnames";
import { useSelector } from "react-redux";
import { Typography } from "@spenmo/splice";

import styles from "./styles.module.scss";

const SINGAPORE = "Singapore";
const NIUM = "NIUM";
const KYB_APPROVED = "APPROVED";

export const NiumDisclaimer = ({ type }: { type: "card" | "topup" | "bill" }) => {
  const orgDetail = useSelector((state: any) => state.b2bOrgDetailReducer);
  const orgConfig = useSelector((state: any) => state.orgConfigs?.data?.payload?.configs);

  const { country, cardVendor, kybStatus } = orgDetail?.data?.payload || {};
  const useNiumAsPayoutVendor = orgConfig?.facilitator_vendor?.value === NIUM && kybStatus === KYB_APPROVED;

  const showDisclaimerLogic = type === "bill" ? useNiumAsPayoutVendor : country === SINGAPORE && cardVendor === NIUM;

  const [clamp, setClamp] = useState(true);

  if (showDisclaimerLogic) {
    return (
      <div className={styles.container}>
        <Typography size="s" variant="body-content" className={cn(styles.disclaimer, { [styles.clamp]: clamp })}>
          In Singapore, the payment services (including the issuance of the Spenmo Visa Card are provided by Nium Pte.
          Ltd., a company registered in Singapore (UEN 201422465R) and a major payment institution licensed by the
          Monetary Authority of Singapore. Digital Services SG Five Pte. Ltd. is not licensed to provide any payment
          services in Singapore (or exempt from such licensing).
        </Typography>
        <Typography onClick={() => setClamp(!clamp)} size="s" variant="body-content" className={styles.link}>
          See {clamp ? "more" : "less"}
        </Typography>
      </div>
    );
  }

  return null;
};
