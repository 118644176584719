import React, { useContext, useEffect, useState } from "react";
import classNames from "classnames";
import s from "Views/Analytics/Layout/index.module.scss";
import styles from "./index.module.scss";
import moment from "moment";
import { Tooltip, TooltipArrowAlignmentEnum, TooltipPositionEnum } from "Modules/DS/Tooltip";
import AnalyticsContext from "Views/Analytics/Context/context";

export interface CustomDateType {
  label: string;
  key: "months" | "years" | "quarters";
  start: string;
  end: string;
}

interface CustomFilterProps {
  triggerCustomDateSelection: (date: CustomDateType) => void;
  active: boolean;
}

const CustomFilter = ({ triggerCustomDateSelection, active }: CustomFilterProps): JSX.Element => {
  const { filter } = useContext(AnalyticsContext);
  const [selectedCustomDate, setSelectedCustomDate] = useState<CustomDateType>(null);
  const [showCustomDatePopover, setShowCustomDatePopover] = useState<boolean>(filter.otherCustomFilter.isCustom);

  useEffect(() => {
    if (!active) {
      setSelectedCustomDate(null);
    }
  }, [active]);

  const customDateSelections: CustomDateType[] = [
    {
      label: "Month to Date",
      key: "months",
      start: moment().utc().startOf("month").toISOString(),
      end: moment().utc().endOf("date").toISOString(),
    },
    {
      label: "Quarter to Date",
      key: "quarters",
      start: moment().utc().startOf("quarter").toISOString(),
      end: moment().utc().endOf("date").toISOString(),
    },
    {
      label: "Year to Date",
      key: "years",
      start: moment().utc().startOf("year").toISOString(),
      end: moment().utc().endOf("date").toISOString(),
    },
  ];

  const handleSelectCustomDate = (date: CustomDateType): void => {
    triggerCustomDateSelection(date);
    setSelectedCustomDate(date);
  };

  const formatDate = (date: CustomDateType): string => {
    return moment(date.start).utc().format("D MMM") + " - " + moment(date.end).utc().format("D MMM YYYY");
  };

  return (
    <div
      onClick={() => setShowCustomDatePopover(!showCustomDatePopover)}
      className={classNames(s.filterItem, styles.customFilter, {
        [s.active]: active,
      })}
      tabIndex={-1}
      onBlur={() => setShowCustomDatePopover(false)}
    >
      {selectedCustomDate?.label ? (
        <Tooltip
          text={formatDate(selectedCustomDate)}
          alignArrow={TooltipArrowAlignmentEnum.CENTER}
          position={TooltipPositionEnum.BOTTOM}
        >
          {selectedCustomDate?.label}
        </Tooltip>
      ) : (
        "Others"
      )}

      {showCustomDatePopover && (
        <div className={styles.customFilterItemContainer}>
          {customDateSelections.map((date) => {
            return (
              <div
                className={classNames(styles.customFilterItem, {
                  [styles.active]: selectedCustomDate?.key === date.key,
                })}
                key={`date-${date.key}`}
                onClick={() => handleSelectCustomDate(date)}
              >
                <p className={styles.title}>{date.label}</p>
                <span className={styles.selectedDate}>{formatDate(date)}</span>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default CustomFilter;
