import * as React from "react";

import { FilterById } from "utility/index";

import { analytics, FilterComponents } from "Modules/Filters";

export const CategoryFilter = ({ listData, selectedCategory, dispatchAction, formItem }: ICategoryFilter) => {
  return (
    <FilterComponents.SearchDropDownFilter
      title="Category"
      formItem={formItem ? formItem : "category_id"}
      listData={listData || []}
      appendClassName="category"
      dispatchAction={dispatchAction}
      eventName={analytics.categorySelected}
      placeholder="Select Category"
      displayKey="category_name"
    >
      {selectedCategory ? FilterById(selectedCategory, listData)[0]?.category_name : null}
    </FilterComponents.SearchDropDownFilter>
  );
};

interface ICategoryFilter {
  listData: any[];
  formItem: string;
  dispatchAction: () => void;
  selectedCategory?: number;
}
