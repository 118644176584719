import React from "react";
import { Button, Typography } from "@spenmo/splice";

import { errorIcon } from "assets/img";
import styles from "./index.module.scss";

const ErrorState = () => {
  return (
    <div className={styles.container}>
      <img src={errorIcon} alt="error icon" />
      <Typography size="m" variant="headline-content" tag="h3">
        Unable to Load Data
      </Typography>
      <Button variant="primary" onClick={() => window.location.reload()}>
        Try again
      </Button>
    </div>
  );
};

export default ErrorState;
