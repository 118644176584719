import APIclient from "API/Client";
import { GetOrgId } from "utility";
import { urlParamsBuilder } from "utility/APIWrapper";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";

const FETCH_CARDS_LIST = "FETCH_CARDS_LIST";

export const FETCH_CARDS_LIST_REQUEST = `${FETCH_CARDS_LIST}_REQUEST`;
export const FETCH_CARDS_LIST_SUCCESS = `${FETCH_CARDS_LIST}_SUCCESS`;
export const FETCH_CARDS_LIST_FAILURE = `${FETCH_CARDS_LIST}_FAILURE`;
export const RESET_CARDS_LIST_DATA = "RESET_CARDS_LIST_DATA";

const fetchCardsListRequest = () => ({
  type: FETCH_CARDS_LIST_REQUEST,
});

const fetchCardsListError = (data) => ({
  type: FETCH_CARDS_LIST_FAILURE,
  data: data,
});

const fetchCardsListSuccess = (data) => ({
  type: FETCH_CARDS_LIST_SUCCESS,
  data: data,
});

export const resetCardsListData = () => ({
  type: RESET_CARDS_LIST_DATA,
});

const getRequestUrl = (fetchOnlyUserCards, filterOptions, teamId = "") => {
  const orgId = GetOrgId();
  if (fetchOnlyUserCards) {
    const { ordering = null, page = 0, limit = 10 } = filterOptions;
    let params = {
      organisation_id: orgId,
      pg: page,
      limit,
      my_card: true,
    };
    if (ordering) {
      params = { ...params, ...ordering };
    }
    return urlParamsBuilder(`/api/v1/org/card`, params);
  } else {
    return `/api/v1/org/card/?organisation_id=${orgId}&team_id=${teamId}`;
  }
};

export const fetchUserCardsList = (fetchOnlyUserCards = false, filterOptions = {}) => {
  return (dispatch, getState) => {
    dispatch(fetchCardsListRequest());

    const requestUrl = getRequestUrl(fetchOnlyUserCards, filterOptions, getState?.()?.teamReducer?.selectedTeamId);
    APIclient.getData(requestUrl)
      .then((data) => {
        if (data?.data?.status > HTTP_STATUS_CODE.OK) {
          dispatch(fetchCardsListError(data?.data));
        } else {
          dispatch(fetchCardsListSuccess(data?.data));
        }
      })
      .catch((err) => {
        dispatch(fetchCardsListError(err));
      });
  };
};

export const fetchUserCardsListForTeamRemoval = (filterOptions = {}) => {
  const orgId = GetOrgId();
  const { cardholder_name, team_id } = filterOptions;
  let params = {
    organisation_id: orgId,
    pg: 0,
    limit: 10,
    search_filters: JSON.stringify({ cardholder_name }),
    team_id,
  };
  return (dispatch) => {
    dispatch(fetchCardsListRequest());
    const requestUrl = urlParamsBuilder(`/api/v1/org/card`, params);
    APIclient.getData(requestUrl)
      .then((data) => {
        if (data?.data?.status > HTTP_STATUS_CODE.OK) {
          dispatch(fetchCardsListError(data?.data));
        } else {
          dispatch(fetchCardsListSuccess(data?.data));
        }
      })
      .catch((err) => {
        dispatch(fetchCardsListError(err));
      });
  };
};
