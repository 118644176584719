import React, { useEffect, useState } from "react";
import { useLocation, useRouteMatch } from "react-router-dom";

import { CASBIN_PAGES } from "Permission/Pages";
import usePermissionCheck from "Permission/usePermissionCheck";
import { REIMBURSEMENT_TRANSACTION_PAGE } from "Permission/Actions";

import { ALIGN_ACTION_ITEM, ActionsBar } from "Modules/DS/DataTable";

import {
  PAGE_TABS,
  REIMBURSEMENT_PAGE_ACTIONS,
  REIMBURSEMENT_PAGE_OPERATIONS,
  REIMBURSEMENT_PAYOUTS_OPTIONS,
  REIMBURSEMENT_SETTLEMENT_STATUSES,
} from "Views/Reimbursement/Constants";

import Status from "Views/Reimbursement/List/Actions/Status";
import Budget from "Views/Reimbursement/List/Actions/Budget";
import Merchant from "Views/Reimbursement/List/Actions/Merchant";
import Requester from "Views/Reimbursement/List/Actions/Requester";
import ExpenseDate from "Views/Reimbursement/List/Actions/ExpenseDate";
import PaidDate from "Views/Reimbursement/List/Actions/PaidDate";

import { ILocationParams } from "Views/Reimbursement/@types";
import { ActionsSkeleton } from "Views/Reimbursement/List/Skeleton";
import {
  Export,
  NewReimbursement,
} from "Views/Reimbursement/List/Actions/Buttons";
import {
  SettlementViewChips,
  SettlementView,
} from "Views/Reimbursement/List/Actions/Settlements";
import {
  PayoutsViewChips,
  PayoutsView,
} from "Views/Reimbursement/List/Actions/Payouts";

import styles from "./index.module.scss";

const Actions = () => {
  const location = useLocation();

  const actions =
    PAGE_TABS.find((tab) => location.pathname.includes(tab.tabKey))?.actions ||
    [];

  const [loading, setLoading] = useState(false);

  const { params } = useRouteMatch<ILocationParams>();
  const { operation = "", id = "" } = params;

  const isPayoutsView = operation === REIMBURSEMENT_PAGE_OPERATIONS.PAYOUTS;
  const isSettlementsView =
    operation === REIMBURSEMENT_PAGE_OPERATIONS.SETTLEMENTS;
  const isUnpaidPayouts =
    id === REIMBURSEMENT_SETTLEMENT_STATUSES.UNPAID_FOR_PAYOUTS_VIEW.id;
  const isPaidPayouts =
    id === REIMBURSEMENT_SETTLEMENT_STATUSES.SETTLED_FOR_SETTLEMENT_VIEW.id;
  const isApproveSettlements =
    id === REIMBURSEMENT_SETTLEMENT_STATUSES.APPROVED.id;

  const permissionParam = [
    {
      object: CASBIN_PAGES.REIMBURSEMENT_TRANSACTION_PAGE,
      action: REIMBURSEMENT_TRANSACTION_PAGE.VIEW_SETTLEMENT,
    },
    {
      object: CASBIN_PAGES.REIMBURSEMENT_TRANSACTION_PAGE,
      action: REIMBURSEMENT_TRANSACTION_PAGE.CREATE,
    },
  ];

  const [reimbursementPermissions, loadingPermissions] = usePermissionCheck(
    permissionParam,
    true,
  ) as [boolean, boolean];
  const isAllowedSettlementView = reimbursementPermissions[0];
  const isAllowedCreateReimbursement = reimbursementPermissions[1];

  useEffect(() => {
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, [
    isPayoutsView,
    isSettlementsView,
    isApproveSettlements,
    isPaidPayouts,
    isUnpaidPayouts,
  ]);

  const getActions = () => {
    return [
      {
        id: "REIMBURSEMENTS-ACTION-1",
        enabled:
          actions.includes(REIMBURSEMENT_PAGE_ACTIONS.PAYOUTS_ACTION_CHIPS) &&
          isAllowedSettlementView &&
          isPayoutsView,
        align: ALIGN_ACTION_ITEM.LEFT,
        component: PayoutsViewChips,
      },
      {
        id: "REIMBURSEMENTS-ACTION-2",
        enabled:
          actions.includes(REIMBURSEMENT_PAGE_ACTIONS.EXPENSE_DATE) &&
          !isPayoutsView,
        align: ALIGN_ACTION_ITEM.LEFT,
        component: ExpenseDate,
      },
      {
        id: "REIMBURSEMENTS-ACTION-3",
        enabled:
          actions.includes(REIMBURSEMENT_PAGE_ACTIONS.BUDGET) && !isPayoutsView,
        align: ALIGN_ACTION_ITEM.LEFT,
        component: Budget,
      },
      {
        id: "REIMBURSEMENTS-ACTION-4",
        enabled: actions.includes(REIMBURSEMENT_PAGE_ACTIONS.REQUESTER),
        align: ALIGN_ACTION_ITEM.LEFT,
        component: Requester,
      },
      {
        id: "REIMBURSEMENTS-ACTION-5",
        enabled:
          actions.includes(REIMBURSEMENT_PAGE_ACTIONS.MERCHANT) &&
          !isPayoutsView,
        align: ALIGN_ACTION_ITEM.LEFT,
        component: Merchant,
      },
      {
        id: "REIMBURSEMENTS-ACTION-6",
        enabled:
          actions.includes(REIMBURSEMENT_PAGE_ACTIONS.STATUS) &&
          !isSettlementsView,
        align: ALIGN_ACTION_ITEM.LEFT,
        component: Status,
      },
      {
        id: "REIMBURSEMENTS-ACTION-7",
        enabled:
          actions.includes(REIMBURSEMENT_PAGE_ACTIONS.PAID_DATE) &&
          isPayoutsView &&
          id === REIMBURSEMENT_PAYOUTS_OPTIONS[1].id,
        align: ALIGN_ACTION_ITEM.LEFT,
        component: PaidDate,
      },
      {
        id: "REIMBURSEMENTS-ACTION-8",
        enabled:
          actions.includes(
            REIMBURSEMENT_PAGE_ACTIONS.SETTLEMENT_ACTION_CHIPS,
          ) &&
          isAllowedSettlementView &&
          isSettlementsView,
        align: ALIGN_ACTION_ITEM.LEFT,
        component: SettlementViewChips,
      },
      {
        id: "REIMBURSEMENTS-ACTION-9",
        enabled:
          actions.includes(REIMBURSEMENT_PAGE_ACTIONS.SETTLEMENT_VIEW) &&
          isAllowedSettlementView,
        align: ALIGN_ACTION_ITEM.RIGHT,
        component: SettlementView,
      },
      {
        id: "REIMBURSEMENTS-ACTION-10",
        enabled:
          actions.includes(REIMBURSEMENT_PAGE_ACTIONS.PAYOUTS_VIEW) &&
          isAllowedSettlementView,
        align: ALIGN_ACTION_ITEM.RIGHT,
        component: PayoutsView,
      },
      {
        id: "REIMBURSEMENTS-ACTION-11",
        enabled:
          actions.includes(REIMBURSEMENT_PAGE_ACTIONS.RIGHT_BORDER) &&
          (actions.includes(REIMBURSEMENT_PAGE_ACTIONS.SETTLEMENT_VIEW) ||
            actions.includes(REIMBURSEMENT_PAGE_ACTIONS.PAYOUTS_VIEW)) &&
          isAllowedSettlementView &&
          actions.includes(REIMBURSEMENT_PAGE_ACTIONS.EXPORT),
        align: ALIGN_ACTION_ITEM.RIGHT,
        component: <div className={styles.border} />,
      },
      {
        id: "REIMBURSEMENTS-ACTION-12",
        enabled: actions.includes(REIMBURSEMENT_PAGE_ACTIONS.EXPORT),
        align: ALIGN_ACTION_ITEM.RIGHT,
        component: Export,
      },
      {
        id: "REIMBURSEMENTS-ACTION-13",
        enabled:
          actions.includes(REIMBURSEMENT_PAGE_ACTIONS.NEW_REIMBURSEMENT) &&
          isAllowedCreateReimbursement,
        align: ALIGN_ACTION_ITEM.RIGHT,
        component: NewReimbursement,
      },
    ];
  };

  return loadingPermissions || loading ? (
    <ActionsSkeleton />
  ) : (
    <ActionsBar>{getActions()}</ActionsBar>
  );
};

export default Actions;
