import { trackEvent } from "utility/analytics";

export const createNewTeamEventSearchParamsValues = {
  key: "createNewTeam",
  value: "true",
};

const isCreateNewTeam = () => {
  if (window?.location?.search) {
    const searchParams = new URLSearchParams(window.location.search);
    return (
      searchParams?.get?.(createNewTeamEventSearchParamsValues?.key) === createNewTeamEventSearchParamsValues?.value
    );
  }
  return false;
};

export const trackActionsOnTeamsPage = (eventName) => {
  trackEvent(`${isCreateNewTeam() ? "create" : "edit"} team ${eventName}`);
};

export const TeamProfileEventsMapping = {
  addNewMemberClicked: "add member clicked",
  membersSelected: "members selected",
  userPromoted: "user promoted",
  userDemoted: "user demoted",
  clawbackClicked: "clawback clicked",
  backToTeamsClicked: "back to teams clicked",
  dailyLimit: "daily transaction limit addded",
  monthlyLimit: "monthly transaction limit addded",
  perTransactionLimit: "per transaction limit added",
  applyPolicies: "apply policies clicked",
};
