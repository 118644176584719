import { useContext, useEffect, useRef } from "react";

import { TrxnFilterContext, TrxnPaginationContext } from "Views/Transaction/Context";

import {
  ITrxnFilterContext,
  ITrxnPaginationContext,
  GET_TRXN_REQUEST_PARAMS,
  GET_BILLS_TRXN_REQUEST_PARAMS,
  IGetTrxnResponse,
  IGetBillTrxnResponse,
} from "Views/Transaction/@types";
import { INITIAL_TRXNS_PAGINATION } from "Views/Transaction/Constants";
import { BILL_TRXNS_TABLE_ROWS } from "Views/Transaction/Constants/Bills";

export const usePageChange = (tab: string, data: IGetTrxnResponse[] | IGetBillTrxnResponse[]) => {
  const { onApplyFilter }: ITrxnFilterContext = useContext(TrxnFilterContext);
  const { currentPage }: ITrxnPaginationContext = useContext(TrxnPaginationContext);

  const pageRef = useRef(currentPage);

  useEffect(() => {
    if (currentPage === INITIAL_TRXNS_PAGINATION) {
      pageRef.current = currentPage;
    }
  }, [currentPage]);

  const applyPagination = (index: number) => {
    if (tab === "bills" && data.length > 0) {
      onApplyFilter({
        [GET_BILLS_TRXN_REQUEST_PARAMS.PAGE]: index === 0 ? currentPage - 1 : currentPage + 1,
        [GET_BILLS_TRXN_REQUEST_PARAMS.ROWS]: BILL_TRXNS_TABLE_ROWS,
      });
    } else if (tab === "all" && data.length > 0 && data[index].sort.length > 0) {
      const value = data[index].sort;
      onApplyFilter({
        [GET_TRXN_REQUEST_PARAMS.SEARCH_AFTER]: [value[0].toString(), value[1]],
        [GET_TRXN_REQUEST_PARAMS.PREVIOUS_PAGE]: index === 0,
      });
    }
  };

  return (pg: number) => {
    if (pageRef.current > pg) {
      applyPagination(0);
    } else if (pageRef.current < pg) {
      applyPagination(data.length - 1);
    }

    pageRef.current = pg;
  };
};
