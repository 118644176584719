import React, { useContext } from "react";

import { DateFilter as DSDate } from "Modules/DS/Filter";
import { IDateFormProps } from "Modules/DS/Filter/@types";

import {
  IGetTrxnRequest,
  ITrxnFilterContext,
  ITrxnPaginationContext,
  GET_TRXN_REQUEST_PARAMS,
} from "Views/Transaction/@types";
import { TrxnFilterContext, TrxnPaginationContext } from "Views/Transaction/Context";
import { ALL_TRXN_PAGINATION_PARAMS, TRXN_ELEMENT_CLASS } from "Views/Transaction/Constants";
import { clearURLParams } from "utility";

export const DateFilter = ({
  id,
  asISO = true,
  defaultValue,
}: {
  id: string;
  asISO?: boolean;
  defaultValue?: string;
}) => {
  const { resetCurrentPage } = useContext<ITrxnPaginationContext>(TrxnPaginationContext);

  const { resetTrxnPaginationParams, filter, onApplyFilter, resetFilter } =
    useContext<ITrxnFilterContext>(TrxnFilterContext);

  const onApply = (value: IDateFormProps) => {
    onApplyFilter({
      ...value,
      ...resetTrxnPaginationParams,
    });
    resetCurrentPage();
  };

  const onClear = (keys: string[]) => {
    clearURLParams(["startDate", "endDate"]);
    resetFilter([...keys, ...ALL_TRXN_PAGINATION_PARAMS]);
    resetCurrentPage();
  };

  return (
    <DSDate<IGetTrxnRequest>
      id={id + TRXN_ELEMENT_CLASS.DATE_FILTER}
      defaultValue={defaultValue}
      asISO={asISO}
      filter={filter}
      onApply={onApply}
      onClear={onClear}
      startKey={GET_TRXN_REQUEST_PARAMS.START_DATE}
      endKey={GET_TRXN_REQUEST_PARAMS.END_DATE}
    />
  );
};
