import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import TableRowField from "Modules/tableRowField";
import ActionButtons from "Modules/ActionButtons";
import { currencyFormatter, getUtcOffset, quantifier } from "utility";
import "./ScheduledDetails.scss";

const ScheduledDetails = (props) => {
  const {
    data: {
      receiverName,
      amount,
      currency,
      senderType,
      senderName,
      receiverTeamName,
      schedule: { type, interval, intervalType, nextDate, endsAfter, isRetriable },
    },
    editAction,
    stopAction,
  } = props;

  const endsOn = type === "UNLIMITED" ? "Never" : `After ${endsAfter} occurrence${quantifier(endsAfter)}`;
  return (
    <div className={"scheduled-details"}>
      <h2 className="scheduled-details__receiver">{receiverName}</h2>
      <h1 className="scheduled-details__amount">{currencyFormatter(amount, currency)}</h1>
      <div className="scheduled-details__date">
        {moment(nextDate?.replace?.("Z00:00", "Z")).format("DD MMM YYYY - HH:mm")} GMT{getUtcOffset()}
      </div>

      <h3>Payment Recurrence Details</h3>
      <TableRowField
        id="repeatEvery"
        title="Repeat Every"
        details={
          intervalType === "NONE"
            ? "No repeat"
            : `${interval} ${intervalType?.toLowerCase?.()}${interval > 1 ? "s" : ""}`
        }
      />
      {intervalType === "NONE" || <TableRowField id="endsOn" title="Ends on" details={endsOn} />}
      <TableRowField
        id="retry"
        title="Retry transaction on the following day if failed"
        details={isRetriable ? "On" : "Off"}
      />

      <h3>OTHER DETAILS</h3>
      <TableRowField
        id="transactionFrom"
        title="Paid from"
        details={`${senderType === "TEAM" ? "Budget" : "Personal Wallet"} - ${senderName}`}
      />
      <div className="scheduled-details__actions">
        <ActionButtons
          buttons={[
            {
              label: `Edit`,
              action: editAction,
              type: "green",
              useConfirm: false,
            },
            {
              label: `Stop`,
              action: stopAction,
              type: "red",
              useConfirm: true,
            },
          ]}
        />
      </div>
    </div>
  );
};

ScheduledDetails.propTypes = {
  data: PropTypes.object,
  editAction: PropTypes.func,
  stopAction: PropTypes.func,
};

export default ScheduledDetails;
