import { IRequirement, ISettlement } from "Views/Employee/types";

import { LineIcon } from "assets/v1.1/icons";

const SETTLEMENTS: IRequirement<ISettlement>[] = [
  {
    id: "SI-1",
    enabled: true,
    requirement: {
      icon: LineIcon.orangeTeam,
      altText: "team icon",
      description: "Remove this employee from all teams and permanently delete their Spenmo account",
    },
  },
  {
    id: "SI-2",
    enabled: true,
    requirement: {
      icon: LineIcon.orangeWallet,
      altText: "wallet icon",
      description: "Return this employee’s remaining balance to their team funds, if any",
    },
  },
  {
    id: "SI-3",
    enabled: true,
    requirement: {
      icon: LineIcon.orangeCard,
      altText: "card icon",
      description: "Permanently delete all cards and card requests that belong to this employee",
    },
  },
].filter((item) => item.enabled);

export default SETTLEMENTS;
