import React from "react";
import { TRXN_STATUS } from "Views/Transaction/Constants";
import styles from "Views/Transaction/Bills/index.module.scss";
import { InfoOutline } from "@spenmo/splice";

export const getAdditionalInfoText = (isLimitExceeded: boolean) => {
  return (
    <>
      {isLimitExceeded ? (
        <>
          One or more items will be processed for payment once its selected payment source has sufficient
          <strong> Remaining Spending Limit</strong> for deduction.
        </>
      ) : (
        <>
          One or more items will be processed for payment once there is sufficient <strong>Company Balance</strong>.
        </>
      )}{" "}
      Payment items are processed based on the earliest due date if available or the earliest payment creation date.
    </>
  );
};

export const getAdditionalInfoAboutButton = (trxnStatusKey: string, isUserBillCreatorOrAdmin: boolean) => {
  let editRestrictedMsg = "";
  switch (trxnStatusKey) {
    case TRXN_STATUS.approved:
    case TRXN_STATUS.completed:
    case TRXN_STATUS.exceeded:
      editRestrictedMsg = "Bill has entered approval or payment process and cannot be edited";
      break;
    case TRXN_STATUS.insufficient:
      editRestrictedMsg = "Please top up your balance to proceed with the payment process";
      break;
    case TRXN_STATUS.cancelled:
      editRestrictedMsg = "Cancelled bill cannot be edited";
      break;
    case TRXN_STATUS.extracted:
      editRestrictedMsg = "Bill is currently being extracted and cannot be edited";
      break;
    case TRXN_STATUS.received:
      break;
    default:
      editRestrictedMsg = !isUserBillCreatorOrAdmin && "Please contact creator or admin to edit this bill";
      break;
  }
  return (
    editRestrictedMsg && (
      <div className={styles.sidePanelStickyInfo}>
        <InfoOutline className={styles.infoIcon} iconColor="var(--icon-default)" size="16" />
        <span>{editRestrictedMsg}</span>
      </div>
    )
  );
};

export const isTrxnStatusToViewEditBillModal = (trxnStatus: string) =>
  trxnStatus === TRXN_STATUS.waitingApproval || trxnStatus === TRXN_STATUS.rejected;
