import { createContext } from "react";

import { ITrxnFilterContext, ITrxnMoreFilterContext } from "Views/Transaction/@types";

export const initialTrxnFilter: ITrxnFilterContext = {
  filter: null,
  setFilter: () => null,
  clearFilter: () => null,
  resetAllFilters: () => null,
  resetFilter: () => null,
  onApplyFilter: () => null,
  totalCount: 0,
};

export const initialTrxnMoreFilter: ITrxnMoreFilterContext = {
  more: null,
  applyMoreFilter: () => null,
  clearMoreFilter: () => null,
  clear: false,
  setClear: () => null,
  keys: [],
};

export const TrxnFilterContext = createContext<ITrxnFilterContext>(initialTrxnFilter);

export const TrxnMoreFilterContext = createContext<ITrxnMoreFilterContext>(initialTrxnMoreFilter);
