/*
  enums
*/
export enum GET_KYC_STATUS_ACTION_TYPES {
  FETCH_KYC_STATUS_REQUEST = "EMPLOYEE/FETCH_KYC_STATUS_REQUEST",
  FETCH_KYC_STATUS_SUCCESS = "EMPLOYEE/FETCH_KYC_STATUS_SUCCESS",
  FETCH_KYC_STATUS_ERROR = "EMPLOYEE/FETCH_KYC_STATUS_ERROR",
}

/*
  interfaces
*/
export interface IKycStatus {
  name: string;
  code: number;
  description: string;
}

export interface IKycStatusReducer {
  loading: boolean;
  data: IKycStatus[];
  error: boolean;
}

/*
  initialize
*/
export const initialKycItem: IKycStatus = { name: "", code: 0, description: "" };

export const initialKycState: IKycStatusReducer = {
  loading: false,
  data: [],
  error: false,
};
