import React from "react";
import { useSelector } from "react-redux";

import { Header, Status } from "Views/Transaction/Details";
import { ITopupTrxnDetails } from "Views/Transaction/@types";
import { getRecordDetailsDate } from "Views/Transaction/Helper";

import { Details } from "./Details";

export const TopupTrxnDetails = ({
  amount,
  currencyCode,
  requesterName,
  teamName,
  trxnNumber,
  trxnType,
  createdDate,
  status,
}: ITopupTrxnDetails) => {
  const orgName = useSelector((state: any) => state.userInfoReducer?.data?.payload?.user?.organisation_name);

  const date = getRecordDetailsDate(createdDate);

  return (
    <>
      <Header title={orgName} amount={amount} currencyCode={currencyCode} />
      <Status date={date} status={status} />
      <Details date={date} requesterName={requesterName} team={teamName} trxnNumber={trxnNumber} trxnType={trxnType} />
    </>
  );
};
