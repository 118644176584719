import React, { MouseEvent } from "react";

import Icon from "Modules/Icon";
import { Col, Row } from "Splice/Grid";
import styles from "./index.module.scss";
import { useGetSummaryConfigs } from "Views/KYX/hooks/useGetSummaryConfigs";

const RemarksBanner: React.FC = (): JSX.Element => {
  const { getRemarksSummary } = useGetSummaryConfigs();

  const onSeeRemarks = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    getRemarksSummary();
  };

  return (
    <div className={styles.container}>
      <Row align="end" justify="space-between" className={styles.row}>
        <Col className={styles.content} span={24}>
          <Icon icon="fillWarning" size={24} />

          <span>
            An update of your previously submitted data is required.
            <button type="button" className={styles.button} onClick={onSeeRemarks}>
              See remarks
            </button>
          </span>
        </Col>
      </Row>
    </div>
  );
};

export default RemarksBanner;
