import { ITabConfigReturnValue } from "../@types";
import { useSettingContext } from "../context/SettingContext";

export const useTabConfig = (): ITabConfigReturnValue => {
  const { tabsConfig, setTabsConfig } = useSettingContext();

  const toggledEnableReview = (id: number) => {
    const newTabsConfig = tabsConfig.map((tab) => ({ ...tab, review: tab.id === id ? !tab.review : tab.review }));
    setTabsConfig(newTabsConfig);
  };

  const toggleRequiredField = (tabId: number, fieldId: string) => {
    const newSelectedTab = tabsConfig.map((tab) => {
      const newRequiredFields = tab.requiredFields.map((field) => ({
        ...field,
        checked: field.id === fieldId ? !field.checked : field.checked,
      }));
      return {
        ...tab,
        requiredFields: tab.id === tabId ? newRequiredFields : tab.requiredFields,
      };
    });
    setTabsConfig(newSelectedTab);
  };

  return [tabsConfig, toggledEnableReview, toggleRequiredField];
};
