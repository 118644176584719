import * as React from "react";
import classNames from "classnames";
import { ISpendingLock } from "Views/Card/types";
import styles from "./index.module.scss";

export interface IStackedImageProps {
  merchantLocks: ISpendingLock[];
  maxImages: number;
  imageClassName: string;
}

const StackedImages = ({ merchantLocks, maxImages, imageClassName }: IStackedImageProps) => (
  <article className={styles["stacked-images"]} data-testid="stacked-images">
    {merchantLocks?.slice(0, maxImages)?.map((merchantData) => (
      <img
        src={merchantData?.logo}
        key={merchantData?.name}
        alt={merchantData?.altText}
        className={classNames([styles["stacked-images__image"], imageClassName])}
      />
    ))}
    {merchantLocks?.length - maxImages > 0 ? (
      <p className={classNames([styles["stacked-images__extra-count"], imageClassName])}>
        +{merchantLocks?.length - maxImages}
      </p>
    ) : null}
  </article>
);

export default StackedImages;
