import React from "react";
import styles from "./index.module.scss";
import { currencyFormatterV2 } from "utility";
import { InfoOutline, Tooltip, Typography } from "@spenmo/splice";

interface IAmountProps {
  currency: string;
  value: number;
  saasOnly?: boolean;
}

const Amount = ({ currency, value, saasOnly = false }: IAmountProps) => {
  return (
    <div className={styles.container}>
      <Typography className={styles.currency} size="caption-m" variant="body-content">
        {currency}
      </Typography>
      <Typography className={styles.value} size="s" variant="body-content" weight={600}>
        {currencyFormatterV2(value, currency, false, 2)}
      </Typography>
      {saasOnly && (
        <Tooltip
          title="This is the total amount ready to be paid. If you had foreign currency bills, we will use the mid-market rate to calculate this amount."
          placement="bottom"
        >
          <InfoOutline className={styles.infoIcon} iconColor="var(--icon-default)" size="16" />
        </Tooltip>
      )}
    </div>
  );
};

export default Amount;
