import ApprovalRequest from "Models/Approval/ApprovalCenter/approvalRequest.interface";
import BillApprovalRequest from "Models/Approval/ApprovalCenter/billApprovalRequest.interface";
import { ApiCallState } from "Models/Network/models";
import { getURLParams } from "Views/Approval/ApprovalCenter/helper";
import { BillFilter } from "Views/Approval/ApprovalCenter/types";

export interface ApprovalRequestTabCount {
  tabKey: string;
  title: string;
  pendingRequests: number;
}

export interface ApprovalConfig {
  reimbursement: {
    payImmediatelyEnabled: boolean;
  };
}

export interface ApprovalRequestCenter {
  activeTab: string;
  activeSubTab: string;
  allRequests: BillApprovalRequest[];
  apiCallState: ApiCallState;
  approvalRequestDetail: BillApprovalRequest;
  fetchApprovalRequestDetail: ApiCallState;
  fetchCountApiCallState: ApiCallState;
  approveApiCallState: ApiCallState;
  selectedRequests: ApprovalRequest[];
  totalCountByRequestType: ApprovalRequestTabCount[];
  approvalRequestFilter: BillFilter;
  previewFilesList: string[];
  totalPendingRequest: number;
  isWorkflowChange: boolean;
  editedCR: string;
  approvalConfig: ApprovalConfig;
}

export const newApprovalRequestFilter = (assignSource?: Partial<BillFilter>): BillFilter => {
  return Object.assign(
    {
      page: 0,
      limit: 10,
      sortBy: "requestDate",
      sortDirection: "desc",
      ...getURLParams(),
    },
    assignSource
  );
};
