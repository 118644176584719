import React from "react";

import { Card, AWS, Figma, Google, Hubspot, Microsoft, Oracle, Zoom } from "Views/SubscriptionManagement/assets";

import styles from "./styles.module.scss";
import { Typography } from "@spenmo/splice";

export const Empty = () => {
  return (
    <div className={styles.emptyContainer}>
      <div className={styles.content}>
        <div className={styles.logoContainer}>
          <div className={styles.logo}>
            <AWS />
          </div>
          <div className={styles.logo}>
            <Google />
          </div>
          <div className={styles.logo}>
            <Figma />
          </div>
          <div className={styles.logo}>
            <Oracle />
          </div>
          <div className={styles.logo}>
            <Microsoft />
          </div>
          <div className={styles.logo}>
            <Hubspot />
          </div>
          <div className={styles.logo}>
            <Zoom />
          </div>
        </div>
        <Card />
        <Typography size="m" variant="headline-content" className={styles.header}>
          Get visibility, control and savings on your subscriptions through Spenmo
        </Typography>
        <Typography size="m" variant="body-content" className={styles.body}>
          Manage all subscriptions in one place; set spending limits, receive renewal reminders, and save on your
          subscription fees
        </Typography>
      </div>
    </div>
  );
};
