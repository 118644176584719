import React, { useMemo, useState } from "react";
import qs from "query-string";
import { useHistory, useLocation } from "react-router-dom";

import { useMatchMutate, useMutableData } from "API/useData";

import usePermissionCheck from "Permission/usePermissionCheck";

import { SidePanel } from "Modules/DS/SidePanel";
import BillsTrxnSidePanel from "Views/Transaction/Bills/BillsTrxnSidePanel";
import EditBillModal from "Views/UploadInvoice/EditBillModal";

import { GetBaseAuthObject } from "utility";

import {
  getAdditionalInfoAboutButton,
  isTrxnStatusToViewEditBillModal,
} from "Views/Transaction/Bills/Helper";
import { TRANSACTION_DETAIL_V2_PERMISSION_PARAMS } from "Views/Transaction/Permission";
import { getBillsTrxnSidePanelButtons } from "Views/Transaction/Bills/BillsTrxnSidePanel/SidePanelButtons";
import { API_URL, BillFormType } from "Views/Bills/V2/constants";
import {
  BUTTON_ACTION,
  ENABLED_PAY_IMMEDIATELY_STATUS,
} from "Views/Transaction/Constants";

import { EditBillModalProps } from "Views/UploadInvoice/EditBillModal/types.d";
import { BillSidepanelProps } from "./types.d";
import { SaaSConfig } from "Views/Bills/V2/BillForm/type";
import APIClient from "API/Client";
import { TRANSACTION_PATHS } from "Route/Transaction/paths";
import { useToaster } from "Views/Bills/V2/hooks";
import { Payment_Run_API_URL } from "../../PaymentRun/constant";
import { useSelector } from "react-redux";
import { getCookieValue, cookieNames } from "utility/CookieHelper";
import { USER_ROLE } from "constants/Team.constant";

const DEFAULT_EDIT_BILL_PROPS = {
  visible: false,
  billID: null,
  onClose: () => undefined,
};

const BillSidepanel: React.FC<BillSidepanelProps> = (props) => {
  const { children, id, visible, loading, onClose } = props;
  const { appNotification } = useToaster();
  const location = useLocation();
  const history = useHistory();

  const [editBillProps, setEditBillProps] = useState<EditBillModalProps>(
    DEFAULT_EDIT_BILL_PROPS,
  );
  const [formValue, setFormValue] = useState(null);
  const [formValueChanged, setFormValueChanged] = useState(false);
  const [showEditableFields, setShowEditableFields] = useState(false);
  const [discardChangesBtnClicked, setDiscardChangesBtnClicked] =
    useState(false);

  const { data: userInfo, isValidating: userInfoLoading } = useMutableData(
    `${API_URL.userInfo}/${GetBaseAuthObject().userId}?organisation_id=${GetBaseAuthObject().orgId}`,
  );

  const userInfoPayload = useMemo(() => userInfo?.data?.payload, [userInfo]);

  const billDetailURL = qs.stringifyUrl({
    url: `${API_URL.disbursementV1}/bill/${id}`,
  });

  const { data: billDetailData, isValidating: billDetailLoading } =
    useMutableData(id && visible ? billDetailURL : null);
  const billDetailPayload = useMemo(
    () =>
      billDetailData
        ? {
            ...billDetailData?.data?.payload,
            // Note: Need to refactor/revamp BillsTrxnSidePanel to make it cleaner
            id, // manually add id for BillsTrxnSidePanel component
          }
        : undefined,
    [billDetailData, id],
  );

  const { data: paymentRunData, isValidating: paymentRunLoading } =
    useMutableData(API_URL.getPaymentRunSetting);
  const paymentRunPayload = useMemo(
    () => paymentRunData?.data?.payload,
    [paymentRunData],
  );

  const [permissions, loadingPermission] = usePermissionCheck(
    TRANSACTION_DETAIL_V2_PERMISSION_PARAMS,
    true,
  ) as [boolean[], boolean];

  const [IS_EDIT_BILL_ALLOWED, IS_ABLE_TO_INITIATE_PAYMENT] = permissions || [];

  const isUserBillCreatorOrAdmin =
    userInfoPayload?.user?.id === billDetailPayload?.requestorID ||
    userInfoPayload?.user?.is_admin;
  const isLoading =
    userInfoLoading ||
    loadingPermission ||
    billDetailLoading ||
    paymentRunLoading ||
    loading;
  const { data: saasConfigResponse } = useMutableData(
    `${API_URL.saasConfig}?view=payment-run`,
  );
  const saasConfig: SaaSConfig = useMemo(
    () => saasConfigResponse?.data?.payload,
    [saasConfigResponse?.data?.payload],
  );
  const matchMutate = useMatchMutate();

  const markAsPaid = async (selectedBillIds: string[]) => {
    try {
      const response = await APIClient.putData(API_URL.markAsPaid, {
        billIDs: selectedBillIds,
      });
      const {
        data: { payload },
      } = response;
      if (payload.isSuccess) {
        appNotification.success({
          message: `Bill has been marked as Paid`,
        });
        onClose();
        matchMutate(new RegExp(Payment_Run_API_URL.paymentCycles));
      }
    } catch (err) {
      return;
    }
  };

  const sidePanelActionBtnClickHandler = (action: BUTTON_ACTION) => {
    switch (action) {
      case BUTTON_ACTION.PAY_IMMEDIATELY: {
        // TO DO: add validation to check eligibility
        history.push(
          `/bills/payment-run/pay/${btoa(JSON.stringify([billDetailPayload?.id]))}`,
        );
        break;
      }
      case BUTTON_ACTION.MARK_AS_PAID: {
        markAsPaid([billDetailPayload.id]);
        break;
      }
      default: {
        if (
          action === BUTTON_ACTION.EDIT &&
          isTrxnStatusToViewEditBillModal(billDetailPayload?.status)
        ) {
          history.push(
            `${location.pathname}/${BillFormType.EDIT}/${billDetailPayload.id}`,
          );
        } else {
          setShowEditableFields(action === BUTTON_ACTION.EDIT);
          setDiscardChangesBtnClicked(action === BUTTON_ACTION.DISCARD);
          if (action === BUTTON_ACTION.SAVE) {
            // deleting the read-only Receipt/Proof of Payment field from form data
            delete formValue?.paymentReceipt;
            // call save changes API here to update form data
          }
        }
      }
    }
  };

  const areBillTrxnDetailsEditable = useMemo(
    () =>
      IS_EDIT_BILL_ALLOWED &&
      isUserBillCreatorOrAdmin &&
      isTrxnStatusToViewEditBillModal(billDetailPayload?.statusInfo?.key),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [IS_EDIT_BILL_ALLOWED, billDetailPayload, isUserBillCreatorOrAdmin],
  );

  const userInfoData = useSelector(
    (state: any) => state?.userInfoReducer?.data?.payload?.user,
  );
  const isAdmin = userInfoData?.is_admin;
  const isAccountant =
    getCookieValue(cookieNames.USER_COMPANY_ROLE) === USER_ROLE.ACCOUNTANT;

  // check if the status is approved and the payment run is on and user role able to initiate payment
  const isApproved =
    ENABLED_PAY_IMMEDIATELY_STATUS.includes(
      billDetailPayload?.statusInfo?.key,
    ) &&
    Boolean(paymentRunPayload?.setting) &&
    IS_ABLE_TO_INITIATE_PAYMENT &&
    !location.pathname.includes("/bills/payment-run/pay");

  // hide it when it's approved and the payment run is on
  const additionalInfoAboutButton =
    !areBillTrxnDetailsEditable &&
    !isApproved &&
    getAdditionalInfoAboutButton(
      billDetailPayload?.statusInfo?.key || "",
      isUserBillCreatorOrAdmin,
    );

  const markAsPaidAllowed =
    !saasConfig?.isPaymentEnabled &&
    (isAdmin || isAccountant) &&
    ENABLED_PAY_IMMEDIATELY_STATUS.includes(billDetailPayload?.statusInfo?.key);

  const sidepanelBtns =
    (!additionalInfoAboutButton && areBillTrxnDetailsEditable) ||
    isApproved ||
    markAsPaidAllowed
      ? getBillsTrxnSidePanelButtons(
          showEditableFields,
          sidePanelActionBtnClickHandler,
          formValueChanged,
          billDetailPayload?.statusInfo?.key,
          saasConfig?.isPaymentEnabled,
        )
      : [];

  return (
    <>
      <SidePanel
        title="Bill Payment Details"
        visible={visible}
        onClose={onClose}
        loading={isLoading}
        sticky
        stickyContent={markAsPaidAllowed ? "" : additionalInfoAboutButton}
        buttons={sidepanelBtns}
      >
        {children
          ? children
          : id && (
              <BillsTrxnSidePanel
                id={id}
                invoiceData={billDetailPayload}
                billTrxnDetailsEditable={areBillTrxnDetailsEditable}
                showEditableFields={showEditableFields}
                setFormValue={setFormValue}
                setFormValueChanged={setFormValueChanged}
                resetFormData={discardChangesBtnClicked}
              />
            )}
      </SidePanel>
      <EditBillModal {...editBillProps} />
    </>
  );
};

export default BillSidepanel;
