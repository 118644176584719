import React from "react";
import { useLocation } from "react-router-dom";
import useSWRInfinite from "swr/infinite";

import { getData } from "API/Client";
import { useMutableData } from "API/useData";
import { GetOrgId } from "utility";

import { Google } from "../assets";
import { ISubscription } from "../@types";
import { dummySubscriptions } from "../mock";
import { API_CALL } from "../dataCall";

const cleanUpSubscription: (data: any) => ISubscription = (data) => {
  return {
    ...data,
    subscription_id: data.id,
    vendor: {
      ...data.vendor,
      icon: <Google />,
    },
    currency: data.currencyCode,
    estimated_amount: data.estimated_cost,
    review_expiry_date: data.expiry,
  };
};

const cleanUpData = (data, loading) => {
  const { subscriptions = [], pagination = {} } = data?.data?.payload || {};

  return {
    subscriptions: loading ? dummySubscriptions : subscriptions.map(cleanUpSubscription),
    pagination,
  };
};

export const useSubscriptionList = ({ limit = 10 }: { limit?: number } = {}) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const hasFilter = params.size !== 0;

  const { data, isLoading, size, setSize, isValidating, mutate } = useSWRInfinite(
    (index, previousPageData) => {
      if (previousPageData && previousPageData?.data?.payload?.subscriptions?.length !== limit) {
        return null;
      }
      params.set("page", String(index + 1));
      params.set("limit", String(limit));
      return API_CALL.SUBSCRIPTION_LIST_API(params.toString());
    },
    (url) => {
      return getData(url);
    },
    {
      revalidateFirstPage: true,
      revalidateAll: false,
      revalidateOnFocus: true,
      revalidateOnMount: true,
    }
  );

  const pagination = data?.at(-1)?.data?.payload?.pagination || {};
  const subscription = data?.at(-1)?.data?.payload?.subscriptions || [];
  const hasMoreData = pagination.currentPage < pagination.totalPage && subscription.length === limit;
  const subscriptions = (data || dummySubscriptions).flatMap((data) => cleanUpData(data, isLoading).subscriptions);
  const shouldShowLoading = isLoading || subscriptions[0]?.subscription_id === "Mocked_subscription_0";

  return {
    subscriptions,
    isLoading: shouldShowLoading,
    size,
    setSize,
    isValidating,
    hasFilter,
    mutate,
    hasMoreData,
  };
};

export const useSubscriptionAnalytics = () => {
  const {
    data: summaryData,
    isLoading: isLoadingSummary,
    mutate: mutateSummaryApi,
  } = useMutableData(API_CALL.SUMMARY_API(GetOrgId()), {
    revalidateOnMount: true,
  });

  const {
    data: spendAnalysisData,
    isLoading: spendAnalysisLoading,
    mutate: mutateSpendAnalysis,
  } = useMutableData(API_CALL.SPEND_ANALYSIS_API(GetOrgId()), {
    revalidateOnMount: true,
  });

  return {
    summaryData,
    isLoadingSummary,
    mutateSummary() {
      mutateSummaryApi();
      mutateSpendAnalysis();
    },
    spendAnalysisData,
    spendAnalysisLoading,
  };
};

export const useStaticSubscription = (filter?: { [key: string]: string | number }) => {
  const params = new URLSearchParams();

  if (filter) {
    for (const key in filter) {
      params.set(key, String(filter[key]));
    }
  }

  const { data, isLoading, mutate } = useMutableData(API_CALL.SUBSCRIPTION_LIST_API(params.toString()), {
    revalidateOnMount: true,
  });

  const { subscriptions } = cleanUpData(data, isLoading);
  const shouldShowLoading = isLoading || subscriptions[0]?.subscription_id === "Mocked_subscription_0";

  return {
    subscriptions,
    isLoading: shouldShowLoading,
    mutate,
  };
};
