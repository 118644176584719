import React, { useState, useEffect } from "react";

import { connect } from "react-redux";

import { getCurrencyFunc } from "../../Redux/Actions";
import { Toaster } from "../../Modules/toaster";
import { SPLIT_NAMES, SPLIT_TREATMENT_TYPES } from "Redux/splitio/constants";
import useCheckFeatureStatus from "customHooks/featureCheck";

import LoaderIcon from "Views/State/Loading/LoaderIcon";
import BankListing from "./BankListing";
import AddBank from "./AddBank";
import ManagePayout from "./ManagePayout";

import { getCookieValue, cookieNames } from "utility/CookieHelper";
import { trackEvent } from "utility/analytics";
import { getOrgDetailFunc } from "Redux/Actions";
import { RootState } from "Redux/ConfigureStore";
import { validateDashboardCountryCurrency, GetBaseAuthObject } from "utility";
import { BodySkeleton } from "Views/Settings/components";
import { useAnalytic } from "Views/Settings/hooks";

const BAccount = (props) => {
  const { loading, error, data, getcurrency, getOrgDetail, orgDetail, newSettingPage = false } = props;
  const { currency, countryCode } = orgDetail || {};

  const [isUsingCountryCurrency, setIsUsingCountryCurrency] = useState(false);
  const [showToaster, setShowToaster] = useState(false);
  const [showAddBank, setShowAddBank] = useState(false);

  const orgCurrencyCode = getCookieValue(cookieNames.CURRENCY_CODE);
  const [managePayout, countryCurrencyList] = useCheckFeatureStatus(SPLIT_NAMES.crManagePayout, true, true);

  const allowManagePayout = managePayout === SPLIT_TREATMENT_TYPES.ON || isUsingCountryCurrency;

  useAnalytic({ eventSource: "Personal", eventSubSource: "Payout Details" });

  useEffect(() => {
    if (currency && countryCode && countryCurrencyList) {
      const result = validateDashboardCountryCurrency(countryCurrencyList, currency, countryCode);
      setIsUsingCountryCurrency(result);
    }
  }, [countryCode, currency, countryCurrencyList]);

  useEffect(() => {
    getcurrency();
    getOrgDetail(GetBaseAuthObject().orgId);
  }, []);

  useEffect(() => {
    setShowToaster(true);

    if (data?.status === 200) {
      setShowAddBank(false);
    }
  }, [data]);

  const toggleBank = () => {
    setShowAddBank((prevState) => !prevState);
  };

  const onAddBank = () => {
    toggleBank();
    trackEvent("bank accounts add bank clicked");
  };

  if (managePayout === SPLIT_TREATMENT_TYPES.CONTROL) {
    return newSettingPage ? <BodySkeleton paddingLess /> : <LoaderIcon />;
  }

  if (allowManagePayout) {
    return <ManagePayout newSettingPage={newSettingPage} />;
  }

  return (
    <>
      {!showAddBank ? (
        <BankListing addBank={onAddBank} newSettingPage={newSettingPage} />
      ) : (
        <AddBank orgCurrencyCode={orgCurrencyCode} toggleAddBank={toggleBank} />
      )}
      <Toaster
        visible={showToaster && !loading}
        status={error ? "error" : "success"}
        message={data?.payload?.status_message}
        closeFunc={() => {
          setShowToaster(false);
        }}
      />
    </>
  );
};

export default connect(
  (state: RootState) => ({
    loading: state.bankReducer.loading,
    data: state.bankReducer.data,
    error: state.bankReducer.error,
    orgDetail: state.b2bOrgDetailReducer?.data?.payload,
  }),
  {
    getcurrency: getCurrencyFunc,
    getOrgDetail: getOrgDetailFunc,
  }
)(BAccount);
