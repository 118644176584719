import * as React from "react";
import { leftArrowOrangeIcon24x24 } from "assets/icons/card";
import Checkbox from "Modules/DS/Atoms/Checkbox";

import Loader, { LoaderTypes } from "Views/Card/Components/Loader";
import { trackEvent } from "utility/analytics";
import { cardCreatingEvent } from "Views/Card/Analytics";

import styles from "./index.module.scss";
import classNames from "classnames";

interface IActionBtnsProps {
  changeStep: (goToNextStep: boolean) => void;
  renderGoBackBtn: boolean;
  disableNextBtn?: boolean;
  actionBtnName: string | JSX.Element;
  loading?: boolean;
  showTermsAndConditionsCheck?: boolean;
}

const MmphTermsAndConditionsLinks = {
  staging: "https://spenmo-staging-monolith.s3.ap-southeast-1.amazonaws.com/terms/AUB_Debit_TC.pdf",
  prod: "https://spenmo-prod.s3.ap-southeast-1.amazonaws.com/terms/AUB_Debit_TC.pdf",
};

const ActionBtns = ({
  changeStep,
  renderGoBackBtn,
  actionBtnName,
  disableNextBtn = false,
  loading = false,
  showTermsAndConditionsCheck = false,
}: IActionBtnsProps) => {
  const [termsAndConditionsAgreed, setTermsAndConditionsAgreed] = React.useState(false);
  const IS_PROD_ENV = window?.__ENV__.API_BASE_URL === "https://api.spenmo.com";

  const containerClassName = classNames(styles.container, {
    [styles.containerWithTandC]: showTermsAndConditionsCheck,
  });

  return (
    <div className={containerClassName}>
      {showTermsAndConditionsCheck && (
        <div className={styles.termsAndConditionCheckContainer} data-testid="t&c-checkbox">
          <Checkbox
            checked={termsAndConditionsAgreed}
            onClick={() => setTermsAndConditionsAgreed((prevState) => !prevState)}
          />
          By proceeding, you agree to Spenmo Card's&nbsp;
          <a
            href={IS_PROD_ENV ? MmphTermsAndConditionsLinks.prod : MmphTermsAndConditionsLinks.staging}
            target="_blank"
            rel="noreferrer"
          >
            Terms and Conditions.
          </a>
        </div>
      )}

      <div className={styles.btnsContainer}>
        {renderGoBackBtn ? (
          <button
            className={styles.linkBtn}
            onClick={() => {
              changeStep(false);
              trackEvent(cardCreatingEvent.GO_BACK_BUTTON_CLICKED_ON_CARD_CREATION);
            }}
          >
            <img src={leftArrowOrangeIcon24x24} alt="left arrow orange" />
            Go Back
          </button>
        ) : (
          <div />
        )}
        <button
          className={`${styles.primaryBtn} ${loading ? "loading" : ""}`}
          disabled={disableNextBtn || loading || (showTermsAndConditionsCheck && !termsAndConditionsAgreed)}
          onClick={() => {
            changeStep(true);
          }}
        >
          {loading ? <Loader type={LoaderTypes.white} /> : actionBtnName}
        </button>
      </div>
    </div>
  );
};

export default ActionBtns;
