import APIclient from "API/Client";

export const GET_PENDING_APPROVALS_REQUEST = "GET_PENDING_APPROVALS_REQUEST";
export const GET_PENDING_APPROVALS_SUCCESS = "GET_PENDING_APPROVALS_SUCCESS";
export const GET_PENDING_APPROVALS_FAILURE = "GET_PENDING_APPROVALS_FAILURE";
export const CLEAR_GET_PENDING_APPROVALS = "CLEAR_GET_PENDING_APPROVALS";

const getPendingApprovalsReq = (data) => {
  return {
    type: GET_PENDING_APPROVALS_REQUEST,
    data,
  };
};
const getPendingApprovalsSuccess = (data) => {
  return {
    type: GET_PENDING_APPROVALS_SUCCESS,
    data,
  };
};
const getPendingApprovalsError = (data) => {
  return {
    type: GET_PENDING_APPROVALS_FAILURE,
    data,
  };
};

export const clearGetPendingApprovals = (data) => {
  return {
    type: CLEAR_GET_PENDING_APPROVALS,
  };
};

export const getPendingApprovalsFunc = ({ page, limit, processType = "invoice" }) => {
  return (dispatch) => {
    dispatch(getPendingApprovalsReq());

    APIclient.getData(
      `/ms/spm-organisation/v1/approval/assignments?processType=${processType}&isActive=true&page=${page}&limit=${limit}`
    )
      .then((data) => {
        dispatch(getPendingApprovalsSuccess(data.data));
      })
      .catch((err) => {
        const errorMessage = err?.response?.data?.error?.message || err;
        dispatch(getPendingApprovalsError(errorMessage));
      });
  };
};
