import React, { useContext, useState } from "react";
import Chip, { IActionChipProps } from "Modules/DS/Chip";
import { TrxnFilterContext, TrxnPaginationContext } from "Views/Transaction/Context";
import { ALL_TRXN_PAGINATION_PARAMS, resetPaginationParams } from "Views/Transaction/Constants";
import { ITrxnFilterContext, ITrxnPaginationContext, ITrxnChipSelectFilter } from "Views/Transaction/@types";

export const ChipSelect = ({
  label = "",
  filterKey = "",
  value = "",
  dispatchOnSelect = () => null,
  dispatchOnClear = () => null,
}: ITrxnChipSelectFilter) => {
  const [selected, setSelected] = useState(false);

  const { resetCurrentPage } = useContext<ITrxnPaginationContext>(TrxnPaginationContext);
  const { onApplyFilter, resetFilter } = useContext<ITrxnFilterContext>(TrxnFilterContext);

  const onSelectHandler = (value: string) => {
    onApplyFilter({
      [filterKey]: value,
      ...resetPaginationParams,
    });
    setSelected(true);
    dispatchOnSelect?.(value);
    resetCurrentPage();
  };

  const clearFilter = () => {
    resetFilter([filterKey, ...ALL_TRXN_PAGINATION_PARAMS]);
    setSelected(false);
    dispatchOnClear?.();
    resetCurrentPage();
  };

  const chipProps: IActionChipProps = {
    title: label,
    active: selected,
    selected: selected,
    onClear: clearFilter,
    onClick: () => onSelectHandler(value),
  };

  return <Chip.Action {...chipProps} />;
};
