import React, { useContext, useEffect } from "react";
import classNames from "classnames";
import { Typography } from "@spenmo/splice";

import { TOASTER_STATUS_TYPE } from "Modules/DS/Toaster";
import { ITableProps, Table, ActionsBar } from "Modules/DS/DataTable";

import { IItemID } from "Modules/DS/Menu";
import { SidePanel } from "Modules/DS/SidePanel";
import { TrxnShimmerProvider } from "Views/Transaction/Provider";
import { TABS_URL, TRXNS_TABLE_LIMIT, TRXN_ELEMENT_CLASS } from "Views/Transaction/Constants";
import { TrxnPaginationContext, TrxnToastContext, useAccountingTransaction } from "Views/Transaction/Context";
import { ITrxnListProps, ITrxnPaginationContext, ITrxnToastContext } from "Views/Transaction/@types";

import { ErrorState } from "./Error";
import { EmptyState } from "./Empty";
import { paginationProps } from "./usePagination";

import "./index.scss";

const TrxnList = <T extends IItemID>({
  className,
  subtitle,
  total,
  data,
  actions,
  columns,
  bulkAction,
  setRowSelection,
  sidePanelProps,
  emptyStateProps,
  rowAction,
  error = false,
  loading = true,
  errorMessage = "",
  showToasterOnError = false,
  pageSize = TRXNS_TABLE_LIMIT,
  dispatchOnPageChange = () => null,
  additionalInfo = null,
}: ITrxnListProps<T>): JSX.Element => {
  const { activePageDetails } = useAccountingTransaction();
  const activePageName = activePageDetails?.name;
  const isBillsTabActive = activePageName === TABS_URL.BILLS;
  const { setToaster }: ITrxnToastContext = useContext(TrxnToastContext);

  const { currentPage, setCurrentPage, resetCurrentPage }: ITrxnPaginationContext = useContext(TrxnPaginationContext);

  const scrollToTop = () => window.scrollTo(0, 0);

  const emptyState = total === 0 || (Array.isArray(data) && data?.length === 0);

  const onPageChange = (page: number): void => {
    if (loading) return;

    const pg = page - 1;

    setCurrentPage(pg);
    dispatchOnPageChange?.(pg);
    scrollToTop();
  };

  useEffect(() => {
    if (error) {
      scrollToTop();

      if (errorMessage && showToasterOnError) {
        setToaster({
          show: true,
          type: TOASTER_STATUS_TYPE.ERROR,
          message: errorMessage,
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if (emptyState) {
      scrollToTop();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emptyState]);

  useEffect(() => {
    if (activePageName) {
      resetCurrentPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePageName]);

  const table: ITableProps<T> = {
    total,
    pageSize,
    className,
    rowAction,
    bulkAction,
    onPageChange,
    dataSource: data,
    pageNum: currentPage,
    paginationOnTop: true,
    rowSelection: !isBillsTabActive && setRowSelection,
    rowClassName: (record: T) => record.id,
    paginationProps: paginationProps<T>(pageSize),
    ...(total !== 0 && { scrollHorizontal: "fit-content" }),
    ...(loading ? { rowAction: () => null } : { rowAction }),
  };

  const sidePanel = {
    ...sidePanelProps,
    loading: sidePanelProps.loading || loading,
    sidePanelClassName: isBillsTabActive && "bill-details-side-panel",
  };

  const tableClassNames = classNames(TRXN_ELEMENT_CLASS.TABLE, {
    [`increase-table-size--${total}`]: 0 <= total && total < 5,
  });

  const renderTable = () => {
    return (
      <div className={tableClassNames}>
        <Table<T> {...table}>{columns}</Table>
      </div>
    );
  };

  const checkEmptyState = () => {
    return emptyState ? <EmptyState {...emptyStateProps} /> : renderTable();
  };

  const renderPage = () => {
    return (
      <div className={className}>
        {subtitle && (
          <Typography className="trx-subtitle" variant="body-content" size="m" tag="p">
            {subtitle}
          </Typography>
        )}
        <div className={TRXN_ELEMENT_CLASS.ACTIONS}>
          <ActionsBar>{actions}</ActionsBar>
        </div>
        {!loading && additionalInfo}
        {checkEmptyState()}
        {/*can simplify use BillSidepanel component when refactoring Sidepanel component */}
        {sidePanelProps && <SidePanel {...sidePanel} />}{" "}
      </div>
    );
  };

  const checkForError = () => {
    return error ? <ErrorState /> : renderPage();
  };

  return <TrxnShimmerProvider loading={loading}>{checkForError()}</TrxnShimmerProvider>;
};

export * from "./useSelectedRows";
export * from "./usePagination";
export * from "./Column";

export default TrxnList;
