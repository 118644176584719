import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./numberIcon.scss";

const NumberIcon = ({ number, action, primary, className }) => {
  const _className = classNames(
    "number-icon",
    {
      "number-icon--primary": primary,
    },
    className
  );

  return (
    <div onClick={action} className={_className}>
      {number}
    </div>
  );
};

NumberIcon.propTypes = {
  number: PropTypes.number,
  action: PropTypes.func,
  primary: PropTypes.string,
  className: PropTypes.string,
};

export default NumberIcon;
