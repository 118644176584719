import React, { useState } from "react";
import { Divider } from "antd";
import cn from "classnames";
import { pdfjs, Document, Page } from "react-pdf";
import { Loader as SLoader } from "@spenmo/splice";

import Loader from "Modules/DS/Loader";
import { LOADER_SIZES, LOADER_TYPES } from "Modules/DS/Loader/types";
import Modal, { ModalComponent } from "Modules/DS/Modal";
import Icon from "Modules/Icon";
import { useTransformHeicImage } from "Views/Bills/V2/hooks/useTransformHeicImage";

import { greyCloseIcon, errorIcon } from "assets/img";

import styles from "../Bills.module.scss";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
const { CloseButton, Title: ModalTitle } = ModalComponent;

const ErrorPreview = () => {
  return (
    <div className={styles.previewError}>
      <img src={errorIcon} alt="error" width={120} height={120} />
      <p>Cannot load file preview. Please try again.</p>
    </div>
  );
};

const PdfPreview = (props) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  /* istanbul ignore next */
  const handleLoadSuccess = ({ numPages }) => setNumPages(numPages);

  /* istanbul ignore next */
  const handlePagination = (isPrev) => {
    if (isPrev) {
      if (pageNumber === 1) {
        return;
      }

      setPageNumber((pageNum) => pageNum - 1);

      return;
    }

    if (pageNumber === numPages) {
      return;
    }

    setPageNumber((pageNum) => pageNum + 1);
  };

  const { src } = props;

  return (
    <Document
      className={cn(styles.previewFile, styles.previewPdf)}
      file={src}
      onLoadSuccess={handleLoadSuccess}
      loading={
        <div className={styles.previewLoading}>
          <Loader size={LOADER_SIZES.BIG} type={LOADER_TYPES.BRAND} />
        </div>
      }
      error={<ErrorPreview />}
    >
      <Page pageNumber={pageNumber} />
      <div className={styles.previewPaginationContainer}>
        <div className={styles.previewPagination}>
          <Icon
            className={styles.icon}
            icon="chevronLeft"
            size={24}
            onClick={/* istanbul ignore next */ () => handlePagination(true)}
          />
          <p className={styles.page}>
            Page {pageNumber} of {numPages}
          </p>
          <Icon
            className={cn(styles.nextPage, styles.icon)}
            icon="chevronLeft"
            size={24}
            onClick={/* istanbul ignore next */ () => handlePagination(false)}
          />
        </div>
      </div>
    </Document>
  );
};

interface Data {
  fileName: string;
  fileType: string;
  src: string;
}

interface FilePreviewProps {
  isOpen: boolean;
  onClose(): void;
  data?: Data;
}

const FilePreview: React.FC<FilePreviewProps> = (props) => {
  const { isOpen, onClose, data } = props;

  const [isError, setIsError] = useState(false);

  const { imageUrl, isLoading: transformLoading } = useTransformHeicImage(data?.fileType, data?.src, {
    onError: () => setIsError(true),
  });

  return (
    <Modal className={cn(styles.modal, styles.previewModal)} visible={isOpen} close={onClose}>
      <div className={styles.container}>
        <CloseButton src={greyCloseIcon} />
        <ModalTitle>File Preview</ModalTitle>
      </div>
      <Divider />
      <div className={styles.container}>
        <div className={styles.fileContainer}>
          <div className={styles.fileHeader}>
            <p>{data?.fileName}</p>
          </div>
          <div className={styles.preview}>
            {transformLoading ? (
              <SLoader.Spinner size="l" variant="brand" className={styles.previewLoader} />
            ) : (
              data?.fileType?.startsWith("image/") &&
              /* istanbul ignore next */ (isError ? (
                <div className={styles.errorContainer}>
                  <ErrorPreview />
                </div>
              ) : (
                <img
                  className={styles.previewFile}
                  src={imageUrl}
                  alt={data?.fileName}
                  width="100%"
                  height={575}
                  onError={() => setIsError(true)}
                />
              ))
            )}
            {data?.fileType?.startsWith("application/pdf") && <PdfPreview src={data?.src} />}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default FilePreview;
