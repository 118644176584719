// EXTERNAL LIBRARIES
import React, { useEffect } from "react";

// KYX COMPONENTS
import Modal from "Views/KYX/Modal";
import Toast from "Views/KYX/Toast";
import Header from "Views/KYX/Header";
import Remarks from "Views/KYX/Remarks";
// KYX HELPERS
import { KYXModalProvider, KYXToastProvider, KYXRemarksProvider, ManualFormProvider } from "Views/KYX/Provider";

import { useAppDispatch } from "hook";
import { cookieNames, getCookieValue } from "utility/CookieHelper";
import { getPreliminaryData } from "Redux/Actions/KYX/GetPreliminaryData";
import { getCountryList } from "Redux/Actions/Common/getCountryList";
import { getOrgConfigsFunc } from "Redux/Actions/orgConfigsAction";
import { GetOrgId } from "utility";
import { UserInfoFunc } from "Redux/Actions";
import { KYB_STATUS } from "./Constants";
import { useGetKYXFormData } from "./hooks";
import styles from "./index.module.scss";

interface KYXProps {
  children: React.ReactNode;
  infoTitle?: string;
  showHeader?: boolean;
  showLogout?: boolean;
}

const KYX = ({ children, infoTitle = "", showHeader = true, showLogout = true }: KYXProps) => {
  const dispatch = useAppDispatch();
  const { getFormData } = useGetKYXFormData();
  const kybStatus = getCookieValue(cookieNames.KYB_STATUS);

  useEffect(() => {
    if (showLogout) {
      dispatch(getOrgConfigsFunc(GetOrgId()));
      dispatch(UserInfoFunc());
      dispatch(getPreliminaryData());
      dispatch(getCountryList());
    }
  }, [dispatch, showLogout]);

  useEffect(() => {
    if (kybStatus === KYB_STATUS.STARTED) {
      getFormData()
        .then(() => {})
        .catch(() => {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kybStatus]);

  return (
    <div className={styles.container}>
      <KYXModalProvider>
        <KYXToastProvider>
          <KYXRemarksProvider>
            <ManualFormProvider>
              {showHeader && <Header infoTitle={infoTitle} showLogout={showLogout} />}
              {children}
              <Modal />
              <Toast />
              <Remarks />
            </ManualFormProvider>
          </KYXRemarksProvider>
        </KYXToastProvider>
      </KYXModalProvider>
    </div>
  );
};

export default KYX;
