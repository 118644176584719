import React, { useState } from "react";
import classNames from "classnames";
import { trackEvent } from "utility/analytics";
import { CARD_LIST_TABS } from "Views/Card/List/types";
import EmptyCardList from "Views/Card/List/EmptyState";
import DataTable, {
  Table,
  IColumnData,
  BulkActionType,
  ActionButtonType,
  DATA_TABLE_SIZE_TYPES,
} from "Modules/DS/DataTable";
import { cardListingTableEvent } from "Views/Card/Analytics";
import { cardsBulkUpdate } from "Views/Card/List/Utilities";
import { useIsManagerOrAdmin } from "customHooks/Card";
import { useCardListContext } from "Views/Card/List/index.context";
import { cardListColumnsData, cardRequestOrReceivedColumnData } from "Views/Card/List/Table/ColumnsData";
import { showErrorNotification, showSuccessNotification } from "Views/Card/Components/Notification";
import SkeletonTable from "Views/Card/List/Table/Skeleton";
import { ICardDetails } from "Views/Card/types";

import "./index.scss";

type ICardTableProps = { type: CARD_LIST_TABS };

const CardTable = ({ type }: ICardTableProps) => {
  const {
    totalCount,
    currentPage,
    canAddCards,
    selectedRows,
    selectedRowId,
    isNotFiltered,
    setCurrentPage,
    handleRowClick,
    setSelectedRows,
    getCardListData,
    cardListLoading,
    cardListActiveTab,
    cardsListTabsData,
    paginatorStartIndex,
    bulkChangeStatusOrCreateCardDataInProgress,
    filters,
  } = useCardListContext();

  const [selectAll, setSelectAll] = useState(false);
  const [isAdmin, isManager] = useIsManagerOrAdmin();
  const isAdminOrManager = isAdmin || isManager;
  const loading = cardListLoading || bulkChangeStatusOrCreateCardDataInProgress;

  const isCardList = type === CARD_LIST_TABS.CARD_LIST;
  const isReceiveRequestList = type === CARD_LIST_TABS.RECEIVED_CARD_REQUEST;

  const getColumnData = (): IColumnData<ICardDetails>[] => {
    switch (type) {
      case CARD_LIST_TABS.CARD_LIST:
        return cardListColumnsData(loading, isAdminOrManager);
      case CARD_LIST_TABS.SENT_CARD_REQUEST:
        return cardRequestOrReceivedColumnData(true, loading);
      case CARD_LIST_TABS.RECEIVED_CARD_REQUEST:
        return cardRequestOrReceivedColumnData(false, loading);
    }
  };

  const getRequestCardMessage = () => {
    const cardListType = isReceiveRequestList ? "Received" : "Sent";
    return isCardList ? null : `No ${cardListType} Card Requests`;
  };

  const emptyState = () => {
    return cardListLoading ? null : (
      <EmptyCardList
        canAddCards={canAddCards}
        requestCardMessage={getRequestCardMessage()}
        showAddCard={isNotFiltered(filters) && isCardList}
      />
    );
  };

  const tableData = cardsListTabsData
    ? cardsListTabsData[type].map((item) => ({
        ...item,
        isSelected: selectedRows?.includes(item.id),
        key: item?.id,
      }))
    : [];

  const onPageChange = (page) => {
    setCurrentPage(page - paginatorStartIndex);
    getCardListData(page - paginatorStartIndex, cardListActiveTab);
    trackEvent(cardListingTableEvent.PAGINATION_CLICKED, { "page number": page });
  };

  const onSelectAll = (_selected, newSelectedRows) => {
    setSelectAll(!selectAll);
    if (selectAll) {
      setSelectedRows([]);
    } else {
      setSelectedRows(newSelectedRows?.map((x) => x?.id) || []);
    }
  };

  const setRowSelection = {
    onSelectAll: onSelectAll,
    selectedRowKeys: selectedRows,
    preserveSelectedRowKeys: true,
    onChange: (selectedRowKeys) => setSelectedRows(selectedRowKeys),
  };

  const getRowClassName = (record) =>
    classNames({
      "row-selected": record?.id === selectedRowId,
    });

  const dataTableClassName = classNames("card-list", {
    "request-card-listing":
      cardListActiveTab === CARD_LIST_TABS.RECEIVED_CARD_REQUEST ||
      cardListActiveTab === CARD_LIST_TABS.SENT_CARD_REQUEST,
    "card-list-skeleton": loading,
  });

  const handleBulkAction = async (status) => {
    const response = await cardsBulkUpdate(selectedRows, status, isAdmin);
    if (response?.isSuccess) {
      setSelectedRows([]);
      showSuccessNotification(response?.message);
      getCardListData(currentPage, CARD_LIST_TABS.CARD_LIST);
    } else {
      showErrorNotification(response?.message);
    }
    return true;
  };

  const bulkActionProp = {
    type: BulkActionType.Default,
    firstButton: {
      type: ActionButtonType.Primary,
      label: `Enable (${selectedRows?.length})`,
      handler: () => handleBulkAction("unsuspended"),
    },
    secondButton: {
      type: ActionButtonType.Secondary,
      label: `Disable (${selectedRows?.length})`,
      handler: () => handleBulkAction("suspended"),
    },
    dataName: "card",
    data: selectedRows,
    supportingInfo: null,
    clearSelection: () => {
      setSelectedRows([]);
    },
  };

  return (
    <DataTable className={dataTableClassName} size={DATA_TABLE_SIZE_TYPES.LARGE}>
      {loading ? (
        <SkeletonTable />
      ) : (
        <Table<ICardDetails>
          pageSize={10}
          total={totalCount}
          pageNum={currentPage}
          dataSource={tableData}
          rowAction={handleRowClick}
          customEmptyState={emptyState()}
          onPageChange={onPageChange}
          rowSelection={isCardList && setRowSelection}
          rowClassName={getRowClassName}
          bulkAction={bulkActionProp}
        >
          {getColumnData()}
        </Table>
      )}
    </DataTable>
  );
};

export default CardTable;
