import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import moment from "moment";
import { useQuery, currencyFormatter } from "../../utility";
import Button from "../button";

import { connect } from "react-redux";
import LoadingIcon from "../../../src/Views/State/Loading/LoaderIcon";
import { topupStatusFunc } from "../../Redux/Actions/topupStatusAction";
import StatusView from "../statusView";
import "./TopupStatusView.scss";

const TopupStatusView = (props) => {
  let query = useQuery();
  const transaction_id = query.get("transaction_id") || query.get("order_id");
  const amount = query.get("amount");
  const currency = query.get("currency") || "";
  const date = moment().format("MM/DD/YY h:mm a");

  const { topupStatusData, getTopupStatus, success, loading, error } = props;

  useEffect(() => {
    if (success && transaction_id) getTopupStatus(transaction_id);
  }, [success, getTopupStatus, transaction_id]);

  const paymentStatus = ((topupStatusData && topupStatusData.payload) || { payload: [] }).payment_status;

  const topupStatusMsg = (status, currency, amount) => {
    if (status === "pending") {
      return "We have not received your funds yet, will topup your wallet as soon as we receive your funds";
    } else if (status === "complete" && currency && amount) {
      return `An amount of ${currency} ${currencyFormatter(amount, currency, true)} has been topped up in your wallet.`;
    }
    return null;
  };

  const checkTopupStatus = (status) => {
    if (status === "pending") {
      return "Verifying Payment";
    } else if (status === "complete") {
      return "Payment Successful";
    }
    return null;
  };

  const refreshBtn = success && (
    <Button
      action={() => getTopupStatus(transaction_id)}
      iconName={"refresh-2"}
      className={"refresh-btn"}
      textButton
      text={"Refresh"}
    />
  );

  const statusViewProps = useMemo(() => {
    if (transaction_id) {
      if (success) {
        return {
          header: checkTopupStatus(paymentStatus),
          message: topupStatusMsg(paymentStatus, currency, amount),
        };
      }

      return {
        header: "Payment Failed",
        message: "There’s an issue with your payment, please try again later.",
      };
    } else {
      if (success) {
        return {
          header: "Success",
          message: "",
        };
      }

      return {
        header: "Error",
        message: "Please try again",
      };
    }
  }, [amount, currency, paymentStatus, success, transaction_id]);

  return (
    <div className="topup-status-view">
      {refreshBtn}
      {transaction_id ? (
        <>
          {loading ? (
            <LoadingIcon />
          ) : (
            <StatusView
              header={statusViewProps.header}
              error={error && topupStatusData && topupStatusData.payload?.status_message}
              success={success}
              message={statusViewProps.message}
            />
          )}
          {refreshBtn}
          <p>{`TRX NO: ${transaction_id}`}</p>
          <br />
          <p>{date}</p>
        </>
      ) : (
        <StatusView header={statusViewProps.header} success={success} message={statusViewProps.message} />
      )}
      <Link className="topup-status-view--redirect-btn" to="/">
        Back to Dashboard
      </Link>
    </div>
  );
};

TopupStatusView.defaultProps = { topupStatusData: {} };

TopupStatusView.propTypes = {
  success: PropTypes.bool,
  error: PropTypes.bool,
  loading: PropTypes.bool,
  getTopupStatus: PropTypes.func,
  topupStatusData: PropTypes.object,
};

export default connect(
  (state) => ({
    topupStatusData: state.topupStatusReducer.data,
    loading: state.topupStatusReducer.loading,
    error: state.topupStatusReducer.error,
  }),
  { getTopupStatus: topupStatusFunc }
)(TopupStatusView);
