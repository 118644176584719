import React, { useEffect, useMemo, useState } from "react";
import { useHistory, Redirect, useLocation } from "react-router-dom";
import { useIsSessionExpired } from "customHooks/Auth/useIsSessionExpired";
import usePermissionCheck from "Permission/usePermissionCheck";
import { CASBIN_PAGES } from "Permission/Pages";
import { INTEGRATION_PAGE } from "Permission/Actions";
import useCheckFeatureStatus from "customHooks/featureCheck";
import { SPLIT_NAMES, SPLIT_TREATMENT_TYPES } from "Redux/splitio/constants";
import { COMMON_PATHS } from "Route/Common/paths";

import DataTable, { DATA_TABLE_SIZE_TYPES, DATA_TABLE_PADDING } from "Modules/DS/DataTable";
import { Toast as SpliceToast } from "@spenmo/splice";

import Transactions from "Views/TransactionsListing";
import LoadingComponent from "Views/State/Loading/LoaderIcon";
import AccountingWorkflow from "Views/Accounting/Workflow";

import Modal from "./Modal";
import Toast from "./Toast";
import { TrxnPageProvider } from "./Provider";
import TransactionPageHeader from "./TransactionPageHeader";
import { IAccountingTransactionsContext, IActivePageDetails } from "./@types";
import { AccountingTransactionsContext } from "./Context";
import { findActivePageName } from "./Helper";
import {
  TRANSACTION_REQUESTER_PERMISSION_PARAMS,
  accountingPageExportPermission,
  allTrxnsDownloadPermissions,
} from "./Permission";
import AllTrxn from "./All";
import { TABS } from "./Constants";

import styles from "./index.module.scss";

const showNewTransactionsPage = (isAccountingPagePath, urlParams, isCardsTrxnsTabEnabled) => {
  return (
    isAccountingPagePath ||
    !urlParams ||
    (urlParams?.search("subtab=all") === -1 && urlParams?.search("tab=all") !== -1) ||
    urlParams?.search("tab=pending") !== -1 ||
    (isCardsTrxnsTabEnabled && urlParams?.search("tab=cards") !== -1)
  );
};

const checkIsAccountingPagePath = (path = "") => path.includes("accounting");

const Transaction = () => {
  const history = useHistory();
  const { pathname, search } = history.location || {};
  const location = useLocation();
  const isSessionExpired = useIsSessionExpired();

  const [activePageDetails, setActivePageDetails] = useState<IActivePageDetails>(null);
  const [isAccountingPage, setIsAccountingPage] = useState(checkIsAccountingPagePath(pathname));

  const accPagePermissionParam = [{ object: CASBIN_PAGES.INTEGRATION_PAGE, action: INTEGRATION_PAGE.XERO_INTEGRATION }];
  const [isAllowedToViewAccountingPage, accountingPagePermissionLoading] = usePermissionCheck(
    accPagePermissionParam,
    true
  ) as [boolean, boolean];
  const IS_REQUESTER_FILTER_ALLOWED = usePermissionCheck(TRANSACTION_REQUESTER_PERMISSION_PARAMS) as boolean;

  const isCardsTrxnsTabEnabled = useCheckFeatureStatus(SPLIT_NAMES.cardsTrxns) === SPLIT_TREATMENT_TYPES.ON;

  const [IS_ALL_TRXNS_DOWNLOAD_ALLOWED, allTrxnsDownloadPermissionLoading] = usePermissionCheck(
    allTrxnsDownloadPermissions,
    true
  ) as [boolean, boolean];

  const [IS_ACC_TRXNS_EXPORT_ALLOWED, accTrxnsExportPermissionLoading] = usePermissionCheck(
    accountingPageExportPermission,
    true
  ) as [boolean, boolean];

  useEffect(() => {
    setActivePageDetails((prevState) => ({
      ...prevState,
      name: findActivePageName(pathname, search),
      isRequesterFilterAllowed: IS_REQUESTER_FILTER_ALLOWED,
    }));
    setIsAccountingPage(checkIsAccountingPagePath(pathname));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, pathname, location, IS_REQUESTER_FILTER_ALLOWED]);

  // using window.history.state as the url path was updated through window.history.pushState method to prevent page refresh on toggle
  const windowHistoryState = window.history.state?.state;
  // adding this useEffect to show Transactions page on click of Transactions navlink in sidebar when the Accounting page is active
  useEffect(() => {
    if (!isAccountingPage && !checkIsAccountingPagePath(windowHistoryState)) {
      setIsAccountingPage(false);
    }
  }, [isAccountingPage, windowHistoryState]);

  const accountingTransactionsProviderValue: IAccountingTransactionsContext = useMemo(
    () => ({
      activePageDetails,
      setActivePageDetails,
    }),
    [activePageDetails]
  );

  if (accountingPagePermissionLoading || allTrxnsDownloadPermissionLoading || accTrxnsExportPermissionLoading) {
    return <LoadingComponent />;
  }

  if (isSessionExpired) {
    return <Redirect to={COMMON_PATHS.PAGE_NOT_FOUND} />;
  }

  if (isAccountingPage && !isAllowedToViewAccountingPage && !isSessionExpired) {
    return <Redirect to="/403" />;
  }

  if (!showNewTransactionsPage(isAccountingPage, search, isCardsTrxnsTabEnabled)) {
    return <Transactions />;
  }

  return (
    <AccountingTransactionsContext.Provider value={accountingTransactionsProviderValue}>
      <TrxnPageProvider>
        <div className={styles.container}>
          <TransactionPageHeader
            isAllowedToViewAccountingPage={isAllowedToViewAccountingPage}
            isAccountingPage={isAccountingPage}
            setIsAccountingPage={setIsAccountingPage}
            isAllTrxnsDownloadAllowed={IS_ALL_TRXNS_DOWNLOAD_ALLOWED}
            isAccTrxnsExportAllowed={IS_ACC_TRXNS_EXPORT_ALLOWED}
          />
          {isAccountingPage ? (
            <AccountingWorkflow isFromTransactionsPage />
          ) : (
            <>
              <DataTable
                className={styles.transactions}
                size={DATA_TABLE_SIZE_TYPES.LARGE}
                padding={DATA_TABLE_PADDING.NONE}
              >
                <AllTrxn title={TABS.SETTLED} url={TABS.SETTLED} />
              </DataTable>
              <Modal />
              <Toast />
              <SpliceToast />
            </>
          )}
        </div>
      </TrxnPageProvider>
    </AccountingTransactionsContext.Provider>
  );
};

export default Transaction;
