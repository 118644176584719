import AddSpenmoAsaBankAccount from "../Common/AddSpenmoAsABankAccount";
import ChooseOrganisation from "../Common/ChooseOrganisation";
import { AccountingSetup } from "../Common/types";
import CategorySelection from "./CategorySelection";
import SyncAccount from "./SyncAccount";

export const expenseConfig: AccountingSetup[] = [
  {
    label: "Select Xero Organisation",
    index: 0,
    Component: ChooseOrganisation,
  },
  {
    label: "Add Spenmo as a Bank Account in Xero",
    index: 1,
    Component: AddSpenmoAsaBankAccount,
  },
  {
    label: "Map your accounts",
    index: 2,
    Component: SyncAccount,
    needRefetch: true,
  },
  {
    label: "Map your categories",
    index: 3,
    Component: CategorySelection,
  },
];

export const findExpenseConfig = (chooseConnection: boolean) => {
  if (chooseConnection) {
    return expenseConfig;
  }
  return expenseConfig
    .filter((item) => item.index !== 0)
    .map((item, index) => ({
      ...item,
      index,
    }));
};
