import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Form } from "antd";
import { useForm } from "antd/lib/form/Form";
import { Typography, Banner, InfoFilled } from "@spenmo/splice";
import cn from "classnames";

import { useQuery } from "utility/useQuery";
import { trackEvent } from "utility/analytics";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import useCheckFeatureStatus from "customHooks/featureCheck";
import { SPLIT_NAMES, SPLIT_TREATMENT_TYPES } from "Redux/splitio/constants";
import {
  getAccountingSetting,
  getLastSyncTime,
} from "Redux/DataCalls/AccountingSetting.api";
import {
  getXeroIntegrationDetails,
  accountingPartnerAuth,
  getPartnerAccount,
} from "Redux/Actions";
import { fetchAccountingTag } from "Redux/Actions/AccountingTag/accountingTagAction";
import { BodySkeleton } from "Views/Settings/components";
import {
  useToasterContext,
  TOASTER_STATUS_TYPE,
} from "Views/Settings/context/ToasterContext";

import TransactionSettings from "./TransactionSettings";
import PartnerOrgAccountInformation from "./PartnerOrgAccountInformation";
import FooterActionButtons from "./FooterActionButtons";
import { DescriptiveText } from "./components";
import IntegrationPartnerList from "./IntegrationPartnerList";
import {
  getConfigSettingChangedFlag,
  findGlobalConfig,
  mapSetting,
} from "./helper/dataMapping";
import { ACCOUNTING_SETTING_ANALYTICS, PARTNER_TYPE } from "./helper/constant";
import { useDisableButton } from "./hooks/useDisableButton";
import { usePartnerList } from "./hooks/usePartnerList";
import { IGlobalConfig, ISettingChangeObj, ITabConfig } from "./@types";
import { SettingContext } from "./context/SettingContext";

import styles from "./styles.module.scss";

const IntegrationSettingContainer = ({
  isAllowedIntegrationSettingUpdate,
}: {
  isAllowedIntegrationSettingUpdate: boolean;
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [form] = useForm();
  const location = useLocation<{
    message: string;
    type?: TOASTER_STATUS_TYPE;
  }>();
  const { setToaster } = useToasterContext();

  const {
    loading: accountingPartnerLoading,
    error: accountingPartnerError,
    data: accountingPartnerdata,
    xeroAccountReducer,
    accountingTags,
  } = useSelector((state: any) => ({
    ...state.accountingPartnerAuthReducer,
    xeroAccountReducer: state.xeroAccountReducer,
    accountingTags: state.accountingTagReducer?.data?.payload?.tags || [],
  }));
  const {
    has_valid_token: connectedToAccountingPartner,
    expense: usingExpenseIntegration,
    bankfeed: usingBankFeedIntegration,
    validToken,
  } = accountingPartnerdata?.payload || {};
  const onlyExpenseIntegration =
    usingExpenseIntegration && !usingBankFeedIntegration;
  const bankfeedWithExpenseIntegration =
    usingExpenseIntegration && usingBankFeedIntegration;
  const {
    data: partnerAccountData,
    loading: partnerAccountDataLoading,
    integrationDataLoading,
    integrationData,
  } = xeroAccountReducer;

  const partnerIntegrationData = integrationData?.payload || {};
  const { expenseBankAccountId, partnerName } = partnerIntegrationData;
  const isValidXeroAccountData =
    integrationData?.status === HTTP_STATUS_CODE.OK &&
    expenseBankAccountId?.length > 0;

  const { accounts } = partnerAccountData?.payload || {};
  const partnerAccountNotFound =
    integrationData &&
    partnerAccountData?.payload &&
    !accounts?.find((item) => item?.id === expenseBankAccountId) &&
    partnerName === PARTNER_TYPE.XERO;
  const [tabsConfig, setTabsConfig] = useState<ITabConfig[]>([]);
  const [globalConfig, setGlobalConfig] = useState<IGlobalConfig[]>([]);
  const [enabledExpenseSync, setEnabledExpenseSync] = useState(
    bankfeedWithExpenseIntegration,
  );

  const [settingChangeObj, setSettingChangeObj] =
    useState<ISettingChangeObj>(null);
  const [disableButton] = useDisableButton(settingChangeObj, [
    "expenseSync",
    "fundingBankAccount",
  ]);
  const settingContext = useMemo(
    () => ({
      tabsConfig,
      setTabsConfig,
      form,
      globalConfig,
      setGlobalConfig,
      enabledExpenseSync,
      setEnabledExpenseSync,
      usingExpenseIntegration,
      usingBankFeedIntegration,
      setSettingChangeObj,
    }),
    [
      tabsConfig,
      form,
      globalConfig,
      enabledExpenseSync,
      usingExpenseIntegration,
      usingBankFeedIntegration,
    ],
  );
  const { data: lastSyncTime, refetch: refetchLastSyncTime } = useQuery({
    run: Boolean(usingBankFeedIntegration),
    apiCall: getLastSyncTime,
  });
  const { partnerListData, partnerListLoading } = usePartnerList({
    accountingPartnerdata,
    connectedToAccountingPartner,
  });
  const advanceExpenseIntegrationTreatement = useCheckFeatureStatus(
    SPLIT_NAMES.advanceExpenseIntegration,
    true,
  );
  const usingAdvanceExpenseIntegration =
    advanceExpenseIntegrationTreatement !== SPLIT_TREATMENT_TYPES.CONTROL &&
    advanceExpenseIntegrationTreatement === SPLIT_TREATMENT_TYPES.ON;

  const {
    data: accountingSettingData,
    loading: accountingSettingLoading,
    refetch: refetchAccountingSettingData,
  } = useQuery({ apiCall: getAccountingSetting });

  useEffect(() => {
    dispatch(accountingPartnerAuth());
    dispatch(fetchAccountingTag([]));
  }, [dispatch]);

  useEffect(() => {
    if (connectedToAccountingPartner) {
      dispatch(getPartnerAccount());
      dispatch(getXeroIntegrationDetails());
    }
  }, [accountingPartnerdata]);

  useEffect(() => {
    const message = location.state?.message;
    const type = location.state?.type || TOASTER_STATUS_TYPE.SUCCESS;

    if (message) {
      setToaster({ show: true, message, type });
      trackEvent(ACCOUNTING_SETTING_ANALYTICS.INTEGRATIONS_TO_XERO_PROCESSED, {
        xero_api_status: "Success",
      });
      history.replace({ state: {} });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);

  const transactionSettings = useMemo(() => {
    if (!accountingTags.length) {
      const accountingSettingsWithoutTags = Object.entries(
        accountingSettingData?.settingsV2 || {},
      )?.map(([key, value]: any) => [
        key,
        value?.filter((field) => field.code !== "field_tags"),
      ]);
      return Object.fromEntries(accountingSettingsWithoutTags);
    } else {
      return accountingSettingData?.settingsV2;
    }
  }, [accountingTags, accountingSettingData]);

  useEffect(() => {
    setTabsConfig(
      mapSetting(transactionSettings, connectedToAccountingPartner),
    );
    setGlobalConfig(findGlobalConfig(transactionSettings));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionSettings, accountingPartnerdata]);

  useEffect(() => {
    const initialGlobalConfig = findGlobalConfig(transactionSettings);
    const initialTabsConfig = mapSetting(
      transactionSettings,
      connectedToAccountingPartner,
    );
    const { globalConfigChanged, tabsConfigChanged } =
      getConfigSettingChangedFlag(
        initialGlobalConfig,
        globalConfig,
        initialTabsConfig,
        tabsConfig,
      );
    setSettingChangeObj(() => ({
      expenseSync: globalConfigChanged,
      configSetting: tabsConfigChanged,
    }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionSettings, globalConfig, tabsConfig]);

  useEffect(() => {
    setSettingChangeObj((settingChangeObj) => ({
      ...settingChangeObj,
      expenseSync:
        connectedToAccountingPartner &&
        enabledExpenseSync !== bankfeedWithExpenseIntegration,
    }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectedToAccountingPartner, enabledExpenseSync]);

  const isLoading =
    accountingPartnerLoading ||
    partnerAccountDataLoading ||
    accountingSettingLoading ||
    integrationDataLoading ||
    partnerListLoading;

  if (isLoading) {
    return <BodySkeleton paddingLess />;
  }

  return (
    <Form form={form}>
      <SettingContext.Provider value={settingContext}>
        <Typography
          variant="headline-content"
          size="m"
          tag="h3"
          children="Integration"
        />
        <DescriptiveText
          className={styles.integrationLabel}
          description="Power up your workflows with these integrations with Spenmo"
        />
        {!connectedToAccountingPartner && (
          <div className={styles.integrationBanner}>
            <Banner
              variant="neutral"
              size="s"
              description="You may only connect one integration option at once"
            />
          </div>
        )}
        <IntegrationPartnerList
          partnerListData={partnerListData}
          connectedToAccountingPartner={connectedToAccountingPartner}
          isAllowedIntegrationSettingUpdate={isAllowedIntegrationSettingUpdate}
          isPartnerAccountNotAvailable={partnerAccountNotFound}
          usingAdvanceExpenseIntegration={usingAdvanceExpenseIntegration}
          accountingPartnerError={accountingPartnerError}
          accountingPartnerdata={accountingPartnerdata}
        />
        {connectedToAccountingPartner && isValidXeroAccountData && (
          <div className={cn(styles.integrationCard, styles.adjustBorder)}>
            <PartnerOrgAccountInformation
              partnerIntegrationData={partnerIntegrationData}
              partnerAccountData={partnerAccountData}
              partnerAccountNotFound={partnerAccountNotFound}
              settingUpdateNotAllowed={!isAllowedIntegrationSettingUpdate}
              lastSyncTime={lastSyncTime}
              refetchLastSyncTime={refetchLastSyncTime}
              partnerName={partnerName}
            />
            {isAllowedIntegrationSettingUpdate && (
              <FooterActionButtons
                isPartnerConnected={connectedToAccountingPartner}
                setToaster={setToaster}
                partnerAccountData={partnerAccountData?.payload || {}}
                refetchAccountingSettingData={refetchAccountingSettingData}
                disableSaveChangesBtn={disableButton}
                partnerAuthData={accountingPartnerdata}
                partnerIntegrationData={partnerIntegrationData}
                partnerName={partnerName}
              />
            )}
          </div>
        )}
        <div className={styles.integrationCard}>
          <TransactionSettings
            settings={transactionSettings}
            form={form}
            settingUpdateNotAllowed={!isAllowedIntegrationSettingUpdate}
            enableExpenseIntegration={
              (enabledExpenseSync && usingBankFeedIntegration) ||
              onlyExpenseIntegration
            }
            settingsChange={settingChangeObj}
            refetchAccountingSettingData={refetchAccountingSettingData}
            setToaster={setToaster}
            partnerName={partnerName}
          />
        </div>
        {validToken && (
          <Banner
            variant="info"
            icon={InfoFilled}
            title=""
            description={
              <p className={styles.bannerMessage}>
                To use{" "}
                {partnerName === PARTNER_TYPE.NETSUITE
                  ? PARTNER_TYPE.XERO
                  : PARTNER_TYPE.NETSUITE}{" "}
                integration, remove your other connected accounting integrations
              </p>
            }
          />
        )}
      </SettingContext.Provider>
    </Form>
  );
};

export default IntegrationSettingContainer;
