import React, { useCallback, useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { MoreFilter } from "Modules/DS/Filter";
import { ISelectContext, SelectContext } from "Modules/DS/Select";
import { getTeamListByUserId } from "Redux/Actions";
import styles from "./MoreFilters.module.scss";
import { TeamFilter } from "./team";
import AnalyticsContext from "Views/Analytics/Context/context";
import { TransactionTypeFilter } from "./trxnType";
import { POSITION_ALGO_TYPE } from "Modules/DS/Popover";

interface IMoreFilters {
  open?: boolean;
  filters: { [key: string]: string[] };
  applyFilter: (value: { [key: string]: string[] }) => void;
  resetFilter: (value: string[]) => void;
  clearFilter: () => void;
  handleMoreFilter: (value?: { [key: string]: string[] }) => void;
}
export interface ISingleLine {
  id: string;
  title: string | React.ReactNode;
}

export const MoreFilters = ({ filters, applyFilter, clearFilter, resetFilter, handleMoreFilter }: IMoreFilters) => {
  const [currentFilters, setCurrentFilters] = useState<typeof filters>({
    team_ids: [],
    type_ids: [],
  });
  const { clear, setClear, filter, setMoreFilter, moreFilter } = useContext(AnalyticsContext);

  const _filters: string[] = Object.keys({
    team_ids: filter?.team_ids || [],
    type_ids: filter?.type_ids || [],
  })
    .filter(Boolean)
    .filter((key) => filter[key].length)
    .filter((key) => !!moreFilter[key]);

  const { closeSelect } = useContext<ISelectContext>(SelectContext);

  const dispatch = useDispatch();
  const dispatchTeamListAction = useCallback(
    (id) => {
      dispatch(getTeamListByUserId(id));
    },
    [dispatch]
  );

  useEffect(() => {
    dispatchTeamListAction(undefined);
  }, [dispatchTeamListAction]);
  useEffect(() => {
    setCurrentFilters(filters);
  }, [filters]);

  useEffect(() => {
    if (Object.values(filters).filter(Boolean).length === 0 && clear) {
      setClear(false);
    }
  }, [clear, filters]);

  const handleSelectFilter = (selected: string[], type: string) => {
    setCurrentFilters((prevState) => ({
      ...prevState,
      [type]: selected,
    }));
  };

  const clearMoreFilter = (id: string) => {
    let temp = { ...currentFilters };
    delete temp[id];
    handleMoreFilter(temp);
  };

  const clearFilterHandler = () => {
    clearFilter();
    setCurrentFilters((prevState) => ({
      ...prevState,
      type_ids: [],
      team_ids: [],
    }));
    resetFilter(["team_ids", "type_ids"]);
    setClear(true);
    closeSelect();
  };

  const onApplyHandler = () => {
    applyFilter(currentFilters);
    closeSelect();
  };

  const onClearHandler = () => {
    clearFilter();
    setCurrentFilters((prevState) => ({
      ...prevState,
      type_ids: [],
      team_ids: [],
    }));
  };

  const handleClickOutside = () => {
    let moreFilter = {};
    if (filter.team_ids.length) {
      moreFilter["team_ids"] = filter.team_ids;
    }
    if (filter.type_ids.length) {
      moreFilter["type_ids"] = filter.type_ids;
    }
    setMoreFilter(moreFilter);
  };

  const renderFilterList = () => {
    return [
      {
        id: "ALL-ANALYTICS-MORE-FILTER-1",
        enabled: true,
        Component: <TeamFilter handleSelectFilter={handleSelectFilter} clearFilter={clearMoreFilter} id="team_ids" />,
      },
      {
        id: "ALL-ANALYTICS-MORE-FILTER-2",
        enabled: true,
        Component: (
          <TransactionTypeFilter handleSelectFilter={handleSelectFilter} clearFilter={clearMoreFilter} id="type_ids" />
        ),
      },
    ].map((item) => <div key={item.id}>{item.Component}</div>);
  };

  return (
    <div className={styles.moreFilters}>
      <MoreFilter<any>
        useDynamicPosition={true}
        filters={_filters}
        id="analytics"
        clearFilter={clearFilterHandler}
        onApply={onApplyHandler}
        onClear={onClearHandler}
        onClickOutside={handleClickOutside}
        clear={clear}
        positionAlgoType={POSITION_ALGO_TYPE.HORIZONTAL}
      >
        {renderFilterList()}
      </MoreFilter>
    </div>
  );
};
