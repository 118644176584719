import * as React from "react";
import { Button } from "antd";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { useCardDoesNotBelongToUser } from "customHooks/Card";
import ActivateCardModal from "Views/Card/Add/ActivateCardModal";
import { useCardDetailsContext } from "Views/Card/Details/index.context";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import Loader, { LoaderTypes } from "Views/Card/Components/Loader";

import styles from "Views/Card/Details/ActionBtns/index.module.scss";

interface IRemoveButtonProps {
  onPhysicalCardSync: () => void;
}

export const ActivatePhysicalCardBtn = ({ onPhysicalCardSync }: IRemoveButtonProps) => {
  const { cardData } = useCardDetailsContext();
  const { cardHolder, cardUserId } = cardData || {};
  const { data: createCardData, loading: syncCardInProgress } = useSelector(
    (state: any) => state?.createCardReducer || {}
  );
  const [showSyncCardModal, setShowSyncCardModal] = React.useState(false);
  const cardDoesNotBelongsToUser = useCardDoesNotBelongToUser(cardUserId);

  React.useEffect(() => {
    if (createCardData?.payload) {
      const { status, type } = createCardData?.payload || {};
      if (status === HTTP_STATUS_CODE.OK && type === "physicalSync") {
        onPhysicalCardSync();
      }
    }
  }, [createCardData]);

  const btnClassName = classNames(
    styles["btn"],
    styles["primary-btn"],
    styles["primary-btn-with-grey-disable-bg"],
    styles["width-100"],
    styles["activateBtn"]
  );

  return (
    <>
      {showSyncCardModal && <ActivateCardModal onClose={() => setShowSyncCardModal(false)} />}
      <Button
        className={btnClassName}
        disabled={syncCardInProgress || cardDoesNotBelongsToUser}
        onClick={() => setShowSyncCardModal(true)}
        data-testid="remove-btn"
      >
        {syncCardInProgress ? <Loader type={LoaderTypes.white} /> : "Activate Card"}
      </Button>
      {cardDoesNotBelongsToUser && (
        <div className={styles["activateBtnText"]}>{`Only ${cardHolder} can activate this card`}</div>
      )}
    </>
  );
};
