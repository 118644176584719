import React, { useState, useCallback, useEffect, useMemo, useRef } from "react";
import { Form, Checkbox } from "antd";
import moment from "moment";
import { CheckboxChangeEvent } from "antd/lib/checkbox";

import ExchangeInput from "Modules/ExchangeInput";
import { Tooltip, TooltipArrowAlignmentEnum, TooltipPositionEnum } from "Modules/DS/Tooltip";

import {
  BillFeeParameters,
  BillFeeResponse,
  FeeBreakdownProcess,
  FeeId,
  getBillFee,
} from "Redux/DataCalls/Disbursement.api";
import { debounce } from "utility";

import { newInfo } from "assets/img";
import { CorridorTypes, ExchangeCalculatorProps, SwiftPaymentChargeType } from "./types";
import styles from "./ExchageCalculator.module.scss";
import { feeValueFormatter } from "Views/UploadInvoice/helper";
import { InputType } from "Modules/ExchangeInput/types";
import { useSelector } from "react-redux";
import axios from "axios";

const getSwiftPaymentChargeType = (isPayFull) => {
  return isPayFull ? SwiftPaymentChargeType.OUR : SwiftPaymentChargeType.SHA;
};

const ExchangeCalculator = (props: ExchangeCalculatorProps) => {
  const {
    form,
    corridorType,
    // IMPROVEMENT: Should get ocrData from context instead
    ocrData,
    // IMPROVEMENT: amount fields should be handled
    // in this component instead
    receiverAmount,
    senderAmount,
    receiverCountry,
    receiverCurrencyCode,
    senderCurrencyCode,
    // IMPROVEMENT: move fetch bill detail here, and
    // check whether the fullPayment is true/false here
    isFullPayment,
    // IMPROVEMENT: fxRate related should be handled
    // in this component instead.
    // fxRate,
    isFixedRate,
    refetchFxRate,
    // IMPROVEMENT: there should not be any onChange callback here
    onChangeReceiverAmount,
    onChangeSenderAmount,
    // onChangeWalletAmount is only for submitting the bill with the amountFx field
    onChangeWalletAmount,
    // IMPROVEMENT: onChangePayFullGuarantee could be processed here.
    onChangePayFullGuarantee,
    // IMPROVEMENT: onChangeBillFee callback is to support the old PreviewContent
    onChangeBillFee,
    // IMPROVEMENT: handle this from another entry point
    isAmountMismatch = false,
    disabled,
  } = props;
  const orgDetails = useSelector((state: any) => state.b2bOrgDetailReducer?.data?.payload || {});

  // IMPROVEMENT: withOCR is not needed
  // if the field is not changing 1 by 1.
  // in this case, country & OCR data is changing
  // 1 by 1.
  const [withOCR, setWithOCR] = useState(false);

  const [isLoadingBillFee, setIsLoadingBillFee] = useState(false);
  const [fetchBillFeeRetried, setFetchBillFeeRetried] = useState(false);
  const [lastBillFeeParams, setLastBillFeeParams] = useState<BillFeeParameters>();
  const [amountMismatch, setAmountMismatch] = useState(isAmountMismatch);
  const [billFee, setBillFee] = useState<Partial<BillFeeResponse>>();
  const [isPayFull, setIsPayFull] = useState(false);
  const [lastFocus, setLastFocus] = useState<keyof typeof InputType>(InputType.receiver);

  const rejectRef = useRef<any>();
  const isInternationalGlobal = corridorType === CorridorTypes.internationalGlobal;
  const isDomestic = useMemo(
    () =>
      receiverCurrencyCode &&
      senderCurrencyCode &&
      receiverCurrencyCode === senderCurrencyCode &&
      receiverCurrencyCode?.substring(0, 2) === receiverCountry,
    [receiverCountry, receiverCurrencyCode, senderCurrencyCode]
  );
  // IMPROVEMENT: validation that should be from the BE
  // Changing this means you need to change SendMoneyCalculator Component too
  const internationalGlobalUSD = receiverCurrencyCode === "USD" && isInternationalGlobal;

  const breakdownProcess = useMemo(() => {
    if (!Array.isArray(billFee?.breakdown)) {
      return [];
    }

    const additionalProps = (process: FeeBreakdownProcess) => {
      const { id, note, value, type, tooltip } = process;
      const numberValue = feeValueFormatter(type, value, senderCurrencyCode);
      let breakdownProps;
      switch (id) {
        case FeeId.EXCHANGE: {
          const updatedAt = moment(note).format("DD MMM YYYY [at] HH:mm:ss");
          breakdownProps = {
            detail: (
              <div>
                <p>{numberValue}</p>
                <p className={styles.fxNote}>Mid-market rate until {updatedAt}</p>
              </div>
            ),
          };
          break;
        }
        default: {
          // for FeeId.TRANSFER_FEE
          // for FeeId.SWIFT_PAYMENT_CHARGE_FEE
          breakdownProps = {
            detail: note ? (
              <Tooltip text={note} alignArrow={TooltipArrowAlignmentEnum.LEFT} position={TooltipPositionEnum.RIGHT}>
                <p className={styles.tdUnderline}>{value === 0 ? "Free" : numberValue}</p>
              </Tooltip>
            ) : (
              numberValue
            ),
            isLoading: isLoadingBillFee,
          };
        }
      }

      return {
        labelTooltip: tooltip,
        ...breakdownProps,
      };
    };

    return billFee.breakdown.map((process) => {
      const { label, operator, value } = process;

      return {
        label,
        operator,
        value,
        ...additionalProps(process),
      };
    });
  }, [billFee?.breakdown, isLoadingBillFee, senderCurrencyCode]);

  const validateAmount = () => {
    // check if amount calculation mismatch, and try again once
    if (amountMismatch) {
      if (!fetchBillFeeRetried) {
        setFetchBillFeeRetried(true);
        fetchBillFee(lastBillFeeParams);
      }
      return Promise.reject(new Error("There's an issue when calculating payment amount. Please try again."));
    }
    if (billFee?.minimumTransferAmount && Number(senderAmount) < billFee.minimumTransferAmount) {
      return Promise.reject(`Minimum transfer amount is ${billFee.minimumTransferAmount}`);
    }
    return !receiverAmount || Number(receiverAmount) > 0
      ? Promise.resolve()
      : Promise.reject(new Error("Entered amount must be greater than 0."));
  };

  // IMPROVEMENT: could use useSWR for fetching the Bill Fee
  const fetchBillFee = (params: BillFeeParameters) => {
    setIsLoadingBillFee(true);

    const abortSignal = () => {
      if (rejectRef.current) {
        rejectRef.current.cancel("Operation canceled by the user.");
      }

      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();

      rejectRef.current = source;

      return source.token;
    };

    getBillFee(
      {
        ...params,
        receiverCountryCode: receiverCountry,
        receiverCurrency: receiverCurrencyCode,
        senderCurrency: senderCurrencyCode,
      },
      "v2",
      {
        cancelToken: abortSignal(),
      }
    )
      .then((res) => {
        const billFee = res?.data?.payload;
        setBillFee(billFee);
        onChangeBillFee(billFee);

        onChangeWalletAmount(String(billFee?.amountFx));
        if (params?.receiverAmount) {
          onChangeSenderAmount(String(billFee?.totalAmount));
        } else {
          onChangeReceiverAmount(String(billFee?.receiverAmount));
        }
        setAmountMismatch(false);
        setFetchBillFeeRetried(false);
      })
      .catch((e) => {
        // cause validation to show error if API call failed
        setAmountMismatch(true);
      })
      .finally(() => {
        setLastBillFeeParams(params);
        setIsLoadingBillFee(false);
        form.validateFields(["payment"]).catch((e) => console.error(e));
      });
  };

  const refreshFxRate = useCallback(
    debounce((billFeeParams) => {
      refetchFxRate(receiverCurrencyCode, senderCurrencyCode);

      if (billFeeParams.receiverAmount || billFeeParams.totalAmount) {
        fetchBillFee(billFeeParams);
      } else {
        /**
         * set billFee state to show free Fee transfer which is not
         * the best practice since it should come from the BE.
         */
        setBillFee({
          totalFeeAmount: 0,
        });

        if (billFeeParams.hasOwnProperty("receiverAmount")) {
          onChangeSenderAmount?.("");
        } else {
          onChangeReceiverAmount?.("");
        }
      }
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isDomestic, receiverCountry, receiverCurrencyCode, senderCurrencyCode]
  );

  const handleChangeReceiverAmount = (amount: string) => {
    refreshFxRate({
      receiverAmount: Number(amount),
      swiftPaymentChargeType: getSwiftPaymentChargeType(isPayFull),
    });

    onChangeReceiverAmount?.(amount);
  };

  const handleChangeSenderAmount = (amount: string) => {
    refreshFxRate({
      totalAmount: Number(amount),
      swiftPaymentChargeType: getSwiftPaymentChargeType(isPayFull),
    });

    onChangeSenderAmount?.(amount);
  };

  const handleChangeFocus = (type: keyof typeof InputType) => {
    setLastFocus(type);
  };

  const handleTogglePayFull = (e: CheckboxChangeEvent) => {
    const isChecked = e.target.checked;

    const refreshParams =
      lastFocus === InputType.receiver
        ? {
            receiverAmount: Number(receiverAmount),
          }
        : {
            totalAmount: Number(senderAmount),
          };

    refreshFxRate({
      ...refreshParams,
      swiftPaymentChargeType: getSwiftPaymentChargeType(isChecked),
    });
    setIsPayFull(isChecked);

    // callback
    onChangePayFullGuarantee?.(isChecked);
  };

  // update default value for full payment
  useEffect(() => {
    setIsPayFull(isFullPayment);
  }, [isFullPayment]);

  // update amount mismatch validation from props
  useEffect(() => {
    setAmountMismatch(isAmountMismatch);
  }, [isAmountMismatch]);

  // revalidate if amount mismatch is changed
  useEffect(() => {
    form.validateFields(["payment"]);
  }, [amountMismatch]);

  // re-fetch fx rate and bill fee
  useEffect(() => {
    if (receiverCurrencyCode && senderCurrencyCode && receiverCountry) {
      const swiftPaymentChargeType = getSwiftPaymentChargeType(isPayFull);

      // Refreshing the bill fee only when the currency
      // is updated according to the OCR
      // and when the paymentAmount is available
      const ocrCurrency = ocrData?.paymentCurrency || orgDetails?.currency;
      const checkOCRData = ocrData?.paymentAmount && ocrCurrency === receiverCurrencyCode;

      // IMPROVEMENT: since the country is changing even when
      // the field is hidden. The OCR data needed to be extract
      // like tnis.
      // one time OCR
      if (checkOCRData && !withOCR) {
        const ocrReceiverAmount = Number(ocrData.paymentAmount);
        setWithOCR(true);

        if (ocrReceiverAmount) {
          refreshFxRate({
            receiverAmount: ocrReceiverAmount,
            swiftPaymentChargeType,
          });
        }
      } else {
        const refreshParams =
          lastFocus === InputType.receiver
            ? {
                receiverAmount: Number(receiverAmount),
                swiftPaymentChargeType,
              }
            : {
                totalAmount: Number(senderAmount),
                swiftPaymentChargeType,
              };
        refreshFxRate(refreshParams);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFixedRate, receiverCurrencyCode, refreshFxRate, senderCurrencyCode, receiverCountry, receiverAmount, ocrData]);

  return (
    <>
      <Form.Item
        name="payment"
        validateTrigger="onBlur"
        rules={[
          { required: true, message: "Please select currency and enter amount" },
          { pattern: /[0-9]+/, message: "Please select currency and enter amount" },
          { validator: validateAmount },
        ]}
      >
        <ExchangeInput
          receiverAmount={receiverAmount}
          receiverAmountLabel={
            !isPayFull &&
            internationalGlobalUSD && (
              <div className={styles.receiverLabel}>
                <img src={newInfo} alt="tooltip info icon" width={16} height={16} className={styles.infoIcon} />
                Intermediary fees may apply
              </div>
            )
          }
          senderAmount={senderAmount}
          receiverCurrencyCode={receiverCurrencyCode}
          senderCurrencyCode={senderCurrencyCode}
          exchangeProcess={breakdownProcess}
          onChangeReceiverAmount={handleChangeReceiverAmount}
          onChangeSenderAmount={handleChangeSenderAmount}
          onChangeFocus={handleChangeFocus}
          disabled={disabled}
        />
      </Form.Item>
      {isInternationalGlobal && receiverCurrencyCode === "USD" && (
        <div className={styles.payFull}>
          <Checkbox onChange={handleTogglePayFull} checked={isPayFull}>
            Pay Full Amount Guarantee to ensure recipient gets exact amount
          </Checkbox>
        </div>
      )}
    </>
  );
};

export default ExchangeCalculator;
