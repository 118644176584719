import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useLoading from "utility/useLoading";
import { disconnectAccountingPartnerAction, fetchDataSuccess as updateXeroAuthData } from "Redux/Actions";
import {
  getAccountingSetting,
  updateAccountingSetting,
  updateExpenseSyncSetting,
  updateTopupSetting,
} from "Redux/DataCalls/AccountingSetting.api";
import { Button, DeleteFilled } from "@spenmo/splice";
import { trackEvent } from "utility/analytics";
import { ACCOUNTING_SETTING_ANALYTICS } from "../helper/constant";
import DisconnectConfirmationModal from "../components/DisconnectConfirmationModal";
import { TOASTER_STATUS_TYPE } from "Modules/DS/Toaster/types.d";
import { IToasterProps, IXeroAuthData, IPartnerIntegrationData } from "../@types";
import { mapToApiPayload } from "../helper/dataMapping";
import { useSettingContext } from "../context/SettingContext";
import styles from "../styles.module.scss";

const FooterActionButtons = ({
  isPartnerConnected,
  setToaster,
  partnerAccountData,
  refetchAccountingSettingData,
  disableSaveChangesBtn,
  partnerAuthData,
  partnerName,
}: {
  isPartnerConnected: boolean;
  setToaster: (_: IToasterProps) => void;
  partnerAccountData;
  refetchAccountingSettingData;
  disableSaveChangesBtn: boolean;
  partnerAuthData: IXeroAuthData;
  partnerIntegrationData: IPartnerIntegrationData;
  partnerName: string;
}) => {
  const dispatch = useDispatch();
  const {
    usingExpenseIntegration,
    usingBankFeedIntegration,
    tabsConfig,
    globalConfig,
    enabledExpenseSync,
    setSettingChangeObj,
    form,
  } = useSettingContext();
  const onlyExpenseIntegration = !usingBankFeedIntegration && usingExpenseIntegration;

  const [showDisconnectConfirmModal, setShowDisconnectConfirmModal] = useState(false);

  const [updateSetting, loadingUpdateSetting] = useLoading(updateAccountingSetting);
  const [enableSync, loadingEnableSync] = useLoading(updateExpenseSyncSetting);
  const [saveTopupAccount, loadingTopupAccount] = useLoading(updateTopupSetting);

  const saveChangesProcessing = loadingUpdateSetting || loadingTopupAccount || loadingEnableSync;

  const { disconnectLoading } = useSelector((state: any) => ({ ...state.accountingPartnerAuthReducer }));

  const disconnectBtnClickHandler = () => {
    trackEvent(ACCOUNTING_SETTING_ANALYTICS.XERO_INTEGRATION, { xero_api_event_action: "Disconnect" });
    dispatch(disconnectAccountingPartnerAction(partnerName))
      .then(() => {
        setToaster({
          message: `${partnerName} integration has been successfully disconnected.`,
          show: true,
          type: TOASTER_STATUS_TYPE.SUCCESS,
        });
        setShowDisconnectConfirmModal(false);
      })
      .catch(() => {
        setToaster({
          message: "Failed to disconnect. Please try again",
          show: true,
          type: TOASTER_STATUS_TYPE.ERROR,
        });
        setShowDisconnectConfirmModal(false);
      });
  };

  const updateTopupAccount = async () => {
    await saveTopupAccount({
      partnerFundingBankAccountId: form.getFieldValue("xero_topup_account_id"),
    });
  };

  const isUpdatingTopUpAccount = () => {
    const topupAccount = form.getFieldValue("xero_topup_account_id");
    const initialTopupAccount = partnerAccountData?.topup_bank_account_id;

    return topupAccount !== initialTopupAccount;
  };

  const saveChangesClickHandler = async () => {
    try {
      if (isUpdatingTopUpAccount() && onlyExpenseIntegration) {
        await updateTopupAccount();
      }
      if (usingBankFeedIntegration && usingExpenseIntegration !== enabledExpenseSync) {
        await enableSync(enabledExpenseSync);
        // updating the expense flag in xeroAuthReducer=>data directly so as to prevent the xeroAuth API call which causes screen refresh
        dispatch(
          updateXeroAuthData({
            ...partnerAuthData,
            payload: { ...partnerAuthData.payload, expense: enabledExpenseSync },
          })
        );
      }
      await updateSetting(mapToApiPayload(tabsConfig, form, globalConfig));
      refetchAccountingSettingData(getAccountingSetting, true);
      setToaster({
        message: "The accounting setting is successfully updated",
        show: true,
        type: TOASTER_STATUS_TYPE.SUCCESS,
      });
      setSettingChangeObj(null);
    } catch (error) {
      setToaster({ message: "Something went wrong", show: true, type: TOASTER_STATUS_TYPE.ERROR });
    }
  };

  return (
    <>
      <DisconnectConfirmationModal
        showModal={showDisconnectConfirmModal}
        loading={disconnectLoading}
        onClose={() => setShowDisconnectConfirmModal(false)}
        onDisconnect={disconnectBtnClickHandler}
        partnerName={partnerName}
      />
      <div className={styles.footerButtonContainer}>
        {isPartnerConnected && (
          <Button
            icon={DeleteFilled}
            variant="tertiary"
            onClick={() => setShowDisconnectConfirmModal(true)}
            className={styles.disconnectBtn}
          >
            Remove {partnerName} Integration
          </Button>
        )}
        <Button
          variant="primary"
          onClick={saveChangesClickHandler}
          loading={saveChangesProcessing}
          disabled={disableSaveChangesBtn}
        >
          Save Changes
        </Button>
      </div>
    </>
  );
};

export default FooterActionButtons;
