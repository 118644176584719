import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Typography } from "@spenmo/splice";

import { fetchApprovalRequestCount } from "Redux/Reducers/Approval/ApprovalCenter/approvalCenter.slice";

import { MenuItem } from "Views/Navigation/Menu/Item";
import { useTopMenuList } from "Views/Navigation/Config";
import { ISidebarMenuConfig, ISidebarSectionConfig } from "Views/Navigation/@types";

import styles from "./index.module.scss";

/**
 * The TopMenu component renders the top navigation menu based on the provided menu configuration.
 * It also fetches data for approval request count and updates it when necessary.
 */
const TopMenu: React.FC = () => {
  const dispatch = useDispatch();

  // Fetch approval request count when fundTransferFlag changes
  useEffect(() => {
    dispatch(fetchApprovalRequestCount());
  }, [dispatch]);

  const handleSectionTitle = (title: string) => {
    return (
      <Typography className={styles.title} size="caption-m" variant="body-content">
        {title}
      </Typography>
    );
  };

  const handleTopMenuList = (list: ISidebarMenuConfig[]) => {
    return list.map((menu) => <MenuItem key={menu.id} {...menu.props} ref={menu.ref} />);
  };

  const handleTopMenuSections = (sections: ISidebarSectionConfig[]) => {
    return sections.map((item) => (
      <div key={item.id} className={styles.section}>
        {handleSectionTitle(item.title)}
        {handleTopMenuList(item.list)}
      </div>
    ));
  };

  return <div className={styles.container}>{handleTopMenuSections(useTopMenuList())}</div>;
};

export default TopMenu;
