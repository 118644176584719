import React from "react";
import { Input, Form } from "antd";
import TooltipOnIcon from "Modules/TooltipOnIcon";
import { blueInfoCircle } from "assets/img";
import { capitalizeFirstLetter } from "utility";

import "./styles.scss";

export default function AccountCodeInput({ accountingSoftware }) {
  return (
    <Form.Item name="accountCode">
      <div className="account-code-input">
        <Input
          placeholder="Enter Spenmo bank account code"
          maxLength={100}
          defaultValue={localStorage.getItem("accountCode") || ""}
        />
        <TooltipOnIcon
          imageSrc={blueInfoCircle}
          altText="Spenmo account code tooltip"
          description={`The account code used for your Spenmo bank account in ${capitalizeFirstLetter(
            accountingSoftware
          )}.`}
          placement="bottom"
        />
      </div>
    </Form.Item>
  );
}
