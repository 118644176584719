export enum TYPES {
  BANK_TRANSFER = "bank_account",
  PAYNOW = "paynow",
}

export enum PAY_NOW_IDENTIFIER_TYPES {
  MOBILE = "mobile",
  NRIC = "NRIC",
}

export enum FormFields {
  type = "type",
  payNowIdentifierType = "payNowIdentifierType",
  bank_name = "bank_name",
  swift_code = "swift_code",
  platform_pomt = "platform_pomt",
  spenmo_code = "spenmo_code",
  account_name = "account_name",
  account_number = "account_number",
  mobile_number = "mobile_number",
  nric = "nric",
}

export interface PayoutFields {
  id: string;
  user_id: string;
  type: TYPES;
  bank_name?: string;
  swift_code?: string;
  platform_pomt?: string;
  spenmo_code?: string;
  account_name?: string;
  account_number?: string;
  mobile_number?: string;
  nric?: string;
}

export interface PayoutPayload extends PayoutFields {
  is_valid: number;
  updated_at: string;
}

export interface FormValues extends PayoutFields {
  payNowIdentifierType: string;
}

export interface PayoutFormProps {
  show?: boolean;
  onCloseModal(): void;
  onSubmit(): void;
  initialFormValues?: PayoutFields;
}

export interface PayoutDetailProps {
  payoutPayload: PayoutPayload;
  onClickEdit(): void;
  onClickSubmit(): void;
  newSettingPage?: boolean;
}
