import * as React from "react";
import { Button } from "antd";
import Modal, { ModalComponent } from "Modules/DS/Modal";
import { TITLE_TYPE } from "Modules/DS/Modal/types";
import { orangeTick140x150 } from "assets/icons/card";

import styles from "./index.module.scss";

export interface IUnverifiedUserHaltModalProps {
  onClose: () => void;
}

export default function UnverifiedUserHaltModal({ onClose }: IUnverifiedUserHaltModalProps) {
  return (
    <Modal visible close={onClose} className={styles.infoModal}>
      <ModalComponent.CloseButton />
      <ModalComponent.Title titleType={TITLE_TYPE.withBorder}>
        Complete Verification to Request Card
      </ModalComponent.Title>
      <div className={styles.infoContainer}>
        <img src={orangeTick140x150} alt="tick icon" />
        <div className={styles.infoText} data-testid="info-text">
          Only verified Spenmo accounts are able to be cardholders. Verify your account on the Spenmo mobile app to
          start using cards.
        </div>
        <Button className={styles.btn} size="large" onClick={onClose}>
          Got It
        </Button>
      </div>
    </Modal>
  );
}
