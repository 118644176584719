const fill_grey_16 = require("assets/v1.1/icons/Core/Info/fill_grey_16.svg");
export const fillInfo = require("assets/v1.1/icons/Core/Info/fill.svg") + "#fill";
export const info = require("assets/v1.1/icons/Core/Info/outline.svg") + "#outline";
const fill_grey_24 = require("assets/v1.1/icons/Core/Info/fill_grey_24.svg");
const line_grey_16 = require("assets/v1.1/icons/Core/Info/line_grey_16.svg");

const fill_blue = require("assets/v1.1/icons/Core/Info/fill_blue.svg");

export const Info = {
  Fill: { grey_16: fill_grey_16, grey_24: fill_grey_24, blue_18: fill_blue },
  Line: { grey_16: line_grey_16 },
};
