import React, { useMemo } from "react";
import Modal, { ModalComponent } from "Modules/DS/Modal";
import { TITLE_TYPE } from "Modules/DS/Modal/types";
import { Table } from "Modules/DS/DataTable";
import styles from "./styles.module.scss";
import { IBill, IImportBillToDraftsModalProps } from "Views/Bills/ImportBillFromXero/type";
import { ESidePanelButtonType } from "Modules/DS/SidePanel";
import { plural } from "utility";
import { PrimaryButton } from "Modules/DS/SidePanel/components/Buttons/PrimaryButton";
import { rightArrowWhiteLg } from "assets/img";
import Banner from "Modules/DS/Banner";
import { columnData } from "Views/Bills/ImportBillFromXero/ColumnData";
import { isCategoryMappingIssue, isTaxMappingIssue } from "Views/Bills/ImportBillFromXero/helper";
import { Button } from "antd";
import classNames from "classnames";
import { LoadingOutlined } from "@ant-design/icons";

const ImportBillsToDraftsModal = ({
  visible,
  mappedBills,
  setImportBillsToDraftsModal,
  importToDraftsClickHandler,
  importBillsToDraftsLoading,
}: IImportBillToDraftsModalProps) => {
  const tableProps = {
    total: mappedBills.length,
    dataSource: mappedBills,
    paginationEnabled: false,
  };

  const areMappingIssuesPresent = useMemo(
    () => mappedBills.some((bill: IBill) => isCategoryMappingIssue(bill) || isTaxMappingIssue(bill)),
    [mappedBills]
  );

  const bannerContent = (
    <div className={styles.mappingBannerContent}>
      Fields have been reassigned for one or more imported Bills
      <p>
        Data relating to the Categories and Tax Fields are reassigned inside Spenmo. These will not affect the original
        data when syncing back to Xero.
      </p>
    </div>
  );

  return (
    <Modal
      visible={visible}
      className={classNames(styles.importBillsToDraftsModal, {
        [styles.customModalMaxHeight]: mappedBills.length < (!areMappingIssuesPresent ? 5 : 3),
      })}
    >
      <ModalComponent.Title titleType={TITLE_TYPE.withBorder}>Importing Bills</ModalComponent.Title>
      <div className={styles.reviewText}>
        <strong>{mappedBills.length}</strong> {plural(mappedBills.length, "bill", "s", false)} imported from Xero to
        your bill drafts. Review and submit bill drafts to initiate payments.
      </div>
      <section className={styles.body}>
        <div className={styles.billsImportTableContainer}>
          {areMappingIssuesPresent && <Banner type="info" message={bannerContent} className={styles.bannerContainer} />}
          <Table<IBill>
            {...tableProps}
            className={classNames(styles.billsImportDataTable, {
              [styles.customTablePaddingTop]: !areMappingIssuesPresent,
            })}
          >
            {columnData(visible)}
          </Table>
        </div>
      </section>
      <div className={styles.footerButtonContainer}>
        <Button
          className={classNames(styles.backBtn, { [styles.disabledBtnText]: importBillsToDraftsLoading })}
          onClick={() => setImportBillsToDraftsModal({ show: false, mappedBills: [] })}
          id="backBtn"
          disabled={importBillsToDraftsLoading}
        >
          Back
        </Button>
        <PrimaryButton
          id="importToDrafts"
          action={importToDraftsClickHandler}
          text={
            importBillsToDraftsLoading ? (
              <LoadingOutlined spin />
            ) : (
              <span>
                Import to Drafts <img height={20} width={20} src={rightArrowWhiteLg} alt="arrow" />
              </span>
            )
          }
          type={ESidePanelButtonType.PRIMARY}
        />
      </div>
    </Modal>
  );
};

export default ImportBillsToDraftsModal;
