import React, { useContext } from "react";
import { AccountingTagsContext } from "Modules/AccountingTags/context/AccountingTagsContext";

const TagGroup = () => {
  const { setOpenSubMenu, data, setSelectedTag } = useContext(AccountingTagsContext);

  const hasSelectedChildren = (tagGroup) => {
    return !!tagGroup.selectedChildren;
  };

  return (
    <div>
      <div className="accounting-tag-group">
        <p className="accounting-tag-group__info">Select Tag Group</p>
        <ul className="accounting-tag-group__list">
          {data.map((tagGroup) => (
            <li
              className={`${hasSelectedChildren(tagGroup) ? "selected" : ""}`}
              key={tagGroup.id}
              onClick={() => {
                setOpenSubMenu(true);
                setSelectedTag(tagGroup.id);
              }}
            >
              <span>{tagGroup.label}</span>
              <svg width="8" height="12" viewBox="0 0 8 12" className="icon" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M1.81827 0.909118L6.90918 6.00003L1.81827 11.0909"
                  strokeWidth="1.81818"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default TagGroup;
