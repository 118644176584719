import React from "react";
import PropTypes from "prop-types";
import "./loginScreenWrapper.scss";
import { AuthSidebar } from "../../Views/Auth/Components";

const LoginScreenWrapper = ({ children = null, id, className }) => {
  return (
    <div className={`login-screen-wrapper ${className && className}`} id={id || ""}>
      <AuthSidebar />
      <div className={"login-screen-wrapper__container"}>{children}</div>
    </div>
  );
};

LoginScreenWrapper.propTypes = {
  children: PropTypes.any,
  id: PropTypes.string,
  className: PropTypes.string,
};

export default LoginScreenWrapper;
