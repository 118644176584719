export const AcraCorrect = require("src/assets/img/document-guidelines/acra_correct.png");
export const AcraWrong = require("src/assets/img/document-guidelines/acra_wrong.png");
export const NRICCorrect = require("src/assets/img/document-guidelines/nric_correct.png");
export const NRICWrong = require("src/assets/img/document-guidelines/nric_wrong.png");
export const OwnershipCorrect = require("src/assets/img/document-guidelines/ownership_correct.png");
export const OwnershipWrong = require("src/assets/img/document-guidelines/ownership_wrong.png");
export const PassportCorrect = require("src/assets/img/document-guidelines/passport_correct.png");
export const PassportWrong = require("src/assets/img/document-guidelines/passport-wrong.png");
export const ProofOfAddressCorrect = require("src/assets/img/document-guidelines/proof-of-address_correct.png");
export const ProofOfAddressWrong = require("src/assets/img/document-guidelines/proof-of-address_wrong.png");
export const WorkPassCorrect = require("src/assets/img/document-guidelines/work-pass_correct.png");
export const WorkPassWrong = require("src/assets/img/document-guidelines/work-pass_wrong.png");

export const DocumentGuidelines = {
  Acra: { correct: AcraCorrect, wrong: AcraWrong },
  NRIC: { correct: NRICCorrect, wrong: NRICWrong },
  Ownership: { correct: OwnershipCorrect, wrong: OwnershipWrong },
  Passport: { correct: PassportCorrect, wrong: PassportWrong },
  ProofOfAddress: { correct: ProofOfAddressCorrect, wrong: ProofOfAddressWrong },
  WorkPass: { correct: WorkPassCorrect, wrong: WorkPassWrong },
};
