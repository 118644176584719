import { useEffect, useState } from "react";

type IDisableButtonReturnValue = [boolean, (_: boolean) => void];
interface IPageStateChangeObj {
  [stateChangedKey: string]: boolean;
}

export const useDisableButton = (
  pageStateChangeObj: IPageStateChangeObj | null,
  key?: string | string[]
): IDisableButtonReturnValue => {
  const [disableButton, setDisableButton] = useState(true);

  useEffect(() => {
    // disable page action button if there is no change in the initial page state
    if (Array.isArray(key)) {
      setDisableButton(
        !(
          pageStateChangeObj &&
          Object.entries(pageStateChangeObj)
            ?.filter(([item]) => key.includes(item))
            .some(([, val]) => val === true)
        )
      );
    } else if (key) {
      setDisableButton(!pageStateChangeObj?.[key]);
    } else {
      setDisableButton(!(pageStateChangeObj && Object.values(pageStateChangeObj)?.some((val) => val === true)));
    }
  }, [pageStateChangeObj]);

  return [disableButton, setDisableButton];
};
