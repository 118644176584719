import * as React from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import { Button, Modal } from "antd";
import "./PageLeavePrompt.scss";

interface PageLeavePromptProps {
  when: boolean;
  onOK?: () => boolean;
  onCancel?: () => boolean;
  title: string;
  okText: string;
  cancelText: string;
  content: React.ReactElement | string;
}

const PageLeavePrompt: React.FC<PageLeavePromptProps> = ({
  when,
  onOK = () => {
    return true;
  },
  onCancel = () => {
    return false;
  },
  title,
  okText,
  cancelText,
  content,
}: PageLeavePromptProps) => {
  const okRef = useRef({}) as React.MutableRefObject<HTMLButtonElement>;
  const cancelRef = useRef({}) as React.MutableRefObject<HTMLButtonElement>;

  const history = useHistory();

  const [showPrompt, setShowPrompt] = useState<boolean>(false);
  const [currentPath, setCurrentPath] = useState<string>("");

  useEffect(() => {
    if (when) {
      history.block((prompt) => {
        setCurrentPath(prompt.pathname);
        setShowPrompt(true);
        return "true";
      });
    } else {
      history.block(() => undefined);
    }

    return () => {
      history.block(() => undefined);
    };
  }, [history, when]);

  const handleOK = useCallback(async () => {
    if (onOK) {
      const canRoute = await Promise.resolve(onOK());
      if (canRoute) {
        history.block(() => undefined);
        history.push(currentPath);
      }
    }
  }, [currentPath, history, onOK]);

  const handleCancel = useCallback(async () => {
    if (onCancel) {
      const canRoute = await Promise.resolve(onCancel());
      if (canRoute) {
        history.block(() => undefined);
        history.push(currentPath);
      }
    }
    setShowPrompt(false);
  }, [currentPath, history, onCancel]);

  return showPrompt ? (
    <Modal
      className="page-leave-container no-animation"
      visible={showPrompt}
      closable={true}
      footer={null}
      onCancel={() => setShowPrompt(false)}
    >
      <div className="page-leave-container__body">
        <div className="page-leave-container__title">{title}</div>
        <div className="page-leave-container__content">{content}</div>
        <div className="page-leave-container__actions">
          <Button
            ref={okRef}
            onClick={handleOK}
            className={`page-leave-container__actions__button page-leave-container__actions__button--ok`}
          >
            {okText}
          </Button>

          <Button
            ref={cancelRef}
            onClick={handleCancel}
            className={`page-leave-container__actions__button page-leave-container__actions__button--cancel`}
          >
            {cancelText}
          </Button>
        </div>
      </div>
    </Modal>
  ) : null;
};

export default PageLeavePrompt;
