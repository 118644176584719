import React from "react";

import { Toast } from "@spenmo/splice";
import { Header } from "Views/SubscriptionManagement/AutoReconciled/Header";
import AutoReconciledList from "Views/SubscriptionManagement/AutoReconciled/List";

const AutoReconciledTransactions = () => {
  return (
    <section>
      <Header />
      <AutoReconciledList />
      <Toast />
    </section>
  );
};

export default AutoReconciledTransactions;
