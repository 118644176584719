import { ALL_TRXNS_TABLE_LIMIT } from "Views/Transaction/Constants";
import {
  IBillsTrxnSuccessData,
  ICommonTrxnsFilterRequest,
  IGetBillTrxnResponse,
  IGetBillsTrxnRequest,
  IGetTrxnRequest,
  IGetTrxnResponse,
  IGetTrxnsCountRequest,
  ITrxnReceipts,
  ITrxnsDateFilterRequest,
  ITrxnsErrorData,
  ITrxnsPaginationFilterRequest,
  ITrxnsSuccessData,
} from "Views/Transaction/@types";
import { BILL_TRXNS_TABLE_ROWS } from "Views/Transaction/Constants/Bills";
import { initialData as mockBillTrxnsData } from "mockData";

export const initialTrxnDateFilterRequest = (): ITrxnsDateFilterRequest => ({
  start_date: "",
  end_date: "",
});

export const initialTrxnPaginationFilterRequest = (): ITrxnsPaginationFilterRequest => ({
  previous_page: false,
  search_after: [],
});

export const initialCommonTrxnFilterRequest = (): ICommonTrxnsFilterRequest => ({
  status: "",
  team_ids: [],
  team_names: [],
  requester_ids: [],
  requester_names: [],
  recipient_id: [],
  recipient_names: [],
  categories: [],
  search: "",
  min_amount: 0,
  max_amount: 0,
  types: [],
  attachment: null,
});

export const initialGetTrxnRequest = (): IGetTrxnRequest => ({
  limit: ALL_TRXNS_TABLE_LIMIT,
  ...initialTrxnDateFilterRequest,
  ...initialCommonTrxnFilterRequest,
  ...initialTrxnPaginationFilterRequest,
});

export const initialGetTrxnCountRequest = (): IGetTrxnsCountRequest => ({
  start_date: "",
  end_date: "",
});

export const initialTrxnReceipts: ITrxnReceipts = {
  url: "",
};

export const initialGetTrxnResponse: IGetTrxnResponse = {
  id: "",
  transaction_number: "",
  type: "",
  transaction_type: "",
  status: "",
  description: "",
  currency: "",
  amount: 0,
  previous_balance: 0,
  balance: 0,
  organisation_id: "",
  organisation_name: "",
  team_id: "",
  team_name: "",
  payer_id: "",
  payer_name: "",
  payee_id: "",
  payee_name: "",
  category: "",
  tax_amount: 0,
  notes: "",
  receipts: [],
  line_items: [],
  tags: [],
  url: "",
  sort: [0, ""],
  created_at: "",
  updated_at: "",
  foreign_exchange_rate: 0,
  foreign_currency_amount: 0,
  foreign_currency_code: "",
};

export const dummyTrxns: IGetTrxnResponse[] = Array.from({ length: ALL_TRXNS_TABLE_LIMIT }).map((_, index) => ({
  ...initialGetTrxnResponse,
  id: `DUMMY_TRXN_${index}`,
}));

export const initialTrxnsSuccess: { [key: string]: ITrxnsSuccessData } = {
  empty: {
    count: 0,
    data: [],
    has_more: false,
  },
  mocked: {
    count: dummyTrxns.length,
    data: dummyTrxns,
    has_more: false,
  },
};

export const initialTrxnsError: { [key: string]: ITrxnsErrorData } = {
  empty: {
    status: 1000,
    error: "An error occurred",
  },
  mocked: {
    status: 400,
    error: "This is a mocked error message",
  },
};

export const initialGetBillTrxnRequest = (): IGetBillsTrxnRequest => ({
  rows: BILL_TRXNS_TABLE_ROWS,
  page: 0,
});

const initialGetBillTrxnResponse = {
  id: "",
  invoiceNumber: "",
  beneficiaryName: "",
  amount: 0,
  currency: "",
  walletAmount: 0,
  walletCurrency: "",
  status: {},
  SLA: "",
  attachmentsURL: null,
  receiptsURL: [],
  transactionCode: "",
  countAttachments: 0,
  countReceipts: 0,
  dueDate: "",
  createdAt: "",
  updatedAt: "",
  clientNotes: "",
  tags: [],
  attachments: null,
  receipts: [],
  totalFeeAmount: 0,
  swiftPaymentChargeFee: 0,
  billAmountFeeInclusive: 0,
  isEnableFeeCalculator: false,
};
export const dummyBillTrxns: IGetBillTrxnResponse[] = Array.from({ length: BILL_TRXNS_TABLE_ROWS }).map((_, index) => ({
  ...initialGetBillTrxnResponse,
  id: `DUMMY_TRXN_${index}`,
}));

const teamData = {
  payload: {
    teams: [
      {
        id: "1ce09490-3b09-11ed-8ee9-0242ac1f0003",
        team_name: "LARAVEL",
        organisation_id: "7ab0682c-3fd8-4636-a2ca-9be4790f7d40",
        created_at: "2022-09-23 06:29:44",
        updated_at: "2022-09-23 06:29:44",
        team_type: "Organisation",
        total_members: 2,
      },
    ],
    total_count: 18,
    is_next_page_available: 0,
    status_message: "OK",
  },
  status: 200,
};

export const initialBillsTrxnsSuccess: { [key: string]: IBillsTrxnSuccessData } = {
  empty: {
    countAll: 0,
    list: [],
    pagination: {
      CurrentPage: 1,
      TotalPage: 0,
      TotalRow: 0,
    },
  },
  mocked: {
    countAll: dummyBillTrxns.length,
    list: dummyBillTrxns,
    pagination: {
      CurrentPage: 1,
      TotalPage: 1,
      TotalRow: 100,
    },
  },
};

initialTrxnsSuccess.mocked.data = [
  {
    id: "c6280f48-222a-11ee-9c86-0a58a9feac02",
    transaction_number: "BILL-6582YC7BJ7Xv542za5Xb0C6",
    type: "Bill Payment",
    transaction_type: "Bill Payment",
    status: "completed",
    description: "Invoice Expense - test xero banner SGD 1250.64",
    currency: "SGD",
    amount: 1250.64,
    previous_balance: 2688.92,
    balance: 1438.28,
    organisation_id: "6581aee2-f0bc-4bcc",
    organisation_name: "test org",
    team_id: "8cb33ed0-a6c5-11ed-bb1c-0246e42bd00c",
    team_name: "test team",
    payer_id: "d65f0df5-0890-40be-8f2b-0bf9e81a2922",
    payer_name: "admin ",
    payee_id: "",
    payee_name: "test recipient",
    category: "Cost of Goods Sold",
    tax_amount: 0,
    foreign_exchange_rate: 1,
    notes: "item 111",
    receipts: [{ url: "https://sample-receipt/url" }],
    line_items: [],
    tags: null,
    created_at: "2023-07-14T09:42:40Z",
    updated_at: "2023-07-14T09:44:19Z",
    sort: [1689327760000, "c6280f48-222a-11ee-9c86-0a58a9feac02"],
    url: "",
    foreign_currency_amount: 1500,
    foreign_currency_code: "USD",
  },
  {
    id: "8749176a-5d42-11ee-ad82-0a58a9feac02",
    transaction_number: "TRXSAN65143ca933e1e",
    type: "Card (Reversal)",
    transaction_type: "Card (Reversal)",
    status: "completed",
    description: "ruby r Transaction Settlement",
    currency: "SGD",
    amount: -5.08,
    previous_balance: 71156.96,
    balance: 71162.04,
    organisation_id: "427386d9-4442-4fb7-b38c-da648e7026e8",
    organisation_name: "SANITY TESTING",
    team_id: "173de726-1d55-11ed-85a1-0693a1dae4e8",
    team_name: "test team",
    payer_id: "e00501d1-d0a0-4ead-8fd9-1ce6273befa1",
    payer_name: "ruby r",
    payee_id: "",
    payee_name: "Sentry",
    category: "Default",
    tax_amount: 0,
    foreign_exchange_rate: 39.37007874015748,
    notes: "",
    receipts: null,
    line_items: [],
    tags: null,
    created_at: "2023-09-27T14:31:21Z",
    updated_at: "2023-09-27T14:31:21Z",
    sort: [1695825081000, "8749176a-5d42-11ee-ad82-0a58a9feac02"],
    url: "",
    foreign_currency_amount: 1500,
    foreign_currency_code: "USD",
  },
];

const defaultMockStructured = (payload = {}) => ({
  payload,
  status: 200,
});

export const mockAllTrxnRequest = ({ mock, customPayload = null }) => {
  const url = new RegExp(`${window.__ENV__.API_BASE_URL}/ms/spm-reporting/v1/transactions/*`);
  mock.onGet(url).reply(200, initialTrxnsSuccess.mocked);

  mock
    .onGet(window.__ENV__.API_BASE_URL + "/api/v1/org/orgid/team-list?user_id=userid&is_maintain_loggedin_team=1")
    .reply(200, teamData);

  mock.onGet(window.__ENV__.API_BASE_URL + "/ms/b2b-accounting/v2/partners/trackingCategories").reply(
    200,
    defaultMockStructured({
      data: [
        {
          label: "Department",
          values: ["Accounting", "C Office"],
        },
        {
          label: "Location",
          values: ["America", "India", "Indonesia"],
        },
      ],
    })
  );

  mock.onGet(window.__ENV__.API_BASE_URL + "/api/v1/tax?status=active").reply(
    200,
    defaultMockStructured({
      data: [
        {
          id: "9451f87a-3b09-11ed-b372-0242ac1f0003",
          tax_name: "No Tax",
          tax_rate: 0,
          status: "active",
          created_at: "2022-09-23T06:33:04.000000Z",
          updated_at: "2022-09-23T06:33:04.000000Z",
        },
        {
          id: "9451f87a-3b09-11ed-b372-0242ac1f0004",
          tax_name: "Witholding Tax",
          tax_rate: 0,
          status: "active",
          created_at: "2022-09-23T06:33:05.000000Z",
          updated_at: "2022-09-23T06:33:05.000000Z",
        },
      ],
      count: 1,
      state: "ok",
      count_inconsistent: 0,
      status_message: "OK",
    })
  );

  mock
    .onGet(window.__ENV__.API_BASE_URL + "/api/v1/user/info/userid?organisation_id=orgid")
    .reply(200, defaultMockStructured());
  mock.onGet(window.__ENV__.API_BASE_URL + "/api/v1/org/orgid/teams-with-members").reply(
    200,
    defaultMockStructured({
      teams: [
        {
          id: "0baba67f-7381-4dec-9b7f-c3c77b424ab6",
          name: "cvx",
          members: [
            {
              member_id: "3551d4a3-4b06-4811-bad7-7acb8e5cced4",
              user_id: "03702efd-ddbe-44e2-9d5d-20a785e6e671",
              role: "Manager",
              name: "session test",
            },
          ],
        },
      ],
      status_message: "OK",
    })
  );

  mock
    .onPost(window.__ENV__.API_BASE_URL + "/api/v1/accounting/transaction/bulk?organisation_id=orgid")
    .reply(200, defaultMockStructured());

  mock
    .onGet(new RegExp(`${window.__ENV__.API_BASE_URL}/ms/spm-reporting/v1/bankstatement/count*`))
    .reply(200, defaultMockStructured());
};

export const mockBillsTrxnRequest = ({ mock, customResponse = null }) => {
  mock
    .onGet(new RegExp(`${window.__ENV__.API_BASE_URL}/ms/spm-disbursement/v1/bill/*`))
    .reply(200, customResponse || mockBillTrxnsData);
};

export const mockDownloadBillsTrxns = ({ mock, customResponse = null }) => {
  mock.onGet(window.__ENV__.API_BASE_URL + "/ms/spm-disbursement/v1/bill/csv").reply(500, customResponse);
};
