import React from "react";
import dayjs from "dayjs";
import { Typography } from "@spenmo/splice";

import DatePicker from "Modules/datePicker";

import { IExpenseDateExportFilter } from "Views/Reimbursement/@types";
import { REIMBURSEMENT_QUERY_PARAMS } from "Views/Reimbursement/Constants";

import styles from "./index.module.scss";

const ExpenseDate = ({ startDate, endDate, handleOnApply }: IExpenseDateExportFilter) => {
  return (
    <>
      <Typography size="m" variant="body-content" tag="h4">
        Select date range
      </Typography>
      <div className={styles.expenseDate}>
        <div>
          <label htmlFor={REIMBURSEMENT_QUERY_PARAMS.EXPENSE_START_DATE}>
            <Typography size="s" variant="body-content" weight={600}>
              Expense Start Date
            </Typography>
          </label>
          <DatePicker
            id={REIMBURSEMENT_QUERY_PARAMS.EXPENSE_START_DATE}
            picker="date"
            showPastDates
            popupStyle={{ zIndex: "var(--layer-5)" }}
            initialValue={dayjs.unix(startDate).format("YYYY-MM-DD")}
            placeholder="Select Date"
            action={(date: string) => handleOnApply(date, REIMBURSEMENT_QUERY_PARAMS.EXPENSE_START_DATE)}
          />
        </div>
        <div>
          <label htmlFor={REIMBURSEMENT_QUERY_PARAMS.EXPENSE_END_DATE}>
            <Typography size="s" variant="body-content" weight={600}>
              Expense End Date
            </Typography>
          </label>
          <DatePicker
            id={REIMBURSEMENT_QUERY_PARAMS.EXPENSE_END_DATE}
            picker="date"
            showPastDates
            popupStyle={{ zIndex: "var(--layer-5)" }}
            initialValue={dayjs.unix(endDate).format("YYYY-MM-DD")}
            placeholder="Select Date"
            action={(date: string) => handleOnApply(date, REIMBURSEMENT_QUERY_PARAMS.EXPENSE_END_DATE)}
          />
        </div>
      </div>
    </>
  );
};

export default ExpenseDate;
