import * as React from "react";

import classNames from "classnames";
import styles from "./index.module.scss";

interface ICheckboxWrapperProps {
  checked: boolean;
  children: React.ReactNode;
  name: string;
  onClick: (checked: boolean) => void;
  className?: string;
  disabled?: boolean;
}

const CheckboxWrapper = ({
  checked,
  children,
  name,
  onClick,
  className = "",
  disabled = false,
}: ICheckboxWrapperProps) => {
  return (
    <label htmlFor={name} className={classNames([styles["category-lock-checkbox"], className])}>
      {children}
      {disabled ? null : (
        <input
          type="checkbox"
          name={name}
          id={name}
          checked={checked}
          className={styles["category-lock-checkbox__input"]}
          onClick={(event: any) => onClick(event.target.checked)}
          data-testid="checkbox"
        />
      )}
    </label>
  );
};
export default CheckboxWrapper;
