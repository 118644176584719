import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

const API_BASE_URL = window.__ENV__.API_BASE_URL;
const SENTRY_DSN = window.__ENV__.SENTRY_DSN;
const shouldEnableSentry = API_BASE_URL === "https://api.spenmo.com";
export const initSentry = () => {
  try {
    shouldEnableSentry &&
      Sentry.init({
        dsn: SENTRY_DSN,
        integrations: [new BrowserTracing()],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 0.2,
        ignoreErrors: [
          "ResizeObserver loop limit exceeded",
          "ResizeObserver loop completed with undelivered notifications.",
        ],
      });
  } catch (error: any) {
    throw Error("Sentry initialisation failed");
  }
};
