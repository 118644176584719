import * as React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useQuery, getParamsValue } from "utility";
import { Redirect, useLocation } from "react-router-dom";
import { trackEvent } from "utility/analytics";
import { useIsManagerOrAdmin } from "customHooks/Card";
import { getCardDetailsData } from "Views/Card/List/Utilities";
import { getCardDetails } from "Redux/DataCalls/Cards.api";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import useCheckFeatureStatus from "customHooks/featureCheck";
import { SPLIT_NAMES, SPLIT_TREATMENT_TYPES } from "Redux/splitio/constants";
import { useIsSaasWithPaymentsOrg } from "customHooks/useIsSaasWithPaymentsOrg";
import { useIsSessionExpired } from "customHooks/Auth/useIsSessionExpired";

import CardListContext from "Views/Card/List/index.context";
import { useCardListData } from "Views/Card/List/Utilities/UseCardListData";
import AddCard from "Views/Card/Add";
import CardDetailsOrEditModal from "Views/Card/List/Details";
import CardListNotifications from "Views/Card/List/Notifications";
import { cardListingTableEvent, CardListingEventNames, CardSelectedEventName } from "Views/Card/Analytics";
import { CARD_LISTING_PERMISSION_PARAMS } from "Views/Card/Permission";
import CardFilter from "Views/Card/List/Filters";
import CardTable from "Views/Card/List/Table";
import CardTransactions from "Views/Card/Transactions";
import LoadingComponent from "Views/State/Loading/LoaderIcon";

import {
  IFilter,
  CARD_LIST_TABS,
  CardDetailsType,
  CARD_DRAWER_TYPES,
  CARD_DETAILS_TABS,
  URL_PARAMS_KEYS,
  CARD_LIST_ACTIVE_TAB_URL_PARAM_VALUES,
} from "./types";

import "Views/Card/List/index.scss";

import usePermissionCheck from "Permission/usePermissionCheck";
import Tabs, { ListData } from "Modules/DS/Tabs";
import { useCardDeleteModal } from "Views/Card/List/Utilities/UseCardDeleteModal";
import { useCardActivateModal } from "Views/Card/List/Utilities/UseActivateCardModal";
import { useEnableOrDisableCard } from "Views/Card/List/Utilities/UseEnableOrDisable";
import { UpsellingSection } from "Views/Card/Upselling";

const DEFAULT_FILTERS = {
  search_filters: {},
  team_id: [],
  ordering: {},
};

const apiPaginationStartIndex = 0;
const paginatorStartIndex = 1;

const CardListing = () => {
  const IS_ALLOWED_SENT_CARD_REQUEST_TAB: any = usePermissionCheck(CARD_LISTING_PERMISSION_PARAMS) || [];
  const isCardsTrxnsTabEnabled = useCheckFeatureStatus(SPLIT_NAMES.cardsTrxns) === SPLIT_TREATMENT_TYPES.ON;
  const enabledUpsellingCards = useCheckFeatureStatus(SPLIT_NAMES.upsellingCard, true);

  const isSaasWithPaymentsOrg = useIsSaasWithPaymentsOrg();
  const isSessionExpired = useIsSessionExpired();

  const createCardData = useSelector((state: any) => state?.createCardReducer || {});
  const bulkChangeStatusData = useSelector((state: any) => state?.cardActivateBulkReducer || {});
  const { name = "" } = useSelector((state: any) => state?.userInfoReducer?.data?.payload?.user || {});
  const [isAdmin, isManager] = useIsManagerOrAdmin();
  const [cardListData, receivedCardRequestsData, sentCardRequestsData, fetchCardListData] = useCardListData();

  const location: any = useLocation();
  const query = useQuery();
  const defaultCardholderFilter = query.get("defaultCardholderFilter") === "true";
  const cardListActiveTabFromUrl = query?.get(URL_PARAMS_KEYS.CARD_LIST_ACTIVE_TAB) || "";

  const [filters, setFilters] = React.useState<IFilter>(() => {
    let search_filters = {};
    if (defaultCardholderFilter && (isManager || isAdmin)) {
      search_filters = name
        ? {
            cardholder_name: [name],
          }
        : {};
    }
    return {
      ...DEFAULT_FILTERS,
      search_filters,
    };
  });
  const [cardListActiveTab, setCardListActiveTab] = React.useState(CARD_LIST_TABS.CARD_LIST);
  const [cardDetails, setCardDetails] = React.useState<CardDetailsType>(null);
  const [selectedRows, setSelectedRows] = React.useState<any[]>([]);
  const [currentPage, setCurrentPage] = React.useState(apiPaginationStartIndex);

  const getCardListData = (page: number, view?: string | string[]) => fetchCardListData(page, filters, view);

  const [setShowCardDeleteModal, deleteInProgressCards, deleteModal] = useCardDeleteModal(getCardListData);
  const [setShowCardActivationModal, activationInProgressCards, activationModal] =
    useCardActivateModal(getCardListData);
  const [enableOrDisableCard, enableOrDisableInProgressCards, disableConfirmModal] =
    useEnableOrDisableCard(getCardListData);

  useEffect(() => {
    const ITEMS_IN_ONE_PAGE = 10;
    if (selectedRows?.length === ITEMS_IN_ONE_PAGE) {
      trackEvent(cardListingTableEvent.SELECT_ALL_ROW);
    }
  }, [selectedRows]);

  const handleRowClick = (record) => {
    if (cardListActiveTab === CARD_LIST_TABS.SENT_CARD_REQUEST) {
      setCardDetails({
        drawerType: CARD_DRAWER_TYPES.DETAILS,
        data: record,
        activeTab: null,
      });
    } else if (cardListActiveTab === CARD_LIST_TABS.CARD_LIST) {
      setCardDetails({
        drawerType: CARD_DRAWER_TYPES.DETAILS,
        data: record,
        activeTab: CARD_DETAILS_TABS.DETAILS,
      });
      trackEvent(CardSelectedEventName, {
        card_id: record?.id,
        card_select_event_source: "Card Page",
      });
    }
  };

  const isNotFiltered = (filtersData) => {
    if (filtersData?.team_id?.length > 0) {
      return false;
    }
    const filterLength = Object.keys(filtersData?.search_filters)?.length;
    return !filterLength || (filterLength === 1 && filtersData?.search_filters?.card_search === "");
  };

  const trackCardPageLoaded = (tabName: string) => {
    trackEvent(CardListingEventNames.CARD_PAGE_TAB_LOADED, {
      card_tab_event_source: tabName,
    });
  };

  const changeCardListActiveTab = (tab: CARD_LIST_TABS) => {
    setCardListActiveTab(tab);
    if (tab !== CARD_LIST_TABS.CARD_TRXNS) {
      fetchCardListData(apiPaginationStartIndex, DEFAULT_FILTERS, tab);
      setFilters(DEFAULT_FILTERS);
      setCurrentPage(apiPaginationStartIndex);
      trackCardPageLoaded(tab);
    }
  };

  const setActiveCardListTabFromUrl = () => {
    if (cardListActiveTabFromUrl === CARD_LIST_ACTIVE_TAB_URL_PARAM_VALUES.SENT_CARD_REQUESTS && !isAdmin) {
      setCardListActiveTab(CARD_LIST_TABS.SENT_CARD_REQUEST);
      trackCardPageLoaded(CARD_LIST_TABS.SENT_CARD_REQUEST);
      fetchCardListData(apiPaginationStartIndex, filters, CARD_LIST_TABS.SENT_CARD_REQUEST);
    } else if (
      cardListActiveTabFromUrl === CARD_LIST_ACTIVE_TAB_URL_PARAM_VALUES.CARD_TRXNS &&
      isCardsTrxnsTabEnabled
    ) {
      setCardListActiveTab(CARD_LIST_TABS.CARD_TRXNS);
    } else {
      fetchCardListData(apiPaginationStartIndex, filters, CARD_LIST_TABS.CARD_LIST);
      trackEvent(cardListingTableEvent.ALL_CARD_VIEWED);
    }
  };
  const [tab] = getParamsValue("tab");

  const getCardDetailsFromCardIdInUrl = () => {
    const [cardId, cardDrawerType] = getParamsValue(URL_PARAMS_KEYS.CARD_ID, URL_PARAMS_KEYS.CARD_DRAWER_TYPE);
    if (cardId) {
      getCardDetails(cardId)?.then((response) => {
        const { status = "", payload = {} } = response?.data || {};
        if (status === HTTP_STATUS_CODE.OK && payload?.card) {
          setCardDetails({
            drawerType: cardDrawerType === CARD_DRAWER_TYPES.EDIT ? CARD_DRAWER_TYPES.EDIT : CARD_DRAWER_TYPES.DETAILS,
            data: getCardDetailsData(payload?.card, false),
            activeTab: null,
          });
        }
      });
    }
  };

  React.useEffect(() => {
    setActiveCardListTabFromUrl();
    if (cardListActiveTabFromUrl !== CARD_LIST_ACTIVE_TAB_URL_PARAM_VALUES.CARD_TRXNS) {
      if (location && location?.state?.card) {
        setCardDetails({
          drawerType: CARD_DRAWER_TYPES.EDIT,
          data: getCardDetailsData(location.state.card, false),
          activeTab: CARD_DETAILS_TABS.SETTINGS,
        });
      }
      getCardDetailsFromCardIdInUrl();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTotalCount = () => {
    let count = 0;
    switch (true) {
      case cardListActiveTab === CARD_LIST_TABS.CARD_LIST:
        count = cardListData?.data?.payload?.total_count || 0;
        break;
      case cardListActiveTab === CARD_LIST_TABS.RECEIVED_CARD_REQUEST:
        count = receivedCardRequestsData?.data?.payload?.total_count || 0;
        break;
      case cardListActiveTab === CARD_LIST_TABS.SENT_CARD_REQUEST:
        count = sentCardRequestsData?.data?.payload?.total_count || 0;
        break;
    }
    return count;
  };

  const bulkChangeStatusOrCreateCardDataInProgress = bulkChangeStatusData.loading || createCardData.loading;

  const addAdditionalTabs = () => {
    const cardsTabList = [];
    if (isCardsTrxnsTabEnabled) {
      cardsTabList.push(CARD_LIST_TABS.CARD_TRXNS);
    }
    if (IS_ALLOWED_SENT_CARD_REQUEST_TAB) {
      cardsTabList.push(CARD_LIST_TABS.SENT_CARD_REQUEST);
    }

    return cardsTabList;
  };

  const tabs: CARD_LIST_TABS[] = [CARD_LIST_TABS.CARD_LIST, ...addAdditionalTabs()];
  const tabToTitleArr: ListData[] = tabs.map((tab) => {
    return {
      title: tab,
    };
  });

  const getFormattedCardListingData = (data: any, isCardRequestDetails: boolean) => {
    if (Array.isArray(data) && data?.length) {
      return data.map((cardData) => getCardDetailsData(cardData, isCardRequestDetails));
    }
    return [];
  };

  const cardsListTabsData = {
    [CARD_LIST_TABS.CARD_LIST]: getFormattedCardListingData(cardListData?.data?.payload?.cards, false),
    [CARD_LIST_TABS.RECEIVED_CARD_REQUEST]: getFormattedCardListingData(
      receivedCardRequestsData?.data?.payload?.card_requests,
      true
    ),
    [CARD_LIST_TABS.SENT_CARD_REQUEST]: getFormattedCardListingData(
      sentCardRequestsData?.data?.payload?.card_requests,
      true
    ),
  };
  const contextValue = {
    cardListLoading: cardListData?.loading || receivedCardRequestsData?.loading || sentCardRequestsData?.loading,
    bulkChangeStatusOrCreateCardDataInProgress,
    canAddCards: isAdmin || isManager,
    selectedRows,
    handleRowClick,
    setSelectedRows,
    totalCount: getTotalCount(),
    currentPage,
    getCardListData,
    setCurrentPage,
    paginatorStartIndex,
    isNotFiltered,
    filters,
    tabs,
    cardListActiveTab,
    changeCardListActiveTab,
    cardsListTabsData,
    selectedRowId: cardDetails?.data?.id || "",
    cardDetails,
    setCardDetails,
    setShowCardDeleteModal,
    deleteInProgressCards,
    setShowCardActivationModal,
    activationInProgressCards,
    enableOrDisableCard,
    enableOrDisableInProgressCards,
  };
  const handleChangeTag = (view: any) => {
    changeCardListActiveTab(view);
  };

  const CardOrSentCardRequestListing = (
    <CardListContext.Provider value={contextValue}>
      <CardFilter
        showCardownerFilter={(isAdmin || isManager) && cardListActiveTab !== CARD_LIST_TABS.SENT_CARD_REQUEST}
        currentFilters={filters}
        action={(appliedFilters) => {
          setFilters(appliedFilters);
          fetchCardListData(apiPaginationStartIndex, appliedFilters, cardListActiveTab);
          setCurrentPage(apiPaginationStartIndex);
        }}
        cardListActiveTab={cardListActiveTab}
      />

      <CardTable type={cardListActiveTab} />

      <CardDetailsOrEditModal
        cardDetails={cardDetails}
        setCardDetails={setCardDetails}
        cardDetailsInitialTab={(tab as CARD_DETAILS_TABS) || CARD_DETAILS_TABS.DETAILS}
      />
    </CardListContext.Provider>
  );

  if (enabledUpsellingCards === SPLIT_TREATMENT_TYPES.CONTROL) {
    return <LoadingComponent />;
  }

  if (enabledUpsellingCards === SPLIT_TREATMENT_TYPES.ON) {
    return <UpsellingSection />;
  }

  if (!isSaasWithPaymentsOrg && !isSessionExpired) {
    return <Redirect to="/" />;
  }

  return (
    <CardListContext.Provider value={contextValue}>
      <div className="cards-listing">
        <div className="cards-listing__header">
          <h1>Cards</h1>
          <div className="cards-listing__action">
            <Tabs
              activeKey={cardListActiveTab}
              defaultActiveKey={tabs[0].toString()}
              action={handleChangeTag}
              tabsList={tabToTitleArr}
              content={() => null}
            />
            <AddCard
              onFinish={(isAdminOrManager: boolean): void => {
                changeCardListActiveTab(isAdminOrManager ? CARD_LIST_TABS.CARD_LIST : CARD_LIST_TABS.SENT_CARD_REQUEST);
              }}
            />
          </div>
        </div>
        {cardListActiveTab === CARD_LIST_TABS.CARD_TRXNS ? <CardTransactions /> : CardOrSentCardRequestListing}
      </div>
      {deleteModal}
      {activationModal}
      {disableConfirmModal}
      <CardListNotifications />
    </CardListContext.Provider>
  );
};

export default CardListing;
