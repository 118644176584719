export const brazeIdentityEventReservedConstants = [
  {
    segmentKeyName: "firstName",
    brazeSetterMethodName: "setFirstName",
  },
  {
    segmentKeyName: "lastName",
    brazeSetterMethodName: "setLastName",
  },
  {
    segmentKeyName: "phone",
    brazeSetterMethodName: "setPhoneNumber",
  },
  {
    segmentKeyName: "email",
    brazeSetterMethodName: "setEmail",
  },
];
