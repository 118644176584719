import React, { useState } from "react";
import cn from "classnames";
import { Controller } from "react-hook-form";

import { Checkbox, InfoOutline, Input, Tooltip } from "@spenmo/splice";
import { Field, LabelWithTooltip, Select, ErrorMessage } from "Views/SubscriptionManagement/Create/Components";

import { formFieldHasRequiredError } from "Views/SubscriptionManagement/Create/Helper";
import { useCurrency } from "Views/SubscriptionManagement/hooks/useCurrency";
import { useFormatAmount } from "Views/SubscriptionManagement/Create/Hooks/useFormatAmount";
import { useFormContext } from "Views/SubscriptionManagement/Create/Context";
import { option } from "Views/SubscriptionManagement/Create/options";
import { FORM_NAMES } from "Views/SubscriptionManagement/Constants";

import styles from "Views/SubscriptionManagement/Create/styles.module.scss";

export const Amount = () => {
  const { control, setValue, errors, watch } = useFormContext();
  const [amountVariates, setAmountVariates] = useState(control?._formValues?.[FORM_NAMES.VARIATION_PERCENTAGE] !== 0);
  const { data } = useCurrency();

  const hasError = formFieldHasRequiredError(errors, FORM_NAMES.ESTIMATED_AMOUNT);
  const [currency, estimated_amount] = watch([FORM_NAMES.CURRENCY, FORM_NAMES.ESTIMATED_AMOUNT]);

  const { getFormattedAmount, onChangeModifier } = useFormatAmount(currency);

  return (
    <>
      <Field>
        <LabelWithTooltip
          title="Estimated Cost"
          // added space in b/w as per design, please don't edit
          tooltipTitle={`This amount is just used for reporting purposes and does not actually block payments. You will be notified if the actual amount exceeds the estimated amount.

          Please note to include tax in this amount.`}
        />
        <div className={styles.currencyAndAmount}>
          <Select
            name={FORM_NAMES.CURRENCY}
            control={control}
            showSearch
            className={styles.currency}
            options={option.currency(data)}
          />

          <Controller
            name={FORM_NAMES.ESTIMATED_AMOUNT}
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Input.Text
                {...field}
                className={cn(styles.input, styles.amount)}
                name={FORM_NAMES.ESTIMATED_AMOUNT}
                type="text"
                placeholder="Enter amount"
                onChange={(e) => onChangeModifier(e, field.onChange)}
                value={getFormattedAmount(estimated_amount)}
              />
            )}
          />
        </div>
        <div className={styles.checkboxWithTooltip}>
          <Checkbox
            checked={amountVariates}
            onChange={() => {
              setAmountVariates((prevState) => {
                setValue(FORM_NAMES.VARIATION_PERCENTAGE, prevState ? 0 : option.vary[0].value);
                return !prevState;
              });
            }}
          >
            <span className={styles.checkbox}>This amount can vary {amountVariates && "by"}</span>{" "}
          </Checkbox>

          {amountVariates && (
            <Select
              name={FORM_NAMES.VARIATION_PERCENTAGE}
              control={control}
              className={styles.vary}
              options={option.vary}
            />
          )}
          <Tooltip
            placement="bottom"
            title="The variation is added to the estimated cost and and we use the range for reporting and sending reminders. For example, if your variation is 10% on SGD 1,000 estimated cost, the payment range would be from SGD 900 to SGD 1,100."
          >
            <InfoOutline size="16" fill="var(--black-030)" />
          </Tooltip>
        </div>
        {hasError && <ErrorMessage message="Enter an amount" />}
      </Field>
    </>
  );
};
