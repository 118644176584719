import React from "react";
import PropTypes from "prop-types";
import LoadingIcon from "Modules/loading";
import styles from "./overlayLoaderV2.module.scss";

const OverlayLoaderV2 = ({ title, subtitle }) => {
  return (
    <div className={styles.outer}>
      <div className={styles.inner}>
        <LoadingIcon />
        <div className={styles.title}>{title}</div>
        {subtitle}
      </div>
    </div>
  );
};

OverlayLoaderV2.defaultProps = { title: "", subtitle: "" };

OverlayLoaderV2.propTypes = { title: PropTypes.string, subtitle: PropTypes.string };

export default OverlayLoaderV2;
