import React from "react";
import { Select as AntdSelect } from "antd";
import { SelectProps } from "antd/lib/select";
import cn from "classnames";
import { Controller } from "react-hook-form";

import { Checkbox, ChevronDown, InfoOutline, Input, Tooltip, Typography, WarningFilled } from "@spenmo/splice";
import LoadingComponent from "Views/State/Loading/LoaderIcon";

import { FORM_NAMES } from "Views/SubscriptionManagement/Constants";
import { useFormContext } from "Views/SubscriptionManagement/Create/Context";
import { formFieldHasRequiredError } from "Views/SubscriptionManagement/Create/Helper";
import { useSubscriptionManagementForm } from "./form";

import styles from "./styles.module.scss";

const { Option } = AntdSelect;

export const Field = ({ children, twoRow }: { children: React.ReactNode; twoRow?: boolean }) => {
  return <div className={cn(twoRow ? styles.twoRowField : styles.field)}>{children}</div>;
};

export const Select = ({
  control,
  name,
  placeholder,
  options,
  showSearch = false,
  className,
  defaultValue,
  notFoundContent,
  onSearch,
  onChange,
  value,
  requiredErrorMessage,
  loading,
  disabled,
  ...rest
}: {
  options: {
    id: string | number;
    value: string | number;
    children: React.ReactNode;
    label?: string;
  }[];
  name: FORM_NAMES;
  onChange?: (value: string) => void;
  requiredErrorMessage?: string;
} & Pick<ReturnType<typeof useSubscriptionManagementForm>, "control"> &
  SelectProps<any>) => {
  const { errors } = useFormContext();
  const hasError = requiredErrorMessage && formFieldHasRequiredError(errors, name);

  return (
    <>
      <Controller
        name={name}
        control={control}
        rules={{ required: Boolean(requiredErrorMessage) }}
        render={({ field }) => (
          <AntdSelect
            {...field}
            showSearch={showSearch}
            getPopupContainer={(triggerNode) => triggerNode.parentElement}
            placeholder={placeholder}
            className={cn(className)}
            defaultValue={defaultValue || field.value}
            onSearch={onSearch}
            onChange={(value) => {
              onChange?.(value);
              field.onChange(value);
            }}
            value={loading ? undefined : value}
            notFoundContent={loading ? <LoadingComponent /> : notFoundContent}
            suffixIcon={disabled ? null : <ChevronDown size="24" fill="var(--black-070)" className={styles.rotate} />}
            disabled={disabled}
            {...rest}
          >
            {options.map(({ id, value, children, label }) => (
              <Option key={id} value={value} label={label}>
                {children}
              </Option>
            ))}
          </AntdSelect>
        )}
      />
      {hasError && <ErrorMessage message={requiredErrorMessage} />}
    </>
  );
};

export const LabelWithTooltip = ({
  title,
  tooltipTitle,
  className,
}: {
  title: string | React.ReactNode;
  tooltipTitle: string;
  className?: string;
}) => {
  return (
    <Input.Label className={cn(styles.labelWithTooltip, { [className]: className })}>
      {title}
      <Tooltip placement="bottom" title={tooltipTitle}>
        <InfoOutline size="16" fill="var(--black-030)" />
      </Tooltip>
    </Input.Label>
  );
};

export const CheckboxWithTooltip = ({
  checkboxChildren,
  tooltipText,
  onChange,
  checked,
}: {
  checkboxChildren: string | React.ReactNode;
  tooltipText: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  checked?: boolean;
}) => {
  return (
    <div className={styles.checkboxWithTooltip}>
      <Checkbox onChange={onChange} checked={checked}>
        {checkboxChildren}
      </Checkbox>
      <Tooltip placement="bottom" title={tooltipText}>
        <InfoOutline size="16" fill="var(--black-030)" />
      </Tooltip>
    </div>
  );
};

export const ErrorMessage = ({ message, warning = false }: { message: string; warning?: boolean }) => (
  <Typography
    variant="body-content"
    size="caption-m"
    className={cn(styles.errorMessage, { [styles.warning]: warning })}
  >
    <WarningFilled size="16" iconColor="var(--red-050)" />
    {message}
  </Typography>
);
