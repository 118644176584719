import React from "react";

import { IColumnData } from "Modules/DS/DataTable";

import { InputCell } from "Views/Transaction/Shimmer";
import { CellWithSubtitle } from "Views/Transaction/List";
import { ALL_TRXNS_COLUMNS, TRANSACTION_TYPES } from "Views/Transaction/Constants";
import { IGetTrxnResponse, GET_TRXN_RESPONSE_PARAMS } from "Views/Transaction/@types";

export const RecipientCell = ({ children }: { children: IGetTrxnResponse }) => {
  let title: string = children.type === TRANSACTION_TYPES.TOPUP ? children.organisation_name : children.payee_name;

  return (
    <InputCell>
      <CellWithSubtitle title={title || "N.A."} />
    </InputCell>
  );
};

export const recipient = (): IColumnData<IGetTrxnResponse>["column"] => ({
  title: ALL_TRXNS_COLUMNS.RECIPIENT,
  key: GET_TRXN_RESPONSE_PARAMS.PAYEE_ID,
  dataIndex: GET_TRXN_RESPONSE_PARAMS.PAYEE_ID,
  render: (_, record: IGetTrxnResponse) => <RecipientCell>{record}</RecipientCell>,
});
