import React, { useEffect } from "react";

import { getCookieValue, cookieNames } from "utility/CookieHelper";

import { AMOUNT_RANGE, IInputRangeProps } from "Modules/DS/Select";

import styles from "./Input.module.scss";

export const InputRangeSelector = <T extends object = any>({ filter, onApply, clear }: IInputRangeProps<T>) => {
  const orgCurrencyCode = getCookieValue(cookieNames.CURRENCY_CODE);

  useEffect(() => {
    if (clear) {
      (document.getElementById("input-range-selector__min") as HTMLInputElement).value = "";
      (document.getElementById("input-range-selector__max") as HTMLInputElement).value = "";
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clear]);

  return (
    <div className={styles.amount}>
      <div>
        <label>Min:</label>
        <input
          id="input-range-selector__min"
          type="number"
          value={filter?.[AMOUNT_RANGE.MIN] || ""}
          placeholder={`${orgCurrencyCode} 0`}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onApply(AMOUNT_RANGE.MIN, e.target.value.length ? Number(e.target.value) : undefined)
          }
        />
      </div>
      <div>
        <label>Max:</label>
        <input
          id="input-range-selector__max"
          type="number"
          value={filter?.[AMOUNT_RANGE.MAX] || ""}
          placeholder={`${orgCurrencyCode} 0`}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onApply(AMOUNT_RANGE.MAX, e.target.value.length ? Number(e.target.value) : undefined)
          }
        />
      </div>
    </div>
  );
};
