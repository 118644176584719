import React, { useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import qs from "query-string";
import { Loader } from "@spenmo/splice";
import { InputProps } from "antd/lib/input";
import { useParams } from "react-router-dom";

import { getData } from "API/Client";

import Input from "../Input";

import { API_URL, BillFormType, BillParams } from "Views/Bills/V2/constants";
import { useErrorHandler } from "Views/Bills/V2/context/ErrorHandlerContext";

const InvoiceNumber = (props: InputProps) => {
  const { name, value } = props;

  const params = useParams<BillParams>();

  const isNewBill = useMemo(() => params.form === BillFormType.NEW, [params]);

  const { setError, register, clearErrors, formState, watch } = useFormContext();
  const { handleError, handleDeleteError } = useErrorHandler();
  const { defaultValues = null } = formState;
  const { billNumber: existingBillNumber = null } = defaultValues;

  const [isFocus, setIsFocus] = useState(false);
  const [loading, setLoading] = useState(false);

  const vendorID = watch("vendorID");

  const fetchVInvoiceValidation = (billNumber: string | number | readonly string[]) => {
    if (value && value !== existingBillNumber && vendorID) {
      setLoading(true);
      const url = qs.stringifyUrl({
        url: API_URL.validateInvoiceNumber,
        query: {
          billNumber,
          vendorID,
        },
      });

      return getData(url)
        .then((res) => {
          const { isDuplicateBillExisted } = res.data.payload;

          if (isDuplicateBillExisted) {
            setError(name, {
              type: "blur",
              message:
                "Invoice number already exists for this recipient. Please enter a new invoice number to proceed or contact admin to review past bills.",
            });
            return "Invoice number already exists for this recipient. Please enter a new invoice number to proceed or contact admin to review past bills.";
          }

          return true;
        })
        .catch(() => {
          handleError({
            retry: {
              id: "invoiceNumberValidation",
              onClickRetry: () => fetchVInvoiceValidation(billNumber),
            },
          });
          return true;
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      handleDeleteError("invoiceNumberValidation");
      return true;
    }
  };

  const handleOnBlur = async () => {
    setIsFocus(false);
    clearErrors(name);
    if (isNewBill) {
      fetchVInvoiceValidation(value);
    }
  };

  return (
    <Input
      {...register("billNumber", {
        validate: async (value) => !isNewBill || isFocus || (await fetchVInvoiceValidation(value)),
      })}
      {...props}
      onFocus={() => setIsFocus(true)}
      onBlur={handleOnBlur}
      suffix={loading && <Loader.Spinner size="s" variant="brand" />}
    />
  );
};

export default InvoiceNumber;
