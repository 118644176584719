import React from "react";
import { Skeleton } from "antd";
import LimitExpenseCard from "Views/Home/Components/ExpenseCard/LimitExpenseCard";
import WalletExpenseCard from "Views/Home/Components/ExpenseCard/WalletExpenseCard";
import { ExpenseShimmer } from "Views/Home/Components/ExpenseCard/Common/ExpenseShimmer";
import { useIsSaasWithPaymentsOrg } from "customHooks/useIsSaasWithPaymentsOrg";
import "Views/Home/Components/ExpenseCard/ExpenseCard.scss";

const ExpenseCard = ({ expenseDetails, limitModel = false, isLoading = false }) => {
  const isPaymentEnabled = useIsSaasWithPaymentsOrg();
  const getExpenseCards = () => {
    if (isLoading) {
      return <ExpenseShimmer limitModel={limitModel} />;
    } else {
      return limitModel ? <LimitExpenseCard {...expenseDetails} /> : <WalletExpenseCard {...expenseDetails} />;
    }
  };

  // SaaS only hard coded
  const isNameHardCoded = expenseDetails?.teamOrg === "Organisation" && !isPaymentEnabled;
  const teamName = isNameHardCoded ? "Default" : expenseDetails?.teamName;

  return (
    <div className={`expense-card__container expense-card__container--${limitModel ? "limit" : "wallet"}-model`}>
      <p className="expense-card__team-name">{isLoading ? <Skeleton.Input className="title" active /> : teamName}</p>
      <div className="expense-card__expense-details-container">{getExpenseCards()}</div>
    </div>
  );
};

export default ExpenseCard;
