import APIclient from "API/Client";

import { clearCookies } from "utility";

import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import { AuthenticationButtonActions, LoginResponseResult, AuthActionEventSource } from "Views/Auth/Analytics";
import { trackEvent } from "utility/analytics";

export const FETCH_FORGOT_REQUEST = "FETCH_FORGOT_REQUEST";
export const FETCH_FORGOT_SUCCESS = "FETCH_FORGOT_SUCCESS";
export const FETCH_FORGOT_FAILURE = "FETCH_FORGOT_FAILURE";
export const RESET_FORGOT_DATA = "RESET_FORGOT_DATA";

export const fetchDataReq = () => {
  return {
    type: FETCH_FORGOT_REQUEST,
  };
};
export const fetchDataSuccess = (data) => {
  return {
    type: FETCH_FORGOT_SUCCESS,
    data: data,
  };
};
export const fetchDataError = (data) => {
  return {
    type: FETCH_FORGOT_FAILURE,
    data: data,
  };
};

export const resetForgotData = () => {
  return {
    type: RESET_FORGOT_DATA,
  };
};

const triggerAuthTrackEvent = (status) => {
  trackEvent(AuthenticationButtonActions.ACCOUNT_AUTHENTICATION_BUTTON_CLICKED, {
    authentication_event_source: AuthActionEventSource.FORGOT_PASSWORD,
    authentication_event_action: status,
  });
};

export const forgotFunc = (email = "") => {
  return (dispatch) => {
    clearCookies("auth_token");

    dispatch(fetchDataReq());
    APIclient.postData("/ms/b2b-auth/v2/auth/forgot-password/request", {
      email: email.trim(),
    })
      .then((data) => {
        if (data.status === HTTP_STATUS_CODE.OK) {
          dispatch(fetchDataSuccess(data?.data?.data));
          triggerAuthTrackEvent(LoginResponseResult.SUCCESS);
        } else {
          dispatch(fetchDataError(data?.data?.data));
          triggerAuthTrackEvent(LoginResponseResult.FAIL);
        }
      })
      .catch((error) => {
        dispatch(fetchDataError(error?.response?.data?.errors || error?.message));
        triggerAuthTrackEvent(LoginResponseResult.FAIL);
      });
  };
};
