import React from "react";
import { Form, Input } from "antd";
import { Rule } from "antd/lib/form";

export const FormEmail = ({
  onKeyUp,
}: {
  onKeyUp?: () => void;
}): React.ReactElement => {
  const rules: Rule[] = [
    {
      required: true,
      message: "This field cannot be empty",
    },
  ];

  return (
    <Form.Item label="Email" name="email" rules={rules}>
      <Input
        placeholder="Enter email address"
        autoFocus
        onKeyUp={onKeyUp}
        autoComplete="off"
      />
    </Form.Item>
  );
};
