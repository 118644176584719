import * as React from "react";
import {
  activatePhysicalCardIcon48x48,
  requestPhysicalCardIcon48x48,
  createVirtualCardIcon48x48,
  requestVirtualCardIcon48x48,
} from "assets/icons/card";
import { CARD_USER_VENDOR } from "Views/Card/types";

export enum AddCardTypes {
  CreateVirtualCard = "CREATE_VIRTUAL_CARD",
  RequestVirtualCard = "REQUEST_VIRTUAL_CARD",
  RequestPhysicalCard = "REQUEST_PHYSICAL_CARD",
  ActivatePhysicalCard = "ACTIVATE_PHYSICAL_CARD",
  UnverifedUserCardRequest = "UNVERIFIED_USER_CARD_REQUEST",
}

export type addCardTypeConfig = Array<{ label: JSX.Element; icon: string; cardType: AddCardTypes }>;

export const getAddCardTypeConfig = (isManagerOrAdmin: boolean, cardVendor: string): addCardTypeConfig => {
  const requestPhysicalCardConfig = cardVendor !== CARD_USER_VENDOR.MATCHMOVE && {
    label: (
      <p>
        Request
        <br /> Physical Card
      </p>
    ),
    icon: requestPhysicalCardIcon48x48,
    cardType: AddCardTypes.RequestPhysicalCard,
  };

  return [
    {
      label: isManagerOrAdmin ? (
        <>
          <p>
            Create
            <br /> Virtual Card
          </p>
        </>
      ) : (
        <>
          <p>
            Request
            <br /> Virtual Card
          </p>
        </>
      ),
      icon: isManagerOrAdmin ? createVirtualCardIcon48x48 : requestVirtualCardIcon48x48,
      cardType: isManagerOrAdmin ? AddCardTypes.CreateVirtualCard : AddCardTypes.RequestVirtualCard,
    },
    requestPhysicalCardConfig,
    {
      label: (
        <p>
          Activate
          <br /> Physical Card
        </p>
      ),
      icon: activatePhysicalCardIcon48x48,
      cardType: AddCardTypes.ActivatePhysicalCard,
    },
  ].filter(Boolean);
};
