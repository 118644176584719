import React from "react";
import { AlignType } from "rc-table/lib/interface";
import { IColumnData } from "Modules/DS/DataTable";
import { dateTimeWithTimezone, intlDateTimeFormat, intlTimeFormat } from "utility";
import { PAYMENT_COLUMNS } from "Views/SubscriptionManagement/Constants";
import { TitleWithSubTitle } from "Views/SubscriptionManagement/List/Columns/Components";
import { GET_TRXN_RESPONSE_PARAMS, IGetTrxnResponse } from "Views/Transaction/@types";
import { TRANSACTION_TYPES } from "Views/Transaction/Constants";
import { ModalAttachment } from "../ViewAttachments";
import { AmountCell } from "Views/Transaction/All/Columns/Amount";
import ShimmerEffect from "Views/Accounting/Workflow/ShimmerEffect";

const Columns: ({ loading }: { loading: boolean }) => IColumnData<IGetTrxnResponse>[] = ({ loading }) => {
  return [
    {
      id: "PAYMENT-COL-1",
      enabled: true,
      column: {
        title: PAYMENT_COLUMNS.CREATED,
        dataIndex: GET_TRXN_RESPONSE_PARAMS.CREATED_AT,
        key: GET_TRXN_RESPONSE_PARAMS.CREATED_AT,
        render: (date: string) => {
          const formattedDate = dateTimeWithTimezone(date || new Date().toISOString());

          return (
            <ShimmerEffect loading={loading}>
              <TitleWithSubTitle
                title={intlDateTimeFormat(new Date(formattedDate))}
                subtitle={intlTimeFormat(new Date(formattedDate))}
              />
            </ShimmerEffect>
          );
        },
        width: 116,
      },
    },
    {
      id: "PAYMENT-COL-2",
      enabled: true,
      column: {
        title: PAYMENT_COLUMNS.TYPE,
        dataIndex: GET_TRXN_RESPONSE_PARAMS.TRXN_TYPE,
        key: GET_TRXN_RESPONSE_PARAMS.TRXN_TYPE,
        render(type) {
          return <ShimmerEffect loading={loading}>{type}</ShimmerEffect>;
        },
        width: 110,
      },
    },
    {
      id: "PAYMENT-COL-3",
      enabled: true,
      column: {
        title: PAYMENT_COLUMNS.REQUESTER,
        dataIndex: GET_TRXN_RESPONSE_PARAMS.PAYER_ID,
        key: GET_TRXN_RESPONSE_PARAMS.PAYER_ID,
        render: (_, record) => {
          const title = record.type === TRANSACTION_TYPES.TRANSFER ? record.description : record.payer_name;

          return (
            <ShimmerEffect loading={loading}>
              <TitleWithSubTitle title={title} subtitle={record.team_name} />
            </ShimmerEffect>
          );
        },
      },
      width: 120,
    },
    {
      id: "PAYMENT-COL-4",
      enabled: true,
      column: {
        title: "Attachment/Receipts",
        dataIndex: "receipts",
        key: "receipts",
        render(receipts) {
          return (
            <ShimmerEffect loading={loading}>
              <ModalAttachment receipts={(receipts || []).map((receipt) => receipt.url)} />
            </ShimmerEffect>
          );
        },
        align: "center" as AlignType,
      },
      width: 102,
    },
    {
      id: "PAYMENT-COL-5",
      enabled: true,
      column: {
        title: PAYMENT_COLUMNS.AMOUNT,
        key: GET_TRXN_RESPONSE_PARAMS.AMOUNT,
        dataIndex: GET_TRXN_RESPONSE_PARAMS.AMOUNT,
        render: (_, record: IGetTrxnResponse) => (
          <ShimmerEffect loading={loading}>
            <AmountCell record={record} />
          </ShimmerEffect>
        ),
        align: "right" as AlignType,
      },
      width: 150,
    },
  ].filter((item) => item.enabled);
};

export default Columns;
