import React from "react";
import classNames from "classnames";

import Modal from "Modules/DS/Modal";
import Button from "Modules/button";

import styles from "./index.module.scss";
import { ConfirmModalProps } from "./types";

const AddEmpConfirmModal = ({ show, close, view, onConfirm }: ConfirmModalProps): JSX.Element => {
  return (
    <Modal visible={show} className={styles.modal}>
      <p className={styles.message}>
        {view === "csvUpload"
          ? "One or more users in the list are being added with no mobile number hence some functions may be limited. Do you want to continue?"
          : "You are adding a user with no mobile number registered hence some functions may be limited. Do you want to continue?"}
      </p>
      <div className={styles.actionBtns}>
        <Button className={classNames(styles.closeBtn, styles.button)} text="Cancel" plain rounded action={close} />
        <Button className={styles.button} text="Confirm" rounded action={onConfirm} />
      </div>
    </Modal>
  );
};

export default AddEmpConfirmModal;
