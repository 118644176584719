import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { Modal, Typography, UnlockedOutline, CheckFilled, Banner } from "@spenmo/splice";
import { enablePaymentsList } from "Views/Home/Components/UnlockPayments";
import { upsellingBackground } from "assets/img";

import styles from "./styles.module.scss";

const UpsellingModal = ({ onClose, isOpen }: { onClose?: () => void; isOpen?: boolean }) => {
  const location = useLocation();
  const history = useHistory();
  const params = new URLSearchParams(location.search);
  const handleOnClose = () => {
    if (onClose) {
      onClose();
      return;
    }
    history.push(params.get("redirect") || "/");
  };
  return (
    <Modal
      showModal={isOpen ?? true}
      size="l"
      title="Payment with Spenmo"
      onClose={handleOnClose}
      primaryActionButton={{
        title: "Contact Us",
        action() {
          window.open("https://support.spenmo.com/hc/en-us/requests/new");
        },
      }}
      secondaryActionButton={{
        title: "Maybe Later",
        action: handleOnClose,
      }}
    >
      <div className={styles.upsellingModal}>
        <img
          src={upsellingBackground}
          height={400}
          alt="NetSuite upgrade banner"
          className={styles.upsellingBackground}
        />
        <div className={styles.information}>
          <UnlockedOutline className={styles.lockIcon} size="24" iconColor="var(--black-100)" />
          <Typography size="s" variant="body-content" weight={600} className={styles.title}>
            Verify to gain enhanced visibility and control over your company expenses
          </Typography>

          <Typography size="s" weight={600} className={styles.featureTitle} variant="body-content">
            Verify your business to enable:
          </Typography>

          <ul>
            {enablePaymentsList.map((payment, index) => (
              <li className={styles.benefit} key={index}>
                <div className={styles.iconWrapper}>
                  <CheckFilled iconColor="var(--fill-system-success-strong)" size="16" />
                </div>
                <Typography size="s" variant="body-content" className={styles.textList} tag="p">
                  {payment}
                </Typography>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </Modal>
  );
};

export const UpsellingBanner = () => {
  const [showModal, setShowModal] = useState(false);
  return (
    <div className={styles.upsellingSection}>
      <Banner
        variant="neutral"
        description={
          <Typography variant="body-content" size="s" tag="p">
            This feature is only available for company with payment feature enabled. Get verified first to settle your
            company reimbursements with Spenmo.{" "}
            <Typography variant="body-content" size="s" className={styles.learnMore} onClick={() => setShowModal(true)}>
              Learn more
            </Typography>
          </Typography>
        }
      />
      <UpsellingModal isOpen={showModal} onClose={() => setShowModal(false)} />
    </div>
  );
};

export default UpsellingModal;
