import {
  FETCH_CREATE_CARD_REQUEST,
  FETCH_CREATE_CARD_SUCCESS,
  FETCH_CREATE_CARD_FAILURE,
  RESET_CREATE_CARD_DATA,
} from "Redux/Actions/CreateCard";
import { userInitialState } from "../InitialState";

export const createCardReducer = (state = userInitialState, action) => {
  switch (action.type) {
    case FETCH_CREATE_CARD_REQUEST:
      return {
        ...state,
        data: action.data,
        loading: true,
      };
    case FETCH_CREATE_CARD_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
      };
    case FETCH_CREATE_CARD_FAILURE:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: true,
        message: "ERROR ON CREATING CARD",
      };
    case RESET_CREATE_CARD_DATA:
      return {
        ...userInitialState,
        data: null,
      };
    default:
      return state;
  }
};
