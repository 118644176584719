import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Select } from "antd";

import "./TagInput.scss";

const TagInput = ({ className, ...rest }) => {
  const _classNames = classNames("tag-input", className);

  return (
    <Select
      {...rest}
      className={_classNames}
      dropdownClassName={"tag-input__dropdown"}
      tokenSeparators={[" "]}
      mode="tags"
      getPopupContainer={(triggerNode) => triggerNode.parentElement}
    />
  );
};

TagInput.propTypes = {
  className: PropTypes.string,
};

export default TagInput;
