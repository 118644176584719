import React from "react";
import { Modal, Typography } from "@spenmo/splice";
import Icon from "Modules/icons";
import { tinyError } from "assets/img";
import { cookieNames, setCookie } from "utility/CookieHelper";
import { ReKYBLinks } from "Views/KYX/Constants";

import styles from "../index.module.scss";

const ReKYBRejectedModal = ({ setShowReKYBModal }: { setShowReKYBModal: (boolean) => void }): React.ReactElement => {
  const onModalClose = () => {
    setShowReKYBModal(false);
    return setCookie(cookieNames.SHOW_REKYB_MODAL, false);
  };

  return (
    <Modal showModal onClose={onModalClose} title="Company Verification Rejected" size="m">
      <div className={styles.reKybContent}>
        <Icon src={tinyError} alt="error" />

        <Typography className={styles.modalAlertText} variant="body-content" size="s">
          We were unable to verify your company according to the submitted data. Our team will be in contact with you
          soon with more information on this issue.
        </Typography>
        <Typography className={styles.modalAlertText} variant="body-content" size="s">
          For further inquiries, please contact our Customer Support team at{" "}
          <a href={ReKYBLinks.supportLink} target="_blank" rel="noreferrer noopener">
            {"support@spenmo.com"}
          </a>
        </Typography>
      </div>
    </Modal>
  );
};

export default ReKYBRejectedModal;
