import * as React from "react";
import { notification } from "antd";
import { successNotificationIcon24x24, errorNotificationIcon24x24 } from "assets/icons/card";
import "Views/Card/Components/Notification/index.scss";

interface NotificationConfigType {
  message: string;
  duration?: number | null;
}

const NOTIFICATION_DURATION = 3;

export const CardNotification = {
  success: (config: NotificationConfigType) => {
    return notification.success({
      ...config,
      placement: "bottomLeft",
      className: "card-notification card-notification__success",
      icon: <img src={successNotificationIcon24x24} alt="success icon" />,
      closeIcon: null,
    });
  },
  error: (config) => {
    return notification.error({
      ...config,
      placement: "bottomLeft",
      className: "card-notification card-notification__error",
      icon: <img src={errorNotificationIcon24x24} alt="error icon" />,
      closeIcon: null,
    });
  },
};

export const showSuccessNotification = (message) =>
  CardNotification.success({
    message: message,
    duration: NOTIFICATION_DURATION,
  });

export const showErrorNotification = (message) =>
  CardNotification.error({
    message: message,
    duration: 0,
  });
