import React from "react";

import { PRODUCT_NAME } from "Redux/ModularProduct";

import useCheckOrgConfigs from "customHooks/useCheckOrgConfigs";

import { useFetchCRState as useFetchCRSettings } from "Views/Reimbursement/Settings/useCRState";

import mockResponse from "Views/Reimbursement/ExpenseReport/mock";
import ExpenseReportHeader from "Views/Reimbursement/ExpenseReport/Header";
import EmployeeExpenseReport from "Views/Reimbursement/ExpenseReport/Report";

import styles from "./index.module.scss";

const ExpenseReport = () => {
  // Hiding this for time being as this is for future integration purposes
  // const { params } = useRouteMatch<ILocationParams>();

  // const { operation = "", id = "" } = params;

  // const isUnpaidPayouts =
  //   operation === REIMBURSEMENT_PAGE_OPERATIONS.PAYOUTS &&
  //   id === REIMBURSEMENT_SETTLEMENT_STATUSES.UNPAID_FOR_PAYOUTS_VIEW.id;

  const [isCRPaymentEnabled, loadingIsCRPaymentEnabled] = useCheckOrgConfigs(
    PRODUCT_NAME.PAYMENT_FOR_REIMBURSEMENT,
    true
  ) as [boolean, boolean];

  const { fetchState: fetchCRSettings } = useFetchCRSettings();
  const { isLoading: loadingSettlements, data: settlementData } = fetchCRSettings;
  const isSettlementEnabled: boolean = settlementData?.data?.payload?.isEnabled || false;

  const addOnsEnabled = isCRPaymentEnabled && isSettlementEnabled;
  const loadingAddOns = loadingIsCRPaymentEnabled || loadingSettlements;

  const data = mockResponse.payload;
  const total = `SGD ${data.reduce((prev, curr) => prev + curr.totalPayableAmount, 0)}`;
  const count = data.length;

  const renderReports = () => {
    return data.map((item, index) => {
      return (
        <EmployeeExpenseReport
          key={item.requester.id}
          index={index + 1}
          name={item.requester.name}
          email={item.requester.email}
          list={item.reimbursements}
          loading={loadingAddOns}
          addOnsEnabled={addOnsEnabled}
        />
      );
    });
  };

  return (
    <div className={styles.container}>
      {/*
        // Hiding this for time being as this is for future integration purposes
        {isUnpaidPayouts && addOnsEnabled && <PayoutCycleWidget loading={loadingAddOns} />}
      */}
      <div>
        <ExpenseReportHeader
          totalAmount={total}
          employeeCount={count}
          loading={loadingAddOns}
          addOnsEnabled={addOnsEnabled}
        />
        {renderReports()}
      </div>
    </div>
  );
};

export default ExpenseReport;
