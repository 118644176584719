import * as React from "react";
import { Button } from "antd";
import Loader, { LoaderTypes } from "Views/Card/Components/Loader";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import { remindManager } from "Redux/DataCalls/Cards.api";
import { useCardDetailsContext } from "Views/Card/Details/index.context";
import { showSuccessNotification, showErrorNotification } from "Views/Card/Components/Notification";

import styles from "Views/Card/Details/ActionBtns/index.module.scss";

export const RemindButton = () => {
  const { cardData } = useCardDetailsContext();
  const { id } = cardData || {};
  const [remindInProgress, setRemindInProgress] = React.useState(false);

  const onRemind = () => {
    setRemindInProgress(true);
    remindManager(id)
      .then(({ data }) => {
        const { status_message = "" } = data?.payload || {};
        if (data?.status === HTTP_STATUS_CODE.OK) {
          showSuccessNotification(status_message);
        } else {
          showErrorNotification(status_message);
        }
      })
      .catch((err) => {
        const { status_message = "Something went wrong! Please try again later." } = err?.response?.data?.payload || {};
        showErrorNotification(status_message);
      })
      .finally(() => {
        setRemindInProgress(false);
      });
  };

  return (
    <Button className={`${styles["btn"]} ${styles["primary-btn"]}`} disabled={remindInProgress} onClick={onRemind}>
      {remindInProgress ? <Loader type={LoaderTypes.white} /> : "Remind Manager"}
    </Button>
  );
};
