import Button from "Modules/button";
import { Form } from "antd";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "hook";
import Input from "Modules/DS/Input";
import Checkbox from "Modules/DS/Atoms/Checkbox";
import LoaderIcon from "Views/State/Loading/LoaderIcon";
import { getAllMembersFunc } from "Redux/Actions";
import { search as searchIcon } from "assets/img";
import classNames from "classnames";
import "../index.scss";
interface IAmountSendProps {
  handleSubmit: (key, object) => void;
  filterKey: string;
  selectedRequester: Array<string>;
  setSelectedRequester: (val) => void;
  initialRequester: string;
}

const EmployeeFilter = ({
  handleSubmit,
  filterKey,
  selectedRequester,
  setSelectedRequester,
  initialRequester,
}: IAmountSendProps) => {
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  let employeeList = useAppSelector((state) => state?.membersReducer?.data) || [];
  const isLoading = useAppSelector((state) => state?.membersReducer?.loading);
  const [search, setSearch] = useState("");
  const [form] = Form.useForm();

  if (search) {
    employeeList = employeeList.filter((item) => item.name.toLowerCase().includes(search.toLowerCase()));
  }

  useEffect(() => {
    dispatch(getAllMembersFunc());
  }, [dispatch]);

  useEffect(() => {
    focusInput();
    setSelectedRequester(initialRequester);
  }, []);

  const focusInput = () => {
    inputRef?.current?.focus();
  };

  const handleItemClick = (val: string) => {
    let newSelection = [...selectedRequester];
    if (newSelection.includes(val)) {
      newSelection = [];
    } else {
      newSelection = [val];
    }
    form.setFieldsValue({ selected: newSelection });
    setSelectedRequester(newSelection);
  };

  const filterContent = (
    <div className="spm-ctx-menu__popover__content">
      <ul className="requster_filter_list">
        {employeeList?.map((item) => {
          const active = selectedRequester?.includes(item.id);
          const listClassNames = classNames(`spm-ctx-menu__popover__option`, {
            active,
          });
          return (
            <li
              className={listClassNames}
              key={item.id}
              value={item.id}
              onClick={(e) => {
                e.stopPropagation();
                handleItemClick(item.id);
              }}
            >
              <Checkbox
                checked={active}
                onClick={(e) => {
                  e.stopPropagation();
                  handleItemClick(item.id);
                }}
              />
              <div className="spm-ctx-menu__option__content">{item.name}</div>
            </li>
          );
        })}
        {isLoading && <LoaderIcon />}
      </ul>
    </div>
  );

  return (
    <div className={`requester-filter`}>
      <Form name="employeeForm" form={form} onFinish={(values) => handleSubmit(filterKey, values)}>
        <Form.Item name="selected">
          <Input
            ref={inputRef}
            className="spm-search-dropdown__input"
            placeholder={"Search Requester"}
            value={search}
            onChange={setSearch}
            suffix={<img src={searchIcon} alt="" />}
          />
          {filterContent}
        </Form.Item>
        <div className="filter-popover__action">
          <Button
            className="filter-popover__action__button--clear"
            type="button"
            textButton
            action={() => {
              setSelectedRequester([]);
              form.resetFields();
            }}
          >
            Clear
          </Button>
          <Button className="filter-popover__action__button--submit">Apply</Button>
        </div>
      </Form>
    </div>
  );
};

export default EmployeeFilter;
