import * as React from "react";
import classNames from "classnames";
import { Tabs as ITabs } from "antd";

import Badge from "Modules/DS/Badge";

import { ITabsProps, TAB_TYPE, ListData } from "./types.d";

// not using modular scss due to override issues with antd classes
import "./index.scss";

const Tabs: React.FC<ITabsProps> = ({
  tabsList,
  content,
  action,
  defaultActiveKey,
  activeKey,
  type = TAB_TYPE.FLUID,
  destroyInactiveTabPane = false,
  useContentInTabList = false,
}: ITabsProps) => {
  const { TabPane } = ITabs;
  const _tabclassNames = classNames({ [`tabs-list--${type}`]: type }, "tabs-list");

  const getContent = (tab: ListData) => {
    if (useContentInTabList) {
      const { content: contentInTab } = tab;
      return contentInTab ? contentInTab(tab) : null;
    }
    return content ? content(tab) : null;
  };

  return (
    <ITabs
      prefixCls={_tabclassNames}
      activeKey={activeKey}
      defaultActiveKey={defaultActiveKey}
      onChange={action}
      destroyInactiveTabPane={destroyInactiveTabPane}
    >
      {Array.isArray(tabsList) &&
        tabsList?.map((tab) => {
          const tabKey = tab.tabKey ? tab.tabKey : tab.title;
          const tabTitle = (
            <div key={`tab-list-${tabKey}`} className={"tab-title"}>
              {tab.title}
              <Badge count={tab?.count} isIndicator={tab?.isIndicator} />
            </div>
          );

          return (
            <TabPane tab={tabTitle} className="tabs-list-pane" key={tabKey}>
              {getContent(tab)}
            </TabPane>
          );
        })}
    </ITabs>
  );
};

export * from "./types.d";

export default Tabs;
