import { EligibilityValidationError, IDeleteTeamWarning, IEligibilityValidationErrors } from "Views/Team/Delete/type";
import { LineIcon } from "assets/v1.1/icons";
import { orangeWarning } from "assets/v1.1/icons/Line";

const commonEligibilityValidationErrorIcon = { icon: orangeWarning };
export const EligibilityValidationErrors = (): IEligibilityValidationErrors[] => {
  return [
    {
      key: EligibilityValidationError.PENDING_BILLS,
      title: "This budget has ongoing bill payment(s)",
      subtitle:
        "This budget cannot be deleted due to pending transactions. All pending transactions must be settled before deleting this budget.",
    },
    {
      key: EligibilityValidationError.ACTIVE_CARDS,
      title: "This budget has card(s) assigned to budget members",
      subtitle:
        "This budget cannot be deleted due to pending transactions. All pending transactions must be settled before deleting this budget.",
    },
    {
      key: EligibilityValidationError.PENDING_REIMBURSEMENT,
      title: "This budget has ongoing reimbursement(s)",
      subtitle:
        "This budget cannot be deleted due to pending transactions. All pending transactions must be settled before deleting this budget.",
    },
    {
      key: EligibilityValidationError.MEMBERS_HAVE_NO_OTHER_BUDGET,
      title: "This budget has member(s) who do not belong to any other budget",
      subtitle:
        "This budget cannot be deleted due to one or more members of this budget not belonging to any other budget. Each user must belong to at least one budget.",
    },
  ].map((eligibilityValidationError) => ({ ...eligibilityValidationError, ...commonEligibilityValidationErrorIcon }));
};

const MatchMoveWarnings = (teamName: string): IDeleteTeamWarning[] => [
  {
    Budget: {
      content: [
        `${teamName} Budget will be deleted`,
        `Remaining funds in ${teamName} Budget will be returned to the main wallet`,
      ],
      icon: LineIcon.orangeTeam,
    },
  },
  {
    Transaction: {
      content: [
        `Stop ongoing scheduled internal transactions from and to ${teamName} Budget`,
        `Stop fund transfers from and to ${teamName} Budget`,
      ],
      icon: LineIcon.orangeCalendar,
    },
  },
  {
    Card: {
      content: [`Decline all pending card request in ${teamName} Budget`],
      icon: LineIcon.orangeCard,
    },
  },
];

const NonMatchMoveWarnings = (teamName: string): IDeleteTeamWarning[] => [
  {
    Budget: {
      content: [`${teamName} Budget will be deleted`],
      icon: LineIcon.orangeTeam,
    },
  },
  {
    Card: {
      content: [`Decline all pending card request in ${teamName} Budget`],
      icon: LineIcon.orangeCard,
    },
  },
];

export const DeleteTeamWarnings = {
  MATCHMOVE: MatchMoveWarnings,
  NON_MATCHMOVE: NonMatchMoveWarnings,
};

export const MATCH_DELETE_TEXT = "DELETE";
export const DEFAULT_BUDGET_DELETE_ERROR = "Cannot delete default budget";
