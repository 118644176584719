import * as React from "react";
import { Radio, Checkbox } from "antd";
import Input from "Views/Card/Components/Input";
import { CARD_LIMIT_TYPES } from "Views/Card/types";
import { getCardLimitsWithTooltipInfo } from "Views/Card/Components/SpendingLimit/index.constants";
import { Tooltip } from "Views/Card/Components/Tooltip";
import useCheckFeatureStatus from "customHooks/featureCheck";
import { SPLIT_NAMES, SPLIT_TREATMENT_TYPES } from "Redux/splitio/constants";
import { getIsLimitModelOrg } from "customHooks/Card";

import "Views/Card/Components/SpendingLimit/index.scss";

interface SpendingLimitProps {
  cardLimitType: CARD_LIMIT_TYPES;
  cardLimit: string;
  onSpendingLimitTypeChange: (limitType: CARD_LIMIT_TYPES) => void;
  onSpendingLimitChange: (limit: string) => void;
  currencyCode: string;
  showLetMyManagerChooseLimit?: boolean;
  onLetMyMangerChooseLimit?: (letManagerChooseLimit: boolean) => void;
  letMyManagerChooseLimit?: boolean;
  showBlueBackgroundForLimitInput?: boolean;
  showDisabledTotalSpendingLimit?: boolean;
  onInputBlur?: () => void;
  spendingLimitHasError?: boolean;
}

const SpendingLimits = ({
  cardLimitType,
  cardLimit,
  onSpendingLimitTypeChange,
  onSpendingLimitChange,
  currencyCode,
  showLetMyManagerChooseLimit = false,
  onLetMyMangerChooseLimit = (_letManagerChooseLimit) => {
    /* do nothing */
  },
  letMyManagerChooseLimit = false,
  showBlueBackgroundForLimitInput = false,
  showDisabledTotalSpendingLimit = false,
  onInputBlur = () => {
    /* do nothing */
  },
  spendingLimitHasError = false,
}: SpendingLimitProps) => {
  const cardsDailyLimitSplit = useCheckFeatureStatus(SPLIT_NAMES.cardsDailyLimit);
  const enableDailyFilter = getIsLimitModelOrg() && cardsDailyLimitSplit === SPLIT_TREATMENT_TYPES.ON;
  const getInputPlaceholder = () => {
    return showLetMyManagerChooseLimit && letMyManagerChooseLimit ? "Assigned to Budget Owner" : "Set a spending limit";
  };

  const IsInputDisabled = () => {
    return (
      (showLetMyManagerChooseLimit && letMyManagerChooseLimit) ||
      cardLimitType === CARD_LIMIT_TYPES.unlimited ||
      showDisabledTotalSpendingLimit
    );
  };

  return (
    <>
      {!showDisabledTotalSpendingLimit && (
        <Radio.Group
          value={letMyManagerChooseLimit ? null : cardLimitType}
          size={showBlueBackgroundForLimitInput ? "middle" : "large"}
          className="spending-limits__radio-group"
          onChange={(e) => onSpendingLimitTypeChange(e?.target?.value)}
        >
          {getCardLimitsWithTooltipInfo(enableDailyFilter)?.map((limitData) => (
            <Tooltip placement={limitData.tooltipPosition} tooltipText={limitData.tooltipText} key={limitData.label}>
              <div key={limitData.label}>
                <Radio.Button value={limitData.value} data-testid={limitData.value}>
                  {limitData.label}
                </Radio.Button>
              </div>
            </Tooltip>
          ))}
        </Radio.Group>
      )}

      <div className={`${showBlueBackgroundForLimitInput ? "spending-limits__input-blue-wrapper" : ""}`}>
        <Input
          value={cardLimit}
          onChange={(e) => onSpendingLimitChange(e?.target?.value)}
          placeholder={getInputPlaceholder()}
          addonBefore={currencyCode}
          disabled={IsInputDisabled()}
          onBlur={onInputBlur}
          hasError={spendingLimitHasError}
        />
        {showLetMyManagerChooseLimit && (
          <div className="spending-limits__assign-to-manager">
            <Checkbox
              onChange={(event) => onLetMyMangerChooseLimit(event?.target?.checked)}
              checked={letMyManagerChooseLimit}
            />
            <div className="spending-limits__info">
              <p className="header">Assign to Budget Owner</p>
              <p className="subheader">Let Budget Owner decide the Spending Limit of this card.</p>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default SpendingLimits;
