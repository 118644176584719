import React from "react";
import DataTable, { Table, DATA_TABLE_SIZE_TYPES, IColumnData, DATA_TABLE_PADDING } from "Modules/DS/DataTable";
import LoadingComponent from "Views/State/Loading/LoaderIcon";
import TextWithTooltip from "Views/Taxes/components/TextWithTooltip";

import Chip, { CHIP_STATUS } from "Modules/DS/Chip";
import TaxCountBanner from "../TaxCountBanner";
import { ITaxImport } from "Views/Taxes/types";

import "./styles.scss";

const TaxImporting = ({ missingTaxes, partnerName }) => {
  const { data, loading } = missingTaxes;

  const tableProps = {
    total: 2,
    loading: false,
    pageNum: 1,
    pageSize: data?.data?.length || 10,
    paginationEnabled: false,
    emptyState: <p>Taxes not found</p>,
    dataSource: (data?.data || []).map((tax) => ({
      taxName: tax?.taxName,
      taxRate: tax?.taxRate,
      isNew: true,
    })),
  };

  const columnData: IColumnData<ITaxImport>[] = [
    {
      id: "1",
      enabled: true,
      column: {
        key: "taxName",
        title: "Tax Name on Spenmo",
        dataIndex: "taxName",
        width: 150,
        ellipsis: true,
        render(taxName: string) {
          return (
            <>
              <TextWithTooltip
                treshold={24}
                text={taxName}
                element={
                  <div className="text-with-tooltip__title">
                    <span>{taxName}</span>
                  </div>
                }
              />
            </>
          );
        },
      },
    },
    {
      id: "2",
      enabled: true,
      column: {
        key: "taxRate",
        title: `Tax Name on ${partnerName}`,
        dataIndex: "taxRate",
        width: 150,
        ellipsis: true,
        render(taxRate: string, record: ITaxImport) {
          const taxNameonXero = `${record.taxName}(${taxRate}%)`;

          return (
            <div className="text-with-new-wrapper">
              <TextWithTooltip
                treshold={24}
                text={taxNameonXero}
                element={
                  <div className="text-with-tooltip__title">
                    <span>{taxNameonXero}</span>
                  </div>
                }
              />
              {record.isNew && <Chip.Label title="New" status={CHIP_STATUS.INFO} />}
            </div>
          );
        },
      },
    },
  ];

  return (
    <div className="tax-mapping">
      {loading ? (
        <LoadingComponent />
      ) : (
        <>
          <TaxCountBanner count={data?.count} partnerName={partnerName} />

          <DataTable size={DATA_TABLE_SIZE_TYPES.SMALL} padding={DATA_TABLE_PADDING.NONE} className="tax-mapping__data">
            <Table<ITaxImport> {...tableProps}>{columnData}</Table>
          </DataTable>
        </>
      )}
    </div>
  );
};

export default TaxImporting;
