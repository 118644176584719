import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { pdfjs, Document, Page } from "react-pdf";
import { Image } from "antd";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";

import Icon from "Modules/icons";
import { changeFile as changeFileIcon, deleteFile as deleteFileIcon } from "assets/img";
import { ALLOWED_FILE_EXTENSIONS } from "Views/UploadInvoice/const";

import styles from "./Preview.module.scss";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const Preview = ({ url, type, onDelete, onChange, canChangeFile }) => {
  const [pageNumber, setPageNumber] = useState(1),
    [pageCount, setPageCount] = useState(1);

  const onLoadSuccess = ({ numPages }) => setPageCount(numPages),
    changePage = (offset) => setPageNumber((data) => data + offset),
    prevPage = () => changePage(-1),
    nextPage = () => changePage(1);

  const renderButtonChangeDelete = () => (
    <div className={styles["action-button"]}>
      {canChangeFile && (
        <div className={styles.change} onClick={onChange}>
          <Icon src={changeFileIcon} />
          Change file
        </div>
      )}
      <div className={styles.delete} onClick={onDelete}>
        <Icon src={deleteFileIcon} />
        Delete
      </div>
    </div>
  );

  const render = () => {
    switch (type?.toLowerCase()) {
      case ALLOWED_FILE_EXTENSIONS[0]:
      case ALLOWED_FILE_EXTENSIONS[1]:
      case ALLOWED_FILE_EXTENSIONS[2]: {
        return <Image preview={false} className={styles["uploaded-image"]} src={url} />;
      }
      case ALLOWED_FILE_EXTENSIONS[3]: {
        return (
          <>
            <Document file={url} onLoadSuccess={onLoadSuccess}>
              <Page pageNumber={pageNumber} />
            </Document>
            {pageCount > 1 && (
              <div className={styles["page-number"]}>
                Page {pageNumber} of {pageCount}
              </div>
            )}
            {pageCount > 1 && pageNumber > 1 && (
              <div onClick={prevPage} className={classNames(styles.navigation, styles.prev)}>
                <ArrowLeftOutlined />
              </div>
            )}
            {pageCount > 1 && pageNumber < pageCount && (
              <div onClick={nextPage} className={classNames(styles.navigation, styles.next)}>
                <ArrowRightOutlined />
              </div>
            )}
          </>
        );
      }
      default: {
        return null;
      }
    }
  };

  return (
    <div className={styles.container}>
      {ALLOWED_FILE_EXTENSIONS.includes(type?.toLowerCase()) && renderButtonChangeDelete()}
      <div className={styles.inner}>{render()}</div>
    </div>
  );
};

Preview.propTypes = {
  url: PropTypes.string,
  type: PropTypes.string,
  onDelete: PropTypes.func,
  onChange: PropTypes.func,
  canChangeFile: PropTypes.bool,
};

export default Preview;
