import { createContext } from "react";

import { ITrxnModalState, ITrxnModalContext } from "Views/Transaction/@types";

export const initialTrxnModalContext: ITrxnModalContext = {
  resetModal: () => null,
  toggleModal: () => null,
  setShowModal: () => null,
  showModal: {} as ITrxnModalState,
};

export const TrxnModalContext = createContext<ITrxnModalContext>(initialTrxnModalContext);
