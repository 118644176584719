import * as React from "react";
import { Input, Button } from "antd";
import Loader, { LoaderTypes } from "Views/Card/Components/Loader";
import Modal from "Views/Card/Review/Modal";
import { errorCrossIcon80x80 } from "assets/icons/card";

import "Views/Card/Review/Decline/index.scss";

const { TextArea } = Input;

interface DeclineCardRequestProps {
  onReview: () => void;
  rejectionInProgress: boolean;
  onConfirm: (declineReason) => void;
  onClose: () => void;
}

const DeclineModal = ({ onReview, rejectionInProgress, onConfirm, onClose }: DeclineCardRequestProps) => {
  const [declineReason, setDeclineReason] = React.useState("");
  return (
    <Modal onClose={onClose} width={540} title="Decline This Card Request?">
      <div className="card-decline-modal">
        <div className="card-decline-modal__info-container">
          <img src={errorCrossIcon80x80} width={80} height={80} alt="error icon" />
          <p>You are declining this card request. This member may still request a new card.</p>
        </div>
        <label>Provide a reason for declining</label>
        <TextArea
          rows={3}
          placeholder="write a note.."
          value={declineReason}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setDeclineReason(e?.target?.value || "")}
        />

        <div className="card-decline-modal__button-container">
          <Button className="card-decline-modal__button card-decline-modal__button--outline" onClick={onReview}>
            Review Card Request
          </Button>
          <Button
            className={`card-decline-modal__button ${
              rejectionInProgress ? "card-decline-modal__button--loading" : ""
            } `}
            disabled={!declineReason || rejectionInProgress}
            onClick={() => onConfirm(declineReason)}
          >
            {rejectionInProgress ? <Loader type={LoaderTypes.white} /> : "Decline Card Request"}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DeclineModal;
