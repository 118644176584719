import React from "react";

import { Col, Row } from "Splice/Grid";

import Document from "Views/KYX/Intro/Document";
import { IDocumentsInfo, IStartIntro } from "Views/KYX/@types";
import { KYB_SOURCE, DOCUMENTS_INFO } from "Views/KYX/Constants";

import { StartIntroHeader as Header } from "Views/KYX/Intro/Start/Header";
import { KYXPrimaryButton } from "Views/KYX/Intro/Start/Button";

import styles from "./index.module.scss";
import { COMPANY_VERIFICATION } from "Route/KYX/paths";
import { useRedirectionHandler, useStartKYXManually } from "Views/KYX/hooks";

/**
 * A React Component that is used to render the Manual Method of the Starting Intro Screens when a user is logging in for the first time.
 * It is maintained according to the KYX_ORG_COUNTRY_LIST
 *
 * @param {string} companyName - A string value that contains the name of the company.
 * @param {KYX_ORG_COUNTRY_LIST} countryCode - Contains a reference to the Country Codes for the org
 * @returns {JSX.Element} A JSX Element
 */
export const ManualMethod = ({ companyName, countryCode }: IStartIntro): JSX.Element => {
  const details: IDocumentsInfo = DOCUMENTS_INFO()[countryCode][KYB_SOURCE.MANUAL];
  const redirect = useRedirectionHandler(COMPANY_VERIFICATION.COMPANY_INFORMATION);

  const { loading, startVerification } = useStartKYXManually(redirect);
  const buttonText = "Start Verification";

  return (
    <Row className={styles.header} align="end" justify="start">
      <Col className={styles.header} span={24}>
        <Header content={details?.content} label={details?.label} companyName={companyName} />
      </Col>
      <Col className={styles.alignInline} span={24}>
        <Document documents={details?.documents} />
      </Col>
      <Col span={24}>
        <KYXPrimaryButton loading={loading} action={startVerification}>
          {buttonText}
        </KYXPrimaryButton>
      </Col>
    </Row>
  );
};
