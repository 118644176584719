import { USER_ROLE_DISPLAY } from "constants/Team.constant";
import { TEAM_ACTION } from "./List/Actions/constant";
import { ITeam, TeamType } from "Views/Team/type";
import { SCHEDULED_TRANSACTIONS_BULK_LIMIT } from "./constant";

export const displayRole = (role: string): string => {
  return USER_ROLE_DISPLAY[role];
};

export const getKeyOnFirstIndex = (object) => Object.keys(object)[0];

export const isRenameActionActive = (value: TEAM_ACTION, team: ITeam) => {
  return value === TEAM_ACTION.RENAME;
};

export const isDeleteActionActive = (value: TEAM_ACTION, team: ITeam) => {
  return value === TEAM_ACTION.DELETE && team.team_type !== TeamType.ORGANISATION;
};

export const isActionDisabled = (value: TEAM_ACTION, team: ITeam) => {
  let disabled = false;
  switch (value) {
    case TEAM_ACTION.DELETE:
      disabled = !isDeleteActionActive(value, team);
  }
  return disabled;
};

export const setApiDataToNewFormat = (oldFormatData) => {
  const newFormatData = [];
  oldFormatData.forEach((item) => {
    newFormatData.push({
      availableBalance: item.available_balance,
      budgetID: item.team_id,
      id: item.id,
      role: item.role,
      subwalletID: item.subwalletID,
      user: { id: item.user_id, firstName: item.name, lastName: "", email: item.email },
      userID: item.user_id,
    });
  });
  return newFormatData;
};

export const shouldDisableCurrentRowSelection = (selectedRows, currentRow): boolean => {
  if (selectedRows.length === SCHEDULED_TRANSACTIONS_BULK_LIMIT) {
    return selectedRows.findIndex((row) => row.id === currentRow.id) === -1;
  }
};
