import React from "react";
import {
  IRouterType,
  PAGE_GRID_COLUMNS,
  PAGE_GRID_CONTENT_TYPE,
} from "Route/@types";
import { COMMON_PATHS } from "Route/Common/paths";

import HomePage from "Views/Home";
import TopUp from "Views/TopUp/TopUp";
import BankAccount from "Views/BankAccount";
import TopupStatusView from "Modules/TopupStatusView";
import LowBalanceReminder from "Views/LowBalanceReminder/LowBalanceReminder";
import Analytics from "Views/Analytics";
import EmptyState from "Views/State/emptyState";
import Resource from "Views/Resource";
import Settings from "Views/Settings";
import AccessForbidden from "Views/AccessForbidden";
import UpsellingModal from "Views/Upselling";
import Reports from "Views/Reports";

export const CommonRoute: IRouterType[] = [
  {
    id: "common-1",
    path: COMMON_PATHS.TOP_UP_PATH,
    component: <TopUp />,
    title: "Top up page",
    authRequired: true,
    exactPath: true,
    grid: {
      content: PAGE_GRID_CONTENT_TYPE.FIXED,
    },
  },
  {
    id: "common-2",
    path: COMMON_PATHS.BANK_ACCOUNTS,
    component: <BankAccount />,
    title: "Bank account",
    authRequired: true,
    exactPath: true,
    grid: {
      columns: PAGE_GRID_COLUMNS.NONE,
    },
  },
  {
    id: "common-3",
    path: COMMON_PATHS.SUCCESS_PATH,
    component: <TopupStatusView />,
    title: "Top up status success",
    authRequired: true,
    exactPath: true,
    grid: {
      content: PAGE_GRID_CONTENT_TYPE.FIXED,
    },
  },
  {
    id: "common-4",
    path: COMMON_PATHS.ERROR_PATH,
    component: <TopupStatusView />,
    title: "Top up status error",
    authRequired: true,
    exactPath: true,
    grid: {
      content: PAGE_GRID_CONTENT_TYPE.FIXED,
    },
  },
  {
    id: "common-5",
    path: COMMON_PATHS.LOW_BALANCE_REMINDER,
    component: <LowBalanceReminder />,
    title: "Low balance reminder",
    authRequired: true,
    exactPath: true,
    grid: {
      content: PAGE_GRID_CONTENT_TYPE.FIXED,
    },
  },
  {
    id: "common-6",
    path: COMMON_PATHS.ANALYTICS,
    component: <Analytics />,
    title: "Analytics",
    authRequired: true,
    exactPath: true,
  },
  {
    id: "common-6-b",
    path: COMMON_PATHS.REPORTS,
    component: <Reports />,
    title: "Reports",
    authRequired: true,
    exactPath: true,
    grid: {
      columns: PAGE_GRID_COLUMNS.NONE,
    },
  },
  {
    id: "common-7",
    path: COMMON_PATHS.PAGE_NOT_FOUND,
    component: <EmptyState />,
    title: "Page not found",
    authRequired: true,
    exactPath: false,
  },
  {
    id: "common-8",
    path: "/",
    component: <HomePage />,
    title: "home page",
    authRequired: true,
    exactPath: true,
    grid: {
      columns: PAGE_GRID_COLUMNS.NONE,
    },
  },
  {
    id: "common-9",
    path: COMMON_PATHS.RESOURCE,
    component: <Resource />,
    title: "Downloading",
    authRequired: true,
    exactPath: true,
    grid: {
      content: PAGE_GRID_CONTENT_TYPE.FIXED,
    },
  },
  {
    id: "common-10",
    path: COMMON_PATHS.SETTINGS,
    component: <Settings />,
    title: "Setting",
    authRequired: true,
    exactPath: false,
    grid: {
      columns: PAGE_GRID_COLUMNS.NONE,
    },
  },
  {
    id: "common-11",
    path: COMMON_PATHS.UPSELLING,
    component: <UpsellingModal />,
    title: "Upselling Modal",
    authRequired: true,
    exactPath: false,
  },
  {
    id: "common-12",
    path: COMMON_PATHS.FORBIDDEN,
    component: <AccessForbidden />,
    title: "Access Forbidden",
    authRequired: false,
    exactPath: false,
  },
  {
    id: "common-13",
    path: "",
    component: <EmptyState />,
    title: "Page not found",
    authRequired: false,
    exactPath: false,
  },
];
