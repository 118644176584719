import { useContext, useEffect, useState } from "react";
import { FormInstance } from "antd/lib/form";

import {
  FORM_NAME,
  KYX_FORMS_KEYS,
  GUIDELINES_TYPE,
  FORM_FIELD_TYPES,
  PERSON_DOCUMENT_TYPE,
  PERSONNEL_INFORMATION_FORM_ITEM_KEYS,
} from "Views/KYX/Constants";
import { useFormSaveAsDraft } from "Views/KYX/hooks";
import { ManualFormContext } from "Views/KYX/Context";
import { getValidationError, getValidationstatus } from "Views/KYX/Helper";
import { IFormElement, IPersonForm, IGetFormConfigsPayload } from "Views/KYX/@types";

export const useProofOfResidence = (
  form: FormInstance,
  remarks: IGetFormConfigsPayload,
  currentKey: string,
  isFormDisabled: boolean,
  documentSelectionType: PERSON_DOCUMENT_TYPE,
  formId: string
) => {
  const { formData, setFormData } = useContext(ManualFormContext);
  const { saveFormAsDraft } = useFormSaveAsDraft(setFormData);

  const getDocumentTypeKeyName = (): string => {
    let value: string;
    switch (documentSelectionType) {
      case PERSON_DOCUMENT_TYPE.WORKPASS:
        value = KYX_FORMS_KEYS.PERSON_DATA_WORK_PASS_DOCUMENT_TYPE;
        break;
      case PERSON_DOCUMENT_TYPE.PASSPORT:
        value = KYX_FORMS_KEYS.PERSON_DATA_PASSPORT_DOCUMENT_TYPE;
        break;
      case PERSON_DOCUMENT_TYPE.NRIC:
        value = KYX_FORMS_KEYS.PERSON_DATA_NRIC_DOCUMENT_TYPE;
        break;
      default:
        break;
    }

    return value;
  };

  const [isResidentialAddressSame, setIsResidentialAddressSame] = useState<boolean>(true);

  useEffect(() => {
    const isAddressSameCheckbox = form.getFieldValue([
      KYX_FORMS_KEYS.PERSONNEL_DATA_PERSONS,
      currentKey,
      KYX_FORMS_KEYS.PERSON_DATA_ID_DOCUMENTS,
      KYX_FORMS_KEYS.PERSON_IS_RESIDENCE_ADDRESS_SAME,
    ]);

    const personsData = formData?.form?.personnel_information?.persons?.length
      ? formData?.form?.personnel_information?.persons?.[currentKey]?.identification_document
          ?.is_same_residential_address
      : false;

    const addressData = isAddressSameCheckbox && isAddressSameCheckbox[0] === "checked" ? true : personsData;

    setIsResidentialAddressSame(addressData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  const PROOF_OF_RESIDENCE: IFormElement = {
    key:
      FORM_NAME.PERSONNEL_INFORMATION +
      KYX_FORMS_KEYS.PERSONNEL_DATA_PERSONS +
      currentKey +
      KYX_FORMS_KEYS.PERSON_DATA_ID_DOCUMENTS +
      getDocumentTypeKeyName() +
      KYX_FORMS_KEYS.PERSON_IS_RESIDENCE_ADDRESS_SAME,
    col: { span: 24 },
    element: {
      type: FORM_FIELD_TYPES.CHECKLIST,
      props: {
        formProps: {
          name: [KYX_FORMS_KEYS.PERSON_DATA_ID_DOCUMENTS, KYX_FORMS_KEYS.PERSON_IS_RESIDENCE_ADDRESS_SAME],
          required: true,
          validateStatus: getValidationstatus(remarks, [
            KYX_FORMS_KEYS.PERSONNEL_DATA_PERSONS,
            currentKey,
            KYX_FORMS_KEYS.PERSON_IS_RESIDENCE_ADDRESS_SAME,
          ]),
          help: getValidationError(
            remarks,
            [KYX_FORMS_KEYS.PERSONNEL_DATA_PERSONS, currentKey, KYX_FORMS_KEYS.PERSON_IS_RESIDENCE_ADDRESS_SAME],
            "Identity Documents",
            FORM_NAME.PERSONNEL_INFORMATION
          ),
          className: "mb-0",
        },
        checkProps: {
          options: [
            {
              label: "Residential address is different from the address indicated on identity documents",
              value: "checked",
            },
          ],
          onChange: () => {
            setIsResidentialAddressSame((prev) => {
              if (prev) {
                form.setFieldsValue({
                  [KYX_FORMS_KEYS.PERSONNEL_DATA_PERSONS]: {
                    [currentKey]: {
                      [KYX_FORMS_KEYS.PERSON_DATA_ID_DOCUMENTS]: {
                        [KYX_FORMS_KEYS.PERSON_DATA_PROOF_OF_RESIDENCE_DOCUMENT]: "",
                      },
                    },
                  },
                });
              }
              return !prev;
            });
          },
          disabled: isFormDisabled,
        },
      },
    },
  };

  const PROOF_OF_RESIDENCE_HEADER_AND_GUIDELINES: IFormElement =
    documentSelectionType === PERSON_DOCUMENT_TYPE.NRIC && !isResidentialAddressSame
      ? null
      : {
          key:
            FORM_NAME.PERSONNEL_INFORMATION +
            KYX_FORMS_KEYS.PERSONNEL_DATA_PERSONS +
            currentKey +
            KYX_FORMS_KEYS.PERSON_DATA_ID_DOCUMENTS +
            getDocumentTypeKeyName() +
            PERSONNEL_INFORMATION_FORM_ITEM_KEYS.PROOF_OF_RESIDENCE_HEADER_AND_GUIDELINES,
          col: { span: 24, style: { marginTop: "2rem" } },
          element: {
            type: FORM_FIELD_TYPES.HEADER,
            props: {
              title: "Proof of Address",
              guidelines: GUIDELINES_TYPE.PROOF_OF_ADDRESS,
            },
          },
        };

  const UPLOAD_PROOF_OF_RESIDENCE: IFormElement = {
    key:
      FORM_NAME.PERSONNEL_INFORMATION +
      KYX_FORMS_KEYS.PERSONNEL_DATA_PERSONS +
      currentKey +
      KYX_FORMS_KEYS.PERSON_DATA_ID_DOCUMENTS +
      getDocumentTypeKeyName() +
      KYX_FORMS_KEYS.PERSON_DATA_PROOF_OF_RESIDENCE_DOCUMENT,
    col: { span: 24 },
    element: {
      type: FORM_FIELD_TYPES.FILEUPLOAD,
      props: {
        formProps: {
          name: [
            KYX_FORMS_KEYS.PERSON_DATA_ID_DOCUMENTS,
            getDocumentTypeKeyName(),
            KYX_FORMS_KEYS.PERSON_DATA_PROOF_OF_RESIDENCE_DOCUMENT,
          ],
          label: "Upload Proof of Residence",
          required: documentSelectionType === PERSON_DOCUMENT_TYPE.NRIC && !isResidentialAddressSame ? false : true,
          dependencies: [KYX_FORMS_KEYS.PERSON_DATA_ID_DOCUMENTS, KYX_FORMS_KEYS.PERSON_IS_RESIDENCE_ADDRESS_SAME],
          rules: [
            {
              required: documentSelectionType === PERSON_DOCUMENT_TYPE.NRIC && !isResidentialAddressSame ? false : true,
              message: "Please upload a valid Proof of Residence",
            },
          ],
          // validateStatus: getValidationstatus(remarks, [
          //   KYX_FORMS_KEYS.PERSONNEL_DATA_PERSONS,
          //   currentKey,
          //   KYX_FORMS_KEYS.PERSON_DATA_PROOF_OF_RESIDENCE_DOCUMENT,
          // ]),
          // help: getValidationError(
          //   remarks,
          //   [KYX_FORMS_KEYS.PERSONNEL_DATA_PERSONS, currentKey, KYX_FORMS_KEYS.PERSON_DATA_PROOF_OF_RESIDENCE_DOCUMENT],
          //   "Proof of Residence",
          //   FORM_NAME.PERSONNEL_INFORMATION
          // ),
          style: {
            display:
              documentSelectionType === PERSON_DOCUMENT_TYPE.NRIC && !isResidentialAddressSame ? "none" : "block",
          },
          initialValue: formData?.form?.personnel_information?.persons
            ? formData?.form?.personnel_information?.persons[currentKey]?.identification_document[
                getDocumentTypeKeyName()
              ]?.proof_of_residence
            : "",
        },
        disabled: isFormDisabled,
        action: (arg) => {
          const personsObj = form.getFieldsValue()["persons"];
          const residenceDoc = {
            [KYX_FORMS_KEYS.PERSON_DATA_DOCUMENT_TYPE]: documentSelectionType,
            [getDocumentTypeKeyName()]: {
              ...personsObj[currentKey].identification_document[getDocumentTypeKeyName()],
              [KYX_FORMS_KEYS.PERSON_DATA_PROOF_OF_RESIDENCE_DOCUMENT]: arg,
            },
          };

          const newPersons: IPersonForm[] = Object.keys(personsObj).map((key, index) => {
            const data = {
              ...personsObj[key],
              roles_of_individual: personsObj[key]?.roles_of_individual
                ? Object.assign(
                    {},
                    ...personsObj[key]?.roles_of_individual?.map((role) => {
                      return role ? { [role]: true } : null;
                    })
                  )
                : {},
            };

            if (index !== parseInt(currentKey)) {
              // This isn't the person we care about - keep it as-is
              return {
                ...data,
                [KYX_FORMS_KEYS.PERSON_DATA_ID_DOCUMENTS]: {
                  ...data?.identification_document,
                  is_same_residential_address: personsObj[key]?.identification_document?.is_same_residential_address
                    ?.length
                    ? personsObj[key]?.identification_document?.is_same_residential_address?.[0] === "checked"
                    : false,
                },
              };
            }

            // Otherwise, this is the one we want - return an updated value
            return {
              ...data,
              [KYX_FORMS_KEYS.PERSON_DATA_ID_DOCUMENTS]: {
                ...residenceDoc,
                is_same_residential_address: personsObj[key]?.identification_document?.is_same_residential_address
                  ?.length
                  ? personsObj[key]?.identification_document?.is_same_residential_address?.[0] === "checked"
                  : false,
                [getDocumentTypeKeyName()]: {
                  ...personsObj[key].identification_document[getDocumentTypeKeyName()],
                  proof_of_residence: arg,
                },
              },
            } as IPersonForm;
          });

          saveFormAsDraft({
            id: formId,
            form: {
              ...formData.form,
              personnel_information: {
                ...formData.form.personnel_information,
                persons: newPersons,
              },
            },
          });

          return form.setFieldsValue({
            [KYX_FORMS_KEYS.PERSONNEL_DATA_PERSONS]: {
              [currentKey]: { [KYX_FORMS_KEYS.PERSON_DATA_ID_DOCUMENTS]: residenceDoc },
            },
          });
        },
      },
    },
  };

  return [
    documentSelectionType === PERSON_DOCUMENT_TYPE.NRIC && PROOF_OF_RESIDENCE,
    documentSelectionType && PROOF_OF_RESIDENCE_HEADER_AND_GUIDELINES,
    documentSelectionType && UPLOAD_PROOF_OF_RESIDENCE,
  ];
};
