export const BILLS_APPROVAL_EVENTS = {
  billApprovalButtonClicked: "Bill Approval Button Clicked",
  billRequestProcessed: "Bill Requests Successfully Processed",
};

export const CR_APPROVAL_EVENTS = {
  crApprovalButtonClicked: "CR Approval Button Clicked",
  crApprovalDetailsEdited: "CR Approval Details Edited",
  crRequestProcessed: "CR Requests Successfully Processed",
};

export const CARD_APPROVAL_EVENTS = {
  cardApprovalButtonClicked: "Cards Approval Button Clicked",
  cardApprovalDetailsEdited: "Cards Approval Details Edited",
  cardRequestProcessed: "Cards Requests Successfully Processed",
};

export const APPROVAL_EVENTS = {
  APPROVAL_PAGE_LOADED: "Approval Page Loaded",
};
