/*
  enums
*/

export enum CHIP_STATUS {
  SUCCESS = "success",
  ATTENTION = "attention",
  FAILED = "failed",
  INFO = "info",
  NEUTRAL = "neutral",
  STRONG = "strong",
}

export enum TEST_ID {
  INDICATOR = "indicator",
}

export enum TIER_CHIP_STATUS {
  STARTER = "starter", // Basic
  ESSENTIAL = "essential", // Premium
  CUSTOM = "custom", // Premium plan for Legacy and Enterprise
}

/*
  interfaces
*/

export interface IChip {
  title: string;
}

/// ./Action

export interface IActionChipProps extends IChip {
  active: boolean;
  selected: boolean;
  onClear: () => void;
  onClick: React.MouseEventHandler<HTMLDivElement>;
  iconClass?: string;
}

/// ./Title

export interface ITitleWithIcon extends IChip {
  className: string;
}

/// ./Tier
export interface TierProps {
  icon?: string;
  title?: string;
  status: TIER_CHIP_STATUS.ESSENTIAL | TIER_CHIP_STATUS.STARTER | TIER_CHIP_STATUS.CUSTOM;
  secondary?: boolean;
}

/*
  types
*/

/// ./Status

type IStatusProps =
  | { status: CHIP_STATUS.SUCCESS | CHIP_STATUS.INFO; secondary?: boolean }
  | {
      status: CHIP_STATUS.ATTENTION | CHIP_STATUS.FAILED | CHIP_STATUS.NEUTRAL | CHIP_STATUS.STRONG;
      secondary?: never;
    };

export type IStatusChipProps = IChip & IStatusProps;

/// ./Label

type ILabelProps =
  | {
      status:
        | CHIP_STATUS.SUCCESS
        | CHIP_STATUS.ATTENTION
        | CHIP_STATUS.FAILED
        | CHIP_STATUS.NEUTRAL
        | CHIP_STATUS.STRONG;
      onRemove?: never;
    }
  | {
      status: CHIP_STATUS.INFO;
      onRemove?: React.MouseEventHandler<HTMLButtonElement>;
    };

export type ILabelChipProps = IChip &
  ILabelProps & {
    className?: string;
    icon?: string;
  };
