import React from "react";
import IButton from "Modules/button";
import { csvExport } from "assets/img";

const DownloadRawCSVButton = (props) => {
  const { action } = props;

  return <IButton id="exportCsv" action={action} icon={<img alt="download" src={csvExport} />} text="Raw Data CSV" />;
};

export default DownloadRawCSVButton;
