import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import { Button } from "antd";
import MButton from "Modules/button";
import {
  useCheckApprovalFlowAllowed,
  useCheckApprovalFlowLoader,
} from "utility/permissions/approval";
import { trackEvent } from "utility/analytics";
import { useIsSessionExpired } from "customHooks/Auth/useIsSessionExpired";

import "./ApprovalFlow.scss";
import { APPROVAL_FLOW_EVENTS } from "./trackingEvents";
import Loader from "Views/State/Loading/LoaderIcon";
import Tabs from "Modules/DS/Tabs";
import { ApprovalFlowTabs, getApprovalFlowTabs } from "./helpers";
import Icon from "Modules/icons";
import {
  chevronDownApproval,
  billApprovalFlow,
  reimbursementApprovalFlow,
} from "assets/img";
import ContextualMenu from "Modules/DS/ContextualMenu";
import { ContextualMenuItem } from "Modules/DS/ContextualMenu/types";
import { setApprovalFlowActiveTab } from "Redux/Actions/ApprovalFlow/handleTabApprovalFlow";
import useCheckOrgConfigs from "../../customHooks/useCheckOrgConfigs";
import { clearGetApprovalFlowList } from "Redux/Actions/ApprovalFlow/getApprovalFlowList";
import SelfApprovalSidePanel from "./SelfApprovalSidePanel";
import Toaster from "Modules/DS/Toaster";
import {
  TOASTER_SIZE_TYPE,
  TOASTER_STATUS_TYPE,
} from "Modules/DS/Toaster/types.d";
import { IToasterProps } from "Views/Accounting/Settings/@types";
import { clearUpdateSelfApproval } from "Redux/Actions/ApprovalFlow/updateSelfApprovalUsers";
import { useIsSaasWithPaymentsOrg } from "customHooks/useIsSaasWithPaymentsOrg";

const ApprovalFlowMainPage: React.FC = (): JSX.Element => {
  const history = useHistory();
  const userInfo = useSelector((state: any) => state.userInfoReducer);

  const dispatch = useDispatch();
  const billProductConfig = useCheckOrgConfigs("billpay_flag");
  const cashReimbursementProductConfig = useCheckOrgConfigs(
    "cash_reimbursement_flag",
  );
  const isSessionExpired = useIsSessionExpired();
  const isSaasWithPaymentsOrg = useIsSaasWithPaymentsOrg();

  const updateSelfApprovalStatus = useSelector(
    (state: any) => state?.updateSelfApprovalReducer,
  );
  const [showSelfApprovalPanel, setShowSelfApprovalPanel] = useState(false);
  const [toaster, setToaster] = useState<IToasterProps>({
    show: false,
    message: "",
    type: TOASTER_STATUS_TYPE.SUCCESS,
  });

  const approvalFlowTabs = getApprovalFlowTabs(setShowSelfApprovalPanel).filter(
    (tabItem) =>
      tabItem.tabKey === "invoice"
        ? billProductConfig
        : cashReimbursementProductConfig,
  );

  const preselectTab = useSelector(
    (state: any) => state.approvalFlowTabReducer?.activeTab,
  );
  const checkIfPreselectTab = (approvalFlow: ApprovalFlowTabs) =>
    approvalFlow.tabKey === preselectTab;
  const initialTab = approvalFlowTabs.find(checkIfPreselectTab);

  const [activeTab, setActiveTab] = useState<ApprovalFlowTabs>(null);
  const isLoading = useCheckApprovalFlowLoader(userInfo);

  const isAllowed = useCheckApprovalFlowAllowed(userInfo);
  const options = [
    { label: "Bill Payment", value: "invoice", icon: billApprovalFlow },
    {
      label: "Reimbursement",
      value: "reimbursement",
      icon: reimbursementApprovalFlow,
    },
  ].filter((tabItem) =>
    tabItem.value === "invoice"
      ? billProductConfig
      : cashReimbursementProductConfig,
  );

  useEffect(() => {
    if (initialTab && !activeTab) {
      setActiveTab(initialTab);
    }
  }, [initialTab]);

  useEffect(() => {
    if (initialTab) {
      setActiveTab(initialTab);
    }
  }, []);

  useEffect(() => {
    if (!activeTab) {
      setActiveTab(approvalFlowTabs?.[0]);
    }
  }, [isSaasWithPaymentsOrg]);

  useEffect(() => {
    trackEvent(APPROVAL_FLOW_EVENTS.approvalFlowPageLoaded);
  }, []);

  useEffect(() => {
    if (activeTab) {
      dispatch(setApprovalFlowActiveTab(activeTab.tabKey));
    }
  }, [activeTab]);

  useEffect(() => {
    let msg = "";
    let toaster_type = TOASTER_STATUS_TYPE.SUCCESS;
    if (updateSelfApprovalStatus.error) {
      msg = "Failed to save Self Approval policy. Please try again.";
      toaster_type = TOASTER_STATUS_TYPE.ERROR;
      setToaster({
        show: true,
        message: msg,
        type: toaster_type,
      });
    } else if (updateSelfApprovalStatus?.data?.meta?.code === 200) {
      msg = "Successfully changed Self Approval Policy.";
      toaster_type = TOASTER_STATUS_TYPE.SUCCESS;
      setToaster({
        show: true,
        message: msg,
        type: toaster_type,
      });
      setShowSelfApprovalPanel(false);
    }
    dispatch(clearUpdateSelfApproval());
  }, [updateSelfApprovalStatus, dispatch]);

  if (
    [billProductConfig, cashReimbursementProductConfig].every(
      (config) => config === false,
    )
  ) {
    return <Redirect to={"/404"} />;
  }

  if (isLoading || !activeTab) {
    return <Loader />;
  }

  if (!isLoading && !isAllowed && !isSessionExpired) {
    history.push("/404");
  }

  const handleSetActiveTab = (activeKey): void => {
    const filteredTab = approvalFlowTabs?.find(
      (tab): Object => tab.tabKey === activeKey,
    );
    dispatch(clearGetApprovalFlowList());
    dispatch(setApprovalFlowActiveTab(filteredTab.tabKey));
    setActiveTab(filteredTab);
  };

  const openCreateFlow = ({ value }: ContextualMenuItem) => {
    history.push(`/approvalflow/create/${value}`);
  };

  const renderCreateApprovalFlowMenu = (option: ContextualMenuItem) => {
    return (
      <div className="approval-flow__action__option">
        <div className="approval-flow__action__option__icon">
          <Icon src={option?.icon} alt={option.label} />
        </div>
        <div className="approval-flow__action__option__label">
          {option?.label}
        </div>
      </div>
    );
  };

  return (
    <div className={"approval-flow__container"}>
      <div className={"approval-flow__header"}>
        <div>
          <h1 className={"approval-flow__title"}>Approval Policies</h1>
          <p className={"approval-flow__subtitle"}>
            Customize your company approval flow with single or multiple
            approvals.
          </p>
        </div>
        <div>
          <MButton
            className="approval-flow__self"
            action={() => setShowSelfApprovalPanel(true)}
          >
            Self Approval Policy
          </MButton>
          {showSelfApprovalPanel && (
            <SelfApprovalSidePanel
              visible
              setShowSelfApprovalPanel={setShowSelfApprovalPanel}
            />
          )}
        </div>
      </div>
      <div className={"approval-flow__action"}>
        <ContextualMenu
          values={[]}
          onChange={openCreateFlow}
          options={options}
          renderOption={renderCreateApprovalFlowMenu}
        >
          <Button>
            Create Approval Flow
            <Icon src={chevronDownApproval} alt={"create approval menu"} />
          </Button>
        </ContextualMenu>
      </div>
      <Tabs
        content={activeTab?.content}
        tabsList={approvalFlowTabs}
        activeKey={activeTab?.tabKey}
        action={handleSetActiveTab}
        destroyInactiveTabPane={true}
      />
      <Toaster
        size={TOASTER_SIZE_TYPE.M}
        visible={toaster.show}
        status={toaster.type}
        message={toaster.message}
        onClose={() => setToaster({ ...toaster, show: false })}
      />
    </div>
  );
};

export default ApprovalFlowMainPage;
