import React from "react";

import Popover, { POPOVER_WIDTH, POSITION_ALGO_TYPE } from "Modules/DS/Popover";

import { IDateGrid } from "Modules/RecurringDate/types";

import DateItem from "Modules/RecurringDate/Item";

import styles from "./styles.module.scss";

const DateGrid = ({ show = false, recurringDate, onChangeShow, onChangeDate }: IDateGrid) => {
  const handleOnClose = () => {
    onChangeShow(false);
  };

  const handleOnClick = (val: number) => {
    onChangeDate(val);
    handleOnClose();
  };

  return (
    <Popover
      active={show}
      className={styles.popover}
      width={POPOVER_WIDTH.R}
      onClickOutside={handleOnClose}
      useDynamicPosition
      positionAlgoType={POSITION_ALGO_TYPE.VERTICAL}
    >
      <div className={styles.content}>
        {Array.from<number>({ length: 31 }).map((_, index) => {
          const val = index + 1;

          return (
            <DateItem
              key={`date-${val}`}
              date={val}
              selected={val === recurringDate}
              onClickHandler={() => handleOnClick(val)}
            />
          );
        })}
      </div>
    </Popover>
  );
};

export default DateGrid;
