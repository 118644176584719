import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Form, Input } from "antd";
import LetterAvatar from "Modules/letterAvatar";
import { Toaster } from "Modules/toaster";

import { useSelector, useDispatch } from "react-redux";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import { createTeam, resetCreateTeamData } from "Redux/Actions/Teams/createTeamAction";
import { resetTeamProfileData } from "Redux/Actions/Teams/teamProfileAction";
import { useTeamActionContext } from "Views/Team/List/Actions/context";
import { TEAM_ACTION } from "Views/Team/List/Actions/constant";
import { trackEvent } from "utility/analytics";
import "./TeamsName.scss";
import classNames from "classnames";
import useActionsToShow from "Views/Team/hooks/UseActionsToShow";
import { useIsSaasWithPaymentsOrg } from "customHooks/useIsSaasWithPaymentsOrg";

const TeamName = ({ teamDetails, getTeamProfileData }) => {
  const dispatch = useDispatch();
  const { selectAction, selectTeam } = useTeamActionContext();
  const isPaymentEnabled = useIsSaasWithPaymentsOrg();
  // hard coded for the first Budget Profile
  const isHardCodedName = teamDetails?.type === "Organisation" && !isPaymentEnabled;

  const { Item } = Form;
  const [form] = Form.useForm();
  const [statusMessage, setStatusMessage] = useState("");
  const teamData = useSelector((state) => state?.teamReducer);
  const [showToaster, setToasterVisibility] = useState(false);
  const [addTeamStatus, setAddTeamStatus] = useState("");
  const createdTeamData = teamData?.createData?.payload;
  const team_name = createdTeamData?.name;
  const id = createdTeamData?.id;
  const teamActions = useActionsToShow({ ...teamDetails, your_role: teamDetails?.yourRole }); // your_role is added only until team api migration is complete
  const allowRename =
    teamActions.filter((teamAction) => teamAction.value === TEAM_ACTION.RENAME).length === 1 && !isHardCodedName;
  const inputRef = useRef(null);
  const inputElement = inputRef.current;
  const minWidth = 250;

  if (isHardCodedName) {
    teamDetails.name = "Default";
  }

  useEffect(() => {
    if (inputElement) {
      setTimeout(() => {
        if (inputElement.input) {
          if (inputElement.input.scrollWidth > 250) {
            inputElement.input.style.width = inputElement.input.scrollWidth + "px";
          } else {
            inputElement.input.style.width = minWidth + "px";
          }
        }
      }, 100);
    }
    return () => {
      if (inputElement) {
        inputElement.input.style.width = "auto";
      }
    };
  }, [inputElement, inputElement?.input, teamDetails?.name]);

  const inputWrapperStyles = classNames("team-profile-wrapper-name-field", {
    "with-rename-action": allowRename,
  });

  useEffect(() => {
    if (teamData?.createData?.status === HTTP_STATUS_CODE.OK) {
      setToasterVisibility(true);
      dispatch(getTeamProfileData(id));
      setAddTeamStatus("success");
      setStatusMessage(`You have successfully added ${team_name} Budget.`);
      setTimeout(() => {
        dispatch(resetCreateTeamData());
      }, 1000);
    }
    if (teamData?.errorMessage?.status > HTTP_STATUS_CODE.OK) {
      const errorMessage = teamData?.errorMessage?.payload?.status_message;
      setAddTeamStatus("error");
      setToasterVisibility(true);
      setStatusMessage(errorMessage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamData]);

  const onFinish = (values) => {
    dispatch(createTeam(values.team_name));
    trackEvent("create team name added");
  };

  useEffect(() => {
    return () => {
      dispatch(resetCreateTeamData());
      setAddTeamStatus("");
      dispatch(resetTeamProfileData());
    };
  }, [dispatch]);

  useEffect(() => {
    form.setFieldsValue({ team_name: teamDetails?.name });
  }, [form, teamDetails]);

  const handleRename = () => {
    selectTeam({
      ...teamDetails,
      team_name: teamDetails.name,
    });
    selectAction(TEAM_ACTION.RENAME);
  };
  const suffixProps = {};
  if (allowRename) {
    suffixProps.suffix = <span onClick={handleRename}>Rename</span>;
  }

  return (
    <div className="team-profile-wrapper__name">
      <div className="team-profile-wrapper__name-first-letter">
        <LetterAvatar bigSquare name={teamDetails?.name} />
      </div>
      <div className="team-profile-wrapper__name-text-field">
        <h2>Budget Name</h2>
        <Form
          form={form}
          className={inputWrapperStyles}
          validateTrigger={true}
          name={"teamName"}
          onFinish={(values) => onFinish(values)}
        >
          <Item
            name={"team_name"}
            initialValue={teamDetails?.name}
            rules={[
              {
                required: true,
                message: "Please Enter a Team name",
              },
            ]}
          >
            <Input
              ref={inputRef}
              disabled={true}
              {...suffixProps}
              placeholder={"Please Enter a team name"}
              id={"team_name"}
              className={"team-name__input"}
            />
          </Item>
        </Form>
        {}
        <Toaster
          status={addTeamStatus}
          message={statusMessage}
          visible={showToaster}
          closeFunc={() => setToasterVisibility(false)}
        />
      </div>
    </div>
  );
};

export default TeamName;

TeamName.propTypes = {
  teamDetails: PropTypes.object,
  getTeamProfileData: PropTypes.func,
};
