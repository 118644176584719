import React, { useEffect, useState } from "react";
import { Document, Page } from "react-pdf";
import cn from "classnames";
import { Typography, UploadOutline, CrossFilled, AddOutline } from "@spenmo/splice";

import { ALLOWED_FILE_EXTENSIONS } from "Views/UploadInvoice/const";
import { getFileListWithNames } from "Views/SubscriptionManagement/Helper";
import { useUploadFile } from "./useUpload";
import UploadFile from "Modules/UploadFile/UploadFile";

import styles from "./styles.module.scss";

export const MAX_FILE_SIZE_ALLOWED = 10 * 1024 * 1024; // 10MB

export const UploadContract = ({
  defaultFiles = [],
  onChange,
}: {
  defaultFiles?: string[];
  onChange: (path: string[]) => void;
}) => {
  const { trigger: uploadFileApiCall, reset } = useUploadFile();
  const [isUploading, setUploading] = useState(false);
  const [files, setFiles] = useState(getFileListWithNames(defaultFiles));

  const validateFile = (file: any) => {
    const fileExtension = file.name.split(".").pop().toLowerCase();
    const extensionValid = ALLOWED_FILE_EXTENSIONS.includes(fileExtension);
    const sizeValid = file.size <= MAX_FILE_SIZE_ALLOWED;
    const isFileValid = extensionValid && sizeValid;
    return isFileValid;
  };

  const uploadFileToServer = async (file: File, onSuccess: (url: string | null) => void) => {
    reset();
    await uploadFileApiCall(
      { file },
      {
        onSuccess,
      }
    );
  };

  const uploadFile = async ({ payload }) => {
    setUploading(true);
    const onSuccess = (url) => {
      setFiles(files.concat({ url, name: payload?.file.name }));
      setUploading(false);
    };
    await uploadFileToServer(payload?.file, onSuccess);
  };

  const uploadOnDrop: React.DragEventHandler<HTMLDivElement> = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    const uploadedFiles = e.dataTransfer.files;
    if (!Array.isArray(uploadedFiles)) {
      return;
    }

    setUploading(true);
    let paths = [];

    const onSuccess = (url: string | null) => {
      paths = paths.concat(url);
    };

    for (const file of Array.from(uploadedFiles)) {
      const isValid = validateFile(file);
      if (isValid) {
        await uploadFileToServer(file, onSuccess);
      }
    }
    setFiles(files.concat(paths));

    setUploading(false);
  };

  const deleteFile = (selectedUrl: string) => {
    setFiles(files.filter(({ url }) => url !== selectedUrl));
  };

  const isPdfFile = (file: string) => file.includes(".pdf");

  useEffect(() => {
    onChange(files.map((file) => file.url));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  if (files.length !== 0) {
    return (
      <>
        <div
          className={cn(styles.attachmentWithContent, {})}
          onDragOver={(e) => e.preventDefault()}
          onDrop={uploadOnDrop}
          data-testid="file-upload"
        >
          <div className={cn(styles.files)} data-testid="files-upload-list">
            {files.map(({ url, name }, index) => (
              <div key={index} className={styles.file}>
                {isPdfFile(url) ? (
                  <a
                    rel="noreferrer"
                    className={styles["pdf-preview"]}
                    href={url}
                    target="_blank"
                    data-testid="pdf-preview"
                  >
                    <Document file={url}>
                      <Page pageNumber={1} />
                    </Document>
                  </a>
                ) : (
                  <a href={url} rel="noreferrer" target="_blank" data-testid="image-preview">
                    <img src={url} alt="attachment" />
                  </a>
                )}
                <Typography size="m" variant="body-content" className={styles.fileName}>
                  {name}
                </Typography>
                <CrossFilled fill="currentColor" size="16" className={styles.delete} onClick={() => deleteFile(url)} />
              </div>
            ))}
            <UploadFile
              beforeUpload={validateFile}
              customRequest={uploadFile}
              className={styles.addMore}
              accept={ALLOWED_FILE_EXTENSIONS.map((item) => `.${item}`).join(",")}
              customPlaceholder={
                <Typography size="m" variant="body-content" className={styles.addMore}>
                  {isUploading ? (
                    "Uploading"
                  ) : (
                    <>
                      <AddOutline size="16" iconColor="var(--text-link-default)" />
                      Add More
                    </>
                  )}
                </Typography>
              }
            />
          </div>
        </div>
        <Typography size="caption-m" variant="body-content" tag="p" className={styles.hint}>
          Only PDF, PNG, and JPG formats are accepted. Max. file size : 10 MB per file
        </Typography>
      </>
    );
  }

  return (
    <>
      <section className={cn(styles.noFileUploader)} data-testid="empty-file-upload">
        <UploadFile
          beforeUpload={validateFile}
          loading={isUploading}
          customRequest={uploadFile}
          icon={<UploadOutline size="24" fill="var(--black-030)" width="2rem" height="2rem" />}
          withBrowseButton={false}
          listType={"picture-card"}
          text={
            <span>
              Drag and drop 1 file at a time or <span className={styles.browse}>browse</span> your computer
            </span>
          }
          className={styles.uploader}
          accept={ALLOWED_FILE_EXTENSIONS.map((item) => `.${item}`).join(",")}
        />
      </section>
      <Typography size="caption-m" variant="body-content" tag="p" className={styles.hint}>
        Only PDF, PNG, and JPG formats are accepted. Max. file size : 10 MB per file
      </Typography>
    </>
  );
};
