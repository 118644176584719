import { ConfigProvider, Radio, Table, Tabs } from "antd";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Button from "../button";
import SideSection from "../sideSection";
import "./recursiveTable.scss";

const RecursiveTable = ({
  banner,
  loading,
  title,
  buttonTitle,
  action,
  columnData = [],
  data = [],
  dataSource = [],
  rounded = false,
  pageSize = 10,
  rowAction,
  showTabs = false,
  tabTitles,
  tabAction,
  activeKey,
  children,
  showSideSection = false,
  popoverButton,
  className,
  onPageChange,
  total,
  pagination = true,
  pageNum = 0,
  filters = null,
  scroll,
  paginationPos = "bottom",
  sideSectionClass,
  rowSelection,
  rowSelectBtn,
  rowSelectAction,
  disableCheckbox,
  onSideSectionClose,
  rowClassName = "",
  primaryButton,
  subTabTitles = [],
  subTabAction,
  activeSubTab,
  bulkAction,
  selectedRows = [],
  customEmpty,
  titleSubline,
  search,
  customRender,
  additionalInfo,
  tabs,
}) => {
  const { TabPane } = Tabs;
  const [show, setShow] = useState(showSideSection);
  const [selectAll, setSelectAll] = useState(false);
  const [activePage, setActivePage] = useState(1);

  const onRowChange = (record, index) => {
    return {
      onClick: () => {
        rowAction(record, index);
        showSideSection && setShow(true);
      },
    };
  };

  const tableData = dataSource.map((item) => ({
    ...item,
    isSelected: selectedRows.includes(item.id),
  }));

  useEffect(() => {
    setShow(showSideSection);
  }, [showSideSection]);

  useEffect(() => {
    setActivePage(pageNum + 1);
  }, [pageNum]);

  const onRowSelectAction = () => {
    rowSelectAction(selectedRows);
    rowSelection && rowSelection([]);
  };

  const onSelectAll = (selected, selectedRows) => {
    setSelectAll(!selectAll);
    if (selectAll) {
      return rowSelection([]);
    }
    const unSyncedTransactions = selectedRows.filter((trx) => trx.xero_sync_status !== 2).map((trx) => trx.id);
    rowSelection(unSyncedTransactions);
  };

  const setRowSelection = {
    preserveSelectedRowKeys: true,
    selectedRowKeys: selectedRows,
    onChange: (selectedRowKeys) => {
      rowSelection(selectedRowKeys);
    },
    getCheckboxProps: (selectedRowKeys) => {
      return {
        disabled: selectedRowKeys.type === disableCheckbox,
      };
    },
    onSelectAll: onSelectAll,
  };

  return (
    <div className={`recursive-table ${className && className}`}>
      <div className={"recursive-table__container"}>
        <div className={"recursive-table__container__header"}>
          <h1 data-testid="recursive-table-title">{title}</h1>
          {titleSubline && <p className={"recursive-table__title-subline"}>{titleSubline}</p>}
          {data.physical_cards && data.virtual_cards && (
            <p>
              {data.physical_cards} physical cards,
              {data.virtual_cards} virtual cards
            </p>
          )}
          <div style={{ display: "flex" }} className={`action-btns${show ? "--wrapper" : ""}`}>
            {search && <div>{search}</div>}
            {filters && <div style={{ flex: 1 }}>{filters}</div>}
            <div style={{ flex: 1, display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
              {primaryButton && primaryButton}
              {rowSelectBtn && (
                <Button
                  id={"rowSelectBtn"}
                  className={"row-select-btn"}
                  rounded={rounded}
                  text={rowSelectBtn}
                  disabled={!selectedRows || selectedRows.length === 0}
                  action={onRowSelectAction}
                />
              )}
              {buttonTitle && (
                <Button
                  id={"actionButton"}
                  style={{ justifySelf: "flex-end" }}
                  rounded={rounded}
                  text={buttonTitle}
                  action={action}
                />
              )}
            </div>
            {popoverButton && popoverButton}
          </div>
        </div>
        {showTabs && (
          <Tabs
            defaultActiveKey={tabTitles[0]}
            activeKey={activeKey}
            className={"recursive-table__tabs"}
            onChange={tabAction}
          >
            {tabTitles.map((tab) => {
              let title = tab;
              let isDotted = false;
              let key = title;
              if (typeof tab === "object" && tab !== null) {
                title = tab.title;
                key = tab.key || tab.title;
                isDotted = tab.isDotted;
              }
              return (
                <TabPane
                  tab={
                    <div>
                      {title}
                      {isDotted && <div className="tab-dot" />}
                    </div>
                  }
                  key={key}
                  disabled={loading}
                />
              );
            })}
          </Tabs>
        )}
        {tabs && <div className="recursive-table-tabs">{tabs}</div>}
        {banner}
        {loading || (
          <div className="recursive-table__container__action-bar">
            {subTabTitles.length > 0 && (
              <Radio.Group defaultValue={activeSubTab} buttonStyle="solid" onChange={subTabAction}>
                {subTabTitles.map((subTab) => (
                  <Radio.Button key={subTab.key} value={subTab.key}>
                    {subTab.label} {subTab.count > 0 && <span className="subtab-count">{subTab.count}</span>}
                  </Radio.Button>
                ))}
              </Radio.Group>
            )}

            {bulkAction && bulkAction(selectedRows)}
          </div>
        )}
        {additionalInfo}
        {customRender || (
          <ConfigProvider renderEmpty={() => customEmpty}>
            <Table
              loading={loading}
              rowSelection={rowSelection && setRowSelection}
              rowKey={(record) => record.id}
              columns={columnData}
              dataSource={tableData}
              rowClassName={rowClassName}
              scroll={scroll && scroll}
              pagination={
                pagination && {
                  position: [paginationPos, "bottomRight"],
                  id: "pagination",
                  current: activePage,
                  showSizeChanger: false,
                  defaultPageSize: pageSize,
                  total: total,
                  onChange: (page) => {
                    onPageChange && onPageChange(page);
                    setActivePage(page);
                  },
                }
              }
              onRow={onRowChange}
            />
          </ConfigProvider>
        )}
      </div>
      {showSideSection && (
        <SideSection
          className={sideSectionClass}
          visible={show}
          onClose={() => {
            setShow(false);
            onSideSectionClose();
          }}
        >
          {children && children}
        </SideSection>
      )}
    </div>
  );
};

RecursiveTable.defaultProps = {
  banner: null,
  action: () => {},
  rowAction: () => {},
  tabAction: () => {},
  onSideSectionClose: () => {},
  customRender: null,
  additionalInfo: null,
};

RecursiveTable.propTypes = {
  banner: PropTypes.node,
  loading: PropTypes.bool,
  title: PropTypes.string,
  buttonTitle: PropTypes.string,
  columnData: PropTypes.array,
  dataSource: PropTypes.array,
  data: PropTypes.object,
  rounded: PropTypes.bool,
  action: PropTypes.func,
  rowAction: PropTypes.func,
  pageSize: PropTypes.number,
  pageNum: PropTypes.number,
  showTabs: PropTypes.bool,
  tabTitles: PropTypes.array,
  tabAction: PropTypes.func,
  activeKey: PropTypes.string,
  showSideSection: PropTypes.bool,
  children: PropTypes.any,
  popoverButton: PropTypes.any,
  className: PropTypes.string,
  total: PropTypes.any,
  onPageChange: PropTypes.func,
  pagination: PropTypes.bool,
  filters: PropTypes.element,
  scroll: PropTypes.object,
  paginationPos: PropTypes.string,
  sideSectionClass: PropTypes.string,
  selectionType: PropTypes.string,
  rowSelection: PropTypes.func,
  selectedRows: PropTypes.array,
  rowSelectBtn: PropTypes.string,
  rowSelectAction: PropTypes.func,
  onSideSectionClose: PropTypes.func,
  disableCheckbox: PropTypes.bool,
  rowClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  primaryButton: PropTypes.element,
  subTabTitles: PropTypes.array,
  subTabAction: PropTypes.func,
  activeSubTab: PropTypes.string,
  bulkAction: PropTypes.element,
  customEmpty: PropTypes.element,
  titleSubline: PropTypes.string,
  search: PropTypes.element,
  customRender: PropTypes.element,
  additionalInfo: PropTypes.element,
  tabs: PropTypes.element,
};

export default RecursiveTable;
