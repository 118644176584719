import ChooseOrganisation from "../Common/ChooseOrganisation";
import { AccountingSetup } from "../Common/types";
import SyncAccount from "./SyncAccount";
import SyncPeriod from "./SyncPeriod";

export const bankFeedConfig: AccountingSetup[] = [
  {
    label: "Select Xero Organisation",
    index: 0,
    Component: ChooseOrganisation,
  },
  {
    label: "Map your accounts",
    index: 1,
    Component: SyncAccount,
    needRefetch: true,
  },
  {
    label: "Select Sync Period",
    index: 2,
    Component: SyncPeriod,
    needRefetch: false,
  },
];

export const findBankfeedConfig = (chooseConnection: boolean, isPaymentEnabled: boolean) => {
  const bankFeedList = bankFeedConfig.filter((item) => {
    // disable Sync period for SaaS only
    if (!isPaymentEnabled && item.index === 2) {
      return false;
    }

    return true;
  });
  if (chooseConnection) {
    return bankFeedList;
  }

  return bankFeedList
    .filter((item) => {
      return item.index !== 0;
    })
    .map((item, index) => ({
      ...item,
      index,
    }));
};
