import APIclient from "API/Client";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import { GetUserId } from "utility";

export const FETCH_UPDATE_PROFILE_REQUEST = "FETCH_UPDATE_PROFILE_REQUEST";
export const FETCH_UPDATE_PROFILE_SUCCESS = "FETCH_UPDATE_PROFILE_SUCCESS";
export const FETCH_UPDATE_PROFILE_ERROR = "FETCH_UPDATE_PROFILE_ERROR";

export const fetchDataReq = (data) => {
  return {
    type: FETCH_UPDATE_PROFILE_REQUEST,
    data: data,
  };
};
export const fetchDataSuccess = (data) => {
  return {
    type: FETCH_UPDATE_PROFILE_SUCCESS,
    data: {
      payload: data.payload,
    },
  };
};
export const fetchDataError = (data) => {
  return {
    type: FETCH_UPDATE_PROFILE_ERROR,
    data: data,
  };
};

export const updateProfile = (data) => {
  const userId = GetUserId();

  return (dispatch) => {
    dispatch(fetchDataReq());
    APIclient.putData(`/api/v1/user/${userId}/activate-wallet`, { ...data })
      .then((data) => {
        if (data.data.status === HTTP_STATUS_CODE.OK) {
          dispatch(fetchDataSuccess(data.data));
        } else {
          dispatch(fetchDataError(data.data));
        }
      })
      .catch((err) => {
        dispatch(fetchDataError(err));
      });
  };
};
