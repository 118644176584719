import React, { useState } from "react";
import GaBenefitsAndHowItWorks from "Views/SecurityHub/GoogleAuthenticator/ActivateGA/GaBenefitsAndHowItWorks";
import GaActivationInfo from "Views/SecurityHub/GoogleAuthenticator/ActivateGA/GaActivationInfo";

const GoogleAuthenticator = () => {
  const [userVerfied, setUserVerified] = useState(false);
  return userVerfied ? <GaActivationInfo /> : <GaBenefitsAndHowItWorks onVerification={() => setUserVerified(true)} />;
};

export default GoogleAuthenticator;
