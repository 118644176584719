import React from "react";
import dayjs from "dayjs";
import { Typography } from "@spenmo/splice";

import { useMutableData } from "API/useData";
import { API_URL } from "Views/Bills/V2/constants";

import { useBill } from "Views/Bills/V2/context/BillContext";

import { GetOrgId } from "utility";
import styles from "./Recipient.module.scss";

const RecipientLog = () => {
  const { recipientSelectedID } = useBill();

  const { data: previewRecipient } = useMutableData(
    recipientSelectedID ? `${API_URL.disbursementV2}/orgs/${GetOrgId()}/vendors/${recipientSelectedID}` : null
  );

  const { createdInfo, lastModified } = previewRecipient?.data?.payload || {};

  if (!previewRecipient?.data?.payload) {
    return null;
  }

  return (
    <div className={styles.recipientLog}>
      {[createdInfo, lastModified].map((logItem, index) => {
        const { name, timestamp } = logItem;
        const title = index === 0 ? "Recipient created by" : "Recipient last modified by";

        const date = dayjs.unix(timestamp);

        return (
          <div key={title}>
            <Typography className={styles.title} variant="body-content" size="s" tag="p">
              {title}
            </Typography>
            <div className={styles.info}>
              <Typography className={styles.nameInitial} variant="body-content" size="m" tag="p">
                {name[0]}
              </Typography>
              <div>
                <Typography className={styles.name} variant="body-content" size="s" tag="p">
                  {name}
                </Typography>
                <Typography className={styles.date} variant="body-content" size="caption-m" tag="p">
                  {date.format("DD MMM YYYY")} at {date.format("hh:mma")}
                </Typography>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default RecipientLog;
