import { ArrowRightOutlined } from "@ant-design/icons";
import { Col, Row, Typography, Form, Input } from "antd";
import { landingSoon } from "assets/img";
import classNames from "classnames";
import useCheckOrgConfigs from "customHooks/useCheckOrgConfigs.tsx";
import useIsAryadana from "customHooks/useIsAryadana.tsx";
import moment from "moment";
import { BILL_MENU } from "Permission/Actions";
import { CASBIN_PAGES } from "Permission/Pages";
import usePermissionCheck from "Permission/usePermissionCheck";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { getOrgDetailFunc } from "Redux/Actions";
import { PRODUCT_NAME } from "Redux/ModularProduct";
import { GetBaseAuthObject } from "utility";
import { trackEvent } from "utility/analytics";
import AryadanaFooter, { ARYADANA_FOOTER_TYPE } from "Views/Bills/AryadanaFooter";
import { DYNAMIC_FIELD_ID_MAPPING } from "Views/Bills/const";
import LandingSoon from "Views/Bills/LandingSoon";
import Button from "../../Modules/button";
import { FILE_NAME_TIME_OUT } from "./const";
import { payloadParser } from "./helper";
import InvoiceForm from "./InvoiceForm";
import Preview from "./Preview";
import Uploader from "./Uploader";
import styles from "./UploadInvoice.module.scss";
import "./UploadInvoice.scss";
import { BillFooterTypes, BillFormTypes } from "./EditBillModal/types.d";
import DeleteDraftModal from "Views/Bills/ManageDraft/DeleteDraftModal";

import { postData } from "API/Client";

import { appNotification } from "Modules/appNotification/appNotification";
import { GetSingleInvoice } from "../../Redux/DataCalls/Invoices.api";
import { HTTP_STATUS_CODE } from "../../constants/HTTPStatusCode.constant";
import LoaderIcon from "../State/Loading/LoaderIcon";

const UploadInvoice = ({
  billID,
  type = BillFormTypes.New,
  footerType = BillFooterTypes.Default,
  onCloseModal = () => {},
  refreshList = () => {},
}) => {
  const permissionParam = [{ object: CASBIN_PAGES.BILL_MENU, action: BILL_MENU.READ }];
  const [IS_ALLOWED_BILL_MENU_READ, loading] = usePermissionCheck(permissionParam, true);
  const { Title } = Typography,
    isPageAvailable = useCheckOrgConfigs(PRODUCT_NAME.BILLPAY),
    dispatch = useDispatch(),
    history = useHistory(),
    location = useLocation(),
    buttonRef = useRef(),
    [form] = Form.useForm(),
    [orgDetail] = useSelector((state) => [state.b2bOrgDetailReducer]),
    invoiceFormRef = useRef();

  const isEditBill = type === BillFormTypes.Edit;
  const isDraftBill = type === BillFormTypes.Draft;
  const isEditModal = isEditBill || isDraftBill;

  useEffect(() => {
    dispatch(getOrgDetailFunc(GetBaseAuthObject().orgId));
  }, [dispatch]);

  const showAryadana = useIsAryadana();

  useEffect(() => {
    if (!IS_ALLOWED_BILL_MENU_READ && !loading) {
      history.push("/");
    }
  }, [IS_ALLOWED_BILL_MENU_READ]);

  useEffect(() => {
    if (location?.state?.id) setData();
  }, [location]);

  const [uploadedFiles, setUploadedFiles] = useState([]),
    [deletedAttachmentId, setDeletedAttachmentId] = useState([]),
    [isFromTransactionPage, setIsFromTransactionPage] = useState(false),
    [tempFileExt, setTempFileExt] = useState(""),
    [isLoadingOcr, setIsLoadingOcr] = useState(false),
    [isFirstUpload, setIsFirstUpload] = useState(true),
    [shouldSendOcrId, setShouldSendOcrId] = useState(false),
    [changedFileIndex, setChangedFileIndex] = useState(-1),
    [ocrResult, setOcrResult] = useState({
      recipientName: "",
      paymentAmount: "",
      paymentCurrency: null,
      billIssuedDate: undefined,
      invoiceNumber: "",
      recipientBank: "",
      recipientBankAccount: "",
      recipientBankCountry: "",
      recipientEmails: "",
      billDueDate: undefined,
      ocrId: "",
    }),
    [deleteDraftModalVisible, setDeleteDraftModalVisible] = useState(false),
    [currentIndex, setCurrentIndex] = useState(1),
    [isLoadingBillData, setIsLoadingBillData] = useState(false),
    [payloads, setPayloads] = useState({}),
    [billsData, setBillsData] = useState({});

  const isMultipleData = billID?.length > 1;
  const currentID = billID?.[currentIndex - 1];
  const billData = billsData[currentID];

  const fetchBillData = async (id) => {
    setIsLoadingBillData(true);
    try {
      const invoiceResponse = await GetSingleInvoice(id, { source: "edit_bill" });
      if (invoiceResponse.status === HTTP_STATUS_CODE.OK) {
        setIsLoadingBillData(false);
        setBillsData((prevData) => ({ ...prevData, [id]: { id, ...invoiceResponse.payload } }));
      } else {
        throw invoiceResponse;
      }
    } catch (e) {
      setIsLoadingBillData(false);
      console.error(e);
    }
  };

  useEffect(() => {
    billID?.forEach((id) => {
      fetchBillData(id).catch((e) => console.error(e));
    });
  }, [billID]);

  const onValidateFile = (fileExt) => {
    setTempFileExt(fileExt);
  };

  const setData = () => {
    setIsFromTransactionPage(true);
    setIsFirstUpload(false);

    setOcrResult({
      ...ocrResult,
      recipientName: location?.state?.data?.beneficiary?.name,
      paymentAmount: location?.state?.data?.amount,
      paymentCurrency: location?.state?.data?.currency,
      invoiceNumber: location?.state?.data?.invoiceNumber,
      recipientEmails: location?.state?.data?.beneficiary?.email,
      billDueDate:
        location?.state?.data?.paymentScheduleTime !== "-" &&
        moment(location?.state?.data?.paymentScheduleTime || new Date(), "YYYY-MM-DD"),
    });

    if (location?.state?.data?.attachments?.length) {
      const tempUploadedFiles = [];
      location.state.data.attachments.forEach((item) =>
        tempUploadedFiles.push({
          id: item.id,
          url: item.url,
          editable: true,
          type: item.url.split(/[.]+/).pop().split("?")[0],
        })
      );
      setUploadedFiles(tempUploadedFiles);
    }
  };

  const isValidBIC = (bic) => {
    if (!bic) return false;

    return bic.value && bic.confidence === 100;
  };

  const onUploadFile = (data) => {
    if (changedFileIndex === -1) {
      setUploadedFiles([...uploadedFiles, { url: data.fileUrl, type: tempFileExt, file: data.file, isFirstUpload }]);
    } else {
      const newUploadedFiles = [...uploadedFiles];
      newUploadedFiles[changedFileIndex] = { url: data.fileUrl, type: tempFileExt, file: data.file };
      setUploadedFiles(newUploadedFiles);
      setChangedFileIndex(-1);
      setShouldSendOcrId(false);
    }

    if (isFirstUpload && !isEditModal) {
      const dynamicFieldByFieldID = form.getFieldValue("dynamicFieldsByFieldID");
      let ocrRecipientCountry = data.recipientBankCountry?.value;
      let iban = "";
      if (isValidBIC(data.recipientBankAccount) && isNaN(data.recipientBankAccount?.value.slice(0, 2))) {
        ocrRecipientCountry = data.recipientBankAccount?.value.slice(0, 2);
        iban = data.recipientBankAccount?.value;
      } else if (isValidBIC(data.recipientRoutingNo)) {
        ocrRecipientCountry = "US";
      } else if (isValidBIC(data.recipientIfsc)) {
        ocrRecipientCountry = "IN";
      } else if (isValidBIC(data.recipientSwift) && data.recipientSwift?.value?.length > 6) {
        ocrRecipientCountry = data.recipientSwift?.value.slice(4, 6);
      } else if (!Boolean(ocrRecipientCountry)) {
        ocrRecipientCountry = orgDetail?.data?.payload?.countryCode;
      }
      setOcrResult({
        ...ocrResult,
        recipientBankCountry:
          !Boolean(form.getFieldValue("countryCode")) ||
          form.getFieldValue("countryCode") === orgDetail?.data?.payload?.countryCode
            ? ocrRecipientCountry
            : null,
        paymentCurrency:
          !Boolean(form.getFieldValue("currencyCode")) ||
          form.getFieldValue("currencyCode") === orgDetail?.data?.payload?.currency
            ? data.paymentCurrency?.value
            : null,
        paymentAmount: !Boolean(form.getFieldValue("paymentAmount")) ? data.paymentFinalAmount?.value : null,
        billIssuedDate:
          data.paymentIssueDate?.value &&
          moment(data.paymentIssueDate?.value) < moment().subtract(1, "day") &&
          !Boolean(form.getFieldValue("billIssuedDate"))
            ? moment(data.paymentIssueDate?.value, "YYYY-MM-DD")
            : undefined,
        billDueDate:
          data.paymentDueDate?.value && !Boolean(form.getFieldValue("billDueDate"))
            ? moment(data.paymentDueDate?.value, "YYYY-MM-DD")
            : undefined,
        billScheduledDate:
          data.paymentScheduledDate?.value &&
          moment(data.paymentScheduledDate?.value) > moment().subtract(1, "day") &&
          !Boolean(form.getFieldValue("paymentDate"))
            ? moment(data.paymentScheduledDate?.value, "YYYY-MM-DD")
            : undefined,
        recipientName: !Boolean(dynamicFieldByFieldID?.[DYNAMIC_FIELD_ID_MAPPING.beneficiaryName])
          ? data.recipientName?.value
          : "",
        recipientBank: data.recipientBank?.value,
        recipientBankAccount: !Boolean(dynamicFieldByFieldID?.[4]) ? data.recipientBankAccount?.value : null,
        recipientEmails: data.recipientEmail?.value,
        invoiceNumber: !Boolean(form.getFieldValue("invoiceNumber")) ? data.invoiceNo?.value : "",
        notes: !Boolean(form.getFieldValue("remarks")) ? data.notes?.value : "",
        terms: !Boolean(form.getFieldValue("notes")) ? data.terms?.value : "",
        tax: data.paymentTax?.value,
        category: data.invoiceAutoCategory?.value,
        ocrId: data.ocrID,
        recipientIBAN: !Boolean(dynamicFieldByFieldID?.[DYNAMIC_FIELD_ID_MAPPING.IBAN]) ? iban : null,
        recipientCity: !Boolean(dynamicFieldByFieldID?.[DYNAMIC_FIELD_ID_MAPPING.recipientCity])
          ? data.recipientAddressCity?.value
          : null,
        recipientAddress: !Boolean(dynamicFieldByFieldID?.[DYNAMIC_FIELD_ID_MAPPING.recipientAddress])
          ? data.recipientAddress?.value
          : null,
        recipientSWIFT: !Boolean(dynamicFieldByFieldID?.[DYNAMIC_FIELD_ID_MAPPING.swiftCode])
          ? data.recipientSwift?.value
          : null,
        recipientIFSC: !Boolean(dynamicFieldByFieldID?.[DYNAMIC_FIELD_ID_MAPPING.IFSC])
          ? data.recipientIfsc?.value
          : null,
        recipientRoutingNo: !Boolean(dynamicFieldByFieldID?.[DYNAMIC_FIELD_ID_MAPPING.routingNumber])
          ? data.recipientRoutingNo?.value
          : null,
        recipientBSB: !Boolean(dynamicFieldByFieldID?.[DYNAMIC_FIELD_ID_MAPPING.BSB]) ? data.bsbCode?.value : null,
        recipientSortCode: !Boolean(dynamicFieldByFieldID?.[DYNAMIC_FIELD_ID_MAPPING.sortCode])
          ? data.recipientSortCode?.value
          : null,
      });
      setIsFirstUpload(false);
      setShouldSendOcrId(true);
    }
  };

  const onDelete = async (index) => {
    if (uploadedFiles[index].isFirstUpload) {
      setShouldSendOcrId(false);
    }

    if (uploadedFiles[index].editable) {
      setDeletedAttachmentId([...deletedAttachmentId, uploadedFiles[index].id]);
    }

    setUploadedFiles(uploadedFiles.filter((_, idx) => idx !== index));
  };

  const onChange = (index) => {
    setChangedFileIndex(index);
    buttonRef.current.click();
  };

  const onFocus = () => {
    // handle user click `cancel` on upload file dialog when changing invoice
    setTimeout(() => {
      setChangedFileIndex(-1);
    }, FILE_NAME_TIME_OUT);
  };

  useEffect(() => {
    window.addEventListener("focus", onFocus);
    return () => {
      window.removeEventListener("focus", onFocus);
    };
  });

  useEffect(() => {
    trackEvent("bill visit create single entry page");
  }, []);

  useEffect(() => {
    if (isEditModal && billData?.attachments) {
      setIsFirstUpload(false);
      const attachments = billData.attachments?.map((item) => {
        const splittedUrl = item?.url?.split("?")?.[0]?.split(".") || [];
        const type = splittedUrl[splittedUrl.length - 1];
        return { ...item, type, editable: true };
      });
      setUploadedFiles(attachments);
    }
  }, [billData, isEditModal]);

  const billId = (isEditModal && billData?.id) || location?.state?.id;

  if (!isPageAvailable) {
    return (
      <LandingSoon
        iconSrc={landingSoon}
        iconAlt="Landing Soon"
        title="Landing Soon"
        body="Bill Payment will be available in your region soon"
      />
    );
  }

  const handleDeleteDraft = async (id) => {
    return postData("/ms/spm-disbursement/v1/bill/draft/bulk-delete", {
      draftIDs: [id],
    })
      .then((res) => {
        const { data } = res;
        if (data.status !== 200) {
          throw new Error(data.status);
        }
        refreshList();
        onCloseModal();

        appNotification.success({
          message: "Bill has been successfully deleted from drafts",
        });
      })
      .catch(() => {
        appNotification.error({
          message: "An error occurred when deleting selected bill(s). Please try again.",
        });
      });
  };

  const handleSaveDraft = async () => {
    if (invoiceFormRef.current) {
      const draftPayload = await invoiceFormRef.current.getDraftPayload();
      invoiceFormRef.current.saveDraft([draftPayload]);
    }
  };

  const handleSaveMultipleDraft = async () => {
    if (invoiceFormRef.current) {
      const draftPayload = await invoiceFormRef.current.getDraftPayload();
      const payload = [draftPayload];
      Object.keys(payloads).forEach((key) => {
        if (key !== currentID) payload.push(payloads[key]);
      });
      invoiceFormRef.current.saveDraft(payload);
    }
  };

  const updateObject = (oldObj, newObj) => {
    let result = { ...oldObj };

    Object.keys(newObj).forEach((key) => {
      if (newObj[key]?.constructor === Object) {
        result[key] = updateObject(result[key], newObj[key]);
      } else {
        result[key] = newObj[key];
      }
    });

    return result;
  };

  const updateDraftPayload = async () => {
    const draftPayload = invoiceFormRef.current && (await invoiceFormRef.current.getDraftPayload());
    setPayloads({ ...payloads, [currentID]: draftPayload });
    setBillsData({ ...billsData, [currentID]: updateObject(billsData[currentID], payloadParser(draftPayload)) });
  };

  const pageControl = (
    <div className={styles.pageControl}>
      <span>Draft</span>
      <Input
        className={styles.pageInput}
        value={currentIndex}
        onChange={(e) => {
          updateDraftPayload().catch((e) => console.error(e));
          const idx = Number.isNaN(e.target.value) ? currentIndex : parseInt(e.target.value);
          setCurrentIndex(idx <= billID?.length && idx > 0 ? idx : currentIndex);
        }}
      />
      <span className={styles.pageTotal}>of {billID?.length}</span>
      <Button
        className={styles.pageButton}
        disabled={currentIndex <= 1}
        action={() => {
          updateDraftPayload().catch((e) => console.error(e));
          setCurrentIndex(currentIndex - 1);
        }}
      >
        &lt;
      </Button>
      <Button
        className={styles.pageButton}
        disabled={currentIndex >= billID?.length}
        action={() => {
          updateDraftPayload().catch((e) => console.error(e));
          setCurrentIndex(currentIndex + 1);
        }}
      >
        &gt;
      </Button>
    </div>
  );

  const deleteDraftModal = (
    <DeleteDraftModal
      visible={deleteDraftModalVisible}
      setVisible={setDeleteDraftModalVisible}
      action={() => {
        onCloseModal();
        handleDeleteDraft(billData?.id);
      }}
    />
  );

  const renderFooter = () => {
    let actionButtons;
    let ctaButton;

    switch (footerType) {
      case BillFooterTypes.DraftWithoutSubmit:
        actionButtons = (
          <>
            <Button
              className={styles.deleteButton}
              textButton
              action={() => {
                setDeleteDraftModalVisible(true);
              }}
            >
              Delete This Draft
            </Button>
            {deleteDraftModal}
          </>
        );
        ctaButton = (
          <Button className={styles.submit} action={handleSaveMultipleDraft}>
            Save Changes
            <ArrowRightOutlined />
          </Button>
        );
        break;
      case BillFooterTypes.Draft:
        actionButtons = (
          <>
            <Button
              className={styles.deleteButton}
              textButton
              action={() => {
                setDeleteDraftModalVisible(true);
              }}
            >
              Delete Draft
            </Button>
            <Button className={styles.saveButton} textButton action={handleSaveDraft}>
              Save Draft and Exit
            </Button>
            {deleteDraftModal}
          </>
        );
        ctaButton = (
          <Button className={styles.submit} action={() => invoiceFormRef.current?.preview()}>
            Preview Payment Details
            <ArrowRightOutlined />
          </Button>
        );
        break;
      case BillFooterTypes.Default:
      default:
        ctaButton = (
          <Button className={styles.submit} action={() => invoiceFormRef.current?.preview()}>
            Preview Changes
            <ArrowRightOutlined />
          </Button>
        );
    }

    return (
      <>
        <div className={styles.footer}>
          <div className={styles.actions}>{actionButtons}</div>
          {ctaButton}
        </div>
      </>
    );
  };

  return isLoadingBillData ? (
    <LoaderIcon />
  ) : (
    <div className={isEditModal && styles.wrapper}>
      {isMultipleData && pageControl}
      <Row
        className={classNames(styles.container, "upload-invoice-container", {
          [styles.edit]: isEditModal,
          [styles.multiPage]: isMultipleData,
        })}
      >
        <Col span={12} className={classNames(styles.col, styles.left)}>
          <div>
            {uploadedFiles.length > 0 &&
              uploadedFiles.map((item, index) => (
                <Preview
                  key={index}
                  url={item.url}
                  type={item.type}
                  canChangeFile={!item.editable}
                  onChange={() => onChange(index)}
                  onDelete={() => onDelete(index)}
                />
              ))}
            <Uploader
              title={isFirstUpload ? "Upload invoice file" : "Upload more files"}
              subtitle={isFirstUpload ? "We’ll extract the details automatically." : ""}
              useOcr={isFirstUpload && !isEditModal}
              onValidateFile={onValidateFile}
              onUploadFile={onUploadFile}
              setIsLoadingOcr={setIsLoadingOcr}
              ref={buttonRef}
            />
          </div>
          {showAryadana && (
            <div className={styles.aryadana}>
              <AryadanaFooter type={ARYADANA_FOOTER_TYPE.TWO_LINES} />
            </div>
          )}
        </Col>
        <Col span={12} className={classNames(styles.col, styles.right)}>
          <Title className={styles.title}>Payment details</Title>
          <InvoiceForm
            key={billId}
            ref={invoiceFormRef}
            billData={billData}
            form={form}
            ocrResult={ocrResult}
            isLoadingOcr={isLoadingOcr}
            isFromTransactionPage={isFromTransactionPage}
            id={billId}
            uploadedFiles={uploadedFiles}
            shouldSendOcrId={shouldSendOcrId}
            deletedAttachmentId={deletedAttachmentId}
            billFormType={type}
          />
        </Col>
      </Row>
      {isEditModal && renderFooter()}
      {isDraftBill && deleteDraftModal}
    </div>
  );
};

UploadInvoice.propTypes = {
  type: PropTypes.string,
  billID: PropTypes.number || PropTypes.arrayOf(PropTypes.number),
  onCloseModal: PropTypes.func,
  refreshList: PropTypes.func,
};

export default UploadInvoice;
