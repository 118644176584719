import APIclient from "API/Client";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";

export const FETCH_TEAM_PROFILE_DATA = "FETCH_TEAM_PROFILE_DATA";
export const FETCH_TEAM_PROFILE_DATA_REQUEST = "FETCH_TEAM_PROFILE_DATA_REQUEST";
export const FETCH_TEAM_PROFILE_DATA_SUCCESS = "FETCH_TEAM_PROFILE_DATA_SUCCESS";
export const FETCH_TEAM_PROFILE_DATA_FAILURE = "FETCH_TEAM_PROFILE_DATA_FAILURE";
export const RESET_TEAM_PROFILE_DATA = "TEAM_PROFILE_DATA";
export const UPDATE_TEAM_PROFILE_NAME = "UPDATE_TEAM_PROFILE_NAME";

export const fetchDataRequest = () => {
  return {
    type: "FETCH_TEAM_PROFILE_DATA_REQUEST",
  };
};

export const fetchDataSuccess = (data) => {
  return {
    type: "FETCH_TEAM_PROFILE_DATA_SUCCESS",
    data: data,
  };
};

export const fetchDataError = (data) => {
  return {
    type: "FETCH_TEAM_PROFILE_DATA_FAILURE",
    data: data,
  };
};

export const resetTeamProfileData = () => {
  return {
    type: RESET_TEAM_PROFILE_DATA,
  };
};

export const updateTeamProfileName = (data) => {
  return {
    type: UPDATE_TEAM_PROFILE_NAME,
    data: data,
  };
};

export const getTeamProfileData = (teamId) => {
  return (dispatch) => {
    dispatch(fetchDataRequest());
    APIclient.getData(`/ms/spm-organisation/v1/mgmt/budgets/${teamId}`)
      .then((data) => {
        if (data.data.status === HTTP_STATUS_CODE.OK) {
          dispatch(fetchDataSuccess(data.data));
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(fetchDataError(err));
      });
  };
};
