import APIclient from "API/Client";
import { GetFormData } from "../../utility/APIWrapper";
import { trackEvent } from "utility/analytics";
import { getPartnerTaxes } from "Redux/DataCalls/Taxes.api";

export const FETCH_TAX_REQUEST = "FETCH_TAX_REQUEST";
export const FETCH_TAX_SUCCESS = "FETCH_TAX_SUCCESS";
export const FETCH_TAX_ERROR = "FETCH_TAX_ERROR";

export const FETCH_XERO_TAX_REQUEST = "FETCH_XERO_TAX_REQUEST";
export const FETCH_XERO_TAX_SUCCESS = "FETCH_XERO_TAX_SUCCESS";
export const FETCH_XERO_TAX_ERROR = "FETCH_XERO_TAX_ERROR";

export const fetchTaxReq = (data) => {
  return {
    type: "FETCH_TAX_REQUEST",
    data: data,
  };
};

export const fetchTaxSuccess = (data) => {
  return {
    type: "FETCH_TAX_SUCCESS",
    data: data,
  };
};

export const fetchTaxError = (data) => {
  return {
    type: "FETCH_TAX_ERROR",
    data: data,
  };
};

export const fetchXeroTaxReq = (data) => {
  return {
    type: FETCH_XERO_TAX_REQUEST,
    data: data,
  };
};

export const fetchXeroTaxSuccess = (data) => {
  return {
    type: FETCH_XERO_TAX_SUCCESS,
    data: data,
  };
};

export const fetchXeroTaxError = (data) => {
  return {
    type: FETCH_XERO_TAX_ERROR,
    data: data,
  };
};

export const createTaxFunc = (values, trackActions = false) => {
  return (dispatch) => {
    dispatch(fetchTaxReq());

    APIclient.postData("/api/v1/tax/", GetFormData({ ...values }))
      .then((res) => {
        if (res.data.status > 200) {
          dispatch(fetchTaxError(res.data));
          trackActions && trackEvent("add new tax fail");
        } else {
          dispatch(fetchTaxSuccess(res.data));
          trackActions && trackEvent("add new tax success");
        }
      })
      .catch((err) => {
        dispatch(fetchTaxError(err));
        trackActions && trackEvent("add new tax fail");
      });
  };
};

export const editTaxFunc = (values, taxId, trackActions = false) => {
  return (dispatch) => {
    dispatch(fetchTaxReq());

    APIclient.putData(`/api/v1/tax/${taxId}`, { ...values })
      .then((res) => {
        if (res.data.status > 200) {
          dispatch(fetchTaxError(res.data));
          trackActions && trackEvent("edit tax fail");
        } else {
          dispatch(fetchTaxSuccess(res.data));
          trackActions && trackEvent("edit tax success");
        }
      })
      .catch((err) => {
        dispatch(fetchTaxError(err));
        trackActions && trackEvent("edit tax fail");
      });
  };
};

export const getAllTaxesFunc = (params = {}) => {
  return (dispatch) => {
    dispatch(fetchTaxReq());

    const importedTax = params.importedTax || [];

    if (params.importedTax) {
      delete params.importedTax;
    }

    const searchParams = new URLSearchParams(params);

    APIclient.getData("/api/v1/tax?" + searchParams)
      .then((res) => {
        if (res.data.status > 200) {
          dispatch(fetchTaxError(res.data));
        } else {
          dispatch(fetchTaxSuccess(injectImportedTax(res.data, importedTax)));
        }
      })
      .catch((err) => {
        dispatch(fetchTaxError(err));
      });
  };
};

const injectImportedTax = (response, importedTaxes) => {
  const taxes = response?.payload?.data || [];

  return {
    ...response,
    payload: {
      ...response.payload,
      data: taxes.map((tax) => {
        if (importedTaxes.find((importedTax) => tax.partner_tax_type === importedTax.partner_tax_type)) {
          return {
            ...tax,
            isNew: true,
          };
        }
        return tax;
      }),
    },
  };
};

export const getAllPartnerTaxes = () => {
  return (dispatch) => {
    getPartnerTaxes()
      .then(({ data }) => {
        if (data.status === 200) {
          dispatch(fetchXeroTaxSuccess(data));
        } else {
          dispatch(fetchXeroTaxError());
        }
      })
      .catch(() => {
        dispatch(fetchXeroTaxError());
      });
  };
};
