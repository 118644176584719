import React from "react";
import classNames from "classnames";

import { IKYXIconButton } from "Views/KYX/@types";

import styles from "./index.module.scss";

export const KYXIconButton = (props: IKYXIconButton) => {
  return (
    <div className={styles.container}>
      <button type="button" {...{ ...props, className: classNames(styles.delete, props.className) }} />
    </div>
  );
};
