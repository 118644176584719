import * as React from "react";
import { Button } from "antd";

import "Views/Card/Edit/ActionBtns/index.scss";

export interface ISpendingLockDrawerActionButtonsProps {
  onCancel: () => void;
  onSave: () => void;
}

export default function SpendingLockDrawerActionButtons({ onCancel, onSave }: ISpendingLockDrawerActionButtonsProps) {
  return (
    <div className="card-edit-actions__container">
      <Button
        className="card-edit-actions__btn card-edit-actions__btn--transparent"
        onClick={(e) => {
          e.preventDefault();
          onCancel();
        }}
      >
        Cancel
      </Button>
      <Button
        className="card-edit-actions__btn card-edit-actions__btn--primary"
        onClick={(e) => {
          e.preventDefault();
          onSave();
        }}
      >
        Confirm
      </Button>
    </div>
  );
}
