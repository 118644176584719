import React from "react";
import { Link, useLocation } from "react-router-dom";

import { SUBSCRIPTION_PATH } from "Route/Subscription/path";
import { Button, ChevronLeft, Typography, EditOutline } from "@spenmo/splice";
import { Action } from "./Action";
import { useSubscriptionContext } from "Views/SubscriptionManagement/Details/SubscriptionContext";
import { SUBSCRIPTION_STATUS } from "Views/SubscriptionManagement/Constants";
import { TOGGLE_SUBSCRIPTION_STATUS_PARAMS } from "Views/SubscriptionManagement/Permission";
import usePermissionCheck from "Permission/usePermissionCheck";

import styles from "./index.module.scss";

interface IHeaderProps {
  id: string;
  vendorIcon: React.JSX.Element;
  isAllowedToUpdateSubscription: boolean;
}

export const Header = ({ vendorIcon, id, isAllowedToUpdateSubscription }: IHeaderProps) => {
  const { subscription } = useSubscriptionContext();
  const location = useLocation<{ filters?: string }>();
  const params = location.state?.filters || "";
  const { status } = subscription;
  const [IS_ALLOWED_TO_DEACTIVATE_SUBSCRIPTION, IS_ALLOWED_TO_REACTIVATE_SUBSCRIPTION] =
    (usePermissionCheck(TOGGLE_SUBSCRIPTION_STATUS_PARAMS) as boolean[]) || [];

  const isAllowedToToogleStatus = () => {
    if (status === SUBSCRIPTION_STATUS.ACTIVE) {
      return IS_ALLOWED_TO_DEACTIVATE_SUBSCRIPTION;
    } else if (status === SUBSCRIPTION_STATUS.CANCELLED) {
      return IS_ALLOWED_TO_REACTIVATE_SUBSCRIPTION;
    }
    return false;
  };

  return (
    <>
      <div>
        <Link to={SUBSCRIPTION_PATH.SUBSCRIPTION_MANAGEMENT + params} className={styles.linkBtn}>
          <ChevronLeft size="16" iconColor="var(--text-link-default)" />
          <Typography variant="body-content" size="caption-m" weight={600}>
            Back to Subscriptions
          </Typography>
        </Link>
      </div>
      <div className={styles.vendorDetailsContainer}>
        <div className={styles.vendorDetails}>
          {vendorIcon}
          <Typography variant="headline-content" size="m">
            {subscription.vendor?.name}
          </Typography>
        </div>
        <div className={styles.actionsContainer}>
          {isAllowedToUpdateSubscription && (
            <Link to={`${SUBSCRIPTION_PATH.SUBSCRIPTION_MANAGEMENT}/details/${id}/edit`}>
              <Button
                variant="secondary"
                size="s"
                icon={() => <EditOutline size="16" iconColor="var(--text-link-default)" />}
                className={styles.updateBtn}
              >
                Update
              </Button>
            </Link>
          )}
          {isAllowedToToogleStatus() && <Action />}
        </div>
      </div>
    </>
  );
};
