import * as React from "react";
import { ICardDetails } from "Views/Card/types";
import { CARD_DETAILS_TABS } from "Views/Card/List/types";
import CardDetailsContext from "Views/Card/Details/index.context";
import Drawer from "Views/Card/Components/Drawer";
import Modal from "Views/Card/Details/Modal";
import CardDetailsShimmer from "Views/Card/Details/Shimmer";
import Card from "Views/Card/Details/Card";
import CardDetailsInfo from "Views/Card/Details/Info";
import CardDetailsSubInfo from "Views/Card/Details/SubInfo";
import CardDetailsActionBtns from "Views/Card/Details/ActionBtns";

import styles from "Views/Card/Details/index.module.scss";

interface CardDetailsProps {
  cardData: ICardDetails;
  onClose: () => void;
  onEdit: () => void;
  onCardRequestDelete: () => void;
  onCardDelete: () => void;
  onPhysicalCardSync: () => void;
  onCardStatusToggle: (status: boolean) => void;
  cardDetailsInitialTab: CARD_DETAILS_TABS;
  showCardDetailsInModal?: boolean;
  cardDataLoading?: boolean;
}

const CardDetails = ({
  cardData,
  onClose,
  onEdit,
  onCardRequestDelete,
  onCardDelete,
  onPhysicalCardSync,
  onCardStatusToggle,
  cardDetailsInitialTab,
  showCardDetailsInModal,
  cardDataLoading = false,
}: CardDetailsProps) => {
  const [cardDetailsActiveTab, setCardDetailsActiveTab] = React.useState<CARD_DETAILS_TABS>(cardDetailsInitialTab);
  const { cardTitle, isCardRequestDetails } = cardData || {};

  const getDrawerTitle = () => {
    return isCardRequestDetails ? "Card Request Detail" : cardTitle || "Card Details";
  };
  const contextValue = {
    cardData,
    cardDetailsActiveTab,
    setCardDetailsActiveTab,
    showCardDetailsInModal: showCardDetailsInModal || false,
  };

  const CardDetailsComponents = () => {
    if (cardDataLoading) {
      return <CardDetailsShimmer showCardDetailsInModal={showCardDetailsInModal} />;
    }
    return (
      <div data-testid="card-details-drawer" className={styles["container"]}>
        <CardDetailsContext.Provider value={contextValue}>
          <div className={styles["container__top"]}>
            <Card />
            <CardDetailsSubInfo onCardStatusToggle={onCardStatusToggle} />
          </div>
          <div className={styles["container__bottom"]}>
            <CardDetailsInfo />
          </div>
          <CardDetailsActionBtns
            onEdit={onEdit}
            onCardRequestDelete={onCardRequestDelete}
            onCardDelete={onCardDelete}
            onPhysicalCardSync={onPhysicalCardSync}
          />
        </CardDetailsContext.Provider>
      </div>
    );
  };
  return (
    <>
      {showCardDetailsInModal ? (
        <Modal title={getDrawerTitle()} onClose={onClose}>
          {CardDetailsComponents()}
        </Modal>
      ) : (
        <Drawer title={getDrawerTitle()} onClose={onClose}>
          {CardDetailsComponents()}
        </Drawer>
      )}
    </>
  );
};
export default CardDetails;
