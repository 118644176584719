import React from "react";
import Button from "Modules/button";
import { ISidePanelButton } from "../../type.d";
import classNames from "classnames";
import styles from "./styles.module.scss";

export const BorderedDestructiveButton = ({
  action,
  text,
  disabled = true,
  loading,
  shouldUseColSpan,
}: ISidePanelButton) => {
  return (
    <Button
      size="l"
      btnStyle="white-red-with-border"
      className={classNames({ [styles["col-span-2"]]: shouldUseColSpan })}
      disabled={disabled || loading}
      rounded
      action={action}
    >
      {text}
    </Button>
  );
};
