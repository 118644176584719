import {
  SKIP_STEPS_REQUEST,
  SKIP_STEPS_SUCCESS,
  SKIP_STEPS_FAILURE,
  SKIP_STEPS_CLEAR,
} from "Redux/Actions/OnBoarding/skipSteps";
import { userInitialState } from "Redux/InitialState";

export const skipStepsReducer = (state = userInitialState, action) => {
  switch (action.type) {
    case SKIP_STEPS_REQUEST:
      return {
        ...state,
        loading: true,
        data: action.data,
        error: false,
      };
    case SKIP_STEPS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: false,
      };
    case SKIP_STEPS_FAILURE:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: true,
      };
    case SKIP_STEPS_CLEAR:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.data,
      };
    default:
      return state;
  }
};
