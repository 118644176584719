import React from "react";
import dayjs from "dayjs";
import { Tags } from "@spenmo/splice";
import { ColumnType } from "antd/lib/table";
import { useLocation } from "react-router-dom";
import { tagVariant } from "@spenmo/splice/lib/shared/types";

import { currencyFormatterV2 } from "utility";

import { IColumnData } from "Modules/DS/DataTable";

import { useFetchCRState } from "Views/Reimbursement/Settings/useCRState";
import { PAGE_TABS, REIMBURSEMENT_PAGE_COLUMNS } from "Views/Reimbursement/Constants";
import { IReimbursementRequestStatusData, IReimbursementRequestData } from "Views/Reimbursement/@types";

import createdAt from "./CreatedAt";

import styles from "./index.module.scss";

const { Progression } = Tags;

export const expenseDate: ColumnType<IReimbursementRequestData> = {
  title: "Expense Date",
  key: REIMBURSEMENT_PAGE_COLUMNS.EXPENSE_DATE,
  dataIndex: REIMBURSEMENT_PAGE_COLUMNS.EXPENSE_DATE,
  render: (value: number) => dayjs.unix(value).format("DD MMM YYYY"),
};

export const budget: ColumnType<IReimbursementRequestData> = {
  title: "Budget",
  key: REIMBURSEMENT_PAGE_COLUMNS.BUDGET,
  dataIndex: REIMBURSEMENT_PAGE_COLUMNS.BUDGET,
  ellipsis: true,
  className: styles.budget,
};

export const requester: ColumnType<IReimbursementRequestData> = {
  title: "Requester",
  key: REIMBURSEMENT_PAGE_COLUMNS.REQUESTER,
  dataIndex: REIMBURSEMENT_PAGE_COLUMNS.REQUESTER,
};

export const merchant: ColumnType<IReimbursementRequestData> = {
  title: "Merchant",
  key: REIMBURSEMENT_PAGE_COLUMNS.MERCHANT,
  dataIndex: REIMBURSEMENT_PAGE_COLUMNS.MERCHANT,
  ellipsis: true,
  className: styles.merchant,
};

export const amount: ColumnType<IReimbursementRequestData> = {
  title: "Amount",
  key: REIMBURSEMENT_PAGE_COLUMNS.AMOUNT,
  dataIndex: REIMBURSEMENT_PAGE_COLUMNS.AMOUNT,
  align: "right" as ColumnType<any>["align"],
  render: (amount: string, data) =>
    currencyFormatterV2(amount, data.currency_code, true, data?.currency_code !== "IDR" ? 2 : 0),
};

export const batchPayout: ColumnType<IReimbursementRequestData> = {
  title: "Batch Payout",
  key: REIMBURSEMENT_PAGE_COLUMNS.BATCH_PAYOUT,
  dataIndex: REIMBURSEMENT_PAGE_COLUMNS.BATCH_PAYOUT,
  align: "right" as ColumnType<any>["align"],
  render: (value: string) => {
    if (!value) {
      return <p className={styles.batchPayout}>-</p>;
    }

    return value;
  },
};

export const status: ColumnType<IReimbursementRequestData> = {
  title: "Status",
  fixed: "right",
  key: REIMBURSEMENT_PAGE_COLUMNS.STATUS,
  dataIndex: REIMBURSEMENT_PAGE_COLUMNS.STATUS,
  render: (status: IReimbursementRequestStatusData) => {
    const { label, type } = status || {};

    if (!type || !label) {
      return "-";
    }

    return <Progression hierarchy="default" label={label} variant={type as tagVariant} />;
  },
};

const useColumns = (): IColumnData<IReimbursementRequestData>[] => {
  const location = useLocation();

  const { fetchState } = useFetchCRState();
  const isSettlementEnabled: boolean = fetchState?.data?.data?.payload?.isEnabled || false;

  const columns = PAGE_TABS.find((tab) => location.pathname.includes(tab.tabKey))?.columns || [];

  return [
    {
      id: "REIMBURSEMENTS-COL-1",
      enabled: columns.includes(createdAt.key as string),
      column: createdAt,
    },
    {
      id: "REIMBURSEMENTS-COL-2",
      enabled: columns.includes(expenseDate.key as string),
      column: expenseDate,
    },
    {
      id: "REIMBURSEMENTS-COL-3",
      enabled: columns.includes(budget.key as string),
      column: budget,
    },
    {
      id: "REIMBURSEMENTS-COL-4",
      enabled: columns.includes(requester.key as string),
      column: requester,
    },
    {
      id: "REIMBURSEMENTS-COL-5",
      enabled: columns.includes(merchant.key as string),
      column: merchant,
    },
    {
      id: "REIMBURSEMENTS-COL-6",
      enabled: columns.includes(amount.key as string),
      column: amount,
    },
    {
      id: "REIMBURSEMENTS-COL-7",
      enabled: columns.includes(batchPayout.key as string) && isSettlementEnabled,
      column: batchPayout,
    },
    {
      id: "REIMBURSEMENTS-COL-8",
      enabled: columns.includes(status.key as string),
      column: status,
    },
  ].filter((item) => item.enabled);
};

export default useColumns;
