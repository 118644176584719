import React from "react";
import moment from "moment";
import cn from "classnames";
import useSWRMutation from "swr/mutation";
import { Controller, useForm } from "react-hook-form";

import { DatePicker } from "antd";
import { Input, Typography, WarningFilled, toast } from "@spenmo/splice";
import { Field } from "Views/SubscriptionManagement/Create/Components";
import { Modal } from "./index";

import { IModalContent } from "Views/SubscriptionManagement/@types";
import { putData } from "API/Client";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import { useSubscriptionContext } from "Views/SubscriptionManagement/Details/SubscriptionContext";
import { capitalizeFirstLetter } from "utility";

import modalStyles from "./styles.module.scss";
import styles from "Views/SubscriptionManagement/Create/styles.module.scss";

const END_DATE_FORM_NAME = "end_date";

export const ContractRenewal = ({ trigger }: IModalContent) => {
  const {
    control,
    watch,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: { [END_DATE_FORM_NAME]: "" },
  });
  const [endDate] = watch([END_DATE_FORM_NAME]);
  const hasAnError = errors?.[END_DATE_FORM_NAME]?.type === "required";

  const { subscription, mutate: mutateSubscriptionDetails } = useSubscriptionContext();
  const {
    id,
    vendor: { name },
  } = subscription || {};
  const showErrorNotification = (message: string) => {
    toast.danger(message || "We had trouble renewing this subscription. Please try again.");
  };
  const { trigger: renewContract, isMutating } = useSWRMutation(
    `ms/spm-organization/v1/subscriptions/${id}/renewal`,
    (url) =>
      putData(url, { ...getValues() })
        .then(({ data }) => {
          const { status, payload, error } = data || {};
          if (status === HTTP_STATUS_CODE.OK && !payload) {
            mutateSubscriptionDetails();
            toast.success(
              <>
                <Typography variant="body-content" size="m" weight={600}>
                  {capitalizeFirstLetter(name)}
                </Typography>{" "}
                subscription renewed successfully
              </>,
              {
                duration: 3000,
              }
            );
          } else {
            showErrorNotification(error?.message || "");
          }
          return null;
        })
        .catch((err) => {
          showErrorNotification(err?.response?.data?.error?.message || "");
        })
  );
  const action = async (closeModal: () => void) => {
    await handleSubmit(async () => {
      await renewContract();
      closeModal();
    })();
  };
  return (
    <Modal
      buttons={[
        {
          id: "1",
          variant: "secondary",
          text: "Go Back",
          closeModal: true,
          disabled: isMutating,
        },
        {
          id: "2",
          variant: "primary",
          text: "Renew Contract",
          loading: isMutating,
          disabled: isMutating,
          action: action,
        },
      ]}
      title="Contract renewal"
      className={styles.contractRenewal}
      trigger={trigger}
    >
      <Typography size="m" variant="body-content" className={modalStyles.paragraph} tag="p">
        For contract renewals, simply pick a new contract end date. However, if there are changes to the contract
        details, it's advisable to create a new subscription.
      </Typography>
      <form>
        <Field>
          <Input.Label>New contract end date</Input.Label>
          <Controller
            name={END_DATE_FORM_NAME}
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <DatePicker
                {...field}
                getPopupContainer={(triggerNode) => triggerNode.parentElement}
                id={END_DATE_FORM_NAME}
                picker="date"
                placeholder="Select Date"
                disabledDate={(date) => date < moment().endOf("day")}
                onChange={(selectedDate) => {
                  const formattedDate = selectedDate ? selectedDate?.format("YYYY-MM-DDTHH:mm:ss") + "Z" : null;
                  field.onChange(formattedDate);
                }}
                value={endDate ? moment(endDate.split("T")[0]) : null}
              />
            )}
          />
          {hasAnError && (
            <Typography variant="body-content" size="caption-m" className={styles.errorMessage}>
              <WarningFilled size="16" iconColor="var(--red-050)" />
              Select an end date
            </Typography>
          )}
        </Field>
      </form>
      <Typography size="m" variant="body-content" className={cn(modalStyles.paragraph, modalStyles.marginTop)} tag="p">
        Please note, renewing the contract here won't automatically renew it on the vendor's website.
      </Typography>
    </Modal>
  );
};
