import React from "react";
import { Skeleton } from "antd";
import styles from "./Button.module.scss";
import { Tags } from "@spenmo/splice";

interface IDownloadButton {
  onClickHandler: React.MouseEventHandler<HTMLButtonElement>;
  loading?: boolean;
  view?: string;
}

export const DownloadButton = ({ onClickHandler, loading = false, view }: IDownloadButton) => {
  return view !== "bills" && loading ? (
    <Skeleton.Input size="large" active={loading} className={styles.shimmer} />
  ) : (
    <Tags.Label
      onClick={onClickHandler}
      className={styles.container}
      hierarchy="high"
      label="Export"
      size="s"
      variant="info"
    />
  );
};
