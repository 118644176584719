import { PERSON_DOCUMENT_TYPE } from "./index";

export enum PERSONNEL_INFORMATION_FORM_ITEM_KEYS {
  INFO_DETAILS_ATTENTION_BANNER = "info_details_attention_banner",
  ROLE_INFO_DETAILS_NEUTRAL_BANNER = "role_info_details_neutral_banner",
  ROLE_INFO_DETAILS_AUTHORIZED_USERS = "role_info_details_authorized_users",
  ROLE_INFO_DETAILS_COMPANY_DIRECTOR = "role_info_details_company_director",
  ROLE_INFO_DETAILS_BENEFICIAL_OWNER = "role_info_details_beneficial_owner",
  PERSONNEL_INFO_AND_BANNER_DETAILS = "personnel_information_and_banner_details",
  HAS_ONLY_DIRECTOR = "personnel_information_has_only_director",
  ROLE_OF_INDIVIDUAL = "personnel_information_role_of_individual",
  FULL_NAME_OF_INDIVIDUAL = "personnel_information_full_name_of_individual",
  ALIAS_OF_INDIVIDUAL = "personnel_information_alias_of_individual",
  DATE_OF_BIRTH = "personnel_information_document_date_of_birth",
  GENDER = "personnel_information_document_gender",
  NATIONALITY = "personnel_information_document_nationality",
  COUNTRY_OF_RESIDENCE = "personnel_information_document_country_of_residence",
  RESIDENTIAL_ADDRESS = "personnel_information_document_residential_address",
  POSTAL_CODE = "personnel_information_document_postal_code",
  CHOOSE_A_DOCUMENT = "personnel_information_document_type",
  NRIC_DOCUMENT_HEADER_AND_GUIDELINES = "personnel_information_document_type_nric_header_with_guidelines",
  PASSPORT_DOCUMENT_HEADER_AND_GUIDELINES = "personnel_information_document_type_passport_header_with_guidelines",
  WORKPASS_DOCUMENT_HEADER_AND_GUIDELINES = "personnel_information_document_type_workpass_header_with_guidelines",
  NRIC_NUMBER = "personnel_information_document_nric_number",
  PASSPORT_NUMBER = "personnel_information_document_passport_number",
  WORKPASS_NUMBER = "personnel_information_document_workpass_number",
  PASSPORT_EXPIRY = "personnel_information_document_passport_expiry_date",
  UPLOAD_NRIC = "personnel_information_document_upload_nric",
  UPLOAD_PASSPORT = "personnel_information_document_upload_passport",
  UPLOAD_WORKPASS = "personnel_information_document_upload_workpass",
  PROOF_OF_RESIDENCE = "personnel_information_document_proof_of_residence",
  PROOF_OF_RESIDENCE_HEADER_AND_GUIDELINES = "personnel_information_document_type_proof_of_residence_header_with_guidelines",
  UPLOAD_PROOF_OF_RESIDENCE = "personnel_information_document_upload_proof_of_residence",
  EMPTY = "personnel_information_document_empty_span",
}

export const PERSONNEL_INFORMATION_FORM_ITEM_NAMES = {
  [PERSONNEL_INFORMATION_FORM_ITEM_KEYS.HAS_ONLY_DIRECTOR]: "has_only_director",
  [PERSONNEL_INFORMATION_FORM_ITEM_KEYS.ROLE_OF_INDIVIDUAL]: "role_of_individual",
  [PERSONNEL_INFORMATION_FORM_ITEM_KEYS.FULL_NAME_OF_INDIVIDUAL]: "full_name",
  [PERSONNEL_INFORMATION_FORM_ITEM_KEYS.ALIAS_OF_INDIVIDUAL]: "alias",
  [PERSONNEL_INFORMATION_FORM_ITEM_KEYS.DATE_OF_BIRTH]: "date_of_birth",
  [PERSONNEL_INFORMATION_FORM_ITEM_KEYS.GENDER]: "gender",
  [PERSONNEL_INFORMATION_FORM_ITEM_KEYS.NATIONALITY]: "nationality",
  [PERSONNEL_INFORMATION_FORM_ITEM_KEYS.COUNTRY_OF_RESIDENCE]: "country_of_residence",
  [PERSONNEL_INFORMATION_FORM_ITEM_KEYS.RESIDENTIAL_ADDRESS]: "residential_address",
  [PERSONNEL_INFORMATION_FORM_ITEM_KEYS.POSTAL_CODE]: "postal_code",
  [PERSONNEL_INFORMATION_FORM_ITEM_KEYS.CHOOSE_A_DOCUMENT]: "document_type",
  [PERSONNEL_INFORMATION_FORM_ITEM_KEYS.NRIC_NUMBER]: "nric_number",
  [PERSONNEL_INFORMATION_FORM_ITEM_KEYS.PASSPORT_NUMBER]: "passport_number",
  [PERSONNEL_INFORMATION_FORM_ITEM_KEYS.WORKPASS_NUMBER]: "workpass_number",
  [PERSONNEL_INFORMATION_FORM_ITEM_KEYS.PASSPORT_EXPIRY]: "expiry_date",
  [PERSONNEL_INFORMATION_FORM_ITEM_KEYS.UPLOAD_NRIC]: "nric_document",
  [PERSONNEL_INFORMATION_FORM_ITEM_KEYS.UPLOAD_PASSPORT]: "passport_document",
  [PERSONNEL_INFORMATION_FORM_ITEM_KEYS.UPLOAD_WORKPASS]: "workpass_document",
  [PERSONNEL_INFORMATION_FORM_ITEM_KEYS.PROOF_OF_RESIDENCE]: "proof_of_residence",
  [PERSONNEL_INFORMATION_FORM_ITEM_KEYS.UPLOAD_PROOF_OF_RESIDENCE]: "upload_proof_of_residence",
};

export enum ROLE_OF_INDIVIDUAL_OPTIONS {
  AUTHORIZED_PERSON = "AUTHORIZED_PERSON",
  DIRECTOR = "DIRECTOR",
  SHAREHOLDER = "SHAREHOLDER",
}

export const ROLE_OF_INDIVIDUAL_OPTIONS_VALUE = {
  [ROLE_OF_INDIVIDUAL_OPTIONS.AUTHORIZED_PERSON]: "Authorized Person",
  [ROLE_OF_INDIVIDUAL_OPTIONS.DIRECTOR]: "Director",
  [ROLE_OF_INDIVIDUAL_OPTIONS.SHAREHOLDER]: "Shareholder",
};

export const ROLE_OF_INDIVIDUAL_OPTIONS_LABEL = {
  [ROLE_OF_INDIVIDUAL_OPTIONS.AUTHORIZED_PERSON]: "Authorized Person",
  [ROLE_OF_INDIVIDUAL_OPTIONS.DIRECTOR]: "Director",
  [ROLE_OF_INDIVIDUAL_OPTIONS.SHAREHOLDER]: "Shareholder",
};

export enum PERSONNNEL_IDENTIFICATION_DOCUMENT_TYPE_OPTIONS {
  NRIC = "NRIC",
  PASSPORT = "PASSPORT",
  WORKPASS = "WORKPASS",
}

export const PERSONNNEL_IDENTIFICATION_DOCUMENT_TYPE_OPTIONS_VALUE = {
  [PERSONNNEL_IDENTIFICATION_DOCUMENT_TYPE_OPTIONS.NRIC]: "NRIC" as PERSON_DOCUMENT_TYPE,
  [PERSONNNEL_IDENTIFICATION_DOCUMENT_TYPE_OPTIONS.PASSPORT]: "Passport" as PERSON_DOCUMENT_TYPE,
  [PERSONNNEL_IDENTIFICATION_DOCUMENT_TYPE_OPTIONS.WORKPASS]: "WorkPass" as PERSON_DOCUMENT_TYPE,
};

export const PERSONNNEL_IDENTIFICATION_DOCUMENT_TYPE_OPTIONS_LABEL = {
  [PERSONNNEL_IDENTIFICATION_DOCUMENT_TYPE_OPTIONS.NRIC]: "NRIC" as string,
  [PERSONNNEL_IDENTIFICATION_DOCUMENT_TYPE_OPTIONS.PASSPORT]: "Passport" as string,
  [PERSONNNEL_IDENTIFICATION_DOCUMENT_TYPE_OPTIONS.WORKPASS]: "Work Pass" as string,
};

export enum GENDER_OF_INDIVIDUAL_OPTIONS {
  MALE = "Male",
  FEMALE = "Female",
}
export enum VERIFICATION_METHOD_OPTIONS {
  MANUAL = "manually",
  SINGPASS = "singpass",
}

export const GENDER_OF_INDIVIDUAL_OPTIONS_VALUE = {
  [GENDER_OF_INDIVIDUAL_OPTIONS.MALE]: "Male",
  [GENDER_OF_INDIVIDUAL_OPTIONS.FEMALE]: "Female",
};

export const GENDER_OF_INDIVIDUAL_OPTIONS_LABEL = {
  [GENDER_OF_INDIVIDUAL_OPTIONS.MALE]: "Male",
  [GENDER_OF_INDIVIDUAL_OPTIONS.FEMALE]: "Female",
};

export const VERIFICATION_METHOD_OPTIONS_VALUE = {
  [VERIFICATION_METHOD_OPTIONS.MANUAL]: VERIFICATION_METHOD_OPTIONS.MANUAL,
  [VERIFICATION_METHOD_OPTIONS.SINGPASS]: VERIFICATION_METHOD_OPTIONS.SINGPASS,
};

export const VERIFICATION_METHOD_OPTIONS_LABEL = {
  [VERIFICATION_METHOD_OPTIONS.MANUAL]: "Verify manually",
  [VERIFICATION_METHOD_OPTIONS.SINGPASS]: "Verify with Singpass",
};
