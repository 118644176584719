import React from "react";
import { Skeleton } from "antd";

import { ILineSpanProps, MENU_CLASS_NAME } from "Modules/DS/Menu/@types";

import styles from "./Span.module.scss";

export const LineSpan = ({ id, title, onClick, loading }: ILineSpanProps): JSX.Element => {
  return loading ? (
    <Skeleton.Input active={loading} size="small" className={styles.shimmer} />
  ) : (
    <span id={id} onClick={onClick} className={styles[MENU_CLASS_NAME.LINE_TITLE]}>
      {title}
    </span>
  );
};
