export const CARD_COLORS = ["#131A2E", "#E5304C", "#F65731", "#F79E1B", "#F2C94C", "#1FCE8F", "#1E97EE", "#9B51E0"];

export const CARD_COLOR_AND_NAME_MAP = {
  [CARD_COLORS[0]]: "Black",
  [CARD_COLORS[1]]: "Hibiscus",
  [CARD_COLORS[2]]: "Salmon",
  [CARD_COLORS[3]]: "Gold",
  [CARD_COLORS[4]]: "Lemon",
  [CARD_COLORS[5]]: "Emerald",
  [CARD_COLORS[6]]: "Azure",
  [CARD_COLORS[7]]: "Electric",
};

export const REVEAL_CARD_DETAILS_BTN_COLOR_MAP = {
  [CARD_COLORS[0]]: "#333744",
  [CARD_COLORS[1]]: "#CC0B29",
  [CARD_COLORS[2]]: "#CE2A03",
  [CARD_COLORS[3]]: "#F28505",
  [CARD_COLORS[4]]: "#E7B400",
  [CARD_COLORS[5]]: "#03B173",
  [CARD_COLORS[6]]: "#0874C2",
  [CARD_COLORS[7]]: "#7320C0",
};
