import React from "react";
import {
  Modal,
  Typography,
  UnlockedOutline,
  CheckFilled,
} from "@spenmo/splice";
import {
  netsuiteUpgrade,
  sagePartner,
  myobPartner,
  microsoftPartner,
  quickbooksPartner,
} from "assets/img";
import styles from "./styles.module.scss";

const backgroundImage = {
  3: quickbooksPartner,
  4: netsuiteUpgrade,
  5: sagePartner,
  6: myobPartner,
  7: microsoftPartner,
};

const UpgradeNetsuiteModal = ({
  onClose,
  name,
  id,
}: {
  onClose: () => void;
  name?: string;
  id?: number;
}) => {
  const featurePoints = [
    `Close books faster by automating data transfer between Spenmo and ${name}.`,
    "Avoid duplicate entries and minimise errors to enhance overall efficiency.",
    "Import Policies, Budgets and Configurations",
  ];

  return (
    <Modal
      showModal={Boolean(name) && Boolean(id)}
      size="l"
      title={`Integrate ${name} with Spenmo`}
      primaryActionButton={{
        title: "Contact Support",
        action() {
          window.open("mailto:support@spenmo.com");
        },
      }}
      tertiaryActionButton={{
        title: "Maybe Later",
        action: onClose,
      }}
      children={
        <div className={styles.upgradeNetsuite}>
          <img
            src={backgroundImage?.[id]}
            width="51%"
            height={400}
            alt="NetSuite upgrade banner"
          />
          <div className={styles.information}>
            <UnlockedOutline
              className={styles.lockIcon}
              size="24"
              iconColor="var(--black-100)"
            />
            <Typography
              size="s"
              variant="body-content"
              weight={600}
              className={styles.title}
            >
              Keep your expenses in sync with your {name} account
            </Typography>

            <Typography
              size="s"
              weight={600}
              className={styles.featureTitle}
              variant="body-content"
            >
              This feature allows you to...
            </Typography>

            <ul>
              {featurePoints.map((feature, index) => (
                <li className={styles.benefit} key={index}>
                  <div className={styles.iconWrapper}>
                    <CheckFilled
                      iconColor="var(--fill-system-success-strong)"
                      size="16"
                    />
                  </div>
                  <Typography
                    size="s"
                    variant="body-content"
                    className={styles.textList}
                    tag="p"
                  >
                    {feature}
                  </Typography>
                </li>
              ))}
            </ul>
          </div>
        </div>
      }
      onClose={onClose}
    />
  );
};

export default UpgradeNetsuiteModal;
