import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getMonthName } from "utility";
import { ExpenseDetailTitle, Expense } from "Views/Home/Components/ExpenseCard/Common";
import { TitleColorTypes } from "Views/Home/Components/ExpenseCard/Common/Constants";

const ExpenseCard = ({
  personalBalance,
  availableTeamBalance,
  totalTeamSpending,
  currencyCode,
  isManagerOrAdminOrAccountant,
}) => {
  const [isWalletUser, setIsWalletUser] = useState(false);
  const { data: userData, loading: userDataLoading } = useSelector((state) => state?.userInfoReducer);

  function checkIsWalletUser() {
    const { is_wallet_user } = userData?.payload?.user || {};
    /* using !isWalletUser to reduce the set state calls */
    if (is_wallet_user && !userDataLoading && !isWalletUser) {
      setIsWalletUser(true);
    }
  }

  useEffect(() => {
    checkIsWalletUser();
  }, [userDataLoading]);

  return (
    <>
      {isWalletUser && personalBalance && (
        <div className="sp-margin-right--xl">
          <ExpenseDetailTitle title="Personal Balance" color={TitleColorTypes.GRAY} />
          <Expense altText="N/A" expense={personalBalance} currencyCode={currencyCode} />
        </div>
      )}
      {isManagerOrAdminOrAccountant && (
        <div className="sp-margin-right--xl">
          <ExpenseDetailTitle title="Remaining Budget" color={TitleColorTypes.GRAY} />
          <Expense altText="N/A" expense={availableTeamBalance} currencyCode={currencyCode} />
        </div>
      )}
      {isManagerOrAdminOrAccountant && (
        <div>
          <ExpenseDetailTitle title={`${getMonthName(new Date())} Budget Spent`} color={TitleColorTypes.GRAY} />
          <Expense altText="N/A" expense={totalTeamSpending} currencyCode={currencyCode} />
        </div>
      )}
    </>
  );
};

export default ExpenseCard;
