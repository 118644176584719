import React from "react";
import styles from "./index.module.scss";
import Icon from "Modules/icons";
import { ModalComponent } from "Modules/DS/Modal";
import { greyCloseIcon } from "assets/img";
import { TITLE_TYPE } from "Modules/DS/Modal/types";
import { errorIcon } from "assets/img";
import Modal from "Modules/DS/Modal";
import { useDeleteTeamModalClose } from "Views/Team/hooks/UseDeleteTeamModalClose";

const FailedModal = ({ title, teamName }: { title: string; teamName: string }) => {
  const { close } = useDeleteTeamModalClose();
  return (
    <>
      <Modal visible={true} className={`${styles.container}`} close={close}>
        <ModalComponent.CloseButton fixToTop src={greyCloseIcon} />
        <ModalComponent.Title titleType={TITLE_TYPE.withBorder}>
          <p className={`${styles.title}`}>{title}</p>
        </ModalComponent.Title>

        <section className={`${styles.body}`}>
          <Icon className={styles.icon} src={errorIcon} alt="failed to delete team" />
          <div className={styles.text}>
            <p>{`An error occurred while deleting ${teamName} Budget.`}</p>
            <p>Please try again in a few moments.</p>
          </div>
        </section>
      </Modal>
    </>
  );
};

export default FailedModal;
