import React, { useState } from "react";
import { Document, Page } from "react-pdf";
import { ALLOWED_FILE_EXTENSIONS } from "Views/UploadInvoice/const";
import { UploadFile as UploadFileAction } from "Redux/DataCalls/UploadImage.api";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import { EInputType } from "../index";
import { SecondaryButton } from "Modules/DS/SidePanel/components/Buttons/SecondaryButton";
import { ESidePanelButtonType } from "Modules/DS/SidePanel";
import UploadFile from "Modules/UploadFile/UploadFile";
import Icon from "Modules/icons";
import { uploadInvoice as uploadInvoiceLogo } from "assets/img";
import classNames from "classnames";
import { IUploadAttachmentProps } from "../type.d";
import { Core } from "assets/v1.1/icons/Core";

import styles from "./styles.module.scss";
import { Typography } from "@spenmo/splice";

const { Trash } = Core;

export const MAX_FILE_SIZE_ALLOWED = 10 * 1024 * 1024; // 10MB

const UploadAttachment: React.FC<IUploadAttachmentProps> = ({
  transactionForm,
  disabled,
  setTransactionForm,
  hasMissingField,
  deletable,
  additionalConfig = null,
  onDelete,
}) => {
  const [isUploading, setUploading] = useState(false);
  const validateFile = (file: any) => {
    const fileExtension = file.name.split(".").pop().toLowerCase();
    const extensionValid = ALLOWED_FILE_EXTENSIONS.includes(fileExtension);
    const sizeValid = file.size <= MAX_FILE_SIZE_ALLOWED;
    const isFileValid = extensionValid && sizeValid;
    return isFileValid;
  };
  const files = transactionForm[EInputType.RECEIPT] || [];
  const filesDetails = transactionForm[EInputType.RECEIPT_DETAIL] || [];

  const uploadFile = async ({ payload }) => {
    setUploading(true);
    const { data } = await UploadFileAction(payload.file);

    if (data.status === HTTP_STATUS_CODE.OK) {
      setTransactionForm({
        ...transactionForm,
        [EInputType.RECEIPT]: files.concat(data.payload.file_path),
      });
    }
    setUploading(false);
  };

  const deleteFile = (deletedIndex: number) => {
    const deletedFile = files[deletedIndex];
    onDelete?.(deletedFile);
    const newFiles = files.filter((_, index) => index !== deletedIndex);
    setTransactionForm({
      ...transactionForm,
      [EInputType.RECEIPT]: newFiles,
    });
  };

  const isPdf = (file: string) => file.includes(".pdf");

  const uploadOnDrop: React.DragEventHandler<HTMLDivElement> = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    const uploadedFiles = e.dataTransfer.files;
    if (!uploadedFiles) {
      return;
    }

    setUploading(true);
    let paths = [];

    for (const file of Array.from(uploadedFiles)) {
      const isValid = validateFile(file);
      if (isValid) {
        const { data } = await UploadFileAction(file);
        if (data.status === HTTP_STATUS_CODE.OK) {
          paths = paths.concat(data.payload.file_path);
        }
      }
    }
    setTransactionForm({
      ...transactionForm,
      [EInputType.RECEIPT]: files.concat(paths),
    });

    setUploading(false);
  };

  if (files.length !== 0) {
    return (
      <div
        className={classNames(styles["attachment-with-content"], {
          [styles["attachment-with-content__error-border"]]: hasMissingField,
          [styles["attachment-with-content__custom"]]: additionalConfig?.addCustomAttachmentsContainerClass,
        })}
        onDragOver={(e) => e.preventDefault()}
        onDrop={uploadOnDrop}
        data-testid="file-upload"
      >
        <div
          className={classNames(styles["attachment-with-content__files"], {
            [styles["attachment-with-content__files__custom"]]: additionalConfig?.addCustomFilesContainerClass,
            [styles["attachment-with-content__files__custom_bottomPadding"]]:
              !additionalConfig?.hideAddAttachmentButton && additionalConfig?.addCustomFilesContainerClass,
          })}
          data-testid="files-upload-list"
        >
          {files.map((url, index) => (
            <div key={index} className={styles["attachment-with-content__file"]}>
              {!disabled && deletable && (
                <div className={styles["attachment-with-content__delete"]} onClick={() => deleteFile(index)}>
                  <img src={Trash.Fill[16]} alt="Delete Icon" />
                </div>
              )}
              {isPdf(url) ? (
                <a
                  rel="noreferrer"
                  className={styles["pdf-preview"]}
                  href={url}
                  target="_blank"
                  data-testid="pdf-preview"
                >
                  <Document file={url}>
                    <Page pageNumber={1} />
                  </Document>
                </a>
              ) : (
                <a href={url} rel="noreferrer" target="_blank" data-testid="image-preview">
                  <img src={url} alt="attachment" />
                </a>
              )}
              {additionalConfig?.showFileNameAndSize && (
                <div className={styles["attachment-with-content__file_details"]}>
                  <Typography className={styles.fileName} variant="body-content" size="caption-m" tag="p" weight={600}>
                    {filesDetails[url].name}
                  </Typography>
                  <p>
                    <span className={styles.fileInfo}>
                      {(filesDetails[url].size / 1000 / 1000).toFixed(3)} MB
                      <span className={styles["dot-separator"]}>.</span>
                    </span>
                    {/* <span>Change File</span> */}
                  </p>
                </div>
              )}
            </div>
          ))}
        </div>
        {!additionalConfig?.hideAddAttachmentButton && (
          <div className={styles["attachment-with-content__upload"]}>
            <UploadFile
              beforeUpload={validateFile}
              customRequest={uploadFile}
              className="upload-image"
              accept={ALLOWED_FILE_EXTENSIONS.map((item) => `.${item}`).join(",")}
              customPlaceholder={
                <SecondaryButton
                  id="Upload attachment"
                  action={() => {}}
                  disabled={disabled || isUploading}
                  text={isUploading ? "Uploading your file" : "Add Attachments"}
                  type={ESidePanelButtonType.SECONDARY}
                />
              }
              disabled={disabled}
            />
          </div>
        )}
      </div>
    );
  }

  return (
    <section
      className={classNames(styles["no-file-uploader"], {
        [styles["no-file-uploader__error-border"]]: hasMissingField && !disabled,
        [styles["no-file-uploader__disabled"]]: disabled,
      })}
      data-testid="empty-file-upload"
    >
      <UploadFile
        beforeUpload={validateFile}
        customRequest={uploadFile}
        loading={isUploading}
        icon={<Icon src={uploadInvoiceLogo} disabled={disabled} />}
        withBrowseButton={false}
        listType={"picture-card"}
        text={
          <span>
            Drop your files here, or <span className={styles.active}>Choose Files</span>
          </span>
        }
        hint={`We only accept PDF, JPEG, and PNG format. (Max. ${additionalConfig?.isBillsTrxnsPage ? 4 : 10}MB)`}
        className={styles.uploader}
        accept={ALLOWED_FILE_EXTENSIONS.map((item) => `.${item}`).join(",")}
        disabled={disabled}
      />
    </section>
  );
};

export default UploadAttachment;
