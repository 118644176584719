import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { Button } from "antd";
import { useFormContext } from "react-hook-form";
import { CrossOutline, Typography } from "@spenmo/splice";

import Input from "../Input";
import Select, { Option } from "../Select";

import { useBillForm } from "Views/Bills/V2/context/FormContext";
import { RecipientFormContext } from "Views/Bills/V2/Recipient/types";

import { highlighter } from "utility";
import styles from "../FormGenerator.module.scss";

const highlight = (text: string) => <span className={styles.highlight}>{text}</span>;

const RecipientBankSelect = forwardRef((props: any, ref) => {
  const { options, onChange, value, name } = props;
  const { options: __, ...selectProps } = props; // to not use options from props
  const { options: _, pattern, ...inputProps } = props;
  const { setRefetchDynamicFields, isOpenNewBank, onOpenNewBank, onCloseNewBank } = useBillForm<RecipientFormContext>();
  const { resetField, watch } = useFormContext();
  const resetTriggerFields = watch(["countryCode", "currencyCode"]);

  const [search, setSearch] = useState("");
  const [isReset, setIsReset] = useState(true);

  const selectRef = useRef(null);
  useImperativeHandle(ref, () => selectRef.current);

  const handleOpenNewBank = () => {
    if (isReset) {
      resetField(name, {
        defaultValue: "",
        keepDirty: true,
      });
    } else {
      setIsReset(true);
    }

    setRefetchDynamicFields({
      [name]: {
        isNewData: true,
      },
    });
  };

  const handleCloseNewBank = () => {
    onCloseNewBank();
    setRefetchDynamicFields({});
    resetField(name, {
      defaultValue: "",
      keepDirty: true,
    });
  };

  // to detect changes from other component
  useEffect(() => {
    if (isOpenNewBank) {
      handleOpenNewBank();
    }
  }, [isOpenNewBank]);

  // to reset fields if country/currency is changed
  useEffect(() => {
    if (isOpenNewBank) {
      handleCloseNewBank();
    }
  }, resetTriggerFields);

  useEffect(() => {
    if (!isOpenNewBank && value) {
      const selectedIndex = options.findIndex(
        (item) =>
          item.label.toLowerCase().includes(value.toLowerCase()) || item.value.toLowerCase() === value.toLowerCase()
      );

      if (selectedIndex !== -1) {
        onChange(options[selectedIndex].value);
      } else {
        onOpenNewBank();
        setIsReset(false);
      }
    }
  }, [value]);

  useEffect(() => {
    return () => {
      onCloseNewBank();
    };
  }, []);

  const handleChange = (e) => {
    setSearch("");
    onChange(e);
  };

  const handleFilterOption = (value, elem: any) => {
    let additionalCode = elem.props?.sublabel;
    if (additionalCode) {
      additionalCode = additionalCode.toLowerCase().indexOf(value.toLowerCase()) !== -1;
    }
    if (elem?.label) {
      return elem.label.toLowerCase().indexOf(value.toLowerCase()) !== -1 || additionalCode;
    }
  };

  const dropdownRender = (menu: React.ReactElement<any, string | React.JSXElementConstructor<any>>) => {
    return (
      <>
        {menu}
        <Button
          className={styles.newSelectButton}
          onClick={() => {
            onOpenNewBank();
            setIsReset(true);
          }}
        >
          + New Recipient Bank
        </Button>
      </>
    );
  };

  if (isOpenNewBank) {
    return (
      <Input
        {...inputProps}
        placeholder="Enter Recipient Bank Name"
        onChange={(value) => onChange(value, !isOpenNewBank)}
        suffix={
          <div onClick={handleCloseNewBank}>
            <CrossOutline size="24" iconColor="#545454" />
          </div>
        }
      />
    );
  }

  return (
    <Select
      ref={selectRef}
      {...selectProps}
      onChange={handleChange} // refetch manually
      dropdownClassName={styles.selectOptionMultilines}
      optionFilterProp="label"
      optionLabelProp="label"
      showSearch
      dropdownRender={dropdownRender}
      filterOption={handleFilterOption}
      onSearch={(value) => setSearch(value?.toLowerCase())}
    >
      {React.Children.toArray(
        options.map((item) => {
          const label = highlighter(item.label, search, highlight);

          return (
            <Option keys={item.id} value={item.value} label={item.label} sublabel={item.additionalCode}>
              <div>
                <Typography variant="body-content" size="s" tag="p" weight={600}>
                  {label}
                </Typography>
              </div>
              {Boolean(item.additionalCode) && (
                <div>
                  <Typography variant="body-content" size="caption-s" tag="p">
                    {highlighter(item.additionalCode, search, highlight)}
                  </Typography>
                </div>
              )}
            </Option>
          );
        })
      )}
    </Select>
  );
});

export default RecipientBankSelect;
