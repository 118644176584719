import {
  BillInformation,
  EInputType,
  Section,
  TransactionDetail,
  TransactionStatus,
} from "Modules/TransactionDetail";
import React, { useMemo } from "react";
import TrxnInfoCard from "./TrxnInfoCard";
import { Form } from "antd";
import { useBillTrxnDetails } from "../Hooks/useBillTrxnDetails";
import { dateTimeFormatter } from "utility";
import { TRXN_STATUS } from "Views/Transaction/Constants";
import { useMutableData } from "API/useData";
import { API_URL } from "Views/Bills/V2/constants";
import { SaaSConfig } from "Views/Bills/V2/BillForm/type";

const BillsTrxnSidePanel = ({
  billTrxnDetailsEditable,
  id,
  invoiceData,
  showEditableFields,
  setFormValueChanged = () => {},
  resetFormData,
  setFormValue = () => {},
  importBillFromXero,
}: {
  billTrxnDetailsEditable?: boolean;
  id: string;
  invoiceData;
  showEditableFields?: boolean;
  setFormValueChanged?: (_: boolean) => void;
  resetFormData?: boolean;
  setFormValue?: (_: any) => void;
  importBillFromXero?: any;
}) => {
  const pageName = "trxns_bills";
  const [form] = Form.useForm();

  const statusJourney = invoiceData?.statusJourney?.slice().reverse() || [];
  const { data: saasConfigResponse } = useMutableData(`${API_URL.saasConfig}`);
  const saasConfig: SaaSConfig = useMemo(
    () => saasConfigResponse?.data?.payload,
    [saasConfigResponse?.data?.payload],
  );
  const { trxnDetails, defaultValue, billInfoSections } = useBillTrxnDetails(
    invoiceData,
    showEditableFields,
    true,
    saasConfig,
  );

  const currentStatusId = statusJourney.findIndex((status) => status.isActive);

  return (
    <>
      <Section>
        <TrxnInfoCard
          id={id}
          merchant={invoiceData?.beneficiary}
          currency={invoiceData?.currency}
          amount={invoiceData?.amount}
          isShowConversion={invoiceData?.fxRate > 0}
          walletCurrency={invoiceData?.walletCurrency}
          walletAmount={invoiceData?.walletAmount}
          status={invoiceData?.status}
          isFeeDirectlyDeducted={invoiceData?.isFeeDirectlyDeducted}
          billAmountFeeInclusive={invoiceData?.billAmountFeeInclusive}
          feeAmount={invoiceData?.totalFeeAmount}
          selectedTrxnSLA={invoiceData?.SLA}
          swiftPaymentChargeFee={invoiceData?.swiftPaymentChargeFee}
          invoiceAmount={invoiceData?.billTax?.invoiceAmount}
          invoiceCurrency={invoiceData?.billTax?.invoiceCurrency}
          saasConfig={saasConfig}
          importBillFromXero={importBillFromXero}
        />
      </Section>
      <Section>
        <TransactionStatus
          page={pageName}
          currentStatusId={String(currentStatusId <= 0 ? 0 : currentStatusId)}
          transactionStatus={
            statusJourney.map((status, idx) => ({
              date:
                status.timestamp &&
                `${dateTimeFormatter(status.timestamp).date}, ${dateTimeFormatter(status.timestamp).time}`,
              title: status.label,
              id: String(idx),
              subtitle: status.actionUser && `By ${status.actionUser}`,
              notes: status.notes,
              state: status.isFinished ? "done" : "pending",
            })) || []
          }
        />
      </Section>
      <TransactionDetail
        receiptTitle="Attachments"
        visibleInput={{
          [EInputType.TAX]: showEditableFields,
          [EInputType.CATEGORY]: showEditableFields,
          [EInputType.TRANSACTION_TAGS]: showEditableFields,
          [EInputType.NOTES]: showEditableFields,
          // Attachments
          [EInputType.RECEIPT]: {
            additionalConfig: {
              isBillsTrxnsPage: true,
              hideAddAttachmentButton: !showEditableFields,
              showFileNameAndSize: true,
              addCustomAttachmentsContainerClass: true,
              addCustomFilesContainerClass: true,
            },
          },
          // Receipt or Proof Of Payment
          [EInputType.PAYMENT_RECEIPT]:
            invoiceData?.status === TRXN_STATUS.completed ||
            invoiceData?.status === TRXN_STATUS.received,
        }}
        details={trxnDetails()}
        defaultValue={defaultValue()}
        form={form}
        setFormChanged={setFormValueChanged}
        setFormValue={(val) => setFormValue(val)}
        disabled={!billTrxnDetailsEditable || !showEditableFields}
        deletable={showEditableFields}
        resetFormData={resetFormData}
        page={pageName}
        paymentReceiptTitle={
          invoiceData?.status === TRXN_STATUS.received
            ? "Receipt"
            : "Proof of Payment"
        }
      />
      <BillInformation sections={billInfoSections()} />
    </>
  );
};

export default BillsTrxnSidePanel;
