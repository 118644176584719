import APIclient from "API/Client";
import { GetBaseAuthObject } from "../../utility";
import { GetFormData } from "../../utility/APIWrapper";
import { userInitialState } from "../InitialState";
import { trackEvent } from "utility/analytics";
import { AuthenticationButtonActions, LoginResponseResult, AuthActionEventSource } from "Views/Auth/Analytics";

export const FETCH_PASSWORD_REQUEST = "FETCH_PASSWORD_REQUEST";
export const FETCH_PASSWORD_SUCCESS = "FETCH_PASSWORD_SUCCESS";
export const FETCH_PASSWORD_FAILURE = "FETCH_PASSWORD_FAILURE";

const fetchDataReq = (data = userInitialState) => {
  return {
    type: FETCH_PASSWORD_REQUEST,
    data: data,
  };
};

const fetchDataSuccess = (data) => {
  return {
    type: FETCH_PASSWORD_SUCCESS,
    data: data,
    message: data.payload.status_message,
  };
};

const fetchDataError = (data) => {
  return {
    type: FETCH_PASSWORD_FAILURE,
    data: data,
    message: data.payload.status_message,
  };
};

const triggerAuthTrackEvent = (status) => {
  trackEvent(AuthenticationButtonActions.ACCOUNT_AUTHENTICATION_BUTTON_CLICKED, {
    authentication_event_source: AuthActionEventSource.RESET_PASSWORD,
    authentication_event_action: status,
  });
};

export const resetPasswordFunc = (data, trackActions = false) => {
  const orgId = GetBaseAuthObject().orgId;

  return (dispatch) => {
    dispatch(fetchDataReq());
    APIclient.postData("/api/v1/auth/changePassword", GetFormData({ organisation_id: orgId, ...data }))
      .then((data) => {
        if (data.data.status > 200) {
          dispatch(fetchDataError(data.data));
          trackActions && trackEvent("reset password fail");
          triggerAuthTrackEvent(LoginResponseResult.FAIL);
        } else {
          dispatch(fetchDataSuccess(data.data));
          trackActions && trackEvent("reset password success");
          triggerAuthTrackEvent(LoginResponseResult.SUCCESS);
        }
      })
      .catch((err) => {
        dispatch(fetchDataError(err));
        trackActions && trackEvent("reset password fail");
        triggerAuthTrackEvent(LoginResponseResult.FAIL);
      });
  };
};
