import React, { useEffect, useState } from "react";
import { Form, Input, Select } from "antd";
import { roundValue } from "utility";
import { useDispatch, useSelector } from "react-redux";

import { closeIcon } from "assets/img";
import { trackEvent } from "utility/analytics";
import { UNEDITABLE_CATEGORIES } from "constants/Category.constant";
import { xeroExpenseCodes } from "Redux/Actions";
import usePermissionCheck from "Permission/usePermissionCheck";
import { CASBIN_PAGES } from "Permission/Pages";
import { INTEGRATION_PAGE } from "Permission/Actions";
import LoaderIcon from "Views/State/Loading/LoaderIcon";

import { IFormProps } from "../types";
import { categoryEvents } from "../Analytics";
import FormButton from "./FormButton";

import "./styles.scss";

const { Option, OptGroup } = Select;

const defaultCategory = {
  id: "",
  categoryName: "",
  accountingSystemCode: "",
  accountingSystemName: "",
  taxId: "",
  status: "active",
};

const categoryStatus = [
  {
    id: 1,
    label: "Active",
    value: "active",
  },
  {
    id: 2,
    label: "Inactive",
    value: "inactive",
  },
];

const CategoryForm = ({
  hasValidToken,
  isEdit,
  details = defaultCategory,
  onCancel,
  onFinish,
  actionLoading,
  error,
}: IFormProps) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [fieldChanged, setFieldChange] = useState(false);
  const [initialCategoryData, setInitialCategoryData] = useState({
    categoryName: details?.categoryName,
    accountingSystemCode: details?.accountingSystemCode,
    taxId: details?.taxId,
    status: details.status,
  });

  const { xeroAccountReducer, taxData } = useSelector((state: any) => ({
    xeroAccountReducer: state.xeroAccountReducer,
    taxData: state.taxReducer.data,
  }));

  const { xeroCodeLoading, xeroCodeData, xeroCodeError } = xeroAccountReducer;
  const taxes = taxData?.payload?.data || [];
  const loading = xeroCodeLoading && hasValidToken;
  const { accounts: accountingCategories = {} } = xeroCodeData?.payload || {};
  const accountingCategoriesNumber = Object.values(accountingCategories).flatMap((account: any) =>
    account.data.map((item) => item.number)
  );

  const allowEdit = (usePermissionCheck([
    { object: CASBIN_PAGES.INTEGRATION_PAGE, action: INTEGRATION_PAGE.XERO_INTEGRATION },
  ]) || false) as boolean;

  const trackExpenseCategoryEditAddActions = (formItemName, inputName) => {
    const currentValue = form.getFieldValue?.(formItemName);
    currentValue &&
      currentValue !== details?.[formItemName] &&
      trackEvent(categoryEvents.EXPENSE_CATEGORY_DETAILS_EDITED, {
        category_details_event_source: inputName,
        accounting_details_event_action: isEdit ? "Edit" : "Add",
      });

    setInitialCategoryData({
      ...initialCategoryData,
      [formItemName]: currentValue,
    });
  };
  const allowToUpdateCategoryName = allowEdit && !UNEDITABLE_CATEGORIES.includes(details?.categoryName);

  useEffect(() => {
    if (hasValidToken && !xeroCodeData) {
      dispatch(xeroExpenseCodes());
    }
  }, [hasValidToken]);

  useEffect(() => {
    if (error) {
      form.setFields([
        {
          name: "categoryName",
          errors: [error.message],
        },
      ]);
    }
  }, [error, form]);

  if (loading) {
    return <LoaderIcon />;
  }

  return (
    <Form
      form={form}
      onFinish={onFinish}
      hideRequiredMark
      onValuesChange={() => setFieldChange(true)}
      name="category_edit"
      className="category-details-edit"
      initialValues={details}
    >
      <Form.Item
        label="Category name"
        name="categoryName"
        rules={[
          {
            required: true,
            message: "Please enter the category name!",
          },
        ]}
      >
        <Input
          disabled={!allowToUpdateCategoryName}
          placeholder="Category name"
          onChange={() => {
            form.setFields([
              {
                name: "categoryName",
                errors: [],
              },
            ]);
          }}
          onBlur={() => {
            trackExpenseCategoryEditAddActions("category_name", "Account Code");
          }}
        />
      </Form.Item>
      <Form.Item label="Default tax" name="taxId">
        <Select
          disabled={!allowEdit}
          placeholder="Select default tax"
          id="defaultTax"
          getPopupContainer={(triggerNode) => triggerNode.parentElement}
          onChange={() => trackExpenseCategoryEditAddActions("tax_id", "Tax")}
        >
          {taxes?.map((tax) => (
            <Option key={tax.id} value={tax.id}>
              {`${tax.tax_name} (${roundValue(tax.tax_rate)}%)`}
            </Option>
          ))}
        </Select>
      </Form.Item>
      {!hasValidToken && (
        <Form.Item label="Account Code" name="accountingSystemCode">
          <Input
            disabled={!allowEdit}
            placeholder="account code"
            onBlur={() => trackExpenseCategoryEditAddActions("accounting_system_code", "Account Code")}
          />
        </Form.Item>
      )}
      {xeroCodeData && !xeroCodeError && (
        <Form.Item name="accountingSystemCode" label="Account Code">
          {accountingCategories && (
            <Select
              disabled={!allowEdit}
              optionFilterProp="children"
              placeholder="account code"
              getPopupContainer={(triggerNode) => triggerNode.parentElement}
              dropdownClassName="category-details-edit__account-codes-dropdown"
              showSearch
              onChange={(e) => {
                form.setFieldsValue({ accounting_system_code: e });
                trackExpenseCategoryEditAddActions("accounting_system_code", "Account Code");
              }}
              value={
                accountingCategoriesNumber?.includes(details?.accountingSystemCode) ? details?.accountingSystemCode : ""
              }
              filterOption={(inpVal, elem: any) => {
                if (elem?.children) {
                  return elem.children.toLowerCase().indexOf(inpVal.toLowerCase()) !== -1;
                }
              }}
              allowClear
              clearIcon={() => <img src={closeIcon} alt="clear icon" />}
            >
              {Object.entries(accountingCategories).map?.(([type, value]: any) => (
                <OptGroup label={type.toLowerCase()} key={type}>
                  {value?.data?.map?.((account) => (
                    <Option
                      key={account?.number}
                      value={account?.number}
                      className="category-details-edit__account-codes-dropdown__option"
                    >
                      {`${account?.name}(${account?.number})`}
                    </Option>
                  ))}
                </OptGroup>
              ))}
            </Select>
          )}
        </Form.Item>
      )}

      {isEdit && (
        <Form.Item label="Status" name="status">
          <Select
            placeholder="Select tax Status"
            id="categoryStatus"
            defaultValue="active"
            getPopupContainer={(triggerNode) => triggerNode.parentElement}
            onChange={() => trackExpenseCategoryEditAddActions("status", `Status"}`)}
          >
            {categoryStatus?.map((status) => (
              <Option key={status.id} value={status.value}>
                {status.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
      )}

      <div className="category-details-edit__action-btns">
        <FormButton
          isEdit={isEdit}
          allowEdit={allowEdit}
          actionLoading={actionLoading}
          fieldChanged={fieldChanged}
          onCancel={onCancel}
        />
      </div>
    </Form>
  );
};

export default CategoryForm;
