import {
  FETCH_VERIFY_FORGOT_OTP_LIST,
  initialVerifyForgotOTPState,
  VerifyForgotOTPData,
  VerifyForgotOTPReducer,
} from "Redux/Actions/Auth/VerifyForgotOTP/types";

export const verifyForgotOTP = (
  state: VerifyForgotOTPReducer = initialVerifyForgotOTPState,
  action: { type: FETCH_VERIFY_FORGOT_OTP_LIST; data: VerifyForgotOTPData }
): VerifyForgotOTPReducer => {
  switch (action.type) {
    case FETCH_VERIFY_FORGOT_OTP_LIST.REQUEST:
      return {
        ...state,
        loading: true,
        isSuccess: false,
        error: false,
      };
    case FETCH_VERIFY_FORGOT_OTP_LIST.SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        isSuccess: true,
        error: false,
      };
    case FETCH_VERIFY_FORGOT_OTP_LIST.ERROR:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: true,
        isSuccess: false,
      };
    case FETCH_VERIFY_FORGOT_OTP_LIST.RESET:
      return {
        ...state,
        data: null,
        isSuccess: false,
        error: false,
      };
    default:
      return state;
  }
};
