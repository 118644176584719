import React, { useEffect, useState } from "react";
import cn from "classnames";
import pt from "prop-types";
import { useLocation } from "react-router-dom";

import { Accordion, EShadowType } from "Modules/DS/Accordion";
import Loader from "Modules/DS/Loader";
import { LOADER_SIZES, LOADER_TYPES } from "Modules/DS/Loader/types";
import Icon from "Modules/Icon";
import ProgressBar from "Modules/ProgressBar";

import { getData, deleteData } from "API/Client";
import { srcFileTypes } from "../helper";
import { plural } from "utility";
import styles from "../Bills.module.scss";

interface LoadingState {
  isUploading: boolean;
}

const DraftUpload = (props) => {
  const { mutateDraftList } = props;
  const location = useLocation<LoadingState>();

  const [files, setFiles] = useState([]);
  const [uniqueFiles, setUniqueFiles] = useState({});
  const [lastUploadedDate, setLastUploadedDate] = useState();

  const getUploadProgress = () => {
    getData("/ms/spm-disbursement/v1/bill/draft/progress")
      .then((res) => {
        const { status, payload } = res.data;

        if (status !== 200) {
          throw new Error(status);
        }
        setFiles(payload.list);

        setUniqueFiles((prevState) => {
          const files = { ...prevState };

          payload.list.forEach((file) => {
            files[file.id] = true;
          });

          return files;
        });

        if (payload.lastUploadedDate !== lastUploadedDate) {
          setLastUploadedDate(payload.lastUploadedDate);

          if (payload.lastUploadedDate) {
            mutateDraftList();
          }
        }
      })
      .catch(() => {
        setFiles([]);
      });
  };

  const handleResetUpload = () => {
    setFiles([]);
    setUniqueFiles({});
  };

  const handleDeleteUpload = (e, id) => {
    e.preventDefault();

    deleteData(`/ms/spm-disbursement/v1/bill/draft/progress/${id}`).then(() => {
      setUniqueFiles((prevState) => {
        delete prevState[id];

        return prevState;
      });

      getUploadProgress();
    });
  };

  useEffect(() => {
    if (location.pathname === "/bills/drafts") getUploadProgress();
  }, [location.key]);

  useEffect(() => {
    if (location.pathname === "/bills/drafts") {
      const uploadInterval = setInterval(() => getUploadProgress(), 10000);

      return () => {
        clearInterval(uploadInterval);
      };
    }
  }, [location.key, lastUploadedDate]);

  const isUploading = location.state?.isUploading;
  const isProcessing = files.length;
  const successFiles = files.filter((file) => file.errorCode === 0);
  const failedFilesCount = files.length - successFiles.length;
  const completedCount = Object.keys(uniqueFiles).length - failedFilesCount;

  if (!(isProcessing || completedCount || isUploading)) {
    return null;
  }

  const accordionCompletedSubtitle = [];

  if (completedCount > 0) {
    accordionCompletedSubtitle.push(`${plural(completedCount, "upload")} completed`);
  }

  if (failedFilesCount) {
    accordionCompletedSubtitle.push(`${plural(failedFilesCount, "upload")} failed`);
  }

  const accordionProps =
    successFiles.length || isUploading
      ? {
          icon: <Loader size={LOADER_SIZES.BIG} type={LOADER_TYPES.BRAND} />,
          title: "Uploading and analyzing data...",
          subtitle: !isUploading && `Uploading ${plural(successFiles.length, "item")}`,
        }
      : {
          className: cn(styles.accordionCompleted, {
            [styles.success]: !failedFilesCount,
          }),
          icon: <Icon icon="check" size="24" className={styles[!failedFilesCount ? "successIcon" : "dangerIcon"]} />,
          title: "Upload and analysis completed",
          subtitle: accordionCompletedSubtitle.join(", "),
          suffixIcon: !failedFilesCount ? (
            <Icon className={styles.resetIcon} icon="close" onClick={handleResetUpload} />
          ) : undefined,
        };

  return (
    <Accordion
      {...accordionProps}
      className={cn(styles.accordion, accordionProps?.className)}
      shadow={EShadowType.RAISED}
    >
      {files?.map((file) => {
        const { id, filename, fileType, errorCode, progressPercentage } = file;
        const isError = errorCode > 0;

        return (
          <div key={id} className={styles.uploadItem} data-testid="draft-upload-files">
            <img src={srcFileTypes[fileType]} width={48} height={48} alt={filename} />
            <div>
              <p className={styles.fileName}>{filename}</p>
              <p
                className={cn(styles.fileStatus, {
                  [styles.failed]: isError,
                })}
              >
                {isError ? "Upload failed" : "Processing..."}
              </p>
            </div>
            <ProgressBar percentage={progressPercentage} isError={isError} />
            {isError && (
              <Icon
                data-testid="upload-error-icon"
                className={styles.uploadIcon}
                icon="trash"
                size={24}
                onClick={(e) => handleDeleteUpload(e, id)}
              />
            )}
          </div>
        );
      })}
    </Accordion>
  );
};

DraftUpload.propTypes = {
  mutateDraftList: pt.func,
};

export default DraftUpload;
