import { userInitialState } from "Redux/InitialState";
import {
  FETCH_SMART_CSV_REQUEST,
  FETCH_SMART_CSV_SUCCESS,
  FETCH_SMART_CSV_FAILURE,
} from "Redux/Actions/SmartCSV/templateAction";

export const smartCSVTemplateReducer = (state = userInitialState, action) => {
  switch (action.type) {
    case FETCH_SMART_CSV_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case FETCH_SMART_CSV_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload,
      };
    case FETCH_SMART_CSV_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        data: action.payload,
      };
    default:
      return state;
  }
};
