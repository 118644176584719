import { BUSINESS_ACTIVITY_ACTION_TYPES } from "Redux/Actions/KYX/GetBusinessActivity";
import { IBusinessActivityData, IBusinessActivityReducer } from "Redux/Actions/KYX/types";
import { userInitialState } from "Redux/InitialState";

export const businessActivityReducer = (
  state: any = userInitialState,
  action: { type: BUSINESS_ACTIVITY_ACTION_TYPES; data: IBusinessActivityData }
): IBusinessActivityReducer => {
  switch (action.type) {
    case BUSINESS_ACTIVITY_ACTION_TYPES.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case BUSINESS_ACTIVITY_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case BUSINESS_ACTIVITY_ACTION_TYPES.ERROR:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: true,
      };
    default:
      return state;
  }
};
