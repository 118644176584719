import { createContext, useContext } from "react";
import { ISubscriptionDetail } from "Views/SubscriptionManagement/@types";

interface ISubscriptionContext {
  subscription: ISubscriptionDetail;
  mutate: () => void;
}

export const initialSubscription = {} as ISubscriptionDetail;

export const SubscriptionContext = createContext<ISubscriptionContext>({
  subscription: {
    id: "",
    billing_frequency: null,
    budget: { id: "", name: "" },
    owner: { id: "", name: "" },
    contract_type: null,
    currency_code: "",
    description: "",
    documents: [],
    end_date: "",
    start_date: "",
    estimated_cost: null,
    note: "",
    review_expiry_date: "",
    review_contract_duration: null,
    variation_percentage: null,
    status: null,
    vendor: { id: "", name: "", icon: "" },
    next_renewal_date: "",
    last_payment_amount: 0,
    last_payment_Foreign_amount: 0,
    last_payment_foreign_currency: "",
    next_billing_date: "",
    payment: {
      card_id: "",
      card_last_four: "",
      card_name: "",
      card_status: null,
      payment_limit_amount: 0,
    },
  },
  mutate: () => undefined,
});

export const useSubscriptionContext: () => ISubscriptionContext = () => useContext(SubscriptionContext);
