import React, { useContext } from "react";
import { Tag } from "antd";
import "./styles.scss";
import { AccountingTagsContext } from "Modules/AccountingTags/context/AccountingTagsContext";

const SelectedTag = ({ name, tagGroup, editable = true }) => {
  const { deleteTag } = useContext(AccountingTagsContext);
  return (
    <Tag color="#E5F4FF" className="selected-accounting-tags">
      <div className="selected-accounting-tags__wrapper">
        <span>{name}</span>
        {editable && (
          <svg
            width="10"
            height="10"
            viewBox="0 0 10 10"
            className="delete-tag-icon"
            onClick={(e) => {
              e.stopPropagation();
              deleteTag(tagGroup.id);
            }}
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8.8875 2.03057C9.18039 1.73768 9.18039 1.26281 8.8875 0.969914C8.59461 0.677021 8.11973 0.677021 7.82684 0.969914L4.92858 3.86817L2.03033 0.969914C1.73744 0.677021 1.26256 0.677021 0.96967 0.969914C0.676777 1.26281 0.676777 1.73768 0.96967 2.03057L3.86792 4.92883L0.969695 7.82706C0.676802 8.11995 0.676802 8.59482 0.969695 8.88772C1.26259 9.18061 1.73746 9.18061 2.03036 8.88772L4.92858 5.98949L7.82681 8.88772C8.11971 9.18061 8.59458 9.18061 8.88747 8.88772C9.18037 8.59482 9.18037 8.11995 8.88747 7.82706L5.98924 4.92883L8.8875 2.03057Z"
            />
          </svg>
        )}
      </div>
    </Tag>
  );
};

export default SelectedTag;
