import React from "react";
import { Sidepanel } from "@spenmo/splice";
import { ErrorBoundary } from "@sentry/react";
import { useRouteMatch } from "react-router-dom";
import { ISidepanelProps } from "@spenmo/splice/lib/components/SidePanel";

import {
  AuditTrail,
  BatchPayout,
  TransactionDetail,
  TransactionHeader,
} from "Modules/TransactionDetail";

import { useFetchCRState } from "Views/Reimbursement/Settings/useCRState";
import {
  ILocationParams,
  IReimbursementDetailData,
} from "Views/Reimbursement/@types";
import {
  PAGE_TITLE,
  REIMBURSEMENT_PAGE_OPERATIONS,
} from "Views/Reimbursement/Constants";

import SidepanelErrorState from "Views/Reimbursement/Sidepanel/Error";
import SidepanelSkeleton from "Views/Reimbursement/Sidepanel/Skeleton";
import useDetailProps from "Views/Reimbursement/Sidepanel/Detail/useDetailProps";
import useDetailState from "Views/Reimbursement/Sidepanel/Detail/useDetailState";

import styles from "./index.module.scss";

const ReimbursementDetail = ({
  data,
  setFormValue,
}: {
  data: IReimbursementDetailData;
  setFormValue: React.Dispatch<any>;
}) => {
  const { fetchState } = useFetchCRState();
  const isSettlementEnabled: boolean =
    fetchState?.data?.data?.payload?.isEnabled || false;

  const { headerProps, auditTrail, payoutProps, detailProps } = useDetailProps(
    data,
    setFormValue,
  );

  return (
    <div className={styles.container}>
      <TransactionHeader {...headerProps} />
      <AuditTrail {...auditTrail} />
      {isSettlementEnabled && <BatchPayout {...payoutProps} />}
      <TransactionDetail {...detailProps} />
    </div>
  );
};

const Container = ({ id }: { id: string }) => {
  const { panelProps, showErrorState, showSkeleton, data, setFormValue } =
    useDetailState(id);

  const props: ISidepanelProps = {
    showSidepanel: true,
    position: "right",
    transitionType: "rightToLeft",
    title: `${PAGE_TITLE} Details`,
    footerAlignment: "end",
    footerStacking: "horizontal",
    ...panelProps,
  };

  const renderSkeleton = () => {
    return showSkeleton ? (
      <SidepanelSkeleton />
    ) : (
      <ReimbursementDetail setFormValue={setFormValue} data={data} />
    );
  };

  const handleErrorOnPanel = () => {
    return showErrorState ? <SidepanelErrorState /> : renderSkeleton();
  };

  return <Sidepanel {...props}>{handleErrorOnPanel()}</Sidepanel>;
};

const DetailSidepanel = () => {
  const boundary = () => {
    return (
      <Sidepanel
        showSidepanel={true}
        footerStacking="horizontal"
        position="right"
        title={`${PAGE_TITLE} Details`}
        transitionType="rightToLeft"
      >
        <SidepanelErrorState />
      </Sidepanel>
    );
  };

  const { params } = useRouteMatch<ILocationParams>();

  const {
    operation = "",
    id = "",
    secondaryOperation = "",
    secondaryId = "",
  } = params;

  let _id = "";

  if (operation === REIMBURSEMENT_PAGE_OPERATIONS.READ) {
    _id = id;
  } else if (
    operation === REIMBURSEMENT_PAGE_OPERATIONS.SETTLEMENTS &&
    secondaryOperation === REIMBURSEMENT_PAGE_OPERATIONS.READ
  ) {
    _id = secondaryId;
  }

  return (
    <ErrorBoundary fallback={boundary()}>
      <div className={styles.panel}>
        <Container id={_id} />
      </div>
    </ErrorBoundary>
  );
};

export default DetailSidepanel;
