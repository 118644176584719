import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import classNames from "classnames";
import { configQuickAccessShortCuts } from "./QuickLinks.config";

import { useMutableData } from "API/useData";
import * as homeIcons from "assets/img/homeIcons";
import QuickAccessTitleSplit from "./QuickAccessTitleSplit";
import { getTeamRole } from "utility";
import { Tooltip } from "Modules/DS/Tooltip";
import Icon from "Modules/icons";
import { trackEvent } from "utility/analytics";
import { UOBDisabledAccessList } from "./List/UOBList";
import QuickLinksShimmer from "./QuickLinksShimmer";
import useUOBCheck from "customHooks/Card/useUOBCheck.tsx";
import useCheckOrgConfigs from "customHooks/useCheckOrgConfigs.tsx";
import usePermissionCheck from "Permission/usePermissionCheck";
import useCheckFeatureStatus from "customHooks/featureCheck";
import { SPLIT_NAMES, SPLIT_TREATMENT_TYPES } from "Redux/splitio/constants";
import { PRODUCT_NAME } from "Redux/ModularProduct";
import { CASBIN_PAGES } from "Permission/Pages";
import { PAYMENT_RUN_SETTING_PAGE } from "Permission/Actions";
import styles from "./index.module.scss";

const GET_PAYMENT_RUN_SETTING = "/ms/spm-disbursement/v1/payment-run/settings";

const permissions = [
  { object: CASBIN_PAGES.PAYMENT_RUN, action: PAYMENT_RUN_SETTING_PAGE.READ },
];

const QuickLinks = (props) => {
  const {
    isCardVendorNium,
    teamData,
    userInfo,
    isCardVendorNone = false,
  } = props;
  const DEFAULT_QUICK_LINKS_LENGTH = 4;
  const teamRole = getTeamRole(
    teamData?.team_members,
    userInfo?.id,
    teamData?.your_membership,
  );
  const companyRole = teamData?.company_role;
  const [isUOB, loading] = useUOBCheck();
  const paymentRunFlag = useCheckFeatureStatus(
    SPLIT_NAMES.billPaymentRunPage,
    true,
  );
  const paymentRunAllowed = usePermissionCheck(permissions);

  const { data } = useMutableData(
    paymentRunAllowed ? GET_PAYMENT_RUN_SETTING : null,
  );

  const isSetupPaymentRun = Boolean(data?.data?.payload?.setting);

  // should refactor to use function to get isLimitModel
  const isLimitModel = isCardVendorNium || isCardVendorNone || isUOB;
  const quickLinksConfigByRole =
    configQuickAccessShortCuts({
      isLimitModel,
      teamRole,
      companyRole,
      userInfo,
      isPaymentRunFlagOn:
        paymentRunFlag === SPLIT_TREATMENT_TYPES.ON && paymentRunAllowed,
      isSetupPaymentRun,
    }) || {};

  const IS_BILLPAY_AVAILABLE = useCheckOrgConfigs(PRODUCT_NAME.BILLPAY);
  const IS_REIMBURSEMENT_AVAILABLE = useCheckOrgConfigs(
    PRODUCT_NAME.CASH_REIMBURSEMENT,
  );
  const IS_BILLS_AND_REIMBURSE_AVAILABLE =
    IS_BILLPAY_AVAILABLE && IS_REIMBURSEMENT_AVAILABLE;

  const _ContainerClassNames = classNames(styles.container, {
    [styles.wrapped]:
      quickLinksConfigByRole?.length < DEFAULT_QUICK_LINKS_LENGTH,
    [styles.modularContainer]: isCardVendorNone,
  });

  const handleShortCuts = (QuickAccessShortCut, isDiasabled) => {
    if (isDiasabled) {
      return (
        <Tooltip
          text={"Not supported on this \ndashboard"}
          alignArrow="center"
          position="top"
        >
          {QuickAccessShortCut}
        </Tooltip>
      );
    }

    return QuickAccessShortCut;
  };

  const HandleQuickLinks = (quickLink) => {
    const [firstWord, restTitle] = QuickAccessTitleSplit(quickLink?.title);
    const isShortcutAccessDisabled =
      !IS_BILLS_AND_REIMBURSE_AVAILABLE &&
      UOBDisabledAccessList?.includes(quickLink.title);
    const showGreyIconForUOB = isShortcutAccessDisabled
      ? homeIcons?.[`${quickLink.iconName}Grey`]
      : quickLink.icon;
    const _classNames = classNames(styles.card, {
      [styles.disabled]: isShortcutAccessDisabled,
    });

    const QuickAccessShortCut = (
      <Link
        onClick={() => trackEvent(quickLink.eventName)}
        to={quickLink.to}
        data-testid={quickLink.title}
        className={_classNames}
        key={quickLink.title}
      >
        <Icon
          src={showGreyIconForUOB}
          alt={`${quickLink.title} icon`}
          className={styles.icon}
        />
        <p className={styles.text}>{firstWord}</p>
        {isCardVendorNone && <span className={styles.space} />}
        {restTitle && <p className={styles.text}>{restTitle}</p>}
      </Link>
    );

    return quickLink?.element
      ? quickLink?.element
      : handleShortCuts(QuickAccessShortCut, isShortcutAccessDisabled);
  };

  const isLoading = loading;

  return (
    <div className={_ContainerClassNames}>
      {Array.isArray(quickLinksConfigByRole) && !isLoading ? (
        quickLinksConfigByRole?.map(HandleQuickLinks)
      ) : (
        <QuickLinksShimmer isModular={isCardVendorNone} />
      )}
    </div>
  );
};

QuickLinks.propTypes = {
  isCardVendorNium: PropTypes.bool,
  teamData: PropTypes.object,
  userInfo: PropTypes.object,
};

export default QuickLinks;
