import React from "react";

import { Field, LabelWithTooltip, Select } from "Views/SubscriptionManagement/Create/Components";

import { option } from "Views/SubscriptionManagement/Create/options";
import { useFormContext } from "Views/SubscriptionManagement/Create/Context";
import { CONTRACT_TYPE, FORM_NAMES } from "Views/SubscriptionManagement/Constants";

import styles from "Views/SubscriptionManagement/Create/styles.module.scss";

export const ReviewContract = () => {
  const { control, watch } = useFormContext();

  const contractType = watch(FORM_NAMES.CONTRACT_TYPE);
  if (!contractType) {
    return null;
  }
  const reviewType = contractType === CONTRACT_TYPE.FIXED ? "expiry" : "renewal";
  return (
    <>
      <Field>
        <div className={styles.labelWithToggle}>
          <LabelWithTooltip
            tooltipTitle={
              contractType === CONTRACT_TYPE.FIXED
                ? "You will be notified before your contract expires"
                : "You will be notified before your contract auto-renews"
            }
            title={`Reminder Before ${reviewType}`}
          />
        </div>
        <Select
          name={FORM_NAMES.REVIEW_CONTRACT_DURATION}
          control={control}
          showSearch
          placeholder="Select Expiry Method"
          options={option.expiry(reviewType)}
        />
      </Field>
    </>
  );
};
