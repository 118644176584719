import React, { useContext, useEffect } from "react";

import {
  IPageWithTable,
  IGetBillTrxnResponse,
  IBillsTrxnSuccessData,
  IGetBillsTrxnRequest,
  IBillsTrxnErrorData,
} from "Views/Transaction/@types";
import { TrxnFilterProvider } from "Views/Transaction/Provider";
import {
  initialBillsTrxnsSuccess,
  initialGetBillTrxnRequest,
  initialTrxnsError,
} from "Views/Transaction/DataCalls";
import { BillTrxnList } from "Views/Transaction/Bills/List";
import { useBillTrxnsListFetch } from "Views/Transaction/Bills/Hooks/useFetch";
import { SelectProvider } from "Modules/DS/Select";
import { TrxnToastProvider } from "Views/Transaction/Provider/Toast";
import { TrxnModalProvider } from "Views/Transaction/Provider/Modal";
import { TrxnPaginationProvider } from "Views/Transaction/Provider/Pagination";

import styles from "./SubmittedBills.module.scss";
import DataTable, {
  DATA_TABLE_PADDING,
  DATA_TABLE_SIZE_TYPES,
} from "Modules/DS/DataTable";
import Modal from "Views/Transaction/Modal";
import PaymnentCycleView from "Views/Bills/V2/PaymentRun";
import { SWRConfig } from "swr";
import { useHistory, useLocation } from "react-router-dom";
import { PermissionAndSettingsContext } from "Views/Bills/V2/context/BillContext";
import { getIsLimitModel } from "utility";
import Sidepanel from "Views/Bills/V2/Sidepanel";
import NewModal from "Views/Bills/V2/Modal";
import DragDropButton from "Views/Bills/DragDropButton";
import { CASBIN_PAGES } from "Permission/Pages";
import { BILL_PAYMENTS_PAGE } from "Permission/Actions";
import usePermissionCheck from "Permission/usePermissionCheck";
import { PermissionCheckResultWithLoading } from "Permission/types";

// TODO: after feature flag for moving bill trx is removed
// and bill trx is moved from trx page completely
// we should rewrite this to be independent from trx page

const SubmittedBills = (props): JSX.Element => {
  const history = useHistory();
  const { onUpload } = props;
  const { pathname } = useLocation();
  const isLimitModelOrg = getIsLimitModel();
  const { error, loading, data, fetchData } = useBillTrxnsListFetch();
  const isPaymentRunView = pathname.includes("/bills/payment-run");
  const {
    permissionLoading,
    viewAllowed,
    settingLoading: isPaymentRunSettingLoading,
    settings: paymentRunSettingEnabled,
  } = useContext(PermissionAndSettingsContext);

  useEffect(() => {
    const anyNullOrUndefined =
      [
        isPaymentRunView,
        isPaymentRunSettingLoading,
        permissionLoading,
        isLimitModelOrg,
        paymentRunSettingEnabled,
        viewAllowed,
      ].filter((val) => val === null || val === undefined).length > 0;
    if (!anyNullOrUndefined) {
      const redirectIfAccessedViaUrl =
        isPaymentRunView &&
        !isPaymentRunSettingLoading &&
        !permissionLoading &&
        (!isLimitModelOrg || !paymentRunSettingEnabled || !viewAllowed);

      if (redirectIfAccessedViaUrl) {
        history.replace("/bills/submitted");
      }
    }
  }, [
    isPaymentRunView,
    isPaymentRunSettingLoading,
    permissionLoading,
    isLimitModelOrg,
    paymentRunSettingEnabled,
    viewAllowed,
    history,
  ]);

  const errorMessage: string =
    error && (data as IBillsTrxnErrorData)?.error
      ? (data as IBillsTrxnErrorData).error
      : initialTrxnsError.empty.error;

  const total: number = error
    ? initialBillsTrxnsSuccess.mocked.countAll
    : (data as IBillsTrxnSuccessData)?.countAll || 0;

  const listData: IGetBillTrxnResponse[] = error
    ? initialBillsTrxnsSuccess.mocked.list
    : (data as IBillsTrxnSuccessData)?.list;

  const transactionListProps: IPageWithTable<IGetBillTrxnResponse> = {
    total,
    error,
    loading,
    errorMessage,
    data: listData,
  };
  const filters: IGetBillsTrxnRequest = initialGetBillTrxnRequest();

  const createNewBill = {
    object: CASBIN_PAGES.BILL_PAYMENTS_PAGE,
    action: BILL_PAYMENTS_PAGE.SUBMIT_BILL_PAYMENT,
  };

  const [createBillAllowed, createBillPermissionLoading] = usePermissionCheck(
    [createNewBill],
    true,
  ) as PermissionCheckResultWithLoading;

  return (
    <TrxnToastProvider>
      <TrxnModalProvider>
        {!createBillPermissionLoading && createBillAllowed && (
          <DragDropButton
            onUpload={onUpload}
            description="Create and track all bills in your organization"
          />
        )}
        <DataTable
          className={styles.transactions}
          size={DATA_TABLE_SIZE_TYPES.LARGE}
          padding={DATA_TABLE_PADDING.NONE}
        >
          <div className={styles.submittedBills}>
            <SelectProvider>
              <TrxnPaginationProvider>
                <TrxnFilterProvider
                  filters={isPaymentRunView ? {} : filters}
                  onApply={isPaymentRunView ? () => {} : fetchData}
                  moreFilters={[]}
                >
                  {isPaymentRunView && (
                    <SWRConfig>
                      <PaymnentCycleView />
                    </SWRConfig>
                  )}
                  {!isPaymentRunView && (
                    <BillTrxnList {...transactionListProps} />
                  )}
                </TrxnFilterProvider>
              </TrxnPaginationProvider>
            </SelectProvider>
          </div>
        </DataTable>
        <Sidepanel />
        <Modal />
        <NewModal />
      </TrxnModalProvider>
    </TrxnToastProvider>
  );
};

export default SubmittedBills;
