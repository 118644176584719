import {
  FETCH_PHONE_NUMBER_REQUEST,
  FETCH_PHONE_NUMBER_SUCCESS,
  FETCH_PHONE_NUMBER_ERROR,
} from "Redux/Actions/Common/checkPhoneNumAction";
import { userInitialState } from "Redux/InitialState";

export const checkPhoneNumReducer = (state = userInitialState, action) => {
  switch (action.type) {
    case FETCH_PHONE_NUMBER_REQUEST:
      return {
        ...state,
        loading: true,
        data: action.data,
        error: false,
      };
    case FETCH_PHONE_NUMBER_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: false,
      };
    case FETCH_PHONE_NUMBER_ERROR:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: true,
      };
    default:
      return state;
  }
};
