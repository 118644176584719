import { TRANSACTION_TYPE } from "constants/Transaction.constant";
import { EInputType } from "Modules/TransactionDetail";
import { BILL, FIND_TRANSACTION_TYPE_INSIDE_BRACKET } from "../constant/constant";

export const findEmptyField = (formFields: any, settings: any) => {
  if (Object.keys(formFields).length === 0) {
    return [];
  }

  const findRequiredFields = settings.filter((setting) => setting.isEnabled && setting.code.startsWith("field_"));
  const mapped = {
    field_category: "category",
    field_notes: "notes",
    field_vendor: "merchant",
  };

  const missing = findRequiredFields.filter((requiredField) => {
    switch (requiredField.code) {
      case "field_vendor":
        return false;
      case "field_receipts":
        return formFields.receipt ? formFields.receipt.length === 0 : false;
      case "field_tags":
        return formFields.transactionTags ? formFields.transactionTags.length === 0 : false;
      case "field_notes":
        return formFields[mapped.field_notes]?.trim() === "";
      default:
        return !Boolean(formFields[mapped[requiredField.code]]);
    }
  });

  return missing;
};

export const generateMissingField = (expense: any, settings: any) => {
  const mapToInputType = {
    field_category: EInputType.CATEGORY,
    field_notes: EInputType.NOTES,
    field_vendor: EInputType.MERCHANT,
    field_receipts: EInputType.RECEIPT,
    field_tags: EInputType.TRANSACTION_TAGS,
  };

  return findEmptyField(expense, settings).reduce((result, field) => {
    result[mapToInputType[field.code]] = true;
    return result;
  }, {});
};

export const findTransactionType = (type: string) => {
  const isBill = type === BILL;
  const isTopup = type === TRANSACTION_TYPE.TOPUP;
  const isIncentive = type === TRANSACTION_TYPE.INCENTIVE;
  const transactionType = (type?.match?.(FIND_TRANSACTION_TYPE_INSIDE_BRACKET) || [])[1];
  const nonExpenseTransaction =
    [TRANSACTION_TYPE.REVERSAL, TRANSACTION_TYPE.SETTLEMENT].includes(transactionType) ||
    type === TRANSACTION_TYPE.FEES_REFUND;
  const isFee = type === TRANSACTION_TYPE.FEES;
  const isSettlement = TRANSACTION_TYPE.SETTLEMENT === transactionType;

  return {
    isBill,
    isTopup,
    isFee,
    nonExpenseTransaction,
    isIncentive,
    isSettlement,
  };
};
