import React, { useState } from "react";
import { createPortal } from "react-dom";
import { receiptsIcon } from "assets/img";
import { addReceipt } from "assets/v1.1/icons/Line";
import { IconCell } from "Views/Transaction/List";
import { ViewAttachments } from "Views/Transaction/Modal/Attachments";
import Modal from "Modules/DS/Modal";

import styles from "./styles.module.scss";

export const ModalAttachment = ({ receipts }: { receipts: string[] }) => {
  const [openAttachment, setOpenAttachment] = useState(false);

  if (receipts.length === 0) {
    return <IconCell src={addReceipt} alt="no attachment" />;
  }

  return (
    <>
      <IconCell src={receiptsIcon} alt="view attachment" onClick={() => setOpenAttachment(true)} />

      {createPortal(
        <Modal visible={openAttachment} className={styles.attachment} close={() => setOpenAttachment(!openAttachment)}>
          <ViewAttachments receipts={receipts} />
        </Modal>,
        document.getElementById("modal_container")
      )}
    </>
  );
};
