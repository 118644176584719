import { FETCH_EMAIL_REQUEST, FETCH_EMAIL_SUCCESS, FETCH_EMAIL_ERROR } from "Redux/Actions/Common/checkEmailAction";
import { userInitialState } from "Redux/InitialState";

export const checkEmailReducer = (state = userInitialState, action) => {
  switch (action.type) {
    case FETCH_EMAIL_REQUEST:
      return {
        ...state,
        loading: true,
        data: action.data,
        error: false,
      };
    case FETCH_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: false,
      };
    case FETCH_EMAIL_ERROR:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: true,
      };
    default:
      return state;
  }
};
