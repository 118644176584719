import React from "react";
import Icon, { IconList } from "Modules/Icon";
import styles from "./index.module.scss";

const AddButton: React.FC = (): React.ReactElement => {
  return (
    <button type="button">
      <span className={styles.addButton}>
        <Icon
          className={styles.addIcon}
          svgAttr={{ width: 16, height: 16, viewBox: "0 0 16 16" }}
          icon={IconList.add}
        />
      </span>
    </button>
  );
};

export default AddButton;
