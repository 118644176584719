import APIclient from "API/Client";

export const VALIDATE_APPROVAL_FLOW_REQUEST = "VALIDATE_APPROVAL_FLOW_REQUEST";
export const VALIDATE_APPROVAL_FLOW_SUCCESS = "VALIDATE_APPROVAL_FLOW_SUCCESS";
export const VALIDATE_APPROVAL_FLOW_FAILURE = "VALIDATE_APPROVAL_FLOW_FAILURE";
export const CLEAR_VALIDATE_APPROVAL_FLOW = "CLEAR_VALIDATE_APPROVAL_FLOW";

const validateApprovalFlowReq = (data?: any) => {
  return {
    type: VALIDATE_APPROVAL_FLOW_REQUEST,
    data,
  };
};
const validateApprovalFlowSuccess = (data?: any) => {
  return {
    type: VALIDATE_APPROVAL_FLOW_SUCCESS,
    data,
  };
};
const validateApprovalFlowError = (data?: any) => {
  return {
    type: VALIDATE_APPROVAL_FLOW_FAILURE,
    data,
  };
};

export const clearValidateApprovalFlow = () => {
  return {
    type: CLEAR_VALIDATE_APPROVAL_FLOW,
  };
};

export const validateApprovalFlowFunc = (payload) => {
  return (dispatch) => {
    dispatch(validateApprovalFlowReq());

    APIclient.postData(`/ms/spm-organization/v2/approval/workflow/validate`, payload)
      .then((data) => {
        dispatch(validateApprovalFlowSuccess(data.data));
      })
      .catch((err) => {
        const errorDetail = err?.response?.data?.error || err;
        dispatch(
          validateApprovalFlowError({
            ...errorDetail,
            data: err?.response?.data?.payload,
          })
        );
      });
  };
};
