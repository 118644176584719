export const TABS = { details: "Details", status: "Status" };
export const ACTION = { approve: "Approve", decline: "Decline", save: "Save Changes" };
export const STATUS = {
  draft: "draft",
  extracted: "extracted",
  waitingApproval: "waiting_approval",
  insufficient: "insufficient",
  pending: "pending",
  completed: "completed",
  payoutIssue: "payout_issue",
  received: "received",
  cancelled: "cancelled",
  reversed: "reversed",
  rejected: "rejected",
  approved: "approved",
};
export const RELOAD_TIME_OUT = 1000;

export const ENABLE_CONFIRM_TYPES = {
  both: "both",
  approve: "approve",
  decline: "decline",
  none: "none",
};
