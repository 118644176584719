export type PaymentRunPreviewParams = {
  billIDs: string;
};

export enum BANK_METHOD {
  PAY_NOW = "paynow",
}

export interface QRData {
  recipient: string;
  qr_code: string;
}

export interface BankTransferItemMethod {
  countryFlagUrl?: string;
  countryLabel?: string;
  currencyCode?: string;
  bankLogoUrl?: string;
  additionalBankname?: string;
  bankCode?: string;
  accountName?: string;
  accountNumber?: string;
  swiftCode?: string;
  bankAddress?: string;
  descriptionLines?: string[];
  additionalInfos?: {
    label: string;
    value: string;
  }[];
  notes?: string;
  instantReflectNotes?: string;
  isUSDVirtualAccount?: boolean;
}

export interface BankTransferProps {
  description: string;
  methods: BankTransferItemMethod[];
  onClickAutoPay?(): void;
  isAutoPayLoading: boolean;
}

export interface PaymentMethodProps {
  invoiceAmount: number;
}

export interface PaynowProps {
  onClickAutoPay?(): void;
  isAutoPayLoading: boolean;
  isAutoPayDisabled?: boolean;
}

export interface CopyTextProps {
  className?: string;
  text: string;
}

export enum BankTransferItemType {
  IMG = "image",
  TEXT = "text",
  COPY_TEXT = "copyText",
  HTML = "html",
  // custom
  ADDITIONAL_INFO = "additionalInfos",
  USDVirtualAccount = "USDVirtualAccount",
}

export interface SkeletonProps {
  loading: boolean;
}
