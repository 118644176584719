import { reverseObject } from "utility";

export const getMerchantName = (transaction) => {
  return transaction.simplified_merchant_name || transaction.merchant || "N/A";
};

export const TABS = {
  all: "All",
  cards: "Cards",
  team: "Team Transactions",
  user: "Your Transactions",
  pending: "Bills",
  scheduled: "Scheduled Internal Transactions",
};

export const SUB_TABS = {
  all: "All",
  to_approve: "Approval Required",
};

export const REVERSE_TABS = reverseObject(TABS);
export const REVERSE_SUB_TABS = reverseObject(SUB_TABS);

export const EMAIL_APPROVAL_STATE = {
  detail: "detail",
  approved: "approved",
  declined: "declined",
};

export const TR_STATUS = {
  EXCEEDED: "limit_exceeded",
  INSUFFICIENT: "insufficient",
};
