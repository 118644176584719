import React from "react";
import classNames from "classnames";
import { Select } from "antd";

import { FilterById } from "utility";
import { useActiveCategory } from "utility/useActiveCategory";
import LoadingComponent from "Views/State/Loading/LoaderIcon";
import ErrorComponent from "Views/State/Error";

import "./categoryDropdown.scss";

interface ICategoryDropdown {
  showEmptyCategory?: boolean;
  action: (value: string | string[], additionalData?: any) => void;
  defaultValue?: string;
  onDropdownVisibleChange?: React.Dispatch<React.SetStateAction<boolean>>;
  fallbackValue?: string;
  missingField?: boolean;
  suffixIcon?: React.ReactElement;
  disabled?: boolean;
}

const { Option } = Select;

export default function CategoryDropdown({
  defaultValue,
  fallbackValue,
  missingField,
  action,
  suffixIcon,
  onDropdownVisibleChange,
  disabled = false,
  showEmptyCategory,
}: ICategoryDropdown) {
  const { categoryList, error, isLoading } = useActiveCategory();

  const findDefaultValue = () => {
    const isDataExist = categoryList.find(
      (category) => category.categoryName === defaultValue || category.id === defaultValue
    );

    if (isDataExist) {
      return defaultValue;
    }

    return fallbackValue || defaultValue;
  };

  if (isLoading) {
    return <LoadingComponent className="category-dropdown--loader" />;
  }
  if (error) {
    return <ErrorComponent />;
  }

  return (
    <div className="category-dropdown">
      <Select
        showSearch
        defaultValue={findDefaultValue()}
        placeholder="Add Category"
        className={classNames({ "inner-error-border": missingField })}
        getPopupContainer={(triggerNode) => triggerNode.parentElement}
        onSelect={(id) => action(id, FilterById(id, categoryList))}
        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        suffixIcon={suffixIcon}
        onDropdownVisibleChange={onDropdownVisibleChange}
        disabled={disabled}
      >
        {!showEmptyCategory && <Option value="">N.A.</Option>}
        {categoryList.map((data) => (
          <Option key={data.id} value={data.id} id={data.id}>
            {data.categoryName}
          </Option>
        ))}
      </Select>
    </div>
  );
}
