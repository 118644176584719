import "./upload-file.scss";

import React, { useState, forwardRef } from "react";
import { Progress, Upload } from "antd";
import PropTypes from "prop-types";
import Button from "../button";

const UploadFile = forwardRef((props, ref) => {
  const {
    icon,
    text,
    hint,
    error,
    loading,
    customRequest,
    onClick = () => undefined,
    loadingPlaceHolderText = "Uploading your file",
    withBrowseButton = true,
    customPlaceholder,
    disabled,
    ...other
  } = props;
  const [file, setFile] = useState(null);
  const [progress, setProgress] = useState(0);

  const onUploadProgress = (progressEvent) => {
    const currentProgress = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total));
    setProgress(currentProgress);
  };

  const request = async (payload) => {
    try {
      await customRequest({ payload, onUploadProgress });
    } finally {
      setProgress(0);
    }
  };

  const handleChange = (info) => {
    setFile(info.file);
  };

  const LoadingPlaceholder = (
    <div className={"file-upload__loading"}>
      <div className={"file-upload__text"}>{loadingPlaceHolderText}</div>
      <Progress percent={progress} />
      <span>{file?.name}</span>
    </div>
  );

  const DefaultPlaceholder = (
    <>
      <div className={"file-upload__text"}>{text}</div>
      <div className={"file-upload__hint"}>{hint}</div>
      {error && <div className={"file-upload__error"}>{error}</div>}
      {withBrowseButton && (
        <Button ref={ref} className={"file-upload__button"} rounded disabled={disabled}>
          Browse files
        </Button>
      )}
    </>
  );

  return (
    <Upload.Dragger
      className={"file-upload"}
      name={"files"}
      onChange={handleChange}
      customRequest={request}
      showUploadList={false}
      onClick={onClick}
      disabled={disabled}
      {...other}
    >
      <>
        <div className={"file-upload__icon"}>{icon}</div>
        {loading ? LoadingPlaceholder : customPlaceholder || DefaultPlaceholder}
      </>
    </Upload.Dragger>
  );
});

UploadFile.propTypes = {
  icon: PropTypes.any,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  hint: PropTypes.string,
  error: PropTypes.string,
  loading: PropTypes.bool,
  customRequest: PropTypes.func,
};

export default UploadFile;
