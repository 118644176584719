import { IManualFormContext } from "Views/KYX/@types";
import { createContext } from "react";

export const initialManualFormContext: IManualFormContext = {
  isFormModified: false,
  setIsFormModified: () => null,
  formData: {},
  setFormData: () => null,
};

export const ManualFormContext: React.Context<IManualFormContext> =
  createContext<IManualFormContext>(initialManualFormContext);
