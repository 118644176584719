import {
  FETCH_TICKET_FORM_REQUEST,
  FETCH_TICKET_FORM_SUCCESS,
  FETCH_TICKET_FORM_FAILURE,
  FETCH_TICKET_FORM_CLEAR,
} from "../../Actions/ZenDesk/customTicketAction";
import { userInitialState } from "../../InitialState";

export const customTicketFormReducer = (state = userInitialState, action) => {
  switch (action.type) {
    case FETCH_TICKET_FORM_REQUEST:
      return {
        ...state,
        data: action.data,
        loading: true,
        success: false,
        error: false,
      };
    case FETCH_TICKET_FORM_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
        success: true,
        error: false,
      };
    case FETCH_TICKET_FORM_FAILURE:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: true,
        success: false,
      };
    case FETCH_TICKET_FORM_CLEAR:
      return {
        ...state,
        loading: false,
        error: false,
        success: false,
      };
    default:
      return state;
  }
};
