import React, { useContext, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "Redux/ConfigureStore";
import {
  applyFilter,
  clearFilter,
  selectApprovalFilters,
  setCurrentPage,
} from "Redux/Reducers/Approval/ApprovalCenter/approvalCenter.slice";
import { getCurrencyFunc } from "Redux/Actions";
import { useAppSelector } from "../../../../hook";
import { BillFilter, FilterKey } from "../types";
import { ApprovalFilterContext } from "../context";
import EmployeeFilter from "./EmployeeFilter";
import AmountFilter from "../Filter/amount";
import { initalSelectState, ISelectContext, SelectContext, SelectProvider } from "Modules/DS/Select";
import { POPOVER_WIDTH, POSITION_ALGO_TYPE } from "Modules/DS/Popover";
import { FilterItem } from "Modules/DS/Filter";

const Filter = () => {
  return (
    <SelectProvider>
      <ApprovalFundTransferFilter />
    </SelectProvider>
  );
};

const ApprovalFundTransferFilter = () => {
  const { setSelect } = useContext<ISelectContext>(SelectContext);

  const dispatch = useDispatch();
  const approvalRequestFilter: BillFilter = useAppSelector(selectApprovalFilters);

  const { filters, setFilters } = useContext(ApprovalFilterContext);
  const [selectedRequester, setSelectedRequester] = useState<Array<string>>([]);

  useEffect(() => {
    dispatch(getCurrencyFunc());
  }, []);

  useEffect(() => {
    handleClear(FilterKey.Amount);
    handleClear(FilterKey.Requester);
  }, []);

  const { data: currencyData } = useSelector((state: RootState) => state.currencyReducer);
  const currencies = currencyData?.payload?.currencies || [];

  const setContextValues = (filterKey: FilterKey, value: boolean) => {
    setSelect({ ...initalSelectState });
    setFilters((filters) => {
      return {
        ...filters,
        [filterKey]: value,
      };
    });
  };

  const handleSubmit = (filterKey: FilterKey, values) => {
    dispatch(setCurrentPage(1));
    switch (filterKey) {
      case FilterKey.Amount:
        dispatch(applyFilter({ minAmount: values.min_amount, maxAmount: values.max_amount }));
        break;
      case FilterKey.Requester:
        dispatch(applyFilter({ requester: values.selected }));
        break;
    }
    setContextValues(filterKey, true);
  };

  const handleClear = (filterKey: FilterKey) => {
    dispatch(clearFilter(filterKey));
    setContextValues(filterKey, false);
  };

  return (
    <div className="approval-filter">
      <FilterItem
        id={FilterKey.Amount}
        title="Amount"
        selected={filters[FilterKey.Amount]}
        popoverWidth={POPOVER_WIDTH.L}
        clearFilter={() => handleClear(FilterKey.Amount)}
      >
        <AmountFilter
          currencyData={currencies}
          initialMinAmount={approvalRequestFilter.minAmount}
          initialMaxAmount={approvalRequestFilter.maxAmount}
          handleSubmit={handleSubmit}
          filterKey={FilterKey.Amount}
        />
      </FilterItem>
      <FilterItem
        id={FilterKey.Requester}
        title="Requester"
        selected={filters[FilterKey.Requester]}
        popoverWidth={POPOVER_WIDTH.XS}
        positionAlgoType={POSITION_ALGO_TYPE.HORIZONTAL}
        clearFilter={() => handleClear(FilterKey.Requester)}
      >
        <EmployeeFilter
          handleSubmit={handleSubmit}
          filterKey={FilterKey.Requester}
          selectedRequester={selectedRequester}
          setSelectedRequester={setSelectedRequester}
          initialRequester={approvalRequestFilter?.requester || ""}
        />
      </FilterItem>
    </div>
  );
};

export default Filter;
