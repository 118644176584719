export const detectChange = (selectedExpense, formValue) => {
  const original = {
    tax: selectedExpense.tax?.id,
    merchant: selectedExpense.simplified_merchant_name || selectedExpense.merchant,
    category: selectedExpense?.expense_category_id,
    notes: selectedExpense.comment,
    receipts: selectedExpense.receipts,
    transactionTags: selectedExpense.tags,
  };

  return Object.entries(original)
    .filter(([key, value]) => {
      if (!Boolean(value)) {
        return false;
      }
      if (key === "receipts") {
        // When the field is not exist mean there is nothing updated for the recipt field
        return !formValue.receipt ? false : formValue.receipt.length !== value.length;
      }

      if (key === "transactionTags") {
        return checkTagsChange(value, formValue[key]);
      }
      return formValue[key] !== value;
    })
    .map(([key]) => key);
};

export const checkTagsChange = (initialTags, tags) => {
  if (tags?.length !== initialTags.length) {
    return true;
  }

  for (let i = 0; i < tags?.length; i++) {
    if (tags[i].value !== initialTags[i]?.value) {
      return true;
    }
  }

  return false;
};
