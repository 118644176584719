import React from "react";
import FormGenerator from "Views/Bills/V2/components/FormGenerator";
import { CustomFieldType } from "Views/Bills/V2/components/FormGenerator/type";
import { BillFieldGenerator, FieldGenerator } from "Views/Bills/V2/BillForm/type";

/**
 * Helper function is created to render multiple fields inside
 * 1 Component. The idea is to use your own FormGenerator
 * and separate the props for each field inside the Component.
 * @param fieldList Array of field list
 * @returns array of fields
 */
export const generatePaymentDetailFields = (fieldList: FieldGenerator[] = []) => {
  const fields = fieldList.map((field: FieldGenerator) => {
    const data: BillFieldGenerator = { ...field };
    const { name } = data;

    if ("fieldGroup" in data) {
      switch (name) {
        case "twoWayPayment":
          {
            // Hard coded for invoice amount by
            // transforming the field
            const [recipientGet, youPay, swiftPaymentChargeType] = data.fieldGroup;
            data.name = recipientGet.name;
            data.rules = recipientGet.rules;
            data.showError = false;

            // custom render
            data.render = ({ field }) => {
              // TO DO: is it better to not use render from the controller?
              const { name, onChange, value } = field;

              return (
                <FormGenerator
                  fieldType={CustomFieldType.TWO_WAY_PAYMENT}
                  recipientGetProps={{
                    ...recipientGet,
                    // get the controller from the FormController
                    name,
                    value,
                    onChange,
                  }}
                  youPayProps={{
                    ...youPay,
                    rules: youPay.rules,
                    controllerProps: { name: youPay.name, rules: youPay.rules },
                  }}
                  swiftPaymentProps={{
                    ...swiftPaymentChargeType,
                    controllerProps: {
                      name: swiftPaymentChargeType.name,
                      rules: swiftPaymentChargeType.rules,
                    },
                  }}
                />
              );
            };
          }
          break;
        case "paymentScheduleDate": {
          const [dateField, checkboxField = null] = data.fieldGroup;
          data.rules = dateField.rules;
          data.defaultValue = dateField.defaultValue;

          data.render = ({ field }) => {
            // TO DO: is it better to not use render from the controller?
            const { name, onChange, value } = field;

            let checkboxFieldProps = null;

            if (checkboxField) {
              const { fieldType, ...checkboxProps } = checkboxField.fieldProps;
              checkboxFieldProps = {
                ...checkboxProps,
                controllerProps: {
                  name: checkboxField.name,
                  rules: checkboxField.rules,
                  defaultValue: checkboxField.defaultValue,
                },
              };
            }

            return (
              <FormGenerator
                fieldType={CustomFieldType.PAYMENT_SCHEDULE}
                dateFieldProps={{
                  ...dateField,
                  // get the controller from the FormController
                  name,
                  value,
                  onChange,
                }}
                checkboxFieldProps={checkboxFieldProps}
              />
            );
          };
        }
      }
    }

    switch (name) {
      case "sendPaymentUpdatesTo": {
        const regex = data.rules.pattern;
        data.rules = {
          validate: (emails) => {
            const errorEmails = emails?.filter((email) => !regex.test(email));

            if (errorEmails?.length) {
              return `Invalid email address: ${errorEmails.join(", ")}`;
            }

            return true;
          },
        };
      }
    }

    return data;
  });
  return fields;
};
