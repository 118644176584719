import React from "react";

import AccountingSetup from "../Common/AccountingSetup";
import { netsuiteConfig } from "./config";

const NetsuiteSetup = () => {
  return (
    <AccountingSetup
      partner="netsuite"
      title="Oracle NetSuite Integration"
      config={netsuiteConfig}
      description="Continue to set up your NetSuite integration with Spenmo"
    />
  );
};

export default NetsuiteSetup;
