import { useDeleteTeamContext } from "Views/Team/Delete/context";
import { useTeamActionContext } from "Views/Team/List/Actions/context";

export const useDeleteTeamModalClose = () => {
  const { selectAction, selectTeam } = useTeamActionContext();
  const { resetDeleteTeamContext } = useDeleteTeamContext();

  const close = () => {
    selectAction(null);
    selectTeam(null);
    resetDeleteTeamContext();
  };
  return {
    close,
  };
};
