import React, { useState } from "react";
import { AxiosError } from "axios";
import { capitalizeFirstLetter } from "utility";
import useLoading from "utility/useLoading";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import { CreateCategory, UpdateCategory } from "Redux/DataCalls/Categories.api";
import { ICategoryDetailsProps } from "Views/Categories/types";
import CategoryForm from "./CategoryForm";

import "./styles.scss";

const CategoryDetails = ({
  isEdit,
  connectedToAccountingPartner,
  details,
  onCancel,
  onFinish,
}: ICategoryDetailsProps) => {
  const [createCategory, loadingCreateCategory] = useLoading(CreateCategory, null, null, false);
  const [updateCategory, loadingUpdateCategory] = useLoading(UpdateCategory, null, null, false);
  const [error, setError] = useState(null);

  const handleOnFinish = async (values) => {
    // handles the case when user clears the selection
    if (values?.accountingSystemCode === undefined) {
      values.accountingSystemCode = "";
    }
    try {
      if (isEdit) {
        await updateCategory(details.id, values);
        onFinish({
          message: `Category ${details.categoryName} succesfully edited.`,
          status: "edit",
        });
      } else {
        await createCategory(values);

        onFinish({ message: "New category successfully added.", status: "add" });
      }
    } catch (err) {
      if (err?.response?.status === HTTP_STATUS_CODE.REQUEST_CONFLICT) {
        setError({
          status: err?.response?.status,
          message: capitalizeFirstLetter(err?.response?.data?.error?.message),
        });
      } else {
        onError(err);
      }
    }
  };

  const onError = (err: AxiosError<{ error: { code: string; message: string } }>) => {
    onFinish({
      message: err?.response?.data?.error?.message || `Something went wrong`,
      type: "error",
    });
  };

  return (
    <div className="category-details">
      <h1>{isEdit ? "Edit category" : "Add a category"}</h1>
      <CategoryForm
        isEdit={isEdit}
        hasValidToken={connectedToAccountingPartner}
        details={details}
        onCancel={onCancel}
        onFinish={handleOnFinish}
        actionLoading={loadingCreateCategory || loadingUpdateCategory}
        error={error}
      />
    </div>
  );
};

export default CategoryDetails;
