export enum TAB_KEY {
  INVOICE = "invoice",
  CARD = "card",
  FUND = "fund",
  REIMBURSEMENT = "reimbursement",
}

export const APPROVAL_EVENT_SOURCE = {
  [TAB_KEY.INVOICE]: "Bills",
  [TAB_KEY.CARD]: "Cards",
  [TAB_KEY.REIMBURSEMENT]: "CR",
  [TAB_KEY.FUND]: "Fund Transfer",
};

export enum SortType {
  request_date = "requestDate",
  amount_sent = "amountSent",
  amount = "amount",
}

export enum SortOrder {
  ascending = "asc",
  descending = "desc",
}

export enum CARD_APPROVAL_LIMIT_TYPES {
  daily = "daily_limit",
  monthly = "monthly_limit",
  yearly = "yearly_limit",
  total = "total_limit",
  cardTransaction = "card_transaction_limit",
  unlimited = "none",
}

export enum REIMBURSEMENT_STATUS {
  PENDING = "pending",
  READY = "ready",
}

export enum WARNING_TEXT_PAY {
  PAY_SET_UP_PENDING = "You can immediately pay this Reimbursement request after the employee sets up their reimbursement payout method.",
  PAY_SET_UP_READY = "You can immediately pay this Reimbursement request after the employee sets up their reimbursement payout method.",
  SGD_PAY = "Payment is only available for SGD. Please contact your Spenmo Account Manager to pay this reimbursement.",
  CONFIRM_PAY_WARNING = "By confirming, you will be transferring the requested amount to the requester’s bank account or PayNow.",
  CONFIRM_PAY_BUTTON = "Confirm to Pay",
}

export const getCurrencyRuleWarningTextDetail = (
  dashboardCurrency: string,
): string => {
  return `Pay immediately is only available for ${dashboardCurrency}. This reimbursement needs to be settled by the payroll system.`;
};

export const getCurrencyRuleWarningTextList = (
  dashboardCurrency: string,
): string => {
  return `Only Available for ${dashboardCurrency}`;
};

export enum ACTION_TYPE_PAY {
  APPROVE_SETTLE = "approve-settle",
  SETTLE = "settle",
}

export interface BillFilter {
  sortBy: string;
  sortDirection: string;
  page: number;
  limit: number;
  type: string;
  search?: string;
  minSendAmount?: string;
  maxSendAmount?: string;
  minReceiveAmount?: string;
  maxReceiveAmount?: string;
  minInvoiceAmount?: string;
  maxInvoiceAmount?: string;
  startDate?: string;
  endDate?: string;
  pendingSettlement?: boolean;
  trxDateFrom?: string;
  trxDateTo?: string;
  minAmount?: string;
  maxAmount?: string;
  isPhysicalCard?: boolean;
  requester?: string;
  receiveCurrency?: string;
  invoiceCurrency?: string;
}

export enum FilterKey {
  Amount_Send = "amount-send",
  Amount_Receive = "amount-receive",
  Invoice_Amount = "invoice-amount",
  Request_Date = "request-date",
  Card_Type = "card-type",
  Amount = "amount",
  Transaction_Date = "transaction-date",
  Reimbursement_Status = "reimbursement-status",
  Requester = "requester",
}

export interface SortDropdownItem {
  label: string;
  value: SortType;
  order: SortOrder;
}

export interface URLParams {
  type: string;
  pendingSettlement?: boolean;
}

export interface IPopupState {
  visible: boolean;
  error: boolean;
  message: string;
}

export const initalPopupState: IPopupState = {
  visible: false,
  error: false,
  message: "",
};

export const ApprovalCenterConstant = {
  NotAvailable: "N.A.",
};

export const CANCEL_REQUEST_MESSAGE = "Cancel duplicated request";
