import React, { useState, useEffect } from "react";
import "./SelfApprovalSidePanel.scss";
import { ContextualMenuItem } from "Modules/DS/ContextualMenu/types";
import { GetOrgId } from "utility";
import { adminIcon } from "assets/img";
import SearchDropdown from "Modules/DS/SearchDropdown";
import Button from "Modules/button";
import { getAllEmployees } from "Redux/DataCalls/Users.api";
import { ValuesInterface, ResetDefault } from "../helpers";
import Loader from "Views/State/Loading/LoaderIcon";
import { TAB_KEY } from "../../Approval/ApprovalCenter/types";

let timer;

interface PolicyContentInterface {
  setShowConfirmSelfApprovalCloseModal: (val: boolean) => void;
  values: Array<ValuesInterface>;
  setValues: (item: Array<ValuesInterface>) => void;
  hasChangedSelfApprovalList: () => boolean;
  savePolicy: () => void;
  setResetDefault: (val: ResetDefault) => void;
  resetDefault: ResetDefault;
  isLoading: boolean;
  setisDiscardButtonClicked: (val: boolean) => void;
  activeKey: string;
}

const PolicyContent = ({
  setShowConfirmSelfApprovalCloseModal,
  values = [],
  setValues,
  hasChangedSelfApprovalList,
  savePolicy,
  setResetDefault,
  resetDefault,
  isLoading,
  setisDiscardButtonClicked,
  activeKey,
}: PolicyContentInterface): JSX.Element => {
  const ADMIN_OPTION: ContextualMenuItem = {
    value: `admin:${GetOrgId()}`,
    label: "Any Admin",
    role: "Admin",
  };

  const TEAM_MANAGER_OPTION: ContextualMenuItem = {
    value: `manager:`,
    label: "Budget Owner",
    role: "Manager",
  };

  const PAGE_LIMIT = 10;

  const [options, setOptions] = useState([]);
  const [search, setSearch] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [loading, setLoading] = useState(false);
  const [employeeCount, setEmployeeCount] = useState(0);
  const [page, setPage] = useState(1);

  const optionFilter = ({ value }) => !values.find((item) => `${item?.role}:${item.identifier}` === value);

  const setAllSearchOptions = () => {
    return [...selectedOptions, ...options.filter(optionFilter)].filter((item) => !item.isDeleted);
  };

  const fetchEmployeeList = async (listPage, append) => {
    const params = { search, page: listPage, limit: PAGE_LIMIT, roleId: undefined, excludeAdmin: 1 };
    setLoading(true);
    const employeeData = await getAllEmployees(params);
    setLoading(false);
    setEmployeeCount(employeeData?.payload?.pagination?.total);
    const employeeList =
      employeeData?.payload?.list
        ?.filter((item) => !item.isHidden)
        .map((item) => {
          return {
            value: `user:${item.employeeID}`,
            label: `${item.firstName} ${item.lastName}`,
            role: item.roleName,
          };
        })
        .sort((a, b) => (a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1)) || [];
    const managerOption =
      TEAM_MANAGER_OPTION.label.toLowerCase().indexOf(search?.toLowerCase()) > -1 ? [TEAM_MANAGER_OPTION] : [];
    setOptions((prevOptionList) => {
      return append ? [...prevOptionList, ...employeeList] : [...managerOption, ...employeeList];
    });
  };

  useEffect(() => {
    fetchEmployeeList(page, page > 1);
  }, [page]);

  useEffect(() => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      if (page === 1) fetchEmployeeList(1, false);
      else setPage(1);
    }, 1000);
  }, [search]);

  const setSelectedOption = () => {
    let options = [];
    values.forEach((item) => {
      if (item?.role === "manager") options.push(TEAM_MANAGER_OPTION);
      else if (item?.role)
        options.push({
          value: `user:${item?.identifier}`,
          label: item?.assignee?.name,
          error: item?.assignee?.error,
          role: item.role,
        });
    });
    return [...options.filter((item) => item.isDeleted), ...options.filter((item) => !item.isDeleted)];
  };

  let selectedOptions = setSelectedOption();

  const addMoreButton = (setIsEdit) => (
    <div className="self-approval__add-more-btn-container">
      <span className="self-approval__add-more-btn" onClick={() => setIsEdit(true)}>
        Add User(s)
      </span>
      <span className="self-approval__reset-btn" onClick={resetValues}>
        &#x2022; Reset to Default
      </span>
    </div>
  );

  const discardChangesPanel = () => {
    setShowConfirmSelfApprovalCloseModal(true);
    setisDiscardButtonClicked(true);
  };

  const resetValues = () => {
    setValues([]);
    setResetDefault({ ...resetDefault, [activeKey]: true });
  };

  const discardSaveButtons = hasChangedSelfApprovalList() ? (
    <div className="self-approval__discard-save-container">
      <Button action={discardChangesPanel} className={`self-approval__discard discard-${activeKey}`}>
        Discard Changes
      </Button>
      <Button action={savePolicy} className={`self-approval__save save-${activeKey}`}>
        Save Policy
      </Button>
    </div>
  ) : (
    <></>
  );

  return (
    <div className="policy-content__container">
      <h3 className="policy-content__heading">Choose who can self approve requests</h3>
      <p className="policy-content__para">
        Requests from any user not listed below will be sent to any admin by default when the requester and approver are
        the same user
      </p>
      <div className="policy-content__relative-container">
        <p className="policy-content__para">Allow self approval for:</p>
        <div className="flow-approver__select__option">
          <img className="flow-approver__warning__icon" src={adminIcon} alt="admin" />
          <div className="policy-content__default-admin">
            <p className="policy-content__admin-option">{ADMIN_OPTION.label}</p>
            <span className="policy-content__option-label">Default</span>
          </div>
        </div>
        {resetDefault[TAB_KEY.INVOICE] || resetDefault[TAB_KEY.REIMBURSEMENT] ? discardSaveButtons : <></>}
        {selectedOptions.length === 0 && hasChangedSelfApprovalList() ? discardSaveButtons : <></>}
        {isLoading ? <Loader /> : null}
        {!isLoading && (showDropdown || selectedOptions.length > 0) ? (
          <SearchDropdown
            className="self-approval__selected-option"
            checkBoxStyle="self-approval__checkbox-right"
            placeholder="Search by name"
            values={selectedOptions}
            options={setAllSearchOptions()}
            addMoreLabel="Add User(s)"
            onChange={(val) => {
              setValues(
                val.map((item) => {
                  const [role, identifier] = item?.value?.split(":") || [];
                  return {
                    role,
                    identifier,
                    itemKey: `${activeKey}_${item?.label}`,
                    assignee: {
                      id: role !== "admin" ? identifier : null,
                      name: item?.label,
                      role: item?.role,
                    },
                  };
                })
              );
            }}
            onSearch={(val) => setSearch(val)}
            onScrollBottom={() =>
              setPage((currentPage) => (currentPage * PAGE_LIMIT < employeeCount ? currentPage + 1 : currentPage))
            }
            isLoading={loading}
            defaultOpen
            renderOption={(option: ContextualMenuItem) => {
              const { label } = option;
              const name = label?.split(" ");
              const firstName = name[0];
              const lastName = name[1];
              return (
                <div className="flow-approver__select__option">
                  {option?.label === "Budget Owner" ? (
                    <img className="flow-approver__warning__icon" src={adminIcon} alt="admin" />
                  ) : (
                    <div className="flow-approver__option__icon">
                      {firstName?.[0]?.toUpperCase()}
                      {lastName?.[0]?.toUpperCase()}
                    </div>
                  )}
                  <div
                    className={`policy-content__admin-option flow-approver__option__label ${
                      option.isDeleted ? "flow-approver__option__label__inactive" : ""
                    }`}
                  >
                    {option?.label} {option.isDeleted ? "(Inactive)" : ""}
                    {option?.label !== "Budget Owner" && (
                      <p>{option?.role?.charAt(0).toUpperCase() + option?.role?.slice(1)}</p>
                    )}
                  </div>
                </div>
              );
            }}
            setShowDropdown={setShowDropdown}
            multiple
            addMoreButton={addMoreButton}
            discardSaveButtons={discardSaveButtons}
            optionLabelOR={<></>}
          />
        ) : (
          <div className="policy-content__add-btn">
            <Button action={() => setShowDropdown(true)}>Add User(s)</Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default PolicyContent;
