import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button } from "antd";
import classNames from "classnames";
import Icon from "../icons";
import { greyCloseIcon, closeIconWhite } from "../../assets/img/index";
import "./ActionButtons.scss";

const ActionButtons = ({ buttons, isBulk, isLarge, isSelected }) => {
  const [confirm, setConfirm] = useState(null);

  let classModifiers = "";

  if (isBulk) {
    classModifiers += "action-buttons--bulk ";
  }
  if (isLarge) {
    classModifiers += "action-buttons--large ";
  }

  const handleButtonClick = (btn) => {
    if (btn.useConfirm) {
      setConfirm(btn);
    } else {
      btn.action();
    }
  };

  const handleConfirmClick = (e) => {
    e.stopPropagation();
    confirm.action();
    setConfirm(null);
  };

  const handleConfirmClose = (e) => {
    e.stopPropagation();
    setConfirm(null);
  };

  const generateButtons = (buttons, confirm) => {
    return (
      <div className={`action-buttons ${classModifiers}`}>
        {confirm ? (
          <>
            <Button className={`action-buttons__button--${confirm.type}`} onClick={handleConfirmClick}>
              Confirm to {confirm.label}
            </Button>
            <Icon
              className={"close-button"}
              src={isLarge ? closeIconWhite : greyCloseIcon}
              size="2"
              action={handleConfirmClose}
            />
          </>
        ) : (
          buttons.map((btn) => {
            const buttonClassNames = classNames({
              [`action-buttons__button--${btn.type}`]: true,
              [btn.className]: btn.className,
            });
            return (
              <Button
                className={buttonClassNames}
                onClick={(e) => {
                  e.stopPropagation();
                  handleButtonClick(btn);
                }}
                key={btn.label}
              >
                {btn.label}
              </Button>
            );
          })
        )}
      </div>
    );
  };

  if (!isBulk && isSelected) {
    return <div>See top for action</div>;
  }

  return generateButtons(buttons, confirm);
};

ActionButtons.propTypes = {
  buttons: PropTypes.array,
  className: PropTypes.string,
  isSelected: PropTypes.bool,
  isBulk: PropTypes.bool,
  isLarge: PropTypes.bool,
};

export default ActionButtons;
