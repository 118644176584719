import React, { ReactNode } from "react";
import classNames from "classnames";

import Icon from "Modules/icons";

import { dataTableNoRecordFound } from "assets/img";
import styles from "./NoRecord.module.scss";

interface NoRecordProps {
  emptyMessage: string | ReactNode;
  className?: string;
}

const NoRecord: React.FC<NoRecordProps> = ({ emptyMessage, className }) => {
  return (
    <div className={classNames(styles.container, { [className]: Boolean(className) })}>
      <Icon src={dataTableNoRecordFound} alt={"no result found"} className={styles["container__no-result-icon"]} />
      <div className={styles["container__no-result-supporting-info"]}>{emptyMessage}</div>
    </div>
  );
};

export default NoRecord;
