import React, { HTMLAttributes } from "react";
import cn from "classnames";

import { addLine, addFill } from "assets/v1.1/icons/Core/Add";
import { openInNew } from "assets/v1.1/icons/Core/OpenInNew";
import { editOutline } from "assets/v1.1/icons/Core/Edit";
import { trash } from "assets/v1.1/icons/Core/Trash";
import { receiptAdded, fillReceiptAdded } from "assets/v1.1/icons/Core/ReceiptAdded";
import { fillWarning } from "assets/v1.1/icons/Core/Warning";
import { fillCheck, outlineCheck } from "assets/v1.1/icons/Core/Check";
import { close } from "assets/v1.1/icons/Core/Close";
import { info, fillInfo } from "assets/v1.1/icons/Core/Info";
import { chevronLeft } from "assets/v1.1/icons/Core/ChevronLeft";
import { suggestion } from "assets/v1.1/icons/Flat";
import { help } from "assets/v1.1/icons/Core/Help";
import { verticalMore } from "assets/v1.1/icons/Core/VerticalMore";
import * as Icons from "assets/v1.1/icons/Core";

import styles from "./Icon.module.scss";
import { icBusiness } from "assets/v1.1/icons/Core/IcBusiness";
import { icCheck } from "assets/v1.1/icons/Core/IcCheck";
import { icPerson } from "assets/v1.1/icons/Core/IcPerson";

export enum IconList {
  add = "add",
  edit = "edit",
  close = "close",
  check = "check",
  trash = "trash",
  info = "info",
  help = "help",
  chevronLeft = "chevronLeft",
  receiptAdded = "receiptAdded",
  fillReceiptAdded = "fillReceiptAdded",
  fillAdd = "fillAdd",
  fillCheck = "fillCheck",
  fillWarning = "fillWarning",
  fillInfo = "fillInfo",
  file = "file",
  external = "external",
  openInNew = "openInNew",
  uploadSuccess = "uploadSuccess",
  suggestion = "suggestion",
  verticalMore = "verticalMore",
  icBusiness = "icBusiness",
  icCheck = "icCheck",
  icPerson = "icPerson",
}

type IconListType = Record<
  Partial<keyof typeof IconList>,
  {
    svg: {
      width: string | number;
      height: string | number;
      viewBox: string;
      fill?: string;
      stroke?: string;
    };
    use: {
      xlinkHref: string;
    };
  }
>;

interface IconProps extends HTMLAttributes<HTMLSpanElement> {
  icon: keyof typeof IconList;
  size?: number | string;
  svgAttr?: object;
  useAttr?: object;
}

const Icon: React.FC<IconProps> = (props) => {
  const { className, icon, size, svgAttr, useAttr, ...rest } = props;

  const defaultSVGProps = {
    width: size || "16",
    height: size || "16",
    viewBox: "0 0 16 16",
    fill: "none",
  };

  const icons: IconListType = {
    add: {
      svg: defaultSVGProps,
      use: {
        xlinkHref: addLine,
      },
    },
    edit: {
      svg: defaultSVGProps,
      use: {
        xlinkHref: editOutline,
      },
    },
    close: {
      svg: defaultSVGProps,
      use: {
        xlinkHref: close,
      },
    },
    check: {
      svg: defaultSVGProps,
      use: {
        xlinkHref: outlineCheck,
      },
    },
    trash: {
      svg: defaultSVGProps,
      use: {
        xlinkHref: trash,
      },
    },
    info: {
      svg: defaultSVGProps,
      use: {
        xlinkHref: info,
      },
    },
    openInNew: {
      svg: defaultSVGProps,
      use: {
        xlinkHref: openInNew,
      },
    },
    help: {
      svg: defaultSVGProps,
      use: {
        xlinkHref: help,
      },
    },
    chevronLeft: {
      svg: defaultSVGProps,
      use: {
        xlinkHref: chevronLeft,
      },
    },
    receiptAdded: {
      svg: defaultSVGProps,
      use: {
        xlinkHref: receiptAdded,
      },
    },
    fillAdd: {
      svg: defaultSVGProps,
      use: {
        xlinkHref: addFill,
      },
    },
    fillReceiptAdded: {
      svg: defaultSVGProps,
      use: {
        xlinkHref: fillReceiptAdded,
      },
    },
    fillCheck: {
      svg: defaultSVGProps,
      use: {
        xlinkHref: fillCheck,
      },
    },
    fillWarning: {
      svg: defaultSVGProps,
      use: {
        xlinkHref: fillWarning,
      },
    },
    fillInfo: {
      svg: defaultSVGProps,
      use: {
        xlinkHref: fillInfo,
      },
    },
    file: {
      svg: defaultSVGProps,
      use: {
        xlinkHref: Icons.Core.File.Line,
      },
    },
    uploadSuccess: {
      svg: defaultSVGProps,
      use: {
        xlinkHref: Icons.Core.UploadSuccess,
      },
    },
    external: {
      svg: defaultSVGProps,
      use: {
        xlinkHref: Icons.Core.External.Line.externalLine,
      },
    },
    suggestion: {
      svg: defaultSVGProps,
      use: {
        xlinkHref: suggestion,
      },
    },
    verticalMore: {
      svg: defaultSVGProps,
      use: {
        xlinkHref: verticalMore,
      },
    },
    icBusiness: {
      svg: defaultSVGProps,
      use: {
        xlinkHref: icBusiness,
      },
    },
    icCheck: {
      svg: defaultSVGProps,
      use: {
        xlinkHref: icCheck,
      },
    },
    icPerson: {
      svg: defaultSVGProps,
      use: {
        xlinkHref: icPerson,
      },
    },
  };

  return (
    <span className={cn(styles.icon, className)} {...rest}>
      <svg {...icons[icon]?.svg} {...svgAttr}>
        <use {...icons[icon]?.use} {...useAttr} />
      </svg>
    </span>
  );
};

export default Icon;
