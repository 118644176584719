import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { Typography, Banner, InfoFilled } from "@spenmo/splice";
import { TAX_STATUS, TAX_MAPPING_STATUS, TAX_STATE_STATUS } from "constants/Tax.constant";
import SideSection from "Modules/sideSection";
import DataTable, { Table, DATA_TABLE_SIZE_TYPES, DATA_TABLE_PADDING } from "Modules/DS/DataTable";
import { accountingPartnerAuth } from "Redux/Actions/accountingPartnerAuth";

import { getAllTaxesFunc } from "Redux/Actions/taxAction";
import { trackEvent } from "utility/analytics";
import usePermissionCheck from "Permission/usePermissionCheck";
import { CASBIN_PAGES } from "Permission/Pages";
import { TAX_PAGE } from "Permission/Actions";
import LoadingComponent from "Views/State/Loading/LoaderIcon";
import { useToasterContext, TOASTER_STATUS_TYPE } from "Views/Settings/context/ToasterContext";
import ErrorComponent from "Views/State/Error";
import { BodySkeleton } from "Views/Settings/components";

import TaxDetails from "../Details/index";
import AddTax from "../Add";
import { ITax } from "../types";
import { fetchColumnData } from "./ColumnData";
import { TaxContext } from "../context/TaxContext";
import { generateSentence } from "../Import/components/TaxCountBanner";
import { taxesEvent } from "../Analytic";

const PER_PAGE_LIMIT = 10;

const TaxesListing = ({ newSettingPage }: { newSettingPage: boolean }) => {
  const location = useLocation();
  const permissionParam = [
    {
      object: CASBIN_PAGES.TAX_PAGE,
      action: TAX_PAGE.ADD_NEW_TAX_BUTTON,
    },
    { object: CASBIN_PAGES.TAX_PAGE, action: TAX_PAGE.EDIT_TAX_BUTTON },
  ];
  const [IS_ALLOWED_TAX_CREATE, IS_ALLOWED_TAX_EDIT]: any = usePermissionCheck(permissionParam) || [];
  const dispatch = useDispatch();
  const [showSideSection, setShowSideSection] = useState(false);
  const [showImportModal, setShowImportModal] = useState(new URLSearchParams(location.search).get("import") === "true");
  const [highlightInconsistentTax, setHighlightInconsistentTax] = useState(false);
  const [details, setDetails] = useState([]);
  const [view, setView] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const { setToaster } = useToasterContext();

  const { taxReducer, accountingPartner } = useSelector((state: any) => ({
    taxReducer: state.taxReducer || {},
    accountingPartner: state.accountingPartnerAuthReducer.data,
  }));

  const { loading, error, data } = taxReducer;

  const onImport = () => {
    trackEvent("Click import from Xero on missing import banner");
    setShowImportModal(true);
  };

  const taxes = data?.payload?.data || [];

  const totalCount = data?.payload?.count || 0;
  const hasValidToken = accountingPartner?.payload?.has_valid_token;
  const accountingPartnerName = accountingPartner?.payload?.partnerName;
  const showInconsistencyAlert =
    data?.payload?.count_inconsistent !== 0 && data?.payload?.state !== TAX_STATE_STATUS.NOT_CONNECTED;
  const showReImportAlert = data?.payload?.state === TAX_STATE_STATUS.INCOMPLETE;
  const { linking, word } = generateSentence(data?.payload?.count_inconsistent);

  useEffect(() => {
    getData();
    dispatch(accountingPartnerAuth());
  }, [dispatch]);

  const getData = (page = 0, importedTax = []) => {
    setCurrentPage(page);
    dispatch(getAllTaxesFunc({ pg: page, limit: PER_PAGE_LIMIT, status: TAX_STATUS.ALL, importedTax }));
  };

  const handleAction = (record, activeView) => {
    setDetails(record);
    setView(activeView);
    setShowSideSection(true);
    trackEvent(taxesEvent.TAX_PAGE_VIEWED, { accounting_settings_event_source: "Tax" });
  };

  const onFinish = (message) => {
    setToaster({
      show: true,
      message,
      type: TOASTER_STATUS_TYPE.SUCCESS,
    });
    setShowSideSection(false);
    trackEvent(view === "create" ? taxesEvent.ADD_TAXES : taxesEvent.EDIT_TAXES, {
      accounting_settings_event_source: "Tax",
    });
    getData();
  };

  const tableProps = {
    total: totalCount,
    loading,
    pageNum: currentPage,
    pageSize: PER_PAGE_LIMIT,
    dataSource: taxes,
    onPageChange: (page) => getData(page - 1),
    emptyState: <p>Taxes not found</p>,
    rowClassName: (record: ITax) => {
      if (record.alert) {
        return `alert-${record.alert.type}`;
      }

      if (record.mapping_status === TAX_MAPPING_STATUS.INCONSISTENT && highlightInconsistentTax) {
        return "inconsistent-tax";
      }

      return "";
    },
    rowAction: (record: ITax) => {
      if (record.alert) {
        return;
      }
      if (IS_ALLOWED_TAX_EDIT) {
        handleAction(record, "edit");
      }
    },
  };

  if (loading) {
    return newSettingPage ? <BodySkeleton paddingLess /> : <LoadingComponent />;
  }

  if (error) {
    return <ErrorComponent />;
  }

  const contextValue = {
    getData,
    setToaster,
  };

  return (
    <TaxContext.Provider value={contextValue}>
      <DataTable
        className="tax-listing"
        padding={newSettingPage ? DATA_TABLE_PADDING.NONE : DATA_TABLE_PADDING.GRID}
        size={DATA_TABLE_SIZE_TYPES.LARGE}
      >
        <div className="header">
          <Typography size="m" variant="headline-content" tag="h3">
            Tax
          </Typography>
          {IS_ALLOWED_TAX_CREATE && (
            <AddTax
              onAddNewTax={() => handleAction([], "create")}
              showImportModal={showImportModal}
              setShowImportModal={setShowImportModal}
              hasValidToken={hasValidToken}
              partnerName={accountingPartnerName}
            />
          )}
        </div>
        <Typography tag="p" size="m" variant="body-content" className="caption">
          Each transaction may be assigned a Tax rate for better visibility
        </Typography>
        <div className={showImportModal || showReImportAlert ? "banner-wrapper" : ""}>
          {showReImportAlert && (
            <Banner
              variant="info"
              icon={InfoFilled}
              title=""
              description={
                <p className="banner-message">
                  There are some taxes in {accountingPartnerName} which do not currently exist in Spenmo.{" "}
                  <span className="highlight pointer" onClick={onImport}>
                    Import from {accountingPartnerName}
                  </span>{" "}
                </p>
              }
            />
          )}
          {showInconsistencyAlert && (
            <Banner
              variant="danger"
              icon={InfoFilled}
              title=""
              description={
                <p className="banner-message">
                  There {linking} <span className="highlight">{data?.payload?.count_inconsistent}</span> {word} with
                  different rates in Spenmo and {accountingPartnerName}.{" "}
                  <span
                    className="highlight pointer"
                    onClick={() => {
                      setHighlightInconsistentTax(!highlightInconsistentTax);
                      trackEvent(
                        `Click  ${!highlightInconsistentTax ? "Show" : "Hide"} from Xero on inconsistent tax banner`
                      );
                    }}
                  >
                    {!highlightInconsistentTax ? "Show" : "Hide"}
                  </span>
                </p>
              }
            />
          )}
        </div>
        <Table<ITax> {...tableProps}>{fetchColumnData(hasValidToken, accountingPartnerName)}</Table>

        {showSideSection && (
          <SideSection visible={showSideSection} onClose={() => setShowSideSection(false)}>
            <TaxDetails
              isEdit={view === "edit"}
              details={details}
              onFinish={onFinish}
              loading={loading}
              connected={hasValidToken}
              partnerName={accountingPartnerName}
            />
          </SideSection>
        )}
      </DataTable>
    </TaxContext.Provider>
  );
};

export default TaxesListing;
