export interface IReKYBModalContent {
  title: string;
  description: string;
  subContent: string;
}

export enum REKYB_UPDATE_MODAL_TYPE {
  BEFORE_EXPIRY = "UpdateKYBBeforeExpiry",
  AFTER_EXPIRY = "UpdateKYBAfterExpiry",
  NON_ADMIN = "UpdateKYBForNonAdmin",
}

const UpdateKYBBeforeExpiry = {
  title: "Update Company Data",
  description:
    "Your company data is incomplete which puts you at risk of losing access to Spenmo. To avoid losing your access, please",
  subContent: "update your company data by",
};

const UpdateKYBAfterExpiry = {
  title: "Update Company Data is Required",
  description:
    "Your company data is incomplete which risks your company’s access to Spenmo. To avoid losing access to Spenmo, please",
  subContent: "update your company data now",
};

const UpdateKYBForNonAdmin = {
  title: "Update Company Data is Required",
  description:
    "Sorry, at the moment, you won’t be able to use the Spenmo dashboard due to incomplete company data. To regain access,",
  subContent: "please ask your company's Admin to update it.",
};

export const ReKYBUpdateModalDetails: {
  [REKYB_UPDATE_MODAL_TYPE.BEFORE_EXPIRY]: IReKYBModalContent;
  [REKYB_UPDATE_MODAL_TYPE.AFTER_EXPIRY]: IReKYBModalContent;
  [REKYB_UPDATE_MODAL_TYPE.NON_ADMIN]: IReKYBModalContent;
} = {
  [REKYB_UPDATE_MODAL_TYPE.BEFORE_EXPIRY]: UpdateKYBBeforeExpiry,
  [REKYB_UPDATE_MODAL_TYPE.AFTER_EXPIRY]: UpdateKYBAfterExpiry,
  [REKYB_UPDATE_MODAL_TYPE.NON_ADMIN]: UpdateKYBForNonAdmin,
};
