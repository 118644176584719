import classNames from "classnames";
import React from "react";
import Icon from "Modules/icons";

import { close } from "assets/v1.1/icons/Line";
import styles from "./styles.module.scss";

export type BannerType = "info" | "failed" | "attention" | "neutral" | "success";

export interface IBannerProps {
  message: string | React.ReactNode;
  type: BannerType;
  icon?: React.ReactNode;
  onClose?: () => void;
  className?: string;
}

const defaultIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    data-testid="default-banner-icon"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12ZM11 9C11 9.55228 11.4477 10 12 10C12.5523 10 13 9.55228 13 9C13 8.44772 12.5523 8 12 8C11.4477 8 11 8.44772 11 9ZM11 12C11 11.4477 11.4477 11 12 11C12.5523 11 13 11.4477 13 12V16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16V12Z"
    />
  </svg>
);

const Banner = ({ message, type, icon = defaultIcon, onClose, className }: IBannerProps) => {
  return (
    <div
      data-testid="banner-container"
      className={classNames(styles[`design-system-banner`], styles[`design-system-banner__${type}`], className)}
    >
      <section>{icon}</section>
      <section className={styles["design-system-banner__message"]}>{message}</section>
      {onClose && <Icon action={onClose} src={close} alt="close icon" className={styles.close} />}
    </div>
  );
};
export default Banner;
