import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Select } from "antd";
import { teamEmpFunc } from "Redux/Actions";
import { SelectMembersList } from "Modules/SelectMembers/SelectMembersList";
import "./SelectMembersFromTeam.scss";

const SelectMembersFromTeam = ({
  teamSelectTitle,
  teamSelectPlaceholder,
  memberListTitle,
  onChange,
  noDefaultTeam = false,
  allowAllTeams = false,
  initialSelectedTeam = null,
  ...restOftheProps
}) => {
  const dispatch = useDispatch();
  const { Option } = Select;
  const teamList = useSelector((state) => state.teamList?.data?.payload?.teams);
  const teamMembers = useSelector((state) => state.empReducer?.data?.payload?.team_members);
  const loading = useSelector((state) => state.empReducer?.loading);
  const selectedTeamId = useSelector((state) => state.teamReducer?.selectedTeamId);
  const allowedTeamIds = useSelector((state) => state.userInfoReducer?.manageTeams);
  const isAdmin = useSelector((state) => state.userInfoReducer?.role === "Admin");
  const allowedTeams =
    isAdmin || allowAllTeams ? teamList : teamList?.filter?.((team) => allowedTeamIds?.includes?.(team?.id));

  const getSelectedTeam = () => {
    if (noDefaultTeam) {
      return initialSelectedTeam ? initialSelectedTeam : allowedTeams?.[0]?.id;
    }
    return selectedTeamId;
  };

  const [selectedTeam, setSelectedTeam] = useState(getSelectedTeam());

  useEffect(() => {
    selectedTeam && dispatch(teamEmpFunc(selectedTeam));
  }, [dispatch, selectedTeam]);

  const handleChange = (dataInput) => {
    let dataToSend = [];
    if (dataInput?.length) {
      dataToSend = [{ ...(dataInput[0] || []), team_id: selectedTeam }];
    }
    onChange(dataToSend);
  };

  const memberListprops = {
    ...restOftheProps,
    list: teamMembers,
    loading: loading,
    title: memberListTitle,
    onChange: handleChange,
  };

  return (
    <div>
      <div className={"select-members-from-team"}>
        <h4 className={"title"}>{teamSelectTitle}</h4>
        <div className={"select-members-from-team__dropdown"}>
          <Select
            style={{ width: "100%" }}
            showSearch
            defaultValue={getSelectedTeam()}
            placeholder={teamSelectPlaceholder}
            onSelect={(id) => setSelectedTeam(id)}
            filterOption={(input, option) => option?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0}
            getPopupContainer={(triggerNode) => triggerNode?.parentElement}
          >
            {allowedTeams?.map((data) => (
              <Option key={data.id} value={data.id} id={data.id}>
                {data.team_name}
              </Option>
            ))}
          </Select>
        </div>
      </div>
      <SelectMembersList {...memberListprops} />
    </div>
  );
};

export default SelectMembersFromTeam;
