export const Amazon = require("./amazon.svg");
export const Acra = require("./acra.svg");
export const Adobe = require("./adobe.svg");
export const Atlassian = require("./atlassian.svg");
export const ClickUp = require("./clickup.svg");
export const Comfort = require("./comfort.svg");
export const Deliveroo = require("./deliveroo.svg");
export const Facebook = require("./facebook.svg");
export const Figma = require("./figma.svg");
export const FoodPanda = require("./foodpanda.svg");
export const GoGox = require("./gogox.svg");
export const Gojek = require("./gojek.svg");
export const Google = require("./google.svg");
export const Grab = require("./grab.svg");
export const Hubspot = require("./hubspot.svg");
export const Lazada = require("./lazada.svg");
export const LinkedIn = require("./linkedIn.svg");
export const Mailchimp = require("./mailchimp.svg");
export const Microsoft = require("./microsoft.svg");
export const Netflix = require("./netflix.svg");
export const Pickupp = require("./pickupp.svg");
export const Shippit = require("./shippit.svg");
export const Shopee = require("./shopee.svg");
export const Shopify = require("./shopify.svg");
export const Spotify = require("./spotify.svg");
export const StockxX = require("./stockxX.svg");
export const TechInasia = require("./techInasia.svg");
export const Tiktok = require("./tiktok.svg");
export const Twilio = require("./twilio.svg");
export const Typeform = require("./typeform.svg");
export const Uber = require("./uber.svg");
export const Udemy = require("./udemy.svg");
export const Upwork = require("./upwork.svg");
export const Xero = require("./xero.svg");
export const Zoom = require("./zoom.svg");
