import React from "react";
import LoadingComponent from "Views/State/Loading/LoaderIcon";
import { intlDateTimeFormat } from "utility";
import { clock } from "assets/v1.1/icons/Line";

import "./styles.scss";

const ImportTaxInformation = ({ importTaxEvent, partnerName, partnerLogoUrl }) => {
  const { data, loading } = importTaxEvent;
  const createdAt = data?.created_at;
  const generateImportDate = () => (createdAt ? intlDateTimeFormat(new Date(createdAt)) : "-");

  return (
    <div className="import-tax-information">
      <section className="import-tax-information__content">
        <h4 className="title">Import Tax</h4>
        <p className="paragraph">
          You will import all taxes from {partnerName}. If there is a tax that has the same name as an existing tax in
          Spenmo, the tax will not be imported again.
        </p>
      </section>

      <section className="import-tax-information__content">
        <h4 className="title">Import Details</h4>
        <div className="import-details">
          <div className="import-details__accounting-software">
            <img alt={"xeroLogo"} src={partnerLogoUrl} className="import-details__accounting_software" />
            <p className="import-details__header">Accounting Software</p>
            <p className="import-details__headline">{partnerName}</p>
          </div>
          <div className="import-details__import-information">
            <img alt={"xeroLogo"} src={clock} className="import-details__logo" />
            <p className="import-details__header">Last Imported & Mapped</p>
            {loading ? (
              <LoadingComponent className={"import-details__loading"} />
            ) : (
              <p className="import-details__headline">{generateImportDate()}</p>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default ImportTaxInformation;
