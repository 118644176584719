import React, { useState, useEffect } from "react";
import { Form } from "antd";
import { useSelector } from "react-redux";

import { RootState } from "Redux/ConfigureStore";

import CountrySelect from "Modules/CountrySelect";

import { ICountry, ICountrySelectProps, IFilteredCountry } from "Views/KYX/@types";

export const KYXCountrySelect = ({ formProps, selectProps }: ICountrySelectProps): React.ReactElement => {
  const { isnationality = false, placeholder, onChange, disabled, ...restProps } = selectProps || {};

  const [value, setValue] = useState<string>("");
  const [filteredList, setFilteredList] = useState<IFilteredCountry[]>([]);

  const countryList: ICountry[] = useSelector(
    (state: RootState) => state.countryListReducer?.data?.payload?.list || []
  );

  useEffect(() => {
    if (countryList && countryList?.length > 0) {
      const list = countryList.map((country: ICountry) => ({
        countryCode: country.code,
        countryId: parseInt(country.id),
        countryName: isnationality ? country?.nationality_name : country?.name,
      }));
      setFilteredList(list);
    }
  }, [isnationality, countryList]);

  const handleCountryChange = (val: string): void => {
    setValue(val);
    onChange?.(val);
  };

  return (
    <Form.Item {...formProps}>
      <CountrySelect
        value={value}
        disabled={disabled}
        placeholder={placeholder}
        loading={!countryList}
        countryList={filteredList}
        onChange={handleCountryChange}
        {...restProps}
      />
    </Form.Item>
  );
};
