import React, { useState } from "react";

import { useHistory } from "react-router-dom";
import { Modal, Typography } from "@spenmo/splice";

import styles from "./index.module.scss";
import { setupPaymentRun } from "assets/img";

const SetUpPaymentRun = () => {
  const [showModal, setShowModal] = useState(false);
  const history = useHistory();
  return (
    <>
      <Modal
        size="m"
        title="Set up payment runs"
        showModal={showModal}
        primaryActionButton={{
          title: "Set up",
          action: () => history.push("/settings/payment-run?edit=true"),
        }}
        secondaryActionButton={{
          title: "Close",
          action: () => setShowModal(false),
        }}
      >
        <div className={styles.body}>
          <img className={styles.images} src={setupPaymentRun} alt="Setup payment run?" width={368} height={219} />
          <Typography className={styles.description} variant="body-content" size="s">
            Organise your ad-hoc submitted bills by scheduled cycles (weekly, fortnightly or monthly) and settle them in
            batches
          </Typography>
        </div>
      </Modal>
      <Typography onClick={() => setShowModal(true)} className={styles.container} variant="body-content" size="s">
        Set up Payment Run
      </Typography>
    </>
  );
};
export default SetUpPaymentRun;
