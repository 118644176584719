import React, { useContext } from "react";
import PropTypes from "prop-types";
import { MyCardsContext } from "Views/Home/Components/MyCards/MyCards.context";
import CompactCard from "Modules/CompactCard/CompactCard";
import { getCardType } from "../Utilities";
import { getMerchantNames } from "utility";
import { CARD_STATUS, CARD_TYPE } from "Views/Card/types";

const UserCard = ({ cardDetails = {} }) => {
  const { onUserCardClick } = useContext(MyCardsContext);

  const { name, id, cardholder_name, card_last_four, team, is_physical, color, card_status, spending_limits } =
    cardDetails;
  const merchantNames = getMerchantNames(spending_limits?.merchant_limits) || [];

  const cardType = getCardType(is_physical);
  const notActivatedCard = cardType === CARD_TYPE.PHYSICAL && card_status === CARD_STATUS.NOT_ACTIVATED;

  return (
    <CompactCard
      key={id}
      action={() => onUserCardClick(cardDetails)}
      cardName={name || cardholder_name}
      cardLastFour={notActivatedCard ? "XXXX" : card_last_four}
      teamName={team?.team_name}
      cardType={cardType}
      cardColor={color}
      merchantNames={merchantNames}
      cardStatus={card_status}
    />
  );
};

UserCard.propTypes = {
  cardDetails: PropTypes.any,
};

export default UserCard;
