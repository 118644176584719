import useCheckFeatureStatus from "customHooks/featureCheck";
import { SPLIT_NAMES, SPLIT_TREATMENT_TYPES } from "Redux/splitio/constants";

export const usePermission = () => {
  const treatment = useCheckFeatureStatus(SPLIT_NAMES.subscriptionManagement, true);
  const loadingTreatment = treatment === SPLIT_TREATMENT_TYPES.CONTROL;

  return {
    loading: loadingTreatment,
    allowedToOpenPage: treatment === SPLIT_TREATMENT_TYPES.ON,
  };
};
