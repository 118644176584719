import { FormInstance } from "antd/lib/form";
import { createContext, useContext } from "react";

interface IFormContext {
  form: FormInstance;
  setFormValue: (value: any) => void;
  formValue: any;
  tags: any;
  taxes: any;
  accountingSetting: any;
  selectedExpense: any;
  setSelectedExpense: (value: any) => void;
  expenseNotFound: boolean;
  setExpenseNotFound: (value: any) => void;
  connectedUsingExpenseIntegration: boolean;
  connected: boolean;
}

export const initialForm = {
  form: null,
  setFormValue: () => {},
  formValue: {},
  tags: [],
  taxes: [],
  accountingSetting: [],
  selectedExpense: {},
  setSelectedExpense: () => {},
  expenseNotFound: false,
  setExpenseNotFound: () => {},
  connectedUsingExpenseIntegration: false,
  connected: false,
};

const FormContext = createContext<IFormContext>(initialForm);

export const useFormContext: () => IFormContext = () => useContext(FormContext);

export default FormContext;
