import * as React from "react";
import { useContext } from "react";

import KycModal from "Views/Employee/Kyc/Modal";
import useDeletionModal from "Views/Employee/Delete/Modal";
import UpdateRoleModal from "Views/Employee/Update/Role/Modal";
import RoleExplanationModal from "Views/Employee/RoleExplanationModal";

import { EmployeeModalTypes, IEmployeeModal } from "Views/Employee/types";
import { EmployeesModalContext, IEmployeesModalContext } from "Views/Employee/context";
import { AdminConfirmation } from "Views/Employee/Update/Role/Modal/AdminConfirmation";

const useModalType = (): IEmployeeModal => {
  const { resetModal }: IEmployeesModalContext = useContext(EmployeesModalContext);

  const deletionModal = useDeletionModal();

  return {
    [EmployeeModalTypes.NONE]: {
      close: () => null,
      className: null,
      children: null,
    },
    [EmployeeModalTypes.KYC]: {
      close: resetModal,
      className: "employees__modal--kyc-info",
      children: <KycModal />,
    },
    [EmployeeModalTypes.ROLE_EXPLANATION]: {
      close: resetModal,
      children: <RoleExplanationModal />,
    },
    [EmployeeModalTypes.ROLE]: {
      close: null,
      className: "employees__modal--role-update",
      children: <UpdateRoleModal />,
    },
    [EmployeeModalTypes.SWITCH_ADMIN_INFORMATION]: {
      close: null,
      className: "",
      children: <AdminConfirmation title="You're about to switch a user to Admin role" usage="admin" />,
    },
    [EmployeeModalTypes.DELETE]: {
      close: deletionModal.close,
      className: deletionModal.className,
      children: deletionModal.children,
    },
  };
};

export default useModalType;
