import APIclient from "API/Client";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { GetOrgId } from "utility";
import { urlParamsBuilder } from "utility/APIWrapper";

export const uploadSingle = async (file, onUploadProgress) => {
  const formData = new FormData();
  formData.append("file", file);

  return APIclient.postData(`/ms/spm-disbursement/v1/ocr/extract`, formData, false, {
    onUploadProgress,
    timeout: 30000,
  });
};

export const getBulkTemplateVersion = async () => {
  return APIclient.getData(`/ms/spm-disbursement/v1/bill/bulk-template`);
};

export const updateBulkTemplateVersion = async (versionID) => {
  return APIclient.postData(`/ms/spm-disbursement/v1/bill/bulk-template`, {
    versionID,
  });
};

export const uploadBulk = async (file, selectedWallet, selectedTeam, onUploadProgress) => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("walletID", selectedWallet);
  formData.append("teamID", selectedTeam);

  return APIclient.postData(`/ms/spm-disbursement/v1/bill/validate-excel`, formData, false, { onUploadProgress });
};

export const getListCurrency = async (filtered = false, countryCode = "") => {
  const filter = filtered ? `?filterBy=org&recipientCountry=${countryCode}` : "";
  return APIclient.getData(`/ms/spm-disbursement/v1/master/currency${filter}`);
};

export const getListCountry = async (filtered = false, showFrequentlyUsed = false) => {
  const filter = filtered ? `?filterBy=org&showFrequentlyUsed=${showFrequentlyUsed}` : "";
  return APIclient.getData(`/ms/spm-disbursement/v1/master/country${filter}`);
};

export const getListBank = async (countryCode: string, paymentCurrency: string, isTraditional?: boolean) => {
  const traditionalQuery = isTraditional ? "&type=bank" : "";
  return APIclient.getData(
    `/ms/spm-disbursement/v1/bank/list?countryCode=${countryCode}&paymentCurrency=${paymentCurrency}${traditionalQuery}`
  );
};

export const getConversionRate = (from, to) => {
  return APIclient.getData(`/api/v1/enumeration/conversion_rates?from_currency=${from}&to_currency=${to}`);
};

export const getConversionRateV2 = (from, to, config = {}) => {
  return APIclient.getData(
    `/ms/spm-disbursement/v1/currency/conversion-rates?from_currency=${from}&to_currency=${to}`,
    undefined,
    undefined,
    config
  );
};

export const getConversionAmount = (params) => {
  return APIclient.postData(`/ms/spm-disbursement/v1/currency/amount-conversion`, params);
};

export const stopPayment = async (billIDs) => {
  const bills = [];
  billIDs.forEach((billID) => bills.push({ billID, status: "cancelled" }));

  return APIclient.putData(`/ms/spm-disbursement/v1/bill/status`, { bills });
};

export const updateBillDetail = async ({
  receiptFiles = [],
  attachmentFiles = [],
  tags = [],
  expenseCategoryID = null,
  taxID = null,
  clientNotes = null,
  id,
}) => {
  const formData = new FormData();
  receiptFiles.length && receiptFiles.forEach((item) => formData.append("receipts", item));
  attachmentFiles.length && attachmentFiles.forEach((item) => formData.append("attachments", item));
  expenseCategoryID && formData.append("expenseCategoryID", expenseCategoryID);
  taxID && formData.append("taxID", taxID);
  clientNotes && formData.append("clientNotes", clientNotes);
  tags.length && formData.append("tags", JSON.stringify(tags));
  return APIclient.putData(`/ms/spm-disbursement/v1/bill/${id}/detail`, formData);
};

export const submitSingleBill = async (formData) => {
  return APIclient.postData(`/ms/spm-disbursement/v1/bill/single`, formData)
    .then(({ data }) => data)
    .catch((error) => error?.response?.data || null);
};

export const submitBill = async (formData, id) => {
  return APIclient.putData(`/ms/spm-disbursement/v1/bill/${id}`, formData)
    .then(({ data }: any) => data)
    .catch((error) => error?.response?.data || null);
};

export const submitMultipleBill = async (walletID: string, teamID: string, swiftPaymentChargeType: "SHA" | "OUR") => {
  return APIclient.putData(`/ms/spm-disbursement/v1/bill/save-excel`, {
    walletID,
    teamID,
    swiftPaymentChargeType,
  })
    .then(({ data }: any) => data)
    .catch(({ response: { data } }) => data);
};

export const deleteAttachment = async (attachmentID) => {
  return APIclient.deleteData(`/ms/spm-disbursement/v1/attachment/${attachmentID}`);
};

export const getDynamicForm = async (params) => {
  return APIclient.getData(urlParamsBuilder(`/ms/spm-disbursement/v1/bill/form-fields`, params));
};

type ReceiverFeeParams = {
  senderCurrency: string;
  receiverCurrency: string;
  receiverCountryCode: string;
  receiverAmount: number;
  totalAmount?: number;
  swiftPaymentChargeType: "SHA" | "OUR";
};

type SenderFeeParams = {
  senderCurrency: string;
  receiverCurrency: string;
  receiverCountryCode: string;
  totalAmount: number;
  receiverAmount?: number;
  swiftPaymentChargeType: "SHA" | "OUR";
};

export type BillFeeParameters = ReceiverFeeParams | SenderFeeParams;

export enum FeeId {
  EXCHANGE = "EXCHANGE",
  TRANSFER_FEE = "TRANSFER_FEE",
  SWIFT_PAYMENT_CHARGE_FEE = "SWIFT_PAYMENT_CHARGE_FEE",
}

export enum FeeOperator {
  MULTIPLY = "MULTIPLY",
  ADDITION = "ADDITION",
}

export enum FeeType {
  UNIT = "UNIT",
  CURRENCY = "CURRENCY",
}

export type FeeBreakdownProcess = {
  id: keyof typeof FeeId;
  label: string;
  note: string;
  operator: keyof typeof FeeOperator;
  tooltip: string;
  type: keyof typeof FeeType;
  value: number;
};

export interface BillFeeResponse {
  corridor: string;
  flatFee: number;
  flatFeeCurrency: string;
  variableRate: number;
  flatFeeFxRate: number;
  amountFxRate: number;
  totalFeeAmount: number;
  totalAmount: number;
  minimumTransferAmount?: number;
  swiftPaymentChargeFee?: number;
  breakdown?: FeeBreakdownProcess[];
}

export const getBillFee = async (params: BillFeeParameters, version: "v1" | "v2" = "v1", options?: {}) => {
  const url = urlParamsBuilder(`/ms/spm-disbursement/${version}/bill/corridor-simulator`, params);
  return APIclient.getData(url, undefined, undefined, options);
};

export interface VendorData {
  id: number;
  legalName: string;
  beneficiaryName: string;
  organisationID: string;
}

export const getVendorList = async () => {
  const url = `/ms/spm-disbursement/v1/vendor`;
  return APIclient.getData(url);
};

export const checkVendorNameAvailability = (legalName: string) => {
  const orgId = GetOrgId();
  const url = `/ms/spm-disbursement/v2/orgs/${orgId}/vendors/check-name-availability`;
  return APIclient.postData(url, {
    legalName,
  });
};

export interface GetVendorListWithPaginationParams {
  page_size?: string;
  page_token?: string;
  keyword: string;
  sort_by: string;
  sort_dir: "asc" | "desc" | "";
}

export const getVendorListWithPagination = async (
  params: GetVendorListWithPaginationParams,
  configs?: AxiosRequestConfig
) => {
  const orgId = GetOrgId();
  const urlParams = new URLSearchParams(params as any);
  const url = `/ms/spm-disbursement/v2/orgs/${orgId}/vendors`;
  return APIclient.getData(url, urlParams.toString(), false, configs);
};

export const getVendorDetail = async (id) => {
  const url = `/ms/spm-disbursement/v2/vendor/${id}`;
  return APIclient.getData(url);
};

export const getVendorDetailWithBillInfo = async (id: number) => {
  const orgId = GetOrgId();
  const url = `/ms/spm-disbursement/v2/orgs/${orgId}/vendors/${id}`;
  return APIclient.getData(url);
};

export const saveVendor = async (formData) => {
  const url = `/ms/spm-disbursement/v2/vendor`;
  return APIclient.putData(url, formData)
    .then(({ data }: any) => data)
    .catch(({ response: { data } }) => data);
};

export const editVendor = async (id, formData) => {
  const url = `/ms/spm-disbursement/v2/vendor/${id}`;
  return APIclient.putData(url, formData)
    .then(({ data }: any) => data)
    .catch(({ response: { data } }) => data);
};

export const getCorridorType = (senderCountryCode, senderCurrency, paymentCurrency, recipientCountryCode) => {
  let params = { recipientCountryCode, paymentCurrency, senderCountryCode, senderCurrency };
  const url = urlParamsBuilder("/ms/spm-disbursement/v1/master/corridor", params);
  return APIclient.getData(url.toString());
};

export const billPreview = async (formData) => {
  const orgId = GetOrgId();
  formData.append("organisationID", orgId);

  return APIclient.postData(`/ms/spm-disbursement/v1/bill/preview`, formData)
    .then(({ data }) => data)
    .catch(({ response: { data } }) => data);
};

export const vendorPreview = async (formData: FormData) => {
  return APIclient.postData(`/ms/spm-disbursement/v1/vendor/preview`, formData)
    .then(({ data }) => data)
    .catch(({ response: { data } }) => data);
};

export const vendorPreviewV2 = async (formData: FormData) => {
  return APIclient.postData(`/ms/spm-disbursement/v2/vendor/preview`, formData)
    .then(({ data }) => data)
    .catch(({ response: { data } }) => data);
};

export const billPreviewV2 = async (formData: FormData) => {
  const orgId = GetOrgId();
  formData.append("organisationID", orgId);

  return APIclient.postData(`/ms/spm-disbursement/v2/bill/preview`, formData)
    .then(({ data }) => data)
    .catch(({ response: { data } }) => data);
};

export const saveDraftBill = async (data) => {
  const orgId = GetOrgId();

  return APIclient.putData(`/ms/spm-disbursement/v1/bill/draft/bulk`, {
    draftBills: data.map((item) => ({ ...item, organisationID: orgId })),
  })
    .then(({ data }) => data)
    .catch(({ response: { data } }) => data);
};

export const submitDraftBill = async (formData, id) => {
  const orgId = GetOrgId();
  formData.append("id", id);
  formData.append("organisationID", orgId);

  return APIclient.putData(`/ms/spm-disbursement/v1/bill/draft/submit/single`, formData)
    .then(({ data }) => data)
    .catch(({ response: { data } }) => data);
};

export const submitBulkDraftBill = async (payload) => {
  return APIclient.postData(`/ms/spm-disbursement/v1/bill/draft/submit/bulk`, payload)
    .then(({ data }) => data)
    .catch(({ response: { data } }) => data);
};

export const uploadAttachment = async (formData) => {
  return APIclient.postData(`/ms/spm-disbursement/v1/file/upload`, formData, false, {
    timeout: 30000,
  })
    .then(({ data }) => data)
    .catch(({ response: { data } }) => data);
};

export interface CheckSimilarInvoicePayload {
  vendorID: string;
  billID?: string;
  currency?: string;
  amount?: number;
  dueDate?: string;
  invoiceNo?: string;
}

export const checkSimilarInvoice = async (payload: CheckSimilarInvoicePayload) => {
  const url = `/ms/spm-disbursement/v1/aire/spend-alert`;
  return APIclient.postData(url, payload);
};

export interface TaxCalculatorPayload {
  amount: string;
  taxRate: string;
  vatRate?: string;
}

export interface TaxData {
  amount: number;
  taxRate: number;
  vatRate: number;
  vatAmount: number;
  taxAmount: number;
  totalAmount: number;
  hasManualWHT?: boolean;
  isInclusive?: boolean;
}

export const taxCalculator = async (payload: TaxCalculatorPayload): Promise<AxiosResponse<{ payload: TaxData }>> => {
  const url = `/ms/spm-disbursement/v1/tax-calculator`;
  return APIclient.getData(urlParamsBuilder(url, payload));
};
