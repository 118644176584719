const mockResponse = {
  meta: {
    path: "/v2/reimbursements",
    code: 200,
    message: "GET /v2/reimbursements [200] OK",
    timestamp: "2023-09-21T05:02:33Z",
  },
  payload: [
    {
      requester: {
        id: "999671a4-bc4b-4b31-aba8-da0c38a44c85",
        name: "John Doe",
        email: "john.doe@gmail.com",
      },
      reimbursements: [
        {
          id: "02e569e6-1d00-11ef-86b4-0242ac120013",
          requestedAt: 1715126400,
          team: {
            id: "bf26e69f-d65a-4f45-8cf8-e9381bc49555",
            name: "Spenmo",
          },
          merchant: "Google",
          category: {
            id: 26,
            name: "Default",
          },
          currency: "SGD",
          amount: 100,
          walletCurrency: "SGD",
          walletAmount: 100,
          status: {
            key: "settled_non_payout",
            label: "Paid - Others",
            type: "success",
          },
        },
      ],
      totalPayableAmount: 100,
      totalReimbursementAmount: 100,
      totalCount: 1,
    },
    {
      requester: {
        id: "999671a4-aaaa-4b31-aba8-da0c38a44c85",
        name: "Jane Doe",
        email: "jane.doe@gmail.com",
      },
      reimbursements: [
        {
          id: "02e569e6-aaaa-11ef-86b4-0242ac120013",
          requestedAt: 1715126600,
          team: {
            id: "bf26e69f-aaa-4f45-8cf8-e9381bc49555",
            name: "Spenmo",
          },
          merchant: "Google",
          category: {
            id: 26,
            name: "Default",
          },
          currency: "SGD",
          amount: 100,
          walletCurrency: "SGD",
          walletAmount: 100,
          status: {
            key: "waiting_approval",
            label: "Pending Approval",
            type: "warning",
          },
        },
      ],
      totalPayableAmount: 100,
      totalReimbursementAmount: 100,
      totalCount: 1,
    },
    {
      requester: {
        id: "999671a4-bbbb-4b31-aba8-da0c38a44c85",
        name: "Jack Jones",
        email: "jack.jones@gmail.com",
      },
      reimbursements: [
        {
          id: "02e569e6-bbbb-11ef-86b4-0242ac120013",
          requestedAt: 1715124600,
          team: {
            id: "bf26e69f-bbbb-4f45-8cf8-e9381bc49555",
            name: "Spenmo",
          },
          merchant: "Google",
          category: {
            id: 26,
            name: "Default",
          },
          currency: "SGD",
          amount: 100,
          walletCurrency: "SGD",
          walletAmount: 100,
          status: {
            key: "settled",
            label: "Paid - Spenmo",
            type: "success",
          },
        },
      ],
      totalPayableAmount: 100,
      totalReimbursementAmount: 100,
      totalCount: 1,
    },
  ],
  status: 200,
};

export default mockResponse;
