import { useEffect, useState } from "react";
import { useBillForm } from "../context/FormContext";
import { RecipientFormContext } from "./types";
import { isEmpty } from "../utilities";

const CustomWrapper = (props) => {
  const { isOnChangeRefetch, resetFields, children, name, value, onChange, ...rest } = props;

  const { refetchValues, setRefetchValues, setRefetchDynamicFields } = useBillForm<RecipientFormContext>();

  const [isEnableRefetch, setIsEnableRefetch] = useState(true);

  const handleRefetch = () => {
    if (isOnChangeRefetch) {
      if (!name.includes("dynamicFields.")) {
        if (!refetchValues[name]) {
          setRefetchValues((prevValue) => ({
            ...prevValue,
            [name]: true,
          }));
        }
      } else if (!isEmpty(value)) {
        setRefetchDynamicFields({
          [name]: true,
        });
      }
    } else {
      setRefetchValues((prev) => {
        const { [name]: deleteKey, ...rest } = prev || {};

        return rest;
      });
    }
  };

  useEffect(() => {
    if (isEnableRefetch) {
      handleRefetch();
    }
  }, [isOnChangeRefetch, name, value]);

  const handleOnChange = (value: unknown, enableRefetch = true) => {
    if (isEnableRefetch !== enableRefetch) {
      setIsEnableRefetch(enableRefetch);
    }

    onChange(value);
  };

  return children({
    ...rest,
    name,
    value,
    onChange: handleOnChange,
  });
};

export default CustomWrapper;
