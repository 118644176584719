import React from "react";
import { Button, Typography } from "@spenmo/splice";
import { errorIcon } from "../../assets/img";
import styles from "./index.module.scss";
import { useHistory } from "react-router-dom";

const AccessForbidden = () => {
  const history = useHistory();

  return (
    <div className={styles.container}>
      <img className={styles.accessForbiddenImg} src={errorIcon} alt="access denied" />
      <Typography size="l" variant="body-content" className={styles.caption}>
        You do not have access to this page
      </Typography>
      <Typography size="m" tag="p" variant="body-content" className={styles.captionMessage}>
        Reach out to your Spenmo administrator for access
      </Typography>
      <Button
        variant="primary"
        className={styles.backHomeButton}
        onClick={() => {
          history.push("/");
        }}
      >
        Back to Homepage
      </Button>
    </div>
  );
};

export default AccessForbidden;
