export const ANALYTIC_EVENT = {
  BULK_EDIT_TAX: "BULK_EDIT_TAX",
  BULK_EDIT_TRANSACTION_TAGS: "BULK_EDIT_TRANSACTION_TAGS",
  BULK_EDIT_CATEGORY: "BULK_EDIT_CATEGORY",
  BULK_SYNC: "BULK_SYNC",
  BULK_RETRY_SYNC: "BULK_RETRY_SYNC",
  REDO_SYNC_IN_COMPLETE_TAB: "REDO_SYNC_IN_COMPLETE_TAB",
  SEND_EMAIL_REMINDER: "SEND_EMAIL_REMINDER",
  FORCE_SYNC_TRANSACTION: "FORCE_SYNC_TRANSACTION",
};
