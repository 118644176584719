import React from "react";
import { Link } from "react-router-dom";

import { SUBSCRIPTION_PATH } from "Route/Subscription/path";
import { ChevronLeft, Typography } from "@spenmo/splice";

import styles from "./index.module.scss";

export const Header = () => {
  return (
    <div className={styles.header}>
      <Link to={SUBSCRIPTION_PATH.SUBSCRIPTION_MANAGEMENT} className={styles.linkBtn}>
        <ChevronLeft size="16" iconColor="var(--text-link-default)" />
        <Typography variant="body-content" size="caption-m" weight={600}>
          Back to Subscriptions
        </Typography>
      </Link>

      <Typography variant="headline-content" size="l" tag="h1">
        Review auto-reconciled transactions
      </Typography>
    </div>
  );
};
