import APIclient from "API/Client";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import { GetOrgId } from "utility";

export const FETCH_TEAM_MEMBER_REQUEST = "FETCH_TEAM_MEMBER_REQUEST";
export const FETCH_TEAM_MEMBER_SUCCESS = "FETCH_TEAM_MEMBER_SUCCESS";
export const FETCH_TEAM_MEMBER_FAILURE = "FETCH_TEAM_MEMBER_FAILURE";
export const RESET_TEAM_MEMBER = "RESET_TEAM_MEMBER";

export const fetchDataRequest = (data) => {
  return {
    type: "FETCH_TEAM_MEMBER_REQUEST",
    data: data,
  };
};

export const fetchDataSuccess = (data) => {
  return {
    type: "FETCH_TEAM_MEMBER_SUCCESS",
    data: data,
  };
};

export const fetchDataError = (data) => {
  return {
    type: "FETCH_TEAM_MEMBER_FAILURE",
    data: data,
  };
};

export const resetTeamMemberData = () => {
  return {
    type: RESET_TEAM_MEMBER,
  };
};
export const searchTeamMember = (teamId, name, page = 0) => {
  return (dispatch) => {
    dispatch(fetchDataRequest());
    APIclient.getData(`/api/v1/org/${GetOrgId()}/team/${teamId}/member/search?name=${name}&page=${page}`)
      .then((data) => {
        if (data.data.status === HTTP_STATUS_CODE.OK) {
          dispatch(fetchDataSuccess(data.data));
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(fetchDataError(err));
      });
  };
};
