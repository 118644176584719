import React from "react";

import { Input } from "@spenmo/splice";
import { Field, Select } from "Views/SubscriptionManagement/Create/Components";
import { DatePicker } from "./DatePicker";
import { ContractTypeOptions } from "./Options";

import { option } from "Views/SubscriptionManagement/Create/options";
import { CONTRACT_TYPE, FORM_NAMES } from "Views/SubscriptionManagement/Constants";
import { useFormContext } from "Views/SubscriptionManagement/Create/Context";
import { ReviewContract } from "../Review";

export const ContractType = () => {
  const { control, watch } = useFormContext();
  const [contractType, endDate, nextBillingDate] = watch([
    FORM_NAMES.CONTRACT_TYPE,
    FORM_NAMES.END_DATE,
    FORM_NAMES.NEXT_BILLING_DATE,
  ]);

  return (
    <>
      <ContractTypeOptions contractType={contractType} />
      {contractType && (
        <Field twoRow>
          {contractType === CONTRACT_TYPE.FIXED ? (
            <Field>
              <DatePicker
                label="Contract End Date"
                name={FORM_NAMES.END_DATE}
                value={endDate}
                errorMessage="Select end date"
                disableBeforeDisabledDate
                disabledDate={nextBillingDate}
              />
            </Field>
          ) : (
            <Field>
              <Input.Label>Rolling Frequency</Input.Label>
              <Select
                requiredErrorMessage="Select a rolling frequency"
                name={FORM_NAMES.ROLLING_FREQUENCY}
                control={control}
                options={option.rollingFrequency}
              />
            </Field>
          )}
          <ReviewContract />
        </Field>
      )}
    </>
  );
};
