import APIclient from "API/Client";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import { GetOrgId } from "utility";

export const GET_CARD_NUMBERS_REQUEST = "GET_CARD_NUMBERS_REQUEST";
export const GET_CARD_NUMBERS_SUCCESS = "GET_CARD_NUMBERS_SUCCESS";
export const GET_CARD_NUMBERS_FAILURE = "GET_CARD_NUMBERS_FAILURE";

const fetchDataReq = () => {
  return {
    type: GET_CARD_NUMBERS_REQUEST,
    data: {},
  };
};
const fetchDataSuccess = (data) => {
  return {
    type: GET_CARD_NUMBERS_SUCCESS,
    data: data,
  };
};

const fetchDataError = (data) => {
  return {
    type: GET_CARD_NUMBERS_FAILURE,
    data: data,
  };
};

export const getCardNumbersFunc = () => {
  return (dispatch) => {
    dispatch(fetchDataReq());
    const orgId = GetOrgId();
    APIclient.getData(`/api/v1/org/card/number?organisation_id=${orgId}`)
      .then(({ status, data }) => {
        if (status === HTTP_STATUS_CODE.OK) {
          dispatch(fetchDataSuccess(data));
        } else {
          dispatch(fetchDataError(data));
        }
      })
      .catch((err) => {
        dispatch(fetchDataError(err));
      });
  };
};
