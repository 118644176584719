import React from "react";
import classNames from "classnames";
import Banner from "Modules/DS/Banner";
import { ESidePanelButtonType, ISidePanelButton } from "Modules/DS/SidePanel";
import { PrimaryButton } from "./PrimaryButton";
import { DestructiveButton } from "./DestructiveButton";
import { TertiaryButton } from "./TertiaryButton";
import { ConstructiveButton } from "./ConstructiveButton";
import { BorderedDestructiveButton } from "./BorderedDestructiveButton";
import { SecondaryButton } from "./SecondaryButton";
import { ConstructiveEnableButton } from "./ConstructiveEnableButton";
import { PrimaryEnableButton } from "./PrimaryEnableButton";
import styles from "./styles.module.scss";
import { TextButton } from "./TextButton";

export const generateButton = (button: ISidePanelButton, length: number) => {
  const shouldUseColSpan = length === 1 || button.shouldUseColSpan;

  const DefaultPrimaryButton = (
    <PrimaryButton
      key={button.id}
      {...button}
      action={() => button.action && button.action()}
      shouldUseColSpan={shouldUseColSpan}
    />
  );

  switch (button.type) {
    case ESidePanelButtonType.PRIMARY:
      return DefaultPrimaryButton;
    case ESidePanelButtonType.DESTRUCTIVE:
      return (
        <DestructiveButton
          key={button.id}
          {...button}
          action={() => button.action && button.action()}
          shouldUseColSpan={shouldUseColSpan}
        />
      );
    case ESidePanelButtonType.TERTIARY:
      return (
        <TertiaryButton
          key={button.id}
          {...button}
          action={() => button.action && button.action()}
          shouldUseColSpan={shouldUseColSpan}
        />
      );
    case ESidePanelButtonType.CONSTRUCTIVE:
      return (
        <ConstructiveButton
          key={button.id}
          {...button}
          action={() => button.action && button.action()}
          shouldUseColSpan={shouldUseColSpan}
        />
      );
    case ESidePanelButtonType.BORDERED_DESTRUCTIVE:
      return (
        <BorderedDestructiveButton
          key={button.id}
          {...button}
          action={() => button.action && button.action()}
          shouldUseColSpan={shouldUseColSpan}
        />
      );
    case ESidePanelButtonType.SECONDARY:
      return (
        <SecondaryButton
          key={button.id}
          {...button}
          action={() => button.action && button.action()}
          shouldUseColSpan={shouldUseColSpan}
        />
      );
    case ESidePanelButtonType.ADDITIONAL_INFORMATION:
      return <Banner key={button.id} message={button.additionalInfo.content} type="neutral" />;
    case ESidePanelButtonType.CONSTRUCTIVE_ENABLE:
      return (
        <ConstructiveEnableButton
          key={button.id}
          {...button}
          action={() => button.action && button.action()}
          shouldUseColSpan={shouldUseColSpan}
        />
      );
    case ESidePanelButtonType.PRIMARY_ENABLE:
      return (
        <React.Fragment key={button.id}>
          <span
            className={classNames([
              styles["col-span-2"],
              styles["txt-warning-pay-set-up"],
              {
                [styles.hidden]: button.warningHidden,
              },
            ])}
          >
            {button.warningText}
          </span>
          <PrimaryEnableButton
            {...button}
            action={() => button.action && button.action()}
            shouldUseColSpan={shouldUseColSpan}
          />
        </React.Fragment>
      );
    case ESidePanelButtonType.TEXT:
      return (
        <TextButton
          key={button.id}
          {...button}
          action={() => button.action && button.action()}
          shouldUseColSpan={shouldUseColSpan}
        />
      );
    default:
      return DefaultPrimaryButton;
  }
};

export { default as ButtonWrapper } from "./ButtonWrapper";
