import React from "react";

interface IFaq {
  question: string;
  answer: JSX.Element | string;
}

const SPENMO_DASHBOARD_URL = "https://dashboard.spenmo.com";
const SUPPORT_PAGE_URL = "https://support.spenmo.com/hc/en-us/articles/4410256863641-How-To-Check-User-Balance";
const BILLS_SUPPORT_PAGE_URL = "https://support.spenmo.com/hc/en-us/articles/4413246924057-Bill-Dashboard";

export const MMPH_FAQS: IFaq[] = [
  {
    question: "What will happen to my subscription fees during the migration period?",
    answer: (
      <>
        If you are an existing cardholder under the annual essential plan, we will temporarily suspend your subscription
        costs during the migration period until it is complete. Annual subscriptions will be extended for the duration
        of the suspended period during migration.
        <br />
        <br />
        For existing card customers on our monthly essential plan, we will waive your subscription fee starting March
        2023 until the migration is complete.
      </>
    ),
  },
  {
    question: "What will happen to my funds when the Spenmo Cards become unavailable?",
    answer:
      "Company funds will remain in your Spenmo account even during the course of the migration process. If you wish to move funds out of your account, kindly inform your dedicated Account Manager for the refund process to commence.",
  },
  {
    question: "When will the cards feature be available again?",
    answer:
      "We are migrating customer accounts to our new card provider and anticipate that card features will become available again soon. However, we cannot provide you with an exact timeframe for the time being. We are migrating accounts in batches and will contact you if we require additional information for your account activation. We appreciate your patience and understanding during this process and will keep you informed of any updates.",
  },
  {
    question: "I want to refund my annual paid subscription. What can I do?",
    answer:
      "We are sorry to hear about your decision. While we still believe that deferring your subscription is the ideal course of action, we respect your decision to request a refund. Should you wish to proceed, kindly inform your dedicated Account Manager for the refund process to commence.",
  },
  {
    question: "Can I downgrade first to Starter Plan while you're working things out?",
    answer:
      "Yes, but we will waive your monthly subscription fees until the migration is complete. So you can continue to enjoy all of the benefits of Essential Plan—without having to pay for it!",
  },
  {
    question: "What will happen to my transaction history during the migration process?",
    answer: (
      <>
        Customers may still access their transaction history during migration by logging into{" "}
        <a href={SPENMO_DASHBOARD_URL} target="_blank" rel="noreferrer">
          https://dashboard.spenmo.com
        </a>
      </>
    ),
  },
  {
    question: "How can we withdraw funds from our Spenmo account during the migration?",
    answer: (
      <>
        If you're looking to withdraw funds from your Spenmo account, please reach out to your dedicated Account Manager
        and provide them with your bank account details for the refund process to begin. Before doing so, we kindly ask
        that you ensure all funds from employee accounts are transferred back to the company wallet to expedite the
        process. Please refer to the knowledge base article linked{" "}
        <a href={SUPPORT_PAGE_URL} target="_blank" rel="noreferrer">
          here
        </a>{" "}
        for more information. Thank you for your cooperation.
      </>
    ),
  },
];

export const UOB_FAQS: IFaq[] = [
  {
    question: "What will happen to my subscription fees during the migration period?",
    answer: (
      <>
        If you are an existing cardholder under the <b>annual plan</b>, we will temporarily suspend your subscription
        costs during the migration period until it is complete. Annual subscriptions will be extended for the duration
        of the suspended period during migration.
        <br />
        <br />
        For existing card customers on our <b>monthly plan</b>, we will waive your subscription fee starting April 2023
        until the migration is complete.
      </>
    ),
  },
  {
    question: "What will happen to my funds when the Spenmo Cards become unavailable?",
    answer: (
      <>
        Company funds will remain in your Spenmo account even during the course of the migration process. If you wish to
        move funds out of your account, you may do so via Bill Pay. You may learn more about our Bill Pay feature{" "}
        <a href={BILLS_SUPPORT_PAGE_URL} target="_blank" rel="noreferrer">
          here
        </a>
      </>
    ),
  },
  {
    question: "When will the cards feature be available again?",
    answer:
      "We will announce our timelines for migration on 20th March. We appreciate your patience while we work through this transition period.",
  },
  {
    question: "What will happen to my cards prior to 1st April?",
    answer:
      "Your cards will continue to work as usual from now until 31st March. However, we highly recommend that you begin looking for temporary payment options as soon as possible so that your business will not be interrupted when we transition to our new card provider beginning 1st April.",
  },
  {
    question: "I want to refund my annual paid subscription. What can I do?",
    answer:
      "We are sorry to hear about your decision. While we still believe that deferring your subscription is the ideal course of action, we respect your decision to request a refund. Should you wish to proceed, kindly inform your dedicated Account Manager and we will credit your subscription cost from March onwards to your Spenmo account.",
  },

  {
    question: "What will happen to my transaction history when the migration begins on 1st April?",
    answer: (
      <>
        Customers may still access their transaction history during migration by logging into{" "}
        <a href={SPENMO_DASHBOARD_URL} target="_blank" rel="noreferrer">
          https://dashboard.spenmo.com
        </a>
      </>
    ),
  },
  {
    question: "Can I withdraw my remaining funds in Spenmo while waiting for the migration?",
    answer:
      "Yes, you would be able to withdraw your remaining funds in Spenmo, you should be able to withdraw your funds after 30th April 2023. Where we make sure that all of your transactions have been settled completely.",
  },
  {
    question: "What will happen to my account after the migration process?",
    answer:
      "You will be able to access your existing account using your current credentials. We won't require documents resubmission in order for you to use your account after the migration.",
  },
  {
    question: "Do I need to ask my employees to resubmit account verification in order to use Spenmo new cards?",
    answer:
      "If your employees have submitted verification previously, they don't need to redo their account verification again. We are going to verify based on your employees last verification.",
  },
];

export const getAdvisoryTexts: (isOrgCurrencyPHP: boolean) => string[] = (isOrgCurrencyPHP) => [
  "Dear Valued Customer,",
  "Thank you for trusting Spenmo as your spend management platform.",
  `As we continuously strive to improve your payment experience, we would like to inform you that we are in the process of transitioning to a new card provider. This will enhance your card experience, resulting in fewer service interruptions and better acceptance rates for local and international merchants${
    isOrgCurrencyPHP ? " and local ATM withdrawals" : ""
  }.`,
  "During this process, we will begin the migration of our customer accounts to our new card provider. We ask for your cooperation and understanding as you will be unable to use our card features starting 3rd April 2023 until the migration process has been completed.",
  "Our Bill Pay and Reimbursement services remain unaffected. You can continue using these features as per normal during the migration period.",
  "We apologise for any inconvenience caused by this migration. You may contact your Dedicated Account Manager for any inquiries or you may also refer to our FAQs below for immediate answers.",
  "We appreciate your support as we continue to work on improving our platform.",
  "Best regards,",
  "The Spenmo Team",
];
