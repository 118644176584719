import { COMPANY_VERIFICATION } from "Route/KYX/paths";

import { IFormProgress, IProgressTabItem } from "Views/KYX/@types";
import { CompanyInformation, PersonnelInformation, CompanyDocument } from "Views/KYX/Progress/Step";

import { FORM_NAME } from "./Forms";

export enum PROGRESS_FOOTER_ACTION {
  BACK = "back",
  NEXT = "next",
  DRAFT = "draft",
}

export enum FORM_SECTION_COMPONENT {
  CARD = "CARD",
  BANNER = "BANNER",
  CONTAINER = "CONTAINER",
  CARD_WITH_OPTIONAL = "CARD_WITH_OPTIONAL",
  MULTIPLE_SECTIONS_IN_CARD = "MULTIPLE_SECTIONS_IN_CARD",
  MULTIPLE_SECTIONS_WITH_HEADERS_IN_CARD = "MULTIPLE_SECTIONS_WITH_HEADERS_IN_CARD",
}

export enum KYB_METHOD_LIST {
  MY_INFO = "MyInfo Business",
  MANUAL = "Manual Upload",
}

export enum MY_INFO_ERROR_TYPE {
  ACRA = "ACRA Data Discrepancy",
  DATA_UNAVALIABLE = "Data Unavailable",
}

export enum FORM_STEP {
  COMPANY_INFORMATION = 1,
  PERSONNEL_INFORMATION = 2,
  COMPANY_DOCUMENTS = 3,
}

export enum DOCUMENT_STEP_TYPE {
  MULTIPLE_HEADERS_AND_STEPS = "MULTIPLE_HEADERS_AND_STEPS",
  MULTIPLE_STEPS = "MULTIPLE_STEPS",
  DEFAULT = "DEFAULT",
  NONE = "NONE",
}

export enum SECTION_CONTAINER_CLASS {
  FLAT_TOP = "flatTop",
  HIDE_CONTAINER = "hideContainer",
}

export const FORMS_LIST = {
  [FORM_NAME.COMPANY_INFORMATION]: "Company Information" as const,
  [FORM_NAME.PERSONNEL_INFORMATION]: "Personnel Information" as const,
  [FORM_NAME.COMPANY_DOCUMENTS]: "Company Documents" as const,
} as const;

export const FORMS_PROGRESS: IFormProgress[] = [
  {
    id: FORM_NAME.COMPANY_INFORMATION,
    step: FORM_STEP.COMPANY_INFORMATION,
  },
  {
    id: FORM_NAME.PERSONNEL_INFORMATION,
    step: FORM_STEP.PERSONNEL_INFORMATION,
  },
  {
    id: FORM_NAME.COMPANY_DOCUMENTS,
    step: FORM_STEP.COMPANY_DOCUMENTS,
  },
];

export const RESUME_INTRO_PROGRESS_OVERVIEW_LAST_STEP_PROXY_VALUE = FORMS_PROGRESS[0].step;

const COMPANY_INFORMATION: IProgressTabItem = {
  content: CompanyInformation,
  tabKey: FORMS_PROGRESS[0].id,
  url: COMPANY_VERIFICATION.COMPANY_INFORMATION,
  title: `${FORMS_PROGRESS[0].step}. ${FORMS_LIST[FORMS_PROGRESS[0].id]}`,
};

const PERSONNEL_INFORMATION: IProgressTabItem = {
  content: PersonnelInformation,
  tabKey: FORMS_PROGRESS[1].id,
  url: COMPANY_VERIFICATION.PERSONNEL_INFORMATION,
  title: `${FORMS_PROGRESS[1].step}. ${FORMS_LIST[FORMS_PROGRESS[1].id]}`,
};

const COMPANY_DOCUMENTS: IProgressTabItem = {
  content: CompanyDocument,
  tabKey: FORMS_PROGRESS[2].id,
  url: COMPANY_VERIFICATION.COMPANY_DOCUMENTS,
  title: `${FORMS_PROGRESS[2].step}. ${FORMS_LIST[FORMS_PROGRESS[2].id]}`,
};

export const PROGRESS_TABS: IProgressTabItem[] = [COMPANY_INFORMATION, PERSONNEL_INFORMATION, COMPANY_DOCUMENTS];
