import { AxiosResponse } from "axios";
import APIclient from "API/Client";
import { buildUrl, treatParams } from "Views/Transaction/Helper";
import {
  IGetCardsTrxnsAxiosResponse,
  ICardTrxnDetailsResponse,
  IGetCardsTrxnsRequest,
} from "Views/Transaction/@types/cards";
import { CARDS_TRXNS_SERVICE } from "./Services";

export const getAllCardsTrxns = async (
  payload?: IGetCardsTrxnsRequest
): Promise<AxiosResponse<IGetCardsTrxnsAxiosResponse>> => {
  const url: string = buildUrl(
    CARDS_TRXNS_SERVICE.GET_CARDS_TRXNS_LIST,
    treatParams<{ [key: string]: string }>({ filters: JSON.stringify(payload) })
  );

  return APIclient.getData(url);
};

export const getCardTrxnDetails = async (
  transactionId: string,
  isSpenmoTrxnId: boolean
): Promise<AxiosResponse<ICardTrxnDetailsResponse>> => {
  return APIclient.getData(
    `ms/b2b-card/v1/transaction/${isSpenmoTrxnId ? "spenmo-txn-id/" : ""}${transactionId}/life-cycle`
  );
};
