import APIclient from "API/Client";
import { GetOrgId } from "../../utility";
import { orgTeamFunc } from "Redux/Actions/orgTeamAction";
import { trackEvent } from "utility/analytics";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";

export const FETCH_APPROVAL_REQUEST = "FETCH_APPROVAL_REQUEST";
export const FETCH_APPROVAL_SUCCESS = "FETCH_APPROVAL_SUCCESS";
export const FETCH_APPROVAL_FAILURE = "FETCH_APPROVAL_FAILURE";

export const fetchDataReq = (data) => {
  return {
    type: FETCH_APPROVAL_REQUEST,
    data: data,
  };
};
export const fetchDataSuccess = (data) => {
  return {
    type: FETCH_APPROVAL_SUCCESS,
    data: data,
  };
};
export const fetchDataError = (data) => {
  return {
    type: FETCH_APPROVAL_FAILURE,
    data: data,
  };
};

const trackApiEvents = (trackActions, type, isdelete, requestSuccess, eventData = {}) => {
  let eventName = "";
  if (isdelete) {
    eventName = `request sent delete ${requestSuccess ? "success" : "fail"}`;
  } else {
    eventName = `request details ${type} ${requestSuccess ? "success" : "error"}`;
  }
  trackActions && trackEvent(eventName, eventData);
};

export const requestActionFunc = (values, requestId, type, isdelete = false, trackActions = false) => {
  const orgId = GetOrgId();
  const requestType = type === "decline" ? "reject" : "approve";
  return (dispatch) => {
    dispatch(fetchDataReq());
    APIclient.putData(`/api/v1/fund/request/${isdelete ? "delete" : requestType}/${requestId}`, {
      organisation_id: orgId,
      ...values,
    })
      .then((response) => {
        const { data } = response || {};
        // eslint-disable-next-line no-debugger
        // debugger;
        dispatch(fetchDataSuccess(data));
        if (data?.status === HTTP_STATUS_CODE.OK) {
          trackApiEvents(trackActions, type, isdelete, true);
        } else {
          trackApiEvents(trackActions, type, isdelete, false, {
            "error reason": data?.payload?.status_message || "",
          });
        }
      })
      .catch((err) => {
        dispatch(fetchDataError(err));
        trackApiEvents(trackActions, type, isdelete, false, {
          "error reason": err || "",
        });
      });
  };
};

export const requestPayNowFunc = (requestId) => {
  const orgId = GetOrgId();

  return (dispatch) => {
    dispatch(fetchDataReq());
    APIclient.putData(`/api/v1/fund/request/settle/${requestId}`, {
      organisation_id: orgId,
    })
      .then((data) => {
        dispatch(fetchDataSuccess(data.data));
        dispatch(orgTeamFunc());
      })
      .catch((err) => {
        dispatch(fetchDataError(err));
      });
  };
};
