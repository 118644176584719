import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { getUrlParam } from "utility";

import { useVerifiedReceiptsMemoized } from "./Receipts";

import { errorIcon } from "assets/img";
import styles from "./index.module.scss";

const Resource = () => {
  const history = useHistory();
  const location = useLocation();

  const urlParam = getUrlParam(location.search, "url");

  const { fetchReceipts, attachments, loading, error, isComplete } = useVerifiedReceiptsMemoized(urlParam);

  useEffect(() => {
    fetchReceipts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (error && !loading) {
    return (
      <div className={styles.container}>
        <div className={styles.errorState}>
          <img src={errorIcon} alt="error icon" />
          <p>Cannot load file preview. Please verify the link is not broken.</p>
          <button onClick={() => window.location.reload()}>Reload Page</button>
        </div>
      </div>
    );
  }

  if (attachments.current.length) {
    window.location.replace(attachments.current[0]);
  }

  if (isComplete) {
    /**
     * observed an issue on safari browser that, if we just call `history.push("/")`
     * the download starts and user is redirected to home page, but the page never finish loading.
     * adding this timeout before push, solves the issue.
     */
    setTimeout(() => {
      history.push("/");
    }, 250);
  }

  return (
    <div className={styles.container}>
      <div className={styles.loading} />
    </div>
  );
};

export default Resource;
