import React, { useRef } from "react";

import { IconCell } from "Views/Transaction/List";
import { IGetTrxnResponse } from "Views/Transaction/@types";
import { TRXN_ELEMENT_IDS } from "Views/Transaction/Constants";
import { ALLOWED_FILE_EXTENSIONS } from "Views/UploadInvoice/const";

import { useUploadData } from "./useUploadData";

import { addReceipt } from "assets/v1.1/icons/Line";
import styles from "./index.module.scss";

export const AddAttachmentCell = ({
  record,
  setLoading,
  disabled = false,
}: {
  record: IGetTrxnResponse;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  disabled?: boolean;
}) => {
  const inputFileRef = useRef(null);

  const uploadData = useUploadData();

  const onIconClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
    if (disabled) return;

    inputFileRef.current.click();
  };

  const onAddReceiptsClick: React.ChangeEventHandler<HTMLInputElement> = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (disabled) return;

    const uploadedFiles: FileList = e.target.files;

    if (!uploadedFiles.length) return;

    await uploadData(uploadedFiles, record, setLoading);
  };

  const onClickHandler = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!([TRXN_ELEMENT_IDS.ICON_CELL, TRXN_ELEMENT_IDS.ADD_ATTACHMENT] as string[]).includes((e.target as Element).id))
      return;

    e.stopPropagation();
  };

  return (
    <div onClick={onClickHandler}>
      <input
        type="file"
        multiple
        ref={inputFileRef}
        onChange={onAddReceiptsClick}
        className={styles.addAttachment}
        accept={ALLOWED_FILE_EXTENSIONS.map((item) => `.${item}`).join(",")}
        id={TRXN_ELEMENT_IDS.ADD_ATTACHMENT}
      />
      <IconCell src={addReceipt} alt="add attachment" onClick={onIconClick} disabled={disabled} />
    </div>
  );
};
