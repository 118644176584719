const baseTypes = {
  CreateComponentData: "DYNAMIC_CREATE_COMPONENT_DATA",
  UpdateComponentData: "DYNAMIC_UPDATE_COMPONENT_DATA",
  DeleteComponentData: "DYNAMIC_DELETE_COMPONENT_DATA",
  //Data oriented
  AddErrorInComponent: "DYNAMIC_ADD_ERROR_COMPONENT_DATA",
  ToggleLoaderInComponent: "DYNAMIC_LOADER_UPDATE_COMPONENT_DATA",
  AddDataInComponent: "DYNAMIC_ADD_DATA_COMPONENT_DATA",
};

export const baseComponentData = {
  error: null,
  data: {},
  loading: false,
};

export default baseTypes;
