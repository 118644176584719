import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import moment from "moment";
import { SpendingTrend } from "./SpendingTrend";
import { Categories } from "./Categories";
import { Merchants } from "./Merchants";
import { Teams } from "./Team";
import Card from "./Card";
import styles from "./Analytics.module.scss";

/* importing layout components */
import { AnalyticsLayout } from "Views/Analytics/Layout";
import { IAnalyticsFilter, IRootDataType } from "Views/Analytics/@types";

/* importing dummy data */
import AnalyticsContext from "./Context/context";
import { getCookieValue, cookieNames } from "utility/CookieHelper";
import { useMerchantList, useSpendingTrendList, useCategoryList, useTeamList } from "./hooks";
import { SelectProvider } from "Modules/DS/Select";
import { CASBIN_PAGES } from "Permission/Pages";
import { CENTRALISED_APPROVAL_MENU } from "Permission/Actions";
import usePermissionCheck from "Permission/usePermissionCheck";
import { useHistory } from "react-router-dom";
import { currencyFormatterV2 as currencyFormatter } from "utility";
import { dateFormatter4, getCurrentDate } from "./utils";
import queryString from "query-string";
import LoadingComponent from "Views/State/Loading/LoaderIcon";
import { transactionType } from "./Filters/MoreFilters/transactionType";
import { AnalyticsCardTitle, AnalyticsEventsName } from "./Analytics";
import { trackEvent } from "utility/analytics";
import { useIsSessionExpired } from "customHooks/Auth/useIsSessionExpired";

export const percentageStyles = {
  positive: "positive-change",
  negative: "negative-change",
  zero: undefined,
};

function Analytics() {
  const currency = getCookieValue(cookieNames.CURRENCY_CODE);
  const [, setHasError] = useState<boolean>(false);
  const isSessionExpired = useIsSessionExpired();

  const history = useHistory();
  const permissionParam = [{ object: CASBIN_PAGES.ANALYTICS_MENU, action: CENTRALISED_APPROVAL_MENU.READ }];
  const [IS_ALLOWED_ANALYTICS_MENU_READ, loadingPermission] = usePermissionCheck(permissionParam, true) as [
    boolean,
    boolean
  ];

  const [filter, setFilter] = useState<IAnalyticsFilter>({
    startDate: moment().add(-1, "M").add(1, "d").utc().startOf("d").toISOString(),
    endDate: getCurrentDate(),
    differences: {
      days: 0,
      months: 1,
    },
    team_ids: [],
    type_ids: [],
    otherCustomFilter: {
      isCustom: false,
      type: "months",
    },
  });
  const [moreFilter, setMoreFilter] = useState<{ [key: string]: string[] }>({
    team_ids: [],
    type_ids: [],
  });
  const [totalSpending, setTotalSpending] = useState<number | null>(null);
  const [clear, setClear] = useState(false);
  const getTotalSpending = (value: number) => {
    setTotalSpending(value);
  };
  const checkRetrieveDataError = (value: boolean) => {
    setHasError(value);
  };
  const analyticsContext = {
    filter,
    setFilter,
    currency,
    clear,
    setClear,
    moreFilter,
    setMoreFilter,
  };
  const { spendingTrendData, spendingTrendLoading } = useSpendingTrendList({
    filter,
    currency,
    getTotalSpending,
    checkRetrieveDataError,
  });
  const { spendingMerchantsData, spendingMerchantsLoading } = useMerchantList({
    filter,
    currency,
    checkRetrieveDataError,
  });
  const { selectedCategory, handleSelectChart, spendingCategoriesLoading, spendingCategoriesData, categoryPieChart } =
    useCategoryList({
      filter,
      currency,
      checkRetrieveDataError,
    });
  const { spendingTeamsLoading, spendingTeamsData, totalTeamCategories, colorMap, totalTeamAmount } = useTeamList({
    filter,
    currency,
    checkRetrieveDataError,
  });

  useEffect(() => {
    trackEvent(AnalyticsEventsName.PAGE_LOADED);
  }, []);

  if (loadingPermission) {
    return <LoadingComponent />;
  }

  if (!IS_ALLOWED_ANALYTICS_MENU_READ && !isSessionExpired) {
    return <Redirect to="/" />;
  }

  const getDeeplinkDate = (rootData: IRootDataType): object => {
    if (rootData.from === AnalyticsCardTitle.SPENDING_TREND) {
      if (
        (filter.differences.months === 1 && filter.otherCustomFilter.isCustom === false) ||
        (filter.otherCustomFilter.isCustom === true && filter.otherCustomFilter.type === "months")
      ) {
        return {
          startDate: moment(rootData.data?.timestamp).utc().startOf("d").format("YYYY-MM-DD"),
          endDate: moment(rootData.data?.timestamp).utc().endOf("d").format("YYYY-MM-DD"),
        };
      }
      return {
        startDate: moment(rootData.data?.start).format("YYYY-MM-DD"),
        endDate: moment(rootData.data?.end).format("YYYY-MM-DD"),
      };
    }
    return {
      startDate: moment(filter.startDate).utc().format("YYYY-MM-DD"),
      endDate: moment(filter.endDate).utc().format("YYYY-MM-DD"),
    };
  };

  const getTeamId = (rootData: IRootDataType): string | string[] => {
    if (rootData.from === AnalyticsCardTitle.BUDGETS && rootData.data?.selectedTeam) return rootData.data.selectedTeam;
    return filter.team_ids?.join(",") || null;
  };

  const handleApplyDeeplink = (rootData: IRootDataType): void => {
    if (Boolean(history) === false || Boolean(rootData) === false) return;

    trackEvent(AnalyticsEventsName.DATA_POINT_CLICKED, {
      analytics_event_source: rootData.from,
    });

    const query = queryString.stringifyUrl(
      {
        url: "/transactions",
        query: {
          tab: "all",
          types: filter.type_ids?.join(",") || transactionType.map((type) => type.id).join(","),
          teams: getTeamId(rootData),
          ...getDeeplinkDate(rootData),
          ...(rootData.from === AnalyticsCardTitle.CATEGORIES ? { category: rootData.data?.selectedCategory } : {}),
          ...(rootData.from === AnalyticsCardTitle.MERCHANTS
            ? { recipientNames: rootData.data?.selectedMerchants }
            : {}),
        },
      },
      {
        skipEmptyString: true,
      }
    );
    history.push(query);
  };

  return (
    <AnalyticsContext.Provider value={analyticsContext}>
      <SelectProvider>
        <AnalyticsLayout>
          <Card
            title={
              <div className={styles.analyticsTitle}>
                Spending Trend
                <span>{dateFormatter4(filter.startDate, filter.endDate)}</span>
              </div>
            }
            className={styles.analyticCard}
            rightSideTitle={
              totalSpending ? (
                <div className={styles.totalSpending}>
                  <span>Total Spending</span> <span>{currencyFormatter(totalSpending, currency, true, 2)}</span>
                </div>
              ) : null
            }
          >
            <SpendingTrend
              spendingTrendData={spendingTrendData}
              spendingTrendLoading={spendingTrendLoading}
              handleApplyDeeplink={handleApplyDeeplink}
            />
          </Card>
          <Card className={styles.analyticCard} title="Categories">
            <Categories
              filter={filter}
              selectedCategory={selectedCategory}
              spendingCategoriesData={spendingCategoriesData}
              spendingCategoriesLoading={spendingCategoriesLoading}
              categoryPieChart={categoryPieChart}
              handleSelectChart={handleSelectChart}
              handleApplyDeeplink={handleApplyDeeplink}
            />
          </Card>
          <Card className={styles.analyticCard} title="Merchants">
            <Merchants
              filter={filter}
              spendingMerchantsLoading={spendingMerchantsLoading}
              spendingMerchantsData={spendingMerchantsData}
              handleApplyDeeplink={handleApplyDeeplink}
            />
          </Card>
          <Card title="Budgets">
            <Teams
              filter={filter}
              spendingTeamsLoading={spendingTeamsLoading}
              spendingTeamsData={spendingTeamsData}
              totalTeamCategories={totalTeamCategories}
              colorMap={colorMap}
              totalTeamAmount={totalTeamAmount}
              handleApplyDeeplink={handleApplyDeeplink}
            />
          </Card>
        </AnalyticsLayout>
      </SelectProvider>
    </AnalyticsContext.Provider>
  );
}
export default Analytics;
