import * as React from "react";
import { Button, Modal } from "antd";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import { checkForPhysicalCardEligibility } from "Redux/DataCalls/Cards.api";
import { ModalComponent } from "Modules/DS/Modal";
import { blackCrossIcon24x24, emptyCardIcon120x120 } from "assets/icons/card";

import styles from "Views/Card/Add/PhysicalCardEligibility/index.module.scss";
import { showErrorNotification } from "Views/Card/Components/Notification";

interface IPhysicalCardEligibilityProps {
  onClose: () => void;
  showCardRequestFlow: () => void;
}

const OTP_VERIFICATION_NEEDED = 1001;
const NOT_A_WALLET_USER = 1002;

const PhysicalCardEligibility: React.FunctionComponent<IPhysicalCardEligibilityProps> = ({
  onClose,
  showCardRequestFlow,
}) => {
  const [checkingInProgress, setCheckingInProgress] = React.useState(false);
  const [eligibilityInfo, setEligibilityInfo] = React.useState({
    header: "",
    footer: "",
  });

  const handleError = (statusMessage = "Something went wrong! Please try again later.") => {
    showErrorNotification(statusMessage);
    onClose();
  };
  const checkEligibility = () => {
    setCheckingInProgress(true);
    checkForPhysicalCardEligibility()
      .then((response) => {
        const { payload = {}, status } = response?.data || {};
        // if otp verification needed it will be handled in the otpVerificationModal in card request flow
        if (status === HTTP_STATUS_CODE.OK || [OTP_VERIFICATION_NEEDED, NOT_A_WALLET_USER].includes(status)) {
          onClose();
          showCardRequestFlow();
        } else {
          const { footer = "", header = "" } = payload || {};
          if (footer && header) {
            setEligibilityInfo({
              header,
              footer,
            });
          } else {
            handleError(payload?.status_message || "");
          }
        }
      })
      .catch((err) => {
        handleError(err?.response?.data?.payload?.status_message || "");
      })
      .finally(() => {
        setCheckingInProgress(false);
      });
  };
  React.useEffect(() => {
    checkEligibility();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getScreen = () => {
    switch (true) {
      case checkingInProgress:
        return (
          <div className={styles["loader-container"]}>
            <ModalComponent.Loader />
          </div>
        );
      case !!eligibilityInfo?.header:
        return (
          <div className={styles["info-container"]}>
            <img src={emptyCardIcon120x120} alt="empty card icon" width={120} height={120} />
            <p className={styles["info"]}>{eligibilityInfo?.footer}</p>
            <Button onClick={onClose} className={styles["action-btn"]}>
              Got It
            </Button>
          </div>
        );
      default:
        return null;
    }
  };
  return (
    <>
      <Modal
        visible
        onCancel={onClose}
        className={styles["eligibility-modal"]}
        title={checkingInProgress ? null : eligibilityInfo?.header}
        footer={null}
        closeIcon={checkingInProgress ? <div /> : <img src={blackCrossIcon24x24} alt="close icon" />}
        centered
        width={checkingInProgress ? 200 : 540}
      >
        {getScreen()}
      </Modal>
    </>
  );
};

export default PhysicalCardEligibility;
