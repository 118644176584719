import React, { useContext, useState } from "react";
import cn from "classnames";
import { Modal } from "@spenmo/splice";
import { useForm, FormProvider } from "react-hook-form";
import useSWRMutation from "swr/mutation";

import { putData } from "API/Client";
import { useMatchMutate } from "API/useData";

import { appNotification } from "Modules/appNotification/appNotification";
import { SidePanel } from "Modules/DS/SidePanel";
import RecipientForm from "./RecipientForm";
import RecipientPreview from "./RecipientPreview";
import { useDisclosure } from "../hooks/useDisclosure";
import FormFooter from "../BillForm/FormFooter";

import { BillContext } from "../context/BillContext";
import { FormContext } from "../context/FormContext";
import { RecipientFormInputs, RefetchDynamicFields } from "./types";
import { API_URL } from "../constants";
import { billDiscard } from "assets/img";
import { getRecipientPayload } from "./helper";

import styles from "./Recipient.module.scss";

const RecipientSidepanel: React.FC = () => {
  const formMethods = useForm<RecipientFormInputs>({
    mode: "onTouched",
    reValidateMode: "onChange",
  });
  const { handleSubmit, getValues, unregister, reset } = formMethods;
  const {
    isOpenRecipientSidePanel,
    setIsOpenRecipientSidePanel,
    dynamicFieldsIdentifier,
    setDynamicFieldsIdentifier,
    recipientSelectedID,
    setRecipientSelectedID,
  } = useContext(BillContext);
  const {
    isOpen: isShowPreview,
    onOpen: openPreview,
    onClose: closePreview,
  } = useDisclosure();
  const {
    isOpen: isShowDiscardModal,
    onOpen: openDiscardModal,
    onClose: closeDiscardModal,
  } = useDisclosure();
  const {
    isOpen: isOpenNewBank,
    onOpen: onOpenNewBank,
    onClose: onCloseNewBank,
  } = useDisclosure();
  const matchMutate = useMatchMutate();

  const [refetchValues, setRefetchValues] = useState({});
  const [refetchDynamicFields, setRefetchDynamicFields] = useState<
    RefetchDynamicFields | undefined
  >();

  const { trigger, isMutating } = useSWRMutation(
    API_URL.saveRecipient,
    (url: string, { arg }: { arg: any }) => putData(url, arg),
    {
      onSuccess: async (res) => {
        const isEdited = Boolean(recipientSelectedID);
        appNotification.success({
          message: `${getValues("legalName")} has been ${isEdited ? "edited" : "succcessfully added"}`,
        });

        await matchMutate(new RegExp(API_URL.recipientList));
        await matchMutate(new RegExp(API_URL.disbursementV2));

        if (isEdited) {
          await matchMutate(
            new RegExp(`${API_URL.recipientDetail}/${recipientSelectedID}`),
          );
        } else {
          setRecipientSelectedID(res.data.payload.ID);
          await matchMutate(
            new RegExp(`${API_URL.recipientDetail}/${res.data.payload.ID}`),
          );
        }
        closeSidePanel();
      },
    },
  );

  const closeSidePanel = () => {
    unregister();
    reset();
    setRefetchValues({});
    setRefetchDynamicFields(undefined);
    setDynamicFieldsIdentifier(undefined);

    setIsOpenRecipientSidePanel(false);
    closePreview();
    closeDiscardModal();
  };

  const submitRecipientForm = () => {
    trigger(
      getRecipientPayload(
        getValues(),
        dynamicFieldsIdentifier,
        recipientSelectedID,
      ),
    );
  };

  return (
    <>
      <FormContext.Provider
        value={{
          // TO DO: create a custom Provider component for FormContext
          refetchValues,
          setRefetchValues,
          refetchDynamicFields,
          setRefetchDynamicFields,
          isOpenNewBank,
          onOpenNewBank,
          onCloseNewBank,
        }}
      >
        <FormProvider {...formMethods}>
          <SidePanel
            sidePanelClassName={styles.sidepanel}
            visible={isOpenRecipientSidePanel}
            onClose={openDiscardModal}
            title={`${recipientSelectedID ? "Edit" : "New"} Recipient`}
            className={styles.recipientSidePanel}
            sticky
          >
            <form>
              {isShowPreview && <RecipientPreview />}

              {/* just hide it from UI, unmount this component will reset the form state */}
              <div
                className={cn({
                  [styles.hidden]: isShowPreview,
                })}
              >
                {isOpenRecipientSidePanel && <RecipientForm />}
              </div>

              {isShowPreview ? (
                <FormFooter
                  onClickBack={closePreview}
                  onSubmit={submitRecipientForm}
                  isSubmitLoading={isMutating}
                  submitText="Submit"
                  key="sidepanel-footer-preview"
                />
              ) : (
                <FormFooter
                  onClickBack={openDiscardModal}
                  onSubmit={handleSubmit(openPreview)}
                  submitText="Next"
                  key="sidepanel-footer-form"
                />
              )}
            </form>
          </SidePanel>
        </FormProvider>
      </FormContext.Provider>

      <Modal
        showModal={isShowDiscardModal}
        size="m"
        title="Discard Changes?"
        onClose={closeDiscardModal}
        footerFullWidthButtons
        primaryActionButton={{
          title: "Stay on page",
          action: closeDiscardModal,
        }}
        secondaryActionButton={{
          title: "Discard",
          action: closeSidePanel,
        }}
        className={styles.discardModal}
      >
        <img
          className={styles.images}
          src={billDiscard}
          alt="Discard Changes?"
          width={120}
          height={120}
        />
        <div>
          There are unsaved changes in this recipient form. Do you want to
          discard these changes?
        </div>
      </Modal>
    </>
  );
};

export default RecipientSidepanel;
