import * as React from "react";
import { Progress } from "antd";
import { Info } from "assets/v1.1/icons/Core/Info";
import { Tooltip } from "Modules/DS/Tooltip";
import { getCookieValue, cookieNames } from "utility/CookieHelper";
import { formatHeaderText } from "Views/Card/List/Table/FuelGuage/Utility";
import { TooltipPositionEnum, TooltipArrowAlignmentEnum } from "Modules/DS/Tooltip/types.d";
import { CARD_LIMIT_TYPES, ISpendingAllowanceDetails } from "Views/Card/types";
import styles from "Views/Card/List/Table/FuelGuage/index.module.scss";
import classNames from "classnames";

interface FuelGuageTypes {
  spendingAllowanceDetails: ISpendingAllowanceDetails;
  cardLimitType: CARD_LIMIT_TYPES;
  disabledCard: boolean;
}

const FuelGuage = ({ spendingAllowanceDetails, cardLimitType, disabledCard }: FuelGuageTypes) => {
  const { headerText, footerText, limitToDisplay, cardRemainingLimit, cardTotalLimit, spendPercentage } =
    spendingAllowanceDetails;

  const orgCurrencyCode = getCookieValue(cookieNames.CURRENCY_CODE);

  const getCardRemainingLimit = () => {
    const showLimitInXL = cardLimitType === CARD_LIMIT_TYPES.unlimited && !footerText;
    const _classname = classNames(styles["info-text"], {
      [styles["info-text--xl"]]: showLimitInXL,
      [styles["info-text--red"]]: spendPercentage > 100,
    });
    let remainingLimit: string | JSX.Element;
    if (showLimitInXL) {
      remainingLimit = (
        <>
          {cardRemainingLimit?.replace("spent", "")}
          <span className={styles["info-text--l"]}>Spent</span>
        </>
      );
    } else {
      remainingLimit = cardRemainingLimit;
    }
    return <p className={_classname}>{remainingLimit}</p>;
  };

  const getFuelGuage = () => {
    const fuelGuageClassName = classNames(styles["progress-bar"], {
      [styles["progress-bar--red"]]: spendPercentage > 100 && !disabledCard,
      [styles["progress-bar--grey"]]: disabledCard,
    });
    return (
      <div className={styles["fuel-guage"]}>
        <Progress percent={spendPercentage} className={fuelGuageClassName} data-testid="progress-bar" />
        <div className={styles["fuel-guage-limits-container"]}>
          {getCardRemainingLimit()}
          <p className={styles["info-text-greyed-out"]}>{cardTotalLimit}</p>
        </div>
      </div>
    );
  };

  const footerTextTooltip = () => (
    <Tooltip
      children={<img src={Info.Fill["grey_16"]} height={16} width={16} alt="info icon" />}
      text={footerText}
      position={TooltipPositionEnum.TOP}
      alignArrow={TooltipArrowAlignmentEnum.CENTER}
    />
  );

  const getLimit = () => limitToDisplay?.replace?.(orgCurrencyCode, "")?.replace?.("remaining", "");

  const getHeaderText = () => {
    return cardLimitType === CARD_LIMIT_TYPES.unlimited && !footerText ? (
      <div />
    ) : (
      <p className={styles["limit-type-text"]}>{formatHeaderText(headerText || "")}</p>
    );
  };

  const containerClassName = classNames(styles["card-spending-allowance"], {
    [styles["card-spending-allowance--disabled"]]: disabledCard,
  });

  if (headerText || limitToDisplay || footerText || cardLimitType === CARD_LIMIT_TYPES.unlimited) {
    return (
      <div className={containerClassName}>
        <div className={styles["header"]}>
          {getHeaderText()}
          <div className={styles["limit-container"]}>
            <div className={styles["tooltip-container"]}>{footerText && footerTextTooltip()}</div>
            {limitToDisplay && (
              <>
                <span className={styles["currency-code"]}>{orgCurrencyCode}</span>
                <span className={styles["limit"]}>{getLimit()}</span>
              </>
            )}
          </div>
        </div>
        {getFuelGuage()}
      </div>
    );
  }

  return null;
};

export default FuelGuage;
