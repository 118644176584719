import * as React from "react";
import { Button, Modal } from "antd";

import { refreshFxRate } from "assets/img";

import "./RefreshFxModal.scss";

interface RefreshFxModalProps {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  action?: () => void;
  actionLabel?: string;
  hasCloseButton?: boolean;
  closeButtonLabel?: string;
}

const RefreshFxModal = ({
  visible,
  setVisible,
  action,
  actionLabel = "View Details",
  hasCloseButton = false,
  closeButtonLabel = "Back",
}: RefreshFxModalProps) => {
  const handleAction = () => {
    setVisible(false);
    action?.();
  };

  return (
    <Modal className="refresh-fx-modal" width={350} onCancel={() => setVisible(false)} visible={visible} footer={null}>
      <h1 className="refresh-fx-modal__title">Exchange Rate Updated</h1>
      <div className="refresh-fx-modal__content">
        <img className="icon" src={refreshFxRate} alt="refresh fx rate" />
        <p className="desc">
          Exchange rate has been updated. Please review the listed payment details below before proceeding:
        </p>
        <ul className="fields">
          <li>Payment Amount</li>
          <li>Amount to Deduct from Wallet</li>
          <li>Exchange Rate</li>
        </ul>
        <div className="refresh-fx-modal__action">
          {hasCloseButton && (
            <Button className="close" onClick={() => setVisible(false)}>
              {closeButtonLabel}
            </Button>
          )}
          <Button className="action" onClick={handleAction}>
            {actionLabel}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default RefreshFxModal;
