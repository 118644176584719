import { ListData } from "Modules/DS/Tabs";
import { currencyFormatter } from "utility";
import ApprovalFlowList from "./ApprovalList";
import { AMOUNT_SUBMENU, APPROVAL_FLOW_EVENT_TYPE, TABS } from "./constants";
import { trackEvent } from "utility/analytics";
import { APPROVAL_FLOW_EVENTS } from "./trackingEvents";
import React from "react";
import PolicyContent from "./SelfApprovalSidePanel/PolicyContent";
import { TAB_KEY } from "../Approval/ApprovalCenter/types";
import ChangeLogHistory from "./SelfApprovalSidePanel/ChangeHistory";
import { plural } from "utility";

export const validateConditions = (conditions) => {
  const conditionTypes = Object.keys(conditions);
  return conditionTypes.reduce((acc, curval) => {
    return acc && !checkEmptyCondition(curval, conditions[curval]);
  }, conditionTypes.length > 0);
};

export const validateApprovers = (steps) => {
  return steps.reduce((acc, curval) => {
    return acc && curval?.assignees?.length > 0 && curval.assignees?.every((item) => item.role);
  }, true);
};

export const checkEmptyCondition = (key, value) => {
  switch (key) {
    case "amount":
      return !(value?.operator && value?.value);
    case "wallet":
      return !(value?.length > 0 ? value[0]?.walletId : !value?.length);
    case "expenseCategory":
      return !value?.length;
  }
};

export const conditionParser = (key, condition, currencyCode) => {
  switch (key) {
    case "wallet":
      return condition?.length
        ? `Budget is from ${condition
            .map((wallet) => (wallet.isDeleted ? `<span class="error-text">` + wallet.name + `</span>` : wallet.name))
            .join(", ")}`
        : "Any Budget";
    case "amount":
      const { value, operator } = condition || {};
      const amounts = value?.split(":").map((val) => currencyFormatter(val, currencyCode));
      return value && operator
        ? `Amount is ${AMOUNT_SUBMENU.find((op) => op.value === operator)?.label?.toLowerCase()} ${amounts.join(
            " to "
          )}`
        : "Any Amount";
    case "expenseCategory":
      return condition?.length
        ? `Expense Categor${condition?.length > 1 ? "ies are" : "y is"} ${condition
            ?.map((category) =>
              category.isDeleted ? `<span class="error-text">` + category.name + `</span>` : category.name
            )
            ?.join(", ")}`
        : "Any Expense Category";
    default:
      return "";
  }
};

export const generateMessageForFlowErrors = (flow) => {
  let errorMessage = [];
  const flowErrors = Object.keys(flow.condition)
    .filter((item) => item !== "amount")
    .reduce((prevVal, currVal) => {
      return {
        ...prevVal,
        [currVal]: flow.condition[currVal].reduce((prev, curr) => {
          return prev + (curr.isDeleted ? 1 : 0);
        }, 0),
      };
    }, {});
  flowErrors["approvers"] = flow.stepAssignees.reduce((prev, curr) => {
    return (
      prev +
      curr.assignees.reduce((prev2, curr2) => {
        return prev2 + (curr2.assignee.isDeleted ? 1 : 0);
      }, 0)
    );
  }, 0);

  flowErrors["invaildRole"] = flow?.stepAssignees
    ?.flatMap((item) => item.assignees)
    ?.reduce((prev, curr) => {
      return prev + (curr?.assignee?.isInvalidRole ? 1 : 0);
    }, 0);

  let errors = {};
  Object.keys(flowErrors).forEach((key) => {
    if (flowErrors[key] > 0) {
      errors[key] = flowErrors[key];
    }
  });

  const walletError = flowErrors["wallet"];
  const expenseCategoryErrors = flowErrors["expenseCategory"];
  const approverErrors = flowErrors["approvers"];
  const invaildRoleErrors = flowErrors["invaildRole"];

  let errorVerb = "";
  let errorActionMessage = "Please edit this Approval Flow.";

  if (invaildRoleErrors) {
    const conditionErrors = walletError + expenseCategoryErrors + approverErrors;
    if (conditionErrors > 1) {
      let errorVerb = conditionErrors > 1 ? "are" : "is";
      let errorLabel = ` ${plural(conditionErrors, "condition")} `;
      errorMessage.push(errorLabel + " " + errorVerb + " no longer active, and");
    }
    errorMessage.push(plural(invaildRoleErrors, "approver") + " lost their admin privileges.");
    errorMessage.push(errorActionMessage);
    return errorMessage.join(" ");
  } else if ((approverErrors && (walletError || expenseCategoryErrors)) || (walletError && expenseCategoryErrors)) {
    const conditionErrors = walletError + expenseCategoryErrors;
    errorVerb = conditionErrors > 1 ? "are" : "is";
    let errorLabel = conditionErrors > 1 ? " conditions " : " condition";
    errorMessage.push(conditionErrors + errorLabel);
  } else if (walletError) {
    if (walletError === flow.condition.wallet.length) {
      errorVerb = "are";
      errorMessage.push("All Budgets");
    } else {
      errorVerb = walletError > 1 ? " are " : " is";
      let errorLabel = walletError > 1 ? " Budgets " : " Budget";
      errorMessage.push(walletError + errorLabel);
      errorActionMessage = "Current requests containing any valid Budgets below will still follow this flow.";
    }
  } else if (expenseCategoryErrors) {
    if (expenseCategoryErrors === flow.condition.expenseCategory.length) {
      errorMessage.push("All Categories");
      errorVerb = "are";
    } else {
      errorMessage.push(expenseCategoryErrors + ` Expense Categor${expenseCategoryErrors == 1 ? "y" : "ies"}`);
      errorActionMessage = "Current requests containing any valid Expense Category below will still follow this flow.";
      errorVerb = expenseCategoryErrors > 1 ? "are" : "is";
    }
  }

  if (approverErrors) {
    let errorLabel = approverErrors > 1 ? " approvers" : " approver";
    errorMessage.push(approverErrors + errorLabel);
  }

  if (approverErrors > 1) {
    errorVerb = "are";
  }

  if (errorMessage.length > 1) {
    errorVerb = "are";
  }

  errorMessage = [errorMessage.join(" and ")];
  errorMessage.push(errorVerb);
  errorMessage.push("no longer active.");
  errorMessage.push(errorActionMessage);
  return errorMessage.join(" ");
};
export interface ApprovalFlowTabs {
  title: string;
  tabKey: string;
  content: (tab: ListData) => React.ReactNode;
}

export interface ChangeLogHistoryTabs extends SelfApprovalTabs {}

export const getApprovalFlowTabs = (
  setShowSelfApprovalPanel?: React.Dispatch<React.SetStateAction<boolean>>
): ApprovalFlowTabs[] => [
  {
    title: TABS.BILL_PAYMENT,
    tabKey: "invoice",
    content: (tab: ListData) => {
      return <ApprovalFlowList setShowSelfApprovalPanel={setShowSelfApprovalPanel} />;
    },
  },
  {
    title: TABS.REIMBURSEMENT,
    tabKey: "reimbursement",
    content: (tab: ListData) => {
      return <ApprovalFlowList setShowSelfApprovalPanel={setShowSelfApprovalPanel} />;
    },
  },
];

export interface ValuesInterface {
  role: string;
  identifier: string;
  assignee: {
    id: string;
    name: string;
    role: string;
    error: boolean;
  };
}

export interface ResetDefault {
  [TAB_KEY.INVOICE]: boolean;
  [TAB_KEY.REIMBURSEMENT]: boolean;
}

export interface ContentParams {
  setShowConfirmSelfApprovalCloseModal: (val: boolean) => void;
  values: Array<ValuesInterface>;
  setValues: (val: Array<ValuesInterface>) => void;
  hasChangedSelfApprovalList: () => boolean;
  savePolicy: () => void;
  setResetDefault: (obj: ResetDefault) => void;
  resetDefault: ResetDefault;
  isLoading: boolean;
  setisDiscardButtonClicked: (val: boolean) => void;
  activeKey: string;
}
export interface SelfApprovalTabs {
  title: string;
  tabKey: string;
  content: (tab: ContentParams) => React.ReactNode;
}

export const getSelfApprovalPolicyTabs = (
  isBillPayEnabled: boolean,
  isReimbursementEnabled: boolean
): SelfApprovalTabs[] => {
  return [
    {
      title: TABS.BILL_PAYMENT,
      tabKey: TAB_KEY.INVOICE,
      enabled: isBillPayEnabled,
      content: (tab: ContentParams) => {
        return (
          <PolicyContent
            setShowConfirmSelfApprovalCloseModal={tab.setShowConfirmSelfApprovalCloseModal}
            values={tab.values}
            setValues={tab.setValues}
            hasChangedSelfApprovalList={tab.hasChangedSelfApprovalList}
            savePolicy={tab.savePolicy}
            setResetDefault={tab.setResetDefault}
            resetDefault={tab.resetDefault}
            isLoading={tab.isLoading}
            setisDiscardButtonClicked={tab.setisDiscardButtonClicked}
            activeKey={tab.activeKey}
          />
        );
      },
    },
    {
      title: TABS.REIMBURSEMENT,
      tabKey: TAB_KEY.REIMBURSEMENT,
      enabled: isReimbursementEnabled,
      content: (tab: ContentParams) => {
        return (
          <PolicyContent
            setShowConfirmSelfApprovalCloseModal={tab.setShowConfirmSelfApprovalCloseModal}
            values={tab.values}
            setValues={tab.setValues}
            hasChangedSelfApprovalList={tab.hasChangedSelfApprovalList}
            savePolicy={tab.savePolicy}
            setResetDefault={tab.setResetDefault}
            resetDefault={tab.resetDefault}
            isLoading={tab.isLoading}
            setisDiscardButtonClicked={tab.setisDiscardButtonClicked}
            activeKey={tab.activeKey}
          />
        );
      },
    },
  ].filter((tab) => tab.enabled);
};

export const getChangelogHistoryTabs = (
  isBillPayEnabled: boolean,
  isReimbursementEnabled: boolean
): ChangeLogHistoryTabs[] => {
  return [
    {
      title: TABS.BILL_PAYMENT,
      tabKey: "invoice",
      enabled: isBillPayEnabled,
      content: (tab: ContentParams) => {
        return <ChangeLogHistory type={TAB_KEY.INVOICE} />;
      },
    },
    {
      title: TABS.REIMBURSEMENT,
      tabKey: "reimbursement",
      enabled: isReimbursementEnabled,
      content: (tab: ContentParams) => {
        return <ChangeLogHistory type={TAB_KEY.REIMBURSEMENT} />;
      },
    },
  ].filter((tab) => tab.enabled);
};

export const getLabelForFlowTitle = (processType, action) => {
  let flowType = processType === "invoice" ? "Bill Payment" : "Reimbursement";
  let actionType = action === "edit" ? "Edit" : "Create";
  let label = `${actionType} a ${flowType} Approval Flow`;
  return label;
};

export const getEventType = (processType: string) => {
  if (processType === "reimbursement") {
    return APPROVAL_FLOW_EVENT_TYPE.Reimbursemnt;
  } else if (processType === "invoice") {
    return APPROVAL_FLOW_EVENT_TYPE.Bills;
  }
};

export const isNewFlow = (id: string): boolean => {
  return ["reimbursement", "invoice"].includes(id);
};

export const handleFlowSettingsUpdatedEvent = (eventSource, isDefault, id, processType) => {
  const eventName = isNewFlow(id)
    ? APPROVAL_FLOW_EVENTS.approvalFlowNewSettingsEdited
    : APPROVAL_FLOW_EVENTS.approvalFlowExistingSettingsEdited;
  const eventData = {
    ...(!isNewFlow(id) && { is_default_flow: isDefault, approval_flow_id: id }),
    ...{ approval_flow_type: getEventType(processType), approval_flow_event_source: eventSource },
  };

  trackEvent(eventName, eventData);
};
