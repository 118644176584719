import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { getCardNumbersFunc, getCardOwnerNamesFunc, resetCreateCardData } from "Redux/Actions";
import { useCardListContext } from "Views/Card/List/index.context";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import { CARD_LIST_TABS } from "../types";
import { showErrorNotification, showSuccessNotification } from "Views/Card/Components/Notification";

export interface IToaster {
  message: string;
  status: string;
  isPrimary: boolean;
  visible: boolean;
}

const CardListNotifications = () => {
  const dispatch = useDispatch();
  const createCardData = useSelector((state: any) => state?.createCardReducer || {});
  const { changeCardListActiveTab } = useCardListContext();

  React.useEffect(() => {
    if (createCardData?.data?.payload) {
      const { data } = createCardData;

      if (data?.payload?.status === HTTP_STATUS_CODE.OK) {
        let message = {
          physical: "You have successfully requested a physical card!",
          virtual: "You have successfully created a virtual card!",
          physicalSync: "You have successfully synced a physical card!",
        };
        let statusText = message[data?.payload?.type] || "";
        changeCardListActiveTab(CARD_LIST_TABS.CARD_LIST);
        dispatch(getCardNumbersFunc());
        dispatch(getCardOwnerNamesFunc());
        showSuccessNotification(statusText);
      } else {
        const errorMessage = data?.payload?.payload?.status_message || "Something went wrong, Please try again later.";
        showErrorNotification(errorMessage);
      }
      dispatch(resetCreateCardData());
    }
  }, [createCardData]);

  return null;
};

export default CardListNotifications;
