import React, { useState } from "react";
import classNames from "classnames";
import { Button, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import { PrimaryOrSecondary } from "Modules/DS/DataTable";

import styles from "./defaultActions.module.scss";

interface DefaultActionsProps {
  firstButton: PrimaryOrSecondary;
  secondButton: PrimaryOrSecondary;
}

export const DefaultActions: React.FC<DefaultActionsProps> = ({
  firstButton,
  secondButton,
}: DefaultActionsProps): JSX.Element => {
  const loadingSpinner = <LoadingOutlined spin />;

  const [isSubmitFirstButton, setIsSubmitFirstButton] = useState(false);
  const [isSubmitSecondButton, setIsSubmitSecondButton] = useState(false);

  const handleFirstButton = async () => {
    if (isSubmitFirstButton) {
      return;
    }
    setIsSubmitFirstButton(true);
    const response = await firstButton.handler();
    if (response) {
      setIsSubmitFirstButton(false);
    }
  };

  const handleSecondButton = async () => {
    if (isSubmitSecondButton) {
      return;
    }
    setIsSubmitSecondButton(true);
    const response = await secondButton.handler();
    if (response) {
      setIsSubmitSecondButton(false);
    }
  };

  return (
    <>
      <div className={styles["default-actions"]}>
        <Button
          className={classNames({
            [styles[`default-actions__button--${firstButton.type}`]]: !firstButton.disabled,
            [styles[`default-actions__button--disabled`]]: firstButton.disabled,
          })}
          onClick={(e) => {
            e.stopPropagation();
            handleFirstButton();
          }}
        >
          {isSubmitFirstButton ? <Spin indicator={loadingSpinner} /> : `${firstButton.label}`}
        </Button>
        {Boolean(secondButton) && (
          <Button
            className={styles[`default-actions__button--${secondButton.type}`]}
            onClick={(e) => {
              e.stopPropagation();
              handleSecondButton();
            }}
          >
            {isSubmitSecondButton ? <Spin indicator={loadingSpinner} /> : secondButton.label}
          </Button>
        )}
      </div>
    </>
  );
};
