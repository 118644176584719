import React from "react";
import dayjs from "dayjs";
import qs from "query-string";
import { Base64 } from "js-base64";
import { IModalProps } from "@spenmo/splice/lib/components/Modal";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { Banner, IllReimbursements, InfoFilled, Modal, Typography } from "@spenmo/splice";

import { ILocationParams, ILocationQueryParams } from "Views/Reimbursement/@types";
import { REIMBURSEMENTS_PAGE_ANALYTICS, trackReimbursementAnalytics } from "Views/Reimbursement/Analytics";
import {
  REIMBURSEMENT_MODAL_OPTIONS,
  REIMBURSEMENT_PAGE_OPERATIONS,
  REIMBURSEMENT_QUERY_PARAMS,
} from "Views/Reimbursement/Constants";

import ModalSkeleton from "Views/Reimbursement/Modal/Skeleton";
import useMakePayment from "Views/Reimbursement/hooks/useMakePayment";
import { useFetchCRState } from "Views/Reimbursement/Settings/useCRState";

import styles from "./index.module.scss";

const Container = ({ isEnabled, date }: { isEnabled: boolean; date: string }) => {
  const location = useLocation();

  const { payoutValue = "", payoutUser = "" }: ILocationQueryParams = qs.parse(location.search, {
    parseNumbers: true,
    parseBooleans: true,
  });

  const renderBanner = () => {
    return isEnabled ? (
      <Banner
        variant="neutral"
        icon={InfoFilled}
        description={`If you can wait until the next settlement cycle set on the ${dayjs(date).format(
          "DD MMM YYYY"
        )}, this payment will be made automatically then without any fees.`}
      />
    ) : null;
  };

  return (
    <div className={styles.container}>
      <div className={styles.icon}>
        <IllReimbursements size={120} />
      </div>
      <Typography size="m" variant="body-content" tag="p" className={styles.content}>
        You are about to make an ad-hoc payment of <strong>{Base64.decode(payoutValue)}</strong> to{" "}
        {Base64.decode(payoutUser)}. A transfer fee of <strong>SGD 0.50</strong> will be charged to your Spenmo account.
      </Typography>
      {renderBanner()}
    </div>
  );
};

const MakePayment = () => {
  const history = useHistory();
  const location = useLocation();

  const { params } = useRouteMatch<ILocationParams>();

  const { operation = "", id = "", secondaryOperation = "", secondaryId = "", tertiaryOperation = "" } = params;

  const query: ILocationQueryParams = qs.parse(location.search, {
    parseNumbers: true,
    parseBooleans: true,
  });

  const { makePayment } = useMakePayment();

  const getPath = () => {
    let pathname = undefined;

    switch (true) {
      case operation === REIMBURSEMENT_PAGE_OPERATIONS.READ &&
        secondaryOperation === REIMBURSEMENT_PAGE_OPERATIONS.MODAL:
        pathname = `/${operation}/${id}/${REIMBURSEMENT_PAGE_OPERATIONS.MODAL}/${REIMBURSEMENT_MODAL_OPTIONS.MAKE_PAYMENT}`;
        break;
      case operation !== REIMBURSEMENT_PAGE_OPERATIONS.READ &&
        secondaryOperation === REIMBURSEMENT_PAGE_OPERATIONS.READ &&
        tertiaryOperation === REIMBURSEMENT_PAGE_OPERATIONS.MODAL:
        pathname = `/${secondaryOperation}/${secondaryId}/${REIMBURSEMENT_PAGE_OPERATIONS.MODAL}/${REIMBURSEMENT_MODAL_OPTIONS.MAKE_PAYMENT}`;
        break;
      default:
        pathname = `/${REIMBURSEMENT_PAGE_OPERATIONS.MODAL}/${REIMBURSEMENT_MODAL_OPTIONS.MAKE_PAYMENT}`;
        break;
    }
    return pathname;
  };

  const handleOnClose = () => {
    history.push({
      pathname: location.pathname.replace(getPath(), ""),
      search: qs.stringify({
        ...query,
        [REIMBURSEMENT_QUERY_PARAMS.PAYOUT_VALUE]: undefined,
        [REIMBURSEMENT_QUERY_PARAMS.PAYOUT_USER]: undefined,
        [REIMBURSEMENT_QUERY_PARAMS.PAGE]: 1,
      }),
    });
  };

  const { fetchState } = useFetchCRState();
  const { isLoading, isValidating, data } = fetchState;

  const isSettlementEnabled: boolean = data?.data?.payload?.isEnabled;
  const nextSettlementDate: string = data?.data?.payload?.nextSettlementDate;

  const eventName = isSettlementEnabled
    ? REIMBURSEMENTS_PAGE_ANALYTICS.REIMBURSEMENT_DETAILS_SETTLEMENT_ON
    : REIMBURSEMENTS_PAGE_ANALYTICS.REIMBURSEMENT_DETAILS_SETTLEMENT_OFF;

  const onCancelMakePayment = () => {
    history.action === "PUSH" ? history.goBack() : handleOnClose();
    trackReimbursementAnalytics({
      eventName,
      eventSource: "Make Payment",
      eventSubSource: "Cancel",
    });
  };

  const getId = () => {
    let settlementId = undefined;
    switch (true) {
      case operation === REIMBURSEMENT_PAGE_OPERATIONS.READ &&
        secondaryOperation === REIMBURSEMENT_PAGE_OPERATIONS.MODAL:
        settlementId = id;
        break;
      case operation === REIMBURSEMENT_PAGE_OPERATIONS.SETTLEMENTS &&
        secondaryOperation === REIMBURSEMENT_PAGE_OPERATIONS.READ &&
        tertiaryOperation === REIMBURSEMENT_PAGE_OPERATIONS.MODAL:
        settlementId = secondaryId;
        break;
      default:
        settlementId = "";
        break;
    }
    return settlementId;
  };

  const onConfirmMakePayment = () => {
    makePayment(getId())
      .then(() => {
        trackReimbursementAnalytics({
          eventName,
          eventSource: "Make Payment",
          eventSubSource: "Confirm",
        });
      })
      .finally(() => {
        handleOnClose();
      });
  };

  const props: IModalProps = {
    showModal: true,
    size: "m",
    title: `Make Payment`,
    onClose: () => (history.action === "PUSH" ? history.goBack() : handleOnClose()),
    children:
      isLoading || isValidating ? (
        <ModalSkeleton />
      ) : (
        <Container isEnabled={isSettlementEnabled} date={nextSettlementDate} />
      ),
    footerFullWidthButtons: true,
    primaryActionButton: {
      title: "Confirm",
      action: onConfirmMakePayment,
    },
    secondaryActionButton: {
      title: "Cancel",
      action: onCancelMakePayment,
    },
  };

  return <Modal {...props} />;
};

export default MakePayment;
