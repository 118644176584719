import * as React from "react";

import { debounce } from "utility";
import { useDispatch } from "react-redux";

import Searchbar from "Modules/search";
import { searchTeamMember } from "Redux/Actions/Teams/teamMemberSearch";

import styles from "./styles.module.scss";

const DEBOUNCE_DELAY_TIME = 500;
export const MEMBER_INITIAL_PAGE_NUMBER = 0;
const SearchMember = (props) => {
  const dispatch = useDispatch();
  const { setIsMemberSearch, teamId } = props;
  const handleSearch = debounce((value: string) => {
    setIsMemberSearch(value);
    dispatch(searchTeamMember(teamId, value, MEMBER_INITIAL_PAGE_NUMBER));
  }, DEBOUNCE_DELAY_TIME);

  return <Searchbar getKeyUpValue={handleSearch} placeholder="Search Member" className={styles.margin} />;
};

export default SearchMember;
