import { GetTransactions } from "./Transactions.api";
import { GetAllUsers } from "./Users.api";
import { GetAllRequests, GetAllScheduledSends } from "./Payments.api";
import { GetAllApprovals } from "./Approvals.api";
import { GetAllReimbursements } from "./Reimbursements.api";
import { GetReceiptData } from "./Receipts.api";
import { GetAllCards, GetAllCardsRequests } from "./Cards.api";
import { GetCategories } from "./Categories.api";
import { GetAllBanks } from "./Banks.api";
import { GetAllInvoices, GetSingleInvoice } from "./Invoices.api";

const mappings = {
  getTransactions: GetTransactions,
  getUsers: GetAllUsers,
  getAllRequests: GetAllRequests,
  getAllScheduledSends: GetAllScheduledSends,
  getSingleInvoice: GetSingleInvoice,
  getAllCards: GetAllCards,
  getReceiptData: GetReceiptData,
  getAllApprovals: GetAllApprovals,
  getAllReimbursements: GetAllReimbursements,
  getCategories: GetCategories,
  getAllBanks: GetAllBanks,
  getAllInvoices: GetAllInvoices,
  GetAllCardsRequests: GetAllCardsRequests,
};
export default mappings;
