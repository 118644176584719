import React from "react";
import styles from "./index.module.scss";
import RedirectionButton from "../../RedirectionButton";
import { IRemarks, IFormLevelRemarks } from "Views/KYX/@types";

const FormLevelRemarks: React.FC<IFormLevelRemarks> = ({ details, info }: IFormLevelRemarks): JSX.Element => {
  const setRemarksReason = (reason: Array<string> | string): JSX.Element | JSX.Element[] => {
    if (Array.isArray(reason)) {
      return reason.map((item) => <p className={styles.message}>{item}</p>);
    }
    return <p className={styles.message}>{reason}</p>;
  };

  const handleRemarksReasons = (reasons: IRemarks): JSX.Element[] => {
    return (
      Array.isArray(reasons) &&
      reasons.map((reason, index) => (
        <div key={index}>
          <h4 className={styles.subTitle}>{reason?.field_label}</h4>
          {setRemarksReason(reason?.field_remarks)}
        </div>
      ))
    );
  };

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>{details?.title}</h2>

      {handleRemarksReasons(details?.reasons)}
      {details?.general_remark && (
        <div>
          <h4 className={styles.subTitle}>General Remarks</h4>
          {setRemarksReason(details?.general_remark)}
        </div>
      )}
      {info?.button && <RedirectionButton details={info?.button} />}
    </div>
  );
};

export default FormLevelRemarks;
