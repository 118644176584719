import APIclient from "API/Client";
import { source } from "../index";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import { GetOrgId } from "utility";
import { getTeamDatilsById } from "Redux/Actions/Teams/teamDetailsAction";
import { trackEvent } from "utility/analytics";

export const FETCH_TEAMS_LIST = "FETCH_TEAMS_LIST";
export const FETCH_TEAMS_LIST_REQUEST = `${FETCH_TEAMS_LIST}_REQUEST`;
export const FETCH_TEAMS_LIST_SUCCESS = `${FETCH_TEAMS_LIST}_SUCCESS`;
export const FETCH_TEAMS_LIST_FAILURE = `${FETCH_TEAMS_LIST}_FAILURE`;
export const RESET_TEAMS_DATA = `RESET_TEAMS_DATA`;
export const UPDATE_TEAM_DATA = "UPDATE_TEAM_DATA";
export const UPDATE_WALlET = "UPDATE_WALlET"; //need to remove
export const UPDATE_USER_ROLE_MEMBERSHIP = "UPDATE_USER_ROLE_MEMBERSHIP"; //need to remove

export const fetchDataRequest = () => {
  return {
    type: "FETCH_TEAMS_LIST_REQUEST",
  };
};

export const fetchDataSuccess = (data) => {
  return {
    type: "FETCH_TEAMS_LIST_SUCCESS",
    data: data,
  };
};

export const fetchDataError = (data) => {
  return {
    type: "FETCH_TEAMS_LIST_FAILURE",
    data: data,
  };
};

const updateTeam = (teamId = "") => {
  return {
    type: "UPDATE_TEAM_DATA",
    data: teamId,
  };
};

export const resetTeamListData = () => ({
  type: RESET_TEAMS_DATA,
});
//global teamlist action
//by calling this api global team list will update and selected team will be first team from the list.
//if you want to refresh team list with already selected team use update
export const getTeamList = () => {
  return (dispatch) => {
    dispatch(fetchDataRequest());
    APIclient.getData(
      `/api/v1/org/${GetOrgId()}/team-list?pg=0&limit=100&type=all&with_members=1`,
    )
      .then((data) => {
        if (data?.data?.status === HTTP_STATUS_CODE.FORBIDDEN) {
          source.cancel("Operation canceled for error");
        } else if (data.data.status > HTTP_STATUS_CODE.OK) {
          source.cancel("Operation canceled for error");
        } else {
          return data;
        }
      })
      .then((data) => {
        const { teams } = data.data.payload;
        dispatch(updateTeam(teams[0].id));
        dispatch(fetchDataSuccess(data.data));
        /*
          Reason to comment: dispatch(getTeamDatilsById(teams[0].id))

          In the initial version of the dashboard, we used to set the default team on the homepage,
          and the balance and other details were displayed based on that selection.
          The API endpoint called for setting the default team and related details was: /api/v1/org/${GetOrgId()}/team/${teamId}
          However, as per the VAPT (Vulnerability Assessment and Penetration Testing) findings, certain sensitive
          information exposed via this API should only be accessible to users with admin privileges. Roles such as
          accountant, bookkeeper, and others should not have access to this data.
        */

        dispatch(getTeamDatilsById(teams[0].id));
      })
      .catch((err) => {
        dispatch(fetchDataError(err));
      });
  };
};
