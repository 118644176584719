import { FormInstance } from "antd/lib/form";
import { DynamicFormField, DynamicFormFieldType } from "Modules/DynamicForm/types";
import React from "react";
import { OCRData } from "Views/UploadInvoice/InvoiceForm/VendorSection/types";
import { BANK_NAME_ALIAS } from "Views/UploadInvoice/const";
import DynamicFormCheckbox from "../Checkbox";
import DynamicFormBankName from "../Custom/BankName";
import BankValidation from "../Custom/BankValidation";
import DynamicFormSwiftCode from "../Custom/SwiftCode";
import DynamicFormIban from "../Custom/Iban";
import DynamicFormBsb from "../Custom/Bsb";
import DynamicFormMobileNumber from "../Custom/MobileNumber";
import DynamicFormDropdown from "../Dropdown";
import DynamicFormRadio from "../Radio";
import DynamicFormText from "../Text";
import DynamicFormTextarea from "../Textarea";
import DynamicFormTextfield from "../Textfield";
import DynamicFormEmailTagInput from "../Custom/EmailTagInput";
import DynamicFormTagInput from "../TagInput";
import { generateOCRLabel, autoChainOCR } from "./dynamicOCR";
import WithholdingTaxCategory from "../Custom/WithholdingTaxCategory";
import RegiesteredEntityName from "../Custom/RegisteredEntityName";

export const generateField =
  (
    onFetchValue: (fieldID: number, value: string, autoChain?: boolean) => void,
    onChangeValue: (fieldID: number, value: string) => void,
    form: FormInstance,
    ocrData?: OCRData
  ) =>
  (field: DynamicFormField) => {
    const isTextFieldType = [DynamicFormFieldType.textfield, DynamicFormFieldType.textarea].includes(field.type);
    const onChangeValueHandler = field.fieldProps.doFetch && !isTextFieldType ? onFetchValue : onChangeValue;
    const props = {
      ...field,
      onChangeValue: onChangeValueHandler,
      labelField: generateOCRLabel(form, field, ocrData),
      form,
    };

    autoChainOCR(form, field, onFetchValue, ocrData);

    if (field.fieldProps.inBSS.hide) {
      return null;
    }

    switch (field.alias) {
      case BANK_NAME_ALIAS:
        return <DynamicFormBankName {...props} />;

      case "swiftCode":
        return <DynamicFormSwiftCode {...props} />;

      case "ibanCode":
        return <DynamicFormIban {...props} />;

      case "bsbCode":
        return <DynamicFormBsb {...props} />;

      case "achCode":
      case "ifscCode":
        return <BankValidation {...props} />;
      case "mobileNumber":
        return <DynamicFormMobileNumber {...props} />;
      case "beneficiaryATCTaxCategory":
        return <WithholdingTaxCategory {...props} />;
      case "beneficiaryRegisteredEntityName":
        return <RegiesteredEntityName {...props} />;
      case "recipientEmail": {
        return <DynamicFormEmailTagInput {...props} />;
      }
    }

    switch (field.type) {
      case DynamicFormFieldType.checkbox:
        return <DynamicFormCheckbox {...props} />;

      case DynamicFormFieldType.dropdown:
        return <DynamicFormDropdown {...props} />;

      case DynamicFormFieldType.option:
        return <DynamicFormRadio {...props} />;

      case DynamicFormFieldType.text:
        return <DynamicFormText {...props} />;

      case DynamicFormFieldType.textarea:
        return <DynamicFormTextarea {...props} />;

      case DynamicFormFieldType.textfield:
        return <DynamicFormTextfield {...props} />;

      case DynamicFormFieldType.tagInput: {
        return <DynamicFormTagInput {...props} />;
      }
      default:
        return null;
    }
  };
