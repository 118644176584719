import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Modal from "Modules/modal";
import Button from "Modules/button";
import SelectMembers from "Modules/SelectMembers";
import { addTeamMember, resetAddTeamData } from "Redux/Actions/Teams/addTeamMemberAction";
import { useDispatch } from "react-redux";
import { trackActionsOnTeamsPage, TeamProfileEventsMapping } from "Views/Team/Profile/TeamProfileEventsUtilites";

const TeamMemberModal = (props) => {
  const dispatch = useDispatch();
  const { isModalVisible, employeeData = [], setModalVisibility, teamDetails, addMemberLoading } = props;
  const { members: team_members } = teamDetails || [];
  const [selectedTeamMember, setSelectedTeamMember] = useState([]);

  const notAddedEmployee = employeeData?.filter(function (data) {
    return !team_members?.some(function (team) {
      return data?.id === team?.user?.id;
    });
  });

  useEffect(() => {
    return () => dispatch(resetAddTeamData());
  }, [dispatch]);

  const addMembersToTeam = () => {
    const requestBody = {
      user_ids: selectedTeamMember.map((e) => e.id),
    };
    const teamId = teamDetails?.id;
    dispatch(addTeamMember(requestBody, teamId));
    trackActionsOnTeamsPage(TeamProfileEventsMapping.membersSelected);
  };

  const selecteTeamMemberToAdd = (data) => {
    setSelectedTeamMember(data);
  };

  return (
    <div className={"add-team-members"}>
      <Modal
        subtitle={"Budget Members are users who can spend from this budget."}
        show={isModalVisible}
        title={"Add members"}
        className={"add-team-members__modal-existing-members"}
        close={() => setModalVisibility(false)}
      >
        <SelectMembers
          allEmployees={notAddedEmployee || []}
          checkDuplicates
          receivedTeamMembers={[]}
          onChange={(data) => selecteTeamMemberToAdd(data)}
          isTeam={false}
        />

        <div className={"add-team-members__footer"}>
          <Button
            className={"add-team-members__confirm-btn"}
            text={"Confirm"}
            action={() => addMembersToTeam()}
            secondary
            rounded
            disabled={addMemberLoading}
          />
        </div>
      </Modal>
    </div>
  );
};
TeamMemberModal.propTypes = {
  isModalVisible: PropTypes.bool,
  employeeData: PropTypes.array,
  setModalVisibility: PropTypes.func,
  teamDetails: PropTypes.object,
  addMemberLoading: PropTypes.bool,
};
export default TeamMemberModal;
