import * as React from "react";
import { ICardDetails, CARD_LIMIT_TYPES } from "Views/Card/types";
import { currencyFormatterWithFractions } from "utility";
import { getCookieValue, cookieNames } from "utility/CookieHelper";
import { CARD_LIMITS_LABELS } from "Views/Card/Components/SpendingLimit/index.constants";
import { CARD_SETTING_LABEL } from "constants/Card";

export const getCardLimit = (cardLimitType: CARD_LIMIT_TYPES, cardLimit: string) => {
  const orgCurrencyCode = getCookieValue(cookieNames.CURRENCY_CODE);

  if (cardLimitType !== CARD_LIMIT_TYPES.unlimited) {
    return (
      <>
        {currencyFormatterWithFractions(cardLimit, orgCurrencyCode, 2, false)}{" "}
        <span className="request-review__column-data--small">{CARD_LIMITS_LABELS[cardLimitType] || ""}</span>
      </>
    );
  } else {
    return (cardLimitType && CARD_LIMITS_LABELS[cardLimitType]) || "";
  }
};

export const CardRequestInfoToRenderConfig = (cardData: ICardDetails) => {
  const {
    cardHolder,
    team,
    spendingLocks,
    expenseCategoryName,
    spendingLimit,
    spendingLimitType,
    letMyManagerChooseLimit,
  } = cardData;
  const { SPENDING_LIMIT, DEFAULT_EXPENSE_CATEGORY } = CARD_SETTING_LABEL;
  const cardSettingsRenderConfig = [
    {
      label: "CARDHOLDER",
      value: cardHolder,
      show: true,
    },
    {
      label: "TEAM",
      value: team,
      show: true,
    },
    {
      label: SPENDING_LIMIT,
      value: letMyManagerChooseLimit ? "Not added" : getCardLimit(spendingLimitType, spendingLimit),
      show: true,
    },
    {
      label: "SPENDING LOCK",
      value: spendingLocks?.map((merchantData) => merchantData?.name)?.join(", ") || "None",
      show: true,
    },
    {
      label: DEFAULT_EXPENSE_CATEGORY,
      value: expenseCategoryName || "N/A",
      show: true,
    },
  ];
  return cardSettingsRenderConfig.filter((item) => item.show);
};
