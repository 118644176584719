import React from "react";
import moment from "moment";
import { Tooltip } from "antd";

import Icon from "Modules/icons";
import ImageCarousel from "Modules/ImageCarousel";
import LabelWithTooltip from "Modules/LabelWithTooltip";

import { receiptsGreyIcon, blueInfoCircle } from "assets/img";

import { currencyFormatter, dateTimeFormatter, currencyFormatterWithFractions } from "utility";

import SyncStatus from "./SyncStatus";
import { getMerchantName } from "./TransactionsHelper";
import { DynamicSLALabel } from "Modules/DynamicSLA";

import Chip from "Modules/DS/Chip";
import { STATUS_COLOR_MAP } from "./helper";

const checkStatus = (status, approval) => {
  switch (true) {
    case status === "waiting_approval":
      return { label: "Waiting for approval", color: "light-orange" };
    case status === "pending" && approval === "1":
      return { label: "Approved", color: "green" };
    case status === "pending":
      return { label: "Invoice Processed", color: "purple" };
    case status === "settled":
      return { label: "Sending Payment", color: "blue" };
    case status === "completed":
      return { label: "Payment Sent", color: "blue" };
    case status === "received":
      return { label: "Received", color: "green" };
    case status === "failed":
      return { label: "Failed", color: "red" };
    case status === "rejected" && approval === "0":
      return { label: "Declined", color: "red" };
    case status === "rejected":
      return { label: "Cancelled", color: "red" };
    case status === "reversed":
      return { label: "Reversed", color: "purple" };
    default:
      return { label: "-", color: "grey" };
  }
};

export const columnData = (isAdminOrAccountant, newTransactionTypeActive, connectedToXero) =>
  [
    {
      title: "Date & Time",
      dataIndex: "created_at",
      key: "created_at",
      width: 80,
      enabled: true,
      render: (timestamp) => (
        <p>
          {dateTimeFormatter(timestamp).date} <br /> {dateTimeFormatter(timestamp).time}
        </p>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: 130,
      enabled: true,
      ellipsis: { showTitle: false },
      render: (des) => (
        <Tooltip title={des} trigger="hover" placement="bottomLeft" overlayClassName="transaction-description-tooltip">
          <div className="transaction-description">{des}</div>
        </Tooltip>
      ),
    },
    {
      title: "Budget",
      dataIndex: "team",
      key: "team",
      width: 80,
      enabled: true,
      render: (team) => (team ? team.team_name : "N/A"),
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      width: 80,
      enabled: true,
      render: (cat) => (cat ? cat.expense_category_name : "N/A"),
    },
    {
      title: "Transaction Type",
      dataIndex: newTransactionTypeActive ? "transaction_type" : "type",
      key: "type",
      width: 110,
      enabled: true,
    },
    {
      title: "Merchant",
      dataIndex: "simplified_merchant_name",
      key: "simplified_merchant_name",
      width: 100,
      enabled: true,
      render: (_, record) => getMerchantName(record),
    },
    {
      title: "Receipts",
      dataIndex: "receipts",
      key: "receipts",
      align: "center",
      onCell: (receipts) => {
        if (receipts) {
          return {
            onClick: (event) => event.stopPropagation(),
            onDoubleClick: (event) => event.stopPropagation(),
          };
        }
      },
      render: (receipts, _) =>
        receipts ? (
          <ImageCarousel
            images={receipts.urls}
            count={receipts.count}
            message={receipts.count <= 0 && "No Receipts!"}
            lessMargin
          />
        ) : (
          "N/A"
        ),
      width: 53,
      enabled: true,
    },
    {
      title: "Credit",
      dataIndex: "amount",
      key: "amount",
      render: (elem, obj) => obj.isCredit === "1" && currencyFormatterWithFractions(elem, obj.currency_code, 2, true),
      width: 80,
      align: "center",
      ellipsis: true,
      enabled: true,
    },
    {
      title: "Debit",
      dataIndex: "amount",
      key: "amount",
      render: (elem, obj) => obj.isCredit === "0" && currencyFormatterWithFractions(elem, obj.currency_code, 2, true),
      align: "center",
      width: 80,
      ellipsis: true,
      enabled: true,
    },
    {
      title: (
        <LabelWithTooltip
          label="Company Balance"
          imageSrc={blueInfoCircle}
          altText="Information about Company Balance"
          description="The total amount of alloted and available funds for all the teams within the company."
          overlayInnerStyle={{ color: "white" }}
        />
      ),
      dataIndex: "running_balance",
      key: "running_balance",
      width: 127,
      ellipsis: true,
      enabled: isAdminOrAccountant,
      render: (elem, obj) => elem && currencyFormatter(elem.org_new_balance, obj.currency_code),
    },
    {
      title: "Sync Status",
      dataIndex: "xero_sync_status",
      key: "xero_sync_status",
      enabled: connectedToXero,
      render: (_, obj) => <SyncStatus data={obj} />,
      width: 75,
    },
  ].filter((item) => item.enabled);

export const InvoiceColumnData = [
  {
    title: "Date & Time",
    dataIndex: "created_at",
    key: "created_at",
    render: (timestamp) => (
      <p>
        {dateTimeFormatter(timestamp).date} <br /> {dateTimeFormatter(timestamp).time}
      </p>
    ),
  },
  {
    title: "Merchant Name",
    dataIndex: "receiver_name",
    key: "receiver_name",
    className: "transactions__invoices",
  },
  {
    title: "Invoice Number",
    dataIndex: "invoice_number",
    key: "invoice_number",
  },
  {
    title: "Sender",
    key: "amount",
    dataIndex: "amount",
    render: (data, record) => <p>{currencyFormatter(data, record.currency_code)}</p>,
  },
  {
    title: "Receiver",
    key: "foreign_currency_amount",
    dataIndex: "foreign_currency_amount",
    render: (data, record) => (data ? <p>{currencyFormatter(data, record.foreign_currency_code)}</p> : <p>-</p>),
  },
  {
    title: "Receipts/Invoices",
    dataIndex: "receipts",
    key: "receipts",
    width: 200,
    align: "left",
    onCell: (receipts) => {
      if (receipts) {
        return {
          onClick: (event) => event.stopPropagation(),
          onDoubleClick: (event) => event.stopPropagation(),
        };
      }
    },
    render: (receipts, obj) =>
      receipts ? (
        <ImageCarousel images={receipts.urls} count={receipts.count} message={receipts.count <= 0 && "No Receipts!"} />
      ) : (
        <Icon
          src={receiptsGreyIcon}
          alt={"receipt icon"}
          action={(e) => {
            e.stopPropagation();
          }}
          className="image-carousel--icon"
        />
      ),
  },
  {
    title: "Status",
    dataIndex: "settlement_status",
    key: "settlement_status",
    render: (status, record) => {
      const statusText = checkStatus(status, record.approval_decision);
      return (
        <h4 className="transactions__invoices__status">
          <span
            className={`transactions__invoices__status-color ${`transactions__invoices__status-color--${statusText?.color}`}`}
          />
          {statusText?.label}
        </h4>
      );
    },
  },
];

export const pendingColumnData = [
  {
    title: "Date & Time",
    dataIndex: "createdAt",
    key: "createdAt",
    width: 110,
    render: (data) => (data ? `${dateTimeFormatter(data).date} ${dateTimeFormatter(data).time}` : "-"),
  },
  {
    title: "Recipient Name",
    dataIndex: "beneficiaryName",
    key: "beneficiaryName",
    width: 100,
  },
  {
    title: "Invoice Number",
    dataIndex: "invoiceNumber",
    key: "invoiceNumber",
    width: 200,
  },
  {
    title: "You pay",
    key: "billAmountFeeInclusive",
    dataIndex: "billAmountFeeInclusive",
    width: 100,
    render: (data, record) => currencyFormatter(data, record.walletCurrency),
  },
  {
    title: "Recipient gets",
    key: "amount",
    dataIndex: "amount",
    width: 100,
    render: (data, record) => currencyFormatter(data, record.currency),
  },
  {
    title: "Receipts/Invoices",
    dataIndex: "countReceipts",
    key: "countReceipts",
    width: 105,
    align: "left",
    onCell: () => {
      return {
        onClick: (event) => event.stopPropagation(),
        onDoubleClick: (event) => event.stopPropagation(),
      };
    },
    render: (_, record) => {
      const imagesList = [];
      if (record.receiptsURL?.length) imagesList.push(...record.receiptsURL);
      if (record.attachmentsURL?.length) imagesList.push(...record.attachmentsURL);

      const totalCount = record.countReceipts + record.countAttachments;

      return <ImageCarousel images={imagesList} count={totalCount} message="No Receipts/Invoices!" />;
    },
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    width: 90,
    render: (data) => {
      return (
        <div className="transactions__invoices__status">
          <Chip.Status title={data?.label} status={STATUS_COLOR_MAP[data?.color]} />
        </div>
      );
    },
  },
  {
    title: (
      <DynamicSLALabel
        tooltipArrowAlignment="right"
        tooltipMessage={`Estimated Arrival is calculated from the time payment status changes to "Sending Payment". The dates provided do not factor in any public holidays of the recipient country.`}
      />
    ),
    dataIndex: "SLA",
    key: "SLA",
    width: 150,
  },
];

export const approvalColumnData = (renderAction) => [
  ...pendingColumnData,
  {
    title: "Actions",
    key: "actions",
    dataIndex: "actions",
    fixed: "right",
    width: 300,
    className: "transactions__actions-column",
    render: renderAction,
  },
];

export const scheduledColumnData = (renderAction) => [
  {
    title: "Select All",
    dataIndex: "senderName",
    key: "senderName",
    className: "transactions__item--big",
  },
  {
    render: () => <h2>→</h2>,
    key: "arrow",
  },
  {
    dataIndex: "receiverName",
    key: "receiverName",
    className: "transactions__item--big",
  },
  {
    dataIndex: "receiverTeamName",
    key: "receiverTeamName",
    className: "transactions__item--big",
  },
  {
    dataIndex: "amount",
    render: (amount, record) => currencyFormatter(amount, record.currency),
    key: "amount",
  },
  {
    dataIndex: "schedule",
    render: (data) => moment(data?.nextDate?.replace?.("Z00:00", "Z"))?.format("DD MMM YYYY - HH:mm"),
    key: "nextOccurence",
  },
  {
    key: "actions",
    dataIndex: "id",
    width: 300,
    render: renderAction,
  },
];
