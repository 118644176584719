import { useContext, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { cookieNames, getCookieValue } from "utility/CookieHelper";
import { COMPANY_VERIFICATION } from "Route/KYX/paths";
import { KYB_STATUS, KYX_MODAL_TYPES, PROGRESS_FOOTER_ACTION, PROGRESS_TABS } from "Views/KYX/Constants";
import { KYXModalContext } from "Views/KYX/Context";

export const useStepChangeHandler = () => {
  const location = useLocation();
  const history = useHistory();
  const { setModal } = useContext(KYXModalContext);

  const kybStatus = getCookieValue(cookieNames.KYB_STATUS);
  const activeTabIndex = PROGRESS_TABS.findIndex((tab) => {
    return location.pathname.includes(tab.url);
  });
  const [activeTab, setActiveTab] = useState(PROGRESS_TABS[activeTabIndex || 0]);
  // ? Use of session storage is intended here as user may choose to reload the page and in that case we will want to keep the form in editable state and not disable it.
  const lastSubmittedSavedIndex = parseInt(sessionStorage.getItem("lastSubmittedSavedIndex"));
  const [lastSubmittedStepIndex, setLastSubmittedStepIndex] = useState(lastSubmittedSavedIndex || 0);

  const evaluatePageDisabled = (activeTabIndex: number, lastSubmittedIndex: number) => {
    if (kybStatus === KYB_STATUS.ADDITIONAL_DOCUMENTS_REQUIRED) {
      return false;
    } else {
      return !(activeTabIndex === 0 ? true : typeof lastSubmittedIndex && activeTabIndex <= lastSubmittedIndex + 1);
    }
  };

  const [isFormDisabled, setIsFormDisabled] = useState(evaluatePageDisabled(activeTabIndex, lastSubmittedStepIndex));

  const handleActiveTab = (activeKey: string): void => {
    const filteredTab = PROGRESS_TABS.find((tab): Object => tab.tabKey === activeKey || tab.title === activeKey);
    history.replace(filteredTab.url);
    setActiveTab(filteredTab);
    setIsFormDisabled(evaluatePageDisabled(activeTabIndex, lastSubmittedStepIndex));
  };

  const handleStepChange = (type: PROGRESS_FOOTER_ACTION.NEXT | PROGRESS_FOOTER_ACTION.BACK) => {
    const tabKey = activeTab.tabKey;
    const stepNo = PROGRESS_TABS.findIndex((tab) => tab.tabKey === tabKey);
    let newStep: number;
    const MIN_STEP = 0;
    const MAX_STEP = PROGRESS_TABS.length - 1;
    if (type === PROGRESS_FOOTER_ACTION.BACK) {
      newStep = stepNo - 1;
    } else {
      if (type === PROGRESS_FOOTER_ACTION.NEXT) {
        setLastSubmittedStepIndex(stepNo);
        sessionStorage.setItem("lastSubmittedSavedIndex", stepNo.toString());
        setLastSubmittedStepIndex(stepNo);
      }
      newStep = stepNo + 1;
    }
    if (newStep < MIN_STEP) {
      history.push(COMPANY_VERIFICATION.WELCOME);
    } else if (newStep <= MAX_STEP) {
      handleActiveTab(PROGRESS_TABS[newStep].tabKey);
    } else {
      return setModal({ type: KYX_MODAL_TYPES.CLIENT_AGREEMENT, visible: true });
    }
  };

  return {
    handleStepChange,
    activeTabIndex,
    handleActiveTab,
    isFormDisabled,
  };
};
