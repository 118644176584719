import React from "react";
import classNames from "classnames";
import { Tags, Typography } from "@spenmo/splice";

import { IReimbursementExportOption } from "Views/Reimbursement/@types";

import CsvIcon from "Views/Reimbursement/Sidepanel/Export/CsvIcon";

import styles from "./index.module.scss";

const ExportOption = ({
  id,
  description,
  title,
  iconColor,
  comingSoon,
  active,
  handleOnClick,
  bottom,
}: IReimbursementExportOption) => {
  const onClick = () => {
    if (comingSoon) return;

    handleOnClick(id);
  };

  return (
    <button
      key={id}
      onClick={onClick}
      className={classNames(styles.section, { [styles.active]: active, [styles.soon]: comingSoon })}
    >
      <CsvIcon color={iconColor} />
      <div className={styles.details}>
        <Typography size="m" variant="body-content" tag="h5">
          {title}
        </Typography>
        <Typography size="s" variant="body-content" tag="p">
          {description}
        </Typography>
        {comingSoon ? <Tags.Progression hierarchy="low" label="Coming Soon" variant="info" /> : null}
        {bottom ? bottom() : null}
      </div>
    </button>
  );
};

export default ExportOption;
