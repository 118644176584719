import APIclient from "API/Client";
import { urlParamsBuilder, GetFormData } from "utility/APIWrapper";
import { GetOrgId, GetUserId } from "utility";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";

const AxiosGetForApiWrapper = (url) =>
  APIclient.getData(url)
    .then((response) =>
      response?.status >= HTTP_STATUS_CODE.OK && response?.status < HTTP_STATUS_CODE.MULTIPLE_CHOICES
        ? Promise.resolve(response?.data, response)
        : Promise.reject(response?.data)
    )
    .catch((err) => Promise.reject(err));

export const GetAllCards = (
  pageNumber = 0,
  pageSize = 10,
  filters = {
    team_id: [],
    ordering: {},
    search_filters: {},
  }
) => {
  const { team_id, ordering, search_filters } = filters;

  let params = {
    organisation_id: GetOrgId(),
    pg: pageNumber,
    limit: pageSize,
    search_filters: JSON.stringify(search_filters),
  };

  if (team_id?.length > 0) {
    params.team_id = JSON.stringify(team_id);
  }

  if (ordering) {
    params = { ...params, ...ordering };
  }

  const url = urlParamsBuilder(`/api/v1/org/card`, params);
  return AxiosGetForApiWrapper(url);
};

export const rejectCardRequest = (cardId, reason) => {
  return APIclient.postData(`/api/v1/org/card/cardrequest/${cardId}/reject?organisation_id=${GetOrgId()}`, {
    reject_reason: reason,
  });
};

export const GetCardApprovers = (teamId) => {
  return APIclient.getData(
    `/api/v1/org/${GetOrgId()}/team/${teamId}?only_approvers=1&with_policy=0&with_member_details=0`
  );
};

export const sendCardRequest = (payload) => {
  return APIclient.postData(`/api/v1/org/card/cardrequest`, payload);
};

export const GetAllCardsRequests = (
  pageNumber = 0,
  pageSize = 10,
  filters = {
    team_id: [],
    ordering: {},
    search_filters: {},
  },
  type = "received_request",
  status = ""
) => {
  const { team_id, ordering, search_filters } = filters;

  let params = {
    organisation_id: GetOrgId(),
    pg: pageNumber,
    limit: pageSize,
    search_filters: JSON.stringify(search_filters),
    card_details: true,
    [type]: true,
    filters: JSON.stringify({ status: status }),
  };

  if (team_id?.length > 0) {
    params.team_id = JSON.stringify(team_id);
  }

  if (ordering) {
    params = { ...params, ...ordering };
  }

  const url = urlParamsBuilder(`/api/v1/org/card/cardrequest/all`, params);
  return AxiosGetForApiWrapper(url);
};

export const approveCardRequest = (cardRequestId, payload) => {
  return APIclient.postData(`/api/v1/org/card/cardrequest/${cardRequestId}/approve`, {
    ...payload,
    organisation_id: GetOrgId(),
  });
};

export const remindManager = (cardRequestId) => {
  return APIclient.postData(`/api/v1/org/card/cardrequest/${cardRequestId}/remind`, { organisation_id: GetOrgId() });
};

export const updateCardDetails = (cardId, payload, isCardRequestDetailsUpdate) => {
  const url = `/api/v1/org/card/${isCardRequestDetailsUpdate ? "cardrequest/" : ""}${cardId}${
    isCardRequestDetailsUpdate ? "/update" : ""
  }`;
  return APIclient[isCardRequestDetailsUpdate ? "postData" : "putData"](url, {
    ...payload,
    organisation_id: GetOrgId(),
  });
};

export const removeCardRequest = (cardRequestId) => {
  return APIclient.postData(`/api/v1/org/card/cardrequest/${cardRequestId}/delete`, { organisation_id: GetOrgId() });
};

export const setCardPin = (cardId, payload) => {
  return APIclient.postData(`/api/v1/org/card/${cardId}/set-pin`, { ...payload, organisation_id: GetOrgId() });
};

export const deleteCard = (cardId, payload) => {
  return APIclient.postData(`/api/v1/org/card/${cardId}/block`, {
    ...payload,
    organisation_id: GetOrgId(),
  });
};

export const checkForPhysicalCardEligibility = () => {
  return APIclient.postData(`/api/v1/org/card/cardrequest/can-create-request`, {
    organisation_id: GetOrgId(),
    user_id: GetUserId(),
    is_physical: 1,
  });
};

export const getPhoneCodes = () => {
  return APIclient.getData(`/ms/spm-organization/v1/master/phone-code`);
};

export const changeCardStatus = (status, cardId) => {
  return APIclient.postData(
    `/api/v1/org/card/${cardId}/status/update`,
    GetFormData({ status: status, organisation_id: GetOrgId() })
  );
};

export const activatePhysicalCard = (cardLastFour) => {
  return APIclient.postData(
    `/api/v1/org/card/physical/activate`,
    GetFormData({ organisation_id: GetOrgId(), card_last_four: cardLastFour })
  );
};

export const getCardDetails = (cardId) => {
  return APIclient.getData(`/api/v1/org/card/${cardId}?organisation_id=${GetOrgId()}`);
};
