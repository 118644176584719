import * as React from "react";
import RcTooltip from "rc-tooltip";
import { PlacementType } from "Views/Card/Components/Tooltip/types.d";
import "rc-tooltip/assets/bootstrap_white.css";
import "Views/Card/Components/Tooltip/index.scss";

/**
 * ToolTip Component
 * @param {string} tooltipText - text of the tooltip
 * @param {string} placement - position of tooltip ( PlacementType )
 */

export interface ITooltipProps {
  placement: PlacementType;
  tooltipText: string | React.ReactNode;
  children: string | React.JSX.Element;
  key?: React.Key;
}

export const Tooltip = ({ children, tooltipText, placement, key }: ITooltipProps) => {
  const childrenWrapper = typeof children === "string" ? <span>{children}</span> : children;
  return (
    <RcTooltip placement={placement} overlay={tooltipText} overlayClassName="ds-tooltip" key={key}>
      {childrenWrapper}
    </RcTooltip>
  );
};
