import React, { useContext } from "react";

import Button from "Modules/button";
import RichHeader from "Modules/DS/RichHeader";
import { IKYXModalContext, IManualFormContext } from "Views/KYX/@types";
import { KYXModalContext } from "Views/KYX/Context";
import { ManualFormContext } from "Views/KYX/Context/ManualForm";
import { KYXFormSubmitLoader } from "Views/KYX/Modal/Submit";

import useLogout from "utility/useLogout";
import { useFormSaveAsDraft } from "Views/KYX/hooks";

import styles from "./index.module.scss";

const SaveAsDraftLogOutModal: React.FC = () => {
  const logout = useLogout();

  const { resetModal }: IKYXModalContext = useContext(KYXModalContext);
  const { formData }: IManualFormContext = useContext(ManualFormContext);
  const { loading, saveFormAsDraft } = useFormSaveAsDraft(() => logout());

  const onSaveAsDraft = () => {
    saveFormAsDraft(formData);
  };

  if (loading) {
    return <KYXFormSubmitLoader open={loading} message="Saving Draft..." />;
  }

  return (
    <div className={styles.wrapper}>
      <RichHeader title="Save changes before logging out?" onClose={resetModal} />
      <div className={styles.content}>
        <p className={styles.message}>Any unsaved data upon log out will be discarded. Save changes before logout?</p>

        <div className={styles.actionBtns}>
          <Button secondary text="Discard Changes" outline rounded action={logout} />
          <Button text="Save Changes" rounded action={onSaveAsDraft} />
        </div>
      </div>
    </div>
  );
};

export default SaveAsDraftLogOutModal;
