import { CARD_TXN_STATUS } from "Views/Transaction/@types";

export const CARDS_TRXNS_TABLE_LIMIT = 30;

export const SETTLED_TRXNS_STATUS = [
  CARD_TXN_STATUS.SETTLED,
  CARD_TXN_STATUS.SETTLED_UPDATED,
  CARD_TXN_STATUS.UNMATCHED_SETTLEMENT,
  CARD_TXN_STATUS.CLOSED,
];

export const REVERSED_TRXNS_STATUS = [CARD_TXN_STATUS.REVERSED, CARD_TXN_STATUS.APPROVED_DROPPED_OFF];

export const REFUNDED_TRXNS_STATUS = [
  CARD_TXN_STATUS.REFUND_FULL,
  CARD_TXN_STATUS.REFUND_PARTIAL,
  CARD_TXN_STATUS.UNMATCHED_REVERSAL,
  CARD_TXN_STATUS.SETTLEMENT_CREDIT,
];

export const CARDS_TRXN_SEARCH_PARAMS = {
  txnNumber: "txnNumber",
};
