import React, { useRef, useState } from "react";
import qs from "query-string";
import { Base64 } from "js-base64";
import { useHistory, useLocation } from "react-router-dom";

import { Quick } from "Modules/DS/Filter";
import { POSITION_ALGO_TYPE } from "Modules/DS/Popover";

import useConfig from "Views/Reimbursement/hooks/useConfig";
import { REIMBURSEMENT_QUERY_PARAMS } from "Views/Reimbursement/Constants";
import { ILabelWithID, ILocationQueryParams } from "Views/Reimbursement/@types";

const StatusFilter = () => {
  const history = useHistory();
  const location = useLocation();

  const { data, showSkeleton } = useConfig();

  const query: ILocationQueryParams = qs.parse(location.search, {
    parseNumbers: true,
    parseBooleans: true,
  });

  const { statuses = "" } = query;

  const [title, setTitle] = useState<string>();
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const presetValue = useRef<string[]>(Base64.decode(statuses).split(","));

  const handleOnAddSelected = (value: string[]) => {
    history.push({
      pathname: location.pathname,
      search: qs.stringify({
        ...query,
        [REIMBURSEMENT_QUERY_PARAMS.STATUSES]: Base64.encodeURI(value),
        [REIMBURSEMENT_QUERY_PARAMS.PAGE]: 1,
      }),
    });
  };

  const handleClearFilter = () => {
    history.push({
      pathname: location.pathname,
      search: qs.stringify({
        ...query,
        [REIMBURSEMENT_QUERY_PARAMS.STATUSES]: undefined,
        [REIMBURSEMENT_QUERY_PARAMS.PAGE]: 1,
      }),
    });
  };

  const handleSetSelectedIds = (value: React.SetStateAction<string[]>) => {
    presetValue.current = value as string[];
    setSelectedIds(value);
  };

  return (
    <Quick.Multiple<ILabelWithID>
      filterItemProps={{ id: "REIMBURSEMENTS-ACTION-STATUS-FILTER", positionAlgoType: POSITION_ALGO_TYPE.HORIZONTAL }}
      defaultValue="Status"
      searchable={false}
      displayKey="label"
      onAddSelected={handleOnAddSelected}
      clearFilter={handleClearFilter}
      states={{
        selectedIds,
        setSelectedIds: handleSetSelectedIds,
        setTitle,
        title,
        loading: showSkeleton,
      }}
      presetValue={presetValue.current}
    >
      {data.filterStatuses}
    </Quick.Multiple>
  );
};

export default StatusFilter;
