import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useCardList } from "Views/Home/Components/MyCards/UserCardCustomHook";
import { MyCardsContextProvider } from "Views/Home/Components/MyCards/MyCards.context";
import { getCardDetailsData } from "Views/Card/List/Utilities";
import { CARD_DETAILS_TABS } from "Views/Card/List/types";
import { CARD_STATUS } from "Views/Card/types";
import { COMPANY_ROLES } from "constants/QuickAccess.constant";
import { checkAccess } from "utility/permissions/permission";
import { GetRoleDetails } from "./UserCards/Utilities";

import CardDetailsModal from "Views/Card/Details";
import UserCards from "Views/Home/Components/MyCards/UserCards";
import { CardSelectedEventName } from "Views/Card/Analytics";
import { trackEvent } from "utility/analytics";

const MyCards = (props) => {
  const CARD_LIMIT = 10;
  const history = useHistory();
  const roleDetails = GetRoleDetails(props?.teamData, props?.userInfo);
  const isAuditor = checkAccess([COMPANY_ROLES.AUDITOR], roleDetails?.companyRole);
  const [showCardDetailsModal, setShowCardDetailsModal] = useState(null);
  const [cardListData, userCardsListLoading, userCardsListError, refetchUserCardsList] = useCardList(
    isAuditor,
    CARD_LIMIT
  );
  const onUserCardClick = (cardDetails) => {
    trackEvent(CardSelectedEventName, { card_id: cardDetails?.id, card_select_event_source: "Home" });
    setShowCardDetailsModal({
      isVisible: true,
      data: cardDetails,
    });
  };
  const onCloseOfCardDetailsModal = () => {
    trackEvent("card details close button clicked", { id: showCardDetailsModal?.data?.id });
    setShowCardDetailsModal({
      isVisible: false,
      data: null,
    });
  };
  const contextValue = {
    onUserCardClick,
    userDetails: props,
  };
  const refreshCardsListing = () => {
    refetchUserCardsList();
    onCloseOfCardDetailsModal();
  };
  const getCardDetailsModal = () => {
    return (
      <CardDetailsModal
        cardData={getCardDetailsData(showCardDetailsModal?.data, false)}
        onClose={onCloseOfCardDetailsModal}
        onEdit={() => {
          history.push("/cards", { card: showCardDetailsModal?.data });
          onCloseOfCardDetailsModal();
        }}
        onCardRequestDelete={() => {
          /* do nothing */
        }}
        onCardDelete={refreshCardsListing}
        onPhysicalCardSync={refreshCardsListing}
        onCardStatusToggle={(status) => {
          refetchUserCardsList();
          setShowCardDetailsModal((prevState) => ({
            ...prevState,
            data: {
              ...prevState?.data,
              card_status: status ? CARD_STATUS.ENABLED : CARD_STATUS.DISABLED,
            },
          }));
        }}
        cardDetailsInitialTab={CARD_DETAILS_TABS.DETAILS}
        showCardDetailsInModal
      />
    );
  };
  return (
    <MyCardsContextProvider value={contextValue}>
      <UserCards
        cardListData={cardListData}
        userCardsListLoading={userCardsListLoading}
        userCardsListError={userCardsListError}
        refetchUserCardsList={refetchUserCardsList}
      />
      {showCardDetailsModal?.isVisible && getCardDetailsModal()}
    </MyCardsContextProvider>
  );
};

export default MyCards;
