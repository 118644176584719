import React from "react";

import { Modal } from "@spenmo/splice";

import styles from "./index.module.scss";
import { ILatePaymentAlertInfo } from "Views/Bills/V2/PaymentRun/types";

interface IConfirmationAlertProps {
  title: string;
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<ILatePaymentAlertInfo | boolean>>;
  confirmPay: () => void;
  children: React.ReactNode;
}
const ConfirmationAlert = ({ title, show, setShow, confirmPay, children }: IConfirmationAlertProps) => {
  return (
    <div className={styles.modal}>
      <Modal
        size="m"
        title={title}
        showModal={show}
        onClose={() => setShow(false)}
        primaryActionButton={{
          title: "Confirm",
          action: () => {
            setShow(false);
            confirmPay();
          },
        }}
        secondaryActionButton={{
          title: "Back",
          action: () => setShow(false),
        }}
      >
        <div className={styles.body}>{children}</div>
      </Modal>
    </div>
  );
};

export default ConfirmationAlert;
