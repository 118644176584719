import React, { useContext, useEffect, useState } from "react";
import { Form, Tabs } from "antd";
import { InternalNamePath } from "antd/lib/form/interface";

import Icon, { IconList } from "Modules/Icon";
import {
  FORM_SOURCE_TYPE,
  IKYXRemarkContextType,
  IKYXToastContext,
  IPersonForm,
  IProgressTabPane,
} from "Views/KYX/@types";

import { ProgressStep } from "Views/KYX/Progress/Step";
import { getRemarksPersonIds } from "Views/KYX/Helper";
import { KYXRemarkContext } from "Views/KYX/Context/Remarks";
import { DocumentStep } from "Views/KYX/Progress/Step/Document";
import { FORM_NAME, KYX_FORMS_KEYS } from "Views/KYX/Constants";
import { useFetchDataFormatter, useFormSaveAsDraft, useStepChangeHandler } from "Views/KYX/hooks";

import DeleteButton from "./DeleteButton";

import styles from "./index.module.scss";
import { usePersonnelInfoFormDataSGD } from "./Form/Personnel";
import { usePersonnelInfoFormBannerSGD } from "./Form/Banner";
import { KYXToastContext } from "Views/KYX/Context";
import { TOASTER_SIZE_TYPE, TOASTER_STATUS_TYPE } from "Modules/DS/Toaster";
import { usePersonnelInfoFormDataSGDMyInfo } from "./Form/PersonnelMyInfo";
import TabBarExtraContent from "./TabExtraContent";

const { TabPane } = Tabs;

export const ManualMethod = () => {
  const [form] = Form.useForm();

  const { formData, formattedInitialValues, loading } = useFetchDataFormatter();
  const { formRemarks }: IKYXRemarkContextType = useContext(KYXRemarkContext);
  const remarks = formRemarks?.[FORM_NAME.PERSONNEL_INFORMATION];
  const { isFormDisabled } = useStepChangeHandler();
  const initialPanes: IProgressTabPane[] = [{ name: "Person 1", key: "0", id: "0", isNotVerified: false }];

  const [activeKey, setActiveKey] = useState<string>(initialPanes[0].key);

  const { personnel_information } = formData?.form || {};
  const isFormSourceMyInfo = formData?.source === FORM_SOURCE_TYPE.MY_INFO;
  const { share_holders } = personnel_information || {};
  const { source } = formData || {};

  useEffect(() => {
    const { persons } = formattedInitialValues || { persons: {} };
    const personsArray = Object.values(persons);

    if (Array.isArray(personsArray) && personsArray?.length) {
      const initialPanes = personsArray?.map(
        (
          person: {
            full_name?: string;
            roles_of_individual: string[];
            id: string;
            status: "verified" | "";
            source: "MyInfo" | "";
          },
          index
        ) => {
          const activePersonRole = person?.roles_of_individual;
          const isOnlyDirector = activePersonRole.includes("is_director") && activePersonRole?.length === 1;

          return {
            name: person?.full_name || `Person ${index + 1}`,
            key: index.toString(),
            id: person.id,
            isNotVerified: person.source === "MyInfo" && !isOnlyDirector ? person.status !== "verified" : false,
          };
        }
      );
      setPanes(initialPanes);
    }
  }, [formattedInitialValues]);

  const { setToaster } = useContext<IKYXToastContext>(KYXToastContext);

  useEffect(() => {
    if (source === "MyInfo" && share_holders?.length) setActiveKey("entity");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [source]);

  const [panes, setPanes] = useState<IProgressTabPane[]>(initialPanes);

  const onEdit = (_e: React.MouseEvent, action: "add" | "remove"): void => {
    if (action === "remove") {
      return null;
    } else if (action === "add") {
      return addPerson();
    }
  };
  const { saveFormAsDraft } = useFormSaveAsDraft();

  const removePerson = (targetKey: string): void => {
    const oldPanes = panes;
    setToaster({
      message: "Personnel successfully deleted ",
      visible: true,
      actionLabel: "Undo",
      status: TOASTER_STATUS_TYPE.SUCCESS,
      action: () => {
        setPanes([...oldPanes]);
      },
      size: TOASTER_SIZE_TYPE.S,
    });
    let newActiveKey = activeKey;
    let lastIndex = -1;
    const newPanes = panes.filter((pane, i) => {
      if (pane.key === targetKey) {
        lastIndex = i - 1;
        return false;
      }
      return true;
    });
    if (newPanes.length && newActiveKey === targetKey) {
      if (lastIndex >= 0) {
        newActiveKey = newPanes[lastIndex].key;
      } else {
        newActiveKey = newPanes[0].key;
      }
    }
    setPanes(newPanes);
    setActiveKey(newActiveKey);

    const persons = [...(formData.form.personnel_information.persons || [])];
    persons[targetKey] = undefined;
    saveFormAsDraft({
      ...formData,
      id: formData.form_id,
      form: {
        ...formData.form,
        personnel_information: {
          ...formData.form.personnel_information,
          persons: [...persons],
        },
      },
    });
    form.setFieldsValue({ persons: { [targetKey]: undefined } });
  };

  const onChange = (key: string): void => {
    setActiveKey(key);
  };

  const addPerson = (): void => {
    const paneIndex = panes.length;

    const newActiveKey = (paneIndex + 1).toString();
    const newPanes: IProgressTabPane[] = [
      ...panes,
      {
        name: "Person " + newActiveKey,
        key: newActiveKey,
        id: newActiveKey,
      },
    ];

    setPanes(newPanes);
    setActiveKey(newActiveKey);
  };
  const [errorTabs, setErrorTabs] = useState([]);
  const handleErrors = (
    errors: {
      name: InternalNamePath;
      errors: string[];
    }[]
  ) => {
    const errorTabIndices = errors.map((errorField) => errorField.name[1]);
    let uniqueErrorTabs = errorTabIndices.filter((value, index, array) => array.indexOf(value) === index);
    setErrorTabs(uniqueErrorTabs);
    setToaster({
      message: "One or more required fields have an error. Please check and try again.",
      visible: true,
      status: TOASTER_STATUS_TYPE.ERROR,
      size: TOASTER_SIZE_TYPE.S,
    });
  };
  const getTabName = (name: string, id: string, index: number, isNotVerified = false) => {
    const filteredPersons = getRemarksPersonIds(remarks) || [];
    let showAlert = isNotVerified || filteredPersons?.includes(id) || errorTabs.includes(index.toString());

    return (
      <div className={styles.tabName}>
        {showAlert && (
          <Icon
            className={styles.tabIcon}
            svgAttr={{ width: 24, height: 24, viewBox: "0 0 24 24" }}
            icon={IconList.fillWarning}
          />
        )}
        <p>{name}</p>
      </div>
    );
  };

  const PersonalIdentificationForm = ({ index }) => {
    const personnelInfoForm = usePersonnelInfoFormDataSGD(form, activeKey, formData, remarks, index, isFormDisabled);
    return (
      <div>
        {index !== "0" && <DeleteButton onClick={() => removePerson(activeKey)} />}
        <DocumentStep {...personnelInfoForm} />
      </div>
    );
  };
  const PersonalIdentificationFormMyInfo = ({ index }) => {
    const personnelInfoForm = usePersonnelInfoFormDataSGDMyInfo(
      form,
      activeKey,
      formData,
      remarks,
      index,
      isFormDisabled
    );
    const isSourceMyInfo = formData?.form?.personnel_information?.persons[index]?.source === "MyInfo";
    return (
      <div>
        {index !== "0" && !isSourceMyInfo && <DeleteButton onClick={() => removePerson(activeKey)} />}
        <DocumentStep {...personnelInfoForm} />
      </div>
    );
  };

  const handleSelectTab = (item: IPersonForm): void => {
    let key = panes.find((pane) => pane.id === item.id)?.key || panes[0]?.key;
    setActiveKey(key);
  };

  const renderShareHolderDetails = (label: string, value: string | number = "-"): JSX.Element => (
    <div className={styles.shareHolderDetail}>
      <p>{label}</p>
      <p>{value}</p>
    </div>
  );

  const personnelInfoBanners = usePersonnelInfoFormBannerSGD(remarks, isFormDisabled, isFormSourceMyInfo);
  return (
    <ProgressStep
      form={form}
      name={FORM_NAME.PERSONNEL_INFORMATION}
      formatter={{ formData, formattedInitialValues, loading }}
      handleErrors={handleErrors}
    >
      <DocumentStep {...personnelInfoBanners} />
      <div className={styles.tabs}>
        <Tabs
          prefixCls="person-tab"
          type="editable-card"
          addIcon={
            <TabBarExtraContent
              panes={panes}
              remarks={remarks}
              errorTabs={errorTabs}
              activeKey={activeKey}
              handleSelectTab={handleSelectTab}
            />
          }
          onEdit={onEdit}
          onChange={onChange}
          activeKey={activeKey}
        >
          {source === "MyInfo" && Array.isArray(share_holders) && share_holders.length && (
            <TabPane className={styles.tabPane} tab={`Entity Shareholder (${share_holders.length})`} key={"entity"}>
              <div className={styles.shareHolderTabContent}>
                {share_holders.map((items, index) => (
                  <div className={styles.shareHolerItem}>
                    <div className={styles.sharesHolderTitle}>Entity Shareholder {index + 1}</div>
                    <div className={styles.shareHolerItemContent}>
                      {renderShareHolderDetails("Entity Name", items.entity_name)}
                      {renderShareHolderDetails("UEN", items.uen)}
                    </div>
                    <div className={styles.shareHolerItemContent}>
                      {renderShareHolderDetails("Share Type", items.share_type)}
                      {renderShareHolderDetails("Share Allocation", items.share_allocation)}
                    </div>
                  </div>
                ))}
              </div>
            </TabPane>
          )}
          {panes.map(({ name, key, id, isNotVerified }, index) => {
            const isSourceMyInfo = formData?.form?.personnel_information?.persons?.[index]?.source === "MyInfo";
            return (
              <TabPane
                className={styles.tabPane}
                tab={getTabName(name, id, index, isNotVerified)}
                key={key}
                forceRender
              >
                <Form.List name={[KYX_FORMS_KEYS.PERSONNEL_DATA_PERSONS, key]}>
                  {() =>
                    isSourceMyInfo ? (
                      <PersonalIdentificationFormMyInfo index={index.toString()} key={key} />
                    ) : (
                      <PersonalIdentificationForm index={index.toString()} key={key} />
                    )
                  }
                </Form.List>
              </TabPane>
            );
          })}
        </Tabs>
      </div>
    </ProgressStep>
  );
};
