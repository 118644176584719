import { greyCloseIcon } from "assets/img";
import { DynamicFormContext } from "Modules/DynamicForm/store";
import { DynamicFormFieldProps } from "Modules/DynamicForm/types";
import React, { useContext, useEffect } from "react";
import { DynamicFormDropdownBase } from "../../Dropdown";
import DynamicFormTextfield from "../../Textfield";
import textfieldStyle from "../../Textfield/Textfield.module.scss";
import style from "./BankName.module.scss";

const DynamicFormBankName = (props: DynamicFormFieldProps) => {
  const { id, fieldID, onChangeValue, value: fieldValue, options, fieldProps } = props;
  const { isNewDataFields, setIsNewDataFields, setFields, setChainFields, form } = useContext(DynamicFormContext);

  const setBankName = (bankName?: string) => {
    onChangeValue?.(fieldID, bankName);
    setChainFields((prev) => ({
      ...prev,
      [fieldID]: bankName,
    }));
    form.setFieldsValue({
      dynamicFields: {
        [id]: bankName,
      },
    });
  };

  useEffect(() => {
    if (isNewDataFields[fieldID]) {
      setBankName(fieldValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNewDataFields, fieldID]);

  const handleClear = () => {
    setIsNewDataFields((prev) => ({
      ...prev,
      [fieldID]: false,
    }));
    setBankName();
  };

  const handleChange = (value: string) => {
    onChangeValue?.(fieldID, value);
  };

  const handleNew = (value?: string) => {
    setIsNewDataFields((prev) => ({
      ...prev,
      [fieldID]: true,
    }));
    setFields((prev) => {
      prev[fieldID].value = value;
      return { ...prev };
    });
  };

  const handleNewByEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      const { value } = e.currentTarget;
      const isExist = Boolean(options.find((option) => option.label.toLowerCase().search(value) !== -1));
      if (!isExist) {
        handleNew(value.toUpperCase());
      }
    }
  };

  const handleChangeNewBankAsUppercase = (_id: number, value: string) => {
    form.setFieldsValue({
      dynamicFields: {
        [id]: value.toUpperCase(),
      },
    });
  };

  if (isNewDataFields[fieldID]) {
    return (
      <DynamicFormTextfield
        {...props}
        className={style.input}
        onChangeValue={handleChangeNewBankAsUppercase}
        fieldProps={{
          ...fieldProps,
          placeholder: "Enter the recipient’s bank name",
        }}
        suffix={<img className={textfieldStyle.clearIcon} alt="Clear" src={greyCloseIcon} onClick={handleClear} />}
      />
    );
  }

  return (
    <DynamicFormDropdownBase
      {...props}
      className={style.input}
      onChange={handleChange}
      onClickNewButton={handleNew}
      onEnter={handleNewByEnter}
    />
  );
};

export default DynamicFormBankName;
