import APIclient from "API/Client";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";

const AxiosGetForApiWrapper = (url) =>
  APIclient.getData(url)
    .then((response) =>
      response?.status >= HTTP_STATUS_CODE.OK && response?.status < HTTP_STATUS_CODE.MULTIPLE_CHOICES
        ? Promise.resolve(response?.data, response)
        : Promise.reject(response?.data)
    )
    .catch((err) => Promise.reject(err));

export default AxiosGetForApiWrapper;
