import React from "react";
import styles from "./index.module.scss";
import { errorIcon } from "assets/img";
import Icon from "Modules/icons";

const JointApplicantSessionExpired = (): JSX.Element => {
  return (
    <div className={styles.container}>
      <Icon className={styles.icon} src={errorIcon} alt={"error icon"} />
      <h1 className={styles.text}>URL Session Expired</h1>
      <p className={styles.description}>
        Sorry, access to review your information as a shareholder has been expired. Please reach out to your Authorized
        Person who shared this URL
      </p>
    </div>
  );
};

export default JointApplicantSessionExpired;
