import React from "react";
import PropTypes from "prop-types";
import Modal from "Modules/modal";
import Icon from "Modules/icons";
import { errorIcon } from "assets/img";
import Button from "Modules/button";
import "./BulkUploadErrorModal.scss";

const BulkUploadErrorModal = (props) => {
  const { show, close, errorLink } = props;
  const errorDescriptionTwo = "Please download the Error Note, adjust your data, and reupload.";
  return (
    <Modal show={show} close={close} title="Upload Failed: Format Mismatch" className="bulk-upload-error-modal">
      <hr className="breakline" />
      <div className="bulk-upload-modal-container">
        <Icon src={errorIcon} className="bulk-upload-error-icon" />
        <div className="error-description">
          <p>
            We found some <strong>format mismatch</strong> in the employee data you uploaded. We generated an Error Note
            to help you solve this issue.
          </p>
          <p className="error-description-two">{errorDescriptionTwo}</p>
        </div>
        <div className="action-btns">
          <a className="action-btns__download-btn" href={errorLink} rel="noreferrer">
            Download Error Note
          </a>
          <Button text="Close" className="action-btns__close-btn" secondary action={close} />
        </div>
      </div>
    </Modal>
  );
};
BulkUploadErrorModal.propTypes = {
  show: PropTypes.bool,
  close: PropTypes.func,
  errorLink: PropTypes.any,
};
export default BulkUploadErrorModal;
