import React from "react";
import classNames from "classnames";
import { intlDateTimeFormat, intlTimeFormat, dateTimeWithTimezone } from "utility";
import { IColumnData } from "Modules/DS/DataTable";
import { wrapTooltip } from "Views/Transaction/Helper";

import WorkflowStatus from "../WorkflowStatus";
import ShimmerEffect from "../ShimmerEffect";
import ModalAttachment from "../ModalAttachment";
import { doesntHaveAttachment } from "../PendingReview/helper";
import { EWorkflowApiStatus, ITransaction } from "../type";

import styles from "./styles.module.scss";
import CategoryDropdown from "./CategoryDropdown";
import { IToasterProps } from "Views/Accounting/Settings/@types";

export const columnData: (
  status: EWorkflowApiStatus,
  loading: boolean,
  setToaster: React.Dispatch<React.SetStateAction<IToasterProps>>,
  refetch: () => void
) => IColumnData<ITransaction>[] = (status, loading, setToaster, refetch) => {
  const additionalWidth = status === EWorkflowApiStatus.FAILED_SYNC ? 0 : 24;

  const columns = [
    {
      id: "PENDING_WORKFLOW-COL-001",
      enabled: true,
      column: {
        title: "Date & Time",
        dataIndex: "createdAt",
        key: "createdAt",
        width: 165,
        render(createdAt) {
          const formattedDate = dateTimeWithTimezone(createdAt || new Date().toISOString());
          return (
            <ShimmerEffect loading={loading}>
              <div className={styles.createdAt}>
                <p className={styles.body}>{intlDateTimeFormat(new Date(formattedDate))}</p>
                <p className={styles.caption}>{intlTimeFormat(new Date(formattedDate))}</p>
              </div>
            </ShimmerEffect>
          );
        },
      },
    },
    {
      id: "PENDING_WORKFLOW-COL-002",
      enabled: true,
      column: {
        title: "Type",
        dataIndex: "transactionType",
        key: "transactionType",
        width: 155 + additionalWidth,
        render(transactionType) {
          return <ShimmerEffect loading={loading}>{transactionType}</ShimmerEffect>;
        },
      },
    },
    {
      id: "PENDING_WORKFLOW-COL-005",
      enabled: true,
      column: {
        title: "Requester",
        dataIndex: "employee",
        key: "employee",
        width: 130 + additionalWidth,
        ellipsis: true,
        render(employee, transaction) {
          return (
            <ShimmerEffect loading={loading}>
              <div>
                <p className={classNames(styles.body, styles["with-ellipsis"])}>{employee?.name}</p>
                <p className={styles.caption}>{employee?.team}</p>
              </div>
            </ShimmerEffect>
          );
        },
      },
    },
    {
      id: "PENDING_WORKFLOW-COL-003",
      enabled: true,
      column: {
        title: "Recipient/Merchant",
        dataIndex: "merchant",
        key: "merchant",
        width: 150 + additionalWidth,
        render(merchant) {
          return (
            <ShimmerEffect loading={loading}>
              <div className={styles.tooltipWrapper}>{wrapTooltip(merchant || "N.A.", "h5")}</div>
            </ShimmerEffect>
          );
        },
      },
    },
    {
      id: "PENDING_WORKFLOW-COL-006",
      enabled: true,
      column: {
        title: "Category",
        dataIndex: "category",
        key: "category",
        width: 150 + additionalWidth,
        render(_, record) {
          return (
            <ShimmerEffect loading={loading}>
              <CategoryDropdown record={record} setToaster={setToaster} refetch={refetch} />
            </ShimmerEffect>
          );
        },
      },
    },
    {
      id: "PENDING_WORKFLOW-COL-006",
      enabled: true,
      column: {
        title: "Attachment/Receipts",
        dataIndex: "receipt",
        width: 130 + additionalWidth,
        key: "receipt",
        render(receipt, transaction) {
          const getContent = () => {
            if (doesntHaveAttachment(transaction.transactionType)) {
              return <p className={styles.center}>N.A.</p>;
            }

            return (
              <ModalAttachment receipts={receipt} record={transaction} setToaster={setToaster} refetch={refetch} />
            );
          };

          return <ShimmerEffect loading={loading}>{getContent()}</ShimmerEffect>;
        },
      },
    },
    {
      id: "PENDING_WORKFLOW-COL-004",
      enabled: true,
      column: {
        title: "Amount",
        dataIndex: "amount",
        key: "amount",
        className: styles.alignRight,
        width: 140,
        render(amount, transaction: ITransaction) {
          return <ShimmerEffect loading={loading}>{transaction.isDebit ? amount : `(${amount})`}</ShimmerEffect>;
        },
      },
    },
    {
      id: "PENDING_WORKFLOW-COL-007",
      enabled: status === EWorkflowApiStatus.FAILED_SYNC,
      column: {
        title: "Status",
        dataIndex: "status",
        width: 170,
        key: "status",
        render(status, transaction: ITransaction) {
          return (
            <ShimmerEffect loading={loading}>
              <WorkflowStatus status={status} transaction={transaction} />
            </ShimmerEffect>
          );
        },
      },
    },
  ];

  return columns.filter((column) => column.enabled);
};
