/*
  enums
*/
export enum DELETE_EMPLOYEE_FROM_ORG_ACTION_TYPES {
  FETCH_DELETE_EMPLOYEE_FROM_ORG_REQUEST = "EMPLOYEE/FETCH_DELETE_EMPLOYEE_FROM_ORG_REQUEST",
  FETCH_DELETE_EMPLOYEE_FROM_ORG_SUCCESS = "EMPLOYEE/FETCH_DELETE_EMPLOYEE_FROM_ORG_SUCCESS",
  FETCH_DELETE_EMPLOYEE_FROM_ORG_ERROR = "EMPLOYEE/FETCH_DELETE_EMPLOYEE_FROM_ORG_ERROR",
  CLEAR_DELETE_EMPLOYEE_FROM_ORG = "EMPLOYEE/CLEAR_DELETE_EMPLOYEE_FROM_ORG",
}

/*
  interfaces
*/
export interface IDeleteEmployeeFromOrgData {
  status_message?: string;
  id?: string;
  name?: string;
  success?: boolean;
}

export interface IDeleteEmployeeFromOrgReducer {
  loading: boolean;
  data: IDeleteEmployeeFromOrgData;
  error: boolean;
}

/*
  initialize
*/

export const initialDeleteEmployeFromOrgState: IDeleteEmployeeFromOrgReducer = {
  loading: false,
  data: {} as IDeleteEmployeeFromOrgData,
  error: false,
};
