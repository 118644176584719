import { InputProps } from "Modules/ExchangeInput/types";
import { FormInstance } from "antd/lib/form";
import { OCRData } from "../VendorSection/types";

type ExchangeRate = {
  amount: number;
  updatedAt: string;
};

export interface ExchangeCalculatorProps extends InputProps {
  form: FormInstance<any>;
  corridorType: string;
  ocrData?: OCRData;
  fxRate?: ExchangeRate;
  isFixedRate?: boolean;
  receiverCountry: string;
  isFullPayment?: boolean;
  refetchFxRate(receiverCurrencyCode, senderCurrencyCode): void;
  onChangePayFullGuarantee?(isPayFull: boolean): void;
  onChangeBillFee?(billFee: any): void;
  onChangeWalletAmount?(amountFx: string): void;
  isAmountMismatch: boolean;
  disabled?: boolean;
}

export enum CorridorTypes {
  domestic = "domestic",
  internationalLocal = "international_local",
  internationalGlobal = "international_global",
}

export enum SwiftPaymentChargeType {
  OUR = "OUR",
  SHA = "SHA",
}
