import * as React from "react";
import { Button, Typography } from "antd";
import { setCardPin } from "Redux/DataCalls/Cards.api";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import { showSuccessNotification } from "Views/Card/Components/Notification";

import styles from "Views/Card/Pin/ActionBtn/index.module.scss";
import Loader, { LoaderTypes } from "Views/Card/Components/Loader";

const { Text } = Typography;

interface ICardPinActionBtnProps {
  cardId: string;
  disabled: boolean;
  isUpdatePin: boolean;
  pin: string;
  onUpdate: () => void;
}

const CardPinActionBtn = ({ cardId, disabled, isUpdatePin, pin, onUpdate }: ICardPinActionBtnProps) => {
  const [actionInProgress, setActionInProgress] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");

  const onClick = () => {
    setActionInProgress(true);
    const cardPinPayload = {
      pin,
      pin_confirmation: pin,
      device: navigator?.userAgent || "",
    };
    const errorMessageSuffix = isUpdatePin ? "changing" : "setting";
    setCardPin(cardId, cardPinPayload)
      .then(({ data }) => {
        const { payload = {}, status } = data || {};
        const { status_message = "" } = payload;
        if (status === HTTP_STATUS_CODE.OK) {
          showSuccessNotification(`Successfully ${isUpdatePin ? "changed" : "set"} PIN.`);
          onUpdate();
        } else {
          setErrorMessage(status_message || `Error ${errorMessageSuffix} PIN. Please try again.`);
        }
      })
      .catch((error) => {
        const { status_message = "" } = error?.response?.data?.payload || {};
        setErrorMessage(status_message || `Error ${errorMessageSuffix} PIN. Please try again.`);
      })
      .finally(() => {
        setActionInProgress(false);
      });
  };

  const getBtnText = () => {
    switch (true) {
      case actionInProgress:
        return <Loader type={LoaderTypes.white} />;
      case isUpdatePin:
        return "Change PIN";
      default:
        return "Set PIN";
    }
  };
  return (
    <>
      {errorMessage ? <Text className={styles["error-message"]}>{errorMessage}</Text> : null}
      <Button disabled={disabled} className={styles["primary-btn"]} onClick={onClick}>
        {getBtnText()}
      </Button>
    </>
  );
};

export default CardPinActionBtn;
