import React from "react";
import { successIcon } from "assets/img";
import Icon from "Modules/icons";
import MobileQRCodeBanner from "Modules/mobileQRCodeBanner";
import { Link } from "react-router-dom";

const UpdateProfileSuccess = () => {
  return (
    <div className={"update-profile-success"}>
      <div className={"update-profile-success__content"}>
        <Icon className={"status-icon"} src={successIcon} alt={"success icon"} />
        <h4 className={"title"}>Your Cards feature is activated!</h4>
        <p className={"description"}>
          Now you can create virtual and physical cards. Cards are customizable based to your needs.
        </p>
        <Link className={"button button--rounded"} to={"/"}>
          View Cards Now
        </Link>
      </div>
      <MobileQRCodeBanner />
    </div>
  );
};

export default UpdateProfileSuccess;
