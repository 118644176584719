import APIclient from "API/Client";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import { updateTeam } from "Redux/Actions";
import { getTeamProfileData } from "Redux/Actions/Teams/teamProfileAction";

export const MOVE_TEAM_MEMBER = "MOVE_TEAM_MEMBER";
export const MOVE_TEAM_MEMBER_REQUEST = `${MOVE_TEAM_MEMBER}_REQUEST`;
export const MOVE_TEAM_MEMBER_SUCCESS = `${MOVE_TEAM_MEMBER}_SUCCESS`;
export const MOVE_TEAM_MEMBER_FAILURE = `${MOVE_TEAM_MEMBER}_FAILURE`;
export const RESET_MOVE_TEAM_MEMBER = "RESET_MOVE_TEAM_MEMBER";

export const createTeamRequest = (data) => {
  return {
    type: MOVE_TEAM_MEMBER_REQUEST,
    data: data,
  };
};

const moveTeamMemberSuccess = (data) => {
  return {
    type: MOVE_TEAM_MEMBER_SUCCESS,
    data: data,
  };
};
const moveTeamMemberError = (data) => {
  return {
    type: MOVE_TEAM_MEMBER_FAILURE,
    data: data,
  };
};
export const resetMoveTeamMember = () => {
  return {
    type: RESET_MOVE_TEAM_MEMBER,
  };
};

export const moveTeamMember = (data) => {
  return (dispatch) => {
    const { teamId, userId, newTeamId } = data;
    const bodyParam = {
      newBudgetID: newTeamId,
    };

    dispatch(createTeamRequest(data));
    APIclient.postData(`/ms/spm-organisation/v1/mgmt/budgets/${teamId}/members/${userId}/move`, bodyParam)
      .then((data) => {
        if (data.data.status > HTTP_STATUS_CODE.OK) {
          dispatch(moveTeamMemberError(data.data));
        } else {
          dispatch(moveTeamMemberSuccess(data.data));
          dispatch(updateTeam());
          dispatch(getTeamProfileData(teamId));
        }
      })
      .catch((err) => {});
  };
};

export const removeTeamMember = (data) => {
  return (dispatch) => {
    const { teamId, userId } = data;
    dispatch(createTeamRequest(data));
    APIclient.deleteData(`/ms/spm-organisation/v1/mgmt/budgets/${teamId}/members/${userId}`)
      .then((res: { data: { status: 0 } }) => {
        if (res.data.status > HTTP_STATUS_CODE.OK) {
          dispatch(moveTeamMemberError(res.data));
        } else {
          dispatch(moveTeamMemberSuccess(res.data));
          dispatch(getTeamProfileData(teamId));
        }
      })
      .catch((err) => {});
  };
};
