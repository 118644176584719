import {
  FETCH_TOPUP_FAILURE,
  FETCH_TOPUP_REQUEST,
  FETCH_TOPUP_SUCCESS,
  RESET_TOPUP_DATA,
} from "../Actions/topupAction";
import { userInitialState } from "../InitialState";

export const topupReducer = (
  state = {
    paymentType: "",
    ...userInitialState,
  },
  action
) => {
  switch (action.type) {
    case FETCH_TOPUP_REQUEST:
      return {
        ...state,
        loading: true,
        data: action.data,
        error: false,
      };
    case FETCH_TOPUP_SUCCESS:
      return {
        ...state,
        loading: false,
        paymentType: action.paymentType,
        data: action.data,
        error: false,
      };
    case FETCH_TOPUP_FAILURE:
      return {
        ...state,
        loading: false,
        paymentType: action.paymentType,
        error: action.error,
      };
    case RESET_TOPUP_DATA:
      return {
        paymentType: "",
        ...userInitialState,
      };
    default:
      return state;
  }
};
