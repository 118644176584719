import React from "react";

import { useModal } from "Views/Transaction/Modal";
import { TrxnModalContext } from "Views/Transaction/Context";
import { ITrxnModalContext } from "Views/Transaction/@types";

export const TrxnModalProvider = ({ children }: { children: React.ReactNode }) => {
  const [showModal, setShowModal, resetModal, toggleModal] = useModal();

  const modalContext: ITrxnModalContext = {
    showModal,
    setShowModal,
    resetModal,
    toggleModal,
  };

  return <TrxnModalContext.Provider value={modalContext}>{children}</TrxnModalContext.Provider>;
};
