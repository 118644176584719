import { IKYXToastContext } from "Views/KYX/@types";
import { KYXToastContext } from "Views/KYX/Context";
import {
  IGetMyInfoTokenResponse,
  IPostMyInfoAuthorisePayload,
  IPostMyInfoAuthoriseResponse,
  IPostMyInfoCallbackPayload,
  IPostMyInfoCallbackResponse,
  IPostMyInfoSubmitPayload,
  IPostMyInfoSubmitResponse,
  getMyInfoToken,
  postMyInfoAuthorise,
  postMyInfoCallback,
  postMyInfoSubmit,
} from "Views/KYX/DataCalls/MyInfo.api";
import { errorHandler } from "Views/KYX/Helper/errorHandler";
import { useContext, useState } from "react";

export const usePostMyInfoAuthorise = () => {
  const [loading, setLoading] = useState(false);
  const { setToaster } = useContext<IKYXToastContext>(KYXToastContext);
  const myInfoAuthorise = (payload: IPostMyInfoAuthorisePayload): Promise<IPostMyInfoAuthoriseResponse> => {
    return new Promise((resolve, reject) => {
      setLoading(true);
      postMyInfoAuthorise(payload)
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          const toast = errorHandler(err);
          setToaster(toast);
          reject(err);
        })
        .finally(() => {
          setLoading(false);
        });
    });
  };
  return { loading, myInfoAuthorise };
};
export const usePostMyInfoCallback = () => {
  const [loading, setLoading] = useState(false);
  const { setToaster } = useContext<IKYXToastContext>(KYXToastContext);
  const myInfoCallback = (payload: IPostMyInfoCallbackPayload): Promise<IPostMyInfoCallbackResponse> => {
    return new Promise((resolve, reject) => {
      setLoading(true);
      postMyInfoCallback(payload)
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          const toast = errorHandler(err);
          setToaster(toast);
          reject(err);
        })
        .finally(() => {
          setLoading(false);
        });
    });
  };
  return { loading, myInfoCallback };
};
export const usePostMyInfoSubmit = () => {
  const [loading, setLoading] = useState(false);
  const { setToaster } = useContext<IKYXToastContext>(KYXToastContext);
  const myInfoSubmit = (payload: IPostMyInfoSubmitPayload): Promise<IPostMyInfoSubmitResponse> => {
    return new Promise((resolve, reject) => {
      setLoading(true);
      postMyInfoSubmit(payload)
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          const toast = errorHandler(err);
          setToaster(toast);
          reject(err);
        })
        .finally(() => {
          setLoading(false);
        });
    });
  };
  return { loading, myInfoSubmit };
};
export const useGetMyInfoToken = () => {
  const [loading, setLoading] = useState(false);
  const { setToaster } = useContext<IKYXToastContext>(KYXToastContext);
  const myInfoToken = (token: string): Promise<IGetMyInfoTokenResponse> => {
    return new Promise((resolve, reject) => {
      setLoading(true);
      getMyInfoToken(token)
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          const toast = errorHandler(err);
          setToaster(toast);
          reject(err);
        })
        .finally(() => {
          setLoading(false);
        });
    });
  };
  return { loading, myInfoToken };
};
