import React from "react";
import PropTypes from "prop-types";
import TooltipOnIcon from "Modules/TooltipOnIcon";
import classNames from "classnames";
import useIsAryadana from "customHooks/useIsAryadana.tsx";
import { ptAryadana } from "assets/img";

const InfoUnit = (props) => {
  const { align, className, label, value } = props;

  const _className = classNames(
    "info-unit",
    {
      [`sp-items--${align}`]: align,
    },
    className
  );

  const showAryadana = useIsAryadana();

  return (
    <div className={_className}>
      <div className={"info-unit__label"}>
        {showAryadana && (
          <>
            <TooltipOnIcon
              imageSrc={ptAryadana}
              imageClassName="homepage-header__label__aryadana-tooltip"
              altText="Powered by PT Aryadana"
              description="Powered by PT Aryadana"
              placement="bottom"
              overlayClassName="homepage-header__aryadana-tooltip"
            />{" "}
          </>
        )}
        {label}
      </div>
      <div className={"info-unit__value"}>{value}</div>
    </div>
  );
};

InfoUnit.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.node,
  align: PropTypes.string,
};

export default InfoUnit;
