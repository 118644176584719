import {
  EDIT_SCHEDULED_TRANSACTION_REQUEST,
  EDIT_SCHEDULED_TRANSACTION_SUCCESS,
  EDIT_SCHEDULED_TRANSACTION_FAILURE,
  CLEAR_EDIT_SCHEDULED_TRANSACTION,
} from "Redux/Actions/ScheduledTransactions/editScheduledTransaction";
import { userInitialState } from "Redux/InitialState";

export const editScheduledTransactionReducer = (state = userInitialState, action) => {
  switch (action.type) {
    case EDIT_SCHEDULED_TRANSACTION_REQUEST:
      return {
        ...state,
        data: action.data,
        loading: true,
      };
    case EDIT_SCHEDULED_TRANSACTION_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
      };
    case EDIT_SCHEDULED_TRANSACTION_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.data,
        error: true,
      };
    case CLEAR_EDIT_SCHEDULED_TRANSACTION:
      return userInitialState;
    default:
      return state;
  }
};
