import React from "react";

import { AddOutline, Typography } from "@spenmo/splice";
import { VendorImage } from "Views/SubscriptionManagement/Components/VendorImage";

import {
  ROLLING_FREQUENCY,
  REVIEW_CONTRACT_DURATION,
  BILLING_FREQUENCY,
  BILLING_FREQUENCY_LABELS,
} from "Views/SubscriptionManagement/Constants";

import styles from "./styles.module.scss";

const getTypography = (optionName) => (
  <Typography variant="body-content" size="caption-m" className={styles.selectOption}>
    {optionName}
  </Typography>
);

export const option = {
  vary: [
    { id: 1, value: 10, children: "10%" },
    { id: 2, value: 20, children: "20%" },
    { id: 3, value: 30, children: "30%" },
    { id: 4, value: 40, children: "40%" },
  ],
  billingFrequency: [
    {
      id: 1,
      value: BILLING_FREQUENCY.MONTHLY,
      children: getTypography(BILLING_FREQUENCY_LABELS[BILLING_FREQUENCY.MONTHLY]),
    },
    {
      id: 2,
      value: BILLING_FREQUENCY.ANNUALLY,
      children: getTypography(BILLING_FREQUENCY_LABELS[BILLING_FREQUENCY.ANNUALLY]),
    },
  ],
  rollingFrequency: [
    { id: 2, value: ROLLING_FREQUENCY.MONTHLY, children: getTypography("Monthly") },
    { id: 3, value: ROLLING_FREQUENCY.QUARTERLY, children: getTypography("Quarterly") },
    { id: 4, value: ROLLING_FREQUENCY.ANNUALLY, children: getTypography("Annually") },
  ],
  expiry: (reviewType: "expiry" | "renewal") => [
    { id: 1, value: null, children: getTypography("No Reminder") },
    { id: 2, value: REVIEW_CONTRACT_DURATION.IN_1_WEEK, children: getTypography(`1 week before ${reviewType} date`) },
    { id: 3, value: REVIEW_CONTRACT_DURATION.IN_2_WEEKS, children: getTypography(`2 weeks before ${reviewType} date`) },
    { id: 4, value: REVIEW_CONTRACT_DURATION.IN_1_MONTH, children: getTypography(`1 month before ${reviewType} date`) },
    {
      id: 5,
      value: REVIEW_CONTRACT_DURATION.IN_2_MONTHS,
      children: getTypography(`2 months before ${reviewType} date`),
    },
    {
      id: 6,
      value: REVIEW_CONTRACT_DURATION.IN_3_MONTHS,
      children: getTypography(`3 months before ${reviewType} date`),
    },
  ],
  currency: (data) => {
    return data.map((currency) => ({
      id: currency.id,
      value: currency.currency,
      children: (
        <div className={styles.vendor}>
          <img src={currency.country} alt={currency.country} className={styles.flag} />
          {currency.currency}
        </div>
      ),
    }));
  },
  budget: (teamMembers) => {
    return teamMembers.map(({ id, teamName }) => ({
      id: id,
      value: id,
      children: teamName,
      label: teamName,
    }));
  },
  owner: (teamMembers) => {
    return teamMembers.map(({ id, name }) => ({
      id: id,
      value: id,
      children: name,
      label: name,
    }));
  },
};

export const subscriptionListWithAddNew = (subscriptions, allowToCreateSubscriptions) => {
  return [
    allowToCreateSubscriptions
      ? {
          id: "0",
          Icon: <AddOutline size="16" iconColor="var(--text-link-default)" />,
          value: "Add New",
          label: (
            <Typography variant="body-content" size="caption-m" weight={600} className={styles.addNew}>
              Add New
            </Typography>
          ),
        }
      : null,
    ...subscriptions.map((subscription) => ({
      id: subscription?.id,
      Icon: <VendorImage width={18} height={20} vendor={subscription?.vendor?.name} />,
      value: subscription?.id,
      label: (
        <Typography variant="body-content" size="caption-m">
          {subscription?.vendor?.name?.toLowerCase()}
        </Typography>
      ),
    })),
  ].filter(Boolean);
};
