import React from "react";
import PropTypes from "prop-types";
import Icon from "Modules/icons";
import { spenmoIconBordered, oneURLQRCode, googlePlayBtn, appStoreBtn } from "assets/img";
import "./mobileQRCodeBanner.scss";

const MobileQRCodeBanner = ({ description }) => {
  return (
    <div className="mobile-qr-code-banner">
      <div className="qr-code">
        <Icon className="spenmo-icon" src={spenmoIconBordered} alt={"spenmo icon"} />
        <Icon className="mobile-qr-code" src={oneURLQRCode} alt={"mobile QR code"} />
        <div className="mobile-app-stores-imgs">
          <a href="https://onelink.to/un3yd4" rel="noreferrer" target="_blank">
            <Icon src={googlePlayBtn} alt={"google play"} />
          </a>
          <a href="https://onelink.to/un3yd4" rel="noreferrer" target="_blank">
            <Icon src={appStoreBtn} alt={"app store"} />
          </a>
        </div>
      </div>
      <p className="mobile-qr-code-banner__desc">
        {description || (
          <>
            Download Spenmo mobile app and validate your ID to
            <span> unlock more benefits</span> for your account.
          </>
        )}
      </p>
    </div>
  );
};

MobileQRCodeBanner.propTypes = {
  description: PropTypes.any,
};

export default MobileQRCodeBanner;
