import React from "react";
import { useRouteMatch } from "react-router-dom";
import { Sidepanel as SPMSidepanel } from "@spenmo/splice";

import { ILocationParams } from "Views/Reimbursement/@types";
import ExportSidepanel from "Views/Reimbursement/Sidepanel/Export";
import DetailSidepanel from "Views/Reimbursement/Sidepanel/Detail";
import { PAGE_TITLE, REIMBURSEMENT_PAGE_OPERATIONS } from "Views/Reimbursement/Constants";

const DefaultSidepanel = () => {
  return (
    <SPMSidepanel
      showSidepanel={false}
      position="right"
      title={PAGE_TITLE}
      footerStacking="horizontal"
      transitionType="rightToLeft"
    />
  );
};

const Sidepanel = () => {
  const { params } = useRouteMatch<ILocationParams>();

  const { operation = "", secondaryOperation = "" } = params;

  let Component: React.FunctionComponent = undefined;

  switch (true) {
    case operation === REIMBURSEMENT_PAGE_OPERATIONS.EXPORT:
    case operation === REIMBURSEMENT_PAGE_OPERATIONS.SETTLEMENTS &&
      secondaryOperation === REIMBURSEMENT_PAGE_OPERATIONS.EXPORT:
    case operation === REIMBURSEMENT_PAGE_OPERATIONS.PAYOUTS &&
      secondaryOperation === REIMBURSEMENT_PAGE_OPERATIONS.EXPORT:
      Component = ExportSidepanel;
      break;
    case operation === REIMBURSEMENT_PAGE_OPERATIONS.READ:
    case operation === REIMBURSEMENT_PAGE_OPERATIONS.SETTLEMENTS &&
      secondaryOperation === REIMBURSEMENT_PAGE_OPERATIONS.READ:
      Component = DetailSidepanel;
      break;
    case operation === REIMBURSEMENT_PAGE_OPERATIONS.PAYOUTS &&
      secondaryOperation === REIMBURSEMENT_PAGE_OPERATIONS.READ:
      Component = DetailSidepanel;
      break;
    case operation === "":
    default:
      Component = DefaultSidepanel;
      break;
  }

  return <Component />;
};

export default Sidepanel;
