import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import Button from "../../Modules/button";
import ModalDetails from "../../Modules/modalDetails";
import { Toaster } from "../../Modules/toaster";
import { GetBaseAuthObject } from "../../utility";
import LoaderIcon from "../State/Loading/LoaderIcon";
import { connect } from "react-redux";
import { reqFunc } from "../../Redux/Actions";
import "./RequestMoney.scss";
import { trackEvent } from "utility/analytics";
import { getParamsValue } from "utility";
import { QueryParamsKeys, QueryParamsVaules } from "constants/MoneyQueryParams.constants";

class RequestMoney extends React.Component {
  state = {
    show: false,
    showRequestColleague: false,
    showRequestTeam: false,
    isSchedulePayment: true,
    showToaster: false,
  };

  componentDidMount() {
    const [moneyActionType] = getParamsValue(QueryParamsKeys.MONEY_ACTION_TYPE);
    if (moneyActionType === QueryParamsVaules.REQUEST_MONEY) {
      this.requestMoneyActionHandler();
    }
  }

  handleShow = () => {
    this.setState({ show: true });
  };

  submitReqData = (obj) => {
    const { wallet = {}, user = {} } = obj || {};
    const reqMoney = {
      amount: { amount: obj.amount, currency: obj.to_currency },
      requestor: { team_id: obj.user.team_id, type: "team" },
      request: { image_urls: [], notes: "" },
      requested_from: { team_id: obj.modal_user.team_id, type: "team" },
      organisation_id: GetBaseAuthObject().orgId,
    };
    if (obj.images || obj.comment) {
      reqMoney.request = {
        image_urls: obj.images,
        notes: obj.comment,
      };
    }
    if (this.state.showRequestColleague) {
      reqMoney.requestor = {
        team_id: wallet?.team_id,
        type: wallet?.type === "user" ? "user" : "team",
        user_id: user?.user_id,
      };
      reqMoney.requested_from = [{ type: "user", user_id: obj.modal_user.user_id }];
    }
    if (obj.date_picker || obj.time_period) {
      reqMoney.schedule = {
        schedule_date: obj.date_picker || moment().format("DD-MM-YYYY"),
        schedule_frequency: obj.time_period || "none",
      };
    }
    this.props.requestMoneyFunc(reqMoney, true);
  };

  requestMoneyActionHandler = () => {
    this.setState({ showRequestColleague: true, isSchedulePayment: false });
    trackEvent("home request money clicked");
  };

  getButtonElement = () => {
    const { hideButton, loading, customBtnComponent = null } = this.props;
    switch (true) {
      case hideButton:
        return null;
      case loading:
        return <LoaderIcon />;
      case !!customBtnComponent:
        return customBtnComponent(() => this.requestMoneyActionHandler());
      default:
        return (
          <Button
            className={"request-money-btn"}
            text={"Request Money"}
            action={() => this.requestMoneyActionHandler()}
            secondary
          />
        );
    }
  };

  render() {
    return (
      <>
        {this.getButtonElement()}
        <ModalDetails
          updateData={this.submitReqData}
          id={"RequestFrom"}
          isSchedulePayment={this.state.isSchedulePayment}
          show={this.state.showRequestColleague || this.state.showRequestTeam}
          title={"Request Money"}
          close={() => this.setState({ showRequestColleague: false, showRequestTeam: false, isSchedulePayment: true })}
          showUpload
          isRequestMoney={true}
          isTeam={this.state.showRequestTeam}
          onSuccess={() => this.setState({ showToaster: true, showRequestColleague: false, showRequestTeam: false })}
          trackActions={true}
        />
        {this.state.showToaster && (
          <>
            <Toaster
              closeFunc={() => this.setState({ showToaster: false })}
              message={"Request Money successful"}
              visible={this.props.data.payload && this.props.data.payload.status_message === "OK"}
              status={"success"}
            />
            <Toaster
              closeFunc={() => this.setState({ showToaster: false })}
              message={this.props.data.payload && this.props.data.payload.status_message}
              visible={this.props.data.payload && this.props.data.payload.status_message !== "OK"}
            />
          </>
        )}
      </>
    );
  }
}

RequestMoney.propTypes = {
  hideButton: PropTypes.bool,
  requestMoneyFunc: PropTypes.func,
  loading: PropTypes.bool,
  data: PropTypes.object,
  error: PropTypes.bool,
  customBtnComponent: PropTypes.func,
};

export default connect(
  (state) => ({
    loading: state.moneyReducer.loading,
    data: state.moneyReducer.data,
    error: state.moneyReducer.error,
  }),
  {
    requestMoneyFunc: reqFunc,
  }
)(RequestMoney);
