import * as React from "react";

import { trackEvent } from "utility/analytics";
import { EmployeeDeleteEventName } from "Views/Employee/Analytics";

import { ModalComponent } from "Modules/DS/Modal";
import { TITLE_TYPE } from "Modules/DS/Modal/types";

import Input from "Views/Employee/Delete/Modal/Confirm/Input";
import { Requirement } from "Views/Employee/Modal/Requirement";
import SETTLEMENTS from "Views/Employee/Delete/Modal/Confirm/Settlements";

const Confirm = ({
  name,
  onCancel,
  confirmDelete,
}: {
  name: string;
  onCancel: () => void;
  confirmDelete: () => void;
}) => {
  const viewObject = {
    title: `You are about to delete\n${name ? name : ""}`,
    subTitle: "Proceeding to delete will:",
  };

  const handleCancel = () => {
    onCancel();
    trackEvent(EmployeeDeleteEventName.DELETE_ACCOUNT_EVENT, {
      account_deletion_event_source: EmployeeDeleteEventName.DELETE_ACCOUNT_CANCELLED,
    });
  };
  const handleDeleteEmployee = () => {
    confirmDelete();
    trackEvent(EmployeeDeleteEventName.DELETE_ACCOUNT_EVENT, {
      account_deletion_event_source: EmployeeDeleteEventName.DELETE_ACCOUNT_CONFIRMED,
    });
  };

  return (
    <>
      <ModalComponent.CloseButton />
      <ModalComponent.Title titleType={TITLE_TYPE.withBorder}>{viewObject.title}</ModalComponent.Title>
      <ModalComponent.SubTitle>{viewObject.subTitle}</ModalComponent.SubTitle>
      <Requirement.Settlement>{SETTLEMENTS}</Requirement.Settlement>
      <Input onCancel={handleCancel} deleteEmployee={handleDeleteEmployee} />
    </>
  );
};

export default Confirm;
