import { Tooltip } from "antd";
import React from "react";
import { ITextWithTooltipProps } from "Views/Taxes/types";

import "./styles.scss";

const TextWithTooltip = ({ treshold, text, element }: ITextWithTooltipProps) => {
  return (
    <>
      {text.length > treshold ? (
        <Tooltip
          title={text}
          placement="bottom"
          overlayClassName="text-with-tooltip"
          transitionName="text-with-tooltip-transition"
        >
          <div className="text-with-tooltip__title">{element || text}</div>
        </Tooltip>
      ) : (
        element || text
      )}
    </>
  );
};

export default TextWithTooltip;
