export enum AuthenticationButtonActions {
  ACCOUNT_AUTHENTICATION_BUTTON_CLICKED = "Account Authentication Button Clicked",
}

export enum LoginResponseResult {
  SUCCESS = "Success",
  FAIL = "Fail",
}

export enum AuthActionEventSource {
  LOGIN = "Login",
  LOGOUT = "Logout",
  FORGOT_PASSWORD = "Forgot Password",
  RESET_PASSWORD = "Reset Password",
}
