import React, { useContext, useEffect, useState } from "react";
import DynamicFormTextfield from "../../Textfield";
import { bulbTooltip as bulbTooltipIcon } from "assets/img";
import { DynamicFormContext } from "Modules/DynamicForm/store";
import Icon from "Modules/icons";
import { Tooltip, TooltipArrowAlignmentEnum, TooltipPositionEnum } from "Modules/DS/Tooltip";

const RegiesteredEntityName = (props) => {
  const { id, fieldID, onChangeValue, fieldProps } = props;
  const { form } = useContext(DynamicFormContext);
  const { beneficiaryName, dynamicFields, dynamicFieldsByFieldID } = form.getFieldsValue([
    "beneficiaryName",
    "dynamicFields",
    "dynamicFieldsByFieldID",
  ]);
  const parentField = dynamicFieldsByFieldID?.[fieldProps?.parentFieldID];

  const [isLinked, setIsLinked] = useState(dynamicFields[id] === beneficiaryName || !dynamicFields[id]);

  useEffect(() => {
    if (isLinked) {
      onChangeValue(fieldID, beneficiaryName);
      form.setFieldsValue({ dynamicFields: { ...dynamicFields, [id]: beneficiaryName } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [beneficiaryName, parentField]);

  const handleOnChange = (fieldID, value) => {
    isLinked && setIsLinked(false);
    onChangeValue(fieldID, value);
  };

  const TooltipMessage =
    "Registered entity name is auto-populated to match recipient bank account name. You can override this field with a different name.";

  return (
    <>
      <DynamicFormTextfield
        {...props}
        onChangeValue={handleOnChange}
        suffix={
          isLinked && beneficiaryName ? (
            <div className="icon-tooltip">
              <Tooltip
                text={TooltipMessage}
                alignArrow={TooltipArrowAlignmentEnum.CENTER}
                position={TooltipPositionEnum.LEFT}
              >
                <Icon alt="bulb-tooltip-icon" src={bulbTooltipIcon} />
              </Tooltip>
            </div>
          ) : (
            <></>
          )
        }
      />
    </>
  );
};

export default RegiesteredEntityName;
