import React, { useContext, useEffect, useState } from "react";

import { MoreFilter as DSMore, invalidAmountErrorMsg } from "Modules/DS/Filter";
import { ISelectContext, SelectContext } from "Modules/DS/Select";
import Menu from "Modules/DS/Menu";
import { ALL_TRXN_PAGINATION_PARAMS, resetPaginationParams } from "Views/Transaction/Constants";
import { TrxnFilterContext, TrxnMoreFilterContext, TrxnPaginationContext } from "Views/Transaction/Context";
import {
  GET_TRXN_REQUEST_PARAMS,
  ITrxnFilterContext,
  ITrxnMoreFilterContext,
  ITrxnPaginationContext,
} from "Views/Transaction/@types";
import { clearURLParams } from "utility";

export const MoreFilter = ({ children }: { children: React.ReactNode }) => {
  const { closeSelect } = useContext<ISelectContext>(SelectContext);
  const { resetCurrentPage } = useContext<ITrxnPaginationContext>(TrxnPaginationContext);
  const { filter, onApplyFilter, resetFilter, clearFilter, clearAll } =
    useContext<ITrxnFilterContext>(TrxnFilterContext);
  const { more, clear, setClear, keys, resetMoreFilter, containerClass } =
    useContext<ITrxnMoreFilterContext>(TrxnMoreFilterContext);

  const _keys: string[] = [...keys, ...ALL_TRXN_PAGINATION_PARAMS];

  const _filters: string[] = Object.keys(filter)
    .filter(Boolean)
    .filter((key) => keys.includes(key) && more[key]);

  const [showInvalidAmountError, setShowInvalidAmountError] = useState(false);

  const onClearHandler = () => {
    clearFilter(_keys);
    setClear(true);
    resetMoreFilter();
    setShowInvalidAmountError(false);
  };

  const onApplyHandler = () => {
    if (Boolean(more[GET_TRXN_REQUEST_PARAMS.TEAM_ID]) === false) {
      clearURLParams(["teams"]);
    }
    const isInvalidAmount = more.min_amount > more.max_amount;
    setShowInvalidAmountError(isInvalidAmount);

    if (!isInvalidAmount) {
      onApplyFilter({
        ...more,
        ...resetPaginationParams,
      });
      resetCurrentPage();
      closeSelect();
    }
  };

  const clearFilterHandler = () => {
    resetFilter(_keys);
    resetMoreFilter();
    resetCurrentPage();
    closeSelect();
    setClear(true);
    clearURLParams(["teams"]);
    setShowInvalidAmountError(false);
  };

  useEffect(() => {
    if (Object.keys(filter).filter((key) => more[key]).length === 0 && clear) setClear(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clear]);

  return (
    <DSMore
      id="transactions"
      filters={_filters}
      clearFilter={clearFilterHandler}
      onApply={onApplyHandler}
      onClear={onClearHandler}
      clear={clearAll || clear}
      containerClass={containerClass}
      useDynamicPosition={false}
    >
      {children}
      {showInvalidAmountError && (
        <Menu.Row id="inputError" title="">
          {invalidAmountErrorMsg()}
        </Menu.Row>
      )}
    </DSMore>
  );
};
