import React, { useContext, useEffect, useState } from "react";

import { Quick } from "Modules/DS/Filter";
import { IItemID } from "Modules/DS/Menu/@types";
import { POSITION_ALGO_TYPE } from "Modules/DS/Popover";

import { TrxnFilterContext, TrxnPaginationContext } from "Views/Transaction/Context";
import { ALL_TRXN_PAGINATION_PARAMS, resetPaginationParams } from "Views/Transaction/Constants";
import { ITrxnFilterContext, ITrxnPaginationContext, ITrxnSectionQuickFilter } from "Views/Transaction/@types";
import { ITeamWithMembersData } from "Views/Team";

export const SectionSelect = <T extends IItemID, K extends object = any>({
  id,
  secondaryId,
  defaultValue,
  titleKey,
  appliedKey,
  subArryTitleKey,
  subArryIdKey,
  listKey,
  children,
  loading,
  searchPlaceholder,
  dispatchOnSelect = () => null,
  dispatchOnClear = () => null,
}: ITrxnSectionQuickFilter<T, K>) => {
  const [title, setTitle] = useState("");
  const [selectedIds, setSelectedIds] = useState([]);
  const [value, setValue] = useState("");
  const [searching, setSearching] = useState(false);

  const { resetCurrentPage } = useContext<ITrxnPaginationContext>(TrxnPaginationContext);
  const { onApplyFilter, resetFilter, urlFilters } = useContext<ITrxnFilterContext>(TrxnFilterContext);

  useEffect(() => {
    if (urlFilters.requester_ids && urlFilters.team_ids) {
      const requesterId = urlFilters.requester_ids[0];

      const userTeams = (children || [])
        .filter((team: any) => (team?.members || []).find((member) => member?.user_id === requesterId))
        .map((team) => team.id);

      const selectedMember = children
        .map((item: any) => item.members)
        .flat()
        .find((member) => member.user_id === urlFilters.requester_ids?.[0]);

      const teamsLength = userTeams.length;

      if (teamsLength > 0) {
        setTitle(teamsLength === 1 ? selectedMember?.name : `Requester (${userTeams.length})`);
        const selectedIds = userTeams.map((team) => {
          return `${requesterId}/idSeparator/${team}`;
        });

        setSelectedIds(selectedIds);
      }
    }
  }, [urlFilters.requester_ids, urlFilters.team_ids, children]);

  const onSelectHandler = (value: string[], secondaryValue: string[]) => {
    onApplyFilter({
      [id]: value,
      [secondaryId]: secondaryValue,
      ...resetPaginationParams,
    });
    dispatchOnSelect?.(value, secondaryValue);
    resetCurrentPage();
  };

  const clearFilter = () => {
    resetFilter([id, secondaryId, ...ALL_TRXN_PAGINATION_PARAMS]);
    dispatchOnClear?.();
    resetCurrentPage();
  };

  const sortListDataByName = (data) =>
    data.sort((a: ITeamWithMembersData, b: ITeamWithMembersData) => a.name.localeCompare(b.name));

  const sortData = (data) => {
    return sortListDataByName(data).map((item) => {
      return {
        ...item,
        members: sortListDataByName(item.members),
      };
    });
  };

  return (
    <Quick.Sectioned<T, K>
      idSeparator="/idSeparator/"
      listKey={listKey}
      titleKey={titleKey}
      appliedKey={appliedKey}
      subArryIdKey={subArryIdKey}
      subArryTitleKey={subArryTitleKey}
      searchPlaceholder={searchPlaceholder}
      filterItemProps={{ id, containerClass: ".data-table-container", positionAlgoType: POSITION_ALGO_TYPE.HORIZONTAL }}
      defaultValue={defaultValue}
      onAddSelected={onSelectHandler}
      clearFilter={clearFilter}
      states={{
        loading,
        title,
        setTitle,
        selectedIds,
        setSelectedIds,
        searching,
        setSearching,
        setValue,
        value,
      }}
      sortFilteredData={sortData}
    >
      {sortData(children)}
    </Quick.Sectioned>
  );
};
