import React from "react";
import { CARD_LIST_TABS } from "Views/Card/List/types";
import { Table, IColumnData } from "Modules/DS/DataTable";
import { useIsManagerOrAdmin } from "customHooks/Card";
import { useCardListContext } from "Views/Card/List/index.context";
import { cardListColumnsData, cardRequestOrReceivedColumnData } from "Views/Card/List/Table/ColumnsData";
import { ICardDetails } from "Views/Card/types";
import { getCardDetailsData } from "Views/Card/List/Utilities";

import "./index.scss";

const CardSkeletonTable = () => {
  const { totalCount, currentPage, cardListActiveTab } = useCardListContext();
  const [isAdmin, isManager] = useIsManagerOrAdmin();
  const isAdminOrManager = isAdmin || isManager;

  const getColumnData = (): IColumnData<ICardDetails>[] => {
    switch (cardListActiveTab) {
      case CARD_LIST_TABS.CARD_LIST:
        return cardListColumnsData(true, isAdminOrManager);
      case CARD_LIST_TABS.SENT_CARD_REQUEST:
        return cardRequestOrReceivedColumnData(true, true);
      case CARD_LIST_TABS.RECEIVED_CARD_REQUEST:
        return cardRequestOrReceivedColumnData(false, true);
    }
  };

  const setRowSelection = {
    selectedRowKeys: [],
  };

  const isCardRequestList = !!(
    cardListActiveTab === CARD_LIST_TABS.RECEIVED_CARD_REQUEST || CARD_LIST_TABS.SENT_CARD_REQUEST
  );

  const tableData = Array?.from({ length: 10 }).map((_, index) => ({
    isSelected: false,
    ...getCardDetailsData({}, isCardRequestList),
    id: index?.toString(),
  }));

  return (
    <Table<ICardDetails>
      pageSize={10}
      total={totalCount}
      pageNum={currentPage}
      dataSource={tableData}
      rowSelection={cardListActiveTab === CARD_LIST_TABS.CARD_LIST ? setRowSelection : null}
    >
      {getColumnData()}
    </Table>
  );
};

export default CardSkeletonTable;
