import * as React from "react";
import { useCardDetailsEditContext, CARD_DETAILS_FIELDS_MAP } from "Views/Card/Edit/index.context";
import SpendingLimits from "Views/Card/Components/SpendingLimit";
import { ExpenseCategory } from "Views/Card/Components/ExpenseCategory";
import SpendingLock from "Views/Card/Edit/Policy/SpendingLock";
import CardPolicyReadOnlyData from "Views/Card/Edit/Policy/ReadOnlyData/index";
import { getCookieValue, cookieNames } from "utility/CookieHelper";
import { onlyPositiveIntegersWithFractionsRegEx } from "constants/RegularExpressions.constant";
import { DEFAULT_TRANSACTION_TAGS_KB_ARTICLE_LINK } from "constants/Card";
import TagsDropdown from "Views/Card/CreateOrRequest/Steps/Policy/TagsDropdown";
import { helpIcon16x16, infoIcon16x16 } from "assets/icons/card/";
import { ITag, CARD_LIMIT_TYPES } from "Views/Card/types";
import styles from "./index.module.scss";
import { Tooltip, TooltipArrowAlignmentEnum, TooltipPositionEnum } from "Modules/DS/Tooltip";
import { useCanUserEditCards, useTransactionTags } from "customHooks/Card";

export default function CardEditPolicy() {
  const { cardDetailsEditState, setCardDetailsEditState, cardData } = useCardDetailsEditContext();
  const { teamId, isCardRequestDetails } = cardData || {};
  const currencyCode = getCookieValue(cookieNames.CURRENCY_CODE);
  const canUserEditCards = useCanUserEditCards(teamId);

  const { spendingLimitType, spendingLimit, expenseCategoryId, tags, letMyManagerChooseLimit } = cardDetailsEditState;

  const onSpendingLimitChange = (limit) => {
    if (onlyPositiveIntegersWithFractionsRegEx?.test?.(limit) || limit === "") {
      setState(CARD_DETAILS_FIELDS_MAP.SPENDING_LIMIT, limit);
    }
  };
  const showTransactionTags = useTransactionTags();

  const onLetMyMangerChooseLimit = (letMyManagerChooseLimit: boolean) => {
    setState(
      CARD_DETAILS_FIELDS_MAP.SPENDING_LIMIT_TYPE,
      letMyManagerChooseLimit ? null : CARD_LIMIT_TYPES.monthlyLimit
    );
    letMyManagerChooseLimit && setState(CARD_DETAILS_FIELDS_MAP.SPENDING_LIMIT, "");
    setState(CARD_DETAILS_FIELDS_MAP.LET_MY_MANAGER_CHOOSE_LIMIT, letMyManagerChooseLimit);
  };

  const setState = (key, value) => {
    setCardDetailsEditState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const onExpenseCategorySelection = (expenseCategoryID: number) => {
    setState(CARD_DETAILS_FIELDS_MAP.EXPENSE_CATEGORY_ID, expenseCategoryID);
  };

  const onSpendingLimitTypeChange = (limitType: CARD_LIMIT_TYPES) => {
    setState(CARD_DETAILS_FIELDS_MAP.LET_MY_MANAGER_CHOOSE_LIMIT, false);
    setState(CARD_DETAILS_FIELDS_MAP.SPENDING_LIMIT_TYPE, limitType);
  };

  const onTransactionTagSelection = (data: ITag[]) => {
    setState(CARD_DETAILS_FIELDS_MAP.TAGS, data);
  };

  return (
    <>
      <p className="card-edit__container-header">Card Policy</p>
      <div className="card-edit__fields-container">
        {canUserEditCards || isCardRequestDetails ? (
          <>
            <p className="card-edit__field-header sp-margin-bottom--l">Spending Limit</p>
            <SpendingLimits
              cardLimitType={spendingLimitType}
              cardLimit={spendingLimit}
              onSpendingLimitTypeChange={onSpendingLimitTypeChange}
              onSpendingLimitChange={onSpendingLimitChange}
              currencyCode={currencyCode}
              showLetMyManagerChooseLimit={isCardRequestDetails}
              onLetMyMangerChooseLimit={onLetMyMangerChooseLimit}
              letMyManagerChooseLimit={letMyManagerChooseLimit}
            />
            <div className="create-or-request-card-steps__input-label sp-margin-top--xl">
              <p>Default Expense Category</p>
              <Tooltip
                text="Every expense in this card will be automatically categorized into the selected category."
                alignArrow={TooltipArrowAlignmentEnum.CENTER}
                position={TooltipPositionEnum.TOP}
              >
                <img src={infoIcon16x16} alt="Help Icon" />
              </Tooltip>
              <span className={styles["optional-field-label"]}>(optional)</span>
            </div>
            <ExpenseCategory selectExpenseCategoryID={expenseCategoryId} onSelect={onExpenseCategorySelection} />
            {showTransactionTags ? (
              <>
                <div className="create-or-request-card-steps__input-label sp-margin-top--xl">
                  <p>Default Transaction Tags</p>
                  <a
                    href={DEFAULT_TRANSACTION_TAGS_KB_ARTICLE_LINK}
                    className="create-or-request-card-steps__input-label__link"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Tooltip
                      text="Click to read about Transaction Tag"
                      alignArrow={TooltipArrowAlignmentEnum.CENTER}
                      position={TooltipPositionEnum.TOP}
                    >
                      <img src={helpIcon16x16} alt="Help Icon" />
                    </Tooltip>
                  </a>
                  <p className={styles["optional-field-label"]}>(optional)</p>
                </div>
                <TagsDropdown selectedTags={tags} onChange={onTransactionTagSelection} />
              </>
            ) : null}

            <p className="card-edit__field-header sp-margin-top--xl">
              <span>Spending Lock</span>
            </p>
            <SpendingLock />
          </>
        ) : (
          <CardPolicyReadOnlyData />
        )}
      </div>
    </>
  );
}
