import React from "react";
import { Redirect } from "react-router-dom";
import TaxesListing from "Views/Taxes/List";
import { useIsSessionExpired } from "customHooks/Auth/useIsSessionExpired";
import usePermissionCheck from "Permission/usePermissionCheck";
import { CASBIN_PAGES } from "Permission/Pages";
import { TAX_MENU } from "Permission/Actions";
import LoadingComponent from "Views/State/Loading/LoaderIcon";
import { useAnalytic } from "Views/Settings/hooks";
import { BodySkeleton } from "Views/Settings/components";

import "Views/Taxes/Taxes.scss";

const Taxes = ({ newSettingPage = false }: { newSettingPage?: boolean }) => {
  const permissionParam = [{ object: CASBIN_PAGES.TAX_MENU, action: TAX_MENU.READ }];
  const [IS_ALLOWED_TAX_MENU_READ, loadingPermission] = usePermissionCheck(permissionParam, true) as [boolean, boolean];
  const isSessionExpired = useIsSessionExpired();
  useAnalytic({ eventSource: "Company", eventSubSource: "Tax" });

  if (loadingPermission) {
    return newSettingPage ? <BodySkeleton paddingLess /> : <LoadingComponent />;
  }

  if (!IS_ALLOWED_TAX_MENU_READ && !isSessionExpired) {
    return <Redirect to={"/404"} />;
  }

  return (
    <div className="taxes">
      <TaxesListing newSettingPage={newSettingPage} />
    </div>
  );
};

export default Taxes;
