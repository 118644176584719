import React from "react";
import UserInitials from "./UserInitials";
import styles from "Views/Card/List/Table/CardHolder/index.module.scss";
import classNames from "classnames";

interface ICardHolderProps {
  cardHolderName: string;
  teamName: string;
  renderOnlyTeamName: boolean;
  disabledCard: boolean;
}

const CardHolder = ({ cardHolderName, teamName, renderOnlyTeamName, disabledCard }: ICardHolderProps) => {
  const getVariant = () => {
    if (disabledCard) {
      return "grey";
    }
    return renderOnlyTeamName ? "green" : "default";
  };
  const cardHolderClassName = classNames(styles["card-holder"], {
    [styles["card-holder--disabled"]]: disabledCard,
  });

  const teamNameClassName = classNames(styles["team-name--black"], {
    [styles["team-name--disabled"]]: disabledCard,
  });
  return (
    <div className={styles["container"]}>
      <UserInitials name={renderOnlyTeamName ? teamName : cardHolderName} variant={getVariant()} />
      <div className={styles["card-holder-container"]}>
        {renderOnlyTeamName ? (
          <p className={teamNameClassName}>{teamName?.toLowerCase()}</p>
        ) : (
          <>
            <p className={cardHolderClassName}>{cardHolderName?.toLowerCase()}</p>
            <p className={styles["team-name"]}>{teamName?.toLowerCase()}</p>
          </>
        )}
      </div>
    </div>
  );
};

export default CardHolder;
