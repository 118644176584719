import { ICreateForm, ISubscriptionDetail } from "Views/SubscriptionManagement/@types";
import { FORM_NAMES, PAYMENT_METHODS } from "Views/SubscriptionManagement/Constants";
import { formatDate } from "utility/DateUtilites";

export const formatDateWithZone = (date: string) => formatDate(date, "YYYY-MM-DDTHH:mm:ss") + "Z";

const getFileName = (url) => {
  if (url) {
    let fileName = url.split("/")?.at(-1)?.split("?")?.at(0) || "";
    return fileName.substring(fileName.indexOf("-") + 1).replaceAll("%", " ");
  }
  return "";
};

export const getFileListWithNames = (documents: string[]) =>
  documents.filter(Boolean).map((url) => ({
    url,
    name: getFileName(url),
  }));

export const getInitialValuesForEdit = (subscription: ISubscriptionDetail): ICreateForm => {
  const {
    vendor: { id: vendor_id = "" },
    currency_code,
    variation_percentage,
    budget: { id: budget_id = "" },
    owner: { id: owner_id = "", name: owner_name = "" },
    estimated_cost,
    rolling_frequency,
    documents,
    note = "",
    description,
    end_date,
    next_billing_date,
    review_contract_duration,
    contract_type,
    billing_frequency,
    payment,
  } = subscription || {};
  const { card_id, payment_limit_amount } = payment;
  return {
    [FORM_NAMES.VENDOR_ID]: vendor_id,
    [FORM_NAMES.VENDOR_NAME]: "",
    [FORM_NAMES.CURRENCY]: currency_code,
    [FORM_NAMES.ESTIMATED_AMOUNT]: estimated_cost,
    [FORM_NAMES.VARIATION_PERCENTAGE]: variation_percentage,
    [FORM_NAMES.BILLING_FREQUENCY]: billing_frequency,
    [FORM_NAMES.DESCRIPTION]: description,
    [FORM_NAMES.CONTRACT_TYPE]: contract_type,
    [FORM_NAMES.START_DATE]: null,
    [FORM_NAMES.END_DATE]: end_date ? formatDateWithZone(end_date) : "",
    [FORM_NAMES.NEXT_BILLING_DATE]: next_billing_date ? formatDateWithZone(next_billing_date) : "",
    [FORM_NAMES.ROLLING_FREQUENCY]: rolling_frequency ?? null,
    [FORM_NAMES.REVIEW_CONTRACT_DURATION]: review_contract_duration || null,
    [FORM_NAMES.DOCUMENTS]: documents?.filter(Boolean) || [],
    [FORM_NAMES.NOTES]: note,
    [FORM_NAMES.BUDGET_ID]: budget_id,
    [FORM_NAMES.OWNER_ID]: owner_id,
    [FORM_NAMES.OWNER_NAME]: owner_name,
    [FORM_NAMES.PAYMENT_METHOD]: card_id ? PAYMENT_METHODS.EXISTING_CARD : null,
    [FORM_NAMES.CARD_ID]: card_id || "",
    [FORM_NAMES.PAYMENT_LIMIT]: payment_limit_amount || null,
  };
};
