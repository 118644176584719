import React from "react";
import PropTypes from "prop-types";
import { Typography } from "antd";

import styles from "./StopPaymentButton.module.scss";

const StopPaymentButton = ({ onClick }) => {
  const { Text } = Typography;

  return (
    <div className={styles.container}>
      <Text className={styles.text}>Unknown payment? </Text>
      <Text type="danger" className={styles.button} onClick={onClick}>
        Stop Payment
      </Text>
    </div>
  );
};

StopPaymentButton.propTypes = { onClick: PropTypes.func };

StopPaymentButton.defaultProps = { onClick: () => {} };

export default StopPaymentButton;
