import React from "react";
import { AmountFilter } from "Modules/DS/Filter";
import { useTeamActionContext } from "Views/Team/List/Actions/context";
import { FUND_TRANSFER_FILTERS_NAMES } from "Views/Team/List/Actions/constant";
import { IDateFormProps } from "Views/Team/List/Actions/type";

interface IAmountProps {
  activeSubTab: string;
  filter: {
    min_amount: string;
    max_amount: string;
  };
}

const FundTransferAmountFilter = ({ activeSubTab, filter = { min_amount: "", max_amount: "" } }: IAmountProps) => {
  const { onApplyFilter, resetFilter } = useTeamActionContext();

  const onApply = (value: IDateFormProps) => {
    onApplyFilter({ name: FUND_TRANSFER_FILTERS_NAMES.AMOUNT, value: value });
  };

  const onClear = () => {
    resetFilter(FUND_TRANSFER_FILTERS_NAMES.AMOUNT);
  };

  return (
    <AmountFilter
      id={`${activeSubTab}_fund_transfer_amount_filter`}
      filter={filter}
      onApply={onApply}
      onClear={onClear}
      defaultValue="Amount(Min/Max)"
    />
  );
};

export default FundTransferAmountFilter;
