import React, { useState } from "react";
import styles from "./index.module.scss";
import classNames from "classnames";

import Icon from "Modules/icons";
import Button from "Modules/button";
import { greyCloseIcon } from "assets/img";
import { ModalComponent } from "Modules/DS/Modal";
import { useDeleteTeamContext } from "Views/Team/Delete/context";
import { TITLE_TYPE } from "Modules/DS/Modal/types";
import SettlementRequirement from "Views/Team/SettlementRequirement";
import { getKeyOnFirstIndex } from "Views/Team/helper";
import { DeleteTeamWarnings, MATCH_DELETE_TEXT } from "Views/Team/Delete/constant";
import TypeTextToConfirmAction from "Views/Team/TypeTextToConfirmAction";
import { useDeleteTeamModalClose } from "Views/Team/hooks/UseDeleteTeamModalClose";
import { getIsCardVendroMatchMove } from "customHooks/Card";

const ConfirmationModal = ({ title, teamName, teamId }: { title: string; teamName: string; teamId: string }) => {
  const { close } = useDeleteTeamModalClose();
  const { deleteTeam } = useDeleteTeamContext();
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
  const confirmButtonInputStyle = classNames(styles.confirm, {
    [styles["confirm-active"]]: confirmDelete,
    [styles["confirm-inactive"]]: !confirmDelete,
  });
  const handleDelete = () => deleteTeam(teamId);
  const isMatchMove = getIsCardVendroMatchMove();

  return (
    <>
      <ModalComponent.CloseButton fixToTop src={greyCloseIcon} />
      <ModalComponent.Title titleType={TITLE_TYPE.withBorder}>
        <p className={`${styles.title}`}>{title}</p>
      </ModalComponent.Title>

      <section className={`${styles.body}`}>
        <section>
          <p className={`${styles["body-title"]}`}>Proceeding to delete budget will:</p>
          <section>
            {(isMatchMove ? DeleteTeamWarnings.MATCHMOVE(teamName) : DeleteTeamWarnings.NON_MATCHMOVE(teamName)).map(
              (deleteTeamWarning) => {
                const key = getKeyOnFirstIndex(deleteTeamWarning);
                return (
                  <SettlementRequirement
                    key={key}
                    icon={<Icon key={key} src={deleteTeamWarning[key].icon} />}
                    content={deleteTeamWarning[key].content.map((warning) => (
                      <p key={warning}>{warning}</p>
                    ))}
                  />
                );
              }
            )}
          </section>
        </section>
        <TypeTextToConfirmAction
          result={setConfirmDelete}
          placeholder="Type the exact word using the same letter case"
          title={`Type ${MATCH_DELETE_TEXT} to confirm`}
          matchText={MATCH_DELETE_TEXT}
          errorText="Please enter the correct word to proceed"
        />
      </section>

      <div className={`${styles["footer"]}`}>
        <Button text="Back" rounded textButton className={`${styles["go-back"]}`} action={close} />
        <Button
          className={confirmButtonInputStyle}
          action={handleDelete}
          text="Confirm"
          rounded
          disabled={!confirmDelete}
        />
      </div>
    </>
  );
};

export default ConfirmationModal;
