import APIclient from "API/Client";
import { GetBaseAuthObject } from "../../utility";

export const FETCH_MEMBER_REQUEST = "FETCH_MEMBER_REQUEST";
export const FETCH_MEMBER_SUCCESS = "FETCH_MEMBER_SUCCESS";
export const FETCH_MEMBER_FAILURE = "FETCH_MEMBER_FAILURE";

const fetchDataReq = (data = []) => {
  return {
    type: FETCH_MEMBER_REQUEST,
    data: data,
  };
};

const fetchDataSuccess = (data) => {
  return {
    type: FETCH_MEMBER_SUCCESS,
    data: data.payload.employees,
  };
};

const fetchDataError = (data) => {
  return {
    type: FETCH_MEMBER_FAILURE,
    data: data,
  };
};

export const getAllMembersFunc = (filter) => {
  const orgId = GetBaseAuthObject().orgId;
  const queryParams = filter ? `search_filters=${JSON.stringify(filter)}` : "";
  return (dispatch) => {
    dispatch(fetchDataReq());
    APIclient.getData(`/api/v1/org/${orgId}/employees?${queryParams}`)
      .then((data) => {
        if (data.data.status === 200) {
          dispatch(fetchDataSuccess(data.data));
        } else {
          dispatch(fetchDataError(data.data));
        }
      })
      .catch((err) => {
        dispatch(fetchDataError(err));
      });
  };
};
