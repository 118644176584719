import Banner from "Modules/DS/Banner";
import React from "react";
import { ITaxCountBanner } from "Views/Taxes/types";

export const generateSentence = (count) =>
  count === 1 ? { linking: "is", word: "tax" } : { linking: "are", word: "taxes" };

const TaxCountBanner = ({ count, partnerName }: ITaxCountBanner) => {
  const sentences = generateSentence(count);

  if (!count) {
    return null;
  }

  return (
    <div className="tax-mapping__import-count-new">
      <Banner
        type="info"
        message={`There ${sentences.linking} ${count} new ${sentences.word} imported from ${partnerName}`}
      />
    </div>
  );
};

export default TaxCountBanner;
