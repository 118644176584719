import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import cn from "classnames";
import { Dropdown, Menu, Typography } from "antd";
import { SWRConfig } from "swr";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Button as SButton, UploadFilled } from "@spenmo/splice";

import {
  getOrgDetailFunc,
  getOrgConfigsFunc,
  accountingPartnerAuth,
} from "Redux/Actions";
import Simulation from "Views/Bills/Simulation";

import { DragDrop } from "Views/Bills";
import ManageDraft from "Views/Bills/ManageDraft";
import ManageRecipients from "Views/Bills/ManageRecipients";
import SubmittedBills from "Views/Bills/SubmittedBills";
import BillForm from "Views/Bills/V2/BillForm";
import RecipientSidepanel from "Views/Bills/V2/Recipient/RecipientSidepanel";
import { SidePanel } from "Modules/DS/SidePanel";
import Button, { BUTTON_SIZES, BUTTON_TYPES } from "Modules/DS/Button";
import Tabs, { ListData } from "Modules/DS/Tabs";

import XeroAction from "Views/Bills/V2/Actions/Xero";
import { useSuccessBillToast } from "Views/Bills/V2/hooks/useSuccessBillToast";
import {
  BillProvider,
  PermissionAndSettingsContext,
} from "Views/Bills/V2/context/BillContext";

import { threeDots } from "assets/img";
import { GetOrgId, getIsLimitModel } from "utility";
import { trackEvent } from "utility/analytics";
import { API_URL, BillFormType } from "Views/Bills/V2/constants";
import { getTabList } from "Views/Bills/V2/helper";
import { TABS } from "Views/Bills/const";

import styles from "./Bills.module.scss";
import { useMutableData } from "API/useData";
import { BILL_PATHS } from "Route/Bills/paths";
import useCheckOrgConfigs from "customHooks/useCheckOrgConfigs";
import { PRODUCT_NAME } from "Redux/ModularProduct";
import { COMMON_PATHS } from "Route/Common/paths";
import usePermissionCheck from "Permission/usePermissionCheck";
import { CASBIN_PAGES } from "Permission/Pages";
import { BILL_PAYMENTS_PAGE } from "Permission/Actions";
import { PermissionCheckResultWithLoading } from "Permission/types";

const { Title } = Typography;

export interface BillsV2Props {
  tabKey: string;
}

interface LocationState {
  data: {
    isSuccess: boolean;
    isApprovalEnabled: boolean;
    isBalanceSufficient: boolean;
    balance: unknown;
    saveVendorMessage: string;
    isTeamLimitExceeded: boolean;
    paymentSource: string;
    isMultipleUpload: boolean;
    toasterData: {
      message?: string;
      actionUrl?: string;
      actionLabel?: string;
    };
  };
}

const BillsV2: React.FC<BillsV2Props> = ({ tabKey }) => {
  const [paymentSimulatorVisible, setPaymentSimulatorVisible] = useState(false);
  const inputFileRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation<LocationState>();

  const isLimitModelOrg = useMemo(() => getIsLimitModel(), []);

  const submittedBillPermission = {
    object: CASBIN_PAGES.BILL_PAYMENTS_PAGE,
    action: BILL_PAYMENTS_PAGE.SUBMITTED_BILL_TAB,
  };
  const manageDraftPermission = {
    object: CASBIN_PAGES.BILL_PAYMENTS_PAGE,
    action: BILL_PAYMENTS_PAGE.MANAGE_DRAFTS_TAB,
  };
  const manageReceipientsTab = {
    object: CASBIN_PAGES.BILL_PAYMENTS_PAGE,
    action: BILL_PAYMENTS_PAGE.MANAGE_RECEIPIENTS_TAB,
  };
  const createNewBill = {
    object: CASBIN_PAGES.BILL_PAYMENTS_PAGE,
    action: BILL_PAYMENTS_PAGE.SUBMIT_BILL_PAYMENT,
  };

  const [permissions, permissionLoading] = usePermissionCheck(
    [
      submittedBillPermission,
      manageDraftPermission,
      manageReceipientsTab,
      createNewBill,
    ],
    true,
  ) as PermissionCheckResultWithLoading;

  const { data: billpayConfig, isLoading: billPayConfigLoading } =
    useMutableData(API_URL.billpayConfig);

  const { isPaymentEnabled, isPaymentRun } = useMemo(
    () => billpayConfig?.data?.payload || {},
    [billpayConfig],
  );

  const { setupAllowed } = useContext(PermissionAndSettingsContext);

  // IMPROVEMENT: this is an old code. We shouldn't use this toaster here
  // and should just put it inside the component that use it instead
  const showSuccessBillToast = useSuccessBillToast(isPaymentRun);

  const tabs = getTabList();

  const isTabFound = useMemo(
    () => tabs.find((tab) => tab.tabKey === tabKey),
    [tabs, tabKey],
  );

  const [isBpConfigEnabled, loadingBpConfig] = useCheckOrgConfigs(
    PRODUCT_NAME.BILLPAY,
    true,
  ) as [boolean, boolean];

  const handleLinkCTA = (url: string) => {
    history.push(url);
  };

  const handleOpenSidebar = (setter: Function) => () => {
    setter(true);
  };

  const handleCloseSidebar = (setter: Function) => () => {
    setter(false);
  };

  useEffect(() => {
    // Check if both loading states are false to ensure all necessary data is loaded
    if (!loadingBpConfig && !billPayConfigLoading && !permissionLoading) {
      if (
        (tabKey === TABS.SUBMITTED_BILLS.tabKey && !permissions[0]) ||
        (tabKey === TABS.MANAGE_DRAFT.tabKey && !permissions[1]) ||
        (tabKey === TABS.MANAGE_RECIPIENTS.tabKey && !permissions[2])
      ) {
        history.replace(COMMON_PATHS.PAGE_NOT_FOUND);
      }
      // Check if the billpay configuration is enabled
      if (isBpConfigEnabled) {
        // Check if the tab is not found
        if (!isTabFound) {
          // Check if payment run setting is enabled and the organization follows the limit model
          if (isPaymentRun && isLimitModelOrg) {
            // Redirect to the payment run page if conditions are met
            history.replace(BILL_PATHS.PAYMENT_RUN);
          } else {
            // Redirect to the submitted bills page if conditions are not met
            history.replace(TABS.SUBMITTED_BILLS.link);
          }
        }
      } else {
        // Redirect to the page not found if bill pay configuration is not enabled
        history.replace(COMMON_PATHS.PAGE_NOT_FOUND);
      }
    }
  }, [
    isTabFound,
    isBpConfigEnabled,
    isPaymentRun,
    billPayConfigLoading,
    loadingBpConfig,
    permissionLoading,
    history,
    tabKey,
    isLimitModelOrg,
    permissions,
  ]);

  useEffect(() => {
    dispatch(getOrgConfigsFunc(GetOrgId()));
    dispatch(getOrgDetailFunc(GetOrgId()));
    dispatch(accountingPartnerAuth());
  }, [dispatch]);

  useEffect(() => {
    if (location?.state?.data && !billPayConfigLoading) {
      showSuccessBillToast();
    }
  }, [location?.state?.data, showSuccessBillToast, billPayConfigLoading]);

  const handleChangeTab = (tabKey: string) => {
    let url;

    switch (tabKey) {
      case TABS.SUBMITTED_BILLS.tabKey: {
        if (isPaymentRun && isLimitModelOrg) url = BILL_PATHS.PAYMENT_RUN;
        else url = TABS.SUBMITTED_BILLS.link;
        break;
      }
      case TABS.MANAGE_RECIPIENTS.tabKey: {
        url = TABS.MANAGE_RECIPIENTS.link;
        break;
      }
      case TABS.MANAGE_DRAFT.tabKey: {
        url = TABS.MANAGE_DRAFT.link;
        break;
      }
      default: {
        url = TABS.CREATE_BILLS.link;
      }
    }

    history.push(url);
  };

  const renderTabContent = useCallback((tab: ListData) => {
    switch (tab.tabKey) {
      case TABS.MANAGE_DRAFT.tabKey: {
        return <ManageDraft onUpload={() => inputFileRef.current?.click()} />;
      }
      case TABS.MANAGE_RECIPIENTS.tabKey: {
        return <ManageRecipients />;
      }
      default: {
        return (
          <SubmittedBills onUpload={() => inputFileRef.current?.click()} />
        );
      }
    }
  }, []);

  const menu = (
    <Menu className={styles.dropdownMenu}>
      {isPaymentEnabled && (
        <Menu.Item>
          <XeroAction />
        </Menu.Item>
      )}
      <Menu.Item onClick={handleOpenSidebar(setPaymentSimulatorVisible)}>
        Payment simulator
      </Menu.Item>
      {setupAllowed && (
        <Menu.Item onClick={() => handleLinkCTA(`/settings/payment-run`)}>
          Payment run settings
        </Menu.Item>
      )}
    </Menu>
  );

  return (
    <SWRConfig>
      <BillProvider>
        <DragDrop ref={inputFileRef}>
          <div className={styles.spaceBetween}>
            <Title>Bill Payments</Title>
            {!permissionLoading && permissions[3] && (
              <div className={cn(styles.flex, styles.buttonGroup)}>
                {isPaymentEnabled && (
                  <SButton
                    icon={UploadFilled}
                    size="s"
                    variant="secondary"
                    onClick={() => handleLinkCTA("/bills/bulk")}
                  >
                    Upload Spreadsheet
                  </SButton>
                )}
                {!permissionLoading && (
                  <Button.Title
                    size={BUTTON_SIZES.SMALL}
                    onClick={() => {
                      handleLinkCTA(`${location.pathname}/${BillFormType.NEW}`);
                      trackEvent("New Bill Button Clicked");
                    }}
                  >
                    + Create a Bill
                  </Button.Title>
                )}
                <div className={styles.dropdown}>
                  <Dropdown
                    overlay={menu}
                    trigger={["click"]}
                    placement="bottomRight"
                  >
                    <Button.Title
                      size={BUTTON_SIZES.SMALL}
                      type={BUTTON_TYPES.SECONDARY}
                    >
                      <img src={threeDots} alt="more icon" />
                    </Button.Title>
                  </Dropdown>
                </div>
              </div>
            )}
          </div>

          <Tabs
            tabsList={tabs}
            content={renderTabContent}
            action={handleChangeTab}
            activeKey={tabKey}
            destroyInactiveTabPane
          />
        </DragDrop>
        <SidePanel
          visible={paymentSimulatorVisible}
          onClose={handleCloseSidebar(setPaymentSimulatorVisible)}
          title="Payment Simulator"
          sticky
        >
          <Simulation />
        </SidePanel>

        <BillForm />

        <RecipientSidepanel />
      </BillProvider>
    </SWRConfig>
  );
};

export default BillsV2;
