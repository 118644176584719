import React from "react";
import Modal, { ModalComponent } from "Modules/DS/Modal";
import classNames from "classnames";
import { greyCloseIcon } from "assets/img";
import IButton from "Modules/button";
import { TITLE_TYPE } from "Modules/DS/Modal/types";
import { capitalizeFirstLetter } from "utility";
import { IForceSyncProps } from "../type";

import styles from "./styles.module.scss";

const ForceSync = ({ visible, onClose, loading, onForceSync, missingFields, connected }: IForceSyncProps) => {
  const cannotProceed =
    connected && (missingFields.includes("field_category") || missingFields.includes("field_vendor"));
  const syncOrComplete = connected ? "sync" : "complete";

  const generateMessage = () => {
    if (cannotProceed) {
      return "Please ensure you choose a valid 'Category' and 'Vendor' and try again";
    }

    if (missingFields.length !== 0) {
      const message = "Some of the required fields are not filled in. Are you sure you want to continue?";
      if (connected) {
        return `${message} Once synced, any changes in details within Spenmo will no longer be reflected in Xero`;
      }
      return message;
    }
  };

  return (
    <Modal visible={visible} close={onClose} className={styles["force-sync"]}>
      <ModalComponent.CloseButton fixToTop src={greyCloseIcon} />
      <ModalComponent.Title titleType={TITLE_TYPE.withBorder}>
        Save & {syncOrComplete} this transaction anyway?
      </ModalComponent.Title>
      <section className={styles["force-sync__body"]}>
        <p>{generateMessage()}</p>
        <div className={styles["force-sync__action-btns"]}>
          <IButton text="Cancel" btnStyle="white-orange-with-border" action={onClose} />
          <IButton
            text={`Yes, Save & ${capitalizeFirstLetter(syncOrComplete)}`}
            loading={loading}
            action={onForceSync}
            disabled={cannotProceed}
            className={classNames({
              disabled: loading || cannotProceed,
            })}
          />
        </div>
      </section>
    </Modal>
  );
};

export default ForceSync;
