import React from "react";
import moment from "moment";
import { TEAM_TYPE } from "constants/Team.constant";
import { BANK_IDENTIFIER } from "Views/UploadInvoice/const";

export const renderBankIdentifierLabel = (type) => {
  for (const item in BANK_IDENTIFIER) {
    if (BANK_IDENTIFIER[item].key === type) {
      return BANK_IDENTIFIER[item].label;
    }
  }
  return "";
};

export const renderEmail = (data) =>
  data.split(",").map((item, index, arr) => (
    <>
      <a key={index} href={`mailto:${item}`}>
        {item}
      </a>
      {index !== arr.length - 1 && ", "}
    </>
  ));

export const renderDate = (data) => moment(data).format("DD MMM YYYY");

export const mapTeamToWallet = ({
  teams = [],
  isAdmin = false,
  isAccountant = false,
  isBookkeeper = false,
  isCards2 = false,
  manageTeams = [],
  saasConfig,
}) => {
  const MAIN_WALLET = saasConfig?.isPaymentEnabled ? "Company Main Balance" : "Default";
  // admin or accountant or book keeper or company that using cards 2.0 can select from all wallets
  // else manager can only select wallet from the team he/she manages
  const allWalletsAllowed = isAdmin || isAccountant || isBookkeeper || isCards2;
  const filteredData = allWalletsAllowed ? teams : teams?.filter((item) => manageTeams.includes(item.id));

  const wallet = filteredData.map((item) => ({
    amount: item?.wallet_amount,
    currency_code: item?.currency_code,
    id: item?.id,
    walletId: item?.wallet_id,
    name: item?.type === TEAM_TYPE.ORGANISATION ? MAIN_WALLET : item?.name,
    teamId: item?.id,
  }));

  // move MAIN_WALLET to first index of array
  wallet.forEach((item, index) => {
    if (item.name === MAIN_WALLET) {
      wallet.splice(index, 1);
      wallet.unshift(item);
    }
  });

  return wallet;
};

export const findDynamicFormAlias = (data) => (alias) => {
  if (!data?.dynamicFields) {
    return;
  }
  const resp = data.dynamicFields.find((item) => item?.fieldAlias === alias);
  return resp?.valueLabel;
};

export const findFieldPropsFromAlias = (data) => (alias) => {
  if (!data?.dynamicFields) {
    return;
  }
  const resp = data.dynamicFields.find((item) => item?.fieldAlias === alias);
  return resp?.fieldProps;
};
