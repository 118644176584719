import React from "react";
import Modal from "Modules/DS/Modal";
import { Title, CloseButton } from "Modules/DS/Modal/Components";
import { miniSpotSearchIcon } from "assets/img";
import Button from "Modules/button";
import styles from "./WithholdingTaxModal.module.scss";
import { currencyFormatterV2 } from "utility";

interface WithholdingTaxModalProps {
  data?: {
    taxData: {
      amount: string;
      label: string;
    };
    taxAmount: number;
    paymentAmount: string;
    paymentCurrency: string;
  };
  visible: boolean;
  close: () => void;
  action: () => void;
}

const WithholdingTaxModal = ({ data, visible, close, action }: WithholdingTaxModalProps) => {
  const taxRate = parseInt(data?.taxData?.amount);
  const taxAmount = currencyFormatterV2(data?.taxAmount, data?.paymentCurrency);

  return (
    <Modal visible={visible} close={close}>
      <Title>Confirm Withholding Tax Details</Title>
      <CloseButton fixToTop />
      <div className={styles.container}>
        <div className={styles.content}>
          <img width={120} height={120} className={styles.icon} src={miniSpotSearchIcon} alt="edit icon" />
          {data ? (
            <>
              <p>
                Recipient ATC Code and Tax Category: <strong>{data?.taxData?.label}</strong>
              </p>
              <p>
                Withholding Tax Rate: <strong>{taxRate}%</strong>
              </p>
              <p>
                Withholding Tax Amount: <strong>{taxAmount}</strong>
              </p>
            </>
          ) : (
            <p>
              Some of these bills are subjected to withholding tax. If you are sure all the entered withholding tax
              details are accurate you can proceed to submit.
            </p>
          )}
        </div>
        <div className={styles.actions}>
          <Button
            className={styles.cancel}
            action={() => {
              close();
            }}
          >
            Back to Edit Details
          </Button>
          <Button
            className={styles.confirm}
            action={() => {
              action();
              close();
            }}
          >
            Confirm
          </Button>
        </div>
        <p className={styles.disclaimer}>
          By clicking the confirm button, you agree that the information you entered in the platform is correct and
          accurate to the best of your knowledge, and you further confirm that Spenmo shall not be held liable for any
          and all damages resulting from the information you have provided.
        </p>
      </div>
    </Modal>
  );
};

export default WithholdingTaxModal;
