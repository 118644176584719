import React, { isValidElement } from "react";
import { IListProps } from "../type";
import styles from "./styles.module.scss";
import { Tooltip } from "antd";
import { infoOutlineGrey } from "assets/img";
import classNames from "classnames";
import { isBillsTrxnsPage } from "../TransactionDetail";

const List: React.FC<IListProps> = ({ details, children, page }: IListProps) => {
  return (
    <ul className={styles.list}>
      {details.map((detail) => (
        <li key={detail.id}>
          <p className={classNames(styles.key, { [styles.keyColor]: isBillsTrxnsPage(page) })}>
            {detail.key}
            {detail.tooltipContent && (
              <Tooltip title={detail.tooltipContent} placement="right">
                <img
                  height={16}
                  width={16}
                  className={styles.tooltipInfoIcon}
                  src={infoOutlineGrey}
                  alt="tooltip-info-icon"
                />
              </Tooltip>
            )}
          </p>
          <div className={classNames(styles.value, { [styles.valueColor]: isBillsTrxnsPage(page) })}>
            {isValidElement(detail.value) ? detail.value : <p>{detail.value}</p>}
            {Boolean(detail.subtitle) && (
              <p className={classNames(styles.subtitle, { [styles.keyColor]: isBillsTrxnsPage(page) })}>
                {detail.subtitle}
              </p>
            )}
          </div>
        </li>
      ))}
      {children}
    </ul>
  );
};

export default List;
