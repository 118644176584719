import React, { useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Button } from "antd";

import Icon from "Modules/icons";
import LoadingIcon from "Modules/loading";

import { darkGreyCloseIcon } from "assets/img/index";
import { ACTION } from "Views/TransactionsListing/Pending/const";

import styles from "./ApproveDeclineButton.module.scss";
import { ENABLE_CONFIRM_TYPES } from "../../../const";

const ApproveDeclineButton = ({
  onApprove,
  onDecline,
  onSave,
  isFormChanged,
  isPendingApproval,
  isSubmit,
  approvalState,
  enableSave,
  enableConfirm = ENABLE_CONFIRM_TYPES.both,
  trackApproval = () => undefined,
}) => {
  const [confirmAction, setConfirmAction] = useState(ACTION[approvalState] || "");

  const handleButtonClick = (action, source = "") => {
    if (
      action === ACTION.approve &&
      (enableConfirm === ENABLE_CONFIRM_TYPES.none || enableConfirm === ENABLE_CONFIRM_TYPES.decline)
    ) {
      trackApproval(action, source);
      onApprove();
    } else if (
      action === ACTION.decline &&
      (enableConfirm === ENABLE_CONFIRM_TYPES.none || enableConfirm === ENABLE_CONFIRM_TYPES.approve)
    ) {
      trackApproval(action, source);
      onDecline();
    } else {
      setConfirmAction(action);
    }
  };

  const renderApproverButton = isPendingApproval ? (
    <div className={styles.container}>
      <Button
        shape="round"
        size="large"
        className={classNames(styles.button, styles.approve)}
        onClick={() => handleButtonClick(ACTION.approve, "Details in Approvals")}
      >
        {ACTION.approve}
      </Button>
      <Button
        className={classNames(styles.button, styles.decline)}
        shape="round"
        size="large"
        onClick={() => handleButtonClick(ACTION.decline, "Details in Approvals")}
      >
        {ACTION.decline}
      </Button>
    </div>
  ) : null;

  const renderSaveButton = (
    <div className={styles.container}>
      <Button
        className={classNames(styles.button, styles.save)}
        shape="round"
        size="large"
        disabled={!isFormChanged}
        onClick={() => handleButtonClick(ACTION.save)}
      >
        {ACTION.save}
      </Button>
    </div>
  );

  const renderButton = enableSave ? renderSaveButton : renderApproverButton;

  const decideAction = () => {
    if (confirmAction === ACTION.approve) {
      trackApproval("Confirm Approve", "Details in Approvals");
      onApprove();
    } else if (confirmAction === ACTION.decline) {
      trackApproval("Confirm Decline", "Details in Approvals");
      onDecline();
    } else {
      onSave();
    }
  };

  const renderConfirm = (
    <>
      <div className={styles.container}>
        <Button
          shape="round"
          size="large"
          className={classNames(styles.button, {
            [styles.approve]: confirmAction === ACTION.approve,
            [styles.decline]: confirmAction === ACTION.decline,
            [styles.save]: confirmAction === ACTION.save,
          })}
          onClick={decideAction}
          disabled={isSubmit}
        >
          {isSubmit ? <LoadingIcon size="s" /> : `Confirm to ${confirmAction}`}
        </Button>
        <Icon className={styles.close} src={darkGreyCloseIcon} action={() => setConfirmAction("")} />
      </div>
    </>
  );

  return <div className={styles.wrapper}>{confirmAction === "" ? renderButton : renderConfirm}</div>;
};

ApproveDeclineButton.defaultProps = { onApprove: () => {}, onDecline: () => {}, onSave: () => {}, isSubmit: false };

ApproveDeclineButton.propTypes = {
  onApprove: PropTypes.func,
  onDecline: PropTypes.func,
  onSave: PropTypes.func,
  isSubmit: PropTypes.bool,
  isPendingApproval: PropTypes.bool,
  isFormChanged: PropTypes.bool,
  trackApproval: PropTypes.func,
  enableSave: PropTypes.bool,
  enableConfirm: PropTypes.oneOf(Object.values(ENABLE_CONFIRM_TYPES)),
};

export default ApproveDeclineButton;
