import React from "react";
import Button from "Modules/button";
import Icon from "Modules/icons";
import { useHistory } from "react-router-dom";
import { trackEvent } from "utility/analytics";
import { HomeEventName } from "Views/Wallet/Analytics";
import AddOrRequestCard from "Views/Card/Add";
import { cardCTABtn } from "Views/Home/Components/MyCards/UserCards/Utilities";

import { miniSpotActivateIcon } from "assets/img";

import styles from "./index.module.scss";

const ActivateCardBanner = () => {
  const history = useHistory();
  const cardActivateRedirection = () => {
    history.push("/profileupdate");
    trackEvent(HomeEventName.ACTIVATE_NOW_BUTTON_CLICKED);
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h4 className={styles.title}>My Cards</h4>
        <AddOrRequestCard
          onFinish={() => {
            /* do nothing*/
          }}
          buttonComponent={cardCTABtn("Add Card")}
        />
      </div>

      <div className={styles.section}>
        <h5 className={styles.subtitle}>Activate the Cards feature to access your cards</h5>
        <p className={styles.text}>
          Activate now and access multiple virtual cards that can be customized to your needs.
        </p>
        <Button className={styles.button} secondary action={cardActivateRedirection} rounded text="Activate Now" />
      </div>
      <Icon className={styles.icon} src={miniSpotActivateIcon} alt="activate wallet spotlight icon" />
    </div>
  );
};

export default ActivateCardBanner;
