import React from "react";
import classNames from "classnames";
import styles from "./index.module.scss";

interface IMiniCardProps {
  cardLastFourDigits: string;
  cardColor: string;
  disabled: boolean;
}

const MiniCard = ({ cardLastFourDigits, cardColor, disabled }: IMiniCardProps) => {
  const containerClassname = classNames(styles["mini-card"], {
    [styles["mini-card--disabled"]]: disabled,
  });
  return (
    <article className={containerClassname} style={{ backgroundColor: cardColor }}>
      <p className={styles["mini-card__number"]}>
        *** <span>{cardLastFourDigits}</span>
      </p>
    </article>
  );
};

export default MiniCard;
