import * as React from "react";
import ApprovalActions from "Views/Card/List/ApprovalActions";
import { currencyFormatterWithFractions } from "utility";
import { getCardSpendingLimit, getCardRequestTagInfo } from "utility/Card/index";
import { CARD_LIMITS_LABELS } from "Views/Card/Components/SpendingLimit/index.constants";
import { CARD_LIMIT_TYPES, CARD_STATUS, CARD_TYPE, ICardDetails } from "Views/Card/types";
import Tag from "Views/Card/Components/Tag";
import CardTitle from "Views/Card/List/Table/CardTitle";
import CardHolder from "Views/Card/List/Table/CardHolder";
import MerchantLocks from "Views/Card/List/Table/MerchantLocks";
import FuelGuage from "Views/Card/List/Table/FuelGuage";
import QuickAction from "Views/Card/List/Table/QuickAction";
import SpendingLimits from "Views/Card/List/Table/SpendingLimits";
import CardTitleShimmer from "Views/Card/List/Table/CardTitle/Shimmer";
import CardHolderShimmer from "Views/Card/List/Table/CardHolder/Shimmer";
import MerchantLocksShimmer from "Views/Card/List/Table/MerchantLocks/Shimmer";
import FuelGuageShimmer from "Views/Card/List/Table/FuelGuage/Shimmer";
import SpendingLimitsShimmer from "Views/Card/List/Table/SpendingLimits/Shimmer";
import ApprovalActionShimmer from "Views/Card/List/ApprovalActions/Shimmer";

import { IColumnData } from "Modules/DS/DataTable";

import styles from "Views/Card/List/Table/QuickAction/index.module.scss";

const formatCurrency = (amount, currencyCode) => currencyFormatterWithFractions(amount, currencyCode, 2);

export const getCardLimit = (spendingLimits) => {
  const { currency_code } = spendingLimits || {};
  const { cardLimit } = getCardSpendingLimit(spendingLimits);
  return cardLimit ? formatCurrency(cardLimit, currency_code) : CARD_LIMITS_LABELS[CARD_LIMIT_TYPES.unlimited];
};

const checkIfCardIsDisabled = (cardData: ICardDetails) => {
  const { isCardRequestDetails, cardStatus } = cardData || {};
  return cardStatus === CARD_STATUS.DISABLED && !isCardRequestDetails;
};

const getCardRequestTag = (status) => {
  const tagInfo = getCardRequestTagInfo(status);
  return <Tag tagName={tagInfo.label} tagType={tagInfo.tagType} />;
};

export const CardTypeLabels = {
  [CARD_TYPE.VIRTUAL]: "Virtual",
  [CARD_TYPE.PHYSICAL]: "Physical",
};

const renderColumn = (loading: boolean, shimmer: JSX.Element, component: JSX.Element) =>
  loading ? shimmer : component;

export const cardListColumnsData = (loading: boolean, isAdminOrManager: boolean): IColumnData<ICardDetails>[] => {
  return [
    {
      id: "CARD-COL-1",
      enabled: true,
      column: {
        title: "Card Title",
        dataIndex: "name",
        key: "name",
        width: "86%",
        render: (_, record) =>
          renderColumn(
            loading,
            <CardTitleShimmer renderCardShimmer />,
            <CardTitle displayMiniCard cardData={record} disabledCard={checkIfCardIsDisabled(record)} />
          ),
      },
    },
    {
      id: "CARD-COL-2",
      enabled: true,
      column: {
        title: isAdminOrManager ? "Cardholder" : "Budget",
        dataIndex: "cardholder_name",
        key: "cardholder_name",
        width: "80%",
        render: (_, record) =>
          renderColumn(
            loading,
            <CardHolderShimmer renderTeamNameShimmer />,
            <CardHolder
              cardHolderName={record?.cardHolder}
              teamName={record?.team}
              renderOnlyTeamName={!isAdminOrManager}
              disabledCard={checkIfCardIsDisabled(record)}
            />
          ),
      },
    },
    {
      id: "CARD-COL-3",
      enabled: true,
      column: {
        title: "Spending Lock",
        dataIndex: "spendingLocks",
        key: "merchant_logo",
        width: "50%",
        render: (spendingLocks, record) =>
          renderColumn(
            loading,
            <MerchantLocksShimmer />,
            <MerchantLocks
              merchantLocks={spendingLocks}
              disabledCard={checkIfCardIsDisabled(record)}
              spendingLockType={record?.spendingLockType}
            />
          ),
      },
    },
    {
      id: "CARD-COL-4",
      enabled: true,
      column: {
        title: "Card Limit",
        dataIndex: "fuel guage",
        key: "spendingAllowanceDetails",
        width: "100%",
        render: (_, record) =>
          renderColumn(
            loading,
            <FuelGuageShimmer />,
            <div className={styles["fuel-guage-quick-action-container"]}>
              <FuelGuage
                spendingAllowanceDetails={record?.spendingAllowanceDetails}
                cardLimitType={record?.spendingLimitType}
                disabledCard={checkIfCardIsDisabled(record)}
              />
              <QuickAction cardData={record} />
            </div>
          ),
      },
    },
  ]?.filter((column) => column?.enabled);
};

export const cardRequestOrReceivedColumnData = (
  isSentRequest: boolean,
  loading: boolean
): IColumnData<ICardDetails>[] => {
  let additionalColumn: IColumnData<ICardDetails> = {
    id: "CARD-REQ-REC-COL-7",
    enabled: true,
    column: {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: "120%",
      render: (_, record) => renderColumn(loading, <ApprovalActionShimmer />, <ApprovalActions record={record} />),
    },
  };

  if (isSentRequest) {
    additionalColumn = {
      id: "CARD-REQ-REC-COL-8",
      enabled: true,
      column: {
        title: "Status",
        dataIndex: "cardStatus",
        key: "cardStatus",
        width: "55%",
        render: (cardStatus) => renderColumn(loading, <MerchantLocksShimmer />, <>{getCardRequestTag(cardStatus)}</>),
      },
    };
  }

  return [
    {
      id: "CARD-COL-1",
      enabled: true,
      column: {
        title: "Card Title",
        dataIndex: "name",
        key: "name",
        width: "100%",
        render: (_, record) =>
          renderColumn(
            loading,
            <CardTitleShimmer renderCardShimmer={false} />,
            <CardTitle cardData={record} displayMiniCard={false} disabledCard={checkIfCardIsDisabled(record)} />
          ),
      },
    },
    {
      id: "CARD-COL-2",
      enabled: true,
      column: {
        title: isSentRequest ? "Budget" : "Cardholder",
        dataIndex: "cardholder_name",
        key: "cardholder_name",
        width: "100%",
        render: (_, record) =>
          renderColumn(
            loading,
            <CardHolderShimmer renderTeamNameShimmer />,
            <CardHolder
              cardHolderName={record?.cardHolder}
              teamName={record?.team}
              renderOnlyTeamName={isSentRequest}
              disabledCard={checkIfCardIsDisabled(record)}
            />
          ),
      },
    },
    {
      id: "CARD-COL-3",
      enabled: true,
      column: {
        title: "Spending Lock",
        dataIndex: "spendingLocks",
        key: "merchant_logo",
        width: "60%",
        render: (spendingLocks, record) =>
          renderColumn(
            loading,
            <MerchantLocksShimmer />,
            <MerchantLocks
              merchantLocks={spendingLocks}
              disabledCard={checkIfCardIsDisabled(record)}
              spendingLockType={record?.spendingLockType}
            />
          ),
      },
    },
    {
      id: "CARD-COL-4",
      enabled: true,
      column: {
        title: "Card Limit",
        dataIndex: "spending_limits",
        key: "spending_limits",
        width: isSentRequest ? "100%" : "80%",
        render: (_, record) =>
          renderColumn(
            loading,
            <SpendingLimitsShimmer />,
            <SpendingLimits
              limit={record?.spendingLimit}
              type={record?.spendingLimitType}
              isLimitSet={!record?.letMyManagerChooseLimit}
            />
          ),
      },
    },
    additionalColumn,
  ]?.filter((column) => column?.enabled);
};
