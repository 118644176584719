import React from "react";
import { Checkbox, Typography } from "@spenmo/splice";
import styles from "./styles.module.scss";
import cn from "classnames";

export const SettingLabel = ({ text }: { text: string }) => (
  <Typography variant="body-content" size="s" tag="p" className={styles.settingLabel} children={text} />
);

export const DescriptiveText = ({ description, className }: { description: string; className?: string }) => (
  <Typography
    variant="body-content"
    size="m"
    tag="p"
    children={description}
    className={cn(styles.descriptiveText, { [className]: Boolean(className) })}
  />
);

export const CheckboxWithDescription = ({
  checkboxLabel,
  checkboxProps,
  description,
}: {
  checkboxLabel: string;
  checkboxProps: {
    disabled?: boolean;
    checked?: boolean;
    onChange: (e) => void;
  };
  description?: string;
}) => {
  return (
    <section className={styles.checkboxDescContainer}>
      <Checkbox {...checkboxProps}>
        <Typography
          variant="body-content"
          size="m"
          weight={600}
          children={checkboxLabel}
          className={styles.checkboxLabel}
        />
      </Checkbox>
      {description && <DescriptiveText className={styles.checkboxDescription} description={description} />}
    </section>
  );
};
