import React, { useState, useEffect, useMemo } from "react";
import { useLocation, useHistory } from "react-router-dom";

import Filter, { Quick, DateFilter, DATE_RANGE } from "Modules/DS/Filter";
import { POPOVER_WIDTH, POSITION_ALGO_TYPE } from "Modules/DS/Popover";
import Search from "Modules/search";

import { SortFilterData, getDraftSortBy } from "../const";

import styles from "../Bills.module.scss";
import { useMutableData } from "API/useData";
import { API_URL } from "Views/Bills/V2/constants";
import { SaaSConfig } from "Views/Bills/V2/BillForm/type";

const DraftFilter = () => {
  const { pathname, search } = useLocation();
  const history = useHistory();
  const query = useMemo(() => new URLSearchParams(search), [search]);
  const startDueDate = query.get("startDueDate");
  const endDueDate = query.get("endDueDate");
  const startScheduledDate = query.get("startScheduledDate");
  const endScheduledDate = query.get("endScheduledDate");
  const startCreationDate = query.get("startCreationDate");
  const endCreationDate = query.get("endCreationDate");
  const sortDir = query.get("sortDir");
  const sortBy = query.get("sortBy");
  const sortValue = sortBy && sortDir ? [`${sortBy},${sortDir}`] : [];
  const { data: saasConfigResponse } = useMutableData(`${API_URL.saasConfig}?view=draft`);
  const saasConfig: SaaSConfig = useMemo(() => saasConfigResponse?.data?.payload, [saasConfigResponse?.data?.payload]);

  // keyword using a useState because the useLocation value is changing back to before the page is refreshed
  const [keyword, setKeyword] = useState("");

  const clearFilter = (keys) => {
    keys.forEach((key) => {
      query.delete(key);
    });

    history.push({
      pathname,
      search: query.toString() ? "?" + query.toString() : "",
    });
  };

  const handleResetFilter = () => {
    history.push(pathname);
  };

  const handleChangeSearch = (value) => {
    if (value) {
      query.set("keyword", value);
    } else {
      query.delete("keyword");
    }

    setKeyword(value);

    history.push({
      pathname,
      search: query.toString() ? "?" + query.toString() : "",
    });
  };

  const hasFilter =
    keyword ||
    sortDir ||
    sortBy ||
    startDueDate ||
    endDueDate ||
    startScheduledDate ||
    endScheduledDate ||
    startCreationDate ||
    endCreationDate;

  useEffect(() => {
    setKeyword(query.get("keyword") || "");
  }, [query]);

  return (
    <Filter className={styles.filter}>
      <Search
        className={styles.searchFilter}
        placeholder="Search for recipient or invoice number..."
        value={keyword}
        changeAction={handleChangeSearch}
        onChangeDelay={500}
      />
      <DateFilter
        id="creationDate"
        defaultValue="Created Date"
        filter={{
          [DATE_RANGE.START]: startCreationDate,
          [DATE_RANGE.END]: endCreationDate,
        }}
        onApply={(date) => {
          const { start_date, end_date } = date;
          query.set("startCreationDate", start_date);
          query.set("endCreationDate", end_date);

          history.push({
            pathname: pathname,
            search: "?" + query.toString(),
          });
        }}
        onClear={() => clearFilter(["startCreationDate", "endCreationDate"])}
      />
      <DateFilter
        id="dueDate"
        defaultValue="Due Date"
        filter={{
          [DATE_RANGE.START]: startDueDate,
          [DATE_RANGE.END]: endDueDate,
        }}
        onApply={(date) => {
          const { start_date, end_date } = date;
          query.set("startDueDate", start_date);
          query.set("endDueDate", end_date);

          history.push({
            pathname: pathname,
            search: "?" + query.toString(),
          });
        }}
        onClear={() => clearFilter(["startDueDate", "endDueDate"])}
      />
      <DateFilter
        id="scheduledDate"
        defaultValue="Payment Initiation Date"
        filter={{
          [DATE_RANGE.START]: startScheduledDate,
          [DATE_RANGE.END]: endScheduledDate,
        }}
        onApply={(date) => {
          const { start_date, end_date } = date;
          query.set("startScheduledDate", start_date);
          query.set("endScheduledDate", end_date);

          history.push({
            pathname: pathname,
            search: "?" + query.toString(),
          });
        }}
        onClear={() => clearFilter(["startScheduledDate", "endScheduledDate"])}
      />
      <Quick.Single<SortFilterData>
        filterItemProps={{
          id: "sortBy",
          popoverWidth: POPOVER_WIDTH.M,
          containerClass: ".data-table-container",
          positionAlgoType: POSITION_ALGO_TYPE.HORIZONTAL,
        }}
        presetValue=""
        displayKey="title"
        defaultValue="Sort By"
        searchable={false}
        onSelect={(selected) => {
          const [sortBy, sortDir] = selected.split(",");

          query.set("sortBy", sortBy);
          query.set("sortDir", sortDir);

          history.push({
            pathname: pathname,
            search: "?" + query.toString(),
          });
        }}
        clearFilter={() => clearFilter(["sortBy", "sortDir"])}
        states={{
          selected: String(sortValue),
          setSelected: () => {},
          setTitle: () => {},
          title: getDraftSortBy(saasConfig).find((item) => item.id === String(sortValue))?.title || "Sort By",
        }}
      >
        {getDraftSortBy(saasConfig)}
      </Quick.Single>
      {hasFilter && (
        <div className={styles.resetFilter} onClick={handleResetFilter}>
          Clear all filters
        </div>
      )}
    </Filter>
  );
};

export default DraftFilter;
