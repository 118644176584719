import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { SelectMembersList } from "Modules/SelectMembers/SelectMembersList";
import SelectMembersFromTeam from "Modules/SelectMembersFromTeam";
import Modal from "Modules/modal";
import { teamEmpFunc, getTeamList } from "Redux/Actions";
import LoadingIcon from "Modules/loading";
import "./SendMoney.scss";
import SendMoneyForm from "./SendMoneyForm";
import { trackEvent } from "utility/analytics";
import { getParamsValue } from "utility";
import { QueryParamsKeys } from "constants/MoneyQueryParams.constants";

const SendMoneyModal = ({ show, close, action, isTeam, initialValues = {}, isEdit, trackActions = false }) => {
  const dispatch = useDispatch();

  const teamReducer = useSelector((state) => state.teamReducer);
  const { data: teamData, loading: teamDataLoading } = useSelector((state) => state.teamList || {});
  const selectedTeamId = teamReducer?.selectedTeamId;

  const employeeReducer = useSelector((state) => state.empReducer);
  const employeeData = employeeReducer?.data;

  const [receiver, setReceiver] = useState(null);

  let list, loading;
  if (isTeam) {
    list = teamData?.payload?.teams;
    loading = teamDataLoading;
  } else {
    list = employeeData?.payload?.team_members;
    loading = employeeReducer?.loading;
  }

  useEffect(() => {
    if (show) {
      const [initialSelectedTeam] = getParamsValue(QueryParamsKeys.INITIAL_SELECTED_TEAM_ID);
      if (isTeam) {
        if (!teamData) dispatch(getTeamList());
      } else if (!initialSelectedTeam) {
        dispatch(teamEmpFunc(null, true));
      }
    }
  }, [dispatch, isTeam, teamData, selectedTeamId, show]);

  useEffect(() => {
    if (list && initialValues?.receiverId) {
      setReceiver(list.find((item) => initialValues?.receiverId === item.id));
    }
  }, [initialValues, list]);

  const handleAction = (values) => {
    const payload = {
      receiver,
      ...values,
    };
    close();
    action(payload);
    setReceiver(null);
  };

  const getInitialSelectedTeam = () => {
    const [initialSelectedTeam] = getParamsValue(QueryParamsKeys.INITIAL_SELECTED_TEAM_ID);
    if (!initialSelectedTeam) return null;
    const { teams = [] } = teamData?.payload || {};
    if (isTeam && list?.length > 0) {
      const selectedTeam = list?.filter((team) => team?.id === initialSelectedTeam);
      return selectedTeam?.length > 0 ? selectedTeam : null;
    } else if (!isTeam && teams?.length > 0) {
      return teams?.filter((team) => team?.id === initialSelectedTeam)?.length > 0 ? initialSelectedTeam : null;
    }
    return null;
  };

  const getSelectMemberElement = () => {
    if (initialValues?.receiverId) {
      return null;
    } else if (!isTeam) {
      return (
        <SelectMembersFromTeam
          title={"Send money to"}
          singleSelection
          onChange={(data) => {
            setReceiver(data);
          }}
          data={teamData}
          teamSelectTitle="Send money to"
          teamSelectPlaceholder="Select Budget"
          memberListTitle=""
          isSendMoneyForMMDashboardV2
          searchPlaceholder="Search Budget member"
          allowAllTeams
          noDefaultTeam
          initialSelectedTeam={getInitialSelectedTeam()}
        />
      );
    } else {
      return (
        <SelectMembersList
          list={list}
          className="send-money__modal__list"
          title="Send money to"
          isTeam={isTeam}
          subTitle={isTeam ? "Budgets" : ""}
          onChange={(value) => {
            setReceiver(value);
            trackActions &&
              trackEvent("send money to recipient selected", {
                "recipient type": isTeam ? "team" : "colleague",
              });
          }}
          singleSelection
          initialSelectedMembers={getInitialSelectedTeam()}
        />
      );
    }
  };

  return (
    <Modal show={show} close={close} className={"send-money__modal"}>
      <h1 className="send-money__modal__title">Send money to {isTeam ? "Budget" : "Colleague"}</h1>
      <div className="send-money__modal__container">
        {loading && <LoadingIcon />}
        {getSelectMemberElement()}
        <SendMoneyForm
          initialValues={initialValues}
          action={handleAction}
          isEdit={isEdit}
          isTeam={isTeam}
          receiver={receiver}
          close={close}
          trackActions={trackActions}
        />
      </div>
    </Modal>
  );
};

SendMoneyModal.propTypes = {
  action: PropTypes.func,
  close: PropTypes.func,
  isEdit: PropTypes.bool,
  isTeam: PropTypes.bool,
  show: PropTypes.bool,
  initialValues: PropTypes.object,
  trackActions: PropTypes.bool,
};

export default SendMoneyModal;
