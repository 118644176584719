/**
 * @fileoverview This module defines the Header component which renders the navigation header.
 * It includes the splice navigation header, organisation details from cookies, and an accounts popover.
 * The header utilizes several utilities and hooks to fetch and display information.
 *
 * @author Sushmitha, Bakshi
 * @created 24 Aug 2023
 * @lastModified 30 Aug 2023
 */
import React, { useContext, useEffect, useMemo, useState } from "react";
import { ChevronRight, Navigation } from "@spenmo/splice";

import { RootState } from "Redux/ConfigureStore";
import { linkedAccountSuccess } from "Redux/Actions/loginAction";

import { useAppDispatch, useAppSelector } from "hook";

import { getCookieValue, cookieNames } from "utility/CookieHelper";

import AccountsPopover from "Views/Navigation/Header/Popover";
import { NavigationContext } from "Views/Navigation/Context";
import { IHeader, ILinkedAccounts, INavigationContext } from "Views/Navigation/@types";

import styles from "./index.module.scss";

/**
 * Header
 *
 * Component that represents the navigation header in the application.
 * It displays the current organisation's name, role, and currency.
 * The header also manages the popover for switching between different linked accounts.
 *
 * @param {string} organisationName - Name of the organisation to display in the header.
 *
 * @returns {React.ReactElement} Renders the navigation header with optional popover for linked accounts.
 */
const Header: React.FC<IHeader> = ({ organisationName }: IHeader): React.ReactElement => {
  const dispatch = useAppDispatch();

  const [isActive, setIsActive] = useState(false);

  const { companyRole, currencyCode } = useMemo(() => {
    return {
      companyRole: getCookieValue(cookieNames.USER_COMPANY_ROLE),
      currencyCode: getCookieValue(cookieNames.CURRENCY_CODE),
    };
  }, []);

  const linkedAccounts: ILinkedAccounts[] =
    useAppSelector((state: RootState) => state.loginReducer?.data?.linkedAccount) || [];
  const { isPopoverOpen, setIsPopoverOpen }: INavigationContext = useContext(NavigationContext);
  const hasLinkedAccounts: boolean = linkedAccounts?.length > 0;

  useEffect(() => {
    dispatch(linkedAccountSuccess(JSON.parse(window.localStorage.getItem("linked-account")) || []));
  }, [dispatch]);

  useEffect(() => {
    setIsActive(isPopoverOpen);
  }, [isPopoverOpen]);

  return (
    <>
      <div className={styles.header} data-testid="navigation-header">
        <Navigation.Header
          className={styles.header}
          showAvatar
          isActive={isActive}
          nonInteractive={!hasLinkedAccounts}
          title={organisationName}
          subtitle={`${companyRole} (${currencyCode})`}
          onClick={() => setIsPopoverOpen(!isPopoverOpen)}
          suffixIcon={hasLinkedAccounts ? ChevronRight : undefined}
        />
        {isPopoverOpen && linkedAccounts.length > 0 && <AccountsPopover linkedAccounts={linkedAccounts} />}
      </div>
      <div className={styles.separator} />
    </>
  );
};

export default Header;
