import React, { useEffect } from "react";
import { getTimeDiffInSeconds, useQuery } from "utility";
import LoginScreenWrapper from "Modules/loginScreenWrapper";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import OTPVerification from "../../../Views/EmployeeOTP/OTPVerification";
import { OTP_REQUEST_TYPES } from "Redux/Actions/Auth/VerifyForgotOTP/types";
import {
  resetVerifyForgotOTP,
  VerifyForgotOTP,
} from "Redux/Actions/Auth/VerifyForgotOTP";
import { getCookieValue, cookieNames } from "utility/CookieHelper";
import { EXPIRED_TOKEN_CODE } from "../ChangePassword/types";
import styles from "./index.module.scss";
import { FailedAttempts } from "../Components/FailedAttempts";
import { LOGIN_PATH } from "constants/Routes.constants";
import { appNotification } from "Modules/appNotification/appNotification";
import { OTP_EXPIRES_AT_SESSION_STORAGE_NAME } from "Redux/Actions/loginAction";
import { OTP_UNBLOCK_TIME } from "constants/Auth.constant";

const ForgotPasswordOTP = (): React.ReactElement => {
  let query = useQuery();
  const OTP_LENGTH = 6;
  const isForgotPassword = getCookieValue(cookieNames.IS_FORGOT_PASSWORD);
  const dispatch = useDispatch();
  const token = query.get("t");
  const {
    loading,
    error,
    data = {},
    isSuccess,
  } = useSelector((state: any) => state.verifyForgotOTP);

  const OTP_EXPIRE_TIME = new Date(
    sessionStorage
      ?.getItem?.(OTP_EXPIRES_AT_SESSION_STORAGE_NAME)
      ?.replace(/\s/, "T") || "",
  );

  const otpTimeoutSeconds = getTimeDiffInSeconds(OTP_EXPIRE_TIME);

  useEffect((): void => {
    dispatch(resetVerifyForgotOTP());
    dispatch(VerifyForgotOTP(OTP_REQUEST_TYPES.SEND, token));
  }, [dispatch]);

  const handleOTPInput = (enteredOtp) => {
    error && dispatch(resetVerifyForgotOTP());
    if (enteredOtp?.length === OTP_LENGTH) {
      dispatch(
        VerifyForgotOTP(OTP_REQUEST_TYPES.VERIFICATION, token, {
          otp_to_test: enteredOtp,
        }),
      );
    }
  };

  const onResentOTP = (): void => {
    dispatch(VerifyForgotOTP(OTP_REQUEST_TYPES.RESEND, token));
  };

  const getForgotPasswordOTPView = (): React.ReactElement => {
    switch (true) {
      case error && data?.errors?.[0]?.code === EXPIRED_TOKEN_CODE:
        appNotification.error({
          message: "Your reset password session is expired. Please try again.",
        });
        return <Redirect to={LOGIN_PATH} />;
      // if the otpTimeoutSeconds is more than 300seconds, then we need to show failed attempts component

      /*
        We are Removing this logic from FE as it creating issues and hard to debug.
        Ideally this logic should be in BE. we should get
        { errorcode: "something" , message: "To many failed attempt"} from BE
         We will bring it back asap after BE support.
      */
      // case otpTimeoutSeconds >= OTP_UNBLOCK_TIME:
      //   return <FailedAttempts />;

      default:
        return (
          <OTPVerification
            isforgotOTP
            reFetchOtp={onResentOTP}
            forgotOTPLoding={loading}
            fetchOtpData={data}
            fetchOtpError={error}
            action={handleOTPInput}
          />
        );
    }
  };

  if (isForgotPassword && isSuccess) {
    return <Redirect to={`/changepassword?t=${token}`} />;
  }

  return (
    <LoginScreenWrapper className={styles.wrapper}>
      {getForgotPasswordOTPView()}
    </LoginScreenWrapper>
  );
};

export default ForgotPasswordOTP;
