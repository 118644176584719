export const removeDuplicates = (arr: string[]): string[] => {
  const uniqueArr: string[] = [];
  const set: Set<string> = new Set();

  for (const item of arr) {
    if (!set.has(item)) {
      set.add(item);
      uniqueArr.push(item);
    }
  }

  return uniqueArr;
};
