import APIclient from "API/Client";
import { GetUserId } from "utility";

import { trackEvent } from "utility/analytics";

import {
  IUpdateRoleError,
  IUpdateRoleSuccess,
  UPDATE_ROLE_ACTION_TYPES,
} from "Redux/Actions/Employee/Update/Role/types";

import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import { EmployeeRoleUpdateEventName } from "Views/Employee/Analytics";

export const fetchUpdateRoleRequest = (
  employeeName: string,
): {
  type: UPDATE_ROLE_ACTION_TYPES.FETCH_UPDATE_ROLE_REQUEST;
  data: { employeeName: string };
} => {
  return {
    type: UPDATE_ROLE_ACTION_TYPES.FETCH_UPDATE_ROLE_REQUEST,
    data: { employeeName },
  };
};

export const fetchUpdateRoleSuccess = (
  data: IUpdateRoleSuccess,
): {
  type: UPDATE_ROLE_ACTION_TYPES.FETCH_UPDATE_ROLE_SUCCESS;
  data: IUpdateRoleSuccess;
} => {
  return {
    type: UPDATE_ROLE_ACTION_TYPES.FETCH_UPDATE_ROLE_SUCCESS,
    data: data,
  };
};

export const fetchUpdateRoleError = (
  data: IUpdateRoleError,
): {
  type: UPDATE_ROLE_ACTION_TYPES.FETCH_UPDATE_ROLE_ERROR;
  data: IUpdateRoleError;
} => {
  return {
    type: UPDATE_ROLE_ACTION_TYPES.FETCH_UPDATE_ROLE_ERROR,
    data: data,
  };
};

export const clearUpdateRole = (): {
  type: UPDATE_ROLE_ACTION_TYPES.CLEAR_UPDATE_ROLE;
  data: {};
} => {
  return {
    type: UPDATE_ROLE_ACTION_TYPES.CLEAR_UPDATE_ROLE,
    data: {},
  };
};

export const updateRole = (
  roleId: number,
  employeeId: string,
  event: string = "",
  employeeName = "",
) => {
  return (dispatch) => {
    dispatch(fetchUpdateRoleRequest(employeeName));

    const body = {
      roleID: roleId,
      updatedBy: GetUserId(),
    };

    APIclient.putData(`/ms/spm-organization/v1/employee/${employeeId}/role`, {
      ...body,
    })
      .then((res: any) => {
        const { status, payload = {}, error } = res?.data || {};
        if (
          status > HTTP_STATUS_CODE.OK ||
          !!error ||
          (status === HTTP_STATUS_CODE.OK && !payload?.isSuccess)
        ) {
          const errorObj =
            status === HTTP_STATUS_CODE.OK
              ? { message: payload?.message || "" }
              : error;
          dispatch(fetchUpdateRoleError(errorObj));
          event && trackEvent(`${event} failed`);
          trackEvent(EmployeeRoleUpdateEventName.ROLE_UPDATE_STATUS, {
            role_update_success: false,
          });
        } else {
          dispatch(fetchUpdateRoleSuccess({ ...payload, employeeName }));
          trackEvent(EmployeeRoleUpdateEventName.ROLE_UPDATE_STATUS, {
            role_update_success: true,
          });
          event && trackEvent(`${event} success`);
        }
      })
      .catch((err) => {
        dispatch(fetchUpdateRoleError(err?.response?.data?.error || err));
        trackEvent(EmployeeRoleUpdateEventName.ROLE_UPDATE_STATUS, {
          role_update_success: false,
        });
        event && trackEvent(`${event} failed`);
      });
  };
};
