import React, { useContext } from "react";

import { trackEvent } from "utility/analytics";

import { ISelectContext, SelectContext } from "Modules/DS/Select";

import {
  TRXNS_SMART_CSV,
  TRXNS_SMART_CSV_ID,
  CSV_EXPORT_WARNING,
  SMART_CSV_LIMIT_TRANSACTION,
  CSV_EXPORT_LIMIT,
  TRXN_MODAL_TYPES,
} from "Views/Transaction/Constants";
import { TRXNS_SMART_CSV_ANALYTICS } from "Views/Transaction/Analytics";
import {
  ITrxnsDateFilterRequest,
  IDownloadAsButton,
  IExportOptions,
  ITrxnToastContext,
} from "Views/Transaction/@types";

import { csvExport, smartCSVExport, pdfExport, attachmentExport } from "assets/img";
import styles from "./Options.module.scss";
import { TrxnToastContext } from "Views/Transaction/Context";
import { TOASTER_STATUS_TYPE } from "Modules/DS/Toaster";

export const OptionButton = ({ src, alt, title, subtitle, onClick }: IDownloadAsButton) => {
  return (
    <button className={styles.container} onClick={onClick}>
      <img src={src} alt={alt} />
      <div className={styles.content}>
        <p className={styles.title}>{title}</p>
        {subtitle && <span className={styles.subtitle}>{subtitle}</span>}
      </div>
    </button>
  );
};

export const ExportOptions = <T extends ITrxnsDateFilterRequest>({
  filters = {} as T,
  totalCSVCount = 0,
  totalStatementCount = 0,
  totalReceiptsCount = 0,
  rawCsv = { enabled: false },
  customCsv = { enabled: false },
  bankStatement = { enabled: false },
  downloadReceipt = { enabled: false },
  exportRawCSV = () => null,
  emailBankStatement = () => null,
  emailDownloadReceipt = () => null,
  emailRawCSV = () => null,
  showModalType,
}: IExportOptions<T>) => {
  const { closeSelect } = useContext<ISelectContext>(SelectContext);
  const { setToaster }: ITrxnToastContext = useContext<ITrxnToastContext>(TrxnToastContext);
  const noTransactionsFoundToastError = () =>
    setToaster({
      show: true,
      type: TOASTER_STATUS_TYPE.ERROR,
      message: "Download cannot be started as there are no rows found in your current view",
    });

  const noAttachmentFoundToastError = () =>
    setToaster({
      show: true,
      type: TOASTER_STATUS_TYPE.ERROR,
      message: "Download cannot be started as there are no attachments found in your current view",
    });

  const onClickHandler = (id: string) => {
    closeSelect();
    trackEvent(TRXNS_SMART_CSV_ANALYTICS[id]);
  };

  const optionsList = [
    {
      id: TRXNS_SMART_CSV_ID.BANK_STATEMENT,
      enabled: bankStatement.enabled,
      props: {
        src: pdfExport,
        alt: "download bank statement",
        title: "Bank Statement",
        onClick: () => {
          if (totalStatementCount === 0) {
            noTransactionsFoundToastError();
          } else if (totalStatementCount > SMART_CSV_LIMIT_TRANSACTION) {
            showModalType?.(TRXN_MODAL_TYPES.BANK_STATEMENT, { emailBankStatement });
          } else {
            bankStatement.download?.();
          }
          onClickHandler(TRXNS_SMART_CSV.BANK_STATEMENT);
        },
      },
    },
    {
      id: TRXNS_SMART_CSV.RAW_CSV,
      enabled: rawCsv.enabled,
      props: {
        src: csvExport,
        alt: "download raw csv",
        title: "Raw Data CSV",
        onClick: () => {
          if (totalCSVCount === 0) {
            noTransactionsFoundToastError();
          } else if (totalCSVCount > CSV_EXPORT_LIMIT) {
            showModalType?.(TRXN_MODAL_TYPES.RAW_CSV_LIMIT, { emailRawCSV });
          } else if (totalCSVCount > CSV_EXPORT_WARNING) {
            showModalType?.(TRXN_MODAL_TYPES.RAW_CSV_WARNING, { exportRawCSV });
          } else {
            rawCsv.download?.();
          }
          onClickHandler(TRXNS_SMART_CSV.RAW_CSV);
        },
      },
    },
    {
      id: TRXNS_SMART_CSV.CUSTOM_CSV,
      enabled: customCsv.enabled,
      props: {
        src: smartCSVExport,
        alt: "download custom csv",
        title: "Custom CSV",
        subtitle: "Export data to CSV template from online accounting software.",
        onClick: () => {
          if (totalStatementCount === 0) {
            noTransactionsFoundToastError();
          } else {
            showModalType?.(TRXN_MODAL_TYPES.CUSTOM_CSV, { filters, totalCount: totalStatementCount });
          }
          onClickHandler(TRXNS_SMART_CSV.CUSTOM_CSV);
        },
      },
    },
    {
      id: TRXNS_SMART_CSV.DOWNLOAD_RECEIPT,
      enabled: downloadReceipt.enabled,
      props: {
        src: attachmentExport,
        alt: "download zip",
        title: "Attachments (.zip)",
        subtitle: "Export all receipts and invoices for transactions in view",
        onClick: () => {
          if (totalReceiptsCount === 0) {
            noAttachmentFoundToastError();
          } else {
            showModalType?.(TRXN_MODAL_TYPES.DOWNLOAD_RECEIPT, { emailDownloadReceipt });
          }
          onClickHandler(TRXNS_SMART_CSV.DOWNLOAD_RECEIPT);
        },
      },
    },
  ].filter((item) => item.enabled);

  return (
    <div className={styles.options}>
      {optionsList.map(({ id, props }) => (
        <OptionButton key={id} {...props} />
      ))}
    </div>
  );
};
