import { useSelector } from "react-redux";
import { CARD_VENDOR_TYPE } from "constants/QuickAccess.constant";

const useUOBCheck = () => {
  const COUNTRY_CODE = "ID";
  const { data = {}, loading = false } = useSelector((state: any) => state.b2bOrgDetailReducer || {});

  const isUOBCardVendor = data?.payload?.cardVendor?.trim() === CARD_VENDOR_TYPE.UOB;
  const isUOB = isUOBCardVendor && data?.payload?.countryCode === COUNTRY_CODE;

  return [isUOB, loading];
};

export default useUOBCheck;
