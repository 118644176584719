import React, { useEffect, useState } from "react";
import { SidePanel } from "Modules/DS/SidePanel";
import Tabs from "Modules/DS/Tabs";
import { ChangeLogHistoryTabs, SelfApprovalTabs, getChangelogHistoryTabs, getSelfApprovalPolicyTabs } from "../helpers";
import "./SelfApprovalSidePanel.scss";
import Header from "Views/ApprovalFlow/SelfApprovalSidePanel/Header";
import { SidePanelContent } from "Views/ApprovalFlow/SelfApprovalSidePanel/type";
import { getSelfApprovalList } from "Redux/DataCalls/ApprovalFlow.api";
import DiscardChangesModal from "./DiscardChangesModal";
import { updateSelfApprovalUsers } from "Redux/Actions/ApprovalFlow/updateSelfApprovalUsers";
import { useDispatch, useSelector } from "react-redux";
import { TAB_KEY } from "../../Approval/ApprovalCenter/types";
import useCheckOrgConfigs from "customHooks/useCheckOrgConfigs";
import { PRODUCT_NAME } from "Redux/ModularProduct";

const SelfApprovalSidePanel = ({ visible, setShowSelfApprovalPanel }) => {
  const dispatch = useDispatch();
  const [showConfirmSelfApprovalCloseModal, setShowConfirmSelfApprovalCloseModal] = useState<boolean>(false);
  const isBillPayEnabled = useCheckOrgConfigs(PRODUCT_NAME.BILLPAY);
  const isReimbursementEnabled = useCheckOrgConfigs(PRODUCT_NAME.CASH_REIMBURSEMENT);

  const approvalFlowTabs = getSelfApprovalPolicyTabs(isBillPayEnabled, isReimbursementEnabled);
  const changelogHistoryTabs = getChangelogHistoryTabs(isBillPayEnabled, isReimbursementEnabled);
  const [tabs, setTabs] = useState<SelfApprovalTabs[] | ChangeLogHistoryTabs[]>(null);
  const [activeTab, setActiveTab] = useState<SelfApprovalTabs | ChangeLogHistoryTabs>(approvalFlowTabs[0]);
  const [activeView, setActiveView] = useState<SidePanelContent>(SidePanelContent.POLICY_CONTENT);
  const [invoiceValues, setInvoiceValues] = useState([]);
  const [savedInvoiceValues, setSavedInvoiceValues] = useState([]);
  const [reimbursementValues, setReimbursementValues] = useState([]);
  const [savedReimbursementValues, setSavedReimbursementValues] = useState([]);
  const [resetDefault, setResetDefault] = useState({ [TAB_KEY.INVOICE]: false, [TAB_KEY.REIMBURSEMENT]: false });
  const updateSelfApprovalStatus = useSelector((state: any) => state?.updateSelfApprovalReducer);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDiscardButtonClicked, setisDiscardButtonClicked] = useState<boolean>(false);

  const handleSetActiveTab = (activeKey): void => {
    const filteredTab = tabs?.find((tab): Object => tab.tabKey === activeKey);
    setActiveTab(filteredTab);
  };

  const handleSidePanelClose = () => {
    if (hasChangedSelfApprovalList()) {
      setShowConfirmSelfApprovalCloseModal(true);
      setisDiscardButtonClicked(false);
    } else {
      setShowSelfApprovalPanel(false);
    }
  };

  const backProps = { onBack: null };
  const closeProps = { onClose: handleSidePanelClose };

  if (activeView === SidePanelContent.CHANGE_LOG_HISTORY) {
    backProps.onBack = () => setActiveView(SidePanelContent.POLICY_CONTENT);
    closeProps.onClose = () => setShowSelfApprovalPanel(false);
  }

  useEffect(() => {
    if (activeView === SidePanelContent.POLICY_CONTENT) {
      fetchSelfApproverList(TAB_KEY.INVOICE);
      fetchSelfApproverList(TAB_KEY.REIMBURSEMENT);
    }
  }, [updateSelfApprovalStatus]);

  const fetchSelfApproverList = (tabKey) => {
    setIsLoading(true);
    getSelfApprovalList(tabKey).then((res: any) => {
      if (tabKey === TAB_KEY.INVOICE) {
        const selfApprovers = res?.data?.payload?.users;
        setInvoiceValues(saveValues(selfApprovers, tabKey));
        setSavedInvoiceValues(saveValues(selfApprovers, tabKey));
      } else if (tabKey === TAB_KEY.REIMBURSEMENT) {
        const selfApprovers = res?.data?.payload?.users;
        setReimbursementValues(saveValues(selfApprovers, tabKey));
        setSavedReimbursementValues(saveValues(selfApprovers, tabKey));
      }
      setIsLoading(false);
    });
  };

  const saveValues = (selfApprovers, tabKey) => {
    return selfApprovers
      .filter((item) => item.displayName !== "Any Admin")
      .map((item) => {
        return {
          role: item?.assigneeRole,
          identifier: item?.identifier,
          itemKey: `${tabKey}_${item?.displayName}`,
          assignee: {
            id: item?.assigneeRole !== "admin" ? item?.identifier : null,
            name: item?.displayName,
            role: item?.assigneeRole,
          },
        };
      });
  };

  useEffect(() => {
    if (activeView === SidePanelContent.CHANGE_LOG_HISTORY) {
      setTabs(changelogHistoryTabs);
      setActiveTab(changelogHistoryTabs[0]);
    } else {
      setTabs(approvalFlowTabs);
      setActiveTab(approvalFlowTabs[0]);
    }
  }, [activeView]);

  const hasChangedSelfApprovalList = () => {
    if (
      invoiceValues.length !== savedInvoiceValues.length ||
      reimbursementValues.length !== savedReimbursementValues.length
    ) {
      return true;
    }
    return (
      invoiceValues.filter((item) => savedInvoiceValues.every((el) => el.assignee.name !== item.assignee.name)).length >
        0 ||
      reimbursementValues.filter((item) =>
        savedReimbursementValues.every((el) => el.assignee.name !== item.assignee.name)
      ).length > 0
    );
  };

  const discardChangesModal = () => {
    setShowConfirmSelfApprovalCloseModal(false);
    setShowSelfApprovalPanel(false);
    setResetDefault({ [TAB_KEY.INVOICE]: false, [TAB_KEY.REIMBURSEMENT]: false });
  };

  const saveDefaultPolicy = (processType) => {
    let payload = {
      processType,
      action: "reset",
    };
    setResetDefault({ [TAB_KEY.INVOICE]: false, [TAB_KEY.REIMBURSEMENT]: false });
    dispatch(updateSelfApprovalUsers(payload));
  };

  const saveSelectedPolicy = (processType, values) => {
    const defaultUsers = [{ assigneeRole: "admin" }];
    const selectedUsers = values.map((item) => ({
      assigneeRole: item.role,
      identifier: item.identifier || null,
    }));
    let payload = {
      processType,
      action: "modify",
      users: [...defaultUsers, ...selectedUsers],
    };
    dispatch(updateSelfApprovalUsers(payload));
  };

  const savePolicy = () => {
    if (!resetDefault[TAB_KEY.INVOICE]) {
      saveSelectedPolicy(TAB_KEY.INVOICE, invoiceValues);
    }
    if (!resetDefault[TAB_KEY.REIMBURSEMENT]) {
      saveSelectedPolicy(TAB_KEY.REIMBURSEMENT, reimbursementValues);
    }
    if (resetDefault[TAB_KEY.INVOICE]) {
      saveDefaultPolicy(TAB_KEY.INVOICE);
    }
    if (resetDefault[TAB_KEY.REIMBURSEMENT]) {
      saveDefaultPolicy(TAB_KEY.REIMBURSEMENT);
    }
    setShowConfirmSelfApprovalCloseModal(false);
  };

  return (
    <>
      <DiscardChangesModal
        setShowConfirmSelfApprovalCloseModal={setShowConfirmSelfApprovalCloseModal}
        showConfirmSelfApprovalCloseModal={showConfirmSelfApprovalCloseModal}
        discardChangesModal={discardChangesModal}
        savePolicy={savePolicy}
        isDiscardButtonClicked={isDiscardButtonClicked}
      />
      <SidePanel
        title={<Header activeView={activeView} setActiveView={setActiveView} />}
        {...backProps}
        visible={visible}
        {...closeProps}
      >
        <div className="self-approval__tab-container">
          <Tabs
            content={() =>
              activeTab?.content({
                setShowConfirmSelfApprovalCloseModal,
                values: activeTab.tabKey === TAB_KEY.INVOICE ? invoiceValues : reimbursementValues,
                setValues: activeTab.tabKey === TAB_KEY.INVOICE ? setInvoiceValues : setReimbursementValues,
                hasChangedSelfApprovalList,
                savePolicy,
                setResetDefault,
                resetDefault,
                isLoading,
                setisDiscardButtonClicked,
                activeKey: activeTab?.tabKey,
              })
            }
            tabsList={tabs}
            activeKey={activeTab?.tabKey}
            action={handleSetActiveTab}
            destroyInactiveTabPane={true}
          />
        </div>
      </SidePanel>
    </>
  );
};

export default SelfApprovalSidePanel;
