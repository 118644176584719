import React from "react";
import classNames from "classnames";
import styles from "./index.module.scss";

interface IUserInitialsProps {
  name: string;
  variant: "default" | "green" | "grey";
}

const getInitials = (name: string) => {
  const nameSplitArr = name?.trim()?.split(" ") || [];
  const initials = nameSplitArr?.reduce((acc, curr, index) => {
    if (index === 0 || index === nameSplitArr?.length - 1) {
      acc = `${acc}${curr?.charAt(0)?.toUpperCase()}`;
    }
    return acc;
  }, "");
  return initials;
};

const UserInitials = ({ name, variant }: IUserInitialsProps) => {
  const className = classNames(styles["user-initials"], {
    [styles[`user-initials--${variant}`]]: variant !== "default",
  });
  return <article className={className}>{getInitials(name)}</article>;
};

export default UserInitials;
