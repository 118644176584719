import React from "react";
import { IColumnData } from "Modules/DS/DataTable";
import { ISubscription, IVendor } from "Views/SubscriptionManagement/@types";
import { TitleWithSubTitle, VendorWithIcon, setExpiryOrRenewal, EstimatedCost, LastPayment } from "./Components";
import { Tags } from "@spenmo/splice";
import {
  SUBSCRIPTION_COLUMNS,
  SUBSCRIPTION_PARAMS,
  SUBSCRIPTION_STATUS_LABELS,
  BILLING_FREQUENCY_LABELS,
  SUBSCRIPTION_STATUS,
} from "Views/SubscriptionManagement/Constants";
import { LabelWithTooltip } from "Views/SubscriptionManagement/Create/Components";
import ShimmerEffect from "Views/Accounting/Workflow/ShimmerEffect";

import styles from "./index.module.scss";

/**
 * Represents the columns configuration for displaying subscription data in a table.
 * @returns {IColumnData<ISubscription>[]} The array of column configurations.
 */
const Columns: ({ loading }: { loading: boolean }) => IColumnData<ISubscription>[] = ({ loading }) => {
  return [
    {
      id: "SUBSCRIPTIONS-COL-1",
      enabled: true,
      column: {
        title: SUBSCRIPTION_COLUMNS.VENDOR,
        dataIndex: SUBSCRIPTION_PARAMS.VENDOR,
        key: SUBSCRIPTION_PARAMS.VENDOR,
        render: (record: IVendor) => (
          <ShimmerEffect loading={loading}>
            <VendorWithIcon record={record} />
          </ShimmerEffect>
        ),
      },
    },
    {
      id: "SUBSCRIPTIONS-COL-2",
      enabled: true,
      column: {
        width: 200,
        title: (
          <LabelWithTooltip
            className={styles.estimatedCost}
            title={SUBSCRIPTION_COLUMNS.ESTIMATED_COST}
            tooltipTitle="The cost you estimated for this subscription in a given cycle"
          />
        ),
        key: SUBSCRIPTION_PARAMS.ESTIMATED_AMOUNT,
        render: (record: ISubscription) => (
          <ShimmerEffect loading={loading}>
            <EstimatedCost record={record} />
          </ShimmerEffect>
        ),
      },
    },
    {
      id: "SUBSCRIPTIONS-COL-3",
      enabled: true,
      column: {
        width: 200,
        title: (
          <LabelWithTooltip
            className={styles.estimatedCost}
            title={SUBSCRIPTION_COLUMNS.LAST_PAYMENT}
            tooltipTitle="This is the total spend on this subscription in the previous cycle"
          />
        ),
        key: SUBSCRIPTION_PARAMS.LAST_PAYMENT_AMOUNT,
        render: (record: ISubscription) => (
          <ShimmerEffect loading={loading}>
            <LastPayment record={record} />
          </ShimmerEffect>
        ),
      },
    },
    {
      id: "SUBSCRIPTIONS-COL-4",
      enabled: true,
      column: {
        title: SUBSCRIPTION_COLUMNS.BILLING_FREQUENCY,
        dataIndex: SUBSCRIPTION_PARAMS.BILLING_FREQUENCY,
        key: SUBSCRIPTION_PARAMS.BILLING_FREQUENCY,
        render: (billing_frequency) => (
          <ShimmerEffect loading={loading}>{BILLING_FREQUENCY_LABELS[billing_frequency] || ""}</ShimmerEffect>
        ),
      },
    },
    {
      id: "SUBSCRIPTIONS-COL-5",
      enabled: true,
      column: {
        title: SUBSCRIPTION_COLUMNS.BUDGET_OWNER,
        key: SUBSCRIPTION_PARAMS.BUDGET,
        render: (record: ISubscription) => (
          <ShimmerEffect loading={loading}>
            <TitleWithSubTitle title={record.budget.name} subtitle={record.owner.name} />
          </ShimmerEffect>
        ),
      },
    },
    {
      id: "SUBSCRIPTIONS-COL-6",
      enabled: true,
      column: {
        title: SUBSCRIPTION_COLUMNS.STATUS,
        key: SUBSCRIPTION_PARAMS.STATUS,
        render: (record: ISubscription) => (
          <ShimmerEffect loading={loading}>
            <Tags.Progression
              hierarchy="default"
              label={SUBSCRIPTION_STATUS_LABELS[record.status]}
              variant={record.status === SUBSCRIPTION_STATUS.ACTIVE ? "success" : "danger"}
            />
          </ShimmerEffect>
        ),
      },
    },
    {
      id: "SUBSCRIPTIONS-COL-7",
      enabled: true,
      column: {
        title: SUBSCRIPTION_COLUMNS.EXPIRY_RENEWAL,
        dataIndex: SUBSCRIPTION_PARAMS.REVIEW_EXPIRY_DATE,
        key: SUBSCRIPTION_PARAMS.REVIEW_EXPIRY_DATE,
        render: (_: string, subscription: ISubscription) => (
          <ShimmerEffect loading={loading}>{setExpiryOrRenewal(subscription)}</ShimmerEffect>
        ),
      },
    },
  ].filter((item) => item.enabled);
};

export default Columns;
