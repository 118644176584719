import React from "react";
import { Popover, Typography } from "antd";
import classNames from "classnames";

import s from "./CategoryColumns.module.scss";
import analyticsStyles from "../../Analytics.module.scss";
import { currencyFormatterV2 as currencyFormatter } from "utility";
import {
  calculateDate,
  checkChangePercentage,
  getCurrentAndPastSpendingText,
  dateFormatter4,
} from "Views/Analytics/utils";
import { percentageStyles } from "Views/Analytics";

const { Text } = Typography;

export function getCategoryColumns(selectedCategory, filter) {
  const columns = [
    {
      title: () => (
        <div>
          {getCurrentAndPastSpendingText(filter.otherCustomFilter, false)}
          <br />
          {dateFormatter4(filter.startDate, filter.endDate)}
        </div>
      ),
      dataIndex: "leftColumn",
      key: "leftColumn",
      render: ({ category, currentSpending, color, currency }) => {
        return (
          <div
            className={s.leftCol}
            style={{ filter: selectedCategory?.category !== category && selectedCategory ? "opacity(0.5)" : undefined }}
          >
            <div
              className={classNames(color === "#C5C7CD" ? s.otherCategory : s.category)}
              style={{ background: color }}
            />
            <div className={s.content}>
              <Popover
                arrowPointAtCenter={true}
                placement="bottom"
                overlayClassName="analytics-tooltip"
                content={category}
              >
                <Text style={{ maxWidth: 100 }} ellipsis>
                  {category}
                </Text>
              </Popover>
              <span>{currencyFormatter(currentSpending, currency, true, 2)}</span>
            </div>
          </div>
        );
      },
      width: "50%",
    },
    {
      title: () => (
        <div className={classNames(analyticsStyles.tableHead, analyticsStyles.tableLastCol)}>
          {getCurrentAndPastSpendingText(filter.otherCustomFilter, true)}
          <br />
          {dateFormatter4(
            calculateDate(filter.startDate, filter.differences, filter.otherCustomFilter),
            calculateDate(filter.endDate, filter.differences, filter.otherCustomFilter, true)
          )}
        </div>
      ),
      dataIndex: "rightColumn",
      key: "rightColumn",
      render: ({ category, pastSpending, change, currency }) => {
        return (
          <div
            className={s.rightCol}
            style={{ filter: selectedCategory?.category !== category && selectedCategory ? "opacity(0.5)" : undefined }}
          >
            <span className={percentageStyles[checkChangePercentage(change)]}>
              {checkChangePercentage(change) === "positive" ? `+${change}` : change || "0%"}
            </span>
            <span>{currencyFormatter(pastSpending, currency, true, 2)}</span>
          </div>
        );
      },
      width: "50%",
    },
  ];

  return columns;
}
