import React from "react";
import { SocialMediaIcons } from "assets/icons/socialMedia";

import styles from "Views/KYX/Progress/Step/First/Form/index.module.scss";

export const NUM_OF_EMPLOYEES = [
  { label: "1-10", value: "1-10" },
  { label: "11-50", value: "11-50" },
  { label: "51-100", value: "51-100" },
  { label: "101-500", value: "101-500" },
  { label: ">500", value: ">500" },
];

export const SOCIAL_MEDIA_OPTIONS = [
  {
    label: (
      <span className={styles.socialMediaItem}>
        <img src={SocialMediaIcons.facebook} alt="icon" /> Facebook
      </span>
    ),
    value: "Facebook",
  },
  {
    label: (
      <span className={styles.socialMediaItem}>
        <img src={SocialMediaIcons.instagram} alt="icon" /> Instagram
      </span>
    ),
    value: "Instagram",
  },
  {
    label: (
      <span className={styles.socialMediaItem}>
        <img src={SocialMediaIcons.linkedin} alt="icon" /> LinkedIn
      </span>
    ),
    value: "Linkedin",
  },
  {
    label: (
      <span className={styles.socialMediaItem}>
        <img src={SocialMediaIcons.tiktok} alt="icon" /> TikTok
      </span>
    ),
    value: "Tiktok",
  },
  {
    label: (
      <span className={styles.socialMediaItem}>
        <img src={SocialMediaIcons.twitter} alt="icon" /> Twitter
      </span>
    ),
    value: "Twitter",
  },
  {
    label: (
      <span className={styles.socialMediaItem}>
        <img src={SocialMediaIcons.whatsApp} alt="icon" /> WhatsApp
      </span>
    ),
    value: "Whatsapp",
  },
  {
    label: (
      <span className={styles.socialMediaItem}>
        <img src={SocialMediaIcons.others} alt="icon" /> Others
      </span>
    ),
    value: "Others",
  },
];

export enum IS_BUSINESS_ADDRESS_OPTIONS {
  IS_COMPANY_ADDRESS = "is_company_address",
}

export const IS_BUSINESS_ADDRESS_OPTIONS_VALUE = {
  [IS_BUSINESS_ADDRESS_OPTIONS.IS_COMPANY_ADDRESS]: "true",
};

export const IS_BUSINESS_ADDRESS_OPTIONS_LABEL = {
  [IS_BUSINESS_ADDRESS_OPTIONS.IS_COMPANY_ADDRESS]: "Business Address is different from Registered Address",
};

export enum BOOLEAN_OPTIONS {
  YES = "Yes",
  NO = "No",
}

export const BOOLEAN_OPTIONS_VALUE = {
  [BOOLEAN_OPTIONS.YES]: true,
  [BOOLEAN_OPTIONS.NO]: false,
};

export const BOOLEAN_OPTIONS_LABEL = {
  [BOOLEAN_OPTIONS.YES]: "Yes",
  [BOOLEAN_OPTIONS.NO]: "No",
};

export enum MONTHLY_VALUE_OF_TRNX_SGD_OPTIONS {
  LESS_THAN_10K = "less_than_10k",
  TEN_TO_25K = "10k-25k",
  TWENTY_FIVE_TO_50K = "25k-50k",
  FIFTY_TO_100K = "50k-100k",
  HUNDERED_TO_250K = "100k-250k",
  MORE_THAN_250K = "more_than_250k",
}

export const MONTHLY_VALUE_OF_TRNX_SGD_OPTIONS_VALUE = {
  [MONTHLY_VALUE_OF_TRNX_SGD_OPTIONS.LESS_THAN_10K]: "less than 10k",
  [MONTHLY_VALUE_OF_TRNX_SGD_OPTIONS.TEN_TO_25K]: "10k-25k",
  [MONTHLY_VALUE_OF_TRNX_SGD_OPTIONS.TWENTY_FIVE_TO_50K]: "25k-50k",
  [MONTHLY_VALUE_OF_TRNX_SGD_OPTIONS.FIFTY_TO_100K]: "50k-100k",
  [MONTHLY_VALUE_OF_TRNX_SGD_OPTIONS.HUNDERED_TO_250K]: "100k-250k",
  [MONTHLY_VALUE_OF_TRNX_SGD_OPTIONS.MORE_THAN_250K]: "more than 250k",
};

export const MONTHLY_VALUE_OF_TRNX_SGD_OPTIONS_LABEL = {
  [MONTHLY_VALUE_OF_TRNX_SGD_OPTIONS.LESS_THAN_10K]: "less than 10k",
  [MONTHLY_VALUE_OF_TRNX_SGD_OPTIONS.TEN_TO_25K]: "10k-25k",
  [MONTHLY_VALUE_OF_TRNX_SGD_OPTIONS.TWENTY_FIVE_TO_50K]: "25k-50k",
  [MONTHLY_VALUE_OF_TRNX_SGD_OPTIONS.FIFTY_TO_100K]: "50k-100k",
  [MONTHLY_VALUE_OF_TRNX_SGD_OPTIONS.HUNDERED_TO_250K]: "100k-250k",
  [MONTHLY_VALUE_OF_TRNX_SGD_OPTIONS.MORE_THAN_250K]: "more than 250k",
};

export enum MONTHLY_VOLUME_OF_TRNX_OPTIONS {
  LESS_THAN_25 = "1-24",
  TWENTY_FIVE_TO_49 = "25-49",
  FIFTY_TO_99 = "50-99",
  HUNDERED_TO_249 = "100-249",
  TWO_HUNDERED_FIFTY_TO_500 = "250-499",
  MORE_THAN_500 = "more_than_500",
}

export const MONTHLY_VOLUME_OF_TRNX_OPTIONS_VALUE = {
  [MONTHLY_VOLUME_OF_TRNX_OPTIONS.LESS_THAN_25]: "1-24",
  [MONTHLY_VOLUME_OF_TRNX_OPTIONS.TWENTY_FIVE_TO_49]: "25-49",
  [MONTHLY_VOLUME_OF_TRNX_OPTIONS.FIFTY_TO_99]: "50-99",
  [MONTHLY_VOLUME_OF_TRNX_OPTIONS.HUNDERED_TO_249]: "100-249",
  [MONTHLY_VOLUME_OF_TRNX_OPTIONS.TWO_HUNDERED_FIFTY_TO_500]: "250-499",
  [MONTHLY_VOLUME_OF_TRNX_OPTIONS.MORE_THAN_500]: "more than 500",
};

export const MONTHLY_VOLUME_OF_TRNX_OPTIONS_LABEL = {
  [MONTHLY_VOLUME_OF_TRNX_OPTIONS.LESS_THAN_25]: "1-24",
  [MONTHLY_VOLUME_OF_TRNX_OPTIONS.TWENTY_FIVE_TO_49]: "25-49",
  [MONTHLY_VOLUME_OF_TRNX_OPTIONS.FIFTY_TO_99]: "50-99",
  [MONTHLY_VOLUME_OF_TRNX_OPTIONS.HUNDERED_TO_249]: "100-249",
  [MONTHLY_VOLUME_OF_TRNX_OPTIONS.TWO_HUNDERED_FIFTY_TO_500]: "250-499",
  [MONTHLY_VOLUME_OF_TRNX_OPTIONS.MORE_THAN_500]: "more than 500",
};

export enum PURPOSE_OF_OPENING_ACCOUNT_OPTIONS {
  SALARIES = "salaries",
  PAYMENT_TO_VENDORS = "payment_to_vendors",
  CASH_REIMBURSEMENT = "cash_reimbursement",
  CORPORATE_SPENDING = "corporate_spending",
  OTHER = "others",
}

export const PURPOSE_OF_OPENING_ACCOUNT_VALUE = {
  [PURPOSE_OF_OPENING_ACCOUNT_OPTIONS.SALARIES]: "Salaries",
  [PURPOSE_OF_OPENING_ACCOUNT_OPTIONS.PAYMENT_TO_VENDORS]: "Payment to Vendors",
  [PURPOSE_OF_OPENING_ACCOUNT_OPTIONS.CASH_REIMBURSEMENT]: "Cash Reimbursement",
  [PURPOSE_OF_OPENING_ACCOUNT_OPTIONS.CORPORATE_SPENDING]: "Corporate Spending",
  [PURPOSE_OF_OPENING_ACCOUNT_OPTIONS.OTHER]: "Others",
};

export const PURPOSE_OF_OPENING_ACCOUNT_LABEL = {
  [PURPOSE_OF_OPENING_ACCOUNT_OPTIONS.SALARIES]: "Salaries",
  [PURPOSE_OF_OPENING_ACCOUNT_OPTIONS.PAYMENT_TO_VENDORS]: "Payment to Vendors",
  [PURPOSE_OF_OPENING_ACCOUNT_OPTIONS.CASH_REIMBURSEMENT]: "Cash Reimbursement",
  [PURPOSE_OF_OPENING_ACCOUNT_OPTIONS.CORPORATE_SPENDING]: "Corporate Spending",
  [PURPOSE_OF_OPENING_ACCOUNT_OPTIONS.OTHER]: "Others",
};
