import { Button } from "antd";
import Modal from "Modules/DS/Modal";
import React, { useState } from "react";
import Icon from "Modules/icons";
import style from "./index.module.scss";
import { NavIcon } from "assets/v1.1/icons";
import { useMoveTeamMemberContext } from "../MoveTeamMember/context";
import {
  removeBudgetIconCards,
  removeBudgetIconReimbursement,
  removeBudgetIconInternalTrancsaction,
  removeBudgetIconWallet,
} from "assets/v1.1/icons/Flat";
import useCheckOrgConfigs from "../../../../customHooks/useCheckOrgConfigs";

interface IRemoveBudgetWarning {
  confirmRemove: () => void;
}

const RemoveBudgetProfileWarning = ({ confirmRemove }: IRemoveBudgetWarning) => {
  const { employeeName, handleClose } = useMoveTeamMemberContext();
  const [keyword, setKeyword] = useState("");
  const cardVendor = useCheckOrgConfigs("card_vendor");

  const onClickCancel = () => {
    handleClose();
  };

  const handleConfirmRemove = () => {
    if (keyword === "REMOVE") {
      confirmRemove();
    }
  };

  return (
    <Modal visible={true} className={style["allow-remove-budget-profile-modal"]}>
      <div>
        <Icon
          src={NavIcon.Navigation.Cross.Line[24]}
          alt={"close decline approval request modal"}
          action={onClickCancel}
          className={style.close_icon}
        />
        <h3 className={style.heading}>Remove {employeeName} from this Budget?</h3>
      </div>
      <div className={style.content}>
        <p className={style.proceed}>Proceeding to remove will result in these conditions:</p>
        <div className={style.background} data-show="true">
          {cardVendor === "MatchMove" && (
            <div className={style.info}>
              <div className={style.image_container}>
                <img className={style.image} src={removeBudgetIconInternalTrancsaction} />
              </div>
              <ul className={style.info__text}>
                <li>Return {employeeName} any remaining personal balance to the budget.</li>
                <li>Stop ongoing Scheduled Internal Transactions from and to {employeeName} in this budget.</li>
              </ul>
            </div>
          )}
          <div className={style.info}>
            <div className={style.image_container}>
              <img className={style.image} src={removeBudgetIconCards} />
            </div>
            <ul className={style.info__text}>
              <li>Decline any ongoing card requests from {employeeName} in this budget. </li>
              <li>If they are budget owners, card requests submitted to them can still be approved by an admin.</li>
            </ul>
          </div>
          {cardVendor === "MatchMove" && (
            <div className={style.info}>
              <div className={style.image_container}>
                <img className={style.image} src={removeBudgetIconWallet} />
              </div>
              <ul className={style.info__text}>
                <li>Decline any ongoing fund transfer requests from and to {employeeName} in the budget.</li>
              </ul>
            </div>
          )}
          <div className={`${style.info} ${style["no-margin-bottom"]}`}>
            <div className={style.image_container}>
              <img className={style.image} src={removeBudgetIconReimbursement} />
            </div>
            <ul className={style.info__text}>
              <li>Any reimbursement requests from {employeeName} will not be impacted</li>
              <li>
                If they are budget owners, any reimbursement requests submitted to them will not be impacted. The
                approver for the request(s) might change.
              </li>
            </ul>
          </div>
        </div>
        <div className={style.content__input}>
          <label>Type REMOVE to confirm</label>
          <input type="text" value={keyword} onChange={(e) => setKeyword(e.target.value)} />
        </div>
        <div className={style.action_area}>
          <div className={style.button}>
            <Button className={style.button__back} onClick={onClickCancel}>
              Back
            </Button>
            <Button className={style.button__confirm} onClick={handleConfirmRemove} disabled={keyword !== "REMOVE"}>
              Confirm
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default RemoveBudgetProfileWarning;
