import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./icon.scss";

const Icon = ({ src, alt = "image", action, className, title, iconName, id = "id", disabled, ...other }) => {
  const _className = classNames(
    "icon",
    {
      "icon--disabled": disabled,
    },
    className
  );

  const _src = iconName ? require(`../../assets/img/${iconName}.svg`) : src;

  return (
    <div id={other.targetId || id} className={_className} {...other}>
      <img id={other.targetId || ""} className={"icon__image"} onClick={action} src={_src} alt={alt} />
      {title && <p className={"icon__title"}>{title}</p>}
    </div>
  );
};

Icon.propTypes = {
  src: PropTypes.any,
  alt: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string,
  action: PropTypes.func,
  title: PropTypes.string,
  iconName: PropTypes.string,
  disabled: PropTypes.bool,
};

export default Icon;
