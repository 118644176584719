import React, { useState } from "react";
import Button from "Modules/button";
import { ISidePanelButton } from "../../type.d";
import classNames from "classnames";
import Icon from "Modules/icons";
import styles from "./styles.module.scss";
import { greyCloseIcon } from "assets/img/index";

export const ConstructiveEnableButton = ({
  action,
  text,
  disabled,
  loading,
  shouldUseColSpan,
  enableConfirmCallback,
  disabledConfirmCallback,
}: ISidePanelButton) => {
  const [confirmAction, setConfirmAction] = useState(false);

  const enableConfirm = () => {
    setConfirmAction(true);
    if (enableConfirmCallback) enableConfirmCallback();
  };

  const disableConfirm = () => {
    setConfirmAction(false);
    if (disabledConfirmCallback) disabledConfirmCallback();
  };

  if (confirmAction) {
    return (
      <div className={classNames([styles["confirm-approve"], styles["col-span-2"]])}>
        <Button
          size="l"
          rounded
          disabled={disabled || loading}
          btnStyle={classNames({
            green: !disabled && !loading,
          })}
          className={classNames([styles["confirm-approve__button"]])}
          action={action}
        >
          Confirm to Approve
        </Button>
        <Icon
          alt="close-confirm-button-icon"
          src={greyCloseIcon}
          size="2"
          action={(e) => {
            disableConfirm();
          }}
        />
      </div>
    );
  }
  return (
    <Button
      size="l"
      rounded
      disabled={disabled || loading}
      btnStyle={classNames({
        green: !disabled && !loading,
      })}
      className={classNames({ [styles["col-span-2"]]: shouldUseColSpan })}
      action={enableConfirm}
    >
      {text}
    </Button>
  );
};
