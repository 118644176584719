import { Cross } from "assets/v1.1/icons/Navigation/Cross";
import { Filter } from "assets/v1.1/icons/Navigation/Filter";
import { Chevron } from "assets/v1.1/icons/Navigation/Chevron";
import { Overflow } from "assets/v1.1/icons/Navigation/Overflow";

export const Navigation = {
  Cross,
  Filter,
  Chevron,
  Overflow,
};
