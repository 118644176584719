import * as React from "react";
import { Skeleton } from "antd";

import styles from "./index.module.scss";

const FuelGuageShimmer = () => {
  const spendLimitShimmer = (
    <div className={styles["shimmer__text-container"]}>
      <Skeleton.Input className={styles["shimmer__title"]} active />
      <Skeleton.Input className={styles["shimmer__title"]} active />
    </div>
  );
  return (
    <div data-testid="shimmer-container" className={styles["shimmer__container"]}>
      <div className={styles["shimmer__fuel-guage-container"]}>
        {spendLimitShimmer}
        <Skeleton.Input className={styles["shimmer__progress-bar"]} active />
        {spendLimitShimmer}
      </div>
      <Skeleton.Avatar className={styles["shimmer__quick-actions"]} active />
    </div>
  );
};

export default FuelGuageShimmer;
