import React, { useContext } from "react";
import PropTypes from "prop-types";

import ZenDeskChat from "Views/SpenmoSupport/ZenDeskChat";
import ContactForm from "Views/SpenmoSupport/ContactForm";
import SupportPopup from "Views/SpenmoSupport/SupportPopup";

import { SpenmoSupportContext, SUPPORT_STATUS } from "Views/SpenmoSupport/common";
import { SUPPORT_CHAT_SEARCH_PARAMS } from "Views/SpenmoSupport/common/SearchParams.constants";

// utility function
import { getParamsValue } from "utility";

const SpenmoSupport = () => {
  const [automatedMsg = ""] = getParamsValue(SUPPORT_CHAT_SEARCH_PARAMS.CHAT_SUPPORT_MESSAGE);
  const { supportStatus, showSupportChat } = useContext(SpenmoSupportContext);
  return (
    <>
      <SupportPopup />
      <ZenDeskChat />
      {supportStatus === SUPPORT_STATUS.OFFLINE && showSupportChat && <ContactForm automatedMsg={automatedMsg} />}
    </>
  );
};

SpenmoSupport.propTypes = {
  showChat: PropTypes.bool,
  onClose: PropTypes.func,
  automatedMsg: PropTypes.string,
};

export default SpenmoSupport;
