import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import qs from "query-string";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useFilter } from "Modules/DS/Filter";
import { TrxnFilterContext, TrxnMoreFilterContext } from "Views/Transaction/Context";
import { ITrxnFilter, ITrxnMoreFilter, ATTACHMENT_FILTER, IGetTrxnRequest } from "Views/Transaction/@types";
import { TABS_URL, resetBillsTrxnPaginationParams, resetPaginationParams } from "Views/Transaction/Constants";
import { useAccountingTransaction } from "../Context/AccountingTransactions";

dayjs.extend(utc);

const URL_FILTER_KEYS = [
  "start_date",
  "end_date",
  "team_id",
  "attachment",
  "has_attachment",
  "min_amount",
  "max_amount",
  "merchant",
  "types",
  "team_ids",
  "categories",
  "recipient_names",
  "requester_ids",
  "unscheduled",
];

export const TrxnFilterProvider = ({
  children,
  filters,
  onApply,
  moreFilters,
  containerClass,
}: {
  filters: ITrxnFilter;
  children: React.ReactNode;
  onApply: (value: ITrxnFilter) => void;
  moreFilters: string[];
  containerClass?: string;
}): JSX.Element => {
  const location = useLocation<{
    refetchBillList?: boolean;
  }>();

  const [clear, setClear] = useState(false);
  const [clearAll, setClearAll] = useState(false);
  const [more, setMore] = useState<ITrxnMoreFilter>({});

  // IMPROVEMENT: Shouldn't use useRef at all
  // since query should be handled in the URL instead
  // of creating a context.
  const previousFilterRef = useRef<ITrxnFilter>();

  const handleApplyFilter = (value: ITrxnFilter) => {
    const filterList = ["start_date", "end_Date", "merchant", "statusList", "min_amount", "max_amount", "unscheduled"];
    const prevFilter = previousFilterRef.current;

    const differences = filterList.some((filterKey) => prevFilter[filterKey] !== value[filterKey]);

    if (differences) {
      onApply({ ...value, page: 0 });
      return;
    }

    onApply(value);
  };

  const { onApplyFilter, resetFilter, resetAllFilters, filter, setFilter, clearFilter } = useFilter<ITrxnFilter>(
    filters,
    handleApplyFilter,
    moreFilters,
    more
  );

  const { activePageDetails, setActivePageDetails } = useAccountingTransaction();
  const activePageName = activePageDetails?.name;
  const isAllTabActive = activePageName === TABS_URL.SETTLED;
  const isBillsTabActive = activePageName === TABS_URL.BILLS;

  useEffect(() => {
    previousFilterRef.current = filter;
  }, [filter]);

  useEffect(() => {
    setActivePageDetails({
      ...activePageDetails,
      allTrxnFilters: isAllTabActive ? filter : activePageDetails?.allTrxnFilters || {},
      billTrxnFilters: isBillsTabActive ? filter : activePageDetails?.billTrxnFilters || {},
    });
  }, [filter]);

  const urlFilters = useRef({});

  const query = qs.parse(location.search);
  const {
    startDate: start_date,
    endDate: end_date,
    teamId: team_id,
    hasAttachment: has_attachment,
    tab,
    minAmount: min_amount,
    maxAmount: max_amount,
    recipient,
    types,
    teams: team_ids,
    category,
    recipientNames: recipient_names,
    requesterIds: requester_ids,
    unscheduled,
  } = query;

  const setFilterValuesFromUrl = useCallback(() => {
    if (isBillsTabActive) {
      if (recipient) {
        urlFilters.current = {
          ...urlFilters.current,
          merchant: [recipient],
        };
      }

      if (start_date && end_date) {
        urlFilters.current = {
          ...urlFilters.current,
          start_date: start_date,
          end_date: end_date,
        };
      }

      if (min_amount && max_amount) {
        urlFilters.current = {
          ...urlFilters.current,
          min_amount: min_amount,
          max_amount: max_amount,
        };
      }
    }

    if (start_date && end_date && team_id && tab === "team") {
      urlFilters.current = {
        start_date: start_date,
        end_date: end_date,
        team_id: team_id,
      };
    }

    if (isAllTabActive) {
      if (start_date && end_date) {
        urlFilters.current = {
          start_date: dayjs(String(start_date)).utc().local().toISOString(),
          end_date: dayjs(String(end_date)).endOf("d").toISOString(),
        };
      }

      if (types) urlFilters.current = { ...urlFilters.current, types: types ? String(types).split(",") : [] };
      if (category) urlFilters.current = { ...urlFilters.current, categories: category ? [category] : [] };
      if (recipient_names)
        urlFilters.current = { ...urlFilters.current, recipient_names: recipient_names ? [recipient_names] : [] };
      if (team_ids)
        urlFilters.current = {
          ...urlFilters.current,
          team_ids: team_ids ? String(team_ids).split(",") : [],
        };
      if (requester_ids && team_ids) {
        urlFilters.current = {
          ...urlFilters.current,
          requester_ids: requester_ids ? String(requester_ids).split(",") : [],
          team_ids: team_ids ? String(team_ids).split(",") : [],
        };
      }
    }

    if (unscheduled) {
      urlFilters.current = {
        ...urlFilters.current,
        unscheduled,
      };
    }

    if (has_attachment) {
      urlFilters.current = {
        ...urlFilters.current,
        [isAllTabActive ? "attachment" : "has_attachment"]: has_attachment as ATTACHMENT_FILTER,
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    setFilterValuesFromUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePageName]);

  useEffect(() => {
    moreFilters.forEach((key) => filter[key] && (more[key] = filter[key]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    URL_FILTER_KEYS.forEach((key) => {
      const currentUrlFilters = urlFilters.current as IGetTrxnRequest;

      if (!currentUrlFilters[key]) {
        return;
      }
      if (requester_ids && team_ids && key === "team_ids") {
        // If both requested_ids and team_ids exist we will not add the
        // standalone team filter on more filter, instead it will use the requestor filter
        return;
      }
      more[key] = urlFilters.current[key];
    });
    onApplyFilter(urlFilters.current);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Object.keys(urlFilters.current).length]);

  // refetching filter data for bill list
  useEffect(() => {
    if (location.state?.refetchBillList && window) {
      const windowHistory = window.history;
      const { refetchBillList, ...restState } = windowHistory.state?.state || {};
      onApplyFilter(filter);

      windowHistory.replaceState({ ...windowHistory.state, state: restState }, document.title);
    }
  }, [location.state]);

  const applyMoreFilter = <R extends unknown>(item: string, value: R) =>
    setMore((prevState) => ({ ...prevState, [item]: value }));

  const clearMoreFilter = (item: string) => {
    setMore((prev) => {
      delete prev[item];
      if (
        Object.keys(filter)
          .filter((key) => moreFilters.includes(key))
          .includes(item)
      ) {
        delete filter[item];
      }
      return { ...prev };
    });
  };

  const resetMoreFilter = () => setMore({});

  const clearFilterHandler = (keys: string[]) => {
    clearFilter(keys);
  };

  const trxnFilterProviderValue = useMemo(
    () => ({
      filter: { ...filter, ...urlFilters.current },
      clearAll,
      setClearAll,
      onApplyFilter,
      resetFilter,
      resetAllFilters,
      setFilter,
      clearFilter: clearFilterHandler,
      resetTrxnPaginationParams: isBillsTabActive ? resetBillsTrxnPaginationParams : resetPaginationParams,
      urlFilters: urlFilters.current,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filter, urlFilters]
  );

  const trxnMoreFilterProviderValue = useMemo(
    () => ({
      more,
      applyMoreFilter,
      clearMoreFilter,
      resetMoreFilter,
      clear,
      setClear,
      keys: moreFilters,
      containerClass,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [more]
  );

  return (
    <TrxnFilterContext.Provider value={trxnFilterProviderValue}>
      <TrxnMoreFilterContext.Provider value={trxnMoreFilterProviderValue}>{children}</TrxnMoreFilterContext.Provider>
    </TrxnFilterContext.Provider>
  );
};
