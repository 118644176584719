import React from "react";
import { useHistory } from "react-router-dom";

import Banner from "Modules/DS/Banner";
import { Typography } from "@spenmo/splice";

import { CARD_TXN_STATUS, IGetCardsTrxnsData } from "Views/Transaction/@types";
import { getAmountDetail, isFxTransaction } from "Views/Transaction/Cards/SidePanel/TrxnAmount/Utility";
import { SUPPORT_CHAT_SEARCH_PARAMS } from "Views/SpenmoSupport/common/SearchParams.constants";
import { SETTLED_TRXNS_STATUS } from "Views/Transaction/Constants/Cards";
import { capitalizeFirstLetter } from "utility";
import cn from "classnames";

import styles from "./index.module.scss";

interface ITrxnAmountProps {
  record: IGetCardsTrxnsData;
  transactionNumber: string;
}

const TrxnAmount = ({ record, transactionNumber }: ITrxnAmountProps) => {
  const {
    merchant = "",
    fx_type,
    fx_fee = "",
    amount = "",
    amount_currency_code,
    receiving_currency_amount,
    receiving_currency_amount_currency_code,
    txn_status,
  } = record || {};
  const history = useHistory();
  const { pathname, search = "" } = history?.location || {};
  const isDCCTrxn = fx_type?.includes("DCC_FX_RATE");
  const isFxTrxn = isFxTransaction(fx_type || "");
  const getAmountWithCurrency = (amount: string, currencyCode: string) =>
    `${currencyCode || ""} ${Math.abs(+(amount || ""))?.toFixed(2)}`;

  const getAdditionalBreakup = () => {
    const isPendingTrxn = txn_status === CARD_TXN_STATUS.PENDING;
    const trxnAmountWithFx = Number(amount) - Number(fx_fee);
    return (
      <>
        {getAmountDetail(
          "Paid Amount",
          getAmountWithCurrency(trxnAmountWithFx.toString(), amount_currency_code),
          isPendingTrxn ? "Transaction amount may differ once they are settled" : ""
        )}
        {getAmountDetail(
          "Fee",
          getAmountWithCurrency(fx_fee, amount_currency_code),
          "This includes Dynamic Currency Conversion (DCC) fee"
        )}
      </>
    );
  };

  const requestFullBreakDown = () => {
    const searchParams = new URLSearchParams(search);
    searchParams.set(SUPPORT_CHAT_SEARCH_PARAMS.SHOW_SUPPORT_CHAT, "true");
    searchParams.set(
      SUPPORT_CHAT_SEARCH_PARAMS.CHAT_SUPPORT_MESSAGE,
      `I require full break up of the transaction for Transaction Number:${transactionNumber}`
    );
    history.push({
      pathname,
      search: searchParams.toString(),
    });
  };

  const message = (
    <>
      For a more detailed transaction breakdown for billing,{" "}
      <button onClick={requestFullBreakDown} className={styles.bannerCta}>
        request full transaction breakdown
      </button>
    </>
  );
  const showBanner = SETTLED_TRXNS_STATUS.includes(txn_status);
  return (
    <div className={styles.container}>
      <section className={cn(styles.sectionContainer, { [styles.containerWithInfoBanner]: showBanner })}>
        <Typography size="s" variant="body-content" className={styles.merchantHeader} tag="p">
          Merchant
        </Typography>
        <Typography size="m" variant="body-content" weight={600} className={styles.merchant} tag="p">
          {capitalizeFirstLetter(merchant?.toLowerCase())}
        </Typography>
        <Typography size="l" variant="headline-content" className={styles.amount}>
          {getAmountWithCurrency(amount, amount_currency_code)}
        </Typography>

        {isFxTrxn && (
          <Typography size="l" variant="body-content" tag="p" className={styles.fxAmount}>
            {getAmountWithCurrency(receiving_currency_amount, receiving_currency_amount_currency_code)}
          </Typography>
        )}

        {isDCCTrxn && (
          <>
            <div className={styles.divider} />
            {getAdditionalBreakup()}
            <div className={styles.dashedDivider} />
            {getAmountDetail("Total Paid Amount", getAmountWithCurrency(amount, amount_currency_code))}
          </>
        )}
      </section>
      {showBanner && (
        <Banner
          type="info"
          className={styles.banner}
          message={
            <Typography variant="body-content" size="caption-m" tag="p">
              {message}
            </Typography>
          }
        />
      )}
    </div>
  );
};

export default TrxnAmount;
