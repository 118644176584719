import * as React from "react";
import { Typography } from "antd";
import { useCardDetailsContext } from "Views/Card/Details/index.context";
import { getIsCardVendorNium } from "customHooks/Card";

import CardCvv from "Views/Card/Details/Card/V2/Cvv";
import CardNumber from "Views/Card/Details/Card/V2/Number";
import { CARD_COLORS } from "constants/Card/CardColors.constants";

import { visaIconWhite61x31 } from "assets/icons/card";
import { vendor_logo } from "assets/img";
import { spenmoOrangeLogoLarge, spenmoWhiteLogoLarge } from "assets/v1.1/logo/spenmo";

import styles from "Views/Card/Details/Card/V2/index.module.scss";

const { Text } = Typography;

const CardV2 = () => {
  const { cardData } = useCardDetailsContext();
  const { cardHolder, cardColor, id, cardType, cardLastFourDigits, cardExpiryMonth, cardExpiryYear } = cardData || {};

  const isCardVendorNium = getIsCardVendorNium();

  return (
    <div
      className={styles["card-v2"]}
      style={{
        backgroundColor: cardColor,
      }}
      data-testid="card-v2"
    >
      <img
        src={cardColor === CARD_COLORS[0] ? spenmoOrangeLogoLarge : spenmoWhiteLogoLarge}
        alt={"spenmo logo"}
        width={30}
        height={32}
      />
      <Text className={styles["card-type"]}>{`${cardType} Card`}</Text>
      <Text className={styles["card-title"]} ellipsis>
        {cardHolder}
      </Text>
      <CardNumber cardLastFourDigits={cardLastFourDigits} cardId={id} cardColor={cardColor} />
      <div className={styles["card-expiry-cvv-container"]}>
        <Text className={styles["info-label"]}>{"VALID\nTHRU"}</Text>
        <p className={styles["expiry"]}>{`${cardExpiryMonth}/${+cardExpiryYear.substring(2)}`}</p>
        <p className={styles["info-label"]}>{"SECURITY\nCODE"}</p>
        <CardCvv cardId={id} cardColor={cardColor} />
      </div>
      <div className={styles["footer"]}>
        <img src={isCardVendorNium ? visaIconWhite61x31 : vendor_logo} alt="vendor icon" width={61} height={31} />
      </div>
    </div>
  );
};

export default CardV2;
