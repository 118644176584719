import React, { forwardRef, useCallback, useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { BulbFilled, CheckOutline, Typography, Tooltip } from "@spenmo/splice";
import { Input as AntdInput } from "antd";
import qs from "query-string";
import cn from "classnames";
import useSWRMutation from "swr/mutation";

import { BIC_TYPE } from "Modules/DynamicForm/constants";
import Loading3Quaters from "Modules/loading/loading3Quaters";
import { BankValidationProps, BankValidationErrorMessage } from "Views/Bills/V2/components/FormGenerator/type";
import Input from "Views/Bills/V2/components/FormGenerator/Input";
import { API_URL } from "Views/Bills/V2/constants";
import { GetBaseAuthObject, debounce } from "utility";
import { BankValidatorTypes } from "./type";
import styles from "./BankValidation.module.scss";
import { getData } from "API/Client";

const BankValidation = forwardRef((props: Partial<BankValidationProps>, ref: React.RefObject<AntdInput>) => {
  const { name, pattern, placeholder, value, ...rest } = props;

  const {
    watch,
    register,
    setError,
    formState: { errors },
  } = useFormContext();

  const fieldName = useMemo((): string => name.split(".")?.[1], [name]);
  const senderCountryCode = GetBaseAuthObject()?.orgCountryCode;

  const [bankValue, vendorCountryCode] = watch(["dynamicFields.beneficiaryBankName", "countryCode"]);

  const apiURL = qs.stringifyUrl({
    url: `${API_URL.disbursementV1}/bank-analytic/${BIC_TYPE[fieldName]}/${value}/verify`,
    query: {
      senderCountry: senderCountryCode,
      receiverCountry: vendorCountryCode,
      spenmoCode: bankValue,
    },
  });

  const { data, trigger, isMutating, error } = useSWRMutation(apiURL, (url, { arg }) => getData(url));

  const verifyResponse = data?.data?.payload;

  const verifyBIC = useCallback(debounce(trigger), []);

  const validateBIC = useCallback(
    (value: string): string | boolean => {
      const label = placeholder.replace("Enter ", "");
      const hasBankValue = Boolean(bankValue);
      const isAustralia = "au" === vendorCountryCode?.toLowerCase();
      const countryCode = value?.slice(0, 2);

      switch (true) {
        case fieldName === BankValidatorTypes.BSB_CODE && !isAustralia: {
          // Note: BSB code is only used for Australia country
          return `${label} ${BankValidationErrorMessage.ERROR_COUNTRY_MISMATCH}`;
        }
        case fieldName === BankValidatorTypes.IBAN_CODE &&
          countryCode &&
          countryCode.toLowerCase() !== vendorCountryCode?.toLowerCase(): {
          return `${label} Code ${BankValidationErrorMessage.ERROR_COUNTRY_MISMATCH}`;
        }
        case fieldName === BankValidatorTypes.SORT_CODE: {
          // Note: For sort code no API validation is required
          return true;
        }
        case !hasBankValue: {
          setError("dynamicFields.beneficiaryBankName", {
            message: BankValidationErrorMessage.REQUIRED_FIELD_ERROR,
          });

          return true;
        }
        case fieldName === BankValidatorTypes.IBAN_CODE && hasBankValue && verifyResponse?.code === 4008: {
          // Note: when it's IBAN and the bank value is not empty, need to check the verificationResponse code
          return verifyResponse.message;
        }
        default: {
          return true;
        }
      }
    },
    [fieldName, verifyResponse?.code, verifyResponse?.message, bankValue, placeholder, vendorCountryCode, setError]
  );

  useEffect(() => {
    if (bankValue && vendorCountryCode && value) {
      verifyBIC();
    }
  }, [bankValue, vendorCountryCode, value]);

  const inputSuffix = useMemo(() => {
    if (isMutating) return <Loading3Quaters color="orange" size="24" />;
    if (verifyResponse) {
      if (verifyResponse.isMatch) {
        return <CheckOutline iconColor="currentColor" size="24" className={styles.successIcon} />;
      } else {
        if (fieldName === BankValidatorTypes.IBAN_CODE && verifyResponse.code === 4008) {
          // force setError since the response might change from the bankValue and not the value
          setError(name, {
            message: verifyResponse.message,
          });
        } else {
          return (
            <Tooltip data-testid="tooltip-icon" placement="left" title={verifyResponse.message}>
              <BulbFilled iconColor="currentColor" className={styles.suggestionIcon} size="24" />
            </Tooltip>
          );
        }
      }
    }

    return <></>;
  }, [fieldName, isMutating, name, setError, verifyResponse]);

  return (
    <div className={styles.bankValidation}>
      <Input
        className={cn(styles.input, {
          [styles.requiredBorder]: errors?.dynamicFields?.[fieldName],
        })}
        type="text"
        placeholder={placeholder}
        suffix={inputSuffix}
        value={value}
        {...register(name, {
          validate: validateBIC,
        })}
        {...rest}
      />
      {error && (
        <div>
          <Typography className={styles.validatedFailed} variant="body-content" tag="div" size="s">
            This code cannot be validated now.
          </Typography>
          <Typography className={styles.tryAgain} onClick={trigger} variant="body-content" tag="div" size="s">
            Try again
          </Typography>
        </div>
      )}
    </div>
  );
});

export default BankValidation;
