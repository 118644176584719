import React from "react";
import InfoUnit from "../../Modules/InfoUnit/InfoUnit";
import { currencyFormatter } from "../../utility";
import Icon from "../../Modules/icons";
import LoadingIcon from "../../Modules/loading";

const TopUpBalance = ({ orgWallet, iconAction }) => {
  const Balance = () => {
    if (!orgWallet) {
      return <LoadingIcon />;
    }

    return (
      <>
        <Icon iconName={"refresh-2"} className={"sp-margin-right--s"} action={iconAction} />
        {orgWallet ? (
          <div>{currencyFormatter(orgWallet.wallet_amount, orgWallet.currency_code)}</div>
        ) : (
          <div className={"text--danger"}>Unable to get company balance</div>
        )}
      </>
    );
  };

  return (
    <InfoUnit className={"topup__balance-amount"} align={"end"} label={"Current Company Balance"} value={<Balance />} />
  );
};

export default TopUpBalance;
