import React, { useEffect } from "react";
import { useSettingContext } from "../context/SettingContext";
import { findSingleSettingConfig } from "../helper/dataMapping";
import { CheckboxWithDescription, SettingLabel } from "../components";
import { Col, Row } from "Splice/Grid";
import { useIsSaasWithPaymentsOrg } from "customHooks/useIsSaasWithPaymentsOrg";

const AUTO_RECONCILE = "auto_reconcile";
const SYNC_BILL_IN_DASHBOARD_CURRENCY = "sync_bill_in_dashboard_currency";

const ReconciliationSetting = ({ settingUpdateNotAllowed }: { settingUpdateNotAllowed: boolean }) => {
  const {
    usingExpenseIntegration,
    usingBankFeedIntegration,
    globalConfig,
    setGlobalConfig,
    enabledExpenseSync,
    setEnabledExpenseSync,
  } = useSettingContext();
  const isPaymentEnabled = useIsSaasWithPaymentsOrg();
  const manualReconcileEnabled = findSingleSettingConfig(AUTO_RECONCILE, globalConfig)?.isEnabled || false;
  const syncBillInDashboardCurrency =
    findSingleSettingConfig(SYNC_BILL_IN_DASHBOARD_CURRENCY, globalConfig)?.isEnabled || false;
  const bankfeedWithExpenseIntegration = usingExpenseIntegration && usingBankFeedIntegration;
  const onlyExpenseIntegration = !usingBankFeedIntegration && usingExpenseIntegration;

  useEffect(() => {
    setEnabledExpenseSync(bankfeedWithExpenseIntegration);
  }, [bankfeedWithExpenseIntegration, setEnabledExpenseSync]);

  const updateGlobalConfig = ({ key }: { key: string }) => {
    const newGlobalConfig = globalConfig.map((config) => ({
      ...config,
      isEnabled: key === config.code ? !config.isEnabled : config.isEnabled,
    }));
    setGlobalConfig(newGlobalConfig);
  };

  const showSyncBillInDashboardCurrency = () =>
    usingBankFeedIntegration ? enabledExpenseSync : usingExpenseIntegration;

  return (
    <>
      <Row column={16} offset={0} gutter={[0, 0]}>
        <Col span={16}>
          <SettingLabel text="Sync Configuration" />
          {usingBankFeedIntegration && (
            <CheckboxWithDescription
              checkboxLabel="Sync Expense Data"
              checkboxProps={{
                checked: enabledExpenseSync,
                onChange: () => setEnabledExpenseSync(!enabledExpenseSync),
                disabled: settingUpdateNotAllowed || !isPaymentEnabled,
              }}
              description="Sync your expense details (Receipts, Category, etc.) to Xero. Expenses synced this way will show up as items for reconciliation in Xero."
            />
          )}
        </Col>
      </Row>
      {onlyExpenseIntegration && (
        <Row column={16} offset={0} gutter={[0, 24]}>
          <Col span={16}>
            <CheckboxWithDescription
              checkboxLabel="Automatic Reconciliation"
              checkboxProps={{
                checked: manualReconcileEnabled,
                onChange: () => updateGlobalConfig({ key: AUTO_RECONCILE }),
                disabled: settingUpdateNotAllowed,
              }}
              description="Spenmo will automatically reconcile synchronized transactions in Xero"
            />
          </Col>
        </Row>
      )}
      {showSyncBillInDashboardCurrency() && (
        <Row column={16} offset={0} gutter={[0, 24]}>
          <Col span={16}>
            <CheckboxWithDescription
              checkboxLabel="Keep foreign currency for Bills"
              checkboxProps={{
                checked: !syncBillInDashboardCurrency, // follows reverse logic, i.e., when flag is false then it's checked
                onChange: () => updateGlobalConfig({ key: SYNC_BILL_IN_DASHBOARD_CURRENCY }),
                disabled: settingUpdateNotAllowed,
              }}
              description="Bills in a foreign currency will be synchronized to Xero in the same currency it was created in"
            />
          </Col>
        </Row>
      )}
    </>
  );
};

export default ReconciliationSetting;
