import { IBill } from "Views/Bills/ImportBillFromXero";

export const sortBill = (key) => (a: IBill, b: IBill) => {
  switch (key) {
    case "created_date_newest_first":
      return new Date(b.date).getTime() - new Date(a.date).getTime();
    case "created_date_oldest_first":
      return new Date(a.date).getTime() - new Date(b.date).getTime();
    case "due_date_newest_first":
      return new Date(b.dueDate).getTime() - new Date(a.dueDate).getTime();
    case "due_date_oldest_first":
      return new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime();
    case "amount_highest_first":
      return b.total - a.total;
    case "amount_lowest_first":
      return a.total - b.total;
    default:
      return 0;
  }
};

export const filterByReceipient = (data: IBill[], recipient: string[]) => {
  const lowerCaseRecipient = recipient.map((recipient) => recipient.toLowerCase());
  return data.filter((bill) => lowerCaseRecipient.includes(bill.recipient.toLowerCase()));
};

export const filterByReference = (data: IBill[], reference: string) => {
  return data.filter((bill) => bill.reference.toLowerCase().includes(reference.toLowerCase()));
};

const generateDate = (initialDate, targetedDate) => {
  const startDate = new Date(initialDate);
  startDate.setHours(0, 0, 0);
  const endDate = new Date(targetedDate);
  endDate.setHours(0, 0, 0);
  return [startDate, endDate];
};

export const filterByStartDate = (data: IBill[], key: string, value: string) => {
  return data.filter((bill) => {
    const [date, startDate] = generateDate(bill[key], value);
    return date.getTime() >= startDate.getTime();
  });
};

export const filterByEndDate = (data: IBill[], key: string, value: string) => {
  return data.filter((bill) => {
    const [date, endDate] = generateDate(bill[key], value);
    return date.getTime() <= endDate.getTime();
  });
};

export const generateFilteredTransaction = (originalData, filter) => {
  const { sort, recipient, dueDateStartDate, dueDateEndDate, startDate, endDate, reference } = filter;
  let filteredTransasction = [...originalData];

  const functionGenerator = {
    recipient: () => filterByReceipient(filteredTransasction, recipient),
    dueDateStartDate: () => filterByStartDate(filteredTransasction, "dueDate", dueDateStartDate),
    dueDateEndDate: () => filterByEndDate(filteredTransasction, "dueDate", dueDateEndDate),
    endDate: () => filterByEndDate(filteredTransasction, "date", endDate),
    startDate: () => filterByStartDate(filteredTransasction, "date", startDate),
    reference: () => filterByReference(filteredTransasction, reference),
    sort: () => [...filteredTransasction].sort(sortBill(sort)),
  };

  Object.keys(filter).forEach((key) => {
    filteredTransasction = functionGenerator[key]();
  });

  return filteredTransasction;
};
