import { createContext } from "react";

import { INavigationContext } from "Views/Navigation/@types";

/**
 * The initial state for the Navigation context.
 *
 * @property {false} isPopoverOpen - The default state of the popover visibility.
 * @property {Function} setIsPopoverOpen - A function to set the popover visibility state.
 */
export const initialNavigationContext: INavigationContext = {
  isPopoverOpen: false,
  setIsPopoverOpen: () => null,
};

/**
 * A context object representing the Navigation state.
 * @context
 * @type {INavigationContext}
 */
export const NavigationContext: React.Context<INavigationContext> =
  createContext<INavigationContext>(initialNavigationContext);
