import React, { useEffect, useState, useMemo, useCallback } from "react";
import { Form } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  ISidePanelButton,
  SidePanel,
  ESidePanelButtonType,
} from "Modules/DS/SidePanel";
import {
  AuditTrail,
  EInputType,
  TransactionDetail,
  TransactionHeader,
} from "Modules/TransactionDetail";
import { infoBrown } from "assets/icons/role";
import { getRequestDetails } from "Redux/DataCalls/Approvals.api";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import { convertStatusJourneyToAuditTrail } from "Views/Reimbursement/DetailReimbursement/helper";
import {
  detailDataMapping,
  showPayImmediately,
  isCRCurrencyRuleInvalid,
} from "../helper";
import { useTransactionDetail } from "../hook";
import { ACTION_TYPE_PAY, REIMBURSEMENT_STATUS, TAB_KEY } from "../types";
import { GetApprovalCenterDetail } from "Redux/DataCalls/ApprovalCenter.api";
import {
  checkWorkflow,
  clearEditedCR,
  fetchApprovalRequest,
  saveReimbursementDetails,
  selectActiveSubTab,
  selectApprovalRequest,
  selectApproveApiCallState,
  selectIfEditedCRPresent,
  selectWorkflowChange,
  setEditedCR,
  fetchApprovalRequestList,
  selectPayImmediatelyConfig,
} from "Redux/Reducers/Approval/ApprovalCenter/approvalCenter.slice";
import { AppDispatch, useAppSelector } from "../../../../hook";
import { useIsSaasWithPaymentsOrg } from "customHooks/useIsSaasWithPaymentsOrg";
import ReimbursementApprovalRequest from "Models/Approval/ApprovalCenter/reimbursementApprovalRequest.interface";
import { NetworkCallState } from "Models/Network/models";
import { GetBaseAuthObject, roundValue } from "utility";
import { trackEvent } from "utility/analytics";
import { CR_APPROVAL_EVENTS } from "../trackEvents";
import "./index.scss";
import { ApprovalRequestDetail } from "Models/Approval/ApprovalCenter/approvalRequest.interface";
import useCheckFeatureStatus from "customHooks/featureCheck";
import { SPLIT_NAMES, SPLIT_TREATMENT_TYPES } from "Redux/splitio/constants";

const option = {
  [EInputType.TAX]: true,
  [EInputType.CATEGORY]: true,
  [EInputType.NOTES]: true,
  [EInputType.TRANSACTION_TAGS]: true,
  [EInputType.RECEIPT]: true,
};

interface IApprovalReimbursementDetail {
  record: ReimbursementApprovalRequest;
  visible: boolean;
  buttons?: ISidePanelButton[];
  setVisible: (value: boolean) => void;
  onApprove: (object: any) => Promise<object>;
  onDecline: (boolean: boolean) => void;
  onPayApproveImmediately: (object: any) => Promise<object>;
  trackEventData?: TrackEventDataType;
}

interface TrackEventDataType {
  name: string;
  data: {
    bill_id: Array<string>;
    user_role: string;
  };
}

let timerId;

const ApprovalReimbursementDetail = ({
  visible,
  record,
  setVisible,
  onApprove,
  onDecline,
  onPayApproveImmediately,
  trackEventData,
}: IApprovalReimbursementDetail) => {
  const dispatch: AppDispatch = useDispatch();

  const activeSubTab: REIMBURSEMENT_STATUS = useAppSelector(selectActiveSubTab);
  const currencyCode = useAppSelector(
    (state) => state.wallet?.data?.currency_code,
  );
  const approveApiCallState = useAppSelector(selectApproveApiCallState);
  const isWorkflowChange = useAppSelector(selectWorkflowChange);
  const [form] = Form.useForm();
  const [formValue, setFormValue] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const [reimbursementDetail, setReimbursementDetail] = useState<any>({});
  const [editable, setEditable] = useState(false);
  const [formChanged, setFormChanged] = useState(false);
  const reimbursementApprovalRequestDetail: ApprovalRequestDetail = useSelector(
    selectApprovalRequest,
  );
  const editedCR = useAppSelector(selectIfEditedCRPresent);
  const currencyRuleInvalid = isCRCurrencyRuleInvalid(record);
  const payImmediatelyConfig = useAppSelector(selectPayImmediatelyConfig);

  const isPayCRAdhocOn =
    useCheckFeatureStatus(SPLIT_NAMES.paynowCRAdhoc) ===
    SPLIT_TREATMENT_TYPES.ON;
  const isSaasWithPaymentsOrg = useIsSaasWithPaymentsOrg();

  useEffect(() => {
    if (editedCR !== record.processID) {
      dispatch(clearEditedCR());
    }
  }, [editedCR, record.processID]);

  const fetchData = async () => {
    try {
      const response = await getRequestDetails(record.processID);
      const { data } = response || {};
      if (data?.status === HTTP_STATUS_CODE.OK && data?.payload?.request?.id) {
        if (data && data.payload) {
          const { request } = data.payload;
          setReimbursementDetail(detailDataMapping(request));
        }
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const fetchApprovalRequestDetail = () => {
    dispatch(
      fetchApprovalRequest({
        process_id: record.processID,
        process_type: "reimbursement",
      }),
    );
  };

  useEffect(() => {
    fetchData();
    fetchApprovalRequestDetail();
  }, []);

  const {
    header,
    loading: transactionLoading,
    detail,
    defaultValue,
    cleanExpense,
  } = useTransactionDetail(
    reimbursementDetail,
    visible,
    !reimbursementDetail?.transactionNumber,
  );

  const [confirmApprovalBtn, setConfirmApprovalBtn] = useState(false);
  const [confirmPayBtn, setConfirmPayBtn] = useState(false);

  const handleApprove = async () => {
    await onApprove({
      ids: [reimbursementDetail.id],
      type: reimbursementDetail.type,
      approved: true,
    });
  };

  const handlePayApproveImmediately = async (actionType) => {
    await onPayApproveImmediately({
      id: reimbursementDetail.id,
      type: reimbursementDetail.type,
      actionType,
    });
  };

  const handleChangeFormValue = (value) => {
    if (editable && formValue.category !== value.category && value.category) {
      dispatch(
        checkWorkflow({
          processId: reimbursementDetail.id,
          processType: TAB_KEY.REIMBURSEMENT,
          categoryId: value.category,
        }),
      );
    }
    setFormValue(value);
    setFormChanged(Boolean(value?.receipt?.length));
  };

  const saveReimbursementDetail = useCallback(async () => {
    const cleanTax = formValue.tax ? { tax_id: formValue.tax } : {};

    const response = await dispatch(
      saveReimbursementDetails({
        id: record.processID,
        payload: {
          amount: {
            amount: reimbursementDetail.baseAmount,
            currency: reimbursementDetail.currency,
          },
          requestor: {
            user_id: reimbursementDetail.user_id || GetBaseAuthObject().userId,
            team_id: reimbursementDetail.team_id,
            type: "user",
          },
          request: {
            ...cleanTax,
            type: TAB_KEY.REIMBURSEMENT,
            image_urls: formValue.receipt || [],
            notes:
              formValue.notes !== undefined
                ? formValue.notes
                : reimbursementDetail.comment,
            tags:
              formValue.transactionTags?.map((tag) => ({
                label: tag.label,
                value: tag.value,
              })) || [],
            expense_category_id:
              formValue.category !== undefined
                ? formValue.category
                : reimbursementDetail.category,
          },
          organisation_id: GetBaseAuthObject().orgId,
        },
        transaction_number: reimbursementDetail.transactionNumber,
      }),
    );
    if (response.payload?.data?.status === HTTP_STATUS_CODE.OK) {
      dispatch(setEditedCR(record.processID));
      checkDetailsUpdate(1);
      setEditable(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValue, reimbursementDetail, record]);

  const checkDetailsUpdate = (callCounter) => {
    clearInterval(timerId);
    if (callCounter <= 5) {
      timerId = setTimeout(async () => {
        const res = await GetApprovalCenterDetail(
          record.processID,
          TAB_KEY.REIMBURSEMENT,
        );
        recallForNoUpdate(res, callCounter + 1);
      }, callCounter * 1000);
    }
  };

  const recallForNoUpdate = (res, callCounter) => {
    const lastUpdateTime = new Date(res.payload.updatedAt).getTime();
    const currentTime = new Date(
      new Date().toISOString().replace("T", " ").substring(0, 19),
    ).getTime();
    const timeSinceLastUpdate = Math.floor(
      (currentTime - lastUpdateTime) / 1000,
    );
    if (res.payload.status === 403) {
      dispatch(fetchApprovalRequestList());
    } else if (timeSinceLastUpdate > 10) {
      checkDetailsUpdate(callCounter);
    } else {
      dispatch(fetchApprovalRequestList());
    }
  };

  const {
    disablePayImmediately,
    warningHidden,
    warningText,
    hiddenButtonBeforeConfirm,
    approveDeclineReady,
  } = useMemo(() => {
    let approveDeclineReady = null;
    if (activeSubTab === REIMBURSEMENT_STATUS.PENDING) {
      approveDeclineReady = true;
    } else {
      approveDeclineReady = false;
    }

    let warningText = record.payImmediatelyValidationError;
    let warningHidden = !payImmediatelyConfig;
    let hiddenButtonBeforeConfirm = approveDeclineReady
      ? !payImmediatelyConfig
      : currencyRuleInvalid;

    return {
      disablePayImmediately: record.payImmediatelyEligible,
      warningHidden,
      warningText,
      hiddenButtonBeforeConfirm,
      approveDeclineReady,
    };
  }, [
    reimbursementDetail,
    record,
    currencyCode,
    activeSubTab,
    reimbursementApprovalRequestDetail,
  ]);

  const buttonLoading = useMemo(
    () => approveApiCallState.loading === NetworkCallState.loading,
    [approveApiCallState.loading],
  );

  const handleTrackEvent = (action: string) => {
    const source = approveDeclineReady
      ? "Details in Pending Approval"
      : "Details in Waiting Payment";
    const data = {
      approval_cr_event_action: action,
      approval_cr_event_source: source,
      ...trackEventData.data,
    };
    trackEvent(trackEventData.name, data);
  };

  const buttons = useMemo(() => {
    if (editable) {
      return [
        {
          id: "SIDE-PANEL-BUTTON-001",
          type: ESidePanelButtonType.TEXT,
          disabled: false,
          text: "Cancel",
          enabled: true,
          loading: false,
          action: async () => {
            setEditable(false);
          },
        },
        {
          id: "SIDE-PANEL-BUTTON-002",
          type: ESidePanelButtonType.PRIMARY,
          enabled: true,
          disabled: !formChanged,
          text: "Save Changes",
          loading: buttonLoading,
          action: saveReimbursementDetail,
        },
      ];
    }

    return [
      {
        id: "SIDE-PANEL-BUTTON-001",
        type: ESidePanelButtonType.CONSTRUCTIVE_ENABLE,
        enabled: approveDeclineReady && !confirmPayBtn,
        disabled: false,
        text: "Approve",
        loading: false,
        action: async () => {
          handleApprove();
          handleTrackEvent("Confirm Approve");
        },
        enableConfirmCallback: () => {
          setConfirmApprovalBtn(true);
          handleTrackEvent("Approve");
        },
        disabledConfirmCallback: () => {
          setConfirmApprovalBtn(false);
        },
      },
      {
        id: "SIDE-PANEL-BUTTON-002",
        type: ESidePanelButtonType.BORDERED_DESTRUCTIVE,
        enabled: approveDeclineReady && !confirmApprovalBtn && !confirmPayBtn,
        disabled: false,
        text: "Decline",
        loading: false,
        action: async () => {
          onDecline(true);
          handleTrackEvent("Decline");
        },
      },
      isPayCRAdhocOn &&
        showPayImmediately(reimbursementApprovalRequestDetail, activeSubTab) &&
        isSaasWithPaymentsOrg && {
          id: "SIDE-PANEL-BUTTON-003",
          type: ESidePanelButtonType.PRIMARY_ENABLE,
          enabled: !confirmApprovalBtn,
          disabled: !disablePayImmediately,
          text: approveDeclineReady
            ? "Approve & Pay Immediately"
            : "Pay Immediately",
          warningHidden: warningHidden,
          warningText: warningText,
          shouldUseColSpan: true,
          hiddenButtonBeforeConfirm: hiddenButtonBeforeConfirm,
          loading: false,
          action: async () => {
            if (approveDeclineReady) {
              handlePayApproveImmediately(ACTION_TYPE_PAY.APPROVE_SETTLE);
            } else {
              handlePayApproveImmediately(ACTION_TYPE_PAY.SETTLE);
            }
            handleTrackEvent("Confirm Pay");
          },
          enableConfirmCallback: () => {
            setConfirmPayBtn(true);
            handleTrackEvent("Pay Immediately");
          },
          disabledConfirmCallback: () => {
            setConfirmPayBtn(false);
          },
        },
    ];
  }, [
    approveDeclineReady,
    buttonLoading,
    confirmApprovalBtn,
    confirmPayBtn,
    editable,
    formChanged,
    hiddenButtonBeforeConfirm,
    disablePayImmediately,
    warningHidden,
    warningText,
    saveReimbursementDetail,
    reimbursementApprovalRequestDetail,
  ]);

  const workflowChangeWarning = isWorkflowChange ? (
    <div className="workflow-change__warning">
      <img src={infoBrown} />
      <div>
        <p className="warning-heading">
          This request will be removed from your list
        </p>
        <p className="warning-text">
          If you save changes with the newly selected category, this request
          will be redirected to another approver(s) following a different
          approval flow.
        </p>
      </div>
    </div>
  ) : (
    <></>
  );

  return (
    <SidePanel
      title="Reimbursement"
      visible={visible}
      buttons={buttons.filter((item) => item.enabled)}
      loading={loading || transactionLoading}
      sticky={buttons?.length !== 0}
      onClose={() => setVisible(false)}
      {...(!loading &&
        editable === false && { onEdit: () => setEditable(true) })}
      {...(editable === true && { onBack: () => setEditable(false) })}
      stickyContent={workflowChangeWarning}
    >
      <TransactionHeader {...header()} subTitle="Merchant" />
      <AuditTrail
        title="Payment Tracking"
        list={convertStatusJourneyToAuditTrail(
          reimbursementDetail.status_journey,
        )}
        isEditable
      />
      <TransactionDetail
        receiptTitle="Attachments"
        visibleInput={option}
        details={detail()}
        defaultValue={defaultValue()}
        taxFallback={`${cleanExpense?.tax?.name} (${roundValue(cleanExpense?.tax?.rate)}%)`}
        form={form}
        setFormChanged={setFormChanged}
        setFormValue={handleChangeFormValue}
        disabled={!editable}
        trackEventData={{
          name: CR_APPROVAL_EVENTS.crApprovalDetailsEdited,
          role: trackEventData?.data?.user_role,
        }}
      />
    </SidePanel>
  );
};

export default ApprovalReimbursementDetail;
