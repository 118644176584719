export enum LAST_POSSIBLE_RECURRING_DATE {
  MIN = 29,
  MAX = 31,
}
export interface IDateItem {
  date: number;
  selected: boolean;
  onClickHandler?: React.MouseEventHandler<HTMLButtonElement>;
}

export interface IDateGrid {
  show: boolean;
  recurringDate: number;
  onChangeShow: React.Dispatch<React.SetStateAction<boolean>>;
  onChangeDate: React.Dispatch<React.SetStateAction<number>>;
}

export interface IDateInput {
  show: boolean;
  date: number;
  onClickHandler: React.MouseEventHandler<HTMLDivElement>;
}

export interface IRecurringDateSelector {
  recurringDate: number;
  onChangeDate: React.Dispatch<React.SetStateAction<number>>;
  onClickHandler: React.MouseEventHandler<HTMLDivElement>;
}
