import {
  FETCH_EMPLOYEE_REQUEST,
  FETCH_EMPLOYEE_SUCCESS,
  FETCH_EMPLOYEE_FAILURE,
  FETCH_TEAM_EMPLOYEE_REQUEST,
  FETCH_TEAM_EMPLOYEE_SUCCESS,
  FETCH_TEAM_EMPLOYEE_FAILURE,
} from "../Actions/employeeAction";
import { userInitialState } from "../InitialState";

export const empReducer = (
  state = {
    ...userInitialState,
  },
  action
) => {
  switch (action.type) {
    case FETCH_EMPLOYEE_REQUEST:
      return {
        ...state,
        data: action.data,
        loading: true,
      };
    case FETCH_EMPLOYEE_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
      };
    case FETCH_EMPLOYEE_FAILURE:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: true,
      };
    case FETCH_TEAM_EMPLOYEE_REQUEST:
      return {
        ...state,
        data: action.data,
        loading: true,
      };
    case FETCH_TEAM_EMPLOYEE_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
      };
    case FETCH_TEAM_EMPLOYEE_FAILURE:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: true,
      };
    default:
      return state;
  }
};
