import axios, { CancelTokenSource } from "axios";

import APIclient from "API/Client";

import { GetOrgId } from "utility";
import { urlParamsBuilder } from "utility/APIWrapper";

import { BILLS_TRXNS_SERVICE } from "./Services";

export const searchBillsMerchantList = async (value: string, source: CancelTokenSource) => {
  const config = { cancelToken: source.token };

  const payload = {
    organisationID: GetOrgId(),
    keywordField: "merchant",
    keywordValue: value,
    orderBy: "ASC",
  };

  const url = urlParamsBuilder(BILLS_TRXNS_SERVICE.SEARCH_MERCHANTS, payload);

  try {
    const response = await APIclient.getData(url, null, null, config);
    return response.data;
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log("Request canceled", error.message);
    } else {
      throw error;
    }
  }
};
