import * as React from "react";
import { useState } from "react";
import { Form, Input as AntInput } from "antd";
import { Rule } from "antd/lib/form";

import Button from "Modules/button";

import "./index.scss";

const _INPUT_VALIDATION_TEXT: string = "DELETE";
const _INPUT_CONFIRMATION_FORM_ITEM_NAME: string = "confirm";

interface IInput {
  [key: string]: string;
}

const Input = ({ onCancel, deleteEmployee }: { onCancel: () => void; deleteEmployee: () => void }) => {
  const [form] = Form.useForm<IInput>();
  const [inputValue, setInputValue] = useState("");

  const [blockButton, setBlockButton] = useState(true);

  const handleDeletion = (value: IInput) => {
    if (value[_INPUT_CONFIRMATION_FORM_ITEM_NAME] === _INPUT_VALIDATION_TEXT) deleteEmployee();
  };

  const viewObject = {
    label: `Type ${_INPUT_VALIDATION_TEXT} to confirm`,
    inputMaxLength: 6,
    errorMessage: `${_INPUT_VALIDATION_TEXT} is typed incorrectly, please make sure to use the correct spelling and capitalization.`,
  };

  const inputRules: Rule[] = [
    {
      required: true,
      message: viewObject.errorMessage,
    },
    () => ({
      validator(_, value: string) {
        if (!value || value === "") {
          setBlockButton(true);
          return Promise.reject();
        } else if (value !== _INPUT_VALIDATION_TEXT) {
          setBlockButton(true);
          return Promise.reject(viewObject.errorMessage);
        } else {
          setBlockButton(false);
          return Promise.resolve();
        }
      },
    }),
  ];

  return (
    <Form<IInput> form={form} onFinish={handleDeletion}>
      <div className="confirm-input-container">
        <span>{viewObject.label}</span>
        <Form.Item name={_INPUT_CONFIRMATION_FORM_ITEM_NAME} rules={inputRules}>
          <AntInput
            type="text"
            value={inputValue}
            maxLength={viewObject.inputMaxLength}
            onChange={(event) => setInputValue(event.target.value)}
            placeholder="Type the exact word using the same letter case"
          />
        </Form.Item>
      </div>
      <div className="confirm-input__actions-container">
        <Button rounded btnStyle="transparent-orange-with-border" type="button" action={onCancel}>
          Cancel
        </Button>
        <Button rounded type="submit" className={`confirm-input__delete-button${blockButton ? "--inactive" : ""}`}>
          Delete
        </Button>
      </div>
    </Form>
  );
};

export default Input;
