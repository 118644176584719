import React, { useEffect, useContext } from "react";
import { useSelector } from "react-redux";

import { IUpdateRoleReducer } from "Redux/Actions/Employee/Update/Role/types";

import { ModalComponent } from "Modules/DS/Modal";
import { TITLE_TYPE } from "Modules/DS/Modal/types";

import * as E from "Views/Employee/context";
import { EmployeeModalTypes, IUpdateRoleModalView } from "Views/Employee/types";

import Failed from "./Failed";
import Processing from "./Processing";

import "./index.scss";

const UpdateRoleModal = () => {
  const { currentPage }: E.IEmployeesPaginationContext = useContext(E.EmployeesPaginationContext);
  const { showModal, resetModal }: E.IEmployeesModalContext = useContext(E.EmployeesModalContext);
  const { searchVal, filters, getEmployeesData }: E.IEmployeesContext = useContext(E.EmployeesPageContext);

  const { visible, type } = showModal;

  const updateRoleDetails: IUpdateRoleReducer = useSelector((state: any) => state.updateRoleReducer);

  const updateRoleError: boolean = updateRoleDetails?.error || false;
  const updateRoleLoading: boolean = updateRoleDetails?.loading || false;

  useEffect(() => {
    if (visible && type === EmployeeModalTypes.ROLE) {
      if (!!updateRoleDetails && !!updateRoleDetails.data && !!updateRoleDetails.data.isSuccess) {
        resetModal();
        getEmployeesData(currentPage, searchVal, filters.values);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateRoleLoading]);

  const viewObject: IUpdateRoleModalView = { title: "", children: null };

  if (updateRoleError) {
    viewObject.title = "Data Update Failed";
    viewObject.children = <Failed />;
  } else {
    viewObject.title = "Processing Data Update";
    viewObject.children = <Processing />;
  }

  return (
    <>
      <ModalComponent.Title titleType={TITLE_TYPE.withBorder}>{viewObject.title}</ModalComponent.Title>
      {viewObject.children}
    </>
  );
};

export default UpdateRoleModal;
