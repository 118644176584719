import { useContext, useState } from "react";

import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";

import { getSingpassToken } from "Views/KYX/DataCalls";
import { KYXToastContext, KYXErrorToaster } from "Views/KYX/Context";
import { IKYXToastContext, IGetSingpassToken } from "Views/KYX/@types";

export const useGetSingpassToken = (dispatchOnSuccess?: ({ value, expiry_time }) => void): IGetSingpassToken => {
  const [loading, setLoading] = useState(false);
  const { setToaster } = useContext<IKYXToastContext>(KYXToastContext);

  const getToken = async (personnelId: string) => {
    try {
      setLoading(true);

      const response = await getSingpassToken(personnelId);

      if (response.status !== HTTP_STATUS_CODE.OK) {
        throw new Error(response.statusText);
      }

      const data = response.data?.payload;
      const errorMessage = response.data?.error?.message || response.data?.meta?.message;

      if (response?.data?.status === HTTP_STATUS_CODE.OK) {
        dispatchOnSuccess?.({ value: data?.token, expiry_time: data?.expiry_time });
      } else {
        throw new Error(errorMessage);
      }
    } catch (error) {
      const toast = KYXErrorToaster(
        error?.response?.data?.error?.message || error?.response?.data?.errors?.[0]?.message || error?.message
      );
      setToaster(toast);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return { getToken, loading };
};
