import React, { useMemo, useState } from "react";
import { IKYXRemarkContextType, IRemarkState } from "Views/KYX/@types";
import { initialRemarkState, KYXRemarkContext } from "Views/KYX/Context/Remarks";

export const KYXRemarksProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const [remarks, setRemarks] = useState<IRemarkState>(initialRemarkState);
  const [formRemarks, setFormRemarks] = useState<IRemarkState>(initialRemarkState);

  const close = (): void => setRemarks(initialRemarkState);

  const value = useMemo(
    (): IKYXRemarkContextType => ({
      remarks,
      setRemarks,
      close,
      formRemarks,
      setFormRemarks,
    }),
    [remarks, formRemarks]
  );

  return <KYXRemarkContext.Provider value={value}>{children}</KYXRemarkContext.Provider>;
};
