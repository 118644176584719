import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Cookies } from "react-cookie";
import { GetBaseAuthObject } from "utility";
import { detectTabChange } from "utility/detectTabchange";
import Modal from "Modules/modal";
import Button from "Modules/button";

import "./index.scss";
/*
  In multi signin if user has opened web-dashboard in multiple tabs and
  switch to a account which is different from other tab's selected account,
  go to next tab then one popup will be shown to user to switch account
  to as previously selected account.
  Reason: in all tab selected account should be same as per business logic
*/
const cookies = new Cookies();
const authToken = cookies.get("auth_token");
const USER_ID = GetBaseAuthObject().userId;

export const TabChangeModal = () => {
  const [show, setVisibility] = useState(false);
  const linkedAccounts = useSelector((state: any) => state?.loginReducer?.data?.linkedAccount);

  const handleTabSwitch = () => {
    /**  check incase of multi sign-in only */
    if (linkedAccounts?.length > 0) {
      const isAccountSwitched = authToken !== cookies.get("auth_token") && GetBaseAuthObject().userId !== USER_ID;
      if (isAccountSwitched) setVisibility(true);
    }
  };

  useEffect(() => {
    detectTabChange(handleTabSwitch);
  }, []);

  return (
    <Modal show={show} title="Warning" className="session-timeout-modal">
      <p>You have switched account in other tab, need to refresh this tab</p>
      <Button
        text="Refresh Tab"
        className="session-timeout-modal__button"
        rounded
        action={() => {
          window.location.reload();
        }}
      />
    </Modal>
  );
};
