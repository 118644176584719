import React from "react";
import s from "./LineChart.module.scss";

import { ParentSize } from "@visx/responsive";
import { AnimatedAxis, AnimatedGrid, AnimatedAreaSeries, buildChartTheme, XYChart, Tooltip } from "@visx/xychart";
import { scaleLinear, scaleTime } from "@visx/scale";
import { extent } from "d3-array";
import { LinearGradient } from "@visx/gradient";

import { EventHandlerParams } from "@visx/xychart/lib/types/series";

/* Utils */
import { dateFormatter1, numberFormatter, SGDCurrencyFormatter } from "Views/Analytics/utils";
import { AnalyticsCardTitle } from "Views/Analytics/Analytics";

/* type */
interface DateValueType {
  timestamp: string;
  amount: number;
}

/*
# Accessors

Here we modify raw data that we get from APIs to the form which will allows us to plot the data. Here, assuming that we get date in form of ISO-string, so we are converting it to date object, while keeping value as it is.
*/
const dataAccessors = {
  xAccessor: (d: DateValueType) => +new Date(d.timestamp),
  yAccessor: (d: DateValueType) => d.amount,
};

/* Chart theme */
const chartTheme = buildChartTheme({
  backgroundColor: "#ffffff", // tooltip background color
  colors: ["#34629A"], // line color
  tickLength: 14,

  // labels
  svgLabelSmall: { fill: "#676B78", fontSize: 10 }, // this determines the color of date and amount at axis
  svgLabelBig: { fill: "#34629A" }, // this determines the color of text in tooltip

  // grid
  gridColor: "#E4E5E7", // grid color
  gridColorDark: "#E4E5E7", // grid color
});

// 0.9 is intentional to make last data pickable in tooltip inside the line chart
const INNER_PADDING_XAXIS = 0.9;

/* Configuring XYChart */
const dateScaleConfig = { type: "band", paddingInner: INNER_PADDING_XAXIS } as const;
const amountScaleConfig = { type: "linear" } as const;
const config = {
  x: dateScaleConfig,
  y: amountScaleConfig,
};

function LineChartToolTip({ tooltipData }) {
  return (
    <div className={s.lineChartTooltip}>
      <div className={s.total}>
        <span>Total Spending</span>
        <span>{SGDCurrencyFormatter(dataAccessors.yAccessor(tooltipData.nearestDatum.datum as DateValueType))}</span>
        <span>{tooltipData.nearestDatum.datum.label}</span>
      </div>
      {tooltipData.nearestDatum.datum?.subTotal?.length > 0 && (
        <div className={s.subTotal}>
          {tooltipData.nearestDatum.datum.subTotal.map((item) => (
            <div className={s.row} key={item.title}>
              <span>{item.title}</span>
              <span>{SGDCurrencyFormatter(item.amount)}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export function LineChart({ data, handleApplyDeeplink, hideDate = false, noOfTicks = 15 }) {
  const handleSelect = (props: EventHandlerParams<DateValueType>): void => {
    handleApplyDeeplink({
      from: AnalyticsCardTitle.SPENDING_TREND,
      data: {
        ...props.datum,
      },
    });
  };

  const customTickFormatter = (date: string) => (hideDate ? dateFormatter1(date).split(" ")[1] : dateFormatter1(date));

  return (
    /* To make our chart responsive, we are wrapping our chart in ParentSize component which passes parent container's width and height to it. Thus, with its help we can make responsive charts. */
    <ParentSize className={s.container} debounceTime={200}>
      {({ width, height }) => {
        const parentWidth = width;
        const parentHeight = height;

        /*
         # Scale

         To properly scale our axis so that data look natural we scale data of each axis here.
         */
        const xScale = scaleTime({
          domain: extent(data, dataAccessors.xAccessor) as [Date, Date], // input scale
          range: extent(data, dataAccessors.xAccessor) as [Date, Date], // output scale
          round: true,
          nice: false,
        });

        const yScale = scaleLinear<number>({
          domain: [0, 1] as [number, number], // input scale
          range: [0, 1] as [number, number], // output scale
          round: true,
          nice: true,
        });

        return (
          /* XYChart provides us a cartesian coordinates. It also provide child component for axis, grid, curves and so on. By use all these child components we can build our chart. Additionally it provides theming prop, using which we can set theme of our chart.

        Here, we passed parentHeight and parentWidth prop to XYChart same as ParentSize, thus it will always cover its parent.

        */

          <XYChart
            margin={{ left: 80, top: 40, bottom: 40, right: 10 }}
            height={parentHeight}
            width={parentWidth}
            theme={chartTheme}
            xScale={config.x}
            yScale={config.y}
            onPointerDown={handleSelect}
          >
            {/* Background grid */}
            <AnimatedGrid columns={false} numTicks={6} stroke="#E4E5E7" strokeWidth={1} />

            {/* Here, we create y-axis */}
            <AnimatedAxis
              orientation="left"
              numTicks={6}
              hideAxisLine
              hideTicks
              animationTrajectory="min"
              tickFormat={numberFormatter}
            />

            {/* Now, lets create x-axis */}
            <AnimatedAxis
              orientation="bottom"
              animationTrajectory="max"
              numTicks={noOfTicks}
              tickFormat={customTickFormatter}
            />

            {/* using linearGradient to fill color under line in our curve. */}
            <LinearGradient id="area-gradient" from="#D2E0F0" to="#f7fafc" toOpacity={0.1} />
            {/* This `AnimatedAreaSeries` is provided by `XYChart` itself to create areaChart. Here we will create our line and will fill area below it with color. */}
            <AnimatedAreaSeries
              data={data}
              dataKey="closingAmount"
              xAccessor={(d) => xScale(dataAccessors.xAccessor(d as DateValueType)) ?? 0}
              yAccessor={(d) => yScale(dataAccessors.yAccessor(d as DateValueType)) ?? 0}
              fill="url(#area-gradient)"
              stroke="url(#area-gradient)"
            />

            {/* Finally lets add tooltip to it. */}
            <Tooltip
              detectBounds
              showDatumGlyph
              snapTooltipToDatumX
              snapTooltipToDatumY
              showVerticalCrosshair
              showSeriesGlyphs
              renderTooltip={({ tooltipData }) => <LineChartToolTip tooltipData={tooltipData} />}
            />
          </XYChart>
        );
      }}
    </ParentSize>
  );
}
