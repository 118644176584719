import React, { useState } from "react";
import dayjs from "dayjs";
import qs from "query-string";
import { Base64 } from "js-base64";
import { IllApprovals, Modal, Typography } from "@spenmo/splice";
import { IModalProps } from "@spenmo/splice/lib/components/Modal";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";

import { ILocationParams, ILocationQueryParams } from "Views/Reimbursement/@types";
import {
  REIMBURSEMENT_MODAL_OPTIONS,
  REIMBURSEMENT_PAGE_OPERATIONS,
  REIMBURSEMENT_QUERY_PARAMS,
} from "Views/Reimbursement/Constants";
import { REIMBURSEMENTS_PAGE_ANALYTICS, trackReimbursementAnalytics } from "Views/Reimbursement/Analytics";

import ModalSkeleton from "Views/Reimbursement/Modal/Skeleton";
import { useFetchCRState } from "Views/Reimbursement/Settings/useCRState";
import useMarkAsSettled from "Views/Reimbursement/hooks/useMarkAsSettled";

import styles from "./index.module.scss";

const Container = ({ isEnabled, date }: { isEnabled: boolean; date: string }) => {
  const location = useLocation();

  const { bulkSettlement, payoutValue = "" }: ILocationQueryParams = qs.parse(location.search, {
    parseNumbers: true,
    parseBooleans: true,
  });

  const getText = () => {
    let text: string = undefined;

    switch (true) {
      case isEnabled && !bulkSettlement:
        text = `This reimbursement payout is scheduled on ${dayjs(date).format(
          "DD MMM YYYY"
        )}. Marking this as settled will remove this request amount of ${Base64.decode(
          payoutValue
        )} from the batch payout.`;
        break;
      case isEnabled && Boolean(bulkSettlement):
        text = `These reimbursement payouts are scheduled on ${dayjs(date).format(
          "DD MMM YYYY"
        )}. Marking these as settled will remove this request amount from its original batch payout, if any.`;
        break;
      case !isEnabled && Boolean(bulkSettlement):
        text = "These reimbursement payouts will be marked as settled.";
        break;

      default:
        text = "This reimbursement payout will be marked as settled.";
        break;
    }

    return text;
  };

  return (
    <div>
      <div className={styles.icon}>
        <IllApprovals size={120} />
      </div>
      <Typography size="m" variant="body-content" tag="p" className={styles.content}>
        {getText()}
      </Typography>
    </div>
  );
};

const MarkAsSettled = () => {
  const history = useHistory();
  const location = useLocation();

  const { params } = useRouteMatch<ILocationParams>();

  const { operation = "", id = "", secondaryOperation = "", secondaryId = "", tertiaryOperation = "" } = params;

  const query: ILocationQueryParams = qs.parse(location.search, {
    parseNumbers: true,
    parseBooleans: true,
  });

  const { bulkSettlement, bulkIds = "" } = query;

  const { markAsSettledInBulk } = useMarkAsSettled();

  const [loading, setLoading] = useState(false);

  const getPath = () => {
    let pathname = undefined;
    switch (true) {
      case operation === REIMBURSEMENT_PAGE_OPERATIONS.READ &&
        secondaryOperation === REIMBURSEMENT_PAGE_OPERATIONS.MODAL &&
        !bulkSettlement:
        pathname = `/${operation}/${id}/${REIMBURSEMENT_PAGE_OPERATIONS.MODAL}/${REIMBURSEMENT_MODAL_OPTIONS.MARK_AS_SETTLED}`;
        break;
      case operation !== REIMBURSEMENT_PAGE_OPERATIONS.READ &&
        secondaryOperation === REIMBURSEMENT_PAGE_OPERATIONS.READ &&
        tertiaryOperation === REIMBURSEMENT_PAGE_OPERATIONS.MODAL &&
        !bulkSettlement:
        pathname = `/${secondaryOperation}/${secondaryId}/${REIMBURSEMENT_PAGE_OPERATIONS.MODAL}/${REIMBURSEMENT_MODAL_OPTIONS.MARK_AS_SETTLED}`;
        break;
      default:
        pathname = `/${REIMBURSEMENT_PAGE_OPERATIONS.MODAL}/${REIMBURSEMENT_MODAL_OPTIONS.MARK_AS_SETTLED}`;
        break;
    }
    return pathname;
  };

  const handleOnClose = () => {
    history.push({
      pathname: location.pathname.replace(getPath(), ""),
      search: qs.stringify({
        ...query,
        [REIMBURSEMENT_QUERY_PARAMS.BULK_IDS]: undefined,
        [REIMBURSEMENT_QUERY_PARAMS.PAYOUT_VALUE]: undefined,
        [REIMBURSEMENT_QUERY_PARAMS.BULK_SETTLEMENT]: undefined,
        [REIMBURSEMENT_QUERY_PARAMS.PAGE]: 1,
      }),
    });
  };

  const { fetchState } = useFetchCRState();
  const { isLoading, isValidating, data } = fetchState;

  const isSettlementEnabled: boolean = data?.data?.payload?.isEnabled;
  const nextSettlementDate: string = data?.data?.payload?.nextSettlementDate;

  const eventName = isSettlementEnabled
    ? REIMBURSEMENTS_PAGE_ANALYTICS.REIMBURSEMENT_DETAILS_SETTLEMENT_ON
    : REIMBURSEMENTS_PAGE_ANALYTICS.REIMBURSEMENT_DETAILS_SETTLEMENT_OFF;

  const onCancelMarkAsSettled = () => {
    history.action === "PUSH" ? history.goBack() : handleOnClose();
    trackReimbursementAnalytics({
      eventName,
      eventSource: "Mark As Settled",
      eventSubSource: "Cancel",
    });
  };

  const getId = () => {
    let settlementId: string[] = undefined;
    switch (true) {
      case operation === REIMBURSEMENT_PAGE_OPERATIONS.READ &&
        secondaryOperation === REIMBURSEMENT_PAGE_OPERATIONS.MODAL &&
        !bulkSettlement:
        settlementId = [id];
        break;
      case operation === REIMBURSEMENT_PAGE_OPERATIONS.SETTLEMENTS &&
        secondaryOperation === REIMBURSEMENT_PAGE_OPERATIONS.READ &&
        tertiaryOperation === REIMBURSEMENT_PAGE_OPERATIONS.MODAL &&
        !bulkSettlement:
        settlementId = [secondaryId];
        break;
      case operation === REIMBURSEMENT_PAGE_OPERATIONS.SETTLEMENTS &&
        secondaryOperation === REIMBURSEMENT_PAGE_OPERATIONS.MODAL &&
        !bulkSettlement &&
        bulkIds.length > 0:
      case operation === REIMBURSEMENT_PAGE_OPERATIONS.SETTLEMENTS &&
        secondaryOperation === REIMBURSEMENT_PAGE_OPERATIONS.MODAL &&
        bulkSettlement &&
        bulkIds.length > 0:
        settlementId = Base64.decode(bulkIds)
          .split(",")
          .filter((item: string) => item.trim());
        break;
      default:
        settlementId = [];
        break;
    }
    return settlementId;
  };

  const onConfirmMarkAsSettled = () => {
    setLoading(true);

    markAsSettledInBulk(getId())
      .then(() => {
        trackReimbursementAnalytics({
          eventName,
          eventSource: bulkIds.length ? "Mark As Settled Bulk Action" : "Mark As Settled",
          eventSubSource: "Confirm",
        });
      })
      .finally(() => {
        setTimeout(() => {
          setLoading(false);
          handleOnClose();
        }, 6000);
      });
  };

  const props: IModalProps = {
    showModal: true,
    size: "m",
    title: `Mark As Paid`,
    onClose: () => (history.action === "PUSH" ? history.goBack() : handleOnClose()),
    children:
      isLoading || isValidating ? (
        <ModalSkeleton />
      ) : (
        <Container isEnabled={isSettlementEnabled} date={nextSettlementDate} />
      ),
    footerFullWidthButtons: true,
    primaryActionButton: {
      title: "Confirm",
      action: onConfirmMarkAsSettled,
      loading: loading,
    },
    secondaryActionButton: {
      title: "Cancel",
      action: onCancelMarkAsSettled,
      loading: loading,
    },
  };

  return <Modal {...props} />;
};

export default MarkAsSettled;
