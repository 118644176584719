import React, { useRef } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Typography, Image } from "antd";
import { pdfjs, Document, Page } from "react-pdf";

import { ALLOWED_FILE_EXTENSIONS, MAX_FILE_SIZE_ALLOWED } from "Views/UploadInvoice/const";
import { appNotification } from "Modules/appNotification/appNotification";
import Icon from "Modules/icons";
import { uploadReceiptIcon } from "assets/img";

import styles from "./ImageList.module.scss";
import "./ImageList.scss";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const ImageList = ({ images, onAddImage, canAddImage, onImageClick }) => {
  const { Text } = Typography,
    uploader = useRef();

  const validateFile = (file) => {
    const fileExtension = file.name.split(".").pop().toLowerCase();
    const extensionValid = ALLOWED_FILE_EXTENSIONS.includes(fileExtension);
    const sizeValid = file.size <= MAX_FILE_SIZE_ALLOWED;
    const isFileValid = extensionValid && sizeValid;

    if (!extensionValid) {
      appNotification.error({ message: `Only [${ALLOWED_FILE_EXTENSIONS.join(", ")}] extensions are allowed.` });
    } else if (!sizeValid) {
      appNotification.error({ message: "Maximum file size exceeded. Please keep your file under 12 MB." });
    }

    return isFileValid;
  };

  const onChange = (event) => {
    if (event.target.files.length && validateFile(event.target.files[0])) {
      onAddImage({
        file: event.target.files[0],
        preview: URL.createObjectURL(event.target.files[0]),
        isPdf: event.target.files[0].type === "application/pdf",
      });
    }
  };

  const onClick = () => {
    uploader.current.click();
  };

  const addButton = (
    <>
      <div className={classNames(styles.add, styles.child)} onClick={onClick}>
        <div className={classNames(styles.wrapper)}>
          <Icon className={styles.icon} src={uploadReceiptIcon} />
          <Text className={styles.text}>+ Add file</Text>
        </div>
      </div>
      <input
        type="file"
        accept={ALLOWED_FILE_EXTENSIONS.map((item) => `.${item}`).join(",")}
        ref={uploader}
        onChange={onChange}
        className={styles.hidden}
      />
    </>
  );

  return (
    <div className={classNames(styles.container, "transaction-image-list-container")}>
      {images.map((item, index) => {
        const isPdf = item.url.indexOf(".pdf") > 0 || item.isPdf;
        return (
          <a
            className={styles.inner}
            key={index}
            href={item.url}
            onClick={onImageClick}
            target={"_blank"}
            data-testid="bill-receipt-images"
            rel="noreferrer"
          >
            {isPdf ? (
              <Document file={item.url}>
                <Page pageNumber={1} />
              </Document>
            ) : (
              <Image preview={false} className={styles.child} src={item.url} />
            )}
          </a>
        );
      })}
      {canAddImage && addButton}
    </div>
  );
};

ImageList.defaultProps = { images: [], onAddImage: () => {}, canAddImage: false, onImageClick: () => {} };

ImageList.propTypes = {
  images: PropTypes.array,
  onAddImage: PropTypes.func,
  canAddImage: PropTypes.bool,
  onImageClick: PropTypes.func,
};

export default ImageList;
