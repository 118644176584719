import React from "react";
import PropTypes from "prop-types";
import { Col, Form, Input, Row, Select } from "antd";
import Button from "Modules/button";
import IDatePicker from "Modules/datePicker";
import SelectAmount from "Modules/selectAmount";
import UploadImage from "Modules/uploadImage";
import CategoryDropdown from "Modules/categoryDropdown";
import { GetBaseAuthObject, millisecondFormatter } from "utility/index";
import LoaderIcon from "Views/State/Loading/LoaderIcon";
import { connect } from "react-redux";
import dayjs from "dayjs";
import {
  AddReimburseFunc,
  getCurrencyFunc,
  editReimburseFunc,
  getAllTeamsWallet,
  getOrgDetailFunc,
} from "Redux/Actions";
import "./AddReimbursement.scss";
import Icon from "Modules/icons";
import { leftArrowOrange, iconNotFound } from "assets/img";
import { trackEvent } from "utility/analytics";
import AccountingTags from "Modules/AccountingTags";
import AryadanaFooter, {
  ARYADANA_FOOTER_TYPE,
} from "Views/Bills/AryadanaFooter";
import { ARYADANA_COUNTRY } from "Views/Bills/const";
import { selectTreatmentValue } from "@splitsoftware/splitio-redux";
import { SPLIT_NAMES, SPLIT_TREATMENT_TYPES } from "Redux/splitio/constants";
import PayoutsBanner from "./Banner";
import {
  getCRSettings,
  getTaxList,
} from "Views/Reimbursement/Settings/useCRState";
import { getCookieValue, cookieNames } from "utility/CookieHelper";
import { InfoOutline, Tooltip } from "@spenmo/splice";
import { CASBIN_PAGES } from "Permission/Pages";
import { REIMBURSEMENT_TRANSACTION_PAGE } from "Permission/Actions";
import { fetchPermissionForClassComp } from "Permission/PermissionForClassComp";

class AddReimbursement extends React.Component {
  static propTypes = {
    toggleAddReimbursement: PropTypes.func,
    submitReimbursement: PropTypes.func,
    currencyData: PropTypes.object,
    addReimburse: PropTypes.func,
    loading: PropTypes.bool,
    getcurrency: PropTypes.func,
    teamData: PropTypes.object,
    Categoryloader: PropTypes.bool,
    editData: PropTypes.object,
    isEdit: PropTypes.bool,
    editReimburse: PropTypes.func,
    b2bOrgDetailReducer: PropTypes.object,
    splitio: PropTypes.object,
    isNewCR: PropTypes.bool,
  };

  static defaultProps = {
    currencyData: {},
  };

  state = {
    currency: this.props?.teamData?.currency_code,
    amount:
      this.props?.editData?.amountWithoutCurrency ||
      this.props?.editData?.amount,
    transaction_date:
      this.props.isEdit && this.props.editData?.request_timestamp
        ? millisecondFormatter(this.props.editData?.request_timestamp)
        : undefined,
    merchant: this.props?.editData?.merchant,
    title: this.props?.editData?.title || this.props?.editData?.notes,
    team_id: this.props?.editData?.team_id,
    expense_category_name: this.props?.editData?.expense_category_name,
    expense_category_id: this.props?.editData?.expense_category_id,
    receipts: { urls: this.props?.editData?.receipts?.urls },
    teamList: [],
    tags: [],
    taxList: [],
  };

  form = React.createRef();

  async componentDidMount() {
    const { getcurrency, getWallets, isEdit, getOrgDetail, editData } =
      this.props;
    getcurrency();
    getWallets("cr");

    const permissionParam = [
      {
        object: CASBIN_PAGES.REIMBURSEMENT_SETTING_MENU,
        action: REIMBURSEMENT_TRANSACTION_PAGE.READ,
      },
    ];
    const response = await fetchPermissionForClassComp(permissionParam);
    if (response) {
      getCRSettings((isEnabled, settledAt) => {
        this.setState({
          crSettings: { isEnabled: isEnabled, settledAt: settledAt },
        });
      });
    }
    getTaxList().then((res) => {
      const taxList = res?.data?.payload?.data || [];
      const tax_id = taxList.find((tax) => tax.id === editData?.tax?.id)?.id;

      if (tax_id) {
        this.form.current.setFieldsValue({
          tax_id,
        });
      }

      this.setState({
        taxList,
        tax_id,
      });
    });
    getOrgDetail(GetBaseAuthObject().orgId);
    if (isEdit) {
      this.setState({
        currency: editData?.currency_code,
        transaction_date: millisecondFormatter(editData?.request_timestamp),
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.allTeamsWallet !== prevProps.allTeamsWallet) {
      const teamList = this.props.allTeamsWallet?.data?.payload?.teams || [];

      if (teamList.length === 1) {
        this.form?.current &&
          this.form.current.setFieldsValue({
            team_id: teamList[0].id,
          });
      }
      this.setState({
        teamList,
      });
    }
  }

  setAmount = (val) => {
    if (val !== this.state.amount) {
      this.form?.current && this.form.current.setFieldsValue({ amount: val });
      this.setState({ amount: val });
    }
  };
  handleMerchant = (e) => {
    this.setState({ merchant: e.target.value });
  };

  handleTeamIdChange = () => {
    this.setState({});
  };

  handleDateChange = (value, type) => {
    this.setState({ [type]: value });
    this.form?.current && this.form.current.setFieldsValue({ [type]: value });
    trackEvent("CR Approval Details Edited", {
      approval_cr_details_edited: "Date",
    });
  };
  handleCategoryChange = (id, options) => {
    const name = options[0]?.category_name;
    const taxId = options[0]?.taxId;
    this.setState({
      expense_category_name: name,
      expense_category_id: id,
      tax_id: taxId,
    });
    this.form?.current &&
      this.form.current.setFieldsValue({
        expense_category_id: id,
        tax_id: taxId,
      });
    trackEvent("CR Approval Details Edited", {
      approval_cr_details_edited: "Category",
    });
  };

  handleImages = (images) => {
    this.form?.current &&
      this.form.current.setFieldsValue({ image_urls: images });
    const { receipts } = this.state;
    if (images?.length > receipts?.urls?.length) {
      trackEvent("CR Approval Details Edited", {
        approval_cr_details_edited: "Photo",
      });
    }
    this.setState({ receipts: { ...receipts, urls: images } });
  };

  handleNotesChange = (e) => {
    this.setState({ title: e.target.value });
  };

  handleOnBlurOfInputs = (prevValue, newValue, eventName) => {
    newValue !== prevValue &&
      trackEvent("CR Approval Details Edited", {
        approval_cr_details_edited: eventName,
      });
  };

  handleOnCurrencyChange = (value) => {
    this.setState({ currency: value });
    trackEvent("CR Approval Details Edited", {
      approval_cr_details_edited: "Currency",
    });
  };

  onFinish = (values) => {
    const {
      isEdit,
      addReimburse,
      editReimburse,
      isNewCR,
      submitReimbursement,
    } = this.props;
    trackEvent("CR Submission Confirm Clicked");
    const editData = this.props?.editData;
    const formData = {
      amount: { amount: values.amount, currency: this.state.currency },
      request: {
        type: "reimbursement",
        transaction_date: values.transaction_date
          ? dayjs(values.transaction_date).format("YYYY-MM-DD")
          : values.transaction_date,
        merchant: values.merchant,
        expense_category_id: values.expense_category_id,
        image_urls: values.image_urls,
        notes: values.notes,
        tags: values.tags,
        tax_id: values.tax_id,
      },
      requestor: {
        user_id: editData?.user_id || GetBaseAuthObject().userId,
        team_id: values.team_id,
        type: "user",
      },
      organisation_id: GetBaseAuthObject().orgId,
    };
    if (isEdit && editData.id && !isNewCR) {
      editReimburse(formData, editData.id);
    } else if (isEdit && editData.id && isNewCR) {
      editReimburse(formData, editData.id, submitReimbursement);
    } else if (!isEdit && isNewCR) {
      addReimburse(formData, submitReimbursement);
    } else {
      addReimburse(formData);
    }
  };

  render() {
    const { TextArea } = Input;
    const {
      merchant,
      expense_category_id,
      expense_category_name,
      amount,
      transaction_date,
      currency,
      title,
      receipts,
      tags,
      team_id,
      tax_id,
      crSettings,
    } = this.state;
    const { toggleAddReimbursement, editData } = this.props;
    const currencyData = this.props?.currencyData?.payload?.currencies || [];
    const loading = this.props.loading || this.props.Categoryloader;

    const orgCurrencyCode = getCookieValue(cookieNames.CURRENCY_CODE);

    const noData = (
      <div class="ant-empty ant-empty-normal ant-empty-small">
        <div class="ant-empty-image">
          <img src={iconNotFound} alt="data not found" />
        </div>
        <p class="ant-empty-description">
          {this.props.allTeamsWallet?.data?.payload?.status_message === "OK" ? (
            "No team found"
          ) : (
            <>
              <p>Unable to load teams</p>
              <p>Refresh the page and try again</p>
            </>
          )}
        </p>
      </div>
    );

    const aryadanaTreatment = selectTreatmentValue(
      this.props.splitio,
      SPLIT_NAMES.aryadanaLogo,
      GetBaseAuthObject().orgId,
    );
    const orgDetails = this.props.b2bOrgDetailReducer?.data?.payload;
    const showAryadana =
      aryadanaTreatment === SPLIT_TREATMENT_TYPES.ON &&
      orgDetails?.countryCode === ARYADANA_COUNTRY;

    return (
      <div className={"add-reimbursement"}>
        <div>
          <span
            className={"add-reimbursement--back"}
            onClick={toggleAddReimbursement}
          >
            <Icon className={"add-reimbursement--icon"} src={leftArrowOrange} />
            <p>Back to Requests</p>
          </span>
        </div>
        <div className={"add-reimbursement--header"}>
          <h2>Add Reimbursement</h2>
        </div>
        {loading ? (
          <LoaderIcon />
        ) : (
          <Form
            className={"add-reimbursement--container"}
            ref={this.form}
            onFinish={this.onFinish}
          >
            <h3>Expense Details</h3>
            <Row gutter={[8, 8]}>
              <Col span={6}>
                <Form.Item
                  name={"merchant"}
                  label={"Merchant"}
                  initialValue={merchant}
                  rules={[
                    { required: true, message: "Please input the merchant!" },
                    { whitespace: true, message: "This field cannot be empty" },
                  ]}
                >
                  <Input
                    onChange={this.handleMerchant}
                    placeholder="Enter merchant"
                    onBlur={() =>
                      this.handleOnBlurOfInputs(
                        editData?.merchant,
                        merchant,
                        "Merchant",
                      )
                    }
                  />
                </Form.Item>
              </Col>
              <Col />
              <Col span={6}>
                <Form.Item
                  name={"expense_category_id"}
                  initialValue={expense_category_id}
                  label={"Category"}
                  rules={[
                    { required: true, message: "Please Select Category!" },
                  ]}
                >
                  <CategoryDropdown
                    showEmptyCategory={true}
                    defaultValue={expense_category_name}
                    action={(id, options) =>
                      this.handleCategoryChange(id, options)
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[8, 8]}>
              <Col span={6}>
                <Form.Item
                  name={"amount"}
                  label={"Amount"}
                  initialValue={amount}
                  rules={[
                    { required: true, message: "Please enter the amount!" },
                  ]}
                >
                  <SelectAmount
                    initialValue={amount}
                    currencyData={currencyData}
                    initialCurrency={currency || orgCurrencyCode}
                    currencyAction={this.handleOnCurrencyChange}
                    action={this.setAmount}
                    inputOnBlurHandler={() =>
                      this.handleOnBlurOfInputs(
                        editData?.amount,
                        amount,
                        "Amount",
                      )
                    }
                    trackActions={true}
                    decimalsLimit={2}
                  />
                </Form.Item>
              </Col>
              <Col />
              <Col span={6}>
                <Form.Item
                  name="tax_id"
                  label={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      Tax{" "}
                      <Tooltip title="GST / VAT">
                        <InfoOutline iconColor="var(--icon-default)" />
                      </Tooltip>
                    </div>
                  }
                  initialValue={tax_id}
                >
                  <Select
                    className="tax-select"
                    value={tax_id}
                    placeholder="Select Tax"
                    showSearch
                    filterOption={(input, option) => {
                      return option.label
                        ?.toLowerCase()
                        ?.includes(input.toLowerCase());
                    }}
                    notFoundContent={noData}
                    getPopupContainer={(triggerNode) =>
                      triggerNode.parentElement
                    }
                  >
                    {this.state.taxList.map((item) => (
                      <Select.Option
                        key={item.id}
                        value={item.id}
                        label={`${item.tax_name} (${item.tax_rate}%)`}
                      >
                        <div>
                          {item.tax_name} ({item.tax_rate}%)
                        </div>
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[8, 8]}>
              <Col span={6}>
                <Form.Item
                  name="team_id"
                  label="Budget"
                  rules={[{ required: true, message: "Please select team" }]}
                  initialValue={team_id}
                >
                  <Select
                    defaultValue={team_id}
                    placeholder="Select Budget"
                    showSearch
                    filterOption={(input, option) => {
                      return option.children
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                    notFoundContent={noData}
                  >
                    {this.state.teamList.map((item) => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col />
              <Col span={6}>
                <Form.Item
                  name="transaction_date"
                  valuePropName="transaction_date"
                  label="Expense Date"
                  initialValue={transaction_date}
                >
                  <IDatePicker
                    initialValue={transaction_date}
                    picker="date"
                    showPastDates
                    action={(val) =>
                      this.handleDateChange(val, "transaction_date")
                    }
                  />
                </Form.Item>
              </Col>
              <Col />
              <Col span={6}>
                <Form.Item name={"tags"} initialValue={tags}>
                  <AccountingTags
                    onChange={(tags) => this.setState({ tags })}
                    initialTags={tags}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <h3>Further Information</h3>
            </Row>
            <Row gutter={[8, 8]}>
              <Col span={6}>
                <Form.Item name="notes" label="Notes" initialValue={title}>
                  <TextArea
                    className={"add-reimbursement--container__notes"}
                    onChange={this.handleNotesChange}
                    onBlur={() =>
                      this.handleOnBlurOfInputs(editData?.title, title, "Notes")
                    }
                    placeholder="Enter a reason for this expense"
                  />
                </Form.Item>
              </Col>
              <Col span={6} className="add-reimbursement--container__upload">
                <Form.Item
                  initialValue={receipts?.urls}
                  name={"image_urls"}
                  label={"Receipts"}
                  rules={[
                    { required: true, message: "Please upload the receipt!" },
                  ]}
                >
                  <UploadImage
                    isDrag
                    multipleUpload
                    images={receipts?.urls}
                    fileList={(images) => this.handleImages(images)}
                  />
                </Form.Item>
              </Col>
            </Row>

            <PayoutsBanner {...crSettings} />

            <Form.Item className={"add-reimbursement--container__action-btns"}>
              <Button
                htmlType="submit"
                id={"addReimbursementSubmit"}
                text={"Submit"}
              />
              <Button
                className={"cancel-btn"}
                action={toggleAddReimbursement}
                text={"Cancel"}
              />
            </Form.Item>
          </Form>
        )}
        {showAryadana && (
          <div className="add-reimbursement--aryadana">
            <AryadanaFooter type={ARYADANA_FOOTER_TYPE.ONE_LINES} />
          </div>
        )}
      </div>
    );
  }
}

export default connect(
  (state) => {
    return {
      loading:
        state.reimburseReducer.loading ||
        state.currencyReducer.loading ||
        state.allTeamsWalletReducer.loading,
      currencyData: state.currencyReducer.data,
      teamData: state.wallet.data,
      Categoryloader: state.userInfoReducer.loading,
      allTeamsWallet: state.allTeamsWalletReducer,
      b2bOrgDetailReducer: state.b2bOrgDetailReducer,
      splitio: state.splitio,
    };
  },
  {
    addReimburse: AddReimburseFunc,
    getcurrency: getCurrencyFunc,
    editReimburse: editReimburseFunc,
    getWallets: getAllTeamsWallet,
    getOrgDetail: getOrgDetailFunc,
  },
)(AddReimbursement);
