import React, { useContext } from "react";
import classNames from "classnames";

import { POPOVER_WIDTH } from "Modules/DS/Popover";
import { ISelectContext, SelectContext, useSelectTypes } from "Modules/DS/Select";

import { InputCell } from "Views/Transaction/Shimmer";
import { PopoverWithBanner } from "Views/Transaction/Popover";
import { IDropdownCell, IPopoverWithBanner } from "Views/Transaction/@types";
import { wrapTooltip } from "Views/Transaction/Helper";

import styles from "./Dropdown.module.scss";

const MAX_CHAR_COUNT = 12;

export const DropdownCell = ({
  id,
  value,
  title,
  type,
  children,
  loading = false,
  popoverWidth = POPOVER_WIDTH.M,
  onClose = () => null,
  bannerText = "",
  disabled = false,
}: IDropdownCell) => {
  const { searchable } = useSelectTypes(type);

  const { select, onSelectHandler } = useContext<ISelectContext>(SelectContext);

  const activeDropdown: boolean = select.show && select.key === id;

  const iconClass = classNames(
    styles.chevron,
    { [styles.up__chevron]: activeDropdown },
    { [styles.icon__disabled]: disabled }
  );

  const titleClass = classNames(styles.title, { [styles.active]: value }, { [styles.title__disabled]: disabled });

  const onDropdownContentHandler = (e: React.MouseEvent<Element, MouseEvent>) => {
    e.stopPropagation();

    if (disabled) return;

    if (activeDropdown && searchable) return;

    // Setting a dynamic min-height when user opening the dropdown
    // To prevent the popover is partially hidden when the table has only
    // Small amount of data
    const element = document.querySelector<HTMLDivElement>(".ant-table-content");
    if (element) {
      element.style.minHeight = "39rem";
    }

    onSelectHandler(id);
  };

  const handleOnClose = () => {
    // Reset the dynamic min height when closing the popover
    const element = document.querySelector<HTMLDivElement>(".ant-table-content");

    if (element) {
      element.style.minHeight = null;
    }

    onClose();
  };

  const popoverProps: IPopoverWithBanner = {
    onClose: handleOnClose,
    bannerText,
    children,
    active: activeDropdown,
    width: popoverWidth,
    className: styles.popover,
    containerClass: ".ant-table",
  };

  const renderDropdownInput = (): JSX.Element => {
    return (
      <div className={styles.dropdown}>
        {wrapTooltip(value || `Choose ${title}`, "p", MAX_CHAR_COUNT, { className: titleClass })}
        <div className={iconClass} />
      </div>
    );
  };

  return (
    <InputCell active={loading}>
      <div className={classNames(styles.cell, { [styles.disabled]: disabled })} onClick={onDropdownContentHandler}>
        {renderDropdownInput()}
        <PopoverWithBanner {...popoverProps} />
      </div>
    </InputCell>
  );
};
