import "Views/SecurityHub/GoogleAuthenticator/OtpAuthenticationModal/OtpAuthenticationModal.scss";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { UserInfoFunc } from "Redux/Actions";
import { fetchOtpResetData, fetchOtp } from "Redux/Actions/OTP/fetchOtpAction";
import { verifyOtpResetData } from "Redux/Actions/OTP/otpVerificationAction";
import { Modal, Typography } from "@spenmo/splice";
import DeactivationInfo from "Views/SecurityHub/GoogleAuthenticator/OtpAuthenticationModal/DeactivationInfo";
import OtpForm from "Views/SecurityHub/GoogleAuthenticator/OtpAuthenticationModal/OtpForm";
import Loader from "Views/State/Loading/LoaderIcon";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import { personalMenu } from "Views/Settings/config";

const OtpAuthenticationModal = ({ onClose, showDeactivateInfoFirst, onConfirm, otpLength }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { data: sendOtpData, loading: sendOtpInProgress } = useSelector((state: any) => state?.getOtpReducer);
  const { data: userInfoData, loading: userInfoLoading } = useSelector((state: any) => state?.userInfoReducer);
  const [showDeactivateInfo, setShowDeactivateInfo] = useState(showDeactivateInfoFirst);
  const [showLoader, setShowLoader] = useState(!showDeactivateInfoFirst);
  const [showErrorInfo, setShowErrorInfo] = useState("");
  const userMobileNumber = userInfoData?.payload?.user?.mobile_number || "";
  const is2FASupported = !!userMobileNumber;

  const sendOtp = () => {
    showDeactivateInfo && setShowDeactivateInfo(false);
    setShowLoader(!!is2FASupported);
    if (is2FASupported) {
      dispatch(fetchOtp("OtpAuthenticationModal", true));
    } else {
      setShowErrorInfo(
        "Please contact our support center at support@spenmo.com or help page to get assistance to enable this feature."
      );
    }
  };
  useEffect(() => {
    if (!userInfoLoading) {
      if (!userInfoData?.payload?.user) {
        dispatch(UserInfoFunc());
      } else if (!showDeactivateInfo) {
        sendOtp();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, userInfoData, userInfoLoading]);

  useEffect(() => {
    if (sendOtpData?.payload && sendOtpData?.status === HTTP_STATUS_CODE.OK && !sendOtpInProgress) {
      setShowLoader(false);
    }
  }, [sendOtpData, sendOtpInProgress]);

  const resetOtpData = () => {
    dispatch(fetchOtpResetData());
    dispatch(verifyOtpResetData());
  };

  const onCloseHandler = () => {
    resetOtpData();
    onClose();
  };

  const onVerificationHandler = () => {
    resetOtpData();
    onConfirm();
  };

  const getCurrentView = () => {
    switch (true) {
      case showLoader:
        return <Loader />;
      case showDeactivateInfo:
        return <DeactivationInfo />;
      case !!showErrorInfo:
        return (
          <Typography tag="p" size="m" variant="body-content">
            {showErrorInfo}
          </Typography>
        );
      default:
        return (
          <OtpForm
            userMobileNumber={userMobileNumber?.toString()}
            onVerification={onVerificationHandler}
            otpLength={otpLength}
          />
        );
    }
  };

  const getModalTitle = () => {
    if (showDeactivateInfo) {
      return "Deactivate Google Authenticator";
    } else if (showErrorInfo) {
      return "Please reach our support";
    }
    return "Make sure this account is yours";
  };

  const getModalButton = () => {
    if (showDeactivateInfo) {
      return {
        title: "Deactivate Google Authenticator",
        action: sendOtp,
      };
    }

    if (showErrorInfo) {
      return {
        title: "OK",
        action: () => history.push(personalMenu[0].path),
      };
    }

    return null;
  };

  return (
    <Modal size="m" showModal title={getModalTitle()} onClose={onCloseHandler} primaryActionButton={getModalButton()}>
      {getCurrentView()}
    </Modal>
  );
};

OtpAuthenticationModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  showDeactivateInfoFirst: PropTypes.bool.isRequired,
  otpLength: PropTypes.number.isRequired,
};

export default OtpAuthenticationModal;
