import { RootState } from "Redux/ConfigureStore";
import { KYX_STATES } from "Views/KYX/Constants";

import { tinyError, tinySuccess, documentUploaded, documentInReview, documentPending } from "assets/img";
import { useAppSelector } from "hook";
import { IPreliminaryData } from "../@types";
import { useIsSaasWithPaymentsOrg } from "customHooks/useIsSaasWithPaymentsOrg";

export interface IKYXStatesDetail {
  icon: any;
  showButtons: boolean;
  showInfo: boolean;
  title: any;
  direction: string;
  message: string[];
  showGoToDashboard?: boolean;
}

export const KYXStatesDetails = (): {
  [KYX_STATES.APPROVED]: IKYXStatesDetail;
  [KYX_STATES.REJECTED]: IKYXStatesDetail;
  [KYX_STATES.IN_REVIEW]: IKYXStatesDetail;
  [KYX_STATES.SUBMITTED]: IKYXStatesDetail;
  [KYX_STATES.ADDITIONAL_DOC_REQUIRED]: IKYXStatesDetail;
} => {
  const preliminaryData: IPreliminaryData = useAppSelector(
    (state: RootState) => state.preliminaryDataReducer?.data?.payload
  );
  const isSaasWithPaymentsOrg = useIsSaasWithPaymentsOrg();

  const companyName = preliminaryData?.org_name;

  return {
    [KYX_STATES.SUBMITTED]: {
      icon: documentUploaded,
      showButtons: false,
      showInfo: true,
      title: null,
      direction: "vertical",
      message: [
        `Thank you for submitting the Company Verification Form for ${companyName}. Our verification process may take up to five business days. We will update you on your company's verification status via email and notification on your Spenmo dashboard.`,
        "Once your company is successfully verified, you will have access to all of Spenmo's features.",
      ],
    },
    [KYX_STATES.APPROVED]: {
      icon: tinySuccess,
      showButtons: true,
      title: null,
      showInfo: false,
      direction: "vertical",
      message: [
        "Your company has been successfully verified. Now you can access all the tools you need to automate your company’s payables, making finances simpler and faster than ever.",
      ],
    },
    [KYX_STATES.REJECTED]: {
      icon: tinyError,
      showButtons: false,
      showGoToDashboard: !isSaasWithPaymentsOrg,
      showInfo: true,
      title: null,
      direction: "vertical",
      message: [
        "We regret to inform you that we were unable to verify your company. Our team will be in contact with you soon with more information on this issue.",
      ],
    },
    [KYX_STATES.IN_REVIEW]: {
      icon: documentInReview,
      showButtons: false,
      showGoToDashboard: !isSaasWithPaymentsOrg,
      showInfo: true,
      title: null,
      direction: "vertical",
      message: [
        "Your company verification is under review. We will send updates on your verification status through your registered email address and your Spenmo dashboard.",
      ],
    },
    [KYX_STATES.ADDITIONAL_DOC_REQUIRED]: {
      icon: documentPending,
      showButtons: false,
      showInfo: false,
      title: "Data Updates Required",
      direction: "horizontal",
      message: ["Update your previously submitted company data to continue the verification process"],
    },
  };
};
