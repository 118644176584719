import React from "react";

import { SelectProvider } from "Modules/DS/Select";

import { TrxnToastProvider } from "./Toast";
import { TrxnModalProvider } from "./Modal";
import { TrxnPaginationProvider } from "./Pagination";

export const TrxnPageProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
  return (
    <SelectProvider>
      <TrxnToastProvider>
        <TrxnModalProvider>
          <TrxnPaginationProvider>{children}</TrxnPaginationProvider>
        </TrxnModalProvider>
      </TrxnToastProvider>
    </SelectProvider>
  );
};

export * from "./Shimmer";
export * from "./Filter";
