import React from "react";
import { useAppSelector } from "hook";

import { Multiple } from "../More";

import { ITitleWithID } from "Views/Transaction/@types";
import { USER_ROLE } from "constants/Team.constant";

export const TeamFilter = ({
  id,
  appliedKey = "id",
  handleSelectFilter,
  clearFilter,
}: {
  id: string;
  appliedKey?: keyof ITitleWithID;
  handleSelectFilter: (values: string[], type: string) => void;
  clearFilter;
}) => {
  const label = "Budget";

  const loading: boolean = useAppSelector((state: any) => state.teamListByUserIdReducer?.loading);

  const hasPrivilegedRole = (user): boolean =>
    user.company_role === USER_ROLE.ADMIN ||
    user.company_role === USER_ROLE.ACCOUNTANT ||
    user.company_role === USER_ROLE.AUDITOR ||
    user.your_role === USER_ROLE.MANAGER;

  const teamListData = useAppSelector((state: any) => state.teamListByUserIdReducer?.data);
  const data = !Array.isArray(teamListData)
    ? []
    : teamListData
        .filter((team) => hasPrivilegedRole(team))
        .map((team) => ({
          id: team.id,
          title: team.team_name,
        }));

  return (
    <Multiple<ITitleWithID>
      id={id}
      searchable
      label={label}
      loading={loading}
      displayKey="title"
      appliedKey={appliedKey}
      handleSelectFilter={handleSelectFilter}
      clearMoreFilter={clearFilter}
    >
      {data}
    </Multiple>
  );
};
