import React from "react";
import { xero_logo } from "assets/img/integrations/xero";
import { externalLinkIcon } from "assets/img";
import classNames from "classnames";
import styles from "./styles.module.scss";
import { IBillImportedFromXeroData, useFetchInvoiceLink } from "./useFetchInvoiceLink";
import { Button, Typography } from "@spenmo/splice";
import { ACCOUNT_INTEGRATIONS_PATH } from "constants/Routes.constants";

export const importedBillText = "This bill was imported from Xero";
export const overridenBillDetailsText = "One or more details have been made from the original imported bill from Xero";
export const importedBillAPIFailureText = "Additonal information cannot be retrieved from Xero right now";
export const billNotFoundInXeroText = "This bill can no longer be found in Xero";
export const notConnectedToXeroText = "Your Spenmo organization is not connected to Xero";

const BillImportedFromXeroBanner = ({
  xeroBillBannerData,
  areBillDetailsOverriden,
  lessSpacing,
}: {
  xeroBillBannerData: IBillImportedFromXeroData;
  areBillDetailsOverriden?: boolean;
  lessSpacing?: boolean;
}) => {
  const { fetchInvoiceLink, billImportedFromXeroData, loadingXeroInvoiceLink } = useFetchInvoiceLink();
  const { link, xeroInvoiceLinkFailure = false, billID = "", identifierType = "", errorMsg = "" } = xeroBillBannerData;

  const invoiceLink = link || billImportedFromXeroData?.link;
  const errorOccurred = (xeroInvoiceLinkFailure && !invoiceLink) || billImportedFromXeroData?.xeroInvoiceLinkFailure;
  const bannerTextContent = areBillDetailsOverriden ? overridenBillDetailsText : importedBillText;
  const tryAgainBtnLabel = "Try again";
  const connectToXeroBtnLabel = "Connect to Xero";

  const getError = () => {
    if (errorOccurred) {
      if (errorMsg === "xero transaction not found") {
        return { msg: billNotFoundInXeroText, btnLabel: "" };
      } else if (errorMsg === "user is not connected to Xero") {
        return { msg: notConnectedToXeroText, btnLabel: connectToXeroBtnLabel };
      }
      return { msg: importedBillAPIFailureText, btnLabel: tryAgainBtnLabel };
    }
    return { msg: "", btnLabel: "" };
  };
  const actionBtnLabel = getError().btnLabel;

  const actionBtnClickHandler = (btnLabel) => {
    if (btnLabel === tryAgainBtnLabel && billID) {
      fetchInvoiceLink(billID, identifierType);
    } else if (btnLabel === connectToXeroBtnLabel) {
      window.open(ACCOUNT_INTEGRATIONS_PATH, "_blank", "noreferrer");
    }
  };

  return (
    <div
      className={classNames(styles.bannerContainer, {
        [styles.bannerBgColor]: areBillDetailsOverriden || errorOccurred,
        [styles.bannerMargin]: lessSpacing,
      })}
    >
      <img src={xero_logo} alt="Xero Logo" height={24} width={24} />
      <div className={styles.bannerTextContent}>
        <Typography
          tag="p"
          variant="body-content"
          children={errorOccurred ? getError().msg : bannerTextContent}
          size="m"
        />
        {invoiceLink && (
          <a href={invoiceLink} target="_blank" rel="noreferrer">
            <img src={externalLinkIcon} alt="external-link" height={18} width={18} />
            View Bill on Xero
          </a>
        )}
        {errorOccurred && actionBtnLabel && (
          <Button
            variant="secondary"
            size="s"
            onClick={() => actionBtnClickHandler(actionBtnLabel)}
            loading={actionBtnLabel === tryAgainBtnLabel && loadingXeroInvoiceLink}
            className={styles.actionBtn}
          >
            {actionBtnLabel}
          </Button>
        )}
      </div>
    </div>
  );
};

export default BillImportedFromXeroBanner;
