import { ISidePanelProps } from "Modules/DS/SidePanel";

export enum GET_CARDS_TRXNS_REQUEST_PARAMS {
  LIMIT = "limit",
  PAGE = "pg",
  TXN_STATUS = "txn_status",
  START_DATE = "start_date",
  END_DATE = "end_date",
  CARD_LAST_FOUR = "card_last_four",
  CARDHOLDER_NAME = "cardholder_name",
  TEAM_ID = "team_id",
  MERCHANT = "simplified_merchant_name",
  MIN_AMOUNT = "min_amount",
  MAX_AMOUNT = "max_amount",
  ATTACHMENT = "attachment",
}

export enum CARD_TXN_STATUS {
  PENDING = "PENDING",
  SETTLED = "SETTLED",
  DECLINED = "DECLINED",
  REVERSED = "REVERSED",
  REFUND_FULL = "REFUND_FULL",
  REFUND_PARTIAL = "REFUND_PARTIAL",
  DISPUTED = "DISPUTED",
  CLOSED = "CLOSED",
  UNMATCHED_REVERSAL = "UNMATCHED_REVERSAL",
  DISPUTED_CHARGEBACK = "DISPUTED_CHARGEBACK",
  UNMATCHED_SETTLEMENT = "UNMATCHED_SETTLEMENT",
  SETTLED_UPDATED = "SETTLED_UPDATED",
  APPROVED_DROPPED_OFF = "APPROVED_DROPPED_OFF",
  SETTLEMENT_CREDIT = "SETTLEMENT_CREDIT",
}

export interface IGetCardsTrxnsRequest {
  [GET_CARDS_TRXNS_REQUEST_PARAMS.LIMIT]: number;
  [GET_CARDS_TRXNS_REQUEST_PARAMS.PAGE]: number;
  [GET_CARDS_TRXNS_REQUEST_PARAMS.TXN_STATUS]?: [
    CARD_TXN_STATUS.PENDING,
    CARD_TXN_STATUS.SETTLED,
    CARD_TXN_STATUS.DECLINED
  ];
  [GET_CARDS_TRXNS_REQUEST_PARAMS.START_DATE]?: string;
  [GET_CARDS_TRXNS_REQUEST_PARAMS.END_DATE]?: string;
  [GET_CARDS_TRXNS_REQUEST_PARAMS.CARD_LAST_FOUR]?: string[];
  [GET_CARDS_TRXNS_REQUEST_PARAMS.CARDHOLDER_NAME]?: string[];
  [GET_CARDS_TRXNS_REQUEST_PARAMS.TEAM_ID]?: string[];
  [GET_CARDS_TRXNS_REQUEST_PARAMS.MERCHANT]?: string[];
}

export enum GET_CARDS_TRXNS_DATA_PARAMS {
  ID = "id",
  TRANSACTION_NUMBER = "transaction_number",
  CREATED_AT = "created_at",
  POSTED_DATE = "posted_date",
  CARDHOLDER_NAME = "cardholder_name",
  TEAM_NAME = "team_name",
  MERCHANT = "merchant",
  SIMPLIFIED_MERCHANT = "simplified_merchant_name",
  SETTLED_DATE = "settled_date",
  CARD_TITLE = "card_name",
  CARD_LAST_FOUR = "card_last_four",
  AMOUNT = "amount",
  AMOUNT_CURRENCY_CODE = "amount_currency_code",
  RECEIVING_CURRENCY_AMOUNT = "receiving_currency_amount",
  RECEIVING_CURRENCY_CODE = "receiving_currency_amount_currency_code",
  STATUS = "txn_status",
  FX_FEE = "fx_fee",
  FX_TYPE = "fx_type",
  FX_RATE = "fx_rate",
}

export interface IGetCardsTrxnsData {
  [GET_CARDS_TRXNS_DATA_PARAMS.ID]: string;
  [GET_CARDS_TRXNS_DATA_PARAMS.TRANSACTION_NUMBER]: string;
  [GET_CARDS_TRXNS_DATA_PARAMS.CREATED_AT]: string;
  [GET_CARDS_TRXNS_DATA_PARAMS.POSTED_DATE]: string;
  [GET_CARDS_TRXNS_DATA_PARAMS.CARDHOLDER_NAME]: string;
  [GET_CARDS_TRXNS_DATA_PARAMS.TEAM_NAME]: string;
  [GET_CARDS_TRXNS_DATA_PARAMS.MERCHANT]: string;
  [GET_CARDS_TRXNS_DATA_PARAMS.SIMPLIFIED_MERCHANT]: string;
  [GET_CARDS_TRXNS_DATA_PARAMS.SETTLED_DATE]: string;
  [GET_CARDS_TRXNS_DATA_PARAMS.CARD_TITLE]: string;
  [GET_CARDS_TRXNS_DATA_PARAMS.CARD_LAST_FOUR]: string;
  [GET_CARDS_TRXNS_DATA_PARAMS.AMOUNT]: string; // Total converted amount (FX + TRXN amount)
  [GET_CARDS_TRXNS_DATA_PARAMS.AMOUNT_CURRENCY_CODE]: string;
  [GET_CARDS_TRXNS_DATA_PARAMS.RECEIVING_CURRENCY_AMOUNT]: string; // Original Trxn amount
  [GET_CARDS_TRXNS_DATA_PARAMS.RECEIVING_CURRENCY_CODE]: string;
  [GET_CARDS_TRXNS_DATA_PARAMS.STATUS]: CARD_TXN_STATUS;
  [GET_CARDS_TRXNS_DATA_PARAMS.FX_FEE]: string;
  [GET_CARDS_TRXNS_DATA_PARAMS.FX_RATE]: string;
  [GET_CARDS_TRXNS_DATA_PARAMS.FX_TYPE]: string;
}

export enum CARDS_TRXNS_RESPONSE_PARAMS {
  TOTAL = "total",
  CARD_TRANSACTIONS = "card_transactions",
  TOTAL_PAGE = "total_pg",
  CURRENT_PAGE = "current_pg",
  STATUS_MESSAGE = "status_message",
}

export interface ICardsTrxnsSuccessData {
  [CARDS_TRXNS_RESPONSE_PARAMS.CARD_TRANSACTIONS]: IGetCardsTrxnsData[];
  [CARDS_TRXNS_RESPONSE_PARAMS.TOTAL_PAGE]: number;
  [CARDS_TRXNS_RESPONSE_PARAMS.CURRENT_PAGE]: number;
  [CARDS_TRXNS_RESPONSE_PARAMS.TOTAL]: number;
}

export interface ICardsTrxnsErrorData {
  [CARDS_TRXNS_RESPONSE_PARAMS.STATUS_MESSAGE]: string;
}

export type IGetCardsTrxnsResponsePayload = ICardsTrxnsSuccessData | ICardsTrxnsErrorData;

export type IGetCardsTrxnsAxiosResponse = {
  status: number;
  payload: IGetCardsTrxnsResponsePayload;
};

export enum CARDS_TRXNS_TABLE_COLUMNS {
  CREATED = "Created",
  DATE_N_TIME = "Date & Time",
  CARDHOLDER = "Cardholder",
  BUDGET = "Budget",
  MERCHANT = "Merchant",
  SETTLED_DATE = "Completed",
  CARD_TITLE = "Card Title",
  CARD_LAST_FOUR = "Last 4 digits",
  AMOUNT = "Amount",
  ATTACHMENT = "Attachment/Receipts",
  STATUS = "Status",
}

export type SidePanelDetailPropsType = Pick<
  ISidePanelProps,
  "visible" | "onClose" | "title" | "sticky" | "buttons" | "loading"
>;

export interface ITrxnLifeCycle {
  txn_status: CARD_TXN_STATUS;
  created_at: string;
  txn_description: string;
  receiving_currency_amount: string;
  receiving_currency_amount_currency_code: string;
}

export interface ICardTrxnDetailsSuccessData {
  final_state: IGetCardsTrxnsData;
  life_cycle: ITrxnLifeCycle[];
  receipts: {
    expense: {
      expense_category_id: string;
      tags: { label: string; value: string }[];
      tax: {
        id: string;
        tax_name: string;
      };
      receipts: { id: string; url: string }[];
      comment: string;
    };
    transaction: {
      transaction_id: string;
      transaction_number: string;
    };
  };
}

export interface ICardTrxnDetailsErrorData {
  status_message: string;
}

export type ICardTrxnDetailsResponsePayload = ICardTrxnDetailsSuccessData | ICardTrxnDetailsErrorData;

export interface ICardTrxnDetailsResponse {
  status: number;
  payload: ICardTrxnDetailsResponsePayload;
}
