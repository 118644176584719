import userflow from "userflow.js";
import { getCookieValue, cookieNames } from "utility/CookieHelper";

export const userFlowInit = () => userflow.init(window.__ENV__.USERFLOW_API_KEY);

export const userFlowIdentify = () => (dispatch, getState) => {
  const user = getState().userInfoReducer?.data?.payload?.user;
  const companyRole = getCookieValue(cookieNames.USER_COMPANY_ROLE) || "";
  const userId = user?.id;
  const orgId = user?.organisation_id;
  const userName = user?.name;
  const orgName = user?.organisation_name;
  const isAdmin = user?.is_admin;
  /* Cost cutting only run for production env  */
  const IS_PRODUCTION_ENV = window.__ENV__.API_BASE_URL === "https://api.spenmo.com";
  /*
    To test on local env, please add ! before the IS_PRODUCTION_ENV variable below &&
    WARNING: PLEASE REMOVE THIS BEFORE MERGING TO MAIN
  */
  IS_PRODUCTION_ENV &&
    userId &&
    userflow.identify(userId, {
      userName,
      orgName,
      orgId,
      isAdmin,
      role: companyRole,
    });
};

export const initUserFlow = async () => {
  try {
    userFlowInit();
  } catch (e) {
    console.error(e, "Failed to initialize the userflow SDK");
  }
};
