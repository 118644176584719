import APIclient from "API/Client";
import { GetOrgId } from "utility";

export const FETCH_EMPLOYEE_REQUEST = "FETCH_EMPLOYEE_REQUEST";
export const FETCH_EMPLOYEE_SUCCESS = "FETCH_EMPLOYEE_SUCCESS";
export const FETCH_EMPLOYEE_FAILURE = "FETCH_EMPLOYEE_FAILURE";
export const FETCH_TEAM_EMPLOYEE_REQUEST = "FETCH_TEAM_EMPLOYEE_REQUEST";
export const FETCH_TEAM_EMPLOYEE_SUCCESS = "FETCH_TEAM_EMPLOYEE_SUCCESS";
export const FETCH_TEAM_EMPLOYEE_FAILURE = "FETCH_TEAM_EMPLOYEE_FAILURE";

const fetchDataReq = () => {
  return {
    type: FETCH_EMPLOYEE_REQUEST,
    data: {},
  };
};
const fetchDataSuccess = (data) => {
  return {
    type: FETCH_EMPLOYEE_SUCCESS,
    data: data,
  };
};

const fetchDataError = (data, comp) => {
  return {
    type: FETCH_EMPLOYEE_FAILURE,
    data: data,
  };
};
const fetchTeamEmpDataReq = () => {
  return {
    type: FETCH_EMPLOYEE_REQUEST,
    data: {},
  };
};
const fetchTeamEmpDataSuccess = (data) => {
  return {
    type: FETCH_EMPLOYEE_SUCCESS,
    data: data,
  };
};

const fetchTeamEmpDataError = (data, comp) => {
  return {
    type: FETCH_EMPLOYEE_FAILURE,
    data: data,
  };
};

export const empFunc = (orgId = GetOrgId(), hasWallet) => {
  return (dispatch) => {
    dispatch(fetchDataReq());
    const filter = hasWallet ? `?has_wallet=${hasWallet}` : "";
    APIclient.getData(`/api/v1/org/${orgId}/employees${filter}`)
      .then((data) => {
        dispatch(fetchDataSuccess(data.data));
      })
      .catch((err) => {
        dispatch(fetchDataError(err));
      });
  };
};

export const teamEmpFunc = (teamId = null, hasWallet, withAccountant = false, includeDefaultUsers = false) => {
  return (dispatch, getState) => {
    dispatch(fetchTeamEmpDataReq());
    const walletFilter = hasWallet ? `&has_wallet=${hasWallet}` : "";
    const selectedTeamId = teamId ? teamId : getState().teamReducer.selectedTeamId;
    const accountantFilter = withAccountant ? "&with_accountant=true" : "";
    const defaultUsers = includeDefaultUsers ? "&include_default_users=true" : "";
    APIclient.getData(
      `/api/v1/org/${GetOrgId()}/team/${selectedTeamId}/member?pg=0&limit=1000${walletFilter}${accountantFilter}${defaultUsers}`
    )
      .then((data) => {
        dispatch(fetchTeamEmpDataSuccess(data.data));
      })
      .catch((err) => {
        dispatch(fetchTeamEmpDataError(err));
      });
  };
};
