import APIclient from "API/Client";
import { GetOrgId } from "utility";
import { urlParamsBuilder } from "utility/APIWrapper";

const FETCH_MERCHANT_LIST = "FETCH_MERCHANT_LIST";

export const FETCH_MERCHANT_LIST_REQUEST = `${FETCH_MERCHANT_LIST}_REQUEST`;
export const FETCH_MERCHANT_LIST_SUCCESS = `${FETCH_MERCHANT_LIST}_SUCCESS`;
export const FETCH_MERCHANT_LIST_FAILURE = `${FETCH_MERCHANT_LIST}_FAILURE`;
export const RESET_MERCHANT_LIST_DATA = "RESET_MERCHANT_LIST_DATA";

const fetchMerchantListRequest = () => ({
  type: FETCH_MERCHANT_LIST_REQUEST,
});

const fetchMerchantListError = (data) => ({
  type: FETCH_MERCHANT_LIST_FAILURE,
  data: data,
});

const fetchMerchantListSuccess = (data) => ({
  type: FETCH_MERCHANT_LIST_SUCCESS,
  data: data,
});

export const resetMerchantListData = () => ({
  type: RESET_MERCHANT_LIST_DATA,
});

export const fetchMerchantList = (value) => {
  return (dispatch) => {
    dispatch(fetchMerchantListRequest());
    const payload = {
      search_term: value,
      organisation_id: GetOrgId(),
    };
    APIclient.postData(`/api/v1/transactions/search_merchants`, payload)
      .then((data) => {
        dispatch(fetchMerchantListSuccess(data?.data));
      })
      .catch((err) => {
        dispatch(fetchMerchantListError(err));
      });
  };
};

export const fetchAllMerchantList = (value) => {
  return (dispatch) => {
    dispatch(fetchMerchantListRequest());
    const payload = {
      organisationID: GetOrgId(),
      keywordField: "merchant",
      keywordValue: value,
      orderBy: "ASC",
    };
    const url = urlParamsBuilder(`/ms/spm-disbursement/v1/bill/specific-info`, payload);
    APIclient.getData(url)
      .then((data) => {
        dispatch(fetchMerchantListSuccess(data?.data));
      })
      .catch((err) => {
        dispatch(fetchMerchantListError(err));
      });
  };
};
