import React from "react";

import { Tooltip, WarningFilled, Tags } from "@spenmo/splice";

import styles from "./styles.module.scss";

export const VariationPercentage = ({ percentage = 0 }: { percentage: number }) => {
  return (
    <Tooltip
      placement="bottom"
      title={`This means you have factored-in a variation of ±${percentage}% on your estimated cost.`}
    >
      {percentage > 0 && <Tags.Label hierarchy="default" label={`±${percentage}%`} variant="info" />}
    </Tooltip>
  );
};

export const LastPaymentAlert = ({ gap }: { gap: string }) => {
  return (
    <Tooltip
      placement="bottom"
      title={`You paid ${gap} more than you estimated in the previous cycle. Kindly review this transaction for any anomalies.`}
    >
      <WarningFilled size="16" iconColor="var(--red-050)" className={styles.warning} />
    </Tooltip>
  );
};
