import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import ModalWrapper from "Modules/DS/Modal";
import ClientAgreementModal from "Views/KYX/Modal/ClientAgreement";
import { checkTermsAndConditionsExisting } from "Redux/Actions";
import { getPreliminaryData } from "Redux/Actions/KYX/GetPreliminaryData";

import styles from "./index.module.scss";

export const TermsAndCondition = () => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);

  const termsAndConditionsExistingData = useSelector((state: any) => state?.termsAndConditionsExistingReducer);
  const { isAgreedLatestTnC = true, isOnboardingFinish = true } = termsAndConditionsExistingData?.data?.payload || {};

  /**
   * Determine if the T & C modal should be displayed based on specific conditions.
   *
   * The modal should be shown if:
   * 1. The onboarding process is completed.
   * 2. The user has not agreed to the latest T & C.
   */
  const showTnC = !isAgreedLatestTnC && isOnboardingFinish;

  useEffect(() => {
    dispatch(checkTermsAndConditionsExisting());
    dispatch(getPreliminaryData());
  }, [dispatch]);

  useEffect(() => {
    setShowModal(showTnC);
  }, [showTnC]);

  return (
    <ModalWrapper visible={showModal} className={styles.wrapper} close={() => setShowModal(false)}>
      <ClientAgreementModal isUpdateAgreement onClose={() => setShowModal(false)} />
    </ModalWrapper>
  );
};
