import * as React from "react";
import classNames from "classnames";

import { trackEvent } from "utility/analytics";

import { ISingleLine, IMultiLine, ISingleLineWithLink } from "Views/Employee/types";

import styles from "./Pending.module.scss";

export const ActionTitle = ({ children }: { children: string }) => {
  return <h4 className={styles.action__title}>{children}</h4>;
};

export const SingleLine = ({ title, trailing }: ISingleLine) => {
  return (
    <li data-testid="single line" className={styles.single__line}>
      <ActionTitle>{title}</ActionTitle>
      <span>{trailing}</span>
    </li>
  );
};

export const MultiLine = ({ title, trailing, subtitle }: IMultiLine) => {
  return (
    <li data-testid="multi line" className={styles.multi__line}>
      <ActionTitle>{title}</ActionTitle>
      <span>{trailing}</span>
      <div data-testid="subtitle" className={styles.subtitle}>
        {subtitle}
      </div>
    </li>
  );
};

export const SingleLineWithLink = ({ title, trailing, link, text, analytics }: ISingleLineWithLink) => {
  const eventTracker = () => {
    if (!!analytics && analytics.enabled) {
      trackEvent(analytics.eventName, { ...analytics.properties });
    }
  };

  return (
    <li data-testid="link line" className={classNames(styles.single__line, styles.link__action)}>
      <ActionTitle>{title}</ActionTitle>
      <span>{trailing}</span>
      <a onClick={eventTracker} target="_blank" rel="noreferrer" href={link}>
        {text}
      </a>
    </li>
  );
};
