import React, { useContext, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import { useMutableData } from "API/useData";
import { getCookieValue, cookieNames } from "utility/CookieHelper";
import { USER_ROLE } from "constants/Team.constant";
import usePermissionCheck from "Permission/usePermissionCheck";
import { ISidePanelProps } from "Modules/DS/SidePanel";
import { IGetBillTrxnResponse, IPageWithTable, ITrxnToastContext } from "Views/Transaction/@types";
import {
  BILL_TRXNS_TABLE_ROWS,
  TRXN_TABLE_CLASS,
  TRXN_STATUS,
  BUTTON_ACTION,
  API_URL,
  ENABLED_PAY_IMMEDIATELY_STATUS,
} from "Views/Transaction/Constants";
import { API_URL as Bill_API_URL } from "Views/Bills/V2/constants";
import TrxnList from "Views/Transaction/List";
import { TRANSACTION_DETAIL_V2_PERMISSION_PARAMS } from "Views/Transaction/Permission";
import { getBillsTableColumns } from "./Columns";
import { usePageChange } from "../Hooks/usePageChange";
import Actions from "./Actions";
import { getUrlParam } from "utility";
import styles from "./index.module.scss";
import BillsTrxnSidePanel from "./BillsTrxnSidePanel";
import { getBillsTrxnSidePanelButtons } from "./BillsTrxnSidePanel/SidePanelButtons";
import { useBillTrxnDetailsFetch } from "./Hooks/useFetch";
import { getAdditionalInfoAboutButton, getAdditionalInfoText, isTrxnStatusToViewEditBillModal } from "./Helper";
import Toast from "Views/Transaction/Toast";
import { TrxnToastContext } from "Views/Transaction/Context";
import { Banner, InfoFilled } from "@spenmo/splice";
import useGetBillUrl from "Views/Bills/useGetBillUrl";
import { BillFormType } from "Views/Bills/V2/constants";
import APIClient from "API/Client";

import { useToaster } from "Views/Bills/V2/hooks";
import { TRANSACTION_PATHS } from "Route/Transaction/paths";

export const BillTrxnList = ({
  data,
  loading,
  error,
  total,
  errorMessage,
}: IPageWithTable<IGetBillTrxnResponse>): JSX.Element => {
  const history = useHistory();
  const { search } = history.location || {};
  const location = useLocation<any>();
  const invoiceId = getUrlParam(search, "invoiceid");
  const { setToaster } = useContext<ITrxnToastContext>(TrxnToastContext);
  const { appNotification } = useToaster();
  const userInfoData = useSelector((state: any) => state?.userInfoReducer?.data?.payload?.user);

  const [permissionList, loadingPermission] = usePermissionCheck(TRANSACTION_DETAIL_V2_PERMISSION_PARAMS, true) as [
    boolean[],
    boolean
  ];

  const [IS_EDIT_BILL_ALLOWED, IS_ABLE_TO_INITIATE_PAYMENT] = permissionList || [];

  const onPageChangeHandler = usePageChange("bills", data);

  const [showSidePanel, setShowSidePanel] = useState(false);
  const [selectedTrxn, setSelectedTrxn] = useState(null);
  const [formValue, setFormValue] = useState(null);
  const [formValueChanged, setFormValueChanged] = useState(false);
  const [showEditableFields, setShowEditableFields] = useState(false);
  const [discardChangesBtnClicked, setDiscardChangesBtnClicked] = useState(false);

  const { data: invoiceData, loading: sidePanelDataLoading, fetchData } = useBillTrxnDetailsFetch();

  const { data: billpayConfig, isValidating } = useMutableData(API_URL.billpayConfig);

  const { isPaymentEnabled, isPaymentRun } = useMemo(() => billpayConfig?.data?.payload || {}, [billpayConfig]);

  const isAdmin = userInfoData?.is_admin;
  const isUserBillCreatorOrAdmin = userInfoData?.id === invoiceData?.requestorID || isAdmin;
  const isAccountant = getCookieValue(cookieNames.USER_COMPANY_ROLE) === USER_ROLE.ACCOUNTANT;

  const areBillTrxnDetailsEditable = useMemo(
    () =>
      IS_EDIT_BILL_ALLOWED && isUserBillCreatorOrAdmin && isTrxnStatusToViewEditBillModal(invoiceData?.statusInfo?.key),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [IS_EDIT_BILL_ALLOWED, invoiceData, isUserBillCreatorOrAdmin]
  );

  const rowClickHandler = (record) => {
    setSelectedTrxn(record);
    setShowSidePanel(true);
    fetchData(record.id);
  };

  const { billUrl } = useGetBillUrl();

  useEffect(() => {
    if (invoiceId) {
      rowClickHandler({ id: invoiceId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceId]);

  useEffect(() => {
    const editBillToastState = location?.state?.editBillToaster;
    if (editBillToastState) {
      setToaster({
        show: editBillToastState?.visible,
        message: editBillToastState?.message,
        type: editBillToastState?.status,
      });
    }

    const refetchBillDetail = location.state?.refetchBillDetail;

    if (refetchBillDetail && selectedTrxn) {
      const windowHistory = window.history;
      const { refetchBillDetail, ...restState } = windowHistory.state?.state || {};
      fetchData(selectedTrxn.id);
      windowHistory.replaceState({ ...windowHistory.state, state: restState }, document.title);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);

  const closeSidePanel = () => {
    setShowSidePanel(false);
    setShowEditableFields(false);
    setSelectedTrxn(null);
    if (invoiceId) history.replace(billUrl);
  };

  // const isPaymentRun = Boolean(paymentRunPayload?.setting);
  // check if the user role able to initiate payment run
  const isEnableInitiatePaymentRun = isPaymentRun && IS_ABLE_TO_INITIATE_PAYMENT;

  // check if the status is approved and the payment run is on
  const isApproved =
    ENABLED_PAY_IMMEDIATELY_STATUS.includes(invoiceData?.statusInfo?.key) &&
    isEnableInitiatePaymentRun &&
    !location.pathname.includes("/bills/payment-run/pay");

  const markAsPaidAllowed =
    !isPaymentEnabled &&
    (isAdmin || isAccountant) &&
    ENABLED_PAY_IMMEDIATELY_STATUS.includes(invoiceData?.statusInfo?.key);

  // hide it when it's approved and the payment run is on
  const additionalInfoAboutButton =
    !areBillTrxnDetailsEditable &&
    !isApproved &&
    getAdditionalInfoAboutButton(invoiceData?.statusInfo?.key || "", isUserBillCreatorOrAdmin);

  const markAsPaid = async (selectedBillIds: string[]) => {
    try {
      const response = await APIClient.putData(Bill_API_URL.markAsPaid, {
        billIDs: selectedBillIds,
      });
      const {
        data: { payload },
      } = response;
      if (payload.isSuccess) {
        appNotification.success({
          message: `Bill has been marked as Paid`,
        });
        setShowSidePanel(false);
        setTimeout(() => {
          history.push("/");
          history.push("bills/submitted");
        }, 1);
      }
    } catch (err) {
      return;
    }
  };

  const sidePanelActionBtnClickHandler = (action: BUTTON_ACTION) => {
    switch (action) {
      case BUTTON_ACTION.PAY_IMMEDIATELY: {
        // TO DO: add validation to check eligibility
        history.push(`/bills/payment-run/pay/${btoa(JSON.stringify([invoiceData?.id]))}`);
        break;
      }
      case BUTTON_ACTION.MARK_AS_PAID: {
        markAsPaid([invoiceData?.id]);
        break;
      }
      default: {
        if (action === BUTTON_ACTION.EDIT && isTrxnStatusToViewEditBillModal(invoiceData?.status)) {
          history.push(`${location.pathname}/${BillFormType.EDIT}/${invoiceData.id}`);
        } else {
          setShowEditableFields(action === BUTTON_ACTION.EDIT);
          setDiscardChangesBtnClicked(action === BUTTON_ACTION.DISCARD);
          if (action === BUTTON_ACTION.SAVE) {
            // deleting the read-only Receipt/Proof of Payment field from form data
            delete formValue?.paymentReceipt;
            // call save changes API here to update form data
          }
        }
      }
    }
  };

  const sidePanelProps: ISidePanelProps = {
    title: "Bill Payment Details",
    onClose: closeSidePanel,
    visible: showSidePanel,
    loading: loadingPermission || sidePanelDataLoading || isValidating,
    buttons:
      showSidePanel && ((!additionalInfoAboutButton && areBillTrxnDetailsEditable) || isApproved || markAsPaidAllowed)
        ? getBillsTrxnSidePanelButtons(
            showEditableFields,
            sidePanelActionBtnClickHandler,
            formValueChanged,
            invoiceData?.statusInfo?.key,
            isPaymentEnabled
          )
        : [],
    sticky: true,
    stickyContent: markAsPaidAllowed ? "" : additionalInfoAboutButton,
    children: selectedTrxn && (
      <BillsTrxnSidePanel
        billTrxnDetailsEditable={areBillTrxnDetailsEditable}
        id={selectedTrxn.id}
        invoiceData={invoiceData}
        showEditableFields={showEditableFields}
        setFormValue={setFormValue}
        setFormValueChanged={setFormValueChanged}
        resetFormData={discardChangesBtnClicked}
      />
    ),
  };

  let additionalInfo;
  const limitExceeded = data?.filter((item: IGetBillTrxnResponse) => item.status?.key === TRXN_STATUS.exceeded);
  const insufficient = data?.filter((item: IGetBillTrxnResponse) => item.status?.key === TRXN_STATUS.insufficient);

  if ((limitExceeded?.length || insufficient?.length) && isPaymentEnabled) {
    additionalInfo = (
      <div className={styles.additionalInfoContainer}>
        <Banner
          variant="neutral"
          title=""
          description={getAdditionalInfoText(Boolean(limitExceeded?.length))}
          icon={InfoFilled}
        />
      </div>
    );
  }
  const props = {
    data,
    total,
    error,
    loading,
    errorMessage,
    sidePanelProps,
    actions: Actions(),
    rowAction: rowClickHandler,
    columns: getBillsTableColumns(isPaymentEnabled, isPaymentRun, IS_ABLE_TO_INITIATE_PAYMENT), // use the permission for admin & accountant roles
    className: TRXN_TABLE_CLASS.BILL,
    dispatchOnPageChange: onPageChangeHandler,
    pageSize: BILL_TRXNS_TABLE_ROWS,
    additionalInfo,
  };

  return (
    <>
      <TrxnList<IGetBillTrxnResponse> {...props} />
      <Toast />
    </>
  );
};
