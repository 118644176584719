import { GetOrgId } from "utility";

export const KYX_SERVICE = (): {
  KYB_FORM_DATA: string;
  SAVE_KYB_DRAFT_FORM_DATA: string;
  START_KYX_MANUALLY: string;
  USER_CONSENT: string;
  GET_FORM_CONFIGS: string;
  GET_SUMMARY_CONFIGS: string;
  COMPLETE: string;
  GET_SINGPASS_TOKEN: string;
  UPLOAD_DOCS_URL: string;
} =>
  ({
    KYB_FORM_DATA: `ms/spm-organisation/v1/kyb/${GetOrgId()}/form`,
    SAVE_KYB_DRAFT_FORM_DATA: `ms/spm-organisation/v1/kyb/${GetOrgId()}/form/save-draft`,
    START_KYX_MANUALLY: `ms/spm-organisation/v1/kyb/${GetOrgId()}/start`,
    USER_CONSENT: "/ms/spm-organisation/v1/agreements",
    GET_FORM_CONFIGS: `/ms/spm-organisation/v1/kyb/${GetOrgId()}/form/configs`,
    GET_SUMMARY_CONFIGS: `ms/spm-organisation/v1/kyb/${GetOrgId()}/form/configs-summary`,
    COMPLETE: "/ms/spm-organisation/v1/onboarding/kyb/complete",
    GET_SINGPASS_TOKEN: `/ms/spm-organization/v1/kyb/${GetOrgId()}/personnel`,
    UPLOAD_DOCS_URL: `/ms/spm-organisation/v1/kyb/${GetOrgId()}/documents`,
  } as const);

export const MY_INFO_BUSINESS_SERVICE = (path: string) => {
  let pathValue: string;
  const baseUrl = "ms/spm-organization/v1/myinfobiz/";
  switch (path) {
    case "authorise":
      pathValue = `${baseUrl}authorise`;
      break;
    case "kyb":
      pathValue = `${baseUrl}kyb`;
      break;
    default:
      break;
  }
  return pathValue;
};
export const MY_INFO_SERVICE = (path: string) => {
  let pathValue: string;
  const baseUrl = "ms/spm-organization/public/v1/myinfo/";
  switch (path) {
    case "authorise":
      pathValue = `${baseUrl}authorise`;
      break;
    case "callback":
      pathValue = `${baseUrl}callback`;
      break;
    case "submit":
      pathValue = `${baseUrl}submit`;
      break;
    case "token":
      pathValue = `${baseUrl}token/`;
      break;
    default:
      break;
  }
  return pathValue;
};
