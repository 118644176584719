import { useRef, useEffect } from "react";

export const useClickOutside = (callback = () => null, ignoreElements = []) => {
  const ref = useRef(null);

  useEffect(() => {
    const handler = (e) => {
      if (!ref.current) return;

      let needToIgnore = false;

      ignoreElements.forEach((selector) => {
        const elements = document.querySelectorAll(selector);

        if (!elements) return;

        elements.forEach((element) => {
          if (element.contains(e.target) || e.target === element) {
            needToIgnore = true;
          }
        });
      });

      if (!ref.current.contains(e.target) || e.target === ref.current) {
        if (!needToIgnore) {
          callback && callback(e);
        }
      }
    };

    document.addEventListener("click", handler);

    return () => {
      document.removeEventListener("click", handler);
    };
  }, [callback, ignoreElements]);

  return [ref];
};
