import React, { useState } from "react";
import { useCreateOrRequestCardContext } from "Views/Card/CreateOrRequest/index.context";
import { Actions } from "Views/Card/CreateOrRequest/Utilities/UseCreateOrRequestState";
import ChooseSpendingLock from "Views/Card/Components/SpendingLock/Choose";
import AddSpendingLock from "Views/Card/CreateOrRequest/Steps/SpendingLock/Add";
import ActionBtns from "Views/Card/CreateOrRequest/ActionBtns";

import { SPENDING_LOCK_TYPES, CARD_TYPE } from "Views/Card/types";

import "Views/Card/CreateOrRequest/Steps/index.scss";
import styles from "./index.module.scss";

export const SpendingLock = () => {
  const [selectedLockType, setSelectedLockType] = useState<SPENDING_LOCK_TYPES>(SPENDING_LOCK_TYPES.NONE);
  const [showAddSpendingLockScreen, setShowAddSpendingLockScreen] = useState<boolean>(true);
  const [clickedOnSpendingLockOrSkipped, setClickedOnSpendingLockOrSkipped] = useState<boolean>(false);

  const { state, dispatch, changeStep, cardType } = useCreateOrRequestCardContext();
  const { spendingLockType, spendingLocks, adminOrManagerInSelectedTeam } = state;

  const onChangeStep = (goToNextStep: boolean) => {
    if (!adminOrManagerInSelectedTeam || clickedOnSpendingLockOrSkipped || spendingLocks?.length > 0 || !goToNextStep) {
      changeStep(goToNextStep);
    } else {
      setClickedOnSpendingLockOrSkipped(true);
    }
  };

  let createButtonText: string;
  if (clickedOnSpendingLockOrSkipped || spendingLocks?.length > 0) {
    if (adminOrManagerInSelectedTeam && cardType === CARD_TYPE.VIRTUAL) {
      createButtonText = "Create Card";
    } else {
      createButtonText = "Next";
    }
  } else {
    createButtonText = "Skip";
  }

  return showAddSpendingLockScreen ? (
    <div className={styles.spendingLockContainer}>
      <p className="create-or-request-card-steps__header sp-margin-bottom--xl">Set Up Spending Lock</p>
      <section className={styles.spendingLockOptionsContainer}>
        <ChooseSpendingLock
          cardActionType="Create"
          onSpendingLockTypeClick={(selectedLockType: SPENDING_LOCK_TYPES) => {
            setSelectedLockType(selectedLockType);
            setShowAddSpendingLockScreen(false);
            setClickedOnSpendingLockOrSkipped(true);
          }}
          onRemove={() => {
            dispatch({ type: Actions.UPDATE_SPENDING_LOCK_TYPE, value: SPENDING_LOCK_TYPES.NONE });
            dispatch({ type: Actions.UPDATE_SPENDING_LOCKS, value: [] });
          }}
          spendingLockType={spendingLockType}
          spendingLocks={spendingLocks?.map((lock) => lock?.name)}
        />
      </section>
      <ActionBtns
        actionBtnName={createButtonText}
        loading={false}
        changeStep={onChangeStep}
        renderGoBackBtn={true}
        disableNextBtn={false}
      />
    </div>
  ) : (
    <AddSpendingLock
      selectedSpendingLockType={selectedLockType}
      toggleLockType={() => {
        setSelectedLockType((prevType) =>
          prevType === SPENDING_LOCK_TYPES.CATEGORY ? SPENDING_LOCK_TYPES.MERCHANT : SPENDING_LOCK_TYPES.CATEGORY
        );
      }}
      setShowAddSpendingLockScreen={setShowAddSpendingLockScreen}
    />
  );
};
