import { useState } from "react";

import { IUseCommonSearchProps } from "Modules/DS/Search";

export const useCommonSearchProps = (inputValue: string = ""): IUseCommonSearchProps => {
  const [value, setValue] = useState<string>(inputValue);
  const [loading, setLoading] = useState<boolean>(false);

  const stopLoader = () => setLoading(false);

  const onInputChange = (val: string) => {
    setValue(val);
    setLoading(true);
  };

  const clearInput = () => {
    setValue("");
    stopLoader();
  };

  const functions = {
    onInputChange,
    clearInput,
    stopLoader,
  };

  return {
    ...functions,
    get: { value, loading },
  };
};
