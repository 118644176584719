import React from "react";

//Modules
import Modal from "Modules/modal";
import Button from "Modules/button";

//Constant
import { LOGIN_PATH } from "constants/Routes.constants";

//utility
import useLogout from "utility/useLogout";

//style
import "./styles.scss";

const CustomSessionTimeoutModal = ({ errorMessage, buttonText, title = "Session Expired" }) => {
  const logout = useLogout();

  const handleTimeoutSession = () => {
    logout(LOGIN_PATH);
  };

  return (
    <Modal show title={title} className={"session-timeout-modal"}>
      <p>{errorMessage}</p>
      <Button
        testid={"goLogin"}
        text={buttonText}
        className="session-timeout-modal__button"
        rounded
        action={handleTimeoutSession}
      />
    </Modal>
  );
};

export default CustomSessionTimeoutModal;
