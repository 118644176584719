import React from "react";
import { IRouterType, PAGE_GRID_CONTENT_TYPE } from "Route/@types";
import { BILL_PATHS } from "Route/Bills/paths";

//Employee Components
import PayBills from "Views/PayBills/PayBills";
import BillsVersioning from "Views/Bills/BillsVersioning";
import UploadMultiple from "Views/UploadMultiple";
import withUseConfigHoc from "Route/WithUseConfigHoc";
import { TABS } from "Views/Bills/const";
import PaymentRunPreview from "Views/Bills/V2/PaymentRunPreview";

const WithConfigPayBills = withUseConfigHoc(PayBills);
const WithConfigUploadMultiple = withUseConfigHoc(UploadMultiple);

const billPaths = Object.keys(TABS).map((index) => ({
  id: `bill-2-${index + 1}`,
  path: TABS[index].pathname,
  component: <BillsVersioning tabKey={TABS[index].tabKey} />,
  title: TABS[index].title,
  authRequired: true,
  exactPath: true,
}));

export const BillRoute: IRouterType[] = [
  {
    id: "bill-1",
    path: BILL_PATHS.PAY_BILLS,
    component: <WithConfigPayBills />,
    title: "Pay bills",
    authRequired: true,
    exactPath: true,
    grid: {
      content: PAGE_GRID_CONTENT_TYPE.FIXED,
    },
  },
  ...billPaths,
  {
    id: "bill-4",
    path: BILL_PATHS.BULK_UPLOAD_BILLS,
    component: <WithConfigUploadMultiple />,
    title: "Upload multiples bill invoice",
    authRequired: true,
    exactPath: true,
    grid: {
      content: PAGE_GRID_CONTENT_TYPE.FIXED,
    },
  },
  {
    id: "bill-5",
    path: BILL_PATHS.PAYMENT_RUN_PREVIEW,
    component: <PaymentRunPreview />,
    title: "Payment Run Preview",
    authRequired: true,
    exactPath: true,
    grid: {
      content: PAGE_GRID_CONTENT_TYPE.FLUID,
    },
  },
  {
    id: "bill-6",
    path: BILL_PATHS.PAYMENT_RUN,
    component: <BillsVersioning tabKey="submittedBills" />,
    title: "Payment Run",
    authRequired: true,
    exactPath: true,
  },
  {
    id: "bill-7",
    path: `${BILL_PATHS.PAYMENT_RUN}/:form?/:id?/:operation?`,
    component: <BillsVersioning tabKey="submittedBills" />,
    title: "Payment Run",
    authRequired: true,
    exactPath: true,
  },
];
