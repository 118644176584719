import React from "react";
import { DynamicFormFieldProps } from "Modules/DynamicForm/types";
import { FormItemProps } from "antd/lib/form";
import { infoOutline } from "assets/img";
import { rules } from "./rules";
import Icon from "Modules/icons";
import style from "../../DynamicForm.module.scss";
import classNames from "classnames";
import { Rule } from "antd/lib/form";

export const formItemProps = (
  { extra, fieldProps, id, label, labelField, className, validator, validateStatus, help }: DynamicFormFieldProps,
  customRules?: Rule[]
): FormItemProps => {
  return {
    extra: fieldProps.noteText || extra,
    label: labelField || label,
    name: ["dynamicFields", String(id)],
    requiredMark: "optional",
    rules: customRules || rules(fieldProps, validator),
    normalize: fieldProps.isTrimSpace ? (value) => value?.replace?.(/\s/g, "") : null,
    tooltip: fieldProps.information
      ? {
          title: fieldProps.information,
          icon: <Icon src={infoOutline} />,
          placement: "right",
        }
      : undefined,
    className: classNames(fieldProps.indentation ? style[`indent${fieldProps.indentation}`] : undefined, className),
    validateStatus,
    help,
  };
};
