import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { errorIcon, successIcon } from "../../assets/img";
import Icon from "../icons";
import LoadingScreen from "../../Views/State/loadingScreen";
import "./statusView.scss";

const StatusView = ({
  success,
  header,
  message,
  ButtonLink = "/",
  ButtonText,
  error,
  primary,
  className,
  children,
}) => {
  if (!header && !message) {
    return <LoadingScreen />;
  }

  return (
    <div className={`status-view ${primary && "status-view--primary"} ${className && className}`}>
      <h2>{header || error}</h2>
      <Icon src={success ? successIcon : errorIcon} className={"status-view--icon"} />
      <p className={"status-view--message"}>{message}</p>
      {ButtonText && (
        <Link className={"status-view--redirect-btn"} to={ButtonLink}>
          {ButtonText}
        </Link>
      )}
      {children && children}
    </div>
  );
};

StatusView.propTypes = {
  success: PropTypes.bool,
  header: PropTypes.string,
  error: PropTypes.bool,
  message: PropTypes.string,
  ButtonLink: PropTypes.string,
  ButtonText: PropTypes.string,
  primary: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.any,
};

export default StatusView;
