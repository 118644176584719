import React, { useContext, useEffect, useRef } from "react";

import { capitalizeFirstLetter } from "utility";

import {
  SelectContext,
  ISelectContext,
  IDropdownContainer,
  ISingleSelectDropdown,
  initialSingleSelectDropdownStates,
  IBasicDropdownsState,
  ICommonDropdownProps,
} from "Modules/DS/Select";
import { useSimpleSearch } from "Modules/DS/Search";
import { IListContainer } from "Modules/DS/Filter/@types";
import { ILineProps, LINE_TYPE } from "Modules/DS/Menu/@types";
import { useSingleSelect } from "Modules/DS/Menu/List/useHooks/useSingleSelect";

import { Container } from "./Container";
import { getLabel } from "./getLabel";

export const SingleSelectDropdown = <T extends object = any>({
  height,
  children,
  displayKey,
  defaultValue,
  idKey = "id" as keyof T,
  appliedKey = "id" as keyof T,
  iconKey,
  searchValue = "",
  header = null,
  footer = null,
  onSelect = () => null,
  dropdownProps,
  clearDropdown = () => null,
  onClickOutside = () => null,
  presetValue,
  clear,
  parentPopoverId,
  states = { ...initialSingleSelectDropdownStates },
}: ISingleSelectDropdown<T>): JSX.Element => {
  const processedLabel = defaultValue.includes("/")
    ? defaultValue.split("/")[0].toLowerCase()
    : defaultValue.toLowerCase();

  const { get, onSearchHandler } = useSimpleSearch<T>(children, displayKey, searchValue);

  const { closeNested, select, nested } = useContext<ISelectContext>(SelectContext);

  const _defaultValue = `Select ${processedLabel}`;

  const isSelected: boolean = states.title && states.title !== _defaultValue;

  const clearingDropdown = useRef<boolean>(false);

  const isParentPopoverMounted = select.show && parentPopoverId && select.key === parentPopoverId;
  const isDropdownPopoverMounted = nested.show && nested.key === dropdownProps.id;

  useEffect(() => {
    if (clear) {
      handleClearDropdown();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clear]);

  useEffect(() => {
    if (states.selected && !states.title && clearingDropdown.current) {
      handleClearDropdown();
      clearingDropdown.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearingDropdown]);

  useEffect(() => {
    if (presetValue) {
      const checked: T = children.find((item) => presetValue === item[appliedKey as string]);
      if (!checked) return;

      if (states.title && !states.selected) {
        states?.setSelected?.(checked[idKey as string]);
        return;
      }

      if (!states.selected && !states.title && !clearingDropdown.current) {
        states?.setSelected?.(checked[idKey as string]);

        let { text, icon } = getLabel<T>(
          [checked],
          displayKey,
          _defaultValue,
          capitalizeFirstLetter(processedLabel),
          iconKey
        );

        states?.setTitle?.(text);
        states?.setIcon?.(icon);

        return;
      } else if (!states.selected && !states.title && clearingDropdown.current) {
        handleClearDropdown();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isParentPopoverMounted, isDropdownPopoverMounted, presetValue]);

  const onClickHandler = useSingleSelect((id: string) => {
    const selectedItem: T = children.find((item) => item[idKey as string] === id);

    if (!selectedItem[idKey as string] || typeof selectedItem[idKey as string] !== "string") return;
    if (!selectedItem[displayKey as string] || typeof selectedItem[displayKey as string] !== "string") return;
    if (!selectedItem[appliedKey as string] || typeof selectedItem[appliedKey as string] !== "string") return;

    states?.setSelected?.(selectedItem[idKey as string]);
    states?.setTitle?.(selectedItem[displayKey as string]);

    const value: string = selectedItem[appliedKey as string];

    onSelect?.(value);

    let { text, icon } = getLabel<T>(
      [selectedItem],
      displayKey,
      _defaultValue,
      capitalizeFirstLetter(processedLabel),
      iconKey
    );

    states.setTitle(text);
    states?.setIcon?.(icon);

    closeNested();

    onSearchHandler("");
  });

  const handleClearDropdown = () => {
    clearingDropdown.current = true;

    states?.setLoading?.(false);
    states?.setSelected?.("");
    states?.setTitle?.("");
    states?.setIcon?.("");

    clearDropdown?.();
    onSearchHandler("");
  };

  const onClickOutsideHandler = () => {
    onClickOutside?.();
    onSearchHandler("");
  };

  const _states: IBasicDropdownsState = {
    loading: states?.loading || get?.loading,
    setLoading: states?.setLoading,
    value: get.value,
    setValue: onSearchHandler,
  };

  const lineProps = (item: T): ILineProps => ({
    id: item[idKey as string],
    title: item[displayKey as string],
    src: item?.[iconKey as string],
    selected: states.selected === item[appliedKey as string],
    type: LINE_TYPE.SINGLE_LINE,
    loading: _states.loading,
    onClick: onClickHandler,
  });

  const _dropdownProps: ICommonDropdownProps = {
    selected: isSelected,
    clearDropdown: handleClearDropdown,
    onClickOutside: onClickOutsideHandler,
    title: isSelected ? states.title : _defaultValue,
    searchPlaceholder: `Search ${processedLabel}`,
    ...dropdownProps,
  };

  const listProps: Omit<IListContainer<T>, "children"> = {
    idKey,
    height,
    lineProps,
    loading: _states.loading,
  };

  const props: Omit<IDropdownContainer<T>, "children"> = {
    footer,
    header,
    listProps,
    states: _states,
    dropdownProps: _dropdownProps,
  };

  return <Container<T> {...props}>{get.data}</Container>;
};
