import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";

import loggerMiddleware from "./Middleware/logger";
import monitorReducerEnhancer from "./Enhancer/monitorReducer";
import rootReducer from "./Reducers";
import { initSplitio } from "utility/splitioInit";
import { initUserFlow } from "utility/userFlow";

const isDevelopmentEnv = process.env.NODE_ENV === "development";

export function configuredStore(preloadedState?) {
  const store = configureStore({
    reducer: rootReducer,
    middleware: [loggerMiddleware, ...getDefaultMiddleware()],
    preloadedState,
    enhancers: [monitorReducerEnhancer],
    devTools: isDevelopmentEnv,
  });
  return store;
}

const mainStore = configuredStore();

mainStore.dispatch(initSplitio());
initUserFlow();

export default mainStore;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof mainStore.getState>;
export type AppDispatch = typeof mainStore.dispatch;
