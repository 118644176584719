import React, { useCallback, useRef, useState } from "react";
import qs from "query-string";
import { Base64 } from "js-base64";
import { useHistory, useLocation } from "react-router-dom";

import { Quick } from "Modules/DS/Filter";
import { POSITION_ALGO_TYPE } from "Modules/DS/Popover";

import { REIMBURSEMENT_QUERY_PARAMS } from "Views/Reimbursement/Constants";
import { ILabelWithID, ILocationQueryParams } from "Views/Reimbursement/@types";
import { useReimbursementMerchantSearch } from "Views/Reimbursement/hooks/useMerchantSearch";

const MerchantFilter = () => {
  const { loading, data, fetchMerchants } = useReimbursementMerchantSearch();

  const getList = (arr: string[] = []) => {
    return arr.length > 0 ? arr.map((item: string) => ({ id: item, label: item })) : [];
  };

  const history = useHistory();
  const location = useLocation();

  const query: ILocationQueryParams = qs.parse(location.search, {
    parseNumbers: true,
    parseBooleans: true,
  });

  const { merchantIds = "" } = query;

  const [title, setTitle] = useState<string>();
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const presetValue = useRef<string[]>(
    Base64.decode(merchantIds)
      .split(",")
      .filter((item: string) => item.trim())
  );

  const merchantList = getList(data);
  const dataList = useCallback(() => getList(presetValue.current), []);

  const handleOnAddSelected = (value: string[]) => {
    history.push({
      pathname: location.pathname,
      search: qs.stringify({
        ...query,
        [REIMBURSEMENT_QUERY_PARAMS.MERCHANT_IDS]: Base64.encodeURI(value),
        [REIMBURSEMENT_QUERY_PARAMS.PAGE]: 1,
      }),
    });
  };

  const handleClearFilter = () => {
    history.push({
      pathname: location.pathname,
      search: qs.stringify({
        ...query,
        [REIMBURSEMENT_QUERY_PARAMS.MERCHANT_IDS]: undefined,
        [REIMBURSEMENT_QUERY_PARAMS.PAGE]: 1,
      }),
    });
  };

  const handleSetSelectedIds = (value: React.SetStateAction<string[]>) => {
    presetValue.current = value as string[];
    setSelectedIds(value);
  };

  return (
    <Quick.Elastic<ILabelWithID>
      onSearch={fetchMerchants}
      showEmptyState
      filterItemProps={{ id: "REIMBURSEMENTS-ACTION-MERCHANT-FILTER", positionAlgoType: POSITION_ALGO_TYPE.HORIZONTAL }}
      defaultValue="Merchant"
      displayKey="label"
      onAddSelected={handleOnAddSelected}
      clearFilter={handleClearFilter}
      states={{
        selectedIds,
        setSelectedIds: handleSetSelectedIds,
        setTitle,
        title,
        loading,
      }}
      presetValue={presetValue.current}
      dataSet={dataList()}
    >
      {merchantList}
    </Quick.Elastic>
  );
};

export default MerchantFilter;
