export const yellowInfo = require("assets/v1.1/icons/Flat/yellow-info.svg");
export const suggestion = require("assets/v1.1/icons/Flat/suggestion.svg") + "#fill";
export const grayInfo = require("assets/v1.1/icons/Flat/gray-info.svg");

export const emptyState = require("assets/v1.1/icons/Flat/empty.svg");
export const emptyStateAdd = require("assets/v1.1/icons/Flat/empty_add.svg");

export const premiumWhiteIcon = require("assets/v1.1/icons/Flat/ic_premium_white.svg");
export const premiumOrangeIcon = require("assets/v1.1/icons/Flat/ic_premium_orange.svg");

export const faqIcon = require("assets/v1.1/icons/Flat/faq_icon.svg");
export const playBtn = require("assets/v1.1/icons/Flat/play.svg");
export const removeBudgetIconCards = require("assets/v1.1/icons/Flat/remove_budget_icon_cards.svg");
export const removeBudgetIconReimbursement = require("assets/v1.1/icons/Flat/remove_budget_icon_reimbursement.svg");
export const removeBudgetIconInternalTrancsaction = require("assets/v1.1/icons/Flat/remove_budget_icon_int-transactions.svg");
export const removeBudgetIconWallet = require("assets/v1.1/icons/Flat/remove_budget_icon_wallet.svg");
export const discardModalIcon = require("assets/v1.1/icons/Flat/splice_files-warning.svg");
