import React from "react";
import { Redirect } from "react-router-dom";
import { IRouterType, PAGE_BASE_COLOR_TYPE, PAGE_GRID_COLUMNS, PAGE_GRID_CONTENT_TYPE } from "Route/@types";
import { APPROVAL_PATHS } from "Route/Approval/paths";

// Approval Component
import ApprovalCenter from "Views/Approval/ApprovalCenter/index";
import ApprovalFlowMainPage from "Views/ApprovalFlow";
import Reimbursement from "Views/Reimbursement";
import CreateFlowPage from "Views/ApprovalFlow/CreateFlow/index";
import withUseConfigHoc from "Route/WithUseConfigHoc";
import { REIMBURSEMENT_VIEWS } from "Views/Reimbursement/Constants";
import NewReimbursement from "Views/Reimbursement/Create";
import EditReimbursement from "Views/Reimbursement/Update";

const WithConfigApprovalCenter = withUseConfigHoc(ApprovalCenter);
const WithConfigApprovalFlow = withUseConfigHoc(ApprovalFlowMainPage);

const reimbursementPaths = Object.keys(REIMBURSEMENT_VIEWS).map((index) => ({
  id: `reimbursement-3-${REIMBURSEMENT_VIEWS[index].tabKey}`,
  path: REIMBURSEMENT_VIEWS[index].pathname,
  component: <Reimbursement tabKey={REIMBURSEMENT_VIEWS[index].tabKey} />,
  title: REIMBURSEMENT_VIEWS[index].title,
  authRequired: true,
  exactPath: true,
  grid: {
    columns: PAGE_GRID_COLUMNS.TWENTY_FOUR,
    content: PAGE_GRID_CONTENT_TYPE.FLUID,
    baseColor: PAGE_BASE_COLOR_TYPE.BRAND_NAVY,
  },
}));

export const ApprovalRoute: IRouterType[] = [
  {
    id: "approval-1",
    path: APPROVAL_PATHS.APPROVAL_FLOW,
    component: <WithConfigApprovalFlow />,
    title: "Approval flow",
    authRequired: true,
    exactPath: true,
  },
  {
    id: "approval-2",
    path: APPROVAL_PATHS.APPROVAL_REQUEST_CENTER,
    component: <WithConfigApprovalCenter />,
    title: "Approval center",
    authRequired: true,
    exactPath: true,
  },
  ...reimbursementPaths,
  {
    id: "approval-3-NEW-REIMBURSEMENTS",
    path: APPROVAL_PATHS.NEW_REIMBURSEMENTS,
    component: <NewReimbursement />,
    title: "Create New Reimbursement",
    authRequired: true,
    exactPath: true,
    grid: {
      columns: PAGE_GRID_COLUMNS.SINGLE,
      content: PAGE_GRID_CONTENT_TYPE.FLUID,
    },
  },
  {
    id: "approval-3-EDIT-REIMBURSEMENTS",
    path: APPROVAL_PATHS.EDIT_REIMBURSEMENTS,
    component: <EditReimbursement />,
    title: "Edit Reimbursement Details",
    authRequired: true,
    exactPath: true,
    grid: {
      columns: PAGE_GRID_COLUMNS.SINGLE,
      content: PAGE_GRID_CONTENT_TYPE.FLUID,
    },
  },
  {
    id: "approval-4",
    path: APPROVAL_PATHS.CREATE_APPROVAL_FLOW_WITH_PROCESS,
    component: <CreateFlowPage />,
    title: "Create approval flow with process type",
    authRequired: true,
    exactPath: true,
    grid: {
      columns: PAGE_GRID_COLUMNS.NONE,
    },
  },
  {
    id: "approval-5",
    path: APPROVAL_PATHS.EDIT_APPROVAL_FLOW_WITH_ID,
    component: <CreateFlowPage />,
    title: "Edit approval flow with id",
    authRequired: true,
    exactPath: true,
    grid: {
      columns: PAGE_GRID_COLUMNS.NONE,
    },
  },
  {
    id: "approval-6",
    path: APPROVAL_PATHS.VIEW_APPROVAL_FLOW_WITH_ID,
    component: <CreateFlowPage />,
    title: "View approval flow with id",
    authRequired: true,
    exactPath: true,
    grid: {
      columns: PAGE_GRID_COLUMNS.NONE,
    },
  },
  {
    id: "approval-7",
    path: APPROVAL_PATHS.OLD_APPROVAL_PAGE,
    component: (
      <Redirect
        to={{
          pathname: APPROVAL_PATHS.REIMBURSEMENTS,
        }}
      />
    ),
    title: "Approval listing",
    authRequired: true,
    exactPath: true,
    grid: {
      columns: PAGE_GRID_COLUMNS.NONE,
    },
  },
];
