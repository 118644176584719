import { IGetTrxnRequest } from "Views/Transaction/@types";

export const buildUrl = (url: string, payload: IGetTrxnRequest): string => {
  let params: string = "?";

  const singleParam = (key: string) => `${key}=${encodeURIComponent(payload[key])}`;

  const multipleParams = (key: string) =>
    payload[key].map((item: string) => `${key}=${encodeURIComponent(item)}`).join("&");

  const paramType = (key: string) => {
    return Array.isArray(payload[key]) ? multipleParams(key) : singleParam(key);
  };

  for (const key in payload) {
    if (!!payload[key]) params = params + paramType(key) + "&";
  }

  if (params.endsWith("&")) params = params.slice(0, -1);

  return url + params;
};
