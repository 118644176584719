import { urlParamsBuilder } from "utility/APIWrapper";

// need to trasnform this since only safari browser is supported for this ext.
export const TRANSFORMED_FILE_EXTENSIONS = ["heic", "heif"];

export const ALLOWED_FILE_EXTENSIONS = ["jpg", "jpeg", "png", "pdf", ...TRANSFORMED_FILE_EXTENSIONS];
export const ADDITIONAL_ATTACHMENT_ALLOWED_FILE_EXT = ["pdf", "png"];

// Used to accept props in the uploader.
export const ALLOWED_FILE_EXTENSIONS_FORMATTED = ALLOWED_FILE_EXTENSIONS.map((item) => `.${item}`).join(",");
export const ADDITIONAL_ATTACHMENT_EXT_FORMATTED = ADDITIONAL_ATTACHMENT_ALLOWED_FILE_EXT.map(
  (item) => `.${item}`
).join(",");

export const MAX_FILE_SIZE_ALLOWED_IN_MB = 10;
export const MAX_FILE_SIZE_ALLOWED = MAX_FILE_SIZE_ALLOWED_IN_MB * 1000 * 1000; // 10MB

// Up to 30 invoice (1 invoice = 1 bill)
export const MAX_ALLOWED_FILE_UPLOAD = 30;

export const VALIDATION_MSG = {
  maxLength: (fieldLabel: string, value: number) => `${fieldLabel} cannot exceed ${value} characters.`,
  required: (fieldLabel: string) => `${fieldLabel} cannot be empty`,
  duplicateRecipientName: "This recipient name has been taken. Use another name instead.",
};

export type BillParams = {
  form: BillFormType;
  id: string;
  operation: string;
  vendorId: string;
};

export enum BillFormType {
  NEW = "new",
  EDIT = "edit",
  BULK = "bulk",
}

export enum API_URL {
  // general
  org = "/api/v1/org",
  userInfo = "/api/v1/user/info",
  xeroAuth = "/api/v1/auth/xero/check",
  xeroTag = "/ms/b2b-accounting/v2/partners/trackingCategories",
  tax = "/api/v1/tax",
  disbursementV1 = "/ms/spm-disbursement/v1",
  disbursementV2 = "/ms/spm-disbursement/v2", // this API is similar to recipient detail used to get the readable label and value
  // bill payment
  recipientDetail = "/ms/spm-disbursement/v2/vendor",
  recipientPreview = "/ms/spm-disbursement/v3/vendor/preview",
  saveRecipient = "/ms/spm-disbursement/v2/vendor",
  currency = "/ms/spm-disbursement/v1/master/currency",
  recipientList = "/ms/spm-disbursement/v1/vendor",
  saveAsDraft = "/ms/spm-disbursement/v1/bill/draft",
  billDetailFields = "https://spenmo.com/api/bill-fields", // dummy for SWR
  countryList = "/ms/spm-disbursement/v1/master/country",
  currencyList = "/ms/spm-disbursement/v1/master/currency",
  recipientFields = "/ms/spm-disbursement/v1/bill/form-fields",
  getRecipientAmount = "/ms/spm-disbursement/v1/bill/calculate-recipient-amount",
  schedulePayment = "/ms/spm-disbursement/v1/sla",
  getBillFee = "/ms/spm-disbursement/v2/bill/corridor-simulator",
  extractOCR = "/ms/spm-disbursement/v2/ocr/extract",
  draftUpload = "/ms/spm-disbursement/v1/bill/draft/upload",
  submitBill = "/ms/spm-disbursement/v2/bill/single",
  validateInvoiceNumber = "/ms/spm-disbursement/v1/bill/duplication-check",
  previewBill = "/ms/spm-disbursement/v3/bill/preview",
  billpayConfig = "/ms/spm-disbursement/v1/config/page/billpay",
  // Payment run
  getPaymentRunSetting = "/ms/spm-disbursement/v1/payment-run/settings",
  billList = "/ms/spm-disbursement/v1/payment-run/payments/preview",
  topupList = "/ms/spm-disbursement/v1/master/topup/list",
  generateQR = "/ms/spm-topup/v3/dashboard/accounts/paynow",
  processPaymentBills = "/ms/spm-disbursement/v1/payment-run/payments",
  saasConfig = "/ms/spm-disbursement/v1/config/page/billpay",
  markAsPaid = `/ms/spm-disbursement/v1/bill/mark-as-paid`,
  calculateAmount = "/ms/spm-disbursement/v1/payment-run/calculate",
}

export const TEAMS_PAGE_URL = "/teams";

export const TRANSACTIONS_PAGE = (subtab) => urlParamsBuilder("/transactions", { tab: "pending", subtab });

export enum TEAM_LIMIT_EXCEEDED {
  YES = "yes",
  NO = "no",
  PARTIAL = "partial",
}

export const DATE_FORMAT = {
  standard: "YYYY-MM-DD",
  short: "DD MMM YYYY",
};

export const RECIPIENT_STATIC_FIELDS = ["currencyCode", "countryCode", "beneficiaryName"];
export const RECIPIENT_CHECKBOX_FIELDS = ["beneficiaryAdditionalBankIdentifierFlag"];
export const RECIPIENT_TAGINPUT_FIELDS = ["recipientEmail"];

/*
 * Email Regex based on https://www.emailregex.com/
 */
export const EMAIL_REGEX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

/**
 * IMPROVEMENT: should be put on outer constants file.
 * since this error code is a global thing
 */

export enum ErrorCode {
  INVALID_PAYLOAD = 3002,
  MAX_FILE_EXCEEDED = 3014,
  FX_RATE_EXPIRED_CODE = 4039, // Note: If fx rates get expired, then we get this error code
  ORG_NOT_ALLOWED = 4065,
  INTERNAL_SERVER_ERROR = 5000,
}

export const DEFAULT_ERROR_MESSAGE = "An error has occured. Please contact support@spenmo.com for further assistance.";

export const SG_FLAG_URL = "https://spenmo-prod.s3.ap-southeast-1.amazonaws.com/flags/SG.svg";

// These should be the same with SUBMITTED_BILLS_VIEW
export const SUBMITTED_BILLS_URL = "/bills/submitted";
export const PAYMENT_RUN_URL = "/bills/payment-run";

export enum SUBMITTED_BILLS_VIEW {
  DEFAULT = "/bills/submitted",
  PAYMENT_RUN = "/bills/payment-run",
}

/**
 * Operations that can be performed on Bills pages. Used as url params to match routes.
 */
export const BILLS_PAGE_OPERATIONS = {
  EXPORT: "export",
  MODAL: "modal",
};

/**
 * Options for different modals related to Bills. Used as url params to match routes.
 */
export const BILLS_MODAL_OPTIONS = {
  MARK_APPROVED_BILLS: "markApprovedBills",
};

/**
 * Options for different query params related to Bills Page.
 */
export enum BILLS_QUERY_PARAMS {
  CYCLE_ID = "cycleId",
  START_DATE = "startDate",
  END_DATE = "endDate",
  EXPORT_TYPE = "exportType",
  RANGE_TYPE = "rangeType",
  MARK_AS_PAID = "markAsPaid",
  APPROVED_BILLS = "approvedBills",
  PENDING_BILLS = "pendingdBills",
  IS_CSV_DOWNLOADABLE = "isCSVDownloadable",
  CURRENT_PAYMENT_CYCLE = "currentPaymentCycle",
}

export const EXPORT_DATE_RANGE_TYPES = {
  PAYMENT_RUN: "payment_run_period",
  DUE_DATES: "bill_due_dates",
  CREATION_DATES: "bill_creation_dates",
};
