import APIclient from "API/Client";

import { GET_KYC_STATUS_ACTION_TYPES, IKycStatus } from "Redux/Actions/Employee/Get/KycStatusList/types";

import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";

export const fetchKycStatusesRequest = (): {
  type: GET_KYC_STATUS_ACTION_TYPES.FETCH_KYC_STATUS_REQUEST;
} => {
  return {
    type: GET_KYC_STATUS_ACTION_TYPES.FETCH_KYC_STATUS_REQUEST,
  };
};

export const fetchKYCStatusesSuccess = (
  data: IKycStatus
): {
  type: GET_KYC_STATUS_ACTION_TYPES.FETCH_KYC_STATUS_SUCCESS;
  data: IKycStatus;
} => {
  return {
    type: GET_KYC_STATUS_ACTION_TYPES.FETCH_KYC_STATUS_SUCCESS,
    data: data,
  };
};

export const fetchKYCStatusesError = (
  data: IKycStatus
): {
  type: GET_KYC_STATUS_ACTION_TYPES.FETCH_KYC_STATUS_ERROR;
  data: IKycStatus;
} => {
  return {
    type: GET_KYC_STATUS_ACTION_TYPES.FETCH_KYC_STATUS_ERROR,
    data: data,
  };
};

export const getKYCStatuses = () => {
  return (dispatch) => {
    dispatch(fetchKycStatusesRequest());

    APIclient.getData("/ms/spm-organization/v1/kyc-statuses")
      .then((res: { [propName: string]: any }) => {
        if (res.data.status > HTTP_STATUS_CODE.OK) {
          dispatch(fetchKYCStatusesError(res.data));
        } else {
          dispatch(fetchKYCStatusesSuccess(res.data?.payload?.payload));
        }
      })
      .catch((err) => {
        dispatch(fetchKYCStatusesError(err));
      });
  };
};
