import { useContext, useState } from "react";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import { saveKYXFormAsDraft } from "Views/KYX/DataCalls";
import { KYXToastContext, KYXErrorToaster } from "Views/KYX/Context";
import { IKYXToastContext, IFormSaveAsDraft, ISaveAsDraftRequest } from "Views/KYX/@types";

/**
 * A custom hook that handles the process of saving KYX form data as draft.
 *
 * PSA: Ensure that this hook is used within the bounds of KYXToastContext.Provider
 *
 * @param {() => void} dispatchOnSuccess (optional) - A callback function that gets executed when the form data is successfully saved.
 * @returns {IFormSaveAsDraft} An object containing saveFormAsDraft and loading properties.
 *
 * This hook uses the `saveKYXFormAsDraft` function internally to make an HTTP request to save KYX form data as draft.
 * It passes the `payload` parameter to the `saveKYXFormAsDraft` function and checks the response status.
 * If the response status is not 200, it throws an error and displays a toast message using the `KYXErrorToaster` function.
 * If the `dispatchOnSuccess` callback function is defined, it is called when the form data is successfully saved.
 */

export const useFormSaveAsDraft = (
  dispatchOnSuccess?: (value: ISaveAsDraftRequest) => void,
  shouldShowLoader: boolean = true
): IFormSaveAsDraft => {
  const [loading, setLoading] = useState(false);
  const { setToaster } = useContext<IKYXToastContext>(KYXToastContext);

  const saveFormAsDraft = async (payload: ISaveAsDraftRequest) => {
    try {
      shouldShowLoader && setLoading(true);

      const response = await saveKYXFormAsDraft(payload);

      const errorMessage = response.data?.error?.message || response.data?.meta?.message;

      if (response?.data?.status === HTTP_STATUS_CODE.OK) {
        dispatchOnSuccess?.(payload);
      } else {
        throw new Error(errorMessage);
      }
    } catch (error) {
      const toast = KYXErrorToaster(
        error?.response?.data?.error?.message || error?.response?.data?.errors?.[0]?.message || error?.message
      );

      setToaster(toast);
      throw error;
    } finally {
      shouldShowLoader && setLoading(false);
    }
  };

  return { saveFormAsDraft, loading };
};
