import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";

import usePermissionCheck from "Permission/usePermissionCheck";

import { Typography, toast } from "@spenmo/splice";
import { ISidePanelProps } from "Modules/DS/SidePanel";
import { ISelectContext, SelectContext } from "Modules/DS/Select";
import { ActionButtonType, BulkActionType, PrimaryOrSecondary } from "Modules/DS/DataTable";
import { trackEvent } from "utility/analytics";

import {
  IPageWithTable,
  ITrxnListProps,
  IGetTrxnResponse,
  ITrxnFilterContext,
  GET_TRXN_REQUEST_PARAMS,
} from "Views/Transaction/@types";
import { TRXN_TABLE_CLASS, ALL_TRXNS_BULK_ACTION, ALL_TRXN_BULK_ACTION_TYPE } from "Views/Transaction/Constants";
import TrxnList, { useSelectedRows } from "Views/Transaction/List";
import { SidePanelDetails, useDetails } from "Views/Transaction/Details";
import { TrxnFilterContext } from "Views/Transaction/Context";
import { TRANSACTION_DETAILS_PERMISSION_PARAMS } from "Views/Transaction/Permission";
import { usePageChange } from "Views/Transaction/Hooks/usePageChange";
import Popover from "Views/Accounting/Workflow/BulkAction/Popover";
import FormContext from "Views/Accounting/Workflow/context/FormContext";
import { ANALYTIC_EVENT } from "Views/Accounting/Workflow/Analytic";
import { useLinkSubscription } from "Views/Transaction/All/useLinkSubscription";
import { SUBSCRIPTION_PATH } from "Route/Subscription/path";

import Info from "./Info";
import columns from "./Columns";
import Actions from "./Actions";
import { BulkUpdatePopover } from "./Bulk";
import { useBulkAction } from "./Bulk/useBulkAction";
import { isEditableTrxnType } from "../Helper";

import styles from "./List.module.scss";

export const AllTrxnList = ({ data, loading, error, total, errorMessage }: IPageWithTable<IGetTrxnResponse>) => {
  const h = useHistory();
  const [updatingCategories, setUpdatingCategories] = useState(false);
  const { moreActionMenu, toggleLoading, toggleVisible, size, setToaster, bulkActionContextValue } = useBulkAction();
  const { search } = useLocation();
  const queryParams = useMemo(() => new URLSearchParams(search), [search]);
  const fromSubscriptionPage = Boolean(queryParams.get("subscription"));
  const subscriptionName = queryParams.get("recipientNames");
  const subscriptionId = queryParams.get("subscription");

  const [IS_SAVE_EDITS_ALLOWED, loadingPermission] = usePermissionCheck(
    TRANSACTION_DETAILS_PERMISSION_PARAMS,
    true
  ) as [boolean, boolean];

  const onPageChangeHandler = usePageChange("all", data);
  const selectedRows = useSelectedRows<IGetTrxnResponse>();
  const {
    record,
    setDetail,
    actionRef,
    attachments,
    expenseData,
    detailsProps,
    sidePanelLoading,
    isHistoricalRefund,
    billImportedFromXeroData,
    setExpenseDataLoading,
  } = useDetails(IS_SAVE_EDITS_ALLOWED, loadingPermission);

  const { filter, onApplyFilter }: ITrxnFilterContext = useContext(TrxnFilterContext);
  const { onSelectHandler }: ISelectContext = useContext<ISelectContext>(SelectContext);

  const getRows: string[] = selectedRows.props.selectedRowKeys as string[];
  const getTotalAmount: { credit: number; debit: number } = selectedRows?.get?.reduce(
    (total, transaction) => {
      if (transaction.amount < 0) {
        total.credit += Math.abs(transaction.amount);
      } else {
        total.debit += transaction.amount;
      }
      return total;
    },
    {
      credit: 0,
      debit: 0,
    }
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onBulkSuccess = useCallback(() => (getRows.length > 1 ? selectedRows.reset() : null), [selectedRows]);

  useEffect(() => {
    if (selectedRows.get?.length > 0) {
      selectedRows.reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  useEffect(() => {
    if (fromSubscriptionPage) {
      selectedRows.set(data);
    }
  }, [data, fromSubscriptionPage]);

  const popoverHandler = async (id: ALL_TRXN_BULK_ACTION_TYPE) => {
    onSelectHandler(id);
    return Promise.resolve(true);
  };

  const isEditCategoryAllowed =
    IS_SAVE_EDITS_ALLOWED && selectedRows?.get?.some((trxn) => isEditableTrxnType(trxn.transaction_type));

  const addCategoryButton: PrimaryOrSecondary = isEditCategoryAllowed
    ? {
        type: ActionButtonType.Secondary,
        label: ALL_TRXNS_BULK_ACTION.CATEGORY,
        handler: async () => await popoverHandler(ALL_TRXN_BULK_ACTION_TYPE.CATEGORY),
      }
    : null;

  const handleLinkSubscription = useLinkSubscription();
  const selectedTnxNums = selectedRows?.get?.map((row) => row.transaction_number);

  const addSubscriptionButton: PrimaryOrSecondary = {
    type: ActionButtonType.Secondary,
    label: `Add to "${subscriptionName}" Subscription`,
    handler: async () => {
      await handleLinkSubscription(selectedTnxNums, subscriptionId, subscriptionName);
      onApplyFilter(filter);
    },
  };

  const bulkAction: ITrxnListProps<IGetTrxnResponse>["bulkAction"] = {
    dataName: "transaction",
    data: selectedRows.get,
    type: BulkActionType.Action_With_Menu,
    supportingInfo: <Info amount={getTotalAmount} />,
    clearSelection: selectedRows.reset,
    button: addCategoryButton,
    showMoreActionMenu: moreActionMenu.show,
    setShowMoreActionMenu: toggleVisible,
    loadingMoreactionMenu: moreActionMenu.loading,
    size,
    moreActionMenu: (
      <FormContext.Provider value={bulkActionContextValue}>
        <Popover
          onError={(error) => {
            toast.danger(error?.payload?.status_message || "Something went wrong");
          }}
          onProcess={toggleLoading}
          onFinish={toggleLoading}
          onSuccess={(message, type, option) => {
            // Track event
            trackEvent(type === "tax" ? ANALYTIC_EVENT.BULK_EDIT_TAX : ANALYTIC_EVENT.BULK_EDIT_TRANSACTION_TAGS, {
              filters: filter,
              section: "Transaction Page",
            });
            // Show toaster message
            toast.success(message, {
              duration: 1500,
              actionButton:
                type === "subscription" ? (
                  <Typography size="m" variant="body-content" weight={600}>
                    <Link
                      to={SUBSCRIPTION_PATH.SUBSCRIPTON_DETAILS.replace(":id", option.subscriptionId) + "?tab=payment"}
                      className={styles.link}
                    >
                      View
                    </Link>
                  </Typography>
                ) : null,
            });
            // Refetch data table
            onApplyFilter(filter);
            // Clear selected ids
            selectedRows.reset();
          }}
          selectedIds={selectedRows.get?.map((item) => item?.id)}
          selectedTransactionNumbers={selectedRows.get?.map((item) => item?.transaction_number)}
          showMoreActionmenu={moreActionMenu.show}
          setShowMoreActionMenu={toggleVisible}
          showAddSubscriptionItem
        />
      </FormContext.Provider>
    ),
  };

  if (fromSubscriptionPage && isEditCategoryAllowed) {
    bulkAction.button = addSubscriptionButton;
    bulkAction.secondButton = addCategoryButton;
  }

  const handleSubRedirection = () => {
    return h.push(SUBSCRIPTION_PATH.SUBSCRIPTON_DETAILS.replace(":id", subscriptionId));
  };

  const unloadedRowProps: Pick<ITrxnListProps<IGetTrxnResponse>, "setRowSelection"> = {
    setRowSelection: selectedRows.emptyProps,
  };

  const loadedRowProps: Pick<ITrxnListProps<IGetTrxnResponse>, "bulkAction" | "setRowSelection"> = {
    bulkAction,
    setRowSelection: selectedRows.props,
  };

  const relatedTransaction = useMemo(() => {
    return data?.find(
      (trxn: IGetTrxnResponse) => trxn.transaction_number === record?.transaction_number && trxn.id !== record?.id
    );
  }, [record]);

  const viewTransactionLinkClickHandler = () => {
    if (relatedTransaction) {
      // to fetch the expense data for the selected trxn's related trxn
      setExpenseDataLoading(true);
      setDetail(relatedTransaction);
    }
  };

  const sidePanelProps: ISidePanelProps = {
    ...detailsProps,
    loading: sidePanelLoading,
    children: (
      <SidePanelDetails
        record={record}
        expenseData={expenseData}
        handleSaveRef={actionRef}
        visible={detailsProps.visible}
        attachments={attachments.current}
        closeDetails={detailsProps.onClose}
        disableEditing={!IS_SAVE_EDITS_ALLOWED}
        isHistoricalRefund={isHistoricalRefund}
        xeroBillBannerData={billImportedFromXeroData}
        relatedTransaction={relatedTransaction}
        viewTransactionClickHandler={viewTransactionLinkClickHandler}
      />
    ),
  };

  const subscriptionEmptyState = {
    emptyStateProps: {
      title: "No transactions to show",
      subtitle: "You have added all potential transactions to this subscription",
      buttonProps: { onClick: handleSubRedirection, text: "View Subscription" },
    },
  };

  const props: ITrxnListProps<IGetTrxnResponse> = {
    subtitle: "Track and tag all paid expenses in your organization",
    data,
    total,
    error,
    loading,
    errorMessage,
    sidePanelProps,
    actions: Actions(),
    rowAction: setDetail,
    columns: columns(getRows, updatingCategories, IS_SAVE_EDITS_ALLOWED, loadingPermission),
    className: TRXN_TABLE_CLASS.ALL,
    dispatchOnPageChange: onPageChangeHandler,
    pageSize: filter[GET_TRXN_REQUEST_PARAMS.LIMIT],
    ...(fromSubscriptionPage && subscriptionEmptyState),
    ...(loading ? unloadedRowProps : loadedRowProps),
  };

  return (
    <>
      <TrxnList<IGetTrxnResponse> {...props} />
      <BulkUpdatePopover<IGetTrxnResponse>
        data={data}
        selectedRows={getRows}
        onSuccess={onBulkSuccess}
        bulkLoader={setUpdatingCategories}
      />
    </>
  );
};
