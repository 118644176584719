import * as React from "react";
import { Modal } from "antd";
import { modalCloseIcon } from "assets/img";

import "Views/Card/Review/Modal/index.scss";

interface CardReviewModalProps {
  children: React.ReactNode;
  onClose: () => void;
  width: number;
  title: string;
}

const CardReviewModal = ({ children, onClose, width, title }: CardReviewModalProps) => {
  return (
    <Modal
      visible
      title={title}
      footer={null}
      maskClosable={false}
      className="card-review-request-modal"
      width={width}
      onCancel={onClose}
      closeIcon={<img src={modalCloseIcon} alt="close icon" />}
    >
      {children}
    </Modal>
  );
};

export default CardReviewModal;
