import React from "react";
import { Col, Row, Divider } from "antd";

import {
  IFormElement,
  IDocumentStep,
  IStepInformation,
  TDocumentStepProps,
  IDocumentWithSingleStep,
  IDocumentWithMultipleSteps,
  IDocumentWithMultipleHeaderAndSteps,
} from "Views/KYX/@types";
import { KYXElements } from "Views/KYX/Elements";
import GuidelinesButton from "Views/KYX/Modal/Guidelines/Button";
import { DOCUMENT_STEP_TYPE, FORM_SECTION_COMPONENT } from "Views/KYX/Constants";

import { SectionCard, SingleSectionCardHeader } from "Views/KYX/Progress/Step/Card";

import styles from "./index.module.scss";

export const FormElement = ({ element }: { element: IFormElement }) => {
  return (
    <Col {...element.col}>
      {element.guidelines && (
        <div className={styles.guidelines}>
          <GuidelinesButton type={element.guidelines} />
        </div>
      )}
      <KYXElements {...element.element} />
    </Col>
  );
};

export const StepInformation = ({ row, document }: IStepInformation) => {
  return (
    <Row {...row}>
      {document.map((element, index) =>
        element ? <FormElement key={element.key ? element.key : index + "_form-element"} element={element} /> : null
      )}
    </Row>
  );
};

export const DocumentWithMultipleSteps = ({ container, documents }: IDocumentWithMultipleSteps) => {
  return (
    <SectionCard {...container}>
      {documents.length > 0 &&
        documents.map(({ key, document, row }, index) => {
          return (
            <div key={key}>
              <StepInformation document={document} row={row} />
              {index + 1 < documents.length && <Divider />}
            </div>
          );
        })}
    </SectionCard>
  );
};

export const DocumentWithMultipleHeaderAndSteps = ({ documents, className }: IDocumentWithMultipleHeaderAndSteps) => {
  return (
    <SectionCard type={FORM_SECTION_COMPONENT.CONTAINER} className={className}>
      {documents.length > 0 &&
        documents.map(({ key, document, row, header }, index) => {
          return (
            <div key={key}>
              <SingleSectionCardHeader key={key + "header"} {...header} />
              <StepInformation key={key + "information"} document={document} row={row} />
              {index + 1 < documents.length && <Divider />}
            </div>
          );
        })}
    </SectionCard>
  );
};

export const DocumentWithSingleStep = ({ container, row, document }: IDocumentWithSingleStep) => {
  return (
    <SectionCard {...container}>
      <StepInformation document={document} row={row} />
    </SectionCard>
  );
};

export const DocumentStep = ({ type, ...props }: IDocumentStep) => {
  let Component: (prop: TDocumentStepProps) => JSX.Element;

  switch (type) {
    case DOCUMENT_STEP_TYPE.MULTIPLE_HEADERS_AND_STEPS:
      Component = DocumentWithMultipleHeaderAndSteps;
      break;
    case DOCUMENT_STEP_TYPE.MULTIPLE_STEPS:
      Component = DocumentWithMultipleSteps;
      break;
    case DOCUMENT_STEP_TYPE.NONE:
      return <></>;
    case DOCUMENT_STEP_TYPE.DEFAULT:
    default:
      Component = DocumentWithSingleStep;
      break;
  }

  return <Component {...props} />;
};
