import React from "react";
import cn from "classnames";

import { ILineProps, LINE_TYPE, MENU_CLASS_NAME } from "Modules/DS/Menu/@types";
import { Tooltip, TooltipArrowAlignmentEnum, TooltipPositionEnum } from "Modules/DS/Tooltip";

import { LineSpan } from "./Span";
import { LineIcon } from "./Icon";
import { LineCheckbox } from "./Checkbox";

import styles from "./Line.module.scss";

const SHOULD_SHOW_TOOLTIP = 24;

export const Line = ({
  id,
  title,
  onClick,
  type,
  selected,
  loading,
  src,
  alt = "icon",
  IconComponent: Component,
  disabled,
}: ILineProps): JSX.Element => {
  const isClickable: boolean = type === LINE_TYPE.CHECKBOX_SINGLE_LINE;

  const renderCheckbox = () => {
    return !isClickable ? null : <LineCheckbox id={id} selected={selected} onClick={onClick} loading={loading} />;
  };

  const renderIcon = () => {
    if (Component) {
      return Component;
    }
    return !src ? null : <LineIcon loading={loading} id={id} src={src} alt={alt} />;
  };

  const renderLine = () => {
    return <LineSpan id={id} loading={loading} onClick={onClick} title={title} />;
  };

  const withTooltip = (children) => {
    if (title?.length >= SHOULD_SHOW_TOOLTIP) {
      return (
        <Tooltip text={title} position={TooltipPositionEnum.BOTTOM} alignArrow={TooltipArrowAlignmentEnum.CENTER}>
          {children}
        </Tooltip>
      );
    }

    return children;
  };

  return withTooltip(
    <li
      id={id}
      className={cn(styles[MENU_CLASS_NAME.LINE_CONTAINER], { [styles.disabled]: disabled })}
      onClick={loading ? () => null : onClick}
    >
      {renderCheckbox()}
      {renderIcon()}
      {renderLine()}
    </li>
  );
};
