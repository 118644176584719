import { GET_BILLS_TRXN_REQUEST_PARAMS } from "Views/Transaction/@types";

export const BILL_TRXNS_TABLE_ROWS = 100;

export enum BILLS_TRXNS_COLUMNS {
  INVOICE_X_DATE = "Inv No. / Date",
  RECIPIENT = "Recipient",
  DUE_DATE = "Due Date",
  PAYMENT_RUN = "Payment Run",
  PAYMENT_DATE = "Payment Date",
  ATTACHMENT = "Attachment",
  STATUS = "Status",
  YOU_PAY = "You Pay",
  RECIPIENT_GETS = "Recipient Gets",
  INVOICE_AMOUNT = "Invoice Amount",
  MARK_AS_PAID_DATE = "Mark as Paid Date",
  // INVOICE_NUMBER = "Invoice Number",
  // RECEIVING_AMOUNT = "Receiving Amount",
  // ESTIMATED_ARRIVAL = "Estimated Arrival",
}

export enum BUTTON_ACTION {
  SAVE = "Save Changes",
  DISCARD = "Discard Changes",
  EDIT = "Edit Bill",
  PAY_IMMEDIATELY = "Pay Immediately",
  MARK_AS_PAID = "Mark as Paid",
}

export const resetBillsTrxnPaginationParams = {
  [GET_BILLS_TRXN_REQUEST_PARAMS.ROWS]: BILL_TRXNS_TABLE_ROWS,
  [GET_BILLS_TRXN_REQUEST_PARAMS.PAGE]: 0,
};

export const EMAIL_APPROVAL_STATE = {
  detail: "detail",
  approved: "approved",
  declined: "declined",
};

export const TRXN_STATUS = {
  exceeded: "limit_exceeded",
  draft: "draft",
  extracted: "extracted",
  waitingApproval: "waiting_approval",
  insufficient: "insufficient",
  pending: "pending",
  completed: "completed",
  payoutIssue: "payout_issue",
  received: "received", // Paid
  cancelled: "cancelled",
  reversed: "reversed",
  rejected: "rejected", // Declined
  approved: "approved", // Bill Processed
};

export const transactionStatuses = [
  "Extracting",
  "Pending Approval",
  "Approved",
  "Insufficient Balance",
  "Budget Overlimit",
  "Sending Payment",
  "Paid",
  "Reversed",
  "Cancelled",
  "Declined",
  "Bill Processed",
  "Waiting for Funds",
];

export const API_URL = {
  getPaymentRunSetting: "/ms/spm-disbursement/v1/payment-run/settings",
  updatePaymentSchedule: "/ms/spm-disbursement/v1/bill/payment-schedule",
  billpayConfig: "/ms/spm-disbursement/v1/config/page/billpay",
};

export const ENABLED_PAY_IMMEDIATELY_STATUS = [TRXN_STATUS.approved, TRXN_STATUS.pending];
