import { IDocumentsInfo } from "Views/KYX/@types";

export const IDR_MANUAL: IDocumentsInfo = {
  content:
    "Thank you for choosing Spenmo as your partner in managing your company’s expenses. \nTo start improving your accounts payable experience, please submit your company and identity documents for verification." as const,
  label: "Please prepare the following documents for the verification process:" as const,
  documents: [
    {
      title: "Identity Documents" as const,
      info: "(In PDF, PNG, or JPEG format for Authorized Users, Directors, and Beneficial Owners)" as const,
      list: [
        {
          document: "KTP (Indonesian)" as const,
        },
        {
          document: "Passport (Other Nationalities)" as const,
        },
      ],
    },
    {
      title: "Company Documents" as const,
      info: "(In PDF, PNG, or JPEG format)" as const,
      list: [
        {
          document: "NIB (Nomor Induk Berusaha)" as const,
        },
        {
          document: "Company Tax ID (NPWP)" as const,
        },
        {
          document:
            "Deed of Establishment (Akta Pendirian) & Ministry Ratification (SK Menkeh Akta Pendirian)" as const,
        },
        {
          label: "If there are changes in management structure:" as const,
          document:
            "Deed of Latest Changes in Management Structure (Akta Perusahaan Terbaru) & Ministry Ratification (SK Menkeh Akta Perusahaan Terbaru)" as const,
        },
      ],
    },
  ],
};
