import "./loadingIcon.scss";

import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

const LoadingIcon = (props) => {
  const { className, size, inline, width, height, ...additionalprops } = props;
  const _className = classNames(
    "sk-fading-circle",
    {
      "sk-fading-circle--inline": inline,
      [`sk-fading-circle--${size}`]: size,
    },
    className
  );

  const styles = width &&
    height && {
      width,
      height,
    };

  return (
    <div
      {...additionalprops}
      className={_className}
      style={{
        ...styles,
      }}
    >
      <div className="sk-circle1 sk-circle" />
      <div className="sk-circle2 sk-circle" />
      <div className="sk-circle3 sk-circle" />
      <div className="sk-circle4 sk-circle" />
      <div className="sk-circle5 sk-circle" />
      <div className="sk-circle6 sk-circle" />
      <div className="sk-circle7 sk-circle" />
      <div className="sk-circle8 sk-circle" />
      <div className="sk-circle9 sk-circle" />
      <div className="sk-circle10 sk-circle" />
      <div className="sk-circle11 sk-circle" />
      <div className="sk-circle12 sk-circle" />
    </div>
  );
};

LoadingIcon.propTypes = {
  className: PropTypes.string,
  size: PropTypes.string,
  inline: PropTypes.bool,
};

export default LoadingIcon;
