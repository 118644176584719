import React, { useContext, useEffect } from "react";
import { AccountingTagsContext } from "Modules/AccountingTags/context/AccountingTagsContext";
import SelectedTag from "../SelectedTag";
import classNames from "classnames";
import "./styles.scss";
import { useClickOutside } from "customHooks/useClickOutside";

const TagInput = ({ editable = true, customLabel, customIcon, onOpenChange, hasMissingField }) => {
  const { setOpenDropdown, setOpenSubMenu, openDropdown, selectedChildren, label } = useContext(AccountingTagsContext);
  const [accountingTagsRef] = useClickOutside(
    (e) => {
      if (!e.target.classList || !e.target.parentElement?.classList) return;
      if (
        e.target.classList.length < 0 ||
        e.target.classList[0] === "delete-tag-icon" ||
        e.target.parentElement.classList[0] === "delete-tag-icon"
      ) {
        return;
      }

      setOpenSubMenu(false);
      setOpenDropdown(false);
    },
    [".accounting-tags", ".transaction-tags", ".selected-tag-wrapper"]
  );

  const emptyLabel = editable ? <span>Add Tags</span> : "-";

  useEffect(() => {
    onOpenChange(openDropdown);
  }, [openDropdown, onOpenChange]);

  return (
    <div className={classNames("accounting-tag-input")}>
      <div className="ant-form-item-label">{customLabel || <label>{label}</label>}</div>
      <div ref={accountingTagsRef}>
        <div
          className={classNames(
            "ant-input tag-input",
            { "tag-input__error": hasMissingField },
            { disabled: !editable }
          )}
          onClick={() => {
            setOpenDropdown(!openDropdown);
            setOpenSubMenu(false);
          }}
        >
          {selectedChildren.length === 0 ? (
            <>{emptyLabel}</>
          ) : (
            selectedChildren.map(({ label, value, tagGroup }) => (
              <SelectedTag key={label} name={`${label} - ${value}`} tagGroup={tagGroup} editable={editable} />
            ))
          )}
          {customIcon || (
            <svg
              width="15"
              height="8"
              viewBox="0 0 15 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={`arrow-icon rotate-0`}
            >
              <path d="M13 2L7.43243 7L1.86486 2" strokeWidth="1.5" strokeLinecap="square" strokeLinejoin="round" />
            </svg>
          )}
        </div>
      </div>
    </div>
  );
};

export default TagInput;
