import APIclient from "API/Client";
import { GetOrgId } from "../../utility";
import { appNotification } from "../../Modules/appNotification/appNotification";
import { parseToURLEncodedForm } from "../../utility/APIWrapper";
import { LOADING_STATE } from "../../constants/Loading";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import { useIsSessionExpired } from "customHooks/Auth/useIsSessionExpired";

export const FETCH_LOW_BALANCE = "FETCH_LOW_BALANCE";
export const FETCH_LOW_BALANCE_SUCCESS = "FETCH_LOW_BALANCE_SUCCESS";
export const FETCH_LOW_BALANCE_FAILURE = "FETCH_LOW_BALANCE_FAILURE";

const fetchLowBalance = (loadingState) => {
  return {
    type: FETCH_LOW_BALANCE,
    loadingState,
  };
};

const fetchLowBalanceSuccess = (data) => {
  return {
    type: FETCH_LOW_BALANCE_SUCCESS,
    data: data,
  };
};

const fetchLowBalanceFailure = () => {
  return {
    type: FETCH_LOW_BALANCE_FAILURE,
  };
};

export const getLowBalanceActionFunc = () => {
  return async (dispatch) => {
    dispatch(fetchLowBalance(LOADING_STATE.GET));

    try {
      const orgId = GetOrgId();
      const { data } = await APIclient.getData(`/api/v1/org/settings/${orgId}`);

      if (data.status === 200) {
        const { low_balance_alert_setting } = data.payload.settings;
        const lowBalanceAmount = low_balance_alert_setting.amount || "";

        dispatch(
          fetchLowBalanceSuccess({
            enabled: low_balance_alert_setting.enabled === "true",
            amount: lowBalanceAmount,
          })
        );
      } else {
        throw data.payload.status_message;
      }
    } catch (error) {
      const isSessionExpired = useIsSessionExpired();
      !isSessionExpired &&
        appNotification.error({
          message: error,
        });

      dispatch(fetchLowBalanceFailure());
    }
  };
};

export const updateLowBalanceActionFunc = (formData) => {
  return async (dispatch) => {
    dispatch(fetchLowBalance(LOADING_STATE.UPDATE));

    try {
      const orgId = GetOrgId();
      const requestBody = parseToURLEncodedForm({
        low_balance_alert_setting: {
          amount: formData.amount,
          enabled: String(formData.enabled),
        },
      });
      const config = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      };
      const { data } = await APIclient.putData(`/api/v1/org/settings/${orgId}`, requestBody, false, config);
      if (data?.status === HTTP_STATUS_CODE.OK) {
        appNotification.success({
          message: "Settings has been updated successfully",
        });
        dispatch(fetchLowBalanceSuccess(formData));
      } else {
        throw data?.payload?.status_message;
      }
    } catch (error) {
      appNotification.error({
        message: error,
      });

      dispatch(fetchLowBalanceFailure());
    }
  };
};
