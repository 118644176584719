import React, { useContext } from "react";

import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";

import { downloadCSV, generateDownloadFilename } from "utility";

import { TOASTER_STATUS_TYPE } from "Modules/DS/Toaster";

import { useSmartCsvFilters } from "Views/Transaction/SmartCSV/v2";
import { DEFAULT_ERROR_MESSAGE, DOWNLOAD_SUCCESS_MSG } from "Views/Transaction/Constants";
import { decodeArrayBuffer, downloadPDF } from "Views/Transaction/Helper";
import {
  TrxnFilterContext,
  TrxnModalContext,
  TrxnToastContext,
  useAccountingTransaction,
} from "Views/Transaction/Context";
import { IGetTrxnRequest, ITrxnModalContext, ITrxnToastContext, ITrxnFilterContext } from "Views/Transaction/@types";
import {
  downloadRecipt,
  downloadTrxnsBankStatement,
  downloadTrxnsCsv,
  trimPaginationAndListParams,
} from "Views/Transaction/DataCalls";

export const verifyEmailInput = (email: string) => {
  const trimEmail = email.trim();

  // regex to validate email
  // We are using a positive lookahead assertion. This can help us avoid backtracking by matching a pattern only if a certain condition is met.
  const mailformat: RegExp = /^(?=[^\s@]+@[^\s@]+\.[^\s@]{2,3}$)[\w.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/;

  return mailformat.test(trimEmail);
};

export const useAllTrxnExportOptions = (count: number = 0, selectedRows?: string[], isExportButton?: boolean) => {
  const { filter }: ITrxnFilterContext = useContext<ITrxnFilterContext>(TrxnFilterContext);
  const { activePageDetails } = useAccountingTransaction();
  const appliedFilters = activePageDetails?.allTrxnFilters || {};
  const { setToaster }: ITrxnToastContext = useContext<ITrxnToastContext>(TrxnToastContext);
  const { resetModal }: ITrxnModalContext = useContext<ITrxnModalContext>(TrxnModalContext);

  const _filters = useSmartCsvFilters<IGetTrxnRequest>(isExportButton ? appliedFilters : filter);

  const invalidEmailToaster = () =>
    setToaster({ show: true, type: TOASTER_STATUS_TYPE.ERROR, message: "Invalid email" });

  const tryCatchBlock = async (
    downloadFunc: Function,
    successMessage: (data: any) => string | React.ReactNode,
    triggerOnSuccess?: (data: any) => void,
    errorMessage?: (error: any) => string,
    ...props: any[]
  ) => {
    try {
      trimPaginationAndListParams(_filters);

      const resp = await downloadFunc(_filters, ...props);

      if (resp.status > HTTP_STATUS_CODE.NO_CONTENT) {
        setToaster({ show: true, type: TOASTER_STATUS_TYPE.ERROR, message: DEFAULT_ERROR_MESSAGE });
      } else {
        triggerOnSuccess?.(resp.data);
        setToaster({ show: true, type: TOASTER_STATUS_TYPE.SUCCESS, message: successMessage(resp.data) });
      }
    } catch (error) {
      setToaster({
        show: true,
        type: TOASTER_STATUS_TYPE.ERROR,
        message: errorMessage?.(error) || DEFAULT_ERROR_MESSAGE,
      });
    }
  };

  const emailBankStatement = async (email: string) => {
    if (email.trim().length === 0) return;

    if (!verifyEmailInput(email)) {
      invalidEmailToaster();
      return;
    }

    const successMessage: string = "A download link to the exported data will be sent to your email";

    await tryCatchBlock(
      downloadTrxnsBankStatement,
      (data: Iterable<number>) => {
        const decoded = decodeArrayBuffer(data);

        return decoded?.message || successMessage;
      },
      () => resetModal(),
      (error) => {
        const data = decodeArrayBuffer(error?.response?.data);

        return data?.error || error.message;
      },
      email.trim()
    );
  };

  const downloadBankStatement = async () => {
    await tryCatchBlock(
      downloadTrxnsBankStatement,
      () => DOWNLOAD_SUCCESS_MSG,
      (data: Iterable<number>) => {
        const fileName = generateDownloadFilename("bank_statement", "pdf");
        downloadPDF(data, fileName);
      },
      (error) => {
        const data = decodeArrayBuffer(error?.response?.data);

        return data?.error || error.message;
      }
    );
  };

  const downloadRawCSV = async () => {
    await tryCatchBlock(
      downloadTrxnsCsv,
      () => DOWNLOAD_SUCCESS_MSG,
      (data: string) => downloadCSV(data, "all-transactions"),
      (error) => {
        const data = error?.response?.data;
        return data?.error || error.message;
      },
      selectedRows
    );
  };

  const exportRawCSV = async () => {
    const successMessage: string =
      "Exporting data. Data will be downloaded automatically after the export process is finished";

    await tryCatchBlock(
      downloadTrxnsCsv,
      () => successMessage,
      (data: string) => {
        resetModal();
        downloadCSV(data, "all-transactions");
      },
      (error) => {
        const data = error?.response?.data;
        return data?.error || error.message;
      },
      selectedRows
    );
  };

  const emailRawCSV = async (email: string) => {
    const successMessage: string = "A download link to the exported data will be sent to your email";

    if (email.trim().length === 0) return;

    if (!verifyEmailInput(email)) {
      invalidEmailToaster();
      return;
    }

    await tryCatchBlock(
      downloadTrxnsCsv,
      (data) => data?.message || successMessage,
      resetModal,
      (error) => {
        const data = error?.response?.data;
        return data?.error || error.message;
      },
      selectedRows,
      email.trim()
    );
  };

  const emailDownloadReceipt = async (email: string) => {
    if (email.trim().length === 0) return;

    if (!verifyEmailInput(email)) {
      invalidEmailToaster();
      return;
    }

    const successMessage = (
      <span>
        You will be notified via email at <strong>{email}</strong> when your download is ready
      </span>
    );

    await tryCatchBlock(
      downloadRecipt,
      () => successMessage,
      () => resetModal(),
      () => "We are unable to process your download now. Please try again later",
      email.trim()
    );
  };

  return { downloadBankStatement, emailBankStatement, downloadRawCSV, exportRawCSV, emailRawCSV, emailDownloadReceipt };
};
