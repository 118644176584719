/**
 * Enum representing the possible status values for KYB (Know Your Business) process.
 * @readonly
 * @enum {string}
 */
export enum KYB_STATUS {
  NOT_STARTED = "NOT_STARTED",
  STARTED = "STARTED",
  ADDITIONAL_DOCUMENTS_REQUIRED = "DOCUMENT_REVISION",
  SUBMITTED = "SUBMITTED",
  IN_REVIEW = "IN_REVIEW",
  ASSESSMENT_IN_PROGRESS = "RISK_ASSESSMENT",
  COMPLIANCE_REVIEW = "CHECKER_REVIEW",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
}

/**
 * List representing the introduction status values from the enum KYB_STATUS
 */
export const KYB_INTRO_STATUSES = [
  KYB_STATUS.NOT_STARTED,
  KYB_STATUS.STARTED,
  KYB_STATUS.ADDITIONAL_DOCUMENTS_REQUIRED,
] as const;

/**
 * List representing the review status values from the enum KYB_STATUS
 */
export const KYB_REVIEW_STATUSES = [
  KYB_STATUS.IN_REVIEW,
  KYB_STATUS.COMPLIANCE_REVIEW,
  KYB_STATUS.ASSESSMENT_IN_PROGRESS,
] as const;

/**
 * Enum representing the possible states for KYX (Know Your Customer/Business) process.
 * @readonly
 * @enum {string}
 */
export enum KYX_STATES {
  SUBMITTED = "Submitted",
  APPROVED = "Approved",
  REJECTED = "Rejected",
  IN_REVIEW = "In Review",
  ADDITIONAL_DOC_REQUIRED = "Additional Documents Required",
}
