import { useEffect, useRef, useState } from "react";

/**
 * Utility function to determine if an element's content overflows its bounds.
 */
export const useScrollOverflow = <T extends HTMLElement>(): [React.MutableRefObject<T>, boolean, boolean] => {
  const ref: React.MutableRefObject<T> = useRef(null);

  const [horizontalOverflow, setHorizontalOverflow] = useState(false);
  const [verticalOverflow, setVerticalOverflow] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    // Check for content overflow within the container

    const isElementOverflown = (element: HTMLElement): { x: boolean; y: boolean } => {
      // We add 8px to account for the scrollbar
      return {
        x: element.scrollWidth > element.clientWidth + 8,
        y: element.scrollHeight > element.clientHeight + 8,
      };
    };

    if (ref.current) {
      const val = isElementOverflown(ref.current);
      setVerticalOverflow(val.y);
      setHorizontalOverflow(val.x);
    }
  });

  return [ref, verticalOverflow, horizontalOverflow];
};
