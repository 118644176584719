import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import {
  sendFunc,
  createScheduledTransactionFunc,
  clearCreateScheduledTransaction,
  clearFetchSendMoney,
} from "../../Redux/Actions";
import Button from "../../Modules/button";
import ChoiceModal from "../../Modules/modal/choiceModal";
import { GetBaseAuthObject } from "../../utility";
import LoaderIcon from "../State/Loading/LoaderIcon";
import SendMoneyModal from "./SendMoneyModal";
import "./SendMoney.scss";
import { trackEvent } from "utility/analytics";
import { getParamsValue } from "utility";
import { QueryParamsKeys, QueryParamsVaules } from "constants/MoneyQueryParams.constants";
import { toast, Toast } from "@spenmo/splice";

const SendMoney = ({ hideButton, customBtnComponent = null }) => {
  const [show, setShow] = useState(false);
  const [receiverType, setReceiverType] = useState(null);

  const dispatch = useDispatch();

  const createScheduledTransactionData = useSelector((state) => state.createScheduledTransactionReducer);
  const sendData = useSelector((state) => state.moneyReducer);

  const loading = sendData?.sendLoading || sendData?.scheduleLoading;

  useEffect(() => {
    if (
      !sendData?.sendLoading &&
      sendData?.sendData?.payload &&
      sendData?.sendData?.status === 200 &&
      !sendData?.sendError
    ) {
      toast.success("Send Money Successful");
      dispatch(clearCreateScheduledTransaction());
      dispatch(clearFetchSendMoney());
    } else if (
      !sendData?.sendLoading &&
      (sendData?.sendError || (sendData?.sendData?.status && sendData?.sendData?.status !== 200))
    ) {
      const errMsg = sendData?.sendData?.payload?.status_message || "Send Money Failed";
      toast.danger(errMsg);
      dispatch(clearCreateScheduledTransaction());
      dispatch(clearFetchSendMoney());
    }
  }, [sendData, dispatch]);

  useEffect(() => {
    if (
      !createScheduledTransactionData?.loading &&
      createScheduledTransactionData?.data?.payload &&
      !createScheduledTransactionData?.error
    ) {
      if (createScheduledTransactionData?.data?.isRecurring) {
        toast.success("Successfully created recurring transaction");
      } else {
        toast.success("Successfully scheduled a transaction");
      }
    } else if (!createScheduledTransactionData?.loading && createScheduledTransactionData?.error) {
      const errMsg = sendData?.sendData?.payload?.status_message || "Send Money Failed";
      toast.danger(errMsg);
    }
    dispatch(clearCreateScheduledTransaction());
    dispatch(clearFetchSendMoney());
  }, [createScheduledTransactionData, dispatch]);

  const autoRenderSendMoney = () => {
    const [moneyActionType, sendMoneyTo] = getParamsValue(
      QueryParamsKeys.MONEY_ACTION_TYPE,
      QueryParamsKeys.SEND_MONEY_TO
    );
    if (moneyActionType === QueryParamsVaules.SEND_MONEY && ["user", "team"]?.includes(sendMoneyTo)) {
      setReceiverType(sendMoneyTo);
    }
  };

  useEffect(() => {
    autoRenderSendMoney();
  }, []);

  const submitSendData = (obj) => {
    const { wallet = {}, receiver } = obj || {};
    const walletType = wallet?.type === "user" ? "user" : "team";
    let payload = {};
    const senderTeamId = wallet?.team_id;
    const senderId = walletType === "user" ? GetBaseAuthObject().userId : senderTeamId;
    const receiverTeamId = receiverType === "user" ? receiver?.[0]?.team_id : receiver?.[0]?.id;
    if (obj?.schedule) {
      try {
        payload = {
          orgId: GetBaseAuthObject().orgId,
          senderId,
          senderTeamId: senderTeamId,
          senderType: obj?.wallet?.type?.toUpperCase?.(),
          receiverId: obj?.receiver?.[0]?.id,
          receiverTeamId: receiverTeamId,
          receiverType: receiverType?.toUpperCase?.(),
          currency: obj?.currency,
          amount: parseFloat(obj?.amount),
          schedule: obj?.schedule,
        };
        dispatch(createScheduledTransactionFunc(payload));
      } catch (e) {
        toast.danger("Invalid value");
      }
    } else {
      payload = {
        amount: {
          to_amount: obj?.amount,
          to_currency: obj?.currency,
          from_amount: obj?.amount,
          from_currency: obj?.wallet?.currency_code,
          fee: 0,
        },
        sender: {
          team_id: senderTeamId,
          type: walletType,
        },
        receiver: {
          team_id: receiverTeamId,
          type: receiverType,
        },
        organisation_id: GetBaseAuthObject().orgId,
      };
      if (receiverType === "user") {
        payload.sender.user_id = GetBaseAuthObject().userId;
        payload.receiver.user_id = obj?.receiver?.[0]?.id;
      }
      if (receiverType === "team" && obj?.wallet?.type === "user") {
        payload.sender.user_id = GetBaseAuthObject().userId;
      }
      dispatch(sendFunc(payload, true, { "recipient type": receiverType === "user" ? "colleague" : "team" }));
    }
  };

  const showPreferredSendMoneyScreen = (screenType) => {
    setReceiverType(screenType);
    setShow(false);
    trackEvent("send money to recipient type selected", {
      "recipient type": screenType === "user" ? "colleague" : "team",
    });
  };

  const onSendMoneyAction = () => {
    setShow(true);
    trackEvent("send money home clicked");
  };

  const getButtonElement = () => {
    switch (true) {
      case hideButton:
        return null;
      case loading:
        return <LoaderIcon />;
      case !!customBtnComponent:
        return customBtnComponent(onSendMoneyAction);
      default:
        return <Button className={"send-money-btn"} text={"Send Money"} action={onSendMoneyAction} secondary />;
    }
  };

  return (
    <>
      {getButtonElement()}
      <ChoiceModal
        className={"send-money-modal"}
        show={show}
        close={() => setShow(false)}
        subtitle={"Select recipient to send money to"}
        title={"Send Money"}
        showEmp={() => showPreferredSendMoneyScreen("user")}
        showTeam={() => showPreferredSendMoneyScreen("team")}
      />
      <SendMoneyModal
        show={receiverType}
        isTeam={receiverType === "team"}
        close={() => {
          setReceiverType(null);
        }}
        action={submitSendData}
        trackActions={true}
      />
      <Toast />
    </>
  );
};

SendMoney.propTypes = {
  hideButton: PropTypes.bool,
  customBtnComponent: PropTypes.func,
};

export default SendMoney;
