import React, { useState } from "react";
import { Popover } from "antd";
import Content from "./Content";
import { useClickOutside } from "customHooks/useClickOutside";
import ModalTaxImport from "Views/Taxes/Import";
import { Button, AddOutline } from "@spenmo/splice";
import { trackEvent } from "utility/analytics";
import { IAddTaxProps } from "../types";

import "./styles.scss";

const AddTax = ({ onAddNewTax, showImportModal, setShowImportModal, hasValidToken, partnerName }: IAddTaxProps) => {
  const [visible, setVisible] = useState(false);

  const closePopover = () => setVisible(false);
  const togglePopover = () => setVisible(!visible);
  const [triggerRef] = useClickOutside(closePopover);

  return (
    <>
      <div ref={triggerRef}>
        <Popover
          overlayClassName="add-tax__popover"
          getPopupContainer={(triggerNode) => triggerNode}
          content={
            <Content
              hasValidToken={hasValidToken}
              onImportTax={() => {
                closePopover();
                setShowImportModal(true);
                trackEvent("Click import from Xero on popover");
              }}
              onAddNewTax={() => {
                closePopover();
                onAddNewTax();
              }}
              partnerName={partnerName}
            />
          }
          visible={visible}
          placement="bottomRight"
        >
          <Button variant="primary" icon={AddOutline} onClick={togglePopover}>
            New
          </Button>
        </Popover>
      </div>
      <ModalTaxImport visible={showImportModal} onClose={() => setShowImportModal(false)} />
    </>
  );
};

export default AddTax;
