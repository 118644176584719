import Chip from "Modules/DS/Chip";
import { ESidePanelButtonType, SidePanel } from "Modules/DS/SidePanel";
import Icon from "Modules/icons";
import { dataTableNoRecordFound } from "assets/img";
import { pencil } from "assets/v1.1/icons/Line";
import classNames from "classnames";
import React, { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { capitalizeFirstLetter, currencyFormatter } from "utility";
import { trackEvent } from "utility/analytics";
import { chipStatus } from "../../const";
import sharedStyles from "../ManageRecipients.module.scss";
import { RecipientData, RecipientDataDynamicFields } from "../types";
import { formatDate } from "../utils/formatter";
import styles from "./RecipientDetail.module.scss";
import { sortByOrderNumber } from "Views/Bills/helper";

export interface RecipientDetailProps {
  data: RecipientData | null;
  onClose?: () => void;
  onEdit?: () => void;
}

const RecipientDetail = ({ data, onClose, onEdit }: RecipientDetailProps) => {
  const [visible, setVisible] = useState(false);
  const history = useHistory();

  useEffect(() => {
    setVisible(Boolean(data));
  }, [data]);

  const handleClose = () => {
    onClose?.();
    setVisible(false);
  };

  const handleCreateBill = () => {
    trackEvent("Recipient Side P - Create bill");
    history.push(`/bills/recipients/new/${data.ID}`);
  };

  const dynamicFormValue = (item: RecipientDataDynamicFields) => {
    switch (item.alias) {
      case "beneficiaryBankName":
        return data.bankName;

      case "paymentPurpose":
        return data.paymentPurpose;

      case "beneficiaryAdditionalBankIdentifierType":
        return item.value.toUpperCase();

      case "beneficiaryType":
        return capitalizeFirstLetter(item.value);
      case "recipientEmail": {
        return item.value.replace(/,/g, ", ");
      }

      default:
        return item.value;
    }
  };

  const dynamicFormFilter = (item: RecipientDataDynamicFields) => {
    return !["true", "false"].includes(item.value);
  };

  const renderContent = () => {
    if (!data) {
      return null;
    }

    // TODO: done remove this, will use it to show last transaction when the feature is ready
    const transactionsPanel = (
      <div className={styles.sidePanel}>
        <div className={classNames(styles.sidePanelContentHeader, styles.sidePanelContentTransactionHeader)}>
          <h2>Transactions</h2>
          {/* <div>View all</div> */}
        </div>

        <div className={styles.sidePanelContent}>
          <div>Upcoming Payments</div>
          {Boolean(data.upcomingPayments?.length) ? (
            React.Children.toArray(
              data.upcomingPayments?.map((item) => (
                <div className={styles.transactionItem}>
                  <div>{formatDate(item.timestamp)}</div>
                  <div>{currencyFormatter(item.amount, item.currency)}</div>
                  {Boolean(chipStatus?.[item.status]) && (
                    <Chip.Status title={chipStatus[item.status].label} status={chipStatus[item.status].variant} />
                  )}
                </div>
              ))
            )
          ) : (
            <div className={styles.noTransaction}>
              <img src={dataTableNoRecordFound} alt="recipients not found" width={32} height={32} />
              <div>No upcoming payments found</div>
            </div>
          )}

          <div>Last 3 Transactions</div>
          {Boolean(data.lastTransactions?.length) ? (
            React.Children.toArray(
              data.lastTransactions?.map((item) => (
                <div className={styles.transactionItem} onMouseOver={() => trackEvent("Recipient Side P - Trx")}>
                  <div>{formatDate(item.timestamp)}</div>
                  <div>{currencyFormatter(item.amount, item.currency)}</div>
                </div>
              ))
            )
          ) : (
            <div className={styles.noTransaction}>
              <img src={dataTableNoRecordFound} alt="recipients not found" width={32} height={32} />
              <div>No transactions found</div>
            </div>
          )}
        </div>
      </div>
    );

    return (
      <>
        <div className={styles.sidePanel}>
          <div className={styles.sidePanelContentHeader}>
            <div>
              <h2>{data.legalName}</h2>
              <div
                role="button"
                className={styles.editRecipientCTA}
                onClick={() => {
                  onEdit?.();
                  trackEvent("Recipient Side P - Edit");
                }}
              >
                <Icon src={pencil} />
                Edit Recipient
              </div>
            </div>
          </div>

          <div className={styles.sidePanelContent}>
            <div>
              <div>Recipient Country</div>
              <div>
                <Icon
                  className="country-select__flag"
                  src={`https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.5.0/flags/4x3/${data.countryCode.toLowerCase()}.svg`}
                />
                {data.countryName}
              </div>
            </div>
            <div>
              <div>Recipient Currency</div>
              <div>{data.currencyCode}</div>
            </div>
            {Boolean(data.dynamicFields?.length) &&
              sortByOrderNumber(data.dynamicFields)
                .filter(dynamicFormFilter)
                .map((item) => (
                  <Fragment key={item.id}>
                    {/* TODO: Refactor this when the BE updates the API to return section informations */}
                    {item.alias === "beneficiaryTIN" && (
                      <h3 className={styles.sectionHeader}>Default Tax Information</h3>
                    )}
                    <div key={item.id}>
                      <div>{item.label}</div>
                      <div>{dynamicFormValue(item)}</div>
                    </div>
                  </Fragment>
                ))}
          </div>

          <div className={styles.sidePanelContentFooter}>
            <div>
              <div>Recipient last modified by</div>
              <div className={sharedStyles.contentItem}>
                <div className={sharedStyles.initial}>{data.lastModified.name[0]}</div>
                <div>
                  <p className={sharedStyles.oneLine}>{data.lastModified.name}</p>
                  <p className={classNames(sharedStyles.oneLine, sharedStyles.secondary)}>
                    {formatDate(data.lastModified.timestamp)}
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div>Recipient created by</div>
              <div className={sharedStyles.contentItem}>
                <div className={sharedStyles.initial}>{data.createdInfo.name[0]}</div>
                <div>
                  <p className={sharedStyles.oneLine}>{data.createdInfo.name}</p>
                  <p className={classNames(sharedStyles.oneLine, sharedStyles.secondary)}>
                    {formatDate(data.createdInfo.timestamp)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* TODO: uncomment this when last transaction strategy is done */}
        {/* {transactionsPanel} */}
      </>
    );
  };

  return (
    <SidePanel
      visible={visible}
      onClose={handleClose}
      title="Bill Payment - Recipient Detail"
      sticky
      buttons={[
        {
          id: "SIDE-PANEL-BUTTON-001",
          text: "Create a Bill with this Recipient",
          action: handleCreateBill,
          type: ESidePanelButtonType.PRIMARY,
        },
      ]}
      className={styles.recipientDetail}
    >
      {renderContent()}
    </SidePanel>
  );
};

export default RecipientDetail;
