import React, { useState, useContext } from "react";
import { Form } from "antd";
import { formItemProps } from "./utils/formItemProps";
import { DynamicFormContext } from "Modules/DynamicForm/store";
import { DynamicFormFieldProps } from "Modules/DynamicForm/types";
import TagInputSelect from "Modules/TagInput";

const TagInput = (props: DynamicFormFieldProps) => {
  const { alias, fieldID, fieldProps, onChangeValue } = props;
  const [value, setValue] = useState([]);
  const handleChange = (value) => {
    onChangeValue?.(fieldID, value);
    setValue(value);
  };

  const handleFocus = () => {
    trackView(alias, value);
  };

  const handleBlur = () => {
    trackEdit(alias, value);
  };

  const validator = (_: unknown, values: string[], callback: (message?: string) => void) => {
    const invalidInputs = values?.filter((value) => !value.match(fieldProps.regex));
    if (!invalidInputs?.length) {
      callback();
    } else {
      callback(fieldProps.regexErrorMessage);
    }
  };

  const { className, ...rest } = formItemProps(props, [
    {
      validator,
    },
  ]);
  const { trackView, trackEdit } = useContext(DynamicFormContext);

  return (
    <Form.Item {...rest} className={className}>
      <TagInputSelect
        placeholder={fieldProps.placeholder}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
    </Form.Item>
  );
};

export default TagInput;
