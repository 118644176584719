//Auth Paths
export const AUTH_PATHS = {
  LOGIN: "/login",
  LOGOUT: "/logout",
  EMPLOYEE_OTP: "/otp",
  FORGOT_PASSWORD: "/forgot",
  FORGOT_PASSWORD_OTP: "/forgotpassword-otp",
  CHANGE_PASSWORD: "/changepassword",
  SECURITY_HUB: "/security-hub",
  ACTIVATE_GA: "/activate-ga",
  RESET_PASSWORD: "/resetpassword",
  FORCE_RESET: "/force-reset",
};
