import React from "react";

export type IBreakpoint = "xl" | "lg" | "md" | "sm";
export type IBreakpointMap = Record<IBreakpoint, string>;
export type IScreenMap = Partial<Record<IBreakpoint, boolean>>;
export type IScreenSizeMap = Partial<Record<IBreakpoint, number>>;

export type IGutter = number | undefined | Partial<Record<IBreakpoint, number>>;
export type IGutterRow = IGutter | [IGutter, IGutter];

export interface IBreakPointItem {
  breakPoint: IBreakpoint;
  offset: number;
  gutters: IGutterRow;
}

export const responsiveContainer: Array<IBreakPointItem> = [
  { breakPoint: "xl", offset: 128, gutters: [12, 24] },
  { breakPoint: "lg", offset: 72, gutters: [12, 24] },
  { breakPoint: "md", offset: 56, gutters: [12, 24] },
  { breakPoint: "sm", offset: 32, gutters: [12, 24] },
];

export const responsiveMap: IBreakpointMap = {
  sm: "(min-width: 1280px)",
  md: "(min-width: 1366px)",
  lg: "(min-width: 1440px)",
  xl: "(min-width: 1600px)",
};

export type ISubscribeFunc = (screens: IScreenMap) => void;

export type IRowAligns = "start" | "end" | "center" | "baseline" | "stretch";
export type IRowJustify = "start" | "end" | "center" | "space-around" | "space-between" | "space-evenly";
export type IRowColumn = number | "none";
export type IResponsive = "xl" | "lg" | "md" | "sm";
export type IResponsiveLike<T> = {
  [key in IResponsive]?: T;
};

export type IGap = number | undefined;

export type IResponsiveAligns = IResponsiveLike<IRowAligns>;
export type IResponsiveJustify = IResponsiveLike<IRowJustify>;
export type IResponsiveColumn = IResponsiveLike<IRowColumn>;
export interface IRowProps extends React.HTMLAttributes<HTMLDivElement> {
  gutter?: IGutterRow;
  align?: IRowAligns | IResponsiveAligns;
  justify?: IRowJustify | IResponsiveJustify;
  column?: IRowColumn | IResponsiveColumn; // Maximum value is 24
  responsiveArray?: Array<IBreakPointItem>;
  offset?: number;
  singleColumn?: boolean;
}

export type IColSpanType = number | string;

export type IFlexType = number | "none" | "auto" | string;

export interface IColSize {
  span?: IColSpanType;
  start?: IColSpanType;
  end?: IColSpanType;
  order?: IColSpanType;
}

export interface IColProps extends React.HTMLAttributes<HTMLDivElement> {
  span?: IColSpanType;
  order?: IColSpanType;
  start?: IColSpanType;
  end?: IColSpanType;
  sm?: IFlexType | IColSize;
  md?: IFlexType | IColSize;
  lg?: IFlexType | IColSize;
  xl?: IFlexType | IColSize;
}
