import React, { useContext } from "react";
import classNames from "classnames";

import useFocus from "utility/useFocus";

import {
  ISelectContext,
  IDropdownBuilder,
  SELECT_CLASSNAMES,
  initialBasicDropdownStates,
} from "Modules/DS/Select/@types";
import { SelectContext } from "Modules/DS/Select/context";
import Chip, { CHIP_STATUS, ILabelChipProps } from "Modules/DS/Chip";
import Popover, { IPopoverProps, POPOVER_WIDTH } from "Modules/DS/Popover";

import styles from "./Builder.module.scss";

export const DropdownBuilder = ({
  id,
  children,
  title,
  footer = null,
  header = null,
  labelIcon = "",
  selected = false,
  searchable = false,
  searchPlaceholder = "",
  clearDropdown = () => null,
  onClickOutside = () => null,
  popoverWidth = POPOVER_WIDTH.INHERIT,
  states = { ...initialBasicDropdownStates },
  containerClass,
  ignoreClickOnElements = [],
  positionAlgoType,
  showEmptyState = true,
}: IDropdownBuilder): JSX.Element => {
  const [inputRef, setInputFocus] = useFocus();
  const { nested, closeNested, nestedHandler } = useContext<ISelectContext>(SelectContext);

  const activeDropdown: boolean = nested.show && nested.key === id;
  const isSearchActive: boolean = activeDropdown && searchable;

  const iconClass = classNames(
    styles.chevron,
    { [styles.up__chevron]: activeDropdown && !searchable && !states.loading },
    { [styles.search__icon]: isSearchActive && !states.loading },
    { [styles.loading]: isSearchActive && states.loading }
  );

  const contentClassName = classNames(styles.content, { [styles.active]: activeDropdown });

  const handleOutsideClick = () => {
    onClickOutside?.();
    closeNested();
    states?.setLoading?.(false);
  };

  const popoverProps: Omit<IPopoverProps, "children"> = {
    active: activeDropdown,
    width: popoverWidth,
    className: classNames(SELECT_CLASSNAMES.DROPDOWN_POPOVER, {
      [styles.popoverWithoutPadding]: !showEmptyState,
    }),
    onClickOutside: handleOutsideClick,
    useDynamicPosition: true,
    containerClass,
    ignoreClickOnElements,
    positionAlgoType,
  };

  const labelChipProps: ILabelChipProps = {
    className: styles[SELECT_CLASSNAMES.DROPDOWN_LABEL],
    title: title,
    status: CHIP_STATUS.INFO,
    onRemove: clearDropdown,
    icon: labelIcon,
  };

  const searchProps: React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > = searchable && {
    autoFocus: true,
    autoComplete: "off",
    type: "search",
    ref: inputRef,
    value: states.value,
    placeholder: searchPlaceholder,
    name: SELECT_CLASSNAMES.DROPDOWN_SEARCH,
    className: SELECT_CLASSNAMES.DROPDOWN_SEARCH,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
      e.stopPropagation();
      states.setValue(e.target.value);
    },
  };

  const onDropdownContentHandler = (e: React.MouseEvent<Element, MouseEvent>) => {
    e.stopPropagation();

    if (activeDropdown && searchable) return;

    nestedHandler(id);
  };

  const _onClickHandler = isSearchActive && !inputRef.current ? setInputFocus : onDropdownContentHandler;

  const renderSearch = () => <input {...searchProps} />;

  const renderLabelChip = () => <Chip.Label {...labelChipProps} />;

  const renderTitleContent = () => (selected ? renderLabelChip() : title);

  const renderTitle = () => (isSearchActive ? renderSearch() : renderTitleContent());

  return (
    <div className={styles.container}>
      <div className={contentClassName} onClick={_onClickHandler}>
        <div className={styles.label}>{renderTitle()}</div>
        <div className={iconClass} />
      </div>
      <Popover {...popoverProps}>
        {header}
        {children}
        {footer}
      </Popover>
    </div>
  );
};
