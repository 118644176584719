import React from "react";
import store from "Redux/ConfigureStore";
import { Provider } from "react-redux";
import { IRouterType } from "Route/@types";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { RouteMap } from "Route/RouteMap";
import { PrivateRouter } from "Route/PrivateRouter";
import MobileAccessAlert from "Modules/mobileAccessAlert";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

const getRoute = () => {
  return RouteMap.map((routeData: IRouterType) => {
    switch (routeData.authRequired) {
      case true:
        return (
          <PrivateRouter
            path={routeData.path}
            exact={routeData.exactPath}
            key={routeData.id}
            isOnboardingRequired={routeData.isOnboardingRequired}
            acceptedStatuses={routeData.acceptedStatuses}
            grid={routeData.grid}
          >
            {routeData.component}
          </PrivateRouter>
        );
      case false:
        return (
          <Route path={routeData.path} exact={routeData.exactPath} key={routeData.id}>
            {routeData.component}
          </Route>
        );
      default:
        return null;
    }
  });
};

export const Router = (): JSX.Element => {
  return (
    <Provider store={store}>
      <DndProvider backend={HTML5Backend}>
        <BrowserRouter
          getUserConfirmation={() => {
            /* Empty callback to restrict the default browser prompt */
          }}
        >
          <MobileAccessAlert /> {/* if user tries to open dashboard in the mobile, this alert will be visible*/}
          <Switch>{getRoute()}</Switch>
        </BrowserRouter>
      </DndProvider>
    </Provider>
  );
};
