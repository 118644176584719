import React, { useState } from "react";
import { Button } from "@spenmo/splice";
import { useHistory } from "react-router-dom";
import { SUBSCRIPTION_PATH } from "Route/Subscription/path";
import { useSWRConfig } from "swr";

import DataTable, { DATA_TABLE_PADDING, DATA_TABLE_SIZE_TYPES, ITableProps, Table } from "Modules/DS/DataTable";
import { Columns } from "Views/SubscriptionManagement/AutoReconciled/Columns";
import { useMutableData } from "API/useData";
import { GetOrgId } from "utility";
import { IAutoReconciledTrxns, ITransactionWithSubscription, OnChange } from "Views/SubscriptionManagement/@types";
import { NOT_A_SUBSCRIPTION } from "Views/SubscriptionManagement/Constants";

import { mockAutoReconciledTransaction } from "./mock";
import { useReconcileTransaction } from "../hooks/useReconcileTransaction";

import styles from "./index.module.scss";

const List = () => {
  const history = useHistory();
  const { mutate } = useSWRConfig();

  const { data, isLoading } = useMutableData(
    `/api/v1/expenses/subscriptions/auto-reconcile?organisation_id=${GetOrgId()}`
  );
  const { trigger, isMutating } = useReconcileTransaction();
  const autoReconcileTransaction = data?.data?.payload?.transactions || [];
  const [reviewedSubscription, setReviewedSubscription] = useState<ITransactionWithSubscription[]>([]);

  const saveChanges = () => {
    const notReviewedTransaction = autoReconcileTransaction
      .filter((transaction) => !reviewedSubscription.find((reviewed) => reviewed.transactionId === transaction.id))
      .map((transaction) => ({ id: transaction.id, subscription_id: transaction.subscription.id }));

    const approvedTransaction = reviewedSubscription
      .filter((transaction) => transaction.subscriptionId !== NOT_A_SUBSCRIPTION.id)
      .map((transaction) => ({ id: transaction.transactionId, subscription_id: transaction.subscriptionId }))
      .concat(notReviewedTransaction);

    const rejectTransaction = reviewedSubscription
      .filter((transaction) => transaction.subscriptionId === NOT_A_SUBSCRIPTION.id)
      .map((transaction) => transaction.transactionId);

    trigger({ approved_transactions: approvedTransaction, rejected_transactions: rejectTransaction }).then(() => {
      history.push(SUBSCRIPTION_PATH.SUBSCRIPTION_MANAGEMENT);
      mutate(`/api/v1/expenses/subscriptions/auto-reconcile?organisation_id=${GetOrgId()}`);
    });
  };

  // Configuration for the DataTable
  const table: ITableProps<IAutoReconciledTrxns> = {
    loading: false,
    rowAction: () => {},
    dataSource: isLoading ? mockAutoReconciledTransaction() : autoReconcileTransaction,
    paginationEnabled: false,
  };

  const handleReviewSubscription: OnChange = ({ subscriptionId, transactionId }) => {
    const hasBeenReviewed = reviewedSubscription.find((transaction) => transaction.transactionId === transactionId);
    const initialTransaction = autoReconcileTransaction.find((transaction) => transaction.id === transactionId);

    if (!hasBeenReviewed) {
      setReviewedSubscription(reviewedSubscription.concat({ subscriptionId, transactionId }));
    } else if (initialTransaction.subscription.id !== subscriptionId) {
      setReviewedSubscription(
        reviewedSubscription.map((transaction) => {
          if (transaction.transactionId === hasBeenReviewed.transactionId) {
            return { subscriptionId, transactionId };
          }
          return transaction;
        })
      );
    } else if (initialTransaction.subscription.id === subscriptionId) {
      setReviewedSubscription(
        reviewedSubscription.filter((transaction) => transaction.subscriptionId === subscriptionId)
      );
    }
  };

  return (
    <>
      <DataTable size={DATA_TABLE_SIZE_TYPES.LARGE} padding={DATA_TABLE_PADDING.NONE}>
        <Table<IAutoReconciledTrxns> className={styles.list} {...table}>
          {Columns(isLoading, handleReviewSubscription)}
        </Table>
      </DataTable>
      {autoReconcileTransaction.length !== 0 && (
        <div className={styles.reviewedButton}>
          <Button variant="primary" size="m" onClick={saveChanges} loading={isMutating}>
            {reviewedSubscription.length === 0 ? "Mark as reviewed" : "Save changes and mark as reviewed"}
          </Button>
        </div>
      )}
    </>
  );
};

export default List;
