import React, { useState } from "react";
import { createPortal } from "react-dom";

import { Typography, Button, Modal } from "@spenmo/splice";

import { currencyFormatterV2 } from "utility";
import { getCookieValue, cookieNames } from "utility/CookieHelper";
import { BudgetAnalysisType } from "Views/SubscriptionManagement/@types";

import styles from "./styles.module.scss";

const budgetColors = [
  "var(--text-body-info)",
  "var(--blue-050)",
  "var(--green-060)",
  "var(--fill-interactive-primary)",
  "var(--card-purple)",
  "var(--card-red)",
  "var(--light-orange-060)",
  "var(--chart-blue-strong)",
];

const TOP_FOUR_BUDGET = 4;

export function AnnualSpendByBudget({ budgetsAnalysis }: { budgetsAnalysis: BudgetAnalysisType[] }) {
  const [showAllBudgets, setShowAllBudgets] = useState(false);

  const orgCurrencyCode = getCookieValue(cookieNames.CURRENCY_CODE);
  const topFourBudgets = budgetsAnalysis.slice(0, 4);

  const getBudgetItem = (inModal: boolean) => (budgetSpendDetails: BudgetAnalysisType, index: number) =>
    (
      <div className={styles.budget}>
        <Typography variant="body-content" size={inModal ? "s" : "caption-m"} className={styles.budgetName} tag="div">
          <div style={{ background: budgetColors[index % budgetColors.length] }} className={styles.rectangle} />
          {budgetSpendDetails.budget_name?.toLowerCase()}
        </Typography>
        <Typography variant="body-content" size={inModal ? "s" : "caption-m"} className={styles.budgetLabel}>
          {currencyFormatterV2(budgetSpendDetails.total_spend, orgCurrencyCode, true, 2)}
        </Typography>
      </div>
    );

  return (
    <>
      <div className={styles.header}>
        <Typography size="caption-m" variant="body-content" className={styles.title}>
          Annual spend by budget
        </Typography>
        {budgetsAnalysis.length > TOP_FOUR_BUDGET && (
          <Button
            size="s"
            variant="tertiary"
            onClick={() => setShowAllBudgets((prevState) => !prevState)}
            className={styles.btn}
          >
            View all
          </Button>
        )}
      </div>
      <div className={styles.budgetContainer}>{topFourBudgets.map(getBudgetItem(false))}</div>
      {createPortal(
        <Modal
          showModal={showAllBudgets}
          size="m"
          title="Annual spend by budget"
          onClose={() => setShowAllBudgets(false)}
          className={styles.modal}
        >
          <section>
            <section className={styles.content}>{budgetsAnalysis.map(getBudgetItem(true))}</section>
            <section className={styles.footer}>
              <Button
                variant="primary"
                size="s"
                onClick={(event) => {
                  event?.preventDefault();
                  setShowAllBudgets(false);
                }}
              >
                Close
              </Button>
            </section>
          </section>
        </Modal>,
        document.getElementById("modal_container")
      )}
    </>
  );
}
