import * as React from "react";
import { useState, useRef, useEffect } from "react";
import { Button } from "antd";
import Input from "Modules/DS/Input";
import { greyCloseIcon, search as searchIcon } from "assets/img";
import "./SearchDropdown.scss";
import ContextualMenu from "../ContextualMenu";
import { ContextualMenuItem, isContextualMenuItem, valueType } from "../ContextualMenu/types";
import classNames from "classnames";
interface SearchDropdownProps {
  className?: string;
  values: Array<valueType> | valueType;
  options: Array<ContextualMenuItem>;
  onChange: (string) => void;
  label?: string;
  placeholder?: string;
  multiple?: boolean;
  addMoreLabel?: string;
  onSearch?: (string) => void;
  defaultOpen?: boolean;
  renderOption?: (option: ContextualMenuItem, isSelected?: boolean) => React.ReactElement | string;
  onScrollBottom?: () => void;
  isLoading?: boolean;
  setShowDropdown?: (flag: boolean) => void;
  addMoreButton?: (setIsEdit: Function) => JSX.Element;
  discardSaveButtons?: JSX.Element;
  optionLabelOR?: JSX.Element;
  checkBoxStyle?: string;
}

const SearchDropdown: React.FC<SearchDropdownProps> = ({
  className,
  label,
  placeholder = "",
  multiple,
  addMoreLabel = "Add more",
  values,
  options = [],
  onChange = () => undefined,
  onSearch = () => undefined,
  defaultOpen = false,
  renderOption = (option: ContextualMenuItem) => option.label,
  onScrollBottom = () => undefined,
  isLoading = false,
  setShowDropdown = () => undefined,
  addMoreButton = (setIsEdit) => (
    <Button className="spm-search-dropdown__addmore" onClick={() => setIsEdit(true)}>
      {addMoreLabel}
    </Button>
  ),
  discardSaveButtons = <></>,
  optionLabelOR = (
    <div className="approver_or_container">
      <span className="approver_or">OR</span>
    </div>
  ),
  checkBoxStyle = "",
}: SearchDropdownProps) => {
  const [isEdit, setIsEdit] = useState<boolean>(!values);
  const [search, setSearch] = useState<string>("");

  const inputRef = useRef(null);

  const dropdownClassName = classNames("spm-search-dropdown", className);

  const handleValueClick = (val?: valueType) => {
    if (multiple && Array.isArray(values)) {
      if (values.length === 1) {
        setShowDropdown(false);
      }
      onChange(values.filter((item) => item !== val));
    } else {
      onChange(undefined);
      setIsEdit(true);
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    onSearch(search);
  }, [search]);

  useEffect(() => {
    focusInput();
  }, [inputRef.current, isEdit]);

  const focusInput = () => {
    inputRef?.current?.focus();
  };

  const displayValues = Array.isArray(values) ? (
    values?.map((item, idx) => {
      const error = (item as ContextualMenuItem).error;
      return (
        <div key={(item as ContextualMenuItem).value} className="spm-search-display-selected-values">
          {optionLabelOR}
          <div
            className={`spm-search-dropdown__value ${className}`}
            onClick={(e) => {
              e.stopPropagation();
              handleValueClick(item);
            }}
          >
            <span className="spm-search-dropdown__value__text">
              {renderOption(
                isContextualMenuItem(item) ? item : ({ label: item, value: item } as ContextualMenuItem),
                true
              )}
            </span>
            <img className="spm-search-dropdown__value__delete" src={greyCloseIcon} />
            {error && <div className="spm-search-dropdown__error">{error}</div>}
          </div>
        </div>
      );
    })
  ) : (
    <>
      <div
        className={"spm-search-dropdown__value"}
        onClick={(e) => {
          e.stopPropagation();
          handleValueClick(values);
        }}
      >
        <span className="spm-search-dropdown__value__text">
          {renderOption(
            isContextualMenuItem(values) ? values : ({ label: values, value: values } as ContextualMenuItem),
            true
          )}
        </span>
        <img className="spm-search-dropdown__value__delete" src={greyCloseIcon} />
        {values && (values as ContextualMenuItem).error && (
          <div className="spm-search-dropdown__error">{(values as ContextualMenuItem).error}</div>
        )}
      </div>
    </>
  );

  const isValueDefined = isContextualMenuItem(values) ? values.value : values;
  const isMultipleValuesDefined = Array.isArray(values) && values?.length;
  const isMultipleValuesEmpty = Array.isArray(values) && !values?.length;

  const shouldDisplayValues = isValueDefined || (isMultipleValuesDefined && (!isEdit || multiple));
  const shouldDisplayAddMoreButton = isMultipleValuesDefined && multiple;
  const shouldDisplayDropdown = isEdit || !values || isMultipleValuesEmpty;

  return (
    <div className={dropdownClassName}>
      {shouldDisplayValues ? displayValues : null}
      {shouldDisplayAddMoreButton ? addMoreButton(setIsEdit) : null}
      {shouldDisplayAddMoreButton && !isEdit ? discardSaveButtons : null}
      {shouldDisplayDropdown ? (
        <ContextualMenu
          defaultOpen={defaultOpen}
          values={values}
          onChange={(option) => {
            setSearch("");
            setIsEdit(false);
            onChange(option);
          }}
          options={options}
          multiple={multiple}
          renderOption={renderOption}
          onScrollBottom={onScrollBottom}
          isLoading={isLoading}
          checkBoxStyle={checkBoxStyle}
          setShowDropdown={setShowDropdown}
        >
          <Input
            ref={inputRef}
            label={label}
            className="spm-search-dropdown__input"
            placeholder={placeholder}
            value={search}
            onChange={setSearch}
            prefix={<img src={searchIcon} />}
          />
        </ContextualMenu>
      ) : null}
    </div>
  );
};

export default SearchDropdown;
