import React, { useContext } from "react";
import { AuthPageContext } from "Views/Auth";
import styles from "./index.module.scss";
import Icon, { IconList } from "Modules/Icon";

export const AuthBoxError = (): React.ReactElement => {
  const { error, errorMessage } = useContext(AuthPageContext);

  const COMMON_SVG_ATTR = { width: 24, height: 16, viewBox: "0 0 24 16" };

  return error && errorMessage ? (
    <div className={styles.error}>
      <Icon className={styles.tabIcon} svgAttr={COMMON_SVG_ATTR} icon={IconList.fillWarning} />
      {errorMessage}
    </div>
  ) : (
    <></>
  );
};
