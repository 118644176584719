import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";

//Modules
import Modal from "Modules/modal";
import Button from "Modules/button";

//Constant
import { LOGIN_PATH, LOGOUT_PATH } from "constants/Routes.constants";

//utility
import useLogout from "utility/useLogout";
import { isUserLoggedIn } from "utility/LoginHelper";
import { cookieNames, getCookieValue, cookies } from "utility/CookieHelper";

import { useRedirectPath } from "customHooks/Route/useRedirection";

//style
import "./styles.scss";

const SessionTimeoutModal = () => {
  const logout = useLogout();
  const redirectPath = useRedirectPath();
  const sessionExpired = getCookieValue(cookieNames.EXPIRED) === "true" || false;
  const [showTimeoutModal, setShowTimeoutModal] = useState(false);

  useEffect(() => {
    function onCookieChange(change: { name: string; value: boolean | string }) {
      const isExpired = true;
      if (change && change.name === cookieNames.EXPIRED && change.value === isExpired) {
        setShowTimeoutModal(true);
      }
    }

    cookies.addChangeListener(onCookieChange);

    return () => {
      cookies.removeChangeListener(onCookieChange);
    };
  }, []);

  const sessionTimeoutMessage = {
    inactiveSession: {
      message: "You have been logged out due to inactivity. Please log in to continue.",
      button: "Close",
    },
  };
  const { message, button } = sessionTimeoutMessage["inactiveSession"];

  useEffect(() => {
    setShowTimeoutModal(sessionExpired);
  });

  const handleTimeoutSession = () => {
    logout(LOGIN_PATH + redirectPath);
    setShowTimeoutModal(false);
  };

  // If user is not login and session inactive modal is not visible, logout user
  if (!isUserLoggedIn() && !showTimeoutModal) {
    return <Redirect to={LOGOUT_PATH + redirectPath} />;
  }

  const modalProps = {
    show: showTimeoutModal,
    title: "Session Expired",
    close: () => false,
    className: "session-timeout-modal",
  };

  const buttonProps = {
    rounded: true,
    text: button,
    className: "session-timeout-modal__button",
    action: handleTimeoutSession,
  };

  const errorText: string = message;

  return (
    <Modal {...modalProps}>
      <p>{errorText}</p>
      <Button {...buttonProps} />
    </Modal>
  );
};

export default SessionTimeoutModal;
