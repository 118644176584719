import React, { useEffect } from "react";
import CardActivationForm from "./CardActivationForm/CardActivationForm";
import UpdateProfileError from "./StatusView/Error/UpdateProfileError";
import UpdateProfileSuccess from "./StatusView/Success/UpdateProfileSuccess";
import LoadingModal from "Modules/loadingModal";
import useCheckFeatureStatus from "customHooks/featureCheck";
import { SPLIT_NAMES } from "Redux/splitio/constants";

import { Redirect } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { getPhoneCodes, updateTeam, UserInfoFunc } from "Redux/Actions";

import "./StatusView/UpdateProfileStatus.scss";
import "./UpdateCardActivation.scss";

const UpdateCardActivation = () => {
  const dispatch = useDispatch();
  const { phoneCodes, updateProfileDetails, userInfo } = useSelector((state) => ({
    phoneCodes: state.phoneCodeReducer?.data?.payload,
    updateProfileDetails: state.updateProfileReducer,
    userInfo: state.userInfoReducer,
  }));
  const { loading, data, error } = updateProfileDetails;
  const isSuccess = data?.payload?.is_success;
  const isWalletUser = userInfo?.data?.payload?.user?.is_wallet_user;
  const isTreatmentActive = useCheckFeatureStatus(SPLIT_NAMES.admin_wallet_activation) === "off";

  useEffect(() => {
    dispatch(getPhoneCodes());
  }, [dispatch]);

  useEffect(() => {
    dispatch(updateTeam());
    dispatch(UserInfoFunc());
  }, [dispatch, isSuccess]);

  if ((isWalletUser && !isSuccess) || isTreatmentActive) {
    return <Redirect to="/" />;
  }

  return (
    <div className={"update-card-activation"}>
      {!error && !isSuccess && (
        <div className={"update-card-activation--wrapper"}>
          <h1 className={"title"}>Profile Update - Cards Activation</h1>
          <p className={"sub-title"}>Update your profile to activate the Cards feature</p>
          <CardActivationForm phoneCodes={phoneCodes} />
        </div>
      )}
      {loading && (
        <LoadingModal
          title={"Validating your profile information"}
          message={"Please wait while we validate your profile information"}
        />
      )}
      {error && <UpdateProfileError />}
      {isSuccess && <UpdateProfileSuccess />}
    </div>
  );
};

export default UpdateCardActivation;
