import React, { useContext, useState } from "react";
import classNames from "classnames";
import { useHistory } from "react-router-dom";

import { COMMON_PATHS } from "Route/Common/paths";

import Icon from "Modules/icons";
import { KYX_STATES } from "Views/KYX/Constants";
import { IKYXToastContext, KYXStatesProps } from "Views/KYX/@types";
import { KYXStatesDetails } from "Views/KYX/json/KYXStates";

import styles from "./index.module.scss";
import { KYXPrimaryButton } from "../Intro/Start/Button";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import { KYXToastContext, KYXErrorToaster } from "../Context";
import { markKYXCompleted } from "../DataCalls";

const KYXStates: React.FC<KYXStatesProps> = ({ type = KYX_STATES.APPROVED }: KYXStatesProps): React.ReactElement => {
  const history = useHistory();
  const getVerificationMessage = (messageDetails: Array<any>): JSX.Element[] => {
    return messageDetails.map((message, index) => (
      <p key={index} className={styles.message}>
        {message}
      </p>
    ));
  };
  const [loading, setLoading] = useState(false);
  const { setToaster } = useContext<IKYXToastContext>(KYXToastContext);
  const HOME_PAGE_URL = "/";

  const submitFormData = async (path) => {
    try {
      setLoading(true);
      const response = await markKYXCompleted();
      const errorMessage = response.data?.error?.message || response.data?.meta?.message;
      if (response?.data?.status === HTTP_STATUS_CODE.OK) {
        history.push(path);
      } else {
        throw new Error(errorMessage);
      }
    } catch (error) {
      const toast = KYXErrorToaster(error?.response?.data?.error?.message || error);
      setToaster(toast);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const handleGotoDashboard = () => {
    return type === KYX_STATES.APPROVED ? submitFormData(HOME_PAGE_URL) : history.push(HOME_PAGE_URL);
  };

  const getActionButtons = (hideTopUp = true): JSX.Element => {
    return (
      <div className={styles.actionBtns}>
        {hideTopUp && (
          <KYXPrimaryButton
            className={styles.textBtn}
            action={() => submitFormData(COMMON_PATHS.TOP_UP_PATH)}
            loading={loading}
          >
            Top Up Now
          </KYXPrimaryButton>
        )}
        <KYXPrimaryButton action={handleGotoDashboard} loading={loading}>
          Go to Dashboard
        </KYXPrimaryButton>
      </div>
    );
  };

  const { icon, title, showButtons, showInfo, message, direction, showGoToDashboard } = KYXStatesDetails()[type];

  const _classNames = classNames(styles.container, styles[type], styles[direction], {
    [styles.InReview]: type === KYX_STATES.IN_REVIEW,
  });

  return (
    <>
      <div className={_classNames}>
        <Icon className={styles.icon} src={icon} alt={type} />
        <div className={styles.content}>
          <h2 className={styles.title}>{title || `Company Verification ${type}`}</h2>
          {getVerificationMessage(message)}
        </div>

        {showButtons && getActionButtons()}

        {showInfo && (
          <p className={styles.info}>
            For further inquiries, please contact our Customer Support team at{" "}
            <a href="mailto:support@spenmo.com">support@spenmo.com</a>
          </p>
        )}

        {showGoToDashboard && getActionButtons(false)}
      </div>
    </>
  );
};

export default KYXStates;
