export interface ContextualMenuItem {
  label: string;
  value: string;
  error?: string;
  [key: string]: any;
}

export type valueType = string | number | ContextualMenuItem;

export const isContextualMenuItem = (item: any): item is ContextualMenuItem => {
  return item && typeof item === "object" && "label" in item && "value" in item;
};
