import * as React from "react";
import { Modal, Alert, Button } from "antd";
import { useEffect, useState } from "react";

interface DeleteFlowModalProps {
  show: boolean;
  title: string;
  onCancel: () => void;
  onDelete: () => void;
}

const DeleteFlowModal: React.FC<DeleteFlowModalProps> = ({ show, title, onCancel, onDelete }: DeleteFlowModalProps) => {
  const [flowNameInput, setFlowNameInput] = useState<string>("");
  const [flowNameInputError, setFlowNameInputError] = useState<boolean>(false);

  useEffect(() => {
    if (flowNameInput.length > 0) {
      flowNameInput === title ? setFlowNameInputError(false) : setFlowNameInputError(true);
    } else {
      setFlowNameInputError(false);
    }
  }, [flowNameInput]);

  return (
    <Modal
      footer={null}
      visible={show}
      className={"approval-flow-modal no-animation"}
      title="Delete this flow?"
      onCancel={() => {
        onCancel();
        setFlowNameInput("");
        setFlowNameInputError(false);
      }}
    >
      <div className="approval-flow-modal__content">
        <div data-show="true">
          <Alert message="" description="This action cannot be undone." type="warning" showIcon />

          <div className="approval-flow-modal__text mt-15">
            <p>
              This will permanently delete <span className="approval-flow-modal__text__title">{title}</span> Approval
              Flow.
            </p>
            <p className="mt-15">What happens after deleting this Flow?</p>
            <ul className="list-style-1">
              <li>Ongoing requests will continue using this Flow.</li>
              <li>Future requests eligible for this Flow will use the Default Workflow.</li>
            </ul>
            <hr className="separator-one" />
            <p>
              To proceed deleting this flow, please type <strong>{title}</strong> in the field below.
            </p>
            <p className="approval-flow-modal__content__flow-label mt-15">Flow Name</p>
          </div>
          <div className="approval-flow-modal__action-area">
            <input
              placeholder={`Type “${title}” here...`}
              name="flowName"
              value={flowNameInput}
              onChange={(e) => setFlowNameInput(e.target.value)}
            />
            {flowNameInputError && (
              <div className="approval-flow-modal__error">
                Flow Name is typed incorrectly, Please check the spelling and letter case.
              </div>
            )}
            <div className="align-right mt-15">
              <Button
                disabled={!flowNameInput || flowNameInputError}
                className={`approval-flow-modal__action-button--delete`}
                onClick={onDelete}
              >
                Delete Flow
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteFlowModal;
