import React from "react";
import PropTypes from "prop-types";

import Item from "Views/TransactionsListing/Pending/Transaction/Detail/Item";
import { BANK_IDENTIFIER, ENTITY_TYPE } from "Views/UploadInvoice/const";
import { renderBankIdentifierLabel } from "utility/Bills";

const Beneficiary = ({ data }) => {
  return (
    <>
      {Boolean(data?.legalName) && <Item title="Recipient">{data?.legalName || "-"}</Item>}
      <Item title="Recipient name">{data?.name || "-"}</Item>
      <Item title="Bank account">{data?.bankAccountNumber || "-"}</Item>
      <Item title="Bank name">{data?.bankName || "-"}</Item>
      <Item title="Bank country">{data?.bankCountryName || "-"}</Item>
      {data?.identifierType && data?.identifierValue && (
        <Item title={renderBankIdentifierLabel(data.identifierType)}>{data.identifierValue.toUpperCase()}</Item>
      )}
      {data?.identifierValue && (
        <Item title="Preferred Bank Identifier Type">
          {BANK_IDENTIFIER[data?.additionalIdentifierType?.toUpperCase()]?.value || "-"}
        </Item>
      )}
      {data?.additionalIdentifierType && data?.additionalIdentifierValue && (
        <Item title={renderBankIdentifierLabel(data?.additionalIdentifierType)}>
          {data.additionalIdentifierValue.toUpperCase()}
        </Item>
      )}
      <Item title="Recipient email">{data?.email || "-"}</Item>
      {data?.type && (
        <Item title="Recipient Bank Account Type">
          {ENTITY_TYPE.find((type) => type.value === data.type)?.label || "-"}
        </Item>
      )}
    </>
  );
};

Beneficiary.propTypes = { data: PropTypes.object };

export default Beneficiary;
