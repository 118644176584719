import * as React from "react";
import { CardDetailsType, CARD_DRAWER_TYPES, CARD_LIST_TABS, CARD_DETAILS_TABS } from "Views/Card/List/types";
import { useCardListContext } from "Views/Card/List/index.context";
import { getCardDetailsData } from "Views/Card/List/Utilities";
import CardDetailsModal from "Views/Card/Details";
import CardDetailsEditModal from "Views/Card/Edit";
import SetOrChangeCardPinDrawer from "Views/Card/Pin";
import { CARD_STATUS } from "Views/Card/types";
import { trackEvent } from "utility/analytics";
import { cardDetailsEvent } from "Views/Card/Analytics";

export interface ICardRequestDetailsProps {
  cardDetails: CardDetailsType;
  setCardDetails: React.Dispatch<React.SetStateAction<CardDetailsType>>;
  cardDetailsInitialTab: CARD_DETAILS_TABS;
}

export default function CardRequestDetails({
  cardDetails,
  setCardDetails,
  cardDetailsInitialTab,
}: ICardRequestDetailsProps) {
  const { getCardListData, currentPage } = useCardListContext();
  const [ShowSetOrChangePinDrawer, setShowSetOrChangePinDrawer] = React.useState(false);
  const fetchCardList = (page: number, listType: CARD_LIST_TABS) => {
    getCardListData(page, listType);
    setCardDetails(null);
  };
  return (
    <div>
      {/* Card Details Drawer */}
      {cardDetails?.drawerType === CARD_DRAWER_TYPES.DETAILS && cardDetails?.data && (
        <CardDetailsModal
          cardData={cardDetails.data}
          onClose={() => {
            setCardDetails(null);
            trackEvent(cardDetailsEvent.CARD_DETAILS_CLOSE_BUTTON_CLICKED);
          }}
          onEdit={() => {
            setCardDetails((prevState) => ({
              ...prevState,
              drawerType: CARD_DRAWER_TYPES.EDIT,
            }));
          }}
          onCardRequestDelete={() => fetchCardList(0, CARD_LIST_TABS.SENT_CARD_REQUEST)}
          onCardDelete={() => fetchCardList(0, CARD_LIST_TABS.CARD_LIST)}
          onPhysicalCardSync={() => fetchCardList(currentPage, CARD_LIST_TABS.CARD_LIST)}
          onCardStatusToggle={(status: boolean) => {
            getCardListData(currentPage, CARD_LIST_TABS.CARD_LIST);
            setCardDetails((prevState) => ({
              ...prevState,
              drawerType: CARD_DRAWER_TYPES.DETAILS,
              data: {
                ...prevState?.data,
                cardStatus: status ? CARD_STATUS.ENABLED : CARD_STATUS.DISABLED,
              },
            }));
          }}
          cardDetailsInitialTab={cardDetailsInitialTab}
        />
      )}
      {/* Card Details Edit Drawer */}
      {cardDetails?.drawerType === CARD_DRAWER_TYPES.EDIT && cardDetails?.data && (
        <CardDetailsEditModal
          cardData={cardDetails.data}
          onClose={() => setCardDetails(null)}
          onSuccessfulEdit={(updatedData) => {
            getCardListData(
              currentPage,
              cardDetails?.data?.isCardRequestDetails ? CARD_LIST_TABS.SENT_CARD_REQUEST : CARD_LIST_TABS.CARD_LIST
            );
            setCardDetails((prevState) => ({
              ...prevState,
              drawerType: CARD_DRAWER_TYPES.DETAILS,
              data: getCardDetailsData(updatedData, prevState?.data?.isCardRequestDetails),
            }));
          }}
          onBackArrowClick={() => {
            setCardDetails((prevState) => ({
              ...prevState,
              drawerType: CARD_DRAWER_TYPES.DETAILS,
            }));
          }}
          onSetOrChangePin={() => {
            setShowSetOrChangePinDrawer(true);
          }}
        />
      )}
      {/* Change Pin Drawer */}
      {ShowSetOrChangePinDrawer && (
        <SetOrChangeCardPinDrawer
          cardDetails={cardDetails?.data}
          onClose={() => {
            setShowSetOrChangePinDrawer(false);
          }}
          onUpdate={() => {
            setCardDetails((prevState) => ({
              ...prevState,
              drawerType: CARD_DRAWER_TYPES.EDIT,
              data: {
                ...prevState?.data,
                isCardPinSet: true,
              },
            }));
            setShowSetOrChangePinDrawer(false);
            getCardListData(currentPage, CARD_LIST_TABS.CARD_LIST);
          }}
        />
      )}
    </div>
  );
}
