import React from "react";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import qs from "query-string";
import { ArrowLeft, Typography } from "@spenmo/splice";
import { useSubscriptionContext } from "Views/SubscriptionManagement/Details/SubscriptionContext";
import PaymentList from "Views/SubscriptionManagement/Details/Summary/Payment/List";

import styles from "./index.module.scss";

export const Payment = () => {
  const { subscription } = useSubscriptionContext();

  const tnxRedirectionLink = qs.stringifyUrl({
    url: "/transactions",
    query: {
      tab: "all",
      startDate: dayjs().subtract(1, "year").toISOString(),
      endDate: dayjs().toISOString(),
      recipientNames: subscription?.vendor?.name,
      subscription: subscription?.id,
    },
  });

  return (
    <div className={styles.payments}>
      <div className={styles.info}>
        <Typography className={styles.infoText} variant="body-content" size="caption-m">
          Found{" "}
          <Typography size="caption-m" variant="body-content" weight={600}>
            some transactions
          </Typography>{" "}
          that might be related to this subscription
        </Typography>
        <Link to={tnxRedirectionLink} className={styles.linkBtn}>
          <Typography variant="body-content" size="caption-m" weight={600}>
            View
          </Typography>
          <ArrowLeft size="16" iconColor="var(--text-link-default)" />
        </Link>
      </div>

      <PaymentList />
    </div>
  );
};
