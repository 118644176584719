import React from "react";
import classNames from "classnames";

import { ILabelChipProps } from "Modules/DS/Chip";

import styles from "./Label.module.scss";

export const LabelChip = ({ title, className, status, onRemove, icon }: ILabelChipProps) => {
  const handleRemoveClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    onRemove?.(e);
  };

  return (
    <div
      className={classNames(styles.container, styles[status], { [className]: className })}
      onClick={(e) => e.stopPropagation()}
    >
      {icon && <img src={icon} alt="icon" className={styles.icon} />}
      <span className="spm-label-chip">{title}</span>
      {onRemove && <button className={styles.remove} onClick={handleRemoveClick} aria-label="Remove" />}
    </div>
  );
};
