import React, { useState, useEffect, useRef } from "react";
import { useAppSelector, useAppDispatch } from "hook";
import { Input, Checkbox, Form } from "antd";

import useCheckFeatureStatus from "customHooks/featureCheck";
import { SPLIT_NAMES, SPLIT_TREATMENT_TYPES } from "Redux/splitio/constants";

import { fetchDataSuccess } from "Redux/Actions/csvAction";
import { downloadSmartCSVTemplate } from "Redux/DataCalls/SmartCSV.api";
import { fetchSmartCSVTemplates } from "Redux/Actions/SmartCSV/templateAction";

import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import { SMART_CSV_LIMIT_TRANSACTION, SMART_CSV_EXCLUDE_ACCOUNT_CODE } from "constants/Transaction.constant";

import { GetOrgId } from "utility";
import useLoading from "utility/useLoading";
import { trackEvent } from "utility/analytics";

import IButton from "Modules/button";
import { ModalComponent } from "Modules/DS/Modal";
import { TITLE_TYPE } from "Modules/DS/Modal/types";
import { TOASTER_STATUS_TYPE } from "Modules/DS/Toaster";

import LoaderIcon from "Views/State/Loading/LoaderIcon";
import { ICustomCSVModal } from "Views/Transaction/@types";
import { AccountingSoftware, InputAccountCode } from "Views/Transaction/SmartCSV/v2";
import {
  customCSVThisMonthDateRange,
  thisMonthDateRange,
  dateFormatter,
  END_DATE,
  messageGenerator,
} from "Views/Transaction/SmartCSV/v2/utilities";

import "./CustomCSV2.scss";

export const CustomCSVModal = <T extends object = any>({
  filters,
  count,
  resetModal,
  setShowToaster,
  accountingStatus,
}: ICustomCSVModal<T>) => {
  const [showTemplate, setShowTemplate] = useState(0);
  const [selectedTemplate, setSelectedTemplate] = useState<any>({});

  const [sentEmail, setSentEmail] = useState(false);
  const [mustSendEmail, setMustSendEmail] = useState(false);
  const isOpen = (id) => showTemplate === id;
  const dispatch = useAppDispatch();
  const downloadLinkRef = useRef<any>();
  const [form] = Form.useForm();
  const useReportingServiceFilter =
    useCheckFeatureStatus(SPLIT_NAMES.customCSVWithReportingFilter) === SPLIT_TREATMENT_TYPES.ON;
  const categoriesData = useAppSelector((state) => state.userInfoReducer?.data?.payload?.categories || []);

  const { userData, smartCSV, accountingPartnerAuth } = useAppSelector((state: any) => ({
    smartCSV: state.smartCSVTemplateReducer,
    userData: state.userInfoReducer?.data,
    accountingPartnerAuth: state.accountingPartnerAuthReducer?.data,
  }));

  const isConnected: boolean = accountingPartnerAuth?.payload?.has_valid_token || false;
  const email: string = userData?.payload?.user?.email || "";

  const { loading, data } = smartCSV;
  const [downloadCSV, loadingDownloadCSV] = useLoading(downloadSmartCSVTemplate, (response) => {
    return response?.data?.error?.message || "Something Went Wrong";
  });
  const noFilters = Object.values(filters).filter(Boolean).length === 0;

  const generator = messageGenerator({
    selectedTemplate,
    noFilters,
    isConnected,
    mustSendEmail,
    sentEmail,
  });

  useEffect(() => {
    dispatch(fetchSmartCSVTemplates());
  }, [dispatch]);

  useEffect(() => {
    if (count) {
      const reachTreshold = count > SMART_CSV_LIMIT_TRANSACTION;
      setMustSendEmail(reachTreshold);
      setSentEmail(reachTreshold);
    }
  }, [count]);

  const notAllowToDownload = () => !selectedTemplate.name || loadingDownloadCSV || count === 0;

  const cleanUpFilter = (filters) => {
    if (filters.types) {
      // On monolith we need to use `type` instead of `types`
      // But on reporting service we need `types` instead of `type`
      filters.type = filters.types;
      delete filters.types;
    }

    if (filters.requester_ids) {
      filters.user_id = filters.requester_ids;
      delete filters.requester_ids;
    }

    if (filters.team_ids) {
      filters.team_id = filters.team_ids;
      delete filters.team_ids;
    }

    if (filters.attachment) {
      filters.has_attachment = filters.attachment;
      delete filters.attachment;
    }

    if (filters.start_date) {
      filters.start_date = dateFormatter(filters.start_date);
    }
    if (filters.end_date) {
      filters.end_date = dateFormatter(filters.end_date || END_DATE);
    }

    if (filters.categories) {
      const originalCategories = filters.categories;
      if (Array.isArray(originalCategories)) {
        const categoriesId = originalCategories.map((categoryName) =>
          categoriesData.find((category) => category.category_name === categoryName).id.toString()
        );
        filters["category_id"] = categoriesId;
        delete filters.categories;
      }
    }

    if (filters.exclude_types) {
      delete filters.exclude_types;
    }
    return filters;
  };

  const onFinish = async (value) => {
    if (notAllowToDownload()) return;

    const reportingServiceFilters = { ...thisMonthDateRange(), ...filters };
    const searchFilters = { ...customCSVThisMonthDateRange(), ...cleanUpFilter(filters) };

    const payload = {
      search_filters: searchFilters,
      template_id: selectedTemplate.id,
      organisation_id: GetOrgId(),
      organisation_name: userData?.payload?.user?.organisation_name,
      is_send_email: sentEmail,
      account_number: form.getFieldValue("accountCode"),
      email: value.email, // Check this one?
    };

    if (useReportingServiceFilter) {
      delete payload.search_filters;
      payload["reporting_filters"] = reportingServiceFilters;
    }

    // This flag will be used later when we integrate this component with the accounting workflow page
    if (accountingStatus) {
      payload["accounting_filters"] = {
        status: accountingStatus,
      };
    }
    const response = await downloadCSV(payload);

    localStorage.setItem("accountCode", form.getFieldValue("accountCode") || "");

    if (response?.data?.status === HTTP_STATUS_CODE.OK) {
      const { data: downloadData } = response;
      if (downloadData.payload.download_url) {
        downloadLinkRef.current.href = downloadData.payload.download_url;
        downloadLinkRef.current.click();
      }

      dispatch(
        fetchDataSuccess({
          ...downloadData.payload,
          status_message: downloadData.payload.message,
        })
      );

      handleClose(downloadData.payload.message);
      trackEvent(`Click on Download ${selectedTemplate.accountingSoftware} - ${selectedTemplate.name}`, { payload });
    }
  };

  const handleClose = (message) => {
    setShowToaster({
      show: true,
      message: message,
      type: TOASTER_STATUS_TYPE.SUCCESS,
    });
    resetModal();
    setShowTemplate(0);
    setSelectedTemplate({});
    if (!mustSendEmail) {
      setSentEmail(false);
      setMustSendEmail(false);
    }
  };

  const showAccountCodeInput = () => {
    return (
      selectedTemplate.name &&
      !SMART_CSV_EXCLUDE_ACCOUNT_CODE.includes(selectedTemplate.name) &&
      selectedTemplate.accountingSoftware === "Jurnal"
    );
  };

  const arrangeOrder = (list = []) => {
    if (isConnected) {
      const xero = list.find((accountingSoftware) => accountingSoftware.name === "Xero");
      const withoutXero = list.filter((accountingSoftware) => accountingSoftware.name !== "Xero");
      return [xero, ...withoutXero].filter(Boolean);
    }

    return list;
  };

  const renderSoftwareList = () => {
    return loading ? (
      <LoaderIcon />
    ) : (
      <div className="accounting-software__list">
        {Array.isArray(data.payload) &&
          arrangeOrder(data.payload).map((template) => (
            <AccountingSoftware
              key={template.id}
              template={template}
              isOpen={isOpen}
              showTemplate={showTemplate}
              setShowTemplate={setShowTemplate}
              isConnected={template.name === "Xero" && isConnected}
              onChangeTemplate={(value) => setSelectedTemplate({ ...value, accountingSoftware: template.name })}
            />
          ))}
      </div>
    );
  };

  const renderButton = () => {
    return (
      <div className="download-button-wrapper">
        <a ref={downloadLinkRef} className="hide"></a>
        <IButton
          rounded
          disabled={notAllowToDownload()}
          loading={loadingDownloadCSV}
          className={selectedTemplate.name && !notAllowToDownload() ? "button--download" : ""}
          id="downloadSmartCSV"
          type="submit"
          text={generator.generateButtonText()}
        />
      </div>
    );
  };

  const renderEmailExportContent = () => {
    return (
      <section>
        <h4 className="header sp-margin-bottom--s">Send export details and download link to email</h4>

        <p className="sub_header sp-margin-bottom--s">
          {mustSendEmail
            ? `The data which contains more than ${SMART_CSV_LIMIT_TRANSACTION} transactions will be sent to email.`
            : `Download again this exported data by the link on the email.`}
        </p>
        <Form.Item
          name="email"
          initialValue={email}
          rules={
            sentEmail
              ? [
                  { type: "email", message: "Please enter correct email address" },
                  { required: sentEmail, message: "Please enter an email address" },
                ]
              : []
          }
        >
          <Input disabled={!sentEmail} maxLength={100} />
        </Form.Item>
      </section>
    );
  };

  const renderEmailExportSection = () => {
    return (
      <>
        <div className="flex">
          <Checkbox
            className={"sp-checkbox"}
            name="checkbox-test"
            disabled={mustSendEmail}
            checked={sentEmail}
            onChange={(e) => {
              setSentEmail(e.target.checked);
              if (!e.target.checked) {
                form.resetFields();
                form.setFieldsValue({ email });
              }
            }}
          />
          {renderEmailExportContent()}
        </div>
        {renderButton()}
      </>
    );
  };

  const renderExportGuideLink = () => {
    return (
      selectedTemplate.guide_url && (
        <a className="guidance" href={selectedTemplate.guide_url} target="__blank">
          <span>
            Learn more about importing CSV inside
            <span className="capitalize"> {selectedTemplate.accountingSoftware}</span>
          </span>
        </a>
      )
    );
  };

  const renderExportGuideSection = () => {
    return (
      <>
        <h4 className="header">{generator.generateTextHeader()}</h4>
        <p className="sub_header min-height">{generator.generateSubHeader()}</p>
        {renderExportGuideLink()}
        {showAccountCodeInput() && <InputAccountCode accountingSoftware={selectedTemplate.accountingSoftware} />}
      </>
    );
  };

  const renderExportGuide = () => {
    return (
      <div className="export-guide">
        <div className="export-guide__content">
          {renderExportGuideSection()}
          <div className="divider"></div>
          {renderEmailExportSection()}
        </div>
      </div>
    );
  };

  return (
    <>
      <ModalComponent.CloseButton />
      <ModalComponent.Title titleType={TITLE_TYPE.largeWithBorder}>Custom CSV</ModalComponent.Title>
      <Form form={form} onFinish={onFinish}>
        <div className="modal-export-csv__content">
          <div className="accounting-software">
            <h4 className="header">Export data to CSV template from</h4>
            {renderSoftwareList()}
          </div>
          {renderExportGuide()}
        </div>
      </Form>
    </>
  );
};
