import { UPDATE_USER_ROLE_MEMBERSHIP } from "Redux/Actions/teamAction";
import { GetUserId } from "utility";
import { FETCH_USER_REQUEST, FETCH_USER_SUCCESS, FETCH_USER_FAILURE } from "../Actions/userAction";
import { userInitialState } from "../InitialState";

export const userInfoReducer = (
  state = {
    ...userInitialState,
    isAdmin: false,
    userId: GetUserId(),
    role: "",
    membership: "",
    company_role: "",
  },
  action
) => {
  switch (action.type) {
    case FETCH_USER_REQUEST:
      return {
        ...state,
        data: action.data,
        loading: true,
        error: false,
      };
    case FETCH_USER_SUCCESS:
      return {
        ...state,
        data: action.data,
        isAdmin: action.data?.payload?.user?.is_admin,
        manageTeams: action.data?.payload?.user?.manage_teams,
        userId: action.data?.payload?.user?.id,
        loading: false,
        error: false,
      };
    case FETCH_USER_FAILURE:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: true,
      };
    case UPDATE_USER_ROLE_MEMBERSHIP:
      return {
        ...state,
        role: action.role,
        membership: action.membership,
        company_role: action.company_role,
      };
    default:
      return state;
  }
};
