import * as React from "react";
import { Modal } from "antd";
import { modalCloseIcon } from "assets/img/";
import Stepbar from "Views/Card/Components/Stepbar";
import { ColorAndApprover } from "Views/Card/CreateOrRequest/Steps";
import "Views/Card/CreateOrRequest/Modal/index.scss";

interface StepConfig {
  Component: JSX.Element;
  stepNumber: number;
}
interface CreateOrRequestModalProps {
  stepsConfig: StepConfig[];
  currentStep: number;
  cardTitle: string;
  onClose: () => void;
}

const CardRequest = ({ stepsConfig, currentStep, cardTitle, onClose }: CreateOrRequestModalProps) => {
  return (
    <Modal
      visible
      title={cardTitle}
      footer={null}
      maskClosable={false}
      className="card-create-or-request-modal"
      width={920}
      onCancel={onClose}
      closeIcon={<img src={modalCloseIcon} alt="close icon" />}
    >
      <div className="card-create-or-request-modal__form-container">
        <div
          className={`card-create-or-request-modal__step-bar card-create-or-request-modal__step-bar--${stepsConfig.length}-step`}
        >
          <Stepbar currentStep={currentStep} numberOfSteps={stepsConfig.length} />
        </div>
        <div className="card-create-or-request-modal__body">{stepsConfig[currentStep].Component}</div>
      </div>
      <ColorAndApprover />
    </Modal>
  );
};

export default CardRequest;
