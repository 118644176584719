// import { Cookies } from "react-cookie";
import { axiosHeader } from "../Redux/Actions";
// const cookies = new Cookies();
const ResolveHeaders = (data) => {
  let headers = {};
  for (let header of data) {
    headers[header[0].toString().toLowerCase()] = header[1];
  }
  return headers;
};

// function buildFormData(formData, data, parentKey) {
//     if (data && typeof data === "object" && !(data instanceof Date) && !(data instanceof File)) {
//         Object.keys(data).forEach((key) => {
//             buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
//         });
//     } else {
//         const value = data == null ? "" : data;

//         formData.append(parentKey, value);
//     }
// }

// function jsonToFormData(data) {
//     const formData = new FormData();

//     buildFormData(formData, data);

//     return formData;
// }

export const GetFormData = (data = {}) => {
  let formData = new FormData();
  Object.entries(data).forEach((x) => {
    formData.append(x[0], typeof x[1] === "object" ? JSON.stringify(x[1]) : x[1]);
  });
  return formData;
};

export const GetFormDataWithArray = (data = {}) => {
  let formData = new FormData();
  Object.entries(data).forEach((x) => {
    if (Array.isArray(x[1])) {
      x[1].forEach((value) => formData.append(`${x[0]}[]`, value));
    } else {
      formData.append(x[0], typeof x[1] === "object" ? JSON.stringify(x[1]) : x[1]);
    }
  });
  return formData;
};

export const parseToURLEncodedForm = (data) => {
  const params = new URLSearchParams();

  Object.keys(data).forEach((key) => {
    if (typeof data[key] === "object") {
      params.append(key, JSON.stringify(data[key]));
    } else {
      params.append(key, data[key]);
    }
  });

  return params;
};

export const urlParamsBuilder = (url, params) => {
  const paramsReducer = (acc, curval) => {
    let prefix = acc === "" ? "?" : "&";
    if (Array.isArray(params[curval])) {
      let arrayParams = "";
      params[curval].forEach((item) => {
        let arrayPrefix = arrayParams === "" ? "" : "&";
        arrayParams += `${arrayPrefix}${curval}=${item}`;
      });
      return `${acc}${prefix}${arrayParams}`;
    }
    return `${acc}${prefix}${curval}=${params[curval]}`;
  };
  return url + Object.keys(params).reduce(paramsReducer, "");
};

const EmptyDataResolver = async (response) => {
  let data = null;
  try {
    data = await response.json();
    return Promise.resolve(data);
  } catch (err) {
    return Promise.resolve(data);
  }
};

const ErrorResolver = async (response) => {
  try {
    const descriptiveError = {
      description: await response.text(),
      message: `${response.statusText} - ${response.status}`,
      status: response.status,
    };
    return Promise.reject(descriptiveError);
  } catch (err) {
    return Promise.reject(err);
  }
};

const GetHeaders = async (includeContentType = true, multipart = false) =>
  Object.assign(
    {},
    includeContentType ? { Accept: "application/json", "Content-Type": "application/json" } : {},
    axiosHeader.headers
  );

class APIWrapper {
  async Get(url) {
    return fetch(url, {
      method: "GET",
      headers: await GetHeaders(),
    })
      .then(async (response) =>
        response.status >= 200 && response.status < 300
          ? Promise.resolve(await EmptyDataResolver(response), response)
          : ErrorResolver(response)
      )
      .catch((err) => Promise.reject(err));
  }

  async Put(url, data) {
    return fetch(url, {
      method: "PUT",
      headers: await GetHeaders(),
      body: JSON.stringify(data),
    })
      .then(async (response) =>
        response.status >= 200 && response.status < 300
          ? Promise.resolve(await EmptyDataResolver(response), response)
          : ErrorResolver(response)
      )
      .catch((err) => Promise.reject(err));
  }

  async Post(url, data) {
    return fetch(url, {
      method: "POST",
      headers: await GetHeaders(),
      body: JSON.stringify(data),
    })
      .then(async (response) =>
        response.status >= 200 && response.status < 300
          ? Promise.resolve(await EmptyDataResolver(response), response)
          : ErrorResolver(response)
      )
      .catch((err) => Promise.reject(err));
  }

  async PostWithFormData(url, data = {}) {
    return fetch(url, {
      method: "POST",
      headers: await GetHeaders(),
      body: GetFormData(data),
    })
      .then(async (response) =>
        response.status >= 200 && response.status < 300
          ? Promise.resolve(await EmptyDataResolver(response), response)
          : ErrorResolver(response)
      )
      .catch((err) => Promise.reject(err));
  }

  async Delete(url, data) {
    return fetch(url, {
      method: "DELETE",
      headers: await GetHeaders(),
      body: JSON.stringify(data),
    })
      .then(async (response) =>
        response.status >= 200 && response.status < 300
          ? response.status === 204
            ? Promise.resolve()
            : Promise.resolve(response.json(), response)
          : ErrorResolver(response)
      )
      .catch((err) => Promise.reject(err));
  }

  async UploadFileMultiPart(url, file) {
    let formData = new FormData();
    formData.append("file", file);
    let requesthead = await GetHeaders();
    delete requesthead["Content-Type"];
    return fetch(url, {
      method: "POST",
      headers: requesthead,
      body: formData,
    })
      .then(async (response) =>
        response.status >= 200 && response.status < 300
          ? Promise.resolve({
              result: {
                data: await EmptyDataResolver(response),
                headers: ResolveHeaders(response.headers),
              },
              file,
              xhr: response,
            })
          : Promise.reject({
              err: await response.text(),
              response,
              file,
            })
      )
      .catch((err) => Promise.reject(err));
  }

  async UploadFilePost(url, file, extraHeaders = {}) {
    const formData = new FormData();
    formData.append("file", file);
    //  Object.keys(extraParams).forEach(x => formData.append(x, extraParams[x]));
    return fetch(url, {
      method: "POST",
      headers: Object.assign({}, await GetHeaders(), { Accept: "*" }, extraHeaders),
      body: JSON.stringify(file),
    })
      .then(async (response) =>
        response.status >= 200 && response.status < 300
          ? Promise.resolve({
              result: {
                data: await EmptyDataResolver(response),
                headers: ResolveHeaders(response.headers),
              },
              file,
              xhr: response,
            })
          : Promise.reject({
              err: await response.text(),
              response,
              file,
            })
      )
      .catch((err) => Promise.reject(err));
  }

  async UploadFilePut(url, file, extraParams = {}) {
    let requesthead = await GetHeaders();
    delete requesthead["Content-Type"];
    return fetch(url, {
      method: "PUT",
      headers: requesthead,
      body: file,
    })
      .then(async (response) =>
        response.status >= 200 && response.status < 300
          ? Promise.resolve({
              result: {
                data: await EmptyDataResolver(response),
                headers: ResolveHeaders(response.headers),
              },
              file,
              xhr: response,
            })
          : Promise.reject({
              err: await response.text(),
              response,
              file,
            })
      )
      .catch((err) => Promise.reject(err));
  }

  async PostWithHeaders(url, data) {
    return fetch(url, {
      method: "POST",
      headers: await GetHeaders(),
      body: JSON.stringify(data),
    })
      .then(async (response) =>
        response.status >= 200 && response.status < 300
          ? Promise.resolve({ data: await response.json(), headers: ResolveHeaders(response.headers) }, response)
          : ErrorResolver(response)
      )
      .catch((err) => Promise.reject(err));
  }

  async GetWithHeaders(url) {
    return fetch(url, {
      method: "GET",
      headers: await GetHeaders(),
    })
      .then(async (response) =>
        response.status >= 200 && response.status < 300
          ? Promise.resolve({ data: await response.json(), headers: ResolveHeaders(response.headers) }, response)
          : ErrorResolver(response)
      )
      .catch((err) => Promise.reject(err));
  }

  async PutWithHeaders(url, data) {
    return fetch(url, {
      method: "PUT",
      headers: await GetHeaders(),
      body: JSON.stringify(data),
    })
      .then(async (response) =>
        response.status >= 200 && response.status < 300
          ? response.status === 204
            ? Promise.resolve()
            : Promise.resolve({ data: await response.json(), headers: ResolveHeaders(response.headers) }, response)
          : ErrorResolver(response)
      )
      .catch((err) => Promise.reject(err));
  }
}

export default new APIWrapper();
