import { MY_INFO_BUSINESS_SERVICE } from "Views/KYX/DataCalls/Services";
import APIclient from "API/Client";

export interface IPostMyInfoBusinessAuthoriseResponse {
  authorise_url: Location | (string & Location);
}

export interface IPostMyInfoBusinessKybResponse {
  isSuccess: boolean;
}

export interface IPostMyInfoBusinessKybPayload {
  code: string;
  state: string;
}

export const postMyInfoBusinessAuthorise = (): Promise<IPostMyInfoBusinessAuthoriseResponse> => {
  return new Promise((resolve, reject) => {
    APIclient.postData(MY_INFO_BUSINESS_SERVICE("authorise"))
      .then((result) => {
        resolve(result.data.payload);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
