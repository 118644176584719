/**
 * @fileoverview The Content component acts as a container wrapper, allowing for dynamic column
 * configurations for content rendering.
 *
 * The component supports three main configurations: SINGLE, TWENTY_FOUR, and NONE.
 *
 * These configurations correspond to grid column layouts to provide flexibility in displaying the main content.
 *
 * @author Krishna, Ritik, Sushmitha, Bakshi
 * @created 6 Feb 2023
 * @lastModified 1 Sept 2023
 */
import React from "react";
import classNames from "classnames";

// Types for routing configuration
import { PAGE_GRID_COLUMNS, SPM_CONTAINER_CONTENT_CLASS } from "Route/@types";

import styles from "./index.module.scss";

/**
 * Props type definition for the Content component.
 *
 * @property {React.ReactNode} children - The main content passed to the container.
 * @property {PAGE_GRID_COLUMNS} [columns=PAGE_GRID_COLUMNS.SINGLE] - Specifies the column layout for the main content.
 */
interface IContentProps {
  children: React.ReactNode;
  columns?: PAGE_GRID_COLUMNS;
}

/**
 * The Content component.
 *
 * This component receives the main content to be rendered, as well as an optional column configuration.
 * Depending on the provided column configuration, it applies the appropriate CSS styles to render the main content.
 *
 * @param {IContentProps} props - Properties passed to the component.
 * @returns {React.ReactElement} Rendered Content component.
 */
const Content: React.FC<IContentProps> = ({
  children,
  columns = PAGE_GRID_COLUMNS.SINGLE,
}: IContentProps): React.ReactElement => {
  return (
    <div
      className={classNames(
        styles.container,
        {
          [styles.singleColumn]: columns === PAGE_GRID_COLUMNS.SINGLE,
          [styles.twentyFourColumns]: columns === PAGE_GRID_COLUMNS.TWENTY_FOUR,
          [styles.none]: columns === PAGE_GRID_COLUMNS.NONE,
        },
        SPM_CONTAINER_CONTENT_CLASS
      )}
    >
      {/* Rendering the main content passed to the component */}
      {children}
    </div>
  );
};

export default Content;
