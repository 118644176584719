import React, { useMemo } from "react";
import cn from "classnames";
import { REPORTS_PATH } from "constants/Routes.constants";
import { Link, Redirect, useRouteMatch } from "react-router-dom";
import styles from "./index.module.scss";
import { Typography } from "@spenmo/splice";
import { Frame } from "Views/Reports/Frame";
import { REPORTS_SERVICES } from "Views/Reports/dataCalls/service";
import { useMutableData } from "API/useData";
import { TabLoader } from "Views/Reports/TabLoader";
import { TabList } from "Views/Reports/types";
import useCheckFeatureStatus from "customHooks/featureCheck";
import { SPLIT_NAMES, SPLIT_TREATMENT_TYPES } from "Redux/splitio/constants";
import LoadingComponent from "Views/State/Loading/LoaderIcon";

const Reports = () => {
  const { params } = useRouteMatch<{ tab: string }>();
  const { tab } = params || {};

  const { data, isLoading } = useMutableData(REPORTS_SERVICES.getMenuList);

  const tabList: TabList = useMemo(() => {
    return data?.data || [];
  }, [data?.data]);

  const activeTab = useMemo(() => {
    return tabList?.find((item) => item.code === tab)?.code || tabList[0]?.code;
  }, [tab, tabList]);

  const viewReportsFlag = useCheckFeatureStatus(
    SPLIT_NAMES.viewReportsPage,
    true,
  );

  if (viewReportsFlag === SPLIT_TREATMENT_TYPES.CONTROL) {
    return <LoadingComponent />;
  } else if (viewReportsFlag === SPLIT_TREATMENT_TYPES.OFF) {
    return <Redirect to="/404" />;
  }

  return (
    <div className={styles.report}>
      <Typography
        size="m"
        variant="headline-content"
        tag="h3"
        className={styles.title}
      >
        Reports
      </Typography>
      <div className={styles.content}>
        <ul className={styles.subMenu}>
          <TabLoader isLoading={isLoading}>
            {tabList.map((item) => {
              const isActive = activeTab === item.code;
              return (
                <li key={item.code}>
                  <Link to={`${REPORTS_PATH}/${item.code}`}>
                    {isActive && <div className={styles.activeIndicator} />}
                    <Typography
                      tag="p"
                      size="m"
                      variant="body-content"
                      className={cn(styles.link, {
                        [styles.active]: isActive,
                      })}
                    >
                      {item.name}
                    </Typography>
                  </Link>
                </li>
              );
            })}
          </TabLoader>
        </ul>
        <section>
          <Frame tab={activeTab} />
        </section>
      </div>
    </div>
  );
};

export default Reports;
