import React from "react";
import { IColumnData } from "Modules/DS/DataTable";
import { Tooltip } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import styles from "./PreviewBulk.module.scss";
import Icon, { IconList } from "Modules/Icon";

const renderValue = (value, fallback = "-") => (["", undefined, null, NaN].includes(value) ? fallback : value);

const renderCell = (value: any, record: Record<string, any>, key: string, optionKey: string, type: string) => {
  const columnError = record.error?.[key]?.errorMessage;
  if (columnError) {
    return (
      <Tooltip title={columnError} placement="left">
        <div className={styles.cellError}>{value}</div>
      </Tooltip>
    );
  }

  if (optionKey && type === "multi_string") {
    return (
      <>
        <div>{value}</div>
        <div className={styles.cellDesc}>{record[optionKey]}</div>
      </>
    );
  }

  return value;
};

export interface PreviewBulkColumn {
  [key: string]: string;
}

export const getColumns = ({ hasError, handleEditBill, tableHeader }): IColumnData<PreviewBulkColumn>[] => {
  const error = hasError
    ? [
        {
          id: "error",
          enabled: true,
          column: {
            title: "",
            dataIndex: "error",
            key: "error",
            width: 75,
            render: (error, record) => {
              if (Object.keys(error).length > 0) {
                const errorMessages = (
                  <ul className={styles.errorList}>
                    {Object.keys(error).map((item) => (
                      <li key={item}>{error[item].errorMessage}</li>
                    ))}
                  </ul>
                );
                return (
                  <Tooltip title={errorMessages} placement="left">
                    <div className={styles.errorCount}>{Object.keys(error).length}</div>
                  </Tooltip>
                );
              }
            },
          },
        },
      ]
    : [];

  const columns = [
    ...tableHeader.map((item) => ({
      id: item.key,
      enabled: true,
      column: {
        title: (
          <div>
            <span className={styles.columnText}>{item.title}</span>
            {item.info && (
              <Tooltip title={item.info} placement="right">
                <Icon className={styles.columnIcon} icon={IconList.info} />
              </Tooltip>
            )}
          </div>
        ),
        dataIndex: item.key,
        key: item.key,
        width: 200,
        render: (value, record) =>
          renderCell(
            item.render ? item.render(value, record) : renderValue(value),
            record,
            item.key,
            item.optionKey,
            item.type
          ),
      },
    })),
    {
      id: "action",
      enabled: true,
      column: {
        fixed: "right",
        title: "Action",
        dataIndex: "action",
        key: "action",
        width: 114,
        render: (label, record) => {
          return (
            <button
              className={styles.edit}
              onClick={() => {
                handleEditBill(record?.billID);
              }}
            >
              <span className={styles.editLabel}>Edit</span>
              <ArrowRightOutlined />
            </button>
          );
        },
      },
    },
  ];

  return [...error, ...columns];
};

export const transformTableData = (tableData) => {
  const billData = [];
  tableData?.forEach((item) => {
    const error = {};
    item?.error?.forEach((e) => {
      error[e.column] = e;
    });
    billData.push({ ...item?.invoice, error });
  });

  return billData;
};
