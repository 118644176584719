import React from "react";
import cn from "classnames";
import { Controller } from "react-hook-form";

import {
  Tooltip,
  InfoOutline,
  Input,
  FileOutline,
  FileFilled,
  CalendarReloadOutline,
  CalendarReloadFilled,
  Typography,
} from "@spenmo/splice";
import { ErrorMessage, Field } from "Views/SubscriptionManagement/Create/Components";

import { CONTRACT_TYPE, FORM_NAMES } from "Views/SubscriptionManagement/Constants";
import { formFieldHasRequiredError } from "Views/SubscriptionManagement/Create/Helper";
import { useFormContext } from "Views/SubscriptionManagement/Create/Context";

import styles from "Views/SubscriptionManagement/Create/styles.module.scss";

const options = [
  {
    label: "Fixed-term",
    value: CONTRACT_TYPE.FIXED,
    icon: (isActive: boolean) =>
      isActive ? (
        <FileFilled size="16" iconColor="var(--navy-070)" />
      ) : (
        <FileOutline size="16" iconColor="var(--black-030)" />
      ),
    tooltipTitle: "Contract has an end-date and doesn't auto-renew",
  },
  {
    label: "Rolling",
    value: CONTRACT_TYPE.ROLLING,
    icon: (isActive: boolean) =>
      isActive ? (
        <CalendarReloadFilled size="16" iconColor="var(--navy-070)" />
      ) : (
        <CalendarReloadOutline size="16" iconColor="var(--black-030)" />
      ),
    tooltipTitle: "Contract auto-renews continuously at a fixed interval",
  },
];

interface IContractTypeOptionsProps {
  contractType: CONTRACT_TYPE;
}

export const ContractTypeOptions = ({ contractType }: IContractTypeOptionsProps) => {
  const { setValue, control, errors } = useFormContext();
  const contractTypeHasError = formFieldHasRequiredError(errors, FORM_NAMES.CONTRACT_TYPE);

  const resetValues = (value: CONTRACT_TYPE) => {
    if (value === CONTRACT_TYPE.ROLLING) {
      setValue(FORM_NAMES.END_DATE, null);
    } else {
      setValue(FORM_NAMES.ROLLING_FREQUENCY, null);
    }
  };
  return (
    <Field>
      <Input.Label>Contract type</Input.Label>
      <Controller
        name={FORM_NAMES.CONTRACT_TYPE}
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <div className={styles.contractType} {...field}>
            {options.map(({ value, label, icon, tooltipTitle }) => (
              <div
                className={cn(styles.item, { [styles.active]: contractType === value })}
                onClick={() => {
                  resetValues(value);
                  field.onChange(value);
                }}
              >
                {icon(contractType === value)}
                <Typography variant="body-content" size="s">
                  {label}
                </Typography>
                <Tooltip placement="bottom" title={tooltipTitle}>
                  <InfoOutline size="16" fill="currentColor" />
                </Tooltip>
              </div>
            ))}
          </div>
        )}
      />
      {contractTypeHasError && <ErrorMessage message="Select a contract type" />}
    </Field>
  );
};
