import React from "react";
import { Tooltip } from "antd";
import { ocrUnion as ocrUnionIcon, ocrUnionGreen as ocrUnionGreenIcon } from "assets/img";
import Icon from "Modules/icons";

import styles from "./ocrTooltip.module.scss";

export interface OCRTooltipProps {
  label: string | React.ReactNode;
  isOptional?: boolean;
  isOCROveridden?: boolean;
}

const OCRTooltip: React.FC<OCRTooltipProps> = ({ label, isOptional, isOCROveridden }) => {
  return (
    <div className={styles.formItemOCR}>
      {label} {isOptional && <span className={styles.optional}>(optional)</span>}
      <div className={styles.tooltip}>
        <Tooltip
          title={
            isOCROveridden ? `This field has been overidden manually from OCR` : `This field has been filled by OCR`
          }
        >
          <Icon
            data-testid="ocr-tooltip-icon"
            alt="ocr-union-icon"
            src={isOCROveridden ? ocrUnionIcon : ocrUnionGreenIcon}
          />
        </Tooltip>
      </div>
    </div>
  );
};

export default OCRTooltip;
