import { trackEvent, identifySessionStorageName } from "utility/analytics";
import { setCookie, cookieNames, removeCookie } from "utility/CookieHelper";
import APIclient from "API/Client";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import SwitchAccountErrorMessage from "Modules/SwitchAccount/ErrorMessage";
import { ERROR_STATUS_CODE } from "constants/ErrorStatusCode.constants";
import { AuthenticationButtonActions, LoginResponseResult, AuthActionEventSource } from "Views/Auth/Analytics";

export const FETCH_LOGIN_REQUEST = "FETCH_LOGIN_REQUEST";
export const FETCH_LOGIN_SUCCESS = "FETCH_LOGIN_SUCCESS";
export const FETCH_LOGIN_FAILURE = "FETCH_LOGIN_FAILURE";
export const USER_AUTH = "USER_AUTH";

export const LOGIN_START = "LOGIN_START";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const LOGIN_IDLE = "LOGIN_IDLE";
export const VERIFY_2FA_START = "VERIFY_2FA_START";
export const VERIFY_2FA_REQUEST = "VERIFY_2FA_REQUEST";
export const VERIFY_2FA_SUCCESS = "VERIFY_2FA_SUCCESS";
export const VERIFY_2FA_FAILURE = "VERIFY_2FA_FAILURE";
export const SWITCH_ACCOUNT_STATUS = "SWITCH_ACCOUNT_STATUS";

export const LINKED_ACCOUNTS = "LINKED_ACCOUNTS";

const fetchDataReq = (data, comp) => {
  return {
    type: FETCH_LOGIN_REQUEST,
    data: data,
  };
};
const fetchDataSuccess = (data) => {
  return {
    type: FETCH_LOGIN_SUCCESS,
    data: data,
  };
};
const fetchDataError = (data) => {
  return {
    type: FETCH_LOGIN_FAILURE,
    data: data,
  };
};

const switchingAccount = (data) => {
  return {
    type: SWITCH_ACCOUNT_STATUS,
    data: data,
  };
};

export const linkedAccountSuccess = (data) => ({
  type: LINKED_ACCOUNTS,
  data: data,
});
export const OTP_EXPIRES_AT_SESSION_STORAGE_NAME = "otp_expired_at";

export const loginIdle = () => ({ type: LOGIN_IDLE });
const verify2FaStart = (data) => ({ type: VERIFY_2FA_START, data });
const verify2FaRequest = () => ({ type: VERIFY_2FA_REQUEST });
const verify2FaSuccess = (data) => ({ type: VERIFY_2FA_SUCCESS, data });
const verify2FaError = (data) => ({ type: VERIFY_2FA_FAILURE, data });

const fetchLinkedAccounts = async (dispatch) => {
  try {
    const { data } = await APIclient.getData("/ms/b2b-auth/v2/multi-login/linked-accounts");
    window.localStorage.setItem("linked-account", JSON.stringify(data?.data));
    dispatch(linkedAccountSuccess(data?.data));
  } catch (err) {
    const { message } = err.response?.data?.errors?.[0] || { message: "Oops! something went wrong." };
    dispatch(fetchDataError(message));
  }
};
export const switchAccount = (user_id) => {
  return async (dispatch) => {
    dispatch(switchingAccount(true));
    const dataObj = {
      new_user_id: user_id,
    };

    try {
      dispatch(fetchDataReq());
      const { data: linkedAccountData } = await APIclient.postData(
        "/ms/b2b-auth/v2/multi-login/switch-account",
        dataObj
      );
      setCookie(cookieNames.AUTH_TOKEN, linkedAccountData?.data.access_token);
      setCookie(cookieNames.REFRESH_TOKEN, linkedAccountData?.data.refresh_token);
      dispatch(fetchDataReq());
      const { data: preliminaryData } = await APIclient.getData("/api/v2/preliminary-data");
      const finalData = { ...linkedAccountData?.data, ...preliminaryData?.payload };
      const {
        phone_code,
        mobile_number,
        org_id,
        org_country_code,
        user_id,
        is_use_credit,
        currency_code = "",
        role = "",
        card_vendor = "",
        user_email = "",
        registration_date = "",
        is_kyb_on_demand = false,
      } = finalData;
      setCookie(cookieNames.USER_MOBILE_NUMBER, mobile_number);
      setCookie(cookieNames.PHONE_CODE, phone_code);
      setCookie(cookieNames.USER_EMAIL, user_email);
      setCookie(cookieNames.IS_USE_CREDIT, is_use_credit);
      setCookie(cookieNames.USER_CARD_VENDOR, card_vendor);
      setCookie(cookieNames.USER_COMPANY_ROLE, role);
      setCookie(cookieNames.CURRENCY_CODE, currency_code);
      setCookie(cookieNames.ORG_COUNTRY_CODE, org_country_code);
      setCookie(cookieNames.REAUTH, org_id + "vspenmo1" + user_id + "vspenmo1" + org_country_code);
      setCookie(cookieNames.ORG_REGISTRATION_DATE, registration_date);
      setCookie(cookieNames.IS_KYB_ON_DEMAND, is_kyb_on_demand);
      await dispatch(fetchDataSuccess({ ...finalData }));
      await dispatch(switchingAccount(false));
      await fetchLinkedAccounts(dispatch).then(() => window.location.assign("/"));
    } catch (err) {
      SwitchAccountErrorMessage("Switch acccount failed, due to error in our system. Please try again later");
      const { message } = err.response?.data?.errors?.[0] || {};
      dispatch(switchingAccount(false));
      dispatch(fetchDataError(message));
      await trackEvent("Account switch fail", { "error reason": message });
    }
  };
};

export const loginFunc = (dataObj = {}) => {
  return (dispatch) => {
    dispatch(fetchDataReq());

    const shouldVerify2Fa = (data = {}) => data.generate_key !== "" && data.access_token === "";

    APIclient.postData("/ms/b2b-auth/v2/auth/login-email", dataObj)
      .then(({ data }) => {
        const { code, message } = data?.errors?.[0] || {};
        if (code === ERROR_STATUS_CODE.INVALID_CREDENTIAL) {
          dispatch(fetchDataError(message));
          return;
        }

        if (shouldVerify2Fa(data.data)) {
          dispatch(
            verify2FaStart({
              ...data.data,
            })
          );
        } else {
          trackEvent(AuthenticationButtonActions.ACCOUNT_AUTHENTICATION_BUTTON_CLICKED, {
            authentication_event_source: AuthActionEventSource.LOGIN,
            authentication_event_action: LoginResponseResult.SUCCESS,
          });
          setCookie(cookieNames.AUTH_TOKEN, data?.data?.access_token);
          setCookie(cookieNames.REFRESH_TOKEN, data?.data?.refresh_token);
          setCookie(cookieNames.USER_EMAIL, data?.data?.user_email);
          setCookie(cookieNames.USER_MOBILE_NUMBER, data?.data?.mobile_number);
          sessionStorage.setItem(OTP_EXPIRES_AT_SESSION_STORAGE_NAME, data?.data?.otp_expired_at);
          setCookie(cookieNames.IS_OTP_VERIFICATION_NEEDED, "true");
          window.location.assign(`/otp${window?.location?.search || ""}`);
        }
      })
      .catch((err) => {
        const { message } = err?.response?.data?.errors?.[0] || err?.response?.data || {};
        dispatch(fetchDataError(message));
        trackEvent("log in fail", { "error reason": message });
        trackEvent(AuthenticationButtonActions.ACCOUNT_AUTHENTICATION_BUTTON_CLICKED, {
          authentication_event_source: AuthActionEventSource.LOGIN,
          authentication_event_action: LoginResponseResult.FAIL,
        });
      });
  };
};

export const getPreliminaryData = async (dispatch) => {
  dispatch(fetchDataReq());
  try {
    const response = await APIclient.getData("/api/v2/preliminary-data");
    const { status, payload = {} } = response?.data || {};
    if (status === HTTP_STATUS_CODE.OK) {
      const {
        phone_code,
        mobile_number,
        org_id,
        org_country_code,
        user_id,
        is_use_credit,
        currency_code = "",
        role = "",
        card_vendor = "",
        user_email = "",
        is_onboarding_finished = true,
        registration_date = "",
        kyb_status = "",
        onboarding_version = "",
        is_rekyb_required = false,
        is_org_blocked = false,
        is_kyb_on_demand = false,
      } = payload || {};
      setCookie(cookieNames.USER_MOBILE_NUMBER, mobile_number);
      setCookie(cookieNames.PHONE_CODE, phone_code);
      setCookie(cookieNames.USER_EMAIL, user_email);
      setCookie(cookieNames.IS_USE_CREDIT, is_use_credit);
      setCookie(cookieNames.USER_CARD_VENDOR, card_vendor);
      setCookie(cookieNames.USER_COMPANY_ROLE, role);
      setCookie(cookieNames.CURRENCY_CODE, currency_code);
      setCookie(cookieNames.IS_ONBOARDING_FINISHED, is_onboarding_finished);
      setCookie(cookieNames.ORG_COUNTRY_CODE, org_country_code);
      setCookie(cookieNames.ONBOARDING_VERSION, onboarding_version);
      setCookie(cookieNames.KYB_STATUS, kyb_status);
      setCookie(cookieNames.REAUTH, org_id + "vspenmo1" + user_id + "vspenmo1" + org_country_code);
      setCookie(cookieNames.ORG_REGISTRATION_DATE, registration_date);
      setCookie(cookieNames.IS_REKYB_REQUIRED, is_rekyb_required);
      setCookie(cookieNames.SHOW_REKYB_MODAL, is_rekyb_required && is_org_blocked);
      setCookie(cookieNames.IS_KYB_ON_DEMAND, is_kyb_on_demand);

      dispatch(fetchDataSuccess({ ...(payload || {}) }));
      fetchLinkedAccounts(dispatch);
      trackEvent("log in success");
    } else {
      throw { response };
    }
  } catch (error) {
    const { message } = error?.response?.data?.errors?.[0] || {};
    dispatch(fetchDataError(message));
    trackEvent("log in fail", { "error reason": message });
  }
};

export const logoutFunc = () => {
  return (dispatch) => {
    dispatch(fetchDataReq());
    APIclient.postData("/api/v2/auth/logout", {})
      .then((data) => {
        trackEvent(AuthenticationButtonActions.ACCOUNT_AUTHENTICATION_BUTTON_CLICKED, {
          authentication_event_source: AuthActionEventSource.LOGOUT,
          authentication_event_action: LoginResponseResult.SUCCESS,
        });
        removeCookie(cookieNames.AUTH_TOKEN);
        removeCookie(cookieNames.EXPIRED);
        removeCookie(cookieNames.IS_OTP_VERIFICATION_NEEDED);
        removeCookie(cookieNames.USER_CARD_VENDOR);
        removeCookie(cookieNames.USER_COMPANY_ROLE);
        removeCookie(cookieNames.CURRENCY_CODE);
        removeCookie(cookieNames.REAUTH);
        removeCookie(cookieNames.REFRESH_TOKEN);
        removeCookie(cookieNames.HIDE_EDIT_BILL_BANNER);
        removeCookie(cookieNames.IS_ONBOARDING_FINISHED);
        removeCookie(cookieNames.ONBOARDING_VERSION);
        removeCookie(cookieNames.KYB_STATUS);
        removeCookie(cookieNames.FORCE_RESET_SUCCESS);
        removeCookie(cookieNames.FORCE_RESET_TOKEN);
        removeCookie(cookieNames.FORCE_RESET_ERROR);
        removeCookie(cookieNames.ORG_REGISTRATION_DATE);
        removeCookie(cookieNames.CONTINUE_MOBILE_VIEW);
        removeCookie(cookieNames.ORG_COUNTRY_CODE);
        removeCookie(cookieNames.IS_FORCE_RESET_NEEDED);
        removeCookie(cookieNames.IS_REKYB_REQUIRED);
        removeCookie(cookieNames.SHOW_REKYB_MODAL);
        removeCookie(cookieNames.IS_KYB_ON_DEMAND);

        localStorage.removeItem("activeKYXStep");
        localStorage.removeItem("isWelcomeActive");
        window.localStorage.removeItem("linked-account");
        sessionStorage.removeItem(identifySessionStorageName);
        dispatch({
          type: USER_AUTH,
          authData: {},
        });
        dispatch(fetchDataSuccess(data?.data));
        const {
          wipeData = () => {
            /* do nothing*/
          },
        } = window?.appboy || {};
        wipeData();
        window.analytics.reset();
        window.location.reload();
      })
      .catch((err) => {
        trackEvent(AuthenticationButtonActions.ACCOUNT_AUTHENTICATION_BUTTON_CLICKED, {
          authentication_event_source: AuthActionEventSource.LOGOUT,
          authentication_event_action: LoginResponseResult.FAIL,
        });
        dispatch(fetchDataError(err.message || err));
      });
  };
};

export const checkAuth = () => {
  return (dispatch) => {
    APIclient.postData("/api/v1/auth/checkSession", {})
      .then((cdata) => {
        dispatch({
          type: USER_AUTH,
          data: cdata?.data?.payload,
        });
      })
      .catch((err) => console.log(err));
  };
};

export const verify2Fa = (requestBody) => async (dispatch, getState) => {
  dispatch(verify2FaRequest());
  const secretKey = getState().loginReducer?.data?.payload?.generate_key;
  APIclient.postData("/ms/b2b-auth/v2/auth/verify-gauth", {
    ...requestBody,
    secret_key: secretKey,
  })
    .then(async ({ data }) => {
      setCookie(cookieNames.AUTH_TOKEN, data?.data?.access_token);
      setCookie(cookieNames.REFRESH_TOKEN, data?.data?.refresh_token);
      await getPreliminaryData(dispatch);
      dispatch(verify2FaSuccess(data?.data));
      fetchLinkedAccounts(dispatch);
    })
    .catch((err) => {
      const { code, message } = err?.response?.data?.errors[0] || {};
      dispatch(verify2FaError({ errorCode: code, errorMessage: message }));
    });
};
