import React, { useState } from "react";
import classNames from "classnames";
import { Core } from "assets/v1.1/icons/Core";
import { IAccordionProps } from "./type.d";

import styles from "./styles.module.scss";

const { ChevronDown } = Core;

export const Accordion = ({
  className,
  children,
  title,
  subtitle,
  icon,
  shadow,
  footer,
  suffixIcon,
  defaultOpen = false,
}: IAccordionProps) => {
  const [open, setOpen] = useState(defaultOpen);

  return (
    <div
      data-testid="accordion"
      className={classNames(
        styles.accordion,
        { [styles[shadow]]: shadow, [styles["accordion__footer"]]: footer },
        className
      )}
    >
      <div className={styles.header}>
        {icon && <div className={styles.icon}>{icon}</div>}
        <div className={styles["title-wrapper"]}>
          <h4 className={styles.title}>{title}</h4>
          {subtitle && <p className={styles["sub-title"]}>{subtitle}</p>}
        </div>

        {suffixIcon || (
          <div onClick={() => setOpen(!open)}>
            <img
              className={classNames(styles["accordion__arrow"], open ? styles["rotate-180"] : styles["rotate-0"])}
              src={ChevronDown.Line[24]}
              alt="Chevron Down Icon"
            />
          </div>
        )}
      </div>

      {open && children}
    </div>
  );
};

export * from "./type.d";
