export enum TeamListEventName {
  TEAM_ELIGIBILITY_FAIL_POPUP = "visit specific team page from eligibility fail popup",
  TEAM_VIEWED = "teams viewed",
  TEAMS_CREATE_NEW_CLICKED = "teams create new clicked",
}

export enum MoveTeamMemberEvent {
  MOVE_TO_TEAM_ACTION = "move to team action clicked",
  CONFIRM_MOVE_TO_TEAM = "confirm move to team button clicked",
  MOVE_TO_TEAM_ONLY_MANAGER = "close need one manager warning modal button clicked",
}

export enum BUDGET_EVENTS {
  BUDGET_PAGE_LOADED = "Budget Page Loaded",
  BUDGET_CREATION_BUTTON_CLICKED = "Budget Creation Button Clicked",
}
