export const KBArticleLink = "https://spenmo.zendesk.com/hc/en-us/articles/20360562461721";

export const TRANSACTION_TYPE = {
  bill: "Bills",
  card: "Cards",
  reimbursement: "Cash Reimbursement",
  topup: "Top Up",
};

export const REQUIRED_FIELDS = {
  field_category: "Category",
  field_tax: "Tax",
  field_vendor: "Vendor",
  field_notes: "Notes",
  field_receipts: "Receipts",
  field_tags: "Tags",
};

export const NON_EDITABLE_FIELDS = {
  Category: 1,
  Tax: 1,
  Vendor: 1,
};

export const ACCOUNTING_SETTING_ANALYTICS = {
  CLICK_CONNECT_INTEGRATION_BUTTON: "Integrations to Xero Clicked",
  XERO_INTEGRATION: "Integrations to Xero Clicked",
  INTEGRATIONS_TO_XERO_PROCESSED: "Integrations to Xero Processed",
};

export const oneMinuteDelay = 60000;

export const syncPauseFailureText =
  "The synchronization process with Xero cannot be paused right now. Try again later.";
export const syncResumeFailureText = "Unable to resume Xero synchronization. Please try again later.";

export const PARTNER_TYPE = {
  NETSUITE: "NetSuite",
  XERO: "Xero",
};
