import APIclient from "API/Client";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";

export const FETCH_PHONE_NUMBER_REQUEST = "FETCH_PHONE_NUMBER_REQUEST";
export const FETCH_PHONE_NUMBER_SUCCESS = "FETCH_PHONE_NUMBER_SUCCESS";
export const FETCH_PHONE_NUMBER_ERROR = "FETCH_PHONE_NUMBER_ERROR";

export const fetchDataRequest = (data) => {
  return {
    type: "FETCH_PHONE_NUMBER_REQUEST",
    data: data,
  };
};

export const fetchDataSuccess = (data) => {
  return {
    type: "FETCH_PHONE_NUMBER_SUCCESS",
    data: data.payload,
  };
};

export const fetchDataError = (data) => {
  return {
    type: "FETCH_PHONE_NUMBER_ERROR",
    data: data,
  };
};

export const CheckPhoneNumber = (phoneCode, phoneNumber) => {
  return (dispatch) => {
    dispatch(fetchDataRequest());

    APIclient.getData(
      `/ms/spm-organization/v1/employee/check-phone?phoneCountryCode=${phoneCode}&phoneNumber=${phoneNumber}`
    )
      .then((data) => {
        if (data.data.status === HTTP_STATUS_CODE.OK) {
          dispatch(fetchDataSuccess(data.data));
        } else {
          dispatch(fetchDataError(data.data));
        }
      })
      .catch((err) => {
        dispatch(fetchDataError(err));
      });
  };
};
