import { useEffect, useState } from "react";

import { showErrorNotification } from "Views/Card/Components/Notification";

import { getCategoriesDetails } from "Redux/DataCalls/Card/CategoryLock.api";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";

import { ICategoryDetails } from "Views/Card/CategoryLockKbArticle/types";

export const useGetCategoryDetails: () => [ICategoryDetails[], boolean] = () => {
  const [data, setData] = useState<ICategoryDetails[]>([]);
  const [loading, setLoading] = useState(false);

  const fetchCategoryDetails = () => {
    getCategoriesDetails()
      .then(({ data }) => {
        /**TODO: get this as array */
        const { status_message = "", ...categoriesData } = data?.payload || {};

        const categoriesDataArr: ICategoryDetails[] = Object.values(categoriesData || {});

        if (data?.status === HTTP_STATUS_CODE.OK && categoriesDataArr.length) {
          setData(categoriesDataArr);
        } else {
          showErrorNotification(status_message);
        }
      })
      .catch((err) => {
        const { status_message = "Something went wrong! Please try again later." } = err?.response?.data?.payload || {};
        showErrorNotification(status_message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchCategoryDetails();
  }, []);

  return [data, loading];
};
