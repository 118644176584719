import {
  GetVendorListWithPaginationParams,
  getVendorDetailWithBillInfo,
  getVendorListWithPagination,
} from "Redux/DataCalls/Disbursement.api";
import { Skeleton } from "antd";
import { landingSoon } from "assets/img";
import axios, { Canceler } from "axios";
import React, { useEffect, useMemo, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useLocation } from "react-router-dom";
import { trackEvent } from "utility/analytics";
import sharedStyles from "../Bills.module.scss";
import styles from "./ManageRecipients.module.scss";
import RecipientDetail from "./RecipientDetail";
import RecipientsFilter from "./RecipientFilter";
import RecipientForm from "./RecipientForm";
import Banner from "Modules/DS/Banner";
import RecipientsTable, { recipientsTableColumns } from "./RecipientsTable";
import RecipientsTableLoader from "./RecipientsTable/RecipientsTableLoader";
import {
  PaginationWithNextPageToken,
  RecipientData,
  RecipientListItem,
} from "./types";
import useCheckOrgConfigs from "customHooks/useCheckOrgConfigs";
import { PRODUCT_NAME } from "Redux/ModularProduct";
import { Button as SpliceButton } from "@spenmo/splice";
import { AddOutline } from "@spenmo/splice";
import classNames from "classnames";

const ManageRecipients: React.FC = () => {
  const [recipientsList, setRecipientsList] = useState<RecipientListItem[]>([]);
  const [recipientDetail, setRecipientDetail] = useState<RecipientData | null>(
    null,
  );
  const [showEditForm, setShowEditForm] = useState(false);
  const [pagination, setPagination] =
    useState<PaginationWithNextPageToken | null>(null);
  const [hasMore, setHasMore] = useState(true);
  const [resetForm, setResetForm] = useState(0);
  const [highlightRow, setHighlightRow] = useState(0);
  const [showModalRecipientForm, setShowModalRecipientForm] = useState(false);

  // will refetch the list when the value is changed
  const [refetchListTrigger, setRevetchListTrigger] = useState(0);

  const { search } = useLocation();
  const isWithholdingTaxEnabled = useCheckOrgConfigs(
    PRODUCT_NAME.BILL_WITHHOLDING_TAX,
  );

  const urlParams = useMemo(() => {
    const urlSearchParams = new URLSearchParams(search);
    return {
      id: urlSearchParams.get("id") || "",
      keyword: urlSearchParams.get("keyword") || "",
      sort_by: urlSearchParams.get("sortBy") || "",
      sort_dir: urlSearchParams.get("sortDir") || "",
    };
  }, [search]);

  const hasFilter = useMemo(() => {
    return Boolean(
      urlParams.keyword || urlParams.sort_by || urlParams.sort_dir,
    );
  }, [urlParams]);

  useEffect(() => {
    trackEvent("LP Recipient - Entry");
  }, []);

  const noData = !hasFilter && pagination?.totalItems === 0;

  const createParams = (
    page_token: string,
  ): GetVendorListWithPaginationParams => {
    return {
      page_size: "10",
      page_token,
      keyword: urlParams.keyword || "",
      sort_by: urlParams.sort_by || "created_at",
      sort_dir:
        (urlParams.sort_dir as GetVendorListWithPaginationParams["sort_dir"]) ||
        "desc",
    };
  };

  const setRecipients = (
    vendors: RecipientListItem[],
    pagination: PaginationWithNextPageToken,
  ) => {
    setPagination(pagination);
    setRecipientsList((prev) => {
      if (pagination.page === 1) {
        return vendors || [];
      } else {
        return [...prev, ...vendors];
      }
    });
    setHasMore(pagination.page < pagination.totalPages);
  };

  const handleFetch = (
    page_token: string = "",
    getCanceler?: (cancel: Canceler) => void,
  ) => {
    const params = createParams(page_token);

    getVendorListWithPagination(params, {
      cancelToken: new axios.CancelToken((cancel) => getCanceler?.(cancel)),
    })
      .then((res) => {
        const { vendors, pagination, nextPageToken } = res.data.payload;
        setRecipients(vendors, { ...pagination, nextPageToken });
      })
      .catch((err: Error) => {
        if (!axios.isCancel(err)) {
          console.error(err.message);
        }
      });
  };

  const handleDetail = (id: number) => {
    trackEvent("LP Recipient - CTA on vendor row", {
      vendorId: id,
    });
    getVendorDetailWithBillInfo(id).then((res) => {
      setRecipientDetail(res.data.payload);
    });
  };

  const handleEdit = () => {
    setResetForm((prev) => prev + 1);
    setShowEditForm(true);
  };

  useEffect(() => {
    const id = Number(urlParams.id);
    if (id) {
      handleDetail(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlParams]);

  useEffect(() => {
    setRecipientsList([]);
    setPagination(null);
    setRecipientDetail(null);
    setHasMore(true);
  }, [urlParams.keyword]);

  useEffect(() => {
    setHasMore(true);
    let cancel: Canceler;
    handleFetch("", (canceler) => {
      cancel = canceler;
    });

    return () => {
      cancel?.();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, refetchListTrigger]);

  const refetchList = () => {
    setRevetchListTrigger((prev) => prev + 1);
  };

  const handleCloseSidePanel = () => {
    setRecipientDetail(null);
    setShowEditForm(false);
    setResetForm((prev) => prev + 1);
    setHighlightRow(0);
  };

  const onSuccessSubmit = () => {
    refetchList();
    handleCloseSidePanel();
  };

  const loader = (
    <>
      {Array(3)
        .fill("")
        .map((item) => (
          <div className={sharedStyles.shimmerTable} key={item}>
            {recipientsTableColumns.map((item) => {
              const columnWidth = Number(item.column.width) - 32;
              return (
                <div
                  style={{
                    width: columnWidth || "100%",
                    flexShrink: columnWidth ? 0 : 1,
                  }}
                  key={item.id}
                >
                  <Skeleton
                    active
                    paragraph={{ rows: 1, width: "100%" }}
                    title={false}
                  />
                </div>
              );
            })}
          </div>
        ))}
    </>
  );

  const newVendorForm = (
    <RecipientForm
      setShowModalRecipientForm={setShowModalRecipientForm}
      showModalRecipientForm={showModalRecipientForm}
      onSuccessSubmit={onSuccessSubmit}
      onGetDetail={(id) => {
        handleDetail(id);
        !recipientDetail && setHighlightRow(id);
      }}
      defaultValue={showEditForm ? recipientDetail : undefined}
      key={resetForm}
    />
  );

  const renderContent = () => {
    if (!pagination) {
      return <RecipientsTableLoader />;
    }

    if (noData) {
      return (
        <div
          className={classNames(styles.noRecipients, {
            [styles.noData]: noData,
          })}
        >
          <img
            src={landingSoon}
            alt="No recipients"
            className={styles.noRecipientsImage}
          />
          <h3>Manage Recipients for Bill Payments</h3>
          <p>
            Recipients added by your organization can be viewed and edited here
          </p>
          <br />
          {newVendorForm}
        </div>
      );
    }

    return (
      <>
        {Boolean(pagination?.totalItems) && (
          <p className={sharedStyles.description}>
            There are <b>{pagination.totalItems}</b> Recipients stored in your
            organization.
          </p>
        )}
        <InfiniteScroll
          dataLength={recipientsList.length}
          next={() => {
            handleFetch(pagination?.nextPageToken);
          }}
          hasMore={hasMore}
          loader={loader}
        >
          <RecipientsTable
            data={recipientsList}
            pagination={pagination}
            onGetDetail={handleDetail}
            highlightRow={highlightRow}
          />
        </InfiniteScroll>
      </>
    );
  };

  return (
    <div className={sharedStyles.draft}>
      <div className={styles.recipientContainer}>
        <SpliceButton
          variant="secondary"
          size="s"
          className={styles.addRecipent}
          onClick={() => setShowModalRecipientForm(true)}
        >
          <AddOutline iconColor="var(--fill-interactive-primary)" size="16" />
          New Recipient
        </SpliceButton>
        <div className={sharedStyles.filterContainer}>
          {!noData && (
            <>
              <RecipientsFilter />
              {newVendorForm}
            </>
          )}
        </div>
        {isWithholdingTaxEnabled && (
          <div className={sharedStyles.banner}>
            <Banner
              type="info"
              message={
                <>
                  <p className={sharedStyles.bannerTitle}>
                    New Withholding Tax feature
                  </p>
                  <p>
                    Add withholding tax information for recipient who resides in
                    the Philippines when creating a new recipient or updating an
                    existing recipient
                  </p>
                </>
              }
            />
          </div>
        )}
        {renderContent()}
        <RecipientDetail
          data={recipientDetail}
          onClose={handleCloseSidePanel}
          onEdit={handleEdit}
        />
      </div>
    </div>
  );
};

export default ManageRecipients;
