import React from "react";
import { Skeleton } from "antd";

import { Col, Row } from "Splice/Grid";

import styles from "./index.module.scss";

export const IntroLoader: React.FC = (): React.ReactElement => {
  return (
    <Row align="end" justify="start">
      <Col className={styles.content} span={24}>
        <Skeleton.Input className={styles.title} active size={"large"} />
        <Skeleton className={styles.message} paragraph={{ rows: 2 }} />
      </Col>
      <Col className={styles.alignInline} span={24}>
        <Skeleton.Input className={styles.card} active size={"large"} />
        <Skeleton.Input className={styles.card} active size={"large"} />
      </Col>
      <Col span={24}>
        <Skeleton.Input className={styles.button} active size={"large"} />
      </Col>
    </Row>
  );
};
