import React, { useState } from "react";
import LoginScreenWrapper from "Modules/loginScreenWrapper";
import OTPVerification from "./OTPVerification";
import ChangePhoneNum from "./ChangePhoneNum";
import { useDispatch, useSelector } from "react-redux";
import { verifyOtpResetData } from "Redux/Actions/OTP/otpVerificationAction";
import { reSendOtp } from "Redux/Actions";
import { Redirect, useLocation } from "react-router";
import { Cookies } from "react-cookie";
import { DASHBOARD_PATH } from "constants/Routes.constants";
import "./EmployeeOTP.scss";
import { trackEvent } from "utility/analytics";
import { FailedAttempts } from "../Auth/Components";
import { getTimeDiffInSeconds } from "utility";
import { OTP_EXPIRES_AT_SESSION_STORAGE_NAME } from "Redux/Actions/loginAction";
import { OTP_UNBLOCK_TIME } from "constants/Auth.constant";

const cookies = new Cookies();

const EmployeeOTP = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [showChangePhoneNum, setChangePhoneNum] = useState(false);
  const { loading, data, error } = useSelector((state) => state.getOtpReducer);
  const isOtpVerificationNeeded =
    cookies.get("is_otp_verification_needed") === "true";
  const redirectTo = location?.search
    ?.replace("?redirect=", "")
    ?.replace("404", "")
    ?.replace("&", "?");
  const redirectionPath = `${DASHBOARD_PATH}${redirectTo || ""}`;

  const OTP_EXPIRE_TIME = new Date(
    sessionStorage
      ?.getItem?.(OTP_EXPIRES_AT_SESSION_STORAGE_NAME)
      ?.replace(/\s/, "T") || "",
  );

  const otpTimer = getTimeDiffInSeconds(OTP_EXPIRE_TIME);

  const reFetchOtp = () => {
    dispatch(verifyOtpResetData());
    dispatch(reSendOtp());
    trackEvent("otp get new code clicked", {});
  };

  const onChangePhoneNumber = (value) => {
    setChangePhoneNum(value);
  };

  const handleOFACView = () => {
    switch (true) {
      case showChangePhoneNum && !loading:
        return <ChangePhoneNum back={() => onChangePhoneNumber(false)} />;
      // if the otpTimeoutSeconds is more than 300seconds, then we need to show failed attempts component
      /*
        We are Removing this logic from FE as it creating issues and hard to debug.
        Ideally this logic should be in BE. we should get
        { errorcode: "something" , message: "To many failed attempt"} from BE
         We will bring it back asap after BE support.
      */

      // case otpTimer >= OTP_UNBLOCK_TIME:
      //   return <FailedAttempts />;

      default:
        return (
          <OTPVerification
            changePhoneNumber={() => {
              onChangePhoneNumber(true);
              trackEvent("otp change phone number clicked", {});
            }}
            fetchOtpError={error}
            fetchOtpData={data}
            forgotOTPLoding={loading}
            reFetchOtp={reFetchOtp}
            redirectionPath={redirectionPath}
          />
        );
    }
  };

  if (!isOtpVerificationNeeded) {
    return <Redirect to={redirectionPath} />;
  }

  return (
    <>
      <LoginScreenWrapper
        className={"employee-otp--wrapper"}
        id={"employee-otp"}
      >
        {handleOFACView()}
      </LoginScreenWrapper>
    </>
  );
};

export default EmployeeOTP;
