import { debounce } from "utility";

import { DEBOUNCE_DELAY_TIME, IUseElasticSearchProps } from "Modules/DS/Search";

import { useCommonSearchProps } from "./useCommon";

export const useElasticSearch = (
  onSearch: (val: string) => void,
  clearSearch: () => void,
  inputValue?: string,
  delay: DEBOUNCE_DELAY_TIME = DEBOUNCE_DELAY_TIME.MAX,
  loaderDelay: DEBOUNCE_DELAY_TIME = DEBOUNCE_DELAY_TIME.MID
): IUseElasticSearchProps => {
  const { get, onInputChange, clearInput, stopLoader } = useCommonSearchProps(inputValue);

  const debounceLoader = debounce(stopLoader, delay + loaderDelay);

  const onSearchHandler = (val: string) => {
    onInputChange(val);
    onSearch(val);
    debounceLoader();
  };

  const clearSearchHandler = () => {
    clearSearch?.();
    clearInput();
  };

  return {
    get,
    clearSearchHandler,
    onSearchHandler,
    stopLoader,
  };
};
