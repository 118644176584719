import React from "react";
import { Form, Select } from "antd";

import { IKYXSelectProps } from "Views/KYX/@types";

export const KYXSelect = ({ formProps, selectProps }: IKYXSelectProps): JSX.Element => {
  return (
    <Form.Item {...formProps}>
      <Select {...selectProps} />
    </Form.Item>
  );
};
