export const importXeroCategories = (xeroCategories = [], spenmoCategories = []) => {
  const spenmoCategoriesAccountingNumbers = [];
  const spenmoCategoriesNames = [];

  spenmoCategories.forEach((item) => {
    if (item?.accounting_system_code && item?.category_name) {
      spenmoCategoriesAccountingNumbers.push(item.accounting_system_code);
      spenmoCategoriesNames.push(item.category_name);
    }
  });
  const importingCategories = [];
  const xeroExpenseCategories = xeroCategories?.find((item) => item?.type === "EXPENSE");
  const { data } = xeroExpenseCategories || {};
  data &&
    Array.isArray(data) &&
    data.forEach((item) => {
      if (
        item?.account_name &&
        item?.account_number &&
        spenmoCategoriesAccountingNumbers.indexOf(item.account_number) === -1 &&
        spenmoCategoriesNames.indexOf(item.account_name) === -1
      ) {
        importingCategories.push({
          category_name: item.account_name,
          accounting_system_code: item.account_number,
          id: item.account_name,
        });
      }
    });
  return importingCategories;
};

export const getXeroCategoriesAccountNumbers = (xeroCategories = []) => {
  return xeroCategories.map((category) => category.number);
};
