import React from "react";
import classNames from "classnames";

import { LoaderProps, LOADER_SIZES, LOADER_TYPES } from "./types";
import styles from "./index.module.scss";

export const Loader = ({ type = LOADER_TYPES.BRAND, size = LOADER_SIZES.BIG }: LoaderProps): JSX.Element => {
  const _classNames = classNames(styles.loader, styles[type], styles[size]);

  return (
    <div className={_classNames}>
      <svg className={styles.svg} width="20" height="20" viewBox="0 0 20 20" fill="currentColor">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.10053 8.15129C2.71426 9.59289 2.78924 11.1193 3.31496 12.5161C3.84068 13.9129 4.79071 15.1099 6.03164 15.9391C7.27257 16.7682 8.74205 17.1879 10.2337 17.139C11.7254 17.0902 13.1643 16.5754 14.3483 15.6668C15.5323 14.7583 16.4021 13.5016 16.8353 12.0735C17.2685 10.6453 17.2435 9.11725 16.7638 7.704C16.2841 6.29075 15.3737 5.06327 14.1606 4.19395C12.9474 3.32463 11.4925 2.85714 10 2.85714C9.21102 2.85714 8.57143 2.21755 8.57143 1.42857C8.57143 0.639593 9.21102 -4.02627e-07 10 -4.37114e-07C12.0894 -5.28446e-07 14.1264 0.654483 15.8248 1.87153C17.5232 3.08858 18.7977 4.80706 19.4693 6.7856C20.1409 8.76415 20.1759 10.9034 19.5694 12.9028C18.9629 14.9023 17.7453 16.6616 16.0876 17.9335C14.43 19.2055 12.4155 19.9263 10.3272 19.9946C8.23888 20.063 6.1816 19.4755 4.4443 18.3147C2.707 17.1539 1.37695 15.478 0.640942 13.5225C-0.0950613 11.567 -0.200042 9.43005 0.340743 7.41181C0.544945 6.64971 1.32828 6.19745 2.09038 6.40166C2.85247 6.60586 3.30473 7.3892 3.10053 8.15129Z"
        />
      </svg>
    </div>
  );
};

export default Loader;
