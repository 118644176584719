import { ApiCallState, NetworkCallState } from "Models/Network/models";

export const updateApiCallStateAsLoading = (apiCallState: ApiCallState) => {
  apiCallState.loading = NetworkCallState.loading;
};

export const updateApiCallStateAsCompleted = (apiCallState: ApiCallState) => {
  apiCallState.loading = NetworkCallState.completed;
  apiCallState.error = false;
};

export const updateApiCallStateAsError = (apiCallState: ApiCallState) => {
  apiCallState.loading = NetworkCallState.completed;
  apiCallState.error = true;
};

export const updateApiCallStateMessage = (apiCallState: ApiCallState, message: string) => {
  apiCallState.message = message;
};
