import React, { useContext } from "react";
import Menu from "Modules/DS/Menu";

import { DatePicker } from "antd";
import moment from "moment";

import styles from "./index.module.scss";
import { ITrxnMoreFilterContext } from "Views/Transaction/@types";
import { TrxnMoreFilterContext } from "Views/Transaction/Context";

const { RangePicker } = DatePicker;

interface IDateFilterProps {
  startDateId: string;
  endDateId: string;
}

const DateFilter = ({ startDateId, endDateId }: IDateFilterProps) => {
  const { applyMoreFilter, clearMoreFilter, more } = useContext<ITrxnMoreFilterContext>(TrxnMoreFilterContext);

  const onRangeSelected = (_, [startDate, endDate]: [string, string]) => {
    if (startDate && endDate) {
      applyMoreFilter(startDateId, startDate);
      applyMoreFilter(endDateId, endDate);
    } else {
      clearMoreFilter(startDateId);
      clearMoreFilter(endDateId);
    }
  };

  const startDate = more?.[startDateId] ? moment(more[startDateId]) : undefined;
  const endDate = more?.[endDateId] ? moment(more[endDateId]) : undefined;

  return (
    <Menu.Row id={"date"} title={"Date"}>
      <RangePicker
        value={[startDate, endDate]}
        onChange={onRangeSelected}
        className={styles.rangePicker}
        placeholder={["Start Date", "End Date"]}
      />
    </Menu.Row>
  );
};

export default DateFilter;
