import { getCardSpendingLimit, getSpendingLockData, getCardType } from "utility/Card/index";
import { ICardDetails, CARD_REQUEST_STATUS, CARD_STATUS, CARD_TYPE, CARD_LIMIT_TYPES } from "Views/Card/types";
import { CardRequestDetailsType } from "Views/Card/Review/types";

import { parseToURLEncodedForm } from "utility/APIWrapper";
import APIclient from "API/Client";
import { GetOrgId } from "utility";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";

export const getCardStatus = (status, isCardRequestDetails) => {
  if (isCardRequestDetails) {
    return status === CARD_REQUEST_STATUS.REQUESTED ? CARD_REQUEST_STATUS.REQUESTED : CARD_REQUEST_STATUS.REJECTED;
  } else if ([CARD_STATUS.ENABLED, CARD_STATUS.NOT_ACTIVATED, CARD_STATUS.IN_PROGRESS].includes(status)) {
    return status;
  } else {
    return CARD_STATUS.DISABLED;
  }
};

const getShippingAddress = (billingAddress = "", country = "", zipCode = "") =>
  [billingAddress, country, zipCode]?.filter((addressInfo) => !!addressInfo)?.join(", ") || "";

export const getCardDetailsData = (cardData: any, isCardRequestDetails: boolean): ICardDetails => {
  const {
    is_physical = 0,
    card_name = "",
    name = "",
    cardholder_name = "",
    card_billing_address = "",
    country = "",
    zip_code = "",
    reject_reason = "",
    status = "",
    id = "",
    spending_limits = {},
    expense_category_id,
    expense_category_name,
    color = "",
    color_name = "",
    has_set_pin = false,
    card_last_four = "",
    card_expiry_year = "",
    card_expiry_month = "",
    card_holder_contact = "",
    card_status = "",
    manager_sets_limit = false,
    team_id = "",
    team_name = "",
    note_to_manager = "",
    tags = [],
    user_id = "",
  } = cardData || {};
  let teamName = team_name;
  let teamId = team_id;

  if (!isCardRequestDetails) {
    teamName = cardData?.team?.team_name || "";
    teamId = cardData?.team?.id || "";
  }
  const cardLimitsObj = getCardSpendingLimit(spending_limits);

  const {
    header_text = "",
    footer_text = "",
    limit_to_display = "",
    card_remaining_limit = "",
    card_total_limit = "",
    spendPercentage = 0,
  } = spending_limits || {};

  const cardStatus = getCardStatus(isCardRequestDetails ? status : card_status, isCardRequestDetails);
  const cardType = getCardType(is_physical);
  const isNotActivatedPhysicalCard = cardStatus === CARD_STATUS.NOT_ACTIVATED && cardType === CARD_TYPE.PHYSICAL;
  const spendingLockData = getSpendingLockData(spending_limits);

  return {
    id: id,
    cardType,
    cardTitle: card_name || name,
    cardHolder: cardholder_name,
    cardColor: color,
    cardColorName: color_name,
    team: teamName,
    teamId: teamId,
    spendingLimit: cardLimitsObj.cardLimit,
    spendingLimitType: cardLimitsObj.cardLimitType,
    expenseCategoryName: expense_category_name,
    expenseCategoryId: expense_category_id || expense_category_id === 0 ? +expense_category_id : null,
    shippingAddress: getShippingAddress(card_billing_address, country, zip_code),
    rejectionReason: reject_reason,
    cardStatus: cardStatus,
    isCardRequestDetails,
    isCardPinSet: has_set_pin,
    cardLastFourDigits: isNotActivatedPhysicalCard ? "XXXX" : card_last_four,
    cardExpiryMonth: card_expiry_month?.toString() || "",
    cardExpiryYear: card_expiry_year?.toString() || "",
    cardHolderContact: card_holder_contact,
    spendingAllowanceDetails: {
      headerText: header_text,
      footerText: footer_text,
      limitToDisplay: limit_to_display,
      cardRemainingLimit: card_remaining_limit,
      cardTotalLimit: card_total_limit,
      spendPercentage: spendPercentage,
    },
    letMyManagerChooseLimit: manager_sets_limit === 1,
    isNotActivatedPhysicalCard,
    noteToManager: note_to_manager,
    tags: tags,
    ...spendingLockData,
    cardUserId: user_id,
  };
};

export const getCardRequestReviewData = (record: ICardDetails): CardRequestDetailsType => {
  const {
    cardHolder,
    team,
    cardTitle,
    cardType,
    spendingLimit,
    spendingLimitType,
    spendingLocks,
    expenseCategoryId,
    shippingAddress,
    id,
    noteToManager,
    tags,
    letMyManagerChooseLimit,
  } = record || {};

  return {
    requestBy: cardHolder,
    team,
    memberNote: noteToManager,
    cardTitle,
    cardType,
    cardLimitType: letMyManagerChooseLimit ? CARD_LIMIT_TYPES.monthlyLimit : spendingLimitType,
    cardLimit: letMyManagerChooseLimit ? "" : spendingLimit,
    merchantLock: spendingLocks?.map((merchantData) => merchantData?.name),
    expenseCategoryId,
    address: shippingAddress,
    cardRequestId: id,
    tags,
  };
};

const helpAndStatusTextMapper = [
  { helpText: "disable", statusText: "disabled" },
  { helpText: "enable", statusText: "enabled" },
];

export const cardsBulkUpdate = async (selectedCards, changeToStatus, isAdmin) => {
  const orgId = GetOrgId();
  const url = isAdmin
    ? "/api/v1/org/card/status/bulk-update"
    : "/api/v1/org/card/status/bulk-update?adminManagerOnly=true";
  const helpAndStatusText = changeToStatus === "suspended" ? helpAndStatusTextMapper[0] : helpAndStatusTextMapper[1];
  try {
    const response = await APIclient.putData(
      url,
      parseToURLEncodedForm({
        status: changeToStatus,
        organisation_id: orgId,
        card_ids: JSON.stringify(selectedCards),
      }),
      false,
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
    const { data, status }: any = response || {};
    if (status === HTTP_STATUS_CODE.OK) {
      const successArray = Object.keys(data?.payload?.success || {});
      const errorArray = Object.keys(data?.payload?.errors || {});
      const successCount = successArray?.length;
      const errorCount = errorArray?.length;
      const totalCount = errorCount + successCount;
      const warningHelpText =
        successCount === totalCount || isAdmin
          ? ""
          : `Please contact the Budget Owner(s) to help ${helpAndStatusText.helpText} the rest.`;
      return {
        isSuccess: true,
        message: `${successCount} out of ${totalCount} cards successfully ${helpAndStatusText.statusText}. ${warningHelpText}`,
      };
    } else {
      throw new Error("Failed");
    }
  } catch {
    return {
      isSuccess: false,
      message: `Cards failed to be ${helpAndStatusText.statusText}`,
    };
  }
};
