import queryString from "query-string";

const useGetBillUrl = () => {
  const billUrl = "/bills/submitted";

  const billParamsBuilder = (query: { [key: string]: string }) =>
    queryString.stringifyUrl({
      url: billUrl,
      query,
    });

  return { billUrl, billParamsBuilder };
};

export default useGetBillUrl;
