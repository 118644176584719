import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { GetBaseAuthObject } from "../utility";
import * as Actions from "./Actions/dynamicActions";

export default function ReduxHOC(WrappedComponent, Name = "default", addStates = () => ({})) {
  return connect(
    (state) => ({
      dynamicData: state.dynamicData,
      teamId: state.teamReducer.selectedTeamId,
      splitio: state.splitio,
      orgId: state.userInfoReducer?.data?.payload?.user?.organisation_id,
      orgConfigs: state.orgConfigs,
      ...addStates(state),
    }),
    (dispatch) => ({
      reduxActions: bindActionCreators(Actions, dispatch),
    })
  )(function WrapperCompnent(wrapperProps) {
    const { dynamicData, reduxActions, teamId } = wrapperProps;
    const baseAuthObject = GetBaseAuthObject();
    const componentName = Name || WrappedComponent.name || WrappedComponent.displayName;
    const componentData = dynamicData[componentName] || Actions.BaseComponentState;

    let injectedprops = {
      data: componentData.data,
      error: componentData.error,
      loading: componentData.loading,
      general: {
        orgId: baseAuthObject.orgId,
        userId: baseAuthObject.userId,
        teamId,
      },
      updateData: (newData = {}) => {
        reduxActions.UpdateData(componentName, newData);
      },
      updateError: (error = {}) => {
        console.error(`Error in ${componentName}`, error);
        reduxActions.UpdateError(componentName, error);
      },
      toggleLoader: () => {
        reduxActions.ToggleLoader(componentName);
      },
      callApiWithLoader: (...args) => {
        const name = args.shift();
        console.log(`Calling API ${name} with loader and then updating the Data`);
        reduxActions.CallAPIWithLoader(componentName, name, args);
      },
      callApiWithoutLoader: (...args) => {
        const name = args.shift();
        console.log(`Calling API ${name} without loader and then updating the Data`);
        reduxActions.CallAPIWithoutLoader(componentName, name, args);
      },
    };
    let toBePassedProps = { ...wrapperProps };
    delete toBePassedProps.dynamicData;
    delete toBePassedProps.reduxActions;

    const combinedProps = Object.assign({}, toBePassedProps, injectedprops);

    return <WrappedComponent {...combinedProps} />;
  });
}
