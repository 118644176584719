import React, { useEffect, useState } from "react";
import { Row, Col, Form } from "antd";
import Button from "Modules/button";
import LoadingComponent from "Views/State/Loading/LoaderIcon";
import { rightArrowWhite16x16 } from "assets/img";
import useLoading from "utility/useLoading";
import { GetCurrencyCode } from "utility";
import { XeroAPI } from "Redux/DataCalls/Xero.api";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import XeroSyncAccounts from "Views/Accounting/Setup/Common/XeroSyncAccount";
import { AccountTypes, SyncAccountsConfig } from "constants/Integration.constant";

import styles from "./SelectXeroSyncAccount.module.scss";

const SelectXeroSyncAccount = ({ goToNextStep, bankAccounts = [], loading }) => {
  const [form] = Form.useForm();
  const getSpenmoBankAccount = () => {
    if (bankAccounts.length) {
      const spenmoBankAccount = bankAccounts.find((item) => item.name?.toLowerCase()?.includes("spenmo"));
      return spenmoBankAccount ? spenmoBankAccount?.id : "";
    }
    return "";
  };
  const [selectedBankAccount, setSelectedBankAccount] = useState(getSpenmoBankAccount());
  const [selectedTopUpAccount, setSelectedTopUpAccount] = useState("");
  const [updateXeroAccount, updateXeroAccountLoading] = useLoading(XeroAPI.updateXeroAccount);

  useEffect(() => {
    if (bankAccounts.length && !selectedBankAccount) {
      const spenmoBankAccountId = getSpenmoBankAccount();
      if (spenmoBankAccountId) {
        form.setFieldsValue({
          [SyncAccountsConfig[AccountTypes.spenmoAccount]?.formItemName]: spenmoBankAccountId,
        });
        setSelectedBankAccount(spenmoBankAccountId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bankAccounts, setSelectedBankAccount]);

  const onFinishHandler = async (values) => {
    const xeroBankAccountId = values[SyncAccountsConfig[AccountTypes.spenmoAccount]?.formItemName];
    const xeroBankAccount = bankAccounts.find((acc) => acc.id === xeroBankAccountId);

    const topUpBankAccountId = values[SyncAccountsConfig[AccountTypes.topUpAccount]?.formItemName];
    const topupBankAccount = bankAccounts.find((acc) => acc.id === topUpBankAccountId);

    const formData = {
      xero_bank_account_name: xeroBankAccount?.name || "",
      xero_bank_account_number: xeroBankAccount?.number,
      xero_bank_account_id: xeroBankAccountId,
      topup_bank_account: topupBankAccount.numbder,
      topup_bank_account_id: topUpBankAccountId,
      expense: true,
      currency: GetCurrencyCode(),
    };
    const response = await updateXeroAccount(formData);
    if (response?.data?.status === HTTP_STATUS_CODE.OK) {
      goToNextStep();
    }
  };

  const onChangeHandler = (value, accountType) => {
    accountType === AccountTypes.spenmoAccount && setSelectedBankAccount(value);
    accountType === AccountTypes.topUpAccount && setSelectedTopUpAccount(value);
  };

  const filterBankAccounts = (checkWithAccount) => {
    return checkWithAccount ? bankAccounts.filter((account) => account.id !== checkWithAccount) : bankAccounts;
  };

  if (loading) {
    return <LoadingComponent />;
  }
  return (
    <>
      <Row justify="start" className={styles.syncAccount}>
        <Form onFinish={onFinishHandler} form={form} className={styles.fullWidth}>
          <Col span={24}>
            <Row className={styles.container}>
              <XeroSyncAccounts
                accountType={AccountTypes.spenmoAccount}
                bankAccounts={filterBankAccounts(selectedTopUpAccount)}
                onChangeHandler={onChangeHandler}
                selectedBankAccount={selectedBankAccount}
                disabled={false}
              />
              <XeroSyncAccounts
                accountType={AccountTypes.topUpAccount}
                bankAccounts={filterBankAccounts(selectedBankAccount)}
                onChangeHandler={onChangeHandler}
                selectedBankAccount={selectedTopUpAccount}
                disabled={!selectedBankAccount}
              />
            </Row>
          </Col>
          <Col span={24} className={styles.syncCta}>
            <Form.Item>
              <Button
                htmlType="submit"
                size="xl"
                rounded
                disabled={!selectedBankAccount || !selectedTopUpAccount}
                loading={updateXeroAccountLoading}
              >
                Next
                <img src={rightArrowWhite16x16} alt="right arrow icon" />
              </Button>
            </Form.Item>
          </Col>
        </Form>
      </Row>
    </>
  );
};

export default SelectXeroSyncAccount;
