export const HTTP_STATUS_CODE = {
  /* 1xx - INFORMATIONAL */
  SWITCHING_PROTOCOLS: 101,

  /* 2xx - SUCCESS */
  OK: 200,
  CREATED: 201,
  ACCEPTED: 202,
  NO_CONTENT: 204,

  /* 3xx - REDIRECTION */
  MULTIPLE_CHOICES: 300,
  MOVED_PERMANENTLY: 301,
  MOVED_TEMPORARILY: 302,
  NOT_MODIFIED: 304,

  /* 4xx - CLIENT ERROR */
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  METHOD_NOT_ALLOWED: 405,
  GONE: 410,
  UNPROCESSABLE_ENTITY: 422,
  TOO_MANY_REQUESTS: 429,
  REQUEST_CONFLICT: 409,

  /* 5xx - SERVER ERRORS */
  INTERNAL_SERVER_ERROR: 500,
  NOT_IMPLEMENTED: 501,
  BAD_GATEWAY: 502,
  SERVICE_UNAVAILABLE: 503,
  GATEWAY_TIMEOUT: 504,
} as const;
