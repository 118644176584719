import React, { useMemo } from "react";
import cn from "classnames";
import { DatePicker } from "antd";
import { Typography, Checkbox } from "@spenmo/splice";
import { useFormContext, Controller } from "react-hook-form";
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";

import FormItem from "./FormItem";

import { customDateFormat } from "./helper";
import { FrequencyType } from "Views/Settings/@types";

import styles from "./PaymentRun.module.scss";

dayjs.extend(weekday);

const FrequencyDate: React.FC = () => {
  const { control, watch, setValue } = useFormContext();

  const [frequency, runOn, isEOM] = watch(["frequency", "runOn", "isEOM"]);

  const monthlyNotes = useMemo(() => {
    if (frequency !== FrequencyType.MONTHLY || isEOM) {
      return undefined;
    }

    const runOnDate = runOn && dayjs(runOn).format("DD");

    switch (runOnDate) {
      case "31": {
        return "Please note that bills shall be processed on the last day of the month";
      }
      case "30": {
        return "Please note that in February, bills shall be processed on the last day of the month.";
      }
      default: {
        return undefined;
      }
    }
  }, [frequency, isEOM, runOn]);

  const weekdays = useMemo(() => {
    const days = [];
    for (let i = 0; i < 7; i++) {
      days.push(
        dayjs()
          .weekday(i + 1)
          .format("dd")
      );
    }

    return days;
  }, []);

  if (!frequency) {
    return null;
  }

  const handleClickWeekdays = (weekdayIndex: string) => {
    setValue("runOn", weekdayIndex, { shouldValidate: true });
  };

  const handleChangeLastDay = (callbackFn: Function) => (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue("runOn", "");

    callbackFn(e);
  };

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < dayjs().endOf("day");
  };

  const label = {
    [FrequencyType.WEEKLY]: "Which day of the week?",
    [FrequencyType.FORTNIGHTLY]: "When would you like to start?",
    [FrequencyType.MONTHLY]: "Which date of the month?",
  };

  return (
    <FormItem label={label[frequency]} htmlFor="runOn">
      <Controller
        name="runOn"
        control={control}
        rules={{
          required: !isEOM,
        }}
        render={({ field }) => <input {...field} hidden aria-hidden />}
      />
      {frequency === FrequencyType.WEEKLY ? (
        <div className={styles.weekdaysContainer}>
          {weekdays.map((weekday, index) => {
            const weekdayIndex = String((index + 1) % 7);
            return (
              <button
                key={weekday}
                className={cn(styles.weekdaysBtn, {
                  [styles.active]: runOn === weekdayIndex,
                })}
                type="button"
                onClick={() => handleClickWeekdays(weekdayIndex)}
              >
                <Typography variant="body-content" size="caption-m" tag="p" weight={600}>
                  {weekday[0]}
                </Typography>
              </button>
            );
          })}
        </div>
      ) : (
        <DatePicker
          data-testid="datepicker"
          id="runOn"
          className={styles.field}
          placeholder="Select a date"
          disabledDate={disabledDate}
          format={customDateFormat(frequency)}
          disabled={isEOM}
          value={runOn}
          onChange={(date) => setValue("runOn", date, { shouldValidate: true })}
        />
      )}
      {frequency === FrequencyType.MONTHLY && (
        <>
          {monthlyNotes && (
            <Typography className={styles.space8} variant="body-content" size="caption-m" tag="p">
              {monthlyNotes}
            </Typography>
          )}
          <div className={styles.space12}>
            <Controller
              name="isEOM"
              control={control}
              render={({ field }) => (
                <Checkbox
                  {...field}
                  data-testid="lastDateOfMonth"
                  size="s"
                  checked={field.value}
                  onChange={handleChangeLastDay(field.onChange)}
                >
                  Set to last date of month
                </Checkbox>
              )}
            />
          </div>
        </>
      )}
    </FormItem>
  );
};

export default FrequencyDate;
