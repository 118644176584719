import { generateDownloadFilename } from "utility";

export const downloadPDF = (data: Iterable<number>, filename: string) => {
  const pdfFileName = generateDownloadFilename(filename, "pdf");

  const byteArray = new Uint8Array(data);
  const a = window.document.createElement("a");

  a.href = window.URL.createObjectURL(new Blob([byteArray], { type: "application/pdf" }));
  a.download = pdfFileName;

  // Append anchor to body.
  document.body.appendChild(a);
  a.click();

  // Remove anchor from body
  document.body.removeChild(a);
};

export const decodeArrayBuffer = (data: Iterable<number>) => {
  const uint8Array = new Uint8Array(data);
  const decoder = new TextDecoder();
  const jsonString = decoder.decode(uint8Array);

  return JSON.parse(jsonString);
};
