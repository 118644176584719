export const TEAM_TYPE = {
  ORGANISATION: "Organisation",
  TEAM: "Team",
};

export const USER_ROLE = {
  ACCOUNTANT: "Accountant",
  ADMIN: "Admin",
  MANAGER: "Manager",
  MEMBER: "Member",
  BOOKKEEPER: "Bookkeeper",
  EMPLOYEE: "Employee",
  AUDITOR: "Auditor",
};

export const USER_MEMBERSHIP = {
  MEMBER: "Member",
  NON_MEMBER: "Non-Member",
};

export const DEFAULT_LIMIT_VALUES = {
  NO_LIMIT_SET: "-1",
  REVERT_THE_LIMIT: "-2",
};
export const LIMIT_TYPES = {
  MONTHLY_LIMIT: "monthly_limit",
  YEARLY_LIMIT: "yearly_limit",
  TOTAL_LIMIT: "total_limit",
  CARD_TRANSACTION_LIMIT: "card_transaction_limit",
  UNLIMITED: "none",
};

export const USER_ROLE_DISPLAY = {
  [USER_ROLE.ACCOUNTANT]: "ACCOUNTANT",
  [USER_ROLE.ADMIN]: "ADMIN",
  [USER_ROLE.MANAGER]: "Owner",
  [USER_ROLE.MEMBER]: "Member",
  [USER_ROLE.BOOKKEEPER]: "Bookkeeper",
  [USER_ROLE.EMPLOYEE]: "Employee",
  [USER_ROLE.AUDITOR]: "Auditor",
};
