import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import SpDatePicker from "Modules/SpDatePicker";
import "./SendMoney.scss";
import Checkbox from "antd/lib/checkbox/Checkbox";
import DetailDropdown from "Modules/DetailDropdown";
import { encodeValue, parseValue, RECURRING_OPTIONS, createListItem } from "./ScheduleHelper";

const ScheduleForm = ({ isEdit, initialValues, setValues = () => {} }) => {
  const [showSchedule, setShowSchedule] = useState(isEdit || false);
  const [scheduleDateTime, setScheduleDateTime] = useState(moment());
  const [scheduleRepeat, setScheduleRepeat] = useState(RECURRING_OPTIONS[0].value);
  const [scheduleRetry, setScheduleRetry] = useState(false);

  useEffect(() => {
    if (initialValues) {
      const { nextDate, isRetriable, type, endsAfter, interval, intervalType } = initialValues;
      if (nextDate) {
        setScheduleDateTime(moment(nextDate?.replace("Z00:00", "Z")));
      }
      if (isRetriable) {
        setScheduleRetry(isRetriable);
      }
      setScheduleRepeat(
        encodeValue({
          interval,
          intervalType,
          type,
          endsAfter,
        })
      );
    }
  }, [initialValues]);

  useEffect(() => {
    if (!showSchedule) {
      setValues(null);
    } else {
      setValues({
        ...parseValue(scheduleRepeat),
        start: scheduleDateTime?.toISOString?.() + "00:00",
        isRetriable: scheduleRetry,
      });
    }
  }, [showSchedule, scheduleDateTime, scheduleRepeat, scheduleRetry, setValues]);

  return (
    <div className={`send-money__modal__schedule ${showSchedule || isEdit ? "open" : ""}`}>
      {isEdit ? (
        <div>Payment Recurrence</div>
      ) : (
        <Checkbox
          className="send-money__modal__schedule-checkbox"
          onChange={(e) => {
            setShowSchedule(e.target.checked);
          }}
          checked={showSchedule}
        >
          Payment Recurrence
        </Checkbox>
      )}
      {(isEdit || showSchedule) && (
        <div className="send-money__modal__schedule-form">
          <div>
            <SpDatePicker
              className="schedule-date"
              hasTimePicker
              disabledDate={(current) => {
                const date = moment(current);
                return date.endOf("day") < moment().endOf("day");
              }}
              validateTime={(value) => {
                if (value.diff(moment(), "minutes") < 0) {
                  return "Can't pick earlier than now";
                }
                return false;
              }}
              action={(value) => {
                setScheduleDateTime(value);
              }}
              defaultDate={isEdit ? scheduleDateTime : null}
            />
            <DetailDropdown
              className="schedule-repeat"
              action={(value) => {
                setScheduleRepeat(value);
              }}
              options={RECURRING_OPTIONS}
              initialValue={scheduleRepeat}
              createListItem={createListItem}
            />
          </div>
          <Checkbox
            className="schedule-retry"
            checked={scheduleRetry}
            onChange={(e) => {
              setScheduleRetry(e.target.checked);
            }}
          >
            Retry once if failed (in 24 hours).
          </Checkbox>
        </div>
      )}
    </div>
  );
};

ScheduleForm.propTypes = {
  isEdit: PropTypes.bool,
  initialValues: PropTypes.object,
  values: PropTypes.object,
  setValues: PropTypes.func,
};

export default ScheduleForm;
