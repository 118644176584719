import React from "react";
import { Form } from "antd";

import Input from "Modules/DS/Input";

import { IKYXInput } from "Views/KYX/@types";

export const KYXInput = ({ formProps, inputProps }: IKYXInput): JSX.Element => {
  return (
    <Form.Item {...formProps}>
      <Input {...inputProps} />
    </Form.Item>
  );
};
