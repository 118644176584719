import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Form } from "antd";
import DropDown from "Modules/dropDown";
import SelectAmount from "Modules/selectAmount";
import { getCurrencyFunc, allWalletFunc } from "Redux/Actions";
import { currencyFormatterWithFractions, getParamsValue } from "utility";
import "./SendMoney.scss";
import Button from "Modules/button";
import LoadingIcon from "Modules/loading";
import ScheduleForm from "./ScheduleForm";
import { trackEvent } from "utility/analytics";
import { QueryParamsKeys } from "constants/MoneyQueryParams.constants";

const SendMoneyForm = ({ receiver, action, isTeam, initialValues = {}, isEdit, trackActions = false }) => {
  const dispatch = useDispatch();

  const selectedTeam = useSelector((state) => state.teamReducer?.data?.payload?.team);

  const walletData = useSelector((state) => state.wallet.data?.collection?.filter((e) => Boolean(e.name) && e));
  const { wdata: allWalletsData = [], loading: allWalletsDataLoading } = useSelector((state) => state.wallet);

  const getInitialSelectedWalletId = () => {
    const [initialWalletId] = getParamsValue(QueryParamsKeys.INITITAL_WALLET_ID);
    return initialWalletId ? initialWalletId : null;
  };

  const [walletId, setWalletId] = useState(getInitialSelectedWalletId());
  const [wallet, setWallet] = useState({});
  const [currency, setCurrency] = useState(initialValues?.currency || null);
  const [amount, setAmount] = useState(initialValues?.amount || 0);
  const [schedule, setSchedule] = useState({});
  const amountRaw = [10, 25, 50, 100, 250, 500];

  useEffect(() => {
    const { amount, currency } = initialValues || {};

    if (amount) {
      setAmount(amount);
    }
    if (currency) {
      setCurrency(currency);
    }
  }, [initialValues]);

  useEffect(() => {
    dispatch(allWalletFunc());
    dispatch(getCurrencyFunc());
  }, [dispatch]);

  const setSelectedWallet = () => {
    const localWalletData = getWalletData();
    if (Array.isArray(localWalletData) && localWalletData?.length) {
      if (walletId) {
        const filteredWallet = localWalletData?.find?.((item) => item?.id === walletId);
        setWallet(filteredWallet);
      } else {
        setWallet(localWalletData[0]);
      }
    }
  };

  useEffect(() => {
    setSelectedWallet();
  }, [walletData, walletId, allWalletsData]);

  useEffect(() => {
    setCurrency(wallet?.currency_code);
  }, [wallet]);

  const getWalletData = () => {
    return allWalletsDataLoading ? [] : allWalletsData;
  };

  const resetForm = () => {
    setAmount(0);
    setWallet(walletData[0]);
    setSchedule({});
  };

  const handleAction = () => {
    const payload = {
      wallet,
      amount,
      currency,
    };
    if (schedule || isEdit) {
      payload.schedule = schedule;
    }
    action(payload);
    trackSendMoneyActions("send money confirm clicked");
    resetForm();
  };

  const trackSendMoneyActions = (eventName, data = {}) => {
    trackActions &&
      trackEvent(eventName, {
        ...data,
        "recipient type": isTeam ? "team" : "colleague",
      });
  };

  const btnStatus = () => {
    return !(receiver?.length || isEdit) || !amount;
  };

  const getWalletDropDown = () => {
    const walletDataToRender = getWalletData();
    if (walletDataToRender?.length) {
      return (
        <div className={"amount-details__select-wallet--details"}>
          <DropDown
            action={(value) => {
              setWalletId(value);
              trackSendMoneyActions("send money from wallet selected");
            }}
            isWallet
            listData={walletDataToRender}
            initialValue={walletId}
            team={selectedTeam?.team_name}
            disabled={isEdit}
            showAmount
            showCurrency
            showAmountInDecimals
          />
          <h3> {currencyFormatterWithFractions(wallet?.amount, wallet?.currency_code, 2, false)} </h3>
        </div>
      );
    } else {
      return <LoadingIcon className="send-money__modal__form-container__loader" />;
    }
  };
  return (
    <div className="send-money__modal__form">
      <Form>
        <div className="send-money__modal__form-container">
          <Form.Item label="Select Budget" className="send-money__modal__form-container__title">
            {isEdit && initialValues?.senderType && initialValues?.senderId && initialValues?.senderName ? (
              <div className="amount-details__wallet-label">
                <span className="amount-details__wallet-label__type">
                  {initialValues?.senderType === "USER" ? "Personal Wallet" : "Budget"}
                </span>
                <span className="amount-details__wallet-label__name">{initialValues.senderName}</span>
              </div>
            ) : (
              <>{getWalletDropDown()}</>
            )}
          </Form.Item>
          <Form.Item
            label="Amount of"
            className={"amount-details__select-amount"}
            rules={[{ required: true, message: "Please Enter the Amount!" }]}
          >
            <SelectAmount
              initialCurrency={wallet?.currency_code}
              initialValue={amount}
              showAmountOptions
              amountOptions={amountRaw}
              currencyAction={(value) => {
                setCurrency(value);
                trackSendMoneyActions("send money currency selected");
              }}
              action={(value) => {
                setAmount(value);
              }}
              trackActions={trackActions}
              inputOnBlurHandler={(value) => trackSendMoneyActions("send money amount entered", { amount: value })}
            />
            <p>Enter amount you want to request or tap any of the suggested amounts</p>
          </Form.Item>
        </div>
        <ScheduleForm isEdit={isEdit} initialValues={initialValues?.schedule} setValues={setSchedule} />
        <div className="send-money__modal__action">
          <Button disabled={btnStatus()} text={isEdit ? "Save" : "Send"} action={handleAction} rounded />
        </div>
      </Form>
    </div>
  );
};

SendMoneyForm.propTypes = {
  action: PropTypes.func,
  isEdit: PropTypes.bool,
  isTeam: PropTypes.bool,
  initialValues: PropTypes.object,
  receiver: PropTypes.array,
  trackActions: PropTypes.bool,
};

export default SendMoneyForm;
