export enum RE_KYB_LABELS {
  LAST_DAY_MESSAGE = "Last day to update your company data to avoid losing access to Spenmo.",
  UPDATE_COMPANY_DATA = "Update Company Data.",
  UPDATE_BEFORE_EXPIRY = "Update your company data now to avoid losing access to Spenmo.",
  REJECTED_DATA = "Company verification rejected. To avoid losing access to Spenmo, contact ",
}

export enum ReKYBLinks {
  supportLink = "https://support.spenmo.com/hc/en-us/requests/new",
}

export * from "./UpdateModal";
