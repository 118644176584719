import { createContext, useContext } from "react";
import { IAccountingTransactionsContext } from "../@types";

export const initialAppliedFiltersContext: IAccountingTransactionsContext = {
  activePageDetails: null,
  setActivePageDetails: () => null,
};

export const AccountingTransactionsContext = createContext(initialAppliedFiltersContext);

export const useAccountingTransaction = () => useContext<IAccountingTransactionsContext>(AccountingTransactionsContext);
