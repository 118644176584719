import React, { useMemo, useRef } from "react";
import { Popover } from "antd";

import styles from "./BarChart.module.scss";
import { convertToPercentage, SGDCurrencyFormatter } from "Views/Analytics/utils";
import { CategoryCard } from "./CategoryCard";

export function BarChart({ data, colors, totalRow, total }) {
  const barRefs = useRef({});

  const categories = useMemo(() => {
    const topTenCategories = data.length > 10 ? data.slice(0, 10) : data;
    const firstFiveCategories = topTenCategories.length > 5 ? topTenCategories.slice(0, 5) : topTenCategories;
    const otherCategories = topTenCategories
      .slice(5, topTenCategories.length)
      .map((item) => ({ ...item, color: "#C5C7CD" }));

    let otherCategory = {
      categoryName: "",
      amount: 0,
      color: "",
    };

    if (otherCategories.length > 0) {
      otherCategory = otherCategories.reduce((acc, curVal, curInd) => {
        return {
          categoryName: "Others",
          amount: acc.amount + curVal.amount,
          color: "#AEC6E4",
        };
      });
    }

    return {
      total: [...firstFiveCategories, { ...otherCategory }, ...otherCategories].filter((item) => item.color !== ""),
      others: otherCategories,
    };
  }, [data]);

  return (
    <div className={styles.container} style={{ width: `${convertToPercentage(totalRow / total)}` }}>
      {categories.total.slice(0, 6).map((item, index) => (
        <div
          key={item.categoryName}
          className={styles.subContainer}
          style={{ width: `${convertToPercentage(item.amount / totalRow)}` }}
        >
          <Popover
            placement="bottom"
            content={
              <CategoryCard
                name={item.categoryName}
                color={colors[item.categoryName]}
                amount={SGDCurrencyFormatter(item.amount, 2, "currency")}
                others={categories.others.length > 0 && item.categoryName === "Others" ? categories.others : []}
              />
            }
          >
            <div
              ref={(el) => (barRefs.current[index] = el)}
              className={styles.bar}
              style={{ background: colors[item.categoryName] }}
            />
          </Popover>
        </div>
      ))}
    </div>
  );
}
