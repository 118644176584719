import { FETCH_CREATE_REQUEST, FETCH_CREATE_SUCCESS, FETCH_CREATE_FAILURE } from "Redux/Actions/Category/create";
import { userInitialState } from "../../InitialState";

export const categoryCreateReducer = (state = userInitialState, action) => {
  switch (action.type) {
    case FETCH_CREATE_REQUEST:
      return {
        ...state,
        createData: action.data,
        loading: true,
        error: false,
      };
    case FETCH_CREATE_SUCCESS:
      return {
        ...state,
        createData: action.data,
        loading: false,
        error: false,
      };
    case FETCH_CREATE_FAILURE:
      return {
        ...state,
        loading: false,
        createData: action.data,
        error: true,
      };
    default:
      return state;
  }
};
