import classNames from "classnames";
import React from "react";
import { TEAM_ACTION } from "Views/Team/List/Actions/constant";
import { useTeamActionContext } from "Views/Team/List/Actions/context";
import styles from "./index.module.scss";
import { isActionDisabled, isDeleteActionActive, isRenameActionActive } from "Views/Team/helper";
import { ITeam } from "Views/Team/type";

const TeamListAction = ({ value, label, team }: { value: TEAM_ACTION; label: string; team: ITeam }) => {
  const { selectAction, selectTeam } = useTeamActionContext();
  const actionDisabled = isActionDisabled(value, team);
  const actionStyle = classNames(styles.action, {
    [styles["inactive"]]: actionDisabled,
    [styles["action-delete"]]: isDeleteActionActive(value, team),
    [styles["action-rename"]]: isRenameActionActive(value, team),
  });
  const handleMenuClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!actionDisabled) {
      selectAction(value);
      selectTeam(team);
    }
  };

  return (
    <div className={actionStyle} onClick={handleMenuClick} key={value}>
      {label}
    </div>
  );
};

export default TeamListAction;
