import { userInitialState } from "Redux/InitialState";
import {
  TERMS_CONDITIONS_SUCCESS,
  TERMS_CONDITIONS_ERROR,
} from "Redux/Actions/TermsAndConditions/termsAndConditionsForNewUserAction";

export const termsAndConditionsForNewUserReducer = (state = userInitialState, action) => {
  switch (action.type) {
    case TERMS_CONDITIONS_SUCCESS:
      return {
        ...state,
        data: action?.data,
      };
    case TERMS_CONDITIONS_ERROR:
      return {
        ...state,
        data: action?.data,
        error: true,
      };
    default:
      return state;
  }
};
