import * as React from "react";
import { Skeleton } from "antd";

import "./index.scss";

const CardsShimmer = () => {
  return (
    <>
      <div data-testid="shimmer-cards-header" className="shimmer-cards-header">
        <h2 className="title">My Cards</h2>
        <Skeleton.Button className="shimmer-view-btn" active shape="square" />
        <Skeleton.Button className="shimmer-add-btn" active shape="square" />
      </div>
      <div data-testid="shimmer-card-container" className="shimmer-card-container">
        {Array.from({ length: 4 })?.map((key, index) => (
          <div className="card" key={index}>
            <Skeleton.Avatar className="avatar" active shape="square" />
            {Array.from({ length: 4 })?.map((key, index) => (
              <Skeleton.Button className={`paragraph-${index}`} key={index} active shape="square" />
            ))}
          </div>
        ))}
      </div>
    </>
  );
};

export default CardsShimmer;
