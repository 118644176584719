import React, { useContext } from "react";

import RichHeader from "Modules/DS/RichHeader";
import { IKYXModalContext } from "Views/KYX/@types";
import { KYXModalContext } from "Views/KYX/Context";

import { errorIcon } from "assets/img";
import styles from "./index.module.scss";
import Icon from "Modules/icons";

const AccessDeniedModal: React.FC = () => {
  const { resetModal, modal }: IKYXModalContext = useContext(KYXModalContext);

  const { payload } = modal;

  return (
    <div className={styles.wrapper}>
      <RichHeader title="Access Denied" onClose={resetModal} />
      <div className={styles.content}>
        <Icon className={styles.icon} src={errorIcon} alt={"error icon"} />
        <p className={styles.message}>
          Your account is not authorized to verify this shareholder information. Please make sure to use the correct
          Singpass account for {payload?.errorFields}
        </p>
      </div>
    </div>
  );
};

export default AccessDeniedModal;
