import { Form } from "antd";

import { EInputType, TransactionForm } from "Modules/TransactionDetail";

import { useTrxnUpdate } from "Views/Transaction/Details";
import { removeDuplicates } from "Views/Transaction/Helper";

export const useExpenseForm = (
  trxnNumber: string,
  closeDetails: () => void,
  receipts: string[],
  notes: string,
  taxId: string,
  categoryId: string,
  trxnId?: string,
  tags?: { label: string; value: string }[],
  expenseReceipts?: { id: string; url: string }[],
  subscriptionId?: string
) => {
  const [form] = Form.useForm();

  const updateTrxn = useTrxnUpdate(trxnNumber, closeDetails, trxnId);

  const buttonAction = async () => {
    const validate = form.isFieldsTouched([EInputType.CATEGORY, EInputType.NOTES, EInputType.RECEIPT, EInputType.TAX]);
    if (!validate) return;

    await updateTrxn(
      form.getFieldValue(EInputType.RECEIPT),
      form.getFieldValue(EInputType.NOTES),
      form.getFieldValue(EInputType.CATEGORY),
      form.getFieldValue(EInputType.TAX),
      form.getFieldValue(EInputType.TRANSACTION_TAGS),
      form.getFieldValue("deleting_receipt_ids"),
      form.getFieldValue(EInputType.SUBSCRIPTION)
    );
  };

  const setFormValue = (val: TransactionForm) => {
    form.setFieldsValue({
      [EInputType.TAX]: val.tax,
      [EInputType.NOTES]: val.notes,
      [EInputType.CATEGORY]: val.category,
      [EInputType.RECEIPT]: val.receipt.filter((item) => !receipts.includes(item)),
      [EInputType.TRANSACTION_TAGS]: val.transactionTags,
    });
  };

  const defaultValue: TransactionForm = {
    [EInputType.TAX]: taxId,
    [EInputType.NOTES]: notes.trim(),
    [EInputType.CATEGORY]: categoryId,
    [EInputType.RECEIPT]: removeDuplicates(receipts),
    [EInputType.TRANSACTION_TAGS]: tags,
    [EInputType.SUBSCRIPTION]: subscriptionId,
  };

  const onDeleteFile = (url) => {
    const findReceiptId = expenseReceipts.find((receipt) => receipt.url === url);

    if (findReceiptId) {
      form.setFieldsValue({
        deleting_receipt_ids: (form.getFieldValue("deleting_receipt_ids") || []).concat(findReceiptId.id),
      });
    }
  };

  return { form, buttonAction, setFormValue, defaultValue, onDeleteFile };
};
