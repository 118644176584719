import "Modules/loading/loading3Quaters.scss";

import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

const Loading3Quaters = ({ color, size }) => {
  const _className = classNames("loading3quaters", {
    [`loading3quaters--${color}`]: color,
    [`loading3quaters--${size}`]: size,
  });
  return <div className={_className} data-testid="loading3quaters" />;
};

Loading3Quaters.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
};

export default Loading3Quaters;
