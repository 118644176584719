import React from "react";
import PropTypes from "prop-types";

import { FilterById } from "utility";

import { analytics, FilterComponents } from "Modules/Filters";

export const EmployeeFilter = ({ listData, selectedEmployee, dispatchAction, formItem }) => {
  return (
    <FilterComponents.SearchDropDownFilter
      title="Employee"
      formItem={formItem ? formItem : "user_id"}
      listData={listData || []}
      appendClassName="employees"
      dispatchAction={dispatchAction}
      eventName={analytics.employeeSelected}
      placeholder="Select Employee"
    >
      {selectedEmployee && FilterById(selectedEmployee, listData)[0]?.name}
    </FilterComponents.SearchDropDownFilter>
  );
};

EmployeeFilter.propTypes = {
  listData: PropTypes.array,
  formItem: PropTypes.string,
  dispatchAction: PropTypes.func,
  selectedEmployee: PropTypes.string,
};
