import * as React from "react";
import { useContext, useEffect } from "react";
import { Select, Col, Form } from "antd";
import { useDispatch, useSelector } from "react-redux";

import { fetchMerchantList, resetMerchantListData } from "Redux/Actions/Merchant/search";

import { debounce } from "utility";

import { FiltersAPI, FilterComponents } from "Modules/Filters";

const { Option } = Select;

const DEBOUNCE_DELAY_TIME = 500;

export const MerchantFilter = ({
  title,
  formItem,
  eventName,
  placeholder,
  appendClassName,
  selectedMerchant,
}: IMerchantFilter) => {
  const dispatch = useDispatch();
  const merchantElasticSearchListData = useSelector(
    (state: any) => state?.merchantListReducer?.data?.payload?.merchants || []
  );
  useEffect(() => {
    return () => {
      dispatch(resetMerchantListData());
    };
  }, []);
  const {
    handleDropDownActions,
  }: { handleDropDownActions: (newValue: string, formItem: string, eventName: string) => void } =
    useContext(FiltersAPI);
  const handleSearch = debounce((value: string) => {
    if (value) {
      dispatch(fetchMerchantList(value));
    }
  }, DEBOUNCE_DELAY_TIME);

  const handleChange = (newValue: string) => {
    handleDropDownActions(newValue, formItem, eventName);
  };

  const options = merchantElasticSearchListData.map((d: string) => (
    <Option value={d} key={d}>
      {d}
    </Option>
  ));

  return (
    <FilterComponents.FilterItemWrapper appendClassName={appendClassName} title={title}>
      <Col>
        <Form.Item name={formItem}>
          <Select
            placeholder={placeholder}
            showSearch
            value={selectedMerchant}
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            onSearch={handleSearch}
            onChange={handleChange}
            notFoundContent={null}
            allowClear
          >
            {options}
          </Select>
        </Form.Item>
      </Col>
    </FilterComponents.FilterItemWrapper>
  );
};

interface IMerchantFilter {
  title: string;
  formItem: string;
  eventName: string;
  placeholder: string;
  appendClassName: string;
  selectedMerchant: string;
}
