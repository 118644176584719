const green_16 = require("assets/v1.1/icons/Core/Check/green_16.svg");
const green_18 = require("assets/v1.1/icons/Core/Check/green_18.svg");

const white_18 = require("assets/v1.1/icons/Core/Check/white_18.svg");

const fill_12 = require("assets/v1.1/icons/Core/Check/fill_12.svg");
const fill_18 = require("assets/v1.1/icons/Core/Check/fill_18.svg");

export const fillCheck = require("assets/v1.1/icons/Core/Check/fill-check.svg") + "#fill";
export const outlineCheck = require("assets/v1.1/icons/Core/Check/outline.svg") + "#outline";

export const Check = {
  Line: { 16: green_16 },
  Fill: { 12: fill_12, 18: fill_18, green_18: green_18, white_18: white_18 },
};
