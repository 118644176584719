import APIclient from "API/Client";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import { getDispatchObject, getFailureDispatchObj } from "Redux/Actions/SecurityHub/GoogleAuthenticator/utilites";

export const FETCH_GA_QR_CODE = "FETCH_GA_QR_CODE";
export const FETCH_GA_QR_CODE_REQUEST = `${FETCH_GA_QR_CODE}_REQUEST`;
export const FETCH_GA_QR_CODE_SUCCESS = `${FETCH_GA_QR_CODE}_SUCCESS`;
export const FETCH_GA_QR_CODE_FAILURE = `${FETCH_GA_QR_CODE}_FAILURE`;

export const VERIFY_GA_AUTHENTICATION_KEY = "VERIFY_GA_AUTHENTICATION_KEY";
export const VERIFY_GA_AUTHENTICATION_KEY_REQUEST = `${VERIFY_GA_AUTHENTICATION_KEY}_REQUEST`;
export const VERIFY_GA_AUTHENTICATION_KEY_SUCCESS = `${VERIFY_GA_AUTHENTICATION_KEY}_SUCCESS`;
export const VERIFY_GA_AUTHENTICATION_KEY_FAILURE = `${VERIFY_GA_AUTHENTICATION_KEY}_FAILURE`;

export const GA_RESET = "GOOGLE_AUTHENTICATOR_DATA_RESET";

export const fetchGaQrCode = () => {
  return (dispatch) => {
    dispatch(getDispatchObject(FETCH_GA_QR_CODE_REQUEST));
    APIclient.getData("/ms/b2b-auth/v1/2fa/initiate")
      .then((response) => {
        const { data = {} } = response || {};
        if (response?.status === HTTP_STATUS_CODE.OK) {
          dispatch(getDispatchObject(FETCH_GA_QR_CODE_SUCCESS, data));
        } else {
          dispatch(getFailureDispatchObj(FETCH_GA_QR_CODE_FAILURE, data));
        }
      })
      .catch((err) => {
        dispatch(
          getFailureDispatchObj(
            FETCH_GA_QR_CODE_FAILURE,
            err?.response?.data,
            "Unable to fetch QR code. Please try again later."
          )
        );
      });
  };
};

export const verifyGaAuthenticationCode = (payload) => {
  return (dispatch) => {
    dispatch(getDispatchObject(VERIFY_GA_AUTHENTICATION_KEY_REQUEST));
    APIclient.postData("/ms/b2b-auth/v1/2fa/enable2FA", payload)
      .then((response) => {
        const { data = {}, status } = response || {};
        if (status === HTTP_STATUS_CODE.OK) {
          dispatch(getDispatchObject(VERIFY_GA_AUTHENTICATION_KEY_SUCCESS, { data, status }));
        } else {
          dispatch(getFailureDispatchObj(VERIFY_GA_AUTHENTICATION_KEY_FAILURE, data));
        }
      })
      .catch((err) => {
        dispatch(getFailureDispatchObj(VERIFY_GA_AUTHENTICATION_KEY_FAILURE, err?.response?.data));
      });
  };
};

export const resetGAData = {
  type: GA_RESET,
};
