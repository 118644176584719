import * as React from "react";
import { whiteLoadingIcon24x24, redLoadingIcon24x24, whiteLoadingIcon16x16 } from "assets/icons/card";

import "Views/Card/Components/Loader/index.scss";

export enum LoaderTypes {
  white = "white",
  white_16x16 = "white_16x16",
  red = "red",
}

const LoadingIconsAndTypeMap = {
  [LoaderTypes.white]: whiteLoadingIcon24x24,
  [LoaderTypes.red]: redLoadingIcon24x24,
  [LoaderTypes.white_16x16]: whiteLoadingIcon16x16,
};

interface ILoaderProps {
  type: LoaderTypes;
}

const Loader = ({ type }: ILoaderProps) => {
  const getWidthAndHeight = () => {
    if ([LoaderTypes.white, LoaderTypes.red].includes(type)) {
      return {
        width: 24,
        height: 24,
      };
    } else {
      return {
        width: 16,
        height: 16,
      };
    }
  };

  const imgWidthAndHeight = getWidthAndHeight();
  return (
    <img
      src={LoadingIconsAndTypeMap[type]}
      width={imgWidthAndHeight.width}
      height={imgWidthAndHeight.height}
      className="cards-loader"
      data-testid="loader"
      alt="loader icon"
    />
  );
};
export default Loader;
