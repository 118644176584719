import APIclient from "API/Client";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import { setCookie } from "utility/CookieHelper";

export const CHANGE_PHONE_NUMBER = "CHANGE_PHONE_NUMBER";
export const CHANGE_PHONE_NUMBER_REQUEST = `${CHANGE_PHONE_NUMBER}_REQUEST`;
export const CHANGE_PHONE_NUMBER_SUCCESS = `${CHANGE_PHONE_NUMBER}_SUCCESS`;
export const CHANGE_PHONE_NUMBER_FAILURE = `${CHANGE_PHONE_NUMBER}_FAILURE`;
export const RESET_CHANGE_PHONE_NUMBER = `RESET_${CHANGE_PHONE_NUMBER}`;

const fetchDataRequest = (data, comp) => {
  return {
    type: CHANGE_PHONE_NUMBER_REQUEST,
    data: data,
  };
};
export const resetChangePhoneNumber = () => {
  return {
    type: RESET_CHANGE_PHONE_NUMBER,
  };
};
const fetchDataSuccess = (data) => {
  return {
    type: CHANGE_PHONE_NUMBER_SUCCESS,
    data: data,
  };
};
const fetchDataError = (data) => {
  return {
    type: CHANGE_PHONE_NUMBER_FAILURE,
    data: data,
  };
};

export const changePhoneNumber = (dataObj = {}, componentName = "default") => {
  return (dispatch) => {
    dispatch(fetchDataRequest());
    APIclient.putData("/api/v1/onboarding/change-phone-number", dataObj)
      .then((data) => {
        const { status_message, is_success } = data.data.payload;
        if (data.data.status > HTTP_STATUS_CODE.OK) {
          dispatch(fetchDataError(status_message));
          return Promise.reject(status_message);
        } else {
          dispatch(fetchDataSuccess({ status_message, is_success }));
          setCookie(
            "mobile_number",
            `${dataObj.mobile_country_code}${dataObj.mobile_number}`,
          );
          return data;
        }
      })
      .catch((err) => {
        const errorMessage =
          err || "Unable to change phone number. Internal Server Error";
        dispatch(fetchDataError(errorMessage, componentName));
      });
  };
};
