import * as React from "react";
import classNames from "classnames";

import { IStatusChipProps, CHIP_STATUS, TEST_ID } from "Modules/DS/Chip";

import styles from "./Status.module.scss";

export const StatusChip = ({ title, status = CHIP_STATUS.SUCCESS, secondary = false }: IStatusChipProps) => {
  return (
    <div className={classNames(styles.container, styles[status], secondary ? styles.secondary : "")}>
      <div data-testid={TEST_ID.INDICATOR} className={styles.indicator} />
      {title}
    </div>
  );
};
