import React from "react";
import { Row, Col, Form, Select } from "antd";
import { circleClose } from "assets/img";

import style from "./styles.module.scss";

const { Option } = Select;

export const List = ({ categories = [], xeroCategoriesAccountNumbers = [], xeroCategories = [] }) => {
  const getOptions = () => {
    return xeroCategories.map((category) => (
      <Option key={category.number} value={category.number}>
        {`${category?.number} - ${category?.name}`}
      </Option>
    ));
  };

  return (
    <>
      <Row justify="space-between" align="middle">
        <Col>
          <label>SPENMO CATEGORIES</label>
        </Col>
        <Col>
          <label>CHART OF ACCOUNTS</label>
        </Col>
      </Row>
      <div className={style.selection}>
        {categories?.map((category, index) => (
          <Row key={category.id} justify="space-between" align="middle" className={style.container}>
            <Col className={style.category}>{category?.category_name}</Col>
            <Col span={10}>
              <Form.Item
                name={category.id}
                id="XeroAccountCode"
                initialValue={
                  (xeroCategoriesAccountNumbers?.includes(category?.accounting_system_code) &&
                    category?.accounting_system_code) ||
                  undefined
                }
                className="sp-margin-0"
              >
                <Select
                  placeholder="Enter Code"
                  className={style.select}
                  dropdownClassName={style.selectDropdown}
                  allowClear
                  clearIcon={<img src={circleClose} alt="clear icon" />}
                >
                  {getOptions()}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        ))}
      </div>
    </>
  );
};
