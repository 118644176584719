import React, { Component } from "react";
import PropTypes from "prop-types";
import { Base64 } from "js-base64";
import { Form } from "antd";
import { connect } from "react-redux";
import Button from "../../../Modules/button";
import { addBankAccFunc } from "../../../Redux/Actions";
import LoaderIcon from "../../State/Loading/LoaderIcon";
import AddPaynow from "./AddPaynow";
import AddBankAccount from "../AddBank/AddBankAccount";
import { trackEvent } from "utility/analytics";

class AddBankForm extends Component {
  static propTypes = {
    view: PropTypes.string,
    cancel: PropTypes.func,
    addAccount: PropTypes.func,
    loading: PropTypes.bool,
    teamCurrency: PropTypes.string,
    currencyData: PropTypes.object,
  };

  form = React.createRef();

  state = {
    addBankInitialValues: {
      bank_name: "",
      swift_code: "",
      account_name: "",
      currency_code: this.props?.teamCurrency || "",
      account_number: "",
      mobile_number: "",
    },
  };

  componentDidUpdate(prevProps) {
    if (this.props.view !== prevProps.view) {
      this.form.current.resetFields();
      this.setState({ addBankInitialValues: { ...this.state.addBankInitialValues, account_number: "" } });
    }
  }

  handleAddBank = (values) => {
    const formData = { ...values, mobile_country_code: 65, type: "paynow" };
    const { view, addAccount } = this.props;
    if (view === "mobile") {
      addAccount(formData, true);
    } else {
      values = {
        ...values,
        bank_name: values.bank_name,
        swift_code: Base64.encode(values.swift_code),
        account_name: values.account_name,
        account_number: Base64.encode(values.account_number),
      };
      addAccount({ ...values, type: "bank_account" }, true);
    }
    trackEvent("add bank account add account clicked");
  };

  trackAddBankAccountEvents = (inputName, eventName) => {
    const { addBankInitialValues } = this.state;
    const currentValue = this.form?.current?.getFieldValue?.(inputName);
    const prevValue = addBankInitialValues?.[inputName];
    currentValue && currentValue !== prevValue && trackEvent(eventName);
    this.setState({ addBankInitialValues: { ...addBankInitialValues, [inputName]: currentValue } });
  };

  render() {
    const currencyData = ((this.props.currencyData && this.props.currencyData.payload) || { currencies: [] })
      .currencies;

    if (this.props.loading) {
      return <LoaderIcon />;
    }

    return (
      <div className={"add-bank--form"}>
        <Form ref={this.form} onFinish={this.handleAddBank} initialValues={{ currency_code: this.props.teamCurrency }}>
          {this.props.view === "mobile" ? (
            <AddPaynow trackAddBankAccountEvents={this.trackAddBankAccountEvents} />
          ) : (
            <AddBankAccount
              teamCurrency={this.props.teamCurrency}
              currencyData={currencyData}
              trackAddBankAccountEvents={this.trackAddBankAccountEvents}
            />
          )}
          <div className={"add-bank__container--action-btns"}>
            <Button text={"Add account"} htmlType="submit" />
            <Button text={"Cancel"} action={() => this.props.cancel()} />
          </div>
        </Form>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    loading: state.bankReducer.loading,
    currencyData: state.currencyReducer.data,
  }),
  {
    addAccount: addBankAccFunc,
  }
)(AddBankForm);
