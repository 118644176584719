import React, { useContext } from "react";

import Toaster, { IToasterProps } from "Modules/DS/Toaster";

import { IKYXToastContext } from "Views/KYX/@types";
import { KYXToastContext } from "Views/KYX/Context";

const Toast = () => {
  const { toaster, resetToaster } = useContext<IKYXToastContext>(KYXToastContext);

  const props: IToasterProps = {
    ...toaster,
    onClose: () => {
      resetToaster();
    },
  };

  return <Toaster {...props} />;
};

export default Toast;
