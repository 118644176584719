import * as React from "react";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { chevronDown } from "assets/img";

import { FLOW_CONDITIONS } from "../../../../constants";

import "./SelectedCondition.scss";
import classNames from "classnames";
import ConflictModal from "./ConflictModal";
import ErrorBox, { ErrorBoxProps } from "Modules/DS/Molecules/ErrorBox";

import { trackEvent } from "utility/analytics";
import { APPROVAL_FLOW_EVENTS } from "Views/ApprovalFlow/trackingEvents";
import { ConditionObject } from "Views/ApprovalFlow/types";
import { getEventType } from "Views/ApprovalFlow/helpers";
interface SelectedConditionProps {
  title: string;
  selected: Array<string>;
  expanded: { [any: string]: boolean };
  conditions: ConditionObject;
  deleteItem: (number) => void;
  toggleExpand: (number) => void;
  setConditions: (object) => void;
  processType: string;
  setEnablePublish: (boolean) => void;
}

const SelectedCondition: React.FC<SelectedConditionProps> = ({
  title,
  selected,
  expanded,
  conditions,
  deleteItem,
  toggleExpand,
  setConditions,
  processType,
  setEnablePublish,
}: SelectedConditionProps): JSX.Element => {
  const history = useHistory();
  const [, , , id] = history.location.pathname.split("/");
  const [error, setError] = useState<ErrorBoxProps>(null);
  const [showConflictModal, setShowConflictModal] = useState(false);
  const publishReducer = useSelector((state: any) => state.createApprovalFlowReducer);
  const validateReducer = useSelector((state: any) => state.validateApprovalFlowReducer);
  const conflictedData = publishReducer.errorDetail?.data?.conflicted || [];

  const setOverlapError = () => {
    setError({
      message: "This Flow has overlapping condition(s) with other Approval Flows.",
      action: {
        label: "Compare Flows",
        onClick: () => setShowConflictModal(true),
      },
    });
  };

  useEffect(() => {
    if (conflictedData.length) {
      setShowConflictModal(true);
      setOverlapError();
      trackEvent(APPROVAL_FLOW_EVENTS.approvalFlowConditionWarningDisplayed, {
        approval_flow_id: id,
        approval_flow_type: getEventType(processType),
      });
    }
  }, [conflictedData]);

  useEffect(() => {
    if (validateReducer?.data?.payload?.isSuccess) setError(null);
    else if (validateReducer?.errorDetail?.data?.conflicted) setOverlapError();
  }, [validateReducer]);

  const getInvalidConditions = (type) => {
    if (type === "wallet" || type === "expenseCategory")
      return (conditions[type] || []).filter((condition) => condition?.isDeleted);
    return [];
  };

  return (
    <>
      {error && <ErrorBox message={error.message} action={error.action} />}
      <ul className="flow-condition__selected">
        {selected.map((type, idx) => {
          const index = idx + 1;
          const condition = FLOW_CONDITIONS[type];
          const selectedItemClassName = classNames("flow-condition__selected__item", {
            expanded: expanded[type],
          });

          const invalidCondition = getInvalidConditions(type);
          if (invalidCondition.length > 0 && type === "wallet") {
            setEnablePublish(false);
          }
          return (
            <>
              {invalidCondition.length > 0 &&
                (type === "wallet" ? (
                  <ErrorBox message={`Please revise the budget below before publishing.`}></ErrorBox>
                ) : (
                  <ErrorBox
                    message={`Please revise categor${
                      invalidCondition.length > 1 ? "ies" : "y"
                    } below before publishing.`}
                  ></ErrorBox>
                ))}
              <li
                key={idx}
                className={selectedItemClassName}
                onClick={() => {
                  expanded[type] || toggleExpand(type);
                }}
              >
                <div className="selected__item__header" onClick={() => toggleExpand(type)}>
                  <span className="selected__item__number">Condition {index}: </span>
                  <span className="selected__item__title">{type === "wallet" && condition.title}</span>
                  {expanded[type] && (
                    <div
                      className="selected__item__delete-btn"
                      onClick={(e) => {
                        e.stopPropagation();
                        deleteItem(type);
                      }}
                    >
                      Delete
                    </div>
                  )}
                  <div className="selected__item__arrow">
                    <img src={chevronDown} />
                  </div>
                </div>
                <div className="selected__item__content">
                  {condition.render({
                    values: conditions[type],
                    setValues: (val) => {
                      setConditions({ ...conditions, [type]: val });
                    },
                    toggleExpand: () => {
                      toggleExpand(type);
                    },
                    isExpanded: expanded[type],
                  })}
                </div>
              </li>
            </>
          );
        })}
      </ul>
      {
        <ConflictModal
          show={showConflictModal}
          setShow={setShowConflictModal}
          title={title}
          conditions={conditions}
          conflictedData={conflictedData}
        />
      }
    </>
  );
};

export default SelectedCondition;
