import APIclient from "API/Client";
import { UpdateSelfApprovalParams } from "Views/ApprovalFlow/types";

export const UPDATE_SELF_APPROVAL_REQUEST = "UPDATE_SELF_APPROVAL_REQUEST";
export const UPDATE_SELF_APPROVAL_SUCCESS = "UPDATE_SELF_APPROVAL_SUCCESS";
export const UPDATE_SELF_APPROVAL_FAILURE = "UPDATE_SELF_APPROVAL_FAILURE";
export const UPDATE_SELF_APPROVAL_FLOW = "UPDATE_SELF_APPROVAL_FLOW";

const updateSelfApprovalReq = (data: any) => {
  return {
    type: UPDATE_SELF_APPROVAL_REQUEST,
    data,
  };
};
const updateSelfApprovalSuccess = (data?: any) => {
  return {
    type: UPDATE_SELF_APPROVAL_SUCCESS,
    data,
  };
};
const updateSelfApprovalError = (data?: any) => {
  return {
    type: UPDATE_SELF_APPROVAL_FAILURE,
    data,
  };
};

export const clearUpdateSelfApproval = () => {
  return {
    type: UPDATE_SELF_APPROVAL_FLOW,
  };
};

export const updateSelfApprovalUsers = (payload: UpdateSelfApprovalParams) => {
  return (dispatch) => {
    dispatch(updateSelfApprovalReq(payload));

    APIclient.putData(`/ms/spm-organization/v1/self-approval-policy/users`, payload)
      .then((data) => {
        dispatch(updateSelfApprovalSuccess(data.data));
      })
      .catch((err) => {
        const errorDetail = err?.response?.data?.error || err;
        dispatch(
          updateSelfApprovalError({
            ...errorDetail,
            data: err?.response?.data?.payload,
          })
        );
      });
  };
};
