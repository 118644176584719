import React from "react";
import { Select as AntdSelect, Form } from "antd";
import { SelectValue } from "antd/lib/select";
import classNames from "classnames";
import { Rule } from "antd/lib/form";

import { Loader } from "@spenmo/splice";
import { Core } from "assets/v1.1/icons/Core";

import styles from "./styles.module.scss";

const { Option } = AntdSelect;
const { ChevronDown } = Core;

interface ISelect {
  data: {
    key: string;
    value: string;
    text: string;
  }[];
  initialValue: string;
  label: string;
  name: string;
  rules?: Rule[];
  placeholder: string;
  disabled?: boolean;
  onChange?: (value: SelectValue) => void;
}

const Select: React.FC<ISelect> = ({
  data,
  initialValue,
  label,
  name,
  rules,
  placeholder,
  onChange = () => {},
  disabled = false,
}) => {
  return (
    <div className={styles.select}>
      <Form.Item name={name} initialValue={initialValue} label={label} rules={rules}>
        <AntdSelect
          getPopupContainer={(triggerNode) => triggerNode.parentElement}
          placeholder={placeholder}
          onChange={onChange}
          disabled={disabled}
          suffixIcon={
            disabled ? (
              <span className={styles.loader}>
                <Loader.Spinner size="l" variant="brand" />
              </span>
            ) : (
              <img
                className={classNames("ant-select-suffix", styles["rotate-180"])}
                src={ChevronDown.Line[24]}
                alt="Chevron Down Icon"
              />
            )
          }
        >
          {data?.map((item) => (
            <Option key={item.key} value={item.value}>
              {item.text}
            </Option>
          ))}
        </AntdSelect>
      </Form.Item>
    </div>
  );
};

export default Select;
