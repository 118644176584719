/**
 * Interface for the toaster component's props.
 * @interface IToasterProps
 */
export interface IToasterProps {
  /**
   * @property {boolean} visible - Determines if the toaster is visible.
   */
  visible: boolean;
  /**
   * @property {string | React.ReactNode} message - The message or content to display in the toaster.
   */
  message: string | React.ReactNode;
  /**
   * @property {TOASTER_STATUS_TYPE} [status] - Optional status type of the toaster, defaults to undefined.
   */
  status?: TOASTER_STATUS_TYPE;
  /**
   * @property {() => void} [onClose] - Optional function to be called when the toaster is closed, defaults to undefined.
   */
  onClose?: () => void;
  /**
   * @property {TOASTER_SIZE_TYPE} [size] - Optional size type of the toaster, defaults to undefined.
   */
  size?: TOASTER_SIZE_TYPE;
  /**
   * @property {string} [className] - Optional CSS class for the toaster, defaults to undefined.
   */
  className?: string;
  /**
   * @property {() => void} [action] - Optional function to be called when the action button is clicked, defaults to undefined.
   */
  action?: () => void;
  /**
   * @property {string} [actionLabel] - Optional label for the action button, defaults to undefined.
   */
  actionLabel?: string;
  /**
   * @property {number} [timeout] - Optional time (in milliseconds) before the toaster automatically closes, defaults to undefined.
   */
  timeout?: number;
}

/**
 * Enum for the toaster status types.
 * @readonly
 * @enum {string}
 */
export enum TOASTER_STATUS_TYPE {
  SUCCESS = "success",
  ERROR = "error",
}

/**
 * Enum for the toaster size types.
 * @readonly
 * @enum {string}
 */
export enum TOASTER_SIZE_TYPE {
  S = "small",
  M = "medium",
  L = "large",
}
