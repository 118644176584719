import React, { useCallback, useContext } from "react";
import { More } from "Views/Transaction/Filters";
import { ITitleWithID, ITrxnMoreFilterContext } from "Views/Transaction/@types";

import { useMerchantSearch } from "Views/Transaction/Cards/Hooks/useMerchantSearch";
import { TrxnMoreFilterContext } from "Views/Transaction/Context";

export const MerchantFilter = ({ id, appliedKey = "id" }: { id: string; appliedKey?: keyof ITitleWithID }) => {
  const { fetchMerchants, data = [], loading, resetData } = useMerchantSearch();

  const { more } = useContext<ITrxnMoreFilterContext>(TrxnMoreFilterContext);

  const getList = (arr: string[] = []) => arr.map((item: string) => ({ id: item, title: item }));

  const merchantList = getList(data);
  const dataList = useCallback(() => getList(more[id]), [more, id]);

  return (
    <More.Elastic<ITitleWithID>
      id={id}
      displayKey="title"
      appliedKey={appliedKey}
      label="Merchant"
      loading={loading}
      onSearch={fetchMerchants}
      onClearSearch={resetData}
      dataSet={dataList()}
    >
      {merchantList}
    </More.Elastic>
  );
};

export default MerchantFilter;
