import { SectionedQuickFilterWithSearch } from "./Sectioned";
import { MultipleQuickFilterWithElaticSearch } from "./Elastic";
import { SingleQuickFilterWithSearch } from "./SingleWithSearch";
import { MultipleQuickFilterWithSearch } from "./MultipleWithSearch";

export const Quick = {
  Single: SingleQuickFilterWithSearch,
  Multiple: MultipleQuickFilterWithSearch,
  Sectioned: SectionedQuickFilterWithSearch,
  Elastic: MultipleQuickFilterWithElaticSearch,
};
