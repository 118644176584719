import { FilterById } from "../index";
//not used
export const getAuthorisedRole = (teamList, temId) => {
  const selectedTeamData = FilterById(temId, teamList);
  const role = selectedTeamData?.[0]?.your_role;
  return role;
};

export const checkAccess = (permissionList, role) => {
  return permissionList.includes(role);
};
//not used
export const getMembershipData = (teamList, temId) => {
  const selectedTeamData = FilterById(temId, teamList);
  return selectedTeamData?.[0]?.your_membership;
};
//will work
export const checkMembership = (permissionList, membership) => {
  return permissionList.includes(membership);
};
//need clarification
export const hasRole = (teamList, permissionList) => {
  return teamList?.reduce?.((acc, curVal) => acc || checkAccess(permissionList, curVal?.your_role), false);
};
