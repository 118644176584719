import React from "react";
import { DynamicFormFieldProps } from "Modules/DynamicForm/types";
import { Form, Input } from "antd";
import { formItemProps } from "../utils/formItemProps";
import style from "./Text.module.scss";
import classNames from "classnames";

const DynamicFormText = (props: DynamicFormFieldProps) => {
  const { value } = props;
  const { className: indentClass, ...rest } = formItemProps(props);

  return (
    <Form.Item {...rest}>
      <>
        <div className={classNames(style.text, indentClass)}>{value}</div>
        <Input type="hidden" />
      </>
    </Form.Item>
  );
};

export default DynamicFormText;
