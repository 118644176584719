export const transformQRCode = (qrCode) => {
  if (!Boolean(qrCode)) {
    return qrCode;
  }
  const mimeType = "data:image/png;base64";
  let parseQR = qrCode;
  if (!qrCode.includes(mimeType)) {
    parseQR = mimeType + "," + qrCode;
  }
  return parseQR;
};

export const getBillIDs = (base64: string) => {
  try {
    return JSON.parse(atob(base64));
  } catch {
    return undefined;
  }
};
