import React from "react";
import classNames from "classnames";
import Icon from "Modules/icons";

import { IconButtonProps, BUTTON_SIZES, BUTTON_TYPES } from "../types";
import styles from "../index.module.scss";
import iconStyles from "./index.module.scss";

export const IconButton = ({
  children,
  disabled,
  loading,
  type = BUTTON_TYPES.PRIMARY,
  size = BUTTON_SIZES.MEDIUM,
  src,
  suffix,
}: IconButtonProps): JSX.Element => {
  const _classNames = classNames(styles.button, styles[type], styles[size], iconStyles[size], {
    [styles.disabled]: disabled,
    [styles.loading]: loading,
    [iconStyles.prefix]: !suffix,
    [iconStyles.suffix]: suffix,
  });

  return (
    <button disabled={disabled} className={_classNames}>
      <Icon className={iconStyles.icon} src={src} />
      {children}
    </button>
  );
};

export default IconButton;
