import React, { useEffect } from "react";
import OtpInput from "Views/EmployeeOTP/OTPVerification/Input";
import { verifyOtp, verifyOtpResetData } from "Redux/Actions/OTP/otpVerificationAction";
import { useDispatch, useSelector } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import OtpTimeOut from "./otpTimeout";
import { getCookieValue, cookieNames } from "utility/CookieHelper";
import LoaderIcon from "Views/State/Loading/LoaderIcon";
import { AUTH_PATHS } from "Route/Auth/paths";
import forgotPasswordCookiesReset from "Views/Auth//ForgotPasswordOTP/utility";
import { resetForgotData } from "Redux/Actions";

import "./OTPVerification.scss";

import { trackEvent } from "utility/analytics";
import { AuthBoxRedirect, AuthContentHeader } from "../../Auth/Components";
import { LOGIN_PATH } from "constants/Routes.constants";
import Loader from "Modules/DS/Loader";
import { LOADER_SIZES } from "Modules/DS/Loader/types";

const OTP_LENGTH = 6;

interface Props {
  changePhoneNumber?: () => void;
  phoneNumber?: string;
  fetchOtpError?: boolean;
  reFetchOtp?: () => void;
  fetchOtpData: any;
  action?: (num) => void;
  isforgotOTP?: boolean;
  redirectionPath?: string;
  forgotOTPLoding?: boolean;
}

const OTPVerification = (props: Props): React.ReactElement => {
  const dispatch = useDispatch();
  const phoneNumber = getCookieValue(cookieNames.USER_MOBILE_NUMBER) || "";
  const userEmail = getCookieValue(cookieNames.USER_EMAIL) || "";
  const {
    reFetchOtp,
    fetchOtpError,
    fetchOtpData,
    action = () => {},
    isforgotOTP,
    redirectionPath = "/",
    forgotOTPLoding,
  } = props;
  const {
    loading: verifyOtpLoading,
    data: verifyOtpData,
    error: verifyOtpError,
  } = useSelector((state: any) => state.verifyOtpReducer);
  const { is_otp_verification_needed = true, status_message = "", is_force_reset } = verifyOtpData?.payload || {};

  useEffect(() => {
    dispatch(verifyOtpResetData());
  }, []);

  if (is_force_reset) {
    dispatch(resetForgotData());
    forgotPasswordCookiesReset();

    return <Redirect to={`${AUTH_PATHS.FORCE_RESET}?redirect=${redirectionPath}`} />;
  } else if (!is_otp_verification_needed && status_message === "User verified successfully!") {
    return <Redirect to={redirectionPath} />;
  }

  const onOtpInputChange = (enteredOtp) => {
    action(enteredOtp);
    verifyOtpError && dispatch(verifyOtpResetData());
    if (enteredOtp?.length === OTP_LENGTH && !isforgotOTP) {
      dispatch(verifyOtp({ otp_to_test: enteredOtp, check_force_reset: true }));
      trackEvent("otp submitted", {});
    }
  };

  const getOtpInfo = () => {
    const combinationText = phoneNumber && userEmail ? ` or ` : "";
    return (
      <>
        Enter the 6-digit code we've sent to {`${phoneNumber ? "+" : ""}${phoneNumber}${combinationText}${userEmail}`}
      </>
    );
  };

  const getErrorMessage = () => {
    if (verifyOtpError) {
      return verifyOtpData || "";
    } else if (fetchOtpError) {
      return fetchOtpData?.payload?.status_message || "";
    }
  };

  const errorMessage = getErrorMessage();

  const showOTPDetails = () => {
    if (forgotOTPLoding) {
      return <Loader size={LOADER_SIZES.EXTRA_BIG} />;
    }
    if (verifyOtpLoading) {
      return (
        <div className="employee-otp-verification__loader-screen">
          <div className="info-text-header">Logging In</div>
          <LoaderIcon className={"loader"} />
          <div className="info-text">Please wait to be logged in...</div>
        </div>
      );
    }
    return (
      <>
        <AuthContentHeader title="Verify Your Login" />
        <p className="auth-title-description">{getOtpInfo()}</p>
        <div className="employee-otp-verification__input-wrapper">
          <OtpInput
            onInputChange={onOtpInputChange}
            inputLength={OTP_LENGTH}
            hasError={!!errorMessage}
            errorMessage={errorMessage}
          />
        </div>
        <OtpTimeOut resendOtp={reFetchOtp} />
        <AuthBoxRedirect>
          <Link id="goLogin" to={LOGIN_PATH} replace>
            Back to Login
          </Link>
        </AuthBoxRedirect>
      </>
    );
  };
  return <div className={`employee-otp-verification ${forgotOTPLoding ? "loader" : ""}`}>{showOTPDetails()}</div>;
};

export default OTPVerification;
