import React, { useCallback, useContext } from "react";

import { Quick } from "Views/Transaction/Filters";
import { TrxnFilterContext } from "Views/Transaction/Context";
import { ALL_TRXNS_COLUMNS } from "Views/Transaction/Constants";
import { useMerchantSearch } from "Views/Transaction/All/useFetch";
import { useBillsMerchantSearch } from "Views/Transaction/Bills/Hooks/useFetch";
import { ITitleWithID, ITrxnFilterContext } from "Views/Transaction/@types";

export const BaseRecipientFilter = ({
  id,
  fetchMerchants,
  data = [],
  loading,
  resetData,
}: {
  id: string;
  fetchMerchants: (value: string) => void;
  data: string[];
  loading: boolean;
  resetData: () => void;
}) => {
  const { filter } = useContext<ITrxnFilterContext>(TrxnFilterContext);

  const getList = (arr: string[] = []) => {
    return arr.length > 0 ? arr.map((item: string) => ({ id: item, title: item })) : [];
  };

  const merchantList = getList(data);
  const dataList = useCallback(() => getList(filter[id]), [filter, id]);

  return (
    <Quick.Elastic<ITitleWithID>
      id={id}
      displayKey="title"
      defaultValue={ALL_TRXNS_COLUMNS.RECIPIENT}
      onSearch={fetchMerchants}
      onClearSearch={resetData}
      loading={loading}
      dataSet={dataList()}
    >
      {merchantList}
    </Quick.Elastic>
  );
};

export const RecipientFilter = ({ id }: { id: string }) => {
  const props = useMerchantSearch();

  return <BaseRecipientFilter id={id} {...props} />;
};

export const BillsRecipientFilter = ({ id }: { id: string }) => {
  const props = useBillsMerchantSearch();

  return <BaseRecipientFilter id={id} {...props} />;
};
