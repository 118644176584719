import APIclient from "API/Client";
import { GetOrgId } from "../../utility";

export const FETCH_UPLOAD_REQUEST = "FETCH_UPLOAD_REQUEST";
export const FETCH_UPLOAD_SUCCESS = "FETCH_UPLOAD_SUCCESS";
export const FETCH_UPLOAD_FAILURE = "FETCH_UPLOAD_FAILURE";

export const fetchDataReq = (data) => {
  return {
    type: FETCH_UPLOAD_REQUEST,
    data: data,
  };
};
export const fetchDataSuccess = (data) => {
  return {
    type: FETCH_UPLOAD_SUCCESS,
    data: data,
    image: data.payload.file_path,
  };
};
export const fetchDataError = (data) => {
  return {
    type: FETCH_UPLOAD_FAILURE,
    data: data,
  };
};

export const uploadImageFunc = (imgdata) => {
  return (dispatch) => {
    dispatch(fetchDataReq());
    const orgId = GetOrgId();
    let formData = new FormData();
    formData.append("blob", imgdata);
    formData.append("organisation_id", orgId);

    APIclient.postData("/api/v1/photo", formData)
      .then((data) => {
        dispatch(fetchDataSuccess(data.data));
      })
      .catch((err) => {
        dispatch(fetchDataError(err));
      });
  };
};
