import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { Typography } from "@spenmo/splice";
import { useQuery } from "utility";
import { RedirectionLoader } from "Views/KYX/Loader/RedirectionLoader";
import { ACCOUNTING_PATHS } from "Route/Accounting/paths";
import { ACCOUNT_INTEGRATIONS_PATH } from "constants/Routes.constants";

import { createPartnerConnection } from "./dataCall";

import styles from "./styles.module.scss";

const CodatCallback = () => {
  const query = useQuery();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(query.get("errormessage"));
  const statusCode = Number(query.get("statuscode"));
  const connectionId = query.get("connectionid");
  const companyId = query.get("companyid");
  const partnerId = 4; // Netsuite ID on backend
  const companyName = query.get("companyname");

  useEffect(() => {
    if (statusCode === 403 && errorMessage === "User cancelled.") {
      history.replace(ACCOUNT_INTEGRATIONS_PATH);
      return;
    }

    if (statusCode !== 200 && statusCode !== 201) {
      setErrorMessage(`Invalid status code ${statusCode} with error message ${query.get("errormessage")}`);
      setLoading(false);
      return;
    }

    if (!connectionId || !companyId || !companyName) {
      setLoading(false);
      setErrorMessage("Missing Required fields");
      return;
    }

    createPartnerConnection({
      partnerConnectionID: connectionId,
      partnerId,
      partnerTenantId: companyId,
      partnerTenantName: companyName,
    })
      .then(({ data }) => {
        // Redirect to netsuite first setup
        history.push(ACCOUNTING_PATHS.NETSUITE_SETUP);
      })
      .catch((err) => {
        setErrorMessage(err?.response?.data?.error?.message || err.message);
      })
      .finally(() => setLoading(false));
  }, []);

  if (loading) {
    return <RedirectionLoader />;
  }

  return (
    <div className={styles.wrapper}>
      <Typography size="l" variant="headline-brand" className={styles.title}>
        There's some issue when connecting your netsuite account
      </Typography>

      <Typography size="m" variant="body-content" className={styles.caption}>
        {Boolean(errorMessage) && <p>Error message: {errorMessage}</p>}
        Please contact <a href="mailto:support@spenmo.com">support@spenmo.com</a> for more info
      </Typography>
    </div>
  );
};

export default CodatCallback;
