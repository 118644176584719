import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { InputNumber } from "antd";
import { getUtcOffset } from "utility";
import "./SpDatePicker.scss";

const TimePicker = ({ date, setDate, timeErrorMessage }) => {
  const hour = date?.hour?.();
  const minute = date?.minute?.();
  return (
    <div className="timepicker">
      <div>
        <InputNumber
          onFocus={(e) => {
            e.target.select();
          }}
          onChange={(value) => {
            const temp = moment(date).set("hour", (value < 24 && value) || 0);
            setDate(temp);
          }}
          value={hour}
          min={0}
          max={23}
          maxLength={2}
        />
        <span>:</span>
        <InputNumber
          onFocus={(e) => {
            e.target.select();
          }}
          onChange={(value) => {
            const temp = moment(date).set("minute", (value < 59 && value) || 0);
            setDate(temp);
          }}
          value={minute}
          min={0}
          max={59}
          maxLength={2}
        />
        <span>GMT{getUtcOffset()}</span>
      </div>
      {timeErrorMessage && <div className="timepicker__error-message">{timeErrorMessage}</div>}
    </div>
  );
};

TimePicker.propTypes = {
  date: PropTypes.instanceOf(Date),
  setDate: PropTypes.func,
  timeErrorMessage: PropTypes.string,
};

export default TimePicker;
