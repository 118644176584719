import React, { useState, useMemo } from "react";
import { useLocation } from "react-router";
import qs from "query-string";

import { Quick } from "Modules/DS/Filter";
import { IMultipleQuickFilterWithSearch } from "Modules/DS/Filter/@types";
import { useSetUrlParams } from "Views/SubscriptionManagement/hooks/useSetUrlParams";

export interface IDropdownOptions {
  title: string;
  id: string;
}

interface IDropdownProps {
  id: string;
  defaultValue: string;
  options: IDropdownOptions[];
  loading?: boolean;
  searchable?: boolean;
  searchValue?: string;
}

export const DropdownFilter = ({
  id,
  defaultValue,
  loading = false,
  searchable = false,
  searchValue,
  options,
}: IDropdownProps) => {
  const [title, setTitle] = useState("");
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const setUrlParams = useSetUrlParams();
  const { search, pathname } = useLocation();

  const query = useMemo(() => qs.parse(search) ?? {}, [search]);

  const getValue = (): string[] => {
    /** Typescript is not able infer these types automatically,
     *  inferring the return type as (string | string[])[] even though
     *  we are returing only string[] with added type checks in if blocks
     */
    if (Array.isArray(query[id])) {
      return query[id] as string[];
    } else if (query[id]) {
      return [query[id]] as string[];
    }
    return [];
  };

  const preSelectedValues = getValue();

  const onSelectHandler = (value: string[]) => {
    setUrlParams(pathname, {
      ...query,
      [id]: value,
    });
  };

  const clearFilter = () => {
    const newFilters = { ...query };
    delete newFilters[id];

    setUrlParams(pathname, newFilters);
  };

  const props: IMultipleQuickFilterWithSearch<IDropdownOptions> = {
    children: options,
    searchable,
    displayKey: "title",
    clearFilter,
    defaultValue,
    presetValue: options?.length > 0 && preSelectedValues,
    onAddSelected: onSelectHandler,
    filterItemProps: { id, renderSpliceActionChip: true, useDynamicPosition: false },
    states: {
      selectedIds,
      setSelectedIds,
      title,
      setTitle,
      loading,
      ...(searchValue && { value: searchValue }),
    },
  };

  return <Quick.Multiple<IDropdownOptions> {...props} />;
};
