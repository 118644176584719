import { POPOVER_WIDTH } from "Modules/DS/Popover";

import {
  IBasicContainerState,
  IMultipleFilterStates,
  IMultipleFilterWithSearchStates,
  ISectionedFilterStates,
  ISingleFilterStates,
  ISingleFilterWithSearchStates,
} from "./states.d";

import { IFilterItem } from "./item.d";

export const intitiaFilterItemProps: Omit<IFilterItem, "children"> = {
  id: "",
  title: "",
  selected: false,
  onClickOutside: () => null,
  clearFilter: () => null,
  popoverWidth: POPOVER_WIDTH.S,
};

export const initialBasicFilterStates: IBasicContainerState = {
  loading: false,
  setLoading: () => null,
};

export const initialSingleFilterStates: ISingleFilterStates = {
  selected: "",
  setSelected: () => null,
  title: "",
  setTitle: () => null,
  ...initialBasicFilterStates,
};

export const initialSingleFilterWithSearchStates: ISingleFilterWithSearchStates = {
  value: "",
  ...initialSingleFilterStates,
};

export const initialMultipleFilterStates: IMultipleFilterStates = {
  selectedIds: [],
  setSelectedIds: () => null,
  title: "",
  setTitle: () => null,
  ...initialBasicFilterStates,
};

export const initialMultipleFilterWithSearchStates: IMultipleFilterWithSearchStates = {
  value: "",
  ...initialMultipleFilterStates,
};

export const initialSectionedFilterWithStates: ISectionedFilterStates = {
  value: "",
  setValue: () => null,
  searching: false,
  setSearching: () => null,
  ...initialMultipleFilterWithSearchStates,
};

export * from "./item.d";
export * from "./date.d";
export * from "./list.d";
export * from "./more.d";
export * from "./quick.d";
export * from "./amount.d";
export * from "./states.d";
export * from "./filters.d";
export * from "./container.d";
