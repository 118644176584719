export enum FETCH_VERIFY_FORGOT_OTP_LIST {
  REQUEST = "FETCH_VERIFY_FORGOT_OTP_REQUEST",
  SUCCESS = "FETCH_VERIFY_FORGOT_OTP_SUCCESS",
  ERROR = "FETCH_VERIFY_FORGOT_OTP_ERROR",
  RESET = "FETCH_VERIFY_FORGOT_OTP_RESET",
}

export enum OTP_REQUEST_TYPES {
  SEND = "send",
  RESEND = "resend",
  VERIFICATION = "verification",
}

export interface VerifyForgotOTPData {
  data: Object;
  status_message: string;
}

export interface VerifyForgotOTPReducer {
  loading: boolean;
  data: VerifyForgotOTPData | any;
  error: boolean;
  isSuccess: boolean;
}

export const initialVerifyForgotOTPState: VerifyForgotOTPReducer = {
  loading: false,
  data: [],
  error: false,
  isSuccess: false,
};
