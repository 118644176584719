import React from "react";

import { Skeleton } from "antd";

import styles from "./index.module.scss";
import PaymentRunContentShimmer from "Views/Bills/V2/PaymentRun/PaymentRunContentShimmer";

const PaymentRunShimmer = () => {
  return (
    <>
      {Array.from(Array(6).keys()).map((key) => (
        <div className={styles.container} key={`container-${key}`}>
          <div className={styles.header}>
            <Skeleton.Input active={true} />
          </div>
          <div className={styles.content}>
            <PaymentRunContentShimmer />
          </div>
        </div>
      ))}
    </>
  );
};

export default PaymentRunShimmer;
