import React from "react";
import { Link } from "react-router-dom";

import {
  CalendarReloadFilled,
  ChevronRight,
  TransactionFilled,
  Typography,
  WarningFilled,
  FileWarningFilled,
} from "@spenmo/splice";
import { useStaticSubscription } from "Views/SubscriptionManagement/hooks/useSubscriptionList";
import { SUBSCRIPTION_EVENT } from "Views/SubscriptionManagement/Analytic";
import { useMutableData } from "API/useData";
import { GetOrgId } from "utility";
import { trackEvent } from "utility/analytics";

import styles from "./styles.module.scss";

const LinkOrDiv = ({ to, count, children }: { to: string; count: number; children: React.ReactNode }) => {
  if (count === 0) {
    return <div className={styles.cursorNotAllowed}>{children}</div>;
  }

  return <Link to={to}>{children}</Link>;
};

export const Action = ({
  icon,
  text,
  count,
  link,
}: {
  icon: "calendar" | "file" | "transaction" | "warning";
  text: string;
  count: string;
  link: string;
}) => {
  const getIcon = () => {
    switch (icon) {
      case "calendar":
        return CalendarReloadFilled;
      case "file":
        return FileWarningFilled;
      case "transaction":
        return TransactionFilled;
      case "warning":
        return WarningFilled;
    }
  };
  const Icon = getIcon();

  const detectEvent = () => {
    trackEvent(SUBSCRIPTION_EVENT.INTERACTION_WITH_SUBSCRIPTION_ANALYTIC, { action_required_event_source: text });
  };

  return (
    <LinkOrDiv to={link} count={Number(count)}>
      <div className={styles.action} onClick={detectEvent}>
        <div className={styles.iconAndText}>
          <Icon size="16" iconColor="currentColor" className={styles.icon} />
          <Typography size="caption-m" variant="body-content">
            {text}
          </Typography>
        </div>
        <div className={styles.iconAndCount}>
          <Typography size="caption-m" variant="body-content">
            {count}
          </Typography>
          <ChevronRight size="16" iconColor="currentColor" className={styles.neutralIcon} />
        </div>
      </div>
    </LinkOrDiv>
  );
};

export const useActionCount = () => {
  const { subscriptions: contractRenewingSoonSubscription } = useStaticSubscription({
    limit: 100,
    page: 1,
    contractsRenewingSoon: "true",
  });

  const { subscriptions: contractExpiringSoonSubscription } = useStaticSubscription({
    limit: 100,
    page: 1,
    contractsExpiringSoon: "true",
  });

  const { subscriptions: overspendBUdgetSubscription } = useStaticSubscription({
    limit: 100,
    page: 1,
    isOverSpend: "true",
  });

  const { data } = useMutableData(`/api/v1/expenses/subscriptions/auto-reconcile?organisation_id=${GetOrgId()}`);
  const autoReconcileTransaction = data?.data?.payload?.transactions?.length || 0;

  return {
    contractRenewingSoonSubscription: contractRenewingSoonSubscription.length,
    contractExpiringSoonSubscription: contractExpiringSoonSubscription.length,
    overspendBudgetSubscription: overspendBUdgetSubscription.length,
    autoReconcileTransaction,
  };
};
