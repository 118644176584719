import { GetOrgId } from "utility";
import APIclient from "API/Client";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import { urlParamsBuilder } from "utility/APIWrapper";

export const GET_CARD_OWNER_NAMES_REQUEST = "GET_CARD_OWNER_NAMES_REQUEST";
export const GET_CARD_OWNER_NAMES_SUCCESS = "GET_CARD_OWNER_NAMES_SUCCESS";
export const GET_CARD_OWNER_NAMES_FAILURE = "GET_CARD_OWNER_NAMES_FAILURE";
export const GET_CARD_REQUEST_OWNER_NAMES_REQUEST = "GET_CARD_REQUEST_OWNER_NAMES_REQUEST";
export const GET_CARD_REQUEST_OWNER_NAMES_SUCCESS = "GET_CARD_REQUEST_OWNER_NAMES_SUCCESS";
export const GET_CARD_REQUEST_OWNER_NAMES_FAILURE = "GET_CARD_REQUEST_OWNER_NAMES_FAILURE";

const fetchDataReq = () => {
  return {
    type: GET_CARD_OWNER_NAMES_REQUEST,
    data: {},
  };
};
const fetchDataSuccess = (data) => {
  return {
    type: GET_CARD_OWNER_NAMES_SUCCESS,
    data: data,
  };
};

const fetchDataError = (data) => {
  return {
    type: GET_CARD_OWNER_NAMES_FAILURE,
    data: data,
  };
};

const fetchCardRequestOwners = () => {
  return {
    type: GET_CARD_REQUEST_OWNER_NAMES_REQUEST,
    data: {},
  };
};
const fetchCardRequestOwnersSuccess = (data) => {
  return {
    type: GET_CARD_REQUEST_OWNER_NAMES_SUCCESS,
    data: data,
  };
};

const fetchCardRequestOwnersError = (data) => {
  return {
    type: GET_CARD_REQUEST_OWNER_NAMES_FAILURE,
    data: data,
  };
};

export const getCardOwnerNamesFunc = () => {
  return (dispatch) => {
    dispatch(fetchDataReq());
    const orgId = GetOrgId();
    APIclient.getData(`/api/v1/org/card/owner?organisation_id=${orgId}`)
      .then((data) => {
        dispatch(fetchDataSuccess(data?.data));
      })
      .catch((err) => {
        dispatch(fetchDataError(err));
      });
  };
};

export const getCardRequestOwnerNamesFunc = () => {
  return (dispatch) => {
    dispatch(fetchCardRequestOwners());
    const url = urlParamsBuilder(`/api/v1/org/card/cardrequest/owner`, {
      organisation_id: GetOrgId(),
      filters: JSON.stringify({ status: "requested" }),
    });
    APIclient.getData(url)
      .then((data) => {
        if (data?.status === HTTP_STATUS_CODE.OK) {
          dispatch(fetchCardRequestOwnersSuccess(data?.data));
        } else {
          dispatch(fetchCardRequestOwnersError(data?.data));
        }
      })
      .catch((err) => {
        dispatch(fetchCardRequestOwnersError(err));
      });
  };
};
