import React from "react";
import { Accordion, EShadowType } from "Modules/DS/Accordion";
import { List } from "../index";
import { IBillInformationProps } from "../type";

import styles from "./styles.module.scss";

const BillInformation: React.FC<IBillInformationProps> = ({ sections }) => {
  return (
    <Accordion
      defaultOpen={true}
      className={styles["billInfoContainer"]}
      title="Bill Information"
      shadow={EShadowType.FLOATING}
    >
      {sections.map((section) => (
        <section key={section.id} className={styles["bill-information__section"]}>
          <h5 className={styles["bill-information__section-title"]}>{section.title}</h5>
          <List details={section.details} />
        </section>
      ))}
    </Accordion>
  );
};

export default BillInformation;
