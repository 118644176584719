import React from "react";
import { Switch } from "antd";
import styles from "./styles.module.scss";
import classNames from "classnames";

interface IToggleSwitchProps {
  onChange: () => void;
  showChildren?: boolean;
  disabled?: boolean;
  defaultChecked?: boolean;
  checked: boolean;
}

const ToggleSwitch = ({ checked, onChange, showChildren, disabled, defaultChecked }: IToggleSwitchProps) => {
  return (
    <Switch
      checked={checked}
      defaultChecked={defaultChecked}
      checkedChildren={
        showChildren && <div className={classNames(styles.checkedChild, { [styles.inactiveCheckedChild]: disabled })} />
      }
      unCheckedChildren={
        showChildren && (
          <div className={classNames(styles.unCheckedChild, { [styles.inactiveUnCheckedChild]: disabled })} />
        )
      }
      className={classNames(styles.toggleSwitch, { [styles.inactiveSwitch]: disabled })}
      onChange={onChange}
      disabled={disabled}
    />
  );
};

export default ToggleSwitch;
