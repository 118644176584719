import { createAsyncThunk, createSlice, SerializedError } from "@reduxjs/toolkit";
import { ISingleLine } from "Views/Accounting/Workflow/TransactionFilter";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import { IBill } from "Views/Bills/ImportBillFromXero";
import { generateFilteredTransaction } from "./helper";
import { getBillFromXero } from "Redux/DataCalls/ImportBillFromXero.api";
interface ImportBillFromXeroState {
  data: IBill[];
  originalData: IBill[];
  billResponse: any;
  recipient: ISingleLine[];
  loading: boolean;
  error: SerializedError;
}

const initialState: ImportBillFromXeroState = {
  data: [],
  originalData: [],
  billResponse: [],
  recipient: [],
  loading: true,
  error: {},
};

export const dataMapping: (data, index?: number) => IBill = (data) => ({
  id: data.InvoiceID,
  invoiceId: data.InvoiceID,
  invoiceNumber: data.InvoiceNumber,
  date: new Date(data.DateString).toISOString(),
  dueDate: new Date(data.DueDateString).toISOString(),
  hasAttachments: data.HasAttachments,
  attachments: data.Attachments,
  subTotal: data.SubTotal,
  totalTax: data.TotalTax,
  total: data.AmountTotal,
  dueTotal: data.AmountDue,
  currencyCode: data.CurrencyCode,
  reference: data.Reference,
  recipient: data?.Contact?.Name || "",
  invoiceLink: data.InvoiceLink || "",
  originalCategory: data.OriginalCategory,
  mappedCategory: data.MappedCategory,
  originalTax: data.OriginalTax,
  mappedTax: data.MappedTax,
  lineItems: data.LineItems || [],
  lineAmountTypes: data.LineAmountTypes,
});

export const fetchImportBillFromXero = createAsyncThunk("importBillFromXero", async (_, { rejectWithValue }) => {
  try {
    const { data: responseData } = await getBillFromXero();

    if (responseData.status === HTTP_STATUS_CODE.OK) {
      const bills = responseData?.payload?.bills || [];
      return {
        data: bills.map((bill, index) => dataMapping(bill, index)) as IBill[],
        apiResponse: bills,
      };
    }
    return rejectWithValue({ data: [] });
  } catch (error) {
    return rejectWithValue({ data: [] });
  }
});

const importBillFromXeroSlice = createSlice({
  name: "importBillFromXero",
  initialState,
  reducers: {
    filterBill(state, action) {
      const filter = action.payload;
      state.data = generateFilteredTransaction(state.originalData, filter);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchImportBillFromXero.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchImportBillFromXero.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
      state.originalData = action.payload.data;
      state.billResponse = action.payload.apiResponse;
      state.recipient = Array.from(new Set(action.payload.data.map((item) => item.recipient))).map((recipient) => ({
        id: recipient,
        title: recipient,
      }));
    });
    builder.addCase(fetchImportBillFromXero.rejected, (state) => {
      state.loading = false;
      state.error = { message: "Something went wrong" };
      state.data = [];
    });
  },
});

export const { filterBill } = importBillFromXeroSlice.actions;

export const importBillFromXeroReducer = importBillFromXeroSlice.reducer;
