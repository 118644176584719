import React from "react";

import { usePagination } from "Views/Transaction/List";
import { TrxnPaginationContext } from "Views/Transaction/Context";
import { ITrxnPaginationContext } from "Views/Transaction/@types";

export const TrxnPaginationProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const [currentPage, setCurrentPage, resetCurrentPage] = usePagination();

  const pagination: ITrxnPaginationContext = {
    currentPage,
    setCurrentPage,
    resetCurrentPage,
  };

  return <TrxnPaginationContext.Provider value={pagination}>{children}</TrxnPaginationContext.Provider>;
};
