import { useCallback, useEffect, useState } from "react";
import { IBreakpoint, IRowProps, IScreenMap, responsiveContainer } from "../types";

type PropType = "align" | "justify" | "column";

export default function useMergePropByScreen(oriProp: IRowProps[PropType], screen: IScreenMap) {
  const [prop, setProp] = useState(typeof oriProp === "string" || typeof oriProp === "number" ? oriProp : "");

  const mergeAlignOrJustify = useCallback(() => {
    if (typeof oriProp !== "object") {
      return;
    }
    for (let responsiveItem of responsiveContainer) {
      const breakpoint: IBreakpoint = responsiveItem.breakPoint;

      if (!screen[breakpoint] || !oriProp) continue;
      const curVal = oriProp[breakpoint];
      if (curVal !== undefined) {
        setProp(curVal);
        return;
      }
    }
  }, [oriProp, screen]);

  useEffect(() => {
    mergeAlignOrJustify();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oriProp, screen]);

  return prop;
}
