import axios from "axios";
import { axiosHeader } from "./index";
import { GetOrgId } from "utility";

export const FETCH_CARD_REQUEST = "FETCH_CARD_REQUEST";
export const FETCH_CARD_SUCCESS = "FETCH_CARD_SUCCESS";
export const FETCH_CARD_FAILURE = "FETCH_CARD_FAILURE";

const fetchDataReq = () => {
  return {
    type: FETCH_CARD_REQUEST,
    data: {},
  };
};
const fetchDataSuccess = (data) => {
  return {
    type: FETCH_CARD_SUCCESS,
    data: data,
  };
};

const fetchDataError = (data, comp) => {
  return {
    type: FETCH_CARD_FAILURE,
    data: data,
  };
};

export const cardFunc = () => {
  return (dispatch, getState) => {
    dispatch(fetchDataReq());
    const orgId = GetOrgId();
    axios
      /*eslint no-process-env: "off"*/
      .get(
        window.__ENV__.API_BASE_URL +
          `/api/v1/org/card/?organisation_id=${orgId}&team_id=${getState().teamReducer.selectedTeamId}`,
        axiosHeader
      )
      .then((data) => {
        dispatch(fetchDataSuccess(data.data));
      })
      .catch((err) => {
        dispatch(fetchDataError(err));
      });
  };
};
