import {
  GET_ALL_TEAMS_WALLET_REQUEST,
  GET_ALL_TEAMS_WALLET_SUCCESS,
  GET_ALL_TEAMS_WALLET_FAILURE,
} from "Redux/Actions/Teams/teamWalletAction";

import { userInitialState as defaultState } from "Redux/InitialState";

export const allTeamsWalletReducer = (state = defaultState, action) => {
  switch (action.type) {
    case GET_ALL_TEAMS_WALLET_REQUEST:
      return { data: action.data, loading: true, error: false };
    case GET_ALL_TEAMS_WALLET_SUCCESS:
      return { data: action.data, loading: false, error: false };
    case GET_ALL_TEAMS_WALLET_FAILURE:
      return { data: action.data, loading: false, error: true };
    default:
      return state;
  }
};
