import * as React from "react";
import { useState, useEffect } from "react";
import { Button } from "antd";
import { GetOrgId } from "utility";
import SearchDropdown from "Modules/DS/SearchDropdown";
import { clearCreateApprovalFlow } from "Redux/Actions/ApprovalFlow/createApprovalFlow";
import { getAllEmployees } from "Redux/DataCalls/Users.api";
import { adminIcon } from "assets/img";
import { info, infoBlue } from "assets/icons/role";
import { useSelector, useDispatch } from "react-redux";
import "./SetApprover.scss";
import { ContextualMenuItem } from "Modules/DS/ContextualMenu/types";
import { useHistory } from "react-router-dom";
import { MultiApproversObject } from "../../../types";
import { useAppSelector } from "hook";
import { CURRENCY_CODE } from "constants/Currency.constant";
import { USER_ROLE } from "constants/Team.constant";
import { Typography } from "@spenmo/splice";
import { API_URL } from "Views/Bills/V2/constants";
import { useMutableData } from "API/useData";

interface SetApproverProps {
  step: number;
  values: Array<MultiApproversObject>;
  setValues: (string) => void;
  disabled: boolean;
  isDefault: boolean;
  conditions: object;
  processType: string;
  hasTeamManager: boolean;
}

let timer;

const SetApprover: React.FC<SetApproverProps> = ({
  step,
  values,
  setValues,
  disabled,
  isDefault,
  conditions,
  processType,
  hasTeamManager,
}: SetApproverProps) => {
  const ADMIN_OPTION: ContextualMenuItem = {
    value: `admin:${GetOrgId()}`,
    label: "Any Admin",
    role: "Admin",
  };
  const TEAM_MANAGER_OPTION: ContextualMenuItem = {
    value: `manager:`,
    label: "Budget Owner",
    role: "Manager",
  };
  const PAGE_LIMIT = 10;
  const teamList = useSelector((state: any) => state.teamList?.data?.payload?.teams || []);
  const defaultTeam = teamList.find((item: any) => item.team_type === "Organisation");
  const [showDropdown, setShowDropdown] = useState(false);
  const [options, setOptions] = useState([ADMIN_OPTION, TEAM_MANAGER_OPTION]);
  const [employeeCount, setEmployeeCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [showManagerWarning, setShowManagerWarning] = useState(false);
  const history = useHistory();
  const [, , action, id] = history.location.pathname.split("/");
  const queryParam = history.location.search;
  const duplicateProcessId = new URLSearchParams(queryParam).get("duplicate_process");
  const isEdit = action === "edit" && id;
  const currencyCode = useAppSelector((state) => state.wallet?.data?.currency_code);
  const [settleCRWithSpenmo, setSettleCRWithSpenmo] = useState(null);
  const optionFilter = ({ value }) =>
    !values.find((item) => item?.assignees?.some((el) => value === `${el.role}:${el.identifier}`));

  const dispatch = useDispatch();

  const setSelectedOption = () => {
    let options = [];
    values[step]?.assignees?.forEach((item) => {
      if (item?.role === "admin") options.push(ADMIN_OPTION);
      else if (item?.role === "manager") options.push(TEAM_MANAGER_OPTION);
      else if (item?.role)
        options.push({
          value: `user:${item?.identifier}`,
          label: item?.assignee?.name,
          error: item?.assignee?.error,
          isDeleted: isEdit ? item?.assignee?.isDeleted : false,
          role: item?.assignee?.role,
        });
    });
    return [...options.filter((item) => item.isDeleted), ...options.filter((item) => !item.isDeleted)];
  };

  let selectedOption = setSelectedOption();

  const defaultFlowHasEmployeeRoles =
    isDefault &&
    processType === "invoice" &&
    values?.[step]?.assignees?.some((apporver) => apporver?.assignee?.role !== USER_ROLE.ADMIN);

  const fetchEmployeeList = async (listPage, append) => {
    const params = { search, page: listPage, limit: PAGE_LIMIT, roleId: isDefault ? 1 : undefined };
    setLoading(true);
    const employeeData = await getAllEmployees(params);
    setLoading(false);
    setEmployeeCount(employeeData?.payload?.pagination?.total);
    const employeeList =
      employeeData?.payload?.list
        ?.filter(
          (item) =>
            !item.isHidden &&
            (isDefault
              ? processType === "reimbursement"
                ? item.roleID === 1 || item.isManager
                : item.roleID === 1
              : item.roleID)
        )
        .map((item) => {
          return {
            value: `user:${item.employeeID}`,
            label: `${item.firstName} ${item.lastName}`,
            role: item.roleName,
          };
        })
        .sort((a, b) => (a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1)) || [];
    const adminOption = ADMIN_OPTION.label.toLowerCase().indexOf(search?.toLowerCase()) > -1 ? [ADMIN_OPTION] : [];
    const managerOption =
      TEAM_MANAGER_OPTION.label.toLowerCase().indexOf(search?.toLowerCase()) > -1 ? [TEAM_MANAGER_OPTION] : [];
    setOptions((prevOptionList) => {
      return append ? [...prevOptionList, ...employeeList] : [...adminOption, ...managerOption, ...employeeList];
    });
  };

  useEffect(() => {
    fetchEmployeeList(page, page > 1);
  }, [page]);

  useEffect(() => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      if (page === 1) fetchEmployeeList(1, false);
      else setPage(1);
    }, 1000);
  }, [search]);

  useEffect(() => {
    const isTeamManagerApproverSelected = selectedOption.some((item) => item.label === "Budget Owner");
    if (isTeamManagerApproverSelected) {
      setShowManagerWarning(!hasTeamManager);
    } else if (!isTeamManagerApproverSelected && showManagerWarning) {
      setShowManagerWarning(false);
    }
  }, [conditions, values.map((val) => val.assignees), hasTeamManager]);

  const { data: getSetting } = useMutableData(API_URL.getPaymentRunSetting);
  const getSettingData = React.useMemo(() => getSetting?.data?.payload?.setting, [getSetting?.data?.payload?.setting]);
  const isPaymentRunOn = Boolean(getSettingData);

  const setAllSearchOptions = () => {
    return [...selectedOption, ...options.filter(optionFilter)].filter((item) => !item.isDeleted);
  };

  const showReminder = () => {
    const articleLink =
      "https://support.spenmo.com/hc/en-us/articles/12481694856601--How-to-Pay-Reimbursement-Requests-on-the-Spenmo-Dashboard-";
    return step === values.length - 1 ? (
      <div className="flow-approver__warning approver-reminder">
        <img src={infoBlue} />
        <div>
          <p> Approver(s) may choose to pay immediately at this step.</p>
          <p className="kb-article-link" onClick={() => window.open(articleLink, "_blank", "noopener")}>
            Learn more
          </p>
        </div>
      </div>
    ) : (
      <></>
    );
  };

  const isAllApproversInactive =
    isEdit && selectedOption.length > 0 ? selectedOption.every((item) => item.isDeleted) : false;

  const shouldShowReminder = (): boolean => {
    return processType === "reimbursement" && currencyCode === CURRENCY_CODE.SGD && !isPaymentRunOn;
  };

  return (
    <div className="flow-approver">
      {defaultFlowHasEmployeeRoles && (
        <div className="flow-approver__warning manager__warning">
          <img src={info} alt="info-icon" />
          <div className="warning-heading">
            <p className="warning-text">Please resolve the following item(s) before proceeding to publish flow.</p>
          </div>
        </div>
      )}
      {showManagerWarning ? (
        <div className="flow-approver__warning manager__warning">
          <img src={info} alt="info-icon" />
          <div className="warning-heading">
            <strong>{`No manager in the ${defaultTeam?.team_name} team.`}</strong>
            <p className="warning-text">
              Please add or choose a team member and set as manager
              <span
                onClick={() => {
                  dispatch(clearCreateApprovalFlow());
                  history.push(`/teamprofile/${defaultTeam.id}`);
                }}
              >
                &nbsp;here.
              </span>
            </p>
          </div>
        </div>
      ) : (
        <></>
      )}
      {isAllApproversInactive ? (
        <div className="flow-approver__warning manager__warning">
          <img src={info} alt="info-icon" />
          <p className="warning-text">None of the approvers is active. Please add at least 1 other approver.</p>
        </div>
      ) : (
        <></>
      )}
      {showDropdown || ((isEdit || duplicateProcessId) && selectedOption.length > 0) ? (
        <>
          <SearchDropdown
            className={"flow-approver__dropdown"}
            placeholder="Search employee by name"
            values={selectedOption}
            options={setAllSearchOptions()}
            addMoreLabel="Edit Approver(s)"
            onChange={(val) => {
              setValues(
                val.map((item) => {
                  const [role, identifier] = item?.value?.split(":") || [];
                  return {
                    role,
                    identifier,
                    assignee: {
                      id: role !== "admin" ? identifier : null,
                      name: item?.label,
                      role: item?.role,
                    },
                    step,
                  };
                })
              );
            }}
            onSearch={(val) => setSearch(val)}
            onScrollBottom={() =>
              setPage((currentPage) => (currentPage * PAGE_LIMIT < employeeCount ? currentPage + 1 : currentPage))
            }
            isLoading={loading}
            defaultOpen
            renderOption={(option: ContextualMenuItem) => (
              <div className="flow-approver__select__option">
                {option?.label === "Any Admin" || option?.label === "Budget Owner" ? (
                  <img className="flow-approver__warning__icon" src={adminIcon} alt="warning-icon" />
                ) : (
                  <div className="flow-approver__option__icon">{option?.label?.[0]?.toUpperCase()}</div>
                )}
                <div
                  className={`flow-approver__option__label ${
                    option.isDeleted ? "flow-approver__option__label__inactive" : ""
                  }`}
                >
                  {option?.label} {option.isDeleted ? "(Inactive)" : ""}
                  {defaultFlowHasEmployeeRoles && option?.role !== USER_ROLE.ADMIN && (
                    <Typography
                      variant="body-content"
                      size="caption-m"
                      tag="p"
                      className="flow-approver__select__option--warning-text"
                    >
                      Approver lost admin rights. Replace the approver, or remove this step.
                    </Typography>
                  )}
                </div>
              </div>
            )}
            setShowDropdown={setShowDropdown}
            multiple
          />
          {shouldShowReminder() && showReminder()}
        </>
      ) : (
        <>
          {shouldShowReminder() && showReminder()}
          <Button className="flow-approver__add-btn" disabled={disabled} onClick={() => setShowDropdown(true)}>
            + Add Approver
          </Button>
        </>
      )}
    </div>
  );
};

export default SetApprover;
