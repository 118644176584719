import APIclient from "API/Client";
import { GetBaseAuthObject } from "../../utility";

export const FETCH_POLICY_REQUEST = "FETCH_POLICY_REQUEST";
export const FETCH_ADDPOLICY_REQUEST = "FETCH_ADDPOLICY_REQUEST";
export const FETCH_ADDPOLICY_SUCCESS = "FETCH_ADDPOLICY_SUCCESS";
export const FETCH_ADDPOLICY_FAILURE = "FETCH_ADDPOLICY_FAILURE";
export const FETCH_POLICY_SUCCESS = "FETCH_POLICY_SUCCESS";
export const FETCH_POLICY_FAILURE = "FETCH_POLICY_FAILURE";
export const UPDATE_TEAM = "UPDATE_TEAM";
export const RESET_TEAM_POLICY = "RESET_TEAM_POLICY";

const fetchDataReq = () => {
  return {
    type: FETCH_POLICY_REQUEST,
    data: {},
  };
};
const fetchDataSuccess = (data, allTeams) => {
  return {
    type: FETCH_POLICY_SUCCESS,
    data: data,
    allTeams: allTeams,
  };
};
const fetchDataError = (data, calledBy) => {
  return {
    type: FETCH_POLICY_FAILURE,
    data: data,
  };
};

const fetchCreateSuccess = (data) => {
  return {
    type: FETCH_ADDPOLICY_SUCCESS,
    createData: data,
  };
};
const fetchCreateReq = () => {
  return {
    type: FETCH_ADDPOLICY_REQUEST,
    data: {},
  };
};
const fetchCreateError = (data) => {
  return {
    type: FETCH_ADDPOLICY_FAILURE,
    data: data,
  };
};

export const resetTeamPolicy = () => {
  return {
    type: RESET_TEAM_POLICY,
  };
};

//need clarification -  it needs global teamselectedId or profile page teamselectedId
export const getTeamPolicyFunc = (teamName) => {
  const orgId = GetBaseAuthObject().orgId; // sessionStorage.getItem("reauth").split("vspenmo1")[0];
  return (dispatch, getState) => {
    dispatch(fetchDataReq());
    APIclient.getData(`/api/v1/org/${orgId}/team/${getState().teamReducer.selectedTeamId}/policy`)
      .then((data) => {
        if (data.data.status > 200) {
          dispatch(fetchDataError(data.data, "status"));
        } else {
          dispatch(fetchDataSuccess(data.data));
        }
      })
      .catch((err) => {
        dispatch(fetchDataError(err, "catch"));
      });
  };
};
export const createTeamPolicyFunc = (data, teamId) => {
  return (dispatch) => {
    dispatch(fetchCreateReq());
    APIclient.putData(`/ms/spm-organisation/v1/mgmt/budgets/${teamId}/policy`, data)
      .then((data) => {
        if (data.data.status > 200) {
          dispatch(fetchCreateError(data.data));
        } else {
          dispatch(fetchCreateSuccess(data.data));
        }
      })
      .catch((err) => {
        dispatch(fetchCreateError(err));
      });
  };
};
