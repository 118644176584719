import React from "react";

import { Col, Row } from "Splice/Grid";

import { COMPANY_VERIFICATION } from "Route/KYX/paths";

import { FilterById } from "utility";

import Button from "Modules/button";
import Loader from "Modules/DS/Loader";
import { LOADER_TYPES } from "Modules/DS/Loader/types";

import { DocumentWithButton } from "Views/KYX/Intro/Document";
import { IDocumentsInfo, IStartIntro } from "Views/KYX/@types";
import { KYB_SOURCE, DOCUMENTS_INFO } from "Views/KYX/Constants";

import { StartIntroHeader as Header } from "Views/KYX/Intro/Start/Header";
import { useRedirectionHandler, useStartKYXManually } from "Views/KYX/hooks";
import { usePostMyInfoBusinessAuthorise } from "Views/KYX/hooks/MyInfoBusiness";
import { ResumeMyInfoButton as MyInfoButton } from "Views/KYX/Modal/ChangeKYBMethod/Button";

import styles from "./index.module.scss";

export const MyInfo = ({ companyName, countryCode }: IStartIntro): React.JSX.Element => {
  const details: IDocumentsInfo = DOCUMENTS_INFO()[countryCode][KYB_SOURCE.AUTOMATED];
  const redirect = useRedirectionHandler(COMPANY_VERIFICATION.COMPANY_INFORMATION);
  const { loading, startVerification } = useStartKYXManually(redirect);
  const { loading: myInfoLoading, myInfoBusinessAuthorise } = usePostMyInfoBusinessAuthorise();

  const onStartMyInfo = () => {
    myInfoBusinessAuthorise()
      .then((result) => {
        window.location.href = result?.authorise_url as string;
      })
      .catch(() => {});
  };

  const StartManualBtn = loading ? (
    <Loader type={LOADER_TYPES.BRAND} />
  ) : (
    <Button textButton action={startVerification} text="Upload Company Documents Manually" />
  );

  return (
    <Row className={styles.myInfo} align="end" justify="start">
      <Col className={styles.header} span={24}>
        <Header content={details?.content} label={details?.label} companyName={companyName} />
      </Col>

      <Col className={styles.content} span={24}>
        <DocumentWithButton
          button={<MyInfoButton loading={myInfoLoading} action={onStartMyInfo} />}
          document={FilterById("myInfo", details?.documents)[0]}
        />
        <DocumentWithButton
          className="manualDoc"
          button={StartManualBtn}
          document={FilterById("manual", details?.documents)[0]}
        />
      </Col>
    </Row>
  );
};
