import React, { useMemo, useState } from "react";
import cn from "classnames";
import dayjs from "dayjs";
import moment from "moment";
import { useSelector } from "react-redux";
import { Base64 } from "js-base64";

import { RootState } from "Redux/ConfigureStore";
import APIClient from "API/Client";

import { appNotification } from "Modules/appNotification/appNotification";

import { PayoutDetailProps, TYPES } from "../types";
import { PAYOUT_TYPE, PAY_NOW_IDENTIFIER } from "../const";
import useCheckOrgConfigs from "customHooks/useCheckOrgConfigs";
import { GetBaseAuthObject } from "utility";
import { GetFormData } from "utility/APIWrapper";
import { useFetchCRState } from "Views/Reimbursement/Settings/useCRState";
import { LAST_POSSIBLE_RECURRING_DATE } from "Modules/RecurringDate/types";

import styles from "./ManagePayout.module.scss";
import { Typography, Button, Banner, InfoFilled } from "@spenmo/splice";

const PayoutDetail = (props: PayoutDetailProps) => {
  const orgDetail = useSelector((state: RootState) => state.b2bOrgDetailReducer?.data?.payload);
  const withSettlementSystem = useCheckOrgConfigs("settlement_system");
  const { onClickEdit, onClickSubmit, payoutPayload } = props;

  const {
    type,
    bank_name,
    swift_code = "",
    account_name,
    account_number = "",
    mobile_number,
    nric,
    updated_at,
    spenmo_code,
    is_valid,
  } = payoutPayload;

  const isBankTransfer = type === TYPES.BANK_TRANSFER;
  const isMobile = Boolean(mobile_number);
  const payNowIdentifier = PAY_NOW_IDENTIFIER[isMobile ? 0 : 1].label;
  const hasSpenmoCode = Boolean(spenmo_code);
  const validPayout = Boolean(is_valid);
  const alertProps = useMemo(() => {
    let message;
    let description = "Please edit your entered details below for your reimbursements to get paid out";

    if (type === TYPES.BANK_TRANSFER) {
      message = "Invalid settings migrated from previously entered Bank Account details";

      if (hasSpenmoCode) {
        message = "Settings migrated from previously entered Bank Account details";
      }
    } else {
      message = "Settings migrated from previously entered PayNow details";
    }

    if (hasSpenmoCode) {
      description =
        "Please check your entered details below for your reimbursements to get paid out. If these are incorrect, you may edit them immediately.";
    }

    return {
      message,
      description,
    };
  }, [type, hasSpenmoCode]);

  const [submitLoading, setSubmitLoading] = useState(false);

  const handleSubmit = () => {
    const formData = GetFormData({
      ...payoutPayload,
      is_confirmed: true,
    });

    setSubmitLoading(true);

    APIClient.postData(`/api/v1/org/${GetBaseAuthObject().orgId}/bank_account/${payoutPayload.id}/update`, formData)
      .then((res) => {
        if (res.data.status !== 200) {
          throw res;
        }

        appNotification.success({
          message: "Your reimbursements payout details have been migrated.",
        });
      })
      .catch((e) => {
        appNotification.error({
          message: `An error has occurred. Please contact support@spenmo.com for further assistance.\nError ID: ${
            e?.data?.status || 500
          }`,
          cta: "Email Support",
          onClickCTA: () => {
            const link = document.createElement("a");
            link.href = "mailto:support@spenmo.com";
            link.click();
            link.remove();
          },
        });
      })
      .finally(() => {
        setSubmitLoading(false);
        onClickSubmit();
      });
  };

  const showAlert = withSettlementSystem && (!validPayout || !hasSpenmoCode);

  const { settledAt, isEnabled } = useFetchCRState();

  const lastPossibleDatesInaMonth =
    settledAt >= LAST_POSSIBLE_RECURRING_DATE.MIN && settledAt <= LAST_POSSIBLE_RECURRING_DATE.MAX;

  const date = dayjs().month(0).date(settledAt).format("Do");

  const getBannerTitle = () => {
    switch (true) {
      case settledAt !== undefined && isEnabled && lastPossibleDatesInaMonth:
        return `Payouts are processed on the ${date} or last day of every month`;
      case settledAt !== undefined && isEnabled && !lastPossibleDatesInaMonth:
        return `Payouts are processed on the ${date} of every month`;
      case settledAt === undefined:
      case !isEnabled:
      default:
        return "Payouts are processed externally outside of Spenmo";
    }
  };

  const getBannerDescription = () => {
    switch (true) {
      case settledAt !== undefined && isEnabled && lastPossibleDatesInaMonth:
        return `Your approved reimbursements are automatically processed and settled either on the ${date} or last day of every month in a single transaction.`;
      case settledAt !== undefined && isEnabled && !lastPossibleDatesInaMonth:
        return `Your approved reimbursements are automatically processed and settled either on the ${date} of every month in a single transaction.`;
      case settledAt === undefined:
      case !isEnabled:
      default:
        return "Your approved reimbursements are processed outside of Spenmo. Check with your administrator or Finance person for more details.";
    }
  };

  return (
    <div>
      <Typography tag="p" size="m" variant="body-content" className={styles.caption}>
        Your reimbursements payout details was last set on <span>{moment(updated_at).format("D MMM YYYY")}</span>
      </Typography>
      <div className={styles.payoutDetails}>
        <Banner icon={InfoFilled} variant="info" title={getBannerTitle()} description={getBannerDescription()} />
      </div>
      {showAlert && (
        <div className={styles.payoutAlert}>
          <Banner
            variant={hasSpenmoCode ? "warning" : "danger"}
            title={alertProps.message}
            description={alertProps.description}
            icon={InfoFilled}
          />
        </div>
      )}
      <div className={styles.payoutDetail}>
        <div className={styles.gridGroup}>
          <p className={styles.label}>Payment Method</p>
          <p className={styles.value}>{PAYOUT_TYPE.find((item) => item.value === type).label}</p>
        </div>
        <div>
          <p className={styles.label}>{isBankTransfer ? "Bank Country" : "PayNow Identifier Type"}</p>
          <p className={styles.value}>{isBankTransfer ? orgDetail?.country : payNowIdentifier}</p>
        </div>
        <div>
          <p className={styles.label}>
            {isBankTransfer ? "Bank Account Currency" : isMobile ? "Mobile Number" : "NRIC"}
          </p>
          <p className={styles.value}>
            {isBankTransfer ? orgDetail?.currency : isMobile ? `+65 ${mobile_number}` : nric}
          </p>
        </div>
        {isBankTransfer ? (
          <>
            <div>
              <p className={styles.label}>Bank</p>
              <p
                className={cn(styles.value, {
                  [styles.danger]: !hasSpenmoCode,
                })}
              >
                {bank_name}
              </p>
            </div>
            <div>
              <p className={styles.label}>SWIFT/BIC Code</p>
              <p
                className={cn(styles.value, {
                  [styles.danger]: !hasSpenmoCode,
                })}
              >
                {Base64.decode(swift_code)}
              </p>
            </div>
            <div>
              <p className={styles.label}>Bank Account Name</p>
              <p className={styles.value}>{account_name}</p>
            </div>
            <div>
              <p className={styles.label}>Bank Account Number</p>
              <p className={styles.value}>{Base64.decode(account_number)}</p>
            </div>
          </>
        ) : (
          <div>
            <p className={styles.label}>PayNow Name</p>
            <p className={styles.value}>{account_name}</p>
          </div>
        )}
        <PayoutFooter
          handleSubmit={handleSubmit}
          hasSpenmoCode={hasSpenmoCode}
          onClickEdit={onClickEdit}
          loading={submitLoading}
          validPayout={validPayout}
        />
      </div>
    </div>
  );
};

const PayoutFooter = ({ validPayout, onClickEdit, hasSpenmoCode, handleSubmit, loading }) => {
  if (validPayout) {
    return (
      <div className={styles.payoutDetailFooter}>
        <Button variant="primary" onClick={onClickEdit}>
          Edit
        </Button>
      </div>
    );
  }

  return (
    <div className={styles.payoutDetailFooter}>
      {hasSpenmoCode && (
        <Button variant="secondary" onClick={onClickEdit}>
          Edit
        </Button>
      )}
      <Button variant="primary" onClick={hasSpenmoCode ? handleSubmit : onClickEdit} loading={loading}>
        {hasSpenmoCode ? "Looks good" : "Edit now"}
      </Button>
    </div>
  );
};

export default PayoutDetail;
