import React from "react";

import { Banner, InfoFilled } from "@spenmo/splice";

import { PARTNER_TYPE } from "../helper/constant";
import { AccountingPartner } from "./AccountingPartner";
import { IXeroConnectivityInfoProps } from "../@types";

import styles from "./styles.module.scss";

const ConnectivityInformation = ({
  isAllowedIntegrationSettingUpdate,
  usingAdvanceExpenseIntegration,
  isPartnerAccountNotAvailable,
  accountingPartnerError,
  accountingPartnerdata,
  accountingPartner,
}: IXeroConnectivityInfoProps) => {
  const { has_valid_token, validToken, completedIntegration, partnerName } =
    accountingPartnerdata?.payload || {};
  const connectedToAccountingPartner = has_valid_token;
  const partialyConnected =
    validToken &&
    !completedIntegration &&
    partnerName === PARTNER_TYPE.NETSUITE;

  // doing type check here so as not to show the label when the status is being determined
  const showAccountNotFoundBanner =
    connectedToAccountingPartner && isPartnerAccountNotAvailable;
  const showConnectedStatus = typeof isPartnerAccountNotAvailable === "boolean";

  return (
    <>
      <AccountingPartner
        {...accountingPartner}
        key={accountingPartner.id}
        connectedToAccountingPartner={connectedToAccountingPartner}
        showConnectedStatus={showConnectedStatus}
        isAllowedIntegrationSettingUpdate={isAllowedIntegrationSettingUpdate}
        accountingPartnerError={accountingPartnerError}
        isPartnerAccountNotAvailable={isPartnerAccountNotAvailable}
        partialyConnected={partialyConnected}
      />

      {accountingPartner.name === PARTNER_TYPE.XERO && (
        <>
          {showAccountNotFoundBanner && (
            <div className={styles.accountNotFoundBanner}>
              <Banner
                variant="warning"
                icon={InfoFilled}
                title=""
                description="Your Xero account cannot be connected. It may have been archived or deleted. To continue to use this integration, remove the current Xero account and reconnect with a valid and active Xero account."
              />
            </div>
          )}
          {usingAdvanceExpenseIntegration && (
            <div className={styles.bankfeedAvailabilityBanner}>
              <Banner
                variant="info"
                title="Bank Feed Integration with Xero now available"
                description={
                  <div className={styles.body}>
                    Spenmo now supports Bank Feed Integration to ensure your
                    Spenmo and Xero balances always match in real-time. To
                    enable Bank Feed Integration for your organization, contact
                    us at{" "}
                    <a href="mailto:support@spenmo.com">support@spenmo.com</a>.
                    Otherwise, current integration with Xero will continue to be
                    supported.
                  </div>
                }
                icon={InfoFilled}
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default ConnectivityInformation;
