import React from "react";
import { Form, Button } from "antd";
import IButton from "Modules/button";
import classNames from "classnames";
import { IFormButtonProps } from "../types";

const FormButton = ({ isEdit, allowEdit, actionLoading, fieldChanged, onCancel }: IFormButtonProps) => {
  return (
    <>
      <Form.Item>
        {!isEdit ? (
          <IButton
            disabled={!allowEdit}
            loading={actionLoading}
            htmlType="submit"
            rounded
            text="Add category"
            className={classNames({ "button--disabled": actionLoading })}
          />
        ) : (
          <IButton
            disabled={!fieldChanged && !allowEdit}
            loading={actionLoading}
            className={classNames("save-btn", {
              active: fieldChanged && allowEdit,
              "button--disabled": !fieldChanged || !allowEdit,
            })}
            htmlType="submit"
            rounded
            text="Save"
          />
        )}
      </Form.Item>
      <Button htmlType="button" onClick={onCancel}>
        Cancel
      </Button>
    </>
  );
};

export default FormButton;
