import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Redirect, useLocation } from "react-router-dom";

import { VERIFY_2FA_FAILURE, VERIFY_2FA_REQUEST, VERIFY_2FA_START } from "Redux/Actions/loginAction";

import { isUserLoggedIn } from "utility/LoginHelper";

import { DASHBOARD_PATH, EMPLOYEE_OTP_PATH, ONBOARDING_PATH_WITH_STEP } from "constants/Routes.constants";

import Login from "Views/Auth/Login/Login";
import OTPVerification from "Views/Auth/Login/GACodeVerification";
import { getCookieValue } from "utility/CookieHelper";
import { isForceResetNeeded } from "utility/ForceResetHelper";
import { AUTH_PATHS } from "Route/Auth/paths";

import "../auth.scss";

const LoginChecker = () => {
  const location = useLocation();

  useEffect(() => {
    const handler = () => {
      if (getCookieValue("reauth")) {
        window.location.reload();
      }
    };

    window.addEventListener("focus", handler);

    return () => {
      window.removeEventListener("focus", handler);
    };
  }, []);

  const { loading, loginData, isDoing2FA } = useSelector((state: any) => ({
    loading: state.loginReducer.loading,
    loginData: state.loginReducer.data,
    isDoing2FA: [VERIFY_2FA_START, VERIFY_2FA_REQUEST, VERIFY_2FA_FAILURE].includes(state.loginReducer.step),
  }));

  const redirectTo = location.search.replace("?redirect=", "").replace("&", "?");
  const isUserOnboarded: boolean = loginData?.payload?.is_onboarding_finished || false;
  const currentOnboardingStep = loginData?.payload?.onboarding_last_step;
  const isOtpVerificationNeeded: boolean = loginData?.payload?.is_otp_verification_needed || false;
  const otpVerificationPath: string = `${EMPLOYEE_OTP_PATH}${location?.search || ""}`;

  if (isDoing2FA) {
    return <OTPVerification />;
  }

  if (isOtpVerificationNeeded) {
    return <Redirect to={otpVerificationPath} />;
  }

  if (isForceResetNeeded() && isForceResetNeeded() !== "undefined") {
    return <Redirect to={AUTH_PATHS.FORCE_RESET} />;
  }

  if (!isUserOnboarded && isUserLoggedIn() && currentOnboardingStep && !loading) {
    return <Redirect to={ONBOARDING_PATH_WITH_STEP + currentOnboardingStep} />;
  }

  if (isUserLoggedIn() && !loading) {
    return <Redirect to={DASHBOARD_PATH + redirectTo} />;
  }

  return <Login />;
};

export default LoginChecker;
