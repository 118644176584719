import Modal, { ModalComponent } from "Modules/DS/Modal";
import React, { useEffect, useRef, useState } from "react";
import styles from "./index.module.scss";
import Button from "Modules/button";
import { greyCloseIcon } from "assets/img";
import { TITLE_TYPE } from "Modules/DS/Modal/types";
import Banner from "Modules/DS/Banner";
import Icon from "Modules/icons";
import { Info } from "assets/v1.1/icons/Core/Info";
import classNames from "classnames";
import { useTeamActionContext } from "Views/Team/List/Actions/context";
import Input from "Modules/DS/Input";
import { RENAME_TEAM_TITLE_LIMIT } from "Views/Team/constant";
import { useTeamToasterContext } from "Views/Team/Toast/context";
import { TOASTER_STATUS_TYPE } from "Modules/DS/Toaster/types.d";
import { RenameTeam } from "Redux/DataCalls/Team.api";
import { AxiosResponse } from "axios";
import { TeamRenameResponseSuccess } from "./type";
import { TEAM_ACTION, TEAM_ACTION_STATUS } from "Views/Team/List/Actions/constant";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";

const Rename = () => {
  const {
    selectAction,
    updateEvent,
    clearEvent,
    state: { selectedTeam },
  } = useTeamActionContext();
  const { setToaster } = useTeamToasterContext();
  const [teamName, setTeamName] = useState<string>(selectedTeam.team_name);
  const [loading, setLoading] = useState<boolean>(false);
  const inputRef = useRef<any>();
  const disableSave = !Boolean(teamName.trim().length);
  const saveButtonInputStyle = classNames(styles.save, {
    [styles["save-active"]]: !disableSave,
    [styles["save-inactive"]]: disableSave,
  });
  const inputStyle = classNames(styles.input, {
    [styles["input-inactive"]]: loading,
  });
  const cancelButtonStyle = classNames(styles.cancel, {
    [styles["cancel-inactive"]]: loading,
  });
  useEffect(() => {
    inputRef.current.focus();
  }, []);

  const handleClose = () => selectAction(null);
  const handleUpdateFail = (error: string) => {
    clearEvent();
    setToaster({
      visible: true,
      status: TOASTER_STATUS_TYPE.ERROR,
      message: error,
    });
    setLoading(false);
  };

  const handleRename = async () => {
    setLoading(true);
    updateEvent(TEAM_ACTION_STATUS.LOADING, TEAM_ACTION.RENAME);
    try {
      const { data } = (await RenameTeam(selectedTeam.id, teamName)) as AxiosResponse<TeamRenameResponseSuccess>;
      const { status } = data;
      if (status === HTTP_STATUS_CODE.OK) {
        setToaster({
          visible: true,
          status: TOASTER_STATUS_TYPE.SUCCESS,
          message: `Budget successfully renamed to ${teamName}`,
        });
        updateEvent(TEAM_ACTION_STATUS.COMPLETED, TEAM_ACTION.RENAME, { id: selectedTeam.id, name: teamName });
        handleClose();
      }
    } catch (e) {
      handleUpdateFail(e?.response?.data?.error?.message);
    }
  };

  return (
    <Modal className={`${styles.modal}`} visible={true} close={handleClose}>
      <ModalComponent.CloseButton fixToTop src={greyCloseIcon} />
      <ModalComponent.Title titleType={TITLE_TYPE.withBorder}>
        <p className={`${styles.title}`}>Rename Budget</p>
      </ModalComponent.Title>
      <section className={`${styles.body}`}>
        <section className={`${styles.banner}`}>
          <Banner
            type="info"
            icon={<Icon src={Info.Fill.grey_24} />}
            message={
              <p className="banner-message">
                Changing the budget name will take effect immediately across Spenmo functionalities. This will impact
                past, present, and future transactions.
              </p>
            }
          />
        </section>
        <section className={`${styles.action}`}>
          <Input
            ref={inputRef}
            showCount
            maxLength={RENAME_TEAM_TITLE_LIMIT}
            label="Budget Name"
            value={teamName}
            disabled={loading}
            className={inputStyle}
            onChange={setTeamName}
          />
          <span className={`${styles.hint}`}>
            Tip: use a descriptive name for easier audit and tracking e.g. “Campaign Budget”
          </span>
        </section>
      </section>

      <div className={`${styles.footer}`}>
        <Button
          text="Cancel"
          disabled={loading}
          rounded
          textButton
          className={cancelButtonStyle}
          action={handleClose}
        />
        <Button
          showOnlyLoaderIconWhenLoading={loading}
          loading={loading}
          className={saveButtonInputStyle}
          action={handleRename}
          text="Save"
          rounded
          disabled={disableSave}
        />
      </div>
    </Modal>
  );
};

export default Rename;
