import React, { useContext } from "react";
import { DynamicFormFieldProps } from "Modules/DynamicForm/types";
import { Form, Radio } from "antd";
import { formItemProps } from "../utils/formItemProps";
import { RadioChangeEvent } from "antd/lib/radio";
import styles from "./Radio.module.scss";
import classNames from "classnames";
import { DynamicFormContext } from "Modules/DynamicForm/store";

const DynamicFormRadio = (props: DynamicFormFieldProps) => {
  const { alias, fieldID, fieldProps, onChangeValue, options } = props;
  const { trackView, trackEdit } = useContext(DynamicFormContext);

  const handleChange = (e: RadioChangeEvent) => {
    trackEdit(alias, e.target.value);
    trackView(alias, e.target.value);
    onChangeValue?.(fieldID, e.target.value);
  };

  const { className: indentClass, ...rest } = formItemProps(props);

  return (
    <Form.Item
      {...rest}
      className={classNames(styles.radio, indentClass, {
        [styles.hidden]: fieldProps.hideWhenSingleOption && options.length < 2,
      })}
    >
      <Radio.Group options={options} onChange={handleChange} disabled={!fieldProps.editable} />
    </Form.Item>
  );
};

export default DynamicFormRadio;
