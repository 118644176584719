import React from "react";

import { TRANSACTION_TYPES } from "Views/Transaction/Constants";
import { ISidePanelDetails, ITrxnDetails } from "Views/Transaction/@types";

import { CardTrxnDetails } from "./Cards";
import { TopupTrxnDetails } from "./Topup";
import { BillsTrxnDetails } from "./Bills";
import { OtherTrxnDetails } from "./Others";
import { TransferTrxnDetails } from "./Transfer";
import { ReimbursementTrxnDetails } from "./Reimbursements";
import { FeesTrxnDetails } from "./Fees";
import { IncentiveTrxnDetails } from "./Incentive";
import { includesReversalTrxns } from "../Helper";
import styles from "./styles.module.scss";
import { childrenTransactionType } from "Views/Accounting/Workflow/hooks/useTransactionDetail";

export const getTrxnDetails = (trxnType: TRANSACTION_TYPES) => {
  let Component: (props: ITrxnDetails) => JSX.Element;

  switch (trxnType) {
    case TRANSACTION_TYPES.TRANSFER:
      Component = TransferTrxnDetails;
      break;
    case TRANSACTION_TYPES.REIMBURSEMENT:
      Component = ReimbursementTrxnDetails;
      break;
    case TRANSACTION_TYPES.CARD_EXPENSE:
    case TRANSACTION_TYPES.CARD_REFUND:
    case TRANSACTION_TYPES.CARD_REVERSAL:
    case TRANSACTION_TYPES.CARD_SETTLEMENT:
      Component = CardTrxnDetails;
      break;
    case TRANSACTION_TYPES.BILL_PAY_EXPENSE:
    case TRANSACTION_TYPES.BILL_PAY_EXPENSE_REVERSAL:
      Component = BillsTrxnDetails;
      break;
    case TRANSACTION_TYPES.FEES:
    case TRANSACTION_TYPES.FEES_REFUND:
      Component = FeesTrxnDetails;
      break;
    case TRANSACTION_TYPES.INCENTIVE:
      Component = IncentiveTrxnDetails;
      break;
    case TRANSACTION_TYPES.TOPUP:
      Component = TopupTrxnDetails;
      break;
    case TRANSACTION_TYPES.OTHERS:
    case TRANSACTION_TYPES.OTHERS_PAYOUT:
    case TRANSACTION_TYPES.OTHERS_REVERSAL:
    default:
      Component = OtherTrxnDetails;
      break;
  }

  return Component;
};

export const SidePanelDetails = ({
  record,
  attachments,
  expenseData,
  closeDetails,
  handleSaveRef,
  visible = false,
  disableEditing = false,
  isHistoricalRefund = false,
  xeroBillBannerData,
  relatedTransaction,
  viewTransactionClickHandler,
}: ISidePanelDetails) => {
  const TrxnDetails = getTrxnDetails(record.transaction_type as TRANSACTION_TYPES);

  const {
    amount,
    created_at,
    status,
    currency,
    payee_name,
    payer_name,
    team_name,
    transaction_number,
    id,
    notes,
    transaction_type,
    foreign_exchange_rate,
    description,
  } = record;

  const trxnNumberElement = () => {
    const trxnTypeKeyword = childrenTransactionType({ transactionType: relatedTransaction?.transaction_type });
    return (
      <>
        {transaction_number}
        {Boolean(relatedTransaction) && (
          <p className={styles["related-transaction"]} onClick={viewTransactionClickHandler}>
            View {!includesReversalTrxns(transaction_type) ? trxnTypeKeyword : "original"} transaction
          </p>
        )}
      </>
    );
  };

  return (
    visible && (
      <TrxnDetails
        amount={amount}
        notes={notes}
        status={status}
        teamName={team_name}
        receipts={attachments}
        currencyCode={currency}
        createdDate={created_at}
        expenseData={expenseData}
        requesterName={transaction_type === TRANSACTION_TYPES.TRANSFER ? description : payer_name}
        recipientName={payee_name}
        closeDetails={closeDetails}
        handleSaveRef={handleSaveRef}
        fxRate={foreign_exchange_rate}
        trxnNumber={transaction_number}
        trxnId={id}
        disableEditing={disableEditing}
        isHistoricalRefund={isHistoricalRefund}
        trxnType={transaction_type as TRANSACTION_TYPES}
        xeroBillBannerData={xeroBillBannerData}
        trxnNumberElement={trxnNumberElement}
      />
    )
  );
};

export * from "./useVerifiedReceipts";
export * from "./useDetails";
export * from "./useUpdate";
export * from "./Header";
export * from "./Status";
