import React, { useContext } from "react";

import { IKYXRemarkContextType } from "Views/KYX/@types";
import { KYXRemarkContext } from "Views/KYX/Context/Remarks";
import { REMARKS_SIDEBAR_TYPE } from "Views/KYX/Constants/Remarks";

import styles from "./index.module.scss";

interface IRemarksButton {
  data: any; // WIP
  type: REMARKS_SIDEBAR_TYPE;
}

const RemarksButton: React.FC<IRemarksButton> = ({ data, type }: IRemarksButton): JSX.Element => {
  const { setRemarks }: IKYXRemarkContextType = useContext(KYXRemarkContext);

  const onSeeRemarks = (e) => {
    e.preventDefault();
    setRemarks({ visible: true, payload: data, type: type });
  };

  return (
    <button type="button" className={styles.button} onClick={onSeeRemarks}>
      See remarks
    </button>
  );
};

export default RemarksButton;
