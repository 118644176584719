import React, { useState, useEffect } from "react";
import { CSSTransition } from "react-transition-group";
import { Radio } from "antd";

import { csvXeroLogo, csvJurnalLogo, csvQuickBooksLogo } from "assets/img";
import "./styles.scss";

export const AccountingSoftware = ({
  template,
  setShowTemplate,
  isOpen,
  isConnected,
  onChangeTemplate,
  showTemplate,
}: any) => {
  const { id, templates, name } = template;
  const [selectedTemplate, setSelectedTemplate] = useState<any>();

  useEffect(() => {
    if (isConnected) {
      setSelectedTemplate(template.templates[0]);
      setShowTemplate(template.id);
      onChangeTemplate(template.templates[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConnected]);

  const clearSelection = () => setSelectedTemplate("");

  const handleClick = () => {
    if (showTemplate === template.id) {
      setShowTemplate("");
    } else {
      setShowTemplate(template.id);
    }
    onChangeTemplate({});
  };

  const getLogoUrl = () => {
    switch (name) {
      case "Xero":
        return csvXeroLogo;
      case "QuickBooks":
        return csvQuickBooksLogo;
      default:
        return csvJurnalLogo;
    }
  };

  return (
    <div className="accounting-software__list-item">
      <div className="accounting-software__list-title" onClick={handleClick}>
        <img alt={"xeroLogo"} src={getLogoUrl()} />
        {/** Later the icon will be provided from BE */}
        <div className="title">
          <div>
            <h5 className="capitalize">{name}</h5>
            {isConnected && <p className="connected">Connected with Spenmo</p>}
          </div>
          <svg
            width="15"
            height="8"
            viewBox="0 0 15 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={`arrow-icon ${isOpen(id) ? "rotate-0" : "rotate-180"}`}
          >
            <path
              d="M13 2L7.43243 7L1.86486 2"
              stroke="#131A2E"
              strokeWidth="1.5"
              strokeLinecap="square"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </div>
      <CSSTransition in={isOpen(id)} timeout={150} classNames={"fade"} unmountOnExit onExit={clearSelection}>
        <Radio.Group
          className={`sp-radio-group`}
          value={selectedTemplate}
          onChange={(e) => setSelectedTemplate(e.target.value)}
        >
          {templates.map((type) => (
            <div className="accounting-software__list-children" key={type.id}>
              <Radio className="sp-radio-group" value={type} onChange={(e) => onChangeTemplate(e.target.value)}>
                <h5>{type.name}</h5>
              </Radio>
            </div>
          ))}
        </Radio.Group>
      </CSSTransition>
    </div>
  );
};
