import useSWRMutation from "swr/mutation";
import { postData } from "API/Client";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";

export const useAddNewVendor = () => {
  return useSWRMutation<string | null, any, string, { name: string }>(
    "ms/spm-organization/v1/subscriptions/vendors",
    (url, { arg }) =>
      postData(url, arg).then(({ data }) => {
        const { status, payload } = data || {};
        if (status === HTTP_STATUS_CODE.OK && payload?.vendor_id) {
          return payload.vendor_id;
        }
        return null;
      })
  );
};
