import APIclient from "API/Client";

import { GetOrgId } from "utility";
import { GetFormData } from "utility/APIWrapper";

import { TRANSACTION_FIELDS } from "constants/Transaction.constant";
import { trimPaginationAndListParams } from "Views/Transaction/DataCalls/index";

export const GetTransactions = async (
  page = 0,
  limit = 7,
  search_filters = {},
  accounting_filters = {},
  cardLastFour = undefined,
  cardUserId = undefined
) => {
  const orgId = GetOrgId();

  let filters = {
    organisation_id: orgId,
  };

  if (cardLastFour) {
    filters = {
      ...filters,
      card_last_four: cardLastFour,
      user_id: cardUserId,
    };
  }

  trimPaginationAndListParams(search_filters);

  try {
    const resp = await APIclient.postData(
      `/api/v1/transactions`,
      GetFormData({
        fields: TRANSACTION_FIELDS,
        filters: { ...filters },
        search_filters: { ...search_filters },
        organisation_id: orgId,
        pg: page,
        limit: limit,
        accounting_filters,
      })
    );
    return resp.data;
  } catch (err) {
    console.error(err);
    return err;
  }
};

export const updateTransactionMerchantName = async (data) => {
  const orgId = GetOrgId();

  return APIclient.postData(`/api/v1/transactions/merchantName`, {
    organisation_id: orgId,
    ...data,
  });
};

export const fetchTransactionDetails = (transactionId) => {
  return APIclient.getData(`/api/v1/transactions/${transactionId}?organisation_id=${GetOrgId()}`);
};

export const saveTransactionApi = (data) => {
  return APIclient.postData("/api/v1/receipt/", GetFormData({ organisation_id: GetOrgId(), ...data }));
};
