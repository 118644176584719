export const TITLE = {
  domestic: "Transfer Fee",
  international: "Transfer Fee",
};
export const TOOLTIP = {
  default:
    "Domestic transfers are charged a flat fee, while international transfer fees vary by amount, currency, country, and exchange rates.",
  paymentAmount: "This is equivalent to the amount sent from your dashboard currency to the recipient",
  fullAmountGuarantee:
    "This guarantees the recipient receives the exact amount and avoid any potential fees charged by recipient's bank",
  estimatedArrival:
    'Estimated Arrival is calculated from the time payment status changes to "Sending Payment". The dates provided do not factor in any public holidays of the recipient country.',
};
export const DESC = {
  default: "Charged at time of payment",
  SG: "Domestic Transfers are free with a limited monthly quota according to your subscription plan. If your monthly quota is exceeded, additional transfer fees will be charged at the end of the month.",
};

export const LEARN_MORE_URL = "https://spenmo.com/faq#billing";
export const BILL_PAY_PROCESSING_TIME_ARTICLE_URL =
  "https://support.spenmo.com/hc/en-us/articles/4412728657433-Bill-Pay-Processing-Time";
