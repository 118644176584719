export interface ITeam {
  id: string;
  team_name: string;
  team_type?: TeamType;
  your_role?: string;
  created_by?: string;
}

export enum TeamType {
  ORGANISATION = "Organisation",
}

interface ISchedule {
  type: string;
  endsAfter: number;
  start: string;
  nextDate: string;
  interval: number;
  intervalType: string;
  isRetriable: boolean;
}

export interface ISidePanelDetailsScheduleTransfer {
  id: string;
  orgId: string;
  senderTeamId: string;
  senderId: string;
  senderType: string;
  receiverTeamId: string;
  receiverId: string;
  receiverType: string;
  currency: string;
  amount: number;
  isRetriable: boolean;
  isActive: boolean;
  schedule: ISchedule;
  createdBy: string;
  updatedBy: string;
  senderName: string;
  receiverName: string;
  senderTeamName: string;
  receiverTeamName: string;
}

export interface IRequestedFundTransferListItem {
  id: string;
  type: string;
  expense_category_id: string;
  transaction_number: string;
  currency_id: number;
  subwallet_id: string;
  request_time: number;
  status: string;
  settlement_status: string;
  title: string;
  merchant: string;
  user_id: string;
  request_initiator_type: string;
  rejection_reason: string;
  img_url: string;
  reference_number: string;
  amount: string;
  created_at: string;
  request_assignees: Array<IRequestAssignees>;
  requested_for_wallet_type: string;
  team_id: string;
  team_name: string;
  currency_code: string;
  user_name: string;
  expense_category_name: string;
  user_pic: string;
  receipts: {
    count: number;
    urls: Array<string>;
  };
  tags: Array<string>;
}

interface IRequestAssignees {
  id: string;
  request_id: string;
  assigned_to: string;
  type: string;
  organisation_id: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  name: string;
}

export interface ISentFundsListItem {
  accounting_message: string;
  accounting_status: string;
  amount: number;
  category: string;
  created_at: string;
  currency: string;
  description: string;
  destination_team: string;
  destination_team_id: string;
  destination_user_name: string;
  id: string;
  foreign_exchange_rate: number;
  line_items: string;
  notes: string;
  organisation_id: string;
  organisation_name: string;
  receipts: string;
  sort: [number, string];
  search: {
    previous: [number, string];
    next: [number, string];
  };
  source_team: string;
  source_team_id: string;
  source_user_name: string;
  status: string;
  tags: string;
  tax_amount: number;
  transaction_number: string;
  updated_at: string;
}
