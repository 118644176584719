import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { darkGreyCloseIcon } from "../../assets/img";
import Icon from "../icons";
import "./sideSection.scss";

const SideSection = ({ children, onClose, visible = false, className }) => {
  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.removeProperty("overflow");
    };
  }, []);

  const handleOnClose = (e) => {
    // This is some hacky way to not close the sidebar when the transaction tags is active on the transaction detail
    if (e?.target?.contains(document.querySelector(".accounting-tags:not(.ant-popover-hidden)"))) {
      return;
    }
    onClose(e);
  };

  return (
    visible && (
      <div className="side-section-wrapper" onClick={handleOnClose}>
        <div className={`side-section ${className ? className : ""}`} onClick={(e) => e.stopPropagation()}>
          <Icon src={darkGreyCloseIcon} className="side-section__close" action={onClose} alt="close" />
          <div className="side-section__content">{!!children && children}</div>
        </div>
      </div>
    )
  );
};

SideSection.propTypes = {
  children: PropTypes.object,
  onClose: PropTypes.func,
  className: PropTypes.string,
  visible: PropTypes.bool,
};

export default SideSection;
