import { createAsyncThunk, createSlice, SerializedError } from "@reduxjs/toolkit";
import axios, { CancelTokenSource } from "axios";
import { currencyFormatterV2 } from "utility";
import { AccountingCount, EWorkflowApiStatus, EWorkflowStatus, ITransaction } from "Views/Accounting/Workflow/type";
import {
  getAccountingCount,
  getAccountingWorkflow,
  IAccountingWorkflowOption,
} from "Redux/DataCalls/AccountingWorkflow.api";
import { PAGE_SIZE } from "Views/Accounting/Workflow/constant/constant";

import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import { ITransactionFilter } from "Views/Accounting/Workflow/TransactionFilter";

interface AccountingWorkflowState {
  data: {
    [EWorkflowApiStatus.FAILED_SYNC]?: {
      transactions: ITransaction[];
    };
    [EWorkflowApiStatus.COMPLETED]?: {
      transactions: ITransaction[];
    };
    [EWorkflowApiStatus.MISSING_INFO]?: {
      transactions: ITransaction[];
    };
    [EWorkflowApiStatus.QUEUED]?: {
      transactions: ITransaction[];
    };
    [EWorkflowApiStatus.PENDING_REVIEW]?: {
      transactions: ITransaction[];
    };
    count: AccountingCount; // This count is a "static" count, that will show total transaction for each statuses ignoring filter used by user
    transactionCount: AccountingCount; // This count is a "dynamic" count, that will show total transaction for each statuses including filter used by user
  };
  loading: boolean;
  keepLoading?: boolean;
  error: SerializedError;
}

interface IFetchAccountingWorkflow {
  filter: any;
  status: EWorkflowApiStatus;
  cancelable?: boolean;
  staticCount?: boolean;
  pg?: number;
  limit?: number;
  sortData?: [number, string];
  moveToPreviousPage?: boolean;
  skipCount?: boolean;
}

let requestToken: CancelTokenSource | null = null;

export const defaultCount: AccountingCount = {
  [EWorkflowApiStatus.COMPLETED]: 0,
  [EWorkflowApiStatus.FAILED_SYNC]: 0,
  [EWorkflowApiStatus.MISSING_INFO]: 0,
  [EWorkflowApiStatus.QUEUED]: 0,
  [EWorkflowApiStatus.PENDING_REVIEW]: 0,
};

const dummyTransaction = Array(10)
  .fill({})
  .map((_, index) => ({ id: `Mocked_transaction_${index}` } as ITransaction));

const defaultData = {
  [EWorkflowApiStatus.PENDING_REVIEW]: {
    transactions: dummyTransaction,
  },
  [EWorkflowApiStatus.MISSING_INFO]: {
    transactions: dummyTransaction,
  },
  [EWorkflowApiStatus.QUEUED]: {
    transactions: dummyTransaction,
  },
  [EWorkflowApiStatus.FAILED_SYNC]: {
    transactions: dummyTransaction,
  },
  [EWorkflowApiStatus.COMPLETED]: {
    transactions: dummyTransaction,
  },
};

const initialState: AccountingWorkflowState = {
  data: {
    ...defaultData,
    count: defaultCount,
    transactionCount: defaultCount,
  },
  loading: true,
  keepLoading: false,
  error: {},
};

export const workflowStatusMapping = (apiStatus: EWorkflowApiStatus) => {
  const library = {
    [EWorkflowApiStatus.COMPLETED]: EWorkflowStatus.COMPLETED,
    [EWorkflowApiStatus.FAILED_SYNC]: EWorkflowStatus.SYNC_FAILED,
    [EWorkflowApiStatus.MISSING_INFO]: EWorkflowStatus.INCOMPLETE_DETAILS,
    [EWorkflowApiStatus.QUEUED]: EWorkflowStatus.IN_QUEUE,
    [EWorkflowApiStatus.PENDING_REVIEW]: EWorkflowStatus.READY_TO_REVIEW,
  };

  return library[apiStatus];
};

const accountingStatusMapping = (transactionType: string) => {
  const library = {
    "Cash Reimbursement": "reimbursement",
    Topup: "topup",
    Incentive: "incentive",
    "Fees (Refund)": "fee-refund",
    Fees: "fee",
  };
  if (transactionType.includes("Bill")) {
    return "bill";
  }

  if (transactionType.includes("Card")) {
    return "card";
  }

  return library[transactionType];
};

const dataMapping: (transaction: any, status: EWorkflowApiStatus) => ITransaction[] = (transactions, status) => {
  return transactions.map((transaction) => ({
    id: transaction.id,
    createdAt: transaction.created_at,
    transactionNumber: transaction.transaction_number,
    transactionType: transaction.transaction_type,
    merchant: transaction.payee_name,
    amount: currencyFormatterV2(Math.abs(transaction.amount), transaction.currency, true, 2),
    currency: transaction.currency_code,
    employee: {
      id: transaction.payer_id,
      name: transaction.payer_name,
      team: transaction.team_name || "",
    },
    category: transaction?.category || "N.A.",
    receipt: !Array.isArray(transaction?.receipts) ? null : transaction?.receipts.map((receipt) => receipt.url),
    status: workflowStatusMapping(status),
    accountingMessage: transaction?.accounting_message || "",
    accountingTransactionStatus: accountingStatusMapping(transaction?.transaction_type),
    isDebit: transaction.amount > 0,
    sort: transaction?.sort,
    rawAmount: transaction.amount,
  }));
};

const capitalizeKey = (accountingCount): AccountingCount => {
  const result = {} as AccountingCount;

  for (const accountingStatus in accountingCount) {
    result[accountingStatus.toUpperCase()] = accountingCount[accountingStatus];
  }
  return result;
};

export const cleanFilter = (filter) => {
  const newFilter = {};

  Object.entries(filter).forEach(([key, value]) => {
    if (value) {
      newFilter[key] = value;
    }
  });

  return newFilter;
};

export const buildApiParams = (
  filters: Partial<ITransactionFilter>,
  accountingStatus: EWorkflowApiStatus,
  option: IAccountingWorkflowOption
) => {
  const params = new URLSearchParams();
  params.append("accounting_statuses", accountingStatus);
  params.append("exclude_types", "Transfer");
  params.append("limit", option.limit?.toString() || "100");

  if (option.sortData) {
    params.append("search_after", String(option.sortData[0]));
    params.append("search_after", String(option.sortData[1]));
  }

  if (option.moveToPreviousPage) {
    params.append("previous_page", "true");
  }

  if (Object.keys(filters).length !== 0) {
    Object.keys(filters).forEach((key) => {
      const value = filters[key];
      if (Array.isArray(value)) {
        value.forEach((value) => params.append(key, value));
      } else {
        params.append(key, String(value));
      }
    });
  }

  return params;
};

export const fetchAccountingWorkflow = createAsyncThunk(
  "accountingWorkflow",
  async (
    {
      filter,
      status,
      limit = PAGE_SIZE,
      cancelable = false,
      sortData,
      moveToPreviousPage,
      skipCount = false,
    }: IFetchAccountingWorkflow,
    { rejectWithValue, getState }
  ) => {
    try {
      const payload: IAccountingWorkflowOption = { limit, sortData, moveToPreviousPage };
      if (cancelable) {
        if (requestToken?.cancel) requestToken.cancel("Cancel duplicated request");
        requestToken = axios.CancelToken.source();
        payload.cancelToken = requestToken.token;
      }
      const storedAccountingCount = (getState() as any)?.accountingWorkflowReducer?.data?.count;
      const params = buildApiParams(cleanFilter(filter), status, payload);
      const { data, status: httpStatus }: any = await getAccountingWorkflow(params);

      let accountingCount = storedAccountingCount;
      if (!skipCount) {
        const { data } = await getAccountingCount();
        accountingCount = data;
      }

      if (httpStatus === HTTP_STATUS_CODE.OK) {
        return {
          transactions: dataMapping(data.data || [], status),
          accountingCount,
          status,
          limit,
          count: data.count,
        };
      }
      return rejectWithValue({ ...data, accountingCount: defaultCount });
    } catch (error) {
      const rejectValue = {
        transactions: [],
        accountingCount: defaultCount,
        status,
        keepLoading: false,
      };

      if (axios.isCancel(error)) {
        rejectValue.keepLoading = true;
      }
      return rejectWithValue(rejectValue);
    }
  }
);

export const fetchAccountingSummary = createAsyncThunk("accountingSummary", async () => {
  const { data: accountingCount, status } = await getAccountingCount();

  if (status === HTTP_STATUS_CODE.OK) {
    return {
      accountingCount,
    };
  }
});

const accountingWorkflowSlice = createSlice({
  name: "accountingWorkflow",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAccountingWorkflow.pending, (state, action) => {
      const status = action.meta.arg.status;

      // To show shimmer effect when loading reset the data to the defaullt
      state.data = {
        ...state.data,
        [status]: {
          transactions: dummyTransaction,
        },
      };
      state.loading = true;
    });
    builder.addCase(fetchAccountingWorkflow.fulfilled, (state, action) => {
      const accountingData = {
        ...defaultData,
        ...state.data,
        count: capitalizeKey(action.payload.accountingCount),
        transactions: action.payload.transactions,
        transactionCount: {
          ...state.data.transactionCount,
          [action.payload.status]: action.payload.count,
        },
        [action.payload.status]: {
          transactions: action.payload.transactions,
        },
      };

      state.loading = false;
      state.data = accountingData;
    });
    builder.addCase(fetchAccountingWorkflow.rejected, (state, action) => {
      const payload = action.payload as any;
      state.loading = payload.keepLoading;
      state.data = {
        ...defaultData,
        ...state.data,
        [payload.status]: {
          transactions: dummyTransaction,
        },
      };
      state.error = action.error;
    });
    builder.addCase(fetchAccountingSummary.fulfilled, (state, action) => {
      state.data.count = capitalizeKey(action.payload.accountingCount);
    });
  },
});

export const accountingWorkflowReducer = accountingWorkflowSlice.reducer;
