import React, { useContext } from "react";

import Button from "Modules/button";
import RichHeader from "Modules/DS/RichHeader";

import { KYXModalContext } from "Views/KYX/Context";
import { GuidelinesData } from "Views/KYX/Constants";
import { IGuidelinesButton, IGuidelinesInfo, IKYXModalContext } from "Views/KYX/@types";

import GuidelinesContent from "./Content";

import styles from "./index.module.scss";

const Guidelines = ({ type, action }: IGuidelinesButton): JSX.Element => {
  const details: IGuidelinesInfo = GuidelinesData[type];
  const { resetModal }: IKYXModalContext = useContext(KYXModalContext);

  return (
    <>
      <RichHeader title={details?.title} onClose={resetModal} />
      <GuidelinesContent data={details?.data} />
      {action && (
        <div className={styles.button}>
          <Button size="l" rounded action={action}>
            Continue
          </Button>
        </div>
      )}
    </>
  );
};

export default Guidelines;
