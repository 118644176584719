import {
  CHANGE_PHONE_NUMBER_REQUEST,
  CHANGE_PHONE_NUMBER_SUCCESS,
  CHANGE_PHONE_NUMBER_FAILURE,
  RESET_CHANGE_PHONE_NUMBER,
} from "Redux/Actions/OTP/changePhoneNumberAction";
import { userInitialState } from "Redux/InitialState";

export const changePhoneNumberReducer = (state = userInitialState, action) => {
  switch (action.type) {
    case CHANGE_PHONE_NUMBER_REQUEST:
      return {
        ...state,
        loading: true,
        data: action.data,
        error: false,
      };
    case CHANGE_PHONE_NUMBER_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: false,
      };
    case CHANGE_PHONE_NUMBER_FAILURE:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: true,
      };
    case RESET_CHANGE_PHONE_NUMBER:
      return {
        ...state,
        is_success: false,
        status_message: null,
        data: null,
      };
    default:
      return state;
  }
};
