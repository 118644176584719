import { useContext, useState } from "react";

import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";

import { getKYXFormData } from "Views/KYX/DataCalls";
import { KYXToastContext, KYXErrorToaster } from "Views/KYX/Context";
import { setCookie, cookieNames } from "utility/CookieHelper";
import { IGetKYXFormData, IFetchFormSubmissionPayload, IKYXToastContext } from "Views/KYX/@types";

/**
 * A custom hook that handles the process of getting KYX form data.
 *
 * PSA: Ensure that this hook is used within the bounds of KYXToastContext.Provider
 *
 * @param {((payload: IFormPayload) => void)} dispatchOnSuccess (optional) - A callback function that gets executed when the form data is successfully retrieved.
 * @returns {IGetKYXFormData} An object containing getFormData and loading properties.
 *
 * This hook uses getKYXFormData function internally to make an HTTP request to retrieve KYX form data.
 * It also checks the response status and throws an error if the response status is not 200.
 */

export const useGetKYXFormData = (
  dispatchOnSuccess?: (payload: IFetchFormSubmissionPayload) => void
): IGetKYXFormData => {
  const [loading, setLoading] = useState(false);
  const { setToaster } = useContext<IKYXToastContext>(KYXToastContext);

  const getFormData = async () => {
    try {
      setLoading(true);

      const response = await getKYXFormData();

      if (response.status !== HTTP_STATUS_CODE.OK) {
        throw new Error(response.statusText);
      }

      const data = response.data?.payload;
      const errorMessage = response.data?.error?.message || response.data?.meta?.message;

      if (response?.data?.status === HTTP_STATUS_CODE.OK) {
        setCookie(cookieNames.KYB_SOURCE, data?.source);
        dispatchOnSuccess?.(data);
      } else {
        throw new Error(errorMessage);
      }
    } catch (error) {
      const toast = KYXErrorToaster(
        error?.response?.data?.error?.message || error?.response?.data?.errors?.[0]?.message || error?.message
      );
      setToaster(toast);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return { getFormData, loading };
};
