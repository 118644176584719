import React from "react";
import classNames from "classnames";
import { ProgressColorTypes } from "./Constants";
export const ExpenseProgressCircle = ({ remainingTeamLimit, totalTeamLimit, totalExpense }) => {
  if (remainingTeamLimit && totalTeamLimit && totalExpense) {
    const LIMIT = 100;
    const teamExpenseInPercentage = Math.floor((remainingTeamLimit / totalTeamLimit) * 100);
    const overLimit = teamExpenseInPercentage > LIMIT;
    const _className = classNames("expense-card__progress-bar-inner-text", {
      "expense-card__progress-bar-inner-text__red": overLimit,
    });
    return (
      <div
        className="expense-card__progress-bar"
        style={{
          background: overLimit
            ? ProgressColorTypes.RED
            : `conic-gradient(${ProgressColorTypes.BLUE} ${teamExpenseInPercentage * 3.6}deg,
            ${ProgressColorTypes.GREEN} ${teamExpenseInPercentage * 3.6}deg`,
        }}
      >
        <div className={_className}>{teamExpenseInPercentage}%</div>
      </div>
    );
  }
  return null;
};
