import React from "react";
import { Tooltip, WarningFilled } from "@spenmo/splice";
import { getMonthName, currencyFormatterWithFractions } from "utility";
import * as ExpenseComponents from "Views/Home/Components/ExpenseCard/Common";
import { TitleColorTypes } from "Views/Home/Components/ExpenseCard/Common/Constants";
import { useIsSaasWithPaymentsOrg } from "customHooks/useIsSaasWithPaymentsOrg";

const ExpenseCard = ({
  remainingTeamLimit,
  currencyCode,
  totalTeamLimit,
  totalExpense,
  id,
}) => {
  const isSaasWithPaymentsOrg = useIsSaasWithPaymentsOrg();

  const getDayMonthYear = () => {
    const date = new Date();
    return {
      month: getMonthName(date, true),
      monthInNumber: date.getMonth() || "",
      day: date.getDate() || "",
      year: date.getFullYear() || "",
    };
  };

  const getValidityDates = () => {
    const { month, day, year } = getDayMonthYear();
    return `01 ${month} - ${day} ${month} ${year}`;
  };

  const getExpiryDate = () => {
    const date = new Date();
    const lastDayOfMonth = new Date(
      date.getFullYear(),
      date.getMonth() + 1,
      0,
    ).getDate();
    const { month, year } = getDayMonthYear();
    return `Expires on ${lastDayOfMonth} ${month} ${year}`;
  };

  const addZeroPrefix = (number) => {
    return number < 9 ? `0${number}` : number;
  };

  const getFilterStartAndEndDateParams = () => {
    let { monthInNumber, day, year } = getDayMonthYear();
    monthInNumber = addZeroPrefix(monthInNumber + 1);
    day = addZeroPrefix(day);
    return `startDate=${year}-${monthInNumber}-01&endDate=${year}-${monthInNumber}-${day}`;
  };

  const remainingBudget = Number(totalTeamLimit) - Number(totalExpense);

  return (
    <>
      <div>
        <ExpenseComponents.ExpenseDetailTitle
          title={isSaasWithPaymentsOrg ? "Spent" : "Est. Spend"}
          color={TitleColorTypes.GREEN}
          to={
            isSaasWithPaymentsOrg
              ? `/transactions?tab=all&teamId=${id}&${getFilterStartAndEndDateParams()}`
              : "/"
          }
        />
        <ExpenseComponents.Expense
          altText="N/A"
          expense={totalExpense}
          currencyCode={currencyCode}
        >
          {totalTeamLimit && remainingBudget < 0 && (
            <Tooltip
              title={`You have exceeded your budget limit by ${currencyFormatterWithFractions(
                Math.abs(remainingBudget),
                currencyCode,
                2,
              )}. Kindly review your spending or increase your limit as needed.`}
            >
              <WarningFilled size="16" iconColor="var(--text-body-danger)" />
            </Tooltip>
          )}
        </ExpenseComponents.Expense>
        {totalExpense && (
          <p className="expense-card__expense-detail-validity">
            {getValidityDates()}
          </p>
        )}
      </div>
      <div className="sp-margin-left--xl">
        <ExpenseComponents.ExpenseDetailTitle
          title={isSaasWithPaymentsOrg ? "Remaining" : "Limit"}
          color={TitleColorTypes.BLUE}
          to={{ pathname: `/teamprofile/${id}`, search: "?tab=policies" }}
        />
        <ExpenseComponents.Expense
          altText="Unlimited"
          expense={isSaasWithPaymentsOrg ? remainingTeamLimit : totalTeamLimit}
          currencyCode={currencyCode}
        />
        {(isSaasWithPaymentsOrg ? remainingTeamLimit : totalTeamLimit) && (
          <p className="expense-card__expense-detail-validity">
            {getExpiryDate()}
          </p>
        )}
      </div>
      <ExpenseComponents.ExpenseProgressCircle
        remainingTeamLimit={remainingTeamLimit}
        totalTeamLimit={totalTeamLimit}
        totalExpense={totalExpense}
      />
    </>
  );
};

export default ExpenseCard;
