import { defaultStyles } from "@visx/tooltip";
import { scaleBand, scaleLinear, scaleOrdinal } from "@visx/scale";
import { SpendAnalysisType } from "Views/SubscriptionManagement/@types";

export const generateAnalysisData: (data: any) => SpendAnalysisType[] = (data) => {
  const analysisData = [];
  const currentMonth = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();

  for (const key in data) {
    const { month, year, estimated, actual } = data[key] || {};

    if (month > currentMonth && year >= currentYear) {
      analysisData.push({
        date: `${year}-${padStart(month)}-01`,
        forecast: estimated.toFixed(2),
      });
    } else {
      analysisData.push({
        date: `${year}-${padStart(month)}-01`,
        estimated: estimated.toFixed(2),
        actual: actual.toFixed(2),
      });
    }
  }

  return analysisData;
};

export const getMaxValue = (data: SpendAnalysisType[]) => {
  return Math.max(
    ...data.flatMap((data) => [Number(data.estimated) || 0, Number(data.actual) || 0, Number(data.forecast) || 0])
  );
};

export const chartStyle = {
  estimatedColor: "var(--navy-060)",
  actualColor: "var(--blue-040)",
  forecastColor: "var(--blue-010)",
  defaultColor: "var(--text-body-default)",
  borderSubtle: "var(--border-neutral-subtle)",
  background: "var(--white-0)",
  defaultMargin: { top: 20, right: 0, bottom: 40, left: 0 },
  fontFamily: "var(--font-noto-sans)",
  bodySubtle: "var(--text-body-subtle)",
  tooltipStyle: {
    ...defaultStyles,
    minWidth: 60,
    backgroundColor: "var(--white-0)",
    boxShadow: "0px 0px 8px 1px rgba(51, 55, 68, 0.10)",
    padding: "var(--spacing-004) var(--spacing-008)",
    borderRadius: "var(--spacing-008)",
  },
};

export const scale = {
  ordinalColorScale: scaleOrdinal({
    domain: ["Estimated", "Actual", "Forecast"],
    range: [chartStyle.estimatedColor, chartStyle.actualColor, chartStyle.forecastColor],
  }),
  dateScaleFn: (data) => {
    return scaleBand<string>({
      domain: data.map(getDate),
      padding: 0.2,
    });
  },
  spendTypeScaleFn: (keys) => {
    return scaleBand<string>({
      domain: keys,
      padding: 0.2,
    });
  },
  spendAmountScaleFn: (data) => {
    return scaleLinear<number>({
      domain: [0, getMaxValue(data)],
    });
  },
  colorScale: (keys) => {
    const { estimatedColor, actualColor, forecastColor } = chartStyle;

    return scaleOrdinal<string, string>({
      domain: keys,
      range: [estimatedColor, actualColor, forecastColor],
    });
  },
};

export const cleanBarGroups = (barGroups, data) => {
  return barGroups.map((barGroups, index) => {
    let x0 = Number(barGroups.x0);

    if (Object.keys(data[index]).length === 2) {
      x0 -= 10;
    }

    if (data[index].actual === 0 && !data[index].forecast) {
      // When there is no actual payment but there's an estimation
      // Shift the bar for the estimated payment to be centered as the x chart label
      x0 += 10;
    }

    return {
      ...barGroups,
      x0: String(x0),
      bars: barGroups.bars
        .filter((bar) => Boolean(bar.value))
        .map((bar) => ({
          ...bar,
          date: data[index].date,
        })),
    };
  });
};

export const getDate = (d) => d.date;

const padStart = (index: number) => String(index).padStart(2, "0");
