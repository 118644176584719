import Button from "Modules/button";
import { Form, Select } from "antd";
import * as React from "react";
import { useState } from "react";
import "./index.scss";
import CurrencyInput from "react-currency-input-field";
import { getCurrencyLocale } from "utility";
import { onlyPositiveIntegersWithFractionsRegEx } from "constants/RegularExpressions.constant";
import { FilterKey } from "../../types";
import { getCookieValue, cookieNames } from "utility/CookieHelper";

const { Option } = Select;

export interface currency {
  id: number;
  currency_code: string;
  currency_description: string;
  iso_currency_code: string;
  country: string;
}

interface IAmountSendProps {
  currencyData: currency[];
  handleSubmit: (key, object) => void;
  initialMinAmount: string;
  initialMaxAmount: string;
  filterKey: string;
  initialCurrency?: string;
}

const AmountFilter = ({
  currencyData,
  initialMinAmount,
  initialMaxAmount,
  handleSubmit,
  filterKey,
  initialCurrency,
}: IAmountSendProps) => {
  const [form] = Form.useForm();
  const isFilterEnabled = [
    FilterKey.Amount_Receive,
    FilterKey.Invoice_Amount,
  ].includes(filterKey as FilterKey);
  const orgCurrencyCode = isFilterEnabled
    ? null
    : getCookieValue(cookieNames.CURRENCY_CODE);
  const [selectedCurrency, setSelectedCurrency] = useState(
    initialCurrency ?? orgCurrencyCode,
  );

  const [minAmount, setMinAmount] = useState(initialMinAmount);
  const [maxAmount, setMaxAmount] = useState(initialMaxAmount);

  return (
    <div className={`amount-filter ${filterKey}`}>
      <Form
        name="amountForm"
        form={form}
        onFinish={(values) => handleSubmit(filterKey, values)}
      >
        <Form.Item name="currency_code" label="Currency">
          <Select
            value={selectedCurrency}
            defaultValue={selectedCurrency}
            disabled={
              FilterKey.Amount_Send === filterKey ||
              FilterKey.Amount === filterKey
            }
            className={"amount-filter__select"}
            placeholder={"Select currency"}
            onChange={(currency_code) => {
              form.setFieldsValue({ curreny_code: currency_code });
              setSelectedCurrency(currency_code);
            }}
          >
            {currencyData?.map?.((currency) => (
              <Option key={currency.id} value={currency.currency_code}>
                {`${currency.currency_code}`}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item className="amount-filter__range">
          <div className="amount-filter__range__input">
            <Form.Item name="min_amount" label="Min" />
            <CurrencyInput
              name="min_amount"
              value={minAmount}
              allowDecimals={true}
              placeholder="Min Amount"
              allowNegativeValue={false}
              intlConfig={{ locale: getCurrencyLocale("SGD") }}
              prefix={""}
              decimalScale={2}
              onValueChange={(value = "") => {
                if (
                  onlyPositiveIntegersWithFractionsRegEx?.test?.(value) ||
                  value === ""
                ) {
                  form.setFieldsValue({ min_amount: value });
                  setMinAmount(value);
                }
              }}
            />
          </div>
          <div className="amount-filter__range__input">
            <Form.Item name="max_amount" label="Max" />
            <CurrencyInput
              name="max_amount"
              value={maxAmount}
              allowDecimals={true}
              placeholder="Max Amount"
              allowNegativeValue={false}
              intlConfig={{ locale: getCurrencyLocale(selectedCurrency) }}
              prefix={""}
              decimalScale={2}
              onValueChange={(value = "") => {
                if (
                  onlyPositiveIntegersWithFractionsRegEx?.test?.(value) ||
                  value === ""
                ) {
                  form.setFieldsValue({ max_amount: value });
                  setMaxAmount(value);
                }
              }}
            />
          </div>
        </Form.Item>
        <div className="filter-popover__action">
          <Button
            className="filter-popover__action__button--clear"
            type="button"
            textButton
            action={() => {
              setSelectedCurrency(orgCurrencyCode);
              setMinAmount("");
              setMaxAmount("");
              form.resetFields();
            }}
          >
            Clear
          </Button>
          <Button className="filter-popover__action__button--submit">
            Apply
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default AmountFilter;
