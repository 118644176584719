import APIclient from "API/Client";

import { source } from "Redux/Actions";

import { GetOrgId } from "utility";

import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";

export const FETCH_TEAM_MEMBERS = "FETCH_TEAM_MEMBERS_BY_TEAM_ID";
export const FETCH_TEAM_MEMBERS_REQUEST = `${FETCH_TEAM_MEMBERS}_REQUEST`;
export const FETCH_TEAM_MEMBERS_SUCCESS = `${FETCH_TEAM_MEMBERS}_SUCCESS`;
export const FETCH_TEAM_MEMBERS_FAILURE = `${FETCH_TEAM_MEMBERS}_FAILURE`;
export const CLEAR_TEAM_MEMBERS_DATA = "CLEAR_TEAM_MEMBERS_DATA";

export const fetchDataRequest = () => {
  return {
    type: FETCH_TEAM_MEMBERS_REQUEST,
  };
};

export const fetchDataSuccess = (data) => {
  return {
    type: FETCH_TEAM_MEMBERS_SUCCESS,
    data: data,
  };
};

export const fetchDataError = (data) => {
  return {
    type: FETCH_TEAM_MEMBERS_FAILURE,
    data: data,
  };
};

export const clearTeamMembersByTeamIdData = () => {
  return {
    type: CLEAR_TEAM_MEMBERS_DATA,
  };
};

export const getTeamMembersByTeamId = (teamId, page) => {
  return (dispatch) => {
    dispatch(fetchDataRequest());
    APIclient.getData(
      `/api/v1/org/${GetOrgId()}/team-members?${page ? "pg=" + page + "&limit=15" : ""}${
        teamId ? "&team_id=" + teamId : ""
      }`
    )
      .then((data) => {
        if (data.data.status > HTTP_STATUS_CODE.OK) {
          source.cancel("Operation canceled for error");
        } else {
          return data;
        }
      })
      .then((data) => {
        const { members } = data.data.payload;
        dispatch(fetchDataSuccess(members));
      })
      .catch((err) => {
        dispatch(fetchDataError(err));
      });
  };
};
