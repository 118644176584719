import React from "react";
import PropTypes from "prop-types";
import { pdfjs, Document, Page } from "react-pdf";
import cn from "classnames";

import Icon from "Modules/icons";
import Modal from "Modules/modal";
import CarouselPreview from "Modules/DS/CarouselFiles";

import { receiptsIcon, receiptsGreyIcon, fileIcon } from "assets/img";
import { isImage } from "utility";
import "./ImageCarousel.scss";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

class ImageCarousel extends React.Component {
  static propTypes = {
    images: PropTypes.array,
    count: PropTypes.number,
    message: PropTypes.string,
    showImagePreview: PropTypes.bool,
    lessMargin: PropTypes.bool,
    className: PropTypes.string,
  };

  state = {
    showModal: false,
  };

  toggleModal = () => {
    this.setState({ showModal: !this.state.showModal });
  };

  render() {
    const { images, count, message, showImagePreview, lessMargin, className = "" } = this.props;

    if (this.state.showModal) {
      return (
        <Modal show={true} className="image-carousel image-carousel__modal-preview" close={this.toggleModal}>
          {images?.length > 0 ? <CarouselPreview files={images} /> : <p>{message || "No Images!"}</p>}
        </Modal>
      );
    }

    if (showImagePreview || this.state.showModal) {
      return images?.length > 0 ? (
        <div className="image-carousel__receipts image-carousel__inline-preview">
          {images.map((receipt, index) => {
            if (receipt.trim() !== "" && receipt.indexOf(".pdf") > 0) {
              return (
                <a rel="noreferrer" className="image-carousel__receipts--source-link" href={receipt} target="_blank">
                  <Document file={receipt}>
                    <Page pageNumber={1} />
                  </Document>
                </a>
              );
            } else if (isImage(receipt)) {
              return (
                <a rel="noreferrer" className="image-carousel__receipts--source-link" href={receipt} target="_blank">
                  <img src={receipt} key={index} className="image-carousel__receipts--image" alt="receipt" />
                </a>
              );
            } else {
              return (
                <a rel="noreferrer" href={receipt} target="_blank">
                  <img src={fileIcon} alt="receipt" className="receipt-image" />
                </a>
              );
            }
          })}
        </div>
      ) : (
        <p style={{ paddingTop: "10px" }}>{message || "No Images!"}</p>
      );
    }

    return (
      <Icon
        src={images.length > 0 ? receiptsIcon : receiptsGreyIcon}
        title={count ? String(count) : ""}
        alt="receipt icon"
        action={(e) => {
          this.toggleModal();
          e.stopPropagation();
        }}
        className={cn("image-carousel--icon", { "less-margin": lessMargin }, className)}
      />
    );
  }
}

export default ImageCarousel;
