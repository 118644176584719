import React, { useEffect, useState, useContext, useCallback } from "react";
import Zendesk from "react-zendesk";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { SPENMO_SUPPORT } from "constants/SpenmoSupport.constant";

import { SpenmoSupportContext, SUPPORT_STATUS } from "Views/SpenmoSupport/common";

import { settings, setJWTToken } from "./ZenDeskSettings";
import { SUPPORT_CHAT_SEARCH_PARAMS } from "Views/SpenmoSupport/common/SearchParams.constants";
import useDefaultTeam from "utility/useDefaultTeam";

const ZenDeskChat = () => {
  const [userDetails, setUserDetails] = useState(null);
  const [widgetLoaded, setWidgetLoaded] = useState(false);
  const history = useHistory();
  const { search: searchParams } = history?.location || {};
  const API_BASE_URL = window.__ENV__.API_BASE_URL;
  const shouldEnableZendesk = API_BASE_URL === "https://api.spenmo.com";
  const triggerSupportChatFromDeeplink =
    searchParams?.includes(`${SUPPORT_CHAT_SEARCH_PARAMS.SHOW_SUPPORT_CHAT}`) || false;

  const userData = useSelector((state) => state.user?.data?.user);

  const organisationName = useDefaultTeam()?.team_name;

  const { setSupportStatus, showSupportChat, supportStatus, setShowSupportChat, shifting, supportChatRef } =
    useContext(SpenmoSupportContext);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      showSupportChat && !supportChatRef.current?.contains(e.target) && setShowSupportChat(false);
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [supportChatRef, showSupportChat, setShowSupportChat]);

  useEffect(() => {
    const widget = document.querySelector("iframe#webWidget");

    widget && widget.classList.add(shifting ? "shift-support" : "shift-support-exit");

    return () => {
      widget && widget.classList.remove("shift-support", "shift-support-exit");
    };
  }, [shifting]);

  let showWidget = showSupportChat && supportStatus === SUPPORT_STATUS.ONLINE;

  const openWidget = useCallback(() => {
    if (showWidget) {
      window.zE("webWidget", "show");
      window.zE("webWidget", "open");
    } else {
      window.zE("webWidget", "hide");
    }
  }, [showWidget]);

  const setIdentity = useCallback(() => {
    window.zE &&
      window.zE("webWidget", "identify", {
        name: userData?.name,
        email: userData?.email,
        organization: organisationName,
      });
  }, [organisationName, userData]);

  useEffect(() => {
    window.zE && openWidget();
  }, [openWidget]);

  useEffect(() => {
    shouldEnableZendesk && setUserDetails(setJWTToken(userData));
  }, [showWidget, userData, shouldEnableZendesk]);

  useEffect(() => {
    if (triggerSupportChatFromDeeplink) {
      setShowSupportChat(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  useEffect(() => {
    widgetLoaded && setIdentity();
  }, [widgetLoaded, setIdentity]);

  const closeWidget = () => {
    window.zE("webWidget:on", "close", () => {
      window.zE("webWidget", "hide");
      setShowSupportChat(false);
    });
  };

  /* We need to show the contactForm first, when user comes from customer support deeplink,
      since we cannot pre-populate the textbox in the chat support. So we are setting the
      support status to offline to show contactForm first.
  */
  const checkChatStatus = () => {
    window.zE("webWidget:on", "chat:status", (status) => {
      status === SUPPORT_STATUS.ONLINE && !triggerSupportChatFromDeeplink
        ? setSupportStatus(SUPPORT_STATUS.ONLINE)
        : setSupportStatus(SUPPORT_STATUS.OFFLINE);
    });
  };

  const handleZenDeskLoad = () => {
    setWidgetLoaded(true);
    openWidget();
    closeWidget();
    checkChatStatus();
  };

  return (
    <>
      {userDetails && (
        <Zendesk
          id="zenDeskWidget"
          zendeskKey={SPENMO_SUPPORT.ZENDESK_KEY}
          {...settings(userDetails)}
          onLoaded={handleZenDeskLoad}
        />
      )}
    </>
  );
};

export default ZenDeskChat;
