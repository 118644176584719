import { IDocumentStep, IPersonForm } from "Views/KYX/@types";
import { DOCUMENT_STEP_TYPE, FORM_SECTION_COMPONENT, KYX_FORMS_KEYS } from "Views/KYX/Constants";
import { usePersonnelInformation } from "./PersonnelInfo";
import { useNRICForm } from "./NRIC";

export const PersonnelForm = (formData: IPersonForm, isFormDisabled: boolean): any => {
  return {
    key: KYX_FORMS_KEYS.PERSONNEL_DATA_PERSON,
    type: DOCUMENT_STEP_TYPE.MULTIPLE_STEPS,
    container: { type: FORM_SECTION_COMPONENT.CARD, props: { title: "Personnel Information" } },
    documents: [
      // Personnel Information
      usePersonnelInformation(formData, isFormDisabled),
      // NRIC
      useNRICForm(formData, isFormDisabled),
    ],
  };
};

export const usePersonnelInfoFormSGD = (formData: IPersonForm, isFormDisabled: boolean): IDocumentStep[] => {
  return [PersonnelForm(formData, isFormDisabled)];
};
