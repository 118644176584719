import React from "react";
import { EmptyState } from "@spenmo/splice";
import { useHistory } from "react-router-dom";

import { useAnalytic } from "Views/Settings/hooks";
import { BodySkeleton } from "Views/Settings/components";

import useCRState from "./useCRState";
import ReimbursementSettingsPageContainer from "./Container";

import styles from "./styles.module.scss";

const ReimbursementSettings = () => {
  const history = useHistory();

  useAnalytic({ eventSource: "Company", eventSubSource: "Reimbursements" });

  const { loading, showEmptyState, containerProps } = useCRState();

  if (loading) {
    return <BodySkeleton paddingLess />;
  }

  if (showEmptyState) {
    return (
      <div className={styles.noAccessPage}>
        <EmptyState
          description="Reach out to your Spenmo administrator for access"
          headline="You do not have access to this page"
          actionButtonText="Back to Homepage"
          onClickActionButton={() => history.push("/")}
        />
      </div>
    );
  }

  return <ReimbursementSettingsPageContainer {...containerProps} />;
};

export default ReimbursementSettings;
