import { FETCH_CARD_CVV_ACTIONS, ICardCvvData } from "Redux/Actions/Card/Cvv";
import { InitialState } from "Redux/Reducers/Card/Utilites/index";

interface ICardCvvReducerAction {
  type: FETCH_CARD_CVV_ACTIONS;
  data: ICardCvvData | {};
}

interface ICardNumberStateType {
  loading: boolean;
  error: boolean;
  data: ICardCvvData | {};
}

export const cardCvvReducer = (state: ICardNumberStateType = InitialState, action: ICardCvvReducerAction) => {
  switch (action.type) {
    case FETCH_CARD_CVV_ACTIONS.FETCH_CARD_CVV_REQUEST:
      return {
        ...state,
        data: action?.data,
        loading: true,
        error: false,
      };
    case FETCH_CARD_CVV_ACTIONS.FETCH_CARD_CVV_SUCCESS:
      return {
        ...state,
        data: action?.data,
        error: false,
        loading: false,
      };
    case FETCH_CARD_CVV_ACTIONS.FETCH_CARD_CVV_FAILURE:
      return {
        ...state,
        loading: false,
        data: action?.data,
        error: true,
      };
    default:
      return state;
  }
};
