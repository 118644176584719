import { createContext } from "react";

import { TOASTER_SIZE_TYPE, TOASTER_STATUS_TYPE } from "Modules/DS/Toaster";

import { DEFAULT_ERROR_MESSAGE } from "Views/KYX/Constants";
import { IKYXToastContext, IKYXToastProps } from "Views/KYX/@types";

/**
 * The initial KYX toast state values.
 * @type {IKYXToastProps}
 */
export const initialKYXToastState: IKYXToastProps = {
  visible: false,
  message: DEFAULT_ERROR_MESSAGE,
  status: TOASTER_STATUS_TYPE.ERROR,
  actionLabel: "",
  action: () => null,
  className: "",
  onClose: () => null,
  size: TOASTER_SIZE_TYPE.S,
  timeout: 0,
};

/**
 * The initial KYX toast context values.
 * @type {IKYXToastContext}
 */
export const initialKYXToast: IKYXToastContext = {
  toaster: initialKYXToastState,
  setToaster: () => null,
  resetToaster: () => null,
};

/**
 * Creates a KYX toast configuration for KYX API error toaster.
 * @param {string} message - The error message to be displayed in the toaster.
 * @returns {IKYXToastProps} The configuration for the error toaster.
 */
export const KYXErrorToaster = (message: string): IKYXToastProps => ({
  visible: true,
  message: message,
  size: TOASTER_SIZE_TYPE.S,
  status: TOASTER_STATUS_TYPE.ERROR,
});

/**
 * The KYX toast context.
 * @type {IKYXToastContext}
 */
export const KYXToastContext: React.Context<IKYXToastContext> = createContext<IKYXToastContext>(initialKYXToast);
