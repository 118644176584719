import React, { useMemo, useReducer } from "react";
import { createAction, createReducer, PayloadAction } from "@reduxjs/toolkit";

import TeamActionContext from "Views/Team/List/Actions/context";
import { TEAM_ACTION, TEAM_ACTION_STATUS } from "Views/Team/List/Actions/constant";
import { ITeamAction, ITeamActionContext, ITeamActionEvent, IFilterValue } from "Views/Team/List/Actions/type";
import { ITeam } from "Views/Team/type";

const initialState: ITeamAction = {
  selectedTeam: null,
  selectedAction: null,
  event: {
    status: null,
    data: null,
    name: null,
  },
  filter: null,
};

const setAction = createAction<TEAM_ACTION>("setAction");
const setTeam = createAction<ITeam>("setTeam");
const setEvent = createAction<ITeamActionEvent>("setEvent");
const resetEvent = createAction<void>("resetEvent");
const applyFilter = createAction<IFilterValue>("applyFilter");
const clearFilter = createAction<string>("clearFilter");

const teamActionReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setAction, (state, action) => {
      state.selectedAction = action.payload;
    })
    .addCase(setTeam, (state, action) => {
      state.selectedTeam = action.payload;
    })
    .addCase(setEvent, (state, action: PayloadAction<ITeamActionEvent>) => {
      state.event = action.payload;
    })
    .addCase(resetEvent, (state, action) => {
      state.event = initialState.event;
    })
    .addCase(applyFilter, (state, action) => {
      const appliedFilter = Object.assign(state?.filter || {});
      appliedFilter[action.payload.name] = action.payload.value;
      state.filter = appliedFilter;
    })
    .addCase(clearFilter, (state, action) => {
      delete state.filter[action.payload];
    });
});

const TeamActionProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(teamActionReducer, initialState);
  const { selectedAction, event, filter } = state;

  const selectAction = (action: TEAM_ACTION) => dispatch(setAction(action));
  const selectTeam = (action: ITeam) => dispatch(setTeam(action));
  const updateEvent = (status: TEAM_ACTION_STATUS, name: TEAM_ACTION, data: any) =>
    dispatch(setEvent({ status: status, data: data, name: name }));
  const clearEvent = () => dispatch(resetEvent());
  const onApplyFilter = (action: IFilterValue) => dispatch(applyFilter(action));
  const resetFilter = (action: string) => dispatch(clearFilter(action));

  const teamActionContext: ITeamActionContext = useMemo(() => {
    return { state, selectAction, selectTeam, updateEvent, clearEvent, onApplyFilter, resetFilter };
  }, [selectedAction, event, filter]);

  return <TeamActionContext.Provider value={teamActionContext}>{children}</TeamActionContext.Provider>;
};

export default TeamActionProvider;
