import * as React from "react";
import { useState } from "react";
import classNames from "classnames";

import singularOrPlural from "utility/Helper/singularOrPlural";

import Icon from "Modules/icons";
import Button from "Modules/button";

import { IPending } from "Views/Employee/types";

import { LineIcon } from "assets/v1.1/icons";
import styles from "./Pending.module.scss";

export const Pending = ({ title, description, list, button }: IPending) => {
  const { data = [], collapsed = false, unit } = !!list && list;

  const [collapse, setCollapse] = useState(collapsed);

  const collapsedText =
    collapse && !!unit && !!data && data.length > 2
      ? `+${data.length - 2} more ${singularOrPlural(data.length - 2, unit)}`
      : "";

  const renderItemHeader = () => {
    return (
      <div data-testid="item header" className={styles.item}>
        <Icon
          alt="pending-item"
          className={styles.item__icon}
          src={LineIcon.orangeInfoCircle}
          data-testid="item header icon"
        />
        <h5 className={styles.item__title}>{title}</h5>
      </div>
    );
  };

  const renderPendingList = () => {
    return (
      !!data &&
      data.length > 0 && (
        <div data-testid="list container" className={styles.list__container}>
          <ul data-testid="list">{collapse ? data.slice(0, 2) : data}</ul>
          {collapse && data.length > 2 && (
            <p data-testid="view more" className={styles.view__more} onClick={() => setCollapse(!collapse)}>
              {collapsedText}
            </p>
          )}
        </div>
      )
    );
  };

  const renderButton = () => {
    return (
      button && (
        <div className={styles.action__button}>
          <Button data-testid="button" action={button.action}>
            {button.text}
          </Button>
        </div>
      )
    );
  };

  const className = !!data && data.length > 0 ? styles.action__list : "";

  return (
    <div data-testid="pending requirement" className={classNames(styles.container, className)}>
      {renderItemHeader()}
      <p className={styles.item__description}>{description}</p>
      {renderPendingList()}
      {renderButton()}
    </div>
  );
};
