import React from "react";
import { Typography } from "@spenmo/splice";

const DeactivationInfo = () => (
  <>
    <Typography tag="p" size="m" variant="body-content">
      Deactivating Google Authenticator will enable login with only email and password.
    </Typography>
  </>
);

export default DeactivationInfo;
