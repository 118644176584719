import React from "react";
import { Form } from "antd";

import { IKYXDualUploader } from "Views/KYX/@types";
import FileUploader from "Views/KYX/Elements/FileUpload";

import styles from "./index.module.scss";

const DualUploader = ({ titleProps, filePropsOne, filePropsTwo }: IKYXDualUploader) => {
  return (
    <div className={styles.dualWrapper}>
      <Form.Item trigger="" validateTrigger="" className={styles.dualUploaderTitle} {...titleProps} />
      <div className={styles.dualUploadWrapper}>
        <FileUploader {...filePropsOne} />
        <FileUploader {...filePropsTwo} />
      </div>
    </div>
  );
};

export default DualUploader;
