import { useContext } from "react";
import { FormInstance } from "antd/lib/form";

import {
  FORM_NAME,
  KYX_FORMS_KEYS,
  PERSON_DOCUMENT_TYPE,
  PERSONNEL_INFORMATION_FORM_ITEM_KEYS,
} from "Views/KYX/Constants/Forms";
import {
  FORM_SOURCE_TYPE,
  IFetchFormSubmissionPayload,
  IFormElement,
  IGetFormConfigsPayload,
  IPersonForm,
} from "Views/KYX/@types";
import { useFormSaveAsDraft } from "Views/KYX/hooks";
import { ManualFormContext } from "Views/KYX/Context";
import { GUIDELINES_TYPE, FORM_FIELD_TYPES } from "Views/KYX/Constants";
import { getValidationError, getValidationstatus } from "Views/KYX/Helper";

import { usePersonnelDetails } from "./PersonnelDetails";

export const useVerifyManuallyNRIC = (
  form: FormInstance,
  formattedFormData: IFetchFormSubmissionPayload,
  remarks: IGetFormConfigsPayload,
  currentKey: string,
  isFormDisabled: boolean,
  isFormVerified: boolean,
  onlyDocNumber: boolean = false
): IFormElement[] => {
  const { formData, setFormData } = useContext(ManualFormContext);
  const { saveFormAsDraft } = useFormSaveAsDraft(setFormData);
  const isMyInfoSource: boolean = formData?.source === FORM_SOURCE_TYPE.MY_INFO;

  const fieldError = "This field cannot be empty";

  const NRIC_DOCUMENT_HEADER_AND_GUIDELINES: IFormElement = {
    key: PERSONNEL_INFORMATION_FORM_ITEM_KEYS.NRIC_DOCUMENT_HEADER_AND_GUIDELINES,
    col: { span: 24 },
    element: {
      type: FORM_FIELD_TYPES.HEADER,
      props: {
        title: `${PERSON_DOCUMENT_TYPE.NRIC} Document`,
        guidelines: GUIDELINES_TYPE.NRIC,
      },
    },
  };

  const NRIC_NUMBER: IFormElement = {
    key: PERSONNEL_INFORMATION_FORM_ITEM_KEYS.NRIC_NUMBER,
    col: { span: 24 },
    element: {
      type: FORM_FIELD_TYPES.INPUT,
      props: {
        formProps: {
          name: [
            KYX_FORMS_KEYS.PERSON_DATA_ID_DOCUMENTS,
            KYX_FORMS_KEYS.PERSON_DATA_NRIC_DOCUMENT_TYPE,
            KYX_FORMS_KEYS.PERSON_DATA_DOCUMENT_NUMBER,
          ],
          label: `${PERSON_DOCUMENT_TYPE.NRIC} Number`,
          required: true,
          rules: [
            {
              required: true,
              message: fieldError,
            },
            { whitespace: true, message: fieldError },
          ],
          validateStatus: getValidationstatus(remarks, [
            KYX_FORMS_KEYS.PERSONNEL_DATA_PERSONS,
            currentKey,
            KYX_FORMS_KEYS.PERSON_DATA_ID_DOCUMENTS,
            KYX_FORMS_KEYS.PERSON_DATA_NRIC_DOCUMENT_TYPE,
            KYX_FORMS_KEYS.PERSON_DATA_DOCUMENT_NUMBER,
          ]),
          help: getValidationError(
            remarks,
            [
              KYX_FORMS_KEYS.PERSONNEL_DATA_PERSONS,
              currentKey,
              KYX_FORMS_KEYS.PERSON_DATA_ID_DOCUMENTS,
              KYX_FORMS_KEYS.PERSON_DATA_NRIC_DOCUMENT_TYPE,
              KYX_FORMS_KEYS.PERSON_DATA_DOCUMENT_NUMBER,
            ],
            `${PERSON_DOCUMENT_TYPE.NRIC} Number`,
            FORM_NAME.PERSONNEL_INFORMATION
          ),
        },
        inputProps: {
          placeholder: `Please write your 9-digits alphanumeric ${PERSON_DOCUMENT_TYPE.NRIC} number here`,
          disabled: isFormDisabled || isMyInfoSource,
        },
      },
    },
  };

  const UPLOAD_NRIC: IFormElement = {
    key: PERSONNEL_INFORMATION_FORM_ITEM_KEYS.UPLOAD_NRIC,
    col: { span: 24 },
    element: {
      type: FORM_FIELD_TYPES.DUALUPLOAD,
      props: {
        titleProps: {
          name: [
            KYX_FORMS_KEYS.PERSON_DATA_ID_DOCUMENTS,
            KYX_FORMS_KEYS.PERSON_DATA_NRIC_DOCUMENT_TYPE,
            KYX_FORMS_KEYS.PERSON_DATA_DOCUMENT,
            "_title",
          ],
          label: `Upload Document`,
          required: true,
        },
        filePropsOne: {
          formProps: {
            name: [
              KYX_FORMS_KEYS.PERSON_DATA_ID_DOCUMENTS,
              KYX_FORMS_KEYS.PERSON_DATA_NRIC_DOCUMENT_TYPE,
              KYX_FORMS_KEYS.PERSON_DATA_DOCUMENT,
            ],
            label: `Front Side`,
            // required: true,
            rules: [
              // *** Added bruteforce way here as normal approach wasn't working here even though the implementation is literally the same as workpass and passport
              ({ getFieldValue, setFieldsValue }) => ({
                validator() {
                  const nric_document = getFieldValue([
                    KYX_FORMS_KEYS.PERSONNEL_DATA_PERSONS,
                    currentKey,
                    KYX_FORMS_KEYS.PERSON_DATA_ID_DOCUMENTS,
                    KYX_FORMS_KEYS.PERSON_DATA_NRIC_DOCUMENT_TYPE,
                    KYX_FORMS_KEYS.PERSON_DATA_DOCUMENT,
                  ]);
                  if (nric_document) {
                    setFieldsValue({
                      [KYX_FORMS_KEYS.PERSONNEL_DATA_PERSONS]: {
                        [currentKey]: {
                          [KYX_FORMS_KEYS.PERSON_DATA_ID_DOCUMENTS]: {
                            [KYX_FORMS_KEYS.PERSON_DATA_DOCUMENT_TYPE]: PERSON_DOCUMENT_TYPE.NRIC,
                            [KYX_FORMS_KEYS.PERSON_DATA_NRIC_DOCUMENT_TYPE]: {
                              [KYX_FORMS_KEYS.PERSON_DATA_DOCUMENT]: nric_document,
                            },
                          },
                        },
                      },
                    });
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error(`Please upload a valid ${PERSON_DOCUMENT_TYPE.NRIC} document`));
                },
              }),
            ],
            validateStatus: getValidationstatus(remarks, [
              KYX_FORMS_KEYS.PERSONNEL_DATA_PERSONS,
              currentKey,
              KYX_FORMS_KEYS.PERSON_DATA_ID_DOCUMENTS,
              KYX_FORMS_KEYS.PERSON_DATA_NRIC_DOCUMENT_TYPE,
              KYX_FORMS_KEYS.PERSON_DATA_DOCUMENT,
            ]),
            help: getValidationError(
              remarks,
              [
                KYX_FORMS_KEYS.PERSONNEL_DATA_PERSONS,
                currentKey,
                KYX_FORMS_KEYS.PERSON_DATA_ID_DOCUMENTS,
                KYX_FORMS_KEYS.PERSON_DATA_NRIC_DOCUMENT_TYPE,
                KYX_FORMS_KEYS.PERSON_DATA_DOCUMENT,
              ],
              `${PERSON_DOCUMENT_TYPE.NRIC} Document`,
              FORM_NAME.PERSONNEL_INFORMATION
            ),
            initialValue: formData?.form?.personnel_information?.persons
              ? formData?.form?.personnel_information?.persons[currentKey]?.identification_document?.nric_document
                  ?.document
              : "",
          },
          disabled: isFormDisabled,
          // guidelinesType: GUIDELINES_TYPE.NRIC,
          action: (arg) => {
            const personsObj = form.getFieldsValue()["persons"];

            const idDoc = {
              [KYX_FORMS_KEYS.PERSON_DATA_DOCUMENT_TYPE]: PERSON_DOCUMENT_TYPE.NRIC,
              [KYX_FORMS_KEYS.PERSON_DATA_NRIC_DOCUMENT_TYPE]: {
                ...personsObj[currentKey].identification_document[KYX_FORMS_KEYS.PERSON_DATA_NRIC_DOCUMENT_TYPE],
                [KYX_FORMS_KEYS.PERSON_DATA_DOCUMENT]: arg,
              },
            };

            const newPersons: IPersonForm[] = Object.keys(personsObj).map((key, index) => {
              const data = {
                ...personsObj[key],
                roles_of_individual: personsObj[key]?.roles_of_individual
                  ? Object.assign(
                      {},
                      ...personsObj[key].roles_of_individual.map((role) => {
                        return role ? { [role]: true } : null;
                      })
                    )
                  : {},
              };

              if (index !== parseInt(currentKey)) {
                // This isn't the person we care about - keep it as-is
                return {
                  ...data,
                  [KYX_FORMS_KEYS.PERSON_DATA_ID_DOCUMENTS]: {
                    ...data?.identification_document,
                    is_same_residential_address: personsObj[key]?.identification_document?.is_same_residential_address
                      ?.length
                      ? personsObj[key]?.identification_document?.is_same_residential_address?.[0] === "checked"
                      : false,
                  },
                };
              }

              // Otherwise, this is the one we want - return an updated value
              return {
                ...data,
                [KYX_FORMS_KEYS.PERSON_DATA_ID_DOCUMENTS]: {
                  ...idDoc,
                  is_same_residential_address: personsObj[key]?.identification_document?.is_same_residential_address
                    ?.length
                    ? personsObj[key]?.identification_document?.is_same_residential_address?.[0] === "checked"
                    : false,
                },
              } as IPersonForm;
            });

            saveFormAsDraft({
              id: formattedFormData?.form_id,
              source: formattedFormData?.source,
              form: {
                ...formData.form,
                personnel_information: {
                  ...formData.form.personnel_information,
                  persons: newPersons,
                },
              },
            });

            return form.setFieldsValue({
              [KYX_FORMS_KEYS.PERSONNEL_DATA_PERSONS]: {
                [currentKey]: { [KYX_FORMS_KEYS.PERSON_DATA_ID_DOCUMENTS]: idDoc },
              },
            });
          },
        },
        filePropsTwo: {
          formProps: {
            name: [
              KYX_FORMS_KEYS.PERSON_DATA_ID_DOCUMENTS,
              KYX_FORMS_KEYS.PERSON_DATA_NRIC_DOCUMENT_TYPE,
              KYX_FORMS_KEYS.PERSON_DATA_DOCUMENT_BACK,
            ],
            label: `Back Side`,
            // required: true,
            rules: [
              // *** Added bruteforce way here as normal approach wasn't working here even though the implementation is literally the same as workpass and passport
              ({ getFieldValue, setFieldsValue }) => ({
                validator() {
                  const nric_document = getFieldValue([
                    KYX_FORMS_KEYS.PERSONNEL_DATA_PERSONS,
                    currentKey,
                    KYX_FORMS_KEYS.PERSON_DATA_ID_DOCUMENTS,
                    KYX_FORMS_KEYS.PERSON_DATA_NRIC_DOCUMENT_TYPE,
                    KYX_FORMS_KEYS.PERSON_DATA_DOCUMENT_BACK,
                  ]);
                  if (nric_document) {
                    setFieldsValue({
                      [KYX_FORMS_KEYS.PERSONNEL_DATA_PERSONS]: {
                        [currentKey]: {
                          [KYX_FORMS_KEYS.PERSON_DATA_ID_DOCUMENTS]: {
                            [KYX_FORMS_KEYS.PERSON_DATA_DOCUMENT_TYPE]: PERSON_DOCUMENT_TYPE.NRIC,
                            [KYX_FORMS_KEYS.PERSON_DATA_NRIC_DOCUMENT_TYPE]: {
                              [KYX_FORMS_KEYS.PERSON_DATA_DOCUMENT_BACK]: nric_document,
                            },
                          },
                        },
                      },
                    });
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error(`Please upload a valid ${PERSON_DOCUMENT_TYPE.NRIC} document`));
                },
              }),
            ],
            validateStatus: getValidationstatus(remarks, [
              KYX_FORMS_KEYS.PERSONNEL_DATA_PERSONS,
              currentKey,
              KYX_FORMS_KEYS.PERSON_DATA_ID_DOCUMENTS,
              KYX_FORMS_KEYS.PERSON_DATA_NRIC_DOCUMENT_TYPE,
              KYX_FORMS_KEYS.PERSON_DATA_DOCUMENT_BACK,
            ]),
            help: getValidationError(
              remarks,
              [
                KYX_FORMS_KEYS.PERSONNEL_DATA_PERSONS,
                currentKey,
                KYX_FORMS_KEYS.PERSON_DATA_ID_DOCUMENTS,
                KYX_FORMS_KEYS.PERSON_DATA_NRIC_DOCUMENT_TYPE,
                KYX_FORMS_KEYS.PERSON_DATA_DOCUMENT_BACK,
              ],
              `${PERSON_DOCUMENT_TYPE.NRIC} Document`,
              FORM_NAME.PERSONNEL_INFORMATION
            ),
            initialValue: formData?.form?.personnel_information?.persons
              ? formData?.form?.personnel_information?.persons[currentKey]?.identification_document?.nric_document
                  ?.document_back
              : "",
          },
          disabled: isFormDisabled,
          // guidelinesType: GUIDELINES_TYPE.NRIC,
          action: (arg) => {
            const personsObj = form.getFieldsValue()["persons"];

            const idDoc = {
              [KYX_FORMS_KEYS.PERSON_DATA_DOCUMENT_TYPE]: PERSON_DOCUMENT_TYPE.NRIC,
              [KYX_FORMS_KEYS.PERSON_DATA_NRIC_DOCUMENT_TYPE]: {
                ...personsObj[currentKey].identification_document[KYX_FORMS_KEYS.PERSON_DATA_NRIC_DOCUMENT_TYPE],
                [KYX_FORMS_KEYS.PERSON_DATA_DOCUMENT_BACK]: arg,
              },
            };

            const newPersons: IPersonForm[] = Object.keys(personsObj).map((key, index) => {
              const data = {
                ...personsObj[key],
                roles_of_individual: personsObj[key]?.roles_of_individual
                  ? Object.assign(
                      {},
                      ...personsObj[key].roles_of_individual.map((role) => {
                        return role ? { [role]: true } : null;
                      })
                    )
                  : {},
              };

              if (index !== parseInt(currentKey)) {
                // This isn't the person we care about - keep it as-is
                return {
                  ...data,
                  [KYX_FORMS_KEYS.PERSON_DATA_ID_DOCUMENTS]: {
                    ...data?.identification_document,
                    is_same_residential_address: personsObj[key]?.identification_document?.is_same_residential_address
                      ?.length
                      ? personsObj[key]?.identification_document?.is_same_residential_address?.[0] === "checked"
                      : false,
                  },
                };
              }

              // Otherwise, this is the one we want - return an updated value
              return {
                ...data,
                [KYX_FORMS_KEYS.PERSON_DATA_ID_DOCUMENTS]: {
                  ...idDoc,
                  is_same_residential_address: personsObj[key]?.identification_document?.is_same_residential_address
                    ?.length
                    ? personsObj[key]?.identification_document?.is_same_residential_address?.[0] === "checked"
                    : false,
                },
              } as IPersonForm;
            });

            saveFormAsDraft({
              id: formattedFormData?.form_id,
              source: formattedFormData?.source,
              form: {
                ...formData.form,
                personnel_information: {
                  ...formData.form.personnel_information,
                  persons: newPersons,
                },
              },
            });

            return form.setFieldsValue({
              [KYX_FORMS_KEYS.PERSONNEL_DATA_PERSONS]: {
                [currentKey]: { [KYX_FORMS_KEYS.PERSON_DATA_ID_DOCUMENTS]: idDoc },
              },
            });
          },
        },
      },
    },
  };

  const personnelDetails = usePersonnelDetails(form, formData, remarks, currentKey, isFormDisabled, isFormVerified);

  return onlyDocNumber
    ? [NRIC_NUMBER]
    : [NRIC_DOCUMENT_HEADER_AND_GUIDELINES, NRIC_NUMBER, !isFormVerified && UPLOAD_NRIC, ...personnelDetails];
};
