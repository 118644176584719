import * as React from "react";
import { useContext, useCallback } from "react";
import { Select } from "antd";
import { useSelector } from "react-redux";

import { removeDuplicates } from "utility";

import { INITIAL_ROLE_UPDATE_CALL_COUNT, NON_WALLET_ROLES_IDS } from "constants/Employee.constant";

import {
  EmployeesDispatchFailsContext,
  EmployeesModalContext,
  IEmployeesDispatchFailsContext,
  IEmployeesModalContext,
} from "Views/Employee/context";
import { ADMIN_ROLE_ID, EmployeeModalTypes, IRole, IUpdateRoleItemProps } from "Views/Employee/types";
import { trackEvent } from "utility/analytics";
import { EmployeeRoleUpdateEventName } from "Views/Employee/Analytics";
import { useIsSaasWithPaymentsOrg } from "customHooks/useIsSaasWithPaymentsOrg";

const UpdateRoleItem = ({ roleId, record }: IUpdateRoleItemProps) => {
  const isSaasAndPaymentsOrg = useIsSaasWithPaymentsOrg();

  const { toggleModal }: IEmployeesModalContext = useContext(EmployeesModalContext);

  const { Option } = Select;

  const { is_default_role, wallet_enabled } = record || {};

  const { dispatchRoleUpdate }: IEmployeesDispatchFailsContext = useContext(EmployeesDispatchFailsContext);

  const rolesList: IRole[] = useSelector((state: any) => state.employeeRoleReducer?.data?.payload);

  const filterDuplicateRoles = (): IRole[] => {
    if (wallet_enabled) {
      return removeDuplicates(rolesList, roleId);
    }
    return rolesList?.filter((item) => NON_WALLET_ROLES_IDS.includes(item.id) && item.id !== roleId);
  };

  const renderOptions = (role: IRole) => {
    return (
      <Option key={role.id} value={role.id}>
        {role.name}
      </Option>
    );
  };

  const onChangeRole = useCallback(
    (value: string) => {
      if (Number(value) === ADMIN_ROLE_ID && isSaasAndPaymentsOrg) {
        toggleModal(EmployeeModalTypes.SWITCH_ADMIN_INFORMATION, { record });
        return;
      }
      dispatchRoleUpdate(INITIAL_ROLE_UPDATE_CALL_COUNT, parseInt(value), record);
      trackEvent(EmployeeRoleUpdateEventName.ROLE_UPDATE_STATUS, { role_update_event_action: record.role_name });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [record, roleId]
  );

  const renderSelect = () => {
    return (
      <Select
        bordered={false}
        onChange={onChangeRole}
        value={record.role_name}
        dropdownClassName="data-table--dropdown__select"
      >
        {filterDuplicateRoles()?.map(renderOptions)}
      </Select>
    );
  };

  return is_default_role ? <p>{record.role_name}</p> : renderSelect();
};

export default UpdateRoleItem;
