const HEADER_TEXT_WITH_YEAR = /^Remaining Limit for \d{4}$/;
const HEADER_TEXT_FOR_TOTAL_LIMIT = /^Remaining Limit$/;
const HEADER_TEXT_FOR_DAILY_LIMIT = /^Remaining Limit for Today$/;
const HEADER_TEXT_WITH_MONTH =
  /^Remaining Limit for (January|February|March|April|May|June|July|August|September|October|November|December)$/;

export const formatHeaderText = (headerText: string) => {
  switch (true) {
    case HEADER_TEXT_FOR_DAILY_LIMIT.test(headerText):
      return `Remaining Limit (Daily)`;
    case HEADER_TEXT_FOR_TOTAL_LIMIT.test(headerText):
      return `Remaining Limit (Total)`;
    case HEADER_TEXT_WITH_YEAR.test(headerText):
      return `Remaining Limit (${headerText?.split("Remaining Limit for ")?.[1] || ""})`;
    case HEADER_TEXT_WITH_MONTH.test(headerText):
      return `Remaining Limit (${headerText?.split("Remaining Limit for ")?.[1]?.substring(0, 3) || ""})`;
    default:
      return headerText;
  }
};
