import { DynamicFormField, DynamicFormFieldWithAncestor } from "Modules/DynamicForm/types";

export const setAncestorIDs = (fields: DynamicFormField[]) => {
  const allFieldsWithAnchestor = fields.reduce((prev, curr) => {
    const { parentFieldID } = curr.fieldProps;

    const ancestorIDs = [];

    if (parentFieldID) {
      ancestorIDs.push(parentFieldID);

      if (prev[parentFieldID]?.fieldProps.parentFieldID) {
        ancestorIDs.push(...prev[parentFieldID].ancestorIDs);
      }
    }

    // Ascending sorting
    ancestorIDs.sort((a, b) => {
      if (a > b) return 1;
      if (b > a) return -1;
      return 0;
    });

    return {
      ...prev,
      [curr.fieldID]: { ...curr, ancestorIDs },
    };
  }, {} as Record<string, DynamicFormFieldWithAncestor>);

  return allFieldsWithAnchestor;
};
