import * as React from "react";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Pagination } from "antd";
import axios, { CancelTokenSource } from "axios";
import ApprovalFlowListItem from "./ApprovalListItem";
import Loading from "Modules/loading";
import {
  clearGetApprovalFlowList,
  getApprovalFlowListFunc,
} from "Redux/Actions/ApprovalFlow/getApprovalFlowList";
import { conditionParser, generateMessageForFlowErrors } from "../helpers";
import "./ApprovalList.scss";
import ErrorBox from "Modules/DS/Molecules/ErrorBox";
import FaqButton from "Modules/DS/FAQButton";
import UpsellModal from "../UpsellModal";
import SelfApprovalInfo from "Views/ApprovalFlow/ApprovalList/SelfApprovalInfo";
import { USER_ROLE } from "constants/Team.constant";

export const DEFAULT_FLOW_DESC =
  "This flow will run by default if the request does not match any existing flow.";
const DEFAULT_FLOW_PRIORITY = 0;
const dateOptions: Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "short",
  day: "numeric",
};

const ApprovalFlowList = ({
  setShowSelfApprovalPanel,
}: {
  setShowSelfApprovalPanel: React.Dispatch<React.SetStateAction<boolean>>;
}): JSX.Element => {
  const dispatch = useDispatch();
  const approvalFlowListReducer = useSelector(
    (state: any) => state.getApprovalFlowListReducer,
  );
  const currencyCode = useSelector(
    (state: any) => state.wallet?.data?.currency_code,
  );
  const history = useHistory();
  const [flowList, setFlowList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const approvalFlowList = approvalFlowListReducer?.data?.payload?.list;
  const [invalidFlow, setInvalidFlow] = useState(false);
  const totalRecords =
    approvalFlowListReducer?.data?.payload?.pagination?.total;
  const processType = useSelector(
    (state: any) => state.approvalFlowTabReducer?.activeTab,
  );
  const requestSource = React.useRef<CancelTokenSource>(
    axios.CancelToken.source(),
  );
  const defaultFlow = approvalFlowList?.find(
    (flow) => flow?.priority === DEFAULT_FLOW_PRIORITY,
  );
  const defaultFlowHasEmployeeRoles =
    processType === "invoice" &&
    defaultFlow?.stepAssignees
      ?.flatMap((stepDetails) => stepDetails?.assignees)
      ?.some((apporver) => apporver?.assignee?.role !== USER_ROLE.ADMIN);

  useEffect(() => {
    if (approvalFlowList?.length) {
      const newApprovalFlowList = [
        ...JSON.parse(JSON.stringify(approvalFlowList)),
      ];
      if (defaultFlowHasEmployeeRoles) {
        const defaultFlow = newApprovalFlowList?.find(
          (flow) => flow?.priority === DEFAULT_FLOW_PRIORITY,
        );
        defaultFlow?.stepAssignees?.forEach?.((stepAssignee) => {
          stepAssignee?.assignees?.forEach?.(({ assignee }) => {
            if (assignee?.role !== USER_ROLE.ADMIN) {
              assignee.isInvalidRole = true;
            }
          });
        });
        defaultFlow.hasInvalidData = true;
      }
      const oneOrMoreFlowInvalid = newApprovalFlowList?.some(
        (approvalFlow) => approvalFlow?.hasInvalidData,
      );
      setFlowList(newApprovalFlowList);
      setInvalidFlow(oneOrMoreFlowInvalid);
    }
  }, [approvalFlowList, defaultFlowHasEmployeeRoles]);

  const getList = (page: number) =>
    dispatch(
      getApprovalFlowListFunc({
        page,
        processType,
        requestSource: requestSource.current,
      }),
    );

  useEffect(() => {
    if (processType) {
      requestSource.current = axios.CancelToken.source();
      getList(1);
      return () => {
        if (requestSource.current) requestSource.current.cancel();
        dispatch(clearGetApprovalFlowList());
      };
    }
  }, [processType]);

  const onChangePageNumber = (pageNum: number) => {
    getList(pageNum);
    setCurrentPage(pageNum);
  };

  const hasStepAllInactive = () => {
    return flowList.some((item) =>
      item.stepAssignees.some((el) =>
        el.assignees.every((e) => e.assignee.isDeleted),
      ),
    );
  };

  return (
    <div className={"approval-flow__list"}>
      {invalidFlow && (
        <div className="approval-flow__invalid-data">
          <ErrorBox
            message={
              "The highlighted conditions in each Approval Flow below are no longer active. Future approval requests will not be able to use these conditions."
            }
          ></ErrorBox>
        </div>
      )}
      <SelfApprovalInfo
        setShowSelfApprovalPanel={setShowSelfApprovalPanel}
        processType={processType}
      />
      <div className="approval-flow__list-header">
        <div>List of Flows</div>
        <div>Approvers</div>
      </div>
      {approvalFlowListReducer.loading ||
      !approvalFlowListReducer?.data?.payload ? (
        <Loading />
      ) : (
        <>
          <ul className="approval-flow__list">
            {flowList.map((item) => {
              const formatDate = (date) =>
                new Date(date).toLocaleDateString("en-US", dateOptions);
              const isDefault = item.priority === 0;
              const publishedBy = item.updatedBy?.name
                ? item.updatedBy?.name
                : isDefault
                  ? "system"
                  : item.createdBy?.name;
              const publishedAt = item.updatedBy?.name
                ? formatDate(item.updatedAt)
                : isDefault
                  ? "automatically"
                  : formatDate(item.createdAt);
              return (
                <ApprovalFlowListItem
                  id={item.id}
                  title={item.name}
                  key={item.id}
                  hasInvalidData={item.hasInvalidData}
                  invalidDataError={
                    item.hasInvalidData
                      ? generateMessageForFlowErrors(item)
                      : ""
                  }
                  desc={
                    item.priority !== 0
                      ? Object.keys(item.condition)
                          .map((key) =>
                            conditionParser(
                              key,
                              item.condition[key],
                              currencyCode,
                            ),
                          )
                          .filter((item) => item)
                          .join(" · ")
                      : DEFAULT_FLOW_DESC
                  }
                  isDefault={isDefault}
                  approvers={item.stepAssignees}
                  publishedAt={publishedAt}
                  publishedBy={publishedBy}
                  onClick={() =>
                    history.push(`/approvalflow/edit/${item.id}`, item)
                  }
                  currentPage={currentPage}
                  processType={processType}
                  stepInactive={hasStepAllInactive()}
                />
              );
            })}
          </ul>
          {flowList?.length === 1 && flowList[0]?.priority === 0 && (
            <UpsellModal />
          )}
          <div className="pagination-component">
            <Pagination
              current={currentPage}
              onChange={onChangePageNumber}
              total={totalRecords}
              hideOnSinglePage
              showSizeChanger={false}
              responsive
            />
          </div>

          <FaqButton />
        </>
      )}
    </div>
  );
};

export default ApprovalFlowList;
