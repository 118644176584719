import React from "react";

import { debounce } from "utility";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

import Searchbar from "Modules/search";
import Filter, { DateFilter } from "Modules/DS/Filter";

import { MerchantFilter } from "./Merchant";
import { CategoryFilter } from "./Category";
import { TransactionTypeFilter } from "./Type";
import TransactionMoreFilter from "./TransactionMoreFilter";
import { IncompleteFieldsFilter } from "./IncompleteFields";

import { EWorkflowApiStatus } from "../type";
import { useFormContext } from "../context/FormContext";

import styles from "./styles.module.scss";

dayjs.extend(utc);

export interface ISingleLine {
  id: string;
  title: string | React.ReactNode;
}

export interface ITransactionFilter {
  start_date: string;
  end_date: string;
  team_id: string;
  user_id: string;
  type: string;
  category_id: string | string[];
  min_amount: number;
  max_amount: number;
  merchant: string | string[];
  has_attachment: string;
  missing_tags: string;
  missing_tax: string;
  missing_account_code: string;
  missing_notes: string;
  startsWith: string;
  xero_sync_status: string;
  // Reporting service Filter
  recipient_names: string | string[];
  types: string;
  categories: string;
  attachment: string;
  missing_fields: string;
  team_ids: string;
  requester_ids: string;
  accounting_search: string;
}

interface ITransactionFilterProps {
  filter?: ITransactionFilter;
  applyFilter: (filter: any, callApi?: boolean) => void;
  resetFilter: (keys: string[]) => void;
  accountingStatus: EWorkflowApiStatus;
}

export const defaultFilter: ITransactionFilter = {
  start_date: "",
  end_date: "",
  team_id: "",
  user_id: "",
  type: "",
  category_id: "",
  min_amount: 0,
  max_amount: 0,
  merchant: "",
  has_attachment: "",
  missing_tags: "",
  missing_tax: "",
  missing_notes: "",
  missing_account_code: "",
  startsWith: "",
  xero_sync_status: "",
  missing_fields: "",
  recipient_names: "",
  types: "",
  categories: "",
  attachment: "",
  team_ids: "",
  requester_ids: "",
  accounting_search: "",
};

const DEBOUNCE_DELAY = 500;

const TransactionFilters = ({
  filter = defaultFilter,
  applyFilter,
  resetFilter,
  accountingStatus,
}: ITransactionFilterProps) => {
  const { connected, connectedUsingExpenseIntegration } = useFormContext();

  const handleSearch = debounce((value: string) => {
    if (!value && !filter.accounting_search) {
      return;
    }
    applyFilter({ accounting_search: value });
  }, DEBOUNCE_DELAY);

  const renderDateFilter = () => {
    return (
      <DateFilter<ITransactionFilter>
        id="transactions_date"
        filter={filter}
        onApply={(filter) => {
          applyFilter({
            start_date: dayjs(filter.start_date).utc().toISOString(),
            end_date: dayjs(filter.end_date).endOf("day").utc().toISOString(),
          });
        }}
        onClear={() => resetFilter(["start_date", "end_date"])}
      />
    );
  };

  const renderMerchantFilter = () => {
    return <MerchantFilter id="recipient_names" filter={filter} applyFilter={applyFilter} resetFilter={resetFilter} />;
  };

  const renderTrxnTypeFilter = () => {
    return <TransactionTypeFilter id="type_filter" applyFilter={applyFilter} resetFilter={resetFilter} />;
  };

  const renderCategoryFilter = () => {
    return <CategoryFilter id="category_filter" applyFilter={applyFilter} resetFilter={resetFilter} />;
  };

  const renderIncompleteFieldsFilter = () => {
    return (
      [EWorkflowApiStatus.MISSING_INFO, EWorkflowApiStatus.PENDING_REVIEW].includes(accountingStatus) && (
        <IncompleteFieldsFilter
          id="incomplete_fields_filter"
          applyFilter={applyFilter}
          resetFilter={resetFilter}
          connected={connected}
          connectedUsingExpenseIntegration={connectedUsingExpenseIntegration}
        />
      )
    );
  };

  return (
    <div className={styles.transaction}>
      <Filter>
        <div className={styles.search}>
          <Searchbar
            placeholder="Search for a transaction"
            getKeyUpValue={handleSearch}
            clearSearch={filter.accounting_search === "" || filter.accounting_search === null}
          />
        </div>
        {renderDateFilter()}
        {renderMerchantFilter()}
        {renderTrxnTypeFilter()}
        {renderCategoryFilter()}
        {renderIncompleteFieldsFilter()}
        <TransactionMoreFilter
          filter={filter}
          applyFilter={applyFilter}
          resetFilter={resetFilter}
          accountingStatus={accountingStatus}
        />
      </Filter>
    </div>
  );
};

export default TransactionFilters;
