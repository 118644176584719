import React from "react";
import Modal, { ModalComponent } from "Modules/DS/Modal";
import { greyCloseIcon } from "assets/img";
import IButton from "Modules/button";
import { TITLE_TYPE } from "Modules/DS/Modal/types";
import { IWarningModal } from "../type";

import styles from "./styles.module.scss";

const WarningModal = ({ visible, onClose, loading, handleSync, message, buttonText, title }: IWarningModal) => {
  return (
    <Modal visible={visible} close={onClose} className={styles["resync-warning"]}>
      <ModalComponent.CloseButton fixToTop src={greyCloseIcon} />
      <ModalComponent.Title titleType={TITLE_TYPE.withBorder}>{title}</ModalComponent.Title>
      <section className={styles["resync-warning__body"]}>
        <p>{message}</p>
        <div className={styles["resync-warning__action-btns"]}>
          <IButton text="Cancel" btnStyle="white-orange-with-border" action={onClose} />
          <IButton text={buttonText} loading={loading} action={handleSync} className={loading ? "disabled" : ""} />
        </div>
      </section>
    </Modal>
  );
};

export default WarningModal;
