/**
 * @fileoverview This module defines the AccountsPopover component which offers a popover
 * functionality to switch between different accounts. This popover also includes a search
 * feature using the fuzzysort library and provides an interactive way to switch between accounts.
 *
 * @author Sushmitha, Bakshi
 * @created 24 Aug 2023
 * @lastModified 30 Aug 2023
 */
import React, { useContext, useEffect, useState } from "react";
import { Typography } from "@spenmo/splice";
import fuzzysort from "fuzzysort";

import { useAppDispatch } from "hook";

import { linkedAccountSuccess, switchAccount } from "Redux/Actions/loginAction";

import Search from "Modules/DS/Search";

import AccountList from "Views/Navigation/Header/List";
import { NavigationContext } from "Views/Navigation/Context";
import { IAccountsPopover, ILinkedAccounts, INavigationContext } from "Views/Navigation/@types";

import styles from "./index.module.scss";

/**
 * AccountsPopover
 *
 * A React functional component that renders a popover allowing users to search and
 * switch between different linked accounts. The component fetches the list of linked
 * accounts from local storage, allowing seamless switching without re-fetching data.
 *
 * @param {ILinkedAccounts[]} linkedAccounts - List of linked accounts.
 *
 * @returns {React.ReactElement} The rendered popover component with search functionality
 * and a list of accounts.
 */
const AccountsPopover: React.FC<IAccountsPopover> = ({ linkedAccounts }: IAccountsPopover): React.ReactElement => {
  const dispatch = useAppDispatch();

  const [query, setQuery] = useState<string>("");

  const { setIsPopoverOpen }: INavigationContext = useContext(NavigationContext);

  const accountsList: Fuzzysort.KeyResults<ILinkedAccounts> = fuzzysort.go(
    query,
    Array.isArray(linkedAccounts) && linkedAccounts.length > 0 ? linkedAccounts : [],
    {
      key: "organisation_name",
      all: true,
    }
  );

  useEffect(() => {
    const storedLinkedAccounts = JSON.parse(window.localStorage.getItem("linked-account")) || [];
    dispatch(linkedAccountSuccess(storedLinkedAccounts));
  }, [dispatch]);

  const handleClearSearch = () => {
    setQuery("");
  };

  const handleAccountSelect = (userId: string) => {
    dispatch(switchAccount(userId));
    setIsPopoverOpen(false);
  };

  return (
    <div
      className={styles.container}
      // Show popover on mouse enter and hide on mouse leave
      onMouseEnter={() => setIsPopoverOpen(true)}
      onMouseLeave={() => setIsPopoverOpen(false)}
    >
      <Typography tag="h5" className={styles.title} variant="body-content" size="caption-m" weight={600}>
        Switch Account to
      </Typography>
      <Search.Bar onSearch={setQuery} clearSearch={handleClearSearch} placeholder="Search Company" />
      <AccountList list={accountsList} onAccountSelect={handleAccountSelect} />
    </div>
  );
};

export default AccountsPopover;
