import React, { useEffect } from "react";
import classNames from "classnames";
import { Tooltip, Typography } from "antd";
import DynamicSLA, { DynamicSLAVariant } from "Modules/DynamicSLA";
import { TOOLTIP, DESC } from "Modules/BillFee/const";
import { currencyFormatterV2 } from "utility";
import { newInfo } from "assets/img";
import { trackEvent } from "utility/analytics";
import { BILL_EVENTS } from "Views/Bills/events";
import styles from "./index.module.scss";
import BillImportedFromXeroBanner from "Modules/BillImportedFromXeroBanner";

const TrxnInfoCard = ({
  id,
  merchant,
  currency,
  amount,
  isShowConversion,
  walletCurrency,
  walletAmount,
  status,
  isFeeDirectlyDeducted,
  billAmountFeeInclusive,
  feeAmount,
  selectedTrxnSLA,
  swiftPaymentChargeFee,
  saasConfig,
  invoiceAmount,
  invoiceCurrency,
  importBillFromXero,
}) => {
  const { Text } = Typography;
  const isSendingPayment = ["payout_issue", "completed"].includes(status);

  const amountGroup = (amount, label) => (
    <>
      <Text className={styles.label}>{label}</Text>
      <Text className={styles.textValue}>{currencyFormatterV2(amount, walletCurrency, true, 2)}</Text>
    </>
  );

  useEffect(() => {
    trackEvent(BILL_EVENTS.detailViewed, { bill_id: id, is_sla_exceeded: isSendingPayment });
  }, []);

  return (
    <div className={styles.container}>
      <Text className={styles.label}>Recipient</Text>
      <Text className={styles.textValue}>{merchant?.name}</Text>
      <Text className={classNames(styles.label, styles.bankNameSpace)}>{merchant?.bankName}</Text>
      {!saasConfig?.isPaymentEnabled && (
        <>
          <Text className={styles.label}>Invoice Amount</Text>
          <Text className={classNames(styles.textValue, styles.big)}>
            {currencyFormatterV2(invoiceAmount, invoiceCurrency, true, 2)}
          </Text>
        </>
      )}
      {saasConfig?.isPaymentEnabled && (
        <>
          <Text className={styles.label}>Recipient Gets</Text>
          <Text className={classNames(styles.textValue, styles.big)}>
            {currencyFormatterV2(amount, currency, true, 2)}
          </Text>
          {!isFeeDirectlyDeducted && isShowConversion && amountGroup(walletAmount, "Payment Amount")}
          {isFeeDirectlyDeducted && (
            <>
              {amountGroup(billAmountFeeInclusive, "You pay")}
              <div className={classNames(styles.feeContainer)}>
                <div className={classNames(styles.leftMarker)}>&nbsp;</div>
                <div className={classNames(styles.right)}>
                  <Text className={styles.label}>
                    Payment Amount
                    <Tooltip title={TOOLTIP.paymentAmount} placement="top">
                      <img width={16} height={17} src={newInfo} alt="tooltip info icon" />
                    </Tooltip>
                  </Text>
                  <Text className={classNames(styles.textValue, styles.feeValueSpace)}>
                    {currencyFormatterV2(walletAmount, walletCurrency, true, 2)}
                  </Text>
                  <Text className={styles.label}>
                    Transfer Fee
                    <Tooltip title={TOOLTIP.default} placement="right">
                      <img width={16} height={17} src={newInfo} alt="tooltip info icon" />
                    </Tooltip>
                  </Text>
                  <Text className={classNames(styles.textValue, styles.feeValueSpace)}>
                    {feeAmount > 0 ? currencyFormatterV2(feeAmount, walletCurrency, true, 2) : "Free"}
                  </Text>
                  <Text className={classNames(styles.label)}>{DESC.default}</Text>
                  {swiftPaymentChargeFee > 0 && (
                    <>
                      <Text className={styles.label}>
                        Full Amount Guarantee Fee
                        <Tooltip title={TOOLTIP.fullAmountGuarantee} placement="top">
                          <img width={16} height={17} src={newInfo} alt="tooltip info icon" />
                        </Tooltip>
                      </Text>
                      <Text className={classNames(styles.textValue, styles.feeValueSpace)}>
                        {currencyFormatterV2(swiftPaymentChargeFee, walletCurrency, true, 2)}
                      </Text>
                    </>
                  )}
                </div>
              </div>
            </>
          )}

          {selectedTrxnSLA ? (
            <>
              <Text className={styles.label}>
                Estimated Arrival
                <Tooltip title={TOOLTIP.estimatedArrival} placement="top">
                  <img width={16} height={17} src={newInfo} alt="tooltip info icon" />
                </Tooltip>
              </Text>
              <Text className={styles.textValue}>{selectedTrxnSLA}</Text>
            </>
          ) : (
            <DynamicSLA
              tooltipMessage={TOOLTIP.estimatedArrival}
              billID={id}
              message={
                isSendingPayment && (
                  <>
                    Payment is taking longer than usual.
                    <br />
                    Please contact our{" "}
                    <a
                      onClick={() => {
                        trackEvent(BILL_EVENTS.slaClick, { bill_id: id });
                      }}
                      href="https://support.spenmo.com/hc/en-us/requests/new"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Customer Support
                    </a>{" "}
                    for more information.
                  </>
                )
              }
              variant={DynamicSLAVariant.vertical}
              labelClassName={styles.label}
              valueClassName={styles.textValue}
            />
          )}
        </>
      )}

      {importBillFromXero ? <BillImportedFromXeroBanner xeroBillBannerData={importBillFromXero} lessSpacing /> : null}
    </div>
  );
};

export default TrxnInfoCard;
