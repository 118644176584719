import React from "react";
import classNames from "classnames";
import { ConfigProvider, Table as AntTable } from "antd";
import { TablePaginationConfig, TableProps } from "antd/lib/table";

import { IScrollProps, ITableProps } from "Modules/DS/DataTable";

import { BulkActions } from "../BulkActions";

import NoRecord from "./NoRecord";

export const Table = <RecordType extends object = any>({
  className,
  total,
  loading,
  dataSource,
  pageNum,
  pageSize,
  onPageChange,
  paginationProps,
  emptyState,
  customEmptyState = null,
  children,
  scrollVertical,
  scrollHorizontal,
  paginationEnabled = true,
  paginationOnTop = false,
  paginationOnBottom = true,
  rowAction = () => {},
  rowClassName = () => "",
  rowSelection,
  bulkAction,
}: ITableProps<RecordType>) => {
  const paginationPos: TablePaginationConfig["position"] = [];

  paginationOnTop && paginationPos.push("topRight");
  paginationOnBottom && paginationPos.push("bottomRight");

  const tableClassName: string = classNames({ pagination_top: paginationOnTop }, { [className]: className });

  const scroll: IScrollProps = { x: scrollHorizontal, y: scrollVertical };

  const pagination: TablePaginationConfig = {
    total: total,
    pageSize: pageSize,
    current: pageNum + 1,
    onChange: onPageChange,
    showSizeChanger: false,
    position: paginationPos,
    hideOnSinglePage: true,
    ...paginationProps,
  };

  const table: TableProps<RecordType> = {
    loading: loading,
    dataSource: dataSource,
    scroll: scroll,
    pagination: paginationEnabled && pagination,
    rowKey: (record: RecordType): string => record["id"],
    onRow: (record: RecordType) => ({
      onClick: (e) => rowAction(record, e),
    }),
    rowClassName,
    rowSelection: rowSelection,
    className: tableClassName,
  };

  const getEmptyState = () => {
    if (customEmptyState) {
      return customEmptyState;
    }
    return <NoRecord emptyMessage={emptyState} />;
  };
  return (
    <ConfigProvider renderEmpty={getEmptyState}>
      <AntTable<RecordType> {...table} tableLayout="fixed">
        {children?.map((item) => (
          <AntTable.Column<RecordType> key={item.id} {...item.column} />
        ))}
      </AntTable>
      {bulkAction && ((bulkAction.data && bulkAction.data.length !== 0) || bulkAction.alwaysShown) && (
        <BulkActions {...bulkAction} />
      )}
    </ConfigProvider>
  );
};
