import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { Typography } from "antd";

import Icon from "Modules/icons";
import UploadFile from "Modules/UploadFile/UploadFile";
import { appNotification } from "Modules/appNotification/appNotification";

import useLoading from "utility/useLoading";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import { uploadInvoice as uploadInvoiceLogo } from "assets/img";
import { uploadSingle as uploadInvoiceAPI } from "Redux/DataCalls/Disbursement.api";

import { ALLOWED_FILE_EXTENSIONS, MAX_FILE_SIZE_ALLOWED } from "Views/UploadInvoice/const";
import { trackEvent } from "utility/analytics";

import styles from "./Uploader.module.scss";

const Uploader = forwardRef(({ title, subtitle, onValidateFile, onUploadFile, setIsLoadingOcr, useOcr }, ref) => {
  const { Title, Paragraph } = Typography;

  const [uploadFileAction, isLoading] = useLoading(uploadInvoiceAPI);

  const validateFile = (file) => {
    const fileExtension = file.name.split(".").pop().toLowerCase();
    const extensionValid = ALLOWED_FILE_EXTENSIONS.includes(fileExtension);
    const sizeValid = file.size <= MAX_FILE_SIZE_ALLOWED;
    const isFileValid = extensionValid && sizeValid;

    if (!extensionValid) {
      appNotification.error({ message: `Only [${ALLOWED_FILE_EXTENSIONS.join(", ")}] extensions are allowed.` });
    } else if (!sizeValid) {
      appNotification.error({ message: "Maximum file size exceeded. Please keep your file under 12 MB." });
    }

    onValidateFile(fileExtension);
    return isFileValid;
  };

  const uploadFile = async ({ payload, onUploadProgress }) => {
    if (useOcr) {
      setIsLoadingOcr(true);
      try {
        const { data } = await uploadFileAction(payload.file, onUploadProgress);
        if (data.status === HTTP_STATUS_CODE.OK) {
          trackEvent("bill upload attachment success");

          onUploadFile(data.payload);
        } else {
          trackEvent("bill upload attachment failed");
        }
      } catch (e) {
        trackEvent("bill upload attachment failed");
      }

      setIsLoadingOcr(false);
    } else {
      onUploadFile({ fileUrl: URL.createObjectURL(payload.file), file: payload.file });
    }
  };

  return (
    <div
      className={styles.container}
      onDrop={(e) => {
        // This is a workaround to handle event tracking on drag and drop attachment
        // Current version of antd doesn't support onDrop for Uploader component
        // When upgrading antd version to 4.16, move this inside the Uploader component
        e.stopPropagation();
        e.preventDefault();
        trackEvent("bill drag and drop attachment");
      }}
    >
      <Title className={styles.title}>{title}</Title>
      {subtitle && <Paragraph className={styles.subtitle}>{subtitle}</Paragraph>}
      <UploadFile
        maxCount={1}
        beforeUpload={validateFile}
        customRequest={uploadFile}
        loading={isLoading}
        icon={<Icon src={uploadInvoiceLogo} />}
        text={"Drop a file here to get started"}
        hint={"We accept JPG, PNG, PDF format"}
        className={styles.uploader}
        ref={ref}
        onClick={() => {
          trackEvent("bill click upload attachment");
        }}
        loadingPlaceHolderText="Payment details will be retrieved from your uploaded file if available"
        accept={ALLOWED_FILE_EXTENSIONS.map((item) => `.${item}`).join(",")}
      />
    </div>
  );
});

Uploader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  onValidateFile: PropTypes.func,
  onUploadFile: PropTypes.func,
  setIsLoadingOcr: PropTypes.func,
  useOcr: PropTypes.bool,
};

Uploader.defaultProps = {
  title: "",
  subtitle: "",
  onValidateFile: () => {},
  onUploadFile: () => {},
  setIsLoadingOcr: () => {},
  useOcr: false,
};

export default Uploader;
