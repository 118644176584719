import { IItemID } from "Modules/DS/Menu/@types";

export const sectionedSearch = <T extends IItemID, K extends object = any>(
  input: string,
  arr: T[],
  displaykey: keyof T,
  subArrKey: keyof T,
  subArrDisplaykey?: keyof K
): T[] => {
  if (!input) return arr;

  const cleanInput = input
    .replace(/\\/g, "\\\\")
    .replace(/\?/g, "\\?")
    .replace(/\*/g, "\\*")
    .replace(/\+/g, "\\+")
    .replace(/\(/g, "\\(")
    .replace(/\)/g, "\\)")
    .replace(/\]/g, "\\]")
    .replace(/\[/g, "\\[");

  const result: T[] = [];
  const inputRegex = new RegExp(cleanInput, "i");

  if (!arr.length) return;

  arr.forEach((item) => {
    if (inputRegex.test(item[displaykey as string])) {
      result.push(item);
    } else {
      if (!item[subArrKey as string].length) return;

      const subArr: K[] = item[subArrKey as string].filter((val: K) => {
        return inputRegex.test(val[subArrDisplaykey ? (subArrDisplaykey as string) : (displaykey as string)]);
      });
      if (subArr.length > 0) {
        result.push({ ...item, [subArrKey]: subArr });
      }
    }
  });
  return result;
};
