import React, { useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "hook";

import { RootState } from "Redux/ConfigureStore";
import { getTeamListByUserId, getTeamMembersByTeamId } from "Redux/Actions";

import { DATE_RANGE } from "Modules/DS/Filter/@types";
import { AMOUNT_RANGE } from "Modules/DS/Select/@types";

import { ALL_TRXNS_COLUMNS } from "Views/Transaction/Constants";
import { Quick, RecipientFilter, BillsRecipientFilter } from "Views/Transaction/Filters";
import { GET_TRXN_RESPONSE_PARAMS, ITitleWithID, ITrxnFilterItem } from "Views/Transaction/@types";

import { ITeamData, IMemberData, TRANSACTION_FILTER } from "./types";

import billsStatus from "./Bills.json";

export const TRXNS_QUICK_FILTER_KEYS: string[] = [
  DATE_RANGE.START,
  DATE_RANGE.END,
  TRANSACTION_FILTER.TEAM_ID,
  TRANSACTION_FILTER.USER_ID,
  TRANSACTION_FILTER.MERCHANT,
];

export const BILLS_QUICK_FILTER_KEYS: string[] = [
  DATE_RANGE.START,
  DATE_RANGE.END,
  TRANSACTION_FILTER.MERCHANT,
  TRANSACTION_FILTER.BILL_STATUS,
  AMOUNT_RANGE.MIN,
  AMOUNT_RANGE.MAX,
];

const useTeamMembers = () => {
  const dispatch = useAppDispatch();

  const dispatchTeamListAction = useCallback(
    (teamId?: string) => {
      dispatch(getTeamListByUserId(teamId));
    },
    [dispatch]
  );

  const dispatchTeamMemberAction = useCallback(
    (userId?: string) => {
      dispatch(getTeamMembersByTeamId(userId));
    },
    [dispatch]
  );

  useEffect(() => {
    dispatchTeamListAction();
  }, [dispatchTeamListAction]);

  useEffect(() => {
    dispatchTeamMemberAction();
  }, [dispatchTeamMemberAction]);

  const loadingTeamList: boolean = useAppSelector(
    (state: RootState) => state.teamListByUserIdReducer?.loading || false
  );
  const teamListData: ITeamData[] = useAppSelector((state: RootState) => state.teamListByUserIdReducer?.data || []);

  const loadingTeamMembers: boolean = useAppSelector(
    (state: RootState) => state.teamMembersByTeamIdReducer?.loading || false
  );
  const teamMembersData: any[] = useAppSelector((state: RootState) => state.teamMembersByTeamIdReducer?.data || []);

  return {
    teamListData,
    loadingTeamList,
    loadingTeamMembers,
    teamMembersData,
    dispatchTeamListAction,
    dispatchTeamMemberAction,
  };
};

export const TeamFilter = ({ id }: { id: string }) => {
  const { loadingTeamList, teamListData, dispatchTeamMemberAction } = useTeamMembers();

  return (
    <Quick.Single<ITeamData>
      id={id}
      searchable
      loading={loadingTeamList}
      defaultValue={ALL_TRXNS_COLUMNS.TEAM}
      displayKey={GET_TRXN_RESPONSE_PARAMS.TEAM_NAME}
      dispatchOnSelect={(value) => dispatchTeamMemberAction(value)}
      dispatchOnClear={() => dispatchTeamMemberAction()}
    >
      {teamListData}
    </Quick.Single>
  );
};

export const EmployeeFilter = ({ id }: { id: string }) => {
  const { loadingTeamMembers, teamMembersData, dispatchTeamListAction } = useTeamMembers();

  return (
    <Quick.Single<IMemberData>
      id={id}
      searchable
      loading={loadingTeamMembers}
      defaultValue={ALL_TRXNS_COLUMNS.EMPLOYEE}
      displayKey={"name"}
      dispatchOnSelect={(value) => dispatchTeamListAction(value)}
      dispatchOnClear={() => dispatchTeamListAction()}
    >
      {teamMembersData}
    </Quick.Single>
  );
};

export const BillStatusFilter = () => {
  const billsStatusList = billsStatus.status.map((item: string) => ({
    id: item,
    title: item,
  }));

  return (
    <Quick.Multiple<ITitleWithID>
      id={TRANSACTION_FILTER.BILL_STATUS}
      searchable
      defaultValue="Status"
      displayKey="title"
    >
      {billsStatusList}
    </Quick.Multiple>
  );
};

export const TRXNS_QUICK_FILTER_LIST: ITrxnFilterItem[] = [
  { id: "ALL-TRXN-QUICK-FILTER-1", enabled: true, Component: <Quick.Date id="all" asISO={false} /> },
  {
    id: "ALL-TRXN-QUICK-FILTER-2",
    enabled: true,
    Component: <TeamFilter id={TRANSACTION_FILTER.TEAM_ID} />,
  },
  {
    id: "ALL-TRXN-QUICK-FILTER-3",
    enabled: true,
    Component: <EmployeeFilter id={TRANSACTION_FILTER.USER_ID} />,
  },
  { id: "ALL-TRXN-QUICK-FILTER-4", enabled: true, Component: <RecipientFilter id={TRANSACTION_FILTER.MERCHANT} /> },
].filter((item) => item.enabled);

export const BILLS_TRXN_QUICK_FILTER_LIST: ITrxnFilterItem[] = [
  { id: "BILLS-TRXN-QUICK-FILTER-1", enabled: true, Component: <Quick.Date id="bills" asISO={false} /> },
  {
    id: "BILLS-TRXN-QUICK-FILTER-2",
    enabled: true,
    Component: <BillsRecipientFilter id={TRANSACTION_FILTER.MERCHANT} />,
  },
  { id: "BILLS-TRXN-QUICK-FILTER-3", enabled: true, Component: <BillStatusFilter /> },
  { id: "BILLS-TRXN-QUICK-FILTER-4", enabled: true, Component: <Quick.Amount id="bills_amount" /> },
].filter((item) => item.enabled);
