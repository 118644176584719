import React, { useContext, useEffect, useState } from "react";

import Menu from "Modules/DS/Menu";
import { FilterItem } from "Modules/DS/Filter";
import { POPOVER_WIDTH } from "Modules/DS/Popover";
import { IAmountFilterProps, IAmountFormProps } from "Modules/DS/Filter/@types";
import Select, { AMOUNT_RANGE, ISelectContext, SelectContext } from "Modules/DS/Select";

import styles from "./Amount.module.scss";
import classNames from "classnames";

export const invalidAmountErrorMsg = (isNotInMoreFilters = false) => (
  <span className={classNames(styles.invalidAmountError, { [styles.errorSpacing]: isNotInMoreFilters })}>
    Invalid minimum and maximum amount entered
  </span>
);

export const AmountFilter = <T extends object = any>({
  id,
  filter,
  onApply,
  onClear,
  defaultValue = "Paid Amount",
}: IAmountFilterProps<T>) => {
  const { closeSelect } = useContext<ISelectContext>(SelectContext);

  const [title, setTitle] = useState<string>(defaultValue);

  const [selected, setSelected] = useState<boolean>(() => {
    // IMPROVEMENT:
    // it's not consistent with onApplyHandler where it will
    // set selected to true when one of the amount is filled
    // which means it should use OR rather than AND
    return filter[AMOUNT_RANGE.MIN] && filter[AMOUNT_RANGE.MAX];
  });

  const [clear, setClear] = useState<boolean>(false);
  const [showInvalidAmountError, setShowInvalidAmountError] = useState(false);

  const [_filter, setFilter] = useState<IAmountFormProps>(filter);

  const reset = [AMOUNT_RANGE.MAX, AMOUNT_RANGE.MIN];

  useEffect(() => {
    if (!_filter?.min_amount && !_filter?.max_amount && clear) setClear(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clear]);

  const onClearHandler = () => {
    setFilter({
      ..._filter,
      [AMOUNT_RANGE.MAX]: undefined,
      [AMOUNT_RANGE.MIN]: undefined,
    });
    setShowInvalidAmountError(false);
    setClear(true);
  };

  const onApplyHandler = () => {
    if (!_filter?.min_amount && !_filter?.max_amount) return;

    // meaning that if 1 of the filter is filled, isInvalidAmount will be false
    // which will enter the IF condition
    const isInvalidAmount = _filter?.min_amount > _filter?.max_amount;
    setShowInvalidAmountError(isInvalidAmount);
    if (!isInvalidAmount) {
      onApply(_filter);
      setSelected(true);
      closeSelect();
    }
  };

  const clearFilterHandler = () => {
    onClear(reset);
    setTitle(defaultValue);
    setSelected(false);
    setFilter({} as T);
    closeSelect();
    setShowInvalidAmountError(false);
  };

  return (
    <FilterItem
      id={id}
      title={title}
      selected={selected}
      clearFilter={clearFilterHandler}
      popoverWidth={POPOVER_WIDTH.M}
    >
      <div className={styles.content}>
        <Select.Range.Input<IAmountFormProps>
          filter={_filter}
          onApply={(key, value) => setFilter({ ..._filter, [key]: value })}
          clear={clear}
        />
      </div>
      {showInvalidAmountError && invalidAmountErrorMsg(true)}
      <Menu.Actions onClear={onClearHandler} onApply={onApplyHandler} />
    </FilterItem>
  );
};
