import React from "react";

import { More } from "Views/Transaction/Filters";

import { ALL_TRXNS_COLUMNS } from "Views/Transaction/Constants";
import { ITitleWithID } from "Views/Transaction/@types";
import { useActiveCategory } from "utility/useActiveCategory";

export const CategoryFilter = ({ id, appliedKey = "title" }: { id: string; appliedKey?: keyof ITitleWithID }) => {
  const label = ALL_TRXNS_COLUMNS.CATEGORY;
  const { isLoading, categoryList } = useActiveCategory();

  const data: ITitleWithID[] = categoryList.map((category) => ({
    id: category.id.toString(),
    title: category.categoryName,
  }));

  return (
    <More.Multiple<ITitleWithID>
      id={id}
      searchable
      label={label}
      loading={isLoading}
      displayKey="title"
      appliedKey={appliedKey}
    >
      {data}
    </More.Multiple>
  );
};
