import * as React from "react";
import { deleteCard } from "Redux/DataCalls/Cards.api";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import { showSuccessNotification, showErrorNotification } from "Views/Card/Components/Notification";
import CardDeleteModal from "Views/Card/Delete";
import { DeleteCardEventNames } from "Views/Card/Analytics";
import { trackEvent } from "utility/analytics";
import { CARD_LIST_TABS } from "Views/Card/List/types";

interface IshowCardDeleteModalDetails {
  id: string;
  visible: boolean;
}

const trackDeleteCardEvent = (eventSource: string, cardId) => {
  trackEvent(DeleteCardEventNames.CARD_DELETE_CARD_CLICKED, {
    card_id: cardId,
    card_delete_event_action: eventSource,
  });
};

export const trackCardDeleteApiResponse = (isSucess: boolean) => {
  trackEvent(DeleteCardEventNames.CARD_DELETE_PROCESSED, {
    card_process_success: isSucess,
  });
};

type deleteModalType = JSX.Element | null;

type useCardDeleteModalType = (
  getCardListData: (page: number, view?: string | string[]) => void
) => [(cardId: string) => void, string[], deleteModalType];

export const useCardDeleteModal: useCardDeleteModalType = (getCardListData) => {
  const [showCardDeleteModalDetails, setShowCardDeleteModalDetails] =
    React.useState<IshowCardDeleteModalDetails | null>({
      id: "",
      visible: false,
    });
  const [deleteInProgressCards, setDeleteInProgressCards] = React.useState<string[]>([]);
  const handleError = (statusMessage) => {
    showErrorNotification(statusMessage);
    trackCardDeleteApiResponse(false);
  };
  let deleteModal: deleteModalType = null;
  const onDelete = (deleteReason: string, cardId: string) => {
    if (deleteInProgressCards?.indexOf(cardId) !== -1) return;
    setDeleteInProgressCards((prevState) => [...prevState, cardId]);
    setShowCardDeleteModalDetails(null);
    deleteCard(cardId, { type: deleteReason })
      .then(({ data }) => {
        const { status_message = "" } = data?.payload || {};
        if (data?.status === HTTP_STATUS_CODE.OK) {
          showSuccessNotification("Card successfully deleted");
          getCardListData(0, CARD_LIST_TABS.CARD_LIST);
          trackCardDeleteApiResponse(true);
        } else {
          handleError(status_message);
        }
      })
      .catch((err) => {
        const { status_message = "Something went wrong! Please try again later." } = err?.response?.data?.payload || {};
        handleError(status_message);
      })
      .finally(() => {
        setDeleteInProgressCards((prevState) => [...(prevState || [])]?.filter((id) => id !== cardId));
      });
    trackDeleteCardEvent("Delete Confirm", cardId);
  };
  if (showCardDeleteModalDetails?.visible) {
    deleteModal = (
      <CardDeleteModal
        cardId={showCardDeleteModalDetails.id}
        onClose={() => setShowCardDeleteModalDetails(null)}
        onDelete={(deleteReason) => onDelete(deleteReason, showCardDeleteModalDetails.id)}
      />
    );
  }

  const setShowCardDeleteModal = (cardId: string) => {
    setShowCardDeleteModalDetails({
      id: cardId,
      visible: true,
    });
    trackDeleteCardEvent("Delete", cardId);
  };

  return [setShowCardDeleteModal, deleteInProgressCards, deleteModal];
};
