import React from "react";
import PropTypes from "prop-types";

import Icon from "Modules/icons";

export const PopupItem = ({ targetId, title, imageUrl, alt }) => {
  return (
    <>
      <Icon targetId={targetId} src={imageUrl} alt={alt} />
      <p id={targetId}>{title}</p>
    </>
  );
};

PopupItem.propTypes = {
  alt: PropTypes.string,
  title: PropTypes.string,
  imageUrl: PropTypes.string,
  targetId: PropTypes.string,
};
