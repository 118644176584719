import React from "react";
import PropTypes from "prop-types";
import Modal from "./index";
import Icon from "../icons";
import { virtualCard, physicalCard, activateCard, paylahIcon, userIcon, peopleIcon } from "assets/img";
import "./modal.scss";

const ChoiceModal = ({
  className = "modal-money",
  show = false,
  close = () => {},
  showVirtual,
  subtitle,
  title = "",
  showEmp,
  showTeam,
  showLah,
  showPhysical,
  showPhysicalSync,
  disableSendMoneyToColleague = false,
}) => {
  return (
    <Modal className={className} show={show} close={close} subtitle={subtitle} title={title}>
      <div className={"modal-money__action-btns"}>
        {(showEmp || showVirtual) && (
          <Icon
            action={showEmp ? showEmp : showVirtual}
            src={showVirtual ? virtualCard : userIcon}
            title={showEmp ? "Colleague" : "Create Virtual Card"}
            disabled={disableSendMoneyToColleague}
          />
        )}
        {(showTeam || showPhysical) && (
          <Icon
            action={showTeam ? showTeam : showPhysical}
            src={showPhysical ? physicalCard : peopleIcon}
            title={showTeam ? "Budget" : "Request Physical Card"}
          />
        )}
        {showLah && <Icon src={paylahIcon} title={"PayLah!"} />}
        {showPhysicalSync && <Icon action={showPhysicalSync} src={activateCard} title={"Activate Physical Card"} />}
      </div>
    </Modal>
  );
};

ChoiceModal.propTypes = {
  className: PropTypes.string,
  show: PropTypes.bool,
  close: PropTypes.func,
  subtitle: PropTypes.string,
  title: PropTypes.string,
  showEmp: PropTypes.func,
  showTeam: PropTypes.func,
  showLah: PropTypes.func,
  showVirtual: PropTypes.func,
  showPhysical: PropTypes.func,
  showPhysicalSync: PropTypes.func,
};

export default ChoiceModal;
