/**
 * @fileoverview Session management component for the application.
 *
 * The `SessionManager` component is responsible for managing session-based tasks
 * such as user authentication
 *
 * @author Krishna, Ritik, Sushmitha, Bakshi
 * @created 6 Feb 2023
 * @lastModified 1 Sept 2023
 */
import React, { useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";

// Route components
import { ISessionConditions } from "Route/@types";
import { COMPANY_VERIFICATION } from "Route/KYX/paths";
import { ACCOUNTING_PATHS } from "Route/Accounting/paths";

// Custom Redux hooks
import { useAppDispatch, useAppSelector } from "hook";

// Redux components
import { RootState } from "Redux/ConfigureStore";
import { getTeamList } from "Redux/Actions/Teams/teamListAction";
import { checkAuth, UserInfoFunc, getOrgConfigsFunc } from "Redux/Actions";

// utility components
import { GetOrgId } from "utility";
import { cookieNames, getCookieValue } from "utility/CookieHelper";

//Views components
import { KYB_ONBOARDING_VERSION } from "Views/KYX/Constants";
import { COMMON_PATHS } from "Route/Common/paths";

interface ISessionProps {
  children: (conditions: ISessionConditions) => React.ReactElement;
}

const SessionManager = ({ children }: ISessionProps) => {
  const location = useLocation();

  const dispatch = useAppDispatch();

  const isKybRoute = useMemo(
    () =>
      (Object.values(COMPANY_VERIFICATION) as string[]).includes(
        location.pathname,
      ),
    [location.pathname],
  );

  const is404Route = useMemo(
    () => location.pathname.includes(COMMON_PATHS.PAGE_NOT_FOUND),
    [location.pathname],
  );

  // Add this flag to hide the sidebar to handle netsuite callback
  const isNetsuiteCallback = useMemo(
    () => location.pathname === ACCOUNTING_PATHS.NETSUITE_CALLBACK,
    [location.pathname],
  );

  // Extract necessary states from the Redux store
  const { user, teamList, orgConfigs, loginData, loading, switchingAccount } =
    useAppSelector((state: RootState) => ({
      user: state.userInfoReducer,
      teamList: state.teamList.data.payload,
      orgConfigs: state.orgConfigs.data,
      loginData: state.loginReducer.data,
      loading: state.loginReducer.loading,
      switchingAccount: state.loginReducer.switchingAccount,
    }));

  // Check if the user has administrative privileges
  const isAdmin = useMemo(
    () => user?.isAdmin || loginData?.payload?.is_admin || false,
    [user, loginData],
  );
  const userData = useMemo(() => user?.data?.payload, [user]);

  const isOnboardingVersionThree: boolean =
    getCookieValue(cookieNames.ONBOARDING_VERSION) === KYB_ONBOARDING_VERSION;

  // is_rekyb_required should be true to show the modal and banner
  const is_rekyb_required: boolean =
    getCookieValue(cookieNames.IS_REKYB_REQUIRED) === "true";

  // Dispatch necessary actions upon mount
  useEffect(() => {
    dispatch(checkAuth());
  }, [dispatch]);

  useEffect(() => {
    if (!orgConfigs) {
      dispatch(getOrgConfigsFunc(GetOrgId()));
    }
  }, [dispatch, orgConfigs]);

  useEffect(() => {
    if (!teamList) {
      dispatch(getTeamList());
    }
  }, [dispatch, teamList]);

  useEffect(() => {
    if (!userData) {
      dispatch(UserInfoFunc());
    }
  }, [dispatch, userData]);

  // Provide essential session-based properties to children components
  return children({
    showTabChangeModal: !loading,
    showRekyb: is_rekyb_required,
    showSidebarLayout: !isKybRoute && !isNetsuiteCallback && !is404Route,
    showOverlayLoader: switchingAccount,
    showTnC: isAdmin && !isKybRoute && !is_rekyb_required,
  });
};

export default SessionManager;
