import React, { useEffect, useMemo, useState } from "react";
import { PreviewBulkColumn, getColumns, transformTableData } from "./helper";
import { ArrowRightOutlined } from "@ant-design/icons";
import useSWR from "swr";
import { Link, useHistory, useLocation } from "react-router-dom";
import { ExternalOutline } from "@spenmo/splice";
import { useSelector } from "react-redux";

import DataTable, { Table, DATA_TABLE_SIZE_TYPES } from "Modules/DS/DataTable";

import { PRODUCT_NAME } from "Redux/ModularProduct";
import { RootState } from "Redux/ConfigureStore";
import { submitBulkDraftBill } from "Redux/DataCalls/Disbursement.api";

import { LocationState, ToasterData } from "..";
import Button from "Modules/button";
import Toaster, { TOASTER_SIZE_TYPE, TOASTER_STATUS_TYPE } from "Modules/DS/Toaster";
import EditBillModal from "Views/UploadInvoice/EditBillModal";
import RefreshFxModal from "Views/UploadInvoice/InvoiceForm/RefreshFxModal";
import WithholdingTaxModal from "Views/UploadInvoice/InvoiceForm/WithholdingTaxModal";

import useGetBillUrl from "Views/Bills/useGetBillUrl";
import useCheckOrgConfigs from "customHooks/useCheckOrgConfigs";

import { currencyFormatterV2, plural } from "utility";
import { LEARN_MORE_URL } from "Modules/BillFee/const";
import { INVALID_FX_RATE } from "Views/UploadInvoice/const";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";

import { BillFooterTypes, BillFormTypes } from "Views/UploadInvoice/EditBillModal/types.d";
import styles from "./PreviewBulk.module.scss";
import { useMutableData } from "API/useData";
import { API_URL } from "Views/Bills/V2/constants";
import { SaaSConfig } from "Views/Bills/V2/BillForm/type";

const PreviewBulk = ({ billIDs, selectedAll = false, onClose = () => {} }) => {
  const location = useLocation<LocationState>();

  const [walletCurrency, orgDetail] = useSelector((state: RootState) => [
    state.wallet?.data?.currency_code,
    state.b2bOrgDetailReducer,
  ]);
  const { isUseApprovalSystem, isShowBillFeeCalculator } = orgDetail?.data?.payload || {};

  const isWithholdingTaxEnabled = useCheckOrgConfigs(PRODUCT_NAME.BILL_WITHHOLDING_TAX);

  const { data: saasConfigResponse } = useMutableData(`${API_URL.saasConfig}?view=draft`);
  const saasConfig: SaaSConfig = useMemo(() => saasConfigResponse?.data?.payload, [saasConfigResponse?.data?.payload]);
  const { isPaymentEnabled = null } = saasConfig || {};

  const selectedBillIds = useMemo(() => {
    return billIDs && billIDs.length > 0 ? atob(billIDs).split(",") : [];
  }, [billIDs]);

  // const [isLoadingBillData, setIsLoadingBillData] = useState(false);
  // const [data, setData] = useState([]);
  const [isEditBillVisible, setIsEditBillVisible] = useState(false);
  const [editBillID, setEditBillID] = useState(null);
  const [showErrorOnly, setShowErrorOnly] = useState(false);
  const [showWithholdingModal, setShowWithholdingModal] = useState(false);
  const [toasterData, setToasterData] = useState<ToasterData>({
    visible: false,
    message: "",
    status: TOASTER_STATUS_TYPE.ERROR,
  });
  const [showFxModal, setShowFxModal] = useState(false);

  const { billParamsBuilder } = useGetBillUrl();

  const clearToasterData = () => {
    setToasterData({
      visible: false,
      message: "",
      status: TOASTER_STATUS_TYPE.ERROR,
    });
    window.history.replaceState({}, document.title);
  };

  const history = useHistory();
  const locationState = location?.state;

  useEffect(() => {
    const _toasterData = locationState?.toasterData;
    const closeModal = locationState?.closeModal;
    const billID = locationState?.billID;

    if (closeModal) {
      setIsEditBillVisible(false);
      billID && mutate();
    }

    if (_toasterData) {
      const action =
        _toasterData.actionLabel === "View Bill"
          ? () => {
              history.push(billParamsBuilder({ invoiceid: billID }));
            }
          : undefined;
      setToasterData({ ..._toasterData, action });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationState?.toasterData]);

  const {
    data: bulkListData,
    isValidating,
    mutate,
  } = useSWR(selectedBillIds ? [selectedBillIds, selectedAll] : null, ([selectedBillIds, selectedAll]) =>
    submitBulkDraftBill({ billIDs: selectedBillIds, isConfirmed: false, selectedAll })
      .then((response) => {
        if (!response?.payload?.data?.tableData) {
          throw new Error();
        }

        return response;
      })
      .catch(() => {
        setToasterData({
          visible: true,
          message: "Error while fetching bill data.",
          status: TOASTER_STATUS_TYPE.ERROR,
          action: mutate,
          actionLabel: "Retry",
        });
      })
  );

  const { tableHeader = [], tableData: billTableData = [] } = bulkListData?.payload?.data || {};
  const isWHT = tableHeader?.some((item) => ["atcCode", "whtRate", "whtAmount"].includes(item.key));
  const data = useMemo(() => transformTableData(billTableData), [billTableData]);

  const handleSubmitBulk = () => {
    submitBulkDraftBill({ billIDs: selectedBillIds, isConfirmed: true, selectedAll })
      .then((response) => {
        if (response?.status === HTTP_STATUS_CODE.OK) {
          history.push({
            pathname: "/bills/drafts",
            state: {
              data: {
                isSuccess: true,
                isMultipleUpload: true,
                isBalanceSufficient: true,
                toasterData: {
                  message: `${plural(stats?.billCount, "bill", "s", true)} ${
                    stats?.billCount > 1 ? "have" : "has"
                  } been succesfully submitted ${isUseApprovalSystem ? "for approval" : "to be paid automatically"}`,
                  actionLabel: plural(stats?.billCount, "View Bill", "s", false),
                  actionUrl: `/bills/submitted${
                    selectedBillIds.length === 1 ? `?invoiceid=${selectedBillIds[0]}` : ""
                  }`,
                },
              },
            },
          });
        } else if (response?.error?.code === INVALID_FX_RATE) {
          mutate();
          setShowFxModal(true);
        } else {
          throw new Error(response?.errorMessage);
        }
      })
      .catch((e) => {
        setToasterData({
          visible: true,
          message: e?.message || "Unexpected error. Please try again later.",
          status: TOASTER_STATUS_TYPE.ERROR,
        });
      });
  };

  const handleEditBill = (id) => {
    history.push(`/bills/drafts/bulk/edit/${id}?data=${billIDs}`);
  };

  const handleCloseEditModal = () => {
    mutate();
    setIsEditBillVisible(false);
    setEditBillID(null);
  };

  const tableData = data.filter((item) => {
    return showErrorOnly ? item.error && Object.keys(item.error).length > 0 : true;
  });

  const stats = useMemo(() => {
    if (!data) {
      return;
    }
    const billCount = data.length;
    const totalAmount = data.reduce((acc, curVal) => acc + curVal?.amountToDeduct || 0, 0);
    const totalFee = data.reduce((acc, curVal) => acc + curVal?.transferFee || 0, 0);
    const errorCount = data.filter((item) => Object.keys(item.error).length > 0).length;
    return { billCount, totalAmount, errorCount, totalFee };
  }, [data]);

  const hasError = stats.errorCount > 0;

  const handleShowWithholdingTaxModal = () => {
    setShowWithholdingModal(true);
  };

  const handleLearnMoreClick = (event: React.MouseEvent) => {
    event.preventDefault();
    window.open(LEARN_MORE_URL, "_blank");
  };

  return (
    <>
      <div className={styles.container}>
        <h1 className={styles.title}>Preview Bulk Submission</h1>
        <div className={styles.back} onClick={onClose}>
          Back to Manage Draft
        </div>
        <p className={styles.desc}>
          <span className={styles.highlight}>{stats?.billCount}&nbsp;</span>
          {`payment${stats?.billCount > 1 ? "s" : ""}`} amounting to &nbsp;
          <span className={styles.highlight}>{currencyFormatterV2(stats?.totalAmount, walletCurrency)}</span> &nbsp;to
          be paid
        </p>
        {isShowBillFeeCalculator && isPaymentEnabled && (
          <p className={styles.desc}>
            <div>
              Transfer fee of{" "}
              <span className={styles.highlight}>{currencyFormatterV2(stats?.totalFee, walletCurrency)}</span> will be
              charged at the time of payment.
              <Link to={"/"} onClick={handleLearnMoreClick}>
                &nbsp;Learn more
              </Link>
            </div>
            <ExternalOutline onClick={handleLearnMoreClick} iconColor="var(--icon-link)" size="24" />
          </p>
        )}

        {hasError && (
          <p className={styles.errorDesc}>
            There are <span className={styles.errorCount}>{stats?.errorCount}</span> fields that require changes
            <button
              className={styles.errorBtn}
              onClick={() => {
                setShowErrorOnly(!showErrorOnly);
              }}
            >
              {showErrorOnly ? "Show all payments" : "Show payments that require changes"}
            </button>
          </p>
        )}
        <DataTable className={styles.draftTable} size={DATA_TABLE_SIZE_TYPES.LARGE}>
          <Table<PreviewBulkColumn>
            loading={isValidating}
            dataSource={tableData}
            scrollHorizontal={500}
            paginationEnabled={false}
            rowClassName={(record) => {
              return record?.error && Object.keys(record.error).length > 0 ? styles.rowError : styles.row;
            }}
          >
            {getColumns({ hasError, handleEditBill, tableHeader })}
          </Table>
        </DataTable>
        <Button
          className={styles.submit}
          rounded
          disabled={hasError}
          action={isWithholdingTaxEnabled && isWHT ? handleShowWithholdingTaxModal : handleSubmitBulk}
        >
          <>
            {isUseApprovalSystem ? `Submit for Approval` : `Submit for Payment`}
            <ArrowRightOutlined />
          </>
        </Button>
      </div>

      <EditBillModal
        title="Edit Draft"
        visible={isEditBillVisible}
        billID={editBillID}
        type={BillFormTypes.Draft}
        footerType={BillFooterTypes.DraftWithoutSubmit}
        onClose={handleCloseEditModal}
        refreshList={() => mutate()}
      />

      <WithholdingTaxModal
        visible={showWithholdingModal}
        close={() => {
          setShowWithholdingModal(false);
        }}
        action={() => {
          handleSubmitBulk();
          setShowWithholdingModal(false);
        }}
      />

      <RefreshFxModal
        visible={showFxModal}
        setVisible={setShowFxModal}
        action={() => {
          handleSubmitBulk();
          setShowFxModal(false);
        }}
        actionLabel="Submit"
        hasCloseButton
        closeButtonLabel="Back to File Preview"
      />

      <Toaster size={TOASTER_SIZE_TYPE.M} onClose={clearToasterData} {...toasterData} />
    </>
  );
};

export default PreviewBulk;
