import React from "react";

import { capitalizeFirstLetter } from "utility";

import { Header, Status } from "Views/Transaction/Details";
import { ITransferTrxnDetails } from "Views/Transaction/@types";
import { getRecordDetailsDate } from "Views/Transaction/Helper";

import { Details } from "./Details";

export const TransferTrxnDetails = ({
  amount,
  currencyCode,
  requesterName,
  recipientName,
  teamName,
  trxnNumber,
  createdDate,
  trxnType,
  status,
}: ITransferTrxnDetails) => {
  const date = getRecordDetailsDate(createdDate);
  const team = capitalizeFirstLetter(teamName);

  return (
    <>
      <Header title={recipientName} subtitle={teamName} amount={amount} currencyCode={currencyCode} />
      <Status date={date} status={status} />
      <Details
        date={date}
        team={team}
        trxnType={trxnType}
        trxnNumber={trxnNumber}
        requesterName={capitalizeFirstLetter(requesterName)}
      />
    </>
  );
};
