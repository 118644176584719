export const TOPUP_PAYMENT_TYPES = {
  BANK_TRANSFER: "Bank Transfer",
  BANK_TRANSFER_WITH_VIRTUAL_ACCOUNT: "Bank Transfer (Virtual Account)",
  CREDIT_CARD: "Credit Card",
  CREDIT_CARD_WITH_FAST: "FAST / Credit Card",
  PAY_NOW: "Pay Now",
  USD_VIRTUAL_ACCOUNT_OR_CARD: "USD Virtual Account / Card",
  TRANSFER_BELOW_50: "Transfers below PHP 50K",
  TRANSFER_ABOVE_50: "Transfers above PHP 50K",
};
