import React from "react";
import { IllBills, Typography } from "@spenmo/splice";

import styles from "./index.module.scss";

const ListEmptyState: React.FC = () => {
  return (
    <div className={styles.emptyState}>
      <IllBills size={120} />
      <Typography className={styles.info} size="caption-m" variant="body-content">
        No transactions found for this subscription
      </Typography>
    </div>
  );
};

export default ListEmptyState;
