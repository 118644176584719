import React, { useMemo, useState } from "react";
import { Card, Button, ChevronDown, ChevronUp } from "@spenmo/splice";
import usePermissionCheck from "Permission/usePermissionCheck";
import { trackEvent } from "utility/analytics";

import { TotalSpendChart } from "./TotalSpendChart";
import { UpcomingSpend } from "./UpcomingSpend";
import { ActionRequired } from "./ActionRequired";
import { SpendAnalysis } from "./SpendAnalysisChart";
import { AnnualSpendByBudget } from "./AnnualSpendByBudget";
import { generateAnalysisData, generateSpendingSummaryData } from "./SpendAnalysisChart/utils";
import { SKIP_FIRST_3_MONTHS, SKIP_LAST_3_MONTHS, STARTING_MONTH } from "../Constants";
import { SUBSCRIPTION_ANALYTICS_PARAMS } from "../Permission";
import { SUBSCRIPTION_EVENT } from "../Analytic";

import styles from "./styles.module.scss";

export const SubscriptionAnalysis = ({
  summaryData,
  isLoadingSummary,
  spendAnalysisData,
  spendAnalysisLoading,
}: {
  summaryData: any;
  isLoadingSummary: boolean;
  spendAnalysisData: any;
  spendAnalysisLoading: boolean;
}) => {
  const IS_ALLOWED_TO_SEE_SUMMARY = (usePermissionCheck(SUBSCRIPTION_ANALYTICS_PARAMS) as boolean) || true;

  const [showMoreInsights, setShowMoreInsights] = useState(false);

  const analysisData = useMemo(
    () => generateAnalysisData(spendAnalysisData?.data?.payload?.spend_analysis.slice(SKIP_FIRST_3_MONTHS) || []),
    [spendAnalysisData?.data?.payload?.spend_analysis]
  );

  const totalSpendData = useMemo(
    () =>
      generateAnalysisData(
        spendAnalysisData?.data?.payload?.spend_analysis.slice(STARTING_MONTH, SKIP_LAST_3_MONTHS) || []
      ),
    [spendAnalysisData?.data?.payload?.spend_analysis]
  );

  const spendingSummaryData = useMemo(
    () => generateSpendingSummaryData(summaryData?.data?.payload || {}),
    [summaryData?.data?.payload]
  );

  const budgetsAnalysis = spendAnalysisData?.data?.payload?.budget_analysis || [];

  if (!IS_ALLOWED_TO_SEE_SUMMARY) {
    return null;
  }

  return (
    <>
      <div className={styles.subscriptionAnalysis}>
        <Card elevation="level-1" padding="medium" radius="m">
          <TotalSpendChart
            totalSpendData={totalSpendData}
            spendingSummaryData={spendingSummaryData}
            isLoading={spendAnalysisLoading || isLoadingSummary}
          />
        </Card>
        <Card elevation="level-1" padding="medium" radius="m">
          <UpcomingSpend
            spendingSummaryData={spendingSummaryData}
            isLoading={spendAnalysisLoading || isLoadingSummary}
          />
        </Card>
        <Card elevation="level-1" padding="medium" radius="m">
          <ActionRequired />
        </Card>
        {showMoreInsights && (
          <>
            <Card elevation="level-1" padding="medium" radius="m">
              <SpendAnalysis analysisData={analysisData} isLoading={spendAnalysisLoading} />
            </Card>
            <Card elevation="level-1" padding="medium" radius="m">
              <AnnualSpendByBudget budgetsAnalysis={budgetsAnalysis} />
            </Card>
          </>
        )}
      </div>
      <div className={styles.btnContainer}>
        <Button
          size="s"
          variant="tertiary"
          onClick={() => {
            setShowMoreInsights((prevState) => !prevState);
            trackEvent(SUBSCRIPTION_EVENT.INTERACTION_WITH_SUBSCRIPTION_ANALYTIC, {
              action_required_event_source: "Interact with fewer or more insight",
            });
          }}
          className={styles.btn}
        >
          {showMoreInsights ? "Fewer" : "More"} insights
          {showMoreInsights ? (
            <ChevronUp size="16" iconColor="var(--blue-060)" />
          ) : (
            <ChevronDown size="16" iconColor="var(--blue-060)" />
          )}
        </Button>
      </div>
    </>
  );
};
