import React from "react";
import classNames from "classnames";
import { Tooltip } from "Modules/DS/Tooltip";
import { TooltipPositionEnum, TooltipArrowAlignmentEnum } from "Modules/DS/Tooltip/types.d";
import MiniCard from "Views/Card/List/Table/CardTitle/MiniCard";
import { CARD_STATUS, ICardDetails } from "Views/Card/types";
import { CARD_COLORS } from "constants/Card/CardColors.constants";
import { ActivateCard } from "assets/v1.1/icons/Core/ActivateCard";
import { redLock16x16 } from "assets/icons/card";
import styles from "Views/Card/List/Table/CardTitle/index.module.scss";

const tooltipTexts = {
  NOT_ACTIVATED_CARD: "Card is not activated.",
  DISABLED_CARD: "Card is disabled.",
};
interface ICardTitleProps {
  cardData: ICardDetails;
  displayMiniCard: boolean;
  disabledCard: boolean;
}

const CardTitle = ({ cardData, displayMiniCard, disabledCard }: ICardTitleProps) => {
  const { cardColor, cardLastFourDigits, cardTitle, cardType, cardStatus } = cardData || {};
  const getTooltip = () => {
    if (cardStatus === CARD_STATUS.NOT_ACTIVATED) {
      return (
        <Tooltip
          children={<img src={ActivateCard.Line[16]} alt="Not activated card" height={16} width={16} />}
          text={tooltipTexts.NOT_ACTIVATED_CARD}
          position={TooltipPositionEnum.TOP}
          alignArrow={TooltipArrowAlignmentEnum.CENTER}
        />
      );
    } else if (cardStatus === CARD_STATUS.DISABLED) {
      return (
        <Tooltip
          children={<img src={redLock16x16} alt="disabled card" height={16} width={16} />}
          text={tooltipTexts.DISABLED_CARD}
          position={TooltipPositionEnum.TOP}
          alignArrow={TooltipArrowAlignmentEnum.CENTER}
        />
      );
    } else {
      return null;
    }
  };

  const cardTitleClassName = classNames(styles["card-title"], {
    [styles["card-title--disabled"]]: disabledCard,
  });

  return (
    <div className={styles["container"]}>
      {displayMiniCard && (
        <MiniCard
          cardLastFourDigits={cardStatus === CARD_STATUS.IN_PROGRESS ? "XXXX" : cardLastFourDigits}
          cardColor={cardColor || CARD_COLORS[0]}
          disabled={disabledCard}
        />
      )}
      <div className={styles["card-title-container"]}>
        <div className={styles["card-title__details"]}>
          <p className={cardTitleClassName}>{cardTitle}</p>
          {getTooltip()}
        </div>
        <p className={styles["card-type"]}>{cardType}</p>
      </div>
    </div>
  );
};

export default CardTitle;
