import React from "react";
import { useHistory } from "react-router-dom";

import { AddOutline, Button, Typography } from "@spenmo/splice";

import { SUBSCRIPTION_PATH } from "Route/Subscription/path";

import styles from "./styles.module.scss";

export const Header = ({ isAllowedToCreateSubscription }: { isAllowedToCreateSubscription: boolean }) => {
  const history = useHistory();

  return (
    <div className={styles.header}>
      <Typography variant="headline-content" size="l" tag="h1" children="Subscriptions" />
      {isAllowedToCreateSubscription && (
        <Button
          size="s"
          variant="primary"
          icon={AddOutline}
          onClick={() => history.push(`${SUBSCRIPTION_PATH.CREATE_SUBSCRIPTION_MANAGEMENT}`)}
        >
          New Subscription
        </Button>
      )}
    </div>
  );
};
