import APIclient from "API/Client";
import { GetOrgId } from "utility";

export const getAccountingSetting = () => {
  return APIclient.getData(`/ms/b2b-accounting/v1/setting/${GetOrgId()}/`);
};

export const updateAccountingSetting = (payload) => {
  return APIclient.putData(`/ms/b2b-accounting/v1/setting/${GetOrgId()}/bulk`, payload);
};

export const updateExpenseSyncSetting = (enabled) => {
  return APIclient.patchData(`/ms/b2b-accounting/v2/partners/connections`, { expense: enabled });
};

export const updateTopupSetting = (payload) => {
  return APIclient.patchData(`/ms/b2b-accounting/v2/partners/connections`, payload);
};

export const getXeroUrl = ({ bankFeed, expense }) => {
  const params = new URLSearchParams();
  params.set("organisation_id", GetOrgId());
  params.set("bankfeed", bankFeed);
  params.set("expense", expense);
  return APIclient.getData(`/api/v1/auth/xero/url?${params.toString()}`);
};

export const getLastSyncTime = () => {
  return APIclient.getData("/ms/b2b-accounting/v1/xero/sync-status");
};

export const syncTransactionsOnDemand = () => {
  return APIclient.postData("/ms/b2b-accounting/v1/xero/bank-feeds/sync");
};

export const getSingleAccountingSettingFromCode = (code) => {
  return APIclient.getData(`/ms/b2b-accounting/v1/setting/${GetOrgId()}/${code}`);
};

export const updateSingleAccountingSetting = (payload) => {
  return APIclient.putData(`/ms/b2b-accounting/v1/setting/${GetOrgId()}`, payload);
};

export const getUserDetailsFromUserId = (userId) => {
  return APIclient.getData(`/api/v1/user/info/${userId}/?organisation_id=${GetOrgId()}`);
};

export const getAccountingPartnerList = () => {
  return APIclient.getData("/ms/b2b-accounting/v2/partners");
};

export const getAccountingPartnerUrl = (id: number) => {
  return APIclient.postData(`/ms/b2b-accounting/v2/partners/${id}/integrations`);
};

export const getPartnerDataStatus = () => {
  return APIclient.getData("/ms/b2b-accounting/v2/partners/dataStatus");
};
