import * as React from "react";
import { Button } from "antd";
import { useCardDoesNotBelongToUser } from "customHooks/Card";
import { useCardDetailsEditContext } from "Views/Card/Edit/index.context";

import { blackTickIcon24x24 } from "assets/icons/card";

import styles from "Views/Card/Edit/Pin/index.module.scss";

interface ICardPin {
  onSetOrChangePin: () => void;
  disablePinChangeBtn: boolean;
}

export default function CardPin({ onSetOrChangePin, disablePinChangeBtn }: ICardPin) {
  const { cardData } = useCardDetailsEditContext();
  const { isCardPinSet, cardUserId } = cardData || {};

  if (useCardDoesNotBelongToUser(cardUserId)) return null;

  const getDisplayMessage = () => {
    if (isCardPinSet) {
      return (
        <>
          <img src={blackTickIcon24x24} width={24} height={24} alt="tick icon" className={styles["tick-icon"]} />
          PIN has been set.
        </>
      );
    }
    return (
      <>
        Enable offline transactions by
        <br /> setting a PIN for this card.
      </>
    );
  };

  return (
    <>
      <p className={styles["header"]}>PIN</p>
      <div className={styles["container"]}>
        <p className={styles["pin-info"]}>{getDisplayMessage()}</p>
        <Button className={styles["btn"]} onClick={onSetOrChangePin} disabled={disablePinChangeBtn}>
          {isCardPinSet ? "Change PIN" : "Set PIN"}
        </Button>
      </div>
    </>
  );
}
