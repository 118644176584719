import * as React from "react";
import { useEffect, useContext } from "react";
import { useSelector } from "react-redux";

import { IUpdateRoleReducer } from "Redux/Actions/Employee/Update/Role/types";

import ModalWrapper from "Modules/DS/Modal";

import { EmployeeModalTypes } from "Views/Employee/types";
import useModalType from "Views/Employee/Modal/useModalType";
import { EmployeesModalContext, IEmployeesModalContext } from "Views/Employee/context";

import "./index.scss";

const Modal = () => {
  const { showModal, toggleModal }: IEmployeesModalContext = useContext(EmployeesModalContext);

  const { visible } = showModal;

  const updateRoleDetails: IUpdateRoleReducer = useSelector((state: any) => state.updateRoleReducer);

  const updateRoleError: boolean = updateRoleDetails?.error || false;
  const updateRoleLoading: boolean = updateRoleDetails?.loading || false;

  useEffect(() => {
    if (updateRoleError || updateRoleLoading) toggleModal(EmployeeModalTypes.ROLE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateRoleError, updateRoleLoading]);

  const modalType = useModalType()[showModal.type];

  return (
    visible && (
      <ModalWrapper className={modalType.className} visible={visible} close={modalType.close}>
        {modalType.children}
      </ModalWrapper>
    )
  );
};

export default Modal;
