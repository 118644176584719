import React from "react";
import dayjs from "dayjs";
import { Typography } from "@spenmo/splice";
import { IColumnData } from "Modules/DS/DataTable";
import ShimmerEffect from "Views/Accounting/Workflow/ShimmerEffect";

import { AUDIT_LOG_COLUMNS, SUBSCRIPTION_AUDIT_TYPES } from "Views/SubscriptionManagement/Constants";
import { IAuditLog } from "Views/SubscriptionManagement/@types";
import { TRANSACTION_PATHS } from "Route/Transaction/paths";
import { capitalizeFirstLetter } from "utility";

import styles from "./index.module.scss";

const TRANSACTION_ACTIVITES = [
  SUBSCRIPTION_AUDIT_TYPES.TRANSACTION_ADDED,
  SUBSCRIPTION_AUDIT_TYPES.TRANSACTION_REMOVED,
];

const subscriptionAuditTypeVerbs = {
  [SUBSCRIPTION_AUDIT_TYPES.CREATED]: "created",
  [SUBSCRIPTION_AUDIT_TYPES.UPDATED]: "updated",
  [SUBSCRIPTION_AUDIT_TYPES.RENEWED]: "renewed",
  [SUBSCRIPTION_AUDIT_TYPES.REACTIVATED]: "reactivated",
  [SUBSCRIPTION_AUDIT_TYPES.DEACTIVATED]: "deactivated",
};

const getActionSummary = (record: IAuditLog, subscriptionName: string) => {
  const {
    change_type,
    additional_data: { new_expiry_date, transaction_number },
  } = record || {};
  if (TRANSACTION_ACTIVITES.includes(change_type)) {
    return (
      <>
        <a
          href={`${window?.location?.origin}${TRANSACTION_PATHS.TRANSACTIONS}?tab=all&transaction_number=${transaction_number}`}
          target="_blank"
          rel="noreferrer"
          className={styles.link}
        >
          <Typography variant="body-content" size="caption-m" weight={600}>
            Transaction
          </Typography>
        </a>
        {` ${
          change_type === SUBSCRIPTION_AUDIT_TYPES.TRANSACTION_ADDED ? "added to" : "removed from"
        } ${subscriptionName} subscription`}
      </>
    );
  } else if (change_type === SUBSCRIPTION_AUDIT_TYPES.RENEWED) {
    return `${subscriptionName} subscription renewed till ${dayjs(new_expiry_date).format("DD MMM YYYY")}`;
  } else {
    return `${subscriptionName} subscription ${subscriptionAuditTypeVerbs[change_type] || ""}`;
  }
};

const Columns: (subscriptionName: string, loading: boolean) => IColumnData<IAuditLog>[] = (
  subscriptionName,
  loading
) => {
  return [
    {
      id: "AUDIT-LOG-COL-1",
      enabled: true,
      column: {
        title: AUDIT_LOG_COLUMNS.ACTION,
        render: (record: IAuditLog) => (
          <ShimmerEffect loading={loading}>
            <Typography variant="body-content" size="caption-m" tag="p" className={styles.textBlack}>
              {getActionSummary(record, capitalizeFirstLetter(subscriptionName.toLowerCase()))}
            </Typography>
          </ShimmerEffect>
        ),
      },
    },
    {
      id: "AUDIT-LOG-COL-2",
      enabled: true,
      column: {
        title: AUDIT_LOG_COLUMNS.USER,
        render: ({ user: { name } }: IAuditLog) => (
          <ShimmerEffect loading={loading}>
            <Typography variant="body-content" size="caption-m" className={styles.userName}>
              {name}
            </Typography>
          </ShimmerEffect>
        ),
      },
    },
    {
      id: "AUDIT-LOG-COL-3",
      enabled: true,
      column: {
        title: AUDIT_LOG_COLUMNS.DATE_AND_TIME,
        render: ({ created_at }: IAuditLog) => (
          <ShimmerEffect loading={loading}>
            <Typography variant="body-content" size="caption-m" tag="p" className={styles.textBlack}>
              {dayjs(created_at).format("DD MMM YYYY")}
            </Typography>
            <Typography variant="body-content" size="caption-m" tag="p" className={styles.textGrey}>
              {dayjs(created_at).format("HH:mm")}
            </Typography>
          </ShimmerEffect>
        ),
      },
    },
  ].filter((item) => item.enabled);
};

export default Columns;
