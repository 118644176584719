import { GET_TRXN_REQUEST_PARAMS } from "Views/Transaction/@types";

export const trimPaginationAndListParams = <T>(payload: T) => {
  delete payload[GET_TRXN_REQUEST_PARAMS.LIMIT];
  delete payload[GET_TRXN_REQUEST_PARAMS.SEARCH_AFTER];
  delete payload[GET_TRXN_REQUEST_PARAMS.PREVIOUS_PAGE];
};

export * from "./Transaction.mocks";
export * from "./Transaction.api";
export * from "./Bills.api";
