import * as React from "react";

import { useCardDetailsContext } from "Views/Card/Details/index.context";
import { CardRequestInfoToRenderConfig } from "Views/Card/Details/Info/Config";

import { renderCardDetails } from "Views/Card/Details/Info/Utility";
import CardDetailsWithTabs from "Views/Card/Details/Info/Tabs";

import styles from "Views/Card/Details/Info/index.module.scss";
import { CARD_STATUS } from "Views/Card/types";

export default function CardDetailsInfo() {
  const { cardData } = useCardDetailsContext();
  const { isCardRequestDetails, isNotActivatedPhysicalCard, cardStatus } = cardData || {};
  if (isCardRequestDetails || isNotActivatedPhysicalCard || cardStatus === CARD_STATUS.IN_PROGRESS) {
    return <div className={styles["container"]}>{renderCardDetails(CardRequestInfoToRenderConfig(cardData))}</div>;
  }
  return <CardDetailsWithTabs />;
}
