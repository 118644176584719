import { toast } from "@spenmo/splice";
import { putData } from "API/Client";
import { ReconcileTransaction } from "Views/SubscriptionManagement/@types";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import useSWRMutation from "swr/mutation";
import { GetOrgId } from "utility";

export const useReconcileTransaction = () => {
  return useSWRMutation<string | null, any, string, ReconcileTransaction>(
    `/api/v1/expenses/subscriptions/auto-reconcile-approval/?organisation_id=${GetOrgId()}`,
    (url, { arg }) => {
      return putData(url, { ...arg })
        .then(({ data }) => {
          const { status } = data || {};
          if (status === HTTP_STATUS_CODE.OK) {
            toast.success(
              "You've reviewed these subscription transactions. They will no longer show up in the Action required section on your Subscription page.",
              {
                duration: 3000,
              }
            );
            return status;
          }
          throw new Error(data);
        })
        .catch((err) => {
          toast.danger("Please retry or contact us at support@spenmo.com if the issue persists.");
          throw new Error(err);
        });
    }
  );
};
