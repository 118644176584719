import { ILegalDocuments } from "Views/KYX/@types";
import { CLIENT_AGREEMENT_TYPE } from "./ClientAgreements";

const SG: ILegalDocuments = {
  title: "Update on Spenmo Client Agreement",
  description:
    "We have updated our Client Agreement. Please take a look and see what has changed. Afterward, simply tick the box below and you’re done.",
  list: [
    "Terms of Use",
    "Privacy Policy",
    "Client Services Agreement",
    "Payment Service Partners",
    "Cash Rebate Programme",
  ].map((name) => ({
    name,
    link: "https://spenmo.com/legal",
  })),
  confirmationMessage: "I have read and agreed to Spenmo Client Agreement",
  buttonTitle: "Done",
};

const ID: ILegalDocuments = {
  title: "Pembaruan Syarat dan Ketentuan Penggunaan Spenmo",
  description:
    "Kami telah memperbarui Syarat dan Ketentuan Penggunaan Spenmo. Mohon cermati pembaruan tersebut dengan membaca dokumen-dokumen berikut.",
  list: ["Persyaratan Penggunaan (Terms of Use)", "Pernyataan Privasi (Privacy Policy)"].map((name) => ({
    name,
    link: "https://spenmo.id/legal",
  })),
  confirmationMessage: "Saya telah membaca dan menyetujui Syarat dan Ketentuan Penggunaan Spenmo",
  buttonTitle: "Setuju",
};

export const UpdateClientAgreementData: {
  [CLIENT_AGREEMENT_TYPE.SG]: ILegalDocuments;
  [CLIENT_AGREEMENT_TYPE.ID]: ILegalDocuments;
  [CLIENT_AGREEMENT_TYPE.DEFAULT]: ILegalDocuments;
} = {
  [CLIENT_AGREEMENT_TYPE.SG]: SG,
  [CLIENT_AGREEMENT_TYPE.ID]: ID,
  [CLIENT_AGREEMENT_TYPE.DEFAULT]: SG,
};
