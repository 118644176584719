import {
  FETCH_STEPS_REQUEST,
  FETCH_STEPS_SUCCESS,
  FETCH_STEPS_FAILURE,
} from "../../Actions/OnBoarding/stepsStatusAction";
import { userInitialState } from "../../InitialState";

export const stepsStatusReducer = (state = userInitialState, action) => {
  switch (action.type) {
    case FETCH_STEPS_REQUEST:
      return {
        ...state,
        loading: true,
        data: action.data,
        error: false,
      };
    case FETCH_STEPS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: false,
      };
    case FETCH_STEPS_FAILURE:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: true,
      };
    default:
      return state;
  }
};
