import APIclient from "API/Client";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import { IPreliminaryData } from "./types";
import { setCookie, cookieNames } from "utility/CookieHelper";

export enum PRELIMINARY_ACTION_TYPES {
  REQUEST = "GET_PRELIMINARY_REQUEST",
  SUCCESS = "GET_PRELIMINARY_SUCCESS",
  ERROR = "GET_PRELIMINARY_ERROR",
}

const fetchDataRequest = (data?: any) => {
  return {
    type: PRELIMINARY_ACTION_TYPES.REQUEST,
    data,
  };
};

const fetchDataSuccess = (data: IPreliminaryData) => {
  return {
    type: PRELIMINARY_ACTION_TYPES.SUCCESS,
    data,
  };
};

const fetchDataError = (data: IPreliminaryData) => {
  return {
    type: PRELIMINARY_ACTION_TYPES.ERROR,
    data,
  };
};

export const getPreliminaryData = () => {
  return (dispatch) => {
    dispatch(fetchDataRequest());

    APIclient.getData("api/v2/preliminary-data")
      .then((response) => {
        const { status, payload = {} } = response?.data || {};
        if (status === HTTP_STATUS_CODE.OK) {
          const {
            phone_code,
            mobile_number,
            org_id,
            org_country_code,
            user_id,
            is_use_credit,
            currency_code = "",
            role = "",
            card_vendor = "",
            user_email = "",
            is_onboarding_finished = true,
            registration_date = "",
            kyb_status = "",
            onboarding_version = "",
            is_kyb_on_demand = false,
          } = payload || {};
          setCookie(cookieNames.USER_MOBILE_NUMBER, mobile_number);
          setCookie(cookieNames.PHONE_CODE, phone_code);
          setCookie(cookieNames.USER_EMAIL, user_email);
          setCookie(cookieNames.IS_USE_CREDIT, is_use_credit);
          setCookie(cookieNames.USER_CARD_VENDOR, card_vendor);
          setCookie(cookieNames.USER_COMPANY_ROLE, role);
          setCookie(cookieNames.CURRENCY_CODE, currency_code);
          setCookie(cookieNames.IS_ONBOARDING_FINISHED, is_onboarding_finished);
          setCookie(cookieNames.ONBOARDING_VERSION, onboarding_version);
          setCookie(cookieNames.KYB_STATUS, kyb_status);
          setCookie(cookieNames.REAUTH, org_id + "vspenmo1" + user_id + "vspenmo1" + org_country_code);
          setCookie(cookieNames.ORG_REGISTRATION_DATE, registration_date);
          setCookie(cookieNames.IS_KYB_ON_DEMAND, is_kyb_on_demand);

          dispatch(fetchDataSuccess(response.data));
        } else {
          dispatch(fetchDataError(response.data));
        }
      })
      .catch((error) => {
        dispatch(fetchDataError(error?.response?.data));
      });
  };
};
