import useSWR, { SWRConfiguration } from "swr";
import { AxiosResponse } from "axios";

import { getData } from "API/Client";

import { getCookieValue, cookieNames } from "utility/CookieHelper";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";

import { appNotification, contactCSErrorMessage } from "Modules/appNotification/appNotification";

import { API_URL } from "Views/Reimbursement/DataCalls/Services";
import { IReimbursementConfig, IUseFetchReimbursementConfig } from "Views/Reimbursement/@types";

const useConfig = (): IUseFetchReimbursementConfig => {
  const keys: [string, string] = [API_URL.CONFIG, getCookieValue(cookieNames.AUTH_TOKEN)];

  const config: SWRConfiguration<AxiosResponse<IReimbursementConfig>> = {
    revalidateOnFocus: false,
    onSuccess: (response) => {
      if (response.data.status !== HTTP_STATUS_CODE.OK) {
        throw new Error(`${response.data.status}`);
      }
    },
    onError: (err) => {
      appNotification.error(contactCSErrorMessage(err.message));
      throw new Error(err.message);
    },
  };

  const { data, isLoading, isValidating, error } = useSWR<AxiosResponse<IReimbursementConfig>>(
    keys,
    ([url]) => getData(url),
    config
  );

  return {
    data: data?.data?.payload || { filterStatuses: [], isPaymentEnabled: false },
    showSkeleton: isLoading || isValidating,
    showErrorState: (error?.message as string)?.length > 0 || false,
  };
};

export default useConfig;
