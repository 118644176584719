import React from "react";
import PropTypes from "prop-types";
import { Tabs } from "antd";

import { CURRENCY_CODE } from "constants/Currency.constant";

import AddBankForm from "./AddBankForm";
import "./AddBank.scss";
import { trackEvent } from "utility/analytics";

class AddBank extends React.Component {
  static propTypes = {
    toggleAddBank: PropTypes.func,
    orgCurrencyCode: PropTypes.string,
    showPayout: PropTypes.bool,
  };

  state = {
    activeView: "mobile",
  };

  trackTabShownAndChangeEvent = (view) => {
    trackEvent(`add bank account ${view === "mobile" ? "paynow" : "bank account"} tab viewed`);
  };

  componentDidMount() {
    const { orgCurrencyCode } = this.props;
    const activeview = orgCurrencyCode === CURRENCY_CODE.SGD ? "mobile" : "bank";
    this.setState({ activeView: activeview });
    this.trackTabShownAndChangeEvent(activeview);
  }

  onTabChange = (key) => {
    this.setState({ activeView: key });
    this.trackTabShownAndChangeEvent(key);
  };

  render() {
    const { TabPane } = Tabs;
    const { orgCurrencyCode } = this.props;

    return (
      <div className="add-bank">
        <h2 className={"add-bank--title"}>Add bank account</h2>
        <div className="add-bank__container">
          <Tabs defaultActiveKey={"mobile"} onChange={this.onTabChange}>
            {orgCurrencyCode === CURRENCY_CODE.SGD && <TabPane tab={"PayNow (Mobile)"} key={"mobile"} />}
            <TabPane tab={"Bank Account"} key={"bank"} />
          </Tabs>

          <AddBankForm teamCurrency={orgCurrencyCode} view={this.state.activeView} cancel={this.props.toggleAddBank} />
        </div>
      </div>
    );
  }
}

export default AddBank;
