import React from "react";
import { Navigation } from "@spenmo/splice";

import styles from "./index.module.scss";

const Footer: React.FC = (): React.ReactElement => {
  return <Navigation.Footer className={styles.footer} />;
};

export default Footer;
