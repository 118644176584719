import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Icon from "../icons";
import { Input } from "antd";
import { search, circleClose } from "../../../src/assets/img/";

import "./search.scss";

const Search = ({
  getValue = () => {},
  className,
  placeholder,
  enterAction,
  changeAction,
  onChangeDelay = 0,
  iconPosition = "left",
  clearSearch = false,
  getKeyUpValue = () => {},
  value: searchValue,
}) => {
  const [value, setValue] = useState(searchValue || "");
  const isMounted = useRef(false);

  useEffect(() => {
    setValue(searchValue || "");
  }, [searchValue]);

  const setInputValue = (val) => {
    setValue(val);
    getValue(val);
    getKeyUpValue(val);
  };

  useEffect(() => {
    let delayDebounceFn;
    if (isMounted?.current) {
      delayDebounceFn = setTimeout(() => {
        changeAction && changeAction(value);
      }, onChangeDelay);
    } else {
      isMounted.current = true;
    }

    return () => clearTimeout(delayDebounceFn);
  }, [value]);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      enterAction && enterAction(value);
    }
  };

  const handleValueChange = (value) => {
    setInputValue(value);
  };

  useEffect(() => {
    if (clearSearch) handleValueChange("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearSearch]);

  const icon = <Icon className="search__icon" src={search} alt="search" />;
  const searchClassName = classNames("search", className);

  return (
    <div className={searchClassName}>
      {iconPosition === "left" && icon}
      <Input
        value={value}
        aria-label="search-input"
        onChange={(e) => handleValueChange(e.target.value)}
        onKeyDown={handleKeyDown}
        onKeyUp={(e) => getKeyUpValue(e.target.value)}
        className={"search__input-field"}
        placeholder={placeholder || "Search"}
      />
      {value !== "" && (
        <Icon className="search__close" action={() => handleValueChange("")} src={circleClose} alt="circle close" />
      )}
      {iconPosition === "right" && icon}
    </div>
  );
};

Search.propTypes = {
  enterAction: PropTypes.func,
  changeAction: PropTypes.func,
  getValue: PropTypes.func,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  onChangeDelay: PropTypes.number,
  iconPosition: PropTypes.string,
  clearSearch: PropTypes.bool,
  getKeyUpValue: PropTypes.func,
};

export default Search;
