import { Button, Popover, Spin } from "antd";
import React, { useState } from "react";
import { threeDots } from "assets/img";
import classNames from "classnames";
import { PrimaryOrSecondary } from "../../../types";
import { LoadingOutlined } from "@ant-design/icons";
import styles from "./actionWithMoreMenu.module.scss";

interface ActionWithMoreMenuProps {
  button?: PrimaryOrSecondary;
  secondButton?: PrimaryOrSecondary;
  moreActionMenu: JSX.Element;
  showMoreActionMenu: boolean;
  loadingMoreactionMenu: boolean;
  setShowMoreActionMenu: React.Dispatch<React.SetStateAction<boolean>>;
  size: string;
}

export const ActionWithMoreMenu: React.FC<ActionWithMoreMenuProps> = ({
  button,
  moreActionMenu,
  secondButton,
  showMoreActionMenu,
  setShowMoreActionMenu,
  loadingMoreactionMenu,
  size,
}: ActionWithMoreMenuProps): JSX.Element => {
  const loadingSpinner = <LoadingOutlined spin />;
  const [isSubmit, setIsSubmit] = useState(false);

  const handleButton = async (selectedButton) => {
    setIsSubmit(true);
    const response = await selectedButton.handler();
    if (response) {
      setIsSubmit(false);
    }
  };

  return (
    <div className={styles["action-with-more-menu"]}>
      {button &&
        (button.customButton || (
          <Button
            className={styles[`action-with-more-menu__button--${button.type}`]}
            onClick={(e) => {
              e.stopPropagation();
              handleButton(button);
            }}
          >
            {isSubmit ? <Spin indicator={loadingSpinner} /> : `${button.label}`}
          </Button>
        ))}

      {secondButton &&
        (secondButton.customButton || (
          <Button
            className={styles[`action-with-more-menu__button--${secondButton.type}`]}
            onClick={(e) => {
              e.stopPropagation();
              handleButton(secondButton);
            }}
          >
            {isSubmit ? <Spin indicator={loadingSpinner} /> : `${secondButton.label}`}
          </Button>
        ))}
      <Popover
        visible={showMoreActionMenu}
        onVisibleChange={() => setShowMoreActionMenu(!showMoreActionMenu)}
        overlayClassName={classNames(
          styles["action-with-more-menu__target-container"],
          styles[`action-with-more-menu__target-container__${size}`]
        )}
        trigger="click"
        getPopupContainer={(triggerNode) => triggerNode.parentElement}
        align={{
          points: ["tr", "tr"],
          offset: [24, 0],
          targetOffset: [0, 50],
        }}
        content={moreActionMenu}
      >
        <div
          className={classNames(styles["action-with-more-menu__source-icon"], {
            [styles["action-with-more-menu__active-icon"]]: showMoreActionMenu || loadingMoreactionMenu,
          })}
          onClick={(e) => e.stopPropagation()}
        >
          {loadingMoreactionMenu ? <Spin indicator={loadingSpinner} /> : <img src={threeDots} alt="more actions" />}
        </div>
      </Popover>
    </div>
  );
};
