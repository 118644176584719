import APIclient from "API/Client";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import { GetOrgId } from "utility";
import { getTeamDatilsById } from "Redux/Actions/Teams/teamDetailsAction";

export const UPDATE_TEAM = "UPDATE_TEAM";
export const UPDATE_TEAM_REQUEST = `${UPDATE_TEAM}_REQUEST`;
export const UPDATE_TEAM_SUCCESS = `${UPDATE_TEAM}_SUCCESS`;
export const UPDATE_TEAM_FAILURE = `${UPDATE_TEAM}_FAILURE`;

export const updateTeamRequest = (data) => {
  return {
    type: UPDATE_TEAM_REQUEST,
    data: data,
  };
};

export const updateTeamSuccess = (data) => {
  return {
    type: UPDATE_TEAM_SUCCESS,
    data: data,
  };
};

export const updateTeamError = (data) => {
  return {
    type: UPDATE_TEAM_FAILURE,
    data: data,
  };
};
// update team list with already selected team
export const updateTeam = () => {
  return (dispatch, getState) => {
    APIclient.getData(`/api/v1/org/${GetOrgId()}/team-list?pg=0&limit=100&type=all&with_members=1`)
      .then((data) => {
        if (data.data.status === HTTP_STATUS_CODE.OK) {
          dispatch(updateTeamSuccess(data.data));
          const teamId = getState().teamReducer.selectedTeamId;
          dispatch(getTeamDatilsById(teamId));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
