import React, { useState, useEffect, useRef } from "react";
import { Popover } from "antd";
import { Button } from "@spenmo/splice";
import { useDispatch, useSelector } from "react-redux";
import { useAppSelector } from "hook";
import { Toaster } from "Modules/toaster";
import { trackEvent } from "utility/analytics";
import { TransactionCSVFunc, DownloadBankStatementFunc } from "Redux/Actions";
import ModaldownloadBankStatement from "./components/ModalDownloadBankStatement";
import ModalSmartCSV from "./components/ModalSmartCSV";
import DownloadRawCSVButton from "./components/DownloadRawCSV";
import DownloadBankStatementButton from "./components/DownloadBankStatement";
import DownloadCustomCSVButton from "./components/DownloadCustomCSV";
import { messageGenerator } from "./utilities";
import { useAccountingTransaction } from "Views/Transaction/Context";
import { useCountFetch } from "Views/Transaction/All/useFetch";
import { thisMonthDateRange } from "Views/Transaction/SmartCSV/v2/utilities";
import { normalizeFilter } from "Views/Accounting/Workflow/Download/helper";
import "./styles.scss";
import { fetchDataError } from "Redux/Actions/csvAction";

const SmartCSV = (props) => {
  const { activePageDetails } = useAccountingTransaction();

  const {
    filters = {},
    view,
    subTab,
    isSideSectionOpen,
    onlyRawCsv = false,
    allTransactionIds,
    accountingStatus,
    showInternalToaster = true,
    trxnCount = 0,
  } = props;
  const [visible, setVisible] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showExportCSVModal, setShowExportCSVModal] = useState(false);
  const { count: bankStmtCount, fetchCount } = useCountFetch();
  const [email, setEmail] = useState("");
  const [showToaster, setShowToaster] = useState(false);
  const { csvReducer, userData, accountingPartnerAuth } = useSelector((state) => ({
    csvReducer: state.csvReducer,
    userData: state.userInfoReducer?.data,
    accountingPartnerAuth: state.accountingPartnerAuthReducer.data,
  }));
  const categoriesData = useAppSelector((state) => state.userInfoReducer?.data?.payload?.categories || []);
  const popupContainerRef = useRef();
  const popupButtonRef = useRef();

  const { message, error, loading } = csvReducer;

  const dispatch = useDispatch();
  const MAXIMUM_COUNT_TRESHOLD = 500;
  const generator = messageGenerator({ filters });

  const noFilters = Object.values(filters).filter(Boolean).length === 0;

  const togglePopover = () => setVisible(!visible);

  // to hide the toaster when we switch between tabs in Accounting page
  useEffect(() => {
    view === "accounting" && setShowToaster(false);
  }, [activePageDetails?.name]);

  const checkSize = () => {
    if (bankStmtCount > MAXIMUM_COUNT_TRESHOLD) {
      setShowModal(true);
    } else {
      dispatch(DownloadBankStatementFunc(filters));
      togglePopover();
      setShowToaster(true);
    }
  };

  const getBankStmtTransactionsCount = async (searchFilters) => {
    await fetchCount({ ...thisMonthDateRange(), ...searchFilters });
  };

  useEffect(() => {
    const isDateFilterApplied = filters.start_date && filters.end_date;
    if (!onlyRawCsv && trxnCount > 0) {
      getBankStmtTransactionsCount(isDateFilterApplied ? { ...filters } : { ...thisMonthDateRange(), ...filters });
    }
  }, [onlyRawCsv, filters, trxnCount]);

  useEffect(() => {
    if (userData?.payload?.user) {
      const { user } = userData.payload;
      setEmail(user.email);
    }
  }, [userData]);

  useEffect(() => {
    const clickOutside = (event) => {
      const { popupRef } = popupContainerRef.current || {};
      const popupElement = popupRef?.current?.getElement();

      if (
        popupElement &&
        !(
          popupElement === event.target ||
          popupElement.contains(event.target) ||
          popupButtonRef.current === event.target ||
          popupButtonRef.current.contains(event.target)
        )
      ) {
        setVisible(false);
      }
    };

    if (visible) {
      document.addEventListener("click", clickOutside);
    }

    return () => {
      document.removeEventListener("click", clickOutside);
    };
  }, [visible]);

  const showNoTrxnToExportError = () => {
    const status_message = "Download cannot be started as there are no rows found in your current view";
    setShowToaster(true);
    dispatch(fetchDataError({ status_message }));
  };

  const bankStmtExportClickHandler = () => {
    if (bankStmtCount > 0) {
      checkSize();
      trackEvent("Transaction Downloaded", { transaction_download_event_action: "Bank Statement" });
      togglePopover();
    } else {
      showNoTrxnToExportError();
    }
  };

  const rawCSVExportClickHandler = () => {
    if (trxnCount > 0) {
      dispatch(TransactionCSVFunc(filters, view, subTab));
      trackEvent("Transaction Downloaded", { transaction_download_event_action: "CSV" });
      togglePopover();
      setShowToaster(true);
    } else {
      showNoTrxnToExportError();
    }
  };

  const customCSVExportClickHandler = () => {
    if (trxnCount > 0) {
      trackEvent("Custom CSV Download Clicked");
      setShowExportCSVModal(true);
      togglePopover();
    } else {
      showNoTrxnToExportError();
    }
  };

  const exportCSVContent = (
    <div className="button-group">
      {!onlyRawCsv && <DownloadBankStatementButton action={bankStmtExportClickHandler} />}
      <DownloadRawCSVButton action={rawCSVExportClickHandler} />
      {!onlyRawCsv && <DownloadCustomCSVButton action={customCSVExportClickHandler} />}
    </div>
  );

  return (
    <>
      <Popover
        className="transaction-export-smart-csv"
        visible={visible}
        overlayClassName="smart-csv"
        placement="bottomRight"
        overlayStyle={{
          width: "30vw",
        }}
        ref={popupContainerRef}
        content={
          <div className="transaction-export-smart-csv__content">
            <div className="summary">{generator.generatePopupMessage()}</div>
            {exportCSVContent}
          </div>
        }
      >
        <Button
          size="s"
          variant="primary"
          loading={loading}
          onClick={togglePopover}
          ref={popupButtonRef}
          data-testid="ExportCSV"
        >
          Export
        </Button>
      </Popover>

      {showInternalToaster && (
        <Toaster
          visible={message && showToaster && !loading}
          message={message}
          primary={isSideSectionOpen}
          status={error ? "error" : "success"}
          closeFunc={() => setShowToaster(false)}
        />
      )}

      {!onlyRawCsv && (
        <ModaldownloadBankStatement
          email={email}
          setEmail={setEmail}
          filters={filters}
          setShowToaster={setShowToaster}
          showModal={showModal}
          setShowModal={setShowModal}
          allTransactionIds={allTransactionIds}
        />
      )}
      {!onlyRawCsv && (
        <ModalSmartCSV
          showModal={showExportCSVModal}
          setShowModal={setShowExportCSVModal}
          email={email}
          count={trxnCount}
          filters={normalizeFilter(filters, categoriesData)}
          reportingFilters={filters}
          noFilters={noFilters}
          isConnected={accountingPartnerAuth?.payload?.has_valid_token}
          setShowToaster={setShowToaster}
          accountingStatus={accountingStatus}
        />
      )}
    </>
  );
};

export default SmartCSV;
