import React, { SetStateAction, useEffect, useMemo, useState } from "react";
import { Select } from "antd";
import { Button, Modal, Typography } from "@spenmo/splice";
import { useLocation, useHistory } from "react-router-dom";
import { Controller, FormProvider, SubmitHandler, useForm } from "react-hook-form";
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";

import { useMutableData } from "API/useData";
import { postData, putData } from "API/Client";

import { appNotification } from "Modules/appNotification/appNotification";
import { GetOrgId, GetUserId } from "utility";
import FormItem from "./FormItem";
import FrequencyDate from "./FrequencyDate";
import { trackEvent } from "utility/analytics";

import { API_URL, DEFAULT_ERROR_MESSAGE, PAYMENT_RUN_EVENT_NAME } from "Views/Settings/constants";
import { getDisableWording, getEditWording, getRunOnValue } from "./helper";
import { FrequencyType, PaymentRunFields, PaymentRunModal } from "Views/Settings/@types";

import styles from "./PaymentRun.module.scss";

dayjs.extend(weekday);

const PaymentRun = () => {
  const form = useForm<PaymentRunFields>({
    mode: "onTouched",
    reValidateMode: "onChange",
  });
  const {
    control,
    watch,
    setValue,
    handleSubmit,
    reset,
    formState: { isValid },
  } = form;

  const { search } = useLocation();
  const history = useHistory();
  const query = new URLSearchParams(search);
  // to open up the form fields
  const isEdit = query.get("edit") === "true";

  const [submitLoading, setSubmitLoading] = useState(false);
  const [disablingLoading, setDisablingLoading] = useState(false);
  const [modalProps, setModalProps] = useState<PaymentRunModal | undefined>();

  const { frequency, isEOM, runOn } = watch();

  const { data: globalConfig } = useMutableData(API_URL.globalConfig);
  const globalConfigData = useMemo(() => globalConfig?.data?.payload || {}, [globalConfig?.data?.payload]);
  const [formItem] = globalConfigData?.contents || [];

  // if not exists -> show the Enable Button -> upon clicking the button, show the form without the disable button
  // if exists -> show the disable Button immediately with the form open
  const { data: getSetting, mutate: mutateGetSetting, isLoading } = useMutableData(API_URL.getSetting);
  const getSettingData = useMemo(() => getSetting?.data?.payload?.setting, [getSetting?.data?.payload?.setting]);

  const isEditForm = Boolean(getSettingData);
  const showEnableDisableBtn = !isEdit || getSettingData;
  const showEnableBtn = !isEdit && !getSettingData;
  const showForm = isEdit || getSettingData;
  // check whether the form data is the same with the getSettingData
  // note: cannot use `isDirty` from the form since the Moment is not
  // detected to be change
  const validateRunOn = useMemo(() => {
    const defaultRunOn = getSettingData?.runOn;
    if (defaultRunOn && runOn) {
      switch (typeof runOn) {
        case "object": {
          if (frequency === FrequencyType.FORTNIGHTLY) {
            return defaultRunOn === runOn.format("YYYY-MM-DD");
          }
          return defaultRunOn?.split("-")[2] === runOn.format("DD");
        }
        case "string": {
          return defaultRunOn === runOn;
        }
      }
    }

    return false;
  }, [getSettingData?.runOn, runOn, frequency]);
  const isClean =
    getSettingData?.frequency === frequency && getSettingData?.isEOM === isEOM && (isEOM || validateRunOn);

  const eventProperties = useMemo(
    () => ({
      organisation_id: GetOrgId(),
      user_id: GetUserId(),
      is_new_flow: !isEditForm, // true on empty data
    }),
    [isEditForm]
  );

  useEffect(() => {
    if (!isLoading) {
      trackEvent(PAYMENT_RUN_EVENT_NAME.pageLoaded, eventProperties);
    }
  }, [isLoading]);

  const resetFormFields = () => {
    if (getSettingData) {
      const { frequency, runOn, isEOM } = getSettingData;

      reset({
        frequency,
        runOn: isEOM ? undefined : getRunOnValue({ frequency, runOn }, true),
        isEOM,
      });
    } else {
      reset({});
    }
  };

  // the same as calling it on `onSuccess` from `options` arg in the useMutableData
  useEffect(() => {
    resetFormFields();
  }, [getSettingData]);

  const fetchAffectedBills = async (
    action: "edit" | "disable" = "edit",
    setLoading: (loading: SetStateAction<Boolean>) => void,
    openModal,
    fetcher
  ) => {
    const effectedBillsCount = await postData(API_URL.previewSetting, {
      action,
      frequency,
      runOn: runOn ? getRunOnValue({ frequency, runOn }) : undefined,
      isEOM,
    })
      .then((res) => {
        return res.data.payload.effectedBillsCount;
      })
      .catch((err) => {
        if (err?.response?.data?.error) {
          const { message } = err.response.data.error;

          appNotification.error({
            message: message || DEFAULT_ERROR_MESSAGE,
          });
        }
      });

    // call the loading callback fn to false
    if (effectedBillsCount === undefined || effectedBillsCount > 0) {
      setLoading(false);

      // on API error
      if (effectedBillsCount === undefined) {
        appNotification.error({
          message: DEFAULT_ERROR_MESSAGE,
        });
      }

      if (effectedBillsCount > 0) {
        openModal(effectedBillsCount);
      }
      return;
    }

    fetcher();
  };

  /**
   * to toggle enable/disable
   * when click the enable: it will show the form
   * when click the disable: it will hide the form
   */
  const handleClickFormButtons = (isEnable?: boolean) => {
    // reset form if user click enable
    if (isEnable) {
      history.push({
        search: "?edit=true",
      });

      resetFormFields();
    } else {
      setDisablingLoading(true);
      const fetchDisableSetting = () =>
        putData(API_URL.disableSetting)
          .then((res) => {
            const { payload, error } = res.data;

            if (error) {
              const { message } = error;

              appNotification.error({
                message,
                cta: "Contact us",
                onClickCTA: () => {
                  const anchorElement = document.createElement("a");
                  anchorElement.href = "mailto:support@spenmo.com";
                  anchorElement.click();
                  anchorElement.remove();
                },
              });

              return;
            }

            const { isSuccess, message } = payload;

            if (isSuccess) {
              appNotification.success({
                message,
              });

              mutateGetSetting();

              history.push({
                search: "",
              });
            }
          })
          .catch((err) => {
            if (err?.response?.data?.error) {
              const { message } = err.response.data.error;

              appNotification.error({
                message: message || DEFAULT_ERROR_MESSAGE,
              });
            }
          })
          .finally(() => {
            setDisablingLoading(false);
          });

      // note: will call the loading callback to false on undefined / greater than 0
      fetchAffectedBills(
        "disable",
        setDisablingLoading,
        (effectedBillsCount: number) =>
          setModalProps({
            title: "Disabling Payment Run",
            content: getDisableWording(effectedBillsCount),
            fetcher: fetchDisableSetting,
          }),
        fetchDisableSetting
      );
    }
  };

  const handleClickDiscard = () => {
    resetFormFields();
    history.push({
      search: "",
    });
  };

  const handleCloseModal = () => setModalProps(undefined);

  // handler for changing / disabling the payment run
  const handleConfirmPaymentRun = (fetcherFn: Function) => {
    fetcherFn().then(() => {
      handleCloseModal();
    });
  };

  const handleSubmitForm: SubmitHandler<{}> = (data: PaymentRunFields) => {
    setSubmitLoading(true);
    const payload = {
      ...data,
      runOn: data.runOn ? getRunOnValue(data) : undefined,
    };

    trackEvent(PAYMENT_RUN_EVENT_NAME.saveBtnClicked, eventProperties);

    const fetchCreateSetting = () =>
      postData(API_URL.createSetting, payload)
        .then((res) => {
          const { error, payload } = res.data;

          if (error) {
            const { message } = error;

            // notification message error if error
            appNotification.error({
              message:
                message || "Payment run could not be set up. You can try again or contact us if the issue persists.",
              cta: "Contact us",
              onClickCTA: () => {
                const anchorElement = document.createElement("a");
                anchorElement.href = "mailto:support@spenmo.com";
                anchorElement.click();
                anchorElement.remove();
              },
            });
            return;
          }

          if (payload) {
            const { frequency, startOn } = payload;

            let message = `You have successfully set up a ${frequency} Payment run `;

            switch (frequency) {
              case FrequencyType.WEEKLY: {
                message += `on ${dayjs(startOn, "YYYY-MM-DD").format("dddd")}s`;
                break;
              }
              case FrequencyType.FORTNIGHTLY: {
                message += `starting ${dayjs(startOn, "YYYY-MM-DD").format("Do MMM YYYY")}`;
                break;
              }
              case FrequencyType.MONTHLY: {
                message += `on ${dayjs(startOn, "YYYY-MM-DD").format("Do")}`;
                break;
              }
            }

            trackEvent(PAYMENT_RUN_EVENT_NAME.saveBtnProcessed, eventProperties);
            // notification message success for weekly, fortnightly, and monthly
            appNotification.success({
              message,
              cta: "View Payment Run",
              onClickCTA: () => {
                history.push("/bills/payment-run");
              },
            });

            mutateGetSetting();
          }
        })
        .catch((err) => {
          if (err?.response?.data?.error) {
            const { message } = err.response.data.error;

            appNotification.error({
              message: message || DEFAULT_ERROR_MESSAGE,
            });
          }
        })
        .finally(() => {
          setSubmitLoading(false);
        });

    if (isEditForm) {
      // note: will call the loading callback to false on undefined / greater than 0
      fetchAffectedBills(
        "edit",
        setSubmitLoading,
        (effectedBillsCount: number) =>
          setModalProps({
            title: "Updating Payment Run",
            content: getEditWording(effectedBillsCount),
            fetcher: fetchCreateSetting,
          }),
        fetchCreateSetting
      );
    } else {
      fetchCreateSetting();
    }
  };

  return (
    <div>
      <div className={styles.title}>
        <Typography variant="headline-content" tag="h2" size="m">
          {globalConfigData.title}
        </Typography>
        {showEnableDisableBtn && (
          <Button
            variant={showEnableBtn ? "success" : "danger"}
            size="s"
            onClick={() => handleClickFormButtons(showEnableBtn)}
            loading={disablingLoading}
            disabled={submitLoading}
          >
            {showEnableBtn ? "Enable" : "Disable"}
          </Button>
        )}
      </div>
      <Typography variant="body-content" tag="p" size="s">
        {globalConfigData.description}
      </Typography>
      {showForm && (
        <FormProvider {...form}>
          <form className={styles.form} onSubmit={handleSubmit(handleSubmitForm)}>
            <FormItem label={formItem?.label} htmlFor="frequency">
              <Controller
                name="frequency"
                rules={{ required: true }}
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    className={styles.field}
                    id="frequency"
                    getPopupContainer={(triggerNode) => triggerNode.parentElement}
                    options={formItem?.options || []}
                    placeholder="Select a frequency"
                    onChange={(...args) => {
                      setValue("runOn", "");
                      setValue("isEOM", false);
                      field.onChange(...args);
                    }}
                  />
                )}
              />
            </FormItem>
            <FrequencyDate />
            <div className={styles.formFooter}>
              {!isClean && (
                <Button
                  size="s"
                  variant="secondary"
                  type="button"
                  onClick={handleClickDiscard}
                  disabled={submitLoading || disablingLoading}
                >
                  Discard
                </Button>
              )}
              <Button
                size="s"
                variant="primary"
                type="submit"
                disabled={!isValid || isClean || disablingLoading}
                loading={submitLoading}
              >
                Save Changes
              </Button>
            </div>
          </form>
        </FormProvider>
      )}
      <Modal
        showModal={Boolean(modalProps)}
        size="m"
        title={modalProps?.title}
        primaryActionButton={{
          title: "Proceed",
          action: () => handleConfirmPaymentRun(modalProps?.fetcher),
        }}
        secondaryActionButton={{
          title: "Go back",
          action: handleCloseModal,
        }}
      >
        {modalProps?.content}
      </Modal>
    </div>
  );
};

export default PaymentRun;
