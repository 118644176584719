import React from "react";
import Modal, { ModalComponent } from "Modules/DS/Modal";
import { TITLE_TYPE } from "Modules/DS/Modal/types";
import Icon from "Modules/icons";
import { greyCloseIcon } from "assets/img";
import { discardModalIcon } from "assets/v1.1/icons/Flat";
import Button from "Modules/button";

interface DiscardChangesModalInterface {
  setShowConfirmSelfApprovalCloseModal: (val: boolean) => void;
  showConfirmSelfApprovalCloseModal: boolean;
  discardChangesModal: () => void;
  savePolicy: () => void;
  isDiscardButtonClicked: boolean;
}

const DiscardChangesModal = ({
  setShowConfirmSelfApprovalCloseModal,
  showConfirmSelfApprovalCloseModal,
  discardChangesModal,
  savePolicy,
  isDiscardButtonClicked,
}: DiscardChangesModalInterface): JSX.Element => {
  return (
    <Modal
      close={() => setShowConfirmSelfApprovalCloseModal(false)}
      visible={showConfirmSelfApprovalCloseModal}
      className="self-approval__discard-confirm-modal"
    >
      <ModalComponent.CloseButton fixToTop src={greyCloseIcon} />
      <ModalComponent.Title titleType={TITLE_TYPE.withBorder}>
        {isDiscardButtonClicked ? "Discard changes" : "Save changes to policy?"}
      </ModalComponent.Title>

      <section>
        <Icon className="self-approval__illustration" src={discardModalIcon} alt="discard_changes" />
        <div className="self-approval__discard-confirm-modal-text">
          <h3>All unsaved changes will be lost</h3>
          <p>
            Changes have been made to the Self Approval Policy. Leaving the page without saving will permanently lose
            all changes.
          </p>
        </div>
      </section>

      {isDiscardButtonClicked ? (
        <div className="self-approval__discard-save-modal-container">
          <Button action={() => setShowConfirmSelfApprovalCloseModal(false)} className="self-approval__discard-modal">
            Cancel
          </Button>
          <Button action={discardChangesModal} className="self-approval__save">
            Discard Changes
          </Button>
        </div>
      ) : (
        <div className="self-approval__discard-save-modal-container">
          <Button action={discardChangesModal} className="self-approval__discard-modal">
            Discard Changes
          </Button>
          <Button action={savePolicy} className="self-approval__save">
            Save & Exit
          </Button>
        </div>
      )}
    </Modal>
  );
};

export default DiscardChangesModal;
