import React, { useEffect, useState } from "react";
import { resetTeamPolicy } from "Redux/Actions/teamPolicyAction";
import Button from "Modules/button";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import { DEFAULT_LIMIT_VALUES, LIMIT_TYPES } from "constants/Team.constant";
import { onlyPositiveIntegersWithFractionsRegEx } from "constants/RegularExpressions.constant";
import { Toaster } from "Modules/toaster";
import { Cookies } from "react-cookie";
import CurrencyInput from "react-currency-input-field";
import { getCurrencyLocale } from "utility";
import { useParams } from "react-router-dom";

import { createTeamPolicyFunc } from "Redux/Actions";
import { useDispatch, useSelector } from "react-redux";
import { trackActionsOnTeamsPage, TeamProfileEventsMapping } from "Views/Team/Profile/TeamProfileEventsUtilites";
import "./policyForm.scss";

import usePermissionCheck from "Permission/usePermissionCheck";
import { CASBIN_PAGES } from "Permission/Pages";
import { TEAMS_PAGE_TEAM_PROFILE_PAGE_POLICIES_TAB } from "Permission/Actions";
import { getIsCardVendroMatchMove } from "customHooks/Card";
import { useIsSaasWithPaymentsOrg } from "customHooks/useIsSaasWithPaymentsOrg";

const getLimitType = (teamData) => {
  const { monthlyLimit } = teamData?.spendingLimits || {};
  return (monthlyLimit || monthlyLimit === 0) && monthlyLimit?.toString() !== DEFAULT_LIMIT_VALUES.NO_LIMIT_SET
    ? LIMIT_TYPES.MONTHLY_LIMIT
    : LIMIT_TYPES.UNLIMITED;
};

const PolicyForm = ({ teamDetails, handleTabChange }) => {
  const { id } = useParams();
  const permissionParam = [
    {
      object: CASBIN_PAGES.TEAMS_PAGE_TEAM_PROFILE_PAGE_POLICIES_TAB,
      action: TEAMS_PAGE_TEAM_PROFILE_PAGE_POLICIES_TAB.SAVE_BUTTON,
      team_id: id,
    },
  ];
  const IS_ALLOWED_SAVE_POLICY_ACTION = usePermissionCheck(permissionParam);
  const cookies = new Cookies();
  const dispatch = useDispatch();
  const { policyData } = useSelector((state) => state?.teamPolicyReducer);
  const [isToasterVisible, setToasterVisibility] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");
  let [isSuccess, setSuccess] = useState(false);
  const [interval, setInterval] = useState(getLimitType(teamDetails));
  const [amount, setAmount] = useState(teamDetails?.spendingLimits?.monthlyLimit || "");
  const limitReached = amount < teamDetails?.expensesThisMonth;
  const currencyCode = cookies.get("currency_code") || "";
  const [amountUpdating, setAmountUpdating] = useState(false);
  const intervalsData = [
    { name: "Monthly", id: LIMIT_TYPES.MONTHLY_LIMIT },
    { name: "Unlimited", id: LIMIT_TYPES.UNLIMITED },
  ];

  const isPaymentEnabled = useIsSaasWithPaymentsOrg();

  useEffect(() => {
    dispatch(resetTeamPolicy());
  }, [dispatch]);
  useEffect(() => {
    setInterval(getLimitType(teamDetails));
    setAmount(teamDetails?.spendingLimits?.monthlyLimit || "");
    setAmountUpdating(false);
  }, [teamDetails]);

  useEffect(() => {
    /**
     * This is to prevent curreny input initialized with negative, though the argument allowNegative is given
     * but it does for the initialization case, it works fine when manually entering value
     */
    if (interval === LIMIT_TYPES.MONTHLY_LIMIT && amount === -1) {
      setAmount("");
    }
  }, [interval, amount]);

  useEffect(() => {
    if (policyData && policyData.status === HTTP_STATUS_CODE.OK) {
      setSuccess(true);
      setToasterVisibility(true);
      setStatusMessage("Budget Policy successfully updated. The new limit is effective immediately.");
    } else if (policyData && policyData?.status > HTTP_STATUS_CODE.OK) {
      setSuccess(false);
      setToasterVisibility(true);
      setStatusMessage(policyData?.payload?.status_message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
      setToasterVisibility(false);
    };
  }, [policyData]);

  const updatePolicy = () => {
    let payload = {};
    if (interval === LIMIT_TYPES.MONTHLY_LIMIT) {
      payload = { monthlyLimit: amount?.toString()?.replace?.(/\.$/, "") || "" };
    } else if (interval === LIMIT_TYPES.UNLIMITED) {
      payload = { noLimit: true };
    }
    dispatch(createTeamPolicyFunc(payload, teamDetails?.id));
    trackActionsOnTeamsPage(TeamProfileEventsMapping.applyPolicies);
  };

  return (
    <div className="policy-form-wrapper">
      <h3>Budget Limit</h3>
      <div className="policy-header-text">
        {isPaymentEnabled && (
          <p>
            {`Cards created under this budget will follow the budget limit. ${
              !getIsCardVendroMatchMove() ? "The limit also applies to Reimbursements and Bill" : ""
            }`}
          </p>
        )}
        {isPaymentEnabled && !getIsCardVendroMatchMove() && <p>Payments from the budget.</p>}
      </div>
      <div style={{ display: "flex" }}>
        {intervalsData.length > 0 && (
          <div className="interval-container">
            {intervalsData.map((item, index) => (
              <div
                className={`policy-form-wrapper__interval ${interval !== "" && interval == item.id && "active"}`}
                key={index}
                onClick={() => {
                  if (!IS_ALLOWED_SAVE_POLICY_ACTION) {
                    return;
                  }
                  setInterval(item.id);
                }}
              >
                {item.name}
              </div>
            ))}
          </div>
        )}
      </div>
      {interval !== "none" && (
        <div className="currency-section">
          <div className="currency-label">{currencyCode}</div>
          <CurrencyInput
            disabled={interval === "none" || !IS_ALLOWED_SAVE_POLICY_ACTION}
            value={amount}
            allowNegativeValue={false}
            placeholder={"Set amount"}
            className={"team-limit__input"}
            intlConfig={{ locale: getCurrencyLocale(currencyCode || "SGD") }}
            decimalsLimit={2}
            onValueChange={(value = "") => {
              if (onlyPositiveIntegersWithFractionsRegEx?.test?.(value) || value === "") {
                setAmount(value);
                setAmountUpdating(true);
              }
            }}
          />
        </div>
      )}
      {interval !== "none" && (
        <div className="policy-footer-text">Monthly Budget Limit means the budget limit will reset every month.</div>
      )}
      {interval === "none" && (
        <div className="policy-footer-text">Unlimited means this budget does not have any spending limits.</div>
      )}
      {amountUpdating && limitReached && interval !== "none" && (
        <div className="policy-footer-text-warning">
          The limit you set is lower than the current team spending. This team will not be able to make <br /> any more
          transactions until the limit is reset next month.
        </div>
      )}
      <Button
        disabled={!IS_ALLOWED_SAVE_POLICY_ACTION || amount === ""}
        rounded
        className="policy-action-btn"
        action={() => updatePolicy()}
      >
        Save Policies
      </Button>
      <Toaster
        status={isSuccess ? "success" : "error"}
        closeFunc={() => {
          setToasterVisibility(false);
          setStatusMessage("");
          handleTabChange("members");
        }}
        message={statusMessage}
        visible={isToasterVisible}
      />
    </div>
  );
};
export default PolicyForm;
