import * as React from "react";
import { useHistory } from "react-router-dom";

import { EMPLOYEES_PATH } from "constants/Routes.constants";

import AddEmpDetails from "Views/Employee/Create/Page/Manually/AddEmployee/AddEmpDetails";

import "./index.scss";

const Manually = () => {
  const history = useHistory();

  return <AddEmpDetails toggleList={() => history.replace(EMPLOYEES_PATH)} bringEmployeesLater={true} />;
};

export default Manually;
