import React from "react";
import PropTypes from "prop-types";
import { Checkbox } from "antd";
import "./selectItem.scss";

const SelectItem = ({ data = [], action, selectedData }) => {
  return (
    <div className="select-item">
      <Checkbox.Group value={selectedData} onChange={action}>
        {data.map((elem) => elem)}
      </Checkbox.Group>
      {data.length <= 0 && <p className={"select-item__empty-state"}>No Data</p>}
    </div>
  );
};

SelectItem.propTypes = {
  data: PropTypes.array,
  action: PropTypes.func,
  selectedData: PropTypes.array,
};

export default SelectItem;
