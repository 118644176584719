/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  enums
  /////////////////////////////////////////////////////////////////////////////////////
*/

export enum SELECT_CLASSNAMES {
  DROPDOWN_POPOVER = "spm-select-dropdown",
  DROPDOWN_SEARCH = "spm-dropdown-search-input",
  DROPDOWN_LABEL = "spm-dropdown-label",
}

export enum SELECT_TYPE {
  SINGLE = "single-select",
  MULTIPLE = "multiple-select",
  SINGLE_WITH_SEARCH = "single-select-with-search",
  MULTIPLE_WITH_SEARCH = "multiple-select-with-search",
  ELASTIC_SEARCH = "elastic-search-select",
}

export enum AMOUNT_RANGE {
  MIN = "min_amount",
  MAX = "max_amount",
}

///////////////////////////////////////////////////
/// ./context
/////////////////////

export interface ISelectState {
  show: boolean;
  key: string;
}

export interface ISelectContext {
  select: ISelectState;
  closeSelect: () => void;
  onSelectHandler: (id: string) => void;
  setSelect: React.Dispatch<React.SetStateAction<ISelectState>>;
  nested?: ISelectState;
  closeNested?: () => void;
  nestedHandler?: (id: string) => void;
  setNested?: React.Dispatch<React.SetStateAction<ISelectState>>;
}

///////////////////////////////////////////////////
/// ./Range
/////////////////////

export interface IInputRangeProps<T> {
  filter: T;
  onApply: (key: string, value: number) => void;
  clear: boolean;
}
