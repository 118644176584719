import React, { useContext } from "react";

import { bulkActionTransactions } from "Redux/DataCalls/AccountingWorkflow.api";

import useLoading from "utility/useLoading";
import { useActiveCategory } from "utility/useActiveCategory";
import { CATEGORIES_PATH } from "constants/Routes.constants";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";

import { LINE_TYPE } from "Modules/DS/Menu";
import { POPOVER_WIDTH } from "Modules/DS/Popover";
import { TOASTER_STATUS_TYPE } from "Modules/DS/Toaster";
import { ListContainer } from "Modules/DS/Filter/Quick/Container/List";
import Search, { DEBOUNCE_DELAY_TIME, IPopoverSearch, useSimpleSearch } from "Modules/DS/Search";
import { ISelectContext, SelectContext, SELECT_TYPE } from "Modules/DS/Select";

import { TrxnFilterContext, TrxnToastContext } from "Views/Transaction/Context";
import { ALL_TRXNS_COLUMNS, DEFAULT_ERROR_MESSAGE } from "Views/Transaction/Constants";
import { ITitleWithID, ITrxnToastContext, ITrxnFilterContext } from "Views/Transaction/@types";

import { FlatIcon } from "assets/v1.1/icons";
import styles from "./Category.module.scss";

export const useCategoryUpdate = (
  selectedRows: string[],
  updateLoader?: React.Dispatch<React.SetStateAction<boolean>>,
  onSuccess?: () => void,
  onError?: (err) => void
) => {
  const { closeSelect } = useContext<ISelectContext>(SelectContext);

  const { setToaster }: ITrxnToastContext = useContext<ITrxnToastContext>(TrxnToastContext);
  const { filter, onApplyFilter }: ITrxnFilterContext = useContext<ITrxnFilterContext>(TrxnFilterContext);

  const [bulkAction] = useLoading(bulkActionTransactions, null, {}, false);

  const { categoryList, isLoading } = useActiveCategory();

  const data: ITitleWithID[] = categoryList?.map((category) => ({
    id: category.id.toString(),
    title: category.categoryName,
  }));

  const addCategories: boolean = data.length === 0 && !isLoading;

  const { get, onSearchHandler, clearSearchHandler } = useSimpleSearch<ITitleWithID>(data, "title");

  const onClickHandler = async (id: string) => {
    try {
      // Reset the dynamic min height when closing the popover
      const element = document.querySelector<HTMLDivElement>(".ant-table-content");
      if (element) {
        element.style.minHeight = null;
      }
      closeSelect();
      updateLoader?.(true);

      const { data } = await bulkAction(selectedRows, "expense_category_id", id);

      if (data.status > HTTP_STATUS_CODE.OK || data.payload.status_message === "User not Authorised") {
        throw new Error(data?.payload?.status_message);
      }

      const message = `${selectedRows.length > 1 ? "Multiple categories" : "1 category"} successfully edited`;

      setTimeout(() => {
        setToaster({
          message,
          show: true,
          type: TOASTER_STATUS_TYPE.SUCCESS,
        });
        onApplyFilter(filter);
        updateLoader?.(false);
        onSuccess?.();
      }, DEBOUNCE_DELAY_TIME.MAX);

      return data;
    } catch (err) {
      onError?.(err);
      updateLoader?.(false);
      setToaster({
        message: err?.message || DEFAULT_ERROR_MESSAGE,
        show: true,
        type: TOASTER_STATUS_TYPE.ERROR,
      });
    }
  };

  const searchProps: IPopoverSearch = {
    value: get.value,
    loading: get.loading,
    placeholder: `Search ${ALL_TRXNS_COLUMNS.CATEGORY}`,
    onSearch: onSearchHandler,
  };

  const renderChildren = (): JSX.Element => {
    return (
      <>
        <Search.Popover {...searchProps} />
        <ListContainer<ITitleWithID>
          loading={isLoading || get.loading}
          lineProps={(item: ITitleWithID) => ({
            id: item.id,
            title: item.title,
            type: LINE_TYPE.SINGLE_LINE,
            loading: isLoading || get.loading,
            onClick: () => onClickHandler(item.id),
          })}
        >
          {get.data.length > 0 || get.value.length > 0 ? get.data : data}
        </ListContainer>
      </>
    );
  };

  const renderAddNew = () => {
    return (
      <div className={styles.addCategory}>
        <img src={FlatIcon.emptyStateAdd} alt="Empty State" />
        <p>Setup your category expenses in Spenmo</p>
        <button onClick={() => window.open(CATEGORIES_PATH, "_blank")}>Add Categories</button>
      </div>
    );
  };

  return {
    addNew: addCategories,
    onClose: clearSearchHandler,
    type: SELECT_TYPE.SINGLE_WITH_SEARCH,
    width: addCategories ? POPOVER_WIDTH.XS : POPOVER_WIDTH.M,
    children: addCategories ? renderAddNew() : renderChildren(),
    bannerText: addCategories ? "" : "Please assign a category to this transaction",
    listLength: get.data.length,
  };
};
