import { IDocumentWithButton, IDocumentsInfo, ILabelDetails } from "Views/KYX/@types";
import { MyInfoLogo, UploadIcon } from "assets/img/kyb";
import { CHIP_STATUS } from "Modules/DS/Chip";

export const MY_INFO_LOGO_DETAILS: ILabelDetails = {
  icon: MyInfoLogo,
  label: {
    title: "Automated",
    status: CHIP_STATUS.STRONG,
  },
};

export const SGD_MANUAL: IDocumentsInfo = {
  content:
    "Thank you for choosing Spenmo as your partner in managing your company expenses. To start improving your accounts payable experience, please submit your company data for verification." as const,
  label: "Please prepare the following documents for the verification process:" as const,
  documents: [
    {
      title: "Identity Documents" as const,
      info: "(All in PDF, PNG, JPEG format for Authorized Users, Directors, and Beneficial Owners)" as const,
      list: [
        {
          document: "NRIC (Singaporean)" as const,
        },
        {
          document: "Passport or Workpass (Other Nationalities)" as const,
        },
        {
          document:
            "Proof of Address (Other Nationalities and Singaporeans whose residential address differs from ID)" as const,
        },
      ],
    },
    {
      title: "Company Documents" as const,
      info: "(All in PDF, PNG, JPEG format)" as const,
      list: [
        {
          document: "ACRA BizFile issued by the Accounting and Corporate Regulatory Authority" as const,
        },
        {
          document: "Memorandum & Articles of Association / Constitution" as const,
        },
      ],
    },
  ],
};

export const SGD_MY_INFO: IDocumentWithButton = {
  content:
    "Thank you for choosing Spenmo as your partner in managing your company expenses. To start improving your accounts payable experience, please submit your company data for verification." as const,
  label: "Select your data submission method:" as const,
  documents: [
    {
      id: "myInfo",
      title: "Fill automatically with Myinfo business" as const,
      ...MY_INFO_LOGO_DETAILS,
      info: "To retrieve company data instantly from Myinfo business, please provide:" as const,
      list: [
        {
          icon: {
            name: "check",
            svgAttr: { width: 20, height: 20, viewBox: "0 0 16 16" },
          },
          document: "Singpass login credentials of the business owner, partner, or director" as const,
        },
        {
          icon: {
            name: "check",
            svgAttr: { width: 20, height: 20, viewBox: "0 0 16 16" },
          },
          document: "Corppass login credentials for the company" as const,
        },
      ],
    },
    {
      id: "manual",
      icon: UploadIcon,
      title: "Upload Manually" as const,
      info: "Submit your company data for verification by uploading:" as const,
      list: [
        {
          document: "ACRA BizFile issued by the Accounting and Corporate Regulatory Authority" as const,
        },
        {
          document: "Memorandum & Articles of Association / Constitution" as const,
        },
        {
          document: "NRIC (Singaporean) or Passport (Other Nationalities)" as const,
        },
        {
          document:
            "Proof of Address (Other Nationalities and Singaporeans whose residential address differs from ID)" as const,
        },
      ],
    },
  ],
};
