import React from "react";

const PasswordCriteria = (): React.JSX.Element => {
  const criteriaList: string[] = [
    "8 letters or more",
    "One lowercase letter",
    "One uppercase letter",
    "One number",
    "One special character (&?#@)",
  ];

  return (
    <>
      <div className="criteria-title">
        <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M-0.000976562 1.5L4.99902 0L9.99902 1.5L9.69597 3.46982C9.25347 6.34608 7.58 8.88689 5.11225 10.4292L4.99902 10.5L4.8858 10.4292C2.41805 8.88689 0.744574 6.34608 0.302073 3.46983L-0.000976562 1.5Z"
            fill="#0C75D2"
          />
        </svg>
        <p>For security reasons, password must contain:</p>
      </div>
      <ul className="criteria-list">
        {criteriaList.map((criteria) => (
          <li key={criteria}>{criteria}</li>
        ))}
      </ul>
    </>
  );
};

export default PasswordCriteria;
