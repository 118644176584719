export enum EmployeeToastEventName {
  DELETE_ACCOUNT_SUCCESS = "Success",
  DELETE_ACCOUNT_STATUS = "Delete Account Processed",
}

export enum EmployeeDeleteEventName {
  ACCOUNT_DELETION_ELIGIBILITY_CHECK_FAIL = "Eligibility Check Failed",
  CLOSE_ACCOUNT_DELETION_ELIGIBILITY_FAIL = "close account deletion eligibility fail window",
  CLOSE_EMPLOYEE_ACCOUNT_DELETION_FAIL = "close employee account deletion fail window",
  DELETE_ACCOUNT_CANCELLED = "Cancel",
  DELETE_ACCOUNT_CLICKED = "Delete",
  DELETE_ACCOUNT_CONFIRMED = "Confirm",
  EMPLOYEE_ACCOUNT_DELETION_FAILED = "Failed",
  VISIT_SPECIFIC_TEAM_PAGE_FROM_ELIGIBILITY_FAIL_POPUP = "visit specific team page from eligibility fail popup",
  VISIT_TEAM_LIST_PAGE_FROM_ELIGIBILITY_FAIL_POPUP = "visit team list page from eligibility fail popup",
  DELETE_ACCOUNT_EVENT = "Delete Account Button Clicked",
}
export enum EmployeeRoleUpdateEventName {
  ROLE_UPDATE_STATUS = "Role Update Processed",
  ROLE_ADMIN = "Admin",
  ROLE_EMPLOYEE = "Employee",
  ROLE_BOOKKEEPER = "Bookkeeper",
  ROLE_UPDATE_SUCCESS = "true",
  ROLE_UPDATE_FAIL = "false",
}

export enum USER_EVENT_NAMES {
  USER_PAGE_LOADED = "User Page Loaded",
  USER_ADD_BUTTON_CLICKED = "User Add Button Clicked",
}
