import "../topup.scss";

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { topupActionFunc } from "../../../Redux/Actions";
import { Form } from "antd";
import SelectAmount from "../../../Modules/selectAmount";
import Button from "../../../Modules/button";
import { CURRENCY_CODE } from "../../../constants/Currency.constant";
import { currencyFormatter } from "../../../utility";
import { resetTopupData } from "../../../Redux/Actions/topupAction";
import Icon from "../../../Modules/icons";
import { orgTeamFunc } from "../../../Redux/Actions/orgTeamAction";
import { trackEvent } from "utility/analytics";

import usePermissionCheck from "Permission/usePermissionCheck";
import { CASBIN_PAGES } from "Permission/Pages";
import { TOPUP_PAGE } from "Permission/Actions";

const TopUpPayNow = ({ currencyCode, trackTopupNow }) => {
  const permissionParam = [
    { object: CASBIN_PAGES.TOPUP_PAGE, action: TOPUP_PAGE.TOPUP_NOW_BUTTON },
  ];
  const IS_ALLOWED_TOP_UP = usePermissionCheck(permissionParam);
  const PAYMENT_TYPE = "paynow";

  const {
    paymentType,
    data: topupData,
    loading,
    error,
  } = useSelector((state) => state.topupReducer);

  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [currency, setCurrency] = useState(currencyCode);

  const parseQRCode = (qrCode) => {
    if (!Boolean(qrCode)) {
      return qrCode;
    }
    const mimeType = "data:image/png;base64";
    let parseQR = qrCode;
    if (!qrCode.includes(mimeType)) {
      parseQR = mimeType + "," + qrCode;
    }
    return parseQR;
  };

  useEffect(() => {
    return () => {
      dispatch(resetTopupData());
    };
  }, [dispatch]);

  const onFinish = (values) => {
    const { amount } = values;
    trackTopupNow(amount); // to track top up amount and method along with currency
    const formData = {
      ...values,
    };
    dispatch(topupActionFunc(formData));
  };

  const handleAmount = (value) => {
    form.setFieldsValue({ amount: value });
  };

  const completeTopup = () => {
    const params = {
      selectedPaymentMethod: PAYMENT_TYPE,
    };
    trackEvent("Top up  user confirmed", params); // to track top up confimation
    dispatch(resetTopupData());
    dispatch(orgTeamFunc());
  };

  const TopUpAmount = (
    <>
      <div className={"sp-margin-bottom--l"}>Enter your amount</div>
      <div className={"sp-margin-bottom--xl"}>
        <Form form={form} onFinish={onFinish}>
          <SelectAmount
            alternate
            currencyAction={(value) => setCurrency(value)}
            showAmountOptions={false}
            initialCurrency={currencyCode}
            action={handleAmount}
            className={"sp-margin-bottom--xl"}
            rules={[
              { required: true, message: "Please enter the Amount!" },
              ({ getFieldValue }) => ({
                validator() {
                  if (
                    currency === CURRENCY_CODE.IDR &&
                    getFieldValue("amount") < 10001
                  ) {
                    return Promise.reject("Minimum amount is IDR 10.001");
                  } else if (getFieldValue("amount") < 1) {
                    return Promise.reject("Minimum amount is 1");
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          />

          <Form.Item>
            {IS_ALLOWED_TOP_UP && (
              <Button
                type={"submit"}
                rounded
                size={"l"}
                loading={loading}
                text={"Top up now"}
              />
            )}
          </Form.Item>
        </Form>
        {error && <div className={"text--danger"}>{error}</div>}
      </div>
    </>
  );

  const TopUpPayNowQR = (
    <>
      <div className={"paynow__header"}>
        <Icon
          iconName={"left-arrow"}
          className={"sp-margin-right--s"}
          onClick={() => dispatch(resetTopupData())}
        />
        {`Top Up ${currencyFormatter(form.getFieldValue("amount"), currency)}`}
      </div>
      <div className={"paynow__qr-code"}>
        <img
          src={parseQRCode(topupData?.data?.qr_code)}
          alt={"Pay Now QR Code"}
        />
      </div>
      <div className={"text--center"}>
        <Button
          type={"button"}
          rounded
          size={"l"}
          action={completeTopup}
          text={"I Have Completed Top Up"}
        />
      </div>
    </>
  );

  return <>{topupData?.data ? TopUpPayNowQR : TopUpAmount}</>;
};

TopUpPayNow.propTypes = {
  currencyCode: PropTypes.string,
  trackTopupNow: PropTypes.func,
};

export default TopUpPayNow;
