import * as React from "react";
import { useContext } from "react";

import { TitleWithIconButton } from "Modules/DS/DataTable";

import { EmployeeModalTypes } from "Views/Employee/types";
import { EmployeesModalContext, IEmployeesModalContext } from "Views/Employee/context";

const RoleTitle = () => {
  const { toggleModal }: IEmployeesModalContext = useContext(EmployeesModalContext);

  return (
    <TitleWithIconButton action={() => toggleModal(EmployeeModalTypes.ROLE_EXPLANATION)}>Role</TitleWithIconButton>
  );
};

export default RoleTitle;
