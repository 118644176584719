import { useContext, useState } from "react";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import { submitKYXFormData } from "Views/KYX/DataCalls";
import { KYXToastContext, KYXErrorToaster } from "Views/KYX/Context";
import { IKYXToastContext, ISubmitKYXFormData, ISubmitKYXRequest } from "Views/KYX/@types";
import { trackEvent } from "utility/analytics";
import { KYXEvents } from "Views/KYX/analytics";

/**
 * A custom hook that handles the process of submitting KYX form data.
 *
 * PSA: Ensure that this hook is used within the bounds of KYXToastContext.Provider
 *
 * @param {() => void} dispatchOnSuccess (optional) - A callback function that gets executed when the form data is successfully submitted.
 * @returns {ISubmitKYXFormData} An object containing submitFormData and loading properties.
 *
 * This hook uses the `submitKYXFormData` function internally to make an HTTP request to submit KYX form data.
 * It passes the `payload` parameter to the `submitKYXFormData` function and checks the response status.
 * If the response status is not 200, it throws an error and displays a toast message using the `KYXErrorToaster` function.
 * If the `dispatchOnSuccess` callback function is defined, it is called when the form data is successfully submitted.
 */

export const useSubmitKYXFormData = (dispatchOnSuccess?: () => void): ISubmitKYXFormData => {
  const [loading, setLoading] = useState(false);
  const { setToaster } = useContext<IKYXToastContext>(KYXToastContext);

  const submitFormData = async (payload: ISubmitKYXRequest) => {
    try {
      setLoading(true);

      const response = await submitKYXFormData(payload);

      const errorMessage = response.data?.error?.message || response.data?.meta?.message;

      if (response?.data?.status === HTTP_STATUS_CODE.OK) {
        trackEvent(KYXEvents.KYC_SUBMIT_PROCESSED, {
          is_success: true,
        });
        dispatchOnSuccess?.();
      } else {
        trackEvent(KYXEvents.KYC_SUBMIT_PROCESSED, {
          is_success: false,
        });
        throw new Error(errorMessage);
      }
    } catch (error) {
      const toast = KYXErrorToaster(error?.response?.data?.error?.message || error);

      setToaster(toast);
      trackEvent(KYXEvents.KYC_SUBMIT_PROCESSED, {
        is_success: false,
      });
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return { submitFormData, loading };
};
