import React, { useEffect, useState } from "react";
import Button from "../button";
import Search from "../search";
import Icon from "../icons";
import { checklistBlue } from "../../assets/img";
import { Checkbox } from "antd";
import { ISelectionData } from "Modules/multiSelection/DropdownSelection";
import SelectItem from "../selectItem/index";
import "./FilterPopover.scss";

interface IFilterPopover {
  list: Array<ISelectionData>;
  defaultValue?: Array<string>;
  values?: Array<string> | string;
  isSearchable?: boolean;
  isMultiSelect?: boolean;
  hasClearButton?: boolean;
  hasSearch?: boolean;
  popupOverClassName?: string;
  action: (payload) => void;
  closeAction: () => void;
}

const FilterPopover: React.FC<IFilterPopover> = ({
  list = [],
  defaultValue = [],
  action,
  closeAction,
  isMultiSelect = false,
  hasClearButton = false,
  hasSearch = false,
  values,
  popupOverClassName,
}) => {
  const [tempValues, setTempValues] = useState(values || defaultValue);
  const [filteredList, setFilteredList] = useState(list);

  useEffect(() => {
    setTempValues(values || defaultValue);
  }, [values]);

  useEffect(() => {
    setFilteredList(list);
  }, [list]);

  const handleCheckboxChange = (e) => {
    setTempValues(e);
  };

  const handleListClick = (e) => {
    action([e]);
    closeAction();
  };

  const handleSubmit = () => {
    action(tempValues);
    closeAction();
  };

  const handleClear = () => {
    setTempValues([]);
  };

  const handleSearch = (value) => {
    setFilteredList(list.filter((item) => item.label.toUpperCase().includes(value.toUpperCase())));
  };

  const renderSelectItems = filteredList?.map((item) => (
    <Checkbox value={item?.value} key={item?.name}>
      {item?.label}
    </Checkbox>
  ));

  return (
    <div
      className={`filter-popover ${isMultiSelect ? "filter-popover--has-action multi-selection-option" : ""} ${
        popupOverClassName || ""
      }`}
    >
      {hasSearch && (
        <Search
          className="filter-popover__search"
          placeholder="Search"
          changeAction={handleSearch}
          iconPosition="right"
        />
      )}
      <div className="filter-popover__list">
        {isMultiSelect ? (
          <SelectItem data={renderSelectItems} selectedData={tempValues} action={handleCheckboxChange} />
        ) : (
          <ul>
            {filteredList.map((item) => {
              const selected = item.value === tempValues?.[0];
              return (
                <li
                  key={item.value as string}
                  onClick={() => handleListClick(item.value)}
                  className={selected ? "selected" : ""}
                >
                  {item.label} {selected && <Icon src={checklistBlue} alt="selected" />}
                </li>
              );
            })}
          </ul>
        )}
      </div>
      {isMultiSelect && (
        <div className="filter-popover__action">
          {hasClearButton && (
            <Button className="filter-popover__action__button--clear" textButton action={handleClear}>
              Clear
            </Button>
          )}
          <Button className="filter-popover__action__button--submit" action={handleSubmit}>
            Apply
          </Button>
        </div>
      )}
    </div>
  );
};

export default FilterPopover;
