import * as React from "react";
import { Checkbox } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getMerchantsFunc } from "Redux/Actions";
import Select from "Views/Card/Components/Select";
import "Views/Card/Components/MerchantLock/index.scss";
interface MerchantLockProps {
  onMerchantLockSelection: (merchantLocks: Array<string>) => void;
  initialValues: string[];
  defaultValue?: string;
}

export const MerchantLock = ({ defaultValue, onMerchantLockSelection, initialValues }: MerchantLockProps) => {
  const dispatch = useDispatch();
  const [merchantData, setMerchantData] = React.useState<{ label: string; name: string }[]>([]);
  const [selectedMerchantList, setSelectedMerchantList] = React.useState<string[]>(initialValues);
  const [searchInputText, setSearchInputText] = React.useState<string>("");

  const { data: merchantDataFromApi, loading: merchantDataLoading } = useSelector(
    (state: any) => state?.merchantReducer || {}
  );

  const processMerchantData = () => {
    const { merchants = [] } = merchantDataFromApi?.payload || {};
    const processedMerchants =
      merchants?.map((merchant) => ({
        name: merchant?.name,
        label: merchant?.name?.toLowerCase?.(),
      })) || [];
    setMerchantData(processedMerchants);
  };

  React.useEffect(() => {
    dispatch(getMerchantsFunc());
  }, [dispatch]);

  React.useEffect(() => {
    processMerchantData();
  }, [merchantDataFromApi]);
  const getDropDown = () => {
    // if asked to show selected first
    // const sortedMerchantList = [...(merchantData || [])].sort((merchant1, merchant2) => {
    //   if (selectedMerchantList?.includes?.(merchant1?.name) && selectedMerchantList?.includes(merchant2?.name)) {
    //     return 0;
    //   }
    //   return selectedMerchantList?.includes?.(merchant1?.name) ? -1 : 1;
    // });
    const sortedMerchantList = Array.isArray(merchantData) ? [...merchantData] : [];
    const filterOnSearchText = searchInputText
      ? sortedMerchantList?.filter((merchant) =>
          merchant?.label?.toLowerCase?.()?.includes(searchInputText?.toLowerCase())
        )
      : sortedMerchantList;
    const handleSelectedMerchant = (values: string[]) => {
      const preSelectedItems = selectedMerchantList || [];
      const newSelectedItems = values || [];
      const oldSelectedToCheckInOptions = preSelectedItems.filter(
        (preSelectedItem) => newSelectedItems.indexOf(preSelectedItem) === -1
      );
      const filteredOptions = filterOnSearchText;

      oldSelectedToCheckInOptions.forEach((oldSelectedToCheckInOption) => {
        const isItemInFilteredOptions = filteredOptions.some(
          (filteredOption) => filteredOption.name === oldSelectedToCheckInOption
        );
        if (isItemInFilteredOptions) {
          oldSelectedToCheckInOptions.splice(oldSelectedToCheckInOptions.indexOf(oldSelectedToCheckInOption), 1);
        }
      });
      const finalValues = [...oldSelectedToCheckInOptions, ...newSelectedItems];
      onMerchantLockSelection(finalValues);
      setSelectedMerchantList(finalValues || []);
    };

    return (
      <Checkbox.Group value={selectedMerchantList} onChange={handleSelectedMerchant}>
        {filterOnSearchText?.map((dataItem) => (
          <Checkbox key={dataItem.name} value={dataItem.name} id={dataItem.name}>
            <p className="label">{dataItem.label}</p>
          </Checkbox>
        ))}
      </Checkbox.Group>
    );
  };

  return (
    <Select
      placeholder="Select Merchant"
      defaultValue={defaultValue || undefined}
      value={selectedMerchantList?.join(", ")?.toLowerCase() || undefined}
      dropdownClassName="merchant-lock__dropdown"
      listHeight={248}
      showSearch
      loading={merchantDataLoading}
      dropdownRender={getDropDown}
      onSearch={setSearchInputText}
      searchValue={searchInputText}
      getPopupContainer={(triggerNode) => triggerNode.parentElement}
      data-testid="merchant-lock"
      className="cards-select-box merchant-lock__select"
    />
  );
};
