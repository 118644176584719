import React from "react";
import Button from "Modules/button";
import { ISidePanelButton } from "../../type.d";
import classNames from "classnames";

import styles from "./styles.module.scss";

export const ConstructiveButton = ({ action, text, disabled, loading, shouldUseColSpan }: ISidePanelButton) => {
  return (
    <Button
      size="l"
      rounded
      disabled={disabled || loading}
      btnStyle={classNames({
        green: !disabled && !loading,
      })}
      className={classNames({ [styles["col-span-2"]]: shouldUseColSpan })}
      action={action}
    >
      {text}
    </Button>
  );
};
