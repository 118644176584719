import * as React from "react";
import classNames from "classnames";
import { checklistWhite } from "assets/img";

import { CHECKBOX_CLASS_NAME, ICheckboxProps } from "./types.d";

import "./Checkbox.scss";

const Checkbox = ({ id, checked, radio, disabled, onClick = () => null, className }: ICheckboxProps) => {
  const checkboxClassNames: string = classNames(CHECKBOX_CLASS_NAME.CONTAINER, className, { checked, radio, disabled });

  return (
    <div className={checkboxClassNames}>
      {radio ? (
        <div className={CHECKBOX_CLASS_NAME.INNER} />
      ) : (
        <img
          id={id ? id : null}
          alt="checkbox"
          src={checklistWhite}
          className={CHECKBOX_CLASS_NAME.INNER}
          onClick={(e) => {
            e.stopPropagation();
            onClick && onClick(e);
          }}
        />
      )}
    </div>
  );
};

export default Checkbox;
