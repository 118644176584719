import React from "react";
import PropTypes from "prop-types";
import { getFirstIndex } from "../../utility";
import "./letterAvatar.scss";

const LetterAvatar = ({ name, rounded, square, className, bigSquare }) => {
  return (
    <div
      className={`letter-avatar ${rounded && "letter-avatar--rounded"} ${square && "letter-avatar--square"} ${
        bigSquare && "letter-avatar--big-square"
      } ${className && className}`}
    >
      {getFirstIndex(name)}
    </div>
  );
};

LetterAvatar.defaultProps = { name: "" };

LetterAvatar.propTypes = {
  name: PropTypes.string,
  square: PropTypes.bool,
  rounded: PropTypes.bool,
  className: PropTypes.string,
  bigSquare: PropTypes.bool,
};

export default LetterAvatar;
