import React from "react";
import cn from "classnames";
import { PropTypes } from "prop-types";
import "./loaderIcon.scss";

const LoaderIcon = (props) => {
  let { style, className } = props;
  return (
    <div className={cn("loading loading-wrapper", className)} style={style} data-testid="loading-icon">
      <div className="dot-flashing" />
    </div>
  );
};
LoaderIcon.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
};

export default LoaderIcon;
