import React, { useState } from "react";
import { createPortal } from "react-dom";
import { Button, Modal as SpliceModal } from "@spenmo/splice";
import { IModal, IModalContent, IModalButton } from "Views/SubscriptionManagement/@types";

import styles from "./styles.module.scss";

export const Modal = ({ buttons, children, className, trigger, title }: IModal & IModalContent) => {
  const [showModal, setShowModal] = useState(false);
  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  const handleButtonAction = (button: IModalButton) => {
    if (button.closeModal) {
      closeModal();
      return;
    }
    button.action(closeModal);
  };

  return (
    <>
      {trigger({
        openModal,
      })}
      {createPortal(
        <SpliceModal
          showModal={showModal}
          size="m"
          title={title}
          onClose={() => setShowModal(false)}
          className={className}
        >
          <section className={styles.container}>
            <section className={styles.content}>{children}</section>
            <section className={styles.footer}>
              {buttons.map((button) => (
                <Button
                  variant={button.variant}
                  size="m"
                  onClick={(event) => {
                    event?.preventDefault();
                    handleButtonAction(button);
                  }}
                  disabled={button.disabled}
                  loading={button?.loading || false}
                >
                  {button.text}
                </Button>
              ))}
            </section>
          </section>
        </SpliceModal>,
        document.getElementById("modal_container")
      )}
    </>
  );
};
