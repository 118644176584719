import { userInitialState } from "Redux/InitialState";
import {
  FETCH_FORGOT_REQUEST,
  FETCH_FORGOT_SUCCESS,
  FETCH_FORGOT_FAILURE,
  RESET_FORGOT_DATA,
} from "Redux/Actions/forgotAction";

export const forgotReducer = (state = userInitialState, action) => {
  switch (action.type) {
    case FETCH_FORGOT_REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
        isSuccess: false,
      };
    case FETCH_FORGOT_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
        isSuccess: true,
      };
    case FETCH_FORGOT_FAILURE:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: true,
        isSuccess: false,
      };
    case RESET_FORGOT_DATA:
      return {
        ...state,
        data: null,
        isSuccess: false,
      };
    default:
      return state;
  }
};
