import React from "react";
import { IStickyProps } from "../../type.d";
import styles from "./styles.module.scss";
import classNames from "classnames";
import { generateButton, ButtonWrapper } from "../Buttons";

const Sticky = ({ buttons, useLongHeight, stickyContent, loading }: IStickyProps) => {
  const longHeight = useLongHeight && buttons.length > 2;

  return (
    <div
      data-testid="sticky-container"
      className={classNames(styles["side-panel__sticky"], {
        [styles["side-panel__sticky-long"]]: longHeight,
      })}
    >
      {Boolean(stickyContent) && stickyContent}
      {!loading && <ButtonWrapper>{buttons.map((button) => generateButton(button, buttons.length))}</ButtonWrapper>}
    </div>
  );
};

export default Sticky;
