export interface RecipientListItem {
  ID: number;
  legalName: string;
  beneficiaryName: string;
  currencyCode: string;
  countryCode: string;
  countryName: string;
  type: string;
  lastTransaction: {
    amount: number;
    currency: string;
    timestamp: number;
  } | null;
  lastModified: {
    name: string;
    timestamp: number;
  } | null;
}

export interface RecipientDataDynamicFields {
  id: number;
  alias: string;
  label: string;
  value: string;
  orderNumber: number;
}

export interface RecipientData {
  bankAccountNumber: string;
  bankCode: string;
  bankName: string;
  beneficiaryName: string;
  countryCode: string;
  countryName: string;
  createdInfo: {
    name: string;
    timestamp: number;
  };
  currencyCode: string;
  dynamicFields?: RecipientDataDynamicFields[];
  ID: number;
  lastModified: {
    name: string;
    timestamp: number;
  };
  lastTransactions: {
    amount: number;
    currency: string;
    timestamp: number;
  }[];
  legalName: string;
  paymentPurpose: string;
  recipientEmail: string;
  type: string;
  upcomingPayments?: {
    amount: number;
    currency: string;
    status: string;
    timestamp: number;
  }[];
}

export interface Pagination {
  page: number;
  size: number;
  totalItems: number;
  totalPages: number;
}

export interface PaginationWithNextPageToken extends Pagination {
  nextPageToken: string;
}

export enum SaveVendorSource {
  portal_single = "portal_single",
  portal_draft = "portal_draft",
  portal_drag_and_drop = "portal_drag_and_drop",
  portal_edit_bill = "portal_edit_bill",
  vendor_management = "vendor_management",
}
