import React, { useMemo } from "react";

import { GetCurrencyCode, capitalizeFirstLetter, currencyFormatter } from "utility";

import { Header, Status } from "Views/Transaction/Details";
import { IDetailWithEnabledFlag, IReimbursementTrxnDetails } from "Views/Transaction/@types";
import { removeDuplicates, getRecordDetailsDate } from "Views/Transaction/Helper";
import { useMutableData } from "API/useData";
import { TRANSACTION_DETAILS } from "Views/Transaction/DataCalls/Services";

import { Details } from "./Details";
import { ITransactionDetailLineItems } from "Modules/TransactionDetail";

export const ReimbursementTrxnDetails = ({
  amount,
  currencyCode,
  requesterName,
  teamName,
  trxnNumber,
  createdDate,
  notes,
  status,
  recipientName,
  receipts,
  handleSaveRef,
  expenseData,
  closeDetails,
  trxnType,
  disableEditing,
}: IReimbursementTrxnDetails) => {
  const date = getRecordDetailsDate(createdDate);

  const { data: multipleCRData } = useMutableData(TRANSACTION_DETAILS().GET_CR_PAYOUT_DETAILS(trxnNumber));
  const { countReimbursement = 0, items = [], currency = "SGD", totalAmount = 0 } = multipleCRData?.data?.payload || {};

  const lineItems: ITransactionDetailLineItems = {
    title: "Reimbursement Items",
    items: items.map((item) => ({
      title: item?.merchant,
      subtitle: `${item?.currency} ${item?.amount}`,
      linkTo: `/reimbursements?transaction_number=${trxnNumber}`,
    })),
    total: {
      title: `Total (${countReimbursement})`,
      subtitle: `${currency} ${totalAmount}`,
    },
  };

  const isMultipleCR = useMemo(() => items.length > 1 && countReimbursement > 1, [countReimbursement, items]);

  const details: IDetailWithEnabledFlag[] = [
    {
      id: "requester",
      key: "Requester",
      value: capitalizeFirstLetter(requesterName),
      subtitle: capitalizeFirstLetter(teamName),
      enabled: true,
    },
    {
      id: "recipient",
      key: "Merchant",
      value: isMultipleCR ? "(Multiple)" : capitalizeFirstLetter(recipientName),
      enabled: true,
    },
    { id: "trxn_number", key: "Transaction Number", value: trxnNumber, enabled: true },
    { id: "created_date", key: "Created Date & Time", value: date, enabled: true },
    { id: "trxn_type", key: "Transaction Type", value: "Reimbursement", enabled: true },
    {
      id: "fx_currency_code",
      key: "Foreign Currency Code",
      value: expenseData.foreignCurrencyCode || "N/A",
      enabled: GetCurrencyCode() !== expenseData.foreignCurrencyCode,
    },
    {
      id: "fx_currency_amount",
      key: "Foreign Currency Amount",
      value: currencyFormatter(expenseData.foreignCurrencyAmount, expenseData.foreignCurrencyCode, true),
      enabled: GetCurrencyCode() !== expenseData.foreignCurrencyCode,
    },
  ].filter((detail) => detail.enabled);

  return (
    <>
      <Header title={requesterName} label="Recipient" amount={amount} currencyCode={currencyCode} />
      <Status date={date} status={status} />
      <Details
        notes={notes}
        details={details}
        handleSaveRef={handleSaveRef}
        trxnType={trxnType}
        trxnNumber={trxnNumber}
        receipts={removeDuplicates(receipts)}
        categoryId={expenseData.category}
        categoryName={expenseData.categoryName}
        taxName={expenseData.taxName}
        closeDetails={closeDetails}
        taxId={expenseData.tax}
        tags={expenseData.tags}
        expenseReceipts={expenseData.receipts}
        disableEditing={disableEditing}
        subscriptionId={expenseData.subscription_id}
        isMultipleCR={isMultipleCR}
        lineItems={lineItems}
        subscriptionInitialProps={{
          vendor_name: isMultipleCR ? null : recipientName,
          estimated_amount: amount,
          currency: currencyCode,
          budget_id: expenseData.budgetId,
          owner_id: expenseData.payerId,
        }}
      />
    </>
  );
};
