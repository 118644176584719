import * as React from "react";
import LetterAvatar from "Modules/letterAvatar";
import Icon from "Modules/icons";
import styles from "./CompactCard.module.scss";
import { merchantLocksWithIconsParam } from "./types";
import MerchantIcons from "./merchantLockIcons";

const MINIMUM_MERCHANTS_SHOWN = 3;
const setMerchantCountIcon = (
  merchantLocksWithIcons: merchantLocksWithIconsParam[],
  merchantList: (Array<string> | any)[],
  cardColor: string
) => {
  const merchantsDisplayed = merchantLocksWithIcons?.slice(0, MINIMUM_MERCHANTS_SHOWN);
  const borderStyle = { border: `2px solid ${cardColor}` };

  return (
    <div className={styles["merchant-lock-container"]}>
      {merchantsDisplayed?.map((merchant) => (
        <Icon style={borderStyle} className={styles["merchant-icon"]} src={merchant.icon} alt={merchant.name} />
      ))}
      {merchantsDisplayed?.length < merchantList?.length && (
        <div style={borderStyle} className={styles["merchant-letter-icon"]}>
          +{merchantList.length - merchantsDisplayed.length}
        </div>
      )}
    </div>
  );
};

export const getMerchantLocks = (merchantList: (Array<string> | any)[], cardName: string, cardColor: string) => {
  const mappedMerchantList = Array.isArray(merchantList) && merchantList?.map((name) => name?.toLowerCase());
  const merchantIcons = MerchantIcons?.filter((merchant) =>
    mappedMerchantList?.includes(merchant?.name?.toLowerCase())
  );

  if (merchantIcons?.length > 0) {
    return setMerchantCountIcon(merchantIcons, mappedMerchantList, cardColor);
  }
  return <LetterAvatar className={styles["merchant-letter-icon"]} rounded name={cardName} />;
};
