import { checkTermsAndConditionsExisting } from "Redux/Actions";
import { GetOrgId, GetUserId } from "utility";
import APIclient from "API/Client";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
export const TERMS_CONDITIONS_SUCCESS = "TERMS_CONDITIONS_SUCCESS";
export const TERMS_CONDITIONS_ERROR = "TERMS_CONDITIONS_ERROR";

export const fetchTnCDataSuccess = (data) => {
  return {
    type: TERMS_CONDITIONS_SUCCESS,
    data: data,
  };
};
export const fetchTnCDataError = (data) => {
  return {
    type: TERMS_CONDITIONS_ERROR,
    data: data,
  };
};

export const setTermsAndConditionsExisting = () => {
  return (dispatch) => {
    const headers = {
      headers: { "X-Organisation-Id": GetOrgId(), "X-User-Id": GetUserId() },
    };
    APIclient.postData(`/ms/spm-organization/v1/agreements`, {}, false, headers)
      .then((data) => {
        if (data?.data?.status === HTTP_STATUS_CODE.OK) {
          dispatch(fetchTnCDataSuccess(data?.data));
          dispatch(checkTermsAndConditionsExisting());
        } else {
          dispatch(fetchTnCDataError(data?.data));
        }
      })
      .catch((err) => {
        dispatch(fetchTnCDataError(err));
      });
  };
};
