import {
  FETCH_USER_WITH_TEAM_REQUEST,
  FETCH_USER_WITH_TEAM_SUCCESS,
  FETCH_USER_WITH_TEAM_FAILURE,
} from "Redux/Actions/user/userWithTeamDataAction";

import { userInitialState } from "Redux/InitialState";

export const userInfoWithTeamDataReducer = (state = { ...userInitialState }, action) => {
  switch (action.type) {
    case FETCH_USER_WITH_TEAM_REQUEST:
      return {
        ...state,
        data: action.data,
        loading: true,
        error: false,
      };

    case FETCH_USER_WITH_TEAM_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
        error: false,
      };

    case FETCH_USER_WITH_TEAM_FAILURE:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: true,
      };

    default:
      return state;
  }
};
