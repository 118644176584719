import React, { forwardRef, useContext, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { Button as AntButton } from "antd";
import { Banner, InfoFilled, Typography, Button, EditOutline } from "@spenmo/splice";

import Icon from "Modules/icons";

import { useMutableData } from "API/useData";

import Select, { Option } from "Views/Bills/V2/components/FormGenerator/Select";
import Preview from "Views/Bills/V2/components/Preview";

import { useDisclosure } from "Views/Bills/V2/hooks/useDisclosure";
import { API_URL } from "Views/Bills/V2/constants";
import { getResponsePayload } from "Views/Bills/V2/utilities";
import { BillContext } from "Views/Bills/V2/context/BillContext";

import { dataTableNoRecordFound } from "assets/img";
import { useErrorHandler } from "Views/Bills/V2/context/ErrorHandlerContext";
import { transformPreviewRecipient } from "./helper";
import { GetOrgId, highlighter } from "utility";
import customStyles from "./RecipientSelect.module.scss";
import styles from "Views/Bills/V2/components/FormGenerator/FormGenerator.module.scss";

const setHighlighter = (text: string) => <span className={customStyles.highlight}>{text}</span>;

/**
 * This component is using react-hook-form.
 * Please use FormProvider before using this.
 * This component should NOT be copied to other page component
 * since it's using css from BillForm
 */
const RecipientSelect = forwardRef((props: any, ref) => {
  const { value, onChange, name, ...rest } = props;
  const {
    setIsOpenRecipientSidePanel,
    recipientSelectedID,
    setRecipientSelectedID,
    ocrData,
    setOCRData,
    setWithOCRData,
  } = useContext(BillContext);

  const [showDetail, setShowDetail] = useState(false);
  const [search, setSearch] = useState("");

  const { handleError } = useErrorHandler();

  const selectRef = useRef(null);
  useImperativeHandle(ref, () => selectRef.current);

  const { isOpen, toggle, onOpen, onClose } = useDisclosure();

  const { data, mutate: refetchList, error: listError } = useMutableData(API_URL.recipientList);

  const recipientList = useMemo(() => {
    const list = getResponsePayload<any[]>(data) || [];

    return list.map((item) => ({
      label: item.legalName,
      value: item.id,
    }));
  }, [data]);

  const { data: previewRecipient } = useMutableData(
    value ? `${API_URL.disbursementV2}/orgs/${GetOrgId()}/vendors/${value}` : null
  );

  const isOCRNewRecipient =
    Boolean(ocrData) && !ocrData.billData.vendorID && !ocrData.billData.isSameWithOCR && Boolean(ocrData.recipientData);

  useEffect(() => {
    if (listError) {
      handleError({
        retry: {
          id: "recipientList",
          onClickRetry: refetchList,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listError]);

  // onChange from recipientSelectedID
  useEffect(() => {
    if (recipientSelectedID) {
      handleOnChange(String(recipientSelectedID));
    }
  }, [recipientSelectedID]);

  // OCR: if a new recipient detected, set recipient field to
  useEffect(() => {
    if (isOCRNewRecipient) {
      handleOnChange(null);
      setRecipientSelectedID(undefined);
    }
  }, [isOCRNewRecipient]);

  const handleOnChange = (value: string | undefined) => {
    if (value !== undefined && value !== null) {
      onChange(Number(value));

      if (value && ocrData?.recipientData) {
        setOCRData({ ...ocrData, recipientData: undefined });
      }
    } else {
      onChange(null);
    }
  };

  const handleDropdownVisibleChange = (open) => {
    if (!open) {
      setSearch("");
    }
    toggle();
  };

  const handleNewRecipient = (withOCRData = false) => {
    setRecipientSelectedID(undefined);
    setWithOCRData(withOCRData);
    setIsOpenRecipientSidePanel(true);
    onClose();
  };

  const handleEdit = () => {
    setRecipientSelectedID(Number(value));
    setIsOpenRecipientSidePanel(true);
  };

  const renderNotFound = () => {
    return (
      <div className={styles.notFound}>
        <Icon src={dataTableNoRecordFound} alt="no result found" />
        <div className={styles.notFoundText}>
          {recipientList?.length
            ? `No recipient found`
            : "You do not have any saved recipients. Create a new recipient to retrieve them in this list for future payments."}
        </div>
      </div>
    );
  };

  const dropdownRecipientRender = (menu: React.ReactElement<any, string | React.JSXElementConstructor<any>>) => {
    const isRecipientExist = Boolean(recipientList?.find((item) => item.label.toLowerCase() === search.toLowerCase()));

    return (
      <>
        {menu}
        <AntButton className={styles.newSelectButton} disabled={isRecipientExist} onClick={() => handleNewRecipient()}>
          + New Recipient
        </AntButton>
      </>
    );
  };

  const recipientData = useMemo(() => {
    const renderActionBtn = (
      <Typography className={customStyles.editBtn} variant="body-content" size="s" tag="p">
        <Typography variant="body-content" size="s">
          <EditOutline size="16" iconColor="var(--text-link-default)" />
        </Typography>
        Edit
      </Typography>
    );
    if (previewRecipient?.data?.payload) {
      return transformPreviewRecipient(previewRecipient.data.payload, renderActionBtn, handleEdit);
    }

    return;
  }, [previewRecipient]);

  return (
    <div className={customStyles.wrapper}>
      {Boolean(value) && (
        <div className={customStyles.actions} tabIndex={0} role="button" onClick={() => setShowDetail(!showDetail)}>
          {showDetail ? "Hide details" : "View details"}
        </div>
      )}
      <Select
        showSearch
        open={isOpen}
        ref={selectRef}
        onSearch={setSearch}
        onDropdownVisibleChange={handleDropdownVisibleChange}
        filterOption={(value, elem: any) => {
          if (elem?.label) {
            return elem.label.toLowerCase().indexOf(value.toLowerCase()) !== -1;
          }
        }}
        optionFilterProp="label"
        notFoundContent={renderNotFound()}
        dropdownRender={dropdownRecipientRender}
        placeholder="Select recipient"
        {...rest}
        // We need a custom value and setter since the state is used in multiple forms.
        // Therefore, we prefer using the context instead of the value from useForm.
        onChange={handleOnChange}
        value={value}
      >
        {recipientList.map((item, index) => {
          const { label, value } = item;
          const highlightedLabel = highlighter(label, search, setHighlighter);

          return (
            <Option key={`${value}-${index}`} label={label} value={value}>
              {highlightedLabel}
            </Option>
          );
        })}
      </Select>
      {showDetail && recipientData && <Preview className={customStyles.preview} data={recipientData} />}
      {isOCRNewRecipient && (
        <div className={customStyles.infoPanel}>
          <Banner
            icon={InfoFilled}
            variant="warning"
            title="Our system detects a new recipient"
            description={
              <div className={customStyles.infoBody}>
                <Typography variant="body-content" size="m" tag="p">
                  Create a new recipient?
                </Typography>
                <div className={customStyles.btnGroup}>
                  <Button variant="secondary" size="s" type="button" onClick={onOpen}>
                    <b>No -</b> Find in Spenmo
                  </Button>
                  <Button variant="primary" size="s" type="button" onClick={() => handleNewRecipient(true)}>
                    <b>Yes -</b> Add to Spenmo
                  </Button>
                </div>
              </div>
            }
          />
        </div>
      )}
    </div>
  );
});

export default RecipientSelect;
