import React, { useEffect, useContext } from "react";

import WidgetHeader from "Views/SpenmoSupport/WidgetHeader";
import { SpenmoSupportContext, TARGET_ID } from "Views/SpenmoSupport/common";

import { PopupItem } from "./Components";

import { chatArticleIcon, chatIcon } from "assets/img";
import "./styles.scss";

const SupportPopup = () => {
  const { toggleSupport, toggleSupportAndChat, showSupportPopup, setShowSupportPopup, shifting, supportPopupRef } =
    useContext(SpenmoSupportContext);

  useEffect(() => {
    const checkIfClickedOutside = (e) =>
      showSupportPopup && !supportPopupRef.current?.contains(e.target) && setShowSupportPopup(false);

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [supportPopupRef, showSupportPopup, setShowSupportPopup]);

  return (
    showSupportPopup && (
      <div
        id="zendeskChatWidget"
        className={`chat-widget ${shifting ? "shift-support" : "shift-support-exit"}`}
        ref={supportPopupRef}
      >
        <WidgetHeader title="Spenmo Support" close={toggleSupport} />
        <a
          id={TARGET_ID.FAQ}
          onClick={toggleSupport}
          href="https://support.spenmo.com/hc/en-us"
          rel="noopener noreferrer"
          target="_blank"
          className="chat-widget__button"
        >
          <PopupItem targetId={TARGET_ID.FAQ} title="Search our FAQs" imageUrl={chatArticleIcon} alt="article" />
        </a>
        <div id={TARGET_ID.SEND_MESSAGE} onClick={toggleSupportAndChat} className="chat-widget__button">
          <PopupItem targetId={TARGET_ID.SEND_MESSAGE} title="Send us a message" imageUrl={chatIcon} alt="chat" />
        </div>
      </div>
    )
  );
};

export default SupportPopup;
