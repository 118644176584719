import * as React from "react";
import { Modal } from "antd";
import Button from "Modules/button";
import { modalCloseIcon } from "assets/img";

import styles from "Views/Card/Details/SubInfo/StatusToggle/index.module.scss";

interface IDisableModalProps {
  onClose: () => void;
  onDisableCard: () => void;
}

const DisableModal: React.FunctionComponent<IDisableModalProps> = ({ onClose, onDisableCard }) => (
  <Modal
    visible
    onCancel={onClose}
    className={styles["disable-modal"]}
    title="Disable Card"
    footer={null}
    closeIcon={<img src={modalCloseIcon} alt="close icon" />}
    centered
  >
    <div className={styles["disable-modal-container"]}>
      <div className={styles["info-header"]}>Are you sure you want to disable this card?</div>
      <div className={styles["info"]}>You will need the Budget Owner to re-enable the card again.</div>
      <div className={styles["disable-btn-container"]}>
        <Button
          className={styles["disable-btn"]}
          text="Disable Card"
          rounded
          action={onDisableCard}
          testid="disable-card"
        />
      </div>
    </div>
  </Modal>
);

export default DisableModal;
