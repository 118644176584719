import APIclient from "API/Client";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import { setCookie, cookieNames, removeCookie } from "utility/CookieHelper";
import { FETCH_FORGOT_PASSWORD_LIST, ForgotPasswordData } from "./types";
import { getPreliminaryData } from "Redux/Actions/loginAction";

export const fetchForgotPasswordRequest = (): { type: FETCH_FORGOT_PASSWORD_LIST.REQUEST } => {
  return {
    type: FETCH_FORGOT_PASSWORD_LIST.REQUEST,
  };
};

export const fetchForgotPasswordSuccess = (
  data: ForgotPasswordData
): {
  type: FETCH_FORGOT_PASSWORD_LIST.SUCCESS;
  data: ForgotPasswordData;
} => {
  return {
    type: FETCH_FORGOT_PASSWORD_LIST.SUCCESS,
    data: data,
  };
};

export const fetchForgotPasswordError = (
  data: ForgotPasswordData
): {
  type: FETCH_FORGOT_PASSWORD_LIST.ERROR;
  data: ForgotPasswordData;
} => {
  return {
    type: FETCH_FORGOT_PASSWORD_LIST.ERROR,
    data: data,
  };
};

export const resetVerifyForgotData = (): {
  type: FETCH_FORGOT_PASSWORD_LIST.RESET;
} => {
  return {
    type: FETCH_FORGOT_PASSWORD_LIST.RESET,
  };
};

export const VerifyForgotPassword = (password, token: string) => {
  return (dispatch) => {
    dispatch(fetchForgotPasswordRequest());
    APIclient.postData(`/ms/b2b-auth/v2/auth/forgot-password/verify?t=${token}`, { new_password: password })
      .then((data: { [propName: string]: any }) => {
        if (data?.status === HTTP_STATUS_CODE.OK) {
          setCookie(cookieNames.CHANGE_PASSWORD_SUCCESS, true);
          setCookie(cookieNames.CHANGE_PASSWORD_MESSAGE, data?.data?.data);
          dispatch(fetchForgotPasswordSuccess(data?.data?.data));
        } else {
          dispatch(fetchForgotPasswordError(data?.data?.data));
        }
      })
      .catch((error) => {
        dispatch(fetchForgotPasswordError(error?.response?.data?.errors || error?.message));
      });
  };
};

export const forceChangePassword = (data: { new_password: string; force_reset_token: string }) => {
  const url = "/ms/b2b-auth/v2/auth/force-change-password";
  return (dispatch) => {
    dispatch(fetchForgotPasswordRequest());
    APIclient.postData(url, { ...data })
      .then(async (data: { [propName: string]: any }) => {
        if (data?.status === HTTP_STATUS_CODE.OK) {
          setCookie(cookieNames.FORCE_RESET_SUCCESS, true);
          removeCookie(cookieNames.FORCE_RESET_TOKEN);
          removeCookie(cookieNames.IS_FORCE_RESET_NEEDED);
          await getPreliminaryData(dispatch);
          dispatch(fetchForgotPasswordSuccess(data?.data?.data));
        } else {
          setCookie(cookieNames.FORCE_RESET_ERROR, true);
          removeCookie(cookieNames.FORCE_RESET_TOKEN);
          removeCookie(cookieNames.IS_FORCE_RESET_NEEDED);
          dispatch(fetchForgotPasswordError(data?.data?.data));
        }
      })
      .catch((error) => {
        setCookie(cookieNames.FORCE_RESET_ERROR, true);
        dispatch(fetchForgotPasswordError(error?.response?.data?.errors || error?.message));
      });
  };
};
