import React, { useContext, useState } from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";

import Button from "Modules/button";
import Icon, { IconList } from "Modules/Icon";
import RichHeader from "Modules/DS/RichHeader";
import Checkbox from "Modules/DS/Atoms/Checkbox";
import { Button as IButton } from "@spenmo/splice";

import { useSetUserConsent } from "Views/KYX/hooks";
import { KYXModalContext } from "Views/KYX/Context";
import { KYXFormSubmitLoader as KYXLoader } from "Views/KYX/Modal/Submit";
import { CLIENT_AGREEMENT_TYPE, ClientAgreementData } from "Views/KYX/Constants/ClientAgreements";
import { UpdateClientAgreementData } from "Views/KYX/Constants/UpdateClientAgreements";

import styles from "./index.module.scss";

interface IClientAgreementModal {
  onClose?: () => void;
  isUpdateAgreement?: boolean;
}

const ClientAgreementModal: React.FC<IClientAgreementModal> = ({
  onClose,
  isUpdateAgreement = false,
}): React.ReactElement => {
  const [isAgreementChecked, setIsAgreementChecked] = useState(false);
  const { org_name = "", org_country_code = "" } =
    useSelector((state: any) => state.preliminaryDataReducer?.data?.payload) || {};

  const { resetModal } = useContext(KYXModalContext);
  const orgCountryCode = CLIENT_AGREEMENT_TYPE?.[org_country_code];

  const details = isUpdateAgreement ? UpdateClientAgreementData : ClientAgreementData;
  const clientAgreements = details?.[orgCountryCode] || details[CLIENT_AGREEMENT_TYPE.DEFAULT];

  const { loading, agreeAgreements } = useSetUserConsent(() => {
    resetModal();
    onClose?.();
  });

  const handleGetLink = (): React.ReactElement[] => {
    return clientAgreements?.list?.map((item) => (
      <a key={item.name} className={styles.card} href={item.link} target="_blank" rel="noreferrer">
        <Icon
          className={styles.fileIcon}
          svgAttr={{ width: 16, height: 20, viewBox: "0 0 16 20" }}
          icon={IconList.file}
        />
        <p>{item.name}</p>
        <Icon className={styles.externalIcon} icon={IconList.external} />
      </a>
    ));
  };

  if (loading) {
    return <KYXLoader open />;
  }

  return (
    <div className={classNames([styles.modal, styles.wrapper])}>
      <RichHeader title={clientAgreements.title} />
      <div className={styles.content}>
        {!isUpdateAgreement && <h4 className={styles.title}>Hi {org_name},</h4>}
        <p className={styles.info}>{clientAgreements.description}</p>
        {handleGetLink()}
        <div className={classNames(styles.checkInfo, { [styles.onboarding1]: isUpdateAgreement })}>
          <Checkbox checked={isAgreementChecked} onClick={() => setIsAgreementChecked(!isAgreementChecked)} />
          {clientAgreements.confirmationMessage}
        </div>
        {!isUpdateAgreement ? (
          <div className={styles.actionBtns}>
            <Button className={styles.button} rounded text="Cancel" action={resetModal} />
            <Button
              className={styles.button}
              action={agreeAgreements}
              disabled={!isAgreementChecked}
              rounded
              text="Agree"
            />
          </div>
        ) : (
          <IButton
            size="l"
            className={styles.done}
            onClick={agreeAgreements}
            disabled={!isAgreementChecked}
            variant="primary"
          >
            {clientAgreements?.buttonTitle}
          </IButton>
        )}
      </div>
    </div>
  );
};

export default ClientAgreementModal;
