import {
  FETCH_XERO_AUTH_REQUEST,
  FETCH_XERO_AUTH_SUCCESS,
  FETCH_XERO_AUTH_FAILURE,
  FETCH_XERO_DISCONNECT_REQUEST,
  FETCH_XERO_DISCONNECT_SUCCESS,
  FETCH_XERO_DISCONNECT_FAILURE,
  RESET_XERO_DATA,
} from "../Actions/xeroAuth";
import { userInitialState } from "../InitialState";

export const accountingPartnerAuthReducer = (state = { ...userInitialState, loading: true }, action) => {
  switch (action.type) {
    case FETCH_XERO_AUTH_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case FETCH_XERO_AUTH_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
        error: false,
      };
    case FETCH_XERO_AUTH_FAILURE:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: true,
      };
    case FETCH_XERO_DISCONNECT_REQUEST:
      return {
        ...state,
        disconnectData: action.data,
        disconnectLoading: true,
        disconnectError: false,
      };
    case FETCH_XERO_DISCONNECT_SUCCESS:
      return {
        ...state,
        disconnectData: action.data,
        disconnectLoading: false,
        disconnectError: false,
      };
    case FETCH_XERO_DISCONNECT_FAILURE:
      return {
        ...state,
        disconnectLoading: false,
        disconnectData: action.data,
        disconnectError: true,
      };
    case RESET_XERO_DATA:
      return {
        ...state,
        data: null,
      };
    default:
      return state;
  }
};
