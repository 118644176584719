import { getCookieValue, cookieNames } from "utility/CookieHelper";
import { USER_ROLE } from "constants/Team.constant";

export const useCheckApprovalFlowAllowed = (userInfo) => {
  const isAccountant = getCookieValue(cookieNames.USER_COMPANY_ROLE) === USER_ROLE.ACCOUNTANT;

  return userInfo?.isAdmin || isAccountant;
};

export const useCheckApprovalFlowLoader = (userInfo) => {
  return userInfo?.loading;
};
