import React, { useContext, createContext, useEffect, useState } from "react";
import { Form } from "antd";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { FormFinishInfo } from "rc-field-form/lib/FormContext";

import { cookieNames, getCookieValue, setCookie } from "utility/CookieHelper";

import { COMPANY_VERIFICATION } from "Route/KYX/paths";

import { SelectProvider } from "Modules/DS/Select";
import { TOASTER_SIZE_TYPE, TOASTER_STATUS_TYPE } from "Modules/DS/Toaster";

import {
  IKYXToastContext,
  ISubmitKYXRequest,
  ISaveAsDraftRequest,
  IKYXRemarkContextType,
  IFetchFormSubmissionPayload,
  FORM_SOURCE_TYPE,
} from "Views/KYX/@types";
import {
  useGetFormConfigs,
  useGetUserConsent,
  useGetKYXFormData,
  useFormSaveAsDraft,
  useSubmitKYXFormData,
  useStepChangeHandler,
} from "Views/KYX/hooks";
import { KYXFormSubmitLoader } from "Views/KYX/Modal/Submit";
import { filterUpdatedRemark, removeFalsyValues } from "Views/KYX/Helper";
import { KYXModalContext, KYXToastContext, KYXRemarkContext } from "Views/KYX/Context";
import { FORM_NAME, KYB_STATUS, KYX_MODAL_TYPES, PROGRESS_FOOTER_ACTION, PROGRESS_TABS } from "Views/KYX/Constants";

import { ProgressTabs as Tabs } from "./Tab";

import "./index.scss";

export const LoadingContext = createContext({ loading: false });

const KYXProgress = () => {
  const kybStatus = getCookieValue(cookieNames.KYB_STATUS);
  const { activeTabIndex, handleStepChange } = useStepChangeHandler();

  const activeTab = PROGRESS_TABS[activeTabIndex || 0];

  const { formRemarks, setFormRemarks }: IKYXRemarkContextType = useContext(KYXRemarkContext);
  const [formData, setFormData] = useState<IFetchFormSubmissionPayload>(undefined);
  const { getRemarks } = useGetFormConfigs(setFormRemarks);
  const { getFormData } = useGetKYXFormData(setFormData);
  const { modal } = useContext(KYXModalContext);
  const history = useHistory();
  const { setToaster } = useContext<IKYXToastContext>(KYXToastContext);

  const redirect = () => history.replace(COMPANY_VERIFICATION.SUBMITTED);
  useEffect(() => {
    getFormData();
    kybStatus === KYB_STATUS.ADDITIONAL_DOCUMENTS_REQUIRED && getRemarks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { loading: submitFormLoader, submitFormData } = useSubmitKYXFormData(() => {
    setCookie(cookieNames.KYB_STATUS, KYB_STATUS.SUBMITTED);
    redirect();
  });
  const { loading: saveAsDraftLoader, saveFormAsDraft } = useFormSaveAsDraft(() =>
    handleStepChange(PROGRESS_FOOTER_ACTION.NEXT)
  );
  const checkForConsentAndSubmit = (formData: ISubmitKYXRequest, showConsentPopup: boolean) => {
    getConsent().then((response) => {
      if (response.isAgreedLatestTnC) {
        submitFormData(formData);
      } else if (showConsentPopup) {
        saveFormAsDraft(formData);
        handleStepChange(PROGRESS_FOOTER_ACTION.NEXT);
      }
    });
  };
  const [modalName, setModalName] = useState(modal.type);
  useEffect(() => {
    if (modal?.visible === false && modalName === KYX_MODAL_TYPES.CLIENT_AGREEMENT) {
      checkForConsentAndSubmit({ ...formData, id: formData.form_id } as ISubmitKYXRequest, false);
    }
    setModalName(modal.type);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal]);

  const formatFormData = (name: string, data: FormFinishInfo): IFetchFormSubmissionPayload => {
    const form = formData;
    const persons = data.values.persons
      ? Object.keys(data.values.persons).map((key) => {
          data.values.persons[key].identification_document.is_same_residential_address = data?.values?.persons?.[key]
            ?.identification_document?.is_same_residential_address?.length
            ? data.values.persons[key].identification_document?.is_same_residential_address?.[0] === "checked"
            : false;
          return data.values.persons[key];
        })
      : [];

    const isBusinessAddressDifferent = data?.values?.company_information?.company_address?.is_business_address_different
      ? data?.values?.company_information?.company_address?.is_business_address_different[0] === "true"
      : false;

    const doesntHavePublicWebsite = data?.values?.company_information?.additional_information
      ?.doesnt_have_public_website
      ? data?.values?.company_information?.additional_information?.doesnt_have_public_website?.[0] === "true"
      : false;

    switch (name) {
      case FORM_NAME.COMPANY_INFORMATION:
        // TODO: Need to generalize how this is happening
        form.form.company_information.general_information = data.values.company_information.general_information;
        form.form.company_information.general_information.date_of_incorporation = (
          data.values.company_information.general_information.date_of_incorporation as moment.Moment
        ).toISOString();

        form.form.company_information.company_address = data.values.company_information.company_address;
        form.form.company_information.company_address.is_business_address_different = isBusinessAddressDifferent;
        form.form.company_information.additional_information.company_website =
          data.values.company_information.additional_information.company_website;
        form.form.company_information.additional_information.doesnt_have_public_website = doesntHavePublicWebsite;
        const socialMediaObject = removeFalsyValues(
          data.values?.company_information?.additional_information?.social_media || {}
        );
        form.form.company_information.additional_information.social_media = Object.keys(socialMediaObject).map(
          (item) => {
            return {
              type: socialMediaObject?.[item]?.type,
              url: socialMediaObject?.[item]?.url,
            };
          }
        );

        break;
      case FORM_NAME.COMPANY_DOCUMENTS:
        form.form.company_document.arca_document = data.values.company_document.arca_document;
        form.form.company_document.arca_document.expiry_date = moment
          .utc(data.values?.company_document?.expiry_date)
          .format();
        form.form.company_document.memorandum_file = data.values.company_document.memorandum_file;
        form.form.company_document.ownership_chart_file = data.values.company_document.ownership_chart_file;
        form.form.company_document.ownership_number_of_levels = data.values.company_document.ownership_number_of_levels;
        form.form.company_document.is_diligence_document_required =
          data.values.company_document.is_diligence_document_required;
        form.form.company_document.due_diligence_documents = data.values.company_document.due_diligence_documents;
        break;
      case FORM_NAME.PERSONNEL_INFORMATION:
        form.form.personnel_information.has_only_director = data.values.has_only_director;
        form.form.personnel_information.number_of_beneficial_owners = data.values.number_of_beneficial_owners;
        form.form.personnel_information.persons = persons?.map((person) => {
          return {
            ...person,
            date_of_birth: moment.utc(person?.date_of_birth).format(),
            roles_of_individual: person?.roles_of_individual
              ? Object.assign(
                  {},
                  ...person?.roles_of_individual?.map((role: any) => {
                    return role ? { [role]: true } : null;
                  })
                )
              : {},
          };
        });

        break;
      default:
        break;
    }
    if (name !== FORM_NAME.PERSONNEL_INFORMATION) {
      return { ...form };
    } else {
      let directorCount: number = 0;
      form.form.personnel_information.persons.forEach((person) => {
        if (person.roles_of_individual.is_director) {
          directorCount += 1;
        }
      });
      let isFormValid: boolean;
      let message: string;
      const isFormSourceMyInfo = formData?.source === FORM_SOURCE_TYPE.MY_INFO;
      if (!isFormSourceMyInfo) {
        if (form.form.personnel_information.has_only_director) {
          isFormValid = directorCount === 1;
          if (!isFormValid) {
            message =
              "You can only declare one Director. Please check the selected number of directors stated in Personnel Information";
          }
        } else {
          isFormValid = directorCount > 1;
          message = "You are required to declare at least two Directors";
        }
      }
      if (isFormValid || isFormSourceMyInfo) {
        return { ...form };
      } else {
        setToaster({ message, visible: true, status: TOASTER_STATUS_TYPE.ERROR, size: TOASTER_SIZE_TYPE.S });
        return undefined;
      }
    }
  };

  const onFormChange = (formName: string, info): void => {
    if (kybStatus === KYB_STATUS.ADDITIONAL_DOCUMENTS_REQUIRED && formName !== FORM_NAME.PERSONNEL_INFORMATION) {
      const fieldName: string[] = info?.changedFields?.[0]?.name || [];
      if (!fieldName.includes(formName)) fieldName.unshift(formName);

      const filteredRemarks = filterUpdatedRemark(formRemarks, fieldName);
      return setFormRemarks({ ...filteredRemarks });
    }
  };

  const { loading: userConsentLoader, getConsent } = useGetUserConsent();
  const handleFormFinish = (name: string, info: FormFinishInfo) => {
    const formattedFormData = formatFormData(name, info);
    if (formattedFormData) {
      if (activeTabIndex < PROGRESS_TABS.length - 1) {
        saveFormAsDraft({ ...formattedFormData, id: formattedFormData.form_id } as ISaveAsDraftRequest);
      } else {
        checkForConsentAndSubmit({ ...formattedFormData, id: formattedFormData.form_id }, true);
      }
    }
  };

  return (
    <SelectProvider>
      <Form.Provider onFormChange={onFormChange} onFormFinish={handleFormFinish}>
        <KYXFormSubmitLoader open={submitFormLoader || userConsentLoader} />
        <div className="kyc-comp">
          <LoadingContext.Provider value={{ loading: saveAsDraftLoader }}>
            <Tabs activeTab={activeTab} />
          </LoadingContext.Provider>
        </div>
      </Form.Provider>
    </SelectProvider>
  );
};

export default KYXProgress;
