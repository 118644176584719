import * as React from "react";
import { Skeleton } from "antd";

import styles from "./index.module.scss";

const MerchantLockShimmer = () => {
  return <Skeleton.Input className={styles.shimmer} active />;
};

export default MerchantLockShimmer;
