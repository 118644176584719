import React, { useEffect } from "react";
import { Redirect, Route, useHistory, useParams, useRouteMatch } from "react-router-dom";
import { usePermission } from "Views/SubscriptionManagement/hooks/usePermission";
import { FORM_TYPE } from "Views/SubscriptionManagement/Constants";
import { Toast } from "@spenmo/splice";

import LoadingComponent from "Views/State/Loading/LoaderIcon";
import { Create } from "Views/SubscriptionManagement/Create";
import { SubscriptionContext } from "Views/SubscriptionManagement/Details/SubscriptionContext";
import { FallbackScreen } from "Modules/FallbackScreen";
import { useMutableData } from "API/useData";
import { getInitialValuesForEdit } from "Views/SubscriptionManagement/Helper";
import { SUBSCRIPTION_PATH } from "Route/Subscription/path";
import { UPDATE_SUBSCRIPTION_PARAMS } from "Views/SubscriptionManagement/Permission";
import { VendorImage } from "Views/SubscriptionManagement/Components/VendorImage";
import usePermissionCheck from "Permission/usePermissionCheck";
import { trackEvent } from "utility/analytics";

import { Header } from "./Header";
import { Summary } from "./Summary";
import { ExpiryBanner } from "./ExpiryBanner";
import { SpendAnalysis } from "./SpendAnalysis";
import { SUBSCRIPTION_EVENT } from "../Analytic";

import styles from "./index.module.scss";

const SubscriptionDetails = () => {
  const { loading, allowedToOpenPage } = usePermission();
  const IS_ALLOWED_TO_UPDATE_SUBSCRIPTION = (usePermissionCheck(UPDATE_SUBSCRIPTION_PARAMS) as boolean) || false;
  const { path } = useRouteMatch();
  const { id } = useParams<{ id: string }>();
  const { data, isLoading, isValidating, error, mutate } = useMutableData(
    `ms/spm-organisation/v1/subscriptions/${id}`,
    {
      fallbackData: {},
    }
  );
  const subscription = data.data?.payload || {};
  const history = useHistory();

  useEffect(() => {
    trackEvent(SUBSCRIPTION_EVENT.ACCESS_SUBSCRIPTION_DETAILS_PAGE);
  }, []);

  if (loading || isLoading || isValidating) {
    return <LoadingComponent />;
  }

  if (error) {
    return <FallbackScreen />;
  }

  if (!allowedToOpenPage) {
    return <Redirect to="/404" />;
  }

  const onSuccessFullUpdate = () => {
    mutate();
    history.push(SUBSCRIPTION_PATH.SUBSCRIPTON_DETAILS.replace(":id", id));
  };

  return (
    <SubscriptionContext.Provider value={{ subscription, mutate }}>
      <section className={styles.container}>
        <Header
          vendorIcon={<VendorImage additionalMargin vendor={subscription.vendor.name} />}
          id={id}
          isAllowedToUpdateSubscription={IS_ALLOWED_TO_UPDATE_SUBSCRIPTION}
        />
        <div className={styles.detailsContainer}>
          <Summary />
          <div className={styles.flexGrowth}>
            <ExpiryBanner />
            <SpendAnalysis />
          </div>
        </div>
        {IS_ALLOWED_TO_UPDATE_SUBSCRIPTION && (
          <Route path={`${path}/edit`}>
            <Create
              onClose={() => history.push(SUBSCRIPTION_PATH.SUBSCRIPTON_DETAILS.replace(":id", id))}
              formType={FORM_TYPE.EDIT}
              initialValue={getInitialValuesForEdit(subscription)}
              id={id}
              onUpdate={onSuccessFullUpdate}
            />
          </Route>
        )}
        <Toast />
      </section>
    </SubscriptionContext.Provider>
  );
};

export default SubscriptionDetails;
