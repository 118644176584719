import React from "react";
import classNames from "classnames";
import { SPENDING_LOCK_TYPES } from "Views/Card/types";
import MerchantSpendingLock from "Views/Card/Components/SpendingLock/Merchant";
import CategorySpendingLock, { ICategoryItem } from "Views/Card/Components/SpendingLock/Category";
import { SPLIT_NAMES, SPLIT_TREATMENT_TYPES } from "Redux/splitio/constants";
import useCheckFeatureStatus from "customHooks/featureCheck";
import styles from "./index.module.scss";
interface IAddSpendingLock {
  selectedSpendingLockType: SPENDING_LOCK_TYPES;
  toggleLockType: () => void;
  spendingLockType: SPENDING_LOCK_TYPES;
  spendingLocks: ICategoryItem[];
  updateSpendingLocks: (spendingLocks) => void;
}

const AddSpendingLock = ({
  selectedSpendingLockType,
  toggleLockType,
  spendingLockType,
  spendingLocks,
  updateSpendingLocks,
}: IAddSpendingLock) => {
  const switchLockType = selectedSpendingLockType === SPENDING_LOCK_TYPES.CATEGORY ? "Merchant" : "Category";
  const lockType =
    selectedSpendingLockType === SPENDING_LOCK_TYPES.CATEGORY ? "Set Up Category Lock" : "Set Up Merchant Lock";
  const isCategoryLockTreatmentOn = useCheckFeatureStatus(SPLIT_NAMES.categoryLock) === SPLIT_TREATMENT_TYPES.ON;

  const onChange = (userSelectedLocks) => {
    let selectedLocks = [];
    if (selectedSpendingLockType === SPENDING_LOCK_TYPES.MERCHANT) {
      selectedLocks = userSelectedLocks?.map((merchant) => ({
        id: merchant,
        name: merchant,
      }));
    } else {
      selectedLocks = userSelectedLocks?.map((lock) => ({
        id: lock?.id,
        name: lock?.name,
      }));
    }
    updateSpendingLocks(selectedLocks);
  };

  const titleClassName = classNames(styles.lockTitle, {
    [styles.marginBottom12]: !isCategoryLockTreatmentOn,
  });

  return (
    <div>
      <p className={titleClassName}>{lockType} </p>
      {isCategoryLockTreatmentOn && (
        <button onClick={toggleLockType} className={styles.cancelBtn} data-testid="switch-button">
          Cancel and switch to {switchLockType} Lock
        </button>
      )}

      <div className={styles.listContainer}>
        {selectedSpendingLockType === SPENDING_LOCK_TYPES.CATEGORY ? (
          <CategorySpendingLock
            onChange={onChange}
            initialSpendingLocks={
              (spendingLockType === SPENDING_LOCK_TYPES.CATEGORY &&
                spendingLocks?.map((lock: ICategoryItem) => ({ name: lock?.name, id: lock?.id }))) ||
              []
            }
            totalShimmerToRender={5}
          />
        ) : (
          <MerchantSpendingLock
            onChange={onChange}
            initialSpendingLocks={
              (spendingLockType === SPENDING_LOCK_TYPES.MERCHANT &&
                spendingLocks?.map((lock: ICategoryItem) => lock?.name)) ||
              []
            }
            totalShimmerToRender={10}
          />
        )}
      </div>
    </div>
  );
};

export default AddSpendingLock;
