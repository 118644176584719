import React from "react";
import PropTypes from "prop-types";
import { currencyFormatter, dateTimeFormatter, roundValue } from "../../../utility";
import TableRowField from "../../../Modules/tableRowField";
import "../TransactionDetails/TransactionDetails.scss";

const TransferDetails = ({ data }) => {
  const dt = dateTimeFormatter(data.created_at);

  return (
    <div className={"transaction-details"}>
      <TableRowField title={"Fund Transfer"} details={`${dt.date} | ${dt.time}`} isBold isSubHeader />
      <div className={"transaction-details__details"}>
        <TableRowField title={currencyFormatter(roundValue(data.amount), data.currency_code)} isBold />
      </div>
      <TableRowField title={"Trx No."} details={data.transaction_number || "N/A"} />
      <div className={"transaction-details__details"}>
        <TableRowField title={"Reference"} details={data.merchant} />
        <TableRowField title={"Card no."} details={data.card_last_four || "N/A"} />
      </div>
      <div className={"transaction-details__details"}>
        <TableRowField title={"Foreign Currency Code"} details={data.foreign_currency_code || "N/A"} />
        <TableRowField title={"Foreign Currency Amount"} details={data.foreign_currency_amount || "N/A"} />
      </div>
      <TableRowField title={"Description"} details={data.description} />
    </div>
  );
};

TransferDetails.propTypes = {
  data: PropTypes.object,
};

export default TransferDetails;
