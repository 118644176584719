import React from "react";

import styles from "./BarChart.module.scss";

interface CategoryCardProps {
  color: string;
  name: string;
  amount: string;
  others?: Array<{
    categoryName: "";
    amount: 0;
    color: "";
  }>;
}

export function CategoryCard({ color, name, amount, others }: CategoryCardProps) {
  return (
    <>
      <div className={styles.categoryCard}>
        <div style={{ backgroundColor: color }} />
        <div>
          <p>{name}</p>
          <p className={styles.amount}>{amount}</p>
        </div>
      </div>
    </>
  );
}
