import React from "react";

import { ITrxnTableEmptyState } from "Views/Transaction/@types";
import { Button, Typography } from "@spenmo/splice";
import { emptyDefaultIcon } from "assets/img";
import styles from "./index.module.scss";

export const EmptyState = ({
  title = "No Transactions Recorded",
  subtitle = "Your future transactions with Spenmo will appear here.",
  buttonProps,
}: ITrxnTableEmptyState) => {
  return (
    <div className={styles.container}>
      <img src={emptyDefaultIcon} alt="empty icon" />
      <Typography className={styles.title} size="xs" variant="headline-content">
        {title}
      </Typography>
      <Typography className={styles.subTitle} size="s" variant="body-content">
        {subtitle}
      </Typography>
      {buttonProps && buttonProps.text && (
        <Button className={styles.button} onClick={buttonProps.onClick} size="m" variant="primary">
          {buttonProps.text}
        </Button>
      )}
    </div>
  );
};
