import React from "react";
import { InView } from "react-intersection-observer";

import { IAuditLog } from "Views/SubscriptionManagement/@types";
import { useAuditLogs } from "Views/SubscriptionManagement/hooks/useActivityLog";
import { useSubscriptionContext } from "Views/SubscriptionManagement/Details/SubscriptionContext";
import { mockAuditLog } from "Views/SubscriptionManagement/mock";

import DataTable, { DATA_TABLE_PADDING, DATA_TABLE_SIZE_TYPES, ITableProps, Table } from "Modules/DS/DataTable";
import Columns from "./Columns";

import styles from "./index.module.scss";

//TODO: rename folder to AuditLog
export const ActivityLog = (): JSX.Element => {
  const { subscription } = useSubscriptionContext();
  const {
    id,
    vendor: { name },
  } = subscription || {};
  const { auditLogs, isLoading, hasMoreData, setSize } = useAuditLogs(id);

  const tableProps: ITableProps<IAuditLog> = {
    loading: false,
    dataSource: isLoading ? mockAuditLog : auditLogs,
    paginationEnabled: false,
    emptyState: "No Activites found for this subscription",
  };

  return (
    <DataTable size={DATA_TABLE_SIZE_TYPES.LARGE} padding={DATA_TABLE_PADDING.NONE}>
      <Table<IAuditLog> {...tableProps} className={styles.table}>
        {Columns(name, isLoading)}
      </Table>

      <InView
        onChange={(_, e) => {
          if (e.isIntersecting && hasMoreData) {
            setSize((size) => size + 1);
          }
        }}
      />
    </DataTable>
  );
};
