import { createAction, createReducer } from "@reduxjs/toolkit";
import { IDeleteTeamState } from "Views/Team/Delete/type";

const deleteTeamSuccess = createAction<object>("deleteTeamSuccess");
const deleteTeamError = createAction<void>("deleteTeamError");
const deleteTeamLoading = createAction<void>("deleteTeamLoading");
const deleteTeamReset = createAction<void>("deleteTeamReset");

const deleteTeamInitialState: IDeleteTeamState = {
  loading: false,
  error: false,
  data: {
    payload: {
      status_message: "",
    },
  },
};

const DeleteTeamReducer = createReducer(deleteTeamInitialState, (builder) => {
  builder
    .addCase(deleteTeamSuccess, (state) => {
      state.loading = false;
      state.error = false;
      state.data.payload.status_message = "OK";
    })
    .addCase(deleteTeamLoading, (state) => {
      state.loading = true;
      state.error = false;
      state.data.payload.status_message = "";
    })
    .addCase(deleteTeamError, (state) => {
      state.loading = false;
      state.error = true;
      state.data.payload.status_message = "";
    })
    .addCase(deleteTeamReset, (state) => {
      state.loading = false;
      state.error = false;
      state.data.payload.status_message = "";
    });
});

export { deleteTeamError, deleteTeamLoading, deleteTeamSuccess, deleteTeamReset, deleteTeamInitialState };

export default DeleteTeamReducer;
