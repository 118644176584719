import React from "react";
import { useTeamToasterContext } from "Views/Team/Toast/context";
import Toaster from "Modules/DS/Toaster";
import { TOASTER_SIZE_TYPE } from "Modules/DS/Toaster/types.d";

const TeamToaster = () => {
  const { toaster, setToaster } = useTeamToasterContext();

  return (
    <Toaster
      size={TOASTER_SIZE_TYPE.S}
      visible={toaster.visible}
      status={toaster.status}
      message={toaster.message}
      onClose={() => setToaster({ ...toaster, visible: false })}
    />
  );
};

export default TeamToaster;
