import React from "react";
import { Form } from "antd";

import { EInputType, ITransactionDetailProps, TransactionDetail } from "Modules/TransactionDetail";

import { IExpenseTrxnsDetails } from "Views/Transaction/@types";
import { includesReversalTrxns } from "Views/Transaction/Helper";
import { useExpenseForm } from "Views/Transaction/Details/useExpenseForm";

export const Details = ({
  taxId,
  notes,
  details,
  receipts,
  trxnId,
  trxnType,
  trxnNumber,
  categoryId,
  categoryName,
  closeDetails,
  handleSaveRef,
  tags,
  disableEditing = false,
  isHistoricalRefund,
}: IExpenseTrxnsDetails) => {
  const { form, buttonAction, defaultValue, setFormValue } = useExpenseForm(
    trxnNumber,
    closeDetails,
    receipts,
    notes,
    taxId,
    categoryId,
    trxnId,
    tags
  );

  handleSaveRef.current = buttonAction;

  const visibleInput = {
    [EInputType.TAX]: false,
    [EInputType.NOTES]: true,
    [EInputType.RECEIPT]: false,
    [EInputType.CATEGORY]: true,
    [EInputType.TRANSACTION_TAGS]: false,
  };

  let props: ITransactionDetailProps = {
    form,
    details,
    defaultValue,
    setFormValue,
    missingFields: defaultValue,
    disabled: disableEditing || includesReversalTrxns(trxnType),
    visibleInput: isHistoricalRefund ? {} : visibleInput,
    categoryFallback: categoryName,
  };

  return (
    <Form form={form}>
      <TransactionDetail {...props} ignoreRequiredCheck />
    </Form>
  );
};
