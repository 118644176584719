import {
  GET_CARD_OWNER_NAMES_FAILURE,
  GET_CARD_OWNER_NAMES_REQUEST,
  GET_CARD_OWNER_NAMES_SUCCESS,
  GET_CARD_REQUEST_OWNER_NAMES_FAILURE,
  GET_CARD_REQUEST_OWNER_NAMES_REQUEST,
  GET_CARD_REQUEST_OWNER_NAMES_SUCCESS,
} from "Redux/Actions/CardFilter/GetCardOwnerNames";
import { userInitialState } from "../../InitialState";

export const getCardOwnerNamesReducer = (state = userInitialState, action) => {
  switch (action.type) {
    case GET_CARD_OWNER_NAMES_REQUEST:
      return {
        ...state,
        data: action.data,
        loading: true,
        fetched: false,
      };
    case GET_CARD_OWNER_NAMES_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
        fetched: true,
      };
    case GET_CARD_OWNER_NAMES_FAILURE:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: true,
        fetched: true,
      };
    case GET_CARD_REQUEST_OWNER_NAMES_REQUEST:
      return {
        ...state,
        cardRequestOwnersData: action?.data,
        cardRequestOwnersError: false,
        cardRequestOwnersLoading: true,
      };
    case GET_CARD_REQUEST_OWNER_NAMES_SUCCESS:
      return {
        ...state,
        cardRequestOwnersData: action?.data,
        cardRequestOwnersError: false,
        cardRequestOwnersLoading: false,
      };
    case GET_CARD_REQUEST_OWNER_NAMES_FAILURE:
      return {
        ...state,
        cardRequestOwnersLoading: false,
        cardRequestOwnersData: action?.data,
        cardRequestOwnersError: true,
      };
    default:
      return state;
  }
};
