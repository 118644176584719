import React, { useState } from "react";
import { Quick } from "Modules/DS/Filter";
import { ISingleLine } from "Views/Accounting/Workflow/TransactionFilter";

interface ISortBy {
  sort: ISingleLine[];
  selectedSort: string;
  adjustFilter: (
    key: string | string[],
    value: ISingleLine | string[] | string | object,
    option?: { appliedFilter: boolean }
  ) => void;
}

const SortBy = ({ sort, selectedSort, adjustFilter }: ISortBy) => {
  const [title, setTitle] = useState("");

  return (
    <Quick.Single<ISingleLine>
      filterItemProps={{ id: "sort_by", containerClass: ".data-table-container" }}
      presetValue={"asd"}
      idKey="id"
      searchable={false}
      displayKey="title"
      defaultValue="Sort By:"
      states={{
        title: title,
        setTitle: (title) => {
          setTitle(`Sort By: ${title}`);
        },
        selected: selectedSort,
        setSelected: (value) => {
          adjustFilter("sort", value);
        },
      }}
      onSelect={(selected) => {
        adjustFilter("sort", selected);
      }}
      clearFilter={() => {
        adjustFilter("sort", "");
        setTitle("");
      }}
    >
      {sort}
    </Quick.Single>
  );
};

export default SortBy;
