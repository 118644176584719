import APIclient from "API/Client";
import { GetBaseAuthObject } from "utility";
import { GetFormData } from "utility/APIWrapper";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";

export const FETCH_XERO_INTEGRATION_DETAILS_REQUEST = "FETCH_ACCOUNTS_REQUEST";
export const FETCH_XERO_INTEGRATION_DETAILS_SUCCESS = "FETCH_XERO_INTEGRATION_DETAILS_SUCCESS";
export const FETCH_XERO_INTEGRATION_DETAILS_FAILURE = "FETCH_XERO_INTEGRATION_DETAILS_FAILURE";

export const FETCH_ACCOUNTS_REQUEST = "FETCH_ACCOUNTS_REQUEST";
export const FETCH_ACCOUNTS_SUCCESS = "FETCH_ACCOUNTS_SUCCESS";
export const FETCH_ACCOUNTS_FAILURE = "FETCH_ACCOUNTS_FAILURE";

export const FETCH_SELECT_ACCOUNT_REQUEST = "FETCH_SELECT_ACCOUNT_REQUEST";
export const FETCH_SELECT_ACCOUNT_SUCCESS = "FETCH_SELECT_ACCOUNT_SUCCESS";
export const FETCH_SELECT_ACCOUNT_FAILURE = "FETCH_SELECT_ACCOUNT_FAILURE";

export const FETCH_XERO_CATEGORIES_REQUEST = "FETCH_XERO_CATEGORIES_REQUEST";
export const FETCH_XERO_CATEGORIES_SUCCESS = "FETCH_XERO_CATEGORIES_SUCCESS";
export const FETCH_XERO_CATEGORIES_FAILURE = "FETCH_XERO_CATEGORIES_FAILURE";

export const SET_XERO_CATEGORIES_REQUEST = "SET_XERO_CATEGORIES_REQUEST";
export const SET_XERO_CATEGORIES_SUCCESS = "SET_XERO_CATEGORIES_SUCCESS";
export const SET_XERO_CATEGORIES_FAILURE = "SET_XERO_CATEGORIES_FAILURE";

const fetchXeroIntegrationDetailsReq = () => ({ type: FETCH_XERO_INTEGRATION_DETAILS_REQUEST });

const fetchXeroIntegrationDetailsSuccess = (data) => ({ type: FETCH_XERO_INTEGRATION_DETAILS_SUCCESS, data });

const fetchXeroIntegrationDetailsError = (data) => ({ type: FETCH_XERO_INTEGRATION_DETAILS_FAILURE, data });

const fetchDataReq = (data = []) => {
  return {
    type: FETCH_ACCOUNTS_REQUEST,
    data: data,
  };
};

const fetchDataSuccess = (data) => {
  return {
    type: FETCH_ACCOUNTS_SUCCESS,
    data: data,
  };
};

const fetchDataError = (data) => {
  return {
    type: FETCH_ACCOUNTS_FAILURE,
    data: data,
  };
};

const fetchSelectAccountReq = (data = []) => {
  return {
    type: FETCH_SELECT_ACCOUNT_REQUEST,
    data: data,
  };
};

const fetchSelectAccountSuccess = (data) => {
  return {
    type: FETCH_SELECT_ACCOUNT_SUCCESS,
    data: data,
  };
};

const fetchSelectAccountError = (data) => {
  return {
    type: FETCH_SELECT_ACCOUNT_FAILURE,
    data: data,
  };
};
const fetctXeroCategoriesReq = (data = []) => {
  return {
    type: FETCH_XERO_CATEGORIES_REQUEST,
    data: data,
  };
};

const fetchXeroCategoriesSuccess = (data) => {
  return {
    type: FETCH_XERO_CATEGORIES_SUCCESS,
    data: data,
  };
};

const fetchXeroCategoriesError = (data) => {
  return {
    type: FETCH_XERO_CATEGORIES_FAILURE,
    data: data,
  };
};

const setXeroCategoriesReq = (data = []) => {
  return {
    type: SET_XERO_CATEGORIES_REQUEST,
    data: data,
  };
};

const setXeroCategoriesSuccess = (data) => {
  return {
    type: SET_XERO_CATEGORIES_SUCCESS,
    data: data,
  };
};

const setXeroCategoriesError = (data) => {
  return {
    type: SET_XERO_CATEGORIES_FAILURE,
    data: data,
  };
};

// API to fetch bank accounts => "/ms/b2b-accounting/v1/xero/bank-accounts"
export const getXeroIntegrationDetails = () => {
  return (dispatch) => {
    dispatch(fetchXeroIntegrationDetailsReq());
    APIclient.getData("/ms/b2b-accounting/v2/partners/connections/details")
      .then((res) => {
        if (res.data.status === HTTP_STATUS_CODE.OK && res.data.payload) {
          dispatch(fetchXeroIntegrationDetailsSuccess(res.data));
        } else {
          dispatch(fetchXeroIntegrationDetailsError(res.data));
        }
      })
      .catch((err) => {
        dispatch(fetchXeroIntegrationDetailsError(err));
      });
  };
};

export const getPartnerAccount = ({ accountType = "bankaccount", page = 1, limit = 1000 } = {}) => {
  return (dispatch) => {
    dispatch(fetchDataReq());
    const params = new URLSearchParams();

    params.set("accountType", accountType);
    params.set("page", page);
    params.set("limit", limit);

    APIclient.getData(`ms/b2b-accounting/v2/partners/accounts?${params.toString()}`)
      .then((data) => {
        if (data.status && data.data.status === 200) {
          dispatch(fetchDataSuccess(data.data));
        } else {
          dispatch(fetchDataError(data.data));
        }
      })
      .catch((err) => {
        dispatch(fetchDataError(err));
      });
  };
};

export const selectXeroAccount = (accountNum, cb = () => {}) => {
  const orgId = GetBaseAuthObject().orgId;

  return (dispatch) => {
    dispatch(fetchSelectAccountReq());
    APIclient.postData("/api/v1/partner/xero/account", GetFormData({ organisation_id: orgId, ...accountNum }))
      .then((data) => {
        if (data.status && data.data.status > 200) {
          dispatch(fetchSelectAccountError(data.data));
        } else {
          dispatch(fetchSelectAccountSuccess(data.data));
          setTimeout(() => {
            cb();
          });
        }
      })
      .catch((err) => {
        dispatch(fetchSelectAccountError(err));
      });
  };
};

const generateDate = (type) => {
  return {
    type,
    data: [],
  };
};

// The order should be Expense -> Asset -> Liablity -> Equity -> Revenue
// To match this order we have to create this default data which match the order
// If not then, the order might be changed based on API return from BE
const defaultData = {
  Expense: generateDate("Expense"),
  Asset: generateDate("Asset"),
  Liability: generateDate("Liability"),
  Equity: generateDate("Equity"),
  Revenue: generateDate("Revenue"),
};

const removeEmptyObject = (groupAccount) => {
  for (const key in groupAccount) {
    const account = groupAccount[key];
    if (account.data.length === 0) {
      delete groupAccount[key];
    }
  }
  return groupAccount;
};
const capitalizeType = (type) => type[0].toUpperCase() + type.slice(1).toLowerCase();

export const groupCategories = (data) => {
  const groupAccount = data.payload.accounts.reduce((groupAccount, account) => {
    const type = capitalizeType(account.type);
    if (!groupAccount[type]) {
      groupAccount[type] = generateDate(type);
    }

    if (account.number) {
      groupAccount[type].data.push(account);
    }

    return groupAccount;
  }, defaultData);

  return {
    ...data,
    payload: {
      ...data.payload,
      accounts: removeEmptyObject(groupAccount),
    },
  };
};

export const xeroExpenseCodes = () => {
  return (dispatch) => {
    dispatch(fetctXeroCategoriesReq());
    const params = new URLSearchParams();

    params.set("accountType", "all");
    params.set("page", 1);
    params.set("limit", 1000);

    APIclient.getData(`ms/b2b-accounting/v2/partners/accounts?${params.toString()}`)
      .then(({ data }) => {
        if (data.status > HTTP_STATUS_CODE.OK) {
          dispatch(fetchXeroCategoriesError(data));
        } else {
          dispatch(fetchXeroCategoriesSuccess(groupCategories(data)));
        }
      })
      .catch((err) => {
        dispatch(fetchXeroCategoriesError(err));
      });
  };
};

export const xeroCategoryCodes = (val, importedCategories) => {
  return (dispatch) => {
    dispatch(setXeroCategoriesReq());
    APIclient.postData("/api/v1/partner/xero/categories", {
      organisation_id: GetBaseAuthObject().orgId,
      account_code_map: JSON.stringify(val),
      import_categories: importedCategories,
    })
      .then((data) => {
        if (data.status && data.data.status > 200) {
          dispatch(setXeroCategoriesError(data.data));
        } else {
          dispatch(setXeroCategoriesSuccess(data.data));
        }
      })
      .catch((err) => {
        dispatch(setXeroCategoriesError(err));
      });
  };
};
