import React, { useContext, useState } from "react";
import qs from "query-string";
import { Banner, Card, IllApprovedToPaid, IllEmpty, InfoFilled, Modal, Typography } from "@spenmo/splice";
import { IModalProps } from "@spenmo/splice/lib/components/Modal";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";

import useExportCSV from "Views/Bills/V2/hooks/useExportCsv";
import { BILLS_QUERY_PARAMS } from "Views/Bills/V2/constants";
import { ILocationParams, ILocationQueryParams } from "Views/Bills/V2/@types";
import { PermissionAndSettingsContext } from "Views/Bills/V2/context/BillContext";

import styles from "./index.module.scss";

const Container = () => {
  const location = useLocation();

  const { settings: paymentRunSettingEnabled } = useContext(PermissionAndSettingsContext);

  const { approvedBills, pendingdBills, currentPaymentCycle, isCSVDownloadable }: ILocationQueryParams = qs.parse(
    location.search,
    {
      parseNumbers: true,
      parseBooleans: true,
    }
  );

  const hasBills = Boolean(approvedBills) || Boolean(pendingdBills);

  const renderItem = (title: string, subtitle: string, count: number) => {
    return (
      <div className={styles.item}>
        <Typography size="m" variant="body-content" weight={600} tag="p" className={styles.title}>
          {title}
        </Typography>
        <div className={styles.data}>
          <Typography size="m" variant="body-content" className={styles.subtitle}>
            {subtitle}
          </Typography>
          <Typography size="m" variant="body-content" weight={600} className={styles.count}>
            {count}
          </Typography>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className={styles.icon}>{hasBills ? <IllApprovedToPaid size={120} /> : <IllEmpty size={120} />}</div>
      {hasBills ? (
        <div className={styles.bills}>
          {paymentRunSettingEnabled && (
            <Typography size="s" variant="body-content" tag="p" className={styles.content}>
              The payment run <strong>{currentPaymentCycle} (Current)</strong> will be closed. This cannot be undone.
            </Typography>
          )}
          <Card className={styles.card} elevation="ground" padding="medium" radius="l">
            {renderItem("Bills to be marked as paid", "Approved Bills", approvedBills)}
            {Boolean(pendingdBills) && <div className={styles.separator} />}
            {Boolean(pendingdBills) && renderItem("Bills to be unscheduled", "Pending Approval Bills", pendingdBills)}
          </Card>
          {isCSVDownloadable ? (
            <Typography size="s" variant="body-content" tag="p" className={styles.content}>
              Your download will start upon confirmation.
            </Typography>
          ) : (
            <Banner
              variant="warning"
              icon={InfoFilled}
              size="l"
              title="Your download will not be initiated"
              description="As there are no approved bills in your current selection, your CSV will not contain any bill information"
            />
          )}
        </div>
      ) : (
        <Typography size="m" variant="body-content" tag="p" className={styles.content}>
          You do not have any approved bills available for export within the selected date range
        </Typography>
      )}
    </div>
  );
};

const MarkApproved = () => {
  const history = useHistory();
  const location = useLocation();

  const { params } = useRouteMatch<ILocationParams>();

  const { operation = "", id = "", form = "" } = params;

  const query: ILocationQueryParams = qs.parse(location.search, {
    parseNumbers: true,
    parseBooleans: true,
  });

  const { approvedBills, pendingdBills } = query;

  const { exportCSV } = useExportCSV();

  const [loading, setLoading] = useState(false);

  const handleOnClose = (sidepanel = false) => {
    history.push({
      pathname: location.pathname.replace(sidepanel ? `/${id}/${operation}` : `/${form}/${id}/${operation}`, ""),
      search: qs.stringify({
        ...query,
        [BILLS_QUERY_PARAMS.APPROVED_BILLS]: undefined,
        [BILLS_QUERY_PARAMS.PENDING_BILLS]: undefined,
        [BILLS_QUERY_PARAMS.IS_CSV_DOWNLOADABLE]: undefined,
        [BILLS_QUERY_PARAMS.CURRENT_PAYMENT_CYCLE]: undefined,
        [BILLS_QUERY_PARAMS.START_DATE]: undefined,
        [BILLS_QUERY_PARAMS.END_DATE]: undefined,
        [BILLS_QUERY_PARAMS.EXPORT_TYPE]: undefined,
        [BILLS_QUERY_PARAMS.RANGE_TYPE]: undefined,
        [BILLS_QUERY_PARAMS.MARK_AS_PAID]: undefined,
        [BILLS_QUERY_PARAMS.CYCLE_ID]: undefined,
      }),
    });
  };

  const onCancel = () => {
    history.action === "PUSH" ? history.goBack() : handleOnClose(true);
  };

  const onConfirm = () => {
    setLoading(true);

    exportCSV().finally(() => {
      setLoading(false);
      handleOnClose();
    });
  };

  const props: IModalProps = {
    showModal: true,
    size: "m",
    title: Boolean(approvedBills) || Boolean(pendingdBills) ? "Do you want to mark as paid" : "No approved bills",
    onClose: onCancel,
    children: <Container />,
    footerFullWidthButtons: false,
    primaryActionButton:
      Boolean(approvedBills) || Boolean(pendingdBills)
        ? {
            title: "Confirm",
            action: onConfirm,
            loading: loading,
          }
        : {
            title: "Change filters",
            action: onCancel,
            loading: loading,
          },
    secondaryActionButton:
      Boolean(approvedBills) || Boolean(pendingdBills)
        ? {
            title: "Back",
            action: onCancel,
            loading: loading,
          }
        : null,
  };

  return <Modal {...props} />;
};

export default MarkApproved;
