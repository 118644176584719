import { combineReducers } from "redux";
import dynamicReducer from "./dynamicReducer";
import { bankReducer } from "./bankReducer";
import { cardActivateReducer } from "./cardActivateReducer";
import { cardActivateBulkReducer } from "./cardActivateBulkReducer";
import { cardEditReducer } from "./cardEditReducer";
import { cardReducer } from "./cardsReducer";
import { categoryReducer } from "./Category";

import { createApprovalFlowReducer } from "./ApprovalFlow/createApprovalFlowReducer";
import { updateSelfApprovalReducer } from "./ApprovalFlow/updateSelfApprovalReducer";
import { createCardReducer } from "./CreateCard";
import { createScheduledTransactionReducer } from "./ScheduledTransactions/createScheduledTransaction";
import { csvReducer } from "./csvReducer";
import { currencyReducer } from "./currencyReducer";
import { cvvReducer } from "./cvvReducer";
import { editScheduledTransactionReducer } from "./ScheduledTransactions/editScheduledTransaction";
import { empReducer } from "./employeeReducer";
import { fetchDataReducer } from "./fetchDataReducer";
import { forgotReducer } from "./forgotReducer";
import { getApprovalFlowListReducer } from "./ApprovalFlow/getApprovalFlowListReducer";
import { deleteApprovalFlowReducer } from "./ApprovalFlow/deleteApprovalFlowReducer";
import { getApprovalFlowReducer } from "./ApprovalFlow/getApprovalFlowReducer";
import { approvalFlowTabReducer } from "./ApprovalFlow/approvalFlowTabReducer";
import { getCardNumbersReducer } from "./CardFilter/GetCardNumbers";
import { getCardOwnerNamesReducer } from "./CardFilter/GetCardOwnerNames";
import { getPendingApprovalsReducer } from "./TransactionApproval/getPendingApprovalsReducer";
import { getScheduledTransactionsReducer } from "./ScheduledTransactions/getScheduledTransactions";
import { imageUploadReducer } from "./imageUploadReducer";
import { loginReducer } from "./loginReducer";
import { countryCodeReducer } from "./countryCodeReducer";
import { lowBalanceReminderReducer } from "./lowBalanceReminderReducer";
import { memberRoleReducer } from "./memberRoleReducer";
import { membersReducer } from "./membersReducer";
import { merchantReducer } from "./merchantReducer";
import { moneyReducer } from "./moneyReducer";
import { orgTeamReducer } from "./orgTeamReducer";
import { receiptActionReducer } from "./transactionReducer";
import { reimburseReducer } from "./reimburseReducer";
import { requestsReducer } from "./requestsReducer";
import { resetPasswordReducer } from "./resetPasswordReducer";
import { scheduleReducer } from "./scheduleReducer";
import { stopScheduledTransactionsReducer } from "./ScheduledTransactions/stopScheduledTransactions";
import { syncTransactionsReducer } from "./syncTransactionsReducer";
import { taxReducer } from "./TaxReducer";
import { b2bOrgDetailReducer } from "./b2bOrgDetailReducer";
import { teamPolicyReducer } from "./teamPolicyReducer";
// import { teamReducer } from "./teamReducer";
import { topupBankTransferReducer } from "./topupBankTransferReducer";
import { topupReducer } from "./topupReducer";
import { topupStatusReducer } from "./topupStatusReducer";
import { userInfoReducer } from "./userInfoReducer";
import { userInfoWithTeamDataReducer } from "./user/userInfoWithTeamDataReducer";
import { userReducer } from "./userReducer";
import { walletReducer } from "./walletReducer";
import { xeroAccountReducer } from "./xeroAccountReducer";
import { accountingPartnerAuthReducer } from "./accountingPartnerAuthReducer";
import { stepsStatusReducer } from "./OnBoarding/stepsStatusReducer";
import { addEmployeeReducer } from "./OnBoarding/addEmployeesReducer";
import { employeeRoleReducer } from "./OnBoarding/rolesReducer";
import { phoneCodeReducer } from "./OnBoarding/phoneCodesReducer";
import { uploadDocumentReducer } from "./OnBoarding/uploadDocument";
import { customTicketFormReducer } from "./ZenDesk/customTicketReducer";
import { uploadedDocReducer } from "./OnBoarding/uploadedDocReducer";
import { skipStepsReducer } from "./OnBoarding/skipStepsReducer";
import { changePhoneNumberReducer } from "./OTP/changePhoneNumberReducer";
import { getOtpReducer } from "./OTP/getOtpReducer";
import { validateApprovalFlowReducer } from "./ApprovalFlow/validateApprovalFlowReducer";
import { verifyOtpReducer } from "./OTP/verifyOtReducer";
import { teamListReducer } from "./Teams/teamListReducer";
import { teamListByUserIdReducer } from "./Teams/teamListByUserIdReducer";
import { teamMembersByTeamIdReducer } from "./Teams/teamMembersByTeamIdReducer";
import { teamDetailsReducer } from "./Teams/teamDetailsReducer";
import { teamProfileReducer } from "./Teams/teamProfileReducer";
import { gaActivationReducer } from "Redux/Reducers/SecurityHub/GoogleAuthenticator/gaActivationReducer";
import { gaConnectionStatusReducer } from "Redux/Reducers/SecurityHub/GoogleAuthenticator/gaConnectionStatusReducer";
import { allTeamsWalletReducer } from "./Teams/teamWalletReducer";
import { termsAndConditionsForExistingUserReducer } from "./TermsAndConditions/termsAndConditionsForExistingUserReducer";
import { termsAndConditionsForNewUserReducer } from "./TermsAndConditions/termsAndConditionsForNewUserReducer";
import { cardsListReducer } from "Redux/Reducers/Cards/ListReducer";
import { merchantListReducer } from "Redux/Reducers/Merchant/search";
import { userWalletBalancesListReducer } from "Redux/Reducers/user/WalletBalancesListReducer";
import { teamsExpensesListReducer } from "Redux/Reducers/Teams/ExpensesListReducer";
import { smartCSVTemplateReducer } from "./SmartCSV/templateReducer";
import { splitReducer } from "@splitsoftware/splitio-redux";
import { checkEmailReducer } from "./Common/checkEmailReducer";
import { checkPhoneNumReducer } from "./Common/checkPhoneNumReducer";
import { updateProfileReducer } from "./Wallet/updateProfileReducer";
import { paginatedTeamListReducer } from "./Teams/paginatedTeamListReducer";
import { accountingTagReducer } from "./AccountingTag/accountingTagReducer";
import { teamMemberSearchReducer } from "./Teams/teamMemberSearchReducer";
import approvalRequestReducer from "./Approval/ApprovalCenter/approvalCenter.slice";
import { verifyForgotPassword } from "./Auth/VerifyForgotPassword";
import { verifyForgotOTP } from "./Auth/verifyForgotOTP";

import { pendingActionsReducer } from "Redux/Reducers/Home/PendingActions/PendingActions";
import { preliminaryDataReducer } from "Redux/Reducers/KYX/preliminaryData";
import { businessActivityReducer } from "Redux/Reducers/KYX/businessActivity";
import { myInfoCallBackReducer } from "Redux/Reducers/KYX/MyInfoCallBack";
import { countryListReducer } from "Redux/Reducers/Common/getCountryList";
import { entityTypesReducer } from "Redux/Reducers/KYX/entityTypes";

// --- Cards
import { cardNumberReducer } from "Redux/Reducers/Card/Number";
import { cardCvvReducer } from "Redux/Reducers/Card/Cvv";

// Employee
import { allEmployeesReducer } from "Redux/Reducers/Employee/Get/Employees";
import { kycStatusesReducer } from "Redux/Reducers/Employee/Get/KycStatusList";
import { updateRoleReducer } from "Redux/Reducers/Employee/Update/Role";
import { deleteEmployeeFromOrgReducer } from "Redux/Reducers/Employee/Delete";
import { deleteEmployeeCheckReducer } from "Redux/Reducers/Employee/Delete/Check";

// Accounting
import { accountingWorkflowReducer } from "./AccountingWorkflow/accountingWorkflow.slice";

import { orgConfigsReducer } from "./orgConfigsReducer";
import { importBillFromXeroReducer } from "./ImportBillFormXero/importBillFromXero.slice";

const EmployeeReducers = {
  allEmployeesReducer,
  updateRoleReducer,
  kycStatusesReducer,
  deleteEmployeeCheckReducer,
  deleteEmployeeFromOrgReducer,
};

export default combineReducers({
  bankReducer,
  cardActivateReducer,
  verifyForgotPassword,
  verifyForgotOTP,
  cardActivateBulkReducer,
  cardEditReducer,
  cardReducer,
  categoryReducer,
  createApprovalFlowReducer,
  updateSelfApprovalReducer,
  createCardReducer,
  createScheduledTransactionReducer,
  csvReducer,
  currencyReducer,
  cvvReducer,
  data: fetchDataReducer,
  dynamicData: dynamicReducer,
  editScheduledTransactionReducer,
  empReducer,
  forgotReducer,
  getApprovalFlowListReducer,
  deleteApprovalFlowReducer,
  getApprovalFlowReducer,
  approvalFlowTabReducer,
  getCardNumbersReducer,
  getCardOwnerNamesReducer,
  getPendingApprovalsReducer,
  getScheduledTransactionsReducer,
  imageUploadReducer,
  loginReducer,
  lowBalanceReminderReducer,
  memberRoleReducer,
  membersReducer,
  merchantReducer,
  moneyReducer,
  orgTeamReducer,
  receiptActionReducer,
  reimburseReducer,
  requestsReducer,
  resetPasswordReducer,
  scheduleReducer,
  stopScheduledTransactionsReducer,
  syncTransactionsReducer,
  taxReducer,
  b2bOrgDetailReducer,
  teamPolicyReducer,
  countryListReducer,
  // teamReducer,
  topupBankTransferReducer,
  topupReducer,
  topupStatusReducer,
  user: userReducer,
  userInfoReducer,
  userInfoWithTeamDataReducer,
  validateApprovalFlowReducer,
  wallet: walletReducer,
  xeroAccountReducer,
  accountingPartnerAuthReducer, // To follow the existing flow which still use redux
  stepsStatusReducer,
  addEmployeeReducer,
  employeeRoleReducer,
  phoneCodeReducer,
  uploadDocumentReducer,
  customTicketFormReducer,
  uploadedDocReducer,
  countryCodeReducer,
  skipStepsReducer,
  changePhoneNumberReducer,
  getOtpReducer,
  verifyOtpReducer,
  teamList: teamListReducer,
  teamReducer: teamDetailsReducer,
  teamProfileData: teamProfileReducer,
  gaActivationReducer,
  gaConnectionStatusReducer,
  allTeamsWalletReducer,
  termsAndConditionsExistingReducer: termsAndConditionsForExistingUserReducer,
  termsAndConditionsReducer: termsAndConditionsForNewUserReducer,
  cardsListReducer,
  merchantListReducer,
  userWalletBalancesListReducer,
  teamsExpensesListReducer,
  smartCSVTemplateReducer,
  splitio: splitReducer,
  checkEmailReducer,
  checkPhoneNumReducer,
  updateProfileReducer,
  teamListByUserIdReducer,
  teamMembersByTeamIdReducer,
  paginatedTeamList: paginatedTeamListReducer,
  accountingTagReducer,
  approvalRequest: approvalRequestReducer,
  teamMemberSearchReducer,
  pendingActionsReducer,
  // --- KYX
  preliminaryDataReducer,
  businessActivityReducer,
  myInfoCallBackReducer,
  entityTypesReducer,
  // --- Cards
  cardNumberReducer,
  cardCvvReducer,
  // ---
  ...EmployeeReducers,
  accountingWorkflowReducer,
  importBillFromXeroReducer,
  orgConfigs: orgConfigsReducer,
});
