import React from "react";
import { Col, Form, Input } from "antd";

export const AddPaynow = ({ trackAddBankAccountEvents = () => {} }) => {
  return (
    <div className={"add-bank--form--mobile"}>
      <h3>Account Details</h3>
      <Col span={6}>
        <Form.Item
          rules={[
            { required: true, message: "Please enter the mobile number!" },
          ]}
          name={"mobile_number"}
          label={"Mobile number"}
        >
          <Input
            className={"mobile"}
            addonBefore={"+65"}
            placeholder="Enter mobile number"
            onBlur={() =>
              trackAddBankAccountEvents(
                "mobile_number",
                "add bank account mobile number added",
              )
            }
            autoComplete="off"
          />
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item
          rules={[
            { required: true, message: "Please enter the account name!" },
          ]}
          name={"account_name"}
          label={"Account Name"}
        >
          <Input
            placeholder={"Your name as per your Bank Account"}
            onBlur={() =>
              trackAddBankAccountEvents(
                "account_name",
                "add bank account name added",
              )
            }
            autoComplete="off"
          />
        </Form.Item>
      </Col>
    </div>
  );
};

export default AddPaynow;
