import React from "react";
import { Moment } from "moment";
import dayjs from "dayjs";
import { DatePicker } from "antd";
import utc from "dayjs/plugin/utc";

import { DATE_FORMAT } from "Views/Bills/V2/constants";

import { DatePickerProps } from "./type";
import style from "./Date.module.scss";

// This is a workaround to resolve bug from antd and dayjs interaction
// ref: https://github.com/react-component/picker/issues/123
import advancedFormat from "dayjs/plugin/advancedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";
import localeData from "dayjs/plugin/localeData";
import weekday from "dayjs/plugin/weekday";
import weekOfYear from "dayjs/plugin/weekOfYear";
import weekYear from "dayjs/plugin/weekYear";

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);

dayjs.extend(utc);

const Date = (props: DatePickerProps) => {
  const { value, onChange, disabledFutureDates, ...rest } = props;

  const disabledDate = (current) => (disabledFutureDates ? current > dayjs().endOf("day") : false);

  const handleChange = (date, dateString) => {
    onChange(date && dayjs.utc(date).format(DATE_FORMAT.standard), dateString);
  };
  return (
    <DatePicker
      className={style.date}
      onChange={handleChange}
      value={value && (dayjs.utc(value) as unknown as Moment)} // use moment because DatePicker antd still use moment and not dayjs
      format={DATE_FORMAT.short}
      disabledDate={disabledDate}
      {...rest}
      getPopupContainer={(triggerNode) => triggerNode.parentElement}
    />
  );
};

export default Date;
