import { Button, Dropdown } from "antd";
import * as React from "react";
import { useState, useEffect } from "react";
import { SortDropdownItem, SortOrder, SortType, TAB_KEY, REIMBURSEMENT_STATUS } from "../types";
import "./index.scss";
import { orangeTick } from "assets/img";
import Icon from "Modules/icons";
import { useDispatch } from "react-redux";
import { AppDispatch, useAppSelector } from "../../../../hook";
import {
  applyFilter,
  fetchApprovalRequestList,
  selectActiveTab,
  selectActiveSubTab,
} from "Redux/Reducers/Approval/ApprovalCenter/approvalCenter.slice";
import { useIsMount } from "utility/useIsMount";

const dropdownData: SortDropdownItem[] = [
  {
    label: "Request Date (Newest First)",
    value: SortType.request_date,
    order: SortOrder.descending,
  },
  {
    label: "Request Date (Oldest First)",
    value: SortType.request_date,
    order: SortOrder.ascending,
  },
  {
    label: "Amount You Send (Highest First)",
    value: SortType.amount_sent,
    order: SortOrder.descending,
  },
  {
    label: "Amount You Send (Lowest First)",
    value: SortType.amount_sent,
    order: SortOrder.ascending,
  },
  {
    label: "Amount (Highest First)",
    value: SortType.amount,
    order: SortOrder.descending,
  },
  {
    label: "Amount (Lowest First)",
    value: SortType.amount,
    order: SortOrder.ascending,
  },
];

const Sort = () => {
  const dispatch: AppDispatch = useDispatch();
  const activeTab: TAB_KEY = useAppSelector(selectActiveTab);
  const activeSubTab: REIMBURSEMENT_STATUS = useAppSelector(selectActiveSubTab);
  const [activeSort, setActiveSort] = useState({ value: SortType.request_date, order: SortOrder.descending });
  const isMount = useIsMount();

  const dropdownList = React.useMemo(() => {
    let sortKeys: SortType[] = [SortType.request_date];
    switch (activeTab) {
      case TAB_KEY.FUND:
        sortKeys.push(SortType.amount);
        break;
      case TAB_KEY.INVOICE:
        sortKeys.push(SortType.amount_sent);
        break;
      case TAB_KEY.REIMBURSEMENT:
        sortKeys.push(SortType.amount);
        break;
      case TAB_KEY.CARD:
      default:
        break;
    }
    return dropdownData.filter((item) => sortKeys.includes(item.value));
  }, [activeTab]);

  const findSortItem = (dropdownItemToFind: Partial<SortDropdownItem>) => {
    return dropdownList.find(
      (dropdownItem) => dropdownItem.value == dropdownItemToFind.value && dropdownItem.order == dropdownItemToFind.order
    );
  };

  const updateActiveSort = (dropdownItem: SortDropdownItem) => {
    setActiveSort({ value: dropdownItem.value, order: dropdownItem.order });
  };

  const showActiveSortLabel = () => findSortItem(activeSort).label;

  const isActiveSort = (dropdownItem: SortDropdownItem) => {
    return activeSort.value === dropdownItem.value && activeSort.order == dropdownItem.order;
  };

  useEffect(() => {
    if (!isMount) {
      dispatch(
        applyFilter({
          sortBy: activeSort.value,
          sortDirection: activeSort.order,
        })
      );
      dispatch(fetchApprovalRequestList());
    }
  }, [activeSort, dispatch]);

  useEffect(() => {
    setActiveSort({ value: SortType.request_date, order: SortOrder.descending });
  }, [activeSubTab]);

  const sortMenu = (
    <div className="approval-action__sort__dropdown">
      {dropdownList.map((dropdownItem, idx) => {
        return (
          <div
            key={idx}
            onClick={() => updateActiveSort(dropdownItem)}
            className={`approval-action__sort__dropdown-item ${
              isActiveSort(dropdownItem) ? "approval-action__sort__dropdown-item--selected" : ""
            } `}
          >
            {dropdownItem.label}
            {isActiveSort(dropdownItem) && (
              <Icon
                src={orangeTick}
                alt={"active sort icon"}
                action={(e) => {
                  e.stopPropagation();
                }}
                className="approval-action__sort__dropdown-item--tick"
              />
            )}
          </div>
        );
      })}
    </div>
  );

  return (
    <div className="approval-action__sort">
      <Dropdown placement="bottomCenter" overlay={sortMenu} trigger={["click"]}>
        <Button>Sort By: {showActiveSortLabel()}</Button>
      </Dropdown>
    </div>
  );
};

export default Sort;
