import { createContext, useContext } from "react";
import { initTeamDetail } from "Views/Team/constant";
import { IMoveTeamMemberContext, ITeamDetails } from "../types";

const MoveTeamMemberContext = createContext<IMoveTeamMemberContext>({
  employeeName: "",
  teamDetails: initTeamDetail,
  destinationTeam: initTeamDetail,
  validateText: "",
  user: {},
  userTeams: [],
  setDestinationTeam: (payload: ITeamDetails) => {
    // Do nothing
  },
  setValidateText: (payload: string) => {
    // Do nothing
  },
  handleClose: () => {
    // Do nothing
  },
});

export const useMoveTeamMemberContext = () => useContext(MoveTeamMemberContext);

export default MoveTeamMemberContext;
