import React from "react";
import cn from "classnames";
import dayjs from "dayjs";
import { Typography } from "@spenmo/splice";
import { ISubscription, IVendor } from "Views/SubscriptionManagement/@types";
import { VendorImage } from "Views/SubscriptionManagement/Components/VendorImage";
import { CONTRACT_TYPE } from "Views/SubscriptionManagement/Constants";
import { LastPaymentAlert, VariationPercentage } from "Views/SubscriptionManagement/Components/Tooltips";
import { GetCurrencyCode, currencyFormatterV2 as currencyFormatter, currencyFormatterV2 } from "utility";

import styles from "./index.module.scss";

export const VendorWithIcon = ({ record }: { record: IVendor }) => {
  return (
    <div className={styles.vendor}>
      <VendorImage vendor={record.name} />
      <Typography size="s" variant="body-content">
        {record.name}
      </Typography>
    </div>
  );
};

export const EstimatedCost = ({ record }: { record: ISubscription }) => {
  return (
    <div className={styles.displayFlex}>
      <Typography size="s" variant="body-content">
        {currencyFormatter(record.estimated_cost || 0, record.currency_code, true, 2)}
      </Typography>
      {Boolean(record?.variation_percentage) ? <VariationPercentage percentage={record?.variation_percentage} /> : null}
    </div>
  );
};

export const LastPayment = ({ record }: { record: ISubscription }) => {
  const {
    last_payment_foreign_currency,
    last_payment_foreign_amount,
    estimated_amount,
    variation_percentage,
    last_payment_amount,
    currency_code,
  } = record;
  const paymentThreshold = estimated_amount + estimated_amount * (variation_percentage / 100);

  const findCurrency = () => {
    if (last_payment_foreign_currency && record.last_payment_foreign_amount) {
      return {
        currency: last_payment_foreign_currency,
        amount: last_payment_foreign_amount,
      };
    }

    return {
      currency: currency_code,
      amount: last_payment_amount,
    };
  };

  const { currency, amount } = findCurrency();

  return (
    <div className={cn(styles.displayFlex, styles.alignStart)}>
      <div>
        <Typography size="s" variant="body-content" tag="p" className={styles.originalCurrency}>
          {currencyFormatterV2(amount, currency, true, 2)}
        </Typography>
        {last_payment_foreign_currency && last_payment_foreign_currency !== currency_code ? (
          <Typography size="caption-m" variant="body-content" tag="p" className={styles.dashboardCurrency}>
            {currencyFormatterV2(amount, currency, true, 2)}
          </Typography>
        ) : null}
      </div>
      {amount > paymentThreshold && (
        <LastPaymentAlert gap={currencyFormatterV2(amount - paymentThreshold, currency, true, 2)} />
      )}
    </div>
  );
};

export const TitleWithSubTitle = ({ title, subtitle }: { title: string; subtitle: string }) => {
  return (
    <div className={styles.title}>
      <Typography size="s" variant="body-content">
        {title}
      </Typography>
      <Typography size="caption-m" variant="body-content" className={styles.label}>
        {subtitle}
      </Typography>
    </div>
  );
};

export const ExpiryOrRenewal = ({ label, date }: { label: string; date: dayjs.Dayjs }) => {
  return (
    <div className={styles.title}>
      <Typography size="caption-m" variant="body-content" className={styles.label}>
        {label}
      </Typography>
      <Typography size="s" variant="body-content">
        {date.format("DD MMM YYYY")}
      </Typography>
    </div>
  );
};

export const setExpiryOrRenewal = (subscription: ISubscription) => {
  return subscription.contract_type === CONTRACT_TYPE.FIXED ? (
    <ExpiryOrRenewal label="Contract expires on" date={dayjs(new Date(subscription.expiry))} />
  ) : (
    <ExpiryOrRenewal label="Contract renews on" date={dayjs(new Date(subscription.next_renewal_date))} />
  );
};
