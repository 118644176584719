import { ICardEditFieldDetails } from "Views/Card/Edit/index.context";
import { CARD_COLOR_AND_NAME_MAP } from "constants/Card/CardColors.constants";
import { DEFAULT_LIMIT_VALUES } from "constants/Team.constant";
import { getSpendingLocksPayload } from "utility/Card";
import { CARD_LIMIT_TYPES } from "Views/Card/types";

export const generatePayload = (cardDetailsEditState: ICardEditFieldDetails, isCardRequestDetails: boolean) => {
  const {
    spendingLockType,
    spendingLocks,
    spendingLimit,
    spendingLimitType,
    title,
    expenseCategoryId,
    color,
    tags,
    letMyManagerChooseLimit,
  } = cardDetailsEditState;
  const defaultLimitsToSend: { [key: string]: number | string } = {
    monthly_limit: DEFAULT_LIMIT_VALUES.REVERT_THE_LIMIT,
    yearly_limit: DEFAULT_LIMIT_VALUES.REVERT_THE_LIMIT,
    total_limit: DEFAULT_LIMIT_VALUES.REVERT_THE_LIMIT,
  };
  if (spendingLimitType !== CARD_LIMIT_TYPES.unlimited && !letMyManagerChooseLimit) {
    defaultLimitsToSend[spendingLimitType] = spendingLimit;
  }
  return {
    card_name: title,
    name: title,
    expense_category_id: expenseCategoryId || undefined,
    color_name: CARD_COLOR_AND_NAME_MAP[color],
    color: color,
    tags: tags,
    ...getSpendingLocksPayload(spendingLockType, spendingLocks),
    ...defaultLimitsToSend,
    ...(isCardRequestDetails ? { manager_sets_limit: letMyManagerChooseLimit ? 1 : 0 } : {}),
  };
};
