import React from "react";
import PropTypes from "prop-types";

import Icon from "Modules/icons";

import { minusIcon } from "assets/img";
import "./WidgetHeader.scss";

const WidgetHeader = ({ close, title, size = "small" }) => {
  return (
    <div className={`chat-widget__header${size && "--" + size}`}>
      <h4>{title}</h4>
      <Icon onClick={close} src={minusIcon} alt="close" />
    </div>
  );
};

WidgetHeader.propTypes = {
  close: PropTypes.func,
  title: PropTypes.string,
  size: PropTypes.string,
};

export default WidgetHeader;
