import * as React from "react";
import { flowConditionWallet, flowConditionAmount, flowConditionCategory } from "assets/img";
import WalletConditionForm from "./CreateFlow/FlowCanvas/FlowCondition/ConditionForms/WalletConditionForm";
import CategoryConditionForm from "./CreateFlow/FlowCanvas/FlowCondition/ConditionForms/CategoryConditionForm";
import AmountConditionForm from "./CreateFlow/FlowCanvas/FlowCondition/ConditionForms/AmountConditionForm";
import { FlowConditionsInterface, Operator } from "./types";

export const AMOUNT_SUBMENU = [
  {
    label: "Less than",
    value: Operator.lt,
  },
  {
    label: "Less than or Equal to",
    value: Operator.lte,
  },
  {
    label: "More than",
    value: Operator.gt,
  },
  {
    label: "More than or Equal to",
    value: Operator.gte,
  },
  {
    label: "Between",
    value: Operator.between,
  },
];

export const FLOW_CONDITIONS: FlowConditionsInterface = {
  wallet: {
    icon: flowConditionWallet,
    title: "Budget",
    desc: "Select the budget to which this flow will apply. When left unspecified, all budgets are included.",
    render: ({ values, setValues, isExpanded, toggleExpand }) => {
      return (
        <WalletConditionForm
          values={values}
          setValues={setValues}
          isExpanded={isExpanded}
          toggleExpand={toggleExpand}
        />
      );
    },
  },
  amount: {
    icon: flowConditionAmount,
    title: "Amount",
    desc: "Select the range of expenses amount that must follow this flow. When left unspecified, all amount is included in this condition.",
    render: ({ values, setValues, isExpanded, toggleExpand }) => {
      return (
        <AmountConditionForm
          values={values}
          setValues={setValues}
          isExpanded={isExpanded}
          toggleExpand={toggleExpand}
        />
      );
    },
    subMenu: { mapToValues: (operator) => ({ operator }), options: AMOUNT_SUBMENU },
  },
  expenseCategory: {
    icon: flowConditionCategory,
    title: "Expense Category",
    desc: "Select the expenses category(es) that must follow this flow. When left unspecified, all expenses categories are included in this condition.",
    render: ({ values, setValues, isExpanded, toggleExpand }) => {
      return (
        <CategoryConditionForm
          values={values}
          setValues={setValues}
          isExpanded={isExpanded}
          toggleExpand={toggleExpand}
        />
      );
    },
  },
};

export const TABS = {
  BILL_PAYMENT: "Bill Payment",
  REIMBURSEMENT: "Reimbursement",
};

export const APPROVAL_FLOW_EVENT_TYPE = {
  Bills: "Bills",
  Reimbursemnt: "CR",
};

export const APPROVAL_FLOW_EVENT_SOURCE = {
  ConditionEdited: "Condition Edited",
  ApproverEdited: "Approver Edited",
};

export const APPROVAL_FLOW_UPSELL_MEDIA_URLS = {
  video: "https://www.youtube.com/embed/lKGxbo3lV5s?autoplay=1&origin=https://example.com",
  thumbnail: "https://img.youtube.com/vi/lKGxbo3lV5s/maxresdefault.jpg",
};
