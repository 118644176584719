import React from "react";
import { SUBSCRIPTION_PATH } from "Route/Subscription/path";
import { Label } from "../components";
import { Action, useActionCount } from "./helper";
import { ISubscriptionManagementFilterKeys } from "Views/SubscriptionManagement/@types/filters";

import styles from "./styles.module.scss";

export const ActionRequired = () => {
  const {
    contractExpiringSoonSubscription,
    contractRenewingSoonSubscription,
    autoReconcileTransaction,
    overspendBudgetSubscription,
  } = useActionCount();
  const sortBy = `sortBy=expiryDate`;

  return (
    <section>
      <Label text="Action required" className={styles.label} />
      <div className={styles.actions}>
        <Action
          icon="calendar"
          text="Review contracts auto-renewing soon"
          count={contractRenewingSoonSubscription}
          link={
            SUBSCRIPTION_PATH.SUBSCRIPTION_MANAGEMENT +
            `?${ISubscriptionManagementFilterKeys.CONTRACTS_RENEWING_SOON}=true&${sortBy}`
          }
        />
        <Action
          icon="file"
          text="Review contracts expiring soon"
          count={contractExpiringSoonSubscription}
          link={
            SUBSCRIPTION_PATH.SUBSCRIPTION_MANAGEMENT +
            `?${ISubscriptionManagementFilterKeys.CONTRACTS_EXPIRING_SOON}=true&${sortBy}`
          }
        />
        <Action
          icon="transaction"
          text="Review transactions suggested by Spenmo"
          count={autoReconcileTransaction}
          link={SUBSCRIPTION_PATH.SUBSCRIPTION_AUTO_RECONCILED_TRXNS}
        />
        <Action
          icon="warning"
          text="Review overspend"
          count={overspendBudgetSubscription}
          link={
            SUBSCRIPTION_PATH.SUBSCRIPTION_MANAGEMENT +
            `?${ISubscriptionManagementFilterKeys.OVER_SPEND}=true&${sortBy}`
          }
        />
      </div>
    </section>
  );
};
