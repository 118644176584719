import React, { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import qs from "query-string";

import CustomWrapper from "./CustomWrapper";
import RecipientLog from "./RecipientLog";
import FieldController from "Views/Bills/V2/components/FieldController";

import { useBill } from "Views/Bills/V2/context/BillContext";
import { useBillForm } from "Views/Bills/V2/context/FormContext";
import { useRecipientDetailFields } from "Views/Bills/V2/hooks/useRecipientFormFields";
import { API_URL } from "Views/Bills/V2/constants";
import { isEmpty } from "Views/Bills/V2/utilities";
import { RecipientFormContext, RecipientFormInputs, RefetchParams } from "./types";
import { generateRecipientFields } from "./helper";

const RecipientForm: React.FC = () => {
  const { getValues, watch } = useFormContext();
  const { recipientSelectedID, ocrData, withOCRData } = useBill();
  const { refetchValues, refetchDynamicFields } = useBillForm<RecipientFormContext>();

  const refetchKeys = Object.keys(refetchValues);
  const refetchVals = watch(refetchKeys);
  const ocrDataKey = useMemo(() => {
    return ocrData?.recipientData ? Object.keys(ocrData.recipientData) : [];
  }, [ocrData?.recipientData]);

  const ocrRelatedFields = watch(ocrDataKey);

  const refetchParams = useMemo(() => {
    const params: RefetchParams = {};

    refetchKeys
      .filter((key) => !key.includes("dynamicFields."))
      .forEach((key, index) => {
        params[key] = refetchVals[index];
      });

    // dynamic fields
    const [dynamicKey] = Object.keys(refetchDynamicFields || {});

    if (dynamicKey) {
      const isNewData = refetchDynamicFields?.[dynamicKey]?.["isNewData"];
      const value = getValues(dynamicKey);

      if (!isEmpty(value)) {
        params[dynamicKey] = value;
      }

      if (isNewData) {
        params.isNewData = true;
        params[dynamicKey] = "";
      }
    }

    return params;
  }, [refetchKeys, refetchVals, refetchDynamicFields]);

  const apiURL = useMemo(() => {
    return qs.stringifyUrl({
      url: API_URL.recipientFields,
      query: {
        ...refetchParams,
        vendorID: recipientSelectedID,
      },
    });
  }, [refetchParams, recipientSelectedID]);

  // IMPROVEMENT: should just gives the ocrID to the BE
  const { data: recipientData, isValidating } = useRecipientDetailFields(apiURL, withOCRData ? ocrData : undefined);

  const formFields = useMemo(() => {
    const formFields = {} as RecipientFormInputs;
    ocrDataKey.forEach((key, index) => {
      formFields[key] = ocrRelatedFields[index];
    });
    return generateRecipientFields(recipientData?.payload, ocrData, formFields);
  }, [recipientData?.payload, ocrData, ocrDataKey, ocrRelatedFields]);

  return (
    <>
      <RecipientLog />
      {formFields.map((item) => (
        <FieldController
          key={item.name}
          {...item}
          fieldProps={{
            ...item.fieldProps,
            disabled: item.fieldProps?.disabled || isValidating,
          }}
        >
          {(props, Component) => {
            return (
              <CustomWrapper key={item.name} {...props}>
                {(fieldProps: Record<string, any>) => <Component {...fieldProps} />}
              </CustomWrapper>
            );
          }}
        </FieldController>
      ))}
    </>
  );
};

export default RecipientForm;
