import React from "react";

import { ILineItem } from "Modules/DS/Menu";

import { More } from "Views/Transaction/Filters";
import { ATTACHMENT_FILTER } from "Views/Transaction/@types";

import { addReceipt } from "assets/v1.1/icons/Line";
import { receiptsIcon } from "assets/img";

export const attachmentOptions = (): ILineItem[] => [
  {
    id: ATTACHMENT_FILTER.YES,
    title: "Uploaded",
    src: receiptsIcon,
  },
  {
    id: ATTACHMENT_FILTER.NO,
    title: "Missing Attachments/Receipts",
    src: addReceipt,
  },
];

export const AttachmentFilter = ({ id }: { id: string }) => {
  return (
    <More.Single<ILineItem> id={id} iconKey={"src"} displayKey="title" label="Attachments/ Receipt">
      {attachmentOptions()}
    </More.Single>
  );
};
