import * as React from "react";
import qs from "query-string";
import PendingActionsShimmer from "./PendingActionsShimmer";
import SectionEmptyState from "Modules/sectionEmptyState";
import { emptyDefaultIcon } from "assets/img";
import { fundtransfer, billsapproval, request, billspay, pendingcard } from "assets/img/homeIcons";
import { GetUserId } from "utility";
import { trackEvent } from "utility/analytics";
import { PendingActionPayload, PendingActionsReducer } from "Redux/Actions/PendingActions/types";
import TaskCard from "./TaskCard";
import { PendingActionEvents } from "Views/Home/Analytics";
import { BILL_PENDING_TASK, CENTRALISED_APPROVAL_BILL_URL } from "Views/Bills/const";
import { Accounting } from "assets/v1.1/icons/Core/Accounting";
import { ACCOUNTING_TRANSACTIONS_WORKFLOW } from "constants/Routes.constants";

const REIMBURSEMENT_PENDING_APPROVAL = "reimbursement_pending_approval";

const PendingActionsCategoriesWithIcons = (
  getLoggedinUserTeams: string[],
  showTrxnRequesterFilter: boolean,
  id?: string
) => {
  const paymentRunLink = qs.stringifyUrl({
    url: "/bills/payment-run",
    query: {
      cycleID: id,
    },
  });

  return {
    insufficient_balance: {
      icon: billspay,
      link: "/transactions?tab=pending&subtab=all",
      param: "invoiceid",
      eventName: PendingActionEvents.EVENT_NAME,
      eventProperties: { taskcard_event_source: PendingActionEvents.INSUFFICIENT_BALANCE },
    },
    payment_run_summary_upcoming: {
      icon: billsapproval,
      link: paymentRunLink,
      eventName: PendingActionEvents.EVENT_NAME,
      eventProperties: { taskcard_event_source: PendingActionEvents.BILL_PAYMENT_SUMMARY_UPCOMING },
    },
    payment_run_summary_overdue: {
      icon: billsapproval,
      link: paymentRunLink,
      eventName: PendingActionEvents.EVENT_NAME,
      eventProperties: { taskcard_event_source: PendingActionEvents.BILL_PAYMENT_SUMMARY_OVERDUE },
    },
    payment_run_summary_today: {
      icon: billsapproval,
      link: paymentRunLink,
      eventName: PendingActionEvents.EVENT_NAME,
      eventProperties: { taskcard_event_source: PendingActionEvents.BILL_PAYMENT_SUMMARY_TODAY },
    },
    payment_run_unscheduled: {
      icon: billspay,
      link: paymentRunLink,
      eventName: PendingActionEvents.EVENT_NAME,
      eventProperties: { taskcard_event_source: PendingActionEvents.BILL_PAYMENT_UNASSIGNED },
    },
    payment_run_insufficient_balance: {
      icon: billsapproval,
      link: paymentRunLink,
      eventName: PendingActionEvents.EVENT_NAME,
      eventProperties: { taskcard_event_source: PendingActionEvents.BILL_PAYMENT_INSUFICIENT_BALANCE },
    },
    bill_pending_approval: {
      icon: billsapproval,
      link: "/transactions?tab=pending&subtab=to_approve",
      param: "invoiceid",
      eventName: PendingActionEvents.EVENT_NAME,
      eventProperties: { taskcard_event_source: PendingActionEvents.BILL_PENDING_APPROVAL },
    },
    reimbursement_pending_approval: {
      icon: request,
      link: "/reimbursements?type=received",
      centralised_link: "/approvalRequestCenter?activeTab=reimbursement",
      param: "id",
      eventName: PendingActionEvents.EVENT_NAME,
      eventProperties: { taskcard_event_source: PendingActionEvents.REIMBURSEMENT_PENDING_APPROVAL },
    },
    fund_transfer_approval: {
      icon: fundtransfer,
      link: "/approvalRequestCenter?activeTab=fund",
      param: "id",
      eventName: PendingActionEvents.EVENT_NAME,
      eventProperties: { taskcard_event_source: PendingActionEvents.FUND_TRANSFER_APPROVAL },
    },
    accounting_failed_sync: {
      icon: Accounting.core,
      link: `${ACCOUNTING_TRANSACTIONS_WORKFLOW}?status=failed_sync`,
      param: "id",
      eventName: PendingActionEvents.EVENT_NAME,
      eventProperties: { taskcard_event_source: PendingActionEvents.ACCOUNTING_FAILED_SYNC },
    },
    accounting_pending_review: {
      icon: Accounting.core,
      link: `${ACCOUNTING_TRANSACTIONS_WORKFLOW}?status=pending_review`,
      param: "id",
      eventName: PendingActionEvents.EVENT_NAME,
      eventProperties: { taskcard_event_source: PendingActionEvents.ACCOUNTING_PENDING_REVIEW },
    },
    accounting_missing_receipt: {
      icon: Accounting.core,
      link: `/transactions?tab=all&hasAttachment=no${
        showTrxnRequesterFilter ? `&requesterIds=${GetUserId()}&teams=${getLoggedinUserTeams.join(",")}` : ""
      }`,
      param: "id",
      eventName: PendingActionEvents.EVENT_NAME,
      eventProperties: { taskcard_event_source: PendingActionEvents.ACCOUNTING_MISSING_RECEIPT },
    },
    card_pending_approval: {
      icon: pendingcard,
      link: "/approvalRequestCenter?activeTab=card",
      eventName: PendingActionEvents.EVENT_NAME,
      eventProperties: { taskcard_event_source: PendingActionEvents.CARD_PENDING_APPROVAL },
    },
  };
};

const setTaskIdToLink = (task, data) => {
  if (task?.id?.trim()?.length && data.param) {
    return `${data.link}&${data.param}=${task.id}`;
  }

  return data?.link;
};

const GetPendingTasks = (
  tasks: PendingActionPayload[],
  getLoggedinUserTeams: string[],
  showTrxnRequesterFilter: boolean
) => {
  return tasks?.map((task, index) => {
    const CategoryDetails = PendingActionsCategoriesWithIcons(getLoggedinUserTeams, showTrxnRequesterFilter, task.id)[
      task.category
    ];
    if (task.category === BILL_PENDING_TASK) {
      CategoryDetails.link = CENTRALISED_APPROVAL_BILL_URL;
    } else if (task.category === REIMBURSEMENT_PENDING_APPROVAL) {
      CategoryDetails.link = CategoryDetails.centralised_link;
      CategoryDetails.param = "processid";
    }

    const cardRedirectLink = setTaskIdToLink(task, CategoryDetails);

    if (!CategoryDetails) return null;

    return (
      <TaskCard
        key={index}
        iconSrc={CategoryDetails?.icon}
        iconAlt={task.category}
        title={task.title}
        description={task.description}
        cardRedirectLink={cardRedirectLink}
        showButton={task.category === "insufficient_balance"}
        btnText={"Top Up"}
        btnRedirectLink={"/topup"}
        action={() => trackEvent(CategoryDetails?.eventName, CategoryDetails.eventProperties)}
      />
    );
  });
};

export const getPendingActionByState = (
  pendingActionsData: PendingActionsReducer,
  isAdmin: boolean,
  isWalletUser: boolean,
  getLoggedinUserTeams: string[],
  showTrxnRequesterFilter: boolean
) => {
  const { loading = false, data = [] } = pendingActionsData ?? {};
  const hasPendingActions = data?.length > 0;

  switch (true) {
    case loading:
      return <PendingActionsShimmer />;

    case hasPendingActions:
      return GetPendingTasks(data, getLoggedinUserTeams, showTrxnRequesterFilter);

    case isAdmin && !isWalletUser:
      return (
        <SectionEmptyState
          iconSrc={emptyDefaultIcon}
          iconAlt="empty state icon"
          message="Let’s start your first transaction with Spenmo!"
        />
      );

    default:
      return (
        <SectionEmptyState
          iconSrc={emptyDefaultIcon}
          iconAlt="default state icon"
          message="Congratulations! You’re all done."
        />
      );
  }
};
