export enum AnalyticsEventsName {
  PAGE_LOADED = "Analytics Page Loaded",
  DATA_POINT_CLICKED = "Analytics Data Point Clicked",
}

export enum AnalyticsCardTitle {
  SPENDING_TREND = "Spending Trend",
  CATEGORIES = "Categories",
  MERCHANTS = "Merchants",
  BUDGETS = "Budgets",
}
