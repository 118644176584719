import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { Typography, Banner, InfoFilled } from "@spenmo/splice";
import { TAX_STATUS } from "constants/Tax.constant";
import { trackEvent } from "utility/analytics";
import SideSection from "Modules/sideSection";
import DataTable, { Table, DATA_TABLE_SIZE_TYPES, DATA_TABLE_PADDING } from "Modules/DS/DataTable";

import ErrorComponent from "Views/State/Error";
import CategoryDetails from "Views/Categories/Details";
import { ICategory } from "Views/Categories/types";
import AddCategory from "Views/Categories/Add";
import { useToasterContext, TOASTER_STATUS_TYPE } from "Views/Settings/context/ToasterContext";

import { CASBIN_PAGES } from "Permission/Pages";
import { CATEGORIES_PAGE } from "Permission/Actions";
import usePermissionCheck from "Permission/usePermissionCheck";

import { fetchCategories } from "Redux/Actions/Category/list";
import { getAllTaxesFunc } from "Redux/Actions";
import { accountingPartnerAuth } from "Redux/Actions/accountingPartnerAuth";

import { CategoryContext } from "../context/CategoryContext";
import { categoryEvents } from "../Analytics";
import { columnData } from "./columnData";

import "./styles.scss";

const CategoriesList = ({ newSettingPage }: { newSettingPage: boolean }) => {
  const location = useLocation();
  const permissionParam = [
    { object: CASBIN_PAGES.CATEGORIES_PAGE, action: CATEGORIES_PAGE.ADD_CATEGORY_BUTTON },
    { object: CASBIN_PAGES.CATEGORIES_PAGE, action: CATEGORIES_PAGE.EDIT_CATEGORY_BUTTON },
  ];

  const [ISALLOWED_ADD_CATEGORY, ISALLOWED_EDIT_CATEGORY]: any = usePermissionCheck(permissionParam) || [];
  const [activePage, setActivePage] = useState(0);
  const [view, setView] = useState("create");
  const [details, setDetails] = useState<ICategory>({} as ICategory);
  const [showSideSection, setShowSideSection] = useState(false);
  const { setToaster } = useToasterContext();

  const [showImportModal, setShowImportModal] = useState(new URLSearchParams(location.search).get("import") === "true");

  const dispatch = useDispatch();
  const { categoryReducer, missingCategories, accountingPartner } = useSelector((state: any) => ({
    categoryReducer: state.categoryReducer?.list?.categoryList || {},
    accountingPartner: state.accountingPartnerAuthReducer.data,
    missingCategories: state.categoryReducer?.list?.missingCategories || {},
  }));

  const { data, loading, error } = categoryReducer;
  const showReImportAlert = Number(missingCategories?.count || 0) !== 0;
  const connectedToAccountingPartner = accountingPartner?.payload?.has_valid_token;
  const accountingPartnerName = accountingPartner?.payload?.partnerName;

  const categoryContext = useMemo(
    () => ({
      setToaster,
      refetch: (importedCategory) => dispatch(fetchCategories(connectedToAccountingPartner, importedCategory)),
    }),
    [dispatch, setToaster, connectedToAccountingPartner]
  );

  const tableProps = {
    total: data?.length,
    loading,
    pageNum: activePage,
    pageSize: 10,
    dataSource: data,
    onPageChange: (page) => setActivePage(page - 1),
    emptyState: <p>Categories not found</p>,
    rowAction: (record: ICategory) => {
      handleAction(record, "edit");
    },
  };

  const handleAction = (record, activeView) => {
    setDetails(record);
    setView(activeView);
    setShowSideSection(true);
    trackEvent(categoryEvents.EXPENSE_CATEGORY_DETAIL_VIEWED, { accounting_settings_event_source: "Expense Category" });
  };

  const onFinish = ({ message, type = "success", status = "" }) => {
    setShowSideSection(false);
    dispatch(fetchCategories(connectedToAccountingPartner));

    const event = {
      add: categoryEvents.ADD_CATEGORY,
      edit: categoryEvents.EDIT_CATEGORY,
      deleted: categoryEvents.DELETE_CATEGORY,
    };

    status && trackEvent(event?.[status], { accounting_settings_event_source: "Expense Category" });

    setToaster({
      show: true,
      message,
      type: type === "success" ? TOASTER_STATUS_TYPE.SUCCESS : TOASTER_STATUS_TYPE.ERROR,
    });
  };

  const onImport = () => {
    trackEvent(categoryEvents.IMPORT_FROM_XERO_FROM_BANNER);
    setShowImportModal(true);
  };

  useEffect(() => {
    dispatch(accountingPartnerAuth());
    dispatch(getAllTaxesFunc({ status: TAX_STATUS.ACTIVE }));
  }, [dispatch]);

  useEffect(() => {
    // The reason we are calling it here because
    // we need to wait until the accounting partner connection
    // has established, hence after is established
    // We can determine if we need to find missing category or not
    if (connectedToAccountingPartner !== undefined) {
      dispatch(fetchCategories(connectedToAccountingPartner));
    }
  }, [connectedToAccountingPartner, dispatch]);

  if (error) {
    return <ErrorComponent />;
  }

  return (
    <CategoryContext.Provider value={categoryContext}>
      <DataTable
        className="categories-listing"
        padding={newSettingPage ? DATA_TABLE_PADDING.NONE : DATA_TABLE_PADDING.GRID}
        size={DATA_TABLE_SIZE_TYPES.LARGE}
      >
        <div className="header">
          <Typography size="m" variant="headline-content" tag="h3">
            Category
          </Typography>
          {ISALLOWED_ADD_CATEGORY && (
            <AddCategory
              onnAddNewCategory={() => handleAction({}, "create")}
              showImportModal={showImportModal}
              setShowImportModal={setShowImportModal}
              hasValidToken={connectedToAccountingPartner}
              partnerName={accountingPartnerName}
            />
          )}
        </div>
        <Typography tag="p" size="m" variant="body-content" className="caption">
          Each transaction may be assigned a Category for ease of tracking across your business expenses
        </Typography>
        <div className={showReImportAlert ? "banner-wrapper" : ""}>
          {showReImportAlert && (
            <Banner
              variant="info"
              icon={InfoFilled}
              title=""
              description={
                <p className="banner-message">
                  There are some categories in {accountingPartnerName} which do not currently exist in Spenmo.{" "}
                  <span className="highlight" onClick={onImport}>
                    Import from {accountingPartnerName}
                  </span>
                </p>
              }
            />
          )}
        </div>
        <Table<ICategory> {...tableProps}>{columnData}</Table>
        {showSideSection && ISALLOWED_EDIT_CATEGORY && (
          <SideSection visible={showSideSection} onClose={() => setShowSideSection(false)}>
            <CategoryDetails
              isEdit={view === "edit"}
              details={details}
              onFinish={onFinish}
              loading={loading}
              connectedToAccountingPartner={connectedToAccountingPartner}
              onCancel={() => setShowSideSection(false)}
            />
          </SideSection>
        )}
      </DataTable>
    </CategoryContext.Provider>
  );
};

export default CategoriesList;
