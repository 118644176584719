import { Tags } from "@spenmo/splice";
import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import { CHIP_STATUS } from "Modules/DS/Chip";
import { getSelfApprovalList } from "Redux/DataCalls/ApprovalFlow.api";
interface SelfApprovalInfoProps {
  setShowSelfApprovalPanel: React.Dispatch<React.SetStateAction<boolean>>;
  processType: string;
}

const SelfApprovalInfo = ({
  setShowSelfApprovalPanel,
  processType,
}: SelfApprovalInfoProps) => {
  const [defaultSelfApproverPolicy, setDefaultSelfApproverPolicy] =
    useState(null);
  const title = defaultSelfApproverPolicy ? "Any Admins (Default)" : "User(s)";
  const status = defaultSelfApproverPolicy
    ? CHIP_STATUS.NEUTRAL
    : CHIP_STATUS.INFO;

  useEffect(() => {
    if (processType) {
      getSelfApprovalList(processType).then((res: any) => {
        const defaultSelfApprover =
          res?.data?.payload?.users.length === 1 &&
          res?.data?.payload?.users[0].assigneeRole === "admin";
        setDefaultSelfApproverPolicy(defaultSelfApprover);
      });
    }
  }, [processType]);

  return (
    <div className={`${styles.container}`}>
      <section className={`${styles.info}`}>
        <span className={`${styles.label}`}>Allow self approval for:</span>
        {defaultSelfApproverPolicy !== null && (
          <Tags.Label hierarchy="default" label={title} variant={status} />
        )}
      </section>
      <span className={`${styles.separator}`}></span>
      <section
        className={`${styles["view-policy-link"]}`}
        onClick={() => setShowSelfApprovalPanel(true)}
      >
        View Policy
      </section>

      <section className={`${styles["kb-article-link"]}`}>
        <a
          href="https://support.spenmo.com/hc/en-us/articles/20160341491993"
          target="_blank"
          rel="noreferrer"
          className={`${styles["kb-article-link__text"]}`}
        >
          Learn more about Self Approval
        </a>
      </section>
    </div>
  );
};

export default SelfApprovalInfo;
