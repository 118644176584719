import React from "react";

import {
  IContainerBuilder,
  IQuickFilterContainer,
  IQuickFilterContainerWithSection,
  intitiaFilterItemProps,
} from "Modules/DS/Filter/@types";
import { IItemID } from "Modules/DS/Menu";

import { ListContainer, SectionList } from "./List";

import { ContainerBuilder } from "./Builder";

export const Container = <T extends object = any>({
  children,
  listProps,
  header = null,
  footer = null,
  iconClass = "",
  filterItemProps = { ...intitiaFilterItemProps },
}: IQuickFilterContainer<T>): JSX.Element => {
  const props: IContainerBuilder = {
    filterItemProps,
    iconClass,
    ...(header && { header }),
    ...(footer && { footer }),
  };

  return (
    <ContainerBuilder {...props}>
      <ListContainer {...listProps}>{children}</ListContainer>
    </ContainerBuilder>
  );
};

export const ContainerWithSection = <T extends IItemID, K extends object = any>({
  children,
  listProps,
  header = null,
  footer = null,
  iconClass = "",
  filterItemProps = { ...intitiaFilterItemProps },
}: IQuickFilterContainerWithSection<T, K>): JSX.Element => {
  const props: IContainerBuilder = {
    filterItemProps,
    iconClass,
    header,
    footer,
  };

  return (
    <ContainerBuilder {...props}>
      <SectionList {...listProps}>{children}</SectionList>
    </ContainerBuilder>
  );
};
