export const analytics = {
  filterBtnClicked: "button clicked",
  cancelFilter: "cancel clicked",
  clearAll: "clear all clicked",
  apply: "apply clicked",
  employeeSelected: "employee selected",
  teamSelected: "team selected",
  transactionTypeSelected: "transaction type selected",
  requestTypeSelected: "request type selected",
  requestStatusSelected: "request status selected",
  kycStatusSelected: "kyc status selected",
  syncStatusSelected: "sync status selected",
  minAmountSelected: "min amount entered",
  maxAmountSelected: "max amount entered",
  startDateSelected: "start date selected",
  endDateSelected: "end date selected",
  transactionMerchantSelected: "merchant selected",
  categorySelected: "category selected",
};
