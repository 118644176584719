import * as React from "react";
import { Tag } from "antd";
import "Views/Card/Components/Tag/index.scss";

export type TagType = "default" | "error" | "success";

export interface ITags {
  tagName: string;
  tagType: TagType;
}

export default function CardTag({ tagName, tagType }: ITags) {
  return (
    <Tag color={tagType} className="cards-tag">
      <span className="cards-tag__dot" />
      {tagName}
    </Tag>
  );
}
