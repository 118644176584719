import { ISidePanelButton } from "Modules/DS/SidePanel";
import { ListData } from "Modules/DS/Tabs";
import { IDetail, ISection, ITransactionHeaderProps, IList } from "Modules/TransactionDetail";
import React from "react";

export enum EWorkflowStatus {
  READY_TO_REVIEW = "Ready to Review",
  INCOMPLETE_DETAILS = "Incomplete Details",
  IN_QUEUE = "In Queue",
  SYNC_FAILED = "Sync Failed",
  COMPLETED = "Completed",
}

export enum EWorkflowApiStatus {
  MISSING_INFO = "MISSING_INFO",
  COMPLETED = "COMPLETED",
  FAILED_SYNC = "FAILED_SYNC",
  PENDING_REVIEW = "PENDING_REVIEW",
  QUEUED = "QUEUED",
}

export interface AccountingCount {
  [EWorkflowApiStatus.COMPLETED]: number;
  [EWorkflowApiStatus.FAILED_SYNC]: number;
  [EWorkflowApiStatus.MISSING_INFO]: number;
  [EWorkflowApiStatus.QUEUED]: number;
  [EWorkflowApiStatus.PENDING_REVIEW]: number;
  called?: boolean;
}

export interface ITransaction {
  id: string;
  transactionNumber: string;
  createdAt: Date;
  transactionType: string;
  merchant: string;
  amount: number | string;
  currency?: string;
  currentStatusId: string;
  employee: {
    id: string;
    name: string;
    team: string;
  };
  transactionStatus?: IList[];
  details?: IDetail[];
  billInformation?: ISection[];
  category: string;
  receipt: string[];
  status: EWorkflowStatus;
  accountingMessage: string;
  accountingTransactionStatus: string;
  isDebit: boolean;
  sort: [number, string];
  rawAmount: number;
}

export interface IWorkflowStatus {
  status: EWorkflowStatus;
  transaction: ITransaction;
}

export interface ITabConfigComponent {
  activeKey: string;
  defaultStatus: EWorkflowApiStatus;
  connected: boolean;
  connectedUsingExpenseIntegration: boolean;
  connectedUsingBankFeedIntegration: boolean;
}

export interface ITabConfig extends ListData {
  id: number;
  key: string;
  enabled: boolean;
  Component: React.FC<ITabConfigComponent>;
}

export interface IFilter {
  id: number;
  text: string;
  counter?: number;
  active: boolean;
  status: EWorkflowApiStatus;
}
export interface IWorkflowFilter {
  filter: IFilter[];
  onClick: (status: IFilter) => void;
}

export interface ITransactionSidePanelProps {
  visible: boolean;
  setVisible: (value: boolean) => void;
  transactionStatus?: IList[];
  header?: ITransactionHeaderProps;
  details?: IDetail[];
  billInformation?: ISection[];
  type: string;
  withSyncDetails?: boolean;
  buttons: ISidePanelButton[];
  currentStatusId: string;
  record: ITransaction;
  relatedTransaction: ITransaction | null;
  onClickRelatedTransaction: () => void;
  customBannerMessage?: string | React.ReactNode;
  ignoreRequiredCheck?: boolean;
  activeTab?: string;
}

export interface IRemainderEmail {
  visible: boolean;
  loading: boolean;
  onClose: () => void;
  count: number;
  onSendRemainder: (employee: string) => void;
  showErrorToaster: (error: any) => void;
  transactionIds: string[];
}

export interface IWarningModal {
  visible: boolean;
  loading: boolean;
  message: string | React.ReactNode;
  buttonText: string;
  title: string;
  onClose: () => void;
  handleSync: () => void | Promise<void>;
}

export interface IMoreActionProps {
  setShowMoreActionMenu: (value: boolean) => void;
  selectedIds: string[];
  selectedTransactionNumbers?: string[];
  onProcess: () => void;
  onSuccess: (message: string, type: string, options?: { subscriptionId: string }) => void;
  onError: (error: any) => void;
  onFinish: () => void;
  showMoreActionmenu: boolean;
  setShowEmailReminder?: (value: boolean) => void;
  setShowWarningModal?: (value: boolean) => void;
  employeeCount?: number;
  connected?: boolean;
  status?: EWorkflowApiStatus;
  showAddSubscriptionItem?: boolean;
}

export interface IForceSyncProps {
  visible: boolean;
  loading: boolean;
  onClose: () => void;
  onForceSync: () => void;
  missingFields: string[];
  connected: boolean;
}

export interface IRow {
  value: React.ReactNode | string;
  searchKey?: string;
  id: number;
}
export interface IBasicSearchProps {
  onSelected: (data: IRow) => void;
  type: string;
  data: IRow[];
  transactionCount: number;
}
