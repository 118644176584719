import {
  FETCH_GA_CONNECTION_STATUS_REQUEST,
  FETCH_GA_CONNECTION_STATUS_SUCCESS,
  FETCH_GA_CONNECTION_STATUS_FAILURE,
  GA_STATUS_DATA_RESET,
} from "Redux/Actions/SecurityHub/GoogleAuthenticator/ConnectionStatusActions";
import { getPrefixedInitialState } from "Redux/InitialState";

export const gaConnectionStatusReducer = (state = getPrefixedInitialState("gaConnectionStatus"), action = {}) => {
  switch (action.type) {
    case FETCH_GA_CONNECTION_STATUS_REQUEST:
      return {
        ...state,
        gaConnectionStatusLoading: true,
        gaConnectionStatusData: null,
        gaConnectionStatusError: false,
      };
    case FETCH_GA_CONNECTION_STATUS_SUCCESS:
      return {
        ...state,
        gaConnectionStatusLoading: false,
        gaConnectionStatusData: action.data,
        gaConnectionStatusError: false,
      };
    case FETCH_GA_CONNECTION_STATUS_FAILURE:
      return {
        ...state,
        gaConnectionStatusLoading: false,
        gaConnectionStatusData: action.data,
        gaConnectionStatusError: true,
      };
    case GA_STATUS_DATA_RESET:
      return getPrefixedInitialState("gaConnectionStatus");
    default:
      return state;
  }
};
