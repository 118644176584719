import axios from "axios";

export const FETCH_DATA_REQUEST = "FETCH_DATA_REQUEST";
export const FETCH_DATA_SUCCESS = "FETCH_DATA_SUCCESS";
export const FETCH_DATA_FAILURE = "FETCH_DATA_FAILURE";

export const fetchDataReq = (data, comp) => {
  return {
    type: FETCH_DATA_REQUEST,
    comp: comp,
  };
};
export const fetchDataSuccess = (data, comp) => {
  return {
    type: FETCH_DATA_SUCCESS,
    data: data,
    comp: comp,
  };
};
export const fetchDataError = (data, comp) => {
  return {
    type: FETCH_DATA_FAILURE,
    data: data,
    comp: comp,
  };
};
export const getData = (url, dataObj = {}, componentName = "default") => {
  return (dispatch) => {
    dispatch(fetchDataReq(componentName));
    axios
      .get(url, dataObj)
      .then((data) => {
        dispatch(fetchDataSuccess(data, componentName));
      })
      .catch((err) => {
        dispatch(fetchDataError(err, componentName));
      });
  };
};

export const postData = (url, dataObj = {}, componentName = "default") => {
  return (dispatch) => {
    dispatch(fetchDataReq(componentName));
    axios
      .post(url, dataObj)
      .then((data) => {
        dispatch(fetchDataSuccess(data, componentName));
      })
      .catch((err) => {
        dispatch(fetchDataError(err, componentName));
      });
  };
};
