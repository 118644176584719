/**
 * `useSetUserConsent` is a custom React hook that manages the user consent process.
 * It sets the user consent and handles errors, while providing loading state management.
 * It can also dispatch a custom action on success.
 *
 * @module useSetUserConsent
 * @param {Function} [dispatchOnSuccess] - Optional callback function to be called on successful user consent.
 * @returns {IAgreeAgreements} - An object containing the `agreeAgreements` function and the `loading` state.
 */

import { useContext, useState } from "react";

import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";

import { getUserConsent, setUserConsent } from "Views/KYX/DataCalls";
import { IAgreeAgreements, IKYXToastContext } from "Views/KYX/@types";
import { KYXErrorToaster, KYXToastContext } from "Views/KYX/Context";

export const useSetUserConsent = (dispatchOnSuccess?: () => void): IAgreeAgreements => {
  // State to manage the loading status.
  const [loading, setLoading] = useState(false);
  // Access the `setToaster` function from the KYXToastContext.
  const { setToaster } = useContext<IKYXToastContext>(KYXToastContext);

  /**
   * `agreeAgreements` is an asynchronous function that sets the user consent.
   * It handles errors and success cases, and calls the optional `dispatchOnSuccess` function if provided.
   */
  const agreeAgreements = async () => {
    try {
      setLoading(true);
      const response = await setUserConsent();
      const errorMessage = response.data?.error?.message || response.data?.meta?.message;

      if (response?.data?.status > HTTP_STATUS_CODE.OK) {
        throw new Error(errorMessage);
      } else if (!response.data?.payload?.isSuccess && response.data?.payload?.message !== "Successfully") {
        throw new Error(response.data?.payload?.message || errorMessage);
      }
      // Call the optional `dispatchOnSuccess` function if provided.
      dispatchOnSuccess?.();
    } catch (error) {
      // Create and show an error toast with the error message.
      const toast = KYXErrorToaster(error?.response?.data?.error?.message || error);
      setToaster(toast);
    } finally {
      setLoading(false);
    }
  };

  return { agreeAgreements, loading } as IAgreeAgreements;
};

export const useGetUserConsent = (dispatchOnSuccess?: () => void) => {
  // State to manage the loading status.
  const [loading, setLoading] = useState(false);
  // Access the `setToaster` function from the KYXToastContext.
  const { setToaster } = useContext<IKYXToastContext>(KYXToastContext);

  /**
   * `agreeAgreements` is an asynchronous function that gets the user consent.
   * It handles errors and success cases, and calls the optional `dispatchOnSuccess` function if provided.
   */
  const getConsent = async () => {
    try {
      setLoading(true);
      const response = await getUserConsent();
      const errorMessage = response.data?.error?.message || response.data?.meta?.message;

      if (response?.data?.status > HTTP_STATUS_CODE.OK) {
        throw new Error(errorMessage);
      }
      // Call the optional `dispatchOnSuccess` function if provided.
      if (!response.data.payload.isAgreedLatestTnC) {
        dispatchOnSuccess?.();
      }
      return response.data.payload;
    } catch (error) {
      // Create and show an error toast with the error message.
      const toast = KYXErrorToaster(error?.response?.data?.error?.message || error);
      setToaster(toast);
    } finally {
      setLoading(false);
    }
  };

  return { getConsent, loading };
};
