import { useCallback, useMemo } from "react";

import { useVerifiedReceipts } from "Views/Transaction/Details";

export const useVerifiedReceiptsMemoized = (urlParam: string) => {
  const decodedUrl = useMemo(() => {
    try {
      return atob(urlParam);
    } catch (error) {
      return null;
    }
  }, [urlParam]);

  const { fetchReceipts, attachments, loading, isComplete, error } = useVerifiedReceipts([decodedUrl]);

  const memoizedFetchReceipts = useCallback(() => {
    fetchReceipts();
  }, [fetchReceipts]);

  return { attachments, loading, error: error || !decodedUrl, fetchReceipts: memoizedFetchReceipts, isComplete };
};
