import React from "react";
import { IRouterType, PAGE_GRID_COLUMNS, PAGE_GRID_CONTENT_TYPE } from "Route/@types";
import { ACCOUNTING_PATHS } from "Route/Accounting/paths";
import CodatCallback from "Views/Accounting/Callback/Codat";

// Accounting Components
import Setup from "Views/Accounting/Setup";
import ImportBillFromXero from "Views/Bills/ImportBillFromXero";

import Transactions from "Views/Transaction";

export const AccountingRoute: IRouterType[] = [
  {
    id: "accounting-1.1",
    path: ACCOUNTING_PATHS.ACCOUNTING_TRANSACTIONS,
    // Accounting page is moved under Transactions page and can be viewed through the Accounting toggle feature
    component: <Transactions />,
    title: "Accounting under Transctions work flow",
    authRequired: true,
    exactPath: true,
    grid: {
      columns: PAGE_GRID_COLUMNS.NONE,
    },
  },
  {
    id: "accounting-5",
    path: ACCOUNTING_PATHS.XERO_SETUP,
    component: <Setup />,
    title: "Accounting xero setup",
    authRequired: true,
    exactPath: true,
    grid: {
      content: PAGE_GRID_CONTENT_TYPE.FIXED,
      columns: PAGE_GRID_COLUMNS.NONE,
    },
  },
  {
    id: "accounting-6",
    path: ACCOUNTING_PATHS.IMPORT_BILL_FROM_XERO,
    component: <ImportBillFromXero />,
    title: "Import bill from xero",
    authRequired: true,
    exactPath: true,
  },
  {
    id: "accounting-7",
    path: ACCOUNTING_PATHS.NETSUITE_CALLBACK,
    component: <CodatCallback />,
    title: "Netsuite Callback",
    authRequired: true,
    exactPath: true,
  },
  {
    id: "accounting-8",
    path: ACCOUNTING_PATHS.NETSUITE_SETUP,
    component: <Setup netsuiteIntegration />,
    title: "Accounting netsuite setup",
    authRequired: true,
    exactPath: true,
    grid: {
      content: PAGE_GRID_CONTENT_TYPE.FIXED,
      columns: PAGE_GRID_COLUMNS.NONE,
    },
  },
];
