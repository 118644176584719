import APIclient from "API/Client";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import { ILegalEntityTypePayload } from "./types";

export enum ENTITY_TYPES {
  REQUEST = "GET_ENTITY_TYPE_REQUEST",
  SUCCESS = "GET_ENTITY_TYPE_SUCCESS",
  ERROR = "GET_ENTITY_TYPE_ERROR",
}

const fetchDataRequest = () => {
  return {
    type: ENTITY_TYPES.REQUEST,
  };
};

const fetchDataSuccess = (data: ILegalEntityTypePayload) => {
  return {
    type: ENTITY_TYPES.SUCCESS,
    data,
  };
};

const fetchDataError = (data: ILegalEntityTypePayload) => {
  return {
    type: ENTITY_TYPES.ERROR,
    data,
  };
};

export const getEntityTypes = () => {
  return (dispatch) => {
    dispatch(fetchDataRequest());

    APIclient.getData("ms/spm-organization/v1/kyb/constants?countryCode=SG&type=company_type")
      .then((response) => {
        if (response?.data?.status === HTTP_STATUS_CODE.OK) {
          dispatch(fetchDataSuccess(response.data));
        } else {
          dispatch(fetchDataError(response.data));
        }
      })
      .catch((error) => {
        dispatch(fetchDataError(error?.response?.data));
      });
  };
};
