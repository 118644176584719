import { createContext } from "react";

import { TOASTER_STATUS_TYPE } from "Modules/DS/Toaster";

import { ITrxnToastContext } from "Views/Transaction/@types";
import { DEFAULT_ERROR_MESSAGE } from "Views/Transaction/Constants";

export const initialTrxnToast: ITrxnToastContext = {
  toaster: {
    show: false,
    message: DEFAULT_ERROR_MESSAGE,
    type: TOASTER_STATUS_TYPE.ERROR,
    actionLabel: "",
    action: () => null,
  },
  setToaster: () => null,
};

export const TrxnToastContext = createContext<ITrxnToastContext>(initialTrxnToast);
