import React, { forwardRef, useCallback, useEffect, useMemo } from "react";
import { Input as AntdInput } from "antd";
import { useFormContext } from "react-hook-form";
import qs from "query-string";

import { getData, postData } from "API/Client";
import { useMutableData } from "API/useData";

import CurrencyInput from "./CurrencyInput";

import { useBillForm } from "Views/Bills/V2/context/FormContext";
import { useErrorHandler } from "Views/Bills/V2/context/ErrorHandlerContext";

import { API_URL } from "Views/Bills/V2/constants";
import { GetCurrencyCode, debounce } from "utility";

import { InvoiceAmountInputProps, VendorDynamicFieldNames } from "Views/Bills/V2/components/FormGenerator/type";
import { BillFormProviderProps, FieldConsumerType } from "Views/Bills/V2/BillForm/type";
import { BillFeeParameters } from "./TwoWayPayment/type";

const InvoiceAmount = forwardRef(
  (props: Partial<InvoiceAmountInputProps & FieldConsumerType>, ref: React.RefObject<AntdInput>) => {
    const {
      selectProps,
      name: amountName,
      swiftPaymentChargeType: defaultSwiftPaymentChargeType,
      youPayName,
      recipientName,
    } = props;
    const { controllerProps } = selectProps;
    const { name: selectName } = controllerProps || {};

    const { watch, setValue } = useFormContext();
    const { setIsSubmitLoading } = useBillForm<BillFormProviderProps>();
    const { handleError } = useErrorHandler();

    const [invoiceCurrency, invoiceAmount] = watch([selectName, amountName]);

    const vendorID = watch("vendorID");
    const swiftPaymentChargeType = watch("swiftPaymentChargeType", defaultSwiftPaymentChargeType || "OUR");

    const {
      data: recipientDetail,
      mutate: refetchDetail,
      error: recipientDetailError,
    } = useMutableData(vendorID ? `${API_URL.recipientDetail}/${vendorID}` : null);

    const { dynamicFields } = recipientDetail?.data?.payload || {};

    const vendorCountryCode = useMemo(() => {
      return dynamicFields?.find((item) => {
        return item.alias === VendorDynamicFieldNames.countryCode;
      })?.value;
    }, [dynamicFields]);

    const vendorCurrencyCode = useMemo(() => {
      return dynamicFields?.find((item) => {
        return item.alias === VendorDynamicFieldNames.currencyCode;
      })?.value;
    }, [dynamicFields]);

    const youPayCurrencyCode = GetCurrencyCode();

    const fetchBillFee = (params: Partial<BillFeeParameters>) => {
      const isReceiver = "receiverAmount" in params;
      const isSender = "totalAmount" in params;
      if (isReceiver || isSender) {
        const getBillFeeAPI = qs.stringifyUrl({
          url: API_URL.getBillFee,
          query: params,
        });
        return getData(getBillFeeAPI)
          .then((res) => {
            const billFee = res?.data?.payload;
            const { amountFx, totalAmount, receiverAmount, amountFxRate } = billFee;

            // IMPROVEMENT: should just give back what's on the field (BE changes)
            // custom amount value
            setValue("walletAmount", amountFx);
            // Note: save fxRate which use in preview page
            setValue("fxRate", amountFxRate);

            if (isReceiver) {
              setValue(youPayName, totalAmount, { shouldValidate: true });
            } else {
              setValue(recipientName, receiverAmount, { shouldValidate: true });
            }
          })
          .catch((e) => {
            // TO DO: if failed
            console.error(e);
            handleError({
              retry: {
                id: "fetchBillFee",
                onClickRetry: () => fetchBillFee(params),
              },
            });
          })
          .finally(() => {
            setIsSubmitLoading(false);
          });
      }
    };

    const fetchGetRecipientAmount = (params: {
      invoiceAmount: string;
      invoiceCurrency: string;
      vendorCurrencyCode: string;
      vendorCountryCode: string;
      youPayCurrencyCode: string;
      swiftPaymentChargeType: "SHA" | "OUR";
    }) => {
      const {
        invoiceAmount,
        invoiceCurrency,
        vendorCurrencyCode,
        vendorCountryCode,
        youPayCurrencyCode,
        swiftPaymentChargeType,
      } = params || {};

      postData(API_URL.getRecipientAmount, {
        billAmount: Number(invoiceAmount),
        billCurrency: invoiceCurrency,
        recipientCurrency: vendorCurrencyCode,
      })
        .then((res) => {
          const { recipientAmount } = res.data.payload;
          setValue(recipientName, recipientAmount);
          fetchBillFee({
            receiverAmount: Number(recipientAmount),
            swiftPaymentChargeType,
            receiverCountryCode: vendorCountryCode,
            receiverCurrency: vendorCurrencyCode,
            senderCurrency: youPayCurrencyCode,
            invoiceAmount: invoiceAmount,
            invoiceCurrency: invoiceCurrency,
          });
        })
        .catch((e) => {
          console.error(e);
          handleError({
            retry: {
              id: "fetchGetRecipientAmount",
              onClickRetry: () => fetchGetRecipientAmount(params),
            },
          });
        });
    };

    const getRecipientAmount = useCallback(debounce(fetchGetRecipientAmount), []);

    // handle recipient detail error
    useEffect(() => {
      if (recipientDetailError) {
        handleError({
          retry: {
            id: "recipientDetail",
            onClickRetry: refetchDetail,
          },
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recipientDetailError]);

    useEffect(() => {
      if (
        invoiceCurrency &&
        invoiceAmount &&
        vendorCurrencyCode &&
        vendorCountryCode &&
        youPayCurrencyCode &&
        swiftPaymentChargeType
      ) {
        setIsSubmitLoading(true);
        getRecipientAmount({
          invoiceAmount,
          invoiceCurrency,
          vendorCurrencyCode,
          vendorCountryCode,
          youPayCurrencyCode,
          swiftPaymentChargeType,
        });
      }
    }, [
      invoiceCurrency,
      vendorCurrencyCode,
      invoiceAmount,
      vendorCountryCode,
      youPayCurrencyCode,
      swiftPaymentChargeType,
    ]);

    return <CurrencyInput ref={ref} {...props} />;
  }
);

export default InvoiceAmount;
