import axios from "axios";

export const FETCH_TICKET_FORM_REQUEST = "FETCH_TICKET_FORM_REQUEST";
export const FETCH_TICKET_FORM_SUCCESS = "FETCH_TICKET_FORM_SUCCESS";
export const FETCH_TICKET_FORM_FAILURE = "FETCH_TICKET_FORM_FAILURE";
export const FETCH_TICKET_FORM_CLEAR = "FETCH_TICKET_FORM_CLEAR";

export const fetchDataReq = (data) => {
  return {
    type: FETCH_TICKET_FORM_REQUEST,
    data: data,
  };
};
export const fetchDataSuccess = (data) => {
  return {
    type: FETCH_TICKET_FORM_SUCCESS,
    data: data,
  };
};
export const fetchDataError = (data) => {
  return {
    type: FETCH_TICKET_FORM_FAILURE,
    data: data,
  };
};
export const fetchDataClear = (data) => {
  return {
    type: FETCH_TICKET_FORM_CLEAR,
    data: data,
  };
};

export const CustomTicketFormFunc = (formData, userInfo) => {
  const url = "https://spenmo.zendesk.com/api/v2/requests.json";
  const user = userInfo.email + "/token";
  const api_token = "23ac2c966d7bf79762c5837e57441ac44b8b9aef6842e15e936b40e83a7abc04";
  const headers = { "content-type": "application/json" };
  const HTTP_STATUS_CODE_OK = 201;
  const COMPANY_NAME_FIELD_ID = 900013868443;

  var body = {
    request: {
      comment: { body: formData.description },
      requester: {
        email: userInfo.email,
        name: userInfo.name,
      },
      custom_fields: [{ id: COMPANY_NAME_FIELD_ID, value: userInfo?.organisation_name }],
      subject: "Chat with Spenmo",
    },
  };

  return (dispatch) => {
    dispatch(fetchDataReq());
    axios
      .post(url, JSON.stringify(body), { headers: headers }, { auth: (user, api_token) })
      .then((data) => {
        if (data.status === HTTP_STATUS_CODE_OK) {
          dispatch(fetchDataSuccess(data));
        } else {
          dispatch(fetchDataError(data));
        }
      })
      .catch((err) => {
        dispatch(fetchDataError(err));
      });
  };
};

export const ClearCustomTicketFormFunc = () => {
  return (dispatch) => {
    dispatch(fetchDataClear(null));
  };
};
