import React, { useEffect } from "react";

import { ALL_TRXNS_COLUMNS } from "Views/Transaction/Constants";
import { ITeamMemberData, ITeamWithMembersData } from "Views/Team";

import { Quick } from "Views/Transaction/Filters";
import { useRequesterFetch } from "Views/Transaction/All/useFetch";
import { GET_TRXN_REQUEST_PARAMS } from "Views/Transaction/@types";

export const RequesterFilter = () => {
  const defaultValue = ALL_TRXNS_COLUMNS.REQUESTER;

  const titleKey = "name";
  const subArryTitleKey = "name";
  const subArryIdKey = "user_id";
  const listKey = "members";

  const { data, loading, fetchData } = useRequesterFetch();

  useEffect(() => {
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Quick.Section<ITeamWithMembersData, ITeamMemberData>
      id={GET_TRXN_REQUEST_PARAMS.REQUESTER_ID}
      secondaryId={GET_TRXN_REQUEST_PARAMS.TEAM_ID}
      listKey={listKey}
      titleKey={titleKey}
      subArryIdKey={subArryIdKey}
      subArryTitleKey={subArryTitleKey}
      searchPlaceholder="employee/team"
      defaultValue={defaultValue}
      loading={loading}
    >
      {data}
    </Quick.Section>
  );
};
