import React from "react";
import { Col, Row } from "antd";
import classNames from "classnames";

import { IBulkActionProps, BulkActionType } from "Modules/DS/DataTable";

import { ActionWithMoreMenu } from "./ActionWithMoreMenu";
import { DefaultActions } from "./defaultActions/defaultActions";
import { DestructiveActions } from "./destructiveActions/destructiveActions";

import styles from "./bulkActions.module.scss";
import { plural } from "utility";

export const BulkActions: React.FC<IBulkActionProps> = ({
  data,
  dataName,
  type,
  supportingInfo,
  constructive,
  destructive,
  button,
  firstButton,
  secondButton,
  link,
  moreActionMenu,
  clearSelection,
  showMoreActionMenu,
  setShowMoreActionMenu,
  loadingMoreactionMenu,
  maximum,
  size,
  trackEventData,
  emptySelectionMessage,
}: IBulkActionProps): JSX.Element => {
  const showActions = (actionType: BulkActionType) => {
    switch (actionType) {
      case BulkActionType.Default:
        return <DefaultActions firstButton={firstButton} secondButton={secondButton} />;
      case BulkActionType.Destructive:
        return (
          <DestructiveActions
            constructive={constructive}
            destructive={destructive}
            button={button}
            trackEventData={trackEventData}
          />
        );
      case BulkActionType.Destructive_With_Link:
        return <DestructiveActions constructive={constructive} destructive={destructive} link={link} />;
      case BulkActionType.Action_With_Menu:
        return (
          <ActionWithMoreMenu
            button={button}
            size={size}
            secondButton={secondButton}
            moreActionMenu={moreActionMenu}
            showMoreActionMenu={showMoreActionMenu}
            setShowMoreActionMenu={setShowMoreActionMenu}
            loadingMoreactionMenu={loadingMoreactionMenu}
          />
        );
    }
  };

  return (
    <div className={styles.bulkActionContainer}>
      <div className={classNames(styles["bulk-actions"], "data-table__bulk-actions")}>
        <Row>
          <Col className={styles.marginAuto}>
            <div className={styles["bulk-actions__selection-control"]}>
              <span className={styles["bulk-actions__selection-control__text"]}>
                {data.length === 0 && emptySelectionMessage
                  ? emptySelectionMessage
                  : `${plural(data.length, dataName)} selected`}
              </span>
              {Boolean(maximum) && (
                <span className={styles["bulk-actions__selection-control__hint"]}>(Maximum: 20)</span>
              )}
            </div>
            {data.length !== 0 && (
              <div className={styles["bulk-actions__clear-selection"]} onClick={clearSelection}>
                Clear {plural(data.length, "selection", "s", false)}
              </div>
            )}
          </Col>
          {Boolean(supportingInfo) && (
            <Col className={styles["bulk-actions__supporting-info-container"]}>
              <span className={styles["bulk-actions__supporting-info-content"]}>{supportingInfo}</span>
            </Col>
          )}
          <Col
            className={
              styles[
                `bulk-actions${
                  type === BulkActionType.Destructive_With_Link ? "__action-control-link" : "__action-control"
                }`
              ]
            }
          >
            {showActions(type)}
          </Col>
        </Row>
      </div>
    </div>
  );
};
