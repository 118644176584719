import * as React from "react";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Button, Input } from "antd";
import { appNotification } from "Modules/appNotification/appNotification";
import OverlayLoaderV2 from "Modules/overlayLoaderV2";
import LoadingModal from "Modules/loadingModal";
import { useCheckApprovalFlowAllowed } from "utility/permissions/approval";
import classNames from "classnames";

import "./CreateFlow.scss";
import FlowCanvas from "./FlowCanvas/";
import { greyCloseIcon } from "assets/img";
import { clearGetApprovalFlow, getApprovalFlowFunc } from "Redux/Actions/ApprovalFlow/getApprovalFlow";
import {
  clearCreateApprovalFlow,
  createApprovalFlowFunc,
  editApprovalFlowFunc,
} from "Redux/Actions/ApprovalFlow/createApprovalFlow";
import { getApprovalFlowNewName, checkTeamManager } from "Redux/DataCalls/ApprovalFlow.api";

import PageLeavePrompt from "Modules/PageLeavePrompt";
import { clearValidateApprovalFlow, validateApprovalFlowFunc } from "Redux/Actions/ApprovalFlow/validateApprovalFlow";
import { trackEvent } from "utility/analytics";
import { APPROVAL_FLOW_EVENTS } from "../trackingEvents";
import { FlowObject, Operator, CreateApprovalFlowPayload, CreateFlowUrlParams } from "../types";
import { getEventType, getLabelForFlowTitle, isNewFlow, validateApprovers, validateConditions } from "../helpers";
import { DEFAULT_FLOW_DESC } from "../ApprovalList";
import { useDragScroll } from "../../../customHooks/useDragScroll";
import { usePreventClose } from "../../../customHooks/usePreventClose";
import UpdateFlowModal from "./UpdateFlowModal";
import { setApprovalFlowActiveTab } from "Redux/Actions/ApprovalFlow/handleTabApprovalFlow";
import FaqButton from "Modules/DS/FAQButton";

const CreateFlowPage: React.FC = (): JSX.Element => {
  const history = useHistory();
  const titleRef = useRef(null);

  const flowDetailReducer = useSelector((state: any) => state.getApprovalFlowReducer);
  const publishReducer = useSelector((state: any) => state.createApprovalFlowReducer);
  const flowData = flowDetailReducer?.data?.payload;
  const userInfo = useSelector((state: any) => state.userInfoReducer);

  const [, , action, id] = history.location.pathname.split("/");

  const isEdit = action === "edit" && id;
  const isEditing = action !== "view";
  const isDefault = flowData ? flowData.priority === 0 : false;
  const { search } = history.location;
  const duplicateProcessId = new URLSearchParams(search).get("duplicate_process");
  const teamList = useSelector((state: any) => state.teamList?.data?.payload?.teams || []);
  const defaultTeam = teamList.find((item: any) => item.team_type === "Organisation");

  const dispatch = useDispatch();
  const [title, setTitle] = useState<string>("");
  const [flow, setFlow] = useState<FlowObject>({ conditions: {}, steps: [{}] });
  const [initialFlowState, setInitialFlowState] = useState<FlowObject>({ conditions: {}, steps: [] });
  const [updateFlowConfirmation, setUpdateFlowConfirmation] = useState<boolean>(false);
  const [showPrompt, setShowPrompt] = useState<boolean>(false);
  const [enablePublish, setEnablePublish] = useState<boolean>(false);
  const [hasTeamManager, setHasTeamManager] = useState<boolean>(false);

  const [scrollRef, isScrolling] = useDragScroll(".flow-item, .flow-item *");
  const contentClassNames = classNames("create-flow__content", { scrolling: isScrolling });
  usePreventClose();
  let { processType }: CreateFlowUrlParams = useParams();

  if (isEdit) {
    processType = flowData?.processType;
  }
  const titleLabel = getLabelForFlowTitle(processType, action);

  useEffect(() => {
    dispatch(clearValidateApprovalFlow());
    return () => {
      dispatch(clearValidateApprovalFlow());
      dispatch(clearGetApprovalFlow());
    };
  }, []);

  useEffect(() => {
    if (!isEdit && !duplicateProcessId) {
      getApprovalFlowNewName(processType).then((res: any) => {
        setTitle(res?.data?.payload?.name);
        if (titleRef.current) {
          titleRef.current?.focus();
          titleRef.current?.select();
        }
      });
    }
  }, [titleRef]);

  const isAllowed = useCheckApprovalFlowAllowed(userInfo);

  useEffect(() => {
    if (userInfo.data?.payload && !isAllowed) {
      history.push("/404");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo, isAllowed]);

  useEffect(() => {
    if (isEdit) {
      dispatch(getApprovalFlowFunc(id));
    } else if (action === "create" && duplicateProcessId) {
      dispatch(getApprovalFlowFunc(duplicateProcessId));
    } else {
      dispatch(clearGetApprovalFlow());
    }
  }, [id, duplicateProcessId]);

  useEffect(() => {
    if (flowData && (id || duplicateProcessId)) {
      let flowObject = {};
      let conditions = {};
      if (flowData.condition) {
        if (flowData.condition.wallet?.length) {
          conditions["wallet"] = [...flowData.condition.wallet];
        }
        if (flowData.condition.amount?.operator && flowData.condition.amount?.value) {
          conditions["amount"] = {
            operator: Operator[flowData.condition.amount.operator],
            value: flowData.condition.amount.value,
          };
        }
        if (flowData.condition.expenseCategory?.length) {
          conditions["expenseCategory"] = flowData.condition.expenseCategory.map((item) => ({
            ...item,
            id: String(item.id),
          }));
        }
      }
      flowObject["conditions"] = conditions;
      flowObject["steps"] = [...flowData.stepAssignees];
      setTitle(flowData.name);
      setFlow({ ...flow, ...flowObject });
      setInitialFlowState({ ...flow, ...flowObject });
    }
  }, [flowData]);

  useEffect(() => {
    if (JSON.stringify(flow) !== JSON.stringify(initialFlowState)) {
      setShowPrompt(true);
    }

    const isFlowValid = (isDefault || validateConditions(flow.conditions)) && validateApprovers(flow.steps);

    const noTeamSelected = !flow.conditions["wallet"];
    const defaultTeamWalletId = defaultTeam?.subwallet_id;
    const isDefaultTeamSelected = flow.conditions?.wallet?.some((wallet) => wallet.walletId === defaultTeamWalletId);
    const teamWalletId = noTeamSelected || isDefaultTeamSelected ? defaultTeamWalletId : null;
    if (teamWalletId) {
      checkTeamManager(teamWalletId).then((res) => {
        let hasTeamManager = Boolean(res?.data?.payload?.team?.total_members);
        let isTeamManagerApproverSelected = false;
        setHasTeamManager(hasTeamManager);
        if (flow.steps) {
          flow.steps.forEach((step) => {
            if (step.assignees) {
              step.assignees.forEach((assignee) => {
                if (assignee.role === "manager") {
                  isTeamManagerApproverSelected = true;
                }
              });
            }
          });
        }
        if (isTeamManagerApproverSelected) {
          setEnablePublish(title && isFlowValid && hasTeamManager);
        } else {
          setEnablePublish(title && isFlowValid);
        }
      });
    } else {
      setEnablePublish(title && isFlowValid);
    }
  }, [flow]);

  useEffect(() => {
    if (!publishReducer.loading && publishReducer.data?.payload?.isSuccess) {
      dispatch(clearCreateApprovalFlow());
      appNotification.success({
        message: `${title} successfully ${isEdit ? "updated" : "created"}.`,
      });
      dispatch(setApprovalFlowActiveTab(isEdit ? flowData.processType : processType));
      history.push("/approvalflow");
    } else if (!publishReducer.loading && publishReducer.error) {
      setShowPrompt(true);
      if (publishReducer.errorDetail.message === "duplicate name") {
        appNotification.error({
          message: "The flow name already exists. Please input another naming.",
          icon: <span className="warn warning">&#9888;</span>,
        });
      } else if (publishReducer.errorDetail.message !== "conflicted workflow") {
        appNotification.error({ message: publishReducer.errorDetail.message });
      }
    }
  }, [publishReducer]);

  const buildPayload = (data) => {
    const { conditions, steps } = data;
    const { expenseCategory, amount, wallet } = conditions;
    const payload: CreateApprovalFlowPayload = {
      processType: isEdit ? flowData.processType : processType,
      name: title,
      conditions: {
        ...(expenseCategory && { expenseCategory: expenseCategory.map((item) => item.id) }),
        ...(amount && { amount }),
        ...(wallet && { wallet: wallet.map((item) => item.walletId) }),
      },
      steps: steps.map((step, idx) => ({
        step: idx + 1,
        approvers: step?.assignees?.map((item) => ({
          role: item.role,
          identifier: item.identifier,
        })),
      })),
    };
    return payload;
  };

  const handlePublish = () => {
    setUpdateFlowConfirmation(false);
    setShowPrompt(false);

    const payload = buildPayload(flow);

    if (isEdit) {
      dispatch(editApprovalFlowFunc(id, payload));
    } else {
      dispatch(createApprovalFlowFunc(payload));
    }

    let eventData = {} as any;
    if (payload.conditions.amount) {
      eventData.amount = payload.conditions.amount.operator;
    }
    if (payload.conditions.wallet) {
      eventData.wallet = true;
    }
    if (payload.conditions.expenseCategory) {
      eventData.expenseCategory = true;
    }
    if (payload.steps) {
      eventData.approvers = payload.steps.length;
    }
    if (id) {
      eventData.approval_flow_id = id;
    }
    eventData.default = isDefault;
    eventData.name = payload.name;
    eventData.processType = payload.processType;

    trackEvent(APPROVAL_FLOW_EVENTS.approvalFlowPublished, {
      ...(!isNewFlow(id) && { approval_flow_id: id }),
      is_new_flow: !isEdit,
      approval_flow_type: getEventType(processType),
      approval_flow_name: payload.name,
      condition_expense_category_used: Boolean(payload.conditions.expenseCategory),
      condition_wallet_used: Boolean(payload.conditions.wallet),
      condition_amount_used: Boolean(payload.conditions.amount),
      approver_step_count: payload.steps.length,
      is_default_flow: isDefault,
      approver_in_each_step_count: payload.steps.map((step) => step.approvers.length),
    });
  };

  const validateFlow = () => {
    const payload = buildPayload(flow);

    dispatch(validateApprovalFlowFunc({ id: id || "", ...payload }));
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (publishReducer?.errorDetail?.message === "conflicted workflow") {
        validateFlow();
      }
    }, 200);

    return () => clearTimeout(delayDebounceFn);
  }, [flow, publishReducer]);

  const handleUpdateFlow = () => {
    if (flowData?.totalActiveRequests) {
      setUpdateFlowConfirmation(true);
    } else {
      handlePublish();
    }
  };

  return flowDetailReducer.loading ? (
    <LoadingModal />
  ) : (
    <div className={"create-flow__container"}>
      <div className={"create-flow__header"}>
        <div className={"create-flow__close"}>
          <Button
            onClick={() => {
              dispatch(clearCreateApprovalFlow());
              history.push("/approvalflow");
            }}
          >
            <img src={greyCloseIcon} />
          </Button>
        </div>
        {!isDefault ? <div className="create-flow__title-label">{titleLabel}</div> : <></>}
        <div className={"create-flow__title"}>
          {isDefault ? (
            <>
              <h1>Default Flow</h1>
              <p>{DEFAULT_FLOW_DESC}</p>
            </>
          ) : (
            <>
              <Input
                disabled={!isEditing}
                ref={titleRef}
                value={title}
                className="create-flow__title__input"
                placeholder="Flow Name"
                onChange={(e) => setTitle(e.target.value)}
              />
            </>
          )}
        </div>
        <div className={"create-flow__action"}>
          <Button
            className={`create-flow__action__button ${isEditing ? "primary" : ""}`}
            disabled={isEditing && !enablePublish}
            onClick={() => {
              isEditing ? handleUpdateFlow() : history.push(`/approvalflow/edit/${id}`);
            }}
          >
            {isEditing ? "Publish" : "Edit"}
          </Button>
        </div>
        <FaqButton />
      </div>

      <div ref={scrollRef as React.LegacyRef<HTMLDivElement>} className={contentClassNames}>
        <FlowCanvas
          isDefault={isDefault}
          isEditing={isEditing}
          flow={flow}
          setFlow={setFlow}
          title={title}
          processType={isEdit ? flowData?.processType : processType}
          setEnablePublish={setEnablePublish}
          id={id}
          hasTeamManager={hasTeamManager}
        />
      </div>

      <UpdateFlowModal
        show={updateFlowConfirmation}
        onCancel={() => {
          setUpdateFlowConfirmation(false);
        }}
        totalActiveRequests={flowData?.totalActiveRequests}
        onUpdate={handlePublish}
      />

      <PageLeavePrompt
        when={isEditing && showPrompt}
        title="Discard Changes?"
        cancelText="No, continue editing"
        okText="Yes, discard changes"
        content={
          <>
            <p>You are in the middle of creating or editing a flow.</p>
            <p>Do you wish to discard the changes you've made? The flow will remain unchanged.</p>
          </>
        }
      />
      {publishReducer.loading && (
        <OverlayLoaderV2 title="Publishing Approval Flow..." subtitle="Please do not close this page" />
      )}
    </div>
  );
};

export default CreateFlowPage;
