import React from "react";
import { close, pencil } from "assets/v1.1/icons/Line";
import { ChevronLeft } from "assets/v1.1/icons/Core/ChevronLeft";
import { IHeaderProps } from "../../type";

import styles from "./styles.module.scss";

const Header = ({ title, onClose, onBack, onEdit }: IHeaderProps) => {
  return (
    <div className={styles["side-panel__header"]}>
      <div className={styles["side-panel__header-wrapper"]}>
        {onBack && (
          <img
            onClick={onBack}
            src={ChevronLeft.Line[24]}
            alt="Back Icon"
            className={styles["side-panel__icon-wrapper"]}
          />
        )}
        <h3 className={styles.title}>{title}</h3>
      </div>

      <div className={styles["side-panel__icon-wrapper"]}>
        {onEdit && <img src={pencil} onClick={onEdit} alt="Edit Icon" />}
        <img src={close} onClick={onClose} alt="Close Icon" />
      </div>
    </div>
  );
};

export default Header;
