import { ArrowRightOutlined } from "@ant-design/icons";
import Modal, { ModalComponent } from "Modules/DS/Modal";
import { CloseButton, Title } from "Modules/DS/Modal/Components";
import { TITLE_TYPE } from "Modules/DS/Modal/types";
import { appNotification } from "Modules/appNotification/appNotification";
import Button from "Modules/button";
import { RootState } from "Redux/ConfigureStore";
import {
  getVendorDetail,
  saveVendor,
  vendorPreviewV2,
} from "Redux/DataCalls/Disbursement.api";
import editBillStyles from "Views/UploadInvoice/EditBillModal/EditBillModal.module.scss";
import { BillFormTypes } from "Views/UploadInvoice/EditBillModal/types.d";
import PreviewVendorContent from "Views/UploadInvoice/InvoiceForm/PreviewModal/PreviewVendorContent";
import VendorSection, {
  VendorSectionProps,
} from "Views/UploadInvoice/InvoiceForm/VendorSection";
import { DUPLICATE_VENDOR_NAME_ERROR_MESSAGE } from "Views/UploadInvoice/const";
import { Form, Tooltip } from "antd";
import { discardEdit, greyCloseIcon, newInfo } from "assets/img";
import classNames from "classnames";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { debounce, isSet } from "utility";
import { trackEvent } from "utility/analytics";
import sharedStyles from "../ManageRecipients.module.scss";
import { RecipientData, SaveVendorSource } from "../types";
import { formatDate } from "../utils/formatter";
import styles from "./RecipientForm.module.scss";
import { PreviewSection } from "Views/UploadInvoice/InvoiceForm/PreviewModal/types";
export interface RecipientFormProps {
  onSuccessSubmit?: () => void;
  onGetDetail?: (id: number) => void;
  defaultValue?: RecipientData;
  setShowModalRecipientForm: React.Dispatch<React.SetStateAction<boolean>>;
  showModalRecipientForm: boolean;
}

const RecipientForm = ({
  onSuccessSubmit,
  onGetDetail,
  defaultValue,
  setShowModalRecipientForm,
  showModalRecipientForm,
}: RecipientFormProps) => {
  const [formOnChangeCount, setFormOnChangeCount] = useState(0);
  const [showDiscardModal, setShowDiscardModal] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [previewData, setPreviewData] = useState<PreviewSection[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<{
    code: string;
    name: string;
  } | null>(null);
  const [selectedCurrencyCode, setSelectedCurrencyCode] = useState("");
  const [vendorDetail, setVendorDetail] =
    useState<VendorSectionProps["defaultValue"]>(undefined);
  const orgDetail = useSelector(
    (state: RootState) => state.b2bOrgDetailReducer?.data?.payload,
  );

  const [form] = Form.useForm();

  const isEdit = Boolean(defaultValue);

  useEffect(() => {
    trackEvent("LP Recipient - Add Recipient");
  }, []);

  useEffect(() => {
    if (defaultValue && orgDetail.id) {
      setShowModalRecipientForm(true);
      getVendorDetail(defaultValue.ID).then((res) => {
        const dynamicFields = res.data.payload?.dynamicFields?.map((field) => {
          // IMPROVEMENT: should not changing the value and just
          // show as it is. P.S it should be an string[]
          if (field.alias === "recipientEmail") {
            return {
              ...field,
              value: field.value ? field.value.split(",") : [],
            };
          }

          return field;
        });

        setVendorDetail({
          beneficiaryName: defaultValue.beneficiaryName,
          countryCode: defaultValue.countryCode,
          currencyCode: defaultValue.currencyCode,
          dynamicFields,
          organisationID: orgDetail.id,
          recipientEmail: defaultValue.recipientEmail
            ? defaultValue.recipientEmail?.split(",")
            : [],
          vendorID: defaultValue.ID.toString(),
          vendorName: defaultValue.legalName,
          isVendorLinked: false,
        });
      });
    }
  }, [defaultValue, orgDetail]);

  useEffect(() => {
    if (orgDetail) {
      setSelectedCountry({
        code: orgDetail.countryCode,
        name: orgDetail.country,
      });
      setSelectedCurrencyCode(orgDetail.currency);
    }
  }, [orgDetail]);

  const editedFields = useMemo(() => {
    if (formOnChangeCount === 0 || !isEdit) {
      return [];
    }
    const edited: { key: string; prevValue: string }[] = [];
    const currentData = form.getFieldsValue();
    if (currentData?.dynamicFields) {
      Object.entries(currentData.dynamicFields).forEach(([key, value]) => {
        const selected = defaultValue?.dynamicFields?.find(
          (item) => item.id?.toString() === key,
        );
        if (selected?.value?.toString() === value?.toString()) {
          return;
        }
        edited.push({ key, prevValue: selected?.value?.toString() });
      });
    }
    if (currentData.vendorName !== defaultValue?.legalName) {
      edited.push({ key: "vendorName", prevValue: defaultValue.legalName });
    }
    if (currentData.beneficiaryName !== defaultValue?.beneficiaryName) {
      edited.push({
        key: "recipientName",
        prevValue: defaultValue.beneficiaryName,
      });
    }
    if (currentData.countryCode !== defaultValue?.countryCode) {
      edited.push({
        key: "recipientBankCountryName",
        prevValue: defaultValue.countryName,
      });
    }
    if (currentData.currencyCode !== defaultValue?.currencyCode) {
      edited.push({
        key: "recipientBankCurrency",
        prevValue: defaultValue.currencyCode,
      });
    }
    if (
      currentData.recipientEmail?.toString() !== defaultValue?.recipientEmail
    ) {
      edited.push({
        key: "recipientEmail",
        prevValue: defaultValue.recipientEmail,
      });
    }
    return edited;
  }, [form, formOnChangeCount, defaultValue, isEdit]);

  const handleFormChange = debounce(() => {
    setFormOnChangeCount((prev) => prev + 1);
  }, 300);

  const handleCloseModalRecipientForm = () => {
    setShowModalRecipientForm(false);
    setShowPreview(false);
    form.resetFields();
    setFormOnChangeCount(0);
  };

  const handleToggleDiscardModal = () => {
    setShowDiscardModal((prev) => !prev);
  };

  const scrollToError = ({ errorFields }) => {
    const id = errorFields?.[0].name.join("_");
    document.getElementById(id)?.scrollIntoView({
      block: "center",
    });

    if (showPreview) {
      const isDuplicateVendorName = errorFields.find(
        (item) =>
          item.name.includes("vendorName") &&
          item.errors.includes(DUPLICATE_VENDOR_NAME_ERROR_MESSAGE),
      );
      if (isDuplicateVendorName) {
        appNotification.error({
          message: `The recipient ${form.getFieldValue(
            "vendorName",
          )} has been created by another Spenmo user in your organization and cannot be created now. Please try again with another name.`,
        });
        setShowModalRecipientForm(false);
      }
    }
  };

  const getVendorPreview = async (payload) => {
    try {
      const resp = await vendorPreviewV2(payload);
      if (resp?.status === HTTP_STATUS_CODE.OK) {
        return resp.payload?.sections;
      } else {
        throw resp?.error?.message;
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handlePreview = () => {
    trackFormCTA("Preview");
    form
      .validateFields()
      .then(async (values) => {
        const payload = {
          legalName: values.vendorName,
          beneficiaryName: values.beneficiaryName,
          recipientEmail: values.recipientEmail?.join(),
          currencyCode: values.currencyCode,
          countryCode: values.countryCode,
          dynamicFields: parsedDynamicFields(values.dynamicFields),
          source: SaveVendorSource.vendor_management,
        };
        if (isEdit) {
          payload["id"] = Number(vendorDetail.vendorID);
        }
        const res = await getVendorPreview(payload);
        setPreviewData(res);
        setShowPreview(true);
      })
      .catch(scrollToError);
  };

  const parsedDynamicFields = (dynamicFields: Record<string, any>) => {
    return Object.entries(dynamicFields).map(([key, value]) => {
      return {
        id: parseInt(key, 10),
        value: isSet(value) ? String(value) : value,
      };
    });
  };

  const handleSubmit = (values: Record<string, any>) => {
    trackFormCTA("Confirm");
    const payload = {
      legalName: values.vendorName,
      beneficiaryName: values.beneficiaryName,
      recipientEmail: values.recipientEmail?.join(),
      currencyCode: values.currencyCode,
      countryCode: values.countryCode,
      dynamicFields: parsedDynamicFields(values.dynamicFields),
      source: SaveVendorSource.vendor_management,
    };
    if (isEdit) {
      payload["id"] = Number(vendorDetail.vendorID);
    }
    saveVendor(payload).then((res) => {
      if (res.status === HTTP_STATUS_CODE.OK) {
        onSuccessSubmit?.();
        appNotification.success({
          message: `${values.vendorName} has been ${defaultValue ? "edited" : "succcessfully added as a recipient"}`,
          cta: "View Recipient",
          onClickCTA: () => {
            onGetDetail?.(res.payload.ID);
          },
        });
      } else {
        appNotification.error({
          message: (
            <div>
              An error has occurred. Please contact <b>support@spenmo.com</b>{" "}
              for further assistance.
              <br />
              Error ID: {res.error?.code}
            </div>
          ),
          cta: "Email Support",
          onClickCTA: () => {
            window.open("mailto:support@spenmo.com");
          },
        });
      }
    });
    setShowModalRecipientForm(false);
    setShowPreview(false);
  };

  const trackView = (fieldName: string) => {
    trackEvent(`view ${fieldName} in VMS`);
  };

  const trackEdit = (fieldName: string) => {
    trackEvent(`edit ${fieldName} in VMS`);
  };

  const trackFormCTA = (context: string) => {
    trackEvent(`Recipient ${isEdit ? "Edit" : "Add"} - ${context}`);
  };

  const renderModalBody = () => {
    return (
      <div className={styles.recipientFormModalContent}>
        {showPreview && (
          <div className={styles.recipientDataPreview}>
            <PreviewVendorContent sections={previewData} />
          </div>
        )}

        <div className={showPreview ? styles.hide : ""}>
          <p className={styles.recipientFormNote}>
            This recipient's details can be retrieved by anyone in your company
            who has access to Bill Payment.
          </p>
          {Boolean(defaultValue) && (
            <div className={styles.lastModifiedAndCreatedBy}>
              <div>
                <div>Recipient last modified by</div>
                <div className={sharedStyles.contentItem}>
                  <div className={sharedStyles.initial}>
                    {defaultValue.lastModified.name[0]}
                  </div>
                  <div>
                    <p className={sharedStyles.oneLine}>
                      {defaultValue.lastModified.name}
                    </p>
                    <p
                      className={classNames(
                        sharedStyles.oneLine,
                        sharedStyles.secondary,
                      )}
                    >
                      {formatDate(defaultValue.lastModified.timestamp)}
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <div>Recipient created by</div>
                <div className={sharedStyles.contentItem}>
                  <div className={sharedStyles.initial}>
                    {defaultValue.createdInfo.name[0]}
                  </div>
                  <div>
                    <p className={sharedStyles.oneLine}>
                      {defaultValue.createdInfo.name}
                    </p>
                    <p
                      className={classNames(
                        sharedStyles.oneLine,
                        sharedStyles.secondary,
                      )}
                    >
                      {formatDate(defaultValue.createdInfo.timestamp)}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
          <Form
            form={form}
            onFinish={handleSubmit}
            onFinishFailed={scrollToError}
            className={styles.recipientForm}
            onValuesChange={handleFormChange}
          >
            <VendorSection
              form={form}
              formOnChangeCount={formOnChangeCount}
              paymentCurrency={selectedCurrencyCode}
              recipientBankCountryCode={selectedCountry?.code}
              onChangeCountry={(code, name) => {
                setSelectedCountry({
                  code,
                  name,
                });
              }}
              onChangeCurrency={(code) => {
                setSelectedCurrencyCode(code);
              }}
              billFormType={
                defaultValue ? BillFormTypes.Edit : BillFormTypes.New
              }
              noSelectVendor
              defaultValue={vendorDetail}
              trackView={trackView}
              trackEdit={trackEdit}
            />
          </Form>
        </div>
      </div>
    );
  };

  return (
    <>
      <Modal
        visible={showModalRecipientForm}
        close={
          editedFields.length
            ? handleToggleDiscardModal
            : handleCloseModalRecipientForm
        }
        className={styles.recipientFormModal}
      >
        <ModalComponent.CloseButton src={greyCloseIcon} />
        <ModalComponent.Title titleType={TITLE_TYPE.withBorder}>
          {defaultValue ? "Edit" : "Add"} Recipient
        </ModalComponent.Title>

        {renderModalBody()}

        <div className={styles.recipientFormModalFooter} data-testid="actions">
          <div className={styles.recipientFormActions}>
            {showPreview ? (
              <>
                <Button type="button" rounded action={form.submit}>
                  Confirm <ArrowRightOutlined />
                </Button>
                <div
                  role="button"
                  onClick={() => setShowPreview(false)}
                  className={styles.linkButton}
                >
                  Back
                </div>
              </>
            ) : (
              <>
                <Button
                  type="button"
                  rounded
                  action={handlePreview}
                  disabled={isEdit && !editedFields.length}
                >
                  Preview Recipient
                </Button>
                {isEdit && (
                  <div
                    role="button"
                    className={classNames(
                      styles.linkButton,
                      styles.linkButtonDisabled,
                    )}
                    onMouseOver={() =>
                      trackEvent("Recipient Delete - hover on icon")
                    }
                  >
                    Delete Recipient{" "}
                    <Tooltip title="Coming soon!">
                      <img src={newInfo} alt="Coming soon!" />
                    </Tooltip>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </Modal>

      <Modal visible={showDiscardModal} close={handleToggleDiscardModal}>
        <Title>Discard Changes?</Title>
        <CloseButton fixToTop />
        <div className={editBillStyles.discardModalContainer}>
          <img
            className={editBillStyles.discardModalIcon}
            src={discardEdit}
            alt="Discard changes"
          />
          <p className={editBillStyles.discardModalText}>
            There are unsaved changes. Do you want to discard these changes?
          </p>
          <Button
            className={editBillStyles.discardModalButton}
            action={handleToggleDiscardModal}
          >
            Continue Editing
          </Button>
          <Button
            className={editBillStyles.discardModalButton}
            textButton
            action={() => {
              handleToggleDiscardModal();
              handleCloseModalRecipientForm();
            }}
          >
            Discard Changes
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default RecipientForm;
