import React from "react";
import { Button } from "@spenmo/splice";
import { useHistory, useLocation } from "react-router-dom";
import { DownloadButton } from "Views/Transaction/Download";
import { BILLS_PAGE_OPERATIONS } from "Views/Bills/V2/constants";

export const BillsTrxnsDownload = ({ isExportButtonEnabled }: { isExportButtonEnabled?: boolean }) => {
  const history = useHistory();
  const location = useLocation();

  const exportBtnClickHandler = () => {
    history.push({ pathname: `${location.pathname}/${BILLS_PAGE_OPERATIONS.EXPORT}`, search: location.search });
  };

  return isExportButtonEnabled ? (
    <Button size="s" variant="primary" onClick={exportBtnClickHandler}>
      Export
    </Button>
  ) : (
    <DownloadButton view="bills" onClickHandler={exportBtnClickHandler} />
  );
};
