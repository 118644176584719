import APIclient from "API/Client";
import { GetUserId, GetOrgId } from "../../../utility";
import { HTTP_STATUS_CODE } from "../../../constants/HTTPStatusCode.constant";

export const FETCH_STEPS_REQUEST = "FETCH_STEPS_REQUEST";
export const FETCH_STEPS_SUCCESS = "FETCH_STEPS_SUCCESS";
export const FETCH_STEPS_FAILURE = "FETCH_STEPS_FAILURE";

export const fetchDataRequest = (data) => {
  return {
    type: "FETCH_STEPS_REQUEST",
    data: data,
  };
};

export const fetchDataSuccess = (data) => {
  return {
    type: "FETCH_STEPS_SUCCESS",
    data: data.payload,
  };
};

export const fetchDataError = (data) => {
  return {
    type: "FETCH_STEPS_FAILURE",
    data: data,
  };
};

export const getStepsStatus = (loader = true) => {
  return (dispatch) => {
    loader && dispatch(fetchDataRequest());

    APIclient.getData(
      `/ms/spm-organization/v1/onboarding/status?X-Organization-Id=${GetOrgId()}&X-User-Id=${GetUserId()}`
    )
      .then((data) => {
        if (data.data.status === HTTP_STATUS_CODE.OK) {
          dispatch(fetchDataSuccess(data.data));
        } else {
          dispatch(fetchDataError(data.data));
        }
      })
      .catch((err) => {
        dispatch(fetchDataError(err));
      });
  };
};
