import React from "react";
import { Typography } from "@spenmo/splice";

import { IModalProps } from "Views/Settings/@types";

import styles from "./styles.module.scss";

export const getModalProps = (confirm: () => void, cancel: () => void, close: () => void): IModalProps => {
  return {
    showModal: true,
    size: "m",
    onClose: close,
    footerFullWidthButtons: true,
    title: "Confirm Monthly Settlement Date",
    primaryActionButton: {
      title: "Confirm",
      action: confirm,
    },
    secondaryActionButton: {
      title: "Cancel",
      action: cancel,
    },
  };
};


const ReimbursementSettingsModal = ({ textContent }: { textContent: string }) => {
  return (
    <Typography size="m" variant="body-content" className={styles.modalContent} tag="p">
      {textContent}
    </Typography>
  );
};

export default ReimbursementSettingsModal;
