import * as React from "react";
import { Button } from "antd";
import {
  RemindButton,
  DeleteButton,
  CardRequestRemoveButton,
  ActivatePhysicalCardBtn,
} from "Views/Card/Details/ActionBtns/Btns";
import { CARD_REQUEST_STATUS } from "Views/Card/types";
import { useCardDetailsContext } from "Views/Card/Details/index.context";
import { deleteCard } from "Redux/DataCalls/Cards.api";
import styles from "Views/Card/Details/ActionBtns/index.module.scss";
import { CARD_DETAILS_TABS } from "Views/Card/List/types";
import CardDeleteModal from "Views/Card/Delete";
import { showSuccessNotification, showErrorNotification } from "Views/Card/Components/Notification";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import { DeleteCardEventNames, EditCardEventNames } from "Views/Card/Analytics";
import { trackEvent } from "utility/analytics";
import classNames from "classnames";

export interface ICardDetailsActionBtnsProps {
  onEdit: () => void;
  onCardRequestDelete: () => void;
  onCardDelete: () => void;
  onPhysicalCardSync: () => void;
}

export default function CardDetailsActionBtns({
  onEdit,
  onCardRequestDelete,
  onCardDelete,
  onPhysicalCardSync,
}: ICardDetailsActionBtnsProps) {
  const { cardData, cardDetailsActiveTab, showCardDetailsInModal } = useCardDetailsContext();

  const { isCardRequestDetails, cardStatus, id, isNotActivatedPhysicalCard } = cardData || {};

  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [deleteInProgress, setDeleteInProgress] = React.useState(false);

  const trackDeleteCardEvent = (eventSource: string) => {
    trackEvent(DeleteCardEventNames.CARD_DELETE_CARD_CLICKED, {
      card_id: id,
      card_delete_event_action: eventSource,
    });
  };

  const trackCardDeleteApiResponse = (isSucess: boolean) => {
    trackEvent(DeleteCardEventNames.CARD_DELETE_PROCESSED, {
      card_process_success: isSucess,
    });
  };

  const handleOnDeleteError = (statusMessage) => {
    showErrorNotification(statusMessage);
    trackCardDeleteApiResponse(false);
  };

  const onDelete = (deleteReason: string) => {
    setDeleteInProgress(true);
    setShowDeleteModal(false);
    deleteCard(id, { type: deleteReason })
      .then(({ data }) => {
        const { status_message = "" } = data?.payload || {};
        if (data?.status === HTTP_STATUS_CODE.OK) {
          showSuccessNotification("Card successfully deleted");
          trackCardDeleteApiResponse(true);
          onCardDelete();
        } else {
          handleOnDeleteError(status_message);
        }
      })
      .catch((err) => {
        const { status_message = "Something went wrong! Please try again later." } = err?.response?.data?.payload || {};
        handleOnDeleteError(status_message);
      })
      .finally(() => {
        setDeleteInProgress(false);
      });
    trackDeleteCardEvent("Delete Confirm");
  };

  const onDeleteBtnClick = () => {
    setShowDeleteModal(true);
    trackDeleteCardEvent("Delete");
  };

  const onEditBtnClick = () => {
    onEdit();
    !isCardRequestDetails && trackEvent(EditCardEventNames.CARD_EDIT_SETTINGS_CLICKED);
  };

  const getActionBtns = () => {
    switch (true) {
      case isCardRequestDetails:
        return cardStatus === CARD_REQUEST_STATUS.REQUESTED ? (
          <>
            <Button className={`${styles["btn"]} ${styles["transparent-btn"]}`} onClick={onEditBtnClick}>
              Edit Request
            </Button>
            <RemindButton />
          </>
        ) : (
          <>
            <CardRequestRemoveButton onCardRequestDelete={onCardRequestDelete} />
          </>
        );
      case isNotActivatedPhysicalCard:
        return <ActivatePhysicalCardBtn onPhysicalCardSync={onPhysicalCardSync} />;
      case cardDetailsActiveTab === CARD_DETAILS_TABS.SETTINGS:
        return (
          <>
            <Button
              className={`${styles["btn"]} ${styles["primary-btn"]} ${styles["primary-xl"]}`}
              onClick={onEditBtnClick}
            >
              Edit Settings
            </Button>
            <DeleteButton deleteInProgress={deleteInProgress} onCardDelete={onDeleteBtnClick} />
          </>
        );
      default:
        return null;
    }
  };
  const _className = classNames(styles["container"], {
    [styles["container-in-modal"]]: showCardDetailsInModal,
  });
  const ActionBtns = getActionBtns();
  return ActionBtns ? (
    <>
      <div className={_className}>{getActionBtns()}</div>
      {showDeleteModal && <CardDeleteModal cardId={id} onClose={() => setShowDeleteModal(false)} onDelete={onDelete} />}
    </>
  ) : null;
}
