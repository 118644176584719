import React, { useState } from "react";
import cn from "classnames";

import { useHistory } from "react-router-dom";
import { Button, HelpOutline, Typography } from "@spenmo/splice";

import ConnectivityInformation from "./ConnectivityInformation";

import { trackEvent } from "utility/analytics";
import { useNetsuiteIntegration } from "./hooks/useNetsuiteIntegration";
import useLoading from "utility/useLoading";
import { ACCOUNTING_SETTING_ANALYTICS, PARTNER_TYPE } from "./helper/constant";
import { getAccountingPartnerUrl } from "Redux/DataCalls/AccountingSetting.api";
import { ACCOUNTING_PATHS } from "Route/Accounting/paths";
import styles from "./styles.module.scss";
import UpgradeNetsuiteModal from "./components/UpgradeNetsuiteModal";
import { AccountingPartnerModalProps } from "./@types/integrationPartnerList";
import { getPartnerLabel } from "./helper/dataMapping";

const IntegrationPartnerList = (props) => {
  const {
    connectedToAccountingPartner,
    partnerListData,
    isAllowedIntegrationSettingUpdate,
    isPartnerAccountNotAvailable,
    usingAdvanceExpenseIntegration,
    accountingPartnerError,
    accountingPartnerdata,
  } = props;

  const { validToken, completedIntegration, partnerName } =
    accountingPartnerdata?.payload || {};
  // Only for NetSuite for now
  const partialyConnected =
    validToken &&
    !completedIntegration &&
    partnerName === PARTNER_TYPE.NETSUITE;

  const history = useHistory();
  const { integrationEnabled } = useNetsuiteIntegration();

  const [modalProps, setModalProps] = useState<AccountingPartnerModalProps>({});

  const [fetchUrl, loading] = useLoading(
    getAccountingPartnerUrl,
    null,
    {},
    false,
  );

  const connectToPartner = async (accountingData) => {
    const { name, id } = accountingData;
    const { validToken, completedIntegration, partnerName } =
      accountingPartnerdata?.payload || {};
    const partialyConnected =
      validToken &&
      !completedIntegration &&
      partnerName === PARTNER_TYPE.NETSUITE;
    const isNetsuite = name === PARTNER_TYPE.NETSUITE;
    const isNotXero = name !== PARTNER_TYPE.XERO;

    if (partialyConnected && isNetsuite) {
      history.push(ACCOUNTING_PATHS.NETSUITE_SETUP);
      return;
    }

    if (!integrationEnabled && isNotXero) {
      setModalProps({
        id,
        name: getPartnerLabel(name),
      });
      return;
    }

    const { data } = await fetchUrl(id);
    const redirectionUrl = data.payload?.redirectUrl;
    if (redirectionUrl) {
      trackEvent(ACCOUNTING_SETTING_ANALYTICS.XERO_INTEGRATION, {
        xero_api_event_action: "Connect",
      });
      trackEvent(ACCOUNTING_SETTING_ANALYTICS.CLICK_CONNECT_INTEGRATION_BUTTON);

      const params = new URLSearchParams();
      if (isNetsuite) {
        const url = window.location.origin.slice(
          window.location.protocol.length + 2,
        ); // To fetch the current location url and omiting the http / https from the URL
        params.set("environment", url);
      }
      window.location.href = `${redirectionUrl}?${params.toString()}`;
    }
  };

  if (connectedToAccountingPartner || partialyConnected) {
    return partnerListData?.map((accountingPartner) => (
      <div
        key={accountingPartner.id}
        className={cn(styles.integrationCard, {
          [styles.removeGap]: connectedToAccountingPartner,
        })}
      >
        <ConnectivityInformation
          isAllowedIntegrationSettingUpdate={isAllowedIntegrationSettingUpdate}
          isPartnerAccountNotAvailable={isPartnerAccountNotAvailable}
          usingAdvanceExpenseIntegration={usingAdvanceExpenseIntegration}
          accountingPartnerError={accountingPartnerError}
          accountingPartnerdata={accountingPartnerdata}
          accountingPartner={accountingPartner}
        />
      </div>
    ));
  }
  return (
    <div className={styles.integrationSelectionContainer}>
      {partnerListData?.map((accountingPartner) => {
        const { id, logoUrl, name, helpUrl } = accountingPartner;
        const isSelectedID = modalProps?.id === id;
        return (
          <div
            key={accountingPartner.id}
            className={styles.integrationSelectionCard}
          >
            <div className={styles.toolsImg}>
              <img src={logoUrl} alt={name} width={48} height={48} />
            </div>
            <Typography variant="body-content" size="s" weight={600} tag="p">
              {getPartnerLabel(name)}
            </Typography>
            <div className={styles.btnGroup}>
              {helpUrl && (
                <Button
                  variant="tertiary"
                  size="s"
                  className={styles.helpBtn}
                  onClick={() => window.open(helpUrl, "_blank")}
                  data-testid="helpButton"
                >
                  <HelpOutline size="16" fill="var(--icon-default)" />
                </Button>
              )}
              <Button
                variant="secondary"
                size="s"
                className={styles.connectBtn}
                onClick={() => connectToPartner(accountingPartner)}
                loading={isSelectedID && loading}
                disabled={loading}
              >
                Connect
              </Button>
            </div>
          </div>
        );
      })}
      <UpgradeNetsuiteModal onClose={() => setModalProps({})} {...modalProps} />
    </div>
  );
};

export default IntegrationPartnerList;
