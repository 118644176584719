import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Link } from "react-router-dom";

import Modal from "Modules/modal";
import { currencyFormatter } from "utility";
import useGetBillUrl from "../useGetBillUrl";

import styles from "./BalanceNotSufficient.module.scss";

const BalanceNotSufficient = ({ isShow, isAdmin, onClose, balanceData, isUsingApproval }) => {
  const { billUrl } = useGetBillUrl();

  const commandTopUp = isAdmin ? "top up" : "inform your Admin to top up";
  const renderBody = (
    <div className={styles.body}>
      <div className={styles.text}>
        The payment for this bill is on hold due to insufficient balance. Please {commandTopUp}{" "}
        <b>{balanceData.walletName}</b> balance to ensure the payment is settled as scheduled.
      </div>
      <div className={styles.box}>
        <div className={styles.item}>
          <span>Payment Amount</span>
          <span>:</span>
          <span>{currencyFormatter(balanceData.amount, balanceData.currency)}</span>
        </div>
        <div className={styles.item}>
          <span>Available Balance</span>
          <span>:</span>
          <span className={styles["color-black"]}>
            {currencyFormatter(balanceData.walletAmount, balanceData.walletCurrency)}
          </span>
        </div>
        <div className={styles.line} />
        <div className={styles.item}>
          <span className={isAdmin ? styles["topup-admin"] : styles["topup-non-admin"]}>Top Up Amount</span>
          <span className={isAdmin ? styles["topup-admin"] : styles["topup-non-admin"]}>:</span>
          <span className={isAdmin ? styles["topup-value-admin"] : styles["topup-value-non-admin"]}>
            {currencyFormatter(balanceData.deficitAmount, balanceData.walletCurrency)}
          </span>
        </div>
      </div>
    </div>
  );

  const renderFooter = (
    <div className={styles.cta}>
      <Link key="transaction" to={billUrl} className={classNames(styles.button, styles.secondaryCta)}>
        Check Payment Status
      </Link>
      {isAdmin && (
        <Link key="topup" to="/topup" target="_blank" className={classNames(styles.button, styles.mainCta)}>
          Top Up Now
        </Link>
      )}
    </div>
  );

  return (
    <Modal className={styles.container} title="Insufficient Balance" show={isShow} close={onClose}>
      {renderBody}
      {renderFooter}
    </Modal>
  );
};

BalanceNotSufficient.defaultProps = {
  isShow: false,
  isAdmin: false,
  isUsingApproval: false,
  onClose: () => {},
  balanceData: {},
};

BalanceNotSufficient.propTypes = {
  isShow: PropTypes.bool,
  isAdmin: PropTypes.bool,
  isUsingApproval: PropTypes.bool,
  onClose: PropTypes.func,
  balanceData: PropTypes.object,
};

export default BalanceNotSufficient;
