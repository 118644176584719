import { useState } from "react";

import { initalPopupState, IPopupState } from "Views/Employee/types";

const useCS = (): [IPopupState, () => void, (message: string) => void] => {
  const [supportChat, setSupportChat] = useState({ ...initalPopupState });

  const clearSupportChat = () => setSupportChat({ ...initalPopupState });

  const toggleSupportChat = (message: string): void => {
    setSupportChat((prevState) => ({ visible: !prevState.visible, message: message }));
  };

  return [supportChat, clearSupportChat, toggleSupportChat];
};

export default useCS;
