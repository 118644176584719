export enum LOADER_TYPES {
  BRAND = "brand",
  WHITE = "white",
  SUCCESS = "success",
  DANGER = "danger",
}

export enum LOADER_SIZES {
  BIG = "big",
  SMALL = "small",
  EXTRA_BIG = "extra-big",
}

export interface LoaderProps {
  size?: LOADER_SIZES;
  type?: LOADER_TYPES;
}
