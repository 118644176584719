import * as React from "react";
import { Divider } from "antd";

import styles from "Views/Card/Details/Info/index.module.scss";

export const getInfoHeader = (name: string | JSX.Element) => {
  return <div className={styles["header"]}>{name}</div>;
};

export const getInfo = (info: string | JSX.Element) => {
  return (
    <p className={styles["info"]} key="info">
      {info}
    </p>
  );
};

export type cardDetailsInfoType = Array<{ label: string | JSX.Element; value: string | JSX.Element }>;

export const renderCardDetails = (cardDetailsInfoData: cardDetailsInfoType) => (
  <>
    {cardDetailsInfoData?.map((info, index) => (
      <div key={index}>
        {getInfoHeader(info.label)}
        {getInfo(info.value)}
        {index < cardDetailsInfoData.length - 1 && <Divider className={styles["divider"]} />}
      </div>
    ))}
  </>
);
