import React from "react";
import PropTypes from "prop-types";
import RecCarousel, { consts } from "react-elastic-carousel";
import classNames from "classnames";

import { rightArrowDark, left_arrow } from "assets/img";

import "./carousel.scss";

/*
  This component is a custom wrapper around react-elastic-carousel

  You can read more about react-elastic-component at https://sag1v.github.io/react-elastic-carousel/

  This component takes 5 props - data, itemsToShow, paginated, showArrows, itemPosition, and breakPoints
    - data takes an array of objects/components which are to be displayed in the carousel
      (Empty array by default)
    - itemsToShow takes an integer, which controls the number of visible items (3 by default)
    - paginated takes a boolean value which controls whether or not the pagination
      bubbles/dots should be rendered (true by default)
    - showArrows takes a boolean value which controls whether or not the arrows at the
      edge of the carousel are rendered (true by default)
    - itemPosition positions the element relative to it’s wrapper
      (use the consts object) - consts.START ("START") | consts.CENTER ("CENTER") | consts.END ("END")
      we can directly use "start", or "end" as we call fetchItemPositionConst() method in the RecCarousel
      to pass on the respective consts value to RecCarousel. CENTER is the default state
    - The breakPoints array, allows you to set different props for different sizes of the element,
      regardless to the window's size.

      react-elastic-carousel is listening to the element's resize event instead of the window.

      You can pass an array of objects that will signal react-elastic-carousel to change props like
      itemsToShow and itemsToScroll (and others) on different width values.

      ANY prop can be passed directly to React Elastic Carousel or via a the breakpoint object.
      Keep in mind that a prop passed via a breakpoint will override a direct prop.

  Basic Usage:

  <Carousel itemsToShow={4} data={[<div>Test 1</div>, <div>Test 2</div>, <div>Test 3</div>, <div>Test 4</div>, <div>Test 5</div>, <div>Test 6</div>]} />

  * Render the carousel without pagination
  <Carousel itemsToShow={4} paginated={false} data={[<div>Test 1</div>, <div>Test 2</div>, <div>Test 3</div>, <div>Test 4</div>, <div>Test 5</div>, <div>Test 6</div> ]} />

  * Render the carousel without arrows
  <Carousel itemsToShow={2} showArrows={false} data={[<div>Test 1</div>, <div>Test 2</div> ]} />

  * Render the carousel items aligned to the right/end of the carousel
  <Carousel itemsToShow={4} itemPostion={"end"} data={[<div>Test 1</div>, <div>Test 2</div> ]} />
  */

const Carousel = ({
  data = [],
  itemsToShow = 3,
  paginated,
  showArrows,
  itemPosition,
  breakPoints,
  carouselClassname,
}) => {
  const fetchItemPositionConst = (position) => {
    switch (position) {
      case "start":
        return consts.START;
      case "end":
        return consts.END;
      default:
        return consts.CENTER;
    }
  };

  /*
      Provides custom arrow buttons. Takes 3 props type, onClick and isEdge.
        - type will be either consts.PREV or consts.NEXT, depends on the relevant arrow direction
        - onClick is the hook to the inner onClick of the Carousel. You can attach it wherever you
          want within the returned component.
        - isEdge Will be true when there are no more items to show for this direction.
  */
  const arrows = ({ type, onClick, isEdge }) => {
    const imageSrc = type === consts.PREV ? left_arrow : rightArrowDark;
    const altString = type === consts.PREV ? "left arrow" : "right arrow";
    return (
      <div className={`carousel__arrows ${isEdge && "carousel__arrows--disable"}`}>
        <img
          src={imageSrc}
          alt={altString}
          onClick={
            !isEdge
              ? (event) => {
                  event.stopPropagation();
                  onClick();
                }
              : null
          }
        />
      </div>
    );
  };

  /*
      Provides custom pagination buttons. Takes 3 props pages, activePage and onClick.
        - pages is an array of page index.
        - activePage returns the current active page id.
        - onClick is the hook to the inner onClick of the Carousel. You can attach it wherever you
          want within the returned component.
  */
  const renderPagination = ({ pages, activePage, onClick }) => {
    const hidePagination = pages.length <= 1;
    return (
      <div className={hidePagination ? "carousel__pagination--hidden" : "carousel__pagination"}>
        {pages.map((page) => {
          const isActivePage = activePage === page;
          return (
            <button
              key={page}
              onClick={() => onClick(page)}
              className={`carousel__pagination__dots ${isActivePage ? "active" : "inactive"}`}
            />
          );
        })}
      </div>
    );
  };

  return (
    data?.length > 0 && (
      <div className="carousel">
        <RecCarousel
          className={classNames("carousel--content", carouselClassname)}
          itemsToShow={itemsToShow}
          breakPoints={breakPoints}
          showArrows={showArrows}
          pagination={paginated}
          renderArrow={arrows}
          renderPagination={renderPagination}
          itemPosition={fetchItemPositionConst(itemPosition)}
        >
          {data.map((carouselItem) => carouselItem)}
        </RecCarousel>
      </div>
    )
  );
};

Carousel.propTypes = {
  data: PropTypes.array,
  paginated: PropTypes.bool,
  showArrows: PropTypes.bool,
  breakPoints: PropTypes.array,
  itemsToShow: PropTypes.number,
  itemPosition: PropTypes.oneOf(["start", "end"]),
};

Carousel.defaultProps = {
  breakPoints: [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2 },
    { width: 738, itemsToShow: 3 },
    { width: 948, itemsToShow: 4 },
  ],
};

export default Carousel;
