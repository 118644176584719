import { useCallback, useRef, useState } from "react";
import axios, { CancelTokenSource } from "axios";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import { DEFAULT_ERROR_MESSAGE } from "Views/Transaction/Constants";
import {
  initialBillsTrxnsSuccess,
  initialGetBillTrxnRequest,
  searchBillsMerchantList,
} from "Views/Transaction/DataCalls";
import { GetAllInvoices, GetSingleInvoice } from "Redux/DataCalls/Invoices.api";
import { IBillsTrxnErrorData, IGetBillsTrxnRequest, IGetBillsTrxnsListResponse } from "Views/Transaction/@types";

export const useBillTrxnsListFetch = () => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState<IGetBillsTrxnsListResponse>(initialBillsTrxnsSuccess.mocked);

  const fetchData = useCallback((params: IGetBillsTrxnRequest = initialGetBillTrxnRequest()) => {
    const { page, rows, status = null } = params;
    setLoading(true);
    setError(false);
    GetAllInvoices(page, rows, params, status)
      .then((resp) => {
        setError(resp.status > HTTP_STATUS_CODE.OK);
        setData(resp?.payload);
      })
      .catch((error) => {
        setError(true);
        setData(error.response.data as IBillsTrxnErrorData);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return { error, loading, data, fetchData };
};

export const useBillsMerchantSearch = () => {
  const latestRequest = useRef<CancelTokenSource>(null);

  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const [data, setData] = useState<string[]>([]);

  const resetData = () => setData([]);

  const fetchMerchants = useCallback((value: string) => {
    if (latestRequest.current) {
      latestRequest.current.cancel("Invalidating previous requests");
    }

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    latestRequest.current = source;

    setLoading(true);
    setError(false);
    setErrorMessage("");

    if (!value) {
      setData([]);
      setError(false);
      setLoading(false);
      setErrorMessage("");
      return;
    }

    searchBillsMerchantList(value, source)
      .then((resp) => {
        if (resp.status > HTTP_STATUS_CODE.OK) {
          setError(true);
          setErrorMessage(resp.data.payload.status_message || DEFAULT_ERROR_MESSAGE);
          setData([]);
        } else {
          setError(false);
          const response =
            Array.isArray(resp.payload.merchants) && resp.payload.merchants.length > 0 ? resp.payload.merchants : [];
          setData(response);
        }
      })
      .catch((error) => {
        setError(true);
        setData([]);
        setErrorMessage(error.message || DEFAULT_ERROR_MESSAGE);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return { error, loading, data, fetchMerchants, resetData, errorMessage };
};

export const useBillTrxnDetailsFetch = () => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState(null);

  const fetchData = useCallback((trxnId: string) => {
    setLoading(true);
    setError(false);
    trxnId &&
      GetSingleInvoice(trxnId)
        .then((resp) => {
          setError(resp.status > HTTP_STATUS_CODE.OK);
          setData({ ...resp?.payload, id: trxnId });
        })
        .catch((error) => {
          setError(true);
          setData(error?.response?.data);
        })
        .finally(() => {
          setLoading(false);
        });
  }, []);

  return { error, loading, data, fetchData };
};
