export const TRANSACTION_TYPE = {
  BANK: "Bank",
  EXPENSE: "Expense",
  REFUND: "Refund",
  REVERSAL: "Reversal",
  TOPUP: "Topup",
  TRANSFER: "Transfer",
  SETTLEMENT: "Settlement",
  INCENTIVE: "Incentive",
  FEE: "Fee", // Used for old transaction page
  FEE_REFUND: "Fee_Refund", // Used for old transaction page
  FEES: "Fees", // Used for accounting page
  FEES_REFUND: "Fees (Refund)", // Used for accounting page
};

export const TRANSACTION_FIELDS = {
  id: true,
  transaction_number: true,
  amount: true,
  past_balance: true,
  available_balance: true,
  user_id: true,
  organisation_id: true,
  created_at: true,
  type: true,
  description: true,
  vendor_transaction_id: true,
  merchant: true,
  card_type: true,
  card_last_four: true,
  foreign_currency_amount: true,
  foreign_currency_code: true,
  vendor_fee_amount: true,
  subwallet_id: true,
  team_id: true,
  isCredit: true,
  receipts: true,
  category: true,
  running_balance: true,
  simplified_merchant_name: true,
  currency_code: true,
};

export const SMART_CSV_LIMIT_TRANSACTION = 500;

export const SMART_CSV_EXCLUDE_ACCOUNT_CODE = ["Bank Statement"];

export const TRANSACTIONS_INITIAL_PAGE_NUMBER = 0;

export const TRANSACTIONS_TABLE_PAGE_SIZE = 10;

export const TRANSACTIONS_TABLE_INITIALIZING = "initializing";

export const TRANSACTIONS_TABLE_INITIAL_STATUS = 0;
