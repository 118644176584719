import React from "react";
import { Document, Page } from "react-pdf";
import { Typography } from "@spenmo/splice";

import { uploadInvoice } from "assets/img";

import styles from "./index.module.scss";

export interface IFilePreviewProps {
  files: { name: string; url: string }[];
}

const FilePlaceholder = ({ name }: { name: string }) => (
  <img className={styles.thumbnail} src={uploadInvoice} alt={name} width={32} height={32} />
);

export function FilePreview({ files }: IFilePreviewProps) {
  const getFilePreview = (url: string, name: string) => {
    const isPDF = url?.includes(".pdf");

    if (isPDF) {
      return (
        <Document
          file={url}
          className={styles.pdfPreview}
          error={<FilePlaceholder name={name} />}
          noData={<FilePlaceholder name={name} />}
        >
          <Page pageNumber={1} />
        </Document>
      );
    }

    return <img className={styles.thumbnail} src={url} alt={name} width={32} height={32} />;
  };

  const getFileExtension = (name: string) => {
    const MAX_LENGTH = 20;
    const lastDotIndex = name?.lastIndexOf(".");
    const fileFormat = name.substring(lastDotIndex + 1);
    const fileNameWithoutExtension = name.substring(0, lastDotIndex);

    return fileNameWithoutExtension.length > MAX_LENGTH ? "...." + fileFormat : "." + fileFormat;
  };

  return (
    <div className={styles.grid}>
      {files.map(({ name, url }) => (
        <a href={url} target="_blank" rel="noreferrer" className={styles.previewLink}>
          {getFilePreview(url, name)}
          <Typography className={styles.fileURL} variant="body-content" size="s">
            <Typography className={styles.filename} variant="body-content" size="s">
              {name?.split(".")[0]}
            </Typography>
            {getFileExtension(name)}
          </Typography>
        </a>
      ))}
    </div>
  );
}
