import * as React from "react";
import { useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import { FormInstance } from "antd/lib/form";

import { IKycStatus } from "Redux/Actions/Employee/Get/KycStatusList/types";
import { IEmployeeFilters } from "Redux/Actions/Employee/Get/Employees/types";

import { EMPLOYEES_INITIAL_PAGE_NUMBER } from "constants/Employee.constant";
import { useIsSaasWithPaymentsOrg } from "customHooks/useIsSaasWithPaymentsOrg";

import Filters, { FilterComponents, analytics } from "Modules/Filters";

import { EmployeesPageContext, IEmployeesContext } from "Views/Employee/context";

import "./index.scss";

const Filter = () => {
  const { filters, setFilters, searchVal, getEmployeesData }: IEmployeesContext = useContext(EmployeesPageContext);

  const { clearFilters } = filters;
  const { team, kyc_status: status }: IEmployeeFilters = filters.values;
  const isSaasAndPaymentsOrg = useIsSaasWithPaymentsOrg();

  const teamsList = useSelector((state: any) => state.teamList?.data?.payload?.teams);
  const kycStatusList: IKycStatus[] = useSelector((state: any) => state.kycStatusesReducer?.data);

  const statusList = kycStatusList?.map?.((obj) => ({ ...obj, id: obj?.code?.toString() })) || [];

  const onApply = (values: IEmployeeFilters) => {
    setFilters({ values: values, clearFilters: null });
    getEmployeesData(EMPLOYEES_INITIAL_PAGE_NUMBER, searchVal, values);
  };

  const onClear = (form: FormInstance<IEmployeeFilters>) =>
    setFilters({ values: {}, clearFilters: () => form.resetFields() });

  useEffect(() => {
    clearFilters && clearFilters();
  }, [clearFilters]);

  return (
    <Filters onApply={onApply} onClear={onClear} filters={filters.values} page="employees" trackFilterEvents>
      <FilterComponents.TeamFilter formItem="team" selectedTeam={team} listData={teamsList || []} />
      {isSaasAndPaymentsOrg && (
        <FilterComponents.DropDownFilter
          appendClassName="kyc-status"
          title="KYC Status"
          placeholder="Select Status"
          formItem="kyc_status"
          listData={statusList || []}
          eventName={analytics.kycStatusSelected}
        >
          {status}
        </FilterComponents.DropDownFilter>
      )}
    </Filters>
  );
};

export default Filter;
