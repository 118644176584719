import React, { useContext } from "react";
import classNames from "classnames";

import { TRXN_MODAL_TYPES } from "Views/Transaction/Constants";
import { TrxnModalContext, TrxnToastContext } from "Views/Transaction/Context";
import { IModalPayloadTypes, ITrxnModal, ITrxnModalContext } from "Views/Transaction/@types";

import { ViewAttachments } from "Views/Transaction/Modal/Attachments";
import RemoveSubscriptionWarning from "Views/Transaction/Modal/Subscriptions";
import { CustomCSVModal } from "Views/Transaction/SmartCSV/v2/Modal/CustomCSV2";
import {
  BankStatementModal,
  CsvExportModal,
  CsvExportWarningModal,
  DownloadReceipt,
} from "Views/Transaction/SmartCSV/v2";

import styles from "./index.module.scss";

export const useModalType = (): ITrxnModal => {
  const { resetModal, showModal }: ITrxnModalContext = useContext(TrxnModalContext);

  const { setToaster } = useContext(TrxnToastContext);

  const {
    filters,
    totalCount,
    receipts,
    emailBankStatement,
    emailDownloadReceipt,
    exportRawCSV,
    emailRawCSV,
    isBillsTrxnsPage,
  }: IModalPayloadTypes = showModal?.payload || {};

  return {
    [TRXN_MODAL_TYPES.NONE]: {
      close: () => null,
      className: null,
      children: null,
    },
    [TRXN_MODAL_TYPES.CUSTOM_CSV]: {
      close: resetModal,
      className: classNames(styles.customCSV, "modal-export-csv"),
      children: (
        <CustomCSVModal filters={filters} count={totalCount} resetModal={resetModal} setShowToaster={setToaster} />
      ),
    },
    [TRXN_MODAL_TYPES.BANK_STATEMENT]: {
      close: resetModal,
      className: "trxn__modal--bank-statement",
      children: <BankStatementModal sendEmail={emailBankStatement} />,
    },
    [TRXN_MODAL_TYPES.DOWNLOAD_RECEIPT]: {
      close: resetModal,
      className: "trxn__modal--download-receipt",
      children: <DownloadReceipt sendEmail={emailDownloadReceipt} />,
    },
    [TRXN_MODAL_TYPES.RAW_CSV_LIMIT]: {
      close: resetModal,
      className: "trxn__modal--raw-csv__limit",
      children: <CsvExportModal sendEmail={emailRawCSV} />,
    },
    [TRXN_MODAL_TYPES.RAW_CSV_WARNING]: {
      close: resetModal,
      className: classNames(styles.rawCSv, "trxn__modal--raw-csv__warning"),
      children: <CsvExportWarningModal download={exportRawCSV} />,
    },
    [TRXN_MODAL_TYPES.VIEW_ATTACHMENT]: {
      close: resetModal,
      className: classNames(styles.attachments, "trxn__modal--view__attachments"),
      children: <ViewAttachments isBillsTrxnsPage={isBillsTrxnsPage} receipts={receipts} />,
    },
    [TRXN_MODAL_TYPES.REMOVE_SUBSCRIPTION_WARNING]: {
      close: resetModal,
      className: classNames(styles.subscriptionWarning),
      children: <RemoveSubscriptionWarning />,
    },
  };
};
