import * as React from "react";

import { ITitleWithIconButton } from "Modules/DS/DataTable";

import { blueInfoCircle } from "assets/img";
import "./index.scss";

export const TitleWithIconButton = ({
  children,
  action,
  img = blueInfoCircle,
  altText = "click to get more information",
}: ITitleWithIconButton) => {
  return (
    <span className="title--with--icon-button__container">
      {children}
      <button data-testid="icon-button" className="title--with--icon-button__icon" onClick={action}>
        <img className="title--with--icon-button__image" src={img} alt={altText} />
      </button>
    </span>
  );
};
