import React, { PropsWithChildren } from "react";
import { Skeleton } from "antd";
import { TabLoaderProps } from "Views/Reports/types";

export const TabLoader = (props: PropsWithChildren<TabLoaderProps>) => {
  const { isLoading, children } = props;
  const arr = new Array(4).fill(true);

  if (isLoading) {
    return (
      <>
        {arr.map((_, index) => {
          return (
            <Skeleton
              key={`tab-loader-${index}`}
              active
              round
              title={{ width: 150 }}
              paragraph={false}
            />
          );
        })}
      </>
    );
  }

  return <>{children}</>;
};
