import { useRef, useEffect } from "react";

export const useScrollBottom = (callback: () => void = () => undefined, threshold: number = 0) => {
  const scrollRef = useRef(null);
  useEffect(() => {
    let scrolled = false;
    const handleScrollBottom = ({ target }) => {
      if (!scrolled && target?.scrollHeight - target.scrollTop < target.clientHeight + threshold) {
        scrolled = true;
        callback();
        setTimeout(() => {
          scrolled = false;
        }, 500);
      }
    };
    scrollRef.current?.addEventListener("scroll", handleScrollBottom);
    return () => scrollRef.current?.removeEventListener("scroll", handleScrollBottom);
  }, [scrollRef]);
  return scrollRef;
};
