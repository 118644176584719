import React from "react";
import AddButton from "../AddButton";
import classNames from "classnames";
import { Popover } from "antd";
import styles from "../index.module.scss";
import Icon, { IconList } from "Modules/Icon";
import { useFetchDataFormatter } from "Views/KYX/hooks";
import { IPersonForm, IProgressTabPane } from "Views/KYX/@types";

const COMMON_SVG_ATTR = { width: 24, height: 24, viewBox: "0 0 24 24" };

interface Props {
  handleSelectTab: (item: IPersonForm) => void;
  panes: IProgressTabPane[];
  remarks: any;
  errorTabs: any[];
  activeKey: string;
}

const TabBarExtraContent = ({ handleSelectTab, panes, remarks, errorTabs, activeKey }: Props): JSX.Element => {
  const formatter = useFetchDataFormatter();
  const activePane = panes?.[activeKey];

  const { personnel_information } = formatter?.formData?.form || {};
  const { share_holders, persons } = personnel_information || {};

  const CheckIcon = <Icon className={styles.icCheckIcon} svgAttr={COMMON_SVG_ATTR} icon={IconList.icCheck} />;

  const handleSelectedField = (selectedItem) => {
    return activePane?.id === selectedItem?.id;
  };

  const MoreContent = (): JSX.Element => {
    const getPersonNameFromPanes = (item: IPersonForm): IPersonForm & IProgressTabPane => {
      const pane = panes.find((pane) => pane?.id === item.id);
      item.full_name = pane?.name;
      return { ...item, ...pane };
    };

    const majorShareHolders = persons
      .filter((person) => Boolean(person?.roles_of_individual?.is_major_share_holder && person.source !== "Manual"))
      .map(getPersonNameFromPanes);
    const minorShareHolders = persons
      .filter(
        (person) =>
          Boolean(person?.roles_of_individual?.is_major_share_holder) === false &&
          Boolean(person?.roles_of_individual?.is_share_holder) === true &&
          person.source !== "Manual"
      )
      .map(getPersonNameFromPanes);
    const otherPersons = persons
      .filter(
        (person) =>
          (Boolean(person?.roles_of_individual?.is_share_holder) === false && person.source === "MyInfo") ||
          person.source === "Manual"
      )
      .map(getPersonNameFromPanes);

    return (
      <div className={styles.moreOptionContentBody} onClick={(e) => e.stopPropagation()}>
        {Array.isArray(share_holders) && share_holders.length && (
          <div className={styles.entityShareHolders}>
            <Icon className={styles.icBusinessIcon} svgAttr={COMMON_SVG_ATTR} icon={IconList.icBusiness} />
            <h2>
              Entity Shareholder{share_holders.length > 1 ? "s" : ""} ({share_holders.length})
            </h2>
            {CheckIcon}
          </div>
        )}
        {Array.isArray(majorShareHolders) && majorShareHolders.length ? (
          <>
            <div className={styles.shareHolderTitle}>
              <h4>
                Major Individual Shareholder{majorShareHolders.length > 1 ? "s" : ""} ({majorShareHolders.length})
              </h4>
            </div>
            {majorShareHolders.map((item) => {
              return (
                <div
                  key={item.id}
                  className={classNames(styles.shareHolderItem, { [styles.selected]: handleSelectedField(item) })}
                  onClick={() => handleSelectTab(item)}
                >
                  {item.isNotVerified ? (
                    <Icon className={styles.tabIcon} svgAttr={COMMON_SVG_ATTR} icon={IconList.fillWarning} />
                  ) : (
                    <Icon className={styles.tabIcon2} svgAttr={COMMON_SVG_ATTR} icon={IconList.icPerson} />
                  )}
                  <h2>{item.full_name}</h2>
                  {handleSelectedField(item) && CheckIcon}
                </div>
              );
            })}
          </>
        ) : null}
        {Array.isArray(minorShareHolders) && minorShareHolders.length ? (
          <>
            <div className={styles.shareHolderTitle}>
              <h4>
                Minor Individual Shareholder{minorShareHolders.length > 1 ? "s" : ""} ({minorShareHolders.length})
              </h4>
            </div>
            {minorShareHolders.map((item) => (
              <div
                key={item.id}
                className={classNames(styles.shareHolderItem, { [styles.selected]: handleSelectedField(item) })}
                onClick={() => handleSelectTab(item)}
              >
                {item.isNotVerified ? (
                  <Icon className={styles.tabIcon} svgAttr={COMMON_SVG_ATTR} icon={IconList.fillWarning} />
                ) : (
                  <Icon className={styles.tabIcon2} svgAttr={COMMON_SVG_ATTR} icon={IconList.icPerson} />
                )}
                <h2>{item.full_name}</h2>
                {handleSelectedField(item) && CheckIcon}
              </div>
            ))}
          </>
        ) : null}
        {Array.isArray(otherPersons) && otherPersons.length ? (
          <>
            <div className={styles.shareHolderTitle}>
              <h4>
                Other Personnel{otherPersons.length > 1 ? "s" : ""} ({otherPersons.length})
              </h4>
            </div>
            {otherPersons.map((item) => (
              <div
                key={item.id}
                className={classNames(styles.shareHolderItem, { [styles.selected]: handleSelectedField(item) })}
                onClick={() => handleSelectTab(item)}
              >
                <Icon className={styles.tabIcon2} svgAttr={COMMON_SVG_ATTR} icon={IconList.icPerson} />
                <h2>{item.full_name}</h2>
                {handleSelectedField(item) && CheckIcon}
              </div>
            ))}
          </>
        ) : null}
      </div>
    );
  };

  return (
    <>
      <Popover
        overlayClassName={styles.moreOptionContent}
        content={<MoreContent />}
        placement="bottomRight"
        trigger="click"
      >
        <button onClick={(e) => e.stopPropagation()}>
          <span className={styles.addButton}>
            <Icon
              className={styles.moreOptionIcon}
              svgAttr={{ width: 24, height: 16, viewBox: "0 0 24 16" }}
              icon={IconList.verticalMore}
            />
          </span>
        </button>
      </Popover>
      <AddButton />
    </>
  );
};

export default TabBarExtraContent;
