import React from "react";
import { Skeleton } from "antd";
import { getCookieValue, cookieNames } from "utility/CookieHelper";
import { Tags, Thumbnail, Typography } from "@spenmo/splice";
import styles from "./index.module.scss";

interface IProps {
  userName: string;
}

const Header: React.FC<IProps> = ({ userName }: IProps) => {
  const role = getCookieValue(cookieNames.USER_COMPANY_ROLE);

  return (
    <div className={styles.header}>
      <Thumbnail initial={userName?.charAt(0) || ""} shape="circle" size="l" variant="orange" />
      {userName ? (
        <Typography size="m" variant="body-content" weight={600}>
          {userName}
        </Typography>
      ) : (
        <Skeleton.Button shape="round" className={styles.nameLoader} active />
      )}
      <Tags.Label hierarchy="default" label={role} size="l" variant="info" />
    </div>
  );
};

export default Header;
