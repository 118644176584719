import APIclient from "API/Client";
import { GetBaseAuthObject } from "utility";
import { trackEvent } from "utility/analytics";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import { categoryEvents } from "Views/Categories/Analytics";

export const FETCH_CREATE_REQUEST = "FETCH_CREATE_REQUEST";
export const FETCH_CREATE_SUCCESS = "FETCH_CREATE_SUCCESS";
export const FETCH_CREATE_FAILURE = "FETCH_CREATE_FAILURE";

export const fetchCreateDataReq = () => {
  return {
    type: FETCH_CREATE_REQUEST,
    data: {},
  };
};

export const fetchCreateDataSuccess = (data) => {
  return {
    type: FETCH_CREATE_SUCCESS,
    data: {
      payload: data,
    },
  };
};

export const fetchCreateDataError = (data) => {
  return {
    type: FETCH_CREATE_FAILURE,
    data: data,
  };
};

export const CategoryCreateFunc = (data, trackActions = false) => {
  const orgId = GetBaseAuthObject().orgId;
  return (dispatch) => {
    dispatch(fetchCreateDataReq());
    return (
      APIclient
        /*eslint no-process-env: "off"*/
        .postData("/api/categories/create", { organisation_id: orgId, ...data })
        .then((data: { [propName: string]: any }) => {
          if (data.data.status > HTTP_STATUS_CODE.OK) {
            dispatch(fetchCreateDataError(data.data));
            trackActions && trackEvent(categoryEvents.ADD_CATEGORY_FAILURE);
            return Promise.reject();
          } else {
            dispatch(fetchCreateDataSuccess(data.data));
            trackActions && trackEvent(categoryEvents.ADD_CATEGORY_SUCCESS);
          }
        })
        .catch((err) => {
          dispatch(fetchCreateDataError(err));
          trackActions && trackEvent(categoryEvents.ADD_CATEGORY_FAILURE);
          return Promise.reject();
        })
    );
  };
};
