import * as React from "react";
import { Skeleton } from "antd";

import "./index.scss";

const ApprovalActionShimmer = () => {
  return (
    <div data-testid="shimmer-container" className="approval-action__shimmer__container">
      {Array?.from({ length: 2 })?.map((_, index) => (
        <Skeleton.Input className="approval-action__shimmer__btn" active key={index} />
      ))}
      <Skeleton.Input className="approval-action__shimmer__btn--link" active />
    </div>
  );
};

export default ApprovalActionShimmer;
