import React from "react";

import { FORMS_PROGRESS } from "Views/KYX/Constants";
import { IResumeIntroFormsOverview } from "Views/KYX/@types";
import Icon from "Modules/icons";
import { LabelChip } from "Modules/DS/Chip/Label";

import { Progress } from "./Progress";

import styles from "./index.module.scss";

/**
 * A React Component that is used to render the Overview of the Resume Intro Screens when a user is logging in again to their last saved session.
 *
 * @param {number} lastStep - indicates which form filling step the user had progressed to.
 * @returns {JSX.Element} A JSX Element
 */

export const Overview = ({ lastStep, details }: IResumeIntroFormsOverview): JSX.Element => {
  return (
    <div className={styles.card}>
      {details && (
        <div className={styles.iconDetails}>
          <Icon className={styles.documentIcon} src={details.icon} alt="document type" />
          <LabelChip title={details?.label?.title} status={details?.label?.status} />
        </div>
      )}

      <h4 className={styles.docTitle}>Continue where you left off</h4>
      <p className={styles.label}>Resume your company verification process from your latest saved draft</p>
      <Progress list={FORMS_PROGRESS} lastStep={lastStep} />
    </div>
  );
};
