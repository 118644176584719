import * as React from "react";
import { Progress } from "antd";
import { useCardDetailsContext } from "Views/Card/Details/index.context";

import styles from "Views/Card/Details/SubInfo/FuelGuage/index.module.scss";

const FuelGuage: React.FunctionComponent = () => {
  const { cardData } = useCardDetailsContext();

  const getFuelGuage = () => {
    const {
      cardRemainingLimit = "",
      cardTotalLimit = "",
      spendPercentage = 0,
    } = cardData?.spendingAllowanceDetails || {};
    return (
      <div className={styles["fuel-guage"]}>
        <Progress
          percent={spendPercentage}
          className={`${styles["progress-bar"]} ${spendPercentage > 100 ? styles["progress-bar--red"] : ""}`}
          data-testid="progress-bar"
        />
        <div className={styles["fuel-guage-limits-container"]}>
          <p className={`${styles["info-text"]} ${spendPercentage > 100 ? styles["info-text--red"] : ""}`}>
            {cardRemainingLimit}
          </p>
          <p className={styles["info-text"]}>{cardTotalLimit}</p>
        </div>
      </div>
    );
  };

  const getCardRemainderSpendingAllowance = () => {
    const { headerText = "", footerText = "", limitToDisplay = "" } = cardData?.spendingAllowanceDetails || {};

    if (headerText || footerText || limitToDisplay) {
      return (
        <div className={styles["card-spending-allowance"]}>
          {headerText && <div className={styles["header"]}>{headerText}</div>}
          {limitToDisplay && (
            <div className={styles["limit-container"]}>
              <p className={styles["limit"]}>{limitToDisplay?.replace?.("remaining", "")}</p>
              {limitToDisplay?.includes?.("remaining") && <p className={styles["limit-text"]}>remaining</p>}
            </div>
          )}
          {footerText && <div className={styles["footer"]}>{footerText}</div>}
          {getFuelGuage()}
        </div>
      );
    }
    return null;
  };
  return getCardRemainderSpendingAllowance();
};

export default FuelGuage;
