/* eslint-disable react-hooks/exhaustive-deps */
import { CurrencyInput } from "Modules/ExchangeInput";
import { TaxData, taxCalculator } from "Redux/DataCalls/Disbursement.api";
import { Checkbox, Form, Select } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { FormInstance } from "antd/lib/form";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { currencyFormatterV2 } from "utility";
import styles from "./WHTCalculator.module.scss";

const { Option } = Select;

export interface WHTCalculatorProps {
  form: FormInstance<any>;
  defaultValue?: TaxData;
  onTaxDataChange?: (taxData: TaxData) => void;
}

const WHTCalculator: React.FC<WHTCalculatorProps> = ({ form, defaultValue, onTaxDataChange }) => {
  const [isInclusive, setIsInclusive] = useState(true);
  const [hasManualWHT, setHasManualWHT] = useState(false);
  const taxesData = useSelector((state: any) => state.taxReducer.data?.payload?.data || []);
  const [taxData, setTaxData] = useState<TaxData | undefined>(undefined);

  const vatRateCalculation = (rate: number) => {
    //TODO: Improvement to calculate from BE
    return String(rate / 100 + 1);
  };

  useEffect(() => {
    if (defaultValue && !taxData) {
      setIsInclusive(defaultValue.isInclusive);
      setHasManualWHT(defaultValue.hasManualWHT);
      form.setFieldsValue({
        billTax: {
          vatRate: defaultValue.vatRate?.toString(),
          amount: defaultValue.amount?.toString(),
          taxAmount: defaultValue.taxAmount?.toString(),
          isInclusive: defaultValue.isInclusive,
          hasManualWHT: defaultValue.hasManualWHT,
        },
      });
      setTaxData(defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  const toggleShowTax = (e: CheckboxChangeEvent) => {
    const checked = e.target.checked;
    setIsInclusive(checked);
    form.setFieldsValue({
      billTax: {
        isInclusive: checked,
        vatRate: undefined,
      },
    });
  };

  const toggleHasManualWHT = (e: CheckboxChangeEvent) => {
    const checked = e.target.checked;
    setHasManualWHT(checked);
    form.setFieldsValue({
      billTax: {
        hasManualWHT: checked,
        taxAmount: "",
      },
    });
  };

  const handleChangeTax = (_key, option) => {
    form.setFieldsValue({ tax: option.key });
  };

  useEffect(() => {
    const amount = form.getFieldValue(["billTax", "amount"]) || 0;
    const vatRate = form.getFieldValue(["billTax", "vatRate"]);
    if (amount && (!isInclusive || vatRate)) {
      const params = {
        amount,
        taxRate: form.getFieldValue("whtRate"),
      };
      if (vatRate) {
        params["vatRate"] = vatRate;
      }
      const manualWHTData = hasManualWHT
        ? { hasManualWHT, taxAmount: Number(form.getFieldValue(["billTax", "taxAmount"]) || 0) }
        : {};
      taxCalculator(params).then((res) => setTaxData({ ...res.data.payload, ...manualWHTData }));
    } else {
      setTaxData({
        amount,
        taxRate: 0,
        vatRate: undefined,
        vatAmount: 0,
        taxAmount: 0,
        totalAmount: amount,
        isInclusive,
      });
    }
  }, [
    form.getFieldValue(["billTax", "amount"]),
    form.getFieldValue(["billTax", "taxAmount"]),
    form.getFieldValue(["billTax", "vatRate"]),
    form.getFieldValue("whtRate"),
    isInclusive,
    hasManualWHT,
  ]);

  useEffect(() => {
    if (taxData && onTaxDataChange) {
      const taxAmount = taxData.taxAmount;
      if (hasManualWHT) {
        onTaxDataChange({ ...taxData, totalAmount: taxData.amount - Number(taxAmount) });
      } else {
        onTaxDataChange(taxData);
      }
    }
  }, [taxData, onTaxDataChange, hasManualWHT]);

  return (
    <div className={styles.wrapper}>
      <CurrencyInput
        currency="PHP"
        label="Invoice Amount"
        defaultValue={taxData?.amount.toString()}
        name={["billTax", "amount"]}
        rules={[{ required: true, message: "This field cannot be empty" }]}
      />
      <Form.Item>
        <Checkbox checked={isInclusive} onChange={toggleShowTax}>
          Invoice amount is VAT inclusive
        </Checkbox>
      </Form.Item>
      {isInclusive && (
        <>
          <Form.Item
            name={["billTax", "vatRate"]}
            label="Tax"
            className={styles.subfield}
            rules={[{ required: true, message: "Please select the tax rate" }]}
          >
            <Select placeholder="Select tax" onChange={handleChangeTax}>
              {taxesData?.map((item) => (
                <Option key={item.id} value={vatRateCalculation(item.tax_rate)}>
                  {item.tax_name} ({item.tax_rate}%)
                </Option>
              ))}
            </Select>
          </Form.Item>
          <div className={styles.spaceBetween}>
            <span>VAT Amount</span>
            <span>{currencyFormatterV2(taxData?.vatAmount || 0, "PHP")}</span>
          </div>
        </>
      )}
      {hasManualWHT ? (
        <CurrencyInput
          currency="PHP"
          label="Withholding Tax Amount"
          name={["billTax", "taxAmount"]}
          rules={[{ required: true, message: "Please select currency and enter amount" }]}
        />
      ) : (
        <div className={styles.spaceBetween}>
          <span>Withholding Tax Amount</span>
          <span>{currencyFormatterV2(taxData?.taxAmount || 0, "PHP")}</span>
        </div>
      )}
      <Form.Item>
        <Checkbox checked={hasManualWHT} onChange={toggleHasManualWHT}>
          Enter tax amount manually
        </Checkbox>
      </Form.Item>
    </div>
  );
};

export default WHTCalculator;
