import React, { useEffect } from "react";
import { useQuery } from "utility";
import { RootState } from "Redux/ConfigureStore";
import { Redirect } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useAppSelector } from "hook";

import { COMPANY_VERIFICATION } from "Route/KYX/paths";
import { RedirectionLoader } from "../Loader/RedirectionLoader";
import { MyInfoCallBack as MyInfoCallBackAPI } from "Redux/Actions";
import { IMyInfoCallBackReducer } from "Redux/Actions/KYX/types";
import styles from "./index.module.scss";

const MyInfoCallBack: React.FC = (): JSX.Element => {
  let query = useQuery();
  const dispatch = useDispatch();

  const code: string = query.get("code");
  const state: string = query.get("state");

  const myInfoCallBackData: IMyInfoCallBackReducer = useAppSelector((state: RootState) => state.myInfoCallBackReducer);

  useEffect(() => {
    const onMyInfoSuccess = () => {
      dispatch(MyInfoCallBackAPI({ code: code, state: state }));
    };
    code && state && onMyInfoSuccess();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code, state]);

  if (myInfoCallBackData?.isSuccess) {
    return <Redirect to={COMPANY_VERIFICATION.COMPANY_INFORMATION} />;
  }

  if (myInfoCallBackData?.error) {
    return <Redirect to={COMPANY_VERIFICATION.WELCOME} />;
  }

  if (myInfoCallBackData?.loading) {
    return <RedirectionLoader />;
  }

  return (
    <div className={styles.wrapper}>
      <h2 className={styles.title}>
        We are unable to retrieve your <br /> information from Myinfo business
      </h2>
      <p className={styles.message}>
        Click <a href={COMPANY_VERIFICATION.WELCOME}>here</a> to proceed with your application
      </p>
      <p className={styles.info}>
        Please contact{" "}
        <a href="mailto:support@myinfo.gov.sg" rel="noopener noreferrer">
          support@myinfo.gov.sg
        </a>{" "}
        if you require additional assistance
      </p>
    </div>
  );
};

export default MyInfoCallBack;
