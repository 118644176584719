export const getDispatchObject = (action, data = {}) => ({
  type: action,
  data: { ...data },
});

export const getFailureDispatchObj = (actionType, payload, genericErrorMessage = "") => {
  const errorMessage =
    payload?.errors?.[0]?.message || genericErrorMessage || "Something went wrong. Please try again later.";
  return getDispatchObject(actionType, { errorMessage });
};
