import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import React, { useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RequestTypes } from "Views/Home/Components/MyCards/UserCards/AddCard/RequestTypes.constants";
import { showErrorNotification, showSuccessNotification } from "Views/Card/Components/Notification";

import AddOrRequestCard from "Views/Card/Add";
import { cardCTABtn } from "Views/Home/Components/MyCards/UserCards/Utilities";
import { resetCreateCardData } from "Redux/Actions/CreateCard";

const AddCard = ({ refetchUserCardsList }) => {
  const dispatch = useDispatch();
  // * Need to remove any type initialState.js is moved to typescript
  const history = useHistory();
  const { data: createdCardData = {} } = useSelector((state: any) => state?.createCardReducer);

  const handleCardCreation = () => {
    if (createdCardData?.payload?.status === HTTP_STATUS_CODE.OK) {
      const { type = "" } = createdCardData?.payload;
      if ([RequestTypes.physical.type, RequestTypes.virtual.type, RequestTypes.physicalSync.type].indexOf(type) != -1) {
        showSuccessNotification(RequestTypes[type]?.successMessage || "");
        dispatch(resetCreateCardData());
      }
      if (type === RequestTypes.virtual.type) {
        history.replace(`/cards`);
      } else if (type === RequestTypes.physicalSync.type) {
        refetchUserCardsList();
      }
    } else if (createdCardData?.payload?.status > HTTP_STATUS_CODE.OK) {
      const { status_message = "Something went wrong, Please try again later." } =
        createdCardData?.payload?.payload || {};
      showErrorNotification(status_message);
      dispatch(resetCreateCardData());
    }
  };

  useEffect(() => {
    return () => {
      dispatch(resetCreateCardData());
    };
  }, []);

  useEffect(() => {
    handleCardCreation();
  }, [createdCardData]);

  const onFinishHandler = useCallback((isAdminOrManagerInSelectedTeam) => {
    history.replace(`/cards${isAdminOrManagerInSelectedTeam ? "" : "?cardListActiveTab=sentCardRequests"}`);
  }, []);

  const getAddCardBtn = () => {
    return <AddOrRequestCard onFinish={onFinishHandler} buttonComponent={cardCTABtn("Add Card")} />;
  };

  return <>{getAddCardBtn()}</>;
};

export default AddCard;
