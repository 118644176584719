import React from "react";
import { Skeleton } from "antd";

export const ExpenseShimmer = ({ limitModel = false }) => {
  return (
    <>
      {Array.from({ length: limitModel ? 2 : 3 })?.map((key) => (
        <div className="expense-card__shimmer-container" key={key}>
          <Skeleton.Input className="expense-title" active />
          <Skeleton.Input className="expense" active />
        </div>
      ))}
    </>
  );
};
