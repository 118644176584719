import APIclient from "API/Client";

import { AxiosResponse } from "axios";

import {
  IStartKYXManuallyResponse,
  ISaveAsDraftResponse,
  ISubmitKYXResponse,
  ISaveAsDraftRequest,
  ISubmitKYXRequest,
  IUserConsentResponse,
  IFetchFormSubmissionResponse,
  IRemarksSummaryResponse,
  IGetFormConfigsResponse,
  IGetUserConsentResponse,
} from "Views/KYX/@types";

import { KYX_SERVICE } from "./Services";

export const startKYXManually = async (): Promise<AxiosResponse<IStartKYXManuallyResponse>> => {
  return await APIclient.postData(KYX_SERVICE().START_KYX_MANUALLY);
};

export const setUserConsent = async (): Promise<AxiosResponse<IUserConsentResponse>> => {
  return APIclient.postData(`${KYX_SERVICE().USER_CONSENT}`);
};

export const getUserConsent = async (): Promise<AxiosResponse<IGetUserConsentResponse>> => {
  return APIclient.getData(`${KYX_SERVICE().USER_CONSENT}`);
};

export const getKYXFormData = async (): Promise<AxiosResponse<IFetchFormSubmissionResponse>> => {
  return APIclient.getData(KYX_SERVICE().KYB_FORM_DATA);
};

export const getSingpassToken = async (personnelId: string): Promise<AxiosResponse<any>> => {
  return APIclient.postData(`${KYX_SERVICE().GET_SINGPASS_TOKEN}/${personnelId}/token`);
};

export const submitKYXFormData = async (payload: ISubmitKYXRequest): Promise<AxiosResponse<ISubmitKYXResponse>> => {
  return APIclient.postData(KYX_SERVICE().KYB_FORM_DATA, payload);
};

export const getFormConfigs = async (): Promise<AxiosResponse<IGetFormConfigsResponse>> => {
  return APIclient.getData(KYX_SERVICE().GET_FORM_CONFIGS);
};

export const getSummaryConfigs = async (): Promise<AxiosResponse<IRemarksSummaryResponse>> => {
  return APIclient.getData(KYX_SERVICE().GET_SUMMARY_CONFIGS);
};

export const markKYXCompleted = async (): Promise<AxiosResponse<ISubmitKYXResponse>> => {
  return APIclient.postData(KYX_SERVICE().COMPLETE);
};

export const saveKYXFormAsDraft = async (
  payload: ISaveAsDraftRequest
): Promise<AxiosResponse<ISaveAsDraftResponse>> => {
  return APIclient.postData(KYX_SERVICE().SAVE_KYB_DRAFT_FORM_DATA, payload);
};
