import React from "react";
import { CrossFilled } from "@spenmo/splice";

export enum AUTO_RECONCILED_TRXNS_COLUMNS {
  DATE_N_TIME = "Created Date & Time",
  TYPE = "Type",
  OWNER_BUDGET = "Owner/Budget",
  VENDOR = "Vendor",
  ATTACHMENT = "Attachment",
  AMOUNT = "Amount",
  SUBSCRIPTION = "Subscription",
}

export enum AUTO_RECONCILED_TRXNS_PARAMS {
  ID = "id",
  TYPE = "type",
  OWNER = "owner",
  BUDGET = "budget",
  SUBSCRIPTION = "subscription",
  VENDOR = "vendor",
  ATTACHMENTS = "attachments",
  AMOUNT = "amount",
  CURRENCY = "currency",
  CREATED_AT = "created_at",
}

export const NOT_A_SUBSCRIPTION = {
  id: "not_a_subscription_id",
  title: "Not a subscription",
  IconComponent: <CrossFilled iconColor="var(--icon-default)" size="16" />,
  disabled: false,
};

export const SUBSCRIPTION_DROPDOWN = "subscription-dropdown";
