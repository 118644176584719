export enum CARD_LIMIT_TYPES {
  dailyLimit = "daily_limit",
  monthlyLimit = "monthly_limit",
  yearlyLimit = "yearly_limit",
  totalLimit = "total_limit",
  cardTransactionLimit = "card_transaction_limit",
  unlimited = "none",
}

export enum CARD_REQUEST_STATUS {
  REQUESTED = "requested",
  REJECTED = "rejected",
}

export enum CARD_STATUS {
  ENABLED = "active",
  DISABLED = "suspended",
  IN_PROGRESS = "in_progress",
  NOT_ACTIVATED = "not_activated",
}

export enum SPENDING_LOCK_TYPES {
  CATEGORY = "category",
  MERCHANT = "merchant",
  NONE = "none",
}

export enum CARD_USER_VENDOR {
  UOB = "UOB",
  NIUM = "NIUM",
  MATCHMOVE = "MatchMove",
  MMPH = "MM-PH",
}
export interface ISpendingAllowanceDetails {
  headerText: string;
  footerText: string;
  limitToDisplay: string;
  cardRemainingLimit: string;
  cardTotalLimit: string;
  spendPercentage: number;
}

export interface ISpendingLock {
  name: string;
  logo: string;
  altText: string;
  id: string;
}
export interface ISpendingLockWithNameAndId {
  name: string;
  id: string;
}

export interface ITag {
  label: string;
  value: string;
  id?: number;
}
export interface ITagGroup {
  id: number;
  label: string;
  selectedChildren: number;
  values: { id: number; value: string }[];
}
export interface ICardDetails {
  cardType: CARD_TYPE;
  cardTitle: string;
  cardHolder: string;
  cardColor: string;
  cardColorName: string;
  team: string;
  teamId: string;
  spendingLimit: string;
  spendingLimitType: CARD_LIMIT_TYPES;
  spendingLockType?: SPENDING_LOCK_TYPES;
  spendingLocks?: ISpendingLock[];
  expenseCategoryName: string;
  expenseCategoryId: number;
  shippingAddress: string;
  rejectionReason: string;
  cardStatus: CARD_REQUEST_STATUS | CARD_STATUS;
  id: string;
  isCardRequestDetails: boolean;
  isCardPinSet: boolean;
  cardLastFourDigits: string;
  cardExpiryMonth: string;
  cardExpiryYear: string;
  cardHolderContact: string;
  spendingAllowanceDetails: ISpendingAllowanceDetails | null;
  letMyManagerChooseLimit: boolean;
  isNotActivatedPhysicalCard: boolean;
  noteToManager: string;
  tags: ITag[];
  cardUserId: string;
}

export enum CARD_TYPE {
  VIRTUAL = "Virtual",
  PHYSICAL = "Physical",
}

export enum KYC_STATUS {
  "INITIAL" = "",
  "PENDING" = "0",
  "SUBMITTED" = "1",
  "APPROVED" = "2",
  "REJECTED" = "-1",
}
