import * as React from "react";
import { CARD_REQUEST_STATUS, CARD_STATUS } from "../types";
import { CARD_LIST_TABS, ICardListContext } from "./types";

export const initialFilter = {
  search_filters: {},
  team_id: [],
  ordering: {},
};

export const InitialState: ICardListContext = {
  cardListLoading: false,
  bulkChangeStatusOrCreateCardDataInProgress: false,
  canAddCards: true,
  selectedRows: [],
  handleRowClick: (_cardDetails) => {
    /* do nothing */
  },
  setSelectedRows: () => {
    /* do nothing */
  },
  totalCount: null,
  currentPage: null,
  setCurrentPage: null,
  getCardListData: (_page: number, _view?: string | string[]) => {
    /* do nothing */
  },
  paginatorStartIndex: null,
  isNotFiltered: (_filterData) => {
    return true;
  },
  filters: initialFilter,
  tabs: [],
  cardListActiveTab: CARD_LIST_TABS.CARD_LIST,
  changeCardListActiveTab: () => {
    /* do nothing */
  },
  cardsListTabsData: null,
  selectedRowId: "",
  setCardDetails: () => {
    /* do nothing */
  },
  cardDetails: null,
  setShowCardDeleteModal: (_cardId: string) => {
    /* do nothing */
  },
  deleteInProgressCards: [],
  setShowCardActivationModal: (_cardId: string) => {
    /* do nothing */
  },
  activationInProgressCards: [],
  enableOrDisableCard: (
    _cardStatus: CARD_STATUS | CARD_REQUEST_STATUS,
    _cardId: string,
    _canUserEditCards: boolean
  ) => {
    /* do nothing */
  },
  enableOrDisableInProgressCards: [],
};

const CardListContext = React.createContext<ICardListContext>(InitialState);

export const useCardListContext = () => React.useContext(CardListContext);

export default CardListContext;
