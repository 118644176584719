import React, { useContext } from "react";

import { Col, Row } from "Splice/Grid";

import { IKYXModalContext, IResumeIntroManual } from "Views/KYX/@types";

import { ResumeIntroHeader as Header } from "Views/KYX/Intro/Resume/Header";
import { ResumeVerification, ResumeWithMyInfo } from "Views/KYX/Intro/Resume/Button";

import {
  KYB_METHOD_LIST,
  KYX_MODAL_TYPES,
  RESUME_INTRO_PROGRESS_OVERVIEW_LAST_STEP_PROXY_VALUE,
} from "Views/KYX/Constants";
import { Overview } from "./Overview";
import { KYXModalContext } from "Views/KYX/Context";
import styles from "./index.module.scss";

/**
 * A React Component that is used to render the WithoutAutomatedSource component of the Resume Intro Screens when a user is logging in again to their last saved session.
 * It is maintained according to the KYX_COUNTRY_LIST
 *
 * @param {string} companyName - A string value that contains the name of the company.
 * @returns {JSX.Element} A JSX Element
 */

export const WithoutAutomatedSource = ({ companyName, showResumeMyInfo = false }: IResumeIntroManual): JSX.Element => {
  const { setModal }: IKYXModalContext = useContext(KYXModalContext);

  const showResumeMyinfoModal = (): void => {
    setModal({
      visible: true,
      type: KYX_MODAL_TYPES.CHANGE_KYB_METHOD,
      payload: { methodType: KYB_METHOD_LIST.MY_INFO },
    });
  };

  return (
    <Row className={styles.welcomeBack} align="end" justify="start">
      <Col className={styles.header} span={24}>
        <Header companyName={companyName} />
        <Overview lastStep={RESUME_INTRO_PROGRESS_OVERVIEW_LAST_STEP_PROXY_VALUE} />
      </Col>
      <Col className={styles.actionBtns} span={24}>
        <ResumeVerification />
        <ResumeWithMyInfo action={showResumeMyinfoModal} />
      </Col>
    </Row>
  );
};
