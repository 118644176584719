import React, { useState, useEffect } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import NumberIcon from "../numberIcon";
import { getCurrencyLocale } from "../../utility";
import CurrencyInput from "react-currency-input-field";
import { Form, Input, Select } from "antd";
import "./selectAmount.scss";

const SelectAmount = (props) => {
  const {
    action,
    amountOptions = [],
    className,
    currencyAction,
    currencyData,
    initialCurrency,
    initialValue,
    rules,
    showAmountOptions,
    inputOnBlurHandler = () => undefined,
    trackActions = false,
    isShowSearch,
    inputClassName,
    selectClassName,
    onFocusCurrency = () => undefined,
    onFocusAmount = () => undefined,
    onBlurCurrency = () => undefined,
    onBlurAmount = () => undefined,
    disableZeroLimit = false,
    decimalsLimit = 8,
  } = props;

  const [value, setValue] = useState(initialValue);
  const [currency, setCurrency] = useState(initialCurrency || "SGD");
  useEffect(() => {
    setValue(initialValue);
    setCurrency(initialCurrency);
  }, [initialCurrency, initialValue]);

  const handleValueChange = (value) => {
    const validateValue = disableZeroLimit ? parseInt(value) && value : !!value;
    const inputVal = validateValue ? value.replace(",", ".") : "";
    setValue(validateValue ? value : "");
    action(inputVal);
  };

  const handleCurrencyChange = (val) => {
    setCurrency(val);
    currencyAction(val);
    setValue(value && value.toString().replace(",", "."));
  };

  const setNumberFormat = (amount, display = false) => {
    if (currency === "IDR") {
      const value = amount.toFixed(3);
      return display ? value : value.replace(".", "");
    }
    return amount;
  };

  const handleAmountOptions = (amount) => {
    setValue(setNumberFormat(amount));
    action(setNumberFormat(amount));
    trackActions && inputOnBlurHandler(setNumberFormat(amount));
  };

  const _className = classNames("select-amount", className);

  const SelectCurrency = (
    <Select
      className={`select-amount__select ${selectClassName}`}
      value={currency}
      onChange={handleCurrencyChange}
      showSearch={isShowSearch}
      onFocus={onFocusCurrency}
      onBlur={onBlurCurrency}
    >
      {currencyData?.map((currency) => (
        <Select.Option key={currency.currency_code} value={currency.currency_code}>
          {currency.currency_code}
        </Select.Option>
      ))}
    </Select>
  );

  return (
    <Form.Item name={"amount"} className={_className} rules={rules}>
      <Input.Group compact className="select-amount__input-wrapper">
        {currencyData?.length > 0 ? SelectCurrency : <div className={"select-amount__currency"}>{initialCurrency}</div>}
        <CurrencyInput
          value={value}
          allowNegativeValue={false}
          placeholder={"Enter amount"}
          className={`select-amount__input-amount ${inputClassName}`}
          step={value}
          intlConfig={{ locale: getCurrencyLocale(currency || "SGD") }}
          decimalsLimit={decimalsLimit}
          onValueChange={handleValueChange}
          onBlur={() => {
            onBlurAmount();
            trackActions && inputOnBlurHandler(value);
          }}
          onFocus={onFocusAmount}
        />
      </Input.Group>
      {showAmountOptions && (
        <div className={"select-amount__amount-options"}>
          {amountOptions.map((amount, index) => (
            <NumberIcon
              primary={currency === "IDR"}
              key={index}
              action={() => handleAmountOptions(amount)}
              number={setNumberFormat(amount, true)}
            />
          ))}
        </div>
      )}
    </Form.Item>
  );
};

SelectAmount.defaultProps = { action: () => {}, isShowSearch: false, inputClassName: "", selectClassName: "" };

SelectAmount.propTypes = {
  isShowSearch: PropTypes.bool,
  inputClassName: PropTypes.string,
  decimalsLimit: PropTypes.number,
  selectClassName: PropTypes.string,
  action: PropTypes.func,
  amountOptions: PropTypes.any,
  className: PropTypes.string,
  currencyAction: PropTypes.any,
  currencyData: PropTypes.array,
  initialCurrency: PropTypes.string,
  initialValue: PropTypes.any,
  rules: PropTypes.arrayOf(PropTypes.any),
  showAmountOptions: PropTypes.bool,
  alternate: PropTypes.bool,
  inputOnBlurHandler: PropTypes.func,
  trackActions: PropTypes.bool,
  onFocusCurrency: PropTypes.func,
  onFocusAmount: PropTypes.func,
  onBlurCurrency: PropTypes.func,
  onBlurAmount: PropTypes.func,
};

export default SelectAmount;
