export enum KYX_FORMS_KEYS {
  FORM_ID = "id",
  SAVE_FORM_ID = "form_id",
  FORM = "form",
  SOURCE = "source",
  // COMPANY_INFORMATION_FORMS
  COMPANY_ADDRESS = "company_address",
  GENERAL_INFORMATION = "general_information",
  ADDITIONAL_INFORMATION = "additional_information",
  // COMPANY_INFORMATION_ELEMENTS
  // // GENERAL_INFORMATION_ELEMENTS
  EMAIL = "email",
  BRAND_NAME = "brand_name",
  SOURCE_OF_FUNDS = "source_of_funds",
  VALUES = "values",
  OTHER_VALUE = "other_value",
  LEGAL_ENTITY_NAME = "legal_entity_name",
  LEGAL_ENTITY_TYPE = "legal_entity_type",
  NUMBER_OF_EMPLOYEES = "number_of_employees",
  COUNTRY_OF_OPERATION = "country_of_operation",
  DATE_OF_INCORPORATION = "date_of_incorporation",
  COUNTRY_OF_INCORPORATION = "country_of_incorporation",
  ESTIMATE_ANNUAL_TURNOVER = "estimate_annual_turnover",
  IS_SAME_OPERATION_COUNTRY = "is_same_operation_country",
  DOESNT_HAVE_PUBLIC_WEBSITE = "doesnt_have_public_website",
  PURPOSE_OF_OPENING_ACCOUNT = "purpose_of_opening_account",
  COMPANY_PRIMARY_BUSINESS_ACTIVITY = "company_primary_business_activity",
  SECONDARY_COMPANY_BUSINESS_ACTIVITY = "secondary_company_business_activity",
  EXPECTED_MONTHLY_VALUE_OF_TRANSACTION = "expected_monthly_value_of_transaction",
  PRIMARY_BUSINESS_ACTIVITY_DESCRIPTION = "primary_business_activity_description",
  EXPECTED_MONTHLY_VOLUME_OF_TRANSACTION = "expected_monthly_volume_of_transaction",
  SECONDARY_BUSINESS_ACTIVITY_DESCRIPTION = "secondary_business_activity_description",
  // // COMPANY_ADDRESS_ELEMENTS
  COMPANY_ADDRESS_POSTAL_CODE = "postal_code",
  BUSINESS_ADDRESS = "business_address",
  IS_BUSINESS_ADDRESS_DIFFERENT = "is_business_address_different",
  REGISTERED_ADDRESS = "registered_address",
  BUSINESS_POSTAL_CODE = "business_postal_code",
  // // ADDITIONAL_INFORMATION_ELEMENTS
  SOCIAL_MEDIA = "social_media",
  SOCIAL_MEDIA_URL = "url",
  SOCIAL_MEDIA_TYPE = "type",
  COMPANY_WEBSITE = "company_website",
  // COMPANY_DOCUMENT_ELEMENTS
  ACRA_DOCUMENT = "arca_document",
  ACRA_DOCUMENT_NUMBER = "number",
  ACRA_DOCUMENT_EXPIRY = "expiry_date",
  ACRA_DOCUMENT_FILE = "document_file",
  MEMORANDUM_FILE = "memorandum_file",
  OWNERSHIP_CHART_FILE = "ownership_chart_file",
  DUE_DILIGENCE_DOCUMENTS = "due_diligence_documents",
  OWNERSHIP_NUMBER_OF_LEVELS = "ownership_number_of_levels",
  IS_DILIGENCE_DOCUMENT_REQUIRED = "is_diligence_document_required",
  SIGNED_AUTHORIZED_USER_FORM_FILE = "signed_authorized_user_form_file",
  SIGNED_BOARD_RESOLUTION_FORM = "signed_board_resolution_form_file",
  // PERSONNEL_INFORMATION_ELEMENTS
  PERSONNEL_DATA_PERSONS = "persons",
  PERSONNEL_DATA_PERSON = "person",
  PERSONNEL_DATA_SHAREHOLDERS = "share_holders",
  PERSONNEL_DATA_ONLY_DIRECTOR = "has_only_director",
  PERSONNEL_DATA_NUMBER_OF_BENEFICIAL_OWNERS = "number_of_beneficial_owners",
  // PERSON_ELEMENTS
  PERSON_DATA_ID = "id",
  PERSON_DATA_ALIAS = "alias",
  PERSON_DATA_GENDER = "gender",
  PERSON_DATA_POSITION = "position",
  PERSON_DATA_FULL_NAME = "full_name",
  PERSON_DATA_NATIONALITY = "nationality",
  PERSON_DATA_SHARE_TYPE = "share_type",
  PERSON_DATA_POSTAL_CODE = "postal_code",
  PERSON_DATA_DOB = "date_of_birth",
  PERSON_DATA_EMAIL = "email_address",
  PERSON_DATA_MOBILE = "mobile_number",
  PERSON_DATA_SHARE_ALLOCATION = "share_allocation",
  PERSON_DATA_OWNERSHIP_CATEGORY = "ownership_category",
  PERSON_DATA_RESIDENTIAL_ADDRESS = "residential_address",
  PERSON_DATA_ROLES = "roles_of_individual",
  PERSON_ROLES_DIRECTOR = "is_director",
  PERSON_ROLES_MAJOR = "is_major_share_holder",
  PERSON_ROLES_SHAREHOLDER = "is_share_holder",
  PERSON_ROLES_AUTHORIZED = "is_authorized_person",
  PERSON_DATA_VERIFICATION_METHOD = "verification_method",
  PERSON_DATA_RESIDENTIAL_COUNTRY = "country_of_residence",
  PERSON_DATA_ID_DOCUMENTS = "identification_document",
  PERSON_DATA_DOCUMENT_TYPE = "document_type",
  PERSON_DATA_NRIC_DOCUMENT_TYPE = "nric_document",
  PERSON_DATA_PASSPORT_DOCUMENT_TYPE = "passport_document",
  PERSON_DATA_WORK_PASS_DOCUMENT_TYPE = "work_pass_document",
  PERSON_DATA_DOCUMENT_NUMBER = "number",
  PERSON_DATA_DOCUMENT = "document",
  PERSON_DATA_DOCUMENT_BACK = "document_back",
  PERSON_DATA_DOCUMENT_EXPIRY = "expiry_date",
  PERSON_DATA_PROOF_OF_RESIDENCE_DOCUMENT = "proof_of_residence",
  PERSON_IS_RESIDENCE_ADDRESS_SAME = "is_same_residential_address",
  SHARE_HOLDER_DATA_ENITY_NAME = "entity_name",
  SHARE_HOLDER_DATA_ENITY_TYPE = "entity_type",
  SHARE_HOLDER_DATA_SHARE_ALLOCATION = "share_allocation",
  SHARE_HOLDER_DATA_SHARE_TYPE = "share_type",
  SHARE_HOLDER_DATA_UEN = "uen",
}

export enum PERSON_DOCUMENT_TYPE {
  NRIC = "NRIC",
  PASSPORT = "Passport",
  WORKPASS = "WorkPass",
}

export enum FORM_NAME {
  COMPANY_INFORMATION = "company_information",
  PERSONNEL_INFORMATION = "personnel_information",
  COMPANY_DOCUMENTS = "company_document",
}

export enum KYB_SOURCE_LIST {
  MANUAL = "Manual",
  MY_INFO = "MyInfo",
}

export * from "./CompanyInfo";
export * from "./PersonnelInfo";
