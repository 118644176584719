export const PAGE_SIZE = 100;
export const FIRST_PAGE = 0;
export const DEFAULT_PAGE = 0;
export const SCROLL_DELAY = 250;
export const BILL = "Bill";
export const FIND_TRANSACTION_TYPE_INSIDE_BRACKET = /\((.*?)\)/;
export const EXPENSE_NOT_FOUND = "expense not found!";
export const TAB_NAME = {
  PENDING_REVIEW: "Pending Review",
  PENDING_SYNC: "Pending Sync",
  COMPLETED: "Completed",
};
