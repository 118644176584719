export const SMART_CSV_LIMIT_TRANSACTION = 500;

export const CSV_EXPORT_WARNING = 1000;
export const CSV_EXPORT_LIMIT = 5000;

export const DOWNLOAD_SMART_CSV = "Download-Smart-CSV-Data";

export enum TRXNS_SMART_CSV {
  RAW_CSV = "RAW_CSV",
  CUSTOM_CSV = "CUSTOM_CSV",
  BANK_STATEMENT = "BANK_STATEMENT",
  DOWNLOAD_RECEIPT = "DOWNLOAD_RECEIPT",
}

export const TRXNS_SMART_CSV_ID = {
  [TRXNS_SMART_CSV.BANK_STATEMENT]: "TRXNS-SMART-CSV-1",
  [TRXNS_SMART_CSV.RAW_CSV]: "TRXNS-SMART-CSV-2",
  [TRXNS_SMART_CSV.CUSTOM_CSV]: "TRXNS-SMART-CSV-3",
  [TRXNS_SMART_CSV.DOWNLOAD_RECEIPT]: "TRXNS-DOWNLOAD-RECEIPT-4",
};
