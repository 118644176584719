export const SORT_OPTIONS = [
  { label: "Created (Newest to Oldest)", value: "created_at:desc" },
  { label: "Created (Oldest to Newest)", value: "created_at:asc" },
  { label: "Card Title (A-Z)", value: "name:asc" },
  { label: "Card Title (Z-A)", value: "name:desc" },
  { label: "Last Used", value: "last_transaction:desc" },
];
export const DEFAULT_SORT = ["created_at:desc"];

export const STATUS_OPTIONS = [
  { label: "Active", value: "active" },
  { label: "Inactive", value: "deactivated" },
];
export const STATUS_OPTIONS_NEW = [
  { label: "Enabled", value: "active" },
  { label: "Disabled", value: "deactivated" },
  { label: "Not Activated", value: "not activated" },
];
export const TYPE_OPTIONS = [
  { label: "Virtual", value: false },
  { label: "Physical", value: true },
];

export const getSortOptions = (isCardListingPage: boolean) => {
  const sortOptions = [
    { label: "Created (Newest to Oldest)", value: "created_at:desc" },
    { label: "Created (Oldest to Newest)", value: "created_at:asc" },
    { label: "Card Title (A-Z)", value: "name:asc" },
    { label: "Card Title (Z-A)", value: "name:desc" },
  ];
  if (isCardListingPage) {
    sortOptions.push({ label: "Last Used", value: "last_transaction:desc" });
  }
  return sortOptions;
};
