import { userInitialState } from "../InitialState";
import { FETCH_ORG_TEAM, FETCH_ORG_TEAM_SUCCESS, FETCH_ORG_TEAM_FAILURE } from "../Actions/orgTeamAction";

export const orgTeamReducer = (
  state = {
    ...userInitialState,
    orgTeam: null,
    loading: false,
  },
  action
) => {
  switch (action.type) {
    case FETCH_ORG_TEAM:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ORG_TEAM_SUCCESS:
      return {
        ...state,
        orgTeam: action.orgTeam,
        allOrgTeams: action.data,
        loading: false,
      };
    case FETCH_ORG_TEAM_FAILURE:
      return {
        ...state,
        orgTeam: action.orgTeam,
        loading: false,
      };
    default:
      return state;
  }
};
