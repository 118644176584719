import React, { useEffect, useState } from "react";
import SmartCSV from "Views/Transaction/SmartCSV/v1";
import { ITransactionFilter } from "../TransactionFilter";
import { EWorkflowApiStatus } from "../type";
import { isEqual } from "./helper";
import { getAccountingWorkflow } from "Redux/DataCalls/AccountingWorkflow.api";
import { buildApiParams, cleanFilter } from "Redux/Reducers/AccountingWorkflow/accountingWorkflow.slice";
import { thisMonthDateRange } from "Views/Transaction/SmartCSV/v2/utilities";
import { TRANSACTION_TYPES } from "Views/Transaction/Constants/";

import "./styles.module.scss";

interface IDownloadProps {
  onlyRawCsv?: boolean;
  allTransactionIds: string[];
  filter: ITransactionFilter;
  accountingStatus: EWorkflowApiStatus;
}

const Download = ({ onlyRawCsv = false, allTransactionIds, filter, accountingStatus }: IDownloadProps) => {
  const [trxnCount, setTrxnCount] = useState(0);

  const getTransactionsCount = async (filters) => {
    const params = buildApiParams(cleanFilter(filters), accountingStatus, { limit: 1 });
    const res = await getAccountingWorkflow(params);
    setTrxnCount(res?.data?.count || 0);
  };

  useEffect(() => {
    const isDateFilterApplied = filter.start_date && filter.end_date;
    const appliedFilters = isDateFilterApplied ? filter : { ...thisMonthDateRange(), ...filter };
    if (allTransactionIds && allTransactionIds?.[0] === "Mocked_transaction_0") return;
    getTransactionsCount(appliedFilters);
  }, [filter, allTransactionIds]);

  const addReportingFilter = (filter) => {
    return {
      ...filter,
      accounting_statuses: [accountingStatus],
      exclude_type: [TRANSACTION_TYPES.TRANSFER],
    };
  };

  return (
    <SmartCSV
      filters={addReportingFilter(filter)}
      onlyRawCsv={onlyRawCsv}
      isSideSectionOpen
      totalCount={allTransactionIds.length}
      allTransactionIds={allTransactionIds}
      view="accounting"
      accountingStatus={accountingStatus}
      trxnCount={trxnCount}
    />
  );
};

const MemoizedDownload = React.memo(Download, isEqual);

export default MemoizedDownload;
