import React from "react";

import classNames from "classnames";
import { Dropdown, Menu } from "antd";
import { Tags, Typography } from "@spenmo/splice";

import styles from "./index.module.scss";
import { PAYMENT_RUN_SORT_BY } from "Views/Bills/V2/PaymentRun/constant";
import { SortIdentifier } from "Views/Bills/V2/PaymentRun/types";

const Sort = ({
  sortValue,
  setSort,
  handleVisibleChange,
}: {
  sortValue: SortIdentifier;
  setSort: React.Dispatch<React.SetStateAction<SortIdentifier>>;
  handleVisibleChange: (visible: boolean) => void;
}) => {
  const sortValueLabel = `Sort by: ${
    sortValue ? PAYMENT_RUN_SORT_BY.find(({ id }) => id === sortValue)?.activeLabel : PAYMENT_RUN_SORT_BY[0].activeLabel
  }`;

  const menu = (
    <Menu onOpenChange={() => handleVisibleChange(false)} className={styles.dropdownMenu}>
      {PAYMENT_RUN_SORT_BY.map(({ id, title }) => {
        return (
          <Menu.Item
            key={id}
            className={classNames(styles.dropdownItem, {
              [styles.active]: sortValue ? id === sortValue : id === PAYMENT_RUN_SORT_BY[0].id,
            })}
            onClick={() => setSort(id)}
          >
            <Typography size="caption-m" variant="body-content">
              {title}
            </Typography>
          </Menu.Item>
        );
      })}
    </Menu>
  );

  return (
    <div className={styles.dropdown}>
      <Dropdown
        onVisibleChange={handleVisibleChange}
        overlay={menu}
        className={styles.dropdownContainer}
        trigger={["click"]}
        placement="bottomRight"
        getPopupContainer={(triggerNode) => triggerNode.parentElement}
      >
        <Tags.Label className={styles.sortBy} hierarchy="high" label={sortValueLabel} size="s" variant="info" />
      </Dropdown>
    </div>
  );
};

export default Sort;
