import * as React from "react";
import styles from "./index.module.scss";

interface ISpendingLockFooterProps {
  message: string;
  onClear: () => void;
}
const SpendingLockFooter = ({ message, onClear }: ISpendingLockFooterProps) => {
  return (
    <article className={styles.spendingLockFooter}>
      <p className={styles.spendingLockFooterText}>{message}</p>
      <button onClick={onClear} className={styles.spendingLockFooterButton}>
        Clear All
      </button>
    </article>
  );
};

export default SpendingLockFooter;
