import * as React from "react";
import { useSelector } from "react-redux";
import { Modal, Button } from "antd";
import { infoIcon } from "assets/img";
import { FLOW_CONDITIONS } from "../../../../constants";
import { conditionParser } from "../../../../helpers";
import "./ConflictModal.scss";
import { ConditionObject } from "Views/ApprovalFlow/types";

interface ConflictModalProps {
  show: boolean;
  setShow: (boolean) => void;
  title: string;
  conditions: object;
  conflictedData: Array<{
    name: string;
    conditions: ConditionObject;
  }>;
}

const ConflictModal: React.FC<ConflictModalProps> = ({
  show,
  setShow,
  title,
  conditions,
  conflictedData,
}: ConflictModalProps) => {
  const currencyCode = useSelector((state: any) => state.wallet?.data?.currency_code);
  return (
    <Modal
      className="approval-flow__conflict-modal no-animation"
      visible={show}
      onCancel={() => setShow(false)}
      title="Overlapping Approval Condition"
      footer={null}
    >
      <div>
        <div className="conflict-modal__desc">
          <img className="desc__icon" src={infoIcon} />
          <p className="desc__text">
            This Approval Flow shares similar condition(s) with other Approval Flows. Please create different conditions
            by editing the Approval Flow below.
          </p>
        </div>
        <div className="conflict-modal__content">
          <div className="conflict-modal__current-flow">
            <h3>Current flow:</h3>
            <div className="current-flow__container">
              <div className="current-flow__title">{title}</div>
              <ul className="current-flow__conditions">
                {Object.keys(FLOW_CONDITIONS).map((item) => (
                  <li key={item} className="current-flow__conditions__item">
                    {conditionParser(item, conditions[item], currencyCode)}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="conflict-modal__conflicting-flow">
            <h3>Conflicting with:</h3>
            <ul className="conflicting-flow__list">
              {conflictedData.map((data) => (
                <li className="conflicting-flow__list-item">
                  <div className="conflicting-flow__title">{data?.name}</div>
                  <ul className="conflicting-flow__conditions">
                    {Object.keys(data?.conditions || {}).map((item) => {
                      const conditionText = conditionParser(item, data?.conditions[item], currencyCode);
                      return conditionText ? (
                        <li className="conflicting-flow__conditions__item">{conditionText}</li>
                      ) : null;
                    })}
                  </ul>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <Button className="conlict-modal__edit-flow-button" onClick={() => setShow(false)}>
          Edit the current flow
        </Button>
      </div>
    </Modal>
  );
};

export default ConflictModal;
