import * as React from "react";
import { toOrdinal } from "utility/index";

import "./ApprovalListItem.scss";
import { ApproverObject } from "../../types";
interface ApproverItemProps {
  index: number;
  approvalFlowId: string;
  assignees: Array<ApproverObject>;
}

const ApproverItem: React.FC<ApproverItemProps> = ({
  index,
  assignees,
  approvalFlowId,
}: ApproverItemProps): JSX.Element => {
  const isInactive = (item) => item?.assignee?.isDeleted || item?.assignee?.isInvalidRole;
  const inactive = assignees.filter(isInactive);
  const active = assignees.filter((item) => !isInactive(item));
  const approverList = [...inactive, ...active];
  return (
    <li className="approver__item" key={approvalFlowId}>
      <span className="approver__item__ordinal">{toOrdinal(index + 1)}</span>
      <span className="approver__item__name">
        {approverList.map((item, idx) => (
          <span key={approvalFlowId + item.identifier}>
            <span className={`${isInactive(item) ? "flow-approver__inactive" : ""}`}>
              {item?.role === "admin" ? "Any Admin" : item?.assignee?.name}
              {item.assignee.isDeleted ? " (inactive)" : ""}
            </span>
            {idx < assignees.length - 1 ? <span>, or&nbsp;</span> : ""}
          </span>
        ))}
      </span>
    </li>
  );
};

export default ApproverItem;
