import { useDispatch, useSelector } from "react-redux";
import { useMemo, useEffect, useState, useCallback } from "react";
import { isEmptyVal } from "Views/Card/List/Filters/Utilities";
import { DEFAULT_SORT } from "Views/Card/List/Filters/Constants";
import { getCookieValue, cookieNames } from "utility/CookieHelper";
import { getKycStatus } from "utility/Card";
import { debounce, GetOrgId } from "utility";
import { CARD_USER_VENDOR, KYC_STATUS } from "Views/Card/types";
import { accountingPartnerAuth } from "Redux/Actions/accountingPartnerAuth";
import { getMerchantCategories } from "Redux/DataCalls/Card/CategoryLock.api";

export const useIsManagerOrAdmin = () => {
  const { manage_teams = [], is_admin } = useSelector((state) => state?.userInfoReducer?.data?.payload?.user || {});
  return [is_admin, Array.isArray(manage_teams) && manage_teams?.length > 0];
};

export const useCanUserEditCards = (teamid) => {
  const { manage_teams = [], is_admin } = useSelector((state) => state?.userInfoReducer?.data?.payload?.user || {});
  return is_admin || (Array.isArray(manage_teams) && manage_teams?.includes(teamid));
};

export const useTransactionTags = () => {
  const accountingPartnerAuthData = useSelector((state) => state.accountingPartnerAuthReducer?.data);
  const hasValidToken = accountingPartnerAuthData?.payload?.has_valid_token;
  const dispatch = useDispatch();
  useEffect(() => {
    if (!hasValidToken) {
      dispatch(accountingPartnerAuth());
    }
  }, [dispatch]);

  return hasValidToken;
};

export const useShowClearAll = ({ currentFilters, sortBy }) => {
  return useMemo(() => {
    if (Object.keys(currentFilters).length === 0) return false;
    if (Object.keys(currentFilters.ordering || {}).length > 0) {
      if (sortBy === DEFAULT_SORT[0]) delete currentFilters.ordering;
    }
    return isEmptyVal(currentFilters);
  }, [currentFilters, sortBy]);
};

export const getIsCardVendorNium = () => {
  return getCookieValue(cookieNames.USER_CARD_VENDOR) === CARD_USER_VENDOR.NIUM;
};

export const useCategoryLocks = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [initialData, setInitialData] = useState(null);

  const getCategories = (queryParams = {}) => {
    getMerchantCategories({ ...queryParams, organisation_id: GetOrgId() })
      .then((response) => {
        const { payload = null } = response?.data || {};
        setData(payload);
        !initialData && setInitialData(payload);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    getCategories();
  }, []);
  const DEBOUNCE_DELAY_TIME = 1000;
  const onSearch = useCallback(
    debounce((searchString) => {
      if (searchString) {
        setLoading(true);
        getCategories({ q: searchString });
      } else {
        setData(initialData);
      }
    }, DEBOUNCE_DELAY_TIME),
    [initialData]
  );

  return { data, error, loading, onSearch };
};
export const useIsLoggedInUserUnverified = () => {
  const { data: userData = {}, loading = false } = useSelector((state) => state?.userInfoReducer || {});
  const kycStatus = getKycStatus(userData?.payload?.user?.kyc || "");
  return !loading && kycStatus !== KYC_STATUS.APPROVED;
};

export const getIsCardVendroMatchMove = () => {
  return getCookieValue(cookieNames.USER_CARD_VENDOR) === CARD_USER_VENDOR.MATCHMOVE;
};

export const getIsLimitModelOrg = () => getIsCardVendorNium();

export const useCardDoesNotBelongToUser = (cardHolderUserId) => {
  const loggedInUserId = useSelector((state) => state?.userInfoReducer?.data?.payload?.user?.id || "");
  return cardHolderUserId !== loggedInUserId;
};
