import React from "react";
import { useHistory, useLocation } from "react-router-dom";

import { Switch, Typography } from "@spenmo/splice";

import styles from "./index.module.scss";
import { SUBMITTED_BILLS_VIEW } from "Views/Bills/V2/constants";

interface ToggleViewProps {
  currentView: SUBMITTED_BILLS_VIEW;
  nextView: SUBMITTED_BILLS_VIEW;
}
const ToggleView: React.FC<ToggleViewProps> = ({ currentView, nextView }) => {
  const location = useLocation();
  const history = useHistory();
  return (
    <>
      <Typography className={styles.container} variant="body-content" size="s">
        Payment run view
      </Typography>
      <Switch
        isActive={currentView === SUBMITTED_BILLS_VIEW.PAYMENT_RUN}
        onClick={() => history.push(nextView, location.state)}
        size="m"
      />
    </>
  );
};

export default ToggleView;
