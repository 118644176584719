import React, { ReactElement } from "react";

import "./styles.scss";
import { AuthSidebar } from "..";

export const AuthContainer = ({ children }: { children: ReactElement }): ReactElement => {
  return (
    <section className={`auth-container`}>
      <AuthSidebar />
      {children}
    </section>
  );
};
