import { categoryListReducer } from "./list";
import { categoryEditReducer } from "./edit";
import { categoryDeleteReducer } from "./delete";
import { categoryCreateReducer } from "./create";
import { combineReducers } from "redux";

export const categoryReducer = combineReducers({
  list: categoryListReducer,
  edit: categoryEditReducer,
  create: categoryCreateReducer,
  delete: categoryDeleteReducer,
});
