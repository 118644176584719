import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Steps } from "antd";
import { useDispatch, useSelector } from "react-redux";
import ModalWrapper, { ModalComponent } from "Modules/DS/Modal";
import Button from "/Modules/button";
import Toaster from "Modules/DS/Toaster";
import SelectTeamStep from "../SelectTeamStep";
import MoveTeamConfirmStep from "../MoveTeamConfirmStep";
import MoveTeamWarning from "../MoveTeamWarning";
import MoveTeamLoading from "../MoveTeamLoading";

import { greyCloseIcon, errorIcon } from "assets/img";

import MoveTeamMemberContext from "./context";

import { teamEmpFunc, getTeamsByUserId } from "Redux/Actions";

import { moveTeamMember, resetMoveTeamMember, removeTeamMember } from "Redux/Actions/Teams/moveTeamMemberAction";
import { IMoveTeamMemberModal, ITeamDetails } from "../types";
import { IToasterProps, TOASTER_SIZE_TYPE, TOASTER_STATUS_TYPE } from "Modules/DS/Toaster/types.d";
import { INITIAL_STEP, initTeamDetail, MOVE_TEAM_MEMBER_STEP, VALIDATE_CONFIRM_MOVE_MEMBER_TEXT } from "../../constant";
import { USER_ROLE } from "constants/Team.constant";

import { trackEvent } from "utility/analytics";
import { MoveTeamMemberEvent } from "Views/Team/Analytics";

import RemoveTeamWarning from "Views/Team/Profile/RemoveTeamWarning";
import RemoveTeamAllow from "Views/Team/Profile/RemoveTeamAllow";
import { fetchUserCardsListForTeamRemoval } from "Redux/Actions/Cards/List";

import "./index.scss";

const { CloseButton, Title } = ModalComponent;
const { Step } = Steps;

const moveTeamMemberSteps = [
  {
    title: MOVE_TEAM_MEMBER_STEP.MOVE_TO_TEAM,
    render: () => <SelectTeamStep />,
    validateButton: ({ teamId }) => !!teamId,
  },
  {
    title: MOVE_TEAM_MEMBER_STEP.CONFIRM,
    render: () => <MoveTeamConfirmStep />,
    validateButton: ({ validateText }) => validateText === VALIDATE_CONFIRM_MOVE_MEMBER_TEXT,
  },
];

const MoveTeamMemberModal = ({ visible, close, user = {}, teamDetails = {}, process }: IMoveTeamMemberModal) => {
  const dispatch = useDispatch();
  const employeeName = user?.firstName + " " + user?.lastName;
  const { loading: teamListLoading, data } = useSelector((state: any) => state?.empReducer || {});
  const { data: teamData } = useSelector((state: any) => state.teamList || {});
  const {
    moveTeamMemberLoading,
    moveTeamMemberSuccess,
    moveTeamMemberFailed,
    userTeamLoading,
    userTeams: totalUserTeams,
  } = useSelector((state: any) => state?.teamReducer || {});

  const {
    loading: cardDataLoading,
    data: cardData,
    error: cardDataError,
  } = useSelector((state: any) => state?.cardsListReducer || {});
  const cardList = cardData?.payload?.cards;
  const totalTeams = teamData?.payload?.teams || [];
  const userTeams = totalUserTeams?.payload?.teams || [];
  const teamList = data?.payload?.team_members || [];
  const [step, setStep] = useState(INITIAL_STEP);
  const [destinationTeam, setDestinationTeam] = useState<ITeamDetails>(initTeamDetail);
  const [validateText, setValidateText] = useState("");
  const [toaster, setToaster] = useState<IToasterProps>({
    visible: false,
    message: "",
  });

  useEffect(() => {
    if (visible) {
      dispatch(teamEmpFunc(teamDetails.id, false, false, true));
      dispatch(getTeamsByUserId(user.id));
      if (process && process === "remove") {
        dispatch(
          fetchUserCardsListForTeamRemoval({
            cardholder_name: [employeeName],
            team_id: [teamDetails.id],
          })
        );
      }
    }
  }, [dispatch, teamDetails.id, visible, user.id, process]);

  useEffect(() => {
    if (moveTeamMemberSuccess) {
      let message = "";
      if (process && process === "move") {
        message = `${employeeName} is successfully moved to ${destinationTeam?.team_name} budget.`;
      }
      if (process && process === "remove") {
        message = `${employeeName} is successfully removed from ${teamDetails?.name} budget.`;
      }
      setToaster({
        visible: true,
        message,
      });
      handleClose();
    }
  }, [moveTeamMemberSuccess]);

  const disabledButton = useMemo(() => {
    const validateFn = moveTeamMemberSteps[step]?.validateButton;
    return !(validateFn && validateFn({ teamId: destinationTeam.id, validateText }));
  }, [step, destinationTeam, validateText]);

  const stepComponent = useMemo(() => {
    const renderComp = moveTeamMemberSteps[step]?.render;
    return renderComp && renderComp();
  }, [step]);

  const showGoBackBtn = useMemo(() => step > 0, [step]);
  const confirmBtn = useMemo(() => step === moveTeamMemberSteps.length - 1, [step]);

  const handleMoveTeamMember = useCallback(() => {
    dispatch(
      moveTeamMember({
        teamId: teamDetails.id,
        userId: user.id,
        newTeamId: destinationTeam.id,
      })
    );
    trackEvent(MoveTeamMemberEvent.CONFIRM_MOVE_TO_TEAM, { "moved user id": user.id });
  }, [destinationTeam.id, dispatch, teamDetails.id, user.id]);

  const handleClose = useCallback(() => {
    if (process && process === "move") {
      setStep(INITIAL_STEP);
      setDestinationTeam(initTeamDetail);
    }
    dispatch(resetMoveTeamMember());
    close();
  }, [close, dispatch]);

  const goNext = useCallback(() => {
    if (confirmBtn) {
      handleMoveTeamMember();
      return;
    }
    setStep((prevStep) => Math.min(prevStep + 1, moveTeamMemberSteps.length - 1));
  }, [confirmBtn, handleMoveTeamMember]);

  const goBack = () => {
    setStep((prevStep) => Math.max(prevStep - 1, 0));
  };

  const confirmRemove = useCallback(() => {
    dispatch(
      removeTeamMember({
        teamId: teamDetails.id,
        userId: user.id,
      })
    );
  }, [dispatch, teamDetails.id, user.id]);

  const allowMoveTeamMemberModal = (process) => {
    if (process === "remove") {
      const cardsListForCurrentUser = cardList.filter((el) => el.user_id === user.id);
      if (cardDataError || cardsListForCurrentUser.length > 0 || userTeams.length <= 1) {
        return <RemoveTeamWarning teamList={teamList} cardList={cardList} userTeams={userTeams} user={user} />;
      }
      return <RemoveTeamAllow confirmRemove={confirmRemove} />;
    }
    if (process === "move") {
      return (
        <>
          <div>
            <CloseButton src={greyCloseIcon} />
          </div>
          <Title>{`Before moving ${employeeName}, please do these steps:`}</Title>

          <Steps className={"move-team-member__steps"} labelPlacement="vertical" current={step}>
            {moveTeamMemberSteps.map(({ title }) => (
              <Step key={title} title={title} />
            ))}
          </Steps>

          {stepComponent}

          <div className={"move-team-member__footer"}>
            {showGoBackBtn && <Button text="Back" rounded textButton btnStyle="go-back" action={goBack} />}
            <Button
              text={confirmBtn ? "Confirm" : "Continue"}
              rounded
              disabled={disabledButton}
              action={goNext}
              testid="move-team-member-continue-btn"
            />
          </div>
        </>
      );
    }
  };

  const warningMoveTeamMemberModal = (process) => {
    if (process === "move") {
      return <MoveTeamWarning />;
    }

    if (process === "remove") {
      return <RemoveTeamWarning teamList={teamList} cardList={cardList} userTeams={userTeams} user={user} />;
    }
  };

  const moveTeamFailedModal = (title, text) => {
    return (
      <>
        <div>
          <CloseButton src={greyCloseIcon} />
        </div>
        <Title>{title}</Title>
        <div className="move-team-member__move-action-error-content">
          <img src={errorIcon} alt="error icon" className="move-team-member__move-action-error-icon" />
          <p className="move-team-member__move-action-error-text">
            {text} <br /> Please try again in a few moments.
          </p>
        </div>
      </>
    );
  };

  const modalContent = useMemo(() => {
    if (teamListLoading || userTeamLoading || cardDataLoading) {
      return (
        <MoveTeamLoading
          title="Checking eligibility..."
          subTitle={`Please wait while we check for pending items from ${employeeName}`}
        />
      );
    }

    if (moveTeamMemberLoading) {
      let title = "";
      let subtitle = "";
      if (process && process === "move") {
        title = "Move in progress";
        subtitle = `Please wait while we are moving ${employeeName} to the new budget`;
      }
      if (process && process === "remove") {
        title = "Removal in progress";
        subtitle = `Please wait while we are removing ${employeeName} from this budget`;
      }
      return <MoveTeamLoading title={title} subTitle={subtitle} />;
    }

    const checkUnavailableTeams =
      totalTeams.length <= userTeams.length ||
      totalTeams.every((totalTeam) => userTeams.find((userTeam) => userTeam.team_id === totalTeam.id));
    let title = "";
    let text = "";
    if (process && process === "move") {
      title = `Failed to move ${employeeName}`;
      text = `An error occurred when moving ${employeeName} to another budget.`;
      if (moveTeamMemberFailed || checkUnavailableTeams) {
        return moveTeamFailedModal(title, text);
      }
    }
    if (process && process === "remove") {
      title = `Failed to remove ${employeeName}`;
      text = `An error occurred while removing ${employeeName}.`;
      if (moveTeamMemberFailed) {
        return moveTeamFailedModal(title, text);
      }
    }

    const managerList = teamList.filter((member) => member.role === USER_ROLE.MANAGER);
    if (managerList.length === 0) {
      return warningMoveTeamMemberModal(process);
    }

    if (managerList.length === 1) {
      const foundMember = managerList.find((member) => member.id === user?.id);
      if (foundMember) {
        return warningMoveTeamMemberModal(process);
      } else {
        return allowMoveTeamMemberModal(process);
      }
    } else if (managerList.length > 1) {
      return allowMoveTeamMemberModal(process);
    }
  }, [
    teamListLoading,
    userTeamLoading,
    teamList,
    totalTeams,
    userTeams,
    moveTeamMemberLoading,
    moveTeamMemberFailed,
    employeeName,
    step,
    stepComponent,
    showGoBackBtn,
    confirmBtn,
    disabledButton,
    goNext,
    user,
    confirmRemove,
    cardDataLoading,
  ]);

  const contextValue = {
    employeeName,
    teamDetails,
    destinationTeam,
    validateText,
    user,
    userTeams,
    setDestinationTeam,
    setValidateText,
    handleClose,
  };

  return (
    <MoveTeamMemberContext.Provider value={contextValue}>
      <div data-testid="move-team-member" className="move-team-member">
        <ModalWrapper className="move-team-member__modal" visible={visible} close={handleClose}>
          {modalContent}
        </ModalWrapper>
      </div>
      <Toaster
        size={TOASTER_SIZE_TYPE.S}
        visible={toaster.visible}
        status={TOASTER_STATUS_TYPE.SUCCESS}
        message={toaster.message}
        onClose={() => setToaster({ ...toaster, visible: false })}
      />
    </MoveTeamMemberContext.Provider>
  );
};

export default MoveTeamMemberModal;
