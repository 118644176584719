import React from "react";
import classNames from "classnames";
import { RenderArrowProps } from "react-elastic-carousel";

import { CoreIcon } from "assets/v1.1/icons";
import styles from "./attachment.module.scss";
import "./carousel.scss";

export const SliderArrows = ({ type, onClick, isEdge }: RenderArrowProps) => {
  const altString = type === "NEXT" ? "left arrow" : "right arrow";

  return (
    <button
      className={classNames(styles.arrows, { [styles.disabled]: isEdge })}
      onClick={(event) => {
        event.stopPropagation();
        !isEdge && onClick();
      }}
    >
      <img
        src={CoreIcon.Core.ChevronDown.Line[24]}
        className={classNames(
          { [styles.prev]: type === "PREV" },
          { [styles.next]: type === "NEXT" },
          { [styles.edge]: isEdge }
        )}
        alt={altString}
      />
    </button>
  );
};
