import APIclient from "API/Client";
import { GetOrgId } from "utility";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";

export enum FETCH_CARD_CVV_ACTIONS {
  FETCH_CARD_CVV_REQUEST = "FETCH_CARD_CVV_REQUEST",
  FETCH_CARD_CVV_SUCCESS = "FETCH_CARD_CVV_SUCCESS",
  FETCH_CARD_CVV_FAILURE = "FETCH_CARD_CVV_FAILURE",
}

export interface ICardCvvData {
  cardCvv: string;
  statusMessage: string;
}

export const getDispatchObject = (action: FETCH_CARD_CVV_ACTIONS, data = {}) => ({
  type: action,
  data: { ...data },
});

export const clearCardCvv = () => {
  return (dispatch) => {
    dispatch(getDispatchObject(FETCH_CARD_CVV_ACTIONS.FETCH_CARD_CVV_SUCCESS));
  };
};

const processCardCvvData = (data) => {
  const { cvv = "", status_message = "Please try again!" } = data?.payload || {};
  return {
    cardCvv: cvv,
    statusMessage: status_message,
  };
};

export const fetchCardCvv = (card_id: string) => {
  return (dispatch) => {
    dispatch(getDispatchObject(FETCH_CARD_CVV_ACTIONS.FETCH_CARD_CVV_REQUEST));
    APIclient.getData(`/api/v1/org/card/${card_id}/cvv/?organisation_id=${GetOrgId()}`)
      .then((response) => {
        const { data = {} }: any = response || {};
        if (data?.status === HTTP_STATUS_CODE.OK) {
          dispatch(getDispatchObject(FETCH_CARD_CVV_ACTIONS.FETCH_CARD_CVV_SUCCESS, processCardCvvData(data)));
        } else {
          dispatch(getDispatchObject(FETCH_CARD_CVV_ACTIONS.FETCH_CARD_CVV_FAILURE, processCardCvvData(data)));
        }
      })
      .catch((error) => {
        dispatch(
          getDispatchObject(FETCH_CARD_CVV_ACTIONS.FETCH_CARD_CVV_FAILURE, processCardCvvData(error?.response?.data))
        );
      });
  };
};
