import React, { useEffect, useState } from "react";
import { Cookies } from "react-cookie";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getPaginatedTeamList, updateTeamName } from "Redux/Actions/Teams/paginatedTeamListAction";
import { BUDGET_EVENTS } from "Views/Team/Analytics";
import { trackEvent } from "utility/analytics";
import useUrlParams from "utility/useUrlParams";
import { Typography } from "@spenmo/splice";

import Button from "Modules/button";

import { GetOrgId, isValidArray, multiArrayValidation } from "utility";

import { USER_ROLE } from "constants/Team.constant";
import { CREATE_TEAM_PATH, TEAM_PROFILE_PATH, TEAMS_URL_PARAMS } from "constants/Routes.constants";
import "./TeamList.scss";

import usePermissionCheck from "Permission/usePermissionCheck";
import { CASBIN_PAGES } from "Permission/Pages";
import { getIsCardMatchMove } from "Views/Home/Utilites";
import { TEAMS_PAGE } from "Permission/Actions";
import { useDeleteTeamContext } from "Views/Team/Delete/context";
import { useTeamActionContext } from "Views/Team/List/Actions/context";
import { useIsSaasWithPaymentsOrg } from "customHooks/useIsSaasWithPaymentsOrg";
import {
  TEAM_ACTION_STATUS,
  TABS,
  TABS_KEYS,
  ADDITIONAL_INFO_TEXT,
  FUND_TRANSFERS_SUB_TABS,
  FUND_TRANSFER_FILTERS_NAMES,
  TABS_TITLES,
} from "Views/Team/List/Actions/constant";
import DataTable, {
  Table,
  Header,
  DATA_TABLE_SIZE_TYPES,
  BulkActionType,
  IBulkActionProps,
} from "Modules/DS/DataTable";
import Tabs, { ListData } from "Modules/DS/Tabs";
import {
  AllBudgetColumnsDataMember,
  AllBudgetColumnsData,
  AllBudgetColumnActions,
  scheduledColumnData,
  RequestFundsColumns,
  requestedFundTransferDetails,
  sentFundTransferDetails,
  SentFundsColumns,
  setCreatedAtDate,
} from "./Columns";
import {
  stopScheduledTransactionsFunc,
  clearStopScheduledTransactions,
  getScheduledTransactionsFunc,
  clearEditScheduledTransaction,
  editScheduledTransactionFunc,
  deleteTeamPendingFundTransfer,
  clearDeleteFundData,
  clearCsvData,
} from "Redux/Actions";
import { fetchPermissionForClassComp } from "Permission/PermissionForClassComp";
import { TRANSACTION_LISTING_PERMISSION_PARAMS } from "Views/Transaction/Permission";
import { shouldDisableCurrentRowSelection } from "../helper";
import ScheduledDetails from "Views/TransactionsListing/ScheduledDetails";
import SideSection from "Modules/sideSection";
import SendMoneyModal from "Views/SendMoney/SendMoneyModal";
import { useTeamToasterContext } from "Views/Team/Toast/context";
import { TOASTER_STATUS_TYPE } from "Modules/DS/Toaster/types.d";
import { FundTransferSubTabs } from "./FundTransfers";
import { GetAllApprovals, getSentFunds } from "Redux/DataCalls/Approvals.api";
import { ESidePanelButtonType, SidePanel } from "Modules/DS/SidePanel";
import { Section, TransactionDetail, TransactionHeader, TransactionStatus } from "Modules/TransactionDetail";
import { Form } from "antd";
import { ISidePanelDetailsScheduleTransfer, IRequestedFundTransferListItem, ISentFundsListItem } from "Views/Team/type";
import FundTransferActions from "./FundTransfers/Action";
import { BasicInfo } from "Views/Transaction/All/Info";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";

const TeamsListing = () => {
  const cookies = new Cookies();
  const currencyCode = cookies.get("currency_code") || "";
  const [form] = Form.useForm();

  const permissionParam = [{ object: CASBIN_PAGES.TEAMS_PAGE, action: TEAMS_PAGE.CREATE_NEW_TEAM_BUTTON }];
  const IS_ALLOWED_CREATE_NEW_TEAM_BUTTON = usePermissionCheck(permissionParam);
  const isMatchMove = getIsCardMatchMove();

  let history = useHistory();
  const dispatch = useDispatch();
  const { setToaster } = useTeamToasterContext();

  const searchParams = useUrlParams();
  const filterTeamsWithSoleManager = !!searchParams && searchParams[TEAMS_URL_PARAMS.SOLE_MANAGER] === "true";

  const [currentPage, setCurrentPage] = useState(0);
  const [selectedRequests, setSelectedRequests] = useState([]);
  const [requestedFundsList, setRequestedFundsList] = useState([] as IRequestedFundTransferListItem[]);
  const [requestedFundsCount, setRequestedFundsCount] = useState(0 as number);
  const [sentFundsList, setSentFundsList] = useState([] as ISentFundsListItem[]);
  const [sentFundsCount, setSentFundsCount] = useState(0 as number);
  const [sentFundsCurrentPage, setSentFundsCurrentPage] = useState(0);
  const [requestedFundTransferApiLoading, setRequestedFundTransferApiLoading] = useState(false as boolean);
  const [sentFundTransferApiLoading, setSentFundTransferApiLoading] = useState(false as boolean);
  const userData = useSelector((state: any) => state?.userInfoReducer);
  const { loading: scheduleLoading, data: scheduleTransactions } = useSelector(
    (state: any) => state?.getScheduledTransactionsReducer
  );
  const { data: stopScheduledTransactionsData } = useSelector((state: any) => state?.stopScheduledTransactionsReducer);
  const { data: editScheduledTransactionsData } = useSelector((state: any) => state?.editScheduledTransactionReducer);
  const { data: deleteFundTransferData } = useSelector((state: any) => state?.reimburseReducer);
  const { data: csvReducerData } = useSelector((state: any) => state?.csvReducer);

  const { loading = false, data } = useSelector((state: any) => state?.paginatedTeamList) || {};
  const teamId: string = useSelector((state: any) => state.teamReducer?.selectedTeamId);

  const teamData = data?.payload?.teams || [];

  const allBudgetsTotalCount = data?.payload?.total_count || 0;
  const scheduleTransactionsData = scheduleTransactions?.payload?.data || [];
  const scheduleTransactionsCount = scheduleTransactions?.payload?.total_count;
  const [activeKey, setActiveKey] = useState(TABS_KEYS.ALL);

  const [activeFundTransferSubTab, setActiveFundTransferSubTab] = useState(
    FUND_TRANSFERS_SUB_TABS.REQUESTED_FUNDS as FUND_TRANSFERS_SUB_TABS
  );

  const [isAllowedEditBtn, setIsAllowedEditButton] = useState(false);
  const [isAllowedStopBtn, setIsAllowedStopButton] = useState(false);
  const [isConfirmBulkStop, setIsConfirmBulkStop] = useState(false);

  const [scheduledEditValue, setScheduledEditValue] = useState(null);
  const [sidePanelDetailsSchedule, setSidePanelDetailsSchedule] = useState<ISidePanelDetailsScheduleTransfer>(null);
  const [sidePanelDetailsRequestedFund, setSidePanelDetailsRequestedFund] =
    useState<IRequestedFundTransferListItem>(null);
  const [sidePanelDetailsSentFund, setSidePanelDetailsSentFund] = useState<ISentFundsListItem>(null);
  const [showSidePanel, setShowSidePanel] = useState(false);
  const getTotalAmount: number = multiArrayValidation(scheduleTransactionsData, selectedRequests)
    ? scheduleTransactionsData
        .filter((trans) => selectedRequests?.some((sel) => sel?.id === trans?.id))
        .reduce((prev, curr) => prev + curr.amount, 0)
    : 0;

  const TABLE_ROW_LIMIT = 10;

  const { loading: deleteLoading } = useDeleteTeamContext();
  const {
    state: { event, filter },
    clearEvent,
  } = useTeamActionContext();

  useEffect(() => {
    if (event.status === TEAM_ACTION_STATUS.COMPLETED) {
      dispatch(updateTeamName(event.data));
      clearEvent();
    }
  }, [event.status]);

  // getRequestedFundTransferData is for => api call for get all fund transfer data with page controls
  const getRequestedFundTransferData = (page: number, filter = {}) => {
    setRequestedFundTransferApiLoading(true);
    const includeMyRequests = true;
    GetAllApprovals(
      GetOrgId(),
      teamId,
      "all",
      page,
      TABLE_ROW_LIMIT,
      "",
      { type: "topup", ...filter },
      includeMyRequests
    ).then((data) => {
      const { requests = [], counts = {} } = data?.payload || [];
      setRequestedFundsCount(counts?.all || 0);
      setRequestedFundsList(requests);
      setRequestedFundTransferApiLoading(false);
    });
  };

  const serializeFilterObject = (filter) => {
    const searchParams = new URLSearchParams(filter);
    if (searchParams.has("start_date")) {
      searchParams.set("start_date", new Date(searchParams.get("start_date")).toISOString());
    }
    if (searchParams.has("end_date")) {
      searchParams.set("end_date", new Date(searchParams.get("end_date")).toISOString());
    }
    return searchParams.toString();
  };

  const getSentFundTransferData = (page: number, filter = {}) => {
    setSentFundsCurrentPage(page);
    setSentFundTransferApiLoading(true);
    let query = `?limit=${TABLE_ROW_LIMIT}`;
    if (Object.keys(filter).length > 0) {
      query += `&${serializeFilterObject(filter)}`;
    }
    if (page > sentFundsCurrentPage) {
      const sort =
        sentFundsList[sentFundsList.length - 1]?.search?.next || sentFundsList[sentFundsList.length - 1]?.sort;
      const searchAfter = isValidArray(sort) ? [...sort].join(",") : "";
      query += `&search_after=${searchAfter}`;
    } else if (page !== 0 && page < sentFundsCurrentPage) {
      const sort = sentFundsList[0]?.search?.previous || sentFundsList[0]?.sort;
      const searchAfter = isValidArray(sort) ? [...sort].join(",") : "";
      query += `&search_after=${searchAfter}&previous_page=true`;
    }

    getSentFunds(query).then((data) => {
      const { data: list = [], count } = data?.payload || {};
      setSentFundsList(list);
      setSentFundsCount(count);
      setSentFundTransferApiLoading(false);
    });
  };

  const isPaymentEnabled = useIsSaasWithPaymentsOrg();

  useEffect(() => {
    if (activeKey === TABS_KEYS.SCHEDULED) {
      dispatch(getScheduledTransactionsFunc({ page: 0, limit: 10 }));
    } else if (activeKey === TABS_KEYS.ALL) {
      fetchBudgetData();
    } else if (activeKey === TABS_KEYS.FUND_TRANSFERS) {
      const dateFilterValues = filter ? filter[FUND_TRANSFER_FILTERS_NAMES?.CREATED_DATE] : {};
      const amountFilterValues = filter ? filter[FUND_TRANSFER_FILTERS_NAMES?.AMOUNT] : {};
      if (activeFundTransferSubTab === FUND_TRANSFERS_SUB_TABS.REQUESTED_FUNDS) {
        getRequestedFundTransferData(0, { ...dateFilterValues, ...amountFilterValues });
      } else if (activeFundTransferSubTab === FUND_TRANSFERS_SUB_TABS.SENT_FUNDS) {
        getSentFundTransferData(0, { ...dateFilterValues, ...amountFilterValues });
      }
    }
  }, [
    activeKey,
    dispatch,
    stopScheduledTransactionsData,
    editScheduledTransactionsData,
    activeFundTransferSubTab,
    deleteFundTransferData,
    filter,
  ]);

  useEffect(() => {
    setCurrentPage(0); // reset pagination whenever new filter is applied or cleared
  }, [filter]);

  const handleBudgetEvents = (event: BUDGET_EVENTS, eventSource: TABS_TITLES | "") => {
    trackEvent(event, {
      budget_page_event_source: eventSource,
    });
  };

  const fetchBudgetData = () => {
    let params = [];
    if (filterTeamsWithSoleManager) {
      params = [...params, true, searchParams[TEAMS_URL_PARAMS.MEMBER_ID]];
    }
    dispatch(getPaginatedTeamList(0, 10, ...params));
    handleBudgetEvents(BUDGET_EVENTS.BUDGET_PAGE_LOADED, TABS_TITLES.ALL_BUDGETS);
  };

  useEffect(() => {
    if (stopScheduledTransactionsData?.payload) {
      handleToasterDisplayForStopEdit(stopScheduledTransactionsData?.payload?.isSuccess, "Successfully Stopped");
      dispatch(clearStopScheduledTransactions());
    }
    if (editScheduledTransactionsData?.payload) {
      handleToasterDisplayForStopEdit(editScheduledTransactionsData?.payload?.isSuccess, "Successfully Edited");
      dispatch(clearEditScheduledTransaction());
    }
  }, [stopScheduledTransactionsData, editScheduledTransactionsData]);

  useEffect(() => {
    if (deleteFundTransferData?.payload) {
      handleToasterDisplayForStopEdit(Boolean(deleteFundTransferData?.payload?.status_message), "Successfully Deleted");
      dispatch(clearDeleteFundData());
    }
  }, [deleteFundTransferData]);

  useEffect(() => {
    if (csvReducerData.status_message) {
      handleToasterDisplayForStopEdit(
        csvReducerData.status_message === "Download successful!",
        csvReducerData.status_message
      );
    }
    if (csvReducerData?.response?.status === HTTP_STATUS_CODE.BAD_REQUEST) {
      handleToasterDisplayForStopEdit(true, csvReducerData?.response?.data?.error, false);
    }
    dispatch(clearCsvData());
  }, [csvReducerData]);

  useEffect(() => {
    fetchPermissionForClassComp(TRANSACTION_LISTING_PERMISSION_PARAMS).then((res) => {
      setIsAllowedEditButton(res[1]);
      setIsAllowedStopButton(res[2]);
    });
  }, []);

  const onAddTeamClickHandler = () => {
    history.push(CREATE_TEAM_PATH);
    handleBudgetEvents(BUDGET_EVENTS.BUDGET_CREATION_BUTTON_CLICKED, TABS_TITLES.ALL_BUDGETS);
  };

  const handleRowClick = (record) => {
    if (activeKey === TABS_KEYS.ALL) {
      history.push(`${TEAM_PROFILE_PATH}/${record.id}`);
    } else if (activeKey === TABS_KEYS.SCHEDULED) {
      setSidePanelDetailsSchedule({ ...record });
      setShowSidePanel(true);
    } else if (activeKey === TABS_KEYS.FUND_TRANSFERS) {
      if (activeFundTransferSubTab === FUND_TRANSFERS_SUB_TABS.REQUESTED_FUNDS) {
        setSidePanelDetailsRequestedFund({ ...record });
        setShowSidePanel(true);
      } else if (activeFundTransferSubTab === FUND_TRANSFERS_SUB_TABS.SENT_FUNDS) {
        setSidePanelDetailsSentFund({ ...record });
        setShowSidePanel(true);
      }
    }
  };

  const getData = (page) => {
    if (activeKey === TABS_KEYS.ALL) {
      dispatch(getPaginatedTeamList(page, 10));
    } else if (activeKey === TABS_KEYS.SCHEDULED) {
      dispatch(getScheduledTransactionsFunc({ page, limit: 10 }));
    } else if (activeKey === TABS_KEYS.FUND_TRANSFERS) {
      const dateFilterValues = filter ? filter[FUND_TRANSFER_FILTERS_NAMES?.CREATED_DATE] : {};
      const amountFilterValues = filter ? filter[FUND_TRANSFER_FILTERS_NAMES?.AMOUNT] : {};
      if (activeFundTransferSubTab === FUND_TRANSFERS_SUB_TABS.REQUESTED_FUNDS) {
        getRequestedFundTransferData(page, { ...dateFilterValues, ...amountFilterValues });
      } else if (activeFundTransferSubTab === FUND_TRANSFERS_SUB_TABS.SENT_FUNDS) {
        getSentFundTransferData(page, { ...dateFilterValues, ...amountFilterValues });
      }
    }
  };

  const paginatorStartIndex = 1;

  const updatePage = (page) => {
    const calculatePage = page - paginatorStartIndex;
    setCurrentPage(calculatePage);
    getData(calculatePage);
  };

  const isMemberOnly = !(
    userData?.isAdmin ||
    userData?.manageTeams?.length > 0 ||
    userData.company_role === USER_ROLE.ACCOUNTANT ||
    userData.company_role === USER_ROLE.AUDITOR
  );

  const showActions = (columns) => {
    return [...columns, ...AllBudgetColumnActions];
  };

  let allBudgetColumns =
    filterTeamsWithSoleManager || isMemberOnly
      ? showActions(AllBudgetColumnsDataMember(isPaymentEnabled))
      : showActions(AllBudgetColumnsData(currencyCode, isPaymentEnabled));
  const showLoading =
    loading ||
    deleteLoading ||
    event.status === TEAM_ACTION_STATUS.LOADING ||
    scheduleLoading ||
    requestedFundTransferApiLoading ||
    sentFundTransferApiLoading;

  const fundTransferSidePanelButtons = () => {
    return userData.userId === sidePanelDetailsRequestedFund.user_id &&
      sidePanelDetailsRequestedFund.status === "pending"
      ? [
          {
            id: "SIDE-PANEL-BUTTON-002",
            type: ESidePanelButtonType.BORDERED_DESTRUCTIVE,
            disabled: false,
            text: "Delete Request",
            loading: false,
            hiddenButtonBeforeConfirm: false,
            action: () => {
              dispatch(deleteTeamPendingFundTransfer(sidePanelDetailsRequestedFund.id));
              setShowSidePanel(false);
            },
          },
        ]
      : [];
  };

  const setSidePanel = () => {
    switch (activeKey) {
      case TABS_KEYS.SCHEDULED:
        return (
          <SideSection
            className={classNames("transactions--side-section", { card: activeKey === TABS_KEYS.SCHEDULED })}
            visible={activeKey === TABS_KEYS.SCHEDULED}
            onClose={() => {
              setShowSidePanel(false);
            }}
          >
            <ScheduledDetails
              data={sidePanelDetailsSchedule}
              editAction={() => {
                setShowSidePanel(false);
                setScheduledEditValue(sidePanelDetailsSchedule);
              }}
              stopAction={() => {
                dispatch(stopScheduledTransactionsFunc({ schedulerIds: [sidePanelDetailsSchedule?.id] }));
                setShowSidePanel(false);
              }}
            />
          </SideSection>
        );
      case TABS_KEYS.FUND_TRANSFERS:
        if (activeFundTransferSubTab === FUND_TRANSFERS_SUB_TABS.REQUESTED_FUNDS) {
          return (
            <SidePanel
              visible={activeKey === TABS_KEYS.FUND_TRANSFERS}
              title="Transfer Details"
              onClose={() => {
                setShowSidePanel(false);
              }}
              buttons={fundTransferSidePanelButtons()}
              sticky={true}
            >
              <TransactionHeader
                secondaryName="Transfer Amount"
                amount={`${sidePanelDetailsRequestedFund.currency_code + " " + sidePanelDetailsRequestedFund.amount}`}
                name={
                  sidePanelDetailsRequestedFund?.requested_for_wallet_type === "team" ? (
                    <>
                      (Budget) <br />
                      <span className="typhography-caption">{sidePanelDetailsRequestedFund?.team_name}</span>
                    </>
                  ) : (
                    <>
                      {sidePanelDetailsRequestedFund.user_name} <br />
                      <span className="typhography-caption">{sidePanelDetailsRequestedFund?.team_name}</span>
                    </>
                  )
                }
                subTitle="Destination Fund"
              />
              <TransactionDetail
                receiptTitle="Attachments"
                visibleInput={[]}
                details={requestedFundTransferDetails(sidePanelDetailsRequestedFund)}
                defaultValue={{}}
                form={form}
                setFormValue={() => {}}
                disabled
              />
            </SidePanel>
          );
        } else if (activeFundTransferSubTab === FUND_TRANSFERS_SUB_TABS.SENT_FUNDS) {
          return (
            <SidePanel visible title="Transfer Details" onClose={() => setShowSidePanel(false)}>
              <TransactionHeader
                secondaryName="Transfer Amount"
                amount={`${sidePanelDetailsSentFund.currency + " " + sidePanelDetailsSentFund.amount}`}
                name={
                  <>
                    <Typography size="s" variant="body-content">
                      {sidePanelDetailsSentFund.source_team}
                    </Typography>{" "}
                    <br />
                    <Typography size="s" variant="body-content" className="typhography-caption">
                      {sidePanelDetailsSentFund?.source_user_name
                        ? sidePanelDetailsSentFund?.source_user_name
                        : "(budget)"}
                    </Typography>
                    <div className="transaction-header-content">
                      <Typography size="s" variant="body-content">
                        Destination Fund
                      </Typography>
                      <br />
                      <Typography size="s" variant="body-content">
                        {sidePanelDetailsSentFund.destination_team}
                      </Typography>{" "}
                      <br />
                      <Typography size="s" variant="body-content" className="typhography-caption">
                        {sidePanelDetailsSentFund?.destination_user_name
                          ? sidePanelDetailsSentFund?.destination_user_name
                          : "(budget)"}
                      </Typography>
                    </div>
                  </>
                }
                subTitle="Source of Fund"
              />
              <Section>
                <TransactionStatus
                  currentStatusId="1"
                  transactionStatus={[
                    {
                      date: setCreatedAtDate(sidePanelDetailsSentFund),
                      id: "1",
                      title: "Completed",
                    },
                  ]}
                />
              </Section>
              <TransactionDetail
                receiptTitle="Attachments"
                visibleInput={[]}
                details={sentFundTransferDetails(sidePanelDetailsSentFund)}
                defaultValue={{}}
                form={form}
                setFormValue={() => {}}
                disabled
              />
            </SidePanel>
          );
        }
        break;
      default:
        return null;
    }
  };

  const setTableData = () => {
    switch (activeKey) {
      case TABS_KEYS.ALL:
        return {
          dataSource: teamData,
          columnData: allBudgetColumns,
          actions: <></>,
        };
      case TABS_KEYS.SCHEDULED:
        return {
          dataSource: scheduleTransactionsData,
          columnData: scheduledColumnData(isAllowedEditBtn, isAllowedStopBtn, setScheduledEditValue),
          actions: <></>,
        };
      case TABS_KEYS.FUND_TRANSFERS:
        if (activeFundTransferSubTab === FUND_TRANSFERS_SUB_TABS.REQUESTED_FUNDS) {
          return {
            dataSource: requestedFundsList,
            columnData: RequestFundsColumns,
            actions: <FundTransferActions teamId={teamId} activeSubTab={activeFundTransferSubTab} />,
          };
        } else if (activeFundTransferSubTab === FUND_TRANSFERS_SUB_TABS.SENT_FUNDS) {
          return {
            dataSource: sentFundsList,
            columnData: SentFundsColumns,
            actions: <FundTransferActions teamId={teamId} activeSubTab={activeFundTransferSubTab} />,
          };
        }
        break;
      default:
        return {
          dataSource: [],
          columnData: [],
          actions: <></>,
        };
    }
  };

  const updateActiveTab = (tab: TABS_KEYS) => {
    let activeTitle: TABS_TITLES | "" = "";
    for (const tabs of TABS) {
      if (tabs.tabKey === tab) {
        activeTitle = tabs.title;
        break;
      }
    }
    setActiveKey(tab);
    tab !== TABS_KEYS.ALL && handleBudgetEvents(BUDGET_EVENTS.BUDGET_PAGE_LOADED, activeTitle);
  };

  const tableData = setTableData();

  const handleToasterDisplayForStopEdit = (val: boolean, msg: string, status: boolean = true) => {
    setToaster({
      visible: val,
      status: status ? TOASTER_STATUS_TYPE.SUCCESS : TOASTER_STATUS_TYPE.ERROR,
      message: msg,
    });
  };

  // getRowSelection returns the controls for selection of the each/all row
  const getRowSelection = () => {
    if (activeKey === TABS_KEYS.SCHEDULED) {
      return {
        onChange: (selectedRequestsOnCurrentPage) => {
          setSelectedRequests(selectedRequestsOnCurrentPage.map((item) => ({ id: item })));
        },
        selectedRowKeys: selectedRequests.map((row) => row.id),
        getCheckboxProps: (record) => {
          return {
            disabled: shouldDisableCurrentRowSelection(selectedRequests, record),
          };
        },
      };
    }
  };

  const getDestructiveLabel = (): string => {
    if (isConfirmBulkStop) return "Confirm to Stop";
    return `Stop(${selectedRequests.length})`;
  };

  // getBulkAction returns the controls for bulk update functionalities
  const getBulkAction = () => {
    if (activeKey === TABS_KEYS.SCHEDULED) {
      return {
        type: BulkActionType.Destructive,
        dataName: "transaction",
        maximum: 20,
        data: selectedRequests,
        supportingInfo: <BasicInfo amount={getTotalAmount} />,
        constructive: {
          label: "",
          handler: () => {},
        },
        destructive: {
          label: getDestructiveLabel(),
          handler: () => {
            if (isConfirmBulkStop) {
              dispatch(stopScheduledTransactionsFunc({ schedulerIds: selectedRequests.map((item) => item.id) }));
              setSelectedRequests([]);
              setIsConfirmBulkStop(false);
            } else {
              setIsConfirmBulkStop(true);
            }
          },
        },
        clearSelection: () => {
          setSelectedRequests([]);
          setIsConfirmBulkStop(false);
        },
      } as IBulkActionProps;
    } else {
      return null;
    }
  };

  // getTotal returns the total count of the records
  const getTotal = (): number => {
    switch (activeKey) {
      case TABS_KEYS.ALL:
        return allBudgetsTotalCount;
      case TABS_KEYS.SCHEDULED:
        return scheduleTransactionsCount;
      case TABS_KEYS.FUND_TRANSFERS:
        if (activeFundTransferSubTab === FUND_TRANSFERS_SUB_TABS.REQUESTED_FUNDS) {
          return requestedFundsCount;
        } else if (activeFundTransferSubTab === FUND_TRANSFERS_SUB_TABS.SENT_FUNDS) {
          return sentFundsCount;
        }
    }
  };

  const table = {
    total: getTotal(),
    loading: showLoading,
    dataSource: tableData.dataSource,
    pageNum: currentPage,
    pageSize: 10,
    onPageChange: updatePage,
    emptyState: <p>No transactions found</p>,
    rowAction: handleRowClick,
    rowSelection: getRowSelection(),
    paginationOnTop: activeKey === TABS_KEYS.FUND_TRANSFERS,
    paginationOnBottom: activeKey !== TABS_KEYS.FUND_TRANSFERS,
    bulkAction: getBulkAction(),
    scrollHorizontal: "fit-content",
  };

  const handleFundTransferSubTask = (tab: FUND_TRANSFERS_SUB_TABS) => {
    setActiveFundTransferSubTab(tab);
  };

  return (
    <div className="teams-tab-page">
      <div className="feature-flag-container">
        <div className="teams-tab-container">
          <div className="teams-tab-page-header">
            <Header title="Budgets"></Header>
            {!isMatchMove && IS_ALLOWED_CREATE_NEW_TEAM_BUTTON && (
              // To maintain consistency with other pages, we're not utilizing splice for this button.
              <Button id="actionButton" className="data-table__action-button" action={onAddTeamClickHandler}>
                + Create New Budget
              </Button>
            )}
          </div>

          {isMatchMove && (
            <Tabs
              tabsList={TABS}
              defaultActiveKey={TABS_KEYS.ALL}
              activeKey={activeKey}
              action={updateActiveTab}
              content={(tab: ListData) => {
                return null;
              }}
            />
          )}

          {isMatchMove && (
            <div className={`teams-header-container${activeKey === TABS_KEYS.FUND_TRANSFERS ? " flex-row" : ""}`}>
              {activeKey === TABS_KEYS.FUND_TRANSFERS ? (
                <>
                  <FundTransferSubTabs activeTab={activeFundTransferSubTab} onChangeTab={handleFundTransferSubTask} />
                  <p className="additional_info">
                    {activeFundTransferSubTab === FUND_TRANSFERS_SUB_TABS.REQUESTED_FUNDS
                      ? ADDITIONAL_INFO_TEXT[FUND_TRANSFERS_SUB_TABS.REQUESTED_FUNDS]
                      : ADDITIONAL_INFO_TEXT[FUND_TRANSFERS_SUB_TABS.SENT_FUNDS]}
                  </p>
                </>
              ) : (
                <p className="additional_info">{ADDITIONAL_INFO_TEXT[activeKey]}</p>
              )}

              {activeKey === TABS_KEYS.ALL && IS_ALLOWED_CREATE_NEW_TEAM_BUTTON && (
                <button className="create-budget-button" onClick={onAddTeamClickHandler}>
                  Create Budget
                </button>
              )}
            </div>
          )}
        </div>

        {isMatchMove && tableData.actions}

        {!isMatchMove && (
          <Typography size="m" variant="body-content" className="budget-info">
            {ADDITIONAL_INFO_TEXT[TABS_KEYS.ALL]}
          </Typography>
        )}
        <DataTable size={DATA_TABLE_SIZE_TYPES.LARGE}>
          <Table {...table}>{tableData.columnData}</Table>
          {showSidePanel && setSidePanel()}
        </DataTable>

        <SendMoneyModal
          show={scheduledEditValue}
          isTeam={false}
          close={() => {
            setScheduledEditValue(null);
          }}
          isEdit
          initialValues={scheduledEditValue}
          action={(value) => {
            const payload = {
              currency: value?.currency,
              amount: parseFloat(value?.amount),
              schedule: value?.schedule,
              isActive: true,
            };
            dispatch(editScheduledTransactionFunc(scheduledEditValue?.id, payload));
          }}
        />
      </div>
    </div>
  );
};

export default TeamsListing;
