import dayjs from "dayjs";
import qs from "query-string";
import { Base64 } from "js-base64";
import { useLocation, useRouteMatch } from "react-router-dom";

import { GetOrgId } from "utility";

import { ILocationParams, ILocationQueryParams, IReimbursementListRequestPayload } from "Views/Reimbursement/@types";
import {
  TABLE_SIZE,
  REIMBURSEMENT_VIEWS,
  REIMBURSEMENT_PAGE_OPERATIONS,
  REIMBURSEMENT_SETTLEMENT_STATUSES,
} from "Views/Reimbursement/Constants";

export const useListCRPayload = (): IReimbursementListRequestPayload => {
  const location = useLocation();

  const { params } = useRouteMatch<ILocationParams>();
  const { operation = "", id = "" } = params;

  const query: ILocationQueryParams = qs.parse(location.search, {
    parseNumbers: true,
    parseBooleans: true,
  });

  const {
    page = 1,
    startDate,
    endDate,
    teamIds = "",
    statuses: paramStatuses = "",
    requesterIds = "",
    merchantIds = "",
  } = query;

  const isSettlementsView = operation === REIMBURSEMENT_PAGE_OPERATIONS.SETTLEMENTS;

  const isPersonalReimbursements = location.pathname.includes(REIMBURSEMENT_VIEWS.PERSONAL_REIMBURSEMENTS.link);

  let statuses: string[] = undefined;
  let start_date: string = undefined;
  let end_date: string = undefined;
  let team_ids: string[] = undefined;
  let requester_user_ids: string[] = undefined;
  let merchant: string[] = undefined;

  if (isSettlementsView && id === REIMBURSEMENT_SETTLEMENT_STATUSES.APPROVED.id) {
    statuses = [REIMBURSEMENT_SETTLEMENT_STATUSES.APPROVED.id];
  } else if (isSettlementsView && id === REIMBURSEMENT_SETTLEMENT_STATUSES.SETTLED_FOR_SETTLEMENT_VIEW.id) {
    statuses = [
      REIMBURSEMENT_SETTLEMENT_STATUSES.SETTLED_WITH_SPENMO.id,
      REIMBURSEMENT_SETTLEMENT_STATUSES.SETTLED_WITH_OTHERS.id,
    ];
  } else if (paramStatuses.length > 0) {
    statuses = Base64.decode(paramStatuses).split(",");
  }

  if (Number(startDate) && Number(endDate)) {
    start_date = dayjs.unix(startDate).format("YYYY-MM-DD");
    end_date = dayjs.unix(endDate).format("YYYY-MM-DD");
  }

  if (teamIds.length > 0) {
    team_ids = Base64.decode(teamIds).split(",");
  }

  if (requesterIds.length > 0) {
    requester_user_ids = Base64.decode(requesterIds).split(",");
  }

  if (merchantIds.length > 0) {
    merchant = Base64.decode(merchantIds).split(",");
  }

  const filters = {
    type: "reimbursement",
    statuses,
    start_date,
    end_date,
    team_ids,
    requester_user_ids,
    merchant,
  };

  const payload: IReimbursementListRequestPayload = {
    status: "all",
    limit: TABLE_SIZE,
    pg: page > 0 ? page - 1 : 0,
    include_my_requests: false,
    organisation_id: GetOrgId(),
    filters: filters,
    ...(isPersonalReimbursements && { is_scheduled_request: false }),
  };

  return payload;
};

export default useListCRPayload;
