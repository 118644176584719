import React, { useCallback, useEffect } from "react";

import { useAppDispatch } from "hook";
import { getTeamListByUserId } from "Redux/Actions";

import TrxnFilters, {
  More,
  Quick,
  TeamFilter,
  CategoryFilter,
  RecipientFilter,
  AttachmentFilter,
  TrxnTypeQuickFilter as TypeFilter,
  BudgetFilter,
} from "Views/Transaction/Filters";
import { GET_TRXN_REQUEST_PARAMS, ITrxnFilterItem } from "Views/Transaction/@types";

import { RequesterFilter } from "./Requester";
import { useAccountingTransaction } from "Views/Transaction/Context";

export const QUICK_FILTER_KEYS: string[] = [
  GET_TRXN_REQUEST_PARAMS.START_DATE,
  GET_TRXN_REQUEST_PARAMS.END_DATE,
  GET_TRXN_REQUEST_PARAMS.TYPES,
  GET_TRXN_REQUEST_PARAMS.TEAM_ID,
  GET_TRXN_REQUEST_PARAMS.REQUESTER_ID,
  GET_TRXN_REQUEST_PARAMS.RECIPIENT_ID,
];

export const MORE_FILTER_KEYS: string[] = [
  GET_TRXN_REQUEST_PARAMS.TEAM_ID,
  GET_TRXN_REQUEST_PARAMS.CATEGORY,
  GET_TRXN_REQUEST_PARAMS.ATTACHMENT,
  GET_TRXN_REQUEST_PARAMS.MIN_AMOUNT,
  GET_TRXN_REQUEST_PARAMS.MAX_AMOUNT,
];

const ALL_TRXN_QUICK_FILTER_LIST = (isRequesterFilterAllowed: boolean): ITrxnFilterItem[] => {
  return [
    { id: "ALL-TRXN-QUICK-FILTER-0", enabled: true, Component: <Quick.SearchBar /> },
    { id: "ALL-TRXN-QUICK-FILTER-1", enabled: true, Component: <Quick.Date id="all" /> },
    { id: "ALL-TRXN-QUICK-FILTER-2", enabled: true, Component: <TypeFilter id={GET_TRXN_REQUEST_PARAMS.TYPES} /> },
    {
      id: "ALL-TRXN-QUICK-FILTER-3",
      enabled: !isRequesterFilterAllowed,
      Component: <TeamFilter id={GET_TRXN_REQUEST_PARAMS.TEAM_ID} />,
    },
    {
      id: "ALL-TRXN-QUICK-FILTER-4",
      enabled: isRequesterFilterAllowed,
      Component: <RequesterFilter />,
    },
    {
      id: "ALL-TRXN-QUICK-FILTER-5",
      enabled: true,
      Component: <RecipientFilter id={GET_TRXN_REQUEST_PARAMS.RECIPIENT_NAME} />,
    },
  ].filter((item) => item.enabled);
};

const ALL_TRXN_MORE_FILTER_LIST: ITrxnFilterItem[] = [
  { id: "ALL-TRXN-MORE-FILTER-1", enabled: true, Component: <BudgetFilter id={GET_TRXN_REQUEST_PARAMS.TEAM_ID} /> },
  { id: "ALL-TRXN-MORE-FILTER-2", enabled: true, Component: <CategoryFilter id={GET_TRXN_REQUEST_PARAMS.CATEGORY} /> },
  {
    id: "ALL-TRXN-MORE-FILTER-3",
    enabled: true,
    Component: <AttachmentFilter id={GET_TRXN_REQUEST_PARAMS.ATTACHMENT} />,
  },
  { id: "ALL-TRXN-MORE-FILTER-4", enabled: true, Component: <More.Amount title="Amount" /> },
].filter((item) => item.enabled);

export const AllTrxnFilters = () => {
  const { activePageDetails } = useAccountingTransaction();
  const isRequesterFilterAllowed = activePageDetails?.isRequesterFilterAllowed;

  const dispatch = useAppDispatch();

  const dispatchTeamListAction = useCallback(
    (teamId?: string[]) => {
      dispatch(getTeamListByUserId(teamId));
    },
    [dispatch]
  );

  useEffect(() => {
    dispatchTeamListAction();
  }, [dispatchTeamListAction]);

  return (
    <TrxnFilters
      isAllTab
      quick={ALL_TRXN_QUICK_FILTER_LIST(isRequesterFilterAllowed)}
      more={ALL_TRXN_MORE_FILTER_LIST}
      quickKeys={QUICK_FILTER_KEYS}
    />
  );
};
