import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import { Col, Row } from "Splice/Grid";
import { Typography, ExternalOutline, Loader } from "@spenmo/splice";
import { BILL_PAGE_PERMISSION_PARAMS } from "Views/Bills/Permission";
import usePermissionCheck from "Permission/usePermissionCheck";

import { SettingLabel } from "../components";
import { PARTNER_TYPE } from "../helper/constant";

import styles from "./styles.module.scss";

const Content = ({ title, disabled }: { title: string; disabled?: boolean }) => {
  return (
    <div
      className={cn("sp-button sp-button--s sp-button--secondary", styles.button, {
        [styles.buttonDisabled]: disabled,
      })}
    >
      <ExternalOutline size="16" iconColor="currentColor" className={styles.icon} />
      <Typography variant="body-content" weight={600} size="s" tag="p">
        {title}
      </Typography>
    </div>
  );
};

const ImportSettings = ({ finishFetchAllData }: { finishFetchAllData: boolean }) => {
  const { data } = useSelector((state: any) => state.accountingPartnerAuthReducer);
  const partnerName = data?.payload?.partnerName;
  const isNetsuite = partnerName === PARTNER_TYPE.NETSUITE;
  const [IS_ALLOWED_SUBMIT_BILL_PAYMENT] = (usePermissionCheck(BILL_PAGE_PERMISSION_PARAMS) as boolean[]) || [];
  return (
    <Row column={16} offset={0} gutter={[0, 0]} className={styles.container}>
      <Col span={16}>
        <SettingLabel text={`Import from ${partnerName}`} />
        <div className={styles.importContainer}>
          <Link to="/settings/category?import=true" target="_blank" rel="noreferrer">
            <Content title="Categories" disabled={!finishFetchAllData} />
          </Link>

          <Link to="/settings/tax?import=true" target="_blank" rel="noreferrer">
            <Content title="Taxes" disabled={!finishFetchAllData} />
          </Link>

          {!finishFetchAllData && <Loader.Spinner size="l" variant="brand" />}
          {IS_ALLOWED_SUBMIT_BILL_PAYMENT && !isNetsuite && (
            <Link to="/bills/xero" target="_blank" rel="noreferrer">
              <Content title="Bills" />
            </Link>
          )}
        </div>
      </Col>
    </Row>
  );
};

export default ImportSettings;
