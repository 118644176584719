import * as React from "react";
import { Skeleton } from "antd";

import Icon from "Modules/icons";
import { navigationChevron } from "assets/img/homeIcons";
import styles from "../TaskCard/index.module.scss";

const PendingActionsShimmer = () => {
  return (
    <>
      {Array.from({ length: 4 })?.map(() => (
        <div data-testid="PendingActionsShimmer" className={styles.card}>
          <Skeleton.Avatar className={styles.avatar} active shape={"square"} />
          <Skeleton.Button className={styles.paragraph} active shape={"round"} />
          <Skeleton.Button className={styles.paragraph} active shape={"round"} />
          <Icon className={styles.arrow} src={navigationChevron} alt={"arrow icon"} />
        </div>
      ))}
    </>
  );
};

export default PendingActionsShimmer;
