/*
  enums
*/

export enum POPOVER_WIDTH {
  XS = "extra-small--width",
  S = "small--width",
  R = "regular--width",
  M = "medium--width",
  L = "large--width",
  XL = "extra-large--width",
  INHERIT = "inherit--width",
}

export enum POSITION_ALGO_TYPE {
  SIBLING = "sibling",
  VERTICAL = "vertical",
  HORIZONTAL = "horizontal",
}

/*
  interfaces
*/

export interface IPopoverProps {
  className: string;
  containerClass?: string;
  active: boolean;
  width?: POPOVER_WIDTH;
  children?: React.ReactNode;
  onClickOutside: (e: MouseEvent) => void;
  ignoreClickOnElements?: string[];
  useDynamicPosition?: boolean;
  positionAlgoType?: POSITION_ALGO_TYPE;
}

export interface IOutsideViewport {
  right: boolean;
  left: boolean;
  top: boolean;
  bottom: boolean;
}
