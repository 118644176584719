import * as React from "react";
import { Modal, Alert, Button } from "antd";

interface UpdateFlowModalProps {
  show: boolean;
  onCancel: () => void;
  totalActiveRequests: number;
  onUpdate: () => void;
}

const UpdateFlowModal: React.FC<UpdateFlowModalProps> = ({
  show,
  onCancel,
  totalActiveRequests,
  onUpdate,
}: UpdateFlowModalProps) => {
  return (
    <Modal
      footer={null}
      visible={show}
      className={"approval-flow-modal no-animation"}
      title="Update this Approval Flow?"
      onCancel={onCancel}
    >
      <div className="approval-flow-modal__content">
        <div data-show="true">
          {!!totalActiveRequests && (
            <Alert
              message=""
              description={`${totalActiveRequests} active requests are following this flow.`}
              type="warning"
              showIcon
            />
          )}
          <div className="approval-flow-modal__text mt-15">
            <p>You are about to update an approval flow with ongoing requests.</p>
            <p className="mt-15">What does this mean?</p>
            <ul className="list-style-1">
              <li>
                All ongoing requests will still follow the previous version of the <br />
                approval flow.
              </li>
              <li>All future requests will follow the updated version.</li>
            </ul>
            <hr className="separator-one" />
            <p>Confirm updating this approval flow by clicking the button below</p>
          </div>
          <div className="approval-flow-modal__action-area">
            <div className="align-right mt-15">
              <Button className={`approval-flow-modal__action-button--update`} onClick={onUpdate}>
                Update Flow
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default UpdateFlowModal;
