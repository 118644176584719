import { appNotification } from "Modules/appNotification/appNotification";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { SUBMITTED_BILLS_URL, SUBMITTED_BILLS_VIEW, TEAMS_PAGE_URL, TEAM_LIMIT_EXCEEDED } from "../constants";

interface LocationState {
  data: {
    isSuccess: boolean;
    isApprovalEnabled: boolean;
    isBalanceSufficient: boolean;
    balance: unknown;
    saveVendorMessage: string;
    isTeamLimitExceeded: TEAM_LIMIT_EXCEEDED;
    paymentSource: string;
    isMultipleUpload: boolean;
    toasterData: {
      message?: string;
      actionUrl?: string;
      actionLabel?: string;
    };
    hideCTA: boolean;
  };
}

interface GenerateToasterAction {
  isApprovalEnabled: boolean;
  isTeamLimitExceeded: TEAM_LIMIT_EXCEEDED;
}

interface GenerateToasterMessage extends GenerateToasterAction {
  isMultipleUpload: boolean;
  paymentSource: string;
}

export const useSuccessBillToast = (isPaymentRunOn: boolean) => {
  const location = useLocation<LocationState>();
  const history = useHistory();

  const generateToasterMessage = ({
    isApprovalEnabled,
    isTeamLimitExceeded,
    paymentSource,
    isMultipleUpload,
  }: GenerateToasterMessage) => {
    if (isApprovalEnabled) {
      if (isTeamLimitExceeded === TEAM_LIMIT_EXCEEDED.YES) {
        return (
          <>
            Your bill has been created. {isMultipleUpload ? "Bills" : "This bill"} will be processed once
            <b> {paymentSource}</b> has sufficient
            <b> Remaining Spending Limit</b> for deduction.
          </>
        );
      } else if (isTeamLimitExceeded === TEAM_LIMIT_EXCEEDED.PARTIAL) {
        return (
          <>
            Your bills have been created. One or more bills will be processed once <b>{paymentSource}</b> has sufficient
            <b> Remaining Spending Limit</b> for deduction.
          </>
        );
      } else {
        return "Bill has been successfully submitted for approval.";
      }
    } else {
      return "Bill has been successfully submitted for processing payment";
    }
  };

  const generateToasterActionLabel = ({ isApprovalEnabled, isTeamLimitExceeded }: GenerateToasterAction) => {
    if (isApprovalEnabled) {
      if (isTeamLimitExceeded === TEAM_LIMIT_EXCEEDED.YES || isTeamLimitExceeded === TEAM_LIMIT_EXCEEDED.PARTIAL) {
        return "View Teams";
      }
    }
    return "View Bill";
  };

  const generateToasterActionUrl = ({ isTeamLimitExceeded, isApprovalEnabled }: GenerateToasterAction) => {
    if (
      isApprovalEnabled &&
      (isTeamLimitExceeded === TEAM_LIMIT_EXCEEDED.YES || isTeamLimitExceeded === TEAM_LIMIT_EXCEEDED.PARTIAL)
    ) {
      return TEAMS_PAGE_URL;
    }

    return isPaymentRunOn ? SUBMITTED_BILLS_VIEW.PAYMENT_RUN : SUBMITTED_BILLS_VIEW.DEFAULT;
  };

  const generateToast = () => {
    const {
      isSuccess,
      isApprovalEnabled,
      saveVendorMessage,
      isTeamLimitExceeded,
      paymentSource,
      isMultipleUpload,
      toasterData,
      hideCTA = false,
    } = location?.state?.data || {};

    if (isSuccess) {
      const toasterMessage =
        toasterData?.message ||
        generateToasterMessage({ isApprovalEnabled, isTeamLimitExceeded, paymentSource, isMultipleUpload });
      const toasterActionUrl =
        toasterData?.actionUrl ||
        generateToasterActionUrl({
          isTeamLimitExceeded,
          isApprovalEnabled,
        });
      const toasterActionLabel =
        toasterData?.actionLabel || generateToasterActionLabel({ isApprovalEnabled, isTeamLimitExceeded });

      appNotification.success({
        message: (
          <div>
            <p>{toasterMessage}</p>
            {saveVendorMessage && <p>{saveVendorMessage}</p>}
          </div>
        ),
        onClickCTA: () => {
          history.push(toasterActionUrl);
        },
        ...(hideCTA ? {} : { cta: toasterActionLabel }),
      });
      history.replace({ ...location, state: undefined });
    } else {
      appNotification.error({
        message: "An error occurred. Please try again.",
      });
    }
  };

  return generateToast;
};
