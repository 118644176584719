import { trackEvent } from "utility/analytics";

import { IReimbursementAnalytics } from "Views/Reimbursement/@types";

export enum REIMBURSEMENTS_PAGE_ANALYTICS {
  REIMBURSEMENT = "Reimbursements",
  REIMBURSEMENT_DETAILS_SETTLEMENT_ON = "Reimbursement Details Sidepanel With Settlement On",
  REIMBURSEMENT_DETAILS_SETTLEMENT_OFF = "Reimbursement Details Sidepanel With Settlement Off",
  COMPANY_REIMBURSEMENT = "Company Reimbursements",
  COMPANY_REIMBURSEMENT_EXPORT_SIDEPANEL = "Company Reimbursements Export Sidepanel",
  SETTLED_REIMBURSEMENT_BULK_ACTION = "Company Reimbursements Settlement View Bulk Action",
  PERSONAL_REIMBURSEMENT = "Personal Reimbursements",
  NEW_REIMBURSEMENT = "New Reimbursement",
  EDIT_REIMBURSEMENT = "Edit Reimbursement",
}

export const trackReimbursementAnalytics = ({ eventName, eventSource, eventSubSource }: IReimbursementAnalytics) => {
  trackEvent(eventName, {
    reimbursement_page_event_source: eventSource,
    reimbursement_page_event_subsource: eventSubSource,
  });
};
