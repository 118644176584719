import React from "react";
import classNames from "classnames";

import Button from "Modules/button";

import styles from "./index.module.scss";
import Loader from "Modules/DS/Loader";
import { LOADER_TYPES } from "Modules/DS/Loader/types";

export const KYXPrimaryButton = ({
  loading,
  action,
  children,
  className,
}: {
  loading: boolean;
  children: JSX.Element | string;
  action?: () => void;
  className?: string;
}) => {
  return (
    <Button
      className={classNames(styles.button, { [styles.loading]: loading, [className]: className })}
      action={action}
      rounded
    >
      {loading ? <Loader type={LOADER_TYPES.WHITE} /> : children}
    </Button>
  );
};
