import moment from "moment";

export const formatDate = (value: string) => {
  let date: string;
  if (value === "0001-01-01T00:00:00Z") {
    date = new Date(new Date()).toUTCString();
  } else {
    date = value;
  }
  return moment(date);
};

export const disableFutureDates = (current) => {
  // Disables dates after today
  return current && current > moment().endOf("day");
};
