import React, { useContext } from "react";
import Chip, { IActionChipProps } from "Modules/DS/Chip";
import { TrxnFilterContext, TrxnPaginationContext } from "Views/Transaction/Context";
import { ITrxnFilterContext, ITrxnPaginationContext, GET_CARDS_TRXNS_REQUEST_PARAMS } from "Views/Transaction/@types";

interface ITrxnStatusProps {
  label: string;
  value: string[];
}

const TrxnStatus = ({ label = "", value = [] }: ITrxnStatusProps) => {
  const { resetCurrentPage } = useContext<ITrxnPaginationContext>(TrxnPaginationContext);
  const { onApplyFilter, resetFilter, filter } = useContext<ITrxnFilterContext>(TrxnFilterContext);

  const txnStatusFilterValue = filter?.[GET_CARDS_TRXNS_REQUEST_PARAMS.TXN_STATUS] || [];

  const isFilterSelected = txnStatusFilterValue?.find((status) => value.includes(status)) || false;

  const onSelectHandler = () => {
    onApplyFilter({
      [GET_CARDS_TRXNS_REQUEST_PARAMS.TXN_STATUS]: [...txnStatusFilterValue, ...value],
      [GET_CARDS_TRXNS_REQUEST_PARAMS.PAGE]: 1,
    });
    resetCurrentPage();
  };

  const clearFilter = () => {
    const filteredValue = txnStatusFilterValue.filter((status) => !value.includes(status));
    if (filteredValue?.length) {
      onApplyFilter({
        [GET_CARDS_TRXNS_REQUEST_PARAMS.TXN_STATUS]: filteredValue,
      });
    } else {
      resetFilter([GET_CARDS_TRXNS_REQUEST_PARAMS.TXN_STATUS]);
    }

    resetCurrentPage();
  };

  const chipProps: IActionChipProps = {
    active: isFilterSelected,
    selected: isFilterSelected,
    title: label,
    onClick: onSelectHandler,
    onClear: clearFilter,
  };

  return <Chip.Action {...chipProps} />;
};

export default TrxnStatus;
