import * as React from "react";
import * as Steps from "Views/Card/CreateOrRequest/Steps";

export const RequestPhysicalCardConfig = (isManagerOrAdmin) => {
  const config = [
    {
      Component: <Steps.ShippingAddress />,
    },
  ];
  if (!isManagerOrAdmin) {
    config.push({
      Component: <Steps.Review />,
    });
  }
  return config;
};

export const RequestVirtualCardConfig = () => [
  {
    Component: <Steps.Review />,
  },
];

export const CreateVirtualCardConfig = () => {
  const config = [
    {
      Component: <Steps.TitleAndTeam />,
    },
    {
      Component: <Steps.Policy />,
    },
    {
      Component: <Steps.SpendingLock />,
    },
  ];
  return config;
};
