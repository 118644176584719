import axios from "axios";
import { axiosHeader } from "./index";
import { GetBaseAuthObject } from "../../utility";

export const FETCH_CVV_REQUEST = "FETCH_CVV_REQUEST";
export const FETCH_CVV_SUCCESS = "FETCH_CVV_SUCCESS";
export const FETCH_CVV_FAILURE = "FETCH_CVV_FAILURE";
export const FETCH_CNUM_REQUEST = "FETCH_CNUM_REQUEST";
export const FETCH_CNUM_SUCCESS = "FETCH_CNUM_SUCCESS";
export const FETCH_CNUM_FAILURE = "FETCH_NUM_FAILURE";

const fetchNumDataReq = (data) => {
  return {
    type: FETCH_CNUM_REQUEST,
    data: data,
  };
};

const fetchNumDataSuccess = (data) => {
  return {
    type: FETCH_CNUM_SUCCESS,
    data: data,
  };
};

const fetchNumDataError = (data) => {
  return {
    type: FETCH_CNUM_FAILURE,
    data: data,
  };
};
const fetchDataReq = (data) => {
  return {
    type: FETCH_CVV_REQUEST,
    data: data,
  };
};

const fetchDataSuccess = (data) => {
  return {
    type: FETCH_CVV_SUCCESS,
    data: data,
  };
};

const fetchDataError = (data) => {
  return {
    type: FETCH_CVV_FAILURE,
    data: data,
  };
};

export const cvvFunc = (card_id) => {
  return (dispatch) => {
    dispatch(fetchDataReq());
    const orgId = GetBaseAuthObject().orgId;
    axios
      /*eslint no-process-env: "off"*/
      .get(window.__ENV__.API_BASE_URL + `/api/v1/org/card/${card_id}/cvv/?organisation_id=${orgId}`, axiosHeader)
      .then((data) => {
        if (data.data.status === 200) {
          dispatch(fetchDataSuccess(data.data));
        } else {
          dispatch(fetchDataError(data.data));
        }
      })
      .catch((err) => {
        dispatch(fetchDataError(err));
      });
  };
};

export const clearCVVFunc = () => {
  return (dispatch) => {
    dispatch(fetchDataSuccess({}));
  };
};

export const numFunc = (card_id) => {
  return (dispatch) => {
    dispatch(fetchNumDataReq());
    const orgId = GetBaseAuthObject().orgId;
    axios
      /*eslint no-process-env: "off"*/
      .get(window.__ENV__.API_BASE_URL + `/api/v1/org/card/${card_id}/details?organisation_id=${orgId}`, axiosHeader)
      .then((data) => {
        if (data.data.status === 200) {
          dispatch(fetchNumDataSuccess(data.data));
        } else {
          dispatch(fetchNumDataError(data.data));
        }
      })
      .catch((err) => {
        dispatch(fetchNumDataError(err));
      });
  };
};
export const clearNumFunc = () => {
  return (dispatch) => {
    dispatch(fetchNumDataSuccess({}));
  };
};
