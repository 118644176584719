import {
  UPDATE_SELF_APPROVAL_REQUEST,
  UPDATE_SELF_APPROVAL_SUCCESS,
  UPDATE_SELF_APPROVAL_FAILURE,
  UPDATE_SELF_APPROVAL_FLOW,
} from "../../Actions/ApprovalFlow/updateSelfApprovalUsers";
import { userInitialState } from "../../InitialState";

export const updateSelfApprovalReducer = (state = userInitialState, action) => {
  switch (action.type) {
    case UPDATE_SELF_APPROVAL_REQUEST:
      return {
        ...state,
        data: action.data,
        loading: true,
        error: false,
        errorDetail: null,
      };
    case UPDATE_SELF_APPROVAL_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
        error: false,
        errorDetail: null,
      };
    case UPDATE_SELF_APPROVAL_FAILURE:
      return {
        ...state,
        data: null,
        loading: false,
        errorDetail: action.data,
        error: true,
      };
    case UPDATE_SELF_APPROVAL_FLOW:
      return userInitialState;
    default:
      return state;
  }
};
