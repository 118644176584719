import React, { useState } from "react";
import { Button, Spin } from "antd";

import Icon from "Modules/icons";
import LoadingIcon from "Modules/loading";
import { LoadingOutlined } from "@ant-design/icons";
import { ActionLink, ConstructiveOrDesctructive, PrimaryOrSecondary, TrackEventProps } from "Modules/DS/DataTable";

import { greyCloseIcon } from "assets/img/index";
import styles from "./destructiveAction.module.scss";

import { trackEvent } from "utility/analytics";
interface DestructiveActionsProps {
  constructive: ConstructiveOrDesctructive;
  destructive: ConstructiveOrDesctructive;
  button?: PrimaryOrSecondary;
  link?: ActionLink;
  trackEventData?: (action: string, source: string) => TrackEventProps;
}

export enum BULK_ACTION_TYPES {
  constructive = "constructive",
  destructive = "desctructive",
}

export const DestructiveActions: React.FC<DestructiveActionsProps> = ({
  constructive,
  destructive,
  button,
  link,
  trackEventData,
}: DestructiveActionsProps): JSX.Element => {
  const loadingSpinner = <LoadingOutlined spin />;

  const [confirmAction, setConfirmAction] = useState(null);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isSubmitButton, setIsSubmitButton] = useState(false);

  const handleButton = async () => {
    if (isSubmitButton) {
      return;
    }
    setIsSubmitButton(true);
    const response = await button.handler();
    if (response) {
      setIsSubmitButton(false);
    }
  };

  const handleConstructive = async () => {
    setIsSubmit(true);
    const response = await constructive.handler();
    if (response) {
      setIsSubmit(false);
      setConfirmAction(null);
      if (trackEventData) {
        const { name, data } = trackEventData("Confirm Approve", "Bulk");
        if (name && data) {
          trackEvent(name, data);
        }
      }
    }
  };

  if (confirmAction) {
    return (
      <div className={styles["destructive-actions"]}>
        <Button
          className={styles["destructive-actions__button--constructive"]}
          onClick={handleConstructive}
          disabled={isSubmit}
        >
          {isSubmit && confirmAction ? <LoadingIcon size="s" /> : `Confirm to ${constructive.label}`}
        </Button>
        <Icon
          alt="close-confirm-button-icon"
          className={styles["close-button"]}
          src={greyCloseIcon}
          size="2"
          action={(e) => {
            e.stopPropagation();
            setConfirmAction(null);
          }}
        />
      </div>
    );
  }

  return (
    <div className={styles["destructive-actions"]}>
      <Button
        className={styles["destructive-actions__button--constructive"]}
        onClick={(e) => {
          e.stopPropagation();
          setConfirmAction(BULK_ACTION_TYPES.constructive);
          if (trackEventData) {
            const { name, data } = trackEventData("Approve", "Bulk");
            if (name && data) {
              trackEvent(name, data);
            }
          }
        }}
      >
        {constructive.label}
      </Button>
      <Button
        className={styles["destructive-actions__button--destructive"]}
        onClick={(e) => {
          e.stopPropagation();
          destructive.handler();
          if (trackEventData) {
            const { name, data } = trackEventData("Decline", "Bulk");
            if (name && data) {
              trackEvent(name, data);
            }
          }
        }}
      >
        {destructive.label}
      </Button>
      {Boolean(button) && (
        <Button
          className={styles[`default-actions__button--${button.type}`]}
          onClick={(e) => {
            e.stopPropagation();
            handleButton().catch((e) => console.error(e));
          }}
        >
          {isSubmitButton ? <Spin indicator={loadingSpinner} /> : `${button.label}`}
        </Button>
      )}
      {link && (
        <Button
          className={styles["destructive-actions__button--link"]}
          onClick={(e) => {
            e.stopPropagation();
            link.handler();
          }}
        >
          {link.label}
        </Button>
      )}
    </div>
  );
};
