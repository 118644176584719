import React from "react";
import { useRouteMatch } from "react-router-dom";
import { Skeleton as AntDSkeleton } from "antd";
import { Checkbox } from "@spenmo/splice";

import { ILocationParams } from "Views/Reimbursement/@types";
import { REIMBURSEMENT_PAGE_OPERATIONS, REIMBURSEMENT_SETTLEMENT_STATUSES } from "Views/Reimbursement/Constants";

import styles from "./index.module.scss";

export const ExpenseReportHeaderSkeleton = () => {
  const { params } = useRouteMatch<ILocationParams>();

  const { operation = "", id = "" } = params;

  const isUnpaidPayouts =
    operation === REIMBURSEMENT_PAGE_OPERATIONS.PAYOUTS &&
    id === REIMBURSEMENT_SETTLEMENT_STATUSES.UNPAID_FOR_PAYOUTS_VIEW.id;

  return (
    <div className={styles.header}>
      {isUnpaidPayouts && (
        <div className={styles.check}>
          <Checkbox size="s" disabled />
        </div>
      )}
      <AntDSkeleton.Input size="small" active={true} className={styles.skeleton} />
    </div>
  );
};

export default ExpenseReportHeaderSkeleton;
