import React, { useEffect, useState } from "react";
import qs from "query-string";
import { Base64 } from "js-base64";
import { Typography } from "@spenmo/splice";
import { useHistory, useLocation } from "react-router-dom";

import SidepanelSkeleton from "Views/Reimbursement/Sidepanel/Skeleton";
import ExportOption from "Views/Reimbursement/Sidepanel/Export/Option";

import { BILLS_QUERY_PARAMS } from "Views/Bills/V2/constants";
import DateType from "Views/Bills/V2/Sidepanel/Export/Date/Type";
import { IExportBillsProps, ILocationQueryParams } from "Views/Bills/V2/@types";
import useExportOptionsList, {
  GENERIC_PAYMENT_CSV,
} from "Views/Bills/V2/hooks/useExportOptionsList";

import styles from "./index.module.scss";

const ExportBills = ({ paymentPeriods, loadingPeriods }: IExportBillsProps) => {
  const history = useHistory();
  const location = useLocation();

  const query: ILocationQueryParams = qs.parse(location.search, {
    parseNumbers: true,
    parseBooleans: true,
  });

  const { exportType = "" } = query;

  const [activeFormat, setActiveFormat] = useState<string>(
    Base64.decode(exportType),
  );

  const { list: options, loading } = useExportOptionsList();

  useEffect(() => {
    if (!exportType && !loading) {
      let search = {};

      if (options.length === 1) {
        setActiveFormat(options[0].id);
        search = {
          ...search,
          [BILLS_QUERY_PARAMS.EXPORT_TYPE]: Base64.encodeURI(options[0].id),
        };
      }

      history.push({
        pathname: location.pathname,
        search: qs.stringify({
          ...query,
          ...search,
        }),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const handleOnClick = (id: string) => {
    setActiveFormat(id);

    history.push({
      pathname: location.pathname,
      search: qs.stringify({
        ...query,
        [BILLS_QUERY_PARAMS.EXPORT_TYPE]: Base64.encodeURI(id),
        ...(id !== GENERIC_PAYMENT_CSV.id && {
          [BILLS_QUERY_PARAMS.MARK_AS_PAID]: undefined,
        }),
      }),
    });
  };

  return loading ? (
    <SidepanelSkeleton />
  ) : (
    <div className={styles.container}>
      <div className={styles.format}>
        <Typography size="m" variant="body-content" tag="h4">
          Select a format
        </Typography>
        {options.map((item) => (
          <ExportOption
            id={item.id}
            key={item.id}
            title={item.title}
            iconColor={item.iconColor}
            description={item.description}
            comingSoon={item.comingSoon}
            active={activeFormat === item.id}
            handleOnClick={handleOnClick}
            bottom={item.bottom}
          />
        ))}
      </div>
      <div className={styles.filter}>
        <DateType
          loadingPeriods={loadingPeriods}
          paymentPeriods={paymentPeriods}
        />
      </div>
    </div>
  );
};

export default ExportBills;
