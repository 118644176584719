import React from "react";
import { IContentProps } from "../types";

const Content = ({ onnAddNewCategory, onImportCategory, hasValidToken, partnerName }: IContentProps) => {
  return (
    <section className="add-tax__content">
      <div className="option" onClick={onnAddNewCategory}>
        <h3 className="title">Add New Category</h3>
      </div>
      {hasValidToken && (
        <div className="option" onClick={onImportCategory}>
          <h3 className="title">Import from {partnerName}</h3>
        </div>
      )}
    </section>
  );
};

export default Content;
