import React, { forwardRef, useImperativeHandle, useMemo, useRef } from "react";
import { SelectProps } from "antd/lib/select";
import { useFormContext } from "react-hook-form";

import { useMutableData } from "API/useData";
import { API_URL } from "Views/Bills/V2/constants";
import Select from "Views/Bills/V2/components/FormGenerator/Select";

import { GetBaseAuthObject } from "utility";

interface CategoryOption {
  value: number;
  label: string;
  taxid: string;
}

const Category = forwardRef((props: SelectProps<any>, ref) => {
  const { onSelect, ...rest } = props;
  const selectRef = useRef(null);
  useImperativeHandle(ref, () => selectRef.current);
  const { setValue, formState, resetField } = useFormContext();
  const { defaultValues = null } = formState;
  const { billNumber = null, expenseCategoryID = null } = defaultValues;

  const { data, isValidating } = useMutableData(
    `${API_URL.userInfo}/${GetBaseAuthObject().userId}?organisation_id=${GetBaseAuthObject().orgId}`
  );

  const selectTax = (_value: any, option: CategoryOption) => {
    setValue("taxID", option.taxid);
  };

  const categories = useMemo(() => {
    if (data?.data?.payload?.categories?.length) {
      return data?.data?.payload?.categories.map((category) => {
        const categoryObj = {
          value: category.id,
          label: category.category_name,
          // custom attributes must be lowercase, this is warning from antd
          taxid: category.tax_id,
        } as CategoryOption;
        if (billNumber && category.id === +expenseCategoryID) {
          resetField("expenseCategoryID", {
            defaultValue: category.category_name,
          });
        }
        return categoryObj;
      });
    }

    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billNumber, expenseCategoryID, data]);

  return (
    <Select
      ref={selectRef}
      {...rest}
      showSearch
      loading={isValidating}
      filterOption={(input, option) => String(option?.label)?.toLowerCase().includes(input.toLowerCase())}
      options={categories}
      onSelect={selectTax}
    />
  );
});

export default Category;
