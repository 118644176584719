import { FETCH_RECEIPT_REQUEST, FETCH_RECEIPT_SUCCESS, FETCH_RECEIPT_FAILURE } from "../Actions/transactionAction";
import { userInitialState } from "../InitialState";

export const receiptActionReducer = (state = userInitialState, action) => {
  switch (action.type) {
    case FETCH_RECEIPT_REQUEST:
      return {
        ...state,
        data: action.data,
        loading: true,
      };
    case FETCH_RECEIPT_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
      };
    case FETCH_RECEIPT_FAILURE:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: true,
      };
    default:
      return state;
  }
};
