import React from "react";
import { Form } from "antd";

import { noOption } from "Modules/TransactionDetail/TransactionDetail";
import { IDetail, TransactionDetail } from "Modules/TransactionDetail";

import { INonExpenseTrxnsDetails } from "Views/Transaction/@types";

export const Details = ({ requesterName, team, trxnNumber, date, trxnType }: INonExpenseTrxnsDetails) => {
  const [form] = Form.useForm();

  const details: IDetail[] = [
    {
      id: "requester",
      key: "Requester",
      value: requesterName,
      subtitle: team,
    },
    { id: "trxn_number", key: "Transaction Number", value: trxnNumber },
    { id: "created_date", key: "Created Date & Time", value: date },
    { id: "trxn_type", key: "Transaction Type", value: trxnType },
  ];

  return (
    <Form form={form}>
      <TransactionDetail form={form} details={details} visibleInput={noOption} setFormValue={() => null} />
    </Form>
  );
};
