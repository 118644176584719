/**
 * FallbackScreen Component
 *
 * This component is displayed when something goes wrong in the application,
 * prompting the user to refresh the page or navigate back to the dashboard.
 *
 * @component
 * @example
 * <FallbackScreen />
 *
 * @returns {JSX.Element} Fallback screen UI with error message and a button to navigate back.
 */
import React from "react";
import { Button, Typography } from "@spenmo/splice";
import { DASHBOARD_PATH } from "constants/Routes.constants";

import styles from "./index.module.scss";

export const FallbackScreen: React.FC = (): JSX.Element => {
  const handleBackToHome = () => {
    window.location.href = DASHBOARD_PATH;
  };

  return (
    <div className={styles.wrapper}>
      <Typography variant="body-content" size="l" tag="h5" weight={600}>
        Something Went Wrong!
      </Typography>
      <Typography size="m" tag="p" variant="body-content">
        Please refresh the page to load data
      </Typography>
      <Button className={styles.button} onClick={handleBackToHome} variant="primary">
        Back to Home
      </Button>
    </div>
  );
};
