import * as React from "react";

import { CARD_LIMIT_TYPES, ISpendingLockWithNameAndId, SPENDING_LOCK_TYPES } from "Views/Card/types";

export type ActionType = {
  type: string;
  value?: string | boolean | number | ISpendingLockWithNameAndId[];
};

export type StateType = {
  cardTitle: string;
  cardColor: string;
  selectedTeamId: string;
  selectedTeamName: string;
  adminOrManagerInSelectedTeam: boolean;
  cardholderId: string;
  selectedApproverId: string;
  selectedApproverName: string;
  cardLimitType: CARD_LIMIT_TYPES;
  cardLimit: string;
  letMyManagerChooseLimit: boolean;
  spendingLockType: SPENDING_LOCK_TYPES;
  spendingLocks: ISpendingLockWithNameAndId[];
  expenseCategoryId: number;
  expenseCategoryName: string;
  addressLine1: string;
  addressLine2: string;
  country: string;
  pincode: string;
  noteToManager: string;
  tags: string[];
};

export const InitialState: StateType = {
  cardTitle: "",
  cardColor: "#131A2E",
  selectedTeamId: "",
  selectedTeamName: "",
  adminOrManagerInSelectedTeam: false,
  cardholderId: "",
  selectedApproverId: "",
  selectedApproverName: "",
  cardLimitType: CARD_LIMIT_TYPES.monthlyLimit,
  cardLimit: "",
  letMyManagerChooseLimit: false,
  spendingLockType: SPENDING_LOCK_TYPES.NONE,
  spendingLocks: [],
  expenseCategoryId: undefined,
  expenseCategoryName: "",
  addressLine1: "",
  addressLine2: "",
  country: "",
  pincode: "",
  noteToManager: "",
  tags: [],
};

export const Actions = {
  UPDATE_CARD_TITLE: "UPDATE_CARD_TITLE",
  UPDATE_CARD_COLOR: "UPDATE_CARD_COLOR",
  UPDATE_SELECTED_TEAM_ID: "UPDATE_SELECTED_TEAM_ID",
  UPDATE_SELECTED_TEAM_NAME: "UPDATE_SELECTED_TEAM_NAME",
  UPDATE_ADMIN_OR_MANAGER_IN_SELECTED_TEAM: "UPDATE_ADMIN_OR_MANAGER_IN_SELECTED_TEAM",
  UPDATE_CARD_HOLDER_ID: "UPDATE_CARD_HOLDER_ID",
  UPDATE_SELECTED_APPROVER_ID: "UPDATE_SELECTED_APPROVER_ID",
  UPDATE_SELECTED_APPROVER_NAME: "UPDATE_SELECTED_APPROVER_NAME",
  UPDATE_CARD_LIMIT_TYPE: "UPDATE_CARD_LIMIT_TYPE",
  UPDATE_CARD_LIMIT: "UPDATE_CARD_LIMIT",
  UPDATE_LET_MY_MANAGER_CHOOSE_LIMIT: "UPDATE_LET_MY_MANAGER_CHOOSE_LIMIT",
  UPDATE_SPENDING_LOCK_TYPE: "UPDATE_SPENDING_LOCK_TYPE",
  UPDATE_SPENDING_LOCKS: "UPDATE_SPENDING_LOCKS",
  UPDATE_EXPENSE_CATEGORY_ID: "UPDATE_EXPENSE_CATEGORY_ID",
  UPDATE_EXPENSE_CATEGORY_NAME: "UPDATE_EXPENSE_CATEGORY_NAME",
  UPDATE_ADDRESS_LINE_1: "UPDATE_ADDRESS_LINE_1",
  UPDATE_ADDRESS_LINE_2: "UPDATE_ADDRESS_LINE_2",
  UPDATE_COUNTRY: "UPDATE_COUNTRY",
  UPDATE_PINCODE: "UPDATE_PINCODE",
  UPDATE_NOTE_TO_MANAGER: "UPDATE_NOTE_TO_MANAGER",
  UPDATE_TAGS: "UPDATE_TAGS",
};

export const ActionMappedToStateFieldNames = {
  [Actions.UPDATE_CARD_TITLE]: "cardTitle",
  [Actions.UPDATE_CARD_COLOR]: "cardColor",
  [Actions.UPDATE_SELECTED_TEAM_ID]: "selectedTeamId",
  [Actions.UPDATE_SELECTED_TEAM_NAME]: "selectedTeamName",
  [Actions.UPDATE_ADMIN_OR_MANAGER_IN_SELECTED_TEAM]: "adminOrManagerInSelectedTeam",
  [Actions.UPDATE_CARD_HOLDER_ID]: "cardholderId",
  [Actions.UPDATE_SELECTED_APPROVER_ID]: "selectedApproverId",
  [Actions.UPDATE_SELECTED_APPROVER_NAME]: "selectedApproverName",
  [Actions.UPDATE_CARD_LIMIT_TYPE]: "cardLimitType",
  [Actions.UPDATE_CARD_LIMIT]: "cardLimit",
  [Actions.UPDATE_LET_MY_MANAGER_CHOOSE_LIMIT]: "letMyManagerChooseLimit",
  [Actions.UPDATE_SPENDING_LOCK_TYPE]: "spendingLockType",
  [Actions.UPDATE_SPENDING_LOCKS]: "spendingLocks",
  [Actions.UPDATE_EXPENSE_CATEGORY_ID]: "expenseCategoryId",
  [Actions.UPDATE_EXPENSE_CATEGORY_NAME]: "expenseCategoryName",
  [Actions.UPDATE_ADDRESS_LINE_1]: "addressLine1",
  [Actions.UPDATE_ADDRESS_LINE_2]: "addressLine2",
  [Actions.UPDATE_COUNTRY]: "country",
  [Actions.UPDATE_PINCODE]: "pincode",
  [Actions.UPDATE_NOTE_TO_MANAGER]: "noteToManager",
  [Actions.UPDATE_TAGS]: "tags",
};

const createOrRequestStateReducer = (state: StateType, action: ActionType) => {
  if (
    (action.type === Actions.UPDATE_ADMIN_OR_MANAGER_IN_SELECTED_TEAM ||
      action.type === Actions.UPDATE_LET_MY_MANAGER_CHOOSE_LIMIT) &&
    typeof action?.value === "boolean"
  ) {
    return { ...state, [ActionMappedToStateFieldNames[action.type]]: action?.value };
  } else if (action.type === Actions.UPDATE_EXPENSE_CATEGORY_ID && typeof action?.value === "number") {
    return { ...state, expenseCategoryId: action?.value };
  } else if (action.type === Actions.UPDATE_SPENDING_LOCKS && typeof action?.value === "object") {
    return { ...state, spendingLocks: action?.value };
  } else if (!!ActionMappedToStateFieldNames[action.type]) {
    return { ...state, [ActionMappedToStateFieldNames[action.type]]: action?.value };
  } else {
    return state;
  }
};

export type useCreateOrRequestStateType = (initialState?: StateType) => [StateType, React.Dispatch<ActionType>];

export const useCreateOrRequestState: useCreateOrRequestStateType = (initialState: StateType = InitialState) => {
  const [state, dispatch] = React.useReducer(createOrRequestStateReducer, initialState);
  return [state, dispatch];
};
