import {
  FETCH_ACCOUNTS_REQUEST,
  FETCH_ACCOUNTS_SUCCESS,
  FETCH_ACCOUNTS_FAILURE,
  FETCH_SELECT_ACCOUNT_REQUEST,
  FETCH_SELECT_ACCOUNT_SUCCESS,
  FETCH_SELECT_ACCOUNT_FAILURE,
  FETCH_XERO_CATEGORIES_REQUEST,
  FETCH_XERO_CATEGORIES_SUCCESS,
  FETCH_XERO_CATEGORIES_FAILURE,
  SET_XERO_CATEGORIES_REQUEST,
  SET_XERO_CATEGORIES_SUCCESS,
  SET_XERO_CATEGORIES_FAILURE,
  FETCH_XERO_INTEGRATION_DETAILS_REQUEST,
  FETCH_XERO_INTEGRATION_DETAILS_SUCCESS,
  FETCH_XERO_INTEGRATION_DETAILS_FAILURE,
} from "../Actions/xeroAccountAction";
import { userInitialState } from "../InitialState";

export const xeroAccountReducer = (state = userInitialState, action) => {
  switch (action.type) {
    case FETCH_XERO_INTEGRATION_DETAILS_REQUEST:
      return {
        ...state,
        integrationData: null,
        integrationDataLoading: true,
        integrationError: false,
      };
    case FETCH_XERO_INTEGRATION_DETAILS_SUCCESS:
      return {
        ...state,
        integrationData: action.data,
        integrationDataLoading: false,
        integrationError: false,
      };
    case FETCH_XERO_INTEGRATION_DETAILS_FAILURE:
      return {
        ...state,
        integrationDataLoading: false,
        integrationData: action.data,
        integrationError: true,
      };
    case FETCH_ACCOUNTS_REQUEST:
      return {
        ...state,
        data: action.data,
        loading: true,
        error: false,
      };
    case FETCH_ACCOUNTS_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
        error: false,
      };
    case FETCH_ACCOUNTS_FAILURE:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: true,
      };
    case FETCH_SELECT_ACCOUNT_REQUEST:
      return {
        ...state,
        accountData: action.data,
        accountLoading: true,
        accountError: false,
      };
    case FETCH_SELECT_ACCOUNT_SUCCESS:
      return {
        ...state,
        accountData: action.data,
        accountLoading: false,
        accountError: false,
      };
    case FETCH_SELECT_ACCOUNT_FAILURE:
      return {
        ...state,
        accountLoading: false,
        accountData: action.data,
        accountError: true,
      };

    case FETCH_XERO_CATEGORIES_REQUEST:
      return {
        ...state,
        xeroCodeData: action.data,
        xeroCodeLoading: true,
        xeroCodeError: false,
      };
    case FETCH_XERO_CATEGORIES_SUCCESS:
      return {
        ...state,
        xeroCodeData: action.data,
        xeroCodeLoading: false,
        xeroCodeError: false,
      };
    case FETCH_XERO_CATEGORIES_FAILURE:
      return {
        ...state,
        xeroCodeLoading: false,
        xeroCodeData: action.data,
        xeroCodeError: true,
      };

    case SET_XERO_CATEGORIES_REQUEST:
      return {
        ...state,
        xeroCategoryData: action.data,
        xeroCategoryLoading: true,
        xeroCategoryError: false,
      };
    case SET_XERO_CATEGORIES_SUCCESS:
      return {
        ...state,
        xeroCategoryData: action.data,
        xeroCategoryLoading: false,
        xeroCategoryError: false,
      };
    case SET_XERO_CATEGORIES_FAILURE:
      return {
        ...state,
        xeroCategoryLoading: false,
        xeroCategoryData: action.data,
        xeroCategoryError: true,
      };
    default:
      return state;
  }
};
