import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Toaster } from "Modules/toaster";
import OverlayLoader from "Modules/overlayLoader";

import AddEmployeeForm from "Views/Employee/Create/Page/Manually/AddEmployeeForm";

import { addEmployeesManually, getEmployeeRoles, getPhoneCodes } from "Redux/Actions";
import { resetAddEmpData } from "Redux/Actions/OnBoarding/addEmployeesAction";
import { splitOnDots } from "utility";

const AddEmpDetails = ({ next, toggleList, bringEmployeesLater }) => {
  const dispatch = useDispatch();
  const [showToaster, setShowToaster] = useState(false);
  const { employeeRoles, phoneCodes, addEmpDetails, orgCurrency } = useSelector((state) => ({
    employeeRoles: state.employeeRoleReducer?.data?.payload,
    phoneCodes: state.phoneCodeReducer?.data?.payload,
    addEmpDetails: state?.addEmployeeReducer,
    orgCurrency: state?.userInfoReducer?.data?.payload?.user?.org_phone_country_code,
  }));

  useEffect(() => {
    dispatch(resetAddEmpData({}));
  }, [bringEmployeesLater, dispatch]);

  const addEmployees = (values) => {
    const requestURL = bringEmployeesLater
      ? "/ms/spm-organization/v1/employee/bulk"
      : "/ms/spm-organization/v1/onboarding/employee-manual";
    dispatch(addEmployeesManually(values, requestURL));
  };

  useEffect(() => {
    if (addEmpDetails?.error) {
      setShowToaster(true);
    }
    if (addEmpDetails?.data?.data?.payload?.isSuccess && !bringEmployeesLater) {
      setShowToaster(true);
      const SHOW_SUCCESS_TOASTER = 3000;
      setTimeout(() => {
        next();
      }, SHOW_SUCCESS_TOASTER);
    }
  }, [addEmpDetails, bringEmployeesLater, next]);

  useEffect(() => {
    dispatch(getEmployeeRoles("company"));
    dispatch(getPhoneCodes());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (bringEmployeesLater && addEmpDetails?.data?.data?.payload?.isSuccess) {
    toggleList();
  }

  return (
    <div className={"add-employee-details"}>
      <h1 className={"title"}>Add employees manually</h1>
      <p className={"sub-title"}>
        Each of the added employee(s) will be notified by email once we finish creating accounts for them.
      </p>
      <AddEmployeeForm
        orgCurrency={orgCurrency}
        action={addEmployees}
        roles={employeeRoles || []}
        phoneCodes={phoneCodes || []}
      />
      <Toaster
        className={`onboarding--toaster ${bringEmployeesLater ? "bring-emp-later" : ""}`}
        visible={showToaster}
        status={addEmpDetails?.error ? "error" : "success"}
        message={splitOnDots(
          addEmpDetails?.data?.response?.data?.error?.message || addEmpDetails?.data?.data?.payload?.message
        )?.map((error, index) => (
          <p key={index}>
            {error} <br></br>
          </p>
        ))}
        closeFunc={() => setShowToaster(false)}
      />
      {addEmpDetails?.loading && <OverlayLoader show />}
    </div>
  );
};

AddEmpDetails.propTypes = {
  next: PropTypes.func,
  toggleList: PropTypes.func,
  bringEmployeesLater: PropTypes.bool,
};

export default AddEmpDetails;
