import * as React from "react";

import Icon from "Modules/icons";
import Chip, { CHIP_STATUS } from "Modules/DS/Chip";
import { vendor_logo as masterCard, visaIconSmall } from "assets/img";
import { getIsCardVendorNium } from "customHooks/Card";
import { Row } from "antd";
import { getMerchantLocks } from "./merchantLocksConfig";
import { ICompactCardProps } from "Modules/CompactCard/types";
import styles from "./CompactCard.module.scss";
import { CARD_STATUS } from "Views/Card/types";
import classNames from "classnames";

const CompactCard = ({
  cardName,
  cardLastFour,
  teamName,
  cardType,
  cardColor,
  action,
  merchantNames = [],
  cardStatus,
}: ICompactCardProps) => {
  const isCardVendorNium = getIsCardVendorNium();

  const cardStatusChipProps = {
    [CARD_STATUS.DISABLED]: {
      title: "Disabled",
      status: CHIP_STATUS.FAILED,
    },
    [CARD_STATUS.NOT_ACTIVATED]: {
      title: "Not Activated",
      status: CHIP_STATUS.NEUTRAL,
    },
    [CARD_STATUS.IN_PROGRESS]: {
      title: "In Progress",
      status: CHIP_STATUS.NEUTRAL,
    },
  };

  const getCardStatusChip = () => {
    if (
      cardStatus === CARD_STATUS.DISABLED ||
      cardStatus === CARD_STATUS.NOT_ACTIVATED ||
      cardStatus === CARD_STATUS.IN_PROGRESS
    ) {
      return <Chip.Status {...cardStatusChipProps[cardStatus]} />;
    }
    return null;
  };

  const statusOverlayClassName = classNames(styles["status-overlay"], {
    [styles["chip-justify-center"]]: cardStatus === CARD_STATUS.NOT_ACTIVATED || cardStatus === CARD_STATUS.IN_PROGRESS,
  });

  return (
    <div onClick={action} className={styles.card} style={{ background: cardColor }}>
      <div className={styles["sec-1"]}>
        {getMerchantLocks(merchantNames, cardName, cardColor)}
        <h5 className={styles["card-name"]}>{cardName}</h5>
        <p className={styles["card-num"]}>**** {cardLastFour}</p>
      </div>
      <div className={styles["sec-2"]}>
        <p className={styles["team-name"]}>{teamName}</p>
        <Row justify="space-between">
          <p className={styles["card-type"]}>{cardType}</p>
          <Icon src={isCardVendorNium ? visaIconSmall : masterCard} alt={isCardVendorNium ? "visa" : "mastercard"} />
        </Row>
      </div>
      {cardStatus !== CARD_STATUS.ENABLED && <div className={statusOverlayClassName}>{getCardStatusChip()}</div>}
    </div>
  );
};

export default CompactCard;
