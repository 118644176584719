import React from "react";

const EmptyIcon = () => {
  return (
    <svg width="112" height="70" viewBox="0 0 112 70" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="empty-img-default">
        <g id="Group">
          <path
            id="Vector"
            d="M56 69.2272C86.9279 69.2272 112 64.4475 112 58.5516C112 52.6556 86.9279 47.876 56 47.876C25.0721 47.876 0 52.6556 0 58.5516C0 64.4475 25.0721 69.2272 56 69.2272Z"
            fill="#F5F5F7"
            fillOpacity="0.8"
          />
          <g id="Vector_2" filter="url(#filter0_i_13263_31886)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M100.802 27.189C100.802 27.189 100.802 27.1889 100.802 27.1888L81.0402 2.37505C80.092 1.20704 78.7059 0.5 77.2464 0.5H34.7572C33.2985 0.5 31.9125 1.20704 30.9642 2.37505L12.3117 25.797C11.5939 26.6983 11.2031 27.8163 11.2031 28.9684V35.0617C11.2031 37.8733 13.4824 40.1526 16.294 40.1526H95.7121C98.5237 40.1526 100.803 37.8733 100.803 35.0617V27.1895C100.803 27.1892 100.803 27.189 100.802 27.189Z"
              fill="url(#paint0_linear_13263_31886)"
            />
          </g>
          <g id="Vector_3" filter="url(#filter1_i_13263_31886)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M100.62 56.9864C99.9803 59.5744 97.7319 61.5034 95.0574 61.5034H16.9487C14.2742 61.5034 12.0258 59.5735 11.3865 56.9864C11.2646 56.4932 11.203 55.9865 11.2031 55.4779V30.7539C11.2031 28.7858 12.7986 27.1903 14.7668 27.1903H32.9417C35.3428 27.1903 37.2781 29.2533 37.2781 31.7578V31.7915C37.2781 34.2953 39.2358 36.317 41.6369 36.317H70.3692C72.7703 36.317 74.728 34.2767 74.728 31.7722V31.762C74.728 29.2575 76.6633 27.1895 79.0644 27.1895H97.2393C99.2075 27.1895 100.803 28.7849 100.803 30.7531V55.4788C100.803 55.9987 100.739 56.5035 100.62 56.9864Z"
              fill="url(#paint1_linear_13263_31886)"
            />
          </g>
        </g>
      </g>
      <defs>
        <filter
          id="filter0_i_13263_31886"
          x="11.2031"
          y="0.5"
          width="89.6016"
          height="40.6523"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="0.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.8 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_13263_31886" />
        </filter>
        <filter
          id="filter1_i_13263_31886"
          x="11.2031"
          y="27.1895"
          width="89.6016"
          height="35.3135"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="0.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_13263_31886" />
        </filter>
        <linearGradient
          id="paint0_linear_13263_31886"
          x1="56.0026"
          y1="40.4999"
          x2="56.0026"
          y2="-77.5001"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DCE1E9" />
          <stop offset="1" stopColor="#F4F8FB" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_13263_31886"
          x1="56.0031"
          y1="27.1895"
          x2="56.0031"
          y2="97.5004"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F4F8FB" />
          <stop offset="1" stopColor="#D9DFE7" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default EmptyIcon;
