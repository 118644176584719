import React from "react";
import classNames from "classnames";

import { IDataTableProps, DATA_TABLE_PADDING, DATA_TABLE_SIZE_TYPES } from "Modules/DS/DataTable/types.d";

import "./index.scss";

const DataTable = ({
  children,
  className = "",
  size = DATA_TABLE_SIZE_TYPES.SMALL,
  padding = DATA_TABLE_PADDING.DEFAULT,
}: IDataTableProps) => {
  return (
    <div className="data-table-container">
      <div
        className={classNames(
          "data-table-content",
          { [size]: size },
          { [padding]: padding },
          { [className]: className }
        )}
      >
        {children}
      </div>
    </div>
  );
};

export default DataTable;

export * from "./Components";

export * from "./types.d";
