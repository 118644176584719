export const APPROVAL_FLOW_EVENTS = {
  approvalFlowConditionWarningDisplayed: `Approval Flow Condition Warning Displayed`,
  approvalFlowDeleted: `Approval Flow Deleted`,
  approvalFlowDuplicated: `Approval Flow Duplicated`,
  approvalFlowInvalidWarningDisplayed: `Approval Flow Invalid Warning Displayed`,
  approvalFlowPageLoaded: `Approval Flow Page Loaded`,
  approvalFlowPublished: `Approval Flow Published`,
  approvalFlowExistingSettingsEdited: `Approval Existing Flow Settings Edited`,
  approvalFlowNewSettingsEdited: `Approval New Flow Settings Edited`,
};
