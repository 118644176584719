import React from "react";
import PieChart from "Splice/Charts/PieChart";
import { Table } from "antd";
import { getCategoryColumns } from "../Elements/CategoryDistribution/CategoryColumns";
import s from "../Analytics.module.scss";
import { CategoriesShimmer } from "./Shimmer";
import { EmptyState } from "Views/Analytics/EmptyState";
import { IAnalyticsFilter, IRootDataType, ISpendingCategoriesData } from "Views/Analytics/@types";
import classNames from "classnames";
import { AnalyticsCardTitle } from "../Analytics";

interface ISpendingCategory {
  filter: IAnalyticsFilter;
  selectedCategory: any;
  spendingCategoriesLoading: boolean;
  spendingCategoriesData: ISpendingCategoriesData[];
  categoryPieChart: {
    category: any;
    amount: any;
    color: any;
  }[];
  handleSelectChart: (data: any) => void;
  handleApplyDeeplink: (rootData: IRootDataType) => void;
}

export const Categories = ({
  filter,
  selectedCategory,
  spendingCategoriesData,
  spendingCategoriesLoading,
  categoryPieChart,
  handleSelectChart,
  handleApplyDeeplink,
}: ISpendingCategory) => {
  if (spendingCategoriesLoading) {
    return <CategoriesShimmer loading={spendingCategoriesLoading} />;
  }

  if (!spendingCategoriesLoading && Array.isArray(categoryPieChart) && !categoryPieChart.length) {
    return <EmptyState />;
  }

  const handleHoverChart = (data: any) => {
    handleSelectChart(data);
  };

  const onClickRow = (data: ISpendingCategoriesData): void => {
    if (data?.leftColumn?.category === "Others") return;
    handleApplyDeeplink({
      from: AnalyticsCardTitle.CATEGORIES,
      data: {
        selectedCategory: data?.leftColumn?.category,
      },
    });
  };

  return (
    <>
      <PieChart data={categoryPieChart} onHover={handleHoverChart} />
      <Table
        columns={getCategoryColumns(selectedCategory, filter)}
        dataSource={spendingCategoriesData}
        pagination={false}
        scroll={{ y: "280px" }}
        className={classNames(s.tableCSS, "analytics-table")}
        onRow={(record) => ({
          onClick: () => onClickRow(record),
        })}
        rowClassName={(record) => (record?.disabled ? "disabled" : "")}
      />
    </>
  );
};
