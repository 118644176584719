import React, { useContext } from "react";
import Searchbar from "Modules/search";
import { debounce } from "utility";

import styles from "./search.module.scss";
import {
  GET_TRXN_REQUEST_PARAMS,
  ITrxnFilterContext,
  ITrxnPaginationContext,
} from "Views/Transaction/@types";
import {
  TrxnFilterContext,
  TrxnPaginationContext,
} from "Views/Transaction/Context";
import { Tooltip } from "@spenmo/splice";

const DEBOUNCE_DELAY = 500;

export const SearchBar = () => {
  const { resetCurrentPage } = useContext<ITrxnPaginationContext>(
    TrxnPaginationContext,
  );
  const { resetTrxnPaginationParams, onApplyFilter, filter } =
    useContext<ITrxnFilterContext>(TrxnFilterContext);

  const handleSearch = debounce((value: string) => {
    if (!value && !filter[GET_TRXN_REQUEST_PARAMS.ACCOUNTING_SEARCH]) {
      return;
    }
    onApplyFilter({
      accounting_search: value,
      ...resetTrxnPaginationParams,
    });
    resetCurrentPage();
  }, DEBOUNCE_DELAY);

  return (
    <Tooltip
      title="Search by full invoice or transaction number"
      placement="top-start"
    >
      <div className={styles.search}>
        <Searchbar
          placeholder="Search for a transaction"
          getKeyUpValue={handleSearch}
          clearSearch={
            filter[GET_TRXN_REQUEST_PARAMS.ACCOUNTING_SEARCH] === "" ||
            filter[GET_TRXN_REQUEST_PARAMS.ACCOUNTING_SEARCH] === null
          }
        />
      </div>
    </Tooltip>
  );
};
