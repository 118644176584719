import * as React from "react";
import classNames from "classnames";

import "./FlowItem.scss";
import { threeDots, trash } from "assets/img";
import { Popover } from "antd";

export enum FlowItemType {
  Default = "default", // shadowed icon, no content bg
  Block = "block", // plain icon, white content bg
  Sequence = "sequence", // colored icon, white content bg
  Dashed = "dashed", // dashed icon, no content bg
}

export enum LineType {
  Active = "active",
  Inactive = "inactive",
}

interface FlowItemProps {
  icon: React.ReactElement | string | number;
  title?: string;
  subtitle?: string;
  children?: React.ReactElement | string;
  style?: FlowItemType;
  lines?: {
    in?: LineType;
    out?: LineType;
  };
  onDelete?: () => void;
  disabled?: boolean;
}

const FlowItem: React.FC<FlowItemProps> = ({
  icon,
  title,
  subtitle,
  children,
  style = FlowItemType.Default,
  lines = {},
  onDelete,
  disabled,
}: FlowItemProps): JSX.Element => {
  const flowItemClassNames = classNames(
    "flow-item",
    {
      "line-in": !!lines.in,
      "line-in--active": lines.in === LineType.Active,
      "line-out": !!lines.out,
      "line-out--active": lines.out === LineType.Active,
      disabled,
    },
    style
  );
  const containerClassNames = classNames("flow-item__container", style);

  const deletePopover = (
    <div className="flow-item__menu__delete-btn" onClick={onDelete}>
      <img src={trash} />
      Delete this step
    </div>
  );

  return (
    <li className={flowItemClassNames}>
      <div className={containerClassNames}>
        <div className="flow-item__icon">{icon}</div>
        <div className="flow-item__title">{title}</div>
        <div className="flow-item__subtitle">{subtitle}</div>
        <div className="flow-item__children">{children}</div>
        {onDelete && (
          <Popover
            trigger="click"
            content={deletePopover}
            overlayClassName={"flow-item__menu__popover"}
            align={{
              points: ["tl", "tr"],
              targetOffset: [100, 0],
            }}
          >
            <div className="flow-item__menu">
              <img src={threeDots} />
            </div>
          </Popover>
        )}
      </div>
    </li>
  );
};

export default FlowItem;
