import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { selectTreatmentValue } from "@splitsoftware/splitio-redux";

import { GetBaseAuthObject, GetOrgId } from "utility";

import * as Actions from "Redux/Actions";
import { getOrgConfigsFunc, getOrgDetailFunc } from "Redux/Actions";
import { SPLIT_NAMES, SPLIT_TREATMENT_TYPES } from "Redux/splitio/constants";

import { USER_ROLE } from "constants/Team.constant";

import { CASBIN_PAGES } from "Permission/Pages";
import { TRANSACTION_PAGE } from "Permission/Actions";
import { fetchPermissionForClassComp } from "Permission/PermissionForClassComp";

import NewTransactions from "Views/Transaction";
import TransactionsListing from "./TransactionsListing";

import "./Transactions.scss";

class Transactions extends React.Component {
  static propTypes = {
    syncData: PropTypes.object,
    syncError: PropTypes.bool,
    syncLoading: PropTypes.bool,
    checkXeroConnection: PropTypes.func,
    syncTransaction: PropTypes.func,
    accountingPartnerAuthReducer: PropTypes.object,
    userData: PropTypes.object,
    pendingApprovalData: PropTypes.object,
    getPendingApprovals: PropTypes.func,
    teamData: PropTypes.object,
    selectedTeamId: PropTypes.string,
    getScheduledTransactions: PropTypes.func,
    scheduledTransactions: PropTypes.object,
    editScheduledTransaction: PropTypes.func,
    editScheduledTransactionData: PropTypes.object,
    stopScheduledTransactions: PropTypes.func,
    stopScheduledTransactionsData: PropTypes.object,
    clearEditScheduledTransaction: PropTypes.object,
    clearStopScheduledTransactions: PropTypes.object,
    b2bOrgDetails: PropTypes.object,
    getOrgConfigs: PropTypes.func,
    getOrgDetails: PropTypes.func,
  };

  state = {
    syncToaster: false,
    isAllowedXeroSync: false,
  };

  componentDidMount() {
    const { checkXeroConnection } = this.props;
    checkXeroConnection();
    const permissionParam = [{ object: CASBIN_PAGES.TRANSACTION_PAGE, action: TRANSACTION_PAGE.SYNC_TO_ZERO_BUTTON }];
    fetchPermissionForClassComp(permissionParam).then((res) => this.setState({ isAllowedXeroSync: res }));
  }

  render() {
    const {
      syncData,
      syncError,
      accountingPartnerAuthReducer,
      syncTransaction,
      pendingApprovalData,
      userData,
      getPendingApprovals,
      getScheduledTransactions,
      scheduledTransactions,
      editScheduledTransaction,
      editScheduledTransactionData,
      stopScheduledTransactions,
      stopScheduledTransactionsData,
      clearEditScheduledTransaction,
      clearStopScheduledTransactions,
      b2bOrgDetails,
      getOrgConfigs,
      getOrgDetails,
    } = this.props;
    const { isAllowedXeroSync } = this.state;

    const newTransactionTypeActive = selectTreatmentValue(
      this.props.splitio,
      SPLIT_NAMES.newTransactionType,
      GetBaseAuthObject().orgId
    );

    const isCardsTrxnsTabEnabled =
      selectTreatmentValue(this.props.splitio, SPLIT_NAMES.cardsTrxns, GetOrgId()) === SPLIT_TREATMENT_TYPES.ON;

    // Condition is to give access to only Admin and Accountant
    const isNotAdminOrAccountant =
      userData?.role !== USER_ROLE.ADMIN && userData?.company_role !== USER_ROLE.ACCOUNTANT;

    const { search } = this.props.location;

    const checkParams =
      !search ||
      (search?.search("subtab=all") === -1 && search?.search("tab=all") !== -1) ||
      search?.search("tab=pending") !== -1 ||
      (isCardsTrxnsTabEnabled && search?.search("tab=cards") !== -1);

    if (checkParams) {
      return <NewTransactions />;
    }

    return (
      <TransactionsListing
        syncTransaction={syncTransaction}
        syncToaster={() => this.setState({ syncToaster: true })}
        xeroAuthdata={accountingPartnerAuthReducer}
        syncData={syncData}
        syncError={syncError}
        pendingApprovalCount={pendingApprovalData?.payload?.pagination?.totalData || 0}
        limitView={!isNotAdminOrAccountant}
        newTransactionTypeActive={newTransactionTypeActive === SPLIT_TREATMENT_TYPES.ON}
        isAdmin={userData?.role === USER_ROLE.ADMIN}
        isSyncButtonAccessAllowed={isAllowedXeroSync && accountingPartnerAuthReducer?.payload?.has_valid_token}
        getPendingApprovals={getPendingApprovals}
        scheduledTransactions={scheduledTransactions}
        getScheduledTransactions={getScheduledTransactions}
        editScheduledTransaction={editScheduledTransaction}
        editScheduledTransactionData={editScheduledTransactionData}
        stopScheduledTransactions={stopScheduledTransactions}
        stopScheduledTransactionsData={stopScheduledTransactionsData}
        clearEditScheduledTransaction={clearEditScheduledTransaction}
        clearStopScheduledTransactions={clearStopScheduledTransactions}
        b2bOrgDetails={b2bOrgDetails}
        getOrgConfigs={getOrgConfigs}
        getOrgDetails={getOrgDetails}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  syncData: state.syncTransactionsReducer.data,
  syncLoading: state.syncTransactionsReducer.loading,
  syncError: state.syncTransactionsReducer.error,
  accountingPartnerAuthReducer: state.accountingPartnerAuthReducer.data,
  pendingApprovalData: state.getPendingApprovalsReducer.data,
  userData: state.userInfoReducer,
  selectedTeamId: state.teamReducer.selectedTeamId, //not used
  teamData: state.teamReducer.data, //not used
  scheduledTransactions: state.getScheduledTransactionsReducer,
  editScheduledTransactionData: state.editScheduledTransactionReducer,
  stopScheduledTransactionsData: state.stopScheduledTransactionsReducer,
  b2bOrgDetails: state.b2bOrgDetailReducer,
  splitio: state.splitio,
});

const mapDispatchToProps = {
  syncTransaction: Actions.syncXeroFunc,
  checkXeroConnection: Actions.accountingPartnerAuth,
  getPendingApprovals: Actions.getPendingApprovalsFunc,
  getScheduledTransactions: Actions.getScheduledTransactionsFunc,
  editScheduledTransaction: Actions.editScheduledTransactionFunc,
  stopScheduledTransactions: Actions.stopScheduledTransactionsFunc,
  clearEditScheduledTransaction: Actions.clearEditScheduledTransaction,
  clearStopScheduledTransactions: Actions.clearStopScheduledTransactions,
  getOrgConfigs: getOrgConfigsFunc,
  getOrgDetails: getOrgDetailFunc,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Transactions));
