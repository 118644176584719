import * as React from "react";
import SimpleFilter, { ISimpleFilter } from "Modules/SimpleFilter";
import { CARD_LIST_TABS } from "Views/Card/List/types";
import CardholderFilter from "Views/Card/List/Filters/Components/CardholderFilter";
import CardFilterMore from "Views/Card/List/Filters/Components/More";
import { trackEvent } from "utility/analytics";
import { filterEvents } from "Views/Card/Analytics";
import { DEFAULT_SORT, getSortOptions, TYPE_OPTIONS } from "Views/Card/List/Filters/Constants";
import "./index.scss";
import { useShowClearAll } from "customHooks/Card";
import { generateOptions } from "Views/Card/List/Filters/Utilities";

type IFilterProps = {
  action: (key: string, value: any) => void;
  clearAllFilter: () => void;
  cardNumbers: string[];
  cardOwnerNames: string[];
  teamList: any;
  showCardownerFilter: boolean;
  cardListActiveTab: CARD_LIST_TABS;
  currentFilters: any;
};

const Filters = ({
  action,
  clearAllFilter,
  cardNumbers,
  cardOwnerNames,
  teamList,
  showCardownerFilter,
  cardListActiveTab,
  currentFilters,
}: IFilterProps) => {
  const [sortBy, setSortBy] = React.useState(DEFAULT_SORT[0]);
  const sortOptions = getSortOptions(cardListActiveTab === CARD_LIST_TABS.CARD_LIST);
  const { search_filters = {} } = currentFilters;

  React.useEffect(() => {
    setSortBy(DEFAULT_SORT[0]);
  }, [cardListActiveTab]);

  const teamListLabel = React.useMemo(() => {
    const result: Array<string> = [];
    teamList.forEach((item) => {
      const foundIndex = currentFilters.team_id.findIndex((val) => val === item.value);
      if (foundIndex !== -1) result[foundIndex] = item.label;
    });
    return result;
  }, [teamList, currentFilters.team_id]);

  const typeLabel = React.useMemo(() => {
    if (search_filters.is_physical == null) return "";
    if (search_filters.is_physical) return TYPE_OPTIONS[1].label;
    return TYPE_OPTIONS[0].label;
  }, [search_filters.is_physical]);

  const showClearAll = useShowClearAll({ currentFilters, sortBy });

  const handleClearAllFilter = () => {
    clearAllFilter();
    setSortBy(DEFAULT_SORT[0]);
    trackEvent(filterEvents.CLEAR_ALL);
  };

  const returnSingleValue = (value) => {
    if (value.length === 1) {
      return value[0];
    }
  };

  const getCardholderFilter = () => {
    const props = {
      text: "Cardholder",
      list: generateOptions(cardOwnerNames),
      isMultiSelect: true,
      hasSearch: true,
      hasClearButton: true,
      action: (value) => {
        trackEvent(filterEvents.CARD_HOLDER, { "card holder name": value });
        action("cardholder_name", value);
      },
    };
    if (showCardownerFilter) {
      const cardHolderName = currentFilters?.search_filters?.cardholder_name;
      return cardListActiveTab === CARD_LIST_TABS.CARD_LIST ? (
        <CardholderFilter {...props} searchFilters={currentFilters?.search_filters} />
      ) : (
        <SimpleFilter
          {...props}
          defaultValue={[]}
          hideActive={!cardHolderName}
          labels={cardHolderName}
          values={cardHolderName}
        />
      );
    }
    return null;
  };

  const getCardTeamFilter = () => {
    let props: ISimpleFilter = {
      text: "Budget",
      list: teamList,
      defaultValue: [],
      isMultiSelect: true,
      hasClearButton: true,
      action: (value) => {
        trackEvent(filterEvents.TEAM_NAME, { "team name": value });
        action("team", value);
      },
      hasSearch: false,
      hideActive: !currentFilters?.["team_id"]?.length,
      labels: teamListLabel,
      values: currentFilters?.["team_id"],
    };
    return <SimpleFilter {...props} />;
  };

  const getCardTypeFilter = () => {
    let props: ISimpleFilter = {
      text: "Type",
      list: TYPE_OPTIONS,
      defaultValue: [],
      isMultiSelect: false,
      action: (value) => {
        const cardType = Array.isArray(value) && value[0] ? "physical" : "virtual";
        trackEvent(filterEvents.CARD_TYPE, { "card type": cardType });
        action("is_physical", returnSingleValue(value));
      },
      hasClearButton: true,
      hideActive: search_filters?.is_physical == null,
      values: search_filters?.is_physical,
      labels: typeLabel,
    };
    return <SimpleFilter {...props} />;
  };

  const getSorterEventName = (key: string, value: string) => {
    switch (key) {
      case "created_at": {
        return value === "asc" ? filterEvents.SORT_OLD_TO_NEW : filterEvents.SORT_NEW_TO_OLD;
      }
      case "name": {
        return value === "asc" ? filterEvents.SORT_BY_NAME_A_TO_Z : filterEvents.SORT_BY_NAME_Z_TO_A;
      }
      case "last_transaction": {
        return filterEvents.SORT_BY_LAST_TRANSACTION;
      }
      default: {
        return "";
      }
    }
  };
  const trackSorter = (data: string) => {
    const filterData = data?.split(":");
    if (Array.isArray(filterData)) {
      const key = filterData[0];
      const value = filterData[1];
      const eventName = getSorterEventName(key, value);
      eventName && trackEvent(eventName);
    }
  };

  return (
    <>
      {getCardholderFilter()}
      {getCardTeamFilter()}
      {getCardTypeFilter()}
      {cardListActiveTab === CARD_LIST_TABS.CARD_LIST && (
        <CardFilterMore
          cardNumbers={generateOptions(cardNumbers)}
          action={action}
          searchFilters={currentFilters?.search_filters}
        />
      )}

      <SimpleFilter
        text="Sort"
        list={sortOptions}
        defaultValue={DEFAULT_SORT}
        hideActive={sortBy !== DEFAULT_SORT[0] ? false : true}
        values={[sortBy]}
        action={(value) => {
          setSortBy(value[0]);
          action("ordering", returnSingleValue(value));
          trackSorter(value[0]);
        }}
        onlyShowText
      />

      {showClearAll && (
        <button className="card-filter__clear-all" onClick={handleClearAllFilter}>
          Clear all filters
        </button>
      )}
    </>
  );
};

export default Filters;
