import { createContext } from "react";

import { KYX_MODAL_TYPES } from "Views/KYX/Constants";
import { IKYXModalContext, IKYXModalState } from "Views/KYX/@types";

/**
 * The initial state for the KYX Modal.
 *
 * @property {KYX_MODAL_TYPES.NONE} type - The type of KYX modal.
 * @property {false} visible - The visibility status of the modal.
 * @property {Object} payload - The payload for the modal.
 */
export const initialKYXModalState: IKYXModalState = {
  type: "NONE" as KYX_MODAL_TYPES.NONE,
  visible: undefined,
  payload: {},
};

/**
 * The initial context for the KYX Modal.
 *
 * @property {Function} resetModal - A function that resets the modal.
 * @property {Function} toggleModal - A function that toggles the modal's visibility.
 * @property {Function} setModal - A function that sets the modal's state.
 * @property {IKYXModalState} modal - The current modal state.
 */
export const initialKYXModalContext: IKYXModalContext = {
  resetModal: () => null,
  toggleModal: () => null,
  setModal: () => null,
  modal: initialKYXModalState,
};

/**
 * A context object representing the KYX modal state.
 * @context
 * @type {IKYXModalContext}
 */
export const KYXModalContext: React.Context<IKYXModalContext> = createContext<IKYXModalContext>(initialKYXModalContext);
