import React, { useState } from "react";
import { ITransaction } from "../type";
import { IDropdownCell } from "Views/Transaction/@types";
import { useCategoryUpdate } from "Views/Transaction/All/Update";
import { editNotAllowed, includesReversalTrxns } from "Views/Transaction/Helper";
import { DropdownCell } from "Views/Transaction/List";
import { IToasterProps } from "Views/Accounting/Settings/@types";
import { TOASTER_STATUS_TYPE } from "Modules/DS/Toaster";
import { DEFAULT_ERROR_MESSAGE } from "Views/Transaction/Constants";

interface ICategoryDropdown {
  record: ITransaction;
  setToaster: React.Dispatch<React.SetStateAction<IToasterProps>>;
  refetch: () => void;
}

const CategoryDropdown = ({ record, setToaster, refetch }: ICategoryDropdown) => {
  const [loading, setLoading] = useState(false);
  const onSuccess = () => {
    setToaster({
      show: true,
      message: "1 category successfully edited",
      type: TOASTER_STATUS_TYPE.SUCCESS,
    });
    refetch();
  };

  const onError = (err) => {
    setToaster({
      message: err?.message || DEFAULT_ERROR_MESSAGE,
      show: true,
      type: TOASTER_STATUS_TYPE.ERROR,
    });
  };
  const { children, onClose, type, width } = useCategoryUpdate([record.id], setLoading, onSuccess, onError);

  const props: IDropdownCell = {
    id: record.id,
    type,
    onClose,
    children,
    popoverWidth: width,
    value: record.category,
    title: "Category",
    loading: loading,
    disabled: includesReversalTrxns(record.transactionType), // Check for TOPUP
  };

  if (editNotAllowed(record.transactionType)) {
    return <span>N.A.</span>;
  }
  return <DropdownCell {...props} />;
};

export default CategoryDropdown;
