import React, { ReactElement, useContext } from "react";

import { AuthPageContext } from "Views/Auth";
import LoaderIcon from "Views/State/Loading/LoaderIcon";

export const AuthLoader = ({ children }: { children: ReactElement }): ReactElement => {
  const { loading } = useContext(AuthPageContext);

  return loading ? <LoaderIcon /> : children;
};
