import React from "react";

import { IColumnData } from "Modules/DS/DataTable";
import { IGetCardsTrxnsData, GET_CARDS_TRXNS_DATA_PARAMS, CARDS_TRXNS_TABLE_COLUMNS } from "Views/Transaction/@types";
import { InputCell } from "Views/Transaction/Shimmer";
import { MultiLineTitle, CellWithSubtitle } from "Views/Transaction/List";
import { dateTimeFormatter } from "utility";

const SettledDate = ({ value }: { value: string }) => {
  if (value && value !== "NA") {
    const title = dateTimeFormatter(value).date;
    const subtitle = dateTimeFormatter(value).time;
    return <CellWithSubtitle title={title} subtitle={subtitle} />;
  }
  return <p>-</p>;
};

export const settledDate = (): IColumnData<IGetCardsTrxnsData>["column"] => ({
  title: (
    <MultiLineTitle title={CARDS_TRXNS_TABLE_COLUMNS.SETTLED_DATE} subtitle={CARDS_TRXNS_TABLE_COLUMNS.DATE_N_TIME} />
  ),
  key: GET_CARDS_TRXNS_DATA_PARAMS.SETTLED_DATE,
  dataIndex: GET_CARDS_TRXNS_DATA_PARAMS.SETTLED_DATE,
  width: 136,
  render: (value: string): React.ReactNode => (
    <InputCell>
      <SettledDate value={value} />
    </InputCell>
  ),
});
