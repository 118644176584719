import { SUBSCRIPTION_STATUS } from "./Details";

export enum SUBSCRIPTION_COLUMNS {
  VENDOR = "Vendor",
  ESTIMATED_COST = "Estimated cost",
  LAST_PAYMENT = "Last payment",
  BILLING_FREQUENCY = "Billing frequency",
  BUDGET_OWNER = "Budget/Owner",
  STATUS = "Status",
  EXPIRY_RENEWAL = "Expiry/Renewal",
}

export enum SUBSCRIPTION_PARAMS {
  SUBSCRIPTION_ID = "subscription_id",
  VENDOR = "vendor",
  BUDGET = "budget",
  OWNER = "owner",
  STATUS = "status",
  CURRENCY_CODE = "currency_code",
  ESTIMATED_AMOUNT = "estimated_amount",
  ESTIMATED_COST = "estimated_cost",
  BILLING_FREQUENCY = "billing_frequency",
  REVIEW_EXPIRY_DATE = "review_expiry_date",
  CONTRACT_TYPE = "contract_type",
  START_DATE = "start_date",
  EXPIRY = "expiry",
  NEXT_RENEWAL_DATE = "next_renewal_date",
  PAYMENT = "payment",
  LAST_PAYMENT_AMOUNT = "last_payment_amount",
  VARIATION_PERCENTAGE = "variation_percentage",
  LAST_PAYMENT_FOREIGN_AMOUNT = "last_payment_foreign_amount",
  LAST_PAYMENT_FOREIGN_CURRENCY = "last_payment_foreign_currency",
}

export enum PAYMENT_COLUMNS {
  CREATED = "Created Date & Time",
  TYPE = "Type",
  REQUESTER = "Requester",
  ATTACHMENT = "Attachment",
  AMOUNT = "Amount",
}

export const SUBSCRIPTION_STATUS_LABELS = {
  [SUBSCRIPTION_STATUS.ACTIVE]: "Active",
  [SUBSCRIPTION_STATUS.CANCELLED]: "Inactive",
  [SUBSCRIPTION_STATUS.EXPIRED]: "Expired",
};
