import React from "react";
import { useAccountingTransaction } from "../Context/AccountingTransactions";
import { BillsTrxnsDownload } from "Views/Transaction/Bills/Actions/BillsTrxnsDownload";
import { AllTrxnDownload } from "Views/Transaction/All/Actions/Download";
import { TABS_URL } from "../Constants";
import { TAB_NAME } from "Views/Accounting/Workflow/constant/constant";
import Download from "Views/Accounting/Workflow/Download";
import { useSelector } from "react-redux";
import { ITransactionFilter } from "Views/Accounting/Workflow/TransactionFilter";

const ExportButton = () => {
  const { activePageDetails } = useAccountingTransaction();
  const activePageName = activePageDetails?.name;

  const showOnlyRawCsvOption = [TAB_NAME.PENDING_REVIEW, TAB_NAME.PENDING_SYNC].includes(activePageName);
  const { data: accountingWorkflowData } = useSelector((state: any) => state.accountingWorkflowReducer);

  const isAccountingPage = Object.values(TAB_NAME).includes(activePageName);
  const accTrxnStatus = isAccountingPage ? activePageDetails?.accSubTabStatus : "";
  const allAccTransactionIds = accTrxnStatus
    ? accountingWorkflowData[accTrxnStatus]?.transactions?.map((transaction) => transaction.id)
    : [];
  const accFilters = activePageDetails?.accountingFilters || {};

  const onlySelected = (filter) => {
    return Object.entries(filter)
      .filter(([_, value]) => Boolean(value))
      .reduce((newFilter, [key, value]) => {
        newFilter[key] = value;
        return newFilter;
      }, {}) as ITransactionFilter;
  };

  if (activePageName === TABS_URL.SETTLED) {
    return <AllTrxnDownload isExportButtonEnabled />;
  }
  if (activePageName === TABS_URL.BILLS) {
    return <BillsTrxnsDownload isExportButtonEnabled />;
  }
  if (isAccountingPage && Object.keys(accFilters)?.length > 0) {
    return (
      <Download
        onlyRawCsv={showOnlyRawCsvOption}
        allTransactionIds={allAccTransactionIds}
        filter={onlySelected(accFilters)}
        accountingStatus={accTrxnStatus}
      />
    );
  }
  return null;
};

export default ExportButton;
