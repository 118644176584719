import React, { useState } from "react";
import * as A from "Views/Approval/ApprovalCenter/context";

const ApprovalCenterProvider = ({ children }: { children: React.ReactNode }) => {
  const [filters, setFilters] = useState({ ...A.initalFiltersState });

  const filterContext = {
    filters,
    setFilters,
  };

  return <A.ApprovalFilterContext.Provider value={filterContext}>{children}</A.ApprovalFilterContext.Provider>;
};

export default ApprovalCenterProvider;
