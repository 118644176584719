import React from "react";
import classNames from "classnames";

import useFocus from "utility/useFocus";

import { IPopoverSearch, SEARCH_CONSTANTS } from "Modules/DS/Search";

import styles from "./PopoverSearch.module.scss";

export const PopoverSearch = ({
  value,
  onSearch,
  placeholder,
  loading = false,
  classname,
}: IPopoverSearch): React.JSX.Element => {
  const [inputRef, setInputFocus] = useFocus();
  const className = classNames([styles.search__popover, classname, { [styles.active]: value?.length > 0 }]);
  const iconClass = loading ? styles.loading : styles.search__icon;

  return (
    <div className={className} onClick={setInputFocus}>
      <div className={iconClass} />
      <input
        type="search"
        ref={inputRef}
        value={value}
        placeholder={placeholder}
        id={SEARCH_CONSTANTS.POPOVER_SEARCH}
        name={SEARCH_CONSTANTS.POPOVER_SEARCH}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onSearch(e.target.value)}
      />
    </div>
  );
};
