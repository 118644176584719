import { CancelToken } from "axios";
import { GetOrgId } from "utility";
import APIclient from "API/Client";

export interface IAccountingWorkflowOption {
  limit: number;
  cancelToken?: CancelToken;
  sortData?: [number, string];
  moveToPreviousPage?: boolean;
}

export const getAccountingWorkflow = (params: URLSearchParams) => {
  return APIclient.getData(`/ms/spm-reporting/v1/transactions?${params.toString()}`);
};

export const updateTransactionStatus = (
  transactionIds: string[],
  action: string,
  additionalPayload?: { [key: string]: boolean | string }
) => {
  return APIclient.postData(`/api/v1/accounting/transactions/workflow`, {
    transaction_ids: transactionIds,
    organisation_id: GetOrgId(),
    action,
    ...additionalPayload,
  });
};

export const bulkActionTransactions = (transactionIds: string[], field: string, value: any) => {
  return APIclient.postData(`/api/v1/accounting/transaction/bulk?organisation_id=${GetOrgId()}`, {
    transaction_ids: transactionIds,
    [field]: value,
  });
};

export const sendEmailRemainder = (transactionIds: string[]) => {
  return APIclient.postData(`/ms/b2b-accounting/v1/reminder/missing-receipt`, {
    transaction_ids: transactionIds,
  });
};

export const validateSyncAvailability = (payload: any) => {
  return APIclient.postData(`/ms/b2b-accounting/v1/transaction/validate`, {
    ...payload,
    organisationId: GetOrgId(),
  });
};

export const findTransactionInXero = (transactionId: string, identifier?: string) => {
  const identifierType = identifier ? `?type=${identifier}` : "";
  return APIclient.getData(`/ms/b2b-accounting/v2/partners/transactions/${transactionId}${identifierType}`);
};

export const getAccountingCount = () => {
  return APIclient.getData(`/ms/spm-reporting/v1/accounting-summary`);
};
