import React from "react";
import { Card, Typography } from "@spenmo/splice";

import styles from "./index.module.scss";

export interface IEmployeeExpenseReport {
  index: number;
  name: string;
  email: string;
  list: any[]; // Update type here
  loading: boolean;
  addOnsEnabled: boolean;
}

const EmployeeExpenseReport = ({ index, name, email, list, loading, addOnsEnabled }: IEmployeeExpenseReport) => {
  return (
    <Card elevation="ground" radius="none" className={styles.employeeReport}>
      <div className={styles.title}>
        <Typography size="m" variant="body-content" weight={600} className={styles.name}>
          {index}. {name}
        </Typography>
        <Typography size="m" variant="body-content" weight={600}>
          ({email})
        </Typography>
      </div>
      <div>Table Component Here</div>
      <div>Amount Payable Here</div>
    </Card>
  );
};

export default EmployeeExpenseReport;
