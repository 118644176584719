import React from "react";
import { useAppSelector } from "hook";
import { Modal, Typography, toast } from "@spenmo/splice";
import { IModalProps } from "@spenmo/splice/lib/components/Modal";
import { useSubscriptionContext } from "Views/SubscriptionManagement/Details/SubscriptionContext";

import styles from "./index.module.scss";
import useSWRMutation from "swr/mutation";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import { putData } from "API/Client";

interface RemoveSubscriptionModalProps {
  onClose: () => void;
  showModal: boolean;
  onFinish: () => void;
  selectedRows: string[];
}

export const useRemoveTransactionFromSubscription = ({ onError }) => {
  return useSWRMutation(
    "ms/spm-organization/v1/expenses/subscription/unlink",
    (
      url: string,
      {
        arg,
      }: {
        arg: {
          subscription_id: string;
          transaction_numbers: string[];
        };
      }
    ) =>
      putData(url, arg)
        .then(({ data }) => {
          const { status } = data || {};
          if (status === HTTP_STATUS_CODE.OK) {
            toast.success("Successfully unlink the transaction from the subscription", { duration: 3000 });
            return;
          }
          throw new Error(data);
        })
        .catch((err) => {
          toast.danger(
            "The transaction couldn't be unlink from the subscription. Please retry or contact us at support@spenmo.com if the issue persists."
          );
          throw new Error(err);
        }),
    {
      onError,
    }
  );
};

const RemoveSubscriptionModal: React.FC<RemoveSubscriptionModalProps> = ({
  onClose,
  showModal,
  selectedRows,
  onFinish,
}) => {
  const { subscription } = useSubscriptionContext();
  const { trigger, isMutating } = useRemoveTransactionFromSubscription({ onError: onFinish });

  const loggedInUserDetails = useAppSelector((state: any) => state?.userInfoReducer);
  const userName = loggedInUserDetails?.data?.payload?.user?.name || "";

  const modalProps: IModalProps = {
    showModal: showModal,
    size: "m",
    title: "Remove Transactions from Subscription",
    onClose: onClose,
    footerButtonAlignment: "end",
    primaryActionButton: {
      title: "Proceed",
      action: () => {
        trigger({ subscription_id: subscription.id, transaction_numbers: selectedRows });
        onFinish();
      },
      loading: isMutating,
    },
    secondaryActionButton: {
      title: "Discard",
      action: onClose,
    },
  };

  return (
    <div className={styles.modal}>
      <Modal {...modalProps}>
        <Typography className={styles.content} size="s" variant="body-content">
          Hey {userName}, confirm your decision to remove these transactions from the "{subscription.vendor?.name}"
          subscription. Once removed, these transactions will no longer be tied to the subscription and will not impact
          its analytics.
        </Typography>
      </Modal>
    </div>
  );
};

export default RemoveSubscriptionModal;
