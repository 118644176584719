import * as React from "react";
import { Drawer } from "antd";
import { drawerCloseIconGrey40x40 } from "assets/icons/card/";

import "Views/Card/Components/Drawer/index.scss";
import classNames from "classnames";

interface IDrawerProps {
  title: string | React.ReactNode;
  children: React.ReactNode;
  onClose: () => void;
  width?: number | null;
  withOverlay?: boolean;
}

const DEFAULT_DRAWER_WIDTH = 460;

const CardsDrawer = ({ title, children, onClose, width = null, withOverlay = false }: IDrawerProps) => {
  return (
    <Drawer
      title={title}
      placement="right"
      onClose={onClose}
      visible
      width={width || DEFAULT_DRAWER_WIDTH}
      className={classNames("cards-drawer", {
        withOverlay,
      })}
      closeIcon={<img src={drawerCloseIconGrey40x40} alt="close icon" height={40} width={40} />}
      destroyOnClose
    >
      {children}
    </Drawer>
  );
};

export default CardsDrawer;
