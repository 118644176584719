import React, { useMemo } from "react";
import { useMoveTeamMemberContext } from "../MoveTeamMember/context";
import SelectRadioList from "../SelectRadioList";

const SelectTeamStep = () => {
  const { employeeName, destinationTeam, setDestinationTeam, teamDetails, userTeams } = useMoveTeamMemberContext();

  const excludeTeams = useMemo(
    () => [teamDetails.id].concat(userTeams.map((userTeam) => userTeam.team_id)),
    [teamDetails, userTeams]
  );

  return (
    <div data-testid="move-team-member-step-one" className="move-team-member__content">
      <SelectRadioList
        title={`Select a new budget for ${employeeName}`}
        searchPlaceholder="Search budget"
        value={destinationTeam.id}
        onChange={setDestinationTeam}
        excludeTeams={excludeTeams}
      />
    </div>
  );
};

export default SelectTeamStep;
