import React, { useState } from "react";
import { Skeleton, Divider } from "antd";
import cn from "classnames";

import Tabs, { TAB_TYPE } from "Modules/DS/Tabs";

import { CARD_DETAILS_TABS } from "Views/Card/List/types";

import styles from "./index.module.scss";
import infoStyles from "Views/Card/Details/Info/index.module.scss";
import tabStyles from "Views/Card/Details/Info/Tabs/index.module.scss";

interface ICardDetailsShimmerProps {
  showCardDetailsInModal: boolean;
}

const tabs = [
  {
    title: CARD_DETAILS_TABS.DETAILS,
    component: null,
  },
  {
    title: CARD_DETAILS_TABS.SETTINGS,
    component: null,
  },
  {
    title: CARD_DETAILS_TABS.HISTORY,
    component: null,
  },
];

const CardDetailsShimmer = ({ showCardDetailsInModal }: ICardDetailsShimmerProps) => {
  const [activeTab, setActiveTab] = useState(CARD_DETAILS_TABS.DETAILS);
  return (
    <div className={styles.container}>
      <Skeleton.Input active className={styles.card} />
      <Skeleton.Input active className={styles.statusToggle} />
      <Skeleton.Input active className={styles.fuelGuage} />
      <div className={cn(infoStyles.container, styles.detailsContainer)}>
        <div
          className={cn(tabStyles["tabs-container"], {
            [tabStyles["tabs-container-in-modal"]]: showCardDetailsInModal,
          })}
        >
          <Tabs
            activeKey={activeTab}
            action={(activeTab) => setActiveTab(activeTab as CARD_DETAILS_TABS)}
            tabsList={tabs}
            content={() =>
              Array.from({ length: 4 }).map((_, index) => (
                <div key={index}>
                  <Skeleton.Input active className={styles.header} />
                  <Skeleton.Input active className={styles.detailsItem} />
                  {index < 3 && <Divider className={styles.divider} />}
                </div>
              ))
            }
            type={TAB_TYPE.FIXED}
          />
        </div>
      </div>
    </div>
  );
};

export default CardDetailsShimmer;
