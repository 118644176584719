import { useContext, useMemo } from "react";

import { SPLIT_NAMES, SPLIT_TREATMENT_TYPES } from "Redux/splitio/constants";

import { Separator } from "Views/Bills/V2/PaymentRun/helper";
import useCheckFeatureStatus from "customHooks/featureCheck";
import ToggleView from "Views/Bills/V2/PaymentRun/ToggleView";
import { SUBMITTED_BILLS_VIEW } from "Views/Bills/V2/constants";
import SetUpPaymentRun from "Views/Bills/V2/PaymentRun/SetupLink";
import { ALIGN_ACTION_ITEM, IActionItem } from "Modules/DS/DataTable";
import { BillsTrxnFilters } from "Views/Transaction/Bills/Actions/Filter";
import { PermissionAndSettingsContext } from "Views/Bills/V2/context/BillContext";
import { BillsTrxnsDownload } from "Views/Transaction/Bills/Actions/BillsTrxnsDownload";
import { getIsLimitModel } from "utility";
import { useMutableData } from "API/useData";
import { API_URL } from "Views/Transaction/Constants";

const Actions = (): IActionItem[] => {
  const paymentRunFlag = useCheckFeatureStatus(
    SPLIT_NAMES.billPaymentRunPage,
    true,
  );
  const isLimitModelOrg = getIsLimitModel();
  const {
    permissionLoading,
    viewAllowed,
    setupAllowed,
    settingLoading: isPaymentRunSettingLoading,
    settings: paymentRunSettingEnabled,
  } = useContext(PermissionAndSettingsContext);

  const { data: billpayConfig } = useMutableData(API_URL.billpayConfig);

  const { filterStatuses, isPaymentEnabled } = useMemo(
    () => billpayConfig?.data?.payload || {},
    [billpayConfig],
  );

  let actions: IActionItem[] = [
    {
      id: "BILLS-TRXN-ACTION-1",
      enabled: true,
      align: ALIGN_ACTION_ITEM.LEFT,
      component: (() =>
        BillsTrxnFilters({
          statuses: filterStatuses?.map((item) => item.label),
          withUnscheduledFilter: isPaymentEnabled,
        }))(),
    },
    {
      id: "BILLS-TRXN-ACTION-4",
      enabled: true,
      align: ALIGN_ACTION_ITEM.RIGHT,
      component: BillsTrxnsDownload({}),
    },
  ];

  if (
    paymentRunFlag === SPLIT_TREATMENT_TYPES.ON &&
    isLimitModelOrg &&
    !permissionLoading &&
    !isPaymentRunSettingLoading
  ) {
    const newActions = [
      {
        id: "BILLS-TRXN-ACTION-2",
        enabled: true,
        align: ALIGN_ACTION_ITEM.RIGHT,
        component: (): JSX.Element => {
          if (paymentRunSettingEnabled && viewAllowed) {
            return ToggleView({
              currentView: SUBMITTED_BILLS_VIEW.DEFAULT,
              nextView: SUBMITTED_BILLS_VIEW.PAYMENT_RUN,
            });
          } else if (setupAllowed) {
            return SetUpPaymentRun();
          }
          return null;
        },
      },
      {
        id: "BILLS-TRXN-ACTION-3",
        enabled: true,
        align: ALIGN_ACTION_ITEM.RIGHT,
        component: (): JSX.Element => {
          if (setupAllowed || (viewAllowed && paymentRunSettingEnabled))
            return Separator();
          return null;
        },
      },
    ];

    actions.splice(1, 0, ...newActions);
  }

  return actions.filter((item) => item.enabled);
};

export default Actions;
