import React from "react";

export interface IAccordionProps {
  className?: string;
  children: React.ReactNode;
  title: string;
  subtitle?: string;
  icon?: React.ReactNode;
  shadow?: EShadowType;
  footer?: boolean;
  suffixIcon?: React.ReactNode;
  defaultOpen?: boolean;
}

export enum EShadowType {
  BASEMENT = "basement",
  LIFTED = "lifted",
  RAISED = "raised",
  FLOATING = "floating",
}
