import React from "react";
import { Typography, Popover } from "antd";

import s from "./TeamsColumns.module.scss";
import analyticsStyles from "../../Analytics.module.scss";
import BarChart from "Splice/Charts/BarChart";

import {
  dateFormatter4,
  SGDCurrencyFormatter,
  calculateDate,
  checkChangePercentage,
  getCurrentAndPastSpendingText,
} from "../../utils";
import classNames from "classnames";
import { percentageStyles } from "Views/Analytics";
import { IAnalyticsFilter } from "Views/Analytics/@types";
import { TotalTeamCategories } from "Views/Analytics/Team";

const { Text } = Typography;

export const renderTeamsColumns = (
  totalTeamCategories: TotalTeamCategories[],
  colorMap,
  totalTeamAmount,
  filter: IAnalyticsFilter
) => [
  {
    title: "Top budgets by spending",
    dataIndex: "teamName",
    key: "teamName",
    render: (teamName) => (
      <>
        {teamName && <div className={s.avatar}>{teamName[0]?.toUpperCase() || "?"}</div>}
        <Popover
          arrowPointAtCenter
          placement="bottom"
          overlayClassName="analytics-tooltip"
          content={teamName ? teamName : "Budget not set"}
        >
          <Text className={s.teamName} style={{ maxWidth: 100 }} ellipsis>
            {teamName ? teamName : <i>Budget not set</i>}
          </Text>
        </Popover>
      </>
    ),
    width: "14%",
  },
  {
    title: () => {
      const categories = totalTeamCategories;
      return (
        <div className={s.totalBreakup}>
          {categories.map((item) => (
            <div key={item.categoryName}>
              <span style={{ background: item.color }} />
              {item.categoryName}
            </div>
          ))}
        </div>
      );
    },
    dataIndex: "categorySpendDetails",
    key: "categorySpendDetails",
    render: (data, record) => {
      return (
        <div className={s.barChartContainer}>
          <BarChart data={data} colors={colorMap} totalRow={record.totalAmount} total={totalTeamAmount} />
        </div>
      );
    },
    width: "52%",
  },
  {
    title: () => (
      <div className={classNames(analyticsStyles.tableHead, analyticsStyles.tableLastCol)}>
        {getCurrentAndPastSpendingText(filter.otherCustomFilter, false)}
        <br />
        {dateFormatter4(filter.startDate, filter.endDate)}
      </div>
    ),
    dataIndex: "currentSpending",
    key: "currentSpending",
    render: (currentSpending) => (
      <div className={classNames(analyticsStyles.tableHead, analyticsStyles.tableLastCol)}>
        {SGDCurrencyFormatter(currentSpending, 2, "currency")}
      </div>
    ),
    width: "13%",
  },
  {
    title: () => (
      <div className={classNames(analyticsStyles.tableHead, analyticsStyles.tableLastCol)}>
        {getCurrentAndPastSpendingText(filter.otherCustomFilter, true)}
        <br />
        {dateFormatter4(
          calculateDate(filter.startDate, filter.differences, filter.otherCustomFilter),
          calculateDate(filter.endDate, filter.differences, filter.otherCustomFilter, true)
        )}
      </div>
    ),
    dataIndex: "pastSpending",
    key: "pastSpending",

    render: (pastSpending) => (
      <div className={classNames(analyticsStyles.tableHead, analyticsStyles.tableLastCol)}>
        {SGDCurrencyFormatter(pastSpending, 2, "currency")}
      </div>
    ),
    width: "13%",
  },
  {
    title: () => <div className={classNames(analyticsStyles.tableHead, analyticsStyles.tableLastCol)}>% Change</div>,
    dataIndex: "change",
    key: "change",
    render: (change) => (
      <div className={classNames(percentageStyles[checkChangePercentage(change)])}>
        {checkChangePercentage(change) === "positive" ? `+${change}` : change || "0%"}
      </div>
    ),
    width: "8%",
  },
];
