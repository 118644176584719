import React from "react";
import { Row, Col, Form } from "antd";
import Button from "Modules/button";
import Error from "Views/State/Error";
import { xeroCategoryCodes } from "Redux/Actions";
import { useDispatch } from "react-redux";
import { List } from "./List";

import style from "./styles.module.scss";

export const XeroCategories = ({
  categories = [],
  xeroCategoriesAccountNumbers = [],
  xeroCategories = [],
  showImportBtn,
  onImport,
  xeroCategoriesMappingInProgress,
  onNextStep,
  importedCategories,
}) => {
  const dispatch = useDispatch();

  const saveCategories = (values) => {
    const filteredArray =
      Object.entries(values)?.map?.((val) => {
        return { [`${val[0]}`]: val[1] === undefined ? "" : val[1] };
      }) || [];
    filteredArray.length ? dispatch(xeroCategoryCodes(filteredArray, importedCategories)) : onNextStep();
  };

  return (
    <Form className={style.form} id="XeroCategoryForm" onFinish={saveCategories}>
      {!xeroCategories.length || !categories.length ? (
        <Error message={`No Expense Categories in ${!xeroCategories.length ? "Xero" : "Spenmo"} :(`} />
      ) : (
        <List
          categories={categories}
          xeroCategoriesAccountNumbers={xeroCategoriesAccountNumbers}
          xeroCategories={xeroCategories}
        />
      )}
      <Row className={style.actionButton} justify={showImportBtn ? "space-between" : "end"}>
        {showImportBtn && (
          <Col>
            <Button rounded action={onImport} size="xl" btnStyle="white-orange-with-border">
              Import Categories from Xero
            </Button>
          </Col>
        )}
        <Form.Item>
          <Col className={style.finishButton}>
            <Button htmlType="submit" size="xl" rounded loading={xeroCategoriesMappingInProgress}>
              Finish Connecting
            </Button>
          </Col>
        </Form.Item>
      </Row>
    </Form>
  );
};
