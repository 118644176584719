import { useContext, useEffect } from "react";
import { FormInstance } from "antd/lib/form";

import { useAppDispatch } from "hook";

import { getBusinessActivityData } from "Redux/Actions/KYX/GetBusinessActivity";
import { KYXRemarkContext } from "Views/KYX/Context/Remarks";
import { FORM_NAME } from "Views/KYX/Constants";
import { IDocumentStep, IFetchFormSubmissionPayload, IKYXRemarkContextType } from "Views/KYX/@types";

import { useCompanyAddressFormSGD } from "./Address";
import { useGeneralInformationFormSGD } from "./General";
import { useAdditionalInformationFormSGD } from "./Optional";
import { getEntityTypes } from "Redux/Actions/KYX/GetEntityTypes";

export const useCompanyInformationFormSGD = (
  form: FormInstance,
  formData: IFetchFormSubmissionPayload,
  isFormDisabled: boolean
): IDocumentStep[] => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getBusinessActivityData());
    dispatch(getEntityTypes());
  }, [dispatch]);

  const { formRemarks }: IKYXRemarkContextType = useContext(KYXRemarkContext);
  const remarks = formRemarks?.[FORM_NAME.COMPANY_INFORMATION];

  return [
    // General Information
    useGeneralInformationFormSGD(form, formData, remarks, isFormDisabled),
    // Company Address
    useCompanyAddressFormSGD(form, formData, remarks, isFormDisabled),
    // Additional Information
    useAdditionalInformationFormSGD(form, formData, remarks, isFormDisabled),
  ];
};
