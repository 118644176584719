import React from "react";
import { Group } from "@visx/group";
import { BarGroup } from "@visx/shape";
import { AxisBottom } from "@visx/axis";
import { LegendOrdinal, LegendItem, LegendLabel } from "@visx/legend";
import { useTooltip, useTooltipInPortal } from "@visx/tooltip";
import { localPoint } from "@visx/event";

import { useMutableData } from "API/useData";
import { Typography, Card } from "@spenmo/splice";
import { GetOrgId, currencyFormatterV2 } from "utility";
import { formatDate } from "utility/DateUtilites";
import { getCookieValue, cookieNames } from "utility/CookieHelper";
import { ContentType, SpendAnalysisType, TooltipData } from "Views/SubscriptionManagement/@types";
import LoadingComponent from "Views/State/Loading/LoaderIcon";

import { chartStyle, cleanBarGroups, generateAnalysisData, getDate, scale } from "./utils";
import { useSubscriptionContext } from "../SubscriptionContext";

import styles from "./styles.module.scss";

let tooltipTimeout: number;

const keys = ["estimated", "actual", "forecast"] as unknown as ContentType[];
const { background, defaultMargin, defaultColor, tooltipStyle, borderSubtle, fontFamily, bodySubtle } = chartStyle;
const { dateScaleFn, spendAmountScaleFn, spendTypeScaleFn, colorScale, ordinalColorScale } = scale;

export const SpendAnalysis = () => {
  const { subscription } = useSubscriptionContext();
  const { data, isLoading } = useMutableData(
    `api/v1/expenses/subscriptions/${subscription.id}/summary?organisation_id=${GetOrgId()}`
  );

  const analysisData = generateAnalysisData(data?.data?.payload?.summary);

  const { tooltipOpen, tooltipLeft, tooltipTop, tooltipData, hideTooltip, showTooltip } = useTooltip<TooltipData>();
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    scroll: true,
  });

  const width = 500;
  const height = 180;

  // bounds
  const xMax = width - defaultMargin.left - defaultMargin.right;
  const yMax = height - defaultMargin.top - defaultMargin.bottom;

  // update scale output dimensions
  const dateScale = dateScaleFn(analysisData);
  const spendTypeScale = spendTypeScaleFn(keys);
  const spendAmountScale = spendAmountScaleFn(analysisData);

  dateScale.rangeRound([0, xMax]);
  spendTypeScale.rangeRound([0, dateScale.bandwidth()]);
  spendAmountScale.range([yMax, 0]);

  return (
    <Card elevation="level-1" padding="medium" radius="m" className={styles.card}>
      <Typography size="m" variant="headline-content" className={styles.title}>
        Monthly Spend Analysis
      </Typography>

      {isLoading ? (
        <div className={styles.loadingContainer}>
          <LoadingComponent />
        </div>
      ) : (
        <>
          <svg ref={containerRef} width={width} height={height}>
            <rect x={0} y={0} width={width} height={height} fill={background} rx={14} />
            <Group top={defaultMargin.top} left={defaultMargin.left}>
              <BarGroup<SpendAnalysisType, ContentType>
                data={analysisData}
                keys={keys}
                height={yMax}
                x0={getDate}
                x0Scale={dateScale}
                x1Scale={spendTypeScale}
                yScale={spendAmountScale}
                color={colorScale(keys)}
              >
                {(barGroups) =>
                  cleanBarGroups(barGroups, analysisData).map((barGroup) => (
                    <Group key={`bar-group-${barGroup.index}-${barGroup.x0}`} left={barGroup.x0}>
                      {barGroup.bars.map((bar) => (
                        <rect
                          key={`bar-group-bar-${barGroup.index}-${bar.index}-${bar.value}-${bar.key}`}
                          x={bar.x}
                          y={Math.min(bar.y, 115)}
                          width={bar.width}
                          height={bar.value > 0 ? Math.max(bar.height, 5) : 0}
                          fill={bar.color}
                          rx={4}
                          ry={4}
                          onMouseLeave={() => {
                            tooltipTimeout = window.setTimeout(() => {
                              hideTooltip();
                            }, 300);
                          }}
                          onMouseMove={(event) => {
                            if (tooltipTimeout) clearTimeout(tooltipTimeout);

                            const eventSvgCoords = localPoint(event);
                            showTooltip({
                              tooltipData: { bar },
                              tooltipTop: eventSvgCoords?.y,
                              tooltipLeft: eventSvgCoords.x,
                            });
                          }}
                        />
                      ))}
                    </Group>
                  ))
                }
              </BarGroup>
            </Group>

            <AxisBottom
              top={yMax + defaultMargin.top}
              tickComponent={({ formattedValue, ...rest }) => {
                return (
                  <>
                    <text {...rest}>
                      <tspan x={rest.x} dy={rest.dy}>
                        {formatDate(new Date(formattedValue), "MMM")}
                      </tspan>
                    </text>
                    <text {...rest} y={rest.y + 12} fill={bodySubtle} fontSize={10}>
                      <tspan x={rest.x} dy={rest.dy}>
                        {formatDate(new Date(formattedValue), "'YY")}
                      </tspan>
                    </text>
                  </>
                );
              }}
              scale={dateScale}
              stroke={borderSubtle}
              hideTicks
              tickLabelProps={{
                fill: defaultColor,
                fontSize: 11,
                fontFamily: fontFamily,
                textAnchor: "middle",
              }}
            />
          </svg>
          <LegendOrdinal scale={ordinalColorScale} labelFormat={(label) => `${label.toUpperCase()}`}>
            {(labels) => (
              <div className={styles.legend}>
                {labels.map((label, i) => (
                  <LegendItem key={`legend-quantile-${i}`} margin="0 5px">
                    <svg width={16} height={8}>
                      <rect fill={label.value} width={16} height={8} rx={4} />
                    </svg>
                    <LegendLabel align="left" margin="0 0 0 4px" className={styles.legendLabel}>
                      {label.text.toLowerCase()}
                    </LegendLabel>
                  </LegendItem>
                ))}
              </div>
            )}
          </LegendOrdinal>
        </>
      )}

      {tooltipOpen && tooltipData && (
        <TooltipInPortal top={tooltipTop} left={tooltipLeft} style={tooltipStyle}>
          <div className={styles.section}>
            <Typography tag="p" variant="body-content" size="m" weight={600} className={styles.amount}>
              {currencyFormatterV2(tooltipData.bar.value, getCookieValue(cookieNames.CURRENCY_CODE), true, 2)}
            </Typography>
            <Typography tag="p" variant="body-content" size="m" className={styles.date}>
              {formatDate(tooltipData.bar.date, "MMM YY")}
            </Typography>
          </div>
        </TooltipInPortal>
      )}
    </Card>
  );
};
