import { COMPANY_VERIFICATION } from "Route/KYX/paths";

import { KYB_STATUS } from "Views/KYX/Constants";

export const kybStatusRedirector = (kybStatus: string) => {
  const kybStatusRedirectMap = {
    [KYB_STATUS.NOT_STARTED]: COMPANY_VERIFICATION.WELCOME,
    [KYB_STATUS.STARTED]: COMPANY_VERIFICATION.WELCOME_BACK,
    [KYB_STATUS.ADDITIONAL_DOCUMENTS_REQUIRED]: COMPANY_VERIFICATION.ADDITIONAL_DOCUMENTS_REQUIRED,
    [KYB_STATUS.SUBMITTED]: COMPANY_VERIFICATION.SUBMITTED,
    [KYB_STATUS.REJECTED]: COMPANY_VERIFICATION.REJECTED,
    [KYB_STATUS.APPROVED]: COMPANY_VERIFICATION.APPROVED,
    [KYB_STATUS.IN_REVIEW]: COMPANY_VERIFICATION.IN_REVIEW,
    [KYB_STATUS.COMPLIANCE_REVIEW]: COMPANY_VERIFICATION.IN_REVIEW,
    [KYB_STATUS.ASSESSMENT_IN_PROGRESS]: COMPANY_VERIFICATION.IN_REVIEW,
  };

  return {
    kybRoute: kybStatusRedirectMap[kybStatus],
    validateStatus: Object.keys(kybStatusRedirectMap).includes(kybStatus),
  };
};
