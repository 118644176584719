import { FETCH_BANK_TRANSFER_FAILURE, FETCH_BANK_TRANSFER_REQUEST, FETCH_BANK_TRANSFER_SUCCESS } from "../Actions";

const initialState = {
  bankTransferList: [],
  loading: false,
  error: false,
};

export const topupBankTransferReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_BANK_TRANSFER_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case FETCH_BANK_TRANSFER_SUCCESS:
      return {
        ...state,
        bankTransferList: action.data,
        loading: false,
        error: false,
      };
    case FETCH_BANK_TRANSFER_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    default:
      return state;
  }
};
