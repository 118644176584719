import { IColumnData } from "Modules/DS/DataTable";
import { IGetCardsTrxnsData } from "Views/Transaction/@types";

import { created } from "./Created";
import { cardholder } from "./Cardholder";
import { merchant } from "./Merchant";
import { settledDate } from "./SettledDate";
import { cardTitle } from "./CardTitle";
import { amount } from "./Amount";
import { status } from "./Status";

import "./index.scss";

const Columns = (): IColumnData<IGetCardsTrxnsData>[] => {
  return [
    {
      id: "CARDS-TRXN-COL-1",
      enabled: true,
      column: created(),
    },
    {
      id: "CARDS-TRXN-COL-2",
      enabled: true,
      column: cardholder(),
    },
    {
      id: "CARDS-TRXN-COL-3",
      enabled: true,
      column: merchant(),
    },
    {
      id: "CARDS-TRXN-COL-4",
      enabled: true,
      column: settledDate(),
    },
    {
      id: "CARDS-TRXN-COL-5",
      enabled: true,
      column: cardTitle(),
    },
    {
      id: "CARDS-TRXN-COL-6",
      enabled: true,
      column: amount(),
    },
    {
      id: "CARDS-TRXN-COL-7",
      enabled: true,
      column: status(),
    },
  ].filter((item) => item.enabled);
};

export default Columns;
