import { FETCH_MEMBER_REQUEST, FETCH_MEMBER_SUCCESS, FETCH_MEMBER_FAILURE } from "../Actions/membersAction";
// import {userInitialState} from "../InitialState";

export const membersReducer = (state = [], action) => {
  switch (action.type) {
    case FETCH_MEMBER_REQUEST:
      return {
        ...state,
        data: action.data,
        loading: true,
        error: false,
      };
    case FETCH_MEMBER_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
      };
    case FETCH_MEMBER_FAILURE:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: true,
      };
    default:
      return state;
  }
};
