import DynamicTypes, { baseComponentData } from "../types/dynamicTypes";
import DataCalls from "../DataCalls";

export const BaseComponentState = baseComponentData;

export const BluntUpdate =
  (componentName = "", componentData = {}) =>
  (dispatch, getState) => {
    dispatch({
      type: DynamicTypes.UpdateComponentData,
      data: {
        componentName,
        updatedData: componentData,
      },
    });
  };

export const UpdateData =
  (componentName = "", componentData = {}) =>
  (dispatch, getState) => {
    dispatch({
      type: DynamicTypes.AddDataInComponent,
      data: {
        componentName,
        loadData: componentData,
      },
    });
  };

export const UpdateError =
  (componentName = "", componentData = {}) =>
  (dispatch, getState) => {
    dispatch({
      type: DynamicTypes.AddErrorInComponent,
      data: {
        componentName,
        errorData: componentData,
      },
    });
  };

export const ToggleLoader =
  (componentName = "") =>
  (dispatch, getState) => {
    dispatch({
      type: DynamicTypes.ToggleLoaderInComponent,
      data: { componentName },
    });
  };

export const CallAPIWithLoader =
  (componentName = "", apiMappingName = "", apiFunctionArguments = []) =>
  (dispatch, getState) => {
    dispatch(BluntUpdate(componentName, { ...baseComponentData, loading: true, error: null }));
    DataCalls[apiMappingName]
      .apply(null, apiFunctionArguments)
      .then((data) => {
        dispatch(BluntUpdate(componentName, { ...baseComponentData, loading: false, error: null, data }));
      })
      .catch((err) => {
        console.error("Error occured", err);
        dispatch(BluntUpdate(componentName, { ...baseComponentData, loading: false, error: err }));
      });
  };

export const CallAPIWithoutLoader =
  (componentName = "", apiMappingName = "", apiFunctionArguments = []) =>
  (dispatch, getState) => {
    DataCalls[apiMappingName]
      .apply(null, apiFunctionArguments)
      .then((data) => {
        dispatch(BluntUpdate(componentName, { ...baseComponentData, error: null, data }));
      })
      .catch((err) => {
        console.error("Error occured", err);
        dispatch(BluntUpdate(componentName, { ...baseComponentData, error: err }));
      });
  };
