import { useContext, useState } from "react";

import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";

import { startKYXManually } from "Views/KYX/DataCalls";
import { IKYXToastContext, IStartManualVerification } from "Views/KYX/@types";
import { KYXToastContext, KYXErrorToaster } from "Views/KYX/Context";
import { KYB_STATUS } from "Views/KYX/Constants";
import { setCookie, cookieNames } from "utility/CookieHelper";
import { trackEvent } from "utility/analytics";
import { KYXEvents } from "Views/KYX/analytics";

/**
 *  A custom hook that handles the process of manually starting KYX verification.
 *
 * PSA: Ensure that this hook is used within the bounds of KYXToastContext.Provider
 *
 * @param {(() => void)} dispatchOnSuccess (optional) - A callback function that gets executed when the KYX verification process is successful.
 * @returns {IStartManualVerification} An object containing startVerification and loading properties
 *
 * This hook uses startKYXManually function internally to make an HTTP request to start the KYX verification process.
 * It also checks the response status and throws an error if the response status is not 200.
 */

export const useStartKYXManually = (dispatchOnSuccess?: () => void): IStartManualVerification => {
  const [loading, setLoading] = useState(false);
  const { setToaster } = useContext<IKYXToastContext>(KYXToastContext);

  const startVerification = async () => {
    trackEvent(KYXEvents.START_BUTTON_CLICKED);
    try {
      setLoading(true);

      const response = await startKYXManually();

      const errorMessage = response.data?.error?.message || response.data.meta.message;

      if (response.data.status > HTTP_STATUS_CODE.OK) {
        throw new Error(errorMessage);
      } else if (!response.data?.payload?.isSuccess && response.data?.payload?.message !== "Successfully") {
        throw new Error(response.data?.payload?.message || errorMessage);
      }
      setCookie(cookieNames.KYB_STATUS, KYB_STATUS.STARTED);

      dispatchOnSuccess?.();
    } catch (error) {
      const toast = KYXErrorToaster(error?.response?.data?.error?.message || error);

      setToaster(toast);
    } finally {
      setLoading(false);
    }
  };

  return { startVerification, loading } as IStartManualVerification;
};
