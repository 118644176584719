import APIclient from "API/Client";
import { HTTP_STATUS_CODE } from "../../../constants/HTTPStatusCode.constant";

export const FETCH_UPLOADED_DOC_REQUEST = "FETCH_UPLOADED_DOC_REQUEST";
export const FETCH_UPLOADED_DOC_SUCCESS = "FETCH_UPLOADED_DOC_SUCCESS";
export const FETCH_UPLOADED_DOC_FAILURE = "FETCH_UPLOADED_DOC_FAILURE";
export const FETCH_UPLOADED_DOC_CLEAR = "FETCH_UPLOADED_DOC_CLEAR";

export const fetchDataRequest = (data) => {
  return {
    type: "FETCH_UPLOADED_DOC_REQUEST",
    data: data,
  };
};

export const fetchDataSuccess = (data, headers) => {
  return {
    type: "FETCH_UPLOADED_DOC_SUCCESS",
    data: {
      data,
      headers,
    },
  };
};

export const fetchDataError = (data) => {
  return {
    type: "FETCH_UPLOADED_DOC_FAILURE",
    data: data,
  };
};

export const clearUploadedDoc = (data) => {
  return {
    type: FETCH_UPLOADED_DOC_CLEAR,
    data: data,
  };
};

export const getUploadedDoc = (path) => {
  return (dispatch) => {
    dispatch(fetchDataRequest());

    APIclient.getData(`/ms/spm-organization/v2/onboarding/document/${path}`)
      .then((data) => {
        if (data.status === HTTP_STATUS_CODE.OK) {
          dispatch(fetchDataSuccess(data.data, data.headers));
        } else {
          dispatch(fetchDataError(data.data));
        }
      })
      .catch((err) => {
        dispatch(fetchDataError(err));
      });
  };
};
