import { urlParamsBuilder } from "../../utility/APIWrapper";
import { TAB_KEY } from "Views/Approval/ApprovalCenter/types";
import AxiosGetForApiWrapper from "utility/apiWrapperHelper";

export const GetApprovalCenterDetail = async (id: string, type: TAB_KEY) => {
  const url = urlParamsBuilder(`/ms/spm-organization/v3/approvals/detail`, {
    process_id: id,
    process_type: type,
  });
  return AxiosGetForApiWrapper(url);
};
