export const CASBIN_PAGES = {
  HOME_PAGE: "homepage",
  TOPUP_PAGE: "topup-page",
  TRANSACTION_PAGE: "transaction-page",
  TRANSACTION_PAGE_DETAILS_PANEL: "transaction-details-panel",
  TRANSACTION_PAGE_ALL_TRANSACTION_TAB: "transaction-page_all-transaction-tab",
  TRANSACTION_PAGE_BILL_TRASACTION_TAB: "transaction-page_bill-transaction-tab",
  TRANSACTION_PAGE_SCHEDULED_INTERNAL_TRANSACTIONS_TAB: "transaction-page_scheduled-internal-transactions-tab",
  TOPUP_MENU: "topup-menu",
  BILL_PAYMENTS_PAGE: "bill-payments-page",
  CARDS_PAGE: "cards-page",
  CARDS_PAGE_CARDS_DETAILS_PAGE: "cards-page_cards-details-page",
  CARDS_PAGE_CARDS_DETAILS_PAGE_HISTORY_TAB: "cards-page_cards-details-page_history-tab",
  CARDS_PAGE_CARDS_DETAILS_PAGE_SETTINGS_TAB: "cards-page_cards-details-page_settings-tab",
  REIMBURSEMENT_REQUEST_PAGE: "reimbursement-request-page",
  TEAMS_PAGE: "teams-page",
  TEAMS_PAGE_TEAM_PROFILE_PAGE: "teams-page_team-profile-page",
  TEAMS_PAGE_TEAM_PROFILE_PAGE_POLICIES_TAB: "teams-page_team-profile-page_policies-tab",
  EMPLOYEES_PAGE: "employees-page",
  APPROVAL_FLOW_MENU: "approval-flow-menu",
  BANK_ACCOUNT_PAGE: "bank-account-page",
  CATEGORIES_PAGE: "categories-page",
  TAX_PAGE: "tax-page",
  INTEGRATION_MENU: "integration-menu",
  INTEGRATION_PAGE: "integration-page",
  LOW_BALANCE_NOTIFICATION_MENU: "low-balance-notification-menu",
  LOW_BALANCE_NOTIFICATION_PAGE: "low-balance-notification-page",
  SECURITY_HUB_MENU: "security-hub-menu",
  TAX_MENU: "tax-menu",
  BANK_ACCOUNT_MENU: "bank-account-menu",
  BILL_MENU: "bill-menu",
  CENTRALISED_APPROVAL_MENU: "centralise-approval-page-menu",
  ANALYTICS_MENU: "analytics-menu",
  CATEGORY_MENU: "category-menu",
  ACCOUNTING_SETTING_MENU: "accounting-setting-menu",
  ACCOUNTING_SETTING_PAGE: "accounting-setting-page",
  PAYMENT_RUN: "payment-run-setting-page",
  PAYMENT_RUN_BILL_PAY_PAGE: "payment-run-billpay-page",
  SUBSCRIPTION_MANAGEMENT: "subscription-management",
  REIMBURSEMENT_SETTING_MENU: "cash-reimbursement-setting-page",
  REIMBURSEMENT_TRANSACTION_PAGE: "cash-reimbursement-transaction-page",
  BILLING_PAGE: "billing",
  BILL_EXPORT_CSV: "bill-export-csv",
};
