import React from "react";

import styles from "./FormItem.module.scss";

const FormItem = (props) => {
  const { children, label, htmlFor } = props;
  return (
    <div className={styles.formItem}>
      <div className={styles.label}>
        <label htmlFor={htmlFor}>{label}</label>
      </div>
      {children}
    </div>
  );
};

export default FormItem;
