import APIclient from "API/Client";
import { GetBaseAuthObject } from "../../utility";
import { GetFormData } from "../../utility/APIWrapper";
import { appNotification } from "../../Modules/appNotification/appNotification";
import { HTTP_STATUS_CODE } from "../../constants/HTTPStatusCode.constant";

export const FETCH_XERO_AUTH_REQUEST = "FETCH_XERO_AUTH_REQUEST";
export const FETCH_XERO_AUTH_SUCCESS = "FETCH_XERO_AUTH_SUCCESS";
export const FETCH_XERO_AUTH_FAILURE = "FETCH_XERO_AUTH_FAILURE";

export const FETCH_XERO_DISCONNECT_REQUEST = "FETCH_XERO_DISCONNECT_REQUEST";
export const FETCH_XERO_DISCONNECT_SUCCESS = "FETCH_XERO_DISCONNECT_SUCCESS";
export const FETCH_XERO_DISCONNECT_FAILURE = "FETCH_XERO_DISCONNECT_FAILURE";

export const RESET_XERO_DATA = "RESET_XERO_DATA";

const fetchDataReq = (data) => {
  return {
    type: FETCH_XERO_AUTH_REQUEST,
    data: data,
  };
};
export const fetchDataSuccess = (data) => {
  return {
    type: FETCH_XERO_AUTH_SUCCESS,
    data: data,
  };
};
const fetchDataError = (data) => {
  return {
    type: FETCH_XERO_AUTH_FAILURE,
    data: data,
  };
};

const fetchDataXeroReq = (data) => {
  return {
    type: FETCH_XERO_DISCONNECT_REQUEST,
    data: data,
  };
};
const fetchDataXeroSuccess = (data) => {
  return {
    type: FETCH_XERO_DISCONNECT_SUCCESS,
    data: data,
  };
};
const fetchDataXeroError = (data) => {
  return {
    type: FETCH_XERO_DISCONNECT_FAILURE,
    data: data,
  };
};
export const resetXeroData = () => {
  return {
    type: RESET_XERO_DATA,
  };
};

export const xeroAuth = () => {
  return (dispatch) => {
    dispatch(fetchDataReq());
    APIclient.getData("/ms/b2b-accounting/v2/partners/connections")
      .then((res) => {
        if (res.status === HTTP_STATUS_CODE.OK && res?.data?.payload) {
          const integrationStatusResponse = { ...res.data };
          const { validToken, completedIntegration, bankfeedEnabled, expenseEnabled } = res.data.payload || {};
          const isXeroConnected = validToken && completedIntegration;
          integrationStatusResponse.payload = {
            ...integrationStatusResponse.payload,
            // adding below properties so as to not affect the current usage of these keys from the old API response
            has_valid_token: isXeroConnected,
            bankfeed: isXeroConnected && bankfeedEnabled,
            expense: isXeroConnected && expenseEnabled,
          };
          dispatch(fetchDataSuccess(integrationStatusResponse));
        } else {
          dispatch(fetchDataError(res.data));
        }
      })
      .catch((err) => {
        dispatch(fetchDataError(err));
      });
  };
};

export const disconectXeroAction = (withAppNotification = true) => {
  const orgId = GetBaseAuthObject().orgId;

  return (dispatch) => {
    dispatch(fetchDataXeroReq());
    return APIclient.postData("/api/v1/auth/xero/disconnect", GetFormData({ organisation_id: orgId }))
      .then((data) => {
        if (data?.data?.status === HTTP_STATUS_CODE.OK) {
          dispatch(fetchDataXeroSuccess(data.data));
          withAppNotification &&
            appNotification.success({
              message: "Xero integration has been successfully disconnected.",
            });
          dispatch(xeroAuth());
        } else {
          dispatch(fetchDataXeroError(data.data));
          withAppNotification &&
            appNotification.error({
              message: data.data.status_message || "Failed to disconnect. Please try again",
            });
          return Promise.reject();
        }
      })
      .catch((err) => {
        dispatch(fetchDataXeroError(err));
        withAppNotification &&
          appNotification.error({
            message: "Failed to disconnect. Please try again",
          });

        return Promise.reject();
      });
  };
};
