import { FETCH_WALLET_REQUEST, FETCH_WALLET_SUCCESS, FETCH_WALLET_FAILURE } from "../Actions/walletAction";
import { userInitialState } from "../InitialState";
import { UPDATE_WALlET } from "Redux/Actions/Teams/teamListAction";

export const walletReducer = (state = { ...userInitialState }, action) => {
  switch (action.type) {
    case FETCH_WALLET_REQUEST:
      return {
        ...state,
        wdata: action.data,
        loading: true,
      };
    case FETCH_WALLET_SUCCESS:
      return {
        ...state,
        wdata: action.data,
        loading: false,
      };
    case FETCH_WALLET_FAILURE:
      return {
        ...state,
        loading: false,
        wdata: action.data,
        error: true,
      };
    case UPDATE_WALlET:
      return {
        ...state,
        data: action.data,
      };
    default:
      return state;
  }
};
