export const getLabel = <T extends object = any>(
  selected: T[],
  displayKey: keyof T,
  defaultValue: string,
  title: string,
  iconKey?: keyof T
) => {
  let text: string = "";
  let icon: string = "";

  if (selected.length === 1) {
    text = selected[0][displayKey as string];
    icon = selected[0][iconKey as string];
  } else if (selected.length > 1) {
    text = (title ? title : defaultValue) + ` (${selected.length})`;
  }

  return { text, icon };
};
