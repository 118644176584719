import { Cookies } from "react-cookie";
import axios from "axios";

export { loginFunc, logoutFunc, checkAuth } from "./loginAction";
export { forgotFunc, resetForgotData } from "./forgotAction";
export { cardActivateFunc, clearCardActivateData } from "./cardActivateAction";
export { cardActivateBulkFunc } from "./cardActivateBulkAction";
export { requestActionFunc, requestPayNowFunc } from "./approvalAction";
export {
  reimburseActionFunc,
  AddReimburseFunc,
  editReimburseFunc,
  deleteTeamPendingFundTransfer,
  clearDeleteFundData,
} from "./reimburseAction";
export { receiptActionFunc } from "./transactionAction";
export { uploadImageFunc } from "./imageUploadAction";
export { getMerchantsFunc } from "./merchantAction";
export { CategoryCreateFunc } from "./Category/create";
export { CategoryEditFunc } from "./Category/edit";
export { CategoryDeleteFunc } from "./Category/delete";
export { scheduleDeleteFunc } from "./schedulePaymentAction";
export { UserInfoFunc } from "./userAction";
export { UserInfoWithTeamData } from "./user/userWithTeamDataAction";
export { createCardFunc, resetCreateCardData } from "./CreateCard";
export { cardFunc } from "./cardAction";
export { sendFunc, reqFunc, clearFetchSendMoney } from "./moneyAction";
export { setMemberRoleFunc, resetMemberRole } from "./memberRoleAction";
export { cardEditActionFunc, cardBlockActionFunc } from "./cardEditAction";
export { allWalletFunc, employeeWallets } from "./walletAction";
export { empFunc, teamEmpFunc } from "./employeeAction";
export { createTeamPolicyFunc, getTeamPolicyFunc } from "./teamPolicyAction";
export { getData, postData } from "./asynActions";
export { resetPasswordFunc } from "./ResetPasswordAction";
export { getAllMembersFunc } from "./membersAction";
export { getCurrencyFunc } from "./currencyAction";
export { numFunc, cvvFunc, clearNumFunc, clearCVVFunc } from "./cvvAction";
export { delBankAccFunc, addBankAccFunc, fetchBankFunc } from "./bankAction";
export {
  TransactionCSVFunc,
  XeroCSVFunc,
  ExpenseCSVFunc,
  DownloadBankStatementFunc,
  RequestsCSVFunc,
  clearCsvData,
  SentFundsCSVFunc,
} from "./csvAction";
export * from "./topupBankTransferAction";
export { topupActionFunc } from "./topupAction";
export {
  getXeroIntegrationDetails,
  getPartnerAccount,
  selectXeroAccount,
  xeroExpenseCodes,
  xeroCategoryCodes,
} from "./xeroAccountAction";
export { accountingPartnerAuth, disconnectAccountingPartnerAction, fetchDataSuccess } from "./accountingPartnerAuth"; // And use this instead
export { createTaxFunc, editTaxFunc, getAllTaxesFunc } from "./taxAction";
export { getOrgDetailFunc } from "./b2bOrgDetailAction";
export { syncXeroFunc } from "./syncTransactionsAction";
export { getStepsStatus } from "./OnBoarding/stepsStatusAction";
export { uploadDocument, clearUploadDocument } from "./OnBoarding/uploadDocument";
export { addEmployeesManually } from "./OnBoarding/addEmployeesAction";
export { getEmployeeRoles } from "./OnBoarding/rolesAction";
export { getPhoneCodes } from "./OnBoarding/phoneCodeAction";
export { setApproverFunc, clearSetApprover } from "./TransactionApproval/setApproverAction";
export * from "./topupBankTransferAction";
export { getPendingApprovalsFunc, clearGetPendingApprovals } from "./TransactionApproval/getPendingApprovalsAction";
export { getCardNumbersFunc } from "./CardFilter/GetCardNumbers";
export { getCardOwnerNamesFunc, getCardRequestOwnerNamesFunc } from "./CardFilter/GetCardOwnerNames";
export { downloadCategoryCSV } from "./OnBoarding/downloadCategoryCSVAction";
export { downloadEmployeeCSV } from "./OnBoarding/downloadEmployeeCSVAction";
export { getScheduledTransactionsFunc } from "./ScheduledTransactions/getScheduledTransactions";
export {
  editScheduledTransactionFunc,
  clearEditScheduledTransaction,
} from "./ScheduledTransactions/editScheduledTransaction";
export {
  stopScheduledTransactionsFunc,
  clearStopScheduledTransactions,
} from "./ScheduledTransactions/stopScheduledTransactions";
export {
  createScheduledTransactionFunc,
  clearCreateScheduledTransaction,
} from "./ScheduledTransactions/createScheduledTransaction";
export { CustomTicketFormFunc, ClearCustomTicketFormFunc } from "./ZenDesk/customTicketAction";
export { getUploadedDoc, clearUploadedDoc } from "./OnBoarding/uploadedDocAction";
export { changePhoneNumber } from "./OTP/changePhoneNumberAction";
export { verifyOtp } from "./OTP/otpVerificationAction";
export { fetchOtp, reSendOtp, fetchOtpV1 } from "./OTP/fetchOtpAction";
export { getTeamProfileData } from "./Teams/teamProfileAction";
export { getTeamList } from "./Teams/teamListAction";
export { getTeamListByUserId, clearTeamListByUserIdData } from "./Teams/teamListByUserIdAction";
export { getTeamMembersByTeamId, clearTeamMembersByTeamIdData } from "./Teams/teamMembersByTeamIdAction";
export { getTeamDatilsById, getTeamsByUserId } from "./Teams/teamDetailsAction";
export { updateTeam } from "./Teams/updateTeamAction";
export { createTeam } from "./Teams/createTeamAction";
export { addTeamMember } from "./Teams/addTeamMemberAction";
export { getAllTeamsWallet } from "./Teams/teamWalletAction";
export { getApprovalFlowListFunc } from "./ApprovalFlow/getApprovalFlowList";
export { createApprovalFlowFunc, editApprovalFlowFunc } from "./ApprovalFlow/createApprovalFlow";
export { validateApprovalFlowFunc } from "./ApprovalFlow/validateApprovalFlow";
export { checkTermsAndConditionsExisting } from "./TermsAndConditions/termsAndConditionsForExistingUserAction";
export { setTermsAndConditionsExisting } from "./TermsAndConditions/termsAndConditionsForNewUserAction";
export { CheckPhoneNumber } from "./Common/checkPhoneNumAction";
export { CheckEmail } from "./Common/checkEmailAction";
export { updateProfile } from "./Wallet/updateProfileAction";

export { getCountryList } from "./Common/getCountryList";
export { getPreliminaryData } from "./KYX/GetPreliminaryData";
export { getBusinessActivityData } from "./KYX/GetBusinessActivity";
export { MyInfoCallBack } from "./KYX/MyInfoCallBack";
export { getEntityTypes } from "./KYX/GetEntityTypes";

export { getAllEmployees } from "./Employee/Get/Employees";
export { getKYCStatuses } from "./Employee/Get/KycStatusList";
export { updateRole, clearUpdateRole } from "./Employee/Update/Role";
export { getPendingActions } from "./PendingActions";

export { VerifyForgotPassword } from "./Auth/VerifyForgotPassword";
export { VerifyForgotOTP } from "./Auth/VerifyForgotOTP";
export { getOrgConfigsFunc } from "./orgConfigsAction";

const CancelToken = axios.CancelToken;
export const source = CancelToken.source();

const cookies = new Cookies();
export const axiosHeader = {
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + cookies.get("auth_token"),
  },
};
export const axiosHeaderBlob = {
  ...axiosHeader,
  responseType: "blob",
};
