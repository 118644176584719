import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { IModalProps } from "@spenmo/splice/lib/components/Modal";
import { Modal, IllEmpty, Typography } from "@spenmo/splice";

import { REIMBURSEMENT_MODAL_OPTIONS, REIMBURSEMENT_PAGE_OPERATIONS } from "Views/Reimbursement/Constants";

import styles from "./index.module.scss";

export const Container = () => {
  return (
    <div className={styles.method}>
      <IllEmpty size={120} />
      <Typography size="m" variant="body-content" tag="p">
        You have yet to provide your reimbursement payout details. A method of payment is required to process a payment
        to you.
      </Typography>
    </div>
  );
};

const Method = () => {
  const history = useHistory();
  const location = useLocation();

  const handleOnClose = () => {
    history.push({
      pathname: location.pathname.replace(
        `/${REIMBURSEMENT_PAGE_OPERATIONS.MODAL}/${REIMBURSEMENT_MODAL_OPTIONS.METHOD_VALIDATION}`,
        ""
      ),
      search: location.search,
    });
  };

  const props: IModalProps = {
    size: "m",
    showModal: true,
    onClose: handleOnClose,
    title: `Payout details is missing`,
    children: <Container />,
    footerButtonAlignment: "end",
    primaryActionButton: {
      title: "Set method now",
      action: () => history.push("/settings/payoutdetail"),
    },
    secondaryActionButton: {
      title: "Maybe later",
      action: handleOnClose,
    },
  };

  return <Modal {...props} />;
};

export default Method;
