import { useEffect, useState } from "react";
import { FormInstance } from "antd/lib/form";

import { useAppSelector } from "hook";

import { RootState } from "Redux/ConfigureStore";
import { IBusinessActivityData, ILegalEntityType } from "Redux/Actions/KYX/types";

import {
  GUIDELINES_TYPE,
  FORM_FIELD_TYPES,
  KYX_COUNTRY_LIST,
  DOCUMENT_STEP_TYPE,
  FORM_SECTION_COMPONENT,
} from "Views/KYX/Constants";
import {
  NUM_OF_EMPLOYEES,
  BOOLEAN_OPTIONS_VALUE,
  BOOLEAN_OPTIONS,
  BOOLEAN_OPTIONS_LABEL,
  PURPOSE_OF_OPENING_ACCOUNT_LABEL,
  PURPOSE_OF_OPENING_ACCOUNT_VALUE,
  PURPOSE_OF_OPENING_ACCOUNT_OPTIONS,
  MONTHLY_VALUE_OF_TRNX_SGD_OPTIONS,
  MONTHLY_VALUE_OF_TRNX_SGD_OPTIONS_LABEL,
  MONTHLY_VALUE_OF_TRNX_SGD_OPTIONS_VALUE,
  MONTHLY_VOLUME_OF_TRNX_OPTIONS,
  MONTHLY_VOLUME_OF_TRNX_OPTIONS_LABEL,
  MONTHLY_VOLUME_OF_TRNX_OPTIONS_VALUE,
  KYX_FORMS_KEYS,
  FORM_NAME,
} from "Views/KYX/Constants/Forms";
import {
  IDocumentStep,
  IFormElement,
  IFetchFormSubmissionPayload,
  IMultipleStepInformation,
  IGetFormConfigsPayload,
  FORM_SOURCE_TYPE,
} from "Views/KYX/@types";
import { getValidationError, getValidationstatus, disableFutureDates } from "Views/KYX/Helper";

import styles from "./index.module.scss";

interface ISelectOptions {
  label: string;
  value: string;
}

export const useGeneralInformationFormPartOneSGD = (
  form: FormInstance,
  formData: IFetchFormSubmissionPayload,
  remarks: IGetFormConfigsPayload,
  isFormDisabled: boolean
): IMultipleStepInformation => {
  const isMyInfoSource: boolean = formData?.source === FORM_SOURCE_TYPE.MY_INFO;

  const LegalEntityTypes: ILegalEntityType[] = useAppSelector(
    (state: RootState) => state.entityTypesReducer?.data?.payload?.kyb_constants || []
  );

  const LegalEntityTypeOptions =
    LegalEntityTypes?.map((item) => ({
      label: item.description,
      value: item.description,
    })) || [];

  const EMAIL_ADDRESS: IFormElement = {
    key: FORM_NAME.COMPANY_INFORMATION + KYX_FORMS_KEYS.GENERAL_INFORMATION + KYX_FORMS_KEYS.EMAIL,
    col: { span: 12 },
    element: {
      type: FORM_FIELD_TYPES.INPUT,
      props: {
        formProps: {
          label: "Email Address",
          name: [FORM_NAME.COMPANY_INFORMATION, KYX_FORMS_KEYS.GENERAL_INFORMATION, KYX_FORMS_KEYS.EMAIL],
          validateStatus: getValidationstatus(remarks, [KYX_FORMS_KEYS.GENERAL_INFORMATION, KYX_FORMS_KEYS.EMAIL]),
          help: getValidationError(
            remarks,
            [KYX_FORMS_KEYS.GENERAL_INFORMATION, KYX_FORMS_KEYS.EMAIL],
            "Email Address",
            FORM_NAME.COMPANY_INFORMATION
          ),
        },
        inputProps: {
          disabled: true,
          datatype: "string",
          placeholder: "admin@spenmo.com",
        },
      },
    },
  };

  const [doesntHavePublicWebsite, setDoesntHavePublicWebsite] = useState<boolean>(undefined);

  useEffect(() => {
    const hasNoWebsite = formData?.form?.company_information?.additional_information?.doesnt_have_public_website;

    if (typeof hasNoWebsite === "boolean") {
      setDoesntHavePublicWebsite(hasNoWebsite);
    }
  }, [formData]);

  const COMPANY_WEBSITE: IFormElement = {
    key: FORM_NAME.COMPANY_INFORMATION + KYX_FORMS_KEYS.ADDITIONAL_INFORMATION + KYX_FORMS_KEYS.COMPANY_WEBSITE,
    col: { span: 12 },
    element: {
      type: FORM_FIELD_TYPES.INPUT,
      props: {
        formProps: {
          name: [FORM_NAME.COMPANY_INFORMATION, KYX_FORMS_KEYS.ADDITIONAL_INFORMATION, KYX_FORMS_KEYS.COMPANY_WEBSITE],
          label: "Company Website",
          validateStatus: getValidationstatus(remarks, [
            KYX_FORMS_KEYS.ADDITIONAL_INFORMATION,
            KYX_FORMS_KEYS.COMPANY_WEBSITE,
          ]),
          rules: [
            { required: !doesntHavePublicWebsite, message: "Please enter company website" },
            { whitespace: !doesntHavePublicWebsite, message: "This field cannot be empty" },
          ],
          help: getValidationError(
            remarks,
            [KYX_FORMS_KEYS.ADDITIONAL_INFORMATION, KYX_FORMS_KEYS.COMPANY_WEBSITE],
            "Company Website",
            FORM_NAME.COMPANY_INFORMATION
          ),
        },
        inputProps: {
          placeholder: !doesntHavePublicWebsite ? "Enter company website address" : "",
          disabled: isFormDisabled || doesntHavePublicWebsite,
          datatype: "string",
        },
      },
    },
  };

  const EMPTY_SPAN: IFormElement = {
    key: FORM_NAME.COMPANY_INFORMATION + "empty_span",
    col: { span: 12 },
    element: {
      type: null,
    },
  };

  const DOESNT_HAVE_PUBLIC_WEBSITE: IFormElement = {
    key:
      FORM_NAME.COMPANY_INFORMATION + KYX_FORMS_KEYS.ADDITIONAL_INFORMATION + KYX_FORMS_KEYS.DOESNT_HAVE_PUBLIC_WEBSITE,
    col: { span: 12 },
    element: {
      type: FORM_FIELD_TYPES.CHECKLIST,
      props: {
        formProps: {
          name: [
            FORM_NAME.COMPANY_INFORMATION,
            KYX_FORMS_KEYS.ADDITIONAL_INFORMATION,
            KYX_FORMS_KEYS.DOESNT_HAVE_PUBLIC_WEBSITE,
          ],
          initialValue: doesntHavePublicWebsite && ["true"],
        },
        checkProps: {
          disabled: isFormDisabled,
          options: [
            {
              name: KYX_FORMS_KEYS.DOESNT_HAVE_PUBLIC_WEBSITE,
              label: "Company does not have a publicly available website ",
              value: "true",
            },
          ],
          onChange: (e) => {
            setDoesntHavePublicWebsite(e[KYX_FORMS_KEYS.DOESNT_HAVE_PUBLIC_WEBSITE]);
            form.setFields([
              {
                name: [
                  FORM_NAME.COMPANY_INFORMATION,
                  KYX_FORMS_KEYS.ADDITIONAL_INFORMATION,
                  KYX_FORMS_KEYS.COMPANY_WEBSITE,
                ],
                errors: [],
              },
            ]);
          },
        },
      },
    },
  };

  const LEGAL_ENTITY_NAME: IFormElement = {
    key: FORM_NAME.COMPANY_INFORMATION + KYX_FORMS_KEYS.GENERAL_INFORMATION + KYX_FORMS_KEYS.LEGAL_ENTITY_NAME,
    col: { span: 12 },
    element: {
      type: FORM_FIELD_TYPES.INPUT,
      props: {
        formProps: {
          label: "Legal Entity Name",
          name: [FORM_NAME.COMPANY_INFORMATION, KYX_FORMS_KEYS.GENERAL_INFORMATION, KYX_FORMS_KEYS.LEGAL_ENTITY_NAME],
          validateStatus: getValidationstatus(remarks, [
            KYX_FORMS_KEYS.GENERAL_INFORMATION,
            KYX_FORMS_KEYS.LEGAL_ENTITY_NAME,
          ]),
          help: getValidationError(
            remarks,
            [KYX_FORMS_KEYS.GENERAL_INFORMATION, KYX_FORMS_KEYS.LEGAL_ENTITY_NAME],
            "Legal Entity Name",
            FORM_NAME.COMPANY_INFORMATION
          ),
        },
        inputProps: {
          disabled: true,
          datatype: "string",
          placeholder: "SPENMO SPV PTE. LTD",
        },
      },
    },
  };

  const BRAND_NAME: IFormElement = {
    key: FORM_NAME.COMPANY_INFORMATION + KYX_FORMS_KEYS.GENERAL_INFORMATION + KYX_FORMS_KEYS.BRAND_NAME,
    col: { span: 12 },
    element: {
      type: FORM_FIELD_TYPES.INPUT,
      props: {
        formProps: {
          label: "Brand Name (if applicable)",
          name: [FORM_NAME.COMPANY_INFORMATION, KYX_FORMS_KEYS.GENERAL_INFORMATION, KYX_FORMS_KEYS.BRAND_NAME],
          rules: [{ whitespace: true, message: "This field cannot be empty" }],
          validateStatus: getValidationstatus(remarks, [KYX_FORMS_KEYS.GENERAL_INFORMATION, KYX_FORMS_KEYS.BRAND_NAME]),
          help: getValidationError(
            remarks,
            [KYX_FORMS_KEYS.GENERAL_INFORMATION, KYX_FORMS_KEYS.BRAND_NAME],
            "Brand Name",
            FORM_NAME.COMPANY_INFORMATION
          ),
        },
        inputProps: {
          datatype: "string",
          placeholder: "Enter brand name",
          disabled: isFormDisabled,
        },
      },
    },
  };

  const LEGAL_ENTITY_TYPE: IFormElement = {
    key: FORM_NAME.COMPANY_INFORMATION + KYX_FORMS_KEYS.GENERAL_INFORMATION + KYX_FORMS_KEYS.LEGAL_ENTITY_TYPE,
    col: { span: 12 },
    element: {
      type: FORM_FIELD_TYPES.SELECT,
      props: {
        formProps: {
          name: [FORM_NAME.COMPANY_INFORMATION, KYX_FORMS_KEYS.GENERAL_INFORMATION, KYX_FORMS_KEYS.LEGAL_ENTITY_TYPE],
          label: "Legal Entity Type",
          required: true,
          rules: [
            {
              required: true,
              message: "Please enter entity type",
            },
          ],
          validateStatus: getValidationstatus(remarks, [
            KYX_FORMS_KEYS.GENERAL_INFORMATION,
            KYX_FORMS_KEYS.LEGAL_ENTITY_TYPE,
          ]),
          help: getValidationError(
            remarks,
            [KYX_FORMS_KEYS.GENERAL_INFORMATION, KYX_FORMS_KEYS.LEGAL_ENTITY_TYPE],
            "Legal Entity Type",
            FORM_NAME.COMPANY_INFORMATION
          ),
        },
        selectProps: {
          placeholder: "Select legal entity type",
          options: LegalEntityTypeOptions,
          disabled: isFormDisabled || isMyInfoSource,
        },
      },
    },
  };

  const NUMBER_OF_EMPLOYEES: IFormElement = {
    key: FORM_NAME.COMPANY_INFORMATION + KYX_FORMS_KEYS.GENERAL_INFORMATION + KYX_FORMS_KEYS.NUMBER_OF_EMPLOYEES,
    col: { span: 12 },
    element: {
      type: FORM_FIELD_TYPES.RADIO,
      props: {
        formProps: {
          name: [FORM_NAME.COMPANY_INFORMATION, KYX_FORMS_KEYS.GENERAL_INFORMATION, KYX_FORMS_KEYS.NUMBER_OF_EMPLOYEES],
          label: "Number of Employees",
          required: true,
          rules: [
            {
              required: true,
              message: "Please select number of employees",
            },
          ],
          validateStatus: getValidationstatus(remarks, [
            KYX_FORMS_KEYS.GENERAL_INFORMATION,
            KYX_FORMS_KEYS.NUMBER_OF_EMPLOYEES,
          ]),
          help: getValidationError(
            remarks,
            [KYX_FORMS_KEYS.GENERAL_INFORMATION, KYX_FORMS_KEYS.NUMBER_OF_EMPLOYEES],
            "Number of Employees",
            FORM_NAME.COMPANY_INFORMATION
          ),
        },
        radioProps: {
          optionType: "button",
          options: NUM_OF_EMPLOYEES,
          disabled: isFormDisabled,
        },
      },
    },
  };

  const DATE_OF_INCORPORATION: IFormElement = {
    key: FORM_NAME.COMPANY_INFORMATION + KYX_FORMS_KEYS.GENERAL_INFORMATION + KYX_FORMS_KEYS.DATE_OF_INCORPORATION,
    col: { span: 12 },
    element: {
      type: FORM_FIELD_TYPES.DATEPICKER,
      props: {
        formProps: {
          name: [
            FORM_NAME.COMPANY_INFORMATION,
            KYX_FORMS_KEYS.GENERAL_INFORMATION,
            KYX_FORMS_KEYS.DATE_OF_INCORPORATION,
          ],
          label: "Date of Incorporation",
          required: true,
          rules: [
            {
              required: true,
              message: "Please select date of incorporation",
            },
          ],
          validateStatus: getValidationstatus(remarks, [
            KYX_FORMS_KEYS.GENERAL_INFORMATION,
            KYX_FORMS_KEYS.DATE_OF_INCORPORATION,
          ]),
          help: getValidationError(
            remarks,
            [KYX_FORMS_KEYS.GENERAL_INFORMATION, KYX_FORMS_KEYS.DATE_OF_INCORPORATION],
            "Date of Incorporation",
            FORM_NAME.COMPANY_INFORMATION
          ),
        },
        dateProps: {
          placeholder: "dd/mm/yyyy",
          disabledDate: disableFutureDates,
          format: "DD/MM/YYYY",
          disabled: isFormDisabled || isMyInfoSource,
        },
      },
    },
  };

  const COUNTRY_OF_INCORPORATION: IFormElement = {
    key: FORM_NAME.COMPANY_INFORMATION + KYX_FORMS_KEYS.GENERAL_INFORMATION + KYX_FORMS_KEYS.COUNTRY_OF_INCORPORATION,
    col: { span: 12 },
    element: {
      type: FORM_FIELD_TYPES.COUNTRYSELECT,
      props: {
        formProps: {
          name: [
            FORM_NAME.COMPANY_INFORMATION,
            KYX_FORMS_KEYS.GENERAL_INFORMATION,
            KYX_FORMS_KEYS.COUNTRY_OF_INCORPORATION,
          ],
          label: "Country of Incorporation",
          validateStatus: getValidationstatus(remarks, [
            KYX_FORMS_KEYS.GENERAL_INFORMATION,
            KYX_FORMS_KEYS.COUNTRY_OF_INCORPORATION,
          ]),
          help: getValidationError(
            remarks,
            [KYX_FORMS_KEYS.GENERAL_INFORMATION, KYX_FORMS_KEYS.COUNTRY_OF_INCORPORATION],
            "Country of Incorporation",
            FORM_NAME.COMPANY_INFORMATION
          ),
        },
        selectProps: {
          disabled: true,
          placeholder: "Select country of incorporation",
        },
      },
    },
  };

  const [mainCountry, setMainCountry] = useState(false);

  useEffect(() => {
    if (formData?.form?.company_information?.general_information?.is_same_operation_country) {
      setMainCountry(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  const IS_SAME_OPERATION_COUNTRY: IFormElement = {
    key: FORM_NAME.COMPANY_INFORMATION + KYX_FORMS_KEYS.GENERAL_INFORMATION + KYX_FORMS_KEYS.IS_SAME_OPERATION_COUNTRY,
    col: { span: 12 },
    element: {
      type: FORM_FIELD_TYPES.RADIO,
      props: {
        formProps: {
          required: true,
          label: "This company's country of incorporation is the same as its main country of operation",
          name: [
            FORM_NAME.COMPANY_INFORMATION,
            KYX_FORMS_KEYS.GENERAL_INFORMATION,
            KYX_FORMS_KEYS.IS_SAME_OPERATION_COUNTRY,
          ],
          className: styles.mainCountry,
          validateStatus: getValidationstatus(remarks, [
            KYX_FORMS_KEYS.GENERAL_INFORMATION,
            KYX_FORMS_KEYS.IS_SAME_OPERATION_COUNTRY,
          ]),
          help: getValidationError(
            remarks,
            [KYX_FORMS_KEYS.GENERAL_INFORMATION, KYX_FORMS_KEYS.IS_SAME_OPERATION_COUNTRY],
            "Country of incorporation",
            FORM_NAME.COMPANY_INFORMATION
          ),
        },
        radioProps: {
          disabled: isFormDisabled,
          onChange: (e) => {
            setMainCountry(e.target.value);

            form.setFieldsValue({
              [FORM_NAME.COMPANY_INFORMATION]: {
                [KYX_FORMS_KEYS.GENERAL_INFORMATION]: {
                  [KYX_FORMS_KEYS.COUNTRY_OF_OPERATION]: undefined,
                },
              },
            });
          },
          options: [
            { label: BOOLEAN_OPTIONS_LABEL[BOOLEAN_OPTIONS.YES], value: BOOLEAN_OPTIONS_VALUE[BOOLEAN_OPTIONS.YES] },
            { label: BOOLEAN_OPTIONS_LABEL[BOOLEAN_OPTIONS.NO], value: BOOLEAN_OPTIONS_VALUE[BOOLEAN_OPTIONS.NO] },
          ],
        },
      },
    },
  };

  const MAIN_COUNTRY_OF_OPERATION: IFormElement = {
    key: FORM_NAME.COMPANY_INFORMATION + KYX_FORMS_KEYS.GENERAL_INFORMATION + KYX_FORMS_KEYS.COUNTRY_OF_OPERATION,
    col: { span: 12 },
    element: {
      type: FORM_FIELD_TYPES.COUNTRYSELECT,
      props: {
        formProps: {
          name: [
            FORM_NAME.COMPANY_INFORMATION,
            KYX_FORMS_KEYS.GENERAL_INFORMATION,
            KYX_FORMS_KEYS.COUNTRY_OF_OPERATION,
          ],
          label: "Main Country of Operation",
          required: mainCountry,
          hidden: !mainCountry,
          style: { display: !mainCountry ? "block" : "none" },
          validateStatus: getValidationstatus(remarks, [
            KYX_FORMS_KEYS.GENERAL_INFORMATION,
            KYX_FORMS_KEYS.COUNTRY_OF_OPERATION,
          ]),
          help: getValidationError(
            remarks,
            [KYX_FORMS_KEYS.GENERAL_INFORMATION, KYX_FORMS_KEYS.COUNTRY_OF_OPERATION],
            "Main Country of Operation",
            FORM_NAME.COMPANY_INFORMATION
          ),
        },
        selectProps: {
          placeholder: "Select country of operation",
          disabled: isFormDisabled,
        },
      },
    },
  };

  return {
    key: FORM_NAME.COMPANY_INFORMATION + `${KYX_FORMS_KEYS.GENERAL_INFORMATION}_1`,
    row: { gutter: 40 },
    document: [
      EMAIL_ADDRESS,
      COMPANY_WEBSITE,
      EMPTY_SPAN,
      DOESNT_HAVE_PUBLIC_WEBSITE,
      LEGAL_ENTITY_NAME,
      BRAND_NAME,
      LEGAL_ENTITY_TYPE,
      NUMBER_OF_EMPLOYEES,
      DATE_OF_INCORPORATION,
      COUNTRY_OF_INCORPORATION,
      IS_SAME_OPERATION_COUNTRY,
      MAIN_COUNTRY_OF_OPERATION,
    ],
  };
};

const filterOption = (inputValue: string, option: ISelectOptions) => {
  return (
    (option.label && option.label.toString().toLocaleLowerCase().includes(inputValue.toLocaleLowerCase())) ||
    (option.value && option.value.includes(inputValue))
  );
};

export const useGeneralInformationFormPartTwoSGD = (
  form: FormInstance,
  formData: IFetchFormSubmissionPayload,
  remarks: IGetFormConfigsPayload,
  isFormDisabled: boolean
): IMultipleStepInformation => {
  const isMyInfoSource: boolean = formData?.source === FORM_SOURCE_TYPE.MY_INFO;

  const businessActivityReducer: IBusinessActivityData[] = useAppSelector(
    (state: RootState) => state?.businessActivityReducer?.data?.payload?.kyb_constants || []
  );

  const businessActivityOptions = businessActivityReducer.map((item) => ({
    label: item.description,
    value: item.code,
  }));

  const COMPANY_PRIMARY_BUSINESS_ACTIVITY: IFormElement = {
    key:
      FORM_NAME.COMPANY_INFORMATION +
      KYX_FORMS_KEYS.GENERAL_INFORMATION +
      KYX_FORMS_KEYS.COMPANY_PRIMARY_BUSINESS_ACTIVITY,
    col: { span: 10 },
    element: {
      type: FORM_FIELD_TYPES.SELECT,
      props: {
        formProps: {
          name: [
            FORM_NAME.COMPANY_INFORMATION,
            KYX_FORMS_KEYS.GENERAL_INFORMATION,
            KYX_FORMS_KEYS.COMPANY_PRIMARY_BUSINESS_ACTIVITY,
          ],
          label: "Company's Primary Business Activity",
          required: true,
          rules: [
            {
              required: true,
              message: "Please select a primary business activity",
            },
          ],
          validateStatus: getValidationstatus(remarks, [
            KYX_FORMS_KEYS.GENERAL_INFORMATION,
            KYX_FORMS_KEYS.COMPANY_PRIMARY_BUSINESS_ACTIVITY,
          ]),
          help: getValidationError(
            remarks,
            [KYX_FORMS_KEYS.GENERAL_INFORMATION, KYX_FORMS_KEYS.COMPANY_PRIMARY_BUSINESS_ACTIVITY],
            "Company Type",
            FORM_NAME.COMPANY_INFORMATION
          ),
        },
        selectProps: {
          showSearch: true,
          options: businessActivityOptions,
          disabled: isFormDisabled || isMyInfoSource,
          placeholder: "Select a primary business activity",
          filterOption: filterOption,
        },
      },
    },
  };

  const COMPANY_PRIMARY_BUSINESS_DESCRIPTION: IFormElement = {
    key:
      FORM_NAME.COMPANY_INFORMATION +
      KYX_FORMS_KEYS.GENERAL_INFORMATION +
      KYX_FORMS_KEYS.PRIMARY_BUSINESS_ACTIVITY_DESCRIPTION,
    guidelines: GUIDELINES_TYPE.BUSINESS_ACTIVITY,
    col: { span: 14 },
    element: {
      type: FORM_FIELD_TYPES.INPUT,
      props: {
        formProps: {
          name: [
            FORM_NAME.COMPANY_INFORMATION,
            KYX_FORMS_KEYS.GENERAL_INFORMATION,
            KYX_FORMS_KEYS.PRIMARY_BUSINESS_ACTIVITY_DESCRIPTION,
          ],
          label: "Primary Business Activity Description",
          required: true,
          rules: [
            {
              required: true,
              message: "Please enter your company's primary business activity description",
            },
            { whitespace: true, message: "This field cannot be empty" },
          ],
          validateStatus: getValidationstatus(remarks, [
            KYX_FORMS_KEYS.GENERAL_INFORMATION,
            KYX_FORMS_KEYS.PRIMARY_BUSINESS_ACTIVITY_DESCRIPTION,
          ]),
          help: getValidationError(
            remarks,
            [KYX_FORMS_KEYS.GENERAL_INFORMATION, KYX_FORMS_KEYS.PRIMARY_BUSINESS_ACTIVITY_DESCRIPTION],
            "Primary Business Activity Description",
            FORM_NAME.COMPANY_INFORMATION
          ),
        },
        inputProps: {
          datatype: "string",
          disabled: isFormDisabled,
          placeholder: "Enter your company's primary business activity description",
        },
      },
    },
  };

  const SECONDARY_COMPANY__BUSINESS_ACTIVITY: IFormElement = {
    key:
      FORM_NAME.COMPANY_INFORMATION +
      KYX_FORMS_KEYS.GENERAL_INFORMATION +
      KYX_FORMS_KEYS.SECONDARY_COMPANY_BUSINESS_ACTIVITY,
    col: { span: 10 },
    element: {
      type: FORM_FIELD_TYPES.SELECT,
      props: {
        formProps: {
          name: [
            FORM_NAME.COMPANY_INFORMATION,
            KYX_FORMS_KEYS.GENERAL_INFORMATION,
            KYX_FORMS_KEYS.SECONDARY_COMPANY_BUSINESS_ACTIVITY,
          ],
          label: "Company's Secondary Business Activity (Optional)",
          required: false,
          validateStatus: getValidationstatus(remarks, [
            KYX_FORMS_KEYS.GENERAL_INFORMATION,
            KYX_FORMS_KEYS.SECONDARY_COMPANY_BUSINESS_ACTIVITY,
          ]),
          help: getValidationError(
            remarks,
            [KYX_FORMS_KEYS.GENERAL_INFORMATION, KYX_FORMS_KEYS.SECONDARY_COMPANY_BUSINESS_ACTIVITY],
            "Company's Secondary Business Activity",
            FORM_NAME.COMPANY_INFORMATION
          ),
        },
        selectProps: {
          showSearch: true,
          disabled: isFormDisabled || isMyInfoSource,
          options: businessActivityOptions,
          placeholder: "Select a secondary business activity",
          filterOption: filterOption,
        },
      },
    },
  };

  const COMPANY_SECONDARY_BUSINESS_DESCRIPTION: IFormElement = {
    key:
      FORM_NAME.COMPANY_INFORMATION +
      KYX_FORMS_KEYS.GENERAL_INFORMATION +
      KYX_FORMS_KEYS.SECONDARY_BUSINESS_ACTIVITY_DESCRIPTION,
    guidelines: GUIDELINES_TYPE.BUSINESS_ACTIVITY,
    col: { span: 14 },
    element: {
      type: FORM_FIELD_TYPES.INPUT,
      props: {
        formProps: {
          name: [
            FORM_NAME.COMPANY_INFORMATION,
            KYX_FORMS_KEYS.GENERAL_INFORMATION,
            KYX_FORMS_KEYS.SECONDARY_BUSINESS_ACTIVITY_DESCRIPTION,
          ],
          label: "Secondary Business Activity Description",
          required: false,
          rules: [{ whitespace: true, message: "This field cannot be empty" }],
          validateStatus: getValidationstatus(remarks, [
            KYX_FORMS_KEYS.GENERAL_INFORMATION,
            KYX_FORMS_KEYS.SECONDARY_BUSINESS_ACTIVITY_DESCRIPTION,
          ]),
          help: getValidationError(
            remarks,
            [KYX_FORMS_KEYS.GENERAL_INFORMATION, KYX_FORMS_KEYS.SECONDARY_BUSINESS_ACTIVITY_DESCRIPTION],
            "Secondary Business Activity Description",
            FORM_NAME.COMPANY_INFORMATION
          ),
        },
        inputProps: {
          datatype: "string",
          disabled: isFormDisabled,
          placeholder: "Enter your company's secondary business activity description",
        },
      },
    },
  };

  const [showOthersInputField, setShowOthersInputField] = useState<boolean>(undefined);

  useEffect(() => {
    if (formData?.form?.company_information?.general_information?.source_of_funds?.values?.includes("Others")) {
      setShowOthersInputField(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  const SOURCE_OF_FUNDS: IFormElement = {
    key:
      FORM_NAME.COMPANY_INFORMATION +
      KYX_FORMS_KEYS.GENERAL_INFORMATION +
      KYX_FORMS_KEYS.SOURCE_OF_FUNDS +
      KYX_FORMS_KEYS.VALUES,
    col: { span: 24 },
    element: {
      type: FORM_FIELD_TYPES.CHECKLIST,
      props: {
        formProps: {
          name: [
            FORM_NAME.COMPANY_INFORMATION,
            KYX_FORMS_KEYS.GENERAL_INFORMATION,
            KYX_FORMS_KEYS.SOURCE_OF_FUNDS,
            KYX_FORMS_KEYS.VALUES,
          ],
          label: "Source of Funds",
          required: true,
          rules: [
            {
              required: true,
              message: "Please select source of funds",
            },
          ],
          validateStatus: getValidationstatus(remarks, [
            KYX_FORMS_KEYS.GENERAL_INFORMATION,
            KYX_FORMS_KEYS.SOURCE_OF_FUNDS,
            KYX_FORMS_KEYS.VALUES,
          ]),
          help: getValidationError(
            remarks,
            [KYX_FORMS_KEYS.GENERAL_INFORMATION, KYX_FORMS_KEYS.SOURCE_OF_FUNDS, KYX_FORMS_KEYS.VALUES],
            "Source of Funds",
            FORM_NAME.COMPANY_INFORMATION
          ),
          style: { marginBottom: showOthersInputField ? ".5rem" : "1.25rem" },
        },
        checkProps: {
          className: styles.info_check_list,
          disabled: isFormDisabled,
          options: [
            { label: "Revenue earned by the business", value: "Revenue earned by the business" },
            { label: "Business Loans", value: "Business Loans" },
            { label: "Inheritance", value: "Inheritance" },
            { label: "Investor Capital", value: "Investor Capital" },
            { label: "Personal Loans", value: "Personal Loans" },
            { label: "Personal Savings", value: "Personal Savings" },
            {
              label: "Others",
              value: "Others",
              onChange: () => {
                setShowOthersInputField((prev) => {
                  if (prev) {
                    form.setFieldsValue({
                      [FORM_NAME.COMPANY_INFORMATION]: {
                        [KYX_FORMS_KEYS.GENERAL_INFORMATION]: {
                          [KYX_FORMS_KEYS.SOURCE_OF_FUNDS]: {
                            [KYX_FORMS_KEYS.OTHER_VALUE]: undefined,
                          },
                        },
                      },
                    });
                  }
                  return !prev;
                });
              },
            },
          ],
        },
      },
    },
  };

  const OTHER_SOURCE_OF_FUNDS: IFormElement = {
    key:
      FORM_NAME.COMPANY_INFORMATION +
      KYX_FORMS_KEYS.GENERAL_INFORMATION +
      KYX_FORMS_KEYS.SOURCE_OF_FUNDS +
      KYX_FORMS_KEYS.OTHER_VALUE,
    col: { span: 12 },
    element: {
      type: FORM_FIELD_TYPES.INPUT,
      props: {
        formProps: {
          name: [
            FORM_NAME.COMPANY_INFORMATION,
            KYX_FORMS_KEYS.GENERAL_INFORMATION,
            KYX_FORMS_KEYS.SOURCE_OF_FUNDS,
            KYX_FORMS_KEYS.OTHER_VALUE,
          ],
          required: showOthersInputField,
          rules: [
            {
              required: showOthersInputField,
              message: "This field cannot be empty",
            },
            { whitespace: true, message: "This field cannot be empty" },
          ],
          hidden: showOthersInputField,
          dependencies: [KYX_FORMS_KEYS.GENERAL_INFORMATION, KYX_FORMS_KEYS.SOURCE_OF_FUNDS, KYX_FORMS_KEYS.VALUES],
          validateStatus: getValidationstatus(remarks, [
            KYX_FORMS_KEYS.GENERAL_INFORMATION,
            KYX_FORMS_KEYS.SOURCE_OF_FUNDS,
            KYX_FORMS_KEYS.OTHER_VALUE,
          ]),
          help: getValidationError(
            remarks,
            [KYX_FORMS_KEYS.GENERAL_INFORMATION, KYX_FORMS_KEYS.SOURCE_OF_FUNDS, KYX_FORMS_KEYS.OTHER_VALUE],
            "Other source of Funds",
            FORM_NAME.COMPANY_INFORMATION
          ),
          style: { display: showOthersInputField ? "block" : "none", marginLeft: ".5rem" },
        },
        inputProps: {
          datatype: "string",
          placeholder: "Enter your other source of funds",
          disabled: isFormDisabled,
        },
      },
    },
  };

  const ESTIMATE_ANNUAL_TURNOVER: IFormElement = {
    key: FORM_NAME.COMPANY_INFORMATION + KYX_FORMS_KEYS.GENERAL_INFORMATION + KYX_FORMS_KEYS.ESTIMATE_ANNUAL_TURNOVER,
    col: { span: 24 },
    element: {
      type: FORM_FIELD_TYPES.RADIO,
      props: {
        formProps: {
          name: [
            FORM_NAME.COMPANY_INFORMATION,
            KYX_FORMS_KEYS.GENERAL_INFORMATION,
            KYX_FORMS_KEYS.ESTIMATE_ANNUAL_TURNOVER,
          ],
          label: `Estimated Annual Turnover (${KYX_COUNTRY_LIST.SGD})`,
          required: true,
          rules: [
            {
              required: true,
              message: `Please select estimated Annual Turnover (${KYX_COUNTRY_LIST.SGD})`,
            },
          ],
          validateStatus: getValidationstatus(remarks, [
            KYX_FORMS_KEYS.GENERAL_INFORMATION,
            KYX_FORMS_KEYS.ESTIMATE_ANNUAL_TURNOVER,
          ]),
          help: getValidationError(
            remarks,
            [KYX_FORMS_KEYS.GENERAL_INFORMATION, KYX_FORMS_KEYS.ESTIMATE_ANNUAL_TURNOVER],
            `Estimated Annual Turnover (${KYX_COUNTRY_LIST.SGD})`,
            FORM_NAME.COMPANY_INFORMATION
          ),
        },
        radioProps: {
          optionType: "button",
          disabled: isFormDisabled,
          options: [
            { label: "50Mn - 500Mn", value: "50Mn - 500Mn" },
            { label: "500Mn - 1Bn", value: "500Mn - 1Bn" },
            { label: "1Bn - 10Bn", value: "1Bn - 10Bn" },
            { label: ">10Bn", value: ">10Bn" },
          ],
        },
      },
    },
  };

  return {
    key: FORM_NAME.COMPANY_INFORMATION + `${KYX_FORMS_KEYS.GENERAL_INFORMATION}_2`,
    row: { gutter: 40 },
    document: [
      COMPANY_PRIMARY_BUSINESS_ACTIVITY,
      COMPANY_PRIMARY_BUSINESS_DESCRIPTION,
      SECONDARY_COMPANY__BUSINESS_ACTIVITY,
      COMPANY_SECONDARY_BUSINESS_DESCRIPTION,
      SOURCE_OF_FUNDS,
      OTHER_SOURCE_OF_FUNDS,
      ESTIMATE_ANNUAL_TURNOVER,
    ],
  };
};

export const useGeneralInformationFormPartThreeSGD = (
  form: FormInstance,
  formData: IFetchFormSubmissionPayload,
  remarks: IGetFormConfigsPayload,
  isFormDisabled: boolean
): IMultipleStepInformation => {
  const [showOthersInputField, setShowOthersInputField] = useState<boolean>(undefined);

  useEffect(() => {
    if (
      formData?.form?.company_information?.general_information?.purpose_of_opening_account?.values?.includes("Others")
    ) {
      setShowOthersInputField(true);
    }
  }, [formData]);

  const PURPOSE_OF_OPENING_ACCOUNT: IFormElement = {
    key:
      FORM_NAME.COMPANY_INFORMATION +
      KYX_FORMS_KEYS.GENERAL_INFORMATION +
      KYX_FORMS_KEYS.PURPOSE_OF_OPENING_ACCOUNT +
      KYX_FORMS_KEYS.VALUES,
    col: { span: 24 },
    element: {
      type: FORM_FIELD_TYPES.CHECKLIST,
      props: {
        formProps: {
          name: [
            FORM_NAME.COMPANY_INFORMATION,
            KYX_FORMS_KEYS.GENERAL_INFORMATION,
            KYX_FORMS_KEYS.PURPOSE_OF_OPENING_ACCOUNT,
            KYX_FORMS_KEYS.VALUES,
          ],
          label: "Purpose of Opening a Spenmo Account",
          required: true,
          rules: [
            {
              required: true,
              message: "Please select purpose of opening a Spenmo account",
            },
          ],
          validateStatus: getValidationstatus(remarks, [
            KYX_FORMS_KEYS.GENERAL_INFORMATION,
            KYX_FORMS_KEYS.PURPOSE_OF_OPENING_ACCOUNT,
            KYX_FORMS_KEYS.VALUES,
          ]),
          help: getValidationError(
            remarks,
            [KYX_FORMS_KEYS.GENERAL_INFORMATION, KYX_FORMS_KEYS.PURPOSE_OF_OPENING_ACCOUNT, KYX_FORMS_KEYS.VALUES],
            "Purpose of Opening a Spenmo Account",
            FORM_NAME.COMPANY_INFORMATION
          ),
          style: { marginBottom: showOthersInputField ? ".5rem" : "1.25rem" },
        },
        checkProps: {
          className: styles.info_check_list,
          disabled: isFormDisabled,
          options: [
            {
              label: PURPOSE_OF_OPENING_ACCOUNT_LABEL[PURPOSE_OF_OPENING_ACCOUNT_OPTIONS.SALARIES],
              value: PURPOSE_OF_OPENING_ACCOUNT_VALUE[PURPOSE_OF_OPENING_ACCOUNT_OPTIONS.SALARIES],
            },
            {
              label: PURPOSE_OF_OPENING_ACCOUNT_LABEL[PURPOSE_OF_OPENING_ACCOUNT_OPTIONS.PAYMENT_TO_VENDORS],
              value: PURPOSE_OF_OPENING_ACCOUNT_VALUE[PURPOSE_OF_OPENING_ACCOUNT_OPTIONS.PAYMENT_TO_VENDORS],
            },
            {
              label: PURPOSE_OF_OPENING_ACCOUNT_LABEL[PURPOSE_OF_OPENING_ACCOUNT_OPTIONS.CASH_REIMBURSEMENT],
              value: PURPOSE_OF_OPENING_ACCOUNT_VALUE[PURPOSE_OF_OPENING_ACCOUNT_OPTIONS.CASH_REIMBURSEMENT],
            },
            {
              label: PURPOSE_OF_OPENING_ACCOUNT_LABEL[PURPOSE_OF_OPENING_ACCOUNT_OPTIONS.CORPORATE_SPENDING],
              value: PURPOSE_OF_OPENING_ACCOUNT_VALUE[PURPOSE_OF_OPENING_ACCOUNT_OPTIONS.CORPORATE_SPENDING],
            },
            {
              label: PURPOSE_OF_OPENING_ACCOUNT_LABEL[PURPOSE_OF_OPENING_ACCOUNT_OPTIONS.OTHER],
              value: PURPOSE_OF_OPENING_ACCOUNT_VALUE[PURPOSE_OF_OPENING_ACCOUNT_OPTIONS.OTHER],
              onChange: () => {
                setShowOthersInputField((prev) => {
                  if (prev) {
                    form.setFieldsValue({
                      [FORM_NAME.COMPANY_INFORMATION]: {
                        [KYX_FORMS_KEYS.GENERAL_INFORMATION]: {
                          [KYX_FORMS_KEYS.PURPOSE_OF_OPENING_ACCOUNT]: {
                            [KYX_FORMS_KEYS.OTHER_VALUE]: undefined,
                          },
                        },
                      },
                    });
                  }
                  return !prev;
                });
              },
            },
          ],
        },
      },
    },
  };

  const EMPTY_SPAN: IFormElement = {
    key: FORM_NAME.COMPANY_INFORMATION + "empty_span_2",
    col: { span: 8 },
    element: {
      type: null,
    },
  };

  const OTHER_PURPOSE_OF_OPENING_ACCOUNT: IFormElement = {
    key:
      FORM_NAME.COMPANY_INFORMATION +
      KYX_FORMS_KEYS.GENERAL_INFORMATION +
      KYX_FORMS_KEYS.PURPOSE_OF_OPENING_ACCOUNT +
      KYX_FORMS_KEYS.OTHER_VALUE,
    col: { span: 12 },
    element: {
      type: FORM_FIELD_TYPES.INPUT,
      props: {
        formProps: {
          name: [
            FORM_NAME.COMPANY_INFORMATION,
            KYX_FORMS_KEYS.GENERAL_INFORMATION,
            KYX_FORMS_KEYS.PURPOSE_OF_OPENING_ACCOUNT,
            KYX_FORMS_KEYS.OTHER_VALUE,
          ],
          required: showOthersInputField,
          rules: [
            { whitespace: true, message: "This field cannot be empty" },
            { required: showOthersInputField, message: "This field cannot be empty" },
          ],
          hidden: showOthersInputField,
          dependencies: [
            KYX_FORMS_KEYS.GENERAL_INFORMATION,
            KYX_FORMS_KEYS.PURPOSE_OF_OPENING_ACCOUNT,
            KYX_FORMS_KEYS.VALUES,
          ],
          style: { display: showOthersInputField ? "block" : "none" },
          validateStatus: getValidationstatus(remarks, [
            KYX_FORMS_KEYS.GENERAL_INFORMATION,
            KYX_FORMS_KEYS.PURPOSE_OF_OPENING_ACCOUNT,
            KYX_FORMS_KEYS.OTHER_VALUE,
          ]),
          help: getValidationError(
            remarks,
            [KYX_FORMS_KEYS.GENERAL_INFORMATION, KYX_FORMS_KEYS.PURPOSE_OF_OPENING_ACCOUNT, KYX_FORMS_KEYS.OTHER_VALUE],
            "Purpose of opening a Spenmo account",
            FORM_NAME.COMPANY_INFORMATION
          ),
        },
        inputProps: {
          datatype: "string",
          placeholder: "Enter your other purpose of opening a Spenmo account",
          disabled: isFormDisabled,
        },
      },
    },
  };

  const MONTHLY_VOLUME_OF_TRNX: IFormElement = {
    key:
      FORM_NAME.COMPANY_INFORMATION +
      KYX_FORMS_KEYS.GENERAL_INFORMATION +
      KYX_FORMS_KEYS.EXPECTED_MONTHLY_VOLUME_OF_TRANSACTION,
    col: { span: 24 },
    element: {
      type: FORM_FIELD_TYPES.RADIO,
      props: {
        formProps: {
          name: [
            FORM_NAME.COMPANY_INFORMATION,
            KYX_FORMS_KEYS.GENERAL_INFORMATION,
            KYX_FORMS_KEYS.EXPECTED_MONTHLY_VOLUME_OF_TRANSACTION,
          ],
          label: "Expected Monthly Volume of Transactions with Spenmo",
          required: true,
          rules: [
            {
              required: true,
              message: "Please select expected monthly volume of transactions with Spenmo",
            },
          ],
          validateStatus: getValidationstatus(remarks, [
            KYX_FORMS_KEYS.GENERAL_INFORMATION,
            KYX_FORMS_KEYS.EXPECTED_MONTHLY_VOLUME_OF_TRANSACTION,
          ]),
          help: getValidationError(
            remarks,
            [KYX_FORMS_KEYS.GENERAL_INFORMATION, KYX_FORMS_KEYS.EXPECTED_MONTHLY_VOLUME_OF_TRANSACTION],
            "Expected Monthly Volume of Transactions with Spenmo",
            FORM_NAME.COMPANY_INFORMATION
          ),
        },
        radioProps: {
          optionType: "button",
          disabled: isFormDisabled,
          options: [
            {
              label: MONTHLY_VOLUME_OF_TRNX_OPTIONS_LABEL[MONTHLY_VOLUME_OF_TRNX_OPTIONS.LESS_THAN_25],
              value: MONTHLY_VOLUME_OF_TRNX_OPTIONS_VALUE[MONTHLY_VOLUME_OF_TRNX_OPTIONS.LESS_THAN_25],
            },
            {
              label: MONTHLY_VOLUME_OF_TRNX_OPTIONS_LABEL[MONTHLY_VOLUME_OF_TRNX_OPTIONS.TWENTY_FIVE_TO_49],
              value: MONTHLY_VOLUME_OF_TRNX_OPTIONS_VALUE[MONTHLY_VOLUME_OF_TRNX_OPTIONS.TWENTY_FIVE_TO_49],
            },
            {
              label: MONTHLY_VOLUME_OF_TRNX_OPTIONS_LABEL[MONTHLY_VOLUME_OF_TRNX_OPTIONS.FIFTY_TO_99],
              value: MONTHLY_VOLUME_OF_TRNX_OPTIONS_VALUE[MONTHLY_VOLUME_OF_TRNX_OPTIONS.FIFTY_TO_99],
            },
            {
              label: MONTHLY_VOLUME_OF_TRNX_OPTIONS_LABEL[MONTHLY_VOLUME_OF_TRNX_OPTIONS.HUNDERED_TO_249],
              value: MONTHLY_VOLUME_OF_TRNX_OPTIONS_VALUE[MONTHLY_VOLUME_OF_TRNX_OPTIONS.HUNDERED_TO_249],
            },
            {
              label: MONTHLY_VOLUME_OF_TRNX_OPTIONS_LABEL[MONTHLY_VOLUME_OF_TRNX_OPTIONS.TWO_HUNDERED_FIFTY_TO_500],
              value: MONTHLY_VOLUME_OF_TRNX_OPTIONS_VALUE[MONTHLY_VOLUME_OF_TRNX_OPTIONS.TWO_HUNDERED_FIFTY_TO_500],
            },
            {
              label: MONTHLY_VOLUME_OF_TRNX_OPTIONS_LABEL[MONTHLY_VOLUME_OF_TRNX_OPTIONS.MORE_THAN_500],
              value: MONTHLY_VOLUME_OF_TRNX_OPTIONS_VALUE[MONTHLY_VOLUME_OF_TRNX_OPTIONS.MORE_THAN_500],
            },
          ],
        },
      },
    },
  };

  const MONTHLY_VALUE_OF_TRNX_SGD: IFormElement = {
    key:
      FORM_NAME.COMPANY_INFORMATION +
      KYX_FORMS_KEYS.GENERAL_INFORMATION +
      KYX_FORMS_KEYS.EXPECTED_MONTHLY_VALUE_OF_TRANSACTION,
    col: { span: 24 },
    element: {
      type: FORM_FIELD_TYPES.RADIO,
      props: {
        formProps: {
          name: [
            FORM_NAME.COMPANY_INFORMATION,
            KYX_FORMS_KEYS.GENERAL_INFORMATION,
            KYX_FORMS_KEYS.EXPECTED_MONTHLY_VALUE_OF_TRANSACTION,
          ],
          label: `Expected Monthly Value of Transactions with Spenmo (${KYX_COUNTRY_LIST.SGD})`,
          required: true,
          rules: [
            {
              required: true,
              message: `Please select expected monthly value of transactions with spenmo (${KYX_COUNTRY_LIST.SGD})`,
            },
          ],
          validateStatus: getValidationstatus(remarks, [
            KYX_FORMS_KEYS.GENERAL_INFORMATION,
            KYX_FORMS_KEYS.EXPECTED_MONTHLY_VALUE_OF_TRANSACTION,
          ]),
          help: getValidationError(
            remarks,
            [KYX_FORMS_KEYS.GENERAL_INFORMATION, KYX_FORMS_KEYS.EXPECTED_MONTHLY_VALUE_OF_TRANSACTION],
            `Expected Monthly Value of Transactions with Spenmo (${KYX_COUNTRY_LIST.SGD})`,
            FORM_NAME.COMPANY_INFORMATION
          ),
        },
        radioProps: {
          optionType: "button",
          disabled: isFormDisabled,
          options: [
            {
              label: MONTHLY_VALUE_OF_TRNX_SGD_OPTIONS_LABEL[MONTHLY_VALUE_OF_TRNX_SGD_OPTIONS.LESS_THAN_10K],
              value: MONTHLY_VALUE_OF_TRNX_SGD_OPTIONS_VALUE[MONTHLY_VALUE_OF_TRNX_SGD_OPTIONS.LESS_THAN_10K],
            },
            {
              label: MONTHLY_VALUE_OF_TRNX_SGD_OPTIONS_LABEL[MONTHLY_VALUE_OF_TRNX_SGD_OPTIONS.TEN_TO_25K],
              value: MONTHLY_VALUE_OF_TRNX_SGD_OPTIONS_VALUE[MONTHLY_VALUE_OF_TRNX_SGD_OPTIONS.TEN_TO_25K],
            },
            {
              label: MONTHLY_VALUE_OF_TRNX_SGD_OPTIONS_LABEL[MONTHLY_VALUE_OF_TRNX_SGD_OPTIONS.TWENTY_FIVE_TO_50K],
              value: MONTHLY_VALUE_OF_TRNX_SGD_OPTIONS_VALUE[MONTHLY_VALUE_OF_TRNX_SGD_OPTIONS.TWENTY_FIVE_TO_50K],
            },
            {
              label: MONTHLY_VALUE_OF_TRNX_SGD_OPTIONS_LABEL[MONTHLY_VALUE_OF_TRNX_SGD_OPTIONS.FIFTY_TO_100K],
              value: MONTHLY_VALUE_OF_TRNX_SGD_OPTIONS_VALUE[MONTHLY_VALUE_OF_TRNX_SGD_OPTIONS.FIFTY_TO_100K],
            },
            {
              label: MONTHLY_VALUE_OF_TRNX_SGD_OPTIONS_LABEL[MONTHLY_VALUE_OF_TRNX_SGD_OPTIONS.HUNDERED_TO_250K],
              value: MONTHLY_VALUE_OF_TRNX_SGD_OPTIONS_VALUE[MONTHLY_VALUE_OF_TRNX_SGD_OPTIONS.HUNDERED_TO_250K],
            },
            {
              label: MONTHLY_VALUE_OF_TRNX_SGD_OPTIONS_LABEL[MONTHLY_VALUE_OF_TRNX_SGD_OPTIONS.MORE_THAN_250K],
              value: MONTHLY_VALUE_OF_TRNX_SGD_OPTIONS_VALUE[MONTHLY_VALUE_OF_TRNX_SGD_OPTIONS.MORE_THAN_250K],
            },
          ],
        },
      },
    },
  };

  return {
    key: FORM_NAME.COMPANY_INFORMATION + `${KYX_FORMS_KEYS.GENERAL_INFORMATION}_3`,
    row: { gutter: 40 },
    document: [
      PURPOSE_OF_OPENING_ACCOUNT,
      EMPTY_SPAN,
      OTHER_PURPOSE_OF_OPENING_ACCOUNT,
      MONTHLY_VOLUME_OF_TRNX,
      MONTHLY_VALUE_OF_TRNX_SGD,
    ],
  };
};

export const useGeneralInformationFormSGD = (
  form: FormInstance,
  formData: IFetchFormSubmissionPayload,
  remarks: IGetFormConfigsPayload,
  isFormDisabled: boolean
): IDocumentStep => {
  return {
    key: FORM_NAME.COMPANY_INFORMATION + KYX_FORMS_KEYS.GENERAL_INFORMATION,
    type: DOCUMENT_STEP_TYPE.MULTIPLE_STEPS,
    container: { type: FORM_SECTION_COMPONENT.CARD, props: { number: 1.1, title: "General Information" } },
    documents: [
      useGeneralInformationFormPartOneSGD(form, formData, remarks, isFormDisabled),
      useGeneralInformationFormPartTwoSGD(form, formData, remarks, isFormDisabled),
      useGeneralInformationFormPartThreeSGD(form, formData, remarks, isFormDisabled),
    ],
  };
};
