import React from "react";
import { Tooltip } from "antd";
import PropTypes from "prop-types";
import classNames from "classnames";

import "./TooltipOnIcon.scss";

/*
    This component describes a basic icon with a tooltip

    Basic Usage:
    <TooltipOnIcon
      imageSrc="link/to/image"
      description={"Your tooltip content goes here"}
    />

  *** Note : If you change the color property, make sure to update the background-color for
  .ant-tooltip-arrow:before, as well. This className updates the color for the arrow
  pointer in the tooltip. In antd v4.3.1, the arrow pointer color needs to be changed seperately from the tooltip component
*/

const TooltipOnIcon = ({
  imageSrc,
  altText,
  description,
  color = "white",
  trigger = "hover",
  placement = "top",
  overlayInnerStyle = { color: "black" },
  imageClassName = "",
  ...props
}) => {
  return (
    <Tooltip
      color={color}
      trigger={trigger}
      title={description}
      placement={placement}
      overlayInnerStyle={overlayInnerStyle}
      {...props}
    >
      <img className={classNames("tooltip__icon", imageClassName)} src={imageSrc} alt={altText} />
    </Tooltip>
  );
};

TooltipOnIcon.propTypes = {
  color: PropTypes.string,
  trigger: PropTypes.string,
  imageSrc: PropTypes.string,
  altText: PropTypes.string,
  placement: PropTypes.string,
  description: PropTypes.string,
  overlayInnerStyle: PropTypes.object,
  imageClassName: PropTypes.string,
};

export default TooltipOnIcon;
