import React, { useMemo, useReducer } from "react";
import { DeleteTeamContext, DeleteTeamEligibilityContext } from "Views/Team/Delete/context";
import APIclient from "API/Client";
import { IDeleteEligibilityResponse, IDeleteTeamContext, IDeleteTeamEligibilityContext } from "./type";
import DeleteTeamEligibilityReducer, {
  deleteTeamEligibilityInitialState,
  eligibilityError,
  eligibilityLoading,
  eligibilityReset,
  eligibilitySuccess,
} from "Redux/Reducers/Teams/DeleteTeamEligibilityReducer";
import DeleteTeamReducer, {
  deleteTeamError,
  deleteTeamInitialState,
  deleteTeamLoading,
  deleteTeamReset,
  deleteTeamSuccess,
} from "Redux/Reducers/Teams/DeleteTeamReducer";
import { AxiosResponse } from "axios";
import { useDispatch } from "react-redux";
import { deleteTeamFromList } from "Redux/Actions/Teams/paginatedTeamListAction";

const DeleteTeamProvider = ({ children }: { children: React.ReactNode }) => {
  const [eligibilityState, dispatchEligibilityCheck] = useReducer(
    DeleteTeamEligibilityReducer,
    deleteTeamEligibilityInitialState
  );
  const [deleteTeamState, dispatchDeleteTeam] = useReducer(DeleteTeamReducer, deleteTeamInitialState);
  const dispatch = useDispatch();

  const checkEligibility = async (id: string) => {
    dispatchEligibilityCheck(eligibilityLoading);
    try {
      const response = await APIclient.getData(`/ms/spm-organisation/v1/mgmt/budgets/${id}/delete-validation`);
      const responseData = response.data;
      if (responseData?.payload?.validationErrors?.length > 0) {
        dispatchEligibilityCheck(eligibilityError(responseData));
      } else {
        dispatchEligibilityCheck(eligibilitySuccess(responseData));
      }
    } catch (error) {
      const response = error.response;
      const { data } = response as AxiosResponse<IDeleteEligibilityResponse>;
      dispatchEligibilityCheck(eligibilityError(data.payload));
    }
  };

  const deleteTeam = async (id: string) => {
    dispatchDeleteTeam(deleteTeamLoading);
    try {
      await APIclient.deleteData(`/ms/spm-organisation/v1/mgmt/budgets/${id}`);
      dispatchDeleteTeam(deleteTeamSuccess());
      dispatch(deleteTeamFromList(id));
    } catch (error) {
      dispatchDeleteTeam(deleteTeamError());
    }
  };

  const eligibilityContext: IDeleteTeamEligibilityContext = {
    data: eligibilityState.data,
    loading: eligibilityState.loading,
    isEligible: !eligibilityState.error,
    checkEligibility,
    resetEligibilityContext: () => dispatchEligibilityCheck(eligibilityReset),
  };

  const { loading: loadingDeleteTeam, error: errorDeleteTeam } = deleteTeamState;
  const teamDeleted = deleteTeamState.data.payload.status_message === "OK";
  const deleteContext: IDeleteTeamContext = useMemo(() => {
    return {
      deleted: teamDeleted,
      loading: loadingDeleteTeam,
      error: errorDeleteTeam,
      deleteTeam,
      resetDeleteTeamContext: () => dispatchDeleteTeam(deleteTeamReset),
    };
  }, [teamDeleted, loadingDeleteTeam, errorDeleteTeam]);

  return (
    <DeleteTeamEligibilityContext.Provider value={eligibilityContext}>
      <DeleteTeamContext.Provider value={deleteContext}>{children}</DeleteTeamContext.Provider>
    </DeleteTeamEligibilityContext.Provider>
  );
};

export default DeleteTeamProvider;
