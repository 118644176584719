import React, { useEffect } from "react";
import Carousel from "react-elastic-carousel";

import { ModalComponent } from "Modules/DS/Modal";
import { TITLE_TYPE } from "Modules/DS/Modal/types";

import { removeDuplicates } from "Views/Transaction/Helper";
import { useVerifiedReceipts } from "Views/Transaction/Details";

import { SliderArrows } from "./Arrows";
import { AttachmentPreview } from "./Preview";

import { errorIcon } from "assets/img";
import styles from "./attachment.module.scss";
import "./carousel.scss";

export const ViewAttachments = ({ receipts, isBillsTrxnsPage }: { receipts: string[]; isBillsTrxnsPage?: boolean }) => {
  const { fetchReceipts, attachments, loading, error } = useVerifiedReceipts(receipts, false, isBillsTrxnsPage);

  useEffect(() => {
    fetchReceipts();
  }, [fetchReceipts]);

  const data = removeDuplicates(attachments.current);

  let child: JSX.Element = null;

  const renderLoader = () => {
    return (
      <div className={styles.content} data-testid="loader">
        <div className={styles.loaderWrapper}>
          <ModalComponent.Loader />
        </div>
      </div>
    );
  };

  const renderErrorState = () => {
    return (
      <div className={styles.content}>
        <div className={styles.errorState}>
          <img src={errorIcon} alt="error icon" />
          <p>Cannot load file preview. Please click the button below to reload</p>
          <button onClick={fetchReceipts}>Reload Preview</button>
        </div>
      </div>
    );
  };

  const renderCarousel = () => {
    return (
      <Carousel isRTL={false} renderArrow={SliderArrows} itemsToScroll={1} disableArrowsOnEnd>
        {data.map((url) => (
          <AttachmentPreview key={url.length + url} url={url} />
        ))}
      </Carousel>
    );
  };

  if (loading) {
    child = renderLoader();
  } else if (!error && data.length) {
    child = renderCarousel();
  } else {
    child = renderErrorState();
  }

  return (
    <>
      <ModalComponent.CloseButton />
      <ModalComponent.Title titleType={TITLE_TYPE.largeWithBorder}>File Preview</ModalComponent.Title>
      {child}
    </>
  );
};
