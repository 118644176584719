import * as React from "react";

import { IRequirement, ISettlement } from "Views/Employee/types";
import { Item } from "Views/Employee/Modal/Requirement/Settlement/Item";

import styles from "./Settlement.module.scss";

export const Settlement = ({ children }: { children: IRequirement<ISettlement>[] }) => {
  return (
    <div data-testid="settlement requirements" className={styles.container}>
      {children.map(({ id, requirement }: IRequirement<ISettlement>) => (
        <Item key={id} {...requirement} />
      ))}
    </div>
  );
};
