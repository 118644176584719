import * as React from "react";
import { useState } from "react";

import SearchDropdown from "Modules/DS/SearchDropdown";
import Tag from "Modules/DS/Atoms/Tag";
import { ContextualMenuItem } from "Modules/DS/ContextualMenu/types";
import { useActiveCategory } from "utility/useActiveCategory";
import { CategoryConditionObject, ConditionFormProps } from "../../../../types";

interface CategoryConditionFormProps extends ConditionFormProps {
  values: Array<CategoryConditionObject>;
}

const CategoryConditionForm: React.FC<CategoryConditionFormProps> = ({
  values,
  setValues,
  isExpanded,
  toggleExpand,
}: CategoryConditionFormProps): JSX.Element => {
  const { categoryList } = useActiveCategory();
  const [search, setSearch] = useState<string>("");

  return (
    <>
      {isExpanded ? (
        <SearchDropdown
          className="category-condition__dropdown"
          label="Select Categories"
          placeholder="Search categories by name"
          values={
            values?.map<ContextualMenuItem>((item) => ({
              ...item,
              label: item.name,
              value: String(item.id),
              error: item.isDeleted
                ? "Expense Category no longer exists. Please delete this Category and select a new one to proceed."
                : "",
            })) || []
          }
          onChange={(val) => {
            if (val.length && (val.length >= values?.length || !values)) toggleExpand();
            setValues(
              val.map((item) => ({
                ...item,
                id: item.value,
                name: item.label || item.value,
              }))
            );
          }}
          onSearch={setSearch}
          defaultOpen
          multiple
          options={categoryList
            ?.filter((category) => category.categoryName.toLowerCase().indexOf(search.toLowerCase()) > -1)
            ?.map((category) => ({ value: String(category.id), label: category.categoryName }))}
        />
      ) : (
        <div className="selected__item__tags">
          {values?.map((item) => (
            <Tag key={item.id}>{item.name || item.id}</Tag>
          ))}
        </div>
      )}
    </>
  );
};

export default CategoryConditionForm;
