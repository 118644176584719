export const PaymentMockData: any = [
  {
    id: "bfb48fc2-f4af-11ed-9d37-02c0d9ebd820",
    transaction_number: "TRXBEL6464cacf0d4d4",
    type: "Card",
    transaction_type: "Card",
    status: "completed",
    accounting_status: "COMPLETED",
    accounting_message: "",
    description: "Purchase TestOne Lambda 2023-05-17 20:38:37 UTC Card : 6470 SGD 0.01",
    currency: "SGD",
    amount: 0.01,
    previous_balance: 41316.54,
    balance: 41316.53,
    organisation_id: "f33ee556-86e6-11eb-9d9d-0242ac110003",
    organisation_name: "BEL DEL",
    team_id: "f341412a-86e6-11eb-b045-0242ac110003",
    team_name: "Marketing budget",
    payer_id: "d7ddb117-ed9e-4ee2-b7a7-d8c79f4a5287",
    payer_name: "TestOne Lambda",
    payee_id: "",
    payee_name: "Google",
    category: "Default",
    tax_amount: 0,
    foreign_exchange_rate: 1,
    notes: "",
    receipts: [],
    line_items: [
      {
        id: "bfb98720-f4af-11ed-96a0-02c0d9ebd820",
        category: "Default",
        comment: "",
        tags: null,
        amount: 0.01,
        tax_amount: 0,
      },
    ],
    tags: null,
    created_at: "2023-05-17T12:38:39Z",
    updated_at: "2023-05-17T12:38:39Z",
    sort: [1684327119000, "bfb48fc2-f4af-11ed-9d37-02c0d9ebd820"],
  },
  {
    id: "96a0c056-f4af-11ed-8e2c-06fefc3c4bac",
    transaction_number: "TRXBEL6464ca89c2f02",
    type: "Card",
    transaction_type: "Card",
    status: "completed",
    accounting_status: "COMPLETED",
    accounting_message: "",
    description: "Purchase TestOne Lambda 2023-05-17 20:37:26 UTC Card : 6470 SGD 0.01",
    currency: "SGD",
    amount: 0.01,
    previous_balance: 41316.55,
    balance: 41316.54,
    organisation_id: "f33ee556-86e6-11eb-9d9d-0242ac110003",
    organisation_name: "BEL DEL",
    team_id: "f341412a-86e6-11eb-b045-0242ac110003",
    team_name: "Marketing budget",
    payer_id: "d7ddb117-ed9e-4ee2-b7a7-d8c79f4a5287",
    payer_name: "TestOne Lambda",
    payee_id: "",
    payee_name: "Google",
    category: "Default",
    tax_amount: 0,
    foreign_exchange_rate: 1,
    notes: "",
    receipts: null,
    line_items: [
      {
        id: "96a7a934-f4af-11ed-90ff-06fefc3c4bac",
        category: "Default",
        comment: "",
        tags: null,
        amount: 0.01,
        tax_amount: 0,
      },
    ],
    tags: null,
    created_at: "2023-05-17T12:37:30Z",
    updated_at: "2023-05-17T12:37:30Z",
    sort: [1684327050000, "96a0c056-f4af-11ed-8e2c-06fefc3c4bac"],
  },
  {
    id: "3738ce02-9315-11ed-9148-0a58a9feac02",
    transaction_number: "TRXBEL63c10a5cbe48f",
    type: "Card (Expense)",
    transaction_type: "Card (Expense)",
    status: "completed",
    accounting_status: "FAILED_SYNC",
    accounting_message: "Unauthorized, please reconnect to Xero",
    description: "Purchase TestOne Lambda 2023-01-13 15:38:04 +0800 UTC Card : 6470 SGD 0.01",
    currency: "SGD",
    amount: 0.01,
    previous_balance: 41316.56,
    balance: 41316.55,
    organisation_id: "f33ee556-86e6-11eb-9d9d-0242ac110003",
    organisation_name: "BEL DEL",
    team_id: "f341412a-86e6-11eb-b045-0242ac110003",
    team_name: "Marketing budget",
    payer_id: "d7ddb117-ed9e-4ee2-b7a7-d8c79f4a5287",
    payer_name: "TestOne Lambda",
    payee_id: "",
    payee_name: "Google",
    category: "Default",
    tax_amount: 0,
    foreign_exchange_rate: 1,
    notes: "Testing again yayayadd",
    receipts: [
      {
        url: "https://api.spenmo-staging.com/api/v1/receipt/0d9809ba-b6be-11ed-91bc-0a58a9feac02/url",
      },
      {
        url: "https://api.spenmo-staging.com/api/v1/receipt/0d984736-b6be-11ed-9295-0a58a9feac02/url",
      },
      {
        url: "https://api.spenmo-staging.com/api/v1/receipt/4ea12cac-c17c-11ed-b31a-02ddbc532dbc/url",
      },
      {
        url: "https://api.spenmo-staging.com/api/v1/receipt/4ea205e6-c17c-11ed-9cd9-02ddbc532dbc/url",
      },
      {
        url: "https://api.spenmo-staging.com/api/v1/receipt/58da46aa-9e26-11ed-ad9c-0277c7461f34/url",
      },
      {
        url: "https://api.spenmo-staging.com/api/v1/receipt/8e49d98a-b691-11ed-931f-0a58a9feac02/url",
      },
      {
        url: "https://api.spenmo-staging.com/api/v1/receipt/8e4acade-b691-11ed-be35-0a58a9feac02/url",
      },
      {
        url: "https://api.spenmo-staging.com/api/v1/receipt/b013a51a-b24e-11ed-b297-0a58a9feac02/url",
      },
      {
        url: "https://api.spenmo-staging.com/api/v1/receipt/fdeb510c-b6bd-11ed-839e-0a58a9feac02/url",
      },
      {
        url: "https://api.spenmo-staging.com/api/v1/receipt/fdebac2e-b6bd-11ed-a374-0a58a9feac02/url",
      },
    ],
    line_items: [
      {
        id: "373cb738-9315-11ed-915d-0a58a9feac02",
        category: "Default",
        comment: "Testing again yayayadd",
        tags: null,
        amount: 0.01,
        tax_amount: 0,
      },
    ],
    tags: null,
    created_at: "2023-01-13T07:38:04Z",
    updated_at: "2023-01-13T07:38:04Z",
    sort: [1673595484000, "3738ce02-9315-11ed-9148-0a58a9feac02"],
  },
  {
    id: "20e96728-6bec-11ee-b83e-0a58a9feac02",
    transaction_number: "BILL-f33EeYh143DEAhr3Dt0j95A",
    type: "Bill Payment",
    transaction_type: "Bill Payment",
    status: "completed",
    accounting_status: "COMPLETED",
    accounting_message: "",
    description: "Invoice Expense BEL DEL 2023-10-16 invoice id : F303_TEOM_20230914_9KTQT SGD 101",
    currency: "SGD",
    amount: 101,
    previous_balance: 38194.33,
    balance: 38093.33,
    organisation_id: "f33ee556-86e6-11eb-9d9d-0242ac110003",
    organisation_name: "BEL DEL",
    team_id: "f341412a-86e6-11eb-b045-0242ac110003",
    team_name: "Marketing budget new",
    payer_id: "f34e5608-86e6-11eb-bd0a-0242ac110003",
    payer_name: "ben test test",
    payee_id: "",
    payee_name: "Test Alfa Dom",
    category: "Entertainment",
    tax_amount: 0,
    foreign_exchange_rate: 1,
    notes: "",
    receipts: [
      {
        url: "https://api.spenmo-staging.com/api/v1/receipt/229cfaa8-6bec-11ee-b495-0a58a9feac02/url",
      },
    ],
    line_items: [
      {
        id: "20ef3626-6bec-11ee-a300-0a58a9feac02",
        category: "Entertainment",
        comment: "",
        tags: null,
        amount: 101,
        tax_amount: 0,
      },
    ],
    tags: null,
    created_at: "2023-10-16T06:20:40Z",
    updated_at: "2023-10-16T06:20:43Z",
    sort: [1697437240000, "20e96728-6bec-11ee-b83e-0a58a9feac02"],
  },
];

export const dummyPayments = Array(5)
  .fill({})
  .map((_, index) => ({
    ...PaymentMockData[0],
    id: `Mocked_payment_${index}`,
  }));

export default PaymentMockData;
