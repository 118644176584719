import { createContext, useContext } from "react";

import { ISettingsModalContext } from "Views/Settings/@types";

export enum SETTINGS_MODAL_TYPES {
  NONE = "NONE",
  CONFIRM_REIMBURSEMENT_SETTINGS = "CONFIRM_REIMBURSEMENT_SETTINGS",
}

export const initialSettingsModalContext: ISettingsModalContext = {
  resetModal: () => null,
  toggleModal: () => null,
  setModal: () => null,
  modal: {
    type: SETTINGS_MODAL_TYPES.NONE,
    props: { showModal: false, size: "m", title: "" },
    payload: {},
  },
};

export const SettingsModalContext = createContext<ISettingsModalContext>(initialSettingsModalContext);

export const useSettingsModalContext: () => ISettingsModalContext = () => useContext(SettingsModalContext);
