import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { clearDeleteEmployeeCheck } from "Redux/Actions/Employee/Delete/Check";
import { IDeleteEmployeeFromOrgReducer } from "Redux/Actions/Employee/Delete/types";
import { IDeleteEmployeeCheckReducer } from "Redux/Actions/Employee/Delete/Check/types";
import { clearDeleteEmployeeFromOrg, deleteEmployeeFromOrg } from "Redux/Actions/Employee/Delete";

import useDeleteModalType from "Views/Employee/Delete/Modal/useDeleteModalType";
import { DELETE_EMPLOYEE_MODAL_TYPES, IEmployeeModalType } from "Views/Employee/types";
import { EmployeesModalContext, IEmployeesModalContext } from "Views/Employee/context";

import "./index.scss";

const useDeletionModal = (): IEmployeeModalType => {
  const dispatch = useDispatch();

  const initalStep = DELETE_EMPLOYEE_MODAL_TYPES.CHECK_ELIGIBILITY;

  const [type, setType] = useState(initalStep);

  const { resetModal }: IEmployeesModalContext = useContext(EmployeesModalContext);

  const deletePreChecks: IDeleteEmployeeCheckReducer = useSelector((state: any) => state.deleteEmployeeCheckReducer);
  const { data: dataFromCheck, error: errorFromCheck } = !!deletePreChecks && deletePreChecks;
  const { id, name } = !!dataFromCheck && dataFromCheck;

  const deleteFromOrg: IDeleteEmployeeFromOrgReducer = useSelector((state: any) => state.deleteEmployeeFromOrgReducer);
  const { error: errorFromOrg, data: dataFromOrg } = !!deleteFromOrg && deleteFromOrg;

  const onClose = () => {
    resetModal();
    setType(initalStep);
    dispatch(clearDeleteEmployeeCheck());
  };

  const confirmDelete = () => {
    setType(DELETE_EMPLOYEE_MODAL_TYPES.IN_PROGRESS);
    dispatch(deleteEmployeeFromOrg(id, name));
  };

  useEffect(() => {
    let modal: DELETE_EMPLOYEE_MODAL_TYPES;

    if (!!dataFromCheck) {
      const { is_eligible, is_success } = dataFromCheck;
      if (is_success) {
        modal = is_eligible
          ? DELETE_EMPLOYEE_MODAL_TYPES.DELETE_EMPLOYEE_PROMPT
          : DELETE_EMPLOYEE_MODAL_TYPES.RESOLVE_PENDING_ITEMS;
      } else if (errorFromCheck) {
        modal = DELETE_EMPLOYEE_MODAL_TYPES.FAILED;
      }
    }

    modal && setType(modal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deletePreChecks]);

  useEffect(() => {
    if (!!dataFromOrg) {
      if (errorFromOrg || dataFromOrg.success === false) {
        setType(DELETE_EMPLOYEE_MODAL_TYPES.FAILED);
      } else if (dataFromOrg.success === true) {
        onClose();
      }
    }

    return () => {
      if (!!dataFromOrg) {
        if (errorFromOrg || dataFromOrg.success === false) {
          dispatch(clearDeleteEmployeeFromOrg());
        }
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, deleteFromOrg]);

  return useDeleteModalType(onClose, name, confirmDelete)[type];
};

export default useDeletionModal;
