import { FETCH_DATA_REQUEST, FETCH_DATA_SUCCESS, FETCH_DATA_FAILURE } from "../Actions/asynActions";

export const fetchDataReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_DATA_REQUEST:
      return {
        ...state,
        data: action.data,
        loading: true,
      };
    case FETCH_DATA_SUCCESS:
      return {
        ...state,
        //         [action.comp]: {
        loading: false,
        data: action.data,
        //         }
      };
    case FETCH_DATA_FAILURE:
      return {
        ...state,
        // [action.comp]: {
        loading: false,
        data: action.data,
        error: true,
        // }
      };
    default:
      return state;
  }
};
