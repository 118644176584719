import * as React from "react";

import {
  IPendingRequests,
  IPendingToApprove,
  ISoleTeamManager,
} from "Redux/Actions/Employee/Delete/Check/types";

import singularOrPlural from "utility/Helper/singularOrPlural";

import { PendingType } from "Views/Employee/Modal/Requirement";

import { EmployeeDeleteEventName } from "../../../Analytics";

type ResolveListType = IPendingToApprove | IPendingRequests | ISoleTeamManager;

export const getListItems = (
  data: ResolveListType[],
  item: (props: ResolveListType) => JSX.Element,
) => {
  const Component: (props: ResolveListType) => JSX.Element = item;

  return (
    !!data &&
    data.map((props: ResolveListType, index: number) => (
      <Component key={index} {...props} />
    ))
  );
};

export const ToApprove = ({
  type,
  total_requests,
  unit,
}: IPendingToApprove) => {
  const trailingText = `(${total_requests} ${singularOrPlural(total_requests, unit)})`;

  return <PendingType.SingleLine title={type} trailing={trailingText} />;
};

export const Requests = ({
  type,
  total_requests,
  unit,
  approvers,
}: IPendingRequests) => {
  const trailingText = `(${total_requests} ${singularOrPlural(total_requests, unit)})`;

  const approverNames: string = approvers.join(", ");

  const subtitle = `${singularOrPlural(approvers.length, "Approver")}: ${approverNames}`;

  return (
    <PendingType.MultiLine
      title={type}
      trailing={trailingText}
      subtitle={subtitle}
    />
  );
};

export const SoleTeamManager = ({
  id,
  name,
  total_members,
}: ISoleTeamManager) => {
  const trailingText = `(${total_members} ${singularOrPlural(total_members, "members")})`;

  const analytics = {
    enabled: true,
    eventName:
      EmployeeDeleteEventName.VISIT_SPECIFIC_TEAM_PAGE_FROM_ELIGIBILITY_FAIL_POPUP,
  };

  return (
    <PendingType.SingleLineWithLink
      title={name}
      trailing={trailingText}
      link={`/teamprofile/${id}`}
      text="Go to Budget Profile"
      analytics={analytics}
    />
  );
};
