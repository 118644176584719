import React from "react";
import styles from "./index.module.scss";

const TeamActionsWarning = ({
  contentHeading,
  contentDescription,
  icon,
}: {
  contentHeading: string;
  contentDescription: string;
  icon: React.ReactNode;
}) => {
  return (
    <section className={`${styles["content-body"]}`}>
      <div className={`${styles["content-highlighted"]}`}>
        <div className={`${styles["icon"]}`}>{icon}</div>
        <div className={`${styles["content-text"]}`}>
          <h2 className={`${styles["heading-text"]}`}>{contentHeading}</h2>
          <p className={`${styles["description"]}`}>{contentDescription}</p>
        </div>
      </div>
    </section>
  );
};

export default TeamActionsWarning;
