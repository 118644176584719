import React, { useEffect } from "react";
import { useAppSelector, useAppDispatch } from "hook";
import { getCardOwnerNamesFunc } from "Redux/Actions/CardFilter/GetCardOwnerNames";

import { More } from "Views/Transaction/Filters";

import { ITitleWithID } from "Views/Transaction/@types";

const CardHolderFilter = ({ id, appliedKey = "id" }: { id: string; appliedKey?: keyof ITitleWithID }) => {
  const dispatch = useAppDispatch();

  const {
    loading = false,
    fetched = false,
    data = {},
  } = useAppSelector((state: any) => state.getCardOwnerNamesReducer || {});

  useEffect(() => {
    if (!fetched) {
      dispatch(getCardOwnerNamesFunc());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const cardOwners = Array.isArray(data?.payload?.card_owners) ? data?.payload?.card_owners : [];

  const filterData: ITitleWithID[] = cardOwners.map((cardOwner) => ({
    id: cardOwner,
    title: cardOwner,
  }));

  return (
    <More.Multiple<ITitleWithID>
      id={id}
      label="Cardholder"
      loading={loading}
      displayKey="title"
      appliedKey={appliedKey}
    >
      {filterData}
    </More.Multiple>
  );
};

export default CardHolderFilter;
