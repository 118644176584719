import { CARD_REQUEST_STATUS, CARD_STATUS } from "Views/Card/types";

export const CardTagsInfoMap = {
  [CARD_REQUEST_STATUS.REQUESTED]: {
    label: "Requested",
    tagType: "default",
  },
  [CARD_REQUEST_STATUS.REJECTED]: {
    label: "Rejected",
    tagType: "error",
  },
  [CARD_STATUS.NOT_ACTIVATED]: {
    label: "Not Activated",
    tagType: "default",
  },
  [CARD_STATUS.ENABLED]: {
    label: "Enabled",
    tagType: "success",
  },
  [CARD_STATUS.DISABLED]: {
    label: "Disabled",
    tagType: "error",
  },
  [CARD_STATUS.IN_PROGRESS]: {
    label: "In progress",
    tagType: "default",
  },
};

export const CARD_SETTING_LABEL = {
  CARD_COLOR: "CARD COLOR",
  CARD_TITLE: "CARD TITLE",
  EXPIRY_YEAR: "EXPIRY YEAR",
  MERCHANT_LOCK: "MERCHANT LOCK",
  SPENDING_LIMIT: "SPENDING LIMIT",
  DEFAULT_EXPENSE_CATEGORY: "DEFAULT EXPENSE CATEGORY",
};

export const DEFAULT_TRANSACTION_TAGS_KB_ARTICLE_LINK =
  "https://support.spenmo.com/hc/en-us/articles/12017380639129-Set-Default-Transaction-Tags-for-Cards";
