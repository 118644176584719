import React from "react";
import classNames from "classnames";

import Banner, { IBannerProps } from "Modules/DS/Banner";

import { KYX_BANNER_MESSAGE_TYPE } from "Views/KYX/Constants";
import { IKYXBanner, IKYXBannerList, IKYXBannerTitle } from "Views/KYX/@types";

import styles from "./index.module.scss";

export const KYXBannerList = ({ title, list }: IKYXBannerList) => {
  return (
    <>
      <p className={styles.header}>{title}</p>
      <ul className={styles.list}>
        {list.map(({ key, ...props }) => (
          <li key={key} {...props} />
        ))}
      </ul>
    </>
  );
};

export const KYXBannerWithTitle = ({ title, message }: IKYXBannerTitle) => {
  return (
    <>
      <p className={styles.header}>{title}</p>
      {message}
    </>
  );
};

export const KYXBanner = ({
  message,
  bannerType,
  list,
  messageType,
  icon,
  onClose,
  title,
}: IKYXBanner): JSX.Element => {
  const handleMessageDetails = (messageType: KYX_BANNER_MESSAGE_TYPE): React.ReactNode => {
    switch (messageType) {
      case KYX_BANNER_MESSAGE_TYPE.LIST:
        return <KYXBannerList title={message} list={list} />;
      case KYX_BANNER_MESSAGE_TYPE.TITLE:
        return <KYXBannerWithTitle title={title} message={message} />;
      case KYX_BANNER_MESSAGE_TYPE.TEXT:
      default:
        return message;
    }
  };

  const renderBannerMessage = (): React.ReactNode => {
    return (
      <div
        className={classNames(styles.bannerMsg, {
          [styles.bannerMargin]: messageType === KYX_BANNER_MESSAGE_TYPE.TEXT && onClose,
        })}
      >
        {handleMessageDetails(messageType)}
      </div>
    );
  };

  const bannerProps: IBannerProps = {
    type: bannerType,
    message: renderBannerMessage(),
    ...(icon && { icon }),
    ...(onClose && { onClose }),
  };

  return <Banner {...bannerProps} />;
};
