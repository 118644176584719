import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withRouter } from "react-router";
import withUseConfigHoc from "Route/WithUseConfigHoc";
import { selectTreatmentValue } from "@splitsoftware/splitio-redux";

import ReduxHoc from "Redux/ReduxHOC";
import { fetchTransactionDetails } from "Redux/DataCalls/Transactions.api";

import { trackEvent } from "utility/analytics";
import { urlParamsBuilder } from "utility/APIWrapper";
import { startDateOfMonth, getUrlParam, currencyFormatter, getParamsValue, GetOrgId } from "utility";
import { TRANSACTION_TYPE } from "constants/Transaction.constant";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import { CARD_VENDOR_TYPE } from "constants/QuickAccess.constant";
import { SPLIT_NAMES, SPLIT_TREATMENT_TYPES } from "Redux/splitio/constants";

import { Toaster } from "Modules/toaster";
import ToasterDS from "Modules/DS/Toaster";
import { TOASTER_SIZE_TYPE, TOASTER_STATUS_TYPE } from "Modules/DS/Toaster/types.d";

import ActionButtons from "Modules/ActionButtons";
import RecursiveTable from "Modules/recursiveTable";
import Tabs from "Modules/DS/Tabs";

import ErrorComponent from "Views/State/Error";
import LoaderIcon from "Views/State/Loading/LoaderIcon";
import SendMoneyModal from "Views/SendMoney/SendMoneyModal";
import EditBillModal from "Views/UploadInvoice/EditBillModal";

import SmartCSV from "Views/Transaction/SmartCSV/v1";
import TransactionFilters from "./Filters";
import TransferDetails from "./TransferDetails";
import ScheduledDetails from "./ScheduledDetails";
import TransactionDetails from "./TransactionDetails";
import { pendingColumnData, columnData, scheduledColumnData } from "./ColumnsData";
import { STATUS } from "./Pending/const";
import PendingDetail from "./Pending/Detail";
import TransactionRefundDetails from "./TransactionDetails/TransactionRefundDetails/TransactionRefundDetails";
import { TABS, SUB_TABS, REVERSE_TABS, REVERSE_SUB_TABS, EMAIL_APPROVAL_STATE, TR_STATUS } from "./TransactionsHelper";

import "./Transactions.scss";
import { getTransactionTabs } from "./helper";

import { fetchPermissionForClassComp } from "Permission/PermissionForClassComp";

import { TRANSACTION_LISTING_PERMISSION_PARAMS } from "Views/Transaction/Permission";

import { PRODUCT_NAME } from "Redux/ModularProduct";
import Icon from "Modules/icons";
import { limitExceededInfo } from "assets/img";
import Banner from "Modules/DS/Banner";
import { cookieNames, getCookieValue, setCookie } from "utility/CookieHelper";
import isEqual from "utility/Helper/isEqual";

class TransactionsListing extends React.Component {
  constructor(props) {
    super(props);
    this.divRef = React.createRef();
  }

  static propTypes = {
    isAdmin: PropTypes.bool,
    callApiWithLoader: PropTypes.func,
    callApiWithoutLoader: PropTypes.func,
    data: PropTypes.object,
    error: PropTypes.object,
    loading: PropTypes.bool,
    general: PropTypes.object,
    syncTransaction: PropTypes.func,
    syncData: PropTypes.object,
    syncError: PropTypes.bool,
    syncLoading: PropTypes.bool,
    xeroAuthdata: PropTypes.object,
    limitView: PropTypes.bool,
    newTransactionTypeActive: PropTypes.bool,
    pendingApprovalCount: PropTypes.number,
    extractingCount: PropTypes.number,
    getPendingApprovals: PropTypes.func,
    location: PropTypes.object,
    history: PropTypes.object,
    isSyncButtonAccessAllowed: PropTypes.bool,
    getScheduledTransactions: PropTypes.func,
    scheduledTransactions: PropTypes.object,
    editScheduledTransaction: PropTypes.func,
    editScheduledTransactionData: PropTypes.object,
    stopScheduledTransactions: PropTypes.func,
    stopScheduledTransactionsData: PropTypes.object,
    clearEditScheduledTransaction: PropTypes.func,
    clearStopScheduledTransactions: PropTypes.func,
    b2bOrgDetails: PropTypes.object,
    orgConfigs: PropTypes.object,
    getOrgConfigs: PropTypes.func,
    getOrgDetails: PropTypes.func,
  };

  state = {
    data: this.props.data,
    details: { user_name: "" },
    view: TABS.all,
    filters: {},
    syncToaster: false,
    toasterIsVisible: false,
    toasterMessage: "",
    toasterIsPrimary: false,
    toasterStatus: "",
    pageNum: 0,
    showSideSection: false,
    subTab: null,
    isApproverModalVisible: false,
    setApproverTrx: [],
    setApproverIsBulk: false,
    selectedRows: [],
    scheduledEditValue: null,
    emailApprovalState: null,
    isSmallScreen: window.matchMedia("(max-width: 600px)").matches,
    isAllowedApprovalRequiredTab: false,
    isAllowedEditBtn: false,
    isAllowedStopBtn: false,
    editBillProps: {
      visible: false,
      billID: null,
      onClose: () => undefined,
    },
    editBillToaster: {
      visible: false,
      message: "",
      status: TOASTER_STATUS_TYPE.ERROR,
    },
    useLegacyRefundDetail: false,
    hideEditBillBanner: false,
  };
  PAGE_SIZE = 100;
  DEFAULT_TAB = TABS.all;
  DEFAULT_SUB_TAB = {
    [TABS.pending]: SUB_TABS.all,
  };

  setEditBillProps = (editBillProps) => {
    this.setState({ editBillProps });
  };

  trackTransactionDetails = (eventName, record) => {
    trackEvent(eventName, { transaction_id: record?.id || "" });
  };

  onRowClick = (record) => {
    if (this.state.view === TABS.pending) {
      trackEvent("bill view detailed", record);
    }
    this.trackTransactionDetails("Transaction Details Clicked", record);
    this.setState({ details: record, showSideSection: true });
  };

  getTransactionDetailsFromTxnIdInUrl = () => {
    const [transactionId] = getParamsValue("txn");
    if (transactionId) {
      fetchTransactionDetails(transactionId)
        .then((response) => {
          if (response?.status === HTTP_STATUS_CODE.OK && response?.data?.data?.id) {
            const { data: transactionDetail } = response.data;
            this.setState({ details: transactionDetail, showSideSection: true });
          }
        })
        .catch((err) => console.log(err));
    }
  };

  setFilterValuesFromUrl = () => {
    const [start_date, end_date, team_id, has_attachment] = getParamsValue(
      "startDate",
      "endDate",
      "teamId",
      "hasAttachment"
    );
    if (start_date && end_date && team_id) {
      this.setState({ filters: { start_date, end_date, team_id } });
    }

    if (has_attachment) {
      this.setState({ filters: { ...this.state.filters, has_attachment } });
    }
  };

  componentDidMount() {
    this.setActiveTabFromUrl();
    this.getTransactionDetailsFromTxnIdInUrl();
    this.setFilterValuesFromUrl();
    const handler = (e) => this.setState({ isSmallScreen: e.matches });
    window.matchMedia("(max-width: 600px)").addEventListener("change", handler);

    fetchPermissionForClassComp(TRANSACTION_LISTING_PERMISSION_PARAMS).then((res) => {
      const [isAllowedApprovalRequiredTab, isAllowedEditBtn, isAllowedStopBtn] = res;
      let showApprovalSubTab = isAllowedApprovalRequiredTab;
      this.setState({ isAllowedApprovalRequiredTab: showApprovalSubTab, isAllowedEditBtn, isAllowedStopBtn });
    });

    if (!this.props.orgConfigs.data) {
      this.props.getOrgConfigs(GetOrgId());
    }

    if (!this.props.b2bOrgDetails?.data?.payload) {
      this.props.getOrgDetails(GetOrgId());
    }

    this.setState({
      hideEditBillBanner: getCookieValue(cookieNames.HIDE_EDIT_BILL_BANNER),
      editBillToaster: this.props.history?.location?.state?.editBillToaster || this.state.editBillToaster,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.location.search !== prevProps.location.search) {
      this.setActiveTabFromUrl();
    }

    if (this.props.syncData !== prevProps.syncData) {
      this.setState({ view: TABS.all }, () => {
        this.getData(this.state.view);
      });
    }

    if (
      this.state.view !== prevState.view ||
      this.state.subTab !== prevState.subTab ||
      this.state.pageNum !== prevState.pageNum ||
      this.props.general?.teamId !== prevProps.general?.teamId ||
      !isEqual(this.state.filters, prevState.filters)
    ) {
      this.setState({ selectedRows: [] });
      this.getData(this.state.view, this.state.pageNum);
    }

    if (this.props.data !== prevProps.data) {
      this.setState({ data: this.props.data });
    }

    if (this.props.editScheduledTransactionData?.loading !== prevProps.editScheduledTransactionData?.loading) {
      const editResponse = this.props.editScheduledTransactionData;
      if (!editResponse?.loading && editResponse?.data && !editResponse?.error) {
        this.showToaster("Successfully edited", "success");
        this.getData(this.state.view);
        this.props.clearEditScheduledTransaction();
      } else if (!editResponse?.loading && editResponse?.error) {
        if (editResponse?.errorMessage?.status === 403) {
          this.showToaster("You don't have permission to update this transaction", "error");
        } else {
          this.showToaster("Error while editing schedule", "error");
        }
      }
    }

    if (this.props.stopScheduledTransactionsData?.loading !== prevProps.stopScheduledTransactionsData?.loading) {
      const stopResponse = this.props.stopScheduledTransactionsData;
      if (!stopResponse?.loading && stopResponse?.data && !stopResponse?.error) {
        this.setState({ selectedRows: [] });
        this.showToaster("Successfully stopped", "success");
        this.getData(this.state.view);
        this.props.clearStopScheduledTransactions();
      } else if (!stopResponse?.loading && stopResponse?.error) {
        this.showToaster("Error while stopping schedule", "error");
      }
    }

    if (this.state.showSideSection !== prevState.showSideSection) {
      this.setState({
        useLegacyRefundDetail: false,
      });
    }
  }

  // if the url param tab is `pending` then
  // - we need to check whether the billpay is available or not from org configs flags
  //   * if billpay is available then we can set the active tab to `pending`
  //   * else we need to redirect user to all tab
  // otherwise we can just set the active tab as per url param
  getActiveTab = (urlParamTab) => {
    if (urlParamTab === REVERSE_TABS.Bills) {
      const isBillpayAvailable = this.props.orgConfigs?.data?.payload?.configs?.[PRODUCT_NAME.BILLPAY]?.value ?? false;
      if (isBillpayAvailable) {
        return TABS[urlParamTab];
      } else {
        this.props.history.push(this.props.location.pathname);
        return null;
      }
    }
    return TABS[urlParamTab];
  };

  setActiveTabFromUrl = () => {
    const {
      location: { search: params },
    } = this.props;
    const tab = getUrlParam(params, "tab") ? this.getActiveTab(getUrlParam(params, "tab")) : this.DEFAULT_TAB;
    const subTab = SUB_TABS[getUrlParam(params, "subtab")] || this.DEFAULT_SUB_TAB[tab];
    const invoiceId = getUrlParam(params, "invoiceid");
    const approvalState = getUrlParam(params, "action");

    const newState = {
      view: tab,
      subTab: subTab,
      showSideSection: false,
      selectedRows: [],
      pageNum: 0,
      details: {},
      emailApprovalState: null,
    };

    if (subTab === SUB_TABS.to_approve) {
      this.props.history.push("/approvalRequestCenter");
    }

    if (tab && tab === TABS.pending) {
      const subTabName = subTab.toLowerCase() === "to approve" ? "bill approval" : `${subTab.toLowerCase()} bill`;
      trackEvent(`bill visit ${subTabName} page`);

      if (invoiceId) {
        newState.showSideSection = true;
        newState.details = { id: invoiceId };
        newState.emailApprovalState = approvalState || EMAIL_APPROVAL_STATE.detail;
      }
    }
    if (tab && [TABS.all, TABS.pending, TABS.scheduled].includes(tab)) {
      trackEvent("Transaction Tab Viewed", { transaction_tab_event_source: tab });
    }

    this.setState(newState);
  };

  tabAction = (key, subkey) => {
    const { history, location } = this.props;
    const params = { tab: REVERSE_TABS[key] };

    if (subkey) {
      params.subtab = REVERSE_SUB_TABS[subkey];
    }
    history.push(urlParamsBuilder(location.pathname, params));
  };

  getPendingApprovalCount = () => {
    this.props.getPendingApprovals({ page: 1, limit: 1 });
  };

  handleGetTabs = (pendingApprovalCount, extractingCount, isBillpayAvailable) => {
    const COUNTRY_CODE = "ID";
    const orgDetails = this.props.b2bOrgDetails?.data;

    const isUOBCardVendor = orgDetails?.payload?.cardVendor?.trim() === CARD_VENDOR_TYPE.UOB;
    const isUOB = isUOBCardVendor && orgDetails?.payload?.countryCode === COUNTRY_CODE;

    const isCardsTrxnsTabEnabled =
      selectTreatmentValue(this.props.splitio, SPLIT_NAMES.cardsTrxns, GetOrgId()) === SPLIT_TREATMENT_TYPES.ON;

    if (isUOB) {
      return [getTransactionTabs(isBillpayAvailable)?.[0]];
    } else {
      return getTransactionTabs(isBillpayAvailable, isCardsTrxnsTabEnabled, pendingApprovalCount, extractingCount);
    }
  };

  getData = (key, page = 0) => {
    if (key !== TABS.pending) {
      this.getPendingApprovalCount();
    }

    if (key === TABS.pending) {
      this.props.callApiWithLoader("getAllInvoices", page, this.PAGE_SIZE, this.state.filters, null);
    } else if (key === TABS.scheduled) {
      this.props.getScheduledTransactions({ page, limit: this.PAGE_SIZE });
    } else {
      this.props.callApiWithLoader("getTransactions", page, this.PAGE_SIZE, this.state.filters);
    }
  };

  checkXeroAuth = (transaction_ids) => {
    const filters = Object.keys(this.state.filters).length;
    const transactionIds = transaction_ids.length;

    if (filters === 0 && transactionIds === 0) {
      this.props.syncTransaction({ search_filters: { start_date: startDateOfMonth() } });
    } else if (filters > 0 && transactionIds === 0) {
      this.props.syncTransaction({ search_filters: this.state.filters });
    } else {
      this.props.syncTransaction({ transaction_ids });
    }
    this.setState({ syncToaster: true });
  };

  handleChangePage = (page) => {
    this.divRef.current.scrollIntoView();
    this.getData(this.state.view, page - 1);
    this.setState({ pageNum: page - 1 });
  };

  showApproverModal = (trx, isBulk) => {
    this.setState({
      isApproverModalVisible: true,
      setApproverTrx: trx,
      setApproverIsBulk: isBulk,
    });
  };

  hideApproverModal = () => {
    this.setState({
      isApproverModalVisible: false,
      setApproverTrx: [],
    });
  };

  showToaster = (toasterMessage, toasterStatus, toasterIsPrimary = false) => {
    this.setState({
      toasterIsVisible: true,
      toasterMessage,
      toasterStatus,
      toasterIsPrimary,
    });
  };

  hideToaster = () => {
    this.setState({
      toasterIsVisible: false,
      toasterMessage: "",
      toasterStatus: "",
      toasterIsPrimary: false,
    });
  };

  optimisticUpdateStatus = (trxIds, status) => {
    const settlementStatus = {
      1: "pending",
      0: "rejected",
    };
    let invoices = this.state.data.payload?.list?.map?.((item) => {
      if (trxIds?.includes(item.id)) {
        return {
          ...item,
          settlement_status: settlementStatus[status],
          approval_decision: status,
        };
      }
      return item;
    });
    this.setState({
      data: {
        ...this.state.data,
        payload: {
          ...this.state.data.payload,
          list: invoices,
        },
      },
    });
    this.getPendingApprovalCount();
  };

  setSelectedRows = (selectedRows) => {
    this.setState({ selectedRows });
  };

  stopScheduledTransactionsFunc = (schedulerIds) => {
    this.props.stopScheduledTransactions({ schedulerIds });
  };

  setRowClassName = (record) => (record.id === this.state.details?.id ? "transactions--selected-row" : "");

  findChildTransaction = (details, dataSource) => {
    if (details.type === TRANSACTION_TYPE.REFUND) {
      return dataSource.find(
        (transaction) =>
          transaction.transaction_number === details.transaction_number && transaction.type !== TRANSACTION_TYPE.REFUND
      );
    }
    return dataSource.find(
      (transaction) =>
        transaction.transaction_number === details.transaction_number &&
        [TRANSACTION_TYPE.REVERSAL, TRANSACTION_TYPE.REFUND, TRANSACTION_TYPE.FEE_REFUND].includes(transaction.type)
    );
  };

  render() {
    const {
      loading,
      error,
      syncData,
      syncError,
      pendingApprovalCount,
      extractingCount,
      limitView,
      newTransactionTypeActive,
      isSyncButtonAccessAllowed,
      scheduledTransactions,
      editScheduledTransaction,
      editScheduledTransactionData,
      stopScheduledTransactionsData,
      orgConfigs,
      xeroAuthdata,
    } = this.props;
    const {
      data,
      details,
      syncToaster,
      filters,
      view,
      pageNum,
      subTab,
      isApproverModalVisible,
      setApproverTrx,
      setApproverIsBulk,
      toasterIsVisible,
      toasterMessage,
      toasterIsPrimary,
      toasterStatus,
      selectedRows,
      scheduledEditValue,
      isAllowedEditBtn,
      isAllowedStopBtn,
      editBillProps,
      editBillToaster,
      useLegacyRefundDetail,
    } = this.state;

    const isBillpayAvailable = orgConfigs?.data?.payload?.configs?.[PRODUCT_NAME.BILLPAY]?.value ?? false;
    const isApprovalAvailable = orgConfigs?.data?.payload?.configs?.[PRODUCT_NAME.APPROVAL]?.value ?? false;

    if (error) {
      return <ErrorComponent />;
    }

    let selectedColumnData = columnData(limitView, newTransactionTypeActive, xeroAuthdata?.payload?.has_valid_token),
      dataSource = [],
      totalCount = 0,
      rowSelection = false,
      subTabTitles,
      subTabAction,
      bulkAction,
      sideSectionView,
      paginationPos = "topRight",
      additionalInfo = null;

    if (view === TABS.pending) {
      dataSource = data?.payload?.list || [];

      const limitExceeded = data?.payload?.list?.filter((item) => item.status.key === TR_STATUS.EXCEEDED);
      const insufficient = data?.payload?.list?.filter((item) => item.status.key === TR_STATUS.INSUFFICIENT);

      if (Boolean(limitExceeded?.length)) {
        additionalInfo = (
          <div className="limit-exceeded-reminder">
            <Icon className="limit-exceeded-reminder__icon" src={limitExceededInfo} />
            <div className="limit-exceeded-reminder__text">
              One or more items will be processed for payment once its selected payment source has sufficient
              <b> Remaining Spending Limit</b> for deduction. Payment items are processed based on earliest due date if
              available or earliest payment creation date.
            </div>
          </div>
        );
      }

      if (Boolean(insufficient?.length)) {
        additionalInfo = (
          <div className="limit-exceeded-reminder">
            <Icon className="limit-exceeded-reminder__icon" src={limitExceededInfo} />
            <div className="limit-exceeded-reminder__text">
              One or more items will be processed for payment once there is sufficient<b> Company Balance</b>. Payment
              items are processed based on earliest due date if available or earliest payment creation date.
            </div>
          </div>
        );
      }

      subTabTitles = [
        {
          key: SUB_TABS.all,
          label: SUB_TABS.all,
        },
      ];
      subTabAction = (e) => {
        this.tabAction(this.state.view, e.target.value);
      };

      totalCount = data?.payload?.countAll;
      sideSectionView = (
        <PendingDetail
          id={details.id}
          status={details.status}
          isExtracting={details?.status?.key === STATUS.draft}
          approvalState={this.state.emailApprovalState}
          setEditBillProps={this.setEditBillProps}
        />
      );
      selectedColumnData = pendingColumnData;
    } else if (view === TABS.scheduled) {
      paginationPos = "";
      rowSelection = this.setSelectedRows;
      dataSource = scheduledTransactions?.data?.payload?.data || [];
      selectedColumnData = scheduledColumnData((id, record) => {
        if (
          (stopScheduledTransactionsData?.loading &&
            stopScheduledTransactionsData?.data?.schedulerIds?.includes?.(id)) ||
          (editScheduledTransactionData?.loading && editScheduledTransactionData?.data?.trxId === id)
        ) {
          return <LoaderIcon />;
        }
        return (
          <ActionButtons
            buttons={[
              ...(isAllowedEditBtn
                ? [
                    {
                      label: `Edit`,
                      action: () => {
                        this.setState({ scheduledEditValue: record });
                      },
                      type: "plain",
                      useConfirm: false,
                    },
                  ]
                : []),
              ...(isAllowedStopBtn
                ? [
                    {
                      label: `Stop`,
                      action: () => {
                        this.stopScheduledTransactionsFunc([id]);
                      },
                      type: "plain",
                      useConfirm: true,
                      className: "action-buttons__button--stop",
                    },
                  ]
                : []),
            ]}
          />
        );
      });
      sideSectionView = (
        <ScheduledDetails
          data={details}
          editAction={() => {
            this.setState({ scheduledEditValue: details });
          }}
          stopAction={() => {
            this.stopScheduledTransactionsFunc([details?.id]);
            this.setState({ showSideSection: false });
          }}
        />
      );
      totalCount = data.payload?.total_count;
    } else if (view === TABS.all) {
      rowSelection = this.setSelectedRows;
      dataSource = (data.payload || { transactions: [] }).transactions;
      if (
        [TRANSACTION_TYPE.EXPENSE, TRANSACTION_TYPE.BANK, TRANSACTION_TYPE.REFUND, TRANSACTION_TYPE.FEE].includes(
          details.type
        ) &&
        !useLegacyRefundDetail
      ) {
        sideSectionView = (
          <TransactionDetails
            receiptsData={details.receipts}
            isSideSectionOpen={this.state.showSideSection}
            showEdit={details.type !== TRANSACTION_TYPE.BANK}
            updateList={() => this.getData(this.state.view, pageNum)}
            sideSectioncb={(flag) => this.setState({ showSideSection: flag })}
            transactionNumber={details.transaction_number}
            transactionId={details.id}
            username={details.user_name}
            transactionType={details.type}
            trackTransactionDetails={(eventName) => this.trackTransactionDetails(eventName, details)}
            openChildTransaction={this.onRowClick}
            connectedToXero={!!this.props.xeroAuthdata?.payload?.has_valid_token}
            childTransaction={this.findChildTransaction(details, dataSource)}
            onUseLegacyRefundDetail={() => this.setState({ useLegacyRefundDetail: true })}
          />
        );
      } else if (
        [TRANSACTION_TYPE.REVERSAL, TRANSACTION_TYPE.SETTLEMENT, TRANSACTION_TYPE.FEE_REFUND].includes(details.type) ||
        useLegacyRefundDetail
      ) {
        sideSectionView = (
          <TransactionRefundDetails
            data={details}
            openParentTransaction={this.onRowClick}
            transactionType={details.type}
            readableTransactionType={details.transaction_type}
            connectedToXero={!!this.props.xeroAuthdata?.payload?.has_valid_token}
            parentTransaction={dataSource.find(
              (transaction) =>
                transaction.transaction_number === details.transaction_number &&
                transaction.type !== TRANSACTION_TYPE.REVERSAL &&
                transaction.type !== TRANSACTION_TYPE.REFUND &&
                transaction.type !== TRANSACTION_TYPE.SETTLEMENT
            )}
            onEditTransaction={(payload) => {
              this.getData(this.state.view, pageNum);
              this.setState({
                details: {
                  ...details,
                  ...payload,
                },
              });
            }}
          />
        );
      } else {
        sideSectionView = <TransferDetails data={details} />;
      }
      totalCount = data.payload?.count;
    } else {
      dataSource = (data.payload || { transactions: [] }).transactions;
      sideSectionView = <TransferDetails data={details} />;
      totalCount = data.payload?.count;
    }

    const clearEditBillToaster = () => {
      this.setState({
        editBillToaster: {
          visible: false,
          message: "",
          status: TOASTER_STATUS_TYPE.ERROR,
        },
      });
    };

    const tableClass = classNames({
      transactions: true,
      scheduled: view === TABS.scheduled,
    });
    let TransactionTabs = this.handleGetTabs(pendingApprovalCount, extractingCount, isBillpayAvailable);

    const isMobileApproval =
      this.state.isSmallScreen &&
      this.state.view === TABS.pending &&
      this.state.details.id &&
      this.state.emailApprovalState;

    const bannerText = isApprovalAvailable
      ? {
          title: "Bills editable before being approved for payment",
          desc: "For better compliance and controls, bills can only be edited before it is approved for payment. Non-payment related details such as Categories, Tags, or attachments can be updated by Admins or Accountants on the Accounting page after the bill has been paid.",
        }
      : {
          title: "Transaction Details can be updated on the Accounting page",
          desc: "Transaction details such as Categories, Tags, or attachments can be updated by Admins or Accountants in the Accounting page after the bill has been paid.",
        };

    return isMobileApproval ? (
      <div className="transactions--mobile">{sideSectionView}</div>
    ) : (
      <div ref={this.divRef}>
        <RecursiveTable
          banner={
            view === TABS.pending &&
            !this.state.hideEditBillBanner && (
              <Banner
                type="info"
                message={
                  <div className="approvals-listing__banner">
                    <h3>{bannerText.title}</h3>
                    <p>{bannerText.desc}</p>
                  </div>
                }
                onClose={() => {
                  this.setState({ hideEditBillBanner: true });
                  setCookie(cookieNames.HIDE_EDIT_BILL_BANNER, true);
                }}
              />
            )
          }
          loading={loading || scheduledTransactions?.loading}
          className={tableClass}
          title="Transactions"
          pageSize={this.PAGE_SIZE}
          pageNum={pageNum}
          dataSource={dataSource}
          columnData={selectedColumnData}
          rowAction={this.onRowClick}
          rowClassName={this.setRowClassName}
          rowSelection={rowSelection}
          selectedRows={selectedRows}
          bulkAction={bulkAction}
          disableCheckbox="Transfer"
          tabs={<Tabs tabsList={TransactionTabs} activeKey={view} action={this.tabAction} />}
          rowSelectBtn={isSyncButtonAccessAllowed && view === TABS.all ? "Sync to Xero" : null}
          rowSelectAction={(selectedKeys) => {
            this.checkXeroAuth(selectedKeys);
            trackEvent("Sync to Xero Button Clicked");
          }}
          total={totalCount}
          onPageChange={this.handleChangePage}
          subTabTitles={subTabTitles}
          subTabAction={subTabAction}
          activeSubTab={subTab}
          popoverButton={
            <SmartCSV
              filters={filters}
              isSideSectionOpen={this.state.showSideSection}
              totalCount={totalCount}
              view={view}
              subTab={subTab}
              selectedRows={this.state.selectedRows}
            />
          }
          showSideSection={this.state.showSideSection}
          onSideSectionClose={() => this.setState({ details: { id: undefined }, showSideSection: false })}
          sideSectionClass="transactions--side-section"
          paginationPos={paginationPos}
          filters={
            <TransactionFilters
              view={view}
              filters={filters}
              onApply={(values) => this.setState({ filters: values, syncToaster: false, pageNum: 0 })}
            />
          }
          additionalInfo={additionalInfo}
        >
          {sideSectionView}
        </RecursiveTable>
        <Toaster
          primary={this.state.showSideSection}
          visible={syncToaster}
          status={syncError ? "error" : "success"}
          closeFunc={() => this.setState({ syncToaster: false })}
          message={syncData && syncData.payload && syncData.payload.status_message}
        />
        <Toaster
          primary={toasterIsPrimary}
          visible={toasterIsVisible}
          status={toasterStatus}
          closeFunc={this.hideToaster}
          message={toasterMessage}
        />
        <ToasterDS
          size={TOASTER_SIZE_TYPE.M}
          status={editBillToaster.status}
          visible={editBillToaster.visible}
          message={editBillToaster.message}
          onClose={clearEditBillToaster}
        />
        {view === TABS.scheduled && selectedRows?.length > 0 && (
          <div className="scheduled-bulk-actions">
            <div className="scheduled-bulk-actions__message">
              {`${selectedRows?.length} transactions selected. Total amount: ${currencyFormatter(
                dataSource.reduce((acc, curVal) => {
                  if (selectedRows.includes(curVal?.id)) {
                    return acc + curVal?.amount;
                  }
                  return acc;
                }, 0),
                dataSource?.[0]?.currency
              )}`}
            </div>
            {stopScheduledTransactionsData?.loading ? (
              <LoaderIcon />
            ) : (
              <ActionButtons
                buttons={[
                  {
                    label: `Stop`,
                    action: () => {
                      this.stopScheduledTransactionsFunc(selectedRows);
                    },
                    type: "red",
                    useConfirm: true,
                  },
                ]}
              />
            )}
          </div>
        )}
        <SendMoneyModal
          show={scheduledEditValue}
          isTeam={false}
          close={() => {
            this.setState({ scheduledEditValue: null });
          }}
          isEdit
          initialValues={scheduledEditValue}
          action={(value) => {
            try {
              const payload = {
                currency: value?.currency,
                amount: parseFloat(value?.amount),
                schedule: value?.schedule,
                isActive: true,
              };
              editScheduledTransaction(scheduledEditValue?.id, payload);
            } catch (e) {
              this.showToaster("Invalid Value", "error");
            }
          }}
        />
        <EditBillModal {...editBillProps} />
      </div>
    );
  }
}

export default withUseConfigHoc(withRouter(ReduxHoc(TransactionsListing, "Transactions-All")));
