import React from "react";

import { useTeamList } from "Views/SubscriptionManagement/hooks/useTeamList";

import { ISubscriptionManagementFilterKeys } from "Views/SubscriptionManagement/@types/filters";

import { DropdownFilter, IDropdownOptions } from "Views/SubscriptionManagement/Filters/DropdownFilter";

export const BudgetFilter = () => {
  const { loading, teamList } = useTeamList();

  const teamOptions: IDropdownOptions[] =
    teamList
      ?.map?.(
        ({ id, team_name }) =>
          id &&
          team_name && {
            title: team_name,
            id,
          }
      )
      ?.filter?.(Boolean) || [];

  return (
    <DropdownFilter
      loading={loading}
      id={ISubscriptionManagementFilterKeys.BUDGET}
      defaultValue="Budget"
      options={teamOptions}
      searchable
    />
  );
};
