import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form } from "antd";
import { Link, Redirect, useHistory, useLocation } from "react-router-dom";
import classNames from "classnames";

import { Banner, InfoFilled } from "@spenmo/splice";
import { TAX_STATUS } from "constants/Tax.constant";
import { ACCOUNTING_TRANSACTIONS_WORKFLOW } from "constants/Routes.constants";
import { useIsSessionExpired } from "customHooks/Auth/useIsSessionExpired";
import useCheckFeatureStatus from "customHooks/featureCheck";
import { useQuery } from "utility/useQuery";
import Tabs from "Modules/DS/Tabs";
import { accountingPartnerAuth, getAllTaxesFunc } from "Redux/Actions";
import { defaultCount } from "Redux/Reducers/AccountingWorkflow/accountingWorkflow.slice";
import { fetchAccountingTag } from "Redux/Actions/AccountingTag/accountingTagAction";
import { getAccountingSetting, getSingleAccountingSettingFromCode } from "Redux/DataCalls/AccountingSetting.api";
import { SPLIT_NAMES, SPLIT_TREATMENT_TYPES } from "Redux/splitio/constants";
import usePermissionCheck from "Permission/usePermissionCheck";
import { INTEGRATION_PAGE } from "Permission/Actions";
import { CASBIN_PAGES } from "Permission/Pages";
import { companyMenu } from "Views/Settings/config";
import LoadingComponent from "Views/State/Loading/LoaderIcon";

import { EWorkflowApiStatus, ITabConfig } from "./type";
import { useTabConfig } from "./hooks/useTabConfig";
import FormContext from "./context/FormContext";
import { TAB_NAME } from "./constant/constant";

import styles from "./styles.module.scss";

export const activeTabBasedOnStatus = (status) => {
  if ([EWorkflowApiStatus.PENDING_REVIEW, EWorkflowApiStatus.MISSING_INFO].includes(status)) {
    return "Pending Review";
  }

  if ([EWorkflowApiStatus.FAILED_SYNC, EWorkflowApiStatus.QUEUED].includes(status)) {
    return "Pending Sync";
  }

  if (EWorkflowApiStatus.COMPLETED === status) {
    return "Completed";
  }
  return "Pending Review";
};

const validateStatus = (status) => {
  const validStatus = [
    EWorkflowApiStatus.COMPLETED,
    EWorkflowApiStatus.FAILED_SYNC,
    EWorkflowApiStatus.MISSING_INFO,
    EWorkflowApiStatus.PENDING_REVIEW,
    EWorkflowApiStatus.QUEUED,
  ];

  if (!status) {
    return "";
  }

  if (validStatus.includes(status)) {
    return status;
  }
  return EWorkflowApiStatus.MISSING_INFO;
};

const permissionParam = [{ object: CASBIN_PAGES.INTEGRATION_PAGE, action: INTEGRATION_PAGE.XERO_INTEGRATION }];

const Workflow = ({ isFromTransactionsPage }: { isFromTransactionsPage?: boolean }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const defaultStatus = (new URLSearchParams(location.search).get("status") || "").toUpperCase() as EWorkflowApiStatus;
  const [status, setStatus] = useState(validateStatus(defaultStatus));
  const [expenseNotFound, setExpenseNotFound] = useState(false);
  const [formValue, setFormValue] = useState({});
  const [selectedExpense, setSelectedExpense] = useState({});
  const [showBankFeedSyncPausedBanner, setShowBankFeedSyncPausedBanner] = useState(false);
  const [activeKey, setActiveKey] = useState(activeTabBasedOnStatus(status));
  const isSessionExpired = useIsSessionExpired();
  const accountingSettingsPagePath = companyMenu[0].path;
  const toggleFeatureFlagTreatment = useCheckFeatureStatus(SPLIT_NAMES.showAccountingToggleFeature);
  const toggleFeatureFlagLoading = toggleFeatureFlagTreatment === SPLIT_TREATMENT_TYPES.CONTROL;
  const showAccountingPageUnderTrxns = toggleFeatureFlagTreatment === SPLIT_TREATMENT_TYPES.ON;

  const [IS_ALLOWED_XERO_INTEGRATION_ACTION, loadingPermission] = usePermissionCheck(permissionParam, true) as [
    boolean,
    boolean
  ];

  useEffect(() => {
    // to redirect the user to the Accounting page under Transactions when trying to access Accounting page through /accounting url
    if (showAccountingPageUnderTrxns && !isFromTransactionsPage) {
      history.push(`${ACCOUNTING_TRANSACTIONS_WORKFLOW}${location.search || ""}`);
    }
  }, [showAccountingPageUnderTrxns, isFromTransactionsPage]);

  const { data: accountingSetting } = useQuery({
    apiCall: getAccountingSetting,
  });
  const { data, accountingWorkflow, accountingTag, taxesData } = useSelector((state: any) => ({
    ...state.accountingPartnerAuthReducer,
    accountingWorkflow: state.accountingWorkflowReducer,
    accountingTag: state.accountingTagReducer,
    taxesData: state.taxReducer.data,
  }));

  const tags = accountingTag?.data?.payload?.tags || [];
  const taxes = taxesData?.payload?.data || [];
  const hasValidToken = data?.payload?.has_valid_token;
  const connectedUsingExpenseIntegration = hasValidToken && data?.payload?.expense;
  const connectedUsingBankFeedIntegration = hasValidToken && data?.payload?.bankfeed;
  const bankfeedWithExpenseIntegration = connectedUsingBankFeedIntegration && connectedUsingExpenseIntegration;

  const [tabsConfig] = useTabConfig(
    accountingWorkflow?.data?.count || defaultCount,
    connectedUsingExpenseIntegration,
    bankfeedWithExpenseIntegration
  );

  useEffect(() => {
    dispatch(accountingPartnerAuth());
    dispatch(getAllTaxesFunc({ status: TAX_STATUS.ACTIVE }));
    dispatch(fetchAccountingTag([]));
  }, []);

  const getBankFeedSyncPauseStatus = async () => {
    const { data } = await getSingleAccountingSettingFromCode("bank_feed_sync_paused");
    setShowBankFeedSyncPausedBanner(data?.payload?.isEnabled);
  };

  useEffect(() => {
    if (data?.payload && !connectedUsingExpenseIntegration && activeKey === TAB_NAME.PENDING_SYNC) {
      setActiveKey(TAB_NAME.PENDING_REVIEW);
      setStatus(EWorkflowApiStatus.MISSING_INFO);
      history.push(ACCOUNTING_TRANSACTIONS_WORKFLOW);
    }
    if (connectedUsingBankFeedIntegration) getBankFeedSyncPauseStatus();
  }, [data]);

  if (!isFromTransactionsPage && (loadingPermission || toggleFeatureFlagLoading)) {
    return <LoadingComponent />;
  }

  if (!isFromTransactionsPage && !IS_ALLOWED_XERO_INTEGRATION_ACTION && !isSessionExpired) {
    return <Redirect to={"/404"} />;
  }

  return (
    <Form form={form}>
      <FormContext.Provider
        value={{
          form,
          formValue,
          setFormValue,
          tags,
          taxes,
          accountingSetting,
          selectedExpense,
          setSelectedExpense,
          expenseNotFound,
          setExpenseNotFound,
          connectedUsingExpenseIntegration,
          connected: hasValidToken,
        }}
      >
        <div className={classNames(styles["accounting-workflow"], styles.accountingPageSpacing)}>
          {showBankFeedSyncPausedBanner && (
            <section className={styles.syncPausedBannerContainer}>
              <Banner
                variant="danger"
                icon={InfoFilled}
                title="Bank Feed Sync has been paused"
                description={
                  <>
                    You can resume sync in <Link to={accountingSettingsPagePath}>Settings</Link> to continue syncing
                    Spenmo transactions with your Xero account
                  </>
                }
              />
            </section>
          )}
          <Tabs
            tabsList={tabsConfig}
            defaultActiveKey="Pending Review"
            activeKey={activeKey}
            action={setActiveKey}
            content={(tab: ITabConfig) => (
              <tab.Component
                activeKey={activeKey}
                defaultStatus={status}
                connected={hasValidToken}
                connectedUsingExpenseIntegration={connectedUsingExpenseIntegration}
                connectedUsingBankFeedIntegration={connectedUsingBankFeedIntegration}
              />
            )}
          />
        </div>
      </FormContext.Provider>
    </Form>
  );
};

export default Workflow;
