import { useMemo, useRef, useState } from "react";

import { CASBIN_PAGES } from "Permission/Pages";
import usePermissionCheck from "Permission/usePermissionCheck";
import {
  REIMBURSEMENT_SETTING_MENU,
  REIMBURSEMENT_TRANSACTION_PAGE,
} from "Permission/Actions";

import API from "API/Client";
import { useMutableData } from "API/useData";

import { SPLIT_NAMES, SPLIT_TREATMENT_TYPES } from "Redux/splitio/constants";

import { trackEvent } from "utility/analytics";
import useCheckFeatureStatus from "customHooks/featureCheck";
import { useIsSessionExpired } from "customHooks/Auth/useIsSessionExpired";

import { LAST_POSSIBLE_RECURRING_DATE } from "Modules/RecurringDate/types";
import {
  appNotification,
  generalErrorMessage,
} from "Modules/appNotification/appNotification";

import {
  SETTINGS_MODAL_TYPES,
  useSettingsModalContext,
} from "Views/Settings/context/Modal";

import { API_URL } from "./API";
import { getModalProps } from "./Modal";
import { CRAnalytics, IReimbursementSettingsPageContainer } from "./types";

export const trackCRAnalytics = ({
  eventSource,
  eventSubSource,
}: CRAnalytics) => {
  trackEvent("Settings Company CR Page", {
    settings_page_event_source: eventSource,
    settings_page_event_subsource: eventSubSource,
  });
};

export const getCRSettings = async (
  updateState: (isEnabled: boolean, settledAt: number) => void,
) => {
  return await API.getData(API_URL.reimbursementSetting)
    .then((resp) => {
      const {
        isEnabled = false,
        settledAt = LAST_POSSIBLE_RECURRING_DATE.MAX,
      } = resp.data.payload;
      updateState(isEnabled, settledAt);
    })
    .catch((err) => {
      appNotification.error({ message: err.message });
    });
};

export const getTaxList = async () => {
  return API.getData(`${API_URL.getTax}?status=active`);
};

export const useFetchCRState = () => {
  const [isEnabled, setIsEnabled] = useState(false);
  const [settledAt, setSettledAt] = useState<number>(
    LAST_POSSIBLE_RECURRING_DATE.MAX,
  );
  const version = useRef<number>(0);
  const nextSettlementDate = useRef<Date>(null);

  const permissionParam = [
    {
      object: CASBIN_PAGES.REIMBURSEMENT_SETTING_MENU,
      action: REIMBURSEMENT_TRANSACTION_PAGE.READ,
    },
  ];

  const [viewSettlementAllowed, loadingPermission] = usePermissionCheck(
    permissionParam,
    true,
  ) as [boolean[], boolean];

  const fetchState = useMutableData(
    !loadingPermission && viewSettlementAllowed
      ? API_URL.reimbursementSetting
      : null,
    {
      onSuccess: (response) => {
        const {
          isEnabled: _isEnabled,
          settledAt: _settledAt,
          version: _version,
          nextSettlementDate: _nextSettlementDate,
        } = response.data.payload;

        setIsEnabled(_isEnabled);

        if (_isEnabled) {
          setSettledAt(Number(_settledAt));
          version.current = _version;
          nextSettlementDate.current = _nextSettlementDate;
        }
      },
      onError: (err) => {
        appNotification.error({ message: err.message });
      },
    },
  );

  return {
    nextSettlementDate,
    version,
    isEnabled,
    settledAt,
    setSettledAt,
    setIsEnabled,
    fetchState,
  };
};

const useCRState = (): {
  loading: boolean;
  showEmptyState: boolean;
  containerProps: IReimbursementSettingsPageContainer;
} => {
  const isSessionExpired = useIsSessionExpired();

  const permissionParam = [
    {
      object: CASBIN_PAGES.REIMBURSEMENT_SETTING_MENU,
      action: REIMBURSEMENT_SETTING_MENU.READ,
    },
  ];

  const [isAllowedRead = false, loadingPermission] = usePermissionCheck(
    permissionParam,
    true,
  ) as [boolean, boolean];

  const isReimbursementTreatmentOn =
    useCheckFeatureStatus(SPLIT_NAMES.billNewCR) === SPLIT_TREATMENT_TYPES.ON;

  const {
    isEnabled,
    version,
    setIsEnabled,
    setSettledAt: onChangeDate,
    settledAt,
    fetchState,
  } = useFetchCRState();
  const { data, isLoading, isValidating, mutate: mutateCRSetting } = fetchState;

  const _isEnabled: boolean = data?.data?.payload?.isEnabled || false;
  const _settledAt: string =
    data?.data?.payload?.settledAt ||
    LAST_POSSIBLE_RECURRING_DATE.MAX.toString();

  const isClean = useMemo(() => {
    const matchEnabled = _isEnabled === isEnabled;
    const matchDate = Number(_settledAt) === Number(settledAt);

    return _isEnabled ? matchEnabled && matchDate : matchEnabled;
  }, [isEnabled, settledAt, _isEnabled, _settledAt]);

  const { toggleModal, resetModal } = useSettingsModalContext();

  const [submitLoading, setSubmitLoading] = useState(false);

  const handleSaveChanges = () => {
    trackCRAnalytics({
      eventSource: "Save Changes",
      eventSubSource: "Clicked",
    });
    isEnabled ? previewChanges() : handleConfirmChanges();
  };

  const handleConfirmChanges = () => {
    trackCRAnalytics({
      eventSource: "Save Changes",
      eventSubSource: "Confirm",
    });
    updateSettings();
  };

  const handleCancelChanges = () => {
    trackCRAnalytics({ eventSource: "Save Changes", eventSubSource: "Cancel" });
    resetModal();
    mutateCRSetting();
  };

  const updateSettings = async () => {
    try {
      setSubmitLoading(true);

      const response = await API.postData(API_URL.reimbursementSetting, {
        isEnabled,
        version: version.current,
        settledAt: settledAt.toString(),
      });
      const { messages } = response.data.payload;

      (messages as string[]).forEach((message) =>
        appNotification.success({ message }),
      );
    } catch (err) {
      const { message, code } = err.response.data.error;
      appNotification.error(generalErrorMessage(message, code));
    } finally {
      resetModal();
      mutateCRSetting().then((resp) => {
        version.current = resp?.data?.payload?.version || null;
      });
      setSubmitLoading(false);
    }
  };

  const previewChanges = async () => {
    try {
      setSubmitLoading(true);
      const response = await API.postData(API_URL.previewReimbursementSetting, {
        isEnabled,
        settledAt: settledAt.toString(),
      });
      const { message } = response.data.payload;

      toggleModal(
        SETTINGS_MODAL_TYPES.CONFIRM_REIMBURSEMENT_SETTINGS,
        getModalProps(handleConfirmChanges, handleCancelChanges, resetModal),
        { textContent: message },
      );
    } catch (err) {
      appNotification.error({ message: err.message });
    } finally {
      setSubmitLoading(false);
    }
  };

  const handleSwitchToggle = () => {
    setIsEnabled((prev) => {
      const newVal = !prev;

      trackCRAnalytics({
        eventSource: "Settings Switch",
        eventSubSource: newVal ? "Toggle On" : "Toggle Off",
      });

      return newVal;
    });
  };

  return {
    loading: loadingPermission || isLoading || isValidating,
    showEmptyState:
      !isAllowedRead || !isReimbursementTreatmentOn || isSessionExpired,
    containerProps: {
      isClean,
      settledAt,
      isEnabled,
      onChangeDate,
      submitLoading,
      handleSwitchToggle,
      handleSaveChanges,
    },
  };
};

export default useCRState;
