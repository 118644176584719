import React from "react";
import classNames from "classnames";

import { ITitleWithIcon } from "Modules/DS/Chip";

import styles from "./Title.module.scss";

/**   Here the className is used to pass in the mask url in the icon div
 *    i.e. mask: url("../../../../assets/v1.1/icons/Core/Add/line_16.svg");
 *
 *    We are using the mask property to display an svg image
 */

export const TitleWithIconPrefixed = ({ title, className }: ITitleWithIcon) => {
  return (
    <div className={styles.container}>
      <div className={classNames(styles.icon, className)} />
      <span>{title}</span>
    </div>
  );
};
