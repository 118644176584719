import React from "react";
import Modal, { ModalComponent } from "Modules/DS/Modal";
import { greyCloseIcon } from "assets/img";
import IButton from "Modules/button";
import { TITLE_TYPE } from "Modules/DS/Modal/types";
import useLoading from "utility/useLoading";
import { IRemainderEmail } from "../type";
import { sendEmailRemainder } from "Redux/DataCalls/AccountingWorkflow.api";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";

import styles from "./styles.module.scss";

const EmailReminder = ({
  visible,
  onClose,
  showErrorToaster,
  onSendRemainder,
  count,
  transactionIds,
}: IRemainderEmail) => {
  const sentence = {
    singular: {
      email: "email",
      employee: "employee",
      emailCapital: "Email",
    },
    plural: {
      email: "emails",
      employee: "employees",
      emailCapital: "Emails",
    },
  };

  const { email, employee, emailCapital } = sentence[count <= 1 ? "singular" : "plural"];
  const [sendRemainder, loadingEmailRemainder] = useLoading(sendEmailRemainder, null, {}, false);

  const handleSendRemainder = async () => {
    try {
      const { data } = await sendRemainder(transactionIds);

      if (data.status === HTTP_STATUS_CODE.OK) {
        onSendRemainder(employee);
      } else {
        return Promise.reject(data);
      }
    } catch (err) {
      showErrorToaster(err);
    }
  };

  return (
    <Modal visible={visible} close={onClose} className={styles["email-reminder"]}>
      <ModalComponent.CloseButton fixToTop src={greyCloseIcon} />
      <ModalComponent.Title titleType={TITLE_TYPE.withBorder}>
        {count === 0 ? "Unable to Send Reminder Email" : `Send Reminder ${emailCapital}`}
      </ModalComponent.Title>
      <section className={styles["email-reminder__body"]}>
        {count === 0 ? (
          <p>
            No missing attachment was found in the selected transactions.Reminder emails can only be sent to employees
            if their transactions are missing attachments.
          </p>
        ) : (
          <p>
            We will send reminder {email} to {count} {employee} for uploading the attachments in the transaction
            details.
          </p>
        )}
        <div className={styles["email-reminder__action-btns"]}>
          {count === 0 ? (
            <IButton text="Got It" action={onClose} />
          ) : (
            <IButton
              text={`Send Reminder ${emailCapital}`}
              loading={loadingEmailRemainder}
              action={handleSendRemainder}
              className={loadingEmailRemainder ? "disabled" : ""}
            />
          )}
        </div>
      </section>
    </Modal>
  );
};

export default EmailReminder;
