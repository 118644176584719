import CardApprovalRequest from "Models/Approval/ApprovalCenter/cardApprovalRequest.interface";
import { CARD_TYPE, CARD_LIMIT_TYPES, ITag } from "Views/Card/types";

export enum CardReviewRequestEditableFieldsEnum {
  CARD_TITLE = "cardTitle",
  CARD_LIMIT_TYPE = "cardLimitType",
  CARD_LIMIT = "cardLimit",
  MERCHANT_LOCK = "merchantLock",
  EXPENSE_CATEGORY_ID = "expenseCategoryId",
  TAGS = "tags",
  MERCHANTS = "merchants",
  MERCHANT_CATEGORY_LIMITS = "merchantCategoryLimits",
}

interface MerchantCategory {
  merchantCategoryName: string;
  merchantCategoryId: string;
}
export interface CardRequestDetailsType {
  cardRequestId: string;
  requestBy: string;
  team: string;
  memberNote: string;
  cardType: CARD_TYPE;
  address: string;
  [CardReviewRequestEditableFieldsEnum.CARD_TITLE]: string;
  [CardReviewRequestEditableFieldsEnum.CARD_LIMIT_TYPE]: CARD_LIMIT_TYPES;
  [CardReviewRequestEditableFieldsEnum.CARD_LIMIT]: string;
  [CardReviewRequestEditableFieldsEnum.MERCHANT_LOCK]: string[];
  [CardReviewRequestEditableFieldsEnum.EXPENSE_CATEGORY_ID]: number;
  [CardReviewRequestEditableFieldsEnum.TAGS]: ITag[];
}

export interface ICardDetail extends CardApprovalRequest {
  expenseCategoryId?: string;
  merchantCategoryLimits: MerchantCategory[];
}

export interface CardUpdateRequestPayload {
  cardLimit: string;
  cardLimitType: string;
  cardTitle: string;
  categoryID: string;
  merchants: Array<{ merchantName: string }>;
  merchantCategoryLimits: MerchantCategory[];
  tags: ITag[];
}
