import APIclient from "API/Client";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import { IBusinessActivityPayload } from "./types";

export enum BUSINESS_ACTIVITY_ACTION_TYPES {
  REQUEST = "GET_BUSINESS_ACTIVITY_REQUEST",
  SUCCESS = "GET_BUSINESS_ACTIVITY_SUCCESS",
  ERROR = "GET_BUSINESS_ACTIVITY_ERROR",
}

const fetchDataRequest = (data?: any) => {
  return {
    type: BUSINESS_ACTIVITY_ACTION_TYPES.REQUEST,
    data,
  };
};

const fetchDataSuccess = (data: IBusinessActivityPayload) => {
  return {
    type: BUSINESS_ACTIVITY_ACTION_TYPES.SUCCESS,
    data,
  };
};

const fetchDataError = (data: IBusinessActivityPayload) => {
  return {
    type: BUSINESS_ACTIVITY_ACTION_TYPES.ERROR,
    data,
  };
};

export const getBusinessActivityData = () => {
  return (dispatch) => {
    dispatch(fetchDataRequest());

    APIclient.getData("ms/spm-organisation/v1/kyb/constants?type=business_activity&countryCode=SG")
      .then((response) => {
        if (response?.data?.status === HTTP_STATUS_CODE.OK) {
          dispatch(fetchDataSuccess(response.data));
        } else {
          dispatch(fetchDataError(response.data));
        }
      })
      .catch((error) => {
        dispatch(fetchDataError(error?.response?.data));
      });
  };
};
