import React from "react";

import { capitalizeFirstLetter } from "utility";

import { IDetail } from "Modules/TransactionDetail";

import { Header, Status } from "Views/Transaction/Details";
import { IOtherTrxnDetails } from "Views/Transaction/@types";
import { removeDuplicates, getRecordDetailsDate } from "Views/Transaction/Helper";

import { Details } from "./Details";

export const OtherTrxnDetails = ({
  amount,
  currencyCode,
  requesterName,
  teamName,
  trxnNumber,
  trxnType,
  createdDate,
  notes,
  status,
  recipientName,
  receipts,
  handleSaveRef,
  expenseData,
  closeDetails,
  disableEditing,
  xeroBillBannerData,
  trxnNumberElement,
}: IOtherTrxnDetails) => {
  const date = getRecordDetailsDate(createdDate);

  const details: IDetail[] = [
    {
      id: "requester",
      key: "Requester",
      value: capitalizeFirstLetter(requesterName),
      subtitle: capitalizeFirstLetter(teamName),
    },
    { id: "trxn_number", key: "Transaction Number", value: trxnNumberElement() },
    { id: "created_date", key: "Date and Time", value: date },
    { id: "trxn_type", key: "Transaction Type", value: trxnType },
  ];

  return (
    <>
      <Header
        title={recipientName}
        label="Merchant"
        amount={amount}
        currencyCode={currencyCode}
        xeroBillBannerData={xeroBillBannerData}
      />
      <Status date={date} status={status} />
      <Details
        notes={notes}
        details={details}
        handleSaveRef={handleSaveRef}
        trxnType={trxnType}
        trxnNumber={trxnNumber}
        receipts={removeDuplicates(receipts)}
        categoryId={expenseData.category}
        categoryName={expenseData.categoryName}
        tags={expenseData.tags}
        closeDetails={closeDetails}
        taxId={expenseData.tax}
        disableEditing={disableEditing}
        subscriptionInitialProps={{
          vendor_name: recipientName,
          estimated_amount: amount,
          currency: currencyCode,
          budget_id: expenseData.budgetId,
          owner_id: expenseData.payerId,
        }}
      />
    </>
  );
};
