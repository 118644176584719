import React, { useState } from "react";
import { Banner, Button, Typography, Card, InfoFilled, Modal } from "@spenmo/splice";
import cn from "classnames";

import CopyText from "./CopyText";

import { BankTransferItemType, BankTransferProps } from "Views/Bills/V2/PaymentRunPreview/type";
import styles from "./PaymentMethod.module.scss";
import { transformBankInfo } from "./helper";

const BankTransfer = (props: BankTransferProps) => {
  const { description, methods, onClickAutoPay, isAutoPayLoading } = props;
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      {description && (
        <Typography
          tag="p"
          variant="body-content"
          size="s"
          className={cn(styles.description, styles.bodyColor)}
          dangerouslySetInnerHTML={{ __html: description }}
        />
      )}
      {methods?.map((bankInfo) => {
        const { countryFlagUrl, countryLabel, currencyCode, accountNumber } = bankInfo;
        const mappedBankInfo = transformBankInfo(bankInfo);

        return (
          <div key={accountNumber} className={styles.bankDetailSection}>
            <div className={styles.flagContainer}>
              <img className={styles.flagImg} src={countryFlagUrl} alt="singapore" width={24} height={16} />
              <Typography tag="p" variant="body-content" size="s" weight={600}>
                {countryLabel} ({currencyCode})
              </Typography>
            </div>
            <Card elevation="ground" padding="medium" radius="m" className={styles.bankDetail}>
              {React.Children.toArray(
                mappedBankInfo.map((item, index) => {
                  const { type, props } = item;
                  const { className, ...rest } = props || {};

                  switch (type) {
                    case BankTransferItemType.IMG: {
                      return <img className={cn(styles.bankItem, className)} alt="alt text" {...rest} />;
                    }
                    case BankTransferItemType.COPY_TEXT: {
                      const { copyText, ...typographyProps } = rest;
                      return (
                        <CopyText text={copyText} className={styles.bankItem}>
                          <Typography className={className} {...typographyProps} />
                        </CopyText>
                      );
                    }
                    case BankTransferItemType.HTML: {
                      return (
                        <div className={styles.bankItem}>
                          {React.Children.toArray(props?.map((htmlProps) => <Typography {...htmlProps} />))}
                        </div>
                      );
                    }
                    case BankTransferItemType.ADDITIONAL_INFO: {
                      return props?.map((info, infoIndex) => {
                        const { labelProps, contentProps } = info;
                        const { copyText, ...typographyProps } = contentProps;

                        return (
                          <div key={`${type}-${index}-${infoIndex}`} className={styles.bankItem}>
                            <Typography {...labelProps} />
                            <CopyText text={copyText} className={styles.bankItem}>
                              <Typography className={className} {...typographyProps} />
                            </CopyText>
                          </div>
                        );
                      });
                    }
                    case BankTransferItemType.USDVirtualAccount: {
                      return (
                        <div className={styles.bankItem}>
                          <Typography
                            tag="p"
                            className={cn(styles.bankItem, styles.bodyColor)}
                            variant="body-content"
                            size="s"
                          >
                            Balance will be updated instantly or within 1-3 working days{" "}
                            <Typography
                              variant="body-content"
                              size="s"
                              className={styles.usdVABankBtn}
                              onClick={() => setShowModal(true)}
                            >
                              depending on your bank
                            </Typography>
                            .
                          </Typography>
                          <div className={styles.bankItem}>
                            <Banner
                              variant="warning"
                              icon={InfoFilled}
                              description="Please make sure that all information has been entered correctly to avoid delay in your top-up process."
                              title=""
                            />
                          </div>
                          <Typography
                            className={cn(styles.bankItem, styles.bodyColor)}
                            tag="p"
                            variant="body-content"
                            size="s"
                          >
                            If you have any question, please contact our Customer Support.
                          </Typography>
                          <Modal
                            showModal={showModal}
                            title="Bank Tranfer"
                            onClose={() => setShowModal(false)}
                            size="m"
                          >
                            <Typography className={styles.bodyColor} variant="body-content" size="m" tag="p">
                              Your balance will be updated after the fund has been received. It may take longer
                              depending on how you make the transfer:
                            </Typography>
                            <ol className={styles.usdVAClause}>
                              <li>
                                <Typography variant="body-content" size="m" tag="p">
                                  Instantly if you transfer from{" "}
                                  <Typography variant="body-content" size="m" weight={600}>
                                    DBS Bank Singapore
                                  </Typography>
                                </Typography>
                              </li>
                              <li>
                                <Typography variant="body-content" size="m" tag="p">
                                  Within 1 working day if you transfer from{" "}
                                  <Typography variant="body-content" size="m" weight={600}>
                                    other Singapore banks
                                  </Typography>
                                </Typography>
                              </li>
                              <li>
                                <Typography variant="body-content" size="m" tag="p">
                                  Within 3 working days if you transfer from foreign banks{" "}
                                  <Typography variant="body-content" size="m" weight={600}>
                                    (outside Singapore)
                                  </Typography>
                                </Typography>
                              </li>
                            </ol>
                          </Modal>
                        </div>
                      );
                    }
                    case BankTransferItemType.TEXT:
                    default: {
                      return <Typography className={cn(className, styles.bankItem)} {...rest} />;
                    }
                  }
                })
              )}
            </Card>
          </div>
        );
      })}
      {Array.isArray(methods) && methods.length > 0 && (
        <>
          <div className={styles.banner}>
            <Banner
              description={
                <Typography variant="body-content" size="s">
                  After transferring funds, refresh your Spenmo Wallet Balance. If the money doesn't appear instantly,
                  use the button below to process the payment automatically once it's in your account.
                </Typography>
              }
              title=""
              icon={InfoFilled}
              variant="info"
            />
          </div>
          <Button
            className={styles.autoPayBtn}
            size="s"
            variant="primary"
            onClick={onClickAutoPay}
            loading={isAutoPayLoading}
          >
            Auto-Pay on Fund Arrival
          </Button>
        </>
      )}
    </>
  );
};

export default BankTransfer;
