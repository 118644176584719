import {
  TRANSACTION_PAGE,
  TRANSACTION_PAGE_DETAILS_PANEL,
  TRANSACTION_PAGE_ALL_TRANSACTION_TAB,
  TRANSACTION_PAGE_BILL_TRASACTION_TAB,
  TRANSACTION_PAGE_SCHEDULED_INTERNAL_TRANSACTIONS_TAB,
  PAYMENT_RUN_BILL_PAY_PAGE,
} from "Permission/Actions";
import { CASBIN_PAGES } from "Permission/Pages";

export const TRANSACTION_REQUESTER_PERMISSION_PARAMS = [
  {
    object: CASBIN_PAGES.TRANSACTION_PAGE,
    action: TRANSACTION_PAGE.REQUESTER_FILTER,
  },
];

export const TRANSACTION_DETAILS_PERMISSION_PARAMS = [
  {
    object: CASBIN_PAGES.TRANSACTION_PAGE_DETAILS_PANEL,
    action: TRANSACTION_PAGE_DETAILS_PANEL.SAVE_BUTTON,
  },
];

export const TRANSACTION_DETAIL_V2_PERMISSION_PARAMS = [
  {
    object: CASBIN_PAGES.TRANSACTION_PAGE_DETAILS_PANEL,
    action: TRANSACTION_PAGE_DETAILS_PANEL.SAVE_BUTTON,
  },
  {
    object: CASBIN_PAGES.PAYMENT_RUN_BILL_PAY_PAGE,
    action: PAYMENT_RUN_BILL_PAY_PAGE.INITIATE_PAYMENT,
  },
];

export const TRANSACTION_PAGE_ALL_TAB_PERMISSION_PARAMS = [
  {
    object: CASBIN_PAGES.TRANSACTION_PAGE_ALL_TRANSACTION_TAB,
    action: TRANSACTION_PAGE_ALL_TRANSACTION_TAB.DOWNLOAD_DATA_BUTTON,
  },
];

export const TRANSACTION_PAGE_LISTING_PERMISSION_PARAMS = [
  {
    object: CASBIN_PAGES.TRANSACTION_PAGE,
    action: TRANSACTION_PAGE.TRANSACTION_TYPE_INCENTIVE,
  },
];

export const TRANSACTION_LISTING_PERMISSION_PARAMS = [
  {
    object: CASBIN_PAGES.TRANSACTION_PAGE_BILL_TRASACTION_TAB,
    action: TRANSACTION_PAGE_BILL_TRASACTION_TAB.APPROVAL_REQUIRED_TAB,
  },
  {
    object: CASBIN_PAGES.TRANSACTION_PAGE_SCHEDULED_INTERNAL_TRANSACTIONS_TAB,
    action: TRANSACTION_PAGE_SCHEDULED_INTERNAL_TRANSACTIONS_TAB.EDIT_BUTTON,
  },
  {
    object: CASBIN_PAGES.TRANSACTION_PAGE_SCHEDULED_INTERNAL_TRANSACTIONS_TAB,
    action: TRANSACTION_PAGE_SCHEDULED_INTERNAL_TRANSACTIONS_TAB.STOP_BUTTON,
  },
];

export const allTrxnsDownloadPermissions = [
  {
    object: CASBIN_PAGES.TRANSACTION_PAGE_ALL_TRANSACTION_TAB,
    action: TRANSACTION_PAGE_ALL_TRANSACTION_TAB.DOWNLOAD_DATA_BUTTON,
  },
];

export const accountingPageExportPermission = [
  { object: CASBIN_PAGES.TRANSACTION_PAGE, action: TRANSACTION_PAGE.DOWNLOAD_DATA_BUTTON },
];
