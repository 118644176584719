import React from "react";
import { Form } from "antd";
import moment from "moment";
import classNames from "classnames";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

import Menu from "Modules/DS/Menu";
import DatePicker from "Modules/datePicker";
import { FilterItem } from "Modules/DS/Filter";
import { POPOVER_WIDTH } from "Modules/DS/Popover";
import { DATE_RANGE, IDateFilterProps } from "Modules/DS/Filter/@types";

import useStates from "./useStates";

import "./Date.scss";

dayjs.extend(utc);

export const DateFilter = <T extends object = any>({
  id,
  filter,
  defaultValue,
  onApply,
  onClear,
  asISO = false,
  startKey = DATE_RANGE.START,
  endKey = DATE_RANGE.END,
}: IDateFilterProps<T>) => {
  const { get, set, applyFilter, clearStates, clearSelected } = useStates(
    filter,
    onApply,
    onClear,
    startKey,
    endKey,
    defaultValue,
    asISO,
  );

  const processDate = (date: string, key: string) => {
    if (key === endKey) {
      const eodTime = moment(date, "YYYY-MM-DD").endOf("day").toDate();

      return asISO ? new Date(eodTime).toISOString() : date;
    } else {
      return asISO ? dayjs(date).utc().local().toISOString() : date;
    }
  };

  const actionHandler = (date: string, key: string, range: string) => {
    const value = date ? processDate(date, key) : null;
    if (Boolean(value) === false) return;
    get.form.setFieldsValue({ [key]: value });
    set[range](value);
  };

  const renderFromInput = () => {
    return (
      <Form.Item label="From">
        <Form.Item name={startKey} noStyle />
        <DatePicker
          id={startKey}
          picker="date"
          showPastDates
          initialValue={get.from}
          placeholder="Select Date"
          action={(date: string) => actionHandler(date, startKey, "from")}
        />
      </Form.Item>
    );
  };

  const renderToInput = () => {
    return (
      <Form.Item label="To">
        <Form.Item name={endKey} noStyle />
        <DatePicker
          id={endKey}
          picker="date"
          showPastDates
          initialValue={get.to}
          placeholder="Select Date"
          action={(date: string) => actionHandler(date, endKey, "to")}
        />
      </Form.Item>
    );
  };

  return (
    <FilterItem
      id={id || "date-quick-filter"}
      title={get.title}
      selected={get.selected}
      clearFilter={clearSelected}
      popoverWidth={POPOVER_WIDTH.M}
    >
      <div className={classNames("date-quick-filter-content", { [id]: id })}>
        <Form name="date-filter-form" form={get.form} onFinish={applyFilter}>
          <div className="date-quick-filter-range">
            {renderFromInput()}
            {renderToInput()}
          </div>
          {get.error.length > 0 && <span className="error">{get.error}</span>}
          <div className="date-filter-actions">
            <Menu.Actions onClear={clearStates} />
          </div>
        </Form>
      </div>
    </FilterItem>
  );
};
