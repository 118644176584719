import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { resetForgotData } from "Redux/Actions";

import { LOGIN_PATH } from "constants/Routes.constants";

import AuthPage from "Views/Auth";
import forgotPasswordCookiesReset from "Views/Auth//ForgotPasswordOTP/utility";

import { ForgotPasswordForm } from "./Components";
import Icon from "Modules/icons";

import "./styles.scss";
import { AuthPageContextProps } from "../context";
import { AuthContentHeader } from "../Components";
import { successIcon } from "assets/img";

const Forgot = (): React.ReactElement => {
  const dispatch = useDispatch();
  const { error, data, isSuccess } = useSelector((state: any) => state.forgotReducer);

  useEffect(() => {
    dispatch(resetForgotData());
    forgotPasswordCookiesReset();
  }, [dispatch]);

  const redirectComponent = () => {
    return (
      <Link id="goLogin" to={LOGIN_PATH} replace>
        Back to Login
      </Link>
    );
  };

  let context: AuthPageContextProps = {
    error: error,
    loading: false,
    errorMessage: error ? data?.[0]?.message || data : null,
    appendClassName: "forgot",
  };

  return (
    <AuthPage value={context} redirectComponent={redirectComponent()}>
      {isSuccess ? (
        <>
          <AuthContentHeader title="Password Reset Email Sent" />
          <Icon className={"thankyou-icon"} src={successIcon} alt={"success icon"} />
          <p className="auth-title-description">
            We have sent password reset instructions to your Spenmo registered email address
          </p>
        </>
      ) : (
        <ForgotPasswordForm />
      )}
    </AuthPage>
  );
};

export default Forgot;
