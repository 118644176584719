import { AxiosResponse } from "axios";
import { API } from "./AxiosSetUp";

export const getData = async (
  service: string,
  params: string = "",
  payloadCallBack: any = false,
  config: { [key: string]: any } = {}
) => {
  try {
    let responseData = {} as AxiosResponse;
    const getUrl = params ? `${service}?${params}` : `${service}`;
    responseData = await API.get(getUrl, config);
    if (!payloadCallBack) {
      return responseData;
    }
    payloadCallBack(responseData);
    return responseData;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const postData = async (
  service: string,
  payload: { [key: string]: any } = {},
  payloadCallBack: any = false,
  config: { [key: string]: any } = {}
) => {
  try {
    let responseData = {} as AxiosResponse;
    responseData = await API.post(service, payload, config);
    if (!payloadCallBack) {
      return responseData;
    }
    payloadCallBack(responseData);
    return responseData;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const putData = async (
  service: string,
  payload: { [key: string]: any } = {},
  payloadCallBack: any = false,
  config: { [key: string]: any } = {}
) => {
  try {
    let responseData = {} as AxiosResponse;
    responseData = await API.put(service, payload, config);
    if (!payloadCallBack) {
      return responseData;
    }
    payloadCallBack(responseData);
    return responseData;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const patchData = async (
  service: string,
  payload: { [key: string]: any } = {},
  payloadCallBack: any = false,
  config: { [key: string]: any } = {}
) => {
  try {
    let responseData = await API.patch(service, payload, config);
    if (!payloadCallBack) {
      return responseData;
    }
    payloadCallBack(responseData);
    return responseData;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const deleteData = async (
  service: string,
  config: { [key: string]: any } = {},
  payloadCallBack: any = false
) => {
  try {
    let responseData = {};
    responseData = await API.delete(service, config);
    if (!payloadCallBack) {
      return responseData;
    }
    payloadCallBack(responseData);
    return responseData;
  } catch (e) {
    return Promise.reject(e);
  }
};

export default {
  getData,
  postData,
  putData,
  deleteData,
  patchData,
};
