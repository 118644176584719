import React from "react";
import Banner from "Modules/DS/Banner";
import classNames from "classnames";
import { IInformationBannerProps } from "../type";

import styles from "./styles.module.scss";

const InformationBanner: React.FC<IInformationBannerProps> = ({ position, onSeeEmptyFields, customMessage }) => {
  const defaultMessage = (
    <>
      Please fill out all details for future audit purposes.{" "}
      <span onClick={onSeeEmptyFields} className={styles.highlight}>
        See empty fields
      </span>
    </>
  );

  const message = customMessage || defaultMessage;

  return (
    <div
      className={classNames({
        [styles["banner-wrapper"]]: position === "top",
        [styles["banner-wrapper__bottom"]]: position === "bottom",
      })}
      data-testid="banner-wrapper"
    >
      <Banner type="info" message={<p className={styles.banner}>{message}</p>} />
    </div>
  );
};

export default InformationBanner;
