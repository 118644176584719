import React, { useEffect, useState } from "react";
import Icon from "Modules/icons";
import SpDatePicker from "Modules/SpDatePicker";
import { Form } from "antd";
import styles from "../InvoiceForm.module.scss";
import "./DateSection.scss";
import moment from "moment";
import { bulbTooltip as bulbTooltipIcon } from "assets/img";
import Banner from "Modules/DS/Banner";
import { Tooltip, TooltipArrowAlignmentEnum, TooltipPositionEnum } from "Modules/DS/Tooltip";
import OCRTooltip from "Modules/ocrTooltip";
import { Loader } from "Modules/DS/Modal/Components";
import { CORRIDOR_SCHEDULE_DATE_INTERVAL } from "Views/UploadInvoice/const";

interface DateSectionProps {
  billDueDate: any;
  paymentDate: any;
  billIssuedDate: any;
  disabledBillDueDate: boolean;
  disabledPaymentDate: boolean;
  disabledBillIssuedDate: boolean;
  disabledBillDueDateField: boolean;
  isPaymentDateSelectedFirst: boolean;
  corridorType: string;
  ocrResult: any;
  actionBillDueDate: (value?: any) => void;
  actionPaymentDate: (value?: any, isNow?: boolean) => void;
  actionBillIssuedDate: (value?: any) => void;
  trackView: (eventName: string, value?: any) => void;
  renderOptionalLabel: (string) => React.ReactNode;
  billDueDateLoading?: boolean;
}

const DateSection = ({
  billDueDate,
  paymentDate,
  billIssuedDate,
  disabledBillDueDate,
  disabledPaymentDate,
  disabledBillIssuedDate,
  disabledBillDueDateField,
  actionBillDueDate = () => undefined,
  actionPaymentDate = () => undefined,
  actionBillIssuedDate = () => undefined,
  trackView = () => undefined,
  renderOptionalLabel = () => undefined,
  corridorType,
  isPaymentDateSelectedFirst,
  ocrResult,
  billDueDateLoading = false,
}: DateSectionProps) => {
  const [tooltipMessage, setTooltipMessage] = useState(""),
    [isLoadingtooltip, setIsLoadingTooltip] = useState(false),
    [isTooltipAutoShown, setIsTooltipAutoShown] = useState(false),
    [isPaymentDateOptimized, setIsPaymentDateOptimized] = useState(false),
    [banner, setBanner] = useState({
      message: "",
      title: "",
    });

  useEffect(() => {
    const isOverdue = billDueDate && billDueDate.isBefore(moment(), "day");
    const intervalDays =
      corridorType === "domestic"
        ? CORRIDOR_SCHEDULE_DATE_INTERVAL.DOMESTIC
        : CORRIDOR_SCHEDULE_DATE_INTERVAL.INTERNATIONAL;
    let predictedScheduleDate = moment(billDueDate).subtract(intervalDays, "day");
    const isScheduleDateLessThanDueDate = billDueDate && moment() > predictedScheduleDate;
    setIsLoadingTooltip(true);
    setTooltipMessage("");

    let bannerContent = {
      message: "",
      title: "",
    };
    if (isOverdue) {
      bannerContent = {
        message: "Please understand your payment will be late. To inform your vendors about the delay in payment.",
        title: "Bill Due Date has passed",
      };
    } else if (isScheduleDateLessThanDueDate) {
      bannerContent = {
        message: "Due to processing time, this payment is unlikely to reach the recipient by the Bill Due Date.",
        title: "Bill Due Date is approaching soon",
      };
    }
    setBanner(bannerContent);

    const showPaymentDateOptimized = billDueDate && paymentDate !== null && !isOverdue;
    setIsPaymentDateOptimized(showPaymentDateOptimized);

    let tooltipMessage = "";
    if (billDueDate) {
      tooltipMessage = defineTooltipMessage(predictedScheduleDate, isOverdue, isScheduleDateLessThanDueDate);
    }

    setTimeout(() => {
      setIsLoadingTooltip(false);
      setTooltipMessage(tooltipMessage);
      setIsTooltipAutoShown(true);

      setTimeout(() => {
        setIsTooltipAutoShown(false);
      }, 3000);
    }, 1000);
  }, [billDueDate, paymentDate]);

  const defineTooltipMessage = (predictedScheduleDate, isOverdue, isScheduleDateLessThanDueDate) => {
    if (isOverdue || isScheduleDateLessThanDueDate) {
      return "Your bill will be processed immediately. Please inform your vendor for the delayed payment.";
    }

    if (isPaymentDateSelectedFirst) {
      const predictedScheduleDateHuman = moment(predictedScheduleDate).isAfter(moment(), "day")
        ? moment(predictedScheduleDate).format("DD MMM YYYY")
        : "Pay Immediately";
      return `To have your payment arrive on time by the Bill Due Date, this payment should be initiated on/before ${predictedScheduleDateHuman}.`;
    }

    return "To allow for processing time, your Payment Scheduled On is auto-scheduled to meet the Bill Due Date.";
  };

  return (
    <div className="date-section">
      {Boolean(banner.title) && (
        <Banner
          type="attention"
          message={
            <>
              <h3>{banner.title}</h3>
              <p className="banner-message">
                {banner.message}{" "}
                <a
                  target="_blank"
                  href="https://support.spenmo.com/hc/en-us/articles/4412728657433-Bill-Pay-Processing-Time"
                >
                  Learn more
                </a>
              </p>
            </>
          }
        />
      )}
      {isPaymentDateOptimized && (
        <div className="optimized-label">
          <Icon className="icon-cursor-default" alt="bulb-tooltip-icon" src={bulbTooltipIcon} />
          <label>We have optimized the Payment Scheduled On for you</label>
        </div>
      )}
      <Form.Item
        label={
          Boolean(ocrResult.billDueDate) ? (
            <OCRTooltip
              label="Bill Due Date"
              isOptional={false}
              isOCROveridden={ocrResult.billDueDate !== billDueDate}
            />
          ) : (
            renderOptionalLabel("Bill Due Date")
          )
        }
        name="billDueDate"
      >
        <SpDatePicker
          data-testid="bill-duedate"
          hasClear
          className={styles["date-picker"]}
          defaultDate={billDueDate}
          action={actionBillDueDate}
          disabledDate={disabledBillDueDate}
          disabled={disabledBillDueDateField}
          placeholder="Select a date"
          onFocus={() => trackView("billDueDate", billDueDate)}
        />
        {billDueDateLoading && (
          <div className="loader-container">
            <Loader className="custom-loader" />
          </div>
        )}
        {disabledBillDueDateField && <p className="date-hint">Select a recipient to update the Bill Due Date</p>}
      </Form.Item>
      <Form.Item
        label={
          Boolean(ocrResult.billScheduledDate) ? (
            <OCRTooltip
              label="Payment Scheduled On"
              isOptional={false}
              isOCROveridden={ocrResult.billScheduledDate !== paymentDate}
            />
          ) : (
            "Payment Scheduled On"
          )
        }
        name="paymentDate"
      >
        <SpDatePicker
          data-testid="payment-schedule"
          className={styles["date-picker"]}
          placeholder="Pay Now"
          defaultDate={paymentDate}
          action={actionPaymentDate}
          disabledDate={disabledPaymentDate}
          onFocus={() => trackView("paymentDate", paymentDate)}
        />
        {tooltipMessage !== "" && (
          <div className="icon-tooltip icon-tooltip-payment-schedule">
            <Tooltip
              text={tooltipMessage}
              alignArrow={TooltipArrowAlignmentEnum.CENTER}
              position={TooltipPositionEnum.LEFT}
              showWithoutHover={isTooltipAutoShown}
            >
              <Icon alt="bulb-tooltip-icon" src={bulbTooltipIcon} />
            </Tooltip>
          </div>
        )}
        {isLoadingtooltip && (
          <div className="loader-container">
            <Loader className="custom-loader" />
          </div>
        )}
      </Form.Item>
      <Form.Item
        label={
          Boolean(ocrResult.billIssuedDate) ? (
            <OCRTooltip
              label="Bill Issuance Date"
              isOptional
              isOCROveridden={ocrResult.billIssuedDate !== billIssuedDate}
            />
          ) : (
            renderOptionalLabel("Bill Issuance Date")
          )
        }
        name="billIssuedDate"
      >
        <SpDatePicker
          hasClear
          className={styles["date-picker"]}
          defaultDate={billIssuedDate}
          action={actionBillIssuedDate}
          disabledDate={disabledBillIssuedDate}
          placeholder="Enter bill issuance date"
          onFocus={() => trackView("billIssuedDate", billIssuedDate)}
        />
      </Form.Item>
    </div>
  );
};

export default DateSection;
