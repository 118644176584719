import React, { useEffect } from "react";
import { SidePanel } from "Modules/DS/SidePanel";
import { EBannerPosition, EInputType, TransactionDetail, TransactionHeader } from "Modules/TransactionDetail";
import { EWorkflowApiStatus, ITransactionSidePanelProps } from "../type";
import { useTransactionDetail } from "../hooks/useTransactionDetail";
import { allOption, refundOption } from "Modules/TransactionDetail/TransactionDetail";
import { EXPENSE_NOT_FOUND } from "../constant/constant";
import { useFormContext } from "../context/FormContext";
import { findEmptyField, findTransactionType, generateMissingField } from "./helper";
import { useFetchInvoiceLink } from "Modules/BillImportedFromXeroBanner/useFetchInvoiceLink";

const TransactionSidePanel: React.FC<ITransactionSidePanelProps> = ({
  visible,
  setVisible,
  type,
  buttons,
  record,
  relatedTransaction,
  onClickRelatedTransaction,
  customBannerMessage,
  ignoreRequiredCheck,
  activeTab,
}) => {
  const { fetchInvoiceLink, billImportedFromXeroData, setBillImportedFromXeroData, loadingXeroInvoiceLink } =
    useFetchInvoiceLink();

  const { isBill, isTopup, nonExpenseTransaction, isSettlement, isIncentive, isFee } = findTransactionType(type);
  const { form, setSelectedExpense, setFormValue, accountingSetting, expenseNotFound, setExpenseNotFound } =
    useFormContext();
  const hideInputField = isTopup;
  const notRequiredApiType = isTopup || expenseNotFound || isSettlement;

  const generateVisibleInput = () => {
    if (expenseNotFound) {
      return { ...refundOption };
    }
    if (isFee) {
      return { ...allOption, [EInputType.RECEIPT]: false };
    }
    if (isIncentive) {
      return {
        [EInputType.MERCHANT]: false,
        [EInputType.CATEGORY]: true,
        [EInputType.TAX]: false,
        [EInputType.TRANSACTION_TAGS]: false,
        [EInputType.RECEIPT]: false,
        [EInputType.NOTES]: true,
      };
    }
    return hideInputField ? {} : allOption;
  };
  const activatedSetting = accountingSetting?.settingsV2?.[record.accountingTransactionStatus] || [];

  const { header, loading, detail, defaultValue, cleanExpense, trxnDetails, setTrxnDetails } = useTransactionDetail(
    record,
    visible,
    {
      useLegacyRefund: expenseNotFound,
      notRequiredApiType,
      relatedTransaction,
      onClickRelatedTransaction,
      onSuccess({ expense }) {
        setSelectedExpense(expense);
      },
      onError(data) {
        if (data?.payload?.status_message === EXPENSE_NOT_FOUND) {
          setExpenseNotFound(true);
        }
      },
    }
  );

  const isBillCreatedInXero = trxnDetails?.source === "portal_xero";
  const isInAccountingCompletedTab = activeTab === EWorkflowApiStatus.COMPLETED;

  useEffect(() => {
    if (visible && record.id && isBillCreatedInXero) {
      fetchInvoiceLink(record.id);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record, trxnDetails]);

  useEffect(() => {
    if (!visible) setTrxnDetails({});
  }, [visible]);

  const findBannerPosition = () => {
    if (customBannerMessage) {
      return isInAccountingCompletedTab && isBillCreatedInXero ? null : EBannerPosition.TOP;
    }

    if (expenseNotFound || hideInputField) {
      return null;
    }

    return !nonExpenseTransaction && findEmptyField(form.getFieldsValue(), activatedSetting)?.length !== 0
      ? EBannerPosition.TOP
      : null;
  };

  const sidePanelCloseHandler = () => {
    setVisible(false);
    setExpenseNotFound(false);
    setBillImportedFromXeroData(null);
  };

  return (
    <SidePanel
      title={isBill ? "Bill Payment" : `${type} Details`}
      visible={visible}
      buttons={buttons}
      loading={(notRequiredApiType ? false : loading) || loadingXeroInvoiceLink}
      sticky={buttons.length !== 0}
      onClose={sidePanelCloseHandler}
    >
      <TransactionHeader {...header()} xeroBillBannerData={billImportedFromXeroData} />
      <TransactionDetail
        receiptTitle={isBill ? "Attachments" : "Receipts"}
        details={detail()}
        defaultValue={defaultValue()}
        visibleInput={generateVisibleInput()}
        form={form}
        bannerPosition={findBannerPosition()}
        missingFields={generateMissingField(form.getFieldsValue(), activatedSetting)}
        setFormValue={setFormValue}
        additionalMarginButtom={expenseNotFound}
        customBannerMessage={customBannerMessage}
        deletable={false}
        categoryFallback={cleanExpense?.expense_category_name}
        taxFallback={cleanExpense?.tax?.tax_name}
        ignoreRequiredCheck={ignoreRequiredCheck}
        disabled={nonExpenseTransaction}
      />
    </SidePanel>
  );
};

export default TransactionSidePanel;
