import * as React from "react";
import { useSelector } from "react-redux";
import useCheckFeatureStatus from "customHooks/featureCheck";
import { SPLIT_NAMES, SPLIT_TREATMENT_TYPES } from "Redux/splitio/constants";
import { trackEvent } from "utility/analytics";
import { useIsManagerOrAdmin, getIsCardVendorNium } from "customHooks/Card";
import { CARD_COLORS } from "constants/Card/CardColors.constants";
import { spenmoOrangeLogoSmall, spenmoWhiteLogoSmall } from "assets/v1.1/logo/spenmo";
import { vendor_logo, visaIcon } from "assets/img";
import { useCreateOrRequestCardContext } from "Views/Card/CreateOrRequest/index.context";
import { CARD_TYPE } from "Views/Card/types";
import { Actions } from "Views/Card/CreateOrRequest/Utilities/UseCreateOrRequestState";
import LetterAvatar from "Modules/letterAvatar";
import { NiumDisclaimer } from "Modules/NiumDisclaimer";

import "Views/Card/CreateOrRequest/Steps/ColorAndApprover/index.scss";

export const ColorAndApprover = () => {
  const { state, dispatch, cardType } = useCreateOrRequestCardContext();
  const treatment = useCheckFeatureStatus(SPLIT_NAMES.removeCardsColorSelection, true);
  const showCardColorSelection = treatment === SPLIT_TREATMENT_TYPES.OFF;

  const [isAdmin, isManager] = useIsManagerOrAdmin();
  const isManagerOrAdmin = isAdmin || isManager;
  const userName = useSelector((state: any) => state?.userInfoReducer?.data?.payload?.user?.name || "");

  const isCardVendorNium = getIsCardVendorNium();

  const onColorChange = (color: string) => {
    dispatch({ type: Actions.UPDATE_CARD_COLOR, value: color });
  };

  const getInfoText = () => {
    const { selectedTeamId, adminOrManagerInSelectedTeam } = state;

    if (!isManagerOrAdmin)
      return `This card will be ${
        cardType === CARD_TYPE.PHYSICAL ? "delivered" : "assigned"
      } to you once your card request is approved by your Budget Owner.`;

    if (cardType === CARD_TYPE.VIRTUAL) {
      if (selectedTeamId) {
        return adminOrManagerInSelectedTeam
          ? "As Budget Owner, you can create cards for both yourself and your budget members"
          : "This card will be assigned to you with the approval from your Budget Owner(s)";
      }
      return `Assign a new Virtual Card to yourself or member of a budget. In budgets where you are not an Owner, your card is subject to the Budget Owner's approval.`;
    } else {
      if (selectedTeamId) {
        return adminOrManagerInSelectedTeam
          ? "The physical card will be delivered to your address. Activate the card prior to use."
          : "This card will be delivered to you once the card request is approved by your Budget Owner";
      }
      return "The physical card will be delivered to your address. Activate the card prior to use.";
    }
  };

  const getCardTitle = () => {
    const { cardTitle } = state;
    return cardType === CARD_TYPE.PHYSICAL ? userName?.toUpperCase() : cardTitle;
  };

  return (
    <div className="card-color-and-approver">
      {/* Card */}
      <div
        className="card-color-and-approver__card"
        style={{
          backgroundColor: state.cardColor,
        }}
        data-testid="card"
      >
        <img
          className="card-color-and-approver__company-logo"
          src={state.cardColor === CARD_COLORS[0] ? spenmoOrangeLogoSmall : spenmoWhiteLogoSmall}
          alt="company logo"
          height={24}
          width={22}
        />
        <p className="card-color-and-approver__card-title">{getCardTitle()}</p>
        <div className="card-color-and-approver__card-footer">
          <p
            className="card-color-and-approver__card-number"
            style={{ color: state.cardColor === CARD_COLORS[0] ? "#666b79" : "#FFF" }}
          >
            **** **** **** ****
          </p>
          <img src={isCardVendorNium ? visaIcon : vendor_logo} alt={"vendor icon"} />
        </div>
      </div>
      {/* Color Picker */}
      {cardType === CARD_TYPE.VIRTUAL && showCardColorSelection && (
        <div>
          {CARD_COLORS.map((color, index) => (
            <div
              onClick={() => {
                trackEvent("create virtual card color changed", { colorname: color });
                onColorChange(color);
              }}
              className={`card-color-and-approver__color-wrapper ${color === state.cardColor ? "active" : ""}`}
              key={`${color}_${index}`}
            >
              <div
                style={{ backgroundColor: color }}
                className="card-color-and-approver__color"
                data-testid="card-color"
              />
            </div>
          ))}
        </div>
      )}
      {/* Approver & Info Text */}
      <div className="card-color-and-approver__info-container">{getInfoText()}</div>
      <div className="card-color-and-approver__selected-approver">
        {state.selectedApproverName && (
          <>
            <LetterAvatar rounded name={state.selectedApproverName} />
            <p className={"card-color-and-approver__approver-name"}>{state.selectedApproverName}</p>
          </>
        )}
      </div>
      <NiumDisclaimer type="card" />
    </div>
  );
};
