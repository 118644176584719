import {
  GET_PENDING_APPROVALS_REQUEST,
  GET_PENDING_APPROVALS_SUCCESS,
  GET_PENDING_APPROVALS_FAILURE,
  CLEAR_GET_PENDING_APPROVALS,
} from "../../Actions/TransactionApproval/getPendingApprovalsAction";
import { userInitialState } from "../../InitialState";

export const getPendingApprovalsReducer = (state = userInitialState, action) => {
  switch (action.type) {
    case GET_PENDING_APPROVALS_REQUEST:
      return {
        ...state,
        data: action.data,
        loading: true,
      };
    case GET_PENDING_APPROVALS_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
      };
    case GET_PENDING_APPROVALS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.data,
        error: true,
      };
    case CLEAR_GET_PENDING_APPROVALS:
      return userInitialState;
    default:
      return state;
  }
};
