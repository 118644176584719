import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import TeamMemberModal from "Views/Team/Profile/AddMember/TeamMemberModal";
import { getAllMembersFunc } from "Redux/Actions";
import { trackActionsOnTeamsPage, TeamProfileEventsMapping } from "Views/Team/Profile/TeamProfileEventsUtilites";

import "./AddMember.scss";
import { useDispatch, useSelector } from "react-redux";

const AaddMember = (props) => {
  const { teamDetails } = props || {};
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.membersReducer || {});
  const { addMemberLoading } = useSelector((state) => state.teamReducer || {});
  const [isModalVisible, setModalVisibility] = useState(false);
  const handleAddMembers = () => {
    setModalVisibility(true);
    trackActionsOnTeamsPage(TeamProfileEventsMapping.addNewMemberClicked);
  };

  useEffect(() => {
    dispatch(
      getAllMembersFunc({
        exclude_default_users: true,
        include_non_wallet: true,
      })
    );
  }, [dispatch]);

  return (
    <>
      <button className="button send-money-btn" onClick={() => handleAddMembers()}>
        + Add Members
      </button>
      {isModalVisible && (
        <TeamMemberModal
          isModalVisible={isModalVisible}
          setModalVisibility={setModalVisibility}
          employeeData={data}
          teamDetails={teamDetails}
          addMemberLoading={addMemberLoading}
        />
      )}
    </>
  );
};

AaddMember.propTypes = {
  teamDetails: PropTypes.object,
};
export default AaddMember;
