import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Tabs } from "antd";
import Chip from "Modules/DS/Chip";
import Icon from "Modules/icons";
import { pencil, grayInfoCircle } from "assets/v1.1/icons/Line";

import { stopPayment, updateBillDetail } from "Redux/DataCalls/Disbursement.api";
import { UserInfoFunc } from "Redux/Actions";

import { TABS, RELOAD_TIME_OUT } from "Views/TransactionsListing/Pending/const";
import Status from "Views/TransactionsListing/Pending/Status";
import ExtractingInfo from "Views/TransactionsListing/Pending/Extracting/InfoCard";
import ExtractingDetail from "Views/TransactionsListing/Pending/Extracting/Detail";
import TransactionInfo from "Views/TransactionsListing/Pending/Transaction/InfoCard";
import TransactionDetail from "Views/TransactionsListing/Pending/Transaction/Detail";
import LoadingComponent from "Views/State/Loading/LoaderIcon";
import ErrorComponent from "Views/State/Error";

import { appNotification } from "Modules/appNotification/appNotification";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";

import StopPaymentButton from "./StopPaymentButton";
import StopPaymentConfirmation from "./StopPaymentConfirmation";

import styles from "./PendingDetail.module.scss";
import { EMAIL_APPROVAL_STATE } from "Views/TransactionsListing/TransactionsHelper";
import { ENABLE_CONFIRM_TYPES, STATUS } from "../const";
import { trackEvent } from "utility/analytics";
import { useHistory, useLocation } from "react-router-dom";
import { APPROVAL_REQUEST_CENTER } from "constants/Routes.constants";

import ApproveDeclineButton from "../Transaction/Detail/ApproveDeclineButton";
import { APPROVAL_ACTIONS, DETAIL_APPROVAL_EVENT, STATUS_COLOR_MAP, APPROVAL_STATUS_EVENT } from "../../helper";
import { TOASTER_SIZE_TYPE, TOASTER_STATUS_TYPE } from "Modules/DS/Toaster/types.d";
import Toaster from "Modules/DS/Toaster";
import { Tooltip, TooltipPositionEnum, TooltipArrowAlignmentEnum } from "Modules/DS/Tooltip";
import { GetSingleInvoice } from "Redux/DataCalls/Invoices.api";
import BillImportedFromXeroBanner from "Modules/BillImportedFromXeroBanner";
import { useFetchInvoiceLink } from "Modules/BillImportedFromXeroBanner/useFetchInvoiceLink";

const PAGE_TABS = {
  all: "all",
  approvals: "approvals",
};

const PendingDetail = ({
  id,
  isExtracting,
  approvalState,
  approvalAction,
  enableConfirm = ENABLE_CONFIRM_TYPES.both,
  setEditBillProps = () => {},
  trackEventData,
  viewRevampedBillTrxnsTable,
}) => {
  const [userInfoData, isXeroConnectedUsingBankfeed] = useSelector((state) => [
    state.userInfoReducer,
    state.accountingPartnerAuthReducer?.data?.payload?.bankfeed,
  ]);

  const dispatch = useDispatch();

  const [isStoppingPayment, setIsStoppingPayment] = useState(false),
    [isSubmit, setIsSubmit] = useState(false),
    [invoiceData, setInvoiceData] = useState({}),
    [loading, setLoading] = useState(false),
    [error, setError] = useState(false),
    [formData, setFormData] = useState({
      receiptFiles: [],
      attachmentFiles: [],
      tags: [],
      expenseCategoryID: null,
      taxID: null,
      clientNotes: "",
    }),
    [toaster, setToaster] = useState({
      isShown: false,
      type: TOASTER_STATUS_TYPE.SUCCESS,
      message: "",
    }),
    [isFormChanged, setIsFormChanged] = useState(false);

  const defaultActiveKey = approvalState !== EMAIL_APPROVAL_STATE.detail ? TABS.details : TABS.status;

  const { fetchInvoiceLink, billImportedFromXeroData, setBillImportedFromXeroData } = useFetchInvoiceLink();

  const { TabPane } = Tabs,
    { payload = {} } = invoiceData;

  useEffect(() => {
    dispatch(UserInfoFunc());
  }, [dispatch]);

  const fetchSingleInvoice = async (id) => {
    setLoading(true);
    try {
      const invoiceResponse = await GetSingleInvoice(id);
      if (invoiceResponse.status === HTTP_STATUS_CODE.OK) {
        setInvoiceData(invoiceResponse);
        if (isXeroConnectedUsingBankfeed && invoiceResponse?.payload?.source === "portal_xero")
          await fetchInvoiceLink(id, "externalId");
        setLoading(false);
        setFormData({
          receiptFiles: [],
          attachmentFiles: [],
          expenseCategoryID: invoiceResponse?.payload?.expenseCategoryID,
          taxID: invoiceResponse?.payload?.taxID,
          clientNotes: invoiceResponse?.payload?.clientNotes,
        });
      } else {
        throw invoiceResponse;
      }
    } catch (e) {
      setError(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    setIsStoppingPayment(false);
    fetchSingleInvoice(id);
    return () => setBillImportedFromXeroData(null);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (
      formData.clientNotes !== payload?.clientNotes ||
      formData.taxID !== payload?.taxID ||
      formData.expenseCategoryID !== payload?.expenseCategoryID ||
      formData.attachmentFiles.length > 0 ||
      (payload?.tags && JSON.stringify(formData.tags) !== JSON.stringify(payload?.tags || []))
    ) {
      setIsFormChanged(true);
    } else {
      setIsFormChanged(false);
    }
  }, [formData]);

  const onClickStopPayment = () => {
    setIsStoppingPayment(true);
  };

  const onCancelStopPayment = () => {
    setIsStoppingPayment(false);
  };

  const onConfirmStopPayment = async () => {
    setIsSubmit(true);
    const { data } = await stopPayment([id]);

    if (data.status === HTTP_STATUS_CODE.OK && data.payload?.data?.[0]?.isSuccess) {
      appNotification.success({ message: "Payment stopped successfully." });
      setTimeout(() => window.location.reload(), RELOAD_TIME_OUT);
    } else {
      appNotification.error({ message: "An error occurred. Please try again." });
      setIsSubmit(false);
    }
  };
  const history = useHistory();
  const location = useLocation();
  const isApprovalPage = location.pathname === APPROVAL_REQUEST_CENTER;

  const tab = history.location.search?.search?.("&subtab=to_approve") > -1 ? PAGE_TABS.approvals : PAGE_TABS.all;

  const updateBill = async () => {
    let actionLabel = APPROVAL_ACTIONS.save;
    let message = "Changes successfully made.";
    trackEvent(DETAIL_APPROVAL_EVENT(actionLabel, true, tab), { transaction_id: id });
    setIsSubmit(true);
    const { data } = await updateBillDetail({ ...formData, id });

    if (data.status === HTTP_STATUS_CODE.OK && data.payload.isSuccess) {
      trackEvent(APPROVAL_STATUS_EVENT(actionLabel, true), { transaction_id: id });
      setToaster({
        isShown: true,
        message,
        type: TOASTER_STATUS_TYPE.SUCCESS,
      });
      setTimeout(() => {
        if (history.location.search?.search?.("invoiceid=")) {
          const redirectUrl = `${history.location.pathname}${history.location.search
            ?.replace?.("&subtab=to_approve", "")
            ?.replace?.("&action=approve", "")
            ?.replace?.("&action=decline", "")}`;
          history.push(redirectUrl);
        }
        window.location.reload();
      }, RELOAD_TIME_OUT);
    } else {
      setToaster({
        isShown: true,
        message: "An error occurred. Please try again.",
        type: TOASTER_STATUS_TYPE.ERROR,
      });
      setIsSubmit(false);
    }
  };

  if (loading) return <LoadingComponent />;
  if (error) return <ErrorComponent />;

  const onCloseEditBill = () => {
    setEditBillProps({
      visible: false,
      billID: null,
      onClose: () => undefined,
    });
  };

  const isAdmin = userInfoData?.data?.payload?.user?.is_admin;
  const isRequestorEmailMatch =
    payload?.requestorEmail && userInfoData?.data?.payload?.user?.email === payload?.requestorEmail;
  const isRequestorIdMatch = payload?.requestorID && userInfoData?.data?.payload?.user?.id === payload?.requestorID;
  const isRequestor = isRequestorEmailMatch || isRequestorIdMatch;
  const isApprover = payload?.approvalSteps?.reduce(
    (acc, curval) => acc || curval?.assignee?.id === userInfoData?.data?.payload?.user?.id
  );

  const showEditBillBtn =
    setEditBillProps &&
    (isAdmin || isRequestor) &&
    tab === PAGE_TABS.all &&
    (payload?.statusInfo?.key === STATUS.waitingApproval || payload?.statusInfo?.key === STATUS.rejected);

  const REQUESTOR_EDIT_RESTRICTED = {
    [STATUS.approved]: "Bill has entered the approval or payment process and cannot be edited.",
    [STATUS.extracted]: "Bill is currently being extracted and cannot be edited.",
    [STATUS.insufficient]: "Bill has been approved and cannot be edited.",
  };
  const APPROVER_EDIT_RESTRICTED = {
    [STATUS.waitingApproval]: "Bills can only be edited by the requester or administrator.",
    [STATUS.declined]: "Bills can only be edited by the requester or administrator.",
  };

  let editRestrictedTooltip;
  if ((isRequestor || isAdmin) && REQUESTOR_EDIT_RESTRICTED.hasOwnProperty(payload?.statusInfo?.key)) {
    editRestrictedTooltip = REQUESTOR_EDIT_RESTRICTED[payload?.statusInfo?.key];
  } else if (isApprover && APPROVER_EDIT_RESTRICTED.hasOwnProperty(payload?.statusInfo?.key)) {
    editRestrictedTooltip = APPROVER_EDIT_RESTRICTED[payload?.statusInfo?.key];
  }

  const editRestrictedLabel =
    editRestrictedTooltip && !userInfoData?.loading ? (
      <div className={styles.editRestricted}>
        <span>Editing Restricted</span>
        <Tooltip
          text={editRestrictedTooltip}
          position={TooltipPositionEnum.TOP}
          alignArrow={TooltipArrowAlignmentEnum.RIGHT}
        >
          <Icon src={grayInfoCircle} />
        </Tooltip>
      </div>
    ) : null;

  const editBillButton = (
    <div
      className={styles.editButton}
      onClick={() => {
        setEditBillProps({
          visible: true,
          billID: [id],
          onClose: onCloseEditBill,
        });
      }}
    >
      <Icon className={styles.editIcon} src={pencil} />
      Edit Bill
    </div>
  );

  const editBillComponent = showEditBillBtn ? editBillButton : editRestrictedLabel;
  const isShowApproveDeclineButton =
    !isStoppingPayment && isApprovalPage && payload?.statusInfo?.key === STATUS.waitingApproval;

  return (
    <>
      <div className={styles.container}>
        {!viewRevampedBillTrxnsTable && <h1 className={styles.title}>Bill Payment</h1>}
        {!isStoppingPayment ? (
          <>
            {isExtracting ? (
              <ExtractingInfo id={id} data={payload} />
            ) : (
              <TransactionInfo
                id={id}
                merchant={payload?.beneficiary?.name}
                currency={payload?.currency}
                amount={payload?.amount}
                isShowConversion={payload?.fxRate > 0}
                walletCurrency={payload?.walletCurrency}
                walletAmount={payload?.walletAmount}
                status={payload.status}
                billAmountFeeInclusive={payload?.billAmountFeeInclusive}
                feeAmount={payload?.totalFeeAmount + payload?.swiftPaymentChargeFee}
                isFeeDirectlyDeducted={payload?.isFeeDirectlyDeducted}
              />
            )}
            {payload?.statusInfo && (
              <div className={styles.status}>
                <Chip.Status title={payload.statusInfo.label} status={STATUS_COLOR_MAP[payload.statusInfo.color]} />
                {!isApprovalPage && editBillComponent}
              </div>
            )}
            {billImportedFromXeroData ? (
              <BillImportedFromXeroBanner xeroBillBannerData={billImportedFromXeroData} />
            ) : null}
            <Tabs className={styles.tab} defaultActiveKey={defaultActiveKey}>
              <TabPane tab={TABS.status} key={TABS.status}>
                <Status data={payload} paymentScheduleTime={payload?.paymentScheduleTime} isExtracting={isExtracting} />
              </TabPane>
              <TabPane tab={TABS.details} key={TABS.details}>
                {isExtracting ? (
                  <ExtractingDetail attachmentImages={payload?.attachments} requestor={payload?.requestor} />
                ) : (
                  <TransactionDetail data={payload} />
                )}
              </TabPane>
            </Tabs>
            {isExtracting && <StopPaymentButton onClick={onClickStopPayment} />}
          </>
        ) : (
          <StopPaymentConfirmation
            onConfirm={onConfirmStopPayment}
            onCancel={onCancelStopPayment}
            merchant={payload?.beneficiary?.name}
            currency={payload?.currency}
            amount={payload?.amount}
            isSubmit={isSubmit}
          />
        )}
      </div>
      {isShowApproveDeclineButton && (
        <ApproveDeclineButton
          approvalState={approvalState}
          isAdmin={isAdmin}
          isPendingApproval={payload?.isPendingApproval}
          onSave={() => updateBill()}
          onApprove={() => approvalAction(true)}
          onDecline={() => approvalAction(false)}
          enableConfirm={enableConfirm}
          isFormChanged={isFormChanged}
          enableSave={!isApprovalPage}
          isSubmit={isSubmit}
          trackApproval={(action, source) => {
            trackEvent(trackEventData.name, {
              ...trackEventData.data,
              approval_bills_event_action: action,
              approval_bills_event_source: source,
            });
          }}
        />
      )}
      <Toaster
        message={toaster.message}
        visible={toaster.isShown}
        status={toaster.type}
        size={TOASTER_SIZE_TYPE.L}
        onClose={() => {
          setToaster({ isShown: false });
        }}
      />
    </>
  );
};

PendingDetail.defaultProps = { id: "", isExtracting: false };

PendingDetail.propTypes = {
  id: PropTypes.string,
  isExtracting: PropTypes.bool,
  approvalState: PropTypes.string,
  approvalAction: PropTypes.func,
  enableConfirm: PropTypes.oneOf(Object.values(ENABLE_CONFIRM_TYPES)),
  setEditBillProps: PropTypes.func,
  viewRevampedBillTrxnsTable: PropTypes.bool,
};

export default PendingDetail;
