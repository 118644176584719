import { useState } from "react";
import qs from "query-string";
import { Base64 } from "js-base64";
import { useHistory, useLocation } from "react-router-dom";
import { TActionButton } from "@spenmo/splice/lib/shared/types";
import { CrossFilled, EditOutline, CheckFilled } from "@spenmo/splice";

import { APPROVAL_PATHS } from "Route/Approval/paths";

import { CASBIN_PAGES } from "Permission/Pages";
import usePermissionCheck from "Permission/usePermissionCheck";
import { REIMBURSEMENT_TRANSACTION_PAGE } from "Permission/Actions";

import { currencyFormatterV2 } from "utility";

import {
  REIMBURSEMENT_VIEWS,
  REIMBURSEMENT_MODAL_OPTIONS,
  REIMBURSEMENT_PAGE_OPERATIONS,
  REIMBURSEMENT_QUERY_PARAMS,
} from "Views/Reimbursement/Constants";
import { ILocationQueryParams, IUseDetailState } from "Views/Reimbursement/@types";
import { REIMBURSEMENTS_PAGE_ANALYTICS, trackReimbursementAnalytics } from "Views/Reimbursement/Analytics";

import useSaveRequest from "Views/Reimbursement/hooks/useSaveRequest";
import useFetchDetails from "Views/Reimbursement/hooks/useFetchDetails";
import useCancelRequest from "Views/Reimbursement/hooks/useCancelRequest";
import { useFetchCRState } from "Views/Reimbursement/Settings/useCRState";

const useDetailState = (id: string): IUseDetailState => {
  const history = useHistory();
  const location = useLocation();

  const query: ILocationQueryParams = qs.parse(location.search, {
    parseNumbers: true,
    parseBooleans: true,
  });

  const [formValue, setFormValue] = useState<any>({});
  const { data, showErrorState, showSkeleton } = useFetchDetails(id);

  const { fetchState } = useFetchCRState();
  const { isLoading, isValidating, data: settlementData } = fetchState;

  const { cancelRequest } = useCancelRequest();
  const { saveRequest } = useSaveRequest();

  const permissionParam = [
    { object: CASBIN_PAGES.REIMBURSEMENT_TRANSACTION_PAGE, action: REIMBURSEMENT_TRANSACTION_PAGE.MARK_AS_SETTLE },
    { object: CASBIN_PAGES.REIMBURSEMENT_TRANSACTION_PAGE, action: REIMBURSEMENT_TRANSACTION_PAGE.MAKE_PAYMENT },
    {
      object: CASBIN_PAGES.REIMBURSEMENT_TRANSACTION_PAGE,
      action: REIMBURSEMENT_TRANSACTION_PAGE.SAVE_ACCOUNTING_FIELDS,
    },
  ];

  const [permissions, loadingPermission] = usePermissionCheck(permissionParam, true) as [boolean[], boolean];

  const isAllowedSettling = permissions?.[0] || false;
  const isAllowedPayment = permissions?.[1] || false;
  const isAllowedSaving = permissions?.[2] || false;

  const isPersonalTab = location.pathname.includes(REIMBURSEMENT_VIEWS.PERSONAL_REIMBURSEMENTS.link);

  const loading = showSkeleton || isLoading || isValidating || loadingPermission;

  const isSettlementEnabled: boolean = settlementData?.data?.payload?.isEnabled || false;

  const eventName = isSettlementEnabled
    ? REIMBURSEMENTS_PAGE_ANALYTICS.REIMBURSEMENT_DETAILS_SETTLEMENT_ON
    : REIMBURSEMENTS_PAGE_ANALYTICS.REIMBURSEMENT_DETAILS_SETTLEMENT_OFF;

  const handleOnClose = () => {
    history.push({
      pathname: location.pathname.replace(`/${REIMBURSEMENT_PAGE_OPERATIONS.READ}/${id}`, ""),
      search: qs.stringify({ ...query, [REIMBURSEMENT_QUERY_PARAMS.PAGE]: 1 }),
    });
  };

  const handleOnEditDetails = () => {
    history.push(`${APPROVAL_PATHS.REIMBURSEMENTS}/${REIMBURSEMENT_PAGE_OPERATIONS.UPDATE}/${id}`);
  };

  const handleSaveChanges = () => {
    saveRequest(id, formValue, data)
      .then(() => {
        trackReimbursementAnalytics({
          eventName: eventName,
          eventSource: "Save Changes",
          eventSubSource: "Clicked",
        });
      })
      .finally(() => {
        handleOnClose();
      });
  };

  const handleDeleteRequest = () => {
    cancelRequest(id)
      .then(() => {
        trackReimbursementAnalytics({
          eventName: eventName,
          eventSource: "Cancel Request",
          eventSubSource: "Clicked",
        });
      })
      .finally(() => {
        handleOnClose();
      });
  };

  const handleMarkAsSettled = () => {
    const val = currencyFormatterV2(data.amount, data.currency_code, true, data.currency_code !== "IDR" ? 2 : 0);
    history.push({
      pathname: `${location.pathname}/${REIMBURSEMENT_PAGE_OPERATIONS.MODAL}/${REIMBURSEMENT_MODAL_OPTIONS.MARK_AS_SETTLED}`,
      search: qs.stringify({
        ...query,
        [REIMBURSEMENT_QUERY_PARAMS.BULK_SETTLEMENT]: false,
        [REIMBURSEMENT_QUERY_PARAMS.PAYOUT_VALUE]: Base64.encodeURI(val),
      }),
    });
    trackReimbursementAnalytics({
      eventName: eventName,
      eventSource: "Mark As Settled",
      eventSubSource: "Clicked",
    });
  };

  const handleMakePayment = () => {
    const val = currencyFormatterV2(data.amount, data.currency_code, true, data.currency_code !== "IDR" ? 2 : 0);
    history.push({
      pathname: `${location.pathname}/${REIMBURSEMENT_PAGE_OPERATIONS.MODAL}/${REIMBURSEMENT_MODAL_OPTIONS.MAKE_PAYMENT}`,
      search: qs.stringify({
        ...query,
        [REIMBURSEMENT_QUERY_PARAMS.PAYOUT_USER]: Base64.encodeURI(data.user_name),
        [REIMBURSEMENT_QUERY_PARAMS.PAYOUT_VALUE]: Base64.encodeURI(val),
      }),
    });
    trackReimbursementAnalytics({
      eventName: eventName,
      eventSource: "Make Payment",
      eventSubSource: "Clicked",
    });
  };

  const actionButton =
    data?.final_status?.key === "waiting_approval" && isPersonalTab
      ? {
          icon: EditOutline,
          action: handleOnEditDetails,
        }
      : null;

  const saveChanges: TActionButton =
    isAllowedSaving && data?.final_status?.key === "approved" && !isPersonalTab
      ? {
          title: "Save Changes",
          variant: "secondary",
          action: handleSaveChanges,
          disabled: loading,
        }
      : null;

  const deleteRequest: TActionButton =
    data?.final_status?.key === "waiting_approval"
      ? {
          title: "Cancel Request",
          variant: "danger",
          icon: CrossFilled,
          action: handleDeleteRequest,
          disabled: loading,
        }
      : null;

  const markAsSettled: TActionButton =
    isAllowedSettling && data?.final_status?.key === "approved" && data?.is_able_to_settle && !isPersonalTab
      ? {
          title: "Mark as Paid",
          variant: "success",
          icon: CheckFilled,
          action: handleMarkAsSettled,
          disabled: loading,
        }
      : null;

  const makePayment: TActionButton =
    isAllowedPayment && data?.final_status?.key === "approved" && data?.is_able_to_settle && !isPersonalTab && false // Hiding make payment button for all cases in first release
      ? {
          title: "Make Payment",
          variant: "tertiary",
          action: handleMakePayment,
          disabled: loading,
        }
      : null;

  return {
    data,
    showSkeleton,
    showErrorState,
    setFormValue,
    panelProps: {
      actionButton,
      onClose: () => (history.action === "PUSH" ? history.goBack() : handleOnClose()),
      primaryActionButton: markAsSettled,
      secondaryActionButton: saveChanges,
      tertiaryActionButton: isPersonalTab ? deleteRequest : makePayment,
    },
  };
};

export default useDetailState;
