import * as React from "react";
import classNames from "classnames";
import { useState } from "react";
import { useCategoryLocks } from "customHooks/Card";

import CategoryLockItem from "Views/Card/Components/SpendingLock/Category/Item";
import Search from "Modules/DS/Search";
import SpendingLockFooter from "Views/Card/Components/SpendingLock/Footer";
import SpendingLockCategoryShimmer from "Views/Card/Components/SpendingLock/Category/Shimmer";

import styles from "./index.module.scss";
import commonStyles from "Views/Card/Components/SpendingLock/index.module.scss";

export interface ICategoryItem {
  id: string;
  name: string;
}

interface IHandleCategoryLockSelectionProps {
  checked: boolean;
  categoryItem: ICategoryItem;
}

interface ICategorySpendingLock {
  initialSpendingLocks: ICategoryItem[];
  onChange: (selectedMerchantLock: ICategoryItem[]) => void;
  totalShimmerToRender: number;
}

const CategoryLock = ({ onChange, initialSpendingLocks, totalShimmerToRender }: ICategorySpendingLock) => {
  const { data, loading, onSearch } = useCategoryLocks();
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [selectedCategoryLocks, setSelectedCategoryLocks] = useState<ICategoryItem[]>(initialSpendingLocks);

  const handleCategoryLockSelection = ({ checked, categoryItem }: IHandleCategoryLockSelectionProps) => {
    let categoryItems: ICategoryItem[] = [...(selectedCategoryLocks || [])];
    if (checked) {
      categoryItems = categoryItems?.concat(categoryItem);
    } else {
      categoryItems = categoryItems?.filter((selectedCategoryLock) => selectedCategoryLock?.id !== categoryItem?.id);
    }
    setSelectedCategoryLocks(categoryItems);
    onChange(categoryItems);
  };

  const isCategorySelected = (id) => selectedCategoryLocks?.some((category) => category?.id === id);

  const getCategoryItems = (categoriesList) => {
    if (Array.isArray(categoriesList) && categoriesList?.length > 0) {
      return (
        <ul>
          {categoriesList?.map((category) => (
            <li key={category?.id}>
              <CategoryLockItem
                checked={isCategorySelected(category?.id)}
                description={category?.description}
                iconImageAlt={`${category?.name} icon`}
                name={category?.name}
                icon={category?.merchant_category_icon}
                onClick={(checked) => {
                  handleCategoryLockSelection({
                    checked,
                    categoryItem: { id: category?.id, name: category?.name },
                  });
                }}
                isRestricted={category?.is_restricted}
              />
            </li>
          ))}
        </ul>
      );
    } else if (categoriesList?.length === 0) {
      const hasRecommendedCategories = searchQuery && data?.recommended_categories?.length > 0;
      return (
        <div className={styles.infoContainer}>
          <p className={`${styles.externalText} ${styles.infoText}`}>{`We could not find any category with that name. ${
            hasRecommendedCategories ? "" : "Try searching for another category or merchant."
          }`}</p>
        </div>
      );
    } else {
      return null;
    }
  };

  const getRecommendedCategories = () => {
    const { recommended_categories = [] } = data || {};
    if (Array.isArray(recommended_categories) && recommended_categories?.length > 0) {
      return (
        <>
          <p className={styles.categoryLockContentTitle}>Recommended categories</p>
          {getCategoryItems(recommended_categories)}
        </>
      );
    }
    return null;
  };

  const showSpendingLockFooter =
    selectedCategoryLocks?.length > 0 &&
    (data?.matched_categories?.length > 0 || data?.recommended_categories?.length > 0);

  const contentClassName = classNames(styles.categoryLockContent, {
    [commonStyles.marginBottomXL]: showSpendingLockFooter,
  });

  return (
    <section className={commonStyles.section} data-testid="category-lock">
      <Search.Popover
        onSearch={(searchString) => {
          setSearchQuery(searchString);
          onSearch(searchString);
        }}
        placeholder="Search for categories"
        value={searchQuery}
        classname={commonStyles.searchBar}
      />
      {loading ? (
        <SpendingLockCategoryShimmer totalShimmerToRender={totalShimmerToRender} />
      ) : (
        <>
          <div className={contentClassName}>
            {getCategoryItems(data?.matched_categories)}
            {getRecommendedCategories()}
          </div>
          {showSpendingLockFooter && (
            <SpendingLockFooter
              message={`${selectedCategoryLocks?.length || 0} Categories Selected`}
              onClear={() => {
                setSelectedCategoryLocks([]);
                onChange([]);
              }}
            />
          )}
        </>
      )}
    </section>
  );
};

export default CategoryLock;
