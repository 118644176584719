import React from "react";
import Section from "Modules/TransactionDetail/Section";
import Banner from "Modules/DS/Banner";
import { ITransactionHeaderProps } from "../index";

import styles from "./styles.module.scss";
import BillImportedFromXeroBanner from "Modules/BillImportedFromXeroBanner";

const TransactionHeader: React.FC<ITransactionHeaderProps> = ({
  amount,
  name,
  caption,
  info,
  secondaryName,
  subTitle = "Recipient",
  xeroBillBannerData,
}) => {
  return (
    <Section>
      <div className={styles.container}>
        <p className={styles.subTitle}>{subTitle}</p>
        <p className={styles.name}>{name}</p>
        {secondaryName && <p className={styles.secondaryName}>{secondaryName}</p>}
        <h3 className={styles.amount}>{amount}</h3>
        {caption && <p className={styles.caption}>{caption}</p>}
        {info && (
          <div className={styles.infoPanel}>
            <Banner type={info.type} message={<p className={styles.banner}>{info.content}</p>} />
          </div>
        )}
        {xeroBillBannerData ? <BillImportedFromXeroBanner xeroBillBannerData={xeroBillBannerData} lessSpacing /> : null}
      </div>
    </Section>
  );
};

export default TransactionHeader;
