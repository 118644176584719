import React, { useEffect } from "react";
import { Form } from "antd";
import { useHistory } from "react-router-dom";
import { usePersonnelInfoFormSGD } from "./PersonnelInfo";
import { DocumentStep } from "../../Step/Document";

import { KYXBanner } from "Views/KYX/Elements/Banner";
import { KYX_BANNER_MESSAGE_TYPE } from "Views/KYX/Constants";
import { Footer } from "Views/KYX/Progress/JointApplicant/Form/Footer";
import { IMyInfoCallBackReducer } from "Redux/Actions/KYX/types";
import { usePostMyInfoSubmit } from "Views/KYX/hooks/MyInfo";
import { COMPANY_VERIFICATION } from "Route/KYX/paths";
import { useAppDispatch, useAppSelector } from "hook";
import { getCountryList } from "Redux/Actions/Common/getCountryList";
import { RootState } from "Redux/ConfigureStore";

import styles from "./index.module.scss";

const PersonnnelInfoForm: React.FC = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [form] = Form.useForm();
  const isFormDisabled = true;

  const { loading, myInfoSubmit } = usePostMyInfoSubmit();

  const myInfoCallBackData: IMyInfoCallBackReducer = useAppSelector((state: RootState) => state.myInfoCallBackReducer);
  const formData = myInfoCallBackData?.data?.payload?.person;
  const companyName = myInfoCallBackData?.data?.payload?.company_name;

  const onFinish = (values) => {
    const payload = {
      auth_token: myInfoCallBackData?.data?.payload?.auth_token,
      country_of_residence: values?.person?.country_of_residence,
      email_address: values?.person?.email_address,
      mobile_number: values?.person?.mobile_number,
    };

    myInfoSubmit(payload).then(() => {
      history.push(COMPANY_VERIFICATION.THANKYOU_PAGE);
    });
  };

  useEffect(() => {
    const IS_PUBLIC = true;
    dispatch(getCountryList(IS_PUBLIC));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!formData) {
      history.push(COMPANY_VERIFICATION.SESSION_EXPIRED);
    }
  }, [formData, history]);

  return (
    <Form form={form} name="persons" onFinish={onFinish} className={styles.form} initialValues={{ ...formData }}>
      <div className={styles.container}>
        <KYXBanner
          bannerType="attention"
          messageType={KYX_BANNER_MESSAGE_TYPE.TITLE}
          title="Verify Data as a Joint Applicant"
          //  defining company name is WIP
          message={`Please verify your information below as a shareholder for ${companyName}. Make sure all of your information are correct to continue your company verification process.`}
        />

        {usePersonnelInfoFormSGD(formData, isFormDisabled).map(({ key, ...props }) => {
          return <DocumentStep key={key} {...props} />;
        })}
      </div>

      <Footer loading={loading} />
    </Form>
  );
};

export default PersonnnelInfoForm;
