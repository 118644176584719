import { getCookieValue, cookieNames } from "utility/CookieHelper";

export const isForceResetNeeded = () => {
  return getCookieValue(cookieNames.IS_FORCE_RESET_NEEDED) && getCookieValue(cookieNames.FORCE_RESET_TOKEN);
};

export const forceResetToken = () => {
  return getCookieValue(cookieNames.FORCE_RESET_TOKEN);
};

export const forceResetSuccess = () => {
  return getCookieValue(cookieNames.FORCE_RESET_SUCCESS);
};
export const forceResetError = () => {
  return getCookieValue(cookieNames.FORCE_RESET_ERROR);
};
