import { Button } from "antd";
import "./index.scss";
import React, { useState } from "react";
import { greyCloseIcon } from "assets/img/index";
import Icon from "Modules/icons";
import LoadingIcon from "Modules/loading";
import ReimbursementApprovalRequest from "Models/Approval/ApprovalCenter/reimbursementApprovalRequest.interface";
import { ACTION_TYPE_PAY } from "Views/Approval/ApprovalCenter/types";
import { trackEvent } from "utility/analytics";
import { useAppSelector } from "../../../../../hook";
import { isCRCurrencyRuleInvalid } from "Views/Approval/ApprovalCenter/helper";

export enum APPROVAL_ACTIONS {
  PAY_IMMEDIATELY = "Pay",
}

interface PaymentActionsProps {
  record: ReimbursementApprovalRequest;
  processType: string;
  onPayApproveImmediately: (object: any) => Promise<object>;
  trackEventName: string;
}

const PaymentActions = ({ record, processType, onPayApproveImmediately, trackEventName }: PaymentActionsProps) => {
  const [confirmAction, setConfirmAction] = useState(null);
  const [isSubmit, setIsSubmit] = useState(false);
  const user = useAppSelector((state) => state.user);
  const currencyRuleInvalid = isCRCurrencyRuleInvalid(record);

  const handlePayImmediately = async (actionType) => {
    setIsSubmit(true);
    const response = await onPayApproveImmediately({
      id: record.processID,
      type: processType,
      actionType: actionType,
    });
    if (response) {
      setIsSubmit(false);
      setConfirmAction(null);
    }
  };

  if (confirmAction) {
    return (
      <div className="payment-actions">
        <Button
          className={`payment-actions__button--${confirmAction} payment-actions__button--confirm`}
          onClick={(e) => {
            e.stopPropagation();
            handlePayImmediately(ACTION_TYPE_PAY.SETTLE);
            trackEvent(trackEventName, {
              approval_cr_event_action: "Confirm Pay",
              approval_cr_event_source: "Single in Waiting Payment",
              bill_id: [record.processID],
              user_role: user.role,
            });
          }}
          disabled={isSubmit}
        >
          {isSubmit && confirmAction ? <LoadingIcon size="s" /> : `Confirm to ${confirmAction}`}
        </Button>
        <Icon
          alt="close-confirm-button-icon"
          className="close-button"
          src={greyCloseIcon}
          size={"2"}
          action={(e) => {
            e.stopPropagation();
            setConfirmAction(null);
          }}
        />
      </div>
    );
  }

  return (
    <div className="payment-actions">
      <>
        {currencyRuleInvalid && (
          <div className="payment-actions__pay-not-applicable">{record.payImmediatelyValidationError}</div>
        )}
        {!currencyRuleInvalid && (
          <Button
            disabled={!(record.payImmediatelyEligible && record.payImmediatelyValidationError === "")}
            className="payment-actions__button--pay-immediately"
            onClick={(e) => {
              e.stopPropagation();
              setConfirmAction(APPROVAL_ACTIONS.PAY_IMMEDIATELY);
              trackEvent(trackEventName, {
                approval_cr_event_action: "Pay Immediately",
                approval_cr_event_source: "Single in Waiting Payment",
                bill_id: [record.processID],
                user_role: user.role,
              });
            }}
          >
            Pay Immediately
          </Button>
        )}
      </>
    </div>
  );
};

export default PaymentActions;
