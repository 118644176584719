import React from "react";

import Menu, { IItemID } from "Modules/DS/Menu";
import { IListContainerProps, ISectionContainer, ISectionList } from "Modules/DS/Filter/@types";

import { dummyData } from "./mock";
import { SectionContainer } from "./Section";

export const ListContainer = <T extends object = any>({
  height,
  children,
  lineProps,
  loading,
  idKey = "id" as keyof T,
  showEmptyState = true,
}: IListContainerProps<T>): JSX.Element => {
  let data: T[] = [] as T[];
  const indexKey = loading ? "id" : (idKey as string);

  const renderListItem = (item: T, index: number) => {
    return <Menu.Line key={item[indexKey] + index} {...lineProps(item)} />;
  };

  if (loading) data = [...dummyData("LIST")] as T[];
  else if (children.length) data = [...children];

  const renderEmptyState = () => (showEmptyState ? <Menu.EmptyState /> : <></>);

  return Array.isArray(data) && data.length ? (
    <Menu.List height={height}>{data.map(renderListItem)}</Menu.List>
  ) : (
    renderEmptyState()
  );
};

export const SectionList = <T extends IItemID, K extends object = any>({
  children,
  height,
  lineProps,
  loading,
  listKey,
  titleKey,
  subArryIdKey,
}: ISectionList<T, K>) => {
  let data: T[] = [];

  const props: Omit<ISectionContainer<T, K>, "children"> = {
    loading,
    lineProps,
    listKey,
    titleKey,
    subArryIdKey,
  };

  const renderListItem = (item: T) => {
    return (
      <SectionContainer key={item.id} {...props}>
        {item}
      </SectionContainer>
    );
  };

  if (loading) data = [dummyData("SECTIONED-LIST")[0]] as T[];
  else if (children.length > 0) data = [...children];

  return data.length ? <Menu.List height={height}>{data.map(renderListItem)}</Menu.List> : <Menu.EmptyState />;
};
