import React, { useContext, useEffect, useState } from "react";
import DynamicFormDropdown from "../../Dropdown";
import styles from "./WithholdingTaxCategory.module.scss";
import { DynamicFormContext } from "Modules/DynamicForm/store";

const WithholdingTaxCategory = (props) => {
  const { form } = useContext(DynamicFormContext);
  const { id } = props;
  const [amount, setAmount] = useState<number>();
  const handleOnChange = (fieldID, value) => {
    handleUpdateValue(value);
    props.onChangeValue(fieldID, value);
  };

  const handleUpdateValue = (value) => {
    const selectedOption = props?.options?.find((item) => item?.id === value) || {};
    form.setFieldsValue({ whtRate: selectedOption.additionalCode || "0" });
    setAmount(selectedOption.additionalCode);

    // To pass the label & tax amount information to BSS form for WHT modal
    form.setFieldsValue({ whtTaxCategory: { label: selectedOption.label, amount: selectedOption.additionalCode } });
  };

  useEffect(() => {
    handleUpdateValue(form.getFieldValue(["dynamicFields", id]));
  }, [form.getFieldValue(["dynamicFields", id])]);

  return (
    <>
      <DynamicFormDropdown
        {...props}
        options={props.options.map((item) => ({ value: item.value, label: item.label }))}
        onChangeValue={handleOnChange}
      />
      {Boolean(amount) && (
        <div className={styles.info}>
          <div className={styles.title}>Withholding Tax Rate</div>
          <div className={styles.amount}>{amount}%</div>
          <div className={styles.desc}>Withholding tax rate is automatically selected according to your ATC code</div>
        </div>
      )}
    </>
  );
};

export default WithholdingTaxCategory;
