import React from "react";
import Button from "Modules/button";
import { ISidePanelButton } from "../../type.d";
import classNames from "classnames";

import styles from "./styles.module.scss";

export const SecondaryButton = ({
  action,
  text,
  disabled,
  loading,
  shouldUseColSpan,
  buttonClassName,
  additionalInfo,
}: ISidePanelButton) => {
  return (
    <>
      <Button
        size="l"
        rounded
        secondary
        disabled={disabled || loading}
        className={classNames({
          [styles["col-span-2"]]: shouldUseColSpan,
          [buttonClassName]: Boolean(buttonClassName),
        })}
        action={action}
      >
        {text}
      </Button>
      {additionalInfo?.content && (
        <div className={classNames(styles["confirm-approve"], { [styles["col-span-2"]]: shouldUseColSpan })}>
          {additionalInfo?.content}
        </div>
      )}
    </>
  );
};
