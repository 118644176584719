/* spenmo logos  */
const spenmoOrangeLogoLarge = require("assets/v1.1/logo/spenmo/orange_large.svg");
const spenmoWhiteLogoLarge = require("assets/v1.1/logo/spenmo/white_large.svg");
const spenmoOrangeLogoSmall = require("assets/v1.1/logo/spenmo/orange_small.svg");
const spenmoWhiteLogoSmall = require("assets/v1.1/logo/spenmo/white_small.svg");
const CS_spenmo_logo = require("assets/v1.1/logo/spenmo/CS_logo_spenmo_2x.png");

/* spenmo logos with SPENMO text */
const spenmoOrangeLogoLargeWithText = require("assets/v1.1/logo/spenmo/orange_large_with_text.svg");

export {
  spenmoOrangeLogoLarge,
  spenmoWhiteLogoLarge,
  spenmoOrangeLogoSmall,
  spenmoWhiteLogoSmall,
  spenmoOrangeLogoLargeWithText,
  CS_spenmo_logo,
};
