export enum ISubscriptionManagementFilterKeys {
  VENDOR = "vendor",
  BUDGET = "budget",
  STATUS = "status",
  CONTRACTS_EXPIRING_SOON = "contractsExpiringSoon",
  CONTRACTS_RENEWING_SOON = "contractsRenewingSoon",
  OVER_SPEND = "isOverSpend",
  SORT_BY = "sortBy",
}

export interface ISubscriptionManagementFilters {
  [ISubscriptionManagementFilterKeys.VENDOR]?: string[];
  [ISubscriptionManagementFilterKeys.BUDGET]?: string[];
  [ISubscriptionManagementFilterKeys.STATUS]?: string[];
  [ISubscriptionManagementFilterKeys.CONTRACTS_EXPIRING_SOON]?: boolean;
  [ISubscriptionManagementFilterKeys.CONTRACTS_RENEWING_SOON]?: boolean;
  [ISubscriptionManagementFilterKeys.SORT_BY]?: string;
}

export enum SORT_BY_VALUES {
  CREATED_DATE = "createdDate",
  ESTIMATED_COST = "estimatedCost",
  EXPIRY_DATE = "expiryDate",
}
