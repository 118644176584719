import React from "react";
import PropTypes from "prop-types";
import RecursiveTable from "../../Modules/recursiveTable";

import ReduxHoc from "../../Redux/ReduxHOC";
import { accountNumMask } from "../../utility";
import LoadingComponent from "../State/Loading/LoaderIcon";
import ErrorComponent from "../State/Error";
import BankDetails from "./BankDetails";
import "./BankListing.scss";
import { Base64 } from "js-base64";
import { trackEvent } from "utility/analytics";

import { fetchPermissionForClassComp } from "Permission/PermissionForClassComp";
import { CASBIN_PAGES } from "Permission/Pages";
import { BANK_ACCOUNT_PAGE } from "Permission/Actions";
import { BodySkeleton } from "Views/Settings/components";

class BankListing extends React.Component {
  static propTypes = {
    callApiWithLoader: PropTypes.func,
    callApiWithoutLoader: PropTypes.func,
    data: PropTypes.object,
    addBank: PropTypes.func,
    loading: PropTypes.bool,
    error: PropTypes.bool,
  };

  componentDidMount() {
    this.props.callApiWithLoader("getAllBanks");
    const permissionParam = [
      { object: CASBIN_PAGES.BANK_ACCOUNT_PAGE, action: BANK_ACCOUNT_PAGE.ADD_ACCOUNT_BUTTON },
      { object: CASBIN_PAGES.BANK_ACCOUNT_PAGE, action: BANK_ACCOUNT_PAGE.DELETE_ACCOUNT_BUTTON },
    ];
    fetchPermissionForClassComp(permissionParam).then((res) => {
      const [isAllowedAddBankAccount, isAllowedDeleteAccount] = res;
      this.setState({ isAllowedAddBankAccount, isAllowedDeleteAccount });
    });
  }

  state = {
    showSideSection: false,
    details: {},
    isAllowedAddBankAccount: false,
    isAllowedDeleteAccount: false,
  };

  updateList() {
    this.props.callApiWithLoader("getAllBanks");
  }

  onRowSelection = (record) => {
    this.setState({ showSideSection: true, details: record });
    trackEvent("bank accounts account selected");
  };

  render() {
    const { data, loading, error, addBank } = this.props;
    const { isAllowedAddBankAccount, isAllowedDeleteAccount } = this.state;

    if (loading) {
      return this.props.newSettingPage ? <BodySkeleton paddingLess /> : <LoadingComponent />;
    }
    if (error) {
      return <ErrorComponent />;
    }

    const dataSource = ((data && data.payload) || { bank_accounts: [] }).bank_accounts;

    return (
      <div className={"bank-listing"}>
        <RecursiveTable
          title={"Bank Accounts"}
          buttonTitle={!dataSource.length && isAllowedAddBankAccount ? "+ Add Account" : null}
          dataSource={dataSource}
          action={addBank}
          pagination={false}
          columnData={[
            {
              title: "Bank Name",
              dataIndex: "bank_name",
              key: "bank_name",
            },
            {
              title: "Account Name",
              dataIndex: "account_name",
              key: "account_name",
            },
            {
              title: "Account/Mobile #",
              dataIndex: "account_number",
              key: "account_number",
              render: (num, record) =>
                record.type === "paynow" ? record.mobile_number : accountNumMask(Base64.decode(record.account_number)),
            },
          ]}
          rowAction={this.onRowSelection}
          showSideSection={this.state.showSideSection}
        >
          <BankDetails
            isAllowedDeleteAccount={isAllowedDeleteAccount}
            bankData={this.state.details}
            handleUpdate={() => {
              this.setState({ showSideSection: false });
              this.updateList();
            }}
          />
        </RecursiveTable>
      </div>
    );
  }
}

export default ReduxHoc(BankListing, "getAllBanks");
