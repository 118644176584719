import React from "react";
import ActionButtons from "Modules/ActionButtons";
import { stopScheduledTransactionsFunc } from "Redux/Actions";
import { useDispatch } from "react-redux";

const ScheduledActionButtons = ({ record, isAllowedEditBtn, isAllowedStopBtn, setScheduledEditValue }) => {
  const dispatch = useDispatch();
  return (
    <>
      <ActionButtons
        buttons={[
          ...(isAllowedEditBtn
            ? [
                {
                  label: "Edit",
                  action: () => {
                    setScheduledEditValue(record);
                  },
                  type: "plain",
                  useConfirm: false,
                },
              ]
            : []),
          ...(isAllowedStopBtn
            ? [
                {
                  label: "Stop",
                  action: () => {
                    dispatch(stopScheduledTransactionsFunc({ schedulerIds: [record.id] }));
                  },
                  type: "plain",
                  useConfirm: true,
                  className: "action-buttons__button--stop",
                },
              ]
            : []),
        ]}
      />
    </>
  );
};

export default ScheduledActionButtons;
