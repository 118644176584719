import * as React from "react";
import { Select as AntdSelect } from "antd";
import { SelectProps, SelectValue } from "antd/lib/select";
import { downArrowIcon24x24 } from "assets/icons/card";
import "Views/Card/Components/Select/index.scss";

export const Select = <T extends SelectValue = SelectValue>({ children, ...props }: SelectProps<T>) => {
  return (
    <AntdSelect<T>
      suffixIcon={<img src={downArrowIcon24x24} alt="down arrow" />}
      className="cards-select-box"
      {...props}
    >
      {children}
    </AntdSelect>
  );
};

export default Select;
