import React, { useMemo } from "react";
import { Controller, ControllerProps } from "react-hook-form";

import { transformRules } from "./helper";

/**
 * FormController is used to wrapped the default Controller
 * from the react-hook-form library. It will transform customize props
 * like rules, etc. according to what the Controller from the react-hook-form accept
 * @param props ControllerProps
 * @returns Controller from React hook form
 */
const FormController = (props: ControllerProps) => {
  const { rules, ...rest } = props;

  const transformedRules = useMemo(() => transformRules(rules), [rules]);

  return <Controller {...rest} rules={transformedRules} />;
};

export default FormController;
