import React from "react";

import { ISubscriptionManagementFilterKeys } from "Views/SubscriptionManagement/@types/filters";

import { SelectProvider } from "Modules/DS/Select";
import { SingleFilter } from "Views/SubscriptionManagement/Filters/SingleFilter";
import { StatusFilter } from "Views/SubscriptionManagement/Filters/Status";
import { BudgetFilter } from "Views/SubscriptionManagement/Filters/Budget";
import { VendorFilter } from "Views/SubscriptionManagement/Filters/Vendors";
import { SortByFilter } from "Views/SubscriptionManagement/Filters/SortBy";

import styles from "./index.module.scss";

export const SubscriptionManagementFilters = () => {
  return (
    <div className={styles.filterContainer}>
      <SelectProvider>
        <div className={styles.quickFilterContainer}>
          <VendorFilter />
          <BudgetFilter />
          <StatusFilter />
          <SingleFilter
            label="Contracts auto-renewing soon"
            id={ISubscriptionManagementFilterKeys.CONTRACTS_RENEWING_SOON}
          />
          <SingleFilter
            label="Contracts expiring soon"
            id={ISubscriptionManagementFilterKeys.CONTRACTS_EXPIRING_SOON}
          />
          <SingleFilter label="Overspend" id={ISubscriptionManagementFilterKeys.OVER_SPEND} />
        </div>
        <SortByFilter />
      </SelectProvider>
    </div>
  );
};
