import React from "react";
import { notification } from "antd";
import cn from "classnames";

import Icon from "../Icon";

import "./app-notification.scss";

export const setNotificationConfig = () => {
  notification.config({
    placement: "bottomLeft",
    duration: 5,
    closeIcon: (
      <Icon className="notification-close-icon" icon="close" size={24} data-testid="notification-close-icon" />
    ),
  });
};

const content = (cta, onClickCTA, message) => (
  <div className="notification-content">
    {cta && typeof onClickCTA === "function" ? (
      <>
        <div className="notification-text">{message}</div>
        <button className="notification-cta" onClick={onClickCTA}>
          {cta}
        </button>
      </>
    ) : (
      message
    )}
  </div>
);

export const generalErrorMessage = (message, errorId) => ({
  message: `${message}\nError ID: ${errorId}`,
  cta: "Email Support",
  onClickCTA: () => {
    const link = document.createElement("a");
    link.href = "mailto:support@spenmo.com";
    link.click();
    link.remove();
  },
});

export const contactCSErrorMessage = (errorId = "") => ({
  message: `An error has occurred. Please contact support@spenmo.com for further assistance.${
    errorId ? " Error ID: " + errorId : ""
  }`,
  cta: "Email Support",
  onClickCTA: () => {
    const link = document.createElement("a");
    link.href = "mailto:support@spenmo.com";
    link.click();
    link.remove();
  },
});

export const appNotification = {
  success: (config) => {
    const { cta, onClickCTA, message, ...rest } = config;

    return notification.success({
      icon: <Icon className="notification-icon" icon="fillCheck" size={24} />,
      ...rest,
      message: content(cta, onClickCTA, message),
      className: cn("app-notification", "app-notification-success", config?.className),
    });
  },
  error: (config) => {
    const { cta, onClickCTA, message, duration = 0, ...rest } = config;

    return notification.error({
      icon: (
        <Icon
          className="notification-icon"
          icon="fillWarning"
          size={24}
          svgAttr={{ width: 24, height: 24, viewBox: "0 0 24 16" }}
        />
      ),
      ...rest,
      message: content(cta, onClickCTA, message),
      className: cn("app-notification", "app-notification-error", config?.className),
      duration,
    });
  },
};
