import * as React from "react";
import { Skeleton } from "antd";

import styles from "./index.module.scss";

interface ISpendingLockMerchantShimmer {
  totalShimmerToRender: number;
}

const SpendingLockMerchantShimmer = ({ totalShimmerToRender }: ISpendingLockMerchantShimmer) => {
  return (
    <div className={styles.shimmer}>
      {Array.from({ length: totalShimmerToRender }, (_, index) => index + 1)?.map((value) => (
        <div data-testid="shimmer-container" className={styles.shimmerContainer} key={value}>
          <Skeleton.Avatar className={styles.shimmerLogo} active shape="circle" />
          <div className={styles.shimmerTitleContainer}>
            <Skeleton.Input className={styles.shimmerTitle} active />
          </div>
        </div>
      ))}
    </div>
  );
};

export default SpendingLockMerchantShimmer;
