import React, { useEffect, useState } from "react";
import { Select } from "antd";
import PropTypes from "prop-types";
import Search from "Modules/search";
import Icon from "Modules/icons";
import { downArrowBlack, downArrowGrey } from "assets/img/index";
import "./phoneCodesDropdown.scss";

const PhoneCodesDropdown = ({
  phoneCodes = [],
  currentValue,
  action,
  placeholder = "Select Phone Code",
  disabled = false,
}) => {
  const { Option } = Select;
  const [searchValue, setSearchValue] = useState("");
  const [filteredList, setFilteredList] = useState(phoneCodes);

  useEffect(() => {
    if (searchValue !== "") {
      const searchedData = phoneCodes.filter(
        (item) =>
          item?.country?.toLowerCase().includes(searchValue?.toLowerCase()) ||
          ("+" + item?.phoneCode).includes(searchValue)
      );
      setFilteredList(searchedData);
    } else {
      setFilteredList(phoneCodes);
    }
  }, [phoneCodes, searchValue]);

  const handlePhoneCodeOption = (phoneCodes) => {
    return (
      Array.isArray(phoneCodes) &&
      phoneCodes?.map((code, index) => {
        return (
          <Option key={index} value={code.phoneCode} label={`(+${code.phoneCode})`}>
            <p className={"phone-code-option"}>
              {code.country} <span>+{code.phoneCode}</span>
            </p>
          </Option>
        );
      })
    );
  };

  const handleDropDownRender = (menu) => {
    return (
      <div className="phone-code-dropdown__overlay-container">
        <Search getValue={setSearchValue} />
        {menu}
      </div>
    );
  };

  return (
    <Select
      className="phone-code-dropdown"
      value={currentValue}
      disabled={disabled}
      placeholder={placeholder}
      placement="bottomLeft"
      suffixIcon={
        <Icon
          className={"phone-code-dropdown__suffix-icon"}
          src={disabled ? downArrowGrey : downArrowBlack}
          alt={"arrow icon"}
        />
      }
      getPopupContainer={(triggerNode) => triggerNode.parentElement}
      dropdownClassName="phone-code-dropdown__overlay"
      dropdownStyle={{ minWidth: "284px" }}
      dropdownRender={handleDropDownRender}
      style={{ width: "auto", margin: "0 8px 0 0" }}
      onChange={action}
      optionLabelProp={"label"}
    >
      {handlePhoneCodeOption(filteredList)}
    </Select>
  );
};

PhoneCodesDropdown.propTypes = {
  phoneCodes: PropTypes.array,
  action: PropTypes.func,
  currentValue: PropTypes.number,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
};

export default PhoneCodesDropdown;
