import React from "react";
import { ChevronDown, ChevronUp, Typography } from "@spenmo/splice";

import { currencyFormatterV2 } from "utility";
import { DefaultFieldType, FieldsType, PreviewField } from "Views/Bills/V2/components/Preview/type";
import { BillPreviewDataResponse } from "Views/Bills/V2/BillForm/type";

import styles from "./BillPreview.module.scss";

const renderField = (item) => {
  return (
    <div key={item.labelName} className={styles.previewItem}>
      <div>{item.labelName}</div>
      {item.isEdited ? (
        <div>
          <span className={styles.newValue}>{item.newValue}</span>
          <span className={styles.oldValue}>{item.oldValue}</span>
        </div>
      ) : (
        <div className={styles.recipientContainer}>
          <div>{item.newValue}</div>
        </div>
      )}
    </div>
  );
};

// IMPROVEMENT: should not have a component inside a helper function
export const generatePreviewFields = (
  previewDataResponse: BillPreviewDataResponse,
  isExpand: boolean,
  setIsExpand: Function
) => {
  const { billDetail, paymentDetail, sections } = previewDataResponse || {
    billDetail: null,
    paymentDetail: null,
    sections: [
      {
        key: "billDetails",
        label: "Bill Details",
        fields: [],
      },
      {
        key: "paymentDetails",
        label: "Payment Details",
        fields: [],
      },
    ],
  };

  const currencyData = {
    recipientCurrency: paymentDetail?.currency,
    youPayCurrency: paymentDetail?.walletCurrency,
    invoiceCurrency: billDetail?.invoiceCurrency,
  };

  // Note: remove recipient fields from bill data response
  const billFieldsData: PreviewField[] =
    sections?.map((sectionItem) => ({
      ...sectionItem,
      fields: sectionItem.fields,
    })) || [];

  const formatValue = (value: string, key: string, currency: string | null = null) => {
    switch (key) {
      case "recipientGets":
        return currencyFormatterV2(value, currencyData?.recipientCurrency);
      case "youPay":
        return currencyFormatterV2(value, currencyData?.youPayCurrency);
      case "invoiceAmount":
        return currencyFormatterV2(value, currency ? currency : currencyData?.invoiceCurrency);
      default:
        return value;
    }
  };

  const section: PreviewField[] = billFieldsData.map((section) => {
    const { key } = section || {};

    let fields: FieldsType[] = section?.fields?.map((item: DefaultFieldType) => {
      const { key, oldValue, newValue, isEdited, hide, subFields } = item;
      if ((!newValue && !oldValue) || hide) return null;

      if (Array.isArray(subFields)) {
        return {
          ...item,
          children: (
            <>
              {isExpand && (
                <div className={styles.recipientBox}>{item.subFields.map((field) => renderField(field))}</div>
              )}{" "}
              <div className={styles.iconContainer}>
                <Typography
                  className={styles.breakdownAction}
                  variant="body-content"
                  size="caption-m"
                  tag="p"
                  onClick={() => setIsExpand((prev) => !prev)}
                  tabIndex={0}
                >
                  {isExpand ? "Hide details" : "See details"}
                  {isExpand ? (
                    <ChevronUp iconColor="var(--icon-subtle)" size="16" />
                  ) : (
                    <ChevronDown iconColor="var(--icon-subtle)" size="16" />
                  )}
                </Typography>
              </div>
            </>
          ),
        };
      }
// Todo: To revisit the approach to handle following by getting rid of invoice currency as hidden field. to be discussed with FE and BE
      if (key === "invoiceAmount" && !isEdited) {
        const invoiceCurrency = section?.fields.find((item: DefaultFieldType) => item.key === "invoiceCurrency");
        const {
          newValue: newCurrency,
          oldValue: oldCurrency,
          isEdited: currencyEdited,
        } = invoiceCurrency as DefaultFieldType;
        if (currencyEdited) {
          return {
            type: "default",
            ...item,
            isEdited: true,
            newValue: formatValue(newValue, key, newCurrency),
            oldValue: formatValue(oldValue, key, oldCurrency),
          };
        }
      }

      return {
        type: "default",
        ...item,
        newValue: formatValue(newValue, key),
        oldValue: formatValue(oldValue, key),
      };
    });

    // section key
    if (key === "paymentDetails" && paymentDetail?.isFXRateChanged) {
      fields = [
        {
          type: "banner",
          description: "The amount/currency has been updated with the current exchange rate applied.",
          variant: "warning",
        },
        ...fields,
      ];
    }

    return {
      ...section,
      fields: fields.filter((item) => item),
    };
  });

  return section;
};
