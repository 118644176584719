import * as React from "react";
import classNames from "classnames";

import { IModalTitleProps } from "Modules/DS/Modal/types";

import styles from "./Title.module.scss";

export const Title = ({ titleType, children }: IModalTitleProps) => {
  const className = titleType ? styles[titleType] : "";

  return <h4 className={classNames(styles.container, className)}>{children}</h4>;
};
