import React, { useRef, useState } from "react";
import qs from "query-string";
import { Base64 } from "js-base64";
import { useHistory, useLocation } from "react-router-dom";

import { useAppSelector } from "hook";
import { RootState } from "Redux/ConfigureStore";

import { Quick } from "Modules/DS/Filter";
import { POSITION_ALGO_TYPE } from "Modules/DS/Popover";

import { ILocationQueryParams } from "Views/Reimbursement/@types";
import { IMemberData } from "Views/TransactionsListing/Filters/types";
import { REIMBURSEMENT_QUERY_PARAMS } from "Views/Reimbursement/Constants";

const RequesterFilter = () => {
  const loading: boolean = useAppSelector((state: RootState) => state.teamMembersByTeamIdReducer?.loading || false);
  const teamMembersData: any[] = useAppSelector((state: RootState) => state.teamMembersByTeamIdReducer?.data || []);

  const history = useHistory();
  const location = useLocation();

  const query: ILocationQueryParams = qs.parse(location.search, {
    parseNumbers: true,
    parseBooleans: true,
  });

  const { requesterIds = "" } = query;

  const [title, setTitle] = useState<string>();
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const presetValue = useRef<string[]>(Base64.decode(requesterIds).split(","));

  const handleOnAddSelected = (value: string[]) => {
    history.push({
      pathname: location.pathname,
      search: qs.stringify({
        ...query,
        [REIMBURSEMENT_QUERY_PARAMS.REQUESTER_IDS]: Base64.encodeURI(value),
        [REIMBURSEMENT_QUERY_PARAMS.PAGE]: 1,
      }),
    });
  };

  const handleClearFilter = () => {
    history.push({
      pathname: location.pathname,
      search: qs.stringify({
        ...query,
        [REIMBURSEMENT_QUERY_PARAMS.REQUESTER_IDS]: undefined,
        [REIMBURSEMENT_QUERY_PARAMS.PAGE]: 1,
      }),
    });
  };

  const handleSetSelectedIds = (value: React.SetStateAction<string[]>) => {
    presetValue.current = value as string[];
    setSelectedIds(value);
  };

  return (
    <Quick.Multiple<IMemberData>
      searchable
      showEmptyState
      filterItemProps={{
        id: "REIMBURSEMENTS-ACTION-REQUESTER-FILTER",
        positionAlgoType: POSITION_ALGO_TYPE.HORIZONTAL,
      }}
      defaultValue="Requester"
      displayKey="name"
      onAddSelected={handleOnAddSelected}
      clearFilter={handleClearFilter}
      states={{
        selectedIds,
        setSelectedIds: handleSetSelectedIds,
        setTitle,
        title,
        loading,
      }}
      presetValue={presetValue.current}
    >
      {teamMembersData}
    </Quick.Multiple>
  );
};

export default RequesterFilter;
