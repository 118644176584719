/*
  enums
*/

export enum CHECKBOX_CLASS_NAME {
  CONTAINER = "spm-checkbox",
  INNER = "spm-checkbox__inner",
}

/*
interfaces
*/

export interface ICheckboxProps {
  checked?: boolean;
  radio?: boolean;
  disabled?: boolean;
  id?: string;
  onClick?: React.MouseEventHandler<HTMLImageElement>;
  className?: string;
}
