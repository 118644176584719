import { Typography } from "antd";
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { Tooltip } from "antd";

import DynamicSLA from "Modules/DynamicSLA";
import { TOOLTIP, DESC } from "Modules/BillFee/const";
import { currencyFormatter } from "utility";
import { newInfo } from "assets/img";
import { trackEvent } from "utility/analytics";
import { BILL_EVENTS } from "Views/Bills/events";

import styles from "./InfoCard.module.scss";

const InfoCard = ({
  id,
  merchant,
  currency,
  amount,
  isShowConversion,
  walletCurrency,
  walletAmount,
  status,
  isFeeDirectlyDeducted,
  billAmountFeeInclusive,
  feeAmount,
}) => {
  const { Text } = Typography;
  const isSendingPayment = ["payout_issue", "completed"].includes(status);
  const isDomesticSG = walletCurrency === currency && walletCurrency === "SGD";

  const amountGroup = (amount, label) => (
    <>
      <Text className={classNames(styles.text, styles.normal)}>{label}</Text>
      <Text className={classNames(styles.text, styles.small)}>{currencyFormatter(amount, walletCurrency)}</Text>
    </>
  );

  const feeDescDefault = () => (
    <>
      <Text className={classNames(styles.text, styles.small)}>{DESC.default}</Text>
    </>
  );

  const feeDescDomesticSG = () => (
    <>
      <Text className={classNames(styles.text, styles.extrasmall)}>{DESC.SG}</Text>
    </>
  );

  useEffect(() => {
    trackEvent(BILL_EVENTS.detailViewed, { bill_id: id, is_sla_exceeded: isSendingPayment });
  }, []);

  const youPayAmount = isDomesticSG ? walletAmount : billAmountFeeInclusive;

  return (
    <div className={styles.container}>
      <Text className={classNames(styles.text, styles.normal)}>Recipient Name</Text>
      <Text className={classNames(styles.text, styles.big)}>{merchant}</Text>
      <Text className={classNames(styles.text, styles.normal)}>Recipient gets</Text>
      <Text className={classNames(styles.text, styles.big)}>{currencyFormatter(amount, currency)}</Text>
      {!isFeeDirectlyDeducted && isShowConversion && <>{amountGroup(youPayAmount, "Payment Amount")}</>}
      {isFeeDirectlyDeducted && (
        <>
          {amountGroup(youPayAmount, "You pay")}
          <div className={classNames(styles.feeContainer)}>
            <div className={classNames(styles.leftMarker)}>&nbsp;</div>
            <div className={classNames(styles.right)}>
              <Text className={classNames(styles.text, styles.normal)}>
                Payment Amount
                <Tooltip title={TOOLTIP.paymentAmount} placement="top">
                  <img src={newInfo} alt="tooltip info icon" />
                </Tooltip>
              </Text>
              <Text className={classNames(styles.text, styles.small)}>
                {currencyFormatter(walletAmount, walletCurrency)}
              </Text>
              <Text className={classNames(styles.text, styles.normal)}>
                Transfer Fee
                <Tooltip title={TOOLTIP.default} placement="right">
                  <img src={newInfo} alt="tooltip info icon" />
                </Tooltip>
              </Text>
              <Text className={classNames(styles.text, styles.small)}>
                {isDomesticSG ? "Free" : currencyFormatter(feeAmount, walletCurrency)}
              </Text>
              {isDomesticSG ? feeDescDomesticSG() : feeDescDefault()}
            </div>
          </div>
        </>
      )}

      <DynamicSLA
        tooltipMessage={`Estimated Arrival is calculated from the time payment status changes to "Sending Payment". The dates provided do not factor in any public holidays of the recipient country.`}
        billID={id}
        message={
          isSendingPayment && (
            <>
              Payment is taking longer than usual.
              <br />
              Please contact our{" "}
              <a
                onClick={() => {
                  trackEvent(BILL_EVENTS.slaClick, { bill_id: id });
                }}
                href="https://support.spenmo.com/hc/en-us/requests/new"
                target="_blank"
                rel="noopener noreferrer"
              >
                Customer Support
              </a>{" "}
              for more information.
            </>
          )
        }
        variant="vertical"
        labelClassName={styles["sla-label"]}
        valueClassName={styles["sla-value"]}
      />
    </div>
  );
};

InfoCard.propTypes = {
  id: PropTypes.string,
  merchant: PropTypes.string,
  currency: PropTypes.string,
  amount: PropTypes.string,
  isShowConversion: PropTypes.bool,
  walletCurrency: PropTypes.string,
  walletAmount: PropTypes.string,
  status: PropTypes.string,
  isFeeDirectlyDeducted: PropTypes.bool,
  billAmountFeeInclusive: PropTypes.string,
  feeAmount: PropTypes.string,
};

InfoCard.defaultProps = {
  merchant: "",
  currency: "",
  amount: "",
  isShowConversion: false,
  walletCurrency: "",
  walletAmount: "",
  status: "",
  isFeeDirectlyDeducted: false,
  billAmountFeeInclusive: "",
  feeAmount: "",
};

export default InfoCard;
