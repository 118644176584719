import React, { useContext } from "react";

import { useMutableData } from "API/useData";
import { useAppSelector } from "hook";
import { ModalComponent } from "Modules/DS/Modal";
import { TITLE_TYPE } from "Modules/DS/Modal/types";
import { Button, Typography } from "@spenmo/splice";
import { ITrxnModalContext } from "Views/Transaction/@types";
import { TrxnModalContext } from "Views/Transaction/Context";
import { TRXN_MODAL_TYPES } from "Views/Transaction/Constants";
import { useRemoveTransactionFromSubscription } from "Views/SubscriptionManagement/Details/Summary/Payment/RemoveSubscriptionModal";

import styles from "./index.module.scss";

const RemoveSubscriptionWarning = () => {
  const { resetModal: closeModal, showModal } = useContext<ITrxnModalContext>(TrxnModalContext);
  const { subscriptionId, transactionNumbers, onFinish } = showModal.payload;

  const { trigger, isMutating } = useRemoveTransactionFromSubscription({ onError: () => closeModal() });
  const { data } = useMutableData(`ms/spm-organisation/v1/subscriptions/${subscriptionId}`, {
    fallbackData: {},
  });
  const loggedInUserDetails = useAppSelector((state: any) => state?.userInfoReducer);
  const userName = loggedInUserDetails?.data?.payload?.user?.name || "";
  const subscription = data.data?.payload || {};

  return (
    <>
      <ModalComponent.CloseButton />
      <ModalComponent.Title titleType={TITLE_TYPE.largeWithBorder}>
        Remove Transaction from Subscription
      </ModalComponent.Title>
      <section className={styles.container}>
        <Typography size="m" variant="body-content" tag="p" className={styles.body}>
          Hey {userName}, confirm your decision to remove this transaction from the "{subscription.vendor?.name}"
          subscription. Once removed, this transaction will no longer be tied to the subscription and will not impact
          its analytics.
        </Typography>
        <section className={styles.footer}>
          <Button variant="secondary" size="l" onClick={closeModal}>
            Discard
          </Button>
          <Button
            variant="primary"
            size="l"
            loading={isMutating}
            onClick={() => {
              trigger({ subscription_id: subscriptionId, transaction_numbers: [transactionNumbers] });
              closeModal();
              onFinish();
            }}
          >
            Proceed
          </Button>
        </section>
      </section>
    </>
  );
};

export const useRemoveSubscriptionModal = (subscriptionId: string, transactionNumbers: string, onFinish) => {
  const { toggleModal, resetModal } = useContext<ITrxnModalContext>(TrxnModalContext);

  const openModal = () =>
    toggleModal(TRXN_MODAL_TYPES.REMOVE_SUBSCRIPTION_WARNING, { subscriptionId, transactionNumbers, onFinish });
  const closeModal = resetModal;

  return { openModal, closeModal };
};

export default RemoveSubscriptionWarning;
