export enum CREATE_STEPS {
  FIRST = "1",
  SECOND = "2",
}

export enum FORM_TYPE {
  CREATE = "create",
  EDIT = "edit",
}

export enum CONTRACT_TYPE {
  FIXED = "FIXED_TERM",
  ROLLING = "ROLLING",
}

export enum BILLING_FREQUENCY {
  MONTHLY = "MONTHLY",
  ANNUALLY = "ANNUALLY",
}

export enum ROLLING_FREQUENCY {
  MONTHLY = "MONTHLY",
  QUARTERLY = "QUARTERLY",
  ANNUALLY = "ANNUALLY",
}

export enum REVIEW_CONTRACT_DURATION {
  IN_1_WEEK = "1 week",
  IN_2_WEEKS = "2 weeks",
  IN_1_MONTH = "1 month",
  IN_2_MONTHS = "2 months",
  IN_3_MONTHS = "3 months",
}

export enum FORM_NAMES {
  VENDOR_ID = "vendor_id",
  VENDOR_NAME = "vendor_name",
  CURRENCY = "currency",
  ESTIMATED_AMOUNT = "estimated_amount",
  VARIATION_PERCENTAGE = "variation_percentage",
  BILLING_FREQUENCY = "billing_frequency",
  DESCRIPTION = "description",
  CONTRACT_TYPE = "contract_type",
  START_DATE = "start_date",
  NEXT_BILLING_DATE = "next_billing_date",
  END_DATE = "end_date",
  ROLLING_FREQUENCY = "rolling_frequency",
  REVIEW_CONTRACT_DURATION = "review_contract_duration",
  DOCUMENTS = "documents",
  NOTES = "note",
  BUDGET_ID = "budget_id",
  OWNER_ID = "owner_id",
  OWNER_NAME = "owner_name",
  CARD_ID = "card_id",
  PAYMENT_METHOD = "payment_method",
  PAYMENT_LIMIT = "payment_limit",
}

export const BILLING_FREQUENCY_LABELS = {
  [BILLING_FREQUENCY.MONTHLY]: "Monthly",
  [BILLING_FREQUENCY.ANNUALLY]: "Annually",
};

export enum PAYMENT_METHODS {
  NEW_CARD = "new_card",
  EXISTING_CARD = "existing_card",
  INVOICE = "invoice",
}
