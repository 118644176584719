import {
  FETCH_PENDING_ACTIONS_TYPES,
  initialPendingActionsState,
  PendingActionsData,
  PendingActionsReducer,
} from "Redux/Actions/PendingActions/types";

export const pendingActionsReducer = (
  state: PendingActionsReducer = initialPendingActionsState,
  action: { type: FETCH_PENDING_ACTIONS_TYPES; data: PendingActionsData }
): PendingActionsReducer => {
  switch (action.type) {
    case FETCH_PENDING_ACTIONS_TYPES.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_PENDING_ACTIONS_TYPES.SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case FETCH_PENDING_ACTIONS_TYPES.ERROR:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: true,
      };
    default:
      return state;
  }
};
