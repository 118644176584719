import React from "react";
import { Typography } from "@spenmo/splice";
// import Payment from "Views/SubscriptionManagement/Details/Summary/Details/Payment";
import { capitalizeFirstLetter } from "utility";
import { formatDate } from "utility/DateUtilites";
import { Grid, SingleItemGrid } from "Views/SubscriptionManagement/Details/Summary/Grid";
import { FilePreview } from "Views/SubscriptionManagement/Details/Summary/Details/FilesPreview";
import { useSubscriptionContext } from "Views/SubscriptionManagement/Details/SubscriptionContext";
import { CONTRACT_TYPE } from "Views/SubscriptionManagement/Constants";
import { ISubscriptionDetail } from "Views/SubscriptionManagement/@types";
import { getFileListWithNames } from "Views/SubscriptionManagement/Helper";

import styles from "./index.module.scss";

const getContractDetails = (subscription: ISubscriptionDetail) => [
  [
    {
      label: "Contract type",
      value: capitalizeFirstLetter(subscription.contract_type.replace("_", " ").toLowerCase()),
    },
    {
      label: "Next billing date",
      value: formatDate(subscription.next_billing_date || new Date(), "DD MMM YYYY"),
    },
    {
      label: subscription.contract_type === CONTRACT_TYPE.FIXED ? "Contract end date" : "Contract renewal date",
      value: formatDate(
        subscription.contract_type === CONTRACT_TYPE.FIXED ? subscription.end_date : subscription.next_renewal_date,
        "DD MMM YYYY"
      ),
    },
  ],
  [
    {
      label: "Budget",
      value: subscription.budget?.name,
    },
    {
      label: "Owner",
      value: subscription.owner?.name,
    },
    // {
    //   label: "Payment",
    //   value: (
    //     <Payment
    //       cardLastFour={subscription.payment.card_last_four}
    //       cardName={subscription.payment.card_name}
    //       cardStatus={subscription.payment.card_status}
    //       id={subscription.payment.card_id}
    //     />
    //   ),
    // },
  ],
];

export const Details = () => {
  const { subscription } = useSubscriptionContext();
  const contracts = getFileListWithNames(subscription.documents || []);

  return (
    <div className={styles.container}>
      {getContractDetails(subscription).map((row) => (
        <Grid items={row} />
      ))}
      <SingleItemGrid label="Description" value={subscription.description} />
      {Boolean(subscription.note) && <SingleItemGrid label="Notes" value={subscription.note} />}
      {Boolean(contracts.length) && (
        <div className={styles.filesColumn}>
          <Typography variant="body-content" size="s">
            Contract
          </Typography>
          <FilePreview files={contracts} />
        </div>
      )}
    </div>
  );
};
