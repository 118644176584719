export const TitleColorTypes = {
  GREEN: "green",
  BLUE: "blue",
  GRAY: "gray",
};
export const ProgressColorTypes = {
  GREEN: "#1fce8f",
  BLUE: "#1e97ee",
  RED: "#df1d0b",
};
