import { cookieNames, removeCookie } from "utility/CookieHelper";

const forgotPasswordCookiesReset = () => {
  removeCookie(cookieNames.IS_FORGOT_PASSWORD);
  removeCookie(cookieNames.CHANGE_PASSWORD_SUCCESS);
  removeCookie(cookieNames.CHANGE_PASSWORD_MESSAGE);
  removeCookie(cookieNames.USER_EMAIL);
  removeCookie(cookieNames.USER_MOBILE_NUMBER);
};

export default forgotPasswordCookiesReset;
