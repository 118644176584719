import React, { useEffect } from "react";
import { Typography } from "@spenmo/splice";
import { useFormContext } from "react-hook-form";

import Input from "Views/Bills/V2/components/FormGenerator/Input";

import { useBillForm } from "Views/Bills/V2/context/FormContext";
import { RecipientFormContext } from "Views/Bills/V2/Recipient/types";
import styles from "./SwiftCode.module.scss";

const SwiftCode = (props) => {
  const { name, disabled } = props;
  const { resetField } = useFormContext();
  const { isOpenNewBank, onOpenNewBank } = useBillForm<RecipientFormContext>();

  useEffect(() => {
    if (isOpenNewBank && disabled) {
      resetField(name, {
        defaultValue: "",
        keepDirty: true,
      });
    }
  }, [isOpenNewBank]);

  return (
    <>
      <Input {...props} />
      {!isOpenNewBank && (
        <Typography className={styles.description} variant="body-content" tag="p" size="caption-m">
          Different bank and SWIFT code?{" "}
          <Typography className={styles.link} variant="body-content" size="caption-m" onClick={onOpenNewBank}>
            +Add new bank
          </Typography>
        </Typography>
      )}
    </>
  );
};

export default SwiftCode;
