import React from "react";
import { Tooltip } from "antd";
import { IColumnData } from "Modules/DS/DataTable";
import { ITax } from "../types";
import { roundValue, capitalizeFirstLetter } from "utility";
import Chip, { CHIP_STATUS } from "Modules/DS/Chip";
import { TAX_MAPPING_STATUS } from "constants/Tax.constant";
import TextWithTooltip from "Views/Taxes/components/TextWithTooltip";

type ColumnDataType = (hasValidToken: boolean, partnerName: string) => IColumnData<ITax>[];

export const fetchColumnData: ColumnDataType = (hasValidToken, partnerName) => {
  return [
    {
      id: "TAX-COL-1",
      enabled: true,
      column: {
        title: "Tax Name",
        dataIndex: "tax_name",
        key: "tax_name",
        width: "50%",
        ellipsis: true,
        render: (taxName: string) => {
          return taxName;
        },
      },
    },
    {
      id: "TAX-COL-2",
      enabled: true,
      column: {
        title: "Tax Rate",
        dataIndex: "tax_rate",
        key: "tax_rate",
        width: "120px",
        render: (taxRate: string, tax: ITax) => {
          if (tax.mapping_status === TAX_MAPPING_STATUS.INCONSISTENT) {
            return (
              <Tooltip
                placement="left"
                transitionName="custom-tooltip-transition"
                getPopupContainer={(triggerNode) => triggerNode.parentElement}
                overlayClassName="inconsistent-tooltip"
                title={`Tax Rate on Spenmo is different from Tax Rate on ${partnerName}.`}
              >
                <p className="inconsistent-tax-text">{taxRate && `${roundValue(taxRate)}%`}</p>
              </Tooltip>
            );
          }

          return <p>{taxRate && `${roundValue(taxRate)}%`}</p>;
        },
      },
    },
    {
      id: "TAX-COL-3",
      enabled: hasValidToken,
      column: {
        title: `Tax Name on ${partnerName}`,
        dataIndex: "partner_tax_name",
        key: "partner_tax_name",
        width: "50%",
        ellipsis: true,
        render: (partner_tax_name: string, tax: ITax) => {
          if (tax.partner_tax_name) {
            const displayTax = `${partner_tax_name} ${tax.partner_tax_rate}%`;
            return (
              <div className="text-with-new-wrapper">
                <TextWithTooltip
                  treshold={tax.isNew ? 24 : 35}
                  text={displayTax}
                  element={
                    <p className="text-with-tooltip__title">
                      <span>{displayTax}</span>
                    </p>
                  }
                />
                {tax.isNew && <Chip.Label title="New" status={CHIP_STATUS.INFO} />}
              </div>
            );
          }

          return "";
        },
      },
    },
    {
      id: "TAX-COL-4",
      enabled: true,
      column: {
        title: "Status",
        dataIndex: "status",
        width: "144px",
        key: "status",
        render: (status: string) => <p>{capitalizeFirstLetter(status)}</p>,
      },
    },
  ].filter((data) => data.enabled);
};
