import React from "react";
import { Skeleton } from "antd";

import styles from "./styles.module.scss";

const SidePanelSkeleton = () => {
  return (
    <div className={styles["side-panel__skeleton"]} data-testid="loading-skeleton-container">
      <Skeleton.Input size="large" active />
      <Skeleton.Input size="large" active />
      <Skeleton.Input size="large" active />
      <Skeleton.Input size="large" active />
      <Skeleton.Input size="large" active />
    </div>
  );
};

export default SidePanelSkeleton;
