import React, { createContext, useState } from "react";

import { ISelectContext, ISelectState } from "Modules/DS/Select";

export const initalSelectState: ISelectState = { show: false, key: undefined };

export const initalSelectContext: ISelectContext = {
  select: { ...initalSelectState },
  nested: { ...initalSelectState },
  setSelect: () => null,
  setNested: () => null,
  closeSelect: () => null,
  closeNested: () => null,
  onSelectHandler: () => null,
};

export const SelectContext = createContext<ISelectContext>({ ...initalSelectContext });

export const useSelectContext = (): ISelectContext => {
  const [select, setSelect] = useState<ISelectState>({ ...initalSelectState });
  const [nested, setNested] = useState<ISelectState>({ ...initalSelectState });

  const closeSelect = () => {
    setSelect({ ...initalSelectState });
    closeNested();
  };

  const closeNested = () => setNested({ ...initalSelectState });

  const onSelectHandler = (id: string) => {
    setSelect({
      show: select.key === id ? false : true,
      key: select.show && select.key === id ? undefined : id,
    });
    closeNested();
  };

  const nestedHandler = (id: string) => {
    setNested({
      show: nested.key === id ? false : true,
      key: nested.show && nested.key === id ? undefined : id,
    });
  };

  return { select, setSelect, closeSelect, onSelectHandler, closeNested, nestedHandler, nested, setNested };
};

export const SelectProvider = ({ children }: { children: React.ReactNode }) => {
  const selectContext = useSelectContext();

  return <SelectContext.Provider value={selectContext}>{children}</SelectContext.Provider>;
};
