import {
  FETCH_UPLOADED_DOC_REQUEST,
  FETCH_UPLOADED_DOC_SUCCESS,
  FETCH_UPLOADED_DOC_FAILURE,
  FETCH_UPLOADED_DOC_CLEAR,
} from "../../Actions/OnBoarding/uploadedDocAction";
import { userInitialState } from "../../InitialState";

export const uploadedDocReducer = (state = userInitialState, action) => {
  switch (action.type) {
    case FETCH_UPLOADED_DOC_REQUEST:
      return {
        ...state,
        loading: true,
        data: action.data,
        error: false,
      };
    case FETCH_UPLOADED_DOC_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: false,
      };
    case FETCH_UPLOADED_DOC_FAILURE:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: true,
      };
    case FETCH_UPLOADED_DOC_CLEAR:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.data,
      };
    default:
      return state;
  }
};
