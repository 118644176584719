import { createContext } from "react";
import { FilterKey } from "./types";

export const initalFiltersState: ApprovalFilterState = {
  [FilterKey.Amount_Send]: false,
  [FilterKey.Amount_Receive]: false,
  [FilterKey.Invoice_Amount]: false,
  [FilterKey.Request_Date]: false,
  [FilterKey.Amount]: false,
  [FilterKey.Transaction_Date]: false,
};

interface ApprovalFilterState {
  [FilterKey.Amount_Send]: boolean;
  [FilterKey.Amount_Receive]: boolean;
  [FilterKey.Invoice_Amount]: boolean;
  [FilterKey.Request_Date]: boolean;
  [FilterKey.Amount]: boolean;
  [FilterKey.Transaction_Date]: boolean;
}

interface IApprovalFilterContext {
  filters: ApprovalFilterState;
  setFilters: React.Dispatch<React.SetStateAction<ApprovalFilterState>>;
}

export const initialApprovalFilterContext: IApprovalFilterContext = {
  filters: { ...initalFiltersState },
  setFilters: () => null,
};

export const ApprovalFilterContext = createContext(initialApprovalFilterContext);
