import useSWR from "swr";
import { GetActiveCategories } from "Redux/DataCalls/Categories.api";

export const useActiveCategory = () => {
  const result = useSWR("category", GetActiveCategories);
  const categoryList = result?.data?.data?.payload?.categories || [];

  return {
    ...result,
    categoryList,
  };
};
