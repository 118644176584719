import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { Button, Loader, Typography, Refresh, WarningFilled } from "@spenmo/splice";
import qs from "query-string";

import { postData, putData } from "API/Client";
import { useMutableData, useMatchMutate } from "API/useData";

import Steps from "Modules/Steps";
import { SidePanel } from "Modules/DS/SidePanel";
import BillDetail from "./BillDetail";
import PaymentDetail from "./PaymentDetail";
import BillPreview from "./BillPreview";
import Uploader from "./Uploader";

import { BillContext } from "Views/Bills/V2/context/BillContext";
import { FormContext } from "Views/Bills/V2/context/FormContext";
import { useErrorHandler } from "Views/Bills/V2/context/ErrorHandlerContext";
import { useDisclosure, useBeforeUnload, useToaster } from "Views/Bills/V2/hooks";

import { GetOrgId, GetUserId, flattenObj } from "utility";
import { trackEvent } from "utility/analytics";
import {
  API_URL,
  BillFormType,
  BillParams,
  ErrorCode,
  PAYMENT_RUN_URL,
  SUBMITTED_BILLS_URL,
} from "Views/Bills/V2/constants";
import { BillFlowEnum, BillFormFields, VendorDetailData } from "./type";
import { DEFAULT_VALUES, getRequestPayload } from "./helper";

import BillStyle from "Views/Bills/V2/Bills.module.scss";
import styles from "./BillForm.module.scss";
import useIsBulkView from "../hooks/useIsBulkView";
import { BILL_EVENTS, BILL_SUBMISSION_TYPES } from "Views/Bills/events";
import { Payment_Run_API_URL } from "../PaymentRun/constant";

const BillForm: React.FC = () => {
  const history = useHistory();
  const location = useLocation();

  const params = useParams<BillParams>();
  const urlParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const encodedBillIds = urlParams.has("data");
  // Memoized calculations for form status
  const isBulkView = useIsBulkView();
  const isNewBill = useMemo(() => params.form === BillFormType.NEW, [params]);
  const isEditForm = useMemo(
    () =>
      (params.form === BillFormType.EDIT && params.id.length > 0) ||
      (isBulkView && params.operation === "edit" && encodedBillIds),
    [params, encodedBillIds, isBulkView]
  );

  // Use useMemo to prevent unnecessary re-computations
  const isEditDraft = useMemo(
    () => isEditForm && location.pathname.includes("/bills/drafts"),
    [isEditForm, location.pathname]
  );

  const isEditBill = useMemo(
    () =>
      isEditForm && (location.pathname.includes(SUBMITTED_BILLS_URL) || location.pathname.includes(PAYMENT_RUN_URL)),
    [isEditForm, location.pathname]
  );

  const isBillFormVisible = isNewBill || isEditDraft || isEditBill;

  const billFormTitle: BillFlowEnum = useMemo(() => {
    switch (true) {
      case isEditDraft:
        return BillFlowEnum.EDIT_DRAFT;
      case isEditBill:
        return BillFlowEnum.EDIT_BILL;
      case isNewBill:
      default:
        return BillFlowEnum.NEW_BILL;
    }
  }, [isEditBill, isEditDraft, isNewBill]);

  const method = useForm<BillFormFields>({
    defaultValues: DEFAULT_VALUES,
    mode: "onTouched",
    reValidateMode: "onChange",
  });

  const { handleSubmit, formState, getValues, reset, watch, resetField, unregister } = method;
  const { isDirty, dirtyFields } = formState;

  const matchMutate = useMatchMutate();

  const billFormVisibleRef = useRef(isBillFormVisible);
  const formRef = useRef();
  const dirtyFieldsBank = useRef({});

  const [step, setStep] = useState(0);
  const [latestStep, setLatestStep] = useState(step);
  // for additional fields page
  const [formPage, setFormPage] = useState<number>();

  // IMPROVEMENT: Put this inside a custom Provider Component?
  const [showPrompt, setShowPrompt] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [refetchValues, setRefetchValues] = useState({});
  const [vendorDetail, setVendorDetail] = useState<VendorDetailData>();
  const [isUploading, setIsUploading] = useState(false);
  const [isFxRateExpired, setIsFxRateExpired] = useState(false);
  const billFormSteps = [
    {
      title: "Bill details",
    },
    {
      title: "Payment details",
    },
    {
      title: "Preview",
    },
  ];

  const { data: getSetting } = useMutableData(API_URL.getPaymentRunSetting);
  const getSettingData = useMemo(() => getSetting?.data?.payload?.setting, [getSetting?.data?.payload?.setting]);
  const isPaymentRunOn = Boolean(getSettingData);

  const billDetailURL = qs.stringifyUrl({
    url: `${API_URL.disbursementV1}/bill/${params.id}`,
    query: {
      source: isEditDraft && !isNewBill ? "edit_draft" : "edit_bill",
    },
  });

  const { data: billDetailData, isValidating, error } = useMutableData(params.id ? billDetailURL : null);

  const ocrAttachment = billDetailData?.data?.payload?.ocrAttachment || {};

  const promptCondition = isBillFormVisible && isDirty;

  const { UnloadModal, setConfirmCondition } = useBeforeUnload(promptCondition, billFormTitle);
  // To show the Form Fields
  const { isOpen: isShowFormField, onClose: closeShowForm, onOpen: openShowForm } = useDisclosure();
  const { appNotification } = useToaster();
  const { setRecipientSelectedID, setOCRData, setWithOCRData } = useContext(BillContext);
  const { setClickSave, handleError, handleResetRefetch } = useErrorHandler();

  const resetForm = useCallback(() => {
    closeShowForm();

    reset(DEFAULT_VALUES);
    unregister("billTax.invoiceCurrency");
    setLatestStep(0);
    setStep(0);
    setIsFxRateExpired(false);
    handleResetRefetch();
    setOCRData(undefined);
    setWithOCRData(false);
    // IMPROVEMENT: in my opinion, it's better to
    // put this inside the specific form rather than the full form.
    // but step 1 & 2 may connected.
    setRefetchValues({});
    setRecipientSelectedID(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset, handleResetRefetch, setOCRData, setWithOCRData, setRecipientSelectedID]);

  useEffect(() => {
    // more info: https://legacy.reactjs.org/docs/hooks-reference.html#lazy-initial-state
    setClickSave(() => handleSaveDraft);
  }, [params]);

  useEffect(() => {
    if (isEditForm) {
      openShowForm();
    }
  }, [isEditForm]);

  useEffect(() => {
    if (ocrAttachment?.id) {
      resetField("ocrID", {
        defaultValue: ocrAttachment?.id,
      });
    }
  }, [ocrAttachment]);

  // tracking on bill field edited
  useEffect(() => {
    const fieldBank = dirtyFieldsBank.current;
    Object.keys(flattenObj(dirtyFields)).forEach((fieldName) => {
      if (!fieldBank[fieldName]) {
        dirtyFieldsBank.current[fieldName] = true;

        trackEvent(BILL_EVENTS.fieldsEdited, {
          bill_edit_event_source: fieldName,
          bill_flow_type: billFormTitle,
          bill_id: params?.id,
        });
      }
    });
    // https://react-hook-form.com/docs/useform/formstate
  }, [formState]);

  useEffect(() => {
    // reset if the previous billForm state is open, and now it's closed
    if (!isBillFormVisible && billFormVisibleRef.current) {
      resetForm();
    }

    billFormVisibleRef.current = isBillFormVisible;
  }, [isBillFormVisible]);

  useEffect(() => {
    setLatestStep(Math.max(step, latestStep));
  }, [step]);

  const closeForm = useCallback(
    (state?: object) => {
      let previousPage: string[] = location.pathname.split("/");
      previousPage.pop();

      // for manage recipients tab
      if (params?.vendorId) {
        previousPage.pop();
      }

      if (!isNewBill) {
        previousPage.pop();
      }

      history.push(previousPage.join("/"), state);
    },
    [history, isNewBill, location.pathname, params]
  );

  const handleClose = useCallback(() => {
    if (promptCondition) {
      setShowPrompt(true);
      return;
    }
    if (isEditBill) {
      if (step === 0 || step === 1) {
        trackEvent(BILL_EVENTS.editPageClosed, { bill_id: params.id });
      } else {
        trackEvent(BILL_EVENTS.previewPageClosed, { bill_id: params.id });
      }
    }
    closeForm();
  }, [closeForm, promptCondition, params]);

  const handleClickDiscard = () => {
    setShowPrompt(false);
    closeForm();
  };

  const handleUpload = (status: "uploading" | "done") => {
    if (status === "uploading") {
      setIsUploading(true);
      return;
    } else if (status === "done") {
      openShowForm();
    }

    setIsUploading(false);
  };

  const handleResetScroll = (callback: Function) => () => {
    document.getElementById("billForm").scrollTop = 0;

    callback();
  };

  const handleSaveDraft = () => {
    trackEvent("Bill Save as Draft Button Clicked", {
      bill_flow_type: billFormTitle,
    });

    const data = getValues();
    const formData = getRequestPayload(data);
    const id = params.id;

    const url = isNewBill ? API_URL.saveAsDraft : `${API_URL.disbursementV2}/bill/${id}`;

    let fetcher;
    if (isNewBill) {
      fetcher = postData;
    } else {
      fetcher = putData;
      formData.append("step", (latestStep + 1) as unknown as string);
      formData.append("isDraft", String(isEditDraft));
      formData.append("action", "edit");
    }

    setIsSubmitLoading(true);
    fetcher(url, formData, false, {
      headers: {
        "X-Organization-Id": GetOrgId(),
        "X-User-Id": GetUserId(),
        "X-Is-Opsy": false,
      },
    })
      .then(async (res) => {
        const { error, payload } = res.data || {};
        if (payload.isSuccess) {
          const { billID } = res.data.payload;

          if (isPaymentRunOn) {
            await matchMutate(new RegExp(Payment_Run_API_URL.paymentCycles));
          } else {
            await matchMutate(new RegExp(API_URL.saveAsDraft));
          }

          await matchMutate(new RegExp(`${API_URL.disbursementV1}/bill/${billID}`), undefined);

          // TO DO: notification if success
          setConfirmCondition(false);
          setShowPrompt(false);
          closeForm();

          trackEvent("Bill Save as Draft Processed", {
            bill_flow_type: billFormTitle,
          });

          return;
        }

        if (error?.message) {
          appNotification.error({
            errorCode: error.code,
            message: error.message,
          });
          return;
        }

        appNotification.error({
          message: "An error occurred when save draft bill. Please try again.",
        });

        // if other than success
        handleError({
          retry: {
            id: "saveDraft",
            onClickRetry: handleSaveDraft,
          },
        });
      })
      .catch((err) => {
        console.error(err);
        handleError({
          retry: {
            id: "saveDraft",
            onClickRetry: handleSaveDraft,
          },
        });

        if (err?.response?.data?.error) {
          const { code, message } = err.response.data.error;

          if (message) {
            appNotification.error({
              errorCode: code,
              message,
            });

            return;
          }
        }

        appNotification.error({
          message: "An error occurred when save draft bill. Please try again.",
        });
      })
      .finally(() => {
        setIsSubmitLoading(false);
      });
  };

  const handleSubmitForm: SubmitHandler<BillFormFields> = useCallback(
    (data) => {
      if (billFormTitle === BillFlowEnum.NEW_BILL || billFormTitle === BillFlowEnum.EDIT_DRAFT) {
        trackEvent("Bill Creation Submit Button Clicked", {
          bill_flow_type: billFormTitle,
        });
      } else {
        trackEvent(BILL_EVENTS.submitClicked, {
          bill_id: params?.id,
          bill_flow_type: billFormTitle,
          bill_submission_type: isBulkView ? BILL_SUBMISSION_TYPES.bulk : BILL_SUBMISSION_TYPES.single,
        });
      }

      const formData = getRequestPayload(data);

      const url = isNewBill ? API_URL.submitBill : `${API_URL.disbursementV2}/bill/${params.id}`;

      let fetcher;
      if (isNewBill) {
        fetcher = postData;
      } else {
        fetcher = putData;
        formData.append("isDraft", String(isEditDraft));
        formData.append("action", isEditBill ? "edit" : "confirm");
      }

      setIsSubmitLoading(true);
      fetcher(url, formData, false)
        .then(async (res) => {
          const { error, payload } = res.data || {};
          const { billID, isSuccess, isApprovalEnabled } = payload || {};

          if (isSuccess) {
            let cta = "processing payment";
            const link = qs.stringifyUrl({
              url: isPaymentRunOn ? PAYMENT_RUN_URL : SUBMITTED_BILLS_URL,
              query: {
                invoiceid: billID,
              },
            });

            if (isApprovalEnabled) {
              cta = "approval";
            }

            if (isPaymentRunOn) {
              await matchMutate(new RegExp(Payment_Run_API_URL.paymentCycles));
            }

            await matchMutate(new RegExp(`${API_URL.disbursementV1}/bill/${billID}`), undefined);

            closeForm({
              refetchBillList: true,
              refetchBillDetail: true,
            });

            trackEvent("Bill Creation Submit Processed", {
              bill_id: billID,
              bill_flow_type: billFormTitle,
            });

            await matchMutate(new RegExp(API_URL.saveAsDraft));

            appNotification.success({
              message: `Bill has been successfully submitted for ${cta}`,
              cta: "View Bill",
              onClickCTA: () =>
                history.push(link, {
                  refetchBillList: true,
                }),
            });

            return;
          }

          if (error?.message) {
            appNotification.error({
              errorCode: error.code,
              message: error.message,
            });
            return;
          }

          appNotification.error({
            message: "An error occurred when submitting bill(s). Please try again.",
          });
        })
        .catch((err) => {
          if (err?.response?.data?.error) {
            const { code, message } = err.response.data.error;
            if (code === ErrorCode.FX_RATE_EXPIRED_CODE) {
              setIsFxRateExpired(true);
              // If fxrates get expired then move back to payment details page.
              setStep(1);
            }

            if (message) {
              appNotification.error({
                errorCode: code,
                message,
              });

              return;
            }
          }

          appNotification.error({
            message: "An error occurred when submitting bill(s). Please try again.",
          });
        })
        .finally(() => {
          setIsSubmitLoading(false);
        });
    },
    [history, params, closeForm]
  );

  const formComponent = useMemo(() => {
    switch (step) {
      case 0: {
        return (
          <BillDetail
            onBack={handleClose}
            onNext={handleResetScroll(() => setStep(1))}
            isShowForm={isShowFormField}
            onShowForm={openShowForm}
            billFlow={billFormTitle}
          />
        );
      }
      case 1: {
        // onNext should be either submit or go to step 3
        // which is additional fields from the vendor management form
        return (
          <PaymentDetail
            ref={formRef}
            onBack={handleResetScroll(() => setStep(0))}
            onNext={handleResetScroll(() => setStep(2))}
            formPage={formPage}
            onChangeFormPage={setFormPage}
            billFlow={billFormTitle}
          />
        );
      }
      case 2: {
        // onNext should be either submit or go to step 3
        // which is additional fields from the vendor management form
        return (
          <BillPreview onBack={() => setStep(1)} onNext={handleSubmit(handleSubmitForm)} billFlow={billFormTitle} />
        );
      }
    }
    return null;
  }, [step, handleClose, isShowFormField, openShowForm, formPage, handleSubmit, handleSubmitForm]);

  const deletedAttachmentIDs = watch("deletedAttachmentIDs", []);

  return (
    <>
      <UnloadModal
        visible={showPrompt}
        onClose={() => setShowPrompt(false)}
        onClickDiscard={handleClickDiscard}
        onClickSave={handleSaveDraft}
      />
      <SidePanel
        sidePanelClassName={BillStyle.sidepanel}
        visible={isBillFormVisible}
        withOverlay
        onClose={handleClose}
        title={billFormTitle}
        sticky
      >
        <Steps current={step} data={billFormSteps} />
        <FormContext.Provider
          value={{
            // TO DO: create a custom Provider component for FormContext
            vendorDetail,
            setVendorDetail,
            handleSaveDraft,
            refetchValues,
            setRefetchValues,
            isSubmitLoading,
            setIsSubmitLoading,
            isFxRateExpired,
            setIsFxRateExpired,
          }}
        >
          <FormProvider {...method}>
            <form ref={formRef} className={styles.BillForm} id="billForm">
              {error ? (
                <div className={styles.errorState}>
                  <WarningFilled size="24" iconColor="var(--red-040)" />
                  <Typography size="caption-m" variant="body-content">
                    Uh-ohh! We couldn’t load the bill details. Let’s give it another shot by refreshing it.
                  </Typography>
                  <Button variant="secondary" icon={Refresh} type="reset" onClick={() => window.location.reload()}>
                    Refresh
                  </Button>
                </div>
              ) : (
                <>
                  {(isNewBill || (!isNewBill && !deletedAttachmentIDs.includes(ocrAttachment?.id))) && (
                    <Uploader onHandleUpload={handleUpload} showField={step === 0} />
                  )}
                  {formComponent}
                  {(isUploading || isValidating) && (
                    <div>
                      <div className={styles.overlay} />
                      <Loader.Spinner size="l" variant="brand" className={styles.overlayIcon} />
                    </div>
                  )}
                </>
              )}
            </form>
          </FormProvider>
        </FormContext.Provider>
      </SidePanel>
    </>
  );
};

export default BillForm;
