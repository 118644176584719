import { useContext, useEffect, useState } from "react";
import { Form } from "antd";
import dayjs from "dayjs";

import { ISelectContext, SelectContext } from "Modules/DS/Select";
import { IDateFormProps, IDateFilterStates } from "Modules/DS/Filter/@types";
import moment from "moment";
import getDateLabel from "./getDateLabel";

const useStates = <T extends object = any>(
  filter: T,
  onApply: (value: IDateFormProps) => void,
  onClear: (keys: string[]) => void,
  startKey: string,
  endKey: string,
  defaultValue: string = "Date",
  asISO?: boolean,
): IDateFilterStates => {
  const [form] = Form.useForm<IDateFormProps>();

  const { closeSelect } = useContext<ISelectContext>(SelectContext);

  const [title, setTitle] = useState<string>(defaultValue);

  const [selected, setSelected] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const [from, setFrom] = useState<string>(filter?.[startKey] || "");
  const [to, setTo] = useState<string>(filter?.[endKey] || "");

  useEffect(() => {
    if (filter?.[startKey] && filter?.[endKey]) {
      applyFilter({
        [startKey]: filter?.[startKey],
        [endKey]: filter?.[endKey],
      });
      form.setFieldsValue({
        [startKey]: dayjs(filter?.[startKey]).toISOString(),
        [endKey]: dayjs(filter?.[endKey]).toISOString(),
      });
      setFrom(dayjs(filter?.[startKey]).format("YYYY-MM-DD"));
      setTo(dayjs(filter?.[endKey]).format("YYYY-MM-DD"));
    } else if (title !== defaultValue) {
      // reset if filter is empty and the title is not using the defaultValue
      clearSelected();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter?.[startKey], filter?.[endKey]]);

  const applyFilter = (values: IDateFormProps) => {
    if (!(values[startKey] || values[endKey]).length) return;

    const label: string = getDateLabel(values[startKey], values[endKey]);

    if (!label.length) {
      setError("Invalid Date Input");
      return;
    }

    if (!asISO) {
      if (values[startKey]) {
        values[startKey] = moment(values[startKey])
          .startOf("day")
          .format("YYYY-MM-DD");
      }
      if (values[endKey]) {
        values[endKey] = moment(values[endKey])
          .startOf("day")
          .format("YYYY-MM-DD");
      }
    }

    setTitle(label);
    setSelected(true);
    closeSelect();
    form.setFieldsValue({
      [startKey]: values[startKey],
      [endKey]: values[endKey],
    });
    onApply(values);
    setError("");
  };

  const clearStates = () => {
    form.resetFields();
    setFrom(undefined);
    setTo(undefined);
    setError("");
  };

  const clearSelected = () => {
    closeSelect();
    clearStates();
    setSelected(false);
    setTitle(defaultValue);
    onClear([startKey, endKey]);
  };

  return {
    get: { form, title, selected, from, to, error },
    set: { from: setFrom, to: setTo },
    defaultValue,
    applyFilter,
    clearStates,
    clearSelected,
  };
};

export default useStates;
