import React from "react";
import PropTypes from "prop-types";
import { Tooltip } from "antd";
import { failedIcon, queuedIcon, syncedIcon } from "../../../assets/img";
import Icon from "../../../Modules/icons";
import "./SyncStatus.scss";

const SyncStatus = ({ data }) => {
  switch (true) {
    case data.type === "Transfer":
      return <p>N/A</p>;
    case data.type !== "Transfer" && data.xero_sync_status === 2:
      return <Icon src={syncedIcon} title={"Synced"} className={"sync-icons"} />;
    case data.type !== "Transfer" && data.xero_sync_status === 1:
      return <Icon src={queuedIcon} title={"Queued"} className={"sync-icons"} />;
    case data.type !== "Transfer" && data.xero_sync_status === 0:
      return "";
    case data.type !== "Transfer" && data.xero_sync_status === -1:
      return (
        <div className={"sync-icons"}>
          <Tooltip
            title={data.xero_sync_status_message}
            key={data.id}
            onClick={(e) => e.stopPropagation()}
            getPopupContainer={() => document.getElementById(data.id)}
            trigger={"click"}
            placement={"bottomRight"}
            overlayClassName="sync-failed-tooltip"
          >
            <Icon src={failedIcon} className={"sync-failed-icon"} id={"failed-icon"} />
            <span id={data.id} />
          </Tooltip>
          <p className={"sync-title"}>Failed</p>
        </div>
      );
    default:
      return "";
  }
};

SyncStatus.propTypes = {
  data: PropTypes.object,
};

export default SyncStatus;
