import { GetUserId } from "utility";

import { USER_AUTH } from "Redux/Actions/loginAction";
import { userInitialState } from "Redux/InitialState";
import { UPDATE_USER_ROLE_MEMBERSHIP } from "Redux/Actions/teamAction";

export const userReducer = (
  state = {
    userInitialState,
    userId: GetUserId(),
    role: "",
    membership: "",
    company_role: "",
  },
  action
) => {
  switch (action.type) {
    case USER_AUTH:
      return {
        ...state,
        data: action.data,
      };
    case UPDATE_USER_ROLE_MEMBERSHIP:
      return {
        ...state,
        role: action.role,
        membership: action.membership,
        company_role: action.company_role,
      };
    default:
      return state;
  }
};
