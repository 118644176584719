import APIclient from "API/Client";
import { HTTP_STATUS_CODE } from "../../../constants/HTTPStatusCode.constant";
import { trackEvent } from "utility/analytics";
export const FETCH_UPLOAD_REQUEST = "FETCH_UPLOAD_REQUEST";
export const FETCH_UPLOAD_SUCCESS = "FETCH_UPLOAD_SUCCESS";
export const FETCH_UPLOAD_FAILURE = "FETCH_UPLOAD_FAILURE";
export const FETCH_UPLOAD_CLEAR = "FETCH_UPLOAD_CLEAR";

export const fetchDataRequest = (data) => {
  return {
    type: "FETCH_UPLOAD_REQUEST",
    data: data,
  };
};

export const fetchDataSuccess = (data) => {
  return {
    type: "FETCH_UPLOAD_SUCCESS",
    data: data.payload,
  };
};

export const fetchDataError = (data) => {
  return {
    type: "FETCH_UPLOAD_FAILURE",
    data: data,
  };
};

export const clearUploadDocument = (data) => {
  return {
    type: FETCH_UPLOAD_CLEAR,
    data: data,
  };
};

const docsUploadAnalytics = (documentType) => {
  const eventName = documentType === "ACRA" ? "Company docs uploaded" : " Directors id uploaded";
  const params = {
    documentType,
  };
  trackEvent(eventName, params);
};

export const uploadDocument = (data) => {
  let formData = new FormData();
  const { documentType, fileList = [] } = data;
  fileList.map((file) => formData.append("file", file));
  formData.append("documentType", documentType);

  return (dispatch) => {
    dispatch(fetchDataRequest());
    APIclient.postData(`/ms/spm-organization/v2/onboarding/document`, formData)
      .then((data) => {
        if (data.data.status === HTTP_STATUS_CODE.OK) {
          // for analytics to track user action
          docsUploadAnalytics(documentType);

          dispatch(fetchDataSuccess(data.data));
        } else {
          dispatch(fetchDataError(data.data));
        }
      })
      .catch((error) => {
        dispatch(fetchDataError(error));
      });
  };
};
