import React, { useContext } from "react";
import Button from "Modules/button";
import Icon from "Modules/icons";
import { Info } from "assets/v1.1/icons/Core/Info";

import { KYXModalContext } from "Views/KYX/Context";
import { KYX_MODAL_TYPES } from "Views/KYX/Constants";
import { IGuidelinesButton, IKYXModalContext } from "Views/KYX/@types";

import styles from "./index.module.scss";

const GuidelinesButton = ({ type }: IGuidelinesButton): JSX.Element => {
  const { setModal }: IKYXModalContext = useContext(KYXModalContext);

  const handleSetModal: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    setModal({ visible: true, type: KYX_MODAL_TYPES.DOCUMENT_GUIDELINES, payload: { guidelinesType: type } });
  };

  return (
    <Button action={handleSetModal} textButton className={styles.button} type="button">
      <Icon className={styles.icon} src={Info.Fill.blue_18} />
      <span>See guidelines</span>
    </Button>
  );
};

export default GuidelinesButton;
