import {
  initialKycState,
  IKycStatus,
  IKycStatusReducer,
  GET_KYC_STATUS_ACTION_TYPES,
} from "Redux/Actions/Employee/Get/KycStatusList/types";

export const kycStatusesReducer = (
  state: IKycStatusReducer = initialKycState,
  action: { type: GET_KYC_STATUS_ACTION_TYPES; data: IKycStatus[] }
): IKycStatusReducer => {
  switch (action.type) {
    case GET_KYC_STATUS_ACTION_TYPES.FETCH_KYC_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_KYC_STATUS_ACTION_TYPES.FETCH_KYC_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: false,
      };
    case GET_KYC_STATUS_ACTION_TYPES.FETCH_KYC_STATUS_ERROR:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: true,
      };
    default:
      return state;
  }
};
