import React from "react";

export const CheckedCircle = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="8" cy="8" r="7.5" fill="var(--dark-orange-040)" stroke="var(--dark-orange-040)" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7121 5.21582C11.3727 4.88664 10.8683 4.94001 10.5854 5.33503L7.70705 9.35488C7.42968 9.74225 7.27374 9.95672 7.14925 10.0885C7.14764 10.0902 7.14605 10.0919 7.1445 10.0935C7.14282 10.0921 7.14111 10.0906 7.13937 10.089C7.00513 9.97089 6.83316 9.77374 6.52679 9.41719L5.36568 8.06587C5.05326 7.70227 4.54673 7.70227 4.23431 8.06587C3.9219 8.42947 3.9219 9.01898 4.23431 9.38257L5.39542 10.7339C5.40632 10.7466 5.41721 10.7592 5.42808 10.7719C5.68927 11.076 5.94055 11.3686 6.17434 11.5743C6.43624 11.8048 6.77921 12.0215 7.21874 11.9983C7.65826 11.9751 7.98297 11.7231 8.22586 11.466C8.44268 11.2365 8.67016 10.9186 8.90663 10.5883C8.91647 10.5745 8.92633 10.5608 8.9362 10.547L11.8146 6.52712C12.0974 6.13209 12.0515 5.54501 11.7121 5.21582Z"
        fill="white"
      />
    </svg>
  );
};
