import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import classNames from "classnames";
import { Form } from "antd";

import Button from "Modules/button";
import { FilterItem } from "Modules/DS/Filter";
import { POSITION_ALGO_TYPE } from "Modules/DS/Popover";
import { ISelectContext, SelectContext, initalSelectState } from "Modules/DS/Select";
import { CARD_TYPE } from "Views/Card/types";

import { FilterKey } from "Views/Approval/ApprovalCenter/types";
import { applyFilter, clearFilter, setCurrentPage } from "Redux/Reducers/Approval/ApprovalCenter/approvalCenter.slice";

import "./index.scss";
import { ApprovalFilterContext } from "../context";

const cardTypes: CARD_TYPE[] = [CARD_TYPE.PHYSICAL, CARD_TYPE.VIRTUAL];

const ApprovalCardFilter = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { setSelect } = useContext<ISelectContext>(SelectContext);
  const [currentType, setCurrentType] = useState<CARD_TYPE | undefined>(null);
  const { filters, setFilters } = useContext(ApprovalFilterContext);

  const clearCardFilter = () => {
    setCurrentType(null);
    dispatch(clearFilter(FilterKey.Card_Type));
    setSelect({ ...initalSelectState });
    setFilters((filters) => {
      return {
        ...filters,
        [FilterKey.Card_Type]: false,
      };
    });
  };

  useEffect(() => {
    return () => {
      clearCardFilter();
    };
  }, []);

  const handleSubmit = () => {
    if (!currentType) return;

    dispatch(setCurrentPage(1));
    dispatch(applyFilter({ isPhysicalCard: currentType === CARD_TYPE.PHYSICAL ? true : false }));
    setSelect({
      ...initalSelectState,
    });
    setFilters((filters) => {
      return {
        ...filters,
        [FilterKey.Card_Type]: true,
      };
    });
  };

  return (
    <Form name="cardTypeForm" form={form} onFinish={handleSubmit}>
      <div className="approval-filter">
        <FilterItem
          id={FilterKey.Card_Type}
          title="Card Type"
          selected={filters[FilterKey.Card_Type]}
          clearFilter={clearCardFilter}
          positionAlgoType={POSITION_ALGO_TYPE.HORIZONTAL}
        >
          <div className="amount-filter__card-type">
            <div className="amount-filter__card-type-content">
              {cardTypes.map((cardType) => (
                <div
                  key={cardType}
                  className={classNames("amount-filter__card-type-item", { active: cardType === currentType })}
                  onClick={() => setCurrentType(cardType)}
                >
                  {cardType}
                </div>
              ))}
            </div>
            <div className="filter-popover__action">
              <Button
                className="filter-popover__action__button--clear"
                type="button"
                textButton
                action={clearCardFilter}
              >
                Clear
              </Button>
              <Button className="filter-popover__action__button--submit">Apply</Button>
            </div>
          </div>
        </FilterItem>
      </div>
    </Form>
  );
};

export default ApprovalCardFilter;
