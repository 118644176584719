export enum NetworkCallState {
  unresolved = "unresolved",
  loading = "loading",
  failed = "failed",
  completed = "completed",
}

export interface Pagination {
  currentPage: number;
  totalPage: number;
  limit: number;
  total: number;
  isEmpty: boolean;
}

export interface ApiCallState {
  loading: NetworkCallState;
  message?: string;
  //data?: T;
  error?: boolean;
  paging?: Pagination;
}
