import APIclient from "API/Client";

export const GET_APPROVAL_FLOW_REQUEST = "GET_APPROVAL_FLOW_REQUEST";
export const GET_APPROVAL_FLOW_SUCCESS = "GET_APPROVAL_FLOW_SUCCESS";
export const GET_APPROVAL_FLOW_FAILURE = "GET_APPROVAL_FLOW_FAILURE";
export const CLEAR_GET_APPROVAL_FLOW = "CLEAR_GET_APPROVAL_FLOW";

const getApprovalFlowReq = (data?: any) => {
  return {
    type: GET_APPROVAL_FLOW_REQUEST,
    data,
  };
};
const getApprovalFlowSuccess = (data?: any) => {
  return {
    type: GET_APPROVAL_FLOW_SUCCESS,
    data,
  };
};
const getApprovalFlowError = (data?: any) => {
  return {
    type: GET_APPROVAL_FLOW_FAILURE,
    data,
  };
};

export const clearGetApprovalFlow = () => {
  return {
    type: CLEAR_GET_APPROVAL_FLOW,
  };
};

export const getApprovalFlowFunc = (id) => {
  return (dispatch) => {
    dispatch(getApprovalFlowReq());

    APIclient.getData(`/ms/spm-organization/v2/approval/workflow/${id}`)
      .then((data) => {
        dispatch(getApprovalFlowSuccess(data.data));
      })
      .catch((err) => {
        const errorMessage = err?.response?.data?.error?.message || err;
        dispatch(getApprovalFlowError(errorMessage));
      });
  };
};
