import { FormInstance } from "antd/lib/form";
import { createContext } from "react";
import { DynamicFormFieldWithAncestor } from "./types";

export interface DynamicFormContextProps {
  currency: string;
  destinationCountry: string;
  fields: Record<string, DynamicFormFieldWithAncestor>;
  form: FormInstance;
  isNewDataFields: Record<string, boolean>;
  key: string;
  setChainFields: React.Dispatch<React.SetStateAction<Record<string, unknown>>>;
  setFields: React.Dispatch<React.SetStateAction<DynamicFormContextProps["fields"]>>;
  setIsNewDataFields: React.Dispatch<React.SetStateAction<DynamicFormContextProps["isNewDataFields"]>>;
  trackView: (_fieldName: string, _value: any) => void;
  trackEdit: (_fieldName: string, _value: any) => void;
}

export const DynamicFormContext = createContext<DynamicFormContextProps>({
  currency: "",
  destinationCountry: "",
  fields: {},
  form: undefined,
  isNewDataFields: {},
  key: "",
  setChainFields: (_fields) => {},
  setFields: (_fields) => {},
  setIsNewDataFields: (_isNewDataFields) => {},
  trackView: (_fieldName, _value) => {},
  trackEdit: (_fieldName, _value) => {},
});
