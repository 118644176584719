import APIclient from "API/Client";

export const DELETE_APPROVAL_FLOW_REQUEST = "DELETE_APPROVAL_FLOW_REQUEST";
export const DELETE_APPROVAL_FLOW_SUCCESS = "DELETE_APPROVAL_FLOW_SUCCESS";
export const DELETE_APPROVAL_FLOW_FAILURE = "DELETE_APPROVAL_FLOW_FAILURE";
export const CLEAR_DELETE_APPROVAL_FLOW = "CLEAR_DELETE_APPROVAL_FLOW";

const deleteApprovalFlowRequest = (data?: any) => {
  return {
    type: DELETE_APPROVAL_FLOW_REQUEST,
    data,
  };
};

const deleteApprovalFlowSuccess = (data?: any) => {
  return {
    type: DELETE_APPROVAL_FLOW_SUCCESS,
    data,
  };
};

const deleteApprovalFlowFailure = (data?: any) => {
  return {
    type: DELETE_APPROVAL_FLOW_FAILURE,
    data,
  };
};

export const clearDeleteApprovalFlow = () => {
  return {
    type: CLEAR_DELETE_APPROVAL_FLOW,
  };
};

export const deleteApprovalFlow = (id) => {
  return (dispatch) => {
    dispatch(deleteApprovalFlowRequest());

    APIclient.deleteData(`/ms/spm-organisation/v2/approval/workflow/${id}`)
      .then((res: any) => {
        if (res.data?.payload?.isSuccess) {
          dispatch(deleteApprovalFlowSuccess({ ...res.data, id }));
        } else {
          dispatch(deleteApprovalFlowFailure(res.data));
        }
      })
      .catch((err) => {
        dispatch(deleteApprovalFlowFailure(err));
      });
  };
};
