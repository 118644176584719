import React, { useContext, useState } from "react";
import { Form } from "antd";
import { FormInstance } from "antd/lib/form";
import { InternalNamePath } from "antd/lib/form/interface";

import { cookieNames, getCookieValue } from "utility/CookieHelper";

import {
  FORM_SOURCE_TYPE,
  IDocumentStep,
  IFetchDataFormatter,
  IFetchFormSubmissionPayload,
  IManualFormContext,
} from "Views/KYX/@types";
import { ProgressFooter } from "Views/KYX/Progress/Footer";
import { FORM_NAME, KYB_STATUS } from "Views/KYX/Constants";
import RemarksBanner from "Views/KYX/Remarks/RemarksBanner";
import { ManualFormContext } from "Views/KYX/Context/ManualForm";
import { DocumentsLoader, InformationLoader } from "Views/KYX/Loader";
import { useFetchDataFormatter, useStepChangeHandler } from "Views/KYX/hooks";

import { DocumentStep } from "./Document";
import { useCompanyDocumentsFormBannerSGD, useCompanyInfoFormBannerSGD } from "./Second/Form/Banner";

import styles from "./index.module.scss";

export const ProgressStep = ({
  name,
  form,
  children,
  formatter,
  handleErrors,
}: {
  name: FORM_NAME;
  form: FormInstance;
  children: React.ReactNode;
  formatter: IFetchDataFormatter;
  handleErrors?: (
    errors: {
      name: InternalNamePath;
      errors: string[];
    }[]
  ) => void;
}) => {
  const [isFormTouched, setIsFormTouched] = useState(false);

  const kybStatus = getCookieValue(cookieNames.KYB_STATUS);
  const { setIsFormModified }: IManualFormContext = useContext(ManualFormContext);

  const initialValues = formatter.formattedInitialValues;

  const loader = () => (name === FORM_NAME.COMPANY_DOCUMENTS ? <DocumentsLoader /> : <InformationLoader />);

  return formatter.loading ? (
    loader()
  ) : (
    <Form
      name={name}
      form={form}
      autoComplete="off"
      initialValues={initialValues}
      onChange={() => {
        setIsFormTouched(true);
        setIsFormModified(true);
      }}
      onValuesChange={() => {
        setIsFormTouched(true);
      }}
      scrollToFirstError={true}
      onFinishFailed={({ errorFields }) => {
        if (handleErrors) {
          handleErrors(errorFields);
        }
        form.validateFields();
      }}
    >
      <div className={styles.container}>{children}</div>
      {kybStatus === KYB_STATUS.ADDITIONAL_DOCUMENTS_REQUIRED && <RemarksBanner />}
      <ProgressFooter
        form={form}
        initialValues={initialValues}
        form_id={formatter.formData?.form_id}
        isFormTouched={isFormTouched}
        setIsFormTouched={(value) => {
          setIsFormTouched(value);
          setIsFormModified(value);
        }}
      />
    </Form>
  );
};

export const ManualMethod = ({
  children,
  name,
}: {
  children: (form: FormInstance, formData: IFetchFormSubmissionPayload, isFormDisabled: boolean) => IDocumentStep[];
  name: FORM_NAME;
}) => {
  const [form] = Form.useForm();
  const { isFormDisabled } = useStepChangeHandler();

  const formatter: IFetchDataFormatter = useFetchDataFormatter();
  const isMyInfoSource: boolean = formatter.formData?.source === FORM_SOURCE_TYPE.MY_INFO;

  const companyInfoBanners = useCompanyInfoFormBannerSGD(isMyInfoSource);
  const companyDocumentBanners = useCompanyDocumentsFormBannerSGD(isMyInfoSource);
  return (
    <ProgressStep form={form} name={name} formatter={formatter}>
      {name === FORM_NAME.COMPANY_INFORMATION ? (
        <DocumentStep {...companyInfoBanners} />
      ) : name === FORM_NAME.COMPANY_DOCUMENTS ? (
        <DocumentStep {...companyDocumentBanners} />
      ) : null}
      {children(form, formatter.formData, isFormDisabled).map(({ key, ...props }) => {
        return <DocumentStep key={key} {...props} />;
      })}
    </ProgressStep>
  );
};

export * from "./First";
export * from "./Second";
export * from "./Third";
