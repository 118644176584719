import React from "react";

import { AuthBoxLogo } from "..";
import "./styles.scss";

export const AuthSidebar = (): React.ReactElement => {
  return (
    <div className="auth-sidebar">
      <AuthBoxLogo />
      <h1>
        Spenmo gives finance teams <span>authority</span> over how employees spend, so you know exactly where every
        dollar is going.
      </h1>
      <ul>
        <li>
          <a href="https://spenmo.com/legal" target="_blank">
            Terms of Use
          </a>
        </li>
        <li>
          <a href="https://spenmo.com/legal" target="_blank">
            Privacy Policy
          </a>
        </li>
      </ul>
    </div>
  );
};
