import React, { useEffect } from "react";
import { Form } from "antd";
import { Base64 } from "js-base64";
import { useSelector, useDispatch } from "react-redux";

import { loginFunc } from "Redux/Actions";
import { trackEvent } from "utility/analytics";
import AuthPage from "Views/Auth";
import { useOnlineStatus } from "customHooks/useOnlineStatus";
import { FormEmail, FormPassword } from "./Components";

import "../auth.scss";
import { AuthBoxError, AuthButton, AuthContentHeader } from "../Components";
import { AuthPageContextProps } from "../context";

const Login = (): React.ReactElement => {
  const dispatch = useDispatch();
  const isOnline = useOnlineStatus();
  const defaultErrorMessage = "Error, Try again";
  const offlineErrMessage =
    "Looks like you're not connected to the internet. Please check your connection and try again.";
  const errMessage = isOnline ? defaultErrorMessage : offlineErrMessage;
  const { loading, error, errorMessage = errMessage } = useSelector((state: any) => state.loginReducer);

  const trackLoginEvent = (eventName, data = {}) => {
    trackEvent(eventName, data);
  };

  useEffect(() => {
    trackLoginEvent("login screen viewed");
  }, []);

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  const onFinish = (values) => {
    values = { ...values, password: Base64.encode(values.password) };
    trackLoginEvent("Account Authentication Button Clicked", { authentication_event_source: "Login" });
    dispatch(loginFunc(values));
  };

  let context: AuthPageContextProps = {
    error: error,
    loading: false,
    errorMessage: errorMessage,
    appendClassName: "login-box",
  };

  return (
    <AuthPage value={context}>
      <>
        <AuthContentHeader title="Log in to Your Account" />
        <AuthBoxError />
        <Form validateTrigger={false} name="login" onFinish={onFinish} data-testid="login-form">
          <FormEmail />
          <FormPassword />
          <AuthButton loading={loading} text={"Login"} handleKeyPress={handleKeyPress} />
        </Form>
      </>
    </AuthPage>
  );
};

export default Login;
