import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { SUPPORT_CHAT_SEARCH_PARAMS } from "Views/SpenmoSupport/common/SearchParams.constants";

import { CustomTicketFormFunc, ClearCustomTicketFormFunc } from "Redux/Actions";

import Icon from "Modules/icons";
import Button from "Modules/button";

import WidgetHeader from "Views/SpenmoSupport/WidgetHeader";
import { SpenmoSupportContext } from "Views/SpenmoSupport/common";

import ContactTicketForm from "./ContactTicketForm";

import { emailSent } from "assets/img";

const ContactForm = ({ onClose, automatedMsg }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname, search } = history?.location || {};

  const { showSupportChat, setShowSupportChat, toggleSupportAndChat, shifting, supportChatRef } =
    useContext(SpenmoSupportContext);

  const customTicketData = useSelector((state) => state.customTicketFormReducer);

  const userInfo = useSelector((state) => state.userInfoReducer);
  const userData = userInfo?.data?.payload?.user || null;

  const resetChatSearchParams = () => {
    if (
      search?.includes(SUPPORT_CHAT_SEARCH_PARAMS.SHOW_SUPPORT_CHAT) ||
      search?.includes(SUPPORT_CHAT_SEARCH_PARAMS.CHAT_SUPPORT_MESSAGE)
    ) {
      const searchParams = new URLSearchParams(search);
      searchParams.delete(SUPPORT_CHAT_SEARCH_PARAMS.SHOW_SUPPORT_CHAT);
      searchParams.delete(SUPPORT_CHAT_SEARCH_PARAMS.CHAT_SUPPORT_MESSAGE);
      history.push({
        pathname,
        search: searchParams.toString(),
      });
    }
  };

  const createCustomTicket = (values) => {
    dispatch(CustomTicketFormFunc(values, userData));
    /* remove chat support deeplink params once, request is made */
    resetChatSearchParams();
  };

  const handleCustomTicketData = () => {
    dispatch(ClearCustomTicketFormFunc());
  };

  const handleWidgetClose = () => {
    setShowSupportChat(false);
    dispatch(ClearCustomTicketFormFunc());
    onClose && onClose();
    /* remove chat support deeplink params if user closes the chatbox */
    resetChatSearchParams();
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) =>
      showSupportChat && !supportChatRef.current?.contains(e.target) && setShowSupportChat(false);

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [supportChatRef, showSupportChat, setShowSupportChat]);

  const handleFormView = () => {
    if (customTicketData?.error) {
      return (
        <div className="zendesk-contact-form--error">
          <p>{customTicketData?.data?.message}</p>
          <Button textButton text="please try again" action={handleCustomTicketData} />
        </div>
      );
    } else if (customTicketData?.success) {
      return (
        <div className="zendesk-contact-form--success">
          <Icon src={emailSent} alt="email" />
          <h4 className="header">Thanks for reaching out</h4>
          <p className="desc">Someone will get back to you soon</p>
          <Button
            textButton
            text="Go Back"
            action={(event) => {
              onClose ? onClose() : toggleSupportAndChat(event);
              handleCustomTicketData();
            }}
          />
        </div>
      );
    } else {
      return (
        <ContactTicketForm
          automatedMsg={automatedMsg}
          loading={customTicketData?.loading}
          onFinish={createCustomTicket}
        />
      );
    }
  };

  return (
    <div className={`zendesk-contact-form  ${shifting ? "shift-support" : "shift-support-exit"}`} ref={supportChatRef}>
      <WidgetHeader size="large" title="Chat with Spenmo" close={handleWidgetClose} />
      {handleFormView()}
    </div>
  );
};

ContactForm.propTypes = {
  onClose: PropTypes.func,
  automatedMsg: PropTypes.string,
};

export default ContactForm;
