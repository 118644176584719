import { AccountingSetup } from "../Common/types";
import SyncAccount from "./SyncAccount";

export const netsuiteConfig: AccountingSetup[] = [
  {
    label: "Map your accounts",
    index: 0,
    Component: SyncAccount,
    needRefetch: false,
  },
];
