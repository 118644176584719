import React from "react";
import PropTypes from "prop-types";
import { currencyFormatter, dateTimeFormatter, roundValue } from "../../../utility";
import TableRowField from "../../../Modules/tableRowField";
import ImageCarousel from "../../../Modules/ImageCarousel";
import Icon from "../../../Modules/icons";
import { Toaster } from "../../../Modules/toaster";
import Modal from "../../../Modules/modal";
import { left_arrow_grey } from "../../../assets/img";
import Button from "../../../Modules/button";
import ReduxHoc from "../../../Redux/ReduxHOC";
import LoadingComponent from "../../State/Loading/LoaderIcon";
import ErrorComponent from "../../State/Error";
import ExpenseDetailsEdit from "./ExpenseDetailsEdit";
import TransactionEditConfirmation from "./TransactionEditConfirmation";

import "./TransactionDetails.scss";
import { getMerchantName } from "../TransactionsHelper";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import { TRANSACTION_TYPE } from "constants/Transaction.constant";
import SelectedTag from "Modules/AccountingTags/components/SelectedTag";

class TransactionDetails extends React.Component {
  static propTypes = {
    transactionId: PropTypes.string,
    username: PropTypes.string,
    callApiWithLoader: PropTypes.func,
    data: PropTypes.object,
    error: PropTypes.object,
    loading: PropTypes.bool,
    showEdit: PropTypes.bool,
    updateList: PropTypes.func,
    isSideSectionOpen: PropTypes.bool,
    receiptsData: PropTypes.array,
    handleEditTransaction: PropTypes.func,
    trackTransactionDetails: PropTypes.func,
    childTransaction: PropTypes.object,
    openChildTransaction: PropTypes.func,
    transactionType: PropTypes.string,
    onUseLegacyRefundDetail: PropTypes.func,
  };

  state = {
    editConfirmation: {
      show: false,
      message: "",
      data: {},
    },
    showEdit: false,
    showImage: true,
    showToaster: false,
    statusMessage: "",
    activeImageModal: "",
    toasterSuccess: false,
  };

  componentDidMount() {
    this.GetData();
  }

  GetData = () => {
    this.props.callApiWithLoader("getReceiptData", this.props.transactionNumber, this.props.transactionId);
  };

  componentDidUpdate(prevProps) {
    if (this.props.transactionNumber !== prevProps.transactionNumber) {
      this.setState({ showEdit: false, showToaster: false });
      this.GetData();
    }

    if (this.props?.data?.status === HTTP_STATUS_CODE.NOT_FOUND) {
      this.props.onUseLegacyRefundDetail();
    }
  }

  onSubmitResponse = (data, id) => {
    const { updateList } = this.props;
    if (data && data.status === HTTP_STATUS_CODE.OK) {
      this.resetEditConfirmationState();
      this.setState({
        showEdit: false,
        showToaster: true,
        statusMessage: data.payload.status_message,
        toasterSuccess: true,
      });
      this.GetData();
      updateList();
      this.props.trackTransactionDetails("transaction edit success");
    } else {
      this.setState({ showToaster: true, toasterSuccess: false, statusMessage: data.payload.status_message });
    }
  };

  onEditConfirmation = (message, data) => {
    this.setState({
      editConfirmation: {
        show: true,
        message,
        data,
      },
    });
  };

  resetEditConfirmationState = () => {
    this.setState({
      showEdit: true,
      editConfirmation: {
        show: false,
        message: "",
        // We still need this data to update the form when user click cancel on the warning page
        data: this.state.editConfirmation.data,
      },
    });
  };

  getReaceiptImages = () => {
    const { data, receiptsData } = this.props;
    const receiptsList = data?.payload?.expense?.receipts || [];
    const receiptArr = [];
    for (const receipt of receiptsList) {
      receiptArr.push(receipt.url);
    }
    const receiptImageList = receiptArr || receiptsData?.urls;
    return receiptImageList;
  };

  editDetailsHandler = () => {
    this.setState({ showEdit: true });
    this.props.trackTransactionDetails("Transaction Details Edited");
  };

  generateChildTransactionName = () => {
    const { childTransaction } = this.props;
    if (childTransaction.type === TRANSACTION_TYPE.FEE_REFUND) {
      return "refund";
    }

    return childTransaction.type.toLowerCase();
  };

  render() {
    const {
      data,
      loading,
      error,
      username,
      showEdit,
      isSideSectionOpen,
      receiptsData,
      trackTransactionDetails,
      childTransaction,
      openChildTransaction,
      connectedToXero,
      transactionType,
      transactionId,
    } = this.props;
    const { editConfirmation } = this.state;
    const isRefund = transactionType === TRANSACTION_TYPE.REFUND;

    if (loading) {
      return <LoadingComponent />;
    }
    if (error) {
      return <ErrorComponent />;
    }
    const dataSource = data.payload && data.payload.expense;

    if (dataSource) {
      const dt = dateTimeFormatter(dataSource.created_at);
      const tax = dataSource?.tax ? `${dataSource?.tax?.tax_name} (${roundValue(dataSource?.tax?.tax_rate)}%)` : "";

      if (editConfirmation.show) {
        return (
          <TransactionEditConfirmation
            editConfirmation={editConfirmation}
            resetEditConfirmationState={this.resetEditConfirmationState}
            onSubmit={this.onSubmitResponse}
          />
        );
      }

      return (
        <>
          <div className={"transaction-details"}>
            {this.state.showEdit && (
              <Icon
                className={"transaction-details--preview-btn"}
                src={left_arrow_grey}
                action={() => this.setState({ showEdit: false })}
                alt={"previous"}
              />
            )}
            <TableRowField title={getMerchantName(dataSource)} details={`${dt.date} | ${dt.time}`} isBold isSubHeader />
            <div className={"transaction-details__details"}>
              <TableRowField
                title={currencyFormatter(roundValue(dataSource.amount), dataSource.currency_code)}
                isBold
              />
            </div>
            <TableRowField title={"Trx No."} details={dataSource.transaction_id || "N/A"} />
            {childTransaction && (
              <span
                onClick={() => openChildTransaction(childTransaction)}
                className="transaction-details__redirect_link"
              >
                View the {isRefund ? "original" : this.generateChildTransactionName()} transaction
              </span>
            )}
            <TableRowField title={"Reference"} details={dataSource.merchant || "N/A"} />
            <div className={"transaction-details__details"}>
              <TableRowField title={"Transaction by"} details={username} />
              {!this.state.showEdit && <TableRowField title={"Tax"} details={tax} />}
            </div>
            {!this.state.showEdit && (
              <>
                <div className={"transaction-details__details"}>
                  <TableRowField title={"Expense Type"} details={dataSource.spend_type} />
                  <TableRowField title={"Card no."} details={dataSource.card_last_four} />
                </div>
                <div className={"transaction-details__details"}>
                  <TableRowField title={"Foreign Currency Code"} details={dataSource.foreign_currency_code || "N/A"} />
                  <TableRowField
                    title={"Foreign Currency Amount"}
                    details={dataSource.foreign_currency_amount || "N/A"}
                  />
                </div>
                <TableRowField
                  title={"Category"}
                  details={
                    dataSource.expense_category_name === "undefined" ? "No category" : dataSource.expense_category_name
                  }
                />
                {connectedToXero && (
                  <d>
                    <TableRowField
                      title={"Tags"}
                      details={
                        <div className="sp-margin-top--s">
                          {Array.isArray(dataSource.tags) && dataSource.tags.length !== 0
                            ? dataSource.tags.map(({ label, value }, index) => (
                                <SelectedTag editable={false} name={`${label} - ${value}`} key={index} />
                              ))
                            : "-"}
                        </div>
                      }
                    />
                  </d>
                )}
                <TableRowField
                  title={"Notes"}
                  details={
                    dataSource.comment.trim() === "" || dataSource.comment === "undefined"
                      ? "No notes"
                      : dataSource.comment
                  }
                />
                <div className={"transaction-details__receipt-wrapper"}>
                  <label>Receipt</label>
                  <ImageCarousel
                    images={this.getReaceiptImages()}
                    showImagePreview
                    message={receiptsData && receiptsData.count === 0 ? "No Receipts" : ""}
                  />
                </div>
                {showEdit && (
                  <div className={"transaction-details__edit-btn"}>
                    <Button text={"Edit details"} action={this.editDetailsHandler} />
                  </div>
                )}
              </>
            )}
            {this.state.showEdit && (
              <ExpenseDetailsEdit
                // getCategories
                transactionData={dataSource}
                transactionId={transactionId}
                onSubmit={this.onSubmitResponse}
                actionData={[]}
                trackTransactionDetails={trackTransactionDetails}
                onEditConfirmation={this.onEditConfirmation}
                editedData={editConfirmation.data}
                transactionType={transactionType}
              />
            )}
          </div>
          <Toaster
            message={this.state.statusMessage}
            visible={this.state.showToaster}
            primary={isSideSectionOpen}
            closeFunc={() => {
              this.setState({ showToaster: false, statusMessage: "" });
            }}
            status={this.state.toasterSuccess ? "success" : "error"}
          />
          <Modal
            className={"preview-modal"}
            show={this.state.activeImageModal !== ""}
            close={() => this.setState({ activeImageModal: "" })}
          >
            <img src={this.state.activeImageModal} alt="receipt" />
          </Modal>
        </>
      );
    }

    return <div className="card-history--empty-state">No Receipt Data</div>;
  }
}

export default ReduxHoc(TransactionDetails, "transaction-details");
