import APIclient from "API/Client";

interface IChooseXeroOrganisationPayload {
  connectionId: string;
  tenantId: string;
  tenantName: string;
}
interface IAddPartnerBankAccount {
  currencyCode: string;
  name: string;
  number: string;
}

interface IUpdatePartnerPayload {
  partnerBankAccountID: string;
  partnerBankAccountNumber: string;
  partnerBankAccountName: string;
}

export const getXeroOrganisation = () => {
  return APIclient.getData("/ms/b2b-accounting/v1/xero/connections");
};

export const chooseXeroOrganisation = (payload: IChooseXeroOrganisationPayload) => {
  return APIclient.patchData("/ms/b2b-accounting/v2/partners/connections", payload);
};

export const addBankAccountInAccountingPartner = (payload: IAddPartnerBankAccount) => {
  return APIclient.postData("/ms/b2b-accounting/v2/partners/accounts", payload);
};

export const updatePartnerConnection = (payload: IUpdatePartnerPayload) => {
  return APIclient.patchData("/ms/b2b-accounting/v2/partners/connections", payload);
};

export const getPartnerDataStatus = () => {
  return APIclient.getData("/ms/b2b-accounting/v2/partners/dataStatus");
};
