import { useAppSelector } from "hook";
import LoadingComponent from "Views/State/Loading/LoaderIcon";
import React from "react";

const withUseConfigHoc = <P extends object>(Component: React.ComponentType<P>) => {
  const WrappedComponent: React.FC<P> = (props) => {
    const { loading, data } = useAppSelector((state) => state?.orgConfigs);
    if (loading || !data?.payload) {
      return <LoadingComponent />;
    }
    return <Component {...props} />;
  };

  return WrappedComponent;
};

export default withUseConfigHoc;
