import React from "react";
import styles from "Views/ApprovalFlow/SelfApprovalSidePanel/Header/index.module.scss";
import { SidePanelContent } from "Views/ApprovalFlow/SelfApprovalSidePanel/type";

const Header = ({
  activeView,
  setActiveView,
}: {
  activeView: SidePanelContent;
  setActiveView: React.Dispatch<React.SetStateAction<SidePanelContent>>;
}) => {
  return (
    <div className={styles["container"]}>
      {activeView === SidePanelContent.POLICY_CONTENT && (
        <>
          <div className={styles["title"]}>Self Approval Policy</div>
          <div
            className={styles["changelog-history-link"]}
            onClick={() => setActiveView(SidePanelContent.CHANGE_LOG_HISTORY)}
          >
            Changelog History
          </div>
        </>
      )}
      {activeView === SidePanelContent.CHANGE_LOG_HISTORY && <div className={styles["title"]}>Changelog History</div>}
    </div>
  );
};

export default Header;
