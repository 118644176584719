import React from "react";

import Checkbox from "Modules/DS/Atoms/Checkbox";
import { ILineCheckboxProps } from "Modules/DS/Menu/@types";

import { SquareSkeleton } from "./Skeleton";

export const LineCheckbox = ({ id, onClick, loading, selected }: ILineCheckboxProps) => {
  return (
    <SquareSkeleton loading={loading}>
      <Checkbox id={id} checked={selected} onClick={onClick} />
    </SquareSkeleton>
  );
};
