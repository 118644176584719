import {
  FETCH_TEAMS_EXPENSES_LIST_REQUEST,
  FETCH_TEAMS_EXPENSES_LIST_SUCCESS,
  FETCH_TEAMS_EXPENSES_LIST_FAILURE,
  RESET_TEAMS_EXPENSES_LIST_DATA,
} from "Redux/Actions/Teams/ExpensesListAction";
import { userInitialState } from "Redux/InitialState";

export const teamsExpensesListReducer = (
  state = {
    ...userInitialState,
  },
  action = {}
) => {
  switch (action?.type) {
    case FETCH_TEAMS_EXPENSES_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case FETCH_TEAMS_EXPENSES_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action?.data,
        error: false,
      };
    case FETCH_TEAMS_EXPENSES_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        data: action?.data,
        error: true,
      };
    case RESET_TEAMS_EXPENSES_LIST_DATA:
      return {
        ...state,
        ...userInitialState,
      };
    default:
      return state;
  }
};

export const fetchTeamExpensesListData = (state) => state?.teamsExpensesListReducer || {};
