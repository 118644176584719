import React from "react";
import Banner from "Modules/DS/Banner";
import { ICategoryCountBanner } from "Views/Categories/types";

export const generateSentence = (count) =>
  count === 1 ? { linking: "is", word: "category" } : { linking: "are", word: "categories" };

const CategoryCountBanner = ({ count, partnerName }: ICategoryCountBanner) => {
  const sentences = generateSentence(count);

  if (!count) {
    return null;
  }

  return (
    <div className="category-mapping__import-count-new">
      <Banner
        type="info"
        message={`There ${sentences.linking} ${count} new ${sentences.word} imported from ${partnerName}`}
      />
    </div>
  );
};

export default CategoryCountBanner;
