import React, { useState } from "react";
import { playBtn } from "assets/v1.1/icons/Flat";
import Modal from "Modules/DS/Modal";
import style from "./index.module.scss";

const UpsellModal: React.FC = (): JSX.Element => {
  const [showVideo, handleShowVideo] = useState(false);

  return (
    <>
      <Modal close={() => handleShowVideo(false)} visible={showVideo} className={style.video_modal}>
        <video width="1007" height="567" controls autoPlay>
          <source src="assets/Approval-Workflows.mp4" type="video/mp4" title="yt-player" data-testid="yt-player" />
          Your browser does not support the video tag.
        </video>
      </Modal>
      <div className={style.upsell_modal_container}>
        <h1 className={style.heading}>Set Up Your Custom Approval Flows Today</h1>
        <div className={style.content}>
          <div className={style.content_player}>
            <img
              className={style.content_image}
              src="assets/img/approval-workflow-setup.jpeg"
              alt="upsell"
              height={249}
              width={438}
              loading="lazy"
            />
            <button data-testid="play-btn" onClick={() => handleShowVideo(true)} className={style.content_play}>
              <img src={playBtn} alt="play" />
            </button>
          </div>
          <div className={style.content_text}>
            <h4 className={style.content_text_heading}>Have Control Over Your Approvals</h4>
            <p className={style.content_text_para1}>
              Are you tired of inefficient and time-consuming approval processes? Set up customizable approval flows on
              Spenmo according to your company policy. It streamlines processes, reduces turnaround time, and gives back
              to you the control of budget.
            </p>
            <div>
              <p className={style.content_text_subheading}>Custom approval flow allows you to:</p>
              <p id="upsell_step_1" className={style.content_text_para2}>
                <span className={style.content_text_check}>&#10004;</span>
                Customize your approval flows by choosing different conditions including amount, category and budget
              </p>
              <p id="upsell_step_2" className={style.content_text_para2}>
                <span className={style.content_text_check}>&#10004;</span>
                Streamline your approval process by selecting up to three approval steps
              </p>
              <p id="upsell_step_3" className={style.content_text_para2}>
                <span className={style.content_text_check}>&#10004;</span>
                Notify approvers through emails automatically after the requests are submitted
              </p>
              <p id="upsell_step_4" className={style.content_text_para2}>
                <span className={style.content_text_check}>&#10004;</span>
                Monitor the approval progress through the audit trail on the Approval and Transaction page
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default UpsellModal;
