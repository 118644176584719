import * as React from "react";

import Columns from "Views/Transaction/Cards/Columns";
import Actions from "Views/Transaction/Cards/Actions";

import { TRXN_TABLE_CLASS } from "Views/Transaction/Constants";
import { useDetails } from "Views/Transaction/Cards/Hooks/useDetails";
import { TrxnFilterContext } from "Views/Transaction/Context";

import {
  IPageWithTable,
  ITrxnListProps,
  IGetCardsTrxnsData,
  ITrxnFilterContext,
  GET_CARDS_TRXNS_REQUEST_PARAMS,
} from "Views/Transaction/@types";
import { CARDS_TRXNS_TABLE_LIMIT } from "Views/Transaction/Constants/Cards";

import TrxnList from "Views/Transaction/List";
import SidePanel from "Views/Transaction/Cards/SidePanel";
import cn from "classnames";

export const CardsTrxnsList = ({ data, loading, error, total, errorMessage }: IPageWithTable<IGetCardsTrxnsData>) => {
  const { fetchTrxnDetails, record, trxnDetails, detailsProps, actionRef, onCloseHandler } = useDetails();
  const { onApplyFilter }: ITrxnFilterContext = React.useContext(TrxnFilterContext);

  const onPageHandler = (pg: number) => {
    onApplyFilter({
      [GET_CARDS_TRXNS_REQUEST_PARAMS.PAGE]: pg + 1,
    });
  };
  const props: ITrxnListProps<IGetCardsTrxnsData> = {
    data,
    total,
    error,
    loading,
    errorMessage,
    actions: Actions(),
    sidePanelProps: {
      ...detailsProps,
      children: (
        <SidePanel record={record} trxnDetails={trxnDetails} handleSaveRef={actionRef} closeDetails={onCloseHandler} />
      ),
    },
    columns: Columns(),
    rowAction: fetchTrxnDetails,
    dispatchOnPageChange: onPageHandler,
    className: cn(TRXN_TABLE_CLASS.ALL, TRXN_TABLE_CLASS.CARDS),
    pageSize: CARDS_TRXNS_TABLE_LIMIT,
  };
  return <TrxnList<IGetCardsTrxnsData> {...props} />;
};
