import React from "react";
import cn from "classnames";

import { SelectProvider } from "Modules/DS/Select";

import styles from "./Filter.module.scss";

const Filter = ({ children, className }: { children: React.ReactNode; className?: string }) => {
  return (
    <SelectProvider>
      <div className={cn(styles.container, className)}>{children}</div>
    </SelectProvider>
  );
};

export * from "./@types";

export * from "./useFilter";
export * from "./Amount";
export * from "./Quick";
export * from "./Date";
export * from "./More";
export * from "./Item";

export default Filter;
