import React from "react";
import { RuleObject } from "antd/lib/form";
import { StoreValue } from "antd/lib/form/interface";
import { Form, Input } from "antd";
import Banner from "Modules/DS/Banner";
import Icon from "Modules/icons";
import { newTab } from "assets/img";
import styles from "./InvoiceNumber.module.scss";
import { Loader } from "Modules/DS/Modal/Components";
import { DUPLICATE_INVOICE_NUMBER_ERROR_MESSAGE } from "Views/UploadInvoice/const";

interface InvoiceNumberProps {
  label: React.ReactNode;
  isValid: boolean;
  onTrackView: (fieldName: string, value?: any) => void;
  onTrackEdit: (fieldName: string, value?: any) => void;
  onChange: (value: string) => void;
  bannerLink?: string;
  isLoading?: boolean;
  isTransactionAccessible?: boolean;
}

const InvoiceNumber = ({
  label,
  isValid,
  onTrackEdit,
  onTrackView,
  onChange,
  bannerLink,
  isLoading,
  isTransactionAccessible,
}: InvoiceNumberProps) => {
  const validator = (_: RuleObject, value: StoreValue): Promise<void> =>
    !value || isValid ? Promise.resolve() : Promise.reject(new Error(DUPLICATE_INVOICE_NUMBER_ERROR_MESSAGE));

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value);
  const reviewLink = (
    <a target="_blank" href={bannerLink} rel="noreferrer">
      Review past bills
      <Icon src={newTab} className={styles["new-tab"]} />
    </a>
  );

  let duplicateInvoiceErrMsg = DUPLICATE_INVOICE_NUMBER_ERROR_MESSAGE,
    bannerMessage = "Review past bills to make sure you are not submitting a duplicate invoice";

  if (!isTransactionAccessible) {
    duplicateInvoiceErrMsg =
      "Invoice number already exists for this recipient. Please enter a new invoice number to proceed or contact admin to review past bills.";
    bannerMessage = "Contact admin to review past bills and make sure you are not submitting a duplicate invoice";
  }

  const formItemValidatorHelp = (
    <>
      {duplicateInvoiceErrMsg} {isTransactionAccessible && reviewLink}
    </>
  );

  // The empty component is there to avoid antd input component losing focus on suffix change
  const inputLoader = isLoading ? <Loader className="custom-loader" /> : <></>;

  return (
    <Form.Item label={label} className={styles["invoice-number"]}>
      <Form.Item name="invoiceNumber" rules={[{ validator: validator }]} help={!isValid && formItemValidatorHelp}>
        <Input
          placeholder="Type invoice number"
          onChange={onChangeInput}
          onFocus={() => onTrackView("invoiceNumber")}
          onBlur={() => onTrackEdit("invoiceNumber")}
          suffix={inputLoader}
        />
      </Form.Item>
      {isValid && (
        <p className={styles["invoice-number-hint"]}>
          If you do not enter an invoice number, we will automatically generate one
        </p>
      )}
      {bannerLink && isValid && (
        <Banner
          type="attention"
          message={
            <>
              <h3 className={styles["banner-title"]}>Similar Invoice(s) Detected</h3>
              <p className={styles["banner-message"]}>{bannerMessage}</p>
              {isTransactionAccessible && <div>{reviewLink}</div>}
            </>
          }
        />
      )}
    </Form.Item>
  );
};

export default InvoiceNumber;
