import React from "react";
import { xero_logo } from "assets/img/integrations/xero";
import { plural } from "utility";

import styles from "./styles.module.scss";

const Banner = ({ count }: { count: number }) => {
  return (
    <div className={styles.banner}>
      <img src={xero_logo} alt="Xero Logo" className={styles.image} />
      <div className={styles.message}>
        <p>
          <span className={styles.importCount}>{count}</span> bill {plural(count, "payment", "s", false)} on Xero
          awaiting import to Spenmo.
        </p>
        <p>Only bills awaiting payment and approvals are retrieved for import from your Xero account.</p>
      </div>
    </div>
  );
};

export default Banner;
