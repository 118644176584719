import axios from "axios";
import { getCookieValue, setCookie, cookieNames } from "utility/CookieHelper";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import { ERROR_STATUS_CODE } from "constants/ErrorStatusCode.constants";

const API_BASE_URL = window.__ENV__.API_BASE_URL;

const DEFAULT_HEADERS = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

export const API = axios.create({
  baseURL: API_BASE_URL,
  headers: { ...DEFAULT_HEADERS },
});

API.defaults.timeout = 30000;

// Handle request logic before serving it to Server
API.interceptors.request.use((config) => {
  const ACCESS_TOKEN = getCookieValue(cookieNames.AUTH_TOKEN);
  try {
    config.headers.Authorization = `Bearer ${ACCESS_TOKEN}`;
    return config;
  } catch (err) {
    return Promise.reject(err);
  }
});

const getRefreshToken = async () => {
  const result = axios.post(
    `${API_BASE_URL}/ms/b2b-auth/v2/auth/refresh`,
    {},
    {
      headers: {
        Authorization: `Bearer ${getCookieValue(cookieNames.REFRESH_TOKEN)}`,
      },
    }
  );
  return result;
};

// Handle response logic before serving it to Client
API.interceptors.response.use(
  (res) => {
    return res;
  },
  async (failedRequest = {}) => {
    const originalConfig = failedRequest?.config || {};
    const { status = "", data = {} } = failedRequest?.response || {};
    const code = data?.errors?.[0]?.code;
    // handle case when access token expires.
    if (
      status === HTTP_STATUS_CODE.UNAUTHORIZED &&
      code === ERROR_STATUS_CODE.INVALID_ACCESS_TOKEN &&
      !originalConfig?._retry
    ) {
      originalConfig._retry = true;
      try {
        const refreshToken = await getRefreshToken();
        const {
          data: {
            data: { access_token, refresh_token },
          },
        } = refreshToken;

        setCookie(cookieNames.AUTH_TOKEN, access_token);
        setCookie(cookieNames.REFRESH_TOKEN, refresh_token);

        failedRequest.response.config.headers["Authorization"] = `Bearer ${access_token}`;
        return API(originalConfig);
      } catch (error) {
        const { status = "", data = {} } = error?.response || {};
        const code = data?.errors?.[0]?.code;

        // Handle case when refresh token expires.
        if (status === HTTP_STATUS_CODE.UNAUTHORIZED && code === ERROR_STATUS_CODE.INVALID_REFRESH_TOKEN) {
          setCookie(cookieNames.EXPIRED, true);
          return Promise.reject(error);
        }
      }
    }

    return Promise.reject(failedRequest);
  }
);
