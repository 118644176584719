import APIclient from "API/Client";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import { updateTeam } from "Redux/Actions";
import { getTeamProfileData } from "Redux/Actions/Teams/teamProfileAction";
import { GetOrgId } from "utility";

export const ADD_TEAM_MEMBER = "ADD_TEAM_MEMBER";
export const ADD_TEAM_MEMBER_REQUEST = `${ADD_TEAM_MEMBER}_REQUEST`;
export const ADD_TEAM_MEMBER_SUCCESS = `${ADD_TEAM_MEMBER}_SUCCESS`;
export const ADD_TEAM_MEMBER_FAILURE = `${ADD_TEAM_MEMBER}_FAILURE`;
export const RESET_ADD_TEAM = "RESET_ADD_TEAM";

const addTeamMemberAddReq = () => {
  return {
    type: ADD_TEAM_MEMBER_REQUEST,
    data: {},
  };
};
const addTeamMemberSuccess = (data) => {
  return {
    type: ADD_TEAM_MEMBER_SUCCESS,
    data: data,
  };
};
const addTeamMemberError = (data) => {
  return {
    type: ADD_TEAM_MEMBER_FAILURE,
    data: data,
  };
};
export const resetAddTeamData = () => {
  return {
    type: RESET_ADD_TEAM,
  };
};

export const addTeamMember = (data, teamId) => {
  return (dispatch) => {
    dispatch(addTeamMemberAddReq());
    const orgId = GetOrgId();
    APIclient.postData(`/api/v1/org/${orgId}/team/${teamId}/member/`, data)
      .then((data) => {
        if (data.data.status > HTTP_STATUS_CODE.OK) {
          dispatch(addTeamMemberError(data.data));
        } else {
          dispatch(addTeamMemberSuccess(data.data));
        }
      })
      .then(() => {
        dispatch(updateTeam());
        dispatch(getTeamProfileData(teamId));
      })
      .catch((err) => {
        dispatch(addTeamMemberError(err));
      });
  };
};
