import React, { useContext } from "react";
import { Col, Form, InputNumber } from "antd";
import PropTypes from "prop-types";

import { FiltersAPI, FilterComponents } from "Modules/Filters";

export const InputRangeFilter = ({
  title,
  minLabel,
  maxLabel,
  minEventName,
  maxEventName,
  minRangeFormItem,
  maxRangeFormItem,
  minInputPlaceholder,
  maxInputPlaceholder,
  appendClassName,
}) => {
  const api = useContext(FiltersAPI);

  return (
    <FilterComponents.FilterItemWrapper appendClassName={appendClassName} title={title}>
      <Col span={6}>
        <Form.Item name={minRangeFormItem} label={minLabel}>
          <InputNumber
            placeholder={minInputPlaceholder}
            onBlur={() => api.handleInputOnBlurActions(minRangeFormItem, minEventName)}
          />
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item name={maxRangeFormItem} label={maxLabel}>
          <InputNumber
            placeholder={maxInputPlaceholder}
            onBlur={() => api.handleInputOnBlurActions(maxRangeFormItem, maxEventName)}
          />
        </Form.Item>
      </Col>
    </FilterComponents.FilterItemWrapper>
  );
};

InputRangeFilter.propTypes = {
  title: PropTypes.string,
  minLabel: PropTypes.string,
  maxLabel: PropTypes.string,
  minEventName: PropTypes.string,
  maxEventName: PropTypes.string,
  minRangeFormItem: PropTypes.string,
  maxRangeFormItem: PropTypes.string,
  minInputPlaceholder: PropTypes.string,
  maxInputPlaceholder: PropTypes.string,
  appendClassName: PropTypes.string,
};
