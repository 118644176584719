import React, { useContext } from "react";
import { Col, Form } from "antd";
import PropTypes from "prop-types";

import DatePicker from "Modules/datePicker";
import { FiltersAPI, analytics, FilterComponents } from "Modules/Filters";

export const DateFilter = ({ title, startDateInitialValue, endDateInitialValue }) => {
  const api = useContext(FiltersAPI);

  return (
    <FilterComponents.FilterItemWrapper appendClassName="date" title={`${title} Date`}>
      <Col span={6}>
        <Form.Item name="start_date" label="start date">
          <DatePicker
            id="startDate"
            action={(date) => api.handleDateChange(date, "start_date", analytics.startDateSelected)}
            showPastDates
            placeholder="Start Date"
            picker="date"
            initialValue={startDateInitialValue}
          />
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item name="end_date" label="end date">
          <DatePicker
            id="endDate"
            action={(date) => api.handleDateChange(date, "end_date", analytics.endDateSelected)}
            showPastDates
            placeholder="End Date"
            picker="date"
            initialValue={endDateInitialValue}
          />
        </Form.Item>
      </Col>
    </FilterComponents.FilterItemWrapper>
  );
};

DateFilter.propTypes = {
  title: PropTypes.string,
  endDateInitialValue: PropTypes.string,
  startDateInitialValue: PropTypes.string,
};
