const fill_white_32 = require("./fill_white_32.svg");
const fill_orange_32 = require("./fill_orange_32.svg");

export const Help = {
  Fill: {
    White: {
      32: fill_white_32,
    },
    Orange: {
      32: fill_orange_32,
    },
  },
};

export const help = require("./outline.svg") + "#outline";
