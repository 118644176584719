import React, { useContext, useEffect, useMemo, useRef, useState } from "react";

import { AxiosResponse } from "axios";

import styles from "./index.module.scss";
import { useMutableData } from "API/useData";
import { ActionsBar } from "Modules/DS/DataTable";
import Actions from "Views/Bills/V2/PaymentRun/Actions";
import { ExportCycleContext } from "Views/Bills/V2/PaymentRun/context";
import { updatePaymentCycleState } from "Views/Bills/V2/PaymentRun/helper";
import PaymentRunShimmer from "Views/Bills/V2/PaymentRun/PaymentRunShimmer";
import PaymentCycleCard from "Views/Bills/V2/PaymentRun/PaymentCycleCard/index";
import { PermissionAndSettingsContext } from "Views/Bills/V2/context/BillContext";
import {
  IPaymentCycleData,
  IPaymentCycleResponse,
} from "Views/Bills/V2/PaymentRun/types";
import {
  Payment_Run_API_URL,
  initialCycleResponse,
} from "Views/Bills/V2/PaymentRun/constant";
import BillSidepanel from "../components/BillSidepanel";
import { useLocation } from "react-router-dom";
import qs from "query-string";

const PaymnentCycleView = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [exportCycleId, setExportCycleId] = useState<number>(null);
  const [allCycles, setAllCycles] = useState<IPaymentCycleData[]>(null);

  const { permissionLoading, settingLoading: isPaymentRunSettingLoading } =
    useContext(PermissionAndSettingsContext);
  const { data, isValidating: isLoading } = useMutableData(
    Payment_Run_API_URL.paymentCycles,
  );
  const response = data as AxiosResponse<IPaymentCycleResponse>;
  const { data: cycleData } = response ?? initialCycleResponse;
  const { payload } = cycleData;
  const { passed, upcoming } = payload;
  const location = useLocation();
  const { invoiceid } = qs.parse(location.search);
  const [showSidePanel, setShowSidePanel] = useState(false);

  useEffect(() => {
    if (invoiceid) {
      setShowSidePanel(true);
    }
  }, [invoiceid]);

  useEffect(() => {
    setAllCycles(updatePaymentCycleState(passed, upcoming));
  }, [passed, upcoming]);

  const exportContext = useMemo(
    () => ({ cycleId: exportCycleId, setCycleId: setExportCycleId }),
    [exportCycleId],
  );
  const showLoading =
    isLoading || isPaymentRunSettingLoading || permissionLoading;
  return (
    <ExportCycleContext.Provider value={exportContext}>
      <div className={styles.container}>
        <div className={styles.actions}>
          <ActionsBar>{Actions()}</ActionsBar>
        </div>
        <div
          className={styles.cyclesContainer}
          ref={containerRef}
          data-testid="payment-cycle-container"
        >
          {allCycles?.map(
            ({ id, name, state, status, statusDetails, isActive, date }) => (
              <PaymentCycleCard
                key={id}
                id={id}
                name={name}
                state={state}
                status={status}
                statusDetails={statusDetails}
                cycles={allCycles}
                date={date}
                isActive={isActive}
                containerRef={containerRef}
              />
            ),
          )}
          {showLoading && <PaymentRunShimmer />}
        </div>
      </div>
      <BillSidepanel
        id={invoiceid as string}
        visible={showSidePanel}
        onClose={() => setShowSidePanel(false)}
      />
    </ExportCycleContext.Provider>
  );
};

export default PaymnentCycleView;
