import React from "react";

import { InputCell } from "Views/Transaction/Shimmer";
import { CellWithSubtitle, MultiLineTitle } from "Views/Transaction/List";

import { IColumnData } from "Modules/DS/DataTable";
import { IGetCardsTrxnsData, CARDS_TRXNS_TABLE_COLUMNS, GET_CARDS_TRXNS_DATA_PARAMS } from "Views/Transaction/@types";

export const cardTitle = (): IColumnData<IGetCardsTrxnsData>["column"] => ({
  title: (
    <MultiLineTitle
      title={CARDS_TRXNS_TABLE_COLUMNS.CARD_TITLE}
      subtitle={CARDS_TRXNS_TABLE_COLUMNS.CARD_LAST_FOUR}
      captionSubtitle
    />
  ),
  dataIndex: GET_CARDS_TRXNS_DATA_PARAMS.CARDHOLDER_NAME,
  key: GET_CARDS_TRXNS_DATA_PARAMS.CARDHOLDER_NAME,
  width: 162,
  render: (_, record: IGetCardsTrxnsData): React.ReactNode => (
    <InputCell>
      <CellWithSubtitle title={record?.card_name} subtitle={`****${record?.card_last_four}`} />
    </InputCell>
  ),
});
