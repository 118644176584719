import React from "react";
import classNames from "classnames";
import { Tooltip, TooltipArrowAlignmentEnum, TooltipPositionEnum } from "Modules/DS/Tooltip";
import CheckboxWrapper from "Views/Card/Components/CheckboxWrapper";
import { Info } from "assets/v1.1/icons/Core/Info";

import styles from "./index.module.scss";

interface ICategoryLockItem {
  name: string;
  description: string;
  icon: string;
  iconImageAlt: string;
  onClick: (checked: boolean) => void;
  checked?: boolean;
  isRestricted?: boolean;
}

const CategoryLockItem = ({
  name,
  description,
  icon,
  iconImageAlt,
  onClick,
  checked = false,
  isRestricted = false,
}: ICategoryLockItem) => {
  return (
    <CheckboxWrapper
      checked={checked}
      name={name.replace(/ /g, "_")}
      className={classNames([styles.categoryLockItem, { [styles.categoryLockItemDisabled]: isRestricted }])}
      onClick={onClick}
      disabled={isRestricted}
    >
      <img src={icon} alt={iconImageAlt} className={styles.categoryLockItemImage} />
      <p className={styles.categoryLockItemText}>
        <span className={styles.categoryLockItemTextTitle}>
          {name}
          {isRestricted ? (
            <Tooltip
              position={TooltipPositionEnum.TOP}
              alignArrow={TooltipArrowAlignmentEnum.CENTER}
              text="We automatically block this category for you"
            >
              <img src={Info.Fill["grey_16"]} height={16} width={16} alt="info icon" />
            </Tooltip>
          ) : null}
        </span>
        <span className={styles.categoryLockItemTextDescription}>{description}</span>
      </p>
    </CheckboxWrapper>
  );
};

export default CategoryLockItem;
