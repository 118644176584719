import * as React from "react";
import { useDispatch } from "react-redux";
import { activatePhysicalCard } from "Redux/DataCalls/Cards.api";
import { getCardNumbersFunc, getCardOwnerNamesFunc } from "Redux/Actions";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import { CARD_LIST_TABS } from "Views/Card/List/types";
import { trackActivatePhysicalCard } from "Redux/Actions/CreateCard";
import { showSuccessNotification, showErrorNotification } from "Views/Card/Components/Notification";
import ActivateCard from "Views/Card/Add/ActivateCardModal";

interface IshowCardActivateModalDetails {
  id: string;
  visible: boolean;
}

type ActivateModalType = JSX.Element | null;

type useCardActivateModalType = (
  getCardListData: (page: number, view?: string | string[]) => void
) => [(cardId: string) => void, string[], ActivateModalType];

export const useCardActivateModal: useCardActivateModalType = (getCardListData) => {
  const dispatch = useDispatch();
  const [showCardActivateModalDetails, setShowCardActivateModalDetails] =
    React.useState<IshowCardActivateModalDetails | null>({
      id: "",
      visible: false,
    });
  const [activationInProgressCards, setActivationInProgressCards] = React.useState<string[]>([]);
  const handleError = (statusMessage) => {
    showErrorNotification(statusMessage);
    trackActivatePhysicalCard(false);
  };
  let activateModal: ActivateModalType = null;
  const onActivationConfirmWithLastFour = (cardLastFour: string, cardId: string) => {
    if (activationInProgressCards?.indexOf(cardId) !== -1) return;
    setActivationInProgressCards((prevState) => [...prevState, cardId]);
    setShowCardActivateModalDetails(null);
    activatePhysicalCard(cardLastFour)
      .then(({ data }) => {
        const { status_message = "" } = data?.payload || {};
        if (data?.status === HTTP_STATUS_CODE.OK) {
          showSuccessNotification("You have successfully synced a physical card!");
          getCardListData(0, CARD_LIST_TABS.CARD_LIST);
          dispatch(getCardNumbersFunc());
          dispatch(getCardOwnerNamesFunc());
          trackActivatePhysicalCard(true);
        } else {
          handleError(status_message);
        }
      })
      .catch((err) => {
        const { status_message = "Something went wrong! Please try again later." } = err?.response?.data?.payload || {};
        handleError(status_message);
      })
      .finally(() => {
        setActivationInProgressCards((prevState) => [...(prevState || [])]?.filter((id) => id !== cardId));
      });
  };

  if (showCardActivateModalDetails?.visible) {
    activateModal = (
      <ActivateCard
        onClose={() => setShowCardActivateModalDetails(null)}
        onActivateCard={(cardLastFour) =>
          onActivationConfirmWithLastFour(cardLastFour, showCardActivateModalDetails?.id)
        }
      />
    );
  }

  const setShowCardActivateModal = (cardId: string) => {
    if (activationInProgressCards.indexOf(cardId) !== -1) return;
    setShowCardActivateModalDetails({
      visible: true,
      id: cardId,
    });
  };

  return [setShowCardActivateModal, activationInProgressCards, activateModal];
};
