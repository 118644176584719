import { useCallback, useRef, useState } from "react";
import axios, { CancelTokenSource } from "axios";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import { DEFAULT_ERROR_MESSAGE } from "Views/Transaction/Constants";
import { searchMerchantList } from "Views/Transaction/DataCalls";

export const useMerchantSearch = () => {
  const latestRequest = useRef<CancelTokenSource>(null);

  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const [data, setData] = useState<string[]>([]);

  const resetData = () => setData([]);

  const fetchMerchants = useCallback((value: string) => {
    if (latestRequest.current) {
      latestRequest.current.cancel("Invalidating previous requests");
    }

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    latestRequest.current = source;

    setLoading(true);
    setError(false);
    setErrorMessage("");

    if (!value) {
      setData([]);
      setError(false);
      setLoading(false);
      setErrorMessage("");
      return;
    }

    searchMerchantList(value, source)
      .then((resp) => {
        if (resp.status > HTTP_STATUS_CODE.OK) {
          setError(true);
          setErrorMessage(resp.data.payload.status_message || DEFAULT_ERROR_MESSAGE);
          setData([]);
        } else {
          setError(false);
          setData(resp.payload.merchants);
        }
      })
      .catch((error) => {
        setError(true);
        setData([]);
        setErrorMessage(error.message || DEFAULT_ERROR_MESSAGE);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return { error, loading, data, fetchMerchants, resetData, errorMessage };
};
