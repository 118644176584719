export const SPLIT_NAMES = {
  admin_wallet_activation: "admin_wallet_activation",
  approvalFlow: "approval_flow",
  newTransactionType: "new_transaction_type",
  centralisedApprovalReimbursement: "centralised_approval_reimbursement",
  aryadanaLogo: "aryadana_logo",
  centralisedApproval: "centralised_approval_bill",
  categoryLock: "category_lock",
  importBillFromXero: "import_bill_from_xero",
  billFeeRealtimeDeduction: "bill_fee_realtime_deduction",
  selfApprovalPolicy: "self_approval_policy",
  crManagePayout: "cr_manage_payout",
  analyticsFeatureFlag: "analytics_feature_flag",
  cardsTrxns: "cards_trxns",
  cardsDailyLimit: "cards_daily_limit",
  advanceExpenseIntegration: "advance_expense_integration",
  showAccountingToggleFeature: "show_accounting_toggle_feature",
  removeEditableMechantNameField: "remove_editable_merchant_name_field",
  migratingAdvancedExpenseCustomer: "migrating_advanced_expense_customer",
  customCSVWithReportingFilter: "custom_csv_to_use_reporting_filter",
  billNewCR: "bill_cr_v2",
  removeCardsColorSelection: "remove_card_selection",
  subscriptionManagement: "subscription_management",
  billPaymentRunPage: "bill_payment_run",
  paynowCRAdhoc: "cr_pay_adhoc",
  upsellingCard: "upselling_card",
  viewReportsPage: "view_reports_page",
};

export const SPLIT_TREATMENT_TYPES = {
  CONTROL: "control", // loading
  ON: "on",
  OFF: "off",
};
