import React from "react";
import { lockOrangeIcon, noSmsOrangeIcon, noInternetOrangeIcon } from "assets/img";

export const GOOGLE_AUTHENTICATOR_INFO = [
  {
    title: "Why use Google Authenticator?",
    info: [
      {
        iconSrc: lockOrangeIcon,
        iconAlt: "lock icon",
        text: "Better security for your Spenmo account",
      },
      {
        iconSrc: noSmsOrangeIcon,
        iconAlt: "no sms icon",
        text: "No need to wait for SMS",
      },
      {
        iconSrc: noInternetOrangeIcon,
        iconAlt: "no internet icon",
        text: "No internet or signal needed",
      },
    ],
  },
  {
    title: "How does it work?",
    info: [
      {
        text: (
          <>
            Install google authenticator apps on your smartphone. You can download the app on{" "}
            <a
              href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en_IN&gl=US"
              target="_blank"
              rel="noreferrer"
            >
              Google Playstore
            </a>{" "}
            (Android) or{" "}
            <a href="https://apps.apple.com/us/app/google-authenticator/id388497605" target="_blank" rel="noreferrer">
              App Store
            </a>{" "}
            (iOS).
          </>
        ),
      },
      {
        text: "Activate Google Authenticator feature on your Spenmo account.",
      },
      {
        text: "Use Google Authenticator everytime you login to your Spenmo account.",
      },
    ],
  },
];
