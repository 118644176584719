import { useMemo } from "react";
import { useMutableData } from "API/useData";
import qs from "query-string";

import { Separator } from "Views/Bills/V2/PaymentRun/helper";
import ToggleView from "Views/Bills/V2/PaymentRun/ToggleView";
import { API_URL, SUBMITTED_BILLS_VIEW } from "Views/Bills/V2/constants";
import { ALIGN_ACTION_ITEM, IActionItem } from "Modules/DS/DataTable";
import { BillsTrxnFilters } from "Views/Transaction/Bills/Actions/Filter";
import { BillsTrxnsDownload } from "Views/Transaction/Bills/Actions/BillsTrxnsDownload";

const Actions = (): IActionItem[] => {
  const billpayConfigURL = qs.stringifyUrl({
    url: API_URL.billpayConfig,
    query: {
      view: "payment-run",
    },
  });

  const { data: billpayConfig } = useMutableData(billpayConfigURL);

  const { filterStatuses } = useMemo(() => billpayConfig?.data?.payload || {}, [billpayConfig]);

  return [
    {
      id: "BILLS-PYAMENT_RUN-ACTION-1",
      enabled: true,
      align: ALIGN_ACTION_ITEM.LEFT,
      component: () =>
        BillsTrxnFilters({
          statuses: filterStatuses?.map((item) => item.label),
          withUnscheduledFilter: false,
        }),
    },
    {
      id: "BILLS-PYAMENT_RUN-ACTION-2",
      enabled: true,
      align: ALIGN_ACTION_ITEM.RIGHT,
      component: () =>
        ToggleView({ currentView: SUBMITTED_BILLS_VIEW.PAYMENT_RUN, nextView: SUBMITTED_BILLS_VIEW.DEFAULT }),
    },
    {
      id: "BILLS-PYAMENT_RUN-ACTION-3",
      enabled: true,
      align: ALIGN_ACTION_ITEM.RIGHT,
      component: Separator,
    },
    {
      id: "BILLS-PYAMENT_RUN-ACTION-4",
      enabled: true,
      align: ALIGN_ACTION_ITEM.RIGHT,
      component: (): JSX.Element => {
        return BillsTrxnsDownload({});
      },
    },
  ].filter((item) => item.enabled);
};

export default Actions;
