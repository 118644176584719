import React from "react";
import "./styles.scss";
import { AuthContentHeader } from "../AuthContentHeader";
import { AuthButton } from "../AuthButton";
import { useHistory } from "react-router-dom";
import { LOGIN_PATH } from "constants/Routes.constants";
import { handleTimer } from "Views/EmployeeOTP/utils";
import { useOTPTimeoutHandler } from "Views/EmployeeOTP/useOTPTimeoutHandler";
import { OTP_UNBLOCK_TIME } from "constants/Auth.constant";

export const FailedAttempts = (): React.ReactElement => {
  const history = useHistory();

  const counter = useOTPTimeoutHandler();

  /*
    the reason behind window.location.reload() is => we are using this component for both SMS/mail otp and GAuth
    for the SMS/mail flow we have /otp page
    for GAuth we don't have separate router, instead we used GACodeVerification component
  */
  // if the otp count down is less than 300 seconds we need to show OTP page
  if (!!counter && counter < OTP_UNBLOCK_TIME) window.location.reload();

  return (
    <>
      <div className="employee-otp-verification">
        <AuthContentHeader
          title="Too Many Failed Attempts"
          description={`Please wait for ${handleTimer(counter)} to try again`}
        />
        <AuthButton text={"Back to Login"} handleKeyPress={() => history.push(LOGIN_PATH)} />
      </div>
    </>
  );
};

export const FailedAttemptsG2FA = (): React.ReactElement => {
  return (
    <>
      <div className="employee-otp-verification">
        <AuthContentHeader title="Too Many Failed Attempts" description={`Please wait for 29 minutes to try again`} />
        <AuthButton text={"Back to Login"} handleKeyPress={() => window.location.reload()} />
      </div>
    </>
  );
};
