import APIclient from "API/Client";
import { GetFormData } from "utility/APIWrapper";
import { GetOrgId } from "utility";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import { EditCardEventNames } from "Views/Card/Analytics";
import { trackEvent } from "utility/analytics";

export const FETCH_CARD_ACTIVATE_REQUEST = "FETCH_CARD_ACTIVATE_REQUEST";
export const FETCH_CARD_ACTIVATE_SUCCESS = "FETCH_CARD_ACTIVATE_SUCCESS";
export const FETCH_CARD_ACTIVATE_FAILURE = "FETCH_CARD_ACTIVATE_FAILURE";
export const RESET_CARD_ACTIVATE_DATA = "RESET_CARD_ACTIVATE_DATA";

export const fetchDataReq = (data) => {
  return {
    type: FETCH_CARD_ACTIVATE_REQUEST,
    data: data,
  };
};
export const fetchDataSuccess = (data) => {
  return {
    type: FETCH_CARD_ACTIVATE_SUCCESS,
    data: data,
  };
};
export const fetchDataError = (data) => {
  return {
    type: FETCH_CARD_ACTIVATE_FAILURE,
    data: data,
  };
};

export const clearCardActivateData = () => ({
  type: RESET_CARD_ACTIVATE_DATA,
});

export const trackCardStatusChange = (isSuccess) => {
  trackEvent(EditCardEventNames.CARD_STATUS_PROCESSED, {
    card_process_success: isSuccess,
  });
};

export const cardActivateFunc = (cardData, status) => {
  const orgId = GetOrgId();
  return (dispatch) => {
    dispatch(fetchDataReq());
    trackEvent(EditCardEventNames.CARD_SETTINGS_EDITED, {
      card_settings_event_source: "Status",
    });
    APIclient.postData(
      `/api/v1/org/card/${cardData?.id}/status/update`,
      GetFormData({ status: status, organisation_id: orgId })
    )
      .then((response) => {
        const { data = {} } = response || {};
        if (data?.status === HTTP_STATUS_CODE.OK) {
          dispatch(fetchDataSuccess(data));
          trackCardStatusChange(true);
        } else {
          dispatch(fetchDataError(data));
          trackCardStatusChange(false);
        }
      })
      .catch((err) => {
        dispatch(fetchDataError(err));
        trackCardStatusChange(false);
      });
  };
};
