import React from "react";
import classNames from "classnames";
import Icon from "Modules/icons";

import { Cross } from "assets/v1.1/icons/Navigation/Cross";
import { Check } from "assets/v1.1/icons/Core/Check";
import { ActionButtonProps, BUTTON_SIZES } from "../types";
import styles from "../index.module.scss";
import actionStyles from "./index.module.scss";
import Loader from "Modules/DS/Loader";
import { LOADER_SIZES, LOADER_TYPES } from "Modules/DS/Loader/types";

export const ActionButton = ({
  children,
  disabled,
  loading,
  size = BUTTON_SIZES.MEDIUM,
  danger,
  onClick,
}: ActionButtonProps): JSX.Element => {
  const _classNames = classNames(styles.button, styles[size], actionStyles[size], {
    [actionStyles.disabled]: disabled,
    [styles.loading]: loading,
    [actionStyles.success]: !danger,
    [actionStyles.danger]: danger,
  });
  const LoaderSize = [BUTTON_SIZES.EXTRA_LARGE, BUTTON_SIZES.LARGE].includes(size)
    ? LOADER_SIZES.BIG
    : LOADER_SIZES.SMALL;
  const LoaderType = danger ? LOADER_TYPES.DANGER : LOADER_TYPES.SUCCESS;

  const handleIcon = () => {
    switch (true) {
      case danger && !disabled:
        return Cross.Fill.red_18;
      case disabled && danger:
        return Cross.Fill.white_18;
      case disabled && !danger:
        return Check.Fill.white_18;
      default:
        return Check.Fill.green_18;
    }
  };
  const btnIcon = handleIcon();

  return (
    <button disabled={disabled} onClick={onClick} className={_classNames}>
      {!loading && (
        <Icon
          className={classNames(styles.icon, actionStyles.actionIcon)}
          src={btnIcon}
          alt={danger ? "cross" : "check"}
        />
      )}
      {!loading && <Icon className={actionStyles.activeIcon} src={Cross.Fill.dark_red_18} alt={"cross"} />}
      {loading ? <Loader size={LoaderSize} type={LoaderType} /> : children}
    </button>
  );
};

export default ActionButton;
