import React from "react";
import PropTypes from "prop-types";
import Icon from "../icons";
import { cameraIcon, upload } from "../../assets/img";
import { UploadFile } from "../../Redux/DataCalls/UploadImage.api";
import { Upload, Modal } from "antd";
import { appNotification } from "Modules/appNotification/appNotification";
import "./uploadImage.scss";

const UPLOAD_LIMIT_SIZE = 12; // in MB

class UploadImage extends React.Component {
  state = {
    fileList: [],
    previewVisible: false,
  };

  static propTypes = {
    fileList: PropTypes.any,
    multipleUpload: PropTypes.bool,
    isDrag: PropTypes.bool,
    images: PropTypes.array,
  };

  componentDidMount() {
    this.existingImages(this.props?.images);
  }

  existingImages = (savedImages) => {
    if (savedImages) {
      let images = [];
      images = savedImages.map((file, index) => ({ url: file, status: "done", uid: index }));
      this.setState({ fileList: images });
    }
  };

  greaterThanLimit = (size) => size / 1024 / 1024 >= UPLOAD_LIMIT_SIZE;

  imageHandler = ({ file, fileList }) => {
    if (this.greaterThanLimit(file.size)) {
      appNotification.error({
        message: "File must smaller than 12MB!",
      });
      return;
    }
    let imgUrls = [];
    imgUrls = fileList && fileList.map((file) => file.xhr || file.url).filter((x) => x);
    this.props.fileList(imgUrls ? imgUrls : []);
    this.setState({ fileList });
  };

  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = async (file) => {
    this.setState({
      previewImage: file.xhr,
      previewVisible: true,
    });
  };

  uploadButton = (isDrag, images) =>
    isDrag && images.length < 1 ? (
      <div className={"upload-image--drag__placeholder"}>
        <span>
          <Icon src={upload} />
          We accept JPG, PNG, GIF and PDF files.
        </span>
      </div>
    ) : (
      <div className={"upload-image--select-card"}>
        <Icon className={"upload-image__camera-icon"} src={cameraIcon} />
        <div className="ant-upload-text">Add Photo</div>
      </div>
    );

  customRequest = ({ onSuccess, onError, file }) => {
    UploadFile(file)
      .then((res) => {
        if (res.data.status === 200) {
          onSuccess(null, res.data.payload.file_path);
        } else {
          onError();
          const errorMessage = res.data.payload?.status_message;
          if (errorMessage) {
            appNotification.error({ message: errorMessage });
          }
        }
      })
      .catch(() => {
        const removedStuckOnUploading = this.state.fileList.filter((item) => item?.status !== "uploading");
        this.setState({
          fileList: removedStuckOnUploading,
        });
      });
  };

  render() {
    const { previewVisible, previewImage, fileList } = this.state;
    const { multipleUpload, isDrag } = this.props;
    const uploadLength = multipleUpload ? fileList.length >= 8 : fileList.length >= 1;
    return (
      <>
        <Upload
          accept=".bmp, .tiff, .gif, .png, .jpg, .jpeg, .pdf"
          customRequest={this.customRequest}
          className={`upload-image ${isDrag && "upload-image--drag"}`}
          onChange={this.imageHandler}
          onPreview={this.handlePreview}
          listType={"picture-card"}
          fileList={fileList}
        >
          {uploadLength ? null : this.uploadButton(isDrag, fileList)}
        </Upload>
        <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
          <img alt="example" style={{ width: "100%" }} src={previewImage} />
        </Modal>
      </>
    );
  }
}

export default UploadImage;
