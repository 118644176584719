import React from "react";
import { Form } from "antd";

import { noOption } from "Modules/TransactionDetail/TransactionDetail";
import { EInputType, ITransactionDetailProps, TransactionDetail } from "Modules/TransactionDetail";

import { IExpenseTrxnsDetails } from "Views/Transaction/@types";
import { includesReversalTrxns } from "Views/Transaction/Helper";
import { useExpenseForm } from "Views/Transaction/Details/useExpenseForm";
import { EXPENSE_INPUT_DETAILS_OPTIONS } from "Views/Transaction/Constants";
import { useRemoveSubscriptionModal } from "Views/Transaction/Modal/Subscriptions";

export const Details = ({
  taxId,
  notes,
  details,
  receipts,
  trxnType,
  trxnNumber,
  categoryId,
  categoryName,
  closeDetails,
  handleSaveRef,
  tags,
  isHistoricalRefund = false,
  disableEditing = false,
  trxnId,
  expenseReceipts,
  bannerPosition = null,
  visibleInput = null,
  taxName,
  subscriptionId,
  subscriptionInitialProps,
}: IExpenseTrxnsDetails) => {
  const { form, buttonAction, defaultValue, setFormValue, onDeleteFile } = useExpenseForm(
    trxnNumber,
    closeDetails,
    receipts,
    notes,
    taxId,
    categoryId,
    trxnId,
    tags,
    expenseReceipts,
    subscriptionId
  );
  const { openModal } = useRemoveSubscriptionModal(subscriptionId, trxnNumber, () => {
    form.setFieldsValue({
      [EInputType.SUBSCRIPTION]: null,
    });
  });

  handleSaveRef.current = buttonAction;

  let props: ITransactionDetailProps;

  if (isHistoricalRefund) {
    props = {
      form,
      details,
      setFormValue: () => null,
      visibleInput: noOption,
    };
  } else {
    props = {
      form,
      details,
      defaultValue,
      setFormValue,
      missingFields: defaultValue,
      disabled: disableEditing || includesReversalTrxns(trxnType),
      visibleInput: visibleInput || EXPENSE_INPUT_DETAILS_OPTIONS,
      onDeleteFile,
      bannerPosition,
      onRemoveSubscription: openModal,
      subscriptionInitialProps,
    };
  }

  return (
    <Form form={form}>
      <TransactionDetail {...props} categoryFallback={categoryName} taxFallback={taxName} ignoreRequiredCheck />
    </Form>
  );
};
