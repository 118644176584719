import React from "react";

import {
  ISingleQuickFilter,
  ISingleQuickFilterWithSearch,
  initialSingleFilterWithSearchStates,
} from "Modules/DS/Filter/@types";
import { IItemID } from "Modules/DS/Menu";
import Search, { useSimpleSearch } from "Modules/DS/Search";

import { SingleQuickFilter } from "./Single";

export const SingleQuickFilterWithSearch = <T extends IItemID>({
  height,
  onSelect,
  children,
  displayKey,
  defaultValue,
  idKey = "id" as keyof T,
  appliedKey = "id" as keyof T,
  footer = null,
  iconClass = "",
  filterItemProps,
  searchable = true,
  clearFilter = () => null,
  onClickOutside = () => null,
  clear,
  presetValue,
  states = { ...initialSingleFilterWithSearchStates },
}: ISingleQuickFilterWithSearch<T>): JSX.Element => {
  const { get, onSearchHandler } = useSimpleSearch<T>(children, displayKey, states?.value);

  const clearFilterHandler = () => {
    clearFilter?.();
    onSearchHandler("");
  };

  const onClickOutsideHandler = () => {
    onClickOutside?.();
    onSearchHandler("");
  };

  const onSelectHandler = (value: string) => {
    onSelect?.(value);
    onSearchHandler("");
  };

  const renderSearch = () => {
    return (
      <Search.Popover
        value={get.value}
        onSearch={onSearchHandler}
        placeholder={`Search ${defaultValue.toLowerCase()}`}
        loading={get.loading || states?.loading}
      />
    );
  };

  const props: Omit<ISingleQuickFilter<T>, "children"> = {
    idKey,
    displayKey,
    appliedKey,
    height,
    footer,
    iconClass,
    defaultValue,
    filterItemProps,
    clear,
    presetValue,
    onSelect: onSelectHandler,
    clearFilter: clearFilterHandler,
    onClickOutside: onClickOutsideHandler,
    states: { ...states, loading: states?.loading || get.loading },
    ...(searchable && { header: renderSearch() }),
  };

  return (
    <SingleQuickFilter<T> {...props}>
      {get.data.length > 0 || get.value.length > 0 ? get.data : children}
    </SingleQuickFilter>
  );
};
