import React from "react";
import { useFetchTeamList, LIMIT_TO_FETCH } from "Views/Home/Components/LimitModelExpenseCarousel/Utitlites";
import ExpenseCard from "Views/Home/Components/ExpenseCard";
import ExpensesCarousel from "Views/Home/Components/ExpensesCarousel";
import ExpenseFetchError from "Views/Home/Components/ExpenseFetchError";

const LimitModelExpenseCarousel = () => {
  const [teamListData, fetchMoreTeams, teamListDataLoading, teamListDataError] = useFetchTeamList();

  const getTeamsExpenseCarouselData = () => {
    if (teamListDataError) {
      return [<ExpenseFetchError onReload={fetchMoreTeams} />];
    } else if (teamListDataLoading && teamListData?.length === 0) {
      return Array.from({ length: LIMIT_TO_FETCH })?.map(() => <ExpenseCard isLoading limitModel />);
    } else {
      return teamListData?.map((expenseDetails) => (
        <ExpenseCard expenseDetails={expenseDetails} limitModel key={expenseDetails?.teamName} />
      ));
    }
  };

  return <ExpensesCarousel data={getTeamsExpenseCarouselData()} onChange={fetchMoreTeams} limitModel />;
};

export default LimitModelExpenseCarousel;
