import { ActionButton } from "./Action";
import { IconButton } from "./Icon";
import { TitleButton } from "./Title";

const Button = {
  Icon: IconButton,
  Action: ActionButton,
  Title: TitleButton,
};

export default Button;

export * from "./types";
