import React, { Fragment } from "react";
import cn from "classnames";
import { Banner, Typography } from "@spenmo/splice";
import { IBannerProps } from "@spenmo/splice/lib/components/Banner";

import { PreviewField } from "./type";
import styles from "./Preview.module.scss";
import BillPreviewShimmer from "Views/Bills/V2/components/BillPreviewShimmer";

export interface PreviewProps {
  className?: string;
  data: PreviewField[];
  isEdited?: boolean;
  banner?: IBannerProps;
}

const Preview: React.FC<PreviewProps> = (props) => {
  const { className, data, isEdited, banner, children } = props;
  return (
    <div className={cn(styles.preview, className)}>
      {banner && <Banner {...banner} />}
      {isEdited && (
        <div className={styles.previewLabel}>
          <div>
            <div className={styles.previewHighlight} />
            <div>Updated field(s)</div>
          </div>
          <div>
            <div className={styles.previewColor} />
            <span>Unchanged</span>
          </div>
        </div>
      )}
      {data.map((section) => {
        const { label, actionText, onClickAction } = section;
        return (
          <div key={label} className={styles.previewSection}>
            <div className={styles.sectionHeader}>
              <Typography className={styles.title} variant="body-content" tag="p" size="s" weight={600}>
                {label}
              </Typography>
              {actionText && onClickAction && (
                <Typography className={styles.cta} variant="body-content" tag="p" size="s" onClick={onClickAction}>
                  {actionText}
                </Typography>
              )}
            </div>
            <div className={styles.previewContent}>
              {section?.fields?.map((item) => {
                // note: more than 1 type means we need switch-case
                if (item.type === "banner") {
                  return <Banner key={item.title} {...item} />;
                }

                return (
                  <Fragment key={item.labelName}>
                    <div className={styles.previewItem}>
                      <div>{item.labelName}</div>
                      {item.isEdited ? (
                        <div>
                          <span className={styles.newValue}>{item.newValue}</span>
                          <span className={styles.oldValue}>{item.oldValue}</span>
                        </div>
                      ) : (
                        <div>{item.newValue || "-"}</div>
                      )}
                    </div>
                    {item?.children}
                  </Fragment>
                );
              })}
              {section?.fields.length === 0 && <BillPreviewShimmer />}
            </div>
          </div>
        );
      })}
      {children}
    </div>
  );
};

export default Preview;
