/**
 * @fileoverview This module defines the AccountsList component which renders a list of accounts.
 * The accounts are presented using the Navigation.Header component from the '@spenmo/splice' library.
 *
 * @author Sushmitha, Bakshi
 * @created 24 Aug 2023
 * @lastModified 30 Aug 2023
 */
import React from "react";
import { Navigation } from "@spenmo/splice";
import { IAccountsList } from "Views/Navigation/@types";

import styles from "./index.module.scss";

/**
 * AccountsList
 *
 * Component that renders a list of accounts. Each account is displayed with an avatar,
 * organisation name, role, and currency. Clicking on an account calls the provided
 * onAccountSelect callback with the account's user_id.
 *
 * @param {Fuzzysort.KeyResults<ILinkedAccounts>} list - List of accounts to display.
 * @param {(userId: string) => void} onAccountSelect - Callback to handle account selection.
 *
 * @returns {React.ReactElement | null} Renders a list of accounts or null if the list is empty.
 */
const AccountsList: React.FC<IAccountsList> = ({ list, onAccountSelect }: IAccountsList): React.ReactElement => {
  return (
    list.length > 0 && (
      <div className={styles.list}>
        {list.map((item) => (
          <Navigation.Header
            showAvatar
            key={item.obj.user_id}
            title={item.obj.organisation_name}
            subtitle={`${item.obj.role} (${item.obj.currency})`}
            onClick={() => onAccountSelect(item.obj.user_id)}
          />
        ))}
      </div>
    )
  );
};

export default AccountsList;
