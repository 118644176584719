import {
  FETCH_MERCHANT_LIST_REQUEST,
  FETCH_MERCHANT_LIST_SUCCESS,
  FETCH_MERCHANT_LIST_FAILURE,
  RESET_MERCHANT_LIST_DATA,
} from "Redux/Actions/Merchant/search";
import { userInitialState } from "Redux/InitialState";

export const merchantListReducer = (state = userInitialState, action = {}) => {
  switch (action?.type) {
    case FETCH_MERCHANT_LIST_REQUEST:
      return {
        ...state,
        data: null,
        loading: true,
      };
    case FETCH_MERCHANT_LIST_SUCCESS:
      return {
        ...state,
        data: action?.data,
        loading: false,
      };
    case FETCH_MERCHANT_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        data: action?.data,
        error: true,
      };
    case RESET_MERCHANT_LIST_DATA:
      return {
        ...state,
        ...userInitialState,
      };
    default:
      return state;
  }
};

export const fetchMerchantListData = (state) => state?.cardsListReducer || {};
