import React from "react";
import { Skeleton as AntDSkeleton } from "antd";

import DataTable, {
  ALIGN_ACTION_ITEM,
  ActionsBar,
  DATA_TABLE_PADDING,
  DATA_TABLE_SIZE_TYPES,
  IActionItem,
  IColumnData,
  ITableProps,
  Table,
} from "Modules/DS/DataTable";

import { IWithID } from "Views/Reimbursement/@types";
import { TABLE_SIZE } from "Views/Reimbursement/Constants";
import { itemRender } from "Views/Reimbursement/List/Pagination";

import styles from "./index.module.scss";

export const TableSkeleton = () => {
  const dummyReimbursements: IWithID[] = Array.from({ length: 5 }).map((_, index) => ({
    id: `REIMBURSEMENTS-SKELETON-${index}`,
  }));

  const dummyColumns: IColumnData<IWithID>[] = Array.from({ length: 8 }).map((_, index) => ({
    id: `REIMBURSEMENTS-SKELETON-COLUMNS-${index}`,
    enabled: true,
    column: {
      key: "id",
      title: <AntDSkeleton.Input size="small" active={true} className={styles.columnHeader} />,
      render: () => <AntDSkeleton.Input size="small" active={true} className={styles.columnData} />,
    },
  }));

  const renderShowTotal = () => {
    return (
      <div className={styles.pagination}>
        <AntDSkeleton.Input size="small" active={true} className={styles.total} />
        <AntDSkeleton.Input size="small" active={true} className={styles.page} />
      </div>
    );
  };

  const table: ITableProps<IWithID> = {
    total: dummyReimbursements.length,
    pageNum: 0,
    pageSize: TABLE_SIZE,
    paginationOnTop: true,
    dataSource: dummyReimbursements,
    paginationProps: {
      itemRender,
      disabled: true,
      showLessItems: true,
      showQuickJumper: false,
      hideOnSinglePage: false,
      showTotal: renderShowTotal,
    },
  };

  return <Table<object> {...table}>{dummyColumns}</Table>;
};

export const ActionsSkeleton = () => {
  const dummyActions: IActionItem[] = Array.from({ length: 4 }).map((_, index) => ({
    id: `REIMBURSEMENTS-SKELETON-ACTIONS-${index}`,
    enabled: true,
    align: index === 3 ? ALIGN_ACTION_ITEM.RIGHT : ALIGN_ACTION_ITEM.LEFT,
    component: <AntDSkeleton.Input size="small" active={true} className={styles.action} />,
  }));

  return <ActionsBar>{dummyActions}</ActionsBar>;
};

const Skeleton = () => {
  return (
    <DataTable className={styles.container} size={DATA_TABLE_SIZE_TYPES.SMALL} padding={DATA_TABLE_PADDING.NONE}>
      <AntDSkeleton.Input size="small" active={true} className={styles.description} />
      <ActionsSkeleton />
      <TableSkeleton />
    </DataTable>
  );
};

export default Skeleton;
