import React from "react";

import { getCookieValue, cookieNames } from "utility/CookieHelper";

import { InformationLoader } from "Views/KYX/Loader";
import { ManualMethod } from "Views/KYX/Progress/Step";
import { FORM_NAME, KYX_ORG_COUNTRY_LIST } from "Views/KYX/Constants";

import { useCompanyDocumentFormSGD } from "./Form";

export const CompanyDocument = () => {
  let Component: JSX.Element;

  const countryCode = getCookieValue(cookieNames.ORG_COUNTRY_CODE);

  switch (countryCode) {
    case KYX_ORG_COUNTRY_LIST.IDR:
      Component = <ManualMethod name={FORM_NAME.COMPANY_DOCUMENTS}>{useCompanyDocumentFormSGD}</ManualMethod>;
      break;
    case KYX_ORG_COUNTRY_LIST.SGD:
      Component = <ManualMethod name={FORM_NAME.COMPANY_DOCUMENTS}>{useCompanyDocumentFormSGD}</ManualMethod>;
      break;
    default:
      Component = <InformationLoader />;
      break;
  }

  return Component;
};
