import React from "react";
import { Progress } from "antd";

import Icon, { IconList } from "Modules/Icon";
import IconComp from "Modules/icons";
import Button from "Modules/button";

import { Delete } from "assets/v1.1/icons/Core/Delete";
import { whiteEyeOutlined } from "assets/img";
import { convertBytestoMB } from "utility";
import { IAddButton, IButton, IChangeFile, IprogressBar } from "Views/KYX/@types";

import styles from "./index.module.scss";

export const AddButton: React.FC<IAddButton> = ({ action }: IAddButton): JSX.Element => {
  return (
    <div className={styles.button} onClick={action}>
      <Icon svgAttr={{ width: 16, height: 16, viewBox: "0 0 16 16" }} className={styles.icon} icon={IconList.add} />
      <p className={styles.text}>Add more files</p>
    </div>
  );
};

export const DeleteBtn: React.FC<IButton> = ({ action, file }: IButton): JSX.Element => {
  return (
    <div className={styles.delete} onClick={(e) => action(e, file)}>
      <img src={Delete.Fill.red_16} alt="delete" />
    </div>
  );
};

export const PreviewBtn: React.FC<IButton> = ({ action, className, file }: IButton): JSX.Element => {
  return <IconComp className={className} onClick={(e) => action(e, file)} src={whiteEyeOutlined} />;
};

export const ChangeFileDetails: React.FC<IChangeFile> = ({ file, onChangeFile }: IChangeFile): JSX.Element => {
  return (
    <div className={styles.actionBtns}>
      <p>{file?.size ? `${convertBytestoMB(file?.size, 2)} MB` : null}</p>
      {file?.size ? <span className={styles.dot} /> : null}
      <Button
        textButton
        className={styles.button}
        rounded
        action={() => onChangeFile(file)}
        text="Change File"
        type="button"
      />
    </div>
  );
};

export const ProgressLoader: React.FC<IprogressBar> = ({ progress, className }: IprogressBar): JSX.Element => {
  return <Progress className={className} strokeWidth={4} strokeColor="#1FCE8F" percent={progress} showInfo={false} />;
};
