import { useHistory } from "react-router";
import qs from "query-string";
import { ISubscriptionManagementFilters } from "../@types/filters";

export const useSetUrlParams = () => {
  const history = useHistory();
  return (pathname: string, filters: ISubscriptionManagementFilters) => {
    history.push({
      pathname,
      search:
        "?" +
        qs.stringify(filters, {
          skipEmptyString: true,
          skipNull: true,
        }),
    });
  };
};
