import APIclient from "API/Client";
import { ANALYTICS_SERVICE } from "Views/Analytics/DataCalls/Services";
import { urlParamsBuilder } from "utility/APIWrapper";

export const getSpendingTrend = async (payload) => {
  const url = urlParamsBuilder(`${ANALYTICS_SERVICE.GET_SPENDING_TREND}`, payload);
  return APIclient.getData(url);
};

export const getSpendingCategories = async (payload) => {
  const url = urlParamsBuilder(`${ANALYTICS_SERVICE.GET_SPENDING_CATEGORIES}`, payload);
  return APIclient.getData(url);
};

export const getSpendingTeams = async (payload) => {
  const url = urlParamsBuilder(`${ANALYTICS_SERVICE.GET_SPENDING_TEAM}`, payload);
  return APIclient.getData(url);
};

export const getSpendingMerchants = async (payload) => {
  const url = urlParamsBuilder(`${ANALYTICS_SERVICE.GET_SPENDING_MERCHANT}`, payload);
  return APIclient.getData(url);
};
