import APIclient from "API/Client";

export const TOPUP_STATUS_REQUEST = "TOPUP_STATUS_REQUEST";
export const TOPUP_STATUS_SUCCESS = "TOPUP_STATUS_SUCCESS";
export const TOPUP_STATUS_FAILURE = "TOPUP_STATUS_FAILURE";

export const fetchDataReq = (data) => {
  return {
    type: TOPUP_STATUS_REQUEST,
    data: data,
    loading: true,
    error: false,
  };
};
const fetchDataSuccess = (data) => {
  return {
    type: TOPUP_STATUS_SUCCESS,
    data: data,
    loading: false,
    error: false,
  };
};

const fetchDataError = (data) => {
  return {
    type: TOPUP_STATUS_FAILURE,
    data: data,
    loading: false,
    error: true,
  };
};

export const topupStatusFunc = (trx_id) => {
  return (dispatch) => {
    dispatch(fetchDataReq());
    APIclient.getData(`/api/v1/orders/${trx_id}`)
      .then((data) => {
        if (data.data.status === 200) {
          dispatch(fetchDataSuccess(data.data));
        } else {
          dispatch(fetchDataError(data.data));
        }
      })
      .catch((err) => {
        dispatch(fetchDataError(err));
      });
  };
};
