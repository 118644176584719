import React from "react";
import { useHistory } from "react-router-dom";

import { Button, Typography, ExternalOutline } from "@spenmo/splice";
import Chip, { CHIP_STATUS } from "Modules/DS/Chip";
import { INTEGRATION_STATUS } from "constants/Integration.constant";
import { ACCOUNTING_PATHS } from "Route/Accounting/paths";

import { PARTNER_TYPE } from "../helper/constant";
import { DescriptiveText } from "../components";
import { IAccountingPartner } from "../@types";

import styles from "./styles.module.scss";

export const AccountingPartner = ({
  logoUrl,
  name,
  helpUrl,
  notes,
  connectedToAccountingPartner,
  showConnectedStatus,
  accountingPartnerError,
  isAllowedIntegrationSettingUpdate,
  isPartnerAccountNotAvailable,
  partialyConnected,
}: IAccountingPartner) => {
  const history = useHistory();
  const isNetsuite = name === PARTNER_TYPE.NETSUITE;

  const displayName = isNetsuite ? "Oracle NetSuite" : name;

  // to only handle partial connection
  const connectToPartner = () => {
    if (partialyConnected && isNetsuite) {
      history.push(ACCOUNTING_PATHS.NETSUITE_SETUP);
    }
  };

  const displayAccountingInfoStatus = () => {
    if (connectedToAccountingPartner && showConnectedStatus) {
      return (
        <Chip.Status
          title={
            isPartnerAccountNotAvailable
              ? INTEGRATION_STATUS.DISCONNECTED
              : INTEGRATION_STATUS.CONNECTED
          }
          status={
            isPartnerAccountNotAvailable
              ? CHIP_STATUS.FAILED
              : CHIP_STATUS.SUCCESS
          }
        />
      );
    }

    if (isAllowedIntegrationSettingUpdate) {
      return (
        <>
          <Chip.Status
            title={INTEGRATION_STATUS.PENDING_SETUP}
            status={CHIP_STATUS.ATTENTION}
          />
          {accountingPartnerError && (
            <Chip.Status
              title={INTEGRATION_STATUS.FAILED_TO_CONNECT}
              status={CHIP_STATUS.FAILED}
            />
          )}
          <div className={styles.buttonContainer}>
            {!isNetsuite && (
              <Button
                icon={ExternalOutline}
                variant="tertiary"
                onClick={() => window.open(helpUrl, "_blank")}
              >
                View Help
              </Button>
            )}
            <Button variant="primary" onClick={connectToPartner}>
              Continue Setup
            </Button>
          </div>
        </>
      );
    }

    return null;
  };

  return (
    <div className={styles.accountingPartnerContainer}>
      <img src={logoUrl} alt="accounting_partner_logo" height={48} width={48} />

      <div className={styles.accountingPartnerInfo}>
        <Typography
          variant="headline-content"
          size="s"
          tag="h3"
          children={displayName}
        />
        <DescriptiveText description={notes} />
        {displayAccountingInfoStatus()}
      </div>
    </div>
  );
};
