import React from "react";
import { useHistory } from "react-router-dom";

import Icon from "Modules/icons";
import Button from "Modules/button";

import { DASHBOARD_PATH } from "constants/Routes.constants";

import styles from "./LandingSoon.module.scss";

interface LandingSoonProps {
  iconSrc: string;
  iconAlt: string;
  title: string;
  body: string;
}

const LandingSoon = ({ iconSrc, iconAlt, title, body }: LandingSoonProps) => {
  const history = useHistory();

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <Icon src={iconSrc} alt={iconAlt} className={styles.icon} />
        <div className={styles.title}>{title}</div>
        <div className={styles.body}>{body}</div>
        <Button rounded={true} className={styles.button} action={() => history.push(DASHBOARD_PATH)}>
          Back to Dashboard
        </Button>
      </div>
    </div>
  );
};

export default LandingSoon;
