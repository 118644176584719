import React from "react";

import { ISubscription, IAuditLog } from "Views/SubscriptionManagement/@types";
import { Figma, Google, Hubspot, Microsoft, Oracle, Zoom } from "Views/SubscriptionManagement/assets";
import { SUBSCRIPTION_AUDIT_TYPES } from "Views/SubscriptionManagement/Constants";

export const dummySubscriptions: ISubscription[] = Array(10)
  .fill({})
  .map((_, index) => ({
    vendor: { id: "fghij-klmno-pqrst-abcde", name: "Google", icon: <Google /> },
    budget: { id: "klmno-pqrst-abcde-fghij", name: "Digital Marketing" },
    owner: { id: "pqrst-abcde-fghij-klmno", name: "Alice Johnson" },
    status: "active",
    currency_code: "SGD",
    estimated_amount: 1000,
    estimated_cost: 1000,
    billing_frequency: "monthly",
    review_expiry_date: "2024-11-30",
    payment: { card_id: "card-number-id" },
    subscription_id: `Mocked_subscription_${index}`,
    expiry: "",
    start_date: "",
    contract_type: "",
    next_renewal_date: "",
    last_payment_amount: 1000,
    variation_percentage: 10,
    last_payment_foreign_amount: 0,
    last_payment_foreign_currency: "",
  }));

export const listMockData: ISubscription[] = [
  {
    subscription_id: "qwert-asdfg-zxcvb-yuiop",
    vendor: { id: "fghij-klmno-pqrst-abcde", name: "Google", icon: <Google /> },
    budget: { id: "klmno-pqrst-abcde-fghij", name: "Digital Marketing" },
    owner: { id: "pqrst-abcde-fghij-klmno", name: "Alice Johnson" },
    status: "active",
    currency_code: "SGD",
    estimated_amount: 1000,
    estimated_cost: 1000,
    billing_frequency: "monthly",
    review_expiry_date: "2024-11-30",
    payment: { card_id: "card-number-id" },
    expiry: "",
    start_date: "",
    contract_type: "",
    next_renewal_date: "",
    last_payment_amount: 1000,
    variation_percentage: 10,
    last_payment_foreign_amount: 0,
    last_payment_foreign_currency: "",
  },
  {
    subscription_id: "asdfg-qwert-yuiop-zxcvb",
    vendor: { id: "klmno-pqrst-abcde-fghij", name: "Figma", icon: <Figma /> },
    budget: { id: "pqrst-abcde-fghij-klmno", name: "Design" },
    owner: { id: "fghij-klmno-pqrst-abcde", name: "Bob Anderson" },
    status: "active",
    currency_code: "SGD",
    estimated_cost: 800,
    estimated_amount: 800,
    billing_frequency: "yearly",
    review_expiry_date: "2024-12-31",
    payment: { card_id: "another-card-number-id" },
    expiry: "",
    start_date: "",
    contract_type: "",
    next_renewal_date: "",
    last_payment_amount: 1000,
    variation_percentage: 10,
    last_payment_foreign_amount: 0,
    last_payment_foreign_currency: "",
  },
  {
    subscription_id: "zxcvb-qwera-sdfgh-tyuio",
    vendor: { id: "v5", name: "Oracle", icon: <Oracle /> },
    budget: { id: "b5", name: "Finance" },
    owner: { id: "o5", name: "Elena Rodriguez" },
    status: "inactive",
    currency_code: "EUR",
    estimated_amount: 1200,
    estimated_cost: 1200,
    billing_frequency: "yearly",
    review_expiry_date: "2025-06-30",
    payment: { card_id: "card-789" },
    expiry: "",
    start_date: "",
    contract_type: "",
    next_renewal_date: "",
    last_payment_amount: 1000,
    variation_percentage: 10,
    last_payment_foreign_amount: 0,
    last_payment_foreign_currency: "",
  },
  {
    subscription_id: "poiuy-trewq-asdfg-hjklz",
    vendor: { id: "v7", name: "Google", icon: <Google /> },
    budget: { id: "b7", name: "IT Services" },
    owner: { id: "o7", name: "Michael Thompson" },
    status: "active",
    currency_code: "USD",
    estimated_amount: 600,
    estimated_cost: 600,
    billing_frequency: "monthly",
    review_expiry_date: "2024-11-30",
    payment: { card_id: "card-123" },
    expiry: "",
    start_date: "",
    contract_type: "",
    next_renewal_date: "",
    last_payment_amount: 1000,
    variation_percentage: 10,
    last_payment_foreign_amount: 0,
    last_payment_foreign_currency: "",
  },
  {
    subscription_id: "yuiop-asdfg-qwert-zxcvb",
    vendor: { id: "v10", name: "Zoom", icon: <Zoom /> },
    budget: { id: "b10", name: "HR" },
    owner: { id: "o10", name: "Sophia Roberts" },
    status: "active",
    currency_code: "EUR",
    estimated_cost: 500,
    estimated_amount: 500,
    billing_frequency: "monthly",
    review_expiry_date: "2024-12-31",
    payment: { card_id: "card-123" },
    expiry: "",
    start_date: "",
    contract_type: "",
    next_renewal_date: "",
    last_payment_amount: 1000,
    variation_percentage: 10,
    last_payment_foreign_amount: 0,
    last_payment_foreign_currency: "",
  },
  {
    subscription_id: "hjklz-poiuy-trewq-asdfg",
    vendor: { id: "v3", name: "Hubspot", icon: <Hubspot /> },
    budget: { id: "b3", name: "Sales" },
    owner: { id: "o3", name: "David Wilson" },
    status: "active",
    currency_code: "USD",
    estimated_amount: 950,
    estimated_cost: 950,
    billing_frequency: "monthly",
    review_expiry_date: "2025-12-31",
    payment: { card_id: "card-456" },
    expiry: "",
    start_date: "",
    contract_type: "",
    next_renewal_date: "",
    last_payment_amount: 1000,
    variation_percentage: 10,
    last_payment_foreign_amount: 0,
    last_payment_foreign_currency: "",
  },
  {
    subscription_id: "asdfg-yuiop-zxcvb-qwert",
    vendor: { id: "v12", name: "Microsoft", icon: <Microsoft /> },
    budget: { id: "b12", name: "Development" },
    owner: { id: "o12", name: "Emma Garcia" },
    status: "inactive",
    currency_code: "EUR",
    estimated_amount: 700,
    estimated_cost: 700,
    billing_frequency: "yearly",
    review_expiry_date: "2025-12-31",
    payment: { card_id: "card-789" },
    expiry: "",
    start_date: "",
    contract_type: "",
    next_renewal_date: "",
    last_payment_amount: 1000,
    variation_percentage: 10,
    last_payment_foreign_amount: 0,
    last_payment_foreign_currency: "",
  },
  {
    subscription_id: "trewq-hjklz-asdfg-yuiop",
    vendor: { id: "v2", name: "Hubspot", icon: <Hubspot /> },
    budget: { id: "b2", name: "Infrastructure" },
    owner: { id: "o2", name: "William Clark" },
    status: "active",
    currency_code: "USD",
    estimated_amount: 750,
    estimated_cost: 750,
    billing_frequency: "monthly",
    review_expiry_date: "2024-12-31",
    payment: { card_id: "card-123" },
    expiry: "",
    start_date: "",
    contract_type: "",
    next_renewal_date: "",
    last_payment_amount: 1000,
    variation_percentage: 10,
    last_payment_foreign_amount: 0,
    last_payment_foreign_currency: "",
  },
  {
    subscription_id: "zxcvb-qwert-asdfg-yuiop",
    vendor: { id: "v9", name: "Microsoft", icon: <Microsoft /> },
    budget: { id: "b9", name: "Support" },
    owner: { id: "o9", name: "Olivia Moore" },
    status: "active",
    currency_code: "USD",
    estimated_amount: 850,
    estimated_cost: 850,
    billing_frequency: "monthly",
    review_expiry_date: "2024-12-31",
    payment: { card_id: "card-789" },
    expiry: "",
    start_date: "",
    contract_type: "",
    next_renewal_date: "",
    last_payment_amount: 1000,
    variation_percentage: 10,
    last_payment_foreign_amount: 0,
    last_payment_foreign_currency: "",
  },
  {
    subscription_id: "poiuy-trewq-hjklz-asdfg",
    vendor: { id: "v6", name: "Microsoft", icon: <Microsoft /> },
    budget: { id: "b6", name: "DevOps" },
    owner: { id: "o6", name: "Daniel White" },
    status: "active",
    currency_code: "EUR",
    estimated_amount: 900,
    estimated_cost: 900,
    billing_frequency: "yearly",
    review_expiry_date: "2024-12-31",
    payment: { card_id: "card-456" },
    expiry: "",
    start_date: "",
    contract_type: "",
    next_renewal_date: "",
    last_payment_amount: 1000,
    variation_percentage: 10,
    last_payment_foreign_amount: 0,
    last_payment_foreign_currency: "",
  },
];

export default listMockData;

export const mockAuditLog: IAuditLog[] = Array(10)
  .fill({})
  .map((_, index) => ({
    change_type: SUBSCRIPTION_AUDIT_TYPES.CREATED,
    created_at: "2024-11-30",
    user: {
      name: "miachel jackson",
      id: "fghij-klmno-pqrst-abcde",
    },
    additional_data: {
      new_expiry_date: null,
      transaction_number: null,
    },
    id: `Mocked_audit_log_${index}`,
  }));

export const budgetsAnalysis = [
  {
    budget_name: "Marketing",
    total_spend: 12000,
  },
  {
    budget_name: "Engineering & IT",
    total_spend: 80000,
  },
  {
    budget_name: "Sales & Support",
    total_spend: 60000,
  },
  {
    budget_name: "Product",
    total_spend: 12000,
  },
  {
    budget_name: "Ads",
    total_spend: 12000,
  },
  {
    budget_name: "Employee Benefits",
    total_spend: 12000,
  },
  {
    budget_name: "HR",
    total_spend: 12000,
  },
  {
    budget_name: "Others",
    total_spend: 12000,
  },
];
