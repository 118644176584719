import React, { useContext } from "react";
import { Col, Form } from "antd";
import PropTypes from "prop-types";

import SearchDropDown from "Modules/searchDropdown";
import { FiltersAPI, FilterComponents } from "Modules/Filters";

export const SearchDropDownFilter = ({
  title,
  formItem,
  listData,
  children,
  eventName,
  placeholder,
  appendClassName,
  dispatchAction,
  displayKey,
}) => {
  const api = useContext(FiltersAPI);

  const handleAction = (id, value) => {
    dispatchAction && dispatchAction(id, value, eventName);
    api.handleDropDownActions(id, formItem, eventName);
  };

  return (
    <FilterComponents.FilterItemWrapper appendClassName={appendClassName} title={title}>
      <Col>
        <Form.Item name={formItem}>
          <SearchDropDown
            displayKey={displayKey}
            action={handleAction}
            initialValue={children}
            placeholder={placeholder}
            listData={listData || []}
          />
        </Form.Item>
      </Col>
    </FilterComponents.FilterItemWrapper>
  );
};

SearchDropDownFilter.propTypes = {
  title: PropTypes.string,
  listData: PropTypes.array,
  children: PropTypes.string,
  formItem: PropTypes.string,
  eventName: PropTypes.string,
  placeholder: PropTypes.string,
  dispatchAction: PropTypes.func,
  appendClassName: PropTypes.string,
};
