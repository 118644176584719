import React, { useContext, useState } from "react";

import { IItemID } from "Modules/DS/Menu";
import { Quick } from "Modules/DS/Filter";
import { POSITION_ALGO_TYPE } from "Modules/DS/Popover";
import { IMultipleQuickFilterWithSearch } from "Modules/DS/Filter/@types";

import { TrxnFilterContext, TrxnPaginationContext } from "Views/Transaction/Context";
import { ALL_TRXN_PAGINATION_PARAMS } from "Views/Transaction/Constants";
import {
  ITrxnFilterContext,
  ITrxnPaginationContext,
  ITrxnMultipleQuickFilter,
  GET_TRXN_REQUEST_PARAMS,
} from "Views/Transaction/@types";
import { clearURLParams } from "utility";

export const MultipleSelect = <T extends IItemID>({
  id,
  children,
  displayKey,
  defaultValue,
  idKey = "id",
  appliedKey = "id",
  loading = false,
  searchable = false,
  height,
  iconClass,
  onClickOutside,
  searchValue,
  dispatchOnSelect = () => null,
  dispatchOnClear = () => null,
}: ITrxnMultipleQuickFilter<T>) => {
  const [title, setTitle] = useState<string>("");
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const { resetCurrentPage } = useContext<ITrxnPaginationContext>(TrxnPaginationContext);
  const { resetTrxnPaginationParams, onApplyFilter, resetFilter, filter, clearAll } =
    useContext<ITrxnFilterContext>(TrxnFilterContext);

  const onSelectHandler = (value: string[]) => {
    onApplyFilter({
      [id]: value,
      ...resetTrxnPaginationParams,
    });
    dispatchOnSelect?.(value);
    resetCurrentPage();
  };

  const clearFilter = () => {
    if (id === GET_TRXN_REQUEST_PARAMS.TYPES) {
      clearURLParams([GET_TRXN_REQUEST_PARAMS.TYPES]);
    }
    resetFilter([id, ...ALL_TRXN_PAGINATION_PARAMS]);
    dispatchOnClear?.();
    resetCurrentPage();
  };

  const props: IMultipleQuickFilterWithSearch<T> = {
    children,
    searchable,
    displayKey,
    idKey,
    appliedKey,
    clearFilter,
    defaultValue,
    height,
    iconClass,
    onClickOutside,
    clear: clearAll,
    presetValue: children?.length > 0 && filter[id],
    onAddSelected: onSelectHandler,
    filterItemProps: { id, containerClass: ".data-table-container", positionAlgoType: POSITION_ALGO_TYPE.HORIZONTAL },
    states: {
      selectedIds,
      setSelectedIds,
      title,
      setTitle,
      loading,
      ...(searchValue && { value: searchValue }),
    },
  };

  return <Quick.Multiple<T> {...props} />;
};
