import { useState } from "react";
import { useDispatch } from "react-redux";
import { fetchAccountingWorkflow } from "Redux/Reducers/AccountingWorkflow/accountingWorkflow.slice";
import { defaultFilter, ITransactionFilter } from "../TransactionFilter";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

export const useFilter = (status) => {
  const dispatch = useDispatch();
  const [filters, setFilters] = useState<ITransactionFilter>(defaultFilter);

  const onApplyFilter = (newFilter, callApi = true) => {
    const combinedFilter = { ...filters, ...newFilter };
    setFilters(combinedFilter);

    callApi && dispatch(fetchAccountingWorkflow({ filter: combinedFilter, status, cancelable: true }));
  };

  const resetFilter = (keys, callApi = true) => {
    const resetKeys = keys.reduce((newKey, key: string) => {
      newKey[key] = key.includes("amount") ? 0 : "";
      return newKey;
    }, {});

    const newFilter = {
      ...filters,
      ...resetKeys,
    };
    setFilters(newFilter);
    callApi && dispatch(fetchAccountingWorkflow({ filter: newFilter, status }));
  };

  return { onApplyFilter, resetFilter, filters, setFilters };
};
