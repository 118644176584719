import * as React from "react";
import { useHistory } from "react-router-dom";

import { ADD_EMPLOYEES_PATH } from "constants/Routes.constants";

import Button from "Modules/button";
import { trackEvent } from "utility/analytics";
import usePermissionCheck from "Permission/usePermissionCheck";
import { CASBIN_PAGES } from "Permission/Pages";
import { EMPLOYEES_PAGE } from "Permission/Actions";
import { USER_EVENT_NAMES } from "Views/Employee/Analytics";
import { useIsSaasWithPaymentsOrg } from "customHooks/useIsSaasWithPaymentsOrg";

const AddButton = () => {
  const permissionParam = [{ object: CASBIN_PAGES.EMPLOYEES_PAGE, action: EMPLOYEES_PAGE.ADD_EMPLOYEES_BUTTON }];
  const IS_ALLOWED_ADD_EMPLOYEES = usePermissionCheck(permissionParam);
  const isSaasAndPaymentsOrg = useIsSaasWithPaymentsOrg();
  const history = useHistory();

  const handleAddEmployee = () => {
    history.push(ADD_EMPLOYEES_PATH);
    trackEvent(USER_EVENT_NAMES.USER_ADD_BUTTON_CLICKED);
  };

  return IS_ALLOWED_ADD_EMPLOYEES ? (
    <Button id="actionButton" className="data-table__action-button" action={handleAddEmployee}>
      {isSaasAndPaymentsOrg ? "+ Add Employees" : "+ Add Users"}
    </Button>
  ) : (
    <></>
  );
};

export default AddButton;
