import APIclient from "API/Client";
import { GetBaseAuthObject } from "utility";

export const getApprovalFlowNewName = (type) => {
  return APIclient.getData(`/ms/spm-organization/v1/approval/workflow-new-name?processType=${type}`);
};

export const checkTeamManager = (walletId) => {
  return APIclient.getData(`/api/v1/org/${GetBaseAuthObject().orgId}/team-wallet/${walletId}?only_manager=1`);
};

export const getSelfApprovalList = (process) => {
  return APIclient.getData(`/ms/spm-organization/v1/self-approval-policy?processType=${process}`);
};

export const getChangelogHistory = (type) => {
  return APIclient.getData(`/ms/spm-organization/v1/self-approval-policy/changelogs?processType=${type}`);
};
