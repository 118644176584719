import { TOPUP_STATUS_REQUEST, TOPUP_STATUS_SUCCESS, TOPUP_STATUS_FAILURE } from "../Actions/topupStatusAction";
import { userInitialState } from "../InitialState";

export const topupStatusReducer = (state = userInitialState, action) => {
  switch (action.type) {
    case TOPUP_STATUS_REQUEST:
      return {
        ...state,
        data: action.data,
        loading: true,
        error: false,
      };
    case TOPUP_STATUS_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
        error: false,
      };
    case TOPUP_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: true,
      };
    default:
      return state;
  }
};
