import APIclient from "API/Client";
import { source } from "../index";
import { updateWallet } from "../walletAction";
import { GetOrgId, getTeamRole, GetUserId } from "utility";
import { identifyEvent } from "utility/analytics";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";

export const FETCH_TEAMS_REQUEST = "FETCH_TEAMS_REQUEST";

export const FETCH_ADDTEAMS_REQUEST = "FETCH_ADDTEAMS_REQUEST"; //need to remove
export const FETCH_ADDTEAMS_SUCCESS = "FETCH_ADDTEAMS_SUCCESS"; //need to remove
export const FETCH_ADDTEAMS_FAILURE = "FETCH_ADDTEAMS_FAILURE"; //need to remove
export const FETCH_TEAMS_SUCCESS = "FETCH_TEAMS_SUCCESS";

export const FETCH_CREATE_TEAMS_SUCCESS = "FETCH_CREATE_TEAMS_SUCCESS"; //need to remove

export const FETCH_TEAMS_FAILURE = "FETCH_TEAMS_FAILURE";

export const UPDATE_TEAM = "UPDATE_TEAM";
export const UPDATE_USER_ROLE_MEMBERSHIP = "UPDATE_USER_ROLE_MEMBERSHIP";

export const FETCH_TEAMS_BY_USER = "FETCH_TEAMS_BY_USER";
export const FETCH_TEAMS_BY_USER_SUCCESS = "FETCH_TEAMS_BY_USER_SUCCESS";
export const FETCH_TEAMS_BY_USER_FAILURE = "FETCH_TEAMS_BY_USER_FAILURE";

//need to fix initial state in reducer before implementing below
// const fetchDataReq = () => {
//   return {
//     type: FETCH_TEAMS_REQUEST,
//   };
// };
const fetchDataSuccess = (data, allTeams) => {
  return {
    type: FETCH_TEAMS_SUCCESS,
    data: data,
    allTeams: allTeams,
  };
};

const updateTeam = (teamId = "") => {
  return {
    type: UPDATE_TEAM,
    data: teamId,
  };
};
// const fetchDataError = (data) => {
//   return {
//     type: FETCH_TEAMS_FAILURE,
//     data: data,
//   };
// };

const setUserRoleMembership = (role, membership, company_role) => {
  return {
    type: UPDATE_USER_ROLE_MEMBERSHIP,
    role,
    membership,
    company_role,
  };
};

const fetchTeamByUserReq = () => {
  return {
    type: FETCH_TEAMS_BY_USER,
  };
};

const fetchTeamByUserSuccess = (data) => {
  return {
    type: FETCH_TEAMS_BY_USER_SUCCESS,
    data,
  };
};

const fetchTeamByUserFailure = () => {
  return {
    type: FETCH_TEAMS_BY_USER_FAILURE,
  };
};

const setUserWallet = (teamdata) => {
  const personalWallet = {};
  const teamWallet = {};
  if (teamdata.your_role === "Admin") {
    if (teamdata.your_membership === "Member" || teamdata.your_membership === "Non-Member") {
      Object.assign(personalWallet, {
        name: "Personal Wallet",
        type: "user",
        currency_code: teamdata?.your_membership_details?.user_wallet?.currency_code,
        amount: teamdata?.your_membership_details?.user_wallet?.available_balance,
        id: teamdata?.your_membership_details?.user_wallet?.id,
      });
    }
    Object.assign(teamWallet, {
      name: "Team Wallet",
      type: "team",
      amount: teamdata.wallet_amount,
      id: teamdata.wallet_id,
      currency_code: teamdata.currency_code,
      expenses: teamdata.expenses_this_month,
      alotted: teamdata.alotted_amount,
    });
    return (dispatch) => {
      dispatch(
        updateWallet({
          name: teamdata.name,
          currency_code: teamdata.currency_code,
          collection: teamdata?.your_membership_details?.user_wallet ? [teamWallet, personalWallet] : [teamWallet],
          your_role: teamdata.your_role,
          your_membership: teamdata.your_membership,
          is_admin: teamdata.is_admin,
        })
      );
    };
  } else if (teamdata.your_membership === "Non-Member") {
    Object.assign(personalWallet, {
      name: "Personal Wallet",
      type: "user",
      currency_code: teamdata?.your_membership_details?.user_wallet?.currency_code,
      amount: teamdata?.your_membership_details?.user_wallet?.available_balance,
      id: teamdata?.your_membership_details?.user_wallet?.id,
    });
    Object.assign(teamWallet, {
      name: "Team Wallet",
      type: "team",
      currency_code: teamdata.currency_code,
      amount: teamdata.wallet_amount,
      id: teamdata.wallet_id,
      expenses: teamdata.expenses_this_month,
      alotted: teamdata.alotted_amount,
    });
    return (dispatch) => {
      dispatch(
        updateWallet({
          name: teamdata.name,
          currency_code: teamdata.currency_code,
          collection: teamdata?.your_membership_details?.user_wallet ? [teamWallet, personalWallet] : [teamWallet],
          your_role: teamdata.your_role,
          your_membership: teamdata.your_membership,
          is_admin: teamdata.is_admin,
        })
      );
    };
  } else if (teamdata.your_membership === "Member" && teamdata.your_role === "Manager") {
    Object.assign(personalWallet, {
      name: "Personal Wallet",
      type: "user",
      amount: teamdata.your_membership_details.user_wallet.available_balance,
      currency_code: teamdata.your_membership_details.user_wallet.currency_code,
      id: teamdata.your_membership_details.user_wallet.id,
    });
    Object.assign(teamWallet, {
      name: "Team Wallet",
      type: "team",
      amount: teamdata.wallet_amount,
      currency_code: teamdata.currency_code,
      id: teamdata.wallet_id,
      expenses: teamdata.expenses_this_month,
      alotted: teamdata.alotted_amount,
    });
    return (dispatch) => {
      dispatch(
        updateWallet({
          name: teamdata.name,
          currency_code: teamdata.currency_code,
          collection: teamdata?.your_membership_details?.user_wallet ? [teamWallet, personalWallet] : [teamWallet],
          your_role: teamdata.your_role,
          your_membership: teamdata.your_membership,
          is_admin: teamdata.is_admin,
        })
      );
    };
  } else if (teamdata.your_membership === "Member" && teamdata.your_role === "Member") {
    Object.assign(personalWallet, {
      name: "Personal Wallet",
      type: "user",
      amount: teamdata.your_membership_details.user_wallet.available_balance,
      currency_code: teamdata.your_membership_details.user_wallet.currency_code,
      id: teamdata.your_membership_details.user_wallet.id,
    });
    return (dispatch) => {
      dispatch(
        updateWallet({
          name: teamdata.name,
          currency_code: teamdata.currency_code,
          collection: teamdata?.your_membership_details?.user_wallet ? [{}, personalWallet] : [{}],
          your_role: teamdata.your_role,
          your_membership: teamdata.your_membership,
          is_admin: teamdata.is_admin,
        })
      );
    };
  }
};

const sendIdentifyEvent = (name, teamRole, companyRole) => {
  identifyEvent({
    "current team": name,
    "team role": teamRole,
    "company role": companyRole,
  });
};

//called after fetching global team list and whenever team is changed from header dropdown *i.e global team list
export const getTeamDatilsById = (teamId, isTeamChangeFromDropDown = false) => {
  return (dispatch) => {
    // dispatch(fetchDataReq());
    APIclient.getData(`/api/v1/org/${GetOrgId()}/team/${teamId}`)
      .then((data) => {
        if (data.data.status === HTTP_STATUS_CODE.FORBIDDEN) {
          source.cancel("Operation canceled for error");
        } else if (data.data.status > HTTP_STATUS_CODE.OK) {
          dispatch(updateTeam("unknown"));
          source.cancel("Operation canceled for error");
        } else {
          return data;
        }
      })
      .then((data) => {
        const { team } = data.data.payload;
        const { name = "", your_membership = "", your_role = "", company_role = "", team_members = [] } = team || {};
        dispatch(fetchDataSuccess(data.data, []));
        dispatch(updateTeam(team.id));
        dispatch(setUserWallet(team));
        dispatch(setUserRoleMembership(your_role, your_membership, company_role));
        isTeamChangeFromDropDown &&
          sendIdentifyEvent(name, getTeamRole(team_members, GetUserId(), your_membership), company_role);
      })
      .catch((err) => {
        console.log(err);
        // dispatch(fetchDataError(err));
      });
  };
};

export const getTeamsByUserId = (userId) => {
  return (dispatch) => {
    dispatch(fetchTeamByUserReq());
    APIclient.getData(`/api/v1/org/${GetOrgId()}/user/${userId}/memberships`)
      .then((data) => {
        if (data.data.status > HTTP_STATUS_CODE.OK) {
          dispatch(fetchTeamByUserFailure());
        } else {
          dispatch(fetchTeamByUserSuccess(data.data));
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(fetchTeamByUserFailure());
      });
  };
};
