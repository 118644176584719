import React from "react";
import { ALIGN_ACTION_ITEM, IActionItem, ITableProps } from "Modules/DS/DataTable";
import TransactionFilters, { ITransactionFilter } from "../TransactionFilter";
import { EWorkflowApiStatus, EWorkflowStatus, IFilter, ITransaction } from "../type";
import { TRANSACTION_TYPES } from "Views/Transaction/Constants";
import { PaginationProps } from "antd/lib/pagination";
import { includesReversalTrxns } from "Views/Transaction/Helper";
import { TRANSACTION_TYPE } from "constants/Transaction.constant";

interface GenerateAction {
  filter: ITransactionFilter;
  onApplyFilter: (newFilter: ITransactionFilter, callApi?: boolean) => void;
  resetFilter: (keys: string | string[], callApi?: boolean) => void;
  clearSelection: () => void;
  status?: () => EWorkflowApiStatus;
}

// Map to value recorded on BE
const syncStatusMap = {
  [EWorkflowApiStatus.COMPLETED]: "2",
  [EWorkflowApiStatus.FAILED_SYNC]: "-1",
  [EWorkflowApiStatus.QUEUED]: "3",
  [EWorkflowApiStatus.MISSING_INFO]: "5",
  [EWorkflowApiStatus.PENDING_REVIEW]: "4",
};

export const generateActions: (param: GenerateAction) => IActionItem[] = ({
  filter,
  onApplyFilter,
  resetFilter,
  clearSelection,
  status,
}) => {
  const syncStatus = syncStatusMap[status()];
  filter.xero_sync_status = syncStatus;

  return [
    {
      id: "PENDING-WORKFLOW-002",
      enabled: true,
      align: ALIGN_ACTION_ITEM.LEFT,
      component: (
        <TransactionFilters
          filter={filter}
          accountingStatus={status()}
          applyFilter={(filter, callApi) => {
            onApplyFilter(filter, callApi);
            clearSelection();
          }}
          resetFilter={(keys, callApi = true) => {
            resetFilter(keys, callApi);
            clearSelection();
          }}
        />
      ),
    },
  ];
};

export const generateSubTab: (status: EWorkflowApiStatus, data: any) => IFilter[] = (status, data) => {
  return [
    {
      id: 1,
      active: status === EWorkflowApiStatus.MISSING_INFO,
      text: EWorkflowStatus.INCOMPLETE_DETAILS,
      counter: data.count[EWorkflowApiStatus.MISSING_INFO],
      status: EWorkflowApiStatus.MISSING_INFO,
    },
    {
      id: 2,
      active: status === EWorkflowApiStatus.PENDING_REVIEW,
      text: EWorkflowStatus.READY_TO_REVIEW,
      counter: data.count[EWorkflowApiStatus.PENDING_REVIEW],
      status: EWorkflowApiStatus.PENDING_REVIEW,
    },
  ];
};

export const generateSuccessMessage: (
  connected: boolean,
  status: EWorkflowApiStatus,
  count: number,
  withoutSync?: boolean
) => string = (connected, status, count, withoutSync) => {
  const transaction = count === 1 ? "transaction" : "transactions";
  const reviewed = status === EWorkflowApiStatus.PENDING_REVIEW ? "reviewed &" : "";

  if (withoutSync) {
    return `${count} ${transaction} successfully saved and marked as completed without sync.`;
  }

  if (!connected) {
    return `${count} ${transaction} successfully saved and marked as completed.`;
  }

  return `${count} ${transaction} successfully ${reviewed} queued for sync. You can check the transaction sync status at the Pending Sync tab.`;
};

export const defineSize = (tags, connected) => {
  const tagSize = tags.length;
  const markAsCompletedSize = connected ? 1 : 0;
  const basicItem = 2; // Send email reminder & Edit Tax
  const total = tagSize + markAsCompletedSize + basicItem;
  return `item__${total}`;
};

export const doesntHaveAttachment = (transactionType) => {
  return [
    TRANSACTION_TYPES.FEES,
    TRANSACTION_TYPES.INCENTIVE,
    TRANSACTION_TYPES.TOPUP,
    TRANSACTION_TYPES.FEES_REFUND,
  ].includes(transactionType);
};

export const getUniqueEmployeeCount = (transactions: ITransaction[], selectedIds: string[]) => {
  return new Set(
    transactions
      .filter((transaction) => selectedIds.includes(transaction.id))
      .filter((transaction) => !doesntHaveAttachment(transaction.transactionType))
      .filter((transaction) => transaction.receipt?.length === 0 || !transaction.receipt)
      .map((transaction) => transaction.employee.id)
  ).size;
};

export const getRelatedTransaction = (transactions: ITransaction[], selectedTransaction: ITransaction) => {
  return transactions.find(
    (transaction: ITransaction) =>
      transaction.transactionNumber === selectedTransaction.transactionNumber &&
      transaction.id !== selectedTransaction.id
  );
};

export const itemRender: PaginationProps["itemRender"] = (_, type, originalElement) => {
  if (type === "page" || type === "jump-next" || type === "jump-prev") {
    return undefined;
  } else {
    return originalElement;
  }
};

export const PaginationDetails = ({
  qty,
  range,
  limit,
  currentPage,
}: {
  qty: number;
  range: [number, number];
  limit: number;
  currentPage: number;
}) => {
  return (
    <div className="trxn_pagination-title">
      <span>
        Showing {range[0]} - {range[1]} of {qty}
      </span>
      <span>
        Page {currentPage + 1} of {Math.ceil(qty / limit)}
      </span>
    </div>
  );
};

export const paginationProps = <T extends object = any>(
  limit: number,
  currentPage: number
): ITableProps<T>["paginationProps"] => ({
  itemRender,
  showLessItems: true,
  showQuickJumper: false,
  className: "trxn_pagination",
  showTotal: (qty: number, range: [number, number]) => (
    <PaginationDetails qty={qty} range={range} limit={limit} currentPage={currentPage} />
  ),
});

export const allowToUpdateTransaction = (trxnType = "") =>
  !includesReversalTrxns(trxnType) && trxnType !== TRANSACTION_TYPE.TOPUP;

export const getTotalAmount = (transactions: ITransaction[], selectedIds: string[]) => {
  return transactions
    .filter((transaction) => selectedIds.includes(transaction.id))
    .reduce(
      (total, transaction) => {
        if (transaction.rawAmount < 0) {
          total.credit += Math.abs(transaction.rawAmount);
        } else {
          total.debit += transaction.rawAmount;
        }
        return total;
      },
      {
        credit: 0,
        debit: 0,
      }
    );
};
