import * as React from "react";
import { Button, notification } from "antd";

import "./FlowCanvas.scss";
import FlowItem, { FlowItemType, LineType } from "./FlowItem";
import { flowStart, flowCondition, flowAdd, flowEnd } from "assets/img";
import FlowCondition from "./FlowCondition";
import SetApprover from "./SetApprover";
import { FlowObject } from "../../types";
import { appNotification } from "Modules/appNotification/appNotification";
import { handleFlowSettingsUpdatedEvent, validateApprovers, validateConditions } from "../../helpers";
import { APPROVAL_FLOW_EVENT_SOURCE } from "Views/ApprovalFlow/constants";

interface FlowCanvasProps {
  isDefault?: boolean;
  isEditing?: boolean;
  flow: FlowObject;
  setFlow: (object) => void;
  title: string;
  processType: string;
  setEnablePublish: (boolean) => void;
  id?: string;
  hasTeamManager: boolean;
}

const FlowCanvas: React.FC<FlowCanvasProps> = ({
  isDefault = false,
  isEditing = false,
  flow = { conditions: {}, steps: [] },
  setFlow = () => undefined,
  title,
  processType,
  setEnablePublish = () => undefined,
  id = null,
  hasTeamManager,
}: FlowCanvasProps): JSX.Element => {
  const handleSetApprover = (value, idx) => {
    const newFlow = { ...flow };
    newFlow.steps[idx - 1] = { step: idx, assignees: value };
    setFlow(newFlow);
  };

  const isConditionValid = isDefault || validateConditions(flow.conditions);
  const isApproverValid = validateApprovers(flow.steps);
  let addApproverSubtitle;
  if (isDefault) {
    if (processType === "invoice") {
      addApproverSubtitle = "Only admins can approve requests in the approval flow.";
    } else if (processType === "reimbursement") {
      addApproverSubtitle = "Only admins or managers can approve requests in default flow.";
    }
  } else {
    addApproverSubtitle = "Assign the approver for this step.";
  }

  return (
    <ul className={"flow-canvas"}>
      <FlowItem
        icon={<img src={flowStart} />}
        title="Request Created"
        subtitle="Requester create a payment request for a specific item."
        lines={{
          out: LineType.Active,
        }}
      />
      {isDefault || (
        <FlowItem
          icon={<img src={flowCondition} />}
          title="Approval Condition"
          subtitle="Requests meeting these condition(s) will follow this workflow. Otherwise, requests will follow the Default Flow."
          style={FlowItemType.Block}
          lines={{
            in: LineType.Active,
            out: isConditionValid ? LineType.Active : LineType.Inactive,
          }}
          disabled={!isEditing}
        >
          <FlowCondition
            title={title}
            conditions={flow.conditions}
            setConditions={(conditions) => {
              setFlow({ ...flow, conditions });
              handleFlowSettingsUpdatedEvent(APPROVAL_FLOW_EVENT_SOURCE.ConditionEdited, isDefault, id, processType);
            }}
            processType={processType}
            isDefault={isDefault}
            setEnablePublish={setEnablePublish}
          />
        </FlowItem>
      )}
      {flow.steps.map((step, idx) => {
        const stepCount = idx + 1;
        const onDelete = () => {
          const key = `flow-canvas__delete-notif--${idx}`;
          const steps = [...flow.steps];
          steps.splice(idx, 1);
          setFlow({ ...flow, steps });
          const undoDelete = () => {
            notification.close(key);
            const undeleteSteps = [...flow.steps];
            undeleteSteps.splice(idx, 1, step);
            setFlow({ ...flow, steps: undeleteSteps });
          };
          appNotification.success({
            key,
            message: (
              <div className="flow-canvas__delete-notif">
                <p>Approval step {idx + 1} succesfully deleted </p>
                <span className="flow-canvas__delete-notif__undo-btn" onClick={undoDelete}>
                  Undo
                </span>
              </div>
            ),
          });
        };
        const lineInActive = validateApprovers(flow.steps.slice(0, stepCount - 1));
        const lineOutActive = validateApprovers(flow.steps.slice(0, stepCount));
        return (
          <FlowItem
            icon={stepCount}
            title={`Approval Step ${stepCount}`}
            subtitle={addApproverSubtitle}
            style={FlowItemType.Sequence}
            onDelete={flow.steps.length > 1 && onDelete}
            lines={{
              in: isConditionValid && lineInActive ? LineType.Active : LineType.Inactive,
              out: isConditionValid && lineOutActive ? LineType.Active : LineType.Inactive,
            }}
            disabled={!isEditing}
          >
            <SetApprover
              setValues={(value) => {
                handleSetApprover(value, stepCount);
                handleFlowSettingsUpdatedEvent(APPROVAL_FLOW_EVENT_SOURCE.ApproverEdited, isDefault, id, processType);
              }}
              isDefault={isDefault}
              values={flow.steps}
              step={idx}
              disabled={!isDefault && !Object.keys(flow.conditions).length && stepCount === 1}
              conditions={flow.conditions}
              processType={processType}
              hasTeamManager={hasTeamManager}
            />
          </FlowItem>
        );
      })}
      {flow.steps.length < 3 && !!isEditing && (
        <FlowItem
          icon={<img src={flowAdd} />}
          title="Add More Step"
          subtitle="You can add up to 3 approval steps."
          style={FlowItemType.Dashed}
          lines={{
            in: isConditionValid && isApproverValid ? LineType.Active : LineType.Inactive,
            out: isConditionValid && isApproverValid ? LineType.Active : LineType.Inactive,
          }}
        >
          <Button
            className="flow-canvas__add-step-btn"
            onClick={() => {
              setFlow({ ...flow, steps: [...flow.steps, []] });
            }}
            disabled={!!flow.steps[flow.steps.length - 1]?.assignees?.every((item) => !item.role)}
          >
            + Add More Step
          </Button>
        </FlowItem>
      )}
      <FlowItem
        icon={<img src={flowEnd} />}
        title="End of Flow"
        subtitle="The request will proceed to payment once it has been approved."
        lines={{
          in: isConditionValid && isApproverValid ? LineType.Active : LineType.Inactive,
        }}
      ></FlowItem>
    </ul>
  );
};

export default FlowCanvas;
