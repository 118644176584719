import { createContext } from "react";

import { IEmployee, IEmployeeFilters } from "Redux/Actions/Employee/Get/Employees/types";

import { EMPLOYEES_INITIAL_PAGE_NUMBER, INITIAL_ROLE_UPDATE_CALL_COUNT } from "constants/Employee.constant";

import { IFilter } from "Modules/Filters/types";

import {
  EmployeeModalTypes,
  IModalState,
  initalFiltersState,
  initalModalState,
  initalPopupState,
  intialPendingRequest,
  IPendingRequest,
  IPopupState,
} from "Views/Employee/types";

/*
  types
*/

export interface IEmployeesContext {
  getEmployeesData?: (page?: number, search?: string, filter?: IEmployeeFilters) => void;
  searchVal?: string;
  setSearchVal?: React.Dispatch<React.SetStateAction<string>>;
  filters?: IFilter<IEmployeeFilters>;
  setFilters?: React.Dispatch<React.SetStateAction<IFilter<IEmployeeFilters>>>;
}

export interface IEmployeesModalContext {
  showModal?: IModalState;
  setShowModal?: React.Dispatch<React.SetStateAction<IModalState>>;
  toggleModal?: (type: EmployeeModalTypes, metaData?: any) => void;
  resetModal?: () => void;
}

export interface IEmployeesCSContext {
  supportChat?: IPopupState;
  clearSupportChat?: () => void;
  toggleSupportChat?: (message: string) => void;
}

export interface IEmployeesPaginationContext {
  currentPage?: number;
  setCurrentPage?: React.Dispatch<React.SetStateAction<number>>;
  resetCurrentPage?: () => void;
}

export interface IEmployeesDispatchFailsContext {
  dispatchRoleUpdate?: (requestCount: number, newRoleId: number, employee: IEmployee) => void;
  updateRequestCount?: number;
  pendingRequest?: IPendingRequest;
}

/*
  initialize
*/

export const initialEmployeesContext: IEmployeesContext = {
  getEmployeesData: () => null,
  searchVal: "",
  setSearchVal: () => null,
  filters: { ...initalFiltersState },
  setFilters: () => null,
};

export const initialEmployeesModalContext: IEmployeesModalContext = {
  resetModal: () => null,
  toggleModal: () => null,
  setShowModal: () => null,
  showModal: { ...initalModalState },
};

export const initialEmployeesCSContext: IEmployeesCSContext = {
  supportChat: { ...initalPopupState },
  clearSupportChat: () => null,
  toggleSupportChat: () => null,
};

export const initialEmployeesPaginationContext: IEmployeesPaginationContext = {
  currentPage: EMPLOYEES_INITIAL_PAGE_NUMBER,
  setCurrentPage: () => null,
  resetCurrentPage: () => null,
};

export const initialEmployeesDispatchFailsContext: IEmployeesDispatchFailsContext = {
  dispatchRoleUpdate: () => null,
  pendingRequest: { ...intialPendingRequest },
  updateRequestCount: INITIAL_ROLE_UPDATE_CALL_COUNT,
};

/*
  create context
*/

export const EmployeesPageContext = createContext<IEmployeesContext>(initialEmployeesContext);
export const EmployeesCSContext = createContext<IEmployeesCSContext>(initialEmployeesCSContext);
export const EmployeesModalContext = createContext<IEmployeesModalContext>(initialEmployeesModalContext);
export const EmployeesPaginationContext = createContext<IEmployeesPaginationContext>(initialEmployeesPaginationContext);
export const EmployeesDispatchFailsContext = createContext<IEmployeesDispatchFailsContext>(
  initialEmployeesDispatchFailsContext
);
