import React from "react";
import { Form, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";

import { forgotFunc } from "Redux/Actions";

import { trackEvent } from "utility/analytics";

import "./styles.scss";
import { AuthButton, AuthContentHeader } from "../../../Components";
import { Rule } from "antd/lib/form";

const FormEmail = () => {
  const rules: Rule[] = [
    {
      required: true,
      message: "This field cannot be empty",
    },
  ];

  return (
    <Form.Item name="email" label="Email" rules={rules}>
      <Input
        id="email"
        placeholder="Enter email address"
        aria-label="Email"
        type="email"
        autoComplete="off"
      />
    </Form.Item>
  );
};

export const ForgotPasswordForm = (): React.ReactElement => {
  const dispatch = useDispatch();

  const { loading }: { loading: boolean } = useSelector(
    (state: any) => state.forgotReducer,
  );

  const onFinish = ({ email }) => {
    trackEvent("forgot password submit clicked", {});
    dispatch(forgotFunc(email));
  };

  return (
    <section className="forget-password" id="forgotAuthentication">
      <AuthContentHeader
        title="Reset Your Password"
        description="We will send you an instruction to reset your password to your Spenmo registered email"
      />
      <Form validateTrigger={false} name="forgot" onFinish={onFinish}>
        <FormEmail />
        <AuthButton text="Reset Password" id="sendBtn" loading={loading} />
      </Form>
    </section>
  );
};
