import * as React from "react";
import { useSelector } from "react-redux";

import { IKycStatus } from "Redux/Actions/Employee/Get/KycStatusList/types";

import { ModalComponent } from "Modules/DS/Modal";
import TableRowField from "Modules/tableRowField";
import { TITLE_TYPE } from "Modules/DS/Modal/types";

import { greyCloseIcon } from "assets/img";

const KycModal = () => {
  const KYCStatuses: IKycStatus[] = useSelector((state: any) => state.kycStatusesReducer?.data);

  const renderContent = (role: IKycStatus) => {
    const { code, name, description } = role;

    return <TableRowField key={code} title={name} details={description} />;
  };

  return (
    <>
      <ModalComponent.CloseButton fixToTop src={greyCloseIcon} />
      <ModalComponent.Title titleType={TITLE_TYPE.primaryColor}>KYC Status</ModalComponent.Title>
      {!!KYCStatuses && Array.isArray(KYCStatuses) && KYCStatuses.length > 0 && KYCStatuses.map(renderContent)}
    </>
  );
};

export default KycModal;
