import { ReactNode } from "react";

import { appNotification } from "Modules/appNotification/appNotification";
import { DEFAULT_ERROR_MESSAGE, ErrorCode } from "Views/Bills/V2/constants";

type ToasterConfig = {
  cta?: string;
  onClickCTA?(): void;
  message: ReactNode;
};

type SuccessToasterConfig = Record<string, any> & ToasterConfig;

type ErrorToasterConfig = Record<string, any> & {
  errorCode?: ErrorCode;
} & ToasterConfig;

export const useToaster = () => {
  const notification = {
    success: (config: SuccessToasterConfig) => appNotification.success(config),
    error: (config: ErrorToasterConfig) => {
      const { errorCode, cta, onClickCTA, message = DEFAULT_ERROR_MESSAGE, ...rest } = config;

      const args = {
        cta,
        onClickCTA,
        message,
        ...rest,
      };

      switch (errorCode) {
        case ErrorCode.INVALID_PAYLOAD:
        case ErrorCode.ORG_NOT_ALLOWED: {
          args.cta = "Email Support";
          args.onClickCTA = () => window.open("mailto:support@spenmo.com");
          break;
        }
        case ErrorCode.INTERNAL_SERVER_ERROR: {
          args.cta = "Refresh";
          args.onClickCTA = () => window.location.reload();
          break;
        }
      }

      // general error with unregistered error code
      if (errorCode && !ErrorCode[errorCode]) {
        args.cta = "Email Support";
        args.onClickCTA = () => window.open("mailto:support@spenmo.com");
        args.message = message + `\nError ID: ${errorCode}`;
      }

      return appNotification.error(args);
    },
  };

  return {
    appNotification: notification,
  };
};
