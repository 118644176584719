export enum Operator {
  lt = "lt",
  lte = "lte",
  gt = "gt",
  gte = "gte",
  between = "between",
}

export interface AmountConditionObject {
  operator: Operator;
  value: string;
  error?: string;
}

export interface CategoryConditionObject {
  id: string;
  name?: string;
  isDeleted?: boolean;
  error?: string;
}

export interface WalletConditionObject {
  walletId: string;
  name: string;
  currency?: string;
  error?: string;
  isDeleted?: boolean;
}

export interface ConditionFormProps {
  values: any;
  setValues: (values) => void;
  isExpanded: boolean;
  toggleExpand: () => void;
}

export interface FlowConditionsSubMenu {
  mapToValues: (string) => any;
  options: Array<{
    label: string;
    value: string;
  }>;
}

export interface FlowConditionsInterface {
  [key: string]: {
    icon: string;
    title: string;
    desc: string;
    subMenu?: FlowConditionsSubMenu;
    render: (ConditionFormProps) => React.ReactElement;
  };
}
export interface FlowObject {
  conditions?: ConditionObject;
  steps: Array<MultiApproversObject>;
}

export interface ConditionObject {
  wallet?: Array<WalletConditionObject>;
  expenseCategory?: Array<CategoryConditionObject>;
  amount?: AmountConditionObject;
}

export interface MultiApproversObject {
  step?: number;
  assignees?: Array<ApproverObject>;
}

export interface ApproverObject {
  step?: number;
  role?: "admin" | "user" | "manager";
  identifier?: string;
  assignee?: AssigneeObject;
}

export interface AssigneeObject {
  id: string;
  name: string;
  isDeleted: boolean;
  error: string;
  role: string;
  isInvalidRole?: boolean;
}

export interface CreateApprovalFlowPayload {
  processType: string;
  name: string;
  conditions: {
    wallet?: Array<string>;
    amount?: AmountConditionObject;
    expenseCategory?: Array<string>;
  };
  steps: Array<StepsPayload>;
}
export interface CreateFlowUrlParams {
  processType: string;
}

export interface StepsPayload {
  step: number;
  approvers: Array<ApproverObject>;
}

interface SelfApprovalUsersPayload {
  assigneeRole: string;
  identifire?: string;
}

export interface UpdateSelfApprovalParams {
  processType: string;
  action: string;
  users?: Array<SelfApprovalUsersPayload>;
}
