import React, { useRef, useState } from "react";
import cn from "classnames";
import { Skeleton } from "antd";
import { Typography } from "@spenmo/splice";
import { XYChart, lightTheme, Tooltip, AnimatedAreaSeries, AnimatedAxis } from "@visx/xychart";
import { LinearGradient } from "@visx/gradient";

import { formatDate } from "utility/DateUtilites";
import { trackEvent } from "utility/analytics";
import { SpendAnalysisType, SpendingSummaryType } from "Views/SubscriptionManagement/@types";
import { LAST_MONTH_SLICE, MONTH_IN_A_YEAR } from "Views/SubscriptionManagement/Constants";
import { SUBSCRIPTION_EVENT } from "Views/SubscriptionManagement/Analytic";
import { currencyFormatterV2 } from "utility";
import { CurrencyText, Dropdown, Label } from "../components";
import { accumulateTotalSpend } from "../SpendAnalysisChart/utils";
import { getCookieValue, cookieNames } from "utility/CookieHelper";

import styles from "./styles.module.scss";

export const TotalSpendChart = ({
  totalSpendData,
  spendingSummaryData,
  isLoading,
}: {
  totalSpendData: SpendAnalysisType[];
  spendingSummaryData: SpendingSummaryType;
  isLoading: boolean;
}) => {
  const parentRef = useRef(null);
  const width = parentRef.current?.clientWidth || 370;
  const height = 80;

  const [slice, setSlice] = useState(0);
  const [month, setMonth] = useState<keyof SpendingSummaryType["totalSpend"]>("12_m");

  const getlastMonthData = () => {
    // This process is needed to show two point of data in a chart
    // When user selecting last month from the dropdown
    const lastMonthSpend = totalSpendData.at(-1);
    const [year, date] = lastMonthSpend.date.split("-");
    const dummyLastMonthSpend = {
      ...lastMonthSpend,
      date: `${year}-${date}-02`,
    };

    return [lastMonthSpend, dummyLastMonthSpend];
  };

  const data = slice === LAST_MONTH_SLICE ? getlastMonthData() : accumulateTotalSpend(totalSpendData.slice(slice));

  const getDate = (d: SpendAnalysisType) => d.date;
  const getTotalSpend = (d: SpendAnalysisType) => Number(d.actual);

  const accessors = {
    x: {
      Actual: getDate,
    },
    y: {
      Actual: getTotalSpend,
    },
    date: getDate,
  };

  const generateTicks = (value: keyof SpendingSummaryType["totalSpend"]) => {
    const [numTick] = value.split("_");
    setSlice(MONTH_IN_A_YEAR - Number(numTick));
    setMonth(value);
    trackEvent(SUBSCRIPTION_EVENT.INTERACTION_WITH_SUBSCRIPTION_ANALYTIC, {
      action_required_event_source: "Total spend month dropdown",
    });
  };

  if (isLoading) {
    return (
      <div className={styles.loadingContainer}>
        <div className={styles.chart}>
          <Skeleton.Input size="small" active className={cn(styles.inputCell, styles.small)} />
          <Skeleton.Input size="small" active className={cn(styles.inputCell, styles.small)} />
        </div>
        <Skeleton.Input size="small" active className={cn(styles.inputCell, styles.big)} />
      </div>
    );
  }

  return (
    <>
      <div className={styles.chart} ref={parentRef}>
        <div>
          <Label text="Total spend">
            <CurrencyText
              amount={spendingSummaryData.totalSpend[month].spend}
              percentage={spendingSummaryData.totalSpend[month].variation}
            />
          </Label>
        </div>

        <Dropdown
          options={[
            {
              label: "Last 12 months",
              value: "12_m",
            },
            {
              label: "Last 6 months",
              value: "6_m",
            },
            {
              label: "Last 3 months",
              value: "3_m",
            },
            {
              label: "Last month",
              value: "0_m",
            },
          ]}
          defaultValue="12_m"
          onChange={generateTicks}
        />
      </div>

      <XYChart
        theme={{
          ...lightTheme,
          colors: ["var(--blue-060)"],
        }}
        yScale={{ type: "linear" }}
        xScale={{ type: "band" }}
        height={height}
        margin={{ top: 0, right: 4, bottom: 20, left: 4 }}
        width={width}
      >
        <rect x={0} y={0} fill="var(--white-0)" />
        <LinearGradient id="area-gradient" from="var(--navy-010)" to="var(--navy-0)" toOpacity={0.1} />

        <AnimatedAreaSeries
          dataKey="Actual"
          data={data}
          xAccessor={accessors.x.Actual}
          yAccessor={accessors.y.Actual}
          fill="url(#area-gradient)"
        />
        <AnimatedAxis
          key={`time-axis`}
          animationTrajectory="max"
          orientation={"bottom"}
          numTicks={data.length}
          hideTicks
          stroke="var(--border-neutral-subtle)"
          tickFormat={(date) => formatDate(new Date(date), "MMM")}
          tickLabelProps={{
            fill: "var(--text-body-default)",
            fontSize: 11,
            fontFamily: "var(--font-noto-sans)",
            textAnchor: "middle",
          }}
        />

        <Tooltip<SpendAnalysisType>
          showHorizontalCrosshair={false}
          showVerticalCrosshair={false}
          snapTooltipToDatumX
          snapTooltipToDatumY
          showDatumGlyph
          renderTooltip={({ tooltipData }) => {
            return (
              <div className={styles.tooltip}>
                <Typography tag="p" variant="body-content" size="m" weight={600} className={styles.amount}>
                  {currencyFormatterV2(
                    tooltipData.nearestDatum?.datum?.actual || 0,
                    getCookieValue(cookieNames.CURRENCY_CODE),
                    true,
                    2
                  )}
                </Typography>
                <Typography tag="p" variant="body-content" size="m" className={styles.date}>
                  {formatDate(tooltipData.nearestDatum?.datum?.date || new Date(), "MMM YY")}
                </Typography>
              </div>
            );
          }}
        />
      </XYChart>
    </>
  );
};
