import React, { useContext, useState } from "react";
import dayjs from "dayjs";
import cn from "classnames";
import { ChevronDown, toast } from "@spenmo/splice";

import { VendorImage } from "Views/SubscriptionManagement/Components/VendorImage";
import { PopoverWithBanner } from "Views/Transaction/Popover";
import { useStaticSubscription } from "Views/SubscriptionManagement/hooks/useSubscriptionList";
import { IExtendedTitleId, ISubscription, IUpdateSubscriptionItem } from "Views/SubscriptionManagement/@types";
import { CONTRACT_TYPE, NOT_A_SUBSCRIPTION, SUBSCRIPTION_DROPDOWN } from "Views/SubscriptionManagement/Constants";
import { IPopoverWithBanner } from "Views/Transaction/@types";
import { ISelectContext, SelectContext } from "Modules/DS/Select";
import { POPOVER_WIDTH } from "Modules/DS/Popover";
import { ListContainer } from "Modules/DS/Filter/Quick/Container/List";
import { LINE_TYPE } from "Modules/DS/Menu";

import styles from "./index.module.scss";

const UpdateSubscriptionItem = ({
  defaultSubscription,
  transactionDate,
  transactionId,
  onChange,
}: IUpdateSubscriptionItem) => {
  const [selectedSubscription, setSelectedSubscription] = useState(defaultSubscription);
  const { subscriptions } = useStaticSubscription();
  const { select, onSelectHandler, closeSelect } = useContext<ISelectContext>(SelectContext);

  const subscriptionList = [NOT_A_SUBSCRIPTION].concat(
    subscriptions.map((subscription) => ({
      id: subscription.id,
      title: subscription.vendor?.name,
      disabled: isDisabled(subscription, transactionDate),
      IconComponent: (
        <VendorImage
          vendor={subscription.vendor?.name}
          width={18}
          height={20}
          disabled={isDisabled(subscription, transactionDate)}
        />
      ),
    }))
  );
  const activeDropdown = select.show;

  const onDropdownContentHandler = (e: React.MouseEvent<Element, MouseEvent>) => {
    e.stopPropagation();
    if (activeDropdown) return;

    // Setting a dynamic min-height when user opening the dropdown
    // To prevent the popover is partially hidden when the table has only
    // Small amount of data
    const element = document.querySelector<HTMLDivElement>(".ant-table-content");
    if (element) {
      element.style.minHeight = "39rem";
    }

    onSelectHandler(SUBSCRIPTION_DROPDOWN);
  };

  const handleOnClose = () => {
    // Reset the dynamic min height when closing the popover
    const element = document.querySelector<HTMLDivElement>(".ant-table-content");

    if (element) {
      element.style.minHeight = null;
    }
  };

  const handleChoosingSubscription = (id) => {
    const subscription = subscriptionList.find((subscription) => id === subscription.id);
    closeSelect();

    if (subscription.disabled) {
      toast.danger(
        "You cannot select this subscription since the end date of this subscription is before the transaction date."
      );
      return;
    }
    setSelectedSubscription(subscription);
    onChange({ subscriptionId: subscription.id, transactionId });

    if (defaultSubscription.id !== subscription.id) {
      toast.success(`You have changed the subscription for this transaction to ${subscription.title}.`, {
        duration: 1000,
      });
    }
  };

  const renderPopoverChildren = (
    <ListContainer
      lineProps={(item: IExtendedTitleId) => ({
        id: item.id,
        title: item.title,
        type: LINE_TYPE.SINGLE_LINE,
        IconComponent: item.IconComponent,
        disabled: item.disabled,
        onClick: (e) => {
          e.stopPropagation();
          handleChoosingSubscription(item.id);
        },
      })}
    >
      {subscriptionList}
    </ListContainer>
  );

  const popoverProps: IPopoverWithBanner = {
    onClose: handleOnClose,
    children: renderPopoverChildren,
    active: activeDropdown,
    width: POPOVER_WIDTH.S,
    className: styles.popover,
    containerClass: ".ant-table",
  };

  return (
    <div className={styles.cell} onClick={onDropdownContentHandler}>
      <div className={styles.dropdown}>
        {selectedSubscription.IconComponent}
        <p>{selectedSubscription.title}</p>
        <ChevronDown
          size="16"
          fill="var(--text-body-default)"
          className={cn({
            [styles.rotateChevron]: activeDropdown,
            [styles.normalChevron]: !activeDropdown,
          })}
        />
      </div>
      <PopoverWithBanner {...popoverProps} />
    </div>
  );
};

const isDisabled = (subscription: ISubscription, transactionDate: string) => {
  if (subscription.contract_type === CONTRACT_TYPE.ROLLING) {
    return false;
  }

  return dayjs(subscription.expiry).isBefore(transactionDate);
};

export default UpdateSubscriptionItem;
