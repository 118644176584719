import { KYX_FORMS_KEYS, REMARKS_TYPES } from "Views/KYX/Constants";
import {
  IKYXSubmitFormResponseData,
  IKYXSubmitFormRequestData,
  IKYXFetchFormData,
  IRemarksSummary,
} from "Views/KYX/@types";

export interface IMetaData {
  path: string;
  code: number;
  message: string;
  timestamp: string;
  error?: string;
}

export interface IErrorData {
  code: number;
  message: string;
}

export interface IGenericSuccessPayload {
  isSuccess: boolean;
  message: string;
}

export interface IKyxApiResponse<T> {
  payload?: T;
  status: number;
  meta: IMetaData;
  error?: IErrorData;
}

export interface IStartKYXManuallyPayload extends IGenericSuccessPayload {
  createdId: string;
}

export interface IStartKYXManuallyResponse extends IKyxApiResponse<IStartKYXManuallyPayload> {}

export interface ISubmitKYXResponsePayload {
  [KYX_FORMS_KEYS.SAVE_FORM_ID]: string;
  [KYX_FORMS_KEYS.FORM]: IKYXSubmitFormResponseData;
}

export interface ISubmitKYXResponse extends IKyxApiResponse<ISubmitKYXResponsePayload> {}

export interface ISubmitKYXRequest {
  [KYX_FORMS_KEYS.FORM_ID]: string;
  [KYX_FORMS_KEYS.FORM]: IKYXSubmitFormRequestData;
}

export interface ISaveAsDraftResponsePayload {
  [KYX_FORMS_KEYS.SAVE_FORM_ID]: string;
  [KYX_FORMS_KEYS.FORM]: IKYXSubmitFormResponseData;
}

export interface ISaveAsDraftResponse extends IKyxApiResponse<ISaveAsDraftResponsePayload> {}

export interface ISaveAsDraftRequest {
  [KYX_FORMS_KEYS.FORM_ID]: string;
  [KYX_FORMS_KEYS.SOURCE]?: string;
  [KYX_FORMS_KEYS.FORM]: IKYXSubmitFormRequestData;
}

export interface IFetchFormSubmissionPayload {
  [KYX_FORMS_KEYS.SAVE_FORM_ID]: string;
  [KYX_FORMS_KEYS.SOURCE]: string;
  [KYX_FORMS_KEYS.FORM]: IKYXFetchFormData;
}

export enum FORM_SOURCE_TYPE {
  MY_INFO = "MyInfo",
}

export interface IFetchFormSubmissionResponse extends IKyxApiResponse<IFetchFormSubmissionPayload> {}

export interface IUserConsentPayload {
  isSuccess: boolean;
  message?: string;
}
export interface IUserConsentResponse extends IKyxApiResponse<IUserConsentPayload> {}

export interface IGetFormConfigsPayload {
  [REMARKS_TYPES.COMPANY_INFORMATION]: any; // WIP [need to update with correct type]
  [REMARKS_TYPES.PERSONNEL_INFORMATION]: any; // WIP [need to update with correct type]
  [REMARKS_TYPES.COMPANY_DOCUMENTS]: any; // WIP [need to update with correct type]
  [REMARKS_TYPES.DUE_DILIGENCE_DOCUMENTS]: any; // WIP [need to update with correct type]
}

export interface IGetFormConfigsResponse extends IKyxApiResponse<IGetFormConfigsPayload> {}
export interface IRemarksSummaryPayload {
  [REMARKS_TYPES.COMPANY_INFORMATION]: IRemarksSummary;
  [REMARKS_TYPES.PERSONNEL_INFORMATION]: IRemarksSummary;
  [REMARKS_TYPES.COMPANY_DOCUMENTS]: IRemarksSummary;
  [REMARKS_TYPES.DUE_DILIGENCE_DOCUMENTS]: IRemarksSummary;
}

export interface IRemarksSummaryResponse extends IKyxApiResponse<IRemarksSummaryPayload> {}
export interface IGetUserConsentPayload {
  isOnboardingFinish: boolean;
  isAgreedLatestTnC: boolean;
}
export interface IGetUserConsentResponse extends IKyxApiResponse<IGetUserConsentPayload> {}
