import React, { useState } from "react";
import PropTypes from "prop-types";
import { Upload } from "antd";
import { UploadFile } from "../../Redux/DataCalls/UploadImage.api";
import { customFileUpload } from "../../Redux/DataCalls/CustomFileUpload.api";
import UploadStatusView from "./uploadStatusView";
import { HTTP_STATUS_CODE } from "../../constants/HTTPStatusCode.constant";
import { validateFileType } from "utility";
import "./fileUpload.scss";

const FileUpload = (props) => {
  const {
    title,
    multiple = false,
    accept = ".jpg",
    format = ["image/png"],
    action,
    formatMessage,
    useCustomAPI = false,
    customUploadURL,
    customUploadAction,
  } = props;
  const { Dragger } = Upload;
  const [fileInfo, setFileInfo] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [progress, setProgress] = useState(0);
  const [bulkError, setBulkError] = useState(false);

  const uploadProps = {
    name: "file",
    type: "file",
    multiple: multiple,
    accept: accept,
    customRequest: ({ onSuccess, onError, file }) => {
      if (validateFileType(format, file?.type)) {
        uploadProps.onUploadProgress();
        if (useCustomAPI) {
          customFileUpload(file, customUploadURL)
            .then((res) => {
              customUploadAction(res);
              if (res.data.status === HTTP_STATUS_CODE.CREATED) {
                setSuccessMsg(res?.data?.payload?.message);
                action("success");
                onSuccess();
              } else {
                setSuccessMsg(res?.data?.payload?.message);
                action("error");
                setFileInfo({ status: "error" });
                setErrorMsg(res?.data?.error?.message);
                onError();
                setBulkError(true);
              }
            })
            .catch((err) => err);
        } else {
          UploadFile(file)
            .then((res) => {
              if (res.data.status === HTTP_STATUS_CODE.OK) {
                onSuccess(null, res.data.payload.file_path);
              } else {
                setErrorMsg(res?.data?.payload?.status_message);
                onError();
              }
            })
            .catch((err) => err);
        }
      }
    },
    itemRender: () => {
      return null;
    },
    onChange(info) {
      if (validateFileType(format, info?.file?.type)) {
        const { status } = info.file;
        setFileInfo(info.file);
        !useCustomAPI && action(null);
        if ((status === "done" || status === "success") && !useCustomAPI) {
          action(info?.fileList?.[0]?.xhr);
        }
      }
    },
    onUploadProgress: () => {
      let now = 0;
      let completed = 100;

      for (let i = now; i <= completed; i++) {
        setProgress(now);
        now++;
      }
    },
  };

  return (
    <Dragger className={"file-upload"} {...uploadProps}>
      <h3 className={"file-upload__title"}>{title}</h3>
      <div className={"file-upload__content"}>
        <UploadStatusView
          fileInfo={fileInfo}
          formatMessage={formatMessage}
          errorMsg={errorMsg}
          successMsg={successMsg}
          progress={progress}
          bulkError={bulkError}
        />
      </div>
    </Dragger>
  );
};

FileUpload.propTypes = {
  title: PropTypes.any,
  formatMessage: PropTypes.string,
  multiple: PropTypes.bool,
  accept: PropTypes.string,
  format: PropTypes.array,
  action: PropTypes.func,
  useCustomAPI: PropTypes.bool,
  customUploadURL: PropTypes.string,
  customUploadAction: PropTypes.func,
};

export default FileUpload;
