import { CASBIN_PAGES } from "Permission/Pages";
import { SUBSCRIPTIONS } from "Permission/Actions";

export const CREATE_NEW_SUBSCRIPTION_PARAMS = [
  {
    object: CASBIN_PAGES.SUBSCRIPTION_MANAGEMENT,
    action: SUBSCRIPTIONS.CREATE,
  },
];

export const UPDATE_SUBSCRIPTION_PARAMS = [
  {
    object: CASBIN_PAGES.SUBSCRIPTION_MANAGEMENT,
    action: SUBSCRIPTIONS.UPDATE,
  },
];

export const RENEW_SUBSCRIPTION_PARAMS = [
  {
    object: CASBIN_PAGES.SUBSCRIPTION_MANAGEMENT,
    action: SUBSCRIPTIONS.RENEW,
  },
];

export const TOGGLE_SUBSCRIPTION_STATUS_PARAMS = [
  {
    object: CASBIN_PAGES.SUBSCRIPTION_MANAGEMENT,
    action: SUBSCRIPTIONS.STOP,
  },
  {
    object: CASBIN_PAGES.SUBSCRIPTION_MANAGEMENT,
    action: SUBSCRIPTIONS.RESTART,
  },
];

export const SUBSCRIPTION_ANALYTICS_PARAMS = [
  {
    object: CASBIN_PAGES.SUBSCRIPTION_MANAGEMENT,
    action: SUBSCRIPTIONS.SUMMARY,
  },
];
