import APIclient from "API/Client";
import { AxiosRequestConfig } from "axios";
import { VerifyBICPayload } from "../fields/Custom/BankValidation/types";
import { DynamicFormProps } from "../types";

const paramsBuilder = (params: Record<string, any>) => {
  const urlSearchParams = new URLSearchParams();

  Object.entries(params).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((item) => urlSearchParams.append(key, item));
    } else {
      urlSearchParams.set(key, value);
    }
  });

  return urlSearchParams.toString();
};

export const fetchDynamicFields = (params: Omit<DynamicFormProps, "form">, configs?: AxiosRequestConfig) => {
  return APIclient.getData("/ms/spm-disbursement/v1/bill/form-fields", paramsBuilder(params), false, {
    ...configs,
  });
};

export const verifyBIC = (payload: VerifyBICPayload, configs?: AxiosRequestConfig) => {
  const { bicType, bic, ...rest } = payload;
  return APIclient.getData(
    `/ms/spm-disbursement/v1/bank-analytic/${bicType}/${bic}/verify`,
    paramsBuilder(rest),
    false,
    {
      ...configs,
    }
  );
};
