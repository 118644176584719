import APIclient from "API/Client";

export const FETCH_ORG_DETAIL_REQUEST = "FETCH_ORG_DETAIL_REQUEST";
export const FETCH_ORG_DETAIL_SUCCESS = "FETCH_ORG_DETAIL_SUCCESS";
export const FETCH_ORG_DETAIL_ERROR = "FETCH_ORG_DETAIL_ERROR";

export const fetchOrgDetailReq = (data) => {
  return {
    type: FETCH_ORG_DETAIL_REQUEST,
    data: data,
  };
};

export const fetchOrgDetailSuccess = (data) => {
  return {
    type: FETCH_ORG_DETAIL_SUCCESS,
    data: data,
  };
};

export const fetchOrgDetailError = (data) => {
  return {
    type: FETCH_ORG_DETAIL_ERROR,
    data: data,
  };
};

export const getOrgDetailFunc = (orgID) => {
  return (dispatch) => {
    dispatch(fetchOrgDetailReq());

    APIclient.getData("/ms/spm-organization/v1/organisation/" + orgID)
      .then((res) => {
        if (res.data.status > 200) {
          dispatch(fetchOrgDetailError(res.data));
        } else {
          dispatch(fetchOrgDetailSuccess(res.data));
        }
      })
      .catch((err) => {
        dispatch(fetchOrgDetailError(err));
      });
  };
};
