import React, { useContext, useState } from "react";
import { PaginationProps } from "antd/lib/pagination";

import { ITableProps } from "Modules/DS/DataTable";

import { ITrxnPaginationContext } from "Views/Transaction/@types";
import { TrxnPaginationContext } from "Views/Transaction/Context";
import { TRXN_ELEMENT_CLASS, INITIAL_TRXNS_PAGINATION } from "Views/Transaction/Constants";

export const usePagination = (): [number, React.Dispatch<React.SetStateAction<number>>, () => void] => {
  const [currentPage, setCurrentPage] = useState(INITIAL_TRXNS_PAGINATION);

  const resetCurrentPage = (): void => setCurrentPage(INITIAL_TRXNS_PAGINATION);

  return [currentPage, setCurrentPage, resetCurrentPage];
};

export const itemRender: PaginationProps["itemRender"] = (_, type, originalElement) => {
  if (type === "page" || type === "jump-next" || type === "jump-prev") {
    return undefined;
  } else {
    return originalElement;
  }
};

export const PaginationDetails = ({ qty, range, limit }: { qty: number; range: [number, number]; limit: number }) => {
  const { currentPage }: ITrxnPaginationContext = useContext(TrxnPaginationContext);

  return (
    <div className={TRXN_ELEMENT_CLASS.PAGINATION_CONTENT}>
      <span>
        Showing {range[0]} - {range[1]} of {qty}
      </span>
      <span>
        Page {currentPage + 1} of {Math.ceil(qty / limit)}
      </span>
    </div>
  );
};

export const paginationProps = <T extends object = any>(limit: number): ITableProps<T>["paginationProps"] => ({
  itemRender,
  showLessItems: true,
  showQuickJumper: false,
  className: TRXN_ELEMENT_CLASS.PAGINATION,
  showTotal: (qty: number, range: [number, number]) => <PaginationDetails qty={qty} range={range} limit={limit} />,
});
