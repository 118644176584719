import React, { useEffect } from "react";
import { Form, Row } from "antd";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import UploadFile from "../../Modules/UploadFile/UploadFile";
import Icon from "../../Modules/icons";
import FileDocImg from "../../assets/icons/file-doc.svg";
import { appNotification } from "../../Modules/appNotification/appNotification";
import { uploadPayBills } from "../../Redux/DataCalls/UploadImage.api";
import useLoading from "../../utility/useLoading";
import { isPayBillsEnabled } from "./PayBillsHelper";
import styles from "./PayBills.module.scss";

const PayBills = ({ user }) => {
  const ALLOWED_FILE_EXTENSIONS = ["xlsx"];
  const [uploadFileAction, isLoading] = useLoading(uploadPayBills);
  const history = useHistory();

  useEffect(() => {
    if (!isPayBillsEnabled(user)) {
      history.push("/404");
    }
  }, []);

  const validateFile = (file) => {
    const fileExtension = file.name.split(".").pop();
    const isFileValid = ALLOWED_FILE_EXTENSIONS.includes(fileExtension);

    if (!isFileValid) {
      appNotification.error({
        message: `Only [${ALLOWED_FILE_EXTENSIONS.join(", ")}] extensions are allowed`,
      });
    }
    return isFileValid;
  };

  const uploadFile = async ({ payload, onUploadProgress }) => {
    const { data } = await uploadFileAction(payload.file, onUploadProgress);
    if (data.status === 200) {
      appNotification.success({
        message: "New payments submitted to be paid automatically.",
      });
      return data;
    } else {
      throw data.payload?.status_message;
    }
  };

  return (
    <Row justify={"center"}>
      <section className={"section"}>
        <div className={"section__header"}>Upload XLSX file for multiple payments</div>
        <Form>
          <Form.Item name="images">
            <Row justify={"center"}>
              <UploadFile
                maxCount={1}
                beforeUpload={validateFile}
                customRequest={uploadFile}
                loading={isLoading}
                icon={<Icon src={FileDocImg} />}
                text={"Drop a file here to get started"}
                hint={"XLSX format"}
                className={styles.uploader}
              />
            </Row>
          </Form.Item>
        </Form>
      </section>
    </Row>
  );
};

PayBills.propTypes = {
  user: PropTypes.object,
};

export default connect((state) => ({
  user: state.userInfoReducer,
}))(PayBills);
