import React from "react";
import PropTypes from "prop-types";
import { Checkbox, Form, Input } from "antd";
import DatePicker from "../datePicker";
import DropDown from "../dropDown";
import Button from "../button";
import SelectAmount from "../selectAmount";
import { getWalletAmount, currencyFormatter, currencyFormatterWithFractions, setMerchantName, isActive } from "utility";
import UploadImage from "../uploadImage";
import MultiSelection from "../multiSelection";
import "./AmountDetails.scss";
import { trackEvent } from "utility/analytics";
import CategoryDropdown from "Modules/categoryDropdown";
import { AmountDetailsEventMapping, RequestMoneyEventKeys } from "Modules/AmountDetails/AmountDetailsEventMapping";
import LabelWithTooltip from "Modules/LabelWithTooltip";
import { blueInfoCircle } from "assets/img";
import { cookieNames, getCookieValue } from "utility/CookieHelper";

class AmountDetails extends React.Component {
  state = {
    walletData: [],
    amountData: [],
    interval: "",
    amount: "",
    toCurrency: "",
    checkDetails: false,
    images: [],
    comment: "",
    disableZeroLimit: false,
  };

  formRef = React.createRef();

  setWalletAmount = (wallData, id) => {
    this.setState({ amountData: getWalletAmount(wallData, id)[0] });
  };

  componentDidMount() {
    const { initialWalletId } = this.props;
    this.setState({ walletData: this.props.walletData.filter((e) => Boolean(e.name) && e) }, () => {
      const { walletData = [] } = this.state || {};
      this.setWalletAmount(walletData, initialWalletId ? initialWalletId : walletData?.[0]?.id);
      this.setState({
        toCurrency: getWalletAmount(walletData, walletData?.[0]?.id)?.[0]?.currency_code,
      });
      initialWalletId && this.formRef.current.setFieldsValue({ wallet: initialWalletId });
    });
  }

  toggleSwitch = () => {
    this.setState({ checkDetails: !this.state.checkDetails });
  };

  onFinish = (values) => {
    if (this.props.selectedMembersData.length > 0) {
      this.props.action({
        ...values,
        wallet: this.state.amountData,
        ...this.props.memberData,
        to_currency: this.state.toCurrency,
      });
      this.props.onConfirm();
      this.trackRequestMoneyActions(RequestMoneyEventKeys.confirmClicked);
    }
  };

  handleContactDetailsCheck = (e) => {
    const { selectedMembersData, checkError } = this.props;
    if (selectedMembersData.length > 0 && e.target.checked) {
      trackEvent("request physical use your contact detils clicked");
      this.formRef.current.setFieldsValue({
        address_1: selectedMembersData[0].address_1,
        address_2: selectedMembersData[0].address_2,
        country: selectedMembersData[0].country,
        zipcode: selectedMembersData[0].zipcode,
      });
      this.toggleSwitch();
    } else if (selectedMembersData.length > 0 && !e.target.checked) {
      this.formRef.current.setFieldsValue({ address_1: "", address_2: "", country: "", zipcode: "" });
      this.toggleSwitch();
    } else {
      checkError();
    }
  };

  setMerchantData = (list) => {
    trackEvent("create virtual card merchant lock added", {
      merchant_lock: list,
    }); // To track merchant lock added by user
    this.formRef.current.setFieldsValue({ merchant_lock: setMerchantName(list) });
  };

  trackRequestMoneyActions = (eventKey) => {
    const { trackActions = false, actionType = "" } = this.props;
    trackActions &&
      trackEvent(`${actionType?.toLowerCase()} ${AmountDetailsEventMapping[actionType]?.[eventKey] || ""}`);
  };

  setAmount = (val) => {
    if (val !== this.state.amount) {
      this.formRef.current.setFieldsValue({ amount: val });
      this.setState({ amount: val });
    }
  };

  componentDidUpdate(prevProps) {
    const { selectedMembersData } = this.props;
    if (selectedMembersData !== prevProps.selectedMembersData) {
      this.formRef.current.setFieldsValue({
        card_name: "",
        deliver_to_name: "",
        address_1: "",
        address_2: "",
        country: "",
        zipcode: "",
      });
      this.setState({ checkDetails: false });
    }
  }

  handleWalletSelection = (val) => {
    this.formRef.current.setFieldsValue({ wallet: val, merchant_lock: val });
    this.setWalletAmount(this.state.walletData, val);
    this.trackRequestMoneyActions(RequestMoneyEventKeys.walletSelected);
  };

  handleImageUpload = (images) => {
    this.formRef.current.setFieldsValue({ images });
    images && images.length > this.state.images && this.trackRequestMoneyActions(RequestMoneyEventKeys.photoUploaded);
    this.setState({ images });
  };

  handleMessageOnBlur = () => {
    const currentValue = this.formRef.current.getFieldValue("comment");
    currentValue &&
      currentValue !== this.state.comment &&
      this.trackRequestMoneyActions(RequestMoneyEventKeys.messageAdded);
    this.setState({ comment: currentValue || "" });
  };

  showAmount = () => {
    const { showSelectedAmountInFractions = false } = this?.props || {};
    const { amount = "", currency_code = "" } = this?.state?.amountData || {};
    return showSelectedAmountInFractions
      ? currencyFormatterWithFractions(amount, currency_code, 2)
      : currencyFormatter(amount, currency_code);
  };

  render() {
    // TODO: walletData and merchantData to be list or dropDownData - to make it generic

    const amountRaw = [10, 25, 50, 100, 250, 500];
    const dataRaw = [
      { id: "none", name: "No repeat" },
      { id: "Weekly", name: "Weekly" },
      {
        id: "Monthly",
        name: "Monthly",
      },
      { id: "Yearly", name: "Yearly" },
    ];
    const {
      showUpload,
      title,
      isSchedulePayment,
      editData,
      checkError = () => {},
      view = "",
      merchantData,
      intervalsData = [],
      isVirtual = false,
      isPhysical = false,
      trackActions = false,
      initialWalletId = null,
    } = this.props;
    const { TextArea } = Input;
    const { walletData, amountData } = this.state;
    const currencyCode = amountData?.currency_code || getCookieValue(cookieNames.CURRENCY_CODE) || "";
    const merchantInitialValue = isVirtual ? { merchant_lock: merchantData?.[0]?.id } : {};

    return (
      <Form
        ref={this.formRef}
        onFinish={this.onFinish}
        layout="vertical"
        className={"amount-details"}
        initialValues={{ ...merchantInitialValue }}
      >
        {!isPhysical && (
          <div className={"amount-details__select-wallet"}>
            <h4 className="title">{isVirtual ? "Merchant Lock" : title}</h4>
            <Form.Item name={isVirtual ? "merchant_lock" : "wallet"}>
              {view === "" && walletData.length > 0 && (
                <div className={"amount-details__select-wallet--details"}>
                  <DropDown
                    action={this.handleWalletSelection}
                    isWallet
                    team={this.props.teamName}
                    listData={walletData}
                    initialValue={initialWalletId}
                  />
                  <h3> {this.showAmount()} </h3>
                </div>
              )}
              {isVirtual && (
                <MultiSelection
                  defaultValue={["none"]}
                  listData={merchantData}
                  placeholder={"Select Merchant"}
                  action={this.setMerchantData}
                />
              )}
            </Form.Item>
          </div>
        )}

        {isPhysical && (
          <Form.Item
            label={"Card Title"}
            name="card_name"
            className={"amount-details__card-details"}
            rules={[{ required: true, message: "Please Add the Card Title!" }]}
          >
            <Input placeholder="Enter name" />
          </Form.Item>
        )}

        {(isVirtual || isPhysical) && (
          <Form.Item
            label={
              <LabelWithTooltip
                imageSrc={blueInfoCircle}
                label={"Default Expense Category"}
                description={"Every expense in this card will be automatically categorized into the selected category."}
              />
            }
            name="expense_category_id"
            className="amount-details__default-expense-category"
          >
            <CategoryDropdown
              defaultValue={"N/A"}
              action={(val) => this.formRef.current.setFieldsValue({ expense_category_id: val })}
            />
          </Form.Item>
        )}

        {!isPhysical && (
          <Form.Item
            label={isVirtual ? "Spending Limit" : "Amount of"}
            name={"amount"}
            className={"amount-details__select-amount"}
            rules={!isVirtual && [{ required: true, message: "Please Enter the Amount!" }]}
          >
            <SelectAmount
              initialCurrency={currencyCode}
              initialValue={editData && editData.amount}
              showAmountOptions={!(isVirtual || isPhysical)}
              amountOptions={amountRaw}
              action={this.setAmount}
              inputOnBlurHandler={(value) =>
                value && this.trackRequestMoneyActions(RequestMoneyEventKeys.amountEntered)
              }
              trackActions={trackActions}
              disableZeroLimit={this.props.disableZeroLimit}
            />
            {!isVirtual && <p>Enter amount you want to request or tap any of the suggested amounts</p>}
          </Form.Item>
        )}

        {isVirtual && (
          <Form.Item
            name={"interval"}
            rules={
              this.state.amount !== "" && [
                {
                  required: true,
                  message: "Please Select the spending limit!",
                },
              ]
            }
          >
            {intervalsData.length > 0 && (
              <div className="amount-details__intervals">
                {intervalsData.map((item, index) => (
                  <div
                    className={`amount-details__interval ${
                      this.state.interval !== "" && isActive(item.id, this.state.interval) && "active"
                    }`}
                    key={index}
                    onClick={() => {
                      trackEvent("create virtual spending limit period selected", {
                        period: item.name,
                      });
                      this.formRef.current.setFieldsValue({ interval: item.id });
                      this.setState({ interval: item.id });
                    }}
                  >
                    {item.name}
                  </div>
                ))}
              </div>
            )}
          </Form.Item>
        )}

        {isPhysical && (
          <>
            <Form.Item
              label={"Deliver to"}
              name="deliver_to_name"
              className={"amount-details__delivery-to-name"}
              rules={[{ required: true, message: "Please Add the delivery name!" }]}
            >
              <Input placeholder="Enter name" />
            </Form.Item>
            <Form.Item name="cardholder_name" className={"amount-details__contact-details-check"}>
              <Checkbox checked={this.state.checkDetails} onChange={this.handleContactDetailsCheck}>
                Use your contact details
              </Checkbox>
            </Form.Item>
            <div className={"amount-details__shipping-address"}>
              <h4 className={"title"}>Shipping Address</h4>
              <div>
                <Form.Item name="address_1" rules={[{ required: true, message: "Please enter the unit details!" }]}>
                  <Input placeholder="Floor/Unit/Room #" />
                </Form.Item>
                <Form.Item name="address_2" rules={[{ required: true, message: "Please enter the street name!" }]}>
                  <Input placeholder="Street" />
                </Form.Item>
              </div>
              <div>
                <Form.Item name="country" rules={[{ required: true, message: "Please enter the country!" }]}>
                  <Input placeholder="Country" />
                </Form.Item>
                <Form.Item name="zipcode" rules={[{ required: true, message: "Please enter the postal code!" }]}>
                  <Input placeholder="Postal Code" />
                </Form.Item>
              </div>
            </div>
          </>
        )}

        {view === "" && isSchedulePayment && (
          <div className={"amount-details__schedule-payment"}>
            <h4 className={"title"}>Schedule Payment</h4>
            <Form.Item
              name="date_picker"
              rules={isSchedulePayment && [{ required: true, message: "Please Select the Date!" }]}
            >
              <DatePicker
                initialValue={editData && editData.created_at}
                placeholder={"Select Date"}
                picker={"date"}
                format={"DD MMM YYYY"}
                action={(val) => this.formRef.current.setFieldsValue({ date_picker: val })}
              />
            </Form.Item>
            <Form.Item
              name="time_period"
              rules={
                isSchedulePayment && [
                  {
                    required: true,
                    message: "Please Select the Time Period!",
                  },
                ]
              }
            >
              <DropDown
                placeholder="No repeat"
                initialValue={editData && editData.schedule_frequency}
                hasBackground
                listData={dataRaw}
                action={(val) => this.formRef.current.setFieldsValue({ time_period: val })}
              />
            </Form.Item>
          </div>
        )}

        {showUpload && (
          <>
            <Form.Item label={"Add Photo"} className={"amount-details__upload-image"} name="images">
              <UploadImage fileList={this.handleImageUpload} />
            </Form.Item>
            <Form.Item
              label={"Add reason for request"}
              name="comment"
              className={"amount-details__comments"}
              rules={[
                { required: true, message: "Please provide a reason for request!" },
                { max: 255, message: "Please Enter the Message less than 255 characters!" },
              ]}
            >
              <TextArea
                onChange={(e) => this.formRef.current.setFieldsValue({ comment: e.target.value })}
                placeholder="Type message here"
                allowClear
                onBlur={this.handleMessageOnBlur}
                showCount
              />
            </Form.Item>
          </>
        )}

        <Form.Item shouldUpdate={true}>
          {() => (
            <Button
              className={"confirm-btn"}
              rounded
              htmlType="submit"
              action={() => checkError()}
              text={showUpload ? "Confirm Request" : "Confirm"}
            />
          )}
        </Form.Item>
      </Form>
    );
  }
}

AmountDetails.propTypes = {
  walletData: PropTypes.array,
  showSelectedAmountInFractions: PropTypes.bool,
  title: PropTypes.string,
  teamName: PropTypes.string,
  showUpload: PropTypes.bool,
  memberData: PropTypes.object,
  merchantData: PropTypes.array,
  isSchedulePayment: PropTypes.bool,
  editData: PropTypes.object,
  checkError: PropTypes.func,
  action: PropTypes.func,
  onConfirm: PropTypes.func,
  selectedMembersData: PropTypes.array,
  view: PropTypes.string,
  intervalsData: PropTypes.array,
  isVirtual: PropTypes.bool,
  isPhysical: PropTypes.bool,
  receiptUploadAction: PropTypes.func,
  trackActions: PropTypes.bool,
  actionType: PropTypes.string,
  userData: PropTypes.object,
  initialWalletId: PropTypes.string,
};

export default AmountDetails;
