import * as React from "react";
import { useContext } from "react";

import { TitleWithIconButton } from "Modules/DS/DataTable";

import { EmployeeModalTypes } from "Views/Employee/types";
import { EmployeesModalContext, IEmployeesModalContext } from "Views/Employee/context";

const KycTitle = () => {
  const { toggleModal }: IEmployeesModalContext = useContext(EmployeesModalContext);

  return <TitleWithIconButton action={() => toggleModal(EmployeeModalTypes.KYC)}>KYC Status</TitleWithIconButton>;
};

export default KycTitle;
