import React from "react";
import { Skeleton } from "antd";
import { Col, Row } from "Splice/Grid";
import "./shimmer.scss";

export const CategoriesShimmer = ({ loading }) => {
  return (
    <>
      <Row className={"categoriesShimmerContainer"} offset={0}>
        <Col span={10}>
          <p className="shimmerHeading">Top categories by spending</p>
          <Skeleton.Avatar active className="avatar" shape={"circle"} />
        </Col>
        <Col span={7}>
          <p className="shimmerHeading">Current Spending</p>
          {Array.from(Array(4).keys())?.map((key) => (
            <Skeleton key={`current-spending-${key}`} active={loading} paragraph={{ rows: 1 }} />
          ))}
        </Col>
        <Col span={7}>
          <p className="shimmerHeading">Past Spending</p>
          {Array.from(Array(4).keys())?.map((key) => (
            <Skeleton key={`past-spending-${key}`} active={loading} paragraph={{ rows: 1 }} />
          ))}
        </Col>
      </Row>
    </>
  );
};
