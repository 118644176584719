import { MENU_CLASS_NAME } from "Modules/DS/Menu/@types";
import { SELECT_CLASSNAMES } from "Modules/DS/Select/@types";

import labelStyles from "Modules/DS/Chip/Label/Label.module.scss";
import chipStyles from "Modules/DS/Chip/Action/Action.module.scss";
import menuStyles from "Modules/DS/Menu/Actions/Actions.module.scss";
import dropdownStyles from "Modules/DS/Select/Dropdown/Container/Builder.module.scss";
import lineStyles from "Modules/DS/Menu/Line/Line.module.scss";

import downloadBtnStyles from "Views/Transaction/Download/Button.module.scss";
import tableDropdownStyles from "Views/Transaction/List/Column/Dropdown.module.scss";

const ANTD_DATE_PICKER_ELEMENTS = [
  ".ant-picker-dropdown",
  ".ant-picker-panel-container",
  ".ant-picker-cell-inner",
  ".ant-picker-month-btn",
  ".ant-picker-year-btn",
  ".ant-select-item-option-content",
];

const MENU_ACTIONS_BAR = [`.${menuStyles.clear}`, `.${menuStyles.add}`];

const LABEL_CHIP = [`.${labelStyles.container}`, `.${labelStyles.remove}`];

export const IGNORE_ELEMENTS = (): string[] => [
  ...ANTD_DATE_PICKER_ELEMENTS,
  ...MENU_ACTIONS_BAR,
  ...LABEL_CHIP,
  `.${chipStyles.container}`,
  `.${downloadBtnStyles.download}`,
  `.${tableDropdownStyles.dropdown}`,
  `.${lineStyles[MENU_CLASS_NAME.LINE_CONTAINER]}`,
  ".spm-label-chip",
  ".spm-dropdown-title",
  `.${dropdownStyles.label}`,
  `.${SELECT_CLASSNAMES.DROPDOWN_SEARCH}`,
  `.${dropdownStyles[SELECT_CLASSNAMES.DROPDOWN_LABEL]}`,
];
