import { EInputType } from "Modules/TransactionDetail";

export const EXPENSE_INPUT_DETAILS_OPTIONS = {
  [EInputType.TAX]: true,
  [EInputType.NOTES]: true,
  [EInputType.RECEIPT]: true,
  [EInputType.CATEGORY]: true,
  [EInputType.TRANSACTION_TAGS]: true,
  [EInputType.SUBSCRIPTION]: true,
};

export const MULTIPLE_CR_DETAILS_OPTIONS = {
  [EInputType.TAX]: false,
  [EInputType.NOTES]: false,
  [EInputType.RECEIPT]: false,
  [EInputType.CATEGORY]: true,
  [EInputType.TRANSACTION_TAGS]: false,
  [EInputType.SUBSCRIPTION]: false,
};
