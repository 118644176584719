import * as React from "react";
import { trackEvent } from "utility/analytics";
import { EmployeeDeleteEventName, EmployeeToastEventName } from "Views/Employee/Analytics";

import { ModalComponent } from "Modules/DS/Modal";
import { TITLE_TYPE } from "Modules/DS/Modal/types";

import { Requirement } from "Views/Employee/Modal/Requirement";
import usePendingItems from "Views/Employee/Delete/Modal/Resolve/usePendingItems";

const Resolve = ({ name }: { name: string }) => {
  const pendingItems = usePendingItems();

  const title = `Before deleting ${name ? name : ""},\nplease resolve the following issue(s):`;

  React.useEffect(() => {
    trackEvent(EmployeeToastEventName.DELETE_ACCOUNT_STATUS, {
      account_deletion_event_action: EmployeeDeleteEventName.ACCOUNT_DELETION_ELIGIBILITY_CHECK_FAIL,
    });
  }, []);

  const renderPendingItems = () => {
    return pendingItems.map((item) => {
      return <Requirement.Pending key={item.id} {...item.requirement} />;
    });
  };

  return (
    <>
      <ModalComponent.CloseButton
        analytics={{ enabled: true, eventName: EmployeeDeleteEventName.CLOSE_ACCOUNT_DELETION_ELIGIBILITY_FAIL }}
      />
      <ModalComponent.Title titleType={TITLE_TYPE.withBorder}>{title}</ModalComponent.Title>
      {renderPendingItems()}
    </>
  );
};

export default Resolve;
