import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Typography, Tooltip, Tag } from "antd";

import Button from "Modules/button";
import Icon from "Modules/icons";
import DropDown from "Modules/dropDown";

import AryadanaFooter, {
  ARYADANA_FOOTER_TYPE,
} from "Views/Bills/AryadanaFooter";
import Step from "Views/UploadMultiple/Step";
import Uploader from "Views/UploadMultiple/Uploader";
import {
  DOWNLOAD_TEMPLATE_URL,
  COPY_TEMPLATE_URL,
} from "Views/UploadMultiple/const";
import LoadingIcon from "Modules/loading";

import styles from "./UploadNotCompleted.module.scss";
import "./UploadNotCompleted.scss";
import { trackEvent } from "utility/analytics";
import {
  getBulkTemplateVersion as getBulkTemplateVersionAPI,
  updateBulkTemplateVersion as updateBulkTemplateVersionAPI,
} from "Redux/DataCalls/Disbursement.api";

import { infoOutline } from "assets/img";
import { yellowInfo } from "assets/v1.1/icons/Flat";

const UploadNotCompleted = ({
  onValidateFile,
  onUploadFile,
  dropDownAction,
  walletData,
  selectedTeam,
  selectedWallet,
  isBalanceHidden,
  showAryadana,
}) => {
  const [bulkTemplateVersion, setBulkTemplateVersion] = useState();
  const [bulkTemplateVersionError, setBulkTemplateVersionError] =
    useState(false);

  const copyTemplateURL = useMemo(() => {
    if (!bulkTemplateVersion) return COPY_TEMPLATE_URL;
    return `${bulkTemplateVersion.docUrl}/copy`;
  }, [bulkTemplateVersion]);

  const downloadTemplateURL = useMemo(() => {
    if (!bulkTemplateVersion) return DOWNLOAD_TEMPLATE_URL;
    return `${bulkTemplateVersion.docUrl}/export?format=xlsx`;
  }, [bulkTemplateVersion]);

  const getTemplateVersion = async () => {
    try {
      const response = await getBulkTemplateVersionAPI();
      if (!response?.data?.payload) return;
      setBulkTemplateVersion(response.data.payload);
    } catch (e) {
      setBulkTemplateVersionError(true);
    }
  };

  useEffect(() => {
    getTemplateVersion();
  }, []);

  const updateUserTemplateStatus = useCallback(async () => {
    if (!bulkTemplateVersion || !bulkTemplateVersion.isNewTag) return;
    try {
      await updateBulkTemplateVersionAPI(bulkTemplateVersion.id);
    } catch {}
  }, [bulkTemplateVersion]);

  const { Title } = Typography;

  const renderTootipContent = (versionData) =>
    versionData && (
      <>
        <strong>Updated in {`${versionData.currentVersion}:`}</strong>
        <p>{versionData.updateNotes}</p>
      </>
    );

  const templateTitle = () => (
    <div className={styles["template-title"]}>
      Get template file
      {bulkTemplateVersion?.isNewTag && (
        <Tag className={styles["new-badge"]}>New</Tag>
      )}
    </div>
  );

  return (
    <div className={styles.flex}>
      <div>
        <Title className={styles.title}>
          Upload XLSX file for multiple payments
        </Title>
        {bulkTemplateVersionError && (
          <div className={styles["error-inform"]}>
            <Icon src={yellowInfo} className={styles["error-icon"]} />

            <div className={styles["error-content"]}>
              <Typography.Title className={styles["error-title"]}>
                Something went wrong when loading this page
              </Typography.Title>
              <Typography.Text className={styles["error-description"]}>
                Information displayed on this page may not show up properly
              </Typography.Text>
              <Button
                textButton
                className={styles["error-button"]}
                action={() => {
                  window.location.reload();
                }}
              >
                Reload page
              </Button>
            </div>
          </div>
        )}
        <Step number={1} title="Select Budget">
          {walletData?.length > 0 ? (
            <DropDown
              isWallet={true}
              showAmount={!isBalanceHidden}
              showCurrency={true}
              listData={walletData}
              action={dropDownAction}
              initialValue={selectedTeam}
              className={styles.ddl}
            />
          ) : (
            <LoadingIcon />
          )}
          {isBalanceHidden && (
            <div className={styles["wallet-disabled-alert"]}>
              Payment will be drawn from the spending limit of this budget.
            </div>
          )}
        </Step>
        <Step number={2} title={templateTitle()}>
          <>
            <Button
              rounded={true}
              action={() => {
                trackEvent("bill click copy template");
                window.open(copyTemplateURL);
                updateUserTemplateStatus();
              }}
            >
              Make a copy in Google Sheets
            </Button>
            <a
              className={styles.download}
              onClick={() => {
                trackEvent("bill click download template");
                window.open(downloadTemplateURL);
                updateUserTemplateStatus();
              }}
            >
              Download as .xlsx instead
            </a>

            <div className={styles["current-template"]}>
              <p>Current template version:</p>
              <strong>{bulkTemplateVersion?.currentVersion || "-"}</strong>
              <Tooltip
                placement="rightTop"
                title={renderTootipContent(bulkTemplateVersion)}
                overlayClassName="template-tooltip"
                arrowPointAtCenter
              >
                <Icon src={infoOutline} className={styles.icon} />
              </Tooltip>
            </div>
          </>
        </Step>
        <Step number={3} title="Upload file with all payment details">
          <>
            <div className={styles.instruction}>
              If you've edited the template on Google Sheets, download the file
              as an XLSX format and upload the file below
            </div>
            <Uploader
              onValidateFile={onValidateFile}
              onUploadFile={onUploadFile}
              selectedTeam={selectedTeam}
              selectedWallet={selectedWallet}
              disabled={walletData?.length === 0}
              supportedVersion={bulkTemplateVersion?.supportedVersion}
            />
          </>
        </Step>
      </div>
      {showAryadana && (
        <div className={styles.aryadana}>
          <AryadanaFooter type={ARYADANA_FOOTER_TYPE.ONE_LINE} />
        </div>
      )}
    </div>
  );
};

UploadNotCompleted.propTypes = {
  showAryadana: PropTypes.bool,
  isBalanceHidden: PropTypes.bool,
  onValidateFile: PropTypes.func,
  onUploadFile: PropTypes.func,
  dropDownAction: PropTypes.func,
  selectedTeam: PropTypes.string,
  selectedWallet: PropTypes.string,
  walletData: PropTypes.arrayOf(PropTypes.object),
};

UploadNotCompleted.defaultProps = {
  onValidateFile: () => {},
  onUploadFile: () => {},
  dropDownAction: () => {},
  walletData: [],
  selectedTeam: "",
  selectedWallet: "",
  isBalanceHidden: false,
  showAryadana: false,
};

export default UploadNotCompleted;
