import dayjs from "dayjs";
import qs from "query-string";
import { Base64 } from "js-base64";
import { useLocation, useRouteMatch } from "react-router-dom";

import { postData } from "API/Client";

import { GetOrgId, downloadCSV } from "utility";
import { GetFormData } from "utility/APIWrapper";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";

import { appNotification, contactCSErrorMessage } from "Modules/appNotification/appNotification";

import { API_URL } from "Views/Reimbursement/DataCalls/Services";
import { ILocationParams, ILocationQueryParams } from "Views/Reimbursement/@types";
import { FULL_CSV, GENERIC_PAYMENT_CSV } from "Views/Reimbursement/hooks/useExportOptionsList";
import { REIMBURSEMENT_PAGE_OPERATIONS, REIMBURSEMENT_SETTLEMENT_STATUSES } from "Views/Reimbursement/Constants";

const useExportCSV = () => {
  const location = useLocation();

  const { params } = useRouteMatch<ILocationParams>();
  const { operation = "", id = "" } = params;

  const query: ILocationQueryParams = qs.parse(location.search, {
    parseNumbers: true,
    parseBooleans: true,
  });

  const {
    expenseStartDate,
    expenseEndDate,
    exportType = "",
    teamIds = "",
    statuses: paramStatuses = "",
    requesterIds = "",
    merchantIds = "",
    bulkIds = "",
  } = query;

  const isSettlementsView = operation === REIMBURSEMENT_PAGE_OPERATIONS.SETTLEMENTS;

  let type: string = undefined;
  let start_date: string = undefined;
  let end_date: string = undefined;
  let statuses: string[] = undefined;
  let team_ids: string[] = undefined;
  let requester_user_ids: string[] = undefined;
  let merchant: string[] = undefined;
  let request_ids: string[] = undefined;

  if (isSettlementsView && id === REIMBURSEMENT_SETTLEMENT_STATUSES.APPROVED.id) {
    statuses = [REIMBURSEMENT_SETTLEMENT_STATUSES.APPROVED.id];
  } else if (isSettlementsView && id === REIMBURSEMENT_SETTLEMENT_STATUSES.SETTLED_FOR_SETTLEMENT_VIEW.id) {
    statuses = [
      REIMBURSEMENT_SETTLEMENT_STATUSES.SETTLED_WITH_SPENMO.id,
      REIMBURSEMENT_SETTLEMENT_STATUSES.SETTLED_WITH_OTHERS.id,
    ];
  } else if (paramStatuses.length > 0) {
    statuses = Base64.decode(paramStatuses).split(",");
  }

  if (Number(expenseStartDate) && Number(expenseEndDate)) {
    start_date = dayjs.unix(expenseStartDate).format("YYYY-MM-DD");
    end_date = dayjs.unix(expenseEndDate).format("YYYY-MM-DD");
  }

  if (teamIds.length > 0) {
    team_ids = Base64.decode(teamIds).split(",");
  }

  if (requesterIds.length > 0) {
    requester_user_ids = Base64.decode(requesterIds).split(",");
  }

  if (merchantIds.length > 0) {
    merchant = Base64.decode(merchantIds).split(",");
  }

  if (exportType.length > 0) {
    type = Base64.decode(exportType);
  }

  if (bulkIds.length > 0) {
    request_ids = Base64.decode(bulkIds).split(",");
  }

  const data = {
    organisation_id: GetOrgId(),
    status: "all",
    filters: { type: "reimbursement", start_date, end_date, statuses, team_ids, requester_user_ids, merchant },
  };

  const genericPayment = {
    ...data,
    csv: {
      request_ids,
    },
  };

  const exportFullCSV = async () => {
    try {
      if (isNaN(expenseStartDate) || isNaN(expenseEndDate)) {
        throw new Error("");
      }

      const response = await postData(API_URL.LIST.EXPORT_FULL_CSV, GetFormData(data));

      if (response.status !== HTTP_STATUS_CODE.OK) {
        throw new Error(`${response.status}`);
      }

      appNotification.success({
        message: "Your download has successfully started.",
      });

      downloadCSV(response.data, `export_full_csv_${dayjs().format("YYYYMMDD")}`, true);
    } catch (error) {
      appNotification.error(contactCSErrorMessage(error.message));
    }
  };

  const genericPaymentCSV = async () => {
    try {
      if (isNaN(expenseStartDate) || isNaN(expenseEndDate)) {
        throw new Error("");
      }

      const response = await postData(API_URL.LIST.GENERIC_PAYMENT_CSV, GetFormData(genericPayment));

      if (response.status !== HTTP_STATUS_CODE.OK) {
        throw new Error(`${response.status}`);
      }

      appNotification.success({
        message: "Your download has successfully started.",
      });

      downloadCSV(response.data, `generic_payment_csv_${dayjs().format("YYYYMMDD")}`, true);
    } catch (error) {
      appNotification.error(contactCSErrorMessage(error.message));
    }
  };

  const exportCSV = async () => {
    if (type === FULL_CSV.id) {
      await exportFullCSV();
    } else if (type === GENERIC_PAYMENT_CSV.id) {
      await genericPaymentCSV();
    }
  };

  return {
    exportCSV,
  };
};

export default useExportCSV;
