import { GetOrgId } from "utility";
import { urlParamsBuilder } from "utility/APIWrapper";
import APIclient from "API/Client";
import { ICategoryLockApiResponse } from "customHooks/Card";

export interface IGetMerchantCategoriesProps {
  organisation_id: string;
  q?: string;
}

export const getMerchantCategories = (
  params: IGetMerchantCategoriesProps
): Promise<{ data: ICategoryLockApiResponse }> => {
  const url = urlParamsBuilder(`/api/v1/cards/policies/merchant-categories`, params);
  return APIclient.getData(url);
};

export const getCategoriesDetails = () => {
  const url = urlParamsBuilder(`/api/v1/cards/policies/merchant-categories/merchants`, { organisation_id: GetOrgId() });
  return APIclient.getData(url);
};
