import React from "react";
import Button from "Modules/button";
import { COMPANY_VERIFICATION } from "Route/KYX/paths";

import styles from "./index.module.scss";

export const ResumeManual = ({ action }: { action: () => void }) => {
  return (
    <Button
      className={styles.myInfoBtn}
      type="button"
      text="Restart Verification with Manual Upload"
      textButton
      action={action}
    />
  );
};

export const ResumeWithMyInfo = ({ action }: { action: () => void }) => {
  return (
    <Button
      className={styles.myInfoBtn}
      type="button"
      text="Restart Verification with MyInfo Business"
      textButton
      action={action}
    />
  );
};

export const ResumeVerification = () => {
  return (
    <a className={styles.button} href={COMPANY_VERIFICATION.COMPANY_INFORMATION}>
      Resume Verification Process
    </a>
  );
};
