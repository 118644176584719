import React from "react";
import { Tooltip } from "antd";
import Chip, { CHIP_STATUS } from "Modules/DS/Chip";
import { EWorkflowStatus, IWorkflowStatus } from "../type";
import { grayInfoCircle } from "assets/v1.1/icons/Line";

import styles from "./styles.module.scss";

const WorkflowStatus = ({ status, transaction }: IWorkflowStatus) => {
  const generateChipColor = () => {
    const types = {
      [EWorkflowStatus.INCOMPLETE_DETAILS]: CHIP_STATUS.ATTENTION,
      [EWorkflowStatus.READY_TO_REVIEW]: CHIP_STATUS.SUCCESS,
      [EWorkflowStatus.IN_QUEUE]: CHIP_STATUS.INFO,
      [EWorkflowStatus.SYNC_FAILED]: CHIP_STATUS.FAILED,
      [EWorkflowStatus.COMPLETED]: CHIP_STATUS.SUCCESS,
    };

    return types[status];
  };
  return (
    <div className={styles["workflow-status"]}>
      <Chip.Label title={status} status={generateChipColor()} />
      {status === EWorkflowStatus.SYNC_FAILED && (
        <Tooltip
          title={transaction.accountingMessage}
          placement="top"
          overlayClassName="failed-sync-tooltip"
          transitionName="failed-sync-tooltip-transition"
        >
          <img src={grayInfoCircle} onClick={(e) => e.stopPropagation()} />
        </Tooltip>
      )}
    </div>
  );
};

export default WorkflowStatus;
