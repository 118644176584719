import React from "react";
import PropTypes from "prop-types";
import { DatePicker } from "antd";
import moment from "moment";
import "./datePicker.scss";

const IDatePicker = ({
  action,
  initialValue = "",
  showPastDates = false,
  picker = "month",
  placeholder,
  format = "DD MMM YYYY",
  pastDateRange = () => null,
  allowClear = true,
  disabled = false,
  ...rest
}) => {
  return (
    <div className="date-picker">
      <DatePicker
        allowClear={allowClear}
        disabledDate={!showPastDates ? (current) => current && current < moment().endOf("day") : pastDateRange}
        defaultValue={initialValue !== "" && moment(initialValue, "YYYY-MM-DD")}
        onChange={(date, dataString) => action(picker === "date" ? date && date.format("YYYY-MM-DD") : dataString)}
        placeholder={placeholder}
        picker={picker}
        format={format}
        className="date-picker__date"
        disabled={disabled}
        {...rest}
      />
    </div>
  );
};

IDatePicker.propTypes = {
  action: PropTypes.func,
  initialValue: PropTypes.string,
  picker: PropTypes.string,
  placeholder: PropTypes.string,
  format: PropTypes.string,
  showPastDates: PropTypes.bool,
};

export default IDatePicker;
