import { Menu } from "../@types";

export const companyMenu: Menu[] = [
  {
    title: "Integration",
    path: "/settings/integration",
    enabled: "ableToOpenIntegrationPage",
  },
  {
    title: "Category",
    path: "/settings/category",
    enabled: "ableToOpenCategoryPage",
  },
  {
    title: "Tax",
    path: "/settings/tax",
    enabled: "ableToOpenTaxPage",
  },
  {
    title: "Payment Run",
    path: "/settings/payment-run",
    enabled: "ableToOpenPaymentRunPage",
  },
  {
    title: "Low Balance Reminder",
    path: "/settings/lowbalancereminder",
    enabled: "ableToOpenLowBalancePage",
  },
  {
    title: "Reimbursements",
    path: "/settings/reimbursement",
    enabled: "ableToOpenReimbursementsPage",
  },
  {
    title: "Billing and Usage",
    path: "/settings/billing",
    enabled: "ableToOpenBillingPage",
  },
];

export const personalMenu: Menu[] = [
  {
    title: "Security",
    path: "/settings/security",
  },
  {
    title: "Payout Details",
    path: "/settings/payoutdetail",
    enabled: "ableToOpenPayoutPage",
  },
];

export const PERSONAL = "Personal";

export const COMPANY = "Company";
