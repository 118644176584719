import APIclient from "API/Client";
import { GetBaseAuthObject } from "utility";
import { trackEvent } from "utility/analytics";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import { categoryEvents } from "Views/Categories/Analytics";

export const FETCH_EDIT_REQUEST = "FETCH_EDIT_REQUEST";
export const FETCH_EDIT_SUCCESS = "FETCH_EDIT_SUCCESS";
export const FETCH_EDIT_FAILURE = "FETCH_EDIT_FAILURE";

export const fetchEditDataReq = () => {
  return {
    type: FETCH_EDIT_REQUEST,
    data: {},
  };
};

export const fetchEditDataSuccess = (data) => {
  return {
    type: FETCH_EDIT_SUCCESS,
    data: data,
  };
};

export const fetchEditDataError = (data) => {
  return {
    type: FETCH_EDIT_FAILURE,
    data: data,
  };
};

export const CategoryEditFunc = (data, categoryId, trackActions = false) => {
  const orgId = GetBaseAuthObject().orgId;
  return (dispatch) => {
    dispatch(fetchEditDataReq());
    return (
      APIclient
        /*eslint no-process-env: "off"*/
        .postData(`/api/categories/edit/${categoryId}`, { organisation_id: orgId, ...data })
        .then((data: { [propName: string]: any }) => {
          if (data.data.status > HTTP_STATUS_CODE.OK) {
            dispatch(fetchEditDataError(data.data));
            trackActions && trackEvent(categoryEvents.EDIT_CATEGORY_FAILURE);
            return Promise.reject();
          } else {
            dispatch(fetchEditDataSuccess(data.data));
            trackActions && trackEvent(categoryEvents.EDIT_CATEGORY_SUCCESS);
          }
        })
        .catch((err) => {
          dispatch(fetchEditDataError(err));
          trackActions && trackEvent(categoryEvents.EDIT_CATEGORY_FAILURE);
          return Promise.reject();
        })
    );
  };
};
