import React, { useContext, useMemo } from "react";

import { AmountFilter } from "Modules/DS/Filter";
import { IDateFormProps } from "Modules/DS/Filter/@types";

import { ITrxnFilterContext, ITrxnPaginationContext } from "Views/Transaction/@types";
import { TrxnFilterContext, TrxnPaginationContext } from "Views/Transaction/Context";
import { ALL_TRXN_PAGINATION_PARAMS } from "Views/Transaction/Constants";
import { useMutableData } from "API/useData";
import { API_URL } from "Views/Bills/V2/constants";

export const PaidAmountFilter = ({ id }: { id: string }) => {
  const { resetCurrentPage } = useContext<ITrxnPaginationContext>(TrxnPaginationContext);

  const { resetTrxnPaginationParams, filter, onApplyFilter, resetFilter } =
    useContext<ITrxnFilterContext>(TrxnFilterContext);

  const { data: billpayConfig } = useMutableData(API_URL.billpayConfig);
  const { isPaymentEnabled } = useMemo(() => billpayConfig?.data?.payload || {}, [billpayConfig]);

  const onApply = (value: IDateFormProps) => {
    onApplyFilter({
      ...value,
      ...resetTrxnPaginationParams,
    });
    resetCurrentPage();
  };

  const onClear = (keys: string[]) => {
    resetFilter([...keys, ...ALL_TRXN_PAGINATION_PARAMS]);
    resetCurrentPage();
  };

  return (
    <AmountFilter
      defaultValue={`${isPaymentEnabled ? "Paid" : "Invoice"} Amount`}
      id={id}
      filter={filter}
      onApply={onApply}
      onClear={onClear}
    />
  );
};
