import React, { useContext } from "react";
import { Drawer } from "antd";

import Icon from "Modules/Icon";
import { KYXRemarkContext } from "Views/KYX/Context/Remarks";
import FormLevelRemarks from "Views/KYX/Remarks/Content/FormLevel";
import FieldLevelRemarks from "Views/KYX/Remarks/Content/FieldLevel";
import { REMARKS_SIDEBAR_TYPE, RemarksInfo } from "Views/KYX/Constants/Remarks";
import { IKYXRemarkContextType, IRemarksInfo } from "Views/KYX/@types";

import styles from "./index.module.scss";

const Remarks: React.FC = (): JSX.Element => {
  const { remarks, close }: IKYXRemarkContextType = useContext(KYXRemarkContext);
  const { visible, payload, type } = remarks;

  const handleRemarksSection = (data) => {
    if (data && type === REMARKS_SIDEBAR_TYPE.FORM) {
      return Object.keys(data)?.map((key) => {
        const details: IRemarksInfo = RemarksInfo[key];
        return data?.[key] ? <FormLevelRemarks details={data?.[key]} info={details} key={key} /> : null;
      });
    }
    return <FieldLevelRemarks details={data} />;
  };

  return (
    <Drawer
      placement="right"
      onClose={close}
      mask={false}
      width={480}
      visible={visible}
      closeIcon={<Icon icon="close" size={24} />}
      className={styles.container}
    >
      <h4 className={styles.title}>Remarks</h4>
      {handleRemarksSection(payload)}
    </Drawer>
  );
};

export default Remarks;
