export const getLabel = <T extends object = any>(selected: T[], displayKey: string, defaultValue: string) => {
  let label: string = "";

  if (selected.length === 1) {
    label = selected[0][displayKey];
  } else if (selected.length > 1) {
    label = defaultValue + ` (${selected.length})`;
  }

  return label;
};
