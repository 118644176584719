import React from "react";

import ErrorState from "Views/Reimbursement/Error";

import styles from "./index.module.scss";

const SidepanelErrorState = () => {
  return (
    <div className={styles.container}>
      <ErrorState />
    </div>
  );
};

export default SidepanelErrorState;
