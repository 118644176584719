import { Button } from "antd";
import "./index.scss";
import React, { useState } from "react";
import { greyCloseIcon } from "assets/img/index";
import { trackEvent } from "utility/analytics";
import Icon from "Modules/icons";
import LoadingIcon from "Modules/loading";
import ApprovalRequest from "Models/Approval/ApprovalCenter/approvalRequest.interface";
import { useAppSelector } from "../../../../../hook";
import { TAB_KEY } from "../../types";

export enum APPROVAL_ACTIONS {
  approve = "approve",
  decline = "decline",
}

interface ApprovalActionsProps {
  record: ApprovalRequest;
  processType: string;
  onApprove: (object) => Promise<object>;
  onDecline: (boolean) => void;
  showDetails: (object, boolean) => void;
  trackEventName?: string;
}

const ApprovalActions = ({
  record,
  processType,
  onApprove,
  onDecline,
  showDetails,
  trackEventName,
}: ApprovalActionsProps) => {
  const [confirmAction, setConfirmAction] = useState(null);
  const [isSubmit, setIsSubmit] = useState(false);

  const user = useAppSelector((state) => state.user);

  const handleApprove = async () => {
    setIsSubmit(true);
    const response = await onApprove({
      ids: [record.processID],
      type: processType,
      approved: true,
    });
    if (response) {
      setIsSubmit(false);
      setConfirmAction(null);
    }
  };

  const handleTrackingData = (action, source) => {
    let trackEventData = {};
    switch (processType) {
      case TAB_KEY.INVOICE:
        trackEventData = {
          approval_bills_event_action: action,
          approval_bills_event_source: source,
          bill_id: [record?.processID],
          user_role: user?.role,
        };
        break;
      case TAB_KEY.CARD:
        trackEventData = {
          approval_cards_event_action: action,
          approval_cards_event_source: source,
          card_id: [record?.processID],
          user_role: user?.role,
        };
        break;
      case TAB_KEY.REIMBURSEMENT:
        trackEventData = {
          approval_cr_event_action: action,
          approval_cr_event_source: "Single in Pending Approval",
          bill_id: [record?.processID],
          user_role: user?.role,
        };
        break;
      default:
        break;
    }
    if (trackEventName) {
      trackEvent(trackEventName, trackEventData);
    }
  };

  if (confirmAction) {
    return (
      <div className={`approval-actions`}>
        <Button
          className={`approval-actions__button--${confirmAction} approval-actions__button--confirm`}
          onClick={(e) => {
            e.stopPropagation();
            handleApprove();
            handleTrackingData("Confirm Approve", "Single");
          }}
          disabled={isSubmit}
        >
          {isSubmit && confirmAction ? <LoadingIcon size="s" /> : `Confirm to ${confirmAction}`}
        </Button>
        <Icon
          alt="close-confirm-button-icon"
          className={"close-button"}
          src={greyCloseIcon}
          size="2"
          action={(e) => {
            e.stopPropagation();
            setConfirmAction(null);
          }}
        />
      </div>
    );
  }

  return (
    <>
      <div className={`approval-actions`}>
        <Button
          className={"approval-actions__button--approve"}
          onClick={(e) => {
            e.stopPropagation();
            showDetails(record, false);
            setConfirmAction(APPROVAL_ACTIONS.approve);
            handleTrackingData("Approve", "Single");
          }}
        >
          Approve
        </Button>
        <Button
          className={"approval-actions__button--decline"}
          onClick={(e) => {
            e.stopPropagation();
            showDetails(record, false);
            onDecline(true);
            handleTrackingData("Decline", "Single");
          }}
        >
          Decline
        </Button>
      </div>
    </>
  );
};

export default ApprovalActions;
