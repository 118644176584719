import { IRouterType } from "Route/@types";
import { AuthRoute } from "Route/Auth";
import { AccountingRoute } from "Route/Accounting";
import { ApprovalRoute } from "Route/Approval";
import { EmployeeRoute } from "Route/Employee";
import { BillRoute } from "Route/Bills";
import { TeamRoute } from "Route/Team";
import { CardRoute } from "Route/Card";
import { TransactionRoute } from "Route/Transaction";
import { CommonRoute } from "Route/Common";
import { OnboardingRoute } from "Route/KYX";
import { SubscriptionRoute } from "Route/Subscription";

// The OnboardingRoute is currently commented out because the feature is not yet live.

export const RouteMap: IRouterType[] = [
  ...AuthRoute,
  ...AccountingRoute,
  ...ApprovalRoute,
  ...EmployeeRoute,
  ...BillRoute,
  ...TeamRoute,
  ...CardRoute,
  ...TransactionRoute,
  ...OnboardingRoute,
  ...SubscriptionRoute,
  ...CommonRoute,
];
