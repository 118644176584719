import * as React from "react";
import { Row, Col, Input } from "antd";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { getCookieValue, cookieNames } from "utility/CookieHelper";
import ActionBtns from "Views/Card/CreateOrRequest/ActionBtns";
import { useCreateOrRequestCardContext } from "Views/Card/CreateOrRequest/index.context";
import { CARD_LIMIT_TYPES, CARD_LIMITS_LABELS } from "Views/Card/CreateOrRequest/Steps/Policy/index.constants";
import { currencyFormatterWithFractions } from "utility";
import "Views/Card/CreateOrRequest/Steps/Review/index.scss";
import { Actions } from "Views/Card/CreateOrRequest/Utilities/UseCreateOrRequestState";

const { TextArea } = Input;

export const Review = () => {
  const { state, changeStep, dispatch, cardRequestInProgress } = useCreateOrRequestCardContext();
  const userName = useSelector((reduxState: any) => reduxState?.userInfoReducer?.data?.payload?.user?.name || "");
  const orgCurrencyCode = getCookieValue(cookieNames.CURRENCY_CODE);

  const { cardLimitType, cardLimit, spendingLocks, selectedTeamName, expenseCategoryName, letMyManagerChooseLimit } =
    state;

  const getCardLimit = () => {
    if (cardLimitType !== CARD_LIMIT_TYPES.unlimited) {
      return (
        <>
          {currencyFormatterWithFractions(cardLimit, orgCurrencyCode, 2, false)}{" "}
          <span className="request-review__column-data--small">{CARD_LIMITS_LABELS[cardLimitType] || ""}</span>
        </>
      );
    } else {
      return (cardLimitType && CARD_LIMITS_LABELS[cardLimitType]) || "";
    }
  };

  const getMerchantLocks = () => {
    if (Array.isArray(spendingLocks) && spendingLocks.length > 0) {
      return spendingLocks?.map((lock) => lock?.name)?.join(", ");
    } else {
      return "None";
    }
  };

  const getShippingAddress = () => {
    const { addressLine1 = "", addressLine2 = "", country = "", pincode = "" } = state;
    if (addressLine1 && country && pincode) {
      return (
        <>
          <p className="request-review__column-header">Shipping Address</p>
          <p className="request-review__column-data">
            {[addressLine1, addressLine2 || "", country, pincode]?.filter((address) => address)?.join?.(", ") || ""}
          </p>
        </>
      );
    }
    return null;
  };

  const spendingLimitClassname = classNames("request-review__column-data request-review__spending-limit", {
    "request-review__spending-limit--grey": letMyManagerChooseLimit,
  });

  return (
    <>
      <p className="create-or-request-card-steps__header">Review Your Request</p>
      <div className="request-review">
        <section className="create-or-request-card-steps__form-container">
          <Row className="sp-margin-top--xl" gutter={[20, 0]}>
            <Col span={12}>
              <p className="request-review__column-header">Spending Limit</p>
              <p className={spendingLimitClassname}>{letMyManagerChooseLimit ? "Not added" : getCardLimit()}</p>
            </Col>
            <Col span={12}>
              <p className="request-review__column-header">Requested by</p>
              <p className="request-review__column-data">{userName}</p>
            </Col>
          </Row>
          <Row className="sp-margin-top--md" gutter={[20, 0]}>
            <Col span={12}>
              <p className="request-review__column-header">Spending Lock</p>
              <p className="request-review__column-data request-review__merchant-lock">{getMerchantLocks()}</p>
            </Col>
            <Col span={12}>
              <p className="request-review__column-header">Budget</p>
              <p className="request-review__column-data">{selectedTeamName}</p>
            </Col>
          </Row>
          <Row className="sp-margin-top--md" gutter={[20, 0]}>
            <Col span={12}>
              <p className="request-review__column-header">Default Expense Category</p>
              <p className="request-review__column-data">{expenseCategoryName || "N/A"}</p>
            </Col>
            <Col span={12}>{getShippingAddress()}</Col>
          </Row>
          {!state?.adminOrManagerInSelectedTeam && (
            <>
              <p className="request-review__text-area__title sp-margin-top--md">Add a note to your Budget Owner</p>
              <TextArea
                className="request-review__text-area"
                value={state.noteToManager}
                rows={3}
                autoSize={false}
                placeholder="Write a note..."
                maxLength={255}
                onChange={(e) => dispatch({ type: Actions.UPDATE_NOTE_TO_MANAGER, value: e?.target?.value || "" })}
              />
            </>
          )}
        </section>
        <div>
          <ActionBtns
            actionBtnName="Confirm"
            loading={cardRequestInProgress}
            changeStep={changeStep}
            renderGoBackBtn={true}
            disableNextBtn={false}
          />
        </div>
      </div>
    </>
  );
};
