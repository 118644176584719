import { BankTransferItemMethod, BankTransferItemType, CopyTextProps } from "Views/Bills/V2/PaymentRunPreview/type";
import cn from "classnames";

import { TypographyProps } from "@spenmo/splice/lib/components/Typography";

import styles from "./PaymentMethod.module.scss";

/**
 * Add bank info item here.
 * No need to add simple Text type if the props
 * is already align with the default props
 * in the BANK_INFO_PROPS_MAPPER
 */
const BANK_INFO_MAPPER = {
  bankLogoUrl: {
    type: BankTransferItemType.IMG,
    props: {
      alt: "bank logo",
      height: 22,
      width: 60,
    },
  },
  accountNumber: {
    type: BankTransferItemType.COPY_TEXT,
  },
  accountName: {
    type: BankTransferItemType.TEXT,
    props: {
      size: "m",
      className: styles.titleColor,
    },
  },
  swiftCode: {
    type: BankTransferItemType.COPY_TEXT,
  },
  bankAddress: {
    type: BankTransferItemType.COPY_TEXT,
  },
  descriptionLines: {
    type: BankTransferItemType.HTML,
  },
  additionalInfos: {
    type: BankTransferItemType.ADDITIONAL_INFO,
  },
  isUSDVirtualAccount: {
    type: BankTransferItemType.USDVirtualAccount,
  },
  instantReflectNotes: {
    type: BankTransferItemType.HTML,
    props: {
      className: styles.instantReflectNotes,
    },
  },
};

/**
 * Add default props for every BankTransferItemType here.
 * Modify the props in the BANK_INFO_MAPPER
 */
const BANK_INFO_PROPS_MAPPER = {
  [BankTransferItemType.IMG]: (src: string, props?: HTMLImageElement) => ({
    type: BankTransferItemType.IMG,
    props: {
      src,
      ...props,
    },
  }),
  [BankTransferItemType.TEXT]: (text: string, props?: TypographyProps) => {
    const { className, ...restProps } = props || {};

    return {
      type: BankTransferItemType.TEXT,
      props: {
        className: cn(styles.bodyColor, className),
        tag: "p",
        variant: "body-content",
        size: "s",
        children: text,
        ...restProps,
      },
    };
  },
  [BankTransferItemType.COPY_TEXT]: (text: string, props?: TypographyProps & CopyTextProps) => ({
    type: BankTransferItemType.COPY_TEXT,
    props: {
      tag: "p",
      variant: "body-content",
      size: "s",
      className: styles.titleColor,
      copyText: text,
      children: text,
      ...props,
    },
  }),
  [BankTransferItemType.HTML]: (htmlText: string[], props?: TypographyProps) => {
    const { className, ...restProps } = props || {};

    // to handle if the htmlText is not using an array
    try {
      const textProps = htmlText.map((text: string) => ({
        className: cn(styles.bodyColor, className),
        tag: "p",
        variant: "body-content",
        size: "s",
        dangerouslySetInnerHTML: { __html: text },
        ...restProps,
      }));

      return {
        type: BankTransferItemType.HTML,
        props: textProps,
      };
    } catch {
      return {
        type: BankTransferItemType.HTML,
        props: [
          {
            className: cn(styles.bodyColor, className),
            tag: "p",
            variant: "body-content",
            size: "s",
            dangerouslySetInnerHTML: { __html: htmlText },
            ...restProps,
          },
        ],
      };
    }
  },
  [BankTransferItemType.ADDITIONAL_INFO]: (
    info: { label: string; value: string }[],
    labelProps?: TypographyProps,
    contentProps?: TypographyProps & CopyTextProps
  ) => {
    const infoProps = info.map((infoItem) => {
      const { label, value } = infoItem;

      return {
        labelProps: {
          tag: "p",
          variant: "body-content",
          size: "m",
          className: styles.bodyColor,
          children: label,
          ...labelProps,
        },
        contentProps: {
          tag: "p",
          variant: "body-content",
          size: "s",
          className: styles.titleColor,
          copyText: value,
          children: value,
          ...contentProps,
        },
      };
    });

    return {
      type: BankTransferItemType.ADDITIONAL_INFO,
      props: infoProps,
    };
  },
  [BankTransferItemType.USDVirtualAccount]: () => ({
    type: BankTransferItemType.USDVirtualAccount,
  }),
};

/**
 * Layout and placement order for
 * Bank Info
 */
const BANK_INFO_KEY_ORDER = [
  "bankLogoUrl",
  "additionalBankname",
  "bankCode",
  "accountNumber",
  "accountName",
  "swiftCode",
  "bankAddress",
  "descriptionLines",
  "additionalInfos",
  "notes",
  "instantReflectNotes",
  "isUSDVirtualAccount",
];

/**
 * Transforming Bank Info Object into array of object
 * which will be mapped in the component
 * @param bankInfoObj bankInfo object from the `methods` field
 * @returns array of object with type and the props of the component
 */
export const transformBankInfo = (bankInfoObj: BankTransferItemMethod = {}) => {
  const result = [];

  BANK_INFO_KEY_ORDER.forEach((key) => {
    const value = bankInfoObj[key];

    // if not empty
    if (
      (value !== undefined && value !== null && value !== "" && value !== false && !Array.isArray(value)) ||
      (Array.isArray(value) && value.length > 0)
    ) {
      // set the default type to Text to simplify things
      const { type = BankTransferItemType.TEXT, props } = BANK_INFO_MAPPER[key] || {};

      result.push(BANK_INFO_PROPS_MAPPER[type](value, props));
    }
  });

  return result;
};
