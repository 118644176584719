import * as React from "react";
import classNames from "classnames";

import { IModalSubTitleProps } from "Modules/DS/Modal/types";

import styles from "./SubTitle.module.scss";

export const SubTitle = ({ children }: IModalSubTitleProps) => {
  return (
    <p data-testid="modal-subtitle" className={classNames(styles.container)}>
      {children}
    </p>
  );
};
