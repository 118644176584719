import React from "react";

import { ISubscriptionManagementFilterKeys } from "Views/SubscriptionManagement/@types/filters";
import { DropdownFilter } from "Views/SubscriptionManagement/Filters/DropdownFilter";
import { useVendors } from "Views/SubscriptionManagement/Create/Hooks/useVendors";

export const VendorFilter = () => {
  const { data: vendorList, isLoading } = useVendors();

  const vendors = (vendorList || []).map((vendor) => ({
    id: vendor.id,
    title: vendor.name,
  }));

  return (
    <DropdownFilter
      loading={isLoading}
      id={ISubscriptionManagementFilterKeys.VENDOR}
      defaultValue="Vendor"
      options={vendors}
      searchable
    />
  );
};
