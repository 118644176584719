import React from "react";
import classNames from "classnames";
import s from "./Card.module.scss";

interface Props {
  title: string | React.ReactNode;
  children: React.ReactNode;
  className?: string;
  rightSideTitle?: React.ReactNode;
}

export function Card({ title, className, children, rightSideTitle }: Props) {
  return (
    <div className={classNames(s.container, className)} data-testid={title}>
      <div className={s.title}>
        {title}
        {Boolean(rightSideTitle) && rightSideTitle}
      </div>
      <div className={classNames(s.content, s.equalPartition)}>{children}</div>
    </div>
  );
}
