import { PaginationProps } from "antd/lib/pagination";
import { TableRowSelection } from "antd/lib/table/interface";
import { ColumnType, TablePaginationConfig } from "antd/lib/table";

export enum DATA_TABLE_SIZE_TYPES {
  SMALL = "data-table-size__small",
  LARGE = "data-table-size__large",
}

export enum DATA_TABLE_PADDING {
  DEFAULT = "data-table-padding__default",
  NONE = "data-table-padding__none",
  GRID = "data-table-padding__grid",
}

export enum ALIGN_ACTION_ITEM {
  LEFT = "LEFT",
  RIGHT = "RIGHT",
}

export interface IScrollProps {
  x?: number | true | string;
  y?: number | string;
  scrollToFirstRowOnChange?: boolean;
}

export interface IColumnData<RecordType> {
  id: string;
  enabled: boolean;
  column: ColumnType<RecordType>;
}

export interface ITableProps<RecordType> {
  className?: string;
  total?: number;
  loading?: boolean;
  dataSource: RecordType[];
  pageNum?: number;
  pageSize?: number;
  onPageChange?: PaginationProps["onChange"];
  paginationProps?: TablePaginationConfig;
  emptyState?: React.ReactNode;
  customEmptyState?: React.ReactNode;
  children?: IColumnData<RecordType>[];
  paginationEnabled?: boolean;
  paginationOnTop?: boolean;
  paginationOnBottom?: boolean | true;
  scrollVertical?: string | number;
  scrollHorizontal?: string | number;
  rowAction?: (record: RecordType, e?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  rowClassName?: (record: RecordType) => string;
  rowSelection?: TableRowSelection<RecordType>;
  bulkAction?: IBulkActionProps;
}

export interface IHeaderProps {
  title: string;
  subtitle?: string;
}

export interface IActionItem {
  id: string;
  enabled: boolean;
  align: ALIGN_ACTION_ITEM;
  component: JSX.Element | (() => JSX.Element);
}

export interface IDataTableProps {
  children: React.ReactNode;
  className?: string;
  size?: DATA_TABLE_SIZE_TYPES;
  padding?: DATA_TABLE_PADDING;
}

export interface ITitleWithIconButton {
  children: string;
  action: () => void;
  img?: string;
  altText?: string;
}

export interface BulkAction {
  label: string;
  handler: (object?) => Promise<any> | void;
}

export enum ActionButtonType {
  Primary = "primary",
  Secondary = "secondary",
}
export interface PrimaryOrSecondary extends BulkAction {
  type: ActionButtonType;
  customButton?: React.ReactNode;
  disabled?: boolean;
}

export interface ConstructiveOrDesctructive extends BulkAction {}

export interface ActionLink extends BulkAction {}

export interface CommonProps {
  dataName: string;
  data: object[];
  supportingInfo: JSX.Element | string;
  clearSelection: () => void;
}
interface DestructiveActionProps extends CommonProps {
  type: BulkActionType.Destructive;
  constructive: ConstructiveOrDesctructive;
  destructive: ConstructiveOrDesctructive;
  button?: PrimaryOrSecondary;
}

interface DestructiveActionWithLinkProps extends CommonProps {
  type: BulkActionType.Destructive_With_Link;
  link: ActionLink;
}
interface DefaultActionProps extends CommonProps {
  type: BulkActionType.Default;
  firstButton: PrimaryOrSecondary;
  secondButton: PrimaryOrSecondary;
}

interface MoreActionMenuProps extends CommonProps {
  type: BulkActionType.Action_With_Menu;
  button: PrimaryOrSecondary;
  moreActionMenu: JSX.Element;
}

export interface TrackEventProps {
  name: string;
  data: EventDataProps;
}
interface EventDataProps {
  bill_id: Array<string>;
  user_role: string;
}

export type BulkActionPropTypes =
  | DestructiveActionProps
  | DestructiveActionWithLinkProps
  | DefaultActionProps
  | MoreActionMenuProps;

export enum BulkActionType {
  Destructive = "destructive",
  Default = "default",
  Destructive_With_Link = "destructive_with_link",
  Action_With_Menu = "Action_With_Menu",
}
export interface IBulkActionProps {
  type: BulkActionType;
  dataName: string;
  data: object[];
  supportingInfo: JSX.Element | string;
  clearSelection: () => void;
  constructive?: ConstructiveOrDesctructive;
  destructive?: ConstructiveOrDesctructive;
  button?: PrimaryOrSecondary;
  firstButton?: PrimaryOrSecondary;
  secondButton?: PrimaryOrSecondary;
  link?: ActionLink;
  moreActionMenu?: JSX.Element;
  showMoreActionMenu?: boolean;
  setShowMoreActionMenu?: React.Dispatch<React.SetStateAction<boolean>>;
  loadingMoreactionMenu?: boolean;
  maximum?: number;
  size?: string;
  trackEventData?: (action: string, source: string) => TrackEventProps;
  alwaysShown?: boolean;
  emptySelectionMessage?: string;
}
