export const missingFilter = (connected: boolean, connectedUsingExpenseIntegration: boolean, hasTags: boolean) => {
  let availiableMissingFilter = [
    {
      id: "attachment",
      title: "Attachment/Receipt",
    },
  ];

  if (connectedUsingExpenseIntegration) {
    availiableMissingFilter = availiableMissingFilter.concat([
      {
        id: "accounting_code",
        title: "Category without account code",
      },
    ]);
  }

  if (connected && hasTags) {
    availiableMissingFilter = availiableMissingFilter.concat([
      {
        id: "tags",
        title: "Transaction Tags",
      },
    ]);
  }

  availiableMissingFilter = availiableMissingFilter.concat([
    {
      id: "notes",
      title: "Notes",
    },
  ]);

  return availiableMissingFilter;
};
