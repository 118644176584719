import * as React from "react";
import { Select } from "antd";
import { darkGreyCloseIcon } from "assets/img";
import DropdownSelection, { ISelectionData } from "Modules/multiSelection/DropdownSelection";
import { useIsMount } from "utility/useIsMount";
import { searchText } from "utility";
import { chevronDown } from "assets/img";
import usePrevious from "utility/usePrevious";

interface ICardholderSelection extends React.ComponentPropsWithoutRef<"div"> {
  listData: Array<ISelectionData>;
  defaultValue?: Array<string>;
  placeholder: string;
  action: (payload: Array<string>) => void;
  closeCardholderFilter?: () => void;
  selectOptions: any;
  resetToDefault?: boolean;
  letterAvatar?: boolean;
  prefix?: string;
}

const { useState, useEffect } = React;

const CardholderSelection: React.FC<ICardholderSelection> = ({
  listData = [],
  defaultValue = [],
  placeholder,
  action,
  selectOptions = {},
  closeCardholderFilter = () => {},
  className,
  resetToDefault,
  letterAvatar = true,
  prefix,
}) => {
  const [selectedList, setSelectedList] = useState(defaultValue);
  const [tempSelectedList, setTempSelectedList] = useState(defaultValue);
  const [filteredList, setFilteredList] = useState(listData);
  const isMount = useIsMount();
  const prevResetToDefault = usePrevious<boolean>(resetToDefault);

  useEffect(() => {
    !isMount && action(selectedList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedList]);

  useEffect(() => {
    if (prevResetToDefault != null && resetToDefault !== prevResetToDefault) {
      setTempSelectedList(defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetToDefault]);

  const deselectValue = (val: string, event: React.SyntheticEvent) => {
    event.stopPropagation();
    setTempSelectedList((prevTempSelectedList) => prevTempSelectedList.filter((item) => item !== val));
    const newSelectedList = selectedList.filter((item) => item !== val);
    if (newSelectedList.length !== selectedList.length) setSelectedList(newSelectedList);
  };

  const onPreventMouseDown = (event: React.SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleSelectValueWithClear = (list: Array<string> = []) => {
    if (!list?.filter || !list?.filter((item) => item).length) return null;
    return (
      <div className="multi-selection__value-container" onMouseDown={onPreventMouseDown}>
        {list.map((item) => (
          <span className="multi-selection__value" key={item}>
            {item}
            <img
              onClick={(e) => deselectValue(item, e)}
              className="multi-selection__value-close-icon"
              src={darkGreyCloseIcon}
              alt="close"
            />
          </span>
        ))}
      </div>
    );
  };

  const clearAll = () => {
    setTempSelectedList([]);
  };

  const handleApply = () => {
    setSelectedList(tempSelectedList);
    setTimeout(() => {
      closeCardholderFilter && closeCardholderFilter();
    }, 0);
  };

  const handleOnSearch = (val: string) => {
    setFilteredList(searchText(val, listData));
  };

  const selectProps = {
    ...selectOptions,
    value: handleSelectValueWithClear(tempSelectedList),
    defaultValue,
    className: `multi-selection ${className || ""}`,
    placeholder: placeholder || "Please select",
    dropdownRender: () => (
      <>
        <DropdownSelection
          letterAvatar={letterAvatar}
          enableSearch={false}
          selectedList={tempSelectedList}
          listData={filteredList}
          setSelectedList={setTempSelectedList}
          prefix={prefix}
          priorityCheckedList={false}
        />
        <div className="card-selection-filter__dropdown-footer">
          <div className="clear-btn" onClick={clearAll}>
            Clear
          </div>
          <button onClick={handleApply}>Apply</button>
        </div>
      </>
    ),
    onSearch: handleOnSearch,
    suffixIcon: <img src={chevronDown} alt="drop down icon" />,
    showSearch: true,
  };

  return <Select {...selectProps} />;
};

export default CardholderSelection;
