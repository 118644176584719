import * as featureFlagsControllers from "Redux/splitio/controllers";

export const initSplitio = () => async (dispatch) => {
  try {
    await dispatch(featureFlagsControllers.init());
    dispatch(featureFlagsControllers.fetchAllTreatments());
  } catch (e) {
    console.error(e, "Failed to initialize the Split SDK");
  }
};
