import { Skeleton } from "antd";
import DataTable, { DATA_TABLE_SIZE_TYPES, Table } from "Modules/DS/DataTable";
import React from "react";
import sharedStyles from "../../Bills.module.scss";
import styles from "./RecipientsTable.module.scss";

const recipientsTableColumns = [
  {
    id: "name",
    enabled: true,
    column: {
      title: "Recipient",
      width: "auto",
      render: () => <Skeleton active paragraph={{ rows: 1, width: "auto" }} title={false} className={styles.shimmer} />,
    },
  },
  {
    id: "countryAndCurrency",
    enabled: true,
    column: {
      title: "Country and Currency",
      width: 256,
      render: () => <Skeleton active paragraph={{ rows: 1, width: "auto" }} title={false} className={styles.shimmer} />,
    },
  },
  {
    id: "type",
    enabled: true,
    column: {
      title: "Recipient Bank Account Type",
      width: 160,
      render: () => <Skeleton active paragraph={{ rows: 1, width: "auto" }} title={false} className={styles.shimmer} />,
    },
  },
  {
    id: "lastTransaction",
    enabled: true,
    column: {
      title: "Last Transaction",
      width: 220,
      render: () => <Skeleton active paragraph={{ rows: 1, width: "auto" }} title={false} className={styles.shimmer} />,
    },
  },
  {
    id: "lastModified",
    enabled: true,
    column: {
      title: "Last Modified By",
      width: 280,
      render: () => <Skeleton active paragraph={{ rows: 1, width: "auto" }} title={false} className={styles.shimmer} />,
    },
  },
];

const RecipientsTableLoader: React.FC = () => {
  return (
    <div className={styles.recipientsTableLoader} data-testid="manage-recipients-loader">
      <Skeleton active paragraph={{ rows: 1, width: 316 }} title={false} className={styles.shimmer} />
      <DataTable className={sharedStyles.draftTable} size={DATA_TABLE_SIZE_TYPES.LARGE}>
        <Table paginationEnabled={false} dataSource={[{}, {}, {}]}>
          {recipientsTableColumns}
        </Table>
      </DataTable>
    </div>
  );
};

export default RecipientsTableLoader;
