import React from "react";
import { Typography } from "@spenmo/splice";
import { SubMenu, Container } from "Views/Settings/components";
import { personalMenu } from "Views/Settings/config";

import styles from "Views/Settings/styles.module.scss";

const Personal = ({ permissions }) => {
  return (
    <section className={styles.body}>
      <Typography tag="p" size="m" variant="body-content" className={styles.caption}>
        Configure and customize your Spenmo account with these settings
      </Typography>
      <section className={styles.content}>
        <SubMenu
          menu={personalMenu.filter((item) => {
            if (!item.enabled) {
              return true;
            }
            return permissions[item.enabled];
          })}
        />
        <Container />
      </section>
    </section>
  );
};

export default Personal;
