import React from "react";

import Modal from "Modules/DS/Modal";
import DSLoader from "Modules/DS/Loader";
import { LOADER_SIZES } from "Modules/DS/Loader/types";

import styles from "./Loader.module.scss";

interface KYXLoaderProps {
  open: boolean;
  message?: string;
}

export const KYXFormSubmitLoader = ({ open, message }: KYXLoaderProps) => {
  return (
    <Modal className={styles.kyxLoader} visible={open}>
      <div className={styles.dialog}>
        <DSLoader size={LOADER_SIZES.BIG} />

        <p className={styles.title}>{message || "Submitting verification data..."}</p>
        <p className={styles.subtitle}>Please do not close this page.</p>
      </div>
    </Modal>
  );
};
