import React from "react";
import { Skeleton } from "antd";

import styles from "./style.module.scss";

const ShimmerEffect = ({ children, loading }) => {
  return loading ? <Skeleton.Input size="small" active={loading} className={styles.inputCell} /> : children || null;
};

export default ShimmerEffect;
