import dayjs from "dayjs";
import heic2any from "heic2any";
import { UploadFile } from "antd/lib/upload/interface";

export const formatDate = (date: number) => {
  return dayjs.unix(date).format("DD MMM YYYY [at] hh:mm a");
};

export const getResponsePayload = <T extends unknown>(data: {
  data: { payload: T };
}): T | undefined => {
  return data?.data?.payload;
};

export const omitKey = <T>(obj: T, keyToOmit: keyof T) => {
  const { [keyToOmit]: omittedKey, ...newObj } = obj;
  return newObj;
};

/**
 * This function is to transform fileList item into Antd Upload data type
 * @param file Antd UploadFile
 * @returns Antd Upload return object
 */
export const transformFile = (file: UploadFile) => {
  const { uid, name, status, originFileObj, type, size, url } = file;

  // if it has url, meaning that it's already been transformed by this function
  if (url) {
    return file;
  }

  return {
    uid,
    name,
    status,
    type,
    size,
    url: URL.createObjectURL(originFileObj),
    originFileObj,
  };
};

/**
 * This function is to get fileList without error status for Antd Upload
 * @param fileList Antd UploadFile
 * @returns array of UploadFile
 */
export const getAcceptedFiles = (fileList: UploadFile[]) => {
  return fileList.filter((file) => file.status && file.status !== "error");
};

/**
 * This function is to get fileList with done status for AntdUpload
 * @param fileList Antd UploadFile
 * @returns array of file object
 */
export const getUploadedFiles = (fileList: UploadFile[]) => {
  return fileList
    .filter((file) => file.status === "done")
    .map((file) => file.originFileObj);
};

export const isEmpty = (value: any) => {
  return value === undefined || value === "" || value === null;
};

export const isPDF = (fileUrl: string) => {
  return fileUrl?.toLowerCase().includes(".pdf");
};

/**
 * This function will transform:
 * 1. Heic/Heif file type into jpeg
 * @param imageUrl image url: string
 * @returns image url: string
 */
export const transformHeicImage = async (imageUrl: string) => {
  if (imageUrl) {
    return fetch(imageUrl)
      .then((res) => res.blob())
      .then((blob) => heic2any({ blob, toType: "image/jpeg" }))
      .then((conversionResult) => {
        // conversionResult is a BLOB
        // of the JPEG formatted image
        const urlCreator = window.URL || window.webkitURL;
        const imageUrl = urlCreator.createObjectURL(conversionResult as Blob);

        return imageUrl;
      })
      .catch((e) => {
        console.error(e);
        // see error handling section
        return imageUrl;
      });
  }

  return imageUrl;
};
