import React from "react";
import { IRouterType } from "Route/@types";
import { CARD_PATHS } from "Route/Card/path";

import UpdateCardActivation from "Views/Wallet/UpdateCardActivation";
import CardsListingV2 from "Views/Card/List";
import CardsFaqs from "Views/Card/Faqs";
import CategoryLockKbArticle from "Views/Card/CategoryLockKbArticle";

export const CardRoute: IRouterType[] = [
  {
    id: "card-1",
    path: CARD_PATHS.CARD_LIST,
    component: <CardsListingV2 />,
    title: "Card list",
    authRequired: true,
    exactPath: true,
  },
  {
    id: "card-2",
    path: CARD_PATHS.PROFILE_UPDATE,
    component: <UpdateCardActivation />,
    title: "card activation update",
    authRequired: true,
    exactPath: true,
  },
  {
    id: "card-3",
    path: CARD_PATHS.CARD_FAQS,
    component: <CardsFaqs />,
    title: "card faqs",
    authRequired: true,
    exactPath: true,
  },
  {
    id: "card-4",
    path: CARD_PATHS.CATEGORY_LOCK_KB_ARCTILE,
    component: <CategoryLockKbArticle />,
    title: "Category lock Kb Article",
    authRequired: true,
    exactPath: true,
  },
];
