import * as React from "react";
import classNames from "classnames";
import { ptAryadana, bankIndonesia, appui } from "assets/img";
import styles from "./AryadanaFooter.module.scss";

export enum ARYADANA_FOOTER_TYPE {
  ONE_LINE = 1,
  TWO_LINES = 2,
  THREE_LINES = 3,
}

interface AryadanaFooterProps {
  type?: number;
}

const AryadanaFooter = ({ type = ARYADANA_FOOTER_TYPE.ONE_LINE }: AryadanaFooterProps) => {
  const additionalClassName = styles[`type${type}`];
  return (
    <div className={classNames(styles.container, additionalClassName)}>
      <div className={styles.item}>
        <img width={60} height={33} src={ptAryadana} alt="PT Aryadana" />
        <div>
          <div>Fund Transfer powered by</div>
          <div>
            <strong>PT Aryadana</strong>
          </div>
        </div>
      </div>
      <div className={styles.item}>
        <img width={36} height={36} src={bankIndonesia} alt="Bank Indonesia" />
        <div>
          <div>Supervised by</div>
          <div>
            <strong>Bank Indonesia</strong>
          </div>
        </div>
      </div>
      <div className={styles.item}>
        <img width={41} height={41} src={appui} alt="APPUI" />
        <div>
          <div>Member of:</div>
          <div>
            <strong>Asosiasi Penyelenggara Pengiriman Uang Indonesia</strong>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AryadanaFooter;
