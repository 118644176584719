const fill_16 = require("assets/v1.1/icons/Navigation/Cross/fill_16.svg");
const fill_24 = require("assets/v1.1/icons/Navigation/Cross/fill_24.svg");

const line_16 = require("assets/v1.1/icons/Navigation/Cross/line_16.svg");
const line_24 = require("assets/v1.1/icons/Navigation/Cross/line_24.svg");

const red_18 = require("assets/v1.1/icons/Navigation/Cross/red_18.svg");
const dark_red_18 = require("assets/v1.1/icons/Navigation/Cross/dark_red_18.svg");
const white_18 = require("assets/v1.1/icons/Navigation/Cross/white_18.svg");

export const Cross = {
  Line: { 16: line_16, 24: line_24 },
  Fill: { 16: fill_16, 24: fill_24, red_18: red_18, dark_red_18: dark_red_18, white_18: white_18 },
};
