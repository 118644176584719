import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import styles from "./styles.module.scss";

import Taxes from "Views/Taxes";
import Categories from "Views/Categories";
import Reimbursements from "Views/Reimbursement/Settings";
import IntegrationSetting from "Views/Accounting/Settings";
import LowBalanceReminder from "Views/LowBalanceReminder/LowBalanceReminder";
import SecurityHub from "Views/SecurityHub";
import BankAccount from "Views/BankAccount";
import PaymentRun from "Views/Settings/components/PaymentRun";
import Billing from "Views/PayInfra/Settings/Billing";

const Container = () => {
  const { path } = useRouteMatch();

  return (
    <section className={styles.container}>
      <Switch>
        <Route path={`${path}/integration`}>
          <div className={styles.fullHeight}>
            <IntegrationSetting />
          </div>
        </Route>
        <Route path={`${path}/category`}>
          <div className={styles.fullHeight}>
            <Categories newSettingPage />
          </div>
        </Route>
        <Route path={`${path}/tax`}>
          <div className={styles.fullHeight}>
            <Taxes newSettingPage />
          </div>
        </Route>
        <Route path={`${path}/payment-run`}>
          <PaymentRun />
        </Route>
        <Route path={`${path}/lowbalancereminder`}>
          <LowBalanceReminder newSettingPage />
        </Route>
        <Route path={`${path}/reimbursement`}>
          <Reimbursements />
        </Route>
        <Route path={`${path}/security`}>
          <div className={styles.fullHeight}>
            <SecurityHub newSettingPage />
          </div>
        </Route>
        <Route path={`${path}/payoutdetail`}>
          <BankAccount newSettingPage />
        </Route>
        <Route path={`${path}/billing`}>
          <Billing />
        </Route>
      </Switch>
    </section>
  );
};

export default Container;
