/**
 * @fileoverview This module provides the Sidebar component used for navigation purposes.
 * The Sidebar displays the Header and the MainMenu components. The Sidebar expects
 * the organization name as a prop to pass down to the Header component.
 *
 * @author Sushmitha, Bakshi
 * @created 24 Aug 2023
 * @lastModified 30 Aug 2023
 */
import React from "react";
import classNames from "classnames";

import { useScrollOverflow } from "utility/useScrollOverflow";

import Header from "Views/Navigation/Header";
import TopMenu from "Views/Navigation/Menu/Top";
import BottomMenu from "Views/Navigation/Menu/Bottom";
import { ISidebar } from "Views/Navigation/@types";

import styles from "./index.module.scss";

/**
 * The Sidebar component serves as a container for the primary navigation items in the application.
 * It consists of the Header (which displays the organization name) and the MainMenu.
 *
 * @param {ISidebar} props - The props passed down to the Sidebar component.
 * @param {string} props.organisationName - The name of the organisation to be displayed in the header.
 *
 * @returns {React.ReactElement} The rendered Sidebar component.
 */
const Sidebar: React.FC<ISidebar> = ({ organisationName }: ISidebar): React.ReactElement => {
  const [divRef, isYAxisOverflown] = useScrollOverflow<HTMLDivElement>();

  return (
    <div className={classNames(styles.container, { [styles.scroll]: isYAxisOverflown })} ref={divRef}>
      <Header organisationName={organisationName} />

      <TopMenu />
      <BottomMenu />
    </div>
  );
};

export default Sidebar;
