import React, { useContext, useState } from "react";

import Menu from "Modules/DS/Menu";
import Select, { ISingleSelectDropdown } from "Modules/DS/Select";

import { TrxnMoreFilterContext } from "Views/Transaction/Context";
import { ISingleMoreFilter, ITrxnMoreFilterContext } from "Views/Transaction/@types";

import { TRXNS_MORE_FILTER_ID } from "./index";

export const SingleSelect = <T extends object = any>({
  id,
  children,
  label,
  displayKey,
  iconKey,
  searchable = false,
  idKey = "id" as keyof T,
  appliedKey = "id" as keyof T,
  loading = false,
  dispatchOnAdd = () => null,
  dispatchOnRemove = () => null,
  onClickOutside = () => null,
  footer,
  header,
  height,
  searchValue,
}: ISingleMoreFilter<T>) => {
  const [title, setTitle] = useState<string>("");
  const [icon, setIcon] = useState<string>("");
  const [selected, setSelected] = useState<string>("");

  const { more, applyMoreFilter, clearMoreFilter, clear } = useContext<ITrxnMoreFilterContext>(TrxnMoreFilterContext);

  const onSelectHandler = (value: string) => {
    applyMoreFilter<string>(id, value);
    dispatchOnAdd?.(value);
  };

  const handleClearDropdown = () => {
    clearMoreFilter(id);

    dispatchOnRemove?.();
  };

  const handleOnClickOutside = () => {
    onClickOutside?.();
    !more[id] && setSelected("");
  };

  const props: ISingleSelectDropdown<T> = {
    idKey,
    displayKey,
    appliedKey,
    iconKey,
    searchValue,
    children,
    height,
    header,
    footer,
    clear,
    defaultValue: label,
    presetValue: more[id],
    parentPopoverId: TRXNS_MORE_FILTER_ID,
    onSelect: onSelectHandler,
    clearDropdown: handleClearDropdown,
    onClickOutside: handleOnClickOutside,
    states: {
      selected,
      setSelected,
      setTitle,
      title,
      loading,
      icon,
      setIcon,
    },
    dropdownProps: {
      id,
      searchable,
      labelIcon: icon,
      containerClass: ".data-table-container",
    },
  };

  return (
    <Menu.Row id={id} title={label}>
      <Select.Dropdown.Single<T> {...props} />
    </Menu.Row>
  );
};
