import APIclient from "API/Client";
import { GetFormData } from "utility/APIWrapper";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import { FETCH_VERIFY_FORGOT_OTP_LIST, OTP_REQUEST_TYPES, VerifyForgotOTPData } from "./types";
import { OTP_EXPIRES_AT_SESSION_STORAGE_NAME } from "Redux/Actions/loginAction";
import { setCookie, cookieNames } from "utility/CookieHelper";

export const fetchForgotOTPRequest = (): { type: FETCH_VERIFY_FORGOT_OTP_LIST.REQUEST } => {
  return {
    type: FETCH_VERIFY_FORGOT_OTP_LIST.REQUEST,
  };
};

export const fetchForgotOTPSuccess = (
  data: VerifyForgotOTPData
): {
  type: FETCH_VERIFY_FORGOT_OTP_LIST.SUCCESS;
  data: VerifyForgotOTPData;
} => {
  return {
    type: FETCH_VERIFY_FORGOT_OTP_LIST.SUCCESS,
    data: data,
  };
};

export const fetchForgotOTPError = (
  data: VerifyForgotOTPData
): {
  type: FETCH_VERIFY_FORGOT_OTP_LIST.ERROR;
  data: VerifyForgotOTPData;
} => {
  return {
    type: FETCH_VERIFY_FORGOT_OTP_LIST.ERROR,
    data: data,
  };
};

export const resetVerifyForgotOTP = (): {
  type: FETCH_VERIFY_FORGOT_OTP_LIST.RESET;
} => {
  return {
    type: FETCH_VERIFY_FORGOT_OTP_LIST.RESET,
  };
};

const setUserDetails = (mobile_number, email) => {
  setCookie(cookieNames.USER_MOBILE_NUMBER, mobile_number);
  setCookie(cookieNames.USER_EMAIL, email);
};

export const VerifyForgotOTP = (type, token, otp?: object) => {
  return (dispatch) => {
    dispatch(fetchForgotOTPRequest());

    const removeOTPExpire = [OTP_REQUEST_TYPES.SEND, OTP_REQUEST_TYPES.RESEND].includes(type);

    APIclient.postData(
      `/api/v2/otp/${type}/forgot-password?t=${token}`,
      type === OTP_REQUEST_TYPES.VERIFICATION && GetFormData({ ...otp })
    )
      .then((data: { [propName: string]: any }) => {
        const { expires_at, mobile_number, email } = data?.data?.payload || {};
        if (data?.data?.status === HTTP_STATUS_CODE.OK) {
          setUserDetails(mobile_number, email);
          removeOTPExpire && sessionStorage.setItem(OTP_EXPIRES_AT_SESSION_STORAGE_NAME, expires_at);
          !removeOTPExpire && sessionStorage.removeItem(OTP_EXPIRES_AT_SESSION_STORAGE_NAME);
          type === OTP_REQUEST_TYPES.VERIFICATION && setCookie(cookieNames.IS_FORGOT_PASSWORD, true);
          dispatch(fetchForgotOTPSuccess(data?.data));
        } else {
          expires_at && sessionStorage.setItem(OTP_EXPIRES_AT_SESSION_STORAGE_NAME, expires_at);
          dispatch(fetchForgotOTPError(data?.data));
        }
      })
      .catch((error) => {
        dispatch(fetchForgotOTPError(error?.response?.data || error));
      });
  };
};
