import React from "react";
import Button from "Modules/button";

import "./TaxDetails.scss";
import { IInconsistenTaxConfirmationProps } from "../types";

const InconsistentTaxConfirmation = ({ onConfirm, data, onCancel, partnerName }: IInconsistenTaxConfirmationProps) => {
  return (
    <>
      <p className="tax-details__confirmation-message">
        The tax rate in Spenmo ({data.taxRate}%) is different from the tax rate in {partnerName} (
        {data?.accountingPartnerTax?.taxRate}%). Do you want to proceed with it anyway or cancel to edit it?
      </p>
      <div className="tax-details__group-button">
        <div className="tax-details__edit-btn bg-green">
          <Button text="Proceed" action={onConfirm} />
        </div>
        <div className="tax-details__edit-btn bg-white">
          <Button text="Cancel" action={onCancel} />
        </div>
      </div>
    </>
  );
};

export default InconsistentTaxConfirmation;
