import * as React from "react";
import { Steps } from "antd";
import "./index.scss";

const { Step } = Steps;

interface StepbarProps {
  currentStep: number;
  numberOfSteps: number;
}

const Stepbar = ({ currentStep, numberOfSteps }: StepbarProps) => {
  const createStepArray = (size) => Array.from({ length: size }).map((_, index) => index + 1);
  return (
    <Steps current={currentStep} className="card-step-bar">
      {numberOfSteps &&
        createStepArray(numberOfSteps)?.map((step) => <Step icon={step} key={step} data-testid="step" />)}
    </Steps>
  );
};

export default Stepbar;
