import { RuleObject } from "antd/lib/form";
import { StoreValue } from "antd/lib/form/interface";
import { BIC_TYPE_KEY, ERROR_BIC_COUNTRY_MISMATCH } from "Modules/DynamicForm/constants";
import { DynamicFormContext } from "Modules/DynamicForm/store";
import { DynamicFormFieldProps } from "Modules/DynamicForm/types";
import React, { useContext } from "react";
import BankValidation from "../BankValidation";

const DynamicFormIbanCode = (props: DynamicFormFieldProps) => {
  const { destinationCountry } = useContext(DynamicFormContext);

  const validator = (_: RuleObject, value: StoreValue): Promise<void> => {
    const countryCode = value.substr(0, 2);
    if (countryCode && countryCode.toLowerCase() !== destinationCountry.toLowerCase())
      return Promise.reject(new Error(ERROR_BIC_COUNTRY_MISMATCH.replace(BIC_TYPE_KEY, props.label)));

    return Promise.resolve();
  };

  return <BankValidation {...props} validator={validator} />;
};

export default DynamicFormIbanCode;
