import { FETCH_APPROVAL_REQUEST, FETCH_APPROVAL_SUCCESS, FETCH_APPROVAL_FAILURE } from "../Actions/approvalAction";
import { userInitialState } from "../InitialState";

export const requestsReducer = (state = userInitialState, action) => {
  switch (action.type) {
    case FETCH_APPROVAL_REQUEST:
      return {
        ...state,
        data: action.data,
        loading: true,
      };
    case FETCH_APPROVAL_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
      };
    case FETCH_APPROVAL_FAILURE:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: true,
      };
    default:
      return state;
  }
};
