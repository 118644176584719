import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import AmountDetails from "./AmountDetails";
import LoadingIcon from "Modules/loading";
import { allWalletFunc } from "Redux/Actions";
import PropTypes from "prop-types";
import { getParamsValue } from "utility";
import { QueryParamsKeys, QueryParamsVaules } from "constants/MoneyQueryParams.constants";
import "Modules/AmountDetails/AmountDetailsWrapper.scss";

const AmountDetailsWrapper = ({
  updateData,
  memberData,
  isRequestMoney,
  disableZeroLimit = false,
  ...restOftheProps
}) => {
  const dispatch = useDispatch();
  const { wdata: allWalletsData = [], loading: allWalletsDataLoading } = useSelector((state) => state.wallet);

  useEffect(() => {
    if (isRequestMoney) {
      dispatch(allWalletFunc());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getWalletData = () => {
    return allWalletsDataLoading ? null : allWalletsData;
  };
  const walletData = getWalletData();

  const onUpdate = (values, member) => {
    updateData({ ...values, ...member });
  };

  if (allWalletsDataLoading) {
    return (
      <div className="amount-details-wrapper">
        <LoadingIcon />
      </div>
    );
  }

  const getInitialWalletId = () => {
    if (isRequestMoney && walletData?.length > 0) {
      const [moneyActionType, initialWalletId] = getParamsValue(
        QueryParamsKeys.MONEY_ACTION_TYPE,
        QueryParamsKeys.INITITAL_WALLET_ID
      );
      if (moneyActionType === QueryParamsVaules.REQUEST_MONEY && initialWalletId) {
        return walletData?.find((wallet) => wallet?.id === initialWalletId) ? initialWalletId : null;
      }
    }
    return null;
  };

  if (walletData && !allWalletsDataLoading) {
    return (
      <AmountDetails
        {...restOftheProps}
        walletData={walletData}
        teamName=""
        action={(values) => onUpdate(values, memberData)}
        showSelectedAmountInFractions={isRequestMoney}
        disableZeroLimit={disableZeroLimit}
        initialWalletId={getInitialWalletId()}
      />
    );
  }
  return <></>;
};

AmountDetailsWrapper.propTypes = {
  title: PropTypes.string,
  showUpload: PropTypes.bool,
  memberData: PropTypes.object,
  merchantData: PropTypes.array,
  intervalsData: PropTypes.array,
  isSchedulePayment: PropTypes.bool,
  checkError: PropTypes.func,
  selectedMembersData: PropTypes.array,
  updateData: PropTypes.func,
  onConfirm: PropTypes.func,
  view: PropTypes.string,
  isVirtual: PropTypes.bool,
  isPhysical: PropTypes.bool,
  trackActions: PropTypes.bool,
  actionType: PropTypes.string,
  isRequestMoney: PropTypes.bool,
};

export default AmountDetailsWrapper;
