import React, { useEffect } from "react";
import { Row, Col } from "antd";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { USER_ROLE } from "constants/Team.constant";
import { blueInfoCircle, ptAryadana } from "assets/img";
import TooltipOnIcon from "Modules/TooltipOnIcon";
import { getMonthName, isOrgPaymentModeCredit, GetBaseAuthObject } from "utility";
import { getRole } from "Views/Home/Components/Header/Utilites";
import { checkLowCompanyBalance, getExpenseElement } from "./Utilites";
import { checkAccess } from "utility/permissions/permission";
import { getOrgDetailFunc } from "Redux/Actions";
import "Views/Home/Components/Header/Header.scss";
import useIsAryadana from "customHooks/useIsAryadana";

import usePermissionCheck from "Permission/usePermissionCheck";
import { CASBIN_PAGES } from "Permission/Pages";
import { HOME_PAGE } from "Permission/Actions";

const Header = ({ companyExpenses, userName, companyRole }) => {
  const dispatch = useDispatch();
  const permissionParam = [{ object: CASBIN_PAGES.HOME_PAGE, action: HOME_PAGE.TOP_UP_ACTION }];
  const IS_ALLOWED_TOP_UP_ACTION = usePermissionCheck(permissionParam);

  const showAryadana = useIsAryadana();

  useEffect(() => {
    dispatch(getOrgDetailFunc(GetBaseAuthObject().orgId));
  }, [dispatch]);

  return (
    <div className="homepage-header__container">
      <Row className="homepage-header__company-details-container" justify="space-between">
        <Col className="homepage-header__username">
          <div className="homepage-header__username-icon">{userName?.charAt(0) || ""}</div>
          {userName}
          {companyRole && <div className="homepage-header__user-role">{getRole(companyRole)}</div>}
        </Col>
        {checkAccess([USER_ROLE.ADMIN, USER_ROLE.ACCOUNTANT, USER_ROLE.AUDITOR], companyRole) && ( // permission need to update in BE
          <Col>
            <Row>
              {getExpenseElement(
                `${getMonthName(new Date())} Company Spending`,
                companyExpenses?.currencyCode,
                companyExpenses?.totalExpense
              )}
              {getExpenseElement(
                isOrgPaymentModeCredit() ? (
                  <>
                    Available Credit Balance
                    <TooltipOnIcon
                      imageSrc={blueInfoCircle}
                      altText="available-credit-tooltip-icon"
                      description={`Spend your credit limit with 0% interest for all Card transactions & 2% interest on Bill Payment & Cash Reimbursements.`}
                      overlayClassName="homepage-header__available-credit-tooltip"
                    />
                  </>
                ) : (
                  <>
                    {showAryadana && (
                      <>
                        <TooltipOnIcon
                          imageSrc={ptAryadana}
                          imageClassName="homepage-header__label__aryadana-tooltip"
                          altText="Powered by PT Aryadana"
                          description="Powered by PT Aryadana"
                          overlayClassName="homepage-header__aryadana-tooltip"
                        />{" "}
                      </>
                    )}
                    Remaining Company Balance
                  </>
                ),
                companyExpenses?.currencyCode,
                companyExpenses?.remainingBalance,
                checkLowCompanyBalance(companyExpenses)
              )}
              {IS_ALLOWED_TOP_UP_ACTION && (
                <Col>
                  <Link to="/topup" className="homepage-header__topup_button">
                    Top Up Now
                  </Link>
                </Col>
              )}
            </Row>
          </Col>
        )}
      </Row>
    </div>
  );
};

Header.propTypes = {
  companyExpenses: PropTypes.object,
  userName: PropTypes.string,
  companyRole: PropTypes.string,
};

export default Header;
