import React from "react";
import { useSelector } from "react-redux";
import useSWRMutation from "swr/mutation";
import cn from "classnames";

import { Modal } from "./index";
import { CheckFilled, DeleteOutline, Typography, toast } from "@spenmo/splice";

import { RootState } from "Redux/ConfigureStore";
import { putData } from "API/Client";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import { useSubscriptionContext } from "Views/SubscriptionManagement/Details/SubscriptionContext";
import { SUBSCRIPTION_STATUS } from "Views/SubscriptionManagement/Constants";
import { capitalizeFirstLetter } from "utility";

import styles from "./styles.module.scss";

const ActionsList = {
  [SUBSCRIPTION_STATUS.ACTIVE]: {
    title: "Deactivating the subscription",
    type: "Deactivate",
    icon: <DeleteOutline size="16" fill="currentColor" />,
    className: styles.deactivate,
  },
  [SUBSCRIPTION_STATUS.CANCELLED]: {
    title: "Reactivating the subscription",
    type: "Reactivate",
    icon: <CheckFilled size="16" fill="currentColor" />,
    className: styles.reactivate,
  },
};

export const SubscriptionAction = ({ closePopover }: { closePopover: () => void }) => {
  const { subscription, mutate: mutateSubscriptionDetails } = useSubscriptionContext();
  const {
    status,
    id,
    vendor: { name },
  } = subscription || {};
  const showErrorNotification = (message: string) => {
    toast.danger(
      message ||
        `We had trouble ${
          actionContent.type.toLowerCase().substring(0, actionContent.type.length - 2) + "ing"
        } this subscription. Please try again.`
    );
  };
  const { trigger: toggleStatus, isMutating } = useSWRMutation(
    `ms/spm-organization/v1/subscriptions/${id}/${status === SUBSCRIPTION_STATUS.ACTIVE ? "deactivate" : "reactivate"}`,
    (url) =>
      putData(url)
        .then(({ data }) => {
          const { status, payload, error } = data || {};
          if (status === HTTP_STATUS_CODE.OK && !payload) {
            closePopover();
            mutateSubscriptionDetails();
            toast.success(
              <>
                <Typography variant="body-content" size="m" weight={600}>
                  {capitalizeFirstLetter(name)}
                </Typography>{" "}
                subscription {actionContent.type.toLowerCase() + "d"} successfully
              </>,
              {
                duration: 3000,
              }
            );
          } else {
            showErrorNotification(error?.message || "");
          }
          return null;
        })
        .catch((err) => {
          showErrorNotification(err?.response?.data?.error?.message || "");
        })
  );
  const action = async (closeModal: () => void) => {
    await toggleStatus();
    closeModal();
  };

  if (status === SUBSCRIPTION_STATUS.EXPIRED) return null;

  const actionContent = ActionsList[status];

  return (
    <ul>
      <Modal
        title={actionContent.title}
        trigger={({ openModal }) => (
          <li
            className={cn(styles.actionItem, actionContent.className)}
            onClick={() => {
              openModal();
              closePopover();
            }}
          >
            {actionContent.icon}
            <Typography size="caption-m" weight={600} variant="body-content">
              {actionContent.type}
            </Typography>
          </li>
        )}
        buttons={[
          {
            id: "1",
            variant: "secondary",
            text: "Go Back",
            closeModal: true,
            disabled: isMutating,
          },
          {
            id: "2",
            variant: "primary",
            text: actionContent.type,
            loading: isMutating,
            disabled: isMutating,
            action,
          },
        ]}
      >
        <ToggleSubscriptionContent isDeactivation={status === SUBSCRIPTION_STATUS.ACTIVE} />
      </Modal>
    </ul>
  );
};

export const ToggleSubscriptionContent = ({ isDeactivation }: { isDeactivation: boolean }) => {
  const userName = useSelector((state: RootState) => state?.userInfoReducer?.data?.payload?.user?.name || "");
  const actionType = isDeactivation ? "deactivate" : "reactivate";
  const actionTypeVerb = actionType.substring(0, actionType.length - 2) + "ing";
  return (
    <>
      <Typography size="m" variant="body-content" className={styles.paragraph} tag="p">
        Hi {userName?.trim()}, just a quick heads-up as you {actionType} your subscription:
      </Typography>

      <ul className={styles.list}>
        <li>Your subscription will remain on Spenmo, now labeled as '{isDeactivation ? "Inactive" : "Active"}'</li>
        <li>
          Spenmo {isDeactivation ? "won't" : "will"} actively search for matching transactions for this subscription{" "}
          {isDeactivation && "anymore"}
        </li>
        <li>
          Please note, {actionTypeVerb} here won't automatically {actionType} it on the vendor's website.
        </li>
      </ul>

      <Typography size="m" variant="body-content" className={styles.paragraph} tag="p">
        Would you like to proceed with {actionTypeVerb} the subscription?
      </Typography>
    </>
  );
};
