/*
  enums
*/
export enum DELETE_EMPLOYEE_CHECK_ACTION_TYPES {
  FETCH_DELETE_EMPLOYEE_CHECK_REQUEST = "EMPLOYEE/FETCH_DELETE_EMPLOYEE_CHECK_REQUEST",
  FETCH_DELETE_EMPLOYEE_CHECK_SUCCESS = "EMPLOYEE/FETCH_DELETE_EMPLOYEE_CHECK_SUCCESS",
  FETCH_DELETE_EMPLOYEE_CHECK_ERROR = "EMPLOYEE/FETCH_DELETE_EMPLOYEE_CHECK_ERROR",
  CLEAR_DELETE_EMPLOYEE_CHECK = "EMPLOYEE/CLEAR_DELETE_EMPLOYEE_CHECK",
}

/*
  interfaces
*/
export interface ISoleTeamManager {
  id: string;
  name: string;
  total_members: number;
}

export interface IPendingRequests {
  type: string;
  total_requests: number;
  approvers: string[];
  unit: string;
}

export interface IPendingToApprove {
  type: string;
  total_requests: number;
  unit: string;
}

export interface IDeleteEmployeeCheckData {
  sole_team_managers?: ISoleTeamManager[];
  pending_requests?: IPendingRequests[];
  pending_to_approve?: IPendingToApprove[];
  is_has_ongoing_transactions?: boolean;
  is_eligible?: boolean;
  is_success?: boolean;
  status_message: string;
  id?: string;
  name?: string;
}

export interface IDeleteEmployeeCheckReducer {
  loading: boolean;
  data: IDeleteEmployeeCheckData;
  error: boolean;
}

/*
  initialize
*/

export const initialDeleteEmployeCheckState: IDeleteEmployeeCheckReducer = {
  loading: false,
  data: {} as IDeleteEmployeeCheckData,
  error: false,
};
