import { GetOrgId, GetUserId, getTeamRole } from "utility";
import { identifyEventWithBrazeDebounce } from "utility/analytics/brazeMiddleware";

export const identifySessionStorageName = "identify-events-remaining-params-sent";

const OS_CONFIG = {
  name: "web portal",
  version: "1.0",
};

const getCommonContextValues = () => {
  const { width = "", height = "" } = window?.screen || {};
  return {
    os: OS_CONFIG,
    screen: {
      width,
      height,
    },
  };
};

const getCommonTrackParams = (userId) => {
  const commonTrackParams = {};
  const organisationId = GetOrgId();
  organisationId && (commonTrackParams.organisation_id = organisationId);
  userId && (commonTrackParams.user_id = userId);
  return commonTrackParams;
};

export const trackEvent = async (eventName, data = {}) => {
  const { track, user } = window.analytics || {};
  const traits = user?.()?.traits?.() || {};
  if (track) {
    const userId = GetUserId();
    const { changeUser } = window?.appboy || {};
    userId && changeUser && changeUser(userId);
    await track(
      eventName,
      {
        ...data,
        ...getCommonTrackParams(userId),
      },
      {
        context: {
          traits,
          ...getCommonContextValues(),
        },
      }
    );
  }
};

export const identifyEvent = (data = {}) => {
  const userId = GetUserId();
  userId &&
    identifyEventWithBrazeDebounce(
      userId,
      {
        ...data,
        "organisation id": GetOrgId(),
      },
      {
        context: {
          ...getCommonContextValues(),
        },
      }
    );
};

export const sendIdentifyEventRemainingParams = (userData, teamData) => {
  const alreadySent = sessionStorage?.getItem?.(identifySessionStorageName);
  if (!alreadySent && userData?.payload?.user?.id && teamData?.name) {
    const {
      payload: { user },
    } = userData;
    const { mobile_number, mobile_country_code, name, id, organisation_name } = user;
    const splittedUserName = name?.split?.(" ") || [];
    const { company_role, your_membership, name: teamName, team_members } = teamData;
    identifyEvent({
      "organisation name": organisation_name || "",
      "user name": name || "",
      "company role": company_role || "",
      "current team": teamName || "",
      "team role": getTeamRole(team_members, id, your_membership),
      // unfollowed standard convention here to support braze
      firstName: splittedUserName?.shift() || "",
      lastName: splittedUserName?.join(" ") || "",
      phone: mobile_number && mobile_country_code ? `${mobile_country_code}${mobile_number}` : "",
    });
    sessionStorage.setItem(identifySessionStorageName, "true");
    groupEvent({
      "organisation name": user?.organisation_name || "",
    });
  }
};

export const groupEvent = (data = {}) => {
  const { group } = window.analytics || {};
  const orgId = GetOrgId();
  group &&
    orgId &&
    group(
      orgId,
      {
        ...data,
        "organisation id": GetOrgId(),
      },
      {
        context: {
          ...getCommonContextValues(),
        },
      }
    );
};
