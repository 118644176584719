export const transactionType = [
  {
    id: "card_expense",
    title: "Card Expense",
  },
  {
    id: "billpay_expense",
    title: "Bill Pay Expense",
  },
  {
    id: "cash_reimbursement",
    title: "Cash Reimbursement",
  },
  {
    id: "fees",
    title: "Fees",
  },
];
