import React, { useCallback, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Popover } from "antd";
import FilterPopover from "./FilterPopover";
import FilterContent from "Modules/SimpleFilter/FilterContent";
import { ISelectionData } from "Modules/multiSelection/DropdownSelection";
import "./SimpleFilter.scss";

export interface ISimpleFilter {
  text: string;
  list: Array<ISelectionData>;
  defaultValue?: Array<string>;
  values?: Array<string> | string;
  labels?: Array<string> | string;
  isMultiSelect?: boolean;
  hasClearButton?: boolean;
  hasSearch?: boolean;
  hideActive?: boolean;
  popupClassName?: string;
  popupOverClassName?: string;
  onlyShowText?: boolean;
  action: (payload) => void;
}

const SimpleFilter: React.FC<ISimpleFilter> = ({
  text,
  values,
  list,
  labels,
  defaultValue = [],
  isMultiSelect,
  hasSearch,
  hasClearButton,
  action,
  hideActive,
  popupClassName,
  popupOverClassName,
  onlyShowText,
}) => {
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const [isActive, setIsActive] = useState(defaultValue.length > 0);

  const clearFilter = useCallback(
    (event) => {
      action([]);
      setIsPopoverVisible(false);
      event.stopPropagation();
    },
    [action]
  );

  const content = useMemo(() => {
    if (onlyShowText || (!values?.length && !labels?.length)) return text;

    return (
      <FilterContent
        text={text}
        isPopoverVisible={isPopoverVisible}
        clearFilter={clearFilter}
        showTextMultipleValues
        values={labels || values}
      />
    );
  }, [text, labels, values, clearFilter, isPopoverVisible, onlyShowText]);

  const handleAction = (values) => {
    setIsActive(values.length > 0);
    action(values);
  };

  const popover = (
    <FilterPopover
      list={list}
      defaultValue={defaultValue}
      isMultiSelect={isMultiSelect}
      hasSearch={hasSearch}
      hasClearButton={hasClearButton}
      values={values}
      popupOverClassName={popupOverClassName}
      action={handleAction}
      closeAction={() => setIsPopoverVisible(false)}
    />
  );

  const buttonActiveClass = !hideActive && isActive ? "popout__content--on" : "";

  return (
    <div className={"popout"}>
      <Popover
        content={popover}
        visible={isPopoverVisible}
        trigger={"click"}
        onVisibleChange={setIsPopoverVisible}
        placement="bottomLeft"
        className={popupClassName}
        overlayClassName={"popout__overlay"}
      >
        <div className={`popout__content ${buttonActiveClass}`}>{content}</div>
      </Popover>
    </div>
  );
};

SimpleFilter.propTypes = {
  text: PropTypes.string,
  list: PropTypes.array,
  defaultValue: PropTypes.array,
  isMultiSelect: PropTypes.bool,
  hasSearch: PropTypes.bool,
  hasClearButton: PropTypes.bool,
  action: PropTypes.func,
  hideActive: PropTypes.bool,
};

export default SimpleFilter;
