import * as React from "react";

import { IHeaderProps } from "Modules/DS/DataTable";

import "./index.scss";

export const Header = ({ title, subtitle }: IHeaderProps) => {
  return (
    <div className="data-table__header__container">
      <h1 className="data-table__header__title">{title}</h1>
      {subtitle && <p className="data-table__header__subtitle">{subtitle}</p>}
    </div>
  );
};
