import React from "react";
import { Link } from "react-router-dom";
import { TitleColorTypes } from "Views/Home/Components/ExpenseCard/Common/Constants";
import rightArrowGreen from "assets/img/arrows/rightArrowGreen.svg";
import rightArrowBlue from "assets/img/arrows/rightArrowBlue.svg";

const getArrowImage = (color) => {
  switch (color) {
    case TitleColorTypes.GREEN:
      return rightArrowGreen;
    case TitleColorTypes.BLUE:
      return rightArrowBlue;
    default:
      return rightArrowBlue;
  }
};

export const ExpenseDetailTitle = ({ title, color, to }) => {
  const _classname = `expense-card__expense-detail-title expense-card__expense-detail-title--${color}`;
  return to ? (
    <Link className={_classname} to={to}>
      {title}
      <img src={getArrowImage(color)} alt={`${color} right arrow`} />
    </Link>
  ) : (
    <p className={_classname}>{title}</p>
  );
};
