import React from "react";
import cn from "classnames";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Typography } from "antd";

import Icon from "Modules/icons";

import styles from "./BillsItem.module.scss";

const BillsItem = ({
  icon,
  url,
  subtitle,
  children,
  onClick,
  className,
  withChevron,
  customIcon,
  customComponent,
  customComponentProps = {},
}) => {
  const { Title, Paragraph } = Typography;

  const Component = url ? Link : "div",
    props = url ? { to: url } : {};

  const SelectedComponent = customComponent ? customComponent : Component;
  return (
    <SelectedComponent {...customComponentProps} {...props} onClick={onClick} className={cn(styles.item, className)}>
      <div className={styles.container}>
        {icon && (
          <div className={cn(styles.content, styles.icon)}>
            <Icon src={icon} />
          </div>
        )}
        <div className={cn(styles.content, styles.flex1)}>
          <div className={styles.titleContainer}>
            <Title className={styles.title} level={4}>
              {children}
            </Title>
            {(url || withChevron) && (
              <div className={styles.navigationIcon}>{customIcon || <Icon iconName="chevron-right" />}</div>
            )}
          </div>
          {subtitle && <Paragraph className={styles.subtitle}>{subtitle}</Paragraph>}
        </div>
      </div>
    </SelectedComponent>
  );
};

BillsItem.propTypes = {
  icon: PropTypes.string,
  url: PropTypes.string,
  subtitle: PropTypes.node,
  children: PropTypes.node,
};

BillsItem.defaultProps = { icon: undefined, url: undefined, subtitle: undefined };

export default BillsItem;
