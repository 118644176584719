import * as merchantIcons from "assets/img/merchantIcons";

const MerchantIcons = [
  {
    name: "ACRA",
    icon: merchantIcons.Acra,
  },
  {
    name: "Adobe",
    icon: merchantIcons.Adobe,
  },
  {
    name: "Amazon",
    icon: merchantIcons.Amazon,
  },
  {
    name: "Atlassian",
    icon: merchantIcons.Atlassian,
  },
  {
    name: "ClickUp",
    icon: merchantIcons.ClickUp,
  },
  {
    name: "Comfort/citycab",
    icon: merchantIcons.Comfort,
  },
  {
    name: "Deliveroo",
    icon: merchantIcons.Deliveroo,
  },
  {
    name: "Facebook",
    icon: merchantIcons.Facebook,
  },
  {
    name: "Figma",
    icon: merchantIcons.Figma,
  },
  {
    name: "FoodPanda",
    icon: merchantIcons.FoodPanda,
  },
  {
    name: "GoGoX/GoGoVan",
    icon: merchantIcons.GoGox,
  },
  {
    name: "Gojek",
    icon: merchantIcons.Gojek,
  },
  {
    name: "Google",
    icon: merchantIcons.Google,
  },
  {
    name: "Grab",
    icon: merchantIcons.Grab,
  },
  {
    name: "Hubspot",
    icon: merchantIcons.Hubspot,
  },
  {
    name: "Lazada",
    icon: merchantIcons.Lazada,
  },
  {
    name: "Mailchimp",
    icon: merchantIcons.Mailchimp,
  },
  {
    name: "Microsoft",
    icon: merchantIcons.Microsoft,
  },
  {
    name: "LinkedIn",
    icon: merchantIcons.LinkedIn,
  },
  {
    name: "Netflix",
    icon: merchantIcons.Netflix,
  },
  {
    name: "Pickupp",
    icon: merchantIcons.Pickupp,
  },
  {
    name: "Shippit",
    icon: merchantIcons.Shippit,
  },
  {
    name: "Shopee",
    icon: merchantIcons.Shopee,
  },
  {
    name: "Shopify",
    icon: merchantIcons.Shopify,
  },
  {
    name: "Spotify",
    icon: merchantIcons.Spotify,
  },
  {
    name: "StockxX",
    icon: merchantIcons.StockxX,
  },
  {
    name: "Tech in Asia",
    icon: merchantIcons.TechInasia,
  },
  {
    name: "Tiktok",
    icon: merchantIcons.Tiktok,
  },
  {
    name: "Twilio",
    icon: merchantIcons.Twilio,
  },
  {
    name: "Typeform",
    icon: merchantIcons.Typeform,
  },
  {
    name: "Uber",
    icon: merchantIcons.Uber,
  },
  {
    name: "Udemy",
    icon: merchantIcons.Udemy,
  },
  {
    name: "Upwork",
    icon: merchantIcons.Upwork,
  },
  {
    name: "Xero",
    icon: merchantIcons.Xero,
  },
  {
    name: "Zoom",
    icon: merchantIcons.Zoom,
  },
];

export default MerchantIcons;
