import classNames from "classnames";
import React, { useState } from "react";
import styles from "./index.module.scss";

interface ITypeTextToConfirmActionProps {
  title: string;
  matchText: string;
  name?: string;
  placeholder: string;
  errorText: string;
  result: (valid: boolean) => void;
}

const TypeTextToConfirmAction = ({
  title,
  matchText,
  name = matchText.trim(),
  placeholder,
  errorText,
  result,
}: ITypeTextToConfirmActionProps) => {
  const [inputValue, setInputValue] = useState<string>("");
  const emptyInput = !inputValue;
  const textNotMatchedError = inputValue && inputValue !== matchText;
  const inputError = emptyInput || textNotMatchedError;
  const inputErrorStyle = classNames(styles["input-error"], {
    [styles["input-error-active"]]: textNotMatchedError,
  });
  result(!inputError);

  return (
    <section className={`${styles["action-area"]}`}>
      <p>{title}</p>
      <input
        placeholder={placeholder}
        name={name}
        className={`${styles.input}`}
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
      />
      <p className={inputErrorStyle}>{errorText}</p>
    </section>
  );
};

export default TypeTextToConfirmAction;
