import React from "react";
import cn from "classnames";

import { CREATE_STEPS } from "Views/SubscriptionManagement/Constants";

import { Typography } from "@spenmo/splice";
import { CheckedCircle } from "assets/icons/CheckedCircle";
import { Circle } from "assets/icons/Circle";

import styles from "./styles.module.scss";

export const Stepper = ({ step }: { step: CREATE_STEPS }) => {
  const isReportingPaymentStep = step === CREATE_STEPS.SECOND;

  return (
    <ul className={styles.stepper}>
      <li className={styles.activeStep}>
        {isReportingPaymentStep ? <CheckedCircle /> : <Circle fill={"var(--dark-orange-040)"} />}
        <Typography
          size="s"
          variant="body-content"
          tag="p"
          weight={isReportingPaymentStep ? null : 600}
          className={cn(styles.title, styles.active, isReportingPaymentStep ? styles.complete : null)}
        >
          1. Contract details
        </Typography>
      </li>
      <li className={cn(styles.middleLine, { [styles.active]: isReportingPaymentStep })}></li>
      <li className={styles.lastSection}>
        <Circle fill={isReportingPaymentStep ? "var(--dark-orange-040)" : "var(--white-060)"} />
        <Typography
          size="caption-m"
          variant="body-content"
          tag="p"
          weight={isReportingPaymentStep ? 600 : null}
          className={cn({ [styles.active]: isReportingPaymentStep, [styles.title]: true })}
        >
          2. Reporting & Payment
        </Typography>
      </li>
    </ul>
  );
};
