import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Popover, Button } from "antd";
import DetailPopover from "./DetailPopover";
import "./DetailDropdown.scss";

const DetailDropdown = ({ initialValue, options = [], action = () => {}, createListItem = () => {} }) => {
  const [newOptions, setNewOptions] = useState(options);
  const [selected, setSelected] = useState(null);
  const [detail, setDetail] = useState(null);
  const [detailTitle, setDetailTitle] = useState("");
  const [detailItem, setDetailItem] = useState(null);
  const [isVisible, setIsVisible] = useState(false);

  const clearDetail = () => {
    setDetail(null);
    setDetailTitle("");
    setDetailItem(null);
  };

  const handleAction = (values) => {
    setIsVisible(false);
    action(values);
  };

  const addNewListItem = (value) => {
    const temp = [];
    const listItem = createListItem(value);
    options.forEach((item) => {
      if (item?.value === listItem?.detail) {
        temp.push(listItem);
      }
      temp.push(item);
    });
    setNewOptions(temp);
    setSelected(listItem);
  };

  const handleDetailAction = (value) => {
    addNewListItem(value);
    handleAction(value);
  };

  useEffect(() => {
    if (initialValue) {
      const selectedInitial = options?.find((item) => item.value === initialValue);
      if (selectedInitial) setSelected(selectedInitial);
    } else {
      setSelected(options[0]);
    }
  }, [initialValue, options]);

  useEffect(() => {
    if (isVisible) clearDetail();
  }, [isVisible]);

  useEffect(() => {
    const isValueExists = newOptions?.find((item) => item.value === initialValue);
    if (initialValue && !isValueExists) {
      addNewListItem(initialValue);
    }
  }, [detailItem, initialValue, options]);

  const popoverContent = (
    <div className="detail-dropdown__popover__content">
      {detail ? (
        <DetailPopover
          title={detailTitle}
          content={detail}
          backAction={clearDetail}
          handleAction={handleDetailAction}
          initialValues={selected?.value}
        />
      ) : (
        <ul>
          {newOptions?.map((item, idx) => (
            <li
              className={item?.value === selected?.value ? "active" : ""}
              key={idx}
              onClick={() => {
                if (item.value !== selected.value) {
                  if (item?.detail) {
                    setDetail(item?.detail);
                    setDetailTitle(item?.title);
                    setDetailItem(item);
                  } else {
                    setNewOptions(options);
                    setSelected(item);
                    handleAction(item?.value);
                  }
                } else {
                  setIsVisible(false);
                }
              }}
            >
              {item.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );

  return (
    <Popover
      trigger="click"
      placement="topLeft"
      content={popoverContent}
      visible={isVisible}
      overlayClassName="detail-dropdown__popover"
      onVisibleChange={(value) => {
        setIsVisible(value);
        // delay change state after animation
        setTimeout(clearDetail, 200);
      }}
    >
      <Button className="detail-dropdown__button">{selected?.label}</Button>
    </Popover>
  );
};

DetailDropdown.propTypes = {
  initialValue: PropTypes.string,
  options: PropTypes.array,
  action: PropTypes.func,
  createListItem: PropTypes.func,
};

export default DetailDropdown;
