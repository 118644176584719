import React from "react";

export const AuthContentHeader = ({
  title,
  description,
}: {
  title: string;
  description?: string;
}): React.ReactElement => {
  return (
    <>
      <h2 className="auth-title">{title}</h2>
      {Boolean(description) && <p className="auth-title-description">{description}</p>}
    </>
  );
};
