import React from "react";
import Button from "Modules/button";
import classNames from "classnames";
import { handleTimer } from "../utils";
import { useOTPTimeoutHandler } from "../useOTPTimeoutHandler";

interface Props {
  resendOtp: () => void;
  align?: string;
}

const OtpTimeOut = (props: Props): React.ReactElement => {
  const { resendOtp, align } = props;

  const counter = useOTPTimeoutHandler();

  const _className = classNames("employee-otp-verification__message", {
    [`employee-otp-verification__message--${align}`]: align,
  });

  if (counter < 1) {
    return (
      <p className={_className}>
        <Button action={resendOtp} textButton className="employee-otp-verification__resend-btn">
          Resend code
        </Button>
      </p>
    );
  }

  return !isNaN(counter) && <p className={_className}>Resend code in {handleTimer(counter)}</p>;
};

export default OtpTimeOut;
