/**
 * `IPageGrid` describes the structure and properties for a route configuration.
 *
 * @property {PAGE_GRID_COLUMNS} [columns] - Optional grid column layout type for the route's content (By default we have a Single Column Grid).
 * @property {PAGE_GRID_CONTENT_TYPE} [content] - Optional content type for the route (By default we have a Data Content Grid).
 */
export interface IPageGrid {
  columns?: PAGE_GRID_COLUMNS | undefined;
  content?: PAGE_GRID_CONTENT_TYPE | undefined;
  baseColor?: PAGE_BASE_COLOR_TYPE | undefined;
}

/**
 * Enum representing the types of content a page can have.
 *
 * @enum {string}
 * @readonly
 * @property {string} FLUID - Represents content type where the data content width is not fixed. Content width is fluid.
 * @property {string} FIXED - Represents a content type where the data content is of a fixed width i.e. 1020px
 */
export enum PAGE_GRID_CONTENT_TYPE {
  FLUID = "data-content",
  FIXED = "non-data-content",
}

/**
 * Enum representing the types of background color a page can have.
 *
 * @enum {string}
 * @readonly
 * @property {string} DEFAULT - Represents the default white background color.
 * @property {string} BRAND_NAVY - Represents the brand navy fill color from splice.
 */
export enum PAGE_BASE_COLOR_TYPE {
  DEFAULT = "default",
  BRAND_NAVY = "brand-navy",
}

/**
 * Enum describing the grid column configurations that a page can use for its layout.
 *
 * @enum {string}
 * @readonly
 * @property {string} SINGLE - Represents a single column grid.
 * @property {string} TWENTY_FOUR - Represents a 24-column grid.
 * @property {string} NONE - Overrides the grid templates.
 */
export enum PAGE_GRID_COLUMNS {
  SINGLE = "single-column-grid",
  TWENTY_FOUR = "24-column-grid",
  NONE = "overide-grid",
}
