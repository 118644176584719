import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import classNames from "classnames";
import { Form, Tooltip } from "antd";
import { FormInstance } from "antd/lib/form";
import { Store } from "antd/lib/form/interface";
import { grayLoadingIcon16x16 } from "assets/icons/card";

import Button from "Modules/button";
import Icon, { IconList } from "Modules/Icon";

import {
  FORM_NAME,
  KYX_FORMS_KEYS,
  KYX_MODAL_TYPES,
  PROGRESS_FOOTER_ACTION,
  PROGRESS_TABS,
} from "Views/KYX/Constants";

import { LoadingContext } from "Views/KYX/Progress";
import { useFormSaveAsDraft } from "Views/KYX/hooks";
import { removeFalsyValues } from "Views/KYX/Helper";
import { KYXFormSubmitLoader } from "Views/KYX/Modal/Submit";
import { KYXPrimaryButton } from "Views/KYX/Intro/Start/Button";
import { useStepChangeHandler } from "Views/KYX/hooks/useStepChangeHandler";
import { KYXModalContext } from "Views/KYX/Context";
import { ManualFormContext } from "Views/KYX/Context/ManualForm";
import {
  IProgressFooterActionType,
  ISaveAsDraftRequest,
  IManualFormContext,
  IKYXModalContext,
} from "Views/KYX/@types";
import { debounce } from "utility";

import { ArrowLeftIcon } from "./Back.icon";
import styles from "./index.module.scss";

export interface IProgressFooter {
  form: FormInstance;
  form_id: string;
  initialValues: Store;
  isFormTouched: boolean;
  setIsFormTouched?: (isFormTouched: boolean) => void;
}

export const NextButton = ({
  isLastStep,
  loading,
}: {
  isLastStep: boolean;
  loading: boolean;
}) => {
  const buttonText = isLastStep ? "Submit" : "Next";

  return (
    <Form.Item>
      <KYXPrimaryButton loading={loading}>{buttonText}</KYXPrimaryButton>
    </Form.Item>
  );
};

const DraftButton = ({
  formatData,
  saveFormAsDraft,
  setAutoSaveData,
  handleFooterActions,
}: {
  formatData: any;
  saveFormAsDraft: (payload: ISaveAsDraftRequest) => void;
  setAutoSaveData: React.Dispatch<
    React.SetStateAction<{
      isSaving: boolean;
      lastSaveTime: Date;
    }>
  >;
  handleFooterActions: (type: IProgressFooterActionType) => Promise<void>;
}) => {
  // ? Time can be adjusted later right now following what was mentioned in JIRA and design
  const TIME_IN_M = 14;
  const TIME_IN_S = TIME_IN_M * 60;
  const TIME_IN_MS = TIME_IN_S * 1000;

  useEffect(() => {
    const interval = setInterval(() => {
      const formattedData = formatData();
      saveFormAsDraft(formattedData);
      setAutoSaveData({ isSaving: true, lastSaveTime: undefined });
    }, TIME_IN_MS);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Form.Item>
      <Button
        className={classNames(styles.button, styles.lightBtn)}
        type="button"
        name="saveAsDraftBtn"
        action={() => {
          handleFooterActions(PROGRESS_FOOTER_ACTION.DRAFT);
        }}
      >
        Save as Draft
      </Button>
    </Form.Item>
  );
};

const SavingLoader = ({
  isSaving,
  lastSaveTime,
}: {
  isSaving: boolean;
  lastSaveTime: Date;
}) => {
  return (
    <div className={styles.savingLoader}>
      {isSaving ? (
        <img src={grayLoadingIcon16x16} alt="Loading Icon" />
      ) : (
        <Tooltip
          title={`Draft saved automatically ${moment(lastSaveTime).fromNow()}`}
          placement="left"
        >
          <Icon
            className={styles.savingLoaderIcon}
            icon={IconList.uploadSuccess}
            svgAttr={{ width: 24, height: 24, viewBox: "0 0 24 24" }}
          />
        </Tooltip>
      )}
      <p className={styles.savingLoaderText}>
        {isSaving ? "Saving ..." : "Saved"}
      </p>
    </div>
  );
};

const BackButton = ({
  handleFooterActions,
}: {
  handleFooterActions: (type: IProgressFooterActionType) => Promise<void>;
}) => {
  return (
    <button
      className={styles.backBtn}
      type="button"
      onClick={() => handleFooterActions(PROGRESS_FOOTER_ACTION.BACK)}
    >
      <ArrowLeftIcon />
      Back
    </button>
  );
};

export const ProgressFooter = ({
  form,
  form_id,
  initialValues,
  isFormTouched,
  setIsFormTouched,
}: IProgressFooter) => {
  const { modal }: IKYXModalContext = useContext(KYXModalContext);
  const { setIsFormModified, setFormData }: IManualFormContext =
    useContext(ManualFormContext);
  const DEBOUNCE_DELAY = 1000;
  const [showLoader, setShowLoader] = useState(false);

  const { handleStepChange, activeTabIndex } = useStepChangeHandler();
  const { saveFormAsDraft, loading } = useFormSaveAsDraft(() => {
    setIsFormTouched(false);
    setIsFormModified(false);
  });
  const loadingContext = useContext(LoadingContext);
  const isLastStep = activeTabIndex === PROGRESS_TABS.length - 1;

  const formatData = (): ISaveAsDraftRequest => {
    const formValues: any = form.getFieldsValue();
    const personsObject = formValues.persons || initialValues.persons;
    const personsArray = personsObject
      ? Object.keys(personsObject).map((key) => {
          return {
            ...personsObject[key],
            identification_document: {
              ...personsObject[key]?.identification_document,
              is_same_residential_address: personsObject[key]
                ?.identification_document?.is_same_residential_address?.length
                ? personsObject[key]?.identification_document
                    ?.is_same_residential_address?.[0] === "checked"
                : false,
            },
          };
        })
      : [];

    const isBusinessAddressDifferent = formValues?.company_information
      ?.company_address?.is_business_address_different.length
      ? formValues?.company_information?.company_address
          ?.is_business_address_different?.[0] === "true"
      : false;

    const doesntHavePublicWebsite = formValues?.company_information
      ?.additional_information?.doesnt_have_public_website?.length
      ? formValues?.company_information?.additional_information
          ?.doesnt_have_public_website?.[0] === "true"
      : false;

    const socialMediaData = removeFalsyValues(
      formValues[FORM_NAME.COMPANY_INFORMATION]?.[
        KYX_FORMS_KEYS.ADDITIONAL_INFORMATION
      ]?.[KYX_FORMS_KEYS.SOCIAL_MEDIA] ||
        initialValues[FORM_NAME.COMPANY_INFORMATION]?.[
          KYX_FORMS_KEYS.ADDITIONAL_INFORMATION
        ]?.[KYX_FORMS_KEYS.SOCIAL_MEDIA],
    );
    const arcaDocument =
      formValues?.[FORM_NAME.COMPANY_DOCUMENTS]?.[
        KYX_FORMS_KEYS.ACRA_DOCUMENT
      ] ||
      initialValues?.[FORM_NAME.COMPANY_DOCUMENTS]?.[
        KYX_FORMS_KEYS.ACRA_DOCUMENT
      ];
    return {
      id: form_id,
      form: {
        [FORM_NAME.COMPANY_DOCUMENTS]: {
          [KYX_FORMS_KEYS.ACRA_DOCUMENT]: {
            ...arcaDocument,
            expiry_date: moment.utc(arcaDocument.expiry_date).format(),
          },
          [KYX_FORMS_KEYS.DUE_DILIGENCE_DOCUMENTS]:
            formValues?.[FORM_NAME.COMPANY_DOCUMENTS]?.[
              KYX_FORMS_KEYS.DUE_DILIGENCE_DOCUMENTS
            ] ||
            initialValues?.[FORM_NAME.COMPANY_DOCUMENTS]?.[
              KYX_FORMS_KEYS.DUE_DILIGENCE_DOCUMENTS
            ],
          [KYX_FORMS_KEYS.IS_DILIGENCE_DOCUMENT_REQUIRED]:
            formValues?.[FORM_NAME.COMPANY_DOCUMENTS]?.[
              KYX_FORMS_KEYS.IS_DILIGENCE_DOCUMENT_REQUIRED
            ] ||
            initialValues?.[FORM_NAME.COMPANY_DOCUMENTS]?.[
              KYX_FORMS_KEYS.IS_DILIGENCE_DOCUMENT_REQUIRED
            ],
          [KYX_FORMS_KEYS.MEMORANDUM_FILE]:
            formValues?.[FORM_NAME.COMPANY_DOCUMENTS]?.[
              KYX_FORMS_KEYS.MEMORANDUM_FILE
            ] ||
            initialValues?.[FORM_NAME.COMPANY_DOCUMENTS]?.[
              KYX_FORMS_KEYS.MEMORANDUM_FILE
            ],
          [KYX_FORMS_KEYS.OWNERSHIP_CHART_FILE]:
            formValues?.[FORM_NAME.COMPANY_DOCUMENTS]?.[
              KYX_FORMS_KEYS.OWNERSHIP_CHART_FILE
            ] ||
            initialValues?.[FORM_NAME.COMPANY_DOCUMENTS]?.[
              KYX_FORMS_KEYS.OWNERSHIP_CHART_FILE
            ],
          [KYX_FORMS_KEYS.OWNERSHIP_NUMBER_OF_LEVELS]:
            formValues?.[FORM_NAME.COMPANY_DOCUMENTS]?.[
              KYX_FORMS_KEYS.OWNERSHIP_NUMBER_OF_LEVELS
            ] ||
            initialValues?.[FORM_NAME.COMPANY_DOCUMENTS]?.[
              KYX_FORMS_KEYS.OWNERSHIP_NUMBER_OF_LEVELS
            ],
          [KYX_FORMS_KEYS.SIGNED_BOARD_RESOLUTION_FORM]:
            formValues?.[FORM_NAME.COMPANY_DOCUMENTS]?.[
              KYX_FORMS_KEYS.SIGNED_BOARD_RESOLUTION_FORM
            ] ||
            initialValues?.[FORM_NAME.COMPANY_DOCUMENTS]?.[
              KYX_FORMS_KEYS.SIGNED_BOARD_RESOLUTION_FORM
            ],
        },
        [FORM_NAME.PERSONNEL_INFORMATION]: {
          has_only_director:
            formValues.has_only_director || initialValues.has_only_director,
          number_of_beneficial_owners:
            formValues.number_of_beneficial_owners ||
            initialValues.number_of_beneficial_owners,
          persons: personsArray?.map((person) => {
            return {
              ...person,
              date_of_birth:
                person?.date_of_birth &&
                moment.utc(person?.date_of_birth).format(),
              roles_of_individual: person?.roles_of_individual
                ? Object.assign(
                    {},
                    ...person?.roles_of_individual?.map((role) => {
                      return role ? { [role]: true } : null;
                    }),
                  )
                : {},
            };
          }),
          share_holders: [],
        },
        [FORM_NAME.COMPANY_INFORMATION]: {
          [KYX_FORMS_KEYS.ADDITIONAL_INFORMATION]: {
            [KYX_FORMS_KEYS.COMPANY_WEBSITE]:
              formValues[FORM_NAME.COMPANY_INFORMATION]?.[
                KYX_FORMS_KEYS.ADDITIONAL_INFORMATION
              ]?.[KYX_FORMS_KEYS.COMPANY_WEBSITE] ||
              initialValues[FORM_NAME.COMPANY_INFORMATION][
                KYX_FORMS_KEYS.ADDITIONAL_INFORMATION
              ][KYX_FORMS_KEYS.COMPANY_WEBSITE],
            [KYX_FORMS_KEYS.SOCIAL_MEDIA]: Object.keys(socialMediaData).map(
              (item) => {
                return {
                  type: socialMediaData[item]?.[
                    KYX_FORMS_KEYS.SOCIAL_MEDIA_TYPE
                  ],
                  url: socialMediaData[item]?.[KYX_FORMS_KEYS.SOCIAL_MEDIA_URL],
                };
              },
            ),
            doesnt_have_public_website: doesntHavePublicWebsite,
          },
          [KYX_FORMS_KEYS.COMPANY_ADDRESS]: {
            ...(formValues[FORM_NAME.COMPANY_INFORMATION]?.[
              KYX_FORMS_KEYS.COMPANY_ADDRESS
            ] ||
              initialValues[FORM_NAME.COMPANY_INFORMATION]?.[
                KYX_FORMS_KEYS.COMPANY_ADDRESS
              ]),
            is_business_address_different: isBusinessAddressDifferent,
          },
          [KYX_FORMS_KEYS.GENERAL_INFORMATION]:
            formValues[FORM_NAME.COMPANY_INFORMATION]?.[
              KYX_FORMS_KEYS.GENERAL_INFORMATION
            ] ||
            initialValues[FORM_NAME.COMPANY_INFORMATION]?.[
              KYX_FORMS_KEYS.GENERAL_INFORMATION
            ],
        },
      },
    };
  };

  const onSaveAsDraft = (formattedData) => {
    setShowLoader(true);
    saveFormAsDraft(formattedData);
  };

  useEffect(() => {
    if (modal.type === KYX_MODAL_TYPES.SAVE_AS_DRAFT_LOGOUT_MODAL) {
      const formattedData = formatData();
      setFormData(formattedData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal]);

  useEffect(() => {
    const handleFieldChange = debounce(() => {
      if (isFormTouched) {
        const formattedData = formatData();
        setShowLoader(false);
        saveFormAsDraft(formattedData);
      }
    }, DEBOUNCE_DELAY);

    handleFieldChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFormTouched]);

  const [autoSaveData, setAutoSaveData] = useState<{
    isSaving: boolean;
    lastSaveTime: Date;
  }>({
    isSaving: false,
    lastSaveTime: undefined,
  });

  useEffect(() => {
    if (autoSaveData.isSaving) {
      setAutoSaveData({ isSaving: loading, lastSaveTime: new Date() });
    }
  }, [autoSaveData.isSaving, loading]);

  useEffect(() => {
    setIsFormModified(isFormTouched);

    const unloadEventFunction = (ev) => {
      if (isFormTouched) {
        ev.preventDefault();
        return (ev.returnValue = "Are you sure you want to close?");
      }
    };
    window.addEventListener("beforeunload", unloadEventFunction);
    return () => {
      window.removeEventListener("beforeunload", unloadEventFunction);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFormTouched]);

  const handleFooterActions = async (type: IProgressFooterActionType) => {
    switch (type) {
      case PROGRESS_FOOTER_ACTION.NEXT:
        handleStepChange(type);
        break;
      case PROGRESS_FOOTER_ACTION.BACK:
        handleStepChange(type);
        break;
      case PROGRESS_FOOTER_ACTION.DRAFT:
        const formattedData = formatData();
        onSaveAsDraft(formattedData);
        break;
      default:
        break;
    }
  };
  return (
    <>
      <KYXFormSubmitLoader
        open={showLoader && loading && !autoSaveData.isSaving}
        message="Saving Draft..."
      />
      <footer className={styles.footer}>
        <div className={styles.container}>
          <BackButton handleFooterActions={handleFooterActions} />
          <div className={styles.buttonGroup}>
            {!isFormTouched && <SavingLoader {...autoSaveData} />}
            <DraftButton
              formatData={formatData}
              handleFooterActions={handleFooterActions}
              saveFormAsDraft={onSaveAsDraft}
              setAutoSaveData={setAutoSaveData}
            />
            <NextButton
              isLastStep={isLastStep}
              loading={loading || loadingContext.loading}
            />
          </div>
        </div>
      </footer>
    </>
  );
};
