export const BILL_EVENTS = {
  slaClick: "Bill Customer Support Button Clicked",
  detailViewed: "Bill Details Viewed",
  allBillsPageLoaded: "Bill All Bills Page Loaded",
  approvalNotifClicked: "Bill Approval Notification Clicked",
  bulkAttachmentProcessed: "Bill (Bulk) Attachment Processed",
  bulkAttachmentUploaded: "Bill (Bulk) Attachment Uploaded",
  bulkProcessed: "Bill (Bulk) Processed",
  bulkSubmitted: "Bill (Bulk) Submitted",
  bulkTemplateAccessed: "Bill (Bulk) Template Accessed",
  createPageLoaded: "Bill Create Page Loaded",
  editPageClosed: "Bill Edit Page Closed",
  editPageLoaded: "Bill Edit Page Loaded",
  fieldsEdited: "Bill Fields Edited",
  fieldsViewed: "Bill Fields Viewed",
  insuficientBalanceNotifClicked: "Bill Insufficient Balance Notification Clicked",
  previewPageClosed: "Bill Preview Page Closed",
  previewPageLoaded: "Bill Preview Page Loaded",
  proofOfPaymentClicked: "Billpay Proof of Payment Clicked",
  receiptUploaded: "Bill Receipt Uploaded",
  singleAttachmentProcessed: "Bill (Single) Attachment Processed",
  singleAttachmentUploaded: "Bill (Single) Attachment Uploaded",
  singleProcessed: "Bill (Single) Processed",
  singleSubmitted: "Bill (Single) Submitted",
  submitClicked: "Bill Submit Clicked",
};

export const BILL_SUBMISSION_TYPES = {
  single: "Single",
  bulk: "Bulk",
};

export const BILL_FLOW_TYPES = {
  edit: "Edit Bill",
  new: "New Bill",
};

export enum BILLS_ANALYTICS {
  PAGE_LOADED = "Bill Page Loaded",
  CREATION_BUTTON_CLICKED = "Bill Creation Button Clicked",
}

export enum CREATE_BILLS_MODE {
  DRAG_AND_DROP = "Drag and Drop",
  UPLOAD_A_SPREADSHEET = "Upload a Spreadsheet",
  SINGLE_FORM = "Single Form",
  IMPORT_FROM_XERO = "Import from Xero",
  ATTACH_INVOICE = "Attach Invoice",
}
