import React from "react";
import { Modal as ModalWrapper } from "@spenmo/splice";
import { useRouteMatch } from "react-router-dom";

import { PAGE_TITLE, REIMBURSEMENT_MODAL_OPTIONS, REIMBURSEMENT_PAGE_OPERATIONS } from "Views/Reimbursement/Constants";

import Method from "Views/Reimbursement/Modal/Method";
import MakePayment from "Views/Reimbursement/Modal/Payment";
import { ILocationParams } from "Views/Reimbursement/@types";
import MarkAsSettled from "Views/Reimbursement/Modal/Settled";

const DefaultModal = () => {
  return <ModalWrapper showModal={false} size="m" title={PAGE_TITLE} />;
};

const Modal = () => {
  const { params } = useRouteMatch<ILocationParams>();

  const {
    operation = "",
    id = "",
    secondaryOperation = "",
    secondaryId = "",
    tertiaryOperation = "",
    tertiaryId = "",
  } = params;

  let Component: React.FunctionComponent = undefined;

  switch (true) {
    case operation === REIMBURSEMENT_PAGE_OPERATIONS.MODAL && id === REIMBURSEMENT_MODAL_OPTIONS.METHOD_VALIDATION:
    case secondaryOperation === REIMBURSEMENT_PAGE_OPERATIONS.MODAL &&
      secondaryId === REIMBURSEMENT_MODAL_OPTIONS.METHOD_VALIDATION:
      Component = Method;
      break;

    case secondaryOperation === REIMBURSEMENT_PAGE_OPERATIONS.MODAL &&
      secondaryId === REIMBURSEMENT_MODAL_OPTIONS.MAKE_PAYMENT:
    case tertiaryOperation === REIMBURSEMENT_PAGE_OPERATIONS.MODAL &&
      tertiaryId === REIMBURSEMENT_MODAL_OPTIONS.MAKE_PAYMENT:
      Component = MakePayment;
      break;

    case secondaryOperation === REIMBURSEMENT_PAGE_OPERATIONS.MODAL &&
      secondaryId === REIMBURSEMENT_MODAL_OPTIONS.MARK_AS_SETTLED:
    case tertiaryOperation === REIMBURSEMENT_PAGE_OPERATIONS.MODAL &&
      tertiaryId === REIMBURSEMENT_MODAL_OPTIONS.MARK_AS_SETTLED:
      Component = MarkAsSettled;
      break;

    case operation === "" && id === "":
    default:
      Component = DefaultModal;
      break;
  }

  return <Component />;
};

export default Modal;
