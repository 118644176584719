import APIclient from "API/Client";
import { GetOrgId } from "../../utility";

export const UploadFile = (file) => {
  const orgId = GetOrgId();
  let formData = new FormData();
  formData.append("blob", file);
  formData.append("organisation_id", orgId);

  return APIclient.postData(`/api/v1/photo`, formData)
    .then((resp) => {
      return resp;
    })
    .catch((err) => {
      console.error(err);
      return err;
    });
};

export const uploadPayBills = async (file, onUploadProgress) => {
  const formData = new FormData();
  formData.append("file_xlsx", file);
  formData.append("organisation_id", GetOrgId());

  return APIclient.postData(`/api/v1/invoices/send-email`, formData, false, {
    onUploadProgress,
  });
};
