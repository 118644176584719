import { createContext, useContext } from "react";

interface IInitialTagsContext {
  initialTags: any;
  setInitialTags: (value: any) => void;
}

const InitialTagContext = createContext<IInitialTagsContext>({
  initialTags: [],
  setInitialTags: () => {},
});

export const useInitialTags: () => IInitialTagsContext = () => useContext(InitialTagContext);

export default InitialTagContext;
