import {
  FETCH_ADD_EMPLOYEE_REQUEST,
  FETCH_ADD_EMPLOYEE_SUCCESS,
  FETCH_ADD_EMPLOYEE_ERROR,
  FETCH_ADD_EMPLOYEE_RESET,
} from "../../Actions/OnBoarding/addEmployeesAction";
import { userInitialState } from "../../InitialState";

export const addEmployeeReducer = (state = userInitialState, action) => {
  switch (action.type) {
    case FETCH_ADD_EMPLOYEE_REQUEST:
      return {
        ...state,
        loading: true,
        data: action.data,
        error: false,
      };
    case FETCH_ADD_EMPLOYEE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: false,
      };
    case FETCH_ADD_EMPLOYEE_ERROR:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: true,
      };
    case FETCH_ADD_EMPLOYEE_RESET:
      return userInitialState;
    default:
      return state;
  }
};
