import React from "react";
import { documentLoadingBlue, documentLoadingGreen, documentError } from "assets/img";

import { IBill } from "./index";
import styles from "./styles.module.scss";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import { checkImportBillStatus } from "Redux/DataCalls/ImportBillFromXero.api";

interface IConfig {
  onRetry: () => void;
}

export const generateCustomEmptyState = (loading: boolean, bills: IBill[], error: any, config: IConfig) => {
  if (loading) {
    return (
      <div className={styles.empty}>
        <img src={documentLoadingGreen} alt="empty data icon" />
        <p className={styles.message}>Please wait while we fetch bills created on Xero</p>
      </div>
    );
  }

  if (!loading && error?.message) {
    return (
      <div className={styles.empty}>
        <img src={documentError} alt="empty data icon" />
        <p className={styles.message}>
          Fetch unsuccessful for bills from Xero.{" "}
          <span className={styles.link} onClick={config.onRetry}>
            Try again
          </span>
        </p>
      </div>
    );
  }

  if (!loading && bills.length === 0) {
    return (
      <div className={styles.empty}>
        <img src={documentLoadingBlue} alt="empty data icon" />
        <p className={styles.message}>No bills at Xero to fetch into Spenmo at this moment</p>
      </div>
    );
  }
};

export const findBillInCurrentPage = (page: number, pageSize: number, bills: IBill[]) => {
  const startPage = page * pageSize;
  const endPage = startPage + pageSize;
  return bills.slice(startPage, endPage);
};

export const sleep = (second: number) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(null);
    }, second * 1000);
  });
};

export const isCategoryMappingIssue = (bill: IBill) =>
  bill?.originalCategory?.id && bill?.originalCategory?.id !== bill?.mappedCategory?.id;
export const isTaxMappingIssue = (bill: IBill) =>
  bill?.originalTax?.id && bill?.originalTax?.id !== bill?.mappedTax?.id;

export const checkBillStatus = async (progressId) => {
  const { data } = await checkImportBillStatus(progressId);
  const { total = 0, completed = 0 } = data?.payload || {};
  const notStarted = total === 0 || completed === 0;
  const stillProcessing = total !== completed;

  if (data.status !== HTTP_STATUS_CODE.OK) {
    throw new Error();
  }
  if (notStarted || stillProcessing) {
    await sleep(1);
    return checkBillStatus(progressId);
  } else if (total === completed) {
    return Promise.resolve("done");
  }
};
