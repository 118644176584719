import React, { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { greyCloseIcon } from "assets/img";
import Modal, { ModalComponent } from "Modules/DS/Modal";
import { TITLE_TYPE } from "Modules/DS/Modal/types";
import Button from "Modules/button";
import useLoading from "utility/useLoading";
import { useQuery } from "utility/useQuery";
import { trackEvent } from "utility/analytics";
import { getLastAccountingEvent } from "Redux/DataCalls/Taxes.api";
import { ImportCategories } from "Redux/DataCalls/Categories.api";
import ImportCategoryInformation from "./components/ImportCategoryInformation";
import CategoryImporting from "./components/CategoryImporting";
import { CategoryContext } from "Views/Categories/context/CategoryContext";
import { IImportTaxStep, IModalCategoryImport } from "../types";
import { categoryEvents } from "../Analytics";

import "./styles.scss";

const ModalCategoryImport = ({ visible, onClose }: IModalCategoryImport) => {
  const missingCategories = useSelector((state: any) => state.categoryReducer?.list?.missingCategories || {});
  const { payload } = useSelector((state: any) => state.accountingPartnerAuthReducer.data);
  const { partnerName, partnerLogoUrl } = payload || {};

  const { setToaster, refetch } = useContext(CategoryContext);
  const [activeStep, setActiveStep] = useState(0);
  const importCategoryEvent = useQuery({
    apiCall: () => getLastAccountingEvent("import_category"),
    run: activeStep === 0 && visible,
  });

  const [importCategory, loadingImportCategory] = useLoading(ImportCategories, (response) => {
    return response?.data?.error?.message || "Something Went Wrong";
  });
  const steps: IImportTaxStep[] = [
    {
      id: 1,
      Component: ImportCategoryInformation,
      text: "Import Category",
      action: () => {
        trackEvent(categoryEvents.IMPORT_CATEGORY_FROM_MODAL);
        setActiveStep(activeStep + 1);
      },
      height: "short",
    },
    {
      id: 2,
      Component: CategoryImporting,
      text: "Finish Importing & Mapping",
      height: "long",
      action: async () => {
        const { categories } = missingCategories;

        if (!categories) {
          onClose();
          return;
        }

        const formattedCategories = categories.map((category) => {
          return { [category.account_name]: category.account_number };
        });

        const { data } = await importCategory(formattedCategories);

        setToaster({
          message: data?.payload?.status_message,
          show: true,
          type: "success",
        });
        refetch(categories);
        trackEvent(categoryEvents.FINISH_IMPORTING_CATEGORY);
        onClose();
      },
    },
  ];
  const step = steps[activeStep];

  const disabledButton = () => {
    if (activeStep === 1 && missingCategories?.count === 0) {
      return true;
    }

    return loadingImportCategory;
  };

  return (
    <Modal
      visible={visible}
      className={`import-category-modal import-category-modal__${step.height}`}
      close={() => {
        setActiveStep(0);
        onClose();
      }}
    >
      <ModalComponent.CloseButton fixToTop src={greyCloseIcon} />
      <ModalComponent.Title titleType={TITLE_TYPE.withBorder}>Add Category</ModalComponent.Title>
      <section className="import-category-modal__body">
        <div className="import-category-modal__body-content">
          <step.Component
            missingCategories={missingCategories}
            importCategoryEvent={importCategoryEvent}
            partnerName={partnerName}
            partnerLogoUrl={partnerLogoUrl}
          />
          <div className="download-button-wrapper">
            <Button
              rounded
              disabled={disabledButton()}
              loading={loadingImportCategory}
              className={disabledButton() ? "button--processing" : "button--download"}
              action={step.action}
              type="submit"
              text={step.text}
            />
          </div>
        </div>
      </section>
    </Modal>
  );
};

export default ModalCategoryImport;
