import React, { useContext, useEffect, useState } from "react";
import styles from "./index.module.scss";
import { singpassIcon, featureCheckIcon, retriveSingpassIcon } from "assets/img";
import Icon from "Modules/icons";
import LoadingComponent from "Views/State/Loading/LoaderIcon";
import { usePostMyInfoAuthorise, useGetMyInfoToken } from "Views/KYX/hooks/MyInfo";
import { IGetMyInfoTokenResponse } from "Views/KYX/DataCalls/MyInfo.api";
import { KYXModalContext } from "Views/KYX/Context";
import { IKYXModalContext } from "Views/KYX/@types";
import { useHistory } from "react-router-dom";
import { COMPANY_VERIFICATION } from "Route/KYX/paths";
import { KYX_MODAL_TYPES } from "Views/KYX/Constants";

const JointApplicantWelcome = (): JSX.Element => {
  const { loading: tokenLoading, myInfoToken } = useGetMyInfoToken();
  const [tokenData, setTokenData] = useState({} as IGetMyInfoTokenResponse);
  const { setModal }: IKYXModalContext = useContext(KYXModalContext);
  const history = useHistory();

  const { loading: authroiseLoading, myInfoAuthorise } = usePostMyInfoAuthorise();

  const getParamToken = (): string | null => {
    const paramsObject = new URLSearchParams(window.location.search);
    if (paramsObject.get("t")) {
      return paramsObject.get("t");
    } else {
      history.push(COMPANY_VERIFICATION.INVALID_TOKEN);
    }
  };

  useEffect(() => {
    myInfoToken(getParamToken())
      .then((response) => {
        setTokenData(response);
      })
      .catch((error) => {
        if (error?.response?.data?.error?.code === 4016) {
          history.push(COMPANY_VERIFICATION.INVALID_TOKEN);
        } else if (error?.response?.data?.error?.code === 4017) {
          history.push(COMPANY_VERIFICATION.SESSION_EXPIRED);
        } else {
          history.push("/404");
        }
      });
  }, []);

  const handleRetrieveSingpass = () => {
    myInfoAuthorise({ token: getParamToken() })
      .then((response) => {
        window.location.href = response?.authorise_url;
      })
      .catch(() => {
        setModal({
          type: KYX_MODAL_TYPES.ACCESS_DENIED_JOINT_APPLICANT,
          visible: true,
          payload: {
            errorFields: tokenData?.person_name,
          },
        });
      });
  };

  if (tokenLoading || authroiseLoading) {
    return <LoadingComponent />;
  }

  const singpassFeatures: string[] = ["No supporting documents required", "Estimated time: 7mins"];

  return (
    <div className={styles.welcomeContainer}>
      <h1 className={styles.title}>Hi {tokenData?.person_name},</h1>
      <p className={styles.verifyDescription}>
        You are required to verify your personal information as a shareholder for {tokenData?.company_name} using
        Singpass. Make sure all of your personal information is correct to continue your company verification process.
      </p>
      <div className={styles.singpassContianer}>
        <Icon src={singpassIcon} alt={"singpass icon"} />
        <div className={styles.singpassContent}>
          <p>
            Provide your personal information with Singpass. Applicable for Singapore Citizen, Singapore Permanent
            Resident, and FIN Holder.
          </p>
          <div className={styles.singpassFeatures}>
            {singpassFeatures.map((feature) => (
              <div className={styles.featureItem}>
                <Icon src={featureCheckIcon} alt={"feature check icon"} />
                <p>{feature}</p>
              </div>
            ))}
          </div>
          <button className={styles.retriveButton} onClick={handleRetrieveSingpass}>
            <Icon src={retriveSingpassIcon} alt={"retrive singpass icon"} />
          </button>
        </div>
      </div>
      <p className={styles.footerDiscription}>
        Singpass enables you to retrieve your personal data from participating Government agencies. With your consent,
        we can auto-fill this form, making your application more convenient.{" "}
      </p>
    </div>
  );
};

export default JointApplicantWelcome;
