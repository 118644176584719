import React, { useContext } from "react";
import { AuthPageContext } from "Views/Auth";
import "./styles.scss";
import Banner from "Modules/DS/Banner";

export const AuthBox = ({ children }: { children: React.ReactElement }): React.ReactElement => {
  const { appendClassName } = useContext(AuthPageContext);
  return (
    <div className="auth-box-wrapper">
      <Banner
        type="info"
        icon={
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12ZM11 9C11 9.55228 11.4477 10 12 10C12.5523 10 13 9.55228 13 9C13 8.44772 12.5523 8 12 8C11.4477 8 11 8.44772 11 9ZM11 12C11 11.4477 11.4477 11 12 11C12.5523 11 13 11.4477 13 12V16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16V12Z"
              fill="#333744"
            />
          </svg>
        }
        className="auth-box-banner"
        message="Protect yourself from phishing and scams. Do not respond to requests for personal information or any login credentials."
      />
      <div className={`auth-box ${appendClassName || ""}`}>{children}</div>
    </div>
  );
};
