import "./text-label.scss";

import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Icon from "../icons";

const TextLabel = (props) => {
  const { className, icon } = props;

  const _className = classNames("text-label", className);

  return (
    <div className={_className}>
      {icon && <Icon className={"text-label__icon"} iconName={icon} />}
      <span>{props.children}</span>
    </div>
  );
};

TextLabel.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  icon: PropTypes.string,
};

export default TextLabel;
