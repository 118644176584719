import { AMOUNT_RANGE } from "Modules/DS/Select";
import { DATE_RANGE } from "Modules/DS/Filter/@types";
import { ATTACHMENT_FILTER } from "Views/Transaction/@types";

export enum TRANSACTION_FILTER {
  TEAM_ID = "team_id",
  USER_ID = "user_id",
  TRXNS_TYPE = "type",
  CATEGORY_ID = "category_id",
  MERCHANT = "merchant",
  ATTACHMENTS = "attachment",
  SYNC_STATUS = "xero_sync_status",
  BILL_STATUS = "statusList",
}

export interface ITransactionFilter {
  [DATE_RANGE.START]?: string;
  [DATE_RANGE.END]?: string;
  [TRANSACTION_FILTER.TEAM_ID]?: string;
  [TRANSACTION_FILTER.USER_ID]?: string;
  [TRANSACTION_FILTER.MERCHANT]?: string[];
  [TRANSACTION_FILTER.ATTACHMENTS]?: ATTACHMENT_FILTER;
  [TRANSACTION_FILTER.TRXNS_TYPE]?: string[];
  [TRANSACTION_FILTER.CATEGORY_ID]?: string[];
  [AMOUNT_RANGE.MIN]?: number;
  [AMOUNT_RANGE.MAX]?: number;
  [TRANSACTION_FILTER.SYNC_STATUS]?: string[];
  [TRANSACTION_FILTER.BILL_STATUS]?: string[];
}

export interface ITransactionFilterProps {
  filters: ITransactionFilter;
  onApply: (values: ITransactionFilter) => void;
  view: string;
}

export interface ITransactionMoreFilter {
  applyFilter: (newFilter: object, dispatchAction?: Function) => void;
  resetFilter: (keys: string[], dispatchAction?: Function) => void;
  filter: ITransactionFilter;
  clearFilter: (keys: string[]) => void;
}

export type IMoreFilter = Pick<
  ITransactionFilter,
  | AMOUNT_RANGE.MAX
  | AMOUNT_RANGE.MIN
  | TRANSACTION_FILTER.SYNC_STATUS
  | TRANSACTION_FILTER.ATTACHMENTS
  | TRANSACTION_FILTER.TRXNS_TYPE
  | TRANSACTION_FILTER.CATEGORY_ID
>;

export interface ITrxnCategory {
  id: number;
  category_name: string;
  organisation_id: string;
  mcc_category_code: null;
  accounting_system_code: "";
  accounting_system_name: string;
  tax_id: string;
  tax_name: string;
  tax_rate: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
}

export interface ITeamData {
  id: string;
  team_name: string;
  organisation_id: string;
  created_by: string;
  modified_by: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  team_type: string;
  subwallet_id: string;
  total_members: number;
  subwallet: {
    id: string;
    available_balance: string;
    wallet_type: string;
    created_at: string;
    updated_at: string;
    deleted_at: string;
    organisation_id: string;
    currency_code: string;
    accounting_category: string;
    balance_type: string;
  };
}

export interface IMemberData {
  email: string;
  id: string;
  mobile_country_code: number;
  mobile_number: number;
  name: string;
  organisation_id: string;
}
