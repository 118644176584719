import React from "react";
import { Tooltip } from "Views/Card/Components/Tooltip";

import styles from "./index.module.scss";

const UnverifiedTag = () => {
  return (
    <Tooltip
      tooltipText="Employees marked as Not Verified are unable to be cardholders. You can create cards for them once their verification is approved."
      placement="top"
    >
      <div className={styles.tag}>Not Verified</div>
    </Tooltip>
  );
};

export default UnverifiedTag;
