import React, { useState } from "react";
import PropTypes from "prop-types";
import { Form, Input, Select } from "antd";
import AccountingTags from "Modules/AccountingTags";
import OCRTooltip from "Modules/ocrTooltip";
import { useActiveCategory } from "utility/useActiveCategory";

import { roundValue } from "utility";

import SpDropDownList from "Modules/SpDropDownList";

const Categorization = ({ taxesData, trackView, trackEdit, renderOptionalLabel, setTags, form, ocrData, isWHT }) => {
  const { Option } = Select;

  const { categoryList } = useActiveCategory();

  const [categorySearch, setCategorySearch] = useState(""),
    [taxSearch, setTaxSearch] = useState("");

  return (
    <>
      <Form.Item label={renderOptionalLabel("Category")} name="category">
        <SpDropDownList
          allowClear
          placeholder="Select category"
          onDoSearch={(val) => setCategorySearch(val)}
          onFocus={() => trackView("category")}
          onBlur={() => trackEdit("category")}
        >
          {categoryList.length &&
            categoryList
              .filter((category) => category.categoryName.toLowerCase().includes(categorySearch.toLowerCase()))
              .map((category, index) => (
                <Option key={index} value={category.id}>
                  {category.categoryName}
                </Option>
              ))}
        </SpDropDownList>
      </Form.Item>
      <Form.Item name="tag">
        <AccountingTags
          onChange={setTags}
          initialTags={form.getFieldValue("tags")}
          label={renderOptionalLabel("Tags")}
        />
      </Form.Item>
      {!isWHT && (
        <Form.Item label={renderOptionalLabel("Tax")} name="tax">
          <SpDropDownList
            allowClear
            placeholder="Select tax"
            onDoSearch={(val) => setTaxSearch(val)}
            onFocus={() => trackView("tax")}
            onBlur={() => trackEdit("tax")}
          >
            {taxesData?.payload?.data?.length &&
              taxesData?.payload?.data
                .filter(
                  (tax) =>
                    tax.tax_name.toLowerCase().includes(taxSearch.toLowerCase()) ||
                    String(tax.tax_rate).includes(taxSearch)
                )
                .map((tax, index) => (
                  <Option key={index} value={tax.id}>
                    {tax.tax_name} ({roundValue(tax.tax_rate)}%)
                  </Option>
                ))}
          </SpDropDownList>
        </Form.Item>
      )}
      <Form.Item
        label={
          Boolean(ocrData?.terms) ? (
            <OCRTooltip label="Notes" isOptional isOCROveridden={ocrData?.terms !== form.getFieldValue("notes")} />
          ) : (
            renderOptionalLabel("Notes")
          )
        }
        name="notes"
      >
        <Input placeholder="Type notes" onFocus={() => trackView("notes")} onBlur={() => trackEdit("notes")} />
      </Form.Item>
    </>
  );
};

Categorization.propTypes = {
  taxesData: PropTypes.object,
  trackView: PropTypes.func,
  trackEdit: PropTypes.func,
  renderOptionalLabel: PropTypes.func,
  setTags: PropTypes.func,
  form: PropTypes.object,
};

export default Categorization;
