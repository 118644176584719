import React from "react";
import qs from "query-string";
import { ActionChipGroup, Switch, Typography } from "@spenmo/splice";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";

import {
  REIMBURSEMENT_QUERY_PARAMS,
  REIMBURSEMENT_PAGE_OPERATIONS,
  REIMBURSEMENT_SETTLEMENT_STATUSES,
  REIMBURSEMENT_SETTLEMENT_OPTIONS,
  REIMBURSEMENT_VIEWS,
} from "Views/Reimbursement/Constants";
import { ILocationParams } from "Views/Reimbursement/@types";

import styles from "./index.module.scss";

export const SettlementViewChips = () => {
  const history = useHistory();
  const location = useLocation();

  const { params } = useRouteMatch<ILocationParams>();
  const { id = "" } = params;

  const getPath = (label: string) => {
    const val = REIMBURSEMENT_SETTLEMENT_OPTIONS.find((value) => label === value.label).id;

    return id && location.pathname.includes(id)
      ? location.pathname.replace(`${id}`, `${val}`)
      : location.pathname + `/${val}`;
  };

  const getActive = () => REIMBURSEMENT_SETTLEMENT_OPTIONS.findIndex((value) => id === value.id);

  const handleOnClick = (label: string) => {
    history.push({
      pathname: getPath(label),
      search: qs.stringify({
        [REIMBURSEMENT_QUERY_PARAMS.PAGE]: 1,
      }),
    });
  };

  return (
    <ActionChipGroup items={REIMBURSEMENT_SETTLEMENT_OPTIONS} defaultActive={getActive()} onClick={handleOnClick} />
  );
};

export const SettlementView = () => {
  const history = useHistory();
  const location = useLocation();

  const { params } = useRouteMatch<ILocationParams>();
  const { operation = "", id = "" } = params;

  const isActive = operation === REIMBURSEMENT_PAGE_OPERATIONS.SETTLEMENTS;

  const activePath = `${REIMBURSEMENT_VIEWS.COMPANY_REIMBURSEMENTS.link}/${REIMBURSEMENT_PAGE_OPERATIONS.SETTLEMENTS}/${REIMBURSEMENT_SETTLEMENT_STATUSES.APPROVED.id}`;
  const inactivePath = location.pathname.replace(`/${operation}/${id}`, "");

  const urlPath = isActive ? inactivePath : activePath;

  const handleSwitchToggle = () => {
    history.push({
      pathname: urlPath,
      search: qs.stringify({
        [REIMBURSEMENT_QUERY_PARAMS.PAGE]: 1,
      }),
    });
  };

  return (
    <div className={styles.settlement}>
      <Typography size="s" variant="body-content">
        Settlement View
      </Typography>
      <Switch size="s" onClick={handleSwitchToggle} isActive={isActive} />
    </div>
  );
};
