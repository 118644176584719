import React, { useState } from "react";
import classNames from "classnames";
import { Checkbox, Form } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox/Checkbox";

import { IChecklistProps, IOption, ISelectedOptions } from "Views/KYX/@types";

/**
 * A component that renders a checklist of options.
 *
 * @param {Object} restProps - Any additional props to pass to the underlying Ant Design `Checkbox.Group` component.
 * @returns {JSX.Element} - The rendered `Checklist` component.
 */

export const KYXChecklist = ({ formProps, checkProps }: IChecklistProps): JSX.Element => {
  const { name, options, onChange, disabled, className, ...restProps } = checkProps || {};
  const [selectedOptions, setSelectedOptions] = useState<ISelectedOptions>({});

  // The function to handle option changes
  const handleOptionChange = (event: CheckboxChangeEvent, option: IOption): void => {
    const isChecked = event.target.checked;

    // Create a new selected options object with the updated option
    // Please make sure to pass the name field in options
    const selectedList = option.name && { ...selectedOptions, [option.name]: isChecked };
    // Updates the selected options state and triggers the onChange function if provided
    setSelectedOptions(selectedList);
    onChange?.(selectedList);
  };

  return (
    <Form.Item {...formProps}>
      <Checkbox.Group data-testid="checkList" className={classNames({ [className || ""]: className })} {...restProps}>
        {options.map((option: IOption) => {
          const { label, value, ...otherProps } = option;
          return (
            <Checkbox
              key={value}
              disabled={disabled}
              value={value}
              onChange={(e) => handleOptionChange(e, option)}
              {...otherProps}
            >
              {label}
            </Checkbox>
          );
        })}
      </Checkbox.Group>
    </Form.Item>
  );
};
