import React from "react";
import { Typography, toast } from "@spenmo/splice";
import { DEFAULT_ERROR_MESSAGE } from "Views/Transaction/Constants";
import { useSaveTrxnDetails } from "Views/Transaction/Details/useFetch";

export const useLinkSubscription = () => {
  const { linkSubscription } = useSaveTrxnDetails();

  const handleLinkSubscription = async (
    selectedTnxNums: string[],
    subscriptionId: string,
    subscriptionName: string
  ) => {
    try {
      await linkSubscription(selectedTnxNums, subscriptionId);
      toast.success(
        <Typography variant="body-content" size="m" tag="p">
          Transactions added to{" "}
          <Typography variant="body-content" size="m" weight={600}>
            {subscriptionName}
          </Typography>{" "}
          subscription successfully
        </Typography>
      );
    } catch (error) {
      toast.danger(error?.message || DEFAULT_ERROR_MESSAGE);
    }
  };

  return handleLinkSubscription;
};
