export interface IDeleteTeamContext {
  error: boolean;
  deleted: boolean;
  loading: boolean;
  deleteTeam: (teamId: string) => void;
  resetDeleteTeamContext: () => void;
}
export interface IDeleteTeamEligibilityContext {
  isEligible: boolean;
  checkEligibility: (teamId: string) => void;
  data: IDeleteEligibilityResponse;
  loading: boolean;
  resetEligibilityContext: () => void;
}
export interface IDeleteTeamEligibilityState {
  loading: boolean;
  data: IDeleteEligibilityResponse;
  error: boolean;
}
export interface IDeleteTeamState {
  loading: boolean;
  data: IDeleteTeamResponse;
  error: boolean;
}
export interface IDeleteEligibilityResponse {
  payload: {
    validationErrors?: Array<{ key: string }>;
    statusMessage: string;
  };
}
export interface IDeleteTeamResponse {
  payload: {
    status_message: string;
  };
}

export enum EligibilityValidationError {
  PENDING_REIMBURSEMENT = "pending_reimbursements",
  PENDING_BILLS = "pending_bills",
  ACTIVE_CARDS = "active_cards",
  MEMBERS_HAVE_NO_OTHER_BUDGET = "members_have_no_other_budget",
}
export interface IEligibilityValidationErrors {
  key: EligibilityValidationError;
  title: string;
  subtitle: string;
  icon: string;
}

export interface IDeleteTeamWarning {
  [key: string]: { content: string[]; icon: string };
}
