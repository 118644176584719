import { CASBIN_PAGES } from "Permission/Pages";
import { CARDS_PAGE } from "Permission/Actions";
import {
  CARDS_PAGE_CARDS_DETAILS_PAGE_HISTORY_TAB,
  CARDS_PAGE_CARDS_DETAILS_PAGE_SETTINGS_TAB,
} from "Permission/Actions";

export const CARD_LISTING_PERMISSION_PARAMS = [
  { object: CASBIN_PAGES.CARDS_PAGE, action: CARDS_PAGE.SENT_CARD_REQUEST_TAB },
];

export const ADD_CARD_PERMISSION_PARAMS = [
  {
    object: CASBIN_PAGES.CARDS_PAGE,
    action: CARDS_PAGE.ADD_CARD_BUTTON,
  },
];

export const CARD_DETAILS_PERMISSION_PARAMS = [
  {
    object: CASBIN_PAGES.CARDS_PAGE_CARDS_DETAILS_PAGE_HISTORY_TAB,
    action: CARDS_PAGE_CARDS_DETAILS_PAGE_HISTORY_TAB.READ,
  },
  {
    object: CASBIN_PAGES.CARDS_PAGE_CARDS_DETAILS_PAGE_SETTINGS_TAB,
    action: CARDS_PAGE_CARDS_DETAILS_PAGE_SETTINGS_TAB.READ,
  },
  {
    object: CASBIN_PAGES.CARDS_PAGE_CARDS_DETAILS_PAGE_SETTINGS_TAB,
    action: CARDS_PAGE_CARDS_DETAILS_PAGE_SETTINGS_TAB.EDIT_BUTTON,
  },
  {
    object: CASBIN_PAGES.CARDS_PAGE_CARDS_DETAILS_PAGE_SETTINGS_TAB,
    action: CARDS_PAGE_CARDS_DETAILS_PAGE_SETTINGS_TAB.DELETE_BUTTON,
  },
];
