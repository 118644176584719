import React, { useState } from "react";
import qs from "query-string";
import { Sidepanel } from "@spenmo/splice";
import { useHistory, useLocation } from "react-router-dom";
import { TActionButton } from "@spenmo/splice/lib/shared/types";
import { ISidepanelProps } from "@spenmo/splice/lib/components/SidePanel";

import { ILocationQueryParams } from "Views/Reimbursement/@types";
import { REIMBURSEMENT_PAGE_OPERATIONS, REIMBURSEMENT_QUERY_PARAMS } from "Views/Reimbursement/Constants";
import { REIMBURSEMENTS_PAGE_ANALYTICS, trackReimbursementAnalytics } from "Views/Reimbursement/Analytics";

import useExportCSV from "Views/Reimbursement/hooks/useExportCsv";

import ExportReimbursements from "./Container";

const ExportSidepanel = () => {
  const history = useHistory();
  const location = useLocation();

  const query: ILocationQueryParams = qs.parse(location.search, {
    parseNumbers: true,
    parseBooleans: true,
  });

  const { expenseStartDate, expenseEndDate, exportType = "" } = query;

  const { exportCSV } = useExportCSV();

  const [exporting, setExporting] = useState<boolean>(false);

  const handleOnClose = () => {
    history.push({
      pathname: location.pathname.replace(`/${REIMBURSEMENT_PAGE_OPERATIONS.EXPORT}`, ""),
      search: qs.stringify({
        ...query,
        [REIMBURSEMENT_QUERY_PARAMS.EXPENSE_START_DATE]: undefined,
        [REIMBURSEMENT_QUERY_PARAMS.EXPENSE_END_DATE]: undefined,
        [REIMBURSEMENT_QUERY_PARAMS.EXPORT_TYPE]: undefined,
      }),
    });
  };

  const downloadData: TActionButton = {
    title: "Download",
    variant: "primary",
    disabled: isNaN(expenseStartDate) || isNaN(expenseEndDate) || !exportType,
    action: () => {
      setExporting(true);
      trackReimbursementAnalytics({
        eventName: REIMBURSEMENTS_PAGE_ANALYTICS.COMPANY_REIMBURSEMENT_EXPORT_SIDEPANEL,
        eventSource: "Download Button",
        eventSubSource: "Clicked",
      });

      exportCSV()
        .then(() => handleOnClose())
        .finally(() => setExporting(false));
    },
    loading: exporting,
  };

  const props: ISidepanelProps = {
    showSidepanel: true,
    position: "right",
    transitionType: "rightToLeft",
    title: `Export`,
    footerAlignment: "end",
    footerStacking: "horizontal",
    onClose: handleOnClose,
    secondaryActionButton: downloadData,
    children: <ExportReimbursements />,
  };

  return <Sidepanel {...props} />;
};

export default ExportSidepanel;
