import { Popover } from "antd";
import React, { useContext } from "react";
import { TagsDropdownContext } from "../context";
import TagGroup from "../TagInput/TagGroup";
import TagOption from "../TagInput/TagOption";

import "./index.scss";

const TagOptionWrapper = () => {
  const { openSubMenu, subMenuPosition } = useContext(TagsDropdownContext);
  return (
    <Popover
      visible={openSubMenu}
      overlayClassName="tag-options-wrapper"
      content={TagOption}
      data-testid="tag-options-wrapper"
      placement={subMenuPosition}
    >
      <TagGroup />
    </Popover>
  );
};

export default TagOptionWrapper;
