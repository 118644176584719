import { ITrxnCategory } from "Views/Transaction/@types";
import { formatDate } from "utility/DateUtilites";
import { ITransactionFilter } from "../TransactionFilter";

/**
 * There's a difference between the filter key on the reporting service and monlith,
 *  so we have to normalize the key and value before sending the data to monolith
 * since the download feature still using the monolith data.
 */
export const normalizeFilter = (
  filter: Partial<ITransactionFilter>,
  categoriesData: ITrxnCategory[]
): Partial<ITransactionFilter> => {
  const newFilter = {} as ITransactionFilter;

  const mapping = {
    recipient_names: "merchant",
    types: "type",
    categories: "category_id",
    attachment: "has_attachment",
    team_ids: "team_id",
    requester_ids: "user_id",
    accounting_search: "startsWith",
  };

  const missingFieldMap = {
    notes: "missing_notes",
    tags: "missing_tags",
    accounting_code: "missing_account_code",
  };

  const ignoredKey = ["accounting_statuses", "exclude_type"];

  Object.entries(filter).forEach(([key, value]) => {
    if (mapping[key]) {
      newFilter[mapping[key]] = value;
    }

    switch (key) {
      case "start_date":
      case "end_date":
        newFilter[key] = formatDate(String(value));
        break;
      case "missing_fields":
        if (Array.isArray(value)) {
          value.forEach((missingField) => {
            newFilter[missingFieldMap[missingField]] = true;
          });
        }
        break;
      case "categories":
        if (Array.isArray(value)) {
          const categoriesId = value.map((categoryName) =>
            categoriesData.find((category) => category.category_name === categoryName).id.toString()
          );
          newFilter["category_id"] = categoriesId;
        }
        break;
      default:
        if (!mapping[key] && !ignoredKey.includes(key)) {
          newFilter[key] = value;
        }
    }
  });

  return newFilter;
};

const deepCheck = (newFilter, oldFilter) => {
  for (const key in newFilter) {
    if (newFilter[key] !== oldFilter[key]) {
      return false;
    }
  }
  return true;
};

export const isEqual = (prevProps, newProps) => {
  if (
    prevProps?.allTransactionIds?.[0] === "Mocked_transaction_0" &&
    newProps?.allTransactionIds?.[0] !== "Mocked_transaction_0"
  ) {
    return false;
  }
  if (newProps.allTransactionIds.length !== prevProps.allTransactionIds.length) {
    return false;
  }
  if (newProps.accountingStatus !== prevProps.accountingStatus) {
    return false;
  }

  if (Object.keys(newProps.filter).length !== Object.keys(prevProps.filter).length) {
    return false;
  }

  return deepCheck(newProps.filter, prevProps.filter);
};
