import {
  FETCH_LOW_BALANCE,
  FETCH_LOW_BALANCE_FAILURE,
  FETCH_LOW_BALANCE_SUCCESS,
} from "../Actions/lowBalanceReminderAction";

const INITIAL_STATE = {
  loading: true,
  data: {
    enabled: false,
    amount: "",
  },
};

export const lowBalanceReminderReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_LOW_BALANCE:
      return {
        ...state,
        loading: action.loadingState,
      };
    case FETCH_LOW_BALANCE_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
      };
    case FETCH_LOW_BALANCE_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
