import * as React from "react";
import { Button, Modal } from "antd";

import "./SaveVendorModal.scss";

interface SaveVendorModalProps {
  isEdit?: boolean;
  recipientName: string;
  visible: boolean;
  setVisible: (visible: boolean) => void;
  action?: () => void;
  cancel?: () => void;
}

const SaveVendorModal = ({ isEdit, recipientName, visible, setVisible, action, cancel }: SaveVendorModalProps) => {
  const handleAction = () => {
    setVisible(false);
    action?.();
  };

  const handleCancel = () => {
    setVisible(false);
    cancel?.();
  };

  const message = isEdit
    ? `You have made some changes to ${recipientName}'s payment details. Would you like to save your changes?
  This will not affect any payment that has been previously submitted.`
    : `Would you like to save  ${recipientName}'s payment details for future usage? This recipient's details can be retrieved by anyone in your company who has access to Bill Payments.`;

  return (
    <Modal className="save-vendor-modal" width={350} onCancel={() => setVisible(false)} visible={visible} footer={null}>
      <h1 className="save-vendor-modal__title">{`${isEdit ? "Update" : "Save"} Recipient Details`}</h1>
      <div className="save-vendor-modal__content">
        <p className="desc">{message}</p>
        <div className="save-vendor-modal__footer">
          <Button className="cancel" onClick={handleCancel}>
            Skip and Submit
          </Button>
          <Button className="action" onClick={handleAction}>
            Save and Submit
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default SaveVendorModal;
