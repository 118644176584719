import React from "react";
import PropTypes from "prop-types";
import { Base64 } from "js-base64";
import TableRowField from "Modules/tableRowField";
import { accountNumMask } from "utility";
import LoaderIcon from "Views/State/Loading/LoaderIcon";
import Button from "Modules/button";

import { connect } from "react-redux";
import { delBankAccFunc } from "Redux/Actions";

import "./BankDetails.scss";
import { trackEvent } from "utility/analytics";

class BankDetails extends React.Component {
  static propTypes = {
    bankData: PropTypes.object,
    loading: PropTypes.bool,
    actionData: PropTypes.object,
    handleUpdate: PropTypes.func,
    deleteAccount: PropTypes.func,
  };

  componentDidUpdate(prevProps) {
    const { actionData, handleUpdate } = this.props;
    if (actionData !== prevProps.actionData) {
      actionData && actionData.status === 200 && handleUpdate();
    }
  }

  componentDidMount() {
    trackEvent("bank account viewed");
  }

  onAccountDelete = () => {
    const { bankData, deleteAccount } = this.props;
    deleteAccount(bankData.id);
    trackEvent("bank account delete account clicked");
  };

  render() {
    const { bankData, loading, isAllowedDeleteAccount } = this.props;

    return loading ? (
      <LoaderIcon />
    ) : (
      <div className={"bank-details"}>
        <div className={"bank-details__header"}>
          <h3>{bankData.bank_name}</h3>
          {bankData.branch_code && (
            <p>{bankData.type === "paynow" ? bankData.branch_code : Base64.decode(bankData.branch_code)}</p>
          )}
        </div>

        <TableRowField title={"Account name"} details={bankData.account_name} />
        <TableRowField
          title={"Account number"}
          details={
            bankData.type === "paynow" ? bankData.mobile_number : accountNumMask(Base64.decode(bankData.account_number))
          }
        />
        <TableRowField
          title={"Currency"}
          details={bankData.currency_code === "SGD" ? "SGD - Singapore Dollars" : bankData.currency_code}
        />

        {isAllowedDeleteAccount && (
          <div className={"bank-details__action-btns"}>
            <Button text={"Delete"} rounded action={this.onAccountDelete} />
          </div>
        )}
      </div>
    );
  }
}

export default connect(
  (state) => ({
    loading: state.bankReducer.loading,
    actionData: state.bankReducer.data,
  }),
  {
    deleteAccount: delBankAccFunc,
  }
)(BankDetails);
