import React from "react";
import Button from "Modules/button";
import { LOADER_TYPES } from "Modules/DS/Loader/types";
import { MyInfoBtn } from "assets/img/kyb";
import Loader from "Modules/DS/Loader";
import Icon from "Modules/icons";

import styles from "./index.module.scss";

interface IButton {
  loading: boolean;
  action: () => void | Promise<void>;
}

const ILoader = <Loader type={LOADER_TYPES.WHITE} />;

export const ResumeMyInfoButton = ({ loading, action }: IButton) => {
  return (
    <Button className={styles.myInfoBtn} action={action} rounded>
      {loading ? ILoader : <Icon src={MyInfoBtn} />}
    </Button>
  );
};

export const ResumeManualButton = ({ loading, action }: IButton) => {
  return (
    <Button className={styles.manualBtn} action={action} rounded>
      {loading ? ILoader : "Upload Company Documents Manually"}
    </Button>
  );
};
