import React, { useState, useEffect, useMemo, useCallback, useRef } from "react";
import moment from "moment";
import axios, { CancelTokenSource } from "axios";
import { Tooltip, TooltipArrowAlignmentEnum, TooltipPositionEnum } from "Modules/DS/Tooltip";

import { BillFeeParameters, FeeBreakdownProcess, FeeId, getCorridorType } from "Redux/DataCalls/Disbursement.api";
import { useSelector } from "react-redux";
import { RootState } from "Redux/ConfigureStore";
import { getBillFee, BillFeeResponse, getConversionRateV2 } from "Redux/DataCalls/Disbursement.api";

import { ExchangeRateObject } from "Modules/TwoWayInput";
import CountrySelect from "Modules/CountrySelect";
import ExchangeInput from "Modules/ExchangeInput";

import { BILL_PAY_PROCESSING_TIME_ARTICLE_URL, DEFAULT_SEND_AMOUNT } from "../const";
import { debounce } from "utility";
import { newInfo, newTab } from "assets/img";
import styles from "./SendMoneyCalculator.module.scss";
import exchangeCalculatorStyles from "Views/UploadInvoice/InvoiceForm/ExchangeCalculator/ExchageCalculator.module.scss";
import { CorridorTypes } from "Views/UploadInvoice/InvoiceForm/ExchangeCalculator/types";
import { feeValueFormatter } from "Views/UploadInvoice/helper";

interface SendMoneyCalculatorProps {
  countryList: Array<{
    countryId: number;
    countryCode: string;
    countryName: string;
  }>;
  currencyList: Array<{
    currency_code: string;
  }>;
  receiverCountryCode: string;
  receiverCurrency: string;
  receiverAmount: number;
  senderCurrency: string;
  senderAmount: number;
  onReceiverCountryCodeChange: (string) => void;
  onReceiverCurrencyChange: (string) => void;
  onReceiverAmountChange: (string) => void;
  onSenderAmountChange: (string) => void;
  onSenderCurrencyChange: (string) => void;
}
let timer;
const DEBOUNCE_DELAY = 300;

const SendMoneyCalculator = ({
  countryList,
  currencyList,
  receiverCountryCode,
  receiverCurrency,
  receiverAmount,
  senderCurrency,
  senderAmount,
  onReceiverCountryCodeChange,
  onReceiverCurrencyChange,
  onReceiverAmountChange,
  onSenderAmountChange,
}: SendMoneyCalculatorProps) => {
  const [fxRate, setFxRate] = useState<ExchangeRateObject>({ amount: 1, updatedAt: "" });
  const [fxRateLoading, setFxRateLoading] = useState<boolean>(false);
  const [billFee, setBillFee] = useState<Partial<BillFeeResponse>>({
    totalFeeAmount: 0,
  });
  const [billFeeLoading, setBillFeeLoading] = useState<boolean>(false);
  const [corridorType, setCorridorType] = useState(null);
  const cancelTokenRef = useRef<CancelTokenSource>(null);

  const [orgDetail] = useSelector((state: RootState) => [state.b2bOrgDetailReducer]);
  const orgCountryCode = orgDetail?.data?.payload?.countryCode;

  // IMPROVEMENT: should not use the enum from other Views component
  const isInternationalGlobal = corridorType === CorridorTypes.internationalGlobal;
  const isDomestic = useMemo(
    () =>
      receiverCurrency &&
      senderCurrency &&
      receiverCurrency === senderCurrency &&
      receiverCurrency?.substring(0, 2) === receiverCountryCode,
    [receiverCountryCode, receiverCurrency, senderCurrency]
  );
  // IMPROVEMENT: validation that should be from the BE
  // Changing this means you need to change ExchangeCalculator Component too
  const internationalGlobalUSD = receiverCurrency === "USD" && isInternationalGlobal;

  const getBillFeeFunc = (params) => {
    clearTimeout(timer);
    timer = setTimeout(async () => {
      setBillFeeLoading(true);
      try {
        const billFeeData = await getBillFee(params);
        setBillFee(billFeeData?.data?.payload);
        setBillFeeLoading(false);
      } catch {
        setBillFee({
          totalFeeAmount: 0,
        });
        setBillFeeLoading(false);
      }
    }, DEBOUNCE_DELAY);
  };

  // IMPROVEMENT: could use useSWR for fetching the Bill Fee
  // Changing this means you need to change ExchangeCalculator Component too
  const fetchBillFee = (params: BillFeeParameters) => {
    setBillFeeLoading(true);
    if (cancelTokenRef.current) {
      cancelTokenRef.current.cancel("Cancelling duplicate api call");
    }
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    cancelTokenRef.current = source;

    getBillFee(
      {
        receiverCountryCode,
        receiverCurrency,
        senderCurrency,
        swiftPaymentChargeType: "SHA",
        ...params,
      },
      "v2",
      {
        cancelToken: source.token,
      }
    )
      .then((res) => {
        const billFee = res?.data?.payload;
        setBillFee(billFee);

        if (params?.receiverAmount) {
          onSenderAmountChange(String(billFee?.totalAmount));
        } else {
          onReceiverAmountChange(String(billFee?.receiverAmount));
        }
      })
      .catch(console.error)
      .finally(() => {
        setBillFeeLoading(false);
      });
  };

  const getExchangeRate = async (from, to) => {
    setFxRateLoading(true);
    const conversionRate = await getConversionRateV2(from, to);
    setFxRate({
      amount: conversionRate?.data?.payload?.buyingRate?.toFixed(8),
      updatedAt: moment(conversionRate?.data?.payload?.updated_at).format("DD MMM YYYY [at] hh:mm:ss"),
    });
    setFxRateLoading(false);
  };

  const getCorridorTypeFunc = async () => {
    const getCorridorTypeData = await getCorridorType(
      orgCountryCode,
      senderCurrency,
      receiverCurrency,
      receiverCountryCode
    );
    setCorridorType(getCorridorTypeData?.data?.payload?.corridorType);
  };

  const refetchBillFeeData = useCallback(
    debounce((billFeeParams) => {
      if (!isDomestic) {
        getExchangeRate(receiverCurrency, senderCurrency);
      }
      if (billFeeParams.receiverAmount || billFeeParams.totalAmount) {
        fetchBillFee({
          ...billFeeParams,
        });
      } else {
        /**
         * set billFee state to show free Fee transfer which is not
         * the best practice since it should come from the BE.
         */
        setBillFee({
          totalFeeAmount: 0,
        });

        if (billFeeParams.hasOwnProperty("receiverAmount")) {
          onSenderAmountChange?.("");
        } else {
          onReceiverAmountChange?.("");
        }
      }
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isDomestic, receiverCountryCode, receiverCurrency, senderCurrency]
  );

  const handleChangeReceiverAmount = (amount: string) => {
    onReceiverAmountChange?.(amount);

    refetchBillFeeData({
      receiverAmount: amount,
    });
  };

  const handleChangeSenderAmount = (amount: string) => {
    onSenderAmountChange?.(amount);

    refetchBillFeeData({
      totalAmount: amount,
    });
  };

  useEffect(() => {
    if (receiverCountryCode && receiverCurrency && senderCurrency) {
      refetchBillFeeData({
        receiverAmount,
      });
    }
  }, [receiverCountryCode, receiverCurrency, senderCurrency, refetchBillFeeData]);

  useEffect(() => {
    if (receiverCountryCode && receiverCurrency && senderCurrency) {
      getCorridorTypeFunc();
    }
  }, [receiverCountryCode, receiverCurrency, receiverAmount, senderCurrency]);

  const breakdownProcess = useMemo(() => {
    if (!Array.isArray(billFee?.breakdown)) {
      return [];
    }

    const additionalProps = (process: FeeBreakdownProcess) => {
      const { id, note, value, type, tooltip } = process;
      const numberValue = feeValueFormatter(type, value, senderCurrency);
      let props;
      switch (id) {
        case FeeId.EXCHANGE: {
          const updatedAt = moment(note).format("DD MMM YYYY [at] HH:mm:ss");
          props = {
            detail: (
              <div>
                <p>{numberValue}</p>
                <p className={exchangeCalculatorStyles.fxNote}>Mid-market rate until {updatedAt}</p>
              </div>
            ),
          };
          break;
        }
        default: {
          // for FeeId.TRANSFER_FEE
          props = {
            detail: note ? (
              <Tooltip text={note} alignArrow={TooltipArrowAlignmentEnum.LEFT} position={TooltipPositionEnum.RIGHT}>
                <p className={exchangeCalculatorStyles.tdUnderline}>{value === 0 ? "Free" : numberValue}</p>
              </Tooltip>
            ) : (
              numberValue
            ),
            isLoading: billFeeLoading,
          };
        }
      }

      return {
        labelTooltip: tooltip,
        ...props,
      };
    };

    return billFee.breakdown.map((process) => {
      const { label, operator, value } = process;

      return {
        label,
        operator,
        value,
        ...additionalProps(process),
      };
    });
  }, [billFee?.breakdown, billFeeLoading, senderCurrency]);

  return (
    <div className={styles.sendMoneyCalc} data-testid="sendMoneyCalculator">
      <CountrySelect
        label="Recipient Bank Country"
        value={receiverCountryCode}
        onChange={onReceiverCountryCodeChange}
        countryList={countryList}
      />

      <ExchangeInput
        receiverAmount={String(receiverAmount || "")}
        receiverAmountLabel={
          internationalGlobalUSD && (
            <div className={styles.receiverLabel}>
              <img src={newInfo} alt="tooltip info icon" width={16} height={16} className={styles.infoIcon} />
              Intermediary fees may apply
            </div>
          )
        }
        receiverCountry={receiverCountryCode}
        senderAmount={String(senderAmount || "")}
        receiverCurrencyCode={receiverCurrency}
        senderCurrencyCode={senderCurrency}
        exchangeProcess={breakdownProcess}
        onChangeReceiverAmount={handleChangeReceiverAmount}
        onChangeSenderAmount={handleChangeSenderAmount}
        onChangeReceiverCurrency={onReceiverCurrencyChange}
        disabledReceiverCountrySelection={false}
      />
      <div className={styles.faq}>
        <p>Payment processing time varies for each payment</p>
        <a
          className={styles.faqLink}
          href={BILL_PAY_PROCESSING_TIME_ARTICLE_URL}
          target="_blank"
          rel="noopener noreferrer"
        >
          See FAQ for estimated payment processing time
          <img src={newTab} alt="new tab icon" width={16} height={16} />
        </a>
      </div>
    </div>
  );
};

export default SendMoneyCalculator;
