import React from "react";
import classNames from "classnames";
import { UploadFile } from "antd/lib/upload/interface";

import { IFileUploaderContent } from "Views/KYX/@types";
import PDFViewer from "Modules/PDFViewer/PDFViewer";

import InitialState from "./InitialState";
import { AddButton, ChangeFileDetails, DeleteBtn, PreviewBtn, ProgressLoader } from "./buttons";

import styles from "./index.module.scss";

const MULTIPLE_IMAGE_MAX_LIMIT = 8;

const FileUploaderContent: React.FC<IFileUploaderContent> = ({
  fileInfo,
  onRemove,
  progress = 0,
  onPreview,
  multipleFiles = [],
  multiple = false,
  disabled = false,
  onChangeFile,
}: IFileUploaderContent) => {
  const { status } = (multiple ? multipleFiles?.[0] : fileInfo) || {};

  const handleFilesList = (): JSX.Element[] => {
    const fileList: UploadFile[] = multiple ? multipleFiles : [fileInfo];
    const _classNames = classNames(styles.content, { [styles.multiple]: multiple });

    return fileList?.map((file) => (
      <div className={_classNames} key={file?.uid}>
        <div className={styles.imagePreviewWrapper}>
          {file?.type === "application/pdf" ? (
            <PDFViewer showArrows={false} className={styles.pdfPreview} file={file?.originFileObj || file?.thumbUrl} />
          ) : (
            <img className={styles.previewImage} src={file?.thumbUrl} alt={"document"} />
          )}

          {status === "done" && <DeleteBtn file={file} action={onRemove} />}
          {status === "done" && <PreviewBtn className={styles.previewBtn} file={file} action={onPreview} />}
          {status === "uploading" && <ProgressLoader className={styles.progressBar} progress={progress} />}
        </div>

        <p className={styles.fileName}>{file?.name}</p>
        <ChangeFileDetails file={file} onChangeFile={onChangeFile} />
      </div>
    ));
  };

  const getFileContent = () => {
    if (status === "done" || status === "uploading") {
      return (
        <>
          {multipleFiles?.length < MULTIPLE_IMAGE_MAX_LIMIT && multiple && <AddButton />}
          {handleFilesList()}
        </>
      );
    }

    return <InitialState disabled={disabled} />;
  };

  return <>{getFileContent()}</>;
};

export default FileUploaderContent;
