import * as React from "react";
import CardReviewModal from "Views/Card/Review/Modal";
import { CardRequestDetailsType, CardReviewRequestEditableFieldsEnum } from "Views/Card/Review/types";
import { getCookieValue, cookieNames } from "utility/CookieHelper";
import { CARD_LIMIT_TYPES } from "Views/Card/types";
import CardReviewEditForm from "Views/Card/Review/EditForm";
import CardReviewActionBtns from "Views/Card/Review/ActionBtns";

import "Views/Card/Review/index.scss";
interface CardReviewProps {
  cardRequestDetails: CardRequestDetailsType;
  disableApproveBtn: boolean;
  onClose: () => void;
  onApprove: (payload) => void;
  onDecline: () => void;
  approvalInProgress: boolean;
}

const CardRequestReview = ({
  cardRequestDetails,
  disableApproveBtn,
  onClose,
  onDecline,
  onApprove,
  approvalInProgress,
}: CardReviewProps) => {
  const [cardDetails, setCardDetails] = React.useState(cardRequestDetails);
  const currencyCode = getCookieValue(cookieNames.CURRENCY_CODE);
  const getReadOnlyData = (headerText: string, data: string) => {
    return data ? (
      <>
        <p className="review__read-only-data-header">{headerText}</p>
        <p className="review__read-only-data">{data}</p>
      </>
    ) : null;
  };
  const changeCardDetails = (
    keyToBeUpdate: CardReviewRequestEditableFieldsEnum,
    value: string | number | string[] | CARD_LIMIT_TYPES
  ) => {
    setCardDetails((prevState) => ({
      ...prevState,
      [keyToBeUpdate]: value,
    }));
  };

  const checkIfFormIsVaild = () => {
    const { cardTitle, cardLimitType, cardLimit } = cardDetails;
    return !!(cardTitle && (cardLimitType === CARD_LIMIT_TYPES.unlimited || parseInt(cardLimit)));
  };

  return (
    <CardReviewModal onClose={onClose} width={730} title="Review Card Request">
      <>
        <div className="review__read-only-data-container">
          {getReadOnlyData("Requested by", cardRequestDetails.requestBy)}
          {getReadOnlyData("Team", cardRequestDetails.team)}
          {getReadOnlyData("Shipped to", cardRequestDetails.address)}
          {getReadOnlyData("Member note", cardRequestDetails.memberNote)}
        </div>
        <div className="review__edit-section">
          <div className="review__edit-section-container">
            <CardReviewEditForm
              cardDetails={cardDetails}
              changeCardDetails={changeCardDetails}
              currencyCode={currencyCode}
            />
          </div>
          <CardReviewActionBtns
            cardDetails={cardDetails}
            onApprove={onApprove}
            onDecline={onDecline}
            disableApproveBtn={disableApproveBtn || !checkIfFormIsVaild()}
            approvalInProgress={approvalInProgress}
          />
        </div>
      </>
    </CardReviewModal>
  );
};

export default CardRequestReview;
