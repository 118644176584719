import { FETCH_CARD_NUMBER_ACTIONS, ICardNumberData } from "Redux/Actions/Card/Number";
import { InitialState } from "Redux/Reducers/Card/Utilites/index";

interface ICardNumberReducerAction {
  type: FETCH_CARD_NUMBER_ACTIONS;
  data: ICardNumberData | {};
}

interface ICardNumberStateType {
  loading: boolean;
  error: boolean;
  data: ICardNumberData | {};
}

export const cardNumberReducer = (state: ICardNumberStateType = InitialState, action: ICardNumberReducerAction) => {
  switch (action.type) {
    case FETCH_CARD_NUMBER_ACTIONS.FETCH_CARD_NUMBER_REQUEST:
      return {
        ...state,
        data: action?.data,
        loading: true,
        error: false,
      };
    case FETCH_CARD_NUMBER_ACTIONS.FETCH_CARD_NUMBER_SUCCESS:
      return {
        ...state,
        data: action?.data,
        error: false,
        loading: false,
      };
    case FETCH_CARD_NUMBER_ACTIONS.FETCH_CARD_NUMBER_FAILURE:
      return {
        ...state,
        loading: false,
        data: action?.data,
        error: true,
      };
    default:
      return state;
  }
};
