import React, { useEffect } from "react";
import qs from "query-string";
import { useHistory } from "react-router-dom";

import { COMMON_PATHS } from "Route/Common/paths";

import { PRODUCT_NAME } from "Redux/ModularProduct";

import useCheckOrgConfigs from "customHooks/useCheckOrgConfigs";

import { appNotification } from "Modules/appNotification/appNotification";

import AddReimbursement from "Views/Reimbursement/AddReimbursement";
import { REIMBURSEMENT_QUERY_PARAMS, REIMBURSEMENT_VIEWS } from "Views/Reimbursement/Constants";
import { REIMBURSEMENTS_PAGE_ANALYTICS, trackReimbursementAnalytics } from "Views/Reimbursement/Analytics";

const NewReimbursement = () => {
  const history = useHistory();

  const [isCrConfigEnabled, loadingCrConfig] = useCheckOrgConfigs(PRODUCT_NAME.CASH_REIMBURSEMENT, true) as [
    boolean,
    boolean
  ];

  // Redirection Logic
  useEffect(() => {
    if (!loadingCrConfig && !isCrConfigEnabled) {
      history.replace(COMMON_PATHS.PAGE_NOT_FOUND);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingCrConfig]);

  useEffect(() => {
    trackReimbursementAnalytics({
      eventName: REIMBURSEMENTS_PAGE_ANALYTICS.NEW_REIMBURSEMENT,
      eventSource: "Page",
      eventSubSource: "Loaded",
    });
  }, []);

  const handleOnToggle = () => {
    return history.action === "PUSH"
      ? history.goBack()
      : history.push({
          pathname: REIMBURSEMENT_VIEWS.PERSONAL_REIMBURSEMENTS.link,
          search: qs.stringify({
            [REIMBURSEMENT_QUERY_PARAMS.PAGE]: 1,
          }),
        });
  };

  return (
    <AddReimbursement
      toggleAddReimbursement={handleOnToggle}
      isNewCR={true}
      loading={loadingCrConfig}
      submitReimbursement={() => {
        history.replace({
          pathname: REIMBURSEMENT_VIEWS.PERSONAL_REIMBURSEMENTS.link,
          search: qs.stringify({
            [REIMBURSEMENT_QUERY_PARAMS.PAGE]: 1,
          }),
        });
        appNotification.success({ message: "Reimbursement request successfully submitted!" });
      }}
    />
  );
};

export default NewReimbursement;
