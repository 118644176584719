import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./overlayLoader.scss";

const OverlayLoader = (props) => {
  const { delay, delayTime, show } = props;
  const [showLoader, setShowLoader] = useState(show || false);

  useEffect(() => {
    if (delay) {
      setShowLoader(true);
      setTimeout(() => {
        setShowLoader(false);
      }, delayTime || 1000);
    }
  }, [delay, delayTime]);

  if (showLoader) {
    return (
      <div className="overlay-loader" title="7">
        <svg
          className={"overlay-loader__wrapper"}
          version="1.1"
          id="Layer_1"
          x="0px"
          y="0px"
          width="24px"
          height="30px"
          viewBox="0 0 24 30"
          xmlSpace="preserve"
        >
          <rect className={"line-1"} x="0" y="10" width="4" height="10" fill="#F65731" opacity="0.2">
            <animate
              attributeName="opacity"
              attributeType="XML"
              values="0.2; 1; .2"
              begin="0s"
              dur="0.6s"
              repeatCount="indefinite"
            />
            <animate
              attributeName="height"
              attributeType="XML"
              values="10; 20; 10"
              begin="0s"
              dur="0.6s"
              repeatCount="indefinite"
            />
            <animate
              attributeName="y"
              attributeType="XML"
              values="10; 5; 10"
              begin="0s"
              dur="0.6s"
              repeatCount="indefinite"
            />
          </rect>
          <rect className={"line-2"} x="8" y="10" width="4" height="10" fill="#F65731" opacity="0.2">
            <animate
              attributeName="opacity"
              attributeType="XML"
              values="0.2; 1; .2"
              begin="0.15s"
              dur="0.6s"
              repeatCount="indefinite"
            />
            <animate
              attributeName="height"
              attributeType="XML"
              values="10; 20; 10"
              begin="0.15s"
              dur="0.6s"
              repeatCount="indefinite"
            />
            <animate
              attributeName="y"
              attributeType="XML"
              values="10; 5; 10"
              begin="0.15s"
              dur="0.6s"
              repeatCount="indefinite"
            />
          </rect>
          <rect className={"line-3"} x="16" y="10" width="4" height="10" fill="#F65731" opacity="0.2">
            <animate
              attributeName="opacity"
              attributeType="XML"
              values="0.2; 1; .2"
              begin="0.3s"
              dur="0.6s"
              repeatCount="indefinite"
            />
            <animate
              attributeName="height"
              attributeType="XML"
              values="10; 20; 10"
              begin="0.3s"
              dur="0.6s"
              repeatCount="indefinite"
            />
            <animate
              attributeName="y"
              attributeType="XML"
              values="10; 5; 10"
              begin="0.3s"
              dur="0.6s"
              repeatCount="indefinite"
            />
          </rect>
        </svg>
      </div>
    );
  }

  return null;
};

OverlayLoader.propTypes = {
  delay: PropTypes.bool,
  delayTime: PropTypes.number,
  show: PropTypes.bool,
};

export default OverlayLoader;
