import React from "react";
import { Skeleton } from "antd";

import styles from "../index.module.scss";
import classNames from "classnames";

const QuickLinksShimmer = (isModular = false) => {
  const _classNames = classNames(styles.card, { [styles.modularCard]: isModular });

  return (
    <>
      {Array.from({ length: 4 })?.map((key, index) => (
        <div data-testid="quicklink-card-shimmer" key={index} className={_classNames}>
          <Skeleton.Avatar className={styles.avatar} active shape={"square"} />
          <Skeleton.Input className={styles.paragraph} active />
          <Skeleton.Input className={styles.paragraph} active />
        </div>
      ))}
    </>
  );
};

export default QuickLinksShimmer;
