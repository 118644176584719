import React from "react";

import { More, Quick } from "Views/Transaction/Filters";
import { ITrxnTypeOption } from "Views/Transaction/@types";
import { ALL_TRXNS_COLUMNS } from "Views/Transaction/Constants";

import { getTrxnTypeOptions } from "./Options";

export const TrxnTypeQuickFilter = ({ id }: { id: string }) => {
  return (
    <Quick.Multiple<ITrxnTypeOption> id={id} displayKey="title" defaultValue={ALL_TRXNS_COLUMNS.TYPE}>
      {getTrxnTypeOptions()}
    </Quick.Multiple>
  );
};

export const TrxnTypeMoreFilter = ({ id }: { id: string }) => {
  return (
    <More.Multiple<ITrxnTypeOption> id={id} displayKey="title" label={`Transaction ${ALL_TRXNS_COLUMNS.TYPE}`}>
      {getTrxnTypeOptions()}
    </More.Multiple>
  );
};
