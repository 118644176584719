import React from "react";
import { Typography } from "@spenmo/splice";
import { SubMenu, Container } from "Views/Settings/components";
import { companyMenu } from "Views/Settings/config";

import styles from "Views/Settings/styles.module.scss";

const Company = ({ permissions }) => {
  return (
    <section className={styles.body}>
      <Typography tag="p" size="m" variant="body-content" className={styles.caption}>
        Make organization wide changes with integrations or policies.
      </Typography>
      <section className={styles.content}>
        <SubMenu menu={companyMenu.filter((item) => permissions[item.enabled])} />
        <Container />
      </section>
    </section>
  );
};

export default Company;
