import React from "react";
import Loader from "Modules/DS/Loader";
import { LOADER_SIZES, LOADER_TYPES } from "Modules/DS/Loader/types";
import styles from "./index.module.scss";

export const RedirectionLoader: React.FC = (): React.ReactElement => {
  return (
    <div className={styles.wrapper}>
      <Loader type={LOADER_TYPES.BRAND} size={LOADER_SIZES.BIG} />
      <h4 className={styles.title}>Redirecting you back to Spenmo...</h4>
      <p className={styles.message}>Please do not close this page</p>
    </div>
  );
};
