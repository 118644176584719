import React from "react";
import Button from "Modules/button";
import Icon from "Modules/icons";
import { Delete } from "assets/v1.1/icons/Core/Delete";
import styles from "./index.module.scss";

interface Props {
  onClick: () => void;
}

const DeleteButton: React.FC<Props> = ({ onClick }: Props): React.ReactElement => {
  return (
    <Button type="button" action={onClick} textButton className={styles.button}>
      <Icon className={styles.icon} src={Delete.Fill.red_16} />
      <span>Delete Personnel</span>
    </Button>
  );
};

export default DeleteButton;
