import React, { useMemo, useRef, useState } from "react";
import dayjs from "dayjs";
import classNames from "classnames";
import { ChevronDown, CalendarOutline } from "@spenmo/splice";

import { IDateInput } from "Modules/RecurringDate/types";

import styles from "./styles.module.scss";

const DateInput = ({ show, date, onClickHandler }: IDateInput) => {
  const [inputOutline, setInputOutline] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);

  const InputIcon = show ? CalendarOutline : ChevronDown;

  const inputValue = useMemo(() => `${dayjs().month(0).date(date).format("Do")} of every month`, [date]);

  return (
    <div
      role="none"
      className={styles.container}
      onClick={(e) => {
        show ? inputRef.current.blur() : inputRef.current.focus();

        onClickHandler(e);
      }}
    >
      <input
        id="date-picker"
        className={classNames(styles.input, {
          [styles.hover]: inputOutline,
        })}
        type="text"
        ref={inputRef}
        value={inputValue}
        onChange={() => null}
      />
      <InputIcon
        size="24"
        className={styles.icon}
        onMouseEnter={() => setInputOutline((prev) => !prev)}
        onMouseLeave={() => setInputOutline((prev) => !prev)}
        iconColor={`var(${show ? "--fill-system-info-strong" : "--icon-default"})`}
      />
    </div>
  );
};

export default DateInput;
