import React from "react";
import dayjs from "dayjs";
import ReKYBUpdateModal from "./Update";
import ReKYBRejectedModal from "Views/KYX/ReKYB/Modal/Rejected";
import { isBefore } from "utility/DateUtilites";
import { KYB_STATUS, REKYB_UPDATE_MODAL_TYPE } from "Views/KYX/Constants";

interface ReKYBModalProps {
  setShowReKYBModal: (boolean) => void;
  dueDate: string;
  kyb_status: KYB_STATUS;
  isAdmin: boolean;
  isOrgBlocked: boolean;
}

const ReKYBModal = ({
  setShowReKYBModal,
  dueDate,
  kyb_status,
  isAdmin,
  isOrgBlocked,
}: ReKYBModalProps): React.ReactElement => {
  const dueDateObject = dayjs(dueDate);
  const currentDate = dayjs();
  const isPastDue = isBefore(dueDateObject, currentDate, "day");
  const isUpdateStatus =
    [KYB_STATUS.NOT_STARTED, KYB_STATUS.STARTED, KYB_STATUS.ADDITIONAL_DOCUMENTS_REQUIRED].includes(kyb_status) &&
    isOrgBlocked;

  const props = {
    dueDate: dueDate,
    setShowReKYBModal: setShowReKYBModal,
    isAdmin: isAdmin,
  };

  switch (true) {
    case isUpdateStatus && isAdmin:
      return <ReKYBUpdateModal {...props} type={REKYB_UPDATE_MODAL_TYPE.AFTER_EXPIRY} />;
    case isUpdateStatus && !isAdmin:
      return <ReKYBUpdateModal {...props} type={REKYB_UPDATE_MODAL_TYPE.NON_ADMIN} />;
    case kyb_status === KYB_STATUS.REJECTED && (isPastDue || !isPastDue):
      return <ReKYBRejectedModal setShowReKYBModal={setShowReKYBModal} />;
    default:
      return null;
  }
};

export default ReKYBModal;
