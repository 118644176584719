import { IKYXToastContext } from "Views/KYX/@types";
import { KYXToastContext } from "Views/KYX/Context";
import {
  IPostMyInfoBusinessAuthoriseResponse,
  postMyInfoBusinessAuthorise,
} from "Views/KYX/DataCalls/MyInfoBusiness.api";
import { errorHandler } from "Views/KYX/Helper/errorHandler";
import { useContext, useState } from "react";

export const usePostMyInfoBusinessAuthorise = () => {
  const [loading, setLoading] = useState(false);
  const { setToaster } = useContext<IKYXToastContext>(KYXToastContext);
  const myInfoBusinessAuthorise = (): Promise<IPostMyInfoBusinessAuthoriseResponse> => {
    return new Promise((resolve, reject) => {
      setLoading(true);
      postMyInfoBusinessAuthorise()
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          const toast = errorHandler(err);
          setToaster(toast);
          reject(err);
        })
        .finally(() => {
          setLoading(false);
        });
    });
  };
  return { loading, myInfoBusinessAuthorise };
};
