import React from "react";

import Icon, { IconList } from "Modules/Icon";
import DocumentIcon from "Modules/icons";
import { LabelChip } from "Modules/DS/Chip/Label";
import { IDocument, IDocumentsList, IDocumentsWithButtonList } from "Views/KYX/@types";
import classNames from "classnames";

import styles from "./index.module.scss";

export const Document = ({ item }: { item: IDocument }) => {
  const iconDetails = item?.icon;

  return (
    <div key={`document-${item.document}`}>
      {item.label && <p className={styles.label}>{item.label}</p>}
      <div className={styles.documentList}>
        <Icon
          svgAttr={iconDetails ? iconDetails?.svgAttr : { width: 16, height: 20, viewBox: "0 0 16 20" }}
          className={styles.icon}
          icon={IconList[iconDetails?.name || "file"]}
        />
        <p className={styles.documentName}>{item.document}</p>
      </div>
    </div>
  );
};

export const DocumentWithButton = ({
  document,
  button,
  className,
}: {
  document: IDocumentsWithButtonList;
  button: JSX.Element;
  className?: string;
}) => {
  return (
    <div
      key={`document-type-${document.title}`}
      className={classNames(styles.card, styles.documentCard, { [styles[className]]: className })}
    >
      <div className={styles.iconDetails}>
        <DocumentIcon className={styles.documentIcon} src={document.icon} alt="document type" />
        {document?.label && <LabelChip title={document.label.title} status={document.label.status} />}
      </div>
      <h4 className={styles.docTitle}>{document.title}</h4>
      <p className={styles.info}>{document.info}</p>
      {document.list.map((item) => (
        <Document key={`document-type-${document.title}--${item.document}`} item={item} />
      ))}
      <div className={styles.button}>{button}</div>
    </div>
  );
};

const IntroDocument = ({ documents }: { documents: IDocumentsList[] }) => {
  const renderContent = (doc: IDocumentsList) => {
    return (
      <div key={`document-type-${doc.title}`} className={styles.card}>
        <h4 className={styles.docTitle}>{doc.title}</h4>
        <p className={styles.info}>{doc.info}</p>
        {doc.list.map((item) => (
          <Document key={`document-type-${doc.title}--${item.document}`} item={item} />
        ))}
      </div>
    );
  };

  return <>{documents?.map(renderContent)}</>;
};

export default IntroDocument;
