import React, { useState } from "react";
import moment from "moment";
import { Steps, Collapse } from "antd";
import Section from "Modules/TransactionDetail/Section";
import { chevronDown } from "assets/img";
import Icon from "Modules/icons";
import { exclamationBlack } from "assets/img/";

import styles from "./styles.module.scss";
import { IAuditTrailProps } from "../type";
import { Typography } from "@spenmo/splice";

const { Step } = Steps;
const { Panel } = Collapse;

const AuditTrail: React.FC<IAuditTrailProps> = ({ list, title, isEditable }) => {
  const [isCollapsedActive, setIsCollapsedActive] = useState(false);
  const findStepIndex = (state: string) => list?.findIndex((val) => val.state === state);
  const currentStepIndex = findStepIndex("active");
  const doneStepIndex = findStepIndex("done");
  const activeStep = currentStepIndex !== -1 ? currentStepIndex : doneStepIndex;
  const activeState = list?.[activeStep];
  const collapseExpandIcon = () => <img src={chevronDown} />;
  const customHeaderPanel = !isCollapsedActive && Boolean(activeState) && (
    <div className={styles.activeState}>
      <div className={styles.left}>
        <div className={styles.title}>
          <div className={styles.bigCircle}>&nbsp;</div>
          <div className={styles.stateLabel}>{activeState.label}</div>
        </div>
        {Boolean(activeState.executor) && <div className={styles.stateExecutor}>by {activeState.executor}</div>}
        <div className={styles.stateDesc}>{activeState.description}</div>
      </div>
      {Boolean(activeState.time) && (
        <div className={styles.time}>{moment(activeState.time).format("DD MMM YYYY, HH:mm")}</div>
      )}
    </div>
  );
  return (
    <Section>
      <div>
        <Typography size="m" variant="headline-brand" tag="h3" className={styles.title}>
          {title}
        </Typography>
        {Boolean(list?.length) && (
          <Collapse
            ghost
            accordion
            className="audit-trail-collapse"
            expandIcon={collapseExpandIcon}
            onChange={() => setIsCollapsedActive(!isCollapsedActive)}
          >
            <Panel header={customHeaderPanel} key="1" className="audit-trail-panel">
              <Steps progressDot current={activeStep} direction="vertical" className="ant-steps-audit-trail">
                {Boolean(list?.length) &&
                  React.Children.toArray(
                    list?.map((step) => (
                      <Step
                        title={step.label}
                        description={
                          <div className={styles.stepDesc}>
                            {Boolean(step.executor) && (
                              <div className={styles.executor}>
                                by {step.executor}
                                <div className={styles.stateDesc}>{step.description}</div>
                              </div>
                            )}
                            {Boolean(step.time) && (
                              <div className={styles.time}>{moment(step.time).format("DD MMM YYYY, HH:mm")}</div>
                            )}
                          </div>
                        }
                      />
                    ))
                  )}
              </Steps>
              {!isEditable && (
                <div className={styles.restricted}>
                  <span className={styles.label}>Editing Restricted</span>
                  <Icon src={exclamationBlack} alt="exclamation" />
                </div>
              )}
            </Panel>
          </Collapse>
        )}
      </div>
    </Section>
  );
};

export default AuditTrail;
