import FundTransferApprovalRequest from "Models/Approval/ApprovalCenter/fundTransferApprovalRequest.interface";
import { ESidePanelButtonType, SidePanel } from "Modules/DS/SidePanel";
import React, { useEffect, useState } from "react";
import { Typography, Select as AntdSelect } from "antd";
import classNames from "classnames";
import { currencyFormatter, dateTimeFormatter, isValidArray } from "utility";
import Select from "Views/Card/Components/Select";
import { allWalletFunc } from "Redux/Actions";

import styles from "./index.module.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  ApproveRequestFundTransferPayload,
  approveRequestFundTransfer,
} from "Redux/Reducers/Approval/ApprovalCenter/approvalCenter.slice";
const { Option } = AntdSelect;

interface IApprovalFundTransferDetails {
  record: FundTransferApprovalRequest;
  visible: boolean;
  setVisible?: (value: boolean) => void;
  onApprove?: (object: any) => Promise<object>;
  onDecline?: () => void;
}

export const walletTypeToDisplayName = (walletType: string) => {
  if (walletType === "team") return "(Budget)";
  else if (walletType === "user") return "(Personal Wallet)";
  return "-";
};

const ApprovalFundTransferSidePanel = ({ visible, record, setVisible, onDecline }: IApprovalFundTransferDetails) => {
  const { Text } = Typography;

  const [selectedSourceOfFund, setSelectedSourceOfFund] = useState(null as string);
  const [showDeclineButton, setShowDeclineButton] = useState(true as boolean);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(allWalletFunc());
  }, [dispatch]);

  const { wdata: walletData = [] } = useSelector((state: any) => state.wallet);

  useEffect(() => {
    if (isValidArray(walletData)) setSelectedSourceOfFund(walletData[0].id);
  }, [walletData]);

  const buttons = [
    {
      id: "SIDE-PANEL-BUTTON-001",
      type: ESidePanelButtonType.CONSTRUCTIVE_ENABLE,
      enabled: true,
      disabled: false,
      text: "Approve",
      loading: false,
      hiddenButtonBeforeConfirm: false,
      enableConfirmCallback: () => {
        setShowDeclineButton(false);
      },
      disabledConfirmCallback: () => {
        setShowDeclineButton(true);
      },
      action: () => {
        const requestType = "approve";
        setVisible(false);
        let payload: ApproveRequestFundTransferPayload = {
          values: { subwallet_id: selectedSourceOfFund },
          type: requestType,
          requestId: record.id,
        };
        dispatch(approveRequestFundTransfer(payload));
      },
    },
    {
      id: "SIDE-PANEL-BUTTON-002",
      type: ESidePanelButtonType.BORDERED_DESTRUCTIVE,
      enabled: showDeclineButton,
      disabled: false,
      text: "Decline",
      loading: false,
      hiddenButtonBeforeConfirm: false,
      action: () => {
        onDecline();
      },
    },
  ];

  return (
    <>
      <SidePanel
        visible={visible}
        title="Fund Transfer"
        onClose={() => setVisible(false)}
        buttons={buttons.filter((b) => Boolean(b.enabled))}
        sticky={true}
      >
        <Text className={classNames(styles.text, styles.small)}>Requester</Text>
        <Text className={classNames(styles.text, styles.big, styles.normalSpace)}>{record.user_name}</Text>

        <Text className={classNames(styles.text, styles.small)}>Amount</Text>
        <Text className={classNames(styles.text, styles.big, styles.normalSpace)}>
          {currencyFormatter(record.amount, record.currency_code)}
        </Text>

        <Text className={classNames(styles.text, styles.small)}>Request Date</Text>
        <Text className={classNames(styles.text, styles.small, styles.normalSpace)}>
          {dateTimeFormatter(record.created_at).date} {dateTimeFormatter(record.created_at).time}
        </Text>

        <Text className={classNames(styles.text, styles.small)}>Budget</Text>
        <Text className={classNames(styles.text, styles.small, styles.normalSpace)}>{record.team_name}</Text>

        <Text className={classNames(styles.text, styles.small)}>Reason</Text>
        <Text className={classNames(styles.text, styles.small, styles.normalSpace)}>{record.title}</Text>

        <Text className={classNames(styles.text, styles.small, styles.normalSpace)}>Source of Funds</Text>
        <Select
          placeholder="Select source of funds"
          key="source of funds"
          value={selectedSourceOfFund}
          onChange={(val) => setSelectedSourceOfFund(val)}
        >
          {isValidArray(walletData) &&
            walletData.map((wallet) => (
              <Option value={wallet.id} key={wallet.id}>
                <div className={styles.budgetItem}>
                  <div>
                    <p>{wallet.team_name}</p>
                    <span>{walletTypeToDisplayName(wallet.wallet_type)}</span>
                  </div>
                  <span>{currencyFormatter(wallet.amount, wallet.currency_code)}</span>
                </div>
              </Option>
            ))}
        </Select>
      </SidePanel>
    </>
  );
};

export default ApprovalFundTransferSidePanel;
