import React from "react";
import classNames from "classnames";

import useFocus from "utility/useFocus";

import { ISearchBar, DEBOUNCE_DELAY_TIME, useElasticSearch, SEARCH_CONSTANTS } from "Modules/DS/Search";

import styles from "./SearchBar.module.scss";

export const SearchBar = ({
  onSearch,
  clearSearch,
  placeholder,
  inputValue = "",
  debounceDelay = DEBOUNCE_DELAY_TIME.MAX,
}: ISearchBar): JSX.Element => {
  const [inputRef, setInputFocus] = useFocus();

  const { get, onSearchHandler, clearSearchHandler } = useElasticSearch(
    onSearch,
    clearSearch,
    inputValue,
    debounceDelay,
    DEBOUNCE_DELAY_TIME.ZERO
  );

  const onClose = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    get.value.length > 0 && clearSearchHandler();
  };

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => onSearchHandler(e.target.value);

  const renderCloseIcon = () => {
    return <button className={styles.close} onClick={onClose} />;
  };

  const renderInput = () => {
    return (
      <input
        type="search"
        ref={inputRef}
        value={get.value}
        onChange={onChangeInput}
        placeholder={placeholder}
        id={SEARCH_CONSTANTS.SEARCH_BAR}
        name={SEARCH_CONSTANTS.SEARCH_BAR}
      />
    );
  };

  const renderPrefixIcon = () => {
    return <div className={get.loading ? styles.loading : styles.search__icon} />;
  };

  const className = classNames(styles.search__bar, { [styles.active]: get.value.length });

  return (
    <div className={className} onClick={setInputFocus}>
      {renderPrefixIcon()}
      {renderInput()}
      {renderCloseIcon()}
    </div>
  );
};
