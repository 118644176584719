import React from "react";
import { ALIGN_ACTION_ITEM, IActionItem } from "Modules/DS/DataTable";

import { CardsTrxnsFilters } from "Views/Transaction/Cards/Actions/Filters";

const Actions = (): IActionItem[] => {
  return [
    {
      id: "CARDS-TRXNS-ACTION-1",
      enabled: true,
      align: ALIGN_ACTION_ITEM.LEFT,
      component: CardsTrxnsFilters,
    },
    {
      id: "CARDS-TRXSN-ACTION-2",
      enabled: false,
      align: ALIGN_ACTION_ITEM.RIGHT,
      component: <button>Download</button>,
    },
  ].filter((item) => item.enabled);
};

export default Actions;
