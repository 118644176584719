import React, { useContext } from "react";

import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";

import { DEBOUNCE_DELAY_TIME } from "Modules/DS/Search";
import { TOASTER_STATUS_TYPE } from "Modules/DS/Toaster";

import {
  ITrxnFilterContext,
  ITrxnToastContext,
  IGetTrxnResponse,
  ITrxnDetailsExpenseData,
} from "Views/Transaction/@types";
import { getReceiptDetails } from "Views/Transaction/DataCalls";
import { DEFAULT_ERROR_MESSAGE, TRANSACTION_TYPES } from "Views/Transaction/Constants";
import { useSaveTrxnDetails } from "Views/Transaction/Details/useFetch";
import { TrxnFilterContext, TrxnToastContext } from "Views/Transaction/Context";

import { processFilesData } from "./processFilesData";

export const useUploadData = () => {
  const { fetchData } = useSaveTrxnDetails();

  const { onApplyFilter, filter }: ITrxnFilterContext = useContext(TrxnFilterContext);
  const { setToaster }: ITrxnToastContext = useContext<ITrxnToastContext>(TrxnToastContext);

  const uploadData = async (
    uploadedFiles: FileList,
    record: IGetTrxnResponse,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    setLoading(true);

    let paths: string[] = [];
    let data: Partial<ITrxnDetailsExpenseData>;

    try {
      paths = await processFilesData(uploadedFiles);
    } catch (error) {
      setLoading(false);
      return setToaster({
        show: true,
        type: TOASTER_STATUS_TYPE.ERROR,
        message: error.message || DEFAULT_ERROR_MESSAGE,
      });
    }

    try {
      const response = await getReceiptDetails(record.id, record.transaction_number);

      if (response.status > HTTP_STATUS_CODE.OK || response.data.status > HTTP_STATUS_CODE.OK) {
        throw new Error(response.data.payload.status_message);
      }

      data = {
        tax: response?.data?.payload?.expense?.tax?.id || "",
        category: response?.data?.payload?.expense?.expense_category_id,
      };
    } catch (error) {
      setLoading(false);
      return setToaster({
        show: true,
        type: TOASTER_STATUS_TYPE.ERROR,
        message: error.message || DEFAULT_ERROR_MESSAGE,
      });
    }

    try {
      const payload = {
        photo_urls: paths,
        tax_id: data.tax,
        expense_category_id: parseInt(data.category),
        notes: undefined,
      };

      if (record.notes) {
        payload.notes = record.notes.trim();
      }

      if (record.transaction_type === TRANSACTION_TYPES.CARD_REFUND) {
        payload["transaction_id"] = record.id;
      }

      await fetchData(record.transaction_number, payload);

      setTimeout(() => {
        setLoading(false);
        setToaster({
          show: true,
          message: `${paths.length} attachments/receipts successfully saved.`,
          type: TOASTER_STATUS_TYPE.SUCCESS,
        });
        onApplyFilter(filter);
      }, DEBOUNCE_DELAY_TIME.MAX * 3);
    } catch (error) {
      setLoading(false);
      return setToaster({
        show: true,
        type: TOASTER_STATUS_TYPE.ERROR,
        message: error.message || DEFAULT_ERROR_MESSAGE,
      });
    }
  };

  return uploadData;
};
