export const transactionType = [
  {
    id: "card_expense",
    title: "Card Expense",
  },
  {
    id: "billpay_expense",
    title: "Bill Pay Expense",
  },
  {
    id: "cash_reimbursement",
    title: "Reimbursements",
  },
  {
    id: "Topup",
    title: "Topup",
  },
  {
    id: "Incentive",
    title: "Incentive",
  },
  {
    id: "fees",
    title: "Fees",
  },
  {
    id: "Others",
    title: "Others",
  },
];
