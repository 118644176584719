import React, { ReactNode } from "react";
import { Cookies } from "react-cookie";
import {
  cashReimburseIcon,
  creditcardIcon,
  fundingIcon,
  saasIcon,
  spendMoneyIcon,
} from "../assets/img";
import moment from "moment";
import currencyCodes from "./currency.json";
import { useLocation } from "react-router-dom";
import { saveAs } from "file-saver";
import { USER_ROLE } from "constants/Team.constant";
import { ICurrencyParts } from "Modules/DS/Input";
import { getCookieValue, cookieNames } from "utility/CookieHelper";
import { CARD_USER_VENDOR } from "Views/Card/types";

export const userLocale =
  navigator?.languages && navigator?.languages?.length
    ? navigator.languages[0]
    : navigator?.language || "sg-SG";

export const searchText = (val, data) => {
  if (val === "") {
    return data;
  } else {
    const searchedData = data.map(
      (item) => item.name.toLowerCase().includes(val.toLowerCase()) && item,
    );
    return searchedData.filter((data) => data !== false);
  }
};
export const searchInTeamList = (val, data) => {
  if (val === "") {
    return data;
  } else {
    const searchedData = data.map((item) =>
      item.team_name
        ? item.team_name.toLowerCase().includes(val.toLowerCase()) && item
        : item.name.toLowerCase().includes(val.toLowerCase()) && item,
    );
    return searchedData.filter((data) => data !== false);
  }
};

export const removeDataObject = (data, dataItem) => {
  return data.filter((item) => {
    return item.id !== dataItem.id;
  });
};

export const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export const getWalletAmount = (data, teamId) => {
  return data.filter((data) => {
    return data.id === teamId;
  });
};

export const getFirstIndex = (name) => {
  return name?.charAt(0);
};

export const setCookies = (name, data = "") => {
  const cookies = new Cookies();
  const expiryTime = new Date(Date.now() + 60 * 10 * 1000);

  cookies.set(name, data, {
    secure: true,
    path: "/",
    expires: expiryTime,
    maxAge: 600,
    sameSite: true,
  });
};

export const getCookies = (name) => {
  const cookies = new Cookies();
  return cookies.get(name);
};

export const clearCookies = (name) => {
  const cookies = new Cookies();
  cookies.remove(name);
};

export const isOrgPaymentModeCredit = () => {
  const cookies = new Cookies();
  const isUseCredit = cookies.get("is_use_credit");
  //cookies.get return string
  const isOrgUsingCreditModel = isUseCredit === "true" ? true : false;
  return isOrgUsingCreditModel;
};

export const dateTimeFormatter = (date) => {
  let formattedDate = "";
  if (date?.includes?.("Z")) {
    formattedDate = date;
  } else {
    const splittedDate = date?.split?.(" ");
    formattedDate = `${splittedDate[0]}T${splittedDate[1]}Z`;
  }
  let requestDate = new Date(formattedDate);
  const timeStamp = requestDate.toLocaleString("en-SG", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
    // TODO : implement timezone for all places.
    // timeZone: "Asia/Singapore",
  });
  const dateStamp = requestDate.toLocaleString("en-SG", {
    month: "short",
    day: "2-digit",
    year: "numeric",
  });
  const monthStamp = requestDate.toLocaleString("en-SG", {
    month: "short",
    day: "2-digit",
  });
  return {
    time: timeStamp,
    date: dateStamp,
    month: monthStamp,
  };
};

export const getCurrencyLocale = (currencyCode) => {
  const currency = currencyCodes.filter(
    (currency) => currency.currency_code === currencyCode,
  );
  return currency.length ? currency[0].locale : "en-SG";
};

export const currencyFormatter = (
  value,
  currencyCode,
  isNumberFormat = false,
) => {
  const amount = currencyCode === "IDR" ? Math.floor(value) : value;

  if (currencyCode) {
    if (!isNumberFormat) {
      const value = new Intl.NumberFormat(userLocale, {
        currency: currencyCode,
      }).format(amount);
      return `${currencyCode} ${value}`;
    }
    return new Intl.NumberFormat(userLocale).format(amount);
  }
  return amount;
};

export const currencyFormatterV2 = (
  value,
  currency,
  showCurrency = true,
  toFixed: number = undefined,
) => {
  const amount = currency === "IDR" ? Math.floor(value) : value;
  if (currency) {
    const decimalDigit = getCurrencyParts(currency).decimalCount || 0;
    const minimumFractionDigits = Math.min(
      value?.split?.(".")?.[1]?.length ||
        (toFixed && currency === "SGD" ? toFixed : 0),
      decimalDigit,
    );

    const val = new Intl.NumberFormat(userLocale, {
      currency,
      style: "currency",
      currencyDisplay: "code",
      minimumFractionDigits,
    })
      .format(amount)
      .replace(currency, "")
      .trim();
    if (showCurrency) {
      return `${currency} ${val}`;
    } else {
      return val;
    }
  }
  return amount;
};

export const getCurrencyParts = (currency: string): ICurrencyParts => {
  const parts = Intl.NumberFormat(userLocale, {
    style: "currency",
    currency,
  }).formatToParts(111111.1111111);
  return parts.reduce((acc, curval) => {
    switch (curval.type) {
      case "decimal":
        return { ...acc, decimalSeparator: curval.value };
      case "group":
        return { ...acc, thousandSeparator: curval.value };
      case "fraction":
        return {
          ...acc,
          decimalCount: currency === "IDR" ? 0 : curval.value.length,
        };
      default:
        return acc;
    }
  }, {});
};

export const currencyParser = (stringNumber, currency) => {
  const currencyParts = getCurrencyParts(currency);
  const decimalSeparatorIndex = stringNumber.indexOf(
    currencyParts.decimalSeparator,
  );
  const lastIndex =
    decimalSeparatorIndex > -1
      ? decimalSeparatorIndex + currencyParts.decimalCount + 1
      : stringNumber.length;
  return stringNumber
    .substring(0, lastIndex)
    .replace(new RegExp("\\" + currencyParts.thousandSeparator, "g"), "")
    .replace(new RegExp("\\" + currencyParts.decimalSeparator), ".")
    .replace(/[^0-9|^.]+/g, "");
};

export const currencyFormatterWithFractions = (
  value,
  currencyCode,
  decimalPlaces,
  isNumberFormat = false,
) => {
  const amount = currencyCode === "IDR" ? Math.floor(value) : value;
  if (currencyCode) {
    const totalFractions = currencyCode === "IDR" ? 0 : decimalPlaces;
    const formattedAmount = new Intl.NumberFormat(userLocale, {
      minimumFractionDigits: totalFractions,
      maximumFractionDigits: totalFractions,
    }).format(amount);
    return isNumberFormat
      ? formattedAmount
      : `${currencyCode} ${formattedAmount}`;
  }
  return value;
};

export const millisecondFormatter = (timestamp) => {
  return moment(timestamp * 1000).format("YYYY-MM-DD HH:MM:SS");
};

export const roundValue = (value) => {
  return Math.round(value * 100) / 100;
};

export const isActive = (arr, activeItem) => {
  return arr.includes(activeItem);
};

export const checkEmptyObject = (obj) => {
  for (let key in obj) {
    if (obj[key] && obj[key] !== null && obj[key] !== "") return false;
  }
  return true;
};

export const getUrlBuilder = (
  startDate,
  endDate,
  status,
  url = window.__ENV__.API_BASE_URL,
  page = 1,
  limit = 10,
) => {
  let builtUrl = url;
  if (page || limit) {
    builtUrl = `${builtUrl}?pg=${page}&limit=${limit}`;
    if (status) {
      builtUrl = `${builtUrl}&status=${status}`;
    }
  }
  if (startDate || endDate) {
    builtUrl = `${builtUrl}?start_date=${startDate}&end_date=${endDate}`;
  }
  return builtUrl;
};

export const getRowIndex = (list, selectedRow) => {
  if (selectedRow) {
    return list.findIndex((row) => row.id === selectedRow.id);
  }
  return 0;
};

export const getPendingList = (list) => {
  if (list && list.length > 0) {
    return list.filter((row) => row.status === "pending");
  }
  return [];
};

export const getListPendingRow = (list, index) => {
  if (list && list.length > 0) {
    const pendingRows = getPendingList(list);
    return pendingRows[index];
  }
  return null;
};

export const postUrlBuilder = (
  params,
  startDate,
  endDate,
  url = window.__ENV__.API_BASE_URL,
  page = 1,
  limit = 10,
) => {
  let built = {
    url: url,
    params: params,
  };
  if (page || limit) {
    built.params = {
      ...params,
      pg: page,
      limi: limit,
    };
  }
  if (startDate || endDate) {
    built.params = {
      ...params,
      start_date: startDate,
      end_date: endDate,
    };
  }
  return built;
};

export const GetUserId = () => {
  return GetBaseAuthObject().userId;
};

export const GetOrgId = () => {
  return GetBaseAuthObject().orgId;
};

export const GetOrgCountryCode = () => {
  return GetBaseAuthObject().orgCountryCode;
};

export const GetCurrencyCode = () => {
  const cookies = new Cookies();
  return cookies.get(cookieNames.CURRENCY_CODE);
};

export const GetBaseAuthObject = () => {
  const cookies = new Cookies();
  const authString = cookies.get("reauth");
  const reAuth = (authString || "").split("vspenmo1");
  return {
    orgId: reAuth[0],
    userId: reAuth[1],
    orgCountryCode: reAuth[2],
  };
};

export const FilterById = (id, data = []) => {
  return data.filter((data) => {
    return data.id === id;
  });
};

export const getIntervalField = (data) => {
  if (data["monthly_limit"]?.toString()) {
    return "monthly_limit";
  } else if (data["yearly_limit"]?.toString()) {
    return "yearly_limit";
  } else if (data["total_limit"]?.toString()) {
    return "total_limit";
  } else {
    return "none";
  }
};

export const accountNumMask = (num) => {
  return num.replace(/\d(?=\d{4})/g, "*");
};

export const removeDuplicatesEmployees = (data) => {
  return data.reduce(
    (r, item) => r.push({ ...item, role: "Member", title: "" }) && r,
    [],
  );
};

export const navigationData = [
  {
    title: "View Requests",
    icon: fundingIcon,
    link: { pathname: "/approvals", state: { showAddReimburse: false } },
    trackOnClick: true,
  },
  {
    title: "Cash Reimbursement",
    icon: cashReimburseIcon,
    link: { pathname: "/approvals", state: { showAddReimburse: true } },
    trackOnClick: true,
  },
  {
    title: "Manage Teams",
    icon: saasIcon,
    link: { pathname: "/teams" },
    trackOnClick: false,
  },
  {
    title: "View Transactions",
    icon: creditcardIcon,
    link: { pathname: "/transactions" },
    trackOnClick: true,
  },
  {
    title: "Top Up Balance",
    icon: spendMoneyIcon,
    link: { pathname: "/topup" },
    trackOnClick: false,
  },
];

export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const getValuesByKey = (arr, key) => {
  return arr.map((elem) => elem[key]);
};

export const startDateOfMonth = () => {
  return startDateOfTheMonth().split("/").reverse().join("-");
};

export const startDateOfTheMonth = () => {
  let today = new Date();
  today.setDate(1);
  return today.toLocaleDateString("en-SG", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  });
};

export const dateFormat = () => {
  const dt = new Date();
  let dateStamp;
  dateStamp = dt.toLocaleString("en-SG", {
    month: "numeric",
    day: "numeric",
    year: "numeric",
  });
  return dateStamp;
};

export const getUrlParam = (url, key) => {
  const match = url.match("[?&]" + key + "=([^&]+)");
  return match ? match[1] : null;
};

export const reverseObject = (object) => {
  return Object.keys(object).reduce((acc, curval) => {
    return { ...acc, [object[curval]]: curval };
  }, {});
};

export const isSameArray = (arr1 = [], arr2 = []) => {
  if (arr1?.length !== arr2?.length) return false;
  const sortedArr1 = arr1.sort();
  const sortedArr2 = arr2.sort();
  let i = sortedArr1.length;
  while (i--) {
    if (sortedArr1[i] !== sortedArr2[i]) return false;
  }
  return true;
};

export const objectifyArray = (array, index = "id") =>
  array.reduce((acc, curval) => ({ ...acc, [curval[index]]: curval }), {});

export const generateDownloadFilename = (
  name: string,
  extension: string,
): string => {
  let dt = new Date();
  let formattedDate = `${dt.getFullYear()}_${dt.getMonth() + 1}_${dt.getDate()}_${dt.getTime()}`;
  return `${name}-${formattedDate}.${extension}`;
};

export const downloadCSV = (data, name, isCompleteFileName = false) => {
  let dt = new Date();
  let formattedDate = `${dt.getFullYear()}_${dt.getMonth() + 1}_${dt.getDate()}_${dt.getTime()}`;
  let filename = `${name}-${formattedDate}.csv`;
  if (isCompleteFileName) {
    filename = `${name}.csv`;
  }
  if (typeof data === "string") {
    const blob = new Blob([data], { type: "text/plain;charset=utf-8" });
    saveAs(blob, filename);
  }
};

export const downloadPDF = (data, name) => {
  let filename = generateDownloadFilename(name, "pdf");

  if (typeof data === "string") {
    let byteCharacters = atob(data);
    const byteNumbers = [];
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    let blob = new Blob([byteArray], { type: "application/pdf" });
    saveAs(blob, filename);
  }
};

export const downloadXLSX = (dataURL, name) => {
  const link = document.createElement("a");
  link.href = dataURL;
  link.download = name;
  link.click();
};

export const getUtcOffset = (date) => {
  const offset = moment().utcOffset() / 60;

  return `${offset >= 0 ? "+" : ""}${offset}`;
};

export const setMerchantName = (list) => {
  const listData = list?.length
    ? list?.map((merchant) => {
        return { merchant_name: merchant };
      })
    : [];
  return listData;
};

export const blobToFile = (file) => {
  const blob = file?.data;
  if (file?.headers) {
    const formattedFile = new File([blob], file?.headers["x-filename"], {
      type: file?.headers["content-type"],
    });
    formattedFile["status"] = "done";
    return formattedFile;
  }
  return null;
};

export const splitOnDots = (text) => {
  if (text) return text.split("...");
};

export const ValidateNumber = () => new RegExp(/^\d*$/);

export const getTimeDiffInSeconds = (time) => {
  if (time) {
    const EXPIRE_TIME = time;
    let PRESENT_UTC_TIME = new Date();
    PRESENT_UTC_TIME = new Date(PRESENT_UTC_TIME.toISOString().split("Z")[0]);

    return Math.floor(
      (new Date(EXPIRE_TIME).getTime() - new Date(PRESENT_UTC_TIME).getTime()) /
        1000,
    );
  }
};

export const validateFileType = (format, fileType) => {
  return format.includes(fileType);
};

export const quantifier = (number) => {
  return number > 1 ? "s" : "";
};

export const urltoFile = async (doc) => {
  const blob = await fetch(doc?.url).then((file) => file.blob());
  const formattedFile =
    blob?.type && new File([blob], doc?.filename, { type: doc?.type });
  formattedFile["status"] = "done";
  formattedFile["url"] = doc.url;
  return formattedFile;
};

export const removeDuplicates = (list, roleId) => {
  return list?.filter((item) => item.id !== roleId);
};

export const debounce = (func, timeout = 300) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};

export const throttle = (func, timeout = 300) => {
  let timer;
  return (...args) => {
    if (timer) {
      return;
    }

    timer = setTimeout(() => {
      func.apply(this, args);
      timer = null;
    }, timeout);
  };
};

const MonthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const getMonthName = (date, shortName = false) => {
  const month = (date && MonthNames?.[date?.getMonth()]) || "";
  return shortName ? month?.substring(0, 3) : month;
};

export const getParamsValue = (...args) => {
  if (window?.location?.search) {
    const paramsObject = new URLSearchParams(window.location.search);
    return args.map((param) => paramsObject.get(param));
  }
  return [];
};

export const getValuesFromObject = (object, result = []) => {
  for (const key in object) {
    if (typeof object[key] === "object") {
      getValuesFromObject(object[key], result);
    } else {
      result.push(object[key]);
    }
  }
  return result;
};

export const startDateOfTheYear = () => {
  return `01/01/${new Date().getFullYear()}`;
};

export const getTeamRole = (teamMembersData, userId, membership) => {
  const userTeamInfo =
    Array.isArray(teamMembersData) &&
    teamMembersData.find((teamMember) => teamMember?.user_id === userId);
  return userTeamInfo?.role || membership || "";
};

export const toNormalCase = (text) =>
  text
    ?.replace?.(/([A-Z])/g, " $1")
    .trim()
    .toLowerCase() || "";

export const capitalizeFirstLetter = (string) => {
  if (typeof string === "string") {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return "";
};

export const isBookkeeper = (companyRoleFromTeamDetails) => {
  const userCompanyRole = getCookies("user_company_role") || "";
  return (
    companyRoleFromTeamDetails === USER_ROLE.BOOKKEEPER ||
    userCompanyRole === USER_ROLE.BOOKKEEPER
  );
};
export const toOrdinal = (n) =>
  n + ["st", "nd", "rd"][((((n + 90) % 100) - 10) % 10) - 1] || "th";

export const ONE_AND_HALF_HOUR = 90 * 60 * 1000;

export const ONE_MINUTE = 1 * 60 * 1000;

export const oneAndHalfHourLater = () => {
  const expires = new Date();
  expires.setTime(expires.getTime() + ONE_AND_HALF_HOUR);
  return expires;
};

export const getMerchantNames = (list) => {
  let merchantList = [];
  // quick fix, it needs to be done from BE
  if (typeof list === "string") {
    merchantList = JSON.parse(list);
  } else if (typeof list === "object") {
    merchantList = list;
  }
  return (
    merchantList?.length &&
    merchantList?.map((merchant) => merchant.merchant_name)
  );
};
export const checkWhiteSpaceRegex = /^\s+$/;

export const intlDateTimeFormat = (date = new Date()) => {
  return new Intl.DateTimeFormat("en-SG", {
    day: "2-digit",
    month: "short",
    year: "numeric",
  }).format(date);
};

export const dateTimeWithTimezone = (originalDate) => {
  let formattedDate = "";
  if (originalDate?.includes?.("Z")) {
    formattedDate = originalDate;
  } else {
    const [date, time] = originalDate?.split?.(" ");
    formattedDate = `${date}T${time}Z`;
  }

  return formattedDate;
};

export const intlTimeFormat = (date = new Date()) => {
  // * as any here is intentional until we migrate to typescript version 4.2 or later
  return new Intl.DateTimeFormat("en-SG", {
    hour: "numeric",
    minute: "numeric",
    hourCycle: "h23",
  } as any).format(date);
};

export const isImage = (file) => {
  return (
    file.toLowerCase().indexOf(".jpeg") > 0 ||
    file.toLowerCase().indexOf(".jpg") > 0 ||
    file.toLowerCase().indexOf(".png") > 0 ||
    file.toLowerCase().indexOf(".svg") > 0
  );
};

export const convertBytestoMB = (bytes, roundValue) => {
  const converted = bytes / (1024 * 1024);
  return roundValue ? converted.toFixed(roundValue) : converted;
};

export const plural = (
  length,
  text,
  suffix = "s",
  showLength = true,
  intermediary = "",
) => {
  const count = showLength ? `${length} ` : "";
  const middleText = intermediary ? `${intermediary} ` : "";
  return `${count}${middleText}${text}${length > 1 ? suffix : ""}`;
};

// clearURLParams is used to clear params in URL and set URL (without reload)
export const clearURLParams = (paramArray: string[]) => {
  if (!Array.isArray(paramArray) || !paramArray.length) return;
  const params = new URLSearchParams(window.location.search);
  paramArray.forEach((param) => {
    if (params.get(param)) params.delete(param);
  });
  if (window.location.search !== `?${params.toString()}`) {
    window.history.replaceState(null, null, `?${params.toString()}`);
  }
};
export const getKYBCountry = () => {
  const cookies = new Cookies();
  return cookies.get("org_country_code");
};

// Checking if the dashboard currency is using the country's currency
export const validateDashboardCountryCurrency = (
  countriesCurrency: Record<string, String>,
  currency: string,
  countryCode: string,
): boolean => {
  return (
    countriesCurrency &&
    countriesCurrency[countryCode] &&
    countriesCurrency[countryCode] === currency
  );
};

export const fallbackString = (text, fallback = "") => {
  return text || fallback;
};

function escapeRegex(text) {
  return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

export const highlighter = (
  haystack: string,
  needle: string,
  highlight: (string) => ReactNode = (text) => <strong>{text}</strong>,
) => {
  const regex = new RegExp(`(${escapeRegex(needle)})`, "gi");
  return (
    <span>
      {needle
        ? haystack
            .split(regex)
            .map((item) =>
              item.toLowerCase() === needle ? highlight(item) : item,
            )
        : haystack}
    </span>
  );
};

export const isSet = (value) => {
  return value !== undefined && value !== null;
};

/* @isValidArray function is for check the props is a valid array and atleast have 1 element */
export const isValidArray = (arr: any) =>
  !!arr && Array.isArray(arr) && !!arr.length;

/* @multiArrayValidation function is for check the props are multiple array and each array of them should be valid array and atleast have 1 element */
export const multiArrayValidation = (...arr): boolean =>
  isValidArray(arr) && !arr.some((value) => !isValidArray(value));

export const flattenObj = (obj: Record<string, any>) => {
  const flattening = (
    obj: Record<string, any>,
    keyStr: string = "",
    flatObj: Record<string, any> = {},
  ) => {
    if (obj === null || typeof obj === "undefined") {
      return flatObj;
    }

    const arr = Object.keys(obj);
    for (let i = 0; i < arr.length; i++) {
      const key = arr[i];
      const value = obj[key];

      if (typeof value === "object") {
        flatObj = flattening(value, keyStr + key + ".", flatObj);
      } else {
        flatObj[keyStr + key] = value;
      }
    }

    return flatObj;
  };

  return flattening(obj);
};

export const removeEmptyProperty = (
  obj: Record<string, any>,
): Record<string, any> => {
  const res = {};

  for (let key in obj) {
    const val = obj[key];

    if (val !== undefined && val !== null && val !== "" && val !== 0) {
      res[key] = val;
    }
  }

  return res;
};

// nium or empty for limit model. Please see the link below for more info
// https://spenmo.atlassian.net/wiki/spaces/PRODUCT/pages/350421308/Draft+Modular+Product+Offering+-+Onboarding+Scope#General-Flow
export const getIsLimitModel = () => {
  return [CARD_USER_VENDOR.NIUM, ""].includes(
    getCookieValue(cookieNames.USER_CARD_VENDOR),
  );
};
