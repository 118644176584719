export const generateOptions = (list) =>
  list.map((item) => ({
    label: item,
    value: item,
    name: item,
  }));

export const isEmptyVal = (val) => {
  if (Array.isArray(val) || typeof val === "string") return !!val.length;
  if (typeof val === "object" && val !== null) {
    if (Object.keys(val).length === 0) return false;
    return Object.keys(val).some((keyVal) => isEmptyVal(val[keyVal]));
  }
  return val != null;
};
