import React from "react";
import { Skeleton } from "antd";

import { ISquareSkeletonProps } from "Modules/DS/Menu/@types";

import styles from "./Skeleton.module.scss";

export const SquareSkeleton = ({ loading, children }: ISquareSkeletonProps) => {
  return loading ? (
    <Skeleton.Avatar active={loading} shape="square" size="default" className={styles.shimmer} />
  ) : (
    children
  );
};
