export const EMPLOYEES_INITIAL_PAGE_NUMBER = 0;

export const INITIAL_ROLE_UPDATE_CALL_COUNT = 0;

export const EMPLOYEES_TABLE_PAGE_SIZE = 10;

export const DEBOUNCE_DELAY_TIME = 1000;

export const UPDATE_ROLE_PORTAL = "update_role_portal";

export const MULTIPLE_ERROR_COUNT_LIMIT = 2;

export const UPDATE_ROLE_HELP_MESSAGE = "Hi, I have issue on update my employee role. can anyone help me about it?";

/**
 * 1 - Admin
 * 4 - Accountant
 * 6 - Auditor
 * These are the only roles which are available without wallets
 */
export const NON_WALLET_ROLES_IDS = [1, 4, 6];
