import APIclient from "API/Client";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import { trackEvent } from "utility/analytics";
import { getTeamList } from "Redux/Actions";

export const CREATE_TEAM = "CREATE_TEAM";
export const CREATE_TEAM_REQUEST = `${CREATE_TEAM}_REQUEST`;
export const CREATE_TEAM_SUCCESS = `${CREATE_TEAM}_SUCCESS`;
export const CREATE_TEAM_FAILURE = `${CREATE_TEAM}_FAILURE`;
export const CREATE_TEAM_RESET = `${CREATE_TEAM}_RESET`;

export const createTeamRequest = (data) => {
  return {
    type: CREATE_TEAM_REQUEST,
    data: data,
  };
};

export const createTeamSuccess = (data) => {
  return {
    type: CREATE_TEAM_SUCCESS,
    createData: data,
  };
};

export const createTeamError = (data) => {
  return {
    type: CREATE_TEAM_FAILURE,
    data: data,
  };
};

export const resetCreateTeamData = () => {
  return {
    type: CREATE_TEAM_RESET,
  };
};

export const createTeam = (payload) => {
  return (dispatch) => {
    dispatch(createTeamRequest());
    APIclient.postData("ms/spm-organization/v1/mgmt/budgets", payload)
      .then((data) => {
        if (data.data.status > HTTP_STATUS_CODE.OK) {
          dispatch(createTeamError(data.data));
        } else {
          dispatch(createTeamSuccess(data.data));
          trackEvent("create team success");
        }
      })
      .then(() => dispatch(getTeamList()))
      .catch((err) => {
        dispatch(createTeamError(err));
      });
  };
};
