import { Form, Input } from "antd";
import { DynamicFormContext } from "Modules/DynamicForm/store";
import { DynamicFormFieldProps } from "Modules/DynamicForm/types";
import React, { useEffect, useRef, useState, useContext } from "react";
import { formItemProps } from "../utils/formItemProps";
import style from "./Textarea.module.scss";

const DynamicFormTextarea = (props: DynamicFormFieldProps) => {
  const { alias, fieldID, fieldProps, onChangeValue } = props;
  const [value, setValue] = useState("");
  const textareaRef = useRef<any>(null);

  const { trackView, trackEdit } = useContext(DynamicFormContext);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChangeValue?.(fieldID, e.currentTarget.value);
    setValue(e.currentTarget.value);
  };

  const handleFocus = () => {
    trackView(alias, value);
  };

  const handleBlur = (e) => {
    const value = e.target?.value?.trim();
    onChangeValue?.(fieldID, value);
    setValue(value);
    trackEdit(alias, value);
  };

  useEffect(() => {
    setValue(textareaRef.current?.state?.value || "");
  }, [textareaRef]);

  const renderLimitInfo = () => {
    const { maxChar } = fieldProps;
    if (maxChar) {
      return (
        <div className={style.limitInfo}>
          {maxChar - value.length} characters left
        </div>
      );
    }
    return null;
  };

  return (
    <>
      <Form.Item
        {...formItemProps(props)}
        normalize={(value) => value?.replace(/^\s+/, "")}
      >
        <Input.TextArea
          placeholder={fieldProps.placeholder}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          disabled={!fieldProps.editable}
          maxLength={fieldProps.maxChar}
          rows={4}
          ref={textareaRef}
        />
      </Form.Item>
      {renderLimitInfo()}
    </>
  );
};

export default DynamicFormTextarea;
