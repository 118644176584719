import React, { useMemo } from "react";

import {
  CARD_TXN_STATUS,
  ICardTrxnDetailsSuccessData,
  IGetCardsTrxnsData,
  ITrxnDetailsSaveAction,
} from "Views/Transaction/@types";
import { TRANSACTION_TYPES } from "Views/Transaction/Constants";
import { Details } from "Views/Transaction/Details/Cards/Details";
import { IDetail, EBannerPosition, EInputType } from "Modules/TransactionDetail";
import { getRecordDetailsDate } from "Views/Transaction/Helper";
import { isFxTransaction } from "Views/Transaction/Cards/SidePanel/TrxnAmount/Utility";
import { REVERSED_TRXNS_STATUS, REFUNDED_TRXNS_STATUS } from "Views/Transaction/Constants/Cards";
import TrxnAmount from "Views/Transaction/Cards/SidePanel/TrxnAmount";
import TrxnStatus from "Views/Transaction/Cards/SidePanel/TrxnStatus";
import { Typography } from "@spenmo/splice";
import { capitalizeFirstLetter } from "utility";

import styles from "./index.module.scss";

export interface ISidePanelProps extends ITrxnDetailsSaveAction {
  trxnDetails: ICardTrxnDetailsSuccessData;
  record: IGetCardsTrxnsData;
}

const SidePanel = ({ trxnDetails, record, handleSaveRef, closeDetails }: ISidePanelProps) => {
  const {
    receipts: {
      expense: {
        comment,
        expense_category_id,
        tax: { id: taxId },
        receipts,
        tags,
      },
      transaction: { transaction_number, transaction_id },
    },
  } = trxnDetails;

  const {
    cardholder_name,
    team_name,
    created_at,
    card_name,
    card_last_four,
    settled_date,
    txn_status,
    fx_type,
    fx_rate,
  } = record || {};

  const isReveresedTrxn = REVERSED_TRXNS_STATUS.includes(txn_status);
  const isRefundedTrxn = REFUNDED_TRXNS_STATUS.includes(txn_status);
  const isFxTrxn = isFxTransaction(fx_type || "");

  const getSettledDateAndTimeText = () => {
    if (txn_status === CARD_TXN_STATUS.PENDING) {
      return (
        <Typography size="s" variant="body-content" tag="p" className={styles.textDisabled}>
          Transaction not yet settled
        </Typography>
      );
    }
    return settled_date === "NA" ? settled_date : getRecordDetailsDate(settled_date || "");
  };

  const getSettledDateAndTimeLabel = () => {
    let prefix = "Settled";
    if (isReveresedTrxn) {
      prefix = "Reversed";
    } else if (isRefundedTrxn) {
      prefix = "Refunded";
    }
    return `${prefix} Date & Time`;
  };

  const details: IDetail[] = [
    {
      id: "cardholder",
      key: "Cardholder",
      value: capitalizeFirstLetter(cardholder_name),
      subtitle: capitalizeFirstLetter(team_name),
    },
    { id: "trxnNumber", key: "Transaction Number", value: transaction_number },
    { id: "createdAt", key: "Created Date & Time", value: getRecordDetailsDate(created_at || "") },
    { id: "SettledDateAndTime", key: getSettledDateAndTimeLabel(), value: getSettledDateAndTimeText() },
    { id: "transactionType", key: "Transaction Type", value: "Cards" },
    { id: "cardTitle", key: "Card Title", value: card_name },
    { id: "cardNumber", key: "Card Number", value: `****${card_last_four}` },
  ];

  if (isFxTrxn) {
    details.push({ id: "fxRate", key: "FX Rate", value: fx_rate });
  }

  const visibleInput = {
    [EInputType.TAX]: true,
    [EInputType.NOTES]: true,
    [EInputType.RECEIPT]: !(isRefundedTrxn || isReveresedTrxn),
    [EInputType.CATEGORY]: true,
    [EInputType.TRANSACTION_TAGS]: true,
  };

  const anyEmptyFields = useMemo(() => {
    return !(
      comment?.trim() &&
      taxId &&
      expense_category_id &&
      (receipts?.length || isRefundedTrxn || isReveresedTrxn)
    );
  }, [comment, taxId, expense_category_id, receipts, isRefundedTrxn, isReveresedTrxn]);

  return (
    <>
      <TrxnAmount record={record} transactionNumber={transaction_number} />
      <TrxnStatus trxnLifecyle={trxnDetails?.life_cycle} trxnStatus={record?.txn_status} />
      <Details
        notes={comment}
        details={details}
        handleSaveRef={handleSaveRef}
        trxnType={TRANSACTION_TYPES.CARD_EXPENSE}
        trxnNumber={transaction_number}
        receipts={receipts.map((receipt) => receipt?.url)}
        categoryId={expense_category_id}
        closeDetails={closeDetails}
        taxId={taxId}
        tags={tags}
        trxnId={transaction_id}
        expenseReceipts={receipts}
        bannerPosition={anyEmptyFields ? EBannerPosition.TOP : null}
        visibleInput={visibleInput}
      />
    </>
  );
};

export default SidePanel;
