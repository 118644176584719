import APIclient from "API/Client";
import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import { GetOrgId } from "utility";

export const FETCH_ROLE_REQUEST = "FETCH_ROLE_REQUEST";
export const FETCH_ROLE_SUCCESS = "FETCH_ROLE_SUCCESS";
export const FETCH_ROLE_ERROR = "FETCH_ROLE_ERROR";

export const fetchDataRequest = (data) => {
  return {
    type: "FETCH_ROLE_REQUEST",
    data: data,
  };
};

export const fetchDataSuccess = (data) => {
  return {
    type: "FETCH_ROLE_SUCCESS",
    data: data.payload,
  };
};

export const fetchDataError = (data) => {
  return {
    type: "FETCH_ROLE_ERROR",
    data: data,
  };
};

export const getEmployeeRoles = (type = null) => {
  const roleType = type ? `?type=${type}&` : "?";
  return (dispatch) => {
    dispatch(fetchDataRequest());

    APIclient.getData(`/ms/spm-organization/v1/roles${roleType}orgID=${GetOrgId()}`)
      .then((data) => {
        if (data.data.status === HTTP_STATUS_CODE.OK) {
          dispatch(fetchDataSuccess(data.data));
        } else {
          dispatch(fetchDataError(data.data));
        }
      })
      .catch((err) => {
        dispatch(fetchDataError(err));
      });
  };
};
