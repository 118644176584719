import {
  BILL_STATUS_ENUM,
  CYCLE_STATUS_ENUM,
  PAYMENT_RUN_SORT_BY,
} from "Views/Bills/V2/PaymentRun/constant";

export enum PAYMENT_CYCLE_STATE {
  UNSCHEDULED = "UNSCHEDULED",
  ADHOC = "AD-HOC",
  UPCOMING = "UPCOMING",
  PAST = "PAST",
  ACTIVE = "ACTIVE",
}
export interface ICycleStatus {
  key: CYCLE_STATUS_ENUM;
  label: string;
}
export interface IPaymentCycleData {
  id: number;
  state: PAYMENT_CYCLE_STATE;
  name: string;
  status: ICycleStatus;
  statusDetails: Array<{ status: ICycleStatus; count: number }>;
  payout?: {
    billIDs: string[];
    totalAmount: number;
  };
  ref?: React.MutableRefObject<HTMLDivElement | null>;
  isActive: boolean;
  date: string;
}
export interface IPaymentCycleResponse {
  payload: {
    passed: IPaymentCycleData[];
    upcoming: IPaymentCycleData[];
  };
}
export interface IPaymentPeriod {
  id: number;
  name: string;
  eligibleToPaid: boolean;
}

export interface IPaymentPeriodResponse {
  payload: IPaymentPeriod[];
  status: number;
}

export interface IBillsResponse {
  payload: {
    list: IBillItem[];
  };
  pagination: {
    currentPage: number;
    totalPage: number;
    totalRow: number;
  };
}

export interface IAssignToCycleResponse {
  payload: {
    isSuccess: boolean;
    message: string;
  };
}

export interface IAssignPreviewResponse {
  payload: {
    isLatePayment: boolean;
  };
}

export interface IValidateSelectedBillsResponse {
  payload: {
    isEligible: boolean;
    overlimitBillsCount: number;
    overlimitBudgetNames: string[];
  };
}
export interface ISelectable {
  isSelected: boolean;
}
export type BillItemStatus = {
  key: BILL_STATUS_ENUM;
  label: string;
};
export interface IBillItem {
  id: string;
  attachmentURLs: string[];
  beneficiaryName: string;
  billNumber: string;
  dueDate: string;
  currency: string;
  paidAmount: number;
  status: BillItemStatus;
  isOverDue: boolean;
  selectable: ISelectable;
}
export type SortIdentifier = (typeof PAYMENT_RUN_SORT_BY)[number]["id"];

type OverlimitBillsCount = {
  type: "overlimitBillsCount";
  data: number;
};

type OverlimitBudgetNames = {
  type: "overlimitBudgetNames";
  data: string[];
};
export type PaySelectedErrorType = OverlimitBillsCount | OverlimitBudgetNames;

export interface IExportCycleContext {
  cycleId: number;
  setCycleId: (number: number) => void;
}

export interface ILatePaymentAlertInfo {
  show: boolean;
  cycle: IPaymentCycleData;
  bills: IBillItem[];
}

export interface BillDetailProps {
  billId: string;
  beneficiaryName: string;
  billNumber: string;
  dueDate: string;
  currency: string;
  paidAmount: number;
  attachmentURLs: string[];
  isOverDue: boolean;
  status: BillItemStatus;
}

export interface IPaymentRunCalculateResponse {
  payload: {
    currency: string;
    totalAmount: number;
  };
}

export interface IMarkAsPaidPreviewResponse {
  payload: {
    isAllPaymentRunBillIncluded: boolean;
  };
}

export interface IMarkAsPaidResponse {
  payload: {
    isSuccess: boolean;
    message: string;
    overlimitBudgetNames: string[];
  };
}
