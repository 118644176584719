import React, { MouseEvent, useEffect } from "react";
import { CSSTransition } from "react-transition-group";
import cn from "classnames";
import Header from "./components/Header";
import SidePanelSkeleton from "./components/Skeleton";
import Sticky from "./components/Sticky";
import { ISidePanelProps } from "./type";
import { ButtonWrapper, generateButton } from "./components/Buttons";
import styles from "./styles.module.scss";

export const SidePanel = ({
  visible,
  withOverlay,
  onClose,
  sticky,
  buttons,
  useLongHeight,
  loading,
  title,
  children,
  onBack,
  onEdit,
  stickyContent,
  className,
  sidePanelClassName,
}: ISidePanelProps) => {
  useEffect(() => {
    if (visible) {
      document.body.style.overflow = "hidden";
    }

    return () => {
      document.body.style.removeProperty("overflow");
    };
  }, [visible]);

  const handleOnClose = (e: MouseEvent<HTMLElement>) => {
    onClose(e);
  };

  const isOverlayVisible = withOverlay && visible;

  return (
    <>
      <CSSTransition appear={isOverlayVisible} in={isOverlayVisible} timeout={300} classNames="fade-in" unmountOnExit>
        <div className={styles.overlay} />
      </CSSTransition>
      <CSSTransition in={visible} timeout={400} classNames="slide-in" unmountOnExit>
        <section
          className={cn(styles["side-panel-wrapper"], className)}
          data-testid="side-panel-wrapper"
          onClick={handleOnClose}
        >
          <section className={cn(styles["side-panel"], sidePanelClassName)} onClick={(e) => e.stopPropagation()}>
            <Header title={title} onClose={onClose} onBack={onBack} onEdit={onEdit} />
            <div className={styles["side-panel__section-wrapper"]}>
              {loading ? <SidePanelSkeleton /> : children}
              {Boolean(buttons?.length) && !sticky && (
                <ButtonWrapper>{buttons.map((button) => generateButton(button, buttons.length))}</ButtonWrapper>
              )}
            </div>
            {Boolean(sticky) && Boolean(buttons?.length) && (
              <Sticky buttons={buttons} useLongHeight={useLongHeight} stickyContent={stickyContent} loading={loading} />
            )}
          </section>
        </section>
      </CSSTransition>
    </>
  );
};

export * from "./type.d";
