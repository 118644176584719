import * as React from "react";
import { emptyCards } from "assets/img/index"; // Migrate this later
import AddCard from "Views/Card/Add";
import { CARD_LIST_TABS } from "Views/Card/List/types";
import { useCardListContext } from "Views/Card/List/index.context";
import styles from "./index.module.scss";

type IEmptyCardProp = {
  showAddCard: boolean;
  requestCardMessage: string;
  canAddCards: boolean;
};

const EmptyCardList = ({ showAddCard, canAddCards, requestCardMessage }: IEmptyCardProp) => {
  const { getCardListData, changeCardListActiveTab } = useCardListContext();

  const onAddCardFinish = (getCardDataType: CARD_LIST_TABS) => {
    changeCardListActiveTab(getCardDataType);
    getCardListData(0, getCardDataType);
  };

  const getEmptyStateInfo = () => {
    if (showAddCard) {
      if (canAddCards) {
        return (
          <>
            <h3>No cards yet</h3>
            <p>Start adding your new card</p>
            <AddCard onFinish={() => onAddCardFinish(CARD_LIST_TABS.CARD_LIST)} />
          </>
        );
      } else {
        return (
          <>
            <h3>No card found</h3>
            <p>
              You can request cards from <br /> your Budget Owner
            </p>
            <AddCard onFinish={() => onAddCardFinish(CARD_LIST_TABS.SENT_CARD_REQUEST)} />
          </>
        );
      }
    } else if (requestCardMessage) {
      return <h3>{requestCardMessage}</h3>;
    } else {
      return <h3>No cards found</h3>;
    }
  };

  return (
    <div className={styles["cards__empty"]}>
      <img alt="empty card list" src={emptyCards} />
      {getEmptyStateInfo()}
    </div>
  );
};

export default EmptyCardList;
