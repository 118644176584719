import APIclient from "API/Client";
import { GetOrgId } from "utility";

import {
  GET_EMPLOYEES_ACTION_TYPES,
  IEmployeeFilters,
  IEmployeesData,
} from "Redux/Actions/Employee/Get/Employees/types";

import { HTTP_STATUS_CODE } from "constants/HTTPStatusCode.constant";
import { EMPLOYEES_TABLE_PAGE_SIZE, EMPLOYEES_INITIAL_PAGE_NUMBER } from "constants/Employee.constant";

export const fetchAllEmployeesRequest = (): {
  type: GET_EMPLOYEES_ACTION_TYPES.FETCH_ALL_EMPLOYEES_REQUEST;
} => {
  return {
    type: GET_EMPLOYEES_ACTION_TYPES.FETCH_ALL_EMPLOYEES_REQUEST,
  };
};

export const fetchAllEmployeesSuccess = (
  data: IEmployeesData
): {
  type: GET_EMPLOYEES_ACTION_TYPES.FETCH_ALL_EMPLOYEES_SUCCESS;
  data: IEmployeesData;
} => {
  return {
    type: GET_EMPLOYEES_ACTION_TYPES.FETCH_ALL_EMPLOYEES_SUCCESS,
    data: data,
  };
};

export const fetchAllEmployeesError = (
  data: IEmployeesData
): {
  type: GET_EMPLOYEES_ACTION_TYPES.FETCH_ALL_EMPLOYEES_ERROR;
  data: IEmployeesData;
} => {
  return {
    type: GET_EMPLOYEES_ACTION_TYPES.FETCH_ALL_EMPLOYEES_ERROR,
    data: data,
  };
};

export const getAllEmployees = (
  pageNumber: number = EMPLOYEES_INITIAL_PAGE_NUMBER,
  pageSize: number = EMPLOYEES_TABLE_PAGE_SIZE,
  search: string = "",
  filters: IEmployeeFilters = {}
) => {
  return (dispatch) => {
    dispatch(fetchAllEmployeesRequest());

    const orgId: string = GetOrgId();

    const pageNum: string = pageNumber ? `pg=${pageNumber}&` : "";
    const pageLimit: string = pageSize ? `limit=${pageSize}&` : "";
    const searchVal: string = search ? `search=${search}&` : "";
    const search_filters: string = filters
      ? `search_filters=${JSON.stringify({ ...filters, exclude_default_users: true, include_non_wallet: true })}&`
      : "";

    APIclient.getData(`/api/v1/org/${orgId}/employees?${pageNum}${pageLimit}${searchVal}${search_filters}`)
      .then((data: { [propName: string]: any }) => {
        if (data.data.status > HTTP_STATUS_CODE.OK) {
          dispatch(fetchAllEmployeesError(data.data));
        } else {
          dispatch(fetchAllEmployeesSuccess(data.data));
        }
      })
      .catch((err) => {
        dispatch(fetchAllEmployeesError(err));
      });
  };
};
