import React from "react";
import PropTypes from "prop-types";
import { Form, Input } from "antd";
import Button from "../../Modules/button";
import { Toaster } from "../../Modules/toaster";
import "./ResetPassword.scss";

import { connect } from "react-redux";
import LoadingComponent from "../State/Loading/LoaderIcon";
import { resetPasswordFunc } from "../../Redux/Actions";
import { trackEvent } from "utility/analytics";

class ResetPassword extends React.Component {
  state = {
    fieldChanged: false,
    showToaster: false,
  };

  formRef = React.createRef();

  componentDidMount() {
    trackEvent("reset password viewed");
  }

  onFinish = (values) => {
    delete values.password;
    this.props.changePassword(values, true);
    this.setState({ fieldChanged: true, showToaster: true });
    trackEvent("reset password confirm clicked");
  };

  render() {
    const { loading, error, errorMessage, successMessage } = this.props;

    return (
      <div className={"reset-password"}>
        <Form
          ref={this.formRef}
          onFinish={this.onFinish}
          validateTrigger={true}
          onValuesChange={() => this.setState({ fieldChanged: false })}
        >
          <h2>Reset Password</h2>

          <Form.Item
            label={"Old Password"}
            type="password"
            name="old_password"
            rules={[
              {
                required: true,
                message: "Please enter your current password!",
              },
            ]}
          >
            <Input.Password placeholder="Old Password" autoComplete="off" />
          </Form.Item>
          <Form.Item
            name="password"
            label="New Password"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
              {
                pattern:
                  /^\S*(?=\S{8,})(?=\S*[a-z])(?=\S*[A-Z])(?=\S*[\d])((?=\S*[\W]))\S*^/,
                message:
                  "Password must be at least 8 letters long with at least one lowercase letter, one uppercase letter, one number and one special character.",
              },
            ]}
            hasFeedback
            validateTrigger="onChange"
          >
            <Input.Password placeholder="New Password" autoComplete="off" />
          </Form.Item>
          <Form.Item
            name="new_password"
            label="Confirm New Password"
            dependencies={["password"]}
            rules={[
              { required: true, message: "Please confirm your password!" },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    "The two passwords that you entered do not match!",
                  );
                },
              }),
            ]}
            hasFeedback
            validateTrigger="onChange"
          >
            <Input.Password
              placeholder={"Confirm Password"}
              autoComplete="off"
            />
          </Form.Item>
          {error && errorMessage && this.state.fieldChanged && (
            <p className={"reset-password--error"}>{errorMessage}</p>
          )}
          <Form.Item>
            {loading ? (
              <LoadingComponent />
            ) : (
              <Button disabled={loading} text="Change Password" rounded />
            )}
          </Form.Item>
        </Form>
        <Toaster
          visible={this.state.showToaster && !error && !loading}
          status={"success"}
          message={successMessage}
          closeFunc={() => {
            this.setState({ showToaster: false });
            this.formRef.current.resetFields();
          }}
        />
      </div>
    );
  }
}

ResetPassword.defaultProps = { errorMessage: "" };

ResetPassword.propTypes = {
  changePassword: PropTypes.func,
  loading: PropTypes.bool,
  successMessage: PropTypes.string,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
};

export default connect(
  (state) => ({
    loading: state.resetPasswordReducer.loading,
    successMessage: state.resetPasswordReducer.successMessage,
    error: state.resetPasswordReducer.error,
    errorMessage: state.resetPasswordReducer.errorMessage,
  }),
  {
    changePassword: resetPasswordFunc,
  },
)(ResetPassword);
