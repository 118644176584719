import DataTable, { DATA_TABLE_SIZE_TYPES, IColumnData, Table } from "Modules/DS/DataTable";
import Icon from "Modules/icons";
import { dataTableNoRecordFound } from "assets/img";
import classNames from "classnames";
import React from "react";
import { capitalizeFirstLetter } from "utility";
import sharedStyles from "../../Bills.module.scss";
import styles from "../ManageRecipients.module.scss";
import { Pagination, RecipientListItem } from "../types";
import { formatDate } from "../utils/formatter";

// TODO: uncomment this when last transaction strategy is done
// import { currencyFormatter } from "utility";
// import { Tooltip } from "antd";
// import { newInfo } from "assets/img";

export const recipientsTableColumns: IColumnData<RecipientListItem>[] = [
  {
    id: "name",
    enabled: true,
    column: {
      title: "Recipient",
      width: "auto",
      render: (_, data) => (
        <>
          <p className={styles.oneLine} title={data.legalName}>
            {data.legalName}
          </p>
          <p className={classNames(styles.oneLine, styles.secondary)} title={data.beneficiaryName}>
            {data.beneficiaryName}
          </p>
        </>
      ),
    },
  },
  {
    id: "countryAndCurrency",
    enabled: true,
    column: {
      title: "Country and Currency",
      width: 256,
      render: (_, data) => (
        <div className={styles.contentItem}>
          <Icon
            className="country-select__flag"
            src={`https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.5.0/flags/4x3/${data.countryCode.toLowerCase()}.svg`}
          />
          <p>
            <span>{data.countryName}</span> in {data.currencyCode}
          </p>
        </div>
      ),
    },
  },
  {
    id: "type",
    enabled: true,
    column: {
      title: "Recipient Bank Account Type",
      width: 160,
      render: (_, data) => capitalizeFirstLetter(data.type),
    },
  },
  // TODO: uncomment this when last transaction strategy is done
  // {
  //   id: "lastTransaction",
  //   enabled: true,
  //   column: {
  //     title: (
  //       <div className={styles.contentItem}>
  //         <div>Last Transaction</div>
  //         <Tooltip title="Showing only paid or reversed transactions">
  //           <img src={newInfo} alt="SLA Information" />
  //         </Tooltip>
  //       </div>
  //     ),
  //     width: 220,
  //     render: (_, data) => {
  //       if (!data.lastTransaction) {
  //         return <span className={styles.contentItemEmpty}>No settled transaction</span>;
  //       }
  //       return (
  //         <>
  //           <p className={styles.oneLine}>
  //             {currencyFormatter(data.lastTransaction.amount, data.lastTransaction.currency)}
  //           </p>
  //           <p className={classNames(styles.oneLine, styles.secondary)}>{formatDate(data.lastTransaction.timestamp)}</p>
  //         </>
  //       );
  //     },
  //   },
  // },
  {
    id: "lastModified",
    enabled: true,
    column: {
      title: "Last Modified By",
      width: 280,
      render: (_, data) => {
        if (!data.lastModified) {
          return <span className={sharedStyles.itemEmpty}>-</span>;
        }
        return (
          <div className={styles.contentItem}>
            <div className={styles.initial}>{data.lastModified.name[0]}</div>
            <div>
              <p className={styles.oneLine}>{data.lastModified.name}</p>
              <p className={classNames(styles.oneLine, styles.secondary)}>{formatDate(data.lastModified.timestamp)}</p>
            </div>
          </div>
        );
      },
    },
  },
];

export interface RecipientsTableProps {
  data: RecipientListItem[];
  pagination: Pagination;
  onGetDetail?: (id: number) => void;
  highlightRow?: number;
}

export const RecipientsTable = ({ data, pagination, onGetDetail, highlightRow }: RecipientsTableProps) => {
  if (!data.length) {
    return (
      <div className={sharedStyles.emptyDraftContainer}>
        <img src={dataTableNoRecordFound} alt="recipients not found" width={120} height={120} />
        <p className={sharedStyles.emptyTitle}>No Recipient Found</p>
        <p className={sharedStyles.content}>Change or remove selected filters to see other results</p>
      </div>
    );
  }

  return (
    <DataTable className={sharedStyles.draftTable} size={DATA_TABLE_SIZE_TYPES.LARGE}>
      <Table
        paginationEnabled={false}
        dataSource={data}
        pageNum={pagination.page}
        pageSize={pagination.size}
        rowAction={(rowData) => onGetDetail?.(rowData.ID)}
        rowClassName={(record) => {
          if (highlightRow === record.ID) {
            return classNames(sharedStyles.clickableRow, styles.highlightRow);
          }
          return sharedStyles.clickableRow;
        }}
      >
        {recipientsTableColumns}
      </Table>
    </DataTable>
  );
};

export default RecipientsTable;
