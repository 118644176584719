import React from "react";
import { useHistory } from "react-router-dom";
import qs from "query-string";
import { GetCurrencyCode, capitalizeFirstLetter, currencyFormatter } from "utility";

import { ICardTrxnDetails, IDetailWithEnabledFlag } from "Views/Transaction/@types";

import { Header, Status } from "Views/Transaction/Details";
import { InfoFilled, Typography, Button } from "@spenmo/splice";
import { removeDuplicates, getRecordDetailsDate } from "Views/Transaction/Helper";
import { CARD_PATHS } from "Route/Card/path";
import useCheckFeatureStatus from "customHooks/featureCheck";
import { SPLIT_NAMES, SPLIT_TREATMENT_TYPES } from "Redux/splitio/constants";

import { Details } from "./Details";
import Banner from "Modules/DS/Banner";

import styles from "./index.module.scss";

export const CardTrxnDetails = ({
  amount,
  currencyCode,
  requesterName,
  trxnNumber,
  teamName,
  notes,
  status,
  recipientName,
  createdDate,
  receipts,
  handleSaveRef,
  closeDetails,
  expenseData,
  trxnType,
  isHistoricalRefund,
  disableEditing,
  trxnId,
  trxnNumberElement,
}: ICardTrxnDetails) => {
  const date = getRecordDetailsDate(createdDate);
  const history = useHistory();
  const isCardsTrxnsTabEnabled = useCheckFeatureStatus(SPLIT_NAMES.cardsTrxns) === SPLIT_TREATMENT_TYPES.ON;

  const details: IDetailWithEnabledFlag[] = [
    {
      id: "requester",
      key: "Requester",
      value: capitalizeFirstLetter(requesterName),
      subtitle: capitalizeFirstLetter(teamName),
      enabled: true,
    },
    { id: "trxn_number", key: "Transaction Number", value: trxnNumberElement(), enabled: true },
    { id: "reference", key: "Reference", value: expenseData.reference, enabled: true },
    { id: "created_date", key: "Created Date & Time", value: date, enabled: true },
    { id: "trxn_type", key: "Transaction Type", value: "Cards", enabled: true },
    {
      id: "fx_currency_code",
      key: "Foreign Currency Code",
      value: expenseData.foreignCurrencyCode || "N/A",
      enabled: GetCurrencyCode() !== expenseData.foreignCurrencyCode,
    },
    {
      id: "fx_currency_amount",
      key: "Foreign Currency Amount",
      value: currencyFormatter(expenseData.foreignCurrencyAmount, expenseData.foreignCurrencyCode, true),
      enabled: GetCurrencyCode() !== expenseData.foreignCurrencyCode,
    },
  ].filter((detail) => detail.enabled);

  const redirectToCardsTab = () => {
    const query = qs.stringifyUrl({
      url: CARD_PATHS.CARD_LIST,
      query: {
        cardListActiveTab: "cardTransactions",
        txnNumber: trxnNumber,
      },
    });
    history.push(query);
  };

  const message = (
    <Typography variant="body-content" size="caption-m" tag="p">
      View more detailed transaction status and amount breakdown in the Cards tab or{" "}
      <Button size="s" variant="tertiary" onClick={redirectToCardsTab} className={styles.redirectCta}>
        click here
      </Button>
    </Typography>
  );

  return (
    <>
      <Header title={recipientName} label="Merchant" amount={amount} currencyCode={currencyCode} />
      {isCardsTrxnsTabEnabled ? (
        <Banner
          type="info"
          icon={<InfoFilled iconColor="currentColor" size="16" />}
          className={styles.banner}
          message={message}
        />
      ) : (
        <Status date={date} status={status} />
      )}
      <Details
        notes={notes}
        details={details}
        handleSaveRef={handleSaveRef}
        trxnType={trxnType}
        trxnNumber={trxnNumber}
        receipts={removeDuplicates(receipts)}
        categoryId={expenseData.category}
        categoryName={expenseData.categoryName}
        taxName={expenseData.taxName}
        closeDetails={closeDetails}
        taxId={expenseData.tax}
        tags={expenseData.tags}
        trxnId={trxnId}
        isHistoricalRefund={isHistoricalRefund}
        disableEditing={disableEditing}
        expenseReceipts={expenseData.receipts}
        subscriptionId={expenseData.subscription_id}
        subscriptionInitialProps={{
          vendor_name: recipientName,
          estimated_amount: amount,
          currency: currencyCode,
          budget_id: expenseData.budgetId,
          owner_id: expenseData.payerId,
        }}
      />
    </>
  );
};
