import { useSelector } from "react-redux";
import { ARYADANA_COUNTRY } from "Views/Bills/const";

import useCheckFeatureStatus from "customHooks/featureCheck";
import { SPLIT_NAMES, SPLIT_TREATMENT_TYPES } from "Redux/splitio/constants";

const useIsAryadana = () => {
  const isAryadanaTreatmentOn = useCheckFeatureStatus(SPLIT_NAMES.aryadanaLogo) === SPLIT_TREATMENT_TYPES.ON;
  const orgDetails = useSelector((state: any) => state.b2bOrgDetailReducer?.data?.payload || {});
  const showAryadana = isAryadanaTreatmentOn && orgDetails?.countryCode === ARYADANA_COUNTRY;

  return Boolean(showAryadana);
};

export default useIsAryadana;
