import React from "react";
import classNames from "classnames";
import { Typography } from "antd";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import moment from "moment";

import { currencyFormatter } from "utility";
import styles from "./InfoCard.module.scss";

const InfoCard = ({ id, data }) => {
  const { Text } = Typography,
    formattedDate = data?.extractionFinishTime
      ? moment(new Date(data.extractionFinishTime)).format("DD MMM YYYY hh:mm a")
      : "-";

  return (
    <div className={styles.container}>
      <Text className={classNames(styles.text, styles.normal)}>Extracting payment details for</Text>
      <Text className={classNames(styles.text, styles.big)}>{data?.beneficiary?.name}</Text>
      <Text className={classNames(styles.text, styles.big)}>
        {`${data?.currency} ${currencyFormatter(data?.amount, data?.currency || "SGD", true)}`}
      </Text>
      <Text className={classNames(styles.text, styles.normal)}>{`Estimated finish in ${formattedDate}`}</Text>
      <Text className={classNames(styles.text, styles.normal)}>
        Extraction takes too long? <Link to={{ pathname: "/bills/create", state: { id, data } }}>Extract manually</Link>
      </Text>
      <Text className={styles.note}>After finished, transaction will move to approval directly.</Text>
    </div>
  );
};

InfoCard.propTypes = { data: PropTypes.object, id: PropTypes.string };

InfoCard.defaultProps = { data: {}, id: "" };

export default InfoCard;
