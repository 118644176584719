import React from "react";

import { Col } from "antd";
import { USER_ROLE } from "constants/Team.constant";
import { currencyFormatterWithFractions } from "utility";
import classNames from "classnames";

export const getRole = (companyRole) => {
  return [USER_ROLE.ADMIN, USER_ROLE.ACCOUNTANT, USER_ROLE.BOOKKEEPER, USER_ROLE.AUDITOR].indexOf(companyRole) !== -1
    ? companyRole
    : "Employee";
};

export const checkLowCompanyBalance = (companyExpenses) => {
  const RADIX_VALUE = 10;
  return (
    parseInt(companyExpenses?.remainingBalance, RADIX_VALUE) <
    parseInt(companyExpenses?.lowBalanceThreshold, RADIX_VALUE)
  );
};

export const getExpenseElement = (expenseName, currencyCode, expense, isLowCompBalance = false) => {
  const _homePageHeaderClassNames = classNames("homepage-header__expense-container", {
    "homepage-header__expense-container--warning": isLowCompBalance,
  });

  return (
    <Col className={_homePageHeaderClassNames}>
      {expenseName}
      <br />
      <p className="homepage-header__expense">{currencyFormatterWithFractions(expense, currencyCode, 2)}</p>
      {isLowCompBalance && <p className="low-comp-balance">Low Company Balance</p>}
    </Col>
  );
};
