export interface ChangePasswordFormProps {
  token: string;
  onFinish: (value: object) => void;
}

export const EXPIRED_TOKEN_CODE = 2002;
export const FORCE_PASSWORD_EXPIRED_TOKEN_CODE = 2004;
export const AUTH_EXPIRED_TOKEN_CODE = 2000;
export const INVALID_REFRESH_TOKEN = 2001;
export const SAME_PASSWORD = 1001;
export const REGREX_EXPRESSION = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W])[A-Za-z\d\W]{8,}$/;
