export const detectTabChange = (handleTabSwitch) => {
  document.addEventListener("visibilitychange", handleTabSwitch);
  document.addEventListener("blur", handleTabSwitch);
  window.addEventListener("blur", handleTabSwitch);
  window.addEventListener("focus", handleTabSwitch);
  document.addEventListener("focus", handleTabSwitch);

  return () => {
    window.removeEventListener("blur", handleTabSwitch);
    document.removeEventListener("blur", handleTabSwitch);
    window.removeEventListener("focus", handleTabSwitch);
    document.removeEventListener("focus", handleTabSwitch);
    document.removeEventListener("visibilitychange", handleTabSwitch);
  };
};
