import React from "react";

import { capitalizeFirstLetter } from "utility";

import { IDetail } from "Modules/TransactionDetail";

import { IIncentiveTrxnDetails } from "Views/Transaction/@types";
import { Header, Status } from "Views/Transaction/Details";
import { removeDuplicates, getRecordDetailsDate } from "Views/Transaction/Helper";

import { Details } from "./Details";

export const IncentiveTrxnDetails = ({
  amount,
  currencyCode,
  requesterName,
  trxnNumber,
  trxnId,
  teamName,
  notes,
  status,
  recipientName,
  createdDate,
  receipts,
  handleSaveRef,
  closeDetails,
  expenseData,
  trxnType,
  disableEditing,
  isHistoricalRefund,
}: IIncentiveTrxnDetails) => {
  const date = getRecordDetailsDate(createdDate);

  const details: IDetail[] = [
    {
      id: "requester",
      key: "Requester",
      value: capitalizeFirstLetter(requesterName),
      subtitle: capitalizeFirstLetter(teamName),
    },
    { id: "trxn_number", key: "Transaction Number", value: trxnNumber },
    { id: "reference", key: "Reference", value: expenseData.reference },
    { id: "created_date", key: "Created Date & Time", value: date },
    { id: "trxn_type", key: "Transaction Type", value: "Incentive" },
  ];

  return (
    <>
      <Header title={recipientName} label="Merchant" amount={amount} currencyCode={currencyCode} />
      <Status date={date} status={status} />
      <Details
        notes={notes}
        details={details}
        handleSaveRef={handleSaveRef}
        trxnType={trxnType}
        trxnNumber={trxnNumber}
        trxnId={trxnId}
        receipts={removeDuplicates(receipts)}
        categoryId={expenseData.category}
        categoryName={expenseData.categoryName}
        closeDetails={closeDetails}
        taxId={expenseData.tax}
        tags={expenseData.tags}
        disableEditing={disableEditing}
        isHistoricalRefund={isHistoricalRefund}
      />
    </>
  );
};
