import * as React from "react";
import { Button, Typography } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { fetchCardCvv, clearCardCvv } from "Redux/Actions/Card/Cvv";
import Loader, { LoaderTypes } from "Views/Card/Components/Loader";
import { showErrorNotification } from "Views/Card/Components/Notification";
import { REVEAL_CARD_DETAILS_BTN_COLOR_MAP } from "constants/Card/CardColors.constants";
import { whiteRefreshIcon16x16 } from "assets/icons/card";

import styles from "Views/Card/Details/Card/V2/Cvv/index.module.scss";

const { Text } = Typography;

export interface ICardCvvProps {
  cardId: string;
  cardColor: string;
}

const CardCvv = ({ cardId, cardColor }: ICardCvvProps) => {
  const dispatch = useDispatch();
  const { data, loading, error } = useSelector((state: any) => state?.cardCvvReducer || {});

  const showNotificationIfFailedToFetch = () => {
    if (error) {
      const { statusMessage = "Unable to fetch CVV! Please try again later." } = data || {};
      showErrorNotification(statusMessage);
    }
  };

  React.useEffect(() => {
    showNotificationIfFailedToFetch();
  }, [data]);

  React.useEffect(() => {
    return () => {
      dispatch(clearCardCvv());
    };
  }, []);

  const getButtonText = () => {
    switch (true) {
      case error:
        return (
          <div className={styles["refresh-container"]}>
            <img src={whiteRefreshIcon16x16} width={16} height={16} alt="refresh icon" />
          </div>
        );
      case loading:
        return <Loader type={LoaderTypes.white_16x16} />;
      default:
        return "Show CVV";
    }
  };

  const getCvvData = () => {
    const { cardCvv = "" } = data || {};
    if (!!cardCvv) {
      return <Text className={styles["card-cvv"]}>{cardCvv}</Text>;
    } else {
      return (
        <Button
          style={{ backgroundColor: REVEAL_CARD_DETAILS_BTN_COLOR_MAP?.[cardColor] || "" }}
          className={styles["show-card-cvv-button"]}
          onClick={() => dispatch(fetchCardCvv(cardId))}
        >
          {getButtonText()}
        </Button>
      );
    }
  };

  return <Text className={styles["card-cvv-container"]}>{getCvvData()}</Text>;
};

export default CardCvv;
