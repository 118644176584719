import React from "react";
import { Link, useLocation } from "react-router-dom";
import cn from "classnames";
import { Typography } from "@spenmo/splice";

import styles from "./styles.module.scss";

const SubMenu = ({ menu }: { menu: { title: string; path: string }[] }) => {
  const location = useLocation();

  const isMatch = (path: string) => {
    return path === location.pathname;
  };
  return (
    <ul className={styles.subMenu}>
      {menu.map((menu) => (
        <li key={menu.title}>
          <Link to={menu.path}>
            {isMatch(menu.path) && <div className={styles.activeIndicator}></div>}
            <Typography
              tag="p"
              size="m"
              variant="body-content"
              className={cn(styles.link, {
                [styles.active]: isMatch(menu.path),
              })}
            >
              {menu.title}
            </Typography>
          </Link>
        </li>
      ))}
    </ul>
  );
};

export default SubMenu;
