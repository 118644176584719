import React, { useState } from "react";
import cn from "classnames";

import { Button } from "@spenmo/splice";
import Tabs from "Modules/DS/Tabs";
import { TOASTER_STATUS_TYPE } from "Modules/DS/Toaster";
import useLoading from "utility/useLoading";
import { getAccountingSetting, updateAccountingSetting } from "Redux/DataCalls/AccountingSetting.api";

import { TRANSACTION_TYPE } from "../helper/constant";
import { mapToApiPayload } from "../helper/dataMapping";
import { useTabConfig } from "../hooks/useTabConfig";
import { ITabConfig, ITransactionSettings } from "../@types";
import { DescriptiveText, SettingLabel, CheckboxWithDescription } from "../components";
import { useDisableButton } from "../hooks/useDisableButton";
import { useSettingContext } from "../context/SettingContext";

import styles from "./styles.module.scss";

const tabName = (name: string) => (name.endsWith("s") ? name.slice(0, -1) : name);

const TransactionSettings: React.FC<ITransactionSettings> = ({
  settings = {},
  settingUpdateNotAllowed,
  enableExpenseIntegration,
  settingsChange,
  refetchAccountingSettingData,
  setToaster,
  partnerName,
}: ITransactionSettings) => {
  const [disableButton] = useDisableButton(settingsChange, "configSetting");
  const { globalConfig, form, setSettingChangeObj } = useSettingContext();

  const defaultActiveKey = Object.keys(settings)?.[0] || "Bills";
  const [activeKey, setActiveKey] = useState(TRANSACTION_TYPE[defaultActiveKey]);
  const [tabsConfig, toggledEnableReview, toggleRequiredField] = useTabConfig();
  const [updateSetting, loadingUpdateSetting] = useLoading(updateAccountingSetting);

  const getTabContent = (tab: ITabConfig) => (
    <div>
      <div className={cn(styles.reviewTrxnsContainer, { [styles.bottomSpacing]: !tab.requiredFields.length })}>
        <SettingLabel text={`Review ${tabName(tab.key)} Transactions`} />
        <CheckboxWithDescription
          checkboxLabel="Enable review for Accountants"
          description="Enabling this will allow you to review settled transaction details before you sync and reconcile them."
          checkboxProps={{
            checked: tab.review,
            onChange: () => toggledEnableReview(tab.id),
            disabled: settingUpdateNotAllowed,
          }}
        />
      </div>
      {tab.AdditionalConfig && enableExpenseIntegration && (
        <tab.AdditionalConfig
          setting={settings[tab.apiKey]}
          settingUpdateNotAllowed={settingUpdateNotAllowed}
          partnerName={partnerName}
        />
      )}
      {Boolean(tab.requiredFields.length) && (
        <>
          <SettingLabel text="Required Fields" />
          <DescriptiveText description="The following fields will be marked as mandatory. Mandatory fields are to be filled up before a transaction is eligible for synchronization." />
          <div className={styles.requiredFieldsContainer}>
            {tab.requiredFields.map((fields) => (
              <React.Fragment key={fields.id}>
                <CheckboxWithDescription
                  checkboxLabel={fields.value}
                  checkboxProps={{
                    disabled: fields.disabled || settingUpdateNotAllowed,
                    checked: fields.checked,
                    onChange: () => toggleRequiredField(tab.id, fields.id),
                  }}
                />
              </React.Fragment>
            ))}
          </div>
        </>
      )}
    </div>
  );

  const saveTransactionSetting = async () => {
    try {
      await updateSetting(mapToApiPayload(tabsConfig, form, globalConfig));
      refetchAccountingSettingData(getAccountingSetting, true);
      setToaster({
        message: "The accounting setting is successfully updated",
        show: true,
        type: TOASTER_STATUS_TYPE.SUCCESS,
      });
      setSettingChangeObj(null);
    } catch (error) {
      setToaster({ message: "Something went wrong", show: true, type: TOASTER_STATUS_TYPE.ERROR });
    }
  };

  return (
    <div className={styles.transactionSettingsContainer}>
      <DescriptiveText description="Set rules for synchronization by expense type" />
      <Tabs
        tabsList={tabsConfig}
        defaultActiveKey="Bills"
        activeKey={activeKey}
        action={setActiveKey}
        content={(tab: ITabConfig) => getTabContent(tab)}
      />
      {!settingUpdateNotAllowed && (
        <div className={styles.footerSection}>
          <Button
            variant="primary"
            onClick={saveTransactionSetting}
            loading={loadingUpdateSetting}
            disabled={disableButton}
          >
            Save Changes
          </Button>
        </div>
      )}
    </div>
  );
};

export default TransactionSettings;
