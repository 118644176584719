import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Popover, Skeleton } from "antd";

import { fetchAccountingTag, setNewTags } from "Redux/Actions/AccountingTag/accountingTagAction";
import { TagsDropdownContext } from "./context";

import "./index.scss";
import TagOptionWrapper from "./TagOptionsWrapper";
import TagInput from "./TagInput";
import { ITag, ITagGroup } from "Views/Card/types";
import { TooltipPlacement } from "antd/lib/tooltip";
interface ITagsDropdown {
  onChange: (tags: ITag[]) => void;
  selectedTags?: ITag[];
  subMenuPosition?: TooltipPlacement;
}
const TagsDropdown = ({ onChange, selectedTags, subMenuPosition = "rightBottom" }: ITagsDropdown) => {
  const [openSubMenu, setOpenSubMenu] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [selectedTag, setSelectedTag] = useState<ITag[]>(selectedTags || []);

  const { data, loading } = useSelector((state: any) => state.accountingTagReducer);
  const dispatch = useDispatch();

  const { tags = [] } = data?.payload || {};

  useEffect(() => {
    dispatch(fetchAccountingTag(selectedTag));
    return () => {
      // * This will reset to be empty the tags when the component is updated or unmount
      dispatch(setNewTags([]));
    };
  }, [dispatch]);
  const selectChildren = (selectedTagGroup: number, selectedTagOption: number) => {
    const newTags = tags.map((tagGroup: ITagGroup) => {
      const newTagGroup = { ...tagGroup };
      if (newTagGroup.id === selectedTagGroup) {
        if (newTagGroup.selectedChildren === selectedTagOption) {
          newTagGroup.selectedChildren = undefined;
        } else {
          newTagGroup.selectedChildren = selectedTagOption;
        }
      }
      return newTagGroup;
    });

    onChange(findSelectedChildren(newTags));

    dispatch(setNewTags(newTags));
  };

  const findSelectedChildren = (selectedTags: ITagGroup[]): ITag[] => {
    return selectedTags
      .filter((tagGroup) => tagGroup.selectedChildren)
      .map((tagGroup) => {
        const { value } = tagGroup.values.find((tagOption) => tagOption.id === tagGroup.selectedChildren);
        return { label: tagGroup.label, value, tagGroup };
      });
  };

  const selectedChildren = findSelectedChildren(tags);

  const deleteTag = (deletedTagGroup: number) => {
    const newTags = tags.map((tagGroup: ITagGroup) => {
      const newTagGroup = { ...tagGroup };
      if (newTagGroup.id === deletedTagGroup) {
        newTagGroup.selectedChildren = undefined;
      }
      return newTagGroup;
    });

    onChange(findSelectedChildren(newTags));
    dispatch(setNewTags(newTags));
  };

  const contextValue = {
    openDropdown,
    setOpenDropdown,
    openSubMenu,
    setOpenSubMenu,
    data: tags,
    selectedTag,
    setSelectedTag,
    selectChildren,
    selectedChildren,
    deleteTag,
    subMenuPosition,
  };

  return (
    <Skeleton active loading={loading} paragraph={false} round title={true}>
      <TagsDropdownContext.Provider value={contextValue}>
        <Popover
          visible={openDropdown}
          overlayClassName="tags-dropdown__popover"
          getPopupContainer={(triggerNode) => triggerNode.parentElement}
          content={TagOptionWrapper}
          data-testid="tags-dropdown-popover"
          placement="top"
        >
          <TagInput />
        </Popover>
      </TagsDropdownContext.Provider>
    </Skeleton>
  );
};

export default TagsDropdown;
