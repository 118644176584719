export const categoryEvents = {
  EXPENSE_CATEGORY_VIEWED: "Expense categories viewed",
  EXPENSE_CATEGORY_DETAIL_VIEWED: "Accounting Settings Details Viewed",
  IMPORT_FROM_XERO: "Click import from Xero on popover",
  EDIT_CATEGORY: "Accounting Settings Edited",
  ADD_CATEGORY: "Accounting Settings Create Button Clicked",
  IMPORT_CATEGORY_FROM_MODAL: "Click import category from modal",
  FINISH_IMPORTING_CATEGORY: "Finish importing categories from modal",
  IMPORT_FROM_XERO_FROM_BANNER: "Click import from Xero on missing import banner",
  ADD_CATEGORY_FAILURE: "add category fail",
  ADD_CATEGORY_SUCCESS: "add category success",
  EDIT_CATEGORY_FAILURE: "edit category fail",
  EDIT_CATEGORY_SUCCESS: "edit category success",
  DELETE_CATEGORY: "Accounting Settings Deleted",
  EXPENSE_CATEGORY_DETAILS_EDITED: "Expense Category Details Edited",
};
