export enum FETCH_PENDING_ACTIONS_TYPES {
  REQUEST = "FETCH_PENDING_ACTIONS_REQUEST",
  SUCCESS = "FETCH_PENDING_ACTIONS_SUCCESS",
  ERROR = "FETCH_PENDING_ACTIONS_ERROR",
}

export interface PendingActionPayload {
  category: string;
  description: string;
  id: string;
  timestamp: string;
  title: string;
}

export interface PendingActionsData {
  data: PendingActionPayload[];
}

export interface PendingActionsReducer {
  loading: boolean;
  data: PendingActionsData | any;
  error: boolean;
}

export const initialPendingActionsState: PendingActionsReducer = {
  loading: false,
  data: [],
  error: false,
};
