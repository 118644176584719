import React from "react";
import { Form, Input } from "antd";
import { NavLink } from "react-router-dom";
import { Rule } from "antd/lib/form";

export const FormPassword = (): React.ReactElement => {
  const rules: Rule[] = [
    {
      required: true,
      message: "This field cannot be empty",
    },
  ];

  return (
    <Form.Item
      label={
        <>
          Password <NavLink to={"/forgot"}>Forgot password?</NavLink>
        </>
      }
      name="password"
      rules={rules}
      className="login-password-field"
    >
      <Input.Password
        visibilityToggle
        placeholder="Enter password"
        autoComplete="off"
      />
    </Form.Item>
  );
};
