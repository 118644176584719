import {
  DELETE_APPROVAL_FLOW_REQUEST,
  DELETE_APPROVAL_FLOW_SUCCESS,
  DELETE_APPROVAL_FLOW_FAILURE,
  CLEAR_DELETE_APPROVAL_FLOW,
} from "../../Actions/ApprovalFlow/deleteApprovalFlow";
import { userInitialState } from "../../InitialState";

export const deleteApprovalFlowReducer = (state = userInitialState, action) => {
  switch (action.type) {
    case DELETE_APPROVAL_FLOW_REQUEST:
      return {
        ...state,
        data: action.data,
        loading: true,
      };
    case DELETE_APPROVAL_FLOW_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
      };
    case DELETE_APPROVAL_FLOW_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.data,
        error: true,
      };
    case CLEAR_DELETE_APPROVAL_FLOW:
      return userInitialState;
    default:
      return state;
  }
};
