import React, { useContext, useState } from "react";

import Menu from "Modules/DS/Menu";
import Select, { IElasticSelectDropdown } from "Modules/DS/Select";

import { TrxnMoreFilterContext } from "Views/Transaction/Context";
import { IElasticMoreFilter, ITrxnMoreFilterContext } from "Views/Transaction/@types";

import { TRXNS_MORE_FILTER_ID } from "./index";

export const ElasticSelect = <T extends object = any>({
  id,
  children,
  label,
  displayKey,
  iconKey,
  idKey = "id" as keyof T,
  appliedKey = "id" as keyof T,
  loading = false,
  dispatchOnAdd = () => null,
  dispatchOnRemove = () => null,
  onClickOutside = () => null,
  header,
  height,
  onSearch,
  onClearSearch,
  dataSet,
}: IElasticMoreFilter<T>) => {
  const [title, setTitle] = useState<string>("");
  const [icon, setIcon] = useState<string>("");
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const { more, applyMoreFilter, clearMoreFilter, clear } = useContext<ITrxnMoreFilterContext>(TrxnMoreFilterContext);

  const onAddSelected = (items: string[]) => {
    applyMoreFilter(id, items);
    dispatchOnAdd?.(items);
  };

  const handleClearDropdown = () => {
    clearMoreFilter(id);
    dispatchOnRemove?.();
  };

  const handleOnClickOutside = () => {
    onClickOutside?.();
    !more[id] && setSelectedIds([]);
  };

  const props: IElasticSelectDropdown<T> = {
    idKey,
    displayKey,
    appliedKey,
    children,
    onAddSelected,
    header,
    height,
    iconKey,
    searchValue: "",
    clear,
    defaultValue: label,
    presetValue: dataSet,
    parentPopoverId: TRXNS_MORE_FILTER_ID,
    clearDropdown: handleClearDropdown,
    onClickOutside: handleOnClickOutside,
    states: {
      selectedIds,
      setSelectedIds,
      setTitle,
      title,
      loading,
      icon,
      setIcon,
    },
    dropdownProps: {
      id,
      searchable: true,
      labelIcon: icon,
      containerClass: ".data-table-container",
    },
    onSearch,
    onClearSearch,
    dataSet,
  };

  return (
    <Menu.Row id={id} title={label}>
      <Select.Dropdown.Elastic<T> {...props} />
    </Menu.Row>
  );
};
